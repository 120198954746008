import {AppDispatch} from '@/config/store';
import {
    GET_CASHFLOW_DETAILS,
    GET_CASHFLOW_DETAILS_SUCCESS,
    GET_CASHFLOW_DETAILS_ERROR,
} from '@components/CashFlow/Redux/Types';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getDetailsCashflow = () => {
    return {
        type: GET_CASHFLOW_DETAILS,
    };
};
export const getDetailsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CASHFLOW_DETAILS_SUCCESS,
        payload: result,
    };
};
export const getDetailsError = (error: AxiosError) => {
    return {
        type: GET_CASHFLOW_DETAILS_ERROR,
        error: error,
    };
};

export function getDetails(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getDetailsCashflow());
        const params = {
            branchId: branchId.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCashflow}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getDetailsSuccess(response));
        } catch (error) {
            dispatch(getDetailsError(error as AxiosError));
        }
    };
}
