import {
    GET_BRANCH_SCHEDULE,
    GET_BRANCH_SCHEDULE_ERROR,
    GET_BRANCH_SCHEDULE_RESET,
    GET_BRANCH_SCHEDULE_SUCCESS,
    GET_BRANCH_SCHEDULE_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    schedule: [],
};

const GetBranchSchedule = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_BRANCH_SCHEDULE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_SCHEDULE_SUCCESS:
            return {
                ...state,
                schedule: action.payload.data,
            };
        case GET_BRANCH_SCHEDULE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRANCH_SCHEDULE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_BRANCH_SCHEDULE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetBranchSchedule;
