import {ResponseData} from '@components/ManageUser/interface';
import {
    PUT_DATA_USER_EMPLOYEE,
    PUT_DATA_USER_EMPLOYEE_SUCCESS,
    PUT_DATA_USER_EMPLOYEE_ERROR,
    RESET_DATA_USER_UPDATE,
} from '@components/ManageUser/Redux/types';

const initialState = {
    data: {},
    error: false,
    loading: false,
};

export const putDataUserEmployee = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case PUT_DATA_USER_EMPLOYEE:
            return {...state, loading: true, error: false};
        case PUT_DATA_USER_EMPLOYEE_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case PUT_DATA_USER_EMPLOYEE_ERROR:
            return {...state, loading: true, error: true};
        case RESET_DATA_USER_UPDATE:
            return initialState;
        default:
            return state;
    }
};

export default putDataUserEmployee;
