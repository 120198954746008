import {
    POST_CREATE_BRANCH,
    POST_CREATE_BRANCH_ERROR,
    POST_CREATE_BRANCH_SUCCESS,
    POST_CREATE_BRANCH_RESET,
} from '@components/Branches/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    idBranch: undefined,
};

const CreateBranch = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CREATE_BRANCH:
            return {
                ...state,
                loading: true,
            };
        case POST_CREATE_BRANCH_SUCCESS:
            return {
                ...state,
                idBranch: action.payload.data,
                loading: false,
            };
        case POST_CREATE_BRANCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_CREATE_BRANCH_RESET:
            return initialState;
        default:
            return state;
    }
};

export default CreateBranch;
