export const ACTIONS_TYPE_FILTER = {
    GetFilters: 'GET_FILTERS',
    DeleteFilter: 'DELETE_FILTER',
    UpdateFilters: 'UPDATE_FILTERS',
    StateButton: 'STATEBUTTON',
};

export const GetFilters = (value) => {
    return {
        type: ACTIONS_TYPE_FILTER.GetFilters,
        payload: value,
    };
};

export const DeleteFilters = (value) => {
    return {
        type: ACTIONS_TYPE_FILTER.DeleteFilter,
        payload: value,
    };
};

export const UpdateFilters = (value) => {
    return {
        type: ACTIONS_TYPE_FILTER.UpdateFilters,
        payload: value,
    };
};
