export const GET_HEADER_RECOLECTION_GOLD = 'GET_HEADER_RECOLECTION_GOLD';
export const GET_HEADER_RECOLECTION_GOLD_SUCCESS = 'GET_HEADER_RECOLECTION_GOLD_SUCCESS';
export const GET_HEADER_RECOLECTION_GOLD_ERROR = 'GET_HEADER_RECOLECTION_GOLD_ERROR';

export const GET_HEADER_AUDIT_GOLD = 'GET_HEADER_AUDIT_GOLD';
export const GET_HEADER_AUDIT_GOLD_SUCCESS = 'GET_HEADER_AUDIT_GOLD_SUCCESS';
export const GET_HEADER_AUDIT_GOLD_ERROR = 'GET_HEADER_AUDIT_GOLD_ERROR';

export const GET_AUDIT_GOLD = 'GET_AUDIT_GOLD';
export const GET_AUDIT_GOLD_SUCCESS = 'GET_AUDIT_GOLD_SUCCESS';
export const GET_AUDIT_GOLD_ERROR = 'GET_AUDIT_GOLD_ERROR';

export const GET_RECOLECTION_GOLD = 'GET_RECOLECTION_GOLD';
export const GET_RECOLECTION_GOLD_SUCCESS = 'GET_RECOLECTION_GOLD_SUCCESS';
export const GET_RECOLECTION_GOLD_ERROR = 'GET_RECOLECTION_GOLD_ERROR';

export const GET_FILE_NAME = 'GET_FILE_NAME';
export const GET_FILE_NAME_SUCCESS = 'GET_FILE_NAME_SUCCESS';
export const GET_FILE_NAME_ERROR = 'GET_FILE_NAME_ERROR';
