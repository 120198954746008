import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {RootState, AppDispatch} from '@config/store';

import {deleteAuditAuctions} from '@ActionsAuction/AuctionActions';
import {
    getAssignmentAuditGold,
    getHeaderAuditGold,
} from '@ActionsRecolectionGold/RecolectionGoldActions';

export const RowAuditGoldTable = (props: {
    sku: string;
    fecha: string;
    familia: string;
    subfamilia: string;
    kilataje: string;
    costo: number;
    tieneIncidencia: boolean;
}) => {
    const dispatch: AppDispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {sku, fecha, familia, subfamilia, kilataje, costo, tieneIncidencia} = props;
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const params = useParams<any>();
    const EditAssignment = (id: number) => {
        setCount(0);
        setCount(id);
    };
    const cancelAssignment = (id: number) => {
        Swal.fire({
            title: 'Cancelar asignación',
            text: '¿Esta seguro que desea cancelar la asignación?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.value) {
                const json = {
                    idAsignacion: Number(params.idaudit),
                    sku: id,
                };

                await dispatch(deleteAuditAuctions(json, token));
                dispatch(getHeaderAuditGold('9', token));
                dispatch(getAssignmentAuditGold('', 10, 0, '1', token));
            }
        });
    };
    const [count, setCount] = useState(0);
    return (
        <tr className="row-sucursal-tr" onClick={() => EditAssignment(Number(sku))}>
            <td className={tieneIncidencia ? 'required-text' : ''}>{sku}</td>
            <td>{DateTime.fromISO(fecha).toFormat('LL')}</td>
            <td>{familia}</td>
            <td>{subfamilia}</td>
            <td>{kilataje}</td>
            {count === Number(sku) ? (
                <td onClick={() => cancelAssignment(Number(sku))}>
                    <i className="material-icons icon-color">clear</i>{' '}
                    <span className="icon-color">Cancelar</span>
                </td>
            ) : (
                <td>{formatterDolar.format(costo)}</td>
            )}
        </tr>
    );
};
