import React, {useEffect, useState} from 'react';

import {ContainerFlex, ContainerForm, Input, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {GetAllContactCompanyData} from '@MyCompany/Redux/Actions/GetAllInfoContactCompany';
import {useForm} from 'react-hook-form';
import {PutDataContact} from '@MyCompany/Redux/Actions/PutDataContact';
import ButtonGeneral from '@General/Atoms/Button';
import {PostAddContactData} from '@MyCompany/Redux/Actions/PostDataContactAdd';
import {CompanyInfoContac, ContactDataEditTypes, Inputs} from '@MyCompany/Interface';
import {Label, border} from '@MyCompany/styles';
import {InputMaskStyled} from '@Roles/InputMaskStyled/style';

export const ContactDataEdit = ({setContactData, setFlowModalChange}: ContactDataEditTypes) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
    } = useForm<Inputs>();
    const messages = {
        mail: 'Asegurate de ingresar una direccion valida',
        phone: 'Asegurate de ingresar un numero de telefono valido (maximo 10 digitos)',
    };
    const patterns = {
        email: /([aA-zZ0-9!#$%&'*+/=?^_`{|}~-]{4,30})@([aA-zZ0-9.!#$%&'*+/=?^_`{|}~-]{4,120}).([aA-zZ0-9.!#$%&'*+/=?^_`{|}~-]{2,30})/,
        phone: /^\d{10}$/,
    };
    const [phoneExist, setPhoneExist] = useState();
    const [mainIdMail, setMainIdMail] = useState(0);
    const [mainIdNumber, setMainIdNumber] = useState(0);

    const inputEmail = watch('email');
    const inputPhone = watch('phone');

    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const id = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails?.[0].enterpriceId as number
    );
    const infoContacts = useSelector((state: RootState) => state.GetAllContactData.contact);

    const putRadioEmail = (values: Inputs) => {
        const jsonMail = {
            companyId: id,
            contactCompanyId: values.contactIdMail,
        };
        dispatch(PutDataContact(token, jsonMail));
        setContactData(0);
    };

    const putRadioPhone = (values: Inputs) => {
        const jsonTel = {
            companyId: id,
            contactCompanyId: values.contactIdPhone,
        };
        dispatch(PutDataContact(token, jsonTel));
        setContactData(0);
    };

    const postInputEmail = (values: Inputs) => {
        const jsonMail = {
            companyId: id,
            description: values.email,
            isMail: true,
        };
        dispatch(PostAddContactData(token, jsonMail));
        setContactData(0);
    };

    const postInputPhone = (values: Inputs) => {
        const jsonTel = {
            companyId: id,
            description: values.phone,
            isMail: false,
        };
        dispatch(PostAddContactData(token, jsonTel));
        setContactData(0);
    };

    const numberPhone = (phone: string) => {
        return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
    };

    const onSubmit = (values: Inputs) => {
        if (mainIdMail > 0) {
            putRadioEmail(values);
        } else {
            postInputEmail(values);
        }
        if (mainIdNumber > 0) {
            putRadioPhone(values);
        } else {
            postInputPhone(values);
        }
    };

    useEffect(() => {
        if (token) {
            dispatch(GetAllContactCompanyData(token, id));
        }
    }, [dispatch, id, token]);

    useEffect(() => {
        if (infoContacts && infoContacts.length > 0) {
            const data = infoContacts.filter(
                (data: CompanyInfoContac) => data.main === true && data.mail === true
            );
            setMainIdMail(data[0].contactId);
        }
        if (infoContacts && infoContacts.length > 0) {
            const data = infoContacts.filter(
                (data: CompanyInfoContac) => data.main === true && data.mail === false
            );
            setMainIdNumber(data[0]?.contactId);
            setPhoneExist(data[0]?.contactId);
        }
    }, [infoContacts]);

    useEffect(() => {
        if (inputEmail === undefined || inputEmail.length === 0) {
            return;
        }
        const sectionBefore = inputEmail.split('@');
        if (sectionBefore[0].length > 30 || sectionBefore[1]?.length > 150) {
            setValue(
                'email',
                sectionBefore[0].slice(0, 30) +
                    '@' +
                    (sectionBefore[1] ? sectionBefore[1].slice(0, 150) : '')
            );
        }

        setMainIdMail(0);
    }, [inputEmail]);

    useEffect(() => {
        if (inputPhone === undefined || inputPhone.length === 0) {
            return;
        }
        setMainIdNumber(0);
    }, [inputPhone]);

    return (
        <ContainerForm FlexDir="column" onSubmit={handleSubmit(onSubmit)}>
            {infoContacts && (
                <ContainerFlex
                    FlexDir="column"
                    {...border}
                    backG="rgba(243, 243, 243, 0.4)"
                    Height="235px"
                    Padding="16px"
                    OverFlowY="auto"
                    ScrollWidth="0px"
                    Justify="start"
                    Radius="0 0 4px 4px;"
                >
                    <ContainerFlex FlexDir="column" Align="start" Height="auto">
                        <Text FontWeight="500" FontSize="0.875rem" Color="#414042">
                            Correo electrónico
                        </Text>
                        <ContainerFlex Align="start" Justify="space-between">
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Width="230px"
                            >
                                {infoContacts
                                    .filter((item: CompanyInfoContac) => item.mail)
                                    .map((item: CompanyInfoContac, index: number) => (
                                        <ContainerFlex
                                            key={index}
                                            Justify="start"
                                            Gap="8px"
                                            Height={item.description.length > 25 ? 'auto' : '24px'}
                                            Width="220px"
                                            MarginTop="10px"
                                        >
                                            <InputColor
                                                type="radio"
                                                BackC="#35cf44"
                                                checked={
                                                    item.contactId === mainIdMail ? true : false
                                                }
                                                onClick={() => {
                                                    setMainIdMail(item.contactId);
                                                    setValue('email', '');
                                                }}
                                                value={item.contactId}
                                                {...register('contactIdMail')}
                                            />
                                            <Label
                                                Width="194px"
                                                FontWeight="normal"
                                                FontSize="0.875rem"
                                                Color="#414042"
                                                WrapOverFlow="break-word"
                                            >
                                                {item.description}
                                            </Label>
                                        </ContainerFlex>
                                    ))}
                            </ContainerFlex>
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Width="230px"
                                MarginTop="8px"
                            >
                                <Text
                                    MarginBottom="4px"
                                    FontSize="0.75rem"
                                    FontWeight="normal"
                                    Color="#6d6e71"
                                >
                                    Otro
                                </Text>
                                <Input
                                    placeholder="Correo electrónico"
                                    FontSize="0.875rem"
                                    error={errors?.email?.message}
                                    Width="230px"
                                    Padding="0px 13px"
                                    Cursor="text"
                                    HolderFontSize="0.875rem"
                                    {...register('email', {
                                        pattern: {
                                            value: patterns.email,
                                            message: messages.mail,
                                        },
                                    })}
                                />
                                {errors?.email?.message && (
                                    <Text Color="red" FontSize="0.65rem">
                                        {errors?.email?.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" MarginTop="10px" Height="auto">
                        <Text FontWeight="500" FontSize="0.875rem" Color="#414042">
                            Teléfono
                        </Text>
                        <ContainerFlex Align="start" MarginTop="8px" Justify="space-between">
                            {phoneExist && (
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    FlexDir="column"
                                    Gap="5px"
                                    Width="230px"
                                >
                                    {infoContacts
                                        .filter((item: CompanyInfoContac) => item.mail === false)
                                        .map((item: CompanyInfoContac, index: number) => (
                                            <ContainerFlex
                                                key={index}
                                                Justify="start"
                                                Gap="8px"
                                                Height="24px"
                                            >
                                                <InputColor
                                                    type="radio"
                                                    BackC="#35cf44"
                                                    value={item.contactId}
                                                    checked={
                                                        item.contactId === mainIdNumber
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        setMainIdNumber(item.contactId);
                                                        setValue('phone', '');
                                                    }}
                                                    {...register('contactIdPhone')}
                                                />
                                                <Text
                                                    FontWeight="normal"
                                                    FontSize="0.875rem"
                                                    Color="#414042"
                                                >
                                                    {numberPhone(item.description)}
                                                </Text>
                                            </ContainerFlex>
                                        ))}
                                </ContainerFlex>
                            )}

                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Width="230px"
                            >
                                {phoneExist ? (
                                    <Text
                                        MarginBottom="4px"
                                        FontSize="0.75rem"
                                        FontWeight="normal"
                                        Color="#6d6e71"
                                    >
                                        Otro
                                    </Text>
                                ) : (
                                    <Text FontSize="0.75rem" FontWeight="normal" Color="#6d6e71">
                                        Agregar{' '}
                                    </Text>
                                )}
                                <InputMaskStyled
                                    placeholder="Número de teléfono"
                                    fontSize="0.875rem"
                                    width="230px"
                                    padding="12px 13px 12px 13px"
                                    mask="9999999999"
                                    maskChar=""
                                    cursor="text"
                                    error={errors?.phone?.message}
                                    {...register('phone', {
                                        pattern: {
                                            value: patterns.phone,
                                            message: messages.phone,
                                        },
                                    })}
                                />
                                {errors?.phone?.message && (
                                    <Text Height="" wSpace="wrap" Color="red" FontSize="0.65rem">
                                        {errors?.phone?.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
            <ContainerFlex Margin="24px 0 " Justify="space-between">
                <Text
                    FontSize="0.875rem"
                    Cursor="pointer"
                    MarginLeft="8px"
                    Color="#35cf44"
                    onClick={() => {
                        setContactData(0);
                    }}
                >
                    Cancelar
                </Text>
                <ButtonGeneral
                    width="11.5rem"
                    height="40px"
                    text="Contactar a un asesor"
                    type="button"
                    FontSize="0.875rem"
                    hColor="#35cf44"
                    hBorder="1px solid redred"
                    transform=""
                    border="none"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                    clic={() => setFlowModalChange(1)}
                />

                <ButtonGeneral
                    width="185px"
                    text=" Confirmar mis datos"
                    border="solid 1px #414042"
                    transform=""
                    hColor="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    secondaryButton="none"
                />
            </ContainerFlex>
        </ContainerForm>
    );
};
