import React from 'react';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IconContainer} from '@/components/CashFlow/styles';

interface IError {
    error: boolean;
    password: string;
}
export const ErrorPasswordRepeat = ({error, password}: IError) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <IconContainer
                FontSize="0.875rem"
                className="material-icons"
                Color={error ? '#FF6357' : '#309C60'}
                Width="auto"
                Height="auto"
            >
                {error ? 'close' : 'done'}
            </IconContainer>
            <Text
                Color={error ? '#FF6357' : '#309C60'}
                FontSize="0.85rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
                Display="inline-block"
            >
                {error
                    ? 'Las contraseñas no coinciden, inténtalo de nuevo'
                    : 'Las contraseñas coinciden'}
            </Text>
        </ContainerFlex>
    );
};
