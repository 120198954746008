import React, {useState, useEffect} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Table} from '@mui/material';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import moreVertIcon from '@/images/more_vert.svg';
import {ImageLogo} from '@/components/LoginClient/styles';
import ExpensesMenu from '@/components/CashFlow/Expenses/OptionsMenu';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import UnfoldMore from '@/images/unfold_more.svg';
import {EXPENSES} from '@/components/CashFlow/Expenses/constants';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getExpensesAction} from '@components/CashFlow/Redux/Actions/GetExpenses';
import {RootState} from '@/config/store';
import {PaginatedForTransactionTable} from '@CashFlow/PaginatedForTransactionTable';
import {SearchFilterTrasn} from '@/components/CashFlow/SearchFilterTrans';
import {IFilterCashFlow} from '@components/CashFlow/interfaces';
import {IExpenseData} from '@components/CashFlow/interfaces';
import {getExpenseInvoiceAction} from '@components/CashFlow/Expenses/Redux/Actions/GetExpenseInvoiceAction';
import {getExpenseDetailssAction} from '@/components/CashFlowExpenseDetails/Redux/ExpenseDetailsAction';

const ITEMS_PER_PAGE = 10;

const Expenses = () => {
    const [file, setFile] = useState<string>('');
    const [expenseId, setExpenseId] = useState(0);
    const expenseData = useSelector((state: RootState) => state.getExpenses.expenses, shallowEqual);
    const totalPages = useSelector(
        (state: RootState) => state.getExpenses.totalPages,
        shallowEqual
    );
    const totalItems = useSelector(
        (state: RootState) => state.getExpenses.totalItems,
        shallowEqual
    );
    const [search, setSearch] = useState('');
    const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilterData] = useState<IFilterCashFlow>({} as IFilterCashFlow);
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );
    const branchId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId ?? null,
        shallowEqual
    );

    useEffect(() => {
        if (branchId) {
            dispatch(getExpensesAction(branchId, search, ITEMS_PER_PAGE, currentPage, token));
        }
    }, [branchId, search, currentPage, dispatch, token]);

    const handleToggle = (id: number) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const dataToDisplay = expenseData ? expenseData : [];

    const fileLink = useSelector(
        (state: RootState) => state.getExpenseInvoiceReducer.expenseInvoice.expenseInvoiceUrl
    );

    const handleInvoiceDownLoad = (id: number) => {
        setExpenseId(id);
        setFile(fileLink);
    };

    useEffect(() => {
        dispatch(getExpenseInvoiceAction(expenseId, token));
    }, [expenseId, file, fileLink]);

    const expenseDetails = (id: number) => {
        dispatch(getExpenseDetailssAction(id, token));
    };

    useEffect(() => {
        if (branchId) {
            dispatch(getExpensesAction(branchId, search, ITEMS_PER_PAGE, currentPage, token));
        }
    }, [branchId, search, currentPage, token, dispatch]);

    return (
        <ContainerFlex FlexDir="column" Gap="16px" Align="start" Justify="start">
            <SearchFilterTrasn
                search={search}
                setSearch={setSearch}
                filterData={filteredData}
                setFilterData={setFilterData}
            />
            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="repeat(7, 1fr)"
                    Border="0 0 1px 0 solid red"
                    Height="40px"
                    Gap="8px"
                >
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.idReferenceNumber}
                    </Text>
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.date}
                        <ImageIcon src={UnfoldMore} margin="0 5px" />
                    </Text>
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.total}
                    </Text>
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.provider}
                    </Text>
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.paymentMethod}
                    </Text>
                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                        {EXPENSES.responsible}
                    </Text>
                </TableRowHead>
                <TableItemsContainer Background="none">
                    {dataToDisplay.map((i: IExpenseData) => (
                        <TableRowItems
                            key={i.id}
                            Height="40px"
                            Padding="0 0.5rem"
                            GridColumn="repeat(7, 1fr)"
                            BorderB="0.5px solid #e4e7e9"
                            Border=""
                        >
                            <Text FontSize="0.875rem" Color="#54575C">
                                {i.id}
                            </Text>
                            <Text FontSize="0.875rem" Color="#54575C">
                                {i.date}
                            </Text>
                            <Text FontSize="0.875rem" Color="#54575C">
                                {`$${i.amount.toLocaleString('en-US')}`}
                            </Text>
                            <Text FontSize="0.875rem" Color="#54575C">
                                {i.supplier}
                            </Text>
                            <Text FontSize="0.875rem" Color="#54575C">
                                {i.paymentType}
                            </Text>

                            <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                {i.responsible}
                            </Text>
                            <ContainerFlex Position="relative" OverFlow="visible">
                                <ImageLogo
                                    src={moreVertIcon}
                                    alt="more"
                                    Width="24px"
                                    Height="24px"
                                    Margin="auto"
                                    Cursor="pointer"
                                    onClick={() => handleToggle(i.id)}
                                />
                                {openDropdownId === i.id && (
                                    <ExpensesMenu
                                        file={file}
                                        downloadInvoice={() => handleInvoiceDownLoad(i.id)}
                                        openExpenseDetails={() => expenseDetails(i.id)}
                                    />
                                )}
                            </ContainerFlex>
                        </TableRowItems>
                    ))}
                </TableItemsContainer>
            </Table>

            <ContainerFlex Justify="center">
                <PaginatedForTransactionTable
                    pageNumber={currentPage}
                    totalPage={totalPages}
                    totalItems={totalItems}
                    firstPage={() => setCurrentPage(1)}
                    lastPage={() => setCurrentPage(totalPages)}
                    nextPage={handleNextPage}
                    prevPage={handlePrevPage}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Expenses;
