export const data = [
    {
        id: '0',
        name: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=2022749a-d379-4d35-b964-f0a5d8c9a2a4&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Sony WH-CH700N',
        apart: '$ 12.00',
        price: '$ 120',
    },
    {
        id: '1',
        name: 'Audio',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=46ca759a-78b3-49a5-a1a8-ab18301fb43f&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'SNAG portable Blueto...',
        apart: '$ 7.00',
        price: '$70.00',
    },
    {
        id: '2',
        name: 'Smart Watch',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=59eb21e7-dafd-48e9-bfb6-992119fd21b8&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Apple Watch Series 5',
        apart: '$ 32.00',
        price: '$ 320.00',
    },
    {
        id: '3',
        name: 'Cámaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=45c790f0-bb54-4eb2-a5a7-1c2ae64f01c3&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Cámara Home A 310 Re...',
        apart: '$ 3.00',
        price: '$ 30.00',
    },
    {
        id: '4',
        name: 'TV',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=5185b09f-c10f-4003-82a5-6f36937b6678&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Sony X800H 43 Inc TV...',
        apart: '$ 50',
        price: '$ 500',
    },
    {
        id: '5',
        name: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=2022749a-d379-4d35-b964-f0a5d8c9a2a4&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Sony WH-CH700N',
        apart: '$ 12.00',
        price: '$ 120',
    },
    {
        id: '6',
        name: 'Audio',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=46ca759a-78b3-49a5-a1a8-ab18301fb43f&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'SNAG portable Blueto...',
        apart: '$ 7.00',
        price: '$70.00',
    },
    {
        id: '7',
        name: 'Smart Watch',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=59eb21e7-dafd-48e9-bfb6-992119fd21b8&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Apple Watch Series 5',
        apart: '$ 32.00',
        price: '$ 320.00',
    },
    {
        id: '8',
        name: 'Cámaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=45c790f0-bb54-4eb2-a5a7-1c2ae64f01c3&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Cámara Home A 310 Re...',
        apart: '$ 3.00',
        price: '$ 30.00',
    },
    {
        id: '9',
        name: 'TV',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=5185b09f-c10f-4003-82a5-6f36937b6678&api_key=CometServer1&access_token=1623377487_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_3c9edd2b6333b1ede21cbdfe0c49036f83dc90f7',
        description: 'Sony X800H 43 Inc TV...',
        apart: '$ 50',
        price: '$ 500',
    },
];
