import {
    POST_CREATE_USER_BRANCH,
    POST_CREATE_USER_BRANCH_ERROR,
    POST_CREATE_USER_BRANCH_FINISH,
    POST_CREATE_USER_BRANCH_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    createUser: false,
};

const CreateUserBranch = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CREATE_USER_BRANCH:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_CREATE_USER_BRANCH_SUCCESS:
            return {
                ...state,
                idBranch: action.payload.data,
            };
        case POST_CREATE_USER_BRANCH_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_CREATE_USER_BRANCH_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default CreateUserBranch;
