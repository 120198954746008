import axios, {AxiosResponse} from 'axios';
import {
    GET_BRANCH_SCHEDULE,
    GET_BRANCH_SCHEDULE_ERROR,
    GET_BRANCH_SCHEDULE_FINISH,
    GET_BRANCH_SCHEDULE_SUCCESS,
    GET_BRANCH_SCHEDULE_RESET,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetBranchScheduleStart = () => {
    return {
        type: GET_BRANCH_SCHEDULE,
    };
};
export const fetchGetBranchScheduleSuccess = (result: AxiosResponse) => {
    return {
        type: GET_BRANCH_SCHEDULE_SUCCESS,
        payload: result,
    };
};
export const fetchGetBranchScheduleError = () => {
    return {
        type: GET_BRANCH_SCHEDULE_ERROR,
    };
};
export const fetchGetBranchScheduleFinish = () => {
    return {
        type: GET_BRANCH_SCHEDULE_FINISH,
    };
};
export const fetchGetBranchScheduleReset = () => {
    return {
        type: GET_BRANCH_SCHEDULE_RESET,
    };
};

export function GetBranchSchedule(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetBranchScheduleStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchSchedule}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetBranchScheduleSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetBranchScheduleError());
        } finally {
            dispatch(fetchGetBranchScheduleFinish());
        }
    };
}
