import {
    DELETE_ITEM_PLEDGE,
    DELETE_ITEM_PLEDGE_SUCCESS,
    DELETE_ITEM_PLEDGE_ERROR,
} from '@components/ShoppingCart/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
};

const DeleteItemPladge = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case DELETE_ITEM_PLEDGE:
            return {
                ...state,
                loading: true,
            };
        case DELETE_ITEM_PLEDGE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_ITEM_PLEDGE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default DeleteItemPladge;
