import {
    GET_TAXDATA_START,
    GET_TAXDATA_SUCCESS,
    GET_TAXDATA_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {TaxDataAction, ICompanyTaxData} from '@components/AccountSettings/Redux/interfaces';

const initialState: ICompanyTaxData = {
    loading: false,
    error: null,
    message: '',
    taxData: {
        companyId: 0,
        rfc: '',
        taxEmail: '',
        companyAddress: '',
    },
};

const GetCompanyTaxData = (state = initialState, action: TaxDataAction): ICompanyTaxData => {
    switch (action.type) {
        case GET_TAXDATA_START:
            return {
                ...state,
                loading: true,
                error: null,
                message: '',
            };
        case GET_TAXDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                taxData: action.payload.data.data,
                message: action.payload.data.message,
            };
        case GET_TAXDATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default GetCompanyTaxData;
