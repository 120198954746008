import * as yup from 'yup';

export const SchemaFormCloseBranch = yup.object().shape({
    diferenceValue: yup
        .string()
        .test('is-less-than-million', 'El valor debe ser menor o igual a un millón', (value) => {
            if (!value) return true;
            const numericValue = Number(value.replace(/[^0-9.-]+/g, ''));
            return numericValue <= 1000000;
        })
        .required('Este campo es obligatorio'),
    observations: yup
        .string()
        .required('Ingresa una descripción')
        .max(64)
        .matches(/^[A-Za-z0-9Ññ][A-Za-z0-9Ññ -.]*$/, 'No se permiten caracteres especiales'),
});
