import React, {ChangeEvent, useRef, useState, useCallback} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ArchiveInputContainer} from '@/components/Quoter/Steps/styles';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import addFile from '@images/addUploadIcone.svg';
import {IExpenseDetailFile} from '@/components/RequestNewCatalogLevel/operational/interface';
import {MOD_TEXT, UPLOAD} from '@/components/RequestNewCatalogLevel/operational/constant';

const UploadFile = (props: IExpenseDetailFile) => {
    const {selectedFile, setSelectedFile} = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileProperties, setFileProperties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            if (file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setFileProperties(file);
                        setErrorFile(false);
                        setErrorMessage('');
                    }
                };
            } else {
                setErrorFile(true);
                setErrorMessage(MOD_TEXT.ERROR);
            }
        }
    };

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            const file = event.dataTransfer.files?.[0];
            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setFileProperties(file);
                        setErrorFile(false);
                        setErrorMessage('');
                    }
                };
            } else {
                setErrorFile(true);
                setErrorMessage(MOD_TEXT.ERROR);
            }
        },
        [setSelectedFile]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    return (
        <ContainerFlex Height="" FlexDir="column">
            <ArchiveInputContainer
                Height="104px"
                Width="100%"
                error={errorFile}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <input
                    type="file"
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                <ContainerFlex FlexDir="column">
                    {selectedFile && selectedFile.length > 0 ? (
                        <ContainerFlex ColumnGap="8px" Justify="start">
                            <ContainerFlex
                                Cursor="pointer"
                                Justify="space-between"
                                Padding="0 18px 0 8px"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <ContainerFlex Align="center">
                                    <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                        {fileProperties !== null ? fileProperties.name : ''}
                                    </Text>
                                    <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                        {fileProperties !== null
                                            ? (fileProperties.size / (1024 * 1024)).toFixed(2)
                                            : 0}
                                        {UPLOAD.MB}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="end">
                                    <Text FontSize="0.75rem" Color="#5A5AFF" FontWeight="500">
                                        {UPLOAD.RESET}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex
                            Gap="16px"
                            Padding="24px"
                            FlexDir="column"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <ContainerFlex Height="max-content">
                                <ImageLogo src={addFile} />
                                <Text Color="#5A5AFF" FontSize="1rem">
                                    {UPLOAD.ADD}
                                </Text>
                            </ContainerFlex>

                            <Text FontSize="1rem" Color="#5A5AFF">
                                {UPLOAD.MB}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ArchiveInputContainer>
            <ContainerFlex Height="max-content" Justify="start">
                {errorFile && (
                    <Text FontSize="0.75rem" Color="#FF6357">
                        {errorMessage}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default UploadFile;
