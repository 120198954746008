import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@/config/store';
import {ContainerFlex, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {IconSvg} from '@Quoter/Icons/styles';
import {formJobLoans, LadaDefault} from '@components/PersonalLoans/constants';
import {JobLoansProps} from '@components/PersonalLoans/interfaces';
import {PersonalLoansJob} from '@components/PersonalLoans/PersonalLoansJob';
import {schemaJobLoans} from '@components/PersonalLoans/schemaPersonalLoans';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {GetAllEmploymentSituation} from '@components/PersonalLoans/Redux/Actions/GetAllCatEmploymentSituation';
import {GetAllCatLaborOld} from '@components/PersonalLoans/Redux/Actions/GetAllCatLaborOld';
import {AddEmploymentSituationForm} from '@components/PersonalLoans/Redux/Actions/CreateEmploymentSituation';
import {AddJobDataLoans} from '@components/PersonalLoans/Redux/Actions/SaveLoansJob';

export const PersonalLoansStepTwo = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const IPersonalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId;
    });
    const saveJobLoans = useSelector((state: RootState) => state.SaveLoansJob.jobLoans);
    const dispatch = useDispatch();
    const [nationSelected, setNationSelected] = useState<IGetAllNations>(LadaDefault);
    const {
        handleSubmit,
        register,
        control,
        watch,
        reset,
        formState: {errors},
    } = useForm<JobLoansProps>({
        resolver: yupResolver(schemaJobLoans),
        defaultValues: formJobLoans,
    });
    const {
        personalInformationId = saveJobLoans.personalInformationId,
        catEmploymentSituationId = saveJobLoans.catEmploymentSituationId,
        nameCompany = saveJobLoans.nameCompany,
        addresCompany = saveJobLoans.addresCompany,
        phoneCompany = saveJobLoans.phoneCompany,
        job = saveJobLoans.job,
        catLaborOldId = saveJobLoans.catLaborOldId,
        monthlyIncome = saveJobLoans.monthlyIncome,
    } = saveJobLoans || formJobLoans;
    useEffect(() => {
        if (token) {
            dispatch(GetAllEmploymentSituation(token));
            dispatch(GetAllCatLaborOld(token));
        }
        reset({
            personalInformationId,
            catEmploymentSituationId,
            nameCompany,
            addresCompany,
            phoneCompany,
            job,
            catLaborOldId,
            monthlyIncome,
        });
    }, []);

    const formData = watch();
    useEffect(() => {
        const handleBeforeUnload = (e: {returnValue: string; preventDefault: () => void}) => {
            dispatch(AddJobDataLoans(formData));
            e.preventDefault();
            e.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData, dispatch]);

    const onSubmit = (data: JobLoansProps) => {
        dispatch(AddJobDataLoans(data));
        dispatch(
            AddEmploymentSituationForm(
                {
                    ...data,
                    catLadaId: nationSelected?.lada,
                    personalInformationId: Number(IPersonalInformationId),
                    catLaborOldId: data.catLaborOldId.value,
                    catEmploymentSituationId: data.catEmploymentSituationId.value,
                },
                token
            )
        );
    };

    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            BackG="none"
            Align="start"
            FlexDir="column"
            Justify="start"
            Height="auto"
            Gap="1.5rem"
        >
            <PersonalLoansJob
                control={control}
                errors={errors}
                register={register}
                setNationSelected={setNationSelected}
            />
            <ContainerFlex Justify="space-between">
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    Width="12.313rem"
                    onClick={() => dispatch(ActualDataStepLoans(1))}
                    SecondaryButton="#FFFFFF"
                    FillIcon="#5A5AFF"
                >
                    {' '}
                    <IconSvg Height="1.5rem" Width="1.5rem">
                        <path
                            d="M14.6 5.99994L16 7.39994L11.4 11.9999L16 16.5999L14.6 17.9999L8.6 11.9999L14.6 5.99994Z"
                            fill="#5A5AFF"
                        />
                    </IconSvg>
                    {'Paso anterior'}
                </ButtonGenerals>
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    Width="12.313rem"
                    type="submit"
                >
                    {'Continuar'}
                    <IconSvg Height="1.5rem" Width="1.5rem">
                        <path
                            d="M9.9 18.0314L8.5 16.6314L13.1 12.0314L8.5 7.43137L9.9 6.03137L15.9 12.0314L9.9 18.0314Z"
                            fill="white"
                        />
                    </IconSvg>
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerForm>
    );
};
