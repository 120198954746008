import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {IRowSchedule, IScheduleBranch} from '@components/Branches/interfaces';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {TimePickerBranch} from '@components/Branches/ManageBranch/Schedules/TimePickerBranch';

export const RowSchedule = ({item, scheduleUpdated, setScheduleUpdated}: IRowSchedule) => {
    const [startHour, setStartHour] = useState<DateTime | null>(
        item.startHour ? DateTime.fromFormat(item.startHour, 'h:mm') : null
    );
    const [endHour, setEndHour] = useState<DateTime | null>(
        item.endHour ? DateTime.fromFormat(item.endHour, 'h:mm') : null
    );
    const handleStatus = (id: number) => {
        setScheduleUpdated(
            scheduleUpdated.map((operation: IScheduleBranch) =>
                operation.operationId === id ? {...operation, status: !operation.status} : operation
            )
        );
    };
    return (
        <ContainerFlex Justify="flex-start" ColumnGap="16px" Padding="16px 0 0 0">
            <Text FontSize="0.875rem" Width="70px" FontWeight="500">
                {item.dayName}
            </Text>
            <GreenSwitch
                size="small"
                checked={item.status}
                onClick={() => handleStatus(item.operationId)}
            />
            <Text FontSize="0.875rem" Width="55px">
                {!item.status ? 'Cerrado' : 'Abierto'}
            </Text>
            <ContainerFlex Height="18px" Width="" Margin="0 8px" Bl="1px solid#a7a9ac" />
            {!item.status ? (
                <>
                    <TimePickerBranch
                        startHour={startHour}
                        endHour={endHour}
                        setStartHour={setStartHour}
                        setEndHour={setEndHour}
                        isStart={true}
                        id={item.operationId}
                        scheduleUpdated={scheduleUpdated}
                        setScheduleUpdated={setScheduleUpdated}
                        disabled={true}
                    />
                    <Text FontSize="0.875rem">-</Text>
                    <TimePickerBranch
                        startHour={startHour}
                        endHour={endHour}
                        setStartHour={setStartHour}
                        setEndHour={setEndHour}
                        isStart={false}
                        id={item.operationId}
                        scheduleUpdated={scheduleUpdated}
                        setScheduleUpdated={setScheduleUpdated}
                        disabled={true}
                    />
                </>
            ) : (
                <>
                    <TimePickerBranch
                        startHour={startHour}
                        endHour={endHour}
                        setStartHour={setStartHour}
                        setEndHour={setEndHour}
                        isStart={true}
                        id={item.operationId}
                        scheduleUpdated={scheduleUpdated}
                        setScheduleUpdated={setScheduleUpdated}
                    />
                    <Text FontSize="0.875rem">-</Text>
                    <TimePickerBranch
                        startHour={startHour}
                        endHour={endHour}
                        setStartHour={setStartHour}
                        setEndHour={setEndHour}
                        isStart={false}
                        id={item.operationId}
                        scheduleUpdated={scheduleUpdated}
                        setScheduleUpdated={setScheduleUpdated}
                    />
                </>
            )}
        </ContainerFlex>
    );
};
