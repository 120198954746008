import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_LIST_USER_NAMES_START,
    GET_LIST_USER_NAMES_SUCCESS,
    GET_LIST_USER_NAMES_ERROR,
    RESET_DATA_USERNAME,
} from '@components/CreateUsers/Redux/Types/Types';
import {AppDispatch} from '@/config/store';

export const fetchGetOptionsUserNamesStart = () => {
    return {
        type: GET_LIST_USER_NAMES_START,
    };
};

export const fetchGetOptionsUserNamesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_LIST_USER_NAMES_SUCCESS,
        payload: result,
    };
};

export const fetchGetOptionsUserNamesError = () => {
    return {
        type: GET_LIST_USER_NAMES_ERROR,
    };
};

export function GetOptionsNames(token: string, inputName: string, inputLastName: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetOptionsUserNamesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetOptionsUserNames}?name=${inputName}&lastname=${inputLastName}`,
                {headers}
            );

            dispatch(fetchGetOptionsUserNamesSuccess(response));
        } catch (error) {
            dispatch(fetchGetOptionsUserNamesError());
        }
    };
}

export const resetOptionsUserNames = () => {
    return {
        type: RESET_DATA_USERNAME,
    };
};
