import {
    VERIFY_CODE_NEW_REQUEST_CLIENT,
    VERIFY_CODE_NEW_SUCCESS_CLIENT,
    VERIFY_CODE_NEW_ERROR_CLIENT,
} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    email: null,
    error: null,
};

export const EmailResetCodeClient = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CODE_NEW_REQUEST_CLIENT:
            return {...state, loading: true};
        case VERIFY_CODE_NEW_SUCCESS_CLIENT:
            return {...state, loading: false, userData: action.payload.data.data};
        case VERIFY_CODE_NEW_ERROR_CLIENT:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
