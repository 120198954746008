export const GET_SECURITYBAG_WEIGHT = 'GET_SECURITYBAG_WEIGHT';
export const GET_SECURITYBAG_WEIGHT_SUCCESS = 'GET_SECURITYBAG_WEIGHT_SUCCESS';
export const GET_SECURITYBAG_WEIGHT_ERROR = 'GET_SECURITYBAG_WEIGHT_ERROR';

export const GET_SECURITYBAG_SMELTING = 'GET_SECURITYBAG_SMELTING';
export const GET_SECURITYBAG_SMELTING_SUCCESS = 'GET_SECURITYBAG_SMELTING_SUCCESS';
export const GET_SECURITYBAG_SMELTING_ERROR = 'GET_SECURITYBAG_SMELTING_ERROR';

export const GET_SECURITYBAG_WEIGHT_EDIT = 'GET_SECURITYBAG_WEIGHT_EDIT';
export const GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS = 'GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS';
export const GET_SECURITYBAG_WEIGHT_EDIT_ERROR = 'GET_SECURITYBAG_WEIGHT_EDIT_ERROR';

export const POST_SECURITYBAG_WEIGHT_EDIT = 'POST_SECURITYBAG_WEIGHT_EDIT';
export const POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS = 'POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS';
export const POST_SECURITYBAG_WEIGHT_EDIT_ERROR = 'POST_SECURITYBAG_WEIGHT_EDIT_ERROR';

export const GET_IDENTIFY_FOUNDRY = 'GET_IDENTIFY_FOUNDRY';
export const GET_IDENTIFY_FOUNDRY_SUCCESS = 'GET_IDENTIFY_FOUNDRY_SUCCESS';
export const GET_IDENTIFY_FOUNDRY_ERROR = 'GET_IDENTIFY_FOUNDRY_ERROR';

export const DELETE_SECURITYBAG_WEIGHT = 'DELETE_SECURITYBAG_WEIGHT';
export const DELETE_SECURITYBAG_WEIGHT_SUCCESS = 'DELETE_SECURITYBAG_WEIGHT_SUCCESS';
export const DELETE_SECURITYBAG_WEIGHT_ERROR = 'DELETE_SECURITYBAG_WEIGHT_ERROR';
