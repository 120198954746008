import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import CreditRequestProfile from '@/components/CreditsAndPawns/CreditRequest/CreditRequestProfile';
import addPlusIconLight from '@components/CreditsAndPawns/icons/addPlusIconLight.svg';
import helpIcon from '@components/CreditsAndPawns/icons/helpIcon.svg';
import CreditRequestHeader from '@/components/CreditsAndPawns/CreditRequest/CreditRequestHeader';
import CreditRequestInformation from '@/components/CreditsAndPawns/CreditRequest/CreditRequestInformation';
import CreditRequestNotes from '@/components/CreditsAndPawns/CreditRequest/CreditRequestNotes';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {getDetailsCreditAction} from '@components/CreditsAndPawns/Redux/Actions/GetDetailsCreditActions';
import {CREDIT_REQUEST_FOOTER} from '@components/CreditsAndPawns/constants';
import CustomerInfoModal from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModal';

const CreditRequest = () => {
    const {id} = useParams<{id?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token && id) {
            dispatch(getDetailsCreditAction({token, creditId: id}));
        }
    }, [id]);
    const {error, data} = useSelector((state: RootState) => {
        return state.getDetailsCredit;
    });
    const {openModal} = useSelector((state: RootState) => {
        return state.getCustomerInfo;
    });

    return (
        <ContainerFlex FlexDir="column" Align="start" Justify="space-between" Height="max-content">
            <ContainerFlex
                FlexDir="column"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1rem"
                Height="100%"
            >
                <ContainerFlex FlexDir="row" Align="start" Justify="start" Height="78px">
                    <CreditRequestHeader solicitudeNumber={data?.solicitudeNumber ?? ''} />
                </ContainerFlex>
                <ContainerFlex FlexDir="row" Align="start" Justify="start" Height="100%" Gap="16px">
                    {error && <Text>Ops se ha presentado un error....</Text>}
                    {data && (
                        <>
                            <ContainerFlex Padding="0px" Justify="start" Align="start">
                                <CreditRequestProfile {...data} />
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Padding="0px"
                                Justify="start"
                                Align="start"
                                Gap="24px"
                            >
                                <CreditRequestInformation
                                    creditRequestData={data}
                                    solicitudeId={parseInt(id as string, 10)}
                                />
                                <CreditRequestNotes />
                            </ContainerFlex>
                        </>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="start" Gap="1px" backG="#F4F5F5" Height="36px" Width="100%">
                <ContainerFlex
                    Gap="0.5rem"
                    Width="214px"
                    Padding="0.5rem 1.5rem"
                    Bt="1.5px solid #5A5AFF"
                    Cursor="pointer"
                    backG="#fff"
                >
                    <Text
                        FontFamily="Work Sans"
                        FontSize="1.0rem"
                        FontStyle="normal"
                        Color="#5A5AFF"
                        FontWeight="500"
                        Cursor="pointer"
                        LHeight="16px"
                    >
                        {CREDIT_REQUEST_FOOTER.TAB_TITLE}
                    </Text>
                    <Image Width="20px" Height="20px" src={helpIcon} />
                </ContainerFlex>
                <ContainerFlex
                    Padding="0.5rem 1.5rem"
                    Width="68px"
                    Bt="1.5px solid #D4D6D8"
                    backG="#fff"
                    Cursor="pointer"
                >
                    <Image Width="20px" Height="20px" src={addPlusIconLight} />
                </ContainerFlex>
            </ContainerFlex>
            {openModal && <CustomerInfoModal />}
        </ContainerFlex>
    );
};

export default CreditRequest;
