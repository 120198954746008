import {
    GET_ORGANIZATIONS,
    GET_ORGANIZATIONS_SUCCESS,
    GET_TREE_SUCCESS,
    GET_ORGANIZATIONS_ERROR,
    RESET_TREE,
} from '@General/BranchTree/Redux/Types';
import {DataResponse} from '@General/BranchTree/interfaces';

const initialState = {
    loading: false,
    tree: [],
    copyTree: [],
    error: false,
    data: [],
};

const getOrganizationsTree = (state = initialState, action: DataResponse) => {
    switch (action.type) {
        case GET_ORGANIZATIONS:
            return {...state, loading: true, error: false};
        case GET_ORGANIZATIONS_SUCCESS:
            return {...state, loading: false, data: action.payload.data};
        case GET_TREE_SUCCESS:
            return {
                ...state,
                loading: false,
                tree: action.payload.data,
                copyTree: action.payload.data,
            };
        case GET_ORGANIZATIONS_ERROR:
            return {...state, error: true};
        case RESET_TREE:
            return initialState;
        default:
            return state;
    }
};

export default getOrganizationsTree;
