import React, {useState} from 'react';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {ContainerFlex, ContainerGrid, Text} from '@components/Shopify/Ecommerce/styles';
import addRole from '@images/addRole.svg';
import {ImageLogo} from '@components/LoginClient/styles';
import {addUserText, noAddress, singleAddress} from '@/components/NewUser/constants';
import {Controller, useFormContext} from 'react-hook-form';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import questionImg from '@images/questionMark.svg';

const AddressForm = () => {
    const {control, register} = useFormContext();
    const [addressQuantity, setAddressQuantity] = useState(0);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="8px">
            <ContainerFlex FlexDir="column" Align="start">
                <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F" Height="2rem">
                    {addUserText.address}
                </Text>
                <Text color="#6D6E71" FontSize="1rem" Height="2rem">
                    {addUserText.addressText}
                </Text>
                <Text
                    onClick={() =>
                        setAddressQuantity(
                            addressQuantity <= noAddress
                                ? noAddress
                                : addressQuantity - singleAddress
                        )
                    }
                    Color="#FF6357"
                    Position="relative"
                    PositionLeft="100rem"
                    PositionBottom="3rem"
                    Cursor="pointer"
                >
                    {addUserText.deleteAddress}
                </Text>
            </ContainerFlex>

            {Array.from({length: addressQuantity}).map((e, i) => (
                <ContainerGrid GridColumns="1fr 1fr .5fr .5fr" Gap="8px" key={i}>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.cp}
                        </Text>

                        <Input {...register('cp')} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.street}
                        </Text>

                        <Input {...register('street')} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.noExt}
                        </Text>
                        <Input {...register('noExterior')} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.noInt}
                        </Text>
                        <Input {...register('noIntern')} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.municipality}
                        </Text>
                        <ContainerFlex>
                            <Controller
                                name="municipality"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <SelectGeneral {...field} value={value} onChange={onChange} />
                                )}
                            />
                            <ImageLogo
                                src={questionImg}
                                Position="relative"
                                Height="20px"
                                Width="20px"
                                Right="4rem"
                                alt="QuestionMark"
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.colony}
                        </Text>
                        <ContainerFlex>
                            <Controller
                                name="colony"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <SelectGeneral {...field} value={value} onChange={onChange} />
                                )}
                            />
                            <ImageLogo
                                src={questionImg}
                                Position="relative"
                                Height="20px"
                                Width="20px"
                                Right="4rem"
                                alt="QuestionMark"
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Align="start"
                        gridColumnStart="3"
                        gridColumnEnd="5"
                    >
                        <Text Color="#6D6E71" FontWeight="600">
                            {addUserText.state}
                        </Text>
                        <ContainerFlex>
                            <Controller
                                name="state"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <SelectGeneral {...field} value={value} onChange={onChange} />
                                )}
                            />
                            <ImageLogo
                                src={questionImg}
                                Position="relative"
                                Height="20px"
                                Width="20px"
                                Right="4rem"
                                alt="QuestionMark"
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerGrid>
            ))}
            <Text
                Color="#5A5AFF"
                Gap="10px"
                Cursor="pointer"
                onClick={() => setAddressQuantity(addressQuantity + singleAddress)}
                MinH="30px"
            >
                <ImageLogo src={addRole} Height="14px" Width="14px" alt="AddIcon" />
                {addUserText.addAddress}
            </Text>
        </ContainerFlex>
    );
};

export default AddressForm;
