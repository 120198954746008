import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerForm,
    TextArea,
} from '@/components/Shopify/Ecommerce/styles';
import {
    BUTTON,
    MODAL_FUNDING_BRANCH,
    MODAL_MOTION,
    DATA_TYPE_CASH,
} from '@/components/CashFlowActions/Operational/Constants';
import UploadFile from '@/components/CashFlowActions/UploadFile';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {CustomSelect} from '@/components/CashFlowActions/InputSelect';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {validationRegisterMotions} from '@/components/CashFlowActions/Operational/ValidationYup';
import {ClosedModalProps, Employee} from '@/components/CashFlowActions/Operational/interfaces';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {TextMotion} from '@/components/CashFlowActions/TextMotion';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const ModalRegisterMotion = ({isOpen, closeModal}: ClosedModalProps) => {
    const employeeData = useSelector(
        (state: RootState) => state.employeeCashFunding.userData as Employee[]
    );
    const mappedEmployeeOptions = employeeData?.map((employee) => ({
        label: employee.userEmployeeName,
        value: employee.id,
    }));

    const formatCurrency = useFormatCurrency();
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(validationRegisterMotions),
        defaultValues: {
            type: '',
            box: '',
            amount: '',
            description: '',
        },
    });
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
            reset();
        }
    };
    const onSubmit = () => {
        closeModal();
        reset();
    };
    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                >
                    <TextMotion />
                    <ContainerForm
                        Padding="24px"
                        Gap="24px"
                        FlexDir="column"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ContainerFlex
                            Gap="8px"
                            Height="max-content"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_MOTION.TYPE}
                            </Text>
                            <Controller
                                name="type"
                                control={control}
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={DATA_TYPE_CASH}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.type && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.type.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_MOTION.BOX}
                            </Text>
                            <Controller
                                name="box"
                                control={control}
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={mappedEmployeeOptions}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.box && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.box.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_MOTION.MONT}
                            </Text>
                            <Controller
                                name="amount"
                                control={control}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        onBlur={(e) => {
                                            const formattedValue = formatCurrency(e.target.value);
                                            field.onChange(formattedValue);
                                        }}
                                        placeholder="$0.00"
                                        value={field.value}
                                        type="text"
                                        TextAlign="start"
                                    />
                                )}
                            />
                            {errors.amount && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.amount.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_MOTION.DESCRIPTION}
                                <Text MarginLeft="8px" FontSize="0.75rem">
                                    {MODAL_FUNDING_BRANCH.OPTION}
                                </Text>
                            </Text>
                            <Controller
                                name="description"
                                control={control}
                                render={({field}) => <TextArea {...field} Height="6.5rem" />}
                            />
                            {errors.description && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.description.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_FUNDING_BRANCH.ADJUNTOS}
                                <Text MarginLeft="8px" FontSize="0.75rem">
                                    {MODAL_FUNDING_BRANCH.OPTION}
                                </Text>
                            </Text>
                            <UploadFile
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />
                            <Text FontSize="0.875" FontWeight="400">
                                {MODAL_FUNDING_BRANCH.UNTIL}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                onClick={() => closeModal()}
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                type="button"
                            >
                                {BUTTON.CLOSE}
                            </ButtonGenerals>
                            <ButtonGenerals Width="100%" type="submit">
                                {BUTTON.REGISTER}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
