import React, {useState} from 'react';
import {AppDispatch, RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';

import {MY_COMPANY} from '@MyCompany/constants';
import {Checkbox} from '@General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {downloadInfoCompany} from '@MyCompany/Redux/Actions/UpdateImgCompany';

export const PrivacityCompany = () => {
    const dispatch: AppDispatch = useDispatch();
    const {companyPrivacy} = useSelector((state: RootState) => state.detailsCompany);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const addIdsPrivacy = companyPrivacy.map((i) => i.privacyId);
    const [checkPrivacy, setCheckPrivacy] = useState<number[]>(addIdsPrivacy);

    const addIdPrivacy = (id: number) => {
        if (!checkPrivacy.includes(id)) setCheckPrivacy([...checkPrivacy, id]);
        if (checkPrivacy.includes(id))
            setCheckPrivacy(checkPrivacy.filter((item: number) => item !== id));
    };

    return (
        <ContainerFlex
            backG="#FFF"
            Radius="1rem"
            Height="auto"
            Gap="1rem"
            Padding="1rem"
            FlexDir="Column"
            Align="start"
        >
            <Text Padding="0.625rem 0" FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                {MY_COMPANY.PRIVACITY_ENTERPRICE}
            </Text>
            {companyPrivacy &&
                companyPrivacy.map((item) => (
                    <ContainerFlex
                        onClick={() => addIdPrivacy(item.privacyId)}
                        key={item.privacyId}
                        Justify="start"
                        Cursor="pointer"
                        Gap="0.5rem"
                        Height="1rem"
                    >
                        <Checkbox checked={checkPrivacy.includes(item.privacyId)} />
                        <Text Cursor="pointer" Color="#2A2C2F">
                            {item.description}
                        </Text>
                    </ContainerFlex>
                ))}
            <Text
                onClick={() => dispatch(downloadInfoCompany(token, companyId))}
                Cursor="pointer"
                Color="#5A5AFF"
                FontSize="0.875rem"
                FontWeight="500"
            >
                {MY_COMPANY.DOWNLOAD_INFO}
            </Text>
            <Text Cursor="pointer" Color="#FF6357" FontSize="0.875rem" FontWeight="500">
                {MY_COMPANY.DELL_INFO}
            </Text>
        </ContainerFlex>
    );
};
