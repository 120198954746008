import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import ProgressBarComponent from '@components/Home/ProgressBarComponent';
import {ProgressBarProp} from '@components/PersonalLoans/interfaces';

export const ProgressBarStep = ({dataStep}: ProgressBarProp) => {
    const calculateProgress = (step: number) => {
        if (step === 1) {
            return 0;
        }
        return ((step - 1) / 3) * 100;
    };

    return (
        <ContainerFlex Justify="start" Align="start" Gap="0.5rem" FlexDir="column">
            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700">
                Paso {dataStep} de 4
            </Text>
            <ProgressBarComponent progress={calculateProgress(dataStep)} />
        </ContainerFlex>
    );
};
