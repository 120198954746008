import React from 'react';

import {Icons} from '@Articles/style';
import {IUpdateCompanyForm} from '@MyCompany/Interface';
import {UPDATE_COMPANY, orderContact} from '@MyCompany/constants';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';

export const UpdateCompanyForm = ({register, errors, contact, setContact}: IUpdateCompanyForm) => {
    const addContact = (type: string, id: number) => {
        if (type === UPDATE_COMPANY.MAIL && contact.mail.length < 3)
            setContact({...contact, mail: [...contact.mail, id]});
        if (type === UPDATE_COMPANY.PHONES && contact.phones.length < 3)
            setContact({...contact, phones: [...contact.phones, id]});
    };

    return (
        <ContainerFlex OverFlowY="auto" Gap="1.5rem" FlexDir="column" Justify="start">
            <ContainerFlex Height="auto" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.NAME}
                </Text>
                <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                        {UPDATE_COMPANY.NAME_COMPANY}
                    </Text>
                    <Input
                        type="text"
                        Padding="12px"
                        FontSize="0.875rem"
                        placeholder=""
                        {...register('nameEnterprice')}
                        error={errors?.nameEnterprice?.message}
                    />
                    {errors?.nameEnterprice?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.nameEnterprice?.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                        {UPDATE_COMPANY.NATURAL_NAME}
                    </Text>
                    <Input
                        type="text"
                        Padding="12px"
                        FontSize="0.875rem"
                        placeholder=""
                        {...register('naturalEnterprice')}
                        error={errors?.naturalEnterprice?.message}
                    />
                    {errors?.naturalEnterprice?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.naturalEnterprice?.message}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.MAIL}
                </Text>
                {contact.mail.map((item) => (
                    <ContainerFlex
                        Height="auto"
                        key={item}
                        Gap="0.25rem"
                        FlexDir="Column"
                        Align="start"
                    >
                        <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                            {orderContact(item) + UPDATE_COMPANY.EMAIL}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder=""
                            {...register(`mail.${item}.email`)}
                            error={errors?.mail?.[item]?.email?.message}
                        />
                        {errors?.mail?.[item]?.email?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors?.mail?.[item]?.email?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
                {contact.mail.length < 3 && (
                    <Text
                        onClick={() => addContact(UPDATE_COMPANY.MAIL, contact.mail.length + 1)}
                        FontWeight="500"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        <Icons className="material-icons" Color="#5A5AFF" MarginRight="0.5rem">
                            add
                        </Icons>
                        {UPDATE_COMPANY.ADD_MAIL}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.PHONES}
                </Text>
                {contact.phones.map((item) => (
                    <ContainerFlex
                        key={item}
                        Height="auto"
                        Gap="0.25rem"
                        FlexDir="Column"
                        Align="start"
                    >
                        <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                            {orderContact(item) + UPDATE_COMPANY.PHONE}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder=""
                            {...register(`phones.${item}.phone`)}
                            error={errors?.phones?.[item]?.phone?.message}
                        />
                        {errors?.phones?.[item]?.phone?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors?.phones?.[item]?.phone?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
                {contact.phones.length < 3 && (
                    <Text
                        onClick={() => addContact(UPDATE_COMPANY.PHONES, contact.phones.length + 1)}
                        FontWeight="500"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        <Icons className="material-icons" Color="#5A5AFF" MarginRight="0.5rem">
                            add
                        </Icons>
                        {UPDATE_COMPANY.ADD_PHONE}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
