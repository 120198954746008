import {RESET_CASHFLOW_ID, SAVE_CASHFLOW_ID} from '@ReduxCashFlowBasic/Types/TypesBranch';
const initialState = {
    cashFlowId: 0,
};
export interface ISaveCashId {
    cashFlowId: number;
}
const getFlowId = (state = initialState, action: {type: string; value: number}) => {
    switch (action.type) {
        case SAVE_CASHFLOW_ID:
            return {
                ...state,
                cashFlowId: action.value,
            };

        case RESET_CASHFLOW_ID:
            return initialState;
        default:
            return state;
    }
};

export default getFlowId;
