import {IReducerType} from '@/components/Branches/interfaces';
import {
    POST_CASHFLOW_SETUP,
    POST_CASHFLOW_SETUP_ERROR,
    POST_CASHFLOW_SETUP_FINISH,
    POST_CASHFLOW_SETUP_RESET,
    POST_CASHFLOW_SETUP_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    createCashSetup: 0,
};

const PostCashFlowSetup = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CASHFLOW_SETUP:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_CASHFLOW_SETUP_SUCCESS:
            return {
                ...state,
                createCashSetup: action.payload.data.data,
            };
        case POST_CASHFLOW_SETUP_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_CASHFLOW_SETUP_FINISH:
            return {
                ...state,
                loading: false,
            };
        case POST_CASHFLOW_SETUP_RESET:
            return initialState;
        default:
            return state;
    }
};

export default PostCashFlowSetup;
