import React from 'react';

import * as content from '@components/CreateUsers/Styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

const RecentActivityUser = () => {
    return (
        <ContainerFlex {...content.contentSection}>
            <Text {...content.subtitles} Padding="0">
                Actividad reciente
            </Text>
            <Text {...content.instructions}>
                Una vez creado, aquí podrás ver actividad de este usuario en la empresa y en el
                sistema.
            </Text>
        </ContainerFlex>
    );
};

export default RecentActivityUser;
