import React, {useState} from 'react';
import {ContainerFlex, Text, Image, Input} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import SearchImg from '@/images/search.svg';
import closed from '@/images/closeBlueIcone.svg';
import Filter from '@images/filter.svg';
import {TEXT_USER} from '@/components/MyCompanyUser/Operational/Constants';
import {ImageLogo} from '@/components/LoginClient/styles';
import retu from '@images/IconeReturn.svg';
import {ModalFilter} from '@/components/MyCompanyUser/ModalFilter';
export const SearchFilterOfUser = ({onSearch}: {onSearch: (searchTerm: string) => void}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [imageSrc, setImageSrc] = useState(SearchImg);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        onSearch(value);
        setImageSrc(value ? closed : SearchImg);
    };
    const handleImageClick = () => {
        if (imageSrc === closed) {
            setSearchTerm('');
            setImageSrc(SearchImg);
            onSearch('');
        }
    };

    return (
        <>
            <ContainerFlex FlexDir="column" Gap="16px">
                <ContainerFlex backG="" Height="40px">
                    <ContainerFlex Width="270px">
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="calc(100% - 24px) 24px"
                            Margin="0 0 4px 0"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0 1rem 0 0"
                            BackG="#FFFFFF"
                            Radius="32px"
                            InputPadding="0 1rem"
                        >
                            <Input
                                type="text"
                                placeholder="Buscar"
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                            <Image
                                alt="icone-search"
                                Width="24px"
                                Height="24px"
                                src={imageSrc}
                                Cursor="pointer"
                                onClick={handleImageClick}
                            />
                        </SearchInput>
                    </ContainerFlex>

                    <ContainerFlex Justify="end">
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            Padding="4px 16px"
                            Width="6.313rem"
                            Height="32px"
                            Cursor="pointer"
                            onClick={openModal}
                        >
                            <Image src={Filter} Cursor="pointer" alt="icone-filter" />
                            <Text
                                FontSize="0.875rem"
                                FontWeight="700"
                                Color="#5A5AFF"
                                Cursor="pointer"
                            >
                                {TEXT_USER.FILTER_BTN}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="space-between">
                    <ContainerFlex Justify="start">
                        <Text>{TEXT_USER.FILTER}</Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="end">
                        <ImageLogo
                            Cursor="pointer"
                            Width="24px"
                            Height="24px"
                            src={retu}
                            alt="icone-return"
                        />
                        <Text
                            onClick={handleImageClick}
                            Cursor="pointer"
                            FontWeight="700"
                            FontSize="0.875rem"
                            Color="#5A5AFF"
                        >
                            {TEXT_USER.FILTERT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ModalFilter isOpen={isModalOpen} closeModal={closeModal} />
        </>
    );
};
