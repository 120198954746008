import {AxiosError, AxiosResponse} from 'axios';
import {
    UPDATE_PERSONAL_LOANS_ERROR,
    UPDATE_PERSONAL_LOANS_START,
    UPDATE_PERSONAL_LOANS_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataPersonalLoans} from '@components/PersonalLoans/interfaces';

const initialState: IDataPersonalLoans = {
    dataLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

const UpdatePersonalLoans = (
    state: IDataPersonalLoans = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataPersonalLoans => {
    switch (action.type) {
        case UPDATE_PERSONAL_LOANS_START:
            return {...state, loading: true, error: false};
        case UPDATE_PERSONAL_LOANS_SUCCESS:
            return {...state, loading: false, error: false, dataLoans: action.payload.data};
        case UPDATE_PERSONAL_LOANS_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default UpdatePersonalLoans;
