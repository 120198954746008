import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    ILoanProductDetailsProps,
    IShowOptionalFields,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {TermNameField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/TermNameField';
import {DescriptionField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DescriptionField';
import {MinAmountField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/MinAmountField';
import {MaxAmountField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/MaxAmountField';
import {PorcentAnualRateField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PorcentAnualRateField';
import {PeriodMinField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PeriodMinField';
import {PeriodMaxField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PeriodMaxField';
import {RequestChannelField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField';
import {CountLimitLoansField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/CountLimitLoansField';
import {BtnShowField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/BtnShowField';
import {DatePickerRange} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/DatePickerRange';

export const LoanProductDetails: React.FC<ILoanProductDetailsProps> = ({
    detailsRef,
    register,
    control,
    errors,
    watch,
    setValue,
    trigger,
    resetField,
    isSubmitted,
}) => {
    const [showOptionalFields, setShowOptionalFields] = useState<IShowOptionalFields>({
        showMaxLoanTerm: false,
        showNoLoanLimit: false,
    });
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={detailsRef}
            >
                <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start" Justify="start">
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {LOAN_DETAILS.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{LOAN_DETAILS.DESCRIPTION}</Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                    <ContainerFlex Gap="1.5rem">
                        <TermNameField register={register} errors={errors} />
                        <DescriptionField errors={errors} control={control} />
                    </ContainerFlex>
                    <ContainerFlex Gap="1.5rem" Justify="start">
                        <MinAmountField errors={errors} control={control} />
                        <MaxAmountField errors={errors} control={control} />
                        <DatePickerRange
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            defaultValue={new Date()}
                            isSubmitted={isSubmitted}
                            trigger={trigger}
                        />
                    </ContainerFlex>
                    <ContainerFlex Gap="1.5rem" Justify="start">
                        <PorcentAnualRateField errors={errors} control={control} />
                        <PeriodMinField errors={errors} control={control} trigger={trigger} />
                        {showOptionalFields.showMaxLoanTerm ? (
                            <PeriodMaxField
                                errors={errors}
                                control={control}
                                trigger={trigger}
                                showOptionalFields={showOptionalFields}
                                setShowOptionalFields={setShowOptionalFields}
                                resetField={resetField}
                            />
                        ) : (
                            <BtnShowField
                                text={LOAN_DETAILS.FIELDS.MAX_TERM_BTN}
                                width={'100%'}
                                onHandleClick={() =>
                                    setShowOptionalFields({
                                        ...showOptionalFields,
                                        showMaxLoanTerm: true,
                                    })
                                }
                            />
                        )}
                    </ContainerFlex>
                    <ContainerFlex Gap="1.5rem" Justify="start" MaxH="90px">
                        <RequestChannelField
                            errors={errors}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            resetField={resetField}
                        />
                        {showOptionalFields.showNoLoanLimit ? (
                            <CountLimitLoansField
                                errors={errors}
                                register={register}
                                showOptionalFields={showOptionalFields}
                                setShowOptionalFields={setShowOptionalFields}
                                resetField={resetField}
                            />
                        ) : (
                            <BtnShowField
                                text={LOAN_DETAILS.FIELDS.NO_LOANS_LIMIT_BTN}
                                width="max-content"
                                onHandleClick={() =>
                                    setShowOptionalFields({
                                        ...showOptionalFields,
                                        showNoLoanLimit: true,
                                    })
                                }
                            />
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
