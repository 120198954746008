import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {MethodPaymentCredits} from '@/components/MyAccount/MyCredit/CreditDetails/MethodPaymentCredits';
import {ResumeTypePay} from '@/components/MyAccount/MyCredit/CreditDetails/ResumeTypePay';
import {ResumeProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {CREDITSROUTE} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
export const ActionPaymentCredits = ({SetSolveResume}: ResumeProps) => {
    return (
        <>
            <Text oFlow="normal" Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                {CREDITSROUTE.TITLE}
            </Text>
            <ContainerFlex Justify="start" Align="start" Gap="0.5rem">
                <MethodPaymentCredits />
                <ResumeTypePay SetSolveResume={SetSolveResume} />
            </ContainerFlex>
        </>
    );
};
