import {AppDispatch} from '@/config/store';
import {
    BALANCE_CASHFLOW,
    BALANCE_CASHFLOW_SUCCESS,
    BALANCE_CASHFLOW_ERROR,
    BALANCE_EXCESS,
    BALANCE_EXCESS_SUCCESS,
    BALANCE_EXCESS_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';

export const balanceCash = () => {
    return {
        type: BALANCE_CASHFLOW,
    };
};

export const balanceCashSucces = (result: {data: AxiosResponse<string>}) => {
    return {
        type: BALANCE_CASHFLOW_SUCCESS,
        payload: result.data,
    };
};

export const balanceCashError = (value: unknown) => {
    return {
        type: BALANCE_CASHFLOW_ERROR,
        payload: value,
    };
};

export function balanceCashFlow(token: string, BranchId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(balanceCash());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.BalanceCashFlow.replace(
                    '{BranchId}',
                    BranchId
                )}`,
                {headers}
            );
            dispatch(balanceCashSucces(response.data));
        } catch (error) {
            dispatch(balanceCashError(error));
        }
    };
}

export const balanceExcess = () => {
    return {
        type: BALANCE_EXCESS,
    };
};

export const balanceExcessSucces = (result: {data: AxiosResponse<string>}) => {
    return {
        type: BALANCE_EXCESS_SUCCESS,
        payload: result.data,
    };
};

export const balanceExcessError = (value: unknown) => {
    return {
        type: BALANCE_EXCESS_ERROR,
        payload: value,
    };
};

export function balanceExcessDeposit(token: string, BranchId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(balanceExcess());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.BalanceExcess.replace(
                    '{BranchId}',
                    BranchId
                )}`,
                {headers}
            );
            dispatch(balanceExcessSucces(response.data));
        } catch (error) {
            dispatch(balanceExcessError(error));
        }
    };
}
