import {
    GET_CHANGE_ROLE_STATE_SUCCESS,
    GET_CHANGE_ROLE_STATE,
    GET_CHANGE_ROLE_STATE_ERROR,
    GET_CHANGE_ROLE_STATE_FINISH,
    POST_DUPLICATE,
    POST_DUPLICATE_SUCCESS,
    POST_DUPLICATE_ERROR,
    POST_DUPLICATE_FINISH,
    POST_DUPLICATE_RESET_MESSAGE,
    RESET_STATE,
} from '@TypesRoles/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    isUpdated: false,
    error: false,
    duplicateLoading: false,
    duplicateSuccess: false,
    duplicateError: false,
    messageDuplicate: false,
};

const UpdateRoleState = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_CHANGE_ROLE_STATE:
            return {
                ...state,
                duplicateError: false,
                error: false,
                loading: true,
            };
        case GET_CHANGE_ROLE_STATE_SUCCESS:
            return {
                ...state,
                isUpdated: action.payload.data,
            };
        case GET_CHANGE_ROLE_STATE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_CHANGE_ROLE_STATE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case POST_DUPLICATE:
            return {
                ...state,
                error: false,
                duplicateError: false,
                duplicateLoading: true,
            };
        case POST_DUPLICATE_SUCCESS:
            return {
                ...state,
                duplicateSuccess: action.payload.data.data,
                messageDuplicate: true,
            };
        case POST_DUPLICATE_ERROR:
            return {
                ...state,
                duplicateError: true,
            };
        case POST_DUPLICATE_FINISH:
            return {
                ...state,
                duplicateLoading: false,
            };
        case POST_DUPLICATE_RESET_MESSAGE:
            return {
                ...state,
                messageDuplicate: false,
            };
        case RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
export default UpdateRoleState;
