import {GET_CLIENT_DETAILS} from '@Shopify/ClientProfile/Redux/types';
import {IClientDetailsRedux, IClientDetailsActions} from '@Shopify/ClientProfile/interfaces';

const initialState: IClientDetailsRedux = {
    clientDetails: null,
    loading: false,
    error: null,
};

const clientDetails = (
    state = initialState,
    action: IClientDetailsActions
): IClientDetailsRedux => {
    switch (action.type) {
        case GET_CLIENT_DETAILS.GET_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CLIENT_DETAILS.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                clientDetails: action.payload,
            };
        case GET_CLIENT_DETAILS.GET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default clientDetails;
