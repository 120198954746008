export const GET_TABLE_COOWNER = 'GET_TABLE_COOWNER';
export const GET_BENEFICIARY = 'GET_BENEFICIARY';
export const GET_IMAGES_QUOTER = 'GET_IMAGES_QUOTER';
export const GET_IMAGES_QUOTER_NO_64 = 'GET_IMAGES_QUOTER_NO_64';

export const GET_ALL_COOWNER = 'GET_ALL_COOWNER';
export const GET_ALL_COOWNER_SUCCESS = 'GET_ALL_COOWNER_SUCCESS';
export const GET_ALL_COOWNER_ERROR = 'GET_ALL_COOWNER_ERROR';

export const POST_COOWNER = 'POST_COOWNER';
export const POST_COOWNER_SUCCESS = 'POST_COOWNER_SUCCESS';
export const POST_COOWNER_ERROR = 'POST_COOWNER_ERROR';

export const PUT_COOWNER = 'PUT_COOWNER';
export const PUT_COOWNER_SUCCESS = 'PUT_COOWNER_SUCCESS';
export const PUT_COOWNER_ERROR = 'PUT_COOWNER_ERROR';

export const DELETE_COOWNER = 'DELETE_COOWNER';
export const DELETE_COOWNER_SUCCESS = 'DELETE_COOWNER_SUCCESS';
export const DELETE_COOWNER_ERROR = 'DELETE_COOWNER_ERROR';

export const CAR_CLIENT = 'CAR_CLIENT';
export const CAR_CLIENT_SUCCESS = 'CAR_CLIENT_SUCCESS';
export const CAR_CLIENT_ERROR = 'CAR_CLIENT_ERROR';

export const NACIONALITY_A = 'NACIONALITY_A';
export const NACIONALITY_SUCCESS = 'NACIONALITY_SUCCESS';
export const NACIONALITY_ERROR = 'NACIONALITY_ERROR';

export const TEMPORAL_PAWN = 'TEMPORAL_PAWN';
export const TEMPORAL_PAWN_SUCCESS = 'TEMPORAL_PAWN_SUCCESS';
export const TEMPORAL_PAWN_ERROR = 'TEMPORAL_PAWN_ERROR';
export const TEMPORAL_PAWN_BLOCK = 'TEMPORAL_PAWN_BLOCK';

export const BENEFICIARY = 'TEMPORAL_PAWN';
export const BENEFICIARY_SUCCESS = 'BENEFICIARY_SUCCESS';
export const BENEFICIARY_ERROR = 'BENEFICIARY_ERROR';

export const BENEFICIARY_DELETE = 'BENEFICIARY_PAWN_DELETE';
export const BENEFICIARY_DELETE_SUCCESS = 'BENEFICIARY_DELETE_SUCCESS';
export const BENEFICIARY_DELETE_ERROR = 'BENEFICIARY_DELETE_ERROR';

export const BENEFICIARY_EDIT = 'BENEFICIARY_EDIT';
export const BENEFICIARY_EDIT_SUCCESS = 'BENEFICIARY_EDIT_SUCCESS';
export const BENEFICIARY_EDIT_ERROR = 'BENEFICIARY_EDIT_ERROR';

export const BENEFICIARY_UPDATE = 'BENEFICIARY_UPDATE';
export const BENEFICIARY_UPDATE_SUCCESS = 'BENEFICIARY_UPDATE_SUCCESS';
export const BENEFICIARY_UPDATE_ERROR = 'BENEFICIARY_UPDATE_ERROR';

export const COOWNER = 'TEMPORAL_PAWN';
export const COOWNER_SUCCESS = 'COOWNER_SUCCESS';
export const COOWNER_ERROR = 'COOWNER_ERROR';

export const COOWNER_DELETE = 'COOWNER_PAWN_DELETE';
export const COOWNER_DELETE_SUCCESS = 'COOWNER_DELETE_SUCCESS';
export const COOWNER_DELETE_ERROR = 'COOWNER_DELETE_ERROR';

export const COOWNER_EDIT = 'COOWNER_EDIT';
export const COOWNER_EDIT_SUCCESS = 'COOWNER_EDIT_SUCCESS';
export const COOWNER_EDIT_ERROR = 'BENEFICIARY_EDIT_ERROR';

export const COOWNER_UPDATE = 'COOWNER_UPDATE';
export const COOWNER_UPDATE_SUCCESS = 'COOWNER_UPDATE_SUCCESS';
export const COOWNER_UPDATE_ERROR = 'BENEFICIARY_UPDATE_ERROR';

export const BENEFIST = 'BENEFIST';
export const BENEFIST_SUCCESS = 'BENEFIST_SUCCESS';
export const BENEFIST_ERROR = 'BENEFICIARY_UPDATE_ERROR';

export const GET_BENEFIST = 'GET_BENEFIST';

export const GET_CHECK = 'GET_CHECK';
