export const ADD_DATA_NEW_FOR_LEVEL_REQUEST = 'ADD_DATA_NEW_FOR_LEVEL_REQUEST';
export const ADD_DATA_NEW_FOR_LEVEL_SUCCESS = 'ADD_DATA_NEW_FOR_LEVEL_SUCCESS';
export const ADD_DATA_NEW_FOR_LEVEL_ERROR = 'ADD_DATA_NEW_FOR_LEVEL_ERROR';
export const BREAD_CRUMB = 'BREAD_CRUMB';
export const ID_SELECTED = 'ID_SELECTED';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';

export const GET_FAMILY_CONCAT_LEVEL = 'CONCAT_LEVEL';
export const GET_SUBFAMILY_SUCCESS = 'GET_SUBFAMILY_SUCCESS';
export const GET_BRAND_CONCAT_SUCCESS = 'GET_BRAND_CONCAT_SUCCESS';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';

export const GET_CAT_GROUP = 'GET_CAT_GROUP';
export const TOTAL_PAGES_GROUP = 'TOTAL_PAGES_GROUP';
export const GET_CAT_GROUP_SUCCESS = 'GET_CAT_GROUP_SUCCESS';
export const GET_CAT_GROUP_ERROR = 'GET_CAT_GROUP_ERROR';

export const GET_CAT_FAMILY = 'GET_CAT_FAMILY';
export const GET_CAT_FAMILY_SUCCESS = 'GET_CAT_FAMILY_SUCCESS';
export const GET_CAT_FAMILY_ERROR = 'GET_CAT_FAMILY_ERROR';

export const GET_CAT_SUBFAMILY = 'GET_CAT_SUBFAMILY';
export const GET_CAT_SUBFAMILY_SUCCESS = 'GET_CAT_SUBFAMILY_SUCCESS';
export const GET_CAT_SUBFAMILY_ERROR = 'GET_CAT_SUBFAMILY_ERROR';

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';

export const GET_ITEMS = 'GET_ITEMS';
export const TOTAL_PAGES_ITEM = 'TOTAL_PAGES_ITEM';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR';

export const GET_SEARCHBAR = 'GET_SEARCHBAR';
export const GET_SEARCHBAR_SUCCESS = 'GET_SEARCHBAR_SUCCESS';
export const GET_SEARCHBAR_ERROR = 'GET_SEARCHBAR_ERROR';

export const GET_GROUP_NODE = 'GET_GROUP_NODE';
export const GET_GROUP_NODE_NAME = 'GET_GROUP_NODE_NAME';
export const SELECTED_LEVEL_NUMBER = 'SELECTED_LEVEL_NUMBER';

export const RESET_SELECTED = 'RESET_SELECTED';
