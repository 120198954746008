import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {RootState, AppDispatch} from '@config/store';
import {schemaForm} from '@components/ManageUser/validationsYup';
import {UserData} from '@components/ManageUser/Redux/interface';
import {PropsEditInformation} from '@components/ManageUser/interface';
import {ContainerCellGrid, ContainerFlex} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {putDataUserEmployeeAxios} from '@components/ManageUser/Redux/Actions/putDataUserEmployeeAction';
import {
    GetPhoneInUse,
    resetGetPhoneInUse,
} from '@components/CreateUsers/Redux/Actions/GetPhoneInUse';
import {
    GetEmailInUse,
    resetGetEmailInUse,
} from '@components/CreateUsers/Redux/Actions/GetEmailInUse';
import {GetUserName, resetGetUserName} from '@components/CreateUsers/Redux/Actions/GetUserName';
import {EnterNamesUser} from '@components/ManageUser/Form/EnterNamesUser';
import {EnterUserNameAndOrganization} from '@components/ManageUser/Form/EnterUserNameAndOrganization';
import {EnterJobAndRole} from '@components/ManageUser/Form/EnterJobAndRole';
import {EnterAddressEmailAndPhone} from '@components/ManageUser/Form/EnterAddressEmailAndPhone';
import {EnterCostAndBoss} from '@components/ManageUser/Form/EnterCostAndBoss';

export const EditInformation = ({setIsEditInfo}: PropsEditInformation) => {
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const emailAvailable = useSelector((state: RootState) => state.GetEmailInUse.emailAvailable);
    const phoneAvailable = useSelector((state: RootState) => state.GetPhoneInUse.phoneAvailable);
    const dispatch: AppDispatch = useDispatch();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        trigger,
        watch,
        setError,
        control,
        formState: {errors, isValid, dirtyFields},
    } = useForm<UserData>({
        defaultValues: UserInformation,
        resolver: yupResolver(schemaForm),
    });
    const onSubmit = (data: UserData) => {
        const updateDataUser = {
            userEmployeeId: data.userEmployeeId,
            names: data.userEmployeeName,
            surnaNames: data.usersLastName,
            userName: data.userName,
            jobPosition: data.job,
            inmmediateBoss: data.inmediateBoss,
            status: data.statusUser,
            companyId: data.companyId,
            startSesionEmail: data.startSesionEmail,
            startSesionPhone: data.startSesionPhone,
            address: data.adress,
            email: data.email,
            phone: data.phone,
            costCenter: data.costCenter,
            rolEmployee: data.roles.map((role) => ({
                rolId: role.roleId,
                rolName: role.roleName,
                substituteEmployeeRol: role.substitutes?.map((substitute) => ({
                    substituteId: substitute.substitutesId,
                    userEmployeeId: substitute.secondUserEmployeeId,
                    secondRol: substitute.secondUserEmployeeId,
                })),
            })),
            levelsBranch: data.branchs.map((branch) => ({
                branchId: branch.branchId,
                nameBranch: branch.branchName,
            })),
        };
        dispatch(putDataUserEmployeeAxios(token, updateDataUser, updateDataUser.userEmployeeId));
        setIsEditInfo(false);
    };
    const listEmployeeRoles = getValues('roles');
    const levelsBranch = watch('branchs');
    const phone = getValues('phone');
    const email = getValues('email');
    const userName = getValues('userName');
    const name = getValues('userEmployeeName');
    const lastName = getValues('usersLastName');
    useMemo(() => {
        if (userName) dispatch(GetUserName(token, companyId, userName));
    }, [companyId, dispatch, userName, token]);

    useMemo(() => {
        if (phone && phone.length === 10) {
            return dispatch(GetPhoneInUse(token, companyId, phone));
        }
        dispatch(resetGetPhoneInUse());
    }, [companyId, dispatch, phone, token]);

    useMemo(() => {
        if (!errors.email?.message && dirtyFields.email)
            return dispatch(GetEmailInUse(token, companyId, email));
        dispatch(resetGetEmailInUse());
    }, [companyId, dispatch, email, token]);

    const isValidEmail = useMemo(() => {
        if (emailAvailable && dirtyFields.email) {
            setError('email', {message: 'Este correo ya está en uso'});
            return false;
        }
        return true;
    }, [emailAvailable]);
    const isValidPhone = useMemo(() => {
        if (phoneAvailable && dirtyFields.phone) {
            setError('phone', {
                message: 'El telefono se encuentra asignado a otro usuario',
            });
            return false;
        }
        return true;
    }, [phoneAvailable]);
    useEffect(() => {
        return () => {
            dispatch(resetGetUserName());
            dispatch(resetGetPhoneInUse());
            dispatch(resetGetEmailInUse());
        };
    }, []);
    return (
        <ContainerFlex
            Display="grid"
            Height="576px"
            GridColumns="40% 40%"
            GridRows="repeat(4, 1fr) 105px 1fr 60px"
            Padding="16px"
            Gap="0 24px"
        >
            <EnterNamesUser
                register={register}
                textErrorName={errors.userEmployeeName?.message}
                textErrorLastName={errors.usersLastName?.message}
            />

            <EnterUserNameAndOrganization
                register={register}
                isTouchedUserName={dirtyFields.userName}
                levelsBranch={levelsBranch}
                setValue={setValue}
            />

            <EnterJobAndRole
                lastName={lastName}
                listEmployeeRoles={listEmployeeRoles}
                name={name}
                register={register}
                setValue={setValue}
                textErrorJob={errors.job?.message}
                trigger={trigger}
            />

            <EnterAddressEmailAndPhone
                control={control}
                isTouchedPhone={dirtyFields.phone}
                register={register}
                textErrorAddress={errors.adress?.message}
                textErrorEmail={errors.email?.message}
                textErrorPhone={errors.phone?.message}
                trigger={trigger}
            />

            <EnterCostAndBoss register={register} />

            <ContainerCellGrid Column="1 / 3" Justify="center" Gap="1.5rem" Align="center">
                <ButtonGeneral
                    text="Cancelar"
                    width="93px"
                    secondaryButton={true}
                    transform="capitalize"
                    bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                    color="#414042"
                    hColor="#35CF44"
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                    clic={() => setIsEditInfo(false)}
                    border="1px solid transparent"
                />
                <ButtonGeneral
                    disabled={!isValid || !isValidEmail || !isValidPhone}
                    text="Guardar y continuar"
                    width="168px"
                    border="1px solid #414042"
                    bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                    color="#414042"
                    hColor="#35CF44"
                    hBorder="#35CF44"
                    hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                    dFontWeight="400"
                    clic={handleSubmit(onSubmit)}
                />
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
