import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {INavbarLoansProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const NavbarLoans: React.FC<INavbarLoansProps> = ({containerRef, refs}) => {
    const [navbarSelected, setNavbarSelected] = useState<string>('Detalle');
    const navBarHeaders = [
        {title: 'Detalle', ref: 'detailsRef'},
        {title: 'Frecuencia de pago', ref: 'frequencyRef'},
        {title: 'Cliente y Documentos', ref: 'clientAndDocumentsRef'},
        {title: 'Desembolso', ref: 'disbursementsRef'},
        {title: 'Métodos de pago', ref: 'paymentMethodRef'},
        {title: 'Cargos y Penalidades', ref: 'chargesRef'},
    ];
    const handleSelectedNavbar = (value: string, refKey: string) => {
        const refElement = refs[refKey];
        setNavbarSelected(value);
        if (refElement.current && containerRef.current) {
            containerRef.current.scrollTo({
                top: refElement.current.offsetTop - 100,
                behavior: 'smooth',
            });
        }
    };
    useEffect(() => {
        if (!containerRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const section = entry.target;
                        const title = navBarHeaders.find(
                            (item) => refs[item.ref].current === section
                        )?.title;
                        if (title) {
                            setNavbarSelected(title);
                        }
                    }
                });
            },
            {root: containerRef.current, threshold: 0.75}
        );

        Object.values(refs).forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            Object.values(refs).forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [navBarHeaders]);

    return (
        <>
            <ContainerFlex
                Padding="0px 0px 21px 0px"
                Height="85px"
                Position="sticky"
                backG="rgba(250, 250, 250, 0.08)"
                FlexDir="column"
                PositionTop="-25px"
                ZIndex="20"
            >
                <ContainerFlex
                    BackdropF="blur(10px)"
                    Position="relative"
                    Height="100%"
                    Padding="21px 0px 0px 0px"
                    Radius="0px 0px 29px 29px"
                >
                    <GridContainer
                        Position="relative"
                        Height="43px"
                        Justify="space-between"
                        Border="1px solid #E8E9EA"
                        BoxS="0px 2px 7px 0px rgba(0, 0, 0, 0.10)"
                        Radius="29px"
                        backG="#FFF"
                        GridColumns="repeat(6,1fr)"
                    >
                        {navBarHeaders.map((value, index) => (
                            <Text
                                Color="#2A2C2F"
                                key={index}
                                Height="100%"
                                Cursor="Pointer"
                                Justify="center"
                                FontWeight={value.title === navbarSelected ? '700' : '400'}
                                BorderRadius={value.title === navbarSelected ? '32px' : '0px'}
                                bGround={value.title === navbarSelected ? '#E5E5FF' : ''}
                                onClick={() => handleSelectedNavbar(value.title, value.ref)}
                            >
                                {value.title}
                            </Text>
                        ))}
                    </GridContainer>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
