import * as yup from 'yup';

const regexPatterns = {
    chargeAmount: /^[0-9]+(?:\.[0-9]{1,2})?$/,
    cardDigits: /^\d{4}$/,
    cve: /^CVE-\d{4}-\d{4,7}$/,
};

export const schemaPaymentGatewayCardEntry = yup.object().shape({
    cardAmount: yup
        .string()
        .required('Ingresa monto en tarjeta')
        .matches(regexPatterns.chargeAmount, 'Monto ingresado es invalido'),
    cardLastFourDigits: yup
        .string()
        .required('Ultimos cuatro numeros son requeridos')
        .matches(regexPatterns.cardDigits, 'Debes de ingresar 4 numeros'),
    cveRegistry: yup
        .string()
        .required('Ingresa el CVE de tu recibo')
        .matches(regexPatterns.cve, 'Formato CVE invalido, use CVE-YYYY-NNNN'),
    debitCredit: yup
        .object()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required()
        .typeError('Escoja tipo Debito or Credito'),
    cardType: yup
        .object()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required()
        .typeError('Tipo de tarjeta es requerido'),
});

export const schemaPaymentGatewayCashEntry = yup.object().shape({
    cashAmount: yup
        .string()
        .required('Ingresa monto en efectivo')
        .matches(regexPatterns.chargeAmount, 'Monto ingresado es invalido'),
    changeAmount: yup
        .string()
        .required('Ingresa monto de cambio')
        .matches(regexPatterns.chargeAmount, 'Monto ingresado es invalido'),
});
