import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateWarehouses} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {StorageLevelList} from '@components/LocationsBranch/StorageLevelList';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';
import {
    DEFAULT_LEVEL_ID,
    DEFAULT_PARENT_LEVEL_ID,
    FIXED_LEVEL,
    MAX_LEVELS,
    START_LEVEL,
    STORAGE,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {IStorage, IStorageTypeDetailProps} from '@components/LocationsBranch/interfaces';
import {SearchInput} from '@Customer/Styles';
import EditIcon from '@images/editIcon.svg';
import backArrowIcon from '@images/backArrowIcon.svg';
import AddIcon from '@images/addUser.svg';
import AddFloor from '@images/addIcon.svg';
import HelpIcon from '@images/help.svg';

export const StorageTypeDetail = ({
    warehouse,
    selectedStorageType,
    setSelectedStorage,
}: IStorageTypeDetailProps) => {
    const dispatch = useDispatch();
    const [editId, setEditId] = useState<number>(1);
    const storageTypes = warehouse.storageTypes || [];

    const onAddNewLevel = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            const value = event.currentTarget.value;
            const newStorageType = {...selectedStorageType};

            newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
                if (level.id === editId) {
                    level.parentLevel = newStorageType.levelId ?? DEFAULT_PARENT_LEVEL_ID;
                    level.name = value;
                    level.productQuantity = 0;
                    level.showRowLevelInput = false;
                    level.showNewLevel = false;
                }
            });

            updateStorage({
                ...newStorageType,
                storageFloor: newStorageType.storageFloor || [],
            });
        }
    };

    const onAddNewRow = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            const value = event.currentTarget.value;
            const newStorageType = {...selectedStorageType};
            newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
                if (level.id === editId) {
                    level.showRowLevelInput = false;
                    level.showNewLevel = false;
                    level.rows?.push({
                        name: value,
                        parentLevel: level.levelId ?? DEFAULT_PARENT_LEVEL_ID,
                        productQuantity: 0,
                    });
                }
            });

            updateStorage({
                ...newStorageType,
                storageFloor: newStorageType.storageFloor || [],
            });

            return;
        }
    };

    const onClickNewRow = (id: number, visible: boolean = false, index: number) => {
        setEditId(id);
        setRowLevelInput(id, visible, index);
    };

    const setRowLevelInput = (id: number, visible: boolean, index: number) => {
        if (!selectedStorageType) return;
        const newStorageType = {...selectedStorageType};
        newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
            if (level.id === id) {
                level.showRowLevelInput = visible;
                level.showNewLevel = false;
            }
        });
        updateStorage(newStorageType);
    };

    const onClickNewLevel = (visible: boolean = false, index: number) => {
        const curretFloor = selectedStorageType?.storageFloor[index];
        const quantityLevel = curretFloor?.levels?.length ?? 0;
        if (quantityLevel >= MAX_LEVELS) return;
        const currrentLevel =
            curretFloor?.levels && curretFloor?.levels.find((level) => level.name === '');
        let id = currrentLevel?.id ?? START_LEVEL;
        if (!currrentLevel) {
            id = MAX_LEVELS;
            if (quantityLevel < MAX_LEVELS) {
                const lastLevel = curretFloor?.levels?.at(-1);
                const newId = (lastLevel?.id ?? DEFAULT_LEVEL_ID) + 1;
                curretFloor?.levels?.push({
                    id: newId,
                    name: '',
                    levelId: FIXED_LEVEL,
                    productQuantity: 0,
                    parentLevel: selectedStorageType?.id ?? DEFAULT_PARENT_LEVEL_ID,
                    showRowLevelInput: false,
                    showNewLevel: false,
                    rows: [],
                });
                id = newId;
            }
        }
        setEditId(id);
        setShowLevelInput(id, visible, index);
    };

    const setShowLevelInput = (id: number, visible: boolean, index: number) => {
        if (!selectedStorageType) return;
        const newStorageType = {...selectedStorageType};
        const curretFloor = newStorageType?.storageFloor[index];

        curretFloor?.levels?.forEach((level) => {
            if (level.id === id) {
                level.showRowLevelInput = false;
                level.showNewLevel = visible;
            }
        });
        updateStorage(newStorageType);
    };

    const updateStorage = (newStorageType: IStorage) => {
        const storageTypeIndex = storageTypes.findIndex(
            (storageType) => storageType.id === newStorageType.id
        );
        const newStorageTypes = [...storageTypes];
        newStorageTypes[storageTypeIndex] = newStorageType;
        const newWarehouse = JSON.parse(JSON.stringify(warehouse));
        newWarehouse.storageTypes = newStorageTypes;

        dispatch(updateWarehouses(newWarehouse));
    };

    const onGoBack = () => {
        setSelectedStorage(null);
    };

    const onClickNewFloor = () => {
        const newId = (selectedStorageType?.storageFloor?.length ?? 0) + 1;
        const newStorageType = {
            ...selectedStorageType,
            storageFloor: [
                ...(selectedStorageType?.storageFloor || []),
                ...getInitialFloor(newId, selectedStorageType?.id ?? DEFAULT_PARENT_LEVEL_ID, true),
            ],
        };
        setSelectedStorage(newStorageType);
        updateStorage(newStorageType);
    };

    const onCreateFloorName = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            const value = event.currentTarget.value;
            const newStorageType = {...selectedStorageType};

            if (newStorageType?.storageFloor?.[index]) {
                newStorageType.storageFloor[index].name = value;
            }

            updateStorage({
                ...newStorageType,
                storageFloor: newStorageType.storageFloor || [],
            });
        }
    };

    return (
        <>
            {selectedStorageType?.storageFloor?.map((floor, index) => (
                <ContainerFlex
                    key={index}
                    backG="#FAFAFF"
                    Gap="1rem"
                    FlexDir="column"
                    Padding="1rem 1.5rem 1rem 1.5rem"
                    Align="start"
                    Justify="start"
                    Radius="1rem"
                    MarginBt="1rem"
                >
                    <ContainerFlex FlexDir="column" Justify="start" Align="start" Radius="1rem">
                        {index === 0 && (
                            <Text
                                FontSize="1rem"
                                Color="#5A5AFF"
                                FontWeight="500"
                                PaddingB="1rem"
                                Cursor="pointer"
                                onClick={() => onGoBack()}
                            >
                                <Image
                                    src={backArrowIcon}
                                    alt="add"
                                    Width="1rem"
                                    Height="1rem"
                                    Margin="0 0.313rem 0 0"
                                />
                                {STORAGE.HOME}
                            </Text>
                        )}
                        <ContainerFlex Justify="start" Align="start" Gap="0.5rem" Radius="1rem">
                            {floor?.name !== '' ? (
                                <ContainerFlex Justify="start" Gap="0.5rem">
                                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500">
                                        {floor?.name}
                                    </Text>
                                    <Text FontSize="0.875rem" MarginTop="0.313rem">
                                        {floor?.productQuantity} {STORAGE.PRODUCTS}
                                    </Text>
                                    <Image
                                        src={EditIcon}
                                        alt="add"
                                        Width="1.125rem"
                                        Height="1.125rem"
                                        Margin="0 0 0.313rem"
                                    />
                                </ContainerFlex>
                            ) : (
                                <ContainerFlex FlexDir="column" Height={'2.5rem'} Width="18.75rem">
                                    <SearchInput GridColumn="90% 10%" FontSize="1rem" Right="none">
                                        <input
                                            type="text"
                                            name="level"
                                            onKeyDown={(e) => {
                                                onCreateFloorName(e, index);
                                            }}
                                            autoFocus
                                            onFocus={() => {}}
                                        />
                                        <Image src={HelpIcon} alt="help-icon" />
                                    </SearchInput>
                                </ContainerFlex>
                            )}

                            <ContainerFlex Justify="end" Gap="0.5rem">
                                <ButtonGenerals
                                    Height="2rem"
                                    Radius="24px"
                                    Border="1px solid #5A5AFF"
                                    Cursor="pointer"
                                    FontSize="0.85rem"
                                    FontWeight="500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickNewLevel(true, index);
                                    }}
                                >
                                    <Image src={AddIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                                    {STORAGE.ADD}
                                </ButtonGenerals>
                            </ContainerFlex>
                        </ContainerFlex>
                        {floor?.name !== '' && (
                            <ContainerFlex Justify="start" Align="start" Radius="1rem">
                                <Text FontSize="0.75rem">
                                    {STORAGE.LEVEL}
                                    {'1'}
                                </Text>
                            </ContainerFlex>
                        )}
                        <StorageLevelList
                            storage={floor}
                            onAddNewLevel={(
                                event: React.KeyboardEvent<HTMLInputElement>,
                                floorIndex
                            ) => {
                                onAddNewLevel(event, floorIndex);
                            }}
                            onAddNewRow={(
                                event: React.KeyboardEvent<HTMLInputElement>,
                                floorIndex
                            ) => {
                                onAddNewRow(event, floorIndex);
                            }}
                            onClickNewRow={onClickNewRow}
                            setEditId={setEditId}
                            floorIndex={index}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            ))}
            <ContainerFlex Justify="start">
                <ButtonGeneral
                    color="#5A5AFF"
                    backGround="#FFFFFF"
                    hText="#5A5AFF"
                    hShadow=""
                    height="2.5rem"
                    cursor="pointer"
                    border="1px solid #5A5AFF"
                    hBackG="#E5E5FF"
                    clic={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onClickNewFloor();
                    }}
                    text={
                        <>
                            <Image src={AddFloor} alt="add" color="#5A5AFF" />
                            <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer" FontSize="1rem">
                                {STORAGE.NEW_FLOOR}
                            </Text>
                        </>
                    }
                />
            </ContainerFlex>
        </>
    );
};
