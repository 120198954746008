import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {SubMenuItemPropsExpanded} from '@components/SideBarMenu/interfaces';

const SideBarMenuSubItemExpanded: React.FC<SubMenuItemPropsExpanded> = ({
    subItem,
    subIndex,
    clickedSubItem,
    index,
    handleSubItemClick,
}) => {
    const navigate = useNavigate();
    return (
        <ContainerFlex key={index} FlexDir="column">
            <ContainerFlex
                Justify="start"
                Gap="0.5rem"
                Height="2.5rem"
                Padding="0.5rem"
                HBorderLeft="1.5px solid  #5A5AFF"
                HColor="#5A5AFF"
                HBackground="#F0F0FF"
            >
                {subItem.cssIcon !== '#' && subItem.cssIcon !== '/' ? (
                    <Image
                        src={
                            clickedSubItem === `${index}-${subIndex}`
                                ? subItem.cssIconHover
                                : subItem.cssIcon
                        }
                        width="20px"
                        alt="item-icon"
                    />
                ) : (
                    <Text Width="20px" Height="20px" />
                )}
                <Text
                    Cursor="pointer"
                    onClick={() => {
                        handleSubItemClick(index, subIndex);
                        navigate(`${subItem.route}`);
                    }}
                    Color={clickedSubItem === `${index}-${subIndex}` ? '#5A5AFF' : '#54575C'}
                >
                    {subItem.title}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default SideBarMenuSubItemExpanded;
