import React, {useState} from 'react';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerCellGrid,
    ContainerForm,
    Image,
    ContainerGrid,
} from '@/components/Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {TYPEDOCUMENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import CloseIcon from '@images/closeDark.svg';
import AddIcon from '@images/add - plus.svg';
import DoneIcon from '@images/check_done_blue.svg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {addDocumentNewOption} from '@/components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';

export const TypeDocuments = () => {
    const dispatch = useDispatch();
    const {documentOptions} = useSelector((state: RootState) => {
        return state.DocumentDisbursementReducer;
    });

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [addDocuments, setAddDocuments] = useState(false);

    const timeOptions = [
        {label: 'Semanas', value: 1},
        {label: 'Meses', value: 2},
        {label: 'Años', value: 3},
    ];

    const handleAddDocument = () => {
        if (name && description) {
            const newDocumentOption = {
                documentId: documentOptions.length + 1,
                documentName: name,
                documentDetails: description,
            };
            dispatch(addDocumentNewOption(newDocumentOption));
            setAddDocuments(false);
            setName('');
            setDescription('');
        }
    };

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column">
            <ContainerGrid
                GridColumns="0.05fr 0.5fr 0.5fr"
                Align="center"
                Height="48px"
                Padding="0.5rem 1rem"
                BorderBt="solid 1px #E8E9EA"
            >
                <ContainerFlex Justify="start" Padding="0 8px">
                    <Checkbox />
                </ContainerFlex>
                <Text oFlow="normal" wSpace="pre" Color="#2A2C2F" FontWeight="700">
                    {TYPEDOCUMENTS.DOCUMENT}{' '}
                </Text>
                <Text oFlow="normal" wSpace="pre" Color="#2A2C2F" FontWeight="700">
                    {TYPEDOCUMENTS.REQUIRED}
                    <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                        {TYPEDOCUMENTS.OPTIONAL}
                    </Text>
                </Text>
                <ContainerFlex />
            </ContainerGrid>
            {documentOptions.map((item, index: number) => (
                <ContainerGrid
                    key={index}
                    GridColumns="0.02fr 0.5fr 0.5fr"
                    Justify="start"
                    Height="70px"
                    Padding="0.5rem 1rem"
                    BorderBt="solid 1px #E8E9EA"
                    Gap="1rem"
                >
                    <ContainerFlex Justify="start" Padding="0 8px">
                        <Checkbox />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#2A2C2F">{item.documentName}</Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {item.documentDetails}
                        </Text>
                    </ContainerFlex>
                    {item.documentName === TYPEDOCUMENTS.JOB_DOC && (
                        <ContainerFlex>
                            <ContainerFlex Justify="center">
                                <Text FontSize="0.875rem" Color="#2A2C2F" MarginRight="1rem">
                                    {TYPEDOCUMENTS.TIME}
                                </Text>
                                <Input
                                    Radius="32px 0 0 32px"
                                    type="number"
                                    Width="98px"
                                    placeholder="N.º"
                                />
                                <SelectGeneral
                                    options={timeOptions}
                                    borderRadius="0px 32px 32px 0px"
                                    border="1px solid #E8E9EA"
                                    background="#FFFFFF"
                                    width="150px"
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                </ContainerGrid>
            ))}
            {addDocuments ? (
                <ContainerForm
                    Justify="space-between"
                    MarginT="1rem"
                    Padding="1rem"
                    Border="1px solid #5A5AFF"
                    Radius="1rem"
                >
                    <ContainerCellGrid FlexDir="column" Align="start" Width="363px" Gap="0.25rem">
                        <Text oFlow="normal" wSpace="pre" FontWeight="700" Color="#2A2C2F">
                            {TYPEDOCUMENTS.DOCUMENT}
                        </Text>
                        <Input
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </ContainerCellGrid>
                    <ContainerCellGrid FlexDir="column" Align="start" Width="363px" Gap="0.25rem">
                        <Text oFlow="normal" wSpace="pre" FontWeight="700" Color="#2A2C2F">
                            {TYPEDOCUMENTS.INFO}
                            <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                                {TYPEDOCUMENTS.OPTIONAL}
                            </Text>
                        </Text>
                        <Input
                            placeholder=""
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </ContainerCellGrid>
                    <ContainerCellGrid FlexDir="column" Align="end" Width="107px" Gap="0.25rem">
                        <Image
                            src={CloseIcon}
                            alt="close-icon"
                            Cursor="pointer"
                            onClick={() => setAddDocuments(false)}
                        />
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            Width="107px"
                            type="button"
                            onClick={handleAddDocument}
                        >
                            <Image
                                src={DoneIcon}
                                alt="done-icon"
                                Cursor="pointer"
                                onClick={() => setAddDocuments(false)}
                            />
                            {TYPEDOCUMENTS.APPLY}
                        </ButtonGenerals>
                    </ContainerCellGrid>
                </ContainerForm>
            ) : (
                <ContainerFlex Justify="start" MarginTop="1rem" Gap="0.5rem" Height="1.5rem">
                    <Image
                        src={AddIcon}
                        alt="add-icon"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    />
                    <Text
                        FontWeight="700"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    >
                        {TYPEDOCUMENTS.ANOTHERDOC}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
