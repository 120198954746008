import {
    PUT_APPROVE_REQUEST,
    PUT_APPROVE_REQUEST_SUCCESS,
    PUT_APPROVE_REQUEST_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';
const initialState = {
    info: [],
    aprove: false,
    error: false,
};
const putAprovedRequest = (
    state = initialState,
    action: {type: string; value: AxiosResponse<string>}
) => {
    switch (action.type) {
        case PUT_APPROVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PUT_APPROVE_REQUEST_SUCCESS:
            return {
                ...state,
                aprove: action.value,
                loading: false,
            };
        case PUT_APPROVE_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default putAprovedRequest;
