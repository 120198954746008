import styled from '@emotion/styled';
interface IImageShow {
    Border?: string;
}
export const ImgShow = styled.img<IImageShow>`
    width: 125px;
    height: 125px;
    border: ${(props) => props.Border};
    border-radius: 19px;
    opacity: 1;
`;
ImgShow.defaultProps = {
    Border: '1px solid #e5e7e9',
};
