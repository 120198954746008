import {useState, useEffect, useCallback} from 'react';
import {ANIMATION_STATES} from '@/components/CreateBranch/BranchInformation/constants';

export const useModalAnimation = (modalState: boolean, applyAnimation: boolean) => {
    const [shouldRender, setShouldRender] = useState(modalState);
    const [animationState, setAnimationState] = useState(
        applyAnimation ? (modalState ? ANIMATION_STATES.ENTERING : ANIMATION_STATES.EXITING) : ''
    );

    useEffect(() => {
        if (applyAnimation) {
            setAnimationState(modalState ? ANIMATION_STATES.ENTERING : ANIMATION_STATES.EXITING);
        }
        if (modalState) setShouldRender(true);
    }, [modalState, applyAnimation]);

    const handleAnimationEnd = useCallback(() => {
        if (animationState === ANIMATION_STATES.EXITING) {
            setShouldRender(false);
        }
    }, [animationState]);

    return {
        shouldRender,
        animationState,
        handleAnimationEnd,
    };
};
