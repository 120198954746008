import axios from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';

import {
    GET_DOCUMENT_HEADER,
    GET_DOCUMENT_HEADER_SUCCESS,
    GET_DOCUMENT_HEADER_ERROR,
    GET_DOCUMENT_BODY,
    GET_DOCUMENT_BODY_SUCCESS,
    GET_DOCUMENT_BODY_ERROR,
    PUT_DOCUMENT_FINISH,
    PUT_DOCUMENT_FINISH_SUCCESS,
    PUT_DOCUMENT_FINISH_ERROR,
} from '@TypesDocumentFoundry/DocumentFoundryTypes';
import {URL} from '@config/constants/index';
import {IDocumentsFinish} from '@/components/Foundry/interfaces';

export const fetchGetDocumentHeader = () => {
    return {
        type: GET_DOCUMENT_HEADER,
    };
};
export const fetchGetDocumentHeaderSuccess = (filters: unknown) => {
    return {
        type: GET_DOCUMENT_HEADER_SUCCESS,
        value: filters,
    };
};
export const fetchGetDocumentHeaderError = () => {
    return {
        type: GET_DOCUMENT_HEADER_ERROR,
    };
};
export function getDocumentHeader(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetDocumentHeader());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.DocumentHeader.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetDocumentHeaderSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetDocumentHeaderError());
        }
    };
}
export const fetchGetDocumentBody = () => {
    return {
        type: GET_DOCUMENT_BODY,
    };
};
export const fetchGetDocumentBodySuccess = (data: unknown) => {
    return {
        type: GET_DOCUMENT_BODY_SUCCESS,
        value: data,
    };
};
export const fetchGetDocumentBodyError = () => {
    return {
        type: GET_DOCUMENT_BODY_ERROR,
    };
};
export function getDocumentBody(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetDocumentBody());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.DocumentBody.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetDocumentBodySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetDocumentBodyError);
        }
    };
}
export const fetchPutDocumentFinish = () => {
    return {
        type: PUT_DOCUMENT_FINISH,
    };
};
export const fetchPutDocumentFinishSuccess = () => {
    return {
        type: PUT_DOCUMENT_FINISH_SUCCESS,
    };
};
export const fetchPutDocumentFinishError = () => {
    return {
        type: PUT_DOCUMENT_FINISH_ERROR,
    };
};
export function putDocumentFinish(data: IDocumentsFinish, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutDocumentFinish());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentFinish}`,
                data,
                {headers}
            );
            dispatch(fetchPutDocumentFinishSuccess());
            Swal.fire({
                icon: 'success',
                title: 'Finalizar auditoría',
                text: '¡La auditoria ha sido Finalizada con éxito!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Finalizar auditoría',
            });
            dispatch(fetchPutDocumentFinishError);
        }
    };
}
