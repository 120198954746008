import {TOGGLE_LOADING} from '@General/Atoms/LoadingAtaskate/Redux/Types';

interface LoadingPayload {
    isLoading: boolean;
    promptText?: string;
}

interface LoadingAction {
    type: string;
    payload: LoadingPayload;
}

interface LoadingState {
    isLoading: boolean;
    promptText?: string;
}

const initialState: LoadingState = {
    isLoading: false,
    promptText: undefined,
};

export const loadingReducer = (state = initialState, action: LoadingAction) => {
    switch (action.type) {
        case TOGGLE_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                promptText: action.payload.promptText || undefined,
            };
        default:
            return state;
    }
};
