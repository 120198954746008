import {SAVE_USER} from '@components/CreateUsers/Redux/Types/Types';
import {ISaveUser, ISaveUserReducer} from '@components/CreateUsers/Redux/interface';

const initialState: ISaveUser = {
    personal: {
        imageProfileBase64: '',
        imageProfile: '',
        names: '',
        firsLastNames: '',
        secondLastNames: '',
        birthDate: '',
    },
    credential: {
        userType: 0,
        pin: '',
        accessCredentials: {emailAdmin: '', userName: '', password: '', repeatPassword: ''},
    },
    roleAdmins: [{roleIdAdmin: 0, roleNameAdmin: ''}],
    roleEmployees: [{roleIdEmployee: 0, roleNameEmployee: ''}],
    aditionalInformationEmployee: [
        {
            manageAditionalInformation: true,
            codigoPostal: '',
            address: '',
            externalNumber: '',
            ineternalNumber: '',
            catColoniaId: {value: 0, label: ''},
        },
    ],
    typeAction: '',
};

export const saveUser = (state = initialState, action: ISaveUserReducer): ISaveUser => {
    switch (action.type) {
        case SAVE_USER.PERSONAL_DATA:
            return {
                ...state,
                personal: action.personal,
            };
        case SAVE_USER.CREDENTIALS:
            return {
                ...state,
                credential: action.credential,
            };
        case SAVE_USER.ADMIN_ROLES:
            return {
                ...state,
                roleAdmins: action.roleAdmins,
            };
        case SAVE_USER.POS_ROLES:
            return {
                ...state,
                roleEmployees: action.roleEmployees,
            };
        case SAVE_USER.ADDRESS:
            return {
                ...state,
                aditionalInformationEmployee: action.aditionalInformationEmployee,
            };
        case SAVE_USER.TYPE:
            return {
                ...state,
                typeAction: action.typeAction,
            };
        case SAVE_USER.RESET:
            return initialState;
        default:
            return state;
    }
};
