import React, {useCallback, useEffect, useRef, ChangeEvent} from 'react';
import {
    Range,
    RangeLabel,
    Slider,
    Thumb,
    Track,
} from '@/components/CreditsAndPawns/FilterModal/DoubleRangeSliderStyles';
import {IFilterValues} from '@components/CreditsAndPawns/interfaces';
import {SLIDER_TYPE} from '@components/CreditsAndPawns/constants';

interface DoubleRangeSliderProps {
    min: number;
    max: number;
    step?: number;
    type?: string;
    sliderWidth?: string;
    minVal: number;
    maxVal: number;
    setVal: (filtersValues: IFilterValues) => void;
    filtersValues: IFilterValues;
    nameMin: string;
    nameMax: string;
}

const DoubleRangeSlider: React.FC<DoubleRangeSliderProps> = ({
    min,
    max,
    step = 1,
    type = '',
    sliderWidth = '189px',
    minVal,
    maxVal,
    setVal,
    filtersValues,
    nameMin,
    nameMax,
}) => {
    const minValRef = useRef<HTMLInputElement>(null);
    const maxValRef = useRef<HTMLInputElement>(null);
    const range = useRef<HTMLDivElement>(null);

    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    useEffect(() => {
        if (maxValRef.current && range.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value);

            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    useEffect(() => {
        if (minValRef.current && range.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(+event.target.value, maxVal - 1);
        setVal({...filtersValues, [nameMin]: value});
        event.target.value = value.toString();
    };

    const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(+event.target.value, minVal + 1);
        setVal({...filtersValues, [nameMax]: value});
        event.target.value = value.toString();
    };

    const sliderLabelMap = {
        [SLIDER_TYPE.CURRENCY]: (minVal: number, maxVal: number) => (
            <span>
                {minVal.toLocaleString('en-US', {style: 'currency', currency: 'USD'})} -
                {maxVal.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
            </span>
        ),
        [SLIDER_TYPE.PERCENTAGE]: (minVal: number, maxVal: number) => (
            <span>
                {minVal}% - {maxVal}%
            </span>
        ),
        default: (minVal: number, maxVal: number) => (
            <span>
                {minVal} - {maxVal}
            </span>
        ),
    };

    const SliderLabel = sliderLabelMap[type] || sliderLabelMap.default;

    return (
        <>
            <RangeLabel>{SliderLabel(minVal, maxVal)}</RangeLabel>
            <Slider sliderWidth={sliderWidth}>
                <Thumb
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onChange={handleMinChange}
                    zIndex={2}
                    step={step}
                    sliderWidth={sliderWidth}
                    type="range"
                />
                <Thumb
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    onChange={handleMaxChange}
                    zIndex={3}
                    step={step}
                    sliderWidth={sliderWidth}
                    type="range"
                />
                <Track />
                <Range ref={range} />
            </Slider>
        </>
    );
};

export default DoubleRangeSlider;
