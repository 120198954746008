import {toolTipText} from '@components/AuctionPass/Constants';
import {SpeechBubble, Text} from '@components/Shopify/Ecommerce/styles';
import React from 'react';

export const Tooltip = () => {
    return (
        <SpeechBubble>
            <Text Color="#D8FF03" FontWeight="700">
                {toolTipText.title}
            </Text>
            <Text Color="white" Width="80%" FlexWrap="wrap" MarginTop="10px">
                {toolTipText.text}
            </Text>
        </SpeechBubble>
    );
};
