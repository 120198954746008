import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';

import {ImgShow} from '@AdminLocations/style';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ManageRack from '@MenageLocations/Containers/ManageRack';
import ManageFloor from '@MenageLocations/Containers/ManageFloor';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import {ManageVault} from '@MenageLocations/Containers/ManageVault';
import ManagePension from '@MenageLocations/Containers/ManagePension';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {productsLocations} from '@Locations/interfaces';
import {
    fetchGetIdContainer,
    getLocationContainerC,
    getLocationFloor,
    getLocationPension,
    getLocationRack,
    getLocationVault,
} from '@AdminLocations/redux/actions/LocationAction';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';

export default function AssigmentLocations(): JSX.Element {
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const branch = useSelector((state: RootState) => {
        return state.nearByBranches?.branch;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/', label: 'Ubicaciones'},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: productsLoations.productId?.data.pladgeDescription},
    ];
    const pageNumber = 1;
    const pageSize = 1000;
    const formLocations = (type: number) => {
        const MESSAGE_LOCATIONS: {[key: number]: JSX.Element} = {
            1: <ManageRack />,
            2: <ManageVault />,
            3: <ManagePension />,
            4: <ManageFloor />,
        };
        return MESSAGE_LOCATIONS[type];
    };

    useEffect(() => {
        dispatch(getLocationContainerC(Number(branch), token));
    }, [branch]);

    useEffect(() => {
        dispatch(getLocationFloor(Number(branch), pageSize, pageNumber, token));
        dispatch(getLocationPension(Number(branch), pageSize, pageNumber, token));
        dispatch(getLocationRack(Number(branch), pageSize, pageNumber, token));
        dispatch(getLocationVault(Number(branch), pageNumber, pageSize, token));
    }, []);

    return (
        <ContainerFlex Padding="0.656rem 23.5rem 0 6.25rem" FlexDir="column" Align="start">
            {productsLoations.loading ? (
                <LoadingGeneral />
            ) : (
                <>
                    <ContainerFlex Justify="start" PaddingB="2.438rem">
                        <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="start">
                        <ContainerFlex Justify="start" Radius="5px" FlexDir="column" Width="300px">
                            <ImgShow src={productsLoations.productId.data.imageUrl} />

                            <ContainerFlex
                                Align="start"
                                Padding="1.813rem 0 0.625rem 0"
                                FlexDir="column"
                            >
                                <Text FontWeight="300" FontSize="0.75rem" Color="#CBCED1">
                                    SKU
                                </Text>
                                <Text FontSize="0.875rem">
                                    {productsLoations.productId.data.sku}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="start" Padding="0.625rem 0" FlexDir="column">
                                <Text FontWeight="300" FontSize="0.75rem" Color="#CBCED1">
                                    Articulo
                                </Text>
                                <Text FontSize="1.125rem" wSpace="normal" Height="auto">
                                    {productsLoations.productId.data.pladgeDescription}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="start" Padding="0.625rem 0" FlexDir="column">
                                <Text FontWeight="300" FontSize="0.75rem" Color="#CBCED1">
                                    Tipo de transacción
                                </Text>
                                <Text FontSize="0.875rem">
                                    {productsLoations.productId.data.transactionType}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="start" Padding="0.625rem 0" FlexDir="column">
                                <Text FontWeight="300" FontSize="0.75rem" Color="#CBCED1">
                                    Fecha de Contrato
                                </Text>
                                <Text FontSize="0.875rem">
                                    {productsLoations.productId.data.pawnDate}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="start" Padding="0.625rem 0" FlexDir="column">
                                <Text FontWeight="300" FontSize="0.75rem" Color="#CBCED1">
                                    Fecha de Vencimiento
                                </Text>
                                <Text FontSize="0.875rem">
                                    {productsLoations.productId.data.dateDue}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="750px"
                            Justify="start"
                            Align="start"
                            Padding="0 1.688rem"
                            MarginBt="2rem"
                            FlexDir="column"
                            Bl="1px solid #E4E7E9"
                        >
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text FontSize="0.75rem" Color="#6D6E71" required>
                                    Sucursal
                                </Text>
                                <Text FontWeight="500" Transform="capitalize">
                                    {productsLoations.branchName}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Padding="2.625rem 0 1.938rem 0" Justify="space-between">
                                {locations.containerC && (
                                    <ContainerFlex
                                        Height="auto"
                                        Align="start"
                                        Justify="space-between"
                                    >
                                        {locations.containerC.map((item, index: number) => (
                                            <ContainerFlex
                                                FlexDir="column"
                                                key={index}
                                                Width="98px"
                                            >
                                                <ContainerFlex
                                                    Border={
                                                        locations.locationsidcontainer ===
                                                        item.containerId
                                                            ? '2px solid #7AC143'
                                                            : '2px solid #E4E7E9'
                                                    }
                                                    Height="98px"
                                                    Width="98px"
                                                    Radius="5px"
                                                    Cursor="pointer"
                                                    onClick={() =>
                                                        dispatch(
                                                            fetchGetIdContainer(item.containerId)
                                                        )
                                                    }
                                                >
                                                    <img
                                                        alt={item.urlActive}
                                                        width="50px"
                                                        height="50px"
                                                        src={
                                                            locations.locationsidcontainer ===
                                                            item.containerId
                                                                ? item.urlActive
                                                                : item.urlInactive
                                                        }
                                                    />
                                                </ContainerFlex>
                                                <ContainerFlex Width="110px" Padding="11px 0">
                                                    <Text wSpace="pre">{item.container}</Text>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        ))}
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                            <ContainerFlex>
                                {formLocations(locations.locationsidcontainer)}
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
}
