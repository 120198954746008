import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerForm,
    Input,
    Text,
} from '@Shopify/Ecommerce/styles';
import {COOWNEREDIT} from '@Quoter/Steps/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {showAddCoOwner} from '@Quoter/Steps/interface';
import {SelectLada} from '@Quoter/CoownerBeneficiary/SelectLada';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {formAddCoowner} from '@Quoter/CoownerBeneficiary/Redux/Actions/FormAddCoOwnerActions';
import {IFormAddCoowner} from '@components/Quoter/CoownerBeneficiary/Redux/interfaces';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';

export const AddCoOwnerBenefist: React.FC<showAddCoOwner> = ({showAdd, setShowAdd}) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const {
        client: {id},
    } = useSelector((state: RootState) => state.clientList) as IClientData;

    const dispatch = useDispatch();

    const [nationSelected, setNationSelected] = useState<IGetAllNations | null>();
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: {errors},
    } = useForm<IFormAddCoowner>({
        defaultValues: {
            clientId: id,
            name: '',
            flag: '',
            lada: '',
            phone: '',
            email: '',
            contactType: 0,
        },
    });

    useEffect(() => {
        if (nationSelected) {
            setValue('lada', nationSelected.lada);
            setValue('flag', nationSelected.urlFlag);
        }
    }, [nationSelected]);

    const onSubmitAdd = (data: IFormAddCoowner) => {
        dispatch(formAddCoowner(token, data));
        setShowAdd(false);
    };

    return (
        <Modal
            Top="0"
            modalState={showAdd}
            changeModalState={() => {}}
            titleModalState={true}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmitAdd)}
                FlexDir="column"
                Width="430px"
                Height="100vh"
                Padding="1rem"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                    <ContainerFlex Justify="space-between" Height="auto">
                        <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                            {COOWNEREDIT.TITLE_ADD}
                        </Text>
                        <Icons {...styles.iconSearch} onClick={() => setShowAdd(false)}>
                            {'close'}
                        </Icons>
                    </ContainerFlex>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="80px">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.NAME}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder=""
                            {...register('name', {
                                required: 'Ingrese nombre',
                                pattern: {
                                    value: /^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
                                    message: 'Carácter inválido, ingrese solo letras.',
                                },
                            })}
                            value={watch('name')}
                            onChange={(e) => setValue('name', e.target.value)}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.name?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="80px">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.EMAIL}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder=""
                            {...register('email', {
                                required: 'Ingrese correo',
                            })}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.email?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="80px">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.PHONE}
                        </Text>
                        <ContainerFlex>
                            <SelectLada handleNationSelected={setNationSelected} />
                            <Input
                                Radius="0"
                                RadiusTR="50px"
                                RadiusBR="50px"
                                type="number"
                                Padding="12px"
                                FontSize="0.875rem"
                                placeholder=""
                                {...register('phone', {
                                    required: 'Ingrese teléfono',
                                })}
                            />
                        </ContainerFlex>
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.phone?.message}
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Height="2.5rem">
                    <ButtonGenerals
                        Width="12rem"
                        BackGC="#FFFFFF"
                        Border="1px solid #5A5AFF"
                        Color="#5A5AFF"
                        HColor="#FFFFFF"
                        FontWeight="500"
                        onClick={() => {
                            setShowAdd(false);
                        }}
                    >
                        {'Cancelar'}
                    </ButtonGenerals>
                    <ButtonGeneral
                        width="12rem"
                        text="Guardar cambios"
                        height="40px"
                        cursor="pointer"
                        type="submit"
                    />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
