// reducers.ts
import {ADD_EMAIL_USER} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    email: '',
};

const addEmail = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EMAIL_USER:
            return {
                ...state,
                email: action.payload,
            };
        default:
            return state;
    }
};

export default addEmail;
