import {ISaveLoansJob, ISaveLoansJobReducer} from '@components/PersonalLoans/interfaces';
import {SAVE_LOANS_JOB, SAVE_LOANS_RESET} from '@components/PersonalLoans/Redux/Types/Types';

const initialState: ISaveLoansJob = {
    jobLoans: {
        personalInformationId: 0,
        catEmploymentSituationId: {
            value: 0,
            label: '',
        },
        nameCompany: '',
        addresCompany: '',
        catLadaId: '',
        phoneCompany: '',
        job: '',
        catLaborOldId: {
            value: 0,
            label: '',
        },
        monthlyIncome: 0,
    },
};

const SaveLoansJob = (state = initialState, action: ISaveLoansJobReducer): ISaveLoansJob => {
    switch (action.type) {
        case SAVE_LOANS_JOB:
            return {
                ...state,
                jobLoans: action.jobLoans,
            };
        case SAVE_LOANS_RESET:
            return initialState;
        default:
            return state;
    }
};
export default SaveLoansJob;
