import {AxiosError, AxiosResponse} from 'axios';
import {
    TARGET_EDIT_COOWNER,
    TARGET_EDIT_RESET,
    UPDATE_EDIT_COOWNER_ERROR,
    UPDATE_EDIT_COOWNER_START,
    UPDATE_EDIT_COOWNER_SUCCESS,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {DataUpdateCoOwner, IDataAllCoowner} from '@Quoter/CoownerBeneficiary/Redux/interfaces';

const initialState: DataUpdateCoOwner = {
    coOwnerData: null,
    targetCoOwner: null,
    loading: false,
    error: false,
    errorData: null,
};

const UpdateCoOwner = (
    state = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; item: IDataAllCoowner}
) => {
    switch (action.type) {
        case UPDATE_EDIT_COOWNER_START:
            return {...state, loading: true, error: false};
        case UPDATE_EDIT_COOWNER_SUCCESS:
            return {...state, loading: false, error: false, coOwnerData: action.payload.data};
        case UPDATE_EDIT_COOWNER_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case TARGET_EDIT_COOWNER:
            return {...state, loading: false, error: false, targetCoOwner: action.item};
        case TARGET_EDIT_RESET:
            return initialState;
        default:
            return state;
    }
};
export default UpdateCoOwner;
