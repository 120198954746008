import {AppDispatch} from '@/config/store';
import axios, {AxiosError} from 'axios';

import {URL} from '@config/constants/index';
import {GET_USER_BY_LEVEL, DEL_USERS} from '@components/LocationsBranch/Redux/types';
import {IUserData} from '@components/LocationsBranch/interfaces';
import {removeAddedUsersByIds} from '@components/LocationsBranch/Redux/Action/AddUser';
import {defaultCompanyUsersData} from '@components/LocationsBranch/constants';

const getUserByLevelStart = () => ({
    type: GET_USER_BY_LEVEL.GET_USER_START,
});
const getUserByLevelSuccess = (data: IUserData[]) => ({
    type: GET_USER_BY_LEVEL.GET_USER_SUCCESS,
    userData: data,
});

const getUserByLevelError = (error: AxiosError) => ({
    type: GET_USER_BY_LEVEL.GET_USER_ERROR,
    error: error,
});

export const mergeAddedUsers = (addedUsers: IUserData[]) => ({
    type: GET_USER_BY_LEVEL.ADD_USERS,
    userData: addedUsers,
});

export const updateUsersPagination = (newPage: number) => ({
    type: GET_USER_BY_LEVEL.UPDATE_PAGINATION,
    newPage,
});

export const sortUsers = (sortBy: keyof IUserData, sortDirection: string) => ({
    type: GET_USER_BY_LEVEL.SORT_USERS,
    sortBy,
    sortDirection,
});

export const toggleUserSelect = (userId: number) => ({
    type: GET_USER_BY_LEVEL.TOGGLE_USER_SELECT,
    userId,
});

export const toggleAllUsers = (selectAll: boolean) => ({
    type: GET_USER_BY_LEVEL.TOGGLE_ALL_USERS,
    selectAll,
});

export const GetUserByLevel = (token: string, levelId?: number) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUserByLevelStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUserLevelById}`,
                {
                    headers,
                    params: {
                        levelId,
                    },
                }
            );
            if (
                typeof response.data !== 'string' &&
                response.data !== 'No se encontraron usuarios.'
            ) {
                const UsersData: IUserData[] = response.data.data;
                dispatch(getUserByLevelSuccess(UsersData));
            } else {
                dispatch(getUserByLevelSuccess(defaultCompanyUsersData.allUserData));
            }
        } catch (error: unknown) {
            dispatch(getUserByLevelError(error as AxiosError));
        }
    };
};

export const removeUsers = (userIds: number[] = []) => ({
    type: GET_USER_BY_LEVEL.REMOVE_USERS,
    userIds,
});

export const removeSelectedUsers = (userIds: number[] = []) => {
    return (dispatch: AppDispatch) => {
        dispatch(removeUsers(userIds));
        dispatch(removeAddedUsersByIds(userIds));
    };
};

export const getIDUser = (id: number) => ({
    type: DEL_USERS.ADD_ID_USER,
    idUser: id,
});

export const saveAllIdUsers = (ids: number[]) => ({
    type: DEL_USERS.SAVE_USERS_ID,
    saveAllUsers: ids,
});

export const resetSaveUsers = () => ({type: DEL_USERS.RESET_SAVE});
