import React from 'react';
import {useSelector} from 'react-redux';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ComplementCards} from '@components/Home/Admin/ComplementsCards';
import {RootState} from '@/config/store';
import {ICompanyBenefits} from '@/components/SignUp/interfaces';
import {COMPANY_REINFORCENMENT} from '@/components/Home/Admin/constants';
export const CompanyReinforcement = () => {
    const addons = useSelector((state: RootState) => state.catCompanyBenefits?.data);
    return (
        <ContainerFlex
            Padding="16px"
            Width="100%"
            backG="#FAFAFA"
            Radius="16px"
            Justify="space-between"
            FlexWrap="wrap"
            RowGap="18px"
            Height="auto"
        >
            <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500" Width="70%">
                {COMPANY_REINFORCENMENT.Title}
            </Text>
            <Text
                FontSize="1rem"
                Color="#5A5AFF"
                FontWeight="500"
                Cursor="pointer"
                Width="20%"
                Justify="end"
            >
                {COMPANY_REINFORCENMENT.Text}
            </Text>

            {addons &&
                addons.length > 0 &&
                addons?.slice(0, 3).map((data: ICompanyBenefits) => {
                    return (
                        <ComplementCards
                            name={data.benefitName}
                            description={data.description}
                            cost={data.price}
                            id={data.id}
                            key={data.id}
                        />
                    );
                })}
        </ContainerFlex>
    );
};
