import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants';

import {
    GET_ORGANIZATIONS,
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATIONS_ERROR,
    GET_TREE,
    GET_TREE_SUCCESS,
    GET_TREE_ERROR,
    GET_TREE_SUCCESS_COPY,
    RESET_TREE,
} from '@General/BranchTree/Redux/Types';
import {AppDispatch} from '@/config/store';
export const resetTree = () => {
    return {
        type: RESET_TREE,
    };
};
export const getOrganizationsTree = () => ({type: GET_ORGANIZATIONS});
export const getOrganizationsTreeSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ORGANIZATIONS_SUCCESS,
        payload: result,
    };
};
export const getOrganizationsTreeSuccessCopy = (result: AxiosResponse) => {
    return {
        type: GET_TREE_SUCCESS_COPY,
        payload: result,
    };
};
export const getOrganizationsTreeError = () => ({
    type: GET_ORGANIZATIONS_ERROR,
});

export const getOrganizationsTreeAxios = (
    companyId: number,
    pageNumber: number,
    pageSize: number,
    token: string
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getOrganizationsTree());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchByRole.replace(
                    '{companyId}',
                    String(companyId)
                )
                    .replace('{pageNumber}', String(pageNumber))
                    .replace('{pageSize}', String(pageSize))}`,
                {headers}
            );
            dispatch(getOrganizationsTreeSuccess(response.data));
            dispatch(getOrganizationsTreeSuccessCopy(response.data));
        } catch (error) {
            dispatch(getOrganizationsTreeError());
        }
    };
};

export const getTree = () => ({type: GET_TREE});
export const getTreeSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TREE_SUCCESS,
        payload: result,
    };
};
export const getTreeTreeError = () => ({
    type: GET_TREE_ERROR,
});

export const getTreeComponent = (token: string, companyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTree());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.getBranchTree.replace(
                    '{companyId}',
                    companyId
                )}`,
                {
                    headers,
                }
            );
            dispatch(getTreeSuccess(response));
        } catch (error) {
            dispatch(getTreeTreeError());
        }
    };
};
