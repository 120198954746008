import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import plusIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/plusIcon.svg';
import {IOtherCardsProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {Card} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Card';

export const OtherCards: React.FC<IOtherCardsProps> = ({
    otherCards,
    showDynamicFields,
    setShowDynamicFields,
}) => {
    return (
        <>
            <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
                <Text Color="#2A2C2F" FontWeight="700" FontSize="0.875rem">
                    {PAYMENT_METHOD.OTHER_CARDS_TITLE}
                </Text>

                <ContainerFlex FlexDir="column" Gap="1rem">
                    {otherCards.map((card, index) => (
                        <Card {...card} key={index} isVisa={false} />
                    ))}
                </ContainerFlex>

                {!showDynamicFields && (
                    <ContainerFlex
                        Justify="start"
                        Bt="1px solid #E8E9EA"
                        Padding="1rem 0px 0px 0px"
                    >
                        <Text
                            Gap="0.5rem"
                            Cursor="pointer"
                            Color="#5A5AFF"
                            FontWeight="700"
                            Width="max-content"
                            onClick={() => setShowDynamicFields(!showDynamicFields)}
                        >
                            <Image src={plusIcon} Width="24px" Height="24px" />
                            {PAYMENT_METHOD.ADD_NEW_CARD}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </>
    );
};
