import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {TransactionDetails} from '@/components/TransactionsDetails/TransactionsDetails';
import closed from '@images/closeRed.svg';
import {ImageLogo} from '@/components/TransactionsDetails/Operational/styles';
import {DETAIL_COMPONENT} from '@/components/TransactionsDetails/Operational/Constants';
import {useNavigate} from 'react-router-dom';

export const ProductDetailsContainer = () => {
    const navigate = useNavigate();
    return (
        <ContainerCellGrid Padding="16px 24px">
            <TransactionDetails />
            <ContainerFlex Width="max-content" Height="max-content" Cursor="pointer" Align="start">
                <ImageLogo
                    onClick={() => navigate('/CashFlow')}
                    Width="24px"
                    Height="24px"
                    src={closed}
                />
                <Text Cursor="pointer" Color=" #FF6357" FontSize="1rem" FontWeight="500">
                    {DETAIL_COMPONENT.CLOUSED}
                </Text>
            </ContainerFlex>
        </ContainerCellGrid>
    );
};
