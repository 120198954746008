import {
    GET_ALL_CAT_FREQUENCY_START,
    GET_ALL_CAT_FREQUENCY_ERROR,
    GET_ALL_CAT_FREQUENCY_SUCCESS,
    GET_AMOUNT_TERM_ID_START,
    GET_AMOUNT_TERM_ID_SUCCESS,
    GET_AMOUNT_TERM_ID_ERROR,
    SET_FREQUENCY_TERM_CREDIT_ID,
} from '@components/CreditQuoter/Redux/Types/Types';
import {AxiosResponse} from 'axios';
import {IGetAllCatFrequency} from '@components/CreditQuoter/interfaces';

const initialState: IGetAllCatFrequency = {
    data: null,
    termId: null,
    loading: false,
    error: false,
    frequencyTermCreditId: null,
};

const getAllCatFrequency = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; frequencyTermCreditId: number}
) => {
    switch (action.type) {
        case GET_ALL_CAT_FREQUENCY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_CAT_FREQUENCY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ALL_CAT_FREQUENCY_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_AMOUNT_TERM_ID_START:
            return {
                ...state,
                loading: true,
            };
        case GET_AMOUNT_TERM_ID_SUCCESS:
            return {
                ...state,
                termId: action.payload.data.termId,
                loading: false,
                error: false,
            };
        case GET_AMOUNT_TERM_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case SET_FREQUENCY_TERM_CREDIT_ID:
            return {
                ...state,
                frequencyTermCreditId: action.frequencyTermCreditId,
            };
        default:
            return state;
    }
};

export default getAllCatFrequency;
