import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {IAccordionProps} from '@/components/PersonalLoans/interfaces';
import {
    AccordionStyled,
    AccordionDetailsStyled,
    AccordionSummaryStyled,
} from '@/components/PersonalLoans/styles';

const PersonalLoansAccordion: React.FC<IAccordionProps> = ({
    summaryIcon,
    summaryTitle,
    summaryParagraph,
    children,
    expanded,
    onChange,
    expandedIcon,
    collapsedIcon,
}) => {
    return (
        <AccordionStyled
            Width="100%"
            BorderColor={`${expanded ? '#5A5AFF' : '#E8E9EA'}`}
            BorderWidth="1px"
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummaryStyled
                expandIcon={
                    <Image
                        src={expanded ? expandedIcon : collapsedIcon}
                        height="24px"
                        width="25px"
                    />
                }
            >
                <ContainerFlex Justify="start" Align="start" Width="100%" Gap="8px">
                    <ContainerFlex
                        Width="44px"
                        Height="44px"
                        Justify="center"
                        Align="center"
                        backG="#F4F5F5"
                        Radius="50%"
                    >
                        <Image src={summaryIcon} width="24px" height="24px" />
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Height="100%"
                        Width="100%"
                        Gap="4px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {summaryTitle}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="0.88rem"
                            Color="#54575C"
                            FontStyle="normal"
                            LHeight="1.05rem"
                        >
                            {summaryParagraph}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
                <ContainerFlex>{children}</ContainerFlex>
            </AccordionDetailsStyled>
        </AccordionStyled>
    );
};

export default PersonalLoansAccordion;
