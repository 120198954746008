import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {catCompany} from '@components/SignUp/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {NEWGROUP} from '@components/LocationsBranch/constants';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import * as style from '@components/LocationsBranch/stylesLocations';
import {IBusinessLines} from '@components/LocationsBranch/interfaces';
import {saveBusiness} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const BusinessLines = ({setValue, nameBusiness, errors}: IBusinessLines) => {
    const dispatch = useDispatch();
    const {lines, business} = useSelector((state: RootState) => ({
        lines: state.groupBranchPersistence,
        business: state.catPlaceAndBusiness,
    }));

    const saveLineBusiness = (id: number) => {
        if (lines.businessLine.includes(id))
            dispatch(saveBusiness(lines.businessLine.filter((i) => i !== id)));
        else dispatch(saveBusiness([...lines.businessLine, id]));
    };

    useEffect(() => setValue(nameBusiness, lines.businessLine), [lines.businessLine]);

    return (
        <ContainerFlex {...style.contentGral} Gap="none">
            <Text {...style.contentGralTitle} MarginB="1rem">
                {NEWGROUP.LINEBUSINESS}
            </Text>
            {business.catBusiness &&
                business.catBusiness.map((data: catCompany) => (
                    <ContainerFlex
                        onClick={() => saveLineBusiness(data.id)}
                        key={data.id}
                        Justify="space-between"
                        Padding="0.5rem"
                    >
                        <Text Color="#2A2C2F">{data.bussiness}</Text>
                        <GreenSwitch
                            defaultChecked={false}
                            checked={lines.businessLine.includes(data.id)}
                        />
                    </ContainerFlex>
                ))}

            {errors[nameBusiness] && (
                <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                    {errors[nameBusiness]?.message as string}
                </Text>
            )}
        </ContainerFlex>
    );
};
