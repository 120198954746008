import {ResponseData} from '@components/ManageUser/interface';
import {
    GET_PASSWORD_RANDOM,
    GET_PASSWORD_RANDOM_SUCCESS,
    GET_PASSWORD_RANDOM_ERROR,
} from '@components/ManageUser/Redux/types';

const initialState = {
    data: undefined,
    error: false,
    loading: false,
};

export const getPasswordRandom = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_PASSWORD_RANDOM:
            return {...state, loading: true, error: false};
        case GET_PASSWORD_RANDOM_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case GET_PASSWORD_RANDOM_ERROR:
            return {...state, loading: true, error: true};
        default:
            return state;
    }
};

export default getPasswordRandom;
