export const GET_TRASACTION = 'GET_TRASACTION';
export const GET_TRASACTION_SUCCESS = 'GET_TRASACTION_SUCCESS';
export const GET_TRASACTION_ERROR = 'GET_TRASACTION_ERROR';

export const GET_CASHFLOW_DETAILS = 'GET_CASHFLOW';
export const GET_CASHFLOW_DETAILS_SUCCESS = 'GET_CASHFLOW_SUCCESS';
export const GET_CASHFLOW_DETAILS_ERROR = 'GET_CASHFLOW_ERROR';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const GET_PAYMENT_TYPE = 'GET_PAYMENT_TYPE';
export const GET_PAYMENT_TYPE_SUCCESS = 'GET_PAYMENT_TYPE_SUCCESS';
export const GET_PAYMENT_TYPE_ERROR = 'GET_PAYMENT_TYPE_ERROR';

export const GET_EXPENSE_TYPE = 'GET_EXPENSE_TYPE';
export const GET_EXPENSE_TYPE_SUCCESS = 'GET_EXPENSE_TYPE_SUCCESS';
export const GET_EXPENSE_TYPE_ERROR = 'GET_EXPENSE_TYPE_ERROR';

export const GET_BRANCH_PROVIDERS = 'GET_BRANCH_PROVIDERS';
export const GET_BRANCH_PROVIDERS_SUCCESS = 'GET_BRANCH_PROVIDERS_SUCCESS';
export const GET_BRANCH_PROVIDERS_ERROR = 'GET_BRANCH_PROVIDERS_ERROR';

export const POST_CREATE_EXPENSE = 'POST_CREATE_EXPENSE';
export const POST_CREATE_EXPENSE_SUCCESS = 'POST_CREATE_EXPENSE_SUCCESS';
export const POST_CREATE_EXPENSE_ERROR = 'POST_CREATE_EXPENSE_ERROR';
export const RESET_EXPENSE_CREATED_FLAG = 'RESET_EXPENSE_CREATED_FLAG';

export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';
export const GET_EXPENSES_ERROR = 'GET_EXPENSES_ERROR';

export const FILTERS_PERSISTANCE = 'FILTERS_PERSISTANCE';
export const FILTERS_PERSISTANCE_RESET = 'FILTERS_PERSISTANCE_RESET';

export const SELECT_TABLE = 'SELECT_TABLE';
