import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';

import {SEARCH_LEVEL, SEARCH_LEVEL_ERROR, SEARCH_LEVEL_SUCCESS} from '@TypesOrgCreation/types';
import StringUtils from '@GenericScripts/utils';
import {updatePageNumber} from '@ActionsOrgCreation/CheckedState';
import {AppDispatch} from '@/config/store';
import {ISearch} from '@OrganizationCreation/interface';

export const fetchLevel = () => {
    return {
        type: SEARCH_LEVEL,
    };
};
export const searchLevels = (result: AxiosResponse) => {
    return {
        type: SEARCH_LEVEL_SUCCESS,
        payload: result,
    };
};
export const searchLevelError = () => {
    return {
        type: SEARCH_LEVEL_ERROR,
    };
};
export function SearchLevelOrganization(filters: ISearch, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchLevel());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SearchLevels}${queryString}`,
                {headers}
            );
            dispatch(searchLevels(response));
            dispatch(updatePageNumber(1));
        } catch (err) {
            dispatch(searchLevelError());
        }
    };
}
