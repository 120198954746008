import React from 'react';

import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import checkMark from '@components/Branches/Images/Checkmark.svg';
import {IPlanLineInfoProps} from '@components/MyPlan/interfaces';

const PlanLineInfo: React.FC<IPlanLineInfoProps> = ({
    label,
    detail,
    icon,
    detailLabel,
    fontSize,
    fontWeight,
}) => {
    return (
        <ContainerFlex Justify="space-between" Align="center">
            <ContainerFlex Align="center" Justify="start" Gap="8px">
                {icon && <Image src={checkMark} Height="24px" Width="24px" />}
                <Text
                    FontSize={fontSize}
                    Color="#2A2C2F"
                    FontWeight={fontWeight}
                    PaddingL={!icon ? '2rem' : '0'}
                >
                    {label}
                </Text>
            </ContainerFlex>
            {!detailLabel ? (
                <Text FontSize={fontSize} Color="#2A2C2F" FontWeight={fontWeight} oFlow="visible">
                    {detail}
                </Text>
            ) : (
                <ContainerFlex FlexDir="column" Justify="center" Align="flex-end" Gap="4px">
                    <Text
                        FontSize={fontSize}
                        Color="#2A2C2F"
                        FontWeight={fontWeight}
                        oFlow="visible"
                    >
                        {detail}
                    </Text>
                    <Text FontSize="0.75rem" FontWeight="400" Color="#71767D" oFlow="visible">
                        {detailLabel}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default PlanLineInfo;
