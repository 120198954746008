import React, {useState, useEffect, useRef} from 'react';
import {
    OptionsMenu,
    SelectContainer,
    SelectField,
    Option,
} from '@/components/CashFlowActions/Operational/styles';
import {CustomSelectProps} from '@/components/CashFlowActions/Operational/interfaces';
import {BTN} from '@/components/CashFlowActions/Operational/Constants';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import arrow from '@images/arrowBottom.svg';
import {Text} from '@/components/Shopify/Ecommerce/styles';

export const CustomSelect = ({options, value, onChange, disabled}: CustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    const handleSelect = (optionValue: string) => {
        onChange(optionValue);
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <SelectContainer ref={selectRef}>
            <SelectField onClick={() => !disabled && setIsOpen(!isOpen)} isOpen={isOpen}>
                {value ? (
                    options.find((option) => option.value === value)?.label
                ) : (
                    <Text Color="#AAA">{BTN.SELECT}</Text>
                )}
                <ImageLogo width="24px" Height="24px" src={arrow} />
            </SelectField>
            <OptionsMenu isOpen={isOpen}>
                {options.map((option) => (
                    <Option
                        key={option.value}
                        isSelected={value === option.value}
                        onClick={() => handleSelect(option.value)}
                    >
                        {option.label}
                    </Option>
                ))}
            </OptionsMenu>
        </SelectContainer>
    );
};
