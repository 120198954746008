import styled from '@emotion/styled';
import {IPropsIconSvg} from '@Quoter/Icons/interfacesStyles';

export const IconSvg = styled.svg<IPropsIconSvg>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    color: ${(props) => props.Color};
    fill: 'none';
    transition: ${(props) => props.Transition};
    rotate: ${(props) => props.Rotate};
    &:hover path {
        fill: ${(props) => props.HoverF};
    }
`;
