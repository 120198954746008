import {
    GET_AUDITORS,
    GET_AUDITORS_SUCCESS,
    GET_AUDITORS_ERROR,
} from '@TypesAuditAssignment/AuditorsTypes';

const initialState = {
    auditors: [],
    error: false,
    loading: false,
};

const auditors = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_AUDITORS:
            return {
                ...state,
                loading: true,
            };
        case GET_AUDITORS_SUCCESS:
            return {
                ...state,
                auditors: action.value,
                loading: false,
            };
        case GET_AUDITORS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};
export default auditors;
