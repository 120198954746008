import {ScoreStyle, FingerClickEvent} from '@Shopify/ListClient/interfaces';
import {IFingerData} from '@Shopify/ListClient//redux/interfaces';
export const NEW_CLIENT = {
    TITLE: 'Crear nuevo cliente',
};

export const PROGRESSITEMS = [
    {step: 1, title: 'Información general'},
    {step: 2, title: 'Comprobantes'},
    {step: 3, title: 'Registro de huellas'},
];

export const PROOF_LABELS = {
    ADD_PROOF: 'Agrega comprobantes',
    TYPE_OF_PROOF_OF_ADDRESS: 'Tipo de comporbante de domicilio',
    EFFECTIVE_DATE: 'Inicio de vigencia',
    SELECT_IMAGE: 'Subir Imagen',
    IMAGE_SIZE: '25MB Max.',
};

export const INPUT_LABELS = {
    OPTIONAL: '(Opcional)',
};

export const CREATE_CLIENT_LABELS = {
    NOMBRES: 'Nombres(s)',
    APELLIDO_PATERNO: 'Apellido paterno',
    APELLIDO_MATERNO: 'Apellido materno',
    TELEFONO: 'Teléfono',
    CORREO_ELECTRONICO: 'Correo electrónico',
    ESTADO_CIVIL: 'Estado Civil',
    ACTIVIDAD_ECONOMICA: 'Actividad económica',
    GENERO: 'Género',
    PAIS_DONDE_NACIO: 'Pais donde nació',
    RFC: 'R.F.C',
    CURP: 'CURP',
    NACIONALIDAD: 'Nacionalidad',
    TIPO_DE_IDENTIFICACION: 'Tipo de identificación',
    NUMERO_DE_IDENTIFICACION: 'Número de identificación',
    VIGENCIA: 'Vigencia',
    SUBIR_DOCUMENTO: 'Subir documento de identificación',
    SEGUNDO_CORREO_ELECTRONICO: 'Segundo correo electrónico',
    TELEFONO_FIJO: 'Teléfono fijo',
    TELEFONO_CELULAR: 'Teléfono celular',
    OTRO_TELEFONO: 'Otro teléfono',
    DIRECCION: 'Dirección',
    NUMERO_EXTERIOR: 'Número exterior',
    NUMERO_INTERIOR: 'Número interior',
    CODIGO_POSTAL: 'Codigo postal',
    COLONIA: 'Colonia',
    MUNICIPIO_ALCALDIA: 'Municipio/Alcaldía',
    CIUDAD: 'Ciudad',
    ESTADO: 'Estado',
    NOTAS: 'Notas',
    COTITULAR_NOMBRES: 'Nombres(s)',
    COTITULAR_APELLIDOS: 'Apellido(s)',
    COTITULAR_TELEFONO: 'Número de teléfono',
    COTITULAR_CORREO_ELECTRONICO: 'Correo electrónico',
    COMO_SE_ENTERO: 'Cómo se enteró de nosotros?',
    OTRO: 'Otro',
};

export const FORM_ERRORS = {
    PROOF_TYPE: 'Ocurrió un error al obtener los tipos de comprobantes.',
    MEDIA_TYPE_ERROR: 'Imagen no es valida (.jpeg, .jpg). Por favor, intenta nuevamente.',
    COMPLETE_PROOF_DOCUMENT: 'Ocurrió un error al procesar comprobante.',
    GENERAL_ERROR: 'Ocurrió un error durante el proceso. Por favor, contacta al administrador.',
};

export const formNewClient = {
    imageUser: '',
    names: '',
    lastName: '',
    secondLastName: '',
    mainPhone: '',
    mainEmail: '',
    civilStatusId: '',
    economicActivityId: '',
    genderId: '',
    countryOfBirthId: '',
    rfc: '',
    curp: '',
    nationality: '',
    catIdentificationTypeId: '',
    identificactionNumber: '',
    validity: '',
    secondEmail: '',
    landline: '',
    cellPhone: '',
    anotherPhone: '',
    street: '',
    externalNumber: '',
    internalNumber: '',
    cp: '',
    colonyId: '',
    municipalityId: '',
    cityId: '',
    stateId: '',
    notes: '',
    cotitularId: '2',
    cotitularNombres0: '',
    cotitularApellidos0: '',
    cotitularTelefono0: '',
    cotitularCorreoElectronico0: '',
    cotitularNombres1: '',
    cotitularApellidos1: '',
    cotitularTelefono1: '',
    cotitularCorreoElectronico1: '',
    cotitularNombres2: '',
    cotitularApellidos2: '',
    cotitularTelefono2: '',
    cotitularCorreoElectronico2: '',
    cotitularNombres3: '',
    cotitularApellidos3: '',
    cotitularTelefono3: '',
    cotitularCorreoElectronico3: '',
    catCustomerMarketingId: 0,
    acceptMarketing: false,
    other: '',
    clientId: '0',
    typeOfReceiptId: '',
    effectiveDate: '',
    imageSrcFile: '',
    imageProof: '',
    imageFileExtension: '',
};

export const TableText = {
    Clients: 'Clientes',
    NewClient: 'Nuevo cliente',
    NoClients: 'No se encontraron clientes',
};

export const ClientTableHeaderText = {
    ClientName: 'Nombre',
    Email: 'Correo electrónico',
    Phone: 'Teléfono',
    Score: 'Score',
};

export const ClientSort = {
    Asc: 'asc',
    Desc: 'desc',
};
export const ClientSortColumns: {[key: string]: number} = {
    name: 1,
    email: 2,
    score: 3,
};
export const ClientDefaultPrompts = {
    SearchPlaceHolder: 'Busca nombre, correo electrónico o teléfono',
    NoRecordsFound: 'No se encontraron resultados',
    ModifyParams: 'Modifica los parametros para obtener resultados',
};

export const ClientAddNewFormLabels = {
    CreateNewClient: 'Creación de un Cliente',
    GeneralData: 'Datos Generales',
    EnterMainInfo: 'Ingresa la información principal del cliente.',
    Names: 'Nombre(s)',
    LastName: 'Apellido Paterno',
    MaidenName: 'Apellido Materno',
    IDType: 'Tipo de identificación',
    AdditionalInfo: 'Información adicional',
    IDNumber: 'Número de identificación',
};

export const ClientListPageSize = 10;

export const ClientScoresText = {
    Excelent: 'Excelente',
    VeryGood: 'Muy Bueno',
    Good: 'Bueno',
    Regular: 'Regular',
    Poor: 'Pobre',
};

export const scoreStyles: {[key: string]: ScoreStyle} = {
    [ClientScoresText.Excelent]: {
        border: '0.5px solid #008000',
        backgroundColor: '#E4F7EC',
        color: '#174A2E',
    },
    [ClientScoresText.VeryGood]: {
        border: '0.5px solid #acacff',
        backgroundColor: '#e5e5ff',
        color: '#0D166B',
    },
    [ClientScoresText.Good]: {
        border: '0.5px solid #eac76b',
        backgroundColor: '#fff5db',
        color: '#614500',
    },
    [ClientScoresText.Regular]: {
        border: '0.5px solid #ff6ae8',
        backgroundColor: '#FFE5FB',
        color: '#2A2C2F',
    },
    [ClientScoresText.Poor]: {
        border: '0.5px solid #ff8e85',
        backgroundColor: '#fee9e8',
        color: '#2A2C2F',
    },
};

export const defaultStyle: ScoreStyle = {
    border: '0.5px solid black',
    backgroundColor: 'white',
    color: 'black',
};

export const getStyle = (status: string): ScoreStyle => {
    return scoreStyles[status] || defaultStyle;
};

export const FINGERPRINT_SELECTION = {
    HOW_TO_PROMPT: '¿Cómo va a tomar el registro de huellas?',
    SELECT_FINGER: 'Selecciona un dedo',
    UPLOAD_FINGERPRINT_OF: 'Subir huella del',
    MB: 'MB',
    UPTO_25MB: 'Hasta 25 MB',
    UPLOAD: 'Subir',
    REPLACE: 'Remplazar',
    LEFT_HAND: 'Mano izquierda',
    RIGHT_HAND: 'Mano derecha',
    PLEASE_TRY_AGAIN: 'Porfavor trata de nuevo.',
};

export const FingersOnHand = {
    thumb: 'Pulgar',
    index: 'Índice',
    middle: 'Medio',
    ring: 'Anular',
    pinkie: 'Meñique',
};
export const getCurrentSelection = (selectedFinger: string): string => {
    let finger = '';
    switch (selectedFinger) {
        case 'Pulgar':
            finger = 'thumb';
            break;
        case 'Índice':
            finger = 'index';
            break;
        case 'Medio':
            finger = 'middle';
            break;
        case 'Anular':
            finger = 'ring';
            break;
        case 'Meñique':
            finger = 'pinkie';
            break;
    }
    return finger;
};
export const getSelectedPrint = (
    selected: FingerClickEvent,
    current: FingerClickEvent
): boolean => {
    return selected.hand === current.hand && selected.finger === current.finger;
};

export const findFingerId = (
    fingers: IFingerData[],
    electedFinger: {hand: string; finger: string}
): number | undefined => {
    const foundFinger = fingers.find(
        (f) =>
            f.hand.toLowerCase() === electedFinger.hand.toLowerCase() &&
            f.finger.toLowerCase() === electedFinger.finger.toLowerCase()
    );

    return foundFinger?.fingerId;
};

export const PROFILEVALUATE = {
    TITLE: 'Evaluación del perfil',
    BEHAVIOR: 'Comportamiento',
};

export const DATE_FORMATS = {
    MONTH: 'MM',
    YEAR: 'yyyy',
    FULL_DATE: 'dd/MM/yyyy',
};
