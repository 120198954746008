import {useRef, useEffect} from 'react';

function useScrollIntoView<T>(dependency: T) {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [dependency]);

    return elementRef;
}

export default useScrollIntoView;
