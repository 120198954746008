import axios from 'axios';
import {Dispatch} from 'redux';
import {FieldValues} from 'react-hook-form';
import {URL} from '@config/constants/index';
import {IUse} from '@/components/Articles/Redux/interfaces';
import {ILevelCatalog} from '@Articles/interface';
import {ARTICLE_MANAGES} from '@/components/Articles/Redux/ManageTypes';
import StringUtils from '@/GenericScripts/utils';
import {
    saveArticle,
    saveBrand,
    saveFamily,
    saveSubFamily,
} from '@/components/Articles/Redux/Actions/SavedLevels';
export const LevelError = (value: unknown) => {
    return {
        type: ARTICLE_MANAGES.ERROR,
        payload: value,
    };
};
export const GroupError = () => {
    return {
        type: ARTICLE_MANAGES.GROUP_SELECT_ERROR,
    };
};
export const GroupGroupSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.GROUP_SELECT_SUCCESS,
        payload: value,
    };
};
export const GroupSelect = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.catGroup}`,
                {headers}
            );
            dispatch(GroupGroupSuccess(response.data));
        } catch (error) {
            dispatch(GroupError());
        }
    };
};
export const GroupManagementError = () => {
    return {
        type: ARTICLE_MANAGES.GROUP_ERROR,
    };
};
export const GroupManagementGroupSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.GROUP_SUCCESS,
        payload: value,
    };
};
export const GroupManagementGroupDataSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.GROUP_SUCCESS_DATA,
        payload: value,
    };
};
export const GroupManagementGroup = (
    token: string,
    requestBody: {searchName: string; pageNumber: number; pageSize: number}
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...requestBody,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSearchArticle}${queryString}`,
                {headers}
            );
            dispatch(GroupManagementGroupSuccess(response.data.data));
            dispatch(GroupManagementGroupDataSuccess(response.data));
        } catch (error) {
            dispatch(GroupManagementError());
        }
    };
};

export const FamilySuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.FAMILY,
        payload: value.data,
    };
};
export const getFamilyLevel = (token: string, GroupId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.catFamily.replace(
                    '{groupId}',
                    GroupId
                )}`,
                {headers}
            );
            dispatch(FamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const SubfamilySuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.SUBFAMILY,
        payload: value.data,
    };
};
export const getSubfamilyLevel = (token: string, FamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.catSubFamily.replace(
                    '{familyId}',
                    FamilyId
                )}`,
                {headers}
            );
            dispatch(SubfamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const BrandSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.BRAND,
        payload: value.data,
    };
};
export const getBrandLevel = (token: string, SubFamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.catBrand.replace(
                    '{subFamilyId}',
                    SubFamilyId
                )}`,
                {headers}
            );
            dispatch(BrandSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const ArticleSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE_MANAGES.ARTICLES,
        payload: value.data,
    };
};
export const getArticleLevel = (token: string, BrandId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.catArticle.replace(
                    '{brandId}',
                    BrandId
                )}`,
                {headers}
            );
            dispatch(ArticleSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const ResetFamily = (resetFamily: (e: IUse[]) => void) => {
    return (dispatch: Dispatch) => {
        resetFamily([{id: 0, name: '', familyId: 0}]);
        dispatch(saveFamily(0));
        dispatch({
            type: ARTICLE_MANAGES.RESET_FAMILY,
        });
    };
};

export const ResetSubfamily = (setSubFamily: (e: IUse[]) => void) => {
    return (dispatch: Dispatch) => {
        setSubFamily([{id: 0, name: '', familyId: 0}]);
        dispatch(saveSubFamily(0));
        dispatch({
            type: ARTICLE_MANAGES.RESET_SUBFAMILY,
        });
    };
};

export const ResetBrand = (setBrand: (e: IUse[]) => void) => {
    return (dispatch: Dispatch) => {
        setBrand([{id: 0, name: '', familyId: 0}]);
        dispatch(saveBrand(0));
        dispatch({
            type: ARTICLE_MANAGES.RESET_BRAND,
        });
    };
};

export const ResetArticle = (setArticle: (e: IUse[]) => void) => {
    return (dispatch: Dispatch) => {
        setArticle([{id: 0, name: '', familyId: 0}]);
        dispatch(saveArticle(0));
        dispatch({
            type: ARTICLE_MANAGES.RESET_ARTICLES,
        });
    };
};

export const ResetAllManagementLevel = () => {
    return {
        type: ARTICLE_MANAGES.RESET_ALL,
    };
};

export const addLevelCatalog = (LevelCatalog: ILevelCatalog) => ({
    type: ARTICLE_MANAGES.LEVELCATALOG,
    payload: LevelCatalog,
});
