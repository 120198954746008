import {FieldValues} from 'react-hook-form';
import {
    SELECT_QUICK_PAYMENT,
    ACTION_STATE_SELECTED,
    STEP_PAYMENT_SELECTED,
    RESET_ACTION_PAYMENT,
} from '@components/QuickPayment/Redux/Types/Types';

export const selectPayment = (value: FieldValues) => ({
    type: SELECT_QUICK_PAYMENT,
    item: value,
});

export const selectActionState = (value: number) => ({
    type: ACTION_STATE_SELECTED,
    actionState: value,
});

export const selectStepPayment = (value: number) => ({
    type: STEP_PAYMENT_SELECTED,
    stepSelected: value,
});

export const resetPayments = () => {
    return {
        type: RESET_ACTION_PAYMENT,
    };
};
