import {IFeatureHereditary} from '@Articles/interface';
import {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_CHARACTERISTIC_HEREDITARY_ERROR,
    LIST_CHARACTERISTIC_HEREDITARY_START,
    LIST_CHARACTERISTIC_HEREDITARY_SUCCESS,
} from '@components/Articles/Redux/Types';
import {RESET_DYNAMIC_FEATURES} from '@/components/Catalogue/Redux/Types/Types';

const initialState: IFeatureHereditary = {
    getHereditary: null,
    errorData: null,
    error: false,
    loading: false,
};

const GetInheritedFeatures = (
    state: IFeatureHereditary = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IFeatureHereditary => {
    switch (action.type) {
        case LIST_CHARACTERISTIC_HEREDITARY_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case LIST_CHARACTERISTIC_HEREDITARY_SUCCESS:
            return {
                ...state,
                getHereditary: action.payload.data,
                loading: false,
                error: false,
            };
        case LIST_CHARACTERISTIC_HEREDITARY_ERROR:
            return {
                ...state,
                errorData: action.error,
                loading: false,
                error: true,
            };
        case RESET_DYNAMIC_FEATURES:
            return initialState;
        default:
            return state;
    }
};
export default GetInheritedFeatures;
