import {IReducerType} from '@/components/Roles/interface';
import {
    DELETE_UPDATE_LEVELS_START,
    DELETE_UPDATE_LEVELS_ERROR,
    DELETE_UPDATE_LEVELS_SUCCESS,
    DELETE_UPDATE_LEVELS_FINISH,
} from '@TypesOrgCreation/types';

const initialState = {
    deleteLevel: false,
    loading: false,
    error: false,
};
const DeleteLevels = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case DELETE_UPDATE_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_UPDATE_LEVELS_SUCCESS:
            return {
                ...state,
                deleteLevel: action.payload.data.data,
            };
        case DELETE_UPDATE_LEVELS_ERROR:
            return {
                ...state,
                error: true,
            };
        case DELETE_UPDATE_LEVELS_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default DeleteLevels;
