import React, {ChangeEventHandler, forwardRef} from 'react';

import {StyledRadio, Radio, CheckedRadio} from '@Shopify/Ecommerce/styles';

export const RadioButton = forwardRef<
    HTMLInputElement,
    {
        value: string;
        onChange?: ChangeEventHandler<HTMLInputElement>;
        disabled?: boolean;
        background?: string;
        BorderColor?: string;
        BorderColorChecked?: string;
        BorderColorHover?: string;
    }
>((props, ref) => {
    const {background, ...rest} = props;

    return (
        <StyledRadio>
            <Radio {...rest} type="radio" ref={ref} />
            <CheckedRadio BackGround={background} />
        </StyledRadio>
    );
});
