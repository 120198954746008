export const GET_SECURITYBAG_WEIGHT_JIRACAS = 'GET_SECURITYBAG_WEIGHT_JIRACAS';
export const GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS = 'GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS';
export const GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR = 'GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR';

export const POST_SECURITYBAG_WEIGHT_JIRACAS = 'POST_SECURITYBAG_WEIGHT_JIRACAS';
export const POST_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS = 'POST_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS';
export const POST_SECURITYBAG_WEIGHT_JIRACAS_ERROR = 'POST_SECURITYBAG_WEIGHT_JIRACAS_ERROR';

export const GET_SECURITYBAG_WEIGHT_ESCORIA = 'GET_SECURITYBAG_WEIGHT_ESCORIA';
export const GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS = 'GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS';
export const GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR = 'GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR';

export const POST_SECURITYBAG_WEIGHT_ESCORIA = 'POST_SECURITYBAG_WEIGHT_ESCORIA';
export const POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS = 'POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS';
export const POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR = 'POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR';

export const GET_SECURITYBAG_WEIGHT_GOLD = 'GET_SECURITYBAG_WEIGHT_GOLD';
export const GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS = 'GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS';
export const GET_SECURITYBAG_WEIGHT_GOLD_ERROR = 'GET_SECURITYBAG_WEIGHT_GOLD_ERROR';

export const POST_SECURITYBAG_WEIGHT_GOLD = 'POST_SECURITYBAG_WEIGHT_GOLD';
export const POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS = 'POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS';
export const POST_SECURITYBAG_WEIGHT_GOLD_ERROR = 'POST_SECURITYBAG_WEIGHT_GOLD_ERROR';
