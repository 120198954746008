import {useForm} from 'react-hook-form';
import {RootState} from '@config/store';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TypeRole} from '@Roles/CreateRoles/TypeRole';
import {ROLES, createFormRol} from '@Roles/constants';
import * as styles from '@Roles/CreateRoles/stylesCreate';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {AssigmentRoles} from '@Roles/CreateRoles/AssigmentRole';
import {PermisionsRole} from '@Roles/CreateRoles/PermisionsRole';
import {ICheckTypeRole, ICreateRol, ICreateRoles} from '@Roles/AddRoles/interfaces';
import {
    modulesPersis,
    resetRoles,
    roleList,
    rolesCheck,
} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';
import {UserAssignment} from '@Roles/CreateRoles/UserAssignment';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {CreateRoles} from '@Roles/CreateRoles/Redux/Actions/TreePermissions';
import {affectedUser} from '@Roles/CreateRoles/Redux/Actions/AffectedRoles';
import {AssigmentRolesEdit} from '@Roles/CreateRoles/AsssignatedRole';
import {yupResolver} from '@hookform/resolvers/yup';
import {shemaCreateRole} from '@Roles/CreateRoles/schemaQuoter';

export const CreateRole = () => {
    const [showUserAssignModal, setShowUserAssignModal] = useState(false);
    const [roles, setRoles] = useState<ICheckTypeRole>({pos: false, admin: false});

    const enterPriceId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails?.[0].enterpriceId as number;
    });
    const {roleById, idRole} = useSelector((state: RootState) => state.RoleById);
    const persistenceRol = useSelector((state: RootState) => state.PersistenceRole);
    const {loading, loadingUpdate} = useSelector((state: RootState) => state.CreateUpdateRole);

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validateRole = () => {
        if (!roles.admin && !roles.pos) {
            setError('checkAdmin', {message: 'Indique al menos un tipo de rol'});
            setError('checkPOS', {message: 'Indique al menos un tipo de rol'});

            return false;
        }
        return true;
    };

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        getValues,
        setError,
        formState: {errors},
    } = useForm<ICreateRoles>({
        defaultValues: {...createFormRol, companyId: enterPriceId},
        resolver: yupResolver(shemaCreateRole),
    });

    const onSubmit = (data: ICreateRol) => {
        if (persistenceRol.typeAction === ROLES.NEW && validateRole())
            dispatch(
                CreateRoles(
                    token,
                    {
                        roleName: data.roleName,
                        roleType: data.roleType,
                        modules: data.modules,
                        companyId: enterPriceId,
                        userId: data.userId,
                    },
                    navigate
                )
            );

        if (persistenceRol.typeAction === ROLES.UPDATE)
            dispatch(affectedUser(token, {roleId: idRole}, setShowUserAssignModal));
    };

    const decartRoles = () => {
        dispatch(resetRoles());
        navigate('/Roles');
    };

    const detectRole = () => {
        let role = {admin: false, pos: false};
        if (roleById.roleType === ROLES.TYPE_ROLE.ADMIN_ID) role = {admin: true, pos: false};
        if (roleById.roleType === ROLES.TYPE_ROLE.POS_ID) role = {admin: false, pos: true};
        if (roleById.roleType === ROLES.TYPE_ROLE.ADMINPOS) role = {admin: true, pos: true};
        return role;
    };

    const handleDataRoles = () => {
        dispatch(roleList({roleName: roleById.roleName, roleType: roleById.roleType}));
        dispatch(rolesCheck(detectRole()));
        dispatch(modulesPersis(roleById.modules));
    };

    useEffect(() => {
        if (persistenceRol.typeAction === ROLES.UPDATE && roleById && roleById.roleName)
            handleDataRoles();
    }, [roleById && roleById.roleName]);
    return (
        <>
            <ContainerForm onSubmit={handleSubmit(onSubmit)} {...styles.form}>
                <Text {...styles.title}>{persistenceRol.typeAction + ROLES.CREATE_ROLE}</Text>
                <TypeRole
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    reset={reset}
                    getValues={getValues}
                    roles={roles}
                    setRoles={setRoles}
                />
                <PermisionsRole setValue={setValue} reset={reset} />
                {persistenceRol.typeAction === ROLES.UPDATE && <AssigmentRolesEdit />}
                <AssigmentRoles setValue={setValue} reset={reset} />
                <ContainerFlex Gap="1.5rem" Align="end" Padding="0 0 1rem 0">
                    <Text
                        onClick={() => decartRoles()}
                        Color="#FF6357"
                        Margin="0.5rem 1rem"
                        MarginB="0.5rem"
                        Cursor="pointer"
                    >
                        Descartar
                    </Text>
                    {persistenceRol.typeAction === ROLES.NEW && (
                        <>
                            {loading ? (
                                <LoadingAtaskate />
                            ) : (
                                <ButtonGeneral
                                    width=""
                                    padding="1rem 0.5rem"
                                    text="Guardar"
                                    type="submit"
                                    height="40px"
                                    cursor="pointer"
                                />
                            )}
                        </>
                    )}
                    {persistenceRol.typeAction === ROLES.UPDATE && (
                        <>
                            {loadingUpdate ? (
                                <LoadingAtaskate />
                            ) : (
                                <ButtonGeneral
                                    width=""
                                    padding="1rem 0.5rem"
                                    text="Guardar"
                                    type="submit"
                                    height="40px"
                                    cursor="pointer"
                                />
                            )}
                        </>
                    )}
                </ContainerFlex>
            </ContainerForm>
            {showUserAssignModal && (
                <UserAssignment
                    showUserAssignModal={showUserAssignModal}
                    setShowUserAssignModal={setShowUserAssignModal}
                />
            )}
        </>
    );
};
