import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import * as style from '@Articles/style';
import {SelectLabel} from '@FieldDinamics/SelectLabel';

import {IOtheOptions} from '@Articles/interface';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {CatLabelSize} from '@/components/Articles/Redux/Actions/FieldDinamics';

const LabelCatalog = (props: IOtheOptions) => {
    const {dispatch} = props;

    const detailFields = useSelector((state: RootState) => state.DetailFields);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    useEffect(() => {
        dispatch(CatLabelSize(token));
    }, []);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="1rem"
            Gap="1rem"
            backG="#FFFFFF"
            Radius="1rem"
        >
            <Text {...style.Card.Title}>Etiqueta impresa</Text>
            <SelectLabel dispatch={dispatch} detailFields={detailFields} />
        </ContainerFlex>
    );
};

export default LabelCatalog;
