import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';

import {DeadlineGeneral} from '@CreateDeadline/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import Rating from '@mui/material/Rating';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import {setStep, startsClient} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const TypeofClient = (props: DeadlineGeneral) => {
    const {deadlineById, deadlinesGeneral} = props;
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            !deadlinesGeneral.starts
        ) {
            dispatch(startsClient(deadlineById.deadlineId.data.quantityStars));
        }
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            onClick={() => {
                if (
                    (deadlinesGeneral.promotionExtensions.haveExtension &&
                        deadlinesGeneral.promotionExtensions.promotionExtensions?.dailyCost) ||
                    !deadlinesGeneral.promotionExtensions.haveExtension ||
                    deadlinesGeneral.starts
                ) {
                    dispatch(setStep(8));
                }
            }}
        >
            <TitleDeadline
                title={'Plazo tipo de cliente'}
                step={deadlinesGeneral.step}
                stepCurrent={8}
                stepDependence={0}
                dependence={deadlinesGeneral.starts !== 0}
            />

            {deadlinesGeneral.step === 8 && (
                <ContainerFlex FlexDir="column" Align="start" Padding="1.5rem">
                    <Text FontSize="0.875rem">
                        Selecciona el nivel de estrellas del cliente al que aplica este plazo.
                    </Text>
                    <ContainerFlex Margin="1rem 0 0 0" Border="1px solid #e4e7e9">
                        <ContainerFlex
                            Justify="start"
                            Width="20%"
                            Padding="1rem 1.5rem"
                            Br="1px solid #e4e7e9"
                        >
                            <Rating
                                name="simple-controlled"
                                disabled={deadlineById.edit}
                                value={deadlinesGeneral.starts}
                                onChange={(event, newValue) => {
                                    dispatch(startsClient(Number(newValue)));
                                }}
                            />
                        </ContainerFlex>

                        <ContainerFlex Padding="0 0.75rem">
                            {deadlinesGeneral.starts !== 0 && (
                                <Text Height="auto" wSpace="normal" FontSize="0.875rem">
                                    Has seleccionado {deadlinesGeneral.starts} estrellas, aplica
                                    para todos los clientes iguales o superiores a esta
                                    clasificación.
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
