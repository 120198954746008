import React, {useState, useEffect} from 'react';
import {RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';

import {TableItems} from '@components/Shopify/Endeavors/styles';
import {ContainerDetails} from '@components/Pawn/styles';
import {postPawn, getPawn, fetchGetDataSearch} from '@components/Pawn/redux/actions/PawnActions';

import {useForm} from 'react-hook-form';
import BackArrow from '@images/back_arrow_24px.svg';
import ButtonGeneral from '@components/General/Atoms/Button';
import {IDateLimit} from '@components/Pawn/interface/interface';

export const DetailsReactivations = () => {
    const reactivations = useSelector((state: RootState) => {
        return state.reactivations;
    });
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation?.userData?.token as string;
    });
    const [datasDetails, setDatasDetails] = useState<Array<IDateLimit>>([]);

    const dispatch = useDispatch();
    const {handleSubmit} = useForm({
        defaultValues: {
            group: '',
            contract: '',
            name: '',
        },
    });
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    useEffect(() => {
        const datadetail = reactivations.pawn.filter(
            (item: {plazo: string}) => item.plazo === reactivations.pawnid
        );
        setDatasDetails(datadetail);
    }, []);
    const addRow = async () => {
        const jason = {
            idSucursal: reactivations.databranch,
            idEmpeño: reactivations.datacontract,
            fecha: DateTime.fromISO(datasDetails[0].fechaLimitePago.toISOString()).toFormat(
                "yyyy-MM-dd'T'HH:mm:ss"
            ),
        };
        await dispatch(postPawn(token, jason));
        setDatasDetails([]);
    };
    const onSubmit = async (values: {
        group: React.SetStateAction<string>;
        name: React.SetStateAction<string>;
        contract: string;
    }) => {
        if (!reactivations.pawn) {
            await dispatch(getPawn(Number(values.group), Number(values.name), token));
            dispatch(fetchGetDataSearch(Number(values.group), Number(values.name)));
        }
    };
    return (
        <ContainerDetails Align="space-around" FlexDir="column">
            <ContainerDetails
                Padding="19px 0 24px 17px"
                onClick={() => (window.location.href = `../Reactivations`)}
            >
                <img src={BackArrow} alt="" />
                <TableItems Size="18px">Volver</TableItems>
            </ContainerDetails>
            <form className="container-form" onSubmit={handleSubmit(onSubmit)}>
                {datasDetails.map((pawntable, index: number) => (
                    <>
                        <ContainerDetails Justify="space-between" key={index}>
                            <ContainerDetails Padding="0 0 0 17px" FlexDir="column" Width="100%">
                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        ID Empeño:
                                    </TableItems>
                                    <TableItems Size="16px">{pawntable.idEmpeño}</TableItems>
                                </ContainerDetails>

                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        Mutuo
                                    </TableItems>
                                    <TableItems Size="16px">
                                        {formatterDolar.format(pawntable.mutuo)}
                                    </TableItems>
                                </ContainerDetails>

                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        IVA
                                    </TableItems>
                                    <TableItems Size="16px">
                                        {formatterDolar.format(pawntable.iva)}
                                    </TableItems>
                                </ContainerDetails>
                            </ContainerDetails>

                            <ContainerDetails Align="space-between" FlexDir="column" Width="50%">
                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        Plazo
                                    </TableItems>
                                    <TableItems Size="16px">{pawntable.plazo}</TableItems>
                                </ContainerDetails>

                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        Intereses
                                    </TableItems>
                                    <TableItems Size="16px">
                                        {formatterDolar.format(pawntable.intereses)}
                                    </TableItems>
                                </ContainerDetails>

                                <ContainerDetails Padding="0 0 40px 0" FlexDir=" column">
                                    <TableItems Justify="flex-start" Weigth="300" Size="16px">
                                        Fecha Limite
                                    </TableItems>
                                    <TableItems Size="16px">
                                        {DateTime.fromISO(
                                            pawntable.fechaLimitePago.toISOString()
                                        ).toFormat('dd/MM/yyyy')}
                                    </TableItems>
                                </ContainerDetails>
                            </ContainerDetails>
                        </ContainerDetails>
                        <div>
                            <ContainerDetails Padding="0 0 0 17px">
                                <ButtonGeneral
                                    width="320px"
                                    text="Condonar"
                                    clic={() => addRow()}
                                />
                            </ContainerDetails>
                        </div>
                    </>
                ))}
            </form>
        </ContainerDetails>
    );
};
