import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IFilterUserTypesProps, FilterType} from '@components/Users/interface';

export const FilterUserType: React.FC<IFilterUserTypesProps> = ({onFilterChange}) => {
    const FilterType: FilterType[] = [
        {userType: 'Todos', userId: 3},
        {userType: 'Administrativos', userId: 1},
        {userType: 'P.O.S', userId: 2},
    ];

    const [filterSelected, setFilterSelected] = useState<{userType: string; userId: number} | null>(
        null
    );

    const clickFilterSelect = (filter: {userType: string; userId: number}) => {
        setFilterSelected(filterSelected === filter ? null : filter);
    };

    if (filterSelected === null) {
        setFilterSelected({
            userType: 'Todos',
            userId: 3,
        });
    }

    return (
        <ContainerFlex Justify="start" Height="2.5rem">
            <ContainerFlex Width="18.875rem">
                {FilterType.map((filter, index) => (
                    <Text
                        Align="start"
                        key={index}
                        Padding="0.5rem 1rem"
                        FontSize="1rem"
                        Cursor="pointer"
                        onClick={() => {
                            onFilterChange(filter);
                            clickFilterSelect(filter);
                        }}
                        FontWeight={filter.userId === filterSelected?.userId ? '500' : '400'}
                        BorderBt={
                            filter.userId === filterSelected?.userId ? '1.5px solid #5A5AFF' : ''
                        }
                        Color={filter.userId === filterSelected?.userId ? '#5A5AFF' : '#54575C'}
                    >
                        {filter.userType}
                    </Text>
                ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};
