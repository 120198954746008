import {FILTERS_PERSISTANCE, FILTERS_PERSISTANCE_RESET} from '@components/CashFlow/Redux/Types';
import {IGetTransactionsParams} from '@components/CashFlow/interfaces';

export const saveFilters = (result: IGetTransactionsParams) => {
    return {
        type: FILTERS_PERSISTANCE,
        data: result,
    };
};

export const resetFilters = () => ({
    type: FILTERS_PERSISTANCE_RESET,
});
