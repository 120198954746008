import React from 'react';
import {
    ButtonContainer,
    CancelIconContainer,
    Input,
    SearchInputWrapper,
} from '@components/NavBarPOS/SearchComponent/styles';
import {ISearchInput} from '@components/NavBarPOS/SearchComponent/interface';
import {IconSvg} from '@/components/Quoter/styles';

export const SearchGeneral = ({
    variant,
    value,
    handleClose,
    handleButton,
    handleQr,
    onChange,
    hasQR,
    placeholder,
}: ISearchInput) => {
    const emptyValue = value === '';
    const notEmptyValue = value !== '';
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(event);
    const variantIcon = (variant: string) => {
        const icon: {
            [key: string]: JSX.Element;
        } = {
            nav: (
                <CancelIconContainer variant={variant}>
                    {emptyValue ? (
                        hasQR && (
                            <IconSvg Height="24px" Width="24px" cursor="pointer">
                                <path
                                    d="M3 11V3H11V11H3ZM5 9H9V5H5V9ZM3 21V13H11V21H3ZM5 19H9V15H5V19ZM13 11V3H21V11H13ZM15 9H19V5H15V9ZM19 21V19H21V21H19ZM13 15V13H15V15H13ZM15 17V15H17V17H15ZM13 19V17H15V19H13ZM15 21V19H17V21H15ZM17 19V17H19V19H17ZM17 15V13H19V15H17ZM19 17V15H21V17H19Z"
                                    fill="#5A5AFF"
                                    onClick={handleQr}
                                />
                            </IconSvg>
                        )
                    ) : (
                        <IconSvg Height="24px" Width="24px" onClick={handleClose} cursor="pointer">
                            <path
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#5A5AFF"
                            />
                        </IconSvg>
                    )}
                </CancelIconContainer>
            ),
            component: (
                <CancelIconContainer variant={variant}>
                    {notEmptyValue && (
                        <IconSvg Height="24px" Width="24px" onClick={handleClose} cursor="pointer">
                            <path
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#5A5AFF"
                            />
                        </IconSvg>
                    )}
                </CancelIconContainer>
            ),
        };
        return icon[variant];
    };
    return (
        <SearchInputWrapper>
            <Input type="input" placeholder={placeholder} value={value} onChange={handleChange} />
            {variantIcon(variant)}
            <ButtonContainer variant={variant} onClick={handleButton} disabled={emptyValue}>
                <IconSvg Height="24px" Width="24px">
                    <path
                        d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.371 4.888 14.113C3.62933 12.8543 3 11.3167 3 9.5C3 7.68333 3.62933 6.14567 4.888 4.887C6.146 3.629 7.68333 3 9.5 3C11.3167 3 12.8543 3.629 14.113 4.887C15.371 6.14567 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5627 11.8127 14 10.75 14 9.5C14 8.25 13.5627 7.18733 12.688 6.312C11.8127 5.43733 10.75 5 9.5 5C8.25 5 7.18733 5.43733 6.312 6.312C5.43733 7.18733 5 8.25 5 9.5C5 10.75 5.43733 11.8127 6.312 12.688C7.18733 13.5627 8.25 14 9.5 14Z"
                        fill="#FFFFFF"
                    />
                </IconSvg>
            </ButtonContainer>
        </SearchInputWrapper>
    );
};
