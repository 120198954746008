import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import {ErrorMessage} from '@hookform/error-message';

import {
    getGroup,
    getFamily,
    getSubfamily,
    getBrand,
    getItem,
    postBanner,
    getBrandGeneral,
} from '@ActionsEcommerce/BannerActions';
import {ContainerTableBanner, ContainerForm, ButtonSubmit} from '@Ecommerce/styles';
import {DateTime} from 'luxon';

const newLocal = new Date();
export function BannerAdministration() {
    const [table, setTable] = useState({
        group: 'todos',
        family: 'todos',
        subfamily: 'todos',
        brand: 'todos',
        item: 'todos',
    });
    const [data, setData] = useState([]);
    const [valueDate, setvalueDate] = useState(newLocal);
    const [valueDateEnd, setvalueDateEnd] = useState(newLocal);

    const banner = useSelector((state) => {
        return state.banner;
    });

    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const {
        handleSubmit,
        register,
        control,

        formState: {errors},
    } = useForm({
        defaultValues: {
            name: '',
            description: '',
            urlImage: '',
            dateStart: valueDate,
            dateEnd: valueDateEnd,
            group: 0,
            family: 0,
            subFamily: 0,
            brandd: 0,
            item: 0,
            image: '',
        },
    });
    const dispatch = useDispatch();
    const selectChange = useCallback(async () => {
        if (token) {
            dispatch(getGroup(token));
            dispatch(getBrandGeneral(token));
        }
    }, [dispatch, token]);
    useEffect(() => {
        selectChange();
    }, [selectChange]);
    const addRow = () => {
        const updatedRows = [...data, table];
        setData(updatedRows);
    };

    const handleonChangeItems = (id) => {
        setTable({...table, item: id.split('|').pop()});
    };
    const handleonChangeFamily = (id) => {
        setTable({...table, group: id.split('|').pop()});

        dispatch(getFamily(id.split('|').reverse().pop(), token));
    };
    const handleonChangeBrand = (id) => {
        setTable({...table, subfamily: id.split('|').pop()});

        dispatch(getBrand(id.split('|').reverse().pop(), token));
    };
    const handleonChangeSubfamily = (id) => {
        setTable({...table, family: id.split('|').pop()});
        dispatch(getSubfamily(id.split('|').reverse().pop(), token));
    };
    const handleonChangeItem = (id) => {
        setTable({...table, brand: id.split('|').pop()});
        dispatch(getItem(id.split('|').reverse().pop(), token));
    };
    const handleonChangeDate = (date) => {
        setvalueDate(date);
    };
    const handleonChangeDateEnd = (date) => {
        setvalueDateEnd(date);
    };

    const onSubmit = async (values) => {
        const formData = new FormData();
        formData.append('Name', values.name);
        formData.append('Description', values.description);
        formData.append(
            'DateStart',
            DateTime.fromJSDate(valueDate).toFormat("yyyy-MM-dd'T'HH:mm:ss")
        );
        formData.append(
            'DateEnd',
            DateTime.fromJSDate(valueDateEnd).toFormat("yyyy-MM-dd'T'HH:mm:ss")
        );
        formData.append('DateNow', DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"));
        formData.append('Image', values.image[0]);
        await dispatch(postBanner(token, formData, data));
    };

    return (
        <>
            <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                <span className="child-breadcrumb">Ecommerce</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Banner principal</span>
            </div>

            <div className="w-90 mx-auto mt-4">
                <form className="container-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex w-100 mt-3 justify-content-end">
                        <div className=" w-100 mx-auto">
                            Crea el banner para la campaña que deseas realizar
                        </div>
                        {data.length && (
                            <button className=" btn-submit" type="submit">
                                Finalizar
                            </button>
                        )}
                    </div>
                    <ContainerForm>
                        <div className="name">
                            <div>
                                <label className="required">Nombre campaña</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('name', {
                                        required: 'Ingrese motivo',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="motivo"
                                    message="Seleccione motivo"
                                    as="p"
                                />
                            </div>
                            <div>
                                <label className="required">Descripción</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('description', {
                                        required: 'Ingrese motivo',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="motivo"
                                    message="Seleccione motivo"
                                    as="p"
                                />
                            </div>
                            <div>
                                <label className="required"> Archivo </label>
                                <input
                                    type="file"
                                    className="   input-react-hook-form"
                                    {...register('image', {required: 'Ingrese imagen'})}
                                />
                            </div>
                            <div>
                                <label className="required">Fecha de inicio</label>
                                <Controller
                                    control={control}
                                    name="dateStart"
                                    render={({onChange, onBlur, value}) => (
                                        <ReactDatePicker
                                            onChange={(e) => {
                                                handleonChangeDate(e);
                                            }}
                                            onBlur={onBlur}
                                            selected={valueDate}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="idTipoAsignacion"
                                    message="Seleccione tipo de asignación"
                                    as="p"
                                />
                            </div>
                            <div>
                                <label className="required">Fecha de fin</label>
                                <Controller
                                    control={control}
                                    name="dateEnd"
                                    render={({onChange, onBlur, value}) => (
                                        <ReactDatePicker
                                            onChange={(e) => {
                                                handleonChangeDateEnd(e);
                                            }}
                                            onBlur={onBlur}
                                            selected={valueDateEnd}
                                            dateFormat="dd/MM/yyyy"
                                            showDisabledMonthNavigation
                                            showTimeSelect
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Grupo</label>
                            <select
                                {...register('group')}
                                id="group"
                                className="input-react-hook-form"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    handleonChangeFamily(value);
                                }}
                            >
                                {banner.group &&
                                    banner.group.map((incidents) => (
                                        <option
                                            value={`${incidents.id}|${incidents.description}`}
                                            key={incidents.id}
                                        >
                                            {incidents.description}
                                        </option>
                                    ))}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="group"
                                message="Seleccione motivo"
                                as="p"
                            />
                        </div>
                        <div className="row-Article">
                            <div>
                                <label className="required">Familia</label>
                                <select
                                    {...register('idAsignador')}
                                    className="input-react-hook-form"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleonChangeSubfamily(value);
                                    }}
                                >
                                    <option value="-1"></option>
                                    {banner.family &&
                                        banner.family.map((incidents) => (
                                            <option
                                                key={incidents.id}
                                                value={`${incidents.id}|${incidents.description}`}
                                            >
                                                {incidents.description}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div>
                                <label className="required">Subfamilia</label>
                                <select
                                    {...register('idAsignador')}
                                    className="input-react-hook-form"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleonChangeBrand(value);
                                    }}
                                >
                                    <option value="-1"></option>
                                    {banner.subfamily &&
                                        banner.subfamily.map((incidents) => (
                                            <option
                                                value={`${incidents.id}|${incidents.description}`}
                                                key={incidents.id}
                                            >
                                                {incidents.description}
                                            </option>
                                        ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="idFundidora"
                                    message="Seleccione Testigo"
                                    as="p"
                                />
                            </div>
                            <div>
                                <label className="required">Marca</label>
                                <select
                                    {...register('brand')}
                                    className="input-react-hook-form"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleonChangeItem(value);
                                    }}
                                >
                                    <option value="-1"></option>
                                    {banner.brand &&
                                        banner.brand.map((incidents) => (
                                            <option
                                                value={`${incidents.id}|${incidents.description}`}
                                                key={incidents.id}
                                            >
                                                {incidents.description}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div>
                                <label className="required">Articulo</label>
                                <select
                                    {...register('item')}
                                    className="input-react-hook-form"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleonChangeItems(value);
                                    }}
                                >
                                    <option value="-1"></option>
                                    {banner.item &&
                                        banner.item.map((incidents) => (
                                            <option
                                                value={`${incidents.id}|${incidents.description}`}
                                                key={incidents.id}
                                            >
                                                {incidents.description}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </ContainerForm>
                </form>

                <ButtonSubmit onClick={() => addRow()}>Agregar</ButtonSubmit>
                <ContainerTableBanner>
                    <table>
                        <thead>
                            <tr>
                                <th>Grupo</th>
                                <th>Familia</th>
                                <th>Subfamilia</th>
                                <th>Marca</th>
                                <th>Artículo</th>
                                <th>Fecha inicio</th>
                                <th>Fecha fin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((incidents, index) => (
                                <tr key={index}>
                                    <td>{incidents.group}</td>
                                    <td>{incidents.family}</td>
                                    <td>{incidents.subfamily}</td>
                                    <td>{incidents.brand}</td>
                                    <td>{incidents.item}</td>
                                    <td>{DateTime.fromJSDate(valueDate).toFormat('dd/MM/yyyy')}</td>
                                    <td>
                                        {DateTime.fromJSDate(valueDateEnd).toFormat('dd/MM/yyyy')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ContainerTableBanner>
            </div>
        </>
    );
}
