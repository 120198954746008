import {IBusinessLine, IBusinessLineRedux} from '@components/MyPlan/interfaces';
import {MY_PLAN_BUSINESS_LINES} from '@components/MyPlan/Redux/types';

const initialState: IBusinessLine = {
    businessLine: {
        companyId: 0,
        companyName: '',
        paymentMethod: '',
        messagePayment: '',
        firstText: '',
        secondText: '',
        businessLines: [],
    },
    loading: false,
    error: false,
};

export const getBusinessLines = (
    state = initialState,
    action: IBusinessLineRedux
): IBusinessLine => {
    switch (action.type) {
        case MY_PLAN_BUSINESS_LINES.START_BUSINESS_LINE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case MY_PLAN_BUSINESS_LINES.SUCCESS_BUSINESS_LINE:
            return {
                ...state,
                businessLine: action.businessLine,
                loading: false,
                error: false,
            };
        case MY_PLAN_BUSINESS_LINES.ERROR_BUSINESS_LINE:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
