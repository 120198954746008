import React from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {ContainerFlex, Text, Image, ImgAvatar} from '@components/Shopify/Ecommerce/styles';
import {CREDIT_REQUEST_USER} from '@components/CreditsAndPawns/constants';
import {
    ICreditRequestUserProps,
    IDetailsCreditScoreProps,
} from '@components/CreditsAndPawns/interfaces';
import {openCustomerInformationModal} from '@components/CreditsAndPawns/Redux/Actions/GetCustomerInformationActions';
import homeIcon from '@components/CreditsAndPawns/icons/homeIcon.svg';
import mailIcon from '@components/CreditsAndPawns/icons/mailIcon.svg';
import ScoreIndicator from '@components/CreditsAndPawns/CreditRequest/ScoreIndicator/ScoreIndicator';

const CreditRequestUser: React.FC<ICreditRequestUserProps> = ({
    clientName,
    imageUrl,
    nuc,
    clientId,
    phone,
    email,
    adrress,
    scoreTotal,
    personalInformationId,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const handleOpenModal = () => {
        dispatch(openCustomerInformationModal(personalInformationId));
    };
    return (
        <>
            <ContainerFlex FlexDir="column" Align="start" Justify="start" Width="64px">
                <ImgAvatar Width="64px" Height="64px" Radius="50%" src={imageUrl} />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="start" Gap="0.5rem">
                <ContainerFlex FlexDir="row" Align="center" Justify="start" Gap="5px">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.5rem"
                        Color="#1D1E20"
                        FontStyle="normal"
                        LHeight="1.8rem"
                        Align="center"
                    >
                        {clientName}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                        Align="center"
                    >
                        {`${CREDIT_REQUEST_USER.NUC}:`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.8rem"
                        Align="center"
                    >
                        {nuc}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="0.5rem">
                    <ContainerFlex FlexDir="row" Align="center" Justify="start" Gap="0.25rem">
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="0.88rem"
                            Color="#1D1E20"
                            FontStyle="normal"
                            LHeight="1.05rem"
                            Align="center"
                        >
                            {phone}
                        </Text>
                        <Text FontFamily="Nunito" FontSize="1.0rem" Color="#E8E9EA">
                            {`|`}
                        </Text>
                        <Image Width="14px" Height="14px" src={mailIcon} />
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="0.88rem"
                            Color="#1D1E20"
                            FontStyle="normal"
                            LHeight="1.05rem"
                            Align="center"
                        >
                            {email}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="row" Align="Start" Justify="start" Gap="0.25rem">
                        <Image Width="14px" Height="14px" src={homeIcon} />
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="0.88rem"
                            Color="#1D1E20"
                            FontStyle="normal"
                            LHeight="1.05rem"
                            Align="center"
                        >
                            {adrress}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="row" Align="start" Justify="start" Gap="0.5rem">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#5A5AFF"
                        FontStyle="normal"
                        LHeight="1.5rem"
                        Cursor="pointer"
                        onClick={handleOpenModal}
                    >
                        {CREDIT_REQUEST_USER.CTA_INFORMATION}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1.0rem" Color="#E8E9EA">
                        {`|`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#5A5AFF"
                        FontStyle="normal"
                        LHeight="1.5rem"
                        Cursor="pointer"
                    >
                        {CREDIT_REQUEST_USER.CTA_EVALUATION}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ScoreIndicator {...(scoreTotal as IDetailsCreditScoreProps)} />
        </>
    );
};

export default CreditRequestUser;
