import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ContainerCellGrid, ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ICompanyBenefits} from '@components/SignUp/interfaces';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {RootState} from '@/config/store';
import {formatterWithoutDigits} from '@/hooks/currentFormatUtils';

export const Addons = (props: {
    checkedAddons: ICompanyBenefits[];
    setCheckedAddons: React.Dispatch<React.SetStateAction<ICompanyBenefits[]>>;
}) => {
    const addons = useSelector((state: RootState) => state.catCompanyBenefits.data);

    const {checkedAddons, setCheckedAddons} = props;

    const handleCheck = (element: ICompanyBenefits) => {
        if (checkedAddons.some((item) => item.id === element.id)) {
            setCheckedAddons(checkedAddons.filter((item) => item.id !== element.id));
        } else {
            setCheckedAddons([...checkedAddons, element]);
        }
    };

    useEffect(() => {}, [checkedAddons]);

    return (
        <ContainerFlex
            Display="grid"
            GridColumns="repeat(2, minmax(16.87rem, 1fr))"
            Gap="1rem"
            Height="auto"
        >
            {addons?.map((element: ICompanyBenefits) => (
                <ContainerCellGrid
                    key={element.id}
                    FlexDir="column"
                    BorderR="0.5rem"
                    Border="#5A5AFF 1px solid"
                    Padding="1rem"
                    Gap="0.5rem"
                    Height="auto"
                    Cursor="pointer"
                    onClick={() => handleCheck(element)}
                >
                    <ContainerCellGrid Cursor="pointer" Justify="space-between">
                        <ContainerCellGrid Cursor="pointer" Align="center" Gap="0.5rem">
                            <Checkbox
                                checked={checkedAddons.some((item) => item.id === element.id)}
                            />
                            <Text Cursor="pointer" FontSize="0.875rem" FontWeight="500">
                                {element.benefitName}
                            </Text>
                        </ContainerCellGrid>
                        <ContainerCellGrid Cursor="pointer" FlexDir="column" Width="">
                            <Text Cursor="pointer" Color="#2A2C2F" FontSize="1rem" FontWeight="500">
                                {formatterWithoutDigits.format(element.price)}
                            </Text>
                            <Text Cursor="pointer" Color="#71767D" FontSize="0.75rem">
                                {element.typeUser}
                            </Text>
                        </ContainerCellGrid>
                    </ContainerCellGrid>
                    <Text Cursor="pointer" FontSize="0.75rem" FontWeight="500">
                        {element.title}
                    </Text>
                    <Text
                        Cursor="pointer"
                        FontSize="0.75rem"
                        FlexWrap="wrap"
                        wSpace="normal"
                        Height="auto"
                    >
                        {element.description}
                    </Text>
                </ContainerCellGrid>
            ))}
        </ContainerFlex>
    );
};
