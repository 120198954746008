import React, {useEffect, useState} from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import upArrowSelected from '@components/Branches/Images/UpArrowSelected.svg';
import {CashFlowConfigInfo} from '@components/Branches/ManageBranch/BranchCashFlow/CashFlowConfigInfo';
import {IBranchCashFlow} from '@components/Branches/interfaces';
import {useParams} from 'react-router-dom';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {GetCashFlowSetup} from '@components/Branches/ManageBranch/Redux/Actions/GetCashFlowSetup';

export const BranchCashFlow = ({showItems, setShowItems}: IBranchCashFlow) => {
    const {idBranch} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [showCashForm, setShowCashForm] = useState(false);
    useEffect(() => {
        dispatch(GetCashFlowSetup(Number(idBranch), token));
    }, []);
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                backG="#f3f3f3"
                Height="56px"
                Justify="space-between"
                Radius="4px 4px 0 0"
                Border="1px solid #e5e7e9"
                Padding="18.5px 16px"
            >
                <Text FontSize="1rem" FontWeight="500" Color="#414042">
                    Flujo de efectivo
                </Text>
                {!showItems.cashFlow ? (
                    <Image
                        src={downArrowSelected}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                cashFlow: !showItems.cashFlow,
                                user: false,
                                schedule: false,
                                info: false,
                                services: false,
                            });
                        }}
                    />
                ) : (
                    <Image
                        src={upArrowSelected}
                        alt="up-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({...showItems, cashFlow: !showItems.cashFlow});
                        }}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="column"
                Radius="0 0 4px 4px"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Gap={!showItems.cashFlow ? '8px' : '0px'}
                Height="auto"
                Padding={!showItems.cashFlow ? '16px' : '0 16px 16px 16px'}
                MarginBt="24px"
            >
                {!showItems.cashFlow ? (
                    <Text FontSize="0.875rem" Color="#6d6e71">
                        Gestiona el flujo de efectivo para la sucursal
                    </Text>
                ) : (
                    <CashFlowConfigInfo
                        showCashForm={showCashForm}
                        setShowCashForm={setShowCashForm}
                    />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
