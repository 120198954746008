import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {ContainerFlex, ImgProduct, TextEllipsis, Text} from '@Shopify/Ecommerce/styles';
import {Table, TableItem, TableRowHeadContract, TableRowItems} from '@Shopify/Endeavors/styles';
import {FilterLocations} from '@MenageLocations/FilterLocations';
import {
    fetchGetQueryLocationStart,
    getQueryLocations,
    locationsById,
} from '@ActionsManageLocations/ManageLocationsActions';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {
    productsLocations,
    pledgeVoult,
    pledgeRack,
    pledgePension,
    pledgeFloor,
} from '@Locations/interfaces';
import {IconContainer} from '@/components/Quoter/styles';
import RackProucts from '@Locations/ManageLocations/DeatilLocations/Rack';
import VoultProucts from '@Locations/ManageLocations/DeatilLocations/Vault';
import PensionProucts from '@Locations/ManageLocations/DeatilLocations/Pension';
import FloorProucts from '@Locations/ManageLocations/DeatilLocations/Floor';
import {fetchGetIdContainer} from '@AdminLocations/redux/actions/LocationAction';

export default function ManageLocations(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const branch = useSelector((state: RootState) => {
        return state.nearByBranches.branch;
    });
    const [search, setSearch] = useState('');
    const continerType = (
        type: string,
        pledgeRack: pledgeRack,
        pledgeVoult: pledgeVoult,
        pledgePension: pledgePension,
        pledgeFloor: pledgeFloor
    ) => {
        if (type) {
            const MESSAGE_LOCATIONS: {[key: string]: JSX.Element} = {
                Rack: <RackProucts pledgeRack={pledgeRack} />,
                Voult: <VoultProucts pledgeVoult={pledgeVoult} />,
                Pension: <PensionProucts pledgePension={pledgePension} />,
                Floor: <FloorProucts pledgeFloor={pledgeFloor} />,
            };
            return MESSAGE_LOCATIONS[type];
        }
    };

    const continerID = (type: string) => {
        const MESSAGE_LOCATIONS: {[key: string]: number} = {
            Rack: 1,
            Voult: 2,
            Pension: 3,
            Floor: 4,
        };
        return MESSAGE_LOCATIONS[type];
    };

    const handlePageClickHistory = (data: {selected: number}) => {
        dispatch(
            getQueryLocations(Number(branch), 10, data.selected + 1, search ? search : null, token)
        );
    };

    useEffect(() => {
        if (branch !== '') handlePageClickHistory({selected: 0});
        dispatch(fetchGetQueryLocationStart());
    }, [branch, search]);

    return (
        <>
            <ContainerFlex Padding="3.125rem 12.063rem 0 6.25rem" FlexDir="column" Align="start">
                <ContainerFlex>
                    <FilterLocations
                        handlePageClickHistory={handlePageClickHistory}
                        search={search}
                        setSearch={setSearch}
                    />
                </ContainerFlex>
                <Table>
                    <TableRowHeadContract Background="#F3F3F3" GridTemplate="2fr 4fr">
                        <TableItem Justify="start" Margin="0 0 0 34px" Weight="500">
                            Producto
                        </TableItem>
                        <TableItem Weight="500">Ubicación</TableItem>
                    </TableRowHeadContract>
                    {productsLoations.loading && (
                        <ContainerFlex Height="30vh">
                            <LoadingGeneral />
                        </ContainerFlex>
                    )}

                    {productsLoations.producst !== null &&
                        !productsLoations.loading &&
                        (productsLoations.producst.data.length > 0 ? (
                            productsLoations.producst.data.map((item, index: number) => (
                                <>
                                    <TableRowItems
                                        Cursor="auto"
                                        Height="63px"
                                        BackGColor=""
                                        GridColumn="2fr 4fr"
                                        key={index}
                                    >
                                        <ContainerFlex
                                            Align="center"
                                            Justify="center"
                                            Padding="0 22px"
                                        >
                                            <ImgProduct src={item.imageUrl} />
                                            <ContainerFlex Align="start" FlexDir="column">
                                                <TextEllipsis
                                                    FontWeight="400"
                                                    FontSize="14px"
                                                    Color="#4A4A4A"
                                                >
                                                    {item.itemName}
                                                </TextEllipsis>
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#CBCED1"
                                                >
                                                    {item.transactionType}
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                        {item.containerType !== null ? (
                                            <TableRowItems
                                                Cursor="auto"
                                                Height="auto"
                                                Border=""
                                                BackGColor=""
                                                GridColumn="2fr 2fr 2fr 2fr 1fr"
                                            >
                                                {continerType(
                                                    item.containerType,
                                                    item.pledgeRack,
                                                    item.pledgeVoult,
                                                    item.pledgePension,
                                                    item.pledgeFloor
                                                )}

                                                <TableItem Padding="0 1rem" Justify="end">
                                                    <IconContainer
                                                        className="material-icons"
                                                        onClick={() => {
                                                            dispatch(
                                                                locationsById(
                                                                    item.pledgeId,
                                                                    item.containerType,
                                                                    token
                                                                )
                                                            );
                                                            dispatch(
                                                                fetchGetIdContainer(
                                                                    continerID(item.containerType)
                                                                )
                                                            );
                                                        }}
                                                        FontSize="1rem"
                                                    >
                                                        edit
                                                    </IconContainer>
                                                </TableItem>
                                            </TableRowItems>
                                        ) : (
                                            <ContainerFlex Justify="end" Padding="0 1rem">
                                                <Text
                                                    wSpace="pre"
                                                    Cursor="pointer"
                                                    FontSize="0.875rem"
                                                    Color="#7AC143"
                                                    onClick={() => {
                                                        dispatch(
                                                            locationsById(
                                                                item.pledgeId,
                                                                'Rack',
                                                                token
                                                            )
                                                        );
                                                        dispatch(fetchGetIdContainer(1));
                                                        navigate('/Locations/AssigmentLocations');
                                                    }}
                                                >
                                                    Asignar Ubicación {'>'}
                                                </Text>
                                            </ContainerFlex>
                                        )}
                                    </TableRowItems>
                                </>
                            ))
                        ) : (
                            <ContainerFlex Height="10vh">
                                No se encontrarón coincidencias para la sucursal seleccionada.
                            </ContainerFlex>
                        ))}
                </Table>

                {productsLoations.producst !== null &&
                    !productsLoations.loading &&
                    productsLoations.producst.data.length > 0 && (
                        <ContainerFlex Padding="5px 0" Cursor="pointer">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                pageCount={productsLoations.producst.totalPages}
                                forcePage={productsLoations.producst.pageNumber - 1}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClickHistory}
                            />
                        </ContainerFlex>
                    )}
            </ContainerFlex>
        </>
    );
}
