import {
    CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP,
    ADD_CLIENT_ADDRESS_PROOF,
    GET_PROOFTYPES_SUCCESS,
    GET_PROOFTYPES_ERROR,
    SET_LOADING,
    GET_CIVIL_STATUS_SUCCESS,
    GET_ECONOMIC_ACTIVITY_SUCCESS,
    GET_GENRE_SUCCESS,
    GET_IDENTIFICATION_TYPE_SUCCESS,
    SET_LOADING_CLIENT_INFO,
    GET_MARKETING_CLIENT_SUCCESS,
    ADD_NEW_CLIENT_INFO_SUCCESS,
    SET_CLIENT_FINGERPRINTS,
    RESET_NEWCLIENTPROCESS,
} from '@ListClient/redux/types/Types';
import {INewClientProcess} from '@ListClient/redux/interfaces';
import {INewClientForm} from '@components/Shopify/ListClient/redux/interfaces';

const initialState: INewClientProcess = {
    actualStep: 1,
    error: false,
    message: '',
    loading: false,
    proofTypes: [],
    loadingClientInfo: false,
    civilStatus: [],
    economicActivity: [],
    genres: [],
    identificationTypes: [],
    marketingClient: [],
    newClientId: 0,
    fingerprints: {
        left: {
            thumb: null,
            index: null,
            middle: null,
            ring: null,
            pinkie: null,
        },
        right: {
            thumb: null,
            index: null,
            middle: null,
            ring: null,
            pinkie: null,
        },
    },
};

const newClientProcess = (state = initialState, action: INewClientForm): INewClientProcess => {
    switch (action.type) {
        case ADD_NEW_CLIENT_INFO_SUCCESS:
            return {...state, loading: false, error: false, newClientId: action.newClientId};
        case ADD_CLIENT_ADDRESS_PROOF:
            return {...state, loading: true, error: false};
        case CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP:
            return {...state, actualStep: action.actualStep};
        case GET_PROOFTYPES_SUCCESS:
            return {...state, proofTypes: action.proofTypes};
        case GET_PROOFTYPES_ERROR:
            return {...state, loading: false, error: true};
        case SET_LOADING:
            return {...state, loading: action.payload as unknown as boolean};
        case SET_LOADING_CLIENT_INFO:
            return {...state, loadingClientInfo: action.payload as unknown as boolean};
        case GET_CIVIL_STATUS_SUCCESS:
            return {...state, civilStatus: action.civilStatus};
        case GET_ECONOMIC_ACTIVITY_SUCCESS:
            return {...state, economicActivity: action.economicActivity};
        case GET_GENRE_SUCCESS:
            return {...state, genres: action.genres};
        case GET_IDENTIFICATION_TYPE_SUCCESS:
            return {...state, identificationTypes: action.identificationTypes};
        case GET_MARKETING_CLIENT_SUCCESS:
            return {...state, marketingClient: action.marketingClient};
        case SET_CLIENT_FINGERPRINTS:
            return {...state, loading: false, error: false, fingerprints: action.fingerprints};
        case RESET_NEWCLIENTPROCESS:
            return initialState;
        default:
            return state;
    }
};
export default newClientProcess;
