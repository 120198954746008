import {
    ICardData,
    ICardDataAPI,
    IPaymentMethodsReducer,
} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {
    PAYMENT_METHOD_ERROR,
    PAYMENT_METHOD_START,
    GET_CLIENT_CARDS_SUCCESS,
    PAYMENT_METHOD_SUCCESS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {AxiosResponse} from 'axios';

const initialState: IPaymentMethodsReducer = {
    cards: null,
    defaultPaymentMethod: null,
    loading: false,
    error: false,
    mustBeUpdated: true,
};

const paymentMethods = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
    }
): IPaymentMethodsReducer => {
    switch (action.type) {
        case PAYMENT_METHOD_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CLIENT_CARDS_SUCCESS:
            return populateClientCards(state, action.payload);
        case PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                mustBeUpdated: true,
            };
        case PAYMENT_METHOD_ERROR:
            return {
                ...initialState,
                error: true,
            };
        default:
            return state;
    }
};

const populateClientCards = (
    state: IPaymentMethodsReducer,
    response: AxiosResponse
): IPaymentMethodsReducer => {
    const data: ICardDataAPI[] = response.data;
    let defaultPaymentMethod: ICardData | null = null;
    const newCardCollection: ICardData[] | null =
        data?.map((card) => {
            const newCard = {
                cardNumber: card.cardNumber,
                isDefaultCard: card.default,
                paymentMethodId: card.paymetMethodId,
                typeImageCard: card.cardType.urlImagen,
            };
            if (card.default) defaultPaymentMethod = newCard;
            return newCard;
        }) ?? null;
    return {...state, defaultPaymentMethod, cards: newCardCollection, mustBeUpdated: false};
};

export default paymentMethods;
