import {
    RESET_SAVED_LEVELS,
    SAVE_ARTICLE,
    SAVE_BRAND,
    SAVE_FAMILY,
    SAVE_GROUP,
    SAVE_SUBFAMILY,
} from '@/components/Articles/Redux/ManageTypes';
interface IData {
    GroupId: number;
    FamilyId: number;
    SubfamilyId: number;
    BrandId: number;
    ArticleId: number;
}
const INITIAL_STATE: IData = {
    GroupId: 0,
    FamilyId: 0,
    SubfamilyId: 0,
    BrandId: 0,
    ArticleId: 0,
};

const SavedLevels = (state = INITIAL_STATE, action: {type: string; payload: number}) => {
    switch (action.type) {
        case SAVE_GROUP:
            return {
                ...state,
                GroupId: action.payload,
            };
        case SAVE_FAMILY:
            return {
                ...state,

                FamilyId: action.payload,
            };
        case SAVE_SUBFAMILY:
            return {
                ...state,

                SubfamilyId: action.payload,
            };
        case SAVE_BRAND:
            return {
                ...state,

                BrandId: action.payload,
            };
        case SAVE_ARTICLE:
            return {
                ...state,

                ArticleId: action.payload,
            };
        case RESET_SAVED_LEVELS:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default SavedLevels;
