import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {StatusPayment} from '@/components/MyAccount/MyCredit/CreditDetails/StatusPayment';
import ArrowRight from '@images/arrow right gray.svg';
import {ActionPaymentCredits} from '@/components/MyAccount/MyCredit/CreditDetails//ActionPaymentCredits';
import {CREDITSROUTE} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
export const CreditsPayment = () => {
    const [solveResume, SetSolveResume] = useState(false);
    return (
        <ContainerFlex
            FlexDir="column"
            Width="100%"
            Justify="start"
            Align="start"
            Padding="1.5rem 3.75rem"
            backG="#FAFAFF"
            Gap="1.2rem"
        >
            <ContainerFlex Justify="start" Gap="0.25rem" Height="24px">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.INITIAL} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.ACCOUNT} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.LOANS} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#0D166B" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.PAY}
                </Text>
            </ContainerFlex>
            {solveResume ? (
                <StatusPayment />
            ) : (
                <ActionPaymentCredits SetSolveResume={SetSolveResume} />
            )}
        </ContainerFlex>
    );
};
