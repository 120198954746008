import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import SuccessHeader from '@/components/QuickPayment/SuccessPaymentSummary/SuccessHeader';
import SuccessFooter from '@/components/QuickPayment/SuccessPaymentSummary/SuccessFooter';
import SuccessInfoMessage from '@/components/QuickPayment/SuccessPaymentSummary/SuccessInfoMessage';
import SuccessSummary from '@/components/QuickPayment/SuccessPaymentSummary/SuccessSummary';
import SuccessFormVoucher from '@/components/QuickPayment/SuccessPaymentSummary/SuccessFormVoucher';
import {FieldValues, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {sendConfirmationVoucher} from '@/components/QuickPayment/Redux/Actions/SendConfirmationVoucher';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ERROR_MESSAGE} from '@/components/QuickPayment/Constants';

const SuccessPaymentSummary = () => {
    const dispatch = useDispatch();
    const {emailSend} = useSelector((state: RootState) => {
        return state.sendConfirmationVoucher;
    });
    const schema = yup.object().shape({
        email: yup
            .string()
            .email(ERROR_MESSAGE.EMAIL_NO_VALID)
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                ERROR_MESSAGE.EMAIL_NO_VALID
            )
            .required(ERROR_MESSAGE.EMAIL_REQUIRED),
    });
    const {personalCreditsSelected} = useSelector((state: RootState) => {
        return state.addFastPayment;
    });
    const solicitudCreditoPersonalId = personalCreditsSelected[0]?.personalCreditId;
    const {
        register,
        watch,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<FieldValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        },
    });
    const email = watch().email;
    const onSubmit = () => {
        dispatch(sendConfirmationVoucher({solicitudCreditoPersonalId, email}));
    };
    return (
        <ContainerFlex Align="center" Justify="center" Height="100vh" backG="#F0F0FF">
            <ContainerFlex
                FlexDir="column"
                Gap="24px"
                Padding="24px"
                backG="#FFFFFF"
                Border="1px solid #E8E9EA"
                BoxShadow="0px 4px 8px 0px rgba(228, 204, 255, 0.15)"
                Radius="24px"
                Width="916px"
                Height={emailSend ? '646px' : '734px'}
            >
                <SuccessHeader />
                <ContainerFlex FlexDir="column" Gap="24px">
                    <SuccessInfoMessage />
                    <SuccessSummary />
                    <SuccessFormVoucher
                        emailSend={emailSend}
                        onSubmit={onSubmit}
                        register={register}
                        handleSubmit={handleSubmit}
                        control={control}
                        email={email}
                        errorMessage={errors.email?.message as string}
                    />
                </ContainerFlex>
                <SuccessFooter />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SuccessPaymentSummary;
