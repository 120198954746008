import styled from '@emotion/styled';

export const ContainerAdminCards = styled.div`
    display: flex;
    padding: 62px 100px;
    justify-content: flex-start;
    div:last-child {
        margin-left: 50px;
    }
    div {
        justify-content: space-around;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 19px 11px 19px 11px;
        background: #f3f3f3 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width: 150px;
        height: 150px;
        h2 {
            text-align: left;
            font: normal normal normal 16px/19px Work Sans;
            letter-spacing: 0px;
            color: #414042;
            opacity: 1;
        }
        span {
            text-align: center;
            font: normal normal normal 10px/11px Work Sans;
            letter-spacing: 0px;
            color: #414042;
            opacity: 1;
        }
    }
`;
export const ContainerTableBanner = styled.div`
    width: 90vw;
    padding-top: 2rem;
    margin: 0 auto;

    div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 41px;
        span {
            text-align: left;
            font: normal normal normal 16px Work Sans;
            letter-spacing: 0px;
            color: #4a4a4a;
            opacity: 1;
        }
        button {
            border: 1px solid #414042;
            text-transform: uppercase;
            border-radius: 5px;
            width: 160px;
            height: 40px;
            background-color: white;
            box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
            &:hover {
                border-color: #7ac143;
            }
        }
    }
    > table {
        width: 100%;

        tbody tr {
            height: 43px;
            border-radius: 5px;
            margin-bottom: 5px;
            td {
                padding: 0;
                text-align: left;
            }
            td:first-child {
                padding-left: 14px;
            }
        }

        thead {
            background: #f3f3f3 0% 0% no-repeat padding-box;
            border-radius: 5px;
            height: 52px;
            th:first-child {
                padding-left: 14px;
                font: normal normal medium 14px/16px Work Sans;
                letter-spacing: 0px;
                color: #4a4a4a;
            }
        }
    }
`;
export const ButtonSubmit = styled.button`
    float: right;
    margin: 10px 0 20px;
    border: 1px solid #414042;
    text-transform: uppercase;
    border-radius: 5px;
    width: 160px;
    height: 40px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    &:hover {
        border-color: #7ac143;
    }
`;
export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    .name {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
            display: flex;
            flex-direction: column;
            width: 30%;
        }
    }

    .row-Article {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        div {
            display: flex;
            flex-direction: column;
            width: 21%;
            padding: 10px 0;
        }
    }
    > div:nth-child(4) {
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
`;
