import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, AppDispatch} from '@config/store';
import {DateTime} from 'luxon';
import Swal from 'sweetalert2';

import {getConsultAssignment} from '@ActionsConsultAssignment/ConsultAssignmentAction';
import {putCancelAssignment} from '@ActionsConsultAssignment/ConsultAssignmentAction';
import {TableTrConsult} from '@ConsultAssignment/style';
interface NewType {
    noAsignacio: number;
    estatus: string;
    fundidora: string;
    fecha: string;
    auditor: string;
    noSeguribolsa: string;
    noPapeleta: string;
    noPrendas: number;
    costo: number;
    sucursal: string;
    region: string;
}
export const RowConsultAssignment = (props: NewType): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const [count, setCount] = useState(0);
    const history = useNavigate();
    const EditAssignment = (id: number) => {
        setCount(0);
        setCount(id);
    };
    const redirectoEdition = (id: number) => {
        // dispatch( obtenerProductoEditar(producto) );
        history(`Edicion/${id}`);
    };
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const assignment = useSelector((state: RootState) => {
        return state.assignment;
    });
    const cancelAssignment = (id: number) => {
        Swal.fire({
            title: 'Cancelar asignación',
            text: '¿Esta seguro que desea cancelar la asignación?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.value) {
                const json = {
                    idAsignacion: id,
                    idUsuario: 5,
                    fecha: DateTime.local().toString(),
                    motivo: '',
                };

                await dispatch(putCancelAssignment(json, token));
                dispatch(
                    getConsultAssignment(
                        assignment.filters,
                        10,
                        assignment.assignmentPageIndex,
                        token
                    )
                );
            }
        });
    };
    const {
        noAsignacio,
        estatus,
        sucursal,
        fundidora,
        fecha,
        auditor,
        noPapeleta,
        noSeguribolsa,
        noPrendas,
        costo,
    } = props;
    return (
        <TableTrConsult onClick={() => EditAssignment(noAsignacio)}>
            <td>{noAsignacio}</td>
            <td>{estatus}</td>
            <td>{sucursal}</td>
            <td>{fundidora}</td>
            <td>{DateTime.fromISO(fecha).toLocaleString(DateTime.DATE_SHORT)}</td>
            <td>{auditor}</td>
            <td>{noPapeleta}</td>
            <td>{noSeguribolsa}</td>
            {count === noAsignacio ? (
                <td onClick={() => redirectoEdition(noAsignacio)}>
                    <i className="material-icons">create</i> <span>Editar</span>
                </td>
            ) : (
                <td>{noPrendas}</td>
            )}
            {count === noAsignacio && estatus === 'Pendiente' ? (
                <td onClick={() => cancelAssignment(noAsignacio)}>
                    <i className="material-icons">clear</i> <span>Cancelar</span>
                </td>
            ) : (
                <td>{costo}</td>
            )}
        </TableTrConsult>
    );
};
