export const ADMIN_GENERAL = {
    name: 'Multiapoyo SAS',
    rfc: 'RFC',
    rfcNumber: 'XEXT990101NI4',
    edit: 'Editar',
    totalGross: 'Total bruto acumulado',
    month: 'Enero 2024',
    total: '$537.000',
    prevMonth: '+25% del mes anterior',
    prevYear: '+15% del año anterior',
    totalNet: 'Total neto acumulado',
    monthNet: 'Enero 2024',
    totalNetNumber: '$537.000',
    prevMonthNet: '+10% del mes anterior',
    prevMonthYear: '-5% del año anterior',
};

export const BAR_CHART_INCOME = {
    net: 'Ingreso neto',
    month: 'Últimos 6 meses',
};

export const LINE_CHART_INCOME = {
    income: 'Ingreso neto',
    month: 'Últimos 6 meses',
};
