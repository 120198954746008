import styled from '@emotion/styled';
import {
    IPropsContainerButton,
    IPropsDivConsult,
    IPropsDownloadLink,
    IPropsLittleBox,
    IPropsTableItem,
    IPropsTableItems,
    IPropsTableItemsContainer,
    IPropsTableItemsHistory,
    IPropsTableRowHead,
    IPropsTableRowHeadContract,
    IPropsTableRowHeadHome,
    IPropsTableRowItems,
    IPropsTableRowItemsContract,
    IPropsTableRowItemsHome,
} from '@Endeavors/interfacesStyles';
export const ContainerConsult = styled.div`
    width: 90vw;
    margin: auto;
`;
export const Filter = styled.div`
    margin: 30px 0 5px 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 2rem;
    i {
        font-size: 20px;
        margin-right: 0.5rem;
    }
`;
export const TableConsult = styled.div`
    width: 90vw;
    margin: auto;
    margin-bottom: 1rem;
    color: var(--gray-dark);
`;
export const TableTrConsult = styled.tr`
    cursor: pointer;
    height: 2.5rem;
    padding: 0.5rem 0;
    &:hover {
        background: var(--gray-4);
    }
    td {
        border-bottom: 1px solid var(--gray-4);
    }
    i,
    span:last-child {
        color: #7ac143;
        font-size: 12px;
    }
`;
export const CardConsult = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none !important;
    box-shadow: 0px 1px 3px #00000033;
    border-radius: 0.25rem;
`;
export const ContainerFilter = styled.div`
    height: 98px;
    display: flex;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 20px 30px 16px 30px;
    form {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }
`;
export const DivConsult = styled.div<IPropsDivConsult>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.end && 'flex-end'};
    height: ${(props) => props.ht && '40px'};
    padding-bottom: 1.5rem;
    width: ${(props) => (props.width ? '30%' : '20%')};
    padding-left: ${(props) => (props.pl ? '1.5rem' : '1rem')};
    padding-left: ${(props) => props.PL && '3rem'} !important;
    padding-right: ${(props) => (props.pr ? '1rem' : '1.5rem')};
    margin-top: ${(props) => (props.mt ? '1rem' : '3rem')};
    button:last-child {
        border: 1px solid var(--black);
        text-transform: uppercase;
        border-radius: 5px;
        width: 160px;
        height: 40px;
        align-items: center;
        background-color: white !important;
        box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    }
    button:last-child:hover {
        border-color: var(--green);
    }
`;
export const ButtonConsult = styled.button`
    border: 1px solid var(--black);
    text-transform: uppercase;
    border-radius: 5px;
    width: 160px;
    height: 40px;
    margin-left: 2rem;
    align-items: center;
    background-color: white !important;
    box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);

    button:hover {
        border-color: var(--green);
    }
`;
export const Inputs = styled.input`
    background: fixed;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray-3);
    padding: 2px 12px;
`;
export const ContainerNotification = styled.div`
    position: fixed;
    padding: 1.5rem;
    border-radius: 15px;
    display: flex;
    width: 60%;
    flex-direction: column;
    margin: 5% 30%;
    min-height: 69px;
    max-height: 200px;
    box-sizing: border-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    border-radius: 5px;
`;
export const ContainerButton = styled.button<IPropsContainerButton>`
    box-sizing: border-box;
    display: flex;
    font: ${(props) => props.Font};
    letter-spacing: 0px;
    align-items: baseline;
    justify-content: center;
    background: none;
    /* border-radius: 5px; */
    margin-top: ${(props) => props.Mt};
    border: none;
`;
ContainerButton.defaultProps = {
    Font: 'normal normal normal 14px Work Sans',
    Mt: '0',
};
export const Table = styled.div`
    width: 100%;
    display: grid;
    background: #fff;
`;
export const TableItemsContainer = styled.div<IPropsTableItemsContainer>`
    background: #fff;
    display: grid;
    grid-template-rows: ${(props) => props.GridRow};
    grid-template-columns: ${(props) => props.GridColumns};
    height: ${(props) => props.Height};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    overflow: ${(props) => props.Overflow};
    flex-wrap: ${(props) => props.Wrap};
    grid-auto-flow: ${(props) => props.GridFlow};
    padding: ${(props) => props.Padding};
    max-height: ${(props) => props.MaxH};
    min-height: ${(props) => props.MinH};
    &::-webkit-scrollbar {
        width: 0rem;
    }
`;
TableItemsContainer.defaultProps = {
    Height: '90%',
    GridRow: 'repeat(auto, 4.313rem)',
    Overflow: 'auto',
    Width: '100%',
};
export const TableRowItems = styled.div<IPropsTableRowItems>`
    display: grid;
    justify-content: ${(props) => props.Justify};
    grid-template-columns: ${(props) => props.GridColumn};
    align-content: ${(props) => props.Align};
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    width: ${(props) => props.Width};
    opacity: ${(props) => props.Opacity};
    background: ${(props) => props.Background};
    min-height: ${(props) => props.MinHeight};
    max-height: ${(props) => props.MaxHeight};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    border-left: ${(props) => props.BorderL};
    position: ${(props) => props.Position};
    border-top: ${(props) => props.BorderT};
    border-bottom: ${(props) => props.BorderB};
    border-right: ${(props) => props.BorderR};
    &:hover {
        cursor: ${(props) => props.Cursor};
        background-color: ${(props) => props.BackGColor};
    }
`;
TableRowItems.defaultProps = {
    Opacity: '100%',
    GridColumn: '0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr',
    Margin: '5px 0 0 0',
    Width: '100%',
    Border: '0.5px solid #e4e7e9',
    Padding: 'none',
    Height: 'none',
    Justify: 'none',
    MinHeight: '2rem',
    MaxHeight: '4rem',
    Cursor: 'pointer',
    Background: '#FFFFFF',
    BackGColor: '#f5fbff',
    Radius: '5px',
    Align: 'center',
};

export const TableRowItemsHome = styled.div<IPropsTableRowItemsHome>`
    display: grid;
    align-items: center;
    position: relative;
    margin-top: 5px;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    grid-template-columns: ${(props) => props.GridColumn};
    padding: ${(props) => props.Padding};
    min-height: 43px;
    height: 70px;
    &:hover {
        cursor: default;
    }
`;
TableRowItemsHome.defaultProps = {
    GridColumn: '1fr 2fr 1fr 1fr 1fr 1fr 1fr',
};
export const TableRowItemsPrendas = styled.div`
    width: 90%;
    margin: 0 auto;
    display: grid;
    margin-top: 5px;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    grid-template-columns: repeat(7, 1fr);
    background: #fff;
    min-height: 3rem;
    max-height: 6rem;
    &:hover {
        cursor: pointer;
        background-color: #f5fbff;
    }
`;
export const TableRowItemsContract = styled.div<IPropsTableRowItemsContract>`
    display: grid;
    margin-top: 5px;
    border-radius: 5px;
    grid-template-columns: ${(props) => props.GridTemplateColumn};
    background: #fff;
    min-height: 3rem;
    max-height: 6rem;
    &:hover {
        cursor: pointer;
        background-color: #f5fbff;
    }
`;
TableRowItemsContract.defaultProps = {
    GridTemplateColumn: 'repeat(5, 1fr)',
};

export const TableRowItemsHistory = styled.div`
    display: grid;
    margin-top: 5px;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    width: 990px;
    grid-template-columns: 0.7fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr;
    background: #fff;
    min-height: 3rem;
    max-height: 6rem;
    background: #f3f3f3 0% 0% no-repeat padding-box;
`;
/* grid-template-columns: repeat(5, 1fr); */
export const TableRowHead = styled.div<IPropsTableRowHead>`
    display: grid;
    justify-content: ${(props) => props.Justify};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    text-align: center;
    border-radius: ${(props) => props.Radius};
    grid-template-columns: ${(props) => props.GridColumn};
    background: ${(props) => props.backG};
    font-family: work-sans;
    font-style: normal;
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    padding: ${(props) => props.Padding};
`;

TableRowHead.defaultProps = {
    GridColumn: '0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr',
    Height: '52px',
    Width: '100%',
    Margin: 'none',
    Justify: 'none',
    backG: '#f3f3f3 0% 0% no-repeat padding-box',
    Radius: '5px',
};

export const TableRowHeadHome = styled.div<IPropsTableRowHeadHome>`
    display: grid;
    grid-template-columns: ${(props) => props.GridColumn};
    height: ${(props) => props.Height};
    background: ${(props) => props.BackG};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    justify-content: start;
    align-items: flex-start;
    border-radius: 8px;
`;
TableRowHeadHome.defaultProps = {
    GridColumn: '1fr 2fr 1fr 1fr 1fr 1fr 1fr',
    Height: '52px',
    Width: 'none',
    Padding: 'none',
    Margin: 'none',
    BackG: '#e5e7e9 0% 0% no-repeat padding-box',
};
export const TableRowHeadPrendas = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 3rem;
    background: #f3f3f3 0% 0% no-repeat padding-box;
`;
export const TableRowHeadContract = styled.div<IPropsTableRowHeadContract>`
    display: grid;
    grid-template-columns: ${(props) => props.GridTemplate};
    height: 52px;
    background: ${(props) => props.Background};
    padding: ${(props) => props.Padding};
    border: 1px solid #e4e7e9;
    border-radius: 5px;
`;
TableRowHeadContract.defaultProps = {
    Background: ' #ffffff 0% 0% no-repeat padding-box',
    GridTemplate: 'repeat (5, 1fr)',
};
export const ImgQR = styled.img`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 5rem;
    background: #f3f3f3 0% 0% no-repeat padding-box;
`;
export const TabsContainer = styled.div`
    background-color: #ffffff;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding-left: 38px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 5px;
`;

export const TableItem = styled.div<IPropsTableItem>`
    text-align: ${(props) => props.TextAlign};
    display: flex;
    align-items: ${(props) => props.Align};
    justify-content: ${(props) => props.Justify};
    font: normal normal 400 14px Work Sans;
    letter-spacing: 0px;
    margin: ${(props) => props.Margin};
    color: ${(props) => props.Color};
    border: #ffffff;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    font-weight: ${(props) => props.Weight};
    font-size: ${(props) => props.Size};
    padding: ${(props) => props.Padding};
    flex-direction: ${(props) => props.FlexDir};
    gap: ${(props) => props.Gap};
    text-transform: ${(props) => props.Transform};
    box-sizing: border-box;
`;
TableItem.defaultProps = {
    Justify: 'center',
    Align: 'center',
    Margin: 'none',
    Padding: 'none',
    FlexDir: 'row',
    Width: '100%',
    Weight: '400',
    Size: '14px',
    Color: '#4a4a4a',
    TextAlign: 'center',
};

//refrendar Shopi, desempeñar Stripe, reactivar, mensaje

export const TableItems = styled.div<IPropsTableItems>`
    display: flex;
    opacity: 100%;
    text-overflow: ellipsis;
    width: ${(props) => props.Width};
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    align-items: ${(props) => props.Align};
    font-family: Work Sans;
    font-weight: ${(props) => props.Weigth};
    font-size: ${(props) => props.Size};
    text-decoration: ${(props) => props.TextDecoration};
    line-height: 20px;
    color: ${(props) => props.Color};
    padding: ${(props) => props.Padding};
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
    border-left: ${(props) => props.Bl};
    border-right: ${(props) => props.Br};
    border: ${(props) => props.Border};
    white-space: ${(props) => props.wSpace};
    img {
        border-radius: 6px;
    }
`;

TableItems.defaultProps = {
    Justify: 'center',
    Align: 'center',
    FlexDir: 'row',
    Weigth: '500',
    Size: '16px',
    Padding: '0',
    Bb: 'none',
    Border: 'none',
    Color: '#4a4a4a',
    TextDecoration: 'none',
    wSpace: 'normal',
    Width: '100%',
};
export const TableItemsHistory = styled.div<IPropsTableItemsHistory>`
    display: flex;
    justify-content: ${(props) => props.Justify};
    align-items: center;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr;
    font: normal normal 400 14px Work Sans;
    line-height: 20px;
    color: #4a4a4a;
    img {
        width: 20px;
    }
`;
TableItemsHistory.defaultProps = {
    Justify: 'center',
};

export const TableItemsHistory2 = styled.div`
    align-items: center;
    display: flex;
    img {
        justify-content: center;
        align-items: center;
        width: 24px;
        border-radius: 5px;
    }
`;

export const TableItemsHistory1 = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
        width: 22px;
    }
`;

export const Tooltip = styled.a`
    &:hover div {
        display: inline;
        position: absolute;
        border: 2px solid #fff;
        color: #eee;
    }

    div {
        display: none;
    }
`;
export const ContainerInfoContract = styled.div`
    height: 25rem;
    width: 32%;
    margin-top: 1rem;
    box-sizing: border-box;
    padding: 17px 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e4e7e9;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 768px) {
        height: 23rem;
        width: 48%;
        padding: 15px 10px;
        margin-top: 2rem;
    }
    @media (max-width: 450px) {
        width: 100%;
        padding: 10px 5px;
        height: 20rem;
        margin-top: 2rem;
    }
`;

export const DocImg = styled.img`
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @media (max-width: 800px) {
        width: 80px;
        height: 80px;
    }
`;
export const Card = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
`;
export const HeaderCard = styled.div`
    display: flex;
    height: 2.5rem;
    box-sizing: border-box;
    align-items: center;
    padding-left: 1rem;
    background: #f3f3f3 0% 0% no-repeat padding-box;
`;
export const AvatarPrenda = styled.img`
    vertical-align: middle;
    margin: auto 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

export const FigureImg = styled.figure`
    width: 100%;
    text-align: center;
`;
export const Modal = styled.div`
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fefefe;
    margin: auto;
    padding: 2.5rem;
    border: 1px solid #888;
    width: 30vw;
    height: 55vh;
    justify-content: space-between;
`;
export const Linkqr = styled.a`
    text-decoration: underline;
    padding: 0 0 0 0;
`;

export const DownloadLink = styled.a<IPropsDownloadLink>`
    font-family: Work Sans;
    display: ${(props) => props.Display};
    width: ${(props) => props.Width};
    white-space: ${(props) => props.WhiteSpace};
    overflow: ${(props) => props.Overflow};
    text-overflow: ${(props) => props.TextOver};
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    cursor: ${(props) => props.Cursor};
    text-decoration: ${(props) => props.TextD};
`;
export const CredentialImg = styled.img`
    max-width: 40vw;
    max-height: 40vh;
`;

export const LittleBox = styled.div<IPropsLittleBox>`
    display: flex;
    align-items: flex-start;
    height: 9px;
    padding: 1px;
    border-radius: 5px 0px 5px 0px;
    background: ${(props) => props.BackGround};
    text-align: center;
    font: normal normal normal 8px Work Sans;
    letter-spacing: 0px;
    color: #ffffff;
`;

LittleBox.defaultProps = {
    BackGround: '#EF4F55',
    //#7AC143 Verde
    //#EF4F55 Rojo
};

export const Checkbox = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Company = styled.div`
    color: #7ac143;
    font: normal normal 400 12px Work Sans;
`;
