import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_AND_PAWNS_HISTORY_ERROR,
    GET_CREDIT_AND_PAWNS_HISTORY_START,
    GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {ICreditAndPawnsHistoryAction} from '@components/CreditsAndPawns/interfaces';

const getCreditAndPawnsHistoryStart = () => {
    return {
        type: GET_CREDIT_AND_PAWNS_HISTORY_START,
    };
};

const getCreditAndPawnsHistorySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS,
        payload: result,
    };
};

const getCreditAndPawnsHistoryError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_AND_PAWNS_HISTORY_ERROR,
        error: error,
    };
};

export const getCreditAndPawnsHistoryAction = ({token, clientId}: ICreditAndPawnsHistoryAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditAndPawnsHistory}?clientId=${clientId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditAndPawnsHistoryStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getCreditAndPawnsHistorySuccess(response.data));
        } catch (e) {
            dispatch(getCreditAndPawnsHistoryError(e as AxiosError));
        }
    };
};
