import React from 'react';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {statusColorHandler} from '@/components/CreditsAndPawns/ApplicationRow';
import {IStatusInfoProps} from '@/components/MyAccount/interfaces';
import {DEFAULT_STATUS_INFO_LABEL} from '@/components/MyAccount/constants';

const StatusInfo = ({statusId, statusLabel}: IStatusInfoProps) => {
    const {color, backgroundColor, borderColor} = statusColorHandler(statusId || 1);
    return (
        <Text
            FontFamily="Nunito"
            Color={color}
            BorderRadius="32px"
            Padding="2px 8px"
            Border={`1px solid ${borderColor}`}
            BGColor={backgroundColor}
            FontSize="0.875rem"
            FontWeight="700"
            Height="21px"
        >
            {statusLabel || DEFAULT_STATUS_INFO_LABEL}
        </Text>
    );
};

export default StatusInfo;
