import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import React, {useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, ContainerForm, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIconCharges from '@components/PersonalLoans/NewLoanProduct/icons/closeIconCharges.svg';
import {AppDispatch, RootState} from '@/config/store';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {
    IDefaultEditChargeForm,
    IDefaultEditChargeFormProps,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {editCharge} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {AmountSelect} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DefaultEditChargeForm/AmountSelect';
import {SubmitButton} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/SubmitButton';
import {schemaDefaultCharges} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/schemaChargesAndPenalties';

export const DefaultEditChargeForm: React.FC<IDefaultEditChargeFormProps> = ({
    chargeState,
    setChargeState,
}) => {
    const {selectedCharge} = useSelector((state: RootState) => state.setChargesAndPenaltiesTable);
    const dispatch: AppDispatch = useDispatch();
    const {
        watch,
        control,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm<IDefaultEditChargeForm>({
        resolver: yupResolver(schemaDefaultCharges),
    });
    const onSubmit = (data: IDefaultEditChargeForm) => {
        dispatch(
            editCharge({
                chargeAmount: {
                    typeChargeAmountId: data.typeChargeAmount.value,
                    label: data.typeChargeAmount.label,
                    amount: data.amount,
                },
            })
        );
        setChargeState({
            ...chargeState,
            showDefaultEditCharge: false,
        });
    };
    useEffect(() => {
        reset({
            typeChargeAmount: {
                value: selectedCharge.chargeAmount.typeChargeAmountId,
                label: selectedCharge.chargeAmount.label,
            },
            amount: selectedCharge.chargeAmount.amount,
        });
    }, []);

    return (
        <>
            <ContainerForm
                Padding="0px 1rem 1rem 1rem"
                Border="1px solid #5A5AFF"
                background="#FFF"
                Radius="1rem"
                Height="max-content"
                FlexDir="column"
                Position="relative"
            >
                <ContainerFlex Justify="end" Height="24px" Position="relative" PositionTop="8px">
                    <Image
                        src={closeIconCharges}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="close-icon"
                        onClick={() => {
                            setChargeState({
                                ...chargeState,
                                showCreateCharge: false,
                                isCreating: true,
                                showDefaultEditCharge: false,
                            });
                        }}
                    />
                </ContainerFlex>
                <ContainerFlex Gap="1rem" FlexDir="column">
                    <GridContainer Gap="1rem" GridColumns="repeat(3, 1fr)">
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Color="#1D1E20" FontWeight="700">
                                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CHARGE_TYPE}
                            </Text>
                            <Text Color="#2A2C2F">{selectedCharge.typeCharge.label}</Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Color="#1D1E20" FontWeight="700">
                                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CHARGE_NAME}
                            </Text>
                            <Text Color="#2A2C2F">{selectedCharge.chargeName}</Text>
                        </ContainerFlex>
                        <AmountSelect control={control} errors={errors} watch={watch} />
                    </GridContainer>
                    <GridContainer GridColumns="repeat(3, 1fr)" Gap="1rem">
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Color="#1D1E20" FontWeight="700">
                                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CONDITION}
                            </Text>
                            <Text Color="#2A2C2F">{selectedCharge.condition.label}</Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Color="#1D1E20" FontWeight="700">
                                {CHARGES_AND_PENALTIES.FIELDS_TITLES.EVENT_TYPE}
                            </Text>
                            <Text Color="#2A2C2F">{selectedCharge.eventType.label}</Text>
                        </ContainerFlex>

                        <ContainerFlex Align="end" Justify="end">
                            <SubmitButton handleClick={handleSubmit(onSubmit)} />
                        </ContainerFlex>
                    </GridContainer>
                </ContainerFlex>
            </ContainerForm>
        </>
    );
};
