import React from 'react';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {numberToCurrency} from '@/components/CashFlow/constants';
import {TableItem, TableRowItems} from '@/components/Shopify/Endeavors/styles';
import {IAmortizationRowDataProps} from '@components/PersonalLoans/interfaces';

export const AmortizationRowData = ({
    amountQuote,
    capitalPayment,
    interest,
    outstandingBalance,
    quoteNumber,
}: IAmortizationRowDataProps) => {
    return (
        <>
            <TableRowItems
                Align="start"
                Margin="0px"
                BorderL="none"
                BorderT="none"
                GridColumn="91px repeat(4,1fr)"
                Cursor="default"
                BackGColor="#fff"
                Height="47px"
                Border=""
                Radius=""
                BorderB="1px solid #E8E9EA"
            >
                {[
                    {value: quoteNumber, label: 'Quote Number'},
                    {value: capitalPayment, label: 'Capital Payment'},
                    {value: interest, label: 'Interest'},
                    {value: amountQuote, label: 'Amount Quote'},
                    {value: outstandingBalance, label: 'Outstanding Balance'},
                ].map((item, index) => (
                    <TableItem Padding="1.125rem 1rem" Justify="start" Height="48px" key={index}>
                        <Text FontSize="0.875rem" Color="#2A2C2F">
                            {item.value !== quoteNumber ? numberToCurrency(item.value) : item.value}
                        </Text>
                    </TableItem>
                ))}
            </TableRowItems>
        </>
    );
};
