import {ITypeAccess} from '@/components/QuickPayment/interfaces';

export const ACCESSPAYMENT = {
    TITLE: 'Pago rápido',
    ACTION: 'Para realizar el pago necesitamos solo uno de los siguientes datos:',
    TYPE_OF_DATA: {
        CONTRACT: 'Nº de contrato',
        TELEPHONE: 'Nº teléfono',
        EMAIL: 'Correo',
    },
    CTA_PAYMENT: 'Realizar pago',
    MSG_DOES_NOT_EXIST_BASE: 'No existe este',
    MSG_DOES_NOT_HAVE_PAYMENTS_BASE: 'No hemos encontrado pagos pendientes',
    MSG_DOES_NOT_HAVE_PAYMENTS_COMPLEMENT: 'ligados a este ',
};
export const ACTIONPAYMENT = {
    TITLE: 'Pago rápido',
    ENABLE: 'Pagos disponibles',
    STEP1: '1',
    STEP2: '2',
    METHOD: 'Método de pago',
};
export const ENABLEPAYMENT = {
    NAME: 'Préstamo a nombre de',
    BUSINESS: 'Negocio',
    VALUE: 'Monto total',
    LOAN: 'PRESTAMOS',
    PAWN: 'Empeños',
};
export const METHODPAYMENT = {
    TITLE: '¿Cómo quieres pagar?',
    CARD: 'Tarjeta de crédito o débito',
    NUMBER: 'Número de la tarjeta',
    NAME: 'Nombre del titular de la tarjeta',
    DATE: 'Fecha de caducidad',
    CCV: 'CVV',
};
export const RESUMEPAYMENT = {
    TITLE: 'Resumen del pago a realizar',
    RESUME: 'Total a pagar ahora',
    CHOOSE: 'Elegir método de pago',
    PAY: 'Pagar ahora',
    TOTAL_LATE_LOAN: 'Pago de cuota atrasada',
    TOTAL_LOAN: 'Pago cuota préstamo',
    LOADING: 'Procesando....',
};

export const TYPE_OF_LOAN = {
    TYPE_LOAN: 'prestamo',
    TYPE_LATE: 'atrasada',
};

export const TYPE_ACCESS: ITypeAccess[] = [
    {
        name: ACCESSPAYMENT.TYPE_OF_DATA.CONTRACT,
        id: 1,
        title: 'Estos son los pagos que hemos encontrado ligados con el número de contrato: ',
    },
    {
        name: ACCESSPAYMENT.TYPE_OF_DATA.TELEPHONE,
        id: 2,
        title: 'Estos son los pagos que hemos encontrado ligados con este teléfono: ',
    },
    {
        name: ACCESSPAYMENT.TYPE_OF_DATA.EMAIL,
        id: 3,
        title: 'Estos son los pagos que hemos encontrado ligados con este correo electrónico: ',
    },
];

export const HEADER_TITLE = 'Pago rápido';

export const FOOTER_ACTION_BTN_TEXT = 'Hacer otro pago';

export const INFO_MESSAGE = {
    TITLE: '¡Tu pago fue realizado con éxito!',
    SUB_TITLE: 'Se ha enviado el comprobante al correo electrónico registrado del titular.',
};

export const PAYMENT_SUMMARY = {
    TITLE: 'Resumen del pago',
    GO_TO_MY_ACCOUNT_BTN_TEXT: 'Ir a Mi cuenta',
    GO_TO_MY_ACCOUNT_URL: '/#/Login/MarketUser',
    PAYMENT_ITEMS: [
        {
            TITLE: 'Pago cuota préstamo',
            AMOUNT: '$450.00',
        },
        {
            TITLE: 'Pago de cuota atrasada',
            AMOUNT: '$550.00',
        },
        {
            TITLE: 'Total',
            AMOUNT: '$1,000.00',
        },
    ],
};

export const VOUCHER_FORM = {
    TITLE: '¿Quieres enviarle el comprobante a otra persona?',
    ACTION_BTN_TEXT: 'Enviar comprobante',
    CLOSE_BTN_TEXT: 'X',
    SUCCESS_MESSAGE: 'El comprobante de la transacción fue enviado con éxito al correo',
    FORM_FIELDS: {
        EMAIL: {
            NAME: 'email',
            LABEL: 'Correo electrónico',
        },
    },
};

export const ERROR_MESSAGE = {
    EMAIL_NO_VALID: 'Correo no válido',
    EMAIL_REQUIRED: 'Correo electrónico es requerido',
};

export const SCHEMA_QUICK_PAYMENT = {
    REQUIRED: 'Campo obligatorio.',
    REQUIRED_LADA: 'Campo Lada obligatorio.',
    SCHEMA_PHONE: {
        MATCH: 'Número de teléfono inválido.',
        MATCH_LADA: 'Número de Lada inválido.',
    },
    SCHEMA_CONTRACT: {
        MATCH: 'El campo debe contener al menos una letra y un número.',
    },
    SCHEMA_EMAIL: {
        MATCH: 'Correo electrónico no válido',
    },
};

export const SCHEMA_TRANSACION = {
    REQUIRED: 'Campo obligatorio.',
    CARD_NUMBER: {
        MATCH: 'Debe contener entre 12 y 16 dígitos numéricos.',
        TEST: 'Número de tarjeta no válido.',
    },
    CARD_HOLDER_NAME: {
        MATCH: 'Debe contener solo letras y espacios.',
        MIN: 'Debe tener al menos 2 caracteres.',
        MAX: 'Debe tener como máximo 26 caracteres.',
    },
    CARD_EXPIRATION_DATE: {
        MATCH: 'El formato debe ser MM/AAAA.',
        TEST: 'Fecha de caducidad inválida.',
    },
    CARD_CCV: {
        MATCH: 'De 3 o 4 dígitos numéricos.',
    },
};
export const STEP_PAYMENTS = {
    PAYMENTS_TO_MADE: 1,
    PAYMENT_METHOD: 2,
};

export const STATE_ACTIONS = {
    ACCESS_PAYMENT: 1,
    ACTION_PAYMENT: 2,
    SUCCESS_PAYMENT: 3,
};

export const IMAGE_ATL_DESCRIPTION = {
    DONE_ICON: 'Icono de verificación',
    CONNECTOR_ICON: 'Línea divisoria azul',
    INFO_ICON: ' Icono de informacion azul',
    CARD_ICON: 'Icono de tipo de tarjeta de crédito',
    HELP_ICON: 'Abrir diálogo de ayuda',
    CREDIT_CARD_ICON: 'Icono de tarjeta de crédito',
    EXPAND_ICON: 'Icono de flecha para expandir',
    CALENDAR_ICON: 'Icono de calendario',
};
