import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    VALIDATE_IMAGE_PRODUCT,
    VALIDATE_IMAGE_PRODUCT_SUCCESS,
    VALIDATE_IMAGE_PRODUCT_ERROR,
    SET_SELECTED_PLEDGE,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {ImagePledge} from '@Quoter/CoownerBeneficiary/interface';
import {IContract} from '@/components/ShoppingCart/interface';

export const fetchValidateImage = () => {
    return {
        type: VALIDATE_IMAGE_PRODUCT,
    };
};
export const fetchValidateImageSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_IMAGE_PRODUCT_SUCCESS,
        payload: result,
    };
};
export const fetchValidateImageError = (error: AxiosError) => {
    return {
        type: VALIDATE_IMAGE_PRODUCT_ERROR,
        error: error,
    };
};
export function postValidPrechrageimage(
    token: string,
    data: {image: string},
    carShoppingId: number,
    cleanedImagePledges: ImagePledge[],
    setIsCheck: (e: boolean) => void,
    isCheck: boolean
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchValidateImage());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidPrechrageimage}`,
                data,
                {headers}
            );
            dispatch(fetchValidateImageSuccess(response));
            dispatch(
                postUploadImagesArticle(
                    {
                        carShoppingId: carShoppingId,
                        filesPledge: cleanedImagePledges,
                    },
                    setIsCheck,
                    isCheck,
                    token
                )
            );
        } catch (error) {
            dispatch(fetchValidateImageError(error as AxiosError));
        }
    };
}
export const fetchUploadImage = () => {
    return {
        type: VALIDATE_IMAGE_PRODUCT,
    };
};
export const fetchUploadImageSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_IMAGE_PRODUCT_SUCCESS,
        payload: result,
    };
};
export const fetchUploadImageError = (error: AxiosError) => {
    return {
        type: VALIDATE_IMAGE_PRODUCT_ERROR,
        error: error,
    };
};
export function postUploadImagesArticle(
    data: {carShoppingId: number; filesPledge: ImagePledge[]},
    setIsCheck: (e: boolean) => void,
    isCheck: boolean,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUploadImage());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UploadImagesArticle}`,
                data,
                {headers}
            );
            dispatch(fetchUploadImageSuccess(response));
            setIsCheck(!isCheck);
        } catch (error) {
            dispatch(fetchUploadImageError(error as AxiosError));
        }
    };
}

export const setSelectedPledge = (result: IContract) => {
    return {type: SET_SELECTED_PLEDGE, payload: result};
};
