import {ENDEAVORS_CONTRACTS, CAT_STATUS_CONTRACT} from '@Shopify/ClientProfile/Redux/types';
import {IEndeavorContract, IEndeavorContractRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IEndeavorContract = {
    error: false,
    loading: false,
    contracts: {
        clientId: 0,
        totalBalance: 0,
        totalContracts: 0,
        totalPayment: 0,
        pawnContracts: [],
    },
    catStatusContract: [],
    errorCat: false,
    loadingCat: false,
};

export const endeavorsContracs = (
    state = initialState,
    action: IEndeavorContractRedux
): IEndeavorContract => {
    switch (action.type) {
        case ENDEAVORS_CONTRACTS.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ENDEAVORS_CONTRACTS.SUCCESS:
            return {
                ...state,
                contracts: action.contracts,
                loading: false,
            };
        case ENDEAVORS_CONTRACTS.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case CAT_STATUS_CONTRACT.START_PAWN:
            return {
                ...state,
                loadingCat: true,
                errorCat: false,
            };
        case CAT_STATUS_CONTRACT.SUCCESS_PAWN:
            return {
                ...state,
                catStatusContract: action.catStatusContract,
                loadingCat: false,
            };
        case CAT_STATUS_CONTRACT.ERROR_PAWN:
            return {
                ...state,
                loadingCat: false,
                errorCat: true,
            };
        default:
            return state;
    }
};
