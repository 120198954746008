import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {TermAndConditions} from '@Login/MarketPlaceLogin/TermsAndConditions';
import {ResetAccountClient} from '@/components/MarketForgottenLogin/ResetAccount';

export const ResetAcountContainer = () => {
    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <ContainerFlex backG="#E5E5FF" FlexDir="column" Width="100%">
                <ResetAccountClient />
                <TermAndConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
