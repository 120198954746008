import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {NOTIFICATIOS, data} from '@/components/AdminNavbar/Notification/operational/constants';
import {
    IconPhone,
    ImageLogo,
    Line,
    TextS,
} from '@/components/AdminNavbar/Notification/operational/styles';
import arrowL from '@/images/arrow small.svg';

export const AllNotifications = () => {
    return (
        <ContainerFlex FlexDir="column">
            {data.map((data, index) => (
                <ContainerFlex Padding="16px 16px 0px 16px" key={index}>
                    <ContainerFlex FlexDir="column" Gap="8px">
                        <ContainerFlex Justify="space-between">
                            <Text FontWeight="400" Color="#A1A5AA" FontSize="0.75rem">
                                {data.TIME}
                            </Text>
                            <Text FontWeight="400" Color="#A1A5AA" FontSize="0.75rem">
                                {data.TIME_DATE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="start">
                            <Text
                                FontWeight="500"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Align="start"
                            >
                                {data.TITLE}
                            </Text>
                        </ContainerFlex>

                        <ContainerFlex ColumnGap="8px">
                            <ContainerFlex
                                Width="2rem"
                                Height="2rem"
                                Radius="8px"
                                Border="1px solid #D4D6D8"
                            >
                                <IconPhone src={data.IMG} />
                            </ContainerFlex>

                            <TextS>{data.DESCRIPTION}</TextS>
                        </ContainerFlex>
                        <ContainerFlex ColumnGap="4px" Cursor="pointer" Justify="start">
                            <Text
                                Cursor="pointer"
                                FontSize="0.875rem"
                                FontWeight="500"
                                Color="#5A5AFF"
                            >
                                {NOTIFICATIOS.MORE}
                            </Text>
                            <ImageLogo Height="20px" width="20px" src={arrowL} />
                        </ContainerFlex>
                        <Line />
                    </ContainerFlex>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};
