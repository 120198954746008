import React from 'react';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {Box} from '@mui/material';
import {SearchInput} from '@/components/Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import {IFilterDatePickerProps} from '@components/CreditsAndPawns/interfaces';
import {DateTime} from 'luxon';

export const formatDate = (date: Date) => {
    if (!date) {
        return null;
    }
    const dateFormatted = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    return dateFormatted;
};

const FilterDatePicker = ({
    filterName,
    filters,
    handleFilter,
    value,
    disabled,
    minDate,
}: IFilterDatePickerProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
                mask="__/__/____"
                inputFormat="dd/MM/yyyy"
                value={value}
                disabled={disabled}
                minDate={minDate}
                onChange={(newDate) =>
                    handleFilter({
                        ...filters,
                        [filterName]: newDate ? new Date(newDate) : null,
                    })
                }
                renderInput={({inputRef, inputProps, InputProps}) => (
                    <Box>
                        <SearchInput
                            Width="100%"
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            BoxShadowH="0px 4px 4px rgba(53, 207, 68, 0.2)"
                            BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        >
                            <input ref={inputRef} {...inputProps} />
                            <IconContainer
                                Width="100%"
                                Justify="flex-end"
                                Fill="#ACACFF"
                                FontSize="1rem"
                            >
                                {InputProps?.endAdornment}
                            </IconContainer>
                        </SearchInput>
                    </Box>
                )}
            />
        </LocalizationProvider>
    );
};

export default FilterDatePicker;
