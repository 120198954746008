import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_CHARACTERISTIC_DETAILS_ACTION,
    LIST_CHARACTERISTIC_DETAILS_ERROR,
    LIST_CHARACTERISTIC_DETAILS_START,
    LIST_CHARACTERISTIC_DETAILS_SUCCESS,
    LIST_CHARACTERISTIC_RESET,
    LIST_DINAMICS_ADD,
    LIST_HEREDITARY_ADD,
} from '@components/Articles/Redux/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {
    DataCharacteristicDynamic,
    DataCharacteristicsInheriteds,
} from '@components/Articles/Redux/interfaces';

export const fetchDataCharacteristicsStart = () => {
    return {
        type: LIST_CHARACTERISTIC_DETAILS_START,
    };
};
export const fetchDataCharacteristicsSuccess = (result: AxiosResponse) => {
    return {
        type: LIST_CHARACTERISTIC_DETAILS_SUCCESS,
        payload: result,
    };
};
export const fetchDataCharacteristicsError = (error: AxiosError) => {
    return {
        type: LIST_CHARACTERISTIC_DETAILS_ERROR,
        error: error,
    };
};
export function GetDataCharacteristics(
    filters: {levelId: number; CatLevelId: number},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataCharacteristicsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Getcharacteristicsdinamics}${queryString}
            `,
                {headers}
            );
            dispatch(fetchDataCharacteristicsSuccess(response));
        } catch (error) {
            dispatch(fetchDataCharacteristicsError(error as AxiosError));
        }
    };
}

export const fetchActionTypeSelect = (value: number) => {
    return {
        type: LIST_CHARACTERISTIC_DETAILS_ACTION,
        value: value,
    };
};

export const AddDinamicItems = (items: DataCharacteristicDynamic[]) => {
    return {
        type: LIST_DINAMICS_ADD,
        date: items,
    };
};
export const AddHereditaryItems = (items: DataCharacteristicsInheriteds[]) => {
    return {
        type: LIST_HEREDITARY_ADD,
        item: items,
    };
};
export const fetchInfoListReset = () => {
    return {
        type: LIST_CHARACTERISTIC_RESET,
    };
};
