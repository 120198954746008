import React from 'react';
import {useSelector} from 'react-redux';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DETAIL_COMPONENT} from '@/components/TransactionsDetails/Operational/Constants';
import {IPawnData} from '@components/TransactionsDetails/Redux/interface';
import {RootState} from '@/config/store';
import {formatterDolar} from '@/hooks/currentFormatUtils';

export const ProductTotalDetails = () => {
    const PawnsDetails: IPawnData = useSelector((state: RootState) => {
        return state.GetPawnDetails;
    });
    return (
        <ContainerFlex Height="max-content">
            <ContainerCellGrid>
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontSize="1rem" Color="#54575C" FontWeight="500">
                        {DETAIL_COMPONENT.SUB_TOTAL}
                    </Text>
                    <Text FontSize="1rem" Color="#54575C" FontWeight="500">
                        {DETAIL_COMPONENT.IVA}
                    </Text>
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="700">
                        {DETAIL_COMPONENT.TOTAL}
                    </Text>
                </ContainerFlex>

                <ContainerFlex FlexDir="column" Align="end">
                    <Text FontSize="1rem" Color="#54575C" FontWeight="500">
                        {formatterDolar.format(PawnsDetails.catState.contractSubtotal)}
                    </Text>
                    <Text FontSize="1rem" Color="#54575C" FontWeight="500">
                        {formatterDolar.format(PawnsDetails.catState.contractVat)}
                    </Text>
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="700">
                        {formatterDolar.format(PawnsDetails.catState.contractTotal)}
                    </Text>
                </ContainerFlex>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
