import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import addIcon from '@images/addUser.svg';
import filter from '@images/filter.svg';
import {PERSONAL_CREDITS} from '@Shopify/ClientProfile/constants';
import {getClientPersonalCredit} from '@Shopify/ClientProfile/Redux/Action/GetPersonalCredit';
import PersonalCreditsList from '@Shopify/ClientProfile/PersonalCreditsList';

const PersonalCredits = () => {
    const dispatch = useDispatch();
    const {clientProfile, token} = useSelector((state: RootState) => ({
        clientProfile: state.cardClientProfile,
        token: state.getUsersValidation.userData?.token as string,
    }));
    useEffect(() => {
        dispatch(getClientPersonalCredit(token, {clientId: clientProfile.idClient}));
    }, []);

    return (
        <ContainerFlex FlexDir="column" Gap="1rem" Justify="flex-start" Align="center">
            <ContainerFlex Justify="space-between" Align="center" Self="stretch" Height="2rem">
                <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="700">
                    {PERSONAL_CREDITS.TITLE}
                </Text>
                <ButtonGenerals
                    Height="2rem"
                    Padding="0.25rem 1rem"
                    Justify="center"
                    type="button"
                    Gap="0.25rem"
                    Cursor="pointer"
                >
                    <Image src={addIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                    <Text FontSize="0.875rem" Color="#FFF" FontWeight="700" Cursor="inherit">
                        {PERSONAL_CREDITS.APPLY_FOR_LOAN}
                    </Text>
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex
                Justify="end"
                Gap="0.25rem"
                Align="center"
                Cursor="pointer"
                Height="1.5rem"
            >
                <Image src={filter} alt="filter" Width="1.5rem" Height="1.5rem" />
                <Text FontSize="0.875rem" Color="#5A5AFF" FontWeight="700" Cursor="inherit">
                    {PERSONAL_CREDITS.FILTER_BY}
                </Text>
            </ContainerFlex>
            <PersonalCreditsList />
        </ContainerFlex>
    );
};
export default PersonalCredits;
