import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState, AppDispatch} from '@config/store';
import {
    ContainerCellGrid,
    ContainerFlex,
    Figure,
    Image,
    Input,
    Text,
    TextEllipsis,
} from '@Shopify/Ecommerce/styles';
import {ModalOrganizations} from '@components/ManageUser/ActionsSidebar/ModalOrganizations';
import {IPropsEnterUserNameAndOrganizations} from '@components/ManageUser/interface';
import arrowDown from '@images/arrowDonw.svg';

export const EnterUserNameAndOrganization = ({
    register,
    isTouchedUserName,
    levelsBranch,
    setValue,
}: IPropsEnterUserNameAndOrganizations) => {
    const usernameAvailable = useSelector(
        (state: RootState) => state.GetUserName.usernameAvailable
    );
    const [openModalOrganization, setOpenModalOrganization] = useState(false);

    return (
        <>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Nombre de usuario
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Nombre de usuario"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('userName')}
                />
                {usernameAvailable && isTouchedUserName && (
                    <Text FontSize="0.625rem" FontWeight="normal" Color="#ef4f55" Height="">
                        Este nombre de usuario ya existe. Elige otro.
                    </Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="2px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="14px" required>
                    Organización
                </Text>
                <ContainerFlex
                    Height="40px"
                    Border="1px solid #E5E7E9"
                    BoxShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                    HBorderColor="#35CF44"
                    Radius="4px"
                    Padding="0 12px"
                    Cursor="pointer"
                    Justify="space-between"
                    onClick={() => setOpenModalOrganization(true)}
                >
                    <TextEllipsis
                        Cursor="pointer"
                        FontSize="0.875rem"
                        Color="#414042"
                        Width="90%"
                        FontWeight="400"
                    >
                        {levelsBranch?.map(
                            (level, index) =>
                                `${level.branchName}${
                                    index === levelsBranch.length - 1 ? '' : ' > '
                                }`
                        )}
                    </TextEllipsis>
                    <Figure>
                        <Image src={arrowDown} />
                    </Figure>
                </ContainerFlex>
            </ContainerCellGrid>
            {openModalOrganization && (
                <ModalOrganizations
                    setOpenModalOrganization={setOpenModalOrganization}
                    setValue={setValue}
                    levelsBranch={levelsBranch}
                />
            )}
        </>
    );
};
