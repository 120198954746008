import React from 'react';
import Facebook from '@images/Facebook_Logo.svg';
import Google from '@images/Google_Logo.svg';
import Apple from '@images/Apple_Logo.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

import {FigureContainer} from '@/components/Quoter/Steps/styles';
import {USER_CLIENTS_LOGIN} from '@/components/MarketAccessLogin/SubModules/Contants';
import {useNavigate} from 'react-router-dom';

export const ButtonLoginGenerals = () => {
    const navigate = useNavigate();

    return (
        <ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="24px">
                <ContainerFlex Justify="space-between" ColumnGap="16px">
                    <FigureContainer
                        Radius="10px"
                        backG="#1877F2"
                        Height="54px"
                        Cursor="pointer"
                        Width="100%"
                    >
                        <Image src={Facebook} />
                    </FigureContainer>
                    <FigureContainer
                        Radius="10px"
                        backG="#FFFFFF"
                        Height="54px"
                        Cursor="pointer"
                        BoxS="0px 0px 3px 0px rgba(0, 0, 0, 0.08), 0px 2px 3px 0px rgba(0, 0, 0, 0.17)"
                        Width="100%"
                    >
                        <Image src={Google} />
                    </FigureContainer>
                    <FigureContainer
                        Radius="10px"
                        backG="#000000"
                        Height="54px"
                        Cursor="pointer"
                        Width="100%"
                    >
                        <Image src={Apple} />
                    </FigureContainer>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="8px">
                    <Text FontSize="1rem" Color="#2A2C2F" FontFamily="Nunito">
                        {USER_CLIENTS_LOGIN.REGISTER}
                    </Text>
                    <Text
                        FontSize="1rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                        FontFamily="Nunito"
                        Cursor="pointer"
                        onClick={() => navigate('/Login/MarketClient')}
                    >
                        {USER_CLIENTS_LOGIN.CREATE_USER}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
