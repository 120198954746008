import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {FOOTER_ACTION_BTN_TEXT} from '@/components/QuickPayment/Constants';
import {useDispatch} from 'react-redux';
import {sendConfirmationVoucherReset} from '@/components/QuickPayment/Redux/Actions/SendConfirmationVoucher';
import {resetAddFastPayment} from '@/components/QuickPayment/Redux/Actions/AddFastPaymentActions';
import {selectActionState} from '@/components/QuickPayment/Redux/Actions/GetActionPayment';

const SuccessFooter = () => {
    const dispatch = useDispatch();

    const navigateAndClear = () => {
        dispatch(sendConfirmationVoucherReset());
        dispatch(resetAddFastPayment());
        dispatch(selectActionState(1));
    };

    return (
        <ButtonGenerals
            Radius="32px"
            Border="none"
            Padding="8px 16px"
            FontFamily="Nunito"
            onClick={navigateAndClear}
        >
            <Text FontFamily="Nunito" FontSize="1rem" FontWeight="700" Color="#FFFFFF">
                {FOOTER_ACTION_BTN_TEXT}
            </Text>
        </ButtonGenerals>
    );
};

export default SuccessFooter;
