import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {CLIENT_PROFILE, GaugeChartColorData, GaugeMaxScore} from '@Shopify/ClientProfile/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import warningYellow from '@images/warningYellowCircle.svg';
import {getStyle} from '@components/Shopify/ListClient/constants';
import {ProfileValuate} from '@Shopify/ListClient/ProfileValuate';
import ClientInfo from '@Shopify/ClientProfile/ClientInfo';
import GaugeChart from '@/components/General/Atoms/GaugeChart/GaugeChart';
import MoreInformation from '@Shopify/ClientProfile/MoreInformation';

export default function CardProfile() {
    const [showModal, setShowModal] = useState(false);
    const [viewMoreInfo, setViewMoreInfo] = useState(false);
    const {clientProfile} = useSelector((state: RootState) => state.cardClientProfile);
    const scoreStyle = getStyle(clientProfile.score);

    return (
        <FlexPanel
            Padding="1.5rem"
            Align="flex-start"
            Radius="1.5rem"
            Background="#FFF"
            Width="100%"
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Gap="1rem"
        >
            <ContainerFlex FlexDir="row" Align="flex-start">
                <ContainerFlex Align="center" Gap="1rem" Justify="flex-start">
                    <ClientInfo
                        clientProfile={clientProfile}
                        viewingInfo={viewMoreInfo}
                        toggleInfo={setViewMoreInfo}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="center" Gap="1rem" Flex="1 0 0">
                    <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="0.5rem">
                        <GaugeChart
                            value={Number(clientProfile.score)}
                            colorData={GaugeChartColorData}
                            maxScore={GaugeMaxScore}
                            width={120}
                        />

                        <ContainerFlex
                            Display="inline-flex"
                            Padding="0.5rem"
                            Justify="center"
                            Align="center"
                            Gap="0.5rem"
                            Radius="0.5rem"
                            Height="2.063rem"
                            Width="85%"
                            backG={scoreStyle.backgroundColor}
                        >
                            <Text FontSize="0.875rem" Color={scoreStyle.color} FontWeight="700">
                                {CLIENT_PROFILE.SCORE}
                            </Text>
                            <Image src={warningYellow} width="16px" Height="16px" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <Text
                        FontSize="1rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                        LetterSpacing="0.3px"
                        LHeight="1.5rem"
                        Cursor="pointer"
                        onClick={() => setShowModal(true)}
                    >
                        {CLIENT_PROFILE.TEST_PROFILE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {viewMoreInfo && <MoreInformation />}
            {showModal && (
                <ProfileValuate
                    idClient={clientProfile.clientId}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
        </FlexPanel>
    );
}
