import {
    CAT_COMPANY_BENEFIT,
    CAT_COMPANY_BENEFIT_SUCCESS,
    CAT_COMPANY_BENEFIT_ERROR,
} from '@components/SignUp/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: null,
};

const catCompanyBenefits = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case CAT_COMPANY_BENEFIT:
            return {...state, loading: true};
        case CAT_COMPANY_BENEFIT_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case CAT_COMPANY_BENEFIT_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default catCompanyBenefits;
