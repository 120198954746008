import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_HOME_CLIENT,
    GET_HOME_CLIENT_SUCCESS,
    GET_HOME_CLIENT_ERROR,
    GET_HOME_MOVEMENTS,
    GET_HOME_MOVEMENTS_SUCCESS,
    GET_HOME_MOVEMENTS_ERROR,
    GET_HOME_ORDERS,
    GET_HOME_ORDERS_SUCCESS,
    GET_HOME_ORDERS_ERROR,
    GET_HOME_FILTER,
    GET_HOME_FILTER_SUCCESS,
    GET_HOME_FILTER_ERROR,
    GET_HOME_STARS,
    GET_HOME_STARS_SUCCESS,
    GET_HOME_STARS_ERROR,
    GET_HOME_FAVS,
    GET_HOME_FAVS_SUCCESS,
    GET_HOME_FAVS_ERROR,
    GET_CARDS,
    GET_CARDS_SUCCESS,
    GET_CARDS_ERROR,
    POST_CARDS,
    POST_CARDS_SUCCESS,
    POST_CARDS_ERROR,
    PUT_CARDS,
    PUT_CARDS_SUCCESS,
    PUT_CARDS_ERROR,
    CLEAN_FILTERS,
    GET_HOME_ENDORSEMENT_START,
    GET_HOME_ENDORSEMENT_SUCCESS,
    GET_HOME_ENDORSEMENT_ERROR,
    RESET_CLIENT,
} from '@TypesEcommerceShop/HomeClientTypes';

const initialState = {
    homeclient: [],
    filterid: null,
    homestars: null,
    homefilter: [],
    homemov: [],
    homeorders: [],
    homefavs: [],
    homemovglobal: [],
    homeordersglobal: [],
    cards: [],
    error: false,
    loadinginfoclient: false,
    loadingdelete: false,
    loadingpost: false,
    infoclient: null,
    loading: false,
    homeEndorsement: [],
    extractcard: null,
};

const homeclient = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_HOME_CLIENT:
            return {
                ...state,
                loadinginfoclient: true,
                infoclient: null,
            };
        case GET_HOME_CLIENT_SUCCESS:
            return {
                ...state,
                infoclient: action.payload.data,
                loadinginfoclient: false,
            };
        case GET_HOME_CLIENT_ERROR:
            return {
                ...state,
                infoclient: null,
                error: true,
                loadinginfoclient: false,
            };
        case GET_HOME_FAVS:
            return {
                ...state,
                loadinginfoclient: true,
                homefavs: null,
            };
        case GET_HOME_FAVS_SUCCESS:
            return {
                ...state,
                homefavs: action.payload,
                loadinginfoclient: false,
            };
        case GET_HOME_FAVS_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case GET_HOME_MOVEMENTS:
            return {
                ...state,
                loadinginfoclient: true,
            };
        case GET_HOME_MOVEMENTS_SUCCESS:
            return {
                ...state,
                homemov: action.payload,
                homemovglobal: action.payload,
                loadinginfoclient: false,
            };
        case GET_HOME_MOVEMENTS_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };

        case GET_HOME_ORDERS:
            return {
                ...state,
                loadinginfoclient: true,
            };
        case GET_HOME_ORDERS_SUCCESS:
            return {
                ...state,
                homeorders: action.payload,
                homeordersglobal: action.payload,
                loadinginfoclient: false,
            };
        case GET_HOME_ORDERS_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case GET_HOME_STARS:
            return {
                ...state,
                loadinginfoclient: true,
            };
        case GET_HOME_STARS_SUCCESS:
            return {
                ...state,
                homestars: action.payload,
                loadinginfoclient: false,
            };
        case GET_HOME_STARS_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case GET_HOME_FILTER:
            return {
                ...state,
                loadinginfoclient: true,
            };
        case GET_HOME_FILTER_SUCCESS:
            return {
                ...state,
                homefilter: action.payload,
                loadinginfoclient: false,
            };
        case GET_HOME_FILTER_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case GET_CARDS:
            return {
                ...state,
                loadinginfoclient: true,
            };
        case GET_CARDS_SUCCESS:
            return {
                ...state,
                cards: action.payload,
                loadinginfoclient: false,
            };
        case GET_CARDS_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case POST_CARDS:
            return {
                ...state,
                loadingpost: true,
            };
        case POST_CARDS_SUCCESS:
            return {
                ...state,
                loadingpost: false,
                extractcard: action.payload,
            };
        case POST_CARDS_ERROR:
            return {
                ...state,
                error: true,
                loadingpost: false,
            };
        case PUT_CARDS:
            return {
                ...state,
                loadingdelete: true,
            };
        case PUT_CARDS_SUCCESS:
            return {
                ...state,
                loadingdelete: false,
            };
        case PUT_CARDS_ERROR:
            return {
                ...state,
                error: true,
                loadingdelete: false,
            };
        case CLEAN_FILTERS:
            return {
                ...state,
                filterid: null,
                loadinginfoclient: false,
            };

        case GET_HOME_ENDORSEMENT_START:
            return {
                ...state,
                loading: true,
            };
        case GET_HOME_ENDORSEMENT_SUCCESS:
            return {
                ...state,
                homeEndorsement: action.payload,
                loading: false,
            };
        case GET_HOME_ENDORSEMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_CLIENT:
            return initialState;
        default:
            return state;
    }
};
export default homeclient;
