import {
    PUT_OTHER_INFO,
    PUT_OTHER_INFO_ERROR,
    PUT_OTHER_INFO_FINISH,
    PUT_OTHER_INFO_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateOther: false,
};

const PutOtherInfo = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_OTHER_INFO:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_OTHER_INFO_SUCCESS:
            return {
                ...state,
                updateOther: action.payload.data,
            };
        case PUT_OTHER_INFO_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_OTHER_INFO_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutOtherInfo;
