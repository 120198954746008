import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {NewPaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/NewPaymentMethod';
import {PaymentCards} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/PaymentCards';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import plusIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/plusIcon.svg';
import {getClientCardsAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/PaymentMethodsActions';

export const PaymentMethod = () => {
    const dispatch: AppDispatch = useDispatch();
    const [isAddingMethod, setIsAddingMethod] = useState<boolean>(false);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {cards, mustBeUpdated} = useSelector((state: RootState) => state.paymentMethods);

    useEffect(() => {
        if (mustBeUpdated) {
            dispatch(getClientCardsAction(token));
        }
    }, [mustBeUpdated, cards]);
    return (
        <ContainerFlex Padding="1.5rem" Gap="1.5rem" FlexDir="column">
            <ContainerFlex Justify="space-between">
                <ContainerFlex Width="734px" Gap="0.5rem" FlexDir="column" Align="start">
                    <Text
                        Width="217px"
                        Height="28px"
                        Color="#00000"
                        FontSize="1.25rem"
                        FontWeight="700"
                    >
                        {PAYMENT_METHOD.TITLE}
                    </Text>
                    <Text Color="#000000" wSpace="wrap">
                        {PAYMENT_METHOD.DESCRIPTION}
                    </Text>
                </ContainerFlex>
                {!isAddingMethod && (cards === null || cards?.length === 0) && (
                    <ContainerFlex
                        Gap="0.5rem"
                        Width="max-content"
                        Cursor="pointer"
                        onClick={() => setIsAddingMethod(!isAddingMethod)}
                    >
                        <Image src={plusIcon} Width="24px" Height="24px" />
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            LetterSpacing="0.3px"
                            Cursor="pointer"
                        >
                            {PAYMENT_METHOD.ADD_METHOD_BTN}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
            {isAddingMethod && (
                <NewPaymentMethod
                    isAddingMethod={isAddingMethod}
                    setIsAddingMethod={setIsAddingMethod}
                />
            )}
            {cards && cards?.length >= 1 && <PaymentCards />}
        </ContainerFlex>
    );
};
