import {FilterParams} from '@/components/MyAccount/interfaces';
import {
    TOGGLE_CREDIT_TYPE,
    GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR,
    GET_STATIC_INFORMATION_CREDIT_PROFILE_START,
    GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS,
    GET_CARD_CREDIT_PROFILE_START,
    GET_CARD_CREDIT_PROFILE_SUCCESS,
    GET_CARD_CREDIT_PROFILE_ERROR,
    GET_FILTER_VALUES_START,
    GET_FILTER_VALUES_SUCCESS,
    GET_FILTER_VALUES_ERROR,
    SET_FILTER_PARAMS,
    RESET_FILTER_PARAMS,
    GET_CREDIT_PROFILE_FILTERS_START,
    GET_CREDIT_PROFILE_FILTERS_SUCCESS,
    GET_CREDIT_PROFILE_FILTERS_ERROR,
    SET_VISUAL_DATE,
    SET_FILTERS_PARAMS_VISUAL,
} from '@/components/MyAccount/MyCredit/Redux/Types/Types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
export const toggleCreditType = (creditType: string) => {
    return {
        type: TOGGLE_CREDIT_TYPE,
        creditType,
    };
};

export const getStaticInformationCreditProfileStart = () => {
    return {
        type: GET_STATIC_INFORMATION_CREDIT_PROFILE_START,
    };
};

export const getStaticInformationCreditProfileSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS,
        payload,
    };
};

export const getStaticInformationCreditProfileError = (error: AxiosError) => {
    return {
        type: GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR,
        error,
    };
};

export const getCardCreditProfileStart = () => {
    return {
        type: GET_CARD_CREDIT_PROFILE_START,
    };
};

export const getCardCreditProfileSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CARD_CREDIT_PROFILE_SUCCESS,
        payload,
    };
};

export const getCardCreditProfileError = (error: AxiosError) => {
    return {
        type: GET_CARD_CREDIT_PROFILE_ERROR,
        error,
    };
};

export const getFilterValuesStart = () => {
    return {
        type: GET_FILTER_VALUES_START,
    };
};

export const getFilterValuesSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_FILTER_VALUES_SUCCESS,
        payload,
    };
};

export const getFilterValuesError = (error: AxiosError) => {
    return {
        type: GET_FILTER_VALUES_ERROR,
        error,
    };
};

export const setFilterParams = (filterParams: FilterParams) => {
    return {
        type: SET_FILTER_PARAMS,
        filterParams,
    };
};

export const setFiltersParamsVisual = (filterParams: FilterParams) => {
    return {
        type: SET_FILTERS_PARAMS_VISUAL,
        filterParams,
    };
};

export const resetFilterParams = () => {
    return {
        type: RESET_FILTER_PARAMS,
    };
};

export const getCreditProfileFiltersStart = () => {
    return {
        type: GET_CREDIT_PROFILE_FILTERS_START,
    };
};

export const getCreditProfileFiltersSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CREDIT_PROFILE_FILTERS_SUCCESS,
        payload,
    };
};

export const getCreditProfileFiltersError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_PROFILE_FILTERS_ERROR,
        error,
    };
};

export const setVisualDate = (visualDate: Date | null) => {
    return {
        type: SET_VISUAL_DATE,
        visualDate,
    };
};

export const getStaticInformationCreditProfile = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getStaticInformationCreditProfileStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStatisticalnformationCreditProfile}`,
                {headers}
            );
            dispatch(getStaticInformationCreditProfileSuccess(response));
        } catch (error) {
            dispatch(getStaticInformationCreditProfileError(error as AxiosError));
        }
    };
};

export const getCardCreditsClientProfile = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCardCreditProfileStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCardCreditsClientProfile}`,
                {headers}
            );
            dispatch(getCardCreditProfileSuccess(response));
        } catch (error) {
            dispatch(getCardCreditProfileError(error as AxiosError));
        }
    };
};

export const getFilterValues = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getFilterValuesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDataFilterCustomerProfile}`,
                {headers}
            );
            dispatch(getFilterValuesSuccess(response));
        } catch (error) {
            dispatch(getFilterValuesError(error as AxiosError));
        }
    };
};

export const getCreditProfileFilters = (token: string, filterParams: FilterParams) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const noEmptyParams = Object.keys(filterParams).reduce((acc, key) => {
        if (filterParams[key as keyof FilterParams]) {
            return {...acc, [key]: filterParams[key as keyof FilterParams]};
        }
        return acc;
    }, {});
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditProfileFiltersStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetResultFiltroCustomerProfile}`,
                {headers, params: noEmptyParams}
            );
            dispatch(getCreditProfileFiltersSuccess(response));
            dispatch(setFiltersParamsVisual(filterParams));
        } catch (error) {
            dispatch(getCreditProfileFiltersError(error as AxiosError));
        }
    };
};
