import axios, {AxiosResponse} from 'axios';
import {
    GET_LOGIN_PROBLEMS,
    GET_LOGIN_PROBLEMS_ERROR,
    GET_LOGIN_PROBLEMS_SUCCESS,
} from '@components/LoginClient/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetLoginProblemsStart = () => {
    return {
        type: GET_LOGIN_PROBLEMS,
    };
};
export const fetchGetLoginProblemsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_LOGIN_PROBLEMS_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetLoginProblemsError = () => {
    return {
        type: GET_LOGIN_PROBLEMS_ERROR,
    };
};

export function GetLoginProblems() {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetLoginProblemsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLoginProblems}`,
                {headers}
            );
            dispatch(fetchGetLoginProblemsSuccess(response));
        } catch (error) {
            dispatch(fetchGetLoginProblemsError());
        }
    };
}
