import React from 'react';

import {InfoBlockProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';

const InfoBlock: React.FC<InfoBlockProps> = ({
    label,
    value,
    width = '24rem',
    textWidth = '100%',
    optional,
    transformText,
}) => (
    <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem" Width={width}>
        <Text FontSize="1rem" Color="#1D1E20" FontWeight="700" LetterSpacing="0.3px">
            {label}
            {optional && (
                <Text
                    FontSize="1rem"
                    Color="#54575C"
                    FontWeight="400"
                    PaddingL="0.25rem"
                    LetterSpacing="0.3px"
                >
                    {optional}
                </Text>
            )}
        </Text>
        <TextEllipsis
            Height="1.5rem"
            Width={textWidth}
            Padding="0.5rem 0"
            Color="#2A2C2F"
            FontSize="1rem"
            FontWeight="400"
            LetterSpacing="0.3px"
            Transform={transformText}
            FontFamily="Nunito"
        >
            {value ? value : '-'}
        </TextEllipsis>
    </ContainerFlex>
);

export default InfoBlock;
