export const GET_DATA_ROL_NAME = 'GET_DATA_ROL_NAME';
export const GET_DATA_ROL_NAME_SUCCESS = 'GET_DATA_ROL_NAME_SUCCESS';
export const GET_DATA_ROL_NAME_ERROR = 'GET_DATA_ROL_NAME_ERROR';

export const GET_SEARCHED_USERS = 'GET_SEARCHED_USERS';
export const GET_SEARCHED_USERS_SUCCESS = 'GET_SEARCHED_USERS_SUCCESS';
export const GET_SEARCHED_USERS_ERROR = 'GET_SEARCHED_USERS_ERROR';
export const RESET_SEARCHED_USERS = 'RESET_SEARCHED_USERS';

export const GET_SEARCHED_ORGANIZATIONS = 'GET_SEARCHED_ORGANIZATIONS';
export const GET_SEARCHED_ORGANIZATIONS_SUCCESS = 'GET_SEARCHED_ORGANIZATIONS_SUCCESS';
export const GET_SEARCHED_ORGANIZATIONS_ERROR = 'GET_SEARCHED_ORGANIZATIONS_ERROR';
export const RESET_SEARCHED_ORGANIZATIONS = 'RESET_SEARCHED_ORGANIZATIONS ';

export const GET_DATA_MODULES = 'GET_DATA_MODULES';
export const GET_DATA_MODULES_SUCCESS = 'GET_DATA_MODULES_SUCCESS';
export const GET_DATA_MODULES_ERROR = 'GET_DATA_MODULES_ERROR';
export const UPDATE_DATA_MODULES = 'UPDATE_DATA_MODULES';
export const UPDATE_INFO_MODULES = 'UPDATE_INFO_MODULES';

export const POST_ADD_ROL = 'POST_ADD_ROL';
export const POST_ADD_ROL_SUCCESS = 'POST_ADD_ROL_SUCCESS';
export const POST_ADD_ROL_ERROR = 'POST_ADD_ROL_ERROR';
export const POST_ADD_ROL_FINISH = 'POST_ADD_ROL_FINISH';
export const CHANGE_SUCCESS_ADD_ROL = 'CHANGE_SUCCESS_ADD_ROL';

export const GET_ALL_ROLE_FORM = 'GET_ALL_ROLE_FORM';
export const GET_ALL_ROLE_FORM_SUCCESS = 'GET_ALL_ROLE_FORM_SUCCESS';
export const GET_ALL_ROLE_FORM_ERROR = 'GET_ALL_ROLE_FORM_ERROR';
