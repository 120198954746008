import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {UPDATE_IMG_COMPANY, DOWNLOAD_INFO_COMPANY} from '@MyCompany/Redux/Types/types';

export const updateIMGCompanyStart = () => ({
    type: UPDATE_IMG_COMPANY.START_COMPANY_IMG,
});

export const updateIMGCompanySuccess = (result: AxiosResponse) => ({
    type: UPDATE_IMG_COMPANY.SUCCESS_COMPANY_IMG,
    img: result,
});

export const updateIMGCompanyError = (err: AxiosError) => ({
    type: UPDATE_IMG_COMPANY.ERROR_COMPANY_IMG,
    error: err,
});

export const updateIMGCompany = (
    token: string,
    imgData: {
        companyId: number;
        fileExtension: string;
        fileContent: string;
    }
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(updateIMGCompanyStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateImageCompanyId}`,
                imgData,
                {headers}
            );
            dispatch(updateIMGCompanySuccess(response));
        } catch (error) {
            dispatch(updateIMGCompanyError(error as AxiosError));
        }
    };
};

export const downloadInfoCompanyStart = () => ({
    type: DOWNLOAD_INFO_COMPANY.START_COMPANY_INFO,
});

export const downloadInfoCompanySuccess = (result: AxiosResponse) => ({
    type: DOWNLOAD_INFO_COMPANY.SUCCESS_COMPANY_INFO,
    downloadInfo: result.data,
});

export const downloadInfoCompanyError = (err: AxiosError) => ({
    type: DOWNLOAD_INFO_COMPANY.ERROR_COMPANY_INFO,
    error: err,
});

export const downloadInfoCompany = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(downloadInfoCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DownloadCompanyInformation}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(downloadInfoCompanySuccess(response.data));
        } catch (error) {
            dispatch(downloadInfoCompanyError(error as AxiosError));
        }
    };
};
