import {
    GET_EXPENSES,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSES_ERROR,
} from '@components/CashFlow/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    expenses: null,
    totalItems: 0,
    totalPages: 0,
    loading: false,
    error: false,
};

const getExpenses = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_EXPENSES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_EXPENSES_SUCCESS:
            return {
                ...state,
                expenses: action.payload.data.data,
                totalItems: action.payload.data.totalItems,
                totalPages: action.payload.data.totalPages,
                loading: false,
            };
        case GET_EXPENSES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getExpenses;
