import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DETAIL_COMPONENT} from '@/components/TransactionsDetails/Operational/Constants';
import {IconPhone} from '@/components/TransactionsDetails/Operational/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IPawnData} from '@components/TransactionsDetails/Redux/interface';
import {formatterDolar} from '@/hooks/currentFormatUtils';

export const ProductListContainer = () => {
    const PawnsDetails: IPawnData = useSelector((state: RootState) => {
        return state.GetPawnDetails;
    });
    return (
        <ContainerFlex Padding="0px 8px" Gap="18px" FlexDir="column">
            {PawnsDetails.catState.items &&
                PawnsDetails.catState.items.map((product, index) => (
                    <ContainerCellGrid key={index}>
                        <IconPhone src={product.imageUrl} />
                        <ContainerCellGrid Gap="58px">
                            <ContainerFlex
                                Align="start"
                                MarginL="8px"
                                AlignContent=" right"
                                FlexDir="column"
                            >
                                <Text Color="#2A2C2F" FontWeight="500" FontSize="1rem">
                                    {product.model}, {product.make}
                                </Text>
                                <ContainerCellGrid Align="center">
                                    <ContainerFlex Justify="start" Gap="24px">
                                        {product.specs.map((data, index) => {
                                            return (
                                                <Text
                                                    color="#2A2C2F"
                                                    MarginLeft="4px"
                                                    MarginRight="24px"
                                                    FontSize="0.75rem"
                                                    key={index}
                                                >
                                                    {data.specName}
                                                </Text>
                                            );
                                        })}
                                    </ContainerFlex>
                                </ContainerCellGrid>
                            </ContainerFlex>
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text FontWeight="700" FontSize="0.75rem" Color="#54575C">
                                    {DETAIL_COMPONENT.HARVEST}
                                </Text>
                                <Text TextAlign="start">-</Text>
                            </ContainerFlex>
                            <ContainerFlex Align="end" FlexDir="column">
                                <Text FontWeight="700" FontSize="1rem" Color="#54575C">
                                    {formatterDolar.format(product.value)}
                                </Text>
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerCellGrid>
                ))}
        </ContainerFlex>
    );
};
