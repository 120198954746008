import {
    GET_CATALOG_REQUEST_TABLE,
    GET_CATALOG_REQUEST_TABLE_SUCCESS,
    GET_CATALOG_REQUEST_TABLE_ERROR,
} from '@components/Articles/CatalogRequestTable/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: null,
    pageSize: null,
    totalPages: null,
    totalItems: null,
    pageNumber: null,
};

const getRequestsTable = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_CATALOG_REQUEST_TABLE:
            return {...state, loading: true};
        case GET_CATALOG_REQUEST_TABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                pageSize: action.payload.data.pageSize,
                totalPages: action.payload.data.pageNumber,
                totalItems: action.payload.data.totalItems,
                pageNumber: action.payload.data.pageNumber,
            };
        case GET_CATALOG_REQUEST_TABLE_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default getRequestsTable;
