import {
    GET_BRANCH_SCHEDULE_DISCHARGE,
    GET_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    GET_BRANCH_SCHEDULE_DISCHARGE_RESET,
    GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
    GET_BRANCH_SCHEDULE_DISCHARGE_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    scheduleDischarge: [],
};

const GetScheduleDischarge = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_BRANCH_SCHEDULE_DISCHARGE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS:
            return {
                ...state,
                scheduleDischarge: action.payload.data,
            };
        case GET_BRANCH_SCHEDULE_DISCHARGE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRANCH_SCHEDULE_DISCHARGE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_BRANCH_SCHEDULE_DISCHARGE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetScheduleDischarge;
