import styled from '@emotion/styled';
import {IPropsImageLogo} from '@LoginClient/interfacesStyles';

export const ImageLogo = styled.img<IPropsImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: ${(props) => props.Margin};
`;

export const ButtonImg = styled.button`
    position: absolute;
    left: 5%;
    top: 20px;
    padding: 5px 10px;
    background-color: white;
    border: none;
    cursor: pointer;
`;
export const Input = styled.input<{hasError: boolean}>`
    width: 63px;
    height: 59px;
    font-size: 24px;
    text-align: center;
    border: 1px solid ${(props) => (props.hasError ? '#FF6357' : '#5a5aff')};
    border-radius: 5px;
    :focus {
        outline: none;
        border: 2px solid '#5a5aff')};
    }

`;

export const SpanText = styled.span`
    fontfamily: Nunito;
    color: #ff6357;
    font-size: 0.75rem;
`;
