import {
    GET_CLIENT,
    GET_CLIENT_ERROR,
    GET_CLIENT_SUCCESS,
} from '@components/Shopify/Products/redux/types/types';
import {AxiosResponse} from 'axios';
const initialState = {
    error: false,
    loading: false,
    data: [],
};

export const getClientInfo = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case GET_CLIENT:
            return {
                ...state,
                loading: true,
            };
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data: actions.payload,
            };
        case GET_CLIENT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                data: [],
            };
        default:
            return state;
    }
};
