import styled from '@emotion/styled';
import {IPropsDivConsult} from '@ConsultAssignment/interfacesStyles';
export const ContainerConsult = styled.div`
    width: 90vw;
    margin: auto;
`;
export const Filter = styled.div`
    margin: 30px 0 5px 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 2rem;
    i {
        font-size: 20px;
        margin-right: 0.5rem;
    }
`;
export const TableConsult = styled.div`
    width: 90vw;
    margin: auto;
    margin-bottom: 1rem;
    color: var(--gray-dark);
`;
export const TableTrConsult = styled.tr`
    cursor: pointer;
    height: 2.5rem;
    padding: 0.5rem 0;
    &:hover {
        background: var(--gray-4);
    }
    td {
        border-bottom: 1px solid var(--gray-4);
    }
    i,
    span:last-child {
        color: #7ac143;
        font-size: 12px;
    }
`;
export const CardConsult = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none !important;
    box-shadow: 0px 1px 3px #00000033;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
`;
export const ContainerFilter = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    form {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }
`;
export const DivConsult = styled.div<IPropsDivConsult>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.end && 'flex-end'};
    height: ${(props) => props.ht && '40px'};
    padding-bottom: 1.5rem;
    width: ${(props) => (props.width ? '30%' : '20%')};
    padding-left: ${(props) => (props.pl ? '1.5rem' : '1rem')};
    padding-left: ${(props) => props.PL && '3rem'} !important;
    padding-right: ${(props) => (props.pr ? '1rem' : '1.5rem')};
    margin-top: ${(props) => (props.mt ? '1rem' : '3rem')};
    button:last-child {
        border: 1px solid var(--black);
        text-transform: uppercase;
        border-radius: 5px;
        width: 160px;
        height: 40px;
        align-items: center;
        background-color: white !important;
        box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    }
    button:last-child:hover {
        border-color: var(--green);
    }
`;
export const ButtonConsult = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 40px;
    width: 30%;
    margin-top: 3rem;
    padding-right: 1.5rem;
    button {
        border: 1px solid var(--black);
        text-transform: uppercase;
        border-radius: 5px;
        width: 160px;
        height: 40px;
        align-items: center;
        background-color: white !important;
        box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    }
    button:hover {
        border-color: var(--green);
    }
`;
export const Inputs = styled.input`
    background: fixed;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray-3);
    padding: 2px 12px;
`;
