import {
    GET_AUDIT_GOLD,
    GET_AUDIT_GOLD_SUCCESS,
    GET_AUDIT_GOLD_ERROR,
    GET_RECOLECTION_GOLD,
    GET_RECOLECTION_GOLD_SUCCESS,
    GET_RECOLECTION_GOLD_ERROR,
    GET_HEADER_AUDIT_GOLD,
    GET_HEADER_AUDIT_GOLD_SUCCESS,
    GET_HEADER_AUDIT_GOLD_ERROR,
    GET_HEADER_RECOLECTION_GOLD,
    GET_HEADER_RECOLECTION_GOLD_SUCCESS,
    GET_HEADER_RECOLECTION_GOLD_ERROR,
    GET_FILE_NAME,
    GET_FILE_NAME_SUCCESS,
    GET_FILE_NAME_ERROR,
} from '@TypesRecolectionGold/RecolectionGoldTypes';
import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';

import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';

export const fetchGetAssignmentAuditGold = () => {
    return {
        type: GET_AUDIT_GOLD,
    };
};
export const fetchGetAssignmentAuditGoldSuccess = (result: {
    data: AxiosResponse<string>;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_AUDIT_GOLD_SUCCESS,
        value: result.data,
        count: result.count,
        pageIndex: result.pageIndex,
    };
};

export const fetchGetAssignmentAuditGoldError = () => {
    return {
        type: GET_AUDIT_GOLD_ERROR,
    };
};
export function getAssignmentAuditGold(
    filter = '',
    pageSize = 10,
    pageIndex = 0,
    id: string,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filter === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                filter,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetAssignmentAuditGold());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.AssignmentAuditGold.replace('{id}', id)}${queryString}`,
                {headers}
            );
            dispatch(fetchGetAssignmentAuditGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAssignmentAuditGoldError());
        }
    };
}

export const fetchGetAssignmentRecolectionGold = () => {
    return {
        type: GET_RECOLECTION_GOLD,
    };
};
export const fetchGetAssignmentRecolectionGoldSuccess = (result: {
    data: AxiosResponse<string>;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_RECOLECTION_GOLD_SUCCESS,
        value: result.data,
        count: result.count,
        page: result.pageIndex,
    };
};

export const fetchGetAssignmentRecolectionGoldError = () => {
    return {
        type: GET_RECOLECTION_GOLD_ERROR,
    };
};
export function getAssignmentRecolectionGold(
    filter = '',
    pageSize = 10,
    pageIndex = 0,
    id: string,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filter === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                filter,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetAssignmentRecolectionGold());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.AssignmentRecolectionGold.replace('{id}', id)}${queryString}`,
                {headers}
            );
            dispatch(fetchGetAssignmentRecolectionGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAssignmentRecolectionGoldError());
        }
    };
}

export const fetchGetHeaderAuditGold = () => {
    return {
        type: GET_HEADER_AUDIT_GOLD,
    };
};
export const fetchGetHeaderAuditGoldSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_HEADER_AUDIT_GOLD_SUCCESS,
        value: result,
    };
};

export const fetchGetHeaderAuditGoldError = () => {
    return {
        type: GET_HEADER_AUDIT_GOLD_ERROR,
    };
};
export function getHeaderAuditGold(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHeaderAuditGold());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.HeaderAuditGold.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetHeaderAuditGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetHeaderAuditGoldError());
        }
    };
}

export const fetchGetHeaderRecolectionGold = () => {
    return {
        type: GET_HEADER_RECOLECTION_GOLD,
    };
};
export const fetchGetHeaderRecolectionGoldSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_HEADER_RECOLECTION_GOLD_SUCCESS,
        value: result,
    };
};

export const fetchGetHeaderRecolectionGoldError = () => {
    return {
        type: GET_HEADER_RECOLECTION_GOLD_ERROR,
    };
};
export function getAssignmentValidation(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHeaderRecolectionGold());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.HeaderRecolectionGold.replace('{id}', id)}`,
                {headers}
            );
            dispatch(fetchGetHeaderRecolectionGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetHeaderRecolectionGoldError());
        }
    };
}

export const fetchGetFileName = () => {
    return {
        type: GET_FILE_NAME,
    };
};
export const fetchGetFileNameSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_FILE_NAME_SUCCESS,
        value: result,
    };
};

export const fetchGetFileNameError = () => {
    return {
        type: GET_FILE_NAME_ERROR,
    };
};
export function getFileName(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetFileName());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.DocumentDownload.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetFileNameSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetFileNameError());
        }
    };
}
