import React from 'react';
import {DateTime} from 'luxon';
import ReactDatePicker from 'react-datepicker';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {RootState, AppDispatch} from '@config/store';
import {getPurity} from '@ActionsPurity/PurityActions';

export const FilterPurity = () => {
    const {handleSubmit, register, control} = useForm({
        defaultValues: {
            to: null,
            from: null,
            id: null,
        },
    });
    const dispatch: AppDispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const purity = useSelector((state: RootState) => {
        return state.purity;
    });
    function onSubmit(values: {to: string | null; from: string | null; id: number | null}) {
        if (values.to !== null && values.from !== null) {
            const filters = {
                to:
                    values.to !== null
                        ? DateTime.fromJSDate(new Date(values.to)).toFormat('yyyy-MM-dd')
                        : null,
                from:
                    values.from !== null
                        ? DateTime.fromFormat(values.from, 'dd-MM-yyyy').toFormat('dd-MM-yyyy')
                        : null,
                id: values.id !== null && values.id !== undefined ? values.id : 0,
            };
            dispatch(getPurity(filters, 10, purity.purityPageIndex, token));
        } else {
            const filters = {
                id: values.id !== null ? values.id : 0,
            };
            dispatch(getPurity(filters, 10, purity.purityPageIndex, token));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <form className="flex container-form " onSubmit={handleSubmit(onSubmit)}>
                    <div className="column flex w-20 pl-4 pr-3">
                        <label>Desde</label>
                        <Controller
                            control={control}
                            name="from"
                            render={({field: {onChange, onBlur, value}}) => (
                                <ReactDatePicker
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    dateFormat="dd/MM/yyyy"
                                    selected={value}
                                    showDisabledMonthNavigation
                                />
                            )}
                        />
                    </div>
                    <div className="column flex w-20 pl-3 pr-4">
                        <label>Hasta</label>
                        <Controller
                            control={control}
                            name="to"
                            render={({field: {onChange, onBlur, value}}) => (
                                <ReactDatePicker
                                    onChange={onChange}
                                    dateFormat="dd/MM/yyyy"
                                    selected={value}
                                    onBlur={onBlur}
                                    showDisabledMonthNavigation
                                />
                            )}
                        />
                    </div>

                    <div className="column flex w-20 pl-4 pr-3">
                        <label>No. Fundición</label>
                        <input className="input-react-hook-form" {...register('id', {})} />
                    </div>

                    <div className="flex w-30 mt-5 flex-end-button pr-4">
                        <button className="btn-submit" type="submit">
                            Buscar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
