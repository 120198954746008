export const GET_CUSTOMER_PROFITABILITY_SUCCESS = 'GET_CUSTOMER_PROFITABILITY_SUCCESS';
export const GET_CUSTOMER_PROFITABILITY_ERROR = 'GET_CUSTOMER_PROFITABILITY_ERROR';

export const GET_SALE_DAY_SUCCESS = 'GET_SALE_DAY_SUCCESS';
export const GET_SALE_DAY_ERROR = 'GET_SALE_DAY_ERROR';

export const GET_PROFITABILITY_COUNTERSIGN_START = 'GET_PROFITABILITY_COUNTERSIGN_START';
export const GET_PROFITABILITY_COUNTERSIGN_SUCCESS = 'GET_PROFITABILITY_COUNTERSIGN_SUCCESS';
export const GET_PROFITABILITY_COUNTERSIGN_ERROR = 'GET_PROFITABILITY_COUNTERSIGN_ERROR';

export const GET_PROFITABILITY_DISCHARGE_START = 'GET_PROFITABILITY_DISCHARGE_START';
export const GET_PROFITABILITY_DISCHARGE_SUCCESS = 'GET_PROFITABILITY_DISCHARGE_SUCCESS';
export const GET_PROFITABILITY_DISCHARGE_ERROR = 'GET_PROFITABILITY_DISCHARGE_ERROR';

export const GET_PROFITABILITY_AUCTION_START = 'GET_PROFITABILITY_AUCTION_START';
export const GET_PROFITABILITY_AUCTION_SUCCESS = 'GET_PROFITABILITY_AUCTION_SUCCESS';
export const GET_PROFITABILITY_AUCTION_ERROR = 'GET_PROFITABILITY_AUCTION_ERROR';

export const GET_PROFITABILITY_DIFERENCE_START = 'GET_PROFITABILITY_DIFERENCE_START';
export const GET_PROFITABILITY_DIFERENCE_SUCCESS = 'GET_PROFITABILITY_DIFERENCE_SUCCESS';
export const GET_PROFITABILITY_DIFERENCE_ERROR = 'GET_PROFITABILITY_DIFERENCE_ERROR';
