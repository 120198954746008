import React from 'react';
import {RootState, AppDispatch} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {ClickAwayListener} from '@mui/material';

import {DropDownMenuContainer} from '@General/MenuAmazon/ReutilizableComponent/styles';
import {IconContainerMenu, MenuItem} from '@General/MenuAmazon/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import directory from '@SubContainerOrgCreation/Icons/directory.svg';
import edit from '@SubContainerOrgCreation/Icons/edit.svg';
import drop from '@SubContainerOrgCreation/Icons/drop.svg';
import {
    fetchNewPassOrganization,
    fetchSelectNodeId,
    fetchSelectStateModalOrg,
    IsEditable,
} from '@ActionsOrgCreation/GetAllLevels';
import {saveLevelNodes} from '@ActionsOrgCreation/LevelTree';
import {checkedState} from '@ActionsOrgCreation/CheckedState';
import {menuContextual} from '@SubContainerOrgCreation/interface';
import {addLevelPath} from '@TreeTableUtils/LevelsAddFunctions';

export const ContextualMenu = ({
    contextMenuVisibility,
    levelId,
    setContextMenuVisibility,
    node,
    level,
    paginate,
}: menuContextual) => {
    const handleClickAway = () => {
        setContextMenuVisibility(false);
    };
    const Levels = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const dispatch: AppDispatch = useDispatch();
    return (
        <>
            {contextMenuVisibility && node.idLevel === levelId && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <DropDownMenuContainer
                        Width="14rem"
                        Height={level === 5 || level === 0 ? '80px' : '120px'}
                        Position="absolute"
                        Top={paginate <= 4 ? '0' : ''}
                        Bottom="0"
                        BoxShadow=""
                        Right="25px"
                        ZIndex="1"
                    >
                        {level === 5 ? null : (
                            <MenuItem
                                onClick={() => {
                                    dispatch(checkedState(node));
                                    dispatch(saveLevelNodes(addLevelPath(Levels.node)));
                                    dispatch(fetchNewPassOrganization(1));
                                    dispatch(fetchSelectStateModalOrg(true));
                                    dispatch(IsEditable(false));
                                    handleClickAway();
                                }}
                            >
                                <IconContainerMenu className="material-icons" src={directory} />

                                <Text Cursor="pointer" FontSize=".875rem" Padding="0 16px 0 10px">
                                    Crear nivel organizacional
                                </Text>
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                dispatch(fetchSelectNodeId(node));
                                dispatch(checkedState(node));
                                dispatch(saveLevelNodes(addLevelPath(Levels.node)));
                                dispatch(fetchNewPassOrganization(1));
                                dispatch(fetchSelectStateModalOrg(true));
                                dispatch(IsEditable(true));
                                handleClickAway();
                            }}
                        >
                            <IconContainerMenu className="material-icons" src={edit} />

                            <Text Cursor="pointer" FontSize=".875rem" Padding="0 16px 0 16px">
                                Editar
                            </Text>
                        </MenuItem>
                        {level === 0 ? null : (
                            <MenuItem
                                onClick={() => {
                                    dispatch(fetchNewPassOrganization(3));
                                    dispatch(fetchSelectStateModalOrg(true));
                                    handleClickAway();
                                    dispatch(checkedState(node));
                                }}
                            >
                                <IconContainerMenu className="material-icons" src={drop} />
                                <Text FontSize=".875rem" Padding="0 16px 0 0" Cursor="pointer">
                                    Eliminar
                                </Text>
                            </MenuItem>
                        )}
                    </DropDownMenuContainer>
                </ClickAwayListener>
            )}
        </>
    );
};
