import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {CreateLevelOrganization} from '@SubContainerOrgCreation/CreateLevelOrganization';
import {DeleteLevelOrganization} from '@SubContainerOrgCreation/DeleteLevelOrganization';
import {SelectFatherOrganization} from '@SubContainerOrgCreation/SelectFatherOrganization';
import {modalOrganizations} from '@SubContainerOrgCreation/interface';
import {fetchSelectStateModalOrg} from '@ActionsOrgCreation/GetAllLevels';

export const OrganzationLevelModals = ({
    modalState,
    changeModalState,
    setCurrentPage,
}: modalOrganizations) => {
    const Levels = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const dispatch: AppDispatch = useDispatch();
    function PassOrganizator(stateMent: {stateMent: number}) {
        switch (stateMent.stateMent) {
            case 1:
                return (
                    <CreateLevelOrganization
                        changeModalState={changeModalState}
                        setCurrentPage={setCurrentPage}
                    />
                );
            case 2:
                return <SelectFatherOrganization />;
            case 3:
                return (
                    <DeleteLevelOrganization
                        changeModalState={changeModalState}
                        setCurrentPage={setCurrentPage}
                    />
                );
            default:
                return (
                    <CreateLevelOrganization
                        changeModalState={changeModalState}
                        setCurrentPage={setCurrentPage}
                    />
                );
        }
    }
    return (
        <>
            <Modal
                modalState={modalState || Levels?.statemodal ? true : false}
                changeModalState={() => {
                    changeModalState(true);
                    dispatch(fetchSelectStateModalOrg(true));
                }}
                titleModalState={true}
                Height="100vh"
                Width="100vw"
                BoxShadow=""
                backG={Levels.passOrg === 3 ? 'none' : 'rgb(167, 169, 172, 0.25)'}
                Top="0"
            >
                {Levels.passOrg === 0 ? (
                    <PassOrganizator stateMent={1} />
                ) : (
                    <PassOrganizator stateMent={Levels.passOrg} />
                )}
            </Modal>
        </>
    );
};
