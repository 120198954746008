export const DUE_DETAILS = {
    TITLE: 'Plazos',
    TAB_HEADERS: {
        PAYMENT: 'Pago a cuotas',
        MOVEMENTS_HISTORY: 'Historial de movimientos',
    },
    FEE_TABLE_HEADERS: {
        FEE: 'Cuota',
        EXPIRATION_DATE: 'Vencimiento',
        STATUS: 'Estado',
        PAYMENT_DATE: 'Fecha de pago',
        PAYMENT_METHOD: 'Metodo de pago',
    },
    FEE_BTN_TICKET: 'Comprobante',
    FEE_BTN_PAY: 'Pagar',
    MOVEMENTS_TABLE_HEADERS: {
        TRANSACTION: 'Transacción',
        PAYMENT_METHOD: 'Método de pago',
        PAYMENT: 'Pago',
        PAYMENT_DATE: 'Fecha de pago',
    },
    PAYMENT_STATUS_TYPES: {
        PAID: {
            LABEL: 'Pagado',
            BACKGROUND: '#E4F7EC',
            BORDER_COLOR: '#B1E7C9',
            COLOR: '#174A2E',
        },
        UNPAID: {
            LABEL: 'No pagado',
            BACKGROUND: '#F4F5F5',
            BORDER_COLOR: '#D4D6D8',
            COLOR: '#54575C',
        },
        OVERDUE: {
            LABEL: 'Vencido',
            BACKGROUND: '#FFE6E6',
            BORDER_COLOR: '#FE9A9A',
            COLOR: '#501111',
        },
    },
    FILTER_OPTIONS: {
        ALL: {LABEL: 'Todos', VALUE: 'Todos'},
        PAID: {LABEL: 'Pagados', VALUE: 'Pagado'},
        UNPAID: {LABEL: 'No Pagados', VALUE: 'No Pagado'},
        OVERDUE: {LABEL: 'Vencidos', VALUE: 'Vencido'},
        NO_OPTIONS_MESSAGE: 'Sin opciones',
    },
    PAYMENT_TYPE_DELIMITER: '****',
    FORMAT_DATE: 'dd/MM/yyyy',
    ALT_DESCRIPTIONS: {
        DOWNLOAD_ICON: 'Icono de descarga',
        TYPE_PAYMENT: 'Icono de terjate de credito',
    },
    EMPTY_HISTORY: 'No se encontraron movimientos en tu historial',
};
