import React, {useEffect} from 'react';
import {ContainerFlex, ContainerForm, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    ACTIONPAYMENT,
    IMAGE_ATL_DESCRIPTION,
    STATE_ACTIONS,
    STEP_PAYMENTS,
} from '@components/QuickPayment/Constants';
import {EnablePayment} from '@components/QuickPayment/EnablePayment';
import {ResumePayment} from '@components/QuickPayment/ResumePayment';
import {MethodPayment} from '@components/QuickPayment/MethodPayment';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {
    selectStepPayment,
    selectActionState,
} from '@components/QuickPayment/Redux/Actions/GetActionPayment';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaTransactionLoans} from '@components/QuickPayment/schemaQuickPayment';
import {IPersonalPaymentTransaction} from '@components/QuickPayment/interfaces';
import Done from '@images/Secondary.svg';
import Connector from '@images/connector.svg';
import ConnectorDone from '@images/connectordark.svg';
import {addFastPaymentAction} from '@/components/QuickPayment/Redux/Actions/AddFastPaymentActions';
import {resetFastPaymentPlData} from '@/components/QuickPayment/Redux/Actions/GetFastPaymentPersonalActions';

export const ActionPayment = () => {
    const dispatch: AppDispatch = useDispatch();
    const StepPayment = useSelector(
        (state: RootState) => state.GetActionPayment.stepPayment as number
    );
    const {personalCreditsSelected, totalPayment, wasPaymentSuccess} = useSelector(
        (state: RootState) => state.addFastPayment
    );

    useEffect(() => {
        if (wasPaymentSuccess) {
            dispatch(resetFastPaymentPlData());
            dispatch(selectActionState(STATE_ACTIONS.SUCCESS_PAYMENT));
        }
    }, [wasPaymentSuccess]);

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IPersonalPaymentTransaction>({
        defaultValues: {
            cardNumber: '',
            cardHolderName: '',
            expiryDate: '',
            cvv: '',
        },
        resolver: yupResolver(schemaTransactionLoans),
    });

    const handleSubmitPayment = (formData: IPersonalPaymentTransaction) => {
        const {cardNumber, cardHolderName, expiryDate, cvv} = formData;
        const paymentData = {
            personalCreditId: personalCreditsSelected[0].personalCreditId,
            card: cardNumber,
            holderName: cardHolderName,
            expirationDate: expiryDate,
            ccv: cvv,
            amount: totalPayment,
        };
        dispatch(addFastPaymentAction(paymentData));
    };

    const handleBackStep = () => {
        if (StepPayment === STEP_PAYMENTS.PAYMENT_METHOD)
            dispatch(selectStepPayment(STEP_PAYMENTS.PAYMENTS_TO_MADE));
    };

    return (
        <ContainerFlex
            Justify="center"
            Align="center"
            FlexDir="column"
            Gap="24px"
            Padding="24px 60px"
        >
            <ContainerFlex
                FlexDir="column"
                Width="100%"
                Gap="16px"
                Flex="0"
                MinH="72px"
                Align="start"
                Justify="start"
            >
                <ContainerFlex Align="start" Justify="start">
                    <Text FontFamily="Nunito" FontSize="1.5rem" FontWeight="700" Color="#1D1E20">
                        {ACTIONPAYMENT.TITLE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="center" Gap="0.5rem">
                    <ContainerFlex
                        Width="24px"
                        Height="24px"
                        Radius="100px"
                        backG={StepPayment === STEP_PAYMENTS.PAYMENT_METHOD ? '#0D166B' : '#5A5AFF'}
                        Justify="center"
                        Align="center"
                    >
                        {StepPayment === STEP_PAYMENTS.PAYMENT_METHOD ? (
                            <Image
                                src={Done}
                                Width="16px"
                                Height="16px"
                                alt={IMAGE_ATL_DESCRIPTION.DONE_ICON}
                            />
                        ) : (
                            <Text Color="#FFFFFF" FontSize="0.875rem" FontWeight="700">
                                {ACTIONPAYMENT.STEP1}
                            </Text>
                        )}
                    </ContainerFlex>
                    <Text
                        Cursor="pointer"
                        onClick={handleBackStep}
                        Color={
                            StepPayment === STEP_PAYMENTS.PAYMENTS_TO_MADE ? '#5A5AFF' : '#0D166B'
                        }
                    >
                        {ACTIONPAYMENT.ENABLE}
                    </Text>
                    <Image
                        src={
                            StepPayment === STEP_PAYMENTS.PAYMENTS_TO_MADE
                                ? Connector
                                : ConnectorDone
                        }
                        width="32px"
                        Height="1px"
                        alt={IMAGE_ATL_DESCRIPTION.CONNECTOR_ICON}
                    />
                    <ContainerFlex
                        Width="24px"
                        Height="24px"
                        Radius="100px"
                        backG={StepPayment === STEP_PAYMENTS.PAYMENT_METHOD ? '#5A5AFF' : '#FFFFFF'}
                        Justify="center"
                        Align="center"
                    >
                        <Text
                            Color={
                                StepPayment === STEP_PAYMENTS.PAYMENT_METHOD ? '#FFFFFF' : '#54575C'
                            }
                            FontSize="0.875rem"
                            FontWeight="700"
                        >
                            {ACTIONPAYMENT.STEP2}
                        </Text>
                    </ContainerFlex>
                    <Text
                        Color={StepPayment === STEP_PAYMENTS.PAYMENT_METHOD ? '#5A5AFF' : '#1D1E20'}
                    >
                        {ACTIONPAYMENT.METHOD}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Align="start" Gap="16px">
                <ContainerForm
                    onSubmit={handleSubmit(handleSubmitPayment)}
                    BackG="transparent"
                    Justify="start"
                    Align="start"
                    Gap="1rem"
                >
                    {StepPayment === STEP_PAYMENTS.PAYMENTS_TO_MADE && <EnablePayment />}
                    {StepPayment === STEP_PAYMENTS.PAYMENT_METHOD && (
                        <MethodPayment control={control} errors={errors} />
                    )}
                    <ResumePayment />
                </ContainerForm>
            </ContainerFlex>
        </ContainerFlex>
    );
};
