import {Zoom} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AppDispatch, RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {
    ContainerFlex,
    Text,
    ContainerForm,
    Input,
    ContainerImg,
    Image,
} from '@Shopify/Ecommerce/styles';
import warning from '@images/warning.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {yupResolver} from '@hookform/resolvers/yup';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {SearchInput} from '@/components/Customer/Styles';
import {IconContainer, IconImg} from '@/components/Quoter/styles';
import {schema} from '@components/Login/Administrative/validationsYup';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {PrivacityContainer} from '@/components/Login/Administrative/Privacity';
import {IDataLoginAdministrator} from '@components/Login/Administrative/interfaces';
import {getValidateLoginUsersAdministrative} from '@components/Login/Redux/Actions/GetValidateUsers';
import {useNavigate} from 'react-router-dom';
import {resetAccountData} from '@/components/SignUp/Redux/Actions/saveDataFormAction';

export const LoginAccess = (props: {
    userExists: boolean;
    setUserExists: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const {userExists, setUserExists} = props;
    const [viewPassword, setViewPassword] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state: RootState) => state.getUsersValidation.loading);

    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm<IDataLoginAdministrator>({
        defaultValues: {
            Email: '',
            Password: '',
            version: 1,
        },
        resolver: yupResolver(schema),
    });
    const onSubmit = (value: IDataLoginAdministrator) => {
        dispatch(getValidateLoginUsersAdministrative(value, navigate, setUserExists));
    };

    useEffect(() => {
        dispatch(resetAccountData());
    }, []);

    return (
        <ContainerForm
            FlexDir="column"
            onSubmit={handleSubmit(onSubmit)}
            BackG="none"
            Padding="2.5rem 1.5rem 0 1.5rem"
        >
            {!userExists && (
                <Zoom in={!userExists}>
                    <ContainerFlex
                        Height="auto"
                        FontSize="0.75rem"
                        backG="#F3F3F3"
                        Padding="0.5rem 1rem"
                        MarginBt="1rem"
                        Radius="8px"
                        FontWeight="400"
                    >
                        <IconImg
                            Padding="0 0.3rem 0 0"
                            Content="none"
                            Top="auto"
                            Left="auto"
                            Opacity="1"
                            Position=""
                            src={warning}
                        />
                        No reconocemos esta combinación de credenciales.
                    </ContainerFlex>
                </Zoom>
            )}
            <Text FontWeight="500" Color="#54575C" Padding="0 0 1rem 0">
                Inicia sesión
            </Text>
            <ContainerFlex FlexDir="column" Height="auto" Align="start" Gap="4px" Width="95%">
                <Text required FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                    Correo
                </Text>
                <Input Margin="0 0 4px 0" {...register('Email')} />
                {errors.Email?.message && (
                    <Text
                        Color="#ef4f55"
                        FontSize="0.75rem"
                        Align="start"
                        wSpace="normal"
                        Height="auto"
                        FontWeight="400"
                    >
                        {errors.Email.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex
                Margin="1rem 0"
                FlexDir="column"
                Align="start"
                Height="auto"
                Gap="4px"
                Width="95%"
            >
                <Text required FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                    Contraseña
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    Margin="0 0 4px 0"
                    BoxShadowH=""
                    BoxShadowF=""
                    Padding="0 1rem"
                    BackG="#FFFFFF"
                    Radius="32px"
                >
                    <input type={viewPassword ? 'Text' : 'Password'} {...register('Password')} />
                    <ContainerImg onClick={() => setViewPassword(!viewPassword)}>
                        <Image
                            src={viewPassword ? VisibilityOn : VisibilityOff}
                            alt="icon-warning"
                            Cursor="pointer"
                        />
                    </ContainerImg>
                </SearchInput>

                {errors.Password?.message && (
                    <Text
                        Color="#ef4f55"
                        FontSize="0.75rem"
                        Align="start"
                        wSpace="normal"
                        Height="auto"
                        FontWeight="400"
                    >
                        {errors.Password.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Padding="1rem 0">
                <Text
                    Cursor="pointer"
                    Color="#5A5AFF"
                    FontWeight="500"
                    onClick={() => navigate('/Login/ForgotPassword')}
                >
                    ¿Olvidaste tu contraseña?
                </Text>
                <IconContainer
                    Color="#5A5AFF"
                    FontSize="1.5rem"
                    className="material-icons"
                    onClick={() => navigate('/Login/ForgotPassword')}
                >
                    keyboard_arrow_right
                </IconContainer>
            </ContainerFlex>

            {loading ? (
                <LoadingAtaskate />
            ) : (
                <ButtonGeneral
                    width="19rem"
                    text="Iniciar sesión"
                    type="submit"
                    height="40px"
                    cursor="pointer"
                />
            )}
            <ButtonGeneral
                disabled={loading}
                width="19rem"
                text="Crear cuenta"
                clic={() => navigate('/SignUp')}
                type="submit"
                margin="1rem 0"
                color={loading ? '#A1A5AA' : '#5A5AFF'}
                backGround="#FFFFFF"
                hText="#5A5AFF"
                hShadow=""
                height="40px"
                cursor="pointer"
                border="1px solid #5A5AFF"
                hBackG="#E5E5FF"
            />
            <PrivacityContainer />
        </ContainerForm>
    );
};
