import {ACTIONS_TYPE_FILTER} from '@ActionsElectronics/actions';

const INITIAL_STATE = {
    filters: [],
};

const Filters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIONS_TYPE_FILTER.GetFilters:
            return {
                ...state,
                filters: [...state.filters, action.payload],
            };
        case ACTIONS_TYPE_FILTER.DeleteFilters:
            return {
                ...state,
                filters: [],
            };
        case ACTIONS_TYPE_FILTER.UpdateFilters:
            return {
                ...state,
                filters: state.filters.filter(
                    (element) =>
                        element[0] !== action.payload[0] && element[1] !== action.payload[1]
                ),
            };
        case ACTIONS_TYPE_FILTER.StateButton:
            return {
                ...state,
                statebutton: action.payload,
            };
        default:
            return state;
    }
};

export default Filters;
