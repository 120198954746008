import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {ADD_USER_EMPLOYEE} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';
import {IUserEmployee} from '@components/LocationsBranch/interfaces';
import {removeUsers} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';

export const getUserEmployeeStart = () => ({type: ADD_USER_EMPLOYEE.START});

export const getUserEmployeeSuccess = (result: {data: AxiosResponse}) => ({
    type: ADD_USER_EMPLOYEE.SUCCESS,
    companyUsers: result.data,
});

export const getUserEmployeeError = (error: AxiosError) => ({
    type: ADD_USER_EMPLOYEE.ERROR,
    error: error,
});

export const getUserEmployee = (
    data: {
        companyId: number;
        searchName: string;
    },
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUserEmployeeStart());
        const queryString = StringUtils.jsonToQueryString({...data});
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchEmployeeSearch}${queryString}`,
                {headers}
            );
            dispatch(getUserEmployeeSuccess(response.data));
        } catch (error) {
            dispatch(getUserEmployeeError(error as AxiosError));
        }
    };
};

export const resetSearchUserEmployee = () => {
    return {
        type: ADD_USER_EMPLOYEE.RESET_SEARCH,
    };
};

export const setAddedUsers = (users: IUserEmployee[]) => ({
    type: ADD_USER_EMPLOYEE.ADD,
    addedUsers: users,
});

export const removeAddedUsersByIds = (userIds: number[] = []) => ({
    type: ADD_USER_EMPLOYEE.REMOVE_BY_IDS,
    userIds,
});

const resetAddedList = () => ({
    type: ADD_USER_EMPLOYEE.RESET_ADDED_LIST,
});

export function resetAddedUsers() {
    return (dispatch: AppDispatch) => {
        dispatch(removeUsers());
        dispatch(resetAddedList());
    };
}
