import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {OptionMenu} from '@OptionMenu/OptionMenu';
import {Text, ContainerFlex} from '@EcommerceShopify/styles';

import {RootState} from '@config/store';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {CardsClientEndeavor} from '@/components/Shopify/Endeavors/EndeavorsSection/CardsClientEndeavor';
import arrowIndex from '@components/Branches/Images/ArrowBackIndex.svg';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';
import {
    endeavorsByBranch,
    endeavorsByClient,
    endeavorsRestart,
} from '@Endeavors/redux/actions/EndeavorsClientActions';
import {
    endeavorFilter,
    endeavors,
    homeClient,
} from '@/components/Shopify/Endeavors/redux/interfaces';
import {TableEndeavors} from '@/components/Shopify/Endeavors/EndeavorsSection/TableEndeavor';
import {EndeavorsFilters} from '@/components/Shopify/Endeavors/EndeavorsSection/EndeavorFilters';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {postCarId} from '@/components/Quoter/Redux/Actions/GenerateCarId';

export function EndeavorsEndorsement() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialItem = {
        urlQR: '',
        pawnId: 0,
        folioContract: '',
        nameStatusContract: '',
        urlFlag: '',
        branchId: 0,
        branchName: '',
        companyId: 0,
        companyName: '',
        endorsementValue: 0,
        dischargeValue: 0,
        moratorium: 0,
        interest: 0,
        iva: 0,
        interestDiscount: 0,
        ivaDiscount: 0,
        insurance: 0,
        storage: 0,
        maximumInterest: 0,
        hiddenTerm: false,
        netInterest: 0,
        mutualBalance: 0,
        expirationDate: '',
        endorsementNumber: 0,
        pledgeNumber: 0,
        deadlineDate: '',
        urlContract: '',
    };
    const [modal, setModal] = useState({
        type: '',
        item: initialItem,
        flag: false,
    });
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/', label: 'Clientes'},
        {to: '#/Clients/ClientList', label: 'Listado de clientes'},
        {to: '#/Clients/ClientList', label: 'Información general'},
        {to: '', label: 'Empeños'},
    ];
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const endeavorsClient: endeavors = useSelector((state: RootState) => {
        return state.endeavorsClient;
    });
    const homeclient: homeClient = useSelector((state: RootState) => {
        return state.homeclient;
    });

    const filtersEndeavor = () => {
        let json: endeavorFilter = {
            clientId: homeclient.infoclient?.clientId,
            pageSize: 3,
            pageNumber: endeavorsClient.actualPage,
        };
        if (endeavorsClient.numberBranch !== 0)
            json = {...json, branchId: endeavorsClient.numberBranch};
        if (endeavorsClient.folioEndeavor)
            json = {...json, searchFolio: endeavorsClient.folioEndeavor};

        dispatch(endeavorsByClient(token, json));
    };

    useEffect(() => {
        dispatch(endeavorsRestart());
        dispatch(postCarId(token, {}, 'Quoter'));
    }, []);

    useEffect(() => {
        filtersEndeavor();
        if (homeclient.infoclient)
            dispatch(endeavorsByBranch(token, homeclient.infoclient.clientId));
    }, [
        homeclient.infoclient?.clientId,
        endeavorsClient.folioEndeavor,
        endeavorsClient.numberBranch,
        endeavorsClient.actualPage,
    ]);

    return (
        <ContainerFlex Width="100%" Align="start" FlexDir="column" Padding="5px 16px">
            <ContainerFlex Justify="start" Padding="1.313rem 0.5rem 2.438rem 0.5rem">
                <ImgLocation
                    src={arrowIndex}
                    Width="1.5rem"
                    Height="1.5rem"
                    Margin="0 12px 0 0"
                    Cursor="pointer"
                    onClick={() => navigate('/')}
                />
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>

            {homeclient.loadinginfoclient ? (
                <ContainerFlex Height="40vh">
                    <LoadingGeneral />
                </ContainerFlex>
            ) : (
                <ContainerFlex Align="start">
                    <ContainerFlex FlexDir="column" Width="20%">
                        <OptionMenu />
                    </ContainerFlex>
                    <ContainerFlex Padding="0 20px 0 20px" FlexDir="column" Width="80%">
                        {modal.flag && (
                            <Modal
                                modalState={modal.flag}
                                changeModalState={() => {}}
                                Align="center"
                                FlexDirMod="column"
                                Width="65%"
                                titleModalState={false}
                                BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
                                Justify="center"
                                Left="10%"
                                AlignMod="center"
                                Position="fixed"
                                Top="20%"
                                Height="auto"
                                BorderRadius="4px"
                            >
                                <ContainerFlex Padding="1rem" Justify="space-between">
                                    <ContainerFlex FlexDir="column" Align="start" Width="25%">
                                        <Text FontWeight="400" FontSize="14px">
                                            {(modal.type === 'endeavor'
                                                ? 'Desempeño'
                                                : 'Refrento') + ' Folio Nro.'}
                                        </Text>
                                        <Text FontWeight="300">{modal.item.folioContract}</Text>
                                    </ContainerFlex>
                                    <ContainerFlex FlexDir="column" Width="15%" Align="start">
                                        <Text FontWeight="400" FontSize="14px">
                                            Valor a pagar
                                        </Text>
                                        <Text Color="#7AC143">
                                            {formatterDolar.format(
                                                modal.type === 'endeavor'
                                                    ? modal.item.dischargeValue
                                                    : modal.item.endorsementValue
                                            )}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        Bl="1px solid #c6c6c6"
                                        Padding="0 0 0 1.5rem"
                                        Width="55%"
                                        Justify="space-between"
                                    >
                                        <ButtonGeneral
                                            FontSize="0.875rem"
                                            border="none"
                                            text="Realizar otra operación"
                                            hColor="#35cf44"
                                            width="12rem"
                                            clic={() => {
                                                setModal({
                                                    type: '',
                                                    item: initialItem,
                                                    flag: false,
                                                });
                                            }}
                                        />
                                        <ButtonGeneral
                                            FontSize="0.875rem"
                                            text="Proceder pago"
                                            hColor="#35cf44"
                                            clic={() => {
                                                setModal({
                                                    type: '',
                                                    item: initialItem,
                                                    flag: false,
                                                });
                                                navigate('/PaymentQuoter');
                                            }}
                                        />
                                    </ContainerFlex>
                                </ContainerFlex>
                            </Modal>
                        )}

                        {homeclient.infoclient ? (
                            <ContainerFlex Justify="start" Align="start" FlexDir="column">
                                <CardsClientEndeavor />
                                <EndeavorsFilters />
                                <TableEndeavors setModal={setModal} car={modal.flag} />
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Padding="3rem">
                                <Text FontSize="0.875rem" FontWeight="500">
                                    No se ha seleccionado un cliente del listado
                                </Text>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
}
