import React from 'react';

import {Numbers} from '@Articles/style';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {IconContainer} from '@/components/Quoter/styles';
import {DeadlineGeneral} from '@CreateDeadline/interfaces';

export const FinalDeadline = (props: DeadlineGeneral) => {
    const {deadlineById} = props;

    return (
        <ContainerFlex FlexDir="column" Width="60%" Margin="87px auto">
            {deadlineById.loading ? (
                <LoadingGeneral />
            ) : (
                <>
                    <Numbers BGColor="#fff" Border="1px solid #7AC143" Width="90px" Height="90px">
                        <IconContainer className="material-icons" FontSize="5rem" Color="#7AC143">
                            done
                        </IconContainer>
                    </Numbers>
                    <ContainerFlex Margin="22px auto">
                        <Text FontSize="1.125rem">
                            Se ha creado el plazo
                            <Text Color="#4A4A4A" Margin="0 0.5rem" FontWeight="600">
                                Nro. {deadlineById.dataSuccess?.data}
                            </Text>
                            exitosamente.
                        </Text>
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};
