import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    GET_PERSONAL_CREDIT_PRODUCTS_START,
    GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS,
    GET_PERSONAL_CREDIT_PRODUCTS_ERROR,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getPersonalCreditProductsStart = () => ({
    type: GET_PERSONAL_CREDIT_PRODUCTS_START,
});

export const getPersonalCreditProductsSuccess = (data: AxiosResponse) => ({
    type: GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS,
    payload: data,
});

export const getPersonalCreditProductsError = (error: AxiosError) => ({
    type: GET_PERSONAL_CREDIT_PRODUCTS_ERROR,
    payload: error,
});

export const getAllTotalAvailables = (token: string, companyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPersonalCreditProductsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPersonalCreditProducts}`,
                {headers, params: {companyId}}
            );
            dispatch(getPersonalCreditProductsSuccess(response));
        } catch (error) {
            dispatch(getPersonalCreditProductsError(error as AxiosError));
        }
    };
};
