import React from 'react';

interface ArrowIconProps {
    color?: string;
    size?: number;
    direction?: 'left' | 'right' | 'up' | 'down';
    cursor?: string;
    onClick?: () => void;
}

export const NextPage: React.FC<ArrowIconProps> = ({
    color = 'black',
    size = 24,
    direction = 'left',
    cursor = 'default',
    onClick,
}) => {
    const getRotation = () => {
        switch (direction) {
            case 'up':
                return 'rotate(-90deg)';
            case 'right':
                return 'rotate(180deg)';
            case 'down':
                return 'rotate(90deg)';
            default:
                return 'rotate(0deg)';
        }
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{transform: getRotation(), cursor}}
            onClick={onClick}
        >
            <mask id="h8zvm602ga" x="0" y="0" width="12" height="12">
                <path fill="#D9D9D9" d="M0 0h12v12H0z" />
            </mask>
            <g mask="url(#h8zvm602ga)">
                <path d="m3.5 9-.7-.7L5.1 6 2.8 3.7l.7-.7 3 3-3 3zM8 9V3h1v6H8z" fill={color} />
            </g>
        </svg>
    );
};

export const SingleNextPage: React.FC<ArrowIconProps> = ({
    color = 'black',
    size = 24,
    direction = 'left',
    cursor = 'default',
    onClick,
}) => {
    const getRotation = () => {
        switch (direction) {
            case 'up':
                return 'rotate(-90deg)';
            case 'right':
                return 'rotate(180deg)';
            case 'down':
                return 'rotate(90deg)';
            default:
                return 'rotate(0deg)';
        }
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_136_6175"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
                style={{transform: getRotation(), cursor}}
                onClick={onClick}
            >
                <rect width="24" height="24" fill="#6D6E71" />
            </mask>
            <g mask="url(#mask0_136_6175)">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill={color} />
            </g>
        </svg>
    );
};
