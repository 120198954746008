import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {IPropsLoading} from '@Atoms/LoadingAtaskate/interfaces';

const l3 = keyframes`
    to{transform: rotate(1turn)}
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Loading = styled.div<IPropsLoading>`
    width: ${(props) => props.width};
    padding: ${(props) => props.padding};
    aspect-ratio: 1;
    border-radius: 50%;
    background: #5a5aff;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: ${l3} 1s infinite linear;
`;

Loading.defaultProps = {
    width: '24px',
    padding: '8px',
};
