import styled from '@emotion/styled';
import {
    IPropsContentLocation,
    IPropsImgLocation,
    IPropsTextAreal,
    IPropsTextAreals,
    IPropsSelectLocations,
} from '@Locations/AdminLocations/CreateLocation/interfacesStyles';

export const TextAreal = styled.div<IPropsTextAreal>`
    select {
        width: 502px;
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        opacity: 1;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
        outline-color: #35cf44;
        border: 1px solid #e4e7e9;
    }
    input {
        width: ${(props) => props.IWidth};
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        opacity: 1;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
    }
    textarea {
        width: ${(props) => props.TWidth};
        height: 68px;
        padding: 10px 12px;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        background-color: FFFFFF 0% 0% no-repeat padding-box;
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
        resize: none;
    }
`;
TextAreal.defaultProps = {
    TWidth: '100%',
    IWidth: '100%',
};

export const TextAreals = styled.div<IPropsTextAreals>`
    select {
        height: 40px;
        width: 230px;
        border-radius: 5px;
        opacity: 1;
        background: #ffffff;
        outline-color: #35cf44;
        border: 1px solid #e4e7e9;
    }
    input {
        width: 246px;
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        opacity: 1;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
    }
    textarea {
        width: ${(props) => props.TWidth};
        height: 68px;
        padding: 10px 12px;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        background-color: FFFFFF 0% 0% no-repeat padding-box;
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
        resize: none;
    }
`;
TextAreals.defaultProps = {
    TWidth: '100%',
};

export const SelectFileVault = styled.div<IPropsSelectLocations>`
    select {
        height: 40px;
        width: ${(props) => props.Width};
        border-radius: 5px;
        opacity: 1;
        background: #ffffff;
        outline-color: #35cf44;
        border: 1px solid #e4e7e9;
    }
`;

SelectFileVault.defaultProps = {
    Width: '9.375rem',
};

export const SelectContainerVault = styled.div`
    display: flex;
    justify-content: flex-end;
    select {
        height: 40px;
        width: 98px;
        border-radius: 5px;
        opacity: 1;
        background: #ffffff;
        outline-color: #35cf44;
        border: 1px solid #e4e7e9;
    }
`;
export const SelectContainerVaults = styled.div`
    display: flex;
    justify-content: flex-end;
    select {
        height: 40px;
        width: 85px;
        border-radius: 5px;
        opacity: 1;
        background: #ffffff;
        outline-color: #35cf44;
        border: 1px solid #e4e7e9;
    }
`;

export const ContainerArea = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    opacity: 1;
`;
export const SubContainerArea = styled.div`
    display: flex;
    height: 43px;
    box-sizing: border-box;
    border-bottom: 0.5px solid #e4e7e9;
    letter-spacing: 0px;
    opacity: 1;
    padding: 12px 11px;
    align-items: center;
    background: #f3f3f3;
`;
export const ContainerImg = styled.div<IPropsContentLocation>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    align-content: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    background: ${(props) => props.backG};
    border-radius: ${(props) => props.Radius};
    cursor: ${(props) => props.Cursor};

    &:hover {
        background-color: ${(props) => props.BoxBackground};
        img {
            filter: ${(props) => props.HFilter};
        }
    }
`;
ContainerImg.defaultProps = {
    backG: '#EFF0F2',
    BoxBackground: '#7AC143',
    Margin: '0.219rem',
    Width: '45px',
    Height: '45px',
    Radius: '50%',
    Cursor: 'pointer',
    HFilter:
        'invert(0%) sepia(100%) saturate(10%) hue-rotate(302deg) brightness(200%) contrast(104%)',
};
export const ImgLocation = styled.img<IPropsImgLocation>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    margin-left: ${(props) => props.MarginL};
    background: ${(props) => props.backG};
    border-radius: ${(props) => props.Radius};
    cursor: ${(props) => props.Cursor};

    &:hover {
        filter: ${(props) => props.HFilter};
        background-color: ${(props) => props.BoxBackground};
    }
`;
ImgLocation.defaultProps = {
    Width: '100%',
    Height: '100%',
    Padding: '0',
    Margin: '0',
    backG: 'none',
    Radius: '0',
    Cursor: 'auto',
};

export default styled;
