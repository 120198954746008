import {PERSISTENCE_GROUP_BRANCH, STORAGE_TYPES} from '@components/LocationsBranch/Redux/types';
import {WarehouseState, WarehouseAction} from '@components/LocationsBranch/Redux/interfaces';

const initialState: WarehouseState = {
    warehouses: [
        {
            id: 1,
            name: 'Almacén',
        },
    ],
    error: false,
    loading: false,
    savedStorage: [],
};

const Warehouse = (state = initialState, action: WarehouseAction): WarehouseState => {
    switch (action.type) {
        case STORAGE_TYPES.NEW_WAREHOUSE:
            return {
                ...state,
                warehouses: action.payload.warehouses,
            };
        case STORAGE_TYPES.UPDATE_WAREHOUSE: {
            const warehouses = state.warehouses.map((warehouse) => {
                if (warehouse.id === action.payload.warehouse.id) {
                    return action.payload.warehouse;
                }
                return warehouse;
            });
            return {
                ...state,
                warehouses,
            };
        }
        case STORAGE_TYPES.ERROR:
            return {...state, loading: false, error: false};

        case STORAGE_TYPES.START:
            return {...state, loading: true};
        case STORAGE_TYPES.SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case PERSISTENCE_GROUP_BRANCH.RESET:
            return initialState;
        default:
            return state;
    }
};

export default Warehouse;
