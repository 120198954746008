import React from 'react';

import {Icons} from '@Articles/style';
import flagMexico from '@images/flags_mexico.svg';
import {APP_SETTINGS} from '@components/AppSettings/constants';
import * as styles from '@components/AppSettings/stylesAppSetting';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';

export const CountrySettings = () => {
    return (
        <ContainerFlex {...styles.contentSetting}>
            <Text {...styles.titleSetting}>{APP_SETTINGS.COUNTRY}</Text>
            <Text Color="#2A2C2F" FontWeight="500">
                <Image src={flagMexico} Margin="0 0.5rem 0 0" />
                {APP_SETTINGS.MEXICO}
                <Icons {...styles.iconSetting}>help_outline</Icons>
            </Text>
        </ContainerFlex>
    );
};
