import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';

import {
    GET_QUERYLOCATIONS_ERROR,
    GET_QUERYLOCATIONS_START,
    GET_QUERYLOCATIONS_SUCCESS,
    POST_ARTICLELOCATIONS_ERROR,
    POST_ARTICLELOCATIONS_START,
    POST_ARTICLELOCATIONS_SUCCESS,
    LOCATIONSID_START,
    LOCATIONSID_SUCCESS,
    LOCATIONSID_ERROR,
} from '@TypesManageLocations/ManageLocationsTypes';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';

export const fetchGetQueryLocationStart = () => {
    return {
        type: GET_QUERYLOCATIONS_START,
    };
};

export const fetchGetQueryLocationSuccess = (result: AxiosResponse) => {
    return {
        type: GET_QUERYLOCATIONS_SUCCESS,
        payload: result.data,
    };
};

export const fetchGetQueryLocationError = () => {
    return {
        type: GET_QUERYLOCATIONS_ERROR,
    };
};

export function getQueryLocations(
    branchId: number,
    pageSize: number,
    pageNumber: number,
    searchFilter: null | string,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString({
            branchId: branchId,
            pageSize: pageSize,
            pageNumber: pageNumber,
            searchFilter: searchFilter,
        });
        dispatch(fetchGetQueryLocationStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsProducts}${queryString}`,
                {headers}
            );
            dispatch(fetchGetQueryLocationSuccess(res));
        } catch (error) {
            dispatch(fetchGetQueryLocationError());
        }
    };
}

export const locationsByIdStart = () => {
    return {
        type: LOCATIONSID_START,
    };
};

export const locationsByIdSuccess = (result: AxiosResponse) => {
    return {
        type: LOCATIONSID_SUCCESS,
        payload: result,
    };
};

export const locationsByIdError = () => {
    return {
        type: LOCATIONSID_ERROR,
    };
};

export function locationsById(PledgeId: number, type: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(locationsByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsById}`.replace(
                    '{PledgeId}',
                    String(PledgeId)
                ),
                {headers}
            );
            dispatch(locationsByIdSuccess({...res.data, type: type}));
            if (res.data.succeeded) window.location.href = '#/Locations/AssigmentLocations';
        } catch (error) {
            dispatch(locationsByIdError());
        }
    };
}

export const fetchPostArticleLocationStart = () => {
    return {
        type: POST_ARTICLELOCATIONS_START,
    };
};

export const fetchPostArticleLocationSuccess = (result: AxiosResponse) => {
    return {
        type: POST_ARTICLELOCATIONS_SUCCESS,
        value: result.data,
    };
};

export const fetchPostArticleLocationError = () => {
    return {
        type: POST_ARTICLELOCATIONS_ERROR,
    };
};

export function updateLocationProduct(
    data: {
        pledgeId: number;
        squabsVaultId?: number;
        pensionId?: number;
        floorId?: number;
        rackRowId?: number;
        containerTypeId: number;
        issuePrenda?: string;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostArticleLocationStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateLocation}`,
                data,
                {headers}
            );
            dispatch(fetchPostArticleLocationSuccess(response));
            Swal.fire({
                icon: 'success',
                text: '¡El producto ha sido ubicado con éxito!',
                position: 'center',
                timer: 5000,
            }).then((result) => {
                if (result) window.location.href = '#/NavLocations';
            });
        } catch (error) {
            dispatch(fetchPostArticleLocationError());
        }
    };
}
