import {ErrorMessage} from '@hookform/error-message';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';

import '@Products/styles.css';
import {
    getProductsShopifyDetail,
    postProductsShopifyDetail,
} from '@ActionsProducts/ProductsShopifyActions';

export const ProductsDetailShopify = () => {
    const pictureprincipal = '';
    const {sku} = useParams();
    const {
        handleSubmit,
        register,
        getValues,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            sku: '',
            articulo: '',
            descripcion: '',
            idSucursalVitrina: '',
            sucursalVitrina: '',
            estaEnShopify: false,
            largo: '',
            ancho: '',
            alto: '',
            peso: '',
            fotos: [],
        },
    });

    const [images, setImages] = React.useState([]);

    const guardarImages = async (value) => {
        if (images.length > 0) {
            const dataimages = [];
            const formData = new FormData();
            formData.append('sku', sku);
            formData.append('articulo', value.articulo);
            formData.append('descripcion', value.descripcion);
            formData.append('idSucursalVitrina', productsshopifydetail.idSucursalVitrina);
            formData.append('sucursalVitrina', productsshopifydetail.sucursalVitrina);
            formData.append('estaEnShopify', value.estaEnShopify);
            formData.append('largo', value.largo);
            formData.append('ancho', value.ancho);
            formData.append('alto', value.alto);
            formData.append('peso', value.peso);

            dispatch(postProductsShopifyDetail(formData, token, dataimages, sku));
        }
    };
    function onValidate() {
        let bool = true;

        if (profile.role.includes('BAJA PRENDA SHOPIFY')) {
            bool = true;
        }

        return bool;
    }
    function onSubmit(data) {}
    const dispatch = useDispatch();
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const profile = useSelector((state) => {
        return state.logins.login.profile;
    });
    useEffect(() => {
        if (token) {
            const getProductShopifyDetail = () => {
                dispatch(getProductsShopifyDetail(sku, token));
            };
            getProductShopifyDetail();
        }
    }, [token]);
    const productsshopifydetail = useSelector((state) => {
        return state.productsshopify.productsshopifydetail;
    });
    useEffect(() => {
        if (productsshopifydetail) {
            let objetos = productsshopifydetail.fotos.map((item, index) => {
                let jason = {
                    dataURL: item.urldata,
                };
                return jason;
            });
            setImages(objetos);
            reset({
                sku: sku,
                articulo: productsshopifydetail.articulo,
                descripcion: productsshopifydetail.descripcion,
                idSucursalVitrina: productsshopifydetail.idSucursalVitrina,
                sucursalVitrina: productsshopifydetail.sucursalVitrina,
                estaEnShopify: productsshopifydetail.estaEnShopify,
                largo: productsshopifydetail.largo,
                ancho: productsshopifydetail.ancho,
                alto: productsshopifydetail.alto,
                peso: productsshopifydetail.peso,
            });
        } else {
            return;
        }
    }, [productsshopifydetail]);
    return (
        <div className="w-90 mx-auto ">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column  justify-content-center">
                    <span className="text-center pt-4 pb-4 title-shopify">
                        Artículos para Shopify
                    </span>
                    <span className="pt-4  ">
                        Carga nuevas fotos y actualiza la información del artículo para cargar a
                        Shopify
                    </span>
                </div>
                <div className=" pt-5 w-90 mx-auto">
                    <div className="flex  ">
                        <div className="flex w-100 pl-3 flex-row">
                            <img className="pl-5" src={pictureprincipal} alt="" width="76%" />
                        </div>
                        <div className="flex w-50 flex-column pr-4">
                            <div className="width-100 mb-4">
                                <input
                                    className="input-react-hook-form"
                                    {...register('articulo', {})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="articulo"
                                    message="This is required"
                                />
                            </div>

                            <div className="width-100 mb-4">
                                <textarea
                                    rows={5}
                                    className="inputarea-react-hook-form"
                                    {...register('descripcion', {})}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descripcion"
                                    message="This is required"
                                />
                            </div>
                            <div className="flex align-items-center">
                                <span className="pr-4">Sucursal:</span>
                                {productsshopifydetail && (
                                    <span>{productsshopifydetail.sucursalVitrina}</span>
                                )}
                                <span className="pr-4 pl-4">¿Shopify?</span>
                                {productsshopifydetail && (
                                    <GreenSwitch
                                        name="estaEnShopify"
                                        defaultChecked={productsshopifydetail.estaEnShopify}
                                        disabled={!onValidate()}
                                        inputRef={register}
                                    />
                                )}
                            </div>

                            <span className="pt-2">Dimensiones del artículo</span>
                            <div className="flex width-100 pt-1 mb-4">
                                <div className=" width-20 mb-4 mr-2">
                                    <span>largo</span>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('largo', {})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="largo"
                                        message="This is required"
                                    />
                                </div>
                                <div className="flex align-items-center">
                                    <span>X</span>
                                </div>
                                <div className=" width-20 mb-4 ml-2 mr-2">
                                    <span>ancho</span>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('ancho', {})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="ancho"
                                        message="This is required"
                                    />
                                </div>
                                <div className="flex align-items-center">
                                    <span>X</span>
                                </div>
                                <div className=" width-20 mb-4 mr-2 ml-2">
                                    <span>alto</span>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('alto', {})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="alto"
                                        message="This is required"
                                    />
                                </div>
                                <div className="flex align-items-center">
                                    <span>y</span>
                                </div>
                                <div className=" width-20 mb-4 ml-2">
                                    <span>peso</span>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('peso', {})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="peso"
                                        message="This is required"
                                    />
                                </div>
                            </div>
                            {productsshopifydetail && (
                                <button
                                    className="btn-submit-image "
                                    disabled={
                                        images.length >= productsshopifydetail.fotosMinimas
                                            ? false
                                            : true
                                    }
                                    type="submit"
                                    onClick={() =>
                                        guardarImages(
                                            getValues([
                                                'sku',
                                                'articulo',
                                                'descripcion',
                                                'idSucursalVitrina',
                                                'sucursalVitrina',
                                                'estaEnShopify',
                                                'largo',
                                                'ancho',
                                                'alto',
                                                'peso',
                                            ])
                                        )
                                    }
                                >
                                    {' '}
                                    Guardar
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
