import React from 'react';
import {ContainerFlex, Text, Image, ContainerGrid} from '@/components/Shopify/Ecommerce/styles';
import {LOANSLIST, TABLE_FILTERS} from '@components/LoansProducts/constants';
import Add from '@images/add - plus.svg';
import ProductImage from '@images/ProductsAtaskate.svg';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import ProductsTable from '@components/LoansProducts/ProductsTable';
import {SuggestedList} from '@components/LoansProducts/interfaces';
import StatusFilter from '@components/LoansProducts/StatusFilter';

export const ProductsLoans = () => {
    const {data, activeStatus} = useSelector((state: RootState) => {
        return state.getProductsCustomerUserReducer;
    });

    const filterMap = {
        [TABLE_FILTERS[0].label]: data,
        [TABLE_FILTERS[1].label]: data.filter((item: SuggestedList) => item.active === true),
        [TABLE_FILTERS[2].label]: data.filter((item: SuggestedList) => item.active === false),
        [TABLE_FILTERS[3].label]: data,
    };

    const filteredData = filterMap[activeStatus] ? filterMap[activeStatus] : data;

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Gap="1.5rem"
        >
            <ContainerFlex Justify="space-between" Align="center" MinH="40px" Height="40px">
                <Text Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                    {LOANSLIST.PRODCUTS}
                </Text>
            </ContainerFlex>
            {data.length > 0 && <StatusFilter />}
            <ContainerFlex FlexDir="column" Justify="start" Align="start">
                <ContainerGrid
                    GridColumns="2fr 2fr 2fr 2fr 2fr 1.29fr"
                    Height="2.625rem"
                    Padding="0.5rem 1rem"
                    Gap="1rem"
                    BorderBt="1px solid #E8E9EA"
                >
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.NAME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MIN}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAX}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MINTIME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAXTIME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.ACTIVE}
                    </Text>
                </ContainerGrid>
                {data.length > 0 ? (
                    <ProductsTable dataTable={filteredData} />
                ) : (
                    <ContainerFlex Height="9.875rem" FlexDir="column" Gap="0.5rem">
                        <Image src={ProductImage} alt="image" />
                        <ContainerFlex Height="1.5rem" Gap="0.25rem">
                            <Image src={Add} alt="add" />
                            <Text FontSize="0.875rem" FontWeight="700" Color="#5A5AFF">
                                {LOANSLIST.CREATE}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
