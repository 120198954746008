import {CLIENT_PROFILE} from '@Shopify/ClientProfile/Redux/types';
import {IClientProfile, IClientProfileRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IClientProfile = {
    error: false,
    loading: false,
    clientProfile: {
        clientId: 0,
        clientName: '',
        image: '',
        nuc: 0,
        score: '',
        ine: '',
        phone: '',
        email: '',
        address: '',
    },
    idClient: 0,
};

export const cardClientProfile = (
    state = initialState,
    action: IClientProfileRedux
): IClientProfile => {
    switch (action.type) {
        case CLIENT_PROFILE.INITIAL_DATA:
            return {...state, loading: true, error: false};
        case CLIENT_PROFILE.PROFILE_SUCCESS:
            return {
                ...state,
                clientProfile: action.clientProfile,
                loading: false,
                error: false,
            };
        case CLIENT_PROFILE.PROFILE_ERROR:
            return {...state, loading: false, error: true};
        case CLIENT_PROFILE.ID_CLIENT:
            return {...state, loading: false, idClient: action.idClient};
        default:
            return state;
    }
};
