import React, {useState} from 'react';
import * as styles from '@/components/Quoter/stylesConstants';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {Input} from '@components/NavBarPOS/SearchComponent/styles';
import {ISearchProps} from '@components/Users/interface';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';

export const RolesSearch: React.FC<ISearchProps> = ({onSearch}) => {
    const [searchText, setSearchText] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = () => {
        if (searchText.length > 2) {
            onSearch(searchText);
        }
    };

    const handleClearSearch = () => {
        setSearchText('');
        onSearch('');
    };

    return (
        <ContainerFlex
            Width="16.875rem"
            Height="2.5rem"
            Radius="2rem"
            Self="start"
            Border="1px solid #E8E9EA"
            GridColumns="90% 10%"
            Padding="0.5rem 1rem 0.5rem 0rem"
        >
            <Input
                type="input"
                placeholder="Buscar"
                value={searchText}
                onChange={handleChange}
                onKeyDown={(event: {key: string}) => {
                    if (event.key === 'Enter') {
                        handleSearchClick();
                    }
                }}
            />
            <Icons
                {...styles.iconSearch}
                onClick={() => {
                    handleClearSearch();
                }}
            >
                {searchText ? 'close' : 'search'}
            </Icons>
        </ContainerFlex>
    );
};
