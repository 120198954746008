import {GET_SCREEN} from '@components/Users/Redux/types';
export interface IScreen {
    screen: number;
}
const initialState: IScreen = {
    screen: 1,
};

const ScreenStateUsers = (state = initialState, action: {type: string; payload: number}) => {
    switch (action.type) {
        case GET_SCREEN:
            return {
                screen: action.payload,
            };

        default:
            return state;
    }
};
export default ScreenStateUsers;
