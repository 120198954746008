export const GET_INFO_BRANCH = 'GET_INFO_BRANCH';
export const GET_INFO_BRANCH_SUCCESS = 'GET_INFO_BRANCH_SUCCESS';
export const GET_INFO_BRANCH_ERROR = 'GET_INFO_BRANCH_ERROR';
export const GET_INFO_BRANCH_FINISH = 'GET_INFO_BRANCH_FINISH';

export const GET_BRANCH_USERS = 'GET_BRANCH_USERS';
export const GET_BRANCH_USERS_SUCCESS = 'GET_BRANCH_USERS_SUCCESS';
export const GET_BRANCH_USERS_ERROR = 'GET_BRANCH_USERS_ERROR';

export const GET_SEARCH_USERS = 'GET_SEARCH_USERS';
export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS';
export const GET_SEARCH_USERS_ERROR = 'GET_SEARCH_USERS_ERROR';

export const GET_CAT_CITY = 'GET_CAT_CITY';
export const GET_CAT_CITY_SUCCESS = 'GET_CAT_CITY_SUCCESS';
export const GET_CAT_CITY_ERROR = 'GET_CAT_CITY_ERROR';
export const GET_CAT_CITY_RESET = 'GET_CAT_CITY_RESET';

export const GET_CAT_MUNICIPALITY = 'GET_CAT_MUNICIPALITY';
export const GET_CAT_MUNICIPALITY_SUCCESS = 'GET_CAT_MUNICIPALITY_SUCCESS';
export const GET_CAT_MUNICIPALITY_ERROR = 'GET_CAT_MUNICIPALITY_ERROR';
export const GET_CAT_MUNICIPALITY_RESET = 'GET_CAT_MUNICIPALITY_RESET';

export const GET_CAT_STATE = 'GET_CAT_STATE';
export const GET_CAT_STATE_SUCCESS = 'GET_CAT_STATE_SUCCESS';
export const GET_CAT_STATE_ERROR = 'GET_CAT_STATE_ERROR';
export const GET_CAT_STATE_RESET = 'GET_CAT_STATE_RESET';

export const GET_CAT_SUBURB = 'GET_CAT_SUBURB';
export const GET_CAT_SUBURB_SUCCESS = 'GET_CAT_SUBURB_SUCCESS';
export const GET_CAT_SUBURB_ERROR = 'GET_CAT_SUBURB_ERROR';
export const GET_CAT_SUBURB_RESET = 'GET_CAT_SUBURB_RESET';

export const CREATE_POSTAL_INFO = 'CREATE_POSTAL_INFO';
export const CREATE_POSTAL_INFO_SUCCESS = 'CREATE_POSTAL_INFO_SUCCESS';
export const CREATE_POSTAL_INFO_ERROR = 'CREATE_POSTAL_INFO_ERROR';
export const RESET_POSTAL_INFO = 'RESET_POSTAL_INFO';

export const ADD_MAIN_INFO = 'ADD_MAIN_INFO';
export const ADD_MAIN_INFO_SUCCESS = 'ADD_MAIN_INFO_SUCCESS';
export const ADD_MAIN_INFO_ERROR = 'ADD_MAIN_INFO_ERROR';
export const ADD_MAIN_INFO_FINISH = 'ADD_MAIN_INFO_FINISH';

export const PUT_COMPLEMENTARY_INFO = 'PUT_COMPLEMENTARY_INFO';
export const PUT_COMPLEMENTARY_INFO_SUCCESS = 'PUT_COMPLEMENTARY_INFO_SUCCESS';
export const PUT_COMPLEMENTARY_INFO_ERROR = 'PUT_COMPLEMENTARY_INFO_ERROR';
export const PUT_COMPLEMENTARY_INFO_FINISH = 'PUT_COMPLEMENTARY_INFO_FINISH';

export const PUT_OTHER_INFO = 'PUT_OTHER_INFO';
export const PUT_OTHER_INFO_SUCCESS = 'PUT_OTHER_INFO_SUCCESS';
export const PUT_OTHER_INFO_ERROR = 'PUT_OTHER_INFO_ERROR';
export const PUT_OTHER_INFO_FINISH = 'PUT_OTHER_INFO_FINISH';

export const POST_CREATE_USER_BRANCH = 'POST_CREATE_USER_BRANCH';
export const POST_CREATE_USER_BRANCH_SUCCESS = 'POST_CREATE_USER_BRANCH_SUCCESS';
export const POST_CREATE_USER_BRANCH_ERROR = 'POST_CREATE_USER_BRANCH_ERROR';
export const POST_CREATE_USER_BRANCH_FINISH = 'POST_CREATE_USER_BRANCH_FINISH';

export const GET_BRANCH_SCHEDULE = 'GET_BRANCH_SCHEDULE';
export const GET_BRANCH_SCHEDULE_SUCCESS = 'GET_BRANCH_SCHEDULE_SUCCESS';
export const GET_BRANCH_SCHEDULE_ERROR = 'GET_BRANCH_SCHEDULE_ERROR';
export const GET_BRANCH_SCHEDULE_FINISH = 'GET_BRANCH_SCHEDULE_FINISH';
export const GET_BRANCH_SCHEDULE_RESET = 'GET_BRANCH_SCHEDULE_RESET';

export const PUT_BRANCH_SCHEDULE = 'PUT_BRANCH_SCHEDULE';
export const PUT_BRANCH_SCHEDULE_SUCCESS = 'PUT_BRANCH_SCHEDULE_SUCCESS';
export const PUT_BRANCH_SCHEDULE_ERROR = 'PUT_BRANCH_SCHEDULE_ERROR';
export const PUT_BRANCH_SCHEDULE_FINISH = 'PUT_BRANCH_SCHEDULE_FINISH';

export const GET_BRANCH_SCHEDULE_ENDEAVOR = 'GET_BRANCH_SCHEDULE_ENDEAVOR';
export const GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS = 'GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS';
export const GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR = 'GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR';
export const GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH = 'GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH';
export const GET_BRANCH_SCHEDULE_ENDEAVOR_RESET = 'GET_BRANCH_SCHEDULE_ENDEAVOR_RESET';

export const PUT_BRANCH_SCHEDULE_ENDEAVOR = 'PUT_BRANCH_SCHEDULE_ENDEAVOR';
export const PUT_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS = 'PUT_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS';
export const PUT_BRANCH_SCHEDULE_ENDEAVOR_ERROR = 'PUT_BRANCH_SCHEDULE_ENDEAVOR_ERROR';
export const PUT_BRANCH_SCHEDULE_ENDEAVOR_FINISH = 'PUT_BRANCH_SCHEDULE_ENDEAVOR_FINISH';

export const GET_BRANCH_SCHEDULE_DISCHARGE = 'GET_BRANCH_SCHEDULE_DISCHARGE';
export const GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS = 'GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS';
export const GET_BRANCH_SCHEDULE_DISCHARGE_ERROR = 'GET_BRANCH_SCHEDULE_DISCHARGE_ERROR';
export const GET_BRANCH_SCHEDULE_DISCHARGE_FINISH = 'GET_BRANCH_SCHEDULE_DISCHARGE_FINISH';
export const GET_BRANCH_SCHEDULE_DISCHARGE_RESET = 'GET_BRANCH_SCHEDULE_DISCHARGE_RESET';

export const PUT_BRANCH_SCHEDULE_DISCHARGE = 'PUT_BRANCH_SCHEDULE_DISCHARGE';
export const PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS = 'PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS';
export const PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR = 'PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR';
export const PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH = 'PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH';

export const GET_SERVICES_COMPLEMENTS = 'GET_SERVICES_COMPLEMENTS';
export const GET_SERVICES_COMPLEMENTS_SUCCESS = 'GET_SERVICES_COMPLEMENTS_SUCCESS';
export const GET_SERVICES_COMPLEMENTS_ERROR = 'GET_SERVICES_COMPLEMENTS_ERROR';
export const GET_SERVICES_COMPLEMENTS_FINISH = 'GET_SERVICES_COMPLEMENTS_FINISH';
export const GET_SERVICES_COMPLEMENTS_RESET = 'GET_SERVICES_COMPLEMENTS_RESET';

export const POST_SERVICES_COMPLEMENTS = 'POST_SERVICES_COMPLEMENTS';
export const POST_SERVICES_COMPLEMENTS_SUCCESS = 'POST_SERVICES_COMPLEMENTS_SUCCESS';
export const POST_SERVICES_COMPLEMENTS_ERROR = 'POST_SERVICES_COMPLEMENTS_ERROR';
export const POST_SERVICES_COMPLEMENTS_FINISH = 'POST_SERVICES_COMPLEMENTS_FINISH';
export const POST_SERVICES_COMPLEMENTS_RESET = 'POST_SERVICES_COMPLEMENTS_RESET';

export const PUT_SERVICES_COMPLEMENTS = 'PUT_SERVICES_COMPLEMENTS';
export const PUT_SERVICES_COMPLEMENTS_SUCCESS = 'PUT_SERVICES_COMPLEMENTS_SUCCESS';
export const PUT_SERVICES_COMPLEMENTS_ERROR = 'PUT_SERVICES_COMPLEMENTS_ERROR';
export const PUT_SERVICES_COMPLEMENTS_FINISH = 'PUT_SERVICES_COMPLEMENTS_FINISH';
export const PUT_SERVICES_COMPLEMENTS_RESET = 'PUT_SERVICES_COMPLEMENTS_RESET';

export const GET_CASHFLOW_SETUP = 'GET_CASHFLOW_SETUP';
export const GET_CASHFLOW_SETUP_SUCCESS = 'GET_CASHFLOW_SETUP_SUCCESS';
export const GET_CASHFLOW_SETUP_ERROR = 'GET_CASHFLOW_SETUP_ERROR';
export const GET_CASHFLOW_SETUP_FINISH = 'GET_CASHFLOW_SETUP_FINISH';
export const GET_CASHFLOW_SETUP_RESET = 'GET_CASHFLOW_SETUP_RESET';

export const POST_CASHFLOW_SETUP = 'POST_CASHFLOW_SETUP';
export const POST_CASHFLOW_SETUP_SUCCESS = 'POST_CASHFLOW_SETUP_SUCCESS';
export const POST_CASHFLOW_SETUP_ERROR = 'POST_CASHFLOW_SETUP_ERROR';
export const POST_CASHFLOW_SETUP_FINISH = 'POST_CASHFLOW_SETUP_FINISH';
export const POST_CASHFLOW_SETUP_RESET = 'POST_CASHFLOW_SETUP_RESET';

export const PUT_CASHFLOW_SETUP = 'PUT_CASHFLOW_SETUP';
export const PUT_CASHFLOW_SETUP_SUCCESS = 'PUT_CASHFLOW_SETUP_SUCCESS';
export const PUT_CASHFLOW_SETUP_ERROR = 'PUT_CASHFLOW_SETUP_ERROR';
export const PUT_CASHFLOW_SETUP_FINISH = 'PUT_CASHFLOW_SETUP_FINISH';
export const PUT_CASHFLOW_SETUP_RESET = 'PUT_CASHFLOW_SETUP_RESET';

export const GET_BRANCH_DISTANCE = 'GET_BRANCH_DISTANCE';
export const GET_BRANCH_DISTANCE_SUCCESS = 'GET_BRANCH_DISTANCE_SUCCESS';
export const GET_BRANCH_DISTANCE_ERROR = 'GET_BRANCH_DISTANCE_ERROR';
export const GET_BRANCH_DISTANCE_FINISH = 'GET_BRANCH_DISTANCE_FINISH';
export const GET_BRANCH_DISTANCE_RESET = 'GET_BRANCH_DISTANCE_RESET';

export const GET_BRANCH_DISTANCE_RECEIVE = 'GET_BRANCH_DISTANCE_RECEIVE';
export const GET_BRANCH_DISTANCE_RECEIVE_SUCCESS = 'GET_BRANCH_DISTANCE_RECEIVE_SUCCESS';
export const GET_BRANCH_DISTANCE_RECEIVE_ERROR = 'GET_BRANCH_DISTANCE_RECEIVE_ERROR';
export const GET_BRANCH_DISTANCE_RECEIVE_FINISH = 'GET_BRANCH_DISTANCE_RECEIVE_FINISH';
export const GET_BRANCH_DISTANCE_RECEIVE_RESET = 'GET_BRANCH_DISTANCE_RECEIVE_RESET';
