import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants';

import {
    POST_IMAGE_USER,
    POST_IMAGE_USER_SUCCESS,
    POST_IMAGE_USER_ERROR,
} from '@components/ManageUser/Redux/types';
import {AppDispatch} from '@/config/store';

export const postImageUser = () => ({type: POST_IMAGE_USER});
export const postImageUserSuccess = (result: AxiosResponse) => {
    return {
        type: POST_IMAGE_USER_SUCCESS,
        payload: result,
    };
};
export const postImageUserError = () => ({type: POST_IMAGE_USER_ERROR});
export const postImageUserAxios = (
    token: string,
    EmployeeId: string | undefined,
    formData: FormData
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'image/jpeg',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postImageUser());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateEmployeeImage}`,
                formData,
                {
                    headers,
                    params: {
                        EmployeeId,
                    },
                }
            );
            dispatch(postImageUserSuccess(response));
        } catch (error) {
            dispatch(postImageUserError());
        }
    };
};
