import React from 'react';
import {useDispatch} from 'react-redux';
import noFilterResultImage from '@/images/ImageNoFIlterResult.svg';
import {Text, ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {toggleFiltersMenu} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
import {NO_FILTER_RESULT} from '@/components/CreditsAndPawns/constants';

const NoFilterResult = () => {
    const dispatch = useDispatch();
    return (
        <ContainerFlex FlexDir={'column'} Gap="8px">
            <img src={noFilterResultImage} alt="Logo image" />
            <Text Color="#2A2C2F" LHeight="24px" LetterSpacing="0.3px" FontFamily="Nunito">
                {NO_FILTER_RESULT.TITLE}
            </Text>
            <ButtonGenerals
                BackGC="transparent"
                HBackG="transparent"
                onClick={() => dispatch(toggleFiltersMenu())}
            >
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontWeight="700"
                >
                    {NO_FILTER_RESULT.BUTTON_TEXT}
                </Text>
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default NoFilterResult;
