import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';

import * as styles from '@/components/Quoter/stylesConstants';
import {SearchInput} from '@Customer/Styles';
import {IGeneralQuoterData} from '@Quoter/inferfaces';
import {ListQuoter} from '@Quoter/Search/listSearch';
import {schemaSearch} from '@Quoter/Redux/yupValidations';
import {MESSAGESEARCH, SEARCHVALUES} from '@Quoter/constants';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {SearchQuoterProduct, resetSearch} from '@Quoter/Redux/Actions/QuoterActions';
import Switch from '@mui/material/Switch';

export const SearchQuoter = (props: IGeneralQuoterData) => {
    const {branchId, token, setView, onSubmitQuoter} = props;
    const dispatch: AppDispatch = useDispatch();
    const [viewList, setViewList] = useState(true);
    const {success} = useSelector((state: RootState) => state.addCatalogRequest);
    const {handleSubmit, register, watch, reset} = useForm({
        defaultValues: SEARCHVALUES,
        resolver: yupResolver(schemaSearch),
    });
    const validateArticle = watch('product').length > 2;
    const searchProducts = () => {
        dispatch(
            SearchQuoterProduct(token, {
                branchId: branchId,
                articleName: watch('product'),
            })
        );
    };
    useEffect(() => {
        if (success) {
            reset(SEARCHVALUES);
            closeProducts();
        }
    }, [success]);
    useEffect(() => {
        reset(SEARCHVALUES);
        closeProducts();
    }, [onSubmitQuoter]);

    const closeProducts = () => {
        setViewList(false);
        dispatch(resetSearch());
    };

    return (
        <ContainerForm
            FlexDir="column"
            Gap="1rem"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            onSubmit={handleSubmit(searchProducts)}
        >
            <Text FontWeight="500" FontSize="1.5rem" Color="#54575C">
                {MESSAGESEARCH.SEARCH}
            </Text>
            <ContainerFlex Justify="space-between" FlexDir="column">
                <ContainerFlex Position="relative" Align="end" Padding="0 0 24px 0">
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="90% 5% 5%"
                        FontSize="1rem"
                        PlaceholderColor="#A1A5AA"
                        WBoxShadow=""
                    >
                        <input
                            type="text"
                            {...register('product')}
                            autoComplete="off"
                            placeholder={MESSAGESEARCH.SEARCH}
                            onKeyUp={() => {
                                if (validateArticle) searchProducts();
                            }}
                            onKeyDown={() => setViewList(true)}
                        />
                        <Icons {...styles.iconSearch}>qr_code</Icons>
                        <Icons
                            {...styles.iconSearch}
                            onClick={() => {
                                reset(SEARCHVALUES);
                                closeProducts();
                            }}
                        >
                            {validateArticle ? 'close' : 'search'}
                        </Icons>
                    </SearchInput>
                    <ListQuoter
                        reset={reset}
                        viewList={viewList}
                        setViewList={setViewList}
                        validateArticle={validateArticle}
                        branchId={branchId}
                        setView={setView}
                    />
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" />
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Padding="24px 0 0 0"
                    Gap="16px"
                >
                    <Text FontSize="1rem" Color="#54575C">
                        {MESSAGESEARCH.EXTRACTION}
                    </Text>
                    <Switch
                        sx={{
                            width: 48,
                            height: 24,
                            padding: 0,
                            borderRadius: 16,
                            '& .MuiSwitch-switchBase': {
                                color: '#FFF',
                                padding: 0,
                                margin: 0.25,
                                boxShadow: '1px 1px 4px rgba(212, 214, 216, 0.88)',
                                '&.Mui-checked': {
                                    color: '#5A5AFF',
                                    transform: 'translateX(24px)',
                                    boxShadow: '1px 1px 4px rgba(172, 172, 255, 0.88)',
                                },
                                '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#E5E5FF',
                                    opacity: 1,
                                },
                            },
                            '& .MuiSwitch-track': {
                                backgroundColor: '#F4F5F5',
                                borderRadius: 16,
                                opacity: 1,
                                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset',
                            },
                            '& .MuiSwitch-thumb': {
                                boxShadow: 'none',
                            },
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
