import {RoleAdmin, RoleEmployee} from '@components/CreateUsers/interfaces';

export const CREATEUSER = {
    TITLE: 'Nuevo Usuario',
    PERSONALTITLE: 'Datos Personales',
    PERSONALDETAILS:
        'Estos detalles son fundamentales para su identificación y seguridad en nuestra plataforma.',
    USERNAME: 'Nombre(s)',
    USERLASTNAME: 'Apellido paterno',
    USERSECONDLASTNAME: 'Apellido materno',
    USERBIRTHDAY: 'Fecha de nacimiento',
    LOCATION: 'Ubicación',
    LOCATIONDETAILS: 'Asigna las ubicaciones en tu empresa a las que este usuario tendrá acceso.',
    ICONKEYDOWN: 'keyboard_arrow_down',
    IMG: 'data:image/jpeg;base64,',
};

export const ACCESSCREDENTIALS = {
    TITLE: 'Credenciales de acceso',
    DETAILS:
        'A continuación, encontrarás los campos de credenciales requeridos para acceder a nuestras plataformas de servicio.',
    SELECT: 'Selecciona a cual plataforma necesita acceso',
    EMAIL: 'Correo electrónico',
    USERNAME: 'Nombre de usuario (Solo aplica para POS)',
    PASSWORD: 'Contraseña',
    REPEATPASSWORD: 'Repetir contraseña',
    GENERATE: 'Generar automáticamente',
};
export const EMAILFORMAT =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ADRESSDETAILS = {
    TITLE: 'Dirección',
    DETAILS: 'Agregar una dirección personal o laboral para este usuario.',
    CP: 'C.P.',
    ADRESS: 'Calle',
    NUMEXT: 'No. exterior',
    NUMINT: 'No. interior',
    OPTIONAL: '(Opcional)',
    COLONY: 'Colonia',
    CITYHALL: 'Alcaldía',
    STATE: 'Estado',
    ADDADRESS: 'Agregar dirección',
    ADDMORE: 'Agregar otra dirección',
    DELETEADRESS: 'Eliminar dirección',
};
export const PIN = {
    DETAILS: 'Quieres agregar un PIN? (Solo aplica para POS)',
    PIN: 'PIN',
};
export const ROLES = {
    TITLEADMIN: 'Roles Administrativo',
    TITLEPOS: 'Roles POS',
    DETAILS:
        'Asigna uno o más roles que este usuario fungirá en la empresa, esto afectará los permisos y accesos que tiene dentro de la plataforma.',
    ADDROLE: 'Agregar otro rol',
};

export const BRANCHLOCATION = {
    TITLEBRANCH: 'Sucursales',
    DETAILS: 'Filtra sucursales por grupos organizacionales',
    SEARCH: 'Busca sucursales',
    GROUPSFILTER: 'Filtrar grupos',
    BRANCHES: 'Mostrar sucursales',
    CANCEL: 'Cancelar',
    CLOSE: 'Cerrar',
    APPLY: 'Aplicar',
    ALLOPTIONS: 'Todas',
    ICONDROPDOWN: 'arrow_drop_down',
    ICONSEARCH: 'search',
    ICONCLOSE: 'close',
    NORESULT: 'No se encontrarón resultados',
};

export const formUsers = {
    names: '',
    firsLastNames: '',
    secondLastNames: '',
    birthDate: '',
    companyId: 0,
    imageProfile: '',
    userType: 0,
    pin: '',
    accessCredentials: {
        emailAdmin: '',
        userName: '',
        password: '',
        repeatPassword: '',
    },
    aditionalInformationEmployee: [
        {
            manageAditionalInformation: true,
            codigoPostal: '',
            address: '',
            externalNumber: '',
            ineternalNumber: '',
            catColoniaId: 0,
        },
    ],
    levelBranch: [
        {
            branchId: 0,
            nameBranch: '',
        },
    ],
    roleAdmins: [] as RoleAdmin[],
    roleEmployees: [] as RoleEmployee[],
};

export const USER_TYPE = {
    ADMIN: 2,
    POS: 1,
    BOTH: 3,
};

export const PASSWD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d\S]{8,16}$/;
