import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableAuctions() {
    return (
        <TableHead>
            <tr>
                <th>Datos</th>
                <th className="text-right">Datos de la prenda</th>
                <th className="text-right">Auditoría de prenda</th>
            </tr>
        </TableHead>
    );
}
