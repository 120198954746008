import axios from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

import {
    GET_EMPLOYEE_MANAGERS,
    GET_EMPLOYEE_MANAGERS_SUCCESS,
    GET_EMPLOYEE_MANAGERS_ERROR,
    GET_EMPLOYEE_WITNESSES,
    GET_EMPLOYEE_WITNESSES_ERROR,
    GET_EMPLOYEE_WITNESSES_SUCCESS,
    GET_EMPLOYEE_BY_ID,
    GET_EMPLOYEE_BY_ID_ERROR,
    GET_EMPLOYEE_BY_ID_SUCCESS,
    GET_MANAGERS_BY_ID,
    GET_MANAGERS_BY_ID_ERROR,
    GET_MANAGERS_BY_ID_SUCCESS,
    POST_SMELTING,
    POST_SMELTING_SUCCESS,
    POST_SMELTING_ERROR,
    RESET_NAMES,
} from '@TypesAssignmentFoundry/AssignmentFoundryTypes';
import {FoundryAssigment} from '@/components/Foundry/interfaces';

export const fetchResetNames = () => {
    return {
        type: RESET_NAMES,
    };
};

export const getResetNames = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchResetNames());
    };
};

export const fetchGetEmployeeManagers = () => {
    return {
        type: GET_EMPLOYEE_MANAGERS,
    };
};
export const fetchGetEmployeeManagersSuccess = (result: {data: unknown}) => {
    return {
        type: GET_EMPLOYEE_MANAGERS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetEmployeeManagersError = () => {
    return {
        type: GET_EMPLOYEE_MANAGERS_ERROR,
    };
};
export const getEmployeeManagers = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeManagers());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EmployeeManagers}`, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetEmployeeManagersSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetEmployeeManagersError());
            });
    };
};

export const fetchGetEmployeeWitnesses = () => {
    return {
        type: GET_EMPLOYEE_WITNESSES,
    };
};
export const fetchGetEmployeeWitnessesSuccess = (result: {data: unknown}) => {
    return {
        type: GET_EMPLOYEE_WITNESSES_SUCCESS,
        value: result.data,
    };
};
export const fetchGetEmployeeWitnessesError = () => {
    return {
        type: GET_EMPLOYEE_WITNESSES_ERROR,
    };
};
export const getEmployeeWitnesses = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeWitnesses());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EmployeeWitnesses}`, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetEmployeeWitnessesSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetEmployeeWitnessesError());
            });
    };
};

export const fetchGetEmployeeById = () => {
    return {
        type: GET_EMPLOYEE_BY_ID,
    };
};
export const fetchGetEmployeeByIdSuccess = (result: {data: unknown}) => {
    return {
        type: GET_EMPLOYEE_BY_ID_SUCCESS,
        value: result.data,
    };
};
export const fetchGetEmployeeByIdError = () => {
    return {
        type: GET_EMPLOYEE_BY_ID_ERROR,
    };
};
export const getEmployeeById = (id: string, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeById());
        return axios
            .get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EmployeeByID.replace(
                    '{id}',
                    id.toString()
                )}`,
                {headers}
            )
            .then((result) => {
                dispatch(fetchGetEmployeeByIdSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetEmployeeByIdError());
            });
    };
};

export const fetchGetEmployeeManagersById = () => {
    return {
        type: GET_MANAGERS_BY_ID,
    };
};
export const fetchGetEmployeeManagersByIdSuccess = (result: {data: unknown}) => {
    return {
        type: GET_MANAGERS_BY_ID_SUCCESS,
        value: result.data,
    };
};
export const fetchGetEmployeeManagersByIdError = () => {
    return {
        type: GET_MANAGERS_BY_ID_ERROR,
    };
};
export const getEmployeeManagersById = (id: string, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeManagersById());
        return axios
            .get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EmployeeByID.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            )
            .then((result) => {
                dispatch(fetchGetEmployeeManagersByIdSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetEmployeeManagersByIdError());
            });
    };
};

export const fetchPostSmelting = () => {
    return {
        type: POST_SMELTING,
    };
};
export const fetchPostSmeltingSuccess = (result: {data: unknown}) => {
    return {
        type: POST_SMELTING_SUCCESS,
        value: result.data,
    };
};
export const fetchPostSmeltingError = () => {
    return {
        type: POST_SMELTING_ERROR,
    };
};
export const postSmelting = (data: FoundryAssigment, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostSmelting());
        return axios
            .post(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Smelting}`, data, {
                headers,
            })
            .then((result) => {
                dispatch(fetchPostSmeltingSuccess(result));
                Swal.fire({
                    icon: 'success',
                    title: 'Agregar visita',
                    text: '¡La visita ha sido agregada con éxito!',
                });
            })
            .catch((error) => {
                dispatch(fetchPostSmeltingError());
                Swal.fire({
                    icon: 'error',
                    title: 'Agregar visita',
                });
            });
    };
};
