import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {WithHoldingsScreen} from '@pages/Foundry/WithHoldingsScreen';
import {ConsultAssignmentScreen} from '@pages/Foundry/ConsultAssignmentScreen';
import {EditAssignmentScreen} from '@pages/Foundry/EditAssignmentScreen';
import {CloseBox} from '@components/CloseBox';
import {SucursalVerificationDataScreen} from '@pages/Foundry/SucursalVerificationData';
import {RecolectionGoldScreen} from '@pages/Foundry/RecolectionGoldScreen';
import {AuctionScreen} from '@pages/Foundry/AuctionScreen';
import {IncidentsFoundryScreen} from '@pages/Foundry/IncidentsFoundryScreen';
import {DocumentFoundryScreen} from '@pages/Foundry/DocumentFoundryScreen';
import {DeliveryScreen} from '@pages/Foundry/DeliveryScreen';
import {DeliverySecuritybagScreen} from '@pages/Foundry/DeliverySecuritybagScreen';
import {AssignmentFoundryScreen} from '@pages/Foundry/AssignmentFoundryScreen';
import {SecurityBagWeightScreen} from '@pages/Foundry/SecurityBagWeightScreen';
import {WeightSecurityBagScreen} from '@pages/Foundry/WeightSecurityBagScreen';
import {SecurityBagWeightJiracasScreen} from '@pages/Foundry/SecurityBagWeightJiracasScreen';
import {SecurityBagWeightEscoriaScreen} from '@pages/Foundry/SecurityBagWeightEscoriaScreen';
import {SecurityBagWeightGoldScreen} from '@pages/Foundry/SecurityBagWeightGoldScreen';
import {PurityScreen} from '@pages/Foundry/PurityScreen';
import {ProductsDetailShopifyScreen} from '@pages/Shopify/ProductsDetailShopifyScreen';
import {PurityLabScreen} from '@pages/Foundry/PurityLabScreen';
import {PurityFileScreen} from '@pages/Foundry/PurityFileScreen';
import {PurityPayScreen} from '@pages/Foundry/PurityPayScreen';
import {ShopifyScreen} from '@pages/Shopify/ShopifyScreen';
import {AdministrationEcommerce} from '@components/Ecommerce/index';
import {Reactivations} from '@components/Pawn/index';
import {DetailsReactivations} from '@pages/Reactivations/DetailsReactivations';
import {ReactivationScreen} from '@pages/Reactivations/ReactivationScreen';
import {BannerTable} from '@components/Ecommerce/BannerTable';
import {BannerAdministration} from '@components/Ecommerce/BannerAdministration';
import {PageNotFound} from '@components/PageNotFound/PageNotFound';
import {Payment} from '@components/Shopify/Payment/index';
import {Orders} from '@components/PagePedidos/Orders';
import {DragandDrop} from '@components/dragAndDrop/index';
import Electronics from '@components/Electronics/index';
import {Articles} from '@components/Articles/Article';
import FieldDinamics from '@components/Articles/FieldDinamics/FieldDinamics';
import Support from '@components/Support/index';
import DeadLines from '@components/DeadLines/DeadlinesIndex/Deadline';
import NewDeadline from '@components/DeadLines/DeadlinesIndex/NewDeadline';
import RouteDeadlines from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/SearchDeadline';
import {CreateDeadline} from '@components/DeadLines/CreateDeadline/CreateDeadline';
import CreateLocation from '@components/Locations/AdminLocations/CreateLocation/CreateLocation';
import Vault from '@components/Locations/AdminLocations/CreateLocation/Containers/Vault';
import Rack from '@components/Locations/AdminLocations/CreateLocation/Containers/Rack';
import Floor from '@components/Locations/AdminLocations/CreateLocation/Containers/Floor';
import Pension from '@components/Locations/AdminLocations/CreateLocation/Containers/Pension';
import LocationSuccess from '@components/Locations/AdminLocations/CreateLocation/Containers/LocationSuccess';
import NavLocations from '@components/Locations/NavLocations';
import AssigmentLocations from '@components/Locations/ManageLocations/AssigmentLocations';
import {Quoter} from '@components/Quoter/Quoter';
import PaymentGateway from '@components/Loan/PaymentQuoter/PaymentGateway';
import PaymentQuoter from '@components/Loan/PaymentQuoter/paymentQuoter';
import PaymentContract from '@components/Loan/PaymentQuoter/PaymentContract';
import PaymentSuccess from '@components/Shopify/Payment/PaymentSuccess';
import {ClientsList} from '@components/Shopify/ListClient/ClientsCard';
import {NewClient} from '@components/Shopify/ListClient/NewClient';
import {EndeavorsEndorsement} from '@/components/Shopify/Endeavors/EndeavorsSection/Endeavor';
import {DetailClient} from '@components/Shopify/ListClient/DetailClient';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import {CreateOrganization} from '@components/OrganizationCreation';
import {Company} from '@components/MyCompany/Company';
import MessageDeleteCompany from '@components/MyCompany/AplicationContact/MessageDeleteCompany';
import {RoleEditDeleteMessages} from '@components/Roles/RoleTable/RoleEditDeleteMessages';
import {Branches} from '@components/Branches';
import {ManageBranch} from '@components/Branches/ManageBranch';
import {CreateUsersForm} from '@components/CreateUsers/Form/CreateUsersForm';
import {ManageUser} from '@components/ManageUser/Index';
import {CloseBranch} from '@components/CloseBranch/CloseBranch';
import {HomeAdmin} from '@/components/Home/Admin/HomeAdmin';

import {routers} from '@/appRoute';
import {AdminNavbar} from '@/components/AdminNavbar/AdminNavbar';
import {HomeEmployee} from '@/components/Home/HomeEmployee';
import {Tabs} from '@/components/Tabs';
import SideBarMainMenu from '@/components/SideBarMenu/SideBarMainMenu';
import {MenuAdmin} from '@/components/SideBarMenu/MenuAdmin';
import {getModulesPosDashboard} from '@components/Home/HomeEmployee/Redux/Actions/getModulesPosAction';
import {CreateUser} from '@/components/CreateUsers/CreateUser';
import {RolesList} from '@/components/Roles/RolesList';
import {CreateRole} from '@/components/Roles/CreateRoles/CreateRoles';
import {CarSelector} from '@/components/ShoppingCart/carSelector';
import {AlmostPayment} from '@/components/ShoppingCart/AlmostPayment';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {LocationBranch} from '@/components/LocationsBranch/LocationBranch';
import {CreateNewGroup} from '@/components/LocationsBranch/CreateNewGroup';
import {PersonalLoans} from '@/components/PersonalLoans/PersonalLoans';
import {ClientProfile} from '@Shopify/ClientProfile/ClientProfile';
import {Enterprice} from '@MyCompany/ModalAddress/Enterprice';
import {AppSettings} from '@components/AppSettings/AppSettings';
import PaymentAndBilling from '@/components/AccountSettings/PaymetAndBilling';
import {OpenFundingsModals} from '@/components/CashFundingModal/FundingModals';
import MyPlan from '@/components/MyPlan/MyPlan';
import {ExpenseDetailsCreate} from '@/components/CashFlow/Expenses/ExpenseDetails/ExpensesDetailsCreate';
import ExpenseDetails from '@/components/CashFlow/Expenses/ExpenseDetails/ExpenseDetails';
import {CreditsAndPawns} from '@/components/CreditsAndPawns/CreditsAndPawns';
import {CashFlow} from '@/components/CashFlow/CashFlow';
import AdminCashflow from '@/components/AdminCashflow/AdminCashflow';
import CreditRequest from '@/components/CreditsAndPawns/CreditRequest/CreditRequest';
import AdminGeneralModule from '@/components/AdminGeneral/AdminGeneralModule';
import {ProductDetailsContainer} from '@/components/TransactionsDetails/ProductDetailsContainer';
import {CreditQuoter} from '@/components/CreditQuoter/CreditQuoter';
import {RequestNewCatalogLevel} from '@/components/RequestNewCatalogLevel/RequestCatalogLevel';
import {CreditValidation} from '@/components/PersonalLoans/CreditStatus/CreditValidation/CreditValidation';
import PersonalLoanSemiApproved from '@/components/PersonalLoans/PersonalLoanSemiApproved/PersonalLoanSemiApproved';
import PersonalLoanApproved from '@/components/PersonalLoans/PersonalLoanApproved/PersonalLoanApproved';
import {CatalogManagement} from '@/components/InventoryManagement/CatalogManagement/ContainerCatalogManagement';
import {DetailsExpense} from '@/components/CashFlowExpenseDetails/ExpenseDetails';
import {CreditsPayment} from '@/components/MyAccount/MyCredit/CreditDetails/CreditsPayment';
import {CreditsCartShopping} from '@/components/MyAccount/MyCredit/CreditDetails/CreditsCartShopping';
import {AllUsers} from '@/components/MyCompanyUser/AllUser';
import MyAccount from '@/components/MyAccount/MyAccount';
import CreditDetails from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetails';
import {NewLoanProduct} from '@/components/PersonalLoans/NewLoanProduct/NewLoanProduct';
import {HomeUser} from '@/components/Users/UserProfile/UserHome';
import {ContractDetail} from '@/components/Shopify/ClientProfile/ContractDetail';
import {LoansProducts} from '@/components/LoansProducts/LoansProducts';
import {NewUser} from '@/components/NewUser/NewUser';
import AuctionPass from '@/components/AuctionPass/AuctionPass';
import {DocumentsClients} from '@/components/PersonalLoans/NewLoanProduct/DocumentsClients';

export const DashboardRoutes = () => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => {
        return state.getUsersValidation.userData.userType;
    });
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });

    const isAdmin = () => {
        return userType === 'Administrator';
    };

    const [openMenuPos, setOpenMenuPos] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const showLoading = useSelector((state) => state.loading.isLoading);
    const loaderPrompt = useSelector((state) => state.loading.promptText);

    const isPathActive = (...paths) => paths.includes(location.pathname);

    const location = useLocation();
    const IsPayment = location.pathname === routers.PaymentQuoter;
    const IsPayed = location.pathname === routers.PaymentQuoterConfirmation;
    const IsClientAddNew = location.pathname === routers.ClientAddNew;

    const shouldShowCarSelector = () => {
        return (
            !isPathActive(routers.ClientAddNew, routers.ContractDetail) &&
            (!isPathActive(routers.ClientProfile) || isCartOpen) &&
            (!isPathActive(routers.PaymentGateway) || isCartOpen)
        );
    };

    const getCartToggleProps = () => {
        const isCartToggleVisible = isPathActive(routers.ClientProfile, routers.PaymentGateway);

        return {
            showCartToggle: isCartToggleVisible,
            isCartOpen,
            setCartView: setIsCartOpen,
        };
    };

    useEffect(() => {
        dispatch(getModulesPosDashboard(token));
    }, []);

    return (
        <>
            <AdminNavbar
                isAdmin={isAdmin()}
                openMenuPos={openMenuPos}
                setOpenMenuPos={setOpenMenuPos}
                cartToggle={getCartToggleProps()}
            />
            {(userType === 'SuperAdministrador' || userType === 'SuperAdministrator') && (
                <ContainerFlex Align="start" Justify="start" Height="calc(100vh - 57px)">
                    <Routes>
                        <Route path={routers.AdministrationArticle} element={<Articles />} />
                        <Route path={'/'} element={<Articles />} />
                        <Route path={routers.FieldDinamics} element={<FieldDinamics />} />
                    </Routes>
                </ContainerFlex>
            )}
            {userType === 'Administrator' && (
                <ContainerFlex Align="start" Justify="start" Height="calc(100vh - 57px)">
                    <MenuAdmin />
                    <ContainerFlex
                        FlexDir="column"
                        Position="relative"
                        Justify="start"
                        Overflow="auto"
                        Align="start"
                    >
                        <Routes>
                            <Route path={'/'} element={<HomeAdmin />} />
                            <Route path={routers.NewDeadline} element={<NewDeadline />} />
                            <Route
                                path={routers.CreateOrganization}
                                element={<CreateOrganization />}
                            />
                            <Route path={routers.UserProfile} element={<HomeUser />} />

                            <Route path={routers.AdminCashflow} element={<AdminCashflow />} />
                            <Route
                                path={routers.AdminGeneralModule}
                                element={<AdminGeneralModule />}
                            />
                            <Route path={routers.AdministrationArticle} element={<Articles />} />
                            <Route path={routers.NewUser} element={<NewUser />} />
                            <Route path={routers.AuctionPass} element={<AuctionPass />} />
                            <Route path={routers.CreateRole} element={<CreateRole />} />
                            <Route path={routers.UpdateRole} element={<CreateRole />} />
                            <Route path={routers.Users} element={<AllUsers />} />
                            <Route path={routers.CreateUsers} element={<CreateUsersForm />} />
                            <Route path={routers.DeleteRole} element={<RoleEditDeleteMessages />} />
                            <Route path={routers.AdminCashflow} element={<AdminCashflow />} />
                            <Route
                                path={routers.AdminGeneralModule}
                                element={<AdminGeneralModule />}
                            />
                            <Route path={routers.Roles} element={<RolesList />} />
                            <Route path={routers.RouteDeadline} element={<RouteDeadlines />} />
                            <Route path={routers.CreateDeadline} element={<CreateDeadline />} />
                            <Route path={routers.DeadLines} element={<DeadLines />} />
                            <Route path={routers.ClientList} element={<ClientsList />} />
                            <Route
                                path={routers.WithHoldingsScreen}
                                element={<WithHoldingsScreen />}
                                render={(props) => <WithHoldingsScreen {...props} />}
                            />
                            <Route
                                path={routers.ConsultAssignmentScreen}
                                element={<ConsultAssignmentScreen />}
                            />
                            <Route
                                path={routers.EditAssignmentScreen}
                                element={<EditAssignmentScreen />}
                            />
                            <Route
                                path={routers.SucursalVerificationDataScreen}
                                element={<SucursalVerificationDataScreen />}
                            />
                            <Route
                                path={routers.RecolectionGoldScreen}
                                element={<RecolectionGoldScreen />}
                            />
                            <Route path={routers.AuctionScreen} element={<AuctionScreen />} />
                            <Route
                                path={routers.IncidentsFoundryScreen}
                                element={<IncidentsFoundryScreen />}
                            />
                            <Route
                                path={routers.ProductsDetailShopify}
                                element={<ProductsDetailShopifyScreen />}
                            />

                            <Route
                                path={routers.DocumentsFoundryScreen}
                                element={<DocumentFoundryScreen />}
                            />
                            <Route path={routers.DeliveryScreen} element={<DeliveryScreen />} />
                            <Route
                                path={routers.DeliverySecuritybagScreen}
                                element={<DeliverySecuritybagScreen />}
                            />
                            <Route
                                path={routers.AssignmentFoundryScreen}
                                element={<AssignmentFoundryScreen />}
                            />
                            <Route
                                path={routers.SecurityBagWeightScreen}
                                element={<SecurityBagWeightScreen />}
                            />
                            <Route
                                path={routers.WeightSecurityBagScreen}
                                element={<WeightSecurityBagScreen />}
                            />

                            <Route
                                path={routers.SecurityBagWeightEscoriaScreen}
                                element={<SecurityBagWeightEscoriaScreen />}
                            />
                            <Route
                                path={routers.SecurityBagWeightJiracasScreen}
                                element={<SecurityBagWeightJiracasScreen />}
                            />
                            <Route
                                path={routers.SecurityBagWeightGoldScreen}
                                element={<SecurityBagWeightGoldScreen />}
                            />
                            <Route path={routers.PurityScreen} element={<PurityScreen />} />
                            <Route path={routers.PurityLabScreen} element={<PurityLabScreen />} />
                            <Route path={routers.PurityFile} element={<PurityFileScreen />} />
                            <Route path={routers.Reactivations} element={<Reactivations />} />
                            <Route
                                path={routers.DetailsReactivations}
                                element={<DetailsReactivations />}
                            />
                            <Route
                                path={routers.ReactivationScreen}
                                element={<ReactivationScreen />}
                            />
                            <Route
                                path={routers.AdministrationEcommerce}
                                element={<AdministrationEcommerce />}
                            />
                            <Route path={routers.PurityPay} element={<PurityPayScreen />} />
                            <Route path={routers.Payment} element={<Payment />} />
                            <Route path={routers.Orders} element={<Orders />} />

                            <Route path={routers.Endeavors} element={<EndeavorsEndorsement />} />
                            <Route path={routers.DetailClient} element={<DetailClient />} />
                            <Route path={routers.Shopify} element={<ShopifyScreen />} />
                            <Route path={routers.Ecommerce} element={<DragandDrop />} />
                            <Route path={routers.CloseBranch} element={<CloseBranch />} />

                            <Route path={routers.Categories} element={<Electronics />} />

                            <Route
                                path={routers.AdministrationBanner}
                                element={<BannerAdministration />}
                            />
                            <Route path={routers.Support} element={<Support />} />
                            <Route path={routers.BannerTable} element={<BannerTable />} />
                            <Route path={routers.CreateLocation} element={<CreateLocation />} />
                            <Route path={routers.CloseBox} element={<CloseBox />} />
                            <Route path={routers.NavLocations} element={<NavLocations />} />
                            <Route path={routers.Quoter} element={<Quoter />} />
                            <Route path={routers.PaymentContracts} element={<PaymentContract />} />
                            <Route path={routers.ManageLocations} element={<NavLocations />} />

                            <Route path={routers.Vault} element={<Vault />} />
                            <Route path={routers.Rack} element={<Rack />} />
                            <Route path={routers.Floor} element={<Floor />} />
                            <Route path={routers.Pension} element={<Pension />} />
                            <Route path={routers.LocationSuccess} element={<LocationSuccess />} />
                            <Route
                                path={routers.AssigmentLocations}
                                element={<AssigmentLocations />}
                            />
                            <Route path={routers.PaymentSuccess} element={<PaymentSuccess />} />
                            <Route exact path={routers.InProcess} element={<PageNotFound />} />

                            <Route path={routers.MyCompany} element={<Company />} />
                            <Route
                                path={routers.AplicationContact}
                                element={<MessageDeleteCompany />}
                            />
                            <Route path={routers.ManageUser} element={<ManageUser />} />
                            <Route
                                path={routers.AplicationContact}
                                element={<MessageDeleteCompany />}
                            />
                            <Route path={routers.Branches} element={<Branches />} />
                            <Route path={routers.ManageBranch} element={<ManageBranch />} />
                            <Route path={routers.CreateUser} element={<CreateUser />} />
                            <Route path={routers.LocationBranch} element={<LocationBranch />} />
                            <Route path={routers.CreateNewGroup} element={<CreateNewGroup />} />
                            <Route path={routers.PersonalLoans} element={<PersonalLoans />} />
                            <Route path={routers.UpdateAddress} element={<Enterprice />} />
                            <Route path={routers.AppSettings} element={<AppSettings />} />
                            <Route
                                path={routers.SettingsPaymentAndBilling}
                                element={<PaymentAndBilling />}
                            />
                            <Route path={routers.MyPlan} element={<MyPlan />} />
                            <Route path={routers.CreditsAndPawns} element={<CreditsAndPawns />} />
                            <Route path={routers.CreditRequest} element={<CreditRequest />} />
                            <Route
                                path={routers.RequestNewCatalogLevel}
                                element={<RequestNewCatalogLevel />}
                            />
                            <Route
                                path={routers.CatalogManagement}
                                element={<CatalogManagement />}
                            />
                            <Route path={routers.CreditsPayment} element={<CreditsPayment />} />
                            <Route
                                path={routers.CreditsCartShopping}
                                element={<CreditsCartShopping />}
                            />
                            <Route path={routers.NewLoanProduct} element={<NewLoanProduct />} />
                            <Route path={routers.LoansProducts} element={<LoansProducts />} />
                            <Route path={routers.NewLoanProduct} element={<NewLoanProduct />} />
                            <Route path={routers.DocumentClients} element={<DocumentsClients />} />
                        </Routes>
                    </ContainerFlex>
                </ContainerFlex>
            )}{' '}
            {userType === 'Client' && (
                <ContainerFlex Align="start" Justify="start" Height="calc(100vh - 57px)">
                    <Routes>
                        <Route path={routers.CreditQuoter} element={<CreditQuoter />} />
                        <Route path={routers.PersonalLoans} element={<PersonalLoans />} />
                        <Route
                            path={routers.PersonalLoansValidating}
                            element={<CreditValidation />}
                        />
                        <Route
                            path={routers.PersonalLoanSemiApproved}
                            element={<PersonalLoanSemiApproved />}
                        />
                        <Route
                            path={routers.PersonalLoansApproved}
                            element={<PersonalLoanApproved />}
                        />
                        <Route path={routers.MyAccount} element={<MyAccount />} />
                        <Route path={routers.CreditDetails} element={<CreditDetails />} />
                        <Route
                            path={routers.CreditsCartShopping}
                            element={<CreditsCartShopping />}
                        />
                        <Route path={routers.CreditsPayment} element={<CreditsPayment />} />
                    </Routes>
                </ContainerFlex>
            )}
            {userType === 'Employee' && (
                <>
                    {openMenuPos && <SideBarMainMenu />}
                    {showLoading && (
                        <ContainerFlex Position="absolute" ZIndex="3" backG="#fafafa">
                            <LoadingAtaskate
                                width="250px"
                                padding="55px"
                                promptText={loaderPrompt}
                                textWidth="550px"
                            />
                        </ContainerFlex>
                    )}
                    <ContainerFlex
                        Height="calc(100vh - 93px)"
                        OverFlow="auto"
                        Position="relative"
                        Align="start"
                        Justify="center"
                        backG="#FAFAFA"
                        Padding={IsPayment || IsPayed ? '0 24px' : ''}
                        Display={shouldShowCarSelector() && 'grid'}
                        GridColumns={
                            shouldShowCarSelector() && 'minmax(28rem, 1fr) minmax(25rem, 0fr)'
                        }
                    >
                        <Routes>
                            <Route path={routers.PaymentGateway} element={<PaymentGateway />} />
                            <Route path={routers.PaymentQuoter} element={<PaymentQuoter />} />

                            <Route
                                path={routers.PaymentQuoterConfirmation}
                                element={<AlmostPayment />}
                            />

                            <Route path={'/'} element={<HomeEmployee />} />
                            <Route
                                path={routers.ExpenseDetailsCreate}
                                element={<ExpenseDetailsCreate />}
                            />
                            <Route path={routers.ExpenseDetails} element={<ExpenseDetails />} />
                            <Route path={routers.CashFlow} element={<CashFlow />} />
                            <Route path={routers.Quoter} element={<Quoter />} />
                            <Route path={routers.ClientList} element={<ClientsList />} />
                            <Route path={routers.ClientAddNew} element={<NewClient />} />
                            <Route exact path={'*'} element={<PageNotFound />} />
                            <Route path={routers.ClientProfile} element={<ClientProfile />} />
                            <Route path={routers.PersonalLoans} element={<PersonalLoans />} />
                            <Route
                                path={routers.ProductDetailsContainer}
                                element={<ProductDetailsContainer />}
                            />
                            <Route
                                path={routers.OpenFundingsModals}
                                element={<OpenFundingsModals />}
                            />
                            <Route path={routers.DetailsExpense} element={<DetailsExpense />} />
                            <Route
                                path={routers.CreditsCartShopping}
                                element={<CreditsCartShopping />}
                            />
                            <Route path={routers.ContractDetail} element={<ContractDetail />} />
                        </Routes>
                        {shouldShowCarSelector() && <CarSelector />}
                    </ContainerFlex>
                    {!IsClientAddNew && <Tabs />}
                </>
            )}
        </>
    );
};
