import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageIcon} from '@components/OrganizationCreation/SubContainer/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {roleSettingsText} from './constants';
import elipseIcon from '@images/Ellipse 975.svg';
import elipseIconRole from '@images/Ellipse 976.svg';
import infoIcon from '@images/vector.svg';
import SelectCheckBox from './SelectCheckBox';

const RoleSettings = () => {
    return (
        <ContainerFlex FlexDir="column" Align="start" Justify="start" Padding="20px" Gap="8px">
            <Text FontWeight="700" FontSize="24px" Height="2rem">
                {roleSettingsText.roleSettings}
            </Text>
            <ContainerFlex Justify="start" Height="5rem">
                <ImageIcon src={elipseIcon} height="32px" width="32px" alt="Elipse Icon" />
                <ImageIcon src={elipseIconRole} height="32px" width="32px" alt="Elipse Icon Role" />
                <Text FontWeight="700" FontSize="20px">
                    {roleSettingsText.roleSelect}
                </Text>
            </ContainerFlex>
            <Text>{roleSettingsText.roleassign}</Text>
            <Text FontWeight="700" FontSize="18px">
                {roleSettingsText.role}
            </Text>
            <SelectCheckBox />
            <ContainerFlex
                Justify="space-between"
                Height="5rem"
                Padding="15px"
                backG="#DBF4FF"
                Radius="8px"
            >
                <Text Color="#004461" FontWeight="700">
                    <ImageIcon src={infoIcon} height="20px" width="20px" alt="Info" />
                    {roleSettingsText.roleMessage}
                </Text>
                <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer">
                    {roleSettingsText.roleLink}
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="5rem" Gap="10px">
                <ButtonGenerals
                    BackGC="white"
                    Color="#5A5AFF"
                    Border="1px solid#5A5AFF"
                    Width="9.1rem"
                    HBackG="#77779e66"
                >
                    {roleSettingsText.cancel}
                </ButtonGenerals>
                <ButtonGenerals Width="9.1rem">{roleSettingsText.save}</ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default RoleSettings;
