import styled from '@emotion/styled';

type SliderProps = {
    sliderWidth: string;
};

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Slider = styled.div<SliderProps>`
    position: relative;
    width: ${({sliderWidth}) => sliderWidth};
`;

export const Track = styled.div`
    position: absolute;
    border-radius: 3px;
    height: 8px;
    background-color: #e8e9ea;
    width: 100%;
    z-index: 0;
`;

export const Range = styled.div`
    position: absolute;
    border-radius: 3px;
    height: 8px;
    background-color: #5a5aff;
    z-index: 1;
`;

export const RangeLabel = styled.div`
    display: flex;
    align-self: flex-end;
    margin-bottom: 12px;
    span {
        color: #54575c;
        font-family: 'Work Sans';
        font-size: 12px;
        line-height: 12px;
    }
`;

export const Thumb = styled.input<{
    zIndex: number;
    sliderWidth: string;
}>`
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: ${({sliderWidth}) => sliderWidth};
    outline: none;
    accent-color: #5a5aff;
    border: none;
    z-index: ${({zIndex}) => zIndex};
    &::-webkit-slider-thumb {
        background-color: #5a5aff;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #5a5aff;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 8px;
        pointer-events: all;
        position: relative;
    }

    &::-moz-range-thumb {
        background-color: #5a5aff;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #5a5aff;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 8px;
        pointer-events: all;
        position: relative;
    }
`;
