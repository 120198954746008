import {AppDispatch, RootState} from '@/config/store';
import {
    GET_ITEMS,
    GET_ITEMS_ERROR,
    GET_ITEMS_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {updateArticleChildren} from '@components/RequestNewCatalogLevel/Redux/Actions/GetCatGroupAction';

export const getItems = () => {
    return {
        type: GET_ITEMS,
    };
};

export const getItemsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ITEMS_SUCCESS,
        payload: result,
    };
};

export const getItemsError = (error: AxiosError) => {
    return {
        type: GET_ITEMS_ERROR,
        payload: error,
    };
};

export const getItemsAction = (
    pageSize: number,
    pageNumber: number,
    brandId: number,
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(getItems());
        const params = {
            pageSize: pageSize?.toString(),
            pageNumber: pageNumber?.toString(),
            brandId: brandId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetItems}?${queryString}`;
        try {
            const firstResponse = await axios.get(url, {headers});
            const totalPages = firstResponse.data.totalPages;
            let allItems = firstResponse.data.data;
            if (totalPages > 1) {
                for (let currentPage = pageNumber + 1; currentPage <= totalPages; currentPage++) {
                    const newParams = {
                        pageSize: pageSize?.toString(),
                        pageNumber: currentPage.toString(),
                        brandId: brandId?.toString(),
                    };

                    const newQueryString = new URLSearchParams(newParams).toString();
                    const newUrl = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetItems}?${newQueryString}`;
                    const newResponse = await axios.get(newUrl, {headers});
                    allItems = [...allItems, ...newResponse.data.data];
                }
            }
            dispatch(getItemsSuccess(allItems));
            dispatch(updateArticleChildren(brandId, allItems));
        } catch (error) {
            dispatch(getItemsError(error as AxiosError));
        }
    };
};
