import React from 'react';
import {Controller} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime} from 'luxon';

import {ContainerFlex, ContainerCellGrid, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import {IconSvg} from '@Quoter/Icons/styles';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import PhoneFormatInput from '@components/PersonalLoans/PhoneFormat';
import {PERSONALDATA} from '@components/PersonalLoans/constants';
import {LoansDataProps, IDateLoan} from '@components/PersonalLoans/interfaces';

export const PersonalDataLoan = ({
    control,
    setValue,
    register,
    errors,
    nationSelected,
    setNationSelected,
}: LoansDataProps) => {
    const converDate = (e: IDateLoan) => {
        if (e && e.c) {
            const date = DateTime.utc(e.c.year, e.c.month, e.c.day, e.c.hour, e.c.minute).toISO();
            setValue('birthdate', date);
        }
    };

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="auto" Gap="1rem">
            <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" oFlow="none">
                {PERSONALDATA.ABOUT}
            </Text>
            <ContainerFlex Justify="start" FlexWrap="wrap" Align="start" Gap="1rem">
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {PERSONALDATA.NAME}
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('name')}
                        error={errors.name?.message}
                    />
                    {errors?.name?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.name?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        Apellidos
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('lastNames')}
                        error={errors.lastNames?.message}
                    />
                    {errors?.lastNames?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.lastNames?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.CURP}
                    </Text>
                    <Input
                        maxLength={18}
                        Height="40px"
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('curp')}
                        error={errors.curp?.message}
                    />
                    {errors?.curp?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.curp?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Width="10.5rem"
                    Height="auto"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.BIRTHDAY}
                    </Text>{' '}
                    <Controller
                        name={`birthdate`}
                        control={control}
                        render={({field: {onChange, ref, value, ...field}}) => (
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePickerCustom
                                    mask="__/__/____"
                                    {...field}
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        converDate(e as IDateLoan);
                                    }}
                                    maxDate={
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate() + 0
                                        )
                                    }
                                    components={{OpenPickerIcon: DateIcon}}
                                    renderInput={({inputRef, inputProps, InputProps}) => (
                                        <CalendarFieldComponent
                                            error={errors.birthdate?.message ? true : false}
                                            Margin="0 0 8px 0"
                                            Width="150px"
                                        >
                                            <InputCalendarField
                                                ref={inputRef}
                                                {...inputProps}
                                                Width="115px"
                                            />
                                            <CalendarIconContainer>
                                                {InputProps?.endAdornment}
                                            </CalendarIconContainer>
                                        </CalendarFieldComponent>
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                    {errors?.birthdate?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.birthdate?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Width="21.875rem"
                    Height="auto"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.PHONE}
                    </Text>
                    <PhoneFormatInput
                        control={control}
                        name="phone"
                        handleNationSelected={setNationSelected}
                    />
                    {errors?.phone?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.phone?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
