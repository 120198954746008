export const GET_USER_VALIDATION_ADMIN = 'GET_USER_VALIDATION_ADMIN';
export const GET_USER_VALIDATION_ADMIN_SUCCESS = 'GET_USER_VALIDATION_ADMIN_SUCCESS';
export const GET_USER_VALIDATION_ADMIN_ERROR = 'GET_USER_VALIDATION_ADMIN_ERROR';

export const GET_USER_AUTHENTICATION_ADMIN = 'GET_USER_AUTHENTICATION_ADMIN';
export const GET_USER_AUTHENTICATION_ADMIN_SUCCESS = 'GET_USER_AUTHENTICATION_ADMIN_SUCCESS';
export const GET_USER_AUTHENTICATION_ADMIN_ERROR = 'GET_USER_AUTHENTICATION_ADMIN_ERROR';

export const POST_VALIDATE_USER = 'POST_VALIDATE_USER';
export const POST_VALIDATE_USER_SUCCESS = 'POST_VALIDATE_USER_SUCCESS';
export const POST_VALIDATE_USER_ERROR = 'POST_VALIDATE_USER_ERROR';

export const POST_CREATE_USER = 'POST_CREATE_USER';
export const POST_CREATE_USER_SUCCESS = 'POST_CREATE_USER_SUCCESS';
export const POST_CREATE_USER_ERROR = 'POST_CREATE_USER_ERROR';

export const STATE_LOGIN_MAIL = 'POST_VALIDATE_USER';
export const STATE_LOGIN_PASS = 'POST_VALIDATE_USER';
export const STATE_ERROR = 'STATE_ERROR';

export const USERNAME_SELECT = 'USERNAME_SELECT';
export const USERNAME_SELECTED = 'USERNAME_SELECT';

export const USER_PASSWORD = 'USER_PASSWORD';
export const PASS = 'PASS';
export const USER_DATA = 'USER_DATA';

export const MAIL_NEW = 'MAIL_NEW';
export const PASS_NEW = 'PASS_NEW';
export const ORGANIZATION_NEW = 'ORGANIZATION_NEW';

export const LOGINS_SCREEN = 'LOGINS_SCREEN';
export const STATUS_TOKEN = 'STATUS_TOKEN';
export const STATUS_TOKEN_SUCCESS = 'STATUS_TOKEN_SUCCESS';
export const STATUS_TOKEN_ERROR = 'STATUS_TOKEN_ERROR';

export const DELETE_TOKEN = 'DELETE_TOKEN';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_ERROR = 'DELETE_TOKEN_ERROR';

export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

export const POST_FORGOT_PASSWORD = 'POST_FORGOT_PASSWORD';
export const POST_FORGOT_PASSWORD_SUCCESS = 'POST_FORGOT_PASSWORD_SUCCESS';
export const POST_FORGOT_PASSWORD_ERROR = 'POST_FORGOT_PASSWORD_ERROR';

export const PUT_RESET_PASSWORD = 'PUT_RESET_PASSWORD';
export const PUT_RESET_PASSWORD_SUCCESS = 'PUT_RESET_PASSWORD_SUCCESS';
export const PUT_RESET_PASSWORD_ERROR = 'PUT_RESET_PASSWORD_ERROR';

export const STATECLOSESESSION = 'STATECLOSESESSION';

export const GET_LOGIN_PROBLEMS = 'GET_LOGIN_PROBLEMS';
export const GET_LOGIN_PROBLEMS_SUCCESS = 'GET_LOGIN_PROBLEMS_SUCCESS';
export const GET_LOGIN_PROBLEMS_ERROR = 'GET_LOGIN_PROBLEMS_ERROR';

export const POST_CREATE_PROBLEMS = 'POST_CREATE_PROBLEMS';
export const POST_CREATE_PROBLEMS_SUCCESS = 'POST_CREATE_PROBLEMS_SUCCESS';
export const POST_CREATE_PROBLEMS_ERROR = 'POST_CREATE_PROBLEMS_ERROR';
export const POST_CREATE_PROBLEMS_RESET = 'POST_CREATE_PROBLEMS_RESET';

export const ADMIN = {
    PASS_NEW: 'PASS_NEW',
    STATE_ERROR: 'STATE_ERROR',
    LOGINS_SCREEN: 'LOGINS_SCREEN',
    RESETLOGINSTATE: 'RESETLOGINSTATE',
    USERNAME_SELECT: 'USERNAME_SELECT',
    ORGANIZATION_NEW: 'ORGANIZATION_NEW',
    STATE_VERIFY_MAIL: 'STATE_VERIFY_MAIL',
    STATE_LOGIN_MAIL: 'POST_VALIDATE_USER',
    STATE_LOGIN_PASS: 'POST_VALIDATE_USER',
};
