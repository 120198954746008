import axios from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

import {
    GET_AUCTIONS,
    GET_AUCTIONS_SUCCESS,
    GET_AUCTIONS_ERROR,
    GET_IMAGES_AUCTIONS,
    GET_IMAGES_AUCTIONS_SUCCESS,
    GET_IMAGES_AUCTIONS_ERROR,
    GET_AUDIT_AUCTIONS,
    GET_AUDIT_AUCTIONS_SUCCESS,
    GET_AUDIT_AUCTIONS_ERROR,
    POST_AUDIT_INCIDENTS,
    POST_AUDIT_INCIDENTS_SUCCESS,
    POST_AUDIT_INCIDENTS_ERROR,
    DELETE_AUDIT_AUCTIONS,
    DELETE_AUDIT_AUCTIONS_SUCCESS,
    DELETE_AUDIT_AUCTIONS_ERROR,
    RESET_AUDIT_ACTIONS,
    INFO_AUDIT_INCIDENT,
    INFO_AUDIT_INCIDENT_SUCCESS,
    INFO_AUDIT_INCIDENT_ERROR,
} from '@TypesAuction/AuctionTypes';
import StringUtils from '@GenericScripts/utils';
import {Audit, AuditIncident, DeleteAuction} from '@/components/Foundry/interfaces';

export const fetchGetAuctions = () => {
    return {
        type: GET_AUCTIONS,
    };
};
export const fetchGetAuctionsSuccess = (result: {data: unknown}) => {
    return {
        type: GET_AUCTIONS_SUCCESS,
        value: result,
    };
};

export const fetchGetAuctionsError = () => {
    return {
        type: GET_AUCTIONS_ERROR,
    };
};
export function getAuctions(sku: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAuctions());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Auctions.replace(
                    '{sku}',
                    sku
                )}`,
                {headers}
            );
            dispatch(fetchGetAuctionsSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAuctionsError());
        }
    };
}

export const fetchGetImagesAuctions = () => {
    return {
        type: GET_IMAGES_AUCTIONS,
    };
};
export const fetchGetImagesAuctionsSuccess = (result: {data: unknown}) => {
    return {
        type: GET_IMAGES_AUCTIONS_SUCCESS,
        value: result,
    };
};

export const fetchGetImagesAuctionsError = () => {
    return {
        type: GET_IMAGES_AUCTIONS_ERROR,
    };
};
export function getImagesAuctions(sku: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetImagesAuctions());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.ImagesAuctions.replace(
                    '{sku}',
                    sku
                )}`,
                {headers}
            );
            dispatch(fetchGetImagesAuctionsSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetImagesAuctionsError());
        }
    };
}

export const fetchGetAuditAuctions = () => {
    return {
        type: GET_AUDIT_AUCTIONS,
    };
};
export const fetchGetAuditAuctionsSuccess = (result: {data: unknown}) => {
    return {
        type: GET_AUDIT_AUCTIONS_SUCCESS,
        value: result,
    };
};

export const fetchGetAuditAuctionsError = () => {
    return {
        type: GET_AUDIT_AUCTIONS_ERROR,
    };
};
export function getAuditAuctions(filters: Audit, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString({...filters});
        dispatch(fetchGetAuditAuctions());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AuditAuctions}${queryString}`,
                {headers}
            );
            dispatch(fetchGetAuditAuctionsSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAuditAuctionsError());
        }
    };
}

export const fetchPostAuditIncidents = () => {
    return {
        type: POST_AUDIT_INCIDENTS,
    };
};
export const fetchPostAuditIncidentsSuccess = () => {
    return {
        type: POST_AUDIT_INCIDENTS_SUCCESS,
    };
};

export const fetchPostAuditIncidentsError = () => {
    return {
        type: POST_AUDIT_INCIDENTS_ERROR,
    };
};
export function postAuditIncidents(
    data: {idSucursal: string; idAsignacion: string},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostAuditIncidents());

        axios
            .post(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.PostAudit}`, data, {
                headers,
            })
            .then((result) => {
                dispatch(fetchPostAuditIncidentsSuccess());
                dispatch(resetAuditAuctions());
                window.location.href = `/Fundiciones/Recoleccion/${data.idSucursal}/${data.idAsignacion}`;
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Guarda auditoria',
                });
                dispatch(fetchPostAuditIncidentsError());
            });
    };
}

export const fetchDeleteAuditAuctions = () => {
    return {
        type: DELETE_AUDIT_AUCTIONS,
    };
};
export const fetchDeleteAuditAuctionsSuccess = () => {
    return {
        type: DELETE_AUDIT_AUCTIONS_SUCCESS,
    };
};

export const fetchDeleteAuditAuctionsError = () => {
    return {
        type: DELETE_AUDIT_AUCTIONS_ERROR,
    };
};
export function deleteAuditAuctions(data: DeleteAuction, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeleteAuditAuctions());
        try {
            await axios.delete(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.DeleteAudit}`,
                {data: data, headers}
            );
            dispatch(fetchDeleteAuditAuctionsSuccess());
            Swal.fire({
                icon: 'success',
                title: 'Cancelar asignación',
                text: '¡La auditoria ha sido Cancelada con éxito!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Cancelar asignación',
            });
            dispatch(fetchDeleteAuditAuctionsError());
        }
    };
}

export const fetchResetAuditAuctions = () => {
    return {
        type: RESET_AUDIT_ACTIONS,
    };
};
export function resetAuditAuctions() {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchResetAuditAuctions());
    };
}

export const fetchGetInfoAudit = () => {
    return {
        type: INFO_AUDIT_INCIDENT,
    };
};
export const fetchGetInfoAuditSuccess = (result: {idTipoIncidencia: number}) => {
    return {
        type: INFO_AUDIT_INCIDENT_SUCCESS,
        value: result,
        tipoincidencia: result.idTipoIncidencia,
    };
};

export const fetchGetInfoAuditError = () => {
    return {
        type: INFO_AUDIT_INCIDENT_ERROR,
    };
};
export function InfoAuditIncident(data: AuditIncident) {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetInfoAudit);
        dispatch(fetchGetInfoAuditSuccess(data));
    };
}
