import axios, {AxiosResponse} from 'axios';
import {
    GET_SEARCH_BRANCHES,
    GET_SEARCH_BRANCHES_ERROR,
    GET_SEARCH_BRANCHES_SUCCESS,
} from '@components/Branches/Redux/Types/types';

import {URL} from '@config/constants/index';
import {IFilters} from '@components/Branches/interfaces';
import {AppDispatch} from '@/config/store';

export const fetchGetSearchBranchesStart = () => {
    return {
        type: GET_SEARCH_BRANCHES,
    };
};
export const fetchGetSearchBranchesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_SEARCH_BRANCHES_SUCCESS,
        payload: result,
    };
};
export const fetchGetSearchBranchesError = () => {
    return {
        type: GET_SEARCH_BRANCHES_ERROR,
    };
};

export function GetSearchBranches(filters: IFilters, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSearchBranchesStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllBranches}`,
                filters,
                {headers}
            );
            dispatch(fetchGetSearchBranchesSuccess(response));
        } catch (error) {
            dispatch(fetchGetSearchBranchesError());
        }
    };
}
