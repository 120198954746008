import {SEARCHING_OPERATIONS} from '@Shopify/ClientProfile/Redux/types';

const initialState = {
    loading: false,
};

const searchOperations = (state = initialState, action: {type: string; payload: boolean}) => {
    switch (action.type) {
        case SEARCHING_OPERATIONS.OPERATION_SEARCHING:
            return {...state, loading: action.payload};
        default:
            return state;
    }
};
export default searchOperations;
