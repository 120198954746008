export const CONFIG_MENU = [
    {name: 'Personaliza y crea ubicaciones y sucursales para tu empresa', id: 1},
    {name: 'Crea usuarios que administren y operen tu empresa', id: 2},
    {name: 'Completa tu perfil', id: 3},
    {name: 'Completa la información de tu empresa', id: 4},
];

export const COSTS_BAR = [
    {
        name: 'Funciones',
        id: 1,
        cost: 45000,
        description:
            'Cotiza tus prendas de forma fácil y rápida, verifica costos y mete estados de tu prenda para poder evaluar su costo y dar el precio justo.',
    },
    {
        name: 'Auditoria',
        id: 2,
        cost: 45000,
        description:
            'Cotiza tus prendas de forma fácil y rápida, verifica costos y mete estados de tu prenda para poder evaluar su costo y dar el precio justo.',
    },
    {
        name: 'Reactivaciones',
        id: 3,
        cost: 45000,
        description:
            'Cotiza tus prendas de forma fácil y rápida, verifica costos y mete estados de tu prenda para poder evaluar su costo y dar el precio justo.',
    },
];

export const ENTERPRICE_OUTPUTS = {
    Title: 'Entradas y salidas',
    Text: 'Aquí encontrarás información útil sobre las entradas y salidas de dinero.',
};

export const RECENT_ACTIVITY = {
    Title: 'Actividad reciente',
    Text: 'Aquí encontrarás información sobre los cambios que otros usuarios hayan hecho.',
};

export const COMPANY_REINFORCENMENT = {
    Title: 'Refuerza tu empresa con complementos poderosos',
    Text: 'Explorar todos',
};

export const PAYMENT_METHOD_TEXT = {
    WithoutPayment: 'Sin método de pago',
    FreeTrialPrompt: 'Tu prueba gratis termina en 29 días',
    Enter: 'Ingresar',
};
