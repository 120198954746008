import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {TermAndConditions} from '@Login/MarketPlaceLogin/TermsAndConditions';
import {CodeVerification} from '@/components/MarketLogin/moldulos/Validation';
import {AtaskateHeader} from '@components/Login/MarketPlaceLogin/Ataskateheader';

export const AccountContainer = () => {
    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <AtaskateHeader />
            <ContainerFlex backG="#E5E5FF" FlexDir="column" Width="50%">
                <CodeVerification />
                <TermAndConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
