export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';

export const GET_BRANCH_START = 'GET_BRANCH_START';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';

export const GET_CONTAINER_START = 'GET_CONTAINER_START';
export const GET_CONTAINER_SUCCESS = 'GET_CONTAINER_SUCCESS';
export const GET_CONTAINER_ERROR = 'GET_CONTAINER_ERROR';

export const POST_ARRAY_EDIT = 'POST_ARRAY_EDIT';
export const POST_ARRAY_SUCCESS = 'POST_ARRAY_SUCCESS';
export const POST_ARRAY_ERROR = 'POST_ARRAY_ERROR';

export const POST_WAREHOUSE_START = 'POST_WAREHOUSE_START';
export const POST_WAREHOUSE_SUCCESS = 'POST_WAREHOUSE_SUCCESS';
export const POST_WAREHOUSE_ERROR = 'POST_WAREHOUSE_ERROR';

export const DATA_SEARCH = 'DATA_SEARCH';

export const GET_CITY_START = 'GET_CITY_START';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_ERROR = 'GET_CITY_ERROR';

export const GET_ORGANIZATION_START = 'GET_ORGANIZATION_START';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const GET_COUNTCONTAINER_START = 'GET_COUNTCONTAINER_START';
export const GET_COUNTCONTAINER_SUCCESS = 'GET_COUNTCONTAINER_SUCCESS';
export const GET_COUNTCONTAINER_ERROR = 'GET_COUNTCONTAINER_ERROR';

export const OBJECT_BRANCH = 'OBJECT_ BRANCH';
export const LOCATIONS_IDCONTAINER = 'LOCATIONS_IDCONTAINER';
export const DATA_CONTAINERS = 'DATA_CONTAINERS';

export const GET_RACK_START = 'GET_RACK_START';
export const GET_RACK_SUCCESS = 'GET_RACK_SUCCESS';
export const GET_RACK_ERROR = 'GET_RACK_ERROR';
export const POST_RACK_START = 'POST_RACK_START';
export const POST_RACK_SUCCESS = 'POST_RACK_SUCCESS';
export const POST_RACK_ERROR = 'POST_RACK_ERROR';
export const PUT_RACK_START = 'PUT_RACK_START';
export const PUT_RACK_SUCCESS = 'PUT_RACK_SUCCESS';
export const PUT_RACK_ERROR = 'PUT_RACK_ERROR';

export const GET_FLOOR_START = 'GET_FLOOR_START';
export const GET_FLOOR_SUCCESS = 'GET_FLOOR_SUCCESS';
export const GET_FLOOR_ERROR = 'GET_FLOOR_ERROR';
export const POST_FLOOR_START = 'POST_FLOOR_START';
export const POST_FLOOR_SUCCESS = 'POST_FLOOR_SUCCESS';
export const POST_FLOOR_ERROR = 'POST_FLOOR_ERROR';
export const PUT_FLOOR_START = 'PUT_FLOOR_START';
export const PUT_FLOOR_SUCCESS = 'PUT_FLOOR_SUCCESS';
export const PUT_FLOOR_ERROR = 'PUT_FLOOR_ERROR';

export const GET_PENSION_START = 'GET_PENSION_START';
export const GET_PENSION_SUCCESS = 'GET_PENSION_SUCCESS';
export const GET_PENSION_ERROR = 'GET_PENSION_ERROR';
export const POST_PENSION_START = 'POST_PENSION_START';
export const POST_PENSION_SUCCESS = 'POST_PENSION_SUCCESS';
export const POST_PENSION_ERROR = 'POST_PENSION_ERROR';
export const PUT_PENSION_START = 'PUT_PENSION_START';
export const PUT_PENSION_SUCCESS = 'PUT_PENSION_SUCCESS';
export const PUT_PENSION_ERROR = 'PUT_PENSION_ERROR';

export const GET_VAULT_START = 'GET_VAULT_START';
export const GET_VAULT_SUCCESS = 'GET_VAULT_SUCCESS';
export const GET_VAULT_ERROR = 'GET_VAULT_ERROR';
export const POST_VAULT_START = 'POST_VAULT_START';
export const POST_VAULT_SUCCESS = 'POST_VAULT_SUCCESS';
export const POST_VAULT_ERROR = 'POST_VAULT_ERROR';
export const PUT_VAULT_START = 'PUT_VAULT_START';
export const PUT_VAULT_SUCCESS = 'PUT_VAULT_SUCCESS';
export const PUT_VAULT_ERROR = 'PUT_VAULT_ERROR';

export const GET_CONTAINERC_START = 'GET_CONTAINERC_START';
export const GET_CONTAINERC_SUCCESS = 'GET_CONTAINERC_SUCCESS';
export const GET_CONTAINERC_ERROR = 'GET_CONTAINERC_ERROR';

export const GET_CREATECONTAINER_START = 'GET_CREATECONTAINER_START';
export const GET_CREATECONTAINER_SUCCESS = 'GET_CREATECONTAINER_SUCCESS';
export const GET_CREATECONTAINER_ERROR = 'GET_CREATECONTAINER_ERROR';

export const DELETE_RACK_START = 'DELETE_RACK_START';
export const DELETE_RACK_SUCCESS = 'DELETE_RACK_SUCCESS';
export const DELETE_RACK_ERROR = 'DELETE_RACK_ERROR';

export const DELETE_FLOOR_START = 'DELETE_FLOOR_START';
export const DELETE_FLOOR_SUCCESS = 'DELETE_FLOOR_SUCCESS';
export const DELETE_FLOOR_ERROR = 'DELETE_FLOOR_ERROR';

export const DELETE_PESNION_START = 'DELETE_PENSION_START';
export const DELETE_PENSION_SUCCESS = 'DELETE_PENSION_SUCCESS';
export const DELETE_PENSION_ERROR = 'DELETE_PENSION_ERROR';

export const DELETE_VAULT_START = 'DELETE_VAULT_START';
export const DELETE_VAULT_SUCCESS = 'DELETE_VAULT_SUCCESS';
export const DELETE_VAULT_ERROR = 'DELETE_VAULT_ERROR';

export const BRANCH_SAVE = 'BRANCH_SAVE';
export const DELETE_LOCATIONS_LOGOUT = 'DELETE_LOCATIONS_LOGOUT';

export const FLOOR_BY_ID_START = 'FLOOR_BY_ID_START';
export const FLOOR_BY_ID_SUCCESS = 'FLOOR_BY_ID_SUCCESS';
export const FLOOR_BY_ID_ERROR = 'FLOOR_BY_ID_ERROR';

export const PENSION_BY_ID_SUCCESS = 'PENSION_BY_ID_SUCCESS';
export const PENSION_BY_ID_START = 'PENSION_BY_ID_START';
export const PENSION_BY_ID_ERROR = 'PENSION_BY_ID_ERROR';

export const RACK_BY_ID_START = 'RACK_BY_ID_START';
export const RACK_BY_ID_SUCCESS = 'RACK_BY_ID_SUCCESS';
export const RACK_BY_ID_ERROR = 'RACK_BY_ID_ERROR';

export const VOULT_ROWS_START = 'VOULT_ROWS_START';
export const VOULT_ROWS_SUCCESS = 'VOULT_ROWS_SUCCESS';
export const VOULT_ROWS_ERROR = 'VOULT_ROWS_ERROR';
export const VOULT_DRAWERS_START = 'VOULT_DRAWERS_START';
export const VOULT_DRAWERS_SUCCESS = 'VOULT_DRAWERS_SUCCESS';
export const VOULT_DRAWERS_ERROR = 'VOULT_DRAWERS_ERROR';
export const VOULT_SQUABS_START = 'VOULT_SQUABS_START';
export const VOULT_SQUABS_SUCCESS = 'VOULT_SQUABS_SUCCESS';
export const VOULT_SQUABS_ERROR = 'VOULT_SQUABS_ERROR';
