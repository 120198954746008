export const GET_FREQUENCY_PAYMENTS_START = 'GET_FREQUENCY_PAYMENTS_START';
export const GET_FREQUENCY_PAYMENTS_SUCESS = 'GET_FREQUENCY_PAYMENTS_SUCESS';
export const GET_FREQUENCY_PAYMENTS_ERROR = 'GET_FREQUENCY_PAYMENTS_ERROR';

export const SET_FREQUENCY_PAYMENTS_TABLE = 'SET_FREQUENCY_PAYMENTS_TABLE';
export const HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE = 'HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE';

export const HANDLE_CHECKBOX_PAYMENT_METHODS = 'HANDLE_CHECKBOX_PAYMENT_METHODS';

export const HANDLE_ALL_CHARGES_CHECKBOXS = 'HANDLE_ALL_CHARGES_CHECKBOXS';
export const HANDLE_CHARGE_CHECKBOX = 'HANDLE_CHARGE_CHECKBOX';
export const SHOW_EDIT_BUTTONS = 'SHOW_EDIT_BUTTONS';
export const ADD_NEW_CHARGE = 'ADD_NEW_CHARGE';
export const DELETE_CHARGE = 'DELETE_CHARGE';
export const SELECT_CHARGE = 'SELECT_CHARGE';
export const EDIT_CHARGE = 'EDIT_CHARGE';

export const GET_DOCUMENTS_PREDIFINED_OPTIONS_START = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_START';
export const GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS';
export const GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR';
export const ADD_DOCUMENT_NEW_OPTION = 'ADD_DOCUMENT_NEW_OPTION';
export const DELETE_DOCUMENT_NEW_OPTION = 'DELETE_DOCUMENT_NEW_OPTION';

export const GET_DISBURSEMENT_OPTIONS_START = 'GET_DISBURSEMENT_OPTIONS_START';
export const GET_DISBURSEMENT_OPTIONS_SUCCESS = 'GET_DISBURSEMENT_OPTIONS_SUCCESS';
export const GET_DISBURSEMENT_OPTIONS_ERROR = 'GET_DISBURSEMENT_OPTIONS_ERROR';
