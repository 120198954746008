import {
    GET_AUDIT_GOLD,
    GET_AUDIT_GOLD_SUCCESS,
    GET_AUDIT_GOLD_ERROR,
    GET_RECOLECTION_GOLD,
    GET_RECOLECTION_GOLD_SUCCESS,
    GET_RECOLECTION_GOLD_ERROR,
    GET_HEADER_AUDIT_GOLD,
    GET_HEADER_AUDIT_GOLD_SUCCESS,
    GET_HEADER_AUDIT_GOLD_ERROR,
    GET_HEADER_RECOLECTION_GOLD,
    GET_HEADER_RECOLECTION_GOLD_SUCCESS,
    GET_HEADER_RECOLECTION_GOLD_ERROR,
    GET_FILE_NAME,
    GET_FILE_NAME_SUCCESS,
    GET_FILE_NAME_ERROR,
} from '@TypesRecolectionGold/RecolectionGoldTypes';
import {FieldValues} from 'react-hook-form';

const initialState: {
    headerauditgold: FieldValues;
    headerrecolectiongold: FieldValues;
    auditgold: [];
    filename: string;
    recolectiongold: [];
    recolectiongoldcount: number;
    recolectiongoldPageIndex: number;
    auditgoldcount: number;
    auditgoldPageIndex: number;
    error: boolean;
    loading: boolean;
} = {
    headerauditgold: {},
    headerrecolectiongold: {},
    auditgold: [],
    filename: '',
    recolectiongold: [],
    recolectiongoldcount: 0,
    recolectiongoldPageIndex: 0,
    auditgoldcount: 0,
    auditgoldPageIndex: 0,
    error: false,
    loading: false,
};

const assignmentrecolectiongold = (
    state = initialState,
    action: {
        type: string;
        count: number;
        pageIndex: number;
        headerauditgold: FieldValues;
        headerrecolectiongold: FieldValues;
        auditgold: [];
        filename: string;
        recolectiongold: [];
        recolectiongoldcount: number;
        recolectiongoldPageIndex: number;
        auditgoldcount: number;
        auditgoldPageIndex: number;
        error: boolean;
        loading: boolean;
    }
): {
    headerauditgold: FieldValues;
    headerrecolectiongold: FieldValues;
    auditgold: [];
    filename: string;
    recolectiongold: [];
    recolectiongoldcount: number;
    recolectiongoldPageIndex: number;
    auditgoldcount: number;
    auditgoldPageIndex: number;
    error: boolean;
    loading: boolean;
} => {
    switch (action.type) {
        case GET_RECOLECTION_GOLD:
            return {
                ...state,
                loading: true,
            };
        case GET_RECOLECTION_GOLD_SUCCESS:
            return {
                ...state,
                recolectiongold: action.recolectiongold,
                recolectiongoldcount: action.count,
                recolectiongoldPageIndex: action.pageIndex,
                loading: false,
            };
        case GET_RECOLECTION_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_HEADER_RECOLECTION_GOLD:
            return {
                ...state,
                loading: true,
            };
        case GET_HEADER_RECOLECTION_GOLD_SUCCESS:
            return {
                ...state,
                headerrecolectiongold: action.headerrecolectiongold,
                loading: false,
            };
        case GET_HEADER_RECOLECTION_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_HEADER_AUDIT_GOLD:
            return {
                ...state,
                loading: true,
            };
        case GET_HEADER_AUDIT_GOLD_SUCCESS:
            return {
                ...state,
                headerauditgold: action.headerauditgold,
                loading: false,
            };
        case GET_HEADER_AUDIT_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_AUDIT_GOLD:
            return {
                ...state,
                loading: true,
            };
        case GET_AUDIT_GOLD_SUCCESS:
            return {
                ...state,
                auditgold: action.auditgold,
                auditgoldcount: action.count,
                auditgoldPageIndex: action.pageIndex,
                loading: false,
            };
        case GET_AUDIT_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_FILE_NAME:
            return {
                ...state,
                loading: true,
            };
        case GET_FILE_NAME_SUCCESS:
            return {
                ...state,
                filename: action.filename,
                loading: false,
            };
        case GET_FILE_NAME_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default assignmentrecolectiongold;
