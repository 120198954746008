import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@Shopify/Endeavors/styles';
import {pledgeFloor} from '@Locations/interfaces';

export default function FloorProucts(props: {pledgeFloor: pledgeFloor}): JSX.Element {
    const {pledgeFloor} = props;
    return (
        <>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Piso
                </Text>
                <Text FontSize="0.875rem">{pledgeFloor.floorName}</Text>
            </TableItem>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Detalle
                </Text>
                <Text FontSize="0.875rem">{pledgeFloor.detailFloor}</Text>
            </TableItem>
            <TableItem />
            <TableItem />
        </>
    );
}
