import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
interface IButton {
    setView: (e: boolean) => void;
}
export const ContainerButtons = ({setView}: IButton) => {
    return (
        <ContainerFlex ColumnGap="24px" Justify="space-between" Height="40px">
            <ContainerFlex Cursor="pointer" Height="40px" onClick={() => setView(false)}>
                <Text Cursor="pointer" Color="#FF6357">
                    {REQUESTS_ARTICLE.BUTTON_CANCEL}
                </Text>
            </ContainerFlex>
            <ButtonGeneral text={REQUESTS_ARTICLE.BUTTON_ACCEPT} width="100%" type="submit" />
        </ContainerFlex>
    );
};
