import {
    GET_GROUP_NODE,
    GET_GROUP_NODE_NAME,
    RESET_SELECTED,
    SELECTED_LEVEL_NUMBER,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {IDATAFACE} from '@components/RequestNewCatalogLevel/interfacesTree';
export interface ICatSelectedNode {
    catSelectGroups: IDATAFACE[];
    name: string;
    numberLevel: number;
}
const initialState: ICatSelectedNode = {
    catSelectGroups: [
        {
            id: 0,
            nameGroup: '',
            nameFamily: '',
            nameBrand: '',
            nameSubFamily: '',
            name: '',
            status: false,
            markId: 0,
            children: [],
            iconUrl: '',
        },
    ],
    numberLevel: 0,
    name: '',
};

export const getSelectedGroupPath = (
    state = initialState,
    action: {
        type: string;
        error: boolean;
        payload: IDATAFACE[];
        names: string;
        level: number;
    }
) => {
    switch (action.type) {
        case SELECTED_LEVEL_NUMBER:
            return {
                ...state,
                numberLevel: action.level,
            };
        case GET_GROUP_NODE:
            return {
                ...state,
                catSelectGroups: action.payload,
            };
        case GET_GROUP_NODE_NAME:
            return {
                ...state,
                name: action.names,
            };
        case RESET_SELECTED:
            return initialState;

        default:
            return state;
    }
};
