import {
    IChargeAndPenaltyItem,
    IChargesAndPenaltiesReducer,
    IEditedCharge,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {
    HANDLE_ALL_CHARGES_CHECKBOXS,
    HANDLE_CHARGE_CHECKBOX,
    SHOW_EDIT_BUTTONS,
    ADD_NEW_CHARGE,
    SELECT_CHARGE,
    EDIT_CHARGE,
    DELETE_CHARGE,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';

const initialState: IChargesAndPenaltiesReducer = {
    chargesAndPenalties: [
        {
            id: 1,
            typeCharge: {typeChargeId: 0, label: 'Penalidad'},
            chargeName: 'Penalidad por mora',
            chargeAmount: {typeChargeAmountId: 1, amount: 5, label: 'Porcentaje'},
            condition: {conditionId: 1, label: 'Cobrar cada vez que pase el evento'},
            eventType: {eventTypeId: 0, label: 'Este en mora el producto'},
            isChecked: false,
            isShowingEditButton: false,
        },
        {
            id: 2,
            typeCharge: {typeChargeId: 1, label: 'Cargo'},
            chargeName: 'Cargo por apertura',
            chargeAmount: {typeChargeAmountId: 1, amount: 5, label: 'Porcentaje'},
            condition: {conditionId: 0, label: 'Cobrar un valor o porcentaje'},
            eventType: {eventTypeId: 1, label: 'Al inicio del crédito'},
            isChecked: false,
            isShowingEditButton: false,
        },
    ],
    selectedCharge: {
        id: 0,
        typeCharge: {typeChargeId: 0, label: ''},
        chargeName: '',
        chargeAmount: {typeChargeAmountId: 0, amount: 0, label: ''},
        condition: {conditionId: 0, label: ''},
        eventType: {eventTypeId: 0, label: ''},
        isChecked: false,
        isShowingEditButton: false,
    },
};

const setChargesAndPenaltiesTable = (
    state = initialState,
    action: {
        type: string;
        id: number;
        allChecked: boolean;
        newCharge: IChargeAndPenaltyItem;
        selectedChargeAndPenalty: IChargeAndPenaltyItem;
        editedCharge: IEditedCharge;
    }
): IChargesAndPenaltiesReducer => {
    switch (action.type) {
        case HANDLE_ALL_CHARGES_CHECKBOXS: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => ({
                    ...item,
                    isChecked: action.allChecked,
                })),
            };
        }
        case HANDLE_CHARGE_CHECKBOX: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            isChecked: !item.isChecked,
                        };
                    }
                    return item;
                }),
            };
        }
        case SHOW_EDIT_BUTTONS: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === action.id) {
                        return {...item, isShowingEditButton: !item.isShowingEditButton};
                    }
                    return {...item, isShowingEditButton: false};
                }),
            };
        }
        case ADD_NEW_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: [...state.chargesAndPenalties, action.newCharge],
            };
        }
        case SELECT_CHARGE: {
            return {...state, selectedCharge: action.selectedChargeAndPenalty};
        }
        case EDIT_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === state.selectedCharge.id) {
                        return {...item, ...action.editedCharge};
                    }
                    return item;
                }),
            };
        }
        case DELETE_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.filter(
                    (item) => item.id !== state.selectedCharge.id
                ),
            };
        }
        default:
            return state;
    }
};

export default setChargesAndPenaltiesTable;
