import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {useClientSort} from '@/hooks/useClientSort';

import {ContainerFlex, Text, TextEllipsis} from '@EcommerceShopify/styles';
import {Table} from '@DeadLines/styles';
import {IconContainer} from '@/components/Quoter/styles';

import {ClientTableHeaderText, ClientSort} from '@components/Shopify/ListClient/constants';
import {TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItems, TableItemsContainer} from '@DeadLines/styles';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {clientCard, TableCardProps} from '@Shopify/ListClient/interfaces';
import {ClientDefaultPrompts, getStyle} from '@components/Shopify/ListClient/constants';
import {saveIdClient} from '@Shopify/ClientProfile/Redux/Action/CardProfileById';
import {useNavigate} from 'react-router-dom';

export function TableCard(props: TableCardProps) {
    const {onSortChange, sortConfig: initialSortConfig} = props;
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const AllClientsHome: clientCard = useSelector((state: RootState) => state.getClient);

    const selectClient = async (idClient: number) => {
        await dispatch(saveIdClient(idClient));
        navigate(`/Clients/ClientProfile`);
    };

    const {requestSort, isSortingActive} = useClientSort(initialSortConfig, onSortChange);

    function renderIconContainer(column: string, type: string) {
        return (
            <IconContainer
                className="material-icons"
                FontSize="1rem"
                Color={isSortingActive(column, type) ? '#5A5AFF' : '#b3b1b6'}
                Width="5%"
                Height="9px"
                FontWeight="600"
                Position="relative"
                Top="34%"
                Left="4%"
            >
                {type === ClientSort.Asc ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </IconContainer>
        );
    }

    return (
        <ContainerFlex FlexDir="column" Gap="1rem" Height="auto">
            <Table>
                <TableRowHead
                    BackG="none"
                    Padding="0px 0px 0px 15px"
                    GridColumn="1.5fr 1.5fr 1fr 1fr 1fr"
                >
                    <TableItem Justify="left" onClick={() => requestSort('name')}>
                        <Text FontWeight="500" PaddingR="1rem">
                            {ClientTableHeaderText.ClientName}
                        </Text>
                        <div>
                            {renderIconContainer('name', ClientSort.Asc)}
                            {renderIconContainer('name', ClientSort.Desc)}
                        </div>
                    </TableItem>
                    <TableItem Justify="left" onClick={() => requestSort('email')}>
                        <Text FontWeight="500" PaddingR="1rem">
                            {ClientTableHeaderText.Email}
                        </Text>
                        <div>
                            {renderIconContainer('email', ClientSort.Asc)}
                            {renderIconContainer('email', ClientSort.Desc)}
                        </div>
                    </TableItem>
                    <TableItem FontWeight="500" Justify="left" Color="#54575C">
                        {ClientTableHeaderText.Phone}
                    </TableItem>
                    <TableItem Justify="left" onClick={() => requestSort('score')}>
                        <Text FontWeight="500" PaddingR="1rem">
                            {ClientTableHeaderText.Score}
                        </Text>
                        <div>
                            {renderIconContainer('score', ClientSort.Asc)}
                            {renderIconContainer('score', ClientSort.Desc)}
                        </div>
                    </TableItem>
                    <TableItem />
                </TableRowHead>
                {AllClientsHome.loading && (
                    <ContainerFlex Height="40vh">
                        <LoadingGeneral />
                    </ContainerFlex>
                )}

                {!AllClientsHome.loading &&
                    AllClientsHome.clients.data &&
                    AllClientsHome.clients.data.map((item, index: number) => {
                        const scoreStyle = getStyle(item.score);

                        return (
                            <TableItemsContainer key={index}>
                                <TableRowItems
                                    onClick={() => selectClient(item.id)}
                                    Height="40px"
                                    Padding="0 0.5rem"
                                    GridColumn="1.5fr 1.5fr 1fr 1fr 1fr"
                                >
                                    <TableItems Padding="0 0 0 8px" Justify="flex-start">
                                        <TextEllipsis
                                            Color="#5A5AFF"
                                            FontSize="0.875rem"
                                            FontWeight="400"
                                            Transform="capitalize"
                                            MaxCharacters={25}
                                        >
                                            {item.name}
                                        </TextEllipsis>
                                    </TableItems>
                                    <TableItems Justify="left" title={item.email}>
                                        <TextEllipsis
                                            Color="#5A5AFF"
                                            FontSize="0.875rem"
                                            FontWeight="400"
                                            MaxCharacters={25}
                                        >
                                            {item.email}
                                        </TextEllipsis>
                                    </TableItems>
                                    <TableItems Justify="left">
                                        <TextEllipsis
                                            Color="#54575C"
                                            FontSize="0.875rem"
                                            FontWeight="400"
                                            MaxCharacters={10}
                                        >
                                            {item.phoneNumber}
                                        </TextEllipsis>
                                    </TableItems>
                                    <TableItems Justify="left">
                                        <ContainerFlex
                                            Justify="flex-start"
                                            Radius="14px"
                                            Padding="0 0.5rem"
                                            Height="20px"
                                            Width="85px"
                                            Border={scoreStyle.border}
                                            backG={scoreStyle.backgroundColor}
                                        >
                                            <Text
                                                FontWeight="500"
                                                Margin="auto"
                                                FontSize="12px"
                                                Color={scoreStyle.color}
                                            >
                                                {item.score}
                                            </Text>
                                        </ContainerFlex>
                                    </TableItems>
                                    <TableItems Justify="left">
                                        <ContainerFlex
                                            Justify="flex-start"
                                            Padding="0 1rem"
                                            Height="24px"
                                            Width="auto"
                                        >
                                            <Text
                                                FontWeight="500"
                                                FontSize=".875rem"
                                                Padding="0 0 0 8px"
                                                Color={item.contractExpiration ? '#ff5f52' : ''}
                                                TextWrap="wrap"
                                            >
                                                {item.contractExpiration
                                                    ? item.contractExpiration
                                                    : '-'}
                                            </Text>
                                        </ContainerFlex>
                                    </TableItems>
                                </TableRowItems>
                            </TableItemsContainer>
                        );
                    })}

                {AllClientsHome.error && (
                    <ContainerFlex Height="40vh" FlexDir="column">
                        <Text FontSize="0.875rem" FontWeight="500">
                            {ClientDefaultPrompts.NoRecordsFound}
                        </Text>
                        <Text FontSize="0.75rem">{ClientDefaultPrompts.ModifyParams}</Text>
                    </ContainerFlex>
                )}
            </Table>
        </ContainerFlex>
    );
}
