import {NEW_STORAGE} from '@components/LocationsBranch/Redux/types';
import {INewStorage, INewStorageRedux} from '@components/LocationsBranch/interfaces';

const initialState: INewStorage = {
    error: false,
    loading: false,
    newStorage: null,
    editStorage: null,
};

export const newStorage = (state = initialState, action: INewStorageRedux): INewStorage => {
    switch (action.type) {
        case NEW_STORAGE.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case NEW_STORAGE.SUCCESS:
            return {
                ...state,
                loading: false,
                newStorage: action.newStorage,
            };
        case NEW_STORAGE.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case NEW_STORAGE.GET_STORAG_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case NEW_STORAGE.GET_STORAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                editStorage: action.newStorage,
            };
        case NEW_STORAGE.GET_STORAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
