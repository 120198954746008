import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';
import axios from 'axios';

import {URL} from '@config/constants/index';

export const ACTION_TYPES_CUSTOMER = {
    GetCustomer: 'GET_CUSTOMER',
    Position: 'POSITION',
    SelectUser: 'SELECT_USER',
    CleanUser: 'CLEAN_USER',
    BooleanSelect: 'BOOLEAN_SELECT',
    ErrorCustomer: 'ERROR_CUSTOMER',
};
export const GetCustomer = (value: FieldValues) => {
    return {
        type: ACTION_TYPES_CUSTOMER.GetCustomer,
        payload: value,
    };
};
export const SelectUser = (value: FieldValues) => {
    return {
        type: ACTION_TYPES_CUSTOMER.SelectUser,
        payload: value,
    };
};
export const CleanUser = () => {
    return {
        type: ACTION_TYPES_CUSTOMER.CleanUser,
    };
};

export const ErrorCustomer = (message: string) => {
    return {
        type: ACTION_TYPES_CUSTOMER.ErrorCustomer,
        message: message,
    };
};
export const Customer = (token: string, user: string, setState: {(e: boolean): void}) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        return axios
            .get(
                `${process.env.REACT_APP_SERVICE_LOAN}${URL.urlLoan.SearchClient.replace(
                    '{SEARCH}',
                    user
                )}`,
                {headers}
            )
            .then((response) => {
                dispatch(GetCustomer(response.data));
                setState(false);
            })
            .catch((error) => {
                dispatch(ErrorCustomer(error.massage));
            });
    };
};
