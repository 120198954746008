import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    RESET_ROUTE_CATALOG,
    SAVE_ROUTE_CATALOG,
    TARGET_CATALOG_ID,
    UPDATE_NEWCATALOGID_ERROR,
    UPDATE_NEWCATALOGID_START,
    UPDATE_NEWCATALOGID_SUCCESS,
} from '@components/Articles/Redux/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IAddRoute, IUse} from '@/components/Articles/Redux/interfaces';
import {FieldValues} from 'react-hook-form';
import {ListCatalogReset} from '@components/Articles/Redux/Actions/GetNewCatalogId';
import {fetchInfoListReset} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {resetDynamicFeatures} from '@/components/Catalogue/Redux/Actions/SaveNewDynamicFeatureActions';
export const fetchUpdateCatalogIdStart = () => {
    return {
        type: UPDATE_NEWCATALOGID_START,
    };
};
export const fetchUpdateCatalogIdSuccess = (result: AxiosResponse) => {
    return {
        type: UPDATE_NEWCATALOGID_SUCCESS,
        payload: result,
    };
};
export const fetchUpdateCatalogIdError = (error: AxiosError) => {
    return {
        type: UPDATE_NEWCATALOGID_ERROR,
        error: error,
    };
};

export function UpdateCatalogIdForm(token: string, data: FieldValues) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUpdateCatalogIdStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateNewCatalogId}
            `,
                data,
                {headers}
            );
            dispatch(fetchUpdateCatalogIdSuccess(response));
            dispatch(ListCatalogReset());
            dispatch(fetchRouteInfoReset());
            dispatch(fetchInfoListReset());
            dispatch(resetDynamicFeatures());
            window.location.href = '#/Management/Articles';
        } catch (error) {
            dispatch(fetchUpdateCatalogIdError(error as AxiosError));
        }
    };
}

export const fetchUpdateLabelTarget = (value: IUse) => {
    return {
        type: TARGET_CATALOG_ID,
        value: value,
    };
};

export const fetchRouteSelect = (value: IAddRoute) => {
    return {
        type: SAVE_ROUTE_CATALOG,
        date: value,
    };
};

export const fetchRouteInfoReset = () => {
    return {
        type: RESET_ROUTE_CATALOG,
    };
};
