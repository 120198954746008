import React from 'react';
import {useSelector} from 'react-redux';
import {TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {TableItem} from '@components/DeadLines/styles';
import {TableRowItems} from '@components/CashFlow/FundingInquiry/styles';
import {RootState} from '@/config/store';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {IUserBranch} from '@/components/Branches/interfaces';

export const TableUserBranch = () => {
    const branchUsers = useSelector((state: RootState) => {
        return state.GetBranchUsers?.branchUsers?.data;
    });
    return (
        <>
            {branchUsers.length !== 0 ? (
                branchUsers.map((item: IUserBranch, i: number) => (
                    <TableRowItems
                        key={i}
                        GridColumn="1.5fr 2.5fr 1fr"
                        Cursor="auto"
                        Height="48px"
                        MinH="48px"
                        Width="100%"
                        BorderRadius="4px"
                    >
                        <TableItem Padding="0 0 0 16px" Justify="start" TextAlign="left">
                            <TextEllipsis FontSize="0.875rem" FontWeight="500">
                                {item.userName}
                            </TextEllipsis>
                        </TableItem>
                        <TableItem Padding="0 0 0 16px" Justify="start" TextAlign="left">
                            <TextEllipsis FontSize="0.875rem" FontWeight="400" Width="300px">
                                {item.totalRole}
                            </TextEllipsis>
                        </TableItem>
                        <TableItem Justify="center">
                            <GreenSwitch checked={item.status} size="small" />
                        </TableItem>
                    </TableRowItems>
                ))
            ) : (
                <TableRowItems
                    GridColumn="100%"
                    Cursor="auto"
                    Height="48px"
                    MinH="48px"
                    Width="100%"
                    BorderRadius="4px"
                >
                    <TableItem>
                        <TextEllipsis FontSize="0.875rem" FontWeight="500" Width="100%">
                            No hay coincidencias
                        </TextEllipsis>
                    </TableItem>
                </TableRowItems>
            )}
        </>
    );
};
