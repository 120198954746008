import {REMOVE_ROLE} from '@Roles/RemoveRole/Redux/Types';
import {IRemoveRole, IRemoveRoleRedux} from '@/components/Roles/RemoveRole/interfaces';

const initialState: IRemoveRole = {
    removeRole: null,
    error: false,
    loading: false,
};

export const removeRole = (state = initialState, action: IRemoveRoleRedux): IRemoveRole => {
    switch (action.type) {
        case REMOVE_ROLE.REMOVE_ROL_START:
            return {...state, loading: true, error: false};
        case REMOVE_ROLE.REMOVE_ROL_SUCCESS:
            return {
                ...state,
                removeRole: action.removeRole,
                loading: false,
                error: false,
            };
        case REMOVE_ROLE.REMOVE_ROL_ERROR:
            return {...state, loading: false, error: false};
        default:
            return state;
    }
};
