import React from 'react';

import * as style from '@/components/Quoter/stylesConstants';
import Warning from '@images/warning-red.svg';
import {MESSAGESEARCH} from '@Quoter/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {IArticleRequest} from '@Quoter/Search/interfaces';

export const MessageError = ({setView}: IArticleRequest) => {
    return (
        <>
            <ContainerFlex {...style.iconWarning}>
                <Image src={Warning} {...style.iconContent} />
                <Text Color="#414042" FontWeight="500">
                    {MESSAGESEARCH.NOFAUNTCAD}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.message}>
                <Text Color="#54575C" FontWeight="500" FontSize="1.25rem">
                    {MESSAGESEARCH.NOSEARCH}
                </Text>
                <Text Color="#71767D" wSpace="normal">
                    {MESSAGESEARCH.WITHOUTCATEGORY}
                </Text>
                <ContainerFlex
                    Width="auto"
                    Border="1px solid #5A5AFF"
                    Radius="32px"
                    onClick={() => setView(true)}
                    Cursor="pointer"
                >
                    <Text FontSize="1rem" Color="#5A5AFF" Padding="8px" Cursor="pointer">
                        {MESSAGESEARCH.BTNSOLICITY}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
