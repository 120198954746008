import {URL} from '@config/constants/index';

import axios from 'axios';
import {
    GET_DATA_CONTACT_COMPANY_START,
    GET_DATA_CONTACT_COMPANY_SUCCESS,
    GET_DATA_CONTACT_COMPANY_ERROR,
} from '@MyCompany/Redux/Types/types';
import {AppDispatch} from '@/config/store';

export const fetchGetContactDataStart = () => {
    return {
        type: GET_DATA_CONTACT_COMPANY_START,
    };
};

export const fetchGetContactDataSuccess = (result: {data: unknown}) => {
    return {
        type: GET_DATA_CONTACT_COMPANY_SUCCESS,
        payload: result.data,
    };
};

export const fetchGetContactDataError = () => {
    return {
        type: GET_DATA_CONTACT_COMPANY_ERROR,
    };
};

export function GetPrincipalContactCompany(token: string, id: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetContactDataStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPrincipalContactCompany}?companyId=${id}`,
                {headers}
            );
            dispatch(fetchGetContactDataSuccess(response));
        } catch (error) {
            dispatch(fetchGetContactDataError());
        }
    };
}
