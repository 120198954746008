import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {RootState, AppDispatch} from '@config/store';
import {IconSvg} from '@General/Atoms/NotificationsList/styles';
import {GetPrincipalContactCompany} from '@MyCompany/Redux/Actions/GetInfoContactCompany';
import {ContactDataTypes} from '@MyCompany/Interface';
import {Label, border} from '@MyCompany/styles';
import {PostEmail} from '@MyCompany/Redux/Actions/PostEmailContact';

export const ContactData = ({
    setContactData,
    setFlowModalChange,
    setModalState,
}: ContactDataTypes) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const infoContact = useSelector((state: RootState) => state.GetPrincipalContactCompany.contact);
    const emailSend = () => {
        dispatch(
            PostEmail(token, {
                contcatInformation: {
                    email: infoContact.principalEmail,
                    phone: infoContact.principalPhone,
                },
            })
        );
        setModalState(false);
    };
    const resUpdate = useSelector((state: RootState) => state.PutDataContact.data);
    const resPost = useSelector((state: RootState) => state.PostDataContactADD.data);
    const numberPhone = (phone: string) => {
        return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
    };
    useEffect(() => {
        dispatch(GetPrincipalContactCompany(token, companyId));
    }, [resUpdate, resPost, dispatch, token, companyId]);
    return (
        <ContainerForm FlexDir="column">
            {infoContact && (
                <>
                    <ContainerFlex
                        {...border}
                        Radius="0 0 4px 4px;"
                        Padding="16px 16px"
                        backG="rgba(243, 243, 243, 0.4)"
                        Height={infoContact.principalEmail?.length > 25 ? 'auto' : '48px'}
                    >
                        {infoContact && (
                            <ContainerFlex Justify="space-between">
                                <Label Color="#414042">• </Label>
                                <Label
                                    Height="auto"
                                    Width="230px"
                                    FontSize="0.875rem"
                                    Color="#414042"
                                    WrapOverFlow="break-word"
                                >
                                    {infoContact.principalEmail}
                                </Label>

                                {infoContact.principalPhone ? (
                                    <Text
                                        Height="16px"
                                        FontSize="0.875rem"
                                        Color="#414042"
                                        MarginLeft="40px"
                                    >
                                        &#8226; {numberPhone(infoContact.principalPhone)}
                                    </Text>
                                ) : (
                                    <Text
                                        FontSize="0.75rem"
                                        Cursor="pointer"
                                        MarginLeft="8px"
                                        Color="#35cf44"
                                        onClick={() => {
                                            setContactData(1);
                                        }}
                                    >
                                        Agregar teléfono +
                                    </Text>
                                )}
                                <Text
                                    FontSize="0.75rem"
                                    Cursor="pointer"
                                    MarginLeft="40px"
                                    Color="#35cf44"
                                    FontWeight="normal"
                                    onClick={() => {
                                        setContactData(1);
                                    }}
                                >
                                    Cambiar{' '}
                                    <IconSvg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6-6 6z"
                                            fill="#35cf44"
                                        />
                                    </IconSvg>
                                </Text>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                    <ContainerFlex Margin="124px" Justify="space-between">
                        <Text
                            FontSize="0.875rem"
                            Cursor="pointer"
                            MarginLeft="8px"
                            Color="#35cf44"
                            onClick={() => {
                                setFlowModalChange(0);
                            }}
                        >
                            Cancelar
                        </Text>
                        <ButtonGeneral
                            width="185px"
                            text="Contactar a un asesor"
                            type="submit"
                            border="none"
                            hColor="#35cf44"
                            transform=""
                            borderHover="solid 1px #a7a9ac"
                            borderDisabled="solid 1px #a7a9ac"
                            bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                            hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                            clic={() => setFlowModalChange(1)}
                            secondaryButton="none"
                        />

                        <ButtonGeneral
                            width="185px"
                            text=" Confirmar mis datos"
                            hColor="#35cf44"
                            transform=""
                            hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                            borderHover="solid 1px #a7a9ac"
                            borderDisabled="solid 1px #a7a9ac"
                            bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                            clic={emailSend}
                            secondaryButton="none"
                        />
                    </ContainerFlex>
                </>
            )}
        </ContainerForm>
    );
};
