import {NEW_BRANCH} from '@components/LocationsBranch/Redux/types';
import {INewBranch, INewBranchRedux} from '@components/LocationsBranch/interfaces';

const initialState: INewBranch = {
    error: false,
    loading: false,
    newBranch: null,
};

export const newBranch = (state = initialState, action: INewBranchRedux): INewBranch => {
    switch (action.type) {
        case NEW_BRANCH.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case NEW_BRANCH.SUCCESS:
            return {
                ...state,
                loading: false,
                newBranch: action.newBranch,
            };
        case NEW_BRANCH.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
