export const JOINTEXTDEADLINE = {
    TITLE: 'Combinar contrato',
    SUBTITLE: 'Arrastra los artículos para agregarlos al contrato que desees.',
    VALUATION: 'Avalúo:',
    LOAN: 'Préstamo:',
    ADD: 'Arrastra artículos para agregarlos a este contrato',
};

export const COOWNEREDIT = {
    TITLE: 'Editar cotitular/beneficiario',
    TITLE_ADD: 'Agrega cotitular/beneficiario',
    NAME: 'Nombre',
    EMAIL: 'Correo',
    PHONE: 'Numero de teléfono',
    ADD: '¿Cómo lo quieres agregar?',
    COOWNER: 'Cotitular',
    BENEFIST: 'Beneficiario',
    DELETE: 'Eliminar contacto',
};
