import * as yup from 'yup';
import {IWareouse} from '@components/LocationsBranch/interfaces';

export const editWarehouseSchema = (warehouses: IWareouse[], initialName: string) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required('Indique el campo')
            .min(3, 'Ingresar por lo menos 3 caracteres')
            .max(20, 'Solo se permiten 20 caracteres')
            .matches(/^[A-Za-z0-9 &\-_\[\]\n\táéíóúÁÉÍÓÚñÑ]*$/, 'Caracteres no permitidos')
            .test('is-valid-name', 'Ya se utilizó este nombre', (value) => {
                return (
                    !warehouses.some((warehouse) => warehouse.name.trim() === value.trim()) ||
                    value.trim() === initialName.trim()
                );
            }),
    });
};
