import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';

export const IndexTree = () => {
    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    return (
        <ContainerFlex
            Width="260px"
            FlexDir="column"
            Border="solid 1px #e5e7e9"
            Justify="start"
            Height="600px"
            Radius="4px"
            Align="start"
        >
            <>
                <Text Height="48px" Padding="16px" FontSize="0.875rem" Color="#414042">
                    Organizaciones
                </Text>
                <ContainerFlex
                    Justify="flex-start"
                    Align="flex-start"
                    Bt="solid 1px #e5e7e9"
                    OverFlow="auto"
                >
                    {TreeComponent.data && TreeComponent.data.length && (
                        <TreeBranch
                            checked={checked}
                            branchesId={branchesId}
                            levelsId={levelsId}
                            branchesData={branchesData}
                            setChecked={setChecked}
                            setBranch={setBranch}
                            setLevel={setLevel}
                            setBranchData={setBranchData}
                            isEdit={false}
                        />
                    )}
                </ContainerFlex>
            </>
        </ContainerFlex>
    );
};
