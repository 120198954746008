import {GET_FLOW_ID, GET_FLOW_ID_SUCCESS, GET_FLOW_ID_ERROR} from '@ReduxsCashFlow/Types/Types';

const initialState = {
    flowBy: [],
    solName: [],
    histor: [],
    data: [],
    loading: false,
    error: false,
};
export interface FlowData {
    flowBy: {
        requestId: number;
        branchId: number;
        userEmployeeId: number;
        date: string;
        requestedAmount: number;
        acceptedAmount: number;
        statusRequest: number;
        motiveId: number;
        observations: string;
        regionId: number;
        historics: Historic[];
        transfers: Transfer[];
    };
    solName: Transfer[];
    histor: Historic[];
    data: {
        tokenExpiration: number;
        succeeded: boolean;
        message: string;
        errors: null;
        token: string;
        data: RequestData[];
    };
    loading: boolean;
    error: boolean;
}
interface ApiResponse {
    tokenExpiration: number;
    succeeded: boolean;
    message: string;
    errors: null;
    token: string;
    data: RequestData[];
}

interface Historic {
    historicId: number;
    historicRequestId: number;
    historicDate: string;
    historicUserId: number;
    historicUserName: string;
    historicObservations: string;
    urlFile: string;
    statusRequestId: number;
    statusRequestName: string;
}

interface Transfer {
    transferId: number;
    transferTypeId: number;
    transferTypeName: string;
    ammount: number;
    withdrawalCode: string;
    transferBranchId: number;
    transferUserId: number;
    transferName: string;
}

interface RequestData {
    requestId: number;
    branchId: number;
    userEmployeeId: number;
    date: string;
    requestedAmount: number;
    acceptedAmount: number;
    statusRequest: number;
    motiveId: number;
    observations: string;
    regionId: number;
    historics: Historic[];
    transfers: Transfer[];
}
const getFlowById = (state = initialState, action: {type: string; payload: ApiResponse}) => {
    switch (action.type) {
        case GET_FLOW_ID:
            return {
                ...state,
                loading: true,
            };
        case GET_FLOW_ID_SUCCESS:
            return {
                ...state,
                flowBy: action.payload.data[0],
                solName: action.payload.data[0].transfers,
                histor: action.payload.data[0].historics,
                data: action.payload,
                loading: true,
            };
        case GET_FLOW_ID_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getFlowById;
