import React from 'react';
import {IInfoContainer} from '@/components/MyAccount/interfaces';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';

const InfoContainer = ({title, value}: IInfoContainer) => {
    return (
        <ContainerFlex Gap="4px" FlexDir="column">
            <Text FontSize="1rem" FontFamily="Nunito" Color="#2A2C2F" LetterSpacing="0.3px">
                {title}
            </Text>
            <Text FontSize="1.5rem" FontFamily="Nunito" FontWeight="700" Color="#1D1E20">
                {value}
            </Text>
        </ContainerFlex>
    );
};

export default InfoContainer;
