import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import debitCardIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/debitCardIcon.svg';
import {PaymentFields} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/PaymentFields';
import {INewPaymentMethodProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';

export const NewPaymentMethod: React.FC<INewPaymentMethodProps> = ({
    isAddingMethod,
    setIsAddingMethod,
}) => {
    return (
        <>
            <ContainerFlex backG="#E8E9EA" Height="1px">
                {''}
            </ContainerFlex>
            <ContainerFlex
                Border="1px solid #5A5AFF"
                Radius="0.5rem"
                Gap="1.5rem"
                Padding="1rem"
                FlexDir="column"
                Align="start"
            >
                <ContainerFlex Gap="0.5rem" Height="max-content" Justify="start" Width="624px">
                    <ContainerFlex Width="44px" Height="44px" Radius="60px" backG="#F4F5F5">
                        <Image src={debitCardIcon} Width="24px" Height="24px" />
                    </ContainerFlex>
                    <Text Width="560px" Height="24px" Color="#2A2C2F" FontWeight="700">
                        {PAYMENT_METHOD.CREDIT_OR_DEBIT}
                    </Text>
                </ContainerFlex>

                <ContainerFlex backG="#E8E9EA" Height="1px">
                    {''}
                </ContainerFlex>

                <PaymentFields
                    isAddingMethod={isAddingMethod}
                    setIsAddingMethod={setIsAddingMethod}
                />
            </ContainerFlex>
        </>
    );
};
