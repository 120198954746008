import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {URL} from '@config/constants/index';

import {STORAGE_TYPES} from '@components/LocationsBranch/Redux/types';
import {
    ILevel,
    IRow,
    IStorage,
    IStorageFloor,
    IWareouse,
} from '@components/LocationsBranch/interfaces';
import {IInventories} from '@components/LocationsBranch/Redux/interfaces';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';

export const addWarehouses = (warehouses: IWareouse[]) => ({
    type: STORAGE_TYPES.NEW_WAREHOUSE,
    payload: {
        warehouses,
    },
});

export const updateWarehouses = (warehouse: IWareouse) => ({
    type: STORAGE_TYPES.UPDATE_WAREHOUSE,
    payload: {
        warehouse,
    },
});

export const getStorageStart = () => ({type: STORAGE_TYPES.START});

export const getStorageSuccess = (result: {data: AxiosResponse}) => ({
    type: STORAGE_TYPES.SUCCESS,
    savedStorage: result.data,
});

export const getStorageError = (error: AxiosError) => ({
    type: STORAGE_TYPES.ERROR,
    error: error,
});

const transformRows = (rows: IInventories[], parentLevelId: number): IRow[] => {
    return rows.map(
        (row): IRow => ({
            name: row.storageName,
            productQuantity: parseInt(row.numberArticles.split(' ')[0], 10),
            parentLevel: parentLevelId,
        })
    );
};

const transformFloors = (levels: IInventories[], parentId: number): IStorageFloor[] => {
    let newFloors = levels.map(
        (level): IStorageFloor => ({
            id: level.storageId,
            name: level.storageName,
            productQuantity: parseInt(level.numberArticles.split(' ')[0], 10),
            levels: transformLevels(level.inventories, level.storageId),
        })
    );

    if (newFloors.length === 0) {
        newFloors = getInitialFloor(1, parentId);
    }

    return newFloors;
};

const transformLevels = (levels: IInventories[], parentLevelId: number): ILevel[] => {
    let newLevels = levels.map(
        (level): ILevel => ({
            id: level.storageId,
            name: level.storageName,
            levelId: 2,
            productQuantity: parseInt(level.numberArticles.split(' ')[0], 10),
            parentLevel: parentLevelId,
            showRowLevelInput: false,
            showNewLevel: false,
            rows: transformRows(level.inventories, level.storageId),
        })
    );

    while (newLevels.length < 3) {
        newLevels = [
            ...newLevels,
            {
                id: newLevels.length + 1,
                name: '',
                levelId: 2,
                productQuantity: 0,
                showRowLevelInput: false,
                showNewLevel: false,
                parentLevel: parentLevelId,
                rows: [],
            },
        ];
    }

    return newLevels;
};

const transformStorageTypes = (inventories: IInventories[], parentLevelId: number): IStorage[] => {
    return inventories.map(
        (inventory): IStorage => ({
            id: inventory.storageId,
            name: inventory.storageName,
            productQuantity: parseInt(inventory.numberArticles.split(' ')[0], 10),
            levelId: 1,
            parentLevel: parentLevelId,
            storageFloor: transformFloors(inventory.inventories, inventory.storageId),
        })
    );
};

const transformData = (data: IInventories[]): IWareouse[] => {
    return data.map((item: IInventories): IWareouse => {
        return {
            id: item.storageId,
            name: item.storageName,
            storageTypes: transformStorageTypes(item.inventories, item.storageId),
        };
    });
};

export const getStorages = (
    data: {
        LevelId: number;
    },
    token: string,
    filterStoradeId?: number
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getStorageStart());
        const queryString = StringUtils.jsonToQueryString({...data});
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInventaryStorage}${queryString}`,
                {headers}
            );
            let filteredStorages = response.data.data;
            if (filterStoradeId !== undefined) {
                filteredStorages = filteredStorages.filter(
                    (storage: IInventories) => storage.storageId === filterStoradeId
                );
            }

            const fromService = transformData(filteredStorages);
            dispatch(getStorageSuccess(response.data));
            dispatch(addWarehouses(fromService));
        } catch (error) {
            dispatch(getStorageError(error as AxiosError));
        }
    };
};
