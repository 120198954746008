import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_SOLICITUDE_TERMS_DETAILS_ERROR,
    GET_SOLICITUDE_TERMS_DETAILS_START,
    GET_SOLICITUDE_TERMS_DETAILS_SUCCESS,
    CLEAR_DATA_TERMS_DETAILS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

const getSolicitudeTermsDetailsStart = () => {
    return {
        type: GET_SOLICITUDE_TERMS_DETAILS_START,
    };
};

const getSolicitudeTermsDetailsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_SOLICITUDE_TERMS_DETAILS_SUCCESS,
        payload: result,
    };
};

const getSolicitudeTermsDetailsError = (error: AxiosError) => {
    return {
        type: GET_SOLICITUDE_TERMS_DETAILS_ERROR,
        error: error,
    };
};

export const clearSolicitudeTermsDetails = () => {
    return {
        type: CLEAR_DATA_TERMS_DETAILS,
    };
};

export const getSolicitudeTermsDetailsAction = (token: string, solicitudeId: number) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSolicitudeTermDetails}?solicitudeId=${solicitudeId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSolicitudeTermsDetailsStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getSolicitudeTermsDetailsSuccess(response.data));
        } catch (e) {
            dispatch(getSolicitudeTermsDetailsError(e as AxiosError));
        }
    };
};
