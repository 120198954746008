import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IconContainer} from '@/components/CashFlow/styles';

import React from 'react';
interface IError {
    error: boolean;
    password: string;
}
export const ErrorPassword = ({error, password}: IError) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <IconContainer
                    FontSize="0.875rem"
                    className="material-icons"
                    Color={password.length < 8 ? '#FF6357' : '#309C60'}
                    Width="auto"
                    Height="auto"
                >
                    {password.length < 8 ? 'close' : 'done'}
                </IconContainer>
                <IconContainer
                    FontSize="0.875rem"
                    className="material-icons"
                    Color={error ? '#FF6357' : '#309C60'}
                    Width="auto"
                    Height="auto"
                >
                    {error ? 'close' : 'done'}
                </IconContainer>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                <Text
                    Color={password.length < 8 ? '#FF6357' : '#309C60'}
                    FontSize="0.85rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                    Display="inline-block"
                >
                    La contraseña debe tener al menos 8 caracteres
                </Text>
                <Text
                    Color={error ? '#FF6357' : '#309C60'}
                    FontSize="0.85rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                    Display="inline-block"
                >
                    Debe incluir una mayúscula, una minúscula, un número y un carácter especial.
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
