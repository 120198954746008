import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import {
    Table,
    TableItem,
    TableItemsContainer,
    TableRowHeadContract,
    TableRowItemsContract,
} from '@/components/DeadLines/styles';
import * as style from '@/components/Quoter/stylesConstants';
import {IDetailsHistory, IHistory} from '@Quoter/inferfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {HistoryStadistic} from '@Quoter/Redux/Actions/StatusStepOne';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {HISTORY, LEVELS, SPECIALTITLE, TITLEHISTORY, WITHOUTPRICE} from '@Quoter/constants';
import {QuitNumberFormat, withoutPrices} from '@/hooks/currentFormatUtils';

export const History = (props: IHistory) => {
    const {history, setHistory, token} = props;
    const dispatch: AppDispatch = useDispatch();
    const {productSearch, stadistics} = useSelector((state: RootState) => state.saveEndeavor);
    const {historyDetail} = useSelector((state: RootState) => state.statusQuoter);
    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;

    useEffect(() => {
        if (
            productSearch.levelsIds[LEVELS.ARTICLE] !== undefined &&
            stadistics.state?.value &&
            stadistics.value &&
            stadistics.loan
        ) {
            if (companyId) {
                dispatch(
                    HistoryStadistic(token, {
                        companyId: companyId,
                        articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                        value: stadistics.value,
                        loan: stadistics.loan,
                        stateConservationId: stadistics.state?.value,
                    })
                );
                dispatch(
                    HistoryStadistic(token, {
                        articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                        value: stadistics.value,
                        loan: stadistics.loan,
                        stateConservationId: stadistics.state?.value,
                    })
                );
            }
        }
    }, [
        productSearch.levelsIds[LEVELS.ARTICLE],
        stadistics.state?.value,
        stadistics.value,
        stadistics.loan,
    ]);

    return (
        <Modal
            modalState={history}
            changeModalState={() => {}}
            Align="center"
            FlexDirMod="column"
            Width="37rem"
            titleModalState={false}
            BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
            Justify="center"
            AlignMod="center"
            Position="fixed"
            Top="0"
            Height="auto"
            Padding="1rem"
            Gap="1rem"
            BorderRadius="1.5rem"
            background="none"
        >
            <ContainerFlex Justify="space-between">
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" FontFamily="TragicGrotesk">
                    {HISTORY.HISTORY}
                </Text>
                <Icons
                    className="material-icons"
                    onClick={() => setHistory(false)}
                    FontSize="2rem"
                    Cursor="pointer"
                    Color="#5A5AFF"
                >
                    {HISTORY.ICON}
                </Icons>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Gap="0.5rem">
                <ContainerFlex
                    Justify="start"
                    Padding="0 0.5rem"
                    Border="1.5px solid #E8E9EA"
                    Height="58px"
                    Radius="8px"
                >
                    <Image
                        alt="user-image"
                        src={productSearch.image}
                        width="42px"
                        height="42px"
                        Border="1px solid #0D166B"
                        Radius="50%"
                        ObjectFit="cover"
                    />
                    <Text Color="#2A2C2F" FontWeight="500" Padding="0 0.5rem">
                        {productSearch.articleName}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Justify="start" Padding="0 1rem" FlexDir="column" Gap="0.5rem">
                    <ContainerFlex Justify="space-between" Gap="0.5rem">
                        <Text Color="#2A2C2F" FontWeight="500" FontSize="0.75rem">
                            {HISTORY.DAYS}
                        </Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {withoutPrices(historyDetail?.daysAging, WITHOUTPRICE.DOLLAR)}
                        </Text>
                    </ContainerFlex>

                    <ContainerFlex Justify="space-between" Gap="0.5rem">
                        <Text Color="#2A2C2F" FontWeight="500" FontSize="0.75rem">
                            {HISTORY.AWESOME}
                        </Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {withoutPrices(
                                QuitNumberFormat(historyDetail?.salePercentage),
                                WITHOUTPRICE.AVG
                            )}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>

            <Table>
                <TableRowHeadContract Grid="repeat(5, 1fr)">
                    {TITLEHISTORY.map((item, index: number) => (
                        <TableItem
                            Justify={item.title === SPECIALTITLE ? 'start' : 'center'}
                            key={index}
                        >
                            <Text
                                wSpace="normal"
                                FontWeight="500"
                                Color="#2A2C2F"
                                FontSize="0.75rem"
                                TextAlign="center"
                            >
                                {item.title}
                            </Text>
                        </TableItem>
                    ))}
                </TableRowHeadContract>
                <TableItemsContainer OverY="auto" Height="15.5rem">
                    {historyDetail?.valuationHistoryCatalog?.length > 0 ? (
                        historyDetail.valuationHistoryCatalog.map(
                            (item: IDetailsHistory, index: number) => (
                                <TableRowItemsContract
                                    Background={index === LEVELS.GROUP ? '#F0F0FF' : ''}
                                    Height="32px"
                                    Grid="repeat(5, 1fr)"
                                    key={index}
                                >
                                    <TableItem Justify="start" Color="#54575C">
                                        {item.monthYear}
                                    </TableItem>
                                    <TableItem Color="#54575C">{item.value}</TableItem>
                                    <TableItem Color="#54575C">{item.loan}</TableItem>
                                    <TableItem Color="#54575C">{item.salePrice}</TableItem>
                                    <TableItem Color="#54575C">
                                        {item.loanAveragePercentage}
                                    </TableItem>
                                </TableRowItemsContract>
                            )
                        )
                    ) : (
                        <ContainerFlex {...style.titleMinicontent} FontWeight="400">
                            {HISTORY.WITHOUTINTORMATION}
                        </ContainerFlex>
                    )}
                </TableItemsContainer>
            </Table>
        </Modal>
    );
};
