import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {AppDispatch} from '@config/store';

import {DeadlineGeneral, interestSubmit, resetInteres} from '@CreateDeadline/interfaces';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {StyleNumericFormat} from '@Loan/styles';
import {QuitNumberFormat, limitNumberFormat} from '@/hooks/currentFormatUtils';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {interestInformation, setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const Interest = (props: DeadlineGeneral) => {
    const dispatch: AppDispatch = useDispatch();
    const {deadlinesGeneral, deadlineById} = props;
    const {handleSubmit, watch, control, reset} = useForm({
        defaultValues: {
            storageRate: '',
            insuranceRate: '',
            operationRate: '',
            cat: '',
            commission: '',
        },
    });
    const values = watch();

    const openStep = () => {
        if (deadlinesGeneral.interest.storageRate || deadlinesGeneral.initialInformation.nameTerm) {
            dispatch(setStep(2));
        }
    };

    const onSubmit = (data: interestSubmit) => {
        if (data.storageRate !== '') {
            const json = {
                storageRate: QuitNumberFormat(data.storageRate),
                insuranceRate: QuitNumberFormat(data.insuranceRate),
                operationRate: QuitNumberFormat(data.operationRate),
                cat: QuitNumberFormat(data.cat),
                commission: QuitNumberFormat(data.commission),
            };
            dispatch(interestInformation(json));
            dispatch(setStep(3));
        }
    };

    const resetsInfo = (data: resetInteres) => {
        reset({
            storageRate: String(data.storageRate),
            insuranceRate: String(data.insuranceRate),
            operationRate: String(data.operationRate),
            cat: String(data.cat),
            commission: String(data.commission),
        });
    };

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            !(
                deadlinesGeneral.interest.storageRate ||
                deadlinesGeneral.interest.operationRate ||
                deadlinesGeneral.interest.insuranceRate ||
                deadlinesGeneral.interest.commission ||
                deadlinesGeneral.interest.cat
            )
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            const json = {
                storageRate: vDeadlines.storageRate,
                insuranceRate: vDeadlines.insuranceRate,
                operationRate: vDeadlines.operationRate,
                cat: vDeadlines.cat,
                commission: vDeadlines.commission,
            };
            dispatch(interestInformation(json));
            resetsInfo(vDeadlines);
        }

        if (deadlinesGeneral.interest.storageRate && (!deadlineById.edit || !deadlineById.copy)) {
            resetsInfo(deadlinesGeneral.interest);
        }
    }, []);

    return (
        <ContainerFlex MarginBt="1rem" Radius="5px" Border="1px solid var(--gray-4)">
            <FormContainer
                MarginTp="0"
                Width="100%"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <TitleDeadline
                    title={'Interés'}
                    step={deadlinesGeneral.step}
                    stepCurrent={2}
                    stepDependence={0}
                    openStep={openStep}
                    dependence={deadlinesGeneral.interest.cat !== undefined}
                />

                {deadlinesGeneral.step === 2 && (
                    <ContainerFlex FlexDir="column">
                        <ContainerFlex
                            Justify="space-between"
                            FlexWrap="wrap"
                            Padding="1.5rem 2.5rem 1.5rem 1.5rem"
                        >
                            <ContainerFlex
                                Align="start"
                                Width="31%"
                                FlexDir="column"
                                Padding="0 0 1.5rem 0"
                            >
                                <Text Color="#6d6e7" FontSize="0.75rem" required>
                                    Tasa de almacenaje
                                </Text>
                                <Controller
                                    name="storageRate"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            placeholder="Tasa de almacenaje"
                                            allowLeadingZeros={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex
                                Align="start"
                                Width="31%"
                                FlexDir="column"
                                Padding="0 0 1.5rem 0"
                            >
                                <Text Color="#6d6e7" FontSize="0.75rem" required>
                                    Tasa Seguro
                                </Text>
                                <Controller
                                    name="insuranceRate"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            placeholder="Tasa Seguro"
                                            allowLeadingZeros={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex
                                Align="start"
                                Width="31%"
                                FlexDir="column"
                                Padding="0 0 1.5rem 0"
                            >
                                <Text Color="#6d6e7" FontSize="0.75rem" required>
                                    Tasa de operación
                                </Text>
                                <Controller
                                    name="operationRate"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            placeholder="Tasa de operación"
                                            allowLeadingZeros={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Align="start" Width="31%" FlexDir="column">
                                <Text Color="#6d6e7" FontSize="0.75rem" required>
                                    CAT
                                </Text>
                                <Controller
                                    name="cat"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            placeholder="Escribe aquí"
                                            maxLength={12}
                                            allowLeadingZeros={false}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Align="start" Width="31%" FlexDir="column">
                                <Text Color="#6d6e7" FontSize="0.75rem" required>
                                    Comisión
                                </Text>
                                <Controller
                                    name="commission"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            placeholder="Comisión"
                                            allowLeadingZeros={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Width="31%">{''}</ContainerFlex>
                        </ContainerFlex>

                        {!deadlineById.edit && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                margin="0 0 1.5rem 0"
                                hColor="#35cf44"
                                text="Continuar"
                                disabled={
                                    !values.cat ||
                                    !values.storageRate ||
                                    !values.operationRate ||
                                    !values.insuranceRate ||
                                    !values.commission
                                }
                            />
                        )}
                    </ContainerFlex>
                )}
            </FormContainer>
        </ContainerFlex>
    );
};
