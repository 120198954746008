import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Modal, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {IPropsModalChangeOrganization, IUpdateUser} from '@components/ManageUser/interface';
import {RootState, AppDispatch} from '@config/store';
import {Branch, Level} from '@General/BranchTree/interfaces';
import {UserData} from '@components/ManageUser/Redux/interface';
import {putDataUserEmployeeAxios} from '@components/ManageUser/Redux/Actions/putDataUserEmployeeAction';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {transformToStrings} from '@components/ManageUser/ActionsSidebar/constants';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';

export const ModalChangeOrganizations = ({
    setOpenModalOrganization,
}: IPropsModalChangeOrganization) => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    const dispatch: AppDispatch = useDispatch();
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const DuplicatedTree = useSelector((state: RootState) => {
        return state.getOrganizationsTree.copyTree;
    });
    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);
    function getBranchNamesByIds(levels: Level[], ids: number[]): Branch[] {
        const branchNames: Branch[] = [];
        if (levels) {
            levels.forEach((level) => {
                level.nextLevel.forEach((nextLevel: {branches: Branch[]}) => {
                    nextLevel.branches.forEach((branch) => {
                        if (ids.includes(branch.idBranch)) {
                            branchNames.push(branch);
                        }
                    });
                });
            });
        }
        return branchNames;
    }
    useEffect(() => {
        if (UserInformation.branchs && TreeComponent.data) {
            const levelsId: number[] = UserInformation.branchs.map((level) => level.branchId);
            setBranch(levelsId);
            setChecked(transformToStrings(levelsId));
            const data = getBranchNamesByIds(DuplicatedTree.data, levelsId);
            setBranchData(data);
        }
    }, [UserInformation.branchs, TreeComponent.data]);
    const saveChanges = () => {
        const uodateBranchs = branchesData.map((data) => ({
            branchId: data.idBranch,
            nameBranch: data.branchName,
        }));
        const updateDataUser: IUpdateUser = {
            userEmployeeId: UserInformation.userEmployeeId,
            names: UserInformation.userEmployeeName,
            surnaNames: UserInformation.usersLastName,
            userName: UserInformation.userName,
            jobPosition: UserInformation.job,
            inmmediateBoss: UserInformation.inmediateBoss,
            status: UserInformation.statusUser,
            companyId: UserInformation.companyId,
            startSesionEmail: UserInformation.startSesionEmail,
            startSesionPhone: UserInformation.startSesionPhone,
            address: UserInformation.adress,
            email: UserInformation.email,
            phone: UserInformation.phone,
            costCenter: UserInformation.costCenter,
            rolEmployee: UserInformation.roles.map((role) => ({
                rolId: role.roleId,
                rolName: role.roleName,
                substituteEmployeeRol: role.substitutes?.map((substitute) => ({
                    substituteId: substitute.substitutesId,
                    userEmployeeId: substitute.secondUserEmployeeId,
                    secondRol: substitute.secondUserEmployeeId,
                })),
            })),
            levelsBranch: uodateBranchs,
        };
        dispatch(putDataUserEmployeeAxios(token, updateDataUser, updateDataUser.userEmployeeId));
        setOpenModalOrganization(false);
    };
    return (
        <Modal>
            <ContainerFlex
                backG="#fff"
                Width="40%"
                Height="596px"
                FlexDir="column"
                BoxS="0px 0px 12px rgba(0, 0, 0, 0.5)"
                BoxShadow=""
                Justify="space-between"
            >
                <Text
                    Color="#35CF44"
                    Padding="0 24px"
                    Height="56px"
                    FontSize="1.25rem"
                    BorderBt="1px solid #35CF44"
                    Width="100%"
                >
                    Cambiar organización
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    TextAlign="start"
                    Width="100%"
                    Padding="24px"
                    Height="auto"
                    LHeight="normal"
                    FlexWrap="wrap"
                    wSpace="pre"
                >
                    <Text FontWeight="500" wSpace="pre">
                        ¡Recuerda!:{' '}
                    </Text>
                    Solo se mostrarán las sucursales a las que el{' '}
                    <Text FontWeight="500" wSpace="pre">
                        {''}ROL{' '}
                    </Text>
                    {''}tiene permiso
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    Padding="0 24px"
                    Justify="start"
                    Overflow="auto"
                    Align="start"
                    Height="62%"
                >
                    {DuplicatedTree.data && TreeComponent.data && TreeComponent.data.length ? (
                        <TreeBranch
                            checked={checked}
                            branchesId={branchesId}
                            levelsId={levelsId}
                            branchesData={branchesData}
                            setChecked={setChecked}
                            setBranch={setBranch}
                            setLevel={setLevel}
                            setBranchData={setBranchData}
                            isEdit={true}
                        />
                    ) : (
                        <LoadingGeneral />
                    )}
                </ContainerFlex>
                <ContainerFlex
                    Height="88px"
                    Bt="1px solid #E5E7E9"
                    Justify="end"
                    Gap="24px"
                    Padding="24px"
                >
                    <ButtonGeneral
                        text="Cancelar"
                        width="93px"
                        secondaryButton={true}
                        transform="capitalize"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => {
                            setOpenModalOrganization(false);
                        }}
                        border="1px solid transparent"
                    />
                    <ButtonGeneral
                        disabled={branchesData.length === 0}
                        text="Guardar y continuar"
                        width="168px"
                        border="1px solid #414042"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder="#35CF44"
                        hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        dFontWeight="400"
                        clic={() => saveChanges()}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
