import React from 'react';
import {Link} from 'react-router-dom';
import {DateTime} from 'luxon';

export const RowRecolectionGoldTable = (props: {
    sku: string;
    fecha: string;
    familia: string;
    subfamilia: string;
    kilataje: number;
    costo: number;
}) => {
    const {sku, fecha, familia, subfamilia, kilataje, costo} = props;
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <tr className="row-sucursal-tr">
            <td className="font-weight-bolder text-reset color-black">
                <Link to={`/Recoleccion/Almoneda/${sku}`}>
                    <span className="color-dark">{sku}</span>
                </Link>
            </td>
            <td>{DateTime.fromISO(fecha).toFormat('LL')}</td>
            <td>{familia}</td>
            <td>{subfamilia}</td>
            <td>{kilataje}</td>
            <td>{formatterDolar.format(costo)}</td>
        </tr>
    );
};
