import styled from '@emotion/styled';
import {ISliderProps, IStyledSVGProps} from '@/components/CreditsAndPawns/interfaces';

export const Slider = styled.input<ISliderProps>`
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    width: ${(props) => props.Width || '100%'};
    height: ${(props) => props.Height || '12px'};
    border-radius: ${(props) => props.Radius || '8px'};
    background: ${(props) => {
        const min = props.min ?? 0;
        const max = props.max ?? 100;
        const value = props.value ?? 0;
        const percentage = max > min ? ((value - min) / (max - min)) * 100 : 0;
        return `linear-gradient(to right, ${props.BackGroundColorFill || '#5A5AFF'} 0%, ${
            props.BackGroundColorFill || '#5A5AFF'
        } ${percentage}%, ${props.BackGroundColor || '#FFF'} ${percentage}%, ${
            props.BackGroundColor || '#FFF'
        } 100%)`;
    }};

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: ${(props) => props.TWidth || '24px'};
        height: ${(props) => props.THeight || '24px'};
        cursor: ${(props) => props.Cursor || 'auto'};
        background-image: radial-gradient(
            circle,
            ${(props) => props.TBackgroundColor || '#5A5AFF'} 40%,
            ${(props) => props.TBackgroundColor || '#5A5AFF'} 45%
        );
        border-radius: 50%;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
    }

    ::-moz-range-thumb {
        cursor: ${(props) => props.Cursor || 'auto'};
        width: ${(props) => props.TWidth || '24px'};
        height: ${(props) => props.THeight || '24px'};
        -moz-appearance: none;
        background-image: radial-gradient(
            circle,
            ${(props) => props.TBackgroundColor || '#5A5AFF'} 40%,
            ${(props) => props.TBackgroundColor || '#5A5AFF'} 45%
        );
        border-radius: 50%;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
    }
`;

export const StyledSVG = styled.svg<IStyledSVGProps>`
xmlns="http://www.w3.org/2000/svg";
fill:${(props) => props.SFill || 'none'};
left: ${(props) => `${props.SLeft || 0}px`};
top: ${(props) => `${props.STop || 0}px`};
transform-origin: ${(props) => props.STransformOrigin || 'none'};
transition: ${(props) => props.STransition || 'none'};
transform: ${(props) => props.STransform || 'none'};
position: ${(props) => props.SPosition || 'static'};
`;
