import {SEARCH_LEVELSANDBRANCH} from '@components/LocationsBranch/Redux/types';
import {SearchLevelState, SearchLevelAction} from '@components/LocationsBranch/Redux/interfaces';

const initialState: SearchLevelState = {
    data: [],
    loading: false,
    error: null,
};

const getSearchLevelsAndBranch = (
    state = initialState,
    action: SearchLevelAction
): SearchLevelState => {
    switch (action.type) {
        case SEARCH_LEVELSANDBRANCH.SEARCH_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SEARCH_LEVELSANDBRANCH.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case SEARCH_LEVELSANDBRANCH.SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SEARCH_LEVELSANDBRANCH.CLEAR_RESULTS:
            return {
                ...state,
                loading: false,
                error: null,
                data: [],
            };
        default:
            return state;
    }
};

export default getSearchLevelsAndBranch;
