import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    FOCUSED_COLORS,
    SCREEN,
    SELECT_TYPE,
    TITLES_USER,
} from '@components/Users/UserProfile/constants';
import {UserCard} from '@components/Users/UserProfile/UserCard';
import {getScreenUsers} from '@components/Users/Redux/Actions/ScreenUseSelector';
import {RootState} from '@/config/store';
import {IScreen} from '@components/Users/Redux/Reducers/ScreenUsers';
import {GeneralInformation} from '@components/Users/UserProfile/GeneralInformation';
import RoleSettings from '@components/Users/UserProfile/RoleSettings/RoleSettings';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {ContactData} from '@components/Users/UserProfile/ContactData';
import {ProfileUserResetPassword} from '@/components/UserProfileResetPassword/ResetPasswordUser';
import {UserBranchAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignment';

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Mi empresa'},
    {to: '#/Users', label: 'Usuario'},
    {to: '#/', label: 'Perfil de usuario'},
];
export const HomeUser = () => {
    const dispatch = useDispatch();
    const Screen: IScreen = useSelector((state: RootState) => state.ScreenStateUsers);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="16px"
            Gap="16px"
            Height="'calc(100vh - 69px)'"
        >
            <Breadcrumb items={itemsBreadcrumb} />
            <Text FontSize="1.5rem" FontWeight="700">
                {TITLES_USER.USER_PROFILE}
            </Text>
            <UserCard />
            <ContainerFlex Height="40px">
                <ContainerFlex Bb={Screen.screen === SCREEN.GENERAL ? FOCUSED_COLORS.FOCUSED : ''}>
                    <Text
                        Color={
                            Screen.screen === SCREEN.GENERAL
                                ? FOCUSED_COLORS.FOCUSED_TEXT
                                : FOCUSED_COLORS.UNFOCUSED_TEXT
                        }
                        Cursor="pointer"
                        FontWeight="500"
                        onClick={() => dispatch(getScreenUsers(SCREEN.GENERAL))}
                    >
                        {SELECT_TYPE.GENERAL}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Bb={Screen.screen === SCREEN.CONTACT ? FOCUSED_COLORS.FOCUSED : ''}>
                    <Text
                        Color={
                            Screen.screen === SCREEN.CONTACT
                                ? FOCUSED_COLORS.FOCUSED_TEXT
                                : FOCUSED_COLORS.UNFOCUSED_TEXT
                        }
                        Cursor="pointer"
                        FontWeight="500"
                        onClick={() => dispatch(getScreenUsers(SCREEN.CONTACT))}
                    >
                        {SELECT_TYPE.CONTACT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Bb={Screen.screen === SCREEN.PASSWORD ? FOCUSED_COLORS.FOCUSED : ''}>
                    <Text
                        Color={
                            Screen.screen === SCREEN.PASSWORD
                                ? FOCUSED_COLORS.FOCUSED_TEXT
                                : FOCUSED_COLORS.UNFOCUSED_TEXT
                        }
                        Cursor="pointer"
                        FontWeight="500"
                        onClick={() => dispatch(getScreenUsers(SCREEN.PASSWORD))}
                    >
                        {SELECT_TYPE.PASSWORD}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Bb={Screen.screen === SCREEN.BRANCH ? FOCUSED_COLORS.FOCUSED : ''}>
                    <Text
                        Color={
                            Screen.screen === SCREEN.BRANCH
                                ? FOCUSED_COLORS.FOCUSED_TEXT
                                : FOCUSED_COLORS.UNFOCUSED_TEXT
                        }
                        Cursor="pointer"
                        FontWeight="500"
                        onClick={() => dispatch(getScreenUsers(SCREEN.BRANCH))}
                    >
                        {SELECT_TYPE.BRANCH}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Bb={Screen.screen === SCREEN.ROLES ? FOCUSED_COLORS.FOCUSED : ''}>
                    <Text
                        Color={
                            Screen.screen === SCREEN.ROLES
                                ? FOCUSED_COLORS.FOCUSED_TEXT
                                : FOCUSED_COLORS.UNFOCUSED_TEXT
                        }
                        Cursor="pointer"
                        FontWeight="500"
                        onClick={() => dispatch(getScreenUsers(SCREEN.ROLES))}
                    >
                        {SELECT_TYPE.ROLES}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {Screen.screen === SCREEN.GENERAL && <GeneralInformation />}
            {Screen.screen === SCREEN.CONTACT && <ContactData />}
            {Screen.screen === SCREEN.PASSWORD && <ProfileUserResetPassword />}
            {Screen.screen === SCREEN.BRANCH && <UserBranchAssignment />}
            {Screen.screen === SCREEN.ROLES && <RoleSettings />}
        </ContainerFlex>
    );
};
