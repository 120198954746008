import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {SelectChangeEvent} from '@mui/material';
import {
    SelectStyled,
    ChipStyled,
    MenuItemStyled,
    FormControlLabelStyled,
} from '@components/PersonalLoans/NewLoanProduct/StyledScore';
import ArrowSmall from '@images/chevronBottom.svg';
import Close from '@images/closeDark.svg';
import CheckboxScore from '@components/PersonalLoans/NewLoanProduct/CheckboxScore';
import {COLORMAP, ROWLIST, colorMap} from '@components/PersonalLoans/NewLoanProduct/constants';

const DropdownIndicator = ({onClick}: {onClick: () => void}) => (
    <Image src={ArrowSmall} onClick={onClick} Cursor="pointer" />
);

const CreditScore = () => {
    const [selectedRows, setSelectedRows] = useState<
        {id: number; name: string; description: string}[]
    >([]);
    const [open, setOpen] = useState(false);
    const [hoveredId, setHoveredId] = useState<number | null>(null);

    const getColor = (id: number): string => {
        return colorMap[id] || COLORMAP.DEFAULT;
    };

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as number[];

        const selectedItems = ROWLIST.filter((row) => value.includes(row.id));
        setSelectedRows(selectedItems);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedRows(ROWLIST);
        } else {
            setSelectedRows([]);
        }
    };

    const handleDropdownToggle = () => {
        setOpen((prev) => !prev);
    };

    const isAllSelected = ROWLIST.length > 0 && selectedRows.length === ROWLIST.length;
    const isSomeSelected = selectedRows.length > 0 && selectedRows.length < ROWLIST.length;

    return (
        <ContainerFlex Justify="start" FlexDir="column" Width="22.688rem" Padding="0">
            <SelectStyled
                multiple
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedRows.map((row) => row.id)}
                onChange={handleChange}
                renderValue={(selected) => (
                    <>
                        {selectedRows.map((row) => (
                            <ChipStyled
                                key={row.id}
                                label={
                                    <ContainerFlex Gap="4px">
                                        {row.name}
                                        <Image
                                            src={Close}
                                            alt="close-icon"
                                            width="20px"
                                            height="20px"
                                            onClick={() => {
                                                setSelectedRows(
                                                    selectedRows.filter(
                                                        (selectedRow) => selectedRow.id !== row.id
                                                    )
                                                );
                                            }}
                                        />
                                    </ContainerFlex>
                                }
                            />
                        ))}
                    </>
                )}
                IconComponent={() => <DropdownIndicator onClick={handleDropdownToggle} />}
                MenuProps={{}}
            >
                <MenuItemStyled Padding="8px 8px 8px 32px">
                    <FormControlLabelStyled
                        label="Todos los clientes"
                        control={
                            <CheckboxScore
                                checked={isAllSelected}
                                indeterminate={isSomeSelected}
                                onChange={handleSelectAllClick}
                            />
                        }
                    />
                </MenuItemStyled>
                {ROWLIST.map((row) => (
                    <MenuItemStyled
                        key={row.id}
                        value={row.id}
                        Padding="8px 8px 8px 32px"
                        onMouseEnter={() => setHoveredId(row.id)}
                        onMouseLeave={() => setHoveredId(null)}
                    >
                        <CheckboxScore
                            checked={selectedRows.some((selectedRow) => selectedRow.id === row.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelectedRows([...selectedRows, row]);
                                } else {
                                    setSelectedRows(
                                        selectedRows.filter(
                                            (selectedRow) => selectedRow.id !== row.id
                                        )
                                    );
                                }
                            }}
                        />
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Height="36px">
                            <Text
                                FontWeight="700"
                                Color={hoveredId === row.id ? '#5A5AFF' : getColor(row.id)}
                            >
                                {row.name}
                            </Text>
                            <Text
                                FontSize="0.75rem"
                                Color={hoveredId === row.id ? '#5A5AFF' : getColor(row.id)}
                            >
                                {row.description}
                            </Text>
                        </ContainerFlex>
                    </MenuItemStyled>
                ))}
            </SelectStyled>
        </ContainerFlex>
    );
};

export default CreditScore;
