import React from 'react';

import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {ContainerFlex} from '@EcommerceShopify/styles';

export const BreadcrumbEndeavor = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/', label: 'Regresa a los resultados'},
        {to: '/', label: 'Realizar Pago'},
    ];
    return (
        <ContainerFlex MarginBt="1.875rem" Align="flex-start" FlexDir="column" Height="25px">
            <Breadcrumb items={itemsBreadcrumb} />
        </ContainerFlex>
    );
};
