import React from 'react';
import {ColumnsAuction} from '@Auction/styles';
interface NewType {
    pesoTotal: number;
    pesoAccesorios: number;
    pesoMetal: number;
    kilataje: number;
    precioVenta: number;
    costo: number;
    avaluo: number;
    estadoConservacion: string;
}

export function RowAuditActions(props: NewType) {
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const {
        pesoTotal,
        pesoAccesorios,
        pesoMetal,
        kilataje,
        precioVenta,
        costo,
        avaluo,
        estadoConservacion,
    } = props;
    return (
        <>
            <ColumnsAuction>
                <span>Peso Total</span>
                <span>Peso accesorios</span>
                <span>Peso metal</span>
                <span>Diferencia peso metal</span>
                <span>Kilataje</span>
                <span>Precio venta</span>
                <span>Costo (mutuo)</span>
                <span>Avalúo</span>
                <span>Edo. de conservación</span>
                <span>Incidencia</span>
                <span>Tipo de incidencia</span>

                <span>Monto a retener</span>
            </ColumnsAuction>
            <ColumnsAuction>
                <span>{pesoTotal}</span>
                <span>{pesoAccesorios}</span>
                <span>{pesoMetal}</span>
                <span>-</span>
                <span>{kilataje}</span>
                <span>{formatterDolar.format(precioVenta)}</span>
                <span>{formatterDolar.format(costo)}</span>
                <span>{formatterDolar.format(avaluo)}</span>
                <span>{estadoConservacion}</span>
                <span>-</span>
                <span>-</span>

                <span>-</span>
            </ColumnsAuction>
        </>
    );
}
