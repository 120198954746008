import React from 'react';
import {LoadingGeneralButtons} from '@Electronics/loading-buttons';
import {ButtonGenerals} from '@Button/styles';

export default function ButtonGeneral(props) {
    const {
        text,
        loading,
        width,
        clic,
        form,
        secondaryButton,
        mt,
        height,
        margin,
        bs,
        marginTop,
        hideborder,
        transform,
        border,
        borderDisabled,
        borderHover,
        FontSize,
        disabled,
        hColor,
        padding,
        color,
        hBorder,
        backGround,
        hText,
        borderH,
        hShadow,
        radius,
        cursor,
        hBackG,
        textColor,
        position,
        top,
        left,
        fontWeight,
        dColor,
        icon,
    } = props;
    return (
        <ButtonGenerals
            Padding={padding}
            SecondaryButton={secondaryButton}
            type="submit"
            Width={width}
            disabled={loading || disabled}
            onClick={clic}
            form={form}
            Height={height}
            Margin={margin}
            MT={mt}
            BS={bs}
            MarginTop={marginTop}
            HideBorder={hideborder}
            Transform={transform}
            Border={border}
            DBorder={borderDisabled}
            FontSize={FontSize}
            HDBorder={borderHover}
            HColor={hColor}
            Color={color}
            DBackG={'#E8E9EA'}
            HDBox={'0 5px 10px 0 rgba(167, 169, 172, 0.1)'}
            HBorder={hBorder}
            BackGC={backGround}
            HText={hText}
            BorderH={borderH}
            HShadow={hShadow}
            Radius={radius}
            Cursor={cursor}
            HBackG={hBackG}
            TextColor={textColor}
            Position={position}
            Top={top}
            Left={left}
            FontWeight={fontWeight}
            DColor={dColor}
        >
            {props?.loading !== true ? text : <LoadingGeneralButtons />}
            {props?.loading !== true ? icon : <LoadingGeneralButtons />}
        </ButtonGenerals>
    );
}
