import React, {useState} from 'react';
import {TableItem} from '@DeadLines/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import {IconContainer} from '@CashFlow/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {getRoles} from '@Roles/Redux/Actions/CreateRoles';
import {fetchFilters} from '@Roles/Redux/Actions/SearchRoleNameValidation';

export const TableRowHeadRoles = (data: {name: string; isOrder: boolean; id: number}) => {
    const dispatch: AppDispatch = useDispatch();
    const [order, setOrder] = useState(false);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const searchedValue = useSelector((state: RootState) => {
        return state.SearchRole.search;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const isOrder = (orderType: number) => {
        if (token) {
            const filters = {
                searchRoleName: searchedValue,
                pageSize: 20,
                pageNumber: 1,
                enterpriseId: companyId,
                orderType: order ? 1 : 2,
                filterRoles: orderType,
            };
            const filter = {
                orderType: order ? 1 : 2,
                filterRoles: orderType,
            };
            dispatch(getRoles(filters, token));
            dispatch(fetchFilters(filter));
        }
    };

    return (
        <TableItem Padding="0 0 0 16px" Justify={data.id === 0 ? 'start' : 'center'}>
            <Text
                FontWeight="500"
                FontSize="0.875rem"
                Padding="0 11px 0 0"
                Color="#414042"
                PaddingR="12.1px"
            >
                {' '}
                {data.name}
            </Text>{' '}
            {data.isOrder && (
                <IconContainer
                    className="material-icons"
                    FontSize=".875rem"
                    Color={order ? ' #35cf44' : '#414042'}
                    Justify="start"
                    onClick={() => {
                        setOrder(!order);
                        isOrder(data.id);
                    }}
                >
                    {order ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </IconContainer>
            )}
        </TableItem>
    );
};
