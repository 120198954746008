import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ContactData} from '@ComponetsModal/ContactData';
import {ContactDataEdit} from '@ComponetsModal/ContactDataEdit';
import {companyFlowMdoals, HashMap} from '@MyCompany/Interface';

export const InfoContact = ({setFlowModalChange, setModalState}: companyFlowMdoals) => {
    const [contactData, setContactData] = useState(0);

    const components: HashMap = {
        0: (
            <ContactData
                setModalState={setModalState}
                setContactData={setContactData}
                setFlowModalChange={setFlowModalChange}
            />
        ),
        1: (
            <ContactDataEdit
                setContactData={setContactData}
                setFlowModalChange={setFlowModalChange}
            />
        ),
    };

    return (
        <ContainerFlex FlexDir="column" Padding="0 24px 24px 24px">
            <Text
                Color="#414042"
                wSpace="wrap"
                FontWeight="500"
                FontSize="0.875rem"
                Height="36px"
                TextAlign="center"
            >
                Por favor confirma tus datos de contacto para que un asesor se ponga en contacto
                contigo
            </Text>
            <ContainerFlex MarginTop="24px" Align="start" FlexDir="column">
                <ContainerFlex
                    Height="48px"
                    Justify="start"
                    Border="solid 1px #e5e7e9"
                    Radius="4px 4px 0 0;"
                    Padding="16px 14.5px"
                >
                    <Text Height="19px" FontWeight="500" FontSize="1rem" Color="#414042">
                        Información de contacto
                    </Text>
                </ContainerFlex>
                {components[contactData] as JSX.Element}
            </ContainerFlex>
        </ContainerFlex>
    );
};
