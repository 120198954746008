import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    VALIDATE_EMAIL_FORMARKET_PLACE,
    VALIDATE_EMAIL_FORMARKET_PLACE_ERROR,
    VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS,
    RESET_EMAIL,
} from '@components/Login/MarketPlaceLogin/Redux/Types';

export const getEmailVerification = () => {
    return {
        type: VALIDATE_EMAIL_FORMARKET_PLACE,
    };
};
export const ResetUserDataMarket = () => {
    return {
        type: RESET_EMAIL,
    };
};
export const getEmailVerificationSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS,
        payload: result,
    };
};
export const getEmailVerificationError = (error: AxiosError) => {
    return {
        type: VALIDATE_EMAIL_FORMARKET_PLACE_ERROR,
        error: error,
    };
};

export function getEmailVerificationUsersMarket(data: string) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getEmailVerification());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateUserMarketPlace.replace(
                    '{email}',
                    data
                )}`,
                {
                    headers,
                }
            );
            dispatch(getEmailVerificationSuccess(response));
        } catch (error) {
            dispatch(getEmailVerificationError(error as AxiosError));
        }
    };
}
