import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {CREATE_ROLE, TREE_PERMISSIONS, UPDATE_ROLE} from '@Roles/CreateRoles/Redux/Types';
import {ICreateRolAction, IUpdateRol} from '@/components/Roles/AddRoles/interfaces';
import {NavigateFunction} from 'react-router-dom';

export const treePermissionsStart = () => ({type: TREE_PERMISSIONS.START});
export const treePermissionsSuccess = (result: {data: AxiosResponse}) => ({
    type: TREE_PERMISSIONS.SUCCESS,
    treePermissions: result.data,
});
export const treePermissionsError = (error: AxiosError) => ({
    type: TREE_PERMISSIONS.ERROR,
    error: error,
});
export const treePermissions = (token: string, filters: {typeRol: number}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(treePermissionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetModuleByTypePermissions}${queryString}`,
                {headers}
            );
            dispatch(treePermissionsSuccess(response.data));
        } catch (error) {
            dispatch(treePermissionsError(error as AxiosError));
        }
    };
};

export const treeReset = () => ({type: TREE_PERMISSIONS.RESET});

export const CreateRoleStart = () => ({type: CREATE_ROLE.CREATE_START});
export const CreateRoleSuccess = (result: AxiosResponse) => ({
    type: CREATE_ROLE.CREATE_SUCCESS,
    createRole: result.data,
});
export const CreateRoleError = (error: AxiosError) => ({
    type: CREATE_ROLE.CREATE_ERROR,
    error: error,
});
export const CreateRoles = (token: string, data: ICreateRolAction, navigate: NavigateFunction) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(CreateRoleStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateRol}`,
                data,
                {headers}
            );
            dispatch(CreateRoleSuccess(response.data));
            navigate('/Roles');
        } catch (error) {
            dispatch(CreateRoleError(error as AxiosError));
        }
    };
};

export const UpdateRoleStart = () => ({type: UPDATE_ROLE.UPDATE_START});
export const UpdateRoleSuccess = (result: AxiosResponse) => ({
    type: UPDATE_ROLE.UPDATE_START,
    createRole: result.data,
});
export const UpdateRoleError = (error: AxiosError) => ({
    type: UPDATE_ROLE.UPDATE_ERROR,
    error: error,
});
export const UpdateRoles = (token: string, data: IUpdateRol, navigate: NavigateFunction) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(UpdateRoleStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateRol}`,
                data,
                {headers}
            );
            dispatch(UpdateRoleSuccess(response.data));
            navigate('/Roles');
        } catch (error) {
            dispatch(UpdateRoleError(error as AxiosError));
        }
    };
};
