import styled from '@emotion/styled';

export const DoneIcon = styled.span`
    font-size: 1.12rem;
    border: 1px solid #7ac143;
    border-radius: 100%;
    box-shadow: 0px 5px 10px #a7a9ac41;
    margin-right: 0.5rem;
    padding: 0.15rem 0.1rem;
    color: #7ac143;
`;
export const NContract = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 0.8rem;
    margin-bottom: 0.8rem;
    &::before {
        content: '';
        position: absolute;
        width: 30%;
        height: 0;
        border: 1px solid #e6e9ee;
        top: 1.6rem;
        left: 3rem;
    }
    &::after {
        content: '';
        position: absolute;
        width: 30%;
        height: 0;
        border: 1px solid #e6e9ee;
        top: 1.6rem;
        right: 3rem;
    }
`;
export const TopTable = styled.div`
    background: #f3f3f3;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: space-around;
    width: 80%;
    height: 52px;
    margin: auto;
    padding: 1rem 0;
    font-size: 1rem;
`;
export const Table = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: space-around;
    padding: 0.8rem 0;
    font-size: 1rem;
    width: 80%;
    height: 52px;
    margin: 0 auto;
    margin-bottom: 1rem;
    img {
        width: 30px;
        height: 30px;
        padding-right: 0.5rem;
    }
`;
export const DetailsPro = styled.div<{isDisplay?: string}>`
    display: ${({isDisplay}) => isDisplay};
    border: 0.5px solid #e4e7e9;
    border-radius: 0 0 5px 5px;
    border-top: 0;
    width: 100%;
`;
export default styled;
