import {
    GET_ALL_CONTRACTS,
    GET_ALL_CONTRACTS_ERROR,
    GET_ALL_CONTRACTS_SUCCESS,
} from '@components/Shopify/Products/redux/types/types';
import {AxiosResponse} from 'axios';
const initialState = {
    error: false,
    loading: false,
    data: [],
    blockActivate: false,
};

export const getCarContracts = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case GET_ALL_CONTRACTS:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_CONTRACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data: actions.payload,
            };
        case GET_ALL_CONTRACTS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                data: [],
            };
        default:
            return state;
    }
};
