import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {
    ICircleProps,
    ILink,
    IPropsArrowIcon,
    IPropsButton,
    IPropsButtonCheck,
    IPropsCircle,
    IPropsEditIcon,
    IPropsIconContainer,
    IPropsIconSvg,
    IPropsImage,
    IPropsInputForm,
    IPropsTitleContainer,
} from '@CashFlow/interfacesStyles';

export const Container = styled.div`
    display: flex;
    width: 95% !important;
    height: 100%;
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    > div {
        font-size: 0.875rem;
        font-weight: normal;
    }
`;

export const TitleContainer = styled.div<IPropsTitleContainer>`
    display: flex;
    background-color: ${(props) => props.BGColor};
    box-sizing: border-box;
    min-height: 56px;
    max-height: 56px;
    justify-content: space-between;
    align-items: flex-start;
    height: 56px;
    width: 949px;
    padding: 16px 0px 16px 24px;
    padding: ${(props) => props.Padding};
    border-bottom: ${({showBorderButtton}) =>
        showBorderButtton ? '1px solid var(--gray-4)' : 'none'};
    font-size: 18px;
    width: 100%;
`;

export const Circle = styled.div<IPropsCircle>`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid ${({borderColor}) => borderColor};
    background-color: ${({backgroundColor}) => backgroundColor};
    font-size: 18px;
    font-weight: normal;
    color: ${({fontColor}) => fontColor};
    box-shadow: 0px 3px 6px #7ac14343;
`;

export const EditIcon = styled.div<IPropsEditIcon>`
    display: ${({isDisplay}) => isDisplay};
    background-color: ${(props) => props.BGColor};
    border-bottom: ${(props) => props.BorderB};
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    margin: auto 12px;
    border-bottom: ${(props) => props.BTBorder};
    transform: ${(props) => props.transform};
`;

export const ArrowIcon = styled.div<IPropsArrowIcon>`
    display: ${({isDisplay}) => isDisplay};
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    margin: auto 0;
    width: 15px;
`;

export const SectionContainer = styled.div`
    width: 100%;
    border: 1px solid var(--gray-4);
    margin-bottom: 8px;
    border-radius: 5px;
    font-size: 16px;

    .first-row {
        border-bottom: 1px solid var(--gray-4);
    }

    .buttonSection {
        display: flex;
        padding: 20px;
        justify-content: center;
    }
`;

export const FormContainer = styled.form<IPropsArrowIcon>`
    display: ${({isDisplay}) => isDisplay};
    grid-template-columns: repeat(3, 1fr);
    padding: 25px 24px 16px 24px;
    font-size: 14px;
    gap: 10px;

    > label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }

    input,
    select {
        height: 40px;
        border-radius: 5px;
        border: 1px solid var(--gray-4);
        font-size: 14px;
        padding: 0 8px;
        width: 100%;
        box-sizing: border-box;
        &:disabled {
            background-color: #fafafa;
            color: var(--gray-2);
        }

        &::placeholder {
            color: var(--gray-2);
        }
    }

    input.montoInput {
        color: #7ac143;
        font-size: 14px;
        width: 100%;
    }

    select {
        color: black;
    }

    select:invalid {
        color: var(--gray-2);
    }

    option {
        color: black;
    }

    option:first-child {
        color: var(--gray-2);
    }

    .gridColumns2-4 {
        grid-column: 2 / 4;
        > input {
            border: 0px;
            background-color: #ffffff;
            width: 100%;
        }
    }

    .gridColumns1-4 {
        grid-column: 1 / 4;
    }

    > div > p > span {
        font-weight: 300;
    }
`;

export const FormContainerSegundo = styled.div<IPropsArrowIcon>`
    display: ${({isDisplay}) => isDisplay};
`;

export const BankContainer = styled.div`
    display: grid;
    grid-template-columns: 55% 45%;
    height: 86px;
    border-bottom: 1px solid var(--gray-4);
    font-size: 13px;
    font-weight: 400;
    gap: 10px;
    box-sizing: border-box;
    padding-left: 15px;

    .custom-radio-btn {
        box-sizing: border-box;
        min-width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        position: relative;
    }

    .custom-radio-btn input {
        display: none;
    }

    .custom-radio-btn .checkmark {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        transition: opacity 0.3s ease;
        border: 1px solid black;
        padding: 3px;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }

    .custom-radio-btn input:checked ~ .checkmark {
        background-origin: content-box;
        background-repeat: no-repeat;
        border: 1px solid #7ac143;
        cursor: pointer;
        background-image: radial-gradient(
            circle at center,
            #7ac143,
            calc((16px - (1px * 2) - (3px * 2)) / 2),
            rgba(255, 255, 255, 0) 0
        );
    }

    > div {
        display: flex;
        align-items: center;
        color: #6d6e71;
        gap: 10px;

        > span {
            font-size: 33px;
        }
    }

    div.cancelIcon > span {
        color: #ef4f55;
        font-size: 24px;
    }

    div.cancelIcon div > p {
        color: #ef4f55;
        font-size: 14px;
        font-weight: 400;
    }

    div > select {
        width: 95%;
        height: 40px;
        border: 1px solid #e4e7e9;
        border-radius: 5px;
        color: var(--gray-2);
        padding-left: 10px;
        > option {
            color: #6d6e71;
        }
    }

    p {
        margin: 0;
        font-size: 13px;

        &:first-child {
            font-weight: 600;
        }
    }
`;

export const AccountRow = styled.div`
    display: flex;
    width: 100%;

    > div {
        border: 1px solid var(--gray-4);
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;

        select {
            width: 500px;
            height: 40px;
            margin-right: 50px;
            border: 1px solid #e4e7e9;
            border-radius: 5px;
            color: #4a4a4a;
            padding-left: 10px;
            font:
                Work Sans,
                Regular;
        }
    }

    div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    div:first-child {
        padding-left: 24px;
    }

    p {
        margin: 0;
        &:first-child {
            color: red;
            font-weight: 600;
        }
    }
`;

export const Button = styled.button<IPropsButton>`
    width: ${(props) => props.Width};
    height: 40px;
    border: 1px solid #414042;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: ${(props) => props.MarginT};
    margin: ${(props) => props.Margin};
    flex-direction: ${(props) => props.FlexDirection};
    border: ${(props) => props.Border};
    width: ${(props) => props.width};
    justify-content: ${(props) => props.justify};
    width: ${(props) => props.width};
    padding: ${(props) => props.padding};
    border-top: ${(props) => props.BorderT};
    cursor: pointer;

    &:hover {
        border-color: ${(props) => props.BorderColor};
    }

    &:disabled {
        background-color: var(--gray-5);
        color: var(--gray-2);
        border: 1px solid var(--gray-2);
    }
`;

export const ButtonCheck = styled.button<IPropsButtonCheck>`
    margin-top: ${(props) => props.Margin};
    flex-direction: ${(props) => props.FlexDirection};
    border: ${(props) => props.Border};
    width: ${(props) => props.width};
    justify-content: ${(props) => props.justify};
    width: ${(props) => props.width};
    background-color: white;

    &:disabled {
        background-color: white;
        border: none;
    }
`;

export const InputForm = styled.input<IPropsInputForm>`
    margin-top: ${(props) => props.Margin};
    flex-direction: ${(props) => props.FlexDirection};
    border: ${(props) => props.Border};
    width: ${(props) => props.width};
    justify-content: ${(props) => props.justify};
    padding: ${(props) => props.padding};
    color: ${(props) => props.color};
`;
export const CircleContainer = styled.div<ICircleProps>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border-radius: 50%;
    background: ${(props) => props.BackG};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    box-sizing: border-box;
`;
CircleContainer.defaultProps = {
    Width: '24px',
    Height: '24px',
    Justify: 'center',
    Align: 'center',
    BackG: '#7ac143',
};
export const IconContainer = styled.div<IPropsIconContainer>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: 100;
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    padding: ${(props) => props.Padding};
    transform: ${(props) => props.Tranform};
    cursor: pointer;

    &:hover {
        color: ${(props) => props.HColor};
    }
`;
IconContainer.defaultProps = {
    Color: ' ',
    FontSize: ' ',
    Padding: '0',
    Width: '24px',
    Height: '24px',
};
export const Image = styled.img<IPropsImage>`
    height: 100%;
    width: 100%;
    border-radius: ${(props) => props.Radius};
`;
export const IconSvg = styled.svg<IPropsIconSvg>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    fill: ${(props) => props.Fill};
    transition: ${(props) => props.Transition};
    rotate: ${(props) => props.Rotate};
    &:hover path {
        fill: ${(props) => props.HoverF};
    }
`;
export const LinkQuoter = styled(Link)<ILink>`
    text-decoration: none;
    width: ${(props) => props.Width};
    height: 20px;
    justify-content: space-between;
    cursor: pointer;
    &:hover div span,
    &:hover div div svg path {
        color: #35cf44;
        fill: #35cf44;
    }
`;
LinkQuoter.defaultProps = {
    Width: '91px',
};
