import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import TabsMenu from '@components/MyAccount/TabsMenu/TabsMenu';
import MyCredit from '@components/MyAccount/MyCredit/MyCredit';
import {
    getCardCreditsClientProfile,
    getStaticInformationCreditProfile,
    getFilterValues,
} from '@components/MyAccount/MyCredit/Redux/Actions/MyCreditActions';
import {TABS_MENU_OPTIONS} from '@components/MyAccount/constants';

const MyAccount = () => {
    const [activeTab, setActiveTab] = useState(TABS_MENU_OPTIONS[2]);
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    useEffect(() => {
        if (!token) return;
        dispatch(getStaticInformationCreditProfile(token));
        dispatch(getCardCreditsClientProfile(token));
        dispatch(getFilterValues(token));
    }, [token, dispatch]);

    return (
        <ContainerFlex
            Padding="48px 60px 38px 60px"
            FlexDir="column"
            Gap="24px"
            backG="#FAFAFF"
            BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
            Justify="flex-start"
            Align="flex-start"
        >
            <TabsMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === TABS_MENU_OPTIONS[2] && <MyCredit />}
        </ContainerFlex>
    );
};

export default MyAccount;
