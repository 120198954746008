import styled from '@emotion/styled';

export const FilterTagContainer = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: #f0f0ff;
    color: #5a5aff;
    border-radius: 32px;
    padding: 4px 8px;
    gap: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
`;

export const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: #5a5aff;
    width: 16px;
    height: 16px;
    font-size: 1.125rem;
    cursor: pointer;
    padding: 0;
`;
