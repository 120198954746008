import {DateTime} from 'luxon';
import {CalendarPicker, LocalizationProvider} from '@mui/x-date-pickers';
import React, {useCallback} from 'react';
import CalendarButtons from '@/components/General/Atoms/CalendarDateSelection/CalendarButtons';
import {
    CalendarSelect,
    CalendarWrapper,
} from '@/components/General/Atoms/CalendarDateSelection/Styles/styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {ICalendarInitDateProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const CalendarInitDate: React.FC<ICalendarInitDateProps> = ({
    years,
    months,
    dayOfWeekFormatter,
    setValue,
    showCalendarModal,
    setShowCalendarModal,
    initialDueDate,
    setInitialDueDate,
    trigger,
    isSubmitted,
}) => {
    const handleDateChange = useCallback((date: DateTime | null) => {
        if (date) {
            setInitialDueDate({
                ...initialDueDate,
                selectedMonth: date.month,
                selectedYear: date.year,
                selectedDate: date,
            });
        }
        setValue('dateDueInit', date);
    }, []);
    const handleContinue = () => {
        setValue('dateDueInit', initialDueDate.selectedDate);
        setInitialDueDate({
            ...initialDueDate,
            prevDate: initialDueDate.selectedDate,
        });
        if (isSubmitted) {
            trigger('dateDueInit');
            trigger('dateDueEnd');
        }
        setShowCalendarModal(!showCalendarModal);
    };
    const handleCancel = () => {
        setValue('dateDueInit', initialDueDate.prevDate);
        setInitialDueDate({
            ...initialDueDate,
            selectedDate: initialDueDate.prevDate,
            selectedMonth: initialDueDate.prevDate
                ? initialDueDate.prevDate.month
                : DateTime.now().month,
            selectedYear: initialDueDate.prevDate
                ? initialDueDate.prevDate.year
                : DateTime.now().year,
        });
        setShowCalendarModal(!showCalendarModal);
    };
    const handleReset = () => {
        setInitialDueDate({
            ...initialDueDate,
            selectedDate: initialDueDate.prevDate,
            selectedMonth: initialDueDate.prevDate
                ? initialDueDate.prevDate.month
                : DateTime.now().month,
            selectedYear: initialDueDate.prevDate
                ? initialDueDate.prevDate.year
                : DateTime.now().year,
        });
    };
    const handleMonthChange = (selectedOption: {value: number; label: string} | null | unknown) => {
        if (selectedOption && typeof selectedOption === 'object' && 'value' in selectedOption) {
            const newMonth =
                typeof selectedOption.value === 'number'
                    ? selectedOption.value
                    : DateTime.now().month;
            const updatedDate = DateTime.local(
                initialDueDate.selectedYear,
                newMonth,
                initialDueDate.selectedDate?.day || 1
            );
            setInitialDueDate({
                ...initialDueDate,
                selectedMonth: newMonth,
                selectedDate: updatedDate,
            });
            setValue('dateDueInit', updatedDate);
        }
    };
    const handleYearChange = (selectedOption: {value: number; label: string} | null | unknown) => {
        if (selectedOption && typeof selectedOption === 'object' && 'value' in selectedOption) {
            const newYear =
                typeof selectedOption.value === 'number'
                    ? selectedOption.value
                    : DateTime.now().year;
            const updatedDate = DateTime.local(
                newYear,
                initialDueDate.selectedMonth,
                initialDueDate.selectedDate?.day || 1
            );
            setInitialDueDate({
                ...initialDueDate,
                selectedYear: newYear,
                selectedDate: updatedDate,
            });
            setValue('dateDueInit', updatedDate);
        }
    };
    return (
        <>
            <ContainerFlex Gap="0.5rem" Padding="0.5rem">
                <CalendarSelect
                    value={
                        months.find((month) => month.value === initialDueDate.selectedMonth) || null
                    }
                    options={months}
                    onChange={handleMonthChange}
                />
                <CalendarSelect
                    value={years.find((year) => year.value === initialDueDate.selectedYear) || null}
                    options={years}
                    onChange={handleYearChange}
                />
            </ContainerFlex>
            <ContainerFlex>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CalendarWrapper className="test">
                        <CalendarPicker
                            dayOfWeekFormatter={dayOfWeekFormatter}
                            date={initialDueDate.selectedDate}
                            onChange={handleDateChange}
                            minDate={DateTime.local()}
                            maxDate={DateTime.local(
                                initialDueDate.selectedYear,
                                initialDueDate.selectedMonth,
                                DateTime.local(
                                    initialDueDate.selectedYear,
                                    initialDueDate.selectedMonth
                                ).daysInMonth
                            )}
                            className="calendar-options"
                        />
                    </CalendarWrapper>
                </LocalizationProvider>
            </ContainerFlex>
            <CalendarButtons
                onCancel={handleCancel}
                onContinue={() => handleContinue()}
                continueDisabled={
                    !initialDueDate.selectedDate ||
                    initialDueDate.selectedDate.startOf('day') < DateTime.local().startOf('day')
                }
                onReset={handleReset}
            />
        </>
    );
};
