export const GET_EMPLOYEE_INCIDENTS = 'GET_EMPLOYEE_INCIDENTS';
export const GET_EMPLOYEE_INCIDENTS_SUCCESS = 'GET_EMPLOYEE_INCIDENTS_SUCCESS';
export const GET_EMPLOYEE_INCIDENTS_ERROR = 'GET_EMPLOYEE_INCIDENTS_ERROR';

export const GET_EMPLOYEE_RESPONSIBLE = 'GET_EMPLOYEE_RESPONSIBLE';
export const GET_EMPLOYEE_RESPONSIBLE_SUCCESS = 'GET_EMPLOYEE_RESPONSIBLE_SUCCESS';
export const GET_EMPLOYEE_RESPONSIBLE_ERROR = 'GET_EMPLOYEE_RESPONSIBLE_ERROR';

export const PUT_AUDIT_INCIDENTS = 'PUT_AUDIT_INCIDENTS';
export const PUT_AUDIT_INCIDENTS_SUCCESS = 'PUT_AUDIT_INCIDENTS_SUCCESS';
export const PUT_AUDIT_INCIDENTS_ERROR = 'PUT_AUDIT_INCIDENTS_ERROR';
