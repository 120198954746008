import {
    GET_PURITY,
    GET_PURITY_SUCCESS,
    GET_PURITY_ERROR,
    GET_PURITY_FILTERS,
    GET_PURITY_LAB,
    GET_PURITY_LAB_SUCCESS,
    GET_PURITY_LAB_ERROR,
    GET_PURITY_FILE,
    GET_PURITY_FILE_ERROR,
    GET_PURITY_FILE_SUCCESS,
    POST_PURITY_FILE,
    POST_PURITY_FILE_SUCCESS,
    POST_PURITY_FILE_ERROR,
    GET_PURITY_INGOT,
    POST_PURITY_PAY,
    POST_PURITY_PAY_ERROR,
    POST_PURITY_PAY_SUCCESS,
} from '@TypesPurity/PurityTypes';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import Swal from 'sweetalert2';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';

export interface Ifilters {
    to?: string | null;
    from?: string | null;
    id?: number;
}
export const fetchGetPurity = () => {
    return {
        type: GET_PURITY,
    };
};
export const fetchGetFiltersPurity = (filters: Ifilters) => {
    return {
        type: GET_PURITY_FILTERS,
        value: filters,
    };
};
export const fetchGetPuritySuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PURITY_SUCCESS,
        value: result,
    };
};

export const fetchGetPurityError = () => {
    return {
        type: GET_PURITY_ERROR,
    };
};
export function getPurity(filters: Ifilters, pageSize = 10, pageIndex = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        if (!filters)
            filters = {
                to: '',
                from: '',
                id: 0,
            };
        const params = {
            ...filters,
            pageSize: pageSize,
            pageIndex: pageIndex,
        };
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetFiltersPurity(filters));
        dispatch(fetchGetPurity());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.getPurity}${queryString}`,
                {headers}
            );
            dispatch(fetchGetPuritySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetPurityError());
        }
    };
}

export const fetchGetIngot = (ingot: number) => {
    return {
        type: GET_PURITY_INGOT,
        value: ingot,
    };
};

export function getIdIngot(ingot: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetIngot(ingot));
    };
}

export const fetchGetPurityLab = (id: string) => {
    return {
        type: GET_PURITY_LAB,
        value: id,
    };
};

export const fetchGetPurityLabSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PURITY_LAB_SUCCESS,
        value: result,
    };
};

export const fetchGetPurityLabError = () => {
    return {
        type: GET_PURITY_LAB_ERROR,
    };
};
export function getPurityLab(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetPurityLab(id));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.getPurityLab.replace(
                    '{id}',
                    id
                )} `,
                {headers}
            );
            dispatch(fetchGetPurityLabSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetPurityLabError());
        }
    };
}

export const fetchGetPurityFile = () => {
    return {
        type: GET_PURITY_FILE,
    };
};

export const fetchGetPurityFileSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PURITY_FILE_SUCCESS,
        value: result,
    };
};

export const fetchGetPurityFileError = () => {
    return {
        type: GET_PURITY_FILE_ERROR,
    };
};
export function getPurityFile(id: string, idingot: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetPurityFile());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.getPurityFile
                    .replace('{id}', id)
                    .replace('{idingot}', idingot)} `,
                {headers}
            );
            dispatch(fetchGetPurityFileSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetPurityFileError());
        }
    };
}

export const fetchPostPurityFile = () => {
    return {
        type: POST_PURITY_FILE,
    };
};

export const fetchPostPurityFileSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_PURITY_FILE_SUCCESS,
        value: result,
    };
};

export const fetchPostPurityFileError = () => {
    return {
        type: POST_PURITY_FILE_ERROR,
    };
};
export function postPurityFile(data: FormData, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostPurityFile());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.postPurityFile} `,
                data,
                {headers}
            );
            dispatch(fetchPostPurityFileSuccess(response.data));
            Swal.fire({
                icon: 'success',
                title: 'Agregar Pureza',
                text: '¡La pureza ha sido agregada con éxito!',
            });
        } catch (error) {
            dispatch(fetchPostPurityFileError());
            Swal.fire({
                icon: 'error',
                title: 'Agregar pureza',
            });
        }
    };
}

export const fetchPostPurityPay = () => {
    return {
        type: POST_PURITY_PAY,
    };
};

export const fetchPostPurityPaySuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: POST_PURITY_PAY_SUCCESS,
        value: result,
    };
};

export const fetchPostPurityPayError = () => {
    return {
        type: POST_PURITY_PAY_ERROR,
    };
};
export function postPurityPay(data: FormData, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostPurityPay());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.postPurityPay} `,
                data,
                {headers}
            );
            dispatch(fetchPostPurityPaySuccess(response.data));
            Swal.fire({
                icon: 'success',
                title: 'Agregar Pago',
                text: '¡El pago ha sido agregada con éxito!',
            });
        } catch (error) {
            dispatch(fetchPostPurityPayError());
            Swal.fire({
                icon: 'error',
                title: 'Agregar pago',
            });
        }
    };
}
