import {ContainerFlex, LinkAtaskate, Text} from '@/components/Shopify/Ecommerce/styles';
import {Table} from '@mui/material';
import React, {useState} from 'react';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import UploadFile from '@/components/CashFlow/Expenses/ExpenseDetails/UploadFile';
import ArrowBack from '@images/arrow_back.svg';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import {EXPENSE_DETAILS} from '@/components/CashFlow/Expenses/ExpenseDetails/constants';
import {ItemData} from '@components/CashFlow/Expenses/ExpenseDetails/interfaces';
import UnfoldMore from '@/images/unfold_more.svg';
import {data} from '@/components/CashFlow/Expenses/ExpenseDetails/data';

const ExpenseDetails = () => {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [fileReceipProperties, setPropiertiesReceipt] = useState<{name: string; size: number}>({
        name: '',
        size: 0,
    });

    const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null);
    const [products] = useState<ItemData[]>(data);
    const [filePropierties, setPropierties] = useState<{name: string; size: number}>({
        name: '',
        size: 0,
    });

    return (
        <ContainerFlex FlexDir="column" Padding="2rem" Justify="start" Gap="1rem">
            <ContainerFlex Justify="start" Margin="0 0 0 4rem">
                <LinkAtaskate to={'/Cashflow'} FontSize="1.3rem">
                    <ImageIcon src={ArrowBack} margin="3px 10px 0 0 " />
                    {EXPENSE_DETAILS.expenses}
                </LinkAtaskate>
            </ContainerFlex>
            <ContainerFlex Height="1.5rem" Padding="2rem" Radius="16px" Justify="space-between">
                <Text FontSize="1.4rem" FontWeight="700">
                    {EXPENSE_DETAILS.accountNumber}
                </Text>
                <Text FontSize="1.4rem" FontWeight="700">
                    {EXPENSE_DETAILS.total}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Padding="2rem" backG="white">
                <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                    <Text FontSize="1.2rem" FontWeight="500">
                        {EXPENSE_DETAILS.principalInfo}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Display="grid"
                    GridColumns="repeat(3, 1fr)"
                    GridAutoRows="minmax(100px, auto)"
                    Gap="10px"
                    Padding="1.5rem"
                >
                    <ContainerFlex FlexDir="column" Align="start" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.expenseType}</Text>
                        <Text FontWeight="300">{EXPENSE_DETAILS.service}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.provider}</Text>
                        <Text FontWeight="300">{EXPENSE_DETAILS.oxxo}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.paymentMethod}</Text>
                        <Text FontWeight="300">{EXPENSE_DETAILS.card}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.referenceId}</Text>
                        <Text FontWeight="300">{EXPENSE_DETAILS.id}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.expenseDate}</Text>
                        <Text FontWeight="300">{EXPENSE_DETAILS.date}</Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Padding="2rem"
                backG="white"
                Gap="1.5rem"
            >
                <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                    <Text FontSize="1.2rem" FontWeight="500">
                        {EXPENSE_DETAILS.expenseDetails}
                    </Text>
                </ContainerFlex>
                <Table>
                    <TableRowHead
                        Height="40px"
                        GridColumn="repeat(6, 1fr)"
                        BackG="none"
                        Border="none"
                    >
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.items}
                            <ImageIcon src={UnfoldMore} margin="0 5px" />
                        </Text>
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.category}
                        </Text>
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.article}
                        </Text>
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.quantity}
                        </Text>
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.amount}
                            <Text FontWeight="500" Color="#A1A5AA" Padding="4px 0px">
                                &nbsp;
                                {EXPENSE_DETAILS.noIva}
                            </Text>
                        </Text>
                        <Text FontWeight="500" Padding="4px 0px">
                            {EXPENSE_DETAILS.iva}
                        </Text>
                    </TableRowHead>
                    <TableItemsContainer Background="none">
                        {products.map((i) => (
                            <TableRowItems
                                key={i.id}
                                Height="40px"
                                GridColumn="repeat(6, 1fr)"
                                BackGround="none"
                                BorderBottom="1px solid #E8E9EA"
                                Border="none"
                                BorderRadius="0px"
                            >
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    {i.id}
                                </Text>
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    {i.category}
                                </Text>
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    {i.item}
                                </Text>
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    {i.quantity}
                                </Text>
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    ${i.price}
                                </Text>
                                <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                    ${i.tax}
                                </Text>
                            </TableRowItems>
                        ))}
                    </TableItemsContainer>
                </Table>
                <ContainerFlex Align="start" FlexDir="column">
                    <Text FontWeight="500">{EXPENSE_DETAILS.observations}</Text>
                    <Text Margin="8px 0 0 0">-</Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Padding="2rem" backG="white" Gap="2rem">
                <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                    <Text FontSize="1.2rem" FontWeight="500">
                        {EXPENSE_DETAILS.attached}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                    <Text FontWeight="600">
                        {EXPENSE_DETAILS.proofOfPayment}
                        <Text FontWeight="600" Color="#A1A5AA">
                            &nbsp;{EXPENSE_DETAILS.optional}
                        </Text>
                    </Text>

                    <UploadFile
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        fileProps={filePropierties}
                        setProps={setPropierties}
                    />
                </ContainerFlex>

                <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                    <Text FontWeight="600">{EXPENSE_DETAILS.receipt}</Text>
                    <UploadFile
                        selectedFile={selectedReceipt}
                        setSelectedFile={setSelectedReceipt}
                        fileProps={fileReceipProperties}
                        setProps={setPropiertiesReceipt}
                    />
                    <Text FontWeight="300">{EXPENSE_DETAILS.XMLformat}</Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ExpenseDetails;
