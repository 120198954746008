import {AxiosResponse, AxiosError} from 'axios';
export const GET_ALL_TOTAL_AVAILABLES_START = 'GET_ALL_TOTAL_AVAILABLES_START';
export const GET_ALL_TOTAL_AVAILABLES_SUCCESS = 'GET_ALL_TOTAL_AVAILABLES_SUCCESS';
export const GET_ALL_TOTAL_AVAILABLES_ERROR = 'GET_ALL_TOTAL_AVAILABLES_ERROR';

export const GET_LIST_LOANS_SEARCH_START = 'GET_LIST_LOANS_SEARCH_START';
export const GET_LIST_LOANS_SEARCH_SUCCESS = 'GET_LIST_LOANS_SEARCH_SUCCESS';
export const GET_LIST_LOANS_SEARCH_ERROR = 'GET_LIST_LOANS_SEARCH_ERROR';
export const GET_LIST_LOANS_START = 'GET_LIST_LOANS_START';
export const GET_LIST_PERSONAL_SUCCESS = 'GET_LIST_PERSONAL_SUCCESS';
export const GET_LIST_PERSONAL_ERROR = 'GET_LIST_PERSONAL_ERROR';
export const SET_LIST_LOANS_STATUS = 'SET_LIST_LOANS_STATUS';
export const CLEAR_LIST_LOANS_SEARCH = 'CLEAR_LIST_LOANS_SEARCH';
export const TOGGLE_FILTERS_MENU = 'TOGGLE_FILTERS_MENU';
export const SET_FILTERS_LIMITS_START = 'SET_FILTERS_LIMITS_START';
export const SET_FILTERS_LIMITS_SUCCESS = 'SET_FILTERS_LIMITS_SUCCESS';
export const SET_FILTERS_LIMITS_ERROR = 'SET_FILTERS_LIMITS_ERROR';
export const SET_FILTERS_VALUES = 'SET_FILTERS_VALUES';
export const SET_FILTERS_VISUAL_STATUS = 'SET_FILTERS_VISUAL_STATUS';
export const CLEAR_FILTERS_VALUES = 'CLEAR_FILTERS_VALUES';
export const GET_SEARCH_CLIENT_START = 'GET_SEARCH_CLIENT_START';
export const GET_SEARCH_CLIENT_SUCCESS = 'GET_SEARCH_CLIENT_SUCCESS';
export const GET_SEARCH_CLIENT_ERROR = 'GET_SEARCH_CLIENT_ERROR';
export const SET_SEARCHTERM = 'SET_SEARCHTERM';
export const SET_SEARCHTERM_CLEAR = 'SET_SEARCHTERM_CLEAR';
export const GET_PERSONAL_LOANS_BY_FILTERS_START = 'GET_PERSONAL_LOANS_BY_FILTERS_START';
export const GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS = 'GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS';
export const GET_PERSONAL_LOANS_BY_FILTERS_ERROR = 'GET_PERSONAL_LOANS_BY_FILTERS_ERROR';

/* Credit Request */
export const GET_DETAILS_CREDIT_START = 'GET_DETAILS_CREDIT_START';
export const GET_DETAILS_CREDIT_SUCCESS = 'GET_DETAILS_CREDIT_SUCCESS';
export const GET_DETAILS_CREDIT_ERROR = 'GET_DETAILS_CREDIT_ERROR';

/* Histories */
export const GET_CREDIT_AND_PAWNS_HISTORY_START = 'GET_CREDIT_AND_PAWNS_HISTORY_START';
export const GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS = 'GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS';
export const GET_CREDIT_AND_PAWNS_HISTORY_ERROR = 'GET_CREDIT_AND_PAWNS_HISTORY_ERROR';

/* Credit Solicitude */
export const GET_APPROVED_CREDIT_SOLICITUDE_START = 'GET_APPROVED_CREDIT_SOLICITUDE_START';
export const GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS = 'GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS';
export const GET_APPROVED_CREDIT_SOLICITUDE_ERROR = 'GET_APPROVED_CREDIT_SOLICITUDE_ERROR';

export const GET_PERCENT_AMORTIZATION_START = 'GET_PERCENT_AMORTIZATION_START';
export const GET_PERCENT_AMORTIZATION_SUCCESS = 'GET_PERCENT_AMORTIZATION_SUCCESS';
export const GET_PERCENT_AMORTIZATION_ERROR = 'GET_PERCENT_AMORTIZATION_ERROR';
export const SET_REQUESTED_AMOUNT_SOLICITUDE = 'SET_REQUESTED_AMOUNT_SOLICITUDE';
export const SET_REQUESTED_AMOUNT_RECEIVE = 'SET_REQUESTED_AMOUNT_RECEIVE';

export type PercentageAmortizationAction =
    | {type: typeof GET_PERCENT_AMORTIZATION_START}
    | {type: typeof GET_PERCENT_AMORTIZATION_SUCCESS; payload: number}
    | {type: typeof GET_PERCENT_AMORTIZATION_ERROR; error: AxiosError}
    | {type: typeof SET_REQUESTED_AMOUNT_SOLICITUDE; payload: number}
    | {type: typeof SET_REQUESTED_AMOUNT_RECEIVE; payload: number};

/* Customer Information */
export const OPEN_CUSTOMER_INFORMATION_MODAL = 'OPEN_CUSTOMER_INFORMATION_MODAL';
export const CLOSE_CUSTOMER_INFORMATION_MODAL = 'CLOSE_CUSTOMER_INFORMATION_MODAL';
export const GET_CUSTOMER_INFORMATION_START = 'GET_CUSTOMER_INFORMATION_START';
export const GET_CUSTOMER_INFORMATION_SUCCESS = 'GET_CUSTOMER_INFORMATION_SUCCESS';
export const GET_CUSTOMER_INFORMATION_ERROR = 'GET_CUSTOMER_INFORMATION_ERROR';
export type CustomerInformationAction =
    | {type: typeof GET_CUSTOMER_INFORMATION_START}
    | {type: typeof GET_CUSTOMER_INFORMATION_SUCCESS; payload: AxiosResponse}
    | {type: typeof GET_CUSTOMER_INFORMATION_ERROR; error: AxiosError}
    | {type: typeof OPEN_CUSTOMER_INFORMATION_MODAL; payload: number}
    | {type: typeof CLOSE_CUSTOMER_INFORMATION_MODAL};
