import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {MODAL_RETURN} from '@/components/CashFlowActions/Operational/Constants';

export const TextReturn = () => {
    return (
        <ContainerFlex Height="max-content" FlexDir="column" Align="start">
            <Text
                Padding="24px"
                FontSize="24px"
                FontWeight="700"
                Color="#1D1E20"
                FontFamily="Nunito"
            >
                {MODAL_RETURN.TITTLE}
            </Text>
            <Text FontWeight="400" Color="#54575C" FontSize="1rem" Padding=" 0px 24px 0px">
                {MODAL_RETURN.TEXT}
            </Text>
            <Text FontWeight="400" Color="#54575C" FontSize="1rem" Padding=" 0px 24px 0px">
                {MODAL_RETURN.TEXT_ONE}
            </Text>
            <Text FontWeight="400" Color="#54575C" FontSize="1rem" Padding=" 0px 24px 0px">
                {MODAL_RETURN.TEXT_TWO}
            </Text>
        </ContainerFlex>
    );
};
