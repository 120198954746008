import styled from '@emotion/styled';

export const TabsMenuContainer = styled.div`
    display: flex;
    border-bottom: 1.2px solid #e8e9ea;
`;

export const Tab = styled.div`
    display: flex;
    padding: 8px 16px;
    cursor: pointer;
    letter-spacing: 0.3px;
    color: #2a2c2f;
    border-bottom: 2px solid transparent;
    &.active {
        color: #5a5aff;
        border-bottom: 2px solid #5a5aff;
    }
`;

export const ArrowBack = styled.img`
    margin-right: 8px;
`;
