import {TREE} from '@components/CreateUsers/Redux/Types/Types';
import {ITreeGroupBranches, ITreeReducer} from '@components/CreateUsers/Redux/interface';

const initialState: ITreeGroupBranches = {
    treeGroup: [],
    treeBranch: [],
    showTreeBranches: {
        group: [],
        branches: [],
        copyBranches: [],
        currentBranches: [],
        treeGroup: [],
    },
    error: false,
    loading: false,
};

const TreeGroupBranches = (state = initialState, action: ITreeReducer): ITreeGroupBranches => {
    switch (action.type) {
        case TREE.GROUPS:
            return {
                ...state,
                loading: true,
                error: true,
            };
        case TREE.GROUPS_SUCCESS:
            return {
                ...state,
                treeGroup: action.treeGroup,
                loading: false,
                error: false,
            };
        case TREE.GROUPS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case TREE.BRANCHES:
            return {
                ...state,
                loading: true,
                error: true,
            };
        case TREE.BRANCHES_SUCCESS:
            return {
                ...state,
                treeBranch: action.treeBranch,
                loading: false,
                error: false,
            };
        case TREE.BRANCHES_ERROR:
            return {
                ...state,
                treeBranch: [],
                loading: false,
                error: true,
            };
        case TREE.SHOW_BRANCHES:
            return {
                ...state,
                showTreeBranches: action.showTreeBranches,
            };
        case TREE.RESET:
            return initialState;
        default:
            return state;
    }
};
export default TreeGroupBranches;
