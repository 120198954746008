import axios, {AxiosResponse} from 'axios';
import {
    DEADLINE_SIMULATION,
    DEADLINE_SIMULATION_SUCCESS,
    DEADLINE_SIMULATION_ERROR,
} from '@CreateDeadline/redux/types';
import {URL} from '@config/constants/index';

import {DeadlineSimulator} from '@CreateDeadline/interfaces';
import Swal from 'sweetalert2';
import {Dispatch} from 'redux';

export const getDeadlineSimulator = () => {
    return {
        type: DEADLINE_SIMULATION,
    };
};

export const getDeadlineSimulatorSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DEADLINE_SIMULATION_SUCCESS,
        payload: result,
    };
};

export const getDeadlineSimulatorError = () => {
    return {
        type: DEADLINE_SIMULATION_ERROR,
    };
};

export const getDeadlineSimulators = (filters: DeadlineSimulator, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(getDeadlineSimulator());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDeadlineSimulator}`,
                filters,
                {headers}
            );
            dispatch(getDeadlineSimulatorSuccess(response.data));
        } catch (error) {
            dispatch(getDeadlineSimulatorError());
            Swal.fire({
                icon: 'error',
                text: 'La(s) sucursal(es) no cuenta(n) con una dirección, seleccione una diferente o llene la dirección en el Módulo de Sucursales.',
                confirmButtonText: 'Continuar',
            });
        }
    };
};
