import {AppDispatch} from '@/config/store';
import {
    TARGET_EDIT_COOWNER,
    TARGET_EDIT_RESET,
    UPDATE_EDIT_COOWNER_ERROR,
    UPDATE_EDIT_COOWNER_START,
    UPDATE_EDIT_COOWNER_SUCCESS,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {CoOwnerData} from '@Quoter/Steps/interface';
import {URL} from '@config/constants/index';
import {IDataAllCoowner} from '@Quoter/CoownerBeneficiary/Redux/interfaces';
import {getAllCoowners} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/GetAllCoOwnerActions';

export const fetchPutUpdateCoOwner = () => {
    return {
        type: UPDATE_EDIT_COOWNER_START,
    };
};
export const fetchPutUpdateCoOwnerSuccess = (result: AxiosResponse) => {
    return {
        type: UPDATE_EDIT_COOWNER_SUCCESS,
        payload: result,
    };
};
export const fetchPutUpdateCoOwnerError = (error: AxiosError) => {
    return {
        type: UPDATE_EDIT_COOWNER_ERROR,
        error: error,
    };
};
export function PutUpdateCoOwner(
    data: CoOwnerData,
    token: string,
    setShowEdit: (e: boolean) => void,
    id: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutUpdateCoOwner());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateCoOwner}
            `,
                data,
                {headers}
            );
            dispatch(fetchPutUpdateCoOwnerSuccess(response));
            dispatch(getAllCoowners(token, {clientId: id}));
            setShowEdit(false);
            dispatch(fetchSelectEditCoOwner(null));
        } catch (error) {
            dispatch(fetchPutUpdateCoOwnerError(error as AxiosError));
        }
    };
}

export const fetchSelectEditCoOwner = (result: null | IDataAllCoowner) => {
    return {type: TARGET_EDIT_COOWNER, item: result};
};

export const fetchResetTargetEdit = () => {
    return {
        type: TARGET_EDIT_RESET,
    };
};
