import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';

import {ContainerArea, ImgLocation, SubContainerArea, TextAreal} from '@CreateLocation/style';
import {ContainerFlex, Input, Text, TextArea} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {RootState, AppDispatch} from '@config/store';
import {
    getCity,
    insertPension,
    restartLocations,
    updatePension,
} from '@ActionsLocation/LocationAction';
import pension from '@images/pension.svg';
import deleteIcon from '@images/delete_forever-black.svg';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import TreeLocations from '@AdminLocations/CreateLocation/Containers/TreeLocations';
import {sendPension, location} from '@Locations/AdminLocations/redux/Interfaces';
import {handleKeypress} from '@/hooks/currentFormatUtils';

const Pension = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/CreateBranch', label: 'Sucursales', reset: () => dispatch(restartLocations(null))},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: 'Crear ubicación'},
    ];
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const dispatch: AppDispatch = useDispatch();
    const history = useNavigate();
    const defaultData = {
        namePension: '',
        contact: '',
        address: '',
        phone: '',
        email: '',
        referencessAddress: '',
        branchId: '',
        cityId: '',
    };
    const {
        register,
        handleSubmit,
        reset,

        formState: {errors},
    } = useForm({
        defaultValues: defaultData,
    });

    const onSubmits = async (data: sendPension) => {
        if (locations.objectbranch.type === 'create')
            dispatch(
                insertPension(
                    {
                        branchId: locations.saveBranch.idBranch,
                        namePension: data.namePension,
                        contact: data.contact,
                        address: data.address,
                        phone: data.phone,
                        email: data.email,
                        referencessAddress: data.address,
                        cityId: Number(data.cityId),
                    },
                    token
                )
            );

        if (locations.objectbranch.type === 'edit')
            dispatch(
                updatePension(
                    {
                        pensionId: locations.objectbranch.id,
                        status: true,
                        branchId: locations.saveBranch.idBranch,
                        namePension: data.namePension,
                        contact: data.contact,
                        address: data.address,
                        phone: data.phone,
                        email: data.email,
                        referencessAddress: data.address,
                        cityId: Number(data.cityId),
                    },
                    token
                )
            );
    };

    useEffect(() => {
        dispatch(getCity(1, token));
        if (locations.objectbranch.type === 'edit') {
            reset({
                branchId: String(locations.objectbranch.branchId),
                namePension: locations.objectbranch.namePension,
                contact: locations.objectbranch.contact,
                address: locations.objectbranch.address,
                phone: locations.objectbranch.phone,
                email: locations.objectbranch.email,
                referencessAddress: locations.objectbranch.referencessAddress,
                cityId: String(locations.objectbranch.cityId),
            });
        }
    }, [dispatch]);

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex PaddingB="2rem" Justify="start">
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>

            <ContainerFlex Align="start" Justify="start">
                <TreeLocations />
                <ContainerFlex Align="flex-start" FlexDir="column" Padding="0 6.313rem 0 1.563rem">
                    <ContainerArea>
                        <SubContainerArea>
                            <Text FontSize="1rem" FontWeight="400">
                                Crear Ubicación
                            </Text>
                        </SubContainerArea>
                        <ContainerFlex Align="flex-start" FlexDir="column" Padding="0 12px">
                            <ContainerFlex
                                Width="150px"
                                FlexDir="column"
                                Align="flex-start"
                                Padding="46px 0 1rem 0"
                            >
                                <ContainerFlex
                                    FlexDir="column"
                                    Border={'2px solid #7AC143'}
                                    Width="98px"
                                    Height="98px"
                                    Radius="5px"
                                >
                                    <img width="50px" height="50px" src={pension} alt="pension" />
                                </ContainerFlex>
                                <ContainerFlex Justify="center" Width="98px">
                                    <Text>Pensión</Text>
                                </ContainerFlex>
                            </ContainerFlex>

                            <ContainerFlex Justify="flex-start" Padding="14px 0">
                                <Text FontSize="1rem" FontWeight="400">
                                    Detalles de la Ubicación en pensión
                                </Text>
                            </ContainerFlex>
                            <form onSubmit={handleSubmit(onSubmits)}>
                                <TextAreal>
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="10apx 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Nombre de la pensión
                                        </Text>
                                        <Input
                                            className="w-150"
                                            {...register('namePension', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                            })}
                                            maxLength={160}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.namePension?.message}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="10px 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Nombre de contacto
                                        </Text>
                                        <Input
                                            {...register('contact', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                            })}
                                            maxLength={160}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.contact?.message}
                                        </Text>
                                    </ContainerFlex>
                                </TextAreal>

                                <ContainerFlex Justify="space-between" Width="100%">
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="0.8rem 0"
                                        Width="48%"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Telefono de contacto
                                        </Text>
                                        <Input
                                            Padding="0.625rem 0.75rem"
                                            {...register('phone', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Ingresa sólo números',
                                                },
                                                minLength: {
                                                    value: 10,
                                                    message: 'Mínimo 10 dígitos',
                                                },
                                                maxLength: {
                                                    value: 12,
                                                    message: 'Máximo 12 dígitos',
                                                },
                                            })}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.phone?.message}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="0.8rem 0"
                                        Width="48%"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem">
                                            Email de contacto
                                        </Text>
                                        <Input
                                            Padding="0.625rem 0.75rem"
                                            {...register('email', {
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Ingresa un correo válido',
                                                },
                                            })}
                                            maxLength={160}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.email?.message}
                                        </Text>
                                    </ContainerFlex>
                                </ContainerFlex>
                                <TextAreal>
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="10px 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Dirección de la pensión
                                        </Text>
                                        <TextArea
                                            {...register('address', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                                minLength: {
                                                    value: 5,
                                                    message: 'Ingresa mínimo 5 carácteres',
                                                },
                                            })}
                                            onKeyPress={handleKeypress}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.address?.message}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="14 px 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Ciudad de la Pensión
                                        </Text>

                                        <select
                                            {...register('cityId', {
                                                required: {
                                                    value: true,
                                                    message: 'Selecciona una Sucursal',
                                                },
                                            })}
                                        >
                                            <option selected disabled hidden value="">
                                                Selecciona una Sucursal
                                            </option>
                                            {locations.cities &&
                                                locations.cities.map((item, index: number) => (
                                                    <option value={item.cityId} key={index}>
                                                        {item.nameCity}
                                                    </option>
                                                ))}
                                        </select>
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.cityId?.message}
                                        </Text>
                                    </ContainerFlex>
                                </TextAreal>
                            </form>

                            <ContainerFlex Justify="space-between" Width="75%" Padding="50px 0">
                                <ButtonGeneral
                                    transform="capitalize"
                                    hideborder="none"
                                    width="167px"
                                    text="Cancelar"
                                    hColor="#35cf44"
                                    clic={() => history('/NavLocations')}
                                />
                                <ButtonGeneral
                                    transform="capitalize"
                                    width="167px"
                                    text="Crear Ubicación"
                                    hColor="#35cf44"
                                    clic={handleSubmit(onSubmits)}
                                />
                                <ContainerFlex
                                    Justify="flex-start"
                                    onClick={() => reset(defaultData)}
                                    Width="167px"
                                    Cursor="pointer"
                                >
                                    <ImgLocation
                                        Width="20px"
                                        Height="20px"
                                        Margin="0 5px"
                                        HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                        src={deleteIcon}
                                    />
                                    <Text Cursor="pointer">Limpiar</Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerArea>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default Pension;
