import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {FieldValues} from 'react-hook-form';

import {
    isForFilters,
    saveSearchIds,
    saveSearchValuation,
} from '@Quoter/Redux/Actions/saveEndeavors';
import {
    Article,
    Brand,
    Family,
    Subfamily,
    articleDinamics,
    articleload,
    resetInputDinamics,
} from '@Quoter/Redux/Actions/PropertiesArticle';
import {DinamicsArticle} from '@StepOne/DinamicsArticle';
import {CATEGORTIZATION} from '@components/Quoter/constants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {
    IArticle,
    IBrand,
    IFamily,
    IStepOne,
    IGroup,
    ISelectReact,
    ISubfamily,
} from '@Quoter/inferfaces';

export const StepOne = (props: IStepOne) => {
    const {branchId, token, register, reset, watch, errors, control, setValue} = props;
    const dispatch: AppDispatch = useDispatch();
    const {searchID, inputDinamics, stadistics} = useSelector(
        (state: RootState) => state.saveEndeavor
    );

    const values = watch();
    const selectConvert = (items: IGroup[] | IFamily[] | ISubfamily[] | IBrand[] | IArticle[]) => {
        return items.map((item: FieldValues) => {
            return {
                value:
                    item.groupId ||
                    item.familyId ||
                    item.subfamilyId ||
                    item.brandId ||
                    item.articleId,
                label:
                    item.nameGroup ||
                    item.nameFamily ||
                    item.nameSubfamily ||
                    item.nameBrand ||
                    item.nameArticle,
            };
        });
    };

    const defaultActionsforSearch = (
        gpo: ISelectReact,
        fml: ISelectReact,
        sfml: ISelectReact,
        bnd: ISelectReact,
        artl: ISelectReact
    ) => {
        dispatch(isForFilters(true));
        dispatch(articleload(false));
        const ids = {
            levelsIds: [gpo.value, fml.value, sfml.value, bnd.value, artl.value],
            breadComesResult: '',
            articleName: artl.label,
            image: '',
        };
        dispatch(saveSearchValuation(ids));
        dispatch(resetInputDinamics());
    };

    const utilsReset = {
        Subfamily: 0,
        Brand: 0,
        Article: 0,
    };

    const resetData = {value: 0, label: ''};

    const handleFamily = (e: ISelectReact) => {
        dispatch(Family(token, {branchId: branchId, groupId: e.value}));
        const json = {
            Group: {value: e.value, label: e.label},
            Family: 0,
            ...utilsReset,
        };
        dispatch(saveSearchIds(json));
        defaultActionsforSearch(e, resetData, resetData, resetData, resetData);
    };

    const handleSubfamily = (e: ISelectReact) => {
        dispatch(Subfamily(token, {branchId: branchId, familyId: e.value}));
        const json = {
            Group: {value: values.Group.value, label: values.Group.label},
            Family: {value: e.value, label: e.label},
            ...utilsReset,
        };
        dispatch(saveSearchIds(json));
        defaultActionsforSearch(values.Group, e, resetData, resetData, resetData);
    };

    const handleBrand = (e: ISelectReact) => {
        dispatch(Brand(token, {branchId: branchId, subFamilyId: e.value}));
        const json = {
            Group: {value: values.Group.value, label: values.Group.label},
            Family: {value: values.Family.value, label: values.Family.label},
            Subfamily: {value: e.value, label: e.label},
            Brand: 0,
            Article: 0,
        };
        dispatch(saveSearchIds(json));
        defaultActionsforSearch(values.Group, values.Family, e, resetData, resetData);
    };

    const handleArticle = (e: ISelectReact) => {
        dispatch(Article(token, {branchId: branchId, brandId: e.value}));
        const json = {
            Group: {value: values.Group.value, label: values.Group.label},
            Family: {value: values.Family.value, label: values.Family.label},
            Subfamily: {value: values.Subfamily.value, label: values.Subfamily.label},
            Brand: {value: e.value, label: e.label},
            Article: 0,
        };
        dispatch(saveSearchIds(json));
        defaultActionsforSearch(values.Group, values.Family, values.Subfamily, e, resetData);
    };

    const dinamicsProducts = (e: ISelectReact) => {
        const json = {
            Group: {value: values.Group.value, label: values.Group.label},
            Family: {value: values.Family.value, label: values.Family.label},
            Subfamily: {value: values.Subfamily.value, label: values.Subfamily.label},
            Brand: {value: values.Brand.value, label: values.Brand.label},
            Article: {value: e.value, label: e.label},
        };
        dispatch(saveSearchIds(json));
        defaultActionsforSearch(values.Group, values.Family, values.Subfamily, values.Brand, e);
        dispatch(articleDinamics(token, {branchId: branchId, articleId: e.value}));
    };

    useEffect(() => {
        reset({
            Group: searchID.Group,
            Family: searchID.Family,
            Subfamily: searchID.Subfamily,
            Brand: searchID.Brand,
            Article: searchID.Article,
            note: inputDinamics.note,
            value: stadistics.value ? stadistics.value : '',
            loan: stadistics.loan ? stadistics.loan : '',
            Status: stadistics.state ? stadistics.state : '',
            ...inputDinamics,
        });
    }, [
        searchID.Group?.label,
        searchID.Family?.label,
        searchID.Subfamily?.label,
        searchID.Brand?.label,
        searchID.Article?.label,
    ]);

    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            backG="#fff"
        >
            <Text FontWeight="500" FontSize="1.5rem" Color="#54575C">
                {CATEGORTIZATION.SUBTITLE}
            </Text>
            <DinamicsArticle
                errors={errors}
                control={control}
                register={register}
                handleBrand={handleBrand}
                handleFamily={handleFamily}
                selectConvert={selectConvert}
                handleArticle={handleArticle}
                handleSubfamily={handleSubfamily}
                dinamicsProducts={dinamicsProducts}
                setValue={setValue}
            />
        </ContainerFlex>
    );
};
