import styled from '@emotion/styled';
import {IGridContainer} from '@/components/MyAccount/interfaces';

export const GridContainer = styled.div<IGridContainer>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 16px;
    width: 100%;
    overflow-y: auto;
    height: ${({height}) => height || '682px'};
`;

export const PawnImage = styled.img`
    border: 1px solid #e8e9ea;
    width: 48px;
    height: 48px;
    border-radius: 8px;
`;
