import {AppDispatch} from '@/config/store';
import {
    GET_SEARCHBAR,
    GET_SEARCHBAR_ERROR,
    GET_SEARCHBAR_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getSearchBar = () => {
    return {
        type: GET_SEARCHBAR,
    };
};

export const getSearchBarSuccess = (result: AxiosResponse) => {
    return {
        type: GET_SEARCHBAR_SUCCESS,
        payload: result,
    };
};

export const getSearchBarError = (error: AxiosError) => {
    return {
        type: GET_SEARCHBAR_ERROR,
        payload: error,
    };
};

export const getSearchBarAction = (searchName: string, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSearchBar());
        const params = {
            searchName: searchName?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSearchBar}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getSearchBarSuccess(response));
        } catch (error) {
            dispatch(getSearchBarError(error as AxiosError));
        }
    };
};
