import * as yup from 'yup';

const rfcRegex =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const curpRegex = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]{2}$/;
export const shemaCreateClientLevelOne = yup.object().shape(
    {
        imageUser: yup
            .string()
            .required('La imagen es requerida.')
            .typeError('La imagen debe de ser .jpg o .jpeg'),
        names: yup.string().required('Los nombres son requeridos.'),
        lastName: yup.string().required('El apellido paterno es requerido.'),
        secondLastName: yup.string().required('El apellido materno es requerido.'),
        mainPhone: yup
            .string()
            .required('El número de teléfono es requerido.')
            .matches(/^[0-9]*$/, 'El número de teléfono debe contenir solo caracteres númericos.')
            .min(10, 'El número de teléfono debe tener al menos 10 dígitos.'),
        mainEmail: yup
            .string()
            .required('El correo electrónico es requerido.')
            .email('No cumple con el formato de email.'),
        civilStatusId: yup
            .object()
            .shape({
                value: yup.string().required('El estado civil es requerido.'),
                label: yup.string(),
            })
            .required()
            .typeError('El estado civil es requerido.'),
        economicActivityId: yup
            .object()
            .shape({
                value: yup.string().required('La actividad económica es requerida.'),
                label: yup.string(),
            })
            .required()
            .typeError('La actividad económica es requerida.'),
        genderId: yup
            .object()
            .shape({
                value: yup.string().required('El género es requerido.'),
                label: yup.string(),
            })
            .required()
            .typeError('El género es requerido.'),
        countryOfBirthId: yup
            .object()
            .shape({
                value: yup.string().required('El pais donde nació es requerido.'),
                label: yup.string(),
            })
            .required()
            .typeError('El pais donde nació es requerido.'),
        rfc: yup
            .string()
            .matches(rfcRegex, 'El RFC no es válido.')
            .required('El rfc es requerido.'),
        curp: yup
            .string()
            .matches(curpRegex, 'El CURP no es válido.')
            .required('El curp es requerido.'),
        nationality: yup.string().required('La nacionalidad es requerida.'),
        catIdentificationTypeId: yup
            .object()
            .shape({
                value: yup.string().required('El tipo es requerido.'),
                label: yup.string(),
            })
            .required()
            .typeError('El tipo es requerido.'),
        identificactionNumber: yup.string().required('El número es requerido.'),
        validity: yup
            .date()
            .required('La fecha de vigencia es requerida.')
            .typeError('La fecha de vigencia es requerida.'),
        secondEmail: yup.string().email('No cumple con el formato de email.').optional(),
        landline: yup
            .string()
            .optional()
            .matches(/^[0-9]*$/, 'El teléfono fijo debe contenir solo caracteres númericos.'),
        cellPhone: yup
            .string()
            .optional()
            .matches(/^[0-9]*$/, 'El teléfono celular debe contenir solo caracteres númericos.'),

        anotherPhone: yup
            .string()
            .optional()
            .matches(/^[0-9]*$/, 'El otro teléfono debe contenir solo caracteres númericos.'),
        street: yup.string().required('La dirección es requerida.'),
        externalNumber: yup.string().required('El número exterior es requerida.'),
        internalNumber: yup.string().required('El número interior es requerida.'),
        cp: yup.string().required('El código postal es requerido.'),
        colonyId: yup
            .object()
            .shape({
                value: yup.string().required('La colonia es requerida.'),
                label: yup.string(),
            })
            .required()
            .typeError('La colonia es requerida.'),

        municipalityId: yup
            .object()
            .shape({
                value: yup.string().required('El municipio/alcaldía es requerida.'),
                label: yup.string(),
            })
            .required('El municipio/alcaldía es requerida.')
            .typeError('El municipio/alcaldía es requerida.'),

        cityId: yup
            .object()
            .shape({
                value: yup.string().required('La ciudad es requerida.'),
                label: yup.string(),
            })
            .required('La ciudad es requerida.')
            .typeError('La ciudad es requerida.'),
        stateId: yup
            .object()
            .shape({
                value: yup.string().required('El estado es requerido.'),
                label: yup.string(),
            })
            .required('El estado es requerido.')
            .typeError('El estado es requerido.'),
        notes: yup.string().required('Las notas son requeridas.'),
        cotitularNombres0: yup.string().when('cotitularNombres0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El nombre es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularApellidos0: yup.string().when('cotitularApellidos0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('Los apellidos son requeridos.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularTelefono0: yup.string().when('cotitularTelefono0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El número de teléfono es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularCorreoElectronico0: yup.string().when('cotitularCorreoElectronico0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El correo electrónico es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularNombres1: yup.string().when('cotitularNombres1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El nombre es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularApellidos1: yup.string().when('cotitularApellidos1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('Los apellidos son requeridos.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularTelefono1: yup.string().when('cotitularTelefono1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El número de teléfono es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularCorreoElectronico1: yup.string().when('cotitularCorreoElectronico1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El correo electrónico es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularNombres2: yup.string().when('cotitularNombres2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El nombre es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularApellidos2: yup.string().when('cotitularApellidos2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('Los apellidos son requeridos.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularTelefono2: yup.string().when('cotitularTelefono2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El número de teléfono es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularCorreoElectronico2: yup.string().when('cotitularCorreoElectronico2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El correo electrónico es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularNombres3: yup.string().when('cotitularNombres3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El nombre es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularApellidos3: yup.string().when('cotitularApellidos3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('Los apellidos son requeridos.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularTelefono3: yup.string().when('cotitularTelefono3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El número de teléfono es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        cotitularCorreoElectronico3: yup.string().when('cotitularCorreoElectronico3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required('El correo electrónico es requerido.');
            } else {
                return yup.string().notRequired();
            }
        }),
        catCustomerMarketingId: yup
            .object()
            .shape({
                value: yup.string().required('Cómo se enteró de nosotros es requerido.'),
                label: yup.string(),
            })
            .required()
            .typeError('Cómo se enteró de nosotros es requerido.'),
        other: yup.string().optional(),
    },

    [
        ['cotitularNombres0', 'cotitularNombres0'],
        ['cotitularApellidos0', 'cotitularApellidos0'],
        ['cotitularTelefono0', 'cotitularTelefono0'],
        ['cotitularCorreoElectronico0', 'cotitularCorreoElectronico0'],
        ['cotitularNombres1', 'cotitularNombres1'],
        ['cotitularApellidos1', 'cotitularApellidos1'],
        ['cotitularTelefono1', 'cotitularTelefono1'],
        ['cotitularCorreoElectronico1', 'cotitularCorreoElectronico1'],
        ['cotitularNombres2', 'cotitularNombres2'],
        ['cotitularApellidos2', 'cotitularApellidos2'],
        ['cotitularTelefono2', 'cotitularTelefono2'],
        ['cotitularCorreoElectronico2', 'cotitularCorreoElectronico2'],
        ['cotitularNombres3', 'cotitularNombres3'],
        ['cotitularApellidos3', 'cotitularApellidos3'],
        ['cotitularTelefono3', 'cotitularTelefono3'],
        ['cotitularCorreoElectronico3', 'cotitularCorreoElectronico3'],
    ]
);

export const shemaCreateClientLevelTwo = yup.object().shape({
    typeOfReceiptId: yup
        .object()
        .shape({
            value: yup.string().required('El tipo de comprobante es requerido.'),
            label: yup.string(),
        })
        .required()
        .typeError('El tipo de comprobante es requerido.'),
    effectiveDate: yup
        .date()
        .required('La fecha de vigencia es requerida.')
        .typeError('La fecha de vigencia es requerida.'),
    imageProof: yup
        .string()
        .required('La imagen del comprobante es requerida.')
        .test('fileType', 'La imagen debe ser .jpg o .png', function (value) {
            const {imageFileExtension} = this.parent;
            return imageFileExtension && ['jpg', 'png'].includes(imageFileExtension.toLowerCase());
        }),
    imageFileExtension: yup
        .string()
        .required('La extensión del archivo es requerida.')
        .oneOf(['jpg', 'png'], 'La imagen debe ser .jpg o .png'),
});

export const schemaCreateClientLevelThree = yup.object().shape({
    fingerPrint: yup
        .string()
        .required(
            'Subir todas las huellas dactilares antes de proceder con la creación del cliente.'
        ),
});
