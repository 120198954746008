import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {RootState, AppDispatch} from '@config/store';
import {Breadcrum} from '@components/ManageUser/Breadcrum';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {SidebarSettings} from '@components/ManageUser/SidebarSettings';
import {FormUserInformation} from '@components/ManageUser/FormUserInformation';
import {getUserInformation} from '@components/ManageUser/Redux/Actions/GetUserInformationById';
import {GetAllBranches} from '@components/Branches/Redux/Actions/GetAllBranches';

export const ManageUser = () => {
    const dispatch: AppDispatch = useDispatch();
    const {idUser} = useParams();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const successUpdateUser = useSelector((state: RootState) => state.GetAllUsers.postDataUser);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    useEffect(() => {
        if (idUser) dispatch(getUserInformation(parseInt(idUser), token));
    }, [dispatch, idUser, successUpdateUser, token]);
    useEffect(() => {
        dispatch(
            GetAllBranches(
                {
                    companyId,
                    branchName: '',
                    pageSize: 50,
                    pageNumber: 1,
                    orderType: 0,
                    filterBranch: 0,
                },
                token
            )
        );
    }, []);
    return (
        <ContainerFlex
            Height="calc(100vh - 68px)"
            FlexWrap="wrap"
            OverFlow="auto"
            Padding="24px"
            Gap="24px"
            Justify="space-between"
            AlignContent="start"
            Align="start"
        >
            <Breadcrum />
            <SidebarSettings />
            <FormUserInformation />
        </ContainerFlex>
    );
};
