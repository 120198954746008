import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CARD_DATA} from '@/components/MyAccount/constants';
import {DateTime} from 'luxon';

export interface ICreditQuotesOrDateProps {
    statusId: number;
    date?: string;
    paidQuotas?: number;
    totalQuotas?: number;
}

const CreditQuotesOrDate = ({
    statusId,
    date,
    paidQuotas,
    totalQuotas,
}: ICreditQuotesOrDateProps) => {
    const formatDate = date ? DateTime.fromISO(date).toFormat('dd/MM/yyyy') : 'Invalid Date';
    const CreditInfo = (statusId: number) => {
        const statusMap = new Map<number, {title: string; value: string | undefined}>([
            [1, {title: CARD_DATA.CREDIT.QUOTAS, value: `${paidQuotas}/${totalQuotas}`}],
            [2, {title: CARD_DATA.CREDIT.QUOTAS, value: `${paidQuotas}/${totalQuotas}`}],
            [3, {title: CARD_DATA.CREDIT.PAYMENT_DATE, value: formatDate}],
            [4, {title: CARD_DATA.CREDIT.REJECTED_DATE, value: formatDate}],
            [5, {title: CARD_DATA.CREDIT.REQUESTED_DATE, value: formatDate}],
            [6, {title: CARD_DATA.CREDIT.APPROVED_DATE, value: formatDate}],
        ]);
        return statusMap.get(statusId);
    };

    const data = CreditInfo(statusId);

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px" Width="fit-content">
            <Text oFlow="normal" Color="#2A2C2F">
                {data?.title}
            </Text>
            <Text oFlow="normal" Color="#2A2C2F" FontWeight="700">
                {data?.value}
            </Text>
        </ContainerFlex>
    );
};

export default CreditQuotesOrDate;
