import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CUSTOMER_INFORMATION_ERROR,
    GET_CUSTOMER_INFORMATION_START,
    GET_CUSTOMER_INFORMATION_SUCCESS,
    OPEN_CUSTOMER_INFORMATION_MODAL,
    CLOSE_CUSTOMER_INFORMATION_MODAL,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {ICustomerInformationAction} from '@components/CreditsAndPawns/interfaces';

const getCustomerInfoStart = () => {
    return {
        type: GET_CUSTOMER_INFORMATION_START,
    };
};

const getCustomerInfoSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CUSTOMER_INFORMATION_SUCCESS,
        payload: result,
    };
};

const getCustomerInfoError = (error: AxiosError) => {
    return {
        type: GET_CUSTOMER_INFORMATION_ERROR,
        error: error,
    };
};

export const openCustomerInformationModal = (personalInformationId: number) => {
    return {
        type: OPEN_CUSTOMER_INFORMATION_MODAL,
        payload: personalInformationId,
    };
};

export const closeCustomerInformationModal = () => {
    return {
        type: CLOSE_CUSTOMER_INFORMATION_MODAL,
    };
};

export const getCustomerInformationAction = ({
    token,
    personalInformationId,
}: ICustomerInformationAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAdditionalCustomerInformation}?personalInformationId=${personalInformationId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCustomerInfoStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getCustomerInfoSuccess(response.data));
        } catch (e) {
            dispatch(getCustomerInfoError(e as AxiosError));
        }
    };
};
