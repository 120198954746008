import React from 'react';

export function HeadTableIncidentsFoundry() {
    return (
        <thead className="table-containers bg-white">
            <tr>
                <th className="text-center">Usuario</th>
                <th className="text-right">Porcentaje</th>
                <th>Monto</th>
                <th className="text-center"></th>
            </tr>
        </thead>
    );
}
