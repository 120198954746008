import {AxiosResponse} from 'axios';
import {
    CAT_BUSINESS,
    CAT_BUSINESS_SUCCESS,
    CAT_BUSINESS_ERROR,
    CAT_PLACES,
    CAT_PLACES_SUCCESS,
    CAT_PLACES_ERROR,
} from '@components/SignUp/Redux/types';

const initialState = {
    error: false,
    loading: false,
    catBusiness: null,
    catPlaces: null,
};

const catPlaceAndBusiness = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case CAT_BUSINESS:
            return {...state, loading: true};
        case CAT_BUSINESS_ERROR:
            return {...state, loading: false, error: true};
        case CAT_BUSINESS_SUCCESS:
            return {...state, loading: false, catBusiness: action.payload.data};
        case CAT_PLACES:
            return {...state, loading: true};
        case CAT_PLACES_ERROR:
            return {...state, loading: false, error: true};
        case CAT_PLACES_SUCCESS:
            return {...state, loading: false, catPlaces: action.payload.data};
        default:
            return state;
    }
};
export default catPlaceAndBusiness;
