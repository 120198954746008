import {
    POST_EMAIL_CONTACT_START,
    POST_EMAIL_CONTACT_SUCCESS,
    POST_EMAIL_CONTACT_ERROR,
} from '@MyCompany/Redux/Types/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    data: false,
};

export const PostEmailContact = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case POST_EMAIL_CONTACT_START:
            return {
                ...state,
                loading: true,
            };

        case POST_EMAIL_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            };
        case POST_EMAIL_CONTACT_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
export default PostEmailContact;
