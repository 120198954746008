import {
    POST_ADD_EXCESS_MOTIVE,
    POST_ADD_EXCESS_MOTIVE_SUCCESS,
    POST_ADD_EXCESS_MOTIVE_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const retunrExcess = (state = initialState, action) => {
    switch (action.type) {
        case POST_ADD_EXCESS_MOTIVE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case POST_ADD_EXCESS_MOTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: null,
            };
        case POST_ADD_EXCESS_MOTIVE_ERROR:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
