import {AppDispatch} from '@/config/store';
import {ADD_CART_PAYMENT, ADD_CART_PAYMENT_SUCCESS, ADD_CART_PAYMENT_ERROR} from '@TypesLoan/types';
import {URL} from '@config/constants/index';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';

import axios, {AxiosError} from 'axios';
import {IAddCartPayment, ISummaryCard} from '@components/Loan/Redux/interfaces';
import {routers} from '@/appRoute';
import {NavigateFunction} from 'react-router-dom';

export const addCartPay = () => {
    return {
        type: ADD_CART_PAYMENT,
    };
};
export const addCartPaySuccess = (result: ISummaryCard) => {
    return {
        type: ADD_CART_PAYMENT_SUCCESS,
        paymentResult: result,
    };
};
export const addCartPayError = (errorMessage: string) => {
    return {
        type: ADD_CART_PAYMENT_ERROR,
        error: errorMessage,
    };
};

export default function addCartPayment(
    token: string,
    data: IAddCartPayment,
    navigate: NavigateFunction
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddCartPayment}`,
                data,
                {headers}
            );
            dispatch(addCartPaySuccess(response.data));
            navigate(routers.PaymentQuoterConfirmation);
            return response.data;
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(toggleLoading(false));
            dispatch(addCartPayError(err.message));
            throw err;
        }
    };
}
