import {AppDispatch} from '@/config/store';

import {ISaveSearchValuator} from '@Quoter/inferfaces';
import {resetInputDinamics} from '@Quoter/Redux/Actions/PropertiesArticle';
import {
    QUOTER_RESET,
    SEARCH_VALUATION_STEP,
    CHANGE_ACTUAL_STEP,
    ID_CONTRACT,
    RESET_ALL,
} from '@Quoter/Redux/Types/Types';
import {resetPassQuoter} from '@Quoter/Redux/Actions/StatusStepOne';
import {resetEditQuoter} from '@/components/ShoppingCart/Redux/Actions/getInfoContractActions';

export const OverResetAll = () => {
    return {
        type: RESET_ALL,
    };
};
export const saveSearchValuation = (value: ISaveSearchValuator) => {
    return {
        type: SEARCH_VALUATION_STEP.SEARCH,
        productSearch: value,
    };
};

export const saveSearchIds = (value: unknown) => {
    return {
        type: SEARCH_VALUATION_STEP.ID,
        searchID: value,
    };
};

export const isForFilters = (value: boolean) => {
    return {
        type: SEARCH_VALUATION_STEP.FILTERS,
        filters: value,
    };
};

export const addDinamics = (value: unknown) => {
    return {
        type: SEARCH_VALUATION_STEP.DINAMICS,
        inputDinamics: value,
    };
};

export const saveStadistics = (value: unknown) => {
    return {
        type: SEARCH_VALUATION_STEP.STADISTICS,
        stadistics: value,
    };
};
export const changeActualStep = (step: number) => {
    return {
        type: CHANGE_ACTUAL_STEP,
        step: step,
    };
};
export const resetSaveQuoter = () => ({type: QUOTER_RESET});

export const restarQuoter = () => {
    return (dispatch: AppDispatch) => {
        dispatch(resetInputDinamics());
        dispatch(resetSaveQuoter());
        dispatch(resetPassQuoter());
        dispatch(resetEditQuoter());
    };
};

export const saveIdContract = (idContract: number) => ({
    type: ID_CONTRACT,
    idContract: idContract,
});
