import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    HOLIDAYS,
    isOptional,
    LAST_LOCATION,
    NEWSTORAGE,
    storageInventory,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import * as style from '@components/LocationsBranch/stylesLocations';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {EditForm, IAddStorage, INewStorageProps} from '@components/LocationsBranch/interfaces';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {AddressLocations} from '@components/LocationsBranch/AddressLocations';
import {
    getStorage,
    newStorage,
    updateStorage,
} from '@components/LocationsBranch/Redux/Action/NewStorage';
import {newStorageShema} from '@components/LocationsBranch/createLocationsErrors';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {resetFormLocation} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormIntialization';

export const NewStorage = ({setModalAddress, storageId}: INewStorageProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState({addUser: false, locations: false});

    const {storages, addStorage, token, inventory, levels, AddUserEmployee} = useSelector(
        (state: RootState) => ({
            storages: state.getCreateGroupBranch,
            levels: state.getLevels.level,
            token: state.getUsersValidation.userData?.token as string,
            company: state.getUsersValidation.userData?.enterpriceDetails?.[0]
                ?.enterpriceId as number,
            addStorage: state.newStorage,
            inventory: state.Warehouse.warehouses,
            AddUserEmployee: state.AddUserEmployee,
        })
    );

    const {
        watch,
        reset,
        register,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameStorage: '',
            storageDirection: {},
            users: [],
            storages: [],
        },
        resolver: yupResolver(newStorageShema),
    });

    const {initialize} = useInitializeForm();

    const onsubmit = (values: FieldValues) => {
        const {nameStorage, storageDirection} = values;
        const storageUsers = AddUserEmployee.addedUsers.map((user) => user.employeeId);
        const commonBody = {
            storageDirection: {
                ...storageDirection,
                colonyId: storageDirection.colony,
            },
            storageUsers,
        };

        const createBody = {
            ...commonBody,
            levelId: levels?.levelId,
            ...isOptional(
                'storageDetail',
                storageInventory(inventory, 'nameStorage', 'storageDetail')
            ),
        };

        const actionBody: IAddStorage = storageId
            ? {...commonBody, storageId, storageName: nameStorage}
            : {...createBody, nameStorage};

        const action = storageId
            ? updateStorage(token, navigate, actionBody)
            : newStorage(token, navigate, actionBody);

        dispatch(action);
    };

    const cancelOperation = async () => {
        resetFormLocation(dispatch);
        navigate('/LocationBranch');
    };

    const getEditStorage = () => {
        if (!levels.levelId || !storageId) return;

        const body = {
            LevelId: levels.levelId,
            StorageId: storageId,
        };
        dispatch(getStorage(token, body));
    };

    const initializeStorage = () => {
        const {
            storageName = '',
            storageDirection = null,
            description = '',
            storagesDetail = null,
            userStorage = [],
        } = addStorage.editStorage || {};

        const source: EditForm = {
            name: storageName,
            levelId: levels.levelId,
            description,
            storagesDetail,
            users: userStorage,
            address: storageDirection,
        };
        initialize(source, NEWSTORAGE.NAME_FORM, setValue);
    };

    useEffect(() => {
        if (!levels.levelId || !storageId) return;
        getEditStorage();
    }, [storageId]);

    useEffect(() => {
        if (addStorage.editStorage) {
            initializeStorage();
        }
    }, [addStorage.editStorage]);

    return (
        <>
            <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
                {storages.loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    <>
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700">
                            {NEWSTORAGE.TITLE}
                        </Text>
                        <NameLocation
                            newName={addStorage}
                            nameLocation={NEWSTORAGE.NAME_FORM}
                            register={register}
                            errors={errors}
                            watch={watch}
                            reset={reset}
                        />
                        <RoutesLocations lastLevel={LAST_LOCATION.NEW_STORAGE} />
                        <AddressLocations
                            errors={errors}
                            setValue={setValue}
                            nameAddress={NEWSTORAGE.ADDRESS_FORM}
                            setModalAddress={setModalAddress}
                        />
                        <UserGroup modal={modal} setModal={setModal} setValue={setValue} />
                        <LocationStorage isOptional={true} />
                        {addStorage.loading ? (
                            <ContainerFlex Height="2.5rem">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Gap="1.5rem" Height="2.5rem">
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.CANCEL}
                                    clic={() => cancelOperation()}
                                    color="#5A5AFF"
                                    backGround="#FFFFFF"
                                    hText="#5A5AFF"
                                    hShadow=""
                                    height="2.5rem"
                                    cursor="pointer"
                                    border="1px solid #5A5AFF"
                                    hBackG="#E5E5FF"
                                />
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.SAVE}
                                    type="submit"
                                    height="2.5rem"
                                    cursor="pointer"
                                />
                            </ContainerFlex>
                        )}
                    </>
                )}
            </ContainerForm>
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
        </>
    );
};
