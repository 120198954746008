import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    REJECT_LOAN_START,
    REJECT_LOAN_SUCCESS,
    REJECT_LOAN_ERROR,
    TOGGLE_REJECT_MODAL,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const rejectLoanStart = () => {
    return {
        type: REJECT_LOAN_START,
    };
};

export const rejectLoanSuccess = (result: AxiosResponse) => {
    return {
        type: REJECT_LOAN_SUCCESS,
        payload: result.data,
    };
};

export const rejectLoanError = (error: AxiosError) => {
    return {
        type: REJECT_LOAN_ERROR,
        payload: error,
    };
};

export const toggleRejectModal = () => {
    return {
        type: TOGGLE_REJECT_MODAL,
    };
};

export const rejectLoan = (personalCreditId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(rejectLoanStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeletePersonalLoan}`,
                {
                    personalCreditId,
                },
                {
                    headers,
                }
            );
            dispatch(rejectLoanSuccess(response));
        } catch (error) {
            dispatch(rejectLoanError(error as AxiosError));
        }
    };
};
