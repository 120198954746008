import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableDelivery() {
    return (
        <>
            <TableHead mt>
                <tr>
                    <th></th>
                    <th>No. de Seguribolsa</th>
                    <th>No. de Papeleta</th>
                    <th>Recolectora</th>
                    <th>Fundidora</th>
                    <th>Quien armó la bolsa</th>
                    <th>Última fecha de edición</th>
                </tr>
            </TableHead>
        </>
    );
}
