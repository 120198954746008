import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    SHOPPING_CAR_CLIENT_ERROR,
    SHOPPING_CAR_CLIENT_START,
    SHOPPING_CAR_CLIENT_SUCCESS,
    GET_DETAILS_SHOPPING_CAR_SUCCESS,
    SHOW_MODAL,
    CLOSE_MODAL,
    ADD_CREDIT_SELECTED,
    UNSELECT_CREDITS_SELECTED,
    REMOVE_CREDIT_SELECTED,
    SHOW_WARNING_MESSAGE,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {
    IDetailLoansCarCredit,
    IPersonalCreditDetails,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const shoppingCarClientError = (error: AxiosError) => {
    return {
        type: SHOPPING_CAR_CLIENT_ERROR,
        error: error,
    };
};

const shoppingCarClientStart = () => {
    return {
        type: SHOPPING_CAR_CLIENT_START,
    };
};

const shoppingCarClientSuccess = (result: AxiosResponse) => {
    return {
        type: SHOPPING_CAR_CLIENT_SUCCESS,
        payload: result,
    };
};

const getDetailsShoppingCarSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DETAILS_SHOPPING_CAR_SUCCESS,
        payload: result,
    };
};

export const getDetailsShoppingCarAction = (token: string, carClientId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DetailsCar.replace(
        '{carClientId}',
        carClientId
    )}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getDetailsShoppingCarSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

const addCreditToBackPackAction = ({
    token,
    carClientId,
    personalCredit,
}: {
    token: string;
    carClientId: string;
    personalCredit: IPersonalCreditDetails;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddBackPackClient}`;
    const {personalCreditId, crontactNumber, paymentId, paymentAmount} = personalCredit;
    const params = {
        carShoppingId: carClientId,
        paymentCredits: [
            {
                solicitudeId: personalCreditId,
                solicitudeNumber: crontactNumber,
                details: {
                    amountAbono: paymentAmount,
                    paymentCreditId: paymentId,
                },
            },
        ],
    };
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.post(url, params, {headers});
            dispatch(showModalAction());
            dispatch(shoppingCarClientSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

export const addCreditToShoppingCarAction = (
    personalCreditId: number,
    token: string,
    carClientId: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetByIdPersonalCreditAmount}`,
                {headers, params: {personalCreditId}}
            );
            const personalCredit: IPersonalCreditDetails = response?.data?.data ?? null;
            if (personalCredit) {
                dispatch(addCreditToBackPackAction({token, carClientId, personalCredit}));
            }
        } catch (error) {
            dispatch(shoppingCarClientError(error as AxiosError));
        }
    };
};

export const deleteCreditToShoppingCarAction = ({
    token,
    paymentCreditId,
    carClienteId,
}: {
    token: string;
    paymentCreditId: number;
    carClienteId: number;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteItemBackPackClient}`;
    const params = {carClienteId, paymentCreditId};
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.delete(url, {
                headers,
                data: params,
            });
            dispatch(shoppingCarClientSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

export const showModalAction = () => {
    return {
        type: SHOW_MODAL,
    };
};

export const closeModalAction = () => {
    return {
        type: CLOSE_MODAL,
    };
};

export const showWarningMessageAction = () => {
    return {
        type: SHOW_WARNING_MESSAGE,
    };
};

export const addCreditSelectedAction = (credit: IDetailLoansCarCredit) => {
    return {
        type: ADD_CREDIT_SELECTED,
        credit,
    };
};

export const removeCreditSelectedAction = (credit: IDetailLoansCarCredit) => {
    return {
        type: REMOVE_CREDIT_SELECTED,
        credit,
    };
};

export const unselectCreditsSelectedAction = () => {
    return {
        type: UNSELECT_CREDITS_SELECTED,
    };
};
