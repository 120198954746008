import {
    PROPERTIES_ARTICLES_REQUEST,
    PROPERTIES_ARTICLES_REQUEST_SUCCESS,
    PROPERTIES_ARTICLES_REQUEST_ERROR,
    CANCEL_QUOTER_REQUEST,
    LEVELS_REQUEST_SOL,
    PROPERTIES_ARTICLES_REQUEST_ID,
    RESET_ARTCILE_DINAMICS_REQUEST,
} from '@TypesQouter/Types';
import {IDinamics, IReducerDinamics} from '@Quoter/inferfaces';

const initialState: IDinamics = {
    group: {breadCrumbs: null, groupList: []},
    family: {breadCrumbs: null, familyData: []},
    subfamily: {breadCrumbs: null, subfamilyList: []},
    brand: {breadCrumbs: null, brandList: []},
    article: {breadCrumbs: null, articleList: []},
    propertiesarticles: [],
    propertiesId: [],
    error: false,
    loading: false,
    loadingArticle: false,
};

const propertiesArticleRequest = (state = initialState, actions: IReducerDinamics): IDinamics => {
    switch (actions.type) {
        case PROPERTIES_ARTICLES_REQUEST:
            return {...state, loading: true, loadingArticle: actions.loadingArticle};
        case LEVELS_REQUEST_SOL.LEVELGROUP:
            return {
                ...state,
                group: actions.group,
                loadingArticle: actions.loadingArticle,
                loading: false,
            };
        case LEVELS_REQUEST_SOL.LEVELFAMILY:
            return {
                ...state,
                family: actions.family,
                subfamily: {breadCrumbs: null, subfamilyList: []},
                brand: {breadCrumbs: null, brandList: []},
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS_REQUEST_SOL.LEVELSUBFAMILY:
            return {
                ...state,
                subfamily: actions.subfamily,
                brand: {breadCrumbs: null, brandList: []},
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS_REQUEST_SOL.LEVELBRAND:
            return {
                ...state,
                brand: actions.brand,
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS_REQUEST_SOL.LEVELARTICLE:
            return {
                ...state,
                article: actions.article,
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS_REQUEST_SOL.LOADING:
            return {...state, loadingArticle: actions.loadingArticle};
        case PROPERTIES_ARTICLES_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingArticle: actions.loadingArticle,
                propertiesarticles: actions.propertiesarticles,
            };
        case PROPERTIES_ARTICLES_REQUEST_ERROR:
            return {...state, error: false, loading: true};
        case CANCEL_QUOTER_REQUEST:
            return {...state, loading: false, article: {breadCrumbs: null, articleList: []}};
        case PROPERTIES_ARTICLES_REQUEST_ID:
            return {
                ...state,
                propertiesId: actions.propertiesId,
                loading: false,
            };
        case RESET_ARTCILE_DINAMICS_REQUEST:
            return {...state, loading: false, propertiesarticles: []};
        default:
            return state;
    }
};
export default propertiesArticleRequest;
