import React from 'react';

export function HeadTableSecurityBagWeight() {
    return (
        <thead className="title-head-foundry">
            <tr>
                <th className="text-center">Folio de papeleta</th>
                <th className="text-center">Seguribolsa</th>
                <th className="pl-1">Sucursal</th>
                <th className="pl-1">Fecha de elaboración</th>
                <th className="text-center">Valor</th>
            </tr>
        </thead>
    );
}
