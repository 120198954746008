import {
    DELETE_UPDATE_LEVELS_ERROR,
    DELETE_UPDATE_LEVELS_START,
    DELETE_UPDATE_LEVELS_SUCCESS,
    GET_LEVELS,
    GET_LEVELS_ERROR,
    GET_LEVELS_SUCCESS,
    PASS_ORGANIZATION,
    POST_CREATE_LEVELS_ERROR,
    POST_CREATE_LEVELS_START,
    POST_CREATE_LEVELS_SUCCESS,
    POST_UPDATE_LEVELS_ERROR,
    POST_UPDATE_LEVELS_START,
    POST_UPDATE_LEVELS_SUCCESS,
    SELECT_FATHER_ID,
    SELECT_NODE_ID,
    SELECT_ORGANIZATION_NAME,
    SELECT_STATE_MODAL_ORG,
    UP_STATUS,
    IS_EDIT,
} from '@TypesOrgCreation/types';
import {FieldValues} from 'react-hook-form';

const initialState: {
    node: [];
    passOrg: number;
    fatherId: FieldValues;
    pageNumber: null;
    nameOrganization: FieldValues;
    nodeId: FieldValues;
    statemodal: boolean;
    upStatus: FieldValues;
    totalPages: number;
    isEdit: boolean;
    loading: boolean;
    error: boolean;
} = {
    node: [],
    passOrg: 0,
    fatherId: {},
    pageNumber: null,
    nameOrganization: {},
    nodeId: {},
    statemodal: false,
    upStatus: {},
    totalPages: 0,
    isEdit: false,
    loading: false,
    error: false,
};
const getAllLevelsOrganization = (
    state = initialState,
    action: {
        type: string;
        node: [];
        passOrg: number;
        fatherId: FieldValues;
        pageNumber: null;
        nameOrganization: FieldValues;
        nodeId: FieldValues;
        statemodal: boolean;
        upStatus: FieldValues;
        totalPages: number;
        isEdit: boolean;
        loading: boolean;
        error: boolean;
    }
): {
    node: [];
    passOrg: number;
    fatherId: FieldValues;
    pageNumber: null;
    nameOrganization: FieldValues;
    nodeId: FieldValues;
    statemodal: boolean;
    upStatus: FieldValues;
    totalPages: number;
    isEdit: boolean;
    loading: boolean;
    error: boolean;
} => {
    switch (action.type) {
        case GET_LEVELS:
            return {
                ...state,
                loading: true,
            };
        case GET_LEVELS_SUCCESS:
            return {
                ...state,
                node: action.node,
                pageNumber: action.pageNumber,
                totalPages: action.totalPages,
                loading: false,
            };
        case GET_LEVELS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_CREATE_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_CREATE_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CREATE_LEVELS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case POST_UPDATE_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_UPDATE_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_UPDATE_LEVELS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case DELETE_UPDATE_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_UPDATE_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_UPDATE_LEVELS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case PASS_ORGANIZATION:
            return {
                ...state,
                passOrg: action.passOrg,
            };
        case SELECT_FATHER_ID:
            return {
                ...state,
                fatherId: action.fatherId,
            };
        case SELECT_ORGANIZATION_NAME:
            return {
                ...state,
                nameOrganization: action.nameOrganization,
            };
        case SELECT_NODE_ID:
            return {
                ...state,
                nodeId: action.nodeId,
            };
        case UP_STATUS:
            return {
                ...state,
                upStatus: action.upStatus,
                loading: false,
            };
        case SELECT_STATE_MODAL_ORG:
            return {
                ...state,
                statemodal: action.statemodal,
                loading: false,
            };
        case IS_EDIT:
            return {
                ...state,
                isEdit: action.isEdit,
                loading: false,
            };
        default:
            return state;
    }
};

export default getAllLevelsOrganization;
