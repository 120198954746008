import styled from '@emotion/styled';
import {
    IPropsTableRowHeadContract,
    IPropsTableRowItemsContract,
} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/ChangeOfProducts/interfacesStyles';

export const TableRowHeadContract = styled.div<IPropsTableRowHeadContract>`
    display: grid;
    grid-template-columns: ${(props) => props.GridColumn};
    height: 52px;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border: 1px solid #e4e7e9;
    border-radius: 5px;
`;
TableRowHeadContract.defaultProps = {
    GridColumn: '0.3fr 1fr 2fr 1fr',
};

export const TableRowItemsContract = styled.div<IPropsTableRowItemsContract>`
    display: grid;
    margin-top: 5px;
    border-radius: 5px;
    grid-template-columns: ${(props) => props.GridColumn};
    margin: ${(props) => props.Margin};
    background: #fff;
    min-height: 3rem;
    max-height: 6rem;
    &:hover {
        cursor: pointer;
        background-color: #f5fbff;
    }
    i {
        color: #757575;
        font-size: 13px;
    }
`;
TableRowItemsContract.defaultProps = {
    GridColumn: '0.3fr 1fr 2fr 1fr',
    Margin: '10px',
};
