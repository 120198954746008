import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import '@Payment/style.css';
import {
    getTransaction,
    AddCarrito,
    deleteCardPayment,
} from '@Products/redux/actions/ProductsShopifyActions';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import visa from '@images/Ecommerce/visa.svg';
import mc from '@images/Ecommerce/mastercard (1).svg';
import {numberToCurrency} from '@CashFlow/constants';
import * as Styled from '@CashFlow/styles';
import {InfoContainer} from '@EcommerceShopify/Subcontainer/ContainerInfo';
import {getInfoClient} from '@EcommerceShopify/redux/actions/HomeClientActions';
import {IconContainer} from '@/components/Quoter/styles';
import {
    Text,
    PaymentImg,
    ContainerFlex,
    PayCardsContainer,
    Input,
    RowDetailPayCardP,
    RowDetailPay,
    ContainerForm,
} from '@EcommerceShopify/styles';
import {DateTime} from 'luxon';
import Title from '@CashFlow/Title';
import {getCards, postCards} from '@EcommerceShopify/redux/actions/HomeClientActions';
import {StyleNumericFormat} from '@Loan/styles';
import {BreadcrumbEndeavor} from '@Endeavors/BreadCrumb/BreadcrumbsEndeavor';
import {ImagesArticle} from '@Payment/styles';
import {deleteCarShopi} from '@Products/redux/actions/ProductsShopifyActions';
import monetization from '@images/monetization.svg';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';

import {PaymentRequest} from '@Loan/Redux/actions/PayQuoterAct';
import {deleteCarShopiValuator} from '@Products/redux/actions/ProductsShopifyActions';
import {limitNumberFormat} from '@/hooks/currentFormatUtils';

export function Payment() {
    const {
        handleSubmit,
        register,
        watch,
        control,
        formState: {errors},
    } = useForm({
        defaultValues: {
            costumer: '',
            savecard: true,
            number: '',
            expMonth: 0,
            expYear: 0,
            cvc: '',
            refrendo: [{number: 0}],
            amount: '',
        },
    });
    const watchAllFields = watch();
    const dispatch = useDispatch();
    const sumatoria = () => {
        const res = productsshopify.carritoprendas
            .filter((item) => item.itIsEndorsement === false)
            .reduce((acc, item) => {
                return (acc += item.dischargeAmount);
            }, 0);

        return res;
    };
    const homeclient = useSelector((state) => {
        return state.homeclient;
    });

    const [payment, setpayment] = useState({});
    const sumatoriatotal = () => {
        return sumatoria() + totalPaymentEndorsement();
    };
    const clientId = useSelector((state) => {
        return state.logins.login.data.clientId;
    });
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    useEffect(() => {
        dispatch(
            getCards(token, clientId === undefined ? homeclient.infoclient.clientId : clientId)
        );
        dispatch(
            getInfoClient(clientId === undefined ? homeclient.infoclient.clientId : clientId, token)
        );
    }, [clientId, dispatch, homeclient.infoclient.clientId, token]);

    const productsshopify = useSelector((state) => {
        return state.productsshopify;
    });
    const userType = useSelector((state) => {
        return state.logins.login.data.userType;
    });

    const [valorTarjeta, setValorTarjeta] = useState(0);
    const [dTarjeta, setdTarjeta] = useState([]);
    const traerDatosTarjeta = (idTarjeta) => {
        setValorTarjeta(idTarjeta);
        setdTarjeta(homeclient.cards.data.filter((tjta) => tjta.accountId === idTarjeta));
    };

    useEffect(() => {
        if (token && watch('number' && 'customer' && 'expiration')) {
            setValorTarjeta(0);
        }
    }, []);
    const valuatorPayment = () => {
        let DataUndorsementPawn = productsshopify.carritoprendas.map((item) => {
            let ComponentConstruct = {};
            ComponentConstruct = {
                movementsTypeId: 1,
                operationId: Number(
                    item.item.dischargeEndorsement.map((item) => {
                        return item.id;
                    })
                ),
                endorementRes: {
                    unitAmmount: item.endorsementAmount,
                    totalAmmount: sumatoriatotal(),
                    endorementReceipts: Object.values(payment).map((item) => {
                        return {
                            catPaymentTypeId: 1,
                            amount: Number(item.replace('$ ', '').replace(',', '')),
                        };
                    }),
                },
            };
            if (item.itIsEndorsement === false) {
                ComponentConstruct = {
                    movementsTypeId: 2,
                    operationId: Number(
                        item.item.dischargeEndorsement.map((item) => {
                            return item.id;
                        })
                    ),
                    payDetail: [
                        {
                            catPaymentTypeId: 1,
                            amount: item.dischargeAmount,
                            dataPayCard: {
                                bankId: 1,
                                extract: '1234',
                                bankCardId: 1,
                            },
                        },
                    ],
                };
            }
            return ComponentConstruct;
        });
        let PostedData = {
            shoppingCarId: shoppingId,
            dataPawn: [],
            movementsTypes: DataUndorsementPawn,
        };
        dispatch(PaymentRequest(token, PostedData, 'Pawn'));
    };
    const onSubmit = async (values) => {
        if (userType === 'Client') {
            if (!valorTarjeta) {
                let jsoncards = {
                    clientId: clientId === undefined ? homeclient.infoclient.clientId : clientId,
                    nameOwner: values.customer,
                    cardNumber: values.number.split('-').join(''),
                    cvc: values.cvc,
                    bank: 1,
                    expMonth: Number(values.expiration.substring(0, 2)),
                    expYear: Number(values.expiration.substring(3, 5)),
                };
                await dispatch(postCards(token, jsoncards));
            }
            await dispatch(getCards(token, clientId));
            traerDatosTarjeta(homeclient?.extractcard);

            const res = productsshopify.carritoprendas.reduce((acc, item) => {
                return (acc += item.dischargeAmount);
            }, 0);
            let PostData = productsshopify.carritoprendas.map((item) => {
                if (item.itIsEndorsement === false) {
                    let DataBase = {
                        movementsClientTypeId: 2,
                        operationId: item.id,
                        payClientDetail: [
                            {
                                catPaymentTypeId: 2,
                                amount: res,
                                dataPayCard: {
                                    bankId: 3,
                                    extract:
                                        valorTarjeta !== 0
                                            ? dTarjeta[0].extract
                                            : homeclient.extractcard.extract,
                                    bankCardId: 3,
                                },
                            },
                        ],
                    };
                    return DataBase;
                }
                if (item.itIsEndorsement === true) {
                    let DataBase = {
                        movementsClientTypeId: 1,
                        operationId: item.id,
                        endorementClientRes: {
                            unitAmmount: Number(item.endorsementAmount),
                            totalAmmount: sumatoriatotal(),
                            endorementReceipts: [
                                {
                                    catPaymentTypeId: 2,
                                    amount: totalPaymentEndorsement(),
                                },
                            ],
                        },
                    };
                    return DataBase;
                }
                return PostData;
            });
            let PostCart = {
                shoppingCarClientId: shoppingId,
                clientId: clientId,
                movementsClientTypes: PostData,
            };
            dispatch(getTransaction(PostCart, token));
        }
        if (userType !== 'Client') {
            valuatorPayment();
        }
    };

    const DeleteCardSelected = async (data) => {
        const valuesR = {
            accountId: data,
            clientId: clientId === undefined ? homeclient.infoclient.clientId : clientId,
        };
        await dispatch(deleteCardPayment(valuesR, token));
        await dispatch(getCards(token, clientId));
        await dispatch(AddCarrito('Client', shoppingId, token));
    };
    const shoppingId = useSelector((state) => {
        return state.getUsersValidation.userData?.carShoopingId;
    });
    const [information, setInformation] = useState({
        view: true,
        complete: true,
    });
    const [detail, setDetail] = useState({view: false, complete: false});
    const [payments, setPayments] = useState({view: false, complete: false});

    const validateButton = () => {
        let flagDisable = false;
        const data = productsshopify?.carritoprendas.filter(
            (data) => data.itIsEndorsement === true
        ).length;

        if (
            userType === 'Client' &&
            (data !== Object.entries(payment).length ||
                (!valorTarjeta && watchAllFields.cvc === ''))
        ) {
            flagDisable = true;
        }

        if (
            userType === 'Employee' &&
            (data !== Object.entries(payment).length ||
                Number(watchAllFields.amount?.replace('$ ', '').replace(',', '')) !==
                    sumatoriatotal())
        ) {
            flagDisable = true;
        }
        return flagDisable;
    };

    const DetectedTypeCard = (number) => {
        const cards = [
            {Id: 1, name: 'AMEX', begins: [34, 37], length: [15]},
            {Id: 4, name: 'Otra', begins: [6011], length: [16]},
            {Id: 2, name: 'Mastercard', begins: [51, 52, 53, 54, 55], length: [16]},
            {Id: 3, name: 'VISA', begins: [4], length: [13, 16]},
        ];

        const begin = (arr, number) => arr.some((data) => number.startsWith(data));
        const length = (arr, number) => arr.some((data) => data === number.length);

        cards.forEach((element) => {
            if (
                begin(element.begins, number.toString()) &&
                length(element.length, number.toString())
            ) {
                return element.Id;
            }
        });
    };

    const DeletePawEndorsement = async (id) => {
        if (userType === 'Client') {
            if (productsshopify.carritoprendas.length > 0) {
                let filter = {
                    detailCarClientId: id,
                    carClienteId: shoppingId,
                };
                await dispatch(deleteCarShopi(filter, token));
                await dispatch(AddCarrito('Client', shoppingId, token));
            }
        } else {
            if (productsshopify.carritoprendas.length > 0) {
                let filter = {
                    dischargeEndorementId: id,
                };
                await dispatch(deleteCarShopiValuator(filter, token));
                await dispatch(AddCarrito('Employee', shoppingId, token));
            }
        }
    };
    //TODO L.F.P.H TL
    const totalPaymentEndorsement = () => {
        let sum = 0;
        for (const key in payment) {
            sum += Number(payment[key].replace('$', ' '));
        }
        return sum;
    };
    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            Align="center"
            PaddinsmWidthg="1.344rem 6.5rem 1.344rem 6.25rem"
            Justify="space-between"
            FlexDir="column"
            Width="100%"
        >
            <BreadcrumbEndeavor />
            {productsshopify?.carritoprendas.length === 0 ? (
                <ContainerFlex>
                    <Text>El carrito esta vacío</Text>
                </ContainerFlex>
            ) : (
                <ContainerFlex Justify="start" Align="start">
                    <ContainerFlex FlexDir="column" Gap="8px">
                        <ContainerFlex
                            Align="flex-start"
                            FlexDir="column"
                            Justify="flex-start"
                            Border="1px solid #E4E7E9"
                            Radius="5px"
                        >
                            <ContainerFlex
                                Align="flex-start"
                                Justify="flex-start"
                                Bb={information.view ? '1px solid #E4E7E9' : ''}
                                Radius="5px"
                                onClick={() =>
                                    setInformation({...information, view: !information.view})
                                }
                                Cursor="pointer"
                            >
                                <Title
                                    numberTitle="1"
                                    nameTitle="Información general"
                                    bgColor="var(--green)"
                                    fontColor="white"
                                    showEditIcon="none"
                                    showNumber={!(!information.view && information.complete)}
                                />
                                <Styled.EditIcon isDisplay="flex">
                                    <span className="material-icons">expand_more</span>
                                </Styled.EditIcon>
                            </ContainerFlex>
                            {information.view && <InfoContainer information={information.view} />}
                        </ContainerFlex>

                        <ContainerFlex
                            Align="flex-start"
                            FlexDir="column"
                            Justify="flex-start"
                            Border="0.5px solid #E4E7E9"
                            Radius="5px"
                        >
                            <ContainerFlex
                                Align="flex-start"
                                Justify="flex-start"
                                Radius="5px"
                                Bb={detail.view ? '1px solid #E4E7E9' : ''}
                                onClick={() => setDetail({...detail, view: !detail.view})}
                                Cursor="pointer"
                            >
                                <Title
                                    numberTitle="2"
                                    nameTitle="Detalle del pedido"
                                    bgColor="var(--green)"
                                    fontColor="white"
                                    showEditIcon="none"
                                    showNumber={!(!detail.view && detail.complete)}
                                />

                                <Styled.EditIcon isDisplay="flex">
                                    <span className="material-icons">expand_more</span>
                                </Styled.EditIcon>
                            </ContainerFlex>

                            {detail.view && (
                                <>
                                    <ContainerFlex
                                        FlexDir="column"
                                        Bb="10px solid #E4E7E9"
                                        Padding="0 0 20px 0"
                                    >
                                        {productsshopify?.carritoprendas.some(
                                            (data) => data.itIsEndorsement === true
                                        ) ? (
                                            <ContainerFlex Height="none">
                                                <Text
                                                    FontSize="18px"
                                                    Width="100%"
                                                    Margin="20px 0"
                                                    Display="block"
                                                    TextAlign="center"
                                                    FontWeight={600}
                                                >
                                                    REFRENDOS
                                                </Text>
                                            </ContainerFlex>
                                        ) : (
                                            ''
                                        )}

                                        {productsshopify?.carritoprendas.map((data, index) => {
                                            return (
                                                <>
                                                    {data.itIsEndorsement === true && (
                                                        <>
                                                            <ContainerFlex
                                                                Padding="12px"
                                                                Align="flex-start"
                                                                Height="none"
                                                            >
                                                                <RowDetailPay>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Refrendo Folio Nro.
                                                                    </Text>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Fecha:
                                                                    </Text>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Valor a Pagar:
                                                                    </Text>
                                                                    <ContainerFlex
                                                                        Padding="0 1.75rem 0 0"
                                                                        Justify="space-between"
                                                                    >
                                                                        <Text
                                                                            FontWeight="300"
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                        >
                                                                            Abono
                                                                        </Text>
                                                                        <Text
                                                                            Color="#4A4A4A"
                                                                            Justify="end"
                                                                            onClick={() => {
                                                                                DeletePawEndorsement(
                                                                                    data.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <IconContainer
                                                                                className="material-icons"
                                                                                FontSize="1.25rem"
                                                                            >
                                                                                delete_forever
                                                                            </IconContainer>
                                                                        </Text>
                                                                    </ContainerFlex>

                                                                    <>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {data.item.folio}
                                                                        </Text>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {DateTime.fromISO(
                                                                                data.item.todayDate.toISOString()
                                                                            ).toFormat(
                                                                                'DD/MM/YYYY'
                                                                            )}
                                                                        </Text>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {numberToCurrency(
                                                                                data.endorsementAmount
                                                                            )}
                                                                        </Text>
                                                                        <ContainerFlex Height="25px">
                                                                            <StyleNumericFormat
                                                                                Height="25px"
                                                                                prefix="$ "
                                                                                postfix=".00"
                                                                                name={`refrendo[${index}].number`}
                                                                                Margin="0 1rem"
                                                                                type="text"
                                                                                required
                                                                                onChange={(e) =>
                                                                                    setpayment({
                                                                                        ...payment,
                                                                                        [e.target
                                                                                            .name]:
                                                                                            e.target
                                                                                                .value,
                                                                                    })
                                                                                }
                                                                                placeholder={'$ 0'}
                                                                                isAllowed={(
                                                                                    values
                                                                                ) =>
                                                                                    limitNumberFormat(
                                                                                        values.value,
                                                                                        0,
                                                                                        data.endorsementAmount
                                                                                    )
                                                                                }
                                                                                defaultValues={0}
                                                                            />

                                                                            <Text
                                                                                Color="red"
                                                                                FontWeight="400"
                                                                                Margin="0.5rem 1rem"
                                                                                FontSize="12px"
                                                                            >
                                                                                {
                                                                                    errors?.refrendo
                                                                                        ?.message
                                                                                }
                                                                            </Text>
                                                                        </ContainerFlex>
                                                                    </>
                                                                </RowDetailPay>
                                                            </ContainerFlex>
                                                            <ContainerFlex
                                                                Height="none"
                                                                Padding="0 16px"
                                                            >
                                                                <div className="headerTable">
                                                                    <div className="column1-2" />
                                                                    <div className="column2-5">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Nombre Artículo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column5-7">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Avalúo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column7-9">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Préstamo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column9-11">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            % Empeño
                                                                        </Text>
                                                                    </div>
                                                                </div>
                                                            </ContainerFlex>
                                                            <ContainerFlex
                                                                Height="none"
                                                                Padding="16px"
                                                                Bb="1px solid #E4E7E9"
                                                            >
                                                                <div className="rowTable">
                                                                    <div className="column1-2">
                                                                        <ImagesArticle
                                                                            src={
                                                                                data.item
                                                                                    .imagePawnId
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="column2-5">
                                                                        <p>{data.item.itemName}</p>
                                                                    </div>
                                                                    <div className="column5-7">
                                                                        <p>
                                                                            {numberToCurrency(
                                                                                data.item.appraiser
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="column7-9">
                                                                        <p>
                                                                            {numberToCurrency(
                                                                                data.item.loan
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="column9-11">
                                                                        <p>
                                                                            {
                                                                                data.item
                                                                                    .pawnPercentage
                                                                            }
                                                                            %
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </ContainerFlex>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </ContainerFlex>

                                    <ContainerFlex FlexDir="column">
                                        {productsshopify?.carritoprendas.some(
                                            (data) => data.itIsEndorsement === false
                                        ) && (
                                            <ContainerFlex Height="none">
                                                <Text
                                                    FontSize="18px"
                                                    Width="100%"
                                                    Margin="20px 0"
                                                    Display="block"
                                                    TextAlign="center"
                                                    FontWeight={600}
                                                >
                                                    DESEMPEÑOS
                                                </Text>
                                            </ContainerFlex>
                                        )}
                                        {productsshopify?.carritoprendas.map((data) => {
                                            return (
                                                <>
                                                    {data.itIsEndorsement === false ? (
                                                        <>
                                                            <ContainerFlex
                                                                Padding="12px"
                                                                Align="flex-start"
                                                                Height="none"
                                                                key={data.item.folio}
                                                            >
                                                                <RowDetailPay>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Desempeño Folio Nro.
                                                                    </Text>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Fecha:
                                                                    </Text>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                    >
                                                                        Valor a Pagar:
                                                                    </Text>
                                                                    <Text
                                                                        FontWeight="300"
                                                                        FontSize="0.875rem"
                                                                        Color="#4A4A4A"
                                                                        Padding="1.5rem 1rem"
                                                                        Justify="end"
                                                                        onClick={() => {
                                                                            DeletePawEndorsement(
                                                                                data.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <IconContainer
                                                                            className="material-icons"
                                                                            FontSize="1.25rem"
                                                                            Justify="end"
                                                                        >
                                                                            delete_forever
                                                                        </IconContainer>
                                                                    </Text>

                                                                    <>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {data.item.folio}
                                                                        </Text>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {DateTime.fromISO(
                                                                                data.item.date.toISOString()
                                                                            ).toFormat(
                                                                                'DD/MM/YYYY'
                                                                            )}
                                                                        </Text>
                                                                        <Text
                                                                            FontWeight="normal"
                                                                            FontSize="0.875rem"
                                                                            Color="#414042"
                                                                            Padding="0 1rem"
                                                                        >
                                                                            {numberToCurrency(
                                                                                data.item
                                                                                    .amountPayment
                                                                            )}
                                                                        </Text>
                                                                    </>
                                                                </RowDetailPay>
                                                            </ContainerFlex>
                                                            <ContainerFlex
                                                                Height="none"
                                                                Padding="0 16px"
                                                            >
                                                                <div className="headerTable">
                                                                    <div className="column1-2" />
                                                                    <div className="column2-5">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Nombre Artículo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column5-7">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Avalúo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column7-9">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            Préstamo
                                                                        </Text>
                                                                    </div>
                                                                    <div className="column9-11">
                                                                        <Text
                                                                            FontSize="0.875rem"
                                                                            Color="#4A4A4A"
                                                                            FontWeight="500"
                                                                        >
                                                                            % Empeño
                                                                        </Text>
                                                                    </div>
                                                                </div>
                                                            </ContainerFlex>

                                                            <ContainerFlex
                                                                Height="none"
                                                                Padding="16px"
                                                                Bb="1px solid #E4E7E9"
                                                            >
                                                                <div className="rowTable">
                                                                    <div className="column1-2">
                                                                        <ImagesArticle
                                                                            src={
                                                                                data.item
                                                                                    .imagePawnId
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="column2-5">
                                                                        <p>{data.item.itemName}</p>
                                                                    </div>
                                                                    <div className="column5-7">
                                                                        <p>
                                                                            {numberToCurrency(
                                                                                data.item.appraiser
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="column7-9">
                                                                        <p>
                                                                            {numberToCurrency(
                                                                                data.item.loan
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="column9-11">
                                                                        <p>
                                                                            {
                                                                                data.item
                                                                                    .pawnPercentage
                                                                            }
                                                                            %
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </ContainerFlex>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            );
                                        })}
                                    </ContainerFlex>
                                </>
                            )}
                        </ContainerFlex>

                        <ContainerFlex
                            Align="flex-start"
                            FlexDir="column"
                            Justify="flex-start"
                            Border="0.5px solid #E4E7E9"
                            Radius="5px"
                        >
                            <ContainerFlex
                                Align="flex-start"
                                Justify="flex-start"
                                Bb={payments.view ? '1px solid #E4E7E9' : ''}
                                Radius="5px"
                                onClick={() => {
                                    setPayments({...payment, view: !payment.view});
                                    setDetail({...detail, complete: !detail.complete});
                                }}
                                Cursor="pointer"
                            >
                                <Title
                                    numberTitle="3"
                                    nameTitle="Pagos"
                                    bgColor="var(--green)"
                                    fontColor="white"
                                    showEditIcon="none"
                                    showNumber={!(!payments.view && payments.complete)}
                                />

                                <Styled.EditIcon isDisplay="flex">
                                    <span className="material-icons">expand_more</span>
                                </Styled.EditIcon>
                            </ContainerFlex>

                            {payments.view &&
                                (userType === 'Client' ? (
                                    <>
                                        <ContainerFlex
                                            Justify="space-between"
                                            Width="30%"
                                            Padding="2rem 1rem"
                                        >
                                            <PaymentImg src={visa} />
                                            <PaymentImg src={mc} />
                                        </ContainerFlex>

                                        <RowDetailPayCardP Bb="none">
                                            <ContainerFlex
                                                Margin="0 0.5rem"
                                                FlexDir="Column"
                                                Align="flex-start"
                                            >
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="0.875rem"
                                                    Color="#4A4A4A"
                                                    Padding="0 1rem 0 0.5rem"
                                                >
                                                    No de tarjeta
                                                </Text>
                                                <ContainerFlex Margin="5px 0" Height="45px">
                                                    <Controller
                                                        watch={DetectedTypeCard('number')}
                                                        name="number"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: !valorTarjeta ? true : false,
                                                                message: 'Campo Obligatorio.',
                                                            },
                                                            pattern: {
                                                                value: /^([0-9\d]-?){1,16}[0-9\d]$/,
                                                                message: 'Indique 16 digitos.',
                                                            },
                                                        }}
                                                        render={({
                                                            field: {onChange, ref, value},
                                                        }) => (
                                                            <InputMask
                                                                placeholder="0000 0000 0000 0000"
                                                                mask="9999-9999-9999-9999"
                                                                ref={ref}
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </ContainerFlex>
                                                <ContainerFlex Height="25px">
                                                    <Text
                                                        Color="red"
                                                        FontWeight="400"
                                                        Margin="0.5rem 1rem"
                                                        FontSize="12px"
                                                    >
                                                        {errors?.number?.message}
                                                    </Text>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                            <ContainerFlex
                                                Margin="0 1rem"
                                                FlexDir="Column"
                                                Align="flex-start"
                                                Justify="flex-start"
                                                Width="auto"
                                            >
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="0.875rem"
                                                    Color="#4A4A4A"
                                                >
                                                    Titular de la tarjeta
                                                </Text>
                                                <ContainerFlex Margin="5px 0" Height="45px">
                                                    <Input
                                                        {...register('customer', {
                                                            required: {
                                                                value: !valorTarjeta ? true : false,
                                                                message: 'Campo Obligatorio',
                                                            },
                                                            pattern: {
                                                                value: /^[A-Za-z]/,
                                                                message: 'Ingresa sólo letras.',
                                                            },
                                                        })}
                                                        rules={{required: false}}
                                                        Margin="0"
                                                    />
                                                </ContainerFlex>
                                            </ContainerFlex>
                                            <ContainerFlex
                                                Margin="0 1rem 0 0"
                                                FlexDir="Column"
                                                Align="flex-start"
                                                Justify="flex-start"
                                            >
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="0.875rem"
                                                    Color="#4A4A4A"
                                                >
                                                    Caducidad
                                                </Text>{' '}
                                                <ContainerFlex
                                                    Justify="flex-start"
                                                    Margin="5px 0"
                                                    Height="45px"
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="expiration"
                                                        rules={{
                                                            required: {
                                                                value: !valorTarjeta ? true : false,
                                                                message: 'Campo Obligatorio.',
                                                            },
                                                            pattern: {
                                                                value: /^((0[1-9]{1})|(1[012]{1}))[/]((2[2-9]{1})|(3[0-9]{1}))$/,
                                                                message: 'Mes y/o año incorrectos.',
                                                            },
                                                        }}
                                                        render={({
                                                            field: {onChange, ref, value},
                                                        }) => (
                                                            <InputMask
                                                                mask="99/99"
                                                                ref={ref}
                                                                onChange={onChange}
                                                                value={value}
                                                                style={{justifyContent: 'start'}}
                                                            />
                                                        )}
                                                    />
                                                </ContainerFlex>
                                            </ContainerFlex>
                                            <ContainerFlex
                                                FlexDir="Column"
                                                Align="flex-start"
                                                Justify="start"
                                            >
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="0.875rem"
                                                    Color="#4A4A4A"
                                                >
                                                    CVV
                                                </Text>
                                                <ContainerFlex
                                                    Justify="start"
                                                    Margin="5px 0"
                                                    Height="45px"
                                                >
                                                    <Controller
                                                        name="cvc"
                                                        control={control}
                                                        Margin="0 1rem"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        rules={{
                                                            required: {
                                                                value: !valorTarjeta ? true : false,
                                                                message: 'Campo Obligatorio.',
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]{3}$/,
                                                                message: 'Indique 3 digitos.',
                                                            },
                                                            autocomplete: 'off',
                                                        }}
                                                        required={
                                                            watch(
                                                                'number' &&
                                                                    'customer' &&
                                                                    'expiration'
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        render={({
                                                            field: {onChange, ref, value},
                                                        }) => (
                                                            <InputMask
                                                                style={{
                                                                    width: '90%',
                                                                    justifyContent: 'start',
                                                                }}
                                                                mask="999"
                                                                type="password"
                                                                ref={ref}
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </ContainerFlex>
                                                <ContainerFlex Height="25px">
                                                    <Text
                                                        Color="red"
                                                        FontWeight="400"
                                                        Margin="0.5rem 1rem"
                                                        FontSize="12px"
                                                    >
                                                        {errors?.cvv?.message}
                                                    </Text>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        </RowDetailPayCardP>

                                        <PayCardsContainer>
                                            {homeclient?.cards?.data &&
                                                homeclient?.cards?.data.map((item) => (
                                                    <>
                                                        <ContainerFlex
                                                            Height="73px"
                                                            Bt="0.5px solid #E4E7E9"
                                                            Padding="0 26px 0 26px "
                                                            key={item.accountId}
                                                        >
                                                            <ContainerFlex>
                                                                <input
                                                                    type="radio"
                                                                    checked={
                                                                        valorTarjeta ===
                                                                        item.accountId
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={item.accountId}
                                                                    onChange={() =>
                                                                        traerDatosTarjeta(
                                                                            item.accountId
                                                                        )
                                                                    }
                                                                />
                                                                <ContainerFlex
                                                                    Width="auto"
                                                                    MarginL="0.5rem"
                                                                >
                                                                    <img
                                                                        src={item.imageCardURL}
                                                                        alt="card"
                                                                    />
                                                                </ContainerFlex>
                                                                <ContainerFlex
                                                                    FlexDir="column"
                                                                    Align="flex-start"
                                                                    Padding="0 0 0 32px"
                                                                >
                                                                    <Text
                                                                        FontWeight="500"
                                                                        FontSize="0.875rem"
                                                                    >
                                                                        Tarjetas de crédito
                                                                        terminación de:{' '}
                                                                        <Text
                                                                            FontWeight="500"
                                                                            FontSize="0.875rem"
                                                                        >
                                                                            {' '}
                                                                            {item.extract}
                                                                        </Text>
                                                                    </Text>

                                                                    <Text
                                                                        FontWeight="400"
                                                                        FontSize="0.875rem"
                                                                    >
                                                                        Vencimiento:{' '}
                                                                        <Text
                                                                            FontWeight="400"
                                                                            FontSize="0.875rem"
                                                                        >
                                                                            {' '}
                                                                            {item.expDate}
                                                                        </Text>
                                                                    </Text>
                                                                </ContainerFlex>
                                                            </ContainerFlex>

                                                            <ContainerFlex
                                                                Width="40%"
                                                                Justify="flex-end"
                                                            >
                                                                <Text
                                                                    FontWeight="300"
                                                                    FontSize="16px"
                                                                    Color="#4A4A4A"
                                                                    Padding="1.5rem 0 1.5rem 4.2rem"
                                                                    Justify="end"
                                                                    onClick={() => {
                                                                        DeleteCardSelected(
                                                                            item.accountId
                                                                        );
                                                                    }}
                                                                >
                                                                    <IconContainer
                                                                        className="material-icons"
                                                                        FontSize="1.25rem"
                                                                        Justify="end"
                                                                    >
                                                                        delete
                                                                    </IconContainer>
                                                                </Text>
                                                            </ContainerFlex>
                                                        </ContainerFlex>
                                                    </>
                                                ))}
                                        </PayCardsContainer>
                                    </>
                                ) : (
                                    <ContainerFlex Padding="2rem 1rem 2rem 1.5rem" FlexDir="column">
                                        <ContainerFlex Justify="start" Align="start">
                                            <Checkbox checked={true} disabled />
                                            <ContainerFlex MarginL="1rem" Width="auto">
                                                <img src={monetization} alt="monetization" />
                                            </ContainerFlex>

                                            <Text
                                                FontSize="0.75rem"
                                                FontWeight="400"
                                                MarginLeft="0.5rem"
                                            >
                                                Realizar pago en efectivo
                                            </Text>
                                        </ContainerFlex>
                                        {true && (
                                            <ContainerForm
                                                autoComplete="off"
                                                Margin="1.25rem 0"
                                                Justify="start"
                                            >
                                                <ContainerFlex
                                                    MarginR="2rem"
                                                    FlexDir="Column"
                                                    Width="30%"
                                                    Align="start"
                                                >
                                                    <Text
                                                        FontSize="0.75rem"
                                                        FontWeight="400"
                                                        required
                                                    >
                                                        Ingresa monto
                                                    </Text>
                                                    <Controller
                                                        name="amount"
                                                        control={control}
                                                        render={({field: {ref, ...field}}) => (
                                                            <StyleNumericFormat
                                                                {...field}
                                                                getInputRef={ref}
                                                                Width="100%"
                                                                defaultValue={''}
                                                                type="text"
                                                                prefix="$ "
                                                                decimalScale={15}
                                                                allowNegative={false}
                                                                placeholder="Ingresa monto"
                                                                Border="1px solid #E5E7E9"
                                                                ColorPlace="#CBCED1"
                                                                isAllowed={(values) =>
                                                                    limitNumberFormat(
                                                                        values.value,
                                                                        0,
                                                                        Math.ceil(sumatoriatotal())
                                                                    )
                                                                }
                                                                HBorder="1px solid #7AC143"
                                                            />
                                                        )}
                                                    />

                                                    <ContainerFlex
                                                        Justify="flex-start"
                                                        Height="1.25rem"
                                                    >
                                                        <Text
                                                            Color="red"
                                                            FontWeight="400"
                                                            FontSize="0.7rem"
                                                        >
                                                            {errors?.amount?.message}
                                                        </Text>
                                                    </ContainerFlex>
                                                </ContainerFlex>
                                            </ContainerForm>
                                        )}
                                    </ContainerFlex>
                                ))}
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex
                        Align="space-between"
                        FlexDir="column"
                        Radius="5px"
                        Width="30vw"
                        Margin="0 1.5rem"
                        Border="1px solid #E4E7E9"
                        Padding="1.125rem 1rem"
                    >
                        <Text FontWeight="450" FontSize="1.125rem" Color="#4A4A4A">
                            Resumen del pedido
                        </Text>
                        <ContainerFlex
                            Bt=""
                            Justify="space-between"
                            Padding="2.375rem 0 0.938rem 0"
                        >
                            <Text FontWeight="300" FontSize="0.875rem">
                                Subtotal refrendo
                            </Text>
                            <Text FontWeight="400" FontSize="0.875rem">
                                $ {totalPaymentEndorsement()}
                            </Text>
                        </ContainerFlex>

                        <ContainerFlex Padding="0 0 0.938rem 0" Justify="space-between">
                            <Text FontWeight="300" FontSize="0.875rem">
                                Subtotal desempeño
                            </Text>
                            <Text FontWeight="350" FontSize="0.875rem">
                                $ {sumatoria()}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Padding="2rem 0" Justify="space-between">
                            <Text FontWeight="400" FontSize="19px">
                                Total
                            </Text>
                            <Text FontWeight="400" FontSize="19px">
                                $ {sumatoriatotal()}
                            </Text>
                        </ContainerFlex>

                        <ButtonGenerals
                            MT="1rem"
                            Width="100%"
                            type="submit"
                            disabled={validateButton()}
                        >
                            CONFIRMAR COMPRA
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerForm>
    );
}
