import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@config/store';
import PaymentAndBillingModal from '@/components/AccountSettings/PaymentAndBillingModal';
import {IMessage} from '@components/Home/Admin/interface';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PAYMENT_METHOD_TEXT} from '@components/Home/Admin/constants';
import infoIcon from '@components/Branches/Images/InfoIcon.svg';

export const PaymentMethod = ({setIsDisplayed}: IMessage) => {
    const [showNewPaymentWindow, setShowNewPaymentWindow] = useState(false);
    const paymentMethods = useSelector(
        (state: RootState) => state.GetCompanyPaymentMethod?.paymentMethods
    );
    const plansMessage = useSelector(
        (state: RootState) => state.getContractedPlans.plans?.messageDay
    );

    return (
        <>
            {!paymentMethods ||
                (paymentMethods.length === 0 && (
                    <>
                        <ContainerFlex
                            GridColumns="1fr 4fr"
                            backG="#DBF4FF"
                            Height="auto"
                            Padding="8px 16px"
                            Border=""
                            Radius="8px"
                        >
                            <ContainerFlex Justify="start" Align="space-between">
                                <Image src={infoIcon} alt="add" Width="20px" Height="20px" />
                                <ContainerFlex
                                    FlexDir="column"
                                    Justify="start"
                                    Align="start"
                                    Padding="0 0 0 8px"
                                    Height="auto"
                                    Gap="8px"
                                >
                                    <Text Color="#004461" FontWeight="500" FontSize="1rem">
                                        {PAYMENT_METHOD_TEXT.WithoutPayment}
                                    </Text>
                                    <Text Color="#54575C" FontWeight="400" FontSize="1rem">
                                        {plansMessage}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Justify="center"
                                Align="end"
                                Padding="0 8px"
                                Width="100%"
                            >
                                <Text
                                    Color="#5A5AFF"
                                    FontWeight="500"
                                    FontSize="1rem"
                                    Cursor="pointer"
                                    onClick={() => setShowNewPaymentWindow(true)}
                                >
                                    {PAYMENT_METHOD_TEXT.Enter}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <PaymentAndBillingModal
                            modalFormType="paymentMethod"
                            showModal={showNewPaymentWindow}
                            setShowModal={setShowNewPaymentWindow}
                            onSuccess={() => setIsDisplayed(false)}
                        />
                    </>
                ))}
        </>
    );
};
