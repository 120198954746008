import {AppDispatch} from '@/config/store';
import {
    VALIDATE_BANK_CARD_START,
    VALIDATE_BANK_CARD_SUCCESS,
    VALIDATE_BANK_CARD_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

const validateBankCardStart = () => {
    return {type: VALIDATE_BANK_CARD_START};
};
const validateBankCardSuccess = (result: AxiosResponse) => {
    return {type: VALIDATE_BANK_CARD_SUCCESS, data: result.data};
};

const validateBankCardError = (error: AxiosError) => {
    return {type: VALIDATE_BANK_CARD_ERROR, error: error};
};

export function ValidateBankCard(cardNumber: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(validateBankCardStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateBankCardType}`,
                {
                    headers,
                    params: {
                        cardNumber,
                    },
                }
            );
            dispatch(validateBankCardSuccess(response.data));
        } catch (error) {
            dispatch(validateBankCardError(error as AxiosError));
        }
    };
}
