export const ADD_FLOW = 'ADD_FLOW';
export const ADD_FLOW_SUCCESS = 'ADD_FLOW_SUCCES';
export const ADD_FLOW_ERROR = 'ADD_FLOW_ERROR';

export const GET_BRANCH_BY_COMPANY = 'GET_BRANCH_BY_COMPANY';
export const GET_BRANCH_BY_COMPANY_SUCCESS = 'GET_BRANCH_BY_COMPANY_SUCCESS';
export const GET_BRANCH_BY_COMPANY_ERROR = 'GET_BRANCH_BY_COMPANY_ERROR';

export const GET_FLOW = 'GET_FLOW';
export const GET_FLOW_SUCCESS = 'GET_FLOW_SUCCESS';
export const GET_FLOW_ERROR = 'GET_FLOW_ERROR';

export const GET_MOTIVE = 'GET_MOTIVE';
export const GET_MOTIVE_SUCCESS = 'GET_MOTIVE_SUCCESS';
export const GET_MOTIVE_ERROR = 'GET_MOTIVE_ERROR';

export const GET_FLOW_ID = 'GET_FLOW_ID';
export const GET_FLOW_ID_SUCCESS = 'GET_FLOW_ID_SUCCESS';
export const GET_FLOW_ID_ERROR = 'GET_FLOW_ID_ERROR';

export const GET_EXCEDENT = 'GET_EXCEDENT';
export const GET_EXCEDENT_SUCCESS = 'GET_EXCEDENT_SUCCESS';
export const GET_EXCEDENT_ERROR = 'GET_EXCEDENT_ERROR';

export const GET_EXCEDENT_ID = 'GET_EXCEDENT_ID';
export const GET_EXCEDENT_ID_SUCCESS = 'GET_EXCEDENT_ID_SUCCESS';
export const GET_EXCEDENT_ID_ERROR = 'GET_EXCEDENT_ID_ERROR';

export const PUT_APPROVE_REQUEST = 'PUT_APPROVE_REQUEST';
export const PUT_APPROVE_REQUEST_SUCCESS = 'PUT_APPROVE_REQUEST_SUCCESS';
export const PUT_APPROVE_REQUEST_ERROR = 'PUT_APPROVE_REQUEST_ERROR';

export const PUT_FLOW_DEPOSITED = 'PUT_FLOW_DEPOSITED';
export const PUT_FLOW_DEPOSITED_SUCCESS = 'PUT_FLOW_DEPOSITED_SUCCESS';
export const PUT_FLOW_DEPOSITED_ERROR = 'PUT_FLOW_DEPOSITED_ERROR';

export const PUT_FLOW_RECIVIED = 'PUT_FLOW_RECIVIED';
export const PUT_FLOW_RECIVIED_SUCCESS = 'PUT_FLOW_RECIVIED_SUCCESS';
export const PUT_FLOW_RECIVIED_ERROR = 'PUT_FLOW_RECIVIED_ERROR';

export const GET_BALANCE_BY_STORE = 'GET_BALANCE_BY_STORE';
export const GET_BALANCE_BY_STORE_ERROR = 'GET_BALANCE_BY_STORE_ERROR';
export const GET_BALANCE_BY_STORE_SUCCESS = 'GET_BALANCE_BY_STORE_SUCCESS';

export const GET_ORGANIZATION_TREE = 'GET_ORGANIZATION_TREE';
export const GET_ORGANIZATION_TREE_SUCCESS = 'GET_ORGANIZATION_TREE_SUCCESS';
export const GET_ORGANIZATION_TREE_SUCCESS_ = 'GET_ORGANIZATION_TREE_SUCCESS_';

export const GET_ORGANIZATION_TREE_ERROR = 'GET_ORGANIZATION_TREE_ERROR';
export const GET_BALANCE_BRANCHES = 'GET_BALANCE_BRANCHES';

export const GET_BALANCES = 'GET_BALANCES';
export const GET_BALANCES_SUCCESS = 'GET_BALANCES_SUCCES';
export const GET_BALANCES_ERROR = 'GET_BALANCES_ERROR';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const GET_BRANCH = 'GET_BRANCH';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';

export const REQUEST_CASHFLOW = 'REQUEST_CASHFLOW';
export const REQUEST_CASHFLOW_SUCCESS = 'REQUEST_CASHFLOW_SUCCESS';
export const REQUEST_CASHFLOW_ERROR = 'REQUEST_CASHFLOW_ERROR';

export const REQUEST_EXCESSMOTIVE = 'REQUEST_EXCESSMOTIVE';
export const REQUEST_EXCESSMOTIVE_SUCCESS = 'REQUEST_EXCESSMOTIVE_SUCCESS';
export const REQUEST_EXCESSMOTIVE_ERROR = 'REQUEST_EXCESSMOTIVE_ERROR';

export const BALANCE_CASHFLOW = 'BALANCE_CASHFLOW';
export const BALANCE_CASHFLOW_SUCCESS = 'BALANCE_CASHFLOW_SUCCESS';
export const BALANCE_CASHFLOW_ERROR = 'BALANCE_CASHFLOW_ERROR';

export const BALANCE_EXCESS = 'BALANCE_EXCESS';
export const BALANCE_EXCESS_SUCCESS = 'BALANCE_EXCESS_SUCCESS';
export const BALANCE_EXCESS_ERROR = 'BALANCE_EXCESS_ERROR';

export const CAT_EXCESS = 'CAT_EXCESS';
export const CAT_EXCESS_SUCCESS = 'CAT_EXCESS_SUCCESS';
export const CAT_EXCESS_ERROR = 'CAT_EXCESS_ERROR';

export const CAT_STATUS = 'CAT_STATUS';
export const CAT_STATUS_SUCCESS = 'CAT_STATUS_SUCCESS';
export const CAT_STATUS_ERROR = 'CAT_STATUS_ERROR';

export const GET_NEAR_BY_BRANCHES = 'GET_NEAR_BY_BRANCHES';
export const GET_NEAR_BY_BRANCHES_SUCCESS = 'GET_NEAR_BY_BRANCHES_SUCCESS';
export const GET_NEAR_BY_BRANCHES_ERROR = 'GET_NEAR_BY_BRANCHES_ERROR';

export const GET_FLOW_CASH = 'PUT_FUNDING_REQUEST';
export const GET_FLOW_CASH_SUCCESS = 'PUT_FUNDING_REQUEST_SUCCESS';
export const GET_FLOW_CASH_ERROR = 'PUT_FUNDING_REQUEST_ERROR';

export const BRANCH_SUCCESS = 'BRANCH_SUCCESS';

export const CATALOG_EXCEDENT = 'CATALOG_EXCEDENT';
export const CATALOG_EXCEDENT_SUCCESS = 'CATALOG_EXCEDENT_SUCCESS';
export const CATALOG_EXCEDENT_ERROR = 'CATALOG_EXCEDENT_ERROR';

export const PUT_EXCESS = 'PUT_EXCESS';
export const PUT_EXCESS_SUCCESS = 'PUT_EXCESS_SUCCESS';
export const PUT_EXCESS_ERROR = 'PUT_EXCESS_ERROR';

export const STATUS_INVALIDATE = 'STATUS_INVALIDATE';
export const STATUS_INVALIDATE_SUCCESS = 'STATUS_INVALIDATE_SUCCESS';
export const STATUS_INVALIDATE_ERROR = 'STATUS_INVALIDATE_ERROR';

export const CURRENT_PAGE = 'CURRENT_PAGE';
export const CURRENT_PAGE_EXCEDENT = 'CURRENT_PAGE_EXCEDENT';
export const CURRENT_PAGE_REQUEST_EXCEDENT = 'CURRENT_PAGE_REQUEST_EXCEDENT';

export const SAVE_CASH_ID = 'SAVE_CASH_ID';

export const INITIAL_PAGE_COUNT = 'INITIAL_PAGE_COUNT';
