import {AxiosError, AxiosResponse} from 'axios';
import {
    CLEAR_FILTERS_VALUES,
    SET_FILTERS_VALUES,
    SET_FILTERS_LIMITS_SUCCESS,
    SET_FILTERS_LIMITS_ERROR,
    TOGGLE_FILTERS_MENU,
    GET_SEARCH_CLIENT_START,
    GET_SEARCH_CLIENT_SUCCESS,
    GET_SEARCH_CLIENT_ERROR,
    SET_SEARCHTERM,
    SET_SEARCHTERM_CLEAR,
    GET_PERSONAL_LOANS_BY_FILTERS_START,
    GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS,
    GET_PERSONAL_LOANS_BY_FILTERS_ERROR,
    SET_FILTERS_VISUAL_STATUS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IFilterLoans, IFilterValues} from '@components/CreditsAndPawns/interfaces';

const initialFiltersValues = {
    userClientId: null,
    scoreStart: 0,
    scoreEnd: 1000,
    Frequency: [],
    interestAnualStart: 0,
    interestAnualEnd: 10,
    amountMaxCreditStart: 0,
    amontMaxCreditEnd: 10000,
    requestedDateStart: null,
    requestedDateEnd: null,
    paymentDateStart: null,
    paymentDateEnd: null,
};

const initialFiltersOptions = {
    frequencyIds: [],
    interestAnualStart: 0,
    interestAnualEnd: 10,
    amontMaxCreditEnd: 10000,
    scoreStart: 0,
    scoreEnd: 1000,
};

const initialState: IFilterLoans = {
    filterData: [],
    noFilterResults: false,
    searchClientsData: [],
    searchTerm: '',
    isLoading: false,
    emptySearch: false,
    openFilter: false,
    filtersOptions: initialFiltersOptions,
    filtersValues: initialFiltersValues,
    filterVisualStatus: initialFiltersValues,
};

const updateFiltersLimits = (state: IFilterLoans, payload: AxiosResponse) => {
    const {interesAnual, montoMaximoCredito, frecuencia} = payload.data.data;
    const interestAnualStart = Math.floor(interesAnual[0]);
    const interestAnualEnd = Math.ceil(interesAnual[interesAnual.length - 1]);

    return {
        ...state,
        filtersOptions: {
            ...state.filtersOptions,
            interestAnualStart,
            interestAnualEnd,
            amontMaxCreditEnd: montoMaximoCredito,
            frequencyIds: frecuencia,
        },
        filtersValues: {
            ...state.filtersValues,
            interestAnualStart,
            interestAnualEnd,
            amontMaxCreditEnd: montoMaximoCredito,
        },
        filterVisualStatus: {
            ...state.filterVisualStatus,
            interestAnualStart,
            interestAnualEnd,
            amontMaxCreditEnd: montoMaximoCredito,
        },
    };
};

const clearFiltersValues = (state: IFilterLoans) => {
    return {
        ...state,
        filtersValues: {
            ...initialFiltersValues,
            interestAnualStart: state.filtersOptions.interestAnualStart,
            interestAnualEnd: state.filtersOptions.interestAnualEnd,
            amontMaxCreditEnd: state.filtersOptions.amontMaxCreditEnd,
        },
        filterVisualStatus: {
            ...initialFiltersValues,
            interestAnualStart: state.filtersOptions.interestAnualStart,
            interestAnualEnd: state.filtersOptions.interestAnualEnd,
            amontMaxCreditEnd: state.filtersOptions.amontMaxCreditEnd,
        },
        searchTerm: '',
        filterData: [],
        emptySearch: false,
        noFilterResults: false,
    };
};

const getListLoans = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
        filtersValues: IFilterValues;
        searchTerm: string;
    }
): IFilterLoans => {
    switch (action.type) {
        case TOGGLE_FILTERS_MENU:
            return {
                ...state,
                openFilter: !state.openFilter,
            };
        case SET_FILTERS_LIMITS_SUCCESS:
            return updateFiltersLimits(state, action.payload);
        case SET_FILTERS_LIMITS_ERROR:
            return {
                ...state,
                filtersOptions: initialFiltersOptions,
                filtersValues: initialFiltersValues,
            };
        case SET_FILTERS_VALUES:
            return {
                ...state,
                filtersValues: action.filtersValues,
            };
        case SET_FILTERS_VISUAL_STATUS:
            return {
                ...state,
                filterVisualStatus: action.filtersValues,
            };
        case CLEAR_FILTERS_VALUES:
            return clearFiltersValues(state);
        case GET_SEARCH_CLIENT_START:
            return {
                ...state,
                searchClientsData: [],
                isLoading: true,
            };
        case GET_SEARCH_CLIENT_SUCCESS:
            return {
                ...state,
                searchClientsData: action.payload.data.data,
                emptySearch: action.payload.data.data.length === 0,
                isLoading: false,
            };
        case GET_SEARCH_CLIENT_ERROR:
            return {
                ...state,
                searchClientsData: [],
                emptySearch: true,
                isLoading: false,
            };
        case SET_SEARCHTERM:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };
        case SET_SEARCHTERM_CLEAR:
            return {
                ...state,
                searchTerm: '',
            };
        case GET_PERSONAL_LOANS_BY_FILTERS_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS:
            return {
                ...state,
                filterData: action.payload.data.data.slice(0, 10),
                noFilterResults: action.payload.data.data.length === 0,
                isLoading: false,
            };
        case GET_PERSONAL_LOANS_BY_FILTERS_ERROR:
            return {
                ...state,
                filterData: [],
                noFilterResults: true,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default getListLoans;
