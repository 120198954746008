import {
    GET_PRODUCTS_CUSTOMER_USER_START,
    GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
    GET_PRODUCTS_CUSTOMER_USER_ERROR,
    SET_ACTIVE_STATUS,
} from '@components/LoansProducts/Redux/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IProductsCustomerUserInitialState} from '@components/LoansProducts/interfaces';
import {TABLE_FILTERS} from '@/components/LoansProducts/constants';

const initialState: IProductsCustomerUserInitialState = {
    data: [],
    loading: false,
    error: false,
    activeStatus: TABLE_FILTERS[0].label,
};

export const getProductsCustomerUserReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError; activeStatus: string}
) => {
    switch (action.type) {
        case GET_PRODUCTS_CUSTOMER_USER_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PRODUCTS_CUSTOMER_USER_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                loading: false,
                error: false,
            };
        case GET_PRODUCTS_CUSTOMER_USER_ERROR:
            return {
                ...state,
                error: true,
            };
        case SET_ACTIVE_STATUS:
            return {
                ...state,
                activeStatus: action.activeStatus,
            };
        default:
            return state;
    }
};
