import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_AND_PAWNS_HISTORY_ERROR,
    GET_CREDIT_AND_PAWNS_HISTORY_START,
    GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IRequestAndPawnsHistoryReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: IRequestAndPawnsHistoryReducer = {
    data: null,
    loading: false,
    error: false,
};

const getCreditAndPawnsHistory = (
    state: IRequestAndPawnsHistoryReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IRequestAndPawnsHistoryReducer => {
    switch (action.type) {
        case GET_CREDIT_AND_PAWNS_HISTORY_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CREDIT_AND_PAWNS_HISTORY_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CREDIT_AND_PAWNS_HISTORY_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getCreditAndPawnsHistory;
