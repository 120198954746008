import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ADD_ROLES_ERROR,
    ADD_ROLES_START,
    ADD_ROLES_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_RESET,
    CREATE_USER_START,
    CREATE_USER_SUCCESS,
} from '@components/CreateUsers/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {EmployeeData} from '@components/CreateUsers/interfaces';
import {AppDispatch} from '@/config/store';
import {resetOptionsUserNames} from '@components/CreateUsers/Redux/Actions/GetListUserNames';
import {GetPostalInfoReset} from '@/components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
export const fetchDataUserStart = () => {
    return {
        type: CREATE_USER_START,
    };
};
export const fetchDataUserSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: result,
    };
};
export const fetchDataUserError = (error: AxiosError) => {
    return {
        type: CREATE_USER_ERROR,
        error: error,
    };
};
export const fetchDataUserReset = () => {
    return {
        type: CREATE_USER_RESET,
    };
};
export function postDataUserForm(data: EmployeeData, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataUserStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostCreateUserEmployee}
            `,
                data,
                {headers}
            );
            dispatch(fetchDataUserSuccess(response));
            window.location.href = '#/Users';
            dispatch(fetchDataUserReset());
            dispatch(GetPostalInfoReset());
            dispatch(resetOptionsUserNames());
        } catch (error) {
            dispatch(fetchDataUserError(error as AxiosError));
        }
    };
}

export const fetchDataRolesStart = () => {
    return {
        type: ADD_ROLES_START,
    };
};
export const fetchDataRolesSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_ROLES_SUCCESS,
        payload: result,
    };
};
export const fetchDataRolesError = (error: AxiosError) => {
    return {
        type: ADD_ROLES_ERROR,
        error: error,
    };
};

export function GetRolsTypesByCompany(CompanyId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataRolesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRolsTypesByCompany.replace(
                    '{CompanyId}',
                    String(CompanyId)
                )}`,
                {headers}
            );
            dispatch(fetchDataRolesSuccess(response));
        } catch (error) {
            dispatch(fetchDataRolesError(error as AxiosError));
        }
    };
}
