import {
    GET_SEARCHED_ORGANIZATIONS,
    GET_SEARCHED_ORGANIZATIONS_SUCCESS,
    GET_SEARCHED_ORGANIZATIONS_ERROR,
    RESET_SEARCHED_ORGANIZATIONS,
} from '@Roles/AddRoles/Redux/Types';
import {TypeResponseData} from '@Roles/AddRoles/interfaces';

const initialState = {
    loading: false,
    error: false,
    data: [],
};

const getSearchedOrganizations = (state = initialState, action: TypeResponseData) => {
    switch (action.type) {
        case GET_SEARCHED_ORGANIZATIONS:
            return {...state, loading: true, error: false};
        case GET_SEARCHED_ORGANIZATIONS_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case GET_SEARCHED_ORGANIZATIONS_ERROR:
            return {...state, error: true};
        case RESET_SEARCHED_ORGANIZATIONS:
            return initialState;
        default:
            return state;
    }
};

export default getSearchedOrganizations;
