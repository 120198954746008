import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import ArrowLeft from '@images/arrowLeftIconGray.svg';
import {NEWGROUP} from '@components/LocationsBranch/constants';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {IRoutesLocationsProps} from '@components/LocationsBranch/interfaces';

export const RoutesLocations = ({lastLevel}: IRoutesLocationsProps) => {
    const levels = useSelector((state: RootState) => state.getLevels.level);
    const route = levels && levels.breadcrumbName && levels.breadcrumbName.length > 0;
    return (
        <ContainerFlex {...style.contentGral} Gap="1.5rem">
            <Text {...style.contentGralTitle}>{NEWGROUP.ORGANIZATION}</Text>
            <ContainerFlex Justify="start">
                <Text FontSize="0.75rem" Color="#2A2C2F" FontWeight="700" wSpace="normal">
                    {route &&
                        levels.breadcrumbName.split('>').map((part, index) => (
                            <React.Fragment key={index}>
                                {part}
                                {index < levels.breadcrumbName.split('>').length - 1 && (
                                    <Image src={ArrowLeft} alt="Arrow" />
                                )}
                            </React.Fragment>
                        ))}
                </Text>
                {route && <Image src={ArrowLeft} alt="Arrow" />}
                <Text FontSize="0.75rem" Color="#0D166B" FontWeight="700">
                    {lastLevel}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
