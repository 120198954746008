import {IAccount, IReducerForm} from '@components/SignUp/interfaces';
import {
    SAVE_ACCOUNT_DATA,
    SAVE_COMPANY_DATA,
    SAVE_PLAN_DATA,
    SAVE_TYPE_SALE_DATA,
    BACK_TO_STEP,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_ERROR,
    RESET_ACCOUNT_DATA,
} from '@components/SignUp/Redux/types';

const initialState: IAccount = {
    actualStep: 1,
    company: null,
    places: null,
    error: false,
    loading: false,
    data: null,
    accountData: {
        names: '',
        secondsNames: '',
        email: '',
        password: '',
        confirmPassword: '',
    },
};

const saveDataSingUp = (state = initialState, action: IReducerForm): IAccount => {
    switch (action.type) {
        case SAVE_ACCOUNT_DATA:
            return {...state, actualStep: 2, accountData: action.accountData};
        case BACK_TO_STEP:
            return {...state, actualStep: state.actualStep - 1};
        case SAVE_COMPANY_DATA:
            return {...state, actualStep: 3, company: action.company};
        case SAVE_TYPE_SALE_DATA:
            return {...state, actualStep: 4, places: action.places};
        case SAVE_PLAN_DATA:
            return {...state};
        case CREATE_ACCOUNT:
            return {...state, loading: true};
        case CREATE_ACCOUNT_SUCCESS:
            return {...state, loading: false};
        case CREATE_ACCOUNT_ERROR:
            return {...state, loading: false};
        case RESET_ACCOUNT_DATA:
            return initialState;
        default:
            return state;
    }
};
export default saveDataSingUp;
