import {
    UPDATE_STATUS_ALL_USERS,
    UPDATE_STATUS_ALL_USERS_SUCCESS,
    UPDATE_STATUS_ALL_USERS_ERROR,
} from '@components/Users/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

const updateStateUsers = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case UPDATE_STATUS_ALL_USERS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_STATUS_ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case UPDATE_STATUS_ALL_USERS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default updateStateUsers;
