import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ImgLocation} from '@CreateLocation/style';
import deleteconfirm from '@images/delete_confirm.svg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {
    fetchGetObjectBranch,
    getDeleteFloor,
    getDeletePension,
    getDeleteRack,
    getDeleteVault,
} from '@ActionsLocation/LocationAction';
import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';
import {modalDeleteLocations, location} from '@Locations/AdminLocations/redux/Interfaces';
import ButtonGeneral from '@/components/General/Atoms/Button';

const LocationsModal = (props: modalDeleteLocations) => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const {show, setShow, branch, setActivate} = props;
    const dispatch: AppDispatch = useDispatch();
    const valuesRack = {
        branchId: Number(branch?.idBranch),
        rackId: [locations.objectbranch?.id],
    };
    const valuesVault = {
        branchId: Number(branch?.idBranch),
        voultId: locations.objectbranch?.id,
    };
    const valuesFloor = {
        branchId: Number(branch?.idBranch),
        floorId: [locations.objectbranch?.id],
    };
    const valuesPension = {
        branchId: Number(branch?.idBranch),
        pensionId: [locations.objectbranch?.id],
    };

    const DeleteAllContainer = () => {
        switch (locations.locationsidcontainer) {
            case 1:
                dispatch(getDeleteRack(valuesRack, token));
                break;
            case 2:
                dispatch(getDeleteVault(valuesVault, token));
                break;
            case 3:
                dispatch(getDeletePension(valuesPension, token));
                break;
            case 4:
                dispatch(getDeleteFloor(valuesFloor, token));
                break;
        }
        setActivate([]);
        fetchGetObjectBranch([]);
    };

    const messageLocations = () => {
        if (locations.locationsidcontainer) {
            const MESSAGE_LOCATIONS: {[key: number]: {initial: string; final: string}} = {
                1: {initial: 'Este rack', final: 'eliminarlo'},
                2: {initial: 'Esta bóveda', final: 'eliminarla'},
                3: {initial: 'Esta pensión', final: 'eliminarla'},
                4: {initial: 'Este piso', final: 'eliminarlo'},
            };
            return MESSAGE_LOCATIONS[locations.locationsidcontainer];
        }
    };

    useEffect(() => {
        messageLocations();
    }, []);

    return (
        <>
            <Modal
                modalState={show}
                changeModalState={() => {}}
                titleModalState={true}
                BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
                Justify="center"
                AlignMod="center"
                Position="fixed"
                Top="50%"
                Height="auto"
                BorderRadius="4px"
                aria-labelledby="example-custom-modal-styling-t!                                                                                                                                                                                                                                                                                          itle"
            >
                <ContainerFlex Align="center" Justify="center" Padding="2rem" FlexDir="column">
                    <ImgLocation Width="218px" Height="245px" src={deleteconfirm} />
                    <Text Margin="5px 0" FontSize="1rem" FontWeight="500">
                        {messageLocations()?.initial +
                            ' actualmente contiene arctículos, reubíquelos'}
                    </Text>
                    <Text Margin="5px 0" FontSize="1rem">
                        {'¿Confirma que desea ' + messageLocations()?.final + '?'}
                    </Text>
                    <ContainerFlex Justify="space-between" Width="30%" Margin="1rem 0">
                        <ButtonGeneral
                            transform="capitalize"
                            width="59px"
                            text="No"
                            hColor="#35cf44"
                            clic={() => {
                                setActivate([]);
                                fetchGetObjectBranch([]);
                                setShow(false);
                            }}
                        />
                        <ButtonGeneral
                            clic={() => DeleteAllContainer()}
                            transform="capitalize"
                            width="59px"
                            hColor="#35cf44"
                            text="Sí"
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
export default LocationsModal;
