import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {CAT_ADDRESS} from '@components/LocationsBranch/Redux/types';

const catAddressStart = () => ({type: CAT_ADDRESS.GRAL_START});

const catPostalCodeSuccess = (result: {data: AxiosResponse}) => ({
    type: CAT_ADDRESS.CAT_POSTAL_CODE_SUCCESS,
    catPostalCode: result.data,
});
const catStateSuccess = (result: {data: AxiosResponse}) => ({
    type: CAT_ADDRESS.CAT_STATE_SUCCESS,
    catStatesByCountry: result.data,
});
const catMunicipalitySuccess = (result: {data: AxiosResponse}) => ({
    type: CAT_ADDRESS.CAT_MUNICIPAITY_SUCCESS,
    catMunicipalityByState: result.data,
});
const catColonySuccess = (result: {data: AxiosResponse}) => ({
    type: CAT_ADDRESS.CAT_COLONY_SUCCESS,
    catColonyByMunicipality: result.data,
});

const catAddressError = (error: AxiosError) => ({
    type: CAT_ADDRESS.GRAL_ERROR,
    error: error,
});

export const catPostalCode = (token: string, postalCode: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catAddressStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetColonysByPostalCode}?PostalCode=${postalCode}`,
                {headers}
            );
            dispatch(catPostalCodeSuccess(response.data));
        } catch (error) {
            dispatch(catAddressError(error as AxiosError));
        }
    };
};

export const catStates = (token: string, countryId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catAddressStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllStateByCountry}?countryId=${countryId}`,
                {headers}
            );
            dispatch(catStateSuccess(response.data));
        } catch (error) {
            dispatch(catAddressError(error as AxiosError));
        }
    };
};

export const catMunicipality = (token: string, stateId: {value: number; label?: string}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catAddressStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllMunicipalityByState}?stateId=${stateId.value}`,
                {headers}
            );
            dispatch(catMunicipalitySuccess(response.data));
        } catch (error) {
            dispatch(catAddressError(error as AxiosError));
        }
    };
};

export const catColony = (token: string, MunicipalityId: {value: number; label?: string}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catAddressStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetColonysByMunicipality}?MunicipalityId=${MunicipalityId.value}`,
                {headers}
            );
            dispatch(catColonySuccess(response.data));
        } catch (error) {
            dispatch(catAddressError(error as AxiosError));
        }
    };
};

export const resetPostalCode = () => ({type: CAT_ADDRESS.RESET_POSTAL_CODE});
export const resetAll = () => ({type: CAT_ADDRESS.RESET_ALL});
