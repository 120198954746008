import React from 'react';
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    ResponsiveContainer,
    RectangleProps,
    Tooltip,
} from 'recharts';
import {incomeData} from '@/components/AdminGeneral/data';

const BarChartIncome = () => {
    const CustomBarShape: React.FC<RectangleProps> = (props) => {
        const {x, y, width, height} = props;
        return (
            <Rectangle
                x={x}
                y={y}
                width={width}
                height={height}
                fill="#ACACFF"
                radius={[5, 5, 0, 0]}
            />
        );
    };
    return (
        <ResponsiveContainer width="100%" height={120}>
            <BarChart width={100} height={50} data={incomeData} barCategoryGap="1%">
                <Tooltip cursor={{fill: 'transparent'}} />
                <XAxis dataKey="month" axisLine={false} tickLine={false} />
                <Bar
                    dataKey="income"
                    fill="#ACACFF"
                    label={{fill: 'white', fontSize: 13, position: 'insideTop'}}
                    shape={<CustomBarShape />}
                    barSize={50}
                    activeBar={{fill: '#5A5AFF'}}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartIncome;
