import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import {RootState, AppDispatch} from '@config/store';

import {HeadTableRecolectionGold} from '@RecolectionGold/HeadTableRecolectionGold';
import {
    getAssignmentAuditGold,
    getAssignmentRecolectionGold,
    getAssignmentValidation,
    getHeaderAuditGold,
} from '@ActionsRecolectionGold/RecolectionGoldActions';
import '@RecolectionGold/styles.css';
import {RowRecolectionGoldTable} from '@RecolectionGold/RowRecolectionGoldTable';
import {RowAuditGoldTable} from '@RecolectionGold/RowAuditGoldTable';
import {Table} from '@DeadLines/styles';

export const RecolectionGold = (): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const {handleSubmit, register} = useForm({
        defaultValues: {
            searchsku: '',
            searchskuaudit: '',
        },
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [filterrecolectiongold, setfilterrecolectiongold] = useState('');
    const [filterauditgold, setfilterauditgold] = useState('');
    useEffect(() => {
        if (token) {
            const getAuditGold = () => dispatch(getAssignmentAuditGold('', 10, 0, '1', token));
            getAuditGold();
            const getRecolectionGold = () =>
                dispatch(getAssignmentRecolectionGold('', 10, 0, '1', token));
            getRecolectionGold();
            const getHeaderAudit = () => dispatch(getHeaderAuditGold('1', token));
            getHeaderAudit();
            const getHeaderGold = () => dispatch(getAssignmentValidation('1', token));
            getHeaderGold();
        }
    }, [dispatch, token]);
    const handlePageClickHistory = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(getAssignmentRecolectionGold(filterrecolectiongold, 10, selected, '1', token));
    };

    const onSubmit = (values: {searchsku: string}) => {
        setfilterrecolectiongold(values.searchsku);
        dispatch(
            getAssignmentRecolectionGold(values.searchsku, 10, recolectiongoldpage, '1', token)
        );
    };
    const handleUserKeyPress = (e: {key: string; shiftKey: boolean}) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit);
        }
    };

    const handlePageClickHistoryAudit = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(getAssignmentAuditGold(filterauditgold, 10, selected, '1', token));
    };

    const onSubmitAudit = (values: {searchskuaudit: string}) => {
        setfilterauditgold(values.searchskuaudit);
        dispatch(
            getAssignmentAuditGold(values.searchskuaudit, 10, recolectiongoldpage, '1', token)
        );
    };
    const handleUserKeyPressAudit = (e: {key: string; shiftKey: boolean}) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmitAudit);
        }
    };
    const recolectiongold = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.recolectiongold;
    });
    const recolectiongoldcount = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.recolectiongoldcount;
    });
    const recolectiongoldpage = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.recolectiongoldPageIndex;
    });
    const auditgold = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.auditgold;
    });
    const auditgoldcount = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.auditgoldcount;
    });
    const auditgoldpage = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.auditgoldPageIndex;
    });
    const headerrecolectiongold = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.headerrecolectiongold;
    });
    const headerauditgold = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.headerauditgold;
    });
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <div className="w-90 mx-auto ">
            <div className="pt-2 pb-3 flex align-items-center w-100 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Sucursal</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link to={`/Fundiciones/Auditoria`}>
                    <span className="child-breadcrumb">Verificación de Datos</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Recolección de Oro</span>
            </div>
            <div className="flex justify-content-between">
                <span>A continuación se muestra un listado de los artículos en almoneda</span>
                <Link to={`/Fundiciones/Recoleccion/Captura`}>
                    {' '}
                    <button className="w-100 pl-5 pr-5 btn-add-incidents h-40">SIGUIENTE</button>
                </Link>
            </div>
            <div className="flex mt-5">
                <div className="w-50 card pt-2 mr-3">
                    <span className="w-100 text-center f-22">Almoneda</span>
                    <div className="flex pt-4 pl-5">
                        <span className="w-30 pl-5 ml-5">
                            Prendas: {headerrecolectiongold && headerrecolectiongold.prendas}
                        </span>
                        <span className="w-70 ">
                            Costo de las prendas:{' '}
                            {headerrecolectiongold &&
                                formatterDolar.format(headerrecolectiongold.costo)}
                        </span>
                    </div>
                    <form
                        className="flex container-form pl-4 mt-5"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input
                            className="input-search"
                            {...register('searchsku', {})}
                            onKeyPress={handleUserKeyPress}
                        />
                        <button type="submit">
                            {' '}
                            <i className="material-icons">search</i>
                        </button>
                    </form>
                    <Table className="mt-4 w-90 mx-auto">
                        <HeadTableRecolectionGold />
                        {recolectiongold.length > 0 ? (
                            recolectiongold.map(
                                (
                                    recolectionitems: JSX.IntrinsicAttributes & {
                                        sku: string;
                                        fecha: string;
                                        familia: string;
                                        subfamilia: string;
                                        kilataje: number;
                                        costo: number;
                                    }
                                ) => (
                                    <RowRecolectionGoldTable
                                        key={recolectionitems.sku}
                                        {...recolectionitems}
                                    />
                                )
                            )
                        ) : (
                            <></>
                        )}
                    </Table>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={recolectiongoldcount / 10}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={recolectiongoldpage}
                        onPageChange={handlePageClickHistory}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
                <div className="w-50 card pt-2 ml-3">
                    <span className="w-100 text-center f-22">Auditadas</span>
                    <div className="flex flex-wrap pt-4">
                        <span className="w-50 pl-4">
                            Total de prendas: {headerauditgold && headerauditgold.prendasTotal}
                        </span>
                        <span className="w-50 ">
                            Prendas en resguardo:{' '}
                            {headerauditgold && headerauditgold.prendasEnResguardo}
                        </span>
                        <span className="w-50 pl-4">
                            Costo de las prendas:{' '}
                            {headerauditgold && formatterDolar.format(headerauditgold.costoTotal)}
                        </span>
                        <span className="w-50 ">
                            Costo en resguardo:{' '}
                            {headerauditgold &&
                                formatterDolar.format(headerauditgold.costoResguardo)}
                        </span>
                        <span className="w-50 pl-4">
                            Incidencia:{' '}
                            <span className="required-text">
                                {headerauditgold && headerauditgold.prendasConIncidencias}
                            </span>
                        </span>
                        <span className="w-50 ">
                            Prendas en seguribolsa:{' '}
                            {headerauditgold && headerauditgold.prendasEnSeguribolsa}
                        </span>
                        <span className="w-50 pl-4">
                            Costo de incidencia:{' '}
                            <span className=" required-text">
                                {headerauditgold &&
                                    formatterDolar.format(headerauditgold.costoIncidencias)}
                            </span>
                        </span>
                        <span className="w-50 ">
                            Costo en seguribolsa:{' '}
                            {headerauditgold &&
                                formatterDolar.format(headerauditgold.costoSeguribolsa)}
                        </span>
                    </div>
                    <form
                        className="flex container-form pl-4 mt-5"
                        onSubmit={handleSubmit(onSubmitAudit)}
                    >
                        <input
                            className="input-search"
                            {...register('searchskuaudit', {})}
                            onKeyPress={handleUserKeyPressAudit}
                        />
                        <button type="submit">
                            {' '}
                            <i className="material-icons">search</i>
                        </button>
                    </form>
                    <Table className="w-92 mx-auto mt-4">
                        <HeadTableRecolectionGold />
                        {auditgold.length > 0 ? (
                            auditgold.map(
                                (
                                    recolectionitems: JSX.IntrinsicAttributes & {
                                        sku: string;
                                        fecha: string;
                                        familia: string;
                                        subfamilia: string;
                                        kilataje: string;
                                        costo: number;
                                        tieneIncidencia: boolean;
                                    }
                                ) => (
                                    <RowAuditGoldTable
                                        key={recolectionitems.sku}
                                        {...recolectionitems}
                                    />
                                )
                            )
                        ) : (
                            <></>
                        )}
                    </Table>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={auditgoldcount / 10}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        forcePage={auditgoldpage}
                        onPageChange={handlePageClickHistoryAudit}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </div>
    );
};
