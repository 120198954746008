import React, {Fragment} from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {IQuoteDataProps} from '@components/CreditQuoter/interfaces';
import {AMORTIZATION_TABLE, CREDIT_QUOTER} from '@components/CreditQuoter/constants';

export const QuoteData = ({
    annualInterest,
    valuation,
    frequencyName,
    numberPeriods,
    payments,
    error,
    showTable,
    setShowTable,
}: IQuoteDataProps) => {
    const numberToCurrency = (number: number) => {
        return new Intl.NumberFormat('ES-MX', {
            style: 'currency',
            currency: 'MXN',
            maximumFractionDigits: 0,
        }).format(number);
    };
    const paymentAmount = Math.round(payments);
    const formattedInterest = annualInterest ? annualInterest + '%' : '50%';
    const formattedPaymentAmount = paymentAmount ? numberToCurrency(paymentAmount) : '$0';

    return (
        <>
            {!error ? (
                <Fragment>
                    <ContainerFlex
                        Gap="0.5rem"
                        Padding="1rem"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Radius="0.5rem"
                        FlexDir="column"
                    >
                        <Text Width="100%" FontSize="1rem" Color="#2A2C2F" Justify="center">
                            {AMORTIZATION_TABLE.QUOTE_DATA.ANNUAL_INTEREST}
                            <Text Color="#2A2C2F" FontWeight="700" PaddingL="0.25rem">
                                {formattedInterest}
                            </Text>
                        </Text>
                        <Text Width="100%" FontSize="1rem" Color="#2A2C2F" Justify="center">
                            {'Tasa -'}
                            <Text Color="#2A2C2F" FontWeight="700" PaddingL="0.25rem">
                                {valuation || AMORTIZATION_TABLE.QUOTE_DATA.FIXED}
                            </Text>
                        </Text>
                        <Text Width="100%" FontSize="1rem" Color="#2A2C2F" Justify="center">
                            {AMORTIZATION_TABLE.QUOTE_DATA.FREQUENCY}
                            <Text Color="#2A2C2F" FontWeight="700" PaddingL="0.25rem">
                                {frequencyName}
                            </Text>
                        </Text>
                    </ContainerFlex>

                    <ContainerFlex
                        Gap="0.5rem"
                        Padding="1rem"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Radius="0.5rem"
                    >
                        <Text FontSize="1rem" Color="#2A2C2F" Justify="center">
                            {`${numberPeriods || 0} pagos de:`}
                        </Text>
                        <Text Color="#2A2C2F" FontWeight="700" FontSize="1.25rem">
                            {formattedPaymentAmount}
                        </Text>
                    </ContainerFlex>

                    {payments && (
                        <ContainerFlex>
                            <Text
                                Color="#5A5AFF"
                                FontSize="0.875rem"
                                FontWeight="700"
                                TextAlign="center"
                                Cursor="pointer"
                                onClick={() => setShowTable(!showTable)}
                            >
                                {CREDIT_QUOTER.BTN_SEE_TABLE}
                            </Text>
                        </ContainerFlex>
                    )}
                </Fragment>
            ) : (
                <ContainerFlex
                    Gap="0.5rem"
                    Padding="1rem"
                    backG="#FFF"
                    Border="1px solid #E8E9EA"
                    Radius="0.5rem"
                >
                    <Text Width="200px" wSpace="normal" TextAlign="center">
                        {CREDIT_QUOTER.QUOTE_ERROR}
                    </Text>
                </ContainerFlex>
            )}
        </>
    );
};
