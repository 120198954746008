import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {TITLES_USER, UserUbication} from '@components/Users/UserProfile/constants';
import PhoneCal from '@images/Add call.svg';
import Mail from '@images/mail_gray.svg';
import Calendar from '@images/calendar_gray.svg';
import Profile from '@images/userImage.svg';
import Hour from '@images/clock.svg';
import Users from '@images/Users_select.svg';
import Place from '@images/location - branch.svg';
import Arrow from '@images/Down.svg';

import {ClickAwayListener} from '@mui/material';

export const UserCard = () => {
    const [show, setShow] = useState(false);
    return (
        <ContainerFlex
            Radius="24px"
            Border="1px solid #E8E9EA"
            Padding="16px"
            ColumnGap="16px"
            Height="177px"
        >
            <ContainerFlex Width="7%" Justify="start" Align="start">
                <Image alt="profile" src={Profile} Radius="50%" Width="100%" Height="64px" />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="8px" Justify="start" Align="start">
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="8px"
                    Height="24px"
                >
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {TITLES_USER.NAME}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Display="grid" GridColumns="1fr 1fr 1fr" Height="52px" Gap="8px">
                    <ContainerFlex Justify="start" Align="start" ColumnGap="4px" Height="10px">
                        <Image alt="phone" height="20px" src={PhoneCal} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.NUMBER}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="start" ColumnGap="4px" Height="10px">
                        <Image alt="mail" height="20px" src={Mail} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.EMAIL}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="start" ColumnGap="4px" Height="10px">
                        <Image alt="calendar" height="20px" src={Calendar} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.DATE}
                        </Text>
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.DATE_VALUE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="start" ColumnGap="8px" Height="10px">
                        <Image alt="hour" height="20px" src={Hour} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.HOUR}
                        </Text>
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.HOUR_VALUE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Height="32px" ColumnGap="38px" Width="60%" Justify="start">
                    <ContainerFlex Justify="start" Align="center" ColumnGap="4px" Height="10px">
                        <Image alt="users" height="24px" src={Users} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.ROL}
                        </Text>
                        <Text FontSize="1rem" FontWeight="700" Color="#1D1E20">
                            {TITLES_USER.ROL_VALUE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        Align="center"
                        ColumnGap="4px"
                        Height="10px"
                        Position="relative"
                    >
                        <Image alt="place" height="24px" src={Place} />
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.PLACE}
                        </Text>
                        <Text FontSize="1rem" FontWeight="700" Color="#1D1E20">
                            {TITLES_USER.PLACE_VALUE}
                        </Text>
                        <Image
                            alt="users"
                            height="24px"
                            Cursor="pointer"
                            src={Arrow}
                            onClick={() => setShow(true)}
                        />
                        {show && (
                            <ClickAwayListener
                                onClickAway={() => {
                                    setShow(false);
                                }}
                            >
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    Position="absolute"
                                    ZIndex="3"
                                    PositionTop="1rem"
                                    backG="#FFFFFF"
                                    Radius="8px"
                                    Border="1px solid #F3F3F3"
                                    Height="120px"
                                    BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                                    FlexDir="column"
                                >
                                    {UserUbication.map((data, index) => {
                                        return (
                                            <ContainerFlex
                                                Padding="8px"
                                                Justify="start"
                                                Height="40px"
                                                Align="start"
                                                FlexDir="column"
                                                Cursor="pointer"
                                                HBackground="#F0F0FF"
                                                HColor="#5A5AFF"
                                                HBorderLeft="1px solid #5A5AFF"
                                                key={index}
                                            >
                                                <Text
                                                    FontSize="1rem"
                                                    Color="#2A2C2F"
                                                    Cursor="pointer"
                                                    HColor="#5A5AFF"
                                                >
                                                    {data.Ubication}
                                                </Text>
                                            </ContainerFlex>
                                        );
                                    })}
                                </ContainerFlex>
                            </ClickAwayListener>
                        )}
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="center" ColumnGap="8px" Height="10px">
                        <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                            {TITLES_USER.STATE}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #B1E7C9"
                            backG="#E4F7EC"
                            Height="21px"
                            Width="50$"
                            Padding="8px"
                        >
                            <Text FontSize="0.875rem" FontWeight="700" Color="#174A2E">
                                {TITLES_USER.STATE_VALUE}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
