import {ROLE_ID} from '@Roles/CreateRoles/Redux/Types';
import {IRoleById, IRoleByIdRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: IRoleById = {
    roleById: {roleName: '', modules: [], roleType: 0, users: []},
    idRole: 0,
    error: false,
    loading: false,
};

export const RoleById = (state = initialState, action: IRoleByIdRedux): IRoleById => {
    switch (action.type) {
        case ROLE_ID.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ROLE_ID.SUCCESS:
            return {
                ...state,
                roleById: action.roleById,
                loading: false,
                error: false,
            };
        case ROLE_ID.CURRENT_ID:
            return {
                ...state,
                idRole: action.idRole,
            };
        case ROLE_ID.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
