import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants';

import {
    PUT_DATA_USER_EMPLOYEE,
    PUT_DATA_USER_EMPLOYEE_SUCCESS,
    PUT_DATA_USER_EMPLOYEE_ERROR,
    RESET_DATA_USER_UPDATE,
} from '@components/ManageUser/Redux/types';
import {AppDispatch} from '@/config/store';
import {IUpdateUser} from '@components/ManageUser/interface';
import {getUserInformation} from '@components/ManageUser/Redux/Actions/GetUserInformationById';

export const putDataUserEmployee = () => ({type: PUT_DATA_USER_EMPLOYEE});
export const putDataUserEmployeeSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_DATA_USER_EMPLOYEE_SUCCESS,
        payload: result,
    };
};
export const putDataUserEmployeeError = () => ({
    type: PUT_DATA_USER_EMPLOYEE_ERROR,
});

export const resetDataUserUpdate = () => ({type: RESET_DATA_USER_UPDATE});
export const putDataUserEmployeeAxios = (token: string, data: IUpdateUser, idUser: number) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(putDataUserEmployee());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutDataUserEmployee}`,
                data,
                {headers}
            );
            dispatch(putDataUserEmployeeSuccess(response));
        } catch (error) {
            dispatch(putDataUserEmployeeError());
        } finally {
            dispatch(getUserInformation(idUser, token));
        }
    };
};
