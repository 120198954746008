import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {GetCatCountry} from '@/components/Branches/Redux/Actions/GetCatCountry';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {TreeCreateBranches} from '@/components/Branches/CreateBranchModal/TreeCreateBranches/TreeCreateBranches';
import {IOrganizationTreeModal} from '@/components/Branches/interfaces';

export const OrganizationTreeModal = ({
    createModal,
    setCreateModal,
    levelSelected,
    watchLevel,
    edit,
    trigger,
}: IOrganizationTreeModal) => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleEdit = () => {
        if (!edit) {
            levelSelected(undefined);
            setCreateModal(false);
            return;
        }
        setCreateModal(false);
    };

    useEffect(() => {
        dispatch(GetCatCountry(token));
    }, []);

    return (
        <Modal
            modalState={createModal}
            changeModalState={() => {}}
            titleModalState={true}
            Width="31.25rem"
            Height="34.625rem"
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            BorderRadius="4px"
            background="none"
            FlexDirMod="column"
        >
            <ContainerFlex FlexDir="column">
                <Text
                    BorderBt="solid 1px #35cf44"
                    Width="100%"
                    Padding=" 24px 16px"
                    FontSize="1.25rem"
                    Color="#35cf44"
                    FontWeight="normal"
                    Self="start"
                >
                    Seleccionar nivel organizacional
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    Padding="24px"
                    Justify="start"
                    Gap="32px"
                    Overflow="auto"
                    Align="start"
                >
                    <Text FontSize="0.875rem" Color="#414042" MinH="14px" Height="14px">
                        Selecciona el nivel organizacional
                    </Text>
                    {TreeComponent.data && TreeComponent.data.length && (
                        <TreeCreateBranches
                            listLevels={TreeComponent.data}
                            levelSelected={levelSelected}
                            watchLevel={watchLevel}
                            isAllOpen={true}
                            trigger={trigger}
                        />
                    )}
                </ContainerFlex>
                <ContainerFlex Width="31.25rem" Height="5.5rem">
                    <Text
                        MarginLeft="65px"
                        Cursor="pointer"
                        FontSize="0.875rem"
                        Color="#35cf44"
                        FontWeight="normal"
                        MarginRight="8px"
                        onClick={() => navigate('/CreateOrganization')}
                    >
                        Crear nivel
                    </Text>
                    <ImgLocation Cursor="pointer" Width="12px" src={nextArrow} />
                    <ButtonGeneral
                        width="5.813rem"
                        height="40px"
                        text="Cancelar"
                        type="button"
                        FontSize="0.875rem"
                        hColor="#35cf44"
                        transform=""
                        border="none"
                        margin="0 0 0 32px;"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => {
                            handleEdit();
                        }}
                    />
                    <ButtonGeneral
                        width="10.5rem"
                        text="Guardar y continuar"
                        FontSize="0.875rem"
                        transform=""
                        hBorder="#35cf44"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        margin="0 24px 0 24px;"
                        disabled={watchLevel === undefined}
                        clic={() => setCreateModal(false)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
