import React from 'react';

export function HeadTableRevisionTable() {
    return (
        <thead className="title-head-foundry">
            <tr>
                <th>Kilates</th>
                <th className="text-center">Gramos</th>
            </tr>
        </thead>
    );
}
