import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {
    GET_PAYMENTMETHOD_START,
    GET_PAYMENTMETHOD_SUCCESS,
    GET_PAYMENTMETHOD_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {IPaymentMethod} from '@components/AccountSettings/Redux/interfaces';

const getPaymentMethodStart = () => ({
    type: GET_PAYMENTMETHOD_START,
});

const getPaymentMethodSuccess = (data: IPaymentMethod[]) => ({
    type: GET_PAYMENTMETHOD_SUCCESS,
    paymentMethods: data,
});

const getPaymentMethodError = (error: AxiosError) => ({
    type: GET_PAYMENTMETHOD_ERROR,
    error: error,
});

export const GetPaymentMethod = (companyId: number, token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPaymentMethodStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCompanyPaymentMethod}`,
                {
                    headers,
                    params: {
                        companyId,
                    },
                }
            );
            const responseData = response.data.data;
            const paymentMethods: IPaymentMethod[] = responseData;
            dispatch(getPaymentMethodSuccess(paymentMethods));
        } catch (error: unknown) {
            dispatch(getPaymentMethodError(error as AxiosError));
        }
    };
};
