import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IconContainer} from '@/components/CashFlow/styles';
interface IMessage {
    message: string;
}
export const ErrorMessages = ({message}: IMessage) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <IconContainer
                FontSize="0.875rem"
                className="material-icons"
                Color="#FF6357"
                Width="auto"
                Height="auto"
            >
                close
            </IconContainer>
            <Text
                Color="#FF6357"
                FontSize="0.85rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
                Display="inline-block"
            >
                {message}
            </Text>
        </ContainerFlex>
    );
};
