import {ADD_USER_EMPLOYEE} from '@components/LocationsBranch/Redux/types';
import {IAddUserEmployee, IAddUserEmployeeRedux} from '@components/LocationsBranch/interfaces';

const initialState: IAddUserEmployee = {
    companyUsers: [],
    addedUsers: [],
    error: false,
    loading: false,
};

export const AddUserEmployee = (
    state = initialState,
    action: IAddUserEmployeeRedux
): IAddUserEmployee => {
    switch (action.type) {
        case ADD_USER_EMPLOYEE.START:
            return {...state, loading: true};
        case ADD_USER_EMPLOYEE.SUCCESS:
            return {
                ...state,
                companyUsers: action.companyUsers,
                loading: false,
            };
        case ADD_USER_EMPLOYEE.ADD:
            return {
                ...state,
                addedUsers: action.addedUsers,
            };
        case ADD_USER_EMPLOYEE.ERROR:
            return {...state, loading: false, error: false};
        case ADD_USER_EMPLOYEE.RESET_SEARCH: {
            const newState = {...initialState, addedUsers: [...state.addedUsers]};
            return newState;
        }
        case ADD_USER_EMPLOYEE.RESET_ADDED_LIST: {
            return {...initialState};
        }
        case ADD_USER_EMPLOYEE.REMOVE_BY_IDS: {
            return {
                ...state,
                addedUsers: state.addedUsers.filter(
                    (user) => !action.userIds.includes(user.employeeId)
                ),
            };
        }
        default:
            return state;
    }
};
