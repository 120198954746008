export const CREDIT_VALIDATION = {
    TITLE: 'Solicitud de préstamo',
    COUNTER: {
        HOURS: 'Horas',
        MINUTES: 'Minutos',
        SECONDS: 'Segundos',
    },
    EMAIL: {
        GET_ANSWER: 'Ten una respuesta en:',
        SEND_EMAIL: 'Te avisaremos mandando un correo a ',
        FINISH_VALIDATION: 'cuando terminemos de validar tus documentos.',
    },
    BUSINESS_LOANS: {
        TITLE: '¿Necesitas dinero rápido?',
        DESCRIPTION: 'Prueba a solicitar con estos otros negocios',
        TABLE_HEADERS: {
            BUSINESS: 'Negocio / Entidad',
            DETAILS: 'Detalle',
        },
    },
    CALL_TO_ACTION: {
        TITLE: 'Empeña y consigue el dinero que necesitas',
        DESCRIPTION: 'Consulta nuestra oferta de empeño para recibir el dinero que necesitas.',
        BTN_DESCRIPTION: 'Cotizar empeño',
    },
    LOGO: {
        POWER: 'Power',
        CREDITS: 'Créditos',
    },
    ERROR: 'No hay alguna solicitud',
    TABLE_CONTENT_BUSINESS: {
        MULTIAPOYO: {
            CALL_TO_ACTION: 'Visita la página de Multiapoyo',
            DETAILS: {
                DESC_1: 'Plazos entre 6 y 24.',
                DESC_2: 'Trámite 100% en línea.',
                DESC_3: 'Sin aval ni anticipos.',
            },
            LOGO_SIZE: {
                WIDTH: '113px',
                HEIGHT: '23px',
            },
        },
        KUESKI: {
            CALL_TO_ACTION: 'Visita la página de Kueski',
            DETAILS: {
                DESC_1: 'Sin aval ni anticipos.',
                DESC_2: 'Trámite en línea.',
            },
            LOGO_SIZE: {
                WIDTH: '100px',
                HEIGHT: '26.366px',
            },
        },
        BAUBAP: {
            CALL_TO_ACTION: 'Visita la página de Baubap',
            DETAILS: {
                DESC_1: 'Sin aval ni anticipos.',
                DESC_2: 'Trámite en línea.',
            },
            LOGO_SIZE: {
                WIDTH: '96.49px',
                HEIGHT: '31px',
            },
        },
    },
};
