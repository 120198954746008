import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {DateTime} from 'luxon';
import Grid from '@mui/material/Grid';

import {Text} from '@Shopify/Ecommerce/styles';
import {ContainerInputs} from '@Pawn/styles';
import {ErrorMessage} from '@hookform/error-message';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {
    getSucursal,
    postPawn,
    getPawn,
    fetchGetPawnid,
    fetchGetDataSearch,
} from '@ActionsPawn/PawnActions';
import useMediaQuery from '@mui/material/useMediaQuery';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {
    TableRowHeadContract,
    TableItems,
    TableRowItemsContract,
    Table,
    TableItem,
} from '@Shopify/Endeavors/styles';

export function Reactivations() {
    const {
        handleSubmit,
        register,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            group: 0,
            contract: '',
        },
    });
    const [selectedArticles, setSelectedArticles] = useState([]);

    const onSelectRequest = (e, index, fecha) => {
        let lselected = selectedArticles;

        if (e.target.checked) {
            if (selectedArticles.length === 0) {
                lselected = [...lselected, fecha];
                setSelectedArticles(lselected);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Solo puede mandar 1 fecha',
                });
            }
        } else {
            if (lselected.length > 0) {
                lselected = lselected.filter((item) => fecha !== item);
                setSelectedArticles(lselected);
            }
        }
    };
    const isChecked = (fecha) => {
        if (selectedArticles === '' || selectedArticles[0] === fecha) {
            for (let item of selectedArticles) {
                if (item === fecha) return true;
            }
        } else {
            return false;
        }
    };
    const worOrderTemplate = (fecha, index) => {
        return (
            <input
                type="checkbox"
                onChange={(e) => onSelectRequest(e, index, fecha)}
                checked={isChecked(fecha)}
            />
        );
    };
    // const articleClicked = (pago,index) => {
    //   e.stopPropagation();

    //   if (selectedArticles === '') {
    //     if (checked) {
    //       setSelectedArticles([...selectedArticles, articleId]);
    //       return (
    //         <input
    //           type="checkbox"
    //           onChange={(e) => articleClicked(
    //             e.target.checked,
    //             pawntable.fechaLimitePago,
    //             e
    //           )}
    //           checked={true}
    //           disabled={this.state.alreadySave >= 1 ? true : false}
    //         />
    //       );
    //     } else {
    //       let articleIndex = selectedArticles.indexOf(articleId);
    //       let updatedArticles = selectedArticles.slice();
    //       updatedArticles.splice(articleIndex, 1);
    //       setSelectedArticles(updatedArticles);
    //       return (
    //         <input
    //           type="checkbox"
    //           onChange={(e) => articleClicked(
    //             e.target.checked,
    //             pawntable.fechaLimitePago,
    //             e
    //           )}
    //           checked={false}
    //           disabled={this.state.alreadySave >= 1 ? true : false}
    //         />
    //       );
    //     }
    //   }
    //   else {
    //     if (checked && articleId !== selectedArticles) {
    //       Swal.fire({
    //         icon: "warning",
    //         title: "Solo puede mandar 1 fecha",
    //       });
    //     }
    //     if (!checked && articleId !== selectedArticles) {
    //       setSelectedArticles('')
    //     }
    //   }
    // };
    const dispatch = useDispatch();
    const selectChange = async () => {
        if (token) {
            dispatch(getSucursal(token));
        }
    };
    useEffect(() => {
        selectChange();

        if (reactivations?.databranch !== null) {
            reset({
                group: reactivations.databranch,
                contract: reactivations.datacontract,
            });
        }
    }, []);
    const addRow = async () => {
        let jason = {
            idSucursal: reactivations.databranch,
            idEmpeño: reactivations.datacontract,
            fecha: DateTime.fromJSDate(selectedArticles[0]).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        };
        await dispatch(postPawn(token, jason));
        setSelectedArticles([]);
    };

    const reactivations = useSelector((state) => {
        return state.reactivations;
    });

    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });

    const onSubmit = async (values) => {
        if (!reactivations.pawn) {
            await dispatch(getPawn(Number(values.group), Number(values.name), token));
            dispatch(fetchGetDataSearch(values.group, values.name));
            setSelectedArticles([]);
        }
    };
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const mobileSize = useMediaQuery('(max-width: 770px');

    return (
        <>
            <div>
                <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                    <span className="child-breadcrumb">Administración</span>
                    <span className="pt-2">
                        <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                    </span>
                    <span className="principal-breadcrumb">Reactivaciones</span>
                </div>
                <div className="w-90 mx-auto mt-4">
                    <form className="container-form" onSubmit={handleSubmit(onSubmit)}>
                        {reactivations.pawn ? (
                            <Text Padding="0 0 24px 0" FontWeight="400" FontSize="14px">
                                Seleccione el contrato a reactivar
                            </Text>
                        ) : (
                            <ContainerInputs>
                                <div>
                                    <Grid item xs={12} sm={6}>
                                        <label className="required">Sucursal</label>
                                        <select
                                            {...register('group')}
                                            id="group"
                                            className="input-react-hook-form"
                                        >
                                            <option value="default" selected disabled>
                                                Selecciona una Sucursal
                                            </option>
                                            {reactivations.sucursal &&
                                                reactivations.sucursal.map((sucursals, index) => (
                                                    <option value={sucursals.id} key={index}>
                                                        {sucursals.description}
                                                    </option>
                                                ))}
                                        </select>
                                        <ErrorMessage
                                            errors={errors}
                                            className="required-text"
                                            name="group"
                                            message="Seleccione motivo"
                                            as="p"
                                        />

                                        <ErrorMessage
                                            errors={errors}
                                            className="required-text"
                                            name="motivo"
                                            message="Seleccione motivo"
                                            as="p"
                                        />
                                    </Grid>
                                </div>
                                <div className="pl-4">
                                    <Grid item xs={12} sm={6}>
                                        <label className="required">Número Contrato</label>
                                        <input
                                            className="input-react-hook-form"
                                            {...register('name', {
                                                required: 'Ingrese motivo',
                                            })}
                                        />
                                    </Grid>
                                </div>
                                <button className=" btn-submit " type="submit">
                                    Buscar
                                </button>
                            </ContainerInputs>
                        )}
                        {!mobileSize ? (
                            <ContainerFlex>
                                <Table>
                                    {reactivations.loading && <LoadingGeneral />}
                                    {reactivations.pawn && (
                                        <>
                                            <TableRowHeadContract
                                                Background="#f3f3f3"
                                                GridTemplate="repeat(7, 1fr)"
                                            >
                                                <TableItem>Check</TableItem>
                                                <TableItem>Plazo</TableItem>
                                                <TableItem>Id Empeño</TableItem>
                                                <TableItem>Mutuo</TableItem>
                                                <TableItem>Intereses</TableItem>
                                                <TableItem>IVA</TableItem>
                                                <TableItem>Fecha Limite</TableItem>
                                            </TableRowHeadContract>

                                            <TableRowItemsContract GridTemplateColumn="repeat(7 , 1fr)">
                                                {reactivations.pawn.map((pawntable, index) => (
                                                    <>
                                                        <TableItems Justify="center">
                                                            {worOrderTemplate(
                                                                pawntable.fechaLimitePago,
                                                                index
                                                            )}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {pawntable.plazo}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {pawntable.idEmpeño}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {formatterDolar.format(pawntable.mutuo)}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {formatterDolar.format(
                                                                pawntable.intereses
                                                            )}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {formatterDolar.format(pawntable.iva)}
                                                        </TableItems>
                                                        <TableItems Size="14px" Justify="center">
                                                            {DateTime.fromISO(
                                                                pawntable.fechaLimitePago
                                                            ).toFormat('dd/MM/yyyy')}
                                                        </TableItems>
                                                    </>
                                                ))}
                                            </TableRowItemsContract>
                                            <div>
                                                <span />
                                                <ContainerFlex Justify="flex-end">
                                                    {selectedArticles.length > 0 ? (
                                                        <button
                                                            className=" btn-submit"
                                                            onClick={() => addRow()}
                                                        >
                                                            Condonar
                                                        </button>
                                                    ) : null}
                                                </ContainerFlex>
                                            </div>
                                        </>
                                    )}
                                </Table>
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex>
                                {/*Mobile*/}
                                <Table>
                                    {reactivations.loading && <LoadingGeneral />}
                                    {reactivations.pawn && (
                                        <>
                                            <TableRowHeadContract
                                                Background="#f3f3f3"
                                                GridTemplate="repeat(2, 1fr)"
                                            >
                                                <TableItem Size="14px">Plazo</TableItem>
                                                <TableItem Size="14px">Fecha Limite</TableItem>
                                            </TableRowHeadContract>
                                            <TableRowItemsContract GridTemplateColumn="repeat(2, 1fr)">
                                                {reactivations.pawn.map((pawntable, index) => (
                                                    <>
                                                        <TableItems
                                                            Size="14px"
                                                            TextDecoration="underline"
                                                            Bb="1px solid #E4E7E9"
                                                            Color="#7AC143"
                                                            onClick={() => {
                                                                window.location.href = `./ReactivationScreen`;
                                                                dispatch(
                                                                    fetchGetPawnid(pawntable.plazo)
                                                                );
                                                            }}
                                                        >
                                                            {pawntable.plazo}
                                                        </TableItems>
                                                        <TableItems
                                                            Size="14px"
                                                            Padding=".5rem"
                                                            Justify="center"
                                                        >
                                                            {DateTime.fromISO(
                                                                pawntable.fechaLimitePago
                                                            ).toFormat('dd/MM/yyyy')}
                                                        </TableItems>
                                                    </>
                                                ))}
                                            </TableRowItemsContract>
                                        </>
                                    )}
                                </Table>
                            </ContainerFlex>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
