import {
    QUICK_ACTIONS_SUCCESS,
    QUICK_ACTIONS_ERROR,
    CLIENT_PROFILE,
} from '@Shopify/ClientProfile/Redux/types';
import {IQuickAction, IQuickActionRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IQuickAction = {
    error: false,
    loading: false,
    actions: [],
};

export const quickAction = (state = initialState, action: IQuickActionRedux): IQuickAction => {
    switch (action.type) {
        case CLIENT_PROFILE.INITIAL_DATA:
            return {...state, loading: true, error: false};
        case QUICK_ACTIONS_SUCCESS:
            return {
                ...state,
                actions: action.actions,
                loading: false,
            };
        case QUICK_ACTIONS_ERROR:
            return {...state, loading: false, error: true, actions: []};
        default:
            return state;
    }
};
