import React from 'react';

import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {ContainerFlex, ContainerCellGrid, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import optionsIcon from '@images/moreMenu.svg';
import {CREDITS_LISTING, getStyle} from '@Shopify/ClientProfile/constants';
import {PersonalCreditItemProps} from '@Shopify/ClientProfile/interfaces';

const PersoanlCreditItem: React.FC<PersonalCreditItemProps> = ({credit}) => {
    const statusStyle = getStyle(credit.status);
    return (
        <ContainerFlex
            Display="grid"
            GridColumns="repeat(6, minmax(3.125rem, 1fr)) 2.5rem"
            Justify="start"
            Align="center"
            Bb="1px solid #E8E9EA"
        >
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Text FontSize="0.75rem" Color="#2A2C2F" FontWeight="400">
                    {credit.creditNumber}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <FlexPanel
                    Justify="center"
                    Align="center"
                    Radius="2rem"
                    Padding="0.125rem 0.5rem"
                    Height="1.125rem"
                    Border={statusStyle.border}
                    Background={statusStyle.backgroundColor}
                >
                    <Text
                        FontWeight="700"
                        FontSize="0.75rem"
                        Justify="center"
                        Color={statusStyle.color}
                    >
                        {credit.status}
                    </Text>
                </FlexPanel>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                    {numberToCurrency(credit.loan)}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                    {credit.payments}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                    {credit.interestRate}%
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700" Cursor="pointer">
                    {CREDITS_LISTING.MAKE_PAYMENT}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid
                Height="3rem"
                Padding="0.25rem 1rem"
                Justify="start"
                Align="center"
                Gap="0.25rem"
            >
                <Image
                    src={optionsIcon}
                    alt="options"
                    Width="1.5rem"
                    Height="1.5rem"
                    Cursor="pointer"
                />
            </ContainerCellGrid>
        </ContainerFlex>
    );
};

export default PersoanlCreditItem;
