import {
    SET_SELECTED_PLEDGE,
    SET_SELECTED_PLEDGE_RESET,
} from '@Quoter/CoownerBeneficiary/Redux/types';
import {SelectedPledgeProps} from '@Quoter/CoownerBeneficiary/Redux/interfaces';
import {IContract} from '@/components/ShoppingCart/interface';

const initialState: SelectedPledgeProps = {
    dataPledge: null,
};

const SelectedPledgeReducer = (
    state = initialState,
    action: {type: string; payload: IContract}
): SelectedPledgeProps => {
    switch (action.type) {
        case SET_SELECTED_PLEDGE:
            return {
                ...state,
                dataPledge: action.payload,
            };
        case SET_SELECTED_PLEDGE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default SelectedPledgeReducer;
