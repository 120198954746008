import React from 'react';
import {useForm} from 'react-hook-form';

import {ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {APP_SETTINGS} from '@components/AppSettings/constants';
import {IFormAppSetting} from '@components/AppSettings/interfaces';
import {CountrySettings} from '@components/AppSettings/CountrySettings';
import {LanguajeSettings} from '@components/AppSettings/LanguajeSettting';
import {TimeZoneSettings} from '@components/AppSettings/TimeZoneSettings';
import {CoinTypeSettings} from '@components/AppSettings/CoinTypeSettings';
import {NotificationsSettings} from '@components/AppSettings/NotificationsSettings';

export const AppSettings = () => {
    const {control, register, watch} = useForm<IFormAppSetting>({
        defaultValues: {
            languaje: {value: 1, label: 'Español (México)'},
            timeZoneAutomatic: true,
            timeZone: {value: 1, label: 'CTS (CDMX)'},
            coinType: {value: 1, label: 'MXN'},
            sesion: true,
            functions: true,
            account: true,
            plans: true,
        },
    });

    return (
        <ContainerForm
            Gap="1rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Padding="1.5rem 1rem"
            BackG="#F4F5F5"
            Height="auto"
        >
            <Text FontSize="1.5rem" Padding="0.7rem 0" Color="#2A2C2F" FontWeight="500">
                {APP_SETTINGS.SETTINGS}
            </Text>

            <LanguajeSettings control={control} />
            <CountrySettings />
            <TimeZoneSettings register={register} control={control} watch={watch} />
            <CoinTypeSettings control={control} />
            <NotificationsSettings register={register} />
        </ContainerForm>
    );
};
