import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, ContainerForm, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {numberToCurrency} from '@CashFlow/constants';
import ButtonGeneral from '@General/Atoms/Button/index';
import {CloseBranchForm} from '@/components/CloseBranch/CloseBranchForm';
import {SchemaFormCloseBranch} from '@components/CloseBranch/SchemaCloseBranch';
import {IFormCloseBox} from '@components/CloseBranch/interface';
import {putBranchUserInfo} from '@components/CloseBranch/redux/actions/PutUserBranchData';
import {RootState} from '@/config/store';
import {BranchFormInformationProps, UserAmount} from '@components/CloseBranch/interfaces';
import {ErrorMessageClose} from '@components/CloseBranch/ErrorMessage';
import {balanceCashFlow} from '@CashFlow/Reduxs/Actions/BalanceCash';

export const BranchFormInformation = ({
    setScreen,
    isConfirm,
    setIsConfirm,
}: BranchFormInformationProps) => {
    const dispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const BranchData = useSelector((state: RootState) => {
        return state.getUserDataBranch;
    });
    const BranchId = useSelector((state: RootState) => {
        return state.BranchIdCash;
    });
    const [userAmounts, setUserAmounts] = useState<UserAmount[]>([
        {
            userEmployeeId: 0,
            amount: 0,
        },
    ]);
    const [isError, setIsError] = useState(false);

    const {
        handleSubmit,
        register,
        control,
        setValue,
        watch,
        reset,
        formState: {errors, isValid},
    } = useForm<IFormCloseBox>({
        resolver: yupResolver(SchemaFormCloseBranch),
        defaultValues: {
            diferenceValue: '',
            observations: '',
        },
    });
    const ReferencValue = watch('diferenceValue');
    const onSubmit = (data: {diferenceValue: string; observations: string}) => {
        const postData = {
            branchId: BranchData.Branch.branchId,
            initialBalance: BranchData.Branch.initialBalance,
            finalBalance: BranchData.Branch.finalBalance,
            pawnsAmmount: BranchData.Branch.pawnsAmmount,
            endorsementsAmmount: BranchData.Branch.endorsementsAmmount,
            dischargesAmmount: BranchData.Branch.dischargesAmmount,
            branchFundingAmmount: BranchData.Branch.branchFundingAmmount,
            surplu: BranchData.Branch.surplu,
            match: isConfirm === 1 ? true : false,
            differenceAmount: Number(data.diferenceValue.replaceAll(',', '').replaceAll('$', '')),
            observation: data.observations,
            missigAmountEmployee: userAmounts,
        };
        dispatch(putBranchUserInfo(postData, token, setScreen));
        dispatch(balanceCashFlow(token, String(BranchId.branchId)));
    };
    const ValidateInput = userAmounts.some(
        (data: {amount: number}) =>
            data.amount > Number(ReferencValue.replaceAll(',', '').replaceAll('$', ''))
    );
    const fullValue = userAmounts.reduce(
        (accumulator: number, currentValue: {amount: number}) => accumulator + currentValue.amount,
        0
    );
    const validateInput = () => {
        if (isConfirm === 0) {
            return true;
        }

        if (isConfirm === 2) {
            if (
                ValidateInput ||
                !isValid ||
                fullValue > Number(ReferencValue.replace(/[, $]/g, '')) ||
                fullValue < Number(ReferencValue.replace(/[, $]/g, '')) ||
                fullValue === 0
            ) {
                return true;
            }
        }

        return false;
    };
    useEffect(() => {
        if (BranchData.Branch?.branchCashiers)
            setUserAmounts(
                BranchData.Branch.branchCashiers.map((data: {employeeId: number}) => ({
                    userEmployeeId: data.employeeId,
                    amount: 0,
                }))
            );
    }, [BranchData]);
    useEffect(() => {
        if (BranchData.Branch?.pledge?.pledgeNumber && BranchData.Branch?.pledge?.pledgeNumber > 0)
            setIsError(true);
    }, []);
    return (
        <>
            <ContainerFlex
                Border="1px solid #35cf44"
                Width="18.13rem"
                Height="67px"
                GridColumns="1fr 1fr 1fr"
                Justify="start"
            >
                <ContainerFlex FlexDir="column" Padding="16px 24px" Height="auto">
                    <Text FontSize="0.75rem">Saldo Inicial</Text>
                    <Text FontSize="1.125rem">
                        {numberToCurrency(
                            BranchData.Branch?.initialBalance
                                ? BranchData.Branch?.initialBalance
                                : 0
                        )}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="start" Bl="1px solid #35cf44" Height="27px" />
                <ContainerFlex FlexDir="column" Padding="16px 24px" Height="auto">
                    <Text FontSize="0.75rem">Saldo al Cierre</Text>
                    <Text FontSize="1.125rem">
                        {numberToCurrency(
                            BranchData.Branch.finalBalance ? BranchData.Branch.finalBalance : 0
                        )}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerForm FlexDir="column" Padding="60px 0 0 0" onSubmit={handleSubmit(onSubmit)}>
                <Text>
                    {' '}
                    Confirmo que los valores relacionados en la sucursal se encuentra bien en
                    relación al dinero
                </Text>
                <ContainerFlex Justify="center" Padding="0 0 32px 0">
                    <InputColor
                        type="radio"
                        value={1}
                        checked={isConfirm === 1 ? true : false}
                        onChange={() => {
                            setIsConfirm(1);
                            reset();
                        }}
                    />{' '}
                    <Text Padding="0 40px 0 4px">Si</Text>
                    <InputColor
                        type="radio"
                        value={2}
                        disabled={BranchData.error}
                        checked={isConfirm === 2 ? true : false}
                        onChange={() => {
                            setIsConfirm(2);
                            reset();
                        }}
                    />{' '}
                    <Text Padding="0 0 0 4px">No</Text>
                </ContainerFlex>
                {isConfirm === 2 && (
                    <CloseBranchForm
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        setUserAmounts={setUserAmounts}
                        userAmounts={userAmounts}
                    />
                )}
                {isError ||
                    (BranchData.error && (
                        <ContainerFlex Justify="center" Padding="2rem 0">
                            <ErrorMessageClose />
                        </ContainerFlex>
                    ))}

                <ButtonGeneral
                    text="Cerrar Sucursal"
                    type="submit"
                    disabled={validateInput()}
                    clic={() => {
                        const Pet = {diferenceValue: '', observations: ''};
                        if (isConfirm === 1) onSubmit(Pet);
                    }}
                />
            </ContainerForm>
        </>
    );
};
