import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_TABLE_AMORTIZATION_START,
    GET_TABLE_AMORTIZATION_SUCCESS,
    GET_TABLE_AMORTIZATION_ERROR,
} from '@components/CreditQuoter/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IFiltersAmortizationTable} from '@components/CreditQuoter/interfaces';
import StringUtils from '@/GenericScripts/utils';

export const getTableAmortizationStart = () => {
    return {
        type: GET_TABLE_AMORTIZATION_START,
    };
};

export const getTableAmortizationSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TABLE_AMORTIZATION_SUCCESS,
        payload: result.data,
    };
};

export const getTableAmortizationError = (error: AxiosError) => {
    return {
        type: GET_TABLE_AMORTIZATION_ERROR,
        error,
    };
};

export const getTableAmortization = (token: string, filters: IFiltersAmortizationTable) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(getTableAmortizationStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTableAmortization}${queryString}`,
                {headers}
            );
            dispatch(getTableAmortizationSuccess(response));
        } catch (error) {
            dispatch(getTableAmortizationError(error as AxiosError));
        }
    };
};
