import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {ROLE_USERS} from '@Roles/CreateRoles/Redux/Types';

const RolesUser = () => {
    return {type: ROLE_USERS.ASIGNMENT_START};
};
const RolesUserSuccess = (result: {data: AxiosResponse}) => ({
    type: ROLE_USERS.ASIGNMENT_SUCCESS,
    userRoles: result.data,
});
const RolesUserError = (error: AxiosError) => ({
    type: ROLE_USERS.ASIGNMENT_ERROR,
    errorUser: error,
});

export const GetRolesUser = (filters: {companyId: number; search: string}, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(RolesUser());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSingleSearchAllUsers}${queryString}`,
                {headers}
            );
            dispatch(RolesUserSuccess(response.data));
        } catch (error) {
            dispatch(RolesUserError(error as AxiosError));
        }
    };
};
