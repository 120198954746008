import React from 'react';

import useScrollIntoView from '@/hooks/useScrollIntoView';
import {ContainerFlex, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {IAttributeGroupProps} from '@components/LocationsBranch/interfaces';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

export const AttributeGroup: React.FC<IAttributeGroupProps> = ({
    title,
    options,
    onSelect,
    selectedValue,
    loading,
}) => {
    const selectedRef = useScrollIntoView(selectedValue);
    return (
        <>
            {options && options.length > 0 ? (
                <ContainerFlex
                    Padding="8px"
                    FlexDir="column"
                    Justify="flex-start"
                    Align="flex-start"
                    Gap="8px"
                    Flex="1 0 0"
                    Radius="8px"
                    backG="#FFF"
                >
                    <Text Color="#2A2C2F" FontSize="1rem" FontWeight="500" oFlow="visible">
                        {title}
                    </Text>
                    <ContainerFlex
                        PaddingL="16px"
                        FlexDir="column"
                        Justify="flex-start"
                        Align="flex-start"
                        Flex="1 0 0"
                        Self="stretch"
                        OverFlow="scroll"
                    >
                        {loading ? (
                            <ContainerFlex Justify="center">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            options.map((option) => (
                                <ContainerFlex
                                    key={option.levelId}
                                    Padding="8px"
                                    Gap="8px"
                                    Justify="flex-start"
                                    Align="center"
                                    Self="stretch"
                                    Height="2.389rem"
                                    ref={option.levelId === selectedValue ? selectedRef : null}
                                >
                                    <InputColor
                                        type="radio"
                                        value={option.levelId}
                                        checked={option.levelId === selectedValue}
                                        onChange={() => onSelect(option.levelId)}
                                    />
                                    <Text FontSize="1rem" color="#2A2C2F" FontWeight="500">
                                        {option.level} {option.count !== 0 && `(${option.count})`}
                                    </Text>
                                </ContainerFlex>
                            ))
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            ) : (
                <ContainerFlex
                    Padding="8px"
                    FlexDir="column"
                    Align="flex-start"
                    Gap="8px"
                    Flex="1 0 0"
                    Radius="8px"
                    backG="#F0F0FF"
                />
            )}
        </>
    );
};
