import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {
    TableItems,
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@CashFlow/FundingInquiry/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RowContainer} from '@DeadLines/styles';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {HeaderMiniTable} from '@/components/DeadLines/CreateDeadline/redux/Constants';
import {DeadlineResult} from '@CreateDeadline/interfaces';

interface RequestRow {
    openDetail?: boolean;
    handleID: () => void | null;
}

const RequestsRowDeadline = (props: RequestRow) => {
    const simulation: DeadlineResult = useSelector((state: RootState) => {
        return state.deadlineSimulator;
    });
    const {handleID = () => null} = props;
    const [showDetails, setShowDetails] = useState(false);
    const HandleStatus = () => {
        setShowDetails(!showDetails);
        handleID();
    };
    return (
        <TableItemsContainer Height="12rem">
            <TableRowItems
                GridColumn="1fr 1fr 1fr 1fr 1fr 2fr"
                Cursor="pointer"
                Height="32px"
                Margin="1rem 0 1.5rem 0"
                Border=""
                onClick={() => HandleStatus()}
            >
                <TableItems Padding="0 2rem" Justify="start" FontSize="0.857rem">
                    {simulation.filters.data.resultTable.temporaryServiceNumber}
                </TableItems>
                <TableItems FontSize="0.857rem">
                    {simulation.filters.data.resultTable.term}
                </TableItems>
                <TableItems FontSize="0.857rem">
                    {formatterDolar.format(simulation.filters.data.resultTable.mutualAmount)}
                </TableItems>
                <TableItems FontSize="0.857rem">
                    {formatterDolar.format(simulation.filters.data.resultTable.interests)}
                </TableItems>
                <TableItems FontSize="0.857rem">
                    {formatterDolar.format(simulation.filters.data.resultTable.iva)}
                </TableItems>
                <TableItems FontSize="0.857rem" Justify="center">
                    {simulation.filters.data.resultTable.numberGarments}
                </TableItems>
            </TableRowItems>
            <ContainerFlex FlexDir="column">
                <TableRowHead
                    GridColumn="1fr 1fr 1fr 1fr 1fr 1.5fr"
                    BackG="white"
                    Width="85%"
                    Border="1px solid #e4e7e9"
                    Margin="0 0 1rem 0"
                    Height="64px"
                >
                    {HeaderMiniTable?.map((header, index) => (
                        <RowContainer key={index} Justify="center" Align="center">
                            <Text
                                Padding="1.5rem 0"
                                FontSize="0.857rem"
                                FontWeight="500"
                                wSpace="normal"
                            >
                                {header.name}
                            </Text>
                        </RowContainer>
                    ))}
                </TableRowHead>

                {simulation.filters.data.resultTable.amortizationTable?.map(
                    (item, index: number) => {
                        return (
                            <TableRowItems
                                key={index}
                                GridColumn="1fr 1fr 1fr 1fr 1fr 1.5fr"
                                Cursor="pointer"
                                Width="85%"
                                Border=""
                                Height="32px"
                                onClick={() => HandleStatus()}
                            >
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.interestRate}
                                </TableItems>
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.paydayLimit}
                                </TableItems>
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.interest}
                                </TableItems>
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.iva}
                                </TableItems>
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.endorsementPayment}
                                </TableItems>
                                <TableItems FontSize="0.857rem" Justify="center">
                                    {item.dischargePayment}
                                </TableItems>
                            </TableRowItems>
                        );
                    }
                )}
            </ContainerFlex>
        </TableItemsContainer>
    );
};

export default RequestsRowDeadline;
