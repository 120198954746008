import {AppDispatch} from '@/config/store';

import {
    DELETE_TEMPORALY_PAWN,
    DELETE_TEMPORALY_PAWN_SUCCESS,
    DELETE_TEMPORALY_PAWN_ERROR,
} from '@components/ShoppingCart/Redux/types';
import {IDeleteTemporalyPawn} from '@components/ShoppingCart/Redux/interfaces';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {getShoppingCartContracts} from '@components/ShoppingCart/Redux/Actions/getCartContractsActions';

export const DeleteTemporalyPawn = () => {
    return {
        type: DELETE_TEMPORALY_PAWN,
    };
};
export const DeleteTemporalyPawnSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_TEMPORALY_PAWN_SUCCESS,
        payload: result.data,
    };
};
export const DeleteTemporalyPawnError = () => {
    return {
        type: DELETE_TEMPORALY_PAWN_ERROR,
    };
};

export function DeleteTemporalyPawns(
    token: string,
    data: IDeleteTemporalyPawn,
    carShoppingId?: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(DeleteTemporalyPawn());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteTemporalyPawn}`,
                {data, headers}
            );
            dispatch(DeleteTemporalyPawnSuccess(response));
            if (carShoppingId)
                dispatch(getShoppingCartContracts(token, {carshopingId: carShoppingId}));
        } catch (error) {
            dispatch(DeleteTemporalyPawnError());
        }
    };
}
