import * as yup from 'yup';

export const schemaPassword = yup.object().shape({
    newPassword: yup
        .string()
        .required('Ingrese una contraseña')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\S]{8,16}$/,
            'Mínimo 8 caracteres, entre ellas 1 mayúscula, números y caracteres especiales.'
        ),
});

export const schema = yup.object().shape({
    names: yup.string().required('Ingrese un nombre').max(160),
    surnaNames: yup.string().required('Ingrese un apellido').max(160),
    userName: yup.string().required('Ingrese un nombre de usuario').max(160),
    jobPosition: yup.string().required('Ingrese un puesto').max(160),
    status: yup.boolean().required(),
    password: yup
        .string()
        .required('Ingrese una contraseña')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^$@$!#+%&<>.])[A-Za-z\d\S]{8,16}$/,
            'Mínimo 8 caracteres, entre ellas 1 mayúscula, números y caracteres especiales.'
        ),
    repeatPassword: yup
        .string()
        .required('Ingrese la confirmación de contraseña')
        .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
    startChangePassword: yup.boolean().required(),
    employeeRolees: yup.array(),
    aditionalInformationEmployee: yup.object().shape({
        manageAditionalInformation: yup.boolean().required(),
        address: yup.string().when('manageAditionalInformation', {
            is: true,
            then: (schema) => schema.required('Ingrese una dirección'),
            otherwise: (schema) => schema.optional(),
        }),
        email: yup.string().when('startSesionEmail', {
            is: true,
            then: (schema) =>
                schema
                    .matches(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        'Ingresa un correo electrónico válido'
                    )
                    .required('Ingrese un correo'),
            otherwise: (schema) =>
                schema.matches(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    'Ingresa un correo electrónico válido'
                ),
        }),
        phone: yup.string().when('startSesionPhone', {
            is: true,
            then: (schema) =>
                schema
                    .required('Ingrese un número de teléfono')
                    .min(10, 'El campo contener mínimo 10 dígitos'),
            otherwise: (schema) => schema.min(10, 'El campo contener mínimo 10 dígitos'),
        }),
        startSesionEmail: yup.boolean().required(),
        startSesionPhone: yup.boolean().required(),
    }),
    levelBranch: yup.array().min(1),
});
