import styled from '@emotion/styled';
import {NumericFormat} from 'react-number-format';
import {
    IPropsContainerImagen,
    IPropsForm,
    IPropsIcons,
    IPropsInput,
    IPropsNumbers,
    IPropsSelect,
    IPropsSelectForm,
    IPropsStyleNumericFormat,
} from '@Loan/interfacesStyles';

export const Input = styled.input<IPropsInput>`
    display: flex;
    flex-direction: ${(props) => props.FlexDir};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    padding: ${(props) => props.Padding && props.Padding};
    margin: ${(props) => props.Margin && props.Margin};
    width: ${(props) => props.Width && props.Width};
    height: ${(props) => props.Height};
    text-align: ${(props) => props.TextA};
    box-sizing: border-box;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
    outline: none;
`;

export const InputCheckbox = styled.input`
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    transform: translateY(-0.075em);
    background-color: #e8e9ea;
    cursor: pointer;

    &:hover {
        border: 1px solid #5a5aff;
    }
    &:checked {
        border: 1px solid #5a5aff;
        &::after {
            content: '';
            position: absolute;
            top: 6px;
            left: 6px;
            width: 10px;
            height: 10px;
            background-color: #5a5aff;
            clip-path: polygon(20% 50%, 10% 65%, 50% 100%, 100% 15%, 85% 5%, 50% 70%);
            transform-origin: bottom left;
        }
    }
`;

Input.defaultProps = {
    FlexDir: 'row',
    Align: 'center',
    Justify: 'center',
    FlexWrap: 'nowrap',
    Padding: '0',
    Margin: '0',
    Width: '100%',
    Height: 'none',
    TextA: 'none',
};
export const Form = styled.form<IPropsForm>`
    display: ${(props) => props.Display};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    margin: ${(props) => props.Margin};
    padding: ${(props) => props.Padding};
    flex-direction: ${(props) => props.FlexDir};
    grid-auto-rows: ${(props) => props.GridRows};
    grid-template-columns: ${(props) => props.GridColumn};
    width: ${(props) => props.Width && props.Width};
    height: ${(props) => props.Width && props.Height};
`;
Form.defaultProps = {
    Width: '100%',
    Height: 'none',
    Margin: 'none',
};
export const Numbers = styled.div<IPropsNumbers>`
    background-color: ${(props) => props.BGColor && props.BGColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${(props) => props.Color && props.Color};
    width: ${(props) => props.Width && props.Width};
    height: ${(props) => props.Height && props.Height};
    margin: ${(props) => props.Margin && props.Margin};
    margin-right: ${(props) => props.MarginR && props.MarginR};
    border: ${(props) => props.Border && props.Border};
    box-shadow: ${(props) => props.Shadow && props.Shadow};
`;
export const Icons = styled.i<IPropsIcons>`
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: ${(props) => props.FontWeight};
    cursor: ${(props) => props.Cursor};
    display: ${(props) => props.Hiden && props.Hiden};
    margin-right: ${(props) => props.MarginRight && props.MarginRight};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
`;
Icons.defaultProps = {
    FontSize: '24px',
    Color: '#414042',
    Padding: 'none',
    Margin: 'none',
    Cursor: 'pointer',
};
export const Select = styled.select<IPropsSelect>`
    padding: ${(props) => props.Padding};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Br};
    width: ${(props) => props.Width};
    justify-content: ${(props) => props.Justify};
    padding-left: ${(props) => props.PaddingLeft};
    margin: ${(props) => props.Margin};
    margin-right: ${(props) => props.MarginRight};
    margin-bottom: ${(props) => props.MarginBottom};

    outline: none;
`;
Select.defaultProps = {
    Padding: '0.8rem',
    Border: '1px solid #E4E7E9',
    Br: '5px',
    Width: '100%',
    Justify: ' ',
    PaddingLeft: ' ',
    Margin: '0',
    MarginRight: '0',
    MarginBottom: '0',
};
export const Option = styled.option`
    width: 100%;
`;
export const Searching = styled.div`
    display: block;
`;
export const ContainerImagen = styled.div<IPropsContainerImagen>`
    background-image: url(${(props) => props.img && props.img});
    width: 240px;
    height: 240px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    margin-right: ${(props) => props.MarginRight && props.MarginRight};
    border: ${(props) => props.Border && props.Border};
    border-radius: 5px;
`;
export const LayoutDelete = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c5c0c05c;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyleNumericFormat = styled(NumericFormat)<IPropsStyleNumericFormat>`
    text-align: ${(props) => props.textAlign};
    border: ${(props) => props.border};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border: ${(props) => props.border};
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.FontWeight};
    font-family: ${(props) => props.FontFamily};
    color: ${(props) => props.Color};
    outline: ${(props) => props.outline};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    border-radius: ${(props) => props.radius};
    border-color: ${(props) => (props.hasError ? 'red' : '#E8E9EA')};

    &::placeholder {
        color: ${(props) => props.colorplace};
    }

    &:hover {
        border: ${(props) => props.hborder};
        box-shadow: ${(props) => props.hshadow};
    }
    &:focus {
        border: ${(props) => props.borderf};
        box-shadow: ${(props) => props.boxshadowf};
        outline: ${(props) => props.outlinef};
    }
`;

StyleNumericFormat.defaultProps = {
    height: '40px',
    padding: '0 1rem',
    hborder: '1px solid #5A5AFF',
    fontSize: '1rem',
    outline: 'none',
    border: '1px solid #E8E9EA',
    radius: '2rem',
};

export const StyleNumericFormat2 = styled(NumericFormat)`
    padding: 1rem;
    border: none;
`;
export const TextArea = styled.textarea`
    width: 100%;
    border-radius: 5px;
    height: 50px;
    padding: 0.5rem;
`;

export const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-template-rows: auto;
    width: 100%;
`;
export const FormStyled = styled.form`
    width: 100%;
`;
export const Button = styled.button`
    background: none;
    border: none;
`;
export const SelectForm = styled.select<IPropsSelectForm>`
    height: ${(props) => props.Height};
    border-radius: 5px;
    border: 1px solid #e4e7e9;
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    text-align: ${(props) => props.TextA};
    width: ${(props) => props.Width};
    cursor: ${(props) => props.Cursor};
    background-color: ${(props) => props.BackGround};

    &:hover {
        border: 1px solid #35cf44;
        box-shadow: 0px 4px 4px rgba(122, 193, 67, 0.2);
    }

    &:focus-within {
        border: 2px solid #35cf44;
        box-shadow: 0px 4px 4px rgba(122, 193, 67, 0.2);
    }
`;
SelectForm.defaultProps = {
    Height: '24px',
    TextA: 'center',
    Padding: '0 0 0 0.8rem',
    Margin: '0 0 0 0.5rem',
    Width: '90%',
};

export const ChargesFile = styled.label`
    width: 100%;
    height: 100%;
    border: 1px solid dashed;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > input {
        display: none;
    }
`;
export const Image = styled.img`
    width: 140px;
    height: 140px;
    cursor: pointer;
    &:hover {
        opacity: 20%;
    }
`;
export const IconDelete = styled.span`
    border: 1px solid #7ac143b2;
    background-color: #7ac143b2;
    border-radius: 50%;
    position: absolute;
    color: #ffffff;
`;
