import styled from '@emotion/styled';
import {
    IPropsContainerSecondary,
    IPropsDivAudit,
    IPropsSubContainer,
    IPropsTableHead,
    IPropsTableSecond,
} from '@AuditAssignment/interfacesStyles';

export const ContainerPrimary = styled.div`
    width: 80% !important;
    height: 100%;
    margin: auto;
`;

export const ContainerSecondary = styled.div<IPropsContainerSecondary>`
    display: flex;
    padding-top: 1.5rem;
    justify-content: ${(props) => props.jc && 'center'};
`;

export const SubContainer = styled.div<IPropsSubContainer>`
    width: 50%;
    padding-top: 1rem;
    padding-right: ${(props) => props.pr && '1rem'};
    padding-left: ${(props) => props.pl && '1.5rem'};
`;

export const TableHead = styled.thead<IPropsTableHead>`
    margin-top: ${(props) => props.mt && '1.5rem'};
    th {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: var(--black);
        font-weight: 500;
        text-align: center;
        background-color: var(--gray-5);
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        border-bottom: 1px solid white !important;
    }

    th:first-child {
        border-radius: 6px 0 0 6px;
        text-align: ${(props) => props.tl && 'left'};
    }

    th:last-child {
        border-radius: 0 6px 6px 0;
        text-align: ${(props) => props.tr && 'right'};
    }
`;

export const Tabletr = styled.tr`
    cursor: pointer;
    text-align: center;
    &:hover {
        background: var(--gray-3);
    }

    td:first-child {
        text-align: left !important;
    }

    td:last-child {
        text-align: right;
    }
`;

export const TableSecond = styled.tr<IPropsTableSecond>`
    text-align: center;
    cursor: ${(props) => props.cursor && 'pointer'};
    &:hover {
        background: ${(props) => props.hover && 'var(--gray-5)'};
    }
`;

export const FormAudit = styled.form`
    flex-wrap: wrap;
    padding: 0 0 0 0;
`;

export const DivAudit = styled.div<IPropsDivAudit>`
    display: flex;
    flex-direction: ${(props) => props.df && 'column'};
    width: 100%;
    justify-content: ${(props) => props.jc && props.jc};
    padding-right: ${(props) => props.pr && '1rem'};
    padding-left: ${(props) => props.pl && '1rem'};
    padding-top: ${(props) => props.pt && '1rem'};

    p:last-child {
        color: red;
        font-size: 11px;
    }
`;

export const ButtonAudit = styled.button`
    border: 1px solid var(--black);
    text-transform: uppercase;
    border-radius: 5px;
    width: 160px;
    height: 40px;
    align-items: center;
    background-color: white !important;
    box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);

    &:hover {
        border-color: var(--green);
    }
`;
