import React, {useCallback} from 'react';
import {Cell, PieChart, Pie, Label} from 'recharts';

import {GaugeChartProps, ArrowShapeProps} from '@Atoms/GaugeChart/interfaces';
import ArrowShape from '@Atoms/GaugeChart/ArrowShape';

const GaugeChart: React.FC<GaugeChartProps> = ({value, colorData, maxScore, width}) => {
    const totalValue = colorData.reduce((acc, item) => acc + item.value, 0);
    const cumulativeData = colorData.map((item, index) =>
        colorData.slice(0, index + 1).reduce((acc, cur) => acc + cur.value, 0)
    );
    const activeSectorIndex = cumulativeData.findIndex(
        (cumulativeValue) => value <= cumulativeValue
    );
    const memoizedActiveShape = useCallback(
        (props: unknown) => {
            const shapeProps = props as ArrowShapeProps;
            return <ArrowShape {...shapeProps} value={value} maxScore={maxScore} />;
        },
        [value]
    );
    const arrowData = [{value}, {value: 0}, {value: totalValue - value}];

    return (
        <PieChart width={width} height={width / 2 + 5}>
            <Pie
                data={colorData}
                innerRadius="180%"
                outerRadius={(width / 2) * 0.9}
                startAngle={180}
                endAngle={0}
                cx={width / 2 - 5}
                cy={width / 2 - 10}
                blendStroke
                activeIndex={activeSectorIndex}
                dataKey="value"
                isAnimationActive={false}
            >
                {colorData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
            <Pie
                data={arrowData}
                outerRadius={(width / 2) * 0.9}
                stroke="none"
                fill="none"
                valueKey={value}
                activeIndex={1}
                activeShape={memoizedActiveShape}
                cx={width / 2 - 7}
                cy={width / 2 - 10}
                startAngle={180}
                endAngle={0}
                dataKey="value"
            >
                <Label
                    value={value}
                    position="centerBottom"
                    className="gauge-label"
                    fontSize="1.5rem"
                    fontFamily="Nunito"
                    fontWeight="400"
                    color="#2A2C2F"
                    fill="#2A2C2F"
                />
            </Pie>
        </PieChart>
    );
};

export default GaugeChart;
