import React, {useEffect, useState} from 'react';
import {IconContainer} from '@/components/Quoter/styles';
import {Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import point from '@/images/bluePoint.svg';
import {useDispatch, useSelector} from 'react-redux';
import {getFamilyAction} from './Redux/Actions/GetFamilyGroupAction';
import {RootState} from '@/config/store';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {Quantity, removeNumberInParentheses} from '@/hooks/currentFormatUtils';
import {MAXIMUN_LEVEL} from '@components/RequestNewCatalogLevel/operational/constant';
import {
    getSelectedGroupNode,
    getSelectedGroupNodeLevel,
    getSelectedGroupNodeName,
} from '@components/RequestNewCatalogLevel/Redux/Actions/GetSelectGroup';
import {useNavigate} from 'react-router-dom';
import {IDATAFACE, ISentData} from '@components/RequestNewCatalogLevel/interfacesTree';
import {ChangeStatusLevel} from '@components/RequestNewCatalogLevel/Redux/Actions/PostChangeStatusLevel';
import {
    BackGroundSelector,
    DinamicBorder,
    DinamicPadding,
} from '@components/RequestNewCatalogLevel/constanst';

export const TreeComponent = (data: ISentData) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: IDATAFACE, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            level={data.level + 1}
                            isView={data.isView}
                            fatherId={data.fatherId}
                            key={index}
                        />
                    );
                })}
        </>
    );
};

const TreeNode = (node: {node: IDATAFACE; level: number; isView: boolean; fatherId: number}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nextChild, setNextChild] = useState(false);
    const [status, setState] = useState(node.node.status);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );
    const sendName =
        removeNumberInParentheses(String(node.node.nameGroup ? node.node.nameGroup : '')) ||
        removeNumberInParentheses(String(node.node.nameFamily ? node.node.nameFamily : '')) ||
        removeNumberInParentheses(String(node.node.nameSubFamily ? node.node.nameSubFamily : '')) ||
        removeNumberInParentheses(String(node.node.nameBrand ? node.node.nameBrand : '')) ||
        node.node.name;
    const ChangeStatus = () => {
        if (token && companyId) {
            const ChangeStatus = {
                levelPhaterId: node.fatherId,
                status: !node.node.status,
                companyId: companyId,
                levelId: node.node.id,
            };
            dispatch(ChangeStatusLevel(token, ChangeStatus, setState));
        }
    };

    const IdSelected = useSelector((state: RootState) => state.AddNewLevel);

    const selectNode = () => {
        dispatch(idSelected(node.node.id));
        dispatch(getSelectedGroupNode(node.node.children));
        dispatch(getSelectedGroupNodeName(sendName));
        dispatch(getSelectedGroupNodeLevel(node.level));
        navigate('/RequestNewCatalogLevel');
    };
    useEffect(() => {
        if (
            node.level === 1 &&
            IdSelected.id === node.node.id &&
            !node.node.children &&
            token &&
            !node.isView
        ) {
            dispatch(getFamilyAction(node.node.id, token));
        }
    }, [IdSelected.id, dispatch]);
    useEffect(() => {
        const levelMap = new Map<number, keyof typeof IdSelected.breadCrumb>([
            [1, 'groupId'],
            [2, 'familyId'],
            [3, 'subFamilyId'],
            [4, 'brandId'],
        ]);
        const breadCrumbKey = levelMap.get(node.level);

        if (breadCrumbKey && IdSelected.breadCrumb[breadCrumbKey] === node.node.id) {
            dispatch(idSelected(node.node.id));
            setNextChild(true);
        }
    }, [IdSelected.breadCrumb, dispatch]);
    useEffect(() => {
        setState(node.node.status);
    }, []);
    return (
        <>
            <TableRowItems
                GridColumn="3fr 1fr 1fr"
                Cursor="auto"
                Height="40px"
                Width="100%"
                BackGH=""
                Border=""
                BorderB={DinamicBorder(node.level)}
                BorderRadius=""
                BackGround={BackGroundSelector(node.level)}
            >
                <TableItem
                    Width="16rem"
                    Justify="start"
                    PaddingLeft={DinamicPadding(node.level)}
                    Gap="16px"
                    Filter={node.node.status ? '' : '0.5'}
                >
                    {!node.node.name && node.level !== MAXIMUN_LEVEL && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1.5rem"
                            Color={nextChild ? '#5A5AFF' : '#54575C'}
                            Justify="start"
                            onClick={() => {
                                dispatch(idSelected(node.node.id));
                                setNextChild(!nextChild);
                            }}
                        >
                            {nextChild ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}
                    {(node.node.name || node.level === MAXIMUN_LEVEL) && (
                        <Image alt="point" src={point} Opacity={node.node.status ? '' : '0.5'} />
                    )}

                    <TextEllipsis
                        FontWeight="500"
                        FontSize="0.875rem"
                        Color="#5A5AFF"
                        title={node.node.nameGroup}
                        onClick={() => {
                            if (!node.isView) {
                                selectNode();
                            }
                        }}
                    >
                        {sendName}
                    </TextEllipsis>
                </TableItem>
                <TableItem
                    Padding={node.level ? '0 0 0 16px' : '0 0 0 16px'}
                    Filter="grayscale(100%)"
                >
                    <Text FontWeight="400" FontSize="0.875rem" Color="#414042">
                        {Quantity(String(node.node.name).replaceAll('(inactivo)', '')) ||
                            Quantity(String(node.node.nameGroup).replaceAll('(inactivo)', '')) ||
                            Quantity(String(node.node.nameFamily).replaceAll('(inactivo)', '')) ||
                            Quantity(
                                String(node.node.nameSubFamily).replaceAll('(inactivo)', '')
                            ) ||
                            Quantity(String(node.node.nameBrand).replaceAll('(inactivo)', ''))}
                    </Text>
                </TableItem>
                <TableItem Justify="end" Padding="0 8px">
                    <GreenSwitch checked={status} size="small" onChange={ChangeStatus} />
                </TableItem>
            </TableRowItems>
            {nextChild && node.node.children && (
                <TreeComponent
                    data={node.node.children}
                    level={node.level}
                    isView={node.isView}
                    fatherId={node.node.id}
                />
            )}
        </>
    );
};
