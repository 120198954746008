import React from 'react';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RECENT_ACTIVITY} from '@/components/Home/Admin/constants';
export const RecentActivity = () => {
    return (
        <ContainerFlex
            Padding="16px"
            Width="100%"
            Height="87px"
            MinH="87px"
            backG="#FAFAFA"
            Radius="16px"
            FlexDir="column"
            Justify="space-between"
            Align="start"
        >
            <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500">
                {RECENT_ACTIVITY.Title}
            </Text>
            <Text FontSize="1rem" Color="#54575C" Width="100%" Justify="center">
                {RECENT_ACTIVITY.Text}
            </Text>
        </ContainerFlex>
    );
};
