import {IDynamicFeatures} from '@components/Catalogue/interfaces';
import {
    SAVE_NEW_DYNAMIC_FEATURE,
    UPDATE_DYNAMIC_FEATURE,
    RESET_DYNAMIC_FEATURES,
} from '@components/Catalogue/Redux/Types/Types';

const initialState: IDynamicFeatures[] = [];

const saveNewDynamicFeature = (
    state = initialState,
    action: {type: string; payload: IDynamicFeatures}
) => {
    switch (action.type) {
        case SAVE_NEW_DYNAMIC_FEATURE:
            return state.find(
                (feature) => feature.idTypeCharacteristics === action.payload.idTypeCharacteristics
            )
                ? state
                : [...state, action.payload];
        case UPDATE_DYNAMIC_FEATURE:
            return state.map((feature) =>
                feature.idTypeCharacteristics === action.payload.idTypeCharacteristics
                    ? {...action.payload}
                    : feature
            );
        case RESET_DYNAMIC_FEATURES:
            return initialState;
        default:
            return state;
    }
};

export default saveNewDynamicFeature;
