import {LAST_LEVEL_BY_COMPANY} from '@components/LocationsBranch/Redux/types';
import {ILevels, ILevelsRedux} from '@components/LocationsBranch/interfaces';

const initialState: ILevels = {
    error: false,
    loading: false,
    level: {breadcrumbId: '', breadcrumbName: '', levelId: 0},
};

export const getLevels = (state = initialState, action: ILevelsRedux): ILevels => {
    switch (action.type) {
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_SUCCESS:
            return {
                ...state,
                loading: false,
                level: action.level,
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_RESET:
            return initialState;
        default:
            return state;
    }
};
