import {
    GET_PAWN,
    GET_PAWN_SUCCESS,
    GET_PAWN_ERROR,
    GET_SUCURSAL,
    GET_SUCURSAL_SUCCESS,
    GET_SUCURSAL_ERROR,
    POST_PAWN,
    POST_PAWN_SUCCESS,
    POST_PAWN_ERROR,
    PAWN_ID,
    DATA_SEARCH,
} from '@TypesPawn/PawnTypes';

import Swal from 'sweetalert2';
import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';

import axios from 'axios';

export const fetchGetSucursal = () => {
    return {
        type: GET_SUCURSAL,
    };
};
export const fetchGetSucursalSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SUCURSAL_SUCCESS,
        value: result.data,
    };
};
export const fetchGetSucursalError = () => {
    return {
        type: GET_SUCURSAL_ERROR,
    };
};

export function getSucursal(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetSucursal());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Sucursal}`,
                {headers}
            );

            dispatch(fetchGetSucursalSuccess(response));
        } catch (error) {
            dispatch(fetchGetSucursalError());
        }
    };
}

export const fetchGetPawn = () => {
    return {
        type: GET_PAWN,
    };
};
export const fetchGetPawnSuccess = (result: {data: unknown}) => {
    return {
        type: GET_PAWN_SUCCESS,
        value: result.data,
    };
};
export const fetchGetPawnError = () => {
    return {
        type: GET_PAWN_ERROR,
    };
};

export function getPawn(id: string | number, contract: string | number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetPawn());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_PAWN}${URL.urlPawn.Reactivations.replace(
                    '{sucursalId}',
                    id.toString()
                ).replace('{contract}', contract.toString())}`,
                {headers}
            );
            if (typeof response.data === 'string') {
                Swal.fire({
                    icon: 'error',
                    title: 'Buscar contrato',
                    position: 'center',
                    text: response.data,
                });
                dispatch(fetchGetPawnError());
            } else {
                dispatch(fetchGetPawnSuccess(response));
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Buscar contrato',
                position: 'center',
            });
            dispatch(fetchGetPawnError());
        }
    };
}

export const fetchPostPawn = () => {
    return {
        type: POST_PAWN,
    };
};
export const fetchPostPawnSuccess = (result: {data: unknown}) => {
    return {
        type: POST_PAWN_SUCCESS,
        value: result.data,
    };
};
export const fetchPostPawnError = () => {
    return {
        type: POST_PAWN_ERROR,
    };
};

export function postPawn(
    token: string,
    data: {idSucursal: string; idEmpeño: string; fecha: string}
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchPostPawn());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_PAWN}${URL.urlPawn.ReactivationsPut}`,
                data,
                {headers}
            );
            dispatch(fetchPostPawnSuccess(response));
            Swal.fire({
                icon: 'success',
                title: 'Condonar',
                text: 'Condonación exitosa',
                position: 'center',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Condonar',
                position: 'center',
            });
            dispatch(fetchPostPawnError());
        }
    };
}
export const fetchGetDataSearch = (id: number, count: number) => {
    return {
        type: DATA_SEARCH,
        value: id,
        count: count,
    };
};

export const fetchGetPawnid = (id: number) => {
    return {
        type: PAWN_ID,
        value: id,
    };
};
