import styled from '@emotion/styled';

export const FondoImg = styled.img`
    margin-top: 40px;
`;
export const FormCloseBox = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
