import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import estadoAprovado from '@/images/estadosAprovado.png';
import {CheckImage} from '@/components/QuickPayment/SuccessPaymentSummary/ResumenPagoExitosoStyles';
import {INFO_MESSAGE} from '@/components/QuickPayment/Constants';

const SuccessInfoMessage = () => {
    return (
        <ContainerFlex Radius="24px" backG="#D1FFD1" Padding="16px" Justify="flex-start" Gap="16px">
            <CheckImage src={estadoAprovado} alt="Imagen de aprobado" />
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="8px">
                <Text FontFamily="Nunito" FontSize="1.25rem" FontWeight="700" Color="#0D166B">
                    {INFO_MESSAGE.TITLE}
                </Text>
                <Text FontFamily="Nunito" FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                    {INFO_MESSAGE.SUB_TITLE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SuccessInfoMessage;
