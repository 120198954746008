import {ITree, ITreeRedux} from '@/components/Articles/interface';
import {
    GET_TREEBRANCHS_SUCCESS,
    GET_TREEBRANCHS_ERROR,
    GET_TREEBRANCHS,
} from '@TypesOrgTreeView/index';

const initialState: ITree = {
    OrganizationTree: [],
    Branchs: [],
    loading: true,
    error: true,
};

const OrganizationsTree = (state = initialState, action: ITreeRedux): ITree => {
    switch (action.type) {
        case GET_TREEBRANCHS:
            return {
                ...state,
                Branchs: action.Branchs,
            };
        case GET_TREEBRANCHS_SUCCESS:
            return {
                ...state,
                OrganizationTree: action.OrganizationTree,
                loading: false,
            };
        case GET_TREEBRANCHS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default OrganizationsTree;
