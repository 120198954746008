export const EXPENSE_DETAILS_NEW = {
    NewExpens: 'Nuevo gasto',
    total: 'Total: $25700',
};

export const UPLOAD_FILE = {
    mb: 'MB',
    replace: 'Remplazar',
    drag: 'Arrastre elementos aquí o',
    search: 'Busca archivos',
};
