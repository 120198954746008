export const GET_PURITY = 'GET_PURITY';
export const GET_PURITY_SUCCESS = 'GET_PURITY_SUCCESS';
export const GET_PURITY_ERROR = 'GET_PURITY_ERROR';
export const GET_PURITY_FILTERS = 'GET_PURITY_FILTERS';

export const GET_PURITY_LAB = 'GET_PURITY_LAB';
export const GET_PURITY_LAB_SUCCESS = 'GET_PURITY_LAB_SUCCESS';
export const GET_PURITY_LAB_ERROR = 'GET_PURITY_LAB_ERROR';

export const GET_PURITY_FILE = 'GET_PURITY_FILE';
export const GET_PURITY_FILE_SUCCESS = 'GET_PURITY_FILE_SUCCESS';
export const GET_PURITY_FILE_ERROR = 'GET_PURITY_FILE_ERROR';
export const GET_PURITY_INGOT = 'GET_PURITY_INGOT';

export const POST_PURITY_FILE = 'POST_PURITY_FILE';
export const POST_PURITY_FILE_SUCCESS = 'POST_PURITY_FILE_SUCCESS';
export const POST_PURITY_FILE_ERROR = 'POST_PURITY_FILE_ERROR';

export const POST_PURITY_PAY = 'POST_PURITY_PAY';
export const POST_PURITY_PAY_SUCCESS = 'POST_PURITY_PAY_SUCCESS';
export const POST_PURITY_PAY_ERROR = 'POST_PURITY_PAY_ERROR';
