import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {SubMenuItemProps} from '@components/SideBarMenu/interfaces';
import ArrowArbolRight from '@images/SideBarMenuAdmin/ArrowArbolRight.svg';
import {IconContainer} from '@/components/Quoter/styles';
import {MENUADMINSETTINGS} from '@components/SideBarMenu/constantsText';

const SideBarMenuSubItem: React.FC<SubMenuItemProps> = ({
    subItem,
    index,
    subIndex,
    clickedSubItem,
    handleSubItemClick,
    expandedSubItems,
    settingsOpen,
    handleClickClose,
}) => {
    const navigate = useNavigate();
    return (
        <ContainerFlex key={index} FlexDir="column">
            {!settingsOpen ? (
                <ContainerFlex
                    Justify="space-between"
                    Height={settingsOpen ? '0rem' : '2.5rem'}
                    backG={clickedSubItem === `${index}-${subIndex}` ? '#F0F0FF' : '#FAFAFF'}
                    Bl={
                        clickedSubItem === `${index}-${subIndex}` ? ' 1.5px solid  #5A5AFF' : 'none'
                    }
                    HBorderLeft="1.5px solid  #5A5AFF"
                    HColor="#5A5AFF"
                    HBackground="#F0F0FF"
                    Cursor="pointer"
                    Padding=".5rem"
                    onClick={() => handleSubItemClick(index, subIndex)}
                >
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        {!settingsOpen && (
                            <>
                                {(subItem.cssIconHover || subItem.cssIcon) && (
                                    <Image
                                        alt="item-icon"
                                        src={
                                            clickedSubItem === `${index}-${subIndex}`
                                                ? subItem.cssIconHover
                                                : subItem.cssIcon
                                        }
                                    />
                                )}

                                <Text
                                    Cursor="pointer"
                                    Color={
                                        clickedSubItem === `${index}-${subIndex}`
                                            ? '#5A5AFF'
                                            : '#54575C'
                                    }
                                >
                                    {subItem.title}
                                </Text>
                            </>
                        )}
                    </ContainerFlex>

                    {subItem.item.length > 0 && (
                        <IconContainer
                            Transform={
                                expandedSubItems.includes(`${index}-${subIndex}`)
                                    ? 'rotate(-90deg)'
                                    : 'rotate(0deg)'
                            }
                            color="red"
                        >
                            <Image src={ArrowArbolRight} alt="arrow-arbol-right" />
                        </IconContainer>
                    )}
                </ContainerFlex>
            ) : (
                subItem.cssIcon && (
                    <Image
                        Cursor="pointer"
                        onClick={() => handleClickClose()}
                        alt="item-icon"
                        src={(() => {
                            if (
                                subItem.title !== MENUADMINSETTINGS.PROMOTIONS &&
                                subItem.title !== MENUADMINSETTINGS.EXPLOREALL
                            ) {
                                return subItem.cssIcon;
                            }
                        })()}
                    />
                )
            )}

            {expandedSubItems.includes(`${index}-${subIndex}`) && subItem.item.length > 0 && (
                <ContainerFlex FlexDir="column" Padding="0.5rem">
                    {subItem.item.map((subSubItem, subSubIndex) => (
                        <ContainerFlex
                            key={subSubIndex}
                            Justify="start"
                            Gap="0.5rem"
                            Height="2.5rem"
                        >
                            {subSubItem.cssIcon ? (
                                <Image src={subSubItem.cssIcon} alt="item-icon" />
                            ) : (
                                <Text Width="20px" Height="20px" />
                            )}
                            <Text Cursor="pointer" onClick={() => navigate(`${subSubItem.route}`)}>
                                {subSubItem.title}
                            </Text>
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
export default SideBarMenuSubItem;
