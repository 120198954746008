import styled from '@emotion/styled';
import {IPropsImageLogo} from '@/components/TransactionsDetails/Operational/interface';

export const ImageLogo = styled.img<IPropsImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: ${(props) => props.Margin};
`;

export const IconImg = styled.img`
    width: 38px;
    height: 38px;
    border-radius: 32px;
`;

export const IconPhone = styled.img`
    width: 42px;
    height: 42px;
    border-radius: 8px;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e8e9ea;
`;
