import {
    NEW_PASSWORD_USER_REQUEST,
    NEW_PASSWORD_USER_SOUCCES,
    NEW_PASSWORD_USER_ERROR,
} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    password: null,
    error: null,
};

export const NewpasswordUser = (state = initialState, action) => {
    switch (action.type) {
        case NEW_PASSWORD_USER_REQUEST:
            return {...state, loading: true};
        case NEW_PASSWORD_USER_SOUCCES:
            return {...state, loading: false, userData: action.payload.data};
        case NEW_PASSWORD_USER_ERROR:
            return {...state, loading: false, error: action.payload.data.data};
        default:
            return state;
    }
};
