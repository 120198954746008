import {IReducerType} from '@/components/Branches/interfaces';
import {
    GET_CASHFLOW_SETUP,
    GET_CASHFLOW_SETUP_ERROR,
    GET_CASHFLOW_SETUP_FINISH,
    GET_CASHFLOW_SETUP_RESET,
    GET_CASHFLOW_SETUP_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    cashSetup: {},
};

const GetCashFlowSetup = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_CASHFLOW_SETUP:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CASHFLOW_SETUP_SUCCESS:
            return {
                ...state,
                cashSetup: action.payload.data.data,
            };
        case GET_CASHFLOW_SETUP_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_CASHFLOW_SETUP_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_CASHFLOW_SETUP_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetCashFlowSetup;
