export const SAVE_ACCOUNT_DATA = 'SAVE_ACCOUNT_DATA';
export const SAVE_COMPANY_DATA = 'SAVE_COMPANY_DATA';
export const SAVE_TYPE_SALE_DATA = 'SAVE_TYPE_SALE_DATA';
export const SAVE_PLAN_DATA = 'SAVE_PLAN_DATA';

export const POST_FORM_DATA = 'POST_FORM_DATA';
export const POST_FORM_DATA_SUCCESS = 'POST_FORM_DATA_SUCCESS';
export const POST_FORM_DATA_ERROR = 'POST_FORM_DATA_ERROR';
export const BACK_TO_STEP = 'BACK_TO_STEP';

export const VALIDATE_USER_ADMINISTRATOR = 'VALIDATE_USER_ADMINISTRATOR';
export const VALIDATE_USER_ADMINISTRATOR_SUCCESS = 'VALIDATE_USER_ADMINISTRATOR_SUCCESS';
export const VALIDATE_USER_ADMINISTRATOR_ERROR = 'VALIDATE_USER_ADMINISTRATOR_ERROR';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';

export const CAT_PLACES = 'CAT_PLACES';
export const CAT_PLACES_SUCCESS = 'CAT_PLACES_SUCCESS';
export const CAT_PLACES_ERROR = 'CAT_PLACES_ERROR';

export const CAT_BUSINESS = 'CAT_BUSINESS';
export const CAT_BUSINESS_SUCCESS = 'CAT_BUSINESS_SUCCESS';
export const CAT_BUSINESS_ERROR = 'CAT_BUSINESS_ERROR';

export const RESET_ACCOUNT_DATA = 'RESET_ACCOUNT_DATA';

export const CAT_COMPANY_BENEFIT = 'CAT_COMPANY_BENEFIT';
export const CAT_COMPANY_BENEFIT_SUCCESS = 'CAT_COMPANY_BENEFIT_SUCCESS';
export const CAT_COMPANY_BENEFIT_ERROR = 'CAT_COMPANY_BENEFIT_ERROR';
