import {FETCH_UPDATE_ENDORSEMENTS_CART} from '@components/ShoppingCart/Redux/types';
import {IEndorsementsData, IEndorsementsdRedux} from '@components/ShoppingCart/Redux/interfaces';
const initialState: IEndorsementsData = {
    loading: false,
    error: false,
    errorMessage: '',
};

const endorsementsReducer = (
    state = initialState,
    action: IEndorsementsdRedux
): IEndorsementsData => {
    switch (action.type) {
        case FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_START:
            return {
                ...state,
                error: false,
                errorMessage: '',
                loading: true,
            };
        case FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: String(action.error.response?.data),
            };
        default:
            return state;
    }
};
export default endorsementsReducer;
