import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {
    TableRowHeadContract,
    TableItems,
    Table,
    TableItem,
    TableRowItems,
} from '@Shopify/Endeavors/styles';
import {fetchGetObjectBranch, getLocationRack} from '@ActionsLocation/LocationAction';
import {RootState, AppDispatch} from '@config/store';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {ImgLocation} from '@CreateLocation/style';
import editIcons from '@images/edit-black-18dp.svg';
import {locationsGeneral, location} from '@Locations/AdminLocations/redux/Interfaces';
import deleteIconBlack from '@images/delete_forever-black.svg';
import {IconContainer} from '@/components/Quoter/styles';

export default function TableRack(props: locationsGeneral) {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const {branch, SelectCheckValidation, setShow} = props;
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState({actualPage: 1, totalItems: 1});

    const paginate = (button: string) => {
        switch (button) {
            case 'back':
                if (page.actualPage > 1)
                    setPage({
                        actualPage: page.actualPage - 1,
                        totalItems: page.totalItems - locations.gRack.pageSize,
                    });
                break;
            case 'next':
                if (page.actualPage < locations.gRack.totalPages)
                    setPage({
                        actualPage: page.actualPage + 1,
                        totalItems: page.totalItems + locations.gRack.pageSize,
                    });
                break;
        }
    };

    useEffect(() => {
        setPage({actualPage: 1, totalItems: 1});
    }, [branch.idBranch]);
    useEffect(() => {
        if (branch.idBranch !== undefined)
            dispatch(getLocationRack(branch.idBranch, 3, page.actualPage, token));
    }, [page.actualPage, branch.idBranch]);

    return (
        <ContainerFlex FlexDir="column" Width="47%" Height="auto" Justify="start" Align="start">
            {locations.loading ? (
                <LoadingGeneral />
            ) : (
                locations.gRack !== null &&
                locations.gRack.data?.length > 0 && (
                    <>
                        <ContainerFlex Justify="end" Padding="0 0 0.8rem 0">
                            <IconContainer
                                Color={page.actualPage > 1 ? '' : '#e5e7e9'}
                                onClick={() => paginate('back')}
                                FontSize="1.25rem"
                                className="material-icons"
                            >
                                keyboard_arrow_left
                            </IconContainer>
                            <Text FontSize="0.75rem">
                                {page.actualPage +
                                    ' - ' +
                                    page.totalItems +
                                    ' de ' +
                                    locations.gRack.totalPages}
                            </Text>
                            <IconContainer
                                Color={
                                    page.actualPage < locations.gRack.totalPages ? '' : '#e5e7e9'
                                }
                                onClick={() => paginate('next')}
                                FontSize="1.25rem"
                                className="material-icons"
                            >
                                keyboard_arrow_right
                            </IconContainer>
                        </ContainerFlex>
                        <Table>
                            <TableRowHeadContract
                                Background="#f3f3f3"
                                GridTemplate="1.5fr 1fr 1.2fr"
                                Padding="0 1rem"
                            >
                                <TableItem Weight="500" Justify="start">
                                    Nombre de rack
                                </TableItem>
                                <TableItem Weight="500"># Fila</TableItem>
                                <TableItem Weight="500">Acciones </TableItem>
                            </TableRowHeadContract>

                            {locations.gRack.data.map((item, index: number) => (
                                <TableRowItems
                                    key={index}
                                    Height="43px"
                                    GridColumn="1.5fr 1fr 1.2fr"
                                    BackGColor=""
                                    Padding="0 1rem"
                                    Width="auto"
                                >
                                    <TableItems Justify="start" Weigth="400" Size="0.875rem">
                                        {item.rackName}
                                    </TableItems>
                                    <TableItems Justify="center" Weigth="400" Size="0.875rem">
                                        {item.rows}
                                    </TableItems>
                                    <TableItems Justify="center" Weigth="400" Size="0.875rem">
                                        <ImgLocation
                                            Width="20px"
                                            Height="20px"
                                            onClick={() => {
                                                dispatch(
                                                    fetchGetObjectBranch({...item, type: 'edit'})
                                                );
                                                navigate('/Locations/CreateLocation/Rack');
                                            }}
                                            src={editIcons}
                                        />
                                        <ImgLocation
                                            onClick={() => {
                                                SelectCheckValidation(item.id);
                                                dispatch(fetchGetObjectBranch(item));
                                                setShow(true);
                                            }}
                                            Width="20px"
                                            Height="20px"
                                            HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                            src={deleteIconBlack}
                                        />
                                    </TableItems>
                                </TableRowItems>
                            ))}
                        </Table>
                    </>
                )
            )}
        </ContainerFlex>
    );
}
