import React, {useEffect, useState} from 'react';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {AppDispatch} from '@/config/store';
import {useDispatch} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';

import {DeadlineGeneral} from '@CreateDeadline/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TableDiscounts} from '@CreateDeadline/DeadlineSteps/Discounts/tableDiscounts';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {discountsTable, setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const Discounts = (props: DeadlineGeneral) => {
    const dispatch: AppDispatch = useDispatch();
    const {deadlineById, deadlinesGeneral} = props;
    const [interest, setInterest] = useState(true);

    const handleContinue = () => {
        dispatch(setStep(6));
    };

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            deadlinesGeneral.promotionDiscount.length === 0
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            setInterest(vDeadlines.haveDiscount);
            vDeadlines.haveDiscount && dispatch(discountsTable(vDeadlines.promotionDiscount));
        }

        if (
            deadlinesGeneral.promotionDiscount.length > 0 &&
            deadlinesGeneral.haveDiscount &&
            (!deadlineById.edit || !deadlineById.copy)
        ) {
            setInterest(deadlinesGeneral.haveDiscount);
            dispatch(discountsTable(deadlinesGeneral.promotionDiscount));
        }
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            onClick={() => {
                if (
                    !interest ||
                    deadlinesGeneral.gracePhase.length > 0 ||
                    (deadlinesGeneral.promotionDiscount.length > 0 && interest)
                )
                    dispatch(setStep(5));
            }}
        >
            <TitleDeadline
                title={'Descuentos'}
                step={deadlinesGeneral.step}
                stepCurrent={5}
                stepDependence={0}
                dependence={
                    !interest || (deadlinesGeneral.promotionDiscount.length > 0 && interest)
                }
            />

            {deadlinesGeneral.step === 5 && (
                <ContainerFlex Padding="1.5rem 12.813rem" FlexDir="column">
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text Color="#6d6e7" FontSize="0.875rem">
                            ¿Trendrá descuentos?
                        </Text>
                        <Text FontSize="0.875rem">
                            No
                            <FormControlLabel
                                className="control-label"
                                control={
                                    <GreenSwitch
                                        disabled={deadlineById?.edit}
                                        checked={interest}
                                        onChange={() => setInterest(!interest)}
                                        size="small"
                                    />
                                }
                                label=""
                            />
                            Sí
                        </Text>
                    </ContainerFlex>
                    {interest && (
                        <TableDiscounts
                            interest={interest}
                            deadlineById={deadlineById}
                            deadlinesGeneral={deadlinesGeneral}
                        />
                    )}
                    <form onSubmit={handleContinue}>
                        {!deadlineById?.edit && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                hColor="#35cf44"
                                disabled={
                                    deadlinesGeneral.promotionDiscount.length === 0 && interest
                                }
                                text="Continuar"
                            />
                        )}
                    </form>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
