import {createTheme} from '@mui/material';

export const select = 'Seleccionar';

export const HeaderUserRow = [
    {name: 'Nombre usuario', id: 0, isOrder: true},
    {name: 'Rol', id: 1, isOrder: true},
    {name: 'Estado', id: 2, isOrder: true},
];

export const TableHeaderBranchRow = [
    {name: 'Nombre sucursal', id: 0, isOrder: true},
    {name: 'Nivel organizacional', id: 1, isOrder: true},
    {name: 'Estado', id: 2, isOrder: true},
];

export const theme = createTheme({
    palette: {
        primary: {
            main: '#35cf44',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Work Sans',
    },
    components: {
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    columnGap: '16px',
                    padding: '20px',
                    '& > :first-of-type': {
                        color: '#414042',
                        boxShadow: '0 0 8px 0 rgba(167, 169, 172, 0.25)',
                        textTransform: 'capitalize',
                        ':hover': {
                            background: '#FFFFF',
                            boxShadow: '0px 2px 4px rgba(53, 207, 68, 0.5)',
                            color: '#35cf44',
                        },
                    },
                    '& > :last-child': {
                        color: '#414042',
                        border: '1px solid #4A4A4A',
                        textTransform: 'capitalize',
                        ':hover': {
                            background: '#FFFFF',
                            border: '1px solid #35cf44',
                            boxShadow: '0px 2px 4px rgba(53, 207, 68, 0.5)',
                            color: '#35cf44',
                        },
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: 'rgba(53, 207, 68) !important',
                    },
                },
            },
        },
    },
});

export const themeSlider = createTheme({
    palette: {
        primary: {
            main: '#35cf44',
        },
    },
    components: {
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    height: '16px',
                    width: '16px',
                },
                track: {
                    height: '2px',
                },
            },
        },
    },
});
export const labelThemeSx = {
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 26,
        height: 26,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#35cf44',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
};
