import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RESET_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {SearchFilterAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/FilterOfUser';
import {BranchAssignmentTree} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignmentTree';

export const UserBranchAssignment = () => {
    return (
        <ContainerFlex FlexDir="column" Padding="24px" Height="max-content" Gap="16px">
            <ContainerFlex Justify="start">
                <Text FontSize="1.5rem" FontWeight="700">
                    {BRANCH_ASSIGNMENT.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
            >
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                        {BRANCH_ASSIGNMENT.TITLE_TWO}
                    </Text>
                    <Text FontWeight="400" Color="#2A2C2F">
                        {BRANCH_ASSIGNMENT.INDICATION}
                    </Text>
                </ContainerFlex>
                <SearchFilterAssignment />
                <ContainerFlex Justify="start">
                    <BranchAssignmentTree />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
            >
                <ButtonGenerals Width="147px" SecondaryButton="#FFFFFF">
                    {RESET_PASSWORD.BTN}
                </ButtonGenerals>
                <ButtonGenerals Width="147px">{RESET_PASSWORD.BTN_KEEP}</ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};
