import React, {useState, useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {
    IClientProofStep,
    MarketingClient,
    SelectOption,
} from '@components/Shopify/ListClient/interfaces';
import {getMarketingClientList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input, LinkAtaskate} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@Atoms/Checkbox/Checkbox';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const Marketing = (props: IClientProofStep) => {
    const {token, register, errors, control, setValue, watch} = props;
    const dispatch: AppDispatch = useDispatch();
    const [other, setOther] = useState(false);

    const handleOnChange = (option: {value: number; label: string}) => {
        if (option.value === 7) {
            setOther(true);
        } else {
            setOther(false);
            setValue('other', '');
        }
    };

    const marketingClient: MarketingClient[] | null = useSelector(
        (state: RootState) => state.newClientProcess.marketingClient
    );
    let formattedMarketingClient: SelectOption[] = [];
    if (marketingClient) {
        formattedMarketingClient = marketingClient.map((marketingClient: MarketingClient) => ({
            value: marketingClient.id,
            label: marketingClient.descriptionMarketing,
        }));
    }
    const ValueOther = watch('other');
    const validate = () => {
        let flag = false;
        if (watch('catCustomerMarketingId.value') === 7 && ValueOther.length === 0) {
            flag = true;
        }
        return flag;
    };
    const Error = validate();
    useEffect(() => {
        if (token) {
            dispatch(getMarketingClientList(token));
        }
    }, [dispatch, token]);
    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            backG="#fff"
            Width="1233px"
            Margin="0 auto"
        >
            <Text FontWeight="500" FontSize="1.5rem" Color="#2A2C2F">
                Mercadotecnia
            </Text>

            <ContainerFlex Justify="space-between" Align="flex-start" MarginTop="0.5rem" Gap="1rem">
                <ContainerFlex {...style.selectContent}>
                    <Text {...style.level} Color="#71767D">
                        {CREATE_CLIENT_LABELS.COMO_SE_ENTERO}
                    </Text>
                    <Controller
                        name="catCustomerMarketingId"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                isSearchable={false}
                                hasError={!!errors.catCustomerMarketingId?.message}
                                options={formattedMarketingClient}
                                onChange={(selectedOption) => {
                                    setValue('catCustomerMarketingId', selectedOption);
                                    if (onChange) onChange(selectedOption);
                                    handleOnChange(selectedOption);
                                }}
                            />
                        )}
                    />
                    <Text Color="#FF6357" FontSize="0.875rem">
                        {errors.catCustomerMarketingId?.message as string}
                    </Text>
                </ContainerFlex>

                <ContainerFlex {...style.selectContent}>
                    <Text {...style.level} Color="#A1A5AA">
                        {CREATE_CLIENT_LABELS.OTRO}
                    </Text>
                    <Controller
                        name="other"
                        control={control}
                        render={() => (
                            <Input
                                {...register('other')}
                                autoFocus
                                disabled={!other}
                                error={Error}
                                disabledBackG="#E8E9EA"
                                Cursor="default"
                                placeholder="Escribe"
                            />
                        )}
                    />
                    <Text Color="#FF6357" FontSize="0.875rem">
                        {Error && 'Este campo es obligatorio'}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <Text FontSize="0.75rem" MarginTop="0.25rem" Color="#71767D">
                Información adicional
            </Text>
            <Text FontSize="1rem" Color="#2A2C2F" MarginTop="0.5rem">
                ¿Acepta usar los datos personales para mercadeo?
            </Text>
            <ContainerFlex Justify="flex-start" MarginTop="1rem">
                <ContainerFlex Width="auto">
                    <Checkbox {...register('acceptMarketing')} />
                    <Text Cursor="pointer" FontSize="1rem" Margin="0 0.5rem">
                        Acepto. Consulta
                    </Text>
                    <LinkAtaskate
                        Color="#5A5AFF"
                        FontWeight="400"
                        Cursor="pointer"
                        to={''}
                        TextDecoration="underline"
                    >
                        aviso de privacidad
                    </LinkAtaskate>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Marketing;
