import {BENEFIST, BENEFIST_SUCCESS, BENEFIST_ERROR} from '@TypesQouter/TypesGeneral';
import {IAllBenefist, IAllBenefistRedux} from '@Quoter/Redux/intefaces';

const initialState: IAllBenefist = {
    error: false,
    loading: false,
    Benefist: [],
};

const getAllBenefist = (state = initialState, actions: IAllBenefistRedux): IAllBenefist => {
    switch (actions.type) {
        case BENEFIST:
            return {
                ...state,
                loading: true,
            };
        case BENEFIST_SUCCESS:
            return {
                ...state,
                loading: false,
                Benefist: actions.Benefist,
            };
        case BENEFIST_ERROR:
            return {
                ...state,
                error: false,
                loading: true,
            };
        default:
            return state;
    }
};

export default getAllBenefist;
