import React, {useEffect} from 'react';
import {ErrorMessage} from '@hookform/error-message';
import {DateTime} from 'luxon';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, AppDispatch} from '@config/store';
import {
    getAssignmentValidation,
    putAssignmentProcess,
} from '@ActionsSucursal/AssignmentValidationActions';
import '@Sucursal/styles.css';

export function SucursalVerificationData() {
    const {
        handleSubmit,
        register,
        reset,

        formState: {errors},
    } = useForm({
        defaultValues: {
            sucursal: '',
            costo: '',
            fecha: '',
            region: '',
            fundidora: '',
            prendas: '',
            asignado: '',
        },
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const history = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token) {
            const getAssignmentValidations = () => {
                dispatch(getAssignmentValidation(token));
            };
            getAssignmentValidations();
        }
    }, []);
    const assignmentvalidation = useSelector((state: RootState) => {
        return state.assignmentvalidation.assignmentvalidation;
    });

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if (assignmentvalidation) {
            reset({
                sucursal: assignmentvalidation.sucursal,
                costo: formatterDolar.format(assignmentvalidation.costo),
                fecha: DateTime.fromISO(assignmentvalidation.fecha).toFormat('LL'),
                region: assignmentvalidation.region,
                fundidora: assignmentvalidation.fundidora,
                prendas: assignmentvalidation.prendas,
                asignado: assignmentvalidation.asignado,
            });
        } else {
            return;
        }
    }, [assignmentvalidation]);

    const onSubmit = async () => {
        const json = {
            idAsignacion: assignmentvalidation.idAsignacion,
            fecha: DateTime.local().minus({hours: 6}).toISO(),
        };
        await dispatch(putAssignmentProcess(json, token));
        history(
            `Recoleccion/${assignmentvalidation.idSucursal}/${assignmentvalidation.idAsignacion}`
        );
    };
    return (
        <>
            {assignmentvalidation !== true ? (
                <>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <span className="child-breadcrumb">Fundiciones</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="child-breadcrumb">Sucursal</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="principal-breadcrumb">Verificación de Datos</span>
                    </div>
                    <div className="container-primary-edit">
                        <span>
                            Verifica que los datos que te asignó tu gerente coincidan con la visita
                            a la sucursal.
                        </span>
                        <form className="flex container-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="column form-padding-left width-50">
                                <label className="required">Fecha</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('fecha', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="fecha"
                                    message="This is required"
                                />
                            </div>
                            <div className="column form-padding-right width-50">
                                <label className="required">Región</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('region', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="btn-aqui-here"
                                    name="region"
                                    message="This is required"
                                    as="p"
                                />
                            </div>
                            <div className="column flex form-padding-left width-50">
                                <label className="required">Fundidora</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('fundidora', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                            </div>
                            <div className="column form-padding-right width-50">
                                <label className="required">No. Prendas</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('prendas', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                            </div>
                            <div className="column flex form-padding-left width-50">
                                <label className="required">Auditor Regional</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('asignado', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                            </div>
                            <div className="column form-padding-right width-50">
                                <label className="required">Costo de paquete</label>
                                <input
                                    className="input-react-hook-form"
                                    disabled={true}
                                    {...register('costo', {
                                        required: 'Required',
                                    })}
                                />
                            </div>
                            <div className="column  width-100">
                                <label className="required">Sucursal</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('sucursal', {
                                        required: 'Required',
                                    })}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex width-100 mt-4 flex-end-button ">
                                <button className="btn-submit" type="submit">
                                    Siguiente
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <a
                    href="https://www.google.com/url?hl=es&q=https://storage.googleapis.com/goldenstarweb/media/ATI-1%2520Atizapan/Contratos%2520apartados/10.pdf&sa=D&ust=1610051255806000&usg=AFQjCNGLPOf9qPELDFEKB080rHqUe9DbBw"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                >
                    <button className="btn-submit flex-end-button" type="submit">
                        A
                    </button>
                </a>
            )}
        </>
    );
}
