import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@images/correctIcone.svg';
import doneIcon from '@images/DoneGreen.svg';
import {RESTORE_NEW_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import {Icon} from '@/components/UserProfileResetPassword/Operational/styles';

interface IError {
    error: boolean;
    password: string;
    repeatPassword?: string;
}

export const ErrorPassword = ({error, password, repeatPassword}: IError) => {
    const passwordIcon = password.length < 8 ? closeIcon : doneIcon;
    const passwordIconColor = password.length < 8 ? '#2A2C2F' : '#309C60';
    const showSecondMessage = error || (repeatPassword && repeatPassword !== password);

    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <Icon
                    src={passwordIcon}
                    alt={password.length < 8 ? 'Close icon' : 'Done icon'}
                    color={passwordIconColor}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                <Text
                    Color={password.length < 8 ? '#2A2C2F' : '#309C60'}
                    FontSize="0.85rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                >
                    {RESTORE_NEW_PASSWORD.MESSAGE}
                </Text>

                {showSecondMessage && (
                    <Text
                        Color={error ? '#2A2C2F' : '#309C60'}
                        FontSize="0.85rem"
                        FontFamily="Nunito"
                        wSpace="normal"
                        TextAlign="justify"
                    >
                        {RESTORE_NEW_PASSWORD.MESSAGE_ONE}
                        {RESTORE_NEW_PASSWORD.MESSAGE_TWO}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
