import {TRANSACTIONS} from '@Shopify/ClientProfile/Redux/types';
import {ITransactionsRedux, ITransactionsState} from '@Shopify/ClientProfile/interfaces';
const initialState: ITransactionsState = {
    error: false,
    loading: false,
    transactions: [],
};
export const getClientTransactions = (
    state = initialState,
    action: ITransactionsRedux
): ITransactionsState => {
    const actionHandlers = {
        [TRANSACTIONS.INITIAL_TRANSACTIONS_DATA]: () => ({
            ...state,
            loading: true,
            error: false,
            transactions: [],
        }),
        [TRANSACTIONS.SUCCESS]: () => ({
            ...state,
            transactions: action.transactions,
            loading: false,
        }),
        [TRANSACTIONS.ERROR]: () => ({
            ...state,
            loading: false,
            error: true,
        }),
        [TRANSACTIONS.RESET_TRANSACTIONS]: () => ({
            ...state,
            transactions: [],
        }),
    };

    return actionHandlers[action.type]?.() || state;
};
