import {
    BRANCH_STATE,
    AMOUNT_SOL_STATE,
    AMOUNT_FI_STATE,
    ACCTION_STATE,
    SIZE_GRID,
    BUTTON_STATE,
    SPCACE,
    BRANCH_ID,
    CONTAINERS_VIEW,
    REQUESTID,
    REQUEST_ID_OPEN,
    REQUEST_STATUS,
    STATUS_FILTER,
    RESET_VIEW_GRIDS,
} from '@ReduxsCashFlow/Types/tableTypes';

const initialState = {
    branch: true,
    amountSol: true,
    amountFi: true,
    stateAction: true,
    stateButton: true,
    containerView: true,
    requestedID: '',
    requestedIDOpen: null,
    branchValue: '',
    requestedStatus: 3,
    space: '0 20px 0 10px',
    statusFilter: [],
    sizeGrid: '2fr 2fr 1fr 3fr 2fr 2fr 1fr .1fr',
};
export interface IScreen {
    branch: boolean;
    amountSol: boolean;
    amountFi: boolean;
    stateAction: boolean;
    stateButton: boolean;
    containerView: boolean;
    requestedID: string;
    requestedIDOpen: number | null;
    branchValue: string;
    requestedStatus: number;
    space: string;
    sizeGrid: string;
}

const cashFlowScreens = (state = initialState, action: {type: string; payload: IScreen}) => {
    switch (action.type) {
        case STATUS_FILTER:
            return {
                ...state,
                statusFilter: action.payload,
            };
        case REQUEST_STATUS:
            return {
                ...state,
                requestedStatus: action.payload,
            };
        case REQUESTID:
            return {
                ...state,
                requestedID: action.payload,
            };
        case REQUEST_ID_OPEN:
            return {
                ...state,
                requestedIDOpen: action.payload,
            };
        case CONTAINERS_VIEW:
            return {
                ...state,
                containerView: action.payload,
            };
        case BRANCH_ID:
            return {
                ...state,
                branchValue: action.payload,
            };
        case SPCACE:
            return {
                ...state,
                space: action.payload,
            };
        case BUTTON_STATE:
            return {
                ...state,
                stateButton: action.payload,
            };
        case BRANCH_STATE:
            return {
                ...state,
                branch: action.payload,
            };
        case AMOUNT_SOL_STATE:
            return {
                ...state,
                amountSol: action.payload,
            };
        case AMOUNT_FI_STATE:
            return {
                ...state,
                amountFi: action.payload,
            };
        case ACCTION_STATE:
            return {
                ...state,
                stateAction: action.payload,
            };
        case SIZE_GRID:
            return {
                ...state,
                sizeGrid: action.payload,
            };
        case RESET_VIEW_GRIDS:
            return initialState;
        default:
            return state;
    }
};
export default cashFlowScreens;
