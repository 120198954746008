import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {Controller} from 'react-hook-form';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import tableIcon from '@/components/PersonalLoans/icons/tableIcon.svg';
import {ICreditLoanSummaryToAccept} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {RejectLoanModal} from '@components/PersonalLoans/PersonalLoanApproved/RejectLoanModal';
import {AmortizationTableLoan} from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/AmortizationTableLoan';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {toggleRejectModal} from '@components/PersonalLoans/Redux/Actions/RejectLoanActions';

const PersonalLoansToAccept: React.FC<ICreditLoanSummaryToAccept> = ({
    dues,
    monthlyPayments,
    totalAmount,
    control,
    errors,
}) => {
    const dispatch = useDispatch();
    const isRejectModalOpen = useSelector((state: RootState) => {
        return state.RejectLoanReducer.isRejectModalOpen;
    });
    const [showTable, setShowTable] = useState<boolean>(false);
    return (
        <>
            <ContainerFlex
                Width="100%"
                Height="100%"
                FlexDir="column"
                Padding="8px 16px"
                Gap="8px"
                backG="#FAFAFA"
                Radius="8px"
            >
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Justify="space-between"
                    Padding="16px 0px"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {`${dues} ${PERSONAL_LOAN_APPROVED.BODY.SUMMARY.MONTHLY_PAYMENTS}`}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.5rem" Color="#1D1E20">
                        {numberToCurrencyWithoutCents(monthlyPayments)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Height="1px" Width="100%" backG="#D4D6D8" />
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Justify="space-between"
                    Padding="16px 0px"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.TOTAL_AMOUNT}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.5rem" Color="#1D1E20">
                        {numberToCurrencyWithoutCents(totalAmount)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Width="100%"
                Height="100%"
                Justify="center"
                Align="center"
                Gap="8px"
                Cursor="pointer"
            >
                <Image Width="24px" Height="24px" src={tableIcon} />
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.0rem"
                    Color="#5A5AFF"
                    Cursor="pointer"
                    onClick={() => setShowTable(!showTable)}
                >
                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.CTA_AMORTIZATION_TABLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="100%">
                <Controller
                    name="terms"
                    control={control}
                    render={({field}) => (
                        <ContainerFlex FlexDir="column">
                            <ContainerFlex Gap="8px">
                                <Checkbox
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                />
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="400"
                                    FontSize="1.0rem"
                                    Color="#2A2C2F"
                                >
                                    {
                                        PERSONAL_LOAN_APPROVED.BODY.SUMMARY
                                            .TERMS_AND_CONDITIONS_ACCEPT
                                    }
                                </Text>
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="400"
                                    FontSize="1.0rem"
                                    Color="#5A5AFF"
                                    Cursor="pointer"
                                >
                                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.TERMS_AND_CONDITIONS}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex>
                                {errors.terms && (
                                    <Text FontFamily="Nunito" FontSize="0.8rem" Color="#ff6357">
                                        {errors.terms.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                />
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="100%" FlexDir="column" Gap="1.5rem">
                <ButtonGenerals
                    type="submit"
                    Width="100%"
                    Height="40px"
                    Padding="8px 16px"
                    BackGC="#5A5AFF"
                    HBackG="#5A5AFF"
                    Radius="32px"
                >
                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.CTA_ACCEPT_LOAN}
                </ButtonGenerals>
                <Text
                    FontWeight="700"
                    Color="#A82424"
                    Cursor="pointer"
                    onClick={() => dispatch(toggleRejectModal())}
                >
                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.REJECT_LOAN}
                </Text>
            </ContainerFlex>
            {isRejectModalOpen && <RejectLoanModal />}
            {showTable && (
                <AmortizationTableLoan showTable={showTable} setShowTable={setShowTable} />
            )}
        </>
    );
};

export default PersonalLoansToAccept;
