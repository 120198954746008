import React from 'react';
import {ContainerFlex, ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import AtaskateLogo from '@images/AtaskateLogo.svg';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

export const LoadingScreen = () => {
    return (
        <ContainerFlex
            FlexDir="column"
            Align="center"
            Justify="center"
            Gap="40px"
            Width="100vw"
            Height="100vh"
        >
            <ContainerImg AlignItems="start" Width="90%">
                <Image src={AtaskateLogo} alt="ataskate-logo" Width="204px" />
            </ContainerImg>
            <LoadingAtaskate width="10%" padding="3.5%" />
            <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                ¡Todo listo!
            </Text>
            <Text Color="#2A2C2F">Bienvenido...</Text>
        </ContainerFlex>
    );
};
