import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {getCreditAndPawnsHistoryAction} from '@/components/CreditsAndPawns/Redux/Actions/GetCreditAndPawnsHistoryActions';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {CREDIT_REQUEST_HISTORIES} from '@/components/CreditsAndPawns/constants';

const CreditRequestAndPawnsTable = ({clientId}: {clientId: number}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    useEffect(() => {
        if (token && clientId) {
            dispatch(getCreditAndPawnsHistoryAction({token, clientId}));
        }
    }, [clientId]);

    const {data: history} = useSelector((state: RootState) => {
        return state.getCreditAndPawnsHistory;
    });
    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="start"
            Width="100%"
            Height="300px"
            Radius="8px"
            Border="1px solid #E8E9EA"
        >
            <ContainerFlex
                Gap="0.1rem"
                Justify="start"
                Align="start"
                Display="grid"
                GridColumns="repeat(5, 122px)"
                Height="49px"
                Bb="1px solid #E8E9EA"
            >
                <ContainerFlex Padding="18px 16px" Height="48px" Justify="start" Width="100%">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color="#1D1E20">
                        {CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_TYPE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Padding="18px 16px" Height="48px" Justify="start" Width="100%">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color="#1D1E20">
                        {CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_STATUS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Padding="18px 16px" Height="48px" Justify="start" Width="100%">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color="#1D1E20">
                        {CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_AMOUNT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Padding="18px 16px" Height="48px" Justify="start" Width="100%">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color="#1D1E20">
                        {CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_PAYMENTS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Padding="18px 16px" Height="48px" Justify="start" Width="100%">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color="#1D1E20">
                        {CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_DEBT}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                OverFlowY="auto"
                Justify="start"
                Align="start"
                Width="100%"
                Height="100%"
            >
                {history &&
                    history?.map((item) => (
                        <ContainerFlex
                            key={item.idOfType}
                            Gap="0.1rem"
                            Justify="start"
                            Align="start"
                            Display="grid"
                            GridColumns="repeat(5, 122px)"
                            Height="49px"
                            Bb="1px solid #E8E9EA"
                        >
                            <ContainerFlex
                                Padding="18px 16px"
                                Height="48px"
                                Justify="start"
                                Width="100%"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="500"
                                    FontSize="0.88rem"
                                    Color="#1D1E20"
                                >
                                    {item.typo}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex
                                Padding="18px 16px"
                                Height="48px"
                                Justify="start"
                                Width="100%"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="700"
                                    FontSize="0.75rem"
                                    Color="#501111"
                                    BorderRadius="32px"
                                    bGround="#FFE6E6"
                                    Padding="2px 8px"
                                    Border="1px solid #FE9A9A"
                                >
                                    {item.estado}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex
                                Padding="18px 16px"
                                Height="48px"
                                Justify="start"
                                Width="100%"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="500"
                                    FontSize="0.88rem"
                                    Color="#1D1E20"
                                >
                                    {numberToCurrencyWithoutCents(item.amountMutual)}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex
                                Padding="18px 16px"
                                Height="48px"
                                Justify="start"
                                Width="100%"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="500"
                                    FontSize="0.88rem"
                                    Color="#1D1E20"
                                >
                                    {numberToCurrencyWithoutCents(item.payment)}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex
                                Padding="18px 16px"
                                Height="48px"
                                Justify="start"
                                Width="100%"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="500"
                                    FontSize="0.88rem"
                                    Color="#1D1E20"
                                >
                                    {numberToCurrencyWithoutCents(item.debet)}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditRequestAndPawnsTable;
