export const GET_STATIC_INFORMATION_CREDIT_PROFILE_START =
    'GET_STATIC_INFORMATION_CREDIT_PROFILE_START';
export const GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS =
    'GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS';
export const GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR =
    'GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR';
export const TOGGLE_CREDIT_TYPE = 'TOGGLE_CREDIT_TYPE';
export const GET_CARD_CREDIT_PROFILE_START = 'GET_CARD_CREDIT_PROFILE_START';
export const GET_CARD_CREDIT_PROFILE_SUCCESS = 'GET_CARD_CREDIT_PROFILE_SUCCESS';
export const GET_CARD_CREDIT_PROFILE_ERROR = 'GET_CARD_CREDIT_PROFILE_ERROR';
export const GET_FILTER_VALUES_START = 'GET_FILTER_VALUES_START';
export const GET_FILTER_VALUES_SUCCESS = 'GET_FILTER_VALUES_SUCCESS';
export const GET_FILTER_VALUES_ERROR = 'GET_FILTER_VALUES_ERROR';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
export const SET_FILTERS_PARAMS_VISUAL = 'SET_FILTERS_PARAMS_VISUAL';
export const RESET_FILTER_PARAMS = 'RESET_FILTER_PARAMS';
export const GET_CREDIT_PROFILE_FILTERS_START = 'GET_CREDIT_PROFILE_FILTERS_START';
export const GET_CREDIT_PROFILE_FILTERS_SUCCESS = 'GET_CREDIT_PROFILE_FILTERS_SUCCESS';
export const GET_CREDIT_PROFILE_FILTERS_ERROR = 'GET_CREDIT_PROFILE_FILTERS_ERROR';
export const SET_VISUAL_DATE = 'SET_VISUAL_DATE';
