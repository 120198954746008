import * as yup from 'yup';
import {VALIDA_FORM} from '@/components/UserProfileResetPassword/Operational/Constants';

const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`|\\]).+$/;

export const shemaResetPassword = yup.object().shape({
    password: yup
        .string()
        .min(8, VALIDA_FORM.MIN)
        .required(VALIDA_FORM.REQUIRED)
        .matches(regex, VALIDA_FORM.MATCHES),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref(VALIDA_FORM.PASS)], VALIDA_FORM.ONE_OF)
        .required(VALIDA_FORM.REQUIRED),
});
