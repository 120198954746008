import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_CITY_START,
    GET_CITY_SUCCESS,
    GET_CITY_ERROR,
    GET_ORGANIZATION_START,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_ERROR,
    GET_COUNTCONTAINER_START,
    GET_COUNTCONTAINER_ERROR,
    GET_COUNTCONTAINER_SUCCESS,
    OBJECT_BRANCH,
    GET_RACK_SUCCESS,
    GET_RACK_START,
    GET_RACK_ERROR,
    GET_FLOOR_START,
    GET_FLOOR_SUCCESS,
    GET_FLOOR_ERROR,
    POST_FLOOR_START,
    POST_FLOOR_SUCCESS,
    POST_FLOOR_ERROR,
    POST_RACK_START,
    POST_RACK_SUCCESS,
    POST_RACK_ERROR,
    PUT_RACK_START,
    PUT_RACK_SUCCESS,
    PUT_RACK_ERROR,
    GET_PENSION_START,
    GET_PENSION_SUCCESS,
    GET_PENSION_ERROR,
    POST_PENSION_START,
    POST_PENSION_SUCCESS,
    POST_PENSION_ERROR,
    POST_VAULT_START,
    POST_VAULT_ERROR,
    POST_VAULT_SUCCESS,
    GET_VAULT_SUCCESS,
    GET_VAULT_START,
    GET_VAULT_ERROR,
    GET_CONTAINERC_START,
    GET_CONTAINERC_SUCCESS,
    GET_CONTAINERC_ERROR,
    GET_CREATECONTAINER_START,
    GET_CREATECONTAINER_SUCCESS,
    GET_CREATECONTAINER_ERROR,
    DELETE_RACK_SUCCESS,
    DELETE_RACK_ERROR,
    DELETE_RACK_START,
    DELETE_FLOOR_START,
    DELETE_FLOOR_SUCCESS,
    DELETE_FLOOR_ERROR,
    DELETE_PENSION_SUCCESS,
    DELETE_PENSION_ERROR,
    DELETE_VAULT_START,
    DELETE_VAULT_SUCCESS,
    DELETE_VAULT_ERROR,
    LOCATIONS_IDCONTAINER,
    DATA_CONTAINERS,
    PUT_PENSION_START,
    PUT_PENSION_SUCCESS,
    PUT_PENSION_ERROR,
    PUT_FLOOR_START,
    PUT_FLOOR_SUCCESS,
    PUT_FLOOR_ERROR,
    BRANCH_SAVE,
    DELETE_LOCATIONS_LOGOUT,
    FLOOR_BY_ID_ERROR,
    FLOOR_BY_ID_SUCCESS,
    FLOOR_BY_ID_START,
    PENSION_BY_ID_START,
    PENSION_BY_ID_SUCCESS,
    PENSION_BY_ID_ERROR,
    RACK_BY_ID_START,
    RACK_BY_ID_SUCCESS,
    RACK_BY_ID_ERROR,
    VOULT_ROWS_START,
    VOULT_ROWS_SUCCESS,
    VOULT_ROWS_ERROR,
    VOULT_DRAWERS_START,
    VOULT_DRAWERS_SUCCESS,
    VOULT_DRAWERS_ERROR,
    VOULT_SQUABS_START,
    VOULT_SQUABS_SUCCESS,
    VOULT_SQUABS_ERROR,
} from '@TypesLocation/LocationTypes';

const initialState = {
    loading: true,
    datatree: null,
    cities: null,
    countcontainer: null,
    objectbranch: null,
    locationsidcontainer: null,
    datacontainers: null,
    gRack: null,
    gFloor: null,
    gPension: null,
    gVault: null,
    floorById: null,
    pensionById: null,
    rackById: null,
    voultRows: null,
    voultDrawers: null,
    voultSquabs: null,
    containerC: null,
    createcontainer: null,
    saveBranch: null,
};
const locationReducers = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case DELETE_LOCATIONS_LOGOUT:
            return {
                loading: false,
                locationsidcontainer: null,
                gRack: null,
                gFloor: null,
                gPension: null,
                gVault: null,
                saveBranch: action.payload,
            };
        case GET_CITY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CITY_SUCCESS:
            return {
                ...state,
                cities: action.payload.data,
                loading: false,
            };
        case GET_CITY_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_ORGANIZATION_START:
            return {
                ...state,
                loading: false,
            };
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                datatree: action.payload,
                loading: false,
            };
        case GET_ORGANIZATION_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_COUNTCONTAINER_START:
            return {
                ...state,
                loading: true,
            };
        case GET_COUNTCONTAINER_SUCCESS:
            return {
                ...state,
                countcontainer: action.payload,
                loading: false,
            };
        case GET_COUNTCONTAINER_ERROR:
            return {
                ...state,
                error: true,
            };
        case OBJECT_BRANCH:
            return {
                ...state,
                objectbranch: action.payload,
            };
        case LOCATIONS_IDCONTAINER:
            return {
                ...state,
                locationsidcontainer: action.payload,
            };
        case DATA_CONTAINERS:
            return {
                ...state,
                datacontainers: action.payload,
            };
        case GET_RACK_START:
            return {
                ...state,
                loading: true,
            };
        case GET_RACK_SUCCESS:
            return {
                ...state,
                gRack: action.payload,

                loading: false,
            };
        case GET_RACK_ERROR:
            return {
                ...state,
                gRack: [],
                error: true,
                loading: false,
            };
        case RACK_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case RACK_BY_ID_SUCCESS:
            return {
                ...state,
                rackById: action.payload,
                loading: false,
            };
        case RACK_BY_ID_ERROR:
            return {
                ...state,
                rackById: null,
                error: true,
                loading: false,
            };
        case POST_RACK_START:
            return {
                ...state,
                loading: true,
            };
        case POST_RACK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_RACK_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_RACK_START:
            return {
                ...state,
                loading: true,
            };
        case PUT_RACK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_RACK_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_FLOOR_START:
            return {
                ...state,
                loading: true,
            };
        case GET_FLOOR_SUCCESS:
            return {
                ...state,
                gFloor: action.payload,
                loading: false,
            };
        case GET_FLOOR_ERROR:
            return {
                ...state,
                gFloor: [],
                error: true,
                loading: false,
            };
        case FLOOR_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case FLOOR_BY_ID_SUCCESS:
            return {
                ...state,
                floorById: action.payload,
                loading: false,
            };
        case FLOOR_BY_ID_ERROR:
            return {
                ...state,
                floorById: null,
                error: true,
                loading: false,
            };
        case PENSION_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case PENSION_BY_ID_SUCCESS:
            return {
                ...state,
                pensionById: action.payload,
                loading: false,
            };
        case PENSION_BY_ID_ERROR:
            return {
                ...state,
                pensionById: null,
                error: true,
                loading: false,
            };
        case POST_FLOOR_START:
            return {
                ...state,
                loading: true,
            };
        case POST_FLOOR_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_FLOOR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_FLOOR_START:
            return {
                ...state,
                loading: true,
            };
        case PUT_FLOOR_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_FLOOR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PENSION_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PENSION_SUCCESS:
            return {
                ...state,
                gPension: action.payload,
                loading: false,
            };
        case GET_PENSION_ERROR:
            return {
                ...state,
                gPension: [],
                error: true,
                loading: false,
            };
        case POST_PENSION_START:
            return {
                ...state,
                loading: true,
            };
        case POST_PENSION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_PENSION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_PENSION_START:
            return {
                ...state,
                loading: true,
            };
        case PUT_PENSION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_PENSION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_VAULT_START:
            return {
                ...state,
                loading: true,
            };
        case GET_VAULT_SUCCESS:
            return {
                ...state,
                gVault: action.payload.data,
                loading: false,
            };
        case GET_VAULT_ERROR:
            return {
                ...state,
                gVault: [],
                error: true,
                loading: false,
            };
        case VOULT_ROWS_START:
            return {
                ...state,
                loading: true,
            };
        case VOULT_ROWS_SUCCESS:
            return {
                ...state,
                voultRows: action.payload,
                loading: false,
            };
        case VOULT_ROWS_ERROR:
            return {
                ...state,
                voultRows: null,
                error: true,
                loading: false,
            };
        case VOULT_DRAWERS_START:
            return {
                ...state,
                loading: true,
            };
        case VOULT_DRAWERS_SUCCESS:
            return {
                ...state,
                voultDrawers: action.payload,
                loading: false,
            };
        case VOULT_DRAWERS_ERROR:
            return {
                ...state,
                voultDrawers: null,
                error: true,
                loading: false,
            };
        case VOULT_SQUABS_START:
            return {
                ...state,
                loading: true,
            };
        case VOULT_SQUABS_SUCCESS:
            return {
                ...state,
                voultSquabs: action.payload,
                loading: false,
            };
        case VOULT_SQUABS_ERROR:
            return {
                ...state,
                voultSquabs: null,
                error: true,
                loading: false,
            };
        case POST_VAULT_START:
            return {
                ...state,
                loading: true,
            };
        case POST_VAULT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_VAULT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        case GET_CONTAINERC_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CONTAINERC_SUCCESS:
            return {
                ...state,
                containerC: action.payload.data,
                loading: false,
            };
        case GET_CONTAINERC_ERROR:
            return {
                ...state,
                error: true,
            };

        case GET_CREATECONTAINER_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CREATECONTAINER_SUCCESS:
            return {
                ...state,
                createcontainer: action.payload.data,
                loading: false,
            };
        case GET_CREATECONTAINER_ERROR:
            return {
                ...state,
                error: true,
            };
        case DELETE_RACK_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_RACK_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case DELETE_RACK_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case DELETE_FLOOR_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_FLOOR_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case DELETE_FLOOR_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case DELETE_PENSION_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case DELETE_PENSION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case DELETE_VAULT_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_VAULT_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case DELETE_VAULT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case BRANCH_SAVE:
            return {
                ...state,
                saveBranch: action.payload,
            };
        default:
            return state;
    }
};
export default locationReducers;
