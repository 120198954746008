import {IDetailsReactivations} from '@InterfacePawn/interface';
import {
    GET_PAWN,
    GET_PAWN_SUCCESS,
    GET_PAWN_ERROR,
    GET_SUCURSAL,
    GET_SUCURSAL_SUCCESS,
    GET_SUCURSAL_ERROR,
    POST_PAWN,
    POST_PAWN_SUCCESS,
    POST_PAWN_ERROR,
    PAWN_ID,
    DATA_SEARCH,
} from '@TypesPawn/PawnTypes';
import {FieldValues} from 'react-hook-form';

const initialState = {
    sucursal: null,
    pawn: null,
    pawnid: null,
    databranch: null,
    datacontract: null,
    error: false,
    loading: false,
};

const reactivations = (
    state: IDetailsReactivations = initialState,
    action: {type: string; value: FieldValues; count: number; pageIndex: number}
) => {
    switch (action.type) {
        case GET_SUCURSAL:
            return {
                ...state,
                loading: true,
            };
        case GET_SUCURSAL_SUCCESS:
            return {
                ...state,
                sucursal: action.value,
                loading: false,
            };
        case GET_SUCURSAL_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_PAWN:
            return {
                ...state,
                loading: true,
            };
        case GET_PAWN_SUCCESS:
            return {
                ...state,
                pawn: action.value,
                loading: false,
            };
        case GET_PAWN_ERROR:
            return {
                ...state,
                pawn: null,
                error: true,
                loading: false,
            };
        case POST_PAWN:
            return {
                ...state,
                loading: true,
            };
        case POST_PAWN_SUCCESS:
            return {
                ...state,
                pawn: null,
                loading: false,
            };
        case POST_PAWN_ERROR:
            return {
                ...state,
                error: true,
            };
        case PAWN_ID:
            return {
                ...state,
                pawnid: action.value,
            };
        case DATA_SEARCH:
            return {
                ...state,
                databranch: action.value,
                datacontract: action.count,
            };
        default:
            return state;
    }
};
export default reactivations;
