import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch, RootState} from '@config/store';
import {getMySalesChannels} from '@components/MyPlan/Redux/Actions/GetSalesChannels';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import plusIcon from '@components/Branches/Images/PlusIcon.svg';
import {SALES_CHANNEL} from '@components/MyPlan/constants';

const SalesChannels = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {channelsData, loading} = useSelector((state: RootState) => state.getSalesChannels);

    useEffect(() => {
        dispatch(getMySalesChannels(token, companyId));
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            Justify="start"
            Gap="16px"
            Height="fit-content"
        >
            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                {SALES_CHANNEL.PROMPT}
            </Text>
            <ContainerFlex
                Padding="16px"
                FlexDir="column"
                Align="flex-start"
                Gap="16px"
                Radius="16px"
                backG="#FFF"
            >
                <ContainerFlex Align="center" Justify="space-between" Self="stretch">
                    <Text FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                        {SALES_CHANNEL.TITLE}
                    </Text>
                    <Text FontSize="1rem" FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                        {SALES_CHANNEL.EDIT}
                    </Text>
                </ContainerFlex>
                {loading && (
                    <ContainerFlex Justify="center">
                        <LoadingAtaskate />
                    </ContainerFlex>
                )}
                {!loading &&
                    channelsData &&
                    channelsData.content.map((channel, index) => {
                        return (
                            <ContainerFlex
                                key={index}
                                Align="center"
                                Justify="flex-start"
                                Gap="8px"
                            >
                                <Image
                                    src={channel.icon}
                                    alt="channel-icon"
                                    Width="20px"
                                    Height="20px"
                                />
                                <Text FontSize="1rem" FontWeight="400" Color="#54575C">
                                    {channel.description}
                                </Text>
                            </ContainerFlex>
                        );
                    })}
                <ContainerFlex
                    Cursor="pointer"
                    Justify="start"
                    Align="center"
                    Width="fit-content"
                    Gap="8px"
                >
                    <Image src={plusIcon} alt="add" Width="24px" Height="24px" />
                    <Text FontSize="1rem" FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                        {SALES_CHANNEL.ADD_CHANNEL}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SalesChannels;
