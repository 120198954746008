import {
    POST_CREATE_STRUCTURE_ERROR,
    POST_CREATE_STRUCTURE_START,
    POST_CREATE_STRUCTURE_SUCCESS,
    POST_CREATE_STRUCTURE_FINISH,
    POST_CREATE_RESET,
} from '@TypesRoles/types';
import {IReducerType} from '@Roles/interface';

const initialState = {
    loading: false,
    error: false,
    createCompany: {},
    success: false,
};

const CreateStructure = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CREATE_STRUCTURE_START:
            return {
                ...state,
                loading: true,
            };
        case POST_CREATE_STRUCTURE_SUCCESS:
            return {
                ...state,
                createCompany: action.payload,
                success: true,
            };
        case POST_CREATE_STRUCTURE_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_CREATE_STRUCTURE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case POST_CREATE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default CreateStructure;
