import styled from '@emotion/styled';
import {ITextBold} from '@components/PersonalLoans/CreditStatus/CreditValidation/styleInterfaces';

export const TextBold = styled.b<ITextBold>`
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-family: ${(props) => props.FontFamily};
`;

TextBold.defaultProps = {
    FontSize: '1rem',
    FontFamily: 'Nunito',
    FontWeight: '700',
};
