export const ADD_DEADLINE = 'ADD_DEADLINE';
export const ADD_DEADLINE_SUCCESS = 'ADD_DEADLINE_SUCCESS';
export const ADD_DEADLINE_ERROR = 'ADD_DEADLINE_ERROR';

export const PUT_DEADLINE = 'PUT_DEADLINE';
export const PUT_DEADLINE_SUCCESS = 'PUT_DEADLINE_SUCCESS';
export const PUT_DEADLINE_ERROR = 'PUT_DEADLINE_ERROR';

export const DELETE_DEADLINE = 'DELETE_DEADLINE';
export const DELETE_DEADLINE_SUCCESS = 'DELETE_DEADLINE_SUCCESS';
export const DELETE_DEADLINE_ERROR = 'DELETE_DEADLINE_ERROR';

export const GET_DEADLINE_ID = 'GET_DEADLINE_ID';
export const GET_DEADLINE_ID_SUCCESS = 'GET_DEADLINE_ID_SUCCESS';
export const GET_DEADLINE_ID_ERROR = 'GET_DEADLINE_ID_ERROR';

export const GET_DEADLINE_BRANCH = 'GET_DEADLINE_BRANCH';
export const GET_DEADLINE_BRANCH_SUCCESS = 'GET_DEADLINE_BRANCH_SUCCESS';
export const GET_DEADLINE_BRANCH_ERROR = 'GET_DEADLINE_BRANCH_ERROR';
export const DELETEDEALINE = 'DELETEDEALINE';

export const ISEDIT = 'ISEDIT';
export const ISCOPY = 'ISCOPY';
