import {IStorageFloor} from '@components/LocationsBranch/interfaces';

export const getInitialFloor = (
    id: number,
    parentId: number,
    emptyName?: boolean
): IStorageFloor[] => {
    return [
        {
            id: 1,
            name: emptyName ? '' : `Piso ${id}`,
            productQuantity: 0,
            levels: Array.from({length: 3}, (_, index) => ({
                id: index + 1,
                name: '',
                levelId: 2,
                productQuantity: 0,
                parentLevel: parentId,
                showRowLevelInput: false,
                showNewLevel: false,
                rows: [],
            })),
        },
    ];
};
