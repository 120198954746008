import {GET_OPEN_HOURS, PERSISTENCE_GROUP_BRANCH} from '@components/LocationsBranch/Redux/types';
import {IGetOpenHours, IGetOpenHoursRedux} from '@components/LocationsBranch/interfaces';

const initialState: IGetOpenHours = {
    openHours: null,
};

const getGroupOpenHours = (state = initialState, action: IGetOpenHoursRedux): IGetOpenHours => {
    switch (action.type) {
        case GET_OPEN_HOURS.ADD:
            return {
                ...state,
                openHours: action.openHours,
            };
        case PERSISTENCE_GROUP_BRANCH.RESET:
            return {
                ...state,
                openHours: null,
            };
        default:
            return state;
    }
};
export default getGroupOpenHours;
