export const CREATE_PROVIDER = {
    newProvider: 'Nuevo Proveedor',
    basicInformation: 'Informacón básica',
    type: 'Tipo',
    name: 'Nombre',
    fiscalInformation: 'Información Fiscal',
    economicActivity: 'Actividad Económica',
    rfc: 'RFC',
    accountNumber: 'No. de cuenta',
    optional: '(Opcional)',
    contact: 'Contacto',
    Phone: 'Telefono principal',
    email: 'Correo electrónico',
};

export const MAX_ACCOUNT_LENGTH = 20;
export const MAX_PHONE_LENGTH = 10;
export const MIN_RFC_LENGTH = 12;
export const MAX_RFC_LENGTH = 13;
export const MAX_PROVIDER_LENGTH = 50;

export const validationYup = {
    required: 'El campo es requerido',
    providerRequired: 'El nombre del proveedor es requerido',
    providerTypeRequired: 'El tipo de proveedor es requerido',
    max50: 'El número debe ser maximo de 50 dígitos',
    RFCRequired: 'El RFC es requerido',
    RFCLength: 'El RFC debe contener 13 caracteres',
    max20: 'El número debe ser maximo de 20 dígitos',
    validNumber: 'Inserte un número válido',
    phoneRequired: 'Ingrese el número de telefono',
    max10: 'El número debe ser de 10 dígitos',
    emailRequired: 'Se requiere correo electrónico',
    emailValid: 'Ingrese un correo electrónico válido',
};
