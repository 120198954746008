import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {SearchInput} from '@Customer/Styles';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {productsLocations} from '@Locations/interfaces';

export const FilterLocations = (props: {
    handlePageClickHistory: (data: {selected: number}) => void;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const branch = useSelector((state: RootState) => {
        return state.nearByBranches?.branch;
    });
    const {handlePageClickHistory, search, setSearch} = props;
    const {handleSubmit, register, reset, watch} = useForm({
        defaultValues: {
            search: '',
        },
    });

    function onSubmit(values: {search: string}) {
        if (!disbledSearch().response) {
            setSearch(values.search);
        }
    }

    const handleKeypress = (e: {key: string}) => {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit);
        }
    };

    const disbledSearch = () => {
        let disable = {
            color: '',
            response: false,
            border: '2px solid #35CF44',
            shadow: '0px 4px 4px rgba(53, 207, 68, 0.2)',
        };

        if (!(search.length > 0))
            if (
                !productsLoations.loading &&
                productsLoations.producst !== null &&
                !(productsLoations.producst.data.length > 0)
            )
                disable = {color: '#f3f3f3', response: true, border: '', shadow: ''};

        return disable;
    };

    useEffect(() => {
        reset({search: ''});
    }, [branch]);

    useEffect(() => {
        if (watch('search').length === 0) {
            setSearch('');
            handlePageClickHistory({selected: 0});
        }
    }, [watch('search'), branch, search]);

    return (
        <ContainerFlex Justify="start" MarginBt="0.938rem" Height="3rem">
            <form onSubmit={handleSubmit(onSubmit)}>
                <SearchInput
                    Padding="0 0.14rem 0 0.847rem"
                    Width="22.688rem"
                    Height="2.5rem"
                    Position="none"
                    GridColumn="89% 11%"
                    BorderH={disbledSearch().border}
                    BoxShadowH={disbledSearch().color}
                    BackG={disbledSearch().color}
                >
                    <input
                        placeholder="Busca por artículo."
                        onKeyPress={handleKeypress}
                        disabled={disbledSearch().response}
                        {...register('search', {})}
                    />
                    <ContainerFlex
                        Width="40px"
                        Height="40px"
                        backG="#E4E7E9"
                        Radius="1.5px"
                        className="material-icons"
                        Cursor="pointer"
                        onClick={handleSubmit(onSubmit)}
                    >
                        search
                    </ContainerFlex>
                </SearchInput>
            </form>
        </ContainerFlex>
    );
};
