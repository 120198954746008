import {
    GET_SEARCHBAR,
    GET_SEARCHBAR_ERROR,
    GET_SEARCHBAR_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {AxiosResponse} from 'axios';
export interface IBreadcrumbSearchCat {
    breadComesName: string;
    levelsIds: {
        groupId: number;
        familyId: number;
        subFamilyId: number;
        brandId: number;
        itemId: number;
    };
}
export interface ICatBrandSearch {
    loading: boolean;
    error: boolean;
    catSearch: IBreadcrumbSearchCat[];
}
const initialState: ICatBrandSearch = {
    loading: false,
    catSearch: [
        {
            breadComesName: '',
            levelsIds: {
                groupId: 0,
                familyId: 0,
                subFamilyId: 0,
                brandId: 0,
                itemId: 0,
            },
        },
    ],
    error: false,
};

export const getSearchBar = (
    state = initialState,
    action: {
        type: string;
        error: boolean;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_SEARCHBAR:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_SEARCHBAR_SUCCESS:
            return {
                ...state,
                loading: false,
                catSearch: action.payload.data.data,
            };
        case GET_SEARCHBAR_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
