import {URL} from '@config/constants/index';

import axios from 'axios';
import {
    GET_CONTACT_COMPANY_START,
    GET_CONTACT_COMPANY_SUCCESS,
    GET_CONTACT_COMPANY_ERROR,
} from '@MyCompany/Redux/Types/types';
import {AppDispatch} from '@/config/store';

export const fetchGetContactCompanyStart = () => {
    return {
        type: GET_CONTACT_COMPANY_START,
    };
};

export const fetchGetContactCompanySuccess = (result: unknown) => {
    return {
        type: GET_CONTACT_COMPANY_SUCCESS,
        payload: result,
    };
};

export const fetchGetContactCompanyError = () => {
    return {
        type: GET_CONTACT_COMPANY_ERROR,
    };
};

export function GetInfoContactCompany(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetContactCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContactCompany}`,
                {headers}
            );

            dispatch(fetchGetContactCompanySuccess(response));
        } catch (error) {
            dispatch(fetchGetContactCompanyError());
        }
    };
}
