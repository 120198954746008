import {GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';
export interface IUserStore {
    employeData: {
        tokenExpiration: number;
        succeeded: boolean;
        message: string;
        errors: null;
        token: string;
        data: [
            {
                employeeId: number;
                employeeName: string;
                employeeAccount: string;
                bankName: string;
                id?: number;
            },
        ];
    };
    loading: boolean;
    error: boolean;
}

const initialState = {
    employeData: {
        tokenExpiration: 0,
        succeeded: false,
        message: '',
        errors: null,
        token: '',
        data: [
            {
                employeeId: 0,
                employeeName: '',
                employeeAccount: '',
                bankName: '',
            },
        ],
    },
    loading: false,
    error: false,
};

const getAccounted = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_ACCOUNT:
            return {
                ...state,
                loading: true,
            };
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                employeData: action.payload,
                loading: true,
            };
        case GET_ACCOUNT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getAccounted;
