import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {PAYMENT_METHODS, VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    IPaymentMethodsProps,
    IPaymentMethodState,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {RowPaymentMethod} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/RowPaymentMethod';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';

export const PaymentMethods: React.FC<IPaymentMethodsProps> = ({
    paymentMethodRef,
    control,
    errors,
    isSubmitted,
    trigger,
}) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const paymentMethodsData: IPaymentMethodState[] = useSelector(
        (state: RootState) => state.setPaymentMethods
    );
    return (
        <ContainerFlex
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1.5rem"
            Gap="1rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Height="max-content"
            ref={paymentMethodRef}
        >
            <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start">
                <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                    {PAYMENT_METHODS.TITLE}
                </Text>
                <Text Color="#2A2C2F">{PAYMENT_METHODS.DESCRIPTION}</Text>
            </ContainerFlex>
            {errors.typePaymentsIns && (
                <WarningError
                    errorMessage={
                        errors.typePaymentsIns.message ||
                        VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE
                    }
                />
            )}
            {paymentMethodsData.map((item, index) => (
                <RowPaymentMethod
                    key={index}
                    control={control}
                    isSubmitted={isSubmitted}
                    trigger={trigger}
                    lastIndex={paymentMethodsData.length - 1}
                    setShowToolTip={setShowToolTip}
                    showToolTip={showToolTip}
                    index={index}
                    item={item}
                />
            ))}
        </ContainerFlex>
    );
};
