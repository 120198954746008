import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import ReactPaginate from 'react-paginate';

import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {getBannerTable} from '@ActionsEcommerce/BannerActions';
import {ContainerTableBanner} from '@Ecommerce/styles';
import {DateTime} from 'luxon';

export function BannerTable() {
    const dispatch = useDispatch();
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const banner = useSelector((state) => {
        return state.banner;
    });

    useEffect(() => {
        if (token) {
            dispatch(getBannerTable('', 10, 0, token));
        }
    }, [token]);
    const handlePageClickHistoryAudit = (data) => {
        let selected = data.selected;
        dispatch(getBannerTable('', 10, selected, token));
    };
    return (
        <ContainerTableBanner>
            <div className="pt-2 pb-2 flex align-items-center w-100 mx-auto">
                <span className="child-breadcrumb">Administración</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Ecommerce</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Banner principal</span>
            </div>
            <div>
                <span>Listado general de banners del ecommerce</span>
                <button onClick={() => (window.location.href = `/Administracion/Banner`)}>
                    CREAR BANNER
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Fecha inicial</th>
                        <th>Fecha final</th>
                        <th>Nivel</th>
                        <th>Subnivel</th>
                        <th>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    {banner.bannerTable &&
                        banner.bannerTable.map((banners, index) => (
                            <tr>
                                <td>{banners.name}</td>
                                <td>{banners.description}</td>
                                <td>{DateTime.fromISO(banners.initDate).toFormat('dd/MM/yyyy')}</td>
                                <td>{DateTime.fromISO(banners.endDate).toFormat('dd/MM/yyyy')}</td>
                                <td>{banners.level}</td>
                                <td>{banners.subLevel}</td>
                                <td>
                                    <GreenSwitch defaultChecked={banners.status} disabled={true} />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={banner.bannerTablecount / 10}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={banner.bannerTablePageIndex}
                onPageChange={handlePageClickHistoryAudit}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </ContainerTableBanner>
    );
}
