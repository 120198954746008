import {
    REQUEST_EXCESSMOTIVE,
    REQUEST_EXCESSMOTIVE_SUCCESS,
    REQUEST_EXCESSMOTIVE_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {ExcessMotive} from '@CashFlow/interfaces';

const initialState = {
    excessMotive: {
        tokenExpiration: 0,
        succeeded: undefined,
        message: '',
        errors: null,
        token: '',
        data: 0,
    },
    loading: false,
    error: false,
};

const requestExcessMotive = (
    state = initialState,
    action: {
        type: string;
        payload: ExcessMotive;
    }
) => {
    switch (action.type) {
        case REQUEST_EXCESSMOTIVE:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_EXCESSMOTIVE_SUCCESS:
            return {
                ...state,
                excessMotive: action.payload,
                loading: false,
            };
        case REQUEST_EXCESSMOTIVE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default requestExcessMotive;
