import {
    GET_CART_CONTRACTS,
    GET_CART_CONTRACTS_SUCCESS,
    GET_CART_CONTRACTS_ERROR,
    PUT_CONTRACTS,
    PUT_CONTRACTS_SUCCESS,
    PUT_CONTRACTS_ERROR,
    EDIT_INFO,
    CANCEL_EDIT_INFO,
    DELETE_CART_CONTRATS,
} from '@components/ShoppingCart/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    loadingUpdate: false,
    data: [],
    info: false,
};

const getCartContracts = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_CART_CONTRACTS:
            return {...state, loading: true};
        case GET_CART_CONTRACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
            };
        case GET_CART_CONTRACTS_ERROR:
            return {...state, loading: false, error: true};
        case PUT_CONTRACTS:
            return {...state, loadingUpdate: true};
        case PUT_CONTRACTS_SUCCESS:
            return {
                ...state,
                loadingUpdate: false,
                data: action.payload.data.data,
            };
        case PUT_CONTRACTS_ERROR:
            return {...state, loadingUpdate: false, error: true};
        case EDIT_INFO:
            return {...state, info: true};
        case CANCEL_EDIT_INFO:
            return {...state, info: false};
        case DELETE_CART_CONTRATS:
            return {...state, data: []};
        default:
            return state;
    }
};

export default getCartContracts;
