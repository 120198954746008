import React from 'react';
import {TableItem} from '@/components/Shopify/Endeavors/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import {IHeaderRowProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const HeaderRow: React.FC<IHeaderRowProps> = ({
    index,
    handleToolTipVisibility,
    id,
    title,
    isShowingToolTip,
    tooltipDescription,
}) => {
    return (
        <TableItem
            Justify={index !== 0 ? 'start' : 'center'}
            Padding={index === 0 ? '0px 0px 0px 2rem' : ''}
            Gap="0.5rem"
        >
            <Text
                Width={index === 0 ? '100%' : 'max-content'}
                Color="#1D1E20"
                FontSize="0.875rem"
                FontWeight="700"
                Height="100%"
            >
                {title}
            </Text>
            {index !== 0 && (
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    FlexDir="column"
                    Position="relative"
                    Justify="start"
                    onMouseEnter={() => handleToolTipVisibility(id, true)}
                    onMouseLeave={() => handleToolTipVisibility(id, false)}
                >
                    <Image
                        src={questionIcon}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="tooltip-icon"
                    />
                    {isShowingToolTip && <ToolTip description={tooltipDescription} />}
                </ContainerFlex>
            )}
        </TableItem>
    );
};
