import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {
    IAmortizationTableFilters,
    IAmortizationTableLoan,
    IAmortizationTableReducer,
} from '@components/PersonalLoans/interfaces';
import {ContainerFlex, Image, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/icons/closeIcon.svg';
import {AppDispatch, RootState} from '@/config/store';
import {getAmortizationTableLoan} from '@components/PersonalLoans/Redux/Actions/GetAmortizationTableLoanActions';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {AmortizationTable} from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/AmortizationTable';
import {
    AMORTIZATION_TABLE_LOANS,
    amortizationTableLayoutData,
} from '@components/PersonalLoans/constants';

export const AmortizationTableLoan = ({showTable, setShowTable}: IAmortizationTableLoan) => {
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const termId = useSelector((state: RootState) => {
        return state.getCreditLoanSummary.data?.termId;
    });
    const loanGranted = useSelector((state: RootState) => {
        return state.getCreditLoanSummary.data?.loanGranted;
    });
    useEffect(() => {
        if (token && termId && loanGranted) {
            const filters: IAmortizationTableFilters = {
                ammountRequested: loanGranted,
                plazoId: termId,
            };
            dispatch(getAmortizationTableLoan(token, filters));
        }
    }, []);
    const tableData: IAmortizationTableReducer = useSelector(
        (state: RootState) => state.getAmortizationTableLoan
    );
    return (
        <>
            <Modal BackC="rgba(0, 0, 0, 0.40)">
                <ContainerFlex
                    Width="777px"
                    Height="max-content"
                    Padding="1.5rem"
                    FlexDir="column"
                    Justify="start"
                    Radius="1.5rem"
                    Gap="1rem"
                    BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    backG="#FFF"
                    Border="1px solid #E8E9EA"
                    OverFlow="hidden"
                >
                    <ContainerFlex Justify="space-between">
                        <Text
                            Width="560px"
                            Height="16px"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                            FontWeight="500"
                        >
                            {AMORTIZATION_TABLE_LOANS.TITLE}
                        </Text>
                        <Image
                            src={closeIcon}
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            onClick={() => setShowTable(!showTable)}
                        />
                    </ContainerFlex>
                    {tableData.loading ? (
                        <ContainerFlex Height="336px">
                            <Loading />
                        </ContainerFlex>
                    ) : (
                        <AmortizationTable tableData={amortizationTableLayoutData} error={false} />
                    )}
                </ContainerFlex>
            </Modal>
        </>
    );
};
