import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {CREDIT_REQUEST_HEADER} from '@components/CreditsAndPawns/constants';
import {ICreditRequestHeaderProps} from '@components/CreditsAndPawns/interfaces';
import {useNavigate} from 'react-router-dom';
import arrowLeft from '@components/CreditsAndPawns/icons/arrowSmallLeft.svg';

const CreditRequestHeader: React.FC<ICreditRequestHeaderProps> = ({solicitudeNumber}) => {
    const navigate = useNavigate();
    return (
        <ContainerFlex FlexDir="column" Align="start" Gap="1.0rem">
            <ContainerFlex FlexDir="row" Align="start" Justify="start" Gap="0.5rem">
                <ContainerFlex
                    FlexDir="row"
                    Justify="start"
                    Gap="8px"
                    onClick={() => navigate('/CreditsAndPawns/')}
                >
                    <Image Width="24px" Height="24px" src={arrowLeft} />
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#5A5AFF"
                        FontStyle="normal"
                        LHeight="1.5rem"
                        Cursor="pointer"
                    >
                        {CREDIT_REQUEST_HEADER.CTA_BACK_TO}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="row" Align="start" Justify="start">
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.75rem"
                    Color="#2A2C2F"
                    TextAlign="center"
                    FontStyle="normal"
                    LHeight="2.28rem"
                >
                    {`${CREDIT_REQUEST_HEADER.TITLE}${solicitudeNumber ?? ''}`}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditRequestHeader;
