import {IReducerType} from '@/components/Branches/interfaces';
import {
    GET_INFO_BRANCH,
    GET_INFO_BRANCH_ERROR,
    GET_INFO_BRANCH_FINISH,
    GET_INFO_BRANCH_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    branchInfo: {},
};

const GetInformationBranch = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_INFO_BRANCH:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_INFO_BRANCH_SUCCESS:
            return {
                ...state,
                branchInfo: action.payload.data.data,
            };
        case GET_INFO_BRANCH_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_INFO_BRANCH_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetInformationBranch;
