import React from 'react';
import {DateTime} from 'luxon';

import {Link} from 'react-router-dom';
export const RowSecurityBagWeight = (props: {
    seguribolsa: number;
    papeleta: string;
    sucursal: string;
    fecha: string;
    costo: number;
}) => {
    const {seguribolsa, papeleta, sucursal, fecha, costo} = props;
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    //   const redirectoAuction = (id: number) => {
    //     history(`Almoneda/${id}`)
    // }
    return (
        <tr className="row-sucursal-tr">
            <td className="font-weight-bolder text-reset color-black">
                <Link to={`/PesoSeguribolsa/${seguribolsa}`}>
                    <span className="color-dark">{seguribolsa}</span>
                </Link>
            </td>
            <td>{papeleta}</td>
            <td>{sucursal}</td>
            <td>{DateTime.fromISO(fecha).toLocaleString(DateTime.DATE_SHORT)}</td>
            <td>{formatterDolar.format(costo)}</td>
        </tr>
    );
};
