import {AppDispatch} from '@/config/store';
import {
    GET_USERS_CLIENTS_VALIDATION_DATA,
    GET_USERS_CLIENTS_VALIDATION_SUCCESS,
    GET_USERS_CLIENTS_VALIDATION_ERROR,
    RESET_USER_CLIENTS_DATA,
} from '@components/MarketAccessLogin/Redux/types/types';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {IFormInput} from '@components/MarketAccessLogin/Operational/Interfaces';
import {NavigateFunction} from 'react-router-dom';
import {getValidateUsersSuccess} from '@/components/Login/Redux/Actions/GetValidateUsers';

export const getUsersClientsValidationData = () => {
    return {
        type: GET_USERS_CLIENTS_VALIDATION_DATA,
    };
};
export const getUsersClientsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_USERS_CLIENTS_VALIDATION_SUCCESS,
        payload: result,
    };
};
export const getUsersClientsError = (error: AxiosError) => {
    return {
        type: GET_USERS_CLIENTS_VALIDATION_ERROR,
        error: error.response?.data,
        payload: error,
    };
};

export const logoutUserClients = () => {
    return {
        type: RESET_USER_CLIENTS_DATA,
    };
};

export function getValidateLoginUserClients(
    data: IFormInput,
    navigate: NavigateFunction,
    setError: (e: boolean) => void
) {
    const headers = {
        Accept: 'application/json',
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getUsersClientsValidationData());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAuthenticationUserClient}`,
                {
                    headers,
                    params: data,
                }
            );

            dispatch(getUsersClientsSuccess(response));
            dispatch(getValidateUsersSuccess(response));
        } catch (error) {
            dispatch(getUsersClientsError(error as AxiosError));
            setError(true);
        }
    };
}
