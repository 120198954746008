import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Modal} from '@/components/Shopify/Ecommerce/styles';
import CustomerInfoModalHeader from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalHeader';
import CustomerInfoModalBody from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalBody';
import CustomerInfoModalFooter from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalFooter';
import {getCustomerInformationAction} from '@/components/CreditsAndPawns/Redux/Actions/GetCustomerInformationActions';

const CustomerInfoModal = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {personalInformationId, data} = useSelector((state: RootState) => {
        return state.getCustomerInfo;
    });
    useEffect(() => {
        if (token && personalInformationId) {
            dispatch(getCustomerInformationAction({token, personalInformationId}));
        }
    }, [personalInformationId]);
    return (
        <Modal Justify="end" Align="start">
            <ContainerFlex
                FlexDir="column"
                Width="400px"
                Height="100%"
                Radius="16px 0px 0px 16px"
                backG="#fff"
                Justify="space-between"
            >
                <CustomerInfoModalHeader />
                {data && <CustomerInfoModalBody {...data} />}
                <CustomerInfoModalFooter />
            </ContainerFlex>
        </Modal>
    );
};

export default CustomerInfoModal;
