import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';

import {GET_CLIENT_DETAILS} from '@Shopify/ClientProfile/Redux/types';

const getClientDetailsStart = () => ({
    type: GET_CLIENT_DETAILS.GET_START,
});
const getClientDetailsSuccess = (result: AxiosResponse) => ({
    type: GET_CLIENT_DETAILS.GET_SUCCESS,
    payload: result.data,
});
const getClientDetailsError = (error: AxiosError) => ({
    type: GET_CLIENT_DETAILS.GET_ERROR,
    error: error.message,
});
export const getClientDetails = (token: string, params: {clientId: number}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    const queryString = StringUtils.jsonToQueryString({...params});

    return async (dispatch: AppDispatch) => {
        dispatch(getClientDetailsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetClientDetails}${queryString}`,
                {headers}
            );
            dispatch(getClientDetailsSuccess(response.data));
        } catch (error) {
            dispatch(getClientDetailsError(error as AxiosError));
        }
    };
};
