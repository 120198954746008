import {AxiosError, AxiosResponse} from 'axios';
import {
    RESEND_EMAIL_VALIDATION_RESET,
    RESEND_EMAIL_VALIDATION_ERROR,
    RESEND_EMAIL_VALIDATION_START,
    RESEND_EMAIL_VALIDATION_SUCCESS,
} from '@components/Login/Redux/Types/Types';
import {IDataLoginResendEmail} from '@components/Login/interfaces';

const initialState: IDataLoginResendEmail = {
    userData: null,
    loading: false,
    error: false,
    errorData: null,
};
const ResendEmailValidation = (
    state: IDataLoginResendEmail = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataLoginResendEmail => {
    switch (action.type) {
        case RESEND_EMAIL_VALIDATION_START:
            return {...state, loading: true, error: false};
        case RESEND_EMAIL_VALIDATION_SUCCESS:
            return {...state, userData: action.payload.data, loading: false, error: false};
        case RESEND_EMAIL_VALIDATION_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case RESEND_EMAIL_VALIDATION_RESET:
            return initialState;
        default:
            return state;
    }
};

export default ResendEmailValidation;
