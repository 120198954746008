import React, {ReactNode} from 'react';
import {ClickAwayListener} from '@mui/material';
import {DropDownMenuContainer} from '@ReutilizableComponent/styles';
import {DropDownMenuTypes} from '@ReutilizableComponent/interface';

export const DropDownMenu = (props: DropDownMenuTypes) => {
    const {
        OutSideClick,
        Height,
        Width,
        Position,
        BackGround,
        Values,
        Border,
        MenuDisplayStatus,
        Bottom,
        Left,
        Right,
        Top,
        Justify,
        BorderRadius,
        BoxShadow,
        Padding,
        Valued,
        OverFlowY,
        ZIndex,
    } = props;
    return (
        <>
            {MenuDisplayStatus && (
                <ClickAwayListener onClickAway={OutSideClick}>
                    <DropDownMenuContainer
                        Position={Position}
                        Width={Width}
                        Height={Height}
                        Background={BackGround}
                        Border={Border}
                        Top={Top}
                        Bottom={Bottom}
                        Left={Left}
                        Right={Right}
                        Justify={Justify}
                        BorderRadius={BorderRadius}
                        BoxShadow={BoxShadow}
                        Padding={Padding}
                        OverFlowY={OverFlowY}
                        ZIndex={ZIndex}
                    >
                        {Values as ReactNode}
                        {Valued}
                    </DropDownMenuContainer>
                </ClickAwayListener>
            )}
        </>
    );
};
