import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {AddIdDocumentData, IDocumentsInfo} from '@Shopify/ClientProfile/interfaces';
import {ADD_ID_DOCUMENT} from '@Shopify/ClientProfile/Redux/types';

const addDocStart = () => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_START,
});

const addDocSuccess = (result: AxiosResponse, imageInfo: IDocumentsInfo) => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_SUCCESS,
    payload: result.data,
    imageData: imageInfo,
});

const addDocError = (message?: string) => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_ERROR,
    message,
});

export const addIdDocument = (
    data: AddIdDocumentData,
    imageInfo: IDocumentsInfo,
    token: string
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(addDocStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddIdentificationDocument}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addDocSuccess(response.data, imageInfo));
        } catch (error) {
            const errorResp = error as AxiosError;
            dispatch(addDocError(errorResp.response?.data as string));
        }
    };
};

export const clearDocumentsError = () => ({
    type: ADD_ID_DOCUMENT.CLEAR_ERROR,
});
