import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

export const PrivacityContainer = () => {
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex Height="auto">
                <Text
                    Cursor="pointer"
                    TextDecoration="underline"
                    FontSize="0.75rem"
                    Padding="0 5px"
                    FontWeight="300"
                    Color="#414042"
                    Height="auto"
                    onClick={() => {
                        window.location.href = `https://www.multiapoyo.com.mx/pages/legales`;
                    }}
                >
                    Términos y condiciones de uso
                </Text>
                <Text
                    FontSize="0.75rem"
                    Color="#414042"
                    Height="auto"
                    FontWeight="300"
                    Padding="0 0.125rem"
                >
                    y el
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="auto">
                <Text
                    TextDecoration="underline"
                    Cursor="pointer"
                    Height="auto"
                    FontSize="0.75rem"
                    Padding="0 5px"
                    FontWeight="300"
                    Color="#414042"
                    onClick={() => {
                        window.location.href = `https://www.multiapoyo.com.mx/pages/aviso-de-privacidad`;
                    }}
                >
                    Aviso de privacidad
                </Text>
                <Text
                    FontSize="0.75rem"
                    Color="#414042"
                    Height="auto"
                    FontWeight="300"
                    Padding="0 0.125rem"
                >
                    de Multiapoyo.
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
