import {AppDispatch} from '@/config/store';
import {
    GET_ALL_COOWNERS,
    GET_ALL_COOWNERS_SUCCESS,
    GET_ALL_COOWNERS_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {IAllCoowner} from '@components/Quoter/CoownerBeneficiary/Redux/interfaces';

export const getAllCoowner = () => {
    return {
        type: GET_ALL_COOWNERS,
    };
};

export const getAllCoownerSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_COOWNERS_SUCCESS,
        payload: result,
    };
};
export const getAllCoownerError = (error: AxiosError) => {
    return {
        type: GET_ALL_COOWNERS_ERROR,
        error: error,
    };
};

export function getAllCoowners(token: string, filters: IAllCoowner) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(getAllCoowner());
        try {
            const response = await axios.get(
                `
            ${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCoOwners}${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getAllCoownerSuccess(response));
        } catch (error) {
            dispatch(getAllCoownerError(error as AxiosError));
        }
    };
}
