import {TypeResponseData} from '@Roles/AddRoles/interfaces';
import {
    GET_EDIT_ROL,
    GET_EDIT_ROL_SUCCESS,
    GET_EDIT_ROL_ERROR,
    GET_EDIT_ROL_RESET,
} from '@Roles/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    edit: false,
    data: {},
};

const editInfoRol = (state = initialState, action: TypeResponseData) => {
    switch (action.type) {
        case GET_EDIT_ROL:
            return {
                ...state,
                loading: true,
            };
        case GET_EDIT_ROL_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                edit: true,
                loading: false,
            };
        case GET_EDIT_ROL_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_EDIT_ROL_RESET:
            return initialState;
        default:
            return state;
    }
};

export default editInfoRol;
