export const getDayHoursInterval = (startHour: number, endHour: number, interval: number) => {
    if (startHour < 0 || startHour > 23 || endHour < 0 || endHour > 23 || interval <= 0) {
        return [];
    }

    const intervals = Math.floor(((endHour - startHour + 1) * 60) / interval);

    return Array.from({length: intervals}, (_, index) => {
        const minute =
            (startHour + Math.floor((index * interval) / 60)) * 60 + ((index * interval) % 60);
        const hour = Math.floor(minute / 60);
        const minuteRemainder = minute % 60;

        return {
            id: index + 1,
            time: `${hour.toString().padStart(2, '0')}:${minuteRemainder
                .toString()
                .padStart(2, '0')}`,
        };
    });
};
