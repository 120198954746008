import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';

import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import TooltipGeneral from '@components/General/Atoms/Tooltip/TooltipGeneral';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';

import * as style from '@Articles/style';
import {Icons} from '@Articles/style';
import {CatTypeArticle} from '@components/Articles/Redux/Actions/FieldDinamics';
import {DINAMICS, HEADERCATALOG, NEWCATALOG} from '@Articles/constants';
import {NewFeatureProps} from '@components/Articles/interface';
import {
    DataCharacteristicDynamic,
    ArticlePropertyList,
    ICharacteristics,
} from '@components/Articles/Redux/interfaces';
import {AddDinamicItems} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {IDetailsFeature, IDynamicFeatures} from '@/components/Catalogue/interfaces';
import {updateDynamicFeature} from '@/components/Catalogue/Redux/Actions/SaveNewDynamicFeatureActions';

const CaracteristicsDinamics: React.FC<NewFeatureProps> = ({showModal, setShowModal}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const DataUpdate = useSelector(
        (state: RootState) => state.GetNewCatalogId.dataCatalog?.dinamicsCharacteristics
    );
    const ActionTypeForm = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.actionType as number
    );
    const [localFeatures, setLocalFeatures] = useState<ICharacteristics[]>([]);
    const [changedFeatures, setChangedFeatures] = useState<ICharacteristics[]>([]);
    const DinamicFeature = useSelector((state: RootState) => {
        return state.SaveNewDynamicFeatureReducer;
    });

    useEffect(() => {
        dispatch(CatTypeArticle(token));
    }, [dispatch, token]);

    useEffect(() => {
        setLocalFeatures(DataUpdate || []);
        setChangedFeatures(DataUpdate || []);
    }, [DataUpdate]);

    useEffect(() => {
        const formattedFeatures: DataCharacteristicDynamic[] = changedFeatures.map((item) => ({
            dynamicCharacteristicsId: item.articleDetailId,
            idTypeCharacteristics: item.typeArticleDetaillId,
            characteristicName: item.propertyName,
            mandatory: item.isRequerid,
            includesLabel: item.includeLabel,
            enabled: item.propertyStatus,
            valueDinamics: item.articlesPropertyLists.map((list: ArticlePropertyList) => ({
                valueDetailId: list.articleDetailsId,
                description: list.descriptionItem,
                statusValue: true,
            })),
        }));
        dispatch(AddDinamicItems(formattedFeatures));
    }, [changedFeatures, dispatch]);
    const handleCheckboxChange = (
        articleDetailId: number,
        field: 'isRequerid' | 'includeLabel' | 'propertyStatus'
    ) => {
        setLocalFeatures((prevLocalFeatures) =>
            prevLocalFeatures.map((item: ICharacteristics) =>
                item.articleDetailId === articleDetailId
                    ? {
                          ...item,
                          [field]:
                              field === 'isRequerid' ? (item[field] === 1 ? 0 : 1) : !item[field],
                      }
                    : item
            )
        );

        setChangedFeatures((prevChangedFeatures) => {
            const existingItemIndex = prevChangedFeatures.findIndex(
                (item) => item.articleDetailId === articleDetailId
            );

            if (existingItemIndex > -1) {
                return prevChangedFeatures.map((item, index) =>
                    index === existingItemIndex
                        ? {
                              ...item,
                              [field]:
                                  field === 'isRequerid'
                                      ? item[field] === 1
                                          ? 0
                                          : 1
                                      : !item[field],
                          }
                        : item
                );
            } else {
                const newItem = localFeatures.find(
                    (item) => item.articleDetailId === articleDetailId
                );
                if (newItem) {
                    return [
                        ...prevChangedFeatures,
                        {
                            ...newItem,
                            [field]:
                                field === 'isRequerid'
                                    ? newItem[field] === 1
                                        ? 0
                                        : 1
                                    : !newItem[field],
                        },
                    ];
                }
                return prevChangedFeatures;
            }
        });
    };

    const handleDinamicFeatureChange = (
        index: number,
        field: 'isRequerid' | 'includeLabelPrinter' | 'enabled'
    ) => {
        const updatedFeature = DinamicFeature[index];
        if (updatedFeature) {
            const updatedFeatureData = {
                ...updatedFeature,
                [field]:
                    field === 'isRequerid'
                        ? updatedFeature[field] === 1
                            ? 0
                            : 1
                        : !updatedFeature[field],
            };
            const updatedFeatures = [...DinamicFeature];
            updatedFeatures[index] = updatedFeatureData;
            dispatch(updateDynamicFeature(updatedFeatureData));
        }
    };

    return (
        <ContainerFlex {...style.Card}>
            <ContainerFlex Height="" Justify="start" Gap="0.5rem">
                <Text {...style.Card.Title}>{DINAMICS.CARACTERISTICS}</Text>
                <TooltipGeneral
                    icon={'help_outline'}
                    titleTooltip={DINAMICS.CARACTERISTICS}
                    descriptionTooltip={DINAMICS.DESCRIPTION_CARACTERISTICS}
                />
            </ContainerFlex>

            <ContainerFlex FlexDir="column" Align="start">
                <Text {...style.Card.Subtitle}>{DINAMICS.SECTION_DESCRIPTION}</Text>
                <ContainerFlex
                    Display="grid"
                    GridColumns="0.5fr 0.5fr 0.5fr 0.2fr 0.2fr 0.2fr"
                    Justify="start"
                    Height="2.75rem"
                    Padding="0.5rem"
                    Bb="solid 1px #E8E9EA"
                >
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.NAME}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.TYPE}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.VALUES}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.MANDATORY}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.ADD}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {HEADERCATALOG.AVIABLE}
                    </Text>
                </ContainerFlex>
                {localFeatures &&
                    ActionTypeForm === NEWCATALOG.EDIT &&
                    localFeatures.map((item: ICharacteristics) => (
                        <ContainerFlex
                            Display="grid"
                            GridColumns="0.5fr 0.5fr 0.5fr 0.2fr 0.2fr 0.2fr"
                            Justify="start"
                            Height="3.5rem"
                            Bb="solid 1px #E8E9EA"
                            Gap="1.5rem"
                            Padding="0.5rem"
                            key={item.articleDetailId}
                        >
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {item.propertyName}
                            </TextEllipsis>
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {item.typeArticleDetailName}
                            </TextEllipsis>
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {item.articlesPropertyLists
                                    .map((list) => list.descriptionItem)
                                    .join(', ')}
                            </TextEllipsis>
                            <Checkbox
                                checked={item.isRequerid === 1}
                                onChange={() =>
                                    handleCheckboxChange(item.articleDetailId, 'isRequerid')
                                }
                            />
                            <Checkbox
                                checked={item.includeLabel}
                                onChange={() =>
                                    handleCheckboxChange(item.articleDetailId, 'includeLabel')
                                }
                            />
                            <GreenSwitch
                                checked={item.propertyStatus}
                                onChange={() =>
                                    handleCheckboxChange(item.articleDetailId, 'propertyStatus')
                                }
                            />
                        </ContainerFlex>
                    ))}
                {DinamicFeature &&
                    Array.isArray(DinamicFeature) &&
                    DinamicFeature.length > 0 &&
                    DinamicFeature.map((feature: IDynamicFeatures, index: number) => (
                        <ContainerFlex
                            key={index}
                            Display="grid"
                            GridColumns="0.5fr 0.5fr 0.5fr 0.2fr 0.2fr 0.2fr"
                            Justify="start"
                            Height="3.5rem"
                            Bb="solid 1px #E8E9EA"
                            Gap="1.5rem"
                            Padding="0.5rem"
                        >
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {feature.nameCharacteristics}
                            </TextEllipsis>
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {feature.typeCharacteristics}
                            </TextEllipsis>
                            <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                {feature.details
                                    .map((detail: IDetailsFeature) => detail.valueDescription)
                                    .join(', ')}
                            </TextEllipsis>
                            <Checkbox
                                checked={feature.isRequerid === 1}
                                onChange={() => handleDinamicFeatureChange(index, 'isRequerid')}
                            />
                            <Checkbox
                                checked={feature.includeLabelPrinter}
                                onChange={() =>
                                    handleDinamicFeatureChange(index, 'includeLabelPrinter')
                                }
                            />
                            <GreenSwitch
                                checked={feature.enabled}
                                onChange={() => handleDinamicFeatureChange(index, 'enabled')}
                            />
                        </ContainerFlex>
                    ))}
            </ContainerFlex>
            <Text {...style.buttonFields} onClick={() => setShowModal(!showModal)}>
                <Icons {...style.IconDinamics} MarginRight="0.25rem">
                    add
                </Icons>
                {DINAMICS.NEW_CARACTERISTIC}
            </Text>
        </ContainerFlex>
    );
};

export default CaracteristicsDinamics;
