import React, {useEffect} from 'react';

import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import ButtonGeneral from '@General/Atoms/Button';
import {ContainerFlex, ContainerForm, Text, TextArea} from '@Shopify/Ecommerce/styles';
import {SelectFileVault} from '@AdminLocations/CreateLocation/style';
import {updateLocationProduct} from '@ActionsManageLocations/ManageLocationsActions';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import {productsLocations} from '@Locations/interfaces';
import {
    VoultDrawersById,
    VoultRowsById,
    VoultSquabsId,
} from '@AdminLocations/redux/actions/LocationAction';

export const ManageVault = () => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const dispatch: AppDispatch = useDispatch();

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            voult: '',
            row: '',
            drawer: '',
            squab: '',
            incidence: '',
        },
    });

    const onSubmit = (data: {squab: string; incidence: string}) => {
        const values = {
            pledgeId: productsLoations.productId.data.pledgeId,
            squabsVaultId: Number(data.squab),
            containerTypeId: locations.locationsidcontainer,
            issuePrenda: data.incidence,
        };
        dispatch(updateLocationProduct(values, token));
    };

    const resetInfo = async () => {
        const restored = productsLoations.productId.data.locationVoult;
        if (restored.voultName !== null) {
            await dispatch(VoultRowsById(String(restored.voultId), token));
            await dispatch(VoultDrawersById(String(restored.rowId), token));
            await dispatch(VoultSquabsId(String(restored.drawerId), token));
            if (!locations.loading && locations.voultSquabs !== null)
                reset({
                    voult: String(restored.voultId),
                    row: String(restored.rowId),
                    drawer: String(restored.drawerId),
                    squab: String(restored.squabId),
                    incidence: productsLoations.productId.data.issueLocation,
                });
        }
    };

    useEffect(() => {
        if (locations.locationsidcontainer === 2) resetInfo();
    }, [locations.locationsidcontainer]);

    return (
        <ContainerFlex Align="start" FlexDir="column">
            <ContainerForm
                Width="100%"
                FlexDir="column"
                Align="start"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Text Height="auto" FontSize="1.125rem" Padding="0 0 1.813rem 0">
                    Detalles de la ubicación en bóveda
                </Text>
                <ContainerFlex Justify="space-between" Width="80%">
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Bóveda
                        </Text>
                        <select
                            {...register('voult', {
                                required: {
                                    value: true,
                                    message: 'Seleccione una bóveda',
                                },
                            })}
                            onChange={(e) => {
                                dispatch(VoultRowsById(e.target.value, token));
                                reset({
                                    row: '',
                                    drawer: '',
                                    squab: '',
                                });
                            }}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.gVault &&
                                locations.gVault.data?.map((item, index: number) => (
                                    <option key={index} value={item.id}>
                                        {item.voultName}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.voult?.message}
                        </Text>
                    </SelectFileVault>
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Fila
                        </Text>
                        <select
                            {...register('row', {
                                required: {
                                    value: true,
                                    message: 'Seleccione una fila',
                                },
                            })}
                            onChange={(e) => {
                                dispatch(VoultDrawersById(e.target.value, token));
                                reset({
                                    drawer: '',
                                    squab: '',
                                });
                            }}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.voultRows &&
                                locations.voultRows?.map((item, index: number) => (
                                    <option key={index} value={item.rowId}>
                                        {item.letterRow}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.row?.message}
                        </Text>
                    </SelectFileVault>
                </ContainerFlex>
                <ContainerFlex Justify="space-between" Width="80%">
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Cajón
                        </Text>
                        <select
                            {...register('drawer', {
                                required: {
                                    value: true,
                                    message: 'Seleccione un cajón',
                                },
                            })}
                            onChange={(e) => {
                                dispatch(VoultSquabsId(e.target.value, token));
                                reset({
                                    squab: '',
                                });
                            }}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.voultDrawers &&
                                locations.voultDrawers?.map((item, index: number) => (
                                    <option key={index} value={item.drawerId}>
                                        {item.dragerNumber}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.drawer?.message}
                        </Text>
                    </SelectFileVault>
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Pichonera
                        </Text>
                        <select
                            {...register('squab', {
                                required: {
                                    value: true,
                                    message: 'Selecciona una pichonera',
                                },
                            })}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.voultSquabs &&
                                locations.voultSquabs?.map((item, index: number) => (
                                    <option key={index} value={item.squabId}>
                                        {item.squabNumber}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.squab?.message}
                        </Text>
                    </SelectFileVault>
                </ContainerFlex>
                <ContainerFlex Align="start" FlexDir="column">
                    <Text FontSize="0.75rem">Incidencias</Text>
                    <TextArea
                        {...register('incidence', {
                            pattern: {
                                value: /^[a-zA-ZáéíóúÁÉÍÓÚ0-9,. ]+$/,
                                message: 'No se permiten caracteres especiales',
                            },
                        })}
                        maxLength={160}
                        rows={6}
                    />
                    <Text Color="red" FontSize="0.75rem">
                        {errors?.incidence?.message}
                    </Text>
                </ContainerFlex>
            </ContainerForm>
            <ContainerFlex Justify="center" Padding="1.938rem 0 0 0">
                <ButtonGeneral
                    width="10.438rem"
                    text="Guardar y Cerrar"
                    hColor="#414042"
                    clic={handleSubmit(onSubmit)}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
