import React from 'react';
import {Img, Text} from '@PageNotFound/styles';
import IconInprocess from '@images/handyman.svg';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';

export const PageNotFound = () => {
    return (
        <ContainerFlex>
            <Img src={IconInprocess} />
            <Text>En construcción</Text>
        </ContainerFlex>
    );
};
