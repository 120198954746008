export const contentStyles = {
    Position: 'absolute',
    Height: '',
    PositionTop: '65%',
    PositionLeft: '0',
    Width: '96%',
    BoxShadow: '',
    BoxS: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
    backG: '#fff',
    FlexDir: 'column',
    OverFlow: 'auto',
    Justify: 'start',
    MaxH: '13rem',
    Radius: '0.5rem',
    ZIndex: '2',
};
export const textApprasialTotal = {
    FontSize: '0.75rem',
    Color: '#54575C',
    Height: 'auto',
};
export const anotherContentStyles = {
    Position: 'absolute',
    Height: 'auto',
    Width: '100%',
    BoxShadow: '',
    BoxS: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
    backG: '#fff',
    FlexDir: 'column',
    OverFlow: 'auto',
    Justify: 'start',
    MaxH: '13rem',
    Radius: '0.5rem',
    ZIndex: '6',
    top: '',
};

export const styleSelect = {
    Width: '100%',
    Height: '40px',
    TransformO: 'capitalize',
    Transform: 'capitalize',
    HBorder: '1px solid #E8E9EA',
    FBorder: '1px solid #E8E9EA',
};

export const content = {
    Display: 'grid',
    GridColumns: 'repeat(3, 31.5%)',
    Gap: '1.5rem',
};

export const selectContent = {
    FlexDir: 'column',
    Align: 'start',
    Height: 'auto',
    Justify: 'space-between',
};

export const level = {
    FontSize: '0.75rem',
    FontWeight: '500',
    Padding: '0 0 0.25rem 0',
    MaxH: '12.5rem',
    Radius: '0.5rem',
    wSpace: 'normal',
};

export const contentAppraisal = {
    FlexDir: 'column',
    Align: 'start',
    Justify: 'start',
};

export const textApprasial = {
    FontSize: '0.75rem',
    Color: '#54575C',
    FontWeight: '500',
    Padding: '0 0 0.25rem 0',
};

export const textPercentage = {
    Width: '100%',
    Color: '#2A2C2F',
    Padding: '0.5rem 1rem',
};

export const tabsBlock = {
    Padding: '0.5rem 1rem',
    Height: '40px',
    Cursor: 'pointer',
    FontWeight: '500',
};

export const miniContent = {
    Align: 'start',
    FlexDir: 'column',
    Gap: '0.5rem',
    Justify: 'space-between',
    Height: 'auto',
};

export const titleMinicontent = {
    FontSize: '0.875rem',
    Color: '#71767D',
    Height: '14px',
};

export const tabsMinicontnt = {
    backG: '#FAFAFA',
    Radius: '0.5rem',
    Gap: '1rem',
    Align: 'start',
    FlexDir: 'column',
    Padding: '0.5rem',
    Justify: 'start',
    Height: '60px',
};

export const subtitles = {
    FontSize: '0.75rem',
    Color: '#54575C',
};

export const textValues = {
    Color: '#54575C',
    FontWeight: '500',
};

export const contentList = {
    Cursor: 'pointer',
    Height: '2.5rem',
    Padding: '0.75rem 0.5rem',
    Bb: '1px solid #E8E9EA',
};
export const contentListBorderLess = {
    Cursor: 'pointer',
    Height: '2.5rem',
    Padding: '0.75rem 0.5rem',
};
export const ResultList = {
    Cursor: 'pointer',
    Height: '2.5rem',
};

export const newContentList = {
    Cursor: 'pointer',
    Height: '2.5rem',
    Padding: '0.75rem 0.5rem',
};

export const wordSearch = {
    Cursor: 'pointer',
    FontSize: '0.875rem',
    Color: '#414042',
    Justify: 'start',
    Width: '100%',
};

export const iconWarning = {
    Height: 'auto',
    Padding: '0.75rem 0.5rem',
    Justify: 'start',
    Bb: '1px solid #E8E9EA',
};

export const iconContent = {
    Radius: '1.5rem',
    alt: 'icon-warning',
    Cursor: 'pointer',
    Margin: '0 1rem 0 0',
};

export const message = {
    Padding: '1rem',
    Gap: '1rem',
    Align: 'start',
    FlexDir: 'column',
};

export const openSection = {
    Cursor: 'pointer',
    Color: '#5A5AFF',
    FontWeight: '500',
};

export const Btn = {
    BorderRadius: '2rem',
    FontWeight: '400',
    Cursor: 'pointer',
    Padding: '0.5rem 1rem',
};

export const iconstBtn = {
    className: 'material-icons',
    Cursor: 'pointer',
    FontSize: '1.5rem',
};

export const iconSearch = {
    className: 'material-icons',
    FontSize: '1.5rem',
    Color: '#5A5AFF',
    Cursor: 'pointer',
};
