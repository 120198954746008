import React from 'react';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import {
    Paginate,
    PaginationInfo,
    PaginationDetail,
} from '@General/Atoms/ListPagination/Styles/styles';
import {PaginationProps} from '@components/General/Atoms/ListPagination/interfaces';
import {ButtonPager} from '@components/Users/styles';
import firtPageArrow from '@components/Branches/Images/FirstPageArrow.svg';
import previousArrowPager from '@components/Branches/Images/PreviousPageArrow.svg';
import nextArrowPager from '@components/Branches/Images/NextPageArrow.svg';
import lastPageArrow from '@components/Branches/Images/LastPageArrow.svg';

const Pagination: React.FC<PaginationProps> = ({
    pageNumber,
    totalPages,
    totalItems,
    pageSize,
    displayPageNumbers = false,
    onPageChange,
}) => {
    const offset = (pageNumber - 1) * pageSize;
    const handlePageClick = ({selected}: {selected: number}) => {
        onPageChange(selected + 1);
    };

    const startItem = offset + 1;
    const endItem = Math.min(offset + pageSize, totalItems);

    const previousLabel = (
        <Image src={previousArrowPager} alt="left-arrow" Width="16px" Height="16px" />
    );

    const nextLabel = <Image src={nextArrowPager} alt="right-arrow" Width="16px" Height="16px" />;

    const firstPageButton = (
        <ButtonPager
            BGColor="transparent"
            disabled={pageNumber === 1}
            Padding="4px 0px 0px"
            onClick={() => onPageChange(1)}
        >
            <Image src={firtPageArrow} alt="far-left-arrow" Width="16px" Height="16px" />
        </ButtonPager>
    );
    const prevPageButton = (
        <ButtonPager
            BGColor="transparent"
            disabled={pageNumber === 1}
            Padding="4px 0px 0px"
            onClick={() => handlePageClick({selected: pageNumber - 2})}
        >
            {previousLabel}
        </ButtonPager>
    );
    const nextPageButton = (
        <ButtonPager
            BGColor="transparent"
            disabled={pageNumber === totalPages}
            Padding="4px 0px 0px"
            onClick={() => handlePageClick({selected: pageNumber})}
        >
            {nextLabel}
        </ButtonPager>
    );

    const lastPageButton = (
        <ButtonPager
            BGColor="transparent"
            disabled={pageNumber === totalPages}
            Padding="4px 0px 0px"
            onClick={() => onPageChange(totalPages)}
        >
            <Image src={lastPageArrow} alt="far-right-arrow" Width="16px" Height="16px" />
        </ButtonPager>
    );

    const detailText = (
        <PaginationDetail>
            <span>
                {startItem}-{endItem} de {totalItems}
            </span>
        </PaginationDetail>
    );

    return (
        <ContainerFlex Gap="0.5rem" Bt="1px solid #E8E9EA" Height="2.5rem">
            {totalItems > 0 && (
                <PaginationInfo>
                    {displayPageNumbers ? (
                        <>
                            {firstPageButton}
                            <Paginate
                                previousLabel={previousLabel}
                                nextLabel={nextLabel}
                                pageCount={totalPages}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClick}
                                forcePage={pageNumber - 1}
                                containerClassName={'pagination'}
                                previousClassName={'page-item-prev'}
                                nextClassName={'page-item-next'}
                                pageClassName={'pagination-page'}
                                activeClassName={'selected'}
                                breakClassName={'pagination-break'}
                                disabledClassName={'disabled'}
                            />
                            {lastPageButton}
                            {detailText}
                        </>
                    ) : (
                        <>
                            {firstPageButton}
                            {prevPageButton}
                            {detailText}
                            {nextPageButton}
                            {lastPageButton}
                        </>
                    )}
                </PaginationInfo>
            )}
        </ContainerFlex>
    );
};

export default Pagination;
