import React from 'react';
import fire from '@images/cons.svg';
import newPaper from '@images/new.svg';
import cup from '@images/cup.svg';
import {IIcon} from '@components/Home/Admin/interface';

import {Image} from '@Shopify/Ecommerce/styles';

export const IconSelector = ({type}: IIcon) => {
    return (
        <>
            {type === 'Fundición' && <Image src={fire} alt="arrow-arbol-up" />}
            {type === 'Auditorías' && <Image src={newPaper} alt="arrow-arbol-up" />}
            {type === 'Reactivaciones' && <Image src={cup} alt="arrow-arbol-up" />}
        </>
    );
};
