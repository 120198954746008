export const renewalMenuStyles = {
    Padding: '0.5rem',
    Width: '100%',
    Align: 'center',
    FontSize: '1rem',
    FontWeight: '400',
    LetterSpacing: '0.019rem',
    Cursor: 'default',
    BorderL: '2px solid transparent',
    HColor: '#5A5AFF',
    HBackColor: '#F0F0FF',
    HBorderL: '2px solid #5A5AFF',
};
