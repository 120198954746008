import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, ImgAvatar, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {RootState, AppDispatch} from '@config/store';
import {GetDataContactPonzu} from '@MyCompany/Redux/Actions/GetDataContactPonzu';
import phonePlus from '@images/phonePlus.svg';
import mail from '@images/mail.svg';
import {companyFlowMdoals} from '@MyCompany/Interface';

export default function ContactAdviser({setFlowModalChange, setModalState}: companyFlowMdoals) {
    const dispatch: AppDispatch = useDispatch();
    const GetDataContactsPonzu = useSelector((state: RootState) => {
        return state.GetDataContactPonzu.data;
    });

        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const selectChange = async () => {
        if (token) {
            dispatch(GetDataContactPonzu(token));
        }
    };
    useEffect(() => {
        selectChange();
    }, []);

    return (
        <>
            <ContainerFlex FlexDir="column" Padding="0 24px 24px 24px">
                <Text FontSize="0.875rem" FontWeight="500">
                    Por favor, ponte en contacto con un asesor.
                </Text>
                <ContainerFlex
                    Border="solid 1px #e5e7e9"
                    FlexDir="column"
                    Radius="5px"
                    Justify=""
                    Align="start"
                    MarginTop="24px"
                    MarginBt="86.4px"
                >
                    <Text
                        FontSize="1rem"
                        Color="#414042"
                        FontWeight="500"
                        Margin="10px 0  0 16px "
                        MarginB="10px"
                    >
                        Contacto Ponzu
                    </Text>
                    <ContainerFlex
                        Justify="start"
                        backG="rgba(243, 243, 243, 0.4)"
                        FlexWrap="Wrap"
                        Bt="solid 1px #e5e7e9"
                        Color="#414042"
                    >
                        <ImgAvatar src={mail} Width="6%" Margin=" 0 18.7px 0 16px" Height="50px" />
                        <Text Color="#414042" FontSize="1.25rem" FontWeight="500" MarginRight="50%">
                            {GetDataContactsPonzu.mail}
                        </Text>
                        <ImgAvatar
                            src={phonePlus}
                            Width="6%"
                            Margin="0 18.7px 0 16px"
                            Height="50px"
                        />
                        <Text Color="#414042" FontSize="1.25rem" FontWeight="500">
                            {GetDataContactsPonzu.phone}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ButtonGeneral
                    color="#414042"
                    width="4.6875rem"
                    height="40px"
                    text="Cerrar"
                    type="submit"
                    transform=""
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hBorder="#35cf44"
                    FontSize="0.875rem"
                    clic={() => {
                        setModalState(false);
                        setFlowModalChange(0);
                    }}
                    border="1px solid #414042"
                />
            </ContainerFlex>
        </>
    );
}
