import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {TABLE_FILTERS} from '@components/LoansProducts/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {setActiveStatus} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';

const StatusFilter = () => {
    const dispatch = useDispatch();
    const {activeStatus} = useSelector((state: RootState) => {
        return state.getProductsCustomerUserReducer;
    });
    const getRadius = (index: number, status: string): string => {
        if (activeStatus === status && index === 0) return '8px 0px 0px 8px';
        if (activeStatus === status && index === TABLE_FILTERS.length - 1) return '0px 8px 8px 0px';
        return '';
    };

    return (
        <ContainerFlex Radius="0.5rem" Border="1px solid #E8E9EA" Width="483px" Height="48px">
            {TABLE_FILTERS.map((data, index) => {
                const radius = getRadius(index, data.label);
                const Br = index !== TABLE_FILTERS.length - 1 ? '1px solid #E8E9EA' : '';
                const Border = activeStatus === data.label ? '1px solid #5A5AFF' : '';
                const backG = activeStatus === data.label ? '#F0F0FF' : '#fff';
                const Color = activeStatus === data.label ? '#5A5AFF' : '#1D1E20';
                const FontWeight = activeStatus === data.label ? '700' : '400';
                return (
                    <ContainerFlex
                        key={index}
                        Flex="1 0 0"
                        Justify="center"
                        Padding="0.75rem 1rem"
                        Br={Br}
                        Border={Border}
                        backG={backG}
                        Radius={radius}
                        Cursor="pointer"
                        onClick={() => dispatch(setActiveStatus(data.label))}
                    >
                        <Text
                            FontFamily="Nunito"
                            Color={Color}
                            FontWeight={FontWeight}
                            Cursor="pointer"
                        >
                            {data.label}
                        </Text>
                    </ContainerFlex>
                );
            })}
        </ContainerFlex>
    );
};

export default StatusFilter;
