import {ARTICLE} from '@/components/Articles/Redux/Types';
import {AxiosResponse} from 'axios';

const INITIAL_STATE = {
    Group: [],
    Family: [],
    Subfamily: [],
    Brand: [],
    Article: [],
    Error: null,
    statelevel: false,
    loading: false,
};

const ArticleRoutes = (state = INITIAL_STATE, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case ARTICLE.GROUP:
            return {
                ...state,
                Group: action.payload,
            };
        case ARTICLE.FAMILY:
            return {
                ...state,
                Family: action.payload,
            };
        case ARTICLE.SUBFAMILY:
            return {
                ...state,
                Subfamily: action.payload,
            };
        case ARTICLE.BRAND:
            return {
                ...state,
                Brand: action.payload,
            };
        case ARTICLE.ARTICLES:
            return {
                ...state,
                Article: action.payload,
            };
        case ARTICLE.LEVEL_STATUS:
            return {
                ...state,
                loading: true,
            };
        case ARTICLE.LEVEL_STATUS_SUCCESS:
            return {
                ...state,
                statelevel: action.payload,
                loading: false,
            };
        case ARTICLE.DELETE_LEVEL_ROUTE:
            return {
                ...state,
                Group: [],
                Family: [],
                Subfamily: [],
                Brand: [],
                Article: [],
            };
        case ARTICLE.ERROR:
            return {
                ...state,
                Error: action.payload,
            };
        default:
            return state;
    }
};
export default ArticleRoutes;
