import {AxiosError, AxiosResponse} from 'axios';
import {
    CAT_LABOR_OLD_START,
    CAT_LABOR_OLD_SUCCESS,
    CAT_LABOR_OLD_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataLaborProps} from '@components/PersonalLoans/interfaces';

const initialState: IDataLaborProps = {
    dataLabor: null,
    loading: false,
    error: false,
    errorData: null,
};

const GetAllCatLaborOld = (
    state: IDataLaborProps = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataLaborProps => {
    switch (action.type) {
        case CAT_LABOR_OLD_START:
            return {...state, loading: true, error: false};
        case CAT_LABOR_OLD_SUCCESS:
            return {...state, loading: false, error: false, dataLabor: action.payload.data.data};
        case CAT_LABOR_OLD_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default GetAllCatLaborOld;
