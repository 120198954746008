import React from 'react';
import {NavLink} from 'react-router-dom';
import '@Promociones/styles.css';
import {listPromo} from '@Electronics/listElectronics.js';
import {FormatNumber} from '@Electronics/index';

const Promociones = () => {
    const productsPromo = listPromo.info.results[0].data;

    return (
        <>
            <div className=" row border-bottom title-promo d-flex">
                <p>Promociones</p>
                <p>
                    <NavLink exact to="#" className="link-title">
                        Ver mas...
                    </NavLink>
                </p>
            </div>
            {productsPromo.length ? (
                <div className="row width-promo">
                    {productsPromo.map((element, index) => {
                        return (
                            <div
                                className="col-lg-5 col-md-3 col-sm-6 border-sides mt-4"
                                key={index}
                            >
                                <div
                                    className="card-img"
                                    style={{backgroundImage: `url(${element.img})`}}
                                />
                                <span className="txt-title mt-2">{element.category}</span>
                                <p className="description">{element.name}</p>
                                <div className="row">
                                    <div className="col-6 ">
                                        <p>Apartado</p>
                                        <span>{FormatNumber(element.apart)}</span>
                                    </div>
                                    <div className="col-6">
                                        <p>Precio</p>
                                        <p className="price">{FormatNumber(element.sale)}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

export default Promociones;
