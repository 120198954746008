import {GET_FLOW, GET_FLOW_SUCCESS, GET_FLOW_ERROR} from '@ReduxsCashFlow/Types/Types';
const initialState = {
    data: {
        tokenExpiration: 60,
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        totalItems: 0,
        token: '',
        errors: null,
        message: null,
        succeeded: true,
        data: [
            {
                typeSearch: '',
                id: 0,
                branchId: '',
                branchName: '',
                zoneId: '',
                zoneName: '',
                employeeUserId: 0,
                employeUserName: '',
                date: '',
                amount: 0,
                amountAcepted: 0,
                motiveId: 0,
                motiveName: '',
                statusId: 0,
                statusName: '',
                observations: '',
                urlDeposited: '',
                urlReceived: '',
                urlVoucher: '',
                companyId: 0,
            },
        ],
    },
    pageNumber: [],
    totalPage: [],
    loading: false,
    error: false,
};
export interface IFoundry {
    data: ICashFlowData;
    pageNumber: [];
    totalPage: number;
    loading: boolean;
    error: boolean;
}
export interface ICashFlowData {
    tokenExpiration: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
    token: string;
    errors: null | string[];
    message: null | string;
    succeeded: boolean;
    data: DataItem[];
}

interface DataItem {
    typeSearch: string;
    id: number;
    branchId: string;
    branchName: string;
    zoneId: string;
    zoneName: string;
    employeeUserId: number;
    employeUserName: string;
    date: string;
    amount: number;
    amountAcepted: number;
    motiveId: number;
    motiveName: string;
    statusId: number;
    statusName: string;
    observations: string;
    urlDeposited: string;
    urlReceived: string;
    urlVoucher: string;
    companyId: number;
}
const getAllFlow = (
    state = initialState,
    action: {type: string; payload: {data: ICashFlowData}}
) => {
    switch (action.type) {
        case GET_FLOW:
            return {
                ...state,
                loading: true,
            };
        case GET_FLOW_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_FLOW_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getAllFlow;
