import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {
    GET_ALL_USERS_START,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_FINISH,
    POST_UPDATE_STATUS_USER_START,
    POST_UPDATE_STATUS_USER_SUCCESS,
    POST_UPDATE_STATUS_USER_ERROR,
    POST_CREATEUSER_EMPLOYEE_START,
    POST_CREATEUSER_EMPLOYEE_SUCCESS,
    POST_CREATEUSER_EMPLOYEE_ERROR,
    ADD_USER_NAME_DELETE,
    REMOVE_USER_NAME_DELETE,
    RESET_DELETE_USER_SUCCEEDED,
    RESET_GET_ALL_USERS,
    PUT_UPDATE_REMOVE_MESSAGE,
    PUT_UPDATE_USER_ADD_MESSAGE,
    POST_UPDATE_STATUS_USER_FINISH,
    POST_CREATEUSER_EMPLOYEE_FINISH,
    ADD_MESSAGGE_ALERT,
    RESET_MESSAGGE_ALERT,
} from '@components/CreateUsers/Redux/Types/Types';
import {resetOptionsUserNames} from '@components/CreateUsers/Redux/Actions/GetListUserNames';
import {ResetRandomPassword} from '@components/CreateUsers/Redux/Actions/PostChangePassword';
import {ISaveUsers} from '@components/CreateUsers/Interface';

export const fetchGetAllUsersStart = () => {
    return {
        type: GET_ALL_USERS_START,
    };
};

export const fetchGetAllUsersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: result,
    };
};

export const fetchGetAllUsersError = () => {
    return {
        type: GET_ALL_USERS_ERROR,
    };
};
export const fetchGetAllUsersFinish = () => {
    return {
        type: GET_ALL_USERS_FINISH,
    };
};

export const addAlertMessagge = (messagge: string, name: string) => {
    return {
        type: ADD_MESSAGGE_ALERT,
        messagge: messagge,
        name: name,
    };
};
export const resetAlertMessagge = () => {
    return {
        type: RESET_MESSAGGE_ALERT,
    };
};

export function GetAllUsers(
    token: string,
    filters: {
        searchName: string;
        orderType: number;
        filterUser?: number;
        companyId: number;
        pageSize: number;
        pageNumber: number;
        levelsId?: number[];
    }
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAllUsersStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllUsers}`,
                filters,
                {headers}
            );

            dispatch(fetchGetAllUsersSuccess(response));
        } catch (error) {
            dispatch(fetchGetAllUsersError());
        } finally {
            dispatch(fetchGetAllUsersFinish());
        }
    };
}

export const fetchPostUpdateUserStart = () => {
    return {
        type: POST_UPDATE_STATUS_USER_START,
    };
};
export const fetchPostUpdateUserSuccess = (result: AxiosResponse) => {
    return {
        type: POST_UPDATE_STATUS_USER_SUCCESS,
        payload: result,
    };
};

export const fetchPostUpdateUserError = () => {
    return {
        type: POST_UPDATE_STATUS_USER_ERROR,
    };
};

export const fetchPostUpdateUserFinish = () => {
    return {
        type: POST_UPDATE_STATUS_USER_FINISH,
    };
};

export const addUserNameDelete = (name: string) => {
    return {
        type: ADD_USER_NAME_DELETE,
        name: name,
    };
};

export const removeUserNameDelete = () => {
    return {
        type: REMOVE_USER_NAME_DELETE,
    };
};

export function postUpdateUser(
    userEmployeeId: number,
    Status: boolean,
    isDelete: boolean,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostUpdateUserStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateStatusUser}?userEmployeeId=${userEmployeeId}&Status=${Status}&isDelete=${isDelete}`,
                [],
                {headers}
            );
            await dispatch(fetchPostUpdateUserSuccess(response));
        } catch (error) {
            dispatch(fetchPostUpdateUserError());
        } finally {
            dispatch(fetchPostUpdateUserFinish());
        }
    };
}

export const fetchPostCreateUserStart = () => {
    return {
        type: POST_CREATEUSER_EMPLOYEE_START,
    };
};
export const fetchPostCreateUserSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CREATEUSER_EMPLOYEE_SUCCESS,
        payload: result,
    };
};

export const fetchPostCreateUserError = () => {
    return {
        type: POST_CREATEUSER_EMPLOYEE_ERROR,
    };
};

export const fetchPostCreateUserFinish = () => {
    return {
        type: POST_CREATEUSER_EMPLOYEE_FINISH,
    };
};

export function postCreateUsers(data: ISaveUsers, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostCreateUserStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateUserEmployee}`,
                data,
                {headers}
            );
            await dispatch(fetchPostCreateUserSuccess(response));
            dispatch(resetOptionsUserNames());
            dispatch(ResetRandomPassword());
        } catch (error) {
            dispatch(fetchPostCreateUserError());
        } finally {
            dispatch(fetchPostCreateUserFinish());
        }
    };
}

export const resetDeleteUserSucceeded = () => {
    return {
        type: RESET_DELETE_USER_SUCCEEDED,
    };
};

export const resetGetAllUsers = () => {
    return {
        type: RESET_GET_ALL_USERS,
    };
};

export const addMessageUpdateUserChange = (name: string) => {
    return {
        type: PUT_UPDATE_USER_ADD_MESSAGE,
        name: name,
    };
};

export const removeMessageUpdateUserChange = () => {
    return {
        type: PUT_UPDATE_REMOVE_MESSAGE,
    };
};
