import React, {ChangeEvent, useState, useEffect, useRef} from 'react';
import {Controller, FieldErrors} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {PROOF_LABELS, FORM_ERRORS} from '@components/Shopify/ListClient/constants';
import {getIdentificationTypesList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {ProofType} from '@components/Shopify/ListClient/interfaces';

import {AppDispatch, RootState} from '@config/store';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import * as style from '@Quoter/stylesConstants';
import * as content from '@components/CreateUsers/Styles';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import Refresh from '@images/icon-refresh.svg';
import {IconSvg} from '@/components/Quoter/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {validateProofAddressClient} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {IValidateProofAddress} from '@components/Shopify/ListClient/redux/interfaces';
import {
    ContainerFlex,
    ContainerForm,
    Text,
    ContainerCellGrid,
    Image,
} from '@Shopify/Ecommerce/styles';
import CustomDatePicker from '@components/Shopify/ListClient/DatePicker';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {IconContainer} from '@/components/Quoter/styles';

const getImageError = (
    errorMessage: string,
    errors: FieldErrors,
    imageSrc: string | null,
    hasSelected: boolean
): string => {
    if (errorMessage && hasSelected) {
        return errorMessage;
    }
    if (errors.imageProof && !imageSrc) {
        return errors.imageProof.message as string;
    }
    if (imageSrc) {
        return '';
    }
    return '';
};

const ClientProof = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const {token, errors, control, setValue} = props;
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selectedImage, setSelectedImage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const proofTypes: ProofType[] | null = useSelector(
        (state: RootState) => state.newClientProcess.proofTypes
    );
    const loading: boolean = useSelector((state: RootState) => state.newClientProcess.loading);
    let formattedProofTypes: {value: number; label: string}[] = [];

    if (proofTypes) {
        formattedProofTypes = proofTypes.map((proofType: ProofType) => ({
            value: proofType.id,
            label: proofType.description,
        }));
    }

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    useEffect(() => {
        dispatch(getIdentificationTypesList(token));
    }, [dispatch, token]);

    const clearSelection = () => {
        setImageSrc(null);
        setImageFileName('');
        setValue('imageProof', '');
        setValue('imageFileExtension', '');
    };

    const imgAdd = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(true);
            const file = e.target.files[0];
            const maxSizeMB = 25;
            const maxSizeBytes = maxSizeMB * 1024 * 1024;

            if (file.size > maxSizeBytes) {
                setErrorMessage(`Archivo no debe de exeder ${maxSizeMB} MB`);
                setSelectedImage(true);
                clearSelection();
                return;
            }

            setSelectedImage(true);
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (!fileExtension || !['jpg', 'png'].includes(fileExtension)) {
                setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                clearSelection();
                return;
            }
            setErrorMessage('');
            setImageFileName(file.name);

            const readFileAsDataURL = (file: File): Promise<string> => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = (error) => reject(error);
                });
            };

            try {
                const fileContent = await readFileAsDataURL(file);
                const base64Content = fileContent.split(',')[1];

                const imageData: IValidateProofAddress = {
                    fileExtension: fileExtension,
                    fileContent: base64Content,
                };

                await dispatch(validateProofAddressClient(imageData, token));
                setImageSrc(fileContent);
                setValue('imageProof', base64Content);
                setValue('imageFileExtension', fileExtension);
            } catch (error) {
                setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                clearSelection();
            }
        }
    };

    const currentError = getImageError(errorMessage, errors, imageSrc, selectedImage);

    return (
        <ContainerFlex FlexDir="column" Bb="1px solid #E8E9EA" Align="start">
            <Text FontWeight="500" FontSize="1.2rem" Color="#2a2c2f">
                {PROOF_LABELS.ADD_PROOF}
            </Text>
            <ContainerForm
                FlexDir="column"
                Align="start"
                autoComplete="off"
                MarginT="1rem"
                Gap="1.5rem"
                Width="100%"
            >
                {loading && (
                    <ContainerFlex Position="absolute" backG="#ffffff87" ZIndex="1000">
                        <LoadingAtaskate width="60px" padding="15px" />
                    </ContainerFlex>
                )}
                <ContainerFlex
                    Padding="0.75rem 1rem 1rem"
                    Gap="24px"
                    backG="#FAFAFA"
                    Radius="1rem"
                    Align="flex-start"
                    Justify="flex-start"
                >
                    <ContainerFlex
                        Justify="center"
                        Align="start"
                        FlexDir="column"
                        Gap="1.5rem"
                        Width="300px"
                        Height="216px"
                    >
                        <FileUpLoad
                            accept=".jpg,.png"
                            type="file"
                            onChange={imgAdd}
                            id="file"
                            ref={fileInputRef}
                        />
                        <FileUpLoadContent
                            {...content.contentImg}
                            Width="273px"
                            Height="152px"
                            RABorder="0"
                            Display="flex"
                            Align="center"
                            FDirection="column"
                            Border={!imageSrc ? '1px dashed #5A5AFF' : '1px dashed #d4d6d8;'}
                        >
                            {imageSrc ? (
                                <>
                                    <Image
                                        src={imageSrc}
                                        {...content.contentImg.imgStart}
                                        Height="100%"
                                        Width="100%"
                                        ObjectFit="contain"
                                        Radius="0"
                                        Margin="0"
                                        Cursor={imageSrc ? 'pointer' : 'default'}
                                    />
                                </>
                            ) : (
                                <ContainerFlex
                                    Justify="center"
                                    Align="center"
                                    FlexDir="column"
                                    Gap="6px"
                                >
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="500"
                                        FontSize="16px"
                                        LHeight="1.7"
                                        wSpace="normal"
                                        Cursor="pointer"
                                        Gap="8px"
                                    >
                                        <IconContainer Color="#5A5AFF" className="material-icons">
                                            publish
                                        </IconContainer>
                                        {PROOF_LABELS.SELECT_IMAGE}
                                    </Text>
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="400"
                                        FontSize="16px"
                                        LHeight="1.7"
                                        wSpace="normal"
                                        Cursor="pointer"
                                    >
                                        {PROOF_LABELS.IMAGE_SIZE}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </FileUpLoadContent>
                        {imageSrc && (
                            <ButtonGenerals
                                type="button"
                                Height="28px"
                                Padding="2px 8px"
                                Gap="4px"
                                BackGC="#e8e9ea"
                                Cursor="pointer"
                                Width="17rem"
                                HBackG="#999a9c"
                                onClick={handleButtonClick}
                            >
                                <Text
                                    FontWeight="500"
                                    FontSize="16px"
                                    Color="#2a2c2f"
                                    oFlow="hidden"
                                    tOver="ellipsis"
                                    wSpace="nowrap"
                                    Cursor="pointer"
                                >
                                    {imageFileName}
                                </Text>
                                <Image src={Refresh} alt="add-client" Width="24px" Height="24px" />
                            </ButtonGenerals>
                        )}
                        <Text Color="red" FontWeight="400" FontSize="0.875rem" Margin="auto">
                            {currentError}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        FlexDir="column"
                        Gap="1.5rem"
                        Width="65%"
                    >
                        {errorMessage === FORM_ERRORS.PROOF_TYPE && (
                            <ContainerFlex Justify="start" Align="start" Gap="8px" Height="auto">
                                <IconSvg Height="16px" width="16px">
                                    <path
                                        d="M4.26732 12.6666L3.33398 11.7333L7.06732 7.99998L3.33398 4.26665L4.26732 3.33331L8.00065 7.06665L11.734 3.33331L12.6673 4.26665L8.93398 7.99998L12.6673 11.7333L11.734 12.6666L8.00065 8.93331L4.26732 12.6666Z"
                                        fill="#FF6357"
                                    />
                                </IconSvg>
                                <Text Color="#FF6357" FontSize=".875rem" wSpace="normal">
                                    {errorMessage}
                                </Text>
                            </ContainerFlex>
                        )}
                        <ContainerFlex {...style.selectContent} Width="70%">
                            <Text {...style.level} Color="#71767c">
                                {PROOF_LABELS.TYPE_OF_PROOF_OF_ADDRESS}
                            </Text>

                            <Controller
                                name="typeOfReceiptId"
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        hasError={!!errors.typeOfReceiptId?.message}
                                        options={formattedProofTypes}
                                        isLoading={!formattedProofTypes}
                                        onChange={(selectedOption) => {
                                            setValue('typeOfReceiptId', selectedOption);
                                            if (onChange) onChange(selectedOption);
                                        }}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.typeOfReceiptId?.message as string}
                            </Text>
                        </ContainerFlex>

                        <ContainerCellGrid
                            AlignContent="start"
                            FlexDir="column"
                            Height="80px"
                            Width="70%"
                        >
                            <Text {...style.level} Color="#71767c">
                                {PROOF_LABELS.EFFECTIVE_DATE}
                            </Text>
                            <CustomDatePicker
                                name="effectiveDate"
                                placeholder="dd/mm/yyyy"
                                openOnError={true}
                                width="100%"
                            />
                        </ContainerCellGrid>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerForm>
        </ContainerFlex>
    );
};

export default ClientProof;
