import axios, {AxiosResponse} from 'axios';
import {
    GET_CASHFLOW_SETUP,
    GET_CASHFLOW_SETUP_ERROR,
    GET_CASHFLOW_SETUP_FINISH,
    GET_CASHFLOW_SETUP_RESET,
    GET_CASHFLOW_SETUP_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetCashFlowSetupStart = () => {
    return {
        type: GET_CASHFLOW_SETUP,
    };
};
export const fetchGetCashFlowSetupSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CASHFLOW_SETUP_SUCCESS,
        payload: result,
    };
};
export const fetchGetCashFlowSetupError = () => {
    return {
        type: GET_CASHFLOW_SETUP_ERROR,
    };
};
export const fetchGetCashFlowSetupFinish = () => {
    return {
        type: GET_CASHFLOW_SETUP_FINISH,
    };
};
export const fetchGetCashFlowSetupReset = () => {
    return {
        type: GET_CASHFLOW_SETUP_RESET,
    };
};

export function GetCashFlowSetup(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCashFlowSetupStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCashFlowSetup}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetCashFlowSetupSuccess(response));
        } catch (error) {
            dispatch(fetchGetCashFlowSetupError());
        } finally {
            dispatch(fetchGetCashFlowSetupFinish());
        }
    };
}
