export const DinamicPadding = (PaddingSize: number) => {
    const paddingMap: {[key: number]: string} = {
        1: '40px',
        2: '56px',
        3: '72px',
        4: '88px',
        5: '104px',
        6: '120px',
    };
    return paddingMap[PaddingSize];
};
export const BackGroundSelector = (PaddingSize: number) => {
    const backgroundMap: {[key: number]: string} = {
        1: '',
        2: '#FAFAFA',
        3: '#F4F5F5',
        4: '#E8E9EA',
        5: '#E8E9EA',
    };
    return backgroundMap[PaddingSize];
};

export const DinamicBorder = (BorderLevel: number) => {
    const borderMap: {[key: number]: string} = {
        1: '1px solid #E8E9EA',
        2: '1px solid #E8E9EA',
        3: '1px solid #E8E9EA',
        4: '1px solid white',
        5: '1px solid white',
    };
    return borderMap[BorderLevel];
};
