import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import pos from '@images/posRol.svg';
import {ROLES, styleValidate} from '@Roles/constants';
import admin from '@images/adminRol.svg';
import {Icons} from '@/components/Articles/style';
import * as styles from '@Roles/CreateRoles/stylesCreate';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ILevel, IPermissions, IShowTree} from '@Roles/AddRoles/interfaces';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {modulesPersis} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';
import {ContainerFlex, Text, TextEllipsis, Image} from '@Shopify/Ecommerce/styles';

export const PermisionsRole = ({setValue}: IPermissions) => {
    const {treePermissions, loading} = useSelector((state: RootState) => state.treePermissions);
    const {modules} = useSelector((state: RootState) => state.PersistenceRole);
    const [showTree, setShowTree] = useState<IShowTree>({openLevel: [], module: [], notify: []});
    const dispatch = useDispatch();

    const openLevels = (id: number) => {
        if (showTree.openLevel.includes(id))
            setShowTree({...showTree, openLevel: showTree.openLevel.filter((i) => i !== id)});
        if (!showTree.openLevel.includes(id))
            setShowTree({...showTree, openLevel: [...showTree.openLevel, id]});
    };

    const addModules = (module: ILevel) => {
        let idsToAdd: number[] = [];
        const addModuleAndChildren = (node: ILevel) => {
            idsToAdd = [...idsToAdd, node.moduleId];
            if (node.nextLevel && node.nextLevel.length > 0) {
                node.nextLevel.forEach((childModule) => {
                    if (childModule.nextLevel && childModule.nextLevel.length > 0)
                        addModuleAndChildren(childModule);
                    else idsToAdd = [...idsToAdd, childModule.moduleId];
                });
            }
        };
        addModuleAndChildren(module);
        const existingModules = idsToAdd.filter((moduleId) => !showTree.module.includes(moduleId));
        const parentModuleId = module.idFather;
        if (
            parentModuleId &&
            module.nextLevel &&
            module.nextLevel.every((child) => showTree.module.includes(child.moduleId))
        ) {
            existingModules.push(parentModuleId);
        }
        setShowTree({...showTree, module: [...showTree.module, ...existingModules]});
    };

    const delModules = (module: ILevel) => {
        let idsToDelete: number[] = [];
        const deleteModuleAndChildren = (node: ILevel) => {
            idsToDelete = [...idsToDelete, node.moduleId];
            if (node.nextLevel && node.nextLevel.length > 0) {
                node.nextLevel.forEach((childModule) => {
                    if (childModule.nextLevel && childModule.nextLevel.length > 0)
                        deleteModuleAndChildren(childModule);
                    else idsToDelete = [...idsToDelete, childModule.moduleId];
                });
            }
        };

        if (module.nextLevel && module.nextLevel.length > 0) deleteModuleAndChildren(module);
        if (module.nextLevel.length === 0)
            idsToDelete = [...idsToDelete, module.moduleId, module.idFather];
        const updatedModules: number[] = showTree.module
            .filter((moduleId) => !idsToDelete.includes(moduleId))
            .filter((item, index, array) => array.indexOf(item) === index);
        const updateNotifies = updatedModules.filter((item) => showTree.notify.includes(item));
        setShowTree({...showTree, module: updatedModules, notify: updateNotifies});
    };

    const addDelModules = (module: ILevel) => {
        if (!showTree.module.includes(module.moduleId)) addModules(module);
        else delModules(module);
    };
    const addNotifies = (module: ILevel) => {
        if (showTree.module.includes(module.moduleId)) {
            if (!showTree.notify.includes(module.moduleId))
                setShowTree({...showTree, notify: [...showTree.notify, module.moduleId]});
            else
                setShowTree({
                    ...showTree,
                    notify: showTree.notify.filter((i) => i !== module.moduleId),
                });
        }
    };

    const treeMap = useMemo(() => {
        const mapTree = (nextLevel: ILevel[]) => {
            if (nextLevel.length === 0) return null;

            return nextLevel.map((module) => (
                <ContainerFlex key={module.moduleId} FlexDir="Column">
                    {module.isHeader ? (
                        <ContainerFlex backG="#ACACFF">
                            <ContainerFlex
                                Justify="start"
                                backG="#ACACFF"
                                Padding="16px"
                                Height="30px"
                            >
                                <TextEllipsis
                                    title={module.moduleName}
                                    Color="#FFFFFF"
                                    FontWeight="400"
                                    Cursor="pointer"
                                >
                                    {module.moduleName}
                                </TextEllipsis>
                            </ContainerFlex>
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Display="grid" GridColumns="repeat(3, 1fr)" Gap="0.5rem">
                            <ContainerFlex
                                Justify="start"
                                Height="40px"
                                PaddingL={styleValidate(module, 'moreLevels')}
                                Cursor="pointer"
                                onClick={() => {
                                    if (module.nextLevel.length > 0) openLevels(module.moduleId);
                                }}
                            >
                                {module.nextLevel.length > 0 && (
                                    <Icons
                                        className="material-icons"
                                        Color="#5A5AFF"
                                        FontSize="1.5rem"
                                    >
                                        {showTree.openLevel.includes(module.moduleId)
                                            ? 'keyboard_arrow_up'
                                            : 'keyboard_arrow_down'}
                                    </Icons>
                                )}
                                {module.icon && (
                                    <ContainerFlex
                                        Radius="50%"
                                        backG="#E5E5FF"
                                        Width="1.688rem"
                                        Height="1.688rem"
                                        Margin="0 0.2rem 0 0"
                                        Padding="0.625rem"
                                    >
                                        <Image
                                            alt="module-img"
                                            src={module.icon}
                                            Width="1.125rem"
                                        />
                                    </ContainerFlex>
                                )}
                                <TextEllipsis
                                    title={module.moduleName}
                                    Color="#54575C"
                                    Width="10rem"
                                    FontWeight="400"
                                    Cursor="pointer"
                                >
                                    {module.moduleName}
                                </TextEllipsis>
                            </ContainerFlex>
                            <ContainerFlex MarginL={styleValidate(module, 'modules')}>
                                <Checkbox
                                    checked={showTree.module.includes(module.moduleId)}
                                    onChange={() => addDelModules(module)}
                                />
                            </ContainerFlex>
                            <ContainerFlex MarginL={styleValidate(module, 'alert')}>
                                <Checkbox
                                    checked={showTree.notify.includes(module.moduleId)}
                                    onChange={() => addNotifies(module)}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                    <ContainerFlex
                        Padding={module.nextLevel.length > 0 ? `0 0 0 1rem` : ''}
                        FlexDir="Column"
                        backG="#FAFAFF"
                    >
                        {showTree.openLevel.includes(module.moduleId) && treeMap(module.nextLevel)}
                    </ContainerFlex>
                </ContainerFlex>
            ));
        };

        return mapTree;
    }, [showTree]);

    const updateModules = () => {
        const modules = showTree.module
            .map((i) => ({
                moduleId: i,
                receiveAlerts: showTree.notify.includes(i),
            }))
            .filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.moduleId === value.moduleId)
            );
        setValue('modules', modules);
        dispatch(modulesPersis(modules));
    };

    const handlePersistece = () => {
        if (modules && modules.length > 0) {
            const newModules = modules.map((mod) => mod.moduleId);
            const newNotify = modules
                .map((mod) => (mod.receiveAlerts ? mod.moduleId : 0))
                .filter((notify) => notify !== 0);

            setShowTree({
                ...showTree,
                openLevel: newModules,
                module: newModules,
                notify: newNotify,
            });
        }
    };

    useEffect(() => {
        updateModules();
    }, [showTree.module.length, showTree.notify.length]);

    useEffect(() => {
        handlePersistece();
    }, [modules && modules.length]);
    useEffect(() => {
        if (treePermissions && treePermissions.length > 0) {
            const checkAndOpenLevels = (levels: ILevel[]) => {
                levels.forEach((level) => {
                    if (level.isHeader) {
                        setShowTree((prevState) => ({
                            ...prevState,
                            openLevel: [...prevState.openLevel, level.moduleId],
                        }));
                    }
                    if (level.nextLevel.length > 0) {
                        checkAndOpenLevels(level.nextLevel);
                    }
                });
            };

            treePermissions.forEach((treeAllow) => {
                checkAndOpenLevels(treeAllow.modules);
            });
        }
    }, [treePermissions, showTree]);
    return (
        <ContainerFlex {...styles.formContent}>
            <Text {...styles.title.subtitles}>{ROLES.PERMISIONS_ROLE.PERMISIONS}</Text>
            {loading ? (
                <ContainerFlex>
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                treePermissions &&
                treePermissions.length > 0 &&
                treePermissions.map((permission, index: number) => (
                    <ContainerFlex
                        key={index}
                        FlexDir="column"
                        Bb={
                            permission.permissionName === ROLES.TYPE_ROLE.POS
                                ? ''
                                : '1px solid #E8E9EA'
                        }
                        PaddingB={permission.permissionName === ROLES.TYPE_ROLE.POS ? '' : '1rem'}
                    >
                        <ContainerFlex Gap="0.5rem" Justify="start">
                            <Image
                                alt="module-img"
                                src={
                                    permission.permissionName === ROLES.TYPE_ROLE.POS ? pos : admin
                                }
                                Margin="0 0.2rem 0 0"
                            />
                            <Text Color="#2A2C2F" FontWeight="500" Height="1rem" Padding="1rem 0">
                                {permission.permissionName}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Display="grid"
                            GridColumns="repeat(3, 1fr)"
                            Gap="0.5rem"
                            Justify="center"
                        >
                            <Text
                                Color="#2A2C2F"
                                FontWeight="500"
                                Height="30px"
                                Padding="0 0 0 1.6rem"
                            >
                                {ROLES.PERMISIONS_ROLE.MODULE}
                            </Text>
                            <Text Color="#2A2C2F" FontWeight="500" Height="30px" Justify="center">
                                {ROLES.PERMISIONS_ROLE.ACCESS}
                            </Text>
                            <Text Color="#2A2C2F" FontWeight="500" Height="30px" Justify="center">
                                {ROLES.PERMISIONS_ROLE.NOTIFY}
                            </Text>
                        </ContainerFlex>
                        {treeMap(permission.modules)}
                    </ContainerFlex>
                ))
            )}
        </ContainerFlex>
    );
};
