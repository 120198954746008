import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import * as style from '@Shopify/ClientProfile/slylesClient';
import {ITabGarments} from '@Shopify/ClientProfile/interfaces';
import {CONTRACT_DETAIL} from '@Shopify/ClientProfile/constants';
import {ContainerFlex, Text, Image, ContainerGrid} from '@Shopify/Ecommerce/styles';
import {saveContractsImg} from '@Shopify/ClientProfile/Redux/Action/DetailsContract';

export const ContractTabArticles = ({setModalImg}: ITabGarments) => {
    const dispatch = useDispatch();
    const {details} = useSelector((state: RootState) => state.deatilContract);

    return (
        <ContainerFlex FlexDir="column" Align="start" Gap="1rem" Height="min-content">
            <Text Color="#000000" FontWeight="700" FontSize="1.25rem">
                {CONTRACT_DETAIL.GARMENTS}
            </Text>
            <ContainerFlex Padding="1.5rem" Gap="1.5rem" Border="1px solid #E8E9EA" Radius="0.5rem">
                {details &&
                    details.contractDetail &&
                    details.contractDetail.garmentsInTheContract &&
                    details.contractDetail.garmentsInTheContract.map((article) => (
                        <ContainerGrid key={article.garmentId} GridColumns="1fr 9fr" Gap="0.625rem">
                            <ContainerFlex
                                onClick={() => {
                                    setModalImg(true);
                                    dispatch(saveContractsImg(article.image));
                                }}
                                {...style.contentImg}
                                Radius="0.25rem"
                                Width="5.438rem"
                            >
                                <Image
                                    src={
                                        article.image.filter((img) => img.isPrincipal === true)[0]
                                            .urlImage
                                    }
                                    {...style.img}
                                    width="86rem"
                                    height="86rem"
                                    alt="garment"
                                />
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start">
                                <Text
                                    Padding="0.25rem 0 0.5rem 0"
                                    {...style.garments.titles}
                                    FontWeight="700"
                                >
                                    {article.garmentsName}
                                </Text>
                                <ContainerGrid
                                    Gap="1.5rem"
                                    GridColumns="3fr repeat(3,2fr)"
                                    Height="min-content"
                                    Padding="0.25rem 0 0.125rem 0"
                                    BorderTp="1px solid #E8E9EA"
                                >
                                    {CONTRACT_DETAIL.ARTICLES_HEADERS.map((header, index) => (
                                        <Text key={index} Color="#2A2C2F">
                                            {header}
                                        </Text>
                                    ))}
                                </ContainerGrid>
                                <ContainerGrid
                                    Gap="1.5rem"
                                    GridColumns="3fr repeat(3,2fr)"
                                    Height="min-content"
                                >
                                    <Text Color="#5A5AFF" FontWeight="600">
                                        {article.houseAndBranch}
                                    </Text>
                                    <Text {...style.garments.titles}>{article.appraisal}</Text>
                                    <Text {...style.garments.titles}>{article.loan}</Text>
                                    <Text {...style.garments.titles}>{article.terms}</Text>
                                </ContainerGrid>
                            </ContainerFlex>
                        </ContainerGrid>
                    ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};
