import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IconContainer, IconSvg} from '@/components/Quoter/styles';
import {yellowMsgIcon} from '@/components/LoginClient/constant';
import {LinkQuoter} from '@CashFlow/styles';

export const ErrorMessageClose = () => {
    const UserBranch = useSelector((state: RootState) => {
        return state.getUserDataBranch;
    });
    return (
        <ContainerFlex
            Height="auto"
            Width="50rem"
            Border="solid 1px #FFBD00"
            Radius="4px"
            Padding="8px 12px"
            backG="#fff"
            BoxS="4px 4px 4px 0 rgba(255, 189, 0, 0.3)"
        >
            <IconSvg
                Width="24px"
                Height="24px"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffbd00"
                xmlns="http://www.w3.org/2000/svg"
            >
                {yellowMsgIcon}
            </IconSvg>
            <ContainerFlex FlexDir="column" Align="flex-start" Padding="0px 16px">
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    Width="18.75rem"
                    TextAlign="start"
                    FontWeight="500"
                >
                    {UserBranch.error ? 'Monto excedente' : 'Ubicaciones pendientes'}
                </Text>
                {UserBranch.error ? (
                    <Text
                        FontSize="0.874rem"
                        Color="#414042"
                        Width="auto"
                        Height="40px"
                        wSpace="normal"
                        TextAlign="start"
                    >
                        El monto final supera el monto máximo de la sucursal. Favor de realizar un
                        depósito de excedente.
                    </Text>
                ) : (
                    <Text
                        FontSize="0.874rem"
                        Color="#414042"
                        Width="auto"
                        Height="40px"
                        wSpace="normal"
                        TextAlign="start"
                    >
                        Actualmente, tienes {UserBranch.Branch.pledge?.pledgeNumber} prendas
                        pendientes de ubicar. Te recomendamos que las ubiques o, si decides, también
                        puedes cerrar la sucursal.
                    </Text>
                )}
            </ContainerFlex>
            {!UserBranch.error && (
                <LinkQuoter to={'/NavLocations'}>
                    <ContainerFlex Width="9.375rem" Justify="start" Align="center">
                        <Text FontSize="0.874rem" Color="#35CF44" Height="2rem" Width="auto">
                            Ubicar prendas
                        </Text>
                        <IconContainer
                            Color="#35CF44"
                            FontSize="0.875rem"
                            className="material-icons"
                        >
                            keyboard_arrow_right
                        </IconContainer>
                    </ContainerFlex>
                </LinkQuoter>
            )}
        </ContainerFlex>
    );
};
