import {
    BENEFICIARY_DELETE,
    BENEFICIARY_DELETE_ERROR,
    BENEFICIARY_DELETE_SUCCESS,
} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingBeneficiaryDeleted: false,
    idBeneficiaryDeleted: 0,
};

const deleteDataBeneficiary = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case BENEFICIARY_DELETE:
            return {
                ...state,
                loadingBeneficiaryDeleted: true,
                error: false,
            };
        case BENEFICIARY_DELETE_SUCCESS:
            return {
                ...state,
                loadingBeneficiaryDeleted: false,
                idBeneficiaryDeleted:
                    state.idBeneficiaryDeleted !== 0 ? 0 : actions.payload.data.data,
            };
        case BENEFICIARY_DELETE_ERROR:
            return {
                ...state,
                error: false,
                loadingBeneficiaryDeleted: true,
            };
        default:
            return state;
    }
};

export default deleteDataBeneficiary;
