import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {CreateAccount} from '@components/Login/MarketPlaceLogin/CreateAccount';
import {CreateUserWithEmail} from '@Login/MarketPlaceLogin/CreateUserWithEmail';
import {TermAndConditions} from '@Login/MarketPlaceLogin/TermsAndConditions';
import {AtaskateHeader} from '@components/Login/MarketPlaceLogin/Ataskateheader';

export const LoginClient = () => {
    const Screen: {data: number} = useSelector((state: RootState) => state.getScreenSelect);

    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <AtaskateHeader />
            <ContainerFlex backG="#E5E5FF" FlexDir="column" Width="50%">
                {Screen.data === 1 && <CreateAccount />}
                {Screen.data === 2 && <CreateUserWithEmail />}
                <TermAndConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
