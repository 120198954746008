import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex} from '@EcommerceShopify/styles';
import {BUTTON_ACTION} from '@General/Atoms/CalendarDateSelection/constants';
import {CalendarButtonsProps} from '@General/Atoms/CalendarDateSelection/interfaces';

const CalendarButtons: React.FC<CalendarButtonsProps> = ({
    onCancel,
    onContinue,
    continueDisabled,
}) => {
    return (
        <ContainerFlex Padding="16px" Justify="center" Align="center" Gap="16px" Self="stretch">
            <ButtonGenerals
                Width="100%"
                Height="32px"
                Padding="4px 16px"
                Border="1px solid #5A5AFF"
                BackGC="#FFF"
                Justify="center"
                Color="#5A5AFF"
                HBackG="#0D166B"
                HColor="#FFF"
                FontWeight="500"
                type="button"
                FontSize="0.875rem"
                onClick={onCancel}
            >
                {BUTTON_ACTION.CANCEL}
            </ButtonGenerals>
            <ButtonGenerals
                Width="100%"
                Height="32px"
                Padding="4px 16px"
                type="button"
                FontWeight="500"
                FontSize="0.875rem"
                onClick={onContinue}
                disabled={continueDisabled}
            >
                {BUTTON_ACTION.CONTINUE}
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default CalendarButtons;
