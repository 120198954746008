import {
    GET_ALL_COOWNER,
    GET_ALL_COOWNER_SUCCESS,
    GET_ALL_COOWNER_ERROR,
} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    coOwners: 0,
};

const getCoOwners = (state = initialState, actions: {type: string; payload: AxiosResponse}) => {
    switch (actions.type) {
        case GET_ALL_COOWNER:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_COOWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                coOwners: state.coOwners !== 0 ? 0 : actions.payload.data.data,
            };
        case GET_ALL_COOWNER_ERROR:
            return {
                ...state,
                error: false,
                loading: true,
            };
        default:
            return state;
    }
};

export default getCoOwners;
