import {GellAllUsersState, ResponseData} from '@components/CreateUsers//Interface';
import {
    GET_ALL_USERS_START,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_FINISH,
    POST_UPDATE_STATUS_USER_START,
    POST_UPDATE_STATUS_USER_SUCCESS,
    POST_UPDATE_STATUS_USER_ERROR,
    POST_UPDATE_STATUS_USER_FINISH,
    POST_CREATEUSER_EMPLOYEE_START,
    POST_CREATEUSER_EMPLOYEE_SUCCESS,
    POST_CREATEUSER_EMPLOYEE_ERROR,
    ADD_USER_NAME_DELETE,
    REMOVE_USER_NAME_DELETE,
    RESET_DELETE_USER_SUCCEEDED,
    RESET_GET_ALL_USERS,
    POST_CREATEUSER_EMPLOYEE_FINISH,
    ADD_MESSAGGE_ALERT,
    RESET_MESSAGGE_ALERT,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState: GellAllUsersState = {
    contact: [],
    nameUserDelete: undefined,
    postDataUser: undefined,
    totalPages: 0,
    postcreateuser: {},
    data: null,
    loading: true,
    loadingUpdate: true,
    loadingUser: true,
    loadingCreate: true,
    isSuccess: false,
    error: false,
    totalUsers: 0,
    userUpdateChange: '',
    messageAlert: undefined,
};

const GetAllUsers = (state = initialState, action: ResponseData): GellAllUsersState => {
    switch (action.type) {
        case GET_ALL_USERS_START:
            return {
                ...state,
                loadingUser: true,
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                contact: action.payload.data.data.userByCompany,
                totalUsers: action.payload.data.data.totalItems,
                totalPages: action.payload.data.data.totalPages,
                isSuccess: true,
            };
        case GET_ALL_USERS_ERROR:
            return {
                ...state,
                error: true,
                isSuccess: false,
            };
        case GET_ALL_USERS_FINISH:
            return {
                ...state,
                loadingUser: false,
            };
        case POST_UPDATE_STATUS_USER_START:
            return {
                ...state,
                loadingUpdate: true,
            };
        case POST_UPDATE_STATUS_USER_SUCCESS:
            return {
                ...state,
                postDataUser: action.payload.data.succeeded,
            };
        case POST_UPDATE_STATUS_USER_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_UPDATE_STATUS_USER_FINISH:
            return {
                ...state,
                loadingUpdate: false,
            };
        case ADD_USER_NAME_DELETE:
            return {
                ...state,
                loading: false,
                nameUserDelete: action.name,
            };

        case REMOVE_USER_NAME_DELETE:
            return {
                ...state,
                loading: false,
                nameUserDelete: undefined,
            };
        case POST_CREATEUSER_EMPLOYEE_START:
            return {
                ...state,
                loadingCreate: true,
            };
        case POST_CREATEUSER_EMPLOYEE_SUCCESS:
            return {
                ...state,
                postcreateuser: action.payload.data.data,
            };
        case POST_CREATEUSER_EMPLOYEE_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_CREATEUSER_EMPLOYEE_FINISH:
            return {
                ...state,
                loadingCreate: false,
            };
        case RESET_DELETE_USER_SUCCEEDED:
            return initialState;
        case RESET_GET_ALL_USERS:
            return initialState;
        case ADD_MESSAGGE_ALERT:
            return {
                ...state,
                loading: false,
                userUpdateChange: action.name,
                messageAlert: action.messagge,
            };
        case RESET_MESSAGGE_ALERT:
            return {
                ...state,
                loading: false,
                userUpdateChange: '',
                messageAlert: undefined,
            };
        default:
            return state;
    }
};

export default GetAllUsers;
