import React from 'react';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREATE_CLIENTS} from '@components/Login/constants';

export const TermAndConditions = () => {
    return (
        <ContainerFlex
            Align="end"
            Height="3rem"
            Position="fixed"
            PositionTop="95%"
            Padding="0 0 40px 0"
        >
            <Text
                FontSize="0.85rem"
                Color="#5A5AFF"
                FontWeight="500"
                FontFamily="Nunito"
                Cursor="pointer"
            >
                {CREATE_CLIENTS.TERMS}
            </Text>
        </ContainerFlex>
    );
};
