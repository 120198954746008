import PhoneOne from '@/components/TransactionsDetails/img/image 11.png';
import PhoneTwo from '@/components/TransactionsDetails/img/image 11 (1).png';

export const DETAIL_COMPONENT = {
    TITTLE: 'Comprobante',
    BALANCE: 'Saldo en monedero:',
    CASH: '$ 0.00',
    INVOICE: 'No. folio:',
    DEADLINES: 'No. Plazos:',
    TYPE: 'Empeño',
    OR: '|',
    SUB_TOTAL: 'Sub-total',
    IVA: 'IVA',
    TOTAL: 'Total',
    CASH_TOTAL: 'Efectivo',
    CREDIT_CARD: 'Tarjeta de crédito',
    COMMIT: 'Cambio',
    LOCATION: 'Ubicación en inventario',
    ROW: 'Ubicación',
    COLUMN: 'Columna',
    TEXT_NUC: 'NUC ',
    FILE: 'Fila',
    CLOUSED: 'Cerrar',
    HARVEST: 'Envío y/o recolección',
};

export const DATA = {
    USER: {
        NAME: 'Juan Miguel Hernandez',
        NUC: '123456',
        INVOICE_NUMBER: '265535',
        DEADLINES_USER: '6 semanas',
        CASH_TOTAL: '-$ 16,000',
        CASH: '$ 0.00',
        LOCATION_USER: 'Rack 1',
        ROW_USER: 'Fila 1',
        COLUMN_USER: 'Columna 1',
    },
    PRODUCTS: [
        {
            IMG: PhoneOne,
            PRODUCT_NAME: 'Galaxy Z Flip5 256GB, rojo.',
            DETAILS_COLOR: 'Rosado',
            DETAIL_GB: '128 GB',
            HARVEST: 'Envío y/o recolección',
            CASH_ENDEAVOR: '-$ 8,000',
            BG_COLOR: '#EADEEF',
        },
        {
            IMG: PhoneTwo,
            PRODUCT_NAME: 'iPhone SE',
            DETAILS_COLOR: 'Negro',
            DETAIL_GB: '128 GB',
            HARVEST: 'Envío y/o recolección',
            CASH_ENDEAVOR: '-$ 8,000',
            BG_COLOR: '#151415',
        },
    ],
};
