import React, {useState} from 'react';
import {Text} from '@components/Shopify/Ecommerce/styles';
import {IconContainer} from '@components/CashFlow/styles';
import {TableItem} from '@components/Shopify/Endeavors/styles';
import {IHeaderUserItems} from '@/components/Branches/interfaces';

export const HeaderUserItems = ({name, id, isOrder, filters, setFilters}: IHeaderUserItems) => {
    const [order, setOrder] = useState(false);
    return (
        <TableItem Padding="0 0 0 16px" Justify={id === 2 ? 'center' : 'start'}>
            <Text
                FontWeight="500"
                FontSize="0.875rem"
                Padding="0 11px 0 0"
                Color="#414042"
                PaddingR="12.1px"
            >
                {name}
            </Text>
            {isOrder && (
                <IconContainer
                    className="material-icons"
                    FontSize=".875rem"
                    Color={order ? ' #35cf44' : '#414042'}
                    Justify="start"
                    onClick={() => {
                        setFilters({
                            ...filters,
                            orderType: filters.orderType === 0 ? 1 : 0,
                            filterUser: id,
                        });
                        setOrder(!order);
                    }}
                >
                    {order ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </IconContainer>
            )}
        </TableItem>
    );
};
