import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {containerStyles} from '@components/AdminNavbar/constants';
import {ClickAwayListener} from '@mui/material';
import {NOTIFICATIOS} from '@/components/AdminNavbar/Notification/operational/constants';
import closed from '@images/close.svg';
import {AllNotifications} from '@/components/AdminNavbar/Notification/AllNotification';
import {INavContextualOption} from '@/components/AdminNavbar/Notification/operational/Interface';

export const Notifications = ({handleClickAwayNotifications}: INavContextualOption) => {
    const [selectOption, setSelectOption] = useState(1);

    const optionsNotifications = [
        {id: 1, label: NOTIFICATIOS.OP_ONE},
        {id: 2, label: NOTIFICATIOS.OP_TWO},
        {id: 3, label: NOTIFICATIOS.OP_THREE},
    ];
    return (
        <ClickAwayListener onClickAway={handleClickAwayNotifications}>
            <ContainerFlex {...containerStyles}>
                <ContainerFlex
                    Width="15.2rem"
                    FlexDir="column"
                    backG="#FFFF"
                    Radius="0.75rem 0.75rem 0.75rem 0.75rem"
                >
                    <ContainerFlex Justify="space-between" Height="" Padding="16px">
                        <Text FontSize="0.875rem" FontWeight="500">
                            {NOTIFICATIOS.TITLE}
                        </Text>
                        <Image
                            width="18px"
                            height="18px"
                            onClick={() => handleClickAwayNotifications()}
                            Cursor="pointer"
                            src={closed}
                        />
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Width="100%" Height="40px">
                        {optionsNotifications.map((option) => (
                            <ContainerFlex
                                key={option.id}
                                Cursor="pointer"
                                Bb={
                                    selectOption === option.id
                                        ? '1px solid #5A5AFF'
                                        : '1px solid #D4D6D8'
                                }
                                onClick={() => setSelectOption(option.id)}
                            >
                                <Text
                                    Cursor="pointer"
                                    FontSize="0.875rem"
                                    Color={selectOption === option.id ? '#5A5AFF' : '#54575C'}
                                >
                                    {option.label}
                                </Text>
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                </ContainerFlex>
                {selectOption === 1 && <AllNotifications />}
            </ContainerFlex>
        </ClickAwayListener>
    );
};
