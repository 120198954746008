import styled from '@emotion/styled';
import {
    IPropsContainerModal,
    IPropsIconContainer,
    IPropsModalDialog,
    IPropsSelectForm,
} from '@CreateBranch/CreateModal/interfacesStyles';
export const ContainerModal = styled.div<IPropsContainerModal>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: ${(props) => props.FlexDir};
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    justify-content: ${(props) => props.Justify};
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: ${(props) => props.BackFil};
    box-shadow: '5px 5px 20px rgba(167, 169, 172, 0.5)';
`;
ContainerModal.defaultProps = {
    Justify: 'center',
    FlexDir: 'row',
    Align: 'center',
    FlexWrap: 'nowrap',
    BackFil: 'blur(2px)',
};

export const ModalDialog = styled.div<IPropsModalDialog>`
    display: flex;
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    flex-direction: ${(props) => props.FlexDirMod};
    background: ${(props) => props.backG};
    justify-content: ${(props) => props.JustifyMod};
    padding: ${(props) => props.Padding};
    align-items: ${(props) => props.AlignMod};
    flex-wrap: ${(props) => props.FlexWrapMod};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    cursor: ${(props) => props.Cursor};
    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    color: ${(props) => props.Color};
`;
ModalDialog.defaultProps = {
    Height: '539px',
    Width: '50%',
    Margin: '0',
    FlexDirMod: 'row',
    backG: '#ffff',
    JustifyMod: 'center',
    Padding: ' ',
    Cursor: 'auto',
    AlignMod: 'center',
    FlexWrapMod: 'nowrap',
    Radius: ' ',
    Border: ' ',
    FontSize: ' 1rem',
    FontWeight: ' ',
    Color: '',
};

export const IconContainer = styled.span<IPropsIconContainer>`
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    padding: ${(props) => props.Padding};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.Radius};
`;
IconContainer.defaultProps = {
    Color: ' ',
    FontSize: ' ',
};
export const SelectForm = styled.select<IPropsSelectForm>`
    height: ${(props) => props.Height};
    border-radius: 5px;
    border: 1px solid #e4e7e9;
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    align-items: ${(props) => props.Align};
    color: ${(props) => props.Color};
    font-family: ${(props) => props.FontFamily};
    font-style: ${(props) => props.FontStyle};
    font-weight: ${(props) => props.Weight};
    font-size: ${(props) => props.FontStyle};
    line-height: ${(props) => props.Height};
    display: ${(props) => props.Display};
    align-items: ${(props) => props.Align};

    option {
        color: #414042;
        display: flex;
        align-items: center;
    }

    &:hover {
        border: ${(props) => props.HBorder};
        box-shadow: ${(props) => props.HShadow};
    }
`;

SelectForm.defaultProps = {
    Padding: '0 0 0 0',
    Width: '100%',
    Justify: 'center',
    Margin: '0 0 0 0',
    Height: '50px',
    FontFamily: 'Work Sans',
    FontStyle: 'normal',
    Weight: '400',
    Display: 'flex',
    Align: 'center',
    Color: '#414042',
};
