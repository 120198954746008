import React, {useRef, useState} from 'react';
import {
    ContainerFlex,
    Text,
    Image,
    ContainerCellGrid,
    Input,
} from '@/components/Shopify/Ecommerce/styles';
import {UPLOADDROPDATA, UPLOADINCOMEDATA} from '@components/PersonalLoans/constants';
import {IconSvg} from '@Quoter/Icons/styles';
import {IncomeLoansDataProp} from '@components/PersonalLoans/interfaces';
import checkIcon from '@components/PersonalLoans/icons/checkIcon.svg';
import credentialIcon from '@components/PersonalLoans/icons/credentialIcon.svg';
import errorCrossIcon from '@components/PersonalLoans/icons/errorCrossIcon.svg';

export const PersonalLoansIncomeDocument = ({
    register,
    errors,
    documentsInfo,
    setDocumentsInfo,
}: IncomeLoansDataProp) => {
    const [showDocument, setShowDocument] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                callback(reader.result as string);
            }
        };
    };
    const isSupportedFileType = (file: File): boolean => {
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
        return allowedTypes.some((type) => file.type.startsWith(type));
    };

    const handleIncomeFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 4 && isSupportedFileType(file)) {
            convertToBase64(file, (base64) => {
                setDocumentsInfo((prevState) => ({
                    ...prevState,
                    incomeImage: {fileName: file.name, formFile: base64},
                }));
            });
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 4 && isSupportedFileType(file)) {
            convertToBase64(file, (base64) => {
                setDocumentsInfo((prevState) => ({
                    ...prevState,
                    incomeImage: {fileName: file.name, formFile: base64},
                }));
            });
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <ContainerFlex
            Justify="center"
            Align="end"
            backG="#FFFFFF"
            Padding="0.5rem 0.625rem"
            Radius="0.5rem"
            Gap="1rem"
            FlexDir="column"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            MinH="3rem"
        >
            <ContainerFlex Justify="space-between" Gap="0.5rem">
                <ContainerFlex Justify="start" Gap="0.5rem" Width="35rem">
                    <Image
                        Height="1.5rem"
                        Width="1.5rem"
                        src={documentsInfo.incomeImage ? checkIcon : credentialIcon}
                    />
                    <ContainerFlex FlexDir="column" Width="max-content" Align="start">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400" oFlow="none">
                            {UPLOADINCOMEDATA.TITLE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <IconSvg
                    Height="1.5rem"
                    width="1.5rem"
                    onClick={() => setShowDocument(!showDocument)}
                    cursor="pointer"
                >
                    {showDocument ? (
                        <path
                            d="M19.7242 14L18.2598 15.4L13.4481 10.8L8.63653 15.4L7.17212 14L13.4481 8.00002L19.7242 14Z"
                            fill="#5A5AFF"
                        />
                    ) : (
                        <path
                            d="M6.74009 9.70005L8.21353 8.30005L13.0549 12.9L17.8962 8.30005L19.3696 9.70005L13.0549 15.7L6.74009 9.70005Z"
                            fill="#5A5AFF"
                        />
                    )}
                </IconSvg>
            </ContainerFlex>
            {showDocument === true &&
                (documentsInfo.incomeImage ? (
                    <ContainerFlex Justify="start" Align="start" Gap="2rem">
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            FlexDir="column"
                            Width="auto"
                            Gap="0.75rem"
                        >
                            <Image
                                src={documentsInfo.incomeImage.formFile}
                                Width="12.75rem"
                                Height="7.875rem"
                                Radius="0.5rem"
                                ObjectFit="cover"
                                style={{cursor: 'pointer'}}
                            />
                            <ContainerFlex
                                Width="auto"
                                Height="auto"
                                Gap="0.25rem"
                                Cursor="pointer"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <input
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={handleIncomeFileChange}
                                    ref={fileInputRef}
                                    accept="image/png, image/jpeg"
                                />
                                <IconSvg Height="1.5rem" width="1.5rem">
                                    <path
                                        d="M4 20V18H6.75L6.35 17.65C5.48333 16.8833 4.875 16.0083 4.525 15.025C4.175 14.0417 4 13.05 4 12.05C4 10.2 4.554 8.554 5.662 7.112C6.77067 5.67067 8.21667 4.71667 10 4.25V6.35C8.8 6.78333 7.83333 7.52067 7.1 8.562C6.36667 9.604 6 10.7667 6 12.05C6 12.8 6.14167 13.529 6.425 14.237C6.70833 14.9457 7.15 15.6 7.75 16.2L8 16.45V14H10V20H4ZM14 19.75V17.65C15.2 17.2167 16.1667 16.4793 16.9 15.438C17.6333 14.396 18 13.2333 18 11.95C18 11.2 17.8583 10.4707 17.575 9.762C17.2917 9.054 16.85 8.4 16.25 7.8L16 7.55V10H14V4H20V6H17.25L17.65 6.35C18.4667 7.16667 19.0627 8.054 19.438 9.012C19.8127 9.97067 20 10.95 20 11.95C20 13.8 19.4457 15.4457 18.337 16.887C17.229 18.329 15.7833 19.2833 14 19.75Z"
                                        fill="#5A5AFF"
                                    />
                                </IconSvg>
                                <Text
                                    Color="#5A5AFF"
                                    FontSize="0.875rem"
                                    FontWeight="700"
                                    Cursor="pointer"
                                >
                                    {UPLOADDROPDATA.REMPLACE}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" FlexDir="column">
                            <ContainerCellGrid
                                FlexDir="column"
                                Align="start"
                                Width="22rem"
                                Gap="0.25rem"
                            >
                                <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                                    {'Nombre'}
                                </Text>
                                <Input
                                    maxLength={100}
                                    Width="100%"
                                    Margin="0 0 8px 0"
                                    {...register('nameIncomeDocument')}
                                    error={errors.nameIncomeDocument?.message}
                                />
                            </ContainerCellGrid>
                            {errors?.nameIncomeDocument?.message && (
                                <ContainerFlex Justify="start" Align="center" Gap="0.25rem">
                                    <Image Height="1rem" Width="1rem" src={errorCrossIcon} />
                                    <Text
                                        Color="#ef4f55"
                                        FontSize="0.75rem"
                                        Align="start"
                                        wSpace="normal"
                                        Height="max-content"
                                        FontWeight="400"
                                    >
                                        {errors.nameIncomeDocument?.message}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                ) : (
                    <>
                        <ContainerFlex
                            Justify="start"
                            FlexDir="column"
                            backG="#FFFFFF"
                            Padding="1rem 0.5rem"
                            Gap="5px"
                            Border="1px dashed #ACACFF"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <input
                                type="file"
                                style={{display: 'none'}}
                                onChange={handleIncomeFileChange}
                                ref={fileInputRef}
                                accept="image/png, image/jpeg"
                            />
                            <IconSvg Height="2.5rem" width="2.5rem">
                                <path
                                    d="M10.8334 33.3333C8.30564 33.3333 6.14591 32.4583 4.35425 30.7083C2.56258 28.9583 1.66675 26.8194 1.66675 24.2916C1.66675 22.125 2.31953 20.1944 3.62508 18.5C4.93064 16.8055 6.63897 15.7222 8.75008 15.25C9.44453 12.6944 10.8334 10.625 12.9167 9.04163C15.0001 7.45829 17.3612 6.66663 20.0001 6.66663C23.2501 6.66663 26.007 7.79857 28.2709 10.0625C30.5348 12.3263 31.6667 15.0833 31.6667 18.3333C33.5834 18.5555 35.1737 19.3819 36.4376 20.8125C37.7015 22.243 38.3334 23.9166 38.3334 25.8333C38.3334 27.9166 37.6042 29.6875 36.1459 31.1458C34.6876 32.6041 32.9167 33.3333 30.8334 33.3333H21.6667C20.7501 33.3333 19.9654 33.0069 19.3126 32.3541C18.6598 31.7013 18.3334 30.9166 18.3334 30V21.4166L15.6667 24L13.3334 21.6666L20.0001 15L26.6667 21.6666L24.3334 24L21.6667 21.4166V30H30.8334C32.0001 30 32.9862 29.5972 33.7917 28.7916C34.5973 27.9861 35.0001 27 35.0001 25.8333C35.0001 24.6666 34.5973 23.6805 33.7917 22.875C32.9862 22.0694 32.0001 21.6666 30.8334 21.6666H28.3334V18.3333C28.3334 16.0277 27.5209 14.0625 25.8959 12.4375C24.2709 10.8125 22.3056 9.99996 20.0001 9.99996C17.6945 9.99996 15.7292 10.8125 14.1042 12.4375C12.4792 14.0625 11.6667 16.0277 11.6667 18.3333H10.8334C9.2223 18.3333 7.8473 18.9027 6.70841 20.0416C5.56953 21.1805 5.00008 22.5555 5.00008 24.1666C5.00008 25.7777 5.56953 27.1527 6.70841 28.2916C7.8473 29.4305 9.2223 30 10.8334 30H15.0001V33.3333H10.8334Z"
                                    fill="#D4D6D8"
                                />
                            </IconSvg>
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                                {UPLOADDROPDATA.DROP}
                            </Text>
                            <Text Color="#A1A5AA" FontSize="0.75rem">
                                {UPLOADDROPDATA.TYPE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="auto"
                            Gap="0.25rem"
                            Margin="0 1rem"
                            Cursor="pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <input
                                type="file"
                                style={{display: 'none'}}
                                onChange={handleIncomeFileChange}
                                ref={fileInputRef}
                                accept="image/png, image/jpeg"
                            />
                            <IconSvg Height="1.5rem" width="1.5rem">
                                <path
                                    d="M9 14H19L15.55 9.5L13.25 12.5L11.7 10.5L9 14ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                                    fill="#5A5AFF"
                                />
                            </IconSvg>
                            <Text
                                Color="#5A5AFF"
                                FontSize="0.875rem"
                                FontWeight="700"
                                Cursor="pointer"
                            >
                                {UPLOADDROPDATA.SELECT}
                            </Text>
                        </ContainerFlex>
                    </>
                ))}
        </ContainerFlex>
    );
};
