import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {GetTaxData} from '@/components/AccountSettings/Redux/Actions/GetCompanyTaxData';
import {ContainerFlex, Text, Image} from '@EcommerceShopify/styles';
import waringIcon from '@components/Branches/Images/WarningIcon.svg';
import PaymentAndBillingModal from '@/components/AccountSettings/PaymentAndBillingModal';
import {PAYMENT_AND_BILLING, TAX_INFO_LABELS} from '@components/AccountSettings/constants';

const MyTaxInformation = () => {
    const dispatch = useDispatch();
    const [showEditTaxInfoWindow, setShowEditTaxInfoWindow] = useState(false);

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const taxData = useSelector((state: RootState) => state.GetCompanyTaxData?.taxData);
    const message = useSelector((state: RootState) => state.GetCompanyTaxData?.message);

    useEffect(() => {
        if (token && companyId) {
            dispatch(GetTaxData(companyId, token));
        }
    }, [dispatch, token, companyId]);

    const getTextStyle = (value: string) => {
        if (value === PAYMENT_AND_BILLING.NO_INFO) {
            return {Color: '#A1A5AA', FontWeight: '400'};
        }
        return {Color: '#2A2C2F', FontWeight: '500'};
    };

    return (
        <>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                Padding="16px"
                FlexDir="column"
                Gap="16px"
                Radius="16px"
                backG="#FFFF"
                Height="auto"
            >
                <ContainerFlex Justify="space-between" Align="center">
                    <Text FontSize="1.25rem" oFlow="visible" Color="#2A2C2F" FontWeight="500">
                        {PAYMENT_AND_BILLING.MY_TAXINFO}
                    </Text>
                    <Text
                        FontSize="1rem"
                        FontWeight="500"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        onClick={() => setShowEditTaxInfoWindow(true)}
                    >
                        {PAYMENT_AND_BILLING.EDIT}
                    </Text>
                </ContainerFlex>
                {message && (
                    <ContainerFlex
                        backG="#FFF5DB"
                        Height="fit-content"
                        Padding="8px"
                        Radius="8px"
                        Align="center"
                    >
                        <ContainerFlex Justify="start" Align="center" Gap="8px" Flex="1 0 0">
                            <Image src={waringIcon} alt="warn" Width="20px" Height="20px" />
                            <Text FontSize="1rem" FontWeight="500" Color="#614500">
                                {PAYMENT_AND_BILLING.COMPLETE_TAX_INFO}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
                <ContainerFlex FlexDir="column" Align="flex-start" Gap="16px">
                    <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px">
                        <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                            {TAX_INFO_LABELS.RFC}:{' '}
                        </Text>
                        <Text FontSize="1rem" {...getTextStyle(taxData.rfc)}>
                            {!taxData.companyId ? PAYMENT_AND_BILLING.NO_INFO : taxData.rfc}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px">
                        <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                            {TAX_INFO_LABELS.EMAIL}:{' '}
                        </Text>
                        <Text FontSize="1rem" {...getTextStyle(taxData.taxEmail)}>
                            {!taxData.companyId ? PAYMENT_AND_BILLING.NO_INFO : taxData.taxEmail}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px">
                        <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                            {TAX_INFO_LABELS.ADDRESS}:{' '}
                        </Text>
                        <Text FontSize="1rem" {...getTextStyle(taxData.companyAddress)}>
                            {!taxData.companyId
                                ? PAYMENT_AND_BILLING.NO_INFO
                                : taxData.companyAddress}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <PaymentAndBillingModal
                modalFormType="taxInfo"
                showModal={showEditTaxInfoWindow}
                setShowModal={setShowEditTaxInfoWindow}
            />
        </>
    );
};

export default MyTaxInformation;
