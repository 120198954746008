import {IReducerType} from '@/components/Roles/interface';
import {SEARCH_LEVEL, SEARCH_LEVEL_ERROR, SEARCH_LEVEL_SUCCESS} from '@TypesOrgCreation/types';

const initialState = {
    levelSearched: [],
    loading: false,
    error: false,
};
const searchLevels = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case SEARCH_LEVEL:
            return {
                ...state,
                loading: true,
            };
        case SEARCH_LEVEL_SUCCESS:
            return {
                ...state,
                levelSearched: action.payload.data.data,
                loading: false,
            };
        case SEARCH_LEVEL_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default searchLevels;
