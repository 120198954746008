import React, {useState} from 'react';

import {ROLES} from '@Roles/constants';
import {RemoveRole} from '@Roles/RemoveRole/RemoveRole';
import * as styles from '@Roles/CreateRoles/stylesCreate';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {AssigmentSearchListEdit} from '@Roles/CreateRoles/AssigmentSearchListEdit';
import {AssigmentUserByRolesSearch} from '@Roles/CreateRoles/AssingmentSearchUserByRole';

export const AssigmentRolesEdit = () => {
    const [showModalRole, setShowModalRole] = useState(false);
    const [selectedUser, setSelectedUser] = useState(0);

    return (
        <ContainerFlex {...styles.formContent}>
            <Text {...styles.title.subtitles}>{ROLES.USER_ROL.ASSIGMENTED_USERS}</Text>

            <>
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="16px">
                    <Text Color="#2A2C2F">{ROLES.USER_ROL.ASSIGNATED_EDIT}</Text>
                    <AssigmentUserByRolesSearch />
                    <AssigmentSearchListEdit
                        setShowModalRole={setShowModalRole}
                        setSelectedUser={setSelectedUser}
                    />
                </ContainerFlex>
            </>
            {showModalRole && (
                <RemoveRole
                    showModalRole={showModalRole}
                    setShowModalRole={setShowModalRole}
                    selectedUser={selectedUser}
                />
            )}
        </ContainerFlex>
    );
};
