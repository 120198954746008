import {IMyPlans, IContractedPlansAction} from '@components/MyPlan/interfaces';
import {MY_CONTRACTED_PLANS} from '@components/MyPlan/Redux/types';

const initialState: IMyPlans = {
    loading: false,
    error: false,
    plans: {
        companyId: 0,
        companyName: '',
        messagePayment: '',
        messageDay: '',
        firstText: '',
        plans: [],
    },
};
export const getContractedPlans = (
    state = initialState,
    action: IContractedPlansAction
): IMyPlans => {
    switch (action.type) {
        case MY_CONTRACTED_PLANS.START_GET_PLANS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case MY_CONTRACTED_PLANS.SUCCESS_GET_PLANS:
            return {
                ...state,
                plans: action.plans,
                loading: false,
                error: false,
            };
        case MY_CONTRACTED_PLANS.ERROR_GET_PLANS:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
