import React from 'react';
import {useNavigate} from 'react-router-dom';

import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {ContainerFlex, Figure, Image} from '@Shopify/Ecommerce/styles';
import ArrowBack from '@components/ManageUser/Icons/arrow_back.svg';

export const Breadcrum = () => {
    const navigate = useNavigate();

    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/#', label: 'Inicio'},
        {to: '/#/Users', label: 'Usuarios'},
        {to: '/#/Users/ManageUser', label: 'Gestionar usuario'},
    ];

    return (
        <ContainerFlex Height="24px" Align="center" Justify="start" Gap="12px">
            <Figure Height="24px" Width="24px">
                <Image src={ArrowBack} onClick={() => navigate(-1)} />
            </Figure>
            <Breadcrumb items={itemsBreadcrumb} />
        </ContainerFlex>
    );
};
