import React from 'react';
import '@/variables.css';
import {PersistGate} from 'redux-persist/integration/react';
import store from '@config/store';
import {AppRouter} from '@routers/AppRouter';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const persistor = persistStore(store);
const App = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <HashRouter>
                        <AppRouter />
                    </HashRouter>
                </PersistGate>
            </Provider>
        </DndProvider>
    );
};

export default App;
