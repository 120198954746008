import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import arrowLeftIcon from '@components/CreditQuoter/icons/arrowLeftIcon.svg';
import brandImage from '@components/CreditQuoter/images/yo-te-presto.png';
import {CREDIT_QUOTER} from '@components/CreditQuoter/constants';
import {GridContainer} from '@components/CreditQuoter/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {AppDispatch, RootState} from '@/config/store';
import {getAllCatFrequency} from '@components/CreditQuoter/Redux/Actions/GetAllCatFrequencyActions';
import {IFormFilterQuote, IFrequencyData} from '@components/CreditQuoter/interfaces';
import {getQuoteCreditPersonalLoans} from '@components/CreditQuoter/Redux/Actions/GetQuoteCreditPersonalLoansActions';
import {QuoteData} from '@components/CreditQuoter/QuoteData';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {QuoteFields} from '@components/CreditQuoter/QuoteFields';
import {schemaCreditQuoter} from '@components/CreditQuoter/schemaCreditQuoter';
import {AmortizationTableQuoter} from '@/components/CreditQuoter/AmortizationTable/AmortizationTableQuoter';

export const CreditQuoter = () => {
    const {control, register, watch, handleSubmit, reset} = useForm<IFormFilterQuote>({
        resolver: yupResolver(schemaCreditQuoter),
        mode: 'onChange',
        defaultValues: {
            frequencyId: 0,
            paymentTerms: {
                label: '',
                value: '',
            },
        },
    });
    const [requestedAmount, setRequestedAmount] = useState<number>(0);
    const hiddenSubmitRef = useRef<HTMLButtonElement>(null);
    const navigate: NavigateFunction = useNavigate();
    const [showTable, setShowTable] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const formData = watch();
    const dataFrequency: IFrequencyData[] = useSelector(
        (state: RootState) => state.getAllCatFrequency?.data || []
    );
    const termId = useSelector((state: RootState) => state.getAllCatFrequency?.termId);
    const frequencyTermCreditId = useSelector(
        (state: RootState) => state.getAllCatFrequency?.frequencyTermCreditId
    );
    const creditQuote = useSelector((state: RootState) => state.getQuoteCreditPersonalLoans);
    useEffect(() => {
        const isFormComplete =
            requestedAmount !== 0 &&
            formData.paymentTerms.value !== '' &&
            formData.frequencyId !== 0;
        if (hiddenSubmitRef.current && isFormComplete) {
            hiddenSubmitRef.current.click();
        }
    }, [formData.frequencyId, formData.paymentTerms, requestedAmount]);
    const onSubmit = (data: IFormFilterQuote) => {
        const filters = {
            amount: requestedAmount,
            frequencyTermCreditId: Number(frequencyTermCreditId!),
            quantityPeriods: Number(data.paymentTerms.value),
        };
        dispatch(getQuoteCreditPersonalLoans(token, filters));
    };
    useEffect(() => {
        reset();
    }, [requestedAmount]);
    useEffect(() => {
        if (termId && token) {
            dispatch(getAllCatFrequency(token, termId));
        }
    }, [termId]);

    return (
        <>
            <ContainerFlex
                Padding="1.5rem 3.75rem"
                FlexDir="column"
                Align="center"
                Justify="start"
                Gap="1rem"
                backG="#FAFAFF"
            >
                <ContainerFlex Gap="0.5rem" Justify="start" Height="24px" Width="100%">
                    <Image src={arrowLeftIcon} />
                    <Text FontWeight="700" Color="#5A5AFF">
                        {CREDIT_QUOTER.GO_BACK_TO_RESULTS}
                    </Text>
                </ContainerFlex>

                <GridContainer
                    Radius="1.5rem"
                    Border="1px solid #E8E9EA"
                    backG="#FFF"
                    Height="max-content"
                    GridColumns="repeat(2,1fr)"
                >
                    <ContainerFlex
                        FlexDir="column"
                        Padding="2rem 5rem"
                        Gap="1rem"
                        Align="start"
                        backG="#FFF"
                        Radius="1.5rem 0px 0px 1.5rem"
                    >
                        <ContainerFlex Height="max-content">
                            <Text FontSize="1.75rem" FontWeight="700" Width="100%">
                                {CREDIT_QUOTER.TITLE}
                            </Text>
                            <ContainerFlex Gap="0.5rem" Justify="end" Width="max-content">
                                <Image src={brandImage} Width="80px" Height="36.281px" />
                                <Text
                                    Color="#5A5AFF"
                                    FontSize="12px"
                                    FontWeight="700"
                                    Cursor="pointer"
                                >
                                    {CREDIT_QUOTER.VISIT_BRANCH}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>

                        <QuoteFields
                            control={control}
                            register={register}
                            dataFrequency={dataFrequency}
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            hiddenSubmitRef={hiddenSubmitRef}
                            frequencyId={formData.frequencyId}
                            setRequestedAmount={setRequestedAmount}
                            requestedAmount={requestedAmount}
                        />
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="2.5rem 7.5rem"
                        backG="linear-gradient(245deg, #30D8FF 15.05%, #FF6AE8 113.39%)"
                        Radius="0px 1.5rem 1.5rem 0px"
                    >
                        <ContainerFlex
                            Radius="1rem"
                            backG="rgba(250, 250, 250, 0.88)"
                            BoxS="4px 4px 8px 0px rgba(113, 118, 125, 0.24)"
                            Padding="1.5rem"
                            Gap="1rem"
                            FlexDir="column"
                        >
                            {!creditQuote.loading ? (
                                <QuoteData
                                    annualInterest={creditQuote.data.annualInterest}
                                    valuation={creditQuote.data.valuation}
                                    frequencyName={creditQuote.data.frequencyName}
                                    payments={creditQuote.data.payments}
                                    numberPeriods={creditQuote.data.quantityPeriods}
                                    error={creditQuote.error}
                                    showTable={showTable}
                                    setShowTable={setShowTable}
                                />
                            ) : (
                                <ContainerFlex>
                                    <Loading />
                                </ContainerFlex>
                            )}

                            <ButtonGenerals
                                Width="100%"
                                FontSize="1rem"
                                FontWeight="700"
                                onClick={() => navigate('/PersonalLoans')}
                            >
                                {CREDIT_QUOTER.BTN_REQUEST_NOW}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerFlex>
                </GridContainer>
                {showTable && (
                    <AmortizationTableQuoter
                        showTable={showTable}
                        setShowTable={setShowTable}
                        periodsNumber={creditQuote.data.quantityPeriods}
                        ammountRequested={requestedAmount}
                        frequencyTermCreditId={frequencyTermCreditId!}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
