import React, {useState} from 'react';
import {ContainerFlex, Text, TextEllipsis, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    FOCUSED_COLORS,
    GENERAL_TITLES,
    SCREEN_TABLE_ACCESS,
    SCREEN_TABLE_DATA_ACCESS,
} from '@components/Users/UserProfile/constants';
import {Table} from '@mui/material';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import {TableItem} from '@/components/DeadLines/styles';
import Save from '@/images/download_focused.svg';
import {NextPage, SingleNextPage} from '@components/Users/UserProfile/Icons/NextAllPage';

export const ResentAccess = () => {
    const [actualPage, setActualPage] = useState(1);
    const firPage = 1;
    const pageNumer = 1;
    const LastPage = 8;
    const Total = 24;
    const Pagination = `${pageNumer} - ${LastPage} de  ${Total}`;
    const HandleNextPage = () => {
        if (actualPage <= LastPage) {
            setActualPage(LastPage);
        }
    };
    const HandleFirtsPage = () => {
        if (actualPage > firPage) {
            setActualPage(firPage);
        }
    };
    return (
        <ContainerFlex
            Radius="24px"
            Justify="start"
            Padding="16PX"
            Align="start"
            Gap="16px"
            FlexDir="column"
            Border={FOCUSED_COLORS.BORDER_LEFT}
            Position="relative"
        >
            <ContainerFlex Justify="space-between" Height="40px">
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.TITLE_RECENT}
                </Text>
                <ContainerFlex
                    Gap="8px"
                    Radius="32px"
                    Padding="8px 16px"
                    Border={FOCUSED_COLORS.FOCUSED}
                    HBackground="#F0F0FF"
                    Width="15.34%"
                    Cursor="pointer"
                >
                    <Text
                        FontSize="1rem"
                        FontWeight="700"
                        Color={FOCUSED_COLORS.FOCUSED_TEXT}
                        Cursor="pointer"
                    >
                        {GENERAL_TITLES.SAVE}
                    </Text>
                    <Image alt="Save" src={Save} />
                </ContainerFlex>
            </ContainerFlex>

            <Table>
                <TableRowHead
                    Height="40px"
                    GridColumn="1fr 2fr 2fr 1fr 1fr"
                    BackG="none"
                    Border="none"
                    Gap="16px"
                >
                    <Text FontWeight="700" Padding="4px 0px" wSpace="">
                        {SCREEN_TABLE_ACCESS.DATE}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE_ACCESS.IP_ADDRESS}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE_ACCESS.ISP}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE_ACCESS.UBICATION}
                    </Text>
                </TableRowHead>
                <TableItemsContainer>
                    {SCREEN_TABLE_DATA_ACCESS.map((i, index) => (
                        <TableRowItems
                            key={index}
                            Height="40px"
                            GridColumn="1fr 2fr 2fr 1fr 1fr"
                            BackGround="none"
                            BorderBottom="1px solid #E8E9EA"
                            Border="none"
                            Gap="16px"
                            BorderRadius="0px"
                        >
                            <TableItem Justify="start" Height="40px">
                                <Text FontSize="0.875rem" FontWeight="400" Padding="4px 0px">
                                    {i.DATE}
                                </Text>
                            </TableItem>
                            <TableItem Justify="start" Height="40px">
                                <TextEllipsis
                                    FontSize="0.875rem"
                                    FontWeight="400"
                                    Padding="4px 0px"
                                >
                                    {i.IP_ADDRESS}
                                </TextEllipsis>
                            </TableItem>

                            <TableItem Justify="start" Height="40px">
                                <Text FontSize="0.875rem" FontWeight="700" Padding="4px 0px">
                                    {i.ISP}
                                </Text>
                            </TableItem>
                            <TableItem Height="40px">
                                <TextEllipsis
                                    FontSize="0.875rem"
                                    FontWeight="300"
                                    Padding="4px 0px"
                                >
                                    {i.UBICATION}
                                </TextEllipsis>
                            </TableItem>
                        </TableRowItems>
                    ))}
                </TableItemsContainer>
            </Table>
            <ContainerFlex Height="24px" Gap="8px">
                <NextPage
                    direction="right"
                    color={actualPage === firPage ? '#2A2C2F' : '#5A5AFF'}
                    size={12}
                    cursor={actualPage === firPage ? 'auto' : 'pointer'}
                    onClick={HandleFirtsPage}
                />
                <SingleNextPage
                    direction="right"
                    color={actualPage === firPage ? '#2A2C2F' : '#5A5AFF'}
                    size={12}
                    cursor={actualPage === firPage ? 'auto' : 'pointer'}
                    onClick={HandleFirtsPage}
                />
                <Text FontWeight="400" Color="#2A2C2F">
                    {Pagination}{' '}
                </Text>
                <SingleNextPage
                    direction="left"
                    color={actualPage === LastPage ? '#2A2C2F' : '#5A5AFF'}
                    size={12}
                    cursor={actualPage === LastPage ? 'auto' : 'pointer'}
                    onClick={HandleNextPage}
                />
                <NextPage
                    direction="left"
                    color={actualPage === LastPage ? '#2A2C2F' : '#5A5AFF'}
                    size={12}
                    cursor={actualPage === LastPage ? 'auto' : 'pointer'}
                    onClick={HandleNextPage}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
