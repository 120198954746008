export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR';

export const GET_TREE = 'GET_TREE';
export const GET_TREE_SUCCESS = 'GET_TREE_SUCCESS';
export const GET_TREE_SUCCESS_COPY = 'GET_TREE_SUCCESS_COPY';

export const GET_TREE_ERROR = 'GET_TREE_SUCCESS_ERROR';

export const RESET_TREE = 'RESET_TREE';
