import {
    POST_CHANGE_PASSWORD_START,
    POST_CHANGE_PASSWORD_SUCCESS,
    POST_CHANGE_PASSWORD_ERROR,
    GET_RANDOM_PASSWORD_START,
    GET_RANDOM_PASSWORD_SUCCESS,
    GET_RANDOM_PASSWORD_ERROR,
    RESET_DATA_PASSWORD,
    POST_PASSWORD_ADD_MESSAGE,
    POST_PASSWORD_REMOVE_MESSAGE,
} from '@components/CreateUsers/Redux/Types/Types';
import {IChangePasswd, IChangePasswdReducer} from '@components/CreateUsers/Redux/interface';

const initialState: IChangePasswd = {
    contact: null,
    data: null,
    datapass: '',
    loading: false,
    error: false,
    userPasswordChange: undefined,
};

const PostChangePass = (state = initialState, action: IChangePasswdReducer): IChangePasswd => {
    switch (action.type) {
        case POST_CHANGE_PASSWORD_START:
            return {
                ...state,
                loading: true,
            };
        case POST_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                contact: action.contact,
                loading: false,
            };
        case POST_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_RANDOM_PASSWORD_START:
            return {
                ...state,
                loading: true,
            };
        case GET_RANDOM_PASSWORD_SUCCESS:
            return {
                ...state,
                datapass: action.datapass,
                loading: false,
            };
        case GET_RANDOM_PASSWORD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_DATA_PASSWORD:
            return initialState;
        case POST_PASSWORD_ADD_MESSAGE:
            return {
                ...state,
                loading: false,
                userPasswordChange: action.userPasswordChange,
            };
        case POST_PASSWORD_REMOVE_MESSAGE:
            return {
                ...state,
                loading: false,
                userPasswordChange: undefined,
            };
        default:
            return state;
    }
};

export default PostChangePass;
