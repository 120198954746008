import * as yup from 'yup';

export const shemaCreateLevel = (actionTypeForm: number) => {
    return yup.object().shape({
        name: yup.string().test('name-validation', 'Campo inválido', function (value) {
            if (actionTypeForm === 2) {
                return true;
            }

            if (
                !value ||
                value.length < 3 ||
                value.length > 255 ||
                !/^[A-Za-z0-9 &\-_\[\]\n\táéíóúÁÉÍÓÚñÑ]*$/.test(value)
            ) {
                if (!value) {
                    return this.createError({message: 'Indique el campo'});
                }
                if (value.length < 3) {
                    return this.createError({message: 'Indique mínimo 3 caracteres'});
                }
                if (value.length > 255) {
                    return this.createError({message: 'Indique máximo 255 caracteres'});
                }
                if (!/^[A-Za-z0-9 &\-_\[\]\n\táéíóúÁÉÍÓÚñÑ]*$/.test(value)) {
                    return this.createError({message: 'Caracteres no permitidos'});
                }
            }

            return true;
        }),
        description: yup
            .string()
            .required('Indique el campo')
            .min(3, 'Indique mínimo 3 caracteres')
            .max(4000, 'Indique máximo 4000 caracteres')
            .matches(
                /^[A-Za-z0-9 .,;:!?@#$%^&*()\-_=+\[\]{}|'"/<>~`±§\n\táéíóúÁÉÍÓÚñÑ]*$/,
                'El campo contiene caracteres no permitidos'
            )
            .required('Este campo es obligatorio'),
    });
};
