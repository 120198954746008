import React from 'react';
export const RowRevisionFoundry = (props: {kilataje: number; gramos: number}) => {
    const {kilataje, gramos} = props;
    return (
        <tr className="row-sucursal-tr">
            <td>{kilataje}K</td>
            <td>{gramos}</td>
        </tr>
    );
};
