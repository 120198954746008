import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import downloadIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/downloadIcon.svg';
import {ALTS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';

const CreditDetailsDownloadButton = ({ctaText}: {ctaText: string}) => {
    return (
        <ContainerFlex MaxW="160px" Align="start" Justify="start" Gap="4px">
            <Image Width="24px" Height="24px" src={downloadIcon} alt={ALTS.DOWNLOAD_BUTTON} />
            <Text
                FontFamily="Nunito"
                FontWeight="700"
                FontSize="0.88rem"
                Color="#5A5AFF"
                Cursor="pointer"
            >
                {ctaText}
            </Text>
        </ContainerFlex>
    );
};

export default CreditDetailsDownloadButton;
