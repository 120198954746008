import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PersonalLoansStepOne} from '@components/PersonalLoans/PersonalLoansStepOne';
import {PersonalLoansStepTwo} from '@components/PersonalLoans/PersonalLoansStepTwo';
import {PersonalLoansStepThree} from '@components/PersonalLoans/PersonalLoansStepThree';
import {PersonalLoansStepFour} from '@components/PersonalLoans/PersonalLoansStepFour';
import {ProgressBarStep} from '@components/PersonalLoans/ProgressBarStep';
import {PERSONALLOANS} from '@components/PersonalLoans/constants';
import IconMenu from '@images/ICON_MENUPP.png';

export const PersonalLoans = () => {
    const DataStepLoans = useSelector((state: RootState) => {
        return state.DataStepLoans.dataStep;
    });
    return (
        <ContainerFlex Align={DataStepLoans === 4 ? 'center' : 'start'} backG="#E5E5FF">
            <ContainerFlex
                Justify="start"
                FlexDir="column"
                Border="solid 1px #0D166B"
                Radius="1rem"
                Padding="1.5rem"
                Gap="1rem"
                backG="#FFFFFF"
                Height="auto"
            >
                <ContainerFlex Justify="space-between" Height="40px">
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                        {PERSONALLOANS.TITLE}
                    </Text>
                    <Image src={IconMenu} />
                </ContainerFlex>
                <ContainerFlex Bb="solid 1px #E8E9EA" Height="1px" />
                <ContainerFlex
                    Align="start"
                    FlexDir="column"
                    Justify="start"
                    Height="auto"
                    Gap="1.5rem"
                >
                    <ProgressBarStep dataStep={DataStepLoans} />
                    {DataStepLoans === 1 && <PersonalLoansStepOne />}
                    {DataStepLoans === 2 && <PersonalLoansStepTwo />}
                    {DataStepLoans === 3 && <PersonalLoansStepThree />}
                    {DataStepLoans === 4 && <PersonalLoansStepFour />}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
