import {
    ADD_DATA_NEW_FOR_LEVEL_REQUEST,
    ADD_DATA_NEW_FOR_LEVEL_SUCCESS,
    ADD_DATA_NEW_FOR_LEVEL_ERROR,
    ID_SELECTED,
    BREAD_CRUMB,
} from '@/components/RequestNewCatalogLevel/Types/Types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
    breadCrumb: {},
    id: 0,
};

export const AddNewLevel = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DATA_NEW_FOR_LEVEL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_DATA_NEW_FOR_LEVEL_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload.data.data,
            };
        case BREAD_CRUMB:
            return {
                ...state,
                breadCrumb: action.payload,
            };
        case ID_SELECTED:
            return {
                ...state,
                id: action.payload,
            };
        case ADD_DATA_NEW_FOR_LEVEL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.response.data,
            };
        default:
            return state;
    }
};
