import React from 'react';

import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/MarketForgottenLogin/Operational/styles';
import Success from '@images/SuccessfulPassword.svg';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {SUCCESS_PASSWORD} from '@/components/MarketForgottenLogin/Operational/contants';
import {useNavigate} from 'react-router-dom';
import {TermAndConditions} from '@/components/Login/MarketPlaceLogin/TermsAndConditions';

export const SuccesfulPassword = () => {
    const navigate = useNavigate();
    const onSubmit = () => {
        navigate('/Login/MarketClient');
    };

    return (
        <ContainerFlex Height="100vh" backG="#E5E5FF" Width="100vw">
            <ContainerFlex
                backG="#FFFFFF"
                FlexDir="column"
                Width="407px"
                Radius="24px"
                Height="349px"
                Padding="32px"
                Gap="24px"
                Border="1px solid #0D166B"
            >
                <ImageLogo Width="74px" Height="75px" src={Success} />
                <ContainerFlex Gap="8px" Height="auto" FlexDir="column">
                    <Text
                        FontFamily="Nunito"
                        Padding="0 10px"
                        FontSize="1.75rem"
                        FontWeight="700"
                        Color="#0D166B"
                    >
                        {SUCCESS_PASSWORD.SUCCESS_TITLE}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        Padding="0 10px"
                        FontSize="1.75rem"
                        FontWeight="700"
                        Color="#0D166B"
                    >
                        {SUCCESS_PASSWORD.SUCCESS_TITLE_ONE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                        {SUCCESS_PASSWORD.SUCCESS_TEXT}
                    </Text>
                </ContainerFlex>

                <ContainerForm FlexDir="column" Gap="12px" onSubmit={onSubmit}>
                    <ContainerFlex>
                        <ButtonGeneral
                            type="submit"
                            FontSize="1rem"
                            padding="8px 16px"
                            height="40px"
                            width="100%"
                            text={SUCCESS_PASSWORD.BTEX}
                        />
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
            <TermAndConditions />
        </ContainerFlex>
    );
};
