import React, {useEffect, useState} from 'react';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ImgLocation} from '@CreateLocation/style';
import {TreeBranchLocations} from '@Locations/AdminLocations/CreateLocation/Containers/TreeLocationSelect';
import {treeBranchLocation} from '@/components/Locations/ManageLocations/redux/actions/TreeLocatiosActions';
import {addLevelToObjects} from '@/components/OrganizationCreation/SubContainer/TreeTableUtils/LevelsAddFunctions';
import {
    NodeData,
    treeLocations,
    location,
    BranchL,
} from '@Locations/AdminLocations/redux/Interfaces';
import {saveBranch} from '@Locations/AdminLocations/redux/actions/LocationAction';

const TreeLocations = () => {
    const token = useSelector((state: RootState) => state.getUsersValidation.userData);
    const TreeComponent: treeLocations = useSelector((state: RootState) => {
        return state.TreeBranchesLocations;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const dispatch: AppDispatch = useDispatch();
    const [tree, setTree] = useState<NodeData[]>([]);

    function findDeepestBranchId(tree: NodeData[]): BranchL {
        for (const level of tree) {
            if (level.branches && level.branches.length > 0) {
                const deepestBranch = level.branches[0];
                return deepestBranch;
            } else if (level.nextLevel && level.nextLevel.length > 0) {
                const deepestInNextLevel = findDeepestBranchId(level.nextLevel);
                if (deepestInNextLevel !== undefined) {
                    return deepestInNextLevel;
                }
            }
        }
        return {
            idLevel: 0,
            idBranch: 0,
            status: false,
            branchName: '',
            urlFlag: '',
            countryName: '',
            branchDescription: '',
            searchType: 0,
            check: false,
            corporation: '',
        };
    }

    useEffect(() => {
        dispatch(
            treeBranchLocation(
                String(token?.token),
                Number(token?.enterpriceDetails?.[0]?.enterpriceId)
            )
        );
    }, []);
    useEffect(() => {
        if (TreeComponent.treeLocation && TreeComponent.treeLocation.data.length > 0) {
            setTree(addLevelToObjects(TreeComponent.treeLocation.data, 0));
        }
    }, [TreeComponent.treeLocation?.data]);

    useEffect(() => {
        if (tree[0])
            dispatch(
                saveBranch(
                    locations.saveBranch !== null &&
                        locations.saveBranch.corporation === tree[0].levelName
                        ? locations.saveBranch
                        : findDeepestBranchId(tree),
                    tree[0].levelName
                )
            );
    }, [TreeComponent.treeLocation?.data, tree]);

    return (
        TreeComponent.treeLocation &&
        TreeComponent.treeLocation.data.length && (
            <ContainerFlex
                FlexDir="Column"
                Width="30%"
                Align="start"
                Border="1px solid #e4e7e9"
                Radius="5px"
                Justify="start"
                Height="95vh"
            >
                <ContainerFlex
                    Align="start"
                    FlexDir="Column"
                    Bb="1px solid #e4e7e9"
                    Height="auto"
                    Padding="1.313rem 1.125rem 0.688rem 1.125rem"
                    MarginBt="2.125rem"
                >
                    <Text Color="#4A4A4A">{locations.saveBranch?.branchName}</Text>
                    <Text FontSize="0.75rem" Color="#7AC143">
                        <ImgLocation
                            Width="24px"
                            Height="24px"
                            Padding="0 0.5rem 0 0"
                            src={locations.saveBranch?.urlFlag}
                        />{' '}
                        {locations.saveBranch?.countryName}
                    </Text>
                </ContainerFlex>

                <TreeBranchLocations data={tree} />
            </ContainerFlex>
        )
    );
};
export default TreeLocations;
