import {POST_COOWNER, POST_COOWNER_ERROR, POST_COOWNER_SUCCESS} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingCoOwnerSaved: false,
    idCoOwnerSaved: 0,
};

const saveDataCoOwner = (state = initialState, actions: {type: string; payload: AxiosResponse}) => {
    switch (actions.type) {
        case POST_COOWNER:
            return {
                ...state,
                loadingCoOwnerSaved: true,
                error: false,
            };
        case POST_COOWNER_SUCCESS:
            return {
                ...state,
                loadingCoOwnerSaved: false,
                idCoOwnerSaved: actions.payload.data.data,
            };
        case POST_COOWNER_ERROR:
            return {
                ...state,
                error: false,
                loadingCoOwnerSaved: false,
            };
        default:
            return state;
    }
};

export default saveDataCoOwner;
