import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import {DateTime} from 'luxon';
import {ErrorMessage} from '@hookform/error-message';
import {Table} from '@DeadLines/styles';

import {getFoundry} from '@ActionsAuditAssignment/FoundryAction';
import {
    getCollector,
    getSecuritybagAdd,
    getSecurityBagBranch,
    getCollectorInternal,
} from '@ActionsDelivery/DeliveryActions';
import {HeadTableDelivery} from '@Delivery/HeadTableDelivery';

export const DeliverySecuritybag = () => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        control,
        formState: {errors},
    } = useForm({
        defaultValues: {
            noSeguriBolsa: '',
            idCollector: '',
            recolectora: '',
            fundidora: '',
        },
    });

    useEffect(() => {
        const getCollectorSelect = () => dispatch(getCollector());
        getCollectorSelect();
        const getSecurityBagBranchTable = () => dispatch(getSecurityBagBranch());
        getSecurityBagBranchTable();
        const getFoundrySelect = () => dispatch(getFoundry());
        getFoundrySelect();
        const getCollectorSelectInternal = () => dispatch(getCollectorInternal());
        getCollectorSelectInternal();
    }, []);
    const securitybranchvalue = useSelector((state) => {
        return state.collector.securitybagvalue;
    });

    const onSubmit = async (values) => {
        let json = {
            seguribolsa: securitybranchvalue.noSeguribolsa,
            fecha: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            seguribolsaNueva: values.noSeguriBolsa,
            papeletaNueva: values.papeletaNueva,
            motivo: values.motivo,
        };
        await dispatch(getSecuritybagAdd(json, token));
        window.location.href = `/Fundiciones/Entrega`;
    };
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });

    return (
        <div className="w-90 mx-auto ">
            <div className="pt-2 pb-3 flex align-items-center w-100 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Entrega</span>
            </div>

            <Table className="mt-4 w-100 mx-auto">
                <HeadTableDelivery />
                <tbody>
                    {securitybranchvalue && (
                        <tr className="row-sucursal-tr">
                            <td>
                                <input
                                    type="checkbox"
                                    checked={true}
                                    className="form-check-input"
                                    id="exampleCheck1"
                                    key={securitybranchvalue.noSeguribolsa}
                                />
                            </td>
                            <td>{securitybranchvalue.noSeguribolsa}</td>
                            <td>{securitybranchvalue.noPapeleta}</td>
                            <td>{securitybranchvalue.recolectora}</td>
                            <td>{securitybranchvalue.fundidora}</td>
                            <td>{securitybranchvalue.quienArmoBolsa}</td>
                            <td>
                                {DateTime.fromISO(
                                    securitybranchvalue.ultimaFechaEdicion
                                ).toLocaleString(DateTime.DATE_SHORT)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <form className="flex container-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="column pr-5 width-50">
                    <label className="required">No De Seguribolsa</label>
                    <div className="width-100 mb-4">
                        <input
                            className="input-react-hook-form"
                            {...register('noSeguriBolsa', {})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="noSeguriBolsa"
                            message="This is required"
                        />
                    </div>
                </div>
                <div className="column pl-5 width-50 d-flex">
                    <label>Fecha</label>
                    <Controller
                        control={control}
                        name="fecha"
                        render={({onChange, onBlur, value}) => (
                            <ReactDatePicker
                                onChange={onChange}
                                onBlur={onBlur}
                                dateFormat="dd/MM/yyyy"
                                selected={value}
                                showDisabledMonthNavigation
                            />
                        )}
                    />
                </div>
                <div className="column pr-5 width-50">
                    <label className="required">Papeleta</label>
                    <div className="width-100 mb-4">
                        <input
                            className="input-react-hook-form"
                            {...register('papeletaNueva', {})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="noSeguriBolsa"
                            message="This is required"
                        />
                    </div>
                </div>
                <div className="column pl-5 width-50">
                    <label className="required">Motivo</label>
                    <div className="width-100 mb-4">
                        <textarea className="input-react-hook-form" {...register('motivo', {})} />
                        <ErrorMessage
                            errors={errors}
                            name="noSeguriBolsa"
                            message="This is required"
                        />
                    </div>
                </div>

                <div className="flex width-100 mt-4 flex-end-button pr-2">
                    <button className="btn-submit-image " type="submit">
                        {' '}
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};
