import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@/config/store';

import * as style from '@components/CreateUsers/Styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text, ContainerInfo} from '@Shopify/Ecommerce/styles';
import {BRANCHLOCATION} from '@components/CreateUsers/constants';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import ButtonGeneral from '@/components/General/Atoms/Button';
import exitDoor from '@images/exit_door.svg';
import {IFinishTransactionModalProps} from '@components/ShoppingCart/interface';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {changeActualStep, restarQuoter} from '@Quoter/Redux/Actions/saveEndeavors';
import {resetSearchClient} from '@components/ShoppingCart/Redux/Actions/SearchClientList';

export const FinishTransactionModal: React.FC<IFinishTransactionModalProps> = ({
    showModal,
    setShowModal,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const onCloseTransactionHandler = async () => {
        if (token) {
            await dispatch(
                DeleteTemporalyPawns(token, {
                    deleteAllPawns: true,
                    operationType: 1,
                    contractPawnId: 0,
                })
            );
            dispatch(changeActualStep(1));
            dispatch(restarQuoter());
            dispatch(resetSearchClient());
            navigate('/');
        }
    };

    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContect}
        >
            <ContainerFlex
                Gap="1.5rem"
                Padding="1.5rem"
                FlexDir="column"
                Height="34.375rem"
                MaxH="70%"
            >
                <ContainerFlex Gap="1rem" Align="center" FlexDir="column">
                    <ImgLocation Height="220px" src={exitDoor} alt="Exit door" />
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                        No olvides estos pasos
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="flex-start" Align="flex-start" Display="block">
                    <ContainerInfo
                        Color="#414042"
                        Padding="0rem 1rem 0rem 4rem"
                        Margin="0rem 0rem 1rem 0rem"
                        TextIndent="-1rem"
                    >
                        1. Asegúrate de imprimir y firmar los contratos. Si aún no lo has hecho,
                        primero cancela e imprime los contratos.
                    </ContainerInfo>
                    <ContainerInfo Color="#414042" Padding="0rem 1rem 0rem 4rem" TextIndent="-1rem">
                        2. Puedes cerrar esta pantalla y luego colocar las prendas en las
                        ubicaciones correspondientes.
                    </ContainerInfo>
                </ContainerFlex>
                <ContainerFlex Gap="0.5rem">
                    <ButtonGeneral
                        width="25%"
                        margin="0rem 2rem"
                        secondaryButton={true}
                        text={BRANCHLOCATION.CANCEL}
                        clic={handleCloseModal}
                    />
                    <ButtonGeneral
                        width="25%"
                        margin="0rem 2rem"
                        text={BRANCHLOCATION.CLOSE}
                        clic={onCloseTransactionHandler}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default FinishTransactionModal;
