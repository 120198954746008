import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_LIST_LOANS_SEARCH_ERROR,
    GET_LIST_LOANS_START,
    GET_LIST_LOANS_SEARCH_START,
    GET_LIST_LOANS_SEARCH_SUCCESS,
    GET_LIST_PERSONAL_SUCCESS,
    GET_LIST_PERSONAL_ERROR,
    SET_LIST_LOANS_STATUS,
    CLEAR_LIST_LOANS_SEARCH,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IDataLoans} from '@components/CreditsAndPawns/interfaces';

const initialState: IDataLoans = {
    data: [],
    searchData: [],
    status: 5,
    totalItems: 0,
    totalPages: 0,
    loadingTable: false,
    loadingSearch: false,
    errorTable: false,
    errorSearch: false,
};

const getListLoans = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError; status: number}
): IDataLoans => {
    switch (action.type) {
        case GET_LIST_LOANS_START:
            return {
                ...state,
                loadingTable: true,
                loadingSearch: false,
                errorTable: false,
                errorSearch: false,
            };
        case GET_LIST_LOANS_SEARCH_START:
            return {
                ...state,
                loadingTable: false,
                loadingSearch: true,
                errorTable: false,
                errorSearch: false,
            };
        case GET_LIST_LOANS_SEARCH_SUCCESS:
            return {
                ...state,
                searchData: action.payload.data.data,
                loadingTable: false,
                loadingSearch: false,
                errorTable: false,
                errorSearch: false,
            };
        case GET_LIST_LOANS_SEARCH_ERROR:
            return {
                ...state,
                searchData: [],
                loadingTable: false,
                loadingSearch: false,
                errorSearch: true,
            };
        case GET_LIST_PERSONAL_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                totalItems: action.payload.data.totalItems,
                totalPages: action.payload.data.totalPages,
                loadingTable: false,
                loadingSearch: false,
                errorTable: false,
                errorSearch: false,
            };
        case GET_LIST_PERSONAL_ERROR:
            return {
                ...state,
                data: [],
                totalItems: 0,
                totalPages: 0,
                loadingTable: false,
                loadingSearch: false,
                errorTable: true,
            };
        case SET_LIST_LOANS_STATUS:
            return {
                ...state,
                status: action.status,
            };
        case CLEAR_LIST_LOANS_SEARCH:
            return {
                ...state,
                errorTable: false,
                searchData: [],
            };
        default:
            return state;
    }
};

export default getListLoans;
