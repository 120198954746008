import React, {useState, useEffect} from 'react';

import {ContainerFlex, Text, InputColor} from '@Shopify/Ecommerce/styles';
import {labelSize, selectLabel} from '@Articles/interface';
import {LabelDetail} from '@/components/Articles/Redux/Actions/FieldDinamics';
import {fetchLabelTarget} from '@components/Articles/Redux/Actions/CreateNewCatalogId';

export const SelectLabel = (props: selectLabel) => {
    const {dispatch, detailFields} = props;
    const [radioSelect, setradioSelect] = useState(1);

    useEffect(() => {
        dispatch(LabelDetail(radioSelect));
        dispatch(fetchLabelTarget(radioSelect));
    }, [radioSelect]);

    useEffect(() => {
        if (detailFields.articles_detail)
            setradioSelect(
                detailFields.articles_detail.labelSizeId === undefined
                    ? 1
                    : detailFields.articles_detail.labelSizeId
            );
    }, [detailFields?.articles_detail?.labelSizeId]);

    return (
        <ContainerFlex Justify="start" Align="start" Gap="1.5rem" Height="44px">
            {detailFields &&
                detailFields.catLabelSize.map((item: labelSize, index: number) => {
                    return (
                        <ContainerFlex Justify="start" Width="17.625rem" Gap="0.5rem" key={index}>
                            <InputColor
                                type="radio"
                                value={radioSelect}
                                checked={radioSelect === item.labelTypeId ? true : false}
                                onChange={() => setradioSelect(item.labelTypeId)}
                            />
                            <Text Color="#2A2C2F" FontSize="1rem">
                                {item.nameLabelType}
                            </Text>
                        </ContainerFlex>
                    );
                })}
        </ContainerFlex>
    );
};
