import {
    GET_AMORTIZATION_TABLE_LOAN_START,
    GET_AMORTIZATION_TABLE_LOAN_SUCCESS,
    GET_AMORTIZATION_TABLE_LOAN_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {IAmortizationTableFilters} from '@components/PersonalLoans/interfaces';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const getAmortizationTableLoanStart = () => {
    return {
        type: GET_AMORTIZATION_TABLE_LOAN_START,
    };
};

export const getAmortizationTableLoanSuccess = (result: AxiosResponse) => {
    return {
        type: GET_AMORTIZATION_TABLE_LOAN_SUCCESS,
        payload: result.data,
    };
};

export const getAmortizationTableLoanError = (error: AxiosError) => {
    return {
        type: GET_AMORTIZATION_TABLE_LOAN_ERROR,
        error,
    };
};

export const getAmortizationTableLoan = (token: string, filters: IAmortizationTableFilters) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getAmortizationTableLoanStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTableAmortization}`,
                {headers, params: filters}
            );
            dispatch(getAmortizationTableLoanSuccess(response));
        } catch (error) {
            dispatch(getAmortizationTableLoanError(error as AxiosError));
        }
    };
};
