import styled from '@emotion/styled';
import {IResultsCard} from '@General/Atoms/SearchBoxResultList/interfaces';

export const SearchContainer = styled.div`
    position: relative;
    height: 0px;
    margin: 0;
    box-sizing: border-box;
`;

export const ResultsCard = styled.div<IResultsCard>`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-height: 13rem;
    background: #fff;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 0.5rem;
    overflow: auto;
    border: ${(props) => props.Border && props.Border};
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
`;
