import {
    DEADLINE_SEARCH,
    LEVELS_DEADLINES,
} from '@DeadLines/DeadlinesIndex/redux/types/DeadlineTypes';
import {ISearchControl} from '@Articles/interface';

const INITIAL_STATE = {
    Error: '',
    ItemsArticle: null,
    LevelSelected: '',
    RouteItemsPromotions: [],
    CurrentRoute: {},
    getLevel: 0,
    AddDinamics: false,
};
const deadLinesRoutes = (
    state: ISearchControl | any = INITIAL_STATE,
    action: {type: string; payload: {}; position: number}
) => {
    switch (action.type) {
        case DEADLINE_SEARCH.TRUE_LEVEL_DINAMICS:
            return {
                ...state,
                AddDinamics: true,
            };
        case DEADLINE_SEARCH.FALSE_LEVEL_DINAMICS:
            return {
                ...state,
                AddDinamics: false,
            };
        case LEVELS_DEADLINES.DEADLINE_ADD_ROUTE_ITEMS:
            return {
                ...state,
                RouteItemsPromotions: [...state.RouteItemsPromotions, action.payload],
            };
        case LEVELS_DEADLINES.DEADLINE_LAST_ROUTE_ITEM:
            return {
                ...state,
                CurrentRoute: action.payload,
            };
        case DEADLINE_SEARCH.ROUTE_ITEMS:
            return {
                ...state,
                RouteItemsPromotions:
                    state.RouteItemsPromotions.length > 0
                        ? state.RouteItemsPromotions
                        : action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_DELETE_LEVEL_ROUTE:
            return {
                ...state,
                RouteItemsPromotions: state.RouteItemsPromotions.filter(
                    (element: {}, index: number) => index < action.position
                ),
                CurrentRoute: state.RouteItemsPromotions[state.RouteItemsPromotions.length - 2],
            };
        case DEADLINE_SEARCH.RESTART_ROUTE:
            return {
                ...state,
                RouteItemsPromotions: [],
            };
        case DEADLINE_SEARCH.SEARCH_ARTICLE:
            return {
                ...state,
                ItemsArticle: action.payload,
            };
        case DEADLINE_SEARCH.RESTART_SEARCH_ARTICLE:
            return {
                ...state,
                Error: '',
                ItemsArticle: null,
                LevelSelected: '',
            };
        case DEADLINE_SEARCH.LEVEL_SELECTED:
            return {
                ...state,
                LevelSelected: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_GET_LEVEL:
            return {
                ...state,
                getLevel: action.payload,
            };
        case DEADLINE_SEARCH.ERROR:
            return {
                ...state,
                Error: action.payload,
            };
        default:
            return state;
    }
};

export default deadLinesRoutes;
