import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Cropper from 'react-easy-crop';

import {ContainerFlex, Modal, Text} from '@Shopify/Ecommerce/styles';
import {IPropsModalCropImage} from '@components/ManageUser/interface';
import ButtonGeneral from '@General/Atoms/Button';
import getCroppedImg from '@components/ManageUser/ModalUploadImages/cropImage';
import {postImageUserAxios} from '@components/ManageUser/Redux/Actions/postImageUserAction';
import {RootState, AppDispatch} from '@config/store';

export const ModalCropImage = ({
    selectImage,
    setOpenModalUploadImage,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
}: IPropsModalCropImage) => {
    const dispatch: AppDispatch = useDispatch();
    const [crop, setCrop] = useState({x: 0, y: 0});
    type Area = {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    const {idUser} = useParams();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        return setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const saveImageUser = useCallback(async () => {
        const croppedImage = await getCroppedImg(selectImage, croppedAreaPixels);
        fetch(croppedImage ?? '')
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], 'imageUser', {type: 'image/png'});
                const formData = new FormData();
                formData.append('Image', file);
                dispatch(postImageUserAxios(token, idUser, formData));
                setOpenModalUploadImage(false);
            });
    }, [croppedAreaPixels]);

    return (
        <Modal>
            <ContainerFlex
                backG="#fff"
                Width="40%"
                Height="auto"
                FlexDir="column"
                BoxShadow=""
                Justify="start"
                Gap="24px"
            >
                <Text
                    Padding="0 24px"
                    Color="#35CF44"
                    Height="56px"
                    FontSize="1.25rem"
                    BorderBt="1px solid #35CF44"
                    Width="100%"
                >
                    Recortar y ubicar
                </Text>
                <ContainerFlex
                    Width="90%"
                    Height="305px"
                    OverFlow="hidden"
                    Position="relative"
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <Cropper
                        image={selectImage}
                        crop={crop}
                        aspect={5 / 5}
                        onCropChange={setCrop}
                        cropShape="round"
                        cropSize={{width: 305, height: 305}}
                        showGrid={false}
                        objectFit={'cover'}
                        onCropComplete={onCropComplete}
                    />
                </ContainerFlex>
                <ContainerFlex Height="88px" Justify="center" Gap="24px" Padding="24px">
                    <ButtonGeneral
                        text="Cancelar"
                        width="93px"
                        secondaryButton={true}
                        transform="capitalize"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => setOpenModalUploadImage(false)}
                        border="1px solid transparent"
                    />
                    <ButtonGeneral
                        text="Siguiente"
                        width="96px"
                        transform="capitalize"
                        border="1px solid #414042"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder="#35CF44"
                        hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        dFontWeight="400"
                        clic={() => {
                            saveImageUser();
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
