import React from 'react';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import CreditRequestUser from '@components/CreditsAndPawns/CreditRequest/CreditRequestUser';
import CreditRequestKPI from '@components/CreditsAndPawns/CreditRequest/CreditRequestKPI';
import CreditRequestAccordion from '@components/CreditsAndPawns/CreditRequest/CreditRequestAccordion';
import {ICreditRequestProfileProps} from '@components/CreditsAndPawns/interfaces';
import CreditRequestAndPawnsTable from '@/components/CreditsAndPawns/CreditRequest/CreditRequestAndPawnsTable';
import {CREDIT_REQUEST_HISTORIES} from '@/components/CreditsAndPawns/constants';

const CreditRequestProfile: React.FC<ICreditRequestProfileProps> = ({
    clientName,
    imageUrl,
    nuc,
    clientId,
    phone,
    email,
    adrress,
    scoreTotal,
    personalInformationId,
    countContractActives,
    mutualAmount,
    amountToPay,
    earnings,
    loses,
    paymentsMade,
}) => {
    const userData = {
        clientName,
        imageUrl,
        nuc,
        clientId,
        phone,
        email,
        adrress,
        scoreTotal,
        personalInformationId,
    };
    const KpiData = {
        countContractActives,
        mutualAmount,
        amountToPay,
        earnings,
        loses,
        paymentsMade,
    };
    return (
        <ContainerFlex
            FlexDir="column"
            Align="start"
            Justify="center"
            Width="710px"
            Height="auto"
            Radius="24px"
            Border="1px solid #E8E9EA"
            backG="#FFFFFF"
            Gap="16px"
            Padding="24px"
        >
            <ContainerFlex FlexDir="row" Align="start" Gap="1.0rem" Height="144px">
                <CreditRequestUser {...userData} />
            </ContainerFlex>
            <CreditRequestKPI {...KpiData} />
            <ContainerFlex FlexDir="column" Align="start" Justify="start" Width="100%" Gap="16px">
                <CreditRequestAccordion
                    summaryTitle={CREDIT_REQUEST_HISTORIES.CREDIT_AND_PAWNS_TITLE}
                >
                    <CreditRequestAndPawnsTable clientId={clientId} />
                </CreditRequestAccordion>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditRequestProfile;
