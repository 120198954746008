import styled from '@emotion/styled';
import {IPropsListItem, IPropsUnorderedList} from '@components/Catalogue/interfacesStyles';

export const UnorderedList = styled.ul<IPropsUnorderedList>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
`;

UnorderedList.defaultProps = {
    Margin: '0',
    Padding: '0',
};

export const ListItem = styled.li<IPropsListItem>`
    height: ${(props) => props.Height};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    font-family: ${(props) => props.FontFamily};
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    line-height: ${(props) => props.LHeight};
    color: ${(props) => props.Color};
    text-align: ${(props) => props.TextAlign};
    list-style: ${(props) => props.ListStyle};
`;
