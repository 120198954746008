import {
    GET_CONSULT_ASSIGNMENT,
    GET_CONSULT_ASSIGNMENT_SUCCESS,
    GET_CONSULT_ASSIGNMENT_ERROR,
    PUT_CANCEL_ASSIGNMENT,
    PUT_CANCEL_ASSIGNMENT_SUCCESS,
    PUT_CANCEL_ASSIGNMENT_ERROR,
    GET_CONSULT_FILTERS_ASSIGNMENT,
} from '@TypesConsultAssignment/ConsultAssignmentTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';
import StringUtils from '@GenericScripts/utils';
import {ConsultAssigment} from '@Foundry/ConsultAssignment/interfaces';

export const fetchGetConsultAssignment = () => {
    return {
        type: GET_CONSULT_ASSIGNMENT,
    };
};
export const fetchGetConsultFiltersAssignment = (filters: unknown) => {
    return {
        type: GET_CONSULT_FILTERS_ASSIGNMENT,
        value: filters,
    };
};
export const fetchGetConsultAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_CONSULT_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetConsultAssignmentError = () => {
    return {
        type: GET_CONSULT_ASSIGNMENT_ERROR,
    };
};
export function getConsultAssignment(
    filters: FieldValues,
    pageSize = 10,
    pageIndex = 0,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return (dispatch: AppDispatch) => {
        if (!filters) filters = {};
        const params = {
            ...filters,
            pageSize: pageSize,
            pageIndex: pageIndex,
        };
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetConsultFiltersAssignment(filters));
        dispatch(fetchGetConsultAssignment());
        axios
            .get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Assignment}${queryString}`,
                {headers}
            )
            .then((response) => {
                dispatch(fetchGetConsultAssignmentSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchGetConsultAssignmentError());
            });
    };
}

export const fetchPutCancelAssignment = () => {
    return {
        type: PUT_CANCEL_ASSIGNMENT,
    };
};
export const fetchPutCancelAssignmentSuccess = () => {
    return {
        type: PUT_CANCEL_ASSIGNMENT_SUCCESS,
    };
};

export const fetchPutCancelAssignmentError = () => {
    return {
        type: PUT_CANCEL_ASSIGNMENT_ERROR,
    };
};
export function putCancelAssignment(data: ConsultAssigment, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutCancelAssignment());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentCancel}`,
                data,
                {headers}
            );
            dispatch(fetchPutCancelAssignmentSuccess());
            Swal.fire({
                icon: 'success',
                title: 'Cancelar asignación',
                text: '¡La auditoria ha sido Cancelada con éxito!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Cancelar asignación',
            });
            dispatch(fetchPutCancelAssignmentError);
        }
    };
}
