import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerCellGrid, Text, Input} from '@Shopify/Ecommerce/styles';
import {InputMaskStyled} from '@Roles/InputMaskStyled/style';
import {IPropsEnterAddressEmailAndPhone} from '@components/ManageUser/interface';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';

export const EnterAddressEmailAndPhone = ({
    control,
    isTouchedPhone,
    register,
    textErrorAddress,
    textErrorEmail,
    textErrorPhone,
    trigger,
}: IPropsEnterAddressEmailAndPhone) => {
    return (
        <>
            <ContainerCellGrid Justify="start" Column="1 / 3" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px">
                    Dirección
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Dirección"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('adress')}
                    error={textErrorAddress}
                />
            </ContainerCellGrid>
            <ContainerCellGrid Wrap="wrap" Align="center" AlignContent="start" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Cuenta de correo electrónico
                </Text>
                <Input
                    placeholder="Correro electrónico"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    Width="100%"
                    {...register('email')}
                    onKeyUp={() => trigger('email')}
                    error={textErrorEmail}
                />

                {textErrorEmail && (
                    <Text
                        FontSize="0.625rem"
                        FontWeight="normal"
                        Color="#ef4f55"
                        Height=""
                        Width="100%"
                    >
                        {textErrorEmail}
                    </Text>
                )}
                <Controller
                    name="startSesionEmail"
                    control={control}
                    render={({field}) => (
                        <GreenSwitch
                            size="small"
                            onChange={(e: {target: {checked: boolean}}) =>
                                field.onChange(e.target.checked)
                            }
                            checked={field.value}
                        />
                    )}
                />

                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" Align="center">
                    Inicia sesión con este correo electrónico
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Wrap="wrap" Align="center" AlignContent="start" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Teléfono
                </Text>
                <Controller
                    name="phone"
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <InputMaskStyled
                            value={value}
                            onChange={onChange}
                            placeholder="Número telefónico"
                            padding="12px"
                            fontSize="0.875rem"
                            width="100%"
                            mask="9999999999"
                            maskChar=""
                            error={textErrorPhone && isTouchedPhone}
                            onKeyUp={() => trigger('phone')}
                        />
                    )}
                />
                {textErrorPhone && isTouchedPhone && (
                    <Text
                        FontSize="0.625rem"
                        FontWeight="normal"
                        Color="#ef4f55"
                        wSpace="normal"
                        Height=""
                        Width="100%"
                    >
                        {textErrorPhone}
                    </Text>
                )}
                <Controller
                    name="startSesionPhone"
                    control={control}
                    render={({field}) => (
                        <GreenSwitch
                            size="small"
                            onChange={(e: {target: {checked: boolean}}) =>
                                field.onChange(e.target.checked)
                            }
                            checked={field.value}
                        />
                    )}
                />
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" Align="center">
                    Inicia sesión con este teléfono
                </Text>
            </ContainerCellGrid>
        </>
    );
};
