import '@Electronics/styles.css';
import React from 'react';
export function LoadingGeneralButtons({position}: {position?: string}) {
    return (
        <div className={`container-loader-buttons ${position}`}>
            <div className="loader-buttons">
                <span className="first-buttons" />
                <span className="second-buttons" />
                <span className="third-buttons" />
            </div>
        </div>
    );
}
