import React from 'react';

export function HeadTableProductsShopify(props) {
    const {width} = props;
    return (
        <thead className="title-head-foundry">
            <tr>
                {/* <th className="text-center"></th> */}
                <th className="text-center">SKU</th>
                {width > 600 && (
                    <>
                        <th className="text-center">Artículo</th>
                        <th className="pl-1">Sucursal</th>
                        <th className="pl-1">Valor de avalúo</th>
                    </>
                )}
                <th className="text-center">Accion</th>
            </tr>
        </thead>
    );
}
