import {AppDispatch} from '@/config/store';
import {
    GET_CART_CONTRACTS,
    GET_CART_CONTRACTS_SUCCESS,
    GET_CART_CONTRACTS_ERROR,
    PUT_CONTRACTS,
    PUT_CONTRACTS_SUCCESS,
    PUT_CONTRACTS_ERROR,
    EDIT_INFO,
    CANCEL_EDIT_INFO,
    DELETE_CART_CONTRATS,
} from '@components/ShoppingCart/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {ICartContractsParams} from '@components/ShoppingCart/interface';
import {DataContract} from '@/components/Quoter/Steps/interface';

export const GETCARTCONTRACTS = () => {
    return {
        type: GET_CART_CONTRACTS,
    };
};

export const GETCARTCONTRACTSSUCCESS = (result: AxiosResponse) => {
    return {
        type: GET_CART_CONTRACTS_SUCCESS,
        payload: result,
    };
};
export const GETCARTCONTRACTSERROR = (error: AxiosError) => {
    return {
        type: GET_CART_CONTRACTS_ERROR,
        error: error,
    };
};

export const DELETECARTCONTRACTS = () => {
    return {
        type: DELETE_CART_CONTRATS,
    };
};

export const searchEditInfo = () => {
    return {
        type: EDIT_INFO,
    };
};
export const cancelSearchEditInfo = () => {
    return {
        type: CANCEL_EDIT_INFO,
    };
};

export function getShoppingCartContracts(token: string, filters: ICartContractsParams) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(GETCARTCONTRACTS());
        try {
            const response = await axios.get(
                `
            ${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Getcarcontracts}${queryString}`,
                {
                    headers,
                }
            );
            dispatch(GETCARTCONTRACTSSUCCESS(response));
        } catch (error) {
            dispatch(GETCARTCONTRACTSERROR(error as AxiosError));
        }
    };
}

export const updateContractStart = () => ({type: PUT_CONTRACTS});
export const updateContractSuccess = (result: AxiosResponse) => ({
    type: PUT_CONTRACTS_SUCCESS,
    payload: result,
});
export const updateContractError = (error: AxiosError) => ({
    type: PUT_CONTRACTS_ERROR,
    error: error,
});
export const updateContract = (
    token: string,
    data: {pawns: DataContract[]},
    carshopingId: number,
    setShowModal: (value: React.SetStateAction<boolean>) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(updateContractStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateSplitCombineTemporalyPawn}`,
                data,
                {headers}
            );
            dispatch(updateContractSuccess(response));
            dispatch(getShoppingCartContracts(token, {carshopingId: carshopingId}));
            setShowModal(false);
        } catch (error) {
            dispatch(updateContractError(error as AxiosError));
        }
    };
};
