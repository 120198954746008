import {ContainerAdminCards} from '@Ecommerce/styles';
import React from 'react';
export function AdministrationEcommerce() {
    return (
        <ContainerAdminCards>
            <div onClick={() => (window.location.href = `/Administracion/Ecommerce/Banner`)}>
                <h2>Banner Principal</h2>
                <span>Crear y administrar banner Principal</span>
            </div>
            <div>
                <h2>Banner Principal</h2>
                <span>Crear y administrar las tarjetas</span>
            </div>
        </ContainerAdminCards>
    );
}
