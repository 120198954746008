import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {AppDispatch} from '@/config/store';

import {getIdIngot} from '@ActionsPurity/PurityActions';
interface NewType {
    lingote: number;
    kilatajes: string;
    purezaPrestalana: number;
    purezaFundidora: number;
    purezaTerceria: number;
    gramosProveedor: number;
    gramosOroFino: number;
    onzasOroFino: number;
    purezaPromedio: number;
    purezaSeleccionada: string;
    costo: number;
    subTotal: number;
    comision: number;
    total: number;
    ganancia: number;
    idingot: string | undefined;
}
export const RowPurityLab = (props: NewType): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const lingoteInfo = (lingote: number) => {
        dispatch(getIdIngot(lingote));
    };

    const {
        lingote,
        kilatajes,
        purezaPrestalana,
        purezaFundidora,
        purezaTerceria,
        gramosProveedor,
        gramosOroFino,
        onzasOroFino,
        purezaPromedio,
        purezaSeleccionada,
        costo,
        subTotal,
        comision,
        total,
        ganancia,
    } = props;

    return (
        <tr>
            <td> {lingote} </td>
            <td>{kilatajes}</td>
            <td>{purezaPrestalana}</td>
            <td>{formatterDolar.format(purezaFundidora)}</td>
            <td>{formatterDolar.format(purezaTerceria)}</td>
            <td>{gramosProveedor} </td>
            <td>{formatterDolar.format(gramosOroFino)}</td>
            <td>{onzasOroFino}</td>
            <td>{purezaPromedio}</td>
            <td>{purezaSeleccionada}</td>
            <td>{formatterDolar.format(costo)}</td>
            <td>{subTotal} %</td>
            <td>{formatterDolar.format(comision)}</td>
            <td>{formatterDolar.format(total)}</td>
            <td>{formatterDolar.format(ganancia)}</td>
            {purezaSeleccionada === '-' ? (
                <Link to={`/PurezaArchivo/${lingote}`}>
                    <td onClick={() => lingoteInfo(lingote)}>
                        <i className="material-icons">publish</i>
                    </td>
                </Link>
            ) : (
                <td />
            )}
        </tr>
    );
};
