import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    IChargesAndPenaltiesProps,
    IChargeState,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import addPlusIcon from '@components/PersonalLoans/NewLoanProduct/icons/addPlusIcon.svg';
import {TableChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/TableChargesAndPenalties/TableChargesAndPenalties';
import {DynamicFieldsCharges} from './DynamicFieldsCharges/DynamicFieldsCharges';
import {DefaultEditChargeForm} from './DefaultEditChargeForm/DefaultEditChargeForm';

export const ChargesAndPenalties: React.FC<IChargesAndPenaltiesProps> = ({chargesRef}) => {
    const [chargeState, setChargeState] = useState<IChargeState>({
        showCreateCharge: false,
        isCreating: false,
        showDefaultEditCharge: false,
    });
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={chargesRef}
            >
                <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start">
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {CHARGES_AND_PENALTIES.TITLE}&nbsp;
                        <Text FontSize="1.25rem" FontWeight="400" Color="#54575C">
                            {CHARGES_AND_PENALTIES.OPTIONAL}
                        </Text>
                    </Text>
                    <Text Color="#2A2C2F">{CHARGES_AND_PENALTIES.DESCRIPTION}</Text>
                </ContainerFlex>

                <TableChargesAndPenalties
                    chargeState={chargeState}
                    setChargeState={setChargeState}
                />

                {!chargeState.showCreateCharge && !chargeState.showDefaultEditCharge && (
                    <ContainerFlex Justify="start">
                        <ContainerFlex
                            Width="max-content"
                            Gap="0.5rem"
                            Cursor="pointer"
                            onClick={() => {
                                setChargeState({
                                    ...chargeState,
                                    isCreating: true,
                                    showCreateCharge: !chargeState.showCreateCharge,
                                });
                            }}
                        >
                            <Image
                                src={addPlusIcon}
                                Width="24px"
                                Height="24px"
                                alt="add-plus-icon"
                            />
                            <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer">
                                {CHARGES_AND_PENALTIES.BTN_CREATE_CHARGE}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}

                {chargeState.showCreateCharge && !chargeState.showDefaultEditCharge && (
                    <DynamicFieldsCharges
                        chargeState={chargeState}
                        setChargeState={setChargeState}
                    />
                )}
                {chargeState.showDefaultEditCharge && (
                    <DefaultEditChargeForm
                        chargeState={chargeState}
                        setChargeState={setChargeState}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
