import React, {useEffect, useState} from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {BranchFormInformation} from '@components/CloseBranch/BranchFormInformation';
import {SuccessBranchSold} from '@/components/CloseBranch/SuccessBranchSold';
import {useDispatch, useSelector} from 'react-redux';
import {GetBranchUserInfo} from '@components/CloseBranch/redux/actions/getUserInfo';
import {RootState} from '@/config/store';

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '/', label: 'Cierre'},
    {to: '', label: 'Cierre de Sucursal'},
];
export const CloseBranch = () => {
    const dispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const BranchData = useSelector((state: RootState) => {
        return state.BranchIdCash;
    });
    const [isConfirm, setIsConfirm] = useState(0);

    const ScreenSelector = (Screen: string) => {
        switch (Screen) {
            case 'Form':
                return (
                    <BranchFormInformation
                        setScreen={setScreen}
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                    />
                );
            case 'Success':
                return <SuccessBranchSold isConfirm={isConfirm} />;
            default:
                return <></>;
        }
    };
    const [screen, setScreen] = useState('Form');
    const branch = useSelector((state: RootState) => {
        return state.nearByBranches?.branch;
    });
    useEffect(() => {
        setScreen('Form');
    }, []);
    useEffect(() => {
        dispatch(GetBranchUserInfo(token, branch));
    }, [BranchData.branchId, branch]);
    return (
        <ContainerFlex Padding="24px 16px" Justify="start" FlexDir="column">
            <ContainerFlex Justify="start" Padding="0 0 48px 0">
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>
            <>{ScreenSelector(screen)}</>
        </ContainerFlex>
    );
};
