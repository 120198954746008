import styled from '@emotion/styled';
import {IPropsHighText, IPropsTextFormat} from '@TreeTableUtils/interfacesStyles';

export const HighText = styled.span<IPropsHighText>`
    font-weight: ${(props) => props.FontWeight};
    padding: ${(props) => props.Padding};
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    cursor: ${(props) => props.Cursor};
`;

export const TextFormat = styled.span<IPropsTextFormat>`
    line-height: ${(props) => props.LHeight};
`;
