import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerImg,
    Image,
    Text,
} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';

export const HomeEmployee = () => {
    const navigate = useNavigate();
    const modules = useSelector((state: RootState) => state.getModulesData.modulesData);
    const loading = useSelector((state: RootState) => state.getModulesData.loading);

    return (
        <>
            {loading ? (
                <ContainerCellGrid Width="100%" Height="100%" Justify="center" Align="center">
                    <LoadingAtaskate width="5%" padding="1.7%" />
                </ContainerCellGrid>
            ) : (
                <ContainerFlex FlexDir="column" Padding="24px" Gap="16px">
                    <ContainerFlex
                        Display="grid"
                        GridColumns="repeat(2, 1fr)"
                        Gap="16px"
                        GridAutoRows="min-content"
                        Height="auto"
                    >
                        {modules.slice(0, 4).map((module, index) => (
                            <ContainerCellGrid
                                key={index}
                                Height="100%"
                                Padding="16px"
                                Gap="8px"
                                FlexDir="column"
                                Align="start"
                                Justify="space-between"
                                bGround="#fff"
                                BorderR="24px"
                                Border="1px solid #D4D6D8"
                                Cursor="pointer"
                                onClick={() => navigate(module.route)}
                            >
                                <ContainerImg>
                                    <Image
                                        src={module.cssIcon}
                                        alt="icon-warning"
                                        Cursor="pointer"
                                    />
                                </ContainerImg>
                                <Text
                                    Color="#2A2C2F"
                                    FontSize="1.25rem"
                                    FontWeight="500"
                                    wSpace="normal"
                                    Cursor="pointer"
                                >
                                    {module.title}
                                </Text>
                            </ContainerCellGrid>
                        ))}
                    </ContainerFlex>
                    <ContainerFlex
                        Display="grid"
                        GridColumns="repeat(4, 1fr)"
                        Gap="16px"
                        GridAutoRows="min-content"
                        OverFlowY="auto"
                    >
                        {modules.slice(4).map((module, index) => (
                            <ContainerCellGrid
                                key={index}
                                Height="100%"
                                Padding="16px"
                                Gap="8px"
                                FlexDir="column"
                                Align="start"
                                Justify="space-between"
                                bGround="#fff"
                                BorderR="24px"
                                Border="1px solid #D4D6D8"
                                Cursor="pointer"
                                onClick={() => navigate(module.route)}
                            >
                                <ContainerImg>
                                    <Image
                                        src={module.cssIcon}
                                        alt="icon-warning"
                                        Cursor="pointer"
                                    />
                                </ContainerImg>
                                <Text
                                    Color="#2A2C2F"
                                    FontSize="1.25rem"
                                    FontWeight="500"
                                    wSpace="normal"
                                    Cursor="pointer"
                                >
                                    {module.title}
                                </Text>
                            </ContainerCellGrid>
                        ))}
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};
