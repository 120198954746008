import {IImageLogo} from '@Login/PasswordForgotAdmin/interfaces';
import styled from '@emotion/styled';

export const errorsStyle = {
    Color: '#ef4f55',
    FontSize: '0.75rem',
    Align: 'start',
    wSpace: 'normal',
    Height: '14px',
    FontWeight: '400',
    MarginTop: '4px',
};
export const textDescription = {
    FontWeight: '500',
    FontSize: '0.75rem',
    Color: '#54575C',
    Height: '12px',
};

export const searchInput = {
    Width: '26.25rem',
    Height: '40px',
    Position: 'none',
    GridColumn: '90% 10%',
    BoxShadowH: '',
    BoxShadowF: '',
    Padding: '0 1rem',
    BackG: '#FFFFFF',
    Radius: '32px',
};

export const containerPass = {
    Height: 'auto',
    backG: '#FFFFFF',
    Width: '31.438rem',
    FlexDir: 'column',
    Padding: '1.5rem 2.5rem',
    Radius: '1.5rem',
};

export const button = {
    width: '26.25rem',
    text: 'Cambiar tu contraseña',
    type: 'submit',
    height: '40px',
};

export const ImageLogo = styled.img<IImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
`;
ImageLogo.defaultProps = {
    Height: '29px',
    Width: '156px',
};
