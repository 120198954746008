export const GET_LEVELS = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCSESS';
export const GET_LEVELS_ERROR = 'GET_LEVELS_ERROR';

export const POST_CREATE_LEVELS_START = 'POST_CREATE_LEVELS_START';
export const POST_CREATE_LEVELS_SUCCESS = 'POST_CREATE_LEVELS_SUCCESS';
export const POST_CREATE_LEVELS_ERROR = 'POST_CREATE_LEVELS_ERROR';
export const POST_CREATE_LEVELS_FINISH = 'POST_CREATE_LEVELS_FINISH';

export const POST_UPDATE_LEVELS_START = 'POST_UPDATE_LEVELS_START';
export const POST_UPDATE_LEVELS_SUCCESS = 'POST_UPDATE_LEVELS_SUCCESS';
export const POST_UPDATE_LEVELS_ERROR = 'POST_UPDATE_LEVELS_ERROR';
export const POST_UPDATE_LEVELS_FINISH = 'POST_UPDATE_LEVELS_FINISH';

export const DELETE_UPDATE_LEVELS_START = 'POST_UPDATE_LEVELS_START';
export const DELETE_UPDATE_LEVELS_SUCCESS = 'POST_UPDATE_LEVELS_SUCCESS';
export const DELETE_UPDATE_LEVELS_ERROR = 'POST_UPDATE_LEVELS_ERROR';
export const DELETE_UPDATE_LEVELS_FINISH = 'POST_UPDATE_LEVELS_FINISH';
export const SEARCH_LEVEL = 'SEARCH_LEVEL';
export const SEARCH_LEVEL_SUCCESS = 'SEARCH_LEVEL_SUCCSESS';
export const SEARCH_LEVEL_ERROR = 'SEARCH_LEVEL_ERROR';
export const PAGE_NUMBER = 'PAGE_NUMBER';
export const PASS_ORGANIZATION = 'PASS_ORGANIZATION';
export const SELECT_FATHER_ID = 'SELECT_FATHER_ID';
export const SELECT_ORGANIZATION_NAME = 'SELECT_ORGANIZATION_NAME';
export const SELECT_NODE_ID = 'SELECT_NODE_ID';
export const SELECT_STATE_MODAL_ORG = 'SELECT_STATE_MODAL_ORG';
export const SAVE_LEVEL_NODES = 'SAVE_LEVEL_NODES';
export const SAVE_LEVEL = 'SAVE_LEVEL';
export const LEVEL = 'LEVEL';
export const CHILD = 'CHILD';

export const STATE = 'STATE';
export const UPDATE_SELECT_NODE_ID = 'UPDATE_SELECT_NODE_ID';
export const UPDATE_STATE = 'UPDATE_STATE';
export const IS_EDIT = 'IS_EDIT';
export const PAGE_NUMBERED = 'PAGE_NUMBERED';

export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION';
export const SEARCH_ORGANIZATION_SUCCESS = 'SEARCH_ORGANIZATION_SUCCESS';
export const SEARCH_ORGANIZATION_ERROR = 'SEARCH_ORGANIZATION_ERROR';

export const SAVE_NODE = 'SAVE_NODE';
export const UP_STATUS = 'UP_STATUS';

export const UPDATE_STATUS_LEVEL_START = 'UPDATE_STATUS_LEVEL_START';
export const UPDATE_STATUS_LEVEL_SUCCESS = 'UPDATE_STATUS_LEVEL_SUCCESS';
export const UPDATE_STATUS_LEVEL_ERROR = 'UPDATE_STATUS_LEVEL_ERROR';
export const UPDATE_STATUS_LEVEL_FINISH = 'UPDATE_STATUS_LEVEL_FINISH';
