import React, {useState} from 'react';

import {ContainerFlex, Text, ContainerIcon, Modal} from '@Shopify/Ecommerce/styles';

import ButtonGeneral from '@General/Atoms/Button';
import {Camera} from '@components/ManageUser/Icons/Camera';
import {Web} from '@components/ManageUser/Icons/Web';
import {IPropsModalUploadImage, ImageUploadState} from '@components/ManageUser/interface';
import {InputImage} from '@components/ManageUser/ModalUploadImages/styles';
import {ModalCropImage} from '@components/ManageUser/ModalUploadImages/ModalCropImage';
import {MessageAlertUploadImage} from '@components/ManageUser/ModalUploadImages/MessageAlertUploadImage';

export const ModalUploadImage = ({setOpenModalUploadImage}: IPropsModalUploadImage) => {
    const [uploadImage, setUploadImage] = useState<ImageUploadState>({
        dragOver: false,
        selectImage: undefined,
    });

    const [viewAlertMessage, setViewAlertMessage] = useState(false);

    const isValidImageType = (file: File) => {
        const acceptedTypes = ['image/jpeg', 'image/png'];
        return acceptedTypes.includes(file.type);
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setUploadImage({...uploadImage, dragOver: true});
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setUploadImage({...uploadImage, dragOver: false});
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files?.[0];

        if (file && isValidImageType(file) && file.size <= 5 * 1024 * 1024) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadImage({
                    dragOver: false,
                    selectImage: reader.result as string,
                });
            };
            return reader.readAsDataURL(file);
        }
        setViewAlertMessage(true);
    };

    const saveFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && isValidImageType(file) && file.size <= 5 * 1024 * 1024) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setUploadImage({...uploadImage, selectImage: base64String});
            };
            return reader.readAsDataURL(file);
        }
        setViewAlertMessage(true);
    };

    return (
        <Modal>
            {viewAlertMessage && (
                <MessageAlertUploadImage setViewAlertMessage={setViewAlertMessage} />
            )}
            <ContainerFlex
                backG="#fff"
                Width="40%"
                MaxW="520px:"
                Height="auto"
                FlexDir="column"
                BoxS="0px 0px 12px rgba(0, 0, 0, 0.5)"
                BoxShadow=""
                Justify="start"
            >
                <Text
                    Padding="0 24px"
                    Color="#35CF44"
                    Height="56px"
                    FontSize="1.25rem"
                    BorderBt="1px solid #35CF44"
                    Width="100%"
                >
                    Cargar fotografía
                </Text>
                <ContainerFlex
                    Padding="24px"
                    Height="355px"
                    Justify="space-between"
                    Gap="8px"
                    FlexWrap="wrap"
                    FlexDir="column"
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <Text
                        wSpace="normal"
                        Height="auto"
                        TextAlign="center"
                        Width="92%"
                        LHeight="normal"
                    >
                        Recuerda, que el tamaño del archivo no debe superar 5 Megabytes
                    </Text>
                    <ContainerIcon
                        Radius="50%"
                        Height="56px"
                        Width="56px"
                        BackGround="#f3f3f3"
                        Border="solid 1px #e5e7e9"
                    >
                        <Camera />
                    </ContainerIcon>
                    <Text
                        wSpace="normal"
                        Height="auto"
                        TextAlign="center"
                        Width="180px"
                        LHeight="normal"
                    >
                        Arrastra una foto aquí - o -
                    </Text>
                    <ContainerFlex Height="88px" Gap="24px">
                        <ButtonGeneral
                            text="Cancelar"
                            width="93px"
                            secondaryButton={true}
                            transform="capitalize"
                            bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                            color="#414042"
                            hColor="#35CF44"
                            hBorder=""
                            hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                            clic={() => setOpenModalUploadImage(false)}
                            border="1px solid transparent"
                        />
                        <InputImage>
                            <Web />
                            Subir del ordenador
                            <input type="file" accept="image/*" onChange={saveFileSelected} />
                        </InputImage>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {uploadImage.selectImage && (
                <ModalCropImage
                    selectImage={uploadImage.selectImage}
                    setOpenModalUploadImage={setOpenModalUploadImage}
                    handleDragEnter={handleDragEnter}
                    handleDragLeave={handleDragLeave}
                    handleDragOver={handleDragOver}
                    handleDrop={handleDrop}
                />
            )}
        </Modal>
    );
};
