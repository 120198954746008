export const inputProperties = {
    decimalSeparator: '.',
    thousandSeparator: true,
    decimalScale: 2,
    allowNegative: false,
    allowLeadingZeros: false,
};

export type interested<T> = {
    [index: number]: T;
};

export const headerTitles = [
    {
        id: 1,
        name: 'No. Plazo',
    },
    {
        id: 2,
        name: 'Plazo',
    },
    {
        id: 3,
        name: 'Monto mutuo',
    },
    {
        id: 4,
        name: 'Intereses',
    },
    {
        id: 5,
        name: 'IVA',
    },
    {
        id: 6,
        name: 'No. prendas totales',
    },
];
export const HeaderMiniTable = [
    {
        id: 0,
        name: '% interes',
    },
    {
        id: 1,
        name: 'Fecha límite de pago',
    },
    {
        id: 2,
        name: 'Intereses',
    },
    {
        id: 3,
        name: 'IVA',
    },
    {
        id: 4,
        name: 'Pago refrendo',
    },
    {
        id: 5,
        name: 'Pago desempeño',
    },
];

export const titleAmount = [
    {
        title: 'Rango Inferior',
    },
    {
        title: 'Rango Superior',
    },
    {
        title: 'Tasa Interés',
    },
    {
        title: 'Tasa Comercialización',
    },
    {
        title: 'Tasa Descuento',
    },
    {
        title: 'Acción',
    },
];
