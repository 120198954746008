import React from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import {RootState, AppDispatch} from '@config/store';

import {DateTime} from 'luxon';
import {
    getDateRangeByAssignment,
    getAuditorByAssignment,
    getStatusByAssignment,
    getRegionByAssignment,
    getSucursalByAssignment,
} from '@ActionsConsultAssignment/ConsultAssignmentFilterAction';
import {getConsultAssignment} from '@ActionsConsultAssignment/ConsultAssignmentAction';

import {ErrorMessage} from '@hookform/error-message';
import '@ConsultAssignment/styles.css';
import {useEffect} from 'react';
import {
    ButtonConsult,
    CardConsult,
    ContainerFilter,
    DivConsult,
    Inputs,
} from '@ConsultAssignment/style';

export const FilterConsultAssignment = () => {
    const {
        handleSubmit,
        register,
        control,

        formState: {errors},
    } = useForm({
        defaultValues: {
            dateMin: '',
            dateMax: '',
            idStore: null,
            idStatus: null,
            idAssigned: null,
            idRegion: null,
            idAssignment: '',
        },
    });

        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const auditorfilter = useSelector((state: RootState) => {
        return state.assignmentfilter.auditfilter;
    });
    const regionfilter = useSelector((state: RootState) => {
        return state.assignmentfilter.regionfilter;
    });
    const statusfilter = useSelector((state: RootState) => {
        return state.assignmentfilter.statusfilter;
    });
    const sucursalfilter = useSelector((state: RootState) => {
        return state.assignmentfilter.sucursalfilter;
    });
    const assignment = useSelector((state: RootState) => {
        return state.assignment;
    });

    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token) {
            const getAuditorSelect = () => dispatch(getAuditorByAssignment(token));
            getAuditorSelect();
            const getRegionSelect = () => dispatch(getRegionByAssignment(token));
            getRegionSelect();
            const getStatusSelect = () => dispatch(getStatusByAssignment(token));
            getStatusSelect();
            const getDateRange = () => dispatch(getDateRangeByAssignment(token));
            getDateRange();
            const getSucursal = () => dispatch(getSucursalByAssignment(0, token));
            getSucursal();
        }
    }, [dispatch]);
    interface Filters {
        dateMax: string | null;
        dateMin: string | null;
        idAssigned: number | null;
        idAssignment: string | number;
        idRegion: number | null;
        idStatus: number | null;
        idStore: number | null;
    }
    function onSubmit(values: Filters) {
        const filters = {
            dateMax: values.dateMax
                ? DateTime.fromJSDate(new Date(values.dateMax)).toFormat('yyyy-MM-dd')
                : '',
            dateMin: values.dateMin
                ? DateTime.fromJSDate(new Date(values.dateMin)).toFormat('yyyy-MM-dd')
                : '',
            idAssigned: values.idAssigned !== null ? values.idAssigned : 0,
            idAssignment: values.idAssignment !== '' ? values.idAssignment : 0,
            idRegion: values.idRegion !== null ? values.idRegion : 0,
            idStatus: values.idStatus !== null ? values.idStatus : 0,
            idStore: values.idStore !== null ? values.idStore : 0,
        };
        dispatch(getConsultAssignment(filters, 10, assignment.assignmentPageIndex, token));
    }

    function onchangeFilters(value: null) {}

    return (
        <CardConsult>
            <ContainerFilter>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DivConsult pl pr>
                        <label>Desde</label>
                        <Controller
                            control={control}
                            name="dateMin"
                            render={({field: {onChange, onBlur, value}}) => (
                                <ReactDatePicker
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/aaaa"
                                    className="input-form"
                                    // minDate={startDate}
                                    showDisabledMonthNavigation
                                />
                            )}
                        />
                    </DivConsult>
                    <DivConsult>
                        <label>Hasta</label>
                        <Controller
                            control={control}
                            name="dateMax"
                            render={({field: {onChange, onBlur, value}}) => (
                                <ReactDatePicker
                                    onChange={onChange}
                                    className="input-form"
                                    onBlur={onBlur}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/aaaa"
                                    // minDate={startDate}
                                    showDisabledMonthNavigation
                                />
                            )}
                        />
                    </DivConsult>
                    <DivConsult width PL>
                        <label>Estatus</label>
                        <Controller
                            {...register('idStatus')}
                            control={control}
                            render={({}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione estatus"
                                    options={statusfilter}
                                    className="input-form"
                                />
                            )}
                        />
                    </DivConsult>
                    <DivConsult width PL>
                        <label>Responsable</label>
                        <Controller
                            {...register('idAssigned')}
                            control={control}
                            render={({}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione responsable"
                                    options={auditorfilter}
                                    className="input-form"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="btn-aqui-here"
                            name="idAssigned"
                            message="This is required"
                            as="p"
                        />
                    </DivConsult>
                    <DivConsult pl mt={true} pr>
                        <label>No. Asignación</label>
                        <Inputs placeholder="Dígite aqui" {...register('idAssignment', {})} />
                    </DivConsult>
                    <DivConsult mt={true}>
                        <label>Region</label>
                        <Controller
                            {...register('idRegion')}
                            control={control}
                            render={({}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione región"
                                    options={regionfilter}
                                    onChange={onchangeFilters}
                                    className="input-form"
                                />
                            )}
                        />
                    </DivConsult>
                    <DivConsult width mt={true} PL>
                        <label>Sucursal</label>
                        <Controller
                            {...register('idStore')}
                            control={control}
                            render={({}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione sucursal"
                                    options={sucursalfilter}
                                    className="input-form"
                                />
                            )}
                        />
                    </DivConsult>
                    <ButtonConsult>
                        <button>Buscar</button>
                    </ButtonConsult>
                </form>
            </ContainerFilter>
        </CardConsult>
    );
};
