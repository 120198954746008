import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {SEARCH_INVENTORY} from '@components/LocationsBranch/Redux/types';

const searchStart = () => ({
    type: SEARCH_INVENTORY.SEARCH_INVENTORY_START,
});

const searchSuccess = (result: LevelSelection[]) => ({
    type: SEARCH_INVENTORY.SEARCH_INVENTORY_SUCCESS,
    payload: result,
});

const searchError = (error: AxiosError) => ({
    type: SEARCH_INVENTORY.SEARCH_INVENTORY_ERROR,
    error: error,
});

export const ClearLevelsSearch = () => ({
    type: SEARCH_INVENTORY.SEARCH_INVENTORY_CLEAR,
});

export const SearchInventoryStorage = (token: string, search: string, levelId?: number | null) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(searchStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSearchInventaryStorage}`,
                {
                    headers,
                    params: {
                        levelId,
                        search,
                    },
                }
            );
            const responseData: LevelSelection[] = response.data.data
                .slice(0, 3)
                .map((item: {title: string; ids: string}) => ({
                    title: item.title,
                    ids: item.ids.split(',').map(Number),
                }));
            dispatch(searchSuccess(responseData));
        } catch (error: unknown) {
            dispatch(searchError(error as AxiosError));
        }
    };
};

export const selectSearchedStorage = (selectedLevel: LevelSelection) => ({
    type: SEARCH_INVENTORY.SET_SELECTED_STORAGE,
    payload: selectedLevel,
});
