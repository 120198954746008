export const NO_EDITED_INFO = {
    CODE: 'Añadir CLAVE',
    BUSSINESS_NAME: 'Añadir Razón Social',
    RFC: 'Añadir RFC',
    PHONE: 'Añadir Teléfono',
    ADDRESS: ', , , Sin Colonia, C.P: 00000,  Sin Estado, Sin Pais',
    MANAGER: 'Añadir Gerente',
    LEGAL_REPRESENTATIVE: 'Añadir Representante Legal',
    EMAIL: 'Añadir Correo',
    CONTRACT_DATE: 'Añadir Fecha',
    REGISTRATION_DATE: 'Añadir Registro',
};

export const VALID_ROLE = 'Gerente Sucursal';
