import React from 'react';

import {ArrowShapeProps} from '@Atoms/GaugeChart/interfaces';
import gaugeArrow from '@images/gaugeArrow.svg';

const ArrowShape: React.FC<ArrowShapeProps> = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    value,
    maxScore,
}) => {
    const RADIAN = Math.PI / 180;
    const arrowBaseX = cx + (outerRadius - 20) * Math.cos(-midAngle * RADIAN);
    const arrowBaseY = cy + (outerRadius - 20) * Math.sin(-midAngle * RADIAN);
    const rotationAngle = (value / maxScore) * 180;

    return (
        <g transform={`translate(${arrowBaseX}, ${arrowBaseY}) rotate(${rotationAngle})`}>
            <image href={gaugeArrow} x={-26} y={-14} width={21.646} height={22.354} />
        </g>
    );
};
export default ArrowShape;
