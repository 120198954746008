import {SAVE_LOANS_RESET, SAVE_LOANS_USER} from '@components/PersonalLoans/Redux/Types/Types';
import {FieldValues} from 'react-hook-form';

export const AddPersonalDataLoans = (result: FieldValues) => {
    return {
        type: SAVE_LOANS_USER,
        personal: result,
    };
};
export const resetLoansUsers = () => {
    return {
        type: SAVE_LOANS_RESET,
    };
};
