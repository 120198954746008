export const ADD_COUNT_MARKET_LOGIN = 'ADD_COUNT_MARKET_LOGIN';
export const ADD_CODE_MARKET_LOGIN = 'ADD_CODE_MARKET_LOGIN';
export const RESET_CODE_MARKET_LOGIN = 'RESET_CODE_MARKET_LOGIN';

export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR';

export const VERIFY_CODE_NEW_REQUEST = 'VERIFY_CODE_NEW_REQUEST';
export const VERIFY_CODE_NEW_SUCCESS = 'VERIFY_CODE_NEW_SUCCESS';
export const VERIFY_CODE_NEW_ERROR = 'VERIFY_CODE_NEW_ERROR';

export const VERIFY_CODE_RESET_ERROR = 'VERIFY_CODE_RESET_ERROR';
