import axios, {AxiosResponse} from 'axios';
import {
    POST_CREATE_PROBLEMS,
    POST_CREATE_PROBLEMS_ERROR,
    POST_CREATE_PROBLEMS_RESET,
    POST_CREATE_PROBLEMS_SUCCESS,
} from '@components/LoginClient/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchPostCreateProblemsStart = () => {
    return {
        type: POST_CREATE_PROBLEMS,
    };
};
export const fetchPostCreateProblemsSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CREATE_PROBLEMS_SUCCESS,
        payload: result.data,
    };
};
export const fetchPostCreateProblemsError = () => {
    return {
        type: POST_CREATE_PROBLEMS_ERROR,
    };
};
export const fetchPostCreateProblemsReset = () => {
    return {
        type: POST_CREATE_PROBLEMS_RESET,
    };
};

export function CreateProblems(data: {problemId: number; fullNames: string; description: string}) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostCreateProblemsStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateProblems}`,
                data,
                {headers}
            );
            dispatch(fetchPostCreateProblemsSuccess(response));
            setTimeout(() => {
                window.location.href = '#/login/Employee/';
            }, 5000);
        } catch (error) {
            dispatch(fetchPostCreateProblemsError());
        }
    };
}
