import {
    RESET_DYNAMIC_FEATURES,
    SAVE_NEW_DYNAMIC_FEATURE,
    UPDATE_DYNAMIC_FEATURE,
} from '@components/Catalogue/Redux/Types/Types';
import {IDynamicFeatures} from '@components/Catalogue/interfaces';

export const saveNewDynamicFeature = (result: IDynamicFeatures) => {
    return {
        type: SAVE_NEW_DYNAMIC_FEATURE,
        payload: result,
    };
};
export const updateDynamicFeature = (feature: IDynamicFeatures) => ({
    type: UPDATE_DYNAMIC_FEATURE,
    payload: feature,
});

export const resetDynamicFeatures = () => {
    return {
        type: RESET_DYNAMIC_FEATURES,
    };
};
