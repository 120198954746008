import React from 'react';

import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/MarketForgottenLogin/Operational/styles';
import fox from '@images/FoxMail.svg';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {RESET_PASSWORD} from '@/components/MarketForgottenLogin/Operational/contants';
import {SearchInput} from '@/components/Customer/Styles';
import {SchemaLoginReset} from '@/components/MarketForgottenLogin/Operational/ResetAccountYup';
import {yupResolver} from '@hookform/resolvers/yup';
import {SubmitHandler, useForm} from 'react-hook-form';
import {IFormInput} from '@/components/MarketForgottenLogin/Operational/interfaces';
import {useDispatch} from 'react-redux';
import {PostNewCode} from '@/components/MarketForgottenLogin/Redux/Actions/EmailResetCodeAction';
import {useNavigate} from 'react-router-dom';
import {addEmailUser} from '@/components/MarketForgottenLogin/Redux/Actions/emalilAction';

export const ResetAccountClient = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInput>({
        defaultValues: {
            email: '',
            version: 1,
        },
        resolver: yupResolver(SchemaLoginReset),
    });

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        const email = data.email;
        dispatch(PostNewCode(email, navigate));
        dispatch(addEmailUser(email));
    };
    return (
        <ContainerFlex Height="100vh" Width="100vw">
            <ContainerFlex
                backG="#FFFFFF"
                FlexDir="column"
                Width="407px"
                Radius="24px"
                Height="547px"
                Padding="32px"
                Gap="24px"
                Border="1px solid #0D166B"
            >
                <ImageLogo Width="160px" Height="160px" src={fox} />
                <ContainerFlex Gap="8px" Height="auto" FlexDir="column">
                    <Text
                        FontFamily="Nunito"
                        Padding="0 10px"
                        FontSize="1.5rem"
                        FontWeight="700"
                        Color="#1D1E20"
                    >
                        {RESET_PASSWORD.TITLE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                        {RESET_PASSWORD.MESSAGE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                        {RESET_PASSWORD.MESSAGE_TWO}
                    </Text>
                </ContainerFlex>

                <ContainerForm FlexDir="column" Gap="12px" onSubmit={handleSubmit(onSubmit)}>
                    <ContainerFlex>
                        <ContainerFlex
                            Justify="start"
                            FlexDir="column"
                            Align="start"
                            Gap="4px"
                            Height="auto"
                        >
                            <Text
                                FontSize="1rem"
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                            >
                                {RESET_PASSWORD.EMAIL}
                            </Text>
                            <SearchInput
                                Height="40px"
                                Position="none"
                                GridColumn="90% 10%"
                                Margin="0 0 4px 0"
                                BoxShadowH=""
                                BoxShadowF=""
                                Padding="0 1rem"
                                BackG="#FFFFFF"
                                Radius="32px"
                            >
                                <input type="text" {...register('email')} />
                            </SearchInput>
                            {errors.email && (
                                <Text FontFamily="Nunito" FontSize="0.75rem" Color="#FF6357">
                                    {errors.email.message}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex>
                        <ButtonGeneral
                            type="submit"
                            FontSize="1rem"
                            padding="8px 16px"
                            height="40px"
                            width="100%"
                            text="Continuar"
                        />
                    </ContainerFlex>
                </ContainerForm>
                <ContainerFlex>
                    <Text
                        FontSize="1rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                        FontFamily="Nunito"
                        Cursor="pointer"
                    >
                        {RESET_PASSWORD.CANCEL}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
