import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {CREDITSANDPAWNS, NUEVA_SOLICITUD} from '@components/CreditsAndPawns/constants';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import addPlusIcon from '@components/CreditsAndPawns/icons/addPlusIcon.svg';

const Header = () => {
    return (
        <ContainerFlex Align="start">
            <ContainerFlex
                FlexDir="column"
                Align="start"
                Justify="space-between"
                Gap="0.5rem"
                Width="640px"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.75rem" Color="#2A2C2F">
                    {CREDITSANDPAWNS.TITLE}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700 " Color="#2A2C2F">
                    {CREDITSANDPAWNS.DESCRIPTION}
                </Text>
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="end">
                <ButtonGenerals Gap="0.5rem">
                    <Image Width="24px" Height="24px" src={addPlusIcon} />
                    {NUEVA_SOLICITUD}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;
