import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TableRowHead, Table} from '@CashFlow/FundingInquiry/styles';
import {TableItem} from '@DeadLines/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TreeComponent} from '@SubContainerOrgCreation/LevelsTree';
import {getAllLevelsOrganization} from '@ActionsOrgCreation/GetAllLevels';
import {RootState, AppDispatch} from '@config/store';
import {saveLevels} from '@ActionsOrgCreation/LevelTree';
import {addLevelPath, addLevelToObjects} from '@TreeTableUtils/LevelsAddFunctions';
import {updatePageNumber} from '@ActionsOrgCreation/CheckedState';
import {useInfiniteScroll} from '@TreeTableUtils/ReutilizableInfiniteScroll';
import {IOrganizationTable} from '@OrganizationCreation/interface';

export const OrganizationTable = ({currentPage, setCurrentPage}: IOrganizationTable) => {
    const dispatch: AppDispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const LevelData = useSelector((state: RootState) => {
        return state.GetLevelsData;
    });
    const Levels = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const deleteLevel = useSelector((state: RootState) => {
        return state.DeleteLevels.loading;
    });
    const createLevel = useSelector((state: RootState) => {
        return state.CreateLevels.loading;
    });
    const updateLevel = useSelector((state: RootState) => {
        return state.UpdateLevels.loading;
    });
    const statusLevel = useSelector((state: RootState) => {
        return state.UpdateStatusLevels.loading;
    });
    const PageSize = useSelector((state: RootState) => {
        return state.GetStatmentData.pageNumber;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    useEffect(() => {
        if (Levels.node.length > 0 && Levels.pageNumber === 1) {
            const level = addLevelToObjects(Levels.node, 0);
            dispatch(saveLevels(addLevelPath(level)));
        }
        if (
            Levels.node.length > 0 &&
            LevelData.node.length > 0 &&
            PageSize > 1 &&
            Levels.pageNumber === PageSize &&
            Levels.totalPages <= PageSize
        ) {
            const level = addLevelToObjects(Levels.node, 0);
            const LevelPath = addLevelPath(level);
            const updateFirst = LevelData.node.map(
                (item: {nextLevel: []}, index: number) =>
                    index === 0 && {
                        ...item,
                        nextLevel: [...item.nextLevel, ...LevelPath[0].nextLevel],
                    }
            );
            dispatch(saveLevels(updateFirst));
        }
    }, [Levels.node]);

    const handleIntersect = useCallback(() => {
        setCurrentPage((prev: number) => prev + 1);
    }, []);
    const targetRef = useInfiniteScroll({
        onIntersect: handleIntersect,
    });
    useEffect(() => {
        if (!deleteLevel && !createLevel && !updateLevel && !statusLevel) {
            const paginated = {
                companyId: companyId,
                search: LevelData.search,
                pageSize: 20,
                pageNumber: PageSize === 0 ? 1 : PageSize,
            };
            dispatch(getAllLevelsOrganization(paginated, token));
        }
    }, [PageSize, deleteLevel, createLevel, updateLevel, statusLevel]);
    useEffect(() => {
        if (currentPage <= Levels.totalPages) {
            dispatch(updatePageNumber(currentPage));
        }
    }, [currentPage]);
    const [levelId, setLevelId] = useState(0);
    return (
        <ContainerFlex FlexDir="column" Padding="8px 0">
            <Table>
                <TableRowHead GridColumn="1.5fr 3.3fr 1.2fr .2fr" Height="48px" BackG="#e5e7e9">
                    <TableItem Padding="0 0 0 16px" Width="16rem">
                        <Text FontWeight="500" FontSize="0.875rem" Color="#414042">
                            {' '}
                            Nombre de nivel organizacional
                        </Text>{' '}
                    </TableItem>
                    <TableItem Padding="0 0 0 16px">
                        <Text
                            FontWeight="500"
                            FontSize="0.875rem"
                            Padding="0 11px 0 0"
                            Color="#414042"
                        >
                            {' '}
                            Descripción
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text FontWeight="500" FontSize="0.85rem" Color="#414042">
                            Estatus
                        </Text>
                    </TableItem>
                </TableRowHead>
                {LevelData.node.length > 0 && (
                    <TreeComponent
                        data={LevelData.node}
                        setCurrentPage={setCurrentPage}
                        setLevelId={setLevelId}
                        levelId={levelId}
                    />
                )}
            </Table>
            <ContainerFlex ref={targetRef} />
        </ContainerFlex>
    );
};
