import axios from 'axios';
import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants';
import StringUtils from '@/GenericScripts/utils';
import {TREE} from '@components/CreateUsers/Redux/Types/Types';
import {ITree, ITreeBranchesAction, ITreeSave} from '@components/CreateUsers/Redux/interface';

export const treeGroupInit = () => ({type: TREE.GROUPS});

export const treeGroupSuccess = (value: {data: []}) => {
    return {
        type: TREE.GROUPS_SUCCESS,
        treeGroup: value.data,
    };
};
export const treeGroupError = (value: unknown) => {
    return {
        type: TREE.GROUPS_ERROR,
        payload: value,
    };
};

export const treeGroups = (token: string, filters: ITree) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(treeGroupInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.TreeGroups}${queryString}`,
                {headers}
            );
            dispatch(treeGroupSuccess(response.data));
        } catch (error) {
            dispatch(treeGroupError(error));
        }
    };
};

export const treeBranchInit = () => ({type: TREE.BRANCHES});

export const treeBranchSuccess = (value: {data: []}) => {
    return {
        type: TREE.BRANCHES_SUCCESS,
        treeBranch: value.data,
    };
};
export const treeBranchError = (value: unknown) => {
    return {
        type: TREE.BRANCHES_ERROR,
        payload: value,
    };
};

export const treeBranches = (token: string, data: ITreeBranchesAction) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(treeBranchInit());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.TreeBranch}`,
                data,
                {headers}
            );
            dispatch(treeBranchSuccess(response.data));
        } catch (error) {
            dispatch(treeBranchError(error));
        }
    };
};

export const saveTree = (value: ITreeSave) => {
    return {
        type: TREE.SHOW_BRANCHES,
        showTreeBranches: value,
    };
};

export const resetTreeBranch = () => ({type: TREE.RESET});
