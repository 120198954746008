export const GET_DOCUMENT_HEADER = 'GET_DOCUMENT_HEADER';
export const GET_DOCUMENT_HEADER_SUCCESS = 'GET_DOCUMENT_HEADER_SUCCESS';
export const GET_DOCUMENT_HEADER_ERROR = 'GET_DOCUMENT_HEADER_ERROR';

export const GET_DOCUMENT_BODY = 'GET_DOCUMENT_BODY';
export const GET_DOCUMENT_BODY_SUCCESS = 'GET_DOCUMENT_BODY_SUCCESS';
export const GET_DOCUMENT_BODY_ERROR = 'GET_DOCUMENT_BODY_ERROR';

export const PUT_DOCUMENT_FINISH = 'PUT_DOCUMENT_FINISH';
export const PUT_DOCUMENT_FINISH_SUCCESS = 'PUT_DOCUMENT_FINISH_SUCCESS';
export const PUT_DOCUMENT_FINISH_ERROR = 'PUT_DOCUMENT_FINISH_ERROR';
