import React from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {DateTime} from 'luxon';
import {getSecurityBagBranchValue} from '@ActionsDelivery/DeliveryActions';
import {IRowDelivery} from '@Foundry/interfaces';

export const RowDelivery = (props: IRowDelivery): JSX.Element => {
    const {noSeguribolsa, noPapeleta, recolectora, fundidora, quienArmoBolsa, ultimaFechaEdicion} =
        props;
    const dispatch: AppDispatch = useDispatch();
    const EditAssignment = (props: IRowDelivery) => {
        dispatch(getSecurityBagBranchValue(props));
    };
    return (
        <tr className="row-sucursal-tr">
            <td>
                <input
                    type="checkbox"
                    onChange={() => EditAssignment(props)}
                    className="form-check-input"
                    id="exampleCheck1"
                    key={noSeguribolsa}
                />
            </td>
            <td className="text-center">{noSeguribolsa}</td>
            <td className="text-center">{noPapeleta}</td>
            <td>{recolectora}</td>
            <td className="text-center">{fundidora}</td>
            <td className="text-center">{quienArmoBolsa}</td>
            <td className="text-center">
                {DateTime.fromISO(ultimaFechaEdicion.toISOString()).toLocaleString(
                    DateTime.DATE_SHORT
                )}
            </td>
        </tr>
    );
};
