import {
    PercentageAmortizationAction,
    GET_PERCENT_AMORTIZATION_ERROR,
    GET_PERCENT_AMORTIZATION_START,
    GET_PERCENT_AMORTIZATION_SUCCESS,
    SET_REQUESTED_AMOUNT_SOLICITUDE,
    SET_REQUESTED_AMOUNT_RECEIVE,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IPercentageAmortizationReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: IPercentageAmortizationReducer = {
    loading: false,
    error: false,
    recalculatedAmount: 0,
    recalculatedAmountReceive: 0,
};

const getDetailsCredit = (
    state: IPercentageAmortizationReducer = initialState,
    action: PercentageAmortizationAction
): IPercentageAmortizationReducer => {
    switch (action.type) {
        case SET_REQUESTED_AMOUNT_RECEIVE:
            return {
                ...state,
                recalculatedAmountReceive: action.payload,
            };
        case SET_REQUESTED_AMOUNT_SOLICITUDE:
            return {
                ...state,
                recalculatedAmount: action.payload,
            };
        case GET_PERCENT_AMORTIZATION_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PERCENT_AMORTIZATION_SUCCESS:
            return {
                ...state,
                recalculatedAmountReceive: action.payload,
                loading: false,
                error: false,
            };
        case GET_PERCENT_AMORTIZATION_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getDetailsCredit;
