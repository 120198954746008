import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';

import {ENDEAVORS} from '@Shopify/Endeavors/redux/types/Types';
import {endeavorCar, endeavorFilter} from '@Shopify/Endeavors/redux/interfaces';

export const endeavorsClient = () => {
    return {
        type: ENDEAVORS.CLIENT,
    };
};
export const endeavorsClientSuccess = (result: AxiosResponse) => {
    return {
        type: ENDEAVORS.CLIENT_SUCCESS,
        payload: result.data,
    };
};
export const endeavorsClientError = () => {
    return {
        type: ENDEAVORS.CLIENT_ERROR,
    };
};

export function endeavorsByClient(token: string, filters: endeavorFilter) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({
        ...filters,
    });
    return async (dispatch: AppDispatch) => {
        dispatch(endeavorsClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.EndeavorClientById}${queryString}`,
                {headers}
            );
            dispatch(endeavorsClientSuccess(response));
        } catch (error) {
            dispatch(endeavorsClientError());
        }
    };
}

export const endeavorsBranch = () => {
    return {
        type: ENDEAVORS.CLIENT,
    };
};
export const endeavorsBranchSuccess = (result: AxiosResponse) => {
    return {
        type: ENDEAVORS.CLIENT_BRANCH_SUCCESS,
        payload: result.data,
    };
};
export const endeavorsBranchError = () => {
    return {
        type: ENDEAVORS.CLIENT_BRANCH_ERROR,
    };
};

export function endeavorsByBranch(token: string, clientId: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(endeavorsBranch());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.EndeavorClientsBranches}`.replace(
                    '{clientId}',
                    String(clientId)
                ),
                {headers}
            );
            dispatch(endeavorsBranchSuccess(response.data));
        } catch (error) {
            dispatch(endeavorsBranchError());
        }
    };
}

export const endeavorsNumberBranch = (result: number) => {
    return {
        type: ENDEAVORS.NUMBER_BRANCH,
        payload: result,
    };
};

export const endeavorsActualPage = (result: number) => {
    return {
        type: ENDEAVORS.ACTUAL_PAGE,
        payload: result,
    };
};

export const endeavorsFolio = (result: string) => {
    return {
        type: ENDEAVORS.FOLIO,
        payload: result,
    };
};

export const endeavorsRestart = () => {
    return {
        type: ENDEAVORS.RESTART,
    };
};

export const endeavorsCar = () => {
    return {
        type: ENDEAVORS.CAR,
    };
};
export const endeavorCarSuccess = (result: AxiosResponse) => {
    return {
        type: ENDEAVORS.CAR_SUCCESS,
        payload: result.data,
    };
};
export const endeavorCarError = () => {
    return {
        type: ENDEAVORS.CAR_ERROR,
    };
};

export function endeavorsByCar(token: string, data: endeavorCar) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(endeavorsCar());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddBackEmployee}`,
                data,
                {headers}
            );
            dispatch(endeavorCarSuccess(response));
        } catch (error) {
            dispatch(endeavorCarError());
        }
    };
}
