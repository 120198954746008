import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useDataParser} from '@/hooks/useInfoDataParser';
import {RootState} from '@/config/store';
import {getAllCoowners} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/GetAllCoOwnerActions';
import {ContactInfoProps, CoOwner} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {CO_OWNER} from '@Shopify/ClientProfile/constants';

const CoOwners: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const {splitName} = useDataParser();
    const dispatch = useDispatch();
    const getAllCoowner = useSelector((state: RootState) => state.getAllCoowner);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    useEffect(() => {
        if (token) {
            dispatch(getAllCoowners(token, {clientId: clientProfile.clientId}));
        }
    }, []);

    const coowners = getAllCoowner?.data || [];
    return (
        <ContainerFlex
            Padding="1rem 1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1rem"
            Radius="1.5rem"
            Border="1px solid #E8E9EA"
            Self="stretch"
        >
            <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                {CO_OWNER.TITLE}
            </Text>
            <ContainerFlex
                Align="flex-start"
                Justify="start"
                FlexDir="column"
                AlignContent="flex-start"
                Gap="1rem"
                FlexWrap="nowrap"
            >
                {coowners.map((coowner: CoOwner, index: number) => {
                    const {firstName, lastName} = splitName(coowner.names);
                    return (
                        <React.Fragment key={index}>
                            <ContainerFlex
                                Gap="1rem"
                                Align="center"
                                AlignContent="center"
                                Justify="flex-start"
                            >
                                <ContainerFlex
                                    FlexDir="column"
                                    Align="flex-start"
                                    Height="3.5rem"
                                    Gap="0.25rem"
                                >
                                    <Text
                                        FontSize="1rem"
                                        Color="#1D1E20"
                                        FontWeight="700"
                                        LetterSpacing="0.3px"
                                    >
                                        {CO_OWNER.NAMES}
                                    </Text>
                                    <TextEllipsis
                                        Height="1.5rem"
                                        Width="90%"
                                        Padding="0.5rem 0"
                                        Color="#2A2C2F"
                                        FontSize="1rem"
                                        FontWeight="400"
                                        LetterSpacing="0.3px"
                                    >
                                        {firstName || '-'}
                                    </TextEllipsis>
                                </ContainerFlex>
                                <ContainerFlex
                                    FlexDir="column"
                                    Align="flex-start"
                                    Height="3.5rem"
                                    Gap="0.25rem"
                                >
                                    <Text
                                        FontSize="1rem"
                                        Color="#1D1E20"
                                        FontWeight="700"
                                        LetterSpacing="0.3px"
                                    >
                                        {CO_OWNER.LASTNAMES}
                                    </Text>
                                    <TextEllipsis
                                        Height="1.5rem"
                                        Width="90%"
                                        Padding="0.5rem 0"
                                        Color="#2A2C2F"
                                        FontSize="1rem"
                                        FontWeight="400"
                                        LetterSpacing="0.3px"
                                    >
                                        {lastName || '-'}
                                    </TextEllipsis>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex
                                Gap="1rem"
                                Align="center"
                                AlignContent="center"
                                Justify="flex-start"
                            >
                                <ContainerFlex
                                    FlexDir="column"
                                    Align="flex-start"
                                    Height="3.5rem"
                                    Gap="0.25rem"
                                >
                                    <Text
                                        FontSize="1rem"
                                        Color="#1D1E20"
                                        FontWeight="700"
                                        LetterSpacing="0.3px"
                                    >
                                        {CO_OWNER.PHONE_NUMBER}
                                    </Text>
                                    <TextEllipsis
                                        Height="1.5rem"
                                        Width="90%"
                                        Padding="0.5rem 0"
                                        Color="#2A2C2F"
                                        FontSize="1rem"
                                        FontWeight="400"
                                        LetterSpacing="0.3px"
                                    >
                                        {coowner.phone || '-'}
                                    </TextEllipsis>
                                </ContainerFlex>
                                <ContainerFlex
                                    FlexDir="column"
                                    Align="flex-start"
                                    Height="3.5rem"
                                    Gap="0.25rem"
                                >
                                    <Text
                                        FontSize="1rem"
                                        Color="#1D1E20"
                                        FontWeight="700"
                                        LetterSpacing="0.3px"
                                    >
                                        {CO_OWNER.EMAIL}
                                    </Text>
                                    <TextEllipsis
                                        Height="1.5rem"
                                        Width="90%"
                                        Padding="0.5rem 0"
                                        Color="#2A2C2F"
                                        FontSize="1rem"
                                        FontWeight="400"
                                        LetterSpacing="0.3px"
                                    >
                                        {coowner.email || '-'}
                                    </TextEllipsis>
                                </ContainerFlex>
                            </ContainerFlex>
                            {index < coowners.length - 1 && (
                                <ContainerFlex Height="1px" backG="#D4D6D8" />
                            )}
                        </React.Fragment>
                    );
                })}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CoOwners;
