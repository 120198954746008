import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {MY_SALES_CHANNELS} from '@components/MyPlan/Redux/types';

const getSalesChannelsStart = () => ({
    type: MY_SALES_CHANNELS.START_GET_CHANNELS,
});
const getSalesChannelsSuccess = (result: AxiosResponse) => ({
    type: MY_SALES_CHANNELS.SUCCESS_GET_CHANNELS,
    channelsData: result,
});
const getSalesChannelsError = (err: AxiosError) => ({
    type: MY_SALES_CHANNELS.ERROR_GET_CHANNELS,
    error: err,
});

export const getMySalesChannels = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSalesChannelsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSalesChannels}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(getSalesChannelsSuccess(response.data.data));
        } catch (error) {
            dispatch(getSalesChannelsError(error as AxiosError));
        }
    };
};
