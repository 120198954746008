import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import blueStart from '@components/PersonalLoans/CreditStatus/icons/blue-start.svg';
import {ListItem, UnorderedList} from '@components/Catalogue/styles';
import blueSmallArrowRight from '@components/CreditsAndPawns/icons/blueSmallArrowRight.svg';
import {IBrandsRowProps} from '@components/PersonalLoans/CreditStatus/interfaces';

export const BrandsRow = ({
    index,
    brandsTableLength,
    brandIconDetails,
    callToAction,
    details,
}: IBrandsRowProps) => {
    return (
        <>
            <GridContainer
                Gap="1rem"
                GridColumns="1fr 225px 1fr"
                Justify="start"
                MinH="75.93px"
                Height="max-content"
                key={index}
            >
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="1rem"
                    Width="225.171px"
                >
                    <ContainerFlex
                        FlexDir="column"
                        Justify="start"
                        Align="start"
                        Gap="0.5rem"
                        Width="47.344px"
                    >
                        <Image
                            Width={brandIconDetails.width}
                            Height={brandIconDetails.height}
                            src={brandIconDetails.icon}
                        />
                        <Text Color="#5A5AFF" FontSize="0.75rem" FontWeight="700" Cursor="pointer">
                            {callToAction}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Gap="0.125rem"
                        Align="start"
                        Justify="start"
                        Height="max-content"
                    >
                        {Array(5)
                            .fill('')
                            .map((_, index) => (
                                <Image src={blueStart} key={index} />
                            ))}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="start">
                    <UnorderedList Height="max-content">
                        {details.map((detail, index) => (
                            <ListItem
                                FontFamily="Nunito"
                                ListStyle="inside"
                                Color="#2A2C2F"
                                TextAlign="start"
                                LHeight="1.5rem"
                                key={index}
                            >
                                {detail.description}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </ContainerFlex>
                <ContainerFlex>
                    <ContainerFlex Gap="0.5rem" Height="24px" Cursor="pointer">
                        <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer">
                            {'Solicitar préstamo'}
                        </Text>
                        <Image src={blueSmallArrowRight} />
                    </ContainerFlex>
                </ContainerFlex>
            </GridContainer>
            {index < brandsTableLength - 1 && (
                <ContainerFlex backG="#E8E9EA" Width="676px" Height="1px">
                    {''}
                </ContainerFlex>
            )}
        </>
    );
};
