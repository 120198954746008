import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {productsLocations} from '@Locations/interfaces';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import {SelectFileVault} from '@AdminLocations/CreateLocation/style';
import {RackById} from '@AdminLocations/redux/actions/LocationAction';
import {updateLocationProduct} from '@ActionsManageLocations/ManageLocationsActions';
import {ContainerFlex, ContainerForm, Text, TextArea} from '@Shopify/Ecommerce/styles';

const ManageRack = () => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const dispatch: AppDispatch = useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            rack: '',
            row: '',
            incidence: '',
        },
    });

    const onSubmit = (data: {row: string; incidence: string}) => {
        const values = {
            pledgeId: productsLoations.productId.data.pledgeId,
            rackRowId: Number(data.row),
            containerTypeId: locations.locationsidcontainer,
            issuePrenda: data.incidence,
        };
        dispatch(updateLocationProduct(values, token));
    };

    const resetInfo = async () => {
        const restored = productsLoations.productId.data.locationRack;
        if (restored.rackName !== null) {
            await dispatch(RackById(String(restored.rackId), token));
            if (!locations.loading && locations.rackById !== null)
                reset({
                    rack: String(restored.rackId),
                    row: String(restored.rowId),
                    incidence: productsLoations.productId.data.issueLocation,
                });
        }
    };

    useEffect(() => {
        if (locations.locationsidcontainer === 1) resetInfo();
    }, [locations.locationsidcontainer]);

    return (
        <ContainerFlex Align="start" FlexDir="column">
            <ContainerForm
                Width="100%"
                FlexDir="column"
                Align="start"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Text Height="auto" FontSize="1.125rem" Padding="0 0 1.813rem 0">
                    Detalles de la ubicación en rack
                </Text>
                <ContainerFlex Justify="space-between" Width="80%">
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Rack
                        </Text>
                        <select
                            {...register('rack', {
                                required: {
                                    value: true,
                                    message: 'Selecciona un contenedor',
                                },
                            })}
                            onChange={(e) => {
                                dispatch(RackById(e.target.value, token));
                                reset({row: ''});
                            }}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.gRack &&
                                locations.gRack.data?.map((item, index: number) => (
                                    <option key={index} value={item.id}>
                                        {item.rackName}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.rack?.message}
                        </Text>
                    </SelectFileVault>
                    <SelectFileVault Width="12rem">
                        <Text FontSize="0.75rem" required>
                            Fila
                        </Text>
                        <select
                            {...register('row', {
                                required: {
                                    value: true,
                                    message: 'Selecciona un contenedor',
                                },
                            })}
                        >
                            <option selected disabled hidden value="">
                                Seleccione
                            </option>
                            {locations.rackById &&
                                locations.rackById?.map((item, index: number) => (
                                    <option key={index} value={item.rowId}>
                                        {item.rowNumber}
                                    </option>
                                ))}
                        </select>
                        <Text Color="red" FontSize="0.75rem">
                            {errors?.row?.message}
                        </Text>
                    </SelectFileVault>
                </ContainerFlex>
                <ContainerFlex Align="start" FlexDir="column">
                    <Text FontSize="0.75rem">Incidencias</Text>
                    <TextArea
                        {...register('incidence', {
                            pattern: {
                                value: /^[a-zA-ZáéíóúÁÉÍÓÚ0-9,. ]+$/,
                                message: 'No se permiten caracteres especiales',
                            },
                        })}
                        maxLength={160}
                        rows={6}
                    />
                    <Text Color="red" FontSize="0.75rem">
                        {errors?.incidence?.message}
                    </Text>
                </ContainerFlex>
            </ContainerForm>
            <ContainerFlex Justify="center" Padding="1.938rem 0 0 0">
                <ButtonGeneral
                    width="10.438rem"
                    text="Guardar y Cerrar"
                    hColor="#414042"
                    clic={handleSubmit(onSubmit)}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ManageRack;
