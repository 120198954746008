import {LEVELS_DEADLINES} from '@ReduxDeadlinesindex/types/DeadlineTypes';

const INITIAL_STATE = {
    Group: [],
    Family: [],
    Subfamily: [],
    Brand: [],
    Article: [],
    Error: null,
    loading: false,
};

const DataLevels = (state = INITIAL_STATE, action: {type: string; payload: {} | any}) => {
    switch (action.type) {
        case LEVELS_DEADLINES.DEADLINE_GROUP:
            return {
                ...state,
                Group: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_FAMILY:
            return {
                ...state,
                Family: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_SUBFAMILY:
            return {
                ...state,
                Subfamily: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_BRAND:
            return {
                ...state,
                Brand: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_ARTICLES:
            return {
                ...state,
                Article: action.payload,
            };
        case LEVELS_DEADLINES.DEADLINE_ERROR:
            return {
                ...state,
                Error: action.payload,
            };
        default:
            return state;
    }
};

export default DataLevels;
