import {OptionType} from '@/components/CashFlowActions/Operational/interfaces';

export const options = [
    'Fondear Caja',
    'Fondear Sucursal',
    'Devolver Excedentes',
    'Registrar Movimiento',
    'Registrar gastos',
];
export const HEADER_TITTLE = {
    TITTLE: 'Usuario',
    SUB_TITTLE_TWO: 'Fondo asignado',
};

export const BUTTON = {
    BT: 'Acciones',
    CLOSE: 'Calcelar',
    FONDING: 'Fondear',
    REQUEST: 'Enviar Solicitud',
    CONFIRM: 'Confirmar',
    REGISTER: 'Registrar',
};

export const data_user = [['Juan Carlos Arias'], ['Mario Barrera'], ['Omar Rodriguez']];

export const DATA_BOX_USER: OptionType[] = [
    {label: 'Juan Carlos Arias', value: 'juan_carlos_arias'},
    {label: 'Mario Barrera', value: 'mario_barrera'},
    {label: 'Omar Rodriguez', value: 'omar_rodriguez'},
];

export const DATA_TYPE_MOV: OptionType[] = [
    {label: 'De caja a Boveda', value: 'De caja a Boveda'},
    {label: 'De Boveda a banco ', value: 'De Boveda a banco '},
];

export const DATA_TYPE_CASH = [
    {label: 'Ingreso', value: 'Ingreso'},
    {label: 'Egreso', value: 'Egreso'},
];

export const MODAL_BOX_FUNDING = {
    TITTLE: 'Fondear cajas',
    SUB_TITTLE: 'Usuario',
    SUB_TITTLE_TWO: 'Fondo asignado',
};

export const MODAL_FUNDING_BRANCH = {
    TITTLE: 'Fondeo de Sucursal',
    TEXT: 'Ingresa a continuación en montó solicitado ',
    TEXT_ONE: 'para el fondeo la de la sucursal.',
    TEXT_TWO: ' será enviada al responsable encargado. ',
    INPUT_TEXT: 'Monto Solicitado',
    INPUT_MESSAGE: 'Monto Solicitado',
    INPUT_MESSAGE_TEXT: 'Mensaje',
    OPTION: ' (Opcional)',
    ADJUNTOS: ' Adjuntos',
    UNTIL: 'Hasta 10 MB ',
};

export const MODAL_RETURN = {
    TITTLE: 'Devolución de excedente',
    TEXT: 'Ingresa a continuación en montó solicitado ',
    TEXT_ONE: 'para el fondeo la de la sucursal. La solicitud  ',
    TEXT_TWO: ' será enviada al responsable encargado. ',
    INPUT_TEXT: 'Tipo de devolución',
    TEXT_USER: 'Usuario',
    MONT: 'Monto a retornar',
    OPTION: '(Opcional)',
    NOTE: 'Nota',
};

export const MODAL_MOTION = {
    TITTLE: 'Registrar movimiento',
    TEXT: 'Registre ingresos o egresos de dinero de',
    TEXT_ONE: 'manera manual.',
    TYPE: 'Tipo de movimineto',
    BOX: 'Caja afectada',
    MONT: 'Monto',
    DESCRIPTION: 'Descripción',
    NOTE: 'Nota',
    ADJUNTOS: 'Adjuntos',
};

export const BTN = {
    TITTLE: 'Fondear',
    REMP: 'Remplazar',
    ADD_TEXT: ' Arrastre elementos aquí o',
    ADD_SEARCH: 'Busca archivos.',
    MB: ' MB',
    SELECT: 'Seleccionar',
};
