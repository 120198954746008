import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';
import {
    GET_CLIENT_LIST,
    GET_CLIENT_LIST_ERROR,
    GET_CLIENT_LIST_SUCCESS,
    ADD_CLIENT_LIST,
    ADD_CLIENT_LIST_ERROR,
    ADD_CLIENT_LIST_SUCCESS,
    RESET_SEARCH,
    SELECT_CLIENT,
    ERROR_CLIENT,
} from '@components/ShoppingCart/Redux/types';
import StringUtils from '@/GenericScripts/utils';
import {FieldValues} from 'react-hook-form';
export const errorClient = (result: boolean) => {
    return {
        type: ERROR_CLIENT,
        payload: result,
    };
};
export const selectedClient = (result: FieldValues) => {
    return {
        type: SELECT_CLIENT,
        payload: result,
    };
};
export const resetSearchClient = () => {
    return {
        type: RESET_SEARCH,
    };
};
export const getClient = () => {
    return {
        type: GET_CLIENT_LIST,
    };
};
export const getClientSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CLIENT_LIST_SUCCESS,
        payload: result,
    };
};
export const getClientError = () => {
    return {
        type: GET_CLIENT_LIST_ERROR,
    };
};

export function getClientList(
    data: {
        companyId: number;
        searchName: string;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...data};

    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: Dispatch) => {
        dispatch(getClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ClientList}${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getClientSuccess(response.data.data));
        } catch (error) {
            dispatch(getClientError());
        }
    };
}
export const addClient = () => {
    return {
        type: ADD_CLIENT_LIST,
    };
};
export const addClientSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_CLIENT_LIST_SUCCESS,
        payload: result,
    };
};
export const addClientError = () => {
    return {
        type: ADD_CLIENT_LIST_ERROR,
    };
};

export function addClientList(
    data: {
        clientId: number;
        carShoppingId: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: Dispatch) => {
        dispatch(addClient());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddClient}`,
                data,
                {
                    headers,
                }
            );
            dispatch(addClientSuccess(response.data.data));
        } catch (error) {
            dispatch(addClientError());
        }
    };
}
