import React from 'react';
import triangleIcon from '@components/PersonalLoans/NewLoanProduct/icons/triangleIcon.svg';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IToolTipProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const ToolTip: React.FC<IToolTipProps> = ({description}) => {
    return (
        <ContainerFlex
            backG="#0D166B"
            Radius="1rem"
            BoxShadow="0px 11px 26.6px 0px"
            FlexDir="column"
            Width="221px"
            Height="max-content"
            Padding="1rem"
            Position="relative"
            PositionTop="15px"
            PositionLeft="-5px"
            ZIndex="20"
            Align="start"
        >
            <Image
                src={triangleIcon}
                Width="16px"
                Height="16px"
                Position="relative"
                PositionTop="-31px"
                PositionLeft="50%"
                alt="triangle-icon"
            />
            <Text
                Color="#FFF"
                wSpace="wrap"
                FontSize="0.875rem"
                TextAlign="start"
                MarginTop="-16px"
            >
                {description}
            </Text>
        </ContainerFlex>
    );
};
