import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START,
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS,
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR,
} from '@components/Catalogue/Redux/Types/Types';

const initialState = {
    data: [],
    loading: false,
    error: false,
};

export const getAllCatArticleType = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START:
            return {...state, loading: true, error: false};
        case GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
export default getAllCatArticleType;
