import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {ContainerFlex, InputColor, Text} from '@/components/Shopify/Ecommerce/styles';
import {ENABLEPAYMENT} from '@components/QuickPayment/Constants';
import {IPersonalCredit} from '@components/QuickPayment/interfaces';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {
    addPersonalCreditToCredits,
    removePersonalCreditInCredits,
} from '@/components/QuickPayment/Redux/Actions/AddFastPaymentActions';

const PersonalCreditRow: React.FC<IPersonalCredit> = ({
    personalCreditId,
    name,
    company,
    amount,
    paymentCredit,
    isPreselected,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const [isSelected, setIsSelected] = useState<boolean>(isPreselected as boolean);
    const personalCredit: IPersonalCredit = {
        personalCreditId,
        name,
        company,
        amount,
        paymentCredit,
    };

    useEffect(() => {
        if (isPreselected) dispatch(addPersonalCreditToCredits(personalCredit));
        setIsSelected(isPreselected as boolean);
    }, [isPreselected]);

    const handleSelected = ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            checked
                ? addPersonalCreditToCredits(personalCredit)
                : removePersonalCreditInCredits(personalCredit)
        );
        setIsSelected(checked);
    };
    return (
        <ContainerFlex
            Justify="space-around"
            Width="100%"
            Height="5.25rem"
            Padding="0.5rem 1rem"
            Border={`solid 1px ${isSelected ? '#5A5AFF' : '#E8E9EA'}`}
            Radius="0.5rem"
            Gap="1rem"
        >
            <ContainerFlex Align="center" Justify="start" Gap="16px">
                <InputColor type="checkbox" checked={isSelected} onChange={handleSelected} />
                <ContainerFlex Justify="start" Align="start" Gap="48px">
                    <ContainerFlex FlexDir="column" Justify="center" Align="start" Gap="4px">
                        <Text>{ENABLEPAYMENT.NAME}</Text>
                        <Text wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {name}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Justify="center" Align="start" Gap="4px">
                        <Text>{ENABLEPAYMENT.BUSINESS}</Text>
                        <Text wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {company || '-'}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="end">
                <ContainerFlex
                    FlexDir="column"
                    Align="center"
                    backG="#FAFAFA"
                    Radius="0.5rem"
                    Width="6.5rem"
                    Padding="8px"
                    Gap="4px"
                >
                    <Text>{ENABLEPAYMENT.VALUE}</Text>
                    <Text wSpace="normal" Color="#2A2C2F" FontWeight="700">
                        {numberToCurrency(amount)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalCreditRow;
