import React, {useEffect, useState} from 'react';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {Controller, useForm} from 'react-hook-form';
import {
    FilterCashFlowProps,
    IFilterCashFlow,
    IFilterParams,
    IGetTransactionsParams,
} from '@CashFlow/interfaces';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {DateFilter} from '@CashFlow/DateFilter';
import ButtonGeneral from '@General/Atoms/Button';
import {typeMovement} from '@CashFlow/constants';
import {yupResolver} from '@hookform/resolvers/yup';
import {getValidationSchema} from '@CashFlow/schemaYupFilter';
import {TITLES_FILTER} from '@CashFlow/constantsAtaskateFlow';
import {useDispatch, useSelector} from 'react-redux';
import {saveFilters} from '@components/CashFlow/Redux/Actions/filtersPersistance';
import {RootState} from '@/config/store';

export const FilterCashFlow: React.FC<FilterCashFlowProps> = ({onSubmit, setShowFilter}) => {
    const [dateFilter, setDateFilter] = useState(false);
    const [hourFilter, setHour] = useState(false);
    const [isReset, setIsReset] = useState(false);

    const {
        control,
        setValue,
        register,
        watch,
        reset,
        formState: {errors},
    } = useForm<IFilterCashFlow>({
        resolver: yupResolver(getValidationSchema(dateFilter, hourFilter)),
        defaultValues: {
            filterMotionType: {},
            filterTransactionType: {},
            startTime: {},
            endTime: {},
        },
    });
    const dispatch = useDispatch();
    const resetAll = () => {
        reset();
        setIsReset(true);
        setDateFilter(false);
        setHour(false);
    };
    const handleClick = () => {
        setIsReset(false);
    };

    const convertTo24HourFormat = (timeString?: string): string => {
        if (!timeString || !/^\d{1,2}:\d{2} (AM|PM)$/i.test(timeString)) {
            return '';
        }

        const [time, modifier] = timeString.split(' ');
        let [hours] = time.split(':').map(Number);
        const minutes = time.split(':')[1];

        if (modifier.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        }

        if (modifier.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    const handleFormSubmit = () => {
        const data = watch();
        const filterData: IGetTransactionsParams = {
            filterTransactionType: data.filterTransactionType.value,
            filterMotionType: data.filterMotionType.value,
            date: '',
            startTime: convertTo24HourFormat(data.startTime.label),
            endTime: convertTo24HourFormat(data.endTime.label),
        };
        setShowFilter(false);
        dispatch(saveFilters(filterData));
    };

    const options = [
        {value: 1, label: 'Entradas'},
        {value: 2, label: 'Salidas'},
        {value: 3, label: 'Sin movimiento'},
    ];

    const filterPersist: IFilterParams = useSelector(
        (state: RootState) => state.filtersPersistance
    );

    useEffect(() => {
        const selectedOption = options.find(
            (option) => option.value === filterPersist.filters.filterTransactionType
        );
        const label = selectedOption ? selectedOption.label : '';
        const selectedOptionMotion = typeMovement.find(
            (option) => option.value === filterPersist.filters.filterMotionType
        );
        const labelMotion = selectedOptionMotion ? selectedOptionMotion.label : '';
        if (filterPersist.filters.filterTransactionType || filterPersist.filters.filterMotionType) {
            const defaultValues = {
                filterMotionType: {value: filterPersist.filters.filterMotionType, label: label},
                filterTransactionType: {
                    value: filterPersist.filters.filterMotionType,
                    label: labelMotion,
                },
                startTime: {},
                endTime: {},
            };
            reset(defaultValues);
        }
    }, []);

    return (
        <ContainerFlex Position="fixed" ZIndex="5" Justify="end" Align="start">
            <ContainerFlex
                Align="start"
                Justify="start"
                Gap="24px"
                FlexDir="column"
                backG="#FFFFFF"
                Width="25rem"
                Padding="24px"
                Height="52rem"
                Radius="16px 0px 0px 16px"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
            >
                <ContainerFlex Justify="space-between" Height="">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                        {TITLES_FILTER.FILTER}
                    </Text>
                    <Text
                        FontSize="1rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                        Cursor="pointer"
                        onClick={() => {
                            resetAll();
                        }}
                    >
                        {TITLES_FILTER.RESTORE}
                    </Text>
                </ContainerFlex>
                <ContainerForm FlexDir="column" Height="" Gap="24px" Justify="start">
                    <ContainerFlex Align="start" Height="" FlexDir="column" Gap="8px">
                        <Text FontSize="1rem" Color="#71767D" FontWeight="500">
                            {TITLES_FILTER.MOV}
                        </Text>
                        <Controller
                            name="filterMotionType"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    hasError={!!errors.filterMotionType?.label?.message}
                                    isSearchable={false}
                                    options={options}
                                    placeholder={'Selecciona'}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                        {errors.filterMotionType?.label?.message && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                {errors.filterMotionType?.label?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                    <ContainerFlex Align="start" Height="" FlexDir="column" Gap="8px">
                        <Text FontSize="1rem" Color="#54575C" FontWeight="700">
                            {TITLES_FILTER.TYPE_TRANS}
                        </Text>
                        <Controller
                            name="filterTransactionType"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={!!errors.filterTransactionType?.label?.message}
                                    options={typeMovement}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                    }}
                                />
                            )}
                        />
                        {errors.filterTransactionType?.label?.message && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                {errors.filterTransactionType?.label?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                    <DateFilter
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        register={register}
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                        hourFilter={hourFilter}
                        setHourFilter={setHour}
                        isReset={isReset}
                        handleClick={handleClick}
                    />
                    <ContainerFlex Align="end" ColumnGap="16px">
                        <ContainerFlex
                            Radius="32px"
                            backG="#FFFFFF"
                            Padding="8px 16px"
                            Width="100%"
                            Cursor="pointer"
                            Border="1px solid #5A5AFF"
                            Height="40px"
                            onClick={() => {
                                setShowFilter(false);
                            }}
                        >
                            <Text FontWeight="700" Color="#5A5AFF" Cursor="pointer">
                                {TITLES_FILTER.CANCEL}
                            </Text>
                        </ContainerFlex>
                        <ButtonGeneral
                            text={TITLES_FILTER.APPLY}
                            width="100%"
                            clic={handleFormSubmit}
                        />
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
        </ContainerFlex>
    );
};
