export const EXPENSES = {
    filter: 'Filtrar',
    idReferenceNumber: 'ID/ No. de referencia',
    date: 'Fecha',
    total: 'Total',
    provider: 'Proveedor',
    paymentMethod: 'Método de pago',
    responsible: 'Responsable',
};

export const OPTIONS_MENU = {
    details: 'Ver detalles',
    download: 'Descargar Factura',
    duplicate: 'Duplicar',
};
