import React, {useState} from 'react';

import {SearchTextboxProps} from '@General/Atoms/SearchBoxResultList/interfaces';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {SearchContainer} from '@General/Atoms/SearchBoxResultList/styles';
import searchIcon from '@components/Branches/Images/AppMagnifierIcon.svg';
import clearIcon from '@images/close.svg';
import {ListResults} from '@General/Atoms/SearchBoxResultList/ListResults';

export const SearchTextbox = <T,>({
    placeholder,
    searchAction,
    resetAction,
    results,
    renderItem,
    onSelect,
    noDataContent,
    defaultValue,
    showSelection = false,
    minSearchCharacters = 3,
}: SearchTextboxProps<T>) => {
    const [viewList, setViewList] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const [searchTriggered, setSearchTriggered] = useState<boolean>(false);

    const validateSearch = searchText.trim().length >= minSearchCharacters;

    const handleReset = () => {
        setSearchText('');
        setSearchTriggered(false);
        resetAction();
        setViewList(false);
    };

    const handleSelect = (item: T) => {
        onSelect(item);

        if (!showSelection) {
            handleReset();
        } else {
            setSearchText('');
            setViewList(false);
        }
    };

    const handleSearch = () => {
        searchAction(searchText);
        setSearchTriggered(true);
        setViewList(true);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchText(value);
        if (value === '') {
            handleReset();
        } else if (value.length < minSearchCharacters && viewList) {
            setViewList(false);
        }
    };

    return (
        <>
            <ContainerFlex Align="baseline" Padding="0" Height="40px">
                <SearchInput Display="flex" Height="40px" Width="100%" Position="none">
                    <input
                        type="text"
                        value={defaultValue ? defaultValue : searchText}
                        placeholder={placeholder}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        onKeyUp={() => {
                            if (validateSearch) handleSearch();
                        }}
                    />
                    {searchTriggered || defaultValue ? (
                        <Image
                            src={clearIcon}
                            alt="icon-clear"
                            Cursor="pointer"
                            Width="24px"
                            Height="24px"
                            onClick={handleReset}
                        />
                    ) : (
                        <Image
                            src={searchIcon}
                            alt="icon-magnifier"
                            Cursor="pointer"
                            Width="24px"
                            Height="24px"
                        />
                    )}
                </SearchInput>
            </ContainerFlex>
            <SearchContainer>
                <ListResults
                    viewList={viewList}
                    setViewList={handleReset}
                    validateSearch={validateSearch}
                    results={results}
                    renderItem={renderItem}
                    onItemSelect={handleSelect}
                    noDataContent={noDataContent}
                />
            </SearchContainer>
        </>
    );
};
