import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {FieldValues} from 'react-hook-form';

import {ADDLEVEL} from '@/components/Articles/Redux/ManageTypes';
import {ListCatalogReset} from '@components/Articles/Redux/Actions/GetNewCatalogId';
import {fetchRouteInfoReset} from '@components/Articles/Redux/Actions/UpdateNewCatalogId';
import {fetchInfoListReset} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {resetDynamicFeatures} from '@/components/Catalogue/Redux/Actions/SaveNewDynamicFeatureActions';

export const AddLevelInit = () => {
    return {type: ADDLEVEL.LEVEL};
};

export const AddLevelSuccess = (data: AxiosResponse) => {
    return {
        type: ADDLEVEL.LEVEL_SUCCESS,
        payload: data,
    };
};

export const AddLevelError = (error: AxiosError) => {
    return {type: ADDLEVEL.LEVEL_ERROR, error: error};
};

export const GetAddLevel = (token: string, data: FieldValues) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateNewCatalogId}`,
                data,
                {headers}
            );
            dispatch(AddLevelSuccess(response));
            dispatch(ListCatalogReset());
            dispatch(fetchRouteInfoReset());
            dispatch(fetchInfoListReset());
            dispatch(resetDynamicFeatures());
            window.location.href = '#/Management/Articles';
        } catch (error) {
            dispatch(AddLevelError(error as AxiosError));
        }
    };
};
