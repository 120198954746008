import {CAR_CLIENT, CAR_CLIENT_ERROR, CAR_CLIENT_SUCCESS} from '@TypesQouter/TypesGeneral';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {shoppingCard} from '@ActionsQuoter/StepsActions';
import {GET_CARSHOOPINGID_TEMPORAL} from '@Shopify/Products/redux/types/ProductsShopifyTypes';
import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';
export const postCar = () => {
    return {
        type: CAR_CLIENT,
    };
};
export const postCarSuccess = (data: AxiosResponse<string>) => {
    return {
        type: CAR_CLIENT_SUCCESS,
        payload: data,
    };
};
export const postCarError = (value: unknown) => {
    return {
        type: CAR_CLIENT_ERROR,
        payload: value,
    };
};
export const shoopingValuator = (card: number) => ({
    type: GET_CARSHOOPINGID_TEMPORAL,
    payload: card,
});

export function postCarId(token: string, data: FieldValues, typeCar: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postCar());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GenerateCarId}`,
                data,
                {headers}
            );
            await dispatch(postCarSuccess(response));
            if (typeCar === 'Quoter') {
                dispatch(shoppingCard(response.data.data.carShoopingId));
            } else {
                dispatch(shoopingValuator(response.data.data.carShoopingId));
            }
        } catch (error) {
            dispatch(postCarError(error));
        }
    };
}
