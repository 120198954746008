import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableAuditAssignmentHistory() {
    return (
        <TableHead>
            <tr>
                <th>Sucursal</th>
                <th>Región</th>
                <th>Sucursal</th>
                <th>Fecha visita</th>
                <th>Auditor</th>
            </tr>
        </TableHead>
    );
}
