import {ResponseData} from '@/components/ManageUser/interface';
import {ADD_PAYMENT, ADD_PAYMENT_SUCCESS, ADD_PAYMENT_ERROR} from '@TypesLoan/types';
import {ContractResponse, IDataAddPayment} from '@/components/Loan/Redux/interfaces';

const initialState: IDataAddPayment = {
    loading: false,
    error: null,
    paymentResult: null,
};

const AddPaymentReducer = (
    state: IDataAddPayment = initialState,
    action: ResponseData
): IDataAddPayment => {
    switch (action.type) {
        case ADD_PAYMENT:
            return {
                ...state,
                loading: true,
                error: null,
                paymentResult: null,
            };
        case ADD_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                paymentResult: action.payload.data as ContractResponse,
            };
        case ADD_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload?.data?.message || 'Unknown error',
                paymentResult: null,
            };
        default:
            return state;
    }
};

export default AddPaymentReducer;
