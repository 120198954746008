import {
    ICredential,
    IPersonal,
    IRolesAdmin,
    IRolesPOS,
} from '@components/CreateUsers/Redux/interface';
import {Roles} from '@components/CreateUsers/interfaces';
import {SAVE_USER} from '@components/CreateUsers/Redux/Types/Types';
import {FieldValues} from 'react-hook-form';

export const personalData = (result: IPersonal) => {
    return {
        type: SAVE_USER.PERSONAL_DATA,
        personal: result,
    };
};

export const credentials = (result: ICredential) => {
    return {
        type: SAVE_USER.CREDENTIALS,
        credential: result,
    };
};
export const adminRoles = (result: IRolesAdmin[] | Roles[]) => {
    return {
        type: SAVE_USER.ADMIN_ROLES,
        roleAdmins: result,
    };
};
export const posRoles = (result: IRolesPOS[] | Roles[]) => {
    return {
        type: SAVE_USER.POS_ROLES,
        roleEmployees: result,
    };
};
export const addressAdd = (result: FieldValues) => {
    return {
        type: SAVE_USER.ADDRESS,
        aditionalInformationEmployee: result,
    };
};
export const typeActionUser = (result: string) => {
    return {
        type: SAVE_USER.TYPE,
        typeAction: result,
    };
};
export const resetUsers = () => ({type: SAVE_USER.RESET});
