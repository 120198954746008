import React from 'react';

import {RenewalExtensionMenuProps} from '@components/ShoppingCart/interface';
import {Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {renewalMenuStyles} from '@components/ShoppingCart/styles';
import {ENDORSEMENTS, OPTIONS} from '@components/ShoppingCart/constants';

const RenewalExtensionMenu: React.FC<RenewalExtensionMenuProps> = ({
    onSelectOption,
    onRemoveExtension,
}) => {
    return (
        <FlexPanel
            FlexDir="column"
            Align="flex-start"
            Self="stretch"
            Border="1px solid #F3F3F3"
            Background="#FFF"
            BoxShadow="0 0.688rem 1.663rem 0 rgba(0, 0, 0, 0.06)"
        >
            <Text
                {...renewalMenuStyles}
                Color="#2A2C2F"
                onClick={() => onSelectOption(OPTIONS.EXTENSION)}
            >
                {ENDORSEMENTS.EDIT}
            </Text>
            <Text {...renewalMenuStyles} Color="#2A2C2F">
                {ENDORSEMENTS.CHANGE_PARTIAL_PAYMENT}
            </Text>
            <Text {...renewalMenuStyles} Color="#2A2C2F" onClick={onRemoveExtension}>
                {ENDORSEMENTS.DELETE}
            </Text>
        </FlexPanel>
    );
};
export default RenewalExtensionMenu;
