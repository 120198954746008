import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {Table} from '@DeadLines/styles';
import {RowAuctionTable} from '@Auction/RowAuctionTable';
import {HeadTableRecolectionGold} from '@RecolectionGold/HeadTableRecolectionGold';
import {HeadTableIncidentsFoundry} from '@IncidentsFoundry/HeadTableIncidentsFoundry';
import {
    getEmployeeIncidents,
    getEmployeeResponsible,
    putAuditIncidents,
} from '@ActionsIncidentsFoundry/IncidentsFoundryActions';

export function IncidentsFoundry() {
    const {register, control, handleSubmit, getValues, setValue} = useForm({
        defaultValues: {
            pesoTotal: '',
            pesoAccesorios: '',
            pesoMetal: '',
            diferenciaPesoMetal: '',
            precioVenta: '',
            costo: '',
            avaluo: '',
            noSeguribolsa: '',
            estadoConservacion: '',
            incidencia: '',
            incidencias: '',
            montoARetener: '',
            kilataje: 0,
            employeeincident: 0,
            platform: '',
            employeeresponsible: 0,
        },
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'location',
    });
    const [Responsable, setResponsable] = useState(false);
    function onSubmit(data) {}
    const tipoIncidencia = (value) => {
        let NombreIncidencia = infoaudit.incidencias.filter((item) => item.value === value);
        return NombreIncidencia[0].label;
    };
    const incidencia = (value) => {
        if (fields.length > 0) {
            let objetos = fields.map((item, index) => {
                let jason = {
                    idUsuario: item.value.value,
                    porcentaje: Number(item.singleValue),
                    monto: Number((auctionsaudit.montoARetener * item.singleValue) / 100),
                };
                return jason;
            });
            let json = {
                idSucursal: assignmentvalidation.idSucursal,
                idAsignacion: assignmentvalidation.idAsignacion,
                sku: sku,

                pesoTotal: infoaudit.pesoTotal,

                pesoAccesorios: infoaudit.pesoAccesorios,

                kilataje: infoaudit.kilataje,

                incidencia: infoaudit.incidencia,

                idTipoIncidencia: infoaudit.idTipoIncidencia,

                montoARetener: infoaudit.montoARetener,

                observaciones: infoaudit.observaciones,
                agregarComoAccesorio: value.agregarComoAccesorio,
                resguardo: value.resguardo,
                noSeguribolsa: value.noSeguribolsa === undefined ? '' : value.noSeguribolsa,
                idResponsableSucursal:
                    value.employeeresponsible === undefined ? 0 : value.employeeresponsible.value,
                ahora: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                usuariosIncidentes: objetos,
            };

            dispatch(
                putAuditIncidents(
                    json,
                    token,
                    assignmentvalidation.idSucursal,
                    assignmentvalidation.idAsignacion
                )
            );
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Guardar incidencia',
                text: 'Es necesario agregar un usuario para finalizar',
            });
        }
    };
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const {sku} = useParams();
    const dispatch = useDispatch();
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    useEffect(() => {
        if (assignmentvalidation && token) {
            const getEmployeeIncidentsSelect = () => {
                dispatch(getEmployeeIncidents(sku, token));
            };
            getEmployeeIncidentsSelect();
            const getEmployeeResponsibleSelect = () => {
                dispatch(getEmployeeResponsible(assignmentvalidation.idSucursal, token));
            };
            getEmployeeResponsibleSelect();
        }
    }, [token]);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: 'white',
        }),
    };

    const infoaudit = useSelector((state) => {
        return state.auctions.infoaudit;
    });
    const auctions = useSelector((state) => {
        return state.auctions.auctions;
    });
    const auctionsaudit = useSelector((state) => {
        return state.auctions.auctionsaudit;
    });
    const assignmentvalidation = useSelector((state) => {
        return state.assignmentvalidation.assignmentvalidation;
    });
    const employeeincident = useSelector((state) => {
        return state.incidentsfoundry.employeeincidents;
    });
    const employeeresponsible = useSelector((state) => {
        return state.incidentsfoundry.employeeresponsible;
    });
    return (
        <>
            <div className="pt-2 pb-3 flex align-items-center w-90 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Sucursal</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link to={`/Fundiciones/Auditoria`}>
                    <span className="child-breadcrumb">Verificación de Datos</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link
                    to={`/Fundiciones/Recoleccion/${assignmentvalidation.idSucursal}/${assignmentvalidation.idAsignacion}`}
                >
                    <span className="child-breadcrumb">Recolección de Oro</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">SKU {sku}</span>
            </div>
            <Table className="mt-5 w-50 mx-auto">
                <HeadTableRecolectionGold />
                <RowAuctionTable {...auctions} />
            </Table>
            <div className="w-90 mx-auto pt-4 pb-3">
                Se detectó una incidencia en este artículo, calcula la incidencia con préstamo
            </div>
            {auctionsaudit && (
                <div className="flex w-90 mx-auto pt-3 pb-5 justify-content-between">
                    <span>Tipo de incidencia: {tipoIncidencia(infoaudit.idTipoIncidencia)}</span>
                    <span>
                        Monto a retener:{' '}
                        <span className="required">
                            {formatterDolar.format(auctionsaudit.montoARetener)}
                        </span>
                    </span>
                    <span>
                        Factor de venta:{' '}
                        <span>{formatterDolar.format(auctionsaudit.precioVenta)}</span>
                    </span>
                </div>
            )}
            <form className="flex w-90 mx-auto" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-50">
                    <div className="flex">
                        <span className="font-number">1.</span>
                        <span>Selecciona el usuario del nombre incidente</span>
                    </div>
                    <div className="flex pt-4 pl-3">
                        <label className="required">Usuario incidente</label>
                    </div>
                    <div className="flex justify-content-between pl-3 pr-5">
                        <Controller
                            as={Select}
                            className="w-50 "
                            options={employeeincident}
                            name="employeeincident"
                            isClearable
                            onChange={(e) => setValue('employeeincident', 'value')}
                            ref={(e) => register({name: 'platform'})}
                            control={control}
                            placeholder="Seleccione usuario incidente"
                            rules={{required: true}}
                        />
                        <input
                            {...register('email', {})}
                            className="w-10 input-form-incidents"
                            type="number"
                        />
                        <button
                            className="btn-add-incidents pl-4 pr-4"
                            type="button"
                            onClick={() =>
                                append({
                                    singleValue: getValues('email'),
                                    value: getValues('employeeincident'),
                                })
                            }
                        >
                            AGREGAR
                        </button>
                    </div>
                    <Table className="mt-5 w-100 mx-auto">
                        <HeadTableIncidentsFoundry />
                    </Table>
                    {fields.map((item, index) => {
                        return (
                            <div
                                className="flex justify-content-between w-90 mx-auto"
                                key={item.id}
                            >
                                <Controller
                                    className="w-100 color-white"
                                    as={Select}
                                    styles={customStyles}
                                    options={employeeincident}
                                    name={`test[${index}].firstName`}
                                    defaultValue={item.value}
                                    control={control}
                                    placeholder="Seleccione usuario incidente"
                                />
                                <Controller
                                    className="w-30 border-0"
                                    as={<input />}
                                    name={`test[${index}].lastName`}
                                    control={control}
                                    defaultValue={item.singleValue} // make sure to set up defaultValue
                                />
                                <Controller
                                    className="w-30 border-0"
                                    as={<input />}
                                    name={`test[${index}].monto`}
                                    control={control}
                                    defaultValue={formatterDolar.format(
                                        (auctionsaudit.montoARetener * item.singleValue) / 100
                                    )} // make sure to set up defaultValue
                                />
                                <button
                                    className="btn-add-incidents border-0"
                                    type="button"
                                    onClick={() => remove(index)}
                                >
                                    <i className="material-icons required ">clear</i>
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div className="w-50 flex flex-column">
                    <div className="flex">
                        <span className="font-number">2. </span>
                        <span>
                            {' '}
                            En caso de que el tipo de incidencia sea PSG, selecciona una de las
                            siguientes opciones:
                        </span>
                    </div>
                    <div className="flex align-items-center pl-3 pt-5 pb-2">
                        <input
                            {...register('agregarComoAccesorio', {
                                required: 'Required',
                            })}
                            type="checkbox"
                        />
                        <span className="pl-2">Agregar al paquete</span>
                    </div>
                    <div className="flex align-items-center pl-3 pt-2">
                        <input
                            {...register('resguardo', {
                                required: 'Required',
                            })}
                            type="checkbox"
                            onChange={() => {
                                setResponsable(!Responsable);
                            }}
                        />
                        <span className="pl-2 ">
                            Crear una nueva seguribolsa para su resguardo en bóveda
                        </span>
                    </div>
                    {Responsable && (
                        <div className="flex pt-4 pl-3 pt-5 pb-5">
                            <div className="flex flex-column pr-3">
                                <label className="required">No. Seguribolsa</label>
                                <input
                                    {...register('noSeguribolsa', {
                                        required: 'Required',
                                    })}
                                    className="w-100 input-form-incidents"
                                />
                            </div>
                            <div className="flex flex-column pl-3 w-50">
                                <label className="required">Responsable de la sucursal</label>
                                <Controller
                                    as={Select}
                                    className="w-100"
                                    options={employeeresponsible}
                                    name="employeeresponsible"
                                    isClearable
                                    onChange={(e) => setValue('employeeincident', 'value')}
                                    ref={(e) => register({name: 'platform'})}
                                    control={control}
                                    placeholder="Seleccione responsable sucursal"
                                    rules={{required: true}}
                                />
                            </div>
                        </div>
                    )}
                    <div className="flex justify-content-end pt-5 ">
                        <button
                            type="submit"
                            className="w-30 btn-add-incidents h-40"
                            onClick={() =>
                                incidencia(
                                    getValues([
                                        'employeeresponsible',
                                        'noSeguribolsa',
                                        'test',
                                        'resguardo',
                                        'agregarComoAccesorio',
                                    ])
                                )
                            }
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}
