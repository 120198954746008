import {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_NEWCATALOGID_ERROR,
    TARGET_CATALOG_ID,
    UPDATE_NEWCATALOGID_START,
    UPDATE_NEWCATALOGID_SUCCESS,
    SAVE_ROUTE_CATALOG,
    RESET_ROUTE_CATALOG,
} from '@components/Articles/Redux/Types';
import {IUpdateCatalogProps} from '@components/Articles/interface';
import {IAddRoute, IUse} from '@/components/Articles/Redux/interfaces';

const initialState: IUpdateCatalogProps = {
    updateCatalog: null,
    loading: false,
    error: false,
    errorData: null,
    labelData: null,
    labelRoute: {
        temporalRoute: [],
        route: [],
    },
};

const UpdateNewCatalogId = (
    state: IUpdateCatalogProps = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
        value: IUse;
        date: IAddRoute;
    }
): IUpdateCatalogProps => {
    switch (action.type) {
        case UPDATE_NEWCATALOGID_START:
            return {...state, loading: true, error: false};
        case UPDATE_NEWCATALOGID_SUCCESS:
            return {...state, loading: false, error: false, updateCatalog: action.payload.data};
        case CREATE_NEWCATALOGID_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case TARGET_CATALOG_ID:
            return {...state, loading: false, error: false, labelData: action.value};
        case SAVE_ROUTE_CATALOG:
            return {...state, loading: false, error: false, labelRoute: action.date};
        case RESET_ROUTE_CATALOG:
            return initialState;
        default:
            return state;
    }
};
export default UpdateNewCatalogId;
