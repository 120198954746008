import {IReducerType} from '@/components/Roles/interface';
import {
    POST_CREATE_LEVELS_START,
    POST_CREATE_LEVELS_ERROR,
    POST_CREATE_LEVELS_SUCCESS,
    POST_CREATE_LEVELS_FINISH,
} from '@TypesOrgCreation/types';

const initialState = {
    createLevel: false,
    loading: false,
    error: false,
};
const CreateLevels = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CREATE_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_CREATE_LEVELS_SUCCESS:
            return {
                ...state,
                createLevel: action.payload.data.data,
            };
        case POST_CREATE_LEVELS_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_CREATE_LEVELS_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default CreateLevels;
