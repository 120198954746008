import {AsigmentEdit} from '@/components/Foundry/interfaces';
import {AppDispatch} from '@/config/store';
import {
    GET_ASSIGMENT_BY_ID,
    GET_ASSIGMENT_BY_ID_SUCCESS,
    GET_ASSIGMENT_BY_ID_ERROR,
    EDIT_ASSIGMENT_BY_ID,
    EDIT_ASSIGMENT_BY_ID_SUCCESS,
    EDIT_ASSIGMENT_BY_ID_ERROR,
} from '@TypesEditAssignment/EditAssignmentTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
import Swal from 'sweetalert2';

export const fetchGetAssignmentById = () => {
    return {
        type: GET_ASSIGMENT_BY_ID,
    };
};
export const fetchGetAssignmentByIdSuccess = (result: {data: unknown}) => {
    return {
        type: GET_ASSIGMENT_BY_ID_SUCCESS,
        value: result,
    };
};

export const fetchGetAssignmentByIdError = () => {
    return {
        type: GET_ASSIGMENT_BY_ID_ERROR,
    };
};
export function getAssignmentById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAssignmentById());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentById.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetAssignmentByIdSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAssignmentByIdError());
        }
    };
}

export const fetchPutAssignmentById = () => {
    return {
        type: EDIT_ASSIGMENT_BY_ID,
    };
};
export const fetchPutAssignmentByIdSuccess = () => {
    return {
        type: EDIT_ASSIGMENT_BY_ID_SUCCESS,
    };
};

export const fetchPutAssignmentByIdError = () => {
    return {
        type: EDIT_ASSIGMENT_BY_ID_ERROR,
    };
};
export function putEditAssignmentById(data: AsigmentEdit, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutAssignmentById());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EditAssignmentById}`,
                data,
                {headers}
            );
            dispatch(fetchPutAssignmentByIdSuccess());
            Swal.fire({
                icon: 'success',
                title: 'Editar asignación',
                text: '¡La auditoria ha sido Editada con éxito!',
            });
        } catch (error) {
            dispatch(fetchPutAssignmentByIdError());
            Swal.fire({
                icon: 'error',
                title: 'Editar asignación',
            });
        }
    };
}
