import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {
    IClientProofStep,
    IdentificationType,
    SelectOption,
} from '@components/Shopify/ListClient/interfaces';
import {getIdentificationTypeList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import CustomDatePicker from '@components/Shopify/ListClient/DatePicker';

const PersonalDataNationRfcCurpInfo = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const {token, register, errors, control, setValue} = props;

    const identificationTypes: IdentificationType[] | null = useSelector(
        (state: RootState) => state.newClientProcess.identificationTypes
    );
    let formattedIdentificationType: SelectOption[] = [];
    if (identificationTypes) {
        formattedIdentificationType = identificationTypes.map(
            (identificationTypes: IdentificationType) => ({
                value: identificationTypes.id,
                label: identificationTypes.identificationName,
            })
        );
    }

    useEffect(() => {
        if (token) {
            dispatch(getIdentificationTypeList(token));
        }
    }, [dispatch, token]);

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.NACIONALIDAD}</Text>
                <Controller
                    name="nationality"
                    control={control}
                    render={() => (
                        <Input {...register('nationality')} error={!!errors.nationality?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.nationality?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.TIPO_DE_IDENTIFICACION}</Text>
                <Controller
                    name="catIdentificationTypeId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.catIdentificationTypeId?.message}
                            options={formattedIdentificationType}
                            onChange={(selectedOption) => {
                                setValue('catIdentificationTypeId', selectedOption);
                                if (onChange) onChange(selectedOption);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.catIdentificationTypeId?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.NUMERO_DE_IDENTIFICACION}</Text>
                <Controller
                    name="identificactionNumber"
                    control={control}
                    render={() => (
                        <Input
                            {...register('identificactionNumber')}
                            error={!!errors.identificactionNumber?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.identificactionNumber?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.VIGENCIA}</Text>
                <CustomDatePicker name="validity" placeholder="dd/mm/yyyy" openOnError={true} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataNationRfcCurpInfo;
