import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import * as styles from '@/components/Quoter/stylesConstants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Input} from '@components/NavBarPOS/SearchComponent/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {getAllUserRole} from '@Roles/CreateRoles/Redux/Actions/GetSearchUserByRole';

export const AssigmentUserByRolesSearch = () => {
    const [userName, setUserName] = useState('');
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch = useDispatch();
    const {idRole} = useSelector((state: RootState) => state.RoleById);
    const handleSearch = (userName: string) => {
        setUserName(userName);
    };

    useEffect(() => {
        const Search = {
            roleId: idRole,
            search: userName,
        };
        if (token && userName.length > 2) dispatch(getAllUserRole(token, Search));
    }, [userName]);
    useEffect(() => {
        const Search = {
            roleId: idRole,
            search: '',
        };
        if (token && userName.length === 0) dispatch(getAllUserRole(token, Search));
    }, [userName]);

    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                Height="2.5rem"
                Radius="2rem"
                Self="start"
                Border="1px solid #E8E9EA"
                GridColumns="90% 10%"
                Padding="0.5rem 1rem 0.5rem 0rem"
            >
                <Input
                    type="input"
                    placeholder="Buscar"
                    value={userName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleSearch(e.target.value)
                    }
                />
                <Icons
                    {...styles.iconSearch}
                    onClick={() => {
                        if (userName.length > 0) {
                            const Search = {
                                roleId: idRole,
                                search: userName,
                            };
                            setUserName('');
                            dispatch(getAllUserRole(token, Search));
                        }
                    }}
                >
                    {userName.length > 0 ? 'close' : 'search'}{' '}
                </Icons>
            </ContainerFlex>
            {false && (
                <ContainerFlex
                    Position="absolute"
                    PositionTop="16rem"
                    backG="#FFFFFF"
                    ZIndex="101"
                    Width="38.31rem"
                    Height="2.69rem"
                    Padding="12px 8px"
                    Border="1px solid #E8E9EA"
                >
                    <Text Color="#54575C" FontWeight="500" wSpace="pre">
                        No existen más usuarios,{' '}
                        <Text
                            Color="#5A5AFF"
                            FontWeight="500"
                            wSpace="pre"
                            Cursor="pointer"
                            onClick={() => (window.location.href = '#/Users/')}
                        >
                            crea usuarios{' '}
                        </Text>
                        primero y asigna su rol.
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
