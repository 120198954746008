import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_NEWCATALOGID_ERROR,
    LIST_NEWCATALOGID_RESET,
    LIST_NEWCATALOGID_START,
    LIST_NEWCATALOGID_SUCCESS,
    TARGET_LEVEL_CATALOG,
} from '@components/Articles/Redux/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';

export const fetchDataCatalogsStart = () => {
    return {
        type: LIST_NEWCATALOGID_START,
    };
};
export const fetchDataCatalogSuccess = (result: AxiosResponse) => {
    return {
        type: LIST_NEWCATALOGID_SUCCESS,
        payload: result,
    };
};
export const fetchDataCatalogError = (error: AxiosError) => {
    return {
        type: LIST_NEWCATALOGID_ERROR,
        error: error,
    };
};
export function GetDataNewCatalogId(filters: {level: number; levelId: number}, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataCatalogsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetNewCatalogId}${queryString}
            `,
                {headers}
            );
            dispatch(fetchDataCatalogSuccess(response));
        } catch (error) {
            dispatch(fetchDataCatalogError(error as AxiosError));
        }
    };
}

export const fetchTargetLevel = (value: number) => {
    return {
        type: TARGET_LEVEL_CATALOG,
        value: value,
    };
};
export const ListCatalogReset = () => {
    return {
        type: LIST_NEWCATALOGID_RESET,
    };
};
