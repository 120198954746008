import {
    PUT_BRANCH_STATUS,
    PUT_BRANCH_STATUS_ERROR,
    PUT_BRANCH_STATUS_FINISH,
    PUT_BRANCH_STATUS_SUCCESS,
} from '@components/Branches/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    branchStatus: false,
};

const PutUpdateBranchStatus = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_BRANCH_STATUS:
            return {
                ...state,
                loading: true,
            };
        case PUT_BRANCH_STATUS_SUCCESS:
            return {
                ...state,
                branchStatus: action.payload.data,
            };
        case PUT_BRANCH_STATUS_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_BRANCH_STATUS_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutUpdateBranchStatus;
