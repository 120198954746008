import React, {useEffect, useState} from 'react';
import arrowDownSmall from '@/images/arrow_down_small.svg';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {DropDownMenu} from '@/components/General/MenuAmazon/ReutilizableComponent/FloatMenu';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Company, FilterParams, RequestStatus} from '@/components/MyAccount/interfaces';

export interface IPropsFilterDropdown {
    options: unknown[];
    name: string;
    handleFilter: (value: FilterParams) => void;
    value: number | null;
    filterParams: FilterParams;
}

const FilterDropdown = ({
    options,
    handleFilter,
    name,
    value,
    filterParams,
}: IPropsFilterDropdown) => {
    const [menuDisplayStatus, setMenuDisplayStatus] = useState(false);
    const [selectedOption, setSelectedOption] = useState<unknown>(null);
    const Height = options.length * 37;
    const Bottom = '-' + (Height + 6);
    const selectedOptionHandler = (option: unknown) => {
        setSelectedOption(option);
        setMenuDisplayStatus(false);
        handleFilter({
            ...filterParams,
            [name]: (option as RequestStatus).id || (option as Company).empresaId,
        });
    };
    const label = selectedOption
        ? (selectedOption as Company).nombreEmpresa || (selectedOption as RequestStatus).descripcion
        : 'Selecionar';

    useEffect(() => {
        if (!value) {
            setSelectedOption(null);
            return;
        }
        const selected = options.find((option) => {
            return (
                (option as RequestStatus).id === value || (option as Company).empresaId === value
            );
        });
        setSelectedOption(selected || null);
    }, [value]);

    return (
        <ContainerFlex
            Width="243px"
            Padding="0 16px"
            Display="flex"
            FontSize="1rem"
            Position="relative"
            Border="1px solid #E8E9EA"
            Radius="32px"
            Height="46px"
            HBoxShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
            HBorderColor="#5A5AFF"
        >
            <ButtonGenerals
                onClick={() => setMenuDisplayStatus(!menuDisplayStatus)}
                Width="100%"
                BackGC="transparent"
                HBackG="transparent"
                Padding="0"
            >
                <Text
                    Width="100%"
                    Color={label !== 'Selecionar' ? '#1D1E20' : '#A1A5AA'}
                    LetterSpacing="0.3px"
                    FontSize="1rem"
                    FontFamily="Nunito"
                >
                    {label || 'Selecionar'}
                </Text>
                <Image
                    src={arrowDownSmall}
                    Transform={menuDisplayStatus ? 'rotate(180deg)' : 'rotate(0deg)'}
                />
            </ButtonGenerals>

            {menuDisplayStatus && (
                <DropDownMenu
                    OutSideClick={() => setMenuDisplayStatus(false)}
                    MenuDisplayStatus={menuDisplayStatus}
                    Values={[]}
                    Bottom={Bottom + 'px'}
                    Left="0"
                    Width="100%"
                    Height={Height + 'px'}
                    Position="absolute"
                    BoxShadow="none"
                    Valued={
                        <>
                            {options.map((option, index) => (
                                <Text
                                    key={index}
                                    Padding="8px 16px"
                                    Color="#1D1E20"
                                    LetterSpacing="0.3px"
                                    FontSize="1rem"
                                    FontFamily="Nunito"
                                    Cursor="pointer"
                                    onClick={() => selectedOptionHandler(option)}
                                >
                                    {(option as Company).nombreEmpresa ||
                                        (option as RequestStatus).descripcion}
                                </Text>
                            ))}
                        </>
                    }
                />
            )}
        </ContainerFlex>
    );
};

export default FilterDropdown;
