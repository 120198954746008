import {DEL_USERS} from '@components/LocationsBranch/Redux/types';
import {IDellUsers, IDellUsersRedux} from '@components/LocationsBranch/interfaces';

const initialState: IDellUsers = {
    idUser: 0,
    saveAllUsers: [],
};

const delUsersLoctations = (state = initialState, action: IDellUsersRedux): IDellUsers => {
    switch (action.type) {
        case DEL_USERS.ADD_ID_USER: {
            return {
                ...state,
                idUser: action.idUser,
            };
        }
        case DEL_USERS.SAVE_USERS_ID: {
            return {
                ...state,
                saveAllUsers: action.saveAllUsers,
            };
        }
        case DEL_USERS.RESET_SAVE: {
            return initialState;
        }
        default:
            return state;
    }
};

export default delUsersLoctations;
