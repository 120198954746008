import styled from '@emotion/styled';
import {IPropsImageLogo} from '@/components/TransactionsDetails/Operational/interface';
export const ImageLogo = styled.img<IPropsImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: ${(props) => props.Margin};
`;
export const IconPhone = styled.img`
    width: 322px;
    height: 32px;
    border-radius: 8px;
`;

export const TextS = styled.span`
    color: #a1a5aa;
    font-size: 0.75rem;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e8e9ea;
`;
