import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_QUERYLOCATIONS_START,
    GET_QUERYLOCATIONS_ERROR,
    GET_QUERYLOCATIONS_SUCCESS,
    POST_ARTICLELOCATIONS_START,
    POST_ARTICLELOCATIONS_SUCCESS,
    POST_ARTICLELOCATIONS_ERROR,
    LOCATIONSID_START,
    LOCATIONSID_SUCCESS,
    LOCATIONSID_ERROR,
    BRANCH_NAME,
} from '@TypesManageLocations/ManageLocationsTypes';

const initialState = {
    branchName: '',
    producst: null,
    productId: null,
    loading: true,
    error: false,
};

const manageLocationsReducers = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_QUERYLOCATIONS_START:
            return {
                ...state,
                productId: null,
                producst: null,
                loading: true,
                error: false,
            };
        case GET_QUERYLOCATIONS_SUCCESS:
            return {
                ...state,
                producst: action.payload,
                loading: false,
                error: false,
            };
        case GET_QUERYLOCATIONS_ERROR:
            return {
                ...state,
                producst: null,
                loading: false,
                error: true,
            };
        case LOCATIONSID_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case LOCATIONSID_SUCCESS:
            return {
                ...state,
                productId: action.payload,
                loading: false,
                error: false,
            };
        case LOCATIONSID_ERROR:
            return {
                ...state,
                producst: null,
                loading: false,
                error: true,
            };
        case BRANCH_NAME:
            return {
                ...state,
                branchName: action.payload,
            };
        case POST_ARTICLELOCATIONS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_ARTICLELOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ARTICLELOCATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default manageLocationsReducers;
