export const months = [
    {value: 1, label: 'Enero'},
    {value: 2, label: 'Febrero'},
    {value: 3, label: 'Marzo'},
    {value: 4, label: 'Abril'},
    {value: 5, label: 'Mayo'},
    {value: 6, label: 'Junio'},
    {value: 7, label: 'Julio'},
    {value: 8, label: 'Agosto'},
    {value: 9, label: 'Septiembre'},
    {value: 10, label: 'Octubre'},
    {value: 11, label: 'Noviembre'},
    {value: 12, label: 'Diciembre'},
];

export const monthsString = [
    {value: "01", label: '01'},
    {value: "02", label: '02'},
    {value: "03", label: '03'},
    {value: "04", label: '04'},
    {value: "05", label: '05'},
    {value: "06", label: '06'},
    {value: "07", label: '07'},
    {value: "08", label: '08'},
    {value: "09", label: '09'},
    {value: "10", label: '10'},
    {value: "11", label: '11'},
    {value: "12", label: '12'},
];

export const SpanishLocaleDayText = {
    weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
};

export const BUTTON_ACTION = {
    CANCEL: 'Cancelar',
    CONTINUE: 'Continuar',
};
