import {Product} from '@DragAndDrop/interfaces';
export const ACTIONS_TYPE_PRODUCTS = {
    GetProducts: 'GET_PRODUCTS',
    UpDateProducts: 'UPDATE_PRODUCTS',
    ShoppingCar: 'OUT_PRODUCTS',
    DeleteProduct: 'DELETE_PRODUCT',
    DeleteProducts: 'DELETE_PRODUCTS',
};

export const GetProducts = (values: Product[]) => {
    return {
        type: ACTIONS_TYPE_PRODUCTS.GetProducts,
        payload: values,
    };
};

export const DeleteProducts = () => {
    return {
        type: ACTIONS_TYPE_PRODUCTS.DeleteProducts,
    };
};
export const UpdateProducts = (value: Product[]) => {
    return {
        type: ACTIONS_TYPE_PRODUCTS.UpDateProducts,
        payload: value,
    };
};

export const DeleteProduct = (value: string) => {
    return {
        type: ACTIONS_TYPE_PRODUCTS.DeleteProduct,
        payload: value,
    };
};

export const ShoppingCar = (value: Product[]) => {
    return {
        type: ACTIONS_TYPE_PRODUCTS.ShoppingCar,
        payload: value,
    };
};
