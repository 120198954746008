import axios from 'axios';
import {
    NEW_PASSWORD_USER_REQUEST,
    NEW_PASSWORD_USER_SOUCCES,
    NEW_PASSWORD_USER_ERROR,
} from '@components/MarketForgottenLogin/Redux/types';

import process from 'process';

import {URL} from '@config/constants/index';

export const PostNewVerifyCodeResquestClient = () => {
    return {
        type: NEW_PASSWORD_USER_REQUEST,
    };
};
export const PostNewVeryfyCodeSuccessClient = (userData) => {
    return {
        type: NEW_PASSWORD_USER_SOUCCES,
        payload: userData,
    };
};

export const PostNewVeryfyCodeFailureClient = (err) => {
    return {
        type: NEW_PASSWORD_USER_ERROR,
        payload: err,
    };
};

export const NewPasswordUserData = (newUserData, navigate) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ',
    };

    return async (dispatch) => {
        dispatch(PostNewVerifyCodeResquestClient());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdatePasswordClient}`,
                newUserData,
                {
                    headers,
                }
            );
            dispatch(PostNewVeryfyCodeSuccessClient(response));
            navigate('/Login/PasswordSouccess');
        } catch (error) {
            dispatch(PostNewVeryfyCodeFailureClient(error));
        }
    };
};
