import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {TermAndConditions} from '@Login/MarketPlaceLogin/TermsAndConditions';
import {AtaskateHeader} from '@components/Login/MarketPlaceLogin/Ataskateheader';
import {LoginClientMarket} from '@/components/MarketAccessLogin/LoginUserMarket';

export const AccountContainerLogin = () => {
    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <AtaskateHeader />
            <ContainerFlex backG="#E5E5FF" FlexDir="column" Width="50%">
                <LoginClientMarket />
                <TermAndConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
