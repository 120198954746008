import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@/components/Quoter/stylesConstants';
import {ITabsContent} from '@Quoter/inferfaces';
import {History} from '@Quoter/Steps/StepOne/History';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {DetailsStadisticGlobal} from '@Quoter/Redux/Actions/StatusStepOne';
import {APPRASIALTEMPORAL, HISTORY, LEVELS, WITHOUTPRICE} from '@Quoter/constants';
import {withoutPrices} from '@/hooks/currentFormatUtils';

export const TabsStadisticsCompany = (props: ITabsContent) => {
    const {token} = props;
    const [history, setHistory] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const {productSearch, stadistics} = useSelector((state: RootState) => state.saveEndeavor);
    const infoStadistics = useSelector((state: RootState) => state.getInfoContracts.data);
    const stateConservationIdEdit = infoStadistics.carPledge?.[0].stateConservationId;
    const {detailsGlobal} = useSelector((state: RootState) => state.statusQuoter);
    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;

    const conservationState = () => {
        if (stadistics.state?.value) {
            const STATE: {[key: number]: string} = {
                1: 'Bueno',
                2: 'Regular',
                3: 'Malo',
            };
            return STATE[stadistics.state?.value];
        }
        if (stateConservationIdEdit) {
            const STATE: {[key: number]: string} = {
                1: 'Bueno',
                2: 'Regular',
                3: 'Malo',
            };
            return STATE[stateConservationIdEdit];
        }
    };

    useEffect(() => {
        if (
            productSearch.levelsIds[LEVELS.ARTICLE] !== undefined &&
            stadistics.state?.value &&
            companyId
        ) {
            dispatch(
                DetailsStadisticGlobal(token, {
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stadistics.state?.value,
                })
            );
        }
    }, [productSearch.levelsIds[LEVELS.ARTICLE], stadistics.state?.value]);

    return (
        <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
            <Text Color="#54575C" {...style.tabsBlock}>
                {APPRASIALTEMPORAL.GLOBAL}
            </Text>
            {detailsGlobal ? (
                <ContainerFlex
                    Display="grid"
                    GridColumns="repeat(4, 1fr)"
                    Height="5.625rem"
                    ColumnGap="16px"
                >
                    <ContainerFlex {...style.miniContent} backG="#FAFAFA" Padding="8px">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE1}</Text>
                        <ContainerFlex {...style.tabsMinicontnt}>
                            {conservationState() ? (
                                <>
                                    <Text {...style.textValues}>
                                        {withoutPrices(
                                            detailsGlobal?.regularValue,
                                            WITHOUTPRICE.DOLLAR
                                        )}
                                    </Text>
                                    <Text {...style.subtitles}>{conservationState()}</Text>
                                </>
                            ) : (
                                <ContainerFlex
                                    {...style.titleMinicontent}
                                    Justify="start"
                                    FontWeight="400"
                                >
                                    {HISTORY.WITHOUTINTORMATION}
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE2}</Text>
                        <ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginR="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(detailsGlobal?.minimumLoan, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE2}</Text>
                            </ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginL="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(detailsGlobal?.maximumLoan, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE3}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE3}</Text>
                        <ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginR="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(detailsGlobal?.minimumSale, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE2}</Text>
                            </ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginL="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(detailsGlobal?.maximumSale, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE3}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE4}</Text>
                        <ContainerFlex {...style.tabsMinicontnt}>
                            <Text {...style.textValues}>
                                {withoutPrices(detailsGlobal?.percentage, WITHOUTPRICE.AVG)}
                            </Text>
                            <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE4}</Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            ) : (
                <ContainerFlex {...style.titleMinicontent} FontWeight="400" Height="5.625rem">
                    {HISTORY.WITHOUTINTORMATION}
                </ContainerFlex>
            )}
            <History history={history} setHistory={setHistory} token={token} />
        </ContainerFlex>
    );
};
