import {ISecurityBagRedux, ISecurityBagWeigh} from '@/components/Foundry/interfaces';
import {
    GET_SECURITYBAG_WEIGHT_JIRACAS,
    GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS,
    GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR,
    POST_SECURITYBAG_WEIGHT_JIRACAS,
    POST_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS,
    POST_SECURITYBAG_WEIGHT_JIRACAS_ERROR,
    GET_SECURITYBAG_WEIGHT_ESCORIA,
    GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
    GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    POST_SECURITYBAG_WEIGHT_ESCORIA,
    POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
    POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    GET_SECURITYBAG_WEIGHT_GOLD,
    GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
    GET_SECURITYBAG_WEIGHT_GOLD_ERROR,
    POST_SECURITYBAG_WEIGHT_GOLD,
    POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
    POST_SECURITYBAG_WEIGHT_GOLD_ERROR,
} from '@TypesSecurityBagWeight/SecurityBagWeightJiracas';

const initialState: ISecurityBagWeigh = {
    securitybagweightjiracas: {},
    securitybagweightescoria: {},
    securitybagweightgold: {},
    error: false,
    loading: false,
};

const securitybagweightjiracas = (
    state = initialState,
    action: ISecurityBagRedux
): ISecurityBagWeigh => {
    switch (action.type) {
        case GET_SECURITYBAG_WEIGHT_JIRACAS:
            return {
                ...state,
                loading: true,
            };
        case GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS:
            return {
                ...state,
                securitybagweightjiracas: action.securitybagweightjiracas,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_JIRACAS:
            return {
                ...state,
                loading: true,
            };
        case POST_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_JIRACAS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_ESCORIA:
            return {
                ...state,
                securitybagweightescoria: {},
                loading: true,
            };
        case GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS:
            return {
                ...state,
                securitybagweightescoria: action.securitybagweightescoria,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_ESCORIA:
            return {
                ...state,
                loading: true,
            };
        case POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_GOLD:
            return {
                ...state,
                securitybagweightgold: {},
                loading: true,
            };
        case GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS:
            return {
                ...state,
                securitybagweightgold: action.securitybagweightgold,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_GOLD:
            return {
                ...state,
                loading: true,
            };
        case POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_GOLD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default securitybagweightjiracas;
