import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import ExpandeArrow from '@images/expande.svg';
import {
    EXPENSE_DETAIL_COMPONENT,
    HeaderTitleExpenseDetail,
} from '@/components/CashFlowExpenseDetails/operational/constants';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {IExpensedata, IExpenses} from './interfaces';

export const ExpenseDetailTable = () => {
    const [expenses, setExpenses] = useState<IExpenses | undefined>();
    const expenseDetailData = useSelector(
        (state: RootState) => state.getExpenseDetails.expenseDetails
    );

    const formatNumber = (amount: number): string => {
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    useEffect(() => {
        setExpenses(expenseDetailData);
    }, [expenseDetailData]);

    return (
        <ContainerFlex Padding="16px 24px" FlexDir="column" Gap="16px">
            <ContainerFlex Justify="start">
                <Text Color=" #2A2C2F" FontWeight="500" FontSize="1.25rem">
                    {EXPENSE_DETAIL_COMPONENT.DETAILS}
                </Text>
            </ContainerFlex>

            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="repeat(5, 1fr)"
                    Border="0 0 1px 0 solid red"
                    Height="40px"
                    Gap="8px"
                >
                    {HeaderTitleExpenseDetail.map((data, index) => {
                        return (
                            <TableItem Justify="start" Gap="8px" Width="100%" key={index}>
                                <Text
                                    FontSize="0.875rem"
                                    Color="#2A2C2F"
                                    FontWeight="700"
                                    wSpace=""
                                >
                                    {data.name}
                                </Text>
                                {index === 0 && <Image src={ExpandeArrow} Cursor="pointer" />}
                                {index === 3 && (
                                    <Text FontSize="1rem" Color="#A1A5AA">
                                        {EXPENSE_DETAIL_COMPONENT.SIN}
                                    </Text>
                                )}
                            </TableItem>
                        );
                    })}
                </TableRowHead>

                <TableItemsContainer>
                    {expenses?.expenseDetails?.map((data: IExpensedata, index: number) => {
                        return (
                            <TableRowItems
                                Height="40px"
                                GridColumn="repeat(5, 1fr)"
                                BorderB="0.5px solid #e4e7e9"
                                Border=""
                                key={index}
                            >
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C" wSpace="">
                                        {index + 1}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {data.description}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" FontWeight="400">
                                        {data.amount.toLocaleString('es-MX')}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {`${formatNumber(data.amount)}`}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {`${formatNumber(data.amountWithVat)}`}
                                    </Text>
                                </TableItem>
                            </TableRowItems>
                        );
                    })}
                </TableItemsContainer>
            </Table>
            <ContainerFlex FlexDir="column" Align="start">
                <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                    {expenses?.remarks ? expenses?.remarks : '-'}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
