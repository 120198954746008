import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {
    APPLICATION_LIST,
    CREDITSANDPAWNS,
    DEFAULT_PAGE,
    NUL_SEARCH,
    TAB_HEADER_TEXT,
    TABLE_FILTERS,
} from '@components/CreditsAndPawns/constants';
import filterIcon from '@components/CreditsAndPawns/icons/filterIcon.svg';
import darkHelpIcon from '@components/CreditsAndPawns/icons/darkHelpIcon.svg';
import {SearchInput} from '@components/Customer/Styles';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import {ApplicationsPagination} from '@components/CreditsAndPawns/ApplicationsPagination';
import {ApplicationsTable} from '@components/CreditsAndPawns/ApplicationsTable';
import {CreditsStatistics} from '@components/CreditsAndPawns/CreditsStatistics';
import {ApplicationsFilter} from '@components/CreditsAndPawns/ApplicationsFilter';
import {AppDispatch, RootState} from '@/config/store';
import {getAllTotalAvailables} from '@components/CreditsAndPawns/Redux/Actions/GetAllTotalAvailablesActions';
import {
    getListLoansSearch,
    clearListLoansSearch,
} from '@components/CreditsAndPawns/Redux/Actions/GetListLoansActions';
import SearchLoanDropdown from '@components/CreditsAndPawns/SearchLoanDropdown';
import Header from '@components/CreditsAndPawns/Header';
import Footer from '@components/CreditsAndPawns/Footer';
import FilterModal from '@components/CreditsAndPawns/FilterModal/FilterModal';
import {
    getDataPersonalLoadFilters,
    toggleFiltersMenu,
} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
export const CreditsAndPawns = () => {
    const {register, reset, watch} = useForm({
        defaultValues: {
            searchText: '',
        },
    });
    const searchText = watch().searchText;
    const [statisticsFilter, setStatisticsFilter] = useState<string>(TAB_HEADER_TEXT.CREDITS);
    const [filterApplications, setFilterApplications] = useState<string>(TABLE_FILTERS.APPLICATION);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [needToOpen, setNeedToOpen] = useState<boolean>(false);
    const quantityPerPage = 10;
    const handleClickOutside = () => {
        setNeedToOpen(false);
    };

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const enterpriceId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId as number;
    });
    const {status, searchData, totalItems, totalPages, loadingSearch} = useSelector(
        (state: RootState) => {
            return state.getListLoans;
        }
    );
    const {openFilter, filterData, noFilterResults} = useSelector((state: RootState) => {
        return state.getFilterListLoans;
    });

    const menuDisplayStatus =
        needToOpen &&
        searchText !== NUL_SEARCH &&
        ((searchText.length > 2 && searchData.length > 0) ||
            (searchData.length === 0 && searchText.length > 2));

    const dispatch: AppDispatch = useDispatch();
    const statisticsData = useSelector((state: RootState) => {
        return state.getAllTotalAvailables;
    });

    const handleSearchAndClear = (pageSize: number, page: number, searchTerm: string) => {
        dispatch(clearListLoansSearch());
        dispatch(
            getListLoansSearch(token, enterpriceId, pageSize, page, status, searchTerm, false)
        );
        if (searchTerm === NUL_SEARCH) {
            reset();
        }
    };

    const handleSearchClick = () => {
        if (searchText.length > 2) {
            setNeedToOpen(false);
            handleSearchAndClear(quantityPerPage, 1, searchText);
        }
    };
    const onClearSearch = () => {
        if (searchText.length > 2) {
            setNeedToOpen(false);
            setPageNumber(1);
            handleSearchAndClear(quantityPerPage, 1, NUL_SEARCH);
        }
    };
    useEffect(() => {
        if (token) {
            dispatch(getAllTotalAvailables(token));
            dispatch(getDataPersonalLoadFilters(token, enterpriceId, status));
        }
    }, []);
    useEffect(() => {
        if (token && searchText.length > 2) {
            dispatch(getListLoansSearch(token, enterpriceId, 3, 1, status, searchText, true));
            setNeedToOpen(true);
        }
    }, [searchText]);
    useEffect(() => {
        if (token) {
            dispatch(
                getListLoansSearch(
                    token,
                    enterpriceId,
                    quantityPerPage,
                    1,
                    status,
                    NUL_SEARCH,
                    false
                )
            );
            dispatch(getDataPersonalLoadFilters(token, enterpriceId, status));
            setPageNumber(1);
        }
    }, [status]);
    useEffect(() => {
        if (token) {
            dispatch(
                getListLoansSearch(
                    token,
                    enterpriceId,
                    quantityPerPage,
                    pageNumber,
                    status,
                    searchText,
                    false
                )
            );
        }
    }, [pageNumber]);

    return (
        <>
            {openFilter && <FilterModal />}
            <ContainerFlex
                FlexDir="column"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
                Height="max-content"
            >
                <Header />
                <CreditsStatistics
                    statisticsFilter={statisticsFilter}
                    setStatisticsFilter={setStatisticsFilter}
                    statisticsData={statisticsData.data.data}
                    loading={statisticsData.loading}
                />
                <ContainerFlex Gap="1.5rem" Justify="space-between">
                    <ApplicationsFilter
                        filterApplications={filterApplications}
                        setFilterApplications={setFilterApplications}
                        reset={reset}
                    />
                    <ContainerFlex Gap="1rem" Justify="end" Width="max-content">
                        <SearchInput
                            Width="300px"
                            Padding="0.5rem 1rem"
                            Display="flex"
                            FontSize="1rem"
                            GridColumn="90% 10%"
                        >
                            <input
                                {...register('searchText')}
                                type="text"
                                placeholder={CREDITSANDPAWNS.INPUT_PLACEHOLDER}
                                autoComplete="off"
                                onKeyDown={(event: {key: string}) => {
                                    if (event.key === 'Enter') {
                                        handleSearchClick();
                                    }
                                }}
                            />
                            <Icons {...styles.iconSearch} onClick={() => onClearSearch()}>
                                {searchText.length > 2 ? 'close' : 'search'}
                            </Icons>
                            <SearchLoanDropdown
                                onClearSearch={handleClickOutside}
                                menuDisplayStatus={menuDisplayStatus}
                                searchOptions={searchData}
                                loading={loadingSearch}
                            />
                        </SearchInput>
                        <ButtonGenerals
                            SecondaryButton="#fff"
                            FontFamily="Nunito"
                            FontWeight="700"
                            onClick={() => {
                                dispatch(toggleFiltersMenu());
                            }}
                        >
                            <Image Width="24px" Height="24px" src={filterIcon} />
                            {'Filtros'}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex>
                    <ContainerFlex Justify="space-between">
                        <Text Color="#2A2C2F" FontWeight="700" FontSize="1.25rem">
                            {APPLICATION_LIST.TITLE}
                        </Text>
                        <ContainerFlex Justify="end" Width="max-content" Gap="0.5rem">
                            <Text FontWeight="400" Color="#1D1E20">
                                {APPLICATION_LIST.SWITCH_TITLE}
                            </Text>
                            <ContainerFlex Width="max-content" Gap="0.125rem">
                                <GreenSwitch />
                                <Image Width="16px" Height="16px" src={darkHelpIcon} />
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ApplicationsTable />
                <ApplicationsPagination
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    quantityPerPage={quantityPerPage}
                    totalPages={
                        filterData.length > 0 || (filterData.length === 0 && noFilterResults)
                            ? DEFAULT_PAGE
                            : totalPages
                    }
                    totalItems={
                        filterData.length > 0 || (filterData.length === 0 && noFilterResults)
                            ? filterData.length
                            : totalItems
                    }
                />
            </ContainerFlex>
            <Footer />
        </>
    );
};
