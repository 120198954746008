import React from 'react';
import {ContainerFlex, Figure, Text} from '@/components/Shopify/Ecommerce/styles';
import {HEADER} from '@/components/MyAccount/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import moneyBalance from '@/images/moneyBalance.svg';
import {DivisorLine} from '@/components/MyAccount/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import InfoContainer from '@/components/MyAccount/MyCredit/InfoContainer';

const Header = () => {
    const {staticInformation} = useSelector((state: RootState) => {
        return state.MyCreditReducer;
    });
    const formatCurrency = useFormatCurrency();
    return (
        <ContainerFlex FlexDir="column" Gap="24px">
            <ContainerFlex Justify="space-between" Height="40px">
                <Text FontSize="1.75rem" FontFamily="Nunito" FontWeight="700" Color="#000000">
                    {HEADER.TITLE}
                </Text>
                <ButtonGenerals
                    BackGC="#FFFFFF"
                    Color="#5A5AFF"
                    HBackG="#F0F0FF"
                    Border="1px solid #5A5AFF"
                    FontFamily="Nunito"
                    FontSize="1rem"
                    FontWeight="700"
                >
                    {HEADER.ACTION_BTN_TEXT}
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Height="105px" Gap="16px">
                <ContainerFlex
                    Padding="24px 48px"
                    Border=" 1px solid #0D166B"
                    Radius="16px"
                    Gap="16px"
                    Width="480px"
                    backG="#FFFFFF"
                >
                    <Figure Width="64px" Height="44px" Radius="60px" Background="#D0F6FF">
                        <img src={moneyBalance} alt="money icon" />
                    </Figure>
                    <ContainerFlex FlexDir="column" Gap="4px" Align="flex-start">
                        <Text
                            FontSize="1.75rem"
                            FontFamily="Nunito"
                            FontWeight="700"
                            Color="#1D1E20"
                        >
                            {formatCurrency(staticInformation.totalAmountCredits.toString())}
                        </Text>
                        <Text
                            FontSize="1rem"
                            FontFamily="Nunito"
                            Color="#2A2C2F"
                            LetterSpacing="0.3px"
                        >
                            {HEADER.IN_CREDIT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Padding="24px"
                    Border=" 1px solid #0D166B"
                    Radius="16px"
                    Gap="16px"
                    backG="#FFFFFF"
                    Justify="space-between"
                >
                    <InfoContainer
                        title={HEADER.PAY_UNTIL_NOW}
                        value={formatCurrency(staticInformation.totalPaid.toString())}
                    />
                    <DivisorLine />
                    <InfoContainer
                        title={HEADER.WHAT_I_OWE}
                        value={formatCurrency(staticInformation.totaOwed.toString())}
                    />
                    <DivisorLine />
                    <InfoContainer
                        title={HEADER.ACTIVE_LOANS}
                        value={staticInformation.activeLoans}
                    />
                    <DivisorLine />
                    <InfoContainer
                        title={HEADER.ACTIVE_PAWNS}
                        value={staticInformation.activePawns}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;
