import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {IAddUserEmployee, IModalState, IUserEmployee} from '@components/LocationsBranch/interfaces';
import {ADD_USER_MODAL} from '@components/LocationsBranch/constants';
import {
    getUserEmployee,
    setAddedUsers,
    resetSearchUserEmployee,
} from '@components/LocationsBranch/Redux/Action/AddUser';
import {SearchInput} from '@Customer/Styles';
import SearchIcon from '@images/search.svg';
import {SearchUser} from '@components/LocationsBranch/SearchUser';
import {AddUserAvtar} from '@components/LocationsBranch/AddUserAvatar';

export const AddUserGroupModal = ({modal, setModal}: IModalState) => {
    const dispatch = useDispatch();
    const {token, companyId} = useSelector((state: RootState) => ({
        token: String(state.getUsersValidation.userData?.token),
        companyId: Number(state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId),
    }));

    const [displaySearchList, setDisplayList] = useState(false);
    const {companyUsers, error, addedUsers, loading}: IAddUserEmployee = useSelector(
        (state: RootState) => state.AddUserEmployee
    );
    const [userList, setUserList] = useState<IUserEmployee[]>([]);
    const onClickUser = (data: IUserEmployee) => {
        if (!userList.some((user) => user.employeeId === data.employeeId)) {
            setUserList((prevUserList) => [...prevUserList, data]);
        }
        if (displaySearchList) {
            setDisplayList(false);
        }
    };
    const onRemoveUser = (employeeId: number) => {
        setUserList((prevUserList) =>
            prevUserList.filter((user) => user.employeeId !== employeeId)
        );
    };
    const handleSearch = (searchName: string) => {
        if (searchName.length > 2 && token) {
            setDisplayList(true);
            dispatch(getUserEmployee({companyId, searchName}, token));
        } else {
            setDisplayList(false);
        }
    };
    const onCancelModal = () => {
        dispatch(resetSearchUserEmployee());
        setModal({...modal, addUser: false});
    };
    const onSaveModal = () => {
        const newUsers =
            addedUsers.length > 0
                ? userList.filter(
                      (user) => !addedUsers.some((item) => user.employeeId === item.employeeId)
                  )
                : userList;
        dispatch(setAddedUsers([...addedUsers, ...newUsers]));
        setModal({...modal, addUser: false});
    };

    return (
        <Modal
            Top="0"
            modalState={modal.addUser}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem 0 0 1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerFlex
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
            >
                <ContainerFlex FlexDir="column" Align="start" Height={'1.5rem'} MarginBt="1.5rem">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {ADD_USER_MODAL.TITLE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Position="relative"
                    FlexDir="column"
                    Align="start"
                    Height={'3.125rem'}
                >
                    <SearchInput GridColumn="90% 10%" FontSize="1rem" Right="none">
                        <input
                            placeholder={ADD_USER_MODAL.PLACEHOLDER}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Image src={SearchIcon} />
                    </SearchInput>
                </ContainerFlex>
                <ContainerFlex
                    Position="relative"
                    FlexDir="column"
                    Align="start"
                    Height={'auto'}
                    MaxH="15rem"
                >
                    {displaySearchList && (
                        <SearchUser
                            setDisplay={setDisplayList}
                            onSelectUser={onClickUser}
                            data={companyUsers}
                            error={error}
                            loading={loading}
                        />
                    )}
                    {userList && userList.length > 0 && (
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            ColumnGap="0.5rem"
                            FlexDir="Column"
                            Gap="1rem"
                            Padding="0"
                            MarginTop="1.5rem"
                            Position="absolute"
                            OverFlow="auto"
                            Height={'21.5rem'}
                        >
                            {userList.map((user) => (
                                <AddUserAvtar
                                    key={user.employeeId}
                                    item={user}
                                    onRemoveUser={onRemoveUser}
                                />
                            ))}
                        </ContainerFlex>
                    )}
                </ContainerFlex>
                <ContainerFlex Gap="1.5rem" Align="end">
                    <ButtonGeneral
                        width="50%"
                        text={ADD_USER_MODAL.CANCEL}
                        clic={() => onCancelModal()}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="2.5rem"
                        cursor="pointer"
                        border="0.063rem solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        width="50%"
                        padding="1rem 0.5rem"
                        text={ADD_USER_MODAL.SAVE}
                        clic={() => onSaveModal()}
                        height="2.5rem"
                        cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
