import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {TREE_LOCATIONS} from '@Locations/ManageLocations/redux/types/ManageLocationsTypes';

export const treeBranchLocations = () => {
    return {
        type: TREE_LOCATIONS.INITIAL,
    };
};

export const treeBranchLocationsSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: TREE_LOCATIONS.SUCCESS,
        payload: result.data,
    };
};

export const treeBranchLocationsError = () => {
    return {
        type: TREE_LOCATIONS.ERROR,
    };
};

export function treeBranchLocation(token: string, CompanyId: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(treeBranchLocations());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.TreeBranchLocation.replace(
                    '{CompanyId}',
                    String(CompanyId)
                )}`,
                {headers}
            );
            dispatch(treeBranchLocationsSuccess(res));
        } catch (error) {
            dispatch(treeBranchLocationsError());
        }
    };
}
