export const USER_EJE = [
    {
        TYPE: 'Devolución compra',
        USER: 'Ivonne Calderon',
        ROLE: 'Cliente',
        DATE: '04/10/2023',
        ESTATE: 'Pendiente',
        MOV: '- $1,500.00',
    },
    {
        TYPE: 'Devolución compra',
        USER: 'Ivonne Calderon',
        ROLE: 'Cliente',
        DATE: '04/10/2023',
        ESTATE: 'Pendiente',
        MOV: '+ $1,500.00',
    },
];

export const header = ['Tipo', 'Usuario', 'Rol', 'Fecha', 'Estado', 'Movimiento', ''];
