import {FIELD_DINAMICS} from '@/components/Articles/Redux/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    articles_detail: {},
    FieldDinamics: [],
    catLabelSize: [],
    catTipoArticle: [],
    labelOption: 0,
    loading: false,
    error: false,
};

const DetailFields = (
    state = initialState,
    action: {type: string; value: AxiosResponse; fieldDinamics: []}
) => {
    switch (action.type) {
        case FIELD_DINAMICS.DETAIL:
            return {
                ...state,
                loading: true,
            };
        case FIELD_DINAMICS.DETAIL_SUCCESS:
            return {
                ...state,
                articles_detail: action.value,
                loading: false,
            };
        case FIELD_DINAMICS.CAT_LABELSIZE:
            return {
                ...state,
                catLabelSize: action.value,
                loading: false,
            };
        case FIELD_DINAMICS.CAT_TIPO_ARTICLE:
            return {
                ...state,
                catTipoArticle: action.value,
                loading: false,
            };
        case FIELD_DINAMICS.LABEL_DETAIL:
            return {
                ...state,
                labelOption: action.value,
                loading: false,
            };
        case FIELD_DINAMICS.INPUT_DINAMICS:
            return {
                ...state,
                FieldDinamics: action.fieldDinamics,
            };
        case FIELD_DINAMICS.ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
export default DetailFields;
