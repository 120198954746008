import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@components/CreateUsers/Styles';
import {SearchInput} from '@/components/Customer/Styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {BRANCHLOCATION} from '@components/CreateUsers/constants';
import {TreeGroups} from '@components/CreateUsers/Location/TreeGroups';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {TreeBranches} from '@components/CreateUsers/Location/TreeBranches';
import {saveTree} from '@components/CreateUsers/Redux/Actions/TreeGroupsAndBranches';
import {IBranchLoaction, ITreeBranches} from '@components/CreateUsers/Redux/interface';

export const BranchLocation = (props: IBranchLoaction) => {
    const {location, setLocation} = props;
    const dispatch: AppDispatch = useDispatch();

    const {showTreeBranches, treeGroup, treeBranch} = useSelector(
        (state: RootState) => state.TreeGroupBranches
    );

    const {editUsers} = useSelector((state: RootState) => state.editUsers);
    const GroupStep = treeGroup.map((item) => item.id);
    const branchesStep = treeBranch
        .map((item) =>
            item.branches.map((element) => ({
                branchId: element.branchId,
                nameBranch: element.branchName,
            }))
        )
        .flat();

    const [search, setSearch] = useState('');
    const [Group, setGroup] = useState<number[]>(GroupStep);
    const [showTree, setShowTree] = useState<ITreeBranches>({
        group: treeBranch.map((item) => item.groupId),
        branches: branchesStep,
        copyBranches: branchesStep,
        currentBranches: [],
    });

    const applyTree = () => {
        dispatch(saveTree({...showTree, treeGroup: Group, currentBranches: []}));
        setLocation(false);
    };

    useEffect(() => {
        if (editUsers.name) setShowTree({...showTreeBranches});
        if (!editUsers.name) setShowTree({...showTreeBranches, group: Group});
    }, [showTreeBranches.branches.length, Group.length]);

    return (
        <Modal
            modalState={location}
            changeModalState={() => {}}
            titleModalState={false}
            {...style.modalContect}
        >
            <ContainerFlex
                Gap="1.5rem"
                Padding="1.5rem"
                FlexDir="column"
                Height="34.375rem"
                MaxH="70%"
            >
                <ContainerFlex Gap="1rem" Align="start" FlexDir="column">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                        {BRANCHLOCATION.TITLEBRANCH}
                    </Text>
                    <Text Color="#54575C">{BRANCHLOCATION.DETAILS}</Text>
                </ContainerFlex>
                <ContainerFlex Padding="0.5rem 1rem" FlexDir="column">
                    <SearchInput {...style.search}>
                        <input
                            value={search}
                            type="text"
                            autoComplete="off"
                            placeholder={BRANCHLOCATION.SEARCH}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                setShowTree({
                                    ...showTreeBranches,
                                    branches: [],
                                    currentBranches: [],
                                });
                            }}
                        />
                        <Icons
                            {...style.iconSearch}
                            onClick={() => {
                                if (search.length > 0) setSearch('');
                            }}
                        >
                            {search.length > 0
                                ? BRANCHLOCATION.ICONCLOSE
                                : BRANCHLOCATION.ICONSEARCH}
                        </Icons>
                    </SearchInput>
                    <ContainerFlex Gap="1rem" Align="start">
                        <ContainerFlex {...style.tabsTree} Width="35%">
                            <Text {...style.treeTitle}>{BRANCHLOCATION.GROUPSFILTER}</Text>
                            <TreeGroups Group={Group} setGroup={setGroup} GroupStep={GroupStep} />
                        </ContainerFlex>
                        <ContainerFlex {...style.tabsTree} Width="65%">
                            <Text {...style.treeTitle}>{BRANCHLOCATION.BRANCHES}</Text>
                            <TreeBranches
                                Group={Group}
                                branchesStep={branchesStep}
                                search={search}
                                showTree={showTree}
                                setShowTree={setShowTree}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Gap="0.5rem">
                    <Text {...style.Btn} onClick={() => setLocation(false)}>
                        {BRANCHLOCATION.CANCEL}
                    </Text>
                    <ButtonGeneral
                        text={BRANCHLOCATION.APPLY}
                        disabled={showTree.branches.length === 0}
                        clic={() => applyTree()}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
