import {GET_HANDS_SCHEMA} from '@ListClient/redux/types/Types';
import {IFingersState} from '@ListClient/redux/interfaces';
import {IHandsAction} from '@components/Shopify/ListClient/redux/interfaces';

const initialState: IFingersState = {
    fingerData: [],
    loading: false,
    error: false,
};

const getHandsFingers = (state = initialState, action: IHandsAction): IFingersState => {
    switch (action.type) {
        case GET_HANDS_SCHEMA.GET_HANDS_START:
            return {...state, loading: true, error: false};
        case GET_HANDS_SCHEMA.GET_HANDS_SUCCESS:
            return {...state, loading: false, error: false, fingerData: action.fingerData};
        case GET_HANDS_SCHEMA.GET_HANDS_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default getHandsFingers;
