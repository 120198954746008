import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import ButtonGeneral from '@General/Atoms/Button';
import rack from '@images/rack.svg';
import {insertRack, restartLocations, updateRack} from '@ActionsLocation/LocationAction';
import {ContainerArea, ImgLocation, SelectFileVault, SubContainerArea} from '@CreateLocation/style';
import deleteIcon from '@images/delete_forever-black.svg';
import TreeLocations from '@AdminLocations/CreateLocation/Containers/TreeLocations';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {containerFileRack} from '@Locations/AdminLocations/redux/constants';
import {sendRack, location} from '@Locations/AdminLocations/redux/Interfaces';

const Rack = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/CreateBranch', label: 'Sucursal', reset: () => dispatch(restartLocations(null))},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: 'Crear ubicación'},
    ];
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);
    const defaultData = {
        rackName: '',
        rowsCount: '',
        branchId: '',
        status: '',
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: defaultData,
    });

    const handleSwitch = () => {
        setIsChecked((prev) => !prev);
    };

    const onSubmits = async (data: sendRack) => {
        if (locations.objectbranch.type === 'create')
            dispatch(
                insertRack(
                    {
                        branchId: locations.saveBranch.idBranch,
                        rackName: data.rackName,
                        rowsCount: Number(data.rowsCount),
                        status: isChecked,
                    },
                    token
                )
            );

        if (locations.objectbranch.type === 'edit')
            dispatch(
                updateRack(
                    {
                        branchId: locations.saveBranch.idBranch,
                        rackId: locations.objectbranch.id,
                        rackName: data.rackName,
                        rowsCount: Number(data.rowsCount),
                        status: isChecked,
                    },
                    token
                )
            );
    };

    useEffect(() => {
        if (locations.objectbranch.type === 'edit') {
            reset({
                branchId: String(locations.objectbranch.branchId),
                rackName: locations.objectbranch.rackName,
                rowsCount: String(locations.objectbranch.rows),
                status: String(locations.objectbranch.status),
            });
            setIsChecked(locations.objectbranch.status);
        }
    }, [dispatch]);

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex PaddingB="2rem" Justify="start">
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start">
                <TreeLocations />
                <ContainerFlex Align="start" FlexDir="column" Padding="0 6.313rem 0 1.563rem">
                    <ContainerArea>
                        <SubContainerArea>
                            <Text FontSize="1rem" FontWeight="400">
                                Crear Ubicación
                            </Text>
                        </SubContainerArea>
                        <ContainerFlex
                            Align="start"
                            FlexDir="column"
                            Padding="0 1rem"
                            Height="auto"
                        >
                            <ContainerFlex
                                FlexDir="column"
                                Width="98px"
                                Padding="3.453rem 0 1rem 0"
                            >
                                <ContainerFlex
                                    Border="2px solid #7AC143"
                                    Height="98px"
                                    Radius="5px"
                                >
                                    <img width="50px" height="50px" src={rack} alt="rack" />
                                </ContainerFlex>
                                <Text FontSize="0.875rem" Padding="0.688rem 0 0 0">
                                    Rack
                                </Text>
                            </ContainerFlex>
                            <Text
                                FontSize="1.125rem"
                                FontWeight="400"
                                Padding="1.688rem 0 1.563rem 0"
                            >
                                Detalle de la ubicación en rack
                            </Text>
                            <form onSubmit={handleSubmit(onSubmits)}>
                                <ContainerFlex Height="auto" Justify="space-around">
                                    <ContainerFlex
                                        Align="start"
                                        FlexDir="column"
                                        Padding="14px 1.813rem 14px 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Nombre de rack
                                        </Text>
                                        <Input
                                            {...register('rackName', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                            })}
                                            maxLength={160}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.rackName?.message}
                                        </Text>
                                    </ContainerFlex>
                                    <SelectFileVault>
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Número de filas
                                        </Text>
                                        <select
                                            {...register('rowsCount', {
                                                required: {
                                                    value: true,
                                                    message: 'Selecciona un contenedor',
                                                },
                                            })}
                                        >
                                            <option selected disabled hidden value="">
                                                ----
                                            </option>
                                            {containerFileRack.map((item, index: number) => (
                                                <option value={item.id} key={index}>
                                                    {`${item.id} filas`}
                                                </option>
                                            ))}
                                        </select>
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.rowsCount?.message}
                                        </Text>
                                    </SelectFileVault>
                                </ContainerFlex>
                                <ContainerFlex
                                    Align="start"
                                    FlexDir="column"
                                    Padding="5px 0"
                                    Height="auto"
                                >
                                    <Text FontSize="0.75rem" FontWeight="400">
                                        ¿Es una vitrina de alto flujo?
                                    </Text>
                                    <Text Padding="1rem 0" FontSize="0.875rem" FontWeight="400">
                                        No
                                        <GreenSwitch
                                            name="status"
                                            defaultChecked
                                            checked={isChecked}
                                            onChange={handleSwitch}
                                            size="small"
                                        />
                                        Sí
                                    </Text>
                                </ContainerFlex>
                            </form>
                            <ContainerFlex
                                Height="auto"
                                Justify="space-between"
                                Width="75%"
                                Padding="50px 0"
                            >
                                <ButtonGeneral
                                    transform="capitalize"
                                    HideBorder="none"
                                    border="none"
                                    width="167px"
                                    text="Cancelar"
                                    hColor="#35cf44"
                                    clic={() => navigate('/NavLocations')}
                                />
                                <ButtonGeneral
                                    transform="capitalize"
                                    width="167px"
                                    hColor="#35cf44"
                                    text="Crear Ubicación"
                                    clic={handleSubmit(onSubmits)}
                                />
                                <ContainerFlex
                                    Justify="start"
                                    onClick={() => {
                                        reset(defaultData);
                                        setIsChecked(false);
                                    }}
                                    Width="167px"
                                    Cursor="pointer"
                                >
                                    <ImgLocation
                                        Width="20px"
                                        Height="20px"
                                        Margin="0 5px"
                                        HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                        src={deleteIcon}
                                    />
                                    <Text Cursor="pointer">Limpiar</Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerArea>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default Rack;
