export const LOANSPRODUCTS = {
    LOANS: 'Préstamos personales',
    TITLE: 'Productos de préstamo',
    DESCRIPTION:
        'Crea y gestiona los productos de préstamos personales que ofrecerás a tus clientes.',
    NEW: 'Nuevo Préstamo',
};
export const LOANSACTIVE = {
    PRODUCT: 'Productos de préstamo',
    ACTIVE: 'Activos',
    INACTIVE: 'Inactivos',
    VALUE: '0',
};
export const LOANSLIST = {
    PRODCUTS: 'Productos',
    NAME: 'Nombre',
    MIN: 'Cantidad mínima',
    MAX: 'Cantidad máxima',
    MINTIME: 'Tiempo de pago min.',
    MAXTIME: 'Tiempo de pago max.',
    ACTIVE: 'Activo',
    CREATE: 'Crea tu primer préstamo',
};
export const SUGGESTEDPRODUCTS = {
    TITLE: 'Sugerencias Ataskate',
    SUB: 'Selecciona los productos sugeridos por Ataskate.',
    SELECT: 'Seleccionar',
};
export const LoansTable = [
    {
        termId: 1,
        termName: 'Préstamo primario',
        minimumQuantity: 500,
        maximumAmount: 10000,
        minimumTime: '1 año',
        maximumTime: '2 años',
    },
    {
        termId: 2,
        termName: 'Préstamo secundario',
        minimumQuantity: 400,
        maximumAmount: 12000,
        minimumTime: '6 meses',
        maximumTime: '2 años',
    },
    {
        termId: 3,
        termName: 'PR PRimario',
        minimumQuantity: 1000,
        maximumAmount: 15000,
        minimumTime: '1 año',
        maximumTime: '2 años',
    },
    {
        termId: 4,
        termName: 'Testing',
        minimumQuantity: 1500,
        maximumAmount: 20000,
        minimumTime: '1 año',
        maximumTime: '3 años',
    },
];

export const TABLE_FILTERS = [
    {label: 'Todos', value: 0},
    {label: 'Activos', value: 1},
    {label: 'Inactivos', value: 2},
    {label: 'Archivados', value: 3},
];
