import React, {useState} from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {ModalFunding} from '@/components/CashFundingModal/ModalFunding';
import {ModalCuestions} from '@/components/CashFundingModal/ModalCuestions';
import {ModalResoluction} from '@/components/CashFundingModal/ModalResoluction';

type ModalType = 'funding' | 'cuestions' | 'resoluction' | null;

export const OpenFundingsModals = () => {
    const [openModal, setOpenModal] = useState<ModalType>('funding');

    const openModalHandler = (modal: ModalType) => setOpenModal(modal);
    const closeModalHandler = () => setOpenModal(null);

    return (
        <ContainerFlex>
            {openModal === 'funding' && (
                <ModalFunding
                    isOpen={openModal === 'funding'}
                    closeModal={closeModalHandler}
                    openCuestionsModal={() => openModalHandler('cuestions')}
                />
            )}
            {openModal === 'cuestions' && (
                <ModalCuestions
                    isOpen={openModal === 'cuestions'}
                    closeModal={closeModalHandler}
                    openResoluctionModal={() => openModalHandler('resoluction')}
                    openFundingModal={() => openModalHandler('funding')}
                />
            )}
            {openModal === 'resoluction' && (
                <ModalResoluction
                    isOpen={openModal === 'resoluction'}
                    closeModal={closeModalHandler}
                />
            )}
        </ContainerFlex>
    );
};
