import {Branch} from './interface';

export const BRANCH_ASSIGNMENT = {
    TITLE: 'Asignación de sucursales',
    TITLE_TWO: 'Selecciona la sucursal del usuario',
    INDICATION: 'Asigna las ubicaciones en tu empresa a las que este usuario tendrá acceso.',
    ASSIGNMENT: '[Nombre Empresa]',
    ASSIGNMENT_ONE: 'México',
    ASSIGNMENT_TWO: 'Centro',
    ASSIGNMENT_THREE: 'CDMX',

    TYPE_ASSIGNMENT: 'Grupo, sucursal o bodega',
    ELEMENT: 'elementos',

    BTN: 'Descartar',
    BTN_KEEP: 'Guardar',

    SEARCH: 'Busca un grupo o sucursal',
};

export const DATA_TYPE_CASH = [
    {label: 'Ingreso', value: 'Ingreso'},
    {label: 'Egreso', value: 'Egreso'},
];

export const branchHierarchy: Branch[] = [
    {
        companyName: 'Ataskate',
        name: 'Mexico',
        type: 'País',
        children: [
            {
                name: 'Bajío',
                type: 'Ciudad',
                children: [
                    {
                        name: 'CDMX',
                        type: 'Zona',
                        children: [
                            {name: 'Coapa', type: 'Sucursal'},
                            {name: 'Coyoacán', type: 'Sucursal'},
                            {name: 'Coyoacán 2', type: 'Sucursal'},
                            {name: 'Coyuya', type: 'Sucursal'},
                            {name: ' San Juanico', type: 'Sucursal'},
                        ],
                    },
                    {
                        name: 'Zona 2',
                        type: 'Zona',
                        children: [
                            {name: 'Sucursal 4', type: 'Sucursal'},
                            {name: 'Sucursal 5', type: 'Sucursal'},
                            {name: 'Sucursal 6', type: 'Sucursal'},
                        ],
                    },
                ],
            },
            {
                name: 'Centro',
                type: 'Ciudad',
                children: [
                    {
                        name: 'CDMX',
                        type: 'Zona',
                        children: [
                            {name: 'Coapa', type: 'Sucursal'},
                            {name: 'Coyoacán', type: 'Sucursal'},
                            {name: 'Coyoacán 2', type: 'Sucursal'},
                            {name: 'Coyuya', type: 'Sucursal'},
                            {name: ' San Juanico', type: 'Sucursal'},
                        ],
                    },
                    {
                        name: 'Zona 2',
                        type: 'Zona',
                        children: [
                            {name: 'Sucursal 4', type: 'Sucursal'},
                            {name: 'Sucursal 5', type: 'Sucursal'},
                            {name: 'Sucursal 6', type: 'Sucursal'},
                        ],
                    },
                ],
            },
            {
                name: 'Guadalajara',
                type: 'Ciudad',
                children: [
                    {
                        name: 'Zona 3',
                        type: 'Zona',
                        children: [
                            {name: 'Sucursal 7', type: 'Sucursal'},
                            {name: 'Sucursal 8', type: 'Sucursal'},
                            {name: 'Sucursal 9', type: 'Sucursal'},
                        ],
                    },
                ],
            },
        ],
    },
    {
        companyName: 'Ataskate',
        name: 'Colombia',
        type: 'País',
        children: [
            {
                name: 'Bogota',
                type: 'Ciudad',
                children: [
                    {
                        name: 'Zona 4',
                        type: 'Zona',
                        children: [
                            {name: 'Sucursal 10', type: 'Sucursal'},
                            {name: 'Sucursal 11', type: 'Sucursal'},
                            {name: 'Sucursal 12', type: 'Sucursal'},
                        ],
                    },
                ],
            },
        ],
    },
    {
        companyName: 'Ataskate',
        name: 'Canada',
        type: 'País',
        children: [
            {
                name: 'Toronto',
                type: 'Ciudad',
                children: [
                    {
                        name: 'Zona 5',
                        type: 'Zona',
                        children: [
                            {name: 'Sucursal 13', type: 'Sucursal'},
                            {name: 'Sucursal 14', type: 'Sucursal'},
                            {name: 'Sucursal 15', type: 'Sucursal'},
                        ],
                    },
                ],
            },
        ],
    },
];
