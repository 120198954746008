export const handleStatus = (status: number, type: 'color' | 'description') => {
    if (type === 'color') {
        const REQUEST_STATUS: {[key: number]: string} = {
            1: '#E3F2D7',
            2: '#FFCCCC',
        };
        return REQUEST_STATUS[status];
    }
    if (type === 'description') {
        const REQUEST_STATUS: {[key: number]: string} = {
            1: 'Activo',
            2: 'Inactivo',
        };
        return REQUEST_STATUS[status];
    }
};

export const titleEndeavors = [
    {title: 'No. Folio'},
    {title: 'Estado del contrato'},
    {title: 'País/Casa de empeño/Sucursal'},
    {title: 'Valor de refrendo'},
    {title: 'Valor de desempeño'},
    {title: 'Fecha de vencimiento'},
    {title: 'Número de refrendos'},
    {title: ''},
];
