import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {DateTime} from 'luxon';
import {RootState, AppDispatch} from '@config/store';

import {getFileName} from '@ActionsRecolectionGold/RecolectionGoldActions';
import {HeadTableAudited} from '@DocumentFoundry/HeadTableAudited';
import {HeadTableDocumentFoundry} from '@DocumentFoundry/HeadTableDocumentFoundry';
import {HeadTableRevisionTable} from '@DocumentFoundry/HeadTableRevisionTable';
import {
    getDocumentBody,
    getDocumentHeader,
    putDocumentFinish,
} from '@ActionsDocumentFoundry/DocumentFoundryActions';
import {RowDocumentAudited} from '@DocumentFoundry/RowDocumentAudited';
import {RowDocumentFoundry} from '@DocumentFoundry/RowDocumentFoundry';
import {RowRevisionFoundry} from '@DocumentFoundry/RowRevisionFoundry';
import {TotalTableFoundry} from '@DocumentFoundry/TotalTableFoundry';
import {Table} from '@DeadLines/styles';

export function DocumentFoundry() {
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {
            observaciones: '',
        },
    });
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [totalquantity, settotalquantity] = useState(0);
    const [totalgramos, settotalgramos] = useState(0);
    const [totalcosto, settotalcosto] = useState(0);
    const [totalquantityauditada, settotalquantityauditada] = useState(0);
    const [totalgramosauditada, settotalgramosauditada] = useState(0);
    const [totalcostoauditada, settotalcostoauditada] = useState(0);
    function onSubmit(data: {observaciones: {}}) {
        dispatch(getFileName(assignmentvalidation.idAsignacion, token));
        const json = {
            idAsignacion: assignmentvalidation.idAsignacion,
            fecha: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            observaciones: data.observaciones,
        };
        window.open(fileURL, 'Download');
        dispatch(putDocumentFinish(json, token));
    }
    const params = useParams<any>();
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (assignmentvalidation) {
            const getDocumentHeaderTable = () => {
                dispatch(getDocumentHeader(String(assignmentvalidation.idAsignacion), token));
            };
            getDocumentHeaderTable();
            const getDocumentBodyTable = () => {
                dispatch(getDocumentBody(String(assignmentvalidation.idAsignacion), token));
            };
            getDocumentBodyTable();
        }
    }, []);
    const fileURL = useSelector((state: RootState) => {
        return state.assignmentrecolectiongold.filename;
    });
    const assignmentvalidation = useSelector((state: RootState) => {
        return state.assignmentvalidation.assignmentvalidation;
    });
    const documentbody = useSelector((state: RootState) => {
        return state.documentfoundry.documentbody;
    });
    const documentheader = useSelector((state: RootState) => {
        return state.documentfoundry.documentheader;
    });
    useEffect(() => {
        if (documentheader && documentbody) {
            const reducer = (acumulator: number, currentValue: {cantidad: number}) =>
                acumulator + currentValue.cantidad;
            settotalquantity(documentbody.sistema.reduce(reducer, 0));
            const gramos = (gramo: number, currentValue: {gramos: number}) =>
                gramo + currentValue.gramos;
            settotalgramos(documentbody.sistema.reduce(gramos, 0));
            const costos = (costo: number, currentValue: {costo: number}) =>
                costo + currentValue.costo;
            settotalcosto(documentbody.sistema.reduce(costos, 0));
            const reducerauditada = (acumulator: number, currentValue: {cantidad: number}) =>
                acumulator + currentValue.cantidad;
            settotalquantityauditada(documentbody.revisionTecnica.reduce(reducerauditada, 0));
            const gramosauditada = (gramo: number, currentValue: {gramos: number}) =>
                gramo + currentValue.gramos;
            settotalgramosauditada(documentbody.revisionTecnica.reduce(gramosauditada, 0));
            const costosauditada = (costo: number, currentValue: {costo: number}) =>
                costo + currentValue.costo;
            settotalcostoauditada(documentbody.revisionTecnica.reduce(costosauditada, 0));
            reset({
                observaciones: documentheader.observaciones,
            });
        } else {
            return;
        }
    }, [documentheader, documentbody, reset]);

    return (
        <>
            <div className="pt-2 pb-3 flex align-items-center w-90 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Sucursal</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link to={`/Fundiciones/Auditoria`}>
                    <span className="child-breadcrumb">Verificación de Datos</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link
                    to={`/Fundiciones/Recoleccion/${assignmentvalidation.idSucursal}/${assignmentvalidation.idAsignacion}`}
                >
                    <span className="child-breadcrumb">Recolección de Oro</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">SKU {params.sku}</span>
            </div>
            <form className="flex container-form" onSubmit={handleSubmit(onSubmit)}>
                {documentheader !== null ? (
                    <>
                        <div className=" w-90 mx-auto flex justify-content-between">
                            <span className="title">{documentheader.texto1}</span>

                            <button className="w-30 pl-5 pr-5 btn-add-incidents h-40" type="submit">
                                FINALIZAR
                            </button>
                        </div>
                        <div className="flex pt-5 w-90 mx-auto flex-wrap pb-5">
                            <span className="w-30 pb-2">
                                No de Seguribolsa: {documentheader.seguribolsa}
                            </span>
                            <span className="w-30 ">Región: {documentheader.region}</span>
                            <span className="w-40 pb-2">
                                Responsable de sucursal: {documentheader.responsable}
                            </span>
                            <span className="w-30">No de papeleta: {documentheader.papeleta}</span>
                            <span className="w-30 pb-2">Sucursal: {documentheader.sucursal}</span>
                            <span className="w-40">
                                Cargo de Responsable: {documentheader.puestoResponsable}
                            </span>
                        </div>
                        <div className="w-90 mx-auto flex flex-column">
                            <span className=" f-22">Acta de Hechos</span>
                            <span className="title mt-4">{documentheader.texto2}</span>
                        </div>
                    </>
                ) : null}
                {documentbody !== null ? (
                    <div className="flex  w-90 mx-auto mt-5 flex-wrap ">
                        <div className="pl-5 pr-5 pb-5 w-50">
                            <div className="card card-body">
                                <div className="text-center">
                                    <span className="f-18 "> Sistema</span>
                                    <Table className="mt-5 w-50 mx-auto">
                                        <HeadTableDocumentFoundry />
                                        {documentbody &&
                                            documentbody.sistema.map(
                                                (
                                                    documentsistema: JSX.IntrinsicAttributes & {
                                                        kilataje: number;
                                                        cantidad: number;
                                                        gramos: number;
                                                        costo: number;
                                                    }
                                                ) => <RowDocumentFoundry {...documentsistema} />
                                            )}
                                        <TotalTableFoundry
                                            cantidad={totalquantity}
                                            gramos={totalgramos}
                                            costo={totalcosto}
                                        />
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="pl-5 pr-5 pb-5 w-50">
                            <div className="card card-body">
                                <div className="text-center">
                                    <span className="f-18 "> Revisión Técnica</span>
                                    <Table className="mt-5 w-50 mx-auto">
                                        <HeadTableDocumentFoundry />
                                        {documentbody &&
                                            documentbody.revisionTecnica.map(
                                                (
                                                    documentrevisiontecnica: JSX.IntrinsicAttributes & {
                                                        kilataje: number;
                                                        cantidad: number;
                                                        gramos: number;
                                                        costo: number;
                                                    }
                                                ) => (
                                                    <RowDocumentFoundry
                                                        {...documentrevisiontecnica}
                                                    />
                                                )
                                            )}
                                        <TotalTableFoundry
                                            cantidad={totalquantityauditada}
                                            gramos={totalgramosauditada}
                                            costo={totalcostoauditada}
                                        />
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="pl-max pr-5 pt-5 w-60">
                            <div className="card card-body">
                                <div className="text-center">
                                    <span className="f-18 ">Auditado</span>
                                    <Table className="mt-5 w-50 mx-auto">
                                        <HeadTableAudited />
                                        {documentbody &&
                                            documentbody.auditado.map(
                                                (
                                                    documentauditado: JSX.IntrinsicAttributes & {
                                                        incidencia: number;
                                                        cantidad: number;
                                                        gramos: number;
                                                        costo: number;
                                                    }
                                                ) => <RowDocumentAudited {...documentauditado} />
                                            )}
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="pl-5 pr-5 pt-5 w-40">
                            <div className="card card-body">
                                <div className="text-center">
                                    <span className="f-18 ">Revisión Técnica Auditado</span>
                                    <Table className="mt-5 w-50 mx-auto">
                                        <HeadTableRevisionTable />
                                        {documentbody &&
                                            documentbody.revisionTecnicaAuditado.map(
                                                (
                                                    documentrevisionauditada: JSX.IntrinsicAttributes & {
                                                        kilataje: number;
                                                        gramos: number;
                                                    }
                                                ) => (
                                                    <RowRevisionFoundry
                                                        // key={assignments.noAsignacio}
                                                        {...documentrevisionauditada}
                                                    />
                                                )
                                            )}
                                    </Table>
                                </div>
                            </div>
                            <div className="mt-5 card card-body">
                                <div className="text-center flex flex-column">
                                    <span className="f-18 ">Peso en piedra gramos</span>
                                    <span className="icon-color">
                                        {documentbody.pesoAccesorios}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {documentheader !== null ? (
                    <div className=" w-90 mx-auto mt-4">
                        <span className="title">{documentheader.texto3}</span>
                    </div>
                ) : null}
                <div className="flexrow w-90 mx-auto pt-3 pb-5">
                    <label className="required">Observaciones</label>
                    <input className="input-react-hook-form" {...register('observaciones', {})} />
                </div>
            </form>
        </>
    );
}
