import {
    POST_CREATE_PROBLEMS,
    POST_CREATE_PROBLEMS_ERROR,
    POST_CREATE_PROBLEMS_RESET,
    POST_CREATE_PROBLEMS_SUCCESS,
} from '@components/LoginClient/Redux/Types/Types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    createProblem: false,
};

const CreateProblems = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_CREATE_PROBLEMS:
            return {
                ...state,
                loading: true,
            };
        case POST_CREATE_PROBLEMS_SUCCESS:
            return {
                ...state,
                createProblem: action.payload.data,
                loading: false,
            };
        case POST_CREATE_PROBLEMS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_CREATE_PROBLEMS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default CreateProblems;
