import React, {useState} from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import deleteIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/deleteIcon.svg';
import {
    ICardProps,
    IModalProps,
} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {ChangeDeleteCardModal} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/ChangeDeleteCardModal';

export const Card: React.FC<ICardProps> = ({
    cardNumber,
    isDefaultCard,
    paymentMethodId,
    typeImageCard,
}) => {
    const [changeDeleteModal, setChangeDeleteModal] = useState<IModalProps>({
        showModal: false,
        isDeleting: false,
    });

    const {showModal, isDeleting} = changeDeleteModal;

    const onHandleOnChange = () => {
        setChangeDeleteModal({isDeleting: false, showModal: true});
    };
    const handleDeleting = () => {
        setChangeDeleteModal({isDeleting: true, showModal: true});
    };
    return (
        <>
            <ContainerFlex
                Padding="1rem"
                Border={isDefaultCard ? '1px solid #5A5AFF' : '1px solid #E8E9EA'}
                Radius="0.5rem"
            >
                <ContainerFlex Gap="0.5rem" Justify="start">
                    <Input
                        type="radio"
                        Width="24px"
                        Height="24px"
                        checked={isDefaultCard}
                        onChange={onHandleOnChange}
                    />
                    <ContainerFlex Gap="1rem" Justify="start">
                        <Image src={typeImageCard} alt="card-icon" Width="32px" Height="32px" />
                        <Text Color="#2A2C2F">{cardNumber}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <Image
                    src={deleteIcon}
                    Width="24px"
                    Height="24px"
                    alt="edit-icon"
                    Cursor="pointer"
                    onClick={handleDeleting}
                />
            </ContainerFlex>
            {showModal && (
                <ChangeDeleteCardModal
                    paymentMethodId={paymentMethodId as number}
                    isDeleting={isDeleting}
                    showModal={showModal}
                    setShowModal={setChangeDeleteModal}
                />
            )}
        </>
    );
};
