import {RootState} from '@/config/store';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ROLES} from '@Roles/constants';
import {ITypeRoles} from '@Roles/AddRoles/interfaces';
import * as styles from '@Roles/CreateRoles/stylesCreate';
import {ContainerFlex, Input, Text, Image} from '@Shopify/Ecommerce/styles';
import pos from '@images/posRol.svg';
import admin from '@images/adminRol.svg';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {roleList, rolesCheck} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';
import {treePermissions} from '@Roles/CreateRoles/Redux/Actions/TreePermissions';

export const TypeRole = ({register, setValue, errors, roles, setRoles}: ITypeRoles) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const persistenceRol = useSelector((state: RootState) => state.PersistenceRole);

    const treePermission = () => {
        if (roles.admin && !roles.pos) {
            dispatch(treePermissions(token, {typeRol: ROLES.TYPE_ROLE.ADMIN_ID}));
            setValue('roleType', ROLES.TYPE_ROLE.ADMIN_ID);
            dispatch(roleList({...persistenceRol.typeRol, roleType: ROLES.TYPE_ROLE.ADMIN_ID}));
        }
        if (roles.pos && !roles.admin) {
            dispatch(treePermissions(token, {typeRol: ROLES.TYPE_ROLE.POS_ID}));
            setValue('roleType', ROLES.TYPE_ROLE.POS_ID);
            dispatch(roleList({...persistenceRol.typeRol, roleType: ROLES.TYPE_ROLE.POS_ID}));
        }
        if (roles.pos && roles.admin) {
            dispatch(treePermissions(token, {typeRol: ROLES.TYPE_ROLE.ADMINPOS}));
            setValue('roleType', ROLES.TYPE_ROLE.ADMINPOS);
            dispatch(roleList({...persistenceRol.typeRol, roleType: ROLES.TYPE_ROLE.ADMINPOS}));
        }
        return;
    };
    useEffect(() => {
        setRoles(persistenceRol.roles);
    }, [persistenceRol.typeRol.roleType, persistenceRol.modules.length]);

    useEffect(() => {
        treePermission();
        dispatch(rolesCheck(roles));
    }, [roles.pos, roles.admin]);
    return (
        <ContainerFlex {...styles.formContent}>
            <Text {...styles.title.subtitles}>{ROLES.TYPE_ROLE.TYPE}</Text>
            {persistenceRol.typeAction === ROLES.UPDATE ? (
                <>
                    {(persistenceRol.typeRol.roleType === ROLES.TYPE_ROLE.ADMIN_ID ||
                        persistenceRol.typeRol.roleType === ROLES.TYPE_ROLE.ADMINPOS) && (
                        <ContainerFlex Width="auto">
                            <Image alt="module-img" src={admin} Margin="0 0.2rem 0 0" />
                            <Text
                                Padding="0 0.5rem"
                                Color="#54575C"
                                Cursor="pointer"
                                FontWeight="500"
                            >
                                {ROLES.TYPE_ROLE.ADMINISTRATIVE}
                            </Text>
                        </ContainerFlex>
                    )}

                    {(persistenceRol.typeRol.roleType === ROLES.TYPE_ROLE.POS_ID ||
                        persistenceRol.typeRol.roleType === ROLES.TYPE_ROLE.ADMINPOS) && (
                        <ContainerFlex Width="auto">
                            <Image alt="module-img" src={pos} Margin="0 0.2rem 0 0" />
                            <Text
                                Padding="0 0.5rem"
                                Color="#54575C"
                                Cursor="pointer"
                                FontWeight="500"
                            >
                                {ROLES.TYPE_ROLE.POS}
                            </Text>
                        </ContainerFlex>
                    )}
                </>
            ) : (
                <>
                    <ContainerFlex
                        Width="auto"
                        onClick={() => setRoles({...roles, admin: !roles.admin})}
                    >
                        <Checkbox checked={roles.admin} {...register(`checkAdmin`)} />
                        <Text Padding="0 0.5rem" Color="#54575C" Cursor="pointer">
                            {ROLES.TYPE_ROLE.ADMINISTRATIVE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Width="auto"
                        onClick={() => setRoles({...roles, pos: !roles.pos})}
                    >
                        <Checkbox checked={roles.pos} {...register(`checkPOS`)} />
                        <Text Padding="0 0.5rem" Color="#54575C" Cursor="pointer">
                            {ROLES.TYPE_ROLE.POS}
                        </Text>
                    </ContainerFlex>
                </>
            )}

            <ContainerFlex Align="start" FlexDir="column" Gap="0.25rem" Width="23.313rem">
                <Text FontWeight="500" Color="#6D6E71">
                    {ROLES.TYPE_ROLE.NAME_ROL}
                </Text>
                <Input
                    {...register(`roleName`)}
                    onChange={(e) =>
                        dispatch(roleList({...persistenceRol.typeRol, roleName: e.target.value}))
                    }
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.roleName && errors?.roleName.message}
                </Text>
            </ContainerFlex>
            <Text Color="#FF6357" FontSize="0.875rem" Height="0.875rem" Padding="0.438rem 0">
                {errors.checkAdmin?.message || errors.checkPOS?.message}
            </Text>
        </ContainerFlex>
    );
};
