import React from 'react';
import {Svg} from '@Shopify/Ecommerce/styles';

export const Camera = () => {
    return (
        <Svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            hoverF="#35CF44"
        >
            <mask id="7ja4zug6ka" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <path
                d="M19 8V6h-2V4h2V2h2v2h2v2h-2v2h-2zM3 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 20V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 3 6h3.15L8 4h6v2H8.875L7.05 8H3v12h16v-9h2v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H3zm8-3.5c1.25 0 2.313-.437 3.188-1.312S15.5 15.25 15.5 14c0-1.25-.437-2.313-1.312-3.188S12.25 9.5 11 9.5c-1.25 0-2.313.437-3.188 1.312S6.5 12.75 6.5 14c0 1.25.437 2.313 1.312 3.188S9.75 18.5 11 18.5zm0-2c-.7 0-1.292-.242-1.775-.725C8.742 15.292 8.5 14.7 8.5 14s.242-1.292.725-1.775C9.708 11.742 10.3 11.5 11 11.5s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725z"
                fill="#6D6E71"
            />
        </Svg>
    );
};
