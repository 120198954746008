import {Controller} from 'react-hook-form';
import React, {useRef} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IMaxAmountFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {StyleNumericFormat} from '@/components/Loan/styles';

export const MaxAmountField: React.FC<IMaxAmountFieldProps> = ({control, errors}) => {
    const maxAmountRef = useRef<HTMLInputElement>(null);
    return (
        <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.MAX_AMOUNT}
            </Text>
            <Controller
                name="maxAmount"
                control={control}
                render={({field: {onChange, value, ...field}}) => (
                    <StyleNumericFormat
                        {...field}
                        placeholder="$"
                        autoComplete="off"
                        type="text"
                        prefix="$ "
                        decimalSeparator="."
                        thousandSeparator={true}
                        decimalScale={0}
                        allowNegative={false}
                        value={value}
                        height="38px"
                        width="90%"
                        textAlign="start"
                        fontSize="1rem"
                        FontFamily="Nunito"
                        FontWeight="400"
                        Color={errors.maxAmount === undefined ? '#2A2C2F' : '#ef4f55'}
                        radius="2rem"
                        getInputRef={maxAmountRef}
                        onValueChange={(values) => {
                            onChange(values.floatValue);
                        }}
                    />
                )}
            />
            {errors.maxAmount && (
                <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem">
                    <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                    {errors.maxAmount.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
