import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import upArrowSelected from '@components/Branches/Images/UpArrowSelected.svg';
import {ScheduleMenu} from '@components/Branches/ManageBranch/Schedules/ScheduleMenu';
import {IBranchSchedule} from '@components/Branches/interfaces';
import {GetBranchSchedule} from '@components/Branches/ManageBranch/Redux/Actions/GetBranchSchedule';
import {GetScheduleEndeavor} from '@components/Branches/ManageBranch/Redux/Actions/GetScheduleEndeavor';
import {GetScheduleDischarge} from '@components/Branches/ManageBranch/Redux/Actions/GetScheduleDischarge';

export const Schedules = ({showItems, setShowItems}: IBranchSchedule) => {
    const {idBranch} = useParams();
    const dispatch: AppDispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        dispatch(GetBranchSchedule(Number(idBranch), token));
        dispatch(GetScheduleEndeavor(Number(idBranch), token));
        dispatch(GetScheduleDischarge(Number(idBranch), token));
    }, []);
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                backG="#f3f3f3"
                Height="56px"
                Justify="space-between"
                Radius="4px 4px 0 0"
                Border="1px solid #e5e7e9"
                Padding="18.5px 16px"
            >
                <Text FontSize="1rem" FontWeight="500" Color="#414042">
                    Horarios
                </Text>
                {!showItems.schedule ? (
                    <Image
                        src={downArrowSelected}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                schedule: !showItems.schedule,
                                info: false,
                                user: false,
                                services: false,
                                cashFlow: false,
                            });
                        }}
                    />
                ) : (
                    <Image
                        src={upArrowSelected}
                        alt="up-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                schedule: !showItems.schedule,
                            });
                        }}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="column"
                Height="auto"
                Padding="16px"
                Radius="0 0 4px 4px"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Gap="8px"
                MarginBt="24px"
            >
                {!showItems.schedule ? (
                    <Text FontSize="0.875rem" Color="#6d6e71">
                        Gestionar horarios, días hábiles de la sucursal
                    </Text>
                ) : (
                    <ScheduleMenu showItems={showItems} setShowItems={setShowItems} />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
