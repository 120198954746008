import React from 'react';
import {Text} from '@/components/Shopify/Ecommerce/styles';

const ErrorMessage = ({children}: {children: React.ReactNode}) => {
    return (
        <Text FontFamily="Nunito" FontSize="0.8rem" Color="#ff6357">
            {children}
        </Text>
    );
};

export default ErrorMessage;
