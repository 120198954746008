export const MY_PLAN_BUSINESS_LINES = {
    START_BUSINESS_LINE: 'START_BUSINESS_LINE',
    SUCCESS_BUSINESS_LINE: 'SUCCESS_BUSINESS_LINE',
    ERROR_BUSINESS_LINE: 'ERROR_BUSINESS_LINE',
};
export const MY_CONTRACTED_PLANS = {
    START_GET_PLANS: 'START_GET_PLANS',
    SUCCESS_GET_PLANS: 'SUCCESS_GET_PLANS',
    ERROR_GET_PLANS: 'ERROR_GET_PLANS',
};

export const MY_SALES_CHANNELS = {
    START_GET_CHANNELS: 'START_GET_CHANNELS',
    SUCCESS_GET_CHANNELS: 'SUCCESS_GET_CHANNELS',
    ERROR_GET_CHANNELS: 'ERROR_GET_CHANNELS',
};
