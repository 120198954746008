import React from 'react';
import {Input, Image, Text} from '@EcommerceShopify/styles';
import {SearchInput} from '@/components/Customer/Styles';
import calendarIcon from '@components/Branches/Images/CalendarIcon.svg';
import {DatePickerInputProps} from '@General/Atoms/CalendarDateSelection/interfaces';

const DatePickerInput: React.FC<DatePickerInputProps> = ({
    placeholder,
    value,
    onClick,
    error,
    field,
}) => (
    <>
        <SearchInput
            error={error}
            Height="40px"
            Position="none"
            GridColumn="90% 10%"
            BoxShadowH=""
            BoxShadowF=""
            Padding="8px 16px"
            BackG="#FFFFFF"
            Radius="32px"
            onClick={onClick}
        >
            <Input
                {...field}
                placeholder={placeholder}
                value={value}
                error={!!error}
                readOnly
                onClick={onClick}
                disabled
            />
            <Image src={calendarIcon} alt="icon-calendar" Cursor="pointer" />
        </SearchInput>
        <Text Color="#FF6357" FontSize="12px">
            {error}
        </Text>
    </>
);
export default DatePickerInput;
