import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import CreditRequestInformationBody from '@/components/CreditsAndPawns/CreditRequest/CreditRequestInformationBody';
import {CREDIT_REQUEST_INFORMATION} from '@/components/CreditsAndPawns/constants';
import {ICreditRequestInformationProps} from '@/components/CreditsAndPawns/interfaces';
import {getRequestedCreditSolicitudeAction} from '@/components/CreditsAndPawns/Redux/Actions/GetApprovedCreditSolicitudeAction';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const CreditRequestInformation = ({
    creditRequestData,
    solicitudeId,
}: {
    creditRequestData: ICreditRequestInformationProps;
    solicitudeId: number;
}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {recalculatedAmount} = useSelector((state: RootState) => {
        return state.GetPercentageAmortizationReducer;
    });
    const handleCreditSolicited = (isApproved: boolean = false) => {
        dispatch(
            getRequestedCreditSolicitudeAction({
                token,
                amountApprobate: recalculatedAmount,
                solicitudeId,
                isApproved,
            })
        );
    };
    return (
        <ContainerFlex
            Height="650px"
            Width="508px"
            Radius="24px"
            Border="1px solid #E8E9EA"
            backG="#fff"
            FlexDir="column"
            Padding="16px 24px"
            Justify="space-between"
            Gap="16px"
        >
            <ContainerFlex Width="100%" Height="24px" Justify="space-between" Align="start">
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.25rem"
                    Color="#1D1E20"
                    FontStyle="normal"
                    LHeight="1.5rem"
                >
                    {CREDIT_REQUEST_INFORMATION.SUB_TITLE}
                </Text>
                <Text
                    FontFamily="Nunito"
                    FontWeight="400"
                    FontSize="1.0rem"
                    Color="#1D1E20"
                    FontStyle="normal"
                    LHeight="1.5rem"
                >
                    {`#${creditRequestData.solicitudeNumber}`}
                </Text>
            </ContainerFlex>
            <CreditRequestInformationBody {...creditRequestData} />
            <ContainerFlex Height="40px" Width="100%" Gap="16px">
                <ButtonGenerals
                    Width="100%"
                    Height="40px"
                    Padding="8px 16px"
                    BackGC="#A82424"
                    HBackG="#A82424"
                    onClick={() => handleCreditSolicited()}
                >
                    {CREDIT_REQUEST_INFORMATION.CTA_REJECT}
                </ButtonGenerals>
                <ButtonGenerals
                    Width="100%"
                    Height="40px"
                    Padding="8px 16px"
                    BackGC="#5A5AFF"
                    HBackG="#5A5AFF"
                    onClick={() => handleCreditSolicited(true)}
                >
                    {`${CREDIT_REQUEST_INFORMATION.CTA_APPROVE} ${numberToCurrencyWithoutCents(
                        recalculatedAmount
                    )}`}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditRequestInformation;
