export const ABOUT_PERSONAL_DATA_START = 'CREATE_ABOUT_DATA_START';
export const ABOUT_PERSONAL_DATA_SUCCESS = 'CREATE_ABOUT_DATA_SUCCESS';
export const ABOUT_PERSONAL_DATA_ERROR = 'CREATE_ABOUT_DATA_ERROR';

export const GET_ALL_VALIDATE_ID_TYPE = 'GET_ALL_VALIDATE_ID_TYPE';
export const GET_ALL_VALIDATE_ID_TYPE_SUCCESS = 'GET_ALL_VALIDATE_ID_TYPE_SUCCESS';
export const GET_ALL_VALIDATE_ID_TYPE_ERROR = 'GET_ALL_VALIDATE_ID_TYPE_ERROR';

export const POST_ADD_CHECK_IDENTITY_START = 'POST_ADD_CHECK_IDENTITY_START';
export const POST_ADD_CHECK_IDENTITY_SUCCESS = 'POST_ADD_CHECK_IDENTITY_SUCCESS';
export const POST_ADD_CHECK_IDENTITY_ERROR = 'POST_ADD_CHECK_IDENTITY_ERROR';

export const PUT_UPDATE_SAVE_DOCUMENTS_ID_START = 'PUT_UPDATE_SAVE_DOCUMENTS_ID_START';
export const PUT_UPDATE_SAVE_DOCUMENTS_ID_SUCCESS = 'PUT_UPDATE_SAVE_DOCUMENTS_ID_SUCCESS';
export const PUT_UPDATE_SAVE_DOCUMENTS_ID_ERROR = 'PUT_UPDATE_SAVE_DOCUMENTS_ID_ERROR';
export const VALIDATE_LOANS_DATA_START = 'VALIDATE_LOANS_DATA_START';
export const VALIDATE_LOANS_DATA_SUCCESS = 'VALIDATE_LOANS_DATA_SUCCESS';
export const VALIDATE_LOANS_DATA_ERROR = 'VALIDATE_LOANS_DATA_ERROR';

export const ABOUT_EMPLOYMENT_START = 'ABOUT_EMPLOYMENT_START';
export const ABOUT_EMPLOYMENT_SUCCESS = 'ABOUT_EMPLOYMENT_SUCCESS';
export const ABOUT_EMPLOYMENT_ERROR = 'ABOUT_EMPLOYMENT_ERROR';

export const EMPLOYMENT_SITUATION_START = 'EMPLOYMENT_SITUATION_START';
export const EMPLOYMENT_SITUATION_SUCCESS = 'EMPLOYMENT_SITUATION_SUCCESS';
export const EMPLOYMENT_SITUATION_ERROR = 'EMPLOYMENT_SITUATION_ERROR';

export const CAT_LABOR_OLD_START = 'CAT_LABOR_OLD_START';
export const CAT_LABOR_OLD_SUCCESS = 'CAT_LABOR_OLD_SUCCESS';
export const CAT_LABOR_OLD_ERROR = 'CAT_LABOR_OLD_ERROR';

export const SAVE_LOANS_USER = 'SAVE_LOANS_USER';
export const SAVE_LOANS_ADDRESS = 'SAVE_LOANS_ADDRESS';
export const SAVE_LOANS_JOB = 'SAVE_LOANS_JOB';
export const SAVE_LOANS_RESET = 'SAVE_LOANS_RESET';

export const DATA_STEP_LOANS = 'DATA_STEP_LOANS';

export const GET_ALL_CAT_BANK_START = 'GET_ALL_CAT_BANK_START';
export const GET_ALL_CAT_BANK_SUCCESS = 'GET_ALL_CAT_BANK_SUCCESS';
export const GET_ALL_CAT_BANK_ERROR = 'GET_ALL_CAT_BANK_ERROR';

export const GET_CREDIT_LOAN_SUMMARY_START = 'GET_CREDIT_LOAN_SUMMARY_START';
export const GET_CREDIT_LOAN_SUMMARY_SUCCESS = 'GET_CREDIT_LOAN_SUMMARY_SUCCESS';
export const GET_CREDIT_LOAN_SUMMARY_ERROR = 'GET_CREDIT_LOAN_SUMMARY_ERROR';

export const ADD_WIRE_TRANSFER_LOAN_START = 'ADD_WIRE_TRANSFER_START';
export const ADD_WIRE_TRANSFER_LOAN_SUCCESS = 'ADD_WIRE_TRANSFER_SUCCESS';
export const ADD_WIRE_TRANSFER_LOAN_ERROR = 'ADD_WIRE_TRANSFER_ERROR';

export const GET_AMORTIZATION_TABLE_LOAN_START = 'GET_AMORTIZATION_TABLE_LOAN_START';
export const GET_AMORTIZATION_TABLE_LOAN_SUCCESS = 'GET_AMORTIZATION_TABLE_LOAN_SUCCESS';
export const GET_AMORTIZATION_TABLE_LOAN_ERROR = 'GET_AMORTIZATION_TABLE_LOAN_ERROR';

export const UPDATE_PERSONAL_LOANS_START = 'UPDATE_PERSONAL_LOANS_START';
export const UPDATE_PERSONAL_LOANS_SUCCESS = 'UPDATE_PERSONAL_LOANS_SUCCESS';
export const UPDATE_PERSONAL_LOANS_ERROR = 'UPDATE_PERSONAL_LOANS_ERROR';
export const REJECT_LOAN_START = 'REJECT_LOAN_START';
export const REJECT_LOAN_SUCCESS = 'REJECT_LOAN_SUCCESS';
export const REJECT_LOAN_ERROR = 'REJECT_LOAN_ERROR';

export const TOGGLE_REJECT_MODAL = 'TOGGLE_REJECT_MODAL';
