import {DateTime} from 'luxon';
import {Box} from '@mui/material';
import 'swiper/swiper-bundle.css';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import {Swiper as SwiperType} from 'swiper';
import React, {useRef, useState} from 'react';

import arrowUpIcon from '@images/arrowUpIcon.svg';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import arrowSwiper from '@images/arrowDark.svg';
import {DATE_FORMATS} from '@Shopify/ListClient/constants';
import * as style from '@Shopify/ClientProfile/slylesClient';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ICardActions} from '@Shopify/ClientProfile/interfaces';
import {formatDate, formatNum} from '@/hooks/currentFormatUtils';
import {SwiperSlider} from '@Shopify/ClientProfile/Styles/styles';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {BUTTON_CONFIG, QUICK_ACTIONS} from '@Shopify/ClientProfile/constants';

export const QuickActions = () => {
    const swiperRef = useRef<SwiperType | null>(null);
    const [quickActions, setQuickActions] = useState(true);
    const {actions} = useSelector((state: RootState) => state.quickAction);
    const convertDate = (date: string) => DateTime.fromJSDate(new Date(date));

    const productDetail = (title: string, detail: string) => {
        return (
            <ContainerFlex FlexDir="Column" Align="start">
                <Text Color="#2A2C2F">{title}</Text>
                <Text {...style.cardsTitle}>{detail}</Text>
            </ContainerFlex>
        );
    };

    const handleNext = () => swiperRef.current?.slideNext();
    const handlePrev = () => swiperRef.current?.slidePrev();

    return (
        <ContainerFlex {...style.contentGral}>
            <ContainerFlex
                Cursor="pointer"
                Justify="space-between"
                onClick={() => setQuickActions(!quickActions)}
            >
                <Text {...style.titles}>{QUICK_ACTIONS.TITLE}</Text>
                {quickActions ? <Image src={arrowUpIcon} /> : <Image src={arrowDownIcon} />}
            </ContainerFlex>
            {quickActions && (
                <Box sx={{...style.boxSlider}}>
                    <SwiperSlider
                        onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
                        modules={[Navigation]}
                        navigation={false}
                        slidesPerView={3}
                        loop={actions && actions.length > 3}
                    >
                        {actions && actions.length > 0 ? (
                            actions.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <ContainerFlex
                                        Padding={style.gapSlide(index, actions.length - 1)}
                                    >
                                        <ContainerFlex {...style.cards}>
                                            <ContainerFlex Justify="space-between" Align="start">
                                                <ContainerFlex
                                                    FlexDir="column"
                                                    Gap="0.25rem"
                                                    Align="start"
                                                    Width="70%"
                                                >
                                                    <Text Color="#5A5AFF" FontWeight="700">
                                                        {card.contract}
                                                    </Text>
                                                    <Text {...style.cardsTitle}>
                                                        {`${card.productType}:`}
                                                        <Text Color="#2A2C2F" MarginLeft="0.5rem">
                                                            {card.branchName}
                                                        </Text>
                                                    </Text>
                                                </ContainerFlex>
                                                <Text {...style.colorProduct(card.productLabel)}>
                                                    {card.productLabel}
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Gap="0.5rem">
                                                {productDetail(
                                                    QUICK_ACTIONS.DATE_EXPIRATION,
                                                    formatDate(
                                                        convertDate(card.dateExpitation),
                                                        DATE_FORMATS.FULL_DATE
                                                    )
                                                )}
                                                {productDetail(
                                                    QUICK_ACTIONS.PAYMENT,
                                                    formatNum({
                                                        number: card.payment,
                                                        fractionDigits: 0,
                                                        money: true,
                                                    })
                                                )}
                                                {productDetail(
                                                    QUICK_ACTIONS.LIQUIDATION,
                                                    formatNum({
                                                        number: card.liquidation,
                                                        fractionDigits: 2,
                                                        money: true,
                                                    })
                                                )}
                                            </ContainerFlex>
                                            <ContainerFlex {...style.sectionImg}>
                                                <Text Color="#2A2C2F">
                                                    {QUICK_ACTIONS.GARMENTS}
                                                </Text>
                                                <ContainerFlex {...style.tabsImg}>
                                                    {card.garmentImages.map((img, index) => (
                                                        <ContainerFlex
                                                            key={index}
                                                            {...style.contentImg}
                                                        >
                                                            <Image src={img.image} {...style.img} />
                                                        </ContainerFlex>
                                                    ))}
                                                </ContainerFlex>
                                            </ContainerFlex>
                                            <ContainerFlex Gap="0.5rem">
                                                {Object.entries(BUTTON_CONFIG).map(
                                                    ([key, {text, secondaryButton}]) =>
                                                        card.actions[key as keyof ICardActions] && (
                                                            <ButtonGeneral
                                                                key={key}
                                                                width="100%"
                                                                height="2rem"
                                                                secondaryButton={secondaryButton}
                                                                text={text}
                                                            />
                                                        )
                                                )}
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                </SwiperSlide>
                            ))
                        ) : (
                            <ContainerFlex Color="#000">{QUICK_ACTIONS.NO_CONTRACTS}</ContainerFlex>
                        )}
                        <ContainerFlex Justify="end" Gap="1rem" Padding="1rem 1.5rem 0 1.5rem">
                            {actions && actions.length > 3 && (
                                <>
                                    <ButtonGeneral
                                        {...style.buttonsSwiper}
                                        clic={handlePrev}
                                        text={
                                            <Image Transform="rotate(180deg)" src={arrowSwiper} />
                                        }
                                    />
                                    <ButtonGeneral
                                        {...style.buttonsSwiper}
                                        clic={handleNext}
                                        text={<Image src={arrowSwiper} />}
                                    />
                                </>
                            )}
                        </ContainerFlex>
                    </SwiperSlider>
                </Box>
            )}
        </ContainerFlex>
    );
};
