import {
    GET_PRODUCTS_SHOPIFY,
    ADD_CARRITO,
    ADD_CARRITO_SHOPI,
    GET_DETAIL_PAY_SHOPI,
    GET_PRODUCTS_SHOPIFY_SUCCESS,
    GET_PRODUCTS_SHOPIFY_ERROR,
    GET_PRODUCTS_SHOPIFY_DETAIL,
    GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS,
    GET_PRODUCTS_SHOPIFY_DETAIL_ERROR,
    POST_PRODUCTS_SHOPIFY,
    POST_PRODUCTS_SHOPIFY_SUCCESS,
    POST_PRODUCTS_SHOPIFY_ERROR,
    POST_UNAUTHORIZED,
    PUT_DELIVERY_CONTRACT,
    PUT_DELIVERY_CONTRACT_SUCCESS,
    PUT_DELIVERY_CONTRACT_ERROR,
    GET_IDROW,
    GET_DETAIL_PAY,
    GET_PAGE_ECOMMERCE,
    GET_INFO_CLIENT,
    GET_INFO_CLIENT_SUCCESS,
    GET_INFO_CLIENT_ERROR,
    GET_PAGE_ECOMMERCE_SUCCESS,
    GET_PAGE_ECOMMERCE_ERROR,
    GET_INFO_MOVIMIENTS,
    GET_INFO_MOVIMIENTS_SUCCESS,
    GET_INFO_MOVIMIENTS_ERROR,
    GET_PRODUCTS_SHOPIFY_DETAILS,
    GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS,
    GET_PRODUCTS_SHOPIFY_DETAILS_ERROR,
    GET_DOCUMENT_CLIENT,
    GET_DOCUMENT_CLIENT_SUCCESS,
    GET_DOCUMENT_CLIENT_ERROR,
    GET_CONTRACT_CLIENT,
    GET_CONTRACT_CLIENT_SUCCESS,
    GET_CONTRACT_CLIENT_ERROR,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_ERROR,
    PUT_REACTIVATION_CONTRACT,
    PUT_REACTIVATION_CONTRACT_SUCCESS,
    PUT_REACTIVATION_CONTRACT_ERROR,
    ADD_CARRITO_START,
    ADD_CARRITO_ERROR,
    ADD_CARRITO_SUCCESS,
    DELETE_CAR,
    DELETE_CAR_SUCCESS,
    DELETE_CAR_ERROR,
    GET_CAR_DATA,
    GET_CAR_DATA_SUCCESS,
    GET_CAR_DATA_ERROR,
    GET_ENDEAVORS_START,
    GET_ENDEAVORS_SUCCESS,
    GET_ENDEAVORS_ERROR,
    DELETE_CARD_START,
    DELETE_CARD_ERROR,
    DELETE_CARD_SUCCESS,
} from '@TypesProducts/ProductsShopifyTypes';
import Swal from 'sweetalert2';
import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {IKartShopi} from '@Shopify/Products/redux/interface';
import {MovimentsInfo} from '@/components/Shopify/Ecommerce/Subcontainer/interfaces';
export const AddNewCar = () => {
    return {
        type: GET_CAR_DATA,
    };
};
export const AddNewCarSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_CAR_DATA_SUCCESS,
        value: result.data,
    };
};
export const AddNewCarError = () => {
    return {
        type: GET_CAR_DATA_ERROR,
    };
};

export function AddNewCarShopi(typeClient: string, data: IKartShopi, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(AddNewCar());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${
                    typeClient === 'Client'
                        ? URL.urlWeb.AddBackPackClient
                        : URL.urlWeb.AddBackEmployee
                }`,
                data,
                {headers}
            );
            dispatch(AddNewCarSuccess(response));
        } catch (error) {
            dispatch(AddNewCarError());
        }
    };
}

export const dropCar = () => {
    return {
        type: DELETE_CAR,
    };
};
export const dropCarSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_CAR_SUCCESS,
        value: result.data,
    };
};
export const dropCarError = () => {
    return {
        type: DELETE_CAR_ERROR,
    };
};

export function deleteCarShopi(data: AxiosResponse<string>, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(dropCar());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteCarClient}`,
                {headers, data}
            );
            dispatch(dropCarSuccess(response));
        } catch (error) {
            dispatch(dropCarError());
        }
    };
}
export const dropCarValuator = () => {
    return {
        type: DELETE_CAR,
    };
};
export const dropCarValuatorSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_CAR_SUCCESS,
        value: result.data,
    };
};
export const dropCarValuatorError = () => {
    return {
        type: DELETE_CAR_ERROR,
    };
};
export function deleteCarShopiValuator(
    data: {
        dischargeEndorementId: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(dropCarValuator());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteCarValuator}`,
                {headers, data}
            );
            dispatch(dropCarValuatorSuccess(response));
            window.location.reload();
        } catch (error) {
            dispatch(dropCarValuatorError());
        }
    };
}

export const fetchUnauthorized = () => {
    return {
        type: POST_UNAUTHORIZED,
        autho: false,
    };
};
export const fetchAuthorized = () => {
    return {
        type: POST_UNAUTHORIZED,
        autho: true,
    };
};
export const fetchGetProductsShopify = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY,
    };
};
export const fetchGetProductsShopifySuccess = (result: {
    data: AxiosResponse<string>;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_PRODUCTS_SHOPIFY_SUCCESS,
        value: result.data,
        count: result.count,
        pageIndex: result.pageIndex,
    };
};
export const fetchGetProductsShopifyError = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY_ERROR,
    };
};
export const fetchAddCarrito = (compras?: []) => {
    return {
        type: ADD_CARRITO,
        value: compras,
    };
};
export const fetchAddCarritoStart = () => {
    return {
        type: ADD_CARRITO_START,
    };
};
export const fetchAddCarritoError = () => {
    return {
        type: ADD_CARRITO_ERROR,
    };
};
export const fetchAddCarritoSuccess = (compras: AxiosResponse<string>) => {
    return {
        type: ADD_CARRITO_SUCCESS,
        value: compras,
    };
};
export function AddCarrito(typeClient: string, data: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchAddCarritoStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${
                    typeClient === 'Client'
                        ? URL.urlWeb.DetailsCar.replace('{carClientId}', data)
                        : URL.urlWeb.GetCarEmploye.replace('{carShoopingId}', data)
                }`,
                {headers}
            );
            dispatch(fetchAddCarritoSuccess(response));
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                title: '¡Se ha vaciado el carrito de compras!',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
            dispatch(fetchAddCarritoError());
            dispatch(fetchAddCarrito([]));
        }
    };
}

export const fetchAddCarritoShopi = (compras: []) => {
    return {
        type: ADD_CARRITO_SHOPI,
        value: compras,
    };
};
export function getCatalogFamily(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetProductsShopify());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Family}`,
                {headers}
            );
            dispatch(fetchGetProductsShopifySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetProductsShopifyError());
        }
    };
}
export function getProductsShopify(filtro = '', pageSize = 10, pageIndex = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filtro === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                filtro,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetProductsShopify());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_SHOPIFY}${URL.urlShopify.getProductsShopify}${queryString}`,
                {headers}
            );

            dispatch(fetchGetProductsShopifySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetProductsShopifyError());
        }
    };
}

export const fetchGetProductsShopifyDetail = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAIL,
    };
};
export const fetchGetProductsShopifyDetailSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS,
        value: result.data,
    };
};
export const fetchGetProductsShopifyDetailError = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAIL_ERROR,
    };
};

export function getProductsShopifyDetail(sku: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetProductsShopifyDetail());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_SHOPIFY
                }${URL.urlShopify.getProductsShopifyDetail.replace('{sku}', sku)}`,
                {headers}
            );
            dispatch(fetchGetProductsShopifyDetailSuccess(response));
        } catch (error) {
            dispatch(fetchGetProductsShopifyDetailError());
        }
    };
}

export const fetchPostProductsShopifyDetail = () => {
    return {
        type: POST_PRODUCTS_SHOPIFY,
    };
};
export const fetchPostProductsShopifyDetailSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: POST_PRODUCTS_SHOPIFY_SUCCESS,
        value: result.data,
    };
};
export const fetchPostProductsShopifyDetailError = () => {
    return {
        type: POST_PRODUCTS_SHOPIFY_ERROR,
    };
};

export function postProductsShopifyDetail(
    data: AxiosResponse<string>,
    token: string,
    dataimages: [{urldata: string}],
    sku: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostProductsShopifyDetail());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_SHOPIFY}${URL.urlShopify.getProductsShopify}`,
                data,
                {headers}
            );
            dispatch(fetchPostProductsShopifyDetailSuccess(response));

            if (response.data !== 0) {
                dataimages.map((item: {urldata: string}, index: number) => {
                    const formData = new FormData();
                    formData.append('SKU', sku);
                    formData.append('Posicion', String(index + 1));
                    formData.append('IdProducto', response.data);
                    axios.post(
                        `${process.env.REACT_APP_SERVICE_SHOPIFY}${URL.urlShopify.getProductsShopify}/image`,
                        formData,
                        {headers}
                    );
                    return response;
                });
            }
            Swal.fire({
                icon: 'success',
                title: 'Guardar Articulo',
                text: '¡El articulo ha sido guardado con éxito!',
            });
        } catch (error) {
            dispatch(fetchPostProductsShopifyDetailError());
            Swal.fire({
                icon: 'error',
                title: 'Guardar Articulo',
                text: '¡Error al guardar un articulo!',
            });
        }
    };
}

export const fetchGetProductsEcommerce = () => {
    return {
        type: GET_PAGE_ECOMMERCE,
    };
};
export const fetchGetProductsEcommerceSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PAGE_ECOMMERCE_SUCCESS,
        value: result.data,
    };
};
export const fetchGetProductsEcommerceError = () => {
    return {
        type: GET_PAGE_ECOMMERCE_ERROR,
    };
};

export const fetchGetInfoClient = () => {
    return {
        type: GET_INFO_CLIENT,
    };
};
export const fetchGetInfoClientSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_INFO_CLIENT_SUCCESS,
        value: result.data,
    };
};
export const fetchGetInfoClientError = () => {
    return {
        type: GET_INFO_CLIENT_ERROR,
    };
};

export function getInfoClients(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetInfoClient());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace('admin', 'consumer')}${
                        URL.urlStore.Clients
                    }`,
                    {headers}
                );
                dispatch(fetchGetInfoClientSuccess(response));
            } catch (error) {
                dispatch(fetchGetInfoClientError());
            }
        }
    };
}

export const fetchGetInfoMovimients = () => {
    return {
        type: GET_INFO_MOVIMIENTS,
    };
};
export const fetchGetInfoMovimientsSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_INFO_MOVIMIENTS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetInfoMovimientsError = () => {
    return {
        type: GET_INFO_MOVIMIENTS_ERROR,
    };
};

export const fetchGetProductsShopifyDetails = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAILS,
    };
};
export const fetchGetProductsShopifyDetailsSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetProductsShopifyDetailsError = () => {
    return {
        type: GET_PRODUCTS_SHOPIFY_DETAILS_ERROR,
    };
};

export function getProductsShopifyDetails(sku: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetProductsShopifyDetails());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_SHOPIFY
                }${URL.urlShopify.getProductsShopifyDetail.replace('{sku}', sku)}`,
                {headers}
            );
            dispatch(fetchGetProductsShopifyDetailsSuccess(response));
        } catch (error) {
            dispatch(fetchGetProductsShopifyDetailsError());
        }
    };
}

export const fetchGetDocumentClient = () => {
    return {
        type: GET_DOCUMENT_CLIENT,
    };
};
export const fetchGetDocumentClientSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_DOCUMENT_CLIENT_SUCCESS,
        value: result.data,
    };
};
export const fetchGetDocumentClientError = () => {
    return {
        type: GET_DOCUMENT_CLIENT_ERROR,
    };
};

export function getDocumentClient(idRow: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetDocumentClient());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace(
                        'admin',
                        'consumer'
                    )}${URL.urlStore.DocumentClients.replace('{idRow}', idRow)}`,
                    {headers}
                );
                dispatch(fetchGetDocumentClientSuccess(response));
            } catch (error) {
                dispatch(fetchGetDocumentClientError());
            }
        }
    };
}

export const fetchGetContractClient = () => {
    return {
        type: GET_CONTRACT_CLIENT,
    };
};
export const fetchGetContractClientSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_CONTRACT_CLIENT_SUCCESS,
        value: result.data,
    };
};
export const fetchGetContractClientError = () => {
    return {
        type: GET_CONTRACT_CLIENT_ERROR,
    };
};

export function getContractClient(idRow: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetContractClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Contract.replace(
                    '{idRow}',
                    idRow
                )}`,
                {headers}
            );
            dispatch(fetchGetContractClientSuccess(response));
        } catch (error) {
            dispatch(fetchGetContractClientError());
        }
    };
}

export const GetIdRow = (idRow: number) => {
    return {
        type: GET_IDROW,
        value: idRow,
    };
};

export const GetDetailPay = (pay: MovimentsInfo) => {
    return {
        type: GET_DETAIL_PAY,
        value: pay,
    };
};

export const GetDetailPayShopi = (pay: string) => {
    return {
        type: GET_DETAIL_PAY_SHOPI,
        value: pay,
    };
};

export const fetchGetTransaction = () => {
    return {
        type: GET_TRANSACTION,
    };
};
export const fetchGetTransactionSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_TRANSACTION_SUCCESS,
        value: result.data,
    };
};
export const fetchGetTransactionError = () => {
    return {
        type: GET_TRANSACTION_ERROR,
    };
};

export function getTransaction(data: AxiosResponse<string>, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetTransaction());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GeneratePayment}`,
                data,
                {headers}
            );
            dispatch(fetchGetTransactionSuccess(response));
            Swal.fire({
                icon: 'success',
                title: 'Pagar',
                text: '¡Su pago ha sido exitoso!',
            }).then((result: {isConfirmed: boolean}) => {
                if (result.isConfirmed) {
                    window.location.href = `/PaymentSuccess`;
                }
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Pagar',
            });
            dispatch(fetchGetTransactionError());
        }
    };
}
export const fetchPutDeliveryContract = () => {
    return {
        type: PUT_DELIVERY_CONTRACT,
    };
};
export const fetchPutDeliveryContractSuccess = () => {
    return {
        type: PUT_DELIVERY_CONTRACT_SUCCESS,
    };
};
export const fetchPutDeliveryContractError = () => {
    return {
        type: PUT_DELIVERY_CONTRACT_ERROR,
    };
};
export function putDeliveryContract(idRow: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        fetchPutDeliveryContract();
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.DeliveryContract.replace(
                    '{idRow}',
                    idRow
                )}`,
                {headers}
            );
            Swal.fire({
                icon: 'success',
                title: 'Entregar',
                text: '¡Ha sido entregado exitosamente!',
            });
            dispatch(fetchPutDeliveryContractSuccess());
            dispatch(getDocumentClient(idRow, token));
        } catch (error) {
            dispatch(fetchPutDeliveryContractError());
            dispatch(fetchGetTransactionError());
        }
    };
}

export const fetchPostEmailVerification = () => {
    return {
        type: POST_PRODUCTS_SHOPIFY,
    };
};
export const fetchPostEmailVerificationSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: POST_PRODUCTS_SHOPIFY_SUCCESS,
        value: result.data,
    };
};
export const fetchPostEmailVerificationError = () => {
    return {
        type: POST_PRODUCTS_SHOPIFY_ERROR,
    };
};

export function postEmailVerification(data: AxiosResponse<string>, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostEmailVerification());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.EmailReactivation}`,
                data,
                {headers}
            );
            dispatch(fetchPostEmailVerificationSuccess(response));

            Swal.fire({
                icon: 'success',
                title: 'Reactivar',
                text: '¡Ha sido reactivado con éxito!',
            });
        } catch (error) {
            dispatch(fetchPostEmailVerificationError());
        }
    };
}

export const fetchPutReactivationContract = () => {
    return {
        type: PUT_REACTIVATION_CONTRACT,
    };
};
export const fetchPutReactivationContractSuccess = () => {
    return {
        type: PUT_REACTIVATION_CONTRACT_SUCCESS,
    };
};
export const fetchPutReactivationContractError = () => {
    return {
        type: PUT_REACTIVATION_CONTRACT_ERROR,
    };
};

export function putReactivationContract(token: string, idSucursal: string, contract: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutReactivationContract());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.ContractReactivation.replace(
                    '{idSucursal}',
                    idSucursal
                ).replace('{Contract}', contract)}`,
                '',
                {headers}
            );
            dispatch(fetchPutReactivationContractSuccess());
        } catch (error) {
            return;
        }
    };
}

export const fetchGetEndeavorStart = () => {
    return {
        type: GET_ENDEAVORS_START,
    };
};
export const fetchGetEndeavorSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_ENDEAVORS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetEndeavorError = () => {
    return {
        type: GET_ENDEAVORS_ERROR,
    };
};

export function GetEndeavorList(clientId: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEndeavorStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetHomeClient.replace(
                    '{clientId}',
                    clientId
                )}`,
                {headers}
            );
            dispatch(fetchGetEndeavorSuccess(response));
        } catch (error) {
            dispatch(fetchGetEndeavorError());
        }
    };
}

export const fetchDeleteCard = () => {
    return {
        type: DELETE_CARD_START,
    };
};
export const fetchDeleteCardSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_CARD_SUCCESS,
        value: result.data,
    };
};
export const fetchDeleteCardError = () => {
    return {
        type: DELETE_CARD_ERROR,
    };
};

export function deleteCardPayment(data: {accountId: number; clientId: number}, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(dropCar());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteCard}`,
                {headers, data}
            );
            dispatch(dropCarSuccess(response));
            Swal.fire({
                icon: 'success',
                title: 'Guardar Articulo',
                text: '¡El articulo ha sido guardado con éxito!',
            });
        } catch (error) {
            dispatch(dropCarError());
            Swal.fire({
                icon: 'error',
                title: 'No se pudo eliminar',
                text: 'Ocurrió un error, porfavor intente nuevamnente',
            });
        }
    };
}
