import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {
    GET_ACTIVATE_LEVEL_OR_ITEM_START,
    GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS,
    GET_ACTIVATE_LEVEL_OR_ITEM_ERROR,
} from '@components/Catalogue/Redux/Types/Types';
import {IFiltersActivateLevelOrItem} from '@components/Catalogue/interfaces';
import StringUtils from '@/GenericScripts/utils';
import {AppDispatch} from '@/config/store';

export const getActivateLevelOrItemStart = () => {
    return {
        type: GET_ACTIVATE_LEVEL_OR_ITEM_START,
    };
};

export const getActivateLevelOrItemSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS,
        payload: result.data,
    };
};

export const getActivateLevelOrItemError = (error: AxiosError) => {
    return {
        type: GET_ACTIVATE_LEVEL_OR_ITEM_ERROR,
        error,
    };
};

export const getActivateLevelOrItem = (token: string, filters: IFiltersActivateLevelOrItem) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(getActivateLevelOrItemStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetActivateLevelOrItem}${queryString}`,
                {headers}
            );
            dispatch(getActivateLevelOrItemSuccess(response));
        } catch (error) {
            dispatch(getActivateLevelOrItemError(error as AxiosError));
        }
    };
};
