import styled from '@emotion/styled';
import pinDropHover from '@images/pinDropHover.svg';
import locationHover from '@images/locationHover.svg';
import {
    IPropsAddImgs,
    IPropsContainerCard,
    IPropsFileUpLoad,
    IPropsFileUpLoadContent,
    IPropsIconContainer,
    IPropsIconImg,
    IPropsIconSvg,
    IPropsIconsSpan,
    IPropsImageIcons,
    IPropsImgFlag,
    IPropsImgs,
    IPropsImgsContent,
} from '@/components/Quoter/interfacesStyles';
export const contentStyles = {
    Position: 'absolute',
    Height: '',
    PositionTop: '65%',
    PositionLeft: '0',
    Width: '100%',
    BoxShadow: '',
    BoxS: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
    backG: '#fff',
    FlexDir: 'column',
    OverFlow: 'auto',
    Justify: 'start',
    MaxH: '13rem',
    Radius: '0.5rem',
    ZIndex: '2',
};
export const anotherContentStyles = {
    Position: 'absolute',
    Height: 'auto',
    Width: '100%',
    BoxShadow: '',
    BoxS: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
    backG: '#fff',
    FlexDir: 'column',
    OverFlow: 'auto',
    Justify: 'start',
    MaxH: '13rem',
    Radius: '0.5rem',
    ZIndex: '6',
    top: '',
};

export const ImageProduct = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 4px;
`;
export const ImageCard = styled.figure`
    height: 100%;
    width: 100%;
    margin: 0px;
`;
export const StateContainer = styled.div`
    background-color: #e3f2d7;
    text-align: center;
    padding: 0;
    height: 2.5rem;
    width: 50%;
`;
export const ImageIcons = styled.img<IPropsImageIcons>`
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    cursor: 'pointer';
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.Padding};
    color: ${(props) => props.Color};
    &:hover {
        filter: invert(64%) sepia(55%) saturate(496%) hue-rotate(51deg) brightness(97%)
            contrast(84%);
        cursor: pointer;
    }
`;
export const IconContainer = styled.div<IPropsIconContainer>`
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    display: flex;
    justify-content: ${(props) => props.Justify};
    justify-self: ${(props) => props.JustifySelf};
    align-items: center;
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: ${(props) => (props.FontWeight ? props.FontWeight : '100')};
    align-items: ${(props) => props.Align};
    padding: ${(props) => props.Padding};
    transform: ${(props) => props.Transform};
    &:hover {
        color: ${(props) => props.ColorH};
    }
    cursor: ${(props) => (props.Cursor ? props.Cursor : 'pointer')};
    border-radius: ${(props) => props.BRadius};
    border-bottom-left-radius: ${(props) => props.BottomLeftRad};
    border-top-left-radius: ${(props) => props.TopLeftRad};
    border-bottom-right-radius: ${(props) => props.BottomRightRad};
    border-top-right-radius: ${(props) => props.TopRightRad};
    background: ${(props) => props.backG};
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    & svg {
        fill: ${(props) => props.Fill};
    }
`;
IconContainer.defaultProps = {
    Color: ' ',
    FontSize: ' ',
    Padding: '0',
    Width: '24px',
    Height: '24px',
    Justify: 'center',
};

export const IconSvg = styled.svg<IPropsIconSvg>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    cursor: ${(props) => props.Cursor};
    margin: ${(props) => props.Margin};
    fill: 'none';
    user-select: none;
    &:hover path {
        fill: ${(props) => props.hoverF};
    }
`;

export const ContainerCard = styled.div<IPropsContainerCard>`
    background-color: white;
    justify-content: flex-start;
    align-items: flex-start;
    height: 10rem;
    flex-direction: column;
    width: 20%;
    margin: 0 3rem 0 0;
    border: ${(props) => props.Border};
    div:last-child {
        margin: ${(props) => props.MarginChild};
    }
`;
ContainerCard.defaultProps = {
    Border: '1px solid #d0d0d0',
    MarginChild: '0 3rem 0 0',
};

export const IconsSpan = styled.span<IPropsIconsSpan>`
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    color: ${(props) => props.Color};
    text-align: center;
`;
export const selectContent = {
    FlexDir: 'column',
    Align: 'start',
    Height: 'auto',
    Justify: 'space-between',
    Gap: '4px',
};

export const level = {
    FontSize: '0.75rem',
    FontWeight: '500',
    MaxH: '12.5rem',
    Radius: '0.5rem',
    wSpace: 'normal',
};

export const FileUpLoad = styled.input<IPropsFileUpLoad>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    opacity: ${(props) => props.Opacity};
    overflow: ${(props) => props.Overflow};
    position: ${(props) => props.Position};
    z-index: ${(props) => props.ZIndex};
    cursor: ${(props) => props.Cursor};
`;

FileUpLoad.defaultProps = {
    Width: '0.1px',
    Height: '0.1px',
    Opacity: '0',
    Overflow: 'hidden',
    Position: 'absolute',
    Cursor: 'Pointer',
};

export const FileUpLoadContent = styled.label<IPropsFileUpLoadContent>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.RABorder};
    display: ${(props) => props.Display};
    flex-direction: ${(props) => props.FDirection};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    margin: ${(props) => props.Margin};
    background-color: ${(props) => props.BackG};
    cursor: ${(props) => props.Cursor};
    &:hover {
        background-color: #c4c4c4;
    }
`;

FileUpLoadContent.defaultProps = {
    Width: '210px',
    Height: '130px',
    RABorder: '4px',
    Display: 'flex',
    FDirection: 'column',
    Justify: 'center',
    Align: 'center',
    BackG: '#E5E7E9',
};
export const tabsBlock = {
    Height: '40px',
    Cursor: 'pointer',
};
export const miniContent = {
    Align: 'start',
    FlexDir: 'column',
    Gap: '0.5rem',
    Justify: 'space-between',
    Height: 'auto',
};
export const AddImgs = styled.div<IPropsAddImgs>`
    justify-content: ${(props) => props.Justify};
    z-index: ${(props) => props.ZIndex};
    position: ${(props) => props.Position};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.BRadius};
    height: ${(props) => props.Height};
    opacity: ${(props) => props.Opacity};

    &:hover {
        img:first-child {
            opacity: ${(props) => (props.HOpacity === '1' ? '0.3' : '9')};
        }

        img:nth-child(2) {
            opacity: ${(props) => (props.HOpacity === '1' ? '9' : '9')};
        }
    }
`;

AddImgs.defaultProps = {
    Justify: 'center',
    ZIndex: '0',
    Position: 'relative',
    Margin: '0 1.5rem 2rem 0',
    Width: '210px',
    Height: '130px',
};

export const ImgsContent = styled.div<IPropsImgsContent>`
    display: flex;
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    box-sizing: border-box;
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    margin: ${(props) => props.Margin};
    margin-bottom: ${(props) => props.MarginB};

    div:first-child {
        span {
            background-color: rgba(229, 231, 233, 0.8);
            padding: 0.2rem;
            text-align: center;
            top: 80%;
            left: 0;
            z-index: 3;
            position: absolute;
            width: 209px;
            color: #6d6e71;
            font-size: 14px;
            font-weight: 500;
            border: 1.2013px solid #e5e7e9;
            border-radius: 0px 0px 4px 4px;
        }
    }

    div:last-child {
        span {
            text-align: center;
            top: 0;
            padding: 0.9rem 0;
            left: 0;
            z-index: 3;
            position: absolute;
            width: 90px;
            color: #6d6e71;
            font-size: 1.2rem;
            font-weight: 400;
            background: linear-gradient(0deg, rgba(243, 243, 243, 0.8), rgba(243, 243, 243, 0.8));
        }
    }
`;

ImgsContent.defaultProps = {
    Align: 'flex-start',
    Justify: 'flex-start',
    FlexWrap: 'wrap',
    Margin: '1rem 0',
    MarginB: '1rem !important',
};

export const Imgs = styled.img<IPropsImgs>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    object-fit: ${(props) => props.ObjectF};
    margin: ${(props) => props.Margin};
    border-radius: ${(props) => props.BRadus};
    .iZIena {
        border-radius: 50%;
    }
`;
export const ImgFlag = styled.img<IPropsImgFlag>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    object-fit: ${(props) => props.ObjectF};
    margin: ${(props) => props.Margin};
    border-radius: ${(props) => props.BRadus};
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    .iZIena img {
        border-radius: 50%;
    }
`;

Imgs.defaultProps = {
    Width: '100%',
    Height: '100%',
    ObjectF: 'contain',
};

export const IconImg = styled.img<IPropsIconImg>`
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    z-index: ${(props) => props.ZIndex};
    padding: ${(props) => props.Padding};
    opacity: ${(props) => props.Opacity};
    cursor: ${(props) => props.Cursor};

    &:hover {
        content: ${(props) => props.Content};
        background-color: ${(props) => props.HBColor};
    }
`;

IconImg.defaultProps = {
    FontSize: '2rem',
    Position: 'absolute',
    Top: '40%',
    Left: '45%',
    Opacity: '0',
    Cursor: 'pointer',
};

export const ImgsDrop = styled.img`
    &:hover {
        content: url(${pinDropHover});
    }
`;

export const ImgsLocation = styled.img`
    &:hover {
        content: url(${locationHover});
    }
`;
