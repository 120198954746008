import React, {useEffect, useState} from 'react';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {POPUPTEXTROLES} from '@Roles/constants';
import {UserRolesSearch} from '@Roles/UserRolesSearch';
import ButtonGeneral from '@components/General/Atoms/Button';
import {useDispatch, useSelector} from 'react-redux';
import {
    GetRolesUserSearch,
    postRolesUserAssignment,
} from '@Roles/Redux/Actions/GetEmployeeUserSearch';
import {RootState} from '@/config/store';
import UserSearchList from '@Roles/UserSearchList';
import {MenuAssignmentProps} from '@Roles/interface';

const MenuRolesAssignment = ({
    showModal,
    setShowModal,
    roleId,
    searchRoles,
}: MenuAssignmentProps) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [errorAssignment, setErrorAssignment] = useState(false);
    const handleSearch = (searchText: string) => {
        setSearchUser(searchText);
    };
    const [searchUser, setSearchUser] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(GetRolesUserSearch(companyId, searchUser ? searchUser : '', token));
        }
    }, [searchUser]);

    const handleCheckboxChange = (itemId: number) => {
        setCheckedItems((prevCheckedItems) => {
            if (prevCheckedItems.includes(itemId)) {
                return prevCheckedItems.filter((id) => id !== itemId);
            } else {
                return [...prevCheckedItems, itemId];
            }
        });
    };

    const assingRoletoUsers = () => {
        dispatch(
            postRolesUserAssignment(
                {enterpriseId: companyId, roleId: roleId, userId: checkedItems},
                token,
                setShowModal,
                setCheckedItems,
                searchRoles
            )
        );
    };

    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => {}}
            titleModalState={true}
            background="none"
            BorderRadius="1rem"
        >
            <ContainerFlex
                Width="41.313rem"
                Height="auto"
                Justify="start"
                Align="start"
                FlexDir="column"
                Padding="1.5rem"
                Gap="2.5rem"
            >
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                    {POPUPTEXTROLES.TITLE}
                </Text>
                <ContainerFlex Justify="start" Align="start" Gap="1rem" FlexDir="column">
                    <UserRolesSearch onSearch={handleSearch} />
                    <ContainerFlex Justify="start" Align="start" FlexDir="column">
                        <Text FontWeight="500" Color="#2A2C2F">
                            {POPUPTEXTROLES.HINT}
                        </Text>
                        <UserSearchList
                            checkedItems={checkedItems}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerForm FlexDir="column" Gap="2.5rem">
                    {errorAssignment && (
                        <Text Self="center" Color="#FF6357" FontSize="0.875rem" FontWeight="500">
                            {POPUPTEXTROLES.SELECTED}
                        </Text>
                    )}

                    <ContainerFlex Gap="1.5rem" Height="2.5rem">
                        <Text
                            onClick={() => {
                                setShowModal(false);
                                setErrorAssignment(false);
                                setSearchUser('');
                                setCheckedItems([]);
                            }}
                            Color="#FF6357"
                            Margin="0.5rem 1rem"
                            MarginB="0.5rem"
                            Cursor="pointer"
                        >
                            Descartar
                        </Text>
                        <ButtonGeneral
                            width="9.313rem"
                            text="Asignar"
                            height="40px"
                            cursor="pointer"
                            clic={() => assingRoletoUsers()}
                        />
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
        </Modal>
    );
};

export default MenuRolesAssignment;
