import React, {useEffect, useState} from 'react';
import {AppDispatch, RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';

import {
    Table,
    TableItem,
    TableItems,
    TableItemsContainer,
    TableRowHead,
    TableRowItemsHome,
} from '@Endeavors/styles';
import {Text, ContainerFlex} from '@EcommerceShopify/styles';
import {handleStatus, titleEndeavors} from '@Endeavors/constants';
import {ImgFlag} from '@/components/Quoter/styles';
import {IconContainer} from '@/components/Quoter/styles';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {
    dataEndeavors,
    endeavors,
    tableEndeavors,
} from '@/components/Shopify/Endeavors/redux/interfaces';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';
import {FontAwesomeIcon} from '@/components/DeadLines/styles';
import {ContextualMenuEndeavors} from '@/components/Shopify/Endeavors/EndeavorsSection/ContextualMenuEndeavors';
import {ModalQR} from '@/components/Shopify/Endeavors/EndeavorsSection/ModalQR';
import {GetCardProducts} from '@/components/Loan/Redux/actions/CardProducts';
import {paymentProducts} from '@/components/Loan/PaymentQuoter/Interfaces';

export function TableEndeavors(props: tableEndeavors) {
    const endeavorsClient: endeavors = useSelector((state: RootState) => {
        return state.endeavorsClient;
    });
    const {setModal, car} = props;
    const login = useSelector((state: RootState) => state.getUsersValidation?.userData);
    const payProducts: paymentProducts = useSelector((state: RootState) => {
        return state.CardProducts;
    });
    const [modalqr, setModalqr] = useState(false);
    const [selected, setSelected] = useState(0);
    const [contextualMenu, setContextualMenu] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const existCar =
        endeavorsClient.endeavors !== null &&
        endeavorsClient.endeavors?.data
            .filter((res) =>
                payProducts.products.data.carClient.some(
                    (car: {pawnId: number}) => car.pawnId === res.pawnId
                )
            )
            .map((item: dataEndeavors) => item.pawnId);

    const handleClickAway = () => {
        setContextualMenu(false);
        setSelected(0);
    };

    useEffect(() => {
        dispatch(GetCardProducts(String(login?.token), Number(login?.carShoopingId)));
    }, [car]);

    return (
        <ContainerFlex Padding="1rem 0 4rem 0">
            <Table>
                <TableRowHead
                    Height="62px"
                    Width="auto"
                    backG="#E5E7E9"
                    GridColumn=".5fr .5fr 1.5fr .5fr .5fr .5fr .5fr .2fr"
                    Padding="0 1rem"
                >
                    {titleEndeavors.map((item: {title: string}, index: number) => (
                        <TableItem Weight="500" Size=".875rem" key={index}>
                            {item.title}
                        </TableItem>
                    ))}
                </TableRowHead>
                <TableItemsContainer GridRow="repeat(3, 5rem)" Overflow="none">
                    {endeavorsClient.loading && (
                        <ContainerFlex Height="20vh">
                            <LoadingGeneral />
                        </ContainerFlex>
                    )}
                    {!endeavorsClient.loading &&
                        (endeavorsClient.endeavors !== null &&
                        endeavorsClient.endeavors.data.length > 0 ? (
                            endeavorsClient.endeavors.data.map((item, index: number) => {
                                return (
                                    <TableRowItemsHome
                                        GridColumn=".5fr .5fr 1.5fr .5fr .5fr .5fr .5fr .2fr"
                                        key={index}
                                        Padding="0 1rem"
                                    >
                                        <TableItems Justify="start">
                                            {item.folioContract && item.urlQR && (
                                                <ContainerFlex
                                                    Cursor="Pointer"
                                                    onClick={() => {
                                                        if (item.folioContract && item.urlQR)
                                                            setModalqr(true);
                                                    }}
                                                >
                                                    <IconContainer
                                                        ColorH="#7AC143"
                                                        FontSize="1.4rem"
                                                        className="material-icons"
                                                    >
                                                        qr_code
                                                    </IconContainer>
                                                    <Text
                                                        FontSize=".785rem"
                                                        Color="#7AC143"
                                                        Padding="0 0 0 0.5rem"
                                                        Cursor="Pointer"
                                                    >
                                                        {item.folioContract}
                                                    </Text>
                                                    <IconContainer
                                                        Color="#7AC143"
                                                        FontSize="1rem"
                                                        className="material-icons"
                                                    >
                                                        keyboard_arrow_down
                                                    </IconContainer>
                                                </ContainerFlex>
                                            )}
                                        </TableItems>

                                        <TableItems Size=".875rem">
                                            <Text
                                                Width="80px"
                                                Justify="center"
                                                BorderRadius="10px"
                                                Self="center"
                                                Border={
                                                    item.nameStatusContract === 'Activo'
                                                        ? '1px solid #35cf44 '
                                                        : '1px solid #ef4f55'
                                                }
                                                Height="20px"
                                                FontSize=".875rem"
                                                bGround={handleStatus(
                                                    item.nameStatusContract === 'Activo' ? 1 : 2,
                                                    'color'
                                                )}
                                            >
                                                {item.nameStatusContract}
                                            </Text>
                                        </TableItems>

                                        <TableItems title={item.branchName.toLowerCase()}>
                                            <ContainerFlex Width="auto">
                                                <ContainerFlex
                                                    Margin="0 8px 0 0"
                                                    Height="24px"
                                                    Width="24px"
                                                >
                                                    <ImgFlag
                                                        Height="24px"
                                                        Width="24px"
                                                        src={item.urlFlag}
                                                    />
                                                </ContainerFlex>
                                                <ContainerFlex
                                                    FlexDir="column"
                                                    Align="start"
                                                    Width="auto"
                                                >
                                                    <Text
                                                        tOver="elipsis"
                                                        FontSize=".875rem"
                                                        Transform="capitalize"
                                                    >
                                                        {item.branchName}
                                                    </Text>
                                                    <Text
                                                        FontSize=".875rem"
                                                        Color="#7AC143"
                                                        Transform="capitalize"
                                                    >
                                                        {item.companyName}
                                                    </Text>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        </TableItems>

                                        <TableItems Justify="center" Size=".875rem">
                                            {formatterDolar.format(item.endorsementValue)}
                                        </TableItems>

                                        <TableItems Justify="center" Size=".875rem">
                                            {formatterDolar.format(item.dischargeValue)}
                                        </TableItems>

                                        <TableItems Justify="center" Size=".875rem">
                                            {DateTime.fromISO(item.expirationDate).toFormat(
                                                'dd/MM/yyyy'
                                            )}
                                        </TableItems>

                                        <TableItems Justify="center" Size=".875rem">
                                            {item.endorsementNumber}
                                        </TableItems>

                                        <TableItem Justify="end">
                                            {item.nameStatusContract === 'Activo' &&
                                                item.folioContract &&
                                                item.endorsementValue !== 0 &&
                                                (existCar !== false &&
                                                    existCar.find(
                                                        (element) => element === item.pawnId
                                                    )) !== item.pawnId && (
                                                    <>
                                                        {selected === item.pawnId && (
                                                            <ContextualMenuEndeavors
                                                                handleClickAway={handleClickAway}
                                                                contextualMenu={contextualMenu}
                                                                endeavors={item}
                                                                setModal={setModal}
                                                            />
                                                        )}
                                                        <FontAwesomeIcon
                                                            ColorH="#7ebb48"
                                                            onClick={() => {
                                                                setSelected(item.pawnId);
                                                                setContextualMenu(true);
                                                            }}
                                                        >
                                                            <i className="material-icons">
                                                                more_vert
                                                            </i>
                                                        </FontAwesomeIcon>
                                                    </>
                                                )}
                                        </TableItem>
                                        {modalqr && (
                                            <ModalQR
                                                modalqr={modalqr}
                                                setModalqr={setModalqr}
                                                QR={item.urlQR}
                                            />
                                        )}
                                    </TableRowItemsHome>
                                );
                            })
                        ) : (
                            <ContainerFlex Height="20vh" FlexDir="column">
                                <Text FontSize="0.875rem" FontWeight="500">
                                    No se encontraron contratos
                                </Text>
                            </ContainerFlex>
                        ))}
                </TableItemsContainer>
            </Table>
        </ContainerFlex>
    );
}
