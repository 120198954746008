import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

export const PrivacityContainer = () => {
    return (
        <ContainerFlex Height="40px" Padding="10px 0" Align="flex-end">
            <Text
                Cursor="pointer"
                TextDecoration="underline"
                FontSize="1rem"
                FontStyle="italic"
                Padding="0 8px"
                FontWeight="400"
                Color="#4a4a4a"
                HColor="#35cf44"
                onClick={() => {
                    window.location.href = `https://www.multiapoyo.com.mx/pages/legales`;
                }}
            >
                Términos y condiciones de Uso
            </Text>
            <Text FontSize="1rem">y el</Text>
            <Text
                TextDecoration="underline"
                Cursor="pointer"
                FontSize="1rem"
                FontStyle="italic"
                Padding="0 8px"
                FontWeight="400"
                Color="#4a4a4a"
                HColor="#35cf44"
                onClick={() => {
                    window.location.href = `https://www.multiapoyo.com.mx/pages/aviso-de-privacidad`;
                }}
            >
                Aviso de privacidad
            </Text>
        </ContainerFlex>
    );
};
