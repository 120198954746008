import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {ICashInfo} from '@components/Branches/interfaces';
import {SelectedBranches} from '@components/Branches/ManageBranch/BranchCashFlow/SelectedBranches';

export const CashFlowConfigInfo = ({showCashForm, setShowCashForm}: ICashInfo) => {
    const cashFlowInfo = useSelector((state: RootState) => {
        return state.GetCashFlowSetup.cashSetup;
    });
    const {error} = useSelector((state: RootState) => {
        return state.GetCashFlowSetup;
    });
    return (
        <ContainerFlex Justify="flex-start" Align="flex-start" Height="auto" FlexDir="column">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Configuración inicial
                </Text>
                <ContainerFlex
                    Width="auto"
                    Justify="start"
                    Height="80%"
                    onClick={() => {
                        setShowCashForm(!showCashForm);
                    }}
                >
                    <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                        Editar
                    </Text>
                    <ImgLocation
                        src={nextArrow}
                        Width="0.875rem"
                        Height="0.875rem"
                        Margin="0 0 0 4px"
                        Cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px 25px 8px 25px"
                Height="48px"
                Justify="space-between"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                Width="auto"
            >
                <Text FontSize="0.875rem" Color="#414042">
                    Monto máximo autorizado:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 12px"
                        FontStyle={error ? 'italic' : ''}
                    >
                        {error ? 'Añadir monto máximo' : `$ ${cashFlowInfo.amountMaximum}`}
                    </Text>
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px 25px"
                Height="auto"
                Align="flex-start"
                Justify="flex-start"
            >
                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
                    <Text FontSize="0.875rem" Color="#414042">
                        ¿Entrega efectivo a otras sucursales?:
                        <Text FontSize="0.875rem" FontWeight="500" Padding="0 0 0 12px">
                            {cashFlowInfo.cashDelivery ? 'Si' : 'No'}
                        </Text>
                    </Text>
                    {cashFlowInfo.moneyTransfersBranches && cashFlowInfo.cashDelivery && (
                        <SelectedBranches dataBranches={cashFlowInfo.moneyTransfersBranches} />
                    )}
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
                    <Text FontSize="0.875rem" Color="#414042">
                        ¿Recibe efectivo de otras sucursales?:
                        <Text FontSize="0.875rem" FontWeight="500" Padding="0 0 0 12px">
                            {cashFlowInfo.receiveCash ? 'Si' : 'No'}
                        </Text>
                    </Text>
                    {cashFlowInfo.branchesThatSendMoney && cashFlowInfo.receiveCash && (
                        <SelectedBranches dataBranches={cashFlowInfo.branchesThatSendMoney} />
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
