import styled from '@emotion/styled';
import {IPropsContainerDetails, IPropsTextDetails} from '@Pawn/interfacesStyles';
export const ContainerAdminCards = styled.div`
    display: flex;
    padding: 62px 100px;
    justify-content: flex-start;
    div:last-child {
        margin-left: 50px;
    }
    div {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 19px 11px 0 11px;
        background: #f3f3f3 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width: 150px;
        height: 150px;
        h2 {
            text-align: left;
            font: normal normal normal 16px/19px Work Sans;
            letter-spacing: 0px;
            color: #414042;
            opacity: 1;
        }
        span {
            text-align: center;
            font: normal normal normal 10px/11px Work Sans;
            letter-spacing: 0px;
            color: #414042;
            opacity: 1;
        }
    }
`;
export const ContainerInputs = styled.div`
    display: flex;
    align-items: flex-end;
    button {
        margin-left: 2rem;
    }
`;
export const ContainerTableBanner = styled.div`
    width: 100%;
    padding-top: 50px;
    margin: 0 auto;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 41px;
        span {
            text-align: left;
            font: normal normal normal 16px Work Sans;
            letter-spacing: 0px;
            color: #4a4a4a;
            opacity: 1;
        }
        button {
            margin-top: 26.78px;
            border: 1px solid #414042;
            text-transform: uppercase;
            border-radius: 5px;
            width: 160px;
            height: 40px;
            background-color: white;
            box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
            &:hover {
                border-color: #7ac143;
            }
        }
    }
    > table {
        width: 100%;
        tbody tr td {
            padding: 0;
            text-align: left;
        }
        thead {
            background: #f3f3f3 0% 0% no-repeat padding-box;
            border-radius: 5px;
            height: 52px;
            th:first-child {
                padding-left: 14px;
                font: normal normal medium 14px/16px Work Sans;
                letter-spacing: 0px;
                color: #4a4a4a;
            }
        }
    }
`;
export const ContainerDetails = styled.div<IPropsContainerDetails>`
    display: flex;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    flex-direction: ${(props) => props.FlexDir};
    padding: ${(props) => props.Padding};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    border: ${(props) => props.Border};
    margin: ${(props) => props.Margin};
    background: ${(props) => props.Background};
    box-shadow: ${(props) => props.BoxS};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    opacity: 1;
    margin-top: ${(props) => props.MarginT};
    cursor: ${(props) => props.Cursor};
`;
ContainerDetails.defaultProps = {
    FlexDir: 'row',
    Padding: '0',
    Justify: 'flex-start',
    Align: 'flex-start',
    Border: 'none',
    Margin: 'none',
    Width: '100%',
    Height: '100%',
    Background: 'none',
    BoxS: 'none',
    Radius: 'none',
    MarginT: 'none',
    Cursor: 'auto',
};
export const TextDetails = styled.div<IPropsTextDetails>`
    display: flex;
    opacity: 100%;
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    align-items: ${(props) => props.Align};
    font-family: Work Sans;
    font-weight: ${(props) => props.Weight};
    font-size: ${(props) => props.Size};
    text-decoration: ${(props) => props.TextDecoration};
    text-transform: ${(props) => props.TextTransform};
    line-height: 20px;
    color: ${(props) => props.Color};
    padding: ${(props) => props.Padding};
    border-bottom: ${(props) => props.Bb};
    border: ${(props) => props.Border};
    margin: ${(props) => props.Margin};
`;

TextDetails.defaultProps = {
    Justify: 'center',
    Align: 'center',
    FlexDir: 'row',
    Weight: '400',
    Size: '16px',
    Padding: '0',
    Bb: 'none',
    Border: 'none',
    Color: '#4a4a4a',
    TextDecoration: 'none',
    TextTransform: 'none',
    Margin: '0',
};
