import {DateTime} from 'luxon';
import {HashMapInterface} from '@CashFlow/interfaces';

export const today = DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm");

export const WRITE_HERE = 'Escribe aquí';

export const currencyToNumber = (currency: string) => {
    return Number(currency.replace('$', '').replace(' ', '').replace(',', ''));
};
export const currencyFormat = (num: number) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const numberToCurrency = (number: number) => {
    return new Intl.NumberFormat('ES-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 2,
    }).format(number);
};

export const headerTitles = [
    {
        id: 1,
        name: 'Tipo',
        className: 'table-header first-column',
        show: true,
    },
    {
        id: 2,
        name: 'Estado',
        className: 'table-header',
        show: true,
    },
    {
        id: 3,
        name: 'Regional',
        className: 'table-header',
        show: true,
    },
    {
        id: 4,
        name: 'Sucursal',
        className: 'table-header',
        show: true,
    },
    {
        id: 5,
        name: 'Monto Solicitado',
        className: 'table-header',
        show: true,
    },
    {
        id: 6,
        name: 'Monto Final',
        className: 'table-header',
        show: true,
    },
    {
        id: 7,
        name: 'Acciones',
        className: 'table-header grid7-9',
        show: true,
    },
];

export const headerExcedents = [
    {
        name: 'Tipo',
        className: 'table-header first-column',
    },
    {
        name: 'Estado',
        className: 'table-header',
    },
    {
        name: 'Regional',
        className: 'table-header',
    },
    {
        name: 'Sucursal',
        className: 'table-header',
    },
    {
        name: 'Monto Solicitado',
        className: 'table-header grid5-7',
    },
    {
        name: 'Monto Aprobado',
        className: 'table-header grid7-10 last-column',
    },
];

export const handleStatus = (status: string | number, type: 'color' | 'description') => {
    if (type === 'color') {
        const REQUEST_STATUS: HashMapInterface = {
            1: '#E5E7E9',
            2: '#FFF2CE',
            3: '#FFCCCC',
            4: '#D4F6FF',
            5: '#E3F2D7',
        };
        return REQUEST_STATUS[status];
    }
    if (type === 'description') {
        const REQUEST_STATUS: HashMapInterface = {
            1: 'Solicitado',
            2: 'Aprobado',
            3: 'Rechazado',
            4: 'Depositado',
            5: 'Completado',
        };
        return REQUEST_STATUS[status];
    }
};

export const CASHFLOW_STATUS = {
    TRANSFER: 'Transferencia',
    BRANCH: 'Sucursal',
    DEPOSITED: 'Depositado',
    REJECTED: 'Rechazado',
    COMPLETED: 'Completado',
    ROLES: {
        TREASURER: 'Tesorero',
        MANAGER: 'Gerente',
    },
};

export const handleStatusExcedent = (status: number, type: 'color' | 'description') => {
    if (type === 'color') {
        const REQUEST_STATUS: HashMapInterface = {
            1: '#E5E7E9',
            3: '#FFCCCC',
            2: '#E3F2D7',
        };
        return REQUEST_STATUS[status];
    }
    if (type === 'description') {
        const REQUEST_STATUS: HashMapInterface = {
            1: 'Enviado',
            3: 'Invalido',
            2: 'Completado',
        };
        return REQUEST_STATUS[status];
    }
};

export const currencyToText = (function () {
    function Unidades(num: number) {
        const NUM_UNIT: HashMapInterface = {
            1: 'UN',
            2: 'DOS',
            3: 'TRES',
            4: 'CUATRO',
            5: 'CINCO',
            6: 'SEIS',
            7: 'SIETE',
            8: 'OCHO',
            9: 'NUEVE',
        };
        return NUM_UNIT[num] || '';
    } //Unidades()

    function Decenas(num: number) {
        const decena = Math.floor(num / 10);
        const unidad = num - decena * 10;
        const NUM_UNIT_DEC: HashMapInterface = {
            0: 'DIEZ',
            1: 'ONCE',
            2: 'DOCE',
            3: 'TRECE',
            4: 'CATORCE',
            5: 'QUINCE',
        };
        const NUM_UNIT_VEINTES: HashMapInterface = {
            0: 'VEINTE',
        };
        const NUM_DEC: HashMapInterface = {
            1: NUM_UNIT_DEC[unidad] || `DIECI${Unidades(unidad)}`,
            2: NUM_UNIT_VEINTES[unidad] || `VEINTI${Unidades(unidad)}`,
            3: DecenasY('TREINTA', unidad),
            4: DecenasY('CUARENTA', unidad),
            5: DecenasY('CINCUENTA', unidad),
            6: DecenasY('SESENTA', unidad),
            7: DecenasY('SETENTA', unidad),
            8: DecenasY('OCHENTA', unidad),
            9: DecenasY('NOVENTA', unidad),
            0: Unidades(unidad),
        };
        return NUM_DEC[decena];
    } //Unidades()

    function DecenasY(strSin: string, numUnidades: number) {
        if (numUnidades > 0) return `${strSin} Y ${Unidades(numUnidades)}`;

        return strSin;
    } //DecenasY()

    function Centenas(num: number) {
        const centenas = Math.floor(num / 100);
        const decenas = num - centenas * 100;

        const NUM_CENTENAS: HashMapInterface = {
            1: decenas > 0 ? `CIENTO ${Decenas(decenas)}` : 'CIEN',
            2: `DOSCIENTOS ${Decenas(decenas)}`,
            3: `TRESCIENTOS ${Decenas(decenas)}`,
            4: `CUATROCIENTOS ${Decenas(decenas)}`,
            5: `QUINIENTOS ${Decenas(decenas)}`,
            6: `SEISCIENTOS ${Decenas(decenas)}`,
            7: `SETECIENTOS ${Decenas(decenas)}`,
            8: `OCHOCIENTOS ${Decenas(decenas)}`,
            9: `NOVECIENTOS ${Decenas(decenas)}`,
        };
        return NUM_CENTENAS[centenas] || Decenas(decenas);
    } //Centenas()

    function Seccion(num: number, divisor: number, strSingular: string, strPlural: string) {
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        let letras = '';

        if (cientos > 0)
            if (cientos > 1) {
                letras = `${Centenas(cientos)} ${strPlural}`;
            } else {
                letras = strSingular;
            }

        if (resto > 0) letras += '';

        return letras;
    } //Seccion()

    function Miles(num: number) {
        const divisor = 1000;
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        const strMiles = Seccion(num, divisor, ' MIL', 'MIL');
        const strCentenas = Centenas(resto);

        if (strMiles === '') return strCentenas;

        return `${strMiles} ${strCentenas}`;
    } //Miles()

    function Millones(num: number) {
        const divisor = 1000000;
        const cientos = Math.floor(num / divisor);
        const resto = num - cientos * divisor;

        const strMillones = Seccion(num, divisor, 'UN MILLON', 'MILLONES');
        const strMiles = Miles(resto);

        if (strMillones === '') return strMiles;

        return `${strMillones} ${strMiles}`;
    } //Millones()

    return function currencyToText(num: number) {
        const data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: Math.round(num * 100) - Math.floor(num) * 100,
            letrasCentavos: '',
            letrasMonedaPlural: 'PESOS',
            letrasMonedaSingular: 'PESO',
            letrasMonedaCentavoPlural: 'CENTAVOS',
            letrasMonedaCentavoSingular: 'CENTAVO',
        };

        if (data.centavos > 0) {
            data.letrasCentavos =
                'CON ' +
                (function () {
                    if (data.centavos === 1)
                        return `${Millones(data.centavos)} ${data.letrasMonedaCentavoSingular}`;
                    else return `${Millones(data.centavos)} ${data.letrasMonedaCentavoPlural}`;
                })();
        }

        if (data.enteros === 0) return `CERO ${data.letrasMonedaPlural} ${data.letrasCentavos}`;
        if (data.enteros === 1)
            return `${Millones(data.enteros)} ${data.letrasMonedaSingular} ${data.letrasCentavos}`;
        else return `${Millones(data.enteros)} ${data.letrasMonedaPlural} ${data.letrasCentavos}`;
    };
})();

export const months = [
    {monthId: 1, month: 'Enero'},
    {monthId: 2, month: 'Febrero'},
    {monthId: 3, month: 'Marzo'},
    {monthId: 4, month: 'Abril'},
    {monthId: 5, month: 'Mayo'},
    {monthId: 6, month: 'Junio'},
    {monthId: 7, month: 'Julio'},
    {monthId: 8, month: 'Agosto'},
    {monthId: 9, month: 'Septiembre'},
    {monthId: 10, month: 'Octubre'},
    {monthId: 11, month: 'Noviembre'},
    {monthId: 12, month: 'Diciembre'},
];

export const timeFilter = [
    {timeId: '7d', time: 'Últimos 7 días'},
    {timeId: '3m', time: 'Últimos 3 meses'},
    {timeId: '1y', time: 'Último año'},
];

export const statusRequestMap = [
    {statusId: '1', position: 1, status: 'Solicitado'},
    {statusId: '2', position: 2, status: 'Aprobado'},
    {statusId: '3', position: 3, status: 'Rechazado'},
    {statusId: '4', position: 4, status: 'Depositado'},
    {statusId: '5', position: 5, status: 'Completado'},
];

export const statusDateMap = [
    {statusId: '3d', status: 'Últimos tres días'},
    {statusId: '7d', status: 'Últimos 7 días'},
    {statusId: '1y', status: 'Último año'},
];

export const statusExcedent = [
    {statusId: '1', status: 'Enviado'},
    {statusId: '2', status: 'Completado'},
    {statusId: '3', status: 'Invalido'},
];

export const typeUsers = {employe: 'Employee', admon: 'Aministrator'};

export const menssageFunding = '¡Se ha enviado el comprobante de deposito con exito!';
export const breakClassName = 'break-me';
export const containerPag = 'pagination';
export const activeClassName = 'active';
export const previousLabel = '<';
export const nextLabel = '>';
export const breakLabel = '...';
export const marginDisplay = 1;
export const pageRange = 4;

export const messageRequest = 'Se ha aprobado la solicitud';
export const messageRequestReject = 'Se ha rechazado la solicitud';

export const typeMovement = [
    {label: 'Empeño', value: 1},
    {label: 'Refrendo', value: 2},
    {label: 'Venta', value: 3},
    {label: 'Apartado', value: 4},
    {label: 'Desempeño', value: 5},
    {label: 'Cancelación de Empeño', value: 6},
    {label: 'Cancelación de Refrendo', value: 7},
    {label: 'Devolución Compra', value: 8},
    {label: 'Cancelación de Apartado', value: 9},
    {label: 'Cancelación de Desempeño', value: 10},
    {label: 'Gasto', value: 11},
    {label: 'Pase al moneda', value: 12},
    {label: 'Retencion auditoria', value: 13},
    {label: 'En transito', value: 14},
    {label: 'Fundida', value: 15},
    {label: 'Reposición', value: 16},
    {label: 'Consignación al Ministério Publico', value: 17},
    {label: 'Asignación a capacitación', value: 18},
    {label: 'Extraviada', value: 19},
    {label: 'Baja por Siniestro', value: 20},
    {label: 'Baja por Inservible', value: 21},
    {label: 'Rifa Interna', value: 22},
    {label: 'Venta Interna', value: 23},
];

export const hoursOfDay = [
    {label: '1:00 AM', value: 1},
    {label: '2:00 AM', value: 2},
    {label: '3:00 AM', value: 3},
    {label: '4:00 AM', value: 4},
    {label: '5:00 AM', value: 5},
    {label: '6:00 AM', value: 6},
    {label: '7:00 AM', value: 7},
    {label: '8:00 AM', value: 8},
    {label: '9:00 AM', value: 9},
    {label: '10:00 AM', value: 10},
    {label: '11:00 AM', value: 11},
    {label: '12:00 PM', value: 12},
    {label: '1:00 PM', value: 13},
    {label: '2:00 PM', value: 14},
    {label: '3:00 PM', value: 15},
    {label: '4:00 PM', value: 16},
    {label: '5:00 PM', value: 17},
    {label: '6:00 PM', value: 18},
    {label: '7:00 PM', value: 19},
    {label: '8:00 PM', value: 20},
    {label: '9:00 PM', value: 21},
    {label: '10:00 PM', value: 22},
    {label: '11:00 PM', value: 23},
    {label: '12:00 AM', value: 24},
];

export const defaultFinanceCardsData = {
    cashNetIncome: 0,
    cashTotalBox: 0,
    cashTotalBranch: 0,
    cashTotalExpenses: 0,
    cashTotalIncome: 0,
    detailsTotalBranch: {
        fundsReserve: 0,
        totalBoxes: 0,
        totalBranch: 0,
    },
    detailsCashTotalBox: {
        totalBoxes: 0,
        usersCashBox: [],
    },
    detailsCashTotalIncome: {
        bankTransfer: 0,
        cash: 0,
        creditCard: 0,
        debitCard: 0,
        digitalWallet: 0,
    },
    detailsCashTotalExpenses: {
        bankTransfer: 0,
        cash: 0,
        digitalWallet: 0,
    },
    detailsCashNetIncome: {
        totalExpenses: 0,
        totalIncome: 0,
        totalNetIncome: 0,
    },
};
