export const ENDEAVORS = {
    CLIENT: 'CLIENT',
    CLIENT_SUCCESS: 'CLIENT_SUCCESS',
    CLIENT_ERROR: 'CLIENT_ERROR',
    CLIENT_BRANCH_SUCCESS: 'CLIENT_BRANCH_SUCCESS',
    CLIENT_BRANCH_ERROR: 'CLIENT_BRANCH_ERROR',
    NUMBER_BRANCH: 'NUMBER_BRANCH',
    ACTUAL_PAGE: 'ACTUAL_PAGE',
    FOLIO: 'FOLIO',
    RESTART: 'RESTART',
    CAR: 'CAR',
    CAR_SUCCESS: 'CAR_SUCCESS',
    CAR_ERROR: 'CAR_ERROR',
};
