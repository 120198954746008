import React from 'react';

import {SubContainerPedidos} from '@EcommerceShopify/styles';
import {Text} from '@EcommerceShopify/styles';
interface NewType {
    name: string;
}
export const SubContainer = (props: NewType): JSX.Element => {
    const {name} = props;
    return (
        <SubContainerPedidos>
            <Text FontSize="18px" FontStyle="normal" FontWeight="400">
                {name}
            </Text>
        </SubContainerPedidos>
    );
};
