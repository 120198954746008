import {useForm} from 'react-hook-form';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {NEW_LOAN_PRODUCT} from '@components/PersonalLoans/NewLoanProduct/constants';
import React, {useEffect, useRef, useState} from 'react';
import {LoanProductDetails} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/LoanProductDetails';
import {NavbarLoans} from '@components/PersonalLoans/NewLoanProduct/NavbarLoans';
import {PaymentFrequency} from './PaymentFrequency/PaymentFrequency';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {INewLoanProductSubmit} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {schemaNewLoanProduct} from '@components/PersonalLoans/NewLoanProduct/schemaNewLoanProduct';
import {yupResolver} from '@hookform/resolvers/yup';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {PaymentMethods} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/PaymentMethods';
import {ChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ChargesAndPenalties';
import {DateTime} from 'luxon';
import {DocumentsClients} from '@components/PersonalLoans/NewLoanProduct/DocumentsClients';
import {Disbursement} from '@components/PersonalLoans/NewLoanProduct/Disbursement';
import {
    getDocumentsPredefinedOptions,
    getDisbursementOptions,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const NewLoanProduct = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        watch,
        setValue,
        trigger,
        resetField,
    } = useForm<INewLoanProductSubmit>({
        resolver: yupResolver(schemaNewLoanProduct),
        defaultValues: {
            termName: '',
            description: '',
            requestChannel: [],
            periodMax: {value: 0, label: ''},
            periodMin: {value: 0, label: ''},
            countLimitLoans: 0,
            dateDueInit: DateTime.now(),
            dateDueEnd: null,
            frequencyTerm: {
                weekly: false,
                fortnightly: false,
                monthly: false,
                bimonthly: false,
                quarterly: false,
                semester: false,
            },
            typePaymentsIns: {
                bankTransfer: false,
                debitCard: false,
                creditCard: false,
                cashPayment: false,
                ataskateWeb: false,
            },
        },
    });
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const refs: {[key: string]: React.MutableRefObject<HTMLDivElement | null>} = {
        detailsRef: useRef<HTMLDivElement | null>(null),
        frequencyRef: useRef<HTMLDivElement | null>(null),
        clientAndDocumentsRef: useRef<HTMLDivElement | null>(null),
        disbursementsRef: useRef<HTMLDivElement | null>(null),
        paymentMethodRef: useRef<HTMLDivElement | null>(null),
        chargesRef: useRef<HTMLDivElement | null>(null),
    };
    const itemsBreadcrumb = [
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_1, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_1},
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_2, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_2},
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_3, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_3},
    ];

    useEffect(() => {
        if (!token) return;
        dispatch(getDocumentsPredefinedOptions(token));
        dispatch(getDisbursementOptions(token));
    }, [dispatch, token]);

    const onSubmit = (data: INewLoanProductSubmit) => {};
    return (
        <>
            <ContainerFlex
                Padding="1.5rem"
                Align="start"
                Justify="start"
                FlexDir="column"
                Gap="1.5rem"
                OverFlow="scroll"
                ref={containerRef}
                Position="relative"
            >
                <ContainerFlex Gap="1rem" FlexDir="column" Align="start" Height="max-content">
                    <Breadcrumb items={itemsBreadcrumb} />
                    <Text FontSize="1.75rem" FontWeight="700" Color="#1D1E20">
                        {NEW_LOAN_PRODUCT.TITLE}
                    </Text>
                </ContainerFlex>

                <NavbarLoans containerRef={containerRef} refs={refs} />

                <ContainerForm
                    Align="start"
                    Justify="start"
                    FlexDir="column"
                    Gap="1.5rem"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <LoanProductDetails
                        detailsRef={refs.detailsRef}
                        register={register}
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        resetField={resetField}
                        isSubmitted={isSubmitted}
                    />
                    <PaymentFrequency
                        frequencyRef={refs.frequencyRef}
                        watch={watch}
                        errors={errors}
                        control={control}
                        isSubmitted={isSubmitted}
                        trigger={trigger}
                    />
                    <DocumentsClients />
                    <Disbursement />
                    <PaymentMethods
                        paymentMethodRef={refs.paymentMethodRef}
                        control={control}
                        errors={errors}
                        isSubmitted={isSubmitted}
                        trigger={trigger}
                    />
                    <ChargesAndPenalties chargesRef={refs.chargesRef} />
                    <ContainerFlex
                        Radius="1rem"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Padding="2.5rem"
                        Gap="1rem"
                    >
                        <ButtonGenerals SecondaryButton="#FFFFFF" Width="147px" type="button">
                            {NEW_LOAN_PRODUCT.BTN_CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals type="submit" onClick={() => setIsSubmitted(true)}>
                            {NEW_LOAN_PRODUCT.BTN_CREATE_PRODUCT}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
        </>
    );
};
