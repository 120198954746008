import * as yup from 'yup';
import {RENEWAL_ERRORS} from '@components/ShoppingCart/constants';

const isFutureDate = (date: Date) => {
    const now = new Date();
    return date > now;
};

export const schemaExtend = yup.object({
    paymentDate: yup
        .date()
        .required(RENEWAL_ERRORS.REQUIRED_PAYMENT_DATE)
        .test('is-future', RENEWAL_ERRORS.FUTURE_DATE, (value) => {
            if (!value) return false;
            return isFutureDate(new Date(value));
        }),
});
