import React from 'react';

import {Controller} from 'react-hook-form';

import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS, INPUT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';

const AdditionalInformationContact = (props: IClientProofStep) => {
    const {register, errors, control} = props;

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>
                    {CREATE_CLIENT_LABELS.SEGUNDO_CORREO_ELECTRONICO}
                    <Text MarginLeft="0.25rem" FontWeight="500" FontSize="0.75rem" Color="#A1A5AA">
                        {INPUT_LABELS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name="secondEmail"
                    control={control}
                    render={() => (
                        <Input {...register('secondEmail')} error={!!errors.secondEmail?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.secondEmail?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>
                    {CREATE_CLIENT_LABELS.TELEFONO_FIJO}{' '}
                    <Text MarginLeft="0.25rem" FontWeight="500" FontSize="0.75rem" Color="#A1A5AA">
                        {INPUT_LABELS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name="landline"
                    control={control}
                    render={() => (
                        <Input {...register('landline')} error={!!errors.landline?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.landline?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>
                    {CREATE_CLIENT_LABELS.TELEFONO_CELULAR}{' '}
                    <Text MarginLeft="0.25rem" FontWeight="500" FontSize="0.75rem" Color="#A1A5AA">
                        {INPUT_LABELS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name="cellPhone"
                    control={control}
                    render={() => (
                        <Input {...register('cellPhone')} error={!!errors.cellPhone?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.cellPhone?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>
                    {CREATE_CLIENT_LABELS.OTRO_TELEFONO}{' '}
                    <Text MarginLeft="0.25rem" FontWeight="500" FontSize="0.75rem" Color="#A1A5AA">
                        {INPUT_LABELS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name="anotherPhone"
                    control={control}
                    render={() => (
                        <Input
                            {...register('anotherPhone')}
                            error={!!errors.anotherPhone?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.anotherPhone?.message as string}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AdditionalInformationContact;
