import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import arrowIcon from '@images/arrowDownIcon.svg';
import LocationsList from '@components/LocationsBranch//LocationsList';
import {LOCATIONGROUP} from '@components/LocationsBranch/constants';
import plusIcon from '@components/Branches/Images/PlusIcon.svg';
import {SearchLocations} from '@components/LocationsBranch/SerachGroups/SearchLocations';
import {SelectLocations} from '@components/LocationsBranch/SelectLocations';

export const LocationBranch = () => {
    const [showGroup, setShowGroup] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState<[number, number]>([0, 0]);
    const [showLocationsModal, setShowLocationsModal] = useState(false);
    const [typeFilter, setTypeFilter] = useState(1);

    const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation.userData?.enterpriceDetails?.[0]?.enterpriceName as string
    );
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1rem"
            Padding="1rem"
            FlexBasis="min-content"
        >
            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                {LOCATIONGROUP.TITLE}
            </Text>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                Gap="0.5rem"
                Padding="1rem"
                backG="#FAFAFA"
                Border="solid 1px #F4F5F5"
                Radius="1rem"
            >
                <SearchLocations
                    setSelectedGroup={setSelectedGroup}
                    setTypeFilter={setTypeFilter}
                />
                {showGroup && (
                    <ContainerFlex Align="flex-end" Gap="0.5rem" Flex="1 0 0" Self="stretch">
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            FlexDir="column"
                            backG="#FFFFFF"
                            Padding="0.5rem"
                            Gap="0.5rem"
                        >
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500">
                                {companyName}
                            </Text>
                            <ContainerFlex
                                Justify="start"
                                Gap="0.5rem"
                                Height="2.5rem"
                                backG="#FFFFFF"
                                Padding="0.5rem"
                                Cursor="pointer"
                                Width="10rem"
                                onClick={() => setShowLocationsModal(true)}
                            >
                                <Image src={plusIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                                <Text
                                    Color="#5A5AFF"
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Cursor="pointer"
                                >
                                    {LOCATIONGROUP.ADDGROUP}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
                <ContainerFlex Align="start" Height="1.5rem">
                    <Text
                        Color="#5A5AFF"
                        FontWeight="700"
                        Cursor="pointer"
                        onClick={() => setShowGroup(!showGroup)}
                    >
                        {showGroup ? LOCATIONGROUP.HIDDENGROUP : LOCATIONGROUP.SHOWGROUP}
                    </Text>
                    <Image
                        src={arrowIcon}
                        alt="type"
                        Width="1.5rem"
                        Height="1.5rem"
                        Cursor="pointer"
                        Transform={showGroup ? 'rotate(180deg)' : 'rotate(0deg)'}
                        onClick={() => setShowGroup(!showGroup)}
                    />
                </ContainerFlex>
                <LocationsList selectedGroup={selectedGroup} typeFilter={typeFilter} />
            </ContainerFlex>
            {showLocationsModal && (
                <SelectLocations
                    showLocationsModal={showLocationsModal}
                    setShowLocationsModal={setShowLocationsModal}
                />
            )}
        </ContainerFlex>
    );
};
