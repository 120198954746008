import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {
    itemsModulesAdmistrator,
    itemsModulesEmployee,
} from '@/components/SideBarMenu/Redux/modulesAdministratorActions';
import {IModulesAdministrator} from '@components/SideBarMenu/interfaces';
import {MENUADMINSETTINGS} from '@components/SideBarMenu/constantsText';
import {useNavigate} from 'react-router-dom';

export const MenuAdminSettings = () => {
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const userType = useSelector((state: RootState) => state.getUsersValidation.userData?.userType);
    const dispatch = useDispatch();
    useEffect(() => {
        if (token) {
            const action =
                userType === 'Administrator' ? itemsModulesAdmistrator : itemsModulesEmployee;
            dispatch(action(token));
        }
    }, [token, userType, dispatch]);

    const settings: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );
    const navigate = useNavigate();

    const settingsItems = settings?.find((items) => items.title === 'Configuraciones');

    return (
        <ContainerFlex
            Width="13rem"
            Height="68rem"
            backG="#F0F0FF"
            Padding="1rem 0rem 1rem 0.5rem"
            FlexDir="column"
            Align="flex-start"
            Justify="flex-start"
        >
            <Text Color="#54575C" FontSize="0.875rem" FontWeight="500" PaddingB="1rem">
                {MENUADMINSETTINGS.SETTINGS}
            </Text>
            <ContainerFlex FlexDir="column" Justify="flex-start">
                {settingsItems?.item.map((items) => (
                    <ContainerFlex
                        key={items.title}
                        FlexDir="column"
                        Height="fit-content"
                        Bb={items.cssNextLine ? '1px solid #E8E9EA' : ''}
                        PaddingB={items.cssNextLine ? '1rem' : ''}
                        MarginBt={items.cssNextLine ? '1rem' : ''}
                    >
                        <ContainerFlex
                            Justify="start"
                            Padding="0.5rem"
                            Gap="0.5rem"
                            Cursor="pointer"
                        >
                            {items.cssIcon !== '#' && items.cssIcon !== '/' ? (
                                <Image alt="item-icon" src={items.cssIcon} />
                            ) : (
                                <Text Width="20px" Height="20px" />
                            )}
                            <Text
                                Color="#54575C"
                                FontSize="1rem"
                                Cursor="pointer"
                                onClick={() => {
                                    navigate(`${items.route}`);
                                }}
                            >
                                {items.title}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};
