import {
    GET_EXPENSE_DETAILS,
    GET_EXPENSE_DETAILS_SUCCESS,
    GET_EXPENSE_DETAILS_ERROR,
} from '@components/CashFlowExpenseDetails/operational/constants';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {getTransactionError} from '@components/CashFlow/Redux/Actions/GetCashTransaction';

export const getExpenseDetails = () => {
    return {
        type: GET_EXPENSE_DETAILS,
    };
};
export const getExpenseDetailsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_EXPENSE_DETAILS_SUCCESS,
        payload: result,
    };
};
export const getExpenseDetailsError = (error: AxiosError) => {
    return {
        type: GET_EXPENSE_DETAILS_ERROR,
        error: error,
    };
};

export function getExpenseDetailssAction(id: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getExpenseDetails());
        const params = {
            id: id.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetExpenseDetails}?${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getExpenseDetailsSuccess(response));
        } catch (error) {
            dispatch(getTransactionError(error as AxiosError));
        }
    };
}
