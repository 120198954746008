import React from 'react';

import {ContainerFlex} from '@EcommerceShopify/styles';
import {clientCard} from '@/components/Shopify/ListClient/redux/interfaces';
import {OptionMenu} from '@Shopify/OptionMenu/OptionMenu';

export function DetailClient(props: {AllClientsHome: clientCard}) {
    return (
        <ContainerFlex Justify="start">
            <ContainerFlex FlexDir="column" Width="20%">
                <OptionMenu />
            </ContainerFlex>
        </ContainerFlex>
    );
}
