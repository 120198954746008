import {GET_ADDRESS} from '@components/LocationsBranch/Redux/types';
import {IAddressData} from '@components/LocationsBranch/interfaces';

export const addAddress = (address: IAddressData) => ({
    type: GET_ADDRESS.ADD,
    address,
});

export const resetAddress = () => ({
    type: GET_ADDRESS.RESET,
});

export const setAddressError = () => ({
    type: GET_ADDRESS.ERROR,
});
