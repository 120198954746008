import * as yup from 'yup';

export const schemaNewFeature = yup.object().shape({
    featureName: yup.string().required('Campo obligatorio'),
    catArticleType: yup.object().shape({
        label: yup.string(),
        value: yup.string().required('Campo obligatorio'),
        icon: yup.string(),
    }),
    mandatoryFeature: yup.boolean(),
    printEtiquette: yup.boolean(),
    details: yup.array().when(['catArticleType.value'], {
        is: (val: string) => val === 'Selección',
        then: (schema) =>
            schema.of(
                yup.object().shape({
                    valueDescription: yup.string().required('Campo requerido'),
                })
            ),
        otherwise: (schema) => schema.notRequired(),
    }),
});
