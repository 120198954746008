import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {MY_PLAN_BUSINESS_LINES} from '@components/MyPlan/Redux/types';

export const businessLinesStart = () => ({
    type: MY_PLAN_BUSINESS_LINES.START_BUSINESS_LINE,
});

export const businessLinesSuccess = (result: AxiosResponse) => ({
    type: MY_PLAN_BUSINESS_LINES.SUCCESS_BUSINESS_LINE,
    businessLine: result.data,
});

export const businessLinesError = (err: AxiosError) => ({
    type: MY_PLAN_BUSINESS_LINES.ERROR_BUSINESS_LINE,
    error: err,
});

export const businessLines = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(businessLinesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInformationOnCompanyBusinessLines}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(businessLinesSuccess(response.data));
        } catch (error) {
            dispatch(businessLinesError(error as AxiosError));
        }
    };
};
