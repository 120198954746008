import React from 'react';
import {Svg} from '@Shopify/Ecommerce/styles';

export const Web = () => {
    return (
        <Svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            hoverF="#35CF44"
        >
            <mask id="criipvzeva" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <path fill="#D9D9D9" d="M.5 0h24v24H.5z" />
            </mask>
            <path
                d="M9.5 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8.5 21c0-.283.096-.52.288-.712A.965.965 0 0 1 9.5 20h1v-2h-6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2.5 16V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4.5 3h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20.5 18h-6v2h1c.283 0 .521.096.713.288A.967.967 0 0 1 16.5 21c0 .283-.096.52-.287.712A.968.968 0 0 1 15.5 22h-6zm-5-6h16V5h-16v11z"
                fill="#6D6E71"
            />
        </Svg>
    );
};
