import React from 'react';
import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, YAxis, Legend} from 'recharts';
import {incomeData} from '@/components/AdminGeneral/data';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/LoginClient/styles';
import moreVert from '@/images/more_vert.svg';
import {BAR_CHART_INCOME} from '@/components/AdminGeneral/constants';

const BarChartNetIncome = () => {
    const lastIndex = incomeData.length - 1;
    const slicedIncome = incomeData.slice(lastIndex - 6, lastIndex);

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex JustifyItems="flex-start" Height="6rem">
                <ContainerFlex FlexDir="column" Align="start" MarginBt="10px">
                    <Text FontSize="1.5rem" FontWeight="700">
                        {BAR_CHART_INCOME.net}
                    </Text>
                    <Text Color="#71767D">{BAR_CHART_INCOME.month}</Text>
                </ContainerFlex>
                <ImageLogo src={moreVert} Height="30px" Width="30px" />
            </ContainerFlex>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    width={100}
                    height={50}
                    data={slicedIncome}
                    layout="vertical"
                    barGap={3}
                    margin={{bottom: 15}}
                >
                    <Tooltip cursor={{fill: 'transparent'}} />
                    <YAxis dataKey="month" type="category" tickLine={false} axisLine={false} />
                    <XAxis dataKey="income" type="number" tickLine={false} axisLine={false} />
                    <Bar dataKey="income" fill="#5A5AFF" barSize={15} layout="horizontal" />
                    <Bar dataKey="income" fill="#ACACFF" barSize={15} layout="horizontal" />
                    <Legend
                        align="left"
                        iconType="circle"
                        payload={[
                            {value: 'Ultimo año', type: 'circle', color: '#5A5AFF'},
                            {value: 'Año anterior', type: 'circle', color: '#ACACFF'},
                        ]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </ContainerFlex>
    );
};

export default BarChartNetIncome;
