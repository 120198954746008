import React, {useCallback, useEffect, useState} from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {LevelItem} from '@components/Branches/CreateBranchModal/TreeCreateBranches/LevelItem';
import {ITreeCreateBranches} from '@components/Branches/interfaces';
import {Level} from '@components/Branches/interfaces';

export const TreeCreateBranches = ({
    listLevels,
    isAllOpen,
    levelSelected,
    watchLevel,
    trigger,
}: ITreeCreateBranches) => {
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const selectIdLevel = (id: number) => {
        if (findId(id)) {
            const idFilters = selectedIds.filter((idLevel: number) => idLevel !== id);
            setSelectedIds(idFilters);
            return;
        }
        setSelectedIds([...selectedIds, id]);
    };
    const findId = (id: number) => {
        return selectedIds.some((idLevel: number) => idLevel === id);
    };
    const extractIdLevels = useCallback((levels: Level[]) => {
        return levels.flatMap((level) => {
            const idLevels = [level.idLevel];
            if (level.nextLevel && level.nextLevel.length > 0) {
                idLevels.push(...extractIdLevels(level.nextLevel));
            }
            return idLevels;
        });
    }, []);
    useEffect(() => {
        if (!isAllOpen) {
            return;
        }
        setSelectedIds(extractIdLevels(listLevels));
    }, [listLevels]);

    const renderTreeItems = (itemsLevels: Level[]) => {
        return itemsLevels?.map((level) => {
            return (
                <ContainerFlex
                    Height="auto"
                    Justify="start"
                    FlexDir="column"
                    Padding="0px 12px"
                    key={level.idLevel}
                >
                    <LevelItem
                        item={level}
                        selectedIds={selectedIds}
                        onSelect={selectIdLevel}
                        levelSelected={levelSelected}
                        watchLevel={watchLevel}
                        trigger={trigger}
                    />
                    {findId(level.idLevel) && renderTreeItems(level.nextLevel)}
                </ContainerFlex>
            );
        });
    };
    return <>{renderTreeItems(listLevels)}</>;
};
