import {
    GET_SECURITYBAG_WEIGHT,
    GET_SECURITYBAG_WEIGHT_SUCCESS,
    GET_SECURITYBAG_WEIGHT_ERROR,
    GET_SECURITYBAG_SMELTING,
    GET_SECURITYBAG_SMELTING_SUCCESS,
    GET_SECURITYBAG_SMELTING_ERROR,
    GET_SECURITYBAG_WEIGHT_EDIT,
    GET_SECURITYBAG_WEIGHT_EDIT_ERROR,
    GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
    GET_IDENTIFY_FOUNDRY,
    GET_IDENTIFY_FOUNDRY_SUCCESS,
    GET_IDENTIFY_FOUNDRY_ERROR,
    DELETE_SECURITYBAG_WEIGHT,
    DELETE_SECURITYBAG_WEIGHT_SUCCESS,
    DELETE_SECURITYBAG_WEIGHT_ERROR,
    POST_SECURITYBAG_WEIGHT_EDIT,
    POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
    POST_SECURITYBAG_WEIGHT_EDIT_ERROR,
} from '@TypesSecurityBagWeight/SecurityBagWeightTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
import StringUtils from '@GenericScripts/utils';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {ISecurity, ISecurityBag} from '@/components/Foundry/interfaces';

export const fetchGetSecurityBagWeight = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT,
    };
};
export const fetchGetSecurityBagWeightSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_SECURITYBAG_WEIGHT_SUCCESS,
        value: result.data,
        count: result.count,
        pageIndex: result.pageIndex,
    };
};

export const fetchGetSecurityBagWeightError = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_ERROR,
    };
};
export function getSecurityBagWeight(filter = '', pageSize = 10, pageIndex = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filter === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                value: filter,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetSecurityBagWeight());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecurityBagTransit}${queryString}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagWeightSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagWeightError());
        }
    };
}

export const fetchGetSecurityBagSmelting = () => {
    return {
        type: GET_SECURITYBAG_SMELTING,
    };
};
export const fetchGetSecurityBagSmeltingSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_SECURITYBAG_SMELTING_SUCCESS,
        value: result.data,
        count: result.count,
        page: result.pageIndex,
    };
};

export const fetchGetSecurityBagSmeltingError = () => {
    return {
        type: GET_SECURITYBAG_SMELTING_ERROR,
    };
};
export function getSecurityBagWeightReview(
    filter = '',
    pageSize = 10,
    pageIndex = 0,
    id: string,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filter === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                value: filter,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetSecurityBagSmelting());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.SecurityBagWightReview.replace('{id}', id)}${queryString}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagSmeltingSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagSmeltingError());
        }
    };
}

export const fetchGetSecurityBagWeightEdit = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_EDIT,
    };
};
export const fetchGetSecurityBagWeightEditSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
        value: result,
    };
};

export const fetchGetSecurityBagWeightEditError = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_EDIT_ERROR,
    };
};
export function getSecurityBagWeightEdit(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagWeightEdit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecurityBagWeight.replace(
                    '{nosecuritybag}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagWeightEditSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagWeightEditError());
        }
    };
}

export const fetchGetIdentifyFoundry = () => {
    return {
        type: GET_IDENTIFY_FOUNDRY,
    };
};
export const fetchGetIdentifyFoundrySuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_IDENTIFY_FOUNDRY_SUCCESS,
        value: result,
    };
};

export const fetchGetIdentifyFoundryError = () => {
    return {
        type: GET_IDENTIFY_FOUNDRY_ERROR,
    };
};
export function getIdentifyFoundry(fecha: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetIdentifyFoundry());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.IdentifyFoundry.replace(
                    '{fecha}',
                    fecha
                )}`,
                {headers}
            );
            dispatch(fetchGetIdentifyFoundrySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetIdentifyFoundryError());
        }
    };
}

export const fetchDeleteSecurityBagWeight = () => {
    return {
        type: DELETE_SECURITYBAG_WEIGHT,
    };
};
export const fetchDeleteSecurityBagWeightSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: DELETE_SECURITYBAG_WEIGHT_SUCCESS,
        value: result,
    };
};

export const fetchDeleteSecurityBagWeightError = () => {
    return {
        type: DELETE_SECURITYBAG_WEIGHT_ERROR,
    };
};
export function deleteSecurityBagWeightError(data: ISecurity, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeleteSecurityBagWeight());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecurityBagWeightDelete}`,
                {data: data, headers}
            );
            dispatch(fetchDeleteSecurityBagWeightSuccess(response.data));
        } catch (error) {
            dispatch(fetchDeleteSecurityBagWeightError());
        }
    };
}

export const fetchPostSecurityBagWeight = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_EDIT,
    };
};
export const fetchPostSecurityBagWeightSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
        value: result,
    };
};

export const fetchPostSecurityBagWeightError = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_EDIT_ERROR,
    };
};
export function postSecurityBagWeight(data: ISecurityBag, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostSecurityBagWeight());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.postSecurityBagWeight}`,
                data,
                {headers}
            );
            dispatch(fetchPostSecurityBagWeightSuccess(response.data));
            Swal.fire({
                icon: 'success',
                title: ' Guardar peso de seguribolsa',
                text: '¡La seguribolsa ha sido guardada con éxito!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'success',
                title: ' Guardar peso de seguribolsa',
                text: '¡La seguribolsa ha sido guardada con éxito!',
            });
            dispatch(fetchPostSecurityBagWeightError());
        }
    };
}
