import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Numbers} from '@Articles/style';
import {IconContainer} from '@/components/Quoter/styles';
import InformationContainer from '@/components/CreateBranch/BranchInformation/InformationContainer';
import {deadlinesID, titleDeadline} from '@CreateDeadline/interfaces';
import {setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const TitleDeadline = (props: titleDeadline) => {
    const {title, step, stepCurrent, stepDependence, openStep, dependence} = props;
    const deadlineById: deadlinesID = useSelector((state: RootState) => {
        return state.getDeadlinedAll;
    });
    const dispatch: AppDispatch = useDispatch();

    return (
        <ContainerFlex
            Justify="start"
            Cursor="pointer"
            Padding="1rem 1.5rem"
            Bb="1px solid #E4E7E9"
            disabled={step === stepDependence}
            onClick={() => {
                if (step < stepDependence) dispatch(setStep(stepCurrent));
                if (openStep !== undefined) openStep();
            }}
        >
            <ContainerFlex Justify="start" Cursor="pointer">
                {dependence && step !== stepCurrent ? (
                    <Numbers BGColor="#fff" Border="1px solid #35cf44">
                        <IconContainer className="material-icons" Color="#35cf44" FontSize="1.5rem">
                            done
                        </IconContainer>
                    </Numbers>
                ) : (
                    <Numbers
                        Color={step === stepCurrent ? '#ffff' : '#35cf44'}
                        BGColor={step === stepCurrent ? '#35cf44' : '#fff'}
                        Border={step === stepCurrent ? '' : '1px solid #35cf44'}
                    >
                        {stepCurrent}
                    </Numbers>
                )}
                <Text Color="#414042" FontSize="1.125rem" Cursor="pointer">
                    {title}
                </Text>
            </ContainerFlex>
            {deadlineById.copy && <InformationContainer icon={'edit'} />}
            <InformationContainer
                icon={step === stepCurrent ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            />
        </ContainerFlex>
    );
};
