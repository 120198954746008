import {
    POST_CARD_QUOTER,
    POST_CARD_QUOTERSUCCESS,
    POST_CARD_QUOTER_ERROR,
    POST_CARD_BLOCK,
} from '@TypesLoan/types';

const initialState = {
    pay: {},
    loading: false,
    error: false,
    payBlock: false,
};

const PayQuoter = (state = initialState, action: {type: string; payload: {} | number}) => {
    switch (action.type) {
        case POST_CARD_QUOTER:
            return {
                ...state,
                loading: true,
            };
        case POST_CARD_QUOTERSUCCESS:
            return {
                ...state,
                pay: action.payload,
                loading: false,
            };
        case POST_CARD_QUOTER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_CARD_BLOCK:
            return {
                ...state,
                payBlock: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
export default PayQuoter;
