import axios, {AxiosResponse} from 'axios';

import {URL} from '@/config/constants';

import {
    GET_IMAGE_USER,
    GET_IMAGE_USER_SUCCESS,
    GET_IMAGE_USER_ERROR,
} from '@components/ManageUser/Redux/types';
import {AppDispatch} from '@/config/store';

export const getImageUser = () => ({type: GET_IMAGE_USER});
export const getImageUserSuccess = (result: AxiosResponse) => {
    return {
        type: GET_IMAGE_USER_SUCCESS,
        payload: result,
    };
};
export const getImageUserError = () => ({type: GET_IMAGE_USER_ERROR});
export const getImageUserAxios = (token: string, userEmployeeId: number) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getImageUser());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetImageUser}`,
                {
                    headers,
                    params: {
                        userEmployeeId,
                    },
                }
            );
            dispatch(getImageUserSuccess(response));
        } catch (error) {
            dispatch(getImageUserError());
        }
    };
};
