import React, {useEffect} from 'react';
import {AppDispatch, RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';

import {formatNum} from '@/hooks/currentFormatUtils';
import {BUSINESS_LINES, colorBusiness, MYPLAN_TEXT} from '@components/MyPlan/constants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {businessLines} from '@components/MyPlan/Redux/Actions/GetBusinessLines';

export const BusinessLines = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {businessLine, loading} = useSelector((state: RootState) => state.getBusinessLines);

    useEffect(() => {
        dispatch(businessLines(token, companyId));
    }, []);

    return loading ? (
        <ContainerFlex Height="15rem">
            <LoadingAtaskate />
        </ContainerFlex>
    ) : (
        businessLine && (
            <ContainerFlex Height="auto" Gap="1rem" FlexDir="Column" Align="start">
                <Text Color="#2A2C2F">{businessLine.secondText}</Text>
                {businessLine.businessLines
                    .map((line, index: number) => (
                        <ContainerFlex
                            Padding="1rem"
                            Height="auto"
                            key={index}
                            FlexDir="Column"
                            Align="start"
                            Radius="1rem"
                            backG="#FFFFFF"
                        >
                            <ContainerFlex Height="auto" Justify="space-between" MarginBt="0.5rem">
                                <Text
                                    FontWeight="500"
                                    FontSize="1.5rem"
                                    Color={colorBusiness(line.type)}
                                >
                                    {line.type}
                                </Text>
                                <ContainerFlex
                                    Width="50%"
                                    FlexDir="Column"
                                    Align="end"
                                    Gap="0.25rem"
                                >
                                    <ContainerFlex Gap="0.25rem" Justify="end">
                                        <Text
                                            FontWeight="500"
                                            FontSize="1.25rem"
                                            Color={colorBusiness(line.type)}
                                        >
                                            {MYPLAN_TEXT.SIGN_CHANGE}
                                        </Text>
                                        <Text
                                            FontWeight="500"
                                            FontSize="2rem"
                                            Color={colorBusiness(line.type)}
                                        >
                                            {formatNum({
                                                number: line.cost,
                                                fractionDigits: 0,
                                                money: false,
                                            })}
                                        </Text>
                                        <Text
                                            FontWeight="600"
                                            FontSize="0.75rem"
                                            Color={colorBusiness(line.type)}
                                        >
                                            {MYPLAN_TEXT.COIN_TYPE}
                                        </Text>
                                    </ContainerFlex>
                                    <Text FontSize="0.75rem" Color="#414042">
                                        {line.planMessage}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            {line.menus.map((menu, index: number) => (
                                <Text key={index} Color="#71767D" PaddingL="0.5rem">
                                    {BUSINESS_LINES.DOT + menu.menuName}
                                </Text>
                            ))}
                            <ContainerFlex Height="auto" Justify="space-between" MarginTop="0.5rem">
                                <Text
                                    Cursor="pointer"
                                    FontSize="0.75rem"
                                    FontWeight="500"
                                    Color={colorBusiness(line.type)}
                                >
                                    {line.featureMessage}
                                </Text>
                                <Text
                                    Cursor="pointer"
                                    FontSize="0.75rem"
                                    FontWeight="500"
                                    Color="#FF6357"
                                >
                                    {BUSINESS_LINES.DELL_BUSINESS_LINE}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    ))
                    .reverse()}
            </ContainerFlex>
        )
    );
};
