import React, {ChangeEventHandler, forwardRef} from 'react';
import {StyledCheckbox, CheckedBox, InputCheckbox} from '@Shopify/Ecommerce/styles';
import IconCheck from '@images/check.svg';
import IconCheckDisabled from '@images/DisableCheck.svg';

interface CheckboxProps {
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    name?: string;
    border?: string;
    backgroundColor?: string;
    id?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const {onChange, disabled, name, checked, backgroundColor, border, id, ...rest} = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    };
    const bgColor = backgroundColor ? backgroundColor : 'transparent';
    const borderVaue = border ? border : '1px solid #5a5aff';

    return (
        <StyledCheckbox>
            <InputCheckbox
                {...rest}
                type="checkbox"
                name={name}
                ref={ref}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
                id={id}
                style={{backgroundColor: bgColor, border: borderVaue}}
            />
            <CheckedBox src={!disabled ? IconCheck : IconCheckDisabled} />
        </StyledCheckbox>
    );
});
