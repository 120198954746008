import React from 'react';
import {TableItem, TableRowItems} from '@/components/Shopify/Endeavors/styles';
import downloadTicketIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/downloadTicketIcon.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {DUE_DETAILS} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/constants';
import {IFeeRowDataProps} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';
import {formatDate} from '@/hooks/currentFormatUtils';
import {DateTime} from 'luxon';

export const FeeRowData: React.FC<IFeeRowDataProps> = ({
    index,
    tableLength,
    fee,
    expirationDate,
    status,
    paymentDate,
    paymentMethod,
    paymentMethodIcon,
    voucherURL,
    statusSetup,
}) => {
    const handlerDownloadVoucher = () => {
        if (voucherURL) {
            const link = document.createElement('a');
            link.href = voucherURL;
            link.download = paymentMethod;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    return (
        <>
            <TableRowItems
                key={index}
                GridColumn="repeat(6,1fr)"
                Height="48px"
                BorderL="none"
                BorderT="none"
                Margin="0px"
                Radius="0px"
                BorderB={tableLength && index === tableLength - 1 ? '' : '1px solid #E8E9EA'}
                Cursor="default"
            >
                <TableItem>
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {fee}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text Width="120px" Justify="center" FontSize="0.875rem" Color="#2A2C2F">
                        {expirationDate
                            ? formatDate(DateTime.fromISO(expirationDate), DUE_DETAILS.FORMAT_DATE)
                            : '-'}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text
                        FontWeight="700"
                        Justify="center"
                        FontSize="0.75rem"
                        Color={statusSetup?.color ?? '#174A2E'}
                        BorderRadius="32px"
                        Border={`1px solid ${statusSetup?.borderColor ?? '#B1E7C9'}`}
                        bGround={statusSetup?.background ?? '#E4F7EC'}
                        Height="18px"
                        Padding="0.125rem 0.5rem"
                    >
                        {status}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text Width="120px" Justify="center" FontSize="0.875rem" Color="#2A2C2F">
                        {paymentDate
                            ? formatDate(DateTime.fromISO(paymentDate), DUE_DETAILS.FORMAT_DATE)
                            : '-'}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text
                        Width="120px"
                        Justify="center"
                        FontSize="0.875rem"
                        Color="#2A2C2F"
                        Gap="0.5rem"
                    >
                        {paymentMethod && paymentMethodIcon && (
                            <Image
                                src={paymentMethodIcon}
                                Width="24px"
                                Height="16px"
                                alt={DUE_DETAILS.ALT_DESCRIPTIONS.TYPE_PAYMENT}
                            />
                        )}
                        {paymentMethod}
                    </Text>
                </TableItem>
                <TableItem>
                    {statusSetup.isPaid ? (
                        <ContainerFlex
                            Gap="0.25rem"
                            Cursor="pointer"
                            onClick={handlerDownloadVoucher}
                        >
                            <Image
                                src={downloadTicketIcon}
                                Width="24px"
                                Height="24px"
                                alt={DUE_DETAILS.ALT_DESCRIPTIONS.DOWNLOAD_ICON}
                            />
                            <Text
                                FontSize="0.875rem"
                                Color="#5A5AFF"
                                FontWeight="700"
                                Cursor="pointer"
                            >
                                {DUE_DETAILS.FEE_BTN_TICKET}
                            </Text>
                        </ContainerFlex>
                    ) : (
                        <ButtonGenerals SecondaryButton="#FFFFFF" Height="32px" FontWeight="700">
                            {DUE_DETAILS.FEE_BTN_PAY}
                        </ButtonGenerals>
                    )}
                </TableItem>
            </TableRowItems>
        </>
    );
};
