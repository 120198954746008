import {CAT_ADDRESS} from '@components/LocationsBranch/Redux/types';
import {ICatAddress, ICatAddressRedux} from '@components/LocationsBranch/interfaces';
const postalCode = {
    colonys: [],
    postalCode: '',
    cityId: 0,
    cityName: '',
    municipalityId: 0,
    municipalityName: '',
    stateId: 0,
    stateName: '',
    countryId: 0,
    countryName: '',
};
const initialState: ICatAddress = {
    catPostalCode: postalCode,
    catStatesByCountry: [],
    catMunicipalityByState: [],
    catColonyByMunicipality: [],
    error: false,
    loading: false,
};

export const catAddress = (state = initialState, action: ICatAddressRedux): ICatAddress => {
    switch (action.type) {
        case CAT_ADDRESS.GRAL_START:
            return {...state, loading: true, error: false};
        case CAT_ADDRESS.CAT_POSTAL_CODE_SUCCESS:
            return {
                ...state,
                catPostalCode: action.catPostalCode,
                loading: false,
            };
        case CAT_ADDRESS.CAT_STATE_SUCCESS:
            return {
                ...state,
                catStatesByCountry: action.catStatesByCountry,
                loading: false,
            };
        case CAT_ADDRESS.CAT_MUNICIPAITY_SUCCESS:
            return {
                ...state,
                catMunicipalityByState: action.catMunicipalityByState,
                loading: false,
            };
        case CAT_ADDRESS.CAT_COLONY_SUCCESS:
            return {
                ...state,
                catColonyByMunicipality: action.catColonyByMunicipality,
                loading: false,
            };
        case CAT_ADDRESS.GRAL_ERROR:
            return {...state, loading: false, error: false};
        case CAT_ADDRESS.RESET_POSTAL_CODE:
            return {
                ...state,
                catPostalCode: postalCode,
            };
        case CAT_ADDRESS.RESET_ALL:
            return initialState;
        default:
            return state;
    }
};
