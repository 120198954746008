import React, {useEffect} from 'react';
import {Warehouse} from '@components/LocationsBranch/Warehouse';
import {useDispatch, useSelector} from 'react-redux';
import {getStorages} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {RootState} from '@/config/store';
import {ILocationStorageProps} from '@components/LocationsBranch/interfaces';

export const LocationStorage = ({isOptional}: ILocationStorageProps) => {
    const dispatch = useDispatch();
    const {token, levels} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        levels: state.getLevels.level,
    }));
    const handleDataLoad = (filterId?: number) => {
        dispatch(getStorages({LevelId: 1}, token, filterId));
    };
    useEffect(() => {
        if (levels) handleDataLoad();
    }, [dispatch, token, levels?.levelId]);

    return <Warehouse isOptional={isOptional} onRefresh={handleDataLoad} />;
};
