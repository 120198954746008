export const VALIDATE_EMAIL_FORMARKET_PLACE = 'VALIDATE_EMAIL_FORMARKET_PLACE';
export const VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS = 'VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS';
export const VALIDATE_EMAIL_FORMARKET_PLACE_ERROR = 'VALIDATE_EMAIL_FORMARKET_PLACE_ERROR';
export const RESET_EMAIL = 'RESET_EMAIL';

export const CREATE_USER_MARKET = 'CREATE_USER_MARKET';
export const CREATE_USER_MARKET_SUCCESS = 'CREATE_USER_MARKET_SUCCESS';
export const CREATE_USER_MARKET_ERROR = 'CREATE_USER_MARKET_ERROR';

export const RESET_USER_MARKET_DATA = 'RESET_USER_MARKET_DATA';
