import {CURRENT_PAGE, INITIAL_PAGE_COUNT} from '@ReduxsCashFlow/Types/Types';

const initialState = {
    currentPageFirst: 1,
    currentExc: 1,
    currentExcReq: 1,
};

const currentPageFlow = (
    state = initialState,
    action: {type: string; payload: number; value: number; current: number}
) => {
    switch (action.type) {
        case CURRENT_PAGE:
            return {
                currentPageFirst: action.payload,
                currentExc: action.value,
                currentExcReq: action.current,
            };
        case INITIAL_PAGE_COUNT:
            return initialState;

        default:
            return state;
    }
};
export default currentPageFlow;
