export const CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP = 'CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP';
export const ADD_NEW_CLIENT_INFO_SUCCESS = 'ADD_NEW_CLIENT_INFO_SUCCESS';
export const ADD_CLIENT_ADDRESS_PROOF = 'ADD_CLIENT_ADDRESS_PROOF';
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const GET_PROOFTYPES_SUCCESS = 'GET_PROOF_TYPES_SUCCESS';
export const GET_PROOFTYPES_ERROR = 'GET_PROOFTYPES_ERROR';
export const SET_LOADING = 'SET_LOADING ';
export const GET_CIVIL_STATUS_SUCCESS = 'GET_CIVIL_STATUS_SUCCESS';
export const GET_ECONOMIC_ACTIVITY_SUCCESS = 'GET_ECONOMIC_ACTIVITY_SUCCESS';
export const GET_GENRE_SUCCESS = 'GET_GENRE_SUCCESS';
export const GET_IDENTIFICATION_TYPE_SUCCESS = 'GET_IDENTIFICATION_TYPE_SUCCESS';
export const SET_LOADING_CLIENT_INFO = 'SET_LOADING_CLIENT_INFO';
export const GET_MARKETING_CLIENT_SUCCESS = 'GET_MARKETING_CLIENT_SUCCESS';
export const ADD_PROOF_ADDRESS_SUCCESS = 'ADD_PROOF_ADDRESS_SUCCESS';
export const SET_PROOFTYPES_SUCCESS = 'SET_PROOF_TYPES_SUCCESS';
export const SET_PROOFTYPES_ERROR = 'SET_PROOFTYPES_ERROR';

export const ASSIGNMENT_CLIENT_PROFILE_START = 'ASSIGNMENT_CLIENT_PROFILE_START';
export const ASSIGNMENT_CLIENT_PROFILE_SUCCESS = 'ASSIGNMENT_CLIENT_PROFILE_SUCCESS';
export const ASSIGNMENT_CLIENT_PROFILE_ERROR = 'ASSIGNMENT_CLIENT_PROFILE_ERROR';

export const SET_CLIENT_FINGERPRINTS = 'SET_CLIENT_FINGERPRINTS';

export const GET_HANDS_SCHEMA = {
    GET_HANDS_START: 'GET_HANDS_START',
    GET_HANDS_SUCCESS: 'GET_HANDS_SUCCESS',
    GET_HANDS_ERROR: 'GET_HANDS_ERROR',
};

export const ADD_HAND_FINGERPRINT = {
    ADD_FINGERPRINT_START: 'ADD_FINGERPRINT_START',
    ADD_FINGERPRINT_SUCCESS: 'ADD_FINGERPRINT_SUCCESS',
    ADD_FINGERPRINT_ERROR: 'ADD_FINGERPRINT_ERROR',
};

export const RESET_NEWCLIENTPROCESS = 'RESET_NEWCLIENTPROCESS';
