import {COLORS} from '@General/Variables';
import styled from '@emotion/styled';
import {IPropsBreadCrumb} from '@General/Moleculs/Breadcrumb/interfacesStyles';

export const BreadcrumbContainer = styled.div<IPropsBreadCrumb>`
    display: flex;
    align-items: center;
    color: ${(props) => props.Color};
    column-gap: ${(props) => props.ColumnGap};

    span {
        display: flex;
        align-items: center;
        text-align: center;
    }
    span > i {
        letter-spacing: 0px;
        padding-left: 5px;
        padding-right: 5px;
        color: ${COLORS.gray2};
        font-size: 0.75rem;
        line-height: 1rem;
    }
    span:last-child {
        font: normal normal normal 0.875rem Work Sans;
        font-size: ${(props) => props.FontLastChild};
        font-weight: ${(props) => props.FontWeight};
        letter-spacing: 0px;
        color: ${(props) => (props.Color ? props.Color : COLORS.black)};
    }
`;

BreadcrumbContainer.defaultProps = {
    FontLastChild: '0.875rem',
    FontWeight: '500',
    ColumnGap: '4px',
};
