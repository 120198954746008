import {RootState} from '@/config/store';
import {Controller} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Select} from '@Quoter/Steps/styles';
import AddPlus from '@images/add - plus.svg';
import {
    RegisterAdressProps,
    AditionalInformationEmployee,
} from '@components/CreateUsers/interfaces';
import * as content from '@components/CreateUsers/Styles';
import {ADRESSDETAILS} from '@components/CreateUsers/constants';
import {addressAdd} from '@components/CreateUsers/Redux/Actions/saveUser';
import {
    GetPostalInfo,
    GetPostalInfoReset,
} from '@components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
import {ContainerFlex, Text, Input, ContainerCellGrid, Image} from '@Shopify/Ecommerce/styles';

const AdressUser: React.FC<RegisterAdressProps> = ({
    control,
    register,
    setValue,
    trigger,
    watch,
    errors,
}) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const cityInfo = useSelector((state: RootState) => state.GetPostalInfo?.postalInfo);
    const {aditionalInformationEmployee} = useSelector((state: RootState) => state.saveUser);
    const {editUsers} = useSelector((state: RootState) => state.editUsers);

    const dispatch = useDispatch();
    const [createAdress, setCreateAdress] = useState(false);
    const [Adress, setAdress] = useState<AditionalInformationEmployee[]>([]);

    const handleAddAddress = () => {
        setCreateAdress(true);
        setAdress([
            {
                codigoPostal: '',
                address: '',
                externalNumber: '',
                ineternalNumber: '',
                catColoniaId: 0,
                manageAditionalInformation: true,
            },
        ]);
    };
    const handleDeleteAddress = () => {
        setCreateAdress(false);
        dispatch(GetPostalInfoReset());
        aditionalInformationEmployee.forEach((_, index) => {
            setValue(`aditionalInformationEmployee.${index}.codigoPostal`, '');
            setValue(`aditionalInformationEmployee.${index}.address`, '');
            setValue(`aditionalInformationEmployee.${index}.externalNumber`, '');
            setValue(`aditionalInformationEmployee.${index}.ineternalNumber`, '');
            setValue(`aditionalInformationEmployee.${index}.catColoniaId`, 0);
        });
    };
    const colonyOptions = cityInfo?.colonys?.map(
        (colony: {colonyId: number; colonyName: string}) => ({
            label: colony.colonyName,
            value: colony.colonyId,
        })
    );

    useEffect(() => {
        if (editUsers.addressUser.length > 0) handleAddAddress();
    }, []);

    return (
        <ContainerFlex {...content.contentSection} Height="auto">
            <Text {...content.subtitles}>{ADRESSDETAILS.TITLE}</Text>
            <Text {...content.instructions}>{ADRESSDETAILS.DETAILS}</Text>
            {createAdress ? (
                <>
                    {Adress.map((address, index) => (
                        <ContainerFlex key={index} FlexDir="column" Gap="1rem">
                            <ContainerFlex Justify="space-between" Align="end" Gap="1rem">
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="10rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.CP}
                                    </Text>
                                    <Input
                                        defaultValue={
                                            aditionalInformationEmployee[index]?.codigoPostal
                                        }
                                        Cursor="text"
                                        type="number"
                                        Width="100%"
                                        Margin="0 0 8px 0"
                                        {...register(
                                            `aditionalInformationEmployee.${index}.codigoPostal`,
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Campo obligatorio.',
                                                },
                                            }
                                        )}
                                        onKeyUp={() =>
                                            trigger(
                                                `aditionalInformationEmployee.${index}.codigoPostal`
                                            )
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length === 5)
                                                dispatch(GetPostalInfo(value, token));
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            );
                                        }}
                                        onInput={(e) => {
                                            const value = e.currentTarget.value.slice(0, 5);
                                            setValue(
                                                `aditionalInformationEmployee.${index}.codigoPostal`,
                                                value
                                            );
                                        }}
                                        error={
                                            errors?.aditionalInformationEmployee?.[`${index}`]
                                                ?.codigoPostal?.message
                                        }
                                    />
                                </ContainerCellGrid>
                                <Text
                                    Margin="0.25rem 0.5rem"
                                    MarginB="0.25rem"
                                    Color="#FF6357"
                                    Cursor="pointer"
                                    onClick={() => {
                                        handleDeleteAddress();
                                    }}
                                >
                                    {ADRESSDETAILS.DELETEADRESS}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" Gap="1rem">
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="35.375rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.ADRESS}
                                    </Text>
                                    <Input
                                        defaultValue={aditionalInformationEmployee[index]?.address}
                                        maxLength={160}
                                        Width="100%"
                                        Margin="0 0 8px 0"
                                        {...register(
                                            `aditionalInformationEmployee.${index}.address`,
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Campo obligatorio.',
                                                },
                                            }
                                        )}
                                        onChange={() =>
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            )
                                        }
                                        error={
                                            errors?.aditionalInformationEmployee?.[`${index}`]
                                                ?.address?.message
                                        }
                                    />
                                </ContainerCellGrid>
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="17.188rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.NUMEXT}
                                    </Text>
                                    <Input
                                        defaultValue={
                                            aditionalInformationEmployee[index]?.externalNumber
                                        }
                                        Width="100%"
                                        Margin="0 0 8px 0"
                                        {...register(
                                            `aditionalInformationEmployee.${index}.externalNumber`,
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Campo obligatorio.',
                                                },
                                            }
                                        )}
                                        onChange={() =>
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            )
                                        }
                                        error={
                                            errors?.aditionalInformationEmployee?.[`${index}`]
                                                ?.externalNumber?.message
                                        }
                                    />
                                    {errors?.aditionalInformationEmployee?.[`${index}`]
                                        ?.externalNumber?.message && (
                                        <Text
                                            Color="#ef4f55"
                                            FontSize="0.75rem"
                                            Align="start"
                                            wSpace="normal"
                                            Height="auto"
                                            FontWeight="400"
                                        >
                                            {
                                                errors?.aditionalInformationEmployee?.[`${index}`]
                                                    ?.externalNumber?.message
                                            }
                                        </Text>
                                    )}
                                </ContainerCellGrid>
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="17.188rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.NUMINT}
                                        <Text
                                            wSpace="pre"
                                            FontWeight="500"
                                            FontSize="0.75rem"
                                            Color="#A1A5AA"
                                        >
                                            {ADRESSDETAILS.OPTIONAL}
                                        </Text>
                                    </Text>
                                    <Input
                                        defaultValue={
                                            aditionalInformationEmployee[index]?.ineternalNumber
                                        }
                                        type="number"
                                        Width="100%"
                                        Margin="0 0 8px 0"
                                        {...register(
                                            `aditionalInformationEmployee.${index}.ineternalNumber`
                                        )}
                                        onChange={() =>
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            )
                                        }
                                    />
                                </ContainerCellGrid>
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" Gap="1rem">
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="23.25rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.COLONY}
                                    </Text>

                                    <Controller
                                        name={`aditionalInformationEmployee.${index}.catColoniaId`}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Campo obligatorio.',
                                            },
                                        }}
                                        render={({field: {onChange, ref, ...field}}) => (
                                            <Select
                                                defaultValue={{
                                                    value: aditionalInformationEmployee[index]
                                                        ?.catColoniaId?.value,
                                                    label: aditionalInformationEmployee[index]
                                                        ?.catColoniaId?.label,
                                                }}
                                                {...field}
                                                ref={ref}
                                                placeholder="Colonia"
                                                isSearchable={false}
                                                options={colonyOptions}
                                                loadingMessage={() => 'Cargando...'}
                                                noOptionsMessage={() => 'Sin opciones'}
                                                onChange={(e) => {
                                                    if (onChange) onChange(e);
                                                    dispatch(
                                                        addressAdd(
                                                            watch('aditionalInformationEmployee')
                                                        )
                                                    );
                                                }}
                                                border={
                                                    errors?.aditionalInformationEmployee?.[
                                                        `${index}`
                                                    ]?.catColoniaId?.message
                                                        ? '1px solid red'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                    {errors?.aditionalInformationEmployee?.[`${index}`]
                                        ?.catColoniaId?.message && (
                                        <Text
                                            Color="#ef4f55"
                                            FontSize="0.75rem"
                                            Align="start"
                                            wSpace="normal"
                                            Height="auto"
                                            FontWeight="400"
                                        >
                                            {
                                                errors?.aditionalInformationEmployee?.[`${index}`]
                                                    ?.catColoniaId?.message
                                            }
                                        </Text>
                                    )}
                                </ContainerCellGrid>
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="23.25rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.CITYHALL}
                                    </Text>
                                    <Select
                                        defaultValue={{
                                            value: aditionalInformationEmployee[index]?.minicipaly
                                                ?.value,
                                            label: aditionalInformationEmployee[index]?.minicipaly
                                                ?.label,
                                        }}
                                        placeholder="Alcaldía"
                                        isSearchable={false}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() => 'Sin opciones'}
                                        options={[
                                            {
                                                label: cityInfo?.municipalityName,
                                                value: cityInfo?.municipalityId,
                                            },
                                        ]}
                                        value={
                                            cityInfo?.municipalityName
                                                ? {
                                                      label: cityInfo.municipalityName,
                                                      value: cityInfo.municipalityId,
                                                  }
                                                : undefined
                                        }
                                        onChange={() =>
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            )
                                        }
                                    />
                                </ContainerCellGrid>
                                <ContainerCellGrid
                                    FlexDir="column"
                                    Align="start"
                                    Height="auto"
                                    Width="23.25rem"
                                >
                                    <Text FontWeight="500" FontSize="0.75rem" Color="#6D6E71">
                                        {ADRESSDETAILS.STATE}
                                    </Text>
                                    <Select
                                        defaultValue={{
                                            value: aditionalInformationEmployee[index]?.state
                                                ?.value,
                                            label: aditionalInformationEmployee[index]?.state
                                                ?.label,
                                        }}
                                        placeholder="Estado"
                                        isSearchable={false}
                                        loadingMessage={() => 'Cargando...'}
                                        noOptionsMessage={() => 'Sin opciones'}
                                        options={[
                                            {
                                                label: cityInfo?.stateName,
                                                value: cityInfo?.cityId,
                                            },
                                        ]}
                                        onChange={() =>
                                            dispatch(
                                                addressAdd(watch('aditionalInformationEmployee'))
                                            )
                                        }
                                        value={
                                            cityInfo?.stateName
                                                ? {
                                                      label: cityInfo?.stateName,
                                                      value: cityInfo?.cityId,
                                                  }
                                                : undefined
                                        }
                                    />
                                </ContainerCellGrid>
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}

                    <ContainerFlex Justify="start" Cursor="pointer" Width="auto">
                        <Image src={AddPlus} alt="icon-add-image" Cursor="pointer" />
                        <Text Color="#5A5AFF" FontSize=".875rem" FontWeight="500" Cursor="pointer">
                            {ADRESSDETAILS.ADDMORE}
                        </Text>
                    </ContainerFlex>
                </>
            ) : (
                <ContainerFlex
                    Justify="start"
                    Cursor="pointer"
                    Width="auto"
                    onClick={handleAddAddress}
                >
                    <Image src={AddPlus} alt="icon-add-image" Cursor="pointer" />
                    <Text Color="#5A5AFF" FontSize=".875rem" FontWeight="500" Cursor="pointer">
                        {ADRESSDETAILS.ADDADRESS}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default AdressUser;
