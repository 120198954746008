import axios, {AxiosResponse} from 'axios';
import {
    ROLES_PASS,
    GET_ROLE,
    GET_ROLE_ERROR,
    GET_ROLE_SUCCESS,
    SEARCH_VALUE,
    RESET_TABLE,
    GET_ROLE_SUCCESS_COPY,
    GET_ROLE_SIZE,
    GET_ROLE_SIZE_SUCCESS,
    GET_ROLE_SIZE_ERROR,
    GET_ROLE_TYPE,
    GET_ROLE_TYPE_SUCCESS,
    GET_ROLE_TYPE_ERROR,
    GET_SELECT_ROLE_SIZE,
    GET_SELECT_ROLE_TYPE,
    SELECT_SHOW_STATE,
    SELECT_ROLETYPE_STATE,
    CURRENT_PAGE,
    GET_ROLE_FINISH,
} from '@TypesRoles/types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const summateCurrentPage = (value: number) => {
    return {
        type: CURRENT_PAGE,
        payload: value,
    };
};
export const searchValue = (value: string) => {
    return {
        type: SEARCH_VALUE,
        payload: value,
    };
};

export const fetchRolesPass = (value: number) => {
    return {
        type: ROLES_PASS,
        payload: value,
    };
};

export const startRole = () => {
    return {
        type: GET_ROLE,
    };
};
export const getRoleSucces = (value: AxiosResponse) => {
    return {
        type: GET_ROLE_SUCCESS,
        payload: value,
    };
};

export const getRoleError = () => {
    return {
        type: GET_ROLE_ERROR,
    };
};
export const getRoleFinish = () => {
    return {
        type: GET_ROLE_FINISH,
    };
};
export const getRoleSuccesCopy = (value: AxiosResponse) => {
    return {
        type: GET_ROLE_SUCCESS_COPY,
        payload: value,
    };
};
export const resetTable = () => {
    return {
        type: RESET_TABLE,
    };
};

export function getRoles(
    filter: {
        enterpriseId: number;
        searchRoleName: string;
        pageSize: number;
        pageNumber: number;
        orderType: number;
        filterRoles: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(startRole());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRoles}`,
                filter,
                {headers}
            );
            await dispatch(getRoleSucces(response.data.data));
            await dispatch(getRoleSuccesCopy(response));
        } catch (error) {
            dispatch(getRoleError());
        } finally {
            dispatch(getRoleFinish());
        }
    };
}
export const startRoleSize = () => {
    return {
        type: GET_ROLE_SIZE,
    };
};
export const getRoleSizeSuccess = (value: AxiosResponse) => {
    return {
        type: GET_ROLE_SIZE_SUCCESS,
        payload: value,
    };
};

export const getRoleSizeError = () => {
    return {
        type: GET_ROLE_SIZE_ERROR,
    };
};

export function getRolesSize(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(startRoleSize());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRoleSize}`,
                {headers}
            );
            await dispatch(getRoleSizeSuccess(response));
        } catch (error) {
            dispatch(getRoleSizeError());
        }
    };
}

export const startRoleType = () => {
    return {
        type: GET_ROLE_TYPE,
    };
};
export const getRoleTypeSuccess = (value: AxiosResponse) => {
    return {
        type: GET_ROLE_TYPE_SUCCESS,
        payload: value,
    };
};

export const getRoleTypeError = () => {
    return {
        type: GET_ROLE_TYPE_ERROR,
    };
};

export function getRolesType(companySizeId: number, roleTypeId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(startRoleType());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSelectRoleType.replace(
                    '{companySizeId}',
                    String(companySizeId)
                ).replace('{roleTypeId}', String(roleTypeId))}`,
                {headers}
            );
            await dispatch(getRoleTypeSuccess(response));
        } catch (error) {
            dispatch(getRoleTypeError());
        }
    };
}

export const fetchSelectRoleSize = (value: {
    id: number;
    nameCompanySize: string;
    users: string;
    branch: string;
    urlImage: string;
}) => {
    return {
        type: GET_SELECT_ROLE_SIZE,
        payload: value,
    };
};

export const fetchSelectRoleType = (
    value: {
        id: number;
        roleName: string;
        usersQuantity: number;
        roleTypeId: number;
        default: number;
    } | null
) => {
    return {
        type: GET_SELECT_ROLE_TYPE,
        payload: value,
    };
};

export const fetchSelectState = (checkedState: boolean) => {
    return {
        type: SELECT_SHOW_STATE,
        payload: checkedState,
    };
};

export const fetchSelectRoleTypeId = (value: number) => {
    return {
        type: SELECT_ROLETYPE_STATE,
        payload: value,
    };
};
