import {IMySalesChannels, ISalesChannelsAction} from '@components/MyPlan/interfaces';
import {MY_SALES_CHANNELS} from '@components/MyPlan/Redux/types';
const initialState: IMySalesChannels = {
    loading: false,
    error: false,
    channelsData: {
        companyId: 0,
        companyName: '',
        messagePayment: '',
        messageDay: '',
        firstText: '',
        title: '',
        content: [],
    },
};
const getSalesChannels = (state = initialState, action: ISalesChannelsAction): IMySalesChannels => {
    switch (action.type) {
        case MY_SALES_CHANNELS.START_GET_CHANNELS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case MY_SALES_CHANNELS.SUCCESS_GET_CHANNELS:
            return {
                ...state,
                channelsData: action.channelsData,
                loading: false,
                error: false,
            };
        case MY_SALES_CHANNELS.ERROR_GET_CHANNELS:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default getSalesChannels;
