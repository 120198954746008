import axios, {AxiosResponse} from 'axios';
import {
    PUT_COMPLEMENTARY_INFO,
    PUT_COMPLEMENTARY_INFO_ERROR,
    PUT_COMPLEMENTARY_INFO_FINISH,
    PUT_COMPLEMENTARY_INFO_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

import {URL} from '@config/constants/index';
import {IShowItem, IUpdateComplementaryInfo} from '@/components/Branches/interfaces';
import {AppDispatch} from '@/config/store';
import {GetInformationBranch} from '@/components/Branches/ManageBranch/Redux/Actions/GetInformationBranch';

export const fetchPutComplementaryInfoStart = () => {
    return {
        type: PUT_COMPLEMENTARY_INFO,
    };
};
export const fetchPutComplementaryInfoSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_COMPLEMENTARY_INFO_SUCCESS,
        payload: result,
    };
};
export const fetchPutComplementaryInfoError = () => {
    return {
        type: PUT_COMPLEMENTARY_INFO_ERROR,
    };
};
export const fetchPutComplementaryInfoFinish = () => {
    return {
        type: PUT_COMPLEMENTARY_INFO_FINISH,
    };
};

export function PutComplementaryInfo(
    data: IUpdateComplementaryInfo,
    token: string,
    setShowComplementaryForm: (e: boolean) => void,
    setShowItems: (e: IShowItem) => void,
    showItems: IShowItem,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutComplementaryInfoStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutComplementaryInfo}`,
                data,
                {headers}
            );
            dispatch(fetchPutComplementaryInfoSuccess(response));
            setShowComplementaryForm(false);
            setShowItems({...showItems, info: false});
        } catch (error) {
            dispatch(fetchPutComplementaryInfoError());
        } finally {
            dispatch(fetchPutComplementaryInfoFinish());
            dispatch(GetInformationBranch(idBranch, token));
        }
    };
}
