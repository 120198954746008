import {AxiosResponse, AxiosError} from 'axios';
import {
    ADD_WIRE_TRANSFER_LOAN_ERROR,
    ADD_WIRE_TRANSFER_LOAN_START,
    ADD_WIRE_TRANSFER_LOAN_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IAddWireTransferLoan} from '@components/PersonalLoans/interfaces';

const initialState: IAddWireTransferLoan = {
    data: 0,
    loading: false,
    error: false,
};

const addWireTransfer = (
    state: IAddWireTransferLoan = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IAddWireTransferLoan => {
    switch (action.type) {
        case ADD_WIRE_TRANSFER_LOAN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_WIRE_TRANSFER_LOAN_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case ADD_WIRE_TRANSFER_LOAN_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default addWireTransfer;
