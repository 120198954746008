import * as yup from 'yup';
import {IGetAllNations} from '@/components/Quoter/CoownerBeneficiary/interface';
import {SCHEMA_TRANSACION, SCHEMA_QUICK_PAYMENT} from '@/components/QuickPayment/Constants';

const phoneRegex = /^(?:\(\d{2}\) \d{4,5}-\d{4}|[0-9]{10})$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const defaultRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;

export const schemaPhone = (ladaValue: string = '') => {
    return yup.object().shape({
        data: yup
            .string()
            .required(
                ladaValue === ''
                    ? SCHEMA_QUICK_PAYMENT.REQUIRED_LADA
                    : SCHEMA_QUICK_PAYMENT.REQUIRED
            )
            .test(
                'is-valid-number-and-lada',
                ladaValue === ''
                    ? SCHEMA_QUICK_PAYMENT.SCHEMA_PHONE.MATCH_LADA
                    : SCHEMA_QUICK_PAYMENT.SCHEMA_PHONE.MATCH,
                (value) => {
                    if (!value) return false;
                    if (!ladaValue) return false;
                    return phoneRegex.test(value);
                }
            ),
    });
};

export const schemaEmail = yup.object().shape({
    data: yup
        .string()
        .required(SCHEMA_QUICK_PAYMENT.REQUIRED)
        .matches(emailRegex, SCHEMA_QUICK_PAYMENT.SCHEMA_EMAIL.MATCH),
});

export const schemaContract = yup.object().shape({
    data: yup
        .string()
        .required(SCHEMA_QUICK_PAYMENT.REQUIRED)
        .matches(defaultRegex, SCHEMA_QUICK_PAYMENT.SCHEMA_CONTRACT.MATCH),
});

export const getSchemaAccessPayment = (
    typeAccessId: number,
    nationSelected: IGetAllNations | null
) => {
    const schemaMap = new Map([
        [1, schemaContract],
        [3, schemaEmail],
    ]);
    if (typeAccessId === 2) return schemaPhone(nationSelected?.lada);
    return schemaMap.get(typeAccessId);
};

export const schemaTransactionLoans = yup.object().shape({
    cardNumber: yup
        .string()
        .required(SCHEMA_TRANSACION.REQUIRED)
        .matches(/^\d{12,16}$/, SCHEMA_TRANSACION.CARD_NUMBER.MATCH)
        .test('is-valid-card', SCHEMA_TRANSACION.CARD_NUMBER.TEST, (value) => {
            if (!value) return false;
            const luhnCheck = (number: string): boolean => {
                const digits = number.split('').reverse().map(Number);
                const sum = digits.reduce((acc, digit, index) => {
                    if (index % 2 === 1) {
                        const doubled = digit * 2;
                        return acc + (doubled > 9 ? doubled - 9 : doubled);
                    }
                    return acc + digit;
                }, 0);
                return sum % 10 === 0;
            };
            const sanitizedValue = value.replace(/\s+/g, '');
            const length = sanitizedValue.length;
            const validLengths = [12, 15, 16];
            if (!validLengths.includes(length)) return false;
            return luhnCheck(sanitizedValue);
        }),
    cardHolderName: yup
        .string()
        .required(SCHEMA_TRANSACION.REQUIRED)
        .min(2, SCHEMA_TRANSACION.CARD_HOLDER_NAME.MIN)
        .matches(/^[A-Za-zÀ-ÖØ-Ýà-öø-ÿ\s]+$/, SCHEMA_TRANSACION.CARD_HOLDER_NAME.MATCH)
        .max(26, SCHEMA_TRANSACION.CARD_HOLDER_NAME.MAX),
    expiryDate: yup
        .string()
        .required(SCHEMA_TRANSACION.REQUIRED)
        .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, SCHEMA_TRANSACION.CARD_EXPIRATION_DATE.MATCH)
        .test('is-valid-expiry-date', SCHEMA_TRANSACION.CARD_EXPIRATION_DATE.TEST, (value) => {
            if (!value) return false;
            const [month, year] = value.split('/').map(Number);
            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1;
            if (year < currentYear) return false;
            if (year === currentYear && month < currentMonth) return false;
            if (month < 1 || month > 12) return false;
            return true;
        }),
    cvv: yup
        .string()
        .required(SCHEMA_TRANSACION.REQUIRED)
        .matches(/^\d{3,4}$/, SCHEMA_TRANSACION.CARD_CCV.MATCH),
});
