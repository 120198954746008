import {
    GET_SUCURSAL,
    GET_SUCURSAL_SUCCESS,
    GET_SUCURSAL_ERROR,
    OBTAIN_INFO_SUCURSAL,
    OBTAIN_INFO_SUCURSAL_SUCCESS,
    GET_SUCURSAL_HISTORY,
    GET_SUCURSAL_HISTORY_SUCCESS,
    GET_SUCURSAL_HISTORY_ERROR,
    POST_ASSIGNMENT,
    POST_ASSIGNMENT_SUCCESS,
    POST_ASSIGNMENT_ERROR,
} from '@TypesAuditAssignment/AuditAssignmentTypes';

const initialState = {
    sucursal: [],
    error: false,
    sucursalcount: 0,
    sucursalID: '',
    sucursalinfoID: [],
    sucursalhistory: [],
    loadingsucursal: false,
    loadingsucursalhistory: false,
};

const sucursal = (state = initialState, action: {type: any; value: any; valuesucursal: any}) => {
    switch (action.type) {
        case GET_SUCURSAL:
            return {
                ...state,
                loadingsucursal: true,
            };
        case GET_SUCURSAL_SUCCESS:
            return {
                ...state,
                sucursal: action.value.data,
                sucursalcount: action.value.count,
                loadingsucursal: false,
            };
        case GET_SUCURSAL_ERROR:
            return {
                ...state,
                error: true,
                loadingsucursal: false,
            };
        case OBTAIN_INFO_SUCURSAL:
            return {
                ...state,
                sucursalID: action.value,
                loading: true,
            };
        case OBTAIN_INFO_SUCURSAL_SUCCESS:
            return {
                ...state,
                sucursalhistory: [],
                sucursalinfoID: state.sucursal.filter(
                    (sucurs: {idsucursal: string}) => sucurs.idsucursal === state.sucursalID
                ),
            };
        case GET_SUCURSAL_HISTORY:
            return {
                ...state,
                loadingsucursalhistory: false,
            };
        case GET_SUCURSAL_HISTORY_SUCCESS:
            return {
                ...state,
                sucursalhistory: action.value,
                loadingsucursalhistory: false,
            };
        case GET_SUCURSAL_HISTORY_ERROR:
            return {
                ...state,
                error: true,
                loadingsucursalhistory: false,
            };
        case POST_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case POST_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default sucursal;
