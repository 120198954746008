import {
    ADD_CODE_MARKET_LOGIN_USER,
    ADD_COUNT_MARKET_LOGIN_USER,
    RESET_CODE_MARKET_LOGIN_USER,
} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    count: 0,
    loading: false,
    code: ['', '', '', ''],
    error: null,
};

const CodePasswordChange = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CODE_MARKET_LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: null,
                code: action.payload,
            };
        case ADD_COUNT_MARKET_LOGIN_USER:
            return {
                ...state,
                loading: false,
                error: null,
                count: action.payload,
            };
        case RESET_CODE_MARKET_LOGIN_USER:
            return {
                ...state,
                loading: false,
                error: null,
                code: ['', '', '', ''],
                count: 30,
            };
        default:
            return state;
    }
};

export default CodePasswordChange;
