import styled from '@emotion/styled';
import {IPropsInputData, IPropsLabel} from '@MyCompany/interfacesStyles';

export const InputData = styled.input<IPropsInputData>`
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    padding: 0 12px;
    margin-top: ${(props) => props.MarginTop};
    border-radius: ${(props) => props.BorderR};
    &:disabled {
        background: rgba(243, 243, 243, 0.4);
        border: none;
        cursor: default;
        width: auto;
    }
    &:focus {
        outline: none;
    }
`;

export const Label = styled.label<IPropsLabel>`
    display: ${(props) => props.Display};

    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    color: ${(props) => props.Color};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin-top: ${(props) => props.MarginTop};
    overflow: ${(props) => props.OverFlow};
    overflow-wrap: ${(props) => props.WrapOverFlow};
    margin: ${(props) => props.Margin};
    margin-left: ${(props) => props.MarginLeft};
    align-items: ${(props) => props.Aling};
`;
Label.defaultProps = {
    Margin: '0px',
    FontWeight: 'normal',
    OverFlow: 'hidden',
    Aling: 'center',
};
export const border = {
    Bb: 'solid 1px #e5e7e9',
    Bl: 'solid 1px #e5e7e9',
    Br: 'solid 1px #e5e7e9',
};
