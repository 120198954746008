import React, {ChangeEvent, useRef, useState, useCallback} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ArchiveInputContainer} from '@/components/Quoter/Steps/styles';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import {IExpenseDetailFile} from '@components/CashFlowActions/Operational/interfaces';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import addFile from '@images/AddFile.svg';
import {BTN} from '@/components/CashFlowActions/Operational/Constants';

const UploadFile = (props: IExpenseDetailFile) => {
    const {selectedFile, setSelectedFile} = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [filePropierties, setPropierties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setPropierties(file);
                        setErrorFile(false);
                    }
                };
            } else {
                setErrorFile(true);
            }
        }
    };

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            const file = event.dataTransfer.files?.[0];
            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setPropierties(file);
                        setErrorFile(false);
                    }
                };
            } else {
                setErrorFile(true);
            }
        },
        [setSelectedFile]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    return (
        <ArchiveInputContainer
            Height="66px"
            Width="100%"
            error={errorFile}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            <ContainerFlex>
                {selectedFile && selectedFile.length > 0 ? (
                    <ContainerFlex ColumnGap="8px" Justify="start">
                        <ContainerFlex
                            FlexDir="column"
                            Cursor="pointer"
                            Justify="space-between"
                            Padding="0 18px 0 8px"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <ContainerFlex Align="start">
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {filePropierties !== null ? filePropierties.name : ''}
                                </Text>
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {filePropierties !== null
                                        ? (filePropierties.size / (1024 * 1024)).toFixed(2)
                                        : 0}
                                    {BTN.MB}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Justify="end">
                                <Text FontSize="0.75rem" Color="#5A5AFF" FontWeight="500">
                                    {BTN.REMP}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                ) : (
                    <ContainerFlex FlexDir="column" onClick={() => fileInputRef.current?.click()}>
                        <ContainerFlex Height="max-content">
                            <ImageLogo src={addFile} />
                            <Text FontSize="1rem" Color="#54575">
                                {BTN.ADD_TEXT}
                            </Text>
                        </ContainerFlex>

                        <Text FontSize="1rem" Color="#5A5AFF">
                            {BTN.ADD_SEARCH}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ArchiveInputContainer>
    );
};

export default UploadFile;
