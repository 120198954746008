import {SAVE_CASH_ID} from '@ReduxsCashFlow/Types/Types';
const INITIAL_STATE = {
    branchId: 0,
};

export const BranchIdCash = (state = INITIAL_STATE, action: {type: string; value: number}) => {
    switch (action.type) {
        case SAVE_CASH_ID:
            return {
                branchId: action.value,
            };

        default:
            return state;
    }
};
export default BranchIdCash;
