import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {InputCheckbox} from '@/components/Loan/styles';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import {ClickAwayListener} from '@mui/material';
import {options} from '@components/AuctionPass/Constants';
import arrow from '@images/close Arrow.svg';

const DropdownCheckbox = () => {
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxChange = (id: number) => {
        setSelectedOptions((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((optionId) => optionId !== id);
            } else if (prevSelected.length < 4) {
                return [...prevSelected, id];
            }
            return prevSelected;
        });
    };

    const selectedLabels = options
        .filter((option) => selectedOptions.includes(option.id))
        .map((option) => option.label);

    return (
        <ContainerFlex
            backG="#FFFFFF"
            FlexDir="column"
            Justify="start"
            Align="start"
            MinH="2rem"
            MarginTop="1.5rem"
        >
            <ButtonGenerals
                onClick={() => setIsOpen(!isOpen)}
                BackGC="#FFFFFF"
                Border="1px solid #E8E9EA"
                Width="80%"
                Padding="4px"
                HBackG="white"
                Justify="start"
                Height="2.5rem"
                MHeight="2.5rem"
            >
                {selectedLabels.slice(0, 2).map((i) => (
                    <Text FontWeight="700" Color="#2A2C2F" BorderRadius="8px" Padding="4px" key={i}>
                        {i}
                    </Text>
                ))}
                {selectedOptions.length > 2 ? (
                    <Text FontWeight="700" Color="#2A2C2F" BorderRadius="8px" Padding="4px">
                        {`+ ${selectedOptions.length - 2}`}
                    </Text>
                ) : (
                    ''
                )}
                <ImageIcon src={arrow} alt="Open" Left="48%" Position="fixed" />
            </ButtonGenerals>

            {isOpen && (
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <ContainerFlex
                        FlexDir="column"
                        Align="start"
                        Justify="start"
                        Gap="8px"
                        Position="relative"
                        backG="white"
                        Height="10rem"
                    >
                        {options.map((option) => (
                            <ContainerFlex
                                key={option.id}
                                FontWeight="400"
                                Align="start"
                                Justify="start"
                                Gap="8px"
                                MarginTop="5px"
                            >
                                <InputCheckbox
                                    type="checkbox"
                                    checked={selectedOptions.includes(option.id)}
                                    onChange={() => handleCheckboxChange(option.id)}
                                />
                                {option.label}
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                </ClickAwayListener>
            )}
        </ContainerFlex>
    );
};

export default DropdownCheckbox;
