import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';

import AddImage from '@images/import.svg';
import AddImageRed from '@images/import_red.svg';
import DeleteImage from '@images/delete - trash.svg';
import EditImage from '@images/replace.svg';

import {AddImgs} from '@Quoter/Steps/styles';
import {IDataImageProdcutsProps, ImagePledge} from '@Quoter/CoownerBeneficiary/interface';
import {postValidPrechrageimage} from '@Quoter/CoownerBeneficiary/Redux/Actions/UploadArticleImages';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';

export const ImagePledgesComponent = ({
    imagePledges,
    setImagePledges,
    imagesRender,
    setImagesRender,
    imageToReplace,
    setImageToReplace,
    selectedPledge,
    isCheck,
    setIsCheck,
}: IDataImageProdcutsProps) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const carShoppingId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.carShoopingId as number;
    });
    const ErrorImageUpload = useSelector((state: RootState) => {
        return state.UploadArticleImages.error;
    });
    const ErrorImageMessage = useSelector((state: RootState) => {
        return state.UploadArticleImages.errorData?.response?.data as string;
    });
    const LoadingImageUpload = useSelector((state: RootState) => {
        return state.UploadArticleImages.loading;
    });

    const dispatch = useDispatch();
    const [isHovering, setIsHovering] = useState<null | number>();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const fileInputRefReplace = useRef<HTMLInputElement>(null);

    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                callback(reader.result as string);
            }
        };
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedPledge || !event.target.files) return;
        const files = Array.from(event.target.files);

        const newImages: {fileName: string; fileSize: number; formFile: string}[] = [];

        files.forEach((file, index) => {
            convertToBase64(file, (base64) => {
                const newImage = {fileName: file.name, fileSize: file.size, formFile: base64};
                newImages.push(newImage);

                if (index === files.length - 1) {
                    setImagePledges((prevState) => {
                        const pledgeIndex = prevState.findIndex(
                            (pledge) => pledge.pledgeId === selectedPledge.pledgeId
                        );
                        let updatedPledges;
                        if (pledgeIndex !== -1) {
                            updatedPledges = [...prevState];
                            updatedPledges[pledgeIndex] = {
                                ...updatedPledges[pledgeIndex],
                                photosPladges: [
                                    ...updatedPledges[pledgeIndex].photosPladges,
                                    ...newImages,
                                ],
                            };
                        } else {
                            updatedPledges = [
                                ...prevState,
                                {pledgeId: selectedPledge.pledgeId, photosPladges: newImages},
                            ];
                        }
                        handleUploadFinish(updatedPledges);
                        return updatedPledges;
                    });
                }
            });
        });
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!selectedPledge) return;

        const files = Array.from(event.dataTransfer.files);

        const newImages: {fileName: string; fileSize: number; formFile: string}[] = [];

        files.forEach((file, index) => {
            convertToBase64(file, (base64) => {
                const newImage = {fileName: file.name, fileSize: file.size, formFile: base64};
                newImages.push(newImage);

                if (index === files.length - 1) {
                    setImagePledges((prevState) => {
                        const pledgeIndex = prevState.findIndex(
                            (pledge) => pledge.pledgeId === selectedPledge.pledgeId
                        );
                        let updatedPledges;
                        if (pledgeIndex !== -1) {
                            updatedPledges = [...prevState];
                            updatedPledges[pledgeIndex] = {
                                ...updatedPledges[pledgeIndex],
                                photosPladges: [
                                    ...updatedPledges[pledgeIndex].photosPladges,
                                    ...newImages,
                                ],
                            };
                        } else {
                            updatedPledges = [
                                ...prevState,
                                {pledgeId: selectedPledge.pledgeId, photosPladges: newImages},
                            ];
                        }
                        handleUploadFinish(updatedPledges);
                        return updatedPledges;
                    });
                }
            });
        });
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleReplaceClick = (pledgeId: number, fileName: string) => {
        setImageToReplace({pledgeId, fileName});
        fileInputRefReplace.current?.click();
    };

    const replaceImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!imageToReplace || !event.target.files) return;
        const file = event.target.files[0];

        convertToBase64(file, (base64) => {
            setImagePledges((prevState) => {
                const updatedPledges = prevState.map((pledge) => {
                    if (pledge.pledgeId === imageToReplace.pledgeId) {
                        const updatedPhotos = pledge.photosPladges.map((photo) => {
                            if (photo.fileName === imageToReplace.fileName) {
                                return {fileName: file.name, fileSize: file.size, formFile: base64};
                            }
                            return photo;
                        });
                        return {...pledge, photosPladges: updatedPhotos};
                    }
                    return pledge;
                });

                handleUploadFinish(updatedPledges);

                return updatedPledges;
            });
        });
    };

    const removeImage = (pledgeId: number, fileName: string) => {
        setImagePledges((prevState) => {
            const updatedPledges = prevState
                .map((pledge) => {
                    if (pledge.pledgeId === pledgeId) {
                        const updatedPhotos = pledge.photosPladges.filter(
                            (photo) => photo.fileName !== fileName
                        );
                        if (updatedPhotos.length > 0) {
                            return {...pledge, photosPladges: updatedPhotos};
                        }
                        return null;
                    }
                    return pledge;
                })
                .filter((pledge) => pledge !== null) as ImagePledge[];
            return updatedPledges;
        });
        const currentImages =
            imagePledges.find((pledge) => pledge.pledgeId === pledgeId)?.photosPladges || [];
        setImagesRender(currentImages);
    };

    const handleUploadFinish = (updatedPledges: ImagePledge[]) => {
        const cleanedImagePledges = updatedPledges.map((imagePledge) => ({
            ...imagePledge,
            photosPladges: imagePledge.photosPladges.map((photo) => ({
                fileName: photo.fileName,
                formFile: photo.formFile.replace(/^data:image\/[a-z]+;base64,/, ''),
                fileSize: photo.fileSize,
            })),
        }));
        const lastAddedImage = updatedPledges.reduce((acc, curr) => {
            const lastImage = curr.photosPladges[curr.photosPladges.length - 1];
            return lastImage ? lastImage.formFile.replace(/^data:image\/[a-z]+;base64,/, '') : acc;
        }, '');
        if (lastAddedImage) {
            dispatch(
                postValidPrechrageimage(
                    token,
                    {image: lastAddedImage},
                    carShoppingId,
                    cleanedImagePledges,
                    setIsCheck,
                    isCheck
                )
            );
        }
    };

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="500">
                Subir archivo
            </Text>
            {LoadingImageUpload ? (
                <LoadingAtaskate />
            ) : selectedPledge &&
              imagesRender.length > 0 &&
              imagePledges.some((pledge) => pledge.pledgeId === selectedPledge.pledgeId) ? (
                <ContainerFlex Justify="start" Gap="1rem" FlexWrap="wrap">
                    {imagesRender &&
                        imagesRender.map((src, index) => (
                            <AddImgs
                                Border="1px solid #E5E5FF"
                                BRadius="16px"
                                HOpacity="1"
                                key={index}
                                Width="78px"
                                Height="56px"
                                onMouseEnter={() => setIsHovering(index)}
                                onMouseLeave={() => setIsHovering(null)}
                            >
                                <Image
                                    src={src.formFile}
                                    Width="78px"
                                    Height="56px"
                                    Radius="16px"
                                    ObjectFit="contain"
                                    style={{cursor: 'pointer'}}
                                />

                                {isHovering === index && (
                                    <ContainerFlex
                                        Position="absolute"
                                        PositionTop="0"
                                        PositionRight="0"
                                        Gap="4px"
                                        FlexDir="row"
                                    >
                                        <Image
                                            src={DeleteImage}
                                            Cursor="pointer"
                                            onClick={() => {
                                                if (selectedPledge)
                                                    removeImage(
                                                        selectedPledge.pledgeId,
                                                        src.fileName
                                                    );
                                            }}
                                        />
                                        <ContainerFlex
                                            Justify="center"
                                            Align="center"
                                            Width="auto"
                                            onClick={() =>
                                                handleReplaceClick(
                                                    selectedPledge.pledgeId,
                                                    src.fileName
                                                )
                                            }
                                        >
                                            <Image src={EditImage} Cursor="pointer" />
                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                style={{display: 'none'}}
                                                onChange={replaceImage}
                                                ref={fileInputRefReplace}
                                            />
                                        </ContainerFlex>
                                    </ContainerFlex>
                                )}
                            </AddImgs>
                        ))}
                    {imagesRender.length <= 5 && (
                        <ContainerFlex
                            Border="1px dashed #5A5AFF"
                            Radius="16px"
                            Width="56px"
                            Height="56px"
                            Padding="16px"
                            Cursor="pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                                ref={fileInputRef}
                            />
                            <Image src={AddImage} Width="24px" Height="24px" />
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            ) : (
                <ContainerFlex
                    Border={!ErrorImageUpload ? '1px dashed #5A5AFF' : '1px dashed #FF6357'}
                    Radius="16px"
                    Padding="1rem"
                    Cursor="pointer"
                    Gap="6px"
                    onClick={() => fileInputRef.current?.click()}
                    onDrop={onDrop}
                    onDragOver={handleDragOver}
                >
                    <input
                        type="file"
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        accept="image/png, image/jpeg"
                    />
                    <Image
                        src={!ErrorImageUpload ? AddImage : AddImageRed}
                        Width="24px"
                        Height="24px"
                    />
                    <Text wSpace="pre">
                        Arrastre elementos aquí o{' '}
                        <Text
                            Color={!ErrorImageUpload ? '#5A5AFF' : '#FF6357'}
                            FontWeight="500"
                            Cursor="pointer"
                        >
                            Busque archivos
                        </Text>
                    </Text>
                </ContainerFlex>
            )}
            {ErrorImageUpload ? (
                <Text Color="#FF6357" FontSize="0.875rem" wSpace="wrap">
                    {ErrorImageMessage}
                </Text>
            ) : (
                <Text Color="#54575C" FontSize="0.875rem" wSpace="wrap">
                    Hasta 10 MB
                </Text>
            )}
        </ContainerFlex>
    );
};
