import {OPTIONS_MENU} from '@/components/CashFlow/Expenses/constants';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import {Anchor, ContainerFlex, LinkAtaskate, Text} from '@/components/Shopify/Ecommerce/styles';
import DownloadImg from '@images/download.svg';
import VisivilityImg from '@images/visibility.svg';
import React from 'react';

interface ExpensesMenuProps {
    downloadInvoice: React.MouseEventHandler<HTMLAnchorElement>;
    file: string;
    openExpenseDetails: React.MouseEventHandler<HTMLElement>;
}

const ExpensesMenu: React.FC<ExpensesMenuProps> = ({file, downloadInvoice, openExpenseDetails}) => {
    return (
        <ContainerFlex
            FlexDir="Column"
            Position="absolute"
            FontSize="1rem"
            Gap="5px"
            Height="6.2rem"
            Width="12rem"
            Margin="10px 210px 0px 0px"
            backG="white"
            Align="start"
            BoxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            ZIndex="10"
            BoxS="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
            Radius="15px"
            Padding="7px"
        >
            <LinkAtaskate to="/CashFlow/ExpenseDetails">
                <Text
                    BGColor="none"
                    ZIndex="5"
                    Cursor="pointer"
                    Gap="5px"
                    onClick={openExpenseDetails}
                >
                    <ImageIcon src={VisivilityImg} />
                    {OPTIONS_MENU.details}
                </Text>
            </LinkAtaskate>

            <Anchor
                cursor="pointer"
                color="2A2C2F"
                textDecoration="none"
                href={file}
                onClick={downloadInvoice}
                download
            >
                <ImageIcon src={DownloadImg} width="1.6rem" />
                {OPTIONS_MENU.download}
            </Anchor>
        </ContainerFlex>
    );
};

export default ExpensesMenu;
