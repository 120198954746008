import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {NEW_PASSWORD} from '@/components/MarketForgottenLogin/Operational/contants';

export const ErrorUser = () => {
    return (
        <ContainerFlex Height="auto" ColumnGap="4px" Align="center" Justify="center" Width="325px">
            <ContainerFlex Justify="center" Align="center" Gap="2px" ColumnGap="4px">
                <Text
                    Color="#FF6357"
                    FontSize="0.75rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                >
                    {NEW_PASSWORD.CODE_VALIDE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
