import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@/components/Quoter/styles';
import {ButtonPager} from '@components/Users/styles';
import {IPageRolesNumber} from '@components/Roles/interface';

export const RolesPage: React.FC<IPageRolesNumber> = ({pageNumber, setPageNumber}) => {
    const pageSize = useSelector(
        (state: RootState) => state.GetRolesInformation.pageSize as number
    );
    const totalItems = useSelector(
        (state: RootState) => state.GetRolesInformation.totalItems as number
    );
    const totalPages = useSelector(
        (state: RootState) => state.GetRolesInformation.totalPages as number
    );

    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);

    const firstPage = () => setPageNumber(1);

    const handlePreviousPage = () => setPageNumber(pageNumber - 1);

    const handleNextPage = () => setPageNumber(pageNumber + 1);

    const lastPage = () => setPageNumber(totalPages);

    return (
        <ContainerFlex Gap="0.5rem" Bt="1px solid #E8E9EA" Height="2.5rem">
            <IconSvg
                onClick={firstPage}
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                Cursor={pageNumber === 1 ? 'not-allowed' : 'pointer'}
            >
                <path
                    d="M4.5 12V4H5.83333V12H4.5ZM11.8333 12L7.83333 8L11.8333 4L12.7667 4.93333L9.7 8L12.7667 11.0667L11.8333 12Z"
                    fill="#5A5AFF"
                />
            </IconSvg>

            <ButtonPager disabled={pageNumber === 1} onClick={handlePreviousPage}>
                <IconSvg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                >
                    <path
                        d="M10.2327 3.99994L11.166 4.93327L8.09935 7.99994L11.166 11.0666L10.2327 11.9999L6.23268 7.99994L10.2327 3.99994Z"
                        fill="#5A5AFF"
                    />
                </IconSvg>
            </ButtonPager>
            <Text Color="#71767D" FontSize="1rem">
                {startIndex + 1} - {endIndex} de {totalItems}
            </Text>
            <ButtonPager disabled={endIndex === totalItems}>
                <IconSvg
                    onClick={handleNextPage}
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                >
                    <path
                        d="M6.76732 12L5.83398 11.0667L8.90065 8L5.83398 4.93333L6.76732 4L10.7673 8L6.76732 12Z"
                        fill="#5A5AFF"
                    />
                </IconSvg>
            </ButtonPager>

            <IconSvg
                Cursor={pageNumber === totalPages ? 'not-allowed' : 'pointer'}
                onClick={() => lastPage()}
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
            >
                <path
                    d="M5.16576 12L4.23242 11.0667L7.29909 8L4.23242 4.93333L5.16576 4L9.16576 8L5.16576 12ZM11.1658 12V4H12.4991V12H11.1658Z"
                    fill="#5A5AFF"
                />
            </IconSvg>
        </ContainerFlex>
    );
};
