import React from 'react';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';
import {ISelectedBranches} from '@/components/Branches/interfaces';

export const SelectedBranches = ({dataBranches}: ISelectedBranches) => {
    return (
        <ContainerFlex
            Justify="flex-start"
            Align="flex-start"
            FlexDir="column"
            Width="300px"
            Padding="16px 0 0 16px"
        >
            {dataBranches.map((branch, index) => (
                <ContainerFlex key={index} Height="30px" ColumnGap="4px">
                    <Checkbox checked={branch.status} disabled />
                    <ContainerFlex
                        FlexDir="column"
                        Align="flex-start"
                        Padding="0px 8px"
                        Width="200px"
                    >
                        <TextEllipsis FontSize="0.875rem" Color="#6d6e71" FontWeight="400">
                            {branch.branchName || branch.destinationBranchName}
                        </TextEllipsis>
                    </ContainerFlex>
                    <Text FontSize="0.625rem" Width="45px" Justify="flex-end" Color="#6d6e71">
                        {`${branch.km}km`}
                    </Text>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};
