import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';

import {RootState} from '@/config/store';
import {schema} from '@components/Login/PasswordForgotAdmin/ValidateYupMail';
import {yupResolver} from '@hookform/resolvers/yup';
import Logo from '@images/logoAtaskate.svg';
import LogoFox from '@images/AtaskateFox.svg';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerForm,
    Input,
    Text,
} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {PrivacityContainer} from '@/components/Login/Administrative/Privacity';
import {IDataLoginMail} from '@components/Login/Administrative/interfaces';
import {MailPasswordSuccess} from '@components/Login/PasswordForgotAdmin/MailPasswordSuccess';
import {MAILVALIDATE} from '@components/Login/constants';
import {
    postResendEmailValidation,
    resetResendEmailValidation,
} from '@/components/Login/Redux/Actions/ResendEmailValidation';
import {ImageLogo} from '@Login/style';

export const VerifyMailPassword = () => {
    const {
        handleSubmit,
        register,
        watch,
        formState: {errors},
    } = useForm<IDataLoginMail>({
        defaultValues: {
            Email: '',
            version: 1,
        },
        resolver: yupResolver(schema),
    });
    const [emailSuccess, setEmailSuccess] = useState(false);
    const dispatch = useDispatch();
    const errorStatus = useSelector(
        (state: RootState) => state.ResendEmailValidation.errorData?.request?.status
    );
    const onSubmit = (value: IDataLoginMail) => {
        dispatch(postResendEmailValidation(value, setEmailSuccess));
    };
    const emailValue = watch('Email');
    useEffect(() => {
        dispatch(resetResendEmailValidation());
    }, []);
    return (
        <ContainerFlex FlexDir="column" Height="100vh" backG="#ACACFF">
            {emailSuccess ? (
                <MailPasswordSuccess />
            ) : (
                <ContainerForm FlexDir="column" onSubmit={handleSubmit(onSubmit)} BackG="none">
                    <ContainerFlex
                        backG="#FFFFFF"
                        FlexDir="column"
                        Width="31.25rem"
                        Height="auto"
                        Radius="1.5rem"
                        Padding="1.5rem 2.5rem"
                        Gap="1rem"
                    >
                        <ImageLogo src={Logo} alt="logo" />
                        <ImgLocation
                            Width="7.875rem"
                            Height="8.577rem"
                            src={LogoFox}
                            alt="logofox"
                            Margin="1.5rem 0 0 0"
                        />
                        <Text FontWeight="500" Color="#54575C">
                            {MAILVALIDATE.TITLE}
                        </Text>
                        <Text FontSize="0.875rem" Color="#54575C">
                            {MAILVALIDATE.SUBTITLE}
                        </Text>
                        <ContainerCellGrid FlexDir="column" Align="start" Height="auto">
                            <Text FontWeight="500" FontSize="0.75rem" Color="#54575C">
                                Correo electrónico
                            </Text>
                            <Input Width="100%" Margin="0 0 8px 0" {...register('Email')} />
                            {errors.Email?.message && (
                                <Text
                                    Color="#ef4f55"
                                    FontSize="0.75rem"
                                    Align="start"
                                    wSpace="normal"
                                    Height="auto"
                                    FontWeight="400"
                                >
                                    {errors.Email.message}
                                </Text>
                            )}
                            {!errors.Email && errorStatus === 400 && (
                                <Text
                                    Color="#5A5AFF"
                                    FontSize="0.75rem"
                                    Align="start"
                                    wSpace="normal"
                                    Height="auto"
                                    FontWeight="400"
                                >
                                    Correo electrónico no encontrado. Por favor, verifica y
                                    reintenta.
                                </Text>
                            )}
                        </ContainerCellGrid>
                        <ButtonGeneral
                            width="100%"
                            text="Enviar enlace"
                            type="submit"
                            height="40px"
                            cursor="pointer"
                            disabled={emailValue.length <= 0}
                        />
                        <PrivacityContainer />
                    </ContainerFlex>
                </ContainerForm>
            )}
        </ContainerFlex>
    );
};
