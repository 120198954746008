import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    TOGGLE_FILTERS_MENU,
    SET_FILTERS_VALUES,
    SET_FILTERS_LIMITS_START,
    SET_FILTERS_LIMITS_SUCCESS,
    SET_FILTERS_LIMITS_ERROR,
    CLEAR_FILTERS_VALUES,
    GET_SEARCH_CLIENT_START,
    GET_SEARCH_CLIENT_SUCCESS,
    GET_SEARCH_CLIENT_ERROR,
    SET_SEARCHTERM,
    SET_SEARCHTERM_CLEAR,
    GET_PERSONAL_LOANS_BY_FILTERS_START,
    GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS,
    GET_PERSONAL_LOANS_BY_FILTERS_ERROR,
    SET_FILTERS_VISUAL_STATUS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IFilterValues, ISearchParamsFilter} from '@components/CreditsAndPawns/interfaces';
import QueryString from 'qs';
import {formatDate} from '@components/CreditsAndPawns/FilterModal/FilterDatePicker';

export const toggleFiltersMenu = () => {
    return {
        type: TOGGLE_FILTERS_MENU,
    };
};

export const setFiltersLimitsStart = () => {
    return {
        type: SET_FILTERS_LIMITS_START,
    };
};

export const setFiltersLimitsSuccess = (result: AxiosResponse) => {
    return {
        type: SET_FILTERS_LIMITS_SUCCESS,
        payload: result,
    };
};

export const setFiltersLimitsError = (error: AxiosError) => {
    return {
        type: SET_FILTERS_LIMITS_ERROR,
        error: error,
    };
};

export const setFiltersValues = (filtersValues: IFilterValues) => {
    return {
        type: SET_FILTERS_VALUES,
        filtersValues,
    };
};

export const setFiltersVisualStatus = (filtersValues: IFilterValues) => {
    return {
        type: SET_FILTERS_VISUAL_STATUS,
        filtersValues,
    };
};

export const clearFiltersValues = () => {
    return {
        type: CLEAR_FILTERS_VALUES,
    };
};

export const getSearchClientStart = () => {
    return {
        type: GET_SEARCH_CLIENT_START,
    };
};

export const getSearchClientSuccess = (result: AxiosResponse) => {
    return {
        type: GET_SEARCH_CLIENT_SUCCESS,
        payload: result,
    };
};

export const getSearchClientError = (error: AxiosError) => {
    return {
        type: GET_SEARCH_CLIENT_ERROR,
        error: error,
    };
};

export const setSearchTerm = (searchTerm: string) => {
    return {
        type: SET_SEARCHTERM,
        searchTerm,
    };
};

export const setSearchTermClear = () => {
    return {
        type: SET_SEARCHTERM_CLEAR,
    };
};

export const getPersonalLoansByFiltersStart = () => {
    return {
        type: GET_PERSONAL_LOANS_BY_FILTERS_START,
    };
};

export const getPersonalLoansByFiltersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_PERSONAL_LOANS_BY_FILTERS_SUCCESS,
        payload: result,
    };
};

export const getPersonalLoansByFiltersError = (error: AxiosError) => {
    return {
        type: GET_PERSONAL_LOANS_BY_FILTERS_ERROR,
        error: error,
    };
};

export const getDataPersonalLoadFilters = (token: string, companyId: number, estadoId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(setFiltersLimitsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDataPersonalLoadFilters}`,
                {headers, params: {companyId, estadoId}}
            );
            dispatch(setFiltersLimitsSuccess(response));
        } catch (error) {
            dispatch(setFiltersLimitsError(error as AxiosError));
        }
    };
};

export const getSearchClient = (
    token: string,
    companyId: number,
    search: string,
    status: number
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSearchClientStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetListLoansSearch}`,
                {headers, params: {companyId, search, status}}
            );
            dispatch(getSearchClientSuccess(response));
        } catch (error) {
            dispatch(getSearchClientError(error as AxiosError));
        }
    };
};

export const getPersonalLoansByFilters = (
    token: string,
    companyId: number,
    statusId: number,
    filtersValues: ISearchParamsFilter
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPersonalLoansByFiltersStart());
        const params: ISearchParamsFilter = {
            ...filtersValues,
            companyId,
            statusId,
            requestedDateStart: formatDate(filtersValues.requestedDateStart as Date),
            requestedDateEnd: formatDate(filtersValues.requestedDateEnd as Date),
            paymentDateStart: formatDate(filtersValues.paymentDateStart as Date),
            paymentDateEnd: formatDate(filtersValues.paymentDateEnd as Date),
        };
        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([key, value]) => value !== null && value !== '')
        );
        const options = {
            paramsSerializer: (params: unknown) => {
                return QueryString.stringify(params, {arrayFormat: 'repeat'});
            },
        };

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPersonalLoansByFilters}`,
                {
                    headers,
                    params: filteredParams,
                    ...options,
                }
            );
            dispatch(getPersonalLoansByFiltersSuccess(response));
            dispatch(
                setFiltersVisualStatus({
                    ...filtersValues,
                    requestedDateStart: filtersValues.requestedDateStart as Date,
                    requestedDateEnd: filtersValues.requestedDateEnd as Date,
                    paymentDateStart: filtersValues.paymentDateStart as Date,
                    paymentDateEnd: filtersValues.paymentDateEnd as Date,
                })
            );
        } catch (error) {
            dispatch(getPersonalLoansByFiltersError(error as AxiosError));
        }
    };
};
