import {AppDispatch} from '@/config/store';
import {
    MODULES_ADMINISTRATOR,
    MODULES_ADMINISTRATOR_SUCCESS,
    MODULES_ADMINISTRATOR_ERROR,
    MODULES_EMPLOYEE,
    MODULES_EMPLOYEE_SUCCESS,
    MODULES_EMPLOYEE_ERROR,
} from '@components/SideBarMenu/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

export const modulesAdmistrator = () => {
    return {
        type: MODULES_ADMINISTRATOR,
    };
};
export const modulesAdmistratorSuccess = (result: AxiosResponse) => {
    return {
        type: MODULES_ADMINISTRATOR_SUCCESS,
        payload: result,
    };
};
export const modulesAdmistratorError = (error: AxiosError) => {
    return {
        type: MODULES_ADMINISTRATOR_ERROR,
        error: error,
    };
};

export function itemsModulesAdmistrator(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(modulesAdmistrator());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ModulesAdministrator}`,
                {
                    headers,
                }
            );

            dispatch(modulesAdmistratorSuccess(response));
        } catch (error) {
            dispatch(modulesAdmistratorError(error as AxiosError));
        }
    };
}

export const modulesEmployeeStart = () => {
    return {
        type: MODULES_EMPLOYEE,
    };
};
export const modulesEmployeeSuccess = (result: AxiosResponse) => {
    return {
        type: MODULES_EMPLOYEE_SUCCESS,
        payload: result,
    };
};
export const modulesEmployeeError = (error: AxiosError) => {
    return {
        type: MODULES_EMPLOYEE_ERROR,
        error: error,
    };
};

export function itemsModulesEmployee(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(modulesEmployeeStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetModulesEmployee}`,
                {
                    headers,
                }
            );

            dispatch(modulesEmployeeSuccess(response));
        } catch (error) {
            dispatch(modulesEmployeeError(error as AxiosError));
        }
    };
}
