import React from 'react';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import PersonalLoanApprovedHeader from '@/components/PersonalLoans/PersonalLoanApproved/PersonalLoanApprovedHeader';
import PersonalLoanApprovedBody from '@/components/PersonalLoans/PersonalLoanApproved/PersonalLoanApprovedBody';
import ataskateIcon from '@/components/PersonalLoans/icons/ataskateIcon.svg';

const PersonalLoanApproved = () => {
    return (
        <ContainerFlex
            Height="auto"
            MinH="100%"
            Width="100%"
            FlexDir="column"
            backG="#E5E5FF"
            Justify="space-between"
            Align="center"
            Padding="33px 59px 15px 59px"
        >
            <ContainerFlex Justify="center" Align="center" Height="100%" Width="100%">
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    Radius="24px"
                    BoxShadow="0px 4px 8px 0px #E4CCFF"
                    Padding="24px"
                    Gap="16px"
                    backG="#FFF"
                    FlexDir="column"
                    Height="100%"
                    Width="100%"
                    BoxS="0px 4px 8px 0px #e4ccff26"
                >
                    <PersonalLoanApprovedHeader />
                    <PersonalLoanApprovedBody />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="end" Align="end" Height="100%" Width="100%">
                <Image src={ataskateIcon} Width="216px" />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default PersonalLoanApproved;
