import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import InfoBlock from '@/components/Shopify/ClientProfile/Information/InfoBlock';
import {ADDRESS_INFORMATION} from '@Shopify/ClientProfile/constants';

const AddressInfo = () => {
    const clientDetails = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.addressInformation
    );
    return (
        <ContainerFlex
            Padding="1rem 1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1rem"
            Radius="1.5rem"
            Border="1px solid #E8E9EA"
            Self="stretch"
        >
            <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                {ADDRESS_INFORMATION.TITLE}
            </Text>
            <ContainerFlex
                Align="flex-start"
                Justify="start"
                FlexDir="column"
                AlignContent="flex-start"
                Gap="1rem"
                FlexWrap="nowrap"
            >
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={ADDRESS_INFORMATION.STREET}
                        value={clientDetails?.street}
                        width="24rem"
                    />
                    <ContainerFlex
                        Width="24rem"
                        Align="flex-start"
                        Justify="flex-start"
                        Gap="0.5rem"
                    >
                        <InfoBlock
                            label={ADDRESS_INFORMATION.EXTERIOR_NO}
                            value={clientDetails?.outerNumber}
                        />
                        <InfoBlock
                            label={ADDRESS_INFORMATION.INTERIOR_NO}
                            value={clientDetails?.interiorNumber}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={ADDRESS_INFORMATION.POSTAL_CODE}
                        value={clientDetails?.postCode}
                        width="24rem"
                    />
                    <InfoBlock
                        label={ADDRESS_INFORMATION.COLONY}
                        value={clientDetails?.colony}
                        width="24rem"
                    />
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={ADDRESS_INFORMATION.MUNICIPALITY}
                        value={clientDetails?.municipaly}
                        width="24rem"
                    />
                    <InfoBlock
                        label={ADDRESS_INFORMATION.STATE}
                        value={clientDetails?.state}
                        width="24rem"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AddressInfo;
