import React, {useEffect} from 'react';

import {AppDispatch} from '@/config/store';
import {useDispatch} from 'react-redux';
import {Close} from '@components/ManageUser/Icons/Close';
import IconAlert from '@components/ManageUser/Icons/IconAlert.svg';
import {ContainerFlex, ContainerIcon, Figure, Image, Text} from '@Shopify/Ecommerce/styles';

export const MessageAlertUploadImage = ({
    setViewAlertMessage,
}: {
    setViewAlertMessage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            setViewAlertMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [dispatch]);
    return (
        <ContainerFlex
            Width="35%"
            Height="auto"
            Justify="space-evenly"
            FlexWrap="wrap"
            backG="#fff"
            Radius="4px"
            Border="solid 1px #ffbd00"
            BoxS="4px 4px 4px 0 rgba(255, 189, 0, 0.3)"
            Position="absolute"
            Padding="4px"
            PositionLeft="32%"
            PositionTop="36%"
        >
            <Figure>
                <Image src={IconAlert} />
            </Figure>
            <Text
                Height=""
                FontSize="0.875rem"
                Color="#414042"
                TextAlign="start"
                Width="75%"
                wSpace="normal"
            >
                La imagen debe ser jpeg o png y no debe pasar los 5Mb
            </Text>
            <ContainerIcon onClick={() => setViewAlertMessage(false)}>
                <Close />
            </ContainerIcon>
        </ContainerFlex>
    );
};
