import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Image, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import React, {useEffect} from 'react';
import {AMORTIZATION_TABLE} from '@components/CreditQuoter/constants';
import closeIcon from '@images/close.svg';
import {
    IAmortizationTableProps,
    IFiltersAmortizationTable,
} from '@components/CreditQuoter/interfaces';
import {AppDispatch, RootState} from '@/config/store';
import {getTableAmortization} from '@components/CreditQuoter/Redux/Actions/GetTableAmortizationActions';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {amortizationTableLayoutData} from '@/components/PersonalLoans/constants';
import {AmortizationTable} from '@/components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/AmortizationTable';

export const AmortizationTableQuoter = ({
    showTable,
    setShowTable,
    ammountRequested,
    periodsNumber,
    frequencyTermCreditId,
}: IAmortizationTableProps) => {
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        if (token) {
            const filters: IFiltersAmortizationTable = {
                ammountRequested,
                PeriodsNumber: periodsNumber,
                FrecuencyTermId: frequencyTermCreditId,
            };
            dispatch(getTableAmortization(token, filters));
        }
    }, [token, ammountRequested, periodsNumber, frequencyTermCreditId]);
    const tableLoading: boolean = useSelector(
        (state: RootState) => state.getTableAmortization.loading
    );

    return (
        <>
            <Modal>
                <ContainerFlex
                    Width="777px"
                    Height="max-content"
                    MaxH="440px"
                    Padding="1.5rem"
                    FlexDir="column"
                    Justify="start"
                    Radius="1.5rem"
                    Gap="1rem"
                    BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    backG="#FFF"
                    Border="1px solid #E8E9EA"
                    OverFlow="hidden"
                >
                    <ContainerFlex Justify="space-between">
                        <Text
                            Width="560px"
                            Height="16px"
                            Color="#1D1E20"
                            FontSize="1.25rem"
                            FontWeight="700"
                        >
                            {AMORTIZATION_TABLE.TITLE}
                        </Text>
                        <Image
                            src={closeIcon}
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            onClick={() => setShowTable(!showTable)}
                        />
                    </ContainerFlex>

                    {tableLoading ? (
                        <ContainerFlex>
                            <Loading />
                        </ContainerFlex>
                    ) : (
                        <AmortizationTable tableData={amortizationTableLayoutData} error={false} />
                    )}
                </ContainerFlex>
            </Modal>
        </>
    );
};
