export const BRANCH_STATE = 'BRANCH_STATE';
export const AMOUNT_SOL_STATE = 'AMOUNT_SOL_STATE';
export const AMOUNT_FI_STATE = 'AMOUNT_FI_STATE';
export const ACCTION_STATE = 'ACCTION_STATE';
export const SIZE_GRID = 'SIZE_GRID';
export const BUTTON_STATE = 'BUTTON_STATE';
export const SPCACE = 'SPCACE';
export const BRANCH_ID = 'BRANCH_ID';
export const CONTAINERS_VIEW = 'CONTAINERS_VIEW';
export const REQUESTID = 'REQUESTID';
export const REQUEST_ID_OPEN = 'REQUEST_ID_OPEN';
export const REQUEST_STATUS = 'REQUEST_STATUS';

export const INITIAL_DATE = 'INIIAL_DATE';
export const END_DATE = 'END_DATE';
export const LASTED_DATE = 'LASTED_DATE';
export const STATUS_FILTER = 'STATUS_FILTER';

export const SOL_STATE_LABEL = 'SOL_STATE_LABEL';
export const EXCESS_STATE_LABEL = 'EXCESS_STATE_LABEL';
export const FILTERREQUEST = 'FILTERREQUEST';
export const EXCESSFILTER = 'EXCESSFILTER';

export const FILTERS = 'FILTERS';
export const RESET_TABLE_FILTERS = 'RESET_TABLE_FILTERS';

export const SOL_STATEMENT = 'SOL_STATEMENT';
export const RESET_VIEW_GRIDS = 'RESET_VIEW_GRIDS';

export const SAVED_REQUEST_ID_FILTER = 'SAVED_REQUEST_ID_FILTER';
export const SAVED_REQUEST_EXCESS_FILTER = 'SAVED_REQUEST_EXCESS_FILTER';

export const ALL_REQUEST = 'ALL_REQUEST';
export const ALL_EXCESS = 'ALL_EXCESS';
