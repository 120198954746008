import React from 'react';
import {DetailsReactivations} from '@Reactivations/DetailsReactivations';
import useMediaQuery from '@mui/material/useMediaQuery';
export function ReactivationScreen() {
    const mobileSize = useMediaQuery('(max-width: 770px');
    return (
        <>
            <div>{mobileSize ? <DetailsReactivations /> : null}</div>
        </>
    );
}
