import {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_CHARACTERISTIC_DETAILS_ACTION,
    LIST_CHARACTERISTIC_DETAILS_ERROR,
    LIST_CHARACTERISTIC_DETAILS_START,
    LIST_CHARACTERISTIC_DETAILS_SUCCESS,
    LIST_CHARACTERISTIC_RESET,
    LIST_DINAMICS_ADD,
    LIST_HEREDITARY_ADD,
} from '@components/Articles/Redux/Types';
import {
    DataCharacteristicDynamic,
    DataCharacteristicsInheriteds,
    GetDetailsprops,
} from '@components/Articles/Redux/interfaces';

const initialState: GetDetailsprops = {
    dataDetails: null,
    loading: false,
    error: false,
    errorData: null,
    actionType: 0,
    dataDinamic: null,
    dataHereditary: null,
};

const GetDetailsCharacteristics = (
    state: GetDetailsprops = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
        value: number;
        date: DataCharacteristicDynamic[];
        item: DataCharacteristicsInheriteds[];
    }
) => {
    switch (action.type) {
        case LIST_CHARACTERISTIC_DETAILS_START:
            return {...state, loading: true, error: false};
        case LIST_CHARACTERISTIC_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dataDetails: action.payload.data.data.articleDetails,
            };
        case LIST_CHARACTERISTIC_DETAILS_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case LIST_CHARACTERISTIC_DETAILS_ACTION:
            return {...state, loading: false, error: false, actionType: action.value};
        case LIST_DINAMICS_ADD:
            return {...state, loading: false, error: false, dataDinamic: action.date};
        case LIST_HEREDITARY_ADD:
            return {...state, loading: false, error: false, dataHereditary: action.item};
        case LIST_CHARACTERISTIC_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetDetailsCharacteristics;
