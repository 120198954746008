import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import arrowChange from '@images/arrowTopLeft.svg';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';

export const TotalCount = () => {
    const summary = useSelector((state: RootState) => state.cartPaymentReducer);
    const summaryContracts = summary.paymentResult && summary.paymentResult.data;
    return (
        <>
            <FlexPanel Width="100%">
                <ContainerFlex Align="start" FlexDir="column" Gap="0.5rem">
                    <Text Color="#2A2C2F">{SHOPPING_CART.SUB_TOTAL}</Text>
                    <Text Color="#2A2C2F">{SHOPPING_CART.IVA_RESUME}</Text>
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="700">
                        {SHOPPING_CART.TOTAL}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="end" FlexDir="column" Gap="0.5rem">
                    <Text Color="#2A2C2F">-{formatterDolar.format(summaryContracts.subTotal)}</Text>
                    <Text Color="#2A2C2F">{formatterDolar.format(summaryContracts.iva)}</Text>
                    <Text FontSize="1.25rem" FontWeight="700" Color="#2A2C2F">
                        -{formatterDolar.format(summaryContracts.total)}
                    </Text>
                </ContainerFlex>
            </FlexPanel>
            <FlexPanel Width="100%" Bb="1px solid #E8E9EA" Bt="1px solid #E8E9EA" Padding="1rem 0">
                <ContainerFlex Align="start" FlexDir="column" Gap="1rem">
                    <Text Color="#2A2C2F" Padding="0.5rem">
                        {SHOPPING_CART.PAY_CREDIT}
                    </Text>
                    <Text Color="#2A2C2F" Padding="0.5rem">
                        {SHOPPING_CART.PAY_CASH}
                    </Text>

                    {summaryContracts &&
                        summaryContracts.endorsementsPay &&
                        summaryContracts.endorsementsPay.length > 0 && (
                            <Text Color="#2A2C2F" Padding="0.5rem">
                                <Image
                                    src={arrowChange}
                                    alt="arrow-change"
                                    Width="0.938rem"
                                    Height="1.063rem"
                                    Padding="0.188rem 0.25rem"
                                />
                                {SHOPPING_CART.CHANGE}
                            </Text>
                        )}
                </ContainerFlex>
                <ContainerFlex Align="end" FlexDir="column" Gap="1rem">
                    <Text Color="#2A2C2F" Padding="0.5rem">
                        {formatterDolar.format(summaryContracts.cardPayment)}
                    </Text>
                    <Text Color="#2A2C2F" Padding="0.5rem">
                        {formatterDolar.format(summaryContracts.cashPayment)}
                    </Text>
                    {summaryContracts &&
                        summaryContracts.endorsementsPay &&
                        summaryContracts.endorsementsPay.length > 0 && (
                            <Text Color="#2A2C2F" FontWeight="700" Padding="0.5rem">
                                {formatterDolar.format(summaryContracts.changePayment)}
                            </Text>
                        )}
                </ContainerFlex>
            </FlexPanel>
        </>
    );
};
