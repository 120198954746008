import React from 'react';
import {Rating} from '@mui/material';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {Avatar, ContainerFlex, ContainerInfo, Text} from '@EcommerceShopify/styles';
import {homeClient} from '@Endeavors/redux/interfaces';

export function InfoContainer(props: {information?: boolean}) {
    const {information} = props;
    function formatPhoneNumber(value: string) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
            6,
            10
        )}`;
    }
    const homeclient: homeClient = useSelector((state: RootState) => {
        return state.homeclient;
    });

    return (
        <ContainerInfo Border={information || !information ? '' : '1px solid #e4e7e9'}>
            <ContainerFlex FlexDir="column" Width="12%">
                <Avatar src={homeclient.infoclient.urlImage} alt="Avatar" />
                <Rating
                    name="read-only"
                    value={homeclient.infoclient !== null ? homeclient.infoclient.stars : 5}
                    size="small"
                    readOnly
                />
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Width="28%" FlexDir="column">
                <Text
                    Transform="capitalize"
                    FontWeight="400"
                    FontSize="1.125rem"
                    Width="100%"
                    Justify="start"
                    Color="#4A4A4A"
                >
                    {homeclient.infoclient.clientName}
                </Text>
                <Text
                    FontWeight="300"
                    FontSize="0.875rem"
                    Width="100%"
                    Justify="start"
                    Color="#4A4A4A"
                >
                    NUC:
                    <Text FontWeight="400" FontSize="0.875rem" Color="#414042" Padding="0 8px">
                        {homeclient.infoclient.nuc}
                    </Text>
                </Text>

                <Text
                    FontWeight="300"
                    FontSize="0.875rem"
                    Color="#4A4A4A"
                    Width="100%"
                    Justify="start"
                >
                    Nacionalidad:
                    <Text
                        FontWeight="normal"
                        FontSize="1rem"
                        smFontWeight="300"
                        smHeight="1rem"
                        smFontSize="14px"
                        Color="#414042"
                        Padding="0 8px"
                    >
                        {homeclient.infoclient.nationality}
                    </Text>
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="center" Width="34%" Justify="end" Gap="6px">
                <Text
                    FontWeight="300"
                    Color="#414042"
                    Width="100%"
                    Justify="start"
                    FontSize="0.875rem"
                >
                    Telefonos:{' '}
                    <Text FontWeight="400" FontSize="0.875rem" Color="#414042" Padding="0 8px">
                        {formatPhoneNumber(homeclient.infoclient.phone)}
                    </Text>
                </Text>

                <Text
                    FontWeight="300"
                    FontSize="0.875rem"
                    Color="#414042"
                    Width="100%"
                    Justify="start"
                >
                    e-mail:
                    <Text FontSize="0.875rem" Color="#414042" Padding="0 8px">
                        {homeclient.infoclient.email}
                    </Text>
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="center" Justify="end" Gap="6px" Width="24%">
                <Text FontWeight="300" FontSize="0.875rem" Width="100%" Justify="start">
                    No. de Apartados:
                    <Text FontWeight="400" FontSize="0.875rem" Padding="0 0 0 8px">
                        {homeclient.infoclient.apartsNumber}
                    </Text>
                </Text>

                <Text FontWeight="300" FontSize="0.875rem" Width="100%" Justify="start">
                    No. de Empeños:
                    <Text FontWeight="400" FontSize="0.875rem" Padding="0 0 0 10px">
                        {homeclient.infoclient.pawnsNumber}
                    </Text>
                </Text>
            </ContainerFlex>
        </ContainerInfo>
    );
}
