import React from 'react';

export function HeadTablePurity() {
    return (
        <thead className="title-head-foundry">
            <tr>
                <th className="text-center">No. Fundición</th>
                <th className="text-center">Fecha</th>
                <th className="text-center">Lingotes</th>
                <th className="text-center">Precio Dolar</th>
                <th className="text-center">Precio Oro</th>
                <th className="text-center">Gramos</th>
                <th className="text-center">Costo total</th>
                <th className="text-center">Pureza Promedio</th>
                <th className="text-center">Gramos oro </th>
                <th className="text-center">Onzas</th>
                <th className="text-center">Subtotal</th>
                <th className="text-center">% Comisión</th>
                <th className="text-center">Comisión</th>
                <th className="text-center">Total</th>
                <th className="text-center">Ganancia</th>
                <th className="text-center">Estatus</th>
            </tr>
        </thead>
    );
}
