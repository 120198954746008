import {
    CHANGE_SCREEN,
    GET_USER_DATA,
    RESET_LOGIN_MARKET,
} from '@components/Login/Redux/Types/Types';
import {IUserPost} from '@components/Login/interfaces';

export const getScreenState = (data: number) => {
    return {
        type: CHANGE_SCREEN,
        payload: data,
    };
};

export const getUserData = (data: IUserPost) => {
    return {
        type: GET_USER_DATA,
        payload: data,
    };
};

export const resetMarketData = () => {
    return {
        type: RESET_LOGIN_MARKET,
    };
};
