import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Text, ContainerFlex} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {GETREQUESTTABLE} from '@components/Articles/CatalogRequestTable/Redux/getHighLevelRequestsActions';
import {ReviewReject} from '@components/Articles/CatalogRequestTable/ReviewReject';
import {RequestTablePager} from '@components/Articles/CatalogRequestTable/RequestTablePager';
import {DownloadLink} from '@components/Shopify/Endeavors/styles';
import {tableTitle} from '@components/Articles/CatalogRequestTable/constants';
import {ITableRequests} from '@components/Articles/CatalogRequestTable/interfaces';
import {RequestTableText} from '@components/Articles/CatalogRequestTable/constants';

export const RequestTable = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [openOptions, setOpenOptions] = useState<ITableRequests | null>(null);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const dispatch = useDispatch();

    const requestCatalogTable = () => {
        if (token)
            dispatch(
                GETREQUESTTABLE(token, {
                    pageSize: 8,
                    pageNumber: pageNumber,
                })
            );
    };

    useEffect(() => {
        requestCatalogTable();
    }, [pageNumber]);

    const dataRequestTable: ITableRequests[] | undefined = useSelector(
        (state: RootState) => state.getRequestsTable.data
    );

    const handlePager = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };
    const handleOptionClick = (request: ITableRequests) => {
        setOpenOptions((prevOption) => (prevOption === request ? null : request));
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #F4F5F5"
            Width="100%"
            Padding="1rem"
            backG="#FFF"
            BoxS="0px 0px 2px 0px rgba(167, 169, 172, 0.25)"
            Height="auto"
        >
            <Text
                Color="#2A2C2F"
                FontSize="1.25rem"
                FontWeight="500"
                Self="start"
                oFlow="visble"
                MarginB="1rem"
            >
                {RequestTableText.Requests}
            </Text>

            <ContainerFlex
                Gap="1rem"
                Justify="start"
                Display="grid"
                GridColumns="repeat(7, 12%) 1.5rem"
                Padding="0.5rem"
                Bb="1px solid  #E8E9EA"
            >
                {tableTitle.map((header) => (
                    <Text
                        key={header.tableTitleText}
                        Color="#2A2C2F"
                        FontSize="0.875rem"
                        FontWeight="500"
                    >
                        {header.tableTitleText}
                    </Text>
                ))}
            </ContainerFlex>
            {dataRequestTable?.map((request: ITableRequests, index: number) => (
                <ContainerFlex
                    key={index}
                    Height="auto"
                    Display="grid"
                    GridColumns="repeat(7, 12%) 1.5rem"
                    Justify="start"
                    Padding="0.5rem"
                    Gap="1rem"
                    Bb="1px solid  #E8E9EA"
                >
                    <Text
                        Color="#54575C"
                        FontSize="0.875rem"
                        FlexWrap="wrap"
                        wSpace="normal"
                        Height="auto"
                    >
                        {request.applicant}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {request.group}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {request.family}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {request.subFamily}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {request.brand}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {request.article}
                    </Text>

                    <DownloadLink
                        href={request.document}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        Color={request.document === 'Sin adjuntar' ? '#54575C' : '#5A5AFF'}
                        FontSize="0.875rem"
                        TextD="none"
                        Display="inline-block"
                        Width="100%"
                        WhiteSpace="nowrap"
                        Overflow="hidden"
                        TextOver="ellipsis"
                    >
                        {request.document}
                    </DownloadLink>

                    <ReviewReject
                        isOpen={openOptions === request}
                        onOptionClick={() => handleOptionClick(request)}
                    />
                </ContainerFlex>
            ))}
            <RequestTablePager page={handlePager} />
        </ContainerFlex>
    );
};
