import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@config/store';
import {Image} from '@Shopify/Ecommerce/styles';
import printIcon from '@images/fingerprint.svg';
import printSelectedIcon from '@images/fingerprintSelected.svg';
import checkIcon from '@images/check_circle.svg';
import {HANDS} from '@ListClient/interfaces';
import {FingerprintBox} from '@ListClient/FingerprintRegistration/styles/styles';
import {FingerProps, HandFingerprintImages} from '@ListClient/interfaces';
import {FingersOnHand, getCurrentSelection} from '@ListClient/constants';

const Finger: React.FC<FingerProps> = ({id, hand, top, left, right, onClick, isSelected}) => {
    const currentFinger = getCurrentSelection(
        id as keyof typeof FingersOnHand
    ) as keyof HandFingerprintImages;
    const currentHand = hand === HANDS.LEFT ? 'left' : 'right';
    const clientFingerprint = useSelector(
        (state: RootState) => state.newClientProcess.fingerprints[currentHand][currentFinger]
    );

    return (
        <FingerprintBox
            Width="0"
            Cursor="pointer"
            Left={left}
            Top={top}
            Right={right}
            onClick={() => onClick(id)}
            Height="2.063rem"
        >
            <Image
                src={isSelected ? printIcon : printSelectedIcon}
                Height="1.75rem"
                Width="1.75rem"
                ObjectFit="contain"
                Padding="0.188rem"
                Background="#f0f0fe"
                Radius="0.438rem"
            />
            {clientFingerprint && (
                <Image
                    src={checkIcon}
                    Height="0.75rem"
                    Width="0.75rem"
                    ObjectFit="contain"
                    Padding="0.188rem"
                    Position="relative"
                    PositionTop="-8px"
                    PositionLeft="7px"
                />
            )}
        </FingerprintBox>
    );
};

export default Finger;
