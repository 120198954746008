import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import estados from '@images/estados.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ImgShow} from '@components/CloseBranch/style';
interface ISuccess {
    isConfirm: number;
}
export const SuccessBranchSold = ({isConfirm}: ISuccess) => {
    const BranchData = useSelector((state: RootState) => {
        return state.putUserBranchInfo;
    });

    return (
        <ContainerFlex Padding="24px 16px" Justify="start" FlexDir="column">
            <ImgShow src={estados} Border="" />
            {isConfirm === 1 ? (
                <Text Padding="16px 0" FontSize="1rem">
                    Operaciones de la sucursal cerradas exitosamente
                </Text>
            ) : (
                <ContainerFlex FlexDir="column">
                    <Text>Se ha registrado un faltante en cierre la sucursal</Text>
                    <ContainerFlex>
                        Su nuevo saldo será
                        <Text Color="#ffbd00" Padding="0 8px">
                            {numberToCurrency(BranchData.CloseBranch?.finalBalanace)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
