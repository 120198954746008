import React from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import * as style from '@components/CreateUsers/Styles';
import {IUserAssignment} from '@Roles/AddRoles/interfaces';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {UpdateRoles} from '@Roles/CreateRoles/Redux/Actions/TreePermissions';
import {ROLE_USER_ASSIGNMENT, USER_ASSIGNMENT_TITLE} from '@Roles/constants';
import {ContainerFlex, Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';

export const UserAssignment = ({showUserAssignModal, setShowUserAssignModal}: IUserAssignment) => {
    const {idRole} = useSelector((state: RootState) => state.RoleById);
    const {affectedUsers} = useSelector((state: RootState) => state.affectedRoles);
    const persistenceRol = useSelector((state: RootState) => state.PersistenceRole);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const editRoles = () => {
        dispatch(
            UpdateRoles(
                token,
                {
                    roleId: idRole,
                    roleName: persistenceRol.typeRol.roleName,
                    roleType: persistenceRol.typeRol.roleType,
                    modules: persistenceRol.modules,
                    userId: persistenceRol.userId,
                },
                navigate
            )
        );
    };
    return (
        <Modal
            modalState={showUserAssignModal}
            changeModalState={() => {}}
            titleModalState={false}
            {...style.modalContect}
            HeightDialog="30.25rem"
        >
            <ContainerFlex Gap="1.5rem" Padding="1.5rem" FlexDir="column" Justify="start">
                <ContainerFlex Gap="1rem" FlexDir="column" Align="start" Height="auto">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {ROLE_USER_ASSIGNMENT.TITLE_ASSINGMENT}
                    </Text>
                    <Text Color="#2A2C2F" wSpace="normal">
                        {ROLE_USER_ASSIGNMENT.DESCRIPTION_ASSINGMENT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column">
                    {affectedUsers && affectedUsers.length > 0 ? (
                        <>
                            <ContainerFlex
                                Display="grid"
                                GridColumns="0.28fr repeat(2, 1fr)"
                                Justify="start"
                                Padding="0.5rem"
                                Height="1.875rem"
                                MaxH="1.875rem"
                                Bb="1px solid #E8E9EA"
                            >
                                {USER_ASSIGNMENT_TITLE.map((item, index) => (
                                    <Text
                                        Color="#2A2C2F"
                                        FontWeight="500"
                                        FontSize="0.875rem"
                                        key={index}
                                        GridColumnsStart={`${index + 2}`}
                                    >
                                        {item}
                                    </Text>
                                ))}
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Height="15rem"
                                OverFlowY="auto"
                                Justify="start"
                            >
                                {affectedUsers.map((item, index) => (
                                    <ContainerFlex
                                        key={index}
                                        Display="grid"
                                        GridColumns="0.28fr repeat(2, 1fr)"
                                        Height="3rem"
                                        MaxH="3rem"
                                        Padding="0.5rem"
                                    >
                                        <Image
                                            Radius="50%"
                                            width="32px"
                                            height="32px"
                                            src={item.image}
                                        />
                                        <TextEllipsis
                                            title={item.userName}
                                            FontWeight="400"
                                            Color="#2A2C2F"
                                            FontSize="0.875rem"
                                            Width="auto"
                                        >
                                            {item.userName}
                                        </TextEllipsis>
                                        <TextEllipsis
                                            title={item.email}
                                            FontWeight="400"
                                            Color="#2A2C2F"
                                            FontSize="0.875rem"
                                            Width="auto"
                                        >
                                            {item.email}
                                        </TextEllipsis>
                                    </ContainerFlex>
                                ))}
                            </ContainerFlex>
                        </>
                    ) : (
                        <Text FontSize="0.875rem" Color="#71767D">
                            {ROLE_USER_ASSIGNMENT.WITHOUT_USERS}
                        </Text>
                    )}
                </ContainerFlex>

                <ContainerFlex Gap="1.5rem" Align="end" Height="auto">
                    <Text
                        onClick={() => setShowUserAssignModal(false)}
                        Color="#FF6357"
                        Margin="0.5rem 1rem"
                        MarginB="0.5rem"
                        Cursor="pointer"
                    >
                        {ROLE_USER_ASSIGNMENT.CANCEL_BUTTON}
                    </Text>
                    <ButtonGeneral
                        width=""
                        padding="1rem 0.5rem"
                        text="Guardar"
                        clic={() => editRoles()}
                        height="40px"
                        cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
