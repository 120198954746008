import {useDispatch} from 'react-redux';
import {Controller} from 'react-hook-form';
import React from 'react';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import {AppDispatch} from '@/config/store';
import {handleCheckBoxPaymentMethods} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/PaymentMethodsActions';
import {PAYMENT_METHODS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IRowPaymentMethodProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

type PaymentMethodsTypeKeys =
    | 'bankTransfer'
    | 'debitCard'
    | 'creditCard'
    | 'cashPayment'
    | 'ataskateWeb';

export const RowPaymentMethod: React.FC<IRowPaymentMethodProps> = ({
    control,
    isSubmitted,
    trigger,
    lastIndex,
    setShowToolTip,
    showToolTip,
    index,
    item,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const paymentMethodTypeHash: {[key: number]: PaymentMethodsTypeKeys} = {
        1: 'bankTransfer',
        2: 'debitCard',
        3: 'creditCard',
        4: 'cashPayment',
        5: 'ataskateWeb',
    };
    const getPaymentMethodId = (id: number) => {
        return paymentMethodTypeHash[id];
    };
    const typePaymentIndicator = getPaymentMethodId(item.id);
    const name: `typePaymentsIns.${PaymentMethodsTypeKeys}` =
        `typePaymentsIns.${typePaymentIndicator}` as const;
    return (
        <ContainerFlex Gap="0.5rem" Justify="start" Align="start">
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, ...field}}) => (
                    <Checkbox
                        {...field}
                        onChange={(e) => {
                            onChange(e);
                            dispatch(handleCheckBoxPaymentMethods(item.id));
                            if (isSubmitted) trigger('typePaymentsIns');
                        }}
                    />
                )}
            />
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                {index !== lastIndex ? (
                    <Text Color="#2A2C2F">{item.type}</Text>
                ) : (
                    <ContainerFlex Gap="0.5rem" Width="max-content" Height="max-content">
                        <Text Color="#2A2C2F">{item.type}</Text>
                        <ContainerFlex
                            Width="24px"
                            Height="24px"
                            FlexDir="column"
                            Justify="start"
                            Position="relative"
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <Image
                                src={questionIcon}
                                Width="24px"
                                Height="24px"
                                Cursor="pointer"
                                alt="tooltip-icon"
                            />
                            {showToolTip && (
                                <ToolTip description={PAYMENT_METHODS.TOOLTIP_DESCRIPTION} />
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                )}

                <Text Color="#54575C" FontSize="0.875rem">
                    {item.description}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
