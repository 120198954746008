import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {
    EditForm,
    GroupEditForm,
    IBranchHolidays,
    IFormBranch,
    User,
} from '@components/LocationsBranch/interfaces';
import {
    SCHEDULE_DEFAULT_VALUES,
    INITIAL_OPENING_HOURS,
    HOLIDAYS_DEFAULT_VALUES,
} from '@components/LocationsBranch/constants';
import {formatHours} from '@/hooks/currentFormatUtils';
import {IOpeningHours} from '@components/LocationsBranch/Redux/interfaces';
import {IScheduleSlot} from '@components/ScheduleTable/interfaces/interfaces';
import {setAddedUsers} from '@components/LocationsBranch/Redux/Action/AddUser';
import {getDayHoursInterval} from '@components/LocationsBranch/utils/GetIntervals';
import {setOpeningHours} from '@components/LocationsBranch/Redux/Action/AddOpenHours';
import {addAddress} from '@components/LocationsBranch/Redux/Action/AddAddressLocation';
import {updateHolidays} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const useInitializeForm = () => {
    const dispatch = useDispatch();
    const {AddUserEmployee} = useSelector((state: RootState) => ({
        AddUserEmployee: state.AddUserEmployee,
    }));
    const initialize = (
        source: EditForm | GroupEditForm | IFormBranch | null,
        nameOf: string,
        setValue: (name: string, value: string) => void
    ) => {
        if (!source) return;
        const {name = '', users} = source || {};
        const newUsers =
            users.map((user: User) => ({
                employeeId: user.usersId,
                imageURL: user.imageUrl,
                name: user.userName,
                role: user.rolName,
                dateSession: new Date(user.lastLogin),
                active: user.status,
            })) || [];
        if (name) setValue(nameOf, name);
        if (newUsers.length) {
            dispatch(setAddedUsers([...AddUserEmployee.addedUsers, ...newUsers]));
        }
        if ('address' in source && source.address) {
            const {
                state = '',
                stateId = '',
                municipal = '',
                municipalId = '',
                colony = '',
                colonyId = '',
                interiorNumber = '',
                outerNumber = '',
                street = '',
                postalCode = '',
            } = source.address;

            const newAddress = {
                state: {label: state, value: stateId.toString()},
                municipaly: {label: municipal, value: municipalId.toString()},
                colony: {label: colony, value: colonyId.toString()},
                interNum: interiorNumber,
                outerNum: outerNumber,
                street,
                postalCode,
            };

            dispatch(addAddress(newAddress));
        }
        if ('openingHours' in source && source.openingHours) {
            const intervals = getDayHoursInterval(0, 23, 30);
            const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
                label: item.time,
                value: String(item.id),
            }));
            const defaultValues = {...SCHEDULE_DEFAULT_VALUES};
            const newSchedule: IScheduleSlot[] =
                source.openingHours.map((hours: IOpeningHours) => ({
                    id: hours.openingHourId,
                    opening:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hours.openingAttentionSchedule)
                        ) || INITIAL_OPENING_HOURS.OPENING,
                    closing:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hours.closingAttentionSchedule)
                        ) || INITIAL_OPENING_HOURS.CLOSING,
                    dayName: hours.dayName,
                    dayOfWeek: hours.weekDay,
                    status: hours.status,
                    toggleName: `openingHours.${hours.weekDay}${defaultValues.opening}`,
                    openingSelectName: `openingHours.${hours.weekDay}.${defaultValues.closing}`,
                    closingSelectName: `openingHours.${hours.weekDay}.${defaultValues.switch}`,
                    halfDay: false,
                    intervals: intervalsOptions,
                })) || [];
            dispatch(setOpeningHours(newSchedule));
        }

        if ('holidays' in source && source.holidays) {
            const intervals = getDayHoursInterval(0, 23, 30);
            const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
                label: item.time,
                value: String(item.id),
            }));
            const defaultValues = {...HOLIDAYS_DEFAULT_VALUES};
            const newHoliday: IScheduleSlot[] =
                source.holidays.map((hour: IBranchHolidays) => ({
                    id: hour.holidayId,
                    opening:
                        intervalsOptions.find((item) => item.label === formatHours(hour.date)) ||
                        INITIAL_OPENING_HOURS.OPENING,
                    closing:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hour.endActivities)
                        ) || INITIAL_OPENING_HOURS.CLOSING,
                    dayName: hour.reason,
                    levelId: hour.holidayId,
                    status: hour.status,
                    toggleName: `holidays.${[hour.holidayId]}.${defaultValues.switch}`,
                    openingSelectName: `holidays.${[hour.holidayId]}.${defaultValues.opening}`,
                    closingSelectName: `holidays.${[hour.holidayId]}.${defaultValues.closing}`,
                    intervals: intervalsOptions,
                })) || [];
            dispatch(updateHolidays(newHoliday));
        }
    };
    return {initialize};
};
