import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';

import * as style from '@components/CreateUsers/Styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {ISelectLocations} from '@components/LocationsBranch/interfaces';
import {LOCATIONS_SELECT} from '@components/LocationsBranch/constants';
import {selectTypeLocation} from '@components/LocationsBranch/Redux/Action/SelectOptionsLocation';
import {
    fetchSelectTypeGroup,
    resetTypeLocations,
} from '@components/LocationsBranch/Redux/Action/CreateNewGroup';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

export const SelectLocations = ({showLocationsModal, setShowLocationsModal}: ISelectLocations) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [typeLocation, setTypeLocation] = useState(1);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const {loading, types} = useSelector((state: RootState) => state.selectTypesLocations);

    useEffect(() => {
        if (token) dispatch(selectTypeLocation(token));
        dispatch(resetTypeLocations());
    }, []);

    useEffect(() => {
        const selectedType = types && types.find((type) => type.locationId === 1);

        if (selectedType) {
            dispatch(fetchSelectTypeGroup(selectedType));
        }
    }, [types]);

    return (
        <Modal
            modalState={showLocationsModal}
            changeModalState={() => {}}
            titleModalState={false}
            {...style.modalContect}
            HeightDialog="19.5rem"
        >
            <ContainerFlex Gap="1.5rem" Padding="1.5rem" FlexDir="column" Justify="start">
                <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {LOCATIONS_SELECT.NEW_GROUP_BRANCH}
                    </Text>
                    <Text Color="#54575C">{LOCATIONS_SELECT.TYPE_INSTRUCTION}</Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontWeight="700" Color="#1D1E20" MarginB="0.5rem">
                        {LOCATIONS_SELECT.TYPE}
                    </Text>
                    {loading ? (
                        <ContainerFlex>
                            <LoadingAtaskate />
                        </ContainerFlex>
                    ) : (
                        types &&
                        types.length > 0 &&
                        types.map((location, index: number) => (
                            <ContainerFlex
                                key={index}
                                Justify="start"
                                Gap="0.5rem"
                                Height="1.5rem"
                                onClick={() => {
                                    setTypeLocation(location.locationId);
                                    dispatch(fetchSelectTypeGroup(location));
                                }}
                            >
                                <InputColor
                                    type="radio"
                                    checked={typeLocation === location.locationId}
                                />
                                <Text Cursor="pointer" Color="#54575C">
                                    {location.locationName}
                                </Text>
                            </ContainerFlex>
                        ))
                    )}
                </ContainerFlex>

                <ContainerFlex Gap="1.5rem" Align="end">
                    <ButtonGeneral
                        width="9.594rem"
                        text="Cancelar"
                        clic={() => setShowLocationsModal(false)}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="40px"
                        cursor="pointer"
                        border="1px solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        width="9.594rem"
                        padding="1rem 0.5rem"
                        text="Continuar"
                        clic={() => navigate('/LocationBranch/CreateLocation')}
                        height="40px"
                        cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
