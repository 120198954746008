import {
    GET_ADMIN_CASHFLOW,
    GET_ADMIN_CASHFLOW_SUCCESS,
    GET_ADMIN_CASHFLOW_ERROR,
} from '@components/AdminCashflow/constants';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const getAdminCashflow = () => {
    return {
        type: GET_ADMIN_CASHFLOW,
    };
};

export const getAdminCashflowSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ADMIN_CASHFLOW_SUCCESS,
        payload: result,
    };
};

export const getAdminCashflowError = (error: AxiosError) => {
    return {
        type: GET_ADMIN_CASHFLOW_ERROR,
        error,
    };
};

export const getAdminCashflowAction = (
    branchName: string,
    pageSize: number,
    pageNumber: number,
    token: string
) => {
    const headers = {
        Accept: 'text/plain',
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch: AppDispatch) => {
        const params = {
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
            branchName: branchName.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        dispatch(getAdminCashflow());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAdminCashflowData}?${queryString}`,
                {headers}
            );
            dispatch(getAdminCashflowSuccess(response));
        } catch (error) {
            dispatch(getAdminCashflowError(error as AxiosError));
        }
    };
};
