import {GET_MOTIVE, GET_MOTIVE_SUCCESS, GET_MOTIVE_ERROR} from '@ReduxsCashFlow/Types/Types';
const initialState = {
    data: [],
    totalPage: [],
    loading: false,
    error: false,
};
export interface IExceesMotive {
    data: InitialExcdent;
    totalPage: [];
    loading: false;
    error: false;
}
interface InitialExcdent {
    tokenExpiration: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
    token: string;
    errors: null | string[];
    message: null | string;
    succeeded: boolean;
    data: ExcdentDataItem[];
}

interface ExcdentDataItem {
    typeSearch: string;
    userEmployeeId: number;
    amount: number;
    statusName: string;
    companyId: number;
    urlVoucher: string;
    motiveId: number;
    date: string;
    userEmployeeName: string;
    zoneId: string;
    branchName: string;
    ammount: number;
    acceptedAmount: number;
    branchId: string;
    id: number;
    observations: string;
    urlReceived: string;
    urlDeposited: string;
    statusId: number;
    employeUserName: string;
    motiveName: string;
}

const getAllFlowExcessMotive = (
    state = initialState,
    action: {type: string; payload: {data: InitialExcdent}}
) => {
    switch (action.type) {
        case GET_MOTIVE:
            return {
                ...state,
                loading: true,
            };
        case GET_MOTIVE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_MOTIVE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getAllFlowExcessMotive;
