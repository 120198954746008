import React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';
import IconCheck from '@images/check.svg';
import IconIndeterminate from '@images/IndeterminateCheck.svg';
import {Image} from '@Shopify/Ecommerce/styles';
import styled from '@emotion/styled';
import {CheckboxScoreProps} from './interfaces';

const StyledFormControlLabel = styled(FormControlLabel)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
`;

const StyledCheckbox = styled(Checkbox)`
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #5a5aff;
    border-radius: 6px;
    cursor: pointer;
    display: grid;
    place-content: center;

    &:disabled {
        background-color: transparent;
        border-color: #a8a8a8;
        cursor: not-allowed;
    }

    &:hover {
        border: 1px solid #5a5aff;
    }

    &:before {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`;

const CheckboxScore = (props: CheckboxScoreProps) => {
    const {label, checked, indeterminate = false, disabled = false, onChange} = props;

    return (
        <StyledFormControlLabel
            control={
                <StyledCheckbox
                    checked={checked}
                    indeterminate={indeterminate}
                    disabled={disabled}
                    onChange={onChange}
                    icon={disabled ? null : <></>}
                    checkedIcon={
                        disabled ? null : (
                            <Image src={IconCheck} alt="Checked" width="20px" height="20px" />
                        )
                    }
                    indeterminateIcon={
                        disabled ? null : (
                            <Image
                                src={IconIndeterminate}
                                alt="Indeterminate"
                                width="18px"
                                height="18px"
                            />
                        )
                    }
                />
            }
            label={label}
        />
    );
};

export default CheckboxScore;
