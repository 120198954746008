import {
    GET_SECURITYBAG_WEIGHT,
    GET_SECURITYBAG_WEIGHT_SUCCESS,
    GET_SECURITYBAG_WEIGHT_ERROR,
    GET_SECURITYBAG_SMELTING,
    GET_SECURITYBAG_SMELTING_SUCCESS,
    GET_SECURITYBAG_SMELTING_ERROR,
    GET_SECURITYBAG_WEIGHT_EDIT,
    GET_SECURITYBAG_WEIGHT_EDIT_ERROR,
    GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
    GET_IDENTIFY_FOUNDRY,
    GET_IDENTIFY_FOUNDRY_SUCCESS,
    GET_IDENTIFY_FOUNDRY_ERROR,
    DELETE_SECURITYBAG_WEIGHT,
    DELETE_SECURITYBAG_WEIGHT_SUCCESS,
    DELETE_SECURITYBAG_WEIGHT_ERROR,
    POST_SECURITYBAG_WEIGHT_EDIT,
    POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS,
    POST_SECURITYBAG_WEIGHT_EDIT_ERROR,
} from '@TypesSecurityBagWeight/SecurityBagWeightTypes';
import {FieldValues} from 'react-hook-form';

const initialState: {
    identifyfoundry: string;
    securitybagweight: FieldValues;
    securitybagweightedit: FieldValues;
    securitybagsmelting: [];
    securitybagweightcount: number;
    securitybagweightPageIndex: number;
    securitybagsmeltingcount: number;
    securitybagsmeltingPageIndex: number;
    error: boolean;
    loading: boolean;
} = {
    identifyfoundry: '',
    securitybagweight: {},
    securitybagweightedit: {},
    securitybagsmelting: [],
    securitybagweightcount: 0,
    securitybagweightPageIndex: 0,
    securitybagsmeltingcount: 0,
    securitybagsmeltingPageIndex: 0,
    error: false,
    loading: false,
};

const securitybagweight = (
    state = initialState,
    action: {
        type: string;
        identifyfoundry: string;
        securitybagweight: FieldValues;
        securitybagweightedit: FieldValues;
        securitybagsmelting: [];
        securitybagweightcount: number;
        securitybagweightPageIndex: number;
        securitybagsmeltingcount: number;
        securitybagsmeltingPageIndex: number;
        error: boolean;
        loading: boolean;
        count: number;
        pageIndex: number;
    }
): {
    identifyfoundry: string;
    securitybagweight: FieldValues;
    securitybagweightedit: FieldValues;
    securitybagsmelting: [];
    securitybagweightcount: number;
    securitybagweightPageIndex: number;
    securitybagsmeltingcount: number;
    securitybagsmeltingPageIndex: number;
    error: boolean;
    loading: boolean;
} => {
    switch (action.type) {
        case GET_SECURITYBAG_WEIGHT:
            return {
                ...state,
                securitybagweight: {},
                loading: true,
            };
        case GET_SECURITYBAG_WEIGHT_SUCCESS:
            return {
                ...state,
                securitybagweight: action.securitybagweight,
                securitybagweightcount: action.count,
                securitybagweightPageIndex: action.pageIndex,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_SMELTING:
            return {
                ...state,
                securitybagsmelting: [],
                loading: true,
            };
        case GET_SECURITYBAG_SMELTING_SUCCESS:
            return {
                ...state,
                securitybagsmelting: action.securitybagsmelting,
                securitybagsmeltingcount: action.count,
                securitybagsmeltingPageIndex: action.pageIndex,
                loading: false,
            };
        case GET_SECURITYBAG_SMELTING_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_EDIT:
            return {
                ...state,
                securitybagweightedit: {},
                loading: true,
            };
        case GET_SECURITYBAG_WEIGHT_EDIT_SUCCESS:
            return {
                ...state,
                securitybagweightedit: action.securitybagweightedit,
                loading: false,
            };
        case GET_SECURITYBAG_WEIGHT_EDIT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_EDIT:
            return {
                ...state,
                loading: true,
            };
        case POST_SECURITYBAG_WEIGHT_EDIT_SUCCESS:
            return {
                ...state,

                loading: false,
            };
        case POST_SECURITYBAG_WEIGHT_EDIT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_IDENTIFY_FOUNDRY:
            return {
                ...state,
                identifyfoundry: '',
                loading: true,
            };
        case GET_IDENTIFY_FOUNDRY_SUCCESS:
            return {
                ...state,
                identifyfoundry: action.identifyfoundry,
                loading: false,
            };
        case GET_IDENTIFY_FOUNDRY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DELETE_SECURITYBAG_WEIGHT:
            return {
                ...state,
                loading: true,
            };
        case DELETE_SECURITYBAG_WEIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_SECURITYBAG_WEIGHT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default securitybagweight;
