import * as Yup from 'yup';
import {FieldError} from 'react-hook-form';

export interface Errors {
    inputs?: (FieldError | undefined)[];
}

export const schemaValide = Yup.object()
    .shape({
        inputs: Yup.array()
            .of(
                Yup.string()
                    .required('*')
                    .matches(/^[0-9]$/, 'El valor debe ser un número entre 0 y 9')
            )
            .test('all-filled', 'Todos los campos deben ser llenados.', (value) => {
                return Array.isArray(value) && value.every((input) => input && input.trim() !== '');
            }),
    })
    .required();
