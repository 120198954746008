import React from 'react';
import {useForm} from 'react-hook-form';
import {DateTime} from 'luxon';
import {ContainerFlex, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    IDynamicCardFieldsProps,
    ICardDataSubmit,
    ICardData,
} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaPaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/schemaPaymentMethods';
import {CardNumberField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CardNumberField';
import {CardOwnerField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CardOwnerField';
import {DatePickerVigency} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/DatePickerVigency';
import {CvvField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CvvField';
import {addNewPaymentMethodAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/PaymentMethodsActions';
import {formatDate} from '@/hooks/currentFormatUtils';

export const DynamicCardFields: React.FC<IDynamicCardFieldsProps> = ({
    setShowDynamicFields,
    showDynamicFields,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
    } = useForm<ICardDataSubmit>({
        resolver: yupResolver(schemaPaymentMethod),
        defaultValues: {
            cardNumber: '',
            cardOwner: '',
            vigency: '',
            cvv: '',
        },
    });

    const onSubmit = (data: ICardDataSubmit) => {
        const newCard: ICardData = {
            ...data,
            vigency: formatDate(
                DateTime.fromISO(data.vigency as string),
                PAYMENT_METHOD.DATE_FORMAT
            ),
        };
        dispatch(addNewPaymentMethodAction({token, paymentMethod: newCard}));
        setShowDynamicFields(!showDynamicFields);
    };
    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setShowDynamicFields(!showDynamicFields);
    };
    return (
        <>
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Gap="1.5rem"
                FlexDir="column"
                Border="1px solid #5A5AFF"
                Padding="1rem"
                Radius="1rem"
            >
                <GridContainer Gap="1rem" Justify="start" GridColumns="2fr 2fr 1.5fr 1fr">
                    <CardNumberField control={control} errors={errors} />
                    <CardOwnerField register={register} errors={errors} />
                    <DatePickerVigency errors={errors} control={control} setValue={setValue} />
                    <CvvField control={control} errors={errors} />
                </GridContainer>

                <ContainerFlex Justify="end">
                    <ContainerFlex Gap="1rem" Width="max-content">
                        <ButtonGenerals
                            type="button"
                            SecondaryButton="#FFFFFF"
                            Width="148px"
                            FontSize="0.875rem"
                            FontWeight="700"
                            Height="32px"
                            onClick={onCancel}
                        >
                            {PAYMENT_METHOD.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            type="submit"
                            Width="148px"
                            FontSize="0.875rem"
                            Height="32px"
                        >
                            {PAYMENT_METHOD.SAVE_CARD}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerForm>
        </>
    );
};
