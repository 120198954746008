import {ContainerFlex, LinkAtaskate, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {SIDEMENU} from '@/components/AdminCashflow/constants';
const ExpensesMenu = () => {
    return (
        <ContainerFlex
            FlexDir="Column"
            Position="absolute"
            FontSize="1rem"
            Gap="10px"
            Height="8.5rem"
            Width="13rem"
            Margin="10px 210px 0px 0px"
            backG="white"
            Align="start"
            BoxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            ZIndex="2"
            BoxS="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
            Radius="15px"
            Padding="7px"
        >
            <LinkAtaskate to="">
                <Text BGColor="none" ZIndex="1000" Cursor="pointer" Gap="5px">
                    {SIDEMENU.view}
                </Text>
            </LinkAtaskate>
            <Text BGColor="none" ZIndex="1000" Cursor="pointer" Gap="5px">
                {SIDEMENU.anchor}
            </Text>
            <Text BGColor="none" ZIndex="1000" Cursor="pointer" Gap="5px">
                {SIDEMENU.refund}
            </Text>
            <Text BGColor="none" ZIndex="1000" Cursor="pointer" Gap="5px">
                {SIDEMENU.register}
            </Text>
        </ContainerFlex>
    );
};
export default ExpensesMenu;
