import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {
    GET_ALL_USER_BY_ROLE,
    GET_ALL_USER_BY_ROLE_ERROR,
    GET_ALL_USER_BY_ROLE_SUCCESS,
} from '@Roles/CreateRoles/Redux/Types';

export const getUserRole = () => ({type: GET_ALL_USER_BY_ROLE});
export const getUserRoleSuccess = (result: {data: AxiosResponse}) => ({
    type: GET_ALL_USER_BY_ROLE_SUCCESS,
    payload: result.data,
});
export const getUserRoleError = (error: AxiosError) => ({
    type: GET_ALL_USER_BY_ROLE_ERROR,
    error: error,
});
export const getAllUserRole = (token: string, filters: {roleId: number; search: string}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(getUserRole());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAsignedUserRole}${queryString}`,
                {headers}
            );
            dispatch(getUserRoleSuccess(response.data));
        } catch (error) {
            dispatch(getUserRoleError(error as AxiosError));
        }
    };
};
