import {AppDispatch} from '@/config/store';
import {
    GET_ALL_VALIDATE_ID_TYPE,
    GET_ALL_VALIDATE_ID_TYPE_SUCCESS,
    GET_ALL_VALIDATE_ID_TYPE_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants';

export const fetchAllValidateType = () => {
    return {
        type: GET_ALL_VALIDATE_ID_TYPE,
    };
};

export const getAllValidateTypeSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_VALIDATE_ID_TYPE_SUCCESS,
        payload: result.data,
    };
};

export const getAllValidateTypeError = (error: AxiosError) => {
    return {
        type: GET_ALL_VALIDATE_ID_TYPE_ERROR,
        payload: error,
    };
};

export const getAllValidateType = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchAllValidateType());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllValidateIdType}`,
                {headers}
            );
            dispatch(getAllValidateTypeSuccess(response));
        } catch (error) {
            dispatch(getAllValidateTypeError(error as AxiosError));
        }
    };
};
