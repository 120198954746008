import React from 'react';

import {TableItems, TableItemsContainer} from '@Endeavors/styles';
import {Text, ContainerFlex} from '@EcommerceShopify/styles';

import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';

export function ModalQR(props: {
    modalqr: boolean;
    setModalqr: React.Dispatch<React.SetStateAction<boolean>>;
    QR: string;
}) {
    const {modalqr, setModalqr, QR} = props;
    return (
        <Modal
            modalState={modalqr}
            changeModalState={() => {}}
            Align="center"
            FlexDirMod="column"
            Width="45%"
            titleModalState={true}
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            Justify="center"
            AlignMod="center"
            Position="fixed"
            Top="50%"
            Height="auto"
            BorderRadius="4px"
        >
            <ContainerFlex Padding="1rem 0" Bb="1px solid #35cf44" Height="56px">
                <ContainerFlex Width="90%" Justify="flex-start" Padding="0 1.5rem">
                    <Text FontSize="1.25rem" Color="#35cf44">
                        Escanea código QR
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    onClick={() => setModalqr(false)}
                    Padding="0 31.5px 0 0"
                    Width="10%"
                    Justify="flex-end"
                    Cursor="pointer"
                >
                    <Text FontSize="1.563rem" Cursor="pointer" Color="#35cf44">
                        x
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Padding="2rem 0 2.5rem 0">
                <Text FontWeight="600" Color="#4A4A4A">
                    Escanea el código QR
                </Text>
                <TableItemsContainer
                    GridRow="repeat(3, 5rem)"
                    GridColumns="repeat(3, 5rem)"
                    Justify="center"
                    Width="auto"
                    Padding="2rem 0"
                >
                    <ContainerFlex Bl="1px solid #35cf44" Bt="1px solid #35cf44" />
                    <TableItems />
                    <ContainerFlex Br="1px solid #35cf44" Bt="1px solid #35cf44" />
                    <TableItems />
                    <TableItems>
                        <img src={QR} width={207} alt={'Sin Imagen'} />
                    </TableItems>
                    <TableItems />
                    <ContainerFlex Bl="1px solid #35cf44" Bb="1px solid #35cf44" />
                    <TableItems />
                    <ContainerFlex Br="1px solid #35cf44" Bb="1px solid #35cf44" />
                </TableItemsContainer>
            </ContainerFlex>
        </Modal>
    );
}
