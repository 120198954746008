import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import styled from '@emotion/styled';
import {IAccordionStyledProps} from '@/components/PersonalLoans/interfaces';

export const AccordionStyled = styled(Accordion)<IAccordionStyledProps>`
    width: ${(props) => props.Width || 'auto'};
    height: ${(props) => props.Height || 'auto'};
    background-color: ${(props) => props.backgroundColor || 'transparent'};
    border-radius: ${(props) => props.BorderRadius || '8px'};
    border: ${(props) =>
        props.BorderWidth
            ? `${props.BorderWidth} solid ${props.BorderColor || '#E8E9EA'}`
            : 'none'};
    box-shadow: none;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
    padding: '8px 16px 16px 16px';
    margin: '0px';
    '& .muiaccordionsummary-content': {
        margin: '0px';
    }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
    padding: '8px 16px 16px 16px';
    margin: '0px';
    '& .muiaccordionsummary-content': {
        margin: '0px';
    }
`;
