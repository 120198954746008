import axios, {AxiosResponse} from 'axios';
import {
    POST_CREATE_USER_BRANCH,
    POST_CREATE_USER_BRANCH_ERROR,
    POST_CREATE_USER_BRANCH_FINISH,
    POST_CREATE_USER_BRANCH_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

import {URL} from '@config/constants/index';
import {GetBranchUsers} from '@components/Branches/ManageBranch/Redux/Actions/GetBranchUsers';
import {AppDispatch} from '@/config/store';

export const fetchPostCreateUserBranchStart = () => {
    return {
        type: POST_CREATE_USER_BRANCH,
    };
};
export const fetchPostCreateUserBranchSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CREATE_USER_BRANCH_SUCCESS,
        payload: result.data,
    };
};
export const fetchPostCreateUserBranchError = () => {
    return {
        type: POST_CREATE_USER_BRANCH_ERROR,
    };
};
export const fetchPostCreateUserBranchFinish = () => {
    return {
        type: POST_CREATE_USER_BRANCH_FINISH,
    };
};

export function CreateUserBranch(
    data: {
        branchId: number;
        usersId: number[];
    },
    token: string,
    setModal: (e: boolean) => void,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostCreateUserBranchStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateUserBranch}`,
                data,
                {headers}
            );
            dispatch(fetchPostCreateUserBranchSuccess(response));
            setModal(false);
            dispatch(
                GetBranchUsers(
                    {
                        branchId: idBranch,
                        searchName: '',
                        orderType: 0,
                        filterUser: 0,
                    },
                    token
                )
            );
        } catch (error) {
            dispatch(fetchPostCreateUserBranchError());
        } finally {
            dispatch(fetchPostCreateUserBranchFinish());
        }
    };
}
