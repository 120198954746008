import {VISIBLE_MENU} from '@TypesLayout/types';

const InitialState = {
    visible: false,
};
const NavMenuVisible = (state = InitialState, action: {type: string; payload: boolean}) => {
    switch (action.type) {
        case VISIBLE_MENU:
            return {
                visible: action.payload,
            };
        default:
            return state;
    }
};

export default NavMenuVisible;
