import {Controller} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {ICvvFieldProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import errorIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/errorIcon.svg';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import warningIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/warningIcon.svg';

export const CvvField: React.FC<ICvvFieldProps> = ({control, errors}) => {
    return (
        <>
            <ContainerFlex FlexDir="column" Gap="0.5rem" Align="start" Justify="start">
                <Text Color="#1D1E20" FontWeight="700" Width="max-content">
                    {PAYMENT_METHOD.FIELDS.CARD_CVV}
                </Text>
                <Controller
                    name="cvv"
                    control={control}
                    render={({field}) => (
                        <SearchInput
                            GridColumn="1fr 24px"
                            Height="40px"
                            Padding="0px 1rem"
                            Width="100%"
                            Position="static"
                            OverFlow="hidden"
                            InputPadding="0px 1rem 0px 2px"
                        >
                            <Input {...field} type="password" maxLength={4} />
                            <Image
                                src={warningIcon}
                                Width="24px"
                                Height="24px"
                                alt="warning-icon"
                            />
                        </SearchInput>
                    )}
                />
                {errors.cvv && (
                    <Text FontSize="0.875rem" Color="#FF6357" Gap="0.25rem">
                        <Image src={errorIcon} Width="16px" Height="16px" />
                        {errors.cvv?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
