export const form = {
    Padding: '1rem 1.5rem 0 1.5rem',
    Gap: '1rem',
    FlexDir: 'column',
    Align: 'start',
    Justify: 'start',
};

export const formContent = {
    Padding: '1rem',
    Gap: '1rem',
    FlexDir: 'Column',
    Align: 'start',
    Justify: 'start',
    Height: 'auto',
    Radius: '1rem',
    Border: '1px solid #F4F5F5',
};

export const title = {
    FontSize: '1.5rem',
    FontWeight: '500',
    Color: '#2A2C2F',
    Height: '1.5rem',
    Padding: '0.75rem 0',
    subtitles: {
        FontSize: '1.25rem',
        FontWeight: '500',
        Color: '#2A2C2F',
        Height: '1.5rem',
        Padding: '0.65rem 0',
    },
};
