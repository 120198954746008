import {DATA_STEP_LOANS, SAVE_LOANS_RESET} from '@components/PersonalLoans/Redux/Types/Types';

export const ActualDataStepLoans = (step: number) => {
    return {
        type: DATA_STEP_LOANS,
        payload: step,
    };
};
export const ResetDataStepLoans = () => {
    return {
        type: SAVE_LOANS_RESET,
    };
};
