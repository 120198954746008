import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Input, Text} from '@components/Shopify/Ecommerce/styles';
import {RootState} from '@config/store';
import ButtonGeneral from '@components/General/Atoms/Button';
import {InputMaskStyled} from '@components/Roles/InputMaskStyled/style';
import {SelectCity} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/SelectCity';
import {SelectState} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/SelectState';
import {SelectSuburb} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/SelectSuburb';
import {SelectMunicipality} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/SelectMunicipality';
import {PutComplementaryInfo} from '@components/Branches/ManageBranch/Redux/Actions/PutComplementaryInfo';
import {
    ICityItem,
    IComplementaryForm,
    IComplementaryValues,
    IHashAddress,
    IMunicipalityItem,
    IPostalInfo,
    IStateItem,
    ISuburbItem,
} from '@components/Branches/interfaces';
import {schemaComplementaryForm} from '@components/Branches/schemaValidations';
import {GetPostalInfo} from '@components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
import {
    LoadCatAddress,
    ResetCatAddress,
} from '@components/Branches/ManageBranch/Redux/Actions/LoadCatAddress';
import {NO_EDITED_INFO} from '@components/Branches/ManageBranch/BranchInfo/constants';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const ComplementaryForm = ({
    showComplementaryForm,
    setShowComplementaryForm,
    setShowItems,
    showItems,
}: IComplementaryForm) => {
    const {idBranch} = useParams();
    const addInfo = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo.additionalInformation;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const catCity = useSelector((state: RootState) => {
        return state.GetCatCity?.catCity;
    });
    const catState = useSelector((state: RootState) => {
        return state.GetCatState?.catState;
    });
    const catMunicipality = useSelector((state: RootState) => {
        return state.GetCatMunicipality?.catMunicipality;
    });
    const catSuburb = useSelector((state: RootState) => {
        return state.GetCatSuburb?.catSuburb;
    });
    const postalInfo = useSelector((state: RootState) => {
        return state.GetPostalInfo?.postalInfo;
    });
    const {error} = useSelector((state: RootState) => {
        return state.GetPostalInfo;
    });
    const complementaryLoad = useSelector((state: RootState) => {
        return state.PutComplementaryInfo?.loading;
    });
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        formState: {errors},
    } = useForm<IComplementaryValues>({
        resolver: yupResolver(schemaComplementaryForm),
        defaultValues: {
            code: addInfo.code === NO_EDITED_INFO.CODE ? '' : addInfo.code,
            rfc: addInfo.rfc === NO_EDITED_INFO.RFC ? '' : addInfo.rfc,
            postalCode: '',
            city: '',
            state: '',
            extNum: '',
            suburb: '',
            suburbId: 0,
            businessName:
                addInfo.businessName === NO_EDITED_INFO.BUSSINESS_NAME ? '' : addInfo.businessName,
            phone: addInfo.phone === NO_EDITED_INFO.PHONE ? '' : addInfo.phone,
            municipality: '',
            street: '',
            intNum: '',
        },
    });
    const formData = watch();
    const setLists = (
        state: IStateItem[],
        municipality: IMunicipalityItem[],
        suburb: ISuburbItem[],
        city: ICityItem[]
    ) => {
        state.map(
            (item) => item.stateId === postalInfo?.stateId && setValue('state', item.stateName)
        );
        municipality.map(
            (item) =>
                item.municipalityId === postalInfo?.municipalityId &&
                setValue('municipality', item.nameMunicipality)
        );
        suburb.map(
            (item) => item.suburbId === postalInfo?.colonyId && setValue('suburb', item.nameSuburb)
        );
        city.map((item) => item.cityId === postalInfo?.cityId && setValue('city', item.nameCity));
    };
    const loadLists = (postalInfo: IPostalInfo, postalCode: string) => {
        if (postalCode.length !== 0) {
            dispatch(LoadCatAddress(postalInfo, token));
            setValue('suburbId', postalInfo?.colonyId);
        }
    };
    const hashMapInfoAddress = (info: string, index: number) => {
        const adress: IHashAddress = {
            0: (info: string) => setValue('street', info),
            1: (info: string) => setValue('extNum', info),
            2: (info: string) => setValue('intNum', info),
            4: (info: string) => setValue('postalCode', info.replace('C.P: ', '')),
        };
        const actionFunction = adress[index];
        if (actionFunction && typeof actionFunction === 'function') {
            actionFunction(info);
        }
    };
    const setAddress = (address: string) => {
        address.split(', ').forEach((data: string, index: number) => {
            hashMapInfoAddress(data, index);
        });
    };
    const resetLists = () => {
        reset();
        dispatch(ResetCatAddress());
    };
    const onSubmit = (data: IComplementaryValues) => {
        const postData = {
            branchId: Number(idBranch),
            code: data.code,
            businessName: data.businessName,
            rfc: data.rfc,
            phone: data.phone,
            suburbId: data.suburbId,
            address: data.street,
            outdoorNumber: data.extNum,
            interiorNumber: data.intNum,
        };
        dispatch(
            PutComplementaryInfo(
                postData,
                token,
                setShowComplementaryForm,
                setShowItems,
                showItems,
                Number(idBranch)
            )
        );
    };
    useEffect(() => {
        if (!addInfo.address.includes('00000')) {
            setAddress(addInfo.address);
        } else {
            resetLists();
        }
    }, []);
    useEffect(() => {
        if (formData.postalCode ? formData.postalCode.length === 5 : 0) {
            dispatch(GetPostalInfo(formData.postalCode ? formData.postalCode : '', token));
        }
    }, [formData.postalCode]);
    useEffect(() => {
        loadLists(postalInfo, formData.postalCode ? formData.postalCode : '');
    }, [postalInfo]);
    useEffect(() => {
        setLists(catState, catMunicipality, catSuburb, catCity);
    }, [catCity, catMunicipality, catSuburb, catState]);
    return (
        <ContainerFlex Height="auto" FlexDir="column" Bb="1px solid #e5e7e9" Padding="16px 0 0 0">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Información complementaria
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Height="580px"
                Padding="16px 0 0 0"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                AlignContent="center"
                ColumnGap="24px"
            >
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    CLAVE
                </Text>
                <Input
                    Cursor="text"
                    Margin={errors.code ? '0 0 4px 0' : '0 0 28px 0'}
                    Height="2.5rem"
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="CLAVE"
                    Width="300px"
                    {...register('code')}
                    type="text"
                    maxLength={16}
                    error={errors.code?.message}
                />
                {errors.code && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.code.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    RFC
                </Text>
                <Input
                    Cursor="text"
                    Margin={errors.rfc ? '0 0 4px 0' : '0 0 28px 0'}
                    Height="2.5rem"
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="RFC"
                    Width="300px"
                    {...register('rfc')}
                    type="text"
                    maxLength={13}
                    error={errors.rfc?.message}
                />
                {errors.rfc && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.rfc.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Código Postal
                </Text>
                <InputMaskStyled
                    autoComplete="off"
                    height="2.5rem"
                    width="300px"
                    margin={errors.postalCode ? '0 0 4px 0' : '0 0 28px 0'}
                    padding="13px 12px"
                    border={errors.postalCode ? '1px solid #ef4f55' : '1px solid #E5E7E9'}
                    radius="4px"
                    placeholder="Código Postal"
                    holdercolor="#cbced1"
                    fontSize="0.875rem"
                    mask="99999"
                    maskChar=""
                    {...register('postalCode')}
                    error={errors.postalCode?.message}
                />
                {errors.postalCode && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.postalCode.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" Color="#6d6e71" Width="300px">
                    Ciudad
                </Text>
                <SelectCity
                    data={
                        formData.postalCode && formData.postalCode.length === 5
                            ? catCity.filter(
                                  (item: ICityItem) => item.cityId === postalInfo?.cityId
                              )
                            : catCity
                    }
                    optionSelect={error ? '' : formData.city ? formData.city : ''}
                    setValue={setValue}
                    disabled={(formData.postalCode && formData.postalCode.length < 5) || error}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Colonia
                </Text>
                <SelectSuburb
                    data={
                        formData.postalCode && formData.postalCode.length === 5
                            ? catSuburb.filter(
                                  (item: ISuburbItem) => item.cp === formData.postalCode
                              )
                            : catSuburb
                    }
                    optionSelect={error ? '' : formData.suburb ? formData.suburb : ''}
                    setValue={setValue}
                    disabled={(formData.postalCode && formData.postalCode.length < 5) || error}
                />

                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Número exterior
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.extNum ? '0 0 4px 0' : '0 0 28px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Número exterior"
                    Width="300px"
                    {...register('extNum')}
                    type="text"
                    maxLength={64}
                    error={errors.extNum?.message}
                />
                {errors.extNum && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.extNum.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Razón social
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.businessName ? '0 0 4px 0' : '0 0 28px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Razón social"
                    Width="300px"
                    {...register('businessName')}
                    type="text"
                    maxLength={64}
                    error={errors.businessName?.message}
                />
                {errors.businessName && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.businessName.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Teléfono
                </Text>
                <InputMaskStyled
                    autoComplete="off"
                    height="2.5rem"
                    width="300px"
                    margin={errors.phone ? '0 0 4px 0' : '0 0 28px 0'}
                    padding="13px 12px"
                    border={errors.phone ? '1px solid #ef4f55' : '1px solid #E5E7E9'}
                    radius="4px"
                    placeholder="Teléfono"
                    holdercolor="#cbced1"
                    fontSize="0.875rem"
                    mask="9999999999"
                    maskChar=""
                    value={formData.phone}
                    {...register('phone')}
                    error={errors.phone?.message}
                />
                {errors.phone && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.phone.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Estado
                </Text>
                <SelectState
                    data={
                        formData.postalCode && formData.postalCode.length === 5
                            ? catState.filter(
                                  (item: IStateItem) => item.stateId === postalInfo?.stateId
                              )
                            : catState
                    }
                    optionSelect={error ? '' : formData.state ? formData.state : ''}
                    setValue={setValue}
                    disabled={(formData.postalCode && formData.postalCode.length < 5) || error}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Alcaldía/Municipio
                </Text>
                <SelectMunicipality
                    data={
                        formData.postalCode && formData.postalCode.length === 5
                            ? catMunicipality.filter(
                                  (item: IMunicipalityItem) =>
                                      item.municipalityId === postalInfo?.municipalityId
                              )
                            : catCity
                    }
                    optionSelect={error ? '' : formData.municipality ? formData.municipality : ''}
                    setValue={setValue}
                    disabled={(formData.postalCode && formData.postalCode.length < 5) || error}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Calle
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.street ? '0 0 4px 0' : '0 0 28px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Calle"
                    Width="300px"
                    {...register('street')}
                    type="text"
                    maxLength={50}
                    error={errors.street?.message}
                />
                {errors.street && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.street.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" Color="#6d6e71" Width="300px">
                    Número interior
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.intNum ? '0 0 4px 0' : '0 0 28px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Número interior"
                    Width="300px"
                    {...register('intNum')}
                    type="text"
                    maxLength={64}
                    error={errors.intNum?.message}
                />
                {errors.intNum && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.intNum.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Padding="18px" Height="16%" Gap="20px">
                <ButtonGeneral
                    width="93px"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        setShowComplementaryForm(!showComplementaryForm);
                        reset();
                    }}
                    disabled={complementaryLoad}
                    cursor={complementaryLoad ? '' : 'pointer'}
                />
                <ButtonGeneral
                    width="87px"
                    text={complementaryLoad ? <LoadingGeneralButtons /> : 'Guardar'}
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform="capitalize"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={handleSubmit(onSubmit)}
                    disabled={complementaryLoad}
                    cursor={complementaryLoad ? '' : 'pointer'}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
