import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState, AppDispatch} from '@config/store';
import {UserData} from '@components/ManageUser/Redux/interface';
import {ContainerCellGrid, ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {resetDataUserUpdate} from '@components/ManageUser/Redux/Actions/putDataUserEmployeeAction';

export const ViewInformationUser = () => {
    const dispatch: AppDispatch = useDispatch();
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    useEffect(() => {
        dispatch(resetDataUserUpdate());
    }, []);
    return (
        <ContainerFlex
            Display="grid"
            Height="248px"
            GridColumns="45% 45%"
            GridRows="repeat(6, 1fr)"
            Padding="16px 16px 16px 40px"
            Justify="flex-start"
        >
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Nombre (s):
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation?.userEmployeeName}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Apellidos :
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation?.usersLastName}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Nombre de usuario:
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation.userName}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Organización:
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation.organization}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Puesto:
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation.job}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Rol(es):
                </Text>
                <Text FontSize="0.875rem" Color="#414042">
                    {UserInformation?.roles?.map((employee, index: number) => {
                        return `${employee.roleName}${
                            index === UserInformation?.roles?.length - 1 ? '' : ', '
                        }`;
                    })}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center" Column="1 / 3">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Dirección:
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    FontStyle={UserInformation.adress ? '' : 'italic'}
                >
                    {UserInformation.adress ?? 'Añadir una dirección'}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Correo electrónico:
                </Text>
                <TextEllipsis
                    FontWeight="400"
                    FontSize="0.875rem"
                    Color="#414042"
                    FontStyle="italic"
                    Width="14rem"
                    TextAlign="left"
                >
                    {UserInformation.email ?? 'Añadir una dirección de correo electrónico'}
                </TextEllipsis>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Teléfono:
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    FontStyle={UserInformation.phone ? '' : 'italic'}
                >
                    {UserInformation.phone ?? 'Añadir un teléfono'}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Centro de costos:
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    FontStyle={UserInformation.costCenter ? '' : 'italic'}
                >
                    {UserInformation.costCenter ?? 'Añadir centro de costos'}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="8px" Justify="start" Align="center">
                <Text FontWeight="300" FontSize="0.875rem" Color="#414042">
                    Jefe inmediato:
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    FontStyle={UserInformation.inmediateBoss ? '' : 'italic'}
                >
                    {UserInformation.inmediateBoss ?? 'Añadir nombre de jefe inmediato'}
                </Text>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
