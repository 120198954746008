import React from 'react';
import {Rating} from '@mui/material';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {Icons} from '@Loan/styles';
import {ContainerFlex, Text} from '@EcommerceShopify/styles';
import {ImageClient} from '@/components/Shopify/ListClient/styles/styles';
import {formatPhoneNumber, formatterDolar} from '@/hooks/currentFormatUtils';
import {homeClient} from '@Shopify/Endeavors/redux/interfaces';

export function CardsClientEndeavor() {
    const homeclient: homeClient = useSelector((state: RootState) => {
        return state.homeclient;
    });

    return (
        <ContainerFlex key={1} Justify="flex-start" Padding="1rem 0" Border="1px solid #F3F3F3">
            <ContainerFlex FlexDir="column" Width="15%" Padding="0 0.5rem">
                <ImageClient src={homeclient.infoclient.urlImage} />
                <ContainerFlex Padding="8px 0">
                    <Text>
                        {homeclient.infoclient.isTrustworthyPerson === true
                            ? 'Confiable'
                            : 'No confiable'}
                    </Text>
                </ContainerFlex>
                <Rating
                    name="read-only"
                    value={homeclient.infoclient !== null ? homeclient.infoclient.stars : 5}
                    size="small"
                    readOnly
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column">
                <ContainerFlex Justify="start">
                    <Text FontWeight="400" FontSize="17px" Transform="capitalize" Padding="1rem 0">
                        {homeclient.infoclient.clientName}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="flex-start">
                    <ContainerFlex
                        FlexDir="column"
                        Align="start"
                        Padding="0rem"
                        smFlexDir="column"
                        smAlign="flex-start"
                        smPadding="0rem 0rem 0rem 3rem"
                    >
                        <Text
                            FontWeight="300"
                            FontSize="0.875rem"
                            Color="414042"
                            Padding="0.25rem 0"
                        >
                            NUC:
                            <Text
                                FontWeight="400"
                                FontSize="0.875rem"
                                Padding="0.25rem 0 0.25rem 0.5rem"
                                Color="414042"
                            >
                                {homeclient.infoclient.nuc}
                            </Text>
                        </Text>
                        <Text
                            FontWeight="300"
                            FontSize="0.875rem"
                            Color="414042"
                            Padding="0.25rem 0"
                        >
                            Teléfonos:
                            <Text
                                FontWeight="400"
                                FontSize="0.875rem"
                                Padding="0.25rem 0.15rem 0.25rem 0.5rem"
                                Color="414042"
                            >
                                {formatPhoneNumber(homeclient.infoclient.phone)}
                            </Text>
                        </Text>
                        <Text
                            FontWeight="300"
                            FontSize="0.875rem"
                            Color="414042"
                            Padding="0.25rem 0"
                        >
                            e-mail:
                            <Text
                                Padding="0.25rem 0.15rem 0.25rem 0.5rem"
                                FontWeight="normal"
                                FontSize="0.875rem"
                            >
                                {homeclient.infoclient.email}
                            </Text>
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Padding="0 3rem 0 0" Height="38px" Width="auto">
                        <ContainerFlex
                            FlexDir="column"
                            Padding="0 16px 0 16px"
                            Margin="1rem 0"
                            Br="0.5px solid #7AC143"
                        >
                            <ContainerFlex Width="auto" Padding="16px 0.5rem">
                                <Text Margin="0" FontSize=".875rem" FontWeight="500">
                                    Ingresos
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex>
                                <Text FontSize=".875rem">
                                    {formatterDolar.format(homeclient.infoclient.apartsAvailable)}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="column"
                            Padding="0 16px 0 16px"
                            Margin="1rem 0"
                            Br="0.5px solid #7AC143"
                        >
                            <ContainerFlex Width="auto" Padding="16px 0.5rem">
                                <Text Margin="0" FontSize=".875rem" FontWeight="500">
                                    Disponible empeño
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex>
                                <Text FontSize=".875rem">
                                    {formatterDolar.format(homeclient.infoclient.pawnsAvailable)}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Margin="14px 0" Padding="0 16px 0 16px">
                            <ContainerFlex Width="auto" Padding="16px 0.5rem">
                                <Text Margin="0" FontSize=".875rem" FontWeight="500">
                                    Disponible apartado
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex>
                                <Text FontSize=".875rem">
                                    {formatterDolar.format(homeclient.infoclient.chargeAvailable)}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex Height="33%" Justify="end">
                            <Text FontWeight="300" FontSize="0.875rem" Padding="0.25rem 0">
                                <Icons className="material-icons" FontSize="20px" Margin="0 0">
                                    keyboard_arrow_down
                                </Icons>
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
}
