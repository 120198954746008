import React, {ChangeEvent, useRef, useState, useCallback, useEffect} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ArchiveInputContainer} from '@/components/Quoter/Steps/styles';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import {IExpenseDetailFile} from '@/components/CashFlow/Expenses/ExpenseDetails/Types';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import checkMark from '@/images/checkMark.svg';
import draftImg from '@/images/draft.svg';
import syncImg from '@/images/sync.svg';
import uploadImg from '@/images/publish.svg';
import {UPLOAD_FILE} from '@/components/CashFlow/Expenses/ExpenseDetails/constants';

const UploadFile = (props: IExpenseDetailFile) => {
    const {selectedFile, setSelectedFile, setProps, fileProps} = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileProperties, setProperties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);

    const convertFileToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const base64String = reader.result.split(',')[1];
                callback(base64String);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                convertFileToBase64(file, (base64String) => {
                    setSelectedFile(base64String);
                    setProperties(file);
                    setProps({name: file.name, size: Number(file.size)});
                    setErrorFile(false);
                });
            } else {
                setSelectedFile(null);
                setErrorFile(true);
            }
        }
    };

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            const file = event.dataTransfer.files?.[0];
            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                convertFileToBase64(file, (base64String) => {
                    setSelectedFile(base64String);
                    setProperties(file);
                    setErrorFile(false);
                });
            } else {
                setSelectedFile(null);
                setErrorFile(true);
            }
        },
        [setSelectedFile]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        setSelectedFile(null);
    }, [setSelectedFile]);

    return (
        <ArchiveInputContainer
            Height="56px"
            Width="100%"
            error={errorFile}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            <ContainerFlex>
                {selectedFile ? (
                    <ContainerFlex ColumnGap="8px" Justify="start">
                        <ContainerFlex
                            Padding="16px"
                            backG="#F4F5F5"
                            Width="auto"
                            Radius="32px 0 0 32px"
                        >
                            <ImageIcon src={draftImg} margin="auto" />
                        </ContainerFlex>
                        <ContainerFlex
                            Justify="space-between"
                            Padding="0 18px 0 8px"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {fileProperties ? fileProperties.name : fileProps.name}
                                </Text>
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    <ImageIcon src={checkMark} height="12px" width="12px" />
                                    {fileProperties
                                        ? (fileProperties.size / (1024 * 1024)).toFixed(2)
                                        : (fileProps.size / (1024 * 1024)).toFixed(2)}
                                    {UPLOAD_FILE.mb}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Justify="end">
                                <ImageIcon src={syncImg} />
                                <Text FontSize="0.75rem" Color="#5A5AFF" FontWeight="500">
                                    {UPLOAD_FILE.replace}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                ) : (
                    <ContainerFlex ColumnGap="8px" onClick={() => fileInputRef.current?.click()}>
                        <ImageIcon src={uploadImg} />
                        <Text FontSize="1rem" Color="#54575">
                            {UPLOAD_FILE.drag}
                        </Text>
                        <Text FontSize="1rem" Color="#5A5AFF">
                            {UPLOAD_FILE.search}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ArchiveInputContainer>
    );
};

export default UploadFile;
