export const STATUSPAYMENT = {
    TITLE: 'Proceso de pago',
    STATUS: '¡Tu pago fue realizado con éxito!',
    SUB: 'Se ha enviado el comprobante al correo electrónico registrado del titular.',
    RESUME: 'Resumen del pago ',
    ACCOUNT: 'Ir a Mi cuenta',
    LOAN: 'Pago cuota préstamo',
    TOTAL: 'Total pagado',
    SEND: '¿Quieres enviarle el comprobante a otra persona?',
    EMAIL: 'Correo electrónico',
    BAUCHER: 'Enviar comprobante',
    PAY: 'Hacer otro pago',
    CHECKED: 'El comprobante de la transacción fue enviado con éxito al correo ',
};

export const METHODPAYMENT = {
    TITLE: 'Método de pago',
    CARD: 'Tarjeta de crédito o débito',
    NUMBER: 'Número de la tarjeta',
    NAME: 'Nombre del titular de la tarjeta',
    DATE: 'Fecha de caducidad',
    CCV: 'CVV',
    SAVE: 'Guardar esta tarjeta para futuras compras',
};

export const RESUMECREDITS = {
    TITLE: 'Resumen del pago a realizar',
    RESUME: 'Total a pagar ahora',
    PAY: 'Continuar',
};
export const RESUMETYPEPAY = {
    TITLE: 'Resumen de compra',
    EDIT: 'Editar carrito',
    LOAN: 'Pago cuota',
    RESUME: 'Total a pagar ahora',
    VALUE: '1,000',
    PAY: 'Continuar',
};

export const CREDITSROUTE = {
    TITLE: 'Proceso de pago ',
    INITIAL: 'Inicio',
    ACCOUNT: 'Mi cuenta',
    LOANS: 'Mis préstamos',
    PAY: 'Pago de cuota',
};
export const formEmail = {
    email: '',
};
export const formCard = {
    name: '',
    cardNumber: '',
};
