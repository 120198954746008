import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@Shopify/Endeavors/styles';
import {pledgePension} from '@Locations/interfaces';

export default function PensionProucts(props: {pledgePension: pledgePension}): JSX.Element {
    const {pledgePension} = props;
    return (
        <>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Pensión
                </Text>
                <Text FontSize="0.875rem">{pledgePension.pensionName}</Text>
            </TableItem>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Teléfono de contacto
                </Text>
                <Text FontSize="0.875rem">{pledgePension.phonePension}</Text>
            </TableItem>
            <TableItem />
            <TableItem />
        </>
    );
}
