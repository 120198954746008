import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {
    UPDATE_LINK_EXPIRED,
    UPDATE_LINK_EXPIRED_SUCCESS,
    UPDATE_LINK_EXPIRED_ERROR,
} from '@components/Login/Redux/Types/Types';
import StringUtils from '@/GenericScripts/utils';

export const updateStatusLink = () => {
    return {
        type: UPDATE_LINK_EXPIRED,
    };
};
export const updateStatusLinkSuccess = (result: AxiosResponse) => {
    return {
        type: UPDATE_LINK_EXPIRED_SUCCESS,
        payload: result,
    };
};
export const updateStatusLinkError = (error: AxiosError) => {
    return {
        type: UPDATE_LINK_EXPIRED_ERROR,
        error: error,
    };
};
export function updateStatusLinkExpeired(rowId: string) {
    const headers = {
        Accept: 'application/json',
    };
    const queryString = StringUtils.jsonToQueryString({rowId});
    return async (dispatch: AppDispatch) => {
        dispatch(updateStatusLink());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdatePasswordRecoveryExpiration}${queryString}`,
                {
                    headers,
                    params: {rowId},
                }
            );
            dispatch(updateStatusLinkSuccess(response));
        } catch (error) {
            dispatch(updateStatusLinkError(error as AxiosError));
        }
    };
}
