import axios, {AxiosResponse} from 'axios';
import {
    PUT_BRANCH_SCHEDULE_DISCHARGE,
    PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH,
    PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IScheduleUpdateData, IShowItem} from '@/components/Branches/interfaces';
import {GetScheduleDischarge} from '@/components/Branches/ManageBranch/Redux/Actions/GetScheduleDischarge';

export const fetchPutScheduleDischargeStart = () => {
    return {
        type: PUT_BRANCH_SCHEDULE_DISCHARGE,
    };
};
export const fetchPutScheduleDischargeSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
        payload: result,
    };
};
export const fetchPutScheduleDischargeError = () => {
    return {
        type: PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    };
};
export const fetchPutScheduleDischargeFinish = () => {
    return {
        type: PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH,
    };
};

export function PutScheduleDischarge(
    data: IScheduleUpdateData,
    token: string,
    setShowOpenSchedule: (e: boolean) => void,
    setShowItems: (e: IShowItem) => void,
    showItems: IShowItem,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutScheduleDischargeStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutScheduleDischarge}`,
                data,
                {headers}
            );
            dispatch(fetchPutScheduleDischargeSuccess(response.data));
            setShowOpenSchedule(false);
            setShowItems({
                ...showItems,
                schedule: false,
            });
        } catch (error) {
            dispatch(fetchPutScheduleDischargeError());
        } finally {
            dispatch(fetchPutScheduleDischargeFinish());
            dispatch(GetScheduleDischarge(idBranch, token));
        }
    };
}
