import {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_NEWCATALOGID_ERROR,
    LIST_NEWCATALOGID_RESET,
    LIST_NEWCATALOGID_START,
    LIST_NEWCATALOGID_SUCCESS,
    TARGET_LEVEL_CATALOG,
} from '@components/Articles/Redux/Types';
import {GetDetailsCatalogprops} from '@components/Articles/Redux/interfaces';

const initialState: GetDetailsCatalogprops = {
    dataCatalog: null,
    loading: false,
    error: false,
    errorData: null,
    targetLevel: 0,
};

const GetNewCatalogId = (
    state: GetDetailsCatalogprops = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; value: number}
): GetDetailsCatalogprops => {
    switch (action.type) {
        case LIST_NEWCATALOGID_START:
            return {...state, loading: true, error: false};
        case LIST_NEWCATALOGID_SUCCESS:
            return {...state, loading: false, error: false, dataCatalog: action.payload.data.data};
        case LIST_NEWCATALOGID_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case TARGET_LEVEL_CATALOG:
            return {...state, loading: false, error: false, targetLevel: action.value};
        case LIST_NEWCATALOGID_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetNewCatalogId;
