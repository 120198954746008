import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {IFormSelectProps} from '@components/AccountSettings/interfaces';

const FormSelect: React.FC<IFormSelectProps> = ({name, label, options, control, error}) => (
    <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px" Height="auto">
        <Text FontSize="12px" FontWeight="500" Color="#6D6E71">
            {label}
        </Text>
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, ...field}}) => (
                <SelectGeneral
                    {...field}
                    value={value}
                    placeholder="Selecciona"
                    isSearchable={false}
                    hasError={!!error}
                    options={options}
                    onChange={(selectedOption) => onChange(selectedOption)}
                />
            )}
        />
        {error && (
            <Text Color="#FF6357" FontSize="12px">
                {error}
            </Text>
        )}
    </ContainerFlex>
);
export default FormSelect;
