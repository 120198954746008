import axios, {AxiosResponse} from 'axios';
import {URL} from '@/config/constants';

import {
    GET_PASSWORD_RANDOM,
    GET_PASSWORD_RANDOM_SUCCESS,
    GET_PASSWORD_RANDOM_ERROR,
} from '@components/ManageUser/Redux/types';
import {AppDispatch} from '@/config/store';

export const getPasswordRandom = () => ({type: GET_PASSWORD_RANDOM});
export const getPasswordRandomSuccess = (result: AxiosResponse) => {
    return {
        type: GET_PASSWORD_RANDOM_SUCCESS,
        payload: result,
    };
};
export const getPasswordRandomError = () => ({type: GET_PASSWORD_RANDOM_ERROR});
export const getPasswordRandomAxios = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPasswordRandom());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPasswordRandom}`,
                {
                    headers,
                }
            );
            dispatch(getPasswordRandomSuccess(response));
        } catch (error) {
            dispatch(getPasswordRandomError());
        }
    };
};
