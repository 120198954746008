import {
    GET_USERS_VALIDATION_DATA,
    GET_USERS_VALIDATION_SUCCESS,
    GET_USERS_VALIDATION_ERROR,
    PRE_GET_USERS_VALIDATION_SUCCESS,
    ALREADY_GET_USERS_VALIDATION_SUCCESS,
    RESET_USER_DATA,
} from '@components/Login/Redux/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDataLogin, UserData} from '@components/Login/interfaces';

const initialState: IDataLogin = {
    userData: null,
    preData: null,
    loading: false,
    error: false,
    errorData: null,
    userType: '',
    userExists: true,
};

const getUsersValidation = (
    state: IDataLogin = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
        already: UserData;
    }
): IDataLogin => {
    switch (action.type) {
        case GET_USERS_VALIDATION_DATA:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ALREADY_GET_USERS_VALIDATION_SUCCESS:
            return {
                ...state,
                userData: action.already,
                loading: false,
            };
        case GET_USERS_VALIDATION_SUCCESS:
            return {
                ...state,
                userData: action.payload.data.data,
                userType: action.payload.data.data.userType,
                loading: false,
            };
        case PRE_GET_USERS_VALIDATION_SUCCESS:
            return {
                ...state,
                preData: action.payload.data.data,
                loading: false,
            };
        case GET_USERS_VALIDATION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                userExists: false,
                errorData: action.error,
            };
        case RESET_USER_DATA:
            return {
                ...state,
                userExists: true,
                userData: null,
            };
        default:
            return state;
    }
};

export default getUsersValidation;
