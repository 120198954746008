import React, {useState} from 'react';
import * as styles from '@/components/Quoter/stylesConstants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Input} from '@components/NavBarPOS/SearchComponent/styles';
import {ISearchProps} from '@components/Users/interface';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const UserRolesSearch: React.FC<ISearchProps> = ({onSearch}) => {
    const [searchText, setSearchText] = useState('');
    const RolesUsersError = useSelector((state: RootState) => state.GetEmployeeUserSearch.error);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };
    const handleSearchClick = () => {
        if (searchText.length > 0) {
            onSearch(searchText);
        } else {
            setSearchText('');
            onSearch('');
        }
    };
    const handleClearSearch = () => {
        setSearchText('');
        onSearch('');
    };

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                Width="38.313rem"
                Height="2.5rem"
                Radius="2rem"
                Self="start"
                Border="1px solid #E8E9EA"
                GridColumns="90% 10%"
                Padding="0.5rem 1rem 0.5rem 0rem"
            >
                <Input
                    type="input"
                    placeholder="Buscar"
                    value={searchText}
                    onChange={handleChange}
                    onKeyDown={(event: {key: string}) => {
                        if (event.key === 'Enter') {
                            handleSearchClick();
                        }
                    }}
                />
                <Icons
                    {...styles.iconSearch}
                    onClick={() => {
                        handleClearSearch();
                    }}
                >
                    {searchText ? 'close' : 'search'}
                </Icons>
            </ContainerFlex>
            {RolesUsersError === true && (
                <ContainerFlex
                    Position="absolute"
                    PositionTop="16rem"
                    backG="#FFFFFF"
                    ZIndex="101"
                    Width="38.31rem"
                    Height="2.69rem"
                    Padding="12px 8px"
                    Border="1px solid #E8E9EA"
                >
                    <Text Color="#54575C" FontWeight="500" wSpace="pre">
                        No existen más usuarios,{' '}
                        <Text
                            Color="#5A5AFF"
                            FontWeight="500"
                            wSpace="pre"
                            Cursor="pointer"
                            onClick={() => (window.location.href = '#/Users/')}
                        >
                            crea usuarios{' '}
                        </Text>
                        primero y asigna su rol.
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
