import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppDispatch, RootState} from '@config/store';
import {getMyPlans} from '@components/MyPlan/Redux/Action/GetContractedPlans';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import PlanLineInfo from '@components/MyPlan/PlanLineInfo';
import {SUMMARY_PLAN_TEXT} from '@components/MyPlan/constants';

const SummaryPlan = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {plans, loading} = useSelector((state: RootState) => state.getContractedPlans);

    useEffect(() => {
        dispatch(getMyPlans(token, companyId));
    }, []);

    const getStyles = (planTitle: string, description: string) => {
        switch (true) {
            case planTitle === SUMMARY_PLAN_TEXT.SUMMARY && description === SUMMARY_PLAN_TEXT.TOTAL:
                return {fontSize: '1.25rem', fontWeight: '500'};
            case planTitle === SUMMARY_PLAN_TEXT.SUMMARY &&
                description === SUMMARY_PLAN_TEXT.USER_TOTAL:
                return {fontSize: '1rem', fontWeight: '500'};
            case description === SUMMARY_PLAN_TEXT.TOTAL:
                return {fontWeight: '500'};
            default:
                return {fontSize: '1rem', fontWeight: '400'};
        }
    };

    return (
        <ContainerFlex FlexDir="column" Padding="0" Gap="16px" Height="fit-content">
            {loading && (
                <ContainerFlex Justify="center">
                    <LoadingAtaskate />
                </ContainerFlex>
            )}
            {!loading &&
                plans &&
                plans.plans.map((plan, index) => {
                    const styleProps =
                        plan.title === SUMMARY_PLAN_TEXT.SUMMARY
                            ? {Padding: '16px', Radius: '16px', backG: '#FFF'}
                            : {};
                    return (
                        <ContainerFlex
                            key={index}
                            FlexDir="column"
                            Align="flex-start"
                            Gap="16px"
                            Height="fit-content"
                            {...styleProps}
                        >
                            {plan.title === SUMMARY_PLAN_TEXT.SUMMARY ? (
                                <Text FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                                    {plan.title}
                                </Text>
                            ) : (
                                <ContainerFlex
                                    Align="center"
                                    Justify="space-between"
                                    Self="stretch"
                                >
                                    <Text FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                                        {plan.title}
                                    </Text>
                                    <Text
                                        FontSize="0.875rem"
                                        FontWeight="500"
                                        Color="#5A5AFF"
                                        Cursor="pointer"
                                    >
                                        {SUMMARY_PLAN_TEXT.MANAGE}
                                    </Text>
                                </ContainerFlex>
                            )}
                            <ContainerFlex FlexDir="column" Align="flex-start" Gap="8px">
                                {plan.content.length > 0 &&
                                    plan.content.map((item, i) => {
                                        const {fontSize, fontWeight} = getStyles(
                                            plan.title,
                                            item.description
                                        );
                                        return (
                                            <PlanLineInfo
                                                key={i}
                                                icon={item.icon}
                                                label={item.description}
                                                detail={formatterDolar.format(item.price)}
                                                detailLabel={item.message}
                                                fontSize={fontSize as string}
                                                fontWeight={fontWeight}
                                            />
                                        );
                                    })}
                            </ContainerFlex>
                        </ContainerFlex>
                    );
                })}
        </ContainerFlex>
    );
};

export default SummaryPlan;
