import {
    GET_DATA_CONTACT_PONZU_START,
    GET_DATA_CONTACT_PONZU_SUCCESS,
    GET_DATA_CONTACT_PONZU_ERROR,
} from '@MyCompany/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    data: [],
};

const GetDataContactPonzu = (state = initialState, action: {type: any; payload: any}) => {
    switch (action.type) {
        case GET_DATA_CONTACT_PONZU_START:
            return {...state, loading: true};

        case GET_DATA_CONTACT_PONZU_SUCCESS:
            return {...state, loading: false, data: action.payload.data};

        case GET_DATA_CONTACT_PONZU_ERROR:
            return {...state, loading: false, error: true};

        default:
            return state;
    }
};

export default GetDataContactPonzu;
