import React from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ITermNameFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const TermNameField: React.FC<ITermNameFieldProps> = ({register, errors}) => {
    return (
        <>
            <ContainerFlex
                Gap="0.25rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Width="362.667px"
            >
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.PRODUCT_NAME}
                </Text>
                <Input {...register('termName')} maxLength={100} />
                {errors.termName && (
                    <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem" wSpace="wrap">
                        <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                        {errors.termName.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
