import React, {Fragment, useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@Quoter/stylesConstants';
import {IDinamicsInputs, IsetDinamicValue} from '@Quoter/inferfaces';
import {addDinamics} from '@Quoter/Redux/Actions/saveEndeavors';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import Switch from '@mui/material/Switch';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

export const InputsDinamics = (props: IDinamicsInputs) => {
    const {dinamics, errors, control, setValue} = props;
    const {inputDinamics} = useSelector((state: RootState) => state.saveEndeavor);
    const dispatch: AppDispatch = useDispatch();
    const selectConvert = (items: string[]) => {
        return items.length > 0
            ? items.map((item, index: number) => ({
                  value: index + 1,
                  label: item,
              }))
            : [{value: '', label: 'Sin opciones'}];
    };
    const getInfoContracts = useSelector((state: RootState) => state.getInfoContracts.data);

    const contractsInfo = getInfoContracts.carPledge?.[0].dinamics;

    const notesInfoEdit = getInfoContracts.carPledge?.[0].notes;
    const stateEdit = getInfoContracts.carPledge?.[0].stateConservationId;

    const StateSelector = (state: number) => {
        switch (state) {
            case 1:
                return {value: state, label: 'Bueno'};
            case 2:
                return {value: state, label: 'Regular'};
            case 3:
                return {value: state, label: 'Malo'};
        }
    };
    const setDinamicValues = () => {
        const dinamicsToAdd: {[key: number]: string} = {};
        dinamics.map((dinamic) => {
            const matchingInfo = contractsInfo?.find(
                (info: IsetDinamicValue) => info.carticleDetailId === dinamic.articleDetailId
            );

            if (matchingInfo) {
                dinamicsToAdd[matchingInfo.carticleDetailId] =
                    dinamic.typeOfProperty === 'Select - Lista desplegable'
                        ? {label: matchingInfo.detailValue}
                        : matchingInfo.detailValue;
                dispatch(
                    addDinamics({
                        ...inputDinamics,
                        ...dinamicsToAdd,
                        Note: notesInfoEdit,
                        Status: StateSelector(stateEdit),
                    })
                );

                return setValue(
                    String(matchingInfo.carticleDetailId),
                    dinamic.typeOfProperty === 'Select - Lista desplegable'
                        ? {label: matchingInfo.detailValue}
                        : matchingInfo.detailValue
                );
            }
            return null;
        });
    };

    const editState = useSelector((state: RootState) => state.getCartContracts.info);
    const {search} = useSelector((state: RootState) => state.SearchQuoter);

    useEffect(() => {
        if (contractsInfo !== undefined) {
            setDinamicValues();
            setValue('Note', notesInfoEdit);
        }
    }, [search, editState, dinamics]);

    return (
        <>
            {dinamics &&
                dinamics
                    .filter((value) => value.status !== false)
                    .map((item, index: number) => {
                        return (
                            <Fragment key={index}>
                                {item.typeOfPropertyId === 1 && (
                                    <ContainerFlex {...style.selectContent}>
                                        <Text {...style.level}>{item.property}</Text>
                                        <Controller
                                            name={`${item.articleDetailId}`}
                                            control={control}
                                            rules={{
                                                required: item.mandatory
                                                    ? {
                                                          value: true,
                                                          message: 'Indique el campo.',
                                                      }
                                                    : false,
                                                pattern: {
                                                    value: /^[a-zA-Z0-9 ]+$/,
                                                    message: 'Indique solo número y letras.',
                                                },
                                            }}
                                            render={({field: {onChange, ref, ...field}}) => (
                                                <Input
                                                    {...field}
                                                    ref={ref}
                                                    type="text"
                                                    error={
                                                        errors[item.articleDetailId]?.message
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (onChange) onChange(e);
                                                        dispatch(
                                                            addDinamics({
                                                                ...inputDinamics,
                                                                [item.articleDetailId]:
                                                                    e.target.value,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                                            {errors[item.articleDetailId]?.message as string}
                                        </Text>
                                    </ContainerFlex>
                                )}
                                {item.typeOfPropertyId === 2 && (
                                    <ContainerFlex {...style.selectContent}>
                                        <Text {...style.level}>{item.property}</Text>
                                        <Controller
                                            name={`${item.articleDetailId}`}
                                            control={control}
                                            rules={{
                                                required: item.mandatory
                                                    ? {
                                                          value: true,
                                                          message: 'Indique el campo.',
                                                      }
                                                    : false,
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]*)?$/,
                                                    message: 'Indique solo números.',
                                                },
                                            }}
                                            render={({field: {onChange, ref, ...field}}) => (
                                                <Input
                                                    {...field}
                                                    ref={ref}
                                                    type="number"
                                                    error={
                                                        errors[item.articleDetailId]?.message
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (onChange) onChange(e);
                                                        dispatch(
                                                            addDinamics({
                                                                ...inputDinamics,
                                                                [item.articleDetailId]:
                                                                    e.target.value,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                                            {errors[item.articleDetailId]?.message as string}
                                        </Text>
                                    </ContainerFlex>
                                )}
                                {item.typeOfPropertyId === 3 && (
                                    <ContainerFlex {...style.selectContent}>
                                        <Text {...style.level}>{item.property}</Text>
                                        <Controller
                                            name={`${item.articleDetailId}`}
                                            control={control}
                                            rules={{
                                                min: item.mandatory
                                                    ? {
                                                          value: 1,
                                                          message: 'Indique una opción.',
                                                      }
                                                    : 0,
                                            }}
                                            render={({field: {onChange, ...field}}) => (
                                                <SelectGeneral
                                                    {...field}
                                                    placeholder="Selecciona"
                                                    isSearchable={false}
                                                    options={selectConvert(
                                                        item.propertyValueList
                                                            ? item.propertyValueList
                                                            : []
                                                    )}
                                                    hasError={
                                                        errors[item.articleDetailId]?.message
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (onChange) onChange(e);
                                                        dispatch(
                                                            addDinamics({
                                                                ...inputDinamics,
                                                                [item.articleDetailId]: e,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                                            {errors[item.articleDetailId]?.message as string}
                                        </Text>
                                    </ContainerFlex>
                                )}
                                {item.typeOfPropertyId === 4 && (
                                    <ContainerFlex {...style.selectContent}>
                                        <Text
                                            {...style.level}
                                            FontSize="1rem"
                                            Padding="0"
                                            FontWeight="400"
                                        >
                                            {item.property}
                                        </Text>
                                        <ContainerFlex Justify="start" Height="auto">
                                            {item.propertyValueList?.[0] && (
                                                <Text {...style.level} PaddingR=".5rem">
                                                    {item.propertyValueList[0]}
                                                </Text>
                                            )}
                                            <Controller
                                                name={`${item.articleDetailId}`}
                                                control={control}
                                                defaultValue={item.status}
                                                render={({
                                                    field: {onChange, ref, value, ...field},
                                                }) => (
                                                    <Switch
                                                        {...field}
                                                        checked={value}
                                                        inputRef={ref}
                                                        onChange={(e) => {
                                                            if (onChange) onChange(e);
                                                            dispatch(
                                                                addDinamics({
                                                                    ...inputDinamics,
                                                                    [item.articleDetailId]:
                                                                        e.target.value,
                                                                })
                                                            );
                                                        }}
                                                        sx={{
                                                            width: 48,
                                                            height: 24,
                                                            padding: 0,
                                                            borderRadius: 16,
                                                            '& .MuiSwitch-switchBase': {
                                                                color: '#FFF',
                                                                padding: 0,
                                                                margin: 0.25,
                                                                boxShadow:
                                                                    '1px 1px 4px rgba(212, 214, 216, 0.88)',
                                                                '&.Mui-checked': {
                                                                    color: '#5A5AFF',
                                                                    transform: 'translateX(24px)',
                                                                    boxShadow:
                                                                        '1px 1px 4px rgba(172, 172, 255, 0.88)',
                                                                },
                                                                '&.Mui-checked + .MuiSwitch-track':
                                                                    {
                                                                        backgroundColor: '#E5E5FF',
                                                                        opacity: 1,
                                                                    },
                                                            },
                                                            '& .MuiSwitch-track': {
                                                                backgroundColor: '#F4F5F5',
                                                                borderRadius: 16,
                                                                opacity: 1,
                                                                boxShadow:
                                                                    '0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset',
                                                            },
                                                            '& .MuiSwitch-thumb': {
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {item.propertyValueList?.[1] && (
                                                <Text {...style.level} PaddingL=".5rem">
                                                    {item.propertyValueList[1]}
                                                </Text>
                                            )}
                                        </ContainerFlex>
                                    </ContainerFlex>
                                )}
                            </Fragment>
                        );
                    })}
        </>
    );
};
