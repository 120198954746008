import {
    RESET_SAVED_LEVELS,
    SAVE_ARTICLE,
    SAVE_BRAND,
    SAVE_FAMILY,
    SAVE_GROUP,
    SAVE_SUBFAMILY,
} from '@/components/Articles/Redux/ManageTypes';

export const saveGroup = (value: number) => {
    return {
        type: SAVE_GROUP,
        payload: value,
    };
};

export const saveFamily = (value: number) => {
    return {
        type: SAVE_FAMILY,
        payload: value,
    };
};

export const saveSubFamily = (value: number) => {
    return {
        type: SAVE_SUBFAMILY,
        payload: value,
    };
};

export const saveBrand = (value: number) => {
    return {
        type: SAVE_BRAND,
        payload: value,
    };
};

export const saveArticle = (value: number) => {
    return {
        type: SAVE_ARTICLE,
        payload: value,
    };
};

export const resetAllSavedLevels = () => {
    return {
        type: RESET_SAVED_LEVELS,
    };
};
