import React from 'react';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {InputMaskStyled} from '@Roles/InputMaskStyled/style';
import {IPropsAdditionalInformation} from '@components/CreateUsers/Interface';
import {InputStyles} from '@components/CreateUsers/Styles';
import {Controller} from 'react-hook-form';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import arrowdonw from '@images/arrowDonw.svg';
import arrowTop from '@images/arrowTop.svg';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {useDispatch} from 'react-redux';
import {resetGetEmailInUse} from '@components/CreateUsers/Redux/Actions/GetEmailInUse';
import {AppDispatch} from '@/config/store';
import {resetGetPhoneInUse} from '@components/CreateUsers/Redux/Actions/GetPhoneInUse';

export const AdditionalInformation = ({
    register,
    setValue,
    trigger,
    textErrorEmail,
    textErrorPhone,
    inValidEmail,
    isPhoneTouched,
    switchEmail,
    switchPhone,
    emailAvailable,
    phoneAvailable,
    control,
    isAditionalInformation,
}: IPropsAdditionalInformation) => {
    const handleChangeEmail = () => {
        trigger('aditionalInformationEmployee.email');
        setValue('aditionalInformationEmployee.startSesionEmail', !inValidEmail);
    };
    const dispatch: AppDispatch = useDispatch();

    const handleKeyDownMail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            dispatch(resetGetEmailInUse());
        }
    };
    const handleKeyDownPhone = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            dispatch(resetGetPhoneInUse());
        }
    };
    return (
        <>
            <ContainerFlex Justify="start" MarginTop="24px">
                <Text
                    FontSize="1rem"
                    Color="#414042"
                    FontWeight="normal"
                    MarginRight="4px"
                    MarginLeft="10px"
                >
                    Gestionar información adicional
                </Text>
                <ImgLocation
                    Cursor="pointer"
                    Width="12px"
                    src={isAditionalInformation ? arrowTop : arrowdonw}
                    onClick={() => {
                        setValue(
                            'aditionalInformationEmployee.manageAditionalInformation',
                            !isAditionalInformation
                        );
                        trigger('aditionalInformationEmployee');
                    }}
                />
            </ContainerFlex>
            {isAditionalInformation && (
                <ContainerFlex FlexDir="column" MarginTop="24px">
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                            Dirección
                        </Text>
                        <Input
                            Cursor="text"
                            maxLength={160}
                            placeholder="Dirección"
                            Padding="12px"
                            Width="100%"
                            HolderFontSize="0.875rem"
                            {...register('aditionalInformationEmployee.address')}
                        />
                    </ContainerFlex>
                    <ContainerFlex MarginTop="6px" Align="start">
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text
                                Height=""
                                FontSize="0.75rem"
                                Color="#6d6e71"
                                FontWeight="normal"
                                required={switchEmail}
                            >
                                Cuenta de correo electrónico
                            </Text>
                            <Input
                                Cursor="text"
                                maxLength={160}
                                {...register('aditionalInformationEmployee.email')}
                                error={textErrorEmail || emailAvailable}
                                placeholder="Cuenta de correo electrónico"
                                onKeyUp={() => handleChangeEmail()}
                                {...InputStyles}
                                onKeyDown={handleKeyDownMail}
                                HolderFontSize="0.875rem"
                            />
                            {emailAvailable && (
                                <Text
                                    FontSize="0.625rem"
                                    FontWeight="normal"
                                    Color="#ef4f55"
                                    Height=""
                                >
                                    Este correo ya esta en uso
                                </Text>
                            )}
                            {textErrorEmail && (
                                <Text
                                    FontSize="0.625rem"
                                    FontWeight="normal"
                                    Color="#ef4f55"
                                    Height=""
                                >
                                    {textErrorEmail}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="column"
                            Align="start"
                            AlignContent="end"
                            FlexWrap="wrap"
                            Gap="4px"
                        >
                            <Text
                                Height=""
                                FontSize="0.75rem"
                                Color="#6d6e71"
                                FontWeight="normal"
                                required={switchPhone}
                            >
                                Teléfono
                            </Text>
                            <InputMaskStyled
                                error={phoneAvailable || (textErrorPhone && isPhoneTouched)}
                                mask="9999999999"
                                maskChar=""
                                placeholder="Teléfono"
                                padding="12px"
                                fontSize="0.875rem"
                                color="#414042"
                                width="300px"
                                {...register('aditionalInformationEmployee.phone')}
                                onKeyUp={() => trigger('aditionalInformationEmployee.phone')}
                                onKeyDown={handleKeyDownPhone}
                            />
                            {phoneAvailable && (
                                <Text FontSize="0.625rem" FontWeight="normal" Color="#ef4f55">
                                    El teléfono se encuentra asignado a otro usuario
                                </Text>
                            )}
                            {textErrorPhone && (isPhoneTouched ?? false) && (
                                <Text
                                    FontSize="0.625rem"
                                    FontWeight="normal"
                                    Color="#ef4f55"
                                    wSpace="normal"
                                >
                                    {textErrorPhone}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between" MarginTop="8px">
                        <ContainerFlex Justify="start" Width="300px">
                            <Controller
                                name="aditionalInformationEmployee.startSesionEmail"
                                control={control}
                                render={({field}) => (
                                    <GreenSwitch
                                        {...register(
                                            'aditionalInformationEmployee.startSesionEmail'
                                        )}
                                        onChange={(e: {target: {checked: boolean}}) => {
                                            field.onChange(e.target.checked);
                                        }}
                                        checked={field.value}
                                        size="small"
                                    />
                                )}
                            />
                            <Text FontSize="0.75rem" Color="#414042" FontWeight="normal">
                                Iniciar sesión con este correo electrónico
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Width="300px">
                            <Controller
                                name="aditionalInformationEmployee.startSesionPhone"
                                control={control}
                                render={({field}) => (
                                    <GreenSwitch
                                        {...register(
                                            'aditionalInformationEmployee.startSesionPhone'
                                        )}
                                        onChange={(e: {target: {checked: boolean}}) => {
                                            field.onChange(e.target.checked);
                                        }}
                                        checked={field.value}
                                        size="small"
                                    />
                                )}
                            />
                            <Text FontSize="0.75rem" Color="#414042" FontWeight="normal">
                                Iniciar sesión con este teléfono
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};
