import React from 'react';
import {UseFormRegister} from 'react-hook-form';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {APP_SETTINGS} from '@components/AppSettings/constants';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import * as styles from '@components/AppSettings/stylesAppSetting';
import {IFormAppSetting} from '@components/AppSettings/interfaces';

export const NotificationsSettings = ({register}: {register: UseFormRegister<IFormAppSetting>}) => {
    return (
        <ContainerFlex {...styles.contentSetting}>
            <ContainerFlex Justify="space-between">
                <Text {...styles.titleSetting}>{APP_SETTINGS.NOTIFICATIONS}</Text>
                <Text Cursor="pointer" FontSize="0.75rem" FontWeight="500" Color="#5A5AFF">
                    {APP_SETTINGS.SEARCH_NOTIFY}
                </Text>
            </ContainerFlex>

            <ContainerFlex {...styles.switchSettings}>
                <GreenSwitch {...register('sesion')} defaultChecked={true} />
                <Text Color="#2A2C2F">{APP_SETTINGS.SESION}</Text>
            </ContainerFlex>
            <ContainerFlex {...styles.switchSettings}>
                <GreenSwitch {...register('functions')} defaultChecked={true} />
                <Text Color="#2A2C2F">{APP_SETTINGS.NEW_FUNCTIONS}</Text>
            </ContainerFlex>
            <ContainerFlex {...styles.switchSettings}>
                <GreenSwitch {...register('account')} defaultChecked={true} />
                <Text Color="#2A2C2F">{APP_SETTINGS.CHANGES_ACCOUNT}</Text>
            </ContainerFlex>
            <ContainerFlex {...styles.switchSettings}>
                <GreenSwitch {...register('plans')} defaultChecked={true} />
                <Text Color="#2A2C2F">{APP_SETTINGS.CHANGES_PLAN}</Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
