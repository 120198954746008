import axios from 'axios';
import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';

import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {
    ICalculateAutionPass,
    ICalutateSuccess,
    ICatStates,
    IDetailsStadistics,
    IHistoryStadistics,
} from '@Quoter/inferfaces';
import {
    STATESELECT,
    STADISTICS,
    RESET_PASS_QUOTER,
    STADISTICS_GLOBAL,
    RESET_GLOBAL,
} from '@TypesQuoter/Types';

export const StateSelect = (data: {state: number; name: string}) => {
    return {
        type: STATESELECT,
        payload: data,
    };
};

export const CalculateAuctionPass = () => ({type: STADISTICS.INITIAL});
export const CalculateAuctionPassSuccess = (result: ICalutateSuccess) => {
    return {
        type: STADISTICS.SUCCESS,
        calculateAuctionPass: result.data,
    };
};
export const CalculateAuctionPassError = (error: unknown) => {
    return {
        type: STADISTICS.ERROR,
        payload: error,
    };
};

export function CalculateAuctionPassQuoter(token: string, data: ICalculateAutionPass) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString(data);
        dispatch(CalculateAuctionPass());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CaculateAuctionPass}${queryString}`,
                {headers}
            );
            dispatch(CalculateAuctionPassSuccess(response.data));
        } catch (error) {
            dispatch(CalculateAuctionPassError(error));
        }
    };
}

export const catStatesSuccess = (result: {data: ICatStates[]}) => {
    return {
        type: STADISTICS.CATSTATES,
        catStates: result.data,
    };
};

export function States(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(CalculateAuctionPass());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatStates}`,
                {headers}
            );
            dispatch(catStatesSuccess(response.data));
        } catch (error) {
            dispatch(CalculateAuctionPassError(error));
        }
    };
}

export const DetailsInit = () => ({type: STADISTICS.INITDETAILS});
export const ResetStadistics = () => ({type: RESET_GLOBAL});

export const DetailsSuccess = (result: {data: FieldValues}) => {
    return {
        type: STADISTICS.DETAILS,
        details: result.data,
    };
};
export const DetailsError = (error: unknown) => {
    return {
        type: STADISTICS.ERRORDETAILS,
        payload: error,
    };
};

export function DetailsStadistic(token: string, data: IDetailsStadistics) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString(data);
        dispatch(DetailsInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DetailsStadistics}${queryString}`,
                {headers}
            );
            dispatch(DetailsSuccess(response.data));
        } catch (error) {
            dispatch(DetailsError(error));
        }
    };
}

export const DetailsInitGlobal = () => ({type: STADISTICS_GLOBAL.INITDETAILS});
export const DetailsGlobalSuccess = (result: {data: FieldValues}) => {
    return {
        type: STADISTICS_GLOBAL.DETAILS,
        detailsGlobal: result.data,
    };
};
export const DetailsGlobalError = (error: unknown) => {
    return {
        type: STADISTICS_GLOBAL.ERRORDETAILS,
        payload: error,
    };
};

export function DetailsStadisticGlobal(token: string, data: IDetailsStadistics) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString(data);
        dispatch(DetailsInitGlobal());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DetailsStadisticsGlobal}${queryString}`,
                {headers}
            );
            dispatch(DetailsGlobalSuccess(response.data));
        } catch (error) {
            dispatch(DetailsGlobalError(error));
        }
    };
}

export const HistoryInit = () => ({type: STADISTICS.INITHISTORY});
export const HistorySuccess = (result: {data: FieldValues}) => {
    return {
        type: STADISTICS.HISTORY,
        historyDetail: result.data,
    };
};
export const HistoryError = (error: unknown) => {
    return {
        type: STADISTICS.ERRORHISTORY,
        payload: error,
    };
};

export function HistoryStadistic(token: string, data: IHistoryStadistics) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        const queryString = StringUtils.jsonToQueryString(data);
        dispatch(HistoryInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.History}${queryString}`,
                {headers}
            );
            dispatch(HistorySuccess(response.data));
        } catch (error) {
            dispatch(HistoryError(error));
        }
    };
}

export const resetPassQuoter = () => {
    return {
        type: RESET_PASS_QUOTER,
    };
};
