import {
    CREATE_POSTAL_INFO,
    CREATE_POSTAL_INFO_ERROR,
    CREATE_POSTAL_INFO_SUCCESS,
    RESET_POSTAL_INFO,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    postalInfo: [],
};

const GetPostalInfo = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case CREATE_POSTAL_INFO:
            return {
                ...state,
                loading: true,
            };
        case CREATE_POSTAL_INFO_SUCCESS:
            return {
                ...state,
                postalInfo: action.payload.data,
                loading: false,
                error: false,
            };
        case CREATE_POSTAL_INFO_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_POSTAL_INFO:
            return initialState;
        default:
            return state;
    }
};

export default GetPostalInfo;
