import {IAssigmentValidation} from '@/components/Foundry/interfaces';
import {AppDispatch} from '@/config/store';
import {
    GET_ASSIGMENT_VALIDATION,
    GET_ASSIGMENT_VALIDATION_SUCCESS,
    GET_ASSIGMENT_VALIDATION_ERROR,
    PUT_ASSIGNMENT_PROCESS,
    PUT_ASSIGNMENT_PROCESS_SUCCESS,
    PUT_ASSIGNMENT_PROCESS_ERROR,
} from '@TypesSucursal/AssignmentValidationTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
export const fetchGetAssignmentValidation = () => {
    return {
        type: GET_ASSIGMENT_VALIDATION,
    };
};
export const fetchGetAssignmentValidationSuccess = (result: {data: unknown}) => {
    return {
        type: GET_ASSIGMENT_VALIDATION_SUCCESS,
        value: result,
    };
};

export const fetchGetAssignmentValidationError = () => {
    return {
        type: GET_ASSIGMENT_VALIDATION_ERROR,
    };
};
export function getAssignmentValidation(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAssignmentValidation());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentValidation}`,
                {headers}
            );
            dispatch(fetchGetAssignmentValidationSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetAssignmentValidationError());
        }
    };
}

export const fetchPutAssignmentProcess = () => {
    return {
        type: PUT_ASSIGNMENT_PROCESS,
    };
};
export const fetchPutAssignmentProcessSuccess = () => {
    return {
        type: PUT_ASSIGNMENT_PROCESS_SUCCESS,
    };
};

export const fetchPutAssignmentProcessError = () => {
    return {
        type: PUT_ASSIGNMENT_PROCESS_ERROR,
    };
};

export function putAssignmentProcess(data: IAssigmentValidation, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutAssignmentProcess());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentProcess}`,
                data,
                {headers}
            );
            dispatch(fetchPutAssignmentProcessSuccess());
        } catch (error) {
            dispatch(fetchPutAssignmentProcessError);
        }
    };
}
