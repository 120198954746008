import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import InfoBlock from '@/components/Shopify/ClientProfile/Information/InfoBlock';
import {PERSONAL_INFORMATION} from '@Shopify/ClientProfile/constants';

const PersonalInfo: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const clientDetails = useSelector((state: RootState) => state.clientDetails.clientDetails);
    return (
        <ContainerFlex
            Padding="1rem 1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1rem"
            Radius="1.5rem"
            Border="1px solid #E8E9EA"
            Self="stretch"
        >
            <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                {PERSONAL_INFORMATION.TITLE}
            </Text>
            <ContainerFlex Align="flex-start" Gap="1.5rem" Self="stretch">
                <Image
                    src={clientProfile.image}
                    Width="7.625rem"
                    Height="7.625rem"
                    Radius="41%"
                    ObjectFit="cover"
                />
                <ContainerFlex
                    Align="flex-start"
                    Justify="start"
                    FlexDir="column"
                    AlignContent="flex-start"
                    Gap="1rem"
                    FlexWrap="nowrap"
                >
                    <ContainerFlex Gap="1rem" Justify="flex-start">
                        <InfoBlock
                            label={PERSONAL_INFORMATION.NAMES}
                            transformText="capitalize"
                            value={clientDetails?.name}
                        />
                        <InfoBlock
                            label={PERSONAL_INFORMATION.PATERNAL_SURNAME}
                            transformText="capitalize"
                            value={clientDetails?.paternalSurname}
                        />
                    </ContainerFlex>
                    <ContainerFlex Gap="1rem" Justify="flex-start">
                        <InfoBlock
                            label={PERSONAL_INFORMATION.MATERNAL_SURNAME}
                            transformText="capitalize"
                            value={clientDetails?.maternalSurname}
                        />
                        <InfoBlock
                            label={PERSONAL_INFORMATION.EMAIL}
                            value={clientDetails?.email}
                        />
                    </ContainerFlex>
                    <ContainerFlex Gap="1rem" Justify="flex-start">
                        <InfoBlock
                            label={PERSONAL_INFORMATION.CELLULAR_PHONE}
                            value={clientDetails?.phone}
                        />
                        <InfoBlock
                            label={PERSONAL_INFORMATION.BIRTH_DATE}
                            value={clientDetails?.birthDate}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalInfo;
