import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants';

import {
    PUT_ACCESS_TO_SYSTEM_USER,
    PUT_ACCESS_TO_SYSTEM_USER_SUCCESS,
    PUT_ACCESS_TO_SYSTEM_USER_ERROR,
} from '@components/ManageUser/Redux/types';
import {UpdateDataUser} from '@components/ManageUser/Redux/interface';
import {AppDispatch} from '@/config/store';

export const putAccessToSystem = () => ({type: PUT_ACCESS_TO_SYSTEM_USER});
export const putAccessToSystemSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_ACCESS_TO_SYSTEM_USER_SUCCESS,
        payload: result,
    };
};
export const putAccessToSystemError = () => ({
    type: PUT_ACCESS_TO_SYSTEM_USER_ERROR,
});
export const putAccessToSystemAxios = (token: string, data: UpdateDataUser) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(putAccessToSystem());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutAccessToSystem}`,
                data,
                {headers}
            );
            dispatch(putAccessToSystemSuccess(response));
        } catch (error) {
            dispatch(putAccessToSystemError());
        }
    };
};
