import React from 'react';
import {Controller} from 'react-hook-form';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';

const PersonalDataNameInfo = (props: IClientProofStep) => {
    const {register, errors, control} = props;

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.NOMBRES}</Text>
                <Controller
                    name="names"
                    control={control}
                    render={() => (
                        <Input {...register('names')} autoFocus error={!!errors.names?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.names?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.APELLIDO_PATERNO}</Text>
                <Controller
                    name="lastName"
                    control={control}
                    render={() => (
                        <Input {...register('lastName')} error={!!errors.lastName?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.lastName?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.APELLIDO_MATERNO}</Text>
                <Controller
                    name="secondLastName"
                    control={control}
                    render={() => (
                        <Input
                            {...register('secondLastName')}
                            error={!!errors.secondLastName?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.secondLastName?.message as string}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataNameInfo;
