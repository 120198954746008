import React from 'react';
import {useSelector} from 'react-redux';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import CardsPay from '@components/Loan/PaymentQuoter/CardsPay';
import {RootState} from '@/config/store';
import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import ContractCart from '@components/Loan/PaymentQuoter/ContractCart';
import {CART_MESSAGES} from '@components/Loan/PaymentQuoter/constants';
import {formatterDolar} from '@/hooks/currentFormatUtils';

const PaymentQuoter = () => {
    const Contracts: IClienctContactPay = useSelector((state: RootState) => state.getCarContracts);

    return (
        <>
            {!Contracts.loading && (
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    Height="100%"
                    Gap="16px"
                    FlexDir="column"
                    Padding="24px"
                >
                    <Text FontSize="1.5rem" FontWeight="500" Height="2.5rem">
                        {CART_MESSAGES.FINALIZE_PAY}
                    </Text>
                    <CardsPay />
                    <ContainerFlex
                        Radius="24px"
                        Justify="start"
                        Align="start"
                        Padding="16px"
                        Border="1px solid #E8E9EA"
                        FlexDir="column"
                        OverFlow="auto"
                        Gap="24px"
                    >
                        <Text
                            Height="1.25rem"
                            Padding="0.625rem 0"
                            FontSize="1.25rem"
                            FontWeight="500"
                        >
                            {CART_MESSAGES.SUMMARY}
                        </Text>
                        <ContainerFlex
                            Height="300px"
                            Justify="start"
                            Align="start"
                            FlexDir="column"
                            OverFlow="auto"
                        >
                            {Contracts.data?.contractsList?.length > 0 &&
                                Contracts.data.contractsList.map((item, index) => (
                                    <ContractCart contractsList={item} key={index} />
                                ))}
                        </ContainerFlex>
                        <ContainerFlex Justify="end" Align="start" Height="auto">
                            <ContainerFlex
                                GridColumns="50% auto"
                                Display="grid"
                                Height="auto"
                                Gap="8px"
                                Width="30%"
                                Justify="end"
                                Align="end"
                            >
                                <ContainerFlex
                                    Align="start"
                                    Height="auto"
                                    FlexDir="column"
                                    Gap="8px"
                                >
                                    <Text FontSize="1rem" Color="#71767D">
                                        {CART_MESSAGES.SUB_TOTAL}
                                    </Text>

                                    <Text FontSize="1rem" Color="#71767D">
                                        {CART_MESSAGES.IVA}
                                    </Text>

                                    <Text FontSize="1.25rem" Color="#71767D" FontWeight="500">
                                        {CART_MESSAGES.TOTAL}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Align="start"
                                    Height="auto"
                                    FlexDir="column"
                                    Gap="8px"
                                    Width="auto"
                                >
                                    <Text FontSize="1rem" Color="#2A2C2F" Self="end">
                                        -{formatterDolar.format(Contracts.data.subtotal)}
                                    </Text>
                                    <Text FontSize="1rem" Color="#2A2C2F" Self="end">
                                        {formatterDolar.format(Contracts.data.iva)}
                                    </Text>
                                    <Text
                                        FontSize="1.25rem"
                                        FontWeight="500"
                                        Color="#2A2C2F"
                                        Self="end"
                                    >
                                        -{formatterDolar.format(Contracts.data.total)}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};

export default PaymentQuoter;
