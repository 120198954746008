import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {
    VALIDATE_LINK_EXPIRED,
    VALIDATE_LINK_EXPIRED_SUCCESS,
    VALIDATE_LINK_EXPIRED_ERROR,
} from '@components/Login/Redux/Types/Types';

export const validateLink = () => {
    return {
        type: VALIDATE_LINK_EXPIRED,
    };
};
export const validateLinkSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_LINK_EXPIRED_SUCCESS,
        payload: result,
    };
};
export const validateLinkError = (error: AxiosError) => {
    return {
        type: VALIDATE_LINK_EXPIRED_ERROR,
        error: error,
    };
};

export function validateLinkExpired(rowId: string) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(validateLink());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPasswordRecoveryExpiration}`,
                {
                    headers,
                    params: {rowId},
                }
            );
            dispatch(validateLinkSuccess(response));
        } catch (error) {
            dispatch(validateLinkError(error as AxiosError));
        }
    };
}
