import {
    RESEND_EMAIL_VALIDATION_RESET,
    RESEND_EMAIL_VALIDATION_ERROR,
    RESEND_EMAIL_VALIDATION_START,
    RESEND_EMAIL_VALIDATION_SUCCESS,
} from '@/components/Login/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {IDataLoginMail} from '@components/Login/Administrative/interfaces';
import {AppDispatch} from '@/config/store';

export const postResendEmailValidationStart = () => {
    return {
        type: RESEND_EMAIL_VALIDATION_START,
    };
};
export const postResendEmailValidationSuccess = (result: AxiosResponse) => {
    return {
        type: RESEND_EMAIL_VALIDATION_SUCCESS,
        payload: result,
    };
};
export const postResednEmailValidationError = (error: AxiosError) => {
    return {
        type: RESEND_EMAIL_VALIDATION_ERROR,
        error: error,
    };
};
export function postResendEmailValidation(
    data: IDataLoginMail,
    setEmailSuccess: React.Dispatch<React.SetStateAction<boolean>>
) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postResendEmailValidationStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ResendEmailValidation}
            `,
                data,
                {headers}
            );
            dispatch(postResendEmailValidationSuccess(response));
            setEmailSuccess(true);
        } catch (error) {
            dispatch(postResednEmailValidationError(error as AxiosError));
        }
    };
}

export const resetResendEmailValidation = () => {
    return {
        type: RESEND_EMAIL_VALIDATION_RESET,
    };
};
