import {HANDLE_CHECKBOX_PAYMENT_METHODS} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IPaymentMethodState} from '@components/PersonalLoans/NewLoanProduct/interfaces';

const initialState: IPaymentMethodState[] = [
    {
        id: 1,
        type: 'Transferencia bancaria',
        description: 'Desde cualquier banco nacional',
        isChecked: false,
    },
    {
        id: 2,
        type: 'Tarjeta de Débito',
        description: 'Mastercard, Visa',
        isChecked: false,
    },
    {
        id: 3,
        type: 'Tarjeta de Crédito',
        description: 'Mastercard, Visa y American Express',
        isChecked: false,
    },
    {
        id: 4,
        type: 'Pago en efectivo',
        description: 'Solo disponibles si tienes tiendas físicas registradas.',
        isChecked: false,
    },
    {
        id: 5,
        type: 'Red Ataskate',
        description: 'Pagos aceptados en todos los negocios de la red Ataskate',
        isChecked: false,
    },
];

const setPaymentMethods = (
    state = initialState,
    action: {type: string; payload: number}
): IPaymentMethodState[] => {
    switch (action.type) {
        case HANDLE_CHECKBOX_PAYMENT_METHODS:
            return state.map((item) => {
                if (item.id === action.payload) {
                    return {
                        ...item,
                        isChecked: !item.isChecked,
                    };
                }
                return item;
            });
        default:
            return state;
    }
};

export default setPaymentMethods;
