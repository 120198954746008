import React from 'react';
import {Controller} from 'react-hook-form';

import SelectGeneral from '@General/Atoms/Select/Select';
import {ITimeZone} from '@components/AppSettings/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {APP_SETTINGS} from '@components/AppSettings/constants';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import * as styles from '@components/AppSettings/stylesAppSetting';

export const TimeZoneSettings = ({control, register, watch}: ITimeZone) => {
    return (
        <ContainerFlex {...styles.contentSetting}>
            <Text {...styles.titleSetting}>{APP_SETTINGS.TIME_ZONE}</Text>
            <ContainerFlex {...styles.switchSettings}>
                <GreenSwitch {...register('timeZoneAutomatic')} defaultChecked={true} />
                <Text Color="#2A2C2F">{APP_SETTINGS.AUTOMATIC_TIME_ZONE}</Text>
            </ContainerFlex>
            <ContainerFlex Justify="start" Height="auto" Width="15.625rem">
                <Controller
                    name="timeZone"
                    control={control}
                    render={({field}) => (
                        <SelectGeneral
                            {...field}
                            isSearchable={false}
                            isDisabled={Boolean(watch('timeZoneAutomatic'))}
                            options={[{value: 1, label: 'CTS (CDMX)'}]}
                        />
                    )}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
