import React from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_ADDRESS, VERIFY_FIELD} from '@MyCompany/constants';
import {updateAddressResolver} from '@MyCompany/updateCompany';
import {IFormAddress, IUpdateAddress} from '@MyCompany/Interface';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {FormUpdateAddress} from '@MyCompany/ModalAddress/FormUpdateAddress';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';

export const UpdateAddress = ({updateAddress, setUpdateAddress}: IUpdateAddress) => {
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: {errors},
    } = useForm<IFormAddress>({
        defaultValues: {
            label: '',
            postalCode: '',
            street: '',
            OutNumber: '',
            InternalNumber: '',
            colonyId: {},
            municipalityId: {},
            stateId: {},
        },
        resolver: yupResolver(updateAddressResolver),
    });
    const cancelOperation = () => {
        setUpdateAddress(false);
        reset();
    };

    const onSubmit = () => cancelOperation();

    return (
        <Modal
            Top="0"
            modalState={updateAddress}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" Padding="0.75rem 0" FontWeight="500" Color="#2A2C2F">
                    {UPDATE_ADDRESS.ADDRESS}
                </Text>
                <FormUpdateAddress register={register} errors={errors} control={control} />
                {errors &&
                    (errors.label ||
                        errors.postalCode ||
                        errors.street ||
                        errors.OutNumber ||
                        errors.colonyId ||
                        errors.municipalityId ||
                        errors.stateId) && (
                        <Text Self="center" Color="#FF6357">
                            {VERIFY_FIELD}
                        </Text>
                    )}
                <ContainerFlex Height="auto" Gap="1.5rem">
                    <Text
                        Color="#FF6357"
                        Width="100%"
                        Cursor="pointer"
                        Height="40px"
                        Justify="center"
                        onClick={() => cancelOperation()}
                    >
                        {UPDATE_ADDRESS.CANCEL}
                    </Text>
                    <ButtonGeneral width="100%" text={UPDATE_ADDRESS.SAVE} />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
