import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {
    GET_DEADLINE,
    GET_DEADLINE_SUCCESS,
    GET_DEADLINE_ERROR,
    GET_FILTERS_DEADLINE,
    FILTERS,
} from '@ReduxDeadlinesindex/types/DeadlineTypes';
import {AllDeadLines} from '@ReduxDeadlinesindex/types/interface';
import {Dispatch} from 'redux';

export const fetchDeadline = () => {
    return {
        type: GET_DEADLINE,
    };
};
export const fetchGetFiltersDeadline = (filters: AllDeadLines) => {
    return {
        type: GET_FILTERS_DEADLINE,
        payload: filters,
    };
};
export const fetchDeadlineSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_DEADLINE_SUCCESS,
        payload: result,
    };
};

export const searchDeadlines = (result: string) => {
    return {
        type: FILTERS.SEARCH_DEADLINE,
        payload: result,
    };
};
export const deleteDeadlines = () => {
    return {
        type: FILTERS.DELETE_SHEARCH,
    };
};
export const actualPage = (result: number) => {
    return {
        type: FILTERS.ACTUAL_PAGE,
        payload: result,
    };
};
export const filterHead = (result: number) => {
    return {
        type: FILTERS.FILTER_HEAD,
        payload: result,
    };
};
export const orderType = (result: number) => {
    return {
        type: FILTERS.ORDER_TYPE,
        payload: result,
    };
};
export const fetchGetDeadlineError = () => {
    return {
        type: GET_DEADLINE_ERROR,
    };
};

export const allDeadlines = (filters: AllDeadLines, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetFiltersDeadline(filters));
        dispatch(fetchDeadline());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllDeadline}`,
                filters,
                {headers}
            );
            dispatch(fetchDeadlineSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetDeadlineError());
        }
    };
};
