import {
    GET_CLIENT_LIST,
    GET_CLIENT_LIST_ERROR,
    GET_CLIENT_LIST_SUCCESS,
    RESET_SEARCH,
    SELECT_CLIENT,
    ERROR_CLIENT,
} from '@components/ShoppingCart/Redux/types';
import {AxiosResponse} from 'axios';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';

const initialState: IClientData = {
    error: false,
    loading: false,
    emptyClient: false,
    data: [],
    client: {
        id: 0,
        name: '',
        nuc: 0,
        status: false,
        roleId: 0,
        email: '',
        stars: 0,
        apartsNumber: 0,
        pawnsNumber: 0,
        apartsAvailable: 0,
        phone: '',
        score: '',
        pointsScore: '',
        percentScore: '',
        urlImageProfile: '',
        isTrustworthyPerson: false,
        firstFolio: '',
        companyId: 0,
        expirationDate: '',
    },
};

const clientList = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case SELECT_CLIENT:
            return {...state, client: action.payload};
        case GET_CLIENT_LIST:
            return {...state, loading: true};
        case GET_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: false,
            };
        case GET_CLIENT_LIST_ERROR:
            return {...state, loading: false, error: true, data: []};
        case ERROR_CLIENT:
            return {...state, emptyClient: action.payload};
        case RESET_SEARCH:
            return initialState;
        default:
            return state;
    }
};

export default clientList;
