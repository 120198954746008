export const NUMBER_STEP_1 = '1';
export const NUMBER_STEP_2 = '2';
export const NUMBER_STEP_3 = '3';
export const NUMBER_STEP_4 = '4';
export const NUMBER_STEP_5 = '5';

export const NUMBER_1_1 = '1-1';
export const NUMBER_1_2 = '1-2';
export const NUMBER_1_3 = '1-3';

export const LEVEL_NAME_GROUP = 'GROUP';
export const LEVEL_NAME_FAMILY = 'FAMILY';
export const LEVEL_NAME_SUBFAMILY = 'SUBFAMILY';
export const LEVEL_NAME_BRAND = 'BRAND';
export const LEVEL_NAME_ARTICLE = 'ARTICLE';

export const QUOTER = {
    TITLE: 'Cotizador',
    PAYMENT: 'Carrito',
    BTNBACK: 'Atrás',
};

export const LEVELS = {
    GROUP: 0,
    FAMILY: 1,
    SUBFAMILY: 2,
    BRAND: 3,
    ARTICLE: 4,
};

export const SEARCHVALUES = {
    product: '',
    code: '',
};

export const PROGRESSITEMS = [
    {step: 1, title: 'Valuación'},
    {step: 2, title: 'Contrato y plazos'},
    {step: 3, title: 'Cotitular y registro fotográfico'},
];

export const MESSAGESEARCH = {
    SEARCH: 'Busca el artículo que quieres cotizar o agrega código de cotización',
    NOFAUNTCAD: 'Parece que este artículo no existe en el catálogo',
    CONTACT: 'Contacta al administrador de tu empresa',
    NOSEARCH: '¿No encontraste lo que buscabas?',
    UPARTICLE:
        'Para habilitar la opción de empeñar este artículo en tu sucursal, por favor, ponte en contacto con el administrador de tu empresa para que pueda activarlo.',
    WITHOUTCATEGORY: 'Es probable que la categoría que buscas todavía no esté creada.',
    BTNSOLICITY: 'Solicitar alta en el catálogo',
    CODE: 'Código de cotización',
    EXTRACTION: '¿Estás haciendo una extracción?',
    INACTIVE_ARTICLE:
        'Este artículo no está actualmente disponible para ser empeñado en tu empresa.',
};

export const CATEGORTIZATION = {
    SUBTITLE: 'Categorización',
    DETAILS: 'Añadir detalles avanzados',
    LEVEL1: 'Nivel 1',
    LEVEL2: 'Nivel 2',
    LEVEL3: 'Nivel 3',
    BRAND: 'Marca',
    ARTICLE: 'Artículo',
    NOTES: 'Notas adicionales',
};

export const APPRASIAL = {
    SUBTITLE: 'Cotización',
    TABCOMPANY: 'Mi empresa',
    TABATASKATE: 'Ataskate global',
    HISTORY: 'Ver historial de valuación',
    ERRORMESSAGE: 'Indique una Referencia',
    APPRASIALTITLE: 'Avalúo',
    APPRASIALTITLESUGGEST: 'Avalúo sugerido:',
    LOAN: 'Préstamo',
    STATE: 'Estado',
    AVG: '% de préstamo',
    PRICESALE: 'Precio de venta',
    STADISTICS: 'Estadística de producto',
    MY_COMPANY: 'Mi empresa',
};

export const APPRASIALTEMPORAL = {
    TITLE1: 'Promedio de avalúo',
    GLOBAL: 'Ataskate Global',
    DATA1: '$16,000',
    TITLE2: 'Valor de préstamo',
    SUBTITLE2: 'Mín.',
    SUBTITLE3: 'Máx.',
    TITLE3: 'Promedio de venta',
    TITLE4: 'Pase almoneda',
    SUBTITLE4: 'Porcentaje',
};

export const TITLETABS = [
    {id: 1, description: 'Mi empresa'},
    {id: 2, description: 'Ataskate global'},
];

export const TITLEHISTORY = [
    {title: 'Mes/Año'},
    {title: 'Valor avaluo'},
    {title: 'Valor préstamo'},
    {title: 'Precio de venta'},
    {title: '% promedio de préstamo'},
];

export const SPECIALTITLE = 'Mes/Año';

export const HISTORY = {
    HISTORY: 'Historial de valuación',
    ICON: 'close',
    PRODUCT: 'Galaxy Z Flip5 256GB, rojo.',
    DAYS: 'Prom. días de envejecimiento:',
    APPRASIAL: 'Avalúo',
    LOAN: 'Préstamo',
    AWESOME: 'Tasa de venta exitosa:',
    WITHOUTINTORMATION: 'Sin información',
};

export const WITHOUTPRICE = {
    NOPRICE: '-',
    AVG: 'AVG',
    DOLLAR: 'DOLLAR',
};

export const ERROR_MESSAGES = {
    TITLE_PROMOTION: 'Vincula al cliente dentro del carrito',
    SUB_TITLE_PROMOTION: 'Para plazos personalizados, por favor, vincula un cliente. ',
    TITLE_DEADLINE: 'No puedes continuar sin vincular un cliente dentro del carrito ',
    TITLE_PROMOTIONS_ERROR: 'Selecciona un plazo para cada contrato',
    SUB_TITLE_PROMOTION_DEADLINE: 'Para plazos personalizados, por favor, vincula un cliente. ',
};

export const TABLE_PARAMS = [
    {title: 'Fecha de pago límite'},
    {title: 'Mutuo'},
    {title: '% Interés'},
    {title: 'Interés'},
    {title: 'IVA'},
    {title: 'Refrendo'},
    {title: 'Desempeño'},
];
