import {ResponseData} from '@components/CreateUsers/Interface';
import {
    GET_USERNAME_START,
    GET_USERNAME_SUCCESS,
    GET_USERNAME_ERROR,
    RESET_GET_USERNAME,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState = {
    usernameAvailable: false,
    data: null,
    loading: false,
    error: false,
};

const GetUserName = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_USERNAME_START:
            return {
                ...state,
                loading: true,
            };
        case GET_USERNAME_SUCCESS:
            return {
                ...state,
                usernameAvailable: action.payload.data.data,
                loading: false,
            };
        case GET_USERNAME_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_GET_USERNAME:
            return initialState;
        default:
            return state;
    }
};

export default GetUserName;
