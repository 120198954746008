import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import CustomerInfoModalVoucher from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalVoucher';
import {MODAL_INFO_BODY} from '@/components/CreditsAndPawns/constants';
import frontalIcon from '@/components/CreditsAndPawns/icons/frontalIcon.svg';
import {ICustomerInfoVoucherProps} from '@/components/CreditsAndPawns/interfaces';

const CustomerInfoModalVouchers = ({vouchers}: {vouchers: ICustomerInfoVoucherProps[]}) => {
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="16px">
            <ContainerFlex Gap="8px" Width="100%" Height="29px" Justify="start">
                <Image src={frontalIcon} />
                <Text
                    FontSize="1.25rem"
                    FontWeight="700"
                    Color="#1D1E20"
                    FontFamily="Nunito"
                    FontStyle="normal"
                    LHeight="1.50rem"
                >
                    {MODAL_INFO_BODY.SUBTITLE_VOUCHERS}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Width="100%" Gap="16px" Justify="start" Align="start">
                {vouchers?.map((voucher) => (
                    <CustomerInfoModalVoucher key={voucher.id} {...voucher} />
                ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CustomerInfoModalVouchers;
