import {ResponseData} from '@/components/ManageUser/interface';
import {TREE_LOCATIONS} from '@TypesManageLocations/ManageLocationsTypes';

const initialState = {
    treeLocation: null,
    loading: true,
    error: true,
};

const TreeBranchesLocations = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case TREE_LOCATIONS.INITIAL:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case TREE_LOCATIONS.SUCCESS:
            return {
                ...state,
                treeLocation: action.payload,
                loading: false,
                error: false,
            };
        case TREE_LOCATIONS.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default TreeBranchesLocations;
