import React from 'react';
import {Svg} from '@Shopify/Ecommerce/styles';

export const CameraSmall = () => {
    return (
        <Svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            hoverF="#35CF44"
        >
            <mask
                id="mask0_1005_67540"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="13"
                height="13"
            >
                <rect width="13" height="13" fill="#D9D9D9" />
            </mask>
            <path
                d="M10.2916 4.33337V3.25004H9.20829V2.16671H10.2916V1.08337H11.375V2.16671H12.4583V3.25004H11.375V4.33337H10.2916ZM1.62496 11.9167C1.32704 11.9167 1.0721 11.8107 0.860126 11.5987C0.647793 11.3864 0.541626 11.1313 0.541626 10.8334V4.33337C0.541626 4.03546 0.647793 3.78051 0.860126 3.56854C1.0721 3.35621 1.32704 3.25004 1.62496 3.25004H3.33121L4.33329 2.16671H7.58329V3.25004H4.80725L3.81871 4.33337H1.62496V10.8334H10.2916V5.95837H11.375V10.8334C11.375 11.1313 11.269 11.3864 11.057 11.5987C10.8447 11.8107 10.5895 11.9167 10.2916 11.9167H1.62496ZM5.95829 10.0209C6.63538 10.0209 7.21099 9.78399 7.68513 9.31021C8.1589 8.83607 8.39579 8.26046 8.39579 7.58337C8.39579 6.90629 8.1589 6.33068 7.68513 5.85654C7.21099 5.38276 6.63538 5.14587 5.95829 5.14587C5.28121 5.14587 4.7056 5.38276 4.23146 5.85654C3.75768 6.33068 3.52079 6.90629 3.52079 7.58337C3.52079 8.26046 3.75768 8.83607 4.23146 9.31021C4.7056 9.78399 5.28121 10.0209 5.95829 10.0209ZM5.95829 8.93754C5.57913 8.93754 5.25864 8.80664 4.99683 8.54483C4.73503 8.28303 4.60413 7.96254 4.60413 7.58337C4.60413 7.20421 4.73503 6.88372 4.99683 6.62192C5.25864 6.36011 5.57913 6.22921 5.95829 6.22921C6.33746 6.22921 6.65794 6.36011 6.91975 6.62192C7.18156 6.88372 7.31246 7.20421 7.31246 7.58337C7.31246 7.96254 7.18156 8.28303 6.91975 8.54483C6.65794 8.80664 6.33746 8.93754 5.95829 8.93754Z"
                fill="white"
            />
        </Svg>
    );
};
