import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {GENERAL_TITLES, IDelete} from '@components/Users/UserProfile/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';

export const DeleteUser = ({setShow}: IDelete) => {
    return (
        <ContainerFlex Position="fixed" ZIndex="5">
            <ContainerFlex
                FlexDir="column"
                Gap="24px"
                Justify="start"
                Height="208px"
                Align="start"
                Radius="24px"
                BoxS=" 0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                Padding="24px"
                Width="47.87%"
                backG="#FFFFFF"
                PositionRight="10%"
                Position="relative"
            >
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.DELETE_ALL}
                </Text>
                <ContainerFlex Justify="start" Align="start" Height="1rem" ColumnGap="8px">
                    <Text FontSize="1rem">{GENERAL_TITLES.DELETE_MESSAGE}</Text>
                    <Text FontSize="1rem" FontWeight="700">
                        {GENERAL_TITLES.USERS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex ColumnGap="16px" Height="72px" Padding="12px 0 0 0">
                    <ButtonGeneral
                        text="Cancelar"
                        secondaryButton
                        height="40px"
                        width="25%"
                        clic={() => setShow(false)}
                    />
                    <ContainerFlex
                        Radius="32px"
                        backG="#A82424"
                        Width="25%"
                        Height="40px"
                        HBackground="#501111"
                        onClick={() => setShow(false)}
                        Cursor="pointer"
                    >
                        <Text FontSize="1rem" FontWeight="700" Color="#FFFFFF" Cursor="pointer">
                            {GENERAL_TITLES.DELETE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
