import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants';
import {AppDispatch} from '@/config/store';

import {
    PROPERTIES_ARTICLES_REQUEST,
    PROPERTIES_ARTICLES_REQUEST_SUCCESS,
    PROPERTIES_ARTICLES_REQUEST_ERROR,
    CANCEL_QUOTER_REQUEST,
    LEVELS_REQUEST_SOL,
    RESET_ARTCILE_DINAMICS_REQUEST,
} from '@TypesQouter/Types';
import StringUtils from '@GenericScripts/utils';
import {levelArticle, levelBrand, levelFamily, levelSubFamily} from '@Quoter/Redux/intefaces';
import {IArticle, IFamily, IGroup, IProperties, ISubfamily} from '@Quoter/inferfaces';

export const articlePropertiesRequest = () => ({type: PROPERTIES_ARTICLES_REQUEST});
export const articleloadRequest = (value: boolean) => {
    return {type: LEVELS_REQUEST_SOL.LOADING, loadingArticle: value};
};
export const articlePropertiesRequestSuccesss = (result: {data: []}) => {
    return {
        type: PROPERTIES_ARTICLES_REQUEST_SUCCESS,
        propertiesarticles: result.data,
    };
};

export const articlePropertiesRequestError = () => ({
    type: PROPERTIES_ARTICLES_REQUEST_ERROR,
});

export const resetDinamics = () => {
    return {
        type: CANCEL_QUOTER_REQUEST,
    };
};

export const resetInputRequestDinamics = () => {
    return {
        type: RESET_ARTCILE_DINAMICS_REQUEST,
    };
};

export const articleDinamicsRequest = (token: string, filters: IProperties) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(articlePropertiesRequest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ArticleProperties}${queryString}`,
                {headers}
            );
            dispatch(articlePropertiesRequestSuccesss(response.data));
        } catch (error) {
            dispatch(articlePropertiesRequestError());
        }
    };
};

export const ErrorPetitionRequest = (value: AxiosError) => {
    return {
        type: LEVELS_REQUEST_SOL.ERRORPETITION,
        payload: value,
    };
};
export const GetGroupRequest = (value: {data: {breadCrumbs: null; groupList: IGroup[]}}) => {
    return {
        type: LEVELS_REQUEST_SOL.LEVELGROUP,
        group: value.data,
    };
};
export const GroupRequest = (token: string, branchId: number) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelGroup.replace(
                    '{branchId}',
                    String(branchId)
                )}`,
                {headers}
            );
            dispatch(GetGroupRequest(response.data));
        } catch (error) {
            dispatch(ErrorPetitionRequest(error as AxiosError));
        }
    };
};

export const GetFamilyRequest = (value: {data: {breadCrumbs: null; familyData: IFamily[]}}) => {
    return {
        type: LEVELS_REQUEST_SOL.LEVELFAMILY,
        family: value.data,
    };
};
export const FamilyRequest = (token: string, data: levelFamily) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelFamily}${queryString}`,
                {headers}
            );
            dispatch(GetFamilyRequest(response.data));
        } catch (error) {
            dispatch(ErrorPetitionRequest(error as AxiosError));
        }
    };
};

export const GetSubfamilyRequest = (value: {
    data: {breadCrumbs: null; subfamilyList: ISubfamily[]};
}) => {
    return {
        type: LEVELS_REQUEST_SOL.LEVELSUBFAMILY,
        subfamily: value.data,
    };
};

export const SubfamilyRequest = (token: string, data: levelSubFamily) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelSubFamily}${queryString}`,
                {headers}
            );
            dispatch(GetSubfamilyRequest(response.data));
        } catch (error) {
            dispatch(ErrorPetitionRequest(error as AxiosError));
        }
    };
};
export const GetBrandRequest = (value: {data: {breadCrumbs: null; brandList: ISubfamily[]}}) => {
    return {
        type: LEVELS_REQUEST_SOL.LEVELBRAND,
        brand: value.data,
    };
};
export const BrandRequest = (token: string, data: levelBrand) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelBrand}${queryString}`,
                {headers}
            );
            dispatch(GetBrandRequest(response.data));
        } catch (error) {
            dispatch(ErrorPetitionRequest(error as AxiosError));
        }
    };
};

export const GetArticleRequest = (value: {data: {breadCrumbs: null; articleList: IArticle[]}}) => {
    return {
        type: LEVELS_REQUEST_SOL.LEVELARTICLE,
        article: value.data,
    };
};

export const ArticleRequest = (token: string, data: levelArticle) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelArticle}${queryString}`,
                {headers}
            );
            dispatch(GetArticleRequest(response.data));
        } catch (error) {
            dispatch(ErrorPetitionRequest(error as AxiosError));
        }
    };
};
