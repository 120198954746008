import {
    LEVEL,
    SAVE_LEVEL,
    SAVE_LEVEL_NODES,
    CHILD,
    PAGE_NUMBER,
    SEARCH_ORGANIZATION,
} from '@TypesOrgCreation/types';

const initialState: {
    node: [];
    levelnodes: [];
    level: number;
    pageNumber: number;
    search: string;
    child: boolean;
    loading: boolean;
    error: boolean;
} = {
    node: [],
    levelnodes: [],
    level: 1,
    pageNumber: 1,
    search: '',
    child: false,
    loading: false,
    error: false,
};
const GetLevelsData = (
    state = initialState,
    action: {
        type: string;
        node: [];
        levelnodes: [];
        level: number;
        pageNumber: number;
        search: string;
        child: boolean;
        loading: boolean;
        error: boolean;
    }
): {
    node: [];
    levelnodes: [];
    level: number;
    pageNumber: number;
    search: string;
    child: boolean;
    loading: boolean;
    error: boolean;
} => {
    switch (action.type) {
        case SAVE_LEVEL:
            return {
                ...state,
                node: action.node,
                loading: false,
            };
        case SAVE_LEVEL_NODES:
            return {
                ...state,
                levelnodes: action.levelnodes,
                loading: false,
            };

        case LEVEL:
            return {
                ...state,
                level: action.level,
                loading: false,
            };
        case PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.pageNumber,
                loading: false,
            };
        case CHILD:
            return {
                ...state,
                child: action.child,
                loading: false,
            };
        case SEARCH_ORGANIZATION:
            return {
                ...state,
                search: action.search,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetLevelsData;
