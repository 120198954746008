import {IStatusStadistics, IRStatusStadistics} from '@Quoter/inferfaces';
import {
    STATESELECT,
    STADISTICS,
    RESET_PASS_QUOTER,
    STADISTICS_GLOBAL,
    RESET_GLOBAL,
} from '@TypesQouter/Types';

const initialState: IStatusStadistics = {
    stateSelect: {
        state: 1,
        name: 'Excelente',
    },
    calculateAuctionPass: {},
    catStates: [{id: 0, stateConservationName: ''}],
    details: {},
    detailsGlobal: {},
    historyDetail: {},
    error: false,
    loading: false,
};
const statusQuoter = (state = initialState, actions: IRStatusStadistics): IStatusStadistics => {
    switch (actions.type) {
        case STATESELECT:
            return {
                ...state,
                loading: false,
                stateSelect: actions.stateSelect,
            };
        case RESET_GLOBAL:
            return initialState;

        case STADISTICS.INITIAL:
            return {
                ...state,
                loading: true,
            };
        case STADISTICS.SUCCESS:
            return {
                ...state,
                loading: false,
                calculateAuctionPass: actions.calculateAuctionPass,
            };
        case STADISTICS.CATSTATES:
            return {
                ...state,
                loading: false,
                catStates: actions.catStates,
            };
        case STADISTICS.ERROR:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case STADISTICS.INITDETAILS:
            return {
                ...state,
                loading: true,
            };
        case STADISTICS.DETAILS:
            return {
                ...state,
                loading: false,
                details: actions.details,
            };
        case STADISTICS.ERRORDETAILS:
            return {
                ...state,
                error: false,
                loading: false,
            };
        case STADISTICS_GLOBAL.INITDETAILS:
            return {
                ...state,
                loading: true,
            };
        case STADISTICS_GLOBAL.DETAILS:
            return {
                ...state,
                loading: false,
                detailsGlobal: actions.detailsGlobal,
            };
        case STADISTICS_GLOBAL.ERRORDETAILS:
            return {
                ...state,
                error: false,
                loading: false,
            };
        case STADISTICS.INITHISTORY:
            return {
                ...state,
                loading: true,
            };
        case STADISTICS.HISTORY:
            return {
                ...state,
                loading: false,
                historyDetail: actions.historyDetail,
            };
        case STADISTICS.ERRORHISTORY:
            return {
                ...state,
                error: false,
                loading: false,
            };
        case RESET_PASS_QUOTER:
            return initialState;
        default:
            return state;
    }
};

export default statusQuoter;
