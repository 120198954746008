import {ContainerAdminCards} from '@Ecommerce/styles';
import React from 'react';
export default function Support() {
    return (
        <ContainerAdminCards>
            <div onClick={() => (window.location.href = `/Reactivations`)}>
                <h2>Soporte</h2>
                <span className="">
                    <i className=" material-icons edit_calendar">edit_calendar</i>
                </span>
                <span>Condonaciones</span>
            </div>
        </ContainerAdminCards>
    );
}
