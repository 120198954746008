import React, {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider} from 'react-hook-form';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_ADDRESS} from '@MyCompany/constants';
import {HOLIDAYS} from '@components/LocationsBranch/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {HolidaysForm} from '@components/LocationsBranch/Holidays/HolidayForm';
import {holidaysShema} from '@components/LocationsBranch/Holidays/holidayErrors';
import {IHolidayFields, IHolidaysAdd} from '@components/LocationsBranch/interfaces';

export const HolidaysAdd = ({showHolidaysModal, setShowHolidaysModal}: IHolidaysAdd) => {
    const methods = useForm();
    const [openingClose, setOpeningClose] = useState(false);
    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        formState: {errors},
    } = useForm<IHolidayFields>({
        defaultValues: {
            holiday: '',
            eachYears: true,
            openingClose: openingClose,
            opening: {},
            ending: {},
        },
        resolver: yupResolver(holidaysShema(openingClose)),
    });

    const cancelOperation = () => {
        setShowHolidaysModal(false);
        reset();
    };

    const onSubmit = () => cancelOperation();

    return (
        <Modal
            Top="0"
            modalState={showHolidaysModal}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <FormProvider {...methods}>
                <ContainerForm
                    onSubmit={handleSubmit(onSubmit)}
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Align="start"
                    Justify="start"
                    Padding="1.5rem"
                    Gap="1.5rem"
                >
                    <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                        {HOLIDAYS.TITLE}
                    </Text>
                    <HolidaysForm
                        register={register}
                        reset={reset}
                        watch={watch}
                        control={control}
                        errors={errors}
                        openingClose={openingClose}
                        setOpeningClose={setOpeningClose}
                    />
                    <ContainerFlex Height="auto" Gap="1.5rem">
                        <ButtonGeneral
                            width="100%"
                            text={UPDATE_ADDRESS.CANCEL}
                            clic={() => cancelOperation()}
                            color="#5A5AFF"
                            backGround="#FFFFFF"
                            hText="#5A5AFF"
                            hShadow=""
                            height="40px"
                            cursor="pointer"
                            border="1px solid #5A5AFF"
                            hBackG="#E5E5FF"
                        />
                        <ButtonGeneral width="100%" text={UPDATE_ADDRESS.SAVE} />
                    </ContainerFlex>
                </ContainerForm>
            </FormProvider>
        </Modal>
    );
};
