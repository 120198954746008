import {IRoleList, IModules, ICheckTypeRole} from '@/components/Roles/AddRoles/interfaces';
import {PERSISTENCE_ROLES} from '@Roles/CreateRoles/Redux/Types';

export const roleList = (data: IRoleList) => ({
    type: PERSISTENCE_ROLES.TYPE,
    typeRol: data,
});

export const rolesCheck = (check: ICheckTypeRole) => ({
    type: PERSISTENCE_ROLES.CHECKS,
    roles: check,
});

export const modulesPersis = (data: IModules[]) => ({
    type: PERSISTENCE_ROLES.PERMISSIONS,
    modules: data,
});

export const assignment = (data: number[]) => ({
    type: PERSISTENCE_ROLES.ASSIGNMENT,
    userId: data,
});

export const typeActionRole = (data: string) => ({
    type: PERSISTENCE_ROLES.TYPE_ACTION,
    typeAction: data,
});

export const resetRoles = () => ({type: PERSISTENCE_ROLES.RESET});
