import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@components/CreateUsers/Styles';
import {BRANCHLOCATION} from '@components/CreateUsers/constants';
import {ITreeBranch} from '@components/CreateUsers/Redux/interface';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {saveTree, treeBranches} from '@components/CreateUsers/Redux/Actions/TreeGroupsAndBranches';

export const TreeBranches = (props: ITreeBranch) => {
    const {search, Group, branchesStep, showTree, setShowTree} = props;
    const dispatch: AppDispatch = useDispatch();

    const tree = useSelector((state: RootState) => state.TreeGroupBranches);
    const {editUsers} = useSelector((state: RootState) => state.editUsers);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;

    const handleShowGroups = (id: number, type: string, branch: string) => {
        if (type === 'All')
            setShowTree({
                ...showTree,
                branches:
                    showTree.copyBranches.length === showTree.branches.length
                        ? showTree.currentBranches
                        : branchesStep,
            });

        if (type === 'Group') {
            if (!showTree.group.includes(id))
                setShowTree({...showTree, group: [...showTree.group, id]});
            if (showTree.group.includes(id))
                setShowTree({
                    ...showTree,
                    group: showTree.group.filter((item: number) => item !== id),
                });
        }

        if (type === 'Branch') {
            if (!showTree.branches.some((branch) => branch.branchId === id))
                setShowTree({
                    ...showTree,
                    branches: [...showTree.branches, {branchId: id, nameBranch: branch}],
                    currentBranches: [...showTree.branches, {branchId: id, nameBranch: branch}],
                });
            if (showTree.branches.some((branch) => branch.branchId === id))
                setShowTree({
                    ...showTree,
                    branches: showTree.branches.filter((item) => item.branchId !== id),
                    currentBranches: showTree.branches.filter((item) => item.branchId !== id),
                });
        }
    };

    useEffect(() => {
        const treeBranch = tree.showTreeBranches.branches.filter((i) => i.nameBranch !== '');
        if (tree.treeBranch.length > 0)
            dispatch(
                saveTree({
                    group: tree.treeBranch.map((item) => item.groupId),
                    branches: treeBranch.length > 0 ? treeBranch : branchesStep,
                    copyBranches: branchesStep,
                    currentBranches: [],
                    treeGroup: Group,
                })
            );
    }, [tree.treeBranch.length, editUsers.name]);

    useEffect(() => {
        if (companyId)
            dispatch(treeBranches(token, {companyId: companyId, branch: search, groupsId: Group}));
    }, [Group, search, editUsers.name]);

    return (
        <ContainerFlex {...style.contentTree}>
            {tree.treeBranch.length > 0 ? (
                <>
                    <ContainerFlex
                        {...style.allOptions}
                        onClick={() => handleShowGroups(0, 'All', '')}
                    >
                        <Checkbox checked={showTree.branches.length === branchesStep.length} />
                        <Text {...style.allText}>{BRANCHLOCATION.ALLOPTIONS}</Text>
                    </ContainerFlex>
                    {tree.treeBranch.map((item) => (
                        <ContainerFlex
                            key={item.groupId}
                            FlexDir="column"
                            Align="start"
                            Height="auto"
                        >
                            <ContainerFlex
                                {...style.contentLabelGroup}
                                onClick={() => handleShowGroups(item.groupId, 'Group', '')}
                            >
                                <TextEllipsis {...style.GroupText}>{item.group}</TextEllipsis>
                                <Icons {...style.icons}>{BRANCHLOCATION.ICONDROPDOWN}</Icons>
                            </ContainerFlex>

                            {showTree.group.includes(item.groupId) &&
                                item.branches.map((element) => (
                                    <ContainerFlex
                                        key={element.branchId}
                                        {...style.contentBranches}
                                        onClick={() =>
                                            handleShowGroups(
                                                element.branchId,
                                                'Branch',
                                                element.branchName
                                            )
                                        }
                                    >
                                        <Checkbox
                                            checked={showTree.branches.some(
                                                (branch) => branch.branchId === element.branchId
                                            )}
                                        />
                                        <TextEllipsis {...style.labelGroup}>
                                            {element.branchName}
                                        </TextEllipsis>
                                    </ContainerFlex>
                                ))}
                        </ContainerFlex>
                    ))}
                </>
            ) : (
                <ContainerFlex
                    Height="auto"
                    FontSize="0.875rem"
                    FontWeight="400"
                    Color="#54575C"
                    Padding="1rem 0"
                >
                    {tree.treeBranch.length === 0 ? BRANCHLOCATION.NORESULT : <LoadingAtaskate />}
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
