export const data = [
    {
        id: 12345,
        category: 'Electrónica',
        item: 'Teléfono móvil',
        quantity: 10,
        price: 5499.99,
        tax: 825.0,
    },
    {
        id: 23456,
        category: 'Hogar',
        item: 'Aspiradora',
        quantity: 5,
        price: 1699.99,
        tax: 204.0,
    },
    {
        id: 34567,
        category: 'Alimentos',
        item: 'Café',
        quantity: 30,
        price: 149.99,
        tax: 10.5,
    },
    {
        id: 45678,
        category: 'Ropa',
        item: 'Camiseta',
        quantity: 20,
        price: 299.99,
        tax: 24.0,
    },
    {
        id: 56789,
        category: 'Deportes',
        item: 'Balón de fútbol',
        quantity: 15,
        price: 379.99,
        tax: 38.0,
    },
    {
        id: 67890,
        category: 'Libros',
        item: 'Novela de ciencia ficción',
        quantity: 25,
        price: 239.99,
        tax: 12.0,
    },
    {
        id: 78901,
        category: 'Electrónica',
        item: 'Auriculares',
        quantity: 8,
        price: 949.99,
        tax: 142.5,
    },
    {
        id: 89012,
        category: 'Juguetes',
        item: 'Rompecabezas',
        quantity: 12,
        price: 199.99,
        tax: 20.0,
    },
    {
        id: 90123,
        category: 'Muebles',
        item: 'Silla de oficina',
        quantity: 10,
        price: 1499.99,
        tax: 180.0,
    },
    {
        id: 10234,
        category: 'Accesorios',
        item: 'Reloj de pulsera',
        quantity: 15,
        price: 1299.99,
        tax: 104.0,
    },
];
