import React from 'react';
import {Control, Controller} from 'react-hook-form';

import SelectGeneral from '@General/Atoms/Select/Select';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {APP_SETTINGS} from '@components/AppSettings/constants';
import * as styles from '@components/AppSettings/stylesAppSetting';
import {IFormAppSetting} from '@components/AppSettings/interfaces';

export const CoinTypeSettings = ({control}: {control: Control<IFormAppSetting>}) => {
    return (
        <ContainerFlex {...styles.contentSetting}>
            <Text {...styles.titleSetting}>{APP_SETTINGS.COIN_TYPE}</Text>
            <ContainerFlex Align="start" Gap="0.25rem" FlexDir="Column" Width="15.625rem">
                <Controller
                    name="coinType"
                    control={control}
                    render={({field}) => (
                        <SelectGeneral
                            {...field}
                            isSearchable={false}
                            options={[{value: 1, label: 'MXN'}]}
                        />
                    )}
                />
                <Text Color="#71767D" FontSize="0.75rem">
                    {APP_SETTINGS.WITHOUT_INFO}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
