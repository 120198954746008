import {AppDispatch} from '@/config/store';
import {NavigateFunction} from 'react-router-dom';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {IGroup} from '@components/LocationsBranch/interfaces';
import {NEW_GROUP} from '@components/LocationsBranch/Redux/types';
import {resetFormLocation} from '@/components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const newGroupStart = () => ({type: NEW_GROUP.START});
export const newGroupSuccess = (result: {data: AxiosResponse}) => ({
    type: NEW_GROUP.SUCCESS,
    newGroup: result.data,
});
export const newGroupError = (error: AxiosError) => ({
    type: NEW_GROUP.ERROR,
    error: error,
});
export const newGroup = (token: string, navigate: NavigateFunction, group: IGroup) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(newGroupStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateLevel}`,
                group,
                {headers}
            );
            dispatch(newGroupSuccess(response.data));
            resetFormLocation(dispatch);
            navigate('/LocationBranch');
        } catch (error) {
            dispatch(newGroupError(error as AxiosError));
        }
    };
};
