import {Controller} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import debitCardIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/debitCardIcon.svg';
import {ICardNumberFieldProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import errorIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/errorIcon.svg';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';

export const CardNumberField: React.FC<ICardNumberFieldProps> = ({control, errors}) => {
    return (
        <>
            <ContainerFlex FlexDir="column" Gap="0.5rem" Align="start" Justify="start">
                <Text Color="#1D1E20" FontWeight="700" Width="max-content">
                    {PAYMENT_METHOD.FIELDS.CARD_NUMBER}
                </Text>
                <Controller
                    name="cardNumber"
                    control={control}
                    render={({field}) => (
                        <SearchInput
                            GridColumn="48px 1fr"
                            Height="40px"
                            Padding="0px"
                            Width="100%"
                            Position="static"
                            OverFlow="hidden"
                            InputPadding="0px 1rem"
                        >
                            <ContainerFlex backG="#F4F5F5" Height="40px" Width="48px">
                                <ContainerFlex
                                    backG="#FFF"
                                    Radius="2.375rem"
                                    Width="32px"
                                    Height="32px"
                                >
                                    <Image src={debitCardIcon} Width="24px" Height="24px" />
                                </ContainerFlex>
                            </ContainerFlex>
                            <Input {...field} maxLength={16} />
                        </SearchInput>
                    )}
                />
                {errors.cardNumber && (
                    <Text FontSize="0.875rem" Color="#FF6357" Gap="0.25rem">
                        <Image src={errorIcon} Width="16px" Height="16px" />
                        {errors.cardNumber?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
