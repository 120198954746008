import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {DeadlineSearch} from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/RouteDeadline';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {deadlinesID} from '@CreateDeadline/interfaces';

const RouteDeadlines = () => {
    const deadlineById: deadlinesID = useSelector((state: RootState) => {
        return state.getDeadlinedAll;
    });
    const title = () => {
        if (deadlineById.edit) return 'Visualización de plazo';
        if (deadlineById.copy) return 'Duplicación de plazo';
        return 'Creación de plazo';
    };

    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/', label: 'Aplicaciones'},
        {to: '#/', label: 'Ciclo prendario'},
        {to: '#/NewDeadline', label: 'Plazos'},
        {
            to: '#/NewDeadline/RouteDeadlines',
            label: title(),
        },
    ];

    return (
        <ContainerFlex FlexDir="column" Padding="0 1.875rem 0 1.5rem">
            <ContainerFlex Justify="flex-start" Padding="30px 0 0 0">
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>
            <DeadlineSearch />
        </ContainerFlex>
    );
};

export default RouteDeadlines;
