import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {
    LOCATIONS_SELECT,
    locationTypeFilter,
    ALLTYPEFILTER,
} from '@components/LocationsBranch/constants';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {
    SearchLevelsAndBranch,
    ClearLevelsSearch,
} from '@/components/LocationsBranch/Redux/Action/SearchLevelsAndBranch';
import {SearchLocationsProps} from '@components/LocationsBranch/SerachGroups/interfaces';
import {SearchTextbox} from '@General/Atoms/SearchBoxResultList/SearchTextbox';
import arrow from '@images/arrow_small.svg';

export const SearchLocations: React.FC<SearchLocationsProps> = ({
    setSelectedGroup,
    setTypeFilter,
}) => {
    const dispatch = useDispatch();
    const [selection, setSelection] = useState<LevelSelection>({title: '', ids: []});
    const [selectedType, setSelectedType] = useState<{value: number; label: string} | null>({
        value: 1,
        label: ALLTYPEFILTER,
    });
    const searchResults = useSelector((state: RootState) => state.getSearchLevelsAndBranch.data);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const handleSearchSelect = (item: LevelSelection) => {
        const lastId = item.ids[item.ids.length - 1];
        const secondLastId = item.ids[item.ids.length - 2];
        setSelection(item);
        setSelectedGroup([lastId, secondLastId]);
    };

    const handleSearch = (searchText: string) => {
        dispatch(SearchLevelsAndBranch(companyId, searchText, token));
    };
    const handleReset = () => {
        setSelection({title: '', ids: []});
        dispatch(ClearLevelsSearch());
        setSelectedGroup([0, 0]);
    };
    const handleTypeChange = (selectedOption: {value: number; label: string} | null) => {
        setSelectedType(selectedOption);
        setTypeFilter(selectedOption?.value ?? 1);
    };
    const renderItem = (item: LevelSelection): JSX.Element => {
        const parts = item.title.split('>');
        return (
            <ContainerFlex PaddingR="4px" Align="center" Justify="flex-start" Gap="4px">
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        <Text>{part.trim()}</Text>
                        {index < parts.length - 1 && (
                            <Image
                                src={arrow}
                                Width="1.125rem"
                                Height="1.125rem"
                                Transform="rotate(180deg)"
                            />
                        )}
                    </React.Fragment>
                ))}
            </ContainerFlex>
        );
    };

    return (
        <ContainerFlex Align="center" Justify="start-flex" Height="40px" Gap="1rem">
            <ContainerFlex Width="300px" Align="baseline" Justify="start" FlexDir="column">
                <SearchTextbox
                    placeholder={LOCATIONS_SELECT.PLACEHOLDER_SEARCH}
                    searchAction={handleSearch}
                    resetAction={handleReset}
                    results={searchResults}
                    renderItem={renderItem}
                    onSelect={handleSearchSelect}
                    defaultValue={selection.title}
                    showSelection={true}
                    noDataContent={
                        <ContainerFlex Padding="8px" Align="center" Justify="start" Self="stretch">
                            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                                {LOCATIONS_SELECT.NO_RECORDS_FOUND}
                            </Text>
                        </ContainerFlex>
                    }
                />
            </ContainerFlex>
            <ContainerFlex Width="140px" Align="center">
                <SelectGeneral
                    value={selectedType}
                    isSearchable={false}
                    options={locationTypeFilter}
                    noOptionsMessage={() => 'Sin opciones'}
                    onChange={handleTypeChange}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
