import {Controller} from 'react-hook-form';
import {components, OptionProps} from 'react-select';
import React from 'react';
import {Select} from '@Quoter/Steps/styles';
import {Image} from '@Shopify/Ecommerce/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {NEWFEATURE} from '@components/Catalogue/constants';
import {NewFeatureSelectProps} from '@components/Catalogue/interfaces';

const Option = (props: OptionProps) => {
    const {Option} = components;
    return (
        <Option {...props}>
            <Text Color="#2A2C2F">
                <Image
                    src={(props.data as {icon: string}).icon}
                    Width="20px"
                    Height="20px"
                    Margin="0px 8px 0px 0px"
                />
                {(props.data as {label: string}).label}
            </Text>
        </Option>
    );
};

export const NewFeatureSelect = ({control, articleTypes, errors}: NewFeatureSelectProps) => {
    return (
        <>
            <ContainerFlex Align="start" FlexDir="column">
                <Text Color="1D1E20" FontWeight="700" FontFamily="Nunito" MarginB="0.25rem">
                    {NEWFEATURE.TYPE}
                </Text>

                <Controller
                    control={control}
                    name="catArticleType"
                    render={({field: {...field}}) => (
                        <Select
                            {...field}
                            placeholder="Selecciona"
                            options={articleTypes}
                            noOptionsMessage={() => 'Sin opciones'}
                            hasError={errors?.catArticleType?.value?.message ? true : false}
                            components={{Option}}
                            isSearchable={false}
                        />
                    )}
                />
                {errors.catArticleType && (
                    <Text Color="red" Margin="4px 0px 0px 0px">
                        {errors.catArticleType?.value?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
