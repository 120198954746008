import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import {incomeData} from '@/components/AdminGeneral/data';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/LoginClient/styles';
import moreVert from '@/images/more_vert.svg';
import {LINE_CHART_INCOME} from '@/components/AdminGeneral/constants';

const LineChartIncome = () => {
    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex JustifyItems="flex-start" Height="6rem" MarginBt="15px">
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontSize="1.5rem" FontWeight="700">
                        {LINE_CHART_INCOME.income}
                    </Text>
                    <Text Color="#71767D">{LINE_CHART_INCOME.month}</Text>
                </ContainerFlex>
                <ImageLogo src={moreVert} Height="30px" Width="30px" />
            </ContainerFlex>
            <LineChart
                width={650}
                height={280}
                data={incomeData}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
            >
                <CartesianGrid strokeDasharray="1" vertical={false} />
                <XAxis dataKey="month" axisLine={false} />
                <YAxis dataKey="income" axisLine={false} tickLine={false} orientation="right" />
                <Tooltip />
                <Legend
                    align="left"
                    iconType="circle"
                    payload={[
                        {value: 'Real', type: 'line', color: '#5A5AFF'},
                        {value: 'Meta', type: 'line', color: '#FF6AE8'},
                        {value: 'Proyección', type: 'line', color: 'black'},
                    ]}
                />
                <Line type="monotone" dataKey="income" stroke="#5A5AFF" />
                <Line type="monotone" dataKey="income" stroke="#5A5AFF" />
            </LineChart>
        </ContainerFlex>
    );
};

export default LineChartIncome;
