import {
    FORM_ADD_COOWNER,
    FORM_ADD_COOWNER_ERROR,
    FORM_ADD_COOWNER_SUCCESS,
    ADD_COOWNER_FORM,
    CANCEL_ADD_COOWNER_FORM,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    addCoOwner: [],
    formAddCoOwnerStatus: false,
};

const formAddCoOwner = (
    state = initialState,
    actions: {block: boolean; type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case FORM_ADD_COOWNER:
            return {
                ...state,
                loading: true,
            };
        case FORM_ADD_COOWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                temporalPawn: actions.payload.data.data,
            };
        case FORM_ADD_COOWNER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case ADD_COOWNER_FORM:
            return {...state, formAddCoOwnerStatus: true};
        case CANCEL_ADD_COOWNER_FORM:
            return {...state, formAddCoOwnerStatus: false};
        default:
            return state;
    }
};

export default formAddCoOwner;
