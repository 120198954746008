import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {MY_ACCOUNT} from '@components/AdminNavbar/constants';
import User from '@images/Users.svg';
import UserOther from '@images/UserOther.svg';
import {IconContainer} from '@Quoter/Steps/styles';
import {useNavigate} from 'react-router-dom';

export const AdminMenu = () => {
    const navigate = useNavigate();
    const [showDetail, setShowDetail] = useState(false);
    return (
        <ContainerFlex Height="auto" Width="100%" FlexDir="column" Padding="8px">
            <ContainerFlex
                Justify="space-between"
                ColumnGap="8px"
                Cursor="pointer"
                onClick={() => setShowDetail(true)}
            >
                {showDetail ? (
                    <Image Width="20px" Height="20px" src={UserOther} />
                ) : (
                    <Image Width="20px" Height="20px" src={User} />
                )}
                <Text
                    FontSize="1rem"
                    Color={showDetail ? '#5A5AFF' : '#2A2C2F'}
                    Width="100%"
                    Cursor="pointer"
                    onClick={() => navigate('/MyAccount')}
                >
                    {MY_ACCOUNT.MY_ACCOUNT}
                </Text>
                <IconContainer
                    className="material-icons"
                    FontSize="1.125rem"
                    Width="1.125rem"
                    Color={showDetail ? '#5A5AFF' : '#2A2C2F'}
                    Cursor="pointer"
                >
                    {showDetail ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </IconContainer>
            </ContainerFlex>
        </ContainerFlex>
    );
};
