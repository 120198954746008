import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {ERROR_MESSAGES} from '@components/Quoter/CoownerBeneficiary/constants';
import {IconSvg} from '@Quoter/Icons/styles';

export const ErrorCoOwnerBeneficiary = () => {
    return (
        <ContainerFlex
            Height="80px"
            Padding="0.5rem 2rem"
            ColumnGap="0.5rem"
            backG="#DBF4FF"
            Align="start"
            Justify="space-between"
            Radius="0.5rem"
            MarginBt="1rem"
        >
            <IconSvg Width="20px" height="20px">
                <path
                    id="info_2"
                    d="M8.97882 14.0316H10.6455V9.03158H8.97882V14.0316ZM9.81215 7.36491C10.0483 7.36491 10.2462 7.28505 10.4059 7.12533C10.5656 6.9656 10.6455 6.76769 10.6455 6.53158C10.6455 6.29546 10.5656 6.09755 10.4059 5.93783C10.2462 5.7781 10.0483 5.69824 9.81215 5.69824C9.57604 5.69824 9.37813 5.7781 9.2184 5.93783C9.05868 6.09755 8.97882 6.29546 8.97882 6.53158C8.97882 6.76769 9.05868 6.9656 9.2184 7.12533C9.37813 7.28505 9.57604 7.36491 9.81215 7.36491ZM9.81215 18.1982C8.65938 18.1982 7.57604 17.9795 6.56215 17.542C5.54826 17.1045 4.66632 16.5107 3.91632 15.7607C3.16632 15.0107 2.57257 14.1288 2.13507 13.1149C1.69757 12.101 1.47882 11.0177 1.47882 9.86491C1.47882 8.71213 1.69757 7.6288 2.13507 6.61491C2.57257 5.60102 3.16632 4.71908 3.91632 3.96908C4.66632 3.21908 5.54826 2.62533 6.56215 2.18783C7.57604 1.75033 8.65938 1.53158 9.81215 1.53158C10.9649 1.53158 12.0483 1.75033 13.0622 2.18783C14.076 2.62533 14.958 3.21908 15.708 3.96908C16.458 4.71908 17.0517 5.60102 17.4892 6.61491C17.9267 7.6288 18.1455 8.71213 18.1455 9.86491C18.1455 11.0177 17.9267 12.101 17.4892 13.1149C17.0517 14.1288 16.458 15.0107 15.708 15.7607C14.958 16.5107 14.076 17.1045 13.0622 17.542C12.0483 17.9795 10.9649 18.1982 9.81215 18.1982Z"
                    fill="#008FCC"
                />
            </IconSvg>
            <ContainerFlex Gap="10px" Align="start" FlexDir="column">
                <Text FontWeight="500" Color="#004461" FontSize="1rem">
                    {ERROR_MESSAGES.TITLE_PERSON}
                </Text>

                <Text FontWeight="400" Color="#2A2C2F" FontSize="1rem" wSpace="pre-wrap">
                    {ERROR_MESSAGES.SUB_TITLE_PERSON}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
