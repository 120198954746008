import styled from '@emotion/styled';
import {NumericFormat} from 'react-number-format';
import {
    IPropsForExcedent,
    IPropsGrid,
    IPropsGridColum,
    IPropsRequestDetail,
    IPropsStatusChecking,
    IPropsStatusColor,
    IPropsStatusRequest,
    IPropsStatusRequest1,
    IPropsStoreDetail,
    IPropsStyledInput,
    IPropsTable,
    IPropsTableItems,
    IPropsTableItemsContainer,
    IPropsTableRow,
    IPropsTableRowHead,
    IPropsTableRowItems,
    IpropsGridSection,
} from '@CashFlow/FundingInquiry/interfacesStyles';

export const FilterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 0px 14px 0;

    .icon-green {
        filter: invert(66%) sepia(39%) saturate(630%) hue-rotate(51deg) brightness(94%)
            contrast(92%);
    }

    .css-g1d714-ValueContainer {
        /* height: 42px; */
        /* align-content: center; */
        justify-content: flex-start;
        margin-bottom: 5px;
    }

    /* .css-yk16xz-control {
    height: 42px;
  } */

    .css-1wa3eu0-placeholder {
        /* height: 25px; */
        top: 59%;
    }

    .css-b8ldur-Input {
        height: 25px;
    }

    .css-1uccc91-singleValue {
        top: 70%;
    }

    .css-1wa3eu0-placeholder {
    }

    .restart {
        &:hover {
            color: #7ac143;
            cursor: pointer;
        }
    }

    form {
        display: flex;
        padding: 0px 0px 5px 5px;
        width: 100%;
        justify-content: space-between;
        align-content: center;
    }

    .input-form {
        padding: 10px;
        width: 262px;
        &:hover {
            cursor: pointer;
        }
    }

    div {
        > select {
            width: 131px;
            height: 40px;
            margin-right: 38px;
            border: 1px solid #e4e7e9;
            border-radius: 5px;
            color: #4a4a4a;
            padding-left: 10px;
            font:
                Work Sans,
                Regular;

            > option {
                border: 1px solid #414042;
                justify-content: center;
                &:first-child {
                    color: gray;
                }
            }

            &:first-child {
                width: 270px;
                height: 40px;
            }
        }
    }

    .filterStyle {
        text-transform: uppercase;
    }

    .restart {
        display: flex;
        align-self: center;
    }
`;

export const StatusColor = styled.div<IPropsStatusColor>`
    width: 7px;
    height: 100%;
    background-color: ${({bgColor}) => bgColor};
    border-radius: 5px 0 0 5px;
`;

export const Grid = styled.div<IPropsGrid>`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-columns: ${({GridCol}) => GridCol};
    justify-content: ${({JustCont}) => JustCont};
    border-top: ${({border}) => border};

    .table-header {
        background-color: var(--gray-5);
        height: 52px;
        display: flex;
        align-items: center;
        > p {
            width: 100%;
            margin: 0 4px;
            text-align: center;
            font-weight: 600;
            font-size: 0.875rem;
        }
    }
    .grid7-9 {
        font-size: 0.857rem;
        grid-column: 7 / 9;
    }

    .first-column {
        border-radius: 5px 0 0 5px;
    }

    .last-column {
        border-radius: 0 5px 5px 0;
    }

    .table-row {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        > p {
            width: 100%;
            margin: 0 2px;
        }
    }

    .first-cell {
        border: 1px solid var(--gray-4);
        border-radius: 5px 0 0 5px;
        border-right: none;
    }

    .middle-cell {
        border-top: 1px solid var(--gray-4);
        border-bottom: 1px solid var(--gray-4);
    }

    .last-cell {
        border: 1px solid var(--gray-4);
        border-left: none;
        border-radius: 0 5px 5px 0;
        display: flex;
        padding-right: 8px;
        justify-content: flex-end;
        &:hover {
            cursor: pointer;
        }
    }

    .inquiry-row {
        &:hover {
            cursor: auto;
        }
    }
`;

export const RequestDetail = styled.div<IPropsRequestDetail>`
    grid-column: 1 / 10;
    border: 1px solid var(--gray-4);
    border-top: ${(props) => props.BorderTop};
    border-radius: ${(props) => props.Radius};
    margin-bottom: 10px;
    margin-left: ${(props) => props.MarginLf};
    margin: ${(props) => props.Margin};
    padding: ${(props) => props.Padding};
    width: ${(props) => props.Width};
    .pl4 {
        padding-left: 4px;
    }
`;

RequestDetail.defaultProps = {
    BorderTop: '0px',
    Radius: '0 0 5px 5px',
};

export const ExcedentDetail = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: 28px 12px;
    border-bottom: 1px solid var(--gray-4);
    span {
        font-weight: 300;
    }
`;

export const StoreDetail = styled.div<IPropsStoreDetail>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: 22px 44px 19px 44px;
    border-bottom: ${(props) => props.BorderBottom};
`;
StoreDetail.defaultProps = {
    BorderBottom: '1px solid var(--gray-4)',
};

export const Div1 = styled.div`
    margin-top: 16px;
    margin-bottom: 10px;
`;

export const Div2 = styled.div`
    padding-bottom: 15px;
`;

export const ApproveRequest = styled.div`
    display: flex;
    padding: 12px;
    input,
    select {
        height: 40px;
        border-radius: 5px;
        border: 1px solid var(--gray-4);
        font-size: 0.875rem;
        padding: 0 8px;
        margin: 0 8px;
        &:disabled {
            background-color: #fafafa;
            color: var(--gray-2);
        }
        &::placeholder {
            color: var(--gray-2);
        }
    }

    > button {
        display: block;
        margin: 20px auto;
    }

    > div {
        > p {
            margin: 0;
            padding-top: 16px;
        }
        > span {
            margin: 0;
            padding-top: 16px;
            padding-bottom: 26px;
        }
    }

    .border-row {
        border: 1px solid var(--gray-4);
        margin: 12px 0;
    }

    > div:first-child {
        width: 30%;
        > div {
            border: 1px solid var(--gray-4);
            border-radius: 5px;
            margin: 27px 12px 12px 0;
            padding: 12px;
            height: 300px;
            overflow: auto;
        }
    }

    > div:last-child {
        width: 70%;
        > div > p {
            display: inline;
            margin-left: 12px;
            font-weight: 600;
        }
    }

    .add-icon {
        color: #7ac143;
        &:hover {
            cursor: pointer;
        }
    }
`;

export const AccountContainer = styled.div`
    border: 1px solid var(--gray-4);
    border-radius: 5px;

    .accountSection {
        padding: 16px 0;
        display: flex;
        align-items: center;
        > div {
            padding: 0 16px;
        }
    }

    .accountSection:first-child {
        border-bottom: 1px solid var(--gray-4);
    }

    p {
        margin: 0;
    }

    .p-bold {
        font-weight: 500;
    }
`;

export const ApproveContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 20px auto;
    .table-container {
        width: 45%;
    }

    .organization-container {
        width: 45%;
    }
`;

export const TableHeader = styled.div`
    width: 100%;
    height: 52px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    background-color: #f3f3f3;
    margin-top: 1px;
`;

export const TableRow = styled.div<IPropsTableRow>`
    width: 100%;
    height: 52px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    background-color: white;
    gap: ${(props) => props.gap};

    .edit {
        &:hover {
            cursor: pointer;
        }
    }

    .positionStyle {
        font-size: 0.714rem;
    }

    input {
        width: 100%;
        margin: 0;
        padding: 0 8px;
        text-align: center;
        &:disabled {
            border: none;
        }
    }

    select {
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
    }

    p {
        margin: 0;
        text-align: center;
        font-weight: 500;
    }

    span {
        display: block;
        text-align: center;
    }

    .p-total {
        font-size: 1.285rem;
        color: #7ac143;
    }

    .remove-item:hover {
        cursor: pointer;
    }

    > div:last-child {
        display: flex;
        > span {
            font-size: 1.142rem;
        }
    }

    .tick {
        color: #7ac143;
        margin-left: 20px;
        &:hover {
            cursor: pointer;
        }
    }

    .close {
        width: 100%;
        &:hover {
            cursor: pointer;
        }
    }

    .workPosition {
        border: none;
        color: var(--gray-4);
    }
`;

export const GridColum = styled.div<IPropsGridColum>`
    font-size: 0.857rem;
    grid-column: ${(props) => props.gridCol};
    font-size: ${(props) => props.gridColFont};
    justify-content: ${(props) => props.gridJustCont};
    margin-left: ${(props) => props.MarginLf};
    padding: ${(props) => props.Padding};
    border-left: ${(props) => props.BorderLf};
    select {
        height: 40px;
    }
    input {
        height: ${(props) => props.Height};
    }
    div:last-child > span {
        height: ${(props) => props.HeightSpan};
        width: ${(props) => props.Width};
    }
    textarea {
        height: 64px;
        width: 100%;
    }
    .upVoucher {
        display: flex;
        color: #7ac143;
        flex-direction: row;

        &:hover {
            cursor: pointer;
        }
    }
`;
GridColum.defaultProps = {
    HeightSpan: '16px',
};
export const InputConfirm = styled.input`
    width: 240px;
    height: 30px;
`;

export const Observations = styled.input`
    width: 816px;
    height: 64px;
`;

export const NewState = styled.select`
    width: 240px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e4e7e9;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    justify-content: center;
`;

export const ButtonContainerLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        margin: 0 20px;
        border: none;
        box-shadow: 0px 1px 3px #00000033;
    }
`;

export const ButtonContainerRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        margin: 0 20px;
        border: 1.5px solid #000;
    }
`;

export const ObservationsContainer = styled.div`
    width: 100%;
    margin: 8px 0 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    span {
        display: block;
        width: 100%;
    }

    textarea {
        resize: none;
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--gray-4);
        font-size: 0.875rem;
        padding: 5px;
        height: 87px;
    }
`;

export const StatusRequest = styled.div<IPropsStatusRequest>`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: 40px 26px;
    margin-top: 15px;
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
    margin: ${(props) => props.Margin};

    /* span {
    font-weight: 300;
  } */

    .approvedStyle {
        font-weight: 400;
        font-size: 0.875rem;
    }
`;

StatusRequest.defaultProps = {
    Bt: '1px solid var(--gray-4)',
    Bb: '1px solid var(--gray-4)',
};
export const ForExcedent = styled.div<IPropsForExcedent>`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: 16px;
    /* margin-top: 15px; */
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};

    .approvedStyle {
        font-weight: 400;
        font-size: 0.875rem;
    }
`;

ForExcedent.defaultProps = {
    Bt: '1px solid var(--gray-4)',
    Bb: '1px solid var(--gray-4)',
};

export const StatusRequest1 = styled.div<IPropsStatusRequest1>`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: ${(props) => props.Padding};
    margin-top: 15px;
    margin: ${(props) => props.Margin};
    /* span {
    font-weight: 300;
  } */

    .approvedStyle {
        font-weight: 400;
        font-size: 0.875rem;
    }
`;

StatusRequest1.defaultProps = {
    Padding: '0 28px',
};

export const StatusChecking = styled.div<IPropsStatusChecking>`
    padding: ${(props) => props.Padding};
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .cancelButton {
        border: none;
        background-color: white;
        span {
            padding: 5px 0px 0px 5px;
        }
    }

    label select {
        height: 40px;
        width: 210px;
        border-radius: 5px;
        border: 1px solid var(--gray-4);
        font-size: 0.875rem;
        padding: 0 8px;
    }

    .upVoucherOption {
        display: flex;
        flex-direction: column;
        padding: none;
    }

    div.voucherEdit {
        color: #7ac143;
        display: flex;

        span.unarchive {
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }
    }

    div.upVoucher {
        display: flex;
        flex-direction: row;

        &:hover {
            color: #7ac143;
            cursor: pointer;
        }
    }

    .upVaucherEdit {
        display: flex;
        color: #7ac143;
    }

    span.cancelVoucher {
        color: var(--gray-4);
        align-self: center;
        font-size: 1.071rem;
        background-color: none;
        padding: none;
        &:hover {
            cursor: pointer;
        }
    }

    .voucherPDF {
        display: flex;
        color: #7ac143;
        cursor: pointer;
        padding-right: 5px;
    }

    .voucherPDFup {
        color: #7ac143;
        text-decoration: underline;
    }

    .material-icons {
        color: #7ac143;
    }

    .voucherReceived {
        &:hover {
            color: #7ac143;
            cursor: pointer;
        }
    }

    .calendar {
        height: 40px;
        width: 210px;
        border-radius: 5px;
        border: 1px solid var(--gray-4);
        font-size: 0.875rem;
        padding-left: 10px;
    }
`;

StatusChecking.defaultProps = {
    Padding: '32px',
};

export const InputChange = styled.div`
    justify-content: center;
`;
export const MenuFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    padding-bottom: 1.5rem;
    width: 40px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const GridSection = styled.div<IpropsGridSection>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({gridCreateCollumn}) => gridCreateCollumn};
    justify-items: ${({gridJustify}) => gridJustify};
    align-items: ${({gridAlign}) => gridAlign};
    grid-column: ${(props) => props.gridCol};
    justify-content: center;
`;

export const TableRowHead = styled.div<IPropsTableRowHead>`
    display: grid;
    grid-template-columns: ${(props) => props.GridColumn};
    height: ${(props) => props.Height};
    background: ${(props) => props.BackG};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    border-top: ${(props) => props.BorderT};
    border-radius: ${(props) => props.Radius};
    gap: ${(props) => props.Gap};
    border-bottom: ${(props) => props.BorderB};
`;
TableRowHead.defaultProps = {
    GridColumn: '1fr 2fr 1fr 1fr 1fr 1fr 1fr',
    Height: '52px',
    Width: 'none',
    Padding: 'none',
    Margin: 'none',
    Border: 'none',
    BackG: '#f3f3f3',
    Radius: '5px',
};

export const TableItemsContainer = styled.div<IPropsTableItemsContainer>`
    background: ${(props) => props.Background};
    width: ${(props) => props.Width};
    display: grid;
    z-index: 0;
    box-shadow: ${(props) => props.Bs};
    grid-template-rows: repeat(auto, 4.313rem);
    height: ${(props) => props.Height};
    max-height: ${(props) => props.MaxHeight};
    min-height: ${(props) => props.MinHeight};
    overflow: ${(props) => props.Overflow};
    &::-webkit-scrollbar {
        width: 0rem;
    }
`;

TableItemsContainer.defaultProps = {
    Overflow: 'auto,',
};
export const TableRowItems = styled.div<IPropsTableRowItems>`
    display: grid;
    margin-top: 0;
    border: ${(props) => props.Border};
    border-bottom: ${(props) => props.BorderBottom};
    border-bottom: ${(props) => props.BorderB};
    border-radius: ${(props) => props.BorderRadius};
    grid-template-columns: ${(props) => props.GridColumn};
    width: ${(props) => props.Width};
    background: ${(props) => props.BackGround};
    position: ${(props) => props.Position};
    box-shadow: ${(props) => props.Bs};
    min-height: ${(props) => props.MinH};
    max-height: ${(props) => props.MaxH};
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    padding: ${(props) => props.Padding};
    gap: ${(props) => props.Gap};
    column-gap: ${(props) => props.ColumnGap};

    &:hover {
        cursor: ${(props) => props.Cursor};
        background-color: ${(props) => props.BackGH};
    }
`;

TableRowItems.defaultProps = {
    Border: '0.5px solid #e4e7e9',
    MinH: '43px',
    MaxH: '8rem',
    Height: '80px',
    BorderRadius: '5px',
    BackGround: '#fff',
};

export const TableItems = styled.div<IPropsTableItems>`
    display: flex;
    opacity: 100%;
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    align-items: ${(props) => props.Align};
    font-family: Work Sans;
    font-weight: ${(props) => props.Weight};
    font-size: ${(props) => props.FontSize};
    text-decoration: ${(props) => props.TextDecoration};
    line-height: 20px;
    color: ${(props) => props.Color};
    padding: ${(props) => props.Padding};
    border-bottom: ${(props) => props.Bb};
    border: ${(props) => props.Border};
    border-right: ${(props) => props.Br && props.Br};
    height: ${(props) => props.Height};
    gap: ${(props) => props.Gap};
    width: ${(props) => props.Width};
    box-sizing: border-box;

    img {
        border-radius: ${(props) => props.Radius};
    }
`;

TableItems.defaultProps = {
    Justify: 'center',
    Align: 'center',
    FlexDir: 'row',
    Weight: '500',
    Padding: '0',
    Bb: 'none',
    Border: 'none',
    Color: '#4a4a4a',
    TextDecoration: 'none',
    Height: 'auto',
};

export const Table = styled.div<IPropsTable>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${(props) => props.Height};
    max-height: ${(props) => props.MaxH};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    overflow: ${(props) => props.Overflow};
    &:last-child {
        border-bottom: ${(props) => props.LCBorderB};
    }
`;

Table.defaultProps = {
    Height: '100%',
};

export const StyledInput = styled(NumericFormat)<IPropsStyledInput>`
    color: black;
    padding: 0 0.5rem;
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};
    background: ${(props) => props.background};
    border: ${(props) => props.border};

    :disabled {
        background: ${(props) => props.dbackground};
    }
`;

StyledInput.defaultProps = {
    width: '9rem',
};

export const CircleButton = styled.button`
    border: none;
    background-color: white;
    span {
        padding: 4px 0px 0px 4px;
    }
`;
