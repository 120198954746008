import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {POPUPTEXTROLES} from '@Roles/constants';
import {ISearchList} from '@Roles/AddRoles/interfaces';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {GetRolesUser} from '@Roles/CreateRoles/Redux/Actions/UserRoles';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerFlex, Text, Image, TextEllipsis} from '@Shopify/Ecommerce/styles';

export const AssigmentSearchList = ({user, handleCheckboxChange}: ISearchList) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {userRoles, loading} = useSelector((state: RootState) => state.UserRoles);

    useEffect(() => {
        if (token && user.search.length === 0)
            dispatch(GetRolesUser({companyId: companyId, search: user.search}, token));
    }, [user.search.length]);

    return (
        <>
            <ContainerFlex
                Display="grid"
                GridColumns="0.1fr 0.2fr repeat(3, 1fr)"
                Justify="start"
                Height="2.5rem"
            >
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F" GridColumnsStart="3">
                    {POPUPTEXTROLES.USERNAME}
                </Text>
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F" GridColumnsStart="4">
                    {POPUPTEXTROLES.ROL}
                </Text>
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F" GridColumnsStart="5">
                    {POPUPTEXTROLES.ACCESS}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Gap="0.5rem"
                OverFlow="auto"
                Height="9rem"
            >
                {loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    userRoles &&
                    userRoles.map((item) => (
                        <ContainerFlex
                            key={item.userId}
                            Display="grid"
                            GridColumns="0.1fr 0.2fr repeat(3, 1fr)"
                            Justify="start"
                            Height="auto"
                        >
                            <Checkbox
                                checked={user.checked.includes(item.userId)}
                                onChange={() => handleCheckboxChange(item.userId)}
                            />
                            <ContainerFlex>
                                <Image
                                    src={item.image}
                                    Width="32px"
                                    Height="32px"
                                    Radius="50%"
                                    ObjectFit="cover"
                                />
                            </ContainerFlex>
                            <TextEllipsis
                                Color="#2A2C2F"
                                FontSize="0.875rem"
                                FontWeight="400"
                                Width="auto"
                            >
                                {item.userName}
                            </TextEllipsis>
                            <ContainerFlex
                                FlexDir="column"
                                Align="start"
                                Justify="start"
                                Width="auto"
                                Height="auto"
                            >
                                {item.rol.map((role, index: number) => (
                                    <TextEllipsis
                                        key={index}
                                        Color="#2A2C2F"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                        Width="auto"
                                    >
                                        {role}
                                    </TextEllipsis>
                                ))}
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start">
                                {item.access.map((access, index: number) => (
                                    <TextEllipsis
                                        Color="#2A2C2F"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                        Width="auto"
                                        key={index}
                                    >
                                        {access}
                                    </TextEllipsis>
                                ))}
                            </ContainerFlex>
                        </ContainerFlex>
                    ))
                )}
            </ContainerFlex>
        </>
    );
};
