import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import {RootState} from '@config/store';
import {ContainerFlex, Input, Text} from '@components/Shopify/Ecommerce/styles';
import ButtonGeneral from '@components/General/Atoms/Button';
import {
    IOtherForm,
    IOtherFormComponent,
    IUpdateOtherInfo,
    IUserBranch,
} from '@components/Branches/interfaces';
import {DatePickerContract} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/DatePickerContract';
import {SelectManager} from '@components/Branches/ManageBranch/BranchInfo/SelectComplementary/SelectManager';
import {PutOtherInfo} from '@components/Branches/ManageBranch/Redux/Actions/PutOtherInfo';
import {schemaOtherForm} from '@components/Branches/schemaValidations';
import {NO_EDITED_INFO, VALID_ROLE} from '@components/Branches/ManageBranch/BranchInfo/constants';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const OtherForm = ({
    showOtherForm,
    setShowOtherForm,
    setShowItems,
    showItems,
}: IOtherFormComponent) => {
    const dispatch = useDispatch();
    const {idBranch} = useParams();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const branchUsers = useSelector((state: RootState) => {
        return state.GetBranchUsers?.branchUsers?.data;
    });
    const otherInfo = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo.otherInformation;
    });
    const otherLoad = useSelector((state: RootState) => {
        return state.PutOtherInfo?.loading;
    });
    const dt = DateTime.now();
    const [date, setDate] = useState<DateTime | null>(dt);
    const {
        handleSubmit,
        register,
        watch,
        trigger,
        reset,
        setValue,
        formState: {errors},
    } = useForm<IOtherForm>({
        resolver: yupResolver(schemaOtherForm),
        defaultValues: {
            userId:
                otherInfo.manager === NO_EDITED_INFO.MANAGER
                    ? undefined
                    : branchUsers.find((user: IUserBranch) => user.userName === otherInfo.manager)
                          ?.userId,
            managerName: otherInfo.manager === NO_EDITED_INFO.MANAGER ? '' : otherInfo.manager,
            legalRepresentative:
                otherInfo.legalRepresentative === NO_EDITED_INFO.LEGAL_REPRESENTATIVE
                    ? ''
                    : otherInfo.legalRepresentative,
            branchEmail: otherInfo.email === NO_EDITED_INFO.EMAIL ? '' : otherInfo.email,
            contractRegistrationDate:
                otherInfo.contractRegistrationDate === NO_EDITED_INFO.CONTRACT_DATE
                    ? date
                    : DateTime.fromFormat(otherInfo.contractRegistrationDate, 'dd/MM/yy'),
            publicRegistrationAdhesionNumber:
                otherInfo.publicRegistrationAdhesionNumber === NO_EDITED_INFO.REGISTRATION_DATE
                    ? ''
                    : otherInfo.publicRegistrationAdhesionNumber,
        },
    });
    const watchManager = watch('managerName');
    const onSubmit = (data: IOtherForm) => {
        const postData: IUpdateOtherInfo = {
            branchId: Number(idBranch),
            userId: data.userId,
            legalRepresentative: data.legalRepresentative,
            branchEmail: data.branchEmail,
            contractRegistrationDate: data.contractRegistrationDate,
            publicRegistrationAdhesionNumber: data.publicRegistrationAdhesionNumber,
        };
        dispatch(
            PutOtherInfo(
                postData,
                token,
                setShowOtherForm,
                setShowItems,
                showItems,
                Number(idBranch)
            )
        );
    };
    useEffect(() => {
        if (otherInfo.contractRegistrationDate !== NO_EDITED_INFO.REGISTRATION_DATE)
            setDate(DateTime.fromFormat(otherInfo.contractRegistrationDate, 'dd/MM/yy'));
    }, [otherInfo]);
    return (
        <ContainerFlex Height="auto" FlexDir="column">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Otra información
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Height="300px"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                AlignContent="center"
                ColumnGap="24px"
            >
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Gerente asignado
                </Text>
                <SelectManager
                    data={branchUsers.filter((item: IUserBranch) =>
                        item.totalRole.includes(VALID_ROLE)
                    )}
                    optionSelect={watchManager}
                    setValue={setValue}
                    errors={errors}
                    trigger={trigger}
                />
                {errors.managerName && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.managerName.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Correo electronico
                </Text>
                <Input
                    Cursor="text"
                    Margin={errors.branchEmail ? '0 0 4px 0' : '0 0 28px 0'}
                    Height="2.5rem"
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Correo electronico"
                    Width="300px"
                    {...register('branchEmail')}
                    type="text"
                    maxLength={160}
                    error={errors.branchEmail?.message}
                />
                {errors.branchEmail && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.branchEmail.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Número registro publico de adhesión
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.publicRegistrationAdhesionNumber ? '0 0 4px 0' : '0 0 28px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Número registro publico de adhesión"
                    Width="300px"
                    {...register('publicRegistrationAdhesionNumber')}
                    type="text"
                    maxLength={160}
                    error={errors.publicRegistrationAdhesionNumber?.message}
                />
                {errors.publicRegistrationAdhesionNumber && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.publicRegistrationAdhesionNumber.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Representate legal
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.legalRepresentative ? '0 0 5px 0' : '0 0 31px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Representate legal"
                    Width="300px"
                    {...register('legalRepresentative')}
                    type="text"
                    maxLength={160}
                    error={errors.legalRepresentative?.message}
                />
                {errors.legalRepresentative && (
                    <Text
                        Padding="0px 12px"
                        FontSize="0.75rem"
                        Margin={errors.legalRepresentative ? '0 0 10px 0' : ''}
                        Color="#ef4f55"
                    >
                        {errors.legalRepresentative.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Fecha de registro contrato
                </Text>
                <DatePickerContract
                    date={date}
                    setDate={setDate}
                    setValue={setValue}
                    errors={errors}
                    trigger={trigger}
                />
                {errors.contractRegistrationDate && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.contractRegistrationDate.message}
                    </Text>
                )}
                <ContainerFlex
                    Height={errors.contractRegistrationDate ? '95px' : '120px'}
                    Width="300px"
                />
            </ContainerFlex>
            <ContainerFlex Padding="18px" Height="16%" Gap="20px">
                <ButtonGeneral
                    width="93px"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        setShowOtherForm(false);
                        reset();
                    }}
                    disabled={otherLoad}
                    cursor={otherLoad ? '' : 'pointer'}
                />
                <ButtonGeneral
                    width="87px"
                    text={otherLoad ? <LoadingGeneralButtons /> : 'Guardar'}
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform="capitalize"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={handleSubmit(onSubmit)}
                    disabled={otherLoad}
                    cursor={otherLoad ? '' : 'pointer'}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
