import {
    BALANCE_CASHFLOW,
    BALANCE_CASHFLOW_SUCCESS,
    BALANCE_CASHFLOW_ERROR,
    BALANCE_EXCESS,
    BALANCE_EXCESS_SUCCESS,
    BALANCE_EXCESS_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';

const initialState: IBalancedCashFlow = {
    balanceCash: {
        branchId: 0,
        balance: 0,
        balanceAverage: 0,
        balanceAveragePrevious: 0,
        mountMax: 0,
        levelId: 0,
        ammountRetention: 0,
    },
    balanceExcss: {
        branchId: 0,
        branchName: '',
        maximumAmount: 0,
        balance: 0,
        extraction: 0,
        levelId: 0,
        levelName: '',
        regionId: 0,
        regionName: '',
        ammountRetention: 0,
    },
    loading: true,
    error: false,
};
export interface BalanceCash {
    branchId: number;
    balance: number;
    balanceAverage: number;
    balanceAveragePrevious: number;
    mountMax: number;
    levelId: number;
    ammountRetention: number;
}

export interface BalanceExcss {
    branchId: number;
    branchName: string;
    maximumAmount: number;
    balance: number;
    extraction: number;
    levelId: number;
    levelName: string;
    regionId: number;
    regionName: string;
    ammountRetention: number;
}

export interface IBalancedCashFlow {
    balanceCash: BalanceCash;
    balanceExcss: BalanceExcss;
    loading: boolean;
    error: boolean;
}

const balanceCashFlow = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case BALANCE_CASHFLOW:
            return {
                ...state,
                loading: true,
            };
        case BALANCE_CASHFLOW_SUCCESS:
            return {
                ...state,
                balanceCash: action.payload,
                loading: false,
            };
        case BALANCE_CASHFLOW_ERROR:
            return {
                ...state,
                error: true,
            };
        case BALANCE_EXCESS:
            return {
                ...state,
                loading: true,
            };
        case BALANCE_EXCESS_SUCCESS:
            return {
                ...state,
                balanceExcss: action.payload,
                loading: false,
            };
        case BALANCE_EXCESS_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
export default balanceCashFlow;
