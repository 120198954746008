import axios, {AxiosResponse} from 'axios';
import {
    GET_CAT_MUNICIPALITY,
    GET_CAT_MUNICIPALITY_ERROR,
    GET_CAT_MUNICIPALITY_RESET,
    GET_CAT_MUNICIPALITY_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {Dispatch} from 'redux';

import {URL} from '@config/constants/index';

export const fetchGetCatMunicipalityStart = () => {
    return {
        type: GET_CAT_MUNICIPALITY,
    };
};
export const fetchGetCatMunicipalitySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_MUNICIPALITY_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetCatMunicipalityError = () => {
    return {
        type: GET_CAT_MUNICIPALITY_ERROR,
    };
};
export const fetchGetCatMunicipalityReset = () => {
    return {
        type: GET_CAT_MUNICIPALITY_RESET,
    };
};

export function GetCatMunicipality(stateId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetCatMunicipalityStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatMunicipality}`,
                {
                    headers,
                    params: {
                        stateId,
                    },
                }
            );
            dispatch(fetchGetCatMunicipalitySuccess(response));
        } catch (error) {
            dispatch(fetchGetCatMunicipalityError());
        }
    };
}
