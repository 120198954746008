import React from 'react';
import {ContainerFlex, Text, ContainerCellGrid, Input} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {DOCUMENTCLIENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import CreditScore from '@components/PersonalLoans/NewLoanProduct/CreditScore';
import {TypeDocuments} from '@components/PersonalLoans/NewLoanProduct/TypeDocuments';

export const DocumentsClients = () => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1.5rem"
            Radius="1.5rem"
            Padding="1.25rem"
            Border="1px solid #E8E9EA"
        >
            <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                {DOCUMENTCLIENTS.TITLE}
            </Text>
            <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                {DOCUMENTCLIENTS.SELECTTYPE}
            </Text>
            <ContainerFlex Justify="start" Gap="1rem" Height="6.25rem">
                <ContainerCellGrid FlexDir="column" Align="start" Width="19.063rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.GENER}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="16px" Height="1.5rem">
                        <ContainerFlex Justify="start" Gap="0.5rem" Width="5.75rem">
                            <Checkbox /> <Text Color="#2A2C2F">{'Mujer'}</Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Gap="0.5rem" Width="5.75rem">
                            <Checkbox /> <Text Color="#2A2C2F">{'Hombre'}</Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="27.25rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.AGE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="16px" Height="2.5rem">
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.BETWEEN}
                        </Text>
                        <Input />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.AND}
                        </Text>
                        <Input />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.YEARS}
                        </Text>
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="19.063rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.SCORE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <CreditScore />
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        <Checkbox /> <Text Color="#2A2C2F">{DOCUMENTCLIENTS.CHECKCREDITS}</Text>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <TypeDocuments />
        </ContainerFlex>
    );
};
