import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {postPurityPay} from '@ActionsPurity/PurityActions';

export function PurityPay() {
    const {
        handleSubmit,
        register,
        watch,
        formState: {errors},
    } = useForm({
        defaultValues: {
            kilatajes: [{name: 'martini'}],
            purezaFundidora: 0,
            purezaPrestalana: 0,
            purezaTerceria: 0,
            valorSeleccionado: 1,
            oroFinoCalculo: 0,
            comisionCalculo: 0,
            onzasCalculo: 0,
            SubtotalCalculo: 0,
            totalCalculo: 0,
        },
    });
    const {id} = useParams();
    const dispatch = useDispatch();

    const watchAllFields = watch();

    const onSubmitAudit = async (value) => {
        const formData = new FormData();
        formData.append('IdFundicion', id);

        formData.append('ImagenReciboPago', watchAllFields.ImagenReciboPago[0]);
        formData.append('CostoFactura', watchAllFields.CostoFactura);

        await dispatch(postPurityPay(formData, token));
        window.location.href = `/Fundiciones/Pureza`;
    };
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    return (
        <>
            <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Pago</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">No. Fundición {id}</span>
            </div>
            <form className="pl-4 mt-5" onSubmit={handleSubmit(onSubmitAudit)}>
                <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                    <label className="w-8 required">Pago</label>
                    <input
                        className="input-react-hook-for "
                        style={{border: errors.ImagenReciboPago ? '1px solid red' : ''}}
                        {...register('ImagenReciboPago', {
                            required: 'Ingrese imagen',
                        })}
                        id="ImagenReciboPago"
                        type="file"
                    />

                    <label className="w-8 ml-4">Moneda</label>
                    <input
                        className="input-react-hook-form  w-20"
                        {...register('CostoFactura', {
                            required: 'Ingrese Moneda',
                        })}
                        id="CostoFactura"
                        aria-invalid={errors.CostoFactura ? 'true' : 'false'}
                    />

                    <button className="w-20 ml-5  btn-add-incidents h-40">GUARDAR</button>
                </div>
            </form>
        </>
    );
}
