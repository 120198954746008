export const listElectronics = [
    {
        id: '0',
        category: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=86a2b795-bdb5-4992-9195-eb25ed7d3c05&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Sony WH-CH700N',
        apart: 7.0,
        sale: 70.0,
        brand: 'Sony',
    },
    {
        id: '1',
        category: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=49f8b0ec-39a7-4fe6-a077-840fbce908b5&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Beats Series 5',
        apart: 7.0,
        sale: 70.0,
        brand: 'Beats',
    },
    {
        id: '2',
        category: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=d94b841a-fd8b-49d5-824d-fa3f1e4d125b&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Sony X800H Inc TV',
        apart: 7.0,
        sale: 70.0,
        brand: 'Sony',
    },
    {
        id: '3',
        category: 'Audifonos',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=95e7d112-b305-457e-a8d9-4402cb035814&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Beats portable Bluetooth',
        apart: 7.0,
        sale: 70.0,
        brand: 'Beats',
    },
    {
        id: '4',
        category: 'Camaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=03a827c5-bf7e-46a6-bd80-2018a0e3b973&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Camara Home 310 Reacondisionada',
        apart: 3.0,
        sale: 30.0,
        brand: 'TP Link',
    },
    {
        id: '5',
        category: 'Camaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=03a827c5-bf7e-46a6-bd80-2018a0e3b973&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Camara Home 310 Reacondisionada',
        apart: 3.0,
        sale: 30.0,
        brand: 'TP Link',
    },
    {
        id: '6',
        category: 'Camaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=03a827c5-bf7e-46a6-bd80-2018a0e3b973&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Camara Home 310 Reacondisionada',
        apart: 3.0,
        sale: 30.0,
        brand: 'TP Link',
    },
    {
        id: '7',
        category: 'Camaras',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=03a827c5-bf7e-46a6-bd80-2018a0e3b973&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Camara Home 310 Reacondisionada',
        apart: 3.0,
        sale: 30.0,
        brand: 'TP Link',
    },
    {
        id: '8',
        category: 'Celulares',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=23a8e7fa-2ccd-483f-979d-ccd7944e99cf&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Apple 12',
        apart: 1000.0,
        sale: 10000.0,
        brand: 'Apple',
    },
    {
        id: '9',
        category: 'Celulares',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=23a8e7fa-2ccd-483f-979d-ccd7944e99cf&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Apple 12',
        apart: 1000.0,
        sale: 10000.0,
        brand: 'Apple',
    },
    {
        id: '10',
        category: 'Celulares',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=23a8e7fa-2ccd-483f-979d-ccd7944e99cf&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Apple 12',
        apart: 1000.0,
        sale: 10000.0,
        brand: 'Apple',
    },
    {
        id: '11',
        category: 'Celulares',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=23a8e7fa-2ccd-483f-979d-ccd7944e99cf&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Apple 12',
        apart: 1000.0,
        sale: 10000.0,
        brand: 'Apple',
    },
    {
        id: '12',
        category: 'Laptop',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=56ea6148-6471-45c0-bf3f-df55b111e433&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Laptop HP 5000',
        apart: 150,
        sale: 1500,
        brand: 'HP',
    },
    {
        id: '13',
        category: 'Laptop',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=56ea6148-6471-45c0-bf3f-df55b111e433&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Laptop HP 5000',
        apart: 150,
        sale: 1500,
        brand: 'Sony',
    },
    {
        id: '14',
        category: 'Laptop',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=56ea6148-6471-45c0-bf3f-df55b111e433&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Laptop HP 5000',
        apart: 150,
        sale: 1500,
        brand: 'HP',
    },
    {
        id: '15',
        category: 'Laptop',
        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=56ea6148-6471-45c0-bf3f-df55b111e433&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
        name: 'Laptop HP 5000',
        apart: 150,
        sale: 1500,
        brand: 'HP',
    },
];

export const Categories = {
    info: {
        results: [
            {
                id: '0',
                name: 'Categorias',
                data: [
                    {
                        id: '0',
                        name: 'Audifonos',
                    },
                    {
                        id: '1',
                        name: 'Camaras',
                    },
                    {
                        id: '2',
                        name: 'Celulares',
                    },
                    {
                        id: '3',
                        name: 'Laptop',
                    },
                ],
            },
            {
                id: '1',
                name: 'Marca',
                data: [
                    {
                        id: '0',
                        name: 'Apple',
                    },
                    {
                        id: '1',
                        name: 'HP',
                    },
                    {
                        id: '2',
                        name: 'Sony',
                    },
                ],
            },
        ],
    },
};

export const listPromo = {
    info: {
        results: [
            {
                id: '0',
                name: 'Articulos con descuento',
                data: [
                    {
                        id: '0',
                        category: 'TV',
                        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=56ea6148-6471-45c0-bf3f-df55b111e433&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
                        name: 'Sony X800H 43 Inc TV',
                        apart: 50.0,
                        sale: 500.0,
                        marca: 'Sony',
                    },
                    {
                        id: '1',
                        category: 'Audio',
                        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=cfb515cc-1f9a-4537-9814-d7d916cdad1c&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
                        name: 'SANAG portable Bluetooth',
                        apart: 7.0,
                        sale: 70.0,
                        marca: 'Sony',
                    },
                    {
                        id: '2',
                        category: 'Audifonos',
                        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=cfb515cc-1f9a-4537-9814-d7d916cdad1c&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
                        name: 'Sony WH-CH700N',
                        apart: 12.0,
                        sale: 120.0,
                        marca: 'Sony',
                    },
                    {
                        id: '3',
                        category: 'Smart Watch',
                        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=23a8e7fa-2ccd-483f-979d-ccd7944e99cf&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
                        name: 'Apple Watch Series 5',
                        apart: 32.0,
                        sale: 320.0,
                        marca: 'Apple',
                    },
                    {
                        id: '4',
                        category: 'Camara',
                        img: 'https://cdn-sharing.adobecc.com/content/storage/id/urn:aaid:sc:US:2be95f9d-268b-4d4f-9c6f-21edbd0cc113;revision=0?component_id=03a827c5-bf7e-46a6-bd80-2018a0e3b973&api_key=CometServer1&access_token=1624330108_urn%3Aaaid%3Asc%3AUS%3A2be95f9d-268b-4d4f-9c6f-21edbd0cc113%3Bpublic_ecf7485d3e6dcbed2113ae4a3dc03a210ec9fda3',
                        name: 'Camara Home A 310 Re...',
                        apart: 3.0,
                        sale: 30.0,
                        marca: 'TP LINK',
                    },
                ],
            },
        ],
    },
};
