import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {useNavigate} from 'react-router-dom';

import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {ContainerArea, ImgLocation, SubContainerArea, TextAreal} from '@CreateLocation/style';
import ButtonGeneral from '@General/Atoms/Button';
import {ContainerFlex, Input, Text, TextArea} from '@Shopify/Ecommerce/styles';
import floor from '@images/floor.svg';
import {insertFloor, restartLocations, updateFloor} from '@ActionsLocation/LocationAction';
import deleteIcon from '@images/delete_forever-black.svg';
import TreeLocations from '@AdminLocations/CreateLocation/Containers/TreeLocations';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {sendFloor, location} from '@Locations/AdminLocations/redux/Interfaces';
import {handleKeypress} from '@/hooks/currentFormatUtils';

const Floor = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/CreateBranch', label: 'Sucursales', reset: () => dispatch(restartLocations(null))},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: 'Crear ubicación'},
    ];
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const dispatch: AppDispatch = useDispatch();
    const history = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const defaultData = {
        nameFloor: '',
        address: '',
        phone: '',
        isHighFlow: '',
        branchId: '',
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: defaultData,
    });

    const handleSwitch = () => {
        setIsChecked((prev) => !prev);
    };

    const onSubmit = async (data: sendFloor) => {
        if (locations.objectbranch.type === 'create')
            dispatch(
                insertFloor(
                    {
                        branchId: locations.saveBranch.idBranch,
                        address: data.address,
                        nameFloor: data.nameFloor,
                        phone: data.phone,
                        isHighFlow: isChecked,
                    },
                    token
                )
            );

        if (locations.objectbranch.type === 'edit')
            dispatch(
                updateFloor(
                    {
                        branchId: locations.saveBranch.idBranch,
                        floorId: locations.objectbranch.id,
                        address: data.address,
                        floorName: data.nameFloor,
                        phone: data.phone,
                        isHighFlow: isChecked,
                    },
                    token
                )
            );
    };

    useEffect(() => {
        if (locations.objectbranch.type === 'edit') {
            reset({
                branchId: String(locations.objectbranch.branchId),
                address: locations.objectbranch.address,
                nameFloor: locations.objectbranch.floorName,
                phone: locations.objectbranch.phone,
                isHighFlow: String(locations.objectbranch.isHighFlow),
            });
            setIsChecked(locations.objectbranch.isHighFlow);
        }
    }, [dispatch]);

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex PaddingB="2rem" Justify="start">
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start">
                <TreeLocations />
                <ContainerFlex Align="flex-start" FlexDir="column" Padding="0 6.313rem 0 1.563rem">
                    <ContainerArea>
                        <SubContainerArea>
                            <Text FontSize="1rem" FontWeight="400">
                                Crear Ubicación
                            </Text>
                        </SubContainerArea>
                        <ContainerFlex Align="flex-start" FlexDir="column" Padding="0 0.625rem">
                            <ContainerFlex
                                Width="150px"
                                FlexDir="column"
                                Align="flex-start"
                                Padding="46px 0 1rem 0"
                            >
                                <ContainerFlex
                                    FlexDir="column"
                                    Border={'2px solid #7AC143'}
                                    Width="98px"
                                    Height="98px"
                                    Radius="5px"
                                >
                                    <img width="50px" height="50px" src={floor} alt="floor" />
                                </ContainerFlex>
                                <ContainerFlex Justify="center" Width="98px">
                                    <Text>Piso</Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex Justify="flex-start" Padding="14px 0">
                                <Text FontSize="1rem" FontWeight="400">
                                    Detalle de la ubicación en piso
                                </Text>
                            </ContainerFlex>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TextAreal IWidth="390px">
                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="14px 0 0 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Nombre de piso
                                        </Text>
                                        <Input
                                            {...register('nameFloor', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                            })}
                                            maxLength={160}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.nameFloor?.message}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Align="flex-start" FlexDir="column">
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Telefono
                                        </Text>
                                        <Input
                                            {...register('phone', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Ingresa sólo números',
                                                },
                                                minLength: {
                                                    value: 10,
                                                    message: 'Mínimo 10 dígitos',
                                                },
                                                maxLength: {
                                                    value: 12,
                                                    message: 'Máximo 12 dígitos',
                                                },
                                            })}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.phone?.message}
                                        </Text>
                                    </ContainerFlex>

                                    <ContainerFlex
                                        Align="flex-start"
                                        FlexDir="column"
                                        Padding="28px 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Detalle de la ubicación
                                        </Text>
                                        <TextArea
                                            {...register('address', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                                minLength: {
                                                    value: 5,
                                                    message: 'Ingresa mínimo 5 carácteres',
                                                },
                                            })}
                                            onKeyPress={handleKeypress}
                                        />
                                        <Text Color="red" FontWeight="400" FontSize="0.75rem">
                                            {errors?.address?.message}
                                        </Text>
                                    </ContainerFlex>
                                </TextAreal>

                                <ContainerFlex Align="flex-start" FlexDir="column">
                                    <Text FontSize="0.75rem" FontWeight="400">
                                        ¿Es una vitrina de alto flujo?
                                    </Text>
                                    <Text Padding="1rem 0" FontSize="0.75rem" FontWeight="400">
                                        No
                                        <GreenSwitch
                                            name="isHighFlow"
                                            defaultChecked
                                            checked={isChecked}
                                            onChange={handleSwitch}
                                            size="small"
                                        />
                                        Sí
                                    </Text>
                                </ContainerFlex>
                            </form>

                            <ContainerFlex Justify="space-between" Width="75%" Padding="50px 0">
                                <ButtonGeneral
                                    transform="capitalize"
                                    hideborder="none"
                                    border="none"
                                    hColor="#35cf44"
                                    width="167px"
                                    text="Cancelar"
                                    clic={() => history('/NavLocations')}
                                />
                                <ButtonGeneral
                                    transform="capitalize"
                                    width="167px"
                                    hColor="#35cf44"
                                    text="Crear Ubicación"
                                    clic={handleSubmit(onSubmit)}
                                />
                                <ContainerFlex
                                    Justify="flex-start"
                                    onClick={() => {
                                        reset(defaultData);
                                        setIsChecked(false);
                                    }}
                                    Width="167px"
                                    Cursor="pointer"
                                >
                                    <ImgLocation
                                        Width="20px"
                                        Height="20px"
                                        Margin="0 5px"
                                        HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                        src={deleteIcon}
                                    />
                                    <Text Cursor="pointer">Limpiar</Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerArea>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default Floor;
