export const GET_LIST_USER_NAMES_START = 'GET_LIST_USER_NAMES_START';
export const GET_LIST_USER_NAMES_SUCCESS = 'GET_LIST_USER_NAMES_SUCCESS';
export const GET_LIST_USER_NAMES_ERROR = 'GET_LIST_USER_NAMES_ERROR';

export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const GET_ALL_USERS_FINISH = 'GET_ALL_USERS_FINISH';
export const RESET_GET_ALL_USERS = 'RESET_GET_ALL_USERS';

export const GET_USERNAME_START = 'GET_USERNAME_START';
export const GET_USERNAME_SUCCESS = 'GET_USERNAME_SUCCESS';
export const GET_USERNAME_ERROR = 'GET_USERNAME_ERROR';
export const RESET_DATA_USERNAME = 'RESET_DATA_USERNAME';
export const RESET_GET_USERNAME = 'RESET_GET_USERNAME';

export const POST_UPDATE_STATUS_USER_START = 'POST_UPDATE_STATUS_USER_START';
export const POST_UPDATE_STATUS_USER_SUCCESS = 'POST_UPDATE_STATUS_USER_SUCCESS';
export const POST_UPDATE_STATUS_USER_ERROR = 'POST_UPDATE_STATUS_USER_ERROR';
export const POST_UPDATE_STATUS_USER_FINISH = 'POST_UPDATE_STATUS_USER_FINISH';

export const ADD_USER_NAME_DELETE = 'ADD_USER_NAME_DELETE';
export const REMOVE_USER_NAME_DELETE = 'REMOVE_USER_NAME_DELETE';
export const PUT_UPDATE_USER_ADD_MESSAGE = 'PUT_UPDATE_USER_ADD_MESSAGE';
export const PUT_UPDATE_REMOVE_MESSAGE = 'PUT_UPDATE_REMOVE_MESSAGE';

export const POST_CREATEUSER_EMPLOYEE_START = 'POST_CREATEUSER_EMPLOYEE_START';
export const POST_CREATEUSER_EMPLOYEE_SUCCESS = 'POST_CREATEUSER_EMPLOYEE_SUCCESS';
export const POST_CREATEUSER_EMPLOYEE_ERROR = 'POST_CREATEUSER_EMPLOYEE_ERROR';
export const POST_CREATEUSER_EMPLOYEE_FINISH = 'POST_CREATEUSER_EMPLOYEE_FINISH';

export const GET_SEARCH_USERS_START = 'GET_SEARCH_USERS_START';
export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS';
export const GET_SEARCH_USERS_ERROR = 'GET_SEARCH_USERS_ERROR';

export const FILTERS = 'FILTERS';
export const SEARCHED = 'SEARCHED';

export const GET_EMAIL_IN_USE_START = 'GET_EMAIL_IN_USE_START';
export const GET_EMAIL_IN_USE_SUCCESS = 'GET_EMAIL_IN_USE_SUCCESS';
export const GET_EMAIL_IN_USE_ERROR = 'GET_EMAIL_IN_USE_ERROR';
export const RESET_GET_EMAIL_IN_USE = 'RESET_GET_EMAIL_IN_USE';

export const GET_PHONE_IN_USE_START = 'GET_PHONE_IN_USE_START';
export const GET_PHONE_IN_USE_SUCCESS = 'GET_PHONE_IN_USE_SUCCESS';
export const GET_PHONE_IN_USE_ERROR = 'GET_PHONE_IN_USE_ERROR';
export const RESET_GET_PHONE_IN_USE = 'RESET_GET_PHONE_IN_USE';

export const POST_CHANGE_PASSWORD_START = 'POST_CHANGE_PASSWORD_START';
export const POST_CHANGE_PASSWORD_SUCCESS = 'POST_CHANGE_PASSWORD_SUCCESS';
export const POST_CHANGE_PASSWORD_ERROR = 'POST_CHANGE_PASSWORD_ERROR';
export const POST_PASSWORD_ADD_MESSAGE = 'POST_PASSWORD_ADD_MESSAGE';
export const POST_PASSWORD_REMOVE_MESSAGE = 'POST_PASSWORD_REMOVE_MESSAGE';

export const GET_RANDOM_PASSWORD_START = 'GET_RANDOM_PASSWORD_START';
export const GET_RANDOM_PASSWORD_SUCCESS = 'GET_RANDOM_PASSWORD_SUCCESS';
export const GET_RANDOM_PASSWORD_ERROR = 'GET_RANDOM_PASSWORD_ERROR';
export const RESET_DATA_PASSWORD = 'RESET_DATA_PASSWORD';

export const RESET_DELETE_USER_SUCCEEDED = 'RESET_DELETE_USER_SUCCEEDED';
export const UPDATE_DATA_FORM_CREATE_USER = 'UPDATE_DATA_FORM_CREATE_USER';
export const RESET_FORM_CREATE_USER = 'RESET_FORM_CREATE_USER';

export const ADD_MESSAGGE_ALERT = 'ADD_MESSAGGE_ALERT';
export const RESET_MESSAGGE_ALERT = 'RESET_MESSAGGE_ALERT';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';

export const TREE = {
    GROUPS: 'GROUPS',
    GROUPS_SUCCESS: 'GROUPS_SUCCESS',
    GROUPS_ERROR: 'GROUPS_ERROR',
    BRANCHES: 'BRANCHES',
    BRANCHES_SUCCESS: 'BRANCHES_SUCCESS',
    BRANCHES_ERROR: 'BRANCHES_ERROR',
    SHOW_BRANCHES: 'SHOW_BRANCHES',
    RESET: 'RESET',
};
export const ADD_ROLES_START = 'ADD_ROLES_START';
export const ADD_ROLES_SUCCESS = 'ADD_ROLES_SUCCESS';
export const ADD_ROLES_ERROR = 'ADD_ROLES_ERROR';

export const SAVE_USER = {
    PERSONAL_DATA: 'PERSONAL_DATA',
    CREDENTIALS: 'CREDENTIALS',
    ADMIN_ROLES: 'ADMIN_ROLES',
    POS_ROLES: 'POS_ROLES',
    ADDRESS: 'ADDRESS',
    TYPE: 'TYPE',
    RESET: 'RESET',
};

export const EDIT_USER = {
    INIT: 'INIT',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
};
