import {FormCreateUser} from '@components/CreateUsers/Interface';
import {
    UPDATE_DATA_FORM_CREATE_USER,
    RESET_FORM_CREATE_USER,
} from '@components/CreateUsers/Redux/Types/Types';

export const updateDataForm = (data: FormCreateUser) => {
    return {
        type: UPDATE_DATA_FORM_CREATE_USER,
        payload: data,
    };
};

export const resetDataForm = () => {
    return {
        type: RESET_FORM_CREATE_USER,
    };
};
