export const GET_BRANCH = 'GET_BRANCH';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR';

export const CASH_NOTIFY = 'CASH_NOTIFY';
export const CASH_NOTIFY_SUCCESS = 'CASH_NOTIFY_SUCCESS';
export const CASH_NOTIFY_ERROR = 'CASH_NOTIFY_ERROR';

export const UPDATE_CASH_NOTIFY = 'UPDATE_CASH_NOTIFY';
export const UPDATE_CASH_NOTIFY_SUCCESS = 'UPDATE_CASH_NOTIFY_SUCCESS';
export const UPDATE_CASH_NOTIFY_ERROR = 'UPDATE_CASH_NOTIFY_ERROR';
export const RESET = 'RESET';
export const RESET_UPDATE = 'RESET_UPDATE';

export const SAVE_CASHFLOW_ID = 'SAVE_CASHFLOW_ID';
export const RESET_CASHFLOW_ID = 'RESET_CASHFLOW_ID';
