import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {IPropsIconContainer, IPropsIconSvg} from '@NotificationsList/interfacesStyles';

export const IconContainer = styled.div<IPropsIconContainer>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: 100;
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    padding: ${(props) => props.Padding};
    transform: ${(props) => props.Tranform};
    cursor: pointer;
`;

IconContainer.defaultProps = {
    Color: ' ',
    FontSize: ' ',
    Padding: '0',
    Width: '24px',
    Height: '24px',
};

export const IconSvg = styled.svg<IPropsIconSvg>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    fill: 'none';
    transition: ${(props) => props.Transition};
    rotate: ${(props) => props.Rotate};
    &:hover path {
        fill: ${(props) => props.HoverF};
    }
`;
export const LinkAuction = styled(Link)`
    padding: 8px 12px;
    width: 95%;
    height: 40px;
    justify-content: space-between;
    border: 1px solid #f3f3f3;
    cursor: pointer;
`;
