import {EDIT_USER, SAVE_USER} from '@components/CreateUsers/Redux/Types/Types';
import {IEditUserReducer, IEditUser} from '@components/CreateUsers/Redux/interface';

const initialState: IEditUser = {
    editUsers: {
        accessCredentials: {
            email: '',
            imageAdmin: '',
            imagePos: '',
            nameUser: '',
            password: '',
            pin: '',
            repeatPassword: '',
        },
        addressUser: [],
        birthdate: '',
        lastName: '',
        motherLastName: '',
        name: '',
        userBranchs: [{branchId: 0, branchName: ''}],
        userId: 0,
        userImage: '',
        userRolAdmin: [{catTypeRolId: 0, catTypeRolName: '', rolId: 0, rolName: ''}],
        userRolPos: [{catTypeRolId: 0, catTypeRolName: '', rolId: 0, rolName: ''}],
    },
    update: null,
    loading: false,
    error: false,
};

export const editUsers = (state = initialState, action: IEditUserReducer): IEditUser => {
    switch (action.type) {
        case EDIT_USER.INIT:
            return {
                ...state,
                loading: true,
                error: true,
            };
        case EDIT_USER.SUCCESS:
            return {
                ...state,
                editUsers: action.editUsers,
                loading: false,
                error: false,
            };
        case EDIT_USER.UPDATE_SUCCESS:
            return {
                ...state,
                update: action.update,
                loading: false,
                error: false,
            };
        case EDIT_USER.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case SAVE_USER.RESET:
            return initialState;
        default:
            return state;
    }
};
