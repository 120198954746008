import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {MoreInfoProps} from '@Shopify/ClientProfile/interfaces';
import {MOREINFO_TABS} from '@Shopify/ClientProfile/constants';
import {ContainerTabs} from '@General/ModuleTabs/styles/styles';
import PersonalInfo from '@/components/Shopify/ClientProfile/Information/PersonalInfo';
import AddressInfo from '@/components/Shopify/ClientProfile/Information/AddressInfo';
import EmploymentInfo from '@/components/Shopify/ClientProfile/Information/EmploymentInfo';
import IdentityDocumentInfo from '@/components/Shopify/ClientProfile/Information/IdentityDocumentInfo';
import Documents from '@/components/Shopify/ClientProfile/Information/Documents';
import CoOwners from '@/components/Shopify/ClientProfile/Information/CoOwners';

const MoreInformation: React.FC<MoreInfoProps> = ({viewingInfo}) => {
    const {clientProfile} = useSelector((state: RootState) => state.cardClientProfile);
    return (
        <ContainerTabs
            tabs={MOREINFO_TABS}
            Gap="0.5rem"
            FlexDir="column"
            Align="center"
            Justify="flex-start"
        >
            <PersonalInfo clientProfile={clientProfile} />
            <AddressInfo />
            <EmploymentInfo clientProfile={clientProfile} />
            <IdentityDocumentInfo />
            <Documents clientProfile={clientProfile} />
            <CoOwners clientProfile={clientProfile} />
        </ContainerTabs>
    );
};

export default MoreInformation;
