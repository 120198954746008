import {
    PAGE_NUMBERED,
    SELECT_FATHER_ID,
    STATE,
    UPDATE_SELECT_NODE_ID,
    UPDATE_STATE,
} from '@TypesOrgCreation/types';
import {ICheckedState, ICheckedStateRedux} from '@OrganizationCreation/interface';

const initialState: ICheckedState = {
    statement: [],
    updateStatement: [],
    pageNumber: 0,
    loading: false,
    error: false,
};
const GetStatmentData = (state = initialState, action: ICheckedStateRedux): ICheckedState => {
    switch (action.type) {
        case STATE:
            return {
                ...state,
                statement: action.statement,
                loading: false,
            };
        case SELECT_FATHER_ID:
            return {
                ...state,
                statement: action.statement,
                loading: false,
            };
        case UPDATE_STATE:
            return {
                ...state,
                updateStatement: action.updateStatement,
                loading: false,
            };
        case UPDATE_SELECT_NODE_ID:
            return {
                ...state,
                updateStatement: action.updateStatement,
            };
        case PAGE_NUMBERED:
            return {
                ...state,
                pageNumber: action.pageNumber,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetStatmentData;
