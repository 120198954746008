import React, {Fragment, useEffect} from 'react';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ContentLevels, Icons, Numbers} from '@Articles/style';
import {
    AddRouteItems,
    DeleteRouteItems,
    GetLevelId,
    LastRouteItems,
    RestartSearchArticle,
    TrueDeadlines,
} from '@ActionsDeadlinesindex/Search';
import {HandleLevelsRoute} from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/HandleLevelsRoute';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import * as style from '@Articles/style';
import {levelName} from '@Articles/interface';
import {routeDeadlines} from '@/components/DeadLines/DeadlinesIndex/redux/Interface';

export const CardLevels = (props: routeDeadlines) => {
    const {level, levelList, levelGral, idHigher} = props;
    const lastItem = levelGral.articles.RouteItemsPromotions;

    const RouteAdd = (element: levelName) => {
        if (level !== 5) {
            HandleLevelsRoute(
                levelGral.dispatch,
                levelGral.token,
                level,
                element.id,
                String(levelGral.branch)
            );
            levelGral.dispatch(
                AddRouteItems({
                    description: element.description,
                    id: element.id,
                    status: element.status,
                    icon: element.urlIcon,
                })
            );
            levelGral.dispatch(
                LastRouteItems({
                    description: element.description,
                    id: element.id,
                    status: element.status,
                })
            );
        } else {
            if (levelGral.articles.RouteItemsPromotions.length < 5) {
                levelGral.dispatch(
                    AddRouteItems({
                        description: element.description,
                        id: element.id,
                        status: element.status,
                        icon: element.urlIcon,
                    })
                );
            }
            levelGral.dispatch(
                LastRouteItems({
                    description: element.description,
                    id: element.id,
                    status: element.status,
                })
            );

            if (
                levelGral.articles.RouteItemsPromotions.filter(
                    (item: {id: number; status: boolean}) =>
                        item.id === levelGral.articles.CurrentRoute.id && item.status === true
                )
            ) {
                levelGral.dispatch(TrueDeadlines());
            }
        }
        levelGral.dispatch(GetLevelId(level));
    };

    const RouteDelete = (index: number, value: {id: number}[]) => {
        const id = index === 0 ? 0 : value[index - 1].id;
        HandleLevelsRoute(levelGral.dispatch, levelGral.token, index, id, String(levelGral.branch));
        levelGral.dispatch(DeleteRouteItems(index));
        levelGral.dispatch(GetLevelId(index));
    };

    useEffect(() => {
        if (levelGral.articles.RouteItemsPromotions.length > 0 && levelGral.articles.getLevel > 0) {
            HandleLevelsRoute(
                levelGral.dispatch,
                levelGral.token,
                levelGral.articles.getLevel,
                levelGral.articles.RouteItemsPromotions[
                    levelGral.articles.RouteItemsPromotions.length - 1
                ].id,
                String(levelGral.branch)
            );
        }
    }, []);

    useEffect(() => {
        if (levelGral.articles.ItemsArticle && levelGral.articles.ItemsArticle.data === null)
            setTimeout(() => levelGral.dispatch(RestartSearchArticle()), 3000);
    }, [levelGral.articles.ItemsArticle]);

    return (
        <ContainerFlex {...style.Card}>
            {levelGral.branch === undefined ? (
                <Text Padding="2rem 1.5rem">Seleccione una Sucursal.</Text>
            ) : levelGral.articles.ItemsArticle &&
              levelGral.articles.ItemsArticle.data === null &&
              idHigher === 0 ? (
                <ContainerFlex Width="100%" Bb="1px solid #E4E7E9">
                    <Text Padding="2rem 0">
                        No se encontraron resultados, intenta modificar los parámetros de búsqueda.
                    </Text>
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex {...style.Title} Padding="1.25rem 1.5rem">
                        <ContainerFlex Justify="flex-start" Cursor="pointer">
                            <Numbers {...style.Level}>{level}</Numbers>
                            <Text FontSize="1.125rem" Color="#414042">
                                Selecciona el nivel {level}
                            </Text>
                        </ContainerFlex>

                        {levelGral.articles.RouteItemsPromotions.length > 0 && (
                            <ContainerFlex {...style.ContentLevels}>
                                <Text
                                    Color="#35cf44"
                                    onClick={() => levelGral.dispatch(TrueDeadlines())}
                                    Cursor="pointer"
                                >
                                    Crear plazo
                                </Text>
                                <Icons
                                    Height="12px"
                                    {...style.CreateLevel}
                                    className="material-icons"
                                >
                                    chevron_right
                                </Icons>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                    {levelGral.articles.RouteItemsPromotions.length > 0 && (
                        <ContainerFlex
                            Justify="left"
                            Border=""
                            Height="fit-content"
                            Bb="1px solid #E4E7E9"
                            Padding="1.428rem 3.875rem"
                            FlexWrap="flex-wrap"
                            Cursor="pointer"
                        >
                            <Numbers
                                Width="2.563rem"
                                Height="2.563rem"
                                Border={
                                    lastItem[lastItem.length - 1].icon !== ''
                                        ? ''
                                        : '1px solid #E4E7E9'
                                }
                                Margin="0 0.6rem 0 0"
                            >
                                {lastItem[lastItem.length - 1].icon ? (
                                    <img src={lastItem[lastItem.length - 1].icon} />
                                ) : (
                                    <Icons className="material-icons">category</Icons>
                                )}
                            </Numbers>
                            <ContainerFlex
                                Width="auto"
                                FlexWrap="wrap"
                                Padding="0 1rem"
                                Justify="left"
                            >
                                {levelGral.articles.RouteItemsPromotions.map(
                                    (value: {description: string}, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                <ContentLevels
                                                    FontWeight="400"
                                                    Color="#414042"
                                                    FontSize="0.875rem"
                                                    TextDecoration="underline"
                                                    Cursor="pointer"
                                                    onClick={() =>
                                                        RouteDelete(
                                                            index,
                                                            levelGral.articles.RouteItemsPromotions
                                                        )
                                                    }
                                                >
                                                    {value.description}
                                                </ContentLevels>
                                                {lastItem.length - 1 !== index && (
                                                    <Icons
                                                        className="material-icons"
                                                        FontSize="1.2rem"
                                                        Color="#414042"
                                                    >
                                                        chevron_right
                                                    </Icons>
                                                )}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                    {levelGral.articles.RouteItemsPromotions.length < 5 && (
                        <style.ContainerItems
                            MinHeight={level === 1 ? '56vh' : '63vh'}
                            MaxHeight={level === 1 ? '56vh' : '63vh'}
                            OverFlow="auto"
                        >
                            {levelList.data?.length > 0 ? (
                                levelList.data
                                    ?.filter(
                                        (item: {description: string}) => item.description !== ''
                                    )
                                    .map((element: levelName, index: number) => {
                                        return (
                                            <ContainerFlex
                                                key={index}
                                                TOpacity
                                                Cursor="pointer"
                                                {...style.Items}
                                                Bb="1px solid #E4E7E9"
                                            >
                                                <ContainerFlex
                                                    Padding="0.625rem 0 0.625rem 3.875rem"
                                                    onClick={() => RouteAdd(element)}
                                                    Cursor="pointer"
                                                    Justify="left"
                                                >
                                                    <ContentLevels
                                                        FontSize="0.875rem"
                                                        Cursor="pointer"
                                                    >
                                                        {element.description}
                                                    </ContentLevels>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        );
                                    })
                            ) : (
                                <ContainerFlex Width="100%">
                                    {levelList.data && levelList.data.length === 0 ? (
                                        <Text Padding="2rem 0">
                                            No se encontraron coincidencias
                                        </Text>
                                    ) : (
                                        <LoadingGeneral />
                                    )}
                                </ContainerFlex>
                            )}
                        </style.ContainerItems>
                    )}
                </>
            )}
        </ContainerFlex>
    );
};
