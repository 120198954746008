import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {
    GET_TAXDATA_START,
    GET_TAXDATA_SUCCESS,
    GET_TAXDATA_ERROR,
} from '@components/AccountSettings/Redux/types/Types';

const getTaxDataStart = () => ({
    type: GET_TAXDATA_START,
});

const getTaxDataSuccess = (data: AxiosResponse) => ({
    type: GET_TAXDATA_SUCCESS,
    payload: data,
});

const getTaxDataError = (error: AxiosError) => ({
    type: GET_TAXDATA_ERROR,
    error: error,
});

export const GetTaxData = (companyId: number, token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTaxDataStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCompanyTaxData}`,
                {
                    headers,
                    params: {
                        companyId,
                    },
                }
            );
            dispatch(getTaxDataSuccess(response));
        } catch (error: unknown) {
            dispatch(getTaxDataError(error as AxiosError));
        }
    };
};
