import React from 'react';
import {ClickAwayListener} from '@mui/material';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import moreVertIcon from '@components/Branches/Images/MoreVertIcon.svg';
import {IOptionsProps} from '@components/General/Atoms/RecordMoreOptions/interfaces';

const MoreOptions: React.FC<IOptionsProps> = ({isOpen, onOptionClick, children}) => {
    return (
        <ContainerFlex Position="relative" Height="fit-content">
            <Image src={moreVertIcon} alt="more" Width="24px" Height="24px" Cursor="pointer" />
            {isOpen && (
                <ClickAwayListener onClickAway={onOptionClick}>
                    <ContainerFlex
                        Position="absolute"
                        PositionTop="-5rem"
                        PositionRight="1rem"
                        Width="15rem"
                        Height="auto"
                        ZIndex="3"
                        backG="#FFFF"
                        FlexDir="column"
                        Border="1px solid #E8E9EA"
                        Align="start"
                    >
                        {children}
                    </ContainerFlex>
                </ClickAwayListener>
            )}
        </ContainerFlex>
    );
};

export default MoreOptions;
