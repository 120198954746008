import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {REMOVE_ROLE} from '@Roles/RemoveRole/Redux/Types';
import {getAllUserRole} from '@/components/Roles/CreateRoles/Redux/Actions/GetSearchUserByRole';

export const removeRoleStart = () => ({type: REMOVE_ROLE.REMOVE_ROL_START});
export const removeRoleSuccess = (result: AxiosResponse) => ({
    type: REMOVE_ROLE.REMOVE_ROL_SUCCESS,
    removeRole: result,
});
export const removeRoleError = (error: AxiosError) => ({
    type: REMOVE_ROLE.REMOVE_ROL_ERROR,
    error: error,
});

export const removeRoles = (
    token: string,
    data: {roleId: number; userId: number},
    setShowModalRole: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(removeRoleStart());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteUserRol}`,
                {headers, data}
            );
            dispatch(removeRoleSuccess(response));
            setShowModalRole(false);
            dispatch(
                getAllUserRole(token, {
                    roleId: data.roleId,
                    search: '',
                })
            );
        } catch (error) {
            dispatch(removeRoleError(error as AxiosError));
        }
    };
};
