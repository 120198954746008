import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {RootState, AppDispatch} from '@config/store';
import {ContainerFlex, ContainerIcon, Figure, Image, Text} from '@Shopify/Ecommerce/styles';
import {ModalChangePassword} from '@components/ManageUser/ActionsSidebar/ModalChangePassword';
import {ModalUploadImage} from '@components/ManageUser/ModalUploadImages/ModaUploadImage';
import {Branch, UserData} from '@components/ManageUser/Redux/interface';
import {getPasswordRandomAxios} from '@components/ManageUser/Redux/Actions/getPasswordRandomAction';
import {ModalDisadleUser} from '@components/ManageUser/ActionsSidebar/ModalDisadleUser';
import {ModalDeleteUser} from '@components/ManageUser/ActionsSidebar/ModalDeleteUser';
import {getImageUserAxios} from '@components/ManageUser/Redux/Actions/getImageUserAction';
import {ModalChangeOrganizations} from '@components/ManageUser/ActionsSidebar/ModalChangeOrganizations';
import {CameraSmall} from '@components/ManageUser/Icons/CameraSmall';
import {Camera} from '@components/ManageUser/Icons/Camera';
import ChevronRight from '@components/ManageUser/Icons/chevronRight.svg';
import {MessageSuccessPassword} from '@components/CreateUsers/MessageSuccessPassword';

export const SidebarSettings = () => {
    const dispatch: AppDispatch = useDispatch();
    const {idUser} = useParams();
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    const totalItems = useSelector((state: RootState) => {
        return state.getAllBranches.tableBranches?.message;
    });
    const imagerUpdate = useSelector((state: RootState) => state.postImageUser.data);
    const userPasswordChange = useSelector(
        (state: RootState) => state.PostChangePass.userPasswordChange
    );

    const userImage = useSelector((state: RootState) => state.getImageUser.data);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [openModalDisableUser, setOpenModalDisableUse] = useState(false);
    const [openModalUploadImage, setOpenModalUploadImage] = useState(false);
    const [openModalDeleteUser, setOpenModalDeleteUse] = useState(false);
    const [openModalOrganization, setOpenModalOrganization] = useState(false);

    const totalBranchsText = (branchs: Branch[]) => {
        const roles: {[key: number]: string} = {
            0: `Sin sucursales asignadas`,
            1: `${branchs?.map((branch) => branch.branchName)}`,
            2: `Permisos en más de una sucursal`,
        };
        return roles[branchs?.length] ?? `Permisos en  ${branchs?.length} sucursales`;
    };

    useEffect(() => {
        if (idUser) dispatch(getImageUserAxios(token, parseInt(idUser)));
    }, [dispatch, idUser, imagerUpdate, token]);

    return (
        <ContainerFlex
            Width="18%"
            Height="auto"
            FlexDir="column"
            Border="1px solid #E5E7E9"
            Radius="4px"
        >
            <ContainerFlex
                Height="auto"
                Padding="12px 16px"
                Justify="start"
                Bb="1px solid #E5E7E9"
                Gap="4px"
                backG={UserInformation.statusUser ? '' : '#f3f3f3'}
                FlexWrap="wrap"
            >
                {userImage !==
                'https://storage.googleapis.com/goldenstarweb/media/Assests/goldenstar.png' ? (
                    <Figure Position="relative">
                        <Image
                            Radius="50%"
                            Height="56px"
                            Width="56px"
                            src={userImage}
                            Opacity={UserInformation.statusUser ? '1' : '0.5'}
                        />
                        {UserInformation.statusUser && (
                            <ContainerIcon
                                Padding="6px"
                                Align="start"
                                Opacity="0"
                                HOpacity="1"
                                PositionTop="50%"
                                Position="absolute"
                                ClipPath="circle(50.0% at 50% 0)"
                                Height="56px"
                                Width="56px"
                                BackGround="rgb(109, 110, 113, 80%)"
                                Border="solid 1px #e5e7e9"
                                onClick={() => setOpenModalUploadImage(true)}
                            >
                                <CameraSmall />
                            </ContainerIcon>
                        )}
                    </Figure>
                ) : (
                    <ContainerIcon
                        Radius="50%"
                        Height="56px"
                        Width="56px"
                        BackGround="#f3f3f3"
                        Border="solid 1px #e5e7e9"
                        onClick={() => setOpenModalUploadImage(true)}
                    >
                        <Camera />
                    </ContainerIcon>
                )}

                {openModalUploadImage && (
                    <ModalUploadImage setOpenModalUploadImage={setOpenModalUploadImage} />
                )}
                <Text
                    MaxW="65%"
                    Height=""
                    wSpace="normal"
                    FontSize="0.875rem"
                    Color="#414042"
                    FlexDirection="column"
                    Align="start"
                >
                    {UserInformation.userEmployeeName}
                    {!UserInformation.statusUser && (
                        <Text Height="" wSpace="normal" FontSize="0.875rem" Color="#ef4f55">
                            Deshabilitado
                        </Text>
                    )}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Height="66px"
                Padding="0 16px"
                Justify="space-evenly"
                Bb="1px solid #E5E7E9"
                FlexDir="column"
            >
                <Text
                    FontSize="0.75rem"
                    Color="#A7A9AC"
                    Width="100%"
                    TextAlign="start"
                    Height="12px"
                >
                    Nivel organizacional
                </Text>
                <ContainerFlex
                    Height=""
                    Position="relative"
                    HVisibility={UserInformation.branchs?.length > 1 ? 'visible' : ''}
                >
                    <Text
                        FontSize="0.875rem"
                        Color="#414042"
                        Width="100%"
                        TextAlign="start"
                        Height=""
                        wSpace="normal"
                        LHeight="normal"
                        Position="relative"
                    >
                        {parseInt(totalItems) === UserInformation?.branchs?.length
                            ? 'Todas'
                            : totalBranchsText(UserInformation?.branchs)}
                    </Text>
                    <ContainerFlex
                        Height="auto"
                        FlexDir="column"
                        backG="#E5E7E9"
                        Padding="8px"
                        Position="absolute"
                        PositionTop="90%"
                        ZIndex="3"
                        Align="start"
                        Visibility="hidden"
                    >
                        {UserInformation.branchs?.map((branch, index) => (
                            <Text key={index} Height="0.875rem" FontSize="0.875rem" Color="#414042">
                                {`${index + 1}. ${branch.branchName}`}
                            </Text>
                        ))}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {UserInformation.statusUser && (
                <>
                    <ContainerFlex
                        Height="40px"
                        Padding="0 12px"
                        Justify="space-between"
                        MarginTop="12px"
                        Cursor="pointer"
                        onClick={() => setOpenModalOrganization(true)}
                    >
                        <Text
                            FontSize="0.875rem"
                            Color="#414042"
                            Width="100%"
                            TextAlign="start"
                            Cursor="pointer"
                        >
                            Cambiar organización
                        </Text>
                        <Figure Height="24px" Width="24px">
                            <Image src={ChevronRight} />
                        </Figure>
                    </ContainerFlex>
                    {openModalOrganization && (
                        <ModalChangeOrganizations
                            setOpenModalOrganization={setOpenModalOrganization}
                        />
                    )}
                    <ContainerFlex
                        Height="40px"
                        Padding="0 12px"
                        Justify="space-between"
                        Cursor="pointer"
                        onClick={() => {
                            setOpenModalPassword(true);
                            dispatch(getPasswordRandomAxios(token));
                        }}
                    >
                        <Text
                            FontSize="0.875rem"
                            Color="#414042"
                            Width="100%"
                            TextAlign="start"
                            Cursor="pointer"
                        >
                            Cambiar contraseña
                        </Text>
                        <Figure Height="24px" Width="24px">
                            <Image src={ChevronRight} />
                        </Figure>
                    </ContainerFlex>
                </>
            )}
            {openModalPassword && (
                <ModalChangePassword setOpenModalPassword={setOpenModalPassword} />
            )}
            <ContainerFlex
                Height="40px"
                Padding="0 12px"
                Justify="space-between"
                onClick={() => setOpenModalDisableUse(true)}
                Cursor="pointer"
            >
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    Width="100%"
                    TextAlign="start"
                    Cursor="pointer"
                >
                    {UserInformation.statusUser ? 'Deshabilitar' : 'Habilitar'} usuario
                </Text>
                <Figure Height="24px" Width="24px">
                    <Image src={ChevronRight} />
                </Figure>
            </ContainerFlex>
            {openModalDisableUser && (
                <ModalDisadleUser
                    setOpenModalDisableUse={setOpenModalDisableUse}
                    status={UserInformation.statusUser}
                />
            )}
            <ContainerFlex
                Height="40px"
                Padding="0 12px"
                Justify="space-between"
                MarginBt="12px"
                Cursor="pointer"
                onClick={() => setOpenModalDeleteUse(true)}
            >
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    Width="100%"
                    TextAlign="start"
                    Cursor="pointer"
                >
                    Eliminar usuario
                </Text>
                <Figure Height="24px" Width="24px">
                    <Image src={ChevronRight} />
                </Figure>
            </ContainerFlex>
            {openModalDeleteUser && (
                <ModalDeleteUser setOpenModalDeleteUse={setOpenModalDeleteUse} />
            )}
            {userPasswordChange && (
                <MessageSuccessPassword userPasswordChange={userPasswordChange} />
            )}
        </ContainerFlex>
    );
};
