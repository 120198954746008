export const GET_BANNER_TABLE = 'GET_BANNER_TABLE';
export const GET_BANNER_TABLE_SUCCESS = 'GET_BANNER_TABLE_SUCCESS';
export const GET_BANNER_TABLE_ERROR = 'GET_BANNER_TABLE_ERROR';

export const GET_GROUP = 'GET_GROUP';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_ERROR = 'GET_GROUP_ERROR';

export const GET_FAMILY = 'GET_FAMILY';
export const GET_FAMILY_SUCCESS = 'GET_FAMILY_SUCCESS';
export const GET_FAMILY_ERROR = 'GET_FAMILY_ERROR';

export const GET_SUBFAMILY = 'GET_SUBFAMILY';
export const GET_SUBFAMILY_SUCCESS = 'GET_SUBFAMILY_SUCCESS';
export const GET_SUBFAMILY_ERROR = 'GET_SUBFAMILY_ERROR';

export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';

export const GET_ITEM = 'GET_ITEM';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_ERROR = 'GET_ITEM_ERROR';

export const POST_BANNER = 'POST_BANNER';
export const POST_BANNER_SUCCESS = 'POST_BANNER_SUCCESS';
export const POST_BANNER_ERROR = 'POST_BANNER_ERROR';

export const POST_BANNER_DETAIL = 'POST_BANNER_DETAIL';
export const POST_BANNER_DETAIL_SUCCESS = 'POST_BANNER_DETAIL_SUCCESS';
export const POST_BANNER_DETAIL_ERROR = 'POST_BANNER_DETAIL_ERROR';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';
