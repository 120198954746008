import React, {useState} from 'react';
import {ContainerCellGrid, Text} from '@Shopify/Ecommerce/styles';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {header, styles, user as userData} from '@/components/MyCompanyUser/Operational/Constants';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {SearchFilterOfUser} from '@/components/MyCompanyUser/filtersOfUser';
import {ImageLogo} from '@/components/LoginClient/styles';
import points from '@images/iconeThreePoin.svg';
import {IconUser} from '@/components/MyCompanyUser/Operational/style';
import {useNavigate} from 'react-router-dom';

export const TableAllUsers = () => {
    const navigate = useNavigate();
    const [filteredUsers, setFilteredUsers] = useState(userData);

    const handleSearch = (searchTerm: string) => {
        const filtered = userData.filter((user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleClick = () => {
        navigate('/User/UserProfile');
    };

    return (
        <ContainerCellGrid FlexDir="column" Gap="16px" Height="max-content">
            <SearchFilterOfUser onSearch={handleSearch} />
            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="repeat(5, 1fr)"
                    Border="0 0 1px 0 solid red"
                    Height="40px"
                    Gap="8px"
                >
                    {header.map((header, index) => (
                        <TableItem Justify="start" Gap="8px" Width="100%" key={index}>
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                                {header}
                            </Text>
                        </TableItem>
                    ))}
                </TableRowHead>
                <TableItemsContainer>
                    {filteredUsers.map((user, rowIndex) => (
                        <TableRowItems
                            Height="40px"
                            GridColumn="repeat(5, 1fr)"
                            BorderB="0.5px solid #e4e7e9"
                            Border=""
                            key={rowIndex}
                        >
                            <TableItem Gap="8px" Justify="start" Padding="4px">
                                <IconUser src={user.image} />
                                <Text
                                    Color="#5A5AFF"
                                    FontWeight="500"
                                    FontSize="0.75rem"
                                    color="#54575C"
                                    HColor="#000061"
                                    onClick={handleClick}
                                >
                                    {user.name}
                                </Text>
                            </TableItem>
                            <TableItem Justify="space-between">
                                <Text FontFamily="Nunito" FontWeight="500" FontSize="0.75rem">
                                    {user.role}
                                </Text>
                            </TableItem>
                            <TableItem Justify="start">
                                {['Admin', 'Pos'].map((type) => {
                                    const hasAccess = user.access.includes(type);
                                    if (!hasAccess) return null;

                                    const {color, border, backgroundColor} =
                                        styles[type as 'Admin' | 'Pos'];

                                    return (
                                        <Text
                                            key={type}
                                            BorderRadius="32px"
                                            Color={color}
                                            Border={border}
                                            BGColor={backgroundColor}
                                            FontWeight="500"
                                            FontSize="0.75rem"
                                            Width="max-content"
                                            FontFamily="Nunito"
                                            Padding="2px 8px"
                                            MarginRight="4px"
                                        >
                                            {type.charAt(0).toUpperCase() + type.slice(1)}
                                        </Text>
                                    );
                                })}
                            </TableItem>

                            <TableItem Justify="space-between">
                                <Text FontWeight="500" FontSize="0.75rem">
                                    {user.lastAccessDate}
                                </Text>
                            </TableItem>
                            <TableItem Justify="space-between">
                                <GreenSwitch name="status" />
                                <ImageLogo
                                    Cursor="pointer"
                                    Width="24px"
                                    Height="24px"
                                    src={points}
                                />
                            </TableItem>
                        </TableRowItems>
                    ))}
                </TableItemsContainer>
            </Table>
        </ContainerCellGrid>
    );
};
