import React from 'react';
import {DateTime} from 'luxon';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {Box, ThemeProvider} from '@mui/material';
import {LocalizationProvider, MobileTimePicker} from '@mui/x-date-pickers';
import {SearchInput} from '@/components/Customer/Styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {IScheduleBranch, ITimePicker} from '@/components/Branches/interfaces';
import {theme} from '@components/Branches/constants';

export const TimePickerBranch = ({
    id,
    startHour,
    endHour,
    setStartHour,
    setEndHour,
    isStart,
    disabled,
    scheduleUpdated,
    setScheduleUpdated,
}: ITimePicker) => {
    const handleChangeHour = (newDate: DateTime | null) =>
        isStart ? setStartHour(newDate) : setEndHour(newDate);
    const handleChangeSchedule = (newDate: DateTime | null) => {
        setScheduleUpdated(
            scheduleUpdated.map((operation: IScheduleBranch) => {
                if (isStart && operation.operationId === id) {
                    return {...operation, startHour: newDate?.toFormat('HH:mm')};
                }
                if (!isStart && operation.operationId === id) {
                    return {...operation, endHour: newDate?.toFormat('HH:mm')};
                }
                return operation;
            })
        );
    };
    return (
        <ContainerFlex Height="35px" Width="110px" Margin="0 0 28px 0">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <ThemeProvider theme={theme}>
                    <MobileTimePicker
                        disabled={disabled}
                        value={isStart ? startHour : endHour}
                        onChange={(newDate) => {
                            handleChangeHour(newDate);
                            handleChangeSchedule(newDate);
                        }}
                        label={'Selecciona la hora'}
                        renderInput={({inputRef, inputProps}) => (
                            <Box>
                                <SearchInput
                                    Width="110px"
                                    Height="35px"
                                    Position="none"
                                    GridColumn="90% 10%"
                                    MinH="35px"
                                    Margin="0 0 5px 0"
                                    Border="1px solid #e5e7e9"
                                    BorderH={disabled ? '' : '1px solid #35cf44'}
                                    BorderF="1px solid #35cf44"
                                    BoxShadowH={
                                        disabled ? '' : '0px 4px 4px rgba(53, 207, 68, 0.2)'
                                    }
                                    BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                                    Cursor={disabled ? '' : 'pointer'}
                                    BackG={disabled ? '#F3F3F3' : '#ffffff'}
                                >
                                    <input ref={inputRef} {...inputProps} />
                                </SearchInput>
                            </Box>
                        )}
                    />
                </ThemeProvider>
            </LocalizationProvider>
        </ContainerFlex>
    );
};
