import React, {useState} from 'react';

import arrowIcon from '@images/arrowDark.svg';
import {PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import {Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';

const PaymentGatewayOtherEntry = () => {
    const [showOtherForm, setShowOtherForm] = useState(false);

    return (
        <FlexPanel Padding="1rem" FlexDir="column" Align="flex-start" Self="stretch">
            <FlexPanel
                Align="flex-start"
                Gap="1rem"
                FlexDir="column"
                Width="100%"
                Bb="1px solid #E8E9EA"
                Padding="0 0 1rem"
            >
                <FlexPanel
                    Align="center"
                    Gap="1rem"
                    Cursor="pointer"
                    onClick={() => setShowOtherForm(!showOtherForm)}
                >
                    <Image
                        src={arrowIcon}
                        alt="arrow"
                        Width="1.5rem"
                        Height="1.5rem"
                        Transition="transform 0.3s ease"
                        Transform={showOtherForm ? 'rotate(90deg)' : 'rotate(180deg)'}
                    />
                    <Text
                        Color="#2A2C2F"
                        FontSize="1rem"
                        FontWeight="700"
                        LetterSpacing="0.019rem"
                        Cursor="inherit"
                    >
                        {PAYMENT_GATEWAY.OTHER_PAYMENT_METHODS}
                    </Text>
                </FlexPanel>
            </FlexPanel>
        </FlexPanel>
    );
};
export default PaymentGatewayOtherEntry;
