import styled from '@emotion/styled';

export const Icon = styled.img<{color: string}>`
    font-size: 0.875rem;
    color: ${(props) => props.color};
    width: 16px;
    height: 16px;
`;
export const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #e8e9ea;
`;
