import {
    GET_ADMIN_CASHFLOW,
    GET_ADMIN_CASHFLOW_SUCCESS,
    GET_ADMIN_CASHFLOW_ERROR,
} from '@components/AdminCashflow/constants';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    cashflowData: null,
    pageNumber: 0,
    totalPages: 0,
    totalItems: 0,
};

export const getAdminCashflowReducer = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_ADMIN_CASHFLOW:
            return {
                ...state,
                loading: true,
            };
        case GET_ADMIN_CASHFLOW_SUCCESS:
            return {
                ...state,
                cashflowData: action.payload.data.data,
                pageNumber: action.payload.data.pageNumber,
                totalPages: action.payload.data.totalPages,
                totalItems: action.payload.data.totalItems,
                loading: false,
            };
        case GET_ADMIN_CASHFLOW_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
