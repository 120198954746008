export const NEW_LOAN_PRODUCT = {
    TITLE: 'Nuevo producto de préstamo',
    ROOT: {
        LEVEL_1: 'Préstamos personales',
        LEVEL_2: 'Productos de préstamo',
        LEVEL_3: 'Nuevo producto',
    },
    URL: {
        LEVEL_1: '#/',
        LEVEL_2: '#/LoansProducts',
        LEVEL_3: '#/NewLoanProduct',
    },
    BTN_CANCEL: 'Cancelar',
    BTN_CREATE_PRODUCT: 'Crear producto',
};

export const LOAN_DETAILS = {
    TITLE: 'Detalles del préstamo',
    DESCRIPTION: 'Añade y selecciona los detalles del préstamo que quieres crear.',
    FIELDS: {
        PRODUCT_NAME: 'Nombre del producto',
        DESCRIPTION: 'Descripción',
        OPTIONAL: '(Opcional)',
        MIN_AMOUNT: 'Cantidad mínima',
        MAX_AMOUNT: 'Cantidad máxima',
        LOAN_VIGENCY: 'Vigencia del préstamo',
        INTEREST: 'Tasa de interés',
        TERMS: 'Duración del préstamo',
        MAX_TERM_BTN: 'Duración máxima',
        MAX_TERM: 'Duración máxima del préstamo',
        APPLICATION_CHANNEL: 'Canal de solicitud',
        NO_LOANS_LIMIT_BTN: 'N.º límite de préstamos',
        NO_LOANS_LIMIT: 'N.º límite de préstamos',
    },
    TOOLTIPS: {
        MIN_PERIOD:
            'La duración del préstamo es el tiempo esperado de recuperación del monto prestado. Si no se selecciona duración máxima la duración que selecciones será la min y la max.',
        MAX_PERIOD:
            'La duración máxima del préstamo es el tiempo máximo esperado de recuperación del monto prestado. ',
        COUNT_LIMIT_LOANS:
            'Número de préstamo de este tipo que pueden estar activos al mismo tiempo',
    },
    DATE_PICKER: {
        BTN_CONTINUE: 'Continuar',
        BTN_CANCEL: 'Cancelar',
        CLEAN_SELECTION: 'Limpiar selección',
        RADIO_INIT_DATE: 'Fecha de inicio',
        RADIO_RANGE_DATES: 'Rango de fechas',
    },
};

export const PAYMENT_FREQUENCY = {
    TITLE: 'Frecuencia de pago',
    DESCRIPTION: 'Seleccione las opciones disponibles de frecuencia de pago para este préstamo.',
    ALERT_DESCRIPTION:
        'Añade una duración en la sección de arriba para ver el calculo del N.º de pagos en la siguiente tabla.',
    TOOLTIPS: {
        MIN_NUM_PAYMENTS:
            'Número de pagos mínimos que realizará un cliente al seleccionar una de estas opciones de frecuencia  y solicite el monto mínimo permitido.',
        MAX_NUM_PAYMENTS:
            'Número de pagos máximos que realizará un cliente al seleccionar una de estas opciones de frecuencia  y solicite el monto máximo permitido.',
    },
    TABLE_HEADERS: {
        FREQUENCY: 'Frecuencia',
        MIN_PAYMENTS: 'N.º de pagos mínimo',
        MAX_PAYMENTS: 'N.º de pagos máximo',
    },
    CHANGE_TERM_TIME: 'Cambia la duración del préstamo',
};

export const frequencyTableHeaders = [
    {
        id: 1,
        title: PAYMENT_FREQUENCY.TABLE_HEADERS.FREQUENCY,
        tooltipDescription: '',
        isShowingToolTip: false,
    },
    {
        id: 2,
        title: PAYMENT_FREQUENCY.TABLE_HEADERS.MIN_PAYMENTS,
        tooltipDescription: PAYMENT_FREQUENCY.TOOLTIPS.MIN_NUM_PAYMENTS,
        isShowingToolTip: false,
    },
    {
        id: 3,
        title: PAYMENT_FREQUENCY.TABLE_HEADERS.MAX_PAYMENTS,
        tooltipDescription: PAYMENT_FREQUENCY.TOOLTIPS.MAX_NUM_PAYMENTS,
        isShowingToolTip: false,
    },
];

export const VALIDATIONS_MSG = {
    MANDATORY_FIELD: 'Campo obligatorio',
    NO_SPACES: 'El campo no puede contener solo espacios',
    PRODUCT_DETAILS: {
        TERM_NAME: {
            MAX_LENGTH: 'El campo no puede exceder los 100 caracteres',
            INVALID_CHARACTERS:
                'El campo contiene caracteres no permitidos. Ingresa un nombre válido',
        },
        DESCRIPTION: {
            MAX_LENGTH: 'El campo no puede exceder los 500 caracteres',
            INVALID_CHARACTERS: 'El campo contiene caracteres no permitidos.',
        },
        MIN_AMOUNT: {
            POSITIVE_NUMBER: 'La cantidad debe de ser mayor a $0',
            LESS_THAN_MAX: 'La cantidad mínima debe de ser menor a la máxima',
        },
        MAX_AMOUNT: {
            POSITIVE_NUMBER: 'La cantidad debe de ser mayor a $0',
            MORE_THAN_MIN: 'La cantidad máxima debe de ser mayor a la mínima',
        },
        PERIOD_MIN: {
            LESS_THAN_MAX_PERIOD: 'La duración debe ser menor a la duración máxima del préstamo',
        },
        PERIOD_MAX: {
            MORE_THAN_MIN_PERIOD: 'La duración máxima debe ser mayor a la duración del préstamo',
        },
        REQUEST_CHANNEL: {
            AT_LEAST_ONE: 'Debes seleccionar al menos una opción',
        },
        COUNT_LIMIT_LOANS: {
            POSITIVE_NUMBER: 'La cantidad debe de ser un número positivo',
            INTEGER: 'Introduce un número entero',
        },
        DUE_DATE_INIT: {
            FUTURE_DATE: 'La vigencia debe de ser una fecha futura',
            VALID_DATE: 'Ingrese una fecha inicial válida',
        },
        DUE_DATE_END: {
            MORE_THAN_INIT_DATE: 'La fecha final debe de ser mayor a la fecha inicial',
            VALID_DATE: 'Ingrese una fecha final válida',
        },
    },
    FREQUENCY_TERMS: {
        FREQUENCY_TERM: {
            AT_LEAST_ONE: 'Selecciona mínimo una opción.',
        },
    },
    PAYMENT_METHODS: {
        AT_LEAST_ONE: 'Selecciona mínimo una opción.',
    },
    CHARGES_AND_PENALTIES: {
        CHARGE_NAME: {
            MAX_LENGTH: 'El campo no puede exceder los 100 caracteres',
            INVALID_CHARACTERS:
                'El campo contiene caracteres no permitidos. Ingresa un nombre válido',
        },
        EVENT_TYPE: {
            VALID_SELECTION: 'Selecciona una opción correspondiente',
        },
    },
};

export const PAYMENT_METHODS = {
    TITLE: 'Métodos de pago',
    DESCRIPTION: 'Métodos de pago aceptados para el pago del préstamo.',
    TOOLTIP_DESCRIPTION:
        'La Red Ataskate son todos aquellos negocios que tienen contratado el servicio Ataskate y que pueden recibir cobros de otros negocios que formen parte de Ataskate por una comisión (5%).',
};

export const CHARGES_AND_PENALTIES = {
    TITLE: 'Cargos y penalidad',
    DESCRIPTION:
        'Selecciona o crea los cargos y penalidades que quieras que tenga tu producto de préstamo.',
    OPTIONAL: '(Opcional)',
    BTN_CREATE_CHARGE: 'Crear cargo/penalidad',
    FIELDS_TITLES: {
        CHARGE_TYPE: 'Tipo de cobro',
        CHARGE_NAME: 'Nombre',
        AMOUNT_PERCENTAGE: 'Monto/Porcentaje',
        CONDITION: 'Condición',
        EVENT_TYPE: 'Tipo de evento',
    },
    BTN_APPLY: 'Aplicar',
    BTN_EDIT: 'Editar',
    BTN_DELETE: 'Eliminar',
};

export const tableHeaders = [
    {title: 'Tipo'},
    {title: 'Nombre'},
    {title: 'Monto/Porcentaje'},
    {title: 'Condición'},
    {title: 'Tipo de evento'},
];

export const typeChargeOptions = [
    {value: 0, label: 'Penalidad'},
    {value: 1, label: 'Cargo'},
];

export const conditionSelectOptions = [
    {value: 0, label: 'Cobrar un valor o porcentaje'},
    {value: 1, label: 'Cobrar cada vez que pase el evento'},
];

export const amountPercentageSelectOptions = [
    {value: 0, label: 'Monto'},
    {value: 1, label: 'Porcentaje'},
];

export const eventTypeOptionsFirstCondition = [
    {value: 0, label: 'Al inicio del crédito.'},
    {value: 1, label: 'Cada vez que se realice un pago.'},
    {value: 2, label: 'En cada frecuencia de pago'},
    {value: 3, label: 'Al finalizar el crédito.'},
];

export const eventTypeOptionsSecondCondition = [
    {value: 0, label: 'Abono del crédito.'},
    {value: 1, label: 'Liquidación anticipada.'},
];
export const DOCUMENTCLIENTS = {
    TITLE: 'Cliente y documentos',
    SELECTTYPE: 'Seleccione el tipo de cliente al que esta destinado este producto',
    GENER: 'Sexo',
    AGE: 'Edad',
    SCORE: 'Score crediticio',
    OPTIONAL: '(Opcional)',
    CHECKCREDITS: 'Revisión de Buró de Crédito',
    BETWEEN: 'Entre',
    AND: 'y',
    YEARS: 'años',
};
export const COLORMAP = {
    LOW: '#A82424',
    REGULAR: '#CC9200',
    GOOD: '#008FCC',
    EXCELLENT: '#309C60',
    DEFAULT: '#0D166B',
};
export const ROWLIST = [
    {
        id: 0,
        description: 'Calificación de cliente nuevo',
        name: 'Clientes nuevos (Primera solicitud)',
    },
    {id: 1, description: '300 - 549', name: 'Bajo'},
    {id: 2, description: '550 - 632', name: 'Regular'},
    {id: 3, description: '633 - 670', name: 'Bueno'},
    {id: 4, description: '671 - 850', name: 'Excelente'},
];

export const TYPEDOCUMENTS = {
    ANOTHERDOC: 'Otro documento',
    REQUIRED: 'Requisisto',
    TIME: 'Antigüedad laboral',
    OPTIONAL: '(Opcional)',
    DOCUMENT: 'Documento',
    NAMEDOC: 'Nombre del documento',
    INFO: 'Información General',
    APPLY: '  Aplicar',
    JOB_DOC: 'Comprobante de trabajo',
};

export const DISBURSEMENT = {
    TITLE: 'Desembolso',
    SUB: 'Seleccione los métodos disponibles para el desembolso del dinero.',
};
export const TypeDisbursement = [
    {
        id: 1,
        title: 'Transferencia bancaria',
        description: 'A cualquier banco nacional',
    },
    {
        id: 2,
        title: 'En efectivo',
        description: 'Solo disponibles si tienes tiendas físicas registradas.',
    },
    {
        id: 3,
        title: 'Billetera Digital Ataskate',
        description: 'Solo disponibles para los clientes que tienen cuenta en Ataskate.com',
    },
];

export const colorMap: {[key: number]: string} = {
    1: COLORMAP.LOW,
    2: COLORMAP.REGULAR,
    3: COLORMAP.GOOD,
    4: COLORMAP.EXCELLENT,
};
