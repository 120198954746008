import React, {useEffect} from 'react';
import {ClickAwayListener} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@/components/Quoter/stylesConstants';
import {LEVELS} from '@Quoter/constants';
import {
    isForFilters,
    saveSearchIds,
    saveSearchValuation,
} from '@Quoter/Redux/Actions/saveEndeavors';
import {
    Article,
    Brand,
    Family,
    Group,
    Subfamily,
    articleDinamics,
    articleload,
} from '@Quoter/Redux/Actions/PropertiesArticle';
import {MessageError} from '@Quoter/Search/messageError';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IResultQuoterSearch, ISearchItems} from '@Quoter/inferfaces';
import {MessageInactiveArticle} from '@Quoter/Search/messageInactiveArticle';
import {cancelSearchEditInfo} from '@components/ShoppingCart/Redux/Actions/getCartContractsActions';

export const ListQuoter = (props: ISearchItems) => {
    const dispatch: AppDispatch = useDispatch();
    const {reset, viewList, validateArticle, setViewList, branchId, setView} = props;

    const {search} = useSelector((state: RootState) => state.SearchQuoter);
    const {productSearch, filters} = useSelector((state: RootState) => state.saveEndeavor);
    const {group, family, subfamily, brand, article, loadingArticle} = useSelector(
        (state: RootState) => state.propertiesArticle
    );
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const editState = useSelector((state: RootState) => state.getCartContracts.info);

    const searchEditButton: IResultQuoterSearch | undefined = search?.[0];

    const handleSearch = async (item: IResultQuoterSearch) => {
        await setViewList(false);
        await dispatch(articleload(true));
        await levels(item.levelsIds);
        reset({product: item.articleName});
        dispatch(isForFilters(false));
        dispatch(saveSearchValuation(item));
    };

    const levels = async (item: number[]) => {
        await dispatch(Family(token, {branchId: branchId, groupId: item[LEVELS.GROUP]}));
        await dispatch(Subfamily(token, {branchId: branchId, familyId: item[LEVELS.FAMILY]}));
        await dispatch(Brand(token, {branchId: branchId, subFamilyId: item[LEVELS.SUBFAMILY]}));
        await dispatch(Article(token, {branchId: branchId, brandId: item[LEVELS.BRAND]}));
        dispatch(articleDinamics(token, {branchId: branchId, articleId: item[LEVELS.ARTICLE]}));
    };

    const searchEditInfo = async () => {
        if (editState && searchEditButton) {
            await handleSearch(searchEditButton);
            if (productSearch.levelsIds.length > 0 && !filters && article.articleList)
                saveCurrentSearch();
        }
        dispatch(cancelSearchEditInfo());
    };

    useEffect(() => {
        searchEditInfo();
    }, [search, editState]);

    const saveCurrentSearch = () => {
        dispatch(
            saveSearchIds({
                Group: {
                    value: productSearch.levelsIds[LEVELS.GROUP],
                    label: group.groupList.filter(
                        (items) => items.groupId === productSearch.levelsIds[LEVELS.GROUP]
                    )[LEVELS.GROUP]?.nameGroup,
                },
                Family: {
                    value: productSearch.levelsIds[LEVELS.FAMILY],
                    label:
                        family.familyData.length > 0
                            ? family.familyData.filter(
                                  (items) =>
                                      items.familyId === productSearch.levelsIds[LEVELS.FAMILY]
                              )[LEVELS.GROUP]?.nameFamily
                            : '',
                },
                Subfamily: {
                    value: productSearch.levelsIds[LEVELS.SUBFAMILY],
                    label:
                        subfamily.subfamilyList.length > 0
                            ? subfamily.subfamilyList.filter(
                                  (items) =>
                                      items.subfamilyId ===
                                      productSearch.levelsIds[LEVELS.SUBFAMILY]
                              )[LEVELS.GROUP]?.nameSubfamily
                            : '',
                },
                Brand: {
                    value: productSearch.levelsIds[LEVELS.BRAND],
                    label:
                        brand.brandList.length > 0
                            ? brand.brandList.filter(
                                  (items) => items.brandId === productSearch.levelsIds[LEVELS.BRAND]
                              )[LEVELS.GROUP]?.nameBrand
                            : '',
                },
                Article: {
                    value: productSearch.levelsIds[LEVELS.ARTICLE],
                    label:
                        article.articleList.length > 0
                            ? article.articleList.filter(
                                  (items) =>
                                      items.articleId === productSearch.levelsIds[LEVELS.ARTICLE]
                              )[LEVELS.GROUP].nameArticle
                            : '',
                },
            })
        );
    };

    useEffect(() => {
        reset({product: productSearch.articleName});
        dispatch(Group(token, branchId));
        levels(productSearch.levelsIds);
    }, []);

    useEffect(() => {
        if (productSearch.levelsIds.length > 0 && !filters && article.articleList)
            saveCurrentSearch();
    }, [loadingArticle, article?.articleList]);

    return (
        <>
            {viewList && (
                <ClickAwayListener onClickAway={() => setViewList(false)}>
                    <ContainerFlex
                        {...style.contentStyles}
                        Border={validateArticle ? '1px solid #F3F3F3' : ''}
                    >
                        {search &&
                            validateArticle &&
                            (search.length > 0 ? (
                                search
                                    .slice(0, 5)
                                    .map((item: IResultQuoterSearch, index: number) =>
                                        item.statusArticle ? (
                                            <ContainerFlex
                                                key={index}
                                                {...style.contentList}
                                                onClick={() => handleSearch(item)}
                                                HBackground="#f0f0ff"
                                                HBorderLeft="2px solid #5a5aff"
                                            >
                                                <Text {...style.wordSearch}>
                                                    {item.articleName}
                                                </Text>
                                            </ContainerFlex>
                                        ) : (
                                            index === 0 && <MessageInactiveArticle key={index} />
                                        )
                                    )
                            ) : (
                                <MessageError setView={setView} />
                            ))}
                    </ContainerFlex>
                </ClickAwayListener>
            )}
        </>
    );
};
