import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {formatterWithoutDigits} from '@/hooks/currentFormatUtils';
import checkMonthlySummary from '@images/checkMonthlySummary.svg';
import lineMonthlySummary from '@images/lineMonthlySummary.svg';
import {IAddons, saveCompany} from '@components/SignUp/interfaces';
import {COMPLEMENTS} from '@components/SignUp/constants';

export const MonthlySummary = (props: {
    monthlySubscription: IAddons[];
    company: saveCompany | null;
}) => {
    const {monthlySubscription, company} = props;

    const sumAddons = () => {
        const subtotals = monthlySubscription.reduce(
            (total: number, item) => total + item.price,
            0
        );
        return subtotals;
    };
    const discountAddons = () => {
        let discount = 0;
        if (company && company.BusinessItem.length >= 2) discount = sumAddons() * 0.2;
        return discount;
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Width="100%"
            backG="#FAFAFF"
            Justify="center"
            Gap="1rem"
            Padding="1rem"
            Radius="1rem"
            Align="flex-start"
        >
            <Text Color="#2A2C2F" FontSize="1.25rem" FontWeight="500">
                {COMPLEMENTS.MONTHLYSUBSCRIPTION}
            </Text>
            <ContainerFlex Height="auto" FlexDir="column">
                {monthlySubscription.map((element, index: number) => (
                    <ContainerFlex Justify="space-between" key={index} Padding="0.25rem 0">
                        <ContainerFlex Width="70%" Justify="start">
                            <Image src={checkMonthlySummary} />
                            <Text PaddingL="0.5rem" Color="#2A2C2F" FontSize="0.875rem">
                                {element.planName}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="30%"
                            Color="#54575C"
                            FontSize="0.875rem"
                            Justify="end"
                        >
                            {formatterWithoutDigits.format(element.price)}
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            <Image src={lineMonthlySummary} />
            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="500">
                {COMPLEMENTS.MONTHLYSUBSCRIPTION}
            </Text>
            <ContainerCellGrid FlexDir="column" Gap="0.5rem" Height="auto">
                <ContainerCellGrid Justify="space-between">
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {COMPLEMENTS.SUBTOTAL}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {formatterWithoutDigits.format(sumAddons())}
                    </Text>
                </ContainerCellGrid>
                {discountAddons() > 0 && (
                    <ContainerCellGrid Justify="space-between">
                        <Text Color="#A1A5AA" FontSize="0.875rem">
                            {COMPLEMENTS.DISCOUNT}
                        </Text>
                        <Text Color="#A1A5AA" FontSize="0.875rem">
                            - {formatterWithoutDigits.format(discountAddons())}
                        </Text>
                    </ContainerCellGrid>
                )}
                <ContainerCellGrid Justify="space-between">
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                        {COMPLEMENTS.MONTHLYTOTAL}
                    </Text>
                    <ContainerFlex Width="auto" FlexDir="column" Align="end">
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {formatterWithoutDigits.format(sumAddons() - discountAddons())}
                        </Text>
                        <Text Color="#71767D" FontSize="0.75rem" FontWeight="400">
                            Por usuario
                        </Text>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
