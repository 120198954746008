import React, {ReactNode, useState} from 'react';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import imgAlert from '@images/iconAlertDelete.svg';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import DeleteModalCompany from '@ComponetsModal/DeleteModalCompany';
import ContactAdviser from '@ComponetsModal/ContactAdviser';
import {InfoContact} from '@ComponetsModal/InfoContact';
import {companyDataModal, HashMap} from '@MyCompany/Interface';

export const ModalInfoContac = ({setModalState, modalState}: companyDataModal) => {
    const [flowModalChange, setFlowModalChange] = useState(0);

    const components: HashMap = {
        0: (
            <DeleteModalCompany
                setModalState={setModalState}
                setFlowModalChange={setFlowModalChange}
            />
        ),
        1: <ContactAdviser setModalState={setModalState} setFlowModalChange={setFlowModalChange} />,
        2: <InfoContact setFlowModalChange={setFlowModalChange} setModalState={setModalState} />,
        3: () => {
            window.location.href = 'MyCompany/AplicationContact';
        },
    };

    return (
        <Modal
            modalState={modalState}
            changeModalState={() => {}}
            titleModalState={true}
            Width="35.75rem"
            Height="auto"
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            BorderRadius="4px"
            background="none"
            FlexDirMod="column"
        >
            <ImgLocation
                Width="12.875rem"
                Height="8.35rem"
                Margin="24px"
                src={imgAlert}
                alt="alert"
            />
            {components[flowModalChange] as ReactNode}
        </Modal>
    );
};
