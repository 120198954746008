import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {InitialInformation} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/InitialInformation';
import {Interest} from '@CreateDeadline/DeadlineSteps/Interest';
import {AmountsAndTimes} from '@CreateDeadline/DeadlineSteps/AmountAndTimes/AmountsAndTimes';
import {DeadlinesComponent} from '@CreateDeadline/DeadlineSteps/Deadlines/Deadlines';
import {Discounts} from '@CreateDeadline/DeadlineSteps/Discounts/Discounts';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ChangeOfProducts} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/ChangeOfProducts/ChangeOfProducts';
import {Extensions} from '@CreateDeadline/DeadlineSteps/Extensions';
import {TypeofClient} from '@CreateDeadline/DeadlineSteps/TypeofClient';
import {FinalDeadline} from '@CreateDeadline/FinalDeadline';
import {PostDeadlines} from '@CreateDeadline/redux/actions/GetAllPromotionServices';
import {deadlinesID, dealinesCreate} from '@CreateDeadline/interfaces';
import * as style from '@Articles/style';
import {setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import ButtonGeneral from '@/components/General/Atoms/Button';

export const CreateDeadline = (props: {Branch: number[]}) => {
    const {Branch} = props;
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const deadlinesGeneral: dealinesCreate = useSelector((state: RootState) => {
        return state.deadlinesGeneral;
    });
    const deadlineById: deadlinesID = useSelector((state: RootState) => {
        return state.getDeadlinedAll;
    });
    const dispatch: AppDispatch = useDispatch();
    const disabledScreen = () => {
        if (
            deadlinesGeneral.initialInformation.nameTerm &&
            deadlinesGeneral.interest.cat &&
            deadlinesGeneral.amountTimes.maximumLoanAmount &&
            deadlinesGeneral.gracePhase.length > 0 &&
            deadlinesGeneral.starts &&
            deadlinesGeneral.step < 10
        )
            return true;
    };

    const buttonDoubleCopy = (type: string) => {
        if (type === 'title') {
            if (deadlineById.copy) return 'Guardar plazo';
            if (deadlineById.edit) return 'Regresar';
            return 'Finalizar';
        }
        if (type === 'function') {
            if (!deadlineById.edit) {
                onSubmit();
                dispatch(setStep(10));
            }
            if (deadlineById.edit) window.location.href = '#/NewDeadLine';
        }
    };
    const onSubmit = () => {
        const json = {
            companyId: deadlinesGeneral.initialInformation.companyId,
            branches: Branch,
            nameTerm: deadlinesGeneral.initialInformation.nameTerm,
            startDate: deadlinesGeneral.initialInformation.startDate,
            endDate: deadlinesGeneral.initialInformation.endDate,
            catalogLevelType: deadlinesGeneral.initialInformation.catalogLevelType,
            catalogLevel: deadlinesGeneral.initialInformation.catalogLevel,
            storageRate: deadlinesGeneral.interest.storageRate,
            insuranceRate: deadlinesGeneral.interest.insuranceRate,
            operationRate: deadlinesGeneral.interest.operationRate,
            cat: deadlinesGeneral.interest.cat,
            commission: deadlinesGeneral.interest.commission,
            minimumLoanAmount: deadlinesGeneral.amountTimes.minimumLoanAmount,
            maximumLoanAmount: deadlinesGeneral.amountTimes.maximumLoanAmount,
            quantityGarments: deadlinesGeneral.amountTimes.quantityGarments,
            marketingPercentage: deadlinesGeneral.amountTimes.marketingPercentage,
            loanPercentage: deadlinesGeneral.amountTimes.loanPercentage,
            negotiationPercentage: deadlinesGeneral.amountTimes.negotiationPercentage,
            dynamicAmount: deadlinesGeneral.amountTimes.dynamicAmount,
            rangeInterest: deadlinesGeneral.rangeInterest,
            gracePhase: deadlinesGeneral.gracePhase,
            haveDiscount: deadlinesGeneral.haveDiscount,
            promotionDiscount: deadlinesGeneral.promotionDiscount,
            possibleProductChanges: deadlinesGeneral.possibleProductChanges,
            haveExtension: deadlinesGeneral.promotionExtensions.haveExtension,
            promotionExtensions: deadlinesGeneral.promotionExtensions.promotionExtensions,
            quantityStars: deadlinesGeneral.starts,
        };
        dispatch(PostDeadlines(json, token));
    };

    return Branch === undefined || Branch.length === 0 ? (
        <ContainerFlex {...style.Card}>
            <Text Padding="2rem 1.5rem">Seleccione una Sucursal.</Text>
        </ContainerFlex>
    ) : (
        <>
            <ContainerFlex
                FlexDir="column"
                Margin="1.813rem 0 1rem 0"
                MinH="90vh"
                MaxH="90vh"
                Justify="start"
                Align="start"
                OverFlow="auto"
                DBackG="var(--gray-5)"
            >
                <InitialInformation
                    deadlinesGeneral={deadlinesGeneral}
                    deadlineById={deadlineById}
                    Branch={Branch}
                />

                {deadlinesGeneral.step !== 10 && (
                    <>
                        <Interest deadlinesGeneral={deadlinesGeneral} deadlineById={deadlineById} />
                        <AmountsAndTimes
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                        />
                        <DeadlinesComponent
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                            Branch={Branch}
                        />
                        <Discounts
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                        />
                        <ChangeOfProducts
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                        />
                        <Extensions
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                        />
                        <TypeofClient
                            deadlinesGeneral={deadlinesGeneral}
                            deadlineById={deadlineById}
                        />
                    </>
                )}

                {!(deadlinesGeneral.step !== 10) && (
                    <FinalDeadline
                        deadlinesGeneral={deadlinesGeneral}
                        deadlineById={deadlineById}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex Margin="1.813rem 0 1rem 0">
                {disabledScreen() && (
                    <ButtonGeneral
                        FontSize="0.875rem"
                        margin="0 0 1.5rem 0"
                        hColor="#35cf44"
                        text={buttonDoubleCopy('title')}
                        clic={() => buttonDoubleCopy('function')}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
