import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableAuditAssignment() {
    return (
        <TableHead tl={true} tr={true}>
            <tr>
                <th>Sucursal</th>
                <th>Región</th>
                <th>No. Prendas</th>
                <th>Costo Paquete</th>
            </tr>
        </TableHead>
    );
}
