import {IHeader} from '@/components/InventoryManagement/CatalogManagement/Operational/interface';

export const CATALOG = {
    TITLE: 'Gestión de catalogo',
    SEARCH: 'Indica articulo, marca, modelo y característica principales.',
};

export const levelColors = ['#FFF', '#FAFAFA', '#FAFAFA', '#F4F5F5', '#E8E9EA'];

export const HeaderTitleCatalog: IHeader[] = [
    {
        name: 'Nivel',
    },
    {
        name: 'No. de artículos',
    },
];
export const PAGE_QUANTITY = 20;
export const INITIAL_PAGE_ITEM = 1;
export const INITIAL_SEARCH_ITEM = 2;
export const TOTAL_PAGE_ITEM = 10;
export const REQUEST_CATALOG = 'Solicitar nivel de artículo';
