export type AmountValueType = 'percentage' | 'amount' | 'none';
type PlaceHolderSelectHash = Record<AmountValueType, string>;
export const amountValueTypes: {[key: string]: AmountValueType} = {
    Percentage: 'percentage',
    Amount: 'amount',
    None: 'none',
};
const placeHolderSelectHash: PlaceHolderSelectHash = {
    percentage: '%',
    amount: '$',
    none: '',
};
export const getPlaceHolder = (amountType: AmountValueType) =>
    placeHolderSelectHash[amountType] || '';
