import {CREATE_ROLE, UPDATE_ROLE} from '@Roles/CreateRoles/Redux/Types';
import {ICreateRole, ICreateRoleRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: ICreateRole = {
    createRole: null,
    updateRole: null,
    errorCreate: false,
    errorUpdate: false,
    loading: false,
    loadingUpdate: false,
};

const CreateUpdateRole = (state = initialState, action: ICreateRoleRedux): ICreateRole => {
    switch (action.type) {
        case CREATE_ROLE.CREATE_START:
            return {...state, loading: true, errorCreate: false};
        case CREATE_ROLE.CREATE_SUCCESS:
            return {
                ...state,
                createRole: action.createRole,
                loading: false,
                errorCreate: false,
            };
        case CREATE_ROLE.CREATE_ERROR:
            return {...state, loading: false, errorCreate: false};
        case UPDATE_ROLE.UPDATE_START:
            return {...state, loadingUpdate: false, errorUpdate: false};
        case UPDATE_ROLE.UPDATE_SUCCESS:
            return {
                ...state,
                updateRole: action.createRole,
                loadingUpdate: false,
                errorUpdate: false,
            };
        case UPDATE_ROLE.UPDATE_ERROR:
            return {...state, loadingUpdate: false, errorUpdate: false};
        default:
            return state;
    }
};
export default CreateUpdateRole;
