import React from 'react';

import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, ContainerImg, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {RadioContainer} from '@components/Home/style';
import {ICard} from '@components/Home/Admin/interface';
import {IconSelector} from '@/components/Home/Admin/IconSelector';
import ArrowSmall from '@images/ArrowSmall.svg';
export const ComplementCards = ({name, description, cost, id}: ICard) => {
    return (
        <ContainerFlex
            backG="#FFFFFF"
            Padding="16px"
            Height="auto"
            FlexDir="column"
            Align="start"
            Radius="16px"
            Width="32%"
            Gap="16px"
        >
            <ContainerFlex Justify="start" Align="start" Width="100%" Gap="8px">
                <RadioContainer>
                    <IconSelector type={name} />
                </RadioContainer>

                <ContainerFlex Justify="space-between" Align="start" FlexWrap="wrap">
                    <Text
                        FontSize="1rem"
                        Color="#2A2C2F"
                        FontWeight="500"
                        Align="start"
                        Padding="0 0 8px 0"
                        Width="75%"
                    >
                        {name}
                    </Text>
                    <ContainerImg>
                        <Image src={ArrowSmall} alt="icon-warning" Cursor="pointer" />
                    </ContainerImg>
                    <Text FontSize="0.75rem" Color="#2A2C2F" wSpace="" Display="inline-block">
                        {description}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Justify="start" Align="center">
                <ContainerFlex Justify="start" FlexDir="column" Align="start">
                    <Text FontSize="0.75rem" Color="#2A2C2F">
                        Costo por mes
                    </Text>
                    <Text FontSize="1remrem" Color="#2A2C2F" FontWeight="500">
                        {formatterDolar.format(cost)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="center" FlexDir="column" Align="end" Height="100%">
                    <ButtonGeneral text="Adquirir" FontSize="0.75rem" height="24px" />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
