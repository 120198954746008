import React from 'react';
import {Control, Controller, FieldValues} from 'react-hook-form';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {HOLIDAYS} from '@components/LocationsBranch/constants';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {IHolidaysForm} from '@components/LocationsBranch/interfaces';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {getDayHoursInterval} from '@components/LocationsBranch/utils/GetIntervals';
import {ISelectReact} from '@/components/Quoter/inferfaces';

export const HolidaysForm = ({
    control,
    errors,
    reset,
    watch,
    register,
    openingClose,
    setOpeningClose,
}: IHolidaysForm) => {
    const intervals = getDayHoursInterval(0, 23, 30);

    const intervalsOptions: ISelectReact[] = intervals.map((item: {time: string; id: number}) => ({
        value: item.id,
        label: item.time,
    }));

    const resetOpening = () => {
        setOpeningClose(!openingClose);
        reset({...watch(), opening: {}, ending: {}});
    };

    return (
        <>
            <ContainerFlex Height="auto" Align="start" Justify="start" Gap="1rem">
                <ContainerFlex FlexDir="Column" Align="start" Gap="0.25rem">
                    <CalendarDateSelection
                        name="holiday"
                        control={control as unknown as Control<FieldValues>}
                        placeholder=" /  / "
                        format="dd/MM/yyyy"
                        error={errors.holiday?.message ? true : false}
                        limitDate={false}
                    />
                    {errors?.holiday?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors.holiday.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex Gap="0.5rem" Height="40px">
                    <Checkbox {...register('eachYears')} />
                    <Text Color="#2A2C2F">{HOLIDAYS.EACH_YEAR}</Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Gap="1rem" Justify="start" Align="start" FlexDir="Column">
                <Text Color="#2A2C2F" FontWeight="500">
                    {HOLIDAYS.OPENING}
                </Text>
                <GreenSwitch
                    {...register('openingClose')}
                    checked={openingClose}
                    onChange={() => resetOpening()}
                />
                {openingClose && (
                    <ContainerFlex Padding="0.5rem" Gap="1.5rem" Align="start">
                        <ContainerFlex FlexDir="Column" Gap="0.25rem" Height="auto" Align="start">
                            <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                                {HOLIDAYS.OPENING}
                            </Text>
                            <Controller
                                name="opening"
                                control={control}
                                render={({field}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        options={intervalsOptions}
                                        hasError={errors?.opening?.label?.message ? true : false}
                                    />
                                )}
                            />
                            {errors?.opening?.label?.message && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.opening.label.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex FlexDir="Column" Gap="0.25rem" Height="auto" Align="start">
                            <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                                {HOLIDAYS.ENDING}
                            </Text>
                            <Controller
                                name="ending"
                                control={control}
                                render={({field}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        options={intervalsOptions}
                                        hasError={errors?.ending?.label?.message ? true : false}
                                    />
                                )}
                            />
                            {errors?.ending?.label?.message && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.ending.label.message}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </>
    );
};
