export const GET_USERS_VALIDATION_DATA = 'GET_USERS_VALIDATION_DATA';
export const GET_USERS_VALIDATION_SUCCESS = 'GET_USERS_VALIDATION_SUCCESS';
export const PRE_GET_USERS_VALIDATION_SUCCESS = 'PRE_GET_USERS_VALIDATION_SUCCESS';
export const ALREADY_GET_USERS_VALIDATION_SUCCESS = 'ALREADY_GET_USERS_VALIDATION_SUCCESS';

export const GET_USERS_VALIDATION_ERROR = 'GET_USERS_VALIDATION_ERROR';
export const RESET_USER_DATA = 'RESET_USER_DATA';

export const GET_RESET_ADMIN_PASSWORD = 'GET_RESET_ADMIN_PASSWORD';
export const GET_RESET_ADMIN_PASSWORD_SUCCESS = 'GET_RESET_ADMIN_PASSWORD_SUCCESS';
export const GET_RESET_ADMIN_PASSWORD_ERROR = 'GET_RESET_ADMIN_PASSWORD_ERROR';

export const PUT_RESET_ADMIN_PASSWORD = 'PUT_RESET_ADMIN_PASSWORD';
export const PUT_RESET_ADMIN_PASSWORD_SUCCESS = 'PUT_RESET_ADMIN_PASSWORD_SUCCESS';
export const PUT_RESET_ADMIN_PASSWORD_ERROR = 'PUT_RESET_ADMIN_PASSWORD_ERROR';

export const RESEND_EMAIL_VALIDATION_START = 'RESEND_EMAIL_VALIDATION_START';
export const RESEND_EMAIL_VALIDATION_SUCCESS = 'RESEND_EMAIL_VALIDATION_SUCCESS';
export const RESEND_EMAIL_VALIDATION_ERROR = 'RESEND_EMAIL_VALIDATION_ERROR';
export const RESEND_EMAIL_VALIDATION_RESET = 'RESEND_EMAIL_VALIDATION_RESET';

export const VALIDATE_LINK_EXPIRED = 'VALIDATE_LINK_EXPIRED';
export const VALIDATE_LINK_EXPIRED_SUCCESS = 'VALIDATE_LINK_EXPIRED_SUCCESS';
export const VALIDATE_LINK_EXPIRED_ERROR = 'VALIDATE_LINK_EXPIRED_ERROR';

export const UPDATE_LINK_EXPIRED = 'UPDATE_LINK_EXPIRED';
export const UPDATE_LINK_EXPIRED_SUCCESS = 'UPDATE_LINK_EXPIRED_SUCCESS';
export const UPDATE_LINK_EXPIRED_ERROR = 'UPDATE_LINK_EXPIRED_ERROR';

export const CHANGE_SCREEN = 'CHANGE_SCREEN';
export const GET_USER_DATA = 'GET_USER_DATA';
export const RESET_LOGIN_MARKET = 'RESET_LOGIN_MARKET';

export const RESET_LOGIN = 'RESET_LOGIN';
