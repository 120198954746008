import {
    GET_SEARCH_USERS,
    GET_SEARCH_USERS_ERROR,
    GET_SEARCH_USERS_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    searchUsers: [],
};

const GetSearchUsers = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_SEARCH_USERS:
            return {
                ...state,
                loading: true,
            };
        case GET_SEARCH_USERS_SUCCESS:
            return {
                ...state,
                searchUsers: action.payload.data,
                loading: false,
            };
        case GET_SEARCH_USERS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetSearchUsers;
