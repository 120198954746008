import {
    POST_CARD_QUOTER,
    POST_CARD_QUOTERSUCCESS,
    POST_CARD_QUOTER_ERROR,
    POST_CARD_BLOCK,
} from '@TypesLoan/types';
import {URL} from '@config/constants/index';

import {cancelQuoterOperation} from '@Quoter/Redux/Actions/StepsActions';
import axios, {AxiosResponse} from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {paySend} from '@Loan/PaymentQuoter/Interfaces';

export const Pay = () => {
    return {
        type: POST_CARD_QUOTER,
    };
};
export const PaySuccess = (result: AxiosResponse) => {
    return {
        type: POST_CARD_QUOTERSUCCESS,
        payload: result,
    };
};

export const PayBlock = (result: boolean) => {
    return {
        type: POST_CARD_BLOCK,
        payload: result,
    };
};

export const PayError = () => {
    return {
        type: POST_CARD_QUOTER_ERROR,
    };
};

export function PaymentRequest(token: string, data: paySend, typeCar: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(Pay());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PayQuoter}`,
                data,
                {headers}
            );
            await dispatch(PaySuccess(response.data));
            Swal.fire({
                icon: 'success',
                text: `Se realizó la transacción con éxito.`,
                confirmButtonText: 'Continuar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (typeCar === 'Quoter') {
                        window.location.href = '/#/PaymentQuoter/PaymentContract';
                        dispatch(cancelQuoterOperation());
                    } else {
                        window.location.href = '/PaymentSuccess';
                    }
                }
            });
        } catch (error) {
            dispatch(PayError());
            Swal.fire({
                icon: 'error',
                text: `No se pudo realizar la transacción.`,
                confirmButtonText: 'Continuar',
            });
            dispatch(PayBlock(false));
        }
    };
}
