import {GET_ADDRESS, PERSISTENCE_GROUP_BRANCH} from '@components/LocationsBranch/Redux/types';
import {IGetAddress, IGetAddressRedux} from '@components/LocationsBranch/interfaces';

const initialState: IGetAddress = {
    address: null,
    error: false,
};

export const getAddress = (state = initialState, action: IGetAddressRedux): IGetAddress => {
    switch (action.type) {
        case GET_ADDRESS.ADD:
            return {
                ...state,
                address: action.address,
            };
        case PERSISTENCE_GROUP_BRANCH.RESET:
            return {
                ...state,
                address: null,
            };
        case GET_ADDRESS.ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
