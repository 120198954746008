import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_TABLE_AMORTIZATION_START,
    GET_TABLE_AMORTIZATION_SUCCESS,
    GET_TABLE_AMORTIZATION_ERROR,
} from '@components/CreditQuoter/Redux/Types/Types';

const initialState = {
    data: [],
    loading: false,
    error: false,
};

const getTableAmortization = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_TABLE_AMORTIZATION_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_TABLE_AMORTIZATION_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_TABLE_AMORTIZATION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getTableAmortization;
