import React from 'react';
import {TABLE_FILTERS} from '@components/CreditsAndPawns/constants';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {IApplicationsFilterProps} from '@components/CreditsAndPawns/interfaces';
import {setListLoansStatus} from '@components/CreditsAndPawns/Redux/Actions/GetListLoansActions';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {clearFiltersValues} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';

export const ApplicationsFilter = ({
    filterApplications,
    setFilterApplications,
    reset,
}: IApplicationsFilterProps) => {
    const dispatch: AppDispatch = useDispatch();
    const tableFilterTitles = [
        {title: TABLE_FILTERS.APPLICATION, value: 5},
        {title: TABLE_FILTERS.APPROVED, value: 6},
        {title: TABLE_FILTERS.UP_TO_DATE, value: 1},
        {title: TABLE_FILTERS.OVERDUE, value: 2},
        {title: TABLE_FILTERS.LIQUIDATED, value: 3},
        {title: TABLE_FILTERS.REJECTED, value: 4},
    ];

    const getRadius = (index: number, title: string): string => {
        if (filterApplications === title && index === 0) return '8px 0px 0px 8px';
        if (filterApplications === title && index === tableFilterTitles.length - 1)
            return '0px 8px 8px 0px';
        return '';
    };

    return (
        <>
            <ContainerFlex Radius="0.5rem" Border="1px solid #E8E9EA">
                {tableFilterTitles.map((data, index) => {
                    const radius = getRadius(index, data.title);
                    return (
                        <ContainerFlex
                            key={index}
                            Flex="1 0 0"
                            Justify="center"
                            Padding="0.75rem 1rem"
                            Br={index !== tableFilterTitles.length - 1 ? '1px solid #E8E9EA' : ''}
                            Border={filterApplications === data.title ? '1px solid #5A5AFF' : ''}
                            backG={filterApplications === data.title ? '#F0F0FF' : '#fff'}
                            Radius={radius}
                            Cursor="pointer"
                            onClick={() => {
                                setFilterApplications(data.title);
                                dispatch(setListLoansStatus(data.value));
                                dispatch(clearFiltersValues());
                                reset();
                            }}
                        >
                            <Text
                                FontFamily="Nunito"
                                Color={filterApplications === data.title ? '#5A5AFF' : '#1D1E20'}
                                FontWeight={filterApplications === data.title ? '700' : '400'}
                                Cursor="pointer"
                            >
                                {data.title}
                            </Text>
                        </ContainerFlex>
                    );
                })}
            </ContainerFlex>
        </>
    );
};
