import {SEARCH} from '@Quoter/Redux/Types/Types';
import {ISearch, ISearchData} from '@Quoter/inferfaces';

const initialState: ISearch = {
    search: null,
    error: false,
    loading: false,
};

const SearchQuoter = (state = initialState, action: ISearchData): ISearch => {
    switch (action.type) {
        case SEARCH.INITIAL:
            return {...state, loading: true, error: false};
        case SEARCH.SUCCESS:
            return {...state, loading: false, error: false, search: action.search};
        case SEARCH.ERROR:
            return {...state, error: true, loading: false};
        case SEARCH.RESET:
            return initialState;
        default:
            return state;
    }
};
export default SearchQuoter;
