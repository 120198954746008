import {
    GET_CUSTOMER_INFORMATION_ERROR,
    GET_CUSTOMER_INFORMATION_START,
    GET_CUSTOMER_INFORMATION_SUCCESS,
    OPEN_CUSTOMER_INFORMATION_MODAL,
    CLOSE_CUSTOMER_INFORMATION_MODAL,
    CustomerInformationAction,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {ICustomerInformationReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: ICustomerInformationReducer = {
    data: null,
    loading: false,
    error: false,
    personalInformationId: 0,
    openModal: false,
};

const getCustomerInfo = (
    state: ICustomerInformationReducer = initialState,
    action: CustomerInformationAction
): ICustomerInformationReducer => {
    switch (action.type) {
        case GET_CUSTOMER_INFORMATION_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CUSTOMER_INFORMATION_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CUSTOMER_INFORMATION_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        case OPEN_CUSTOMER_INFORMATION_MODAL:
            return {
                ...initialState,
                openModal: true,
                personalInformationId: action.payload,
            };
        case CLOSE_CUSTOMER_INFORMATION_MODAL:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default getCustomerInfo;
