import {GET_BALANCES, GET_BALANCES_SUCCESS, GET_BALANCES_ERROR} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    balanced: [],
    loading: false,
    error: false,
};

const getBalanceds = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_BALANCES:
            return {
                ...state,
                loading: true,
            };
        case GET_BALANCES_SUCCESS:
            return {
                ...state,
                balanced: action.payload.data.data,
                loading: true,
            };
        case GET_BALANCES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getBalanceds;
