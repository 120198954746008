export const contentSetting = {
    Align: 'start',
    Padding: '1rem',
    backG: '#FFFFFF',
    Radius: '1rem',
    FlexDir: 'column',
    Gap: '1rem',
};

export const titleSetting = {
    Color: '#2A2C2F',
    FontSize: '1.25rem',
    FontWeight: '500',
};

export const iconSetting = {
    className: 'material-icons',
    FontSize: '1.5rem',
    Color: '#ACACFF',
    Cursor: 'pointer',
    MarginLeft: '1rem',
};

export const switchSettings = {
    Justify: 'start',
    Height: 'auto',
    Gap: '0.5rem',
};
