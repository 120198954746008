import React from 'react';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TitleInterface} from '@CashFlow/interfaces';
import {Circle, EditIcon, IconContainer, TitleContainer} from '@CashFlow/styles';
import {DoneIcon} from '@LoanPay/DetailsProduct/StylesDetails';

const Title = ({
    numberTitle,
    nameTitle,
    showEditIcon,
    onClick = () => null,
    showNumber,
    enabledCircle,
    alert,
}: TitleInterface) => {
    return (
        <TitleContainer showBorderButtton={showNumber}>
            <ContainerFlex Justify="flex-start">
                {showNumber ? (
                    <Circle
                        fontColor={enabledCircle ? '#FFFFFF' : '#CBCED1'}
                        backgroundColor={enabledCircle ? '#7AC143' : '#FFFFFF'}
                        borderColor={enabledCircle ? '#7AC143' : '#CBCED1'}
                    >
                        {numberTitle}
                    </Circle>
                ) : (
                    <Circle backgroundColor="white" borderColor="var(--green)">
                        <DoneIcon />
                    </Circle>
                )}
                <Text Margin="0 1rem" FontWeight="500" FontSize="1.125rem" Color="#414042">
                    {nameTitle}
                </Text>
            </ContainerFlex>
            <EditIcon isDisplay={showEditIcon} onClick={onClick}>
                <IconContainer className="material-icons">Edit</IconContainer>
                Editar
            </EditIcon>
        </TitleContainer>
    );
};

export default Title;
