import {
    ADD_COOWNER_BENEFICIARY,
    ADD_COOWNER_BENEFICIARY_SUCCESS,
    ADD_COOWNER_BENEFICIARY_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

const addCoownerBeneficiary = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case ADD_COOWNER_BENEFICIARY:
            return {
                ...state,
                loading: true,
            };
        case ADD_COOWNER_BENEFICIARY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case ADD_COOWNER_BENEFICIARY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default addCoownerBeneficiary;
