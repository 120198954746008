import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {DateTime} from 'luxon';

import {getHomeOrders} from '@Shopify/Ecommerce/redux/actions/HomeClientActions';
import {
    ImgOrder,
    TextEllipsis,
    Text,
    ContainerFlex,
    ContainerPedidos,
    SubContainerFavs,
    ImgFlagOne,
    ImgProduct,
    IconInactive,
} from '@Shopify/Ecommerce/styles';
import {TableRowHeadContract} from '@Shopify/Endeavors/styles';
import {SubContainer} from '@Shopify/Ecommerce/Subcontainer';
import {homeClient} from '@Shopify/Endeavors/redux/interfaces';

export const Orders = () => {
    const homeclient: homeClient = useSelector((state: RootState) => {
        return state.homeclient;
    });
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getHomeOrders(token));
        }
    }, [dispatch]);
    const openorder: string = '';

    return (
        <ContainerFlex>
            <ContainerFlex Width="70%">
                <ContainerPedidos>
                    <SubContainer name="Mis Pedidos" />
                    <ContainerFlex Padding="25px 1rem " FlexDir="column">
                        {homeclient.homeorders?.map((dataordhome, index: number) => (
                            <SubContainerFavs key={index}>
                                <TableRowHeadContract
                                    Background="#f3f3f3"
                                    GridTemplate="1.5fr 2fr .5fr"
                                >
                                    <Text FontWeight="normal" FontSize="14px" Color="#7AC143">
                                        {dataordhome.statusOrder}
                                        {' | '}
                                        <Text FontWeight="normal" FontSize="12px" Color="#414042">
                                            Preparando {'  '}
                                        </Text>
                                        <ImgFlagOne src={dataordhome.flagCompany} />{' '}
                                        <Text
                                            FontWeight="normal"
                                            FontSize="12px"
                                            Color="#7AC143"
                                            Margin="0 3px"
                                        >
                                            {dataordhome.business}
                                        </Text>
                                    </Text>
                                    <Text FontWeight="300" FontSize="12px" Color="#4A4A4A">
                                        Fecha pedido:{'  '}
                                        <Text FontWeight="400" FontSize="12px" Color="#4A4A4A">
                                            {DateTime.fromISO(dataordhome.dateOrder).toFormat(
                                                'dd/MM/yyyy'
                                            )}
                                        </Text>
                                    </Text>
                                    <Text FontWeight="300" FontSize="12px" Color="#4A4A4A">
                                        {/* <Imgqr src={logoqr} /> */}
                                        No. Pedido:{'  '}
                                        <Text
                                            FontWeight="400"
                                            FontSize="12px"
                                            Color="#4A4A4A"
                                            Margin="0 3px"
                                            Cursor="pointer"
                                        >
                                            {dataordhome.orderId}
                                        </Text>
                                    </Text>
                                </TableRowHeadContract>
                                <div className="sub-containerfavs">
                                    <ContainerFlex Padding="17px 0 11px 0">
                                        <ImgProduct src={dataordhome.productImage} />
                                        <ContainerFlex Align="flex-start" FlexDir="column">
                                            <TextEllipsis
                                                FontWeight="400"
                                                FontSize="14px"
                                                Color="#4A4A4A"
                                            >
                                                {' '}
                                                {dataordhome.productName}
                                            </TextEllipsis>
                                            <Text
                                                Margin="8px 0 0 0"
                                                FontWeight="300"
                                                FontSize="12px"
                                                Color="#4A4A4A"
                                            >
                                                Sucursal:{'  '}
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#4A4A4A"
                                                >
                                                    {dataordhome.business}
                                                </Text>
                                            </Text>
                                        </ContainerFlex>
                                        <ContainerFlex Align="flex-start" FlexDir="column">
                                            <Text FontWeight="300" FontSize="12px" Color="#4A4A4A">
                                                Costo Producto:{'  '}
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#4A4A4A"
                                                >
                                                    {formatterDolar.format(dataordhome.productCost)}
                                                </Text>
                                            </Text>
                                            <Text
                                                Margin="8px 0 0 0"
                                                FontWeight="300"
                                                FontSize="12px"
                                                Color="#4A4A4A"
                                            >
                                                Costo Apartado:{'  '}
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#4A4A4A"
                                                >
                                                    {formatterDolar.format(dataordhome.apartCost)}
                                                </Text>
                                            </Text>
                                        </ContainerFlex>
                                        <ContainerFlex Align="flex-start" FlexDir="column">
                                            <Text FontWeight="300" FontSize="12px" Color="#4A4A4A">
                                                Saldo a pagar:{'  '}
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#4A4A4A"
                                                >
                                                    {formatterDolar.format(dataordhome.balancePay)}
                                                </Text>
                                            </Text>

                                            <Text
                                                Margin="8px 0 0 0"
                                                FontWeight="300"
                                                FontSize="12px"
                                                Color="#4A4A4A"
                                            >
                                                Costo envio:{'  '}
                                                <Text
                                                    FontWeight="400"
                                                    FontSize="12px"
                                                    Color="#4A4A4A"
                                                >
                                                    {formatterDolar.format(
                                                        dataordhome.shippingCost
                                                    )}
                                                </Text>
                                            </Text>
                                        </ContainerFlex>
                                        <ContainerFlex
                                            Cursor="pointer"
                                            FlexDir="column"
                                            Width="25px"
                                        >
                                            <span className="material-icons">expand_more</span>
                                        </ContainerFlex>
                                    </ContainerFlex>

                                    <ContainerFlex>
                                        <ContainerFlex FlexDir="column" Justify="flex-start">
                                            <Text FontSize="12px">ICONO</Text>
                                            <Text FontSize="12px">Datos de envío</Text>
                                        </ContainerFlex>

                                        <ContainerFlex FlexDir="column">
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Cod. Postal:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    123143
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    No.Exterior:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    12345
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Ciudad:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    Ciudad de Mexico
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>

                                        <ContainerFlex FlexDir="column">
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Dirección:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    Edificio JOSE MARTI No. 32
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    No.Interior:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    12345
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Delegación:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    Delegación 1
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>

                                        <ContainerFlex FlexDir="column">
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Estado:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    Ciudad de México
                                                </Text>
                                            </ContainerFlex>
                                            <ContainerFlex Justify="flex-start">
                                                <Text FontWeight="300" FontSize="12px">
                                                    Colonia:
                                                </Text>
                                                <Text FontWeight="400" FontSize="12px">
                                                    Colonia 1
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                    <ContainerFlex Padding="5px" FlexDir="column">
                                        {openorder.includes(String(dataordhome.articleId)) && (
                                            <>
                                                {' '}
                                                <ContainerFlex Padding="5px" Width="50%">
                                                    {dataordhome.statusOrderforArticle.map(
                                                        (
                                                            images: {iconStatusImage: string},
                                                            index: number
                                                        ) => (
                                                            <>
                                                                <ImgOrder
                                                                    key={index}
                                                                    src={images.iconStatusImage}
                                                                />
                                                                {index < 3 && (
                                                                    <ContainerFlex Width="calc(30% - 20px)" />
                                                                )}
                                                            </>
                                                        )
                                                    )}
                                                </ContainerFlex>
                                                <ContainerFlex Width="53%" Justify="space-between">
                                                    {dataordhome.statusOrderforArticle.map(
                                                        (
                                                            textos: {
                                                                checkState: number;
                                                                descriptionHeader: string;
                                                            },
                                                            index: number
                                                        ) => (
                                                            <Text
                                                                FontSize="12px"
                                                                FontWeight="400"
                                                                key={index}
                                                                Color={
                                                                    textos.checkState > 0
                                                                        ? '#414042'
                                                                        : '#A7A9AC'
                                                                }
                                                            >
                                                                {textos.descriptionHeader}
                                                            </Text>
                                                        )
                                                    )}
                                                </ContainerFlex>
                                                <ContainerFlex
                                                    FlexDir="column"
                                                    Padding=" 0"
                                                    Align="flex-start"
                                                    Width="50%"
                                                    Justify="center"
                                                >
                                                    {dataordhome.statusOrderforArticle.map(
                                                        (
                                                            text: {
                                                                checkState: number;
                                                                iconCkeckImage: string | undefined;
                                                                description: string;
                                                                date: string;
                                                            },
                                                            index: number
                                                        ) => (
                                                            <Text
                                                                key={index}
                                                                Margin="13px 0 0 0"
                                                                Color={
                                                                    text.checkState > 0
                                                                        ? '#414042'
                                                                        : '#CBCED1'
                                                                }
                                                                FontSize="12px"
                                                                FontWeight="400"
                                                            >
                                                                {text.checkState === 0 ? (
                                                                    <IconInactive />
                                                                ) : (
                                                                    <img
                                                                        src={text.iconCkeckImage}
                                                                        alt=""
                                                                    />
                                                                )}{' '}
                                                                {text.description}{' '}
                                                                {text.checkState > 0 ? (
                                                                    <Text
                                                                        Margin="0 0 0 13px"
                                                                        Color="#A7A9AC"
                                                                        FontSize="11px"
                                                                        FontWeight="400"
                                                                    >
                                                                        {DateTime.fromISO(
                                                                            text.date
                                                                        ).toFormat('dd/MM/yyyy')}
                                                                    </Text>
                                                                ) : null}
                                                            </Text>
                                                        )
                                                    )}
                                                </ContainerFlex>
                                            </>
                                        )}
                                    </ContainerFlex>
                                </div>
                            </SubContainerFavs>
                        ))}
                    </ContainerFlex>
                </ContainerPedidos>
            </ContainerFlex>
        </ContainerFlex>
    );
};
