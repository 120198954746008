import React from 'react';
import {ClickAwayListener} from '@mui/material';

import {ListResultsProps} from '@General/Atoms/SearchBoxResultList/interfaces';
import {resolveNoDataContent} from '@General/Atoms/SearchBoxResultList/stylesConstants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import * as style from '@General/Atoms/SearchBoxResultList/stylesConstants';
import {ResultsCard} from '@General/Atoms/SearchBoxResultList/styles';

export const ListResults = <T,>({
    viewList,
    validateSearch,
    setViewList,
    results,
    renderItem,
    onItemSelect,
    noDataContent,
}: ListResultsProps<T>) => {
    return (
        <>
            {viewList && (
                <ClickAwayListener onClickAway={() => setViewList(false)}>
                    <ResultsCard Border={validateSearch ? '1px solid #F3F3F3' : ''}>
                        {results && validateSearch && results.length > 0 ? (
                            results.slice(0, 5).map((item, index) => (
                                <ContainerFlex
                                    key={index}
                                    {...style.contentList}
                                    onClick={() => onItemSelect(item)}
                                    HBackground="#f0f0ff"
                                    HBorderLeft="2px solid #5a5aff"
                                >
                                    <Text {...style.wordSearch}>{renderItem(item)}</Text>
                                </ContainerFlex>
                            ))
                        ) : (
                            <ContainerFlex
                                Align="center"
                                Justify="start"
                                onClick={() => setViewList(false)}
                            >
                                {resolveNoDataContent(noDataContent)}
                            </ContainerFlex>
                        )}
                    </ResultsCard>
                </ClickAwayListener>
            )}
        </>
    );
};
