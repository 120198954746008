import * as yup from 'yup';
const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`|\\]).+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const shemaCreateClient = yup.object().shape({
    email: yup
        .string()
        .required('Este campo es obligatorio')
        .matches(
            emailRegex,
            'El formato de correo no se reconoce, intenta de nuevo. Ej. tunombre@correo.com'
        ),
    names: yup.string().required('Este campo es obligatorio'),
    password: yup
        .string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('Este campo es obligatorio')
        .matches(
            regex,
            'Debe incluir una mayúscula, una minúscula, un número y un carácter especial'
        ),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
        .required('Este campo es obligatorio'),
});
