import React, {useState} from 'react';

import {IInheritFromPorps} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import rightArrow from '@components/Branches/Images/RightArrowPurple.svg';
import {INHERIT_FROM} from '@components/LocationsBranch/constants';
import {InheritFromModal} from '@components/LocationsBranch/InheritFromModal';

export const InheritFrom: React.FC<IInheritFromPorps> = ({type, inheriterName}) => {
    const [showInheritForm, setShowInheritForm] = useState(false);
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowInheritForm(true);
    };
    return (
        <>
            <ContainerFlex Align="center" Justify="flex-end" Gap="8px" Width="fit-content">
                <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="400" oFlow="none">
                    {INHERIT_FROM.TITLE}
                </Text>
                <ContainerFlex onClick={handleClick} Justify="center" Cursor="pointer" Gap="4px">
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="500">
                        {inheriterName}
                    </Text>
                    <Image
                        src={rightArrow}
                        alt="arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
            {showInheritForm && (
                <InheritFromModal
                    showModal={showInheritForm}
                    setShowModal={setShowInheritForm}
                    type={type}
                />
            )}
        </>
    );
};
