import {
    GET_DATA_SELECT_REASON_START,
    GET_DATA_SELECT_REASON_SUCCESS,
    GET_DATA_SELECT_REASON_ERROR,
    DELETE_REASON_START,
    DELETE_REASON_SUCCESS,
    DELETE_REASON_ERROR,
} from '@MyCompany/Redux/Types/types';
import {IMotiveDelete, IMotiveDeleteRedux} from '@MyCompany/Interface';

const initialState: IMotiveDelete = {
    loading: false,
    error: false,
    data: [],
};

const GetMotiveDelete = (state = initialState, action: IMotiveDeleteRedux): IMotiveDelete => {
    switch (action.type) {
        case GET_DATA_SELECT_REASON_START:
            return {...state, loading: true};

        case GET_DATA_SELECT_REASON_SUCCESS:
            return {...state, loading: false, data: action.data};

        case GET_DATA_SELECT_REASON_ERROR:
            return {...state, loading: false, error: true};

        case DELETE_REASON_START:
            return {
                ...state,
                loading: true,
            };
        case DELETE_REASON_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_REASON_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};
export default GetMotiveDelete;
