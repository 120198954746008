import {
    VERIFY_CODE_NEW_REQUEST,
    VERIFY_CODE_NEW_SUCCESS,
    VERIFY_CODE_NEW_ERROR,
} from '@components/MarketLogin/redux/types/types';

const initialState = {
    loading: false,
    message: '',
    error: null,
};

export const newValidationCodeMarket = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CODE_NEW_REQUEST:
            return {...state, loading: true};
        case VERIFY_CODE_NEW_SUCCESS:
            return {...state, loading: false};
        case VERIFY_CODE_NEW_ERROR:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
