import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    POST_CREATE_LEVELS_ERROR,
    POST_CREATE_LEVELS_FINISH,
    POST_CREATE_LEVELS_START,
    POST_CREATE_LEVELS_SUCCESS,
} from '@TypesOrgCreation/types';
import {updatePageNumber} from '@ActionsOrgCreation/CheckedState';
import {AppDispatch} from '@/config/store';
import {ICreateLevel} from '@OrganizationCreation/interface';

export const fetchPostCreateLevelStart = () => {
    return {
        type: POST_CREATE_LEVELS_START,
    };
};
export const fetchPostCreateLevelSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_CREATE_LEVELS_SUCCESS,
        value: result.data,
    };
};
export const fetchPostCreateLevelError = () => {
    return {
        type: POST_CREATE_LEVELS_ERROR,
    };
};
export const fetchPostCreateLevelFinish = () => {
    return {
        type: POST_CREATE_LEVELS_FINISH,
    };
};

export function postCreateLevel(data: ICreateLevel, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostCreateLevelStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateLevel}`,
                data,
                {headers}
            );
            await dispatch(fetchPostCreateLevelSuccess(response));
            dispatch(updatePageNumber(1));
        } catch (error) {
            dispatch(fetchPostCreateLevelError());
        } finally {
            dispatch(fetchPostCreateLevelFinish());
        }
    };
}
