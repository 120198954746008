import React, {useEffect, useState} from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {EXPENSE_DETAIL_COMPONENT} from '@/components/CashFlowExpenseDetails/operational/constants';
import {ImageLogo} from '@/components/CashFlowExpenseDetails/operational/styles';
import arrow from '@images/arrowRBlue.svg';
import {ExpenseDetailTable} from '@/components/CashFlowExpenseDetails/ExpenseDetailTable';
import {AttchmentExpenseDetails} from '@/components/CashFlowExpenseDetails/AttachmentsExpenseDetails';
import {MainInformationExpense} from '@/components/CashFlowExpenseDetails/MainInformationExpense';
import {ModalExpenseDetail} from '@/components/CashFlowExpenseDetails/ModalExpenseDetails';
import {useNavigate} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {putAuthExpenseAction} from '@components/CashFlowExpenseDetails/Redux/ExpenseAuthorizationAction';
import {IexpeneArray} from '@components/CashFlowExpenseDetails/interfaces';

type ModalType = 'expense' | null;

export const DetailsExpense = () => {
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [openModal, setOpenModal] = useState<ModalType>(null);
    const navigate = useNavigate();
    const openModalHandler = (modal: ModalType) => setOpenModal(modal);
    const closeModalHandler = () => setOpenModal(null);
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );
    const expenseDetailData = useSelector(
        (state: RootState) => state.getExpenseDetails.expenseDetails
    );

    useEffect(() => {
        const expenseTotal = (expensesArray: IexpeneArray[]) => {
            let total = 0;
            for (let i = 0; i < 1; i++) {
                total = total + expensesArray[i].amount;
                total = total + expensesArray[i].amountWithVat;
            }
            return total;
        };
        if (expenseDetailData.expenseDetails) {
            setExpenseTotal(expenseTotal(expenseDetailData.expenseDetails));
        }
    }, [expenseDetailData.expenseDetails]);

    const authPayload = {
        id: expenseDetailData.id,
        isApproved: true,
        comments: '',
    };

    const handleAuthorization = async () => {
        try {
            await dispatch(putAuthExpenseAction(token, authPayload));
            navigate('/CashFlow');
        } catch (error) {
            return error;
        }
    };

    return (
        <ContainerFlex FlexDir="column" Gap="16px" Padding="16px 24px">
            <ContainerFlex
                Width="77rem"
                Justify="start"
                Cursor="pointer"
                Height=""
                onClick={() => navigate('/Cashflow')}
            >
                <ImageLogo Width="24px" Height="24px" src={arrow} />
                <Text Cursor="pointer" FontSize="1rem" FontWeight="500" Color="#5A5AFF">
                    {EXPENSE_DETAIL_COMPONENT.EXPENSE}
                </Text>
            </ContainerFlex>

            <ContainerFlex Width="77rem" Height="auto">
                <ContainerCellGrid Height="">
                    <ContainerFlex FlexDir="column" Align="start" Height="">
                        <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                            {expenseDetailData.id}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="end" Height="">
                        <Text Color="#2A2C2F" FontWeight="500" FontSize="1.25rem">
                            {EXPENSE_DETAIL_COMPONENT.TOTAL}
                        </Text>
                        <Text Color="#2A2C2F" FontWeight="500" FontSize="1.25rem" PaddingL="8px">
                            {expenseDetailData.expenseDetails && `$${expenseTotal}.00`}
                        </Text>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>

            <ContainerFlex Width="77rem" Height="auto" Radius="16px" backG="#fff">
                <MainInformationExpense />
            </ContainerFlex>
            <ContainerFlex Width="77rem" Height="auto" Radius="16px" backG="#fff">
                <ExpenseDetailTable />
            </ContainerFlex>
            <ContainerFlex Width="77rem" Height="auto" Radius="16px" backG="#fff">
                <AttchmentExpenseDetails />
            </ContainerFlex>

            <ContainerFlex Height="" Align="end" ColumnGap="24px">
                <ButtonGenerals
                    onClick={() => openModalHandler('expense')}
                    SecondaryButton="#FFFFFF"
                    Width="200px"
                >
                    {EXPENSE_DETAIL_COMPONENT.BT_ONE}
                </ButtonGenerals>
                <ButtonGenerals onClick={() => handleAuthorization()} Width="200px">
                    {EXPENSE_DETAIL_COMPONENT.BT_TWO}
                </ButtonGenerals>
            </ContainerFlex>
            <ModalExpenseDetail isOpen={openModal === 'expense'} closeModal={closeModalHandler} />
        </ContainerFlex>
    );
};
