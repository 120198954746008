import styled from '@emotion/styled';
import CheckboxTree from 'react-checkbox-tree';
import {IPropsContainer, IPropsCheckTree} from '@OrganizationTreeView/interfacesStyles';

export const Container = styled.div<IPropsContainer>`
    width: 100%;
    .react-checkbox-tree {
        display: inline-block;
        white-space: nowrap;
        width: 100%;
    }

    .rct-node-icon {
        display: none;
    }

    .rct-text {
        padding-top: 20px;
    }

    .bwPAev .rct-title {
        padding-top: 20px;
    }

    .rct-checkbox {
        display: ${({hideCheckbox}) => (hideCheckbox ? 'none' : 'inline-block')};
    }

    .react-checkbox-tree > ol > li > span {
        font-weight: 600;
        background-color: #f8f8f8;
        width: 100%;
        padding: 1rem 0 0 0;
    }

    .rct-icon-check {
        color: var(--green);
    }

    .rct-icon-half-check {
        color: var(--green);
    }
    .rct-icons-fa4 {
        font-size: 0.875rem;
    }
`;

export const ContainerBalance = styled.div`
    .react-checkbox-tree {
        display: inline-block;
        white-space: nowrap;
        width: 100%;
    }

    .rct-node-icon {
        display: none;
    }

    .rct-text {
        padding-top: 20px;
    }

    .rct-checkbox {
        display: none;
    }
    .react-checkbox-tree ol ol {
        padding-left: 0;
    }

    .react-checkbox-tree > ol > li > span {
        font-weight: 600;
        background-color: #fff;
        width: 100%;
        padding: 1rem 0 0 0;
    }

    .rct-icon-check {
        color: var(--green);
    }

    .rct-icon-half-check {
        color: var(--green);
    }
    .rct-icons-fa4 {
        font-size: 14px;
        color: var(--green);
    }
    .rtc-collapse-icon {
        display: none;
    }
    .rct-icon {
        display: none;
    }
    .rct-title:before {
        content: '$';
    }
    .rct-title:after {
        content: '.00';
    }
`;
export const ContainerNormal = styled.div`
    .react-checkbox-tree {
        display: inline-block;
        white-space: nowrap;
        width: 100%;
    }

    .rct-node-icon {
        display: none;
    }

    .rct-text {
        padding-top: 20px;
    }

    .rct-checkbox {
        display: none;
    }

    .react-checkbox-tree > ol > li > span {
        font-weight: 600;
        background-color: #fff;
        width: 100%;
        padding: 1rem 0 0 0;
    }

    .rct-icon-check {
        color: var(--green);
    }
    .rct-icon-half-check {
        color: var(--green);
    }
    .rct-icons-fa4 {
        font-size: 14px;
    }
`;

export const CheckTree = styled(CheckboxTree)<IPropsCheckTree>`
    .rct-node-icon {
        display: none;
    }
    .react-checkbox-tree > ol > li > span {
        font-weight: 500;
    }

    .rct-node-icon {
        display: none;
    }
    .rct-text {
        padding-top: 0;
    }

    div {
        display: flex;
        justify-content: space-evenly;
    }

    div ol {
        padding-left: 8px;
    }

    div ol li span {
        gap: 3px;
    }
    .rct-collapse,
    .rct-checkbox,
    .rct-node-icon {
        padding: 0;
    }

    div ol li span button {
        padding: 0;
        display: ${(props) => (props.isBranch ? 'none' : 'block')};
    }
    div ol li span button i {
        font-size: 14px;
    }

    div ol li span label {
        height: 40px;
        padding: 0;
        gap: 5px;
        &:hover {
            background: none;
        }
    }
    div ol li span label span {
        padding: 0;
        font-size: 0.875rem;
        font-weight: ${(props) => props.Weight};
        &.rct-node-icon {
            display: none;
        }
    }

    div ol li span label span {
        &.rct-checkbox {
            width: 16px;
        }
    }

    div ol li span label {
        display: flex;
        align-items: center;
        width: auto;
    }

    div ol li span label span {
        &.rct-checkbox {
            display: flex;
        }
    }

    div ol li span label span span {
        &.rct-icon-uncheck {
            &::before {
                display: none;
            }
            border: 1px solid #6d6e71;
            background-color: #fff;
            height: 16px;
            width: 16px;
            border-radius: 4px;

            &:hover {
                border-color: #35cf44;
            }
        }
        &.rct-collapse {
            padding: 0;
        }
        &.rct-icon-check {
            font-size: 17px;
            color: #35cf44;
        }
    }

    div ol li span label span span {
        &.rct-icon-half-check {
            &::before {
                display: none;
            }
            border: 1px solid #6d6e71;
            background-color: #fff;
            height: 16px;
            width: 16px;
            border-radius: 4px;

            &:hover {
                border-color: #35cf44;
            }
        }
        &.rct-icon-check {
            font-size: 17px;
            color: #35cf44;
        }
    }

    div ol li span label span i {
        &.fa-check-square {
            font-size: 18px;
            color: #35cf44;
        }
    }
`;

export const CheckTreeBalance = styled(CheckboxTree)`
    .rct-node-icon {
        display: none;
    }
`;
