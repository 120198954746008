/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import ReactPaginate from 'react-paginate';
import {IReactPaginateProps} from '@/components/General/Atoms/ListPagination/Styles/interfaces';

const paginationItemStyles = css`
    border: none;
    margin: 0;
    text-align: left;
    width: auto;
    border-radius: 0;
`;

export const Paginate = styled(ReactPaginate)<IReactPaginateProps>`
    display: ${(props) => (props.Display ? props.Display : 'flex')};
    justify-content: ${(props) => (props.JustifyCont ? props.JustifyCont : 'space-between')};
    list-style: none;
    cursor: pointer;
    padding: 0;
    margin-top: 20px;

    & li {
        ${paginationItemStyles}
    }

    & a {
        border-radius: 50%;
        margin: 0;
        padding: 7px 14px;
        border: 1px solid transparent;
        color: #505050;
        &:hover {
            color: #3965ce;
            background-color: #e5e5fd;
        }
    }

    & .page-item-next {
        & a {
            padding: 10px 10px 7px 9px;
        }
    }
    & .page-item-prev {
        & a {
            padding: 10px 10px 7px 9px;
        }
    }
    & .disabled a {
        color: #cacaca;
        cursor: auto;
        &:hover {
            color: #cacaca;
            border-color: transparent;
            background-color: transparent;
        }
    }

    & .selected a {
        color: #ffffff;
        background-color: #5a5af8;
    }
    & .pagination-page {
        & a {
            padding: 0.5rem 0.8rem;
        }
    }
    & .pagination-break {
        & a {
            padding: 7px 10px;
        }
    }
`;

export const PaginationInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

export const PaginationDetail = styled.div`
    color: #6b6c72;
`;
