import {AppDispatch} from '@/config/store';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {
    GET_ALLPAWN_DETAILS,
    GET_ALLPAWN_DETAILS_ERROR,
    GET_ALLPAWN_DETAILS_SUCCESS,
} from '@components/TransactionsDetails/Redux/types';

const getPawnDet = () => {
    return {type: GET_ALLPAWN_DETAILS};
};
const getPawnDetSuccess = (result: AxiosResponse) => {
    return {type: GET_ALLPAWN_DETAILS_SUCCESS, payload: result.data};
};

const getPawnDetError = (error: AxiosError) => {
    return {type: GET_ALLPAWN_DETAILS_ERROR, error: error};
};

export function getAllPawnsDetails(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPawnDet());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_WEB
                }${URL.urlWeb.GetPawnByBranchTransactionId.replace('{id}', id)}`,
                {
                    headers,
                }
            );
            dispatch(getPawnDetSuccess(response.data));
        } catch (error) {
            dispatch(getPawnDetError(error as AxiosError));
        }
    };
}
