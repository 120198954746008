import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {getLocationContainerC, fetchGetIdContainer} from '@ActionsLocation/LocationAction';
import {RootState, AppDispatch} from '@config/store';
import CreateLocation from '@CreateLocation/CreateLocation';
import {ContainerArea, SubContainerArea} from '@CreateLocation/style';
import LocationsModal from '@AdminLocations/LocationsModal';
import {BranchL, treeLocations, location} from '@Locations/AdminLocations/redux/Interfaces';
import TableRack from '@Locations/AdminLocations/CreateLocation/TableLocations/TableRack';
import TableVault from '@Locations/AdminLocations/CreateLocation/TableLocations/TableVault';
import TablePension from '@Locations/AdminLocations/CreateLocation/TableLocations/TablePension';
import TableFloor from '@Locations/AdminLocations/CreateLocation/TableLocations/TableFloor';

export default function Locations(props: {branch: BranchL}): JSX.Element {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const TreeComponent: treeLocations = useSelector((state: RootState) => {
        return state.TreeBranchesLocations;
    });
    const dispatch: AppDispatch = useDispatch();
    const [activate, setActivate] = useState<number[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [show, setShow] = useState(false);

    const SelectCheckValidation = (item: number) => {
        setActivate([item, ...activate]);
        if (activate.indexOf(item) !== -1)
            setActivate(activate.filter((data: number) => data !== item));
    };

    const handleSelectAll = () => {
        let rack: number[] = [0];
        let vault: number[] = [0];
        let pension: number[] = [0];
        let floor: number[] = [0];

        setIsCheckAll(!isCheckAll);
        if (isCheckAll === true) {
            if (locations.locationsidcontainer === 1)
                rack = locations.gRack.data.map((data: {id: number}) => {
                    return data.id;
                });
            if (locations.locationsidcontainer === 2)
                vault = locations.gVault.data.map((data: {id: number}) => {
                    return data.id;
                });
            if (locations.locationsidcontainer === 3)
                pension = locations.gPension.data.map((data: {id: number}) => {
                    return data.id;
                });
            if (locations.locationsidcontainer === 4)
                floor = locations.gFloor.data.map((data: {id: number}) => {
                    return data.id;
                });

            setActivate(rack || vault || pension || floor);
        } else {
            setActivate([]);
        }
    };

    const shearchFunctions = {
        branch: locations.saveBranch,
        activate: activate,
        isCheckAll: isCheckAll,
        SelectCheckValidation: SelectCheckValidation,
        handleSelectAll: handleSelectAll,
        setShow: setShow,
    };

    useEffect(() => {
        if (token && locations.saveBranch?.idBranch !== undefined)
            dispatch(getLocationContainerC(locations.saveBranch.idBranch, token));
    }, [locations.saveBranch?.idBranch]);

    useEffect(() => {
        const controlActive =
            (locations.locationsidcontainer === 1 &&
                locations.gRack !== null &&
                locations.gRack.data?.length) ||
            (locations.locationsidcontainer === 2 &&
                locations.gVault !== null &&
                locations.gVault.data?.length) ||
            (locations.locationsidcontainer === 3 &&
                locations.gPension !== null &&
                locations.gPension.data?.length) ||
            (locations.locationsidcontainer === 4 &&
                locations.gFloor !== null &&
                locations.gFloor.data?.length);
        setIsCheckAll(activate.length === controlActive ? false : true);
    }, [activate.length]);

    return (
        <ContainerFlex Align="start" FlexDir="column" Padding="0 6.313rem 0 1.563rem">
            <ContainerFlex Align="start" FlexDir="column" Height="95vh" Justify="start">
                <ContainerArea>
                    <SubContainerArea>
                        <Text FontWeight="400">Gestión de Ubicaciones</Text>
                    </SubContainerArea>
                    <ContainerFlex
                        Padding="1.438rem 0"
                        Justify="start"
                        Align="start"
                        Width="80%"
                        Height="auto"
                    >
                        {locations.containerC && (
                            <ContainerFlex
                                Height="auto"
                                Align="start"
                                Justify="start"
                                Padding="1.438rem 0 0 0.5rem"
                                Width="auto"
                            >
                                {locations.containerC
                                    .filter((item) => item.countContainer !== 0)
                                    .map((item, index: number) => (
                                        <ContainerFlex
                                            FlexDir="column"
                                            key={index}
                                            Margin="0 0.75rem  0 0"
                                        >
                                            <ContainerFlex
                                                Border={
                                                    locations.locationsidcontainer ===
                                                    item.containerId
                                                        ? '2px solid #7AC143'
                                                        : '2px solid #E4E7E9'
                                                }
                                                Height="98px"
                                                Width="98px"
                                                Radius="5px"
                                                Cursor="pointer"
                                                onClick={() => {
                                                    dispatch(fetchGetIdContainer(item.containerId));
                                                    setActivate([]);
                                                }}
                                            >
                                                <img
                                                    alt={item.urlActive}
                                                    width="50px"
                                                    height="50px"
                                                    src={
                                                        locations.locationsidcontainer ===
                                                        item.containerId
                                                            ? item.urlActive
                                                            : item.urlInactive
                                                    }
                                                />
                                            </ContainerFlex>
                                            <ContainerFlex Width="110px" Padding="11px 0">
                                                <Text wSpace="pre">
                                                    {item.container + ' '}
                                                    {'(' + item.countContainer + ')'}
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    ))}
                            </ContainerFlex>
                        )}
                        <ContainerFlex Justify="start" Width="25%">
                            <CreateLocation />
                        </ContainerFlex>
                    </ContainerFlex>

                    {TreeComponent.treeLocation && TreeComponent.treeLocation.data.length > 0 && (
                        <ContainerFlex Justify="start" Align="start" Padding="0 15px">
                            <LocationsModal
                                show={show}
                                setShow={() => setShow(!show)}
                                branch={locations.saveBranch}
                                setActivate={setActivate}
                            />
                            {locations.locationsidcontainer === 1 && (
                                <TableRack {...shearchFunctions} />
                            )}
                            {locations.locationsidcontainer === 2 && (
                                <TableVault {...shearchFunctions} />
                            )}
                            {locations.locationsidcontainer === 3 && (
                                <TablePension {...shearchFunctions} />
                            )}
                            {locations.locationsidcontainer === 4 && (
                                <TableFloor {...shearchFunctions} />
                            )}
                        </ContainerFlex>
                    )}
                </ContainerArea>
            </ContainerFlex>
        </ContainerFlex>
    );
}
