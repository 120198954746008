import axios, {AxiosResponse} from 'axios';
import {
    PUT_BRANCH_STATUS,
    PUT_BRANCH_STATUS_ERROR,
    PUT_BRANCH_STATUS_FINISH,
    PUT_BRANCH_STATUS_SUCCESS,
} from '@components/Branches/Redux/Types/types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchPutUpdateBranchStatusStart = () => {
    return {
        type: PUT_BRANCH_STATUS,
    };
};
export const fetchPutUpdateBranchStatusSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_BRANCH_STATUS_SUCCESS,
        payload: result,
    };
};
export const fetchPutUpdateBranchStatusError = () => {
    return {
        type: PUT_BRANCH_STATUS_ERROR,
    };
};
export const fetchPutUpdateBranchStatusFinish = () => {
    return {
        type: PUT_BRANCH_STATUS_FINISH,
    };
};

export function PutUpdateBranchStatus(data: {branchId: number; status: boolean}, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutUpdateBranchStatusStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutUpdateBranchStatus}`,
                data,
                {headers}
            );
            dispatch(fetchPutUpdateBranchStatusSuccess(response));
        } catch (error) {
            dispatch(fetchPutUpdateBranchStatusError());
        } finally {
            dispatch(fetchPutUpdateBranchStatusFinish());
        }
    };
}
