import React from 'react';
import {DateTime} from 'luxon';
import {TableSecond} from '@AuditAssignment/styles';

export const RowSucursalHistoryTable = (props: {
    sucursal: string;
    region: string;
    estado: string;
    fechaVisita: string;
    auditor: string;
}) => {
    const {sucursal, region, estado, fechaVisita, auditor} = props;
    return (
        <TableSecond>
            <td>{sucursal}</td>
            <td>{region}</td>
            <td>{estado}</td>
            <td>{DateTime.fromISO(fechaVisita).toLocaleString(DateTime.DATE_MED)}</td>
            <td>{auditor}</td>
        </TableSecond>
    );
};
