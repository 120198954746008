export const RESET_PASSWORD = {
    TITLE: 'Restablecer contraseña',
    MESSAGE: 'Confirma el correo asociado a tu cuenta para',
    MESSAGE_TWO: 'crear una nueva contraseña',
    EMAIL: 'Correo electrónico',
    EMAIL_NAME: 'carlos_torres@gmail.com',
    CANCEL: 'Calcelar',
};

export const RESTORE_PASSWORD_VALIDE = {
    TIMER_TEXT: 'Volver a solicitar código en ',
    REQUEST_NEW_CODE: ' Solicitar un nuevo código',
};

export const RESTORE_NEW_PASSWORD = {
    TITLE: 'Crea una nueva contraseña',
    MESSAGE: 'La contraseña debe tener al menos 8 caracteres.',
    MESSAGE_ONE: 'Debe incluir una mayúscula, una minúscula, un número y',
    MESSAGE_TWO: ' un carácter especial.',
    PASSWORD: 'Contraseña',
    PASSWORD_CONFIRM: 'Confirmar contraseña',
    REPEAT_PASS: 'Las contraseñas no coinciden, inténtalo de nuevo',
    SAME_PASSWORD: 'Las contraseñas coinciden',
    CLOSE: 'Close icon',
    DONE: 'Close icon',
};

export const SUCCESS_PASSWORD = {
    SUCCESS_TITLE: 'Tu contraseña ha sido ',
    SUCCESS_TITLE_ONE: 'restablecida',
    SUCCESS_TEXT: 'Ahora puedes iniciar sesión',
    BTEX: 'Iniciar sesión',
};

export const NEW_PASSWORD = {
    PASS: 'Contraseña',
    PASS_CONFIRM: 'Confirmar contraseña',
    CODE_VALIDE: 'El código de no es válido',
    CODE_CLOSE: 'close',
};
