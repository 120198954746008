import {ResponseData} from '@/components/ManageUser/interface';
import {ENDEAVORS} from '@Shopify/Endeavors/redux/types/Types';

const initialState = {
    endeavors: null,
    car_discharge: null,
    branch: null,
    actualPage: 1,
    numberBranch: 0,
    folioEndeavor: '',
    loading: false,
    error: false,
};

const endeavorsClient = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case ENDEAVORS.CLIENT:
            return {
                ...state,
                loading: true,
            };
        case ENDEAVORS.CLIENT_SUCCESS:
            return {
                ...state,
                endeavors: action.payload,
                loading: false,
                error: false,
            };
        case ENDEAVORS.CLIENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case ENDEAVORS.CLIENT_BRANCH_SUCCESS:
            return {
                ...state,
                branch: action.payload,
            };
        case ENDEAVORS.CLIENT_BRANCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case ENDEAVORS.NUMBER_BRANCH:
            return {
                ...state,
                numberBranch: action.payload,
            };
        case ENDEAVORS.ACTUAL_PAGE:
            return {
                ...state,
                actualPage: action.payload,
            };
        case ENDEAVORS.FOLIO:
            return {
                ...state,
                folioEndeavor: action.payload,
            };
        case ENDEAVORS.RESTART:
            return initialState;
        case ENDEAVORS.CAR:
            return {
                ...state,
                loading: true,
            };
        case ENDEAVORS.CAR_SUCCESS:
            return {
                ...state,
                car_discharge: action.payload,
                loading: false,
                error: false,
            };
        case ENDEAVORS.CAR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default endeavorsClient;
