import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {IFormInputProps} from '@components/AccountSettings/interfaces';

const FormInput: React.FC<IFormInputProps> = ({
    name,
    label,
    mutedLabel,
    placeholder,
    type = 'text',
    control,
    register,
    error,
    onBlur,
    maxLength,
}) => (
    <ContainerFlex FlexDir="column" Align="flex-start" Height="auto" Gap="4px">
        <ContainerFlex Gap="4px" Justify="start" Align="center">
            <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                {label}
            </Text>
            {mutedLabel && (
                <Text FontSize="0.75rem" FontWeight="500" Color="#A1A5AA">
                    {' '}
                    {mutedLabel}
                </Text>
            )}
        </ContainerFlex>
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <Input
                    {...field}
                    {...register(name)}
                    placeholder={placeholder}
                    type={type}
                    error={!!error}
                    onBlur={(e) => {
                        field.onBlur();
                        if (onBlur) {
                            onBlur(e);
                        }
                    }}
                    maxLength={maxLength}
                />
            )}
        />
        {error && (
            <Text Color="#FF6357" FontSize="0.75rem" oFlow="visible">
                {error}
            </Text>
        )}
    </ContainerFlex>
);
export default FormInput;
