import {Controller} from 'react-hook-form';
import {DropdownIndicatorProps, components} from 'react-select';
import React, {useMemo, useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Select} from '@/components/Quoter/Steps/styles';
import {IPeriodMinFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import arrowDown from '@components/PersonalLoans/NewLoanProduct/icons/arrowDown.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    return (
        <components.DropdownIndicator {...props}>
            <ContainerFlex Gap="0.25rem" Width="max-content" Height="max-content">
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    FlexDir="column"
                    Position="relative"
                    Justify="start"
                    onMouseEnter={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                >
                    <Image
                        src={questionIcon}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="question-icon"
                    />
                    {showToolTip && <ToolTip description={LOAN_DETAILS.TOOLTIPS.MIN_PERIOD} />}
                </ContainerFlex>
                <Image src={arrowDown} Width="24px" Height="24px" alt="arrow-down-icon" />
            </ContainerFlex>
        </components.DropdownIndicator>
    );
};

export const PeriodMinField: React.FC<IPeriodMinFieldProps> = ({control, errors}) => {
    const periodsOptions = useMemo(() => {
        const periods = [];
        const maxPeriods = 84;
        for (let i = 3; i <= maxPeriods; i += 3) {
            periods.push({value: i, label: `${i} meses`});
        }
        return periods;
    }, []);
    return (
        <ContainerFlex
            Gap="0.25rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Position="relative"
            Height="max-content"
        >
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.TERMS}
            </Text>
            <Controller
                name="periodMin"
                control={control}
                render={({field: {value, onChange, ...field}}) => (
                    <Select
                        {...field}
                        noOptionsMessage={() => 'Sin opciones'}
                        options={periodsOptions}
                        placeholder="Selecciona o escribe"
                        padding="0px"
                        controlTop="-6px"
                        radius="2rem"
                        components={{DropdownIndicator}}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    />
                )}
            />
            {errors.periodMin && (
                <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem" wSpace="wrap">
                    <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                    {errors.periodMin.message || errors.periodMin.value?.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
