import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@Shopify/Endeavors/styles';
import {pledgeRack} from '@Locations/interfaces';

export default function RackProucts(props: {pledgeRack: pledgeRack}): JSX.Element {
    const {pledgeRack} = props;
    return (
        <>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Rack
                </Text>
                <Text FontSize="0.875rem">{pledgeRack.rackName}</Text>
            </TableItem>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Fila
                </Text>
                <Text FontSize="0.875rem">{pledgeRack.rowNumber}</Text>
            </TableItem>
            <TableItem />
            <TableItem />
        </>
    );
}
