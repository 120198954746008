import {BENEFICIARY_SUCCESS, BENEFICIARY_ERROR, BENEFICIARY} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingBeneficiarySaved: false,
    idBeneficiarySaved: 0,
};

const saveDataBenefiaciary = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case BENEFICIARY:
            return {
                ...state,
                loadingBeneficiarySaved: true,
                error: false,
            };
        case BENEFICIARY_SUCCESS:
            return {
                ...state,
                loadingBeneficiarySaved: false,
                idBeneficiarySaved: actions.payload.data.data,
            };
        case BENEFICIARY_ERROR:
            return {
                ...state,
                error: false,
                loadingBeneficiarySaved: false,
            };
        default:
            return state;
    }
};

export default saveDataBenefiaciary;
