import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {IconSvg} from '@/components/Quoter/styles';
import {ClickAwayListener} from '@mui/material';

interface IFastMenu {
    setOpenFastMenu: () => void;
}

export const FastActionsMenu = ({setOpenFastMenu}: IFastMenu) => {
    const menuAction = useSelector((state: RootState) => state.getFastActions);

    const navigate = useNavigate();
    return (
        <ClickAwayListener onClickAway={setOpenFastMenu}>
            <ContainerFlex
                Height="auto"
                FlexDir="column"
                Justify="start"
                backG="#FFFFFF"
                PositionTop="60px"
                PositionRight="3rem"
                Position="absolute"
                Padding="8px 16px"
                Width="auto"
                Radius="12px"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                ZIndex="3"
            >
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="8px"
                    Width="200px"
                >
                    <ContainerFlex Justify="space-between" Align="center">
                        <Text FontSize="1rem" Color="#71767D" Height="24px">
                            Acciones rápidas
                        </Text>
                        <IconSvg
                            Height="24px"
                            Width="24px"
                            onClick={setOpenFastMenu}
                            cursor="pointer"
                        >
                            <path
                                id="close_2"
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#5A5AFF"
                            />
                        </IconSvg>
                    </ContainerFlex>

                    {menuAction.MenuData.map(
                        (
                            data: {name: string; path: {fill: string; d: string}; route: string},
                            index: number
                        ) => {
                            return (
                                <ContainerFlex
                                    Justify="start"
                                    Align="center"
                                    Gap="8px"
                                    key={index}
                                    Height="24px"
                                    ColumnGap="8px"
                                    Cursor="pointer"
                                    onClick={() => {
                                        navigate(data.route);
                                        setOpenFastMenu();
                                    }}
                                >
                                    <IconSvg Height="24px" Width="24px">
                                        <path {...data.path} />
                                    </IconSvg>
                                    <Text Color="#2A2C2F" FontSize="1rem" Cursor="pointer">
                                        {data.name}
                                    </Text>
                                </ContainerFlex>
                            );
                        }
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};
