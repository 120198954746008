import {ResponseData} from '@components/CreateUsers/Interface';
import {
    GET_PHONE_IN_USE_START,
    GET_PHONE_IN_USE_SUCCESS,
    GET_PHONE_IN_USE_ERROR,
    RESET_GET_PHONE_IN_USE,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState = {
    phoneAvailable: false,
    data: null,
    loading: false,
    error: false,
};

const GetPhoneInUse = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_PHONE_IN_USE_START:
            return {
                ...state,
                loading: true,
            };
        case GET_PHONE_IN_USE_SUCCESS:
            return {
                ...state,
                phoneAvailable: action.payload.data.data,
                loading: false,
            };
        case GET_PHONE_IN_USE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_GET_PHONE_IN_USE:
            return initialState;
        default:
            return state;
    }
};

export default GetPhoneInUse;
