import React from 'react';

import {Image} from '@Shopify/Ecommerce/styles';
import ArrowLeft from '@images/arrowLeftIconGray.svg';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {BreadcrumbItemContainer} from '@BreadcrumbItem/styles';

export function BreadcrumbItem(props: {item: IBreadcrumbItem; isLastIndex: boolean}) {
    const {item, isLastIndex} = props;
    return isLastIndex ? (
        <span>{item.label}</span>
    ) : (
        <>
            <BreadcrumbItemContainer href={item.to} onClick={item.reset}>
                {item.label}
            </BreadcrumbItemContainer>
            <Image src={ArrowLeft} Height="16px" />
        </>
    );
}
