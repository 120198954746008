export const EXPENSE_DETAILS = {
    accountNumber: '12983746',
    expenses: 'Gastos',
    total: 'Total: $25700',
    principalInfo: 'Información Principal',
    expenseType: 'Tipo de gasto',
    service: 'Servicios',
    provider: 'Proveedor',
    oxxo: 'Oxxo',
    paymentMethod: 'Método de pago',
    card: 'Tarjeta de crédito o débito',
    referenceId: 'ID/ No de referencia',
    id: '98767854',
    expenseDate: 'Fecha de gasto',
    date: '19/07/2024',
    expenseDetails: 'Detalles de gasto',
    items: 'Items',
    category: 'Categoria',
    article: 'Árticulo o servicio',
    quantity: 'Cantidad',
    amount: 'Monto',
    noIva: ' (Sin IVA)',
    iva: 'IVA',
    observations: 'Observaciones',
    attached: 'Adjuntos',
    proofOfPayment: 'Comprobante de pago',
    optional: '(Opcional)',
    receipt: 'Factura',
    XMLformat: 'Formato XML',
};

export const UPLOAD_FILE = {
    mb: 'MB',
    replace: 'Remplazar',
    drag: 'Arrastra el archivo aquí o',
    search: 'Busca archivos',
};

export const DEFAULT_FORM = {
    expendType: {value: 0, label: ''},
    supplierType: {value: 0, label: ''},
    paymentMethod: {value: 0, label: ''},
    transferNumber: null,
    description: '',
    date: '',
    items: [{item: null, article: '', count: '', cost: '', iva: ''}],
};

export const REQUIRED_MESSAGE = 'El campo es requerido';
export const POSITIVE_NUMBER_MESSAGE = 'El numero debe ser positivo';
export const INTEGER_NUMBER_MESSAGE = 'Inserte un numero válido';
export const TYPE_ERROR_MESSAGE = 'El campo es requerido';
export const REQUIRED_DATE_MESSAGE = 'Fecha es requerida';
export const MIN_ITEMS_MESSAGE = 'Inserte al menos un detalle';
