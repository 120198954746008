export const TABS_MENU_OPTIONS = [
    'Mi Cuenta',
    'Mis compras',
    'Mis créditos',
    'Mi perfil',
    'Transacciones',
];

export const HEADER = {
    TITLE: 'Créditos',
    ACTION_BTN_TEXT: 'Solicitar crédito',
    CREDIT_TOTAL: '$ 12.000',
    IN_CREDIT: 'en créditos',
    PAY_UNTIL_NOW: 'Pagado hasta ahora',
    WHAT_I_OWE: 'Lo que debo',
    ACTIVE_LOANS: 'Préstamos activos',
    ACTIVE_PAWNS: 'Empeños activos',
};

export const CREDIT_TYPES = {
    ALL: 'Todos',
    PERSONAL: 'Créditos personales',
    PAWNS: 'Empeños',
};

export const SEARCH_FILTER = {
    PLACEHOLDER: 'Buscar',
    BTN_TEXT: 'Filtros',
};

export const VIEWS = {
    GRID: 'grid',
    LIST: 'list',
};

export const CARD_DATA = {
    SHARE: {
        TITLE: 'Negocio',
        BUSINESS_NAME: 'Préstamos Bogota',
        STATE: 'Al día',
        LEFT_ACTION_BTN: 'Agregar al carrito',
    },
    CREDIT: {
        TITLE: 'Préstamo',
        LOAN_VALUE: '$12.000',
        QUOTAS: 'Cuotas',
        QUOTAS_VALUE: '2/24',
        RIGHT_ACTION_BTN: 'Pagar cuota',
        DETAIL_BTN: 'Ver detalle de crédito',
        REQUESTED_DATE: 'Fecha de solicitud',
        APPROVED_DATE: 'Fecha de aprobación',
        PAYMENT_DATE: 'Fecha de pago',
        REJECTED_DATE: 'Fecha de rechazo',
        ACCEPT: 'Aceptar préstamo',
        ONTIME: 1,
        OVERDUE: 2,
        SETTLED: 3,
        REJECTED: 4,
        APPLICATION: 5,
        APPROVED: 6,
    },
    PAWNS: {
        TITLE: 'Préstamo',
        LOAN_VALUE: '$12.000',
        REFUND: 'Refrendo',
        REFUND_VALUE: '$200',
        DUE_DATE: 'Vencimiento',
        DUE_DATE_VALUE: ' 01/01/2024',
        PAWN_ITEMS_TITLE: 'Prendas',
        RIGHT_ACTION_BTN: 'Pagar refrendo',
        DETAIL_BTN: 'Ver detalle de empeño',
    },
};

export const CARDS_CONTAINER_TITLE = {
    PERSONAL: 'Créditos personales',
    PAWNS: 'Empeños',
};

export const NOT_FOUND = {
    PERSONAL: 'No hay créditos personales',
    PAWNS: 'No hay empeños',
};

export const FILTER_NAMES = {
    STATUS: 'statusId',
    COMPANY: 'companyId',
    REQUESTED_DATE: 'requestedDate',
    REQUESTED_AMOUNT: 'requestAmount',
};

export const FILTER_LABELS = {
    STATUS: 'Estado del crédito',
    COMPANY: 'Negocio',
    REQUESTED_DATE: 'Fecha de solicitud',
    REQUESTED_AMOUNT: 'Monto solicitado',
};

export const FILTER_ACTION_BTN = {
    CLEAR: 'Limpiar filtros',
    CLOSE: 'X',
    APPLY: 'Aplicar filtro',
};
export const DEFAULT_STATUS_INFO_LABEL = 'Activo';
