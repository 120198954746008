import {GET_BRANCH, GET_BRANCH_SUCCESS, GET_BRANCH_ERROR} from '@ReduxsCashFlow/Types/Types';

const initialState = {
    data: [],
    totalPage: [],
    loading: false,
    error: false,
};

interface BranchInfo {
    id: number;
    brachName: string;
    status: boolean;
}

export interface IBranch {
    data: IBranchData;
    totalPage: number;
    loading: boolean;
    error: boolean;
}
interface IBranchData {
    tokenExpiration: number;
    succeeded: boolean;
    message: string;
    errors: null;
    token: string;
    data: BranchInfo[];
}
const getBranch = (state = initialState, action: {type: string; payload: IBranchData}) => {
    switch (action.type) {
        case GET_BRANCH:
            return {
                ...state,
                loading: true,
            };
        case GET_BRANCH_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_BRANCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getBranch;
