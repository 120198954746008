import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Figure, Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import iconMyCompany from '@images/directory.svg';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';

import ButtonGeneral from '@General/Atoms/Button';
import IconClose from '@components/CreateUsers/Icons/close.svg';
import {IManageRolUser, RolByEnterpriceData, User} from '@components/CreateUsers/Interface';
import {RootState, AppDispatch} from '@config/store';
import {GetAllUsers} from '@components/CreateUsers/Redux/Actions/GetAllUsers';
import {IconContainer} from '@Roles/styles';

export const ModalManageRolUser = (props: IManageRolUser) => {
    const {setModalStateManageRol, name, lastName, selectedRoles, setSelectedRoles} = props;

    const {idUser} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const [selectUserWithSubstituyes, setSelectUserWithSubstituyes] =
        useState<RolByEnterpriceData[]>(selectedRoles);

    const listUsersFound = useSelector((state: RootState) => state.GetAllUsers.contact);
    const [rolId, setRolId] = useState(0);
    const [filtersToSearch, setFiltersToSearch] = useState({
        companyId: companyId,
        searchName: '',
        pageSize: 20,
        pageNumber: 1,
        orderType: 0,
    });

    const selectUser = (user: User, id: number) => {
        const updateRoles = selectUserWithSubstituyes.map((rol) =>
            rol.id === id
                ? {
                      ...rol,
                      substituteEmployee: rol.substituteEmployee?.some(
                          (employee) => employee.userId === user.userId
                      )
                          ? rol.substituteEmployee
                          : [...(rol.substituteEmployee || []), user],
                  }
                : rol
        );
        setSelectUserWithSubstituyes(updateRoles);
        setRolId(0);
        setFiltersToSearch({
            companyId: companyId,
            searchName: '',
            pageSize: 20,
            pageNumber: 1,
            orderType: 0,
        });
    };

    const deleteUser = (idRol: number, id: number) => {
        const updateRoles = selectUserWithSubstituyes.map((rol) =>
            rol.id === idRol
                ? {
                      ...rol,
                      substituteEmployee: rol.substituteEmployee?.filter(
                          (employee) => employee.userId !== id
                      ),
                  }
                : rol
        );
        setSelectUserWithSubstituyes(updateRoles);
        setRolId(0);
        setFiltersToSearch({
            companyId: companyId,
            searchName: '',
            pageSize: 20,
            pageNumber: 1,
            orderType: 0,
        });
    };

    const totalsubstituteEmployee = (substituteEmployee: number) => {
        const roles: {[key: number]: string} = {
            0: `Sin suplentes asignados`,
            1: `1 persona signada a este rol`,
        };
        return roles[substituteEmployee] ?? `${substituteEmployee} personas más en este rol`;
    };

    useEffect(() => {
        if (filtersToSearch.searchName === '') {
            setRolId(0);
        }
        dispatch(GetAllUsers(token, filtersToSearch));
    }, [filtersToSearch]);

    return (
        <Modal
            modalState={true}
            changeModalState={() => {}}
            titleModalState={true}
            Width="49.125rem"
            Height="26.188rem"
            HeightCont="100% "
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            BorderRadius="4px"
            background="none"
            FlexDirMod="column"
        >
            <ContainerFlex FlexDir="column">
                <Text
                    BorderBt="solid 1px #35cf44"
                    Width="100%"
                    Padding=" 24px 16px"
                    FontSize="1.25rem"
                    Color="#35cf44"
                    FontWeight="normal"
                    Self="start"
                >
                    {`Gestionar roles asignados a ${name} ${lastName}`}
                </Text>
                <ContainerFlex Justify="space-between" Padding="24px">
                    {selectUserWithSubstituyes.map((rol, index) => (
                        <ContainerFlex
                            FlexDir="column"
                            Width="22.063rem"
                            key={index}
                            Justify="start"
                            Position="relative"
                        >
                            <ContainerFlex Height="24px" Justify="start" Gap="8px">
                                <ImgLocation
                                    Height="24px"
                                    Width="24px"
                                    src={iconMyCompany}
                                    alt="account"
                                />
                                <Text FontWeight="500">{rol.name}</Text>
                            </ContainerFlex>
                            <ContainerFlex
                                backG="rgba(243, 243, 243, 0.4)"
                                FlexDir="column"
                                MarginTop="8px"
                                Radius="4px"
                                Border="solid 1px #f3f3f3"
                                Padding="8px"
                                Height="auto"
                                OverFlow="auto"
                                MaxH="205px"
                                Justify="start"
                            >
                                <SearchInput
                                    Height="40px"
                                    MinH="40px"
                                    Position=""
                                    Padding="0 12px"
                                    GridColumn="95% 5%"
                                    BorderH="1px solid #35CF44"
                                    BoxShadowH=""
                                    BorderF="2px solid #35CF44"
                                    BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                                >
                                    <input
                                        placeholder="Busca personas para este rol"
                                        onChange={(e) => {
                                            setFiltersToSearch({
                                                ...filtersToSearch,
                                                searchName: e.target.value,
                                            });
                                            setRolId(rol.id);
                                        }}
                                    />
                                    <IconContainer className="material-icons">search</IconContainer>
                                </SearchInput>
                                <ContainerFlex
                                    FlexDir="column"
                                    Justify="start"
                                    MarginTop="16px"
                                    Gap="4px"
                                >
                                    {rol.substituteEmployee && (
                                        <Text
                                            FontSize="0.75rem"
                                            Color="#a7a9ac"
                                            FontWeight="normal"
                                            Self="start"
                                            MinH="14px"
                                        >
                                            {totalsubstituteEmployee(
                                                rol.substituteEmployee?.length
                                            )}
                                        </Text>
                                    )}
                                    {rol.substituteEmployee ? (
                                        rol.substituteEmployee.map((employee) => (
                                            <ContainerFlex
                                                key={employee.userId}
                                                Height="48px"
                                                Border="1px solid #E5E7E9"
                                                Radius="4px"
                                                Padding="8px"
                                                backG="#fff"
                                                Justify="space-between"
                                            >
                                                <Figure>
                                                    <Image
                                                        src={employee.image}
                                                        Width="32px"
                                                        Height="32px"
                                                        alt={''}
                                                        Radius="50%"
                                                    />
                                                </Figure>
                                                <TextEllipsis
                                                    FontSize="0.875rem"
                                                    Color="#414042"
                                                    Width="60%"
                                                    Transform="capitalize"
                                                >
                                                    {employee.userName}
                                                </TextEllipsis>
                                                <Text Color="#A7A9AC" FontSize="0.75rem">
                                                    {employee.roles.map(
                                                        (role, index) =>
                                                            `${role.roleName}${
                                                                index === employee.roles.length - 1
                                                                    ? ''
                                                                    : ' | '
                                                            }`
                                                    )}
                                                </Text>
                                                <Figure Width="">
                                                    <Image
                                                        src={IconClose}
                                                        onClick={() =>
                                                            deleteUser(rol.id, employee.userId)
                                                        }
                                                    />
                                                </Figure>
                                            </ContainerFlex>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </ContainerFlex>
                            </ContainerFlex>
                            {listUsersFound.length >= 0 && rolId === rol.id && (
                                <ContainerFlex
                                    Height=""
                                    MaxH="200px"
                                    OverFlow="auto"
                                    FlexDir="column"
                                    backG="#fff"
                                    Position="absolute"
                                    Border="1px solid #F3F3F3"
                                    Width="calc(100% - 16px)"
                                    PositionLeft="50%"
                                    Transform="translate(-50%, 48px)"
                                    Justify="start"
                                    PositionTop="35px"
                                >
                                    {listUsersFound
                                        .filter((item) => item.userId !== Number(idUser))
                                        .map((user, index) => (
                                            <ContainerFlex
                                                key={index}
                                                Height="60px"
                                                Padding="12px 16px"
                                                Justify="space-between"
                                                Cursor="pointer"
                                                BoxBackground="#F3F3F3"
                                                FlexWrap="wrap"
                                                onClick={() => selectUser(user, rol.id)}
                                            >
                                                <Text
                                                    Color="#414042"
                                                    FontWeight="500"
                                                    Transform="capitalize"
                                                    Width="100%"
                                                    FontSize="0.875rem"
                                                    Height="1rem"
                                                    Cursor="pointer"
                                                >
                                                    {user.userName}
                                                </Text>
                                                <Text
                                                    Color="#a7a9ac"
                                                    Transform="capitalize"
                                                    Width="100%"
                                                    FontSize="0.75rem"
                                                    Height="1rem"
                                                    Cursor="pointer"
                                                >
                                                    {user.roles.map(
                                                        (role, index) =>
                                                            `${role.roleName}${
                                                                index === user.roles.length - 1
                                                                    ? ''
                                                                    : ' | '
                                                            }`
                                                    )}
                                                </Text>
                                            </ContainerFlex>
                                        ))}
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
                <ContainerFlex
                    Height="88px"
                    Border="solid 1px #e5e7e9"
                    Justify="start"
                    PaddingB="28px"
                    PaddingT="28px"
                >
                    <ContainerFlex Width="23rem" MarginL="23px">
                        <Checkbox />
                        <Text
                            Width="21.25rem"
                            Height="auto"
                            FontSize="0.875rem"
                            Color="#414042"
                            FontWeight="normal"
                            wSpace="wrap"
                            MarginLeft="8px"
                        >
                            Regresar roles al terminar periodo de vacaciones y eliminar personas
                            asignadas temporalmente.
                        </Text>
                    </ContainerFlex>
                    <ButtonGeneral
                        width=" 5.813rem"
                        text="Cancelar"
                        FontSize="0.875rem"
                        hColor="#35cf44"
                        transform=""
                        border="none"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        margin="0 0 0 87px"
                        clic={() => {
                            setModalStateManageRol(false);
                            setRolId(0);
                        }}
                    />
                    <ButtonGeneral
                        width="10.5rem"
                        text="Guardar y continuar"
                        FontSize="0.875rem"
                        transform=""
                        hBorder="#35cf44"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        margin="0 0 0 24px;"
                        clic={() => {
                            setModalStateManageRol(false);
                            setSelectedRoles(selectUserWithSubstituyes);
                            setRolId(0);
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
