import {
    CASH_NOTIFY,
    CASH_NOTIFY_SUCCESS,
    CASH_NOTIFY_ERROR,
    RESET,
} from '@ReduxCashFlowBasic/Types/TypesBranch';
const initialState = {
    deetailsFlowCash: {
        tokenExpiration: 0,
        succeeded: false,
        message: '',
        errors: '',
        token: '',
        data: {
            flowCashId: 0,
            historicId: 0,
            transferId: 0,
            destinationBranchId: 0,
            destinationBranch: '',
            creationDate: '',
            statusId: 0,
            statusName: '',
            nameNotification: '',
            ammount: 0,
        },
    },
    loading: false,
    error: false,
};

interface DeetailsFlowCash {
    tokenExpiration: number;
    succeeded: boolean;
    message: string;
    errors: unknown;
    token: string;
    data: {
        flowCashId: number;
        historicId: number;
        transferId: number;
        destinationBranchId: number;
        destinationBranch: string;
        creationDate: string;
        statusId: number;
        statusName: string;
        nameNotification: string;
        ammount: number;
    };
}

export interface INotifyCash {
    deetailsFlowCash: DeetailsFlowCash;
    loading: boolean;
    error: boolean;
}
const getFlowNotify = (state = initialState, action: {type: string; value: DeetailsFlowCash}) => {
    switch (action.type) {
        case CASH_NOTIFY:
            return {
                ...state,
                loading: true,
            };
        case CASH_NOTIFY_SUCCESS:
            return {
                ...state,
                deetailsFlowCash: action.value,
                loading: false,
            };
        case CASH_NOTIFY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
};

export default getFlowNotify;
