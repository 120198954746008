import axios from 'axios';
import {
    ADD_DATA_NEW_FOR_LEVEL_REQUEST,
    ADD_DATA_NEW_FOR_LEVEL_SUCCESS,
    ADD_DATA_NEW_FOR_LEVEL_ERROR,
    ID_SELECTED,
    BREAD_CRUMB,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import process from 'process';
import {URL} from '@config/constants/index';
export const PostNewleveCatalog = () => {
    return {
        type: ADD_DATA_NEW_FOR_LEVEL_REQUEST,
    };
};
export const getBreadCrumbs = (data) => {
    return {
        type: BREAD_CRUMB,
        payload: data,
    };
};

export const idSelected = (id) => {
    return {
        type: ID_SELECTED,
        payload: id,
    };
};

export const PostNewleveCatalogSuccess = (userData) => {
    return {
        type: ADD_DATA_NEW_FOR_LEVEL_SUCCESS,
        payload: userData,
    };
};

export const PostNewleveCatalogFailure = (err) => {
    return {
        type: ADD_DATA_NEW_FOR_LEVEL_ERROR,
        payload: err,
    };
};

export const PostNewLevel = (data, token) => {
    const headers = {
        accept: 'text/json',
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch) => {
        dispatch(PostNewleveCatalog());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostNewArticle}`,
                data,
                {
                    headers,
                }
            );
            dispatch(PostNewleveCatalogSuccess(response));
        } catch (error) {
            dispatch(PostNewleveCatalogFailure(error));
        }
    };
};
