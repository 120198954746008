import {
    GET_NEAR_BY_BRANCHES,
    GET_NEAR_BY_BRANCHES_SUCCESS,
    GET_NEAR_BY_BRANCHES_ERROR,
    CAT_EXCESS,
    CAT_EXCESS_SUCCESS,
    CAT_EXCESS_ERROR,
    BRANCH_SUCCESS,
} from '@ReduxsCashFlow/Types/Types';
import {INearBranches, INearBranchesReducer} from '@CashFlow/interfaces';

const initialState: INearBranches = {
    nearByBranches: [],
    catExcess: [],
    branch: '0',
    loading: true,
    error: false,
};

const nearByBranches = (state = initialState, action: INearBranchesReducer): INearBranches => {
    switch (action.type) {
        case GET_NEAR_BY_BRANCHES:
            return {
                ...state,
                loading: true,
            };
        case GET_NEAR_BY_BRANCHES_SUCCESS:
            return {
                ...state,
                nearByBranches: action.nearByBranches,
                loading: false,
            };
        case GET_NEAR_BY_BRANCHES_ERROR:
            return {
                ...state,
                error: true,
            };
        case CAT_EXCESS:
            return {
                ...state,
                loading: true,
            };
        case CAT_EXCESS_SUCCESS:
            return {
                ...state,
                catExcess: action.catExcess,
                loading: false,
            };
        case CAT_EXCESS_ERROR:
            return {
                ...state,
                error: true,
            };
        case BRANCH_SUCCESS:
            return {
                ...state,
                branch: action.branch,
            };
        default:
            return state;
    }
};
export default nearByBranches;
