export const GET_QUERYLOCATIONS_START = 'GET_QUERYLOCATIONS_START';
export const GET_QUERYLOCATIONS_SUCCESS = 'GET_QUERYLOCATIONS_SUCCESS';
export const GET_QUERYLOCATIONS_ERROR = 'GET_QUERYLOCATIONS_ERROR';

export const POST_ARTICLELOCATIONS_START = 'POST_ARTICLELOCATIONS_START';
export const POST_ARTICLELOCATIONS_SUCCESS = 'POST_ARTICLELOCATIONS_SUCCESS';
export const POST_ARTICLELOCATIONS_ERROR = 'POST_ARTICLELOCATIONS_ERROR';

export const TREE_LOCATIONS = {
    INITIAL: 'INITIAL',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const LOCATIONSID_START = 'LOCATIONSID_START';
export const LOCATIONSID_SUCCESS = 'LOCATIONSID_SUCCESS';
export const LOCATIONSID_ERROR = 'LOCATIONSID_ERROR';
export const BRANCH_NAME = 'BRANCH_NAME';
