import React from 'react';
import {ContainerFlex, Text, Modal, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {CUESTIONS, FUNDING} from '@/components/CashFundingModal/Operational/constants';

interface ModalCuestionsProps {
    isOpen: boolean;
    closeModal: () => void;
    openResoluctionModal: () => void;
    openFundingModal: () => void;
}

export const ModalCuestions = ({openResoluctionModal, openFundingModal}: ModalCuestionsProps) => {
    return (
        <>
            <Modal>
                <ContainerFlex
                    FlexDir="column"
                    Width="33.125rem"
                    Height="27.188rem"
                    Radius="24px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                    Padding="24px"
                >
                    <ContainerFlex FlexDir="column" Justify="start" Align="start">
                        <Text
                            FontSize="1.5rem"
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            MarginB="16px"
                        >
                            {CUESTIONS.TITTLE}
                        </Text>
                        <Text FontSize="1rem" FontWeight="400" Color="#54575C" FontFamily="Nunito">
                            {CUESTIONS.TEXT}
                        </Text>
                        <Text FontSize="1rem" FontWeight="400" Color="#54575C" FontFamily="Nunito">
                            {CUESTIONS.TEXT_ONE}
                        </Text>
                        <Text
                            FontSize="1rem"
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            MarginB="24px"
                        >
                            {CUESTIONS.TEXT_TWO}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column">
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text FontSize="1rem" FontWeight="500" Color="#54575C" MarginB="8px">
                                {CUESTIONS.DATE}
                            </Text>
                            <Text FontSize="1rem" FontWeight="400" Color="#54575C" MarginB="24px">
                                {CUESTIONS.DATE_COMPLETE}
                            </Text>
                        </ContainerFlex>

                        <ContainerCellGrid Padding="8px 16px" Column="16px">
                            <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F" Width="100%">
                                {CUESTIONS.BALANCE}
                            </Text>
                            <Text FontSize="0.875rem" FontWeight="400" Color="#54575C" Width="100%">
                                {CUESTIONS.BALANCE_CASH}
                            </Text>
                        </ContainerCellGrid>

                        <ContainerFlex Bb="1px solid #2A2C2F">
                            <ContainerCellGrid Padding="8px 16px" Column="16px">
                                <Text
                                    FontSize="0.875rem"
                                    FontWeight="500"
                                    FontFamily="Nunito"
                                    color="#2A2C2F"
                                    Width="100%"
                                >
                                    {CUESTIONS.FUND}
                                </Text>
                                <Text
                                    FontSize="0.875rem"
                                    FontWeight="400"
                                    FontFamily="Nunito"
                                    color="#54575C"
                                    Width="100%"
                                >
                                    {CUESTIONS.CASH_NEW}
                                </Text>
                            </ContainerCellGrid>
                        </ContainerFlex>

                        <ContainerFlex MarginBt="32px">
                            <ContainerCellGrid Padding="8px 16px" Column="16px">
                                <Text
                                    FontSize="0.875rem"
                                    FontWeight="400"
                                    color="#2A2C2F"
                                    Width="100%"
                                >
                                    {CUESTIONS.NEW}
                                </Text>
                                <Text FontSize="0.875rem" FontWeight="500" Width="100%">
                                    {FUNDING.CASH_NEW}
                                </Text>
                            </ContainerCellGrid>
                        </ContainerFlex>

                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                onClick={openFundingModal}
                            >
                                {CUESTIONS.INCORRECT}
                            </ButtonGenerals>
                            <ButtonGenerals Width="100%" onClick={openResoluctionModal}>
                                {CUESTIONS.CORRECT}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
