import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RootState, AppDispatch} from '@config/store';
import {UserData, UserSystemAccess} from '@components/ManageUser/Redux/interface';
import {putAccessToSystemAxios} from '@components/ManageUser/Redux/Actions/putAccessToSystemUserAction';
import {getAccessToSystemUserAxios} from '@components/ManageUser/Redux/Actions/getAccessToSystemUserAction';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';

export const AccessToSystem = () => {
    const {idUser} = useParams();
    const dispatch: AppDispatch = useDispatch();

    const accesToSystem: UserSystemAccess = useSelector(
        (state: RootState) => state.getAccessToSystemUser.data
    );
    const accessToSystemData: UserSystemAccess = useSelector(
        (state: RootState) => state.putAccessToSystemUser.data
    );
    const loading: boolean = useSelector((state: RootState) => state.putAccessToSystemUser.loading);
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const toggleAccess = (updateAccess: UserSystemAccess) => {
        const data = {
            ...updateAccess,
            employeeId: idUser,
        };
        dispatch(putAccessToSystemAxios(token, data));
    };

    useEffect(() => {
        if (idUser) dispatch(getAccessToSystemUserAxios(token, parseInt(idUser)));
    }, [accessToSystemData, dispatch, idUser, token]);

    return (
        <ContainerFlex
            Height="auto"
            Border="1px solid #E5E7E9"
            Radius="4px"
            Justify="space-between"
            FlexWrap="wrap"
        >
            <Text
                Height="56px"
                Width="100%"
                BorderBt="0.5px solid #E5E7E9"
                BorderR="4px"
                bGround="#F3F3F3"
                Color="#414042"
                Padding="16px"
                FontWeight="500"
            >
                Accesos al sistema
            </Text>
            <ContainerFlex
                Padding="16px"
                Height="168px"
                Display="grid"
                Justify="space-between"
                AlignContent="end"
                GridColumns="95% 5%"
                GridRows="repeat(4, 25%)"
            >
                <Text Color="#414042" FontSize="0.875rem">
                    Permitir acceso fuera de horario laboral
                </Text>
                <GreenSwitch
                    disabled={!UserInformation.statusUser || loading}
                    size="small"
                    checked={accesToSystem.outWorkingHours}
                    onChange={() => {
                        const updateAccess = {
                            ...accesToSystem,
                            outWorkingHours: !accesToSystem.outWorkingHours,
                        };
                        toggleAccess(updateAccess);
                    }}
                />

                <Text Color="#414042" FontSize="0.875rem">
                    Permitir acceso en dias de descanso
                </Text>

                <GreenSwitch
                    disabled={!UserInformation.statusUser || loading}
                    size="small"
                    checked={accesToSystem.accessOnRestPeriod}
                    onChange={() => {
                        const updateAccess = {
                            ...accesToSystem,
                            accessOnRestPeriod: !accesToSystem.accessOnRestPeriod,
                        };
                        toggleAccess(updateAccess);
                    }}
                />

                <Text Color="#414042" FontSize="0.875rem">
                    Permitir acceso durante periodos de vacaciones
                </Text>

                <GreenSwitch
                    disabled={!UserInformation.statusUser || loading}
                    size="small"
                    checked={accesToSystem.accessOnPeriodVacation}
                    onChange={() => {
                        const updateAccess = {
                            ...accesToSystem,
                            accessOnPeriodVacation: !accesToSystem.accessOnPeriodVacation,
                        };
                        toggleAccess(updateAccess);
                    }}
                />

                <Text Color="#414042" FontSize="0.875rem">
                    Permitir acceso durante periodos de permisos de ausencia
                </Text>

                <GreenSwitch
                    disabled={!UserInformation.statusUser || loading}
                    size="small"
                    checked={accesToSystem?.accessOndaysOff}
                    onChange={() => {
                        const updateAccess = {
                            ...accesToSystem,
                            accessOndaysOff: !accesToSystem.accessOndaysOff,
                        };
                        toggleAccess(updateAccess);
                    }}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
