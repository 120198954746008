import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {getPurityFile, postPurityFile} from '@ActionsPurity/PurityActions';

export function PurityFile() {
    const {
        handleSubmit,
        register,
        watch,

        formState: {errors},
    } = useForm({
        defaultValues: {
            kilatajes: [{name: 'martini'}],
            purezaFundidora: '0',
            purezaPrestalana: '0',
            purezaTerceria: '0',
            valorSeleccionado: '1',
            oroFinoCalculo: '0',
            comisionCalculo: '0',
            onzasCalculo: '0',
            SubtotalCalculo: '0',
            totalCalculo: '0',
            ImagenFundidora: '',
            ImagenPrestalana: '',
            ImagenTerceria: '',
        },
    });
    const {lingote} = useParams();
    const dispatch = useDispatch();
    const id = useSelector((state) => {
        return state.purity.id;
    });

    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    useEffect(() => {
        if (token && id) {
            const getSecurityBagWeightData = () => {
                dispatch(getPurityFile(String(id), String(lingote), token));
            };
            getSecurityBagWeightData();
        }
    }, [dispatch, id, lingote, token]);

    const watchAllFields = watch();
    const purityfile = useSelector((state) => {
        return state.purity.purityfile;
    });

    const onSubmitAudit = async (value) => {
        const formData = new FormData();
        formData.append('IdFundicion', id);
        formData.append('Lingote', lingote);
        formData.append('PurezaPrestalana', watchAllFields.purezaPrestalana);
        formData.append('PurezaFundidora', watchAllFields.purezaFundidora);
        formData.append('PurezaTerceria', watchAllFields.purezaTerceria);
        formData.append('PurezaSeleccionada', watchAllFields.valorSeleccionado);
        formData.append('GramosOroFino', watchAllFields.oroFinoCalculo);
        formData.append('OnzasOroFino', watchAllFields.onzasCalculo);
        formData.append('SubTotal', watchAllFields.SubtotalCalculo);
        formData.append('Comision', value.comisionCalculo);
        formData.append('Total', value.totalCalculo);
        formData.append('ImagenPurezaPrestalana', watchAllFields.ImagenPrestalana[0]);
        formData.append('ImagenPurezaFundidora', watchAllFields.ImagenFundidora[0]);
        formData.append('ImagenPurezaTerceria', watchAllFields.ImagenTerceria[0]);
        await dispatch(postPurityFile(formData, token));
        window.location.href = `/Pureza/${id}`;
    };

    return (
        <>
            {purityfile !== null ? (
                <>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <span className="child-breadcrumb">Fundiciones</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="child-breadcrumb">Pureza</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="principal-breadcrumb">No. Fundición {id}</span>
                    </div>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <form
                            className="flex container-form pl-4 mt-5"
                            onSubmit={handleSubmit(onSubmitAudit)}
                        >
                            <div className="flex justify-content-between w-100">
                                <span>
                                    Captura los resultados de la pureza y carga los archivos
                                    análisis
                                </span>
                            </div>
                            <div className="flex flex-wrap pt-3">
                                <div className="justify-content-between flex w-100">
                                    <span>
                                        <label>Quilate </label>
                                        {purityfile[0].kilatajes}
                                    </span>

                                    <span>
                                        <label>Peso</label>
                                        {purityfile[0].gramosProveedor}
                                    </span>

                                    <span>
                                        <label>Costo</label>
                                        {purityfile[0].costo}
                                    </span>

                                    <span>
                                        <label>% Comisión</label>
                                        {purityfile[0].porcentajeComision}
                                    </span>

                                    <span>
                                        <label>Precio onza</label>
                                        {purityfile[0].precioOnza}
                                    </span>

                                    <span>
                                        <label>Precio dolar</label>
                                        {purityfile[0].precioDolar}
                                    </span>
                                </div>

                                <div className="w-25 flex flex-column pr-5 pt-5">
                                    <label className="required">Pureza Fundidora</label>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('purezaFundidora', {
                                            required: 'Ingrese Precio Oro',
                                        })}
                                        id="purezaFundidora"
                                        style={{
                                            border: errors.purezaFundidora ? '1px solid red' : '',
                                        }}
                                    />
                                </div>
                                <div className="w-25 flex flex-column pr-5 pt-5">
                                    <label className="required">Pureza Prestalana</label>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('purezaPrestalana', {
                                            required: 'Ingrese Precio Oro',
                                        })}
                                        id="purezaPrestalana"
                                        style={{
                                            border: errors.purezaPrestalana ? '1px solid red' : '',
                                        }}
                                        aria-invalid={errors.purezaPrestalana ? 'true' : 'false'}
                                    />
                                </div>
                                <div className="w-25 flex flex-column pr-5 pt-5">
                                    <label>Pureza Terceria</label>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('purezaTerceria', {
                                            required: 'Ingrese Precio Oro',
                                        })}
                                        id="purezaTerceria"
                                        aria-invalid={errors.purezaTerceria ? 'true' : 'false'}
                                    />
                                </div>

                                <div className="w-25 flex flex-column pr-5 pt-5">
                                    <label className="required">Pureza Seleccionada</label>
                                    <select
                                        {...register('valorSeleccionado')}
                                        className="input-react-hook-select-table"
                                    >
                                        <option value="1">Fundidora</option>
                                        <option value="2">Prestalana</option>
                                        <option value="3">Terceria</option>
                                    </select>
                                </div>
                                <div className="w-50 flex pr-5 pt-5">
                                    <label className="w-25 required">Soporte Fundidora</label>
                                    <input
                                        className="input-react-hook-form"
                                        style={{
                                            border: errors.ImagenFundidora ? '1px solid red' : '',
                                        }}
                                        {...register('ImagenFundidora', {
                                            required: 'Ingrese imagen',
                                        })}
                                        id="ImagenFundidora"
                                        type="file"
                                    />
                                </div>
                                <div className="w-50 flex pr-5 pt-5">
                                    <label className="w-25 required">Soporte Prestalana</label>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('ImagenPrestalana', {
                                            required: 'Ingrese imagen',
                                        })}
                                        id="ImagenPrestalana"
                                        type="file"
                                        aria-invalid={errors.ImagenPrestalana ? 'true' : 'false'}
                                    />
                                </div>
                                <div className="w-50 flex pr-5 pt-5">
                                    <label className="w-30"> Soporte Terceria(Opcional)</label>
                                    <input
                                        className="input-react-hook-form"
                                        {...register('ImagenTerceria', {})}
                                        id="ImagenTerceria"
                                        type="file"
                                    />
                                </div>
                                <div className="w-10 flex flex-column pr-5 pt-4">
                                    <label> Oro fino</label>
                                    <input
                                        className="input-react-hook-label-table"
                                        {...register('oroFinoCalculo', {})}
                                        value={
                                            Number(watchAllFields.valorSeleccionado) === 1
                                                ? purityfile[0].gramosProveedor *
                                                  watchAllFields.purezaFundidora
                                                : Number(watchAllFields.valorSeleccionado) === 2
                                                ? purityfile[0].gramosProveedor *
                                                  watchAllFields.purezaPrestalana
                                                : purityfile[0].gramosProveedor *
                                                  watchAllFields.purezaTerceria
                                        }
                                    />
                                </div>
                                <div className="w-10 flex flex-column pr-5 pt-4">
                                    <label className="w-30">Onzas</label>
                                    <input
                                        className="input-react-hook-label-table"
                                        {...register('onzasCalculo', {})}
                                        value={Number(watchAllFields.oroFinoCalculo) / 31.1034768}
                                    />
                                </div>
                                <div className="w-10 flex flex-column pr-5 pt-4">
                                    <label className="w-30">Subtotal</label>
                                    <input
                                        className="input-react-hook-label-table"
                                        {...register('SubtotalCalculo', {})}
                                        value={
                                            watchAllFields.onzasCalculo * purityfile[0].precioOnza
                                        }
                                    />
                                </div>
                                <div className="w-10 flex flex-column pr-5 pt-4">
                                    <label className="w-30">Comision</label>
                                    <input
                                        className="input-react-hook-label-table"
                                        {...register('comisionCalculo', {})}
                                        value={
                                            (watchAllFields.SubtotalCalculo *
                                                purityfile[0].porcentajeComision) /
                                            100
                                        }
                                    />
                                </div>
                                <div className="w-10 flex flex-column pr-5 pt-4">
                                    <label className="w-30">Total</label>

                                    <input
                                        className="input-react-hook-label-table"
                                        {...register('totalCalculo', {})}
                                        value={
                                            watchAllFields.SubtotalCalculo -
                                            watchAllFields.comisionCalculo
                                        }
                                    />
                                </div>
                            </div>

                            <div className="flex justify-content-end w-100 mt-5">
                                <button className="w-20 pl-5 pr-5 btn-add-incidents h-40">
                                    GUARDAR
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
