import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {DeadLineModal} from '@CreateDeadline/DeadlineSteps/Deadlines/ModalDeadlines/DeadLineModal';
import {dealinesSimulators} from '@CreateDeadline/interfaces';

export const Example = (props: dealinesSimulators) => {
    const {openExample, setOpenExample, deadlinesGeneral, deadlineById} = props;

    return (
        <ContainerFlex
            Width="100%"
            Height="100%"
            Position="fixed"
            backG="rgba(167, 169, 172, 0.25)"
            Justify="center"
            Align="center"
            ZIndex="1"
        >
            <DeadLineModal
                openExample={openExample}
                setOpenExample={() => setOpenExample(false)}
                deadlinesGeneral={deadlinesGeneral}
                deadlineById={deadlineById}
            />
        </ContainerFlex>
    );
};
