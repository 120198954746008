import axios, {AxiosResponse} from 'axios';
import {
    GET_CAT_COUNTRY,
    GET_CAT_COUNTRY_ERROR,
    GET_CAT_COUNTRY_SUCCESS,
} from '@components/Branches/Redux/Types/types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetCatCountryStart = () => {
    return {
        type: GET_CAT_COUNTRY,
    };
};
export const fetchGetCatCountrySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_COUNTRY_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetCatCountryError = () => {
    return {
        type: GET_CAT_COUNTRY_ERROR,
    };
};

export function GetCatCountry(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCatCountryStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatCountry}`,
                {headers}
            );
            dispatch(fetchGetCatCountrySuccess(response));
        } catch (error) {
            dispatch(fetchGetCatCountryError());
        }
    };
}
