import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import FilterTag from '@/components/CreditsAndPawns/FilterModal/FilterTag';
import {setFiltersValues} from '@/components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
import {TAG_KEYS} from '@/components/CreditsAndPawns/constants';

const FilterTagValues = () => {
    const dispatch = useDispatch();
    const {filtersOptions, filtersValues} = useSelector((state: RootState) => {
        return state.getFilterListLoans;
    });

    const resetFilter = (key: string, startValue: unknown, endValue: unknown) => {
        dispatch(
            setFiltersValues({
                ...filtersValues,
                [key + TAG_KEYS.START]: startValue,
                [key + TAG_KEYS.END]: endValue,
            })
        );
    };

    const resetScore = () =>
        resetFilter(TAG_KEYS.SCORE, filtersOptions.scoreStart, filtersOptions.scoreEnd);
    const resetInteresAnual = () =>
        resetFilter(
            TAG_KEYS.INTERES_RATE,
            filtersOptions.interestAnualStart,
            filtersOptions.interestAnualEnd
        );
    const resetTotalAmount = () =>
        resetFilter(TAG_KEYS.AMOUNT, 0, filtersOptions.amontMaxCreditEnd);
    const resetRequestedDate = () => resetFilter(TAG_KEYS.REQUEST_DATE, null, null);
    const resetPaymentDate = () => resetFilter(TAG_KEYS.PAYMENT_DATE, null, null);

    const frequencyTags =
        filtersValues.Frequency.length > 0 &&
        filtersOptions.frequencyIds
            .filter((frequency) => filtersValues.Frequency.includes(frequency.id))
            .map((frequency) => (
                <FilterTag
                    key={frequency.id}
                    tagLabel={
                        filtersOptions.frequencyIds.find((f) => f.id === frequency.id)
                            ?.nombreFrecuencia ?? ''
                    }
                    handleFilter={() => {
                        dispatch(
                            setFiltersValues({
                                ...filtersValues,
                                Frequency: filtersValues.Frequency.filter(
                                    (f) => f !== frequency.id
                                ),
                            })
                        );
                    }}
                />
            ));

    return (
        <div>
            <FilterTag
                tagLabel={`Entre ${filtersValues.scoreStart} y ${filtersValues.scoreEnd}`}
                handleFilter={resetScore}
            />
            <FilterTag
                tagLabel={`Entre ${filtersValues.interestAnualStart}% y ${filtersValues.interestAnualEnd}%`}
                handleFilter={resetInteresAnual}
            />
            {frequencyTags}
            <FilterTag
                tagLabel={`Entre ${filtersValues.amountMaxCreditStart.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })} y ${filtersValues.amontMaxCreditEnd.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`}
                handleFilter={resetTotalAmount}
            />
            {filtersValues.requestedDateStart && (
                <FilterTag
                    tagLabel={`${filtersValues.requestedDateStart?.toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })} a ${filtersValues.requestedDateEnd?.toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}`}
                    handleFilter={resetRequestedDate}
                />
            )}
            {filtersValues.paymentDateStart && (
                <FilterTag
                    tagLabel={`${filtersValues.paymentDateStart?.toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })} a ${filtersValues.paymentDateEnd?.toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}`}
                    handleFilter={resetPaymentDate}
                />
            )}
        </div>
    );
};

export default FilterTagValues;
