import axios from 'axios';
import {URL} from '@config/constants/index';

import {
    GET_ASSIGNMENT_TYPES,
    GET_ASSIGNMENT_TYPES_SUCCESS,
    GET_ASSIGNMENT_TYPES_ERROR,
} from '@TypesAuditAssignment/AssignmentTypesTypes';
import {AppDispatch} from '@/config/store';

export const fetchGetAssignmentTypes = () => {
    return {
        type: GET_ASSIGNMENT_TYPES,
    };
};
export const fetchGetAssignmentTypesSuccess = (result: {data: unknown}) => {
    return {
        type: GET_ASSIGNMENT_TYPES_SUCCESS,
        value: result.data,
    };
};

export const fetchGetAssignmentTypesError = () => {
    return {
        type: GET_ASSIGNMENT_TYPES_ERROR,
    };
};
export const getAssignmentTypes = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAssignmentTypes());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentTypes}`, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetAssignmentTypesSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetAssignmentTypesError());
            });
    };
};
