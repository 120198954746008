import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@/config/store';
import {IBreadcrumbItem} from '@components/General/Interfaces/IBreadcrumb';
import arrowIndex from '@components/Branches/Images/ArrowBackIndex.svg';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import Breadcrumb from '@components/General/Moleculs/Breadcrumb/Index';
import {CreateBranchModal} from '@components/Branches/CreateBranchModal/CreateBranchModal';
import {IndexTree} from '@components/Branches/BranchIndex/IndexTree';
import {TableBranchHeader} from '@components/Branches/TableBranches/TableBranchHeader';
import {TableBranch} from '@components/Branches/TableBranches/TableBranch';
import {EmptyBranches} from '@components/Branches/BranchIndex/EmptyBranches';
import {GetAllBranches} from '@components/Branches/Redux/Actions/GetAllBranches';
import {getTreeComponent} from '@General/BranchTree/Redux/Actions/getOrganizationsAction';
import {SearchInput} from '@Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {GetSearchBranches} from '@components/Branches/Redux/Actions/GetSearchBranches';
import {IBranchItems} from '@components/Branches/interfaces';
import {Highlight} from '@components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';

export const Branches = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/', label: 'Inicio'},
        {to: '#/', label: 'Sucursales'},
    ];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceName as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const totalItems = useSelector((state: RootState) => {
        return state.getAllBranches.tableBranches?.message;
    });
    const totalPages = useSelector((state: RootState) => {
        return state.getAllBranches.tableBranches?.totalPages;
    });
    const {loading} = useSelector((state: RootState) => {
        return state.getAllBranches;
    });
    const loadingUpdate = useSelector((state: RootState) => {
        return state.PutUpdateBranchStatus.loading;
    });
    const searchData = useSelector((state: RootState) => {
        return state.getSearchBranches.searchBranches?.data;
    });
    const [search, setSearch] = useState(false);
    const [searchedValue, setSearchValue] = useState('');
    const [iconChange, setIconChange] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [createModal, setCreateModal] = useState(false);
    const [filters, setFilters] = useState({
        companyId,
        branchName: '',
        pageSize: 9,
        pageNumber: 1,
        orderType: 0,
        filterBranch: 0,
    });
    const [filterSearch, setFilterSearch] = useState({
        companyId,
        branchName: '',
        pageSize: 40,
        pageNumber: 1,
        orderType: 0,
        filterBranch: 0,
    });
    const handleIntersect = useCallback(() => {
        setCurrentPage((prev: number) => prev + 1);
    }, []);
    useEffect(() => {
        if (currentPage <= totalPages)
            setFilters({
                ...filters,
                pageSize: 9 * currentPage,
            });
    }, [currentPage]);
    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));
    }, []);
    useEffect(() => {
        dispatch(GetAllBranches(filters, token));
    }, [filters, loadingUpdate]);
    useEffect(() => {
        if (filterSearch.branchName.length > 2) dispatch(GetSearchBranches(filterSearch, token));
    }, [filterSearch]);
    return (
        <ContainerFlex
            Padding="1.625rem 1.5rem"
            Height="auto"
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex Justify="flex-start">
                <ImgLocation
                    src={arrowIndex}
                    Width="1.5rem"
                    Height="1.5rem"
                    Margin="0 12px 0 0"
                    Cursor="pointer"
                    onClick={() => navigate('/')}
                />
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>
            <CreateBranchModal createModal={createModal} setCreateModal={setCreateModal} />
            <ContainerFlex Height="auto" PaddingT="24px" Justify="flex-start" Align="flex-start">
                <IndexTree />
                <ContainerFlex
                    Width="100%"
                    Justify="flex-start"
                    FlexDir="column"
                    PaddingL="18px"
                    Gap={totalItems > 0 ? '' : '38px'}
                >
                    <Text FontSize="0.875rem" TextAlign="start" Width="100%" Color="#414042">
                        Sucursales disponibles para:
                        <Text FontWeight="500" Padding="0 0 0 6px" FontSize="0.875rem">
                            {companyName}
                        </Text>
                    </Text>
                    {Number(totalItems) > 0 && (
                        <ContainerFlex Justify="start" FlexDir="column">
                            <ContainerFlex Justify="start">
                                <ContainerFlex
                                    Margin="24px 0 0 0"
                                    Padding="0 0 16px 0"
                                    Justify="start"
                                >
                                    <SearchInput
                                        Width="20.37rem"
                                        Height="40px"
                                        Position="relative"
                                        GridColumn="90% 10%"
                                        BorderH="1px solid #35CF44"
                                        BoxShadowH=""
                                        BorderF="2px solid #35CF44"
                                        BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                                        Margin="0 0 0 16px"
                                    >
                                        <input
                                            value={filterSearch.branchName}
                                            placeholder="Buscar por nombre de la sucursal"
                                            onChange={(e: {target: {value: string}}) => {
                                                setFilterSearch({
                                                    ...filters,
                                                    branchName: e.target.value,
                                                });
                                                setSearchValue(e.target.value);
                                                if (e.target.value.length >= 3) {
                                                    setSearch(true);
                                                    setIconChange(true);
                                                }
                                                if (e.target.value.length < 3) {
                                                    setSearch(false);
                                                    setIconChange(false);
                                                }
                                            }}
                                        />
                                        {!iconChange ? (
                                            <IconContainer Width="100%" className="material-icons">
                                                search
                                            </IconContainer>
                                        ) : (
                                            <IconContainer
                                                Width="100%"
                                                className="material-icons"
                                                onClick={() => {
                                                    setFilters({
                                                        ...filters,
                                                        branchName: '',
                                                    });
                                                    setFilterSearch({
                                                        ...filterSearch,
                                                        branchName: '',
                                                    });
                                                    setIconChange(false);
                                                    setSearch(false);
                                                }}
                                            >
                                                close
                                            </IconContainer>
                                        )}
                                        {search && (
                                            <ContainerFlex
                                                Height="auto"
                                                FlexDir="column"
                                                backG="#fff"
                                                Position="absolute"
                                                PositionTop="100%"
                                                Border="1px solid #F3F3F3"
                                                Justify="start"
                                                ZIndex="2"
                                                Radius="4px"
                                            >
                                                <ContainerFlex
                                                    Height={
                                                        searchData?.length < 5 ? 'auto' : '200px'
                                                    }
                                                    OverFlowY="scroll"
                                                    FlexWrap="wrap"
                                                >
                                                    {searchData?.map(
                                                        (item: IBranchItems, index: number) => (
                                                            <ContainerFlex
                                                                key={index}
                                                                Justify="start"
                                                                Padding="0 16px"
                                                                Height="40px"
                                                                backG="#fff"
                                                                BoxBackground="#f3f3f3"
                                                                Cursor="pointer"
                                                                onClick={() => {
                                                                    setFilterSearch({
                                                                        ...filterSearch,
                                                                        branchName: item.branchName,
                                                                    });
                                                                    setFilters({
                                                                        ...filterSearch,
                                                                        branchName: item.branchName,
                                                                    });
                                                                    setSearch(false);
                                                                }}
                                                            >
                                                                <Highlight
                                                                    text={item.branchName}
                                                                    highlight={searchedValue}
                                                                />
                                                            </ContainerFlex>
                                                        )
                                                    )}
                                                </ContainerFlex>
                                                {searchData?.length > 5 && (
                                                    <ContainerFlex
                                                        Justify="start"
                                                        Height="40px"
                                                        backG="#fff"
                                                        Padding="0 16px"
                                                        Bt="1px solid #F3F3F3"
                                                    >
                                                        <Text
                                                            FontSize="0.875rem"
                                                            Color="#414042"
                                                            Padding="0 6px 0 0"
                                                        >{`${
                                                            searchData?.length - 5
                                                        } resultados más de`}</Text>
                                                        <Text
                                                            FontSize="0.875rem"
                                                            FontWeight="700"
                                                            Color="#414042"
                                                        >{`"${filterSearch.branchName}"`}</Text>
                                                    </ContainerFlex>
                                                )}
                                                {searchData?.length === 0 && (
                                                    <ContainerFlex
                                                        Justify="start"
                                                        Height="40px"
                                                        backG="#fff"
                                                        Padding="0 16px"
                                                        Bt="1px solid #F3F3F3"
                                                    >
                                                        <Text FontSize="0.875rem" Color="#414042">
                                                            {'No se encontraron coincidencias'}
                                                        </Text>
                                                    </ContainerFlex>
                                                )}
                                            </ContainerFlex>
                                        )}
                                    </SearchInput>
                                </ContainerFlex>
                                <ContainerFlex
                                    Width="auto"
                                    Margin="12px 0 0 0"
                                    onClick={() => setCreateModal(!createModal)}
                                >
                                    <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                                        Crear sucursal
                                    </Text>
                                    <ImgLocation
                                        src={nextArrow}
                                        Width="0.875rem"
                                        Height="0.875rem"
                                        Margin="0 0 0 4px"
                                        Cursor="pointer"
                                    />
                                </ContainerFlex>
                            </ContainerFlex>
                            <TableBranchHeader filters={filters} setFilters={setFilters} />
                        </ContainerFlex>
                    )}
                    {loading ? (
                        <ContainerFlex Width="100%" Height="auto">
                            <LoadingGeneral />
                        </ContainerFlex>
                    ) : Number(totalItems) > 0 ? (
                        <TableBranch handleIntersect={handleIntersect} />
                    ) : (
                        <EmptyBranches createModal={createModal} setCreateModal={setCreateModal} />
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
