import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '@/config/store';

import {MY_COMPANY} from '@MyCompany/constants';
import {TaxCompany} from '@MyCompany/TaxCompany';
import {CompanyData} from '@MyCompany/CompanyData';
import {PrivacityCompany} from '@MyCompany/PrivacityCompany';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {detailsCompany, privacyCompany, taxCompany} from '@MyCompany/Redux/Actions/DetailsCompany';

export const Company = () => {
    const dispatch: AppDispatch = useDispatch();
    const {loading} = useSelector((state: RootState) => state.detailsCompany);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    useEffect(() => {
        if (token) {
            dispatch(detailsCompany(token, companyId));
            dispatch(taxCompany(token, companyId));
            dispatch(privacyCompany(token, companyId));
        }
    }, []);

    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            Padding="1rem 1.5rem"
            Gap="1rem"
            FlexDir="column"
            backG="#fafafa"
            Height="auto"
        >
            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                {MY_COMPANY.MY_ENTERPRICE}
            </Text>
            {loading ? (
                <ContainerFlex Height="70vh">
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <CompanyData />
                    <TaxCompany />
                    <PrivacityCompany />
                </>
            )}
        </ContainerFlex>
    );
};
