import {GET_GROPUPS_COMPANY} from '@components/LocationsBranch/Redux/types';
import {IGroupState, IGroupActions} from '@components/LocationsBranch/Redux/interfaces';

const initialState: IGroupState = {
    loading: false,
    groups: null,
    error: false,
};

const getGroupsByCompanyId = (state = initialState, action: IGroupActions): IGroupState => {
    switch (action.type) {
        case GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                groups: action.payload,
                error: false,
            };
        case GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getGroupsByCompanyId;
