import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import searchIcon from '@images/search.svg';
import clearIcon from '@images/close.svg';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {SearchInput} from '@Customer/Styles';
import IconPersonAdd from '@images/iconPersonAdd.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ShoppingCartCards} from '@components/ShoppingCart/shoppingCartCards';
import {SearchClient} from '@components/ShoppingCart/SearchClient';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    addClientList,
    errorClient,
    getClientList,
    resetSearchClient,
    selectedClient,
} from '@components/ShoppingCart/Redux/Actions/SearchClientList';
import {IEndorsement} from '@components/ShoppingCart/Redux/interfaces';
import {IClientData, IClientList} from '@components/ShoppingCart/Redux/interfaces';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {cartContracts} from '@components/ShoppingCart/interface';
import {restarQuoter} from '@Quoter/Redux/Actions/saveEndeavors';
import Renewals from '@components/ShoppingCart/Renewals';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

export const ShoppingCart = () => {
    const {register, watch, reset, setValue} = useForm();
    const navigate = useNavigate();
    const searchValue = watch('search') || '';
    const [displaySearchList, setDisplayList] = useState(false);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const dispatch = useDispatch();

    const saveEndeavor = useSelector((state: RootState) => state.saveEndeavor);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {client, emptyClient}: IClientData = useSelector((state: RootState) => state.clientList);
    const cardShooping = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.carShoopingId as number;
    });
    const contracts: cartContracts[] = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const getInputIcon = () => (searchValue.length > 0 ? clearIcon : searchIcon);
    const handleClearSearch = () => {
        setValue('search', '');
        setDisplayList(false);
        dispatch(resetSearchClient());
    };
    const selectClient = (data: IClientList) => {
        if (token) {
            const AddClient = {
                clientId: data.id,
                carShoppingId: cardShooping,
            };
            dispatch(selectedClient(data));
            dispatch(addClientList(AddClient, token));
            setDisplayList(false);
            dispatch(errorClient(false));
        }
    };

    const handleSearch = (value: string) => {
        setValue('search', value);
        const sendSearch = {
            companyId: companyId,
            searchName: value,
        };

        if (value.length > 2 && token) {
            setDisplayList(true);
            dispatch(getClientList(sendSearch, token));
        }
    };

    const handleEmptyCart = async () => {
        if (token)
            await dispatch(
                DeleteTemporalyPawns(token, {deleteAllPawns: true, operationType: 1}, carShoppingId)
            );
        navigate('/');
        dispatch(restarQuoter());
    };

    useEffect(() => {
        if (searchValue.length <= 2 && client.name.length === 0) {
            dispatch(resetSearchClient());
            reset();
        }
    }, [searchValue, reset]);

    const calculateTotalPass = (endorsements: IEndorsement[]): number => {
        return endorsements.reduce((total: number, endorsement: IEndorsement) => {
            return total + (endorsement?.pass?.totalPass ?? 0);
        }, 0);
    };

    const allLoans =
        contracts && contracts.length > 0
            ? contracts.reduce((acc: number[], contract) => {
                  return acc.concat(contract.pledges.map((pledge) => pledge.articleLoan));
              }, [])
            : [];

    const totalLoan = allLoans.reduce((acc, value) => acc + value, 0);

    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const allArticleCount =
        cartData?.pawns?.reduce((total, pawn) => {
            return total + pawn.pledges.length;
        }, 0) || 0;

    const formattedTotalLoan =
        totalLoan !== undefined ? formatterDolar.format(totalLoan * -1) : '$0.00';

    const totalPassSum: number = calculateTotalPass(cartData?.endorsements || []);
    return (
        <FlexPanel
            Justify="space-between"
            Background="#F4F5F5"
            BoxShadow=" 0px 0px 3px 0px rgba(167, 169, 172, 0.25)"
            FlexDir="column"
            Width="25rem"
        >
            <FlexPanel FlexDir="column" Align="center" Gap="1rem" Width="25rem">
                <FlexPanel FlexDir="column" Align="flex-start" Background="#FFF" Width="100%">
                    <ContainerFlex
                        Padding="0.5rem 1.5rem"
                        Justify="space-between"
                        Align="center"
                        Self="stretch"
                        Bb="1px solid #E8E9EA"
                        Height="2.813rem"
                    >
                        <Text Color="#2A2C2F" FontSize="1.25rem" FontWeight="700">
                            {SHOPPING_CART.Title}
                        </Text>
                        <Text
                            Color="#5A5AFF"
                            FontSize="1rem"
                            FontWeight="700"
                            Cursor="pointer"
                            LetterSpacing="0.019rem"
                            onClick={() => handleEmptyCart()}
                        >
                            {SHOPPING_CART.Empty_Cart}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="0.5rem 1.5rem"
                        Align="center"
                        Self="stretch"
                        Bb="1px solid #E8E9EA"
                        Height="4.5rem"
                        Gap="1rem"
                        Position="relative"
                    >
                        {client && client.name.length > 0 && (
                            <SearchInput
                                Height="2.5rem"
                                GridColumn="90% 10%"
                                FontSize="1rem"
                                Width="84%"
                                Right="none"
                            >
                                <Text Color="#5A5AFF" FontSize="1rem" FontWeight="500">
                                    {client.name}
                                </Text>
                                {saveEndeavor.actualStep !== 3 && (
                                    <Image
                                        src={clearIcon}
                                        alt="close"
                                        Cursor="pointer"
                                        Width="1.5rem"
                                        Height="1.5rem"
                                        onClick={() => {
                                            dispatch(resetSearchClient());
                                            reset();
                                        }}
                                    />
                                )}
                            </SearchInput>
                        )}
                        {client && client.name.length === 0 && (
                            <SearchInput
                                Height="2.5rem"
                                GridColumn="90% 10%"
                                FontSize="1rem"
                                Width="81%"
                                Right="none"
                                error={emptyClient}
                                PlaceholderColor={emptyClient ? '#ef4f55' : '#A7A9AC'}
                            >
                                <input
                                    placeholder="Vincular cliente"
                                    {...register('search', {
                                        onChange: (e) => handleSearch(e.target.value),
                                    })}
                                />
                                <Image
                                    src={getInputIcon()}
                                    alt="icon-magnifier"
                                    Cursor="pointer"
                                    Width="1.5rem"
                                    Height="1.5rem"
                                    onClick={() => searchValue.length > 0 && handleClearSearch()}
                                />
                            </SearchInput>
                        )}

                        {displaySearchList && (
                            <SearchClient setDisplay={setDisplayList} selectClient={selectClient} />
                        )}
                        <ContainerImg
                            Height="40px"
                            Width="40px"
                            Border="1px solid #5A5AFF"
                            Radius="50%"
                        >
                            <Image src={IconPersonAdd} alt="icon-warning" Cursor="pointer" />
                        </ContainerImg>
                    </ContainerFlex>
                </FlexPanel>
                <Renewals />
                <FlexPanel OverFlowY="scroll" OverFlowX="hidden" Width="100%">
                    <ShoppingCartCards />
                </FlexPanel>
            </FlexPanel>
            <FlexPanel
                FlexDir="column"
                Align="flex-start"
                Width="25rem"
                Radius="1rem 1rem 0 0"
                Background="#FFF"
                BoxS="0px -3px 26.6px 0px rgba(0, 0, 0, 0.06)"
            >
                <ContainerFlex
                    Padding="0.5rem 1.5rem"
                    FlexDir="column"
                    Align="flex-start"
                    Gap="0.25rem"
                    Self="stretch"
                    Bb="1px solid #E8E9EA"
                >
                    <ContainerFlex Justify="space-between" Align='="center' Self="stretch">
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {SHOPPING_CART.SUBTOTAL}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {numberToCurrency(Number(cartData?.subTotal))}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between" Align='="center' Self="stretch">
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {SHOPPING_CART.PARTIAL_PAYMENT}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {numberToCurrency(Number(totalPassSum))}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between" Align='="center' Self="stretch">
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {SHOPPING_CART.IVA}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {numberToCurrency(Number(cartData?.iva))}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Height="2.5rem"
                    Padding="0.5rem 1.5rem"
                    Justify="space-between"
                    Bb="1px solid #E8E9EA"
                    Align="center"
                >
                    <Text Color="#1D1E20" FontSize="1rem" FontWeight="700" LetterSpacing="0.019rem">
                        {allArticleCount} articulos(s)
                    </Text>
                    <ContainerFlex Width="11.594rem" Justify="flex-end" Align="center" Gap="0.5rem">
                        <Text
                            Color="#1D1E20"
                            FontStyle="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {SHOPPING_CART.Total}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            FontStyle="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {formattedTotalLoan}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Height="3.5rem"
                    Padding="0.5rem 1.5rem"
                    Bb="1px solid #E8E9EA"
                    Align="center"
                >
                    <ButtonGeneral
                        width="100%"
                        text={SHOPPING_CART.VIEW_SUMMARY}
                        cursor="pointer"
                    />
                </ContainerFlex>
            </FlexPanel>
        </FlexPanel>
    );
};
