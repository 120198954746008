import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_APPROVED_CREDIT_SOLICITUDE_ERROR,
    GET_APPROVED_CREDIT_SOLICITUDE_START,
    GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IApprovedCreditSolicitudeReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: IApprovedCreditSolicitudeReducer = {
    data: null,
    loading: false,
    error: false,
};

const getDetailsCredit = (
    state: IApprovedCreditSolicitudeReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IApprovedCreditSolicitudeReducer => {
    switch (action.type) {
        case GET_APPROVED_CREDIT_SOLICITUDE_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_APPROVED_CREDIT_SOLICITUDE_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getDetailsCredit;
