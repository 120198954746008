import styled from '@emotion/styled';
import {IProgressBar} from '@components/Home/stylesInterfaces';
import React from 'react';

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 16px;
    background-color: #f4f5f5;
    border-radius: 148px;
    overflow: hidden;
`;

const ProgressBarFill = styled.div<IProgressBar>`
    width: ${(props) => props.progress}%;
    height: 100%;
    background: linear-gradient(to right, #5a5aff, #e5e5ff);
`;
interface IProges {
    progress: number;
}
const ProgressBarComponent = ({progress}: IProges) => {
    return (
        <ProgressBarContainer>
            <ProgressBarFill progress={progress} />
        </ProgressBarContainer>
    );
};

export default ProgressBarComponent;
