import perfile from '@/components/MyCompanyUser/Operational/img/03.png';
import perfile2 from '@/components/MyCompanyUser/Operational/img/Avatar.png';
import perfile3 from '@/components/MyCompanyUser/Operational/img/Avatar1.png';
import perfile4 from '@/components/MyCompanyUser/Operational/img/avatar2.png';
import perfile5 from '@/components/MyCompanyUser/Operational/img/Avatar3.png';
import {OptionType} from './interfaceType';

export const TEXT_USER = {
    TITLE: 'Usuarios',
    BTN_PRIMARY: 'Ir a roles',
    BTN_SECUNDARY: 'Nuevo usuario',
    OP_FIRST: 'Todos',
    OP_SECOND: 'Administrativos',
    OP_THIRD: 'P.O.S',
    FILTER: 'Filtrar por:',
    FILTERT: 'Limpiar filtros',

    NO_DATA: 'No hay datos disponibles',
    FILTER_BTN: 'Filtrar',

    Home: 'Inicio',
    Company: 'Mi empresa',
    user: 'Usuarios',
};

export const header = ['Nombre de usuario', 'Rol', 'Accesos', 'Ultimo inicio de sesión', 'Estado'];

export const user = [
    {
        name: 'Omar Mangin',
        role: 'Super Admin',
        access: ['Admin', 'Pos'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile2,
    },
    {
        name: 'Rogelio Molina Calva',
        role: 'Valuador',
        access: ['Pos'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile5,
    },
    {
        name: 'Luis Fernando Hernandez',
        role: 'Valuador',
        access: ['Pos'],
        lastAccessDate: '12/07/2024',
        isActive: false,
        image: perfile2,
    },
    {
        name: 'Ricardo Galindo Luna',
        role: 'Auditor',
        access: ['Pos'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile3,
    },
    {
        name: 'Roberto Carlos',
        role: 'Gerente',
        access: ['Admin'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile,
    },
    {
        name: 'Luis Fernando',
        role: 'Gerente',
        access: ['Pos'],
        lastAccessDate: '12/07/2024',
        isActive: false,
        image: perfile4,
    },

    {
        name: 'Roberto Martínez',
        role: 'Supervisor',
        access: ['Admin', 'Pos'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile,
    },
    {
        name: 'Patricia Fernández',
        role: 'Admin',
        access: ['Admin', 'Pos'],
        lastAccessDate: '12/07/2024',
        isActive: true,
        image: perfile4,
    },
    {
        name: 'Fernando García',
        role: 'Gerente',
        access: ['Admin', 'Pos'],
        lastAccessDate: '12/07/2024',
        isActive: false,
        image: perfile,
    },
];

export const MOD_FILTER = {
    TITLE: 'Filtrar por',
    INDICATION: 'Filtra por tipo de usuario',
    ACCESS: 'Accesos',
    STATE: 'Estado',
    BTN: 'Calcelar',
    BTNS: 'Continuar',
};

export const data_options: OptionType[] = [
    {value: 'ALL', label: 'Todo'},
    {label: 'Activo', value: 'Activo'},
    {label: 'Inactivo', value: 'Inactivo'},
];

export const data_type = [
    {value: 'ALL', label: 'Todo'},
    {label: 'Admin', value: 'Admin'},
    {label: 'Pos', value: 'Pos'},
    {label: 'Eliminado', value: 'Eliminado'},
];
export const styles = {
    Admin: {
        color: '#000061',
        border: '1px solid #ACACFF',
        backgroundColor: '#E5E5FF',
    },
    Pos: {
        color: '#174A2E',
        border: '1px solid #B1E7C9',
        backgroundColor: '#E4F7EC',
    },
};
