import React from 'react';
import {ContainerFlex, Text, Image, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {SearchListProps, UserAccess, UserRoles, UserRolesItem} from '@Roles/interface';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {useSelector} from 'react-redux';
import {ASSINGROLE_TITLE} from '@Roles/constants';

const UserSearchList: React.FC<SearchListProps> = ({checkedItems, handleCheckboxChange}) => {
    const RolesUsers = useSelector((state: RootState) => state.GetEmployeeUserSearch?.rolesSearch);
    return (
        <>
            <ContainerFlex
                Display="grid"
                GridColumns="0.46fr 1fr repeat(2, 0.8fr)"
                Justify="start"
                Padding="0.5rem"
                Height="1.875rem"
                MaxH="1.875rem"
                Bb="1px solid #E8E9EA"
            >
                {ASSINGROLE_TITLE.map((item, index) => (
                    <Text
                        Color="#2A2C2F"
                        FontWeight="500"
                        FontSize="0.875rem"
                        key={index}
                        GridColumnsStart={`${index + 2}`}
                    >
                        {item}
                    </Text>
                ))}
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Gap="0.5rem"
                OverFlow="auto"
                Height="9rem"
            >
                {RolesUsers &&
                    RolesUsers.map((item: UserRolesItem) => (
                        <ContainerFlex
                            key={item.userId}
                            Display="grid"
                            GridColumns="0.1fr 1fr repeat(2, 0.8fr)"
                            Justify="start"
                            Height="auto"
                        >
                            <ContainerFlex>
                                <Checkbox
                                    checked={checkedItems.includes(item.userId)}
                                    onChange={() => handleCheckboxChange(item.userId)}
                                />
                                <Image
                                    src={item.image}
                                    Width="32px"
                                    Height="32px"
                                    Radius="50%"
                                    ObjectFit="cover"
                                    Margin="0 1.5rem"
                                />
                            </ContainerFlex>
                            <TextEllipsis
                                Color="#2A2C2F"
                                FontSize="0.875rem"
                                FontWeight="400"
                                Width="auto"
                                Padding="0 0.5rem 0 0"
                            >
                                {item.userName}
                            </TextEllipsis>
                            <ContainerFlex
                                FlexDir="column"
                                Align="start"
                                Justify="start"
                                Width="auto"
                                Height="auto"
                            >
                                {item.rolUsers.map((role: UserRoles) => (
                                    <TextEllipsis
                                        key={role.rolId}
                                        Color="#2A2C2F"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                        Width="auto"
                                    >
                                        {role.rolName}
                                    </TextEllipsis>
                                ))}
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Align="start"
                                Justify="start"
                                Width="auto"
                                Height="auto"
                            >
                                {item.accessUsers.map((access: UserAccess) => (
                                    <TextEllipsis
                                        Color="#2A2C2F"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                        Width="auto"
                                        key={access.accessId}
                                    >
                                        {access.accessName}
                                    </TextEllipsis>
                                ))}
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}
            </ContainerFlex>
        </>
    );
};

export default UserSearchList;
