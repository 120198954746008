export const ReviewRejectText = {
    Review: 'Revisar',
    Reject: 'Rechazar',
};
export const RequestTableText = {
    Requests: 'Solicitudes',
};
export const tableTitle = [
    {tableTitleText: 'Solicitante'},
    {tableTitleText: 'Nivel 1'},
    {tableTitleText: 'Nivel 2'},
    {tableTitleText: 'Nivel 3'},
    {tableTitleText: 'Marca'},
    {tableTitleText: 'Artículo'},
    {tableTitleText: 'Documentos'},
];
