import {GET_ALL_FORM, GET_ALL_FORM_ADDRESS} from '@components/Users/Redux/types';
import {IPersonalInfo} from '@components/Users/UserProfile/interfaces';
import {IContactValues} from '@components/Users/UserProfile/yupSchema';
export interface IScreenFormPersonalInfo {
    data: IPersonalInfo;
    address: IContactValues;
}
const initialState: IScreenFormPersonalInfo = {
    data: {
        name: '',
        lastName: '',
        otherLastName: '',
        date: '',
        gender: {label: '', value: 0},
        email: '',
        otherEmail: '',
        cel: '',
        otherCelphone: '',
    },
    address: {
        postal: 0,
        street: '',
        outsideNumber: '',
        insideNumber: '',
        colony: '',
        town: '',
        state: '',
    },
};

const ScreenStateUsersForm = (
    state = initialState,
    action: {type: string; payload: number; form: IPersonalInfo; formAddress: IContactValues}
) => {
    switch (action.type) {
        case GET_ALL_FORM:
            return {
                data: action.form,
            };
        case GET_ALL_FORM_ADDRESS:
            return {
                address: action.formAddress,
            };

        default:
            return state;
    }
};
export default ScreenStateUsersForm;
