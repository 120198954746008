import {AxiosError, AxiosResponse} from 'axios';
import {
    SEND_CONFIRMATION_VOUCHER_FAIL,
    SEND_CONFIRMATION_VOUCHER_START,
    SEND_CONFIRMATION_VOUCHER_SUCCESS,
    SEND_CONFIRMATION_VOUCHER_RESET,
} from '@/components/QuickPayment/Redux/Types/Types';

const initialState = {
    emailSend: false,
    loading: false,
    error: false,
};

export const sendConfirmationVoucher = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case SEND_CONFIRMATION_VOUCHER_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SEND_CONFIRMATION_VOUCHER_SUCCESS:
            return {
                emailSend: action.payload.data.data,
                loading: false,
                error: false,
            };
        case SEND_CONFIRMATION_VOUCHER_FAIL:
            return {
                ...state,
                emailSend: false,
                error: true,
            };
        case SEND_CONFIRMATION_VOUCHER_RESET:
            return {
                emailSend: false,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};
