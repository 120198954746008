import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import arrowUpIcon from '@images/arrowUpIcon.svg';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {CART_MESSAGES} from '@Loan/PaymentQuoter/constants';
import {ContractDetail} from '@/components/Loan/PaymentQuoter/ContractDetail';
import {ContainerFlex, Text, Image, ContainerImg} from '@Shopify/Ecommerce/styles';
import {IClienCarDataMapping} from '@/components/Shopify/Products/redux/interface';
import {FlexPanel} from '@/components/Shopify/ClientProfile/Styles/styles';

const ContractCart = ({
    contractsList,
    allowDetailExpand = true,
    folio,
    contractKey,
    lastItem,
    endorsementsPay,
    pawnsPay,
    dischargesPay,
}: IClienCarDataMapping) => {
    const [showDetail, setShowDetail] = useState(false);
    const branchId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.branchId
    );

    const handleToggleDetailClicked = () => setShowDetail(!showDetail);

    return contractsList ? (
        <>
            <FlexPanel
                Width="100%"
                Justify="start"
                Align="start"
                Bb="1px solid #E8E9EA"
                FlexDir="column"
                Padding="1rem 0.5rem"
                backG={showDetail ? '#F0F0FF' : !allowDetailExpand ? '#fff' : '0'}
                Gap="0.5rem"
                Radius={
                    (!allowDetailExpand && !lastItem) || contractKey === 0
                        ? '0.5rem 0.5rem 0 0'
                        : '0'
                }
                Border={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
            >
                <ContainerFlex
                    Width="5rem"
                    backG="#FFE5FB"
                    Border="1px solid #FFC6F6"
                    Padding="0.125rem 0.5rem"
                    Radius="2rem"
                >
                    <Text FontSize="0.75rem" Color="#610052" FontWeight="500">
                        {CART_MESSAGES.CONTRACT} {contractsList.contractNumber}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="start" ColumnGap="0.5rem" Gap="0.5rem">
                    <ContainerImg Radius="0.5rem" Border="1px solid #E8E9EA">
                        <Image
                            src={contractsList.pledge[0].articlec}
                            alt="cart-item"
                            Height="2.625rem"
                            Width="2.625rem"
                            AspectRatio="1"
                            ObjectFit="contain"
                        />
                    </ContainerImg>
                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                        <Text FontWeight="500" Color="#2A2C2F">
                            {contractsList.numberItems}
                        </Text>
                        <Text FontWeight="400" Color="#54575C">
                            {CART_MESSAGES.LOAN}
                            <Text FontWeight="500" Color="#2A2C2F" Padding="0 0.5rem">
                                {formatterDolar.format(contractsList.totalLoan)}
                            </Text>
                        </Text>
                    </ContainerFlex>
                    {allowDetailExpand && (
                        <ContainerFlex
                            ColumnGap="0.5rem"
                            Justify="end"
                            Align="start"
                            onClick={handleToggleDetailClicked}
                        >
                            <Text Color="#5A5AFF" FontSize="1rem" FontWeight="500" Cursor="pointer">
                                {showDetail ? 'Ocultar' : 'Mostrar'}
                            </Text>
                            <Image
                                src={showDetail ? arrowUpIcon : arrowDownIcon}
                                Height="1.5rem"
                                Width="1.5rem"
                            />
                        </ContainerFlex>
                    )}
                    {!allowDetailExpand && (
                        <ContainerFlex
                            ColumnGap="0.5rem"
                            Justify="end"
                            Align="start"
                            Color="#54575C"
                        >
                            {CART_MESSAGES.FOLIO}:{' '}
                            {folio ? folio : `${branchId}C${contractsList.contractNumber}`}
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </FlexPanel>
            {(showDetail || !allowDetailExpand) &&
                contractsList.pledge.map((data, index) => {
                    const isLastItem = index === contractsList.pledge.length - 1;
                    return (
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            ColumnGap="0.5rem"
                            Gap="0.5rem"
                            Padding="0 1rem"
                            key={index}
                            backG={!allowDetailExpand ? '#FAFAFA' : '#f5f4f4'}
                            Bb={allowDetailExpand ? '1px solid #e3e3e3' : 'none'}
                            Bl={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
                            Br={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
                            PaddingB="0.438rem"
                            Radius={
                                !allowDetailExpand && lastItem && isLastItem
                                    ? ' 0 0 0.5rem 0.5rem'
                                    : 'none'
                            }
                            Border={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
                        >
                            <ContainerImg Radius="0.5rem" Border="1px solid #E8E9EA">
                                <Image
                                    src={data.articlec}
                                    alt="cart-item"
                                    Height="2.625rem"
                                    Width="2.625rem"
                                    AspectRatio="1"
                                    ObjectFit="contain"
                                />
                            </ContainerImg>
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Gap="0.5rem"
                            >
                                <Text FontWeight="500" Color="#2A2C2F">
                                    {data.articleName}
                                </Text>
                                <Text FontWeight="400" Color="#54575C">
                                    {CART_MESSAGES.VALUE}
                                    <Text FontWeight="500" Color="#2A2C2F" Padding="0 0.5rem">
                                        {formatterDolar.format(data.value)}
                                    </Text>
                                </Text>
                                <Text FontWeight="400" Color="#54575C">
                                    {CART_MESSAGES.LOAN}
                                    <Text FontWeight="500" Color="#2A2C2F" Padding="0 0.5rem">
                                        {formatterDolar.format(data.loan)}
                                    </Text>
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    );
                })}
        </>
    ) : (
        <ContractDetail
            endorsementsPay={endorsementsPay}
            pawnsPay={pawnsPay}
            dischargesPay={dischargesPay}
        />
    );
};

export default ContractCart;
