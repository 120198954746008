import {AxiosError, AxiosResponse} from 'axios';
import {
    EMPLOYMENT_SITUATION_START,
    EMPLOYMENT_SITUATION_SUCCESS,
    EMPLOYMENT_SITUATION_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataSituationProps} from '@components/PersonalLoans/interfaces';

const initialState: IDataSituationProps = {
    dataEmployment: null,
    loading: false,
    error: false,
    errorData: null,
};

const GetAllCatEmploymentSituation = (
    state: IDataSituationProps = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataSituationProps => {
    switch (action.type) {
        case EMPLOYMENT_SITUATION_START:
            return {...state, loading: true, error: false};
        case EMPLOYMENT_SITUATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dataEmployment: action.payload.data.data,
            };
        case EMPLOYMENT_SITUATION_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default GetAllCatEmploymentSituation;
