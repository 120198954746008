import {
    GET_SCHEDULES_ERROR,
    GET_SCHEDULES_START,
    GET_SCHEDULES_SUCCESS,
    GET_HOLIDAYS_ERROR,
    GET_HOLIDAYS_START,
    GET_HOLIDAYS_SUCCESS,
    RESET_SCHEDULES,
} from '@components/LocationsBranch/Redux/types';
import {ICreateGroupBranch, ICreateGroupBranchRedux} from '@components/LocationsBranch/interfaces';

const initialState: ICreateGroupBranch = {
    holidays: [],
    schedule: [],
    error: false,
    loading: false,
};

export const getCreateGroupBranch = (
    state = initialState,
    action: ICreateGroupBranchRedux
): ICreateGroupBranch => {
    switch (action.type) {
        case GET_HOLIDAYS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.holidays,
                loading: false,
            };
        case GET_HOLIDAYS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_SCHEDULES_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_SCHEDULES_SUCCESS:
            return {
                ...state,
                schedule: action.schedule,
                loading: false,
            };
        case GET_SCHEDULES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case RESET_SCHEDULES:
            return {
                ...state,
                schedule: [],
            };
        default:
            return state;
    }
};
