import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_ALL_CAT_BANK_ERROR,
    GET_ALL_CAT_BANK_START,
    GET_ALL_CAT_BANK_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

const getAllCatBankStart = () => {
    return {
        type: GET_ALL_CAT_BANK_START,
    };
};

const getAllCatBankSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_CAT_BANK_SUCCESS,
        payload: result,
    };
};

const getAllCatBankError = (error: AxiosError) => {
    return {
        type: GET_ALL_CAT_BANK_ERROR,
        error: error,
    };
};

function getAllCatBankAction(token: string) {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatBank}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getAllCatBankStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getAllCatBankSuccess(response.data));
        } catch (e) {
            dispatch(getAllCatBankError(e as AxiosError));
        }
    };
}

export default getAllCatBankAction;
