import axios, {AxiosResponse} from 'axios';
import {
    PUT_EXPENSE_AUTH,
    PUT_EXPENSE_AUTH_SUCCESS,
    PUT_EXPENSE_AUTH_ERROR,
} from '@components/CashFlowExpenseDetails/operational/constants';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IAuthExpense} from '@components/CashFlowExpenseDetails/operational/interface';

export const putAuthExpense = () => ({
    type: PUT_EXPENSE_AUTH,
});

export const putAuthExpenseSuccess = (result: AxiosResponse) => ({
    type: PUT_EXPENSE_AUTH_SUCCESS,
    payload: result.data,
});

export const putAuthExpenseError = () => ({
    type: PUT_EXPENSE_AUTH_ERROR,
});

export function putAuthExpenseAction(token: string, data: IAuthExpense) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(putAuthExpense());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutExpenseAuth}`,
                data,
                {headers}
            );
            dispatch(putAuthExpenseSuccess(response));
            return response;
        } catch (error) {
            dispatch(putAuthExpenseError());
            throw error;
        }
    };
}
