import {
    GET_CAT_STATE,
    GET_CAT_STATE_ERROR,
    GET_CAT_STATE_RESET,
    GET_CAT_STATE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    catState: [],
};

const GetCatState = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_CAT_STATE:
            return {
                ...state,
                loading: true,
            };
        case GET_CAT_STATE_SUCCESS:
            return {
                ...state,
                catState: action.payload.data,
                loading: false,
            };
        case GET_CAT_STATE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_CAT_STATE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetCatState;
