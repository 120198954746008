import {URL} from '@config/constants/index';

import axios from 'axios';
import {
    GET_DATA_CONTACT_PONZU_START,
    GET_DATA_CONTACT_PONZU_SUCCESS,
    GET_DATA_CONTACT_PONZU_ERROR,
} from '@MyCompany/Redux/Types/types';
import {AppDispatch} from '@/config/store';

export const fetchGetDataContactPonzuStart = () => {
    return {
        type: GET_DATA_CONTACT_PONZU_START,
    };
};

export const fetchGetDataContactPonzuSuccess = (result: {data: unknown}) => {
    return {
        type: GET_DATA_CONTACT_PONZU_SUCCESS,
        payload: result.data,
    };
};

export const fetchGetDataContactPonzuError = () => {
    return {
        type: GET_DATA_CONTACT_PONZU_ERROR,
    };
};

export function GetDataContactPonzu(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetDataContactPonzuStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDataContactPonzu}`,
                {headers}
            );
            dispatch(fetchGetDataContactPonzuSuccess(response));
        } catch (error) {
            dispatch(fetchGetDataContactPonzuError());
        }
    };
}
