import styled from '@emotion/styled';
import {IDivisor, IFormInput} from '@/components/QuickPayment/interfaces';

export const Input = styled.input<IFormInput>`
    width: 283px;
    border-radius: 32px;
    border: 1px solid ${(props) => (props.error ? '#FF6357' : '#E8E9EA')};
    padding: 0 16px;
    height: 40px;
    color: ${(props) => (props.error ? '#FF6357' : '#1D1E20')};
`;

export const CheckImage = styled.img`
    width: 55px;
    height: 55px;
`;

export const Divisor = styled.div<IDivisor>`
    margin: ${(props) => props.margin};
    background: #e8e9ea;
    height: 1px;
    width: 100%;
`;

export const FormLabel = styled.label`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #1d1e20;
    font-family: 'Nunito';
`;
