import {AxiosError, AxiosResponse} from 'axios';
import {IDataUser} from '@components/CreateUsers/interfaces';
import {
    ADD_ROLES_START,
    ADD_ROLES_SUCCESS,
    ADD_ROLES_ERROR,
    CREATE_USER_ERROR,
    CREATE_USER_START,
    CREATE_USER_SUCCESS,
    CREATE_USER_RESET,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState: IDataUser = {
    userData: null,
    dataRoles: null,
    loading: false,
    error: false,
    errorData: null,
};

const CreateDataUser = (
    state: IDataUser = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataUser => {
    switch (action.type) {
        case CREATE_USER_START:
            return {...state, loading: true, error: false};
        case CREATE_USER_SUCCESS:
            return {...state, loading: false, error: false, userData: action.payload.data};
        case CREATE_USER_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case ADD_ROLES_START:
            return {...state, loading: true, error: false};
        case ADD_ROLES_SUCCESS:
            return {...state, loading: false, error: false, dataRoles: action.payload.data};
        case ADD_ROLES_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case CREATE_USER_RESET:
            return initialState;
        default:
            return state;
    }
};
export default CreateDataUser;
