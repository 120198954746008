import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {schema} from '@components/CreateUsers/validationsYup';
import {RootState, AppDispatch} from '@config/store';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import ButtonGeneral from '@General/Atoms/Button';
import {
    GetOptionsNames,
    resetOptionsUserNames,
} from '@components/CreateUsers/Redux/Actions/GetListUserNames';
import {GetUserName, resetGetUserName} from '@components/CreateUsers/Redux/Actions/GetUserName';
import {FormCreateUser} from '@components/CreateUsers/Interface';
import {postCreateUsers} from '@components/CreateUsers/Redux/Actions/GetAllUsers';
import {GetEmailInUse} from '@components/CreateUsers/Redux/Actions/GetEmailInUse';
import {GetPhoneInUse} from '@components/CreateUsers/Redux/Actions/GetPhoneInUse';
import {Level} from '@General/BranchTree/interfaces';
import {AdditionalInformation} from '@components/CreateUsers/Form/AdditionalInformation';
import {EnterName} from '@components/CreateUsers/Form/EnterName';
import {EnterUserAndOrganization} from '@components/CreateUsers/Form/EnterUserAndOrganization';
import {EnterJobPositionAndRol} from '@components/CreateUsers/Form/EnterJobPositionAndRol';
import {EnterPassword} from '@components/CreateUsers/Form/EnterPassword';
import {
    GetPasswordRandom,
    ResetRandomPassword,
} from '@components/CreateUsers/Redux/Actions/PostChangePassword';
import {
    resetDataForm,
    updateDataForm,
} from '@components/CreateUsers/Redux/Actions/dataFormCreateUserAction';
import {getTreeComponent} from '@/components/General/BranchTree/Redux/Actions/getOrganizationsAction';

export const CreateUsersForm = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/', label: 'Inicio'},
        {to: '#/Users', label: 'Usuarios'},
        {to: '#/Users/CreateUsers', label: 'Crear usuario'},
    ];
    const optionsUserNames = useSelector((state: RootState) => state.GetOptionsUserNames.contact);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const usernameAvailable = useSelector(
        (state: RootState) => state.GetUserName.usernameAvailable
    );
    const phoneAvailable = useSelector((state: RootState) => state.GetPhoneInUse.phoneAvailable);
    const emailAvailable = useSelector((state: RootState) => state.GetEmailInUse.emailAvailable);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const listOrganizations: Level[] = useSelector(
        (state: RootState) => state.getOrganizationsTree.data
    );
    const dataFormCreateUSer = useSelector((state: RootState) => state.dataFormCreateUserForm);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors, isValid, dirtyFields},
        trigger,
        getValues,
        getFieldState,
        control,
    } = useForm<FormCreateUser>({
        defaultValues: dataFormCreateUSer,
        resolver: yupResolver(schema),
    });
    const inputName = watch('names');
    const inputLastName = watch('surnaNames');
    const userName = getValues('userName');
    const switchEmail = getValues('aditionalInformationEmployee.startSesionEmail');
    const switchPhone = getValues('aditionalInformationEmployee.startSesionPhone');
    const isAditionalInformation = getValues(
        'aditionalInformationEmployee.manageAditionalInformation'
    );
    const email = getValues('aditionalInformationEmployee.email');
    const phone = getValues('aditionalInformationEmployee.phone');
    const levelsBranch = getValues('levelBranch');
    const listEmployeeRoles = getValues('employeeRolees');
    const [modalStateOrg, setModalStateOrg] = useState(false);
    const [modalStateRol, setModalStateRol] = useState(false);
    const RandomPassword = useSelector((state: RootState) => state.PostChangePass.datapass);
    const getUserOptions = () => {
        if (inputName && inputLastName) {
            dispatch(GetOptionsNames(token, inputName, inputLastName));
            dispatch(GetPasswordRandom(token));
        }
    };
    const onSubmit = (values: FormCreateUser) => {
        dispatch(postCreateUsers({...values, companyId: companyId}, token));
        navigate('/Users');
        dispatch(resetDataForm());
        dispatch(resetGetUserName());
        dispatch(getTreeComponent(token, String(companyId)));
    };
    const saveChangesForm = () => {
        const dataForm = getValues();
        dispatch(updateDataForm(dataForm));
    };
    useMemo(() => {
        if (listOrganizations) {
            const levelBranch = listOrganizations.map((level) => ({
                branchId: level.idLevel,
                nameBranch: level.levelName,
            }));
            setValue('levelBranch', levelBranch);
        }
    }, [listOrganizations, setValue]);
    useMemo(() => {
        if (optionsUserNames.length > 1) setValue('userName', optionsUserNames[0].userName);
        trigger('userName');
    }, [optionsUserNames, setValue, trigger]);

    useMemo(() => {
        if (userName) dispatch(GetUserName(token, companyId, userName));
    }, [companyId, dispatch, token, userName]);
    useMemo(() => {
        if (email && email.match('@') && email.match('.com') && email.length > 10)
            dispatch(GetEmailInUse(token, companyId, email));
    }, [companyId, dispatch, email, token]);

    useMemo(() => {
        if (phone && phone.length === 10) dispatch(GetPhoneInUse(token, companyId, phone));
    }, [companyId, dispatch, phone, token]);
    useEffect(() => {
        if (RandomPassword) {
            setValue('password', RandomPassword);
            trigger('password');
        }
    }, [RandomPassword, trigger, setValue]);
    const CancelForm = () => {
        navigate(-1);
        dispatch(resetOptionsUserNames());
        dispatch(ResetRandomPassword());
        dispatch(resetDataForm());
        dispatch(resetGetUserName());
    };
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    return (
        <ContainerFlex
            Height="auto"
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
            Padding="26px 24px"
        >
            <Breadcrumb items={itemsBreadcrumb} />
            <ContainerFlex
                Height="auto"
                PaddingT="24px"
                Justify="center"
                Align="center"
                FlexDir="column"
            >
                <Text FontWeight="500" FontSize="1.25rem" Color="#414042">
                    Crear usuario
                </Text>
                <ContainerFlex MarginTop="40px" Width="624px" FlexDir="column">
                    <EnterName register={register} />
                    <EnterUserAndOrganization
                        getUserOptions={getUserOptions}
                        levelsBranch={levelsBranch}
                        register={register}
                        setModalStateOrg={setModalStateOrg}
                        setValue={setValue}
                        usernameAvailable={usernameAvailable}
                        modalStateOrg={modalStateOrg}
                        trigger={trigger}
                    />

                    <EnterJobPositionAndRol
                        listEmployeeRoles={listEmployeeRoles}
                        register={register}
                        setModalStateRol={setModalStateRol}
                        inputLastName={inputLastName}
                        inputName={inputName}
                        modalStateRol={modalStateRol}
                        setValue={setValue}
                        trigger={trigger}
                        saveChangesForm={saveChangesForm}
                    />
                    <EnterPassword
                        errorTextPassword={errors.password?.message}
                        errorTextRePassword={errors.repeatPassword?.message}
                        register={register}
                        trigger={trigger}
                    />
                    <AdditionalInformation
                        register={register}
                        setValue={setValue}
                        trigger={trigger}
                        textErrorEmail={errors.aditionalInformationEmployee?.email?.message}
                        textErrorPhone={errors.aditionalInformationEmployee?.phone?.message}
                        inValidEmail={getFieldState('aditionalInformationEmployee.email').invalid}
                        isPhoneTouched={dirtyFields.aditionalInformationEmployee?.phone}
                        switchEmail={switchEmail}
                        switchPhone={switchPhone}
                        emailAvailable={emailAvailable}
                        phoneAvailable={phoneAvailable}
                        control={control}
                        isAditionalInformation={isAditionalInformation}
                    />
                    <ContainerFlex MarginTop="24px">
                        <ButtonGeneral
                            width=" 5.813rem"
                            text="Cancelar"
                            FontSize="0.875rem"
                            hColor="#35cf44"
                            transform=""
                            border="none"
                            bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                            hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                            clic={CancelForm}
                        />
                        <ButtonGeneral
                            width="10.5rem"
                            text="Guardar y continuar"
                            FontSize="0.875rem"
                            transform=""
                            hBorder="#35cf44"
                            hColor="#35cf44"
                            bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                            margin="0 0 0 24px;"
                            clic={handleSubmit(onSubmit)}
                            disabled={
                                !isValid || usernameAvailable || emailAvailable || phoneAvailable
                            }
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
