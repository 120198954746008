import {AppDispatch} from '@/config/store';
import {
    GET_CAT_FAMILY,
    GET_CAT_FAMILY_ERROR,
    GET_CAT_FAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {getSubFamilyAction} from '@components/RequestNewCatalogLevel/Redux/Actions/GetSubFamilyGroupAction';
import {updateFamilyChildren} from '@components/RequestNewCatalogLevel/Redux/Actions/GetCatGroupAction';
import {Quantity} from '@/hooks/currentFormatUtils';

export const getFamily = () => {
    return {
        type: GET_CAT_FAMILY,
    };
};

export const getFamilySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_FAMILY_SUCCESS,
        payload: result,
    };
};

export const getFamilyError = (error: AxiosError) => {
    return {
        type: GET_CAT_FAMILY_ERROR,
        payload: error,
    };
};

export const getFamilyAction = (groupId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getFamily());
        const params = {
            groupId: groupId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetFamilyManager}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getFamilySuccess(response));
            const families = response.data.data;
            dispatch(updateFamilyChildren(groupId, families));
            for (const family of families) {
                const QuantityNumber = Quantity(String(family.nameFamily));
                if (QuantityNumber && QuantityNumber > 0)
                    dispatch(getSubFamilyAction(family.id, token));
            }
        } catch (error) {
            dispatch(getFamilyError(error as AxiosError));
        }
    };
};
