import {
    PUT_EXCESS,
    PUT_EXCESS_SUCCESS,
    PUT_EXCESS_ERROR,
    STATUS_INVALIDATE,
    STATUS_INVALIDATE_SUCCESS,
    STATUS_INVALIDATE_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';
const initialState = {
    info: [],
    aprove: false,
    error: false,
};
const putAprovedExcess = (state = initialState, action: {type: string; value: AxiosResponse}) => {
    switch (action.type) {
        case PUT_EXCESS:
            return {
                ...state,
                loading: true,
            };
        case PUT_EXCESS_SUCCESS:
            return {
                ...state,
                aprove: action.value,
                loading: false,
            };
        case PUT_EXCESS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case STATUS_INVALIDATE:
            return {
                ...state,
                loading: true,
            };
        case STATUS_INVALIDATE_SUCCESS:
            return {
                ...state,
                aprove: action.value,
                loading: false,
            };
        case STATUS_INVALIDATE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default putAprovedExcess;
