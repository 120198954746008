import {useDispatch} from 'react-redux';
import React, {useState} from 'react';
import {AppDispatch} from '@/config/store';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import editIcon from '@components/PersonalLoans/NewLoanProduct/icons/editIcon.svg';
import deleteIcon from '@components/PersonalLoans/NewLoanProduct/icons/deleteIcon.svg';
import editIconBlue from '@components/PersonalLoans/NewLoanProduct/icons/editIconBlue.svg';
import {
    deleteCharge,
    showEditButtons,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {IEditDeleteChargeButtonProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const EditDeleteChargeButton: React.FC<IEditDeleteChargeButtonProps> = ({
    id,
    setChargeState,
    chargeState,
    isOnlyEditing,
}) => {
    const [editIconSrc, setEditIconSrc] = useState(editIcon);
    const dispatch: AppDispatch = useDispatch();
    return (
        <ContainerFlex
            FlexDir="column"
            Width="143px"
            BoxShadow="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
            Radius="0.5rem"
            backG="#FFF"
            Border="1px solid #F3F3F3"
            Height="max-content"
            PositionRight="24px"
            MarginTop="5px"
            ZIndex="1"
        >
            <ContainerFlex
                Justify="start"
                Height="40px"
                Padding="0.5rem"
                Gap="0.5rem"
                Cursor="pointer"
                HBorderLeft="2px solid #5A5AFF"
                HBackground="#F0F0FF"
                HColor="#5A5AFF"
                onClick={() => {
                    if (isOnlyEditing) {
                        setChargeState({
                            ...chargeState,
                            isCreating: false,
                            showCreateCharge: false,
                            showDefaultEditCharge: true,
                        });
                    } else {
                        setChargeState({
                            ...chargeState,
                            isCreating: false,
                            showCreateCharge: true,
                            showDefaultEditCharge: false,
                        });
                    }
                    dispatch(showEditButtons(id));
                }}
                onMouseEnter={() => setEditIconSrc(editIconBlue)}
                onMouseLeave={() => setEditIconSrc(editIcon)}
            >
                <Image src={editIconSrc} Width="20px" Height="20px" alt="edit-icon" />
                <Text Color="#2A2C2F" Cursor="pointer">
                    {CHARGES_AND_PENALTIES.BTN_EDIT}
                </Text>
            </ContainerFlex>
            {!isOnlyEditing && (
                <ContainerFlex
                    Justify="start"
                    Height="40px"
                    Padding="0.5rem"
                    Gap="0.5rem"
                    Cursor="pointer"
                    HBorderLeft="2px solid #5A5AFF"
                    HBackground="#F0F0FF"
                    HColor="#5A5AFF"
                    onClick={() => {
                        dispatch(deleteCharge());
                    }}
                >
                    <Image src={deleteIcon} Width="20px" Height="20px" alt="delete-icon" />
                    <Text Color="#A82424" Cursor="pointer">
                        {CHARGES_AND_PENALTIES.BTN_DELETE}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
