export const NEWFEATURE = {
    TITLE: 'Nueva característica',
    DESC: 'Los características dinámicas son elementos que pueden cambiar dependiendo del estado de un producto. Por ejemplo, opciones de tamaño como chico, mediano o grande, de los que el cliente puede seleccionar.',
    NAME: 'Nombre',
    TYPE: 'Tipo',
    MANDATORYFEATURE: 'Característica obligatoria',
    PRINTETTIQUERE: 'Incluir en etiqueta impresa',
};

export const formNewFeature = {
    featureName: '',
    articleType: '',
    mandatoryFeature: false,
    printEtiquette: false,
};

export const DESACTIVATE_LEVEL = {
    TITLE: 'Desactivar nivel',
    DESCRIPTIONS: {
        DESCRIPTION_1: 'El nivel será desactivado inmediatamente.',
        DESCRIPTION_2: 'Notificaremos a todos los clientes sobre el cambio.',
    },
};

export const ACTIVATE_LEVEL = {
    TITLE: 'Activar nivel',
    DESCRIPTIONS: {
        DESCRIPTION_1: 'El nivel será activado inmediatamente.',
        DESCRIPTION_2: 'Notificaremos a todos los clientes sobre el cambio.',
    },
};

export const DESACTIVATE_ARTICLE = {
    TITLE: 'Desactivar artículo',
    DESCRIPTIONS: {
        DESCRIPTION_1: 'El artículo será desactivado en 72 horas hábiles.',
        DESCRIPTION_2: 'Notificaremos a todos los clientes sobre el cambio.',
    },
};

export const ACTIVATE_ARTICLE = {
    TITLE: 'Activar artículo',
    DESCRIPTIONS: {
        DESCRIPTION_1: 'El artículo será activado inmediatamente.',
        DESCRIPTION_2: 'Notificaremos a todos los clientes sobre el cambio.',
    },
};
