export const GET_ORGANIZATION_TREE_SUCCESS = 'GET_ORGANIZATION_TREE_SUCCESS';
export const GET_ORGANIZATION_TREE_ERROR = 'GET_ORGANIZATION_TREE_ERROR';
export const GET_BRANCH = 'GET_BRANCH';

export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';

export const GET_TREEBRANCHS_SUCCESS = 'GET_TREEBRANCHS_SUCCESS';
export const GET_TREEBRANCHS_ERROR = 'GET_TREEBRANCHS_ERROR';
export const GET_TREEBRANCHS = 'GET_TREEBRANCHS';
