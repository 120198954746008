import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    ADD_CATALOG_ARTICLE,
    ADD_CATALOG_ARTICLE_ERROR,
    ADD_CATALOG_ARTICLE_SUCCESS,
} from '@Shopify/Products/redux/types/ProductsShopifyTypes';
import {AppDispatch} from '@/config/store';
import {IAddCatalog} from '@Quoter/inferfaces';
import {FieldValues} from 'react-hook-form';
export const postCatalogArticleRequest = () => {
    return {
        type: ADD_CATALOG_ARTICLE,
    };
};
export const postCatalogArticleRequestSuccess = (result: AxiosResponse<string>) => {
    return {
        type: ADD_CATALOG_ARTICLE_SUCCESS,
        payload: result.data,
    };
};
export const postCatalogArticleRequestError = (value: unknown) => {
    return {
        type: ADD_CATALOG_ARTICLE_ERROR,
        payload: value,
    };
};
export function AddCart(data: FieldValues) {
    return async (dispatch: AppDispatch) => {
        dispatch(postCatalogArticleRequest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb}`,
                data,
                {}
            );
            await dispatch(postCatalogArticleRequestSuccess(response));
        } catch (error) {
            dispatch(postCatalogArticleRequestError(error));
        }
    };
}
export function addArticleRequest(
    token: string,
    data: IAddCatalog,
    setView: (e: boolean) => void,
    setSuccesMesssage: React.Dispatch<React.SetStateAction<boolean>>
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postCatalogArticleRequest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GenerateCarId}`,
                data,
                {headers}
            );
            await dispatch(postCatalogArticleRequestSuccess(response));
            setView(false);
            setSuccesMesssage(true);
        } catch (error) {
            dispatch(postCatalogArticleRequestError(error));
        }
    };
}
