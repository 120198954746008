import React from 'react';
import {TableSecond} from '@AuditAssignment/styles';
import {DateTime} from 'luxon';

interface NewType {
    sku: string;
    fechaEmpeño: Date;
    familia: string;
    subfamilia: string;
    kilataje: string;
    costo: number;
}
export function RowAuctionTable(props: NewType) {
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const {sku, fechaEmpeño, familia, subfamilia, kilataje, costo} = props;
    return (
        <tbody>
            <TableSecond cursor={true} hover={true}>
                <td>{sku}</td>
                <td>{DateTime.fromJSDate(fechaEmpeño).toLocaleString(DateTime.DATE_MED)}</td>
                <td>{familia}</td>
                <td>{subfamilia}</td>
                <td>{kilataje}K</td>
                <td>{formatterDolar.format(costo)}</td>
            </TableSecond>
        </tbody>
    );
}
