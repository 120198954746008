import React from 'react';
import {ContainerFlex, ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import IconHelp from '@images/helpCircleNeutral.svg';
import IconHAdd from '@images/IconAddPlus.svg';
import {TABS} from '@components/Tabs/constants';

export const Tabs = () => {
    return (
        <ContainerFlex Justify="start" backG="#F4F5F5" Height="36px" Gap="1px">
            <ContainerFlex
                Width="auto"
                Gap="8px"
                Padding="8px 24px"
                Bt="1.5px solid #5A5AFF"
                backG="#FFF"
                Cursor="pointer"
            >
                <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer">
                    {TABS.Title_First_Tab}
                </Text>
                <ContainerImg>
                    <Image src={IconHelp} />
                </ContainerImg>
            </ContainerFlex>
            <ContainerFlex
                Width="auto"
                Gap="8px"
                Padding="8px 24px"
                Bt="1.5px solid #D4D6D8"
                backG="#FFF"
                Cursor="pointer"
            >
                <ContainerImg>
                    <Image src={IconHAdd} />
                </ContainerImg>
            </ContainerFlex>
        </ContainerFlex>
    );
};
