export const GET_EMPLOYE_BY_BRANCH_CASH_FUNDING = 'GET_EMPLOYE_BY_BRANCH_CASH_FUNDING';
export const GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_SUCCESS =
    'GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_SUCCESS';
export const GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_ERROR = 'GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_ERROR';
export const RESET_EMPLOYE_DATA = 'RESET_EMPLOYE_DATA';

export const POST_CREATE_FUNDING = 'POST_CASH_FUNDING';
export const POST_CREATE_FUNDING_SUCCESS = 'POST_CASH_FUNDING_SUCCESS';
export const POST_CREATE_FUNDING_ERROR = 'POST_CASH_FUNDING_ERROR';

export const POST_ADD_FLOW = 'POST_ADD_FLOW';
export const POST_ADD_FLOW_SUCCESS = 'POST_ADD_FLOW_SUCCESS';
export const POST_ADD_FLOW_ERROR = 'POST_ADD_FLOW_ERROR';

export const GET_RETURNTYPE = 'GET_RETURNTYPE';
export const GET_RETURNTYPE_SUCCESS = 'GET_RETURNTYPE_SUCCESS';
export const GET_RETURNTYPE_ERROR = 'GET_RETURNTYPE_ERROR';

export const POST_ADD_EXCESS_MOTIVE = 'POST_ADD_EXCESS_MOTIVE';
export const POST_ADD_EXCESS_MOTIVE_SUCCESS = 'POST_ADD_EXCESS_MOTIVE_SUCCESS';
export const POST_ADD_EXCESS_MOTIVE_ERROR = 'POST_ADD_EXCESS_MOTIVE_ERROR';
