import {ResponseData} from '@components/ManageUser/interface';
import {
    PUT_ACCESS_TO_SYSTEM_USER,
    PUT_ACCESS_TO_SYSTEM_USER_SUCCESS,
    PUT_ACCESS_TO_SYSTEM_USER_ERROR,
} from '@components/ManageUser/Redux/types';

const initialState = {
    data: {},
    error: false,
    loading: false,
};

export const putAccessToSystemUser = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case PUT_ACCESS_TO_SYSTEM_USER:
            return {...state, loading: true, error: false};
        case PUT_ACCESS_TO_SYSTEM_USER_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case PUT_ACCESS_TO_SYSTEM_USER_ERROR:
            return {...state, loading: true, error: true};
        default:
            return state;
    }
};

export default putAccessToSystemUser;
