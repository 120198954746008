import axios, {AxiosResponse} from 'axios';
import {
    PUT_USER_INFO_BRANCH,
    PUT_USER_INFO_BRANCH_SUCCESS,
    PUT_USER_INFO_BRANCH_ERROR,
} from '@components/CloseBranch/redux/types';

import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';

export const putUserBranchInfo = () => {
    return {
        type: PUT_USER_INFO_BRANCH,
    };
};
export const putUserBranchInfoSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_USER_INFO_BRANCH_SUCCESS,
        payload: result.data,
    };
};
export const putUserBranchInfoError = () => {
    return {
        type: PUT_USER_INFO_BRANCH_ERROR,
    };
};
interface MissingAmountEmployee {
    userEmployeeId: number;
    amount: number;
}

interface PostData {
    branchId: number;
    initialBalance: number;
    finalBalance: number;
    pawnsAmmount: number;
    endorsementsAmmount: number;
    dischargesAmmount: number;
    branchFundingAmmount: number;
    surplu: number;
    match: boolean;
    differenceAmount?: number;
    observation?: string;
    missigAmountEmployee?: MissingAmountEmployee[];
}
export function putBranchUserInfo(
    data: PostData,
    token: string,
    setScreen: React.Dispatch<React.SetStateAction<string>>
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(putUserBranchInfo());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostCloseBranch}`,
                data,
                {
                    headers,
                }
            );
            dispatch(putUserBranchInfoSuccess(response));
            setScreen('Success');
        } catch (error) {
            dispatch(putUserBranchInfoError());
        }
    };
}
