import React, {useEffect} from 'react';
import '@DragAndDrop/products.css';
import {useDispatch, useSelector} from 'react-redux';
import {data} from '@DragAndDrop/products';
import {GetProducts} from '@ActionsDragandDrop/actions';
import {RootState, AppDispatch} from '@config/store';
import {Product} from '@DragAndDrop/interfaces';

export const DragandDrop = () => {
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        dispatch(GetProducts(data));
    }, []);

    const listProducts = useSelector((state: RootState) => state.productsEcommerce.products);

    return (
        <>
            {listProducts.length > 0 ? (
                <ul className="row row-cols-auto mt-5  container-list">
                    {listProducts.map((element: Product) => {
                        return (
                            <li
                                className="col-lg-5 col-md-4 col-sm-6 border-sides mt-4"
                                key={element.id}
                                draggable="true"
                                id={element.id}
                            >
                                <div
                                    className="card-img"
                                    style={{backgroundImage: `url(${element.img})`}}
                                />
                                <span className="txt-title mt-2">{element.name}</span>
                                <p className="description">{element.description}</p>
                                <div className="row">
                                    <div className="col-6 ">
                                        <p>Apartado</p>
                                        <span>{element.apart}</span>
                                    </div>
                                    <div className="col-6">
                                        <p>Precio</p>
                                        <p className="price">{element.price}</p>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className="row container-message">
                    <h1 className="col ">No hay elementos en la tienda</h1>
                </div>
            )}
        </>
    );
};
