import {AppDispatch} from '@/config/store';
import {
    GetCatState,
    fetchGetCatStateReset,
} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatState';
import {
    GetCatMunicipality,
    fetchGetCatMunicipalityReset,
} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatMunicipality';
import {
    GetCatCity,
    fetchGetCatCityReset,
} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatCity';
import {
    GetCatSuburb,
    fetchGetCatSuburbReset,
} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatSuburb';
import {IPostalInfo} from '@/components/Branches/interfaces';

export function LoadCatAddress(postalInfo: IPostalInfo, token: string) {
    return (dispatch: AppDispatch) => {
        dispatch(GetCatState(151, token));
        dispatch(GetCatMunicipality(postalInfo?.stateId, token));
        dispatch(GetCatCity(postalInfo?.municipalityId, token));
        dispatch(GetCatSuburb(postalInfo?.municipalityId, token));
    };
}
export function ResetCatAddress() {
    return (dispatch: AppDispatch) => {
        dispatch(fetchGetCatCityReset());
        dispatch(fetchGetCatMunicipalityReset());
        dispatch(fetchGetCatSuburbReset());
        dispatch(fetchGetCatStateReset());
    };
}
