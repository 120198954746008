import React, {useEffect} from 'react';

import {Controller} from 'react-hook-form';

import {IClientProofStep, SelectOption} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, TextArea, Input} from '@Shopify/Ecommerce/styles';
import AdditionalInformationContact from '@/components/Shopify/ListClient/AdditionalInformationContact';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {IColony, IMunicipalityItem, IStateItem} from '@/components/Branches/interfaces';
import {GetCatMunicipality} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatMunicipality';
import {GetPostalInfo} from '@/components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
import {GetCatState} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatState';
import {GetCatSuburb} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatSuburb';

const AdditionalInformation = (props: IClientProofStep) => {
    const {register, errors, control, setValue, watch} = props;
    const catState = useSelector((state: RootState) => {
        return state.GetCatState?.catState;
    });
    const catMunicipality = useSelector((state: RootState) => {
        return state.GetCatMunicipality?.catMunicipality;
    });
    const cityInfo = useSelector((state: RootState) => state.GetPostalInfo?.postalInfo);
    const catSuburb = useSelector((state: RootState) => {
        return state.GetCatSuburb?.catSuburb;
    });
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const dispatch = useDispatch();
    let formattedStates: SelectOption[] = [];
    if (catState) {
        formattedStates = catState.map((nation: IStateItem) => ({
            value: nation.stateId,
            label: nation.stateName,
        }));
    }

    let formattedMuni: SelectOption[] = [];
    if (catMunicipality) {
        formattedMuni = catMunicipality.map((nation: IMunicipalityItem) => ({
            value: nation.municipalityId,
            label: nation.nameMunicipality,
        }));
    }
    let formatedColony: SelectOption[] = [];

    if (catSuburb) {
        formatedColony = catSuburb
            .map((nation: IColony) => ({
                value: nation.suburbId,
                label: nation.nameSuburb,
            }))
            .sort((a: {label: string}, b: {label: string}) => a.label.localeCompare(b.label));
    }
    const postalCode = watch('cp');

    useEffect(() => {
        if (token) dispatch(GetPostalInfo(postalCode, token));
    }, [postalCode]);
    useEffect(() => {
        if (cityInfo.municipalityId && token) {
            dispatch(GetCatMunicipality(cityInfo.stateId, token));
            dispatch(GetCatState(cityInfo.countryId, token));
            dispatch(GetCatSuburb(cityInfo.municipalityId, token));
        }
    }, [cityInfo]);
    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            backG="#FFF"
            Width="1233px"
            Margin="0 auto"
        >
            <Text FontWeight="500" FontSize="1.25rem" Color="#2A2C2F">
                Información complementaria
            </Text>
            <ContainerFlex Padding=" 1.75rem 1rem" Radius="1rem" Align="start">
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                    <AdditionalInformationContact {...props} />

                    <ContainerFlex Justify="space-between" Gap="1rem">
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.DIRECCION}</Text>
                            <Controller
                                name="street"
                                control={control}
                                render={() => (
                                    <Input
                                        {...register('street')}
                                        error={!!errors.street?.message}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.street?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.NUMERO_EXTERIOR}</Text>
                            <Controller
                                name="externalNumber"
                                control={control}
                                render={({field: {onChange}}) => (
                                    <Input
                                        {...register('externalNumber')}
                                        error={!!errors.externalNumber?.message}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.externalNumber?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.NUMERO_INTERIOR}</Text>
                            <Controller
                                name="internalNumber"
                                control={control}
                                render={() => (
                                    <Input
                                        {...register('internalNumber')}
                                        error={!!errors.internalNumber?.message}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.internalNumber?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.CODIGO_POSTAL}</Text>
                            <Controller
                                name="cp"
                                control={control}
                                render={() => (
                                    <Input {...register('cp')} error={!!errors.cp?.message} />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.cp?.message as string}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex Justify="space-between" Gap="1rem">
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.COLONIA}</Text>
                            <Controller
                                name="colonyId"
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        hasError={!!errors.colonyId?.message}
                                        options={formatedColony}
                                        onChange={(selectedOption) => {
                                            if (onChange) onChange(selectedOption);
                                        }}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.colonyId?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.MUNICIPIO_ALCALDIA}</Text>
                            <Controller
                                name="municipalityId"
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        hasError={!!errors.municipalityId?.message}
                                        options={formattedMuni}
                                        onChange={(selectedOption) => {
                                            if (token)
                                                dispatch(GetCatSuburb(selectedOption.value, token));
                                            if (onChange) onChange(selectedOption);
                                        }}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.municipalityId?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.CIUDAD}</Text>
                            <Controller
                                name="cityId"
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        hasError={!!errors.cityId?.message}
                                        options={formattedMuni}
                                        onChange={(selectedOption) => {
                                            if (onChange) onChange(selectedOption);
                                        }}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.cityId?.message as string}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex {...style.selectContent}>
                            <Text {...style.level}>{CREATE_CLIENT_LABELS.ESTADO}</Text>
                            <Controller
                                name="stateId"
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <SelectGeneral
                                        {...field}
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        hasError={!!errors.stateId?.message}
                                        options={formattedStates}
                                        onChange={(selectedOption) => {
                                            if (token)
                                                dispatch(
                                                    GetCatMunicipality(selectedOption.value, token)
                                                );
                                            if (onChange) onChange(selectedOption);
                                        }}
                                    />
                                )}
                            />
                            <Text Color="#FF6357" FontSize="0.875rem">
                                {errors.stateId?.message as string}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start">
                        <Text {...style.level}>{CREATE_CLIENT_LABELS.NOTAS}</Text>
                        <Controller
                            name="notes"
                            control={control}
                            render={() => (
                                <TextArea
                                    Cursor="auto"
                                    rows={5}
                                    {...register('notes')}
                                    error={!!errors.notes?.message}
                                    onChange={(e) => {
                                        setValue('notes', e.target.value);
                                    }}
                                />
                            )}
                        />
                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                            {errors?.notes?.message as string}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AdditionalInformation;
