import {AppDispatch} from '@/config/store';
import {
    GET_CAT_SUBFAMILY,
    GET_CAT_SUBFAMILY_ERROR,
    GET_CAT_SUBFAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {updateSubFamilyChildren} from './GetCatGroupAction';
import {getBrandAction} from './GetBrandGroupAction';
import {Quantity} from '@/hooks/currentFormatUtils';

export const getSubFamily = () => {
    return {
        type: GET_CAT_SUBFAMILY,
    };
};

export const getSubFamilySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_SUBFAMILY_SUCCESS,
        payload: result,
    };
};

export const getSubFamilyError = (error: AxiosError) => {
    return {
        type: GET_CAT_SUBFAMILY_ERROR,
        payload: error,
    };
};

export const getSubFamilyAction = (familyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSubFamily());
        const params = {
            familyId: familyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSubfamilyManager}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getSubFamilySuccess(response));
            const subFamilies = response.data.data;
            dispatch(updateSubFamilyChildren(familyId, subFamilies));
            for (const subfamily of subFamilies) {
                const QuantityNumber = Quantity(String(subfamily.nameSubFamily));
                if (QuantityNumber && QuantityNumber > 0)
                    dispatch(getBrandAction(subfamily.id, token));
            }
        } catch (error) {
            dispatch(getSubFamilyError(error as AxiosError));
        }
    };
};
