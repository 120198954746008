import React from 'react';
import Logo from '@images/AtaskateLogoWhite.svg';
import Endorsement from '@images/Ilu_A.svg';
import Payment from '@images/Ilu_B.svg';
import Offer from '@images/Ilu_C.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ImageLogo} from '@Login/style';
import {CREATE_CLIENTS} from '@components/Login/constants';
import {CardsSubtitles} from '@components/Login/MarketPlaceLogin/CardsSubtitles';

export const AtaskateHeader = () => {
    return (
        <ContainerFlex backG="#5A5AFF" FlexDir="column" Width="50%" Padding="72px 0 0 0">
            <ImageLogo Height="38px" Width="204px" src={Logo} />
            <ContainerFlex FlexDir="column" Gap="56px" Justify="start" Padding="144px 0 0 0">
                <ContainerFlex
                    Height="auto"
                    ColumnGap="12px"
                    GridColumns="20% 80%"
                    Display="grid"
                    Width="50%"
                >
                    <ContainerFlex
                        Radius="68px"
                        Padding="0 32px"
                        backG="#FFFFFF"
                        Width="68px"
                        Height="68px"
                    >
                        <Image src={Endorsement} />
                    </ContainerFlex>
                    <CardsSubtitles />
                </ContainerFlex>
                <ContainerFlex
                    Height="auto"
                    ColumnGap="12px"
                    GridColumns="20% 80%"
                    Display="grid"
                    Width="50%"
                >
                    <ContainerFlex
                        Radius="68px"
                        Padding="0 32px"
                        backG="#FFFFFF"
                        Width="68px"
                        Height="68px"
                    >
                        <Image src={Payment} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" Width="auto" Padding="0 0 0 12px">
                        <Text
                            FontSize="1.25rem"
                            Color="#FFFFFF"
                            FontWeight="700"
                            FontFamily="Nunito"
                        >
                            {CREATE_CLIENTS.ONE_CLICK}
                        </Text>
                        <Text FontSize="1rem" Color="#FFFFFF" FontFamily="Nunito">
                            {CREATE_CLIENTS.LESS_COST}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Height="auto"
                    ColumnGap="12px"
                    GridColumns="20% 80%"
                    Display="grid"
                    Width="50%"
                >
                    <ContainerFlex
                        Radius="68px"
                        Padding="0 32px"
                        backG="#FFFFFF"
                        Width="68px"
                        Height="68px"
                    >
                        <Image src={Offer} />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start" Width="auto" Padding="0 0 0 12px">
                        <Text
                            FontSize="1.25rem"
                            Color="#FFFFFF"
                            FontWeight="700"
                            FontFamily="Nunito"
                        >
                            {CREATE_CLIENTS.OFFEERS}
                        </Text>
                        <Text FontSize="1rem" Color="#FFFFFF" FontFamily="Nunito">
                            {CREATE_CLIENTS.DISCOUNTS}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
