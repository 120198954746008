import {FeatureHereditaryData} from '@/components/Articles/interface';
import {
    RESET_INHERITED_FEATURES,
    SAVE_NEW_INHERITED_FEATURE,
    UPDATE_INHERITED_FEATURE,
} from '@components/Catalogue/Redux/Types/Types';

export const saveNewHereditaryFeature = (result: FeatureHereditaryData) => {
    return {
        type: SAVE_NEW_INHERITED_FEATURE,
        payload: result,
    };
};
export const updateHereditaryFeature = (feature: FeatureHereditaryData) => ({
    type: UPDATE_INHERITED_FEATURE,
    payload: feature,
});

export const resetHereditaryFeatures = () => {
    return {
        type: RESET_INHERITED_FEATURES,
    };
};
