import {SELECT_TYPE_LOCATIONS} from '@components/LocationsBranch/Redux/types';
import {
    ISelectTypeLocations,
    ISelectTypeLocationsRedux,
} from '@components/LocationsBranch/interfaces';

const initialState: ISelectTypeLocations = {
    types: [],
    error: false,
    loading: false,
};

export const selectTypesLocations = (
    state = initialState,
    action: ISelectTypeLocationsRedux
): ISelectTypeLocations => {
    switch (action.type) {
        case SELECT_TYPE_LOCATIONS.START:
            return {...state, loading: true, error: false};
        case SELECT_TYPE_LOCATIONS.SUCCESS:
            return {
                ...state,
                types: action.types,
                loading: false,
                error: false,
            };
        case SELECT_TYPE_LOCATIONS.ERROR:
            return {...state, loading: false, error: false};
        default:
            return state;
    }
};
