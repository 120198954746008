import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_TYPES} from '@/components/MyAccount/constants';
import {toggleCreditType} from '@/components/MyAccount/MyCredit/Redux/Actions/MyCreditActions';
import CreditButton from '@/components/MyAccount/MyCredit/CreditButton';

const TypeFilter = () => {
    const dispatch = useDispatch();
    const {creditType} = useSelector((state: RootState) => state.MyCreditReducer);

    const handleActiveTab = (tab: string) => {
        dispatch(toggleCreditType(tab));
    };

    const creditTypes = [
        {type: CREDIT_TYPES.ALL, radius: '8px 0px 0px 8px'},
        {type: CREDIT_TYPES.PERSONAL, radius: '0'},
        {type: CREDIT_TYPES.PAWNS, radius: '0 8px 8px 0'},
    ];

    return (
        <ContainerFlex Justify="flex-start">
            {creditTypes.map(({type, radius}) => (
                <CreditButton
                    key={type}
                    type={type}
                    isActive={creditType === type}
                    onClick={() => handleActiveTab(type)}
                    radius={radius}
                />
            ))}
        </ContainerFlex>
    );
};

export default TypeFilter;
