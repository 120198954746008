import {
    GET_ALL_FLOWCASH_BRANCH,
    GET_ALL_FLOWCASH_BRANCH_SUCCESS,
    GET_ALL_FLOWCASH_BRANCH_ERROR,
} from '@ReduxCashFlowBasic/Types/TypesFlowCash';

const initialState = {
    deetailsFlowCash: [],
    loading: false,
    error: false,
};
const getFlowCashDetails = (state = initialState, action: {type: string; value: []}) => {
    switch (action.type) {
        case GET_ALL_FLOWCASH_BRANCH:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_FLOWCASH_BRANCH_SUCCESS:
            return {
                ...state,
                deetailsFlowCash: action.value,
                loading: false,
            };
        case GET_ALL_FLOWCASH_BRANCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getFlowCashDetails;
