import {Controller} from 'react-hook-form';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import * as style from '@Quoter/stylesConstants';
import {StyleNumericFormat} from '@Loan/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TabsStadistics} from '@Quoter/Steps/StepOne/TabsStadistics';
import {
    CalculateAuctionPassQuoter,
    DetailsStadistic,
    DetailsStadisticGlobal,
    ResetStadistics,
    States,
} from '@ActionsQuoter/StatusStepOne';
import {OverResetAll, restarQuoter, saveStadistics} from '@Quoter/Redux/Actions/saveEndeavors';
import {APPRASIAL, LEVELS, SEARCHVALUES, WITHOUTPRICE} from '@components/Quoter/constants';
import {ICatStates, IGeneralQuoter, ISelectReact} from '@components/Quoter/inferfaces';
import {QuitNumberFormat, limitNumberFormat, withoutPrices} from '@/hooks/currentFormatUtils';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {TabsStadisticsCompany} from '@Quoter/Steps/StepOne/TabStadisticsCompany';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {cartContracts} from '@components/ShoppingCart/interface';
import {changeActualStep} from '@components/Quoter/Redux/Actions/saveEndeavors';
import {resetInputDinamics} from '@Quoter/Redux/Actions/PropertiesArticle';
import {resetEditQuoter} from '@/components/ShoppingCart/Redux/Actions/getInfoContractActions';

export const StadisticsProduct = (props: IGeneralQuoter) => {
    const {
        branchId,
        token,
        errors,
        control,
        salePrice,
        setValue,
        reset,
        setValueEmpty,
        stadistic,
        setStadistic,
        inputDinamics,
        ResetData,
    } = props;
    const [tabs, setTabs] = useState(1);
    const dispatch: AppDispatch = useDispatch();
    const {productSearch, stadistics} = useSelector((state: RootState) => state.saveEndeavor);
    const {calculateAuctionPass, catStates} = useSelector((state: RootState) => state.statusQuoter);
    const {details} = useSelector((state: RootState) => state.statusQuoter);

    const selectConvert = (items: ICatStates[]) => {
        return (
            items &&
            items.map((item) => ({
                value: item.id,
                label: item.stateConservationName,
            }))
        );
    };

    const calculateLoanValue = () => {
        if (
            stadistic.loan &&
            stadistic.value &&
            stadistic.state &&
            productSearch.levelsIds[LEVELS.ARTICLE] !== undefined
        )
            dispatch(
                CalculateAuctionPassQuoter(token, {
                    branchId: branchId,
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    value: stadistic.value,
                    loan: stadistic.loan,
                    stateConservationId: stadistic.state.value,
                })
            );
    };

    const messageErrorLoanValue = (value: number) => {
        let message = '';
        if (value && productSearch.levelsIds[LEVELS.ARTICLE] === undefined)
            message = APPRASIAL.ERRORMESSAGE;
        return message;
    };

    const cancelOperation = () => {
        dispatch(restarQuoter());
        location.reload();
    };

    useEffect(() => {
        dispatch(States(token));
        setStadistic(stadistics);
    }, []);

    const infoStadistics = useSelector((state: RootState) => state.getInfoContracts.data);
    const carPledgeInfo = infoStadistics.carPledge?.[0];
    const propertiesArticle = useSelector((state: RootState) => state.propertiesArticle);
    const stateConservationIdEdit = infoStadistics.carPledge?.[0].stateConservationId;
    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;
    const [stateLabel, setStateLabel] = useState('');
    const EditPetition = () => {
        if (companyId) {
            dispatch(
                DetailsStadistic(token, {
                    companyId: companyId,
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stateConservationIdEdit,
                })
            );
            dispatch(
                DetailsStadisticGlobal(token, {
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stateConservationIdEdit,
                })
            );
        }
    };
    const EditValues = () => {
        if (carPledgeInfo !== undefined) {
            const {loan, value} = carPledgeInfo;
            switch (stateConservationIdEdit) {
                case 1:
                    setStateLabel('Bueno');
                    break;
                case 2:
                    setStateLabel('Regular');
                    break;
                case 3:
                    setStateLabel('Malo');
                    break;
            }
            setValue('loan', loan);
            setValue('value', value);
            setValue('Status', {value: stateConservationIdEdit, label: stateLabel});
        }
    };

    useEffect(() => {
        EditValues();
    }, [propertiesArticle]);
    useEffect(() => {
        if (propertiesArticle && carPledgeInfo) EditPetition();
    }, [infoStadistics]);

    useEffect(() => {
        calculateLoanValue();
        dispatch(saveStadistics(stadistic));
    }, [stadistic.loan, stadistic.value, stadistic.state]);
    const salesPrice = withoutPrices(calculateAuctionPass?.salePrice, WITHOUTPRICE.DOLLAR);

    if (salePrice) {
        salePrice(salesPrice);
    }
    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const OnResetAll = () => {
        reset();
        reset(ResetData);
        reset(inputDinamics);
        setValueEmpty();
        dispatch(OverResetAll());
        dispatch(ResetStadistics());
        dispatch(resetInputDinamics());
        dispatch(resetEditQuoter());
        setStadistic({loan: 0, value: 0, state: {}});
        restarQuoter();
        reset(SEARCHVALUES);
    };
    return (
        <>
            <ContainerFlex
                FlexDir="column"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1rem"
                Align="start"
                backG="#fff"
            >
                <Text FontWeight="500" FontSize="1.5rem" Color="#54575C">
                    {APPRASIAL.SUBTITLE}
                </Text>
                <ContainerFlex
                    Display="grid"
                    GridColumns="1.5fr 1.5fr 1.5fr 1fr 1fr"
                    Gap="1.5rem"
                    Padding="1.5rem 0"
                >
                    <ContainerFlex
                        {...style.contentAppraisal}
                        Height="100%"
                        Padding={details?.regularValue ? '0 0 14px 0' : ''}
                    >
                        <Text {...style.textApprasial}>{APPRASIAL.APPRASIALTITLE}</Text>
                        <Controller
                            name="value"
                            control={control}
                            render={({field}) => (
                                <StyleNumericFormat
                                    {...field}
                                    name="value"
                                    type="text"
                                    autoComplete="off"
                                    defaultValue={stadistics.value}
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    hasError={errors.value?.message ? true : false}
                                    allowNegative={false}
                                    prefix="$ "
                                    width="80%"
                                    onValueChange={(values) =>
                                        setStadistic({
                                            value: Number(values.floatValue),
                                            loan: stadistic.loan,
                                            state: stadistic.state,
                                        })
                                    }
                                />
                            )}
                        />
                        {details?.regularValue && (
                            <Text {...style.textApprasialTotal}>
                                {APPRASIAL.APPRASIALTITLESUGGEST}{' '}
                                {withoutPrices(details.regularValue, WITHOUTPRICE.DOLLAR)}
                            </Text>
                        )}
                        {errors.value?.message && (
                            <Text Color="red" FontSize="0.75rem" Height="0.75rem">
                                {messageErrorLoanValue(stadistic.value)}{' '}
                                {errors.value.message as string}
                            </Text>
                        )}
                    </ContainerFlex>
                    <ContainerFlex {...style.contentAppraisal} Justify="start">
                        <Text {...style.textApprasial}>{APPRASIAL.LOAN}</Text>

                        <Controller
                            name="loan"
                            control={control}
                            render={({field}) => (
                                <StyleNumericFormat
                                    {...field}
                                    defaultValue={stadistics.loan}
                                    autoComplete="off"
                                    type="text"
                                    prefix="$ "
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    allowNegative={false}
                                    hasError={errors.loan?.message ? true : false}
                                    width="80%"
                                    onValueChange={(values) => {
                                        setStadistic({
                                            loan: Number(values.floatValue),
                                            value: stadistic.value,
                                            state: stadistic.state,
                                        });
                                    }}
                                    isAllowed={(values: {value: string}) =>
                                        limitNumberFormat(
                                            QuitNumberFormat(values.value),
                                            0,
                                            stadistic.value
                                        )
                                    }
                                />
                            )}
                        />
                        <Text Color="red" FontSize="0.75rem" Height="0.75rem">
                            {errors.loan?.message as string}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex {...style.contentAppraisal} Justify="start">
                        <Text {...style.textApprasial}>{APPRASIAL.STATE}</Text>
                        <Controller
                            name="Status"
                            control={control}
                            render={({field: {onChange, ref, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(catStates)}
                                    hasError={errors.Status?.message ? true : false}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        setStadistic({
                                            loan: stadistic.loan,
                                            value: stadistic.value,
                                            state: e as ISelectReact,
                                        });
                                    }}
                                />
                            )}
                        />
                        <Text Color="red" FontSize="0.75rem" Height="0.75rem">
                            {errors.Status?.message as string}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex {...style.contentAppraisal} Justify="start">
                        <Text {...style.textApprasial}>{APPRASIAL.AVG}</Text>
                        <Text {...style.textPercentage}>
                            {withoutPrices(calculateAuctionPass?.passPercentage, WITHOUTPRICE.AVG)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex {...style.contentAppraisal} Justify="start">
                        <Text {...style.textApprasial}>{APPRASIAL.PRICESALE}</Text>
                        <Text {...style.textPercentage}>{salesPrice}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="24px">
                    <TabsStadistics tabs={tabs} setTabs={setTabs} token={token} />
                    <TabsStadisticsCompany tabs={tabs} setTabs={setTabs} token={token} />
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Gap="1rem">
                <Text
                    HColor="#FFFFFF"
                    Color="#5A5AFF"
                    bGround="#FFFFFF"
                    HBackColor="#0D166B"
                    Border="1px solid #5A5AFF"
                    {...style.Btn}
                    onClick={() => {
                        OnResetAll();
                        if (contracts?.length !== 0) dispatch(changeActualStep(2));
                        else cancelOperation();
                    }}
                >
                    {contracts && contracts.length !== 0 ? 'Siguiente Paso' : 'Cancelar'}
                </Text>
                <ButtonGeneral
                    text="Agregar al carrito"
                    type="submit"
                    disabled={contracts && contracts.length >= 7}
                />
            </ContainerFlex>
        </>
    );
};
