import {
    GET_EMPLOYEE_INCIDENTS,
    GET_EMPLOYEE_INCIDENTS_SUCCESS,
    GET_EMPLOYEE_INCIDENTS_ERROR,
    GET_EMPLOYEE_RESPONSIBLE,
    GET_EMPLOYEE_RESPONSIBLE_SUCCESS,
    GET_EMPLOYEE_RESPONSIBLE_ERROR,
    PUT_AUDIT_INCIDENTS,
    PUT_AUDIT_INCIDENTS_SUCCESS,
    PUT_AUDIT_INCIDENTS_ERROR,
} from '@TypesIncidentsFoundry/IncidentsFoundryTypes';

const initialState = {
    employeeresponsible: [],
    employeeincidents: [],
    error: false,
    loading: false,
};

const incidentsfoundry = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_EMPLOYEE_INCIDENTS:
            return {
                ...state,
                loading: true,
            };
        case GET_EMPLOYEE_INCIDENTS_SUCCESS:
            return {
                ...state,
                employeeincidents: action.value,
                loading: false,
            };
        case GET_EMPLOYEE_INCIDENTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_EMPLOYEE_RESPONSIBLE:
            return {
                ...state,
                loading: true,
            };
        case GET_EMPLOYEE_RESPONSIBLE_SUCCESS:
            return {
                ...state,
                employeeresponsible: action.value,
                loading: false,
            };
        case GET_EMPLOYEE_RESPONSIBLE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_AUDIT_INCIDENTS:
            return {
                ...state,
                loading: true,
            };
        case PUT_AUDIT_INCIDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_AUDIT_INCIDENTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default incidentsfoundry;
