import {
    GET_BRANCH_SCHEDULE_ENDEAVOR,
    GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR,
    GET_BRANCH_SCHEDULE_ENDEAVOR_RESET,
    GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS,
    GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    scheduleEndeavor: [],
};

const GetScheduleEndeavor = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_BRANCH_SCHEDULE_ENDEAVOR:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS:
            return {
                ...state,
                scheduleEndeavor: action.payload.data,
            };
        case GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_BRANCH_SCHEDULE_ENDEAVOR_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetScheduleEndeavor;
