import axios, {AxiosResponse} from 'axios';
import {
    GET_CAT_STATE,
    GET_CAT_STATE_ERROR,
    GET_CAT_STATE_RESET,
    GET_CAT_STATE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';

export const fetchGetCatStateStart = () => {
    return {
        type: GET_CAT_STATE,
    };
};
export const fetchGetCatStateSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_STATE_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetCatStateError = () => {
    return {
        type: GET_CAT_STATE_ERROR,
    };
};
export const fetchGetCatStateReset = () => {
    return {
        type: GET_CAT_STATE_RESET,
    };
};

export function GetCatState(countryId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetCatStateStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatState}`,
                {
                    headers,
                    params: {
                        countryId,
                    },
                }
            );
            dispatch(fetchGetCatStateSuccess(response));
        } catch (error) {
            dispatch(fetchGetCatStateError());
        }
    };
}
