import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import upArrowSelected from '@components/Branches/Images/UpArrowSelected.svg';
import {AssociatedCostsInfo} from '@components/Branches/ManageBranch/BranchServices/AssociatedCostsInfo';
import {AssociatedCostsForm} from '@components/Branches/ManageBranch/BranchServices/AssociatedCostsForm';
import {IBranchServices} from '@components/Branches/interfaces';
import {GetServicesComplements} from '@components/Branches/ManageBranch/Redux/Actions/GetServicesComplements';

export const BranchServices = ({showItems, setShowItems}: IBranchServices) => {
    const {idBranch} = useParams();
    const dispatch = useDispatch();
    const [showServicesForm, setShowServicesForm] = useState(false);
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        dispatch(GetServicesComplements(Number(idBranch), token));
    }, []);
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                backG="#f3f3f3"
                Height="56px"
                Justify="space-between"
                Radius="4px 4px 0 0"
                Border="1px solid #e5e7e9"
                Padding="18.5px 16px"
            >
                <Text FontSize="1rem" FontWeight="500" Color="#414042">
                    Servicios
                </Text>
                {!showItems.services ? (
                    <Image
                        src={downArrowSelected}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                services: !showItems.services,
                                user: false,
                                schedule: false,
                                info: false,
                                cashFlow: false,
                            });
                        }}
                    />
                ) : (
                    <Image
                        src={upArrowSelected}
                        alt="up-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({...showItems, services: !showItems.services});
                        }}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="column"
                Radius="0 0 4px 4px"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Gap={!showItems.services ? '8px' : '0px'}
                Height="auto"
                Padding={!showItems.services ? '16px' : '0 16px 16px 16px'}
                MarginBt="24px"
            >
                {!showItems.services ? (
                    <Text FontSize="0.875rem" Color="#6d6e71">
                        Gestión de servicios para la sucursal
                    </Text>
                ) : (
                    <>
                        {!showServicesForm ? (
                            <AssociatedCostsInfo
                                showServicesForm={showServicesForm}
                                setShowServicesForm={setShowServicesForm}
                            />
                        ) : (
                            <AssociatedCostsForm
                                showServicesForm={showServicesForm}
                                setShowServicesForm={setShowServicesForm}
                            />
                        )}
                    </>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
