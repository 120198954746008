import * as yup from 'yup';

export const schemaFormModal = yup.object().shape({
    countryId: yup.number(),
    countryName: yup.string().required('Selecciona un país'),
    nameBranch: yup
        .string()
        .required('Escribe un nombre')
        .max(160)
        .matches(
            /^(?! )[\wñÑáéíóúÁÉÍÓÚ0-9_. -]+$/,
            'No se permite el uso de caracteres especiales'
        ),
    description: yup
        .string()
        .required('Escribe una descripción')
        .max(160)
        .matches(/^(?! )[\wñÑáéíóúÁÉÍÓÚ0-9,. ]+$/, 'No se permite el uso de caracteres especiales'),
    levelId: yup.number().required('Selecciona nivel organizacional'),
});

export const schemaComplementaryForm = yup.object().shape({
    code: yup
        .string()
        .required('Ingresa CLAVE')
        .min(5, 'Ingresa mínimo 5 caracteres')
        .max(16)
        .matches(
            /^[A-Za-z0-9][A-Za-z0-9-]*$/,
            'Valor introducido es invalido, no se permiten caracteres especiales'
        ),
    businessName: yup
        .string()
        .required('Ingresa razón social')
        .max(64)
        .matches(/^[A-Za-z0-9Ññ][A-Za-z0-9Ññ -.]*$/, 'No se permiten caracteres especiales'),
    rfc: yup
        .string()
        .required('Ingresa RFC')
        .min(12, 'Ingresa mínimo 12 caracteres')
        .max(13)
        .matches(
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/,
            'No se permiten caracteres especiales'
        ),
    phone: yup
        .string()
        .required('Ingresa un teléfono')
        .max(10)
        .matches(/^[0-9]*$/, 'No se permiten caracteres especiales'),
    postalCode: yup.string().required('Ingresa el código postal').min(5, 'Ingresa 5 dígitos'),
    state: yup.string().required('Selecciona un estado'),
    city: yup.string(),
    street: yup
        .string()
        .required('Ingresa la calle')
        .max(50)
        .matches(
            /^[A-Za-z0-9ÑñáéíóúÁÉÍÓÚ][A-Za-z0-9ÑñáéíóúÁÉÍÓÚ .]*$/,
            'No se permiten caracteres especiales'
        ),
    municipality: yup.string().required('Selecciona una alcaldía ó municipio'),
    suburb: yup.string().required('Selecciona una colonia'),
    extNum: yup.string().required('Ingresa número exterior'),
    intNum: yup.string(),
});

export const schemaOtherForm = yup.object().shape({
    userId: yup.number(),
    managerName: yup.string().required('Selecciona un usuario'),
    legalRepresentative: yup
        .string()
        .required('Escribe una descripción')
        .max(160)
        .matches(
            /^[A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü][A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü ]*$/,
            'No se permiten caracteres especiales'
        ),
    branchEmail: yup
        .string()
        .required('Escribe un email')
        .email('No cumple con el formato de email'),
    contractRegistrationDate: yup.object().nullable().required('Elige una fecha de contrato'),
    publicRegistrationAdhesionNumber: yup
        .string()
        .required('Escribe una descripción')
        .max(160)
        .matches(
            /^[A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü][A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü ]*$/,
            'No se permiten caracteres especiales'
        ),
});

export const schemaFormServices = yup.object().shape({
    appraisalPercentage: yup.string().required(),
    administrativeCost: yup.string().required(),
    reprintPledge: yup.string().required(),
    reprintPulledApart: yup.string().required(),
});

export const schemaFormCash = yup.object().shape({
    branchId: yup.number(),
    amountMaximum: yup.string().required(),
    cashDelivery: yup.boolean(),
    receiveCash: yup.boolean(),
    moneyTransfersBranches: yup.array(),
    branchesThatSendMoney: yup.array(),
});
