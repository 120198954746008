import styled from '@emotion/styled';
import {IPropsImg, IPropsTextArea} from '@CashFlow/FundingRequest/interfacesStyles';

export const Img = styled.img<IPropsImg>`
    filter: ${(props) => props.Filter};
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    margin-right: ${(props) => props.MarginR};
`;

export const TextArea = styled.textarea<IPropsTextArea>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    padding: ${(props) => props.Padding};
    font-size: ${(props) => props.FontSize};
    text-align: left;
    font-weight: 400;
    font-family: Work Sans;
    resize: none;
    line-height: 1rem;
    color: ${(props) => props.Color};
    &:focus {
        box-shadow: ${(props) => props.FocusBoxShadow};
        outline: ${(props) => props.FocusOutline};
    }
    &:hover {
        border: ${(props) => props.HBorder};
        box-shadow: ${(props) => props.HShadow};
    }
    &::placeholder {
        color: ${(props) => props.HolderColor};
        font-size: ${(props) => props.HolderFontSize};
        line-height: ${(props) => props.HolderHeight};
        font-weight: ${(props) => props.HolderWeight};
    }
`;

TextArea.defaultProps = {
    Width: '100%',
    Height: '100%',
    Border: '1px solid #e4e7e9',
    Radius: '4px',
    Padding: '0.857rem 0.714rem',
    FocusOutline: '2px solid #35cf44',
    HBorder: '1px solid #35cf44',
    FocusBoxShadow: '0px 4px 4px rgba(122, 193, 67, 0.2)',
    HShadow: '0px 4px 4px rgba(122, 193, 67, 0.2)',
    HolderHeight: '1rem',
    HolderWeight: '400',
    HolderColor: '#a7a9ac',
};
