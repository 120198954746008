import {
    GET_RETURNTYPE,
    GET_RETURNTYPE_SUCCESS,
    GET_RETURNTYPE_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const employeeReturnType = (state = initialState, action) => {
    switch (action.type) {
        case GET_RETURNTYPE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_RETURNTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: null,
            };
        case GET_RETURNTYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload?.data?.data,
            };
        default:
            return state;
    }
};
