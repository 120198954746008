import styled from '@emotion/styled';
import {
    IPropsDropDownMenuContainerProps,
    IPropsFloatMenuItem,
} from '@ReutilizableComponent/iterfacesStyles';

export const FloatMenuItem = styled.div<IPropsFloatMenuItem>`
    display: flex;
    justify-content: ${(props) => props.Justify};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.height};
    width: ${(props) => props.Width};
    &:hover {
        background-color: ${(props) => props.HoverColor};
        cursor: ${(props) => props.CursorHover};
    }
    span {
        font-weight: ${(props) => props.FontWeight};
        color: ${(props) => props.SpanColor};
        font-size: ${(props) => props.FontSize};
        padding: ${(props) => props.PaddingSpam};
    }
`;
export const DropDownMenuContainer = styled.div<IPropsDropDownMenuContainerProps>`
    flex-direction: column;
    justify-content: ${(props) => props.Justify};
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.BorderRadius};
    border: ${(props) => props.Border};
    position: ${(props) => props.Position};
    background-color: ${(props) => props.Background};
    box-shadow: ${(props) => props.BoxShadow};
    width: ${(props) => props.Width};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    right: ${(props) => props.Right};
    bottom: ${(props) => props.Bottom};
    z-index: ${(props) => props.ZIndex};
    padding: ${(props) => props.Padding};
    overflow-y: ${(props) => props.OverFlowY};
    box-sizing: border-box;
`;
DropDownMenuContainer.defaultProps = {
    Justify: 'flex-star',
    Height: '100vh',
    Border: '1px solid #e5e7e9',
    BoxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.5)',
    Width: 'auto',
    Position: 'fixed',
    BorderRadius: '4px',
    Background: '#ffffff',
    ZIndex: '2',
    OverFlowY: '',
};
export const IconContainer = styled.img`
    width: 24px;
    height: 24px;
`;
