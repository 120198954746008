import axios, {AxiosResponse, AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {updateGroup, fillingGroup} from '@components/LocationsBranch/Redux/Action/LevelGroups';
import {Levels} from '@components/LocationsBranch/Redux/interfaces';
import {GET_LIST_LEVELS} from '@components/LocationsBranch/Redux/types';

const getLevelsSuccess = (result: AxiosResponse) => ({
    type: GET_LIST_LEVELS.GET_SUCCESS,
    payload: result,
});

const getLevelsError = (error: AxiosError) => ({
    type: GET_LIST_LEVELS.GET_ERROR,
    error: error,
});

export const GetLevelsByLevelId = (
    companyId: number,
    groupType: string,
    group: number,
    levelId: number,
    token: string
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fillingGroup(group, groupType));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetListLevelsByLevelId}`,
                {
                    headers,
                    params: {
                        companyId,
                        levelId,
                    },
                }
            );
            const responseData = response.data.data;
            const levelsData: Levels[] = response.data.data.levels;
            dispatch(getLevelsSuccess(responseData));
            await dispatch(updateGroup(group, levelsData, groupType));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getLevelsError(err));
            throw err;
        }
    };
};
