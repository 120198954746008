import {COMPANY_DETAILS, COMPANY_TAX, COMPANY_PRIVACY} from '@MyCompany/Redux/Types/types';
import {ICompany, ICompanyRedux} from '@MyCompany/Interface';

const initialState: ICompany = {
    company: {
        companyId: 0,
        companyImage: '',
        nameCompany: '',
        businessName: '',
        startCompany: '',
        emailsCompanies: [],
        phoneCompanies: [],
    },
    companyTax: {companyId: 0, rfc: '', taxEmail: '', companyAddress: ''},
    companyPrivacy: [],
    loading: false,
    error: false,
};

export const detailsCompany = (state = initialState, action: ICompanyRedux): ICompany => {
    switch (action.type) {
        case COMPANY_DETAILS.START_COMPANY:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case COMPANY_DETAILS.SUCCESS_COMPANY:
            return {
                ...state,
                company: action.company,
                loading: false,
                error: false,
            };
        case COMPANY_DETAILS.ERROR_COMPANY:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case COMPANY_TAX.START_COMPANY_TAX:
            return {
                ...state,
                loading: true,
            };
        case COMPANY_TAX.SUCCESS_COMPANY_TAX:
            return {
                ...state,
                companyTax: action.companyTax,
                loading: false,
                error: false,
            };
        case COMPANY_TAX.ERROR_COMPANY_TAX:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case COMPANY_PRIVACY.START_COMPANY_PRIVACY:
            return {
                ...state,
                loading: true,
            };
        case COMPANY_PRIVACY.SUCCESS_COMPANY_PRIVACY:
            return {
                ...state,
                companyPrivacy: action.companyPrivacy,
                loading: false,
                error: false,
            };
        case COMPANY_PRIVACY.ERROR_COMPANY_PRIVACY:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
