import {
    GET_MODULES_POS,
    GET_MODULES_POS_SUCCESS,
    GET_MODULES_POS_ERROR,
    RESET_MODULES_POS,
} from '@components/Home/HomeEmployee/Redux/types';
import {AxiosError, AxiosResponse} from 'axios';
import {IResponseModulesData} from '@components/Home/HomeEmployee/interfaces';

const initialState: IResponseModulesData = {
    modulesData: [],
    loading: false,
    error: false,
};

const getModulesData = (
    state: IResponseModulesData = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IResponseModulesData => {
    switch (action.type) {
        case GET_MODULES_POS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_MODULES_POS_SUCCESS:
            return {
                ...state,
                modulesData: action.payload.data.data,
                loading: false,
            };
        case GET_MODULES_POS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_MODULES_POS:
            return initialState;
        default:
            return state;
    }
};

export default getModulesData;
