import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_ERROR,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_SUCCESS_PAGE,
    RESET_PROMOTIONS,
} from '@Quoter/PromotionsRedux/types';

const initialState = {
    error: false,
    loading: false,
    totalPage: {totalItems: 0},
    data: [],
};
export interface IPromotions {
    error: boolean;
    loading: boolean;
    totalPage: {totalItems: number};
    data: [];
}
const getPromotions = (state = initialState, actions: ResponseData) => {
    switch (actions.type) {
        case GET_PROMOTIONS:
            return {...state, loading: true, error: false};
        case GET_PROMOTIONS_SUCCESS:
            return {...state, error: false, loading: false, data: actions.payload};
        case GET_PROMOTIONS_SUCCESS_PAGE:
            return {...state, error: false, loading: false, totalPage: actions.payload};
        case GET_PROMOTIONS_ERROR:
            return {...state, error: true, loading: false, data: []};
        case RESET_PROMOTIONS:
            return initialState;
        default:
            return state;
    }
};

export default getPromotions;
