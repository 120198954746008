import {
    GET_ASSIGMENT_VALIDATION,
    GET_ASSIGMENT_VALIDATION_SUCCESS,
    GET_ASSIGMENT_VALIDATION_ERROR,
    PUT_ASSIGNMENT_PROCESS,
    PUT_ASSIGNMENT_PROCESS_SUCCESS,
    PUT_ASSIGNMENT_PROCESS_ERROR,
} from '@TypesSucursal/AssignmentValidationTypes';

const initialState = {
    assignmentvalidation: [],
    error: false,
    assignmentid: null,
    loading: false,
};

const assignmentvalidation = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_ASSIGMENT_VALIDATION:
            return {
                ...state,
                loading: true,
                assignmentvalidation: [],
            };
        case GET_ASSIGMENT_VALIDATION_SUCCESS:
            return {
                ...state,
                assignmentvalidation: action.value,
                loading: false,
            };
        case GET_ASSIGMENT_VALIDATION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_ASSIGNMENT_PROCESS:
            return {
                ...state,
                loading: true,
            };
        case PUT_ASSIGNMENT_PROCESS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_ASSIGNMENT_PROCESS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default assignmentvalidation;
