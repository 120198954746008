import {ResponseData} from '@components/ManageUser/interface';
import {
    POST_IMAGE_USER,
    POST_IMAGE_USER_SUCCESS,
    POST_IMAGE_USER_ERROR,
} from '@components/ManageUser/Redux/types';

const initialState = {
    data: {},
    error: false,
    loading: false,
};

export const postImageUser = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case POST_IMAGE_USER:
            return {...state, loading: true, error: false};
        case POST_IMAGE_USER_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case POST_IMAGE_USER_ERROR:
            return {...state, loading: true, error: true};
        default:
            return state;
    }
};

export default postImageUser;
