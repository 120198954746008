import {AppDispatch} from '@/config/store';
import {
    FORM_ADD_COOWNER,
    FORM_ADD_COOWNER_ERROR,
    FORM_ADD_COOWNER_SUCCESS,
    ADD_COOWNER_FORM,
    CANCEL_ADD_COOWNER_FORM,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {IFormAddCoowner} from '@components/Quoter/CoownerBeneficiary/Redux/interfaces';

export const addCoowner = () => {
    return {
        type: FORM_ADD_COOWNER,
    };
};
export const addCoownerSuccess = (data: AxiosResponse<string>) => {
    return {
        type: FORM_ADD_COOWNER_SUCCESS,
        payload: data,
    };
};
export const addCoownerError = (value: unknown) => {
    return {
        type: FORM_ADD_COOWNER_ERROR,
        payload: value,
    };
};
export const addCoownerForm = () => {
    return {
        type: ADD_COOWNER_FORM,
    };
};
export const cancelAddCoownerForm = () => {
    return {
        type: CANCEL_ADD_COOWNER_FORM,
    };
};

export function formAddCoowner(token: string, data: IFormAddCoowner) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(addCoowner());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddCoOwner}`,
                data,
                {headers}
            );
            dispatch(addCoownerSuccess(response.data));
        } catch (error) {
            dispatch(addCoownerError(error));
        }
    };
}
