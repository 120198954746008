import {IEndorsement} from '@components/ShoppingCart/Redux/interfaces';
import {IRenewalExtension} from '@components/ShoppingCart/RenewalMenuOption/interface';
import {IPawn} from '@components/ShoppingCart/Redux/interfaces';

export const SHOPPING_CART = {
    Title: 'Carrito',
    Empty_Cart: 'Vaciar carrito',
    Total: 'Total: ',
    Price: '$ 0',
    No_results: 'No se encuentra este resultado',
    Add_to_cart: 'Agregar cliente',
    Solt: 'Saldo en monedero',
    CLOSE: 'Cerrar',
    LOCATION: 'Ubicar en inventario',
    SELECT_LOCATION: 'Selecciona ubicación',
    ROW: 'Fila',
    COLUMN: 'Columna',
    TRANSFER_SUCCESS: 'La transacción fue registrada',
    REICIEVE: 'Comprobante',
    REICIEVE_ENDORMENT: 'Comprobante de refrendo',
    IMP_RECIEVE: 'Imprimir contrato',
    DOWNLOAD_RECIEVE: 'Descargar',
    VALUE_IVA: 0,
    VIEW_SUMMARY: 'Ver resumen',
    SUBTOTAL: 'Sub total',
    IVA: 'I.V.A',
    PARTIAL_PAYMENT: 'Abono',
    SUB_TOTAL: 'Sub-total',
    IVA_RESUME: 'IVA',
    TOTAL: 'Total',
    PAY_CREDIT: 'Tarjeta de crédito',
    PAY_CASH: 'Efectivo entregado',
    CHANGE: 'Cambio entregado',
    SHARE_TICKET: 'Compartir comprobante por correo',
    PRINT_TICKET: 'Imprimir ticket',
    DOWNLOAD_TICKET: 'Descargar ticket',
    NO_GARMENTS: 'No. de prendas:',
    EXPIRATION_DATE: 'Fecha de próximo vencimiento:',
    EXTENSION: 'Extensión',
    EXTENSION_DAYS: 'Días:',
    DOCUMENT_OFICIAL: 'INE:',
    NUC: 'NUC: ',
};

export const TYPE_N_PLEDGES = {
    CONTRACT: 'CONTRACT',
    PLEDGES: 'PLEDGES',
};

export const SHOPPING_CART_CARDS = {
    Appraisal: 'Avalúo:',
    Loan: 'Préstamo:',
};

export const RENEWAL_LABELS = {
    REDEEM: 'Referendo',
    EXTEND: 'Extender',
    PAY: 'Abonar a capital',
    CANCEL: 'Cancelar operación',
};

export const RENEWAL_OPTIONS_SELECTED = {
    INSTALLMENTS: 'INSTALLMENTS',
};

type RenewalOptionsActions = {
    redeem?: () => void;
    extend: () => void;
    payTowardsPrincipal?: () => void;
    cancel?: () => void;
};

export const RENEWAL_OPTIONS = ({
    redeem,
    extend,
    payTowardsPrincipal,
    cancel,
    endorsement,
}: RenewalOptionsActions & {endorsement: IEndorsement}) => {
    const options = [
        {label: 'Desempeñar', color: '#2A2C2F', action: redeem},
        {label: 'Extender', color: '#2A2C2F', action: extend, condition: !endorsement.extension},
        {
            label: 'Abonar a capital',
            color: '#2A2C2F',
            action: payTowardsPrincipal,
            condition: !endorsement.pass,
        },
        {label: 'Cancelar operación', color: '#A82424', action: cancel},
    ];

    return options.filter((option) => option.condition !== false);
};

export const EXTENSION_OPTIONS = [
    {label: 'Editar'},
    {label: 'Cambiar a abono'},
    {label: 'Eliminar'},
];

export const TOWARDS_PRINCIPAL_OPTIONS = [{label: 'Editar'}, {label: 'Eliminar'}];

export const ENDORSEMENTS = {
    ENDORSEMENT: 'Refrendo',
    ARTICLES_COUNT: 'No. articulos ',
    CONTRACT_NUM: 'No. contrato: ',
    NEXT_PAYMENT_DATE: 'Fecha del  próximo pago',
    NEXTPAYMENT_TOOLTIP: 'Esta es la próxima fecha del refrendo',
    NEW_PAYMENT_DATE: 'Nueva fecha de pago',
    ADDITIONAL_COST: 'Costo adicional',
    EXTENSION: 'Extensión',
    DAYS: 'Días',
    EDIT: 'Editar',
    CHANGE_PARTIAL_PAYMENT: 'Cambiar a abono',
    DELETE: 'Eliminar',
};

export const INSTALLMENTS = {
    TITLE: 'Valor del abono',
    PAYMENT: 'Abono',
    OUTSTANDING_BALANCE: 'Saldo mutuo: ',
};

export interface RenewalItemProps {
    pawn: IPawn;
    mainImage: string;
    articlesCnt: number;
}

export interface menuOptions {
    label: string;
    color: string;
    onClick?: () => void;
}
export interface RenewalItemMenuProps {
    setViewingMenu: () => void;
    menuOptions: menuOptions[];
}

export const RENEWAL_ERRORS = {
    REQUIRED_PAYMENT_DATE: 'Fecha es requirida',
    FUTURE_DATE: 'Fecha debe de ser futura',
};

export const createExtension = (
    carShoppingId: number,
    endorsementId: number,
    branchId: number,
    contractId: number,
    days: number,
    totalAmount: number
): IRenewalExtension => {
    return {
        carShoppingId: carShoppingId,
        endorsementId: endorsementId,
        branchId: branchId,
        contractId: contractId,
        days: days,
        amount: totalAmount,
        vat: totalAmount * 0.16,
    };
};

export const INSTALLMENT_MENU_OPTIONS = [
    {label: 'Editar', color: '#2A2C2F', onClick: () => {}},
    {label: 'Eliminar', color: '#A82424', onClick: () => {}},
];

export const OPTIONS = {
    EXTENSION: 'extension',
    PAY_TOWARDS_PRINCIPAL: 'payTowardsPrincipal',
    INSTALLMENT: 'installment',
};
