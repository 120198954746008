import {UPDATE_IMG_COMPANY, DOWNLOAD_INFO_COMPANY} from '@MyCompany/Redux/Types/types';
import {IUpdateIMG, IUpdateIMGRedux} from '@MyCompany/Interface';

const initialState: IUpdateIMG = {
    img: '',
    loading: false,
    error: false,
    downloadInfo: '',
    loadingInfo: false,
    errorInfo: false,
};

export const updateImgCompany = (state = initialState, action: IUpdateIMGRedux): IUpdateIMG => {
    switch (action.type) {
        case UPDATE_IMG_COMPANY.START_COMPANY_IMG:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_IMG_COMPANY.SUCCESS_COMPANY_IMG:
            return {
                ...state,
                img: action.img,
                loading: false,
                error: false,
            };
        case UPDATE_IMG_COMPANY.ERROR_COMPANY_IMG:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DOWNLOAD_INFO_COMPANY.START_COMPANY_INFO:
            return {
                ...state,
                loadingInfo: true,
                errorInfo: false,
            };
        case DOWNLOAD_INFO_COMPANY.SUCCESS_COMPANY_INFO:
            return {
                ...state,
                downloadInfo: action.downloadInfo,
                loadingInfo: false,
                errorInfo: false,
            };
        case DOWNLOAD_INFO_COMPANY.ERROR_COMPANY_INFO:
            return {
                ...state,
                errorInfo: true,
                loadingInfo: false,
            };

        default:
            return state;
    }
};
