import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import ButtonGeneral from '@components/General/Atoms/Button/index';
import {ImageLogo} from '@components/LoginClient/styles';
import {addUserText, ADMIN, BOTH, POS, posADMIN, saveForm} from '@/components/NewUser/constants';
import {
    ContainerFlex,
    ContainerForm,
    ContainerGrid,
    ContainerInput,
    ContainerInputImage,
    InputFile,
    Text,
} from '@components/Shopify/Ecommerce/styles';
import elipseIcon from '@images/Ellipse 975.svg';
import elipseIconRole from '@images/Ellipse 976.svg';
import uploadImg from '@images/UploadFile.svg';
import questionImg from '@images/questionMark.svg';
import React, {useRef, useState} from 'react';
import {Controller, FieldValues, FormProvider, useForm} from 'react-hook-form';
import AddressForm from './AddressForm';
import {AdminRoleSelect} from './AdminRoleSelect';
import PosRoleSelect from './PosRoleSelect';

export const NewUser = () => {
    const {control} = useForm();
    const methods = useForm();
    const [platform, setPlatform] = useState<string>('');
    const [avatarURL, setAvatarURL] = useState<string>(uploadImg);
    const [isSelected, setIsSelected] = useState('');

    const fileUploadRef = useRef<HTMLInputElement | null>(null);

    const handleSelectRole = (option: keyof typeof ROLE_MAP) => {
        const ROLE_MAP = {
            [ADMIN]: ADMIN,
            [POS]: POS,
            [BOTH]: posADMIN,
        };

        const selectedPlatform = ROLE_MAP[option];
        setIsSelected(option);
        setPlatform(selectedPlatform);
    };

    const handleImageUpload = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        fileUploadRef.current?.click();
    };

    const uploadImageDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files?.[0];
        if (uploadedFile) {
            const cachedURL = URL.createObjectURL(uploadedFile);
            setAvatarURL(cachedURL);
        }
    };

    const submitForm = (data: FieldValues) => {
        return data;
    };

    return (
        <FormProvider {...methods}>
            <ContainerForm
                FlexDir="column"
                onSubmit={methods.handleSubmit(submitForm)}
                Height="none"
                Align="start"
                Padding="10px"
                Gap="15px"
            >
                <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F" MinH="2rem">
                    {addUserText.newUser}
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #F4F5F5"
                    Padding="12px"
                    Height="15rem"
                    Radius="20px"
                    Gap="10px"
                >
                    <Text FontWeight="600" FontSize="1.2rem" color="#2A2C2F">
                        {addUserText.personalData}
                    </Text>
                    <Text color="#6D6E71" FontSize="1rem">
                        {addUserText.details}
                    </Text>
                    <ContainerGrid GridColumns=".35fr 1fr 1fr 1fr" Height="10rem" Gap="8px">
                        <ContainerInputImage>
                            <InputFile
                                type="file"
                                ref={fileUploadRef}
                                onChange={uploadImageDisplay}
                                hidden
                            />

                            <ContainerFlex
                                Border="2px dashed blue"
                                Height="100px"
                                Width="100px"
                                Radius="50%"
                                FlexDir="column"
                                onClick={handleImageUpload}
                                Cursor="pointer"
                            >
                                <ImageLogo
                                    src={avatarURL}
                                    Height={avatarURL === uploadImg ? '30px' : '100px'}
                                    Width={avatarURL === uploadImg ? '30px' : '100px'}
                                    Radius={avatarURL === uploadImg ? 'none' : '50%'}
                                    alt="avatar"
                                />
                            </ContainerFlex>
                        </ContainerInputImage>
                        <ContainerInput>
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.name}
                            </Text>
                            <Input {...methods.register('name')} />
                        </ContainerInput>
                        <ContainerInput>
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.lastName}
                            </Text>
                            <Input {...methods.register('lastName')} />
                        </ContainerInput>
                        <ContainerInput>
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.lastNameMother}
                            </Text>
                            <Input {...methods.register('lastNameMother')} />
                        </ContainerInput>
                        <ContainerInput>
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.doB}
                            </Text>
                            <Input />
                        </ContainerInput>
                        <ImageLogo
                            src={questionImg}
                            Position="relative"
                            Height="20px"
                            Width="20px"
                            Right="40px"
                            Top="30px"
                            alt="QuestionMark"
                        />
                    </ContainerGrid>
                </ContainerFlex>

                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #F4F5F5"
                    Padding="10px"
                    Height="25rem"
                    Radius="20px"
                    Gap="13px"
                >
                    <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F">
                        {addUserText.credentials}
                    </Text>
                    <Text color="#6D6E71" FontSize="1rem">
                        {addUserText.credentialsText}
                    </Text>
                    <Text color="#6D6E71" FontSize="1rem">
                        {addUserText.select}
                    </Text>
                    <ContainerFlex Height="4rem" Gap="12px">
                        <ContainerFlex
                            onClick={() => handleSelectRole(ADMIN)}
                            backG={isSelected === ADMIN ? '#5A5AFF' : '#F0F0FF'}
                            color={isSelected === ADMIN ? 'white' : '#6D6E71'}
                            Radius="10px"
                            Cursor="pointer"
                            Gap="10px"
                        >
                            <ImageLogo
                                src={elipseIcon}
                                Height="20px"
                                Width="20px"
                                alt="AdminIcon"
                            />
                            <Text
                                Color={isSelected === ADMIN ? 'white' : '#6D6E71'}
                                FontWeight="700"
                            >
                                {addUserText.admin}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            onClick={() => handleSelectRole(POS)}
                            backG={isSelected === POS ? '#5A5AFF' : '#F0F0FF'}
                            Radius="10px"
                            Cursor="pointer"
                            Gap="10px"
                        >
                            <ImageLogo
                                src={elipseIconRole}
                                Height="20px"
                                Width="20px"
                                alt="POSIcon"
                            />
                            <Text Color={isSelected === POS ? 'white' : '#6D6E71'} FontWeight="700">
                                {addUserText.pos}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            onClick={() => handleSelectRole(BOTH)}
                            backG={isSelected === BOTH ? '#5A5AFF' : '#F0F0FF'}
                            Radius="10px"
                            Cursor="pointer"
                            Gap="10px"
                        >
                            <ImageLogo src={elipseIcon} Height="20px" Width="20px" alt="POSIcon" />
                            <ImageLogo
                                src={elipseIconRole}
                                Height="20px"
                                Width="20px"
                                alt="AdminIcon"
                            />
                            <Text
                                Color={isSelected === BOTH ? 'white' : '#6D6E71'}
                                FontWeight="700"
                            >
                                {addUserText.adminPOS}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerGrid Width="100%" Height="8rem" GridColumns="1fr 1fr" Gap="10px">
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.email}
                            </Text>
                            <Input {...methods.register('email')} />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.userName}
                            </Text>
                            <Input {...methods.register('userName')} />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.password}
                            </Text>
                            <ContainerFlex>
                                <Input type="password" {...methods.register('password')} />
                                <ImageLogo
                                    src={questionImg}
                                    Position="relative"
                                    Height="20px"
                                    Width="20px"
                                    Right="2rem"
                                    alt="QuestionMark"
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#6D6E71" FontWeight="500">
                                {addUserText.password}
                            </Text>
                            <Input type="password" {...methods.register('confirmPassword')} />
                        </ContainerFlex>
                    </ContainerGrid>
                </ContainerFlex>
                {platform === ADMIN && <AdminRoleSelect />}
                {platform === POS && <PosRoleSelect />}
                {platform === posADMIN && (
                    <ContainerFlex FlexDir="column" Gap="10px">
                        <AdminRoleSelect />
                        <PosRoleSelect />
                    </ContainerFlex>
                )}

                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #F4F5F5"
                    Padding="10px"
                    Height=""
                    Radius="20px"
                    Gap="10px"
                >
                    <AddressForm />
                </ContainerFlex>

                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #F4F5F5"
                    Padding="10px"
                    Height="10rem"
                    Radius="20px"
                    Gap="10px"
                >
                    <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F">
                        {addUserText.location}
                    </Text>
                    <Text color="#6D6E71" FontSize="1rem">
                        {addUserText.locationText}
                    </Text>
                    <ContainerFlex Width="20%">
                        <Controller
                            name="colony"
                            control={control}
                            render={({field: {onChange, value, ...field}}) => (
                                <SelectGeneral {...field} value={value} onChange={onChange} />
                            )}
                        />
                        <ImageLogo
                            src={questionImg}
                            Position="relative"
                            Height="20px"
                            Width="20px"
                            Right="4rem"
                            alt="QuestionMark"
                        />
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #F4F5F5"
                    Padding="10px"
                    Height="6rem"
                    Radius="20px"
                    Gap="10px"
                >
                    <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F">
                        {addUserText.activity}
                    </Text>
                    <Text color="#6D6E71" FontSize="1rem" TextAlign="center" Width="100%">
                        {addUserText.activtyText}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Height="5rem" Gap="35px">
                    <ButtonGeneral
                        text="Cancelar"
                        width="95px"
                        color="#FF6357"
                        backGround="white"
                        hBackG="#dfdfdf"
                    />
                    <ButtonGeneral text={saveForm} type="submit" width="95px" />
                </ContainerFlex>
            </ContainerForm>
        </FormProvider>
    );
};
