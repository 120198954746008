import {
    ADD_INFO_PRODUCT_QUOTED,
    ADD_INFO_CLIENT_QUOTED,
    COMPLETE_STEP_1,
    COMPLETE_STEP_2,
    COMPLETE_STEP_3,
    COMPLETE_STEP_4,
    COMPLETE_STEP_5,
    HIDDEN_SEARCH,
    ADD_STEP_ONE2,
    ADD_STEP_ONE3,
    CHANGE_ACTUAL_PAGE,
    ADD_DEADLINE_QUOTED,
    GET_BENEFICIARY,
    CANCEL_QUOTER,
    LOGOUT_CLIENT,
    SHOPPINGCARD,
    IMAGE_BASE_64,
    NOT_COMPLETE_STEP_5,
} from '@TypesQouter/Types';
import {GET_BENEFIST, GET_CHECK} from '@TypesQuoter/TypesGeneral';
import {ClientsQuoter} from '@/components/Shopify/ListClient/redux/interfaces';
import {
    DataInputsDinamics,
    DeadLinesQuoter,
    LevelInfo,
    StadisiticProducts,
} from '@components/Quoter/inferfaces';

export const AddImages64 = (images: string[]) => {
    return {type: IMAGE_BASE_64, images64: images};
};

export const fetchCoOwnerCheck = (value: boolean[]) => {
    return {
        type: GET_CHECK,
        checkedCoOwner: value,
    };
};

export const addProductQuoted = (product: LevelInfo) => {
    return {type: ADD_INFO_PRODUCT_QUOTED, productQuoted: product};
};

export const addDinamicsStepOne = (data: DataInputsDinamics) => ({
    type: ADD_STEP_ONE2,
    payload: data,
});

export const addDStadisticsStepOne = (data: StadisiticProducts) => ({
    type: ADD_STEP_ONE3,
    stadisticStepOne: data,
});
export const addClientQuoted = (client: ClientsQuoter) => ({
    type: ADD_INFO_CLIENT_QUOTED,
    client: client,
});
export const fetchBeneficiaryId = (value: number[]) => {
    return {
        type: GET_BENEFICIARY,
        beneficiaryId: value,
    };
};
export const fetchBenefist = (value: number[]) => {
    return {
        type: GET_BENEFIST,
        value: value,
    };
};
export const changeActualPage = (numberPage: string) => ({
    type: CHANGE_ACTUAL_PAGE,
    numberPage: numberPage,
});

export const addDeadline = (deadline: DeadLinesQuoter) => ({
    type: ADD_DEADLINE_QUOTED,
    deadline: deadline,
});

export const shoppingCard = (card: number) => ({
    type: SHOPPINGCARD,
    card: card,
});

export const cancelQuoterOperation = () => ({type: CANCEL_QUOTER});

export const logoutClient = () => ({type: LOGOUT_CLIENT});

export const hiddenSearch = () => ({type: HIDDEN_SEARCH});

export const resetStep5 = () => ({type: NOT_COMPLETE_STEP_5});

export const completeStep1 = () => ({type: COMPLETE_STEP_1});
export const completeStep2 = () => ({type: COMPLETE_STEP_2});
export const completeStep3 = () => ({type: COMPLETE_STEP_3});
export const completeStep4 = () => ({type: COMPLETE_STEP_4});
export const completeStep5 = () => ({type: COMPLETE_STEP_5});
