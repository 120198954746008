import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {RootState, AppDispatch} from '@config/store';
import {ContainerFlex, Figure, Image, Modal, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import iconDelete from '@OrganizationCreation/SubContainer/Icons/DeleteLevelOrganization.svg';
import {IPropsModalDeleteUser} from '@components/ManageUser/interface';
import {addUserNameDelete, postUpdateUser} from '@components/CreateUsers/Redux/Actions/GetAllUsers';
import {UserData} from '@components/ManageUser/Redux/interface';

export const ModalDeleteUser = ({setOpenModalDeleteUse}: IPropsModalDeleteUser) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const {idUser} = useParams();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);

    const confirmDeleteUser = () => {
        const status = false;
        const isDelete = true;
        dispatch(addUserNameDelete(UserInformation.userEmployeeName));
        if (idUser) dispatch(postUpdateUser(parseInt(idUser), status, isDelete, token));
        setOpenModalDeleteUse(false);
        navigate('/Users');
    };

    return (
        <Modal>
            <ContainerFlex
                backG="#fff"
                Width="36%"
                Height="auto"
                FlexDir="column"
                BoxS="0px 0px 12px rgba(0, 0, 0, 0.5)"
                BoxShadow=""
                Justify="start"
                Padding="24px"
                Gap="24px"
            >
                <Figure>
                    <Image src={iconDelete} />
                </Figure>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    wSpace="normal"
                    Height="auto"
                    TextAlign="center"
                >
                    ¿Estas seguro que deseas eliminar el usuario?
                </Text>
                <ContainerFlex Justify="center" Gap="24px" Height="auto">
                    <ButtonGeneral
                        text="Cancelar"
                        width="93px"
                        secondaryButton={true}
                        transform="capitalize"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => setOpenModalDeleteUse(false)}
                        border="1px solid transparent"
                    />
                    <ButtonGeneral
                        text="Eliminar"
                        width="89px"
                        transform="capitalize"
                        border="1px solid #414042"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder="#35CF44"
                        hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        dFontWeight="400"
                        clic={() => confirmDeleteUser()}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
