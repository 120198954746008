import {
    GET_CONSULT_ASSIGNMENT,
    GET_CONSULT_ASSIGNMENT_SUCCESS,
    GET_CONSULT_ASSIGNMENT_ERROR,
    PUT_CANCEL_ASSIGNMENT,
    PUT_CANCEL_ASSIGNMENT_SUCCESS,
    PUT_CANCEL_ASSIGNMENT_ERROR,
    GET_CONSULT_FILTERS_ASSIGNMENT,
} from '@TypesConsultAssignment/ConsultAssignmentTypes';

const initialState = {
    assignment: [],
    error: false,
    filters: null,
    assignmentcount: 0,
    assignmentPageIndex: 0,
    loading: false,
};

const assignment = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_CONSULT_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_CONSULT_FILTERS_ASSIGNMENT:
            return {
                ...state,
                filters: action.value,
            };
        case GET_CONSULT_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                assignment: action.value.data,
                loading: false,
                assignmentPageIndex: action.value.pageIndex,
                assignmentcount: action.value.count,
            };
        case GET_CONSULT_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_CANCEL_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case PUT_CANCEL_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_CANCEL_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default assignment;
