export const GET_EMPLOYEE_MANAGERS = 'GET_EMPLOYEE_MANAGERS';
export const GET_EMPLOYEE_MANAGERS_SUCCESS = 'GET_EMPLOYEE_MANAGERS_SUCCESS';
export const GET_EMPLOYEE_MANAGERS_ERROR = 'GET_EMPLOYEE_MANAGERS_ERROR';

export const GET_EMPLOYEE_WITNESSES = 'GET_EMPLOYEE_WITNESSES';
export const GET_EMPLOYEE_WITNESSES_SUCCESS = 'GET_EMPLOYEE_WITNESSES_SUCCESS';
export const GET_EMPLOYEE_WITNESSES_ERROR = 'GET_EMPLOYEE_WITNESSES_ERROR';

export const GET_EMPLOYEE_BY_ID = 'GET_EMPLOYEE_BY_ID';
export const GET_EMPLOYEE_BY_ID_SUCCESS = 'GET_EMPLOYEE_BY_ID_SUCCESS';
export const GET_EMPLOYEE_BY_ID_ERROR = 'GET_EMPLOYEE_BY_ID_ERROR';

export const GET_MANAGERS_BY_ID = 'GET_MANAGERS_BY_ID';
export const GET_MANAGERS_BY_ID_SUCCESS = 'GET_MANAGERS_BY_ID_SUCCESS';
export const GET_MANAGERS_BY_ID_ERROR = 'GET_MANAGERS_BY_ID_ERROR';

export const POST_SMELTING = 'POST_SMELTING';
export const POST_SMELTING_SUCCESS = 'POST_SMELTING_SUCCESS';
export const POST_SMELTING_ERROR = 'POST_SMELTING_ERROR';

export const RESET_NAMES = 'RESET_NAMES';
