import React from 'react';

import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import Finger from '@ListClient/FingerprintRegistration/Finger';
import {getSelectedPrint} from '@ListClient/constants';
import {HandProps} from '@ListClient/interfaces';

const Hand: React.FC<HandProps> = ({
    label,
    hand,
    imageSrc,
    fingers,
    onFingerClick,
    selectedFinger,
}) => {
    const handleFingerClick = (id: string) => {
        onFingerClick({hand, finger: id});
    };
    return (
        <ContainerFlex
            Position="relative"
            Padding="0 1rem"
            FlexDir="column"
            Justify="center"
            Align="center"
            Gap="1rem"
        >
            <div style={{position: 'relative', display: 'flex'}}>
                {fingers.map(({id, top, left, right}) => (
                    <Finger
                        key={id}
                        id={id}
                        hand={hand}
                        top={top}
                        left={left}
                        right={right}
                        onClick={handleFingerClick}
                        isSelected={getSelectedPrint(selectedFinger, {
                            finger: id,
                            hand,
                        })}
                    />
                ))}
                <Image src={imageSrc} Height="13.063rem" Width="8.125rem" ObjectFit="contain" />
            </div>
            <Text
                Padding="0 1rem"
                FlexDirection="column"
                Justify="center"
                Align="center"
                FontWeight="400"
                FontSize="1rem"
                Color="#000"
                oFlow="visible"
            >
                {label}
            </Text>
        </ContainerFlex>
    );
};

export default Hand;
