import {
    DELETE_ROLE_START,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_ERROR,
    DELETE_ROLE_FINISH,
    RESET_DELETE,
    DELETE_ROLE_RESET_MESSAGE,
} from '@Roles/Redux/Types/types';
import {IReducerType} from '@Roles/interface';

const initialState = {
    loading: false,
    error: false,
    success: false,
    deleteRoleSingle: null,
};

const DeleteRolesR = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case RESET_DELETE:
            return initialState;
        case DELETE_ROLE_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                deleteRoleSingle: action.payload.data,
                success: true,
            };
        case DELETE_ROLE_ERROR:
            return {
                ...state,
                error: true,
            };
        case DELETE_ROLE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case DELETE_ROLE_RESET_MESSAGE:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
};
export default DeleteRolesR;
