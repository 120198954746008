import styled from '@emotion/styled';
import InputMask from 'react-input-mask';
export const ImagesArticle = styled.img`
    width: 30px;
    height: 30px;
`;

export const InputMaskCustom = styled(InputMask)`
    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;
