import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';

import {
    CLIENT_PROFILE,
    QUICK_ACTIONS_SUCCESS,
    QUICK_ACTIONS_ERROR,
} from '@Shopify/ClientProfile/Redux/types';

export const clientQuickActionsStart = () => ({type: CLIENT_PROFILE.INITIAL_DATA});
export const clientQuickActionsSuccess = (result: AxiosResponse) => ({
    type: QUICK_ACTIONS_SUCCESS,
    actions: result.data,
});
export const clientQuickActionsError = (error: AxiosError) => ({
    type: QUICK_ACTIONS_ERROR,
    error: error,
});
export const clientQuickActions = (token: string, params: {clientId: number}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    const queryString = StringUtils.jsonToQueryString({...params});

    return async (dispatch: AppDispatch) => {
        dispatch(clientQuickActionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetClientCards}${queryString}`,
                {headers}
            );
            dispatch(clientQuickActionsSuccess(response.data));
        } catch (error) {
            dispatch(clientQuickActionsError(error as AxiosError));
        }
    };
};
