import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import upArrowSelected from '@components/Branches/Images/UpArrowSelected.svg';
import {MainInfo} from '@components/Branches/ManageBranch/BranchInfo/MainInfo';
import {ComplementaryInfo} from '@components/Branches/ManageBranch/BranchInfo/ComplementaryInfo';
import {OtherInfo} from '@components/Branches/ManageBranch/BranchInfo/OtherInfo';
import {MainForm} from '@components/Branches/ManageBranch/BranchInfo/MainForm';
import {ComplementaryForm} from '@components/Branches/ManageBranch/BranchInfo/ComplementaryForm';
import {OtherForm} from '@components/Branches/ManageBranch/BranchInfo/OtherForm';
import {IAddInfoValidate, IBranchInfo, IOtherInfoValidate} from '@components/Branches/interfaces';
import {NO_EDITED_INFO} from '@components/Branches/ManageBranch/BranchInfo/constants';
import {GetInformationBranch} from '@components/Branches/ManageBranch/Redux/Actions/GetInformationBranch';

export const BranchInfo = ({showItems, setShowItems}: IBranchInfo) => {
    const {idBranch} = useParams();
    const dispatch = useDispatch();
    const [showMainForm, setShowMainForm] = useState(false);
    const [showComplementaryForm, setShowComplementaryForm] = useState(false);
    const [showOtherForm, setShowOtherForm] = useState(false);
    const [edited, setEdited] = useState(true);
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const addInfo = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo.additionalInformation;
    });
    const otherInfo = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo.otherInformation;
    });
    const branchInfo = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo;
    });
    const {loading} = useSelector((state: RootState) => {
        return state.GetInformationBranch;
    });
    const validateInfo = (addInfo: IAddInfoValidate, otherInfo: IOtherInfoValidate) => {
        if (
            addInfo?.code === NO_EDITED_INFO.CODE ||
            addInfo?.businessName === NO_EDITED_INFO.BUSSINESS_NAME ||
            addInfo?.rfc === NO_EDITED_INFO.RFC ||
            addInfo?.phone === NO_EDITED_INFO.PHONE ||
            addInfo?.address === NO_EDITED_INFO.ADDRESS ||
            otherInfo?.manager === NO_EDITED_INFO.MANAGER ||
            otherInfo?.legalRepresentative === NO_EDITED_INFO.LEGAL_REPRESENTATIVE ||
            otherInfo?.email === NO_EDITED_INFO.EMAIL ||
            otherInfo?.contractRegistrationDate === NO_EDITED_INFO.REGISTRATION_DATE ||
            otherInfo?.publicRegistrationAdhesionNumber === NO_EDITED_INFO.REGISTRATION_DATE
        )
            setEdited(false);
    };
    useEffect(() => {
        dispatch(GetInformationBranch(Number(idBranch), token));
    }, []);
    useEffect(() => {
        validateInfo(addInfo, otherInfo);
        return () => setEdited(true);
    }, [branchInfo]);
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                backG="#f3f3f3"
                Height="56px"
                Justify="space-between"
                Radius="4px 4px 0 0"
                Border="1px solid #e5e7e9"
                Padding="18.5px 16px"
            >
                <Text FontSize="1rem" FontWeight="500" Color="#414042">
                    Información de sucursal
                </Text>
                {!showItems.info ? (
                    <Image
                        src={downArrowSelected}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                info: !showItems.info,
                                user: false,
                                schedule: false,
                                cashFlow: false,
                                services: false,
                            });
                        }}
                    />
                ) : (
                    <Image
                        src={upArrowSelected}
                        alt="up-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({...showItems, info: !showItems.info});
                        }}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="column"
                Height="auto"
                Padding={!showItems.info ? '16px' : '0 16px 16px 16px'}
                Radius="0 0 4px 4px"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                MarginBt="24px"
            >
                {!showItems.info ? (
                    <>
                        <Text FontSize="0.875rem" Color="#6d6e71">
                            Gestiona información básica de la sucursal
                        </Text>
                        {!edited && !loading && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                Completa la información requerida para operar la sucursal
                            </Text>
                        )}
                    </>
                ) : (
                    <>
                        {!showMainForm ? (
                            <MainInfo setShowMainForm={setShowMainForm} />
                        ) : (
                            <MainForm
                                showMainForm={showMainForm}
                                setShowMainForm={setShowMainForm}
                                setShowItems={setShowItems}
                                showItems={showItems}
                            />
                        )}
                        {!showComplementaryForm ? (
                            <ComplementaryInfo
                                showComplementaryForm={showComplementaryForm}
                                setShowComplementaryForm={setShowComplementaryForm}
                            />
                        ) : (
                            <ComplementaryForm
                                showComplementaryForm={showComplementaryForm}
                                setShowComplementaryForm={setShowComplementaryForm}
                                setShowItems={setShowItems}
                                showItems={showItems}
                            />
                        )}
                        {!showOtherForm ? (
                            <OtherInfo
                                showOtherForm={showOtherForm}
                                setShowOtherForm={setShowOtherForm}
                            />
                        ) : (
                            <OtherForm
                                showOtherForm={showOtherForm}
                                setShowOtherForm={setShowOtherForm}
                                setShowItems={setShowItems}
                                showItems={showItems}
                            />
                        )}
                    </>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
