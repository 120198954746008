import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {CardLevels} from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/CardLevels';
import {LevelSelected} from '@ActionsDeadlinesindex/Search';
import {GroupLevel} from '@ActionsDeadlinesindex/GetLevels';
import {cardRoutes} from '@/components/DeadLines/DeadlinesIndex/redux/Interface';

export const CardComponent = (props: cardRoutes) => {
    const {Hiden, showLevelNumber = true, Branch} = props;
    const LevelContents = useSelector((state: RootState) => state.DataLevels);
    const routes = useSelector((state: RootState) => state.deadLinesRoutes);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const dispatch: AppDispatch = useDispatch();

    const levelID = () => {
        if (routes.length) {
            const LEVEL_ID: {[key: number]: {id: number}} = {
                1: routes[0],
                2: routes[1],
                3: routes[2],
                4: routes[3],
                5: routes[4],
            };
            return LEVEL_ID[routes.length].id;
        }
    };

    const levelGral = {
        branch: Branch,
        Hiden: Hiden,
        showLevelNumber: showLevelNumber,
        token: token,
        dispatch: dispatch,
        articles: routes,
        Status: [],
    };
    const PropsGroup = {
        level: 1,
        levelList: LevelContents.Group,
        idHigher: 0,
        Category: 'Grupo',
        levelGral,
    };
    const PropsFamily = {
        level: 2,
        levelList: LevelContents.Family,
        idHigher: levelID(),
        Category: 'Familia',
        levelGral,
    };
    const PropsSubFamily = {
        level: 3,
        levelList: LevelContents.Subfamily,
        idHigher: levelID(),
        Category: 'SubFamilia',
        levelGral,
    };
    const PropsBrand = {
        level: 4,
        levelList: LevelContents.Brand,
        idHigher: levelID(),
        Category: 'Marcas',
        levelGral,
    };
    const PropsArtile = {
        level: 5,
        levelList: LevelContents.Article,
        idHigher: levelID(),
        Category: 'Artículos',
        levelGral,
    };

    useEffect(() => {
        dispatch(GroupLevel(token, String(Branch)));
        dispatch(LevelSelected('group'));
    }, [Branch]);

    return (
        <>
            {(routes.LevelSelected === 'group' || routes.LevelSelected === '') && (
                <CardLevels {...PropsGroup} />
            )}
            {routes.LevelSelected === 'family' && <CardLevels {...PropsFamily} />}
            {routes.LevelSelected === 'subfamily' && <CardLevels {...PropsSubFamily} />}
            {routes.LevelSelected === 'brand' && <CardLevels {...PropsBrand} />}
            {routes.LevelSelected === 'article' && <CardLevels {...PropsArtile} />}
        </>
    );
};
