import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    PUT_UPDATE_SAVE_DOCUMENTS_ID_ERROR,
    PUT_UPDATE_SAVE_DOCUMENTS_ID_START,
    PUT_UPDATE_SAVE_DOCUMENTS_ID_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IUpdateSaveDocumentsId} from '@components/PersonalLoans/interfaces';

export const postUpdateSaveDocumentsIdStart = () => {
    return {
        type: PUT_UPDATE_SAVE_DOCUMENTS_ID_START,
    };
};

export const postUpdateSaveDocumentsIdSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_UPDATE_SAVE_DOCUMENTS_ID_SUCCESS,
        payload: result.data,
    };
};

export const postUpdateSaveDocumentsIdError = (error: AxiosError) => {
    return {
        type: PUT_UPDATE_SAVE_DOCUMENTS_ID_ERROR,
        payload: error,
    };
};

export const updateSaveDocumentsId = (data: IUpdateSaveDocumentsId, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postUpdateSaveDocumentsIdStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateSaveDocumentsId}`,
                data,
                {headers}
            );
            dispatch(postUpdateSaveDocumentsIdSuccess(response));
        } catch (error) {
            dispatch(postUpdateSaveDocumentsIdError(error as AxiosError));
        }
    };
};
