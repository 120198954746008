import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {POPUPTEXTROLES} from '@Roles/constants';
import {ISearchListEdit} from '@Roles/AddRoles/interfaces';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerFlex, Text, Image, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {getAllUserRole} from '@Roles/CreateRoles/Redux/Actions/GetSearchUserByRole';
import {IItemSearch, ISearchUserByRole} from '@Roles/CreateRoles/Redux/Reducer/GetUserByRole';

export const AssigmentSearchListEdit = ({setShowModalRole, setSelectedUser}: ISearchListEdit) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const RolesUsersError: ISearchUserByRole = useSelector(
        (state: RootState) => state.GetSearchUserByRole
    );
    const {idRole} = useSelector((state: RootState) => state.RoleById);
    useEffect(() => {
        const Search = {
            roleId: idRole,
            search: '',
        };
        if (token) dispatch(getAllUserRole(token, Search));
    }, []);

    return (
        <>
            <ContainerFlex Display="grid" GridColumns="1fr 1fr 1fr" Justify="start" Height="2.5rem">
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F">
                    {POPUPTEXTROLES.USERNAME}
                </Text>
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F">
                    {POPUPTEXTROLES.E_MAIL}
                </Text>
                <Text FontSize="0.875rem" FontWeight="500" Color="#2A2C2F">
                    {POPUPTEXTROLES.REMOVE}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Gap="16px"
                OverFlow="auto"
                Height="9rem"
            >
                {RolesUsersError.loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    RolesUsersError.data &&
                    RolesUsersError.data.length > 0 &&
                    RolesUsersError.data.map((item: IItemSearch) => (
                        <ContainerFlex
                            key={item.userId}
                            Display="grid"
                            GridColumns="repeat(3, 1fr)"
                            Justify="start"
                            Height="auto"
                            Gap="8px"
                        >
                            <ContainerFlex ColumnGap="16px" Justify="start">
                                <Image
                                    src={item.image}
                                    Width="32px"
                                    Height="32px"
                                    Radius="50%"
                                    ObjectFit="cover"
                                />
                                <TextEllipsis
                                    Color="#2A2C2F"
                                    FontSize="0.875rem"
                                    FontWeight="400"
                                    Width="15rem"
                                >
                                    {item.userName}
                                </TextEllipsis>
                            </ContainerFlex>

                            <ContainerFlex
                                FlexDir="column"
                                Align="start"
                                Justify="start"
                                Width="auto"
                                Height="auto"
                            >
                                <TextEllipsis
                                    Color="#2A2C2F"
                                    FontSize="0.875rem"
                                    FontWeight="400"
                                    Width="auto"
                                >
                                    {item.email}
                                </TextEllipsis>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start">
                                <ContainerFlex
                                    Radius="32px"
                                    Width="auto"
                                    Border="1px solid #5A5AFF"
                                    Padding="0 16px"
                                    Cursor="pointer"
                                    onClick={() => {
                                        setShowModalRole(true);
                                        setSelectedUser(item.userId);
                                    }}
                                >
                                    <TextEllipsis
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                        Width="auto"
                                        Color="#5A5AFF"
                                        Cursor="pointer"
                                    >
                                        {POPUPTEXTROLES.REMOVE_ACCESS_ROL}
                                    </TextEllipsis>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    ))
                )}
            </ContainerFlex>
        </>
    );
};
