import styled from '@emotion/styled';
import {IPropsTabsContainer} from '@General/ModuleTabs/styles/interfacesStyles';
import TabContainer from '@General/ModuleTabs/TabContainer';

export const ContainerTabs = styled(TabContainer)<IPropsTabsContainer>`
    display: flex;
    gap: ${(props) => props.Gap};
    flex-direction: ${(props) => props.FlexDir};
    align-items: ${(props) => props.Align};
    justify-content: ${(props) => props.Justify};
`;
