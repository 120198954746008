import styled from '@emotion/styled';
import {IPropsImageIcon, IPropsMenuItem} from '@OrganizationCreation/SubContainer/interfacesStyles';
export const BolderWords = {
    FontSize: '0.875rem',
    FontWeight: '500',
    Color: '#414042',
    Align: 'end',
    Height: 'auto',
};

export const NormalWords = {
    FontSize: '0.875rem',
    Color: '#414042',
    FontWeight: 'normal',
    Align: 'end',
    Height: 'auto',
};

export const MenuItem = styled.div<IPropsMenuItem>`
    display: flex;
    justify-content: flex-start;
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    align-items: ${(props) => props.Align};
    margin: ${(props) => props.Margin};
    flex-direction: ${(props) => props.FlexDir};
    &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
    }
`;
MenuItem.defaultProps = {
    Height: '40px',
    Padding: '8px',
    Width: '100%',
};

export const ImageIcon = styled.img<IPropsImageIcon>`
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    cursor: ${(props) => props.cursor};
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    position: ${(props) => props.Position};
    left: ${(props) => props.Left};
    right: ${(props) => props.Right};
    justify-self: flex-end;
`;

ImageIcon.defaultProps = {
    height: '16px',
    width: '16px',
    margin: '0 5px 0 0',
};
