import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Divisor} from '@/components/QuickPayment/SuccessPaymentSummary/ResumenPagoExitosoStyles';
import {HEADER_TITLE} from '@/components/QuickPayment/Constants';

const SuccessHeader = () => {
    return (
        <ContainerFlex Height="49px" Align="flex-start" FlexDir="column">
            <Text FontFamily="Nunito" FontSize="1.5rem" FontWeight="700">
                {HEADER_TITLE}
            </Text>
            <Divisor margin="16px 0 0 0" />
        </ContainerFlex>
    );
};

export default SuccessHeader;
