import {AppDispatch} from '@/config/store';
import {
    GET_AUDITORS,
    GET_AUDITORS_SUCCESS,
    GET_AUDITORS_ERROR,
} from '@TypesAuditAssignment/AuditorsTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';

export const fetchGetAuditors = () => {
    return {
        type: GET_AUDITORS,
    };
};
export const fetchGetAuditorsSuccess = (result: {data: unknown}) => {
    return {
        type: GET_AUDITORS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetAuditorsError = () => {
    return {
        type: GET_AUDITORS_ERROR,
    };
};
export const getAuditors = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetAuditors());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Auditors}`, {headers})
            .then((result) => {
                dispatch(fetchGetAuditorsSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetAuditorsError());
            });
    };
};
