import {
    TEMPORAL_PAWN,
    TEMPORAL_PAWN_ERROR,
    TEMPORAL_PAWN_SUCCESS,
    TEMPORAL_PAWN_BLOCK,
} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    temporalPawn: [],
    blockActivate: false,
};

const postCarPawn = (
    state = initialState,
    actions: {block: boolean; type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case TEMPORAL_PAWN:
            return {
                ...state,
                loading: true,
            };
        case TEMPORAL_PAWN_SUCCESS:
            return {
                ...state,
                loading: false,
                temporalPawn: actions.payload.data.data,
            };
        case TEMPORAL_PAWN_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case TEMPORAL_PAWN_BLOCK:
            return {
                ...state,
                blockActivate: actions.block,
                loading: false,
            };
        default:
            return state;
    }
};

export default postCarPawn;
