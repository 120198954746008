import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {
    GET_HOME_ADMIN,
    GET_HOME_ADMIN_ERROR,
    GET_HOME_ADMIN_SUCCESS,
} from '@components/Home/Admin/Redux/types';

export const getHomeAdmin = () => {
    return {
        type: GET_HOME_ADMIN,
    };
};
export const getHomeAdminSuccess = (result: AxiosResponse<string>) => {
    return {
        type: GET_HOME_ADMIN_SUCCESS,
        payload: result.data,
    };
};

export const getHomeAdminError = (err: unknown) => {
    return {
        type: GET_HOME_ADMIN_ERROR,
        payload: err,
    };
};
export function getAdminHomePercentage(companyId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getHomeAdmin());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.getAdminHomePercentage.replace(
                    '{companyId}',
                    String(companyId)
                )}`,
                {headers}
            );
            dispatch(getHomeAdminSuccess(response));
        } catch (err) {
            dispatch(getHomeAdminError(err));
        }
    };
}
