import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    amounts: Yup.array().of(Yup.string().required('Es requerido')),
});

export const validationBranchSchema = Yup.object().shape({
    amount: Yup.string().required('Es requerido'),
    message: Yup.string(),
});

export const validationRegisterMotions = Yup.object().shape({
    type: Yup.string().required('Es requerido'),
    box: Yup.string().required('Es requerido'),
    amount: Yup.string().required('Es requerido'),
});

export const valideReturnOfSurplus = Yup.object().shape({
    movementType: Yup.string().required('Es requerido'),
    userBox: Yup.string().required('Es requerido'),
    amount: Yup.string().required('Es requerido'),
});
