import {
    GET_AUDITOR_BY_ASSIGNMENT,
    GET_AUDITOR_BY_ASSIGNMENT_SUCCESS,
    GET_AUDITOR_BY_ASSIGNMENT_ERROR,
    GET_REGION_BY_ASSIGNMENT,
    GET_REGION_BY_ASSIGNMENT_SUCCESS,
    GET_REGION_BY_ASSIGNMENT_ERROR,
    GET_STATUS_BY_ASSIGNMENT,
    GET_STATUS_BY_ASSIGNMENT_SUCCESS,
    GET_STATUS_BY_ASSIGNMENT_ERROR,
    GET_DATERANGE_BY_ASSIGNMENT,
    GET_DATERANGE_BY_ASSIGNMENT_SUCCESS,
    GET_DATERANGE_BY_ASSIGNMENT_ERROR,
    GET_SUCURSAL_BY_ASSIGNMENT,
    GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS,
    GET_SUCURSAL_BY_ASSIGNMENT_ERROR,
} from '@TypesConsultAssignment/ConsultAssignmentFilterTypes';
import {IConsultFilter, IConsultFilterRedux} from '@Foundry/ConsultAssignment/interfaces';

const initialState: IConsultFilter = {
    auditfilter: [],
    regionfilter: [],
    statusfilter: [],
    sucursalfilter: [],
    filters: null,
    daterangefilter: null,
    error: false,
    loading: false,
};

const assignmentfilter = (state = initialState, action: IConsultFilterRedux): IConsultFilter => {
    switch (action.type) {
        case GET_AUDITOR_BY_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_AUDITOR_BY_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                auditfilter: action.auditfilter,
                loading: false,
            };
        case GET_AUDITOR_BY_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_REGION_BY_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_REGION_BY_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                regionfilter: action.regionfilter,
                loading: false,
            };
        case GET_REGION_BY_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_STATUS_BY_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_STATUS_BY_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                statusfilter: action.statusfilter,
                loading: false,
            };
        case GET_STATUS_BY_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_DATERANGE_BY_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_DATERANGE_BY_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                filters: action.filters,
                daterangefilter: action.daterangefilter,
                loading: false,
            };
        case GET_DATERANGE_BY_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SUCURSAL_BY_ASSIGNMENT:
            return {
                ...state,
                loading: true,
            };
        case GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                sucursalfilter: action.sucursalfilter,
                loading: false,
            };
        case GET_SUCURSAL_BY_ASSIGNMENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default assignmentfilter;
