import React, {useState} from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {UpdateAddress} from '@MyCompany/ModalAddress/UpdateAddress';
import ButtonGeneral from '@/components/General/Atoms/Button';

export const Enterprice = () => {
    const [updateAddress, setUpdateAddress] = useState(false);

    return (
        <ContainerFlex>
            <ButtonGeneral clic={() => setUpdateAddress(true)} text={'Actualizar dirección'} />
            {updateAddress && (
                <UpdateAddress updateAddress={updateAddress} setUpdateAddress={setUpdateAddress} />
            )}
        </ContainerFlex>
    );
};
