import {AppDispatch} from '@/config/store';
import {AxiosError} from 'axios';
import {TRANSACTIONS} from '@Shopify/ClientProfile/Redux/types';
import {OPERATIONS} from '@Shopify/ClientProfile/constants';
import {ITransactions} from '@Shopify/ClientProfile/interfaces';

export const transactionsStart = () => ({type: TRANSACTIONS.INITIAL_TRANSACTIONS_DATA});

export const transactionsSuccess = (result: ITransactions[]) => ({
    type: TRANSACTIONS.SUCCESS,
    transactions: result,
});

export const transactionsError = (error: AxiosError) => ({
    type: TRANSACTIONS.ERROR,
    error: error,
});

export const resetTrasactions = () => ({type: TRANSACTIONS.RESET_TRANSACTIONS});

export const getClientTransactions = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(transactionsStart());
        try {
            setTimeout(() => {
                dispatch(transactionsSuccess(OPERATIONS.TRANSACTIONS.ALL_TRANSACTIONS));
            }, 1000);
        } catch (error) {
            dispatch(transactionsError(error as AxiosError));
        }
    };
};
