import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {useSelector} from 'react-redux';
import {DETAIL_COMPONENT} from '@/components/TransactionsDetails/Operational/Constants';
import share from '@images/shareIconeDetail.svg';
import {ImageLogo, Line} from '@/components/TransactionsDetails/Operational/styles';
import {IconImg} from '@/components/TransactionsDetails/Operational/styles';
import {ProductListContainer} from '@/components/TransactionsDetails/ProductListContainer';
import {ProductTotalDetails} from '@/components/TransactionsDetails/ProducTotalDetails';
import {CashUserDetails} from '@/components/TransactionsDetails/CashUserDetails';
import {RootState} from '@/config/store';
import {IPawnData} from '@components/TransactionsDetails/Redux/interface';
import {formatterDolar} from '@/hooks/currentFormatUtils';

export const TransactionDetails = () => {
    const PawnsDetails: IPawnData = useSelector((state: RootState) => {
        return state.GetPawnDetails;
    });
    return (
        <ContainerFlex FlexDir="column" backG="#FAFAFA">
            <ContainerFlex
                Border="1px solid #E8E9EA"
                Padding="16px"
                Height="max-content"
                Width="817px"
                Radius="24px"
                backG="#fff"
            >
                <ContainerFlex FlexDir="column" Gap="16px">
                    <ContainerCellGrid Height="max-content" Gap="16px">
                        <ContainerFlex Height="max-content" FlexDir="column">
                            <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                                {DETAIL_COMPONENT.TITTLE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Cursor="pointer"
                            Width="40px"
                            Height="40px"
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            FlexDir="column"
                        >
                            <ImageLogo Width="24px" Height="24px" src={share} />
                        </ContainerFlex>
                    </ContainerCellGrid>
                    <Line />
                    <ContainerFlex Padding="0px 8px" Height="max-content">
                        <ContainerCellGrid Gap="8px">
                            <ContainerFlex Width="38px" Height="38px" Radius="38px">
                                <IconImg src={PawnsDetails.catState.customerImageUrl} alt="user" />
                            </ContainerFlex>
                            <ContainerFlex Align="start" Height="max-content" FlexDir="column">
                                <Text FontWeight="500" FontSize="1rem" Color="#5A5AFF">
                                    {PawnsDetails.catState.customerName}
                                </Text>
                                <ContainerFlex ColumnGap="8px" Align="start" Width="max-content">
                                    <Text
                                        Color="54575C"
                                        FontWeight="400"
                                        FontSize="0.875rem"
                                        TextAlign="start"
                                    >
                                        {DETAIL_COMPONENT.TEXT_NUC}
                                    </Text>
                                    <Text Color="#54575C" FontWeight="500" FontSize="0.875rem">
                                        {PawnsDetails.catState.customerNuc}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex ColumnGap="8px" Width="max-content">
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {DETAIL_COMPONENT.BALANCE}
                                </Text>
                                <Text Color="#54575C" FontWeight="700" FontSize="0.875rem">
                                    {formatterDolar.format(PawnsDetails.catState.customerBalance)}
                                </Text>
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                    <Line />
                    <ContainerFlex Height="max-content">
                        <ContainerCellGrid>
                            <ContainerFlex Width="max-content" ColumnGap="8px" Align="start">
                                <Text
                                    FontSize="0.75rem"
                                    Border="1px solid #FFC6F6"
                                    BGColor="#FFE5FB"
                                    Color="#610052"
                                    BorderRadius="32px"
                                    Padding="2px 8px"
                                    TextAlign="start"
                                >
                                    {DETAIL_COMPONENT.TYPE}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex />
                            <ContainerFlex
                                ColumnGap="8px"
                                Width="max-content"
                                AlignContent=" right"
                                Align="center"
                            >
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {DETAIL_COMPONENT.INVOICE}
                                </Text>
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {PawnsDetails.catState.contractNumber}
                                </Text>
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {DETAIL_COMPONENT.OR}
                                </Text>
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {DETAIL_COMPONENT.DEADLINES}
                                </Text>
                                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem">
                                    {PawnsDetails.catState.contractTerm}
                                </Text>
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                    <ProductListContainer />
                    <Line />
                    <ProductTotalDetails />
                    <Line />
                    <CashUserDetails />
                    <Line />
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                            {DETAIL_COMPONENT.LOCATION}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex>
                        <ContainerCellGrid>
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text Color="#54575C" FontWeight="700">
                                    {DETAIL_COMPONENT.ROW}
                                </Text>
                                <Text Color="#54575C" FontSize="1rem">
                                    {PawnsDetails.catState.inventory.position}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text Color="#54575C" FontWeight="700" FontSize="1rem">
                                    {DETAIL_COMPONENT.FILE}
                                </Text>
                                <Text Color="#54575C" FontSize="1rem">
                                    {PawnsDetails.catState.inventory.row}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="start" FlexDir="column">
                                <Text Color="#54575C" FontWeight="700" FontSize="1rem">
                                    {DETAIL_COMPONENT.COLUMN}
                                </Text>
                                <Text Color="#54575C" FontSize="1rem">
                                    {PawnsDetails.catState.inventory.column}
                                </Text>
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
