import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import getAllCatBankAction from '@/components/PersonalLoans/Redux/Actions/GetAllCatBankActions';
import {ICreditLoanBankTransaction} from '@/components/PersonalLoans/interfaces';
import {Controller} from 'react-hook-form';

const PersonalLoansBankTransaction: React.FC<ICreditLoanBankTransaction> = ({control, errors}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {data} = useSelector((state: RootState) => {
        return state.getAllCatBank;
    });
    useEffect(() => {
        if (token) {
            dispatch(getAllCatBankAction(token));
        }
    }, []);

    const options =
        data?.map((bank) => ({
            label: bank.description,
            value: bank.id,
        })) ?? [];

    return (
        <ContainerFlex Gap="16px" Align="start" Justify="space-between">
            <ContainerFlex
                Width="100%"
                Height="100%"
                FlexDir="column"
                Justify="start"
                Align="start"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANK_TRANSFER_TITLE_LB}
                </Text>
                <Controller
                    name="catBank"
                    control={control}
                    render={({field}) => (
                        <ContainerFlex FlexDir="column" Justify="start" Align="start">
                            <SelectGeneral
                                {...field}
                                options={options}
                                onChange={(selectedOption) =>
                                    field.onChange(selectedOption?.value || '')
                                }
                                value={
                                    options?.find(
                                        (option) =>
                                            option.value === parseInt(field.value as string, 10)
                                    ) || null
                                }
                                placeholder={
                                    PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                        .BANK_TRANSFER_SELECT_PLACEHOLDER
                                }
                            />
                            {errors.catBank && (
                                <Text FontFamily="Nunito" FontSize="0.8rem" Color="#ff6357">
                                    {errors.catBank.message}
                                </Text>
                            )}
                        </ContainerFlex>
                    )}
                />
            </ContainerFlex>
            <ContainerFlex
                Width="100%"
                Height="100%"
                FlexDir="column"
                Justify="start"
                Align="start"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANK_TRANSFER_PASSWORD_LB}
                </Text>
                <Controller
                    name="keyAccount"
                    control={control}
                    render={({field}) => (
                        <ContainerFlex FlexDir="column" Justify="start" Align="start">
                            <Input
                                {...field}
                                type="password"
                                placeholder={
                                    PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                        .BANK_TRANSFER_PASSWORD_PLACEHOLDER
                                }
                                maxLength={18}
                            />
                            {errors.keyAccount && (
                                <Text FontFamily="Nunito" FontSize="0.8rem" Color="#ff6357">
                                    {errors.keyAccount.message}
                                </Text>
                            )}
                        </ContainerFlex>
                    )}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalLoansBankTransaction;
