import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {AppDispatch} from '@/config/store';
import paymenteIcon from '@images/IconCongratulations.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import {fetchAddCarrito} from '@Products/redux/actions/ProductsShopifyActions';
import {ContainerFlex, Text} from '@EcommerceShopify/styles';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    return (
        <>
            <ContainerFlex Padding="50px" Align="flex-start">
                <ContainerFlex Width="40%">
                    <ImgLocation Width="80%" Height="auto" src={paymenteIcon} />
                </ContainerFlex>
                <ContainerFlex Width="40%" FlexDir="column" Justify="flex-start" Align="flex-start">
                    <ContainerFlex Align="flex-start" Padding="0 0 50px 0" Margin="28px 0">
                        <Text Height="auto" FontSize="30px">
                            ¡Gracias por tu Pago!
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="50px 0"
                        onClick={async () => {
                            dispatch(fetchAddCarrito([]));
                        }}
                    >
                        <ButtonGeneral
                            hideborder=""
                            border="white"
                            transform="capitalize"
                            width="167px"
                            text="Regresar al home"
                            clic={() => navigate('/')}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};

export default PaymentSuccess;
