import {
    GET_ORGANIZATION_TREE_SUCCESS,
    GET_ORGANIZATION_TREE_ERROR,
    GET_ORGANIZATION_TREE,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    tree: [],
    loading: false,
    error: false,
};
const GetBalanceTree = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_ORGANIZATION_TREE:
            return {
                ...state,
                loading: false,
            };
        case GET_ORGANIZATION_TREE_SUCCESS:
            return {
                ...state,
                tree: action.payload.data,
                loading: false,
            };
        case GET_ORGANIZATION_TREE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default GetBalanceTree;
