import axios, {AxiosResponse} from 'axios';
import {
    PUT_SERVICES_COMPLEMENTS,
    PUT_SERVICES_COMPLEMENTS_ERROR,
    PUT_SERVICES_COMPLEMENTS_FINISH,
    PUT_SERVICES_COMPLEMENTS_RESET,
    PUT_SERVICES_COMPLEMENTS_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IPutDataServices} from '@/components/Branches/interfaces';
import {GetServicesComplements} from '@/components/Branches/ManageBranch/Redux/Actions/GetServicesComplements';

export const fetchPutServicesComplementsStart = () => {
    return {
        type: PUT_SERVICES_COMPLEMENTS,
    };
};
export const fetchPutServicesComplementsSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_SERVICES_COMPLEMENTS_SUCCESS,
        payload: result,
    };
};
export const fetchPutServicesComplementsError = () => {
    return {
        type: PUT_SERVICES_COMPLEMENTS_ERROR,
    };
};
export const fetchPutServicesComplementsFinish = () => {
    return {
        type: PUT_SERVICES_COMPLEMENTS_FINISH,
    };
};
export const fetchPutServicesComplementsReset = () => {
    return {
        type: PUT_SERVICES_COMPLEMENTS_RESET,
    };
};

export function PutServicesComplements(
    data: IPutDataServices,
    token: string,
    setShowServicesForm: (e: boolean) => void,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutServicesComplementsStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutServicesComplements}`,
                data,
                {headers}
            );
            dispatch(fetchPutServicesComplementsSuccess(response));
            setShowServicesForm(false);
        } catch (error) {
            dispatch(fetchPutServicesComplementsError());
        } finally {
            dispatch(fetchPutServicesComplementsFinish());
            dispatch(GetServicesComplements(idBranch, token));
        }
    };
}
