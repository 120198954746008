import React, {useMemo, useCallback} from 'react';
import {parse} from 'date-fns';
import {DATE_FORMATS} from '@components/Shopify/ListClient/constants';

const useDatePickerUtils = () => {
    const determinePickerProps = useCallback((format) => {
        switch (format) {
            case DATE_FORMATS.MONTH:
                return {
                    dateFormat: DATE_FORMATS.MONTH,
                    showMonthYearPicker: true,
                    renderCustomHeader: () => <div />,
                };
            case DATE_FORMATS.YEAR:
                return {
                    dateFormat: DATE_FORMATS.YEAR,
                    showYearPicker: true,
                    yearDropdownItemNumber: 10,
                    scrollableYearDropdown: true,
                    minDate: new Date(),
                    maxDate: new Date(new Date().getFullYear() + 10, 11, 31),
                };
            default:
                return {
                    dateFormat: format,
                };
        }
    }, []);

    const formatDate = useCallback((date, format) => {
        if (!date) return '';
        switch (format) {
            case DATE_FORMATS.MONTH:
                return date.toLocaleDateString('en', {month: '2-digit'});
            case DATE_FORMATS.YEAR:
                return date.getFullYear().toString();
            default:
                return date.toLocaleDateString('en');
        }
    }, []);

    const parseDate = useCallback((value, format) => {
        try {
            switch (format) {
                case DATE_FORMATS.MONTH:
                    return parse(value, DATE_FORMATS.MONTH, new Date());
                case DATE_FORMATS.YEAR:
                    return parse(value, DATE_FORMATS.YEAR, new Date());
                case DATE_FORMATS.FULL_DATE:
                    return parse(value, DATE_FORMATS.FULL_DATE, new Date());
                default:
                    return parse(value, format, new Date());
            }
        } catch (error) {
            return null;
        }
    }, []);

    return useMemo(
        () => ({
            determinePickerProps,
            formatDate,
            parseDate,
        }),
        [determinePickerProps, formatDate, parseDate]
    );
};

export default useDatePickerUtils;
