import {useMemo} from 'react';

export const useDataParser = () => {
    const splitName = (fullName: string) => {
        const nameParts = fullName.trim().split(/\s+/);
        const firstName = nameParts.length <= 2 ? nameParts[0] : nameParts.slice(0, -2).join(' ');
        const lastName =
            nameParts.length <= 2
                ? nameParts.slice(1).join(' ') || ''
                : nameParts.slice(-2).join(' ');

        return {
            firstName: capitalizeNames(firstName),
            lastName: capitalizeNames(lastName),
        };
    };

    const splitLastNames = (lastName: string) => {
        const lastNameParts = lastName.split(' ');
        const paternalLastName = capitalizeNames(lastNameParts[0] || '-');
        const maternalLastName = capitalizeNames(lastNameParts[1] || '-');

        return {
            paternalLastName,
            maternalLastName,
        };
    };

    const parseAddress = (address: string) => {
        const addressPattern =
            /^(.+),\s*([\d]+),\s*([\d]+),\s*(.+),\s*C\.P\.\:\s*([\d]+),\s*(.+),\s*(.+)$/;
        const match = address.trim().match(addressPattern);

        if (!address || address.trim() === '-' || address.trim() === '' || !match) {
            return {
                street: '-',
                exteriorNum: '-',
                interiorNum: '-',
                colony: '-',
                postalCode: '-',
                municipality: '-',
                state: '-',
            };
        }

        const [, street, exteriorNum, interiorNum, colony, postalCode, municipality, state] = match;

        return {
            street: capitalizeNames(street.trim()),
            exteriorNum: exteriorNum.trim(),
            interiorNum: interiorNum.trim(),
            colony: capitalizeNames(colony.trim()),
            postalCode: postalCode.trim(),
            municipality: capitalizeNames(municipality.trim()),
            state: capitalizeNames(state.trim()),
        };
    };

    const capitalizeNames = (name: string) =>
        name
            .split(' ')
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
            .join(' ');

    return useMemo(() => ({splitName, splitLastNames, parseAddress}), []);
};
