import React from 'react';

export function HeadTableDocumentFoundry() {
    return (
        <thead className="title-head-foundry">
            <tr>
                <th>Kilates</th>
                <th className="text-center">Cantidad</th>
                <th className="text-center">Gramos</th>
                <th className="text-center">Costo</th>
            </tr>
        </thead>
    );
}
