import {IAssignmentFoundry, IAssignmentFoundryRedux} from '@/components/Foundry/interfaces';
import {
    GET_EMPLOYEE_MANAGERS,
    GET_EMPLOYEE_MANAGERS_SUCCESS,
    GET_EMPLOYEE_MANAGERS_ERROR,
    GET_EMPLOYEE_WITNESSES,
    GET_EMPLOYEE_WITNESSES_ERROR,
    GET_EMPLOYEE_WITNESSES_SUCCESS,
    GET_EMPLOYEE_BY_ID,
    GET_EMPLOYEE_BY_ID_ERROR,
    GET_EMPLOYEE_BY_ID_SUCCESS,
    GET_MANAGERS_BY_ID,
    GET_MANAGERS_BY_ID_ERROR,
    GET_MANAGERS_BY_ID_SUCCESS,
    POST_SMELTING,
    POST_SMELTING_SUCCESS,
    POST_SMELTING_ERROR,
    RESET_NAMES,
} from '@TypesAssignmentFoundry/AssignmentFoundryTypes';

const initialState: IAssignmentFoundry = {
    managers: [],
    witnesses: [],
    managersname: '',
    witnessesname: '',
    error: false,
    loading: false,
    loadingpost: false,
};

const employeemanagers = (
    state = initialState,
    action: IAssignmentFoundryRedux
): IAssignmentFoundry => {
    switch (action.type) {
        case GET_EMPLOYEE_MANAGERS:
            return {
                ...state,
                loading: true,
            };
        case GET_EMPLOYEE_MANAGERS_SUCCESS:
            return {
                ...state,
                managers: action.managers,
                loading: false,
            };
        case GET_EMPLOYEE_MANAGERS_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_EMPLOYEE_WITNESSES:
            return {
                ...state,
                loading: true,
            };
        case GET_EMPLOYEE_WITNESSES_SUCCESS:
            return {
                ...state,
                witnesses: action.witnesses,
                loading: false,
            };
        case GET_EMPLOYEE_WITNESSES_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_EMPLOYEE_BY_ID:
            return {
                ...state,
                loading: true,
            };
        case GET_EMPLOYEE_BY_ID_SUCCESS:
            return {
                ...state,
                witnessesname: action.witnessesname,
                loading: false,
            };
        case GET_EMPLOYEE_BY_ID_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_MANAGERS_BY_ID:
            return {
                ...state,
                loading: true,
            };
        case GET_MANAGERS_BY_ID_SUCCESS:
            return {
                ...state,
                managersname: action.managersname,
                loading: false,
            };
        case GET_MANAGERS_BY_ID_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_SMELTING:
            return {
                ...state,
                loadingpost: true,
            };
        case POST_SMELTING_SUCCESS:
            return {
                ...state,
                loadingpost: false,
            };
        case POST_SMELTING_ERROR:
            return {
                ...state,
                error: true,
                loadingpost: false,
            };
        case RESET_NAMES:
            return {
                ...state,
                managersname: '',
                witnessesname: '',
            };
        default:
            return state;
    }
};
export default employeemanagers;
