import {
    SET_PAYMENT_ADDED,
    TOGGLE_VIEW_INSTALLMENT_MENU,
    TOGGLE_VIEW_MENU,
    SET_PAYMENT_DELETED,
} from '@components/ShoppingCart/Redux/types';

const initialState = {
    viewingMenu: false,
    viewingInstallmentMenu: false,
    isPaymentAdded: false,
    isDeleted: false,
};

const renewalReducer = (state = initialState, action: {type: string; payload: boolean}) => {
    switch (action.type) {
        case TOGGLE_VIEW_MENU:
            return {
                ...state,
                viewingMenu: action.payload,
            };
        case TOGGLE_VIEW_INSTALLMENT_MENU:
            return {
                ...state,
                viewingInstallmentMenu: action.payload,
            };
        case SET_PAYMENT_ADDED:
            return {
                ...state,
                isPaymentAdded: action.payload,
            };
        case SET_PAYMENT_DELETED:
            return {
                ...state,
                isDeleted: action.payload,
            };
        default:
            return state;
    }
};

export default renewalReducer;
