import axios from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {FieldValues} from 'react-hook-form';

import {ARTICLE, ARTICLE_SEARCH, FIELD_DINAMICS} from '@/components/Articles/Redux/Types';

export const DeleteRouteItems = (value: number) => {
    return {
        type: ARTICLE.DELETE_LEVEL_ROUTE,
        position: value,
    };
};
export const GetLevelId = (value: number) => {
    return {
        type: ARTICLE.GET_LEVEL,
        payload: value,
    };
};
export const AddRouteItems = (value: FieldValues) => {
    return {
        type: ARTICLE.ADD_ROUTE_ITEMS,
        payload: value,
    };
};

export const LevelDinamics = (level: number) => {
    return {
        type: ARTICLE_SEARCH.ADD_LEVEL_DINAMICS,
        payload: level,
    };
};
export const ErrorSearch = (value: unknown) => {
    return {
        type: ARTICLE_SEARCH.ERROR,
        payload: value,
    };
};
export const SearchArticle = (value: FieldValues) => {
    return {
        type: ARTICLE_SEARCH.SEARCH_ARTICLE,
        payload: value,
    };
};
export const RestartSearchArticle = () => {
    return {
        type: ARTICLE_SEARCH.RESTART_SEARCH_ARTICLE,
    };
};
export const LevelSelected = (level: string) => {
    return {
        type: ARTICLE_SEARCH.LEVEL_SELECTED,
        payload: level,
    };
};
export const RestartRoute = () => {
    return {
        type: ARTICLE_SEARCH.RESTART_ROUTE,
    };
};
export const RouteItems = (value: FieldValues) => {
    return {
        type: ARTICLE_SEARCH.ROUTE_ITEMS,
        payload: value,
    };
};

export const SearchArticleFetch = (token: string, Search: string, BranchId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SearchLevel.replace(
                    '{Search}',
                    Search
                ).replace('{BranchId}', BranchId)}`,
                {headers}
            );

            dispatch(RestartSearchArticle());
            dispatch(SearchArticle(response.data));
        } catch (error) {
            dispatch(ErrorSearch(error));
        }
    };
};

export const IdDinamics = (id: number) => {
    return {
        type: FIELD_DINAMICS.ADD_DINAMIC,
        payload: id,
    };
};
export const RestartDinamics = () => {
    return {
        type: FIELD_DINAMICS.RESTART_DINAMICS,
    };
};
