import {FETCH_CART_BY_ID} from '@components/ShoppingCart/Redux/types';
import {
    IShoppingCartData,
    IGetCartByCartIdRedux,
    ICartData,
} from '@components/ShoppingCart/Redux/interfaces';
import {addDays, format} from 'date-fns';

const initialState: IShoppingCartData = {
    loading: false,
    error: false,
    cartData: null,
};

const getDefaultCartData = (cartData: ICartData): ICartData => ({
    carShoppingId: cartData?.carShoppingId || 0,
    pawns: cartData?.pawns || [],
    subTotal: cartData?.subTotal || 0,
    totalExtension: cartData?.totalExtension || 0,
    totalPass: cartData?.totalPass || 0,
    iva: cartData?.iva || 0,
    total: cartData?.total || 0,
    endorsements: cartData?.endorsements || [],
});

const getCartByCartId = (
    state = initialState,
    action: IGetCartByCartIdRedux
): IShoppingCartData => {
    switch (action.type) {
        case FETCH_CART_BY_ID.FETCH_CART_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CART_BY_ID.FETCH_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                cartData: action.payload,
            };
        case FETCH_CART_BY_ID.FETCH_CART_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case FETCH_CART_BY_ID.UPDATE_EXTENSION: {
            const {contractId, extension} = action;

            const endorsements = state.cartData?.endorsements || [];

            const updatedEndorsements = endorsements.map((endorsement) => {
                if (endorsement.contractId === contractId) {
                    const currentNextPaymentDate = new Date(
                        endorsement.nextPaymentDate + 'T00:00:00'
                    );

                    const newNextPaymentDate = addDays(currentNextPaymentDate, extension.days);

                    const formattedNextPaymentDate = format(newNextPaymentDate, 'yyyy-MM-dd');

                    return {
                        ...endorsement,
                        extension: {
                            numberDays: extension.days,
                            nextPaymentDate: formattedNextPaymentDate,
                            subExtension: extension.amount,
                            ivaExtension: extension.vat,
                            totalExtension: extension.amount + extension.vat,
                        },
                    };
                }
                return endorsement;
            });

            return {
                ...state,
                cartData: {
                    ...getDefaultCartData(state.cartData as ICartData),
                    endorsements: updatedEndorsements,
                },
            };
        }
        case FETCH_CART_BY_ID.REMOVE_EXTENSION: {
            const {contractId} = action;

            const updatedEndorsements = state.cartData?.endorsements.map((endorsement) => {
                if (endorsement.contractId === contractId) {
                    return {
                        ...endorsement,
                        extension: null,
                    };
                }
                return endorsement;
            });

            return {
                ...state,
                cartData: {
                    ...getDefaultCartData(state.cartData as ICartData),
                    endorsements: updatedEndorsements || [],
                },
            };
        }
        default:
            return state;
    }
};
export default getCartByCartId;
