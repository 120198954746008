import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_ALL_CAT_BANK_ERROR,
    GET_ALL_CAT_BANK_START,
    GET_ALL_CAT_BANK_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IAllCatBankReducer} from '@components/PersonalLoans/interfaces';

const initialState: IAllCatBankReducer = {
    data: null,
    loading: false,
    error: false,
};

const getAllCatBank = (
    state: IAllCatBankReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IAllCatBankReducer => {
    switch (action.type) {
        case GET_ALL_CAT_BANK_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ALL_CAT_BANK_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ALL_CAT_BANK_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getAllCatBank;
