import {ADMIN} from '@LoginClient/Redux/Types/Types';
import {IScreenStates, IScreens} from '@/components/LoginClient/Redux/interfaces';

const initialState: IScreens = {
    stateLogMail: false,
    logScreens: 0,
    stateLogPass: [],
    stateScreen: [],
    stateVerify: [],
    stateError: false,
    userName: '',
    orgPass: '',
    passNew: 0,
};

export const getScreenUserStates = (state = initialState, action: IScreenStates): IScreens => {
    switch (action.type) {
        case ADMIN.RESETLOGINSTATE:
            return initialState;
        case ADMIN.LOGINS_SCREEN:
            return {
                ...state,
                logScreens: action.logScreens,
            };
        case ADMIN.ORGANIZATION_NEW:
            return {
                ...state,
                orgPass: action.orgPass,
            };
        case ADMIN.STATE_ERROR:
            return {
                ...state,
                stateError: action.stateError,
            };
        case ADMIN.PASS_NEW:
            return {
                ...state,
                passNew: action.passNew,
            };
        case ADMIN.STATE_LOGIN_MAIL:
            return {
                ...state,
                stateLogMail: action.stateLogMail,
            };
        case ADMIN.STATE_LOGIN_PASS:
            return {
                ...state,
                stateLogPass: action.stateLogPass,
            };
        case ADMIN.STATE_VERIFY_MAIL:
            return {
                ...state,
                stateVerify: action.stateVerify,
            };
        case ADMIN.USERNAME_SELECT:
            return {
                ...state,
                userName: action.userName,
            };
        default:
            return state;
    }
};
