import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_DATA_SELECT_REASON_START,
    GET_DATA_SELECT_REASON_SUCCESS,
    GET_DATA_SELECT_REASON_ERROR,
    DELETE_REASON_START,
    DELETE_REASON_SUCCESS,
    DELETE_REASON_ERROR,
} from '@MyCompany/Redux/Types/types';
import {DataMotiveDelete, DeleteDataDeleteMotive} from '@MyCompany/Interface';
import {AppDispatch} from '@/config/store';

export const fetchGetMotiveDeleteStart = () => {
    return {
        type: GET_DATA_SELECT_REASON_START,
    };
};

export const fetchGetMotiveDeleteSuccess = (result: {data: DataMotiveDelete}) => {
    return {
        type: GET_DATA_SELECT_REASON_SUCCESS,
        data: result.data,
    };
};

export const fetchGetMotiveDeleteError = () => {
    return {
        type: GET_DATA_SELECT_REASON_ERROR,
    };
};

export function GetMotiveDelete(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetMotiveDeleteStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetMotiveDelete}`,
                {headers}
            );
            dispatch(fetchGetMotiveDeleteSuccess(response));
        } catch (error) {
            dispatch(fetchGetMotiveDeleteError());
        }
    };
}

export const deleteDataReasonStart = () => {
    return {
        type: DELETE_REASON_START,
    };
};
export const deleteDataReasonSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_REASON_SUCCESS,
        payload: result,
    };
};

export const deleteDataReasonError = () => {
    return {
        type: DELETE_REASON_ERROR,
    };
};

export const deleteReason = (id: DeleteDataDeleteMotive, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(deleteDataReasonStart());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteReasonCompany}`,
                {data: id, headers}
            );
            dispatch(deleteDataReasonSuccess(response.data));
        } catch (error) {
            dispatch(deleteDataReasonError());
        }
    };
};
