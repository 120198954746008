import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {
    IClientProofStep,
    CivilStatus,
    EconomicActivity,
    Genre,
    SelectOption,
} from '@components/Shopify/ListClient/interfaces';
import {
    getCivilStatusList,
    getGenreList,
    getEconomicActivityList,
} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const PersonalDataCivilStatusGenreInfo = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const {token, errors, control, setValue} = props;

    const civilStatus: CivilStatus[] | null = useSelector(
        (state: RootState) => state.newClientProcess.civilStatus
    );
    let formattedCivilStatus: SelectOption[] = [];
    if (civilStatus) {
        formattedCivilStatus = civilStatus.map((civilStatus: CivilStatus) => ({
            value: civilStatus.id,
            label: civilStatus.nameStatus,
        }));
    }

    const economicActivity: EconomicActivity[] | null = useSelector(
        (state: RootState) => state.newClientProcess.economicActivity
    );
    let formattedEconomicActivity: SelectOption[] = [];
    if (economicActivity) {
        formattedEconomicActivity = economicActivity.map((economicActivity: EconomicActivity) => ({
            value: economicActivity.id,
            label: economicActivity.activityName,
        }));
    }

    const genre: Genre[] | null = useSelector((state: RootState) => state.newClientProcess.genres);
    let formattedGenre: SelectOption[] = [];
    if (genre) {
        formattedGenre = genre.map((genre: Genre) => ({
            value: genre.id,
            label: genre.genderName,
        }));
    }

    useEffect(() => {
        if (token) {
            dispatch(getCivilStatusList(token));
            dispatch(getEconomicActivityList(token));
            dispatch(getGenreList(token));
        }
    }, [dispatch, token]);

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.ESTADO_CIVIL}</Text>
                <Controller
                    name="civilStatusId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.civilStatusId?.message}
                            options={formattedCivilStatus}
                            onChange={(selectedOption) => {
                                setValue('civilStatusId', selectedOption);
                                if (onChange) onChange(selectedOption);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.civilStatusId?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.ACTIVIDAD_ECONOMICA}</Text>
                <Controller
                    name="economicActivityId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.economicActivityId?.message}
                            options={formattedEconomicActivity}
                            onChange={(selectedOption) => {
                                setValue('economicActivityId', selectedOption);
                                if (onChange) onChange(selectedOption);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.economicActivityId?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.GENERO}</Text>
                <Controller
                    name="genderId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.genderId?.message}
                            options={formattedGenre}
                            onChange={(selectedOption) => {
                                setValue('genderId', selectedOption);
                                if (onChange) onChange(selectedOption);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.genderId?.message as string}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataCivilStatusGenreInfo;
