import {AppDispatch} from '@/config/store';
import {
    GET_ALL_CAT_FREQUENCY_START,
    GET_ALL_CAT_FREQUENCY_ERROR,
    GET_ALL_CAT_FREQUENCY_SUCCESS,
    GET_AMOUNT_TERM_ID_START,
    GET_AMOUNT_TERM_ID_SUCCESS,
    GET_AMOUNT_TERM_ID_ERROR,
    SET_FREQUENCY_TERM_CREDIT_ID,
} from '@components/CreditQuoter/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants';

export const getAllCatFrequencyStart = () => {
    return {
        type: GET_ALL_CAT_FREQUENCY_START,
    };
};

export const getAllCatFrequencySuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_CAT_FREQUENCY_SUCCESS,
        payload: result.data,
    };
};

export const getAllCatFrequencyError = (error: AxiosError) => {
    return {
        type: GET_ALL_CAT_FREQUENCY_ERROR,
        error: error,
    };
};

export const getAmountTermIdStart = () => {
    return {
        type: GET_AMOUNT_TERM_ID_START,
    };
};

export const getAmountTermIdSuccess = (result: AxiosResponse) => {
    return {
        type: GET_AMOUNT_TERM_ID_SUCCESS,
        payload: result.data,
    };
};

export const getAmountTermIdError = (error: AxiosError) => {
    return {
        type: GET_AMOUNT_TERM_ID_ERROR,
        error: error,
    };
};

export const setFrequencyTermCreditId = (frequencyTermCreditId: number) => {
    return {
        type: SET_FREQUENCY_TERM_CREDIT_ID,
        frequencyTermCreditId,
    };
};

export const getAmountTermId = (token: string, amount: number, companyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getAmountTermIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRequestedAmountRange}`,
                {headers, params: {amount, companyId}}
            );
            dispatch(getAmountTermIdSuccess(response));
        } catch (error) {
            dispatch(getAmountTermIdError(error as AxiosError));
        }
    };
};

export const getAllCatFrequency = (token: string, termId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getAllCatFrequencyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAmountFrequency}`,
                {headers, params: {termId}}
            );
            dispatch(getAllCatFrequencySuccess(response));
        } catch (error) {
            dispatch(getAllCatFrequencyError(error as AxiosError));
        }
    };
};
