import React from 'react';

import * as style from '@/components/Quoter/stylesConstants';
import Warning from '@images/warning-red.svg';
import {MESSAGESEARCH} from '@Quoter/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

export const MessageInactiveArticle = () => {
    return (
        <>
            <ContainerFlex {...style.iconWarning}>
                <Image src={Warning} {...style.iconContent} />
                <Text Color="#414042" wSpace="normal" FontWeight="500">
                    {MESSAGESEARCH.INACTIVE_ARTICLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.message}>
                <Text Color="#54575C" FontWeight="500" FontSize="1.25rem">
                    {MESSAGESEARCH.CONTACT}
                </Text>
                <Text Color="#71767D" wSpace="normal">
                    {MESSAGESEARCH.UPARTICLE}
                </Text>
            </ContainerFlex>
        </>
    );
};
