import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DISBURSEMENT} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {INewProductDisbursementOptions} from '@components/PersonalLoans/interfaces';

export const Disbursement = () => {
    const {disbursementOptions} = useSelector((state: RootState) => {
        return state.DocumentDisbursementReducer;
    });
    const [disbursementItems, setDisbursementItems] = useState<INewProductDisbursementOptions[]>(
        []
    );

    const handleCheckboxChange = (disbursementItem: INewProductDisbursementOptions) => {
        if (disbursementItems.some((i) => i.disbursementId === disbursementItem.disbursementId)) {
            setDisbursementItems(
                disbursementItems.filter(
                    (i) => i.disbursementId !== disbursementItem.disbursementId
                )
            );
        } else {
            setDisbursementItems([...disbursementItems, disbursementItem]);
        }
    };

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1.5rem"
            Radius="1.5rem"
            Padding="1.25rem"
            Border="1px solid #E8E9EA"
        >
            <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                {DISBURSEMENT.TITLE}
            </Text>
            <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                {DISBURSEMENT.SUB}
            </Text>
            {disbursementOptions.map((item) => (
                <ContainerFlex key={item.disbursementId} Justify="start" Gap="8px" Height="45px">
                    <Checkbox
                        checked={disbursementItems.some(
                            (i) => i.disbursementId === item.disbursementId
                        )}
                        onChange={() => handleCheckboxChange(item)}
                    />
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text Color="#2A2C2F">{item.disbursementName}</Text>
                        <Text Color="#54575C">{item.disbursementDetails}</Text>
                    </ContainerFlex>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};
