import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FieldValues, useForm} from 'react-hook-form';
import {RootState, AppDispatch} from '@config/store';
import {DateTime} from 'luxon';
import {
    getSecurityBagWeight,
    getIdentifyFoundry,
} from '@ActionsSecurityBagWeight/SecurityBagWeightActions';
import {
    getSecurityBagWeightEscoria,
    postSecurityBagWeightEscoria,
} from '@ActionsSecurityBagWeight/SecurityBagWeightJiracasActions';
import {Table} from '@DeadLines/styles';

export const SecurityBagWeightEscoria = (): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const {handleSubmit, register, watch} = useForm({
        defaultValues: {
            kilatajes: [
                {
                    pesoFundidora: 0,
                    escoria: 0,
                    pesoPrestalana: 0,
                    pescoria: 0,
                    muestraFundidora: 0,
                    muestraPrestalana: 0,
                    muestraTerceria: 0,
                    pesoLingote: 0,
                    pesoTestigo: 0,
                },
            ],
            totales: 0,
            pesoEvaporado: 0,
            porcentajeEvaporado: '',
            porcentajeEscoria: '',
        },
    });
    const identify = useSelector((state: RootState) => {
        return state.securitybagweight.identifyfoundry;
    });
    const watchAllFields = watch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        if (token) {
            const getSecurityBagTransit = () => dispatch(getSecurityBagWeight('', 10, 0, token));
            getSecurityBagTransit();
            const getIdentify = () =>
                dispatch(
                    getIdentifyFoundry(
                        DateTime.local().minus({hours: 6}).toFormat('dd-MM-yyyy'),
                        token
                    )
                );
            getIdentify();
        }
    }, [dispatch, token]);
    useEffect(() => {
        if (identify) {
            const getSecurityBagWeightJiracasTable = () =>
                dispatch(getSecurityBagWeightEscoria(String(identify), token));
            getSecurityBagWeightJiracasTable();
        }
    }, [dispatch, identify, token]);

    const handleUserKeyPress = (name: number) => {
        if (watchAllFields?.kilatajes) {
            let namecount = 0;
            let currentNumber = 0;
            for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                switch (name) {
                    case 1:
                        currentNumber = Number(watchAllFields.kilatajes[i]['pesoPrestalana']);
                        break;
                    case 2:
                        currentNumber = Number(watchAllFields.kilatajes[i]['pesoFundidora']);
                        break;
                    case 3:
                        currentNumber = Number(watchAllFields.kilatajes[i]['escoria']);
                        break;
                    case 4:
                        currentNumber = Number(watchAllFields.kilatajes[i]['pescoria']);
                        break;
                    case 5:
                        currentNumber = Number(watchAllFields.kilatajes[i]['muestraFundidora']);
                        break;
                    case 6:
                        currentNumber = Number(watchAllFields.kilatajes[i]['muestraPrestalana']);
                        break;
                    case 7:
                        currentNumber = Number(watchAllFields.kilatajes[i]['muestraTerceria']);
                        break;
                    case 8:
                        currentNumber = Number(watchAllFields.kilatajes[i]['pesoLingote']);
                        break;
                    case 9:
                        currentNumber = Number(watchAllFields.kilatajes[i]['escoria']);
                        break;
                }

                namecount += currentNumber;
            }
            return namecount;
        }
    };
    const handleUserKeyPressEscoria = (id: number, idcase: number) => {
        if (watchAllFields?.kilatajes) {
            let namecount = 0;
            let namecounts = 0;
            let currentNumbers = 0;
            let currentNumber = 0;
            let currentNumbert = 0;
            let pesoFundidoraTotal = 0;
            let muestraTerceriaTotal = 0;
            let muestraFundidoraTotal = 0;
            let muestraPrestalanaTotal = 0;
            let pesoFundidoracount = 0;
            let muestraTerceriacount = 0;
            let muestraFundidoracount = 0;
            let muestraPrestalanacount = 0;
            let pesoLingotecount = 0;
            let pesoLingoteTotal = 0;
            if (idcase === 1) {
                for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                    currentNumbers = Number(watchAllFields.kilatajes[i]['pesoFundidora']);
                    namecount += currentNumbers;
                }
                currentNumber =
                    (Number(watchAllFields.kilatajes[id].pesoFundidora) / namecount) * 100;
                return currentNumber.toFixed(2);
            } else if (idcase === 2) {
                currentNumbert =
                    Number(watchAllFields.kilatajes[id].pescoria) * Number(watchAllFields.totales);
                return currentNumbert.toFixed(2);
            } else if (idcase === 3) {
                for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                    currentNumbers =
                        Number(watchAllFields.kilatajes[i].pescoria) *
                        Number(watchAllFields.totales);
                    namecounts += currentNumbers;
                }
                currentNumber = namecounts;
                return currentNumber.toFixed(2);
            } else if (idcase === 4) {
                for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                    currentNumbers = Number(watchAllFields.kilatajes[i].pesoFundidora);
                    namecounts += currentNumbers;
                }
                currentNumber = (Number(watchAllFields.totales) / namecounts) * 100;
                return currentNumber.toFixed(2);
            } else if (idcase === 5) {
                for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                    pesoFundidoraTotal = Number(watchAllFields.kilatajes[i].pesoFundidora);
                    muestraFundidoraTotal = Number(watchAllFields.kilatajes[i].muestraFundidora);
                    muestraPrestalanaTotal = Number(watchAllFields.kilatajes[i].muestraPrestalana);
                    muestraTerceriaTotal = Number(watchAllFields.kilatajes[i].muestraTerceria);
                    pesoLingoteTotal = Number(watchAllFields.kilatajes[i].pesoLingote);
                    pesoLingotecount += pesoLingoteTotal;
                    pesoFundidoracount += pesoFundidoraTotal;
                    muestraFundidoracount += muestraFundidoraTotal;
                    muestraPrestalanacount += muestraPrestalanaTotal;
                    muestraTerceriacount += muestraTerceriaTotal;
                }
                currentNumber =
                    pesoFundidoracount -
                    (Number(watchAllFields.totales) +
                        pesoLingotecount +
                        muestraFundidoracount +
                        muestraPrestalanacount +
                        muestraTerceriacount);
                return currentNumber.toFixed(2);
            } else if (idcase === 6) {
                for (let i = 0; i < watchAllFields.kilatajes.length; i++) {
                    pesoFundidoraTotal = Number(watchAllFields.kilatajes[i].pesoFundidora);
                    muestraFundidoraTotal = Number(watchAllFields.kilatajes[i].muestraFundidora);
                    muestraPrestalanaTotal = Number(watchAllFields.kilatajes[i].muestraPrestalana);
                    muestraTerceriaTotal = Number(watchAllFields.kilatajes[i].muestraTerceria);
                    pesoLingoteTotal = Number(watchAllFields.kilatajes[i].pesoLingote);
                    pesoLingotecount += pesoLingoteTotal;
                    pesoFundidoracount += pesoFundidoraTotal;
                    muestraFundidoracount += muestraFundidoraTotal;
                    muestraPrestalanacount += muestraPrestalanaTotal;
                    muestraTerceriacount += muestraTerceriaTotal;
                }
                currentNumber =
                    pesoFundidoracount -
                    (Number(watchAllFields.totales) +
                        pesoLingotecount +
                        muestraFundidoracount +
                        muestraPrestalanacount +
                        muestraTerceriacount);
                const totalPorcentajeEvaporado = (currentNumber / pesoFundidoracount) * 100;
                return totalPorcentajeEvaporado.toFixed(2);
            }
        }
    };
    const onSubmitAudit = async (values: FieldValues) => {
        const objetos = values.kilatajes.map(
            (item: {
                kilatajes: number;
                pesoPrestalana: string;
                pesoFundidora: string;
                lingote: string;
                escoria: string;
                muestraFundidora: string;
                muestraPrestalana: string;
                muestraTerceria: string;
                pesoLingote: string;
            }) => {
                const json = {
                    lingote: Number(item.lingote),
                    kilatajes: item.kilatajes,
                    pesoPrestalana: Number(item.pesoPrestalana),
                    pesoFundidora: Number(item.pesoFundidora),
                    pesoEscoria: Number(item.escoria),
                    muestraFundidora: Number(item.muestraFundidora),
                    muestraPrestalana: Number(item.muestraPrestalana),
                    muestraTerceria: Number(item.muestraTerceria),
                    pesoLingote: Number(item.pesoLingote),
                };
                return json;
            }
        );

        const jason = {
            idFundicion: Number(identify),
            pesoEscoria: Number(values.totales),
            porcentajeEscoria: Number(values.porcentajeEscoria),
            pesoEvaporado: Number(values.pesoEvaporado),
            porcentajeEvaporado: Number(values.porcentajeEvaporado),
            lingotes: objetos,
        };
        await dispatch(postSecurityBagWeightEscoria(jason, token));
        window.location.href = `/Fundiciones/PesoEscoriaOro/${identify}`;
    };
    const securitybagweightescoria = useSelector((state: RootState) => {
        return state.securitybagweightjiracas.securitybagweightescoria;
    });

    return (
        <div className="w-90 mx-auto ">
            <div className="pt-2 pb-3 flex align-items-center w-100 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Fundición</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Peso de Seguribolsa</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Peso de Escoria</span>
            </div>
            <div className="flex justify-content-between">
                <span>Selecciona el kilataje que se mezclará en un solo lingote.</span>
            </div>
            <div className="flex mt-5">
                <div className="w-100 card pt-2 ml-3">
                    <form
                        className="flex container-form pl-4"
                        onSubmit={handleSubmit(onSubmitAudit)}
                    >
                        <div className="flex justify-content-end w-100 mt-5">
                            <button className="  mr-5 btn-add-incidents h-40">SIGUIENTE</button>
                        </div>
                        <Table className="w-92 mx-auto mt-4">
                            <thead className="title-head-foundry">
                                <tr>
                                    <th className="text-center">Lingote</th>
                                    <th className="text-center">Kilatajes</th>
                                    <th className="pl-1 text-center">Peso Prestalana</th>
                                    <th className="pl-1 text-center">Peso Fundidora</th>
                                    <th className="pl-1 text-center">Escoria</th>
                                    <th className="pl-1 text-center">% Escoria</th>
                                    <th className="text-center">Muestra Fundidora</th>
                                    <th className="text-center">Muestra Prestalana</th>
                                    <th className="text-center">Muestra Terceria</th>
                                    <th className="text-center">Peso Lingote</th>
                                </tr>
                            </thead>
                        </Table>
                        {securitybagweightescoria &&
                            securitybagweightescoria.listaLingotes.map(
                                (
                                    recolectionitems: JSX.IntrinsicAttributes & {
                                        pesoLingote: string;
                                        muestraTerceria: string;
                                        muestraPrestalana: string;
                                        muestraFundidora: string;
                                        escoria: string;
                                        pescoria: string;
                                        pesoFundidora: string;
                                        pesoPrestalana: string;
                                        lingote: string;
                                        kilatajes: string;
                                        pesoTestigo: string;
                                        pesoMetalTestigo: string;
                                        pesoAccesorios: string;
                                        pesoMetalFundidora: string;
                                        difPsPf: string;
                                        difPtPf: string;
                                    },
                                    index: number
                                ): JSX.Element => (
                                    <Table className="w-92 mx-auto mt-4" key={index}>
                                        <tr className="row-sucursal-tr">
                                            <td className="w-5">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(`kilatajes[${index}].lingote`, {})}
                                                    value={recolectionitems.lingote}
                                                />
                                            </td>
                                            <td className="w-10">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(`kilatajes[${index}].kilatajes`, {})}
                                                    value={recolectionitems.kilatajes}
                                                />
                                            </td>
                                            <td className="w-10">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(
                                                    //   `kilatajes[${index}].pesoPrestalana`,
                                                    //   {}
                                                    // )}
                                                    value={recolectionitems.pesoPrestalana}
                                                />
                                            </td>
                                            <td className="w-12">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(`kilatajes[${index}].pesoFundidora`, {})}
                                                    value={recolectionitems.pesoFundidora}
                                                />
                                            </td>

                                            <td className="w-5">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(`kilatajes[${index}].escoria`, {})}
                                                    value={handleUserKeyPressEscoria(index, 2)}
                                                />
                                            </td>
                                            <td className="  w-8">
                                                <input
                                                    className="input-search disabled-button"
                                                    // {...register(`kilatajes[${index}].pescoria`, {})}
                                                    value={handleUserKeyPressEscoria(index, 1)}
                                                />
                                            </td>

                                            <td className="w-15">
                                                <input
                                                    className="input-search"
                                                    // {...register(
                                                    //   `kilatajes[${index}].muestraFundidora`,
                                                    //   {}
                                                    // )}
                                                    defaultValue={recolectionitems.muestraFundidora}
                                                />
                                            </td>
                                            <td className="w-12">
                                                <input
                                                    className="input-search"
                                                    // {...register(
                                                    //   `kilatajes[${index}].muestraPrestalana`,
                                                    //   {}
                                                    // )}
                                                    defaultValue={
                                                        recolectionitems.muestraPrestalana
                                                    }
                                                />
                                            </td>
                                            <td className="w-15">
                                                <input
                                                    className="input-search"
                                                    // {...register(
                                                    //   `kilatajes[${index}].muestraTerceria`,
                                                    //   {}
                                                    // )}
                                                    defaultValue={recolectionitems.muestraTerceria}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className="input-search"
                                                    // {...register(`kilatajes[${index}].pesoLingote`, {})}
                                                    defaultValue={recolectionitems.pesoLingote}
                                                />
                                            </td>
                                        </tr>
                                    </Table>
                                )
                            )}
                        <Table className="w-92 mx-auto mt-4">
                            <tr className="row-sucursal-tr">
                                <td />
                                <td className="w-9 icon-color">
                                    <span>Totales</span>
                                </td>
                                <td className="w-10 icon-color">
                                    <span>{handleUserKeyPress(1)}</span>
                                </td>
                                <td className="w-12 icon-color">
                                    <span>{handleUserKeyPress(2)}</span>
                                </td>
                                <td className="w-5 icon-color">
                                    <span>{handleUserKeyPressEscoria(1, 3)}</span>
                                </td>
                                <td className="w-8 icon-color">
                                    <span>
                                        <span>{handleUserKeyPress(4)}</span>
                                    </span>
                                </td>
                                <td className="w-15 icon-color">
                                    <span>{handleUserKeyPress(5)}</span>
                                </td>
                                <td className="w-12 icon-color">
                                    <span>{handleUserKeyPress(6)}</span>
                                </td>
                                <td className="w-15 icon-color">
                                    <span>{handleUserKeyPress(7)}</span>
                                </td>
                                <td className="w-8 icon-color">
                                    <span>{handleUserKeyPress(8)}</span>
                                </td>
                            </tr>
                        </Table>

                        <Table className="w-92 ml-5 mt-4 w-50">
                            <thead className="title-head-foundry">
                                <tr>
                                    <th className="text-center">Gramos Escoria</th>
                                    <th className="pl-1">Porcentaje</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>
                                    <input
                                        className="input-search"
                                        {...register('totales', {})}
                                        defaultValue={0}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="input-search disabled-button"
                                        {...register('porcentajeEscoria', {})}
                                        value={handleUserKeyPressEscoria(1, 4)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th className="text-center">Gramos Evaporado</th>
                                <th className="pl-1">Porcentaje Evaporado</th>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        className="input-search disabled-button"
                                        {...register('pesoEvaporado', {})}
                                        value={handleUserKeyPressEscoria(1, 5)}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="input-search disabled-button"
                                        {...register('porcentajeEvaporado', {})}
                                        value={handleUserKeyPressEscoria(1, 6)}
                                    />
                                </td>
                            </tr>
                        </Table>
                    </form>
                </div>
            </div>
        </div>
    );
};
