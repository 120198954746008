import {IselectTable} from '@components/CashFlow/interfaces';
import {SELECT_TABLE} from '@components/CashFlow/Redux/Types';

const initialState: IselectTable = {
    tableNumber: 1,
};

export const selectTableReducer = (
    state = initialState,
    action: {
        type: string;
        payload: number;
    }
) => {
    switch (action.type) {
        case SELECT_TABLE:
            return {
                ...state,
                tableNumber: action.payload,
            };
        default:
            return state;
    }
};
