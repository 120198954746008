import {DateTime} from 'luxon';
import {Controller} from 'react-hook-form';
import React from 'react';
import {DatePickerCustom} from '@/components/CreateUsers/DatePiker/datePickerStyles';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {IDateDueEndInputProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const DateDueEndInput: React.FC<IDateDueEndInputProps> = ({control, handleManualInput}) => {
    return (
        <>
            <Controller
                name="dateDueEnd"
                control={control}
                render={({field: {value, onChange, ...field}}) => (
                    <DatePickerCustom
                        mask="__/__/____"
                        {...field}
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                            if (e === null) return;
                            handleManualInput(e as DateTime, false);
                        }}
                        minDate={
                            new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate() + 0
                            )
                        }
                        disableMaskedInput={false}
                        renderInput={({inputRef, inputProps, InputProps}) => (
                            <Input
                                ref={inputRef}
                                {...inputProps}
                                placeholder={'00/00/0000'}
                                Width="95px"
                            />
                        )}
                    />
                )}
            />
        </>
    );
};
