import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    addressAdd,
    adminRoles,
    credentials,
    personalData,
    posRoles,
    resetUsers,
} from '@components/CreateUsers/Redux/Actions/saveUser';
import RolesUser from '@components/CreateUsers/RolesUser';
import AdressUser from '@components/CreateUsers/AdressUser';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {LevelBranch} from '@components/CreateUsers/Interface';
import {PersonalData} from '@components/CreateUsers//PersonalData';
import LocationUser from '@components/CreateUsers/Location/Location';
import {CREATEUSER, USER_TYPE, formUsers} from '@components/CreateUsers/constants';
import {updateUser} from '@components/CreateUsers/Redux/Actions/editUser';
import {CredentialAccess} from '@components/CreateUsers/CredentialAccess';
import RecentActivityUser from '@components/CreateUsers/RecentActivityUser';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {
    saveTree,
    treeBranches,
    treeGroups,
} from '@components/CreateUsers/Redux/Actions/TreeGroupsAndBranches';
import {postDataUserForm} from '@components/CreateUsers/Redux/Actions/CreateDataUser';
import {GetOptionsNames} from '@components/CreateUsers/Redux/Actions/GetListUserNames';
import {GetPostalInfo} from '@components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
import {AditionalInformationEmployee, EmployeeData} from '@components/CreateUsers/interfaces';

export const CreateUser = () => {
    const {editUsers} = useSelector((state: RootState) => state.editUsers);
    const {showTreeBranches, treeGroup, treeBranch} = useSelector(
        (state: RootState) => state.TreeGroupBranches
    );
    const saveUser = useSelector((state: RootState) => state.saveUser);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const GroupStep = treeGroup.map((item) => item.id);
    const branchesStep = treeBranch
        .map((item) =>
            item.branches.map((element) => ({
                branchId: element.branchId,
                nameBranch: element.branchName,
            }))
        )
        .flat();

    const {
        handleSubmit,
        register,
        control,
        setValue,
        trigger,
        watch,
        reset,
        formState: {errors},
    } = useForm<EmployeeData>({defaultValues: formUsers});

    const dispatch = useDispatch();

    const resetNull = (typeResponse: string) => {
        let response = '';
        if (typeResponse) response = typeResponse;
        return response;
    };
    const onSubmit = (data: EmployeeData) => {
        const address: AditionalInformationEmployee[] = saveUser.aditionalInformationEmployee
            .map((item) => ({
                ...item,
                externalNumber: String(item.externalNumber),
                ineternalNumber: String(item.ineternalNumber),
                catColoniaId: item.catColoniaId.value,
            }))
            .slice(0, 1);
        const RolesAdmins =
            saveUser.credential.userType !== USER_TYPE.POS
                ? [data.roleAdmins].map((item: FieldValues) => ({
                      roleIdAdmin: item.value,
                      roleNameAdmin: item.label,
                  }))
                : [];
        const RolesEmployees =
            saveUser.credential.userType !== USER_TYPE.ADMIN
                ? [data.roleEmployees].map((item: FieldValues) => ({
                      roleIdEmployee: item.value,
                      roleNameEmployee: item.label,
                  }))
                : [];
        if (saveUser.typeAction === 'Create')
            dispatch(
                postDataUserForm(
                    {
                        ...data,
                        aditionalInformationEmployee: address,
                        levelBranch: showTreeBranches.branches,
                        roleAdmins: RolesAdmins,
                        roleEmployees: RolesEmployees,
                        companyId: companyId,
                    },
                    token
                )
            );
        if (saveUser.typeAction === 'Edit')
            dispatch(
                updateUser(
                    {
                        userId: editUsers.userId,
                        names: data.names,
                        firsLastNames: data.firsLastNames,
                        secondLastNames: data.secondLastNames,
                        birthDate: data.birthDate,
                        imageProfile: data.imageProfile,
                        userType: data.userType,
                        credentialsUser: {
                            ...data.accessCredentials,
                            pin: resetNull(data.pin),
                            userName: resetNull(data.accessCredentials.userName),
                        },
                        addres: address.map((item) => ({
                            externalNumber: String(item.externalNumber),
                            ineternalNumber: String(item.ineternalNumber),
                            catColoniaId: item.catColoniaId,
                            codigoPostal: item.codigoPostal,
                            address: item.address,
                        })),
                        levelsBranch: showTreeBranches.branches.map((item) => ({
                            branchId: item.branchId,
                        })),
                        rolsUserAdmins: RolesAdmins.map((item) => ({
                            roleIdAdmin: item.roleIdAdmin,
                        })),
                        rolsUserEmployees: RolesEmployees.map((item) => ({
                            roleIdEmployee: item.roleIdEmployee,
                        })),
                    },
                    token
                )
            );
    };

    const getUserOptions = () => {
        const inputName = watch('names');
        const inputLastName = watch('firsLastNames');
        if (inputName && inputLastName) dispatch(GetOptionsNames(token, inputName, inputLastName));
    };

    const rolId = () => {
        const credential = editUsers.accessCredentials;
        let rolId = 0;
        if (credential.imagePos) rolId = USER_TYPE.POS;
        if (credential.imageAdmin) rolId = USER_TYPE.ADMIN;
        if (credential.imageAdmin && credential.imagePos) rolId = USER_TYPE.BOTH;
        return rolId;
    };

    const resetForm = async () => {
        if (editUsers.addressUser.length > 0)
            dispatch(GetPostalInfo(editUsers.addressUser[0].postalCode, token));
        dispatch(
            personalData({
                names: editUsers.name,
                firsLastNames: editUsers.lastName,
                secondLastNames: editUsers.motherLastName,
                birthDate: editUsers.birthdate,
                imageProfile: editUsers.userImage,
                imageProfileBase64: editUsers.userImage
                    ? CREATEUSER.IMG + editUsers.userImage
                    : editUsers.userImage,
            })
        );
        dispatch(
            credentials({
                userType: rolId(),
                pin: editUsers.accessCredentials.pin,
                accessCredentials: {
                    emailAdmin: editUsers.accessCredentials.email,
                    userName: editUsers.accessCredentials.nameUser,
                    password: editUsers.accessCredentials.password,
                    repeatPassword: editUsers.accessCredentials.repeatPassword,
                },
            })
        );
        dispatch(adminRoles(editUsers.userRolAdmin));
        dispatch(posRoles(editUsers.userRolPos));
        dispatch(
            addressAdd(
                editUsers.addressUser.map((item: FieldValues) => ({
                    manageAditionalInformation: true,
                    codigoPostal: item.postalCode,
                    address: item.street,
                    externalNumber: String(item.outdoorNumber),
                    ineternalNumber: String(item.interiorNumber),
                    catColoniaId: {value: item.suburbId, label: item.suburbName},
                    minicipaly: {value: item.municipalityId, label: item.municipalityName},
                    state: {value: item.stateId, label: item.stateName},
                }))
            )
        );
        await dispatch(treeGroups(token, {CompanyId: companyId}));
    };

    useEffect(() => {
        if (!saveUser.personal.names) resetForm();
    }, []);

    useEffect(() => {
        const branches: LevelBranch[] = editUsers.userBranchs.map((item) => ({
            branchId: item.branchId,
            nameBranch: item.branchName,
        }));
        dispatch(
            saveTree({
                ...showTreeBranches,
                group: GroupStep,
                branches: branches,
                copyBranches: branchesStep,
                currentBranches: [],
                treeGroup: GroupStep,
            })
        );
    }, [branchesStep.length]);

    useEffect(() => {
        dispatch(treeBranches(token, {companyId: companyId, branch: '', groupsId: GroupStep}));
    }, [GroupStep.length]);

    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            BackG="none"
            Align="start"
            FlexDir="column"
            Padding="1rem"
            Gap="1rem"
            Justify="start"
            Height="auto"
        >
            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" Padding="0.75rem 0">
                {CREATEUSER.TITLE}
            </Text>
            <PersonalData
                register={register}
                setValue={setValue}
                control={control}
                errors={errors}
            />
            <CredentialAccess
                register={register}
                getUserOptions={getUserOptions}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
            />
            <RolesUser control={control} errors={errors} setValue={setValue} />
            <AdressUser
                register={register}
                control={control}
                trigger={trigger}
                setValue={setValue}
                watch={watch}
                reset={reset}
                errors={errors}
            />
            <LocationUser reset={reset} />
            <RecentActivityUser />
            <ContainerFlex Gap="1.5rem">
                <Text
                    onClick={async () => {
                        dispatch(resetUsers());
                        window.location.href = '#/Users';
                    }}
                    Color="#FF6357"
                    Margin="0.5rem 1rem"
                    MarginB="0.5rem"
                    Cursor="pointer"
                >
                    Descartar
                </Text>
                <ButtonGeneral
                    width="9.313rem"
                    text="Guardar"
                    type="submit"
                    height="40px"
                    cursor="pointer"
                    disabled={!saveUser.personal.imageProfile}
                />
            </ContainerFlex>
        </ContainerForm>
    );
};
