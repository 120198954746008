import {GET_LIST_LEVELS} from '@components/LocationsBranch/Redux/types';
import {
    GetListLevelsState,
    GetListLevelsAction,
} from '@components/LocationsBranch/Redux/interfaces';

const initialState: GetListLevelsState = {
    data: {
        companyId: 0,
        company: '',
        levels: [],
    },
    loading: false,
    error: null,
};

const getListLevels = (state = initialState, action: GetListLevelsAction): GetListLevelsState => {
    switch (action.type) {
        case GET_LIST_LEVELS.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_LIST_LEVELS.GET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default getListLevels;
