import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import Logo from '@images/Multiapoyo.svg';
import {ImageLogo} from '@LoginClient/styles';
import {SelectProblem} from '@components/LoginClient/SubContainers/AdminContact/SelectProblem';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {TextArea} from '@/components/CashFlow/FundingRequest/styles';
import {GetLoginProblems} from '@components/LoginClient/Redux/Actions/GetLoginProblems';
import {AppDispatch, RootState} from '@/config/store';
import {CreateProblems} from '@components/LoginClient/Redux/Actions/CreateProblems';
import {ContactSuccess} from '@components/LoginClient/SubContainers/AdminContact/ContactSuccess';
import {IContactForm} from '@components/LoginClient/Redux/interfaces';
import {schemaAdminContact} from '@components/LoginClient/SubContainers/AdminContact/yupSchema';
import {PrivacityContainer} from '@/components/LoginClient/SubContainers/PrivacityContainer';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const AdminContact = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const catProblems = useSelector((state: RootState) => {
        return state.GetLoginProblems?.catProblems;
    });
    const successProblem = useSelector((state: RootState) => {
        return state.CreateProblems?.createProblem;
    });
    const loadingProblem = useSelector((state: RootState) => {
        return state.CreateProblems?.loading;
    });
    const [showForm, setShowForm] = useState(false);
    const [userBlocked, setUserBlocked] = useState(false);
    const [idMessage, setIdMessage] = useState(0);
    const {
        handleSubmit,
        register,
        watch,
        trigger,
        formState: {isValid},
        setValue,
    } = useForm<IContactForm>({
        resolver: yupResolver(schemaAdminContact),
        defaultValues: {
            problemId: undefined,
            problem: '',
            fullNames: '',
            userName: '',
            description: '',
        },
    });
    const watchProblem = watch('problem');
    const onSubmit = (data: IContactForm) => {
        if (data.problemId === 1) {
            const sendData = {
                problemId: data.problemId,
                fullNames: data.fullNames,
                description: data.description,
            };
            dispatch(CreateProblems(sendData));
            setIdMessage(data.problemId);
        }
        if (data.problemId === 2 || data.problemId === 3) {
            const sendData = {
                problemId: data.problemId,
                fullNames: data.fullNames,
                userName: data.userName,
                description: data.description,
            };
            dispatch(CreateProblems(sendData));
            setIdMessage(data.problemId);
        }
    };
    useEffect(() => {
        dispatch(GetLoginProblems());
    }, []);
    return (
        <>
            {!successProblem ? (
                <ContainerFlex
                    FlexDir="column"
                    Justify="space-between"
                    Height={showForm ? '100%' : '100vh'}
                    Padding="10vh 0 0 0"
                    RowGap={showForm ? '32px' : '0px'}
                >
                    <ContainerFlex FlexDir="column" Width="500px" Justify="flex-start">
                        <ImageLogo src={Logo} />
                        <Text FontSize="20px" Color="#414042" MarginTop="48px">
                            Contacta a tu administrador
                        </Text>
                        <ContainerFlex
                            Padding="24px"
                            Height="auto"
                            Width="348px"
                            Justify="space-between"
                            Align="flex-start"
                            FlexDir="column"
                            backG="rgba(243, 243, 243, 0.4)"
                            Radius="4px"
                            Border="1px solid #e5e7e9"
                            MarginTop="24px"
                        >
                            <Text FontSize="1rem" TextAlign="center" Color="#414042">
                                Proporciona la siguiente información
                            </Text>
                            <Text FontSize="0.75rem" required Color="#6d6e71" Margin=" 24px 0 0 0">
                                ¿Qué problema tienes?
                            </Text>
                            <SelectProblem
                                data={catProblems}
                                optionSelect={watchProblem}
                                setValue={setValue}
                                trigger={trigger}
                                setShowForm={setShowForm}
                                setUserBlocked={setUserBlocked}
                            />
                            {showForm && (
                                <>
                                    <Text FontSize="0.75rem" required Color="#6d6e71">
                                        ¿Cuáles son tus nombres y apellidos?
                                    </Text>
                                    <Input
                                        Cursor="text"
                                        Height="2.5rem"
                                        Margin="0 0 24px 0"
                                        FontSize="0.875rem"
                                        HolderFontSize="0.875rem"
                                        Padding="13px 13px 11px 13px"
                                        Radius="4px"
                                        placeholder="Nombres y apellidos"
                                        Width="100%"
                                        {...register('fullNames')}
                                        type="text"
                                        maxLength={160}
                                    />
                                    {!userBlocked && (
                                        <>
                                            <Text FontSize="0.75rem" required Color="#6d6e71">
                                                ¿Cuál es tu usuario?
                                            </Text>
                                            <Input
                                                Cursor="text"
                                                Height="2.5rem"
                                                FontSize="0.875rem"
                                                HolderFontSize="0.875rem"
                                                Margin="0 0 24px 0"
                                                Padding="13px 13px 11px 13px"
                                                Radius="4px"
                                                placeholder="Usuario"
                                                Width="100%"
                                                {...register('userName')}
                                                type="text"
                                                maxLength={160}
                                            />
                                        </>
                                    )}

                                    <Text FontSize="0.75rem" Color="#6d6e71">
                                        Descripción del problema
                                    </Text>
                                    <TextArea
                                        FocusOutline="2px solid #35cf44"
                                        placeholder="Descripcion"
                                        FontSize="0.874rem"
                                        Height="92px"
                                        Width="274px"
                                        maxLength={160}
                                        {...register('description')}
                                    />
                                </>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Height="auto" FlexDir="column" Margin="24px 0">
                            <ButtonGeneral
                                margin="24px 0 0 0"
                                width="261px"
                                text={
                                    loadingProblem ? (
                                        <LoadingGeneralButtons />
                                    ) : (
                                        'Enviar a administrador'
                                    )
                                }
                                type="submit"
                                hColor="#35cf44"
                                bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                                transform=""
                                hText="#35cf44"
                                hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                                disabled={!isValid || loadingProblem}
                                cursor={!isValid || loadingProblem ? '' : 'pointer'}
                                clic={handleSubmit(onSubmit)}
                            />
                        </ContainerFlex>
                        <ContainerFlex
                            Height="32px"
                            Width="auto"
                            Margin="24px 0 0 0"
                            onClick={() => {
                                navigate('/login/Employee/');
                            }}
                        >
                            <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                                Cancelar
                            </Text>
                            <ImgLocation
                                src={nextArrow}
                                Width="0.875rem"
                                Height="0.875rem"
                                Margin="0 0 0 4px"
                                Cursor="pointer"
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                    <PrivacityContainer />
                </ContainerFlex>
            ) : (
                <ContactSuccess idMessage={idMessage} />
            )}
        </>
    );
};
