import React from 'react';
import {CreditButtonProps} from '@/components/MyAccount/interfaces';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {CREDIT_TYPES} from '@/components/MyAccount/constants';

const CreditButton = ({type, isActive, onClick, radius}: CreditButtonProps) => {
    const buttonStyles = {
        padding: '12px 16px',
        borderRadius: radius,
        backgroundColor: isActive ? '#F0F0FF' : '#FFFFFF',
        borderColor: isActive ? '#5A5AFF' : '#E8E9EA',
        borderLeft: isActive
            ? '1px solid #5A5AFF'
            : type !== CREDIT_TYPES.ALL
            ? 'none'
            : '1px solid #E8E9EA',
        color: isActive ? '#5A5AFF' : '#1D1E20',
        fontWeight: isActive ? '700' : '400',
        fontFamily: 'Nunito',
        width: '182px',
        hoverBackgroundColor: isActive ? '#F0F0FF' : '#FFFFFF',
        height: '100%',
    };
    return (
        <ButtonGenerals
            Padding={buttonStyles.padding}
            Radius={buttonStyles.borderRadius}
            BackGC={buttonStyles.backgroundColor}
            Border={`1px solid ${buttonStyles.borderColor}`}
            BorderL={buttonStyles.borderLeft}
            Color={buttonStyles.color}
            FontWeight={buttonStyles.fontWeight}
            FontFamily={buttonStyles.fontFamily}
            Width={buttonStyles.width}
            onClick={onClick}
            HBackG={buttonStyles.hoverBackgroundColor}
            Height={buttonStyles.height}
        >
            {type}
        </ButtonGenerals>
    );
};

export default CreditButton;
