import {AppDispatch} from '@/config/store';
import {
    EMPLOYEE_USER_SEARCH_ERROR,
    EMPLOYEE_USER_SEARCH_START,
    EMPLOYEE_USER_SEARCH_SUCCESS,
    USER_ASSIGNMENT_ROLES_ERROR,
    USER_ASSIGNMENT_ROLES_START,
    USER_ASSIGNMENT_ROLES_SUCCESS,
} from '@Roles/Redux/Types/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {UserRolesData} from '@Roles/interface';

const fetchRolesUserSearch = () => {
    return {type: EMPLOYEE_USER_SEARCH_START};
};
const fetchRolesUserSearchSuccess = (result: AxiosResponse) => {
    return {type: EMPLOYEE_USER_SEARCH_SUCCESS, payload: result};
};
const fetchRolesUserSearchError = (error: AxiosError) => {
    return {type: EMPLOYEE_USER_SEARCH_ERROR, error: error};
};

export function GetRolesUserSearch(CompanyId: number, Search: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchRolesUserSearch());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetEmployeeUserSearch.replace(
                    '{CompanyId}',
                    String(CompanyId)
                ).replace('{Search}', String(Search))}`,
                {headers}
            );
            dispatch(fetchRolesUserSearchSuccess(response));
        } catch (error) {
            dispatch(fetchRolesUserSearchError(error as AxiosError));
        }
    };
}

const fetchRolesUserAssignment = () => {
    return {type: USER_ASSIGNMENT_ROLES_START};
};
const fetchRolesUserAssignmentSuccess = (result: AxiosResponse) => {
    return {type: USER_ASSIGNMENT_ROLES_SUCCESS, payload: result};
};
const fetchRolesUserAssignmentError = (error: AxiosError) => {
    return {type: USER_ASSIGNMENT_ROLES_ERROR, error: error};
};

export function postRolesUserAssignment(
    data: UserRolesData,
    token: string,
    setShowModal: (e: boolean) => void,
    setCheckedItems: (e: number[]) => void,
    searchRoles: () => void
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchRolesUserAssignment());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UserAssignmentRole}
            `,
                data,
                {headers}
            );
            dispatch(fetchRolesUserAssignmentSuccess(response));
            setShowModal(false);
            setCheckedItems([]);
            searchRoles();
        } catch (error) {
            dispatch(fetchRolesUserAssignmentError(error as AxiosError));
        }
    };
}
