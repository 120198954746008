import {ADDLEVEL} from '@/components/Articles/Redux/ManageTypes';
import {IAddLevel} from '@Articles/interface';
import {AxiosError, AxiosResponse} from 'axios';

const INITIAL_STATE: IAddLevel = {
    addLevel: null,
    errorData: null,
    error: false,
    loading: false,
};

const GetAddLevel = (
    state: IAddLevel = INITIAL_STATE,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IAddLevel => {
    switch (action.type) {
        case ADDLEVEL.LEVEL:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADDLEVEL.LEVEL_SUCCESS:
            return {
                ...state,
                addLevel: action.payload.data,
                loading: false,
                error: false,
            };
        case ADDLEVEL.LEVEL_ERROR:
            return {
                ...state,
                errorData: action.error,
                loading: false,
                error: true,
            };

        default:
            return state;
    }
};
export default GetAddLevel;
