import React from 'react';
import {
    IArticle,
    IBrand,
    IFamily,
    IGroup,
    ISearchAllCatalog,
    ISubFamily,
    IUse,
} from '@/components/Articles/Redux/interfaces';
export const LevelsSelector = (
    Levels: ISearchAllCatalog,
    token: string,
    setGroup: React.Dispatch<React.SetStateAction<IUse[]>>,
    setFamily: React.Dispatch<React.SetStateAction<IUse[]>>,
    setSubFamily: React.Dispatch<React.SetStateAction<IUse[]>>,
    setBrand: React.Dispatch<React.SetStateAction<IUse[]>>,
    setArticle: React.Dispatch<React.SetStateAction<IUse[]>>
) => {
    if (token && Levels.GroupSelect) {
        const Group = Levels.GroupSelect.map((data: IGroup) => {
            const newGroup = {
                id: data.id,
                name: data.nameGroup,
            };
            return newGroup;
        });
        setGroup(Group);
    }
    if (token && Levels.Family) {
        const Family = Levels.Family.map((data: IFamily) => {
            const newFamily = {
                id: data.id,
                name: data.nameFamily,
                catLevel: data.idGroup,
            };
            return newFamily;
        });
        setFamily(Family);
    }
    if (token && Levels.Subfamily) {
        const SubFamily = Levels.Subfamily.map((data: ISubFamily) => {
            const newSubFamily = {
                id: data.id,
                name: data.nameSubFamily,
                catLevel: data.familyId,
            };
            return newSubFamily;
        });
        setSubFamily(SubFamily);
    }
    if (token && Levels.Brand) {
        const Brand = Levels.Brand.map((data: IBrand) => {
            const newBrand = {
                id: data.id,
                name: data.nameBrand,
                catLevel: data.idSubFamily,
            };
            return newBrand;
        });
        setBrand(Brand);
    }
    if (token && Levels.Article) {
        const Article = Levels.Article.map((data: IArticle) => {
            const newArticle = {
                id: data.id,
                name: data.nameItem,
                catLevel: data.idBrand,
            };
            return newArticle;
        });
        setArticle(Article);
    }
    return null;
};
