import {IGetTransactionsParams} from '@components/CashFlow/interfaces';
import {FILTERS_PERSISTANCE, FILTERS_PERSISTANCE_RESET} from '@components/CashFlow/Redux/Types';

const initialState: IGetTransactionsParams = {
    filterTransactionType: 0,
    filterMotionType: 0,
    date: '',
    startTime: '',
    endTime: '',
};

export const filtersPersistance = (
    state = initialState,
    action: {
        type: string;
        data: IGetTransactionsParams;
    }
) => {
    switch (action.type) {
        case FILTERS_PERSISTANCE:
            return {
                ...state,
                filters: action.data,
            };
        case FILTERS_PERSISTANCE_RESET:
            return {
                filters: initialState,
            };
        default:
            return state;
    }
};
