import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@/config/store';
import {yupClientDocuments} from '@components/Shopify/ClientProfile/yupClientDocuments';
import {addIdDocument} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {
    IAddIdentityDocsProps,
    AddIdDocumentData,
    UploadDocsProps,
} from '@components/Shopify/ClientProfile/interfaces';
import {IDocumentsInfo} from '@components/Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image, ContainerForm} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import closeIcon from '@images/closeDark.svg';
import {UPLOAD_DOCUMENTS} from '@components/Shopify/ClientProfile/constants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import ImageDocSelection from '@components/Shopify/ClientProfile/ImageDocSelection';

const AddIdentityDocs: React.FC<IAddIdentityDocsProps> = ({clientId, setShowModal}) => {
    const dispatch = useDispatch();

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const addedImageDocs = useSelector((state: RootState) => state.addIdentificationDoc.imageData);
    const addDocLoading = useSelector((state: RootState) => state.addIdentificationDoc.loading);

    const [documentsInfo, setDocumentsInfo] = useState<IDocumentsInfo>({
        idDocument: 1,
        frontImage: null,
        backImage: null,
    });

    const {
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(yupClientDocuments),
        defaultValues: {
            idDocument: documentsInfo.idDocument,
            imageDocFront: '',
            imageDocBack: '',
        },
    });

    const onSubmit = async (data: UploadDocsProps) => {
        const imageData: AddIdDocumentData = {
            clientId: clientId,
            identificationTypeId: data.idDocument,
            listImage: [
                {
                    fileExtension: documentsInfo.frontImage?.fileExtension as string,
                    fileContent: documentsInfo.frontImage?.formFile as string,
                },
                {
                    fileExtension: documentsInfo.backImage?.fileExtension as string,
                    fileContent: documentsInfo.backImage?.formFile as string,
                },
            ],
        };
        await dispatch(addIdDocument(imageData, documentsInfo, token));
        setShowModal(false);
    };

    useEffect(() => {
        setDocumentsInfo(addedImageDocs);
    }, [addedImageDocs]);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            Justify="flex-start"
            Gap="1rem"
            Radius="1rem"
            backG="#FFF"
            BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
        >
            <ContainerFlex
                Width="38.313rem"
                Padding="1rem 1.5rem"
                Height="5.5rem"
                Justify="space-between"
                Align="center"
            >
                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start" Gap="1rem">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                        {UPLOAD_DOCUMENTS.TITLE}
                    </Text>
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="400">
                        {UPLOAD_DOCUMENTS.SUBTITLE}
                    </Text>
                </ContainerFlex>
                <Image
                    src={closeIcon}
                    width="24px"
                    height="24px"
                    Cursor="pointer"
                    onClick={() => setShowModal(false)}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column">
                <ContainerFlex
                    Justify="center"
                    Align="center"
                    Gap="1.5rem"
                    Self="stretch"
                    Height="1rem"
                >
                    <ContainerFlex PaddingL="1.5rem" Justify="flex-start">
                        <ContainerFlex
                            Width="3.688rem"
                            Height="1rem"
                            Padding="0.125rem 0.5rem"
                            Justify="center"
                            Align="center"
                            Radius="3.125rem"
                            backG="#E5E5FF"
                            FontSize="0.75rem"
                            Color="#5A5AFF"
                            FontWeight="500"
                            Position="relative"
                            PositionLeft="1rem"
                        >
                            {UPLOAD_DOCUMENTS.FRONT}
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex PaddingR="1.5rem" Justify="flex-start">
                        <ContainerFlex
                            Width="3.688rem"
                            Height="1rem"
                            Padding="0.125rem 0.5rem"
                            Justify="center"
                            Align="center"
                            Radius="3.125rem"
                            backG="#E5E5FF"
                            FontSize="0.75rem"
                            Color="#5A5AFF"
                            FontWeight="500"
                            Position="relative"
                            PositionLeft="1rem"
                        >
                            {UPLOAD_DOCUMENTS.BACK}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerForm
                    onSubmit={handleSubmit(onSubmit)}
                    Padding="0 1.5rem 1rem"
                    FlexDir="column"
                    Align="center"
                    Justify="flex-start"
                    Gap="1.5rem"
                    BackG="none"
                >
                    <ContainerFlex
                        Height="10.063rem"
                        Justify="center"
                        Align="center"
                        Gap="1.5rem"
                        Self="stretch"
                    >
                        <ImageDocSelection
                            type={UPLOAD_DOCUMENTS.FRONT}
                            maxSize={25}
                            errors={errors}
                            documentsInfo={documentsInfo}
                            setDocumentsInfo={setDocumentsInfo}
                            setValue={setValue}
                        />
                        <ImageDocSelection
                            type={UPLOAD_DOCUMENTS.BACK}
                            maxSize={25}
                            errors={errors}
                            documentsInfo={documentsInfo}
                            setDocumentsInfo={setDocumentsInfo}
                            setValue={setValue}
                        />
                    </ContainerFlex>
                    {(errors?.imageDocFront?.message || errors?.imageDocBack?.message) && (
                        <Text FontSize="1rem" Color="#FF6357" FontWeight="400">
                            {UPLOAD_DOCUMENTS.IMAGES_ERROR}
                        </Text>
                    )}
                    <ContainerFlex
                        Height="2.5rem"
                        Justify="center"
                        Align="center"
                        Gap="1.5rem"
                        Self="stretch"
                    >
                        <ButtonGenerals
                            Radius="2rem"
                            Height="2.5rem"
                            Padding="0.5rem 1rem"
                            BackGC="transparent"
                            Justify="center"
                            Color="#5A5AFF"
                            HBackG="transparent"
                            FontWeight="500"
                            FontSize="1rem"
                            type="button"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            {UPLOAD_DOCUMENTS.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Radius="2rem"
                            Height="2.5rem"
                            Padding="0.5rem 1rem"
                            type="submit"
                            FontWeight="500"
                            disabled={addDocLoading}
                        >
                            {addDocLoading && (
                                <LoadingAtaskate width="0.625rem" padding="0.375rem" />
                            )}
                            {UPLOAD_DOCUMENTS.SAVE}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default AddIdentityDocs;
