import React from 'react';
import {Text, ContainerImg, ContainerFlex} from '@Shopify/Ecommerce/styles';
import arrowTopLeftIcon from '@images/arrowTopLeft.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {IInstallmentSummaryProps} from '@Loan/PaymentQuoter/Interfaces';
import {INSTALLMENTS_SUMMARY} from '@Loan/PaymentQuoter/constants';
import {ImageLogo} from '@/components/LoginClient/styles';

const InstallmentSummary: React.FC<IInstallmentSummaryProps> = ({pass}) => {
    return (
        <>
            <ContainerFlex Width="100%" Gap="1rem">
                <ContainerFlex FlexDir="column" Align="center" Width="20%">
                    <ContainerImg AlignItems="flex-start">
                        <ImageLogo
                            src={arrowTopLeftIcon}
                            Width="0.938rem"
                            Height="1.063rem"
                            alt="arrow-top-left-icon"
                        />
                    </ContainerImg>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="flex-start" Width="20%">
                    <Text Color="#1D1E20" FontSize="1rem" FontWeight="700">
                        {INSTALLMENTS_SUMMARY.PAYMENT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="flex-start" Width="60%">
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="400">
                        {INSTALLMENTS_SUMMARY.OUTSTANDING_BALANCE}
                        {numberToCurrency(pass.totalPass)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default InstallmentSummary;
