import {
    VALIDATE_IMAGE_PRODUCT,
    VALIDATE_IMAGE_PRODUCT_SUCCESS,
    VALIDATE_IMAGE_PRODUCT_ERROR,
    UPLOAD_IMAGE_PRODUCT,
    UPLOAD_IMAGE_PRODUCT_SUCCESS,
    UPLOAD_IMAGE_PRODUCT_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {AxiosError, AxiosResponse} from 'axios';
import {UploadArticleReducer} from '@Quoter/CoownerBeneficiary/Redux/interfaces';

const initialState: UploadArticleReducer = {
    validateImage: null,
    uploadImage: null,
    loading: false,
    error: false,
    errorData: null,
};

const UploadArticleImages = (
    state: UploadArticleReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case VALIDATE_IMAGE_PRODUCT:
            return {...state, loading: true, error: false, errorData: null};
        case VALIDATE_IMAGE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                validateImage: action.payload.data,
                errorData: null,
            };
        case VALIDATE_IMAGE_PRODUCT_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case UPLOAD_IMAGE_PRODUCT:
            return {...state, loading: true, error: false, errorData: null};
        case UPLOAD_IMAGE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                uploadImage: action.payload.data,
                errorData: null,
            };
        case UPLOAD_IMAGE_PRODUCT_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default UploadArticleImages;
