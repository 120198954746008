import {SEARCH_INVENTORY} from '@components/LocationsBranch/Redux/types';
import {SearchLevelState, SearchLevelAction} from '@components/LocationsBranch/Redux/interfaces';

const initialState: SearchLevelState = {
    data: [],
    loading: false,
    error: null,
};

const getSearchInventoryStorage = (
    state = initialState,
    action: SearchLevelAction
): SearchLevelState => {
    switch (action.type) {
        case SEARCH_INVENTORY.SEARCH_INVENTORY_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SEARCH_INVENTORY.SEARCH_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case SEARCH_INVENTORY.SEARCH_INVENTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SEARCH_INVENTORY.SEARCH_INVENTORY_CLEAR:
            return {
                ...state,
                loading: false,
                error: null,
                data: [],
            };
        default:
            return state;
    }
};

export default getSearchInventoryStorage;
