import React from 'react';
import * as style from '@Articles/style';
import {ACTIONS} from '@Articles/constants';
import {OtherActionProps} from '@Articles/interface';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';

const OtherActions = (props: OtherActionProps) => {
    const {addSwitch, showActivateModal, setShowActivateModal, ActionTypeForm, setAddSwitch} =
        props;
    const onSwitchChange = () => {
        if (ActionTypeForm === 1) {
            setAddSwitch(!addSwitch);
        } else {
            setShowActivateModal(!showActivateModal);
        }
    };
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Padding="1rem">
            <Text {...style.Card.Title} Padding="0.625rem 0">
                {ACTIONS.TITLE}
            </Text>
            <Text {...style.Card.Subtitle} Padding="0.625rem 0">
                {ACTIONS.ACTIVATE}
            </Text>
            <GreenSwitch checked={addSwitch} onChange={() => onSwitchChange()} />
        </ContainerFlex>
    );
};

export default OtherActions;
