import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {IStorageDataProps} from '@components/LocationsBranch//interfaces';
import EditIcon from '@images/editIcon.svg';

export const StorageData = ({
    storageType,
    onClickEdit,
    justifyContent = 'end',
}: IStorageDataProps) => {
    const [showEdit, setShowEdit] = useState(false);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            ColumnGap="0.5rem"
            HBackground="#f0f0ff"
            HBorderLeft="0.125rem solid #5a5aff"
            FlexDir="Column"
            Gap="0.5rem"
            Padding="0.5rem"
            Height={'2.5rem'}
            onMouseOver={() => {
                setShowEdit(true);
            }}
            onMouseLeave={() => {
                setShowEdit(false);
            }}
            onClick={() => {
                if (onClickEdit) onClickEdit(storageType);
            }}
        >
            <ContainerFlex
                Justify="start"
                Align="start"
                ColumnGap="0.5rem"
                FlexDir="row"
                Gap="0.5rem"
                Padding="0 0.5rem"
            >
                <ContainerFlex Justify="start" Flex="6">
                    <Text Color="#2A2C2F" FontWeight="500" Cursor="pointer" FontSize="1rem">
                        {storageType.name}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FontSize="0.75rem" Flex="6">
                    <ContainerFlex Justify={justifyContent} FontSize="0.75rem" Flex="9">
                        {storageType.productQuantity} Articulos
                    </ContainerFlex>
                    <ContainerFlex Justify={justifyContent} FontSize="0.75rem" Flex="3">
                        {showEdit && (
                            <Image
                                src={EditIcon}
                                alt="add"
                                Width="1.125rem"
                                Height="1.125rem"
                                Margin="0 0 0 1.125rem"
                            />
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
