import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ABOUT_PERSONAL_DATA_ERROR,
    ABOUT_PERSONAL_DATA_START,
    ABOUT_PERSONAL_DATA_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {DataPostPersonalLoans} from '@components/PersonalLoans/interfaces';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

export const fetchDataLoanStart = () => {
    return {
        type: ABOUT_PERSONAL_DATA_START,
    };
};
export const fetchDataLoanSuccess = (result: AxiosResponse) => {
    return {
        type: ABOUT_PERSONAL_DATA_SUCCESS,
        payload: result,
    };
};
export const fetchDataLoanError = (error: AxiosError) => {
    return {
        type: ABOUT_PERSONAL_DATA_ERROR,
        error: error,
    };
};

export function CreatePersonalLoansForm(data: DataPostPersonalLoans, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataLoanStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreatePersonalLoan}
            `,
                data,
                {headers}
            );
            dispatch(fetchDataLoanSuccess(response));
            dispatch(ActualDataStepLoans(2));
        } catch (error) {
            dispatch(fetchDataLoanError(error as AxiosError));
        }
    };
}
