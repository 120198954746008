import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {logoutSesion} from '@/components/Login/Interceptor/reduxLogOut';
import {
    ACCOUNTMENU,
    TYPE_USERS,
    containerStyles,
    stylesTextProps,
} from '@components/AdminNavbar/constants';
import {ClickAwayListener} from '@mui/material';
import {INavContextualMenu} from '@components/AdminNavbar/interfaces';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {DELETECARTCONTRACTS} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';
import logOut from '@images/Tertiary.svg';
import {AdminMenu} from '@components/AdminNavbar/AdminMenu';

export const AccountMenu = ({handleClickAway}: INavContextualMenu) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const posProfile = useSelector((state: RootState) => state.getNavBarPos);
    const {adminProfile} = useSelector((state: RootState) => state.adminUserProfileSettings);
    const userType = useSelector((state: RootState) => state.getUsersValidation.userData?.userType);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <ContainerFlex {...containerStyles}>
                <ContainerFlex
                    Padding="16px"
                    FlexDir="column"
                    Height="auto"
                    RowGap="10px"
                    backG="#FFFF"
                    Radius="0.75rem 0.75rem 0  0"
                    Gap="16px"
                >
                    <ContainerFlex Gap="0.625rem" Justify="start">
                        <Image
                            alt="user-image"
                            src={
                                userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.picture
                                    : adminProfile && adminProfile.picture
                            }
                            width="32px"
                            height="32px"
                            Border="1px solid #0D166B"
                            Radius="50%"
                            ObjectFit="cover"
                            AspectRatio="1 / 1"
                        />

                        <ContainerFlex FlexDir="column" Align="start" Width="70%" Height="auto">
                            <Text
                                FontSize="1rem"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {ACCOUNTMENU.WELCOME}{' '}
                                {userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.userName
                                    : adminProfile && adminProfile.itemNameMenu}
                            </Text>
                            <TextEllipsis
                                FontSize="0.75rem"
                                FontWeight="400"
                                Color="#54575C"
                                Width="100%"
                                FontFamily="Nunito"
                            >
                                {userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.email
                                    : adminProfile && adminProfile.itemNameMenu}
                            </TextEllipsis>
                        </ContainerFlex>
                    </ContainerFlex>
                    <AdminMenu />
                </ContainerFlex>
                <ContainerFlex backG="#F0F0FF" FlexDir="column">
                    <ContainerFlex
                        Padding="0.75rem 1.5rem"
                        Gap="0.625rem"
                        Justify="start"
                        Height="auto"
                    >
                        <Image
                            alt="rol-image"
                            width="32px"
                            height="32px"
                            src={
                                userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData &&
                                      posProfile.NavPosData.submenuPos.pictureAdministrator
                                    : adminProfile && adminProfile.pictureAdministrator
                            }
                            ObjectFit="cover"
                        />
                        <Text
                            FontSize="0.85rem"
                            FontWeight="500"
                            Cursor="pointer"
                            Color="#000"
                            FontFamily="Nunito"
                        >
                            {ACCOUNTMENU.ADMIN}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="0.75rem 1.5rem"
                        Gap="0.625rem"
                        Justify="start"
                        Height="auto"
                    >
                        <Image
                            alt="rol-image"
                            width="32px"
                            height="32px"
                            src={
                                userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData &&
                                      posProfile.NavPosData.submenuPos.picturePos
                                    : adminProfile && adminProfile.picturePos
                            }
                        />
                        <Text
                            FontSize="0.85rem"
                            FontWeight="500"
                            Cursor="pointer"
                            Color="#000"
                            FontFamily="Nunito"
                        >
                            {ACCOUNTMENU.POS}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Height="auto"
                    ColumnGap="8px"
                    Padding="0.75rem 1.5rem"
                >
                    <Image
                        alt="user-image"
                        src={logOut}
                        width="20px"
                        height="20px"
                        ObjectFit="cover"
                        AspectRatio="1 / 1"
                    />
                    <Text
                        {...stylesTextProps}
                        onClick={async () => {
                            if (token) {
                                dispatch(DELETECARTCONTRACTS());
                                await dispatch(
                                    DeleteTemporalyPawns(token, {
                                        deleteAllPawns: true,
                                        operationType: 1,
                                    })
                                );
                            }
                            logoutSesion(dispatch);
                        }}
                    >
                        {ACCOUNTMENU.LOGOUT}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};
