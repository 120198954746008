export const CREDITSANDPAWNS = {
    TITLE: 'Créditos y empeños',
    DESCRIPTION: 'Revisa las solicitudes, los créditos y los empeños.',
    INPUT_PLACEHOLDER: 'Busca un cliente o No. solicitud',
};

export const TAB_HEADER_TEXT = {
    CREDITS: 'Créditos',
    PAWNS: 'Empeños',
};

export const TAB_TITLES = {
    TOTAL: 'Disponible total',
    BRANCH: 'Disponible en sucursal',
    CREDITS: 'Invertido en créditos',
    APPROVED_CREDITS: 'Créditos aprobados',
};

export const TABLE_FILTERS = {
    APPLICATION: 'Solicitudes',
    UP_TO_DATE: 'Al día',
    OVERDUE: 'Atrasados',
    LIQUIDATED: 'Liquidados',
    REJECTED: 'Rechazados',
    APPROVED: 'Aprobados',
};
export const TABLE_STATUS_FILTER_VALUES = {
    UP_TO_DATE: 1,
    OVERDUE: 2,
    LIQUIDATED: 3,
    REJECTED: 4,
    SOLICITUDES: 5,
    APPROVED: 6,
};

export const APPLICATION_LIST = {
    TITLE: 'Lista de solicitudes',
    SWITCH_TITLE: 'Activar revisión de solicitudes en cola',
};

export const TABLE_HEADERS = {
    APPLICATION_ID: 'No. de solicitud',
    CLIENT: 'Cliente',
    SCORE: 'Score',
    VALUATION: 'Tasa',
    TERM: 'Plazo',
    AMOUNT: 'Monto',
    REQUEST_DATE: 'Fecha solicitud',
    PAYMENT_DATE: 'Fecha pago',
    APPROVAL_DATE: 'Fecha aprobación',
    LIQUIDATION_DATE: 'Fecha liquidado',
    REJECTION_DATE: 'Fecha de rechazo',
    STATE: 'Estado',
};

//Credit Request

export const CREDIT_REQUEST_FOOTER = {
    TAB_TITLE: 'Ventana principal',
};

export const CREDIT_REQUEST_HEADER = {
    CTA_BACK_TO: 'Volver a Solicitudes de Créditos',
    TITLE: 'Solicitud #',
};

export const CREDIT_REQUEST_KPI = {
    CONTRACTS: 'Contratos activos',
    MUTUAL_AMOUNT: 'Monto mutuo',
    TO_PAY: 'Por pagar',
    EARNINGS: 'Ganancias intereses',
    LOSSES: 'Perdidas',
    PAYMENTS_MADE: 'Pagos realizados',
};

export const CREDIT_REQUEST_USER = {
    NUC: 'NUC',
    CTA_INFORMATION: 'Más información',
    CTA_EVALUATION: 'Ver evaluación',
};

export const CREDIT_REQUEST_HISTORIES = {
    CREDIT_AND_PAWNS_TITLE: 'Historial de créditos y empeños',
    CREDIT_AND_PAWNS_TYPE: 'Tipo',
    CREDIT_AND_PAWNS_STATUS: 'Estado',
    CREDIT_AND_PAWNS_AMOUNT: 'Monto Mutuo',
    CREDIT_AND_PAWNS_PAYMENTS: 'Pagos',
    CREDIT_AND_PAWNS_DEBT: 'Deuda',
};

export const CREDIT_REQUEST_INFORMATION = {
    SUB_TITLE: 'Solicitud de crédito',
    AMOUNT: 'Monto solicitado',
    TERM: 'Plazo',
    ANNUAL_INTEREST_RATE: 'Tasa de interés anual',
    REQUEST_DATE: 'Fecha de solicitud',
    CTA_REJECT: 'Rechazar solicitud',
    CTA_APPROVE: 'Aprobar',
    PERCENTAGE_MESSAGE: 'Elige el un porcentaje a prestar o escribe una cantidad',
    LEND: 'Préstaras',
    RECEIVE: 'Recibirás',
};

export const CREDIT_REQUEST_NOTES = {
    SUB_TITLE: 'Notas para el cliente',
};

export const ACCORDION_STYLES = {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
        display: 'none',
    },
};

export const ACCORDION_STYLES_NO_PADDING = {
    padding: '0px',
};

export const NUL_SEARCH = 'NUL';

export const CREDITSANDPAWNS_FOOTER_TABS = {
    VENTANA_PRINCIPAL: 'Ventana principal',
};

export const NUEVA_SOLICITUD = 'Nueva solicitud';

export const NO_RESULTS_FOUND = {
    SEARCH: 'Parece que este contrato o nombre no existe en el listado',
    TABLE: 'No hay solicitudes',
    FILTER_SEARCH: 'Nombre no existe en el listado.',
};

export const FILTER_TITLES = {
    FREQUENCY: 'Frecuencia del pago',
    AMOUNT: 'Monto',
    REQUEST_DATE: 'Fecha de solicitud',
    PAYMENT_DATE: 'Fecha de pago',
    SCORE: 'Score',
    INTERES_RATE: 'Tasa de interés',
    CLIENT: 'Cliente',
};

export const FILTER_NAMES = {
    SCORE_START: 'scoreStart',
    SCORE_END: 'scoreEnd',
    ANUAL_INTEREST_START: 'interestAnualStart',
    ANUAL_INTEREST_END: 'interestAnualEnd',
    AMOUNT_MAX_CREDIT_START: 'amountMaxCreditStart',
    AMOUNT_MAX_CREDIT_END: 'amontMaxCreditEnd',
    REQUESTED_DATE_START: 'requestedDateStart',
    REQUESTED_DATE_END: 'requestedDateEnd',
    PAYMENT_DATE_START: 'paymentDateStart',
    PAYMENT_DATE_END: 'paymentDateEnd',
};

export const SLIDER_TYPE = {
    CURRENCY: 'money',
    PERCENTAGE: 'percentage',
};

export const DEFAULT_PAGE = 1;

export const NO_FILTER_RESULT = {
    TITLE: 'No se han encontrado resultados',
    BUTTON_TEXT: 'Ver Filtros',
};

export const LIMITS_RANGES = {
    BAD: {
        scoreColor: '#A82424',
        scoreColorBg: '#F7D3D3',
        infoIconColor: '',
        min: 300,
        max: 549,
        startAngle: 220,
        endAngle: 259,
    },
    REGULAR: {
        scoreColor: '#CC9200',
        scoreColorBg: '#F8E2A6',
        infoIconColor: '',
        min: 550,
        max: 632,
        startAngle: 260,
        endAngle: 315,
    },
    GOOD: {
        scoreColor: '#008FCC',
        scoreColorBg: '#DBF4FF',
        infoIconColor: '',
        min: 633,
        max: 670,
        startAngle: 316,
        endAngle: 365,
    },
    EXCELLENT: {
        scoreColor: '#309C60',
        scoreColorBg: '#8AD0A8',
        infoIconColor: '',
        min: 671,
        max: 850,
        startAngle: 366,
        endAngle: 400,
    },
};

//Customer Information Modal

export const MODAL_INFO_HEADER = {
    TITLE: 'Información del cliente',
};

export const MODAL_INFO_BODY = {
    SUBTITLE_EMPLOYMENT: 'Información laboral',
    EMPLOYMENT_SITUATION: 'Situación laboral',
    COMPANY: 'Empresa',
    ADDRESS: 'Dirección',
    JOB: 'Puesto',
    MONTHLY_INCOME: 'Ingresos mensuales',
    PHONE: 'Teléfono',
    LABOR_OLD: 'Antiguedad',
    SUBTITLE_VOUCHERS: 'Comprobantes',
};

export const MODAL_INFO_FOOTER = {
    CTA_CLOSE: 'Cerrar',
};

export const TAG_KEYS = {
    START: 'Start',
    END: 'End',
    AMOUNT: 'montoMaximoCredito',
    REQUEST_DATE: 'fechaSolicitud',
    PAYMENT_DATE: 'fechaPago',
    SCORE: 'score',
    INTERES_RATE: 'interesAnual',
};

export const AprovedCreditList = [
    {
        amount: 4333,
        applicationId: 62,
        applicationNumber: '100CP17',
        clientName: 'Diego Mora',
        fee: 50,
        frecuenciaPlazoCreditosId: 1013,
        nameTerm: '9 Mensual',
        paymentDate: '2024-08-13T00:00:00',
        requestDate: '2024-08-05T00:00:00',
        score: 500,
        solPrestDatosPersonalId: 0,
        statusId: 6,
        statusName: 'Aprobado',
        updatDate: null,
        isChecked: false,
    },
];
export const LiquidatedList = [
    {
        amount: 4333,
        applicationId: 62,
        applicationNumber: '100CP17',
        clientName: 'Alberto Vega',
        fee: 50,
        frecuenciaPlazoCreditosId: 1013,
        nameTerm: '9 Mensual',
        paymentDate: '2024-08-13T00:00:00',
        requestDate: '2024-08-05T00:00:00',
        score: 500,
        solPrestDatosPersonalId: 0,
        statusId: 3,
        statusName: 'Liquidado',
        updatDate: null,
        isChecked: false,
    },
];
export const RejectedList = [
    {
        amount: 4333,
        applicationId: 62,
        applicationNumber: '100CP17',
        clientName: 'Carolina Reyes',
        fee: 50,
        frecuenciaPlazoCreditosId: 1013,
        nameTerm: '9 Mensual',
        paymentDate: '2024-08-13T00:00:00',
        requestDate: '2024-08-05T00:00:00',
        score: 500,
        solPrestDatosPersonalId: 0,
        statusId: 4,
        statusName: 'Rechazado',
        updatDate: null,
        isChecked: false,
    },
];
