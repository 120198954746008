import {useEffect, useRef} from 'react';

export type UseInfiniteScrollProps = {
    onIntersect: () => void;
    root?: Element | null;
    rootMargin?: string;
    threshold?: number;
};

export const useInfiniteScroll = ({
    onIntersect,
    root = null,
    rootMargin = '0px 0px 0px 0px',
    threshold = 0.5,
}: UseInfiniteScrollProps) => {
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onIntersect();
                }
            },
            {
                root,
                rootMargin,
                threshold,
            }
        );
        const target = targetRef.current;
        if (target !== null) {
            observer.observe(target);
            return () => observer.unobserve(target);
        }
    }, [onIntersect, root, rootMargin, threshold]);

    return targetRef;
};
