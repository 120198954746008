import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_LOAN_SUMMARY_ERROR,
    GET_CREDIT_LOAN_SUMMARY_START,
    GET_CREDIT_LOAN_SUMMARY_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {ICreditLoanSummaryReducer} from '@components/PersonalLoans/interfaces';

const initialState: ICreditLoanSummaryReducer = {
    data: null,
    loading: false,
    error: false,
};

const getCreditLoanSummary = (
    state: ICreditLoanSummaryReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): ICreditLoanSummaryReducer => {
    switch (action.type) {
        case GET_CREDIT_LOAN_SUMMARY_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getCreditLoanSummary;
