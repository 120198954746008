import {IReducerType} from '@/components/Branches/interfaces';
import {
    POST_SERVICES_COMPLEMENTS,
    POST_SERVICES_COMPLEMENTS_ERROR,
    POST_SERVICES_COMPLEMENTS_FINISH,
    POST_SERVICES_COMPLEMENTS_RESET,
    POST_SERVICES_COMPLEMENTS_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    createComplements: 0,
};

const PostServicesComplements = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_SERVICES_COMPLEMENTS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_SERVICES_COMPLEMENTS_SUCCESS:
            return {
                ...state,
                createComplements: action.payload.data.data,
            };
        case POST_SERVICES_COMPLEMENTS_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_SERVICES_COMPLEMENTS_FINISH:
            return {
                ...state,
                loading: false,
            };
        case POST_SERVICES_COMPLEMENTS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default PostServicesComplements;
