import {AppDispatch} from '@/config/store';
import {
    CAT_COMPANY_BENEFIT,
    CAT_COMPANY_BENEFIT_SUCCESS,
    CAT_COMPANY_BENEFIT_ERROR,
} from '@components/SignUp/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

export const catCompanyBenefit = () => {
    return {
        type: CAT_COMPANY_BENEFIT,
    };
};
export const catCompanyBenefitSuccess = (result: AxiosResponse) => {
    return {
        type: CAT_COMPANY_BENEFIT_SUCCESS,
        payload: result,
    };
};
export const catCompanyBenefitError = (error: AxiosError) => {
    return {
        type: CAT_COMPANY_BENEFIT_ERROR,
        error: error,
    };
};

export function infoCompanyBenefit() {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catCompanyBenefit());
        try {
            const response = await axios.get(
                `
            ${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatCompanyBenefits}`,
                {
                    headers,
                }
            );
            dispatch(catCompanyBenefitSuccess(response));
        } catch (error) {
            dispatch(catCompanyBenefitError(error as AxiosError));
        }
    };
}
