import {useCallback, useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {
    selectGroupLevel,
    cancelGroupTypeSelection,
} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {GetLevelsByLevelId} from '@components/LocationsBranch/Redux/Action/GetListLevelsByLevelId';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';

const useLoadLevels = (type: string, companyId: number, token: string) => {
    const dispatch = useDispatch();
    const [groupsFilling, setGroupsFilling] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const groupTypes = useSelector((state: RootState) => state.levelGroups.groupTypes);
    const currentSelection = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === type
    )?.selectedLevel.breadcrum;
    const levelGroups = groupTypes.find((groups) => groups.groupType === type)?.levelGroups || [];
    const levels = useSelector((state: RootState) => state.getLevels.level);

    useEffect(() => {
        if (companyId && token && !currentSelection && levels) {
            loadLevelGroup(1, levels.levelId);
        }
    }, [companyId, token, levels?.levelId]);

    const loadLevelGroup = useCallback(
        async (group: number, levelId: number) => {
            if (token && companyId) {
                await dispatch(GetLevelsByLevelId(companyId, type, group, levelId, token));
            }
        },
        [dispatch, companyId, token]
    );

    const handleGroupSelect = useCallback(
        async (group: number, selectedLevelId: number) => {
            await dispatch(selectGroupLevel(group, selectedLevelId, type));

            if (selectedLevelId !== 0 && group < levelGroups.length) {
                await loadLevelGroup(group + 1, selectedLevelId);
            }
        },
        [dispatch, loadLevelGroup, levelGroups.length]
    );

    const handleSearchSelect = useCallback(
        async (item: LevelSelection) => {
            try {
                setGroupsFilling(true);
                item.ids.forEach(async (id, index) => {
                    const groupNumber = levelGroups[index]?.group || index + 1;
                    await handleGroupSelect(groupNumber, id);
                });
            } catch (error) {
                setErrorMessage('Ocurrió un error al seleccionar niveles.');
            } finally {
                setGroupsFilling(false);
            }
        },
        [handleGroupSelect]
    );

    const getGroupOptions = useCallback(
        (group: number) => {
            const currentGroupOptions = levelGroups.find(
                (levelGroup) => levelGroup.group === group
            );
            return currentGroupOptions ? currentGroupOptions.levels : [];
        },
        [levelGroups]
    );

    const getSelectedValue = useCallback(
        (group: number) => {
            const currentGroup = levelGroups.find((levelGroup) => levelGroup.group === group);
            return currentGroup ? currentGroup.selectedLevelId : 0;
        },
        [levelGroups]
    );

    const handleResetGroup = useCallback(async () => {
        if (levels) dispatch(cancelGroupTypeSelection(companyId, type, levels.levelId, token));
    }, [dispatch, companyId, token]);

    return useMemo(
        () => ({
            groupsFilling,
            levelGroups,
            currentSelection,
            handleGroupSelect,
            getGroupOptions,
            getSelectedValue,
            handleSearchSelect,
            handleResetGroup,
            errorMessage,
        }),
        [
            groupsFilling,
            levelGroups,
            currentSelection,
            handleGroupSelect,
            getGroupOptions,
            getSelectedValue,
            handleSearchSelect,
            handleResetGroup,
            errorMessage,
        ]
    );
};

export default useLoadLevels;
