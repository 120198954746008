import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_ACTIVATE_LEVEL_OR_ITEM_START,
    GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS,
    GET_ACTIVATE_LEVEL_OR_ITEM_ERROR,
} from '@components/Catalogue/Redux/Types/Types';

const initialState = {
    data: {},
    loading: false,
    error: false,
};

const getActivateLevelOrItem = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_ACTIVATE_LEVEL_OR_ITEM_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ACTIVATE_LEVEL_OR_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getActivateLevelOrItem;
