import styled from '@emotion/styled';
import {IPropsImageLogo} from '@/components/MarketForgottenLogin/Operational/interfacesStyles';

export const ImageLogo = styled.img<IPropsImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: ${(props) => props.Margin};
`;

export const ButtonImg = styled.button`
    position: absolute;
    left: 5%;
    top: 20px;
    padding: 5px 10px;
    background-color: white;
    border: none;
    cursor: pointer;
`;
export const Icon = styled.img<{color: string}>`
    font-size: 0.875rem;
    color: ${(props) => props.color};
    width: auto;
    height: auto;
`;
