import {AxiosResponse} from 'axios';
import {
    GET_POS_NAV,
    GET_POS_NAV_ERROR,
    GET_POS_NAV_SUCCESS,
    RESET_NAV_BAR_POS,
} from '@components/NavBarPOS/Redux/types';
import {IAllUserPOSData} from '@components/NavBarPOS/Redux/inteface';

const initialState = {
    NavPosData: null,
    loading: false,
    error: false,
};
const getNavBarPos = (
    state: IAllUserPOSData = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_POS_NAV:
            return {
                ...initialState,
                loading: true,
                error: false,
            };
        case GET_POS_NAV_SUCCESS:
            return {
                ...state,
                NavPosData: action.payload.data,
            };
        case GET_POS_NAV_ERROR:
            return {
                ...state,
                error: true,
            };
        case RESET_NAV_BAR_POS:
            return initialState;
        default:
            return state;
    }
};

export default getNavBarPos;
