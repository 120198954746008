import axios from 'axios';
import {
    POST_CREATE_FUNDING,
    POST_CREATE_FUNDING_SUCCESS,
    POST_CREATE_FUNDING_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

import process from 'process';

import {URL} from '@config/constants/index';

export const CreateFunding = () => {
    return {
        type: POST_CREATE_FUNDING,
    };
};
export const CreateFundingSuccess = (data) => {
    return {
        type: POST_CREATE_FUNDING_SUCCESS,
        payload: data,
    };
};

export const CreateFundingError = (err) => {
    return {
        type: POST_CREATE_FUNDING_ERROR,
        payload: err,
    };
};

export const SendCashfFnding = (token, FundingData) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(CreateFunding());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateCashFunding}`,
                FundingData,
                {headers}
            );
            dispatch(CreateFundingSuccess(response));
            return response;
        } catch (error) {
            dispatch(CreateFundingError(error));
            throw error;
        }
    };
};
