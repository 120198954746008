import * as yup from 'yup';

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAMES_REGEX = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\s]+$/u;
const PASSWORD_REGEX = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ]+$/;

export const schema = yup.object().shape({
    names: yup
        .string()
        .required('Ingrese su nombre')
        .matches(NAMES_REGEX, 'Solo se permiten caracteres alphanuméricos'),
    secondsNames: yup
        .string()
        .required('Ingrese su apellido')
        .matches(NAMES_REGEX, 'Solo se permiten caracteres alphanuméricos'),
    email: yup
        .string()
        .required('Ingrese un correo electrónico')
        .matches(EMAIL_REGEX, 'Ingrese un correo electrónico válido')
        .max(254, 'La longitud máxima es de 254 caracteres'),
    password: yup
        .string()
        .required('Ingrese una contraseña')
        .matches(PASSWORD_REGEX, 'La contraseña no cumple los parámetros')
        .min(8, 'La contraseña debe incluir minimo 8 caracteres'),
    confirmPassword: yup
        .string()
        .required('Ingrese una contraseña')
        .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
});
