import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import Skeleton from '@mui/material/Skeleton';
import expanded from '@images/expande.svg';
import filter from '@images/filter.svg';
import cards from '@images/mastercardIcon.svg';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {hideDigits, OPERATIONS} from '@Shopify/ClientProfile/constants';
import {TransactionClientDetail} from '@Shopify/ClientProfile/TransactionClientDetail';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {getClientTransactions} from '@Shopify/ClientProfile/Redux/Action/GetTransactions';

export const Transactions = () => {
    const dispatch = useDispatch();
    const {transactions, loading} = useSelector((state: RootState) => state.getClientTransactions);
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);

    const onClickDetail = () => {
        setShowTransactionDetail(true);
    };

    useEffect(() => {
        dispatch(getClientTransactions());
    }, []);

    return (
        <ContainerFlex FlexDir="column" Align="end">
            <FlexPanel {...style.filter} Border="1px solid #5A5AFF" Radius="2rem">
                <Image Height="1.5rem" Width="1.5rem" src={filter} alt="filters-icon" />
                <Text {...style.filter.text}>{OPERATIONS.FILTER}</Text>
            </FlexPanel>
            <ContainerFlex {...style.table.content} Height="min-content" Padding="0.5rem">
                {OPERATIONS.TRANSACTIONS.HEADERS.map((header: string, index: number) => (
                    <ContainerFlex
                        key={index}
                        Cursor={index === 0 ? 'pointer' : 'auto'}
                        Gap="0.25rem"
                        Justify="start"
                    >
                        <Text {...style.table.header} Padding="0.25rem 0">
                            {header}
                        </Text>
                        {index === 0 && <Image src={expanded} alt="expanded-icon" />}
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            {!loading && ((transactions && transactions.length === 0) || !transactions) && (
                <ContainerFlex
                    Padding="0.5rem 1rem"
                    Gap="1rem"
                    Bb="1px solid #E8E9EA"
                    Height="2.5rem"
                >
                    <Text Cursor="pointer" FontSize="0.875rem" Color="#2A2C2F" FontWeight="400">
                        {OPERATIONS.TRANSACTIONS.NO_RESULTS}
                    </Text>
                </ContainerFlex>
            )}
            {transactions &&
                transactions.map((item) => (
                    <ContainerFlex key={item.id} {...style.table.content}>
                        <Text {...style.table.header}>{item.noTransaction}</Text>
                        <Text {...style.table.details}>{item.datePay}</Text>
                        <Text {...style.table.details}>{item.pay}</Text>
                        <Text {...style.table.details}>{item.typePay}</Text>
                        <ContainerFlex
                            FlexDir="column"
                            Align="start"
                            Gap="0.25rem"
                            {...style.table.details}
                        >
                            {hideDigits(item.payment)}
                            <Image src={cards} />
                        </ContainerFlex>
                        <Text {...style.styleStatusTransaction(item.status)}>{item.status}</Text>
                        <Text
                            Cursor="pointer"
                            FontSize="0.75rem"
                            Color="#5A5AFF"
                            FontWeight="700"
                            onClick={() => onClickDetail()}
                        >
                            {OPERATIONS.TRANSACTIONS.DETAIL}
                        </Text>
                    </ContainerFlex>
                ))}

            {loading && (
                <ContainerFlex {...style.table.content}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </ContainerFlex>
            )}
            {showTransactionDetail && (
                <TransactionClientDetail
                    setShowTransactionDetail={setShowTransactionDetail}
                    showTransactionDetail={showTransactionDetail}
                />
            )}
        </ContainerFlex>
    );
};
