import React from 'react';
import {ContainerFlex, Text, ContainerCellGrid} from '@Shopify/Ecommerce/styles';
import {EXPENSE_DETAIL_COMPONENT} from '@/components/CashFlowExpenseDetails/operational/constants';
import {DateTime} from 'luxon';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const MainInformationExpense = () => {
    const expenseDetailData = useSelector(
        (state: RootState) => state.getExpenseDetails.expenseDetails
    );

    const dateStr = expenseDetailData.expenseDate;
    const formattedDate = DateTime.fromISO(dateStr).toFormat('dd/MM/yy');
    return (
        <ContainerFlex Padding="16px 24px" FlexDir="column" Gap="16px">
            <ContainerFlex Justify="start">
                <Text FontWeight="500" Color="#2A2C2F" FontSize="1.25rem">
                    {EXPENSE_DETAIL_COMPONENT.INFORMATION}
                </Text>
            </ContainerFlex>
            <ContainerCellGrid Gap="24px">
                <ContainerFlex FlexDir="column" Justify="start" Gap="24px">
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                            {EXPENSE_DETAIL_COMPONENT.EXPENSE_TYPE}
                        </Text>
                        <Text Padding="8px 0px" FontSize="1rem" FontWeight="400" Color="#54575C">
                            {expenseDetailData.expenseTypeName}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                            {EXPENSE_DETAIL_COMPONENT.REFERENCE}
                        </Text>
                        <Text Padding="8px 0px" FontSize="1rem" FontWeight="400" Color="#54575C">
                            {expenseDetailData.referenceNumber}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex FlexDir="column" Gap="24px">
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                            {EXPENSE_DETAIL_COMPONENT.SUPPLIER}
                        </Text>
                        <Text Padding="8px 0px" FontSize="1rem" FontWeight="400" Color="#54575C">
                            {expenseDetailData.expenseProviderName}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                            {EXPENSE_DETAIL_COMPONENT.EXPENSE_DATE}
                        </Text>
                        <Text Padding="8px 0px" FontSize="1rem" FontWeight="400" Color="#54575C">
                            {formattedDate}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex Align="start" Justify="start" FlexDir="column">
                    <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                        {EXPENSE_DETAIL_COMPONENT.PAYMENT_METHOD}
                    </Text>
                    <Text Padding="8px 0px" FontSize="1rem" FontWeight="400" Color="#54575C">
                        {expenseDetailData.paymentMethodName}
                    </Text>
                </ContainerFlex>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
