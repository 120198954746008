import {
    ADD_CODE_MARKET_LOGIN,
    ADD_COUNT_MARKET_LOGIN,
    RESET_CODE_MARKET_LOGIN,
} from '@components/MarketLogin/redux/types/types';

export const addCodeMarketLogin = (result) => {
    return {
        type: ADD_CODE_MARKET_LOGIN,
        payload: result,
    };
};
export const addCountMarketLogin = (result) => {
    return {
        type: ADD_COUNT_MARKET_LOGIN,
        payload: result,
    };
};
export const resetCodeMarketLogin = () => {
    return {
        type: RESET_CODE_MARKET_LOGIN,
    };
};
