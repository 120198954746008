import React from 'react';
import {TabsMenuContainer, Tab, ArrowBack} from '@components/MyAccount/TabsMenu/styles';
import {TABS_MENU_OPTIONS} from '@components/MyAccount/constants';
import {useNavigate} from 'react-router-dom';
import arrowBack from '@/images/arrow_back_alt.svg';

interface TabsMenuProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

const TabsMenu = ({activeTab, setActiveTab}: TabsMenuProps) => {
    const navigate = useNavigate();
    const tabMenu = TABS_MENU_OPTIONS.map((tab, index) => {
        return (
            <Tab
                key={index}
                className={activeTab === tab ? 'active' : ''}
                onClick={() => (index === 0 ? navigate(-1) : setActiveTab(tab))}
            >
                {index === 0 && <ArrowBack src={arrowBack} alt="arrow-back" />}
                {tab}
            </Tab>
        );
    });

    return <TabsMenuContainer>{tabMenu}</TabsMenuContainer>;
};

export default TabsMenu;
