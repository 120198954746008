import React, {useState} from 'react';
import {RootState} from '@config/store';
import {useSelector} from 'react-redux';

import {Icons} from '@Articles/style';
import {ModalInfoContac} from '@MyCompany/ModalInfoContac';
import {MY_COMPANY, withoutInfo} from '@MyCompany/constants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

export const TaxCompany = () => {
    const [modalState, setModalState] = useState(false);
    const {companyTax} = useSelector((state: RootState) => state.detailsCompany);

    return (
        <ContainerFlex
            backG="#FFF"
            Radius="1rem"
            Height="auto"
            Gap="1rem"
            Padding="1rem"
            FlexDir="Column"
        >
            <ContainerFlex Justify="space-between">
                <Text FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                    {MY_COMPANY.TAX_ENTERPRICE}
                </Text>
                <Text FontWeight="500" Color="#5A5AFF">
                    {MY_COMPANY.EDIT}
                </Text>
            </ContainerFlex>
            {companyTax && (
                <>
                    {(companyTax.companyAddress === MY_COMPANY.WITHOUTINFO ||
                        companyTax.rfc === MY_COMPANY.WITHOUTINFO ||
                        companyTax.taxEmail === MY_COMPANY.WITHOUTINFO) && (
                        <ContainerFlex
                            Radius="0.5rem"
                            Gap="0.5rem"
                            Padding="0.5rem"
                            backG="#FFF5DB"
                            FontWeight="500"
                            Color="#614500"
                            Justify="start"
                        >
                            <Icons Color="#CC9200" FontSize="1.25rem" className="material-icons">
                                warning
                            </Icons>
                            {MY_COMPANY.COMPLETE_TAX}
                        </ContainerFlex>
                    )}
                    <ContainerFlex FlexDir="Column" Align="start" Gap="0.25rem">
                        <Text Color="#54575C">{MY_COMPANY.RFC}</Text>
                        <Text {...withoutInfo(companyTax.rfc)}>{companyTax.rfc}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="Column" Align="start" Gap="0.25rem">
                        <Text Color="#54575C">{MY_COMPANY.EMAIL}</Text>
                        <Text {...withoutInfo(companyTax.taxEmail)}>{companyTax.taxEmail}</Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="Column" Align="start" Gap="0.25rem">
                        <Text Color="#54575C">{MY_COMPANY.TAX_ADDRESS}</Text>
                        <Text {...withoutInfo(companyTax.companyAddress)}>
                            {companyTax.companyAddress}
                        </Text>
                    </ContainerFlex>
                </>
            )}
            <ModalInfoContac setModalState={setModalState} modalState={modalState} />
        </ContainerFlex>
    );
};
