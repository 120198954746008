import * as yup from 'yup';

const regexAlphanumeric = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/;
export const schemaEditTaxInfo = yup
    .object()
    .shape({
        rfc: yup.string().required('RFC es requerido.'),
        email: yup
            .string()
            .required('El correo electrónico es requerido.')
            .email('No cumple con el formato de email.'),
        cp: yup.string().required('El código postal es requerido.'),
        street: yup.string().required('Ingresar una calle.'),
        externalNumber: yup
            .string()
            .required('Ingresar un No. exterior.')
            .max(5, 'Ingresar máximo 5 caracteres')
            .matches(regexAlphanumeric, 'Ingresá alfanuméricos'),
        internalNumber: yup
            .string()
            .optional()
            .test('length', 'Ingresá menos de 6 caracteres', (value) => {
                if (!value) return true;
                return value.length <= 5;
            })
            .test('is-valid', 'Ingresá alfanuméricos', (value) => {
                if (!value) return true;
                return regexAlphanumeric.test(value) && value.length <= 5 && value.length >= 1;
            }),
        colonyId: yup
            .object()
            .shape({
                value: yup.string().required(),
                label: yup.string(),
            })
            .required()
            .typeError('Selecciona una Colonia'),
        municipalityId: yup
            .object()
            .shape({
                value: yup.string().required(),
                label: yup.string(),
            })
            .required()
            .typeError('Selecciona una Alcaldía'),
        stateId: yup
            .object()
            .shape({
                value: yup.string().required(),
                label: yup.string(),
            })
            .required()
            .typeError('Selecciona un Estado'),
    })
    .test('general-error', 'Al menos un campo debe ser proporcionado.', function (value) {
        const {rfc, email, cp, street, externalNumber, colonyId, municipalityId, stateId} = value;
        if (
            !rfc ||
            !email ||
            !cp ||
            !street ||
            !externalNumber ||
            !colonyId ||
            !municipalityId ||
            !stateId
        ) {
            return this.createError({
                path: 'at-least-one',
                message: 'Verifica los campos marcados en rojo.',
            });
        }
        return true;
    });
