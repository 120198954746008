import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_LIST_LOANS_START,
    GET_LIST_LOANS_SEARCH_START,
    GET_LIST_LOANS_SEARCH_SUCCESS,
    GET_LIST_LOANS_SEARCH_ERROR,
    GET_LIST_PERSONAL_SUCCESS,
    GET_LIST_PERSONAL_ERROR,
    SET_LIST_LOANS_STATUS,
    CLEAR_LIST_LOANS_SEARCH,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {NUL_SEARCH} from '@components/CreditsAndPawns/constants';

export const getListLoansStart = () => {
    return {
        type: GET_LIST_LOANS_START,
    };
};

export const getListLoansSearchStart = () => {
    return {
        type: GET_LIST_LOANS_SEARCH_START,
    };
};

export const getListLoansSearchSuccess = (result: AxiosResponse) => {
    return {
        type: GET_LIST_LOANS_SEARCH_SUCCESS,
        payload: result,
    };
};

export const getListLoansSearchError = (error: AxiosError) => {
    return {
        type: GET_LIST_LOANS_SEARCH_ERROR,
        error: error,
    };
};

export const getListPersonalSuccess = (result: AxiosResponse) => {
    return {
        type: GET_LIST_PERSONAL_SUCCESS,
        payload: result,
    };
};

export const getListPersonalError = (error: AxiosError) => {
    return {
        type: GET_LIST_PERSONAL_ERROR,
        error: error,
    };
};

export const setListLoansStatus = (status: number) => {
    return {
        type: SET_LIST_LOANS_STATUS,
        status,
    };
};

export const clearListLoansSearch = () => {
    return {
        type: CLEAR_LIST_LOANS_SEARCH,
    };
};

export const getListLoansSearch = (
    token: string,
    companyId: number,
    pageSize: number,
    pageNumber: number,
    status: number,
    search: string,
    isJustSearch: boolean
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        if (search === '') search = NUL_SEARCH;
        if (isJustSearch) dispatch(getListLoansSearchStart());
        else dispatch(getListLoansStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetListLoanPersonal}`,
                {headers, params: {search, companyId, status, pageSize, pageNumber}}
            );
            if (isJustSearch) dispatch(getListLoansSearchSuccess(response));
            else dispatch(getListPersonalSuccess(response));
        } catch (error) {
            if (isJustSearch) dispatch(getListLoansSearchError(error as AxiosError));
            else dispatch(getListPersonalError(error as AxiosError));
        }
    };
};
