import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 67px);
    width: 100vw;
`;
export const Img = styled.img`
    width: 80px;
`;
export const Text = styled.span`
    font-family: WorkSans;
    font-size: 40px;
    font-weight: 400;
    color: #cbced1;
`;
