import {
    USER_INFO_BRANCH,
    USER_INFO_BRANCH_SUCCESS,
    USER_INFO_BRANCH_ERROR,
} from '@components/CloseBranch/redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    Branch: [],
};

const getUserDataBranch = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case USER_INFO_BRANCH:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case USER_INFO_BRANCH_SUCCESS:
            return {
                ...state,
                Branch: action.payload.data,
                loading: false,
            };
        case USER_INFO_BRANCH_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};

export default getUserDataBranch;
