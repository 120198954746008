import axios from 'axios';
import {
    VERIFY_CODE_REQUEST_CLIENT,
    VERIFY_CODE_SUCCESS_CLIENT,
    VERIFY_CODE_ERROR_CLIENT,
} from '@components/MarketForgottenLogin/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';

export const PostVerifyCodeResquestClient = () => {
    return {
        type: VERIFY_CODE_REQUEST_CLIENT,
    };
};
export const PostVeryfyCodeSuccessClient = (data) => {
    return {
        type: VERIFY_CODE_SUCCESS_CLIENT,
        payload: data,
    };
};

export const PostVeryfyCodeFailureClient = (err) => {
    return {
        type: VERIFY_CODE_ERROR_CLIENT,
        payload: err,
    };
};

export const PostCodeNewPassword = (jsonData, navigate, setErrors) => {
    const {rowid, code} = jsonData;
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer',
    };

    return async (dispatch) => {
        dispatch(PostVerifyCodeResquestClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.EmailClientCodeValidator.replace(
                    '{rowid}',
                    rowid
                ).replace('{code}', code)}`,
                {headers}
            );
            dispatch(PostVeryfyCodeSuccessClient(response));
            navigate('/Login/RestoredNewPassword');
        } catch (error) {
            dispatch(PostVeryfyCodeFailureClient(error));
            setErrors(true);
        }
    };
};
