export const GET_HOME_STARS = 'GET_HOME_STARS';
export const GET_HOME_STARS_SUCCESS = 'GET_HOME_STARS_SUCCESS';
export const GET_HOME_STARS_ERROR = 'GET_HOME_STARS_ERROR';
export const GET_HOME_MOVEMENTS = 'GET_HOME_MOVEMENTS';
export const GET_HOME_MOVEMENTS_SUCCESS = 'GET_HOME_MOVEMENTS_SUCCESS';
export const GET_HOME_MOVEMENTS_ERROR = 'GET_HOME_MOVEMENTS_ERROR';
export const GET_HOME_FILTER = 'GET_HOME_FILTER';
export const GET_HOME_FILTER_SUCCESS = 'GET_HOME_FILTER_SUCCESS';
export const GET_HOME_FILTER_ERROR = 'GET_HOME_FILTER_ERROR';
export const GET_HOME_ORDERS = 'GET_HOME_ORDERS';
export const GET_HOME_ORDERS_SUCCESS = 'GET_HOME_ORDERS_SUCCESS';
export const GET_HOME_ORDERS_ERROR = 'GET_HOME_ORDERS_ERROR';
export const GET_HOME_CLIENT = 'GET_HOME_CLIENT';
export const GET_HOME_CLIENT_SUCCESS = 'GET_HOME_CLIENT_SUCCESS';
export const GET_HOME_CLIENT_ERROR = 'GET_HOME_CLIENT_ERROR';
export const GET_FILTER_COUNTRY = 'GET_FILTER_COUNTRY';
export const GET_FILTER_BUSINESS = 'GET_FILTER_BUSINESS';
export const GET_HOME_FAVS = 'GET_HOME_FAVS';
export const GET_HOME_FAVS_SUCCESS = 'GET_HOME_FAVS_SUCCESS';
export const GET_HOME_FAVS_ERROR = 'GET_HOME_FAVS_ERROR';
export const GET_CARDS = 'GET_CARDS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_ERROR = 'GET_CARDS_ERROR';
export const PUT_CARDS = 'PUT_CARDS';
export const PUT_CARDS_SUCCESS = 'PUT_CARDS_SUCCESS';
export const PUT_CARDS_ERROR = 'PUT_CARDS_ERROR';
export const POST_CARDS = 'POST_CARDS';
export const POST_CARDS_SUCCESS = 'POST_CARDS_SUCCESS';
export const POST_CARDS_ERROR = 'POST_CARDS_ERROR';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const GET_HOME_ENDORSEMENT_START = 'GET_HOME_ENDORSEMENT_START';
export const GET_HOME_ENDORSEMENT_SUCCESS = 'GET_HOME_ENDORSEMENT_SUCCESS';
export const GET_HOME_ENDORSEMENT_ERROR = 'GET_HOME_ENDORSEMENT_ERROR';
export const RESET_CLIENT = 'RESET_CLIENT';
