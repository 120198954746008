import {AppDispatch, RootState} from '@/config/store';
import {
    GET_CAT_GROUP,
    GET_CAT_GROUP_ERROR,
    GET_CAT_GROUP_SUCCESS,
    GET_FAMILY_CONCAT_LEVEL,
    GET_SUBFAMILY_SUCCESS,
    GET_BRAND_CONCAT_SUCCESS,
    GET_ARTICLE_SUCCESS,
    TOTAL_PAGES_ITEM,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError} from 'axios';
import {IDATAFACE} from '@components/RequestNewCatalogLevel/interfacesTree';
import {ICatGruop} from '@components/RequestNewCatalogLevel/Redux/Reducers/GetCatGroups';

export const getCatGroup = () => {
    return {
        type: GET_CAT_GROUP,
    };
};
export const getCatGroupPageSuccess = (result: ICatGruop[]) => {
    return {
        type: TOTAL_PAGES_ITEM,
        page: result,
    };
};
export const getCatGroupSuccess = (result: ICatGruop[]) => {
    return {
        type: GET_CAT_GROUP_SUCCESS,
        payload: result,
    };
};

export const getCatGroupError = (error: AxiosError) => {
    return {
        type: GET_CAT_GROUP_ERROR,
        payload: error,
    };
};

export const getCatGroupAction = (
    isScroll: boolean,
    pageSize: number,
    pageNumber: number,
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(getCatGroup());
        const params = {
            pageSize: pageSize?.toString(),
            pageNumber: pageNumber?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatGroup}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            const currentCatGroup: ICatGruop = getState().getCatGroups;
            let updatedCatGroup = [...currentCatGroup.catGroup, ...response.data.data];
            if (!isScroll) {
                updatedCatGroup = response.data.data;
                dispatch(getCatGroupPageSuccess(response.data.totalPages));
            }
            dispatch(getCatGroupSuccess(updatedCatGroup));
        } catch (error) {
            dispatch(getCatGroupError(error as AxiosError));
        }
    };
};
export const updateFamilyChildren = (id: number, children: IDATAFACE[]) => ({
    type: GET_FAMILY_CONCAT_LEVEL,
    payload: {id, children},
});
export const updateSubFamilyChildren = (id: number, children: IDATAFACE[]) => ({
    type: GET_SUBFAMILY_SUCCESS,
    payload: {id, children},
});

export const updateBrandChildren = (id: number, children: IDATAFACE[]) => ({
    type: GET_BRAND_CONCAT_SUCCESS,
    payload: {id, children},
});

export const updateArticleChildren = (id: number, children: IDATAFACE[]) => ({
    type: GET_ARTICLE_SUCCESS,
    payload: {id, children},
});
