import {ACTIONS_TYPE_PRODUCTS} from '@ActionsDragandDrop/actions';
import {Payload, Product} from '@DragAndDrop/interfaces';

const INITIAL_STATE: {products: Product[]; ShoppingCar: Product[]} = {
    products: [],
    ShoppingCar: [],
};

const productsEcommerce = (state = INITIAL_STATE, action: Payload) => {
    switch (action.type) {
        case ACTIONS_TYPE_PRODUCTS.GetProducts:
            return {
                ...state,
                products: action.payload,
            };
        case ACTIONS_TYPE_PRODUCTS.UpDateProducts:
            return {
                ...state,
                products: action.payload,
            };
        case ACTIONS_TYPE_PRODUCTS.DeleteProducts:
            return {
                ...state,
                products: [],
            };
        case ACTIONS_TYPE_PRODUCTS.DeleteProduct:
            return {
                ...state,
                products: [
                    ...state.products,
                    state.products.filter((element) => element.id !== action.payload),
                ],
            };
        case ACTIONS_TYPE_PRODUCTS.ShoppingCar:
            return {
                ...state,
                ShoppingCar: action.payload,
            };
        default:
            return state;
    }
};

export default productsEcommerce;
