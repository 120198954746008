import React, {useEffect} from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {InformationUser} from '@components/ManageUser/InformationUser';
import {AccessToSystem} from '@components/ManageUser/AccessToSystem';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getTreeComponent} from '@General/BranchTree/Redux/Actions/getOrganizationsAction';

export const FormUserInformation = () => {
    const dispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));
    }, []);
    return (
        <ContainerFlex FlexDir="column" Width="calc(100% - (18% + 25px))" Height="auto" Gap="24px">
            <InformationUser />
            <AccessToSystem />
        </ContainerFlex>
    );
};
