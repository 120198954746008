import {AppDispatch} from '@/config/store';
import {NavigateFunction} from 'react-router-dom';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {IBranch} from '@components/LocationsBranch/interfaces';
import {NEW_BRANCH} from '@components/LocationsBranch/Redux/types';
import {resetFormLocation} from '@/components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const newBranchStart = () => ({type: NEW_BRANCH.START});
export const newBranchSuccess = (result: {data: AxiosResponse}) => ({
    type: NEW_BRANCH.SUCCESS,
    newBranch: result.data,
});
export const newBranchError = (error: AxiosError) => ({
    type: NEW_BRANCH.ERROR,
    error: error,
});
export const newBranch = (token: string, navigate: NavigateFunction, branch: IBranch) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(newBranchStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateBranch}`,
                branch,
                {headers}
            );
            dispatch(newBranchSuccess(response.data));
            resetFormLocation(dispatch);
            navigate('/LocationBranch');
        } catch (error) {
            dispatch(newBranchError(error as AxiosError));
        }
    };
};
