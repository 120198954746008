import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_SOLICITUDE_TERMS_DETAILS_ERROR,
    GET_SOLICITUDE_TERMS_DETAILS_START,
    GET_SOLICITUDE_TERMS_DETAILS_SUCCESS,
    CLEAR_DATA_TERMS_DETAILS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {DUE_DETAILS} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/constants';
import {
    ISolicitudeTermReducer,
    ISolicitudeTerm,
    ISolicitudeTermAPI,
} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';

const initialState: ISolicitudeTermReducer = {
    data: null,
    loading: false,
    error: false,
};

const getSolicitudeTermsDetails = (
    state: ISolicitudeTermReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): ISolicitudeTermReducer => {
    switch (action.type) {
        case GET_SOLICITUDE_TERMS_DETAILS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_SOLICITUDE_TERMS_DETAILS_SUCCESS:
            return {
                data: getSolicitudeTermsDetailsData(action.payload),
                loading: false,
                error: false,
            };
        case GET_SOLICITUDE_TERMS_DETAILS_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        case CLEAR_DATA_TERMS_DETAILS:
            return initialState;
        default:
            return state;
    }
};

const getSolicitudeTermsDetailsData = (response: AxiosResponse): ISolicitudeTerm[] => {
    const {data} = response;

    return data?.map((term: ISolicitudeTermAPI) => {
        const {countQuotes, dateDue, tikectUrl, status, paymentType, paymentDate} = term;
        const indexDelimiter = paymentType?.indexOf(DUE_DETAILS.PAYMENT_TYPE_DELIMITER);
        const paymentMethodIcon = indexDelimiter && paymentType.substring(0, indexDelimiter).trim();
        const paymentMethod = indexDelimiter && paymentType.substring(indexDelimiter).trim();
        const statusSetupMap = new Map([
            [
                DUE_DETAILS.PAYMENT_STATUS_TYPES.PAID.LABEL.toLocaleLowerCase(),
                {
                    label: DUE_DETAILS.PAYMENT_STATUS_TYPES.PAID.LABEL,
                    background: DUE_DETAILS.PAYMENT_STATUS_TYPES.PAID.BACKGROUND,
                    color: DUE_DETAILS.PAYMENT_STATUS_TYPES.PAID.COLOR,
                    borderColor: DUE_DETAILS.PAYMENT_STATUS_TYPES.PAID.BORDER_COLOR,
                    isPaid: true,
                },
            ],
            [
                DUE_DETAILS.PAYMENT_STATUS_TYPES.UNPAID.LABEL.toLocaleLowerCase(),
                {
                    label: DUE_DETAILS.PAYMENT_STATUS_TYPES.UNPAID.LABEL,
                    background: DUE_DETAILS.PAYMENT_STATUS_TYPES.UNPAID.BACKGROUND,
                    color: DUE_DETAILS.PAYMENT_STATUS_TYPES.UNPAID.COLOR,
                    borderColor: DUE_DETAILS.PAYMENT_STATUS_TYPES.UNPAID.BORDER_COLOR,
                    isPaid: false,
                },
            ],
            [
                DUE_DETAILS.PAYMENT_STATUS_TYPES.OVERDUE.LABEL.toLocaleLowerCase(),
                {
                    label: DUE_DETAILS.PAYMENT_STATUS_TYPES.OVERDUE.LABEL,
                    background: DUE_DETAILS.PAYMENT_STATUS_TYPES.OVERDUE.BACKGROUND,
                    color: DUE_DETAILS.PAYMENT_STATUS_TYPES.OVERDUE.COLOR,
                    borderColor: DUE_DETAILS.PAYMENT_STATUS_TYPES.OVERDUE.BORDER_COLOR,
                    isPaid: false,
                },
            ],
        ]);
        const statusSetup = statusSetupMap.get(status.toLocaleLowerCase());

        const solicitudeTerm = {
            fee: countQuotes,
            paymentDate,
            expirationDate: dateDue,
            status,
            statusSetup,
            voucherURL: tikectUrl,
            paymentMethod,
            paymentMethodIcon,
        };
        return solicitudeTerm;
    });
};
export default getSolicitudeTermsDetails;
