import {
    GET_CAT_MUNICIPALITY,
    GET_CAT_MUNICIPALITY_ERROR,
    GET_CAT_MUNICIPALITY_RESET,
    GET_CAT_MUNICIPALITY_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    catMunicipality: [],
};

const GetCatMunicipality = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_CAT_MUNICIPALITY:
            return {
                ...state,
                loading: true,
            };
        case GET_CAT_MUNICIPALITY_SUCCESS:
            return {
                ...state,
                catMunicipality: action.payload.data,
                loading: false,
            };
        case GET_CAT_MUNICIPALITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_CAT_MUNICIPALITY_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetCatMunicipality;
