import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import errorIcone from '@images/closeRed.svg';
import {ImageLogo as LoginImageLogo} from '@LoginClient/styles';
interface IMessage {
    message: string;
}
export const ErrorMessagesPassword = ({message}: IMessage) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <LoginImageLogo Width="auto" Height="auto" src={errorIcone} />
            <Text
                Color="#FF6357"
                FontSize="0.85rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
            >
                {message}
            </Text>
        </ContainerFlex>
    );
};
