import {
    GET_AMORTIZATION_TABLE_LOAN_START,
    GET_AMORTIZATION_TABLE_LOAN_SUCCESS,
    GET_AMORTIZATION_TABLE_LOAN_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IAmortizationTableReducer} from '@components/PersonalLoans/interfaces';

const initialState: IAmortizationTableReducer = {
    data: [],
    loading: false,
    error: false,
};

const getAmortizationTableLoan = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_AMORTIZATION_TABLE_LOAN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_AMORTIZATION_TABLE_LOAN_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_AMORTIZATION_TABLE_LOAN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default getAmortizationTableLoan;
