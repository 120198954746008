import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {
    ContainerCellGrid,
    ContainerFlex,
    Figure,
    Image,
    Input,
    Modal,
    Text,
} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {Checkbox} from '@General/Atoms/Checkbox/Checkbox';
import IconPreview from '@ManageUser/Icons/preview.svg';
import IconPReviewOff from '@ManageUser/Icons/previewOff.svg';
import {IFormEditPassword, PropsModalPassword} from '@components/ManageUser/interface';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {
    PostChangePass,
    addMessagePasswordChange,
} from '@components/CreateUsers/Redux/Actions/PostChangePassword';
import {schemaPassword} from '@components/CreateUsers/validationsYup';
import {UserData} from '@components/ManageUser/Redux/interface';

export const ModalChangePassword = ({setOpenModalPassword}: PropsModalPassword) => {
    const dispatch: AppDispatch = useDispatch();
    const {idUser} = useParams();
    const [viewPassword, setViewPassword] = useState(false);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const passwordRandom = useSelector((state: RootState) => state.getPasswordRandom.data);
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    const onSubmit = (data: IFormEditPassword) => {
        dispatch(PostChangePass(token, data));
        setOpenModalPassword(false);
        dispatch(addMessagePasswordChange(UserInformation.userEmployeeName));
    };

    const {
        register,
        handleSubmit,
        trigger,
        setValue,
        formState: {errors, isValid},
    } = useForm<IFormEditPassword>({
        defaultValues: {userId: idUser},
        resolver: yupResolver(schemaPassword),
    });

    useEffect(() => {
        setValue('newPassword', passwordRandom);
        trigger('newPassword');
    }, [passwordRandom, setValue, trigger]);

    return (
        <Modal>
            <ContainerFlex
                backG="#fff"
                Width="40%"
                Height="356px"
                FlexDir="column"
                BoxS="0px 0px 12px rgba(0, 0, 0, 0.5)"
                BoxShadow=""
                Justify="start"
            >
                <Text
                    Color="#35CF44"
                    Padding="0 24px"
                    Height="56px"
                    FontSize="1.25rem"
                    BorderBt="1px solid #35CF44"
                    Width="100%"
                >
                    Cambiar contraseña
                </Text>
                <ContainerFlex
                    Display="grid"
                    AlignContent="space-between"
                    Height="212px"
                    GridRows="20px 70px 20px"
                    GridColumns="100%"
                    Padding="24px"
                >
                    <Text FontSize="0.875rem" Color="#414042">
                        Cambiar Contraseña
                    </Text>
                    <ContainerCellGrid
                        Wrap="wrap"
                        AlignContent="start"
                        Justify="space-between"
                        Height="80px"
                    >
                        <Text Color="#6d6e71" FontSize="0.75rem" required>
                            Nueva contraseña
                        </Text>
                        <Input
                            Width="93%"
                            type={!viewPassword ? 'password' : 'text'}
                            Padding="12px"
                            FontSize="0.875rem"
                            maxLength={16}
                            placeholder="Contraseña"
                            {...register('newPassword')}
                            error={errors.newPassword?.message}
                            onKeyUp={() => trigger('newPassword')}
                        />
                        <Figure>
                            <Image
                                src={!viewPassword ? IconPReviewOff : IconPreview}
                                onClick={() => setViewPassword(!viewPassword)}
                            />
                        </Figure>
                        <Text FontSize="0.625rem" FontWeight="normal" Color="#ef4f55" Height="1rem">
                            {errors.newPassword?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid Gap="8px">
                        <Checkbox />
                        <Text FontSize="0.875rem" Color="#414042">
                            Pedir al usuario cambiar su contraseña cuando inicie sesión
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex
                    Height="88px"
                    Bt="1px solid #E5E7E9"
                    Justify="end"
                    Gap="24px"
                    Padding="24px"
                >
                    <ButtonGeneral
                        text="Cancelar"
                        width="93px"
                        secondaryButton={true}
                        transform="capitalize"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => {
                            setOpenModalPassword(false);
                        }}
                        border="1px solid transparent"
                    />
                    <ButtonGeneral
                        disabled={!isValid}
                        text="Cambiar"
                        width="90px"
                        transform="capitalize"
                        border="1px solid #414042"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder="#35CF44"
                        hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        dFontWeight="400"
                        clic={handleSubmit(onSubmit)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
