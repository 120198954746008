import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {MODAL_MOTION} from '@/components/CashFlowActions/Operational/Constants';

export const TextMotion = () => {
    return (
        <ContainerFlex Height="max-content" FlexDir="column" Align="start">
            <Text
                Padding="24px"
                FontSize="24px"
                FontWeight="700"
                Color="#1D1E20"
                FontFamily="Nunito"
            >
                {MODAL_MOTION.TITTLE}
            </Text>
            <ContainerFlex Height="max-content" Align="start" FlexDir="column">
                <Text FontWeight="400" Color="#54575C" FontSize="1rem" Padding="0px 24px 0px">
                    {MODAL_MOTION.TEXT}
                </Text>
                <Text FontWeight="400" Color="#54575C" FontSize="1rem" Padding="0px 24px 0px">
                    {MODAL_MOTION.TEXT_ONE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
