import React from 'react';
import {TableItem, TableRowItems} from '@/components/Shopify/Endeavors/styles';
import {Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IMovementRowDataProps} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';
import {numberToCurrency} from '@/components/CashFlow/constants';
import {DUE_DETAILS} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/constants';
import {DateTime} from 'luxon';
import {formatDate} from '@/hooks/currentFormatUtils';

export const MovementRowData = ({
    index,
    tableLength,
    transactionType,
    paymentMethod,
    paymentMethodURL,
    payment,
    paymentDate,
}: IMovementRowDataProps) => {
    return (
        <>
            <TableRowItems
                key={index}
                GridColumn="repeat(6,1fr)"
                Height="48px"
                BorderL="none"
                BorderT="none"
                Margin="0px"
                Radius="0px"
                BorderB={tableLength && index === tableLength - 1 ? '' : '1px solid #E8E9EA'}
                Cursor="default"
            >
                <TableItem>
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {transactionType}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text
                        Width="120px"
                        Justify="center"
                        FontSize="0.875rem"
                        Color="#2A2C2F"
                        Gap="0.5rem"
                    >
                        {paymentMethod?.slice(-8)}
                        <Image
                            src={paymentMethodURL}
                            Width="24px"
                            Height="16px"
                            alt={DUE_DETAILS.ALT_DESCRIPTIONS.TYPE_PAYMENT}
                        />
                    </Text>
                </TableItem>
                <TableItem>
                    <Text
                        Width="120px"
                        Justify="center"
                        FontSize="0.875rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                    >
                        {numberToCurrency(payment)}
                    </Text>
                </TableItem>
                <TableItem>
                    <Text Width="120px" Justify="center" FontSize="0.875rem" Color="#2A2C2F">
                        {formatDate(DateTime.fromISO(paymentDate), DUE_DETAILS.FORMAT_DATE)}
                    </Text>
                </TableItem>
            </TableRowItems>
        </>
    );
};
