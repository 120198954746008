import {BRANCH_NAME} from '@/components/Locations/ManageLocations/redux/types/ManageLocationsTypes';
import {AppDispatch} from '@/config/store';
import {
    GET_NEAR_BY_BRANCHES,
    GET_NEAR_BY_BRANCHES_SUCCESS,
    GET_NEAR_BY_BRANCHES_ERROR,
    CAT_EXCESS,
    CAT_EXCESS_SUCCESS,
    CAT_EXCESS_ERROR,
    BRANCH_SUCCESS,
} from '@ReduxsCashFlow/Types/Types';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';

export const getNearByBranches = () => {
    return {
        type: GET_NEAR_BY_BRANCHES,
    };
};

export const getNearByBranchesSuccess = (value: {data: AxiosResponse<string>}) => {
    return {
        type: GET_NEAR_BY_BRANCHES_SUCCESS,
        nearByBranches: value.data,
    };
};

export const getNearByBranchesError = (value: unknown) => {
    return {
        type: GET_NEAR_BY_BRANCHES_ERROR,
        payload: value,
    };
};

export function axiosGetNearByBranches(token: string, BranchId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getNearByBranches());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.EmpoloyeeAcount.replace(
                    '{BranchId}',
                    BranchId
                )}`,
                {headers}
            );
            dispatch(getNearByBranchesSuccess(response.data));
        } catch (error) {
            dispatch(getNearByBranchesError(error));
        }
    };
}

export const cat_excess = () => {
    return {
        type: CAT_EXCESS,
    };
};

export const cat_excessSuccess = (value: {data: AxiosResponse<string>}) => {
    return {
        type: CAT_EXCESS_SUCCESS,
        catExcess: value.data,
    };
};

export const cat_excessError = (value: unknown) => {
    return {
        type: CAT_EXCESS_ERROR,
        payload: value,
    };
};

export function CatExcessMotive(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(cat_excess());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatExcessMotive}`,
                {headers}
            );
            dispatch(cat_excessSuccess(response.data));
        } catch (error) {
            dispatch(cat_excessError(error));
        }
    };
}

export const branchSelected = (branch: string) => {
    return {
        type: BRANCH_SUCCESS,
        branch: branch,
    };
};

export const branchNames = (nameBranch: string) => {
    return {
        type: BRANCH_NAME,
        payload: nameBranch,
    };
};

export const branchSelection = (dispatch: AppDispatch, branch: string, nameBranch: string) => {
    dispatch(branchSelected(branch));
    dispatch(branchNames(nameBranch));
};
