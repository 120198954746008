import {NACIONALITY_A, NACIONALITY_ERROR, NACIONALITY_SUCCESS} from '@TypesQouter/TypesGeneral';
import {INationality, INationalityRedux} from '@Quoter/Redux/intefaces';

const initialState: INationality = {
    error: false,
    loading: false,
    nacionality: [],
};

const getNacionality = (state = initialState, actions: INationalityRedux): INationality => {
    switch (actions.type) {
        case NACIONALITY_A:
            return {
                ...state,
                loading: true,
            };
        case NACIONALITY_SUCCESS:
            return {
                ...state,
                loading: false,
                nacionality: actions.nacionality,
            };
        case NACIONALITY_ERROR:
            return {
                ...state,
                error: false,
                loading: true,
            };
        default:
            return state;
    }
};

export default getNacionality;
