import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {IImageType, ValidateDocumentImageData} from '@Shopify/ClientProfile/interfaces';
import {VALIDATE_ID_DOC_IMAGE} from '@Shopify/ClientProfile/Redux/types';

const validateStart = (imageType: IImageType) => ({
    type: VALIDATE_ID_DOC_IMAGE.SET_START,
    imageType,
});
const validateSuccess = (result: AxiosResponse, imageType: IImageType) => ({
    type: VALIDATE_ID_DOC_IMAGE.SET_SUCCESS,
    payload: result.data,
    imageType,
});
const validateError = (error: AxiosError, imageType: IImageType) => ({
    type: VALIDATE_ID_DOC_IMAGE.SET_ERROR,
    error: error,
    imageType,
});
export const validateDocumentImage = (
    data: ValidateDocumentImageData,
    imageType: IImageType,
    token: string
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(validateStart(imageType));
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidClientIdImage}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(validateSuccess(response.data, imageType));
        } catch (error) {
            dispatch(validateError(error as AxiosError, imageType));
        }
    };
};
