import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    ContainerFlex,
    ContainerForm,
    ContainerImg,
    Text,
    Image,
} from '@/components/Shopify/Ecommerce/styles';

import {yupResolver} from '@hookform/resolvers/yup';
import {shemaResetPassword} from '@/components/MarketForgottenLogin/Operational/ResetAccountYup';
import {ErrorMessagesPassword} from '@/components/MarketForgottenLogin/MessageError';
import {SearchInput} from '@/components/Customer/Styles';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {ErrorPassword} from '@/components/MarketForgottenLogin/ErrorPassword';
import {ErrorPasswordRepeat} from '@/components/MarketForgottenLogin/MessageErrorRepeat';

import {
    NEW_PASSWORD,
    RESTORE_NEW_PASSWORD,
} from '@/components/MarketForgottenLogin/Operational/contants';
import {RootState} from '@/config/store';
import {NewPassword} from '@/components/MarketForgottenLogin/Operational/interfaces';
import {NewPasswordUserData} from '@/components/MarketForgottenLogin/Redux/Actions/NewPasswordAction';
import {useNavigate} from 'react-router-dom';

export const ResetPasswordClient = () => {
    const userData = useSelector((state: RootState) => state.addEmail.email);
    const email = userData;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        watch,
        formState: {errors},
    } = useForm<NewPassword>({
        resolver: yupResolver(shemaResetPassword),
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            repeatPassword: '',
        },
    });

    const [viewPassword, setViewPassword] = useState(false);
    const password = watch('password');
    const repeatPassword = watch('repeatPassword');
    const onSubmit = (values: NewPassword) => {
        const newUserData = {
            email: email,
            newPassword: password,
        };
        dispatch(NewPasswordUserData(newUserData, navigate));
    };

    return (
        <ContainerFlex Height="100vh" backG="#E5E5FF">
            <ContainerForm BackG="" onSubmit={handleSubmit(onSubmit)}>
                <ContainerFlex
                    Radius="24px"
                    backG="#FFFFFF"
                    Padding="32px"
                    Gap="12px"
                    Width="407px"
                    FlexDir="column"
                    Height="457"
                    Border="1px solid #0D166B"
                >
                    <Text FontSize="1.5rem" FontFamily="Nunito" FontWeight="700" Color="#2A2C2F">
                        {RESTORE_NEW_PASSWORD.TITLE}
                    </Text>
                    <ContainerFlex Gap="1px" FlexDir="column" Height="auto">
                        <Text FontSize="0.75rem" FontFamily="Nunito" Color="#2A2C2F">
                            {RESTORE_NEW_PASSWORD.MESSAGE}
                        </Text>
                        <Text FontSize=".75rem" FontFamily="Nunito" Color="#2A2C2F">
                            {RESTORE_NEW_PASSWORD.MESSAGE_ONE}
                        </Text>
                        <Text FontSize=".75rem" FontFamily="Nunito" Color="#2A2C2F">
                            {RESTORE_NEW_PASSWORD.MESSAGE_TWO}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        FlexDir="column"
                        Align="start"
                        Gap="8px"
                        Height="auto"
                    >
                        <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700" Color="#1D1E20">
                            {NEW_PASSWORD.PASS}
                        </Text>
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            error={errors.password?.message}
                            Radius="32px"
                        >
                            <input
                                type={viewPassword ? 'Text' : 'Password'}
                                {...register('password')}
                            />
                            <ContainerImg onClick={() => setViewPassword(!viewPassword)}>
                                <Image
                                    src={viewPassword ? VisibilityOn : VisibilityOff}
                                    alt="icon-warning"
                                    Cursor="pointer"
                                />
                            </ContainerImg>
                        </SearchInput>
                        {password.length > 0 && (
                            <ErrorPassword
                                error={errors.password?.message ? true : false}
                                password={password}
                            />
                        )}
                        {errors.password?.message && password.length === 0 && (
                            <ErrorMessagesPassword message={errors.password?.message} />
                        )}
                        <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700" Color="#2A2C2F">
                            {NEW_PASSWORD.PASS_CONFIRM}
                        </Text>
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            error={errors.repeatPassword?.message}
                            Radius="32px"
                        >
                            <input
                                type={viewPassword ? 'Text' : 'Password'}
                                {...register('repeatPassword')}
                            />
                            {repeatPassword.length > 0 && (
                                <ContainerImg onClick={() => setViewPassword(!viewPassword)}>
                                    <Image
                                        src={viewPassword ? VisibilityOn : VisibilityOff}
                                        alt="icon-warning"
                                        Cursor="pointer"
                                    />
                                </ContainerImg>
                            )}
                        </SearchInput>
                        {repeatPassword.length > 0 && (
                            <ErrorPasswordRepeat
                                error={errors.repeatPassword?.message ? true : false}
                                password={password}
                            />
                        )}
                        {errors.repeatPassword?.message && repeatPassword.length === 0 && (
                            <ErrorMessagesPassword message={errors.repeatPassword?.message} />
                        )}
                    </ContainerFlex>
                    <ButtonGeneral
                        type="submit"
                        FontSize="1rem"
                        padding="8px 16px"
                        height="40px"
                        width="100%"
                        text="Continuar"
                    />
                </ContainerFlex>
            </ContainerForm>
        </ContainerFlex>
    );
};
