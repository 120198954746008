import React from 'react';
import {ContainerFlex, Figure, Image, Input, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {InputStyles} from '@components/CreateUsers/Styles';
import edit from '@components/CreateUsers/Icons/edit.svg';
import {IPropsEnterPositionJobAndRol} from '@components/CreateUsers/Interface';
import {SelectMultiRoles} from '@components/CreateUsers/SelectMultiRoles';

export const EnterJobPositionAndRol = ({
    register,
    setModalStateRol,
    listEmployeeRoles,
    modalStateRol,
    setValue,
    trigger,
    inputName,
    inputLastName,
    saveChangesForm,
}: IPropsEnterPositionJobAndRol) => {
    return (
        <ContainerFlex MarginTop="24px">
            <ContainerFlex FlexDir="column" Align="start" FlexWrap="wrap">
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Puesto
                </Text>
                <Input
                    Cursor="text"
                    maxLength={160}
                    placeholder="Puesto"
                    {...InputStyles}
                    {...register('jobPosition')}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="start" AlignContent="end" FlexWrap="wrap">
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Rol
                </Text>
                <ContainerFlex
                    Height="40px"
                    Width="300px"
                    Border="1px solid #E5E7E9"
                    BoxShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                    HBorderColor="#35CF44"
                    Radius="4px"
                    Padding="0 12px"
                    Cursor="pointer"
                    Justify="space-between"
                    onClick={() => setModalStateRol(true)}
                >
                    {listEmployeeRoles?.length !== 0 ? (
                        <TextEllipsis
                            Cursor="pointer"
                            FontSize="0.875rem"
                            Color="#414042"
                            Width="90%"
                            FontWeight="400"
                        >
                            {listEmployeeRoles.map((employee, index: number) => {
                                return `${employee.roleName}${
                                    index === listEmployeeRoles.length - 1 ? '' : ', '
                                }`;
                            })}
                        </TextEllipsis>
                    ) : (
                        <Text Cursor="pointer" FontSize="0.875rem" Color="#cbced1">
                            Seleccionar rol
                        </Text>
                    )}
                    <Figure>
                        <Image src={edit} alt={'icon-edit'} />
                    </Figure>
                </ContainerFlex>
                <SelectMultiRoles
                    setModalStateRol={setModalStateRol}
                    modalStateRol={modalStateRol}
                    setValuesEmployeeRoles={(newValue) => setValue('employeeRolees', newValue)}
                    validateValuesEmployeeRoles={() => trigger('employeeRolees')}
                    name={inputName}
                    lastName={inputLastName}
                    saveChangesForm={saveChangesForm}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
