import {
    GET_EMPLOYE_BY_BRANCH_CASH_FUNDING,
    GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_SUCCESS,
    GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const employeeCashFunding = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYE_BY_BRANCH_CASH_FUNDING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: null,
            };
        case GET_EMPLOYE_BY_BRANCH_CASH_FUNDING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload?.data?.data,
            };
        default:
            return state;
    }
};
