import {
    BENEFICIARY_EDIT_SUCCESS,
    BENEFICIARY_EDIT_ERROR,
    BENEFICIARY_EDIT,
} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingBeneficiaryUpdated: false,
    idBeneficiaryUpdated: 0,
};

const putDataBenefiaciary = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case BENEFICIARY_EDIT:
            return {
                ...state,
                loadingBeneficiaryUpdated: true,
                error: false,
            };
        case BENEFICIARY_EDIT_SUCCESS:
            return {
                ...state,
                loadingBeneficiaryUpdated: false,
                idBeneficiaryUpdated:
                    state.idBeneficiaryUpdated !== 0 ? 0 : actions.payload.data.data,
            };
        case BENEFICIARY_EDIT_ERROR:
            return {
                ...state,
                error: false,
                loadingBeneficiaryUpdated: true,
            };
        default:
            return state;
    }
};

export default putDataBenefiaciary;
