export const STEP_PAYMENT_SELECTED = 'STEP_PAYMENT_SELECTED';
export const ACTION_STATE_SELECTED = 'ACTION_STATE_SELECTED';
export const SELECT_QUICK_PAYMENT = 'SELECTED_QUICK_PAYMENT';
export const RESET_ACTION_PAYMENT = 'RESET_ACTION_PAYMENT';

export const GET_FAST_PAYMENT_PL_START = 'GET_FAST_PAYMENT_PL_START';
export const GET_FAST_PAYMENT_PL_SUCCESS = 'GET_FAST_PAYMENT_PL_SUCCESS';
export const GET_FAST_PAYMENT_PL_ERROR = 'GET_FAST_PAYMENT_PL_ERROR';
export const SELECT_TYPE_ACCESS = 'SELECT_TYPE_ACCESS';
export const RESET_FAST_PAYMENT_PL_DATA = 'RESET_FAST_PAYMENT_PL_DATA';

export const ADD_PERSONAL_CREDIT_TO_CREDITS = 'ADD_PERSONAL_CREDIT_TO_CREDITS';
export const REMOVE_PERSONAL_CREDIT_IN_CREDITS = 'REMOVE_PERSONAL_CREDIT_IN_CREDITS';
export const ADD_FAST_PAYMENT_START = 'ADD_FAST_PAYMENT_START';
export const ADD_FAST_PAYMENT_SUCCESS = 'ADD_FAST_PAYMENT_SUCCESS';
export const ADD_FAST_PAYMENT_ERROR = 'ADD_FAST_PAYMENT_ERROR';
export const RESET_FAST_PAYMENT = 'RESET_FAST_PAYMENT';

export const SEND_CONFIRMATION_VOUCHER_START = 'SEND_CONFIRMATION_VOUCHER_START';
export const SEND_CONFIRMATION_VOUCHER_SUCCESS = 'SEND_CONFIRMATION_VOUCHER_SUCCESS';
export const SEND_CONFIRMATION_VOUCHER_FAIL = 'SEND_CONFIRMATION_VOUCHER_FAIL';
export const SEND_CONFIRMATION_VOUCHER_RESET = 'SEND_CONFIRMATION_VOUCHER_RESET';
