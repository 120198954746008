import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    CAT_LABOR_OLD_START,
    CAT_LABOR_OLD_SUCCESS,
    CAT_LABOR_OLD_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const fetchLaborDataStart = () => {
    return {
        type: CAT_LABOR_OLD_START,
    };
};
export const fetchLaborDataSuccess = (result: AxiosResponse) => {
    return {
        type: CAT_LABOR_OLD_SUCCESS,
        payload: result,
    };
};
export const fetchLaborDataError = (error: AxiosError) => {
    return {
        type: CAT_LABOR_OLD_ERROR,
        error: error,
    };
};
export function GetAllCatLaborOld(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchLaborDataStart);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatLaborOld}
            `,
                {headers}
            );
            dispatch(fetchLaborDataSuccess(response));
        } catch (error) {
            dispatch(fetchLaborDataError(error as AxiosError));
        }
    };
}
