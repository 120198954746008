import {
    GET_CAT_GROUP_SUCCESS,
    GET_ITEMS,
    GET_ITEMS_ERROR,
    GET_FAMILY_CONCAT_LEVEL,
    GET_SUBFAMILY_SUCCESS,
    GET_BRAND_CONCAT_SUCCESS,
    GET_ARTICLE_SUCCESS,
    TOTAL_PAGES_ITEM,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {AxiosError} from 'axios';
import {IDATAFACE} from '@components/RequestNewCatalogLevel/interfacesTree';
export interface ICatGruop {
    loading: boolean;
    error: boolean;
    page: number;
    catGroup: IDATAFACE[];
}
const initialState: ICatGruop = {
    loading: false,
    page: 0,
    catGroup: [
        {
            id: 0,
            nameGroup: '',
            nameFamily: '',
            nameBrand: '',
            nameSubFamily: '',
            name: '',
            status: false,
            markId: 0,
            children: [],
            iconUrl: '',
        },
    ],
    error: false,
};

export const getCatGroups = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: IDATAFACE;
        page: number;
    }
) => {
    switch (action.type) {
        case GET_ITEMS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CAT_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                catGroup: action.payload,
            };
        case TOTAL_PAGES_ITEM:
            return {
                ...state,
                page: action.page,
            };
        case GET_FAMILY_CONCAT_LEVEL:
            return {
                ...state,
                catGroup: state.catGroup.map((group) =>
                    group.id === action.payload.id
                        ? {...group, children: action.payload.children}
                        : group
                ),
            };
        case GET_SUBFAMILY_SUCCESS:
            return {
                ...state,
                catGroup: state.catGroup.map((group) => ({
                    ...group,
                    children: group.children?.map((family) =>
                        family.id === action.payload.id
                            ? {...family, children: action.payload.children}
                            : family
                    ),
                })),
            };

        case GET_BRAND_CONCAT_SUCCESS:
            return {
                ...state,
                catGroup: state.catGroup.map((group) => ({
                    ...group,
                    children: group.children?.map((family) => ({
                        ...family,
                        children: family.children?.map((subFamily) =>
                            subFamily.id === action.payload.id
                                ? {...subFamily, children: action.payload.children}
                                : subFamily
                        ),
                    })),
                })),
            };

        case GET_ARTICLE_SUCCESS:
            return {
                ...state,
                catGroup: state.catGroup.map((group) => ({
                    ...group,
                    children: group.children?.map((family) => ({
                        ...family,
                        children: family.children?.map((subFamily) => ({
                            ...subFamily,
                            children: subFamily.children?.map((brand) =>
                                brand.id === action.payload.id
                                    ? {...brand, children: action.payload.children}
                                    : brand
                            ),
                        })),
                    })),
                })),
            };
        case GET_ITEMS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
