import {
    ADD_INFO_PRODUCT_QUOTED,
    ADD_INFO_CLIENT_QUOTED,
    ADD_DEADLINE_QUOTED,
    GET_BENEFICIARY,
    COMPLETE_STEP_1,
    COMPLETE_STEP_2,
    COMPLETE_STEP_3,
    COMPLETE_STEP_4,
    COMPLETE_STEP_5,
    HIDDEN_SEARCH,
    ADD_STEP_ONE2,
    ADD_STEP_ONE3,
    CHANGE_ACTUAL_PAGE,
    CANCEL_QUOTER,
    LOGOUT_CLIENT,
    SHOPPINGCARD,
    IMAGE_BASE_64,
    NOT_COMPLETE_STEP_5,
} from '@TypesQouter/Types';
import {GET_BENEFIST, GET_CHECK} from '@TypesQouter/TypesGeneral';
import {
    DataInputsDinamics,
    DeadLinesQuoter,
    LevelInfo,
    StadisiticProducts,
} from '@components/Quoter/inferfaces';
import {ClientsQuoter} from '@/components/Shopify/ListClient/redux/interfaces';
export interface interQuoter {
    product: LevelInfo | null;
    dinamics: DataInputsDinamics | null;
    stadistics: StadisiticProducts | null;
    client: ClientsQuoter | null;
    deadline: DeadLinesQuoter | null;
    coOwnerId: number[];
    benefistId: number[];
    images: string[];
    checkCoowner: boolean[];
    ActualImages: string[];
    imagesNone: string[];
    imagesBase: string[];
    actualPage: string;
    hiddenSearch: boolean;
    completeStep1: boolean;
    completeStep2: boolean;
    completeStep3: boolean;
    completeStep4: boolean;
    completeStep5: boolean;
    cardShooping: number;
}
const initialState: interQuoter = {
    product: null,
    dinamics: null,
    stadistics: null,
    client: null,
    deadline: null,
    coOwnerId: [],
    benefistId: [],
    images: [],
    checkCoowner: [],
    ActualImages: [],
    imagesNone: [],
    imagesBase: [],
    actualPage: '1-1',
    hiddenSearch: false,
    completeStep1: false,
    completeStep2: false,
    completeStep3: false,
    completeStep4: false,
    completeStep5: false,
    cardShooping: 0,
};
const infoQuoted = (
    state: interQuoter = initialState,
    actions: {
        type: string;
        images64: string[];
        checkedCoOwner: boolean[];
        productQuoted: LevelInfo;
        payload: DataInputsDinamics;
        stadisticStepOne: StadisiticProducts;
        client: ClientsQuoter;
        beneficiaryId: number[];
        value: number[];
        numberPage: string;
        deadline: DeadLinesQuoter;
        card: number;
    }
): interQuoter => {
    switch (actions.type) {
        case IMAGE_BASE_64:
            return {...state, imagesBase: actions.images64};
        case GET_BENEFIST:
            return {...state, benefistId: actions.value};
        case GET_BENEFICIARY:
            return {...state, coOwnerId: actions.beneficiaryId};
        case ADD_INFO_PRODUCT_QUOTED:
            return {...state, product: actions.productQuoted};
        case ADD_STEP_ONE2:
            return {
                ...state,
                dinamics: actions.payload,
            };
        case ADD_STEP_ONE3:
            return {
                ...state,
                stadistics: actions.stadisticStepOne,
            };
        case ADD_INFO_CLIENT_QUOTED:
            return {...state, client: actions.client};
        case COMPLETE_STEP_1:
            return {...state, completeStep1: true};
        case ADD_DEADLINE_QUOTED:
            return {...state, deadline: actions.deadline};
        case COMPLETE_STEP_2:
            return {...state, completeStep2: true};
        case COMPLETE_STEP_3:
            return {...state, completeStep3: true};
        case COMPLETE_STEP_4:
            return {...state, completeStep4: true};
        case COMPLETE_STEP_5:
            return {...state, completeStep5: true};
        case HIDDEN_SEARCH:
            return {...state, hiddenSearch: true};
        case CHANGE_ACTUAL_PAGE:
            return {...state, actualPage: actions.numberPage};
        case GET_CHECK:
            return {
                ...state,
                checkCoowner: actions.checkedCoOwner,
            };
        case LOGOUT_CLIENT:
            return initialState;
        case CANCEL_QUOTER:
            return {
                ...state,
                product: null,
                dinamics: null,
                stadistics: null,
                deadline: null,
                coOwnerId: [],
                benefistId: [],
                checkCoowner: [],
                imagesBase: [],
                actualPage: '1-1',
                hiddenSearch: false,
                completeStep1: false,
                completeStep2: false,
                completeStep3: false,
                completeStep4: false,
                completeStep5: false,
            };
        case SHOPPINGCARD:
            return {
                ...state,
                cardShooping: actions.card,
            };
        case NOT_COMPLETE_STEP_5:
            return {...state, completeStep5: false, actualPage: '5'};
        default:
            return state;
    }
};

export default infoQuoted;
