export const ACTIONS_SEARCH = {
    GetSteps: 'GET_STEPS',
    GetSearch: 'GET_SEARCH',
    GetItem: 'GET_ITEM',
    Selected: 'SELECTED',
    Level: 'LEVEL',
    GetRoute: 'GET_ROUTE',
    AddRoute: 'ADD_ROUTE',
    DeleteRoute: 'DELETE_ROUTE',
    AxiosSearch: 'AXIOS_SEARCH',
    ErrorSearch: 'ERROR_SEARCH',
    RestartSearch: 'RESTART_SEARCH',
};

export const RESET_ALL = 'RESET_ALL';
export const RESET_GLOBAL = 'RESET_GLOBAL';

export const ACTIONS_ITEMS = {
    GetGroup: 'GET_GROUP',
    OnlyGroup: 'ONLY_GROUP',
    GetFamily: 'GET_FAMILY',
    OnlyFamily: 'ONLY_FAMILY',
    GetSub: 'GET_SUB',
    OnlySub: 'ONLY_SUB',
    GetBrend: 'GET_BRAND',
    OnlyBrand: 'ONLY_BRAND',
    GetArticle: 'GET_ARTICLE',
    OnlyArticle: 'ONLY_ARTICLE',
    ErrorItems: 'ERROR_ITEMS',
    RestartItems: 'RESTART_ITEMS',
};

export const ID_CONTRACT = 'ID_CONTRACT';

export const SET_ACTUAL_PAGE = 'SET_ACTUAL_PAGE';
export const ACTUAL_IMAGES = 'ACTUAL_IMAGES';

export const GET_PRODUCTS_QUOTED = 'GET_PRODUCTS_QUOTED';
export const GET_PRODUCTS_QUOTED_SUCCESS = 'GET_PRODUCTS_QUOTED_SUCCESS';
export const GET_PRODUCTS_QUOTED_ERROR = 'GET_PRODUCTS_QUOTED_ERROR';
export const GET_RESET_PRODUCTS_QUOTED = 'GET_RESET_PRODUCTS_QUOTED';
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';

export const SET_INFO_PRODUCT_QUOTED = 'SET_INFO_PRODUCT_QUOTED';
export const SET_INFO_CLIENT_QUOTED = 'SET_INFO_CLIENT_QUOTED';
export const ADD_INFO_PRODUCT_QUOTED = 'SET_INFO_PRODUCT_QUOTED';
export const ADD_INFO_CLIENT_QUOTED = 'SET_INFO_CLIENT_QUOTED';
export const ADD_DEADLINE_QUOTED = 'ADD_DEADLINE_QUOTED';

export const GET_PRODUCT_QUOTED = 'GET_PRODUCT_QUOTED';
export const GET_PRODUCT_QUOTED_SUCCESS = 'GET_PRODUCT_QUOTED_SUCCESS';
export const GET_PRODUCT_QUOTED_ERROR = 'GET_PRODUCT_QUOTED_ERROR';

export const GET_GROUP = 'GET_GROUP';
export const GET_GROUP_SUCESS = 'GET_GROUP_SUCESS';
export const GET_GROUP_ERROR = 'GET_GROUP_ERROR';

export const GET_FAMILY = 'GET_FAMILY';
export const GET_FAMILY_SUCESS = 'GET_FAMILY_SUCESS';
export const GET_FAMILY_ERROR = 'GET_FAMILY_ERORR';

export const GET_SUBFAMILY = 'GET_SUBFAMILY';
export const GET_SUBFAMILY_SUCESS = 'GET_SUBFAMILY_SUCESS';
export const GET_SUBFAMILY_ERROR = 'GET_SUBFAMILY_ERROR';

export const CHANGE_ACTUAL_PAGE = 'SET_ACTUAL_PAGE';

export const COMPLETE_STEP_1 = 'COMPLETE_STEP_1';
export const COMPLETE_STEP_2 = 'COMPLETE_STEP_2';
export const COMPLETE_STEP_3 = 'COMPLETE_STEP_3';
export const COMPLETE_STEP_4 = 'COMPLETE_STEP_4';
export const COMPLETE_STEP_5 = 'COMPLETE_STEP_5';

export const HIDDEN_SEARCH = 'HIDDEN_SEARCH';

export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_SUCESS = 'GET_CLIENT_SUCESS';
export const GET_CLIENT_ERROR = 'GET_CLIENT_ERROR';
export const ACTUAL_PAGE_CUSTOMER = 'ACTUAL_PAGE_CUSTOMER';
export const CURRENT_TABLE = 'CURRENT_TABLE';
export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH';
export const RESET_SEARCH_CLIENT = 'RESET_SEARCH_CLIENT';
export const CLIENT_SORT = 'CLIENT_SORT';

export const CANCEL_QUOTER = 'CANCEL_QUOTER';
export const CANCEL_QUOTER_REQUEST = 'CANCEL_QUOTER_REQUEST';

export const STATESELECT = 'STATESELECT';

export const ADD_STEP_ONE2 = 'ADD_STEP_ONE2';
export const ADD_STEP_ONE3 = 'ADD_STEP_ONE3';
export const PROPERTIES_ARTICLES = 'PROPERTIES_ARTICLES';
export const PROPERTIES_ARTICLES_SUCCESS = 'PROPERTIES_ARTICLES_SUCCESS';
export const PROPERTIES_ARTICLES_ERROR = 'PROPERTIES_ARTICLES_ERROR';

export const PROPERTIES_ARTICLES_REQUEST = 'PROPERTIES_ARTICLES_REQUEST';
export const PROPERTIES_ARTICLES_REQUEST_SUCCESS = 'PROPERTIES_ARTICLES_REQUEST_SUCCESS';
export const PROPERTIES_ARTICLES_REQUEST_ERROR = 'PROPERTIES_ARTICLES_REQUEST_ERROR';

export const PROPERTIES_ARTICLES_ID = 'PROPERTIES_ARTICLES_ID';
export const PROPERTIES_ARTICLES_REQUEST_ID = 'PROPERTIES_ARTICLES_REQUEST_ID';

export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCESS';
export const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR';

export const GET_BENEFICIARY = 'GET_BENEFICIARY';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';
export const SHOPPINGCARD = 'SHOPPINGCARD';

export const IMAGE_BASE_64 = 'IMAGE_BASE_64';
export const GET_CHECK = 'GET_CHECK';

export const CHANGE_ACTUAL_STEP = 'CHANGE_ACTUAL_STEP';

export const LEVELS = {
    ERRORPETITION: 'ERRORPETITION',
    LEVELGROUP: 'LEVELGROUP',
    LEVELFAMILY: 'LEVELFAMILY',
    LEVELSUBFAMILY: 'LEVELSUBFAMILY',
    LEVELBRAND: 'LEVELBRAND',
    LEVELARTICLE: 'LEVELARTICLE',
    LOADING: 'LOADING',
};

export const LEVELS_REQUEST = {
    ERRORPETITION: 'ERRORPETITION',
    LEVELGROUP: 'LEVELGROUP',
    LEVELFAMILY: 'LEVELFAMILY',
    LEVELSUBFAMILY: 'LEVELSUBFAMILY',
    LEVELBRAND: 'LEVELBRAND',
    LEVELARTICLE: 'LEVELARTICLE',
    LOADING: 'LOADING',
};

export const LEVELS_REQUEST_SOL = {
    ERRORPETITION: 'ERRORPETITION',
    LEVELGROUP: 'LEVELGROUP',
    LEVELFAMILY: 'LEVELFAMILY',
    LEVELSUBFAMILY: 'LEVELSUBFAMILY',
    LEVELBRAND: 'LEVELBRAND',
    LEVELARTICLE: 'LEVELARTICLE',
    LOADING: 'LOADING',
};

export const RESET_LEVELS = 'RESET_LEVELS';

export const NOT_COMPLETE_STEP_5 = 'NOT_COMPLETE_STEP_5';

export const RESET_PASS_QUOTER = 'RESET_PASS_QUOTER';
export const RESET_ARTCILE_DINAMICS = 'RESET_ARTCILE_DINAMICS';
export const RESET_ARTCILE_DINAMICS_REQUEST = 'RESET_ARTCILE_DINAMICS_REQUEST';

export const GET_PROMOTIONS_TOTALITEMS = 'GET_PROMOTIONS_TOTALITEMS';
export const GET_PROMOTIONS_TOTALITEMS_SUCCESS = 'GET_PROMOTIONS_TOTALITEMS_SUCESS';
export const GET_PROMOTIONS_TOTALITEMS_ERROR = 'GET_PROMOTIONS_TOTALITEMS_ERROR';

export const ADD_PROMOTIONS_FILTER = 'ADD_PROMOTIONS_FILTER';

export const RESET_PROMOTION_TABLE = 'RESET_PROMOTION_TABLE';

export const SEARCH_VALUATION_STEP = {
    SEARCH: 'SEARCH',
    ID: 'ID',
    FILTERS: 'FILTERS',
    DINAMICS: 'DINAMICS',
    STADISTICS: 'STADISTICS',
};

export const QUOTER_RESET = 'QUOTER_RESET';

export const SEARCH = {
    INITIAL: 'SEARCH_INITIAL',
    SUCCESS: 'SEARCH_SUCCESS',
    ERROR: 'SEARCH_ERROR',
    RESET: 'SEARCH_RESET',
};

export const STADISTICS = {
    INITIAL: 'STADISTICS_INITIAL',
    SUCCESS: 'STADISTICS_SUCCESS',
    ERROR: 'STADISTICS_ERROR',
    CATSTATES: 'CATSTATES',
    INITDETAILS: 'INITDETAILS',
    DETAILS: 'DETAILS',
    ERRORDETAILS: 'ERRORDETAILS',
    INITHISTORY: 'INITHISTORY',
    HISTORY: 'HISTORY',
    ERRORHISTORY: 'HISTORY',
};

export const STADISTICS_GLOBAL = {
    INITDETAILS: 'INITDETAILS_COMPANY',
    DETAILS: 'DETAILS_COMPANY',
    ERRORDETAILS: 'ERRORDETAILS_COMPANY',
    RESET: 'RESET',
};
