export const MYPLAN_TEXT = {
    TITLE: 'Mi plan y adicionales',
    COIN_TYPE: 'MXN',
    SIGN_CHANGE: '$',
};

export const MYPLAN_PROVITIONAL = {
    MY_PLAN: 'Contenido para Mi Plan',
    SALES_CHANNELS: 'Contenido para Canales de Venta',
    ADITIONAL: 'Contenido para Adicionales',
    TEMP_TEXT: 'Contenido para Líneas de Negocio',
};

export const MYPLAN_TABS = ['Mi Plan', 'Líneas de Negocio', 'Canales de Venta', 'Adicionales'];

export const BUSINESS_LINES = {
    PAWNSHOP: 'Casa de empeño',
    MARKETING: 'Comercializadora',
    DELL_BUSINESS_LINE: 'Eliminar línea de negocio',
    DOT: '• ',
};

export const colorBusiness = (type: string) => {
    let color = '';
    if (type.includes(BUSINESS_LINES.PAWNSHOP)) color = '#5A5AFF';
    if (type.includes(BUSINESS_LINES.MARKETING)) color = '#00B4DE';
    return color;
};

export const SALES_CHANNEL = {
    PROMPT: 'Los canales de venta aplican para todas las líneas de negocio.',
    TITLE: 'Canales de venta',
    ADD_CHANNEL: 'Agregar canal de venta',
    EDIT: 'Editar',
};

export const SUMMARY_PLAN_TEXT = {
    SUMMARY: 'Resumen de tu plan',
    USER_TOTAL: 'Total por usuarios',
    BY_USER: 'Por usuario',
    TOTAL: 'Total',
    MANAGE: 'Gestionar',
};
