import {AppDispatch} from '@/config/store';
import {NavigateFunction} from 'react-router-dom';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {ROLE_ID} from '@Roles/CreateRoles/Redux/Types';
import {typeActionRole} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';

export const currentRolId = (data: number) => ({
    type: ROLE_ID.CURRENT_ID,
    idRole: data,
});
export const roleByIdStart = () => ({type: ROLE_ID.START});
export const roleByIdSuccess = (result: {data: AxiosResponse}) => ({
    type: ROLE_ID.SUCCESS,
    roleById: result.data,
});
export const roleByIdError = (error: AxiosError) => ({
    type: ROLE_ID.ERROR,
    error: error,
});
export const roleById = (token: string, filter: {roleId: number}, navigate: NavigateFunction) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filter});
    return async (dispatch: AppDispatch) => {
        dispatch(roleByIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllRoleInformation}${queryString}`,
                {headers}
            );
            dispatch(roleByIdSuccess(response.data));
            dispatch(typeActionRole('Editar'));
            navigate('/Roles/UpdateRole');
        } catch (error) {
            dispatch(roleByIdError(error as AxiosError));
        }
    };
};
