import React from 'react';
import {OptionList} from '@/components/Catalogue/NewFeature/OptionList';
import {NewFeatureFieldsProps} from '@components/Catalogue/interfaces';
import {NewFeatureInputName} from '@/components/Catalogue/NewFeature/NewFeatureInputName';
import {NewFeatureCheckboxs} from '@components/Catalogue/NewFeature/NewFeatureCheckboxs';
import {NewFeatureSelect} from '@components/Catalogue/NewFeature/NewFeatureSelect';

export const NewFeatureFields = ({
    register,
    errors,
    control,
    articleTypes,
    formData,
    fields,
    remove,
    handleAddOption,
}: NewFeatureFieldsProps) => {
    return (
        <>
            <NewFeatureInputName register={register} errors={errors} />
            <NewFeatureSelect control={control} articleTypes={articleTypes} errors={errors} />
            {formData.catArticleType.value === 'Selección' && (
                <OptionList
                    fields={fields}
                    register={register}
                    remove={remove}
                    errors={errors}
                    handleAddOption={handleAddOption}
                />
            )}
            <NewFeatureCheckboxs control={control} />
        </>
    );
};
