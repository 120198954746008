import axios, {AxiosResponse} from 'axios';
import {
    GET_INFO_BRANCH,
    GET_INFO_BRANCH_ERROR,
    GET_INFO_BRANCH_FINISH,
    GET_INFO_BRANCH_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';

export const fetchGetInformationBranchStart = () => {
    return {
        type: GET_INFO_BRANCH,
    };
};
export const fetchGetInformationBranchSuccess = (result: AxiosResponse) => {
    return {
        type: GET_INFO_BRANCH_SUCCESS,
        payload: result,
    };
};
export const fetchGetInformationBranchError = () => {
    return {
        type: GET_INFO_BRANCH_ERROR,
    };
};
export const fetchGetInformationBranchFinish = () => {
    return {
        type: GET_INFO_BRANCH_FINISH,
    };
};

export function GetInformationBranch(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetInformationBranchStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInformationBranch}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetInformationBranchSuccess(response));
        } catch (error) {
            dispatch(fetchGetInformationBranchError());
        } finally {
            dispatch(fetchGetInformationBranchFinish());
        }
    };
}
