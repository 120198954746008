import axios, {AxiosResponse} from 'axios';
import {
    POST_EMAIL_CONTACT_START,
    POST_EMAIL_CONTACT_SUCCESS,
    POST_EMAIL_CONTACT_ERROR,
} from '@MyCompany/Redux/Types/types';
import {URL} from '@config/constants/index';

import {DeleteEmail} from '@MyCompany/Interface';
import {AppDispatch} from '@/config/store';

export const PostEmailContactStart = () => {
    return {
        type: POST_EMAIL_CONTACT_START,
    };
};
export const PostEmailContactSuccess = (data: AxiosResponse) => {
    return {
        type: POST_EMAIL_CONTACT_SUCCESS,
        payload: data,
    };
};

export const PostEmailContactError = () => {
    return {
        type: POST_EMAIL_CONTACT_ERROR,
    };
};

export const PostEmail = (token: string, data: DeleteEmail) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(PostEmailContactStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostEmail}`,
                data,
                {headers}
            );
            dispatch(PostEmailContactSuccess(response));
        } catch (error) {
            dispatch(PostEmailContactError());
        } finally {
            window.location.href = '#/MyCompany/AplicationContact';
        }
    };
};
