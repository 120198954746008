import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@/components/Quoter/stylesConstants';
import {ITabsContent} from '@Quoter/inferfaces';
import {History} from '@Quoter/Steps/StepOne/History';
import {Tabs} from '@Quoter/Steps/StepOne/TabsComponent';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {DetailsStadistic, DetailsStadisticGlobal} from '@Quoter/Redux/Actions/StatusStepOne';
import {APPRASIALTEMPORAL, HISTORY, LEVELS, WITHOUTPRICE} from '@Quoter/constants';
import {withoutPrices} from '@/hooks/currentFormatUtils';

export const TabsStadistics = (props: ITabsContent) => {
    const {token} = props;
    const [history, setHistory] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const {productSearch, stadistics} = useSelector((state: RootState) => state.saveEndeavor);
    const infoStadistics = useSelector((state: RootState) => state.getInfoContracts.data);
    const stateConservationIdEdit = infoStadistics.carPledge?.[0].stateConservationId;
    const details = useSelector((state: RootState) => state.statusQuoter.details);
    const propertiesArticle = useSelector((state: RootState) => state.propertiesArticle);

    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;

    const conservationState = () => {
        if (stadistics.state?.value) {
            const STATE: {[key: number]: string} = {
                1: 'Bueno',
                2: 'Regular',
                3: 'Malo',
            };
            return STATE[stadistics.state?.value];
        }
        if (stateConservationIdEdit) {
            const STATE: {[key: number]: string} = {
                1: 'Bueno',
                2: 'Regular',
                3: 'Malo',
            };
            return STATE[stateConservationIdEdit];
        }
    };

    useEffect(() => {
        if (productSearch.levelsIds[LEVELS.ARTICLE] && stadistics.state?.value && companyId) {
            dispatch(
                DetailsStadistic(token, {
                    companyId: companyId,
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stadistics.state?.value,
                })
            );
            dispatch(
                DetailsStadisticGlobal(token, {
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stadistics.state?.value,
                })
            );
        }
    }, [productSearch.levelsIds[LEVELS.ARTICLE], stadistics.state?.value, propertiesArticle]);
    useEffect(() => {
        if (productSearch.levelsIds[LEVELS.ARTICLE] && companyId) {
            dispatch(
                DetailsStadistic(token, {
                    companyId: companyId,
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stateConservationIdEdit,
                })
            );
            dispatch(
                DetailsStadisticGlobal(token, {
                    articleId: productSearch.levelsIds[LEVELS.ARTICLE],
                    stateConservationId: stateConservationIdEdit,
                })
            );
        }
    }, [propertiesArticle]);
    return (
        <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
            <Tabs setHistory={setHistory} />
            {details ? (
                <ContainerFlex
                    Display="grid"
                    GridColumns="repeat(4, 1fr)"
                    Height="5.625rem"
                    ColumnGap="16px"
                >
                    <ContainerFlex {...style.miniContent} backG="#FAFAFA" Padding="8px">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE1}</Text>
                        <ContainerFlex {...style.tabsMinicontnt}>
                            {conservationState() ? (
                                <>
                                    <Text {...style.textValues}>
                                        {withoutPrices(details.regularValue, WITHOUTPRICE.DOLLAR)}
                                    </Text>
                                    <Text {...style.subtitles}>{conservationState()}</Text>
                                </>
                            ) : (
                                <ContainerFlex
                                    {...style.titleMinicontent}
                                    Justify="start"
                                    FontWeight="400"
                                >
                                    {HISTORY.WITHOUTINTORMATION}
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE2}</Text>
                        <ContainerFlex Height="60px">
                            <ContainerFlex {...style.tabsMinicontnt} MarginR="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(details.minimumLoan, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE2}</Text>
                            </ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginL="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(details.maximumLoan, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE3}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE3}</Text>
                        <ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginR="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(details.minimumSale, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE2}</Text>
                            </ContainerFlex>
                            <ContainerFlex {...style.tabsMinicontnt} MarginL="0.25rem">
                                <Text {...style.textValues}>
                                    {withoutPrices(details.maximumSale, WITHOUTPRICE.DOLLAR)}
                                </Text>
                                <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE3}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex {...style.miniContent} Padding="8px" backG="#FAFAFA">
                        <Text {...style.titleMinicontent}>{APPRASIALTEMPORAL.TITLE4}</Text>
                        <ContainerFlex {...style.tabsMinicontnt}>
                            <Text {...style.textValues}>
                                {withoutPrices(details.percentage, WITHOUTPRICE.AVG)}
                            </Text>
                            <Text {...style.subtitles}>{APPRASIALTEMPORAL.SUBTITLE4}</Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            ) : (
                <ContainerFlex {...style.titleMinicontent} FontWeight="400" Height="5.625rem">
                    {HISTORY.WITHOUTINTORMATION}
                </ContainerFlex>
            )}
            <History history={history} setHistory={setHistory} token={token} />
        </ContainerFlex>
    );
};
