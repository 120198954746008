import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {
    SearchLevelsAndBranch,
    ClearLevelsSearch,
} from '@/components/LocationsBranch/Redux/Action/SearchLevelsAndBranch';
import {setCurrentSelectedLevelId} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import useLoadLevels from '@/hooks/useLoadLevels';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import pointingArrow from '@components/Branches/Images/AppPointingArrow.svg';
import Warning from '@images/warning-red.svg';
import * as style from '@General/Atoms/SearchBoxResultList/stylesConstants';
import {INHERIT_FROM, INHERIT_TYPE} from '@components/LocationsBranch/constants';
import {IInheritAttributesProps} from '@components/LocationsBranch/interfaces';
import {InheritActionButtons} from '@components/LocationsBranch/InheritActionButtons';
import {AttributeGroup} from '@/components/LocationsBranch/AttributeGroup';
import {SearchTextbox} from '@General/Atoms/SearchBoxResultList/SearchTextbox';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

export const InheritAttributes: React.FC<IInheritAttributesProps> = ({type, setShowModal}) => {
    const dispatch = useDispatch();

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    const currentBranch = useSelector((state: RootState) => state.getCompanyLevels.data.branchName);

    const {
        groupsFilling,
        levelGroups,
        currentSelection,
        handleGroupSelect,
        getGroupOptions,
        getSelectedValue,
        handleSearchSelect,
        handleResetGroup,
        errorMessage,
    } = useLoadLevels(type, companyId, token);

    const searchResults = useSelector((state: RootState) => state.getSearchLevelsAndBranch.data);

    const handleSearch = (searchText: string) => {
        dispatch(SearchLevelsAndBranch(companyId, searchText, token));
    };

    const handleReset = () => {
        dispatch(ClearLevelsSearch());
    };

    const handleOnSave = () => {
        const selectionBreadcrum: LevelSelection = {title: '', ids: []};
        levelGroups.forEach((group) => {
            if (group.selectedLevelId !== 0) {
                const selectedLevel = group.levels?.find(
                    (level) => level.levelId === group.selectedLevelId
                );
                if (selectedLevel) {
                    selectionBreadcrum.title = selectionBreadcrum.title
                        ? `${selectionBreadcrum.title} > ${selectedLevel.level}`
                        : selectedLevel.level;
                    selectionBreadcrum.ids = [...selectionBreadcrum.ids, group.selectedLevelId];
                }
            }
        });
        const selectedLevelId = levelGroups.reduce((highest, group) => {
            if (group.selectedLevelId !== 0) {
                return group.selectedLevelId;
            }
            return highest;
        }, 0);
        if (selectedLevelId) {
            const groupName = levelGroups
                .find((levels) => levels.selectedLevelId === selectedLevelId)
                ?.levels?.find((level) => level.levelId === selectedLevelId)?.level;
            dispatch(
                setCurrentSelectedLevelId(type, {
                    levelId: selectedLevelId,
                    levelName: groupName as string,
                    breadcrum: selectionBreadcrum,
                })
            );
        }
        setShowModal(false);
    };

    const handleOnCancel = () => {
        setShowModal(false);
        handleResetGroup();
    };

    const renderItem = (item: LevelSelection): JSX.Element => {
        return <>{item.title}</>;
    };

    const inheritTitle = (type: string) => {
        switch (type) {
            case INHERIT_TYPE.USER:
                return INHERIT_FROM.TYPE_USERS;
            case INHERIT_TYPE.LOCATION:
                return INHERIT_FROM.TYPE_INVENTORY;
            default:
                return INHERIT_FROM.TITLE;
        }
    };

    useEffect(() => {
        if (currentSelection) {
            handleSearchSelect(currentSelection);
        }
    }, []);

    return (
        <ContainerFlex Padding="24px" FlexDir="column" Align="center" Gap="24px">
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="24px">
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                    {inheritTitle(type)}
                </Text>
                {type !== INHERIT_TYPE.SCHEDULE && (
                    <>
                        <ContainerFlex
                            FlexDir="column"
                            Align="flex-start"
                            Gap="4px"
                            Height="3.75rem"
                        >
                            <Text Color="#6D6E71" FontSize="0.75rem" FontWeight="500">
                                {INHERIT_FROM.FROM}
                            </Text>
                            <ContainerFlex
                                Padding="8px 0px"
                                Justify="start"
                                Align="center"
                                Gap="8px"
                                Height="40px"
                            >
                                <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                                    {currentBranch}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <Image
                            src={pointingArrow}
                            alt="arrow"
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                        />
                    </>
                )}
                <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px">
                    {type !== INHERIT_TYPE.SCHEDULE && (
                        <Text Color="#6D6E71" FontSize="0.75rem" FontWeight="500">
                            {INHERIT_FROM.TO}
                        </Text>
                    )}
                    <ContainerFlex
                        Padding="16px"
                        FlexDir="column"
                        Justify="flex-end"
                        Align="flex-start"
                        Self="stretch"
                        Radius="16px"
                        Border="1px solid #F4F5F5"
                        backG="#FAFAFA"
                    >
                        <SearchTextbox
                            placeholder={INHERIT_FROM.SEARCH_PLACEHOLDER}
                            searchAction={handleSearch}
                            resetAction={handleReset}
                            results={searchResults}
                            renderItem={renderItem}
                            onSelect={handleSearchSelect}
                            noDataContent={
                                <ContainerFlex
                                    Padding="0.75rem 0.5rem;"
                                    Align="center"
                                    Justify="start"
                                    Self="stretch"
                                >
                                    <Image src={Warning} {...style.warnIconContent} />
                                    <Text Color="#414042" FontWeight="500">
                                        {INHERIT_FROM.NO_RESULTS}
                                    </Text>
                                </ContainerFlex>
                            }
                        />
                        <ContainerFlex Align="flex-start" Gap="8px" MarginTop="16px">
                            {groupsFilling ? (
                                <ContainerFlex Justify="center">
                                    <LoadingAtaskate />
                                </ContainerFlex>
                            ) : (
                                levelGroups.map((group, index) => (
                                    <AttributeGroup
                                        key={index}
                                        title={`Grupo ${group.group}`}
                                        options={getGroupOptions(group.group)}
                                        onSelect={(selectedLevelId) =>
                                            handleGroupSelect(group.group, selectedLevelId)
                                        }
                                        selectedValue={getSelectedValue(group.group)}
                                        loading={group.fetching}
                                    />
                                ))
                            )}
                            {errorMessage && (
                                <Text Color="#FF6357" FontSize="0.75px">
                                    {errorMessage}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <InheritActionButtons onCancel={() => handleOnCancel()} onSave={() => handleOnSave()} />
        </ContainerFlex>
    );
};
