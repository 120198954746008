import {
    POST_ADD_ROL,
    POST_ADD_ROL_SUCCESS,
    POST_ADD_ROL_ERROR,
    CHANGE_SUCCESS_ADD_ROL,
    POST_ADD_ROL_FINISH,
} from '@Roles/AddRoles/Redux/Types';
import {TypeResponseData} from '@Roles/AddRoles/interfaces';

const initialState = {
    loading: false,
    error: false,
    success: false,
    data: [],
};

const postAddRol = (state = initialState, action: TypeResponseData) => {
    switch (action.type) {
        case POST_ADD_ROL:
            return {...state, loading: true};
        case POST_ADD_ROL_SUCCESS:
            return {...state, success: action.payload.data.succeeded};
        case POST_ADD_ROL_ERROR:
            return {...state, error: true};
        case POST_ADD_ROL_FINISH:
            return {...state, loading: false};
        case CHANGE_SUCCESS_ADD_ROL:
            return {...state, success: false};
        default:
            return state;
    }
};

export default postAddRol;
