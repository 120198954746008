import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START,
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS,
    GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR,
} from '@components/Catalogue/Redux/Types/Types';

export const getAllCatArticleTypeDetailsStart = () => {
    return {
        type: GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START,
    };
};

export const getAllCatArticleTypeDetailsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS,
        payload: result,
    };
};

export const getAllCatArticleTypeDetailsError = (error: AxiosError) => {
    return {
        type: GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR,
        error: error,
    };
};

export const getAllCatArticleType = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getAllCatArticleTypeDetailsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatArticleTypeDetails}`,
                {headers}
            );
            dispatch(getAllCatArticleTypeDetailsSuccess(response));
        } catch (error) {
            dispatch(getAllCatArticleTypeDetailsError(error as AxiosError));
        }
    };
};
