import styled from '@emotion/styled';
import {IPropsSpanCard, IPropsDivButton} from '@Auction/interfacesStyles';
export const ContainerAuction = styled.div`
    width: 90%;
    margin: auto;
    padding: 0.25rem;
`;
export const ArrowAuction = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;

    span {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0px;
        color: var(--gray-2);
    }

    a {
        color: var(--gray-2);
    }
    span:last-child {
        font-size: 18px;
        letter-spacing: 0px;
        color: var(--black);
        padding-bottom: 0.2rem;
    }
    i {
        color: var(--gray-2);
        font-size: 20px;
    }
`;
export const SubContainer = styled.div`
    display: flex;
    padding: 2rem;
`;
export const Card = styled.div`
    width: 50%;
    padding-top: 0.5rem;
    margin-right: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none !important;
    box-shadow: 0px 1px 3px #00000033;
    border-radius: 0.25rem;

    span:first-child {
        width: 100%;
        text-align: center;
        font: normal normal normal 22px/25px Work Sans;
        padding-top: 0.5rem !important;
        padding-bottom: 2rem;
    }

    table {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }
`;
export const InfoCard = styled.div`
    display: flex;
    flex-wrap: wrap !important;
    padding-top: 1.5rem;
`;
export const SpanCard = styled.span<IPropsSpanCard>`
    width: ${(props) => (props.width ? '100%' : '50%')} !important;
    padding-left: ${(props) => props.pl && '3rem'};
    padding-top: ${(props) => props.pt && '0.5rem'};
    padding-top: ${(props) => props.ptt && '3rem'};
    padding-bottom: ${(props) => props.pb && '1.5rem'};
`;
export const SubContainerSecond = styled.div`
    width: 50%;
    margin-left: 1rem;
    padding-top: 0.5rem;

    span:first-child {
        width: 100%;
        padding-left: 1.5rem;
    }

    form {
        margin-top: 1rem;
    }
    table {
        width: 92%;
        margin: 0 auto;
        margin-top: 1.5rem;
    }
`;
export const DivFormAuction = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1.5rem;
`;
export const ColumnsAuction = styled.div`
    display: flex;
    flex-direction: column;
    span {
        padding-left: 0 !important;
        padding-top: 0.25rem;
        height: 32.5px;
    }
    div {
        width: 100%;
        padding: 0.25rem 0;
    }

    input,
    select {
        border-radius: 5px;
        border: 1px solid var(--gray-3);
        padding-left: 0.25rem;
    }
`;
export const TextArea = styled.div`
    padding: 0 1.5rem;
    margin-top: 1.5rem;
    width: 95%;
    margin: auto;
    textarea {
        height: 25px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--gray-3);
        padding-left: 0.25rem;
    }
`;
export const DivButton = styled.div<IPropsDivButton>`
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    justify-content: flex-end;
    height: 40px;

    button {
        padding: 0.5rem 0;
        width: 30%;
        text-transform: uppercase;
        height: 100%;
        border-radius: 5px;
        align-items: center;
        background-color: white !important;
        cursor: ${(props) => (props.active ? 'not-allowed' : 'pointer')};
    }
`;
