export const contentList = {
    Cursor: 'pointer',
    Height: '2.5rem',
    Padding: '0.75rem 0.5rem',
    Bb: '1px solid #E8E9EA',
};
export const wordSearch = {
    Cursor: 'pointer',
    FontSize: '0.875rem',
    Color: '#414042',
    Justify: 'start',
    Width: '100%',
};
export const warnIconContent = {
    Radius: '1.5rem',
    alt: 'icon-warning',
    Cursor: 'pointer',
    Margin: '0 0.5rem 0 0',
};
export const resolveNoDataContent = (noDataContent: React.ReactNode | (() => React.ReactNode)) =>
    typeof noDataContent === 'function' ? noDataContent() : noDataContent;
