export const GET_SUCURSAL = 'GET_SUCURSAL';
export const GET_SUCURSAL_SUCCESS = 'GET_SUCURSAL_SUCCESS';
export const GET_SUCURSAL_ERROR = 'GET_SUCURSAL_ERROR';

export const GET_PAWN = 'GET_PAWN';
export const GET_PAWN_SUCCESS = 'GET_PAWN_SUCCESS';
export const GET_PAWN_ERROR = 'GET_PAWN_ERROR';

export const POST_PAWN = 'POST_PAWN';
export const POST_PAWN_SUCCESS = 'POST_PAWN_SUCCESS';
export const POST_PAWN_ERROR = 'POST_PAWN_ERROR';

export const PAWN_ID = 'PAWN_ID';

export const DATA_SEARCH = 'DATA_SEARCH';
