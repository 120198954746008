import {
    PUT_DATA_CONTACT_START,
    PUT_DATA_CONTACT_SUCCESS,
    PUT_DATA_CONTACT_ERROR,
} from '@MyCompany/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

const PutDataContact = (state = initialState, action: {type: any; payload: {}}) => {
    switch (action.type) {
        case PUT_DATA_CONTACT_START:
            return {...state, loading: true};

        case PUT_DATA_CONTACT_SUCCESS:
            return {...state, loading: false, data: action.payload};

        case PUT_DATA_CONTACT_ERROR:
            return {...state, loading: false, error: true};

        default:
            return state;
    }
};

export default PutDataContact;
