import React from 'react';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@General/Atoms/Checkbox/Checkbox';
import {InputStyles} from '@components/CreateUsers/Styles';
import {IPropsEnterPassword} from '@components/CreateUsers/Interface';

export const EnterPassword = ({
    register,
    errorTextPassword,
    errorTextRePassword,
    trigger,
}: IPropsEnterPassword) => {
    return (
        <>
            <ContainerFlex MarginTop="24px" Height="auto" Align="start">
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                        Contaseña
                    </Text>
                    <Input
                        Cursor="text"
                        maxLength={160}
                        placeholder="Contraseña"
                        {...InputStyles}
                        {...register('password')}
                        error={errorTextPassword}
                        onKeyUp={() => trigger('password')}
                    />

                    {errorTextPassword && (
                        <Text
                            FontSize="0.625rem"
                            FontWeight="normal"
                            Color="#ef4f55"
                            wSpace=""
                            MarginTop="4px"
                        >
                            {errorTextPassword}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start" AlignContent="end" FlexWrap="wrap">
                    <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                        Repetir Contaseña
                    </Text>
                    <Input
                        Cursor="text"
                        maxLength={160}
                        error={errorTextRePassword}
                        placeholder="Repetir contraseña"
                        {...InputStyles}
                        {...register('repeatPassword')}
                        onKeyUp={() => trigger('repeatPassword')}
                    />
                    {errorTextRePassword && (
                        <Text
                            FontSize="0.625rem"
                            FontWeight="normal"
                            Color="#ef4f55"
                            wSpace=""
                            MarginTop="4px"
                        >
                            {errorTextRePassword}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Justify="start" MarginTop="24px">
                <Checkbox {...register('startChangePassword')} />
                <Text MarginLeft="4px" FontSize="0.875rem" Color="#414042" FontWeight="normal">
                    Pedir al usuario cambiar su contraseña cuando inicie sesión
                </Text>
            </ContainerFlex>
        </>
    );
};
