import axios from 'axios';

import {URL} from '@config/constants/index';
import {LEVELS_DEADLINES} from '@ReduxDeadlinesindex/types/DeadlineTypes';
import {Dispatch} from 'redux';

export const LevelError = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_ERROR,
        payload: value,
    };
};

export const GroupSuccess = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_GROUP,
        payload: value,
    };
};
export const GroupLevel = (token: string, BrachId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GroupDeadlines.replace(
                    '{BrachId}',
                    BrachId
                )}`,
                {headers}
            );
            dispatch(GroupSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const FamilySuccess = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_FAMILY,
        payload: value,
    };
};
export const FamilyLevel = (token: string, BrachId: string, GroupId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.FamilyDeadlines.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{GroupId}', GroupId)}`,
                {headers}
            );
            dispatch(FamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const SubfamilySuccess = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_SUBFAMILY,
        payload: value,
    };
};

export const SubfamilyLevel = (token: string, BrachId: string, FamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SubfamilyDeadlines.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{FamilyId}', FamilyId)}`,
                {headers}
            );
            dispatch(SubfamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};
export const BrandSuccess = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_BRAND,
        payload: value,
    };
};
export const BrandLevel = (token: string, BrachId: string, SubFamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.BrandDeadlines.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{SubFamilyId}', SubFamilyId)}`,
                {headers}
            );
            dispatch(BrandSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const ArticleSuccess = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_ARTICLES,
        payload: value,
    };
};
export const ArticleLevel = (token: string, BrachId: string, BrandId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ItemsDeadline.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{BrandId}', BrandId)}`,
                {headers}
            );
            dispatch(ArticleSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};
