import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {REMOVE_ROLE} from '@Roles/RemoveRole/constants';
import * as style from '@components/CreateUsers/Styles';
import {IRoleRemove} from '@Roles/RemoveRole/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {removeRoles} from '@Roles/RemoveRole/Redux/Action/RemoveRole';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {delSecction, restartSecction} from '@Roles/RemoveRole/styleRemove';

export const RemoveRole = ({showModalRole, setShowModalRole, selectedUser}: IRoleRemove) => {
    const dispatch = useDispatch();
    const {idRole} = useSelector((state: RootState) => state.RoleById);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const delRol = () => {
        dispatch(removeRoles(token, {roleId: idRole, userId: selectedUser}, setShowModalRole));
    };

    return (
        <Modal
            modalState={showModalRole}
            changeModalState={() => {}}
            titleModalState={false}
            {...style.modalContect}
            HeightDialog="13.063rem"
            MaxH="auto"
            Width="41.313rem"
        >
            <ContainerFlex Gap="1.5rem" Padding="1.5rem" FlexDir="column">
                <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {REMOVE_ROLE.ROLE_TITLE_REMOVE}
                    </Text>
                    <Text Color="#2A2C2F" wSpace="normal">
                        {REMOVE_ROLE.REMOVE_DESCRIPTION}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="1.5rem">
                    <Text onClick={() => setShowModalRole(false)} {...restartSecction}>
                        {REMOVE_ROLE.RESTART}
                    </Text>
                    <ContainerFlex onClick={() => delRol()} {...delSecction}>
                        {REMOVE_ROLE.DEL}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
