import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_NEWCATALOGID_ERROR,
    CREATE_NEWCATALOGID_START,
    CREATE_NEWCATALOGID_SUCCESS,
    LABEL_OPTION_TARGET,
} from '@components/Articles/Redux/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {CatalogIdProps} from '@components/Articles/interface';

export const fetchCatalogIdStart = () => {
    return {
        type: CREATE_NEWCATALOGID_START,
    };
};
export const fetchCatalogIdSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_NEWCATALOGID_SUCCESS,
        payload: result,
    };
};
export const fetchCatalogIdError = (error: AxiosError) => {
    return {
        type: CREATE_NEWCATALOGID_ERROR,
        error: error,
    };
};

export function CreatePersonalLoansForm(data: CatalogIdProps, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchCatalogIdStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateNewCatalogId}
            `,
                data,
                {headers}
            );
            dispatch(fetchCatalogIdSuccess(response));
        } catch (error) {
            dispatch(fetchCatalogIdError(error as AxiosError));
        }
    };
}

export const fetchLabelTarget = (value: number) => {
    return {
        type: LABEL_OPTION_TARGET,
        value: value,
    };
};
