import React, {useState} from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import Tab from '@General/ModuleTabs/Tab';
import {TabContainerProps} from '@General/ModuleTabs/interfaces';

const TabContainer: React.FC<TabContainerProps> = ({
    tabs,
    children,
    visibleContent,
    onTabChange,
}) => {
    const [activeTab, setActiveTab] = useState<string>(tabs[0]);

    const handleTabClick = (label: string) => {
        setActiveTab(label);
        if (onTabChange) onTabChange(label);
    };

    return (
        <>
            <FlexPanel Bb="1px solid #D4D6D8" Width="100%" Justify="start" Align="flex-end">
                {tabs.map((tab) => (
                    <Tab key={tab} label={tab} activeTab={activeTab} onClick={handleTabClick} />
                ))}
            </FlexPanel>
            {visibleContent}
            <ContainerFlex Justify="start" Align="start">
                {children[tabs.indexOf(activeTab)]}
            </ContainerFlex>
        </>
    );
};

export default TabContainer;
