import axios, {AxiosResponse} from 'axios';
import {
    GET_CAT_SUBURB,
    GET_CAT_SUBURB_ERROR,
    GET_CAT_SUBURB_RESET,
    GET_CAT_SUBURB_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {Dispatch} from 'redux';
import {URL} from '@config/constants/index';

export const fetchGetCatSuburbStart = () => {
    return {
        type: GET_CAT_SUBURB,
    };
};
export const fetchGetCatSuburbSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_SUBURB_SUCCESS,
        payload: result,
    };
};
export const fetchGetCatSuburbError = () => {
    return {
        type: GET_CAT_SUBURB_ERROR,
    };
};
export const fetchGetCatSuburbReset = () => {
    return {
        type: GET_CAT_SUBURB_RESET,
    };
};

export function GetCatSuburb(municipalityId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetCatSuburbStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatSuburb}`,
                {
                    headers,
                    params: {
                        municipalityId,
                    },
                }
            );
            dispatch(fetchGetCatSuburbSuccess(response));
        } catch (error) {
            dispatch(fetchGetCatSuburbError());
        }
    };
}
