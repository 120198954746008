import {
    GET_DATA_EXPENSES_FROM,
    RESET_DATA_FORM,
    IMAGES_PAYMENT,
    IMAGES_PAYMENT_PROP,
    IMAGES_RECIEVE,
    IMAGES_RECIEVE_PROP,
} from '@CashFlow/Expenses/Redux/types';
import {IExpendForm} from '@CashFlow/Expenses/ExpenseDetails/Types';

export const getAllExpensesFormData = (data: IExpendForm) => {
    return {
        type: GET_DATA_EXPENSES_FROM,
        payload: data,
    };
};

export const getImageForm = (data: string | null) => {
    return {
        type: IMAGES_PAYMENT,
        ImagePayments: data,
    };
};
export const getImageFormProp = (data: {name: string; size: number}) => {
    return {
        type: IMAGES_PAYMENT_PROP,
        ImagePaymentProps: data,
    };
};
export const getImageRecieveForm = (data: string | null) => {
    return {
        type: IMAGES_RECIEVE,
        ImageRecieves: data,
    };
};
export const getImageRecieveFormProp = (data: {name: string; size: number}) => {
    return {
        type: IMAGES_RECIEVE_PROP,
        ImageRecievesProps: data,
    };
};

export const resetAllFormDataExpenses = () => {
    return {
        type: RESET_DATA_FORM,
    };
};
