import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {formatNum} from '@/hooks/currentFormatUtils';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    CONTRACT_DETAIL,
    dataEndormentDischarge,
    dellWords,
    STATUS_CONTRACT,
} from '@Shopify/ClientProfile/constants';
import {addDischargeEndorement} from '@Shopify/ClientProfile/Redux/Action/DetailsContract';

export const ContractResume = () => {
    const dispatch = useDispatch();
    const {carShoppingId, details, branch, token, pawnId} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        carShoppingId: state.getUsersValidation.userData?.carShoopingId as number,
        details: state.deatilContract.details,
        pawnId: state.deatilContract.pawnId,
        branch: state.getUsersValidation.userData?.branchDetails?.[0]?.branchId as number,
    }));

    const endorementDischarge = (typeMovement: number, payment: number) => {
        const json = {
            carShoppingId: carShoppingId,
            typeMovement: typeMovement,
            branchId: branch,
            pawnId: pawnId,
            paymentAmount: payment,
            ...dataEndormentDischarge,
        };
        dispatch(addDischargeEndorement(token, json));
    };
    return (
        <ContainerFlex {...style.resumeContent}>
            <ContainerFlex FlexDir="column" Align="start" Padding="0 0.5rem 0 0">
                <Text {...style.resumeContent.detail}>
                    {details &&
                        details.borrowedMoney &&
                        formatNum({
                            number: Number(
                                dellWords(details.borrowedMoney, CONTRACT_DETAIL.LENDED)
                            ),
                            fractionDigits: 0,
                            money: true,
                        })}
                </Text>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.LENDED}</Text>
            </ContainerFlex>
            <ContainerFlex {...style.resumeContent.sections} Padding="0 0.5rem">
                <Text {...style.resumeContent.detail}>
                    {details && dellWords(details.garmentsNumber, CONTRACT_DETAIL.ARTICLES)}
                </Text>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.ARTICLES}</Text>
            </ContainerFlex>
            <ContainerFlex {...style.resumeContent.sections} Padding="0 0 0 0.5rem">
                <Text {...style.resumeContent.detail}>{details && details.nextEndorsement}</Text>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.DATE_ENDEAVOR}</Text>
            </ContainerFlex>
            {details && (
                <>
                    {details.contractDetail.status === STATUS_CONTRACT.TO_WIN && (
                        <ButtonGeneral
                            clic={() => endorementDischarge(2, details.contractDetail.discharge)}
                            height="2rem"
                            text={CONTRACT_DETAIL.NOW_DISCHARGE}
                            secondaryButton
                        />
                    )}
                    <ButtonGeneral
                        clic={() => endorementDischarge(1, details.contractDetail.endorsement)}
                        height="2rem"
                        margin="0 0 0 1rem"
                        text={CONTRACT_DETAIL.NOW_PAY}
                    />
                </>
            )}
        </ContainerFlex>
    );
};
