import {AppDispatch} from '@/config/store';
import {
    GET_TRASACTION,
    GET_TRASACTION_SUCCESS,
    GET_TRASACTION_ERROR,
} from '@components/CashFlow/Redux/Types';

import {URL} from '@config/constants/index';
import {IFilterParams, ITransactionPaginate} from '@components/CashFlow/interfaces';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getTransaction = () => {
    return {
        type: GET_TRASACTION,
    };
};
export const getTransactionSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TRASACTION_SUCCESS,
        payload: result,
    };
};
export const getTransactionError = (error: AxiosError) => {
    return {
        type: GET_TRASACTION_ERROR,
        error: error,
    };
};

export function getAllTransactions(
    data: IFilterParams,
    branchId: number,
    paginate: ITransactionPaginate,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTransaction());
        const params = {
            pageSize: paginate.pageSize?.toString() || '10',
            pageNumber: paginate.pageNumber?.toString() || '1',
            search: paginate.search?.toString() || '',
            branchId: branchId.toString(),
            date: data.filters.date.toString() || '',
            filterTransactionType: data.filters.filterTransactionType?.toString() || '0',
            filterMotionType: data.filters.filterMotionType?.toString() || '0',
            startTime: data.filters.startTime || '',
            endtime: data.filters.endTime || '',
        };
        const queryString = new URLSearchParams(params).toString();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllTransactions}?${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getTransactionSuccess(response));
        } catch (error) {
            dispatch(getTransactionError(error as AxiosError));
        }
    };
}
