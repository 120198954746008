export const SELECT_TYPE_LOCATIONS = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const GET_ADDRESS = {
    ADD: 'ADD_NEW_ADDRESS',
    RESET: 'RESET_NEW_ADDRESS',
    ERROR: 'ADD_NEW_ADDRESS_ERROR',
};
export const TYPE_LOCATIONS_SELECTED = 'TYPE_LOCATIONS_SELECTED';

export const CAT_ADDRESS = {
    GRAL_START: 'GRAL_START',
    CAT_POSTAL_CODE_SUCCESS: 'CAT_POSTAL_CODE_SUCCESS',
    CAT_STATE_SUCCESS: 'CAT_STATE_SUCCESS',
    CAT_MUNICIPAITY_SUCCESS: 'CAT_MUNICIPAITY_SUCCESS',
    CAT_COLONY_SUCCESS: 'CAT_COLONY_SUCCESS',
    GRAL_ERROR: 'GRAL_ERROR',
    RESET_POSTAL_CODE: 'RESET_POSTAL_CODE',
    RESET_ALL: 'RESET_ALL',
};

export const RESET_SELECT_LOCATIONS = 'RESET_SELECT_LOCATIONS';

export const GET_HOLIDAYS_START = 'GET_HOLIDAYS_START';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_ERROR = 'GET_HOLIDAYS_ERROR';

export const GET_SCHEDULES_START = 'GET_SCHEDULES_START';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_ERROR = 'GET_HOLIDAYS_ERROR';

export const RESET_SCHEDULES = 'RESET_SCHEDULES';

export const GET_COMPANY_LEVELS = {
    GET_LEVELS_START: 'GET_LEVELS_START',
    GET_LEVELS_SUCCESS: 'GET_LEVELS_SUCCESS',
    GET_LEVELS_ERROR: 'GET_LEVELS_ERROR',
};

export const GET_LIST_LEVELS = {
    GET_SUCCESS: 'GET_SUCCESS',
    GET_ERROR: 'GET_ERROR',
};

export const SEARCH_LEVELSANDBRANCH = {
    SEARCH_START: 'SEARCH_START',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_ERROR: 'SEARCH_ERROR',
    CLEAR_RESULTS: 'CLEAR_RESULTS',
};

export const LEVEL_GROUP_TYPES = {
    INIT_GROUPS: 'INIT_GROUPS',
    UPDATE_GROUP: 'UPDATE_GROUP',
    SELECT_GROUP_LEVEL: 'SELECT_GROUP_LEVEL',
    FILLING_GROUP: 'FILLING_GROUP',
    CURRENT_SELECTED_LEVELID: 'CURRENT_SELECTED_LEVELID',
};

export const ADD_USER_EMPLOYEE = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    RESET_SEARCH: 'RESET_SEARCH',
    RESET_ADDED_LIST: 'RESET_ADDED_LIST',
    ADD: 'ADD_UNIQUE_USERS',
    REMOVE_BY_IDS: 'ADD_USER_EMPLOYEE_REMOVE_BY_IDS',
};

export const GET_USER_BY_LEVEL = {
    GET_USER_START: 'GET_USER_START',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_ERROR: 'GET_USER_ERROR',
    ADD_USERS: 'ADD_USERS',
    UPDATE_PAGINATION: 'UPDATE_PAGINATION',
    SORT_USERS: 'SORT_USERS',
    TOGGLE_USER_SELECT: 'TOGGLE_USER_SELECT',
    TOGGLE_ALL_USERS: 'TOGGLE_ALL_USERS',
    REMOVE_USERS: 'REMOVE_USERS',
};

export const DEL_USERS = {
    ADD_ID_USER: 'ADD_ID_USER',
    SAVE_USERS_ID: 'SAVE_USERS_ID',
    RESET_SAVE: 'RESET_SAVE',
};

export const STORAGE_TYPES = {
    INIT: 'INIT',
    ADD: 'ADD',
    SELECTED: 'SELECTED',
    NEW_WAREHOUSE: 'NEW_WAREHOUSE',
    UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
    ERROR: 'ERROR_STORAGE',
    SUCCESS: 'SUCCESS_STORAGE',
    START: 'START_STORAGE',
};

export const NEW_GROUP = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const PERSISTENCE_GROUP_BRANCH = {
    NAME: 'NAME',
    BUSINESS_LINE: 'BUSINESS_LINE',
    SALES_CHANNELS: 'SALES_CHANNELS',
    USERS: 'USERS',
    SCHEDULES_AND_HOLIDAYS: 'SCHEDULES_AND_HOLIDAYS',
    STORAGE: 'STORAGE',
    RESET: 'RESET',
    HOLIDAYS_UPDATE: 'HOLIDAYS_UPDATE',
};

export const NEW_BRANCH = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const NEW_STORAGE = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    GET_STORAG_START: 'GET_STORAGE',
    GET_STORAGE_SUCCESS: 'GET_STORAGE_SUCCESS',
    GET_STORAGE_ERROR: 'GET_STORAGE_ERROR',
};

export const SEARCH_INVENTORY = {
    SEARCH_INVENTORY_START: 'SEARCH_INVENTORY_START',
    SEARCH_INVENTORY_SUCCESS: 'SEARCH_INVENTORY_SUCCESS',
    SEARCH_INVENTORY_ERROR: 'SEARCH_INVENTORYERROR',
    SEARCH_INVENTORY_CLEAR: 'SEARCH_INVENTORY_CLEAR',
    SET_SELECTED_STORAGE: 'SET_SELECTED_STORAGE',
};

export const LAST_LEVEL_BY_COMPANY = {
    ID_LEVEL_START: 'ID_LEVEL_START',
    ID_LEVEL_SUCCESS: 'ID_LEVEL_SUCCESS',
    ID_LEVEL_ERROR: 'ID_LEVEL_ERROR',
    ID_LEVEL_RESET: 'ID_LEVEL_RESET',
};

export const UPDATE_BRANCH_FORM = {
    BRANCH_START: 'BRANCH_START',
    BRANCH_SUCCESS: 'BRANCH_SUCCESS',
    BRANCH_ERROR: 'BRANCH_ERROR',
};

export const GET_BRANCH = {
    BY_LEVEL_START: 'BY_LEVEL_START',
    BY_LEVEL_SUCCESS: 'BY_LEVEL_SUCCESS',
    BY_LEVEL_ERROR: 'BY_LEVEL_ERROR',
    IS_EDIT: 'IS_EDIT',
};

export const GET_GROPUPS_COMPANY = {
    GET_COMPANY_LEVEL_DETAILS_START: 'GET_COMPANY_LEVEL_DETAILS_START',
    GET_COMPANY_LEVEL_DETAILS_SUCCESS: 'GET_COMPANY_LEVEL_DETAILS_SUCCESS',
    GET_COMPANY_LEVEL_DETAILS_ERROR: 'GET_COMPANY_LEVEL_DETAILS_ERROR',
};

export const GET_OPEN_HOURS = {
    ADD: 'ADD_OPEN_HOURS',
    RESET: 'RESET_OPEN_HOURS',
};
