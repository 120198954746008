import React, {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import {useParams} from 'react-router-dom';
import ReactSelect from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorMessage} from '@hookform/error-message';
import {
    getEmployeeWitnesses,
    getEmployeeManagers,
    getEmployeeById,
    getEmployeeManagersById,
    postSmelting,
    getResetNames,
} from '@ActionsAssignmentFoundry/AssignmentFoundryActions';
import {LoadingGeneralButtons} from '@Electronics/loading-buttons';
import {getFoundry} from '@ActionsAuditAssignment/FoundryAction';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {RootState, AppDispatch} from '@config/store';
import {DateTime} from 'luxon';
import {
    ArrowNav,
    ButtonFoundry,
    Container,
    DivForm,
    FormFoundry,
    Input,
} from '@AssignmentFoundry/styles';

export function AssignmentFoundry() {
    const [valuemanagers, setvaluemanagers] = useState(-1);
    const [valuewitnesses, setvaluewitnesses] = useState(-1);
    const [valueDate, setvalueDate] = useState('');
    const {
        handleSubmit,
        register,
        control,
        reset,

        formState: {errors},
    } = useForm({
        defaultValues: {
            puestomanagers: '',
            puestowitnesses: '',
            idFundidora: '',
            idTipoAsignacion: '',
            motivo: '',
            puesto: '',
            fecha: '',
            idAsignado: 0,
            idAsignador: 0,
        },
    });
    const params = useParams();
    const dispatch: AppDispatch = useDispatch();
    const selectChange = async () => {
        if (token) {
            const getEmployeeWitnessesSelect = () => dispatch(getEmployeeWitnesses(token));
            getEmployeeWitnessesSelect();
            const getFoundrySelect = () => dispatch(getFoundry(token));
            getFoundrySelect();
            const getEmployeeManagersSelect = () => dispatch(getEmployeeManagers(token));
            getEmployeeManagersSelect();
        }
    };
    useEffect(() => {
        selectChange();
    }, [params.id]);
    const managers = useSelector((state: RootState) => {
        return state.employeemanagers.managers;
    });
    const witnesses = useSelector((state: RootState) => {
        return state.employeemanagers.witnesses;
    });
    const foundry = useSelector((state: RootState) => {
        return state.foundry.foundry;
    });
    const foundryloading = useSelector((state: RootState) => {
        return state.foundry.loading;
    });
    const managersname = useSelector((state: RootState) => {
        return state.employeemanagers.managersname;
    });
    const witnessesname = useSelector((state: RootState) => {
        return state.employeemanagers.witnessesname;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const handleonChange = (id: string) => {
        setvaluemanagers(Number(id));
        dispatch(getEmployeeById(id, token));
    };
    const handleonChangeManagers = (id: string) => {
        setvaluewitnesses(Number(id));
        dispatch(getEmployeeManagersById(id, token));
    };
    const handleonChangeDate = (date: Date | [Date, Date] | null) => {
        setvalueDate(String(date));
    };
    useEffect(() => {
        if (managersname || witnessesname) {
            reset({
                puestomanagers: managersname,
                puestowitnesses: witnessesname,
                idAsignado: valuewitnesses,
                idAsignador: valuemanagers,
                fecha: valueDate,
            });
        }
    }, [managersname, witnessesname]);
    useEffect(() => {
        if (valueDate) {
            reset({
                fecha: valueDate,
            });
        }
    }, [valueDate]);
    const loading = useSelector((state: RootState) => {
        return state.employeemanagers;
    });
    interface NewType {
        idSucursal?: string;
        idAsignado: number;
        isAsignacion?: number;
        idAsignador: number;
        idFundidora: string;
        idTipoAsignacion?: string;
        fecha?: string;
        ahora?: string;
        idAsignacion?: number;
        motivo?: string;
    }
    const onSubmit = async (values: NewType) => {
        const jason = {
            idFundidora: values.idFundidora,
            idUsuarioTestigo: parseInt(values.idAsignado.toString(), 10),
            idUsuarioEncargado: parseInt(values.idAsignador.toString(), 10),
            fechaFundicion: DateTime.fromISO(
                values.fecha ?? DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss")
            ).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            now: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        };
        await dispatch(postSmelting(jason, token));
        setvaluemanagers(-1);
        setvaluewitnesses(-1);
        dispatch(getResetNames());
        reset({
            puestomanagers: '',
            puestowitnesses: '',
            idAsignado: 0,
            idAsignador: 0,
            fecha: '',
            idFundidora: '',
        });
    };
    return (
        <>
            <ArrowNav width="90%">
                <span>Fundiciones</span>
                <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>
                <span>Asignación Fundición</span>
            </ArrowNav>
            <Container w="75%">Asigne a un encargado la fundición</Container>
            <Container w="75%" pt="4rem">
                <FormFoundry onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <DivForm pr={true} column={true}>
                            <label className="required">Fecha</label>
                            <Controller
                                control={control}
                                name="fecha"
                                render={({field: {onBlur, value}}) => (
                                    <ReactDatePicker
                                        onChange={(e) => {
                                            handleonChangeDate(e);
                                        }}
                                        onBlur={onBlur}
                                        dateFormat="dd/MM/yyyy"
                                        showDisabledMonthNavigation
                                    />
                                )}
                            />

                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="idTipoAsignacion"
                                message="Seleccione tipo de asignación"
                                as="p"
                            />
                        </DivForm>
                        <DivForm x={true} column={true}>
                            <label className="required">Fundidora</label>
                            {foundryloading === true ? (
                                <LoadingGeneral />
                            ) : (
                                <Controller
                                    {...register('idFundidora')}
                                    control={control}
                                    rules={{required: true}}
                                    render={() => (
                                        <ReactSelect
                                            options={foundry}
                                            isClearable
                                            placeholder="Seleccione fundidora"
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="idAsignado"
                                message="Seleccione Auditor"
                                as="p"
                            />
                        </DivForm>
                        <DivForm pl={true} column={true}>
                            <label className="required">Encargado</label>
                            <select
                                {...register('idAsignado')}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    handleonChangeManagers(value);
                                }}
                            >
                                <option value="-1" />
                                {managers &&
                                    managers.map(
                                        (
                                            incidents: JSX.IntrinsicAttributes & {
                                                value: number;
                                                label: string;
                                            }
                                        ): JSX.Element => (
                                            <option key={incidents.value} value={incidents.value}>
                                                {incidents.label}
                                            </option>
                                        )
                                    )}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="fecha"
                                message="Seleccione Fecha visita"
                                as="p"
                            />
                        </DivForm>
                    </div>
                    <DivForm y={true}>
                        <DivForm column={true} pr={true}>
                            <label className="required">Puesto</label>
                            <Input
                                {...register('puestomanagers', {
                                    required: 'Ingrese motivo',
                                })}
                                id="puestomanagers"
                                aria-invalid={errors.motivo ? 'true' : 'false'}
                            />
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="motivo"
                                message="Seleccione motivo"
                                as="p"
                            />
                        </DivForm>
                        <DivForm column={true} x={true}>
                            <label className="required">Testigo</label>
                            <select
                                {...register('idAsignador')}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    handleonChange(value);
                                }}
                            >
                                <option value="-1" />
                                {witnesses &&
                                    witnesses.map(
                                        (
                                            incidents: JSX.IntrinsicAttributes & {
                                                value: number;
                                                label: string;
                                            }
                                        ): JSX.Element => (
                                            <option key={incidents.value} value={incidents.value}>
                                                {incidents.label}
                                            </option>
                                        )
                                    )}
                            </select>
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="idFundidora"
                                message="Seleccione Testigo"
                                as="p"
                            />
                        </DivForm>
                        <DivForm column={true} pl={true}>
                            <label className="required">Puesto</label>
                            <Input
                                {...register('puestowitnesses', {
                                    required: 'Ingrese motivo',
                                })}
                                id="puestowitnesses"
                                aria-invalid={errors.motivo ? 'true' : 'false'}
                            />
                            <ErrorMessage
                                errors={errors}
                                className="required-text"
                                name="motivo"
                                message="Seleccione motivo"
                                as="p"
                            />
                        </DivForm>
                    </DivForm>
                    <DivForm pt={true} jc={true}>
                        {loading.loadingpost === true ? (
                            <button className="button-filter" disabled>
                                <LoadingGeneralButtons />
                            </button>
                        ) : (
                            <ButtonFoundry>Asignar Fundición</ButtonFoundry>
                        )}
                    </DivForm>
                </FormFoundry>
            </Container>
        </>
    );
}
