import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {MODAL_INFO_BODY} from '@/components/CreditsAndPawns/constants';
import {ICustomerInfoEmploymentProps} from '@/components/CreditsAndPawns/interfaces';
import documentsIcon from '@/components/CreditsAndPawns/icons/documentsIcon.svg';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import CustomerInfoModalEmploymentRow from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalEmploymentRow';

const CustomerInfoModalEmployment: React.FC<ICustomerInfoEmploymentProps> = ({
    employmentSituationName,
    address,
    job,
    monthlyIncome,
    company,
    phone,
    laborOld,
}) => {
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="16px">
            <ContainerFlex Gap="8px" Width="100%" Height="29px" Justify="start" Align="center">
                <Image src={documentsIcon} height="24px" width="24px" />
                <Text
                    FontSize=" 1.25rem"
                    FontWeight="700"
                    Color="#1D1E20"
                    FontFamily="Nunito"
                    LHeight="1.50rem"
                    FontStyle="normal"
                >
                    {MODAL_INFO_BODY.SUBTITLE_EMPLOYMENT}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Width="100%"
                Height="296px"
                Gap="8px"
                Justify="start"
                Align="start"
            >
                <CustomerInfoModalEmploymentRow
                    firstTitle={MODAL_INFO_BODY.EMPLOYMENT_SITUATION}
                    firstText={employmentSituationName}
                    secondTitle={MODAL_INFO_BODY.COMPANY}
                    secondText={company}
                />

                <CustomerInfoModalEmploymentRow
                    firstTitle={MODAL_INFO_BODY.ADDRESS}
                    firstText={address}
                    secondTitle={MODAL_INFO_BODY.PHONE}
                    secondText={phone}
                />

                <CustomerInfoModalEmploymentRow
                    firstTitle={MODAL_INFO_BODY.JOB}
                    firstText={job}
                    secondTitle={MODAL_INFO_BODY.LABOR_OLD}
                    secondText={laborOld}
                />

                <CustomerInfoModalEmploymentRow
                    firstTitle={MODAL_INFO_BODY.MONTHLY_INCOME}
                    firstText={numberToCurrencyWithoutCents(monthlyIncome)}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CustomerInfoModalEmployment;
