import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';
import {clientSort} from '@/components/Quoter/Redux/Actions/SearchClientActions';
import {IClientSorting} from '@Shopify/ListClient/interfaces';
import {
    ClientSort,
    ClientSortColumns as columnMapping,
} from '@components/Shopify/ListClient/constants';

export const useClientSort = (
    sortConfig: IClientSorting | null,
    onSortChange: (sorting: IClientSorting) => void
) => {
    const dispatch: AppDispatch = useDispatch();

    const getSortSelection = (col: string, direction: string): IClientSorting => {
        const selectedCol = columnMapping[col] ?? 0;
        const validDirection = direction === ClientSort.Asc ? 0 : 1;
        return {
            sortedCol: selectedCol,
            direction: validDirection,
        };
    };

    const requestSort = (key: string) => {
        let direction: typeof ClientSort.Asc | typeof ClientSort.Desc = ClientSort.Asc;
        if (
            sortConfig &&
            sortConfig.sortedCol === getSortSelection(key, 'asc').sortedCol &&
            sortConfig.direction === 0
        ) {
            direction = ClientSort.Desc;
        }
        const sorting = getSortSelection(key, direction);
        dispatch(clientSort(sorting));
        onSortChange(sorting);
    };

    const isSortingActive = (key: string, direction: string) => {
        const sorting = getSortSelection(key, direction);
        return (
            sortConfig &&
            sortConfig.sortedCol === sorting.sortedCol &&
            sortConfig.direction === sorting.direction
        );
    };

    return {requestSort, isSortingActive};
};
