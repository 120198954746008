import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {ShoppingCart} from '@components/ShoppingCart/index';
import {ResumePayment} from '@Loan/PaymentQuoter/ResumePayment';
import {routers} from '@/appRoute';

export const CarSelector = () => {
    const location = useLocation();
    const ScreenCatch = () => {
        let ViewCart: ReactNode;
        if (
            location.pathname !== routers.PaymentQuoter &&
            location.pathname !== routers.PaymentQuoterConfirmation
        )
            ViewCart = <ShoppingCart />;

        if (location.pathname === routers.PaymentQuoter) ViewCart = <ResumePayment />;
        if (
            location.pathname === routers.PaymentQuoterConfirmation ||
            location.pathname === routers.ClientAddNew ||
            location.pathname === routers.CashFlow ||
            location.pathname === routers.ProductDetailsContainer ||
            location.pathname === routers.ExpenseDetails ||
            location.pathname === routers.ExpenseDetailsCreate ||
            location.pathname === routers.CashFlow
        )
            ViewCart = <></>;
        return ViewCart;
    };

    return <>{ScreenCatch()}</>;
};
