export const addUserText = {
    newUser: 'Nuevo Usuario',
    personalData: 'Datos Personales',
    details:
        'Estos detalles son fundamentales para su identificación y seguridad en nuestra plataforma.',
    name: 'Nombre(s)',
    lastName: 'Apellido Paterno',
    lastNameMother: 'Apellido Materno',
    doB: 'Fecha de nacimiento',
    admin: 'Administativa',
    pos: 'POS',
    adminPOS: 'Administrativa / POS',
    email: 'Correo electrónico',
    credentials: 'Credenciales de acceso',
    credentialsText:
        'A continuación, encontrarás los campos de credenciales requeridos para acceder a nuestras plataformas de servicio.',
    select: 'Selecciona a cual plataforma necesita acceso',
    userName: 'Nombre de usuario',
    password: 'Contraseña',
    roles: 'Roles POS',
    rolesAdmin: 'Roles Administrativos',
    rolesText:
        'Asigna un rol POS que este usuario fungirá en la empresa, esto afectará los permisos y accesos que tiene dentro de la plataforma.',
    addRole: 'Agregar otro rol',
    address: 'Dirección',
    addressText: 'Agregar una dirección personal o laboral para este usuario.',
    deleteAddress: 'Eliminar dirección',
    cp: 'C.P.',
    street: 'Calle',
    noExt: 'No. Exterior',
    noInt: 'No Interior',
    municipality: 'Municipio',
    colony: 'Colonia / Alcaldía',
    state: 'Estado',
    addAddress: 'Agregar otra dirección',
    location: 'Locaciones',
    locationText: 'Asigna las ubicaciones en tu empresa a las que este usuario tendrá acceso.',
    activity: 'Actividad reciente',
    activtyText:
        'Una vez creado, aquí podrás ver actividad de este usuario en la empresa y en el sistema.',
};

export const saveForm = 'Guardar';
export const noAddress = 0;
export const singleAddress = 1;
export const POS = 'pos';
export const ADMIN = 'admin';
export const posADMIN = 'pos/admin';
export const BOTH = 'both';

export const yupRequired = 'El campo es requerido';
export const yupValidEmail = 'Ingrese un correo electrónico válido';
