export const ROLES_PASS = 'ROLES_PASS';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';
export const GET_ROLE_FINISH = 'GET_ROLE_FINISH';
export const SAVE_ROLES = 'SAVE_ROLES';
export const SEARCH_VALUE = 'SEARCH_VALUE';
export const GET_ROLE_SUCCESS_COPY = 'GET_ROLE_SUCCESS_COPY';

export const GET_ROLE_VALIDATE = 'GET_ROLE_VALIDATE';
export const RESET_VALIDATE = 'RESET_VALIDATE';

export const GET_ROLE_VALIDATE_SUCCESS = 'GET_ROLE_VALIDATE_SUCCESS';
export const GET_ROLE_VALIDATE_ERROR = 'GET_ROLE_VALIDATE_ERROR';

export const GET_ROLE_SIZE = 'GET_ROLE_SIZE';
export const GET_ROLE_SIZE_SUCCESS = 'GET_ROLE_SIZE_SUCCESS';
export const GET_ROLE_SIZE_ERROR = 'GET_ROLE_SIZE_ERROR';
export const GET_SELECT_ROLE_SIZE = 'GET_SELECT_ROLE_SIZE';

export const GET_ROLE_TYPE = 'GET_ROLE_TYPE';
export const GET_ROLE_TYPE_SUCCESS = 'GET_ROLE_TYPE_SUCCESS';
export const GET_ROLE_TYPE_ERROR = 'GET_ROLE_TYPE_ERROR';
export const GET_SELECT_ROLE_TYPE = 'GET_SELECT_ROLE_TYPE';

export const POST_ADD_NEW_ROLE_PAGE = 'POST_ADD_NEW_ROLE_PAGE';

export const FILTERS = 'FILTERS';
export const SEARCHED = 'SEARCHED';

export const GET_SIZE_COMPANY_START = 'GET_SIZE_COMPANY_START';
export const GET_SIZE_COMPANY_SUCCESS = 'GET_SIZE_COMPANY_SUCCESS';
export const GET_SIZE_COMPANY_ERROR = 'GET_SIZE_COMPANY_ERROR';

export const PUT_SIZE_COMPANY_START = 'PUT_SIZE_COMPANY_START';
export const PUT_SIZE_COMPANY_SUCCESS = 'PUT_SIZE_COMPANY_SUCCESS';
export const PUT_SIZE_COMPANY_ERROR = 'PUT_SIZE_COMPANY_ERROR';

export const POST_CREATE_STRUCTURE_START = 'POST_CREATE_STRUCTURE_START';
export const POST_CREATE_STRUCTURE_SUCCESS = 'POST_CREATE_STRUCTURE_SUCCESS';
export const POST_CREATE_STRUCTURE_ERROR = 'POST_CREATE_STRUCTURE_ERROR';
export const POST_CREATE_STRUCTURE_FINISH = 'POST_CREATE_STRUCTURE_FINISH';
export const POST_CREATE_RESET = 'POST_CREATE_RESET';
export const POST_CREATE_RESET_MESSAGE = 'POST_CREATE_RESET_MESSAGE';

export const SELECT_SHOW_STATE = 'SELECT_SHOW_STATE';
export const SELECT_ROLETYPE_STATE = 'SELECT_ROLETYPE_STATE';

export const RESET_TABLE = 'RESET_TABLE';
export const RESET_STATE = 'RESET_STATE';
export const RESET_DELETE = 'RESET_DELETE';

export const GET_ROLE_VALIDATE_EDIT = 'GET_ROLE_VALIDATE_EDIT';
export const GET_ROLE_VALIDATE_EDIT_SUCCEES = 'GET_ROLE_VALIDATE_EDIT_SUCCEES';
export const GET_ROLE_VALIDATE_EDIT_ERROR = 'GET_ROLE_VALIDATE_EDIT_ERROR';

export const POST_DUPLICATE = 'POST_DUPLICATE';
export const POST_DUPLICATE_SUCCESS = 'POST_DUPLICATE_SUCCESS';
export const POST_DUPLICATE_ERROR = 'POST_DUPLICATE_ERROR';
export const POST_DUPLICATE_FINISH = 'POST_DUPLICATE_FINISH';
export const POST_DUPLICATE_RESET_MESSAGE = 'POST_DUPLICATE_RESET_MESSAGE';

export const GET_CHANGE_ROLE_STATE = 'GET_CHANGE_ROLE_STATE';
export const GET_CHANGE_ROLE_STATE_SUCCESS = 'GET_CHANGE_ROLE_STATE_SUCCESS';
export const GET_CHANGE_ROLE_STATE_ERROR = 'GET_CHANGE_ROLE_STATE_ERROR';
export const GET_CHANGE_ROLE_STATE_FINISH = 'GET_CHANGE_ROLE_STATE_FINISH';

export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
export const DELETE_ROLE_FINISH = 'DELETE_ROLE_FINISH';
export const DELETE_ROLE_RESET_MESSAGE = 'DELETE_ROLE_FINISH_RESET_MESSAGE';

export const GET_EDIT_ROL = 'GET_EDIT_ROL';
export const GET_EDIT_ROL_SUCCESS = 'GET_EDIT_ROL_success';
export const GET_EDIT_ROL_ERROR = 'GET_EDIT_ROL_ERROR';
export const GET_EDIT_ROL_RESET = 'GET_EDIT_ROL_RESET';
export const RESET_INFORMATION_ROLE = 'RESET_INFORMATION_ROLE';

export const CURRENT_PAGE = 'CURRENT_PAGE';
export const MODAL_STATES = 'MODAL_STATES';

export const POST_VALIDATE_CUSTOM_ROL_START = 'POST_VALIDATE_CUSTOM_ROL_START';
export const POST_VALIDATE_CUSTOM_ROL_SUCCESS = 'POST_VALIDATE_CUSTOM_ROL_SUCCESS';
export const POST_VALIDATE_CUSTOM_ROL_ERROR = 'POST_VALIDATE_CUSTOM_ROL_ERROR';
export const POST_VALIDATE_CUSTOM_ROL_FINISH = 'POST_VALIDATE_CUSTOM_ROL_FINISH';

export const ROLES_INFORMATION_START = 'ROLES_INFORMATION_START';
export const ROLES_INFORMATION_SUCCESS = 'ROLES_INFORMATION_SUCCESS';
export const ROLES_INFORMATION_ERROR = 'ROLES_INFORMATION_ERROR';

export const EMPLOYEE_USER_SEARCH_START = 'EMPLOYEE_USER_SEARCH_START';
export const EMPLOYEE_USER_SEARCH_SUCCESS = 'EMPLOYEE_USER_SEARCH_SUCCESS';
export const EMPLOYEE_USER_SEARCH_ERROR = 'EMPLOYEE_USER_SEARCH_ERROR';

export const USER_ASSIGNMENT_ROLES_START = 'USER_ASSIGNMENT_ROLES_START';
export const USER_ASSIGNMENT_ROLES_SUCCESS = 'USER_ASSIGNMENT_ROLES_SUCCESS';
export const USER_ASSIGNMENT_ROLES_ERROR = 'USER_ASSIGNMENT_ROLES_ERROR';

export const ROLES_INFORMATION_ASSIGNMENT = 'ROLES_INFORMATION_ASSIGMENT';
