import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    LIST_CHARACTERISTIC_HEREDITARY_ERROR,
    LIST_CHARACTERISTIC_HEREDITARY_START,
    LIST_CHARACTERISTIC_HEREDITARY_SUCCESS,
    SELECTED_DATA_NODE,
} from '@components/Articles/Redux/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {DataNodeProps} from '@components/Articles/Redux/interfaces';

export const fetchHereditaryDataStart = () => {
    return {
        type: LIST_CHARACTERISTIC_HEREDITARY_START,
    };
};
export const fetchHereditaryDataSuccess = (result: AxiosResponse) => {
    return {
        type: LIST_CHARACTERISTIC_HEREDITARY_SUCCESS,
        payload: result,
    };
};
export const fetchHereditaryDataError = (error: AxiosError) => {
    return {
        type: LIST_CHARACTERISTIC_HEREDITARY_ERROR,
        error: error,
    };
};
export function GetInheritedFeaturesData(
    filters: {levelID: number; articleLevels: number},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchHereditaryDataStart);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInheritedFeatures}${queryString}
            `,
                {headers}
            );
            dispatch(fetchHereditaryDataSuccess(response));
        } catch (error) {
            dispatch(fetchHereditaryDataError(error as AxiosError));
        }
    };
}

export const selectedDataNode = (value: DataNodeProps[]) => {
    return {
        type: SELECTED_DATA_NODE,
        value: value,
    };
};
