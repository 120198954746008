import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {SEARCH_LEVELSANDBRANCH} from '@components/LocationsBranch/Redux/types';

const searchStart = () => ({
    type: SEARCH_LEVELSANDBRANCH.SEARCH_START,
});

const searchSuccess = (result: LevelSelection[]) => ({
    type: SEARCH_LEVELSANDBRANCH.SEARCH_SUCCESS,
    payload: result,
});

const searchError = (error: AxiosError) => ({
    type: SEARCH_LEVELSANDBRANCH.SEARCH_ERROR,
    error: error,
});

export const ClearLevelsSearch = () => ({
    type: SEARCH_LEVELSANDBRANCH.CLEAR_RESULTS,
});

export const SearchLevelsAndBranch = (companyId: number, search: string, token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(searchStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSearchLevelsAndBranch}`,
                {
                    headers,
                    params: {
                        companyId,
                        search,
                    },
                }
            );
            const responseData: LevelSelection[] = response.data.data.map(
                (item: {title: string; ids: string}) => ({
                    title: item.title.split('> ').slice(1).join('> '),
                    ids: item.ids.split(',').slice(1).map(Number),
                })
            );
            dispatch(searchSuccess(responseData));
        } catch (error: unknown) {
            dispatch(searchError(error as AxiosError));
        }
    };
};
