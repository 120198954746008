import React, {useState} from 'react';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerCellGrid,
    Image,
} from '@/components/Shopify/Ecommerce/styles';
import {IMAGE_ATL_DESCRIPTION, METHODPAYMENT} from '@components/QuickPayment/Constants';
import {SearchInput} from '@components/Customer/Styles';
import {CardInput} from '@components/QuickPayment/CardInput';
import iCard from '@images/billingblack.svg';
import Up from '@images/Up.svg';
import Down from '@images/Down.svg';
import Calendar from '@images/calendar.svg';
import {IMethodPaymentTransactionProps} from '@components/QuickPayment/interfaces';
import {Controller} from 'react-hook-form';
import ErrorMessage from '@components/QuickPayment/ErrorMessage';

export const MethodPayment: React.FC<IMethodPaymentTransactionProps> = ({control, errors}) => {
    const [showForm, setShowForm] = useState(true);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FFF"
            Width="100%"
            Height="400px"
            Padding="24px"
            Radius="24px"
            Gap="46px"
            OverFlowY="auto"
        >
            <ContainerFlex Width="100%" Align="start" Justify="start" Flex="0">
                <Text FontFamily="Nunito" FontSize="1.13rem" Color="#2A2C2F" FontWeight="700">
                    {METHODPAYMENT.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Align="start" Width="100%" Flex="0">
                <ContainerFlex Justify="start" Align="start" Gap="0.5rem">
                    <Image
                        src={iCard}
                        width="24px"
                        Height="24px"
                        alt={IMAGE_ATL_DESCRIPTION.CREDIT_CARD_ICON}
                    />
                    <Text wSpace="normal" Color="#2A2C2F" FontWeight="700">
                        {METHODPAYMENT.CARD}
                    </Text>
                </ContainerFlex>
                <Image
                    src={showForm ? Up : Down}
                    width="24px"
                    Height="24px"
                    onClick={() => setShowForm(!showForm)}
                    Cursor="pointer"
                    alt={IMAGE_ATL_DESCRIPTION.EXPAND_ICON}
                />
            </ContainerFlex>
            {showForm === true && (
                <ContainerFlex Width="100%" Height="100%" Gap="25px" FlexDir="column">
                    <ContainerFlex Justify="start" Align="start" FlexWrap="wrap" Gap="1rem">
                        <ContainerCellGrid
                            FlexDir="column"
                            Align="start"
                            Height="4.25rem"
                            Width="20rem"
                            Gap="4px"
                        >
                            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                                {METHODPAYMENT.NUMBER}
                            </Text>
                            <CardInput control={control} errors={errors} />
                        </ContainerCellGrid>
                        <ContainerCellGrid
                            FlexDir="column"
                            Align="start"
                            Height="4.25rem"
                            Width="20rem"
                            Gap="4px"
                        >
                            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                                {METHODPAYMENT.NAME}
                            </Text>
                            <ContainerFlex FlexDir="column">
                                <Controller
                                    name="cardHolderName"
                                    control={control}
                                    render={({field}) => <Input {...field} />}
                                />
                                {errors.cardHolderName && (
                                    <ErrorMessage>{errors.cardHolderName.message}</ErrorMessage>
                                )}
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        FlexWrap="wrap"
                        Gap="1rem"
                        PaddingB="10px"
                    >
                        <ContainerCellGrid
                            FlexDir="column"
                            Align="start"
                            Height="4.25rem"
                            Width="10rem"
                            Gap="4px"
                        >
                            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                                {METHODPAYMENT.DATE}
                            </Text>
                            <ContainerFlex FlexDir="column">
                                <SearchInput
                                    Height="40px"
                                    Position="none"
                                    GridColumn="75% 25%"
                                    BoxShadowH=""
                                    BoxShadowF=""
                                    Padding="0.5rem 1rem"
                                    BackG="#FFFFFF"
                                    Radius="32px"
                                    Margin="0 0 8px 0"
                                >
                                    <Controller
                                        name="expiryDate"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <Input {...field} type="text" maxLength={7} />
                                                <Image
                                                    src={Calendar}
                                                    width="24px"
                                                    Height="24px"
                                                    alt={IMAGE_ATL_DESCRIPTION.CALENDAR_ICON}
                                                />
                                            </>
                                        )}
                                    />
                                </SearchInput>
                                {errors.expiryDate && (
                                    <ErrorMessage>{errors.expiryDate.message}</ErrorMessage>
                                )}
                            </ContainerFlex>
                        </ContainerCellGrid>
                        <ContainerCellGrid
                            FlexDir="column"
                            Align="start"
                            Height="4.25rem"
                            Width="7rem"
                            Gap="4px"
                        >
                            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                                {METHODPAYMENT.CCV}
                            </Text>
                            <ContainerFlex FlexDir="column">
                                <Controller
                                    name="cvv"
                                    control={control}
                                    render={({field}) => (
                                        <Input maxLength={4} type="password" {...field} />
                                    )}
                                />
                                {errors.cvv && <ErrorMessage>{errors.cvv.message}</ErrorMessage>}
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
