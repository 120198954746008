export const GET_ALL_NATIONS = 'GET_ALL_NATIONS';
export const GET_ALL_NATIONS_SUCCESS = 'GET_ALL_NATIONS_SUCCESS';
export const GET_ALL_NATIONS_ERROR = 'GET_ALL_NATIONS_ERROR';

export const GET_ALL_COOWNERS = 'GET_ALL_COOWNERS';
export const GET_ALL_COOWNERS_SUCCESS = 'GET_ALL_COOWNERS_SUCCESS';
export const GET_ALL_COOWNERS_ERROR = 'GET_ALL_COOWNERS_ERROR';

export const ADD_COOWNER_BENEFICIARY = 'ADD_COOWNER_BENEFICIARY';
export const ADD_COOWNER_BENEFICIARY_SUCCESS = 'ADD_COOWNER_BENEFICIARY_SUCCESS';
export const ADD_COOWNER_BENEFICIARY_ERROR = 'ADD_COOWNER_BENEFICIARY_ERROR';

export const VALIDATE_IMAGE_PRODUCT = 'VALIDATE_IMAGE_PRODUCT';
export const VALIDATE_IMAGE_PRODUCT_SUCCESS = 'VALIDATE_IMAGE_PRODUCT_SUCCESS';
export const VALIDATE_IMAGE_PRODUCT_ERROR = 'VALIDATE_IMAGE_PRODUCT_ERROR';

export const UPLOAD_IMAGE_PRODUCT = 'UPLOAD_IMAGE_PRODUCT';
export const UPLOAD_IMAGE_PRODUCT_SUCCESS = 'UPLOAD_IMAGE_PRODUCT_SUCCESS';
export const UPLOAD_IMAGE_PRODUCT_ERROR = 'UPLOAD_IMAGE_PRODUCT_ERROR';

export const TARGET_EDIT_COOWNER = 'TARGET_EDIT_COOWNER';
export const TARGET_EDIT_RESET = 'TARGET_EDIT_RESET';

export const UPDATE_EDIT_COOWNER_START = 'UPDATE_EDIT_COOWNER_START';
export const UPDATE_EDIT_COOWNER_SUCCESS = 'UPDATE_EDIT_COOWNER_SUCCESS';
export const UPDATE_EDIT_COOWNER_ERROR = 'UPDATE_EDIT_COOWNER_ERROR';

export const REMOVE_COOWNER_BENEFIST = 'REMOVE_COOWNER_BENEFIST';
export const REMOVE_COOWNER_BENEFIST_SUCCESS = 'REMOVE_COOWNER_BENEFIST_SUCCESS';
export const REMOVE_COOWNER_BENEFIST_ERROR = 'REMOVE_COOWNER_BENEFIST_ERROR';

export const FORM_ADD_COOWNER = 'FORM_ADD_COOWNER';
export const FORM_ADD_COOWNER_SUCCESS = 'FORM_ADD_COOWNER_SUCCESS';
export const FORM_ADD_COOWNER_ERROR = 'FORM_ADD_COOWNER_ERROR';
export const ADD_COOWNER_FORM = 'ADD_COOWNER_FORM';
export const CANCEL_ADD_COOWNER_FORM = 'CANCEL_ADD_COOWNER_FORM';

export const SET_SELECTED_PLEDGE = 'SET_SELECTED_PLEDGE';
export const SET_SELECTED_PLEDGE_RESET = 'SET_SELECTED_PLEDGE_RESET';
