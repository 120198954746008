import {ROLE_USERS} from '@Roles/CreateRoles/Redux/Types';
import {IUserRoles, IUserRolesRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: IUserRoles = {
    userRoles: [],
    error: false,
    loading: false,
};

const UserRoles = (state = initialState, action: IUserRolesRedux): IUserRoles => {
    switch (action.type) {
        case ROLE_USERS.ASIGNMENT_START:
            return {...state, loading: true, error: false};
        case ROLE_USERS.ASIGNMENT_SUCCESS:
            return {
                ...state,
                userRoles: action.userRoles,
                loading: false,
                error: false,
            };
        case ROLE_USERS.ASIGNMENT_ERROR:
            return {...state, loading: false, error: false};
        default:
            return state;
    }
};
export default UserRoles;
