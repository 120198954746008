import axios from 'axios';
import {
    GET_RETURNTYPE,
    GET_RETURNTYPE_SUCCESS,
    GET_RETURNTYPE_ERROR,
    RESET_EMPLOYE_DATA,
} from '@/components/CashFlowActions/Redux/Types/types';

import process from 'process';
import {URL} from '@config/constants/index';

export const GetEmployeeByBranch = () => ({
    type: GET_RETURNTYPE,
});

export const GetEmployeeByBranchSuccess = (data) => ({
    type: GET_RETURNTYPE_SUCCESS,
    payload: data,
});

export const GetEmployeeByBranchError = (error) => ({
    type: GET_RETURNTYPE_ERROR,
    payload: error.response?.data,
});
export const resetEmployeeData = () => ({
    type: RESET_EMPLOYE_DATA,
});

export const GetReturnType = (token) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(GetEmployeeByBranch());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetReturnType}`,
                {headers}
            );
            dispatch(GetEmployeeByBranchSuccess(response.data));
        } catch (error) {
            dispatch(GetEmployeeByBranchError(error));
        }
    };
};
