import {
    PUT_BRANCH_SCHEDULE_ENDEAVOR,
    PUT_BRANCH_SCHEDULE_ENDEAVOR_ERROR,
    PUT_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS,
    PUT_BRANCH_SCHEDULE_ENDEAVOR_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateEndeavor: false,
};

const PutScheduleEndeavor = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_BRANCH_SCHEDULE_ENDEAVOR:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS:
            return {
                ...state,
                updateEndeavor: action.payload.data,
            };
        case PUT_BRANCH_SCHEDULE_ENDEAVOR_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_BRANCH_SCHEDULE_ENDEAVOR_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutScheduleEndeavor;
