import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    PAYMENT_METHOD_ERROR,
    PAYMENT_METHOD_START,
    PAYMENT_METHOD_SUCCESS,
    GET_CLIENT_CARDS_SUCCESS,
} from '@components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {ICardData} from '@components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';

const paymentMethodError = (error: AxiosError) => {
    return {
        type: PAYMENT_METHOD_ERROR,
        error: error,
    };
};

const paymentMethodStart = () => {
    return {
        type: PAYMENT_METHOD_START,
    };
};

const paymentMethodSuccess = (result: AxiosResponse) => {
    return {
        type: PAYMENT_METHOD_SUCCESS,
        payload: result,
    };
};

const getClientCardsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CLIENT_CARDS_SUCCESS,
        payload: result,
    };
};

export const getClientCardsAction = (token: string) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetClientCreditCards}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(paymentMethodStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getClientCardsSuccess(response.data));
        } catch (e) {
            dispatch(paymentMethodError(e as AxiosError));
        }
    };
};

export const addNewPaymentMethodAction = ({
    token,
    paymentMethod: {cardNumber, cardOwner, cvv, vigency},
}: {
    token: string;
    paymentMethod: ICardData;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddPaymentMethod}`;
    const params = {
        card: cardNumber,
        holderName: cardOwner,
        expirationDate: vigency,
        ccv: cvv,
    };
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(paymentMethodStart());
        try {
            const response = await axios.post(url, params, {headers});
            dispatch(paymentMethodSuccess(response.data));
        } catch (e) {
            dispatch(paymentMethodError(e as AxiosError));
        }
    };
};

export const updateDefaultPaymentMethodAction = ({
    token,
    paymentMethodId,
}: {
    token: string;
    paymentMethodId: number;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdatePaymentMethod}`;
    const params = {paymentMethodId};
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(paymentMethodStart());
        try {
            const response = await axios.post(url, params, {headers});
            dispatch(paymentMethodSuccess(response.data));
        } catch (e) {
            dispatch(paymentMethodError(e as AxiosError));
        }
    };
};

export const deletePaymentMethodAction = ({
    token,
    paymentMethodId,
}: {
    token: string;
    paymentMethodId: number;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeletePaymentMethod}`;
    const params = {metodoPagoId: paymentMethodId};
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(paymentMethodStart());
        try {
            const response = await axios.post(url, params, {headers});
            dispatch(paymentMethodSuccess(response.data));
        } catch (e) {
            dispatch(paymentMethodError(e as AxiosError));
        }
    };
};
