import React, {useState, useEffect, useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, AppDispatch} from '@config/store';
import {
    addCodeMarketLoginUser,
    addCountMarketLoginUser,
    resetCodeMarketLoginUser,
} from '@components/MarketForgottenLogin/Redux/Actions/ResetPassCodeAction';
import {schemaValide} from '@/components/MarketLogin/ValidationYup';
import {EMAIL_VERIFICATION} from '@components/MarketLogin/constants';
import {Input, ButtonImg, ImageLogo} from '@/components/MarketLogin/Styles';
import {ImageLogo as LoginImageLogo} from '@LoginClient/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import fox from '@images/FoxMail.svg';
import buttonReset from '@images/resetValidationButton.svg';
import {TextModule} from '@components/MarketLogin/SubModules/ModuleText';
import {TermAndConditions} from '@components/Login/MarketPlaceLogin/TermsAndConditions';
import {PostCodeNewPassword} from '@/components/MarketForgottenLogin/Redux/Actions/VerifyCodeClientAction';
import {NewCodeVerifyUser} from '@/components/MarketForgottenLogin/Redux/Actions/NewResetCodeAction';
import {ErrorUser} from '@/components/MarketForgottenLogin/ErrorMessageCode';

export const ResetAccountCode = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [error, setErrors] = useState(false);

    const count = useSelector((state: RootState) => state.CodePasswordChange.count);
    const code = useSelector((state: RootState) => state.CodePasswordChange.code);
    const rowid = useSelector((state: RootState) => state.EmailResetCodeClient.userData);
    const userData = useSelector((state: RootState) => state.addEmail.email);
    const email = userData;

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schemaValide),
        defaultValues: {inputs: code, names: '', version: 1},
    });
    const [timer, setTimer] = useState(() => (count ? parseInt(count, 10) : 30));
    const [showReset, setShowReset] = useState(false);
    const countdownRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timer > 0) {
            countdownRef.current = setInterval(() => {
                setTimer((prevTimer) => {
                    const newTime = prevTimer - 1;
                    dispatch(addCountMarketLoginUser(newTime.toString()));
                    return newTime;
                });
            }, 1000);
        } else {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
            setShowReset(true);
        }

        return () => {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        };
    }, [timer, dispatch]);

    const onSubmit = async (data: {inputs: string}) => {
        const codeString = code.join('');
        const jsonData = {
            rowid: rowid,
            code: codeString,
        };
        dispatch(PostCodeNewPassword(jsonData, navigate, setErrors));
    };
    const handleReset = () => {
        setTimer(30);
        setShowReset(false);
        setErrors(false);
        reset({inputs: Array(4).fill('')});
        dispatch(NewCodeVerifyUser(email));
        dispatch(resetCodeMarketLoginUser());
    };
    const handleFocus = () => {
        setErrors(false);
    };
    const handleLoginImageClick = () => {
        navigate('/Login/ResetAccount');
        dispatch(resetCodeMarketLoginUser());
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            const codeload = [...code];
            codeload[index] = value;
            dispatch(addCodeMarketLoginUser(codeload));
            const nextInput = document.getElementById(`input-${index + 1}`);
            if (value !== '' && nextInput) {
                nextInput.focus();
            }
        }
    };
    const renderInput = (index: number) => (
        <Controller
            key={index}
            name={`inputs.${index}`}
            control={control}
            render={({field}) => (
                <Input
                    id={`input-${index}`}
                    type="text"
                    maxLength={1}
                    hasError={error || !!errors.inputs}
                    {...field}
                    autoComplete="off"
                    onChange={(e) => {
                        field.onChange(e);
                        handleChange(e, index);
                    }}
                    onFocus={handleFocus}
                />
            )}
        />
    );

    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <ContainerFlex backG="#E5E5FF" FlexDir="column" Width="100%">
                <ContainerFlex
                    FlexDir="column"
                    Gap="24px"
                    Position="relative"
                    Radius="10px"
                    Width="407px"
                    Height="521px"
                    backG="#fff"
                    Border="1px solid #5A5AFF"
                    Padding="32px"
                >
                    <ButtonImg>
                        <LoginImageLogo
                            onClick={handleLoginImageClick}
                            Width="100%"
                            Height="100%"
                            src={buttonReset}
                        />
                    </ButtonImg>
                    <ImageLogo Width="30%" Height="30%" src={fox} />
                    <TextModule />
                    <ContainerForm
                        Gap="24px"
                        Height="auto"
                        FlexDir="column"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ContainerFlex FlexDir="column" Height="auto" Gap="8px">
                            <ContainerFlex ColumnGap="16px">
                                {Array.from({length: 4}, (_, i) => renderInput(i))}
                            </ContainerFlex>
                            <ContainerFlex>
                                {errors.inputs && typeof errors.inputs.message === 'string' && (
                                    <Text FontFamily="Nunito" FontSize="0.75rem" Color="#FF6357">
                                        {errors.inputs.message}
                                    </Text>
                                )}
                                {error && <ErrorUser />}
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex>
                            <ButtonGeneral
                                FontSize="1rem"
                                padding="8px 16px"
                                height="40px"
                                width="100%"
                                text="Verificar"
                            />
                        </ContainerFlex>
                    </ContainerForm>
                    <ContainerFlex>
                        {timer > 0 && (
                            <Text
                                FontFamily="Nunito"
                                FontSize="16px"
                                Color="#2A2C2F"
                                FontWeight="600"
                            >
                                {EMAIL_VERIFICATION.TIMER_TEXT} {timer} seg
                            </Text>
                        )}
                        {showReset && (
                            <Text
                                Cursor="pointer"
                                FontSize="16px"
                                Color="#5A5AFF"
                                FontWeight="600"
                                onClick={handleReset}
                                FontFamily="Nunito"
                            >
                                {EMAIL_VERIFICATION.REQUEST_NEW_CODE}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <TermAndConditions />
        </ContainerFlex>
    );
};
