import {
    UPDATE_LINK_EXPIRED,
    UPDATE_LINK_EXPIRED_ERROR,
    UPDATE_LINK_EXPIRED_SUCCESS,
} from '@components/Login/Redux/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    data: false,
    loading: false,
    error: false,
    success: false,
};

const updateStatusLink = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case UPDATE_LINK_EXPIRED:
            return {...state, loading: true};
        case UPDATE_LINK_EXPIRED_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                success: true,
            };
        case UPDATE_LINK_EXPIRED_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default updateStatusLink;
