import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {AFFECTED_USERS} from '@Roles/CreateRoles/Redux/Types';

export const affectedUserStart = () => ({type: AFFECTED_USERS.START_ROLES});
export const affectedUserSuccess = (result: {data: AxiosResponse}) => ({
    type: AFFECTED_USERS.SUCCESS_ROLES,
    affectedUsers: result.data,
});
export const affectedUserError = (error: AxiosError) => ({
    type: AFFECTED_USERS.ERROR_ROLES,
    error: error,
});
export const affectedUser = (
    token: string,
    filters: {roleId: number},
    setShowUserAssignModal: (value: React.SetStateAction<boolean>) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(affectedUserStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAffectedUsers}${queryString}`,
                {headers}
            );
            dispatch(affectedUserSuccess(response.data));
            setShowUserAssignModal(true);
        } catch (error) {
            dispatch(affectedUserError(error as AxiosError));
        }
    };
};
