import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_ERROR,
    GET_PROMOTIONS_TOTALITEMS,
    GET_PROMOTIONS_TOTALITEMS_SUCCESS,
    GET_PROMOTIONS_TOTALITEMS_ERROR,
    ADD_PROMOTIONS_FILTER,
    RESET_PROMOTION_TABLE,
} from '@TypesQouter/Types';
import {AxiosResponse} from 'axios';
import {LoanObject} from '@components/Quoter/inferfaces';

const initialState = {
    error: false,
    loading: false,
    promotions: [],
    totalItems: {},
};

const getPromotionsQuoter = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse; data: LoanObject[]}
) => {
    switch (actions.type) {
        case GET_PROMOTIONS:
            return {...state, loading: true};
        case GET_PROMOTIONS_SUCCESS:
            return {...state, loading: false, promotions: actions.payload.data.data};
        case GET_PROMOTIONS_ERROR:
            return {...state, error: true, loading: false};
        case GET_PROMOTIONS_TOTALITEMS:
            return {...state, loading: true};
        case GET_PROMOTIONS_TOTALITEMS_SUCCESS:
            return {...state, loading: false, totalItems: actions.payload.data.totalItems};
        case GET_PROMOTIONS_TOTALITEMS_ERROR:
            return {...state, error: true, loading: false};
        case ADD_PROMOTIONS_FILTER:
            return {...state, loading: false, promotions: actions.data};
        case RESET_PROMOTION_TABLE:
            return initialState;
        default:
            return state;
    }
};
export default getPromotionsQuoter;
