import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import Logo from '@images/Multiapoyo.svg';
import {ImageLogo} from '@LoginClient/styles';
import successContact from '@images/successContact.svg';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {fetchPostCreateProblemsReset} from '@components/LoginClient/Redux/Actions/CreateProblems';
import {PrivacityContainer} from '@/components/LoginClient/SubContainers/PrivacityContainer';

export const ContactSuccess = ({idMessage}: {idMessage: number}) => {
    const dispatch: AppDispatch = useDispatch();
    const message = (typeMessage: number) => {
        const message: {
            [key: number]: string;
        } = {
            1: 'Hemos enviado solicitud, espera a que tu administrador se ponga en contacto contigo.',
            2: '¡Hemos enviado tu solicitud de recuperación de contraseña! Por favor, espera a que el administrador se ponga en contacto contigo, para brindarte la asistencia necesaria en el proceso de recuperación de contraseña.',
            3: 'Hemos enviado solicitud, espera a que tu administrador se ponga en contacto contigo.',
        };
        return message[typeMessage];
    };
    useEffect(() => {
        return () => {
            dispatch(fetchPostCreateProblemsReset());
        };
    }, []);
    return (
        <ContainerFlex FlexDir="column" Height="100vh" Padding="24px 0 0 0" Justify="space-between">
            <ImageLogo src={Logo} />
            <ImgLocation Height="300px" Width="500px" src={successContact} />
            <Text
                Width="530px"
                TextAlign="center"
                Height="auto"
                LHeight="normal"
                wSpace="normal"
                Color="#414042"
                FontSize="1rem"
            >
                {message(idMessage)}
            </Text>
            <PrivacityContainer />
        </ContainerFlex>
    );
};
