import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import documentsIcon from '@/components/CreditsAndPawns/icons/documentsIcon.svg';
import checkIcon from '@/components/CreditsAndPawns/icons/checkIcon.svg';
import {ICustomerInfoVoucherProps} from '@/components/CreditsAndPawns/interfaces';

const CustomerInfoModalVoucher: React.FC<ICustomerInfoVoucherProps> = ({
    url,
    name,
    size,
    extension,
}) => {
    const handlerDownloadVoucher = () => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            PaddingR="16px"
            Gap="16px"
            Border="1px solid #E8E9EA"
            Radius="16px"
            Width="100%"
            Height="56px"
        >
            <ContainerFlex
                backG="#FAFAFA"
                Justify="center"
                Align="center"
                Height="55px"
                Width="55px"
                Padding="16px"
                Radius="16px 0px 0px 16px"
                onClick={handlerDownloadVoucher}
                Cursor="pointer"
            >
                <Image src={documentsIcon} width="24px" height="24px" />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Width="100%" Gap="4px" Padding="8px 0px 4px 0px">
                <ContainerFlex Align="center" Justify="start">
                    <Text
                        FontSize="0.88rem"
                        FontWeight="400"
                        Color="#2A2C2F"
                        FontFamily="Nunito"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${name}`}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="center" Justify="start">
                    <Image src={checkIcon} width="16px" height="16px" />
                    <Text
                        FontSize="0.75rem"
                        FontWeight="400"
                        Color="#54575C"
                        FontFamily="Nunito"
                        FontStyle="normal"
                    >
                        {`${size} MB`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CustomerInfoModalVoucher;
