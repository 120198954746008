import React, {useState} from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    Branch,
    TreeProps,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';
import {
    BRANCH_ASSIGNMENT,
    branchHierarchy,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {
    TreeContainer,
    Option,
    Img,
    ScrollableOptionsContainer,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/style';
import arrow from '@images/ArrowLeftIcone.svg';
import topArrow from '@images/iconeTopArrow.svg';

const Tree = ({data}: TreeProps) => {
    const [selectedOptions, setSelectedOptions] = useState<(string | null)[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<boolean[]>([true]);

    const handleSelect = (level: number, option: string) => {
        const updatedSelections = [...selectedOptions];
        updatedSelections[level] = option;
        updatedSelections.splice(level + 1);
        setSelectedOptions(updatedSelections);

        setVisibleColumns((prev) => {
            const updatedVisibility = [...prev];
            updatedVisibility[level + 1] = true;
            return updatedVisibility;
        });
    };
    const toggleColumnVisibility = (level: number) => {
        if (level === 0) {
            setVisibleColumns([true]);
            setSelectedOptions([]);
        } else {
            setVisibleColumns((prev) => {
                const updatedVisibility = [...prev];
                updatedVisibility[level] = !updatedVisibility[level];
                updatedVisibility.fill(false, level + 1);
                return updatedVisibility;
            });
        }
    };
    const renderColumn = (options: Branch[], level: number) => {
        if (level !== 0 && !visibleColumns[level]) return null;

        const previousSelection =
            level === 0 ? options[0]?.companyName : selectedOptions[level - 1];

        return (
            <ContainerFlex Align="start" FlexDir="column" key={level}>
                {level === 0 ? (
                    <Text FontWeight="700" FontSize="14px">
                        {options[0]?.companyName}
                    </Text>
                ) : (
                    <Text FontWeight="700" FontSize="14px">
                        {previousSelection}
                    </Text>
                )}
                <ContainerFlex
                    Justify="start"
                    Padding="8px"
                    Gap="8px"
                    onClick={() => toggleColumnVisibility(level)}
                >
                    <Img height="24px" width="24px" src={arrow} alt="icone-Arrow" />
                    <Text FontWeight="700" Color="#5A5AFF" Cursor="pointer">
                        {level === 0
                            ? BRANCH_ASSIGNMENT.TYPE_ASSIGNMENT
                            : BRANCH_ASSIGNMENT.TYPE_ASSIGNMENT}
                    </Text>
                </ContainerFlex>
                <ScrollableOptionsContainer>
                    {options.map((option) => (
                        <Option
                            key={option.name}
                            isSelected={selectedOptions[level] === option.name}
                        >
                            <ContainerCellGrid>
                                <ContainerFlex Gap="8px">
                                    <Checkbox onChange={() => handleSelect(level, option.name)} />
                                    <ContainerFlex Align="start" FlexDir="column" PaddingL="8px">
                                        <Text Cursor="pointer"> {option.name}</Text>

                                        {option.children && option.children.length > 0 && (
                                            <Text Color="#71767D" FontSize="12px">
                                                {' '}
                                                {`${option.children.length} ${BRANCH_ASSIGNMENT.ELEMENT}`}
                                            </Text>
                                        )}
                                        {(!option.children || option.children.length === 0) &&
                                            level > 0 && (
                                                <Text Color="#71767D" FontSize="12px">
                                                    {option.type}
                                                </Text>
                                            )}
                                    </ContainerFlex>
                                    {option.children &&
                                        option.children.length > 0 &&
                                        selectedOptions[level] !== option.name && (
                                            <Img
                                                height="18px"
                                                width="18px"
                                                src={topArrow}
                                                alt="icone-topArrow"
                                            />
                                        )}
                                </ContainerFlex>
                            </ContainerCellGrid>
                        </Option>
                    ))}
                </ScrollableOptionsContainer>
            </ContainerFlex>
        );
    };

    const getChildren = (options: Branch[], level: number): Branch[] => {
        let selectedOption = options;
        for (let i = 0; i <= level; i++) {
            selectedOption =
                selectedOption.find((option) => option.name === selectedOptions[i])?.children || [];
        }
        return selectedOption;
    };

    return (
        <TreeContainer>
            {renderColumn(data, 0)}
            {selectedOptions.map((_, index) => {
                const children = getChildren(data, index);
                return children.length > 0 && renderColumn(children, index + 1);
            })}
        </TreeContainer>
    );
};
export const BranchAssignmentTree = () => {
    return (
        <ContainerFlex Justify="start">
            <Tree data={branchHierarchy} />
        </ContainerFlex>
    );
};
