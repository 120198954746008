import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorMessage} from '@hookform/error-message';
import {FieldValues, useForm} from 'react-hook-form';
import {DateTime} from 'luxon';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {RootState, AppDispatch} from '@config/store';

import {HeadTableRecolectionGold} from '@RecolectionGold/HeadTableRecolectionGold';
import {
    getAuctions,
    getAuditAuctions,
    getImagesAuctions,
    InfoAuditIncident,
    postAuditIncidents,
} from '@ActionsAuction/AuctionActions';
import {HeadTableAuctions} from '@Auction/HeadTableAuctions';
import {RowAuctionTable} from '@Auction/RowAuctionTable';
import {AuctionsInformation} from '@Auction/AuctionsInformation';
import {RowAuditActions} from '@Auction/RowAuditAuctions';
import {
    ArrowAuction,
    Card,
    ColumnsAuction,
    ContainerAuction,
    DivButton,
    DivFormAuction,
    SubContainer,
    SubContainerSecond,
    TextArea,
} from '@Auction/styles';
import {Table} from '@DeadLines/styles';

export function Auction(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const params = useParams();
    const idsku = '1';
    const base = 'data:image/jpeg;base64,';
    const [calculate, setcalculate] = useState(false);
    const [loadingbutton, setloadingbutton] = useState(false);
    const {
        handleSubmit,
        register,
        getValues,
        watch,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            pesoTotal: '',
            pesoAccesorios: '',
            pesoMetal: '',
            diferenciaPesoMetal: '',
            precioVenta: '',
            costo: '',
            avaluo: '',
            estadoConservacion: '',
            incidencia: '',
            incidencias: '',
            montoARetener: '',
            kilataje: 0,
            observaciones: '',
            idTipoIncidencia: 'vacio',
        },
    });
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const watchAllFields = watch();
    const history = useNavigate();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const auctions = useSelector((state: RootState) => {
        return state.auctions.auctions;
    });
    const imagesauctions = useSelector((state: RootState) => {
        return state.auctions.auctionsimages;
    });
    const auctionsaudit = useSelector((state: RootState) => {
        return state.auctions.auctionsaudit;
    });
    const assignmentvalidation = useSelector((state: RootState) => {
        return state.assignmentvalidation.assignmentvalidation;
    });
    useEffect((): void => {
        if (token) {
            const getAuditGold = () => dispatch(getAuctions(idsku, token));
            getAuditGold();
            const getImagesAudit = () => dispatch(getImagesAuctions(idsku, token));
            getImagesAudit();
        }
    }, []);
    useEffect((): void => {
        if (auctionsaudit) {
            reset({
                pesoTotal: auctionsaudit.pesoTotal,
                pesoAccesorios: auctionsaudit.pesoAccesorios,
                kilataje: auctionsaudit.kilataje,
                pesoMetal: auctionsaudit.pesoMetal,
                diferenciaPesoMetal: auctionsaudit.diferenciaPesoMetal,
                precioVenta: formatterDolar.format(auctionsaudit.precioVenta),
                costo: formatterDolar.format(auctionsaudit.costo),
                avaluo: formatterDolar.format(auctionsaudit.avaluo),
                estadoConservacion: auctionsaudit.estadoConservacion,
                incidencia: auctionsaudit.tieneIncidencia === true ? 'SI' : 'NO',
                idTipoIncidencia:
                    auctionsaudit.tieneIncidencia === true
                        ? auctionsaudit.incidencias[0].value
                        : auctionsaudit.incidencias,
                montoARetener: formatterDolar.format(auctionsaudit.montoARetener),
            });
        } else {
            return;
        }
    }, [auctionsaudit]);
    const onSubmit = async (values: FieldValues) => {
        setloadingbutton(true);
        const filters = {
            pesoTotal: values.pesoTotal,
            pesoAccesorios: values.pesoAccesorios,
            kilataje: values.kilataje,
            sku: idsku,
        };
        await dispatch(getAuditAuctions(filters, token));
        setcalculate(true);
        setloadingbutton(false);
    };

    const incidencia = (value: FieldValues) => {
        if (value.incidencia === 'SI') {
            const json = {
                idSucursal: assignmentvalidation.idSucursal,
                idAsignacion: assignmentvalidation.idAsignacion,
                sku: idsku,
                pesoTotal: Number(value.pesoTotal),
                pesoAccesorios: Number(value.pesoAccesorios),
                kilataje: Number(value.kilataje),
                incidencia: value.incidencia === 'SI' ? true : false,
                idTipoIncidencia: Number(value.idTipoIncidencia),
                montoARetener: Number(value.montoARetener.replace(/[^0-9.]+/g, '')),
                observaciones: value.observaciones,
                ahora: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                incidencias: auctionsaudit.incidencias,
            };
            dispatch(InfoAuditIncident(json));
            history(`Incidencia/${params.sku}`);
        } else {
            const json = {
                idSucursal: assignmentvalidation.idSucursal,
                idAsignacion: assignmentvalidation.idAsignacion,
                sku: idsku,
                pesoTotal: Number(value.pesoTotal),
                pesoAccesorios: Number(value.pesoAccesorios),
                kilataje: Number(value.kilataje),
                observaciones: value.observaciones,
                ahora: DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"),
            };
            dispatch(postAuditIncidents(json, token));
        }
    };

    return (
        <ContainerAuction>
            <ArrowAuction>
                <span>Fundiciones</span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>Sucursal</span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>
                    <Link to={`/Fundiciones/Auditoria`}>Verificación de Datos</Link>
                </span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>
                    <Link
                        to={`/Fundiciones/Recoleccion/${assignmentvalidation.idSucursal}/${assignmentvalidation.idAsignacion}`}
                    >
                        Recolección de Oro
                    </Link>
                </span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>SKU {params.sku}</span>
            </ArrowAuction>
            <SubContainer>
                <Card>
                    <span>Almoneda</span>
                    <Table>
                        <HeadTableRecolectionGold />
                        <RowAuctionTable {...auctions} />
                    </Table>

                    {imagesauctions.length > 0 ? (
                        imagesauctions.map(
                            (
                                assignments: JSX.IntrinsicAttributes & {array: []} & {index: number}
                            ) => (
                                <img
                                    className="image-carousel"
                                    key={assignments.index}
                                    src={[base, assignments.array].join('')}
                                />
                            )
                        )
                    ) : (
                        <></>
                    )}
                    <AuctionsInformation {...auctions} />
                </Card>
                <SubContainerSecond>
                    <span>Ingresa los datos arrojados por tu auditoría en la tabla.</span>
                    <Table>
                        <HeadTableAuctions />
                    </Table>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DivFormAuction>
                            <RowAuditActions {...auctions} />
                            <ColumnsAuction>
                                <div>
                                    <input
                                        {...register('pesoTotal', {
                                            required: 'Required',
                                        })}
                                    />
                                    <ErrorMessage errors={errors} name="pesoTotal" />
                                </div>
                                <div>
                                    <input
                                        {...register('pesoAccesorios', {
                                            required: 'Required',
                                        })}
                                        disabled={false}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        className="btn-aqui-here"
                                        name="pesoAccesorios"
                                        message="This is required"
                                        as="p"
                                    />
                                </div>
                                <div>
                                    <input {...register('pesoMetal', {})} disabled={true} />
                                </div>
                                <div>
                                    <input
                                        {...register('diferenciaPesoMetal', {})}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <select {...register('kilataje')}>
                                        <option value="0">0</option>
                                        <option value="6">6</option>
                                        <option value="8">8</option>
                                        <option value="10">10</option>
                                        <option value="12">12</option>
                                        <option value="14">14</option>
                                        <option value="18">18</option>
                                        <option value="21">21</option>
                                    </select>
                                </div>
                                <div>
                                    <input disabled={true} {...register('precioVenta', {})} />
                                </div>
                                <div>
                                    <input {...register('costo', {})} disabled={true} />
                                </div>
                                <div>
                                    <input {...register('avaluo', {})} disabled={true} />
                                </div>
                                <div>
                                    <input
                                        {...register('estadoConservacion', {})}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <input
                                        {...register('incidencia', {})}
                                        disabled={true}
                                        value={watchAllFields.idTipoIncidencia !== '' ? 'SI' : 'NO'}
                                    />
                                </div>
                                <div>
                                    <select {...register('idTipoIncidencia')}>
                                        {auctionsaudit &&
                                            auctionsaudit.incidencias.map(
                                                (
                                                    incidents: JSX.IntrinsicAttributes & {
                                                        value: number;
                                                        label: string;
                                                    }
                                                ): JSX.Element => (
                                                    <option
                                                        key={incidents.value}
                                                        value={incidents.value}
                                                    >
                                                        {incidents.label}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                                <div>
                                    <input {...register('montoARetener', {})} disabled={true} />
                                </div>
                            </ColumnsAuction>
                        </DivFormAuction>

                        <TextArea>
                            <label>Observaciones</label>
                            <textarea {...register('observaciones', {})} />
                        </TextArea>
                        <DivButton active={loadingbutton}>
                            <button>Validar</button>
                        </DivButton>
                        {auctionsaudit && (
                            <DivButton active={!calculate}>
                                <button
                                    disabled={!calculate}
                                    type="button"
                                    onClick={() =>
                                        incidencia(
                                            getValues([
                                                'montoARetener',
                                                'pesoTotal',
                                                'pesoAccesorios',
                                                'kilataje',
                                                'observaciones',
                                                'incidencia',
                                                'idTipoIncidencia',
                                            ])
                                        )
                                    }
                                >
                                    {auctionsaudit.tieneIncidencia === true
                                        ? 'Crear incidencia'
                                        : 'Guardar'}
                                </button>
                            </DivButton>
                        )}
                    </form>
                </SubContainerSecond>
            </SubContainer>
        </ContainerAuction>
    );
}
