import {
    GET_ASSIGMENT_BY_ID,
    GET_ASSIGMENT_BY_ID_SUCCESS,
    GET_ASSIGMENT_BY_ID_ERROR,
} from '@TypesEditAssignment/EditAssignmentTypes';

const initialState = {
    assignmentbyid: [],
    error: false,
    assignmentid: null,
    loading: false,
};

const assignmentbyid = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_ASSIGMENT_BY_ID:
            return {
                ...state,
                loading: true,
            };
        case GET_ASSIGMENT_BY_ID_SUCCESS:
            return {
                ...state,
                assignmentbyid: action.value,
                loading: false,
                assignmentid: action.value.idSucursal,
            };
        case GET_ASSIGMENT_BY_ID_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};
export default assignmentbyid;
