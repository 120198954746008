import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_FAST_PAYMENT_PL_ERROR,
    GET_FAST_PAYMENT_PL_START,
    GET_FAST_PAYMENT_PL_SUCCESS,
    SELECT_TYPE_ACCESS,
    RESET_FAST_PAYMENT_PL_DATA,
} from '@/components/QuickPayment/Redux/Types/Types';
import {TYPE_ACCESS} from '@/components/QuickPayment/Constants';
import {
    IFastPaymentPersonalReducer,
    ITypeAccess,
    IPersonalCredit,
} from '@/components/QuickPayment/interfaces';

const initialState: IFastPaymentPersonalReducer = {
    data: null,
    loading: false,
    error: false,
    doesExist: true,
    hasPayments: true,
    typeAccessSelected: TYPE_ACCESS[0],
};

const getFastPaymentPersonal = (
    state: IFastPaymentPersonalReducer = initialState,
    action: {type: string; payload: AxiosResponse | ITypeAccess; error?: AxiosError}
): IFastPaymentPersonalReducer => {
    switch (action.type) {
        case GET_FAST_PAYMENT_PL_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_FAST_PAYMENT_PL_SUCCESS:
            return {
                ...state,
                data: getFastPaymentData(action.payload as AxiosResponse),
                doesExist: validateExistence(action.payload as AxiosResponse),
                hasPayments: validatePayments(action.payload as AxiosResponse),
                loading: false,
                error: false,
            };
        case GET_FAST_PAYMENT_PL_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        case SELECT_TYPE_ACCESS:
            return {
                ...initialState,
                typeAccessSelected: action.payload as ITypeAccess,
            };
        case RESET_FAST_PAYMENT_PL_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const validateExistence = (response: AxiosResponse): boolean => {
    const {message, data} = response.data;
    if (!message && data.personalCredit && data.personalCredit.length === 0) return false;

    return true;
};

const validatePayments = (response: AxiosResponse): boolean => {
    const {message, data} = response.data;
    if (message && data.personalCredit && data.personalCredit.length === 0) return false;

    return true;
};

const getFastPaymentData = (response: AxiosResponse): IPersonalCredit[] => {
    const {data} = response.data;
    return data.personalCredit ?? null;
};

export default getFastPaymentPersonal;
