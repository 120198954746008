import {
    GET_ALL_COOWNERS,
    GET_ALL_COOWNERS_SUCCESS,
    GET_ALL_COOWNERS_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    loadingUpdate: false,
    data: [],
    message: null,
};

const getAllCoowner = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_ALL_COOWNERS:
            return {...state, loading: true};
        case GET_ALL_COOWNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                message: action.payload.data.message,
            };
        case GET_ALL_COOWNERS_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default getAllCoowner;
