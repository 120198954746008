import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import ArrowArbolRight from '@images/SideBarMenuAdmin/ArrowArbolRight.svg';
import ArrowArbolUp from '@images/SideBarMenuAdmin/ArrowArbolUp.svg';
import ArrowSmallUp from '@images/SideBarMenuAdmin/ArrowSmallUp.svg';
import {itemsModulesAdmistrator} from '@/components/SideBarMenu/Redux/modulesAdministratorActions';
import {RootState} from '@/config/store';
import {IModulesAdministrator} from '@components/SideBarMenu/interfaces';
import {ISidebarProps} from '@components/SideBarMenu/interfaces';
import {useNavigate} from 'react-router-dom';
import {MenuAdminSettings} from '@/components/SideBarMenu/MenuAdminSettings';
import {IconContainer} from '@/components/Quoter/styles';
import {MENUADMINSETTINGS} from '@components/SideBarMenu/constantsText';

export const MenuAdmin: React.FC<ISidebarProps> = () => {
    const [expandedSubItems, setExpandedSubItems] = useState<string[]>([]);
    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const [clickedItems, setClickedItems] = useState<number | null>(null);
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    const [clickedSubItem, setClickedSubItem] = useState<string | null>(null);
    const [menuSettings, setMenuSettings] = useState(false);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const dispatch = useDispatch();
    useEffect(() => {
        if (token) dispatch(itemsModulesAdmistrator(token));
    }, []);

    const modulesAdministrator: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );

    const navigate = useNavigate();

    const handleItemClick = (index: number) => {
        if (expandedItems.includes(index)) {
            setExpandedItems(expandedItems.filter((item) => item !== index));
        }
        if (clickedItems !== index) {
            setExpandedItems([index, ...expandedItems]);
        }
        setClickedItems(clickedItems === index ? null : index);
        setMenuSettings(false);
    };

    const handleSubItemClick = (index: number, subIndex: number) => {
        const subItemKey = `${index}-${subIndex}`;
        const isExpanded = expandedSubItems.includes(subItemKey);

        setExpandedSubItems((expandedSubItem) =>
            isExpanded
                ? expandedSubItem.filter((item) => item !== subItemKey)
                : [...expandedSubItem, subItemKey]
        );

        setClickedSubItem(clickedSubItem === subItemKey ? null : subItemKey);
        setClickedItems(clickedItems === index ? null : index);
        setMenuSettings(false);
    };

    const clickedSettings = (title: string) => {
        setMenuSettings(!menuSettings);
        setIsMenuCollapsed(!isMenuCollapsed);
    };
    const handleClickClose = () => {
        setMenuSettings(false);
        setIsMenuCollapsed(false);
    };

    const settingSpace = modulesAdministrator?.find((item) => item.downMenuItem);
    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            backG="#FAFAFF"
            Width={!isMenuCollapsed ? '16.5rem' : '3.5rem'}
            OverFlowY="auto"
            OverFlowX="hidden"
        >
            <ContainerFlex
                Height="min-content"
                Padding={!isMenuCollapsed ? '1rem 0rem 1rem 1rem' : '1rem 0.5rem'}
                FlexDir="column"
                Justify={!isMenuCollapsed ? '' : 'start'}
            >
                {modulesAdministrator?.map((item, index) => (
                    <ContainerFlex
                        key={index}
                        FlexDir="column"
                        Height={item === settingSpace && isMenuCollapsed ? '100%' : 'min-content'}
                        Justify="flex-end"
                        PaddingT={item === settingSpace ? '90%' : ''}
                    >
                        <ContainerFlex
                            FlexDir="column"
                            Justify={item === settingSpace && isMenuCollapsed ? 'end' : 'center'}
                        >
                            <ContainerFlex
                                Justify="space-between"
                                Padding={isMenuCollapsed && item.isHamburger ? '0rem' : '0.5rem'}
                                Height={isMenuCollapsed && item.isHamburger ? '0rem' : '2.5rem'}
                                backG={
                                    clickedItems === index && !item.isHamburger
                                        ? '#F0F0FF'
                                        : '#FAFAFF'
                                }
                                Bl={
                                    clickedItems === index && !item.isHamburger
                                        ? ' 1.5px solid  #5A5AFF'
                                        : 'none'
                                }
                            >
                                <ContainerFlex Justify="start" Gap="0.5rem">
                                    {!item.isHamburger &&
                                        item.title !== 'Configuraciones' &&
                                        (clickedItems === index && !item.isHamburger
                                            ? item.cssIconHover && (
                                                  <Image
                                                      alt="item-icon"
                                                      src={item.cssIconHover}
                                                      onClick={() => handleClickClose()}
                                                      Cursor="pointer"
                                                  />
                                              )
                                            : item.cssIcon && (
                                                  <Image
                                                      alt="item-icon"
                                                      src={item.cssIcon}
                                                      onClick={() => handleClickClose()}
                                                      Cursor="pointer"
                                                  />
                                              ))}
                                    {!item.isHamburger &&
                                        item.title === 'Configuraciones' &&
                                        (isMenuCollapsed
                                            ? item.cssIconHover && (
                                                  <Image
                                                      alt="item-icon"
                                                      src={item.cssIconHover}
                                                      Cursor="pointer"
                                                      onClick={() => handleClickClose()}
                                                  />
                                              )
                                            : item.cssIcon && (
                                                  <Image
                                                      alt="item-icon"
                                                      src={item.cssIcon}
                                                      Cursor="pointer"
                                                      onClick={() => handleClickClose()}
                                                  />
                                              ))}

                                    {!isMenuCollapsed && (
                                        <Text
                                            FontSize={item.isHamburger ? ' 0.875rem' : '1rem'}
                                            FontWeight={item.isHamburger ? '700' : '400'}
                                            Color={
                                                clickedItems === index && !item.isHamburger
                                                    ? '#5A5AFF'
                                                    : '#54575C'
                                            }
                                            onClick={
                                                item.title === 'Configuraciones'
                                                    ? () => clickedSettings(item.title)
                                                    : () => {
                                                          navigate(`${item.route}`);
                                                          handleClickClose();
                                                          handleItemClick(index);
                                                      }
                                            }
                                            Cursor="pointer"
                                        >
                                            {item.title}
                                        </Text>
                                    )}
                                </ContainerFlex>

                                {!isMenuCollapsed && item.isHamburger && (
                                    <Image
                                        alt="arrow-small-up"
                                        src={ArrowSmallUp}
                                        onClick={() => handleItemClick(index)}
                                        Cursor="pointer"
                                    />
                                )}

                                {!isMenuCollapsed &&
                                    !expandedItems.includes(index) &&
                                    item.item.length > 0 &&
                                    item.title !== 'Configuraciones' &&
                                    !item.isHamburger && (
                                        <Image src={ArrowArbolRight} alt="arrow-arbol-right" />
                                    )}
                                {expandedItems.includes(index) &&
                                    item.item.length > 0 &&
                                    !item.isHamburger && (
                                        <Image src={ArrowArbolUp} alt="arrow-arbol-up" />
                                    )}
                            </ContainerFlex>

                            {!item.isHamburger && expandedItems.includes(index) && (
                                <ContainerFlex FlexDir="column">
                                    {item.item.map((subItem, subIndex) => (
                                        <ContainerFlex key={subIndex} FlexDir="column">
                                            <ContainerFlex
                                                Justify="start"
                                                Gap="0.5rem"
                                                Height="2.5rem"
                                                Padding="0.5rem 3rem"
                                                HColor="#5A5AFF"
                                                HBackground="#F0F0FF"
                                                HBorderLeft="1.5px solid  #5A5AFF"
                                            >
                                                {item.cssIcon && (
                                                    <Image src={subItem.cssIcon} alt="item-icon" />
                                                )}
                                                <Text
                                                    Cursor="pointer"
                                                    onClick={() => {
                                                        navigate(`${item.route}`);
                                                    }}
                                                >
                                                    {subItem.title}
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    ))}
                                </ContainerFlex>
                            )}

                            {item.isHamburger && !expandedItems.includes(index) && (
                                <ContainerFlex FlexDir="column">
                                    {item.item.map((subItem, subIndex) => (
                                        <ContainerFlex
                                            key={subIndex}
                                            FlexDir="column"
                                            Padding={!isMenuCollapsed ? '0' : '.5rem'}
                                        >
                                            {!isMenuCollapsed ? (
                                                <ContainerFlex
                                                    Justify="space-between"
                                                    Padding="0.5rem"
                                                    Height={isMenuCollapsed ? '0rem' : '2.5rem'}
                                                    backG={
                                                        clickedSubItem === `${index}-${subIndex}`
                                                            ? '#F0F0FF'
                                                            : '#FAFAFF'
                                                    }
                                                    Bl={
                                                        clickedSubItem === `${index}-${subIndex}`
                                                            ? ' 1.5px solid  #5A5AFF'
                                                            : 'none'
                                                    }
                                                    Cursor="pointer"
                                                    onClick={() =>
                                                        handleSubItemClick(index, subIndex)
                                                    }
                                                >
                                                    <ContainerFlex Justify="start" Gap="0.5rem">
                                                        {!isMenuCollapsed &&
                                                            (clickedSubItem ===
                                                            `${index}-${subIndex}`
                                                                ? item.cssIconHover && (
                                                                      <Image
                                                                          alt="item-icon"
                                                                          src={subItem.cssIconHover}
                                                                      />
                                                                  )
                                                                : item.cssIcon && (
                                                                      <Image
                                                                          alt="item-icon"
                                                                          src={subItem.cssIcon}
                                                                      />
                                                                  ))}

                                                        {!isMenuCollapsed && (
                                                            <Text
                                                                Cursor="pointer"
                                                                Color={
                                                                    clickedSubItem ===
                                                                        `${index}-${subIndex}` ||
                                                                    subItem.title ===
                                                                        'Promociones' ||
                                                                    subItem.title ===
                                                                        'Explorar todos'
                                                                        ? '#5A5AFF'
                                                                        : '#54575C'
                                                                }
                                                            >
                                                                {subItem.title}
                                                            </Text>
                                                        )}
                                                    </ContainerFlex>

                                                    {subItem.item.length > 0 && (
                                                        <IconContainer
                                                            Transform={
                                                                expandedSubItems.includes(
                                                                    `${index}-${subIndex}`
                                                                )
                                                                    ? 'rotate(-90deg)'
                                                                    : 'rotate(0deg)'
                                                            }
                                                            color="red"
                                                        >
                                                            <Image
                                                                src={ArrowArbolRight}
                                                                alt="arrow-arbol-right"
                                                            />
                                                        </IconContainer>
                                                    )}
                                                </ContainerFlex>
                                            ) : (
                                                subItem.cssIcon && (
                                                    <Image
                                                        Cursor="pointer"
                                                        onClick={() => handleClickClose()}
                                                        alt="item-icon"
                                                        src={(() => {
                                                            if (
                                                                subItem.title !==
                                                                    MENUADMINSETTINGS.PROMOTIONS &&
                                                                subItem.title !==
                                                                    MENUADMINSETTINGS.EXPLOREALL
                                                            ) {
                                                                return subItem.cssIcon;
                                                            }
                                                        })()}
                                                    />
                                                )
                                            )}

                                            {expandedSubItems.includes(`${index}-${subIndex}`) &&
                                                subItem.item.length > 0 && (
                                                    <ContainerFlex
                                                        FlexDir="column"
                                                        Padding="0.5rem 0rem 0.5rem 3rem"
                                                    >
                                                        {subItem.item.map(
                                                            (subSubItem, subSubIndex) => (
                                                                <ContainerFlex
                                                                    key={subSubIndex}
                                                                    Justify="start"
                                                                    Gap="0.5rem"
                                                                    Height="2.5rem"
                                                                >
                                                                    {subSubItem.cssIcon && (
                                                                        <Image
                                                                            alt="item-icon"
                                                                            src={subSubItem.cssIcon}
                                                                        />
                                                                    )}
                                                                    <Text
                                                                        Cursor="pointer"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `${subSubItem.route}`
                                                                            )
                                                                        }
                                                                    >
                                                                        {subSubItem.title}
                                                                    </Text>
                                                                </ContainerFlex>
                                                            )
                                                        )}
                                                    </ContainerFlex>
                                                )}
                                        </ContainerFlex>
                                    ))}
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            {menuSettings && <MenuAdminSettings />}
        </ContainerFlex>
    );
};
