import {AFFECTED_USERS} from '@Roles/CreateRoles/Redux/Types';
import {IAffetedRoles, IAffetedRolesRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: IAffetedRoles = {
    affectedUsers: [],
    error: false,
    loading: false,
};

export const affectedRoles = (state = initialState, action: IAffetedRolesRedux): IAffetedRoles => {
    switch (action.type) {
        case AFFECTED_USERS.START_ROLES:
            return {...state, loading: true, error: false};
        case AFFECTED_USERS.SUCCESS_ROLES:
            return {
                ...state,
                affectedUsers: action.affectedUsers,
                loading: false,
                error: false,
            };
        case AFFECTED_USERS.ERROR_ROLES:
            return {...state, loading: false, error: false};
        default:
            return state;
    }
};
