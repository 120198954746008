import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@/components/Quoter/styles';
import {ALERT_COOWNER} from '@components/Quoter/CoownerBeneficiary/constants';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const AlertCoownerBeneficiary = () => {
    const getAllCoowner = useSelector((state: RootState) => state.getAllCoowner);
    return (
        <ContainerFlex
            Height="58px"
            Padding="8px 24px"
            Radius="8px"
            ColumnGap="8px"
            backG="#FFF5DB"
            Align="start"
            Justify="space-between"
        >
            <IconSvg Width="20px" height="20px">
                <path
                    d="M0.833008 18.0001L9.99967 2.16675L19.1663 18.0001H0.833008ZM9.99967 15.5001C10.2358 15.5001 10.4337 15.4202 10.5934 15.2605C10.7531 15.1008 10.833 14.9029 10.833 14.6667C10.833 14.4306 10.7531 14.2327 10.5934 14.073C10.4337 13.9133 10.2358 13.8334 9.99967 13.8334C9.76356 13.8334 9.56565 13.9133 9.40592 14.073C9.2462 14.2327 9.16634 14.4306 9.16634 14.6667C9.16634 14.9029 9.2462 15.1008 9.40592 15.2605C9.56565 15.4202 9.76356 15.5001 9.99967 15.5001ZM9.16634 13.0001H10.833V8.83342H9.16634V13.0001Z"
                    fill="#CC9200"
                />
            </IconSvg>
            <ContainerFlex Gap="8px" Align="start" FlexDir="column">
                <Text FontWeight="500" Color="#614500" FontSize="1rem" oFlow="none">
                    {ALERT_COOWNER.Add_Coowner}
                </Text>

                <Text FontWeight="400" Color="#614500" FontSize="0.875rem" oFlow="none">
                    {getAllCoowner?.message}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
