import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import loansBtnIcon from '@/components/PersonalLoans/icons/loansBtaIcon.svg';

const PersonalLoanApprovedHeader = () => {
    return (
        <ContainerFlex
            Flex="1"
            Height="100%"
            Width="100%"
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="8px"
        >
            <ContainerFlex Height="100%" Width="100%" Justify="space-between">
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.5rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.HEADER.TITLE}
                </Text>
                <Image Width="147px" Height="37px" src={loansBtnIcon} />
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
        </ContainerFlex>
    );
};

export default PersonalLoanApprovedHeader;
