import axios from 'axios';
import {
    VERIFY_CODE_NEW_REQUEST_CLIENT,
    VERIFY_CODE_NEW_SUCCESS_CLIENT,
    VERIFY_CODE_NEW_ERROR_CLIENT,
    VERIFY_CODE_RESET_ERROR,
} from '@components/MarketForgottenLogin/Redux/types';

import process from 'process';

import {URL} from '@config/constants/index';

export const PostNewVerifyCodeResquestClient = () => {
    return {
        type: VERIFY_CODE_NEW_REQUEST_CLIENT,
    };
};
export const PostNewVeryfyCodeSuccessClient = (data) => {
    return {
        type: VERIFY_CODE_NEW_SUCCESS_CLIENT,
        payload: data,
    };
};

export const ResetVerifyCode = () => {
    return {
        type: VERIFY_CODE_RESET_ERROR,
    };
};

export const PostNewVeryfyCodeFailureClient = (err) => {
    return {
        type: VERIFY_CODE_NEW_ERROR_CLIENT,
        payload: err,
    };
};

export const NewCodeVerifyUser = (email) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ',
    };

    return async (dispatch) => {
        dispatch(PostNewVerifyCodeResquestClient());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateEmailClient.replace(
                    '{email}',
                    email
                )}`,
                {headers}
            );
            dispatch(PostNewVeryfyCodeSuccessClient(response));
        } catch (error) {
            dispatch(PostNewVeryfyCodeFailureClient(error));
        }
    };
};
