import {INewProdcutDocumentsOptions} from '@/components/PersonalLoans/interfaces';
import {
    GET_DOCUMENTS_PREDIFINED_OPTIONS_START,
    GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS,
    GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR,
    GET_DISBURSEMENT_OPTIONS_START,
    GET_DISBURSEMENT_OPTIONS_SUCCESS,
    GET_DISBURSEMENT_OPTIONS_ERROR,
    ADD_DOCUMENT_NEW_OPTION,
    DELETE_DOCUMENT_NEW_OPTION,
} from '@/components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse, AxiosError} from 'axios';

export const getDocumentsPredefinedOptionsStart = () => {
    return {
        type: GET_DOCUMENTS_PREDIFINED_OPTIONS_START,
    };
};

export const getDocumentsPredefinedOptionsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS,
        payload: result.data,
    };
};

export const getDocumentsPredefinedOptionsError = (error: AxiosError) => {
    return {
        type: GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR,
        error,
    };
};

export const getDisbursementOptionsStart = () => {
    return {
        type: GET_DISBURSEMENT_OPTIONS_START,
    };
};

export const getDisbursementOptionsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DISBURSEMENT_OPTIONS_SUCCESS,
        payload: result.data,
    };
};

export const getDisbursementOptionsError = (error: AxiosError) => {
    return {
        type: GET_DISBURSEMENT_OPTIONS_ERROR,
        error,
    };
};

export const addDocumentNewOption = (newDocumentOption: INewProdcutDocumentsOptions) => {
    return {
        type: ADD_DOCUMENT_NEW_OPTION,
        newDocumentOption,
    };
};

export const deleteDocumentNewOption = (documentId: number) => {
    return {
        type: DELETE_DOCUMENT_NEW_OPTION,
        documentId,
    };
};

export const getDocumentsPredefinedOptions = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getDocumentsPredefinedOptionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatDocuments}`,
                {headers}
            );
            dispatch(getDocumentsPredefinedOptionsSuccess(response));
        } catch (error) {
            dispatch(getDocumentsPredefinedOptionsError(error as AxiosError));
        }
    };
};

export const getDisbursementOptions = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getDisbursementOptionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatDisbursement}`,
                {headers}
            );
            dispatch(getDisbursementOptionsSuccess(response));
        } catch (error) {
            dispatch(getDisbursementOptionsError(error as AxiosError));
        }
    };
};
