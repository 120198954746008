import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ADD_COOWNER_BENEFICIARY,
    ADD_COOWNER_BENEFICIARY_SUCCESS,
    ADD_COOWNER_BENEFICIARY_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IContractByCoowners} from '@Quoter/CoownerBeneficiary/Redux/interfaces';

export const addCoownerBeneficiary = () => {
    return {
        type: ADD_COOWNER_BENEFICIARY,
    };
};
export const addCoownerBeneficiarySuccess = (result: AxiosResponse) => {
    return {
        type: ADD_COOWNER_BENEFICIARY_SUCCESS,
        payload: result,
    };
};
export const addCoownerBeneficiaryError = (error: AxiosError) => {
    return {
        type: ADD_COOWNER_BENEFICIARY_ERROR,
        error: error,
    };
};
export function addCoownerBeneficiarys(token: string, data: IContractByCoowners) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(addCoownerBeneficiary());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddcoownerBeneficiary}`,
                data,
                {headers}
            );
            dispatch(addCoownerBeneficiarySuccess(response));
        } catch (error) {
            dispatch(addCoownerBeneficiaryError(error as AxiosError));
        }
    };
}
