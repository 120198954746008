import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';
import {
    ProofType,
    CivilStatus,
    EconomicActivity,
    Genre,
    IdentificationType,
    MarketingClient,
    ClientFingerprints,
    FingerData,
    FingerKey,
    HANDS,
} from '@ListClient/interfaces';
import {
    IValidateProofAddress,
    IAddProofAddressClient,
    INewClient,
} from '@components/Shopify/ListClient/redux/interfaces';

import {
    ADD_NEW_CLIENT_INFO_SUCCESS,
    CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP,
    ADD_CLIENT_ADDRESS_PROOF,
    GET_PROOFTYPES_SUCCESS,
    GET_PROOFTYPES_ERROR,
    SET_LOADING,
    GET_CIVIL_STATUS_SUCCESS,
    GET_ECONOMIC_ACTIVITY_SUCCESS,
    GET_GENRE_SUCCESS,
    GET_IDENTIFICATION_TYPE_SUCCESS,
    SET_LOADING_CLIENT_INFO,
    GET_MARKETING_CLIENT_SUCCESS,
    SET_CLIENT_FINGERPRINTS,
    ADD_HAND_FINGERPRINT,
    RESET_NEWCLIENTPROCESS,
} from '@ListClient/redux/types/Types';

const addNewCleintSuccess = (id: number) => ({
    type: ADD_NEW_CLIENT_INFO_SUCCESS,
    newClientId: id,
});

export const changeNewClientStep = (step: number) => {
    return {
        type: CHANGE_NEWCLIENTPROCESS_ACTUAL_STEP,
        actualStep: step,
    };
};

export const getTypesSuccess = (data: ProofType[]) => ({
    type: GET_PROOFTYPES_SUCCESS,
    proofTypes: data,
});

export const getTypesError = () => ({
    type: GET_PROOFTYPES_ERROR,
});

export const addAddressProofStart = () => {
    return {
        type: ADD_CLIENT_ADDRESS_PROOF,
    };
};

export const setLoading = (set: boolean) => {
    return {
        type: SET_LOADING,
        payload: set,
    };
};

export const setLoadingClientInfo = (set: boolean) => {
    return {
        type: SET_LOADING_CLIENT_INFO,
        payload: set,
    };
};

export const getCivilStatusSuccess = (data: CivilStatus[]) => ({
    type: GET_CIVIL_STATUS_SUCCESS,
    civilStatus: data,
});

export const getEconomicActivitySuccess = (data: EconomicActivity[]) => ({
    type: GET_ECONOMIC_ACTIVITY_SUCCESS,
    economicActivity: data,
});

export const getGenreSuccess = (data: Genre[]) => ({
    type: GET_GENRE_SUCCESS,
    genres: data,
});

export const getIdentificationTypeSuccess = (data: IdentificationType[]) => ({
    type: GET_IDENTIFICATION_TYPE_SUCCESS,
    identificationTypes: data,
});

export const getMarketingClientSuccess = (data: MarketingClient[]) => ({
    type: GET_MARKETING_CLIENT_SUCCESS,
    marketingClient: data,
});

export const addFingerprint = (data: ClientFingerprints) => {
    return {
        type: SET_CLIENT_FINGERPRINTS,
        fingerprints: data,
    };
};

export const addFingerprintStart = () => {
    return {
        type: ADD_HAND_FINGERPRINT.ADD_FINGERPRINT_START,
    };
};

export const addFingerprintSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_HAND_FINGERPRINT.ADD_FINGERPRINT_SUCCESS,
        payload: result,
    };
};

export const addFingerprintError = (result: string) => {
    return {
        type: ADD_HAND_FINGERPRINT.ADD_FINGERPRINT_ERROR,
        message: result,
    };
};

export const resetNewClientProcess = () => ({
    type: RESET_NEWCLIENTPROCESS,
});

export const addFinger = (fingerdata: FingerData, token: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(addFingerprintStart());
        const handKey = fingerdata.hand === HANDS.LEFT ? 'left' : 'right';
        const fingerKey = fingerdata.finger.toLowerCase() as FingerKey;
        const clientId = fingerdata.clientId;
        try {
            const fingerbase64 = fingerdata.data[handKey]?.[fingerKey]?.imageBase64;
            const fileExtension = fingerdata.data[handKey]?.[fingerKey]?.fileExtension;
            const fingerId = fingerdata.fingerId;
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddFinger}`,
                {
                    fingerbase64,
                    fileExtension,
                    fingerId,
                    clientId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addFingerprintSuccess(response.data));
            dispatch(addFingerprint(fingerdata.data));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(addFingerprintError(err.message));
            throw err;
        }
    };
};

export const getIdentificationTypesList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatDocumentType}`,
                {headers}
            );
            const responseData = response.data.data;
            const proofTypes: ProofType[] = responseData;
            dispatch(getTypesSuccess(proofTypes));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};

export const validateProofAddressClient =
    (data: IValidateProofAddress, token: string) => async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidProofAddressClient}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(setLoading(false));
            return response.data;
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            dispatch(setLoading(false));
            throw err;
        }
    };

export const addProofAddressClient =
    (data: IAddProofAddressClient, token: string) => async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        dispatch(addAddressProofStart());
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddProofAddressClient}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(setLoading(false));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            dispatch(setLoading(false));
            throw err;
        }
    };

export const addNewClientInfo =
    (data: INewClient, token: string) => async (dispatch: AppDispatch) => {
        dispatch(setLoadingClientInfo(true));
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.NewClient}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(setLoadingClientInfo(false));
            dispatch(addNewCleintSuccess(response.data.data));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            dispatch(setLoadingClientInfo(false));
            throw err;
        }
    };

export const getCivilStatusList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllMaritalStatus}`,
                {headers}
            );
            dispatch(getCivilStatusSuccess(response.data.data as CivilStatus[]));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};

export const getEconomicActivityList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllEconomicActivity}`,
                {headers}
            );
            dispatch(getEconomicActivitySuccess(response.data.data as EconomicActivity[]));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};

export const getGenreList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllGender}`,
                {headers}
            );
            dispatch(getGenreSuccess(response.data.data as Genre[]));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};

export const getIdentificationTypeList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllIdentificationType}`,
                {headers}
            );
            dispatch(getIdentificationTypeSuccess(response.data.data as IdentificationType[]));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};

export const getMarketingClientList = (token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllMarketingClient}`,
                {headers}
            );
            dispatch(getMarketingClientSuccess(response.data.data as MarketingClient[]));
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(getTypesError());
            throw err;
        }
    };
};
