import styled from '@emotion/styled';
import {Input} from '@Shopify/Ecommerce/styles';

export const PrefixWrapper = styled.div`
    position: relative;
    left: 1.25rem;
    top: 0;
    width: 0;
    pointer-events: none;
`;

export const StyledInput = styled(Input)<{hasPrefix?: boolean}>`
    padding-left: ${({hasPrefix}) => (hasPrefix ? '2rem' : '1rem')};
`;
