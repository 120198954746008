import styled from '@emotion/styled';
export const InputImage = styled.label`
    height: 40px;
    width: 200px;
    display: flex;
    background-color: #ccc;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #414042;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(167, 169, 172, 0.25);
    outline: none;
    transition: background-color 0.3s;
    justify-content: space-evenly;
    align-items: center;
    color: #414042;
    font-size: 0.875rem;
    &:hover {
        border-color: #35cf44;
        color: #35cf44;
        box-shadow: 0px 4px 4px rgba(53, 207, 68, 0.2);
    }

    input[type='file'] {
        display: none;
    }
`;

export const PreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* position: absolute; */
`;
