import {
    GET_USER_INFORMATION_BY_ID,
    GET_USER_INFORMATION_BY_ID_SUCCESS,
    GET_USER_INFORMATION_BY_ID_ERROR,
} from '@components/ManageUser/Redux/types';

const initialState = {
    data: [],
    error: false,
    loading: false,
};

export const getUserInformation = (state = initialState, action: {type: string; payload: any}) => {
    switch (action.type) {
        case GET_USER_INFORMATION_BY_ID:
            return {...state, loading: true, error: false};
        case GET_USER_INFORMATION_BY_ID_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case GET_USER_INFORMATION_BY_ID_ERROR:
            return {...state, loading: true, error: true};
        default:
            return state;
    }
};

export default getUserInformation;
