import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IconContainer} from '@/components/CashFlow/styles';
import {USER_VALIDATE} from '@/components/MarketAccessLogin/SubModules/Contants';

export const ErrorUser = () => {
    return (
        <ContainerFlex Height="auto" ColumnGap="4px" Align="start" Justify="start" Width="325px">
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <IconContainer
                    FontSize="0.875rem"
                    className="material-icons"
                    Color="#FF6357"
                    Width="auto"
                    Height="auto"
                >
                    {USER_VALIDATE.CLOSE}
                </IconContainer>
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="start" Gap="4px" ColumnGap="4px">
                <Text
                    Color="#FF6357"
                    FontSize="0.75rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                    Display="inline-block"
                >
                    {USER_VALIDATE.VALIDATION}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
