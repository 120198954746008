import {ResponseData} from '@/components/ManageUser/interface';
import {
    ADD_DEADLINE,
    ADD_DEADLINE_SUCCESS,
    ADD_DEADLINE_ERROR,
    GET_DEADLINE_ID,
    GET_DEADLINE_ID_SUCCESS,
    GET_DEADLINE_ID_ERROR,
    ISEDIT,
    ISCOPY,
    DELETEDEALINE,
} from '@ReduxCreateDeadline/types/PromotionsTypes';

const initialState = {
    deadlineId: null,
    dataSuccess: null,
    errorCreate: false,
    edit: false,
    copy: false,
    loading: false,
    error: false,
};

export const getDeadlinedAll = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GET_DEADLINE_ID:
            return {
                ...state,
                deadlineId: null,
                loading: true,
            };
        case ADD_DEADLINE:
            return {
                ...state,
                dataSuccess: null,
                deadlineId: null,
                errorCreate: false,
                error: false,
                loading: true,
            };
        case ADD_DEADLINE_SUCCESS:
            return {
                ...state,
                dataSuccess: action.payload,
                loading: false,
            };
        case ADD_DEADLINE_ERROR:
            return {
                ...state,
                errorCreate: true,
                loading: false,
            };
        case GET_DEADLINE_ID_SUCCESS:
            return {
                ...state,
                deadlineId: action.payload.data,
                loading: false,
            };
        case GET_DEADLINE_ID_ERROR:
            return {
                ...state,
                error: true,
            };
        case DELETEDEALINE:
            return {
                ...state,
                data: [],
            };
        case ISEDIT:
            return {
                ...state,
                edit: action.payload,
            };
        case ISCOPY:
            return {
                ...state,
                copy: action.payload,
            };
        default:
            return state;
    }
};
