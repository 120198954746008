import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_REQUEST_KPI} from '@components/CreditsAndPawns/constants';
import {ICreditRequestKPIProps} from '@components/CreditsAndPawns/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const CreditRequestKPI: React.FC<ICreditRequestKPIProps> = ({
    countContractActives,
    mutualAmount,
    amountToPay,
    earnings,
    loses,
    paymentsMade,
}) => {
    return (
        <ContainerFlex
            Radius="1.5rem"
            Border="1px solid #E8E9EA"
            backG="#FFFFFF"
            Padding=" 16px 0px"
            FlexDir="column"
            Gap="2.25rem"
            Width="100%"
            Height="210px"
        >
            <ContainerFlex
                Gap="0.1rem"
                Justify="start"
                Display="grid"
                GridColumns="repeat(3, 1fr)"
                backG="#E8E9EA"
                Height="49px"
            >
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.CONTRACTS}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#5A5AFF"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {countContractActives}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                    Height="49px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.MUTUAL_AMOUNT}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(mutualAmount)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.TO_PAY}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(amountToPay)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="row" backG="#E8E9EA" Height="1px" Width="605px" />
            <ContainerFlex
                Gap="0.1rem"
                Justify="start"
                Display="grid"
                GridColumns="repeat(3, 1fr)"
                backG="#E8E9EA"
                Height="49px"
            >
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.EARNINGS}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(earnings)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.LOSSES}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(loses)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Padding="0px 24px"
                    backG="#FFFFFF"
                    FlexDir="column"
                    Align="center"
                    Justify="Center"
                    Gap="8px"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.88rem"
                        Color="#54575C"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.05rem"
                    >
                        {`${CREDIT_REQUEST_KPI.PAYMENTS_MADE}`}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        TextAlign="center"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(paymentsMade)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditRequestKPI;
