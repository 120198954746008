import axios, {AxiosResponse} from 'axios';
import {
    USER_INFO_BRANCH,
    USER_INFO_BRANCH_SUCCESS,
    USER_INFO_BRANCH_ERROR,
} from '@components/CloseBranch/redux/types';

import {URL} from '@config/constants/index';
import {Dispatch} from 'redux';

export const getUserBranchInfo = () => {
    return {
        type: USER_INFO_BRANCH,
    };
};
export const getUserBranchInfoSuccess = (result: AxiosResponse) => {
    return {
        type: USER_INFO_BRANCH_SUCCESS,
        payload: result.data,
    };
};
export const getUserBranchInfoError = () => {
    return {
        type: USER_INFO_BRANCH_ERROR,
    };
};
export function GetBranchUserInfo(token: string, BranchId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(getUserBranchInfo());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchClose.replace(
                    '{BranchId}',
                    BranchId
                )}`,
                {
                    headers,
                }
            );
            dispatch(getUserBranchInfoSuccess(response));
        } catch (error) {
            dispatch(getUserBranchInfoError());
        }
    };
}
