import styled from '@emotion/styled';
import {IPropsArrowNav, IPropsContainer, IPropsDivForm} from '@AssignmentFoundry/interfacesStyles';

export const ArrowNav = styled.div<IPropsArrowNav>`
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    margin: auto;

    span:first-child {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0px;
        color: var(--gray-2);
    }
    span:nth-child(0n + 2) > i {
        padding-top: 0.6rem;
    }

    span:last-child {
        font-size: 18px;
        letter-spacing: 0px;
        color: var(--black);
    }
`;
export const Container = styled.div<IPropsContainer>`
    width: ${(props) => (props.w ? props.w : '100%')};
    margin: auto;
    display: ${(props) => props.df && 'flex'};
    padding-top: ${(props) => (props.pt ? props.pt : '')};
`;
export const FormFoundry = styled.form`
    flex-wrap: wrap;
    padding: 0 0 0 0;

    div:first-child {
        display: flex;
    }
`;
export const DivForm = styled.div<IPropsDivForm>`
    display: flex;
    flex-direction: ${(props) => props.column && 'column'};
    width: 100%;
    justify-content: ${(props) => props.jc && 'flex-end'};
    padding-top: ${(props) => props.pt && '1rem'};
    padding-right: ${(props) => props.pr && '1rem'};
    padding-left: ${(props) => props.pl && '1rem'};
    padding-bottom: ${(props) => props.pb && '1rem'};
    padding: ${(props) => props.x && '0 1rem'} ${(props) => props.y && '1rem 0'};

    select:last-child {
        height: 40px;
        width: 100%;
        border: 1px solid var(--gray-3);
        border-radius: 5px;
    }
`;
export const Input = styled.input`
    background: fixed;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray-3);
    padding: 2px 12px;
`;
export const ButtonFoundry = styled.button`
    border: 1px solid var(--black);
    text-transform: uppercase;
    border-radius: 5px;
    width: 160px;
    height: 40px;
    align-items: center;
    background-color: white !important;
    box-shadow: 0px 5px 10px rgb(167 169 172 / 50%);
`;
