import React, {useState} from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import closeIcon from '@components/PersonalLoans/NewLoanProduct/icons/closeIcon.svg';
import {ICountLimitLoansProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {SearchInput} from '@/components/Customer/Styles';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';

export const CountLimitLoansField: React.FC<ICountLimitLoansProps> = ({
    errors,
    register,
    showOptionalFields,
    setShowOptionalFields,
    resetField,
}) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    return (
        <ContainerFlex
            Gap="0.25rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Width="max-content"
        >
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.NO_LOANS_LIMIT}
                <Text Color="#54575C" MarginLeft="0.25rem">
                    {LOAN_DETAILS.FIELDS.OPTIONAL}
                </Text>
            </Text>
            <ContainerFlex Width="max-width" Height="max-content" Justify="start">
                <SearchInput Position="static" Width="335px" GridColumn="90% 10%" widthInput="100%">
                    <Input
                        {...register('countLimitLoans')}
                        type="number"
                        onKeyDown={(e) => {
                            if (e.key === 'e' || e.key === '+' || e.key === '-') {
                                e.preventDefault();
                            }
                        }}
                        Padding="0px"
                    />
                    <ContainerFlex
                        Width="24px"
                        Height="24px"
                        FlexDir="column"
                        Justify="start"
                        Position="relative"
                        onMouseEnter={() => setShowToolTip(true)}
                        onMouseLeave={() => setShowToolTip(false)}
                    >
                        <Image
                            src={questionIcon}
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            alt="tooltip-icon"
                        />
                        {showToolTip && (
                            <ToolTip description={LOAN_DETAILS.TOOLTIPS.COUNT_LIMIT_LOANS} />
                        )}
                    </ContainerFlex>
                </SearchInput>
                <Image
                    src={closeIcon}
                    Cursor="pointer"
                    Width="24px"
                    Height="24px"
                    onClick={() => {
                        setShowOptionalFields({
                            ...showOptionalFields,
                            showNoLoanLimit: false,
                        });
                        resetField('countLimitLoans');
                    }}
                    alt="close-icon"
                />
            </ContainerFlex>
            {errors.countLimitLoans && (
                <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem">
                    <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                    {errors.countLimitLoans.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
