import {AppDispatch} from '@/config/store';
import {
    GET_MODULES_POS,
    GET_MODULES_POS_SUCCESS,
    GET_MODULES_POS_ERROR,
    RESET_MODULES_POS,
} from '@components/Home/HomeEmployee/Redux/types';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';

export const getModulesPos = () => {
    return {
        type: GET_MODULES_POS,
    };
};
export const getModulesPosSuccess = (result: AxiosResponse) => {
    return {
        type: GET_MODULES_POS_SUCCESS,
        payload: result,
    };
};
export const getModulesPosError = (error: AxiosError) => {
    return {
        type: GET_MODULES_POS_ERROR,
        error: error,
    };
};
export const resetModulesPos = () => {
    return {
        type: RESET_MODULES_POS,
    };
};

export function getModulesPosDashboard(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getModulesPos());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDashboardEmployee}`,
                {
                    headers,
                }
            );
            dispatch(getModulesPosSuccess(response));
        } catch (error) {
            dispatch(getModulesPosError(error as AxiosError));
        }
    };
}
