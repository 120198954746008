import {AppDispatch} from '@/config/store';
import {
    GET_HOME_CLIENT,
    GET_HOME_CLIENT_SUCCESS,
    GET_HOME_CLIENT_ERROR,
    GET_HOME_MOVEMENTS,
    GET_HOME_MOVEMENTS_SUCCESS,
    GET_HOME_MOVEMENTS_ERROR,
    GET_HOME_ORDERS,
    GET_FILTER_COUNTRY,
    GET_HOME_ORDERS_SUCCESS,
    GET_HOME_ORDERS_ERROR,
    GET_HOME_FILTER,
    GET_HOME_FILTER_SUCCESS,
    GET_HOME_FILTER_ERROR,
    GET_HOME_STARS,
    GET_HOME_STARS_SUCCESS,
    GET_HOME_STARS_ERROR,
    GET_HOME_FAVS,
    GET_HOME_FAVS_SUCCESS,
    GET_HOME_FAVS_ERROR,
    GET_CARDS,
    GET_CARDS_SUCCESS,
    GET_CARDS_ERROR,
    PUT_CARDS,
    PUT_CARDS_SUCCESS,
    PUT_CARDS_ERROR,
    CLEAN_FILTERS,
    POST_CARDS,
    POST_CARDS_SUCCESS,
    POST_CARDS_ERROR,
    GET_HOME_ENDORSEMENT_START,
    GET_HOME_ENDORSEMENT_SUCCESS,
    GET_HOME_ENDORSEMENT_ERROR,
    RESET_CLIENT,
} from '@TypesEcommerceShop/HomeClientTypes';
import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';
import Swal from 'sweetalert2';

export const fetchGetInfoClient = () => {
    return {
        type: GET_HOME_CLIENT,
    };
};
export const fetchGetInfoClientSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_CLIENT_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetInfoClientError = () => {
    return {
        type: GET_HOME_CLIENT_ERROR,
    };
};
export const fetchFilterCountry = (country: number) => {
    return {
        type: GET_FILTER_COUNTRY,
        country: country,
    };
};
export const fetchCleanFilters = () => {
    return {
        type: CLEAN_FILTERS,
    };
};

export const resetClient = () => {
    return {
        type: RESET_CLIENT,
    };
};
export function getInfoClient(clientId: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetInfoClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInfoClientbyId.replace(
                    '{clientId}',
                    clientId
                )}`,
                {headers}
            );
            dispatch(fetchGetInfoClientSuccess(response));
        } catch (error) {
            dispatch(fetchGetInfoClientError());
        }
    };
}

export const fetchGetHomeOrders = () => {
    return {
        type: GET_HOME_ORDERS,
    };
};
export const fetchGetHomeOrdersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_ORDERS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeOrdersError = () => {
    return {
        type: GET_HOME_ORDERS_ERROR,
    };
};

export function getHomeOrders(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeOrders());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace('admin', 'consumer')}${
                        URL.urlStore.HomeOrders
                    }`,
                    {headers}
                );

                dispatch(fetchGetHomeOrdersSuccess(response));
            } catch (error) {
                dispatch(fetchGetHomeOrdersError());
            }
        }
    };
}

export const fetchGetHomeMovements = () => {
    return {
        type: GET_HOME_MOVEMENTS,
    };
};
export const fetchGetHomeMovementsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_MOVEMENTS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeMovementsError = () => {
    return {
        type: GET_HOME_MOVEMENTS_ERROR,
    };
};

export function getHomeMovements(clientId: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeMovements());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetHomeClient.replace(
                    '{clientId}',
                    clientId
                )}`,
                {headers}
            );

            dispatch(fetchGetHomeMovementsSuccess(response));
        } catch (error) {
            dispatch(fetchGetHomeMovementsError());
        }
    };
}

export const fetchGetHomeFilter = () => {
    return {
        type: GET_HOME_FILTER,
    };
};
export const fetchGetHomeFilterSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_FILTER_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeFilterError = () => {
    return {
        type: GET_HOME_FILTER_ERROR,
    };
};

export function getHomeFilter(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeFilter());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace('admin', 'consumer')}${
                        URL.urlStore.HomeFilter
                    }`,
                    {headers}
                );
                dispatch(fetchGetHomeFilterSuccess(response));
            } catch (error) {
                dispatch(fetchGetHomeFilterError());
            }
        }
    };
}

export const fetchGetHomeStars = () => {
    return {
        type: GET_HOME_STARS,
    };
};
export const fetchGetHomeStarsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_STARS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeStarsError = () => {
    return {
        type: GET_HOME_STARS_ERROR,
    };
};

export function getHomeStars(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeStars());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace('admin', 'consumer')}${
                        URL.urlStore.HomeStars
                    }`,
                    {headers}
                );

                dispatch(fetchGetHomeStarsSuccess(response));
            } catch (error) {
                dispatch(fetchGetHomeStarsError());
            }
        }
    };
}

export const fetchGetHomeFavs = () => {
    return {
        type: GET_HOME_FAVS,
    };
};
export const fetchGetHomeFavsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_FAVS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeFavsError = () => {
    return {
        type: GET_HOME_FAVS_ERROR,
    };
};

export function getHomeFavs(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeFavs());
        if (process.env.REACT_APP_SERVICE_STORE) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVICE_STORE.replace('admin', 'consumer')}${
                        URL.urlStore.HomeFavs
                    }`,
                    {headers}
                );

                dispatch(fetchGetHomeFavsSuccess(response));
            } catch (error) {
                dispatch(fetchGetHomeFavsError());
            }
        }
    };
}

export const fetchGetCards = () => {
    return {
        type: GET_CARDS,
    };
};
export const fetchGetCardsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CARDS_SUCCESS,
        value: result.data,
    };
};
export const fetchGetCardsError = () => {
    return {
        type: GET_CARDS_ERROR,
    };
};

export function getCards(token: string, clientId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCards());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDetailCard.replace(
                    '{clientId}',
                    clientId
                )}`,
                {headers}
            );

            dispatch(fetchGetCardsSuccess(response));
        } catch (error) {
            dispatch(fetchGetCardsError());
        }
    };
}

export const fetchPutCards = () => {
    return {
        type: PUT_CARDS,
    };
};
export const fetchPutCardsSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_CARDS_SUCCESS,
        value: result.data,
    };
};
export const fetchPutCardsError = () => {
    return {
        type: PUT_CARDS_ERROR,
    };
};

export const fetchPostCards = () => {
    return {
        type: POST_CARDS,
    };
};
export const fetchPostCardsSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CARDS_SUCCESS,
        value: result.data,
    };
};
export const fetchPostCardsError = () => {
    return {
        type: POST_CARDS_ERROR,
    };
};

export function postCards(
    token: string,
    data: {
        clientId: number;
        nameOwner: string;
        cardNumber: string;
        cvc: number;
        bank: number;
        expMonth: number;
        expYear: number;
    }
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostCards());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PaymentCard}`,
                data,
                {headers}
            );

            dispatch(fetchPostCardsSuccess(response.data));
        } catch (error) {
            Swal.fire({
                icon: 'error',
                confirmButtonText: 'Continuar',
            });
            dispatch(fetchPostCardsError());
        }
    };
}

export const fetchGetHomeEndorsementStart = () => {
    return {
        type: GET_HOME_ENDORSEMENT_START,
    };
};
export const fetchGetHomeEndorsementSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HOME_ENDORSEMENT_SUCCESS,
        value: result.data,
    };
};
export const fetchGetHomeEndorsementError = () => {
    return {
        type: GET_HOME_ENDORSEMENT_ERROR,
    };
};

export function GetHomeEndorsement(nuc: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetHomeEndorsementStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.InfoClientByNUC.replace(
                    '{NUC}',
                    nuc
                )}`,
                {headers}
            );

            dispatch(fetchGetHomeEndorsementSuccess(response));
        } catch (error) {
            dispatch(fetchGetHomeEndorsementError());
        }
    };
}
