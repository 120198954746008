import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import * as style from '@/components/Quoter/stylesConstants';
import {APPRASIAL} from '@components/Quoter/constants';
interface ITabs {
    setHistory: (e: boolean) => void;
}
export const Tabs = ({setHistory}: ITabs) => {
    return (
        <ContainerFlex Justify="space-between" Height="39px">
            <Text Color="#54575C" {...style.tabsBlock}>
                {APPRASIAL.MY_COMPANY}
            </Text>
            <Text
                Color="#5A5AFF"
                FontSize="0.75rem"
                FontWeight="500"
                onClick={() => setHistory(true)}
                Cursor="pointer"
            >
                {APPRASIAL.HISTORY}
            </Text>
        </ContainerFlex>
    );
};
