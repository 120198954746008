import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    PUT_PROMOTION,
    PUT_PROMOTION_ERROR,
    PUT_PROMOTION_SUCCESS,
} from '@/components/Shopify/Products/redux/types/ProductsShopifyTypes';
import {PawnsData} from '@Quoter/Redux/intefaces';

export const putPromotion = () => {
    return {
        type: PUT_PROMOTION,
    };
};
export const putPromotionSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_PROMOTION_SUCCESS,
        payload: result,
    };
};
export const putPromotionError = () => {
    return {
        type: PUT_PROMOTION_ERROR,
    };
};

export function PutPromotionsStep(
    data: PawnsData,
    token: string,
    changeActualStep: (e: number) => void
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(putPromotion());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutPromotions}`,
                data,
                {headers}
            );
            dispatch(putPromotionSuccess(response.data));
            dispatch(changeActualStep(3));
        } catch (error) {
            dispatch(putPromotionError());
        }
    };
}
