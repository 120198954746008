import styled from '@emotion/styled';
export const ContainerDelivery = styled.div`
    width: 90%;
    margin: auto;

    table {
        margin-top: 1.5rem !important;
        width: 100%;
        margin: auto;
    }
`;
export const DivLink = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        text-align: left;
        font: normal normal normal 14px/16px Work Sans;
        letter-spacing: 0px;
        color: #4a4a4a;
        opacity: 1;
    }
    button {
        width: 200px !important;
        border: 1px solid var(--black);
        text-transform: uppercase;
        border-radius: 5px;
        width: 160px;
        height: 40px;
        align-items: center;
        background-color: white !important;
        box-shadow: 0px 5px 10px rgb(167 169 172 / 50%);
    }
`;
export const FormDelivery = styled.form`
    margin-top: 3rem;
    flex-wrap: wrap;
    padding: 0 0 0 0;
`;
export const DivFlex = styled.div`
    display: flex;
    padding-top: 3rem;
    input {
        background: fixed;
        height: 40px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid var(--gray-3);
        padding: 2px 12px;
    }
    & > div:first-child {
        width: 50%;
    }
    & > div:last-child {
        display: flex;
        width: 100%;
        padding-left: 1rem;
        flex-direction: row;
    }
`;
export const DivInputsDelivery = styled.div`
    width: 100%;
    margin-bottom: 1.5rem;
`;
export const ButtonImageUmpload = styled.div`
    border-radius: 5px;
    background-color: white !important;
    display: flex;
    padding: 5px;
    border: 0.5px solid var(--gray-1);
    align-items: center;
`;
export const Images = styled.div`
    margin-top: 1rem;
    position: relative;
`;
