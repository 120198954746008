import axios, {AxiosResponse} from 'axios';
import {
    GET_SERVICES_COMPLEMENTS,
    GET_SERVICES_COMPLEMENTS_ERROR,
    GET_SERVICES_COMPLEMENTS_FINISH,
    GET_SERVICES_COMPLEMENTS_RESET,
    GET_SERVICES_COMPLEMENTS_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetServicesComplementsStart = () => {
    return {
        type: GET_SERVICES_COMPLEMENTS,
    };
};
export const fetchGetServicesComplementsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_SERVICES_COMPLEMENTS_SUCCESS,
        payload: result,
    };
};
export const fetchGetServicesComplementsError = () => {
    return {
        type: GET_SERVICES_COMPLEMENTS_ERROR,
    };
};
export const fetchGetServicesComplementsFinish = () => {
    return {
        type: GET_SERVICES_COMPLEMENTS_FINISH,
    };
};
export const fetchGetServicesComplementsReset = () => {
    return {
        type: GET_SERVICES_COMPLEMENTS_RESET,
    };
};

export function GetServicesComplements(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetServicesComplementsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetServicesComplements}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetServicesComplementsSuccess(response));
        } catch (error) {
            dispatch(fetchGetServicesComplementsError());
        } finally {
            dispatch(fetchGetServicesComplementsFinish());
        }
    };
}
