import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import arrowSmall from '@images/arrow small.svg';
import {ItemRootProps} from '@components/Catalogue/interfaces';

export const ItemRoot = ({itemRoot}: ItemRootProps) => {
    return (
        <>
            <ContainerFlex Gap="0.5rem" Height="max-content" FlexWrap="wrap">
                {itemRoot.map((level, index) => (
                    <ContainerFlex key={index} Gap="4px" Height="1rem" Width="max-content">
                        <Text
                            Color="#5A5AFF"
                            FontSize="0.75rem"
                            FontWeight="500"
                            FontFamily="Nunito"
                        >
                            {level}
                        </Text>
                        {index !== itemRoot.length - 1 && (
                            <Image src={arrowSmall} Width="16px" Height="16px" />
                        )}
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        </>
    );
};
