import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {EditForm, User} from '@components/LocationsBranch/interfaces';

import {addAddress} from '@components/LocationsBranch/Redux/Action/AddAddressLocation';
import {setAddedUsers} from '@components/LocationsBranch/Redux/Action/AddUser';

export const useInitializeForm = () => {
    const dispatch = useDispatch();
    const {AddUserEmployee} = useSelector((state: RootState) => ({
        AddUserEmployee: state.AddUserEmployee,
    }));

    const initialize = (
        source: EditForm | null,
        nameOf: string,
        setValue: (name: string, value: string) => void
    ) => {
        if (!source) return;

        const {name = '', address = null, users = []} = source || {};

        if (name) setValue(nameOf, name);

        if (users.length) {
            const newUsers =
                users.map((user: User) => ({
                    employeeId: user.usersId,
                    imageURL: user.imageUrl,
                    name: user.userName,
                    role: user.rolName,
                    dateSession: new Date(user.lastLogin),
                    active: user.status,
                })) || [];

            dispatch(setAddedUsers([...AddUserEmployee.addedUsers, ...newUsers]));
        }

        if (address) {
            const {
                state = '',
                stateId = '',
                municipal = '',
                municipalId = '',
                colony = '',
                colonyId = '',
                interiorNumber = '',
                outerNumber = '',
                street = '',
                postalCode = '',
            } = address;
            const newAdress = {
                state: {label: state, value: stateId.toString()},
                municipaly: {label: municipal, value: municipalId.toString()},
                colony: {label: colony, value: colonyId.toString()},
                interNum: interiorNumber,
                outerNum: outerNumber,
                street,
                postalCode,
            };
            dispatch(addAddress(newAdress));
        }
    };

    return {initialize};
};
