import {Option} from '@components/Users/UserProfile/RoleSettings/constants';
import {ICatalogItems} from './interfaces';

export const AuctionPassText = {
    AuctionPassTitle: 'Pase Almoneda',
    AuctionPassDescription:
        'Define el porcentaje de comercialización y determina si el producto en cuestión necesita días de pausa para revisión previa a la venta',
    RulesTitle: 'Reglas',
    CatalogColumn: 'Catalogo',
    CommercializationColumn: 'Incremento % comercialización sobre',
    PercentageColumn: '% Porcentaje',
    DaysOfPauseColumn: 'Dias de pausa',
    EditIconAltText: 'Editar icono',
};

export const toolTipText = {
    title: 'Dias de pausa',
    text: 'Determina si el producto requiere un período de revisión antes de su lanzamiento al mercado.',
};

export const catalogItems: ICatalogItems[] = [
    {
        id: 1,
        catalog: 'Electrónica',
        commercialization: 'Avalúo',
        percentage: '12%',
        daysOfPause: '2 día(s)',
        children: [
            {
                id: 11,
                catalog: 'Celulares',
                commercialization: 'Avalúo',
                percentage: '8%',
                daysOfPause: '1 día(s)',
            },
            {
                id: 12,
                catalog: 'Computadoras',
                commercialization: 'Avalúo',
                percentage: '10%',
                daysOfPause: '2 día(s)',
                children: [
                    {
                        id: 21,
                        catalog: 'Laptops',
                        commercialization: 'Avalúo',
                        percentage: '12%',
                        daysOfPause: '2 día(s)',
                    },
                    {
                        id: 22,
                        catalog: 'Desktops',
                        commercialization: 'Avalúo',
                        percentage: '9%',
                        daysOfPause: '3 día(s)',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        catalog: 'Joyería',
        commercialization: 'Avalúo',
        percentage: '15%',
        daysOfPause: '2 día(s)',
        children: [
            {
                id: 13,
                catalog: 'Anillos',
                commercialization: 'Avalúo',
                percentage: '14%',
                daysOfPause: '2 día(s)',
            },
            {
                id: 14,
                catalog: 'Collares',
                commercialization: 'Avalúo',
                percentage: '16%',
                daysOfPause: '3 día(s)',
            },
        ],
    },
    {
        id: 3,
        catalog: 'Juguetes',
        commercialization: 'Avalúo',
        percentage: '10%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 4,
        catalog: 'Ropa',
        commercialization: 'Avalúo',
        percentage: '18%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 5,
        catalog: 'Electrodomésticos',
        commercialization: 'Avalúo',
        percentage: '14%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 6,
        catalog: 'Muebles',
        commercialization: 'Avalúo',
        percentage: '20%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 7,
        catalog: 'Herramientas',
        commercialization: 'Avalúo',
        percentage: '11%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 8,
        catalog: 'Libros',
        commercialization: 'Avalúo',
        percentage: '5%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 9,
        catalog: 'Deportes',
        commercialization: 'Avalúo',
        percentage: '13%',
        daysOfPause: '2 día(s)',
    },
    {
        id: 10,
        catalog: 'Instrumentos Musicales',
        commercialization: 'Avalúo',
        percentage: '9%',
        daysOfPause: '2 día(s)',
    },
];

export const options: Option[] = [
    {id: 1, label: 'Avalúo'},
    {id: 2, label: 'Préstamos'},
    {id: 3, label: 'Ganancias (interés)'},
    {id: 4, label: 'Perdidas '},
];
