import {SubLevels} from '@SubContainerOrgCreation/interface';
import {
    LEVEL,
    SAVE_LEVEL,
    CHILD,
    PAGE_NUMBER,
    SAVE_LEVEL_NODES,
    SEARCH_ORGANIZATION,
    SAVE_NODE,
} from '@TypesOrgCreation/types';
import {Level} from '@OrganizationCreation/interface';
import {FieldValues} from 'react-hook-form';

export const pageNumber = (pageNumber: number) => {
    return {
        type: PAGE_NUMBER,
        payload: pageNumber,
    };
};

export const saveLevels = (Levels: FieldValues) => {
    return {
        type: SAVE_LEVEL,
        payload: Levels,
    };
};
export const saveLevelNodes = (Levels: Level[]) => {
    return {
        type: SAVE_LEVEL_NODES,
        payload: Levels,
    };
};

export const fetchStateLevel = (levelData: number) => {
    return {
        type: LEVEL,
        payload: levelData,
    };
};
export const hasChilde = (child: boolean) => {
    return {
        type: CHILD,
        payload: child,
    };
};

export const searchData = (searched: string) => {
    return {
        type: SEARCH_ORGANIZATION,
        payload: searched,
    };
};

export const saveNode = (searched: SubLevels) => {
    return {
        type: SAVE_NODE,
        payload: searched,
    };
};
