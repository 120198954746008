import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FieldValues, useForm} from 'react-hook-form';
import {RootState, AppDispatch} from '@config/store';
import {DateTime} from 'luxon';

import {
    getSecurityBagWeight,
    getIdentifyFoundry,
    getSecurityBagWeightReview,
} from '@ActionsSecurityBagWeight/SecurityBagWeightActions';
import ReactPaginate from 'react-paginate';
import {HeadTableSecurityBagWeight} from '@SecurityBagWeight/HeadTableSecurityBagWeight';
import {RowSecurityBagWeight} from '@SecurityBagWeight/RowSecurityBagWeight';
import {RowSecurityBagReview} from '@SecurityBagWeight/RowSecurityBagReview';
import {Table} from '@DeadLines/styles';

export const SecurityBagWeight = (): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const {handleSubmit, register} = useForm({
        defaultValues: {
            searchsku: '',
            searchskuaudit: '',
        },
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const identify = useSelector((state: RootState) => {
        return state.securitybagweight.identifyfoundry;
    });
    const [filterrecolectiongold, setfilterrecolectiongold] = useState('');
    const [filterauditgold, setfilterauditgold] = useState('');
    useEffect(() => {
        const getIdentify = () =>
            dispatch(getIdentifyFoundry(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"), token));
        getIdentify();
    }, [dispatch, token]);
    useEffect(() => {
        if (Number(identify) > -1) {
            const getSecurityBagTransit = () => dispatch(getSecurityBagWeight('', 10, 0, token));
            getSecurityBagTransit();
            const getSecurityBagReview = () =>
                dispatch(getSecurityBagWeightReview('', 10, 0, identify, token));
            getSecurityBagReview();
        }
    }, [dispatch, identify, token]);

    const handlePageClickHistory = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(getSecurityBagWeight(filterrecolectiongold, 10, selected, token));
    };

    const onSubmit = (values: FieldValues) => {
        setfilterrecolectiongold(values.searchsku);
        dispatch(getSecurityBagWeight(values.searchsku, 10, securitybagweightpage, token));
    };
    const handleUserKeyPress = (e: {key: string; shiftKey: boolean}) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit);
        }
    };

    const handlePageClickHistoryAudit = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(getSecurityBagWeightReview(filterauditgold, 10, selected, identify, token));
    };

    const onSubmitAudit = (values: FieldValues) => {
        setfilterauditgold(values.searchskuaudit);
        dispatch(
            getSecurityBagWeightReview(
                values.searchskuaudit,
                10,
                securitybagsmeltingpage,
                identify,
                token
            )
        );
    };
    const handleUserKeyPressAudit = (e: {key: string; shiftKey: boolean}) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmitAudit);
        }
    };
    const securitybagweight = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagweight;
    });
    const securitybagweightcount = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagweightcount;
    });
    const securitybagweightpage = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagweightPageIndex;
    });
    const securitybagsmelting = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagsmelting;
    });
    const securitybagsmeltingcount = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagsmeltingcount;
    });
    const securitybagsmeltingpage = useSelector((state: RootState) => {
        return state.securitybagweight.securitybagsmeltingPageIndex;
    });

    return (
        <div className="w-90 mx-auto ">
            {Number(identify) !== -1 && identify !== null ? (
                <>
                    <div className="pt-2 pb-3 flex align-items-center w-100 mx-auto">
                        <span className="child-breadcrumb">Fundiciones</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="child-breadcrumb">Fundición</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="principal-breadcrumb">Peso de Seguribolsa</span>
                    </div>
                    <div className="flex justify-content-between">
                        <span>
                            Dar clic en la seguribolsa que deseas pesar para capturar los
                            resultados. Selecciona un kilataje de los obtenidos por el sistema para
                            consultar los detalles de las prendas.
                        </span>
                        <Link to={`/PesoSeguribolsaJiracas`}>
                            {' '}
                            <button className="w-100 pl-5 pr-5 btn-add-incidents h-40 ">
                                SIGUIENTE
                            </button>
                        </Link>
                    </div>
                    <div className="flex mt-5">
                        <div className="w-50 card pt-2 mr-3">
                            <span className="w-100 text-center f-22">Seguribolsas</span>
                            <form
                                className="flex container-form pl-4 mt-5"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <input
                                    className="input-search"
                                    {...register('searchsku', {})}
                                    onKeyPress={handleUserKeyPress}
                                />
                                <button type="submit">
                                    {' '}
                                    <i className="material-icons">search</i>
                                </button>
                            </form>
                            <Table className="mt-4 w-90 mx-auto">
                                <HeadTableSecurityBagWeight />
                                {securitybagweight &&
                                    securitybagweight.map(
                                        (
                                            recolectionitems: JSX.IntrinsicAttributes & {
                                                seguribolsa: number;
                                                papeleta: string;
                                                sucursal: string;
                                                fecha: string;
                                                costo: number;
                                            }
                                        ) => (
                                            <RowSecurityBagWeight
                                                key={recolectionitems.seguribolsa}
                                                {...recolectionitems}
                                            />
                                        )
                                    )}
                            </Table>
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={securitybagweightcount / 10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={securitybagweightpage}
                                onPageChange={handlePageClickHistory}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="w-50 card pt-2 ml-3">
                            <span className="w-100 text-center f-22">Seguribolsas Revisadas</span>

                            <form
                                className="flex container-form pl-4 mt-5"
                                onSubmit={handleSubmit(onSubmitAudit)}
                            >
                                <input
                                    className="input-search"
                                    {...register('searchskuaudit', {})}
                                    onKeyPress={handleUserKeyPressAudit}
                                />
                                <button type="submit">
                                    {' '}
                                    <i className="material-icons">search</i>
                                </button>
                            </form>
                            <Table className="w-92 mx-auto mt-4">
                                <HeadTableSecurityBagWeight />
                                {securitybagsmelting &&
                                    securitybagsmelting.map(
                                        (
                                            recolectionitems: JSX.IntrinsicAttributes & {
                                                seguribolsa: number;
                                                papeleta: string;
                                                sucursal: string;
                                                fecha: string;
                                                costo: number;
                                            }
                                        ) => (
                                            <RowSecurityBagReview
                                                key={recolectionitems.seguribolsa}
                                                {...recolectionitems}
                                            />
                                        )
                                    )}
                            </Table>

                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={securitybagsmeltingcount / 10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={securitybagsmeltingpage}
                                onPageChange={handlePageClickHistoryAudit}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <h2>NO SE TIENE FUNDICIÓN</h2>
            )}
        </div>
    );
};
