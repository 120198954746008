import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import TypeFilter from '@/components/MyAccount/MyCredit/TypeFilter';
import SearchAndFilterSubMenu from '@/components/MyAccount/MyCredit/SearchAndFilterSubMenu';
import ViewFormat from '@/components/MyAccount/MyCredit/ViewFormat';

const CreditFilters = () => {
    return (
        <ContainerFlex Height="48px" Justify="space-between" Align="flex-start">
            <TypeFilter />
            <SearchAndFilterSubMenu />
            <ViewFormat />
        </ContainerFlex>
    );
};

export default CreditFilters;
