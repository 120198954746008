import {
    GET_ROLE_VALIDATE,
    GET_ROLE_VALIDATE_SUCCESS,
    GET_ROLE_VALIDATE_ERROR,
    GET_ROLE_VALIDATE_EDIT,
    GET_ROLE_VALIDATE_EDIT_SUCCEES,
    GET_ROLE_VALIDATE_EDIT_ERROR,
    FILTERS,
    SEARCHED,
    RESET_VALIDATE,
} from '@TypesRoles/types';
import {ISearchRoles, ISearchRolesRedux} from '@Roles/interface';

const initialState: ISearchRoles = {
    roleSearch: false,
    roleSearchEdit: true,
    filters: {
        orderType: 0,
        filterRoles: 0,
    },
    search: '',
    error: false,
    loading: false,
};

const SearchRoleNameValidation = (
    state = initialState,
    action: ISearchRolesRedux
): ISearchRoles => {
    switch (action.type) {
        case RESET_VALIDATE:
            return initialState;
        case SEARCHED:
            return {
                ...state,
                search: action.search,
                loading: true,
            };
        case GET_ROLE_VALIDATE:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLE_VALIDATE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_ROLE_VALIDATE_SUCCESS:
            return {
                ...state,
                roleSearch: action.roleSearch,
                loading: false,
            };
        case GET_ROLE_VALIDATE_EDIT:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLE_VALIDATE_EDIT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_ROLE_VALIDATE_EDIT_SUCCEES:
            return {
                ...state,
                roleSearchEdit: action.roleSearchEdit,
                loading: false,
            };
        case FILTERS:
            return {
                ...state,
                filters: action.filters,
                loading: false,
            };
        default:
            return state;
    }
};
export default SearchRoleNameValidation;
