import React from 'react';
import {Svg} from '@Shopify/Ecommerce/styles';

export const Close = () => {
    return (
        <Svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            hoverF="#35CF44"
        >
            <mask id="twqq551oza" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <path fill="#6D6E71" d="M0 0h24v24H0z" />
            </mask>
            <path
                d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6L6.4 19z"
                fill="#6D6E71"
            />
        </Svg>
    );
};
