import React from 'react';
import {Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {Table} from '@mui/material';
import {EXPENSE_DETAILS} from '@/components/CashFlow/Expenses/ExpenseDetails/constants';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import UnfoldMore from '@/images/unfold_more.svg';
import add from '@/images/addPurple.svg';

import {IPrincipalInfo} from '@CashFlow/Expenses/ExpenseDetails/Types';

import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import {TableItem} from '@/components/DeadLines/styles';
import {Controller} from 'react-hook-form';
import {StyleNumericFormat} from '@/components/Loan/styles';

export const AddItemTable = ({register, errors, field, append, control}: IPrincipalInfo) => {
    return (
        <Table>
            <TableRowHead Height="40px" GridColumn="repeat(5, 1fr)" BackG="none" Border="none">
                {' '}
                <Text FontWeight="500" Padding="4px 0px">
                    {EXPENSE_DETAILS.items}
                    <ImageIcon src={UnfoldMore} margin="0 5px" />
                </Text>
                <Text FontWeight="500" Padding="4px 0px">
                    {EXPENSE_DETAILS.article}
                </Text>
                <Text FontWeight="500" Padding="4px 0px">
                    {EXPENSE_DETAILS.quantity}
                </Text>
                <Text FontWeight="500" Padding="4px 0px">
                    {EXPENSE_DETAILS.amount}
                    <Text FontWeight="500" Color="#A1A5AA" Padding="4px 0px">
                        &nbsp;
                        {EXPENSE_DETAILS.noIva}
                    </Text>
                </Text>
                <Text FontWeight="500" Padding="4px 0px">
                    {EXPENSE_DETAILS.iva}
                </Text>
            </TableRowHead>
            <TableItemsContainer Background="none">
                {field.map((i, index) => (
                    <TableRowItems
                        key={i.id}
                        MinH="43px"
                        Height=""
                        GridColumn="repeat(5, 1fr)"
                        BackGround="none"
                        BorderBottom="1px solid #E8E9EA"
                        Border="none"
                        BorderRadius="0px"
                        ColumnGap="1rem"
                        Padding="0 0 8px 0"
                    >
                        <TableItem Justify="start" FlexDir="column">
                            <Input
                                {...register(`items.${index}.item`)}
                                type="number"
                                error={!!errors.items?.[index]?.item?.message}
                                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                            />
                            {errors.items?.[index]?.item?.message && (
                                <Text Color="#FF6357" FontSize="12px">
                                    Campo requerido
                                </Text>
                            )}
                        </TableItem>
                        <TableItem Justify="start" FlexDir="column">
                            <Input
                                {...register(`items.${index}.article`)}
                                type="string"
                                error={!!errors.items?.[index]?.article?.message}
                                maxLength={10}
                            />
                            {errors.items?.[index]?.article?.message && (
                                <Text Color="#FF6357" FontSize="12px">
                                    {errors.items?.[index]?.article?.message}
                                </Text>
                            )}
                        </TableItem>
                        <TableItem Justify="start" FlexDir="column">
                            <Controller
                                name={`items.${index}.count`}
                                control={control}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        name="value"
                                        type="text"
                                        autoComplete="off"
                                        defaultValue={0}
                                        width="80%"
                                    />
                                )}
                            />
                            {errors.items?.[index]?.count?.message && (
                                <Text Color="#FF6357" FontSize="12px">
                                    {errors.items?.[index]?.count?.message as string}
                                </Text>
                            )}
                        </TableItem>
                        <TableItem Justify="start" FlexDir="column">
                            <Controller
                                name={`items.${index}.cost`}
                                control={control}
                                render={({field}) => (
                                    <StyleNumericFormat
                                        {...field}
                                        name="value"
                                        type="text"
                                        autoComplete="off"
                                        decimalSeparator="."
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        hasError={
                                            errors.items?.[index]?.cost?.message ? true : false
                                        }
                                        allowNegative={false}
                                        prefix="$ "
                                        width="80%"
                                    />
                                )}
                            />
                            {errors.items?.[index]?.cost?.message && (
                                <Text Color="#FF6357" FontSize="12px">
                                    Campo requerido
                                </Text>
                            )}
                        </TableItem>
                        <TableItem Justify="start" FlexDir="column">
                            <Controller
                                name={`items.${index}.iva`}
                                control={control}
                                render={({field}) => (
                                    <StyleNumericFormat
                                        {...field}
                                        name="value"
                                        type="text"
                                        autoComplete="off"
                                        decimalSeparator="."
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        hasError={
                                            errors.items?.[index]?.iva?.message ? true : false
                                        }
                                        allowNegative={false}
                                        prefix="$ "
                                        width="80%"
                                    />
                                )}
                            />
                            {errors.items?.[index]?.iva?.message && (
                                <Text Color="#FF6357" FontSize="12px">
                                    Campo requerido
                                </Text>
                            )}
                        </TableItem>
                    </TableRowItems>
                ))}
            </TableItemsContainer>
            <TableItem
                Justify="start"
                onClick={() => append({item: 1, article: '', count: '', cost: '', iva: ''})}
            >
                <ImageIcon src={add} cursor="pointer" height="24px" width="24px" />
                <Text FontSize="0.85rem" Color="#5A5AFF" Cursor="pointer" FontWeight="500">
                    Agregar otro
                </Text>
            </TableItem>
        </Table>
    );
};
