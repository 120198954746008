import {UPDATE_BRANCH_FORM} from '@components/LocationsBranch/Redux/types';
import {
    IUpdateByLevelBranch,
    IUpdateByLevelBranchRedux,
} from '@components/LocationsBranch/interfaces';

const initialState: IUpdateByLevelBranch = {
    error: false,
    loading: false,
    updateBranch: null,
};

export const updateBranch = (
    state = initialState,
    action: IUpdateByLevelBranchRedux
): IUpdateByLevelBranch => {
    switch (action.type) {
        case UPDATE_BRANCH_FORM.BRANCH_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_BRANCH_FORM.BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                updateBranch: action.updateBranch,
            };
        case UPDATE_BRANCH_FORM.BRANCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
