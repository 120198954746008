import {ADD_ID_DOCUMENT} from '@Shopify/ClientProfile/Redux/types';
import {IdDocumentState, IAddDocImgRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IdDocumentState = {
    loading: false,
    error: false,
    message: '',
    data: null,
    imageData: {
        idDocument: 0,
        frontImage: null,
        backImage: null,
    },
};

const addIdentificationDoc = (state = initialState, action: IAddDocImgRedux): IdDocumentState => {
    switch (action.type) {
        case ADD_ID_DOCUMENT.ADD_DOC_START:
            return {
                ...state,
                loading: true,
            };
        case ADD_ID_DOCUMENT.ADD_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                imageData: action.imageData,
            };
        case ADD_ID_DOCUMENT.ADD_DOC_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.message,
            };
        case ADD_ID_DOCUMENT.CLEAR_ERROR:
            return {
                ...state,
                error: false,
                message: '',
            };
        default:
            return state;
    }
};
export default addIdentificationDoc;
