import React from 'react';
import {ContainerFlex, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IChargeNameInputProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const ChargeNameInput: React.FC<IChargeNameInputProps> = ({register, errors}) => {
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="700">
                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CHARGE_NAME}
            </Text>
            <Input {...register('chargeName')} />
            {errors.chargeName && <ErrorMessage message={errors.chargeName.message || ''} />}
        </ContainerFlex>
    );
};
