import {
    GET_BANNER_TABLE,
    GET_BANNER_TABLE_SUCCESS,
    GET_BANNER_TABLE_ERROR,
    GET_GROUP,
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR,
    GET_FAMILY,
    GET_FAMILY_SUCCESS,
    GET_FAMILY_ERROR,
    GET_SUBFAMILY,
    GET_SUBFAMILY_SUCCESS,
    GET_SUBFAMILY_ERROR,
    GET_BRAND,
    GET_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    GET_ITEM,
    GET_ITEM_SUCCESS,
    GET_ITEM_ERROR,
    POST_BANNER,
    POST_BANNER_SUCCESS,
    POST_BANNER_ERROR,
    POST_BANNER_DETAIL,
    POST_BANNER_DETAIL_SUCCESS,
    POST_BANNER_DETAIL_ERROR,
    GET_BANNER,
    GET_BANNER_SUCCESS,
    GET_BANNER_ERROR,
} from '@TypesEcommerce/BannerTypes';
import {IBanner, IBannerRedux} from '@Ecommerce/interfaces';

const initialState: IBanner = {
    banner: [],
    bannerTable: null,
    bannerTablecount: 0,
    bannerTablePageIndex: 0,
    bannerid: null,
    group: null,
    family: null,
    subfamily: null,
    brand: null,
    item: null,
    error: false,
    loading: false,
};

const banner = (state = initialState, action: IBannerRedux): IBanner => {
    switch (action.type) {
        case GET_BANNER_TABLE:
            return {
                ...state,
                loading: true,
            };
        case GET_BANNER_TABLE_SUCCESS:
            return {
                ...state,
                bannerTable: action.bannerTable,
                bannerTablecount: action.count,
                bannerTablePageIndex: action.pageIndex,
                loading: false,
            };
        case GET_BANNER_TABLE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_GROUP:
            return {
                ...state,
                loading: true,
            };
        case GET_GROUP_SUCCESS:
            return {
                ...state,
                group: action.group,
                loading: false,
            };
        case GET_GROUP_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_FAMILY:
            return {
                ...state,
                loading: true,
            };
        case GET_FAMILY_SUCCESS:
            return {
                ...state,
                family: action.family,
                loading: false,
            };
        case GET_FAMILY_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_SUBFAMILY:
            return {
                ...state,
                loading: true,
            };
        case GET_SUBFAMILY_SUCCESS:
            return {
                ...state,
                subfamily: action.subfamily,
                loading: false,
            };
        case GET_SUBFAMILY_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRAND:
            return {
                ...state,
                loading: true,
            };
        case GET_BRAND_SUCCESS:
            return {
                ...state,
                brand: action.brand,
                loading: false,
            };
        case GET_BRAND_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_ITEM:
            return {
                ...state,
                loading: true,
            };
        case GET_ITEM_SUCCESS:
            return {
                ...state,
                item: action.item,
                loading: false,
            };
        case GET_ITEM_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_BANNER:
            return {
                ...state,
                loading: true,
            };
        case POST_BANNER_SUCCESS:
            return {
                ...state,
                bannerid: action.bannerid,
                loading: false,
            };
        case POST_BANNER_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_BANNER_DETAIL:
            return {
                ...state,
                loading: true,
            };
        case POST_BANNER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_BANNER_DETAIL_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BANNER:
            return {
                ...state,
                loading: true,
            };
        case GET_BANNER_SUCCESS:
            return {
                ...state,
                banner: action.banner,
                loading: false,
            };
        case GET_BANNER_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
export default banner;
