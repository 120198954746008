import {
    GET_ALL_BRANCHES,
    GET_ALL_BRANCHES_ERROR,
    GET_ALL_BRANCHES_SUCCESS,
} from '@components/Branches/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    tableBranches: null,
};

const GetAllBranches = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_ALL_BRANCHES:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_BRANCHES_SUCCESS:
            return {
                ...state,
                tableBranches: action.payload.data,
                loading: false,
            };
        case GET_ALL_BRANCHES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetAllBranches;
