export const GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START = 'GET_ALL_CAT_ARTICLE_TYPE_DETAILS_START';
export const GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS = 'GET_ALL_CAT_ARTICLE_TYPE_DETAILS_SUCCESS';
export const GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR = 'GET_ALL_CAT_ARTICLE_TYPE_DETAILS_ERROR';

export const SAVE_NEW_DYNAMIC_FEATURE = 'SAVE_NEW_DYNAMIC_FEATURE';
export const UPDATE_DYNAMIC_FEATURE = 'UPDATE_DYNAMIC_FEATURE';
export const RESET_DYNAMIC_FEATURES = 'RESET_DYNAMIC_FEATURES';

export const GET_ACTIVATE_LEVEL_OR_ITEM_START = 'GET_ACTIVATE_LEVEL_OR_ITEM_START';
export const GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS = 'GET_ACTIVATE_LEVEL_OR_ITEM_SUCCESS';
export const GET_ACTIVATE_LEVEL_OR_ITEM_ERROR = 'GET_ACTIVATE_LEVEL_OR_ITEM_ERROR';

export const SAVE_NEW_INHERITED_FEATURE = 'SAVE_NEW_INHERITED_FEATURE';
export const UPDATE_INHERITED_FEATURE = 'UPDATE_INHERITED_FEATURE';
export const RESET_INHERITED_FEATURES = 'RESET_INHERITED_FEATURES';
