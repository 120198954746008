import axios, {AxiosResponse} from 'axios';
import {
    POST_CREATE_EXPENSE,
    POST_CREATE_EXPENSE_SUCCESS,
    POST_CREATE_EXPENSE_ERROR,
} from '@components/CashFlow/Redux/Types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';

export const postCreateExpense = () => {
    return {
        type: POST_CREATE_EXPENSE,
    };
};
export const postCreateExpenseSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CREATE_EXPENSE_SUCCESS,
        payload: result.data,
    };
};
export const postCreateExpenseError = () => {
    return {
        type: POST_CREATE_EXPENSE_ERROR,
    };
};

export function postCreateExpenseAction(token: string, data: FieldValues) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postCreateExpense());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostCreateExpense}`,
                data,
                {headers}
            );
            dispatch(postCreateExpenseSuccess(response));
        } catch (error) {
            dispatch(postCreateExpenseError());
        }
    };
}
