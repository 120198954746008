import {
    ADMIN_USER_PROFILE_SETTINGS,
    ADMIN_USER_PROFILE_SETTINGS_SUCCESS,
    ADMIN_USER_PROFILE_SETTINGS_ERROR,
} from '@components/AdminNavbar/types';
import {
    IAdminUserProfileInitial,
    IAdminUserProfileInitialRedux,
} from '@components/AdminNavbar/interfaces';

const initialState: IAdminUserProfileInitial = {
    error: false,
    loading: false,
    adminProfile: {
        picture: '',
        pictureAdministrator: '',
        picturePos: '',
        iconUrl: '',
        iconUrlHover: '',
        isHamburger: false,
        itemNameMenu: '',
        submenus: [],
        urlPage: '',
    },
};

const adminUserProfileSettings = (
    state = initialState,
    action: IAdminUserProfileInitialRedux
): IAdminUserProfileInitial => {
    switch (action.type) {
        case ADMIN_USER_PROFILE_SETTINGS:
            return {...state, loading: true};
        case ADMIN_USER_PROFILE_SETTINGS_SUCCESS:
            return {...state, loading: false, adminProfile: action.adminProfile};
        case ADMIN_USER_PROFILE_SETTINGS_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default adminUserProfileSettings;
