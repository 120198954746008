export const ARTICLE = {
    GROUP: 'GROUP',
    FAMILY: 'FAMILY',
    SUBFAMILY: 'SUBFAMILY',
    BRAND: 'BRAND',
    ARTICLES: 'ARTICLES',
    ERROR: 'ERROR',
    LEVEL_STATUS: 'LEVEL_STATUS',
    LEVEL_STATUS_SUCCESS: 'LEVEL_STATUS_SUCCESS',
    DELETE_LEVEL_ROUTE: 'DELETE_LEVEL_ROUTE',
    GET_LEVEL: 'GET_LEVEL',
    ADD_ROUTE_ITEMS: 'ADD_ROUTE_ITEMS',
};

export const ARTICLE_SEARCH = {
    ADD_LEVEL_DINAMICS: 'ADD_LEVEL_DINAMICS',
    ERROR: 'ERROR',
    SEARCH_ARTICLE: 'SEARCH_ARTICLE',
    RESTART_SEARCH_ARTICLE: 'RESTART_SEARCH_ARTICLE',
    LEVEL_SELECTED: 'LEVEL_SELECTED',
    RESTART_ROUTE: 'RESTART_ROUTE',
    ROUTE_ITEMS: 'ROUTE_ITEMS',
};

export const FIELD_DINAMICS = {
    RESTART_DINAMICS: 'RESTART_DINAMICS',
    ADD_DINAMIC: 'ADD_DINAMIC',
    INPUT_DINAMICS: 'INPUT_DINAMICS',
    DETAIL: 'DETAIL',
    DETAIL_SUCCESS: 'DETAIL_SUCCESS',
    ERROR: 'ERROR',
    CAT_LABELSIZE: 'CAT_LABELSIZE',
    CAT_TIPO_ARTICLE: 'CAT_TIPO_ARTICLE',
    LABEL_DETAIL: 'LABEL_DETAIL',
};

export const CREATE_NEWCATALOGID_START = 'CREATE_NEWCATALOGID_START';
export const CREATE_NEWCATALOGID_SUCCESS = 'CREATE_NEWCATALOGID_SUCCESS';
export const CREATE_NEWCATALOGID_ERROR = 'CREATE_NEWCATALOGID_ERROR';
export const LABEL_OPTION_TARGET = 'LABEL_OPTION_TARGET';

export const LIST_CHARACTERISTIC_DINAMICS_START = 'LIST_CHARACTERISTIC_DINAMICS_START';
export const LIST_CHARACTERISTIC_DINAMICS_SUCCESS = 'LIST_CHARACTERISTIC_DINAMICS_SUCCESS';
export const LIST_CHARACTERISTIC_DINAMICS_ERROR = 'LIST_CHARACTERISTIC_DINAMICS_ERROR';

export const LIST_CHARACTERISTIC_HEREDITARY_START = 'LIST_CHARACTERISTIC_HEREDITARY_START';
export const LIST_CHARACTERISTIC_HEREDITARY_SUCCESS = 'LIST_CHARACTERISTIC_HEREDITARY_SUCCESS';
export const LIST_CHARACTERISTIC_HEREDITARY_ERROR = 'LIST_CHARACTERISTIC_HEREDITARY_ERROR';

export const UPDATE_NEWCATALOGID_START = 'UPDATE_NEWCATALOGID_START';
export const UPDATE_NEWCATALOGID_SUCCESS = 'UPDATE_NEWCATALOGID_SUCCESS';
export const UPDATE_NEWCATALOGID_ERROR = 'UPDATE_NEWCATALOGID_ERROR';
export const TARGET_CATALOG_ID = 'TARGET_CATALOG_ID';
export const SAVE_ROUTE_CATALOG = 'SAVE_ROUTE_CATALOG';
export const SELECTED_DATA_NODE = 'SELECTED_DATA_NODE';
export const RESET_ROUTE_CATALOG = 'RESET_ROUTE_CATALOG';

export const LIST_CHARACTERISTIC_DETAILS_START = 'LIST_CHARACTERISTIC_DETAILS_START';
export const LIST_CHARACTERISTIC_DETAILS_SUCCESS = 'LIST_CHARACTERISTIC_DETAILS_SUCCESS';
export const LIST_CHARACTERISTIC_DETAILS_ERROR = 'LIST_CHARACTERISTIC_DETAILS_ERROR';
export const LIST_CHARACTERISTIC_DETAILS_ACTION = 'LIST_CHARACTERISTIC_DETAILS_ACTION';
export const LIST_DINAMICS_ADD = 'LIST_DINAMICS_ADD';
export const LIST_HEREDITARY_ADD = 'LIST_HEREDITARY_ADD';
export const LIST_CHARACTERISTIC_RESET = 'LIST_CHARACTERISTIC_RESET';

export const LIST_NEWCATALOGID_START = 'LIST_NEWCATALOGID_START';
export const LIST_NEWCATALOGID_SUCCESS = 'LIST_NEWCATALOGID_SUCCESS';
export const LIST_NEWCATALOGID_ERROR = 'LIST_NEWCATALOGID_ERROR';
export const TARGET_LEVEL_CATALOG = 'TARGET_LEVEL_CATALOG';
export const LIST_NEWCATALOGID_RESET = 'LIST_NEWCATALOGID_RESET';
