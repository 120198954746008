import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {
    itemsModulesAdmistrator,
    itemsModulesEmployee,
} from '@/components/SideBarMenu/Redux/modulesAdministratorActions';
import {RootState} from '@/config/store';
import {IModulesAdministrator, ISidebarProps} from '@components/SideBarMenu/interfaces';
import SideBarMenuItem from '@/components/SideBarMenu/SideBarMenuItem';
import {MenuAdminSettings} from '@/components/SideBarMenu/MenuAdminSettings';

const SideBarMainMenu: React.FC<ISidebarProps> = () => {
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const userType = useSelector((state: RootState) => state.getUsersValidation.userData?.userType);
    const modulesSideBarMenu: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );
    const dispatch = useDispatch();

    const [state, setState] = useState({
        expandedSubItems: [] as string[],
        clickedItems: null as number | null,
        clickedSubItem: null as string | null,
        menuSettings: false,
        settingsOpen: false,
    });

    useEffect(() => {
        if (token && (userType === 'Administrator' || userType === 'Employee')) {
            const action =
                userType === 'Administrator' ? itemsModulesAdmistrator : itemsModulesEmployee;
            dispatch(action(token));
        }
    }, [token, userType, dispatch]);

    const handleSubItemClick = (index: number, subIndex: number) => {
        const subItemKey = `${index}-${subIndex}`;
        setState((prevState) => ({
            ...prevState,
            expandedSubItems: prevState.expandedSubItems.includes(subItemKey)
                ? prevState.expandedSubItems.filter((item) => item !== subItemKey)
                : [...prevState.expandedSubItems, subItemKey],
            clickedSubItem: prevState.clickedSubItem === subItemKey ? null : subItemKey,
            clickedItems: null,
            menuSettings: false,
        }));
    };

    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            backG="#FAFAFF"
            Width="auto"
            Height="calc(100vh - 93px)"
            OverFlow="auto"
            Position="absolute"
            PositionTop="56px"
            ZIndex="5"
            BoxS="0px 0px 32px 0px rgba(0, 0, 0, 0.20)"
        >
            <ContainerFlex
                Width={state.settingsOpen ? '3.25rem' : '17.875rem'}
                Height="auto"
                backG="#FAFAFF"
                Padding={state.settingsOpen ? '1rem 0.5rem' : '1rem 0rem 1rem 1rem'}
                FlexDir="column"
                Justify={state.settingsOpen ? 'start' : ''}
            >
                {modulesSideBarMenu?.map((item, index) => (
                    <SideBarMenuItem
                        key={index}
                        item={item}
                        index={index}
                        clickedItems={state.clickedItems}
                        clickedSubItem={state.clickedSubItem}
                        settingsOpen={state.settingsOpen}
                        setSettingsOpen={(value) =>
                            setState((prevState) => ({...prevState, settingsOpen: value}))
                        }
                        handleSubItemClick={handleSubItemClick}
                        menuSettings={state.menuSettings}
                        setMenuSettings={(value) =>
                            setState((prevState) => ({...prevState, menuSettings: value}))
                        }
                        expandedSubItems={state.expandedSubItems}
                        setClickedItems={(value) =>
                            setState((prevState) => ({...prevState, clickedItems: value}))
                        }
                        setClickedSubItem={(value) =>
                            setState((prevState) => ({...prevState, clickedSubItem: value}))
                        }
                    />
                ))}
            </ContainerFlex>
            {state.menuSettings && <MenuAdminSettings />}
        </ContainerFlex>
    );
};

export default SideBarMainMenu;
