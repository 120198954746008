import * as yup from 'yup';

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const schema = yup.object().shape({
    Email: yup
        .string()
        .required('Se requiere correo electrónico.')
        .max(254, 'El correo proporcionado debe contener menos de 254 caracteres.')
        .matches(
            EMAIL_REGEX,
            'El correo electrónico proporcionado no está en un formato válido o reconocible.'
        ),
    version: yup.number(),
});
