import {
    PROPERTIES_ARTICLES,
    PROPERTIES_ARTICLES_SUCCESS,
    PROPERTIES_ARTICLES_ERROR,
    CANCEL_QUOTER,
    LEVELS,
    PROPERTIES_ARTICLES_ID,
    RESET_ARTCILE_DINAMICS,
} from '@TypesQouter/Types';
import {IDinamics, IReducerDinamics} from '@Quoter/inferfaces';

const initialState: IDinamics = {
    group: {breadCrumbs: null, groupList: []},
    family: {breadCrumbs: null, familyData: []},
    subfamily: {breadCrumbs: null, subfamilyList: []},
    brand: {breadCrumbs: null, brandList: []},
    article: {breadCrumbs: null, articleList: []},
    propertiesarticles: [],
    propertiesId: [],
    error: false,
    loading: false,
    loadingArticle: false,
};

const propertiesArticle = (state = initialState, actions: IReducerDinamics): IDinamics => {
    switch (actions.type) {
        case PROPERTIES_ARTICLES:
            return {...state, loading: true, loadingArticle: actions.loadingArticle};
        case LEVELS.LEVELGROUP:
            return {
                ...state,
                group: actions.group,
                loadingArticle: actions.loadingArticle,
                loading: false,
            };
        case LEVELS.LEVELFAMILY:
            return {
                ...state,
                family: actions.family,
                subfamily: {breadCrumbs: null, subfamilyList: []},
                brand: {breadCrumbs: null, brandList: []},
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS.LEVELSUBFAMILY:
            return {
                ...state,
                subfamily: actions.subfamily,
                brand: {breadCrumbs: null, brandList: []},
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS.LEVELBRAND:
            return {
                ...state,
                brand: actions.brand,
                article: {breadCrumbs: null, articleList: []},
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS.LEVELARTICLE:
            return {
                ...state,
                article: actions.article,
                loading: false,
                loadingArticle: actions.loadingArticle,
            };
        case LEVELS.LOADING:
            return {...state, loadingArticle: actions.loadingArticle};
        case PROPERTIES_ARTICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingArticle: actions.loadingArticle,
                propertiesarticles: actions.propertiesarticles,
            };
        case PROPERTIES_ARTICLES_ERROR:
            return {...state, error: false, loading: true};
        case CANCEL_QUOTER:
            return {...state, loading: false, article: {breadCrumbs: null, articleList: []}};
        case PROPERTIES_ARTICLES_ID:
            return {
                ...state,
                propertiesId: actions.propertiesId,
                loading: false,
            };
        case RESET_ARTCILE_DINAMICS:
            return {...state, loading: false, propertiesarticles: []};
        default:
            return state;
    }
};
export default propertiesArticle;
