import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableConsultAssignment() {
    return (
        <TableHead>
            <tr>
                <th>No. Asignación</th>
                <th>Estado</th>
                <th>Sucursal</th>
                <th>Fundidora</th>
                <th>Fecha Auditoría</th>
                <th>Auditor</th>
                <th>No. Papeleta</th>
                <th>No. Seguribolsa</th>
                <th>No. Prendas</th>
                <th>Costo Paquete</th>
            </tr>
        </TableHead>
    );
}
