import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CreditDetailsInformationLoan from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformationLoan';
import CreditDetailsInformationStatus from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformationStatus';
import {TermsDetails} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/TermsDetails';
import {PaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/PaymentMethod';

const CreditDetailsInformation = () => {
    return (
        <ContainerFlex
            Padding="24px"
            Border="1px solid #E8E9EA"
            backG="#fff"
            Width="100%"
            Height="100%"
            Flex="0"
            Radius="24px"
            Justify="space-between"
            Gap="24px"
            FlexDir="column"
        >
            <CreditDetailsInformationStatus />
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <CreditDetailsInformationLoan />
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <TermsDetails />
            <PaymentMethod />
        </ContainerFlex>
    );
};

export default CreditDetailsInformation;
