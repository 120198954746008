import {
    PUT_USER_INFO_BRANCH,
    PUT_USER_INFO_BRANCH_SUCCESS,
    PUT_USER_INFO_BRANCH_ERROR,
} from '@components/CloseBranch/redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    CloseBranch: [],
};

const putUserBranchInfo = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case PUT_USER_INFO_BRANCH:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_USER_INFO_BRANCH_SUCCESS:
            return {
                ...state,
                CloseBranch: action.payload.data,
            };
        case PUT_USER_INFO_BRANCH_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};

export default putUserBranchInfo;
