import React from 'react';

import * as style from '@Articles/style';
import {Icons} from '@Articles/style';
import {Tooltip} from '@General/Atoms/Tooltip/interface';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

const TooltipGeneral = (props: Tooltip) => {
    const {icon, titleTooltip, descriptionTooltip} = props;
    return (
        <Icons {...style.IconTooltip} ToolTip>
            {icon}
            <ContainerFlex {...style.IconTooltip.tooltip}>
                <ContainerFlex {...style.IconTooltip.clipTooltip} />
                <ContainerFlex {...style.IconTooltip.tooltipContent}>
                    <Text {...style.IconTooltip.titleTooltip}>{titleTooltip}</Text>
                    <Text {...style.IconTooltip.descriptionTooltip}>{descriptionTooltip}</Text>
                </ContainerFlex>
            </ContainerFlex>
        </Icons>
    );
};

export default TooltipGeneral;
