import React, {useEffect} from 'react';
import {GetMotiveDelete, deleteReason} from '@MyCompany/Redux/Actions/GetMotiveDelete';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {TextArea} from '@CashFlow/FundingRequest/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {Select} from '@Articles/style';
import {RootState, AppDispatch} from '@config/store';
import {PostMotiveDelete} from '@MyCompany/Redux/Actions/PostMotiveDelete';
import {companyFlowMdoals} from '@MyCompany/Interface';

export default function DeleteModalCompany({setFlowModalChange, setModalState}: companyFlowMdoals) {
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm({
        defaultValues: {
            reason: '',
            details: '',
        },
    });
    const dispatch: AppDispatch = useDispatch();
    const GetMotiveDeletes = useSelector((state: RootState) => {
        return state.GetMotiveDelete.data;
    });
    const errorPostMotive = useSelector((state: RootState) => {
        return state.PostMotiveDelete;
    });

    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const selectChange = async () => {
        if (token) {
            dispatch(GetMotiveDelete(token));
        }
    };

    const changeModal = () => {
        dispatch(
            PostMotiveDelete(token, {
                companyId: companyId,
                catMotiveDeleteCompanyId: Number(formData.reason),
                description: formData.details ? formData.details : '',
            })
        );
        if (!errorPostMotive.error) setFlowModalChange(2);
    };

    useEffect(() => {
        selectChange();
    }, []);

    const formData = watch();
    const disableDetails = () => {
        let value = false;
        if (
            (Number(formData.reason) === 8 && formData.details && formData.details.length === 0) ||
            formData.reason === ''
        ) {
            value = true;
        }
        return value;
    };

    return (
        <ContainerFlex FlexDir="column" Padding="0 24px 24px 24px" Width="35.75rem">
            <Text Color="#414042" FontSize="0.875rem" FontWeight="500" Height="16px">
                Para eliminar tu empresa, por favor ponte en contacto con un asesor.
            </Text>
            <Text
                Color="#414042"
                FontSize="0.875rem"
                wSpace="wrap"
                Height="42px"
                TextAlign="center"
                MarginTop="24px"
                FontWeight="normal"
            >
                En Ponzu siempre queremos mejorar y escucharte, por favor selecciona un motivo y
                cuéntanos sobre tu experiencia
            </Text>
            <ContainerForm
                FlexDir="column"
                onSubmit={handleSubmit(changeModal)}
                Margin="24px 0 5px 0"
            >
                <ContainerFlex FlexDir="column" Height="70px" Align="flex-start" MarginBt="5px">
                    <Text
                        FontSize="0.75rem"
                        Color="#6d6e71"
                        Height="55px"
                        FontWeight="normal"
                        required
                    >
                        Motivo
                    </Text>
                    <Select
                        FontSize="0.875rem"
                        {...register(`reason`, {
                            required: {value: true, message: 'Selecciona una opción'},
                        })}
                        Padding="11px 61px 11px 13px"
                        Width="100%"
                        Height="42px"
                        HBorder="1px solid #35cf44"
                        Color={formData.reason !== '' ? '#414042' : '#6d6e71'}
                        OptionColor="#414042"
                        FocusOutline="2px solid #35cf44"
                    >
                        <option disabled value="">
                            Seleccionar
                        </option>
                        {GetMotiveDeletes.length > 0 &&
                            GetMotiveDeletes.map(
                                (
                                    item: {
                                        catMotiveDeleteId: number;
                                        catMotiveDeleteIdDescription: string;
                                    },
                                    index: number
                                ) => (
                                    <option key={index} value={item.catMotiveDeleteId}>
                                        {item.catMotiveDeleteIdDescription}
                                    </option>
                                )
                            )}
                    </Select>
                    <Text FontSize="0.625rem" Color="#EF4F55">
                        {errors.reason?.message}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="flex-start">
                    <Text
                        FontSize="0.75rem"
                        Color="#6d6e71"
                        FontWeight="normal"
                        required={Number(formData.reason) === 8 ? true : false}
                    >
                        Detalles
                    </Text>
                    <TextArea
                        Color="#414042"
                        FontSize="0.875rem"
                        Height="82px"
                        Width="95%"
                        placeholder="Cuéntanos tu experiencia"
                        {...register('details', {
                            required: {
                                value: Number(formData.reason) === 8 ? true : false,
                                message: 'Campo requerido',
                            },
                        })}
                        value={formData.details ? formData.details.slice(0, 160) : ''}
                    />
                </ContainerFlex>
                <ContainerFlex Height="40px" Justify="space-between" MarginTop="24px">
                    <Text
                        FontSize="0.875rem"
                        Cursor="pointer"
                        MarginLeft="8px"
                        Color="#35cf44"
                        onClick={() => {
                            dispatch(deleteReason({companyId: companyId}, token));
                            setModalState(false);
                        }}
                    >
                        Cancelar
                    </Text>
                    <ButtonGeneral
                        width="11.5rem"
                        height="40px"
                        text="Contactar a un asesor"
                        type="button"
                        FontSize="0.875rem"
                        hColor="#35cf44"
                        transform=""
                        border="none"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => setFlowModalChange(1)}
                    />
                    <ButtonGeneral
                        width="11.5625rem"
                        height="40px"
                        text="Quiero ser contactado"
                        type="submit"
                        FontSize="0.875rem"
                        transform=""
                        hBorder="#35cf44"
                        hColor="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        disabled={disableDetails()}
                        clic={handleSubmit(changeModal)}
                    />
                </ContainerFlex>
            </ContainerForm>
        </ContainerFlex>
    );
}
