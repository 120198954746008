import React from 'react';
import {Controller} from 'react-hook-form';

import {UPDATE_ADDRESS} from '@MyCompany/constants';
import {IFormAddressUpdate} from '@MyCompany/Interface';
import {Icons} from '@/components/Articles/style';
import * as styles from '@components/CreateUsers/Styles';
import {SearchInput} from '@/components/Customer/Styles';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

export const FormUpdateAddress = ({register, errors, control}: IFormAddressUpdate) => {
    return (
        <ContainerFlex OverFlowY="auto" Gap="1rem" FlexDir="column" Justify="start">
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.LABEL}
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 8%"
                    FontSize="1rem"
                    Padding="0 0.2rem 0 1rem"
                    PlaceholderColor="#A1A5AA"
                    WBoxShadow=""
                    error={errors?.label?.message}
                >
                    <input type="text" {...register('label')} autoComplete="off" />
                    <Icons {...styles.iconSearch} Color="#A1A5AA">
                        help_outline
                    </Icons>
                </SearchInput>
                {errors?.label?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.label?.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.POSTAL_CODE}
                </Text>
                <Input
                    error={errors?.postalCode?.message}
                    type="number"
                    {...register('postalCode')}
                />
                {errors?.postalCode?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.postalCode?.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.STREET}
                </Text>
                <Input error={errors?.street?.message} {...register('street')} />
                {errors?.street?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.street?.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="1rem">
                <ContainerFlex Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                        {UPDATE_ADDRESS.NUM_OUT}
                    </Text>
                    <Input
                        error={errors?.OutNumber?.message}
                        type="number"
                        {...register('OutNumber')}
                    />
                    {errors?.OutNumber?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.OutNumber?.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                        {UPDATE_ADDRESS.NUM_INTERNAL}
                    </Text>
                    <Input
                        error={errors?.InternalNumber?.message}
                        type="number"
                        {...register('InternalNumber')}
                    />
                    {errors?.InternalNumber?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.InternalNumber?.message}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.COLONY}
                </Text>
                <Controller
                    name="colonyId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            options={[{value: 1, label: 'Los héroes tecámac'}]}
                            onChange={(e) => {
                                if (onChange) onChange(e);
                            }}
                            hasError={errors?.colonyId?.label?.message ? true : false}
                        />
                    )}
                />
                {errors?.colonyId?.label?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.colonyId?.label?.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.MUNICIPALY}
                </Text>
                <Controller
                    name="municipalityId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            options={[{value: 1, label: 'Los héroes tecámac'}]}
                            onChange={(e) => {
                                if (onChange) onChange(e);
                            }}
                            hasError={errors?.municipalityId?.label?.message ? true : false}
                        />
                    )}
                />
                {errors?.municipalityId?.label?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.municipalityId?.label?.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="auto" Gap="0.25rem" FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" FontWeight="500" Color="#6D6E71">
                    {UPDATE_ADDRESS.STATE}
                </Text>
                <Controller
                    name="stateId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            options={[{value: 1, label: 'Los héroes tecámac'}]}
                            onChange={(e) => {
                                if (onChange) onChange(e);
                            }}
                            hasError={errors?.stateId?.label?.message ? true : false}
                        />
                    )}
                />
                {errors?.stateId?.label?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors?.stateId?.label?.message}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
