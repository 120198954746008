import StringUtils from '@/GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {
    ASSIGNMENT_CLIENT_PROFILE_ERROR,
    ASSIGNMENT_CLIENT_PROFILE_START,
    ASSIGNMENT_CLIENT_PROFILE_SUCCESS,
} from '@Shopify/ListClient/redux/types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

const fetchClientProfileStart = () => {
    return {type: ASSIGNMENT_CLIENT_PROFILE_START};
};
const fetchClientProfileSuccess = (result: AxiosResponse) => {
    return {type: ASSIGNMENT_CLIENT_PROFILE_SUCCESS, dataClient: result.data};
};

const fetchClientProfileError = (error: AxiosError) => {
    return {type: ASSIGNMENT_CLIENT_PROFILE_ERROR, error: error};
};

export function GetClientProfileInformation(
    filters: {
        clientId: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(fetchClientProfileStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAssignmentClient}${queryString}`,
                {headers}
            );
            dispatch(fetchClientProfileSuccess(response.data));
        } catch (error) {
            dispatch(fetchClientProfileError(error as AxiosError));
        }
    };
}
