import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {
    TableRowHeadContract,
    TableItems,
    Table,
    TableItem,
    TableRowItems,
} from '@Shopify/Endeavors/styles';
import {fetchGetObjectBranch, getLocationFloor} from '@ActionsLocation/LocationAction';
import {RootState, AppDispatch} from '@config/store';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {ImgLocation} from '@CreateLocation/style';
import editIcons from '@images/edit-black-18dp.svg';
import {locationsGeneral, location} from '@Locations/AdminLocations/redux/Interfaces';
import deleteIconBlack from '@images/delete_forever-black.svg';
import {IconContainer} from '@/components/Quoter/styles';

export default function TableFloor(props: locationsGeneral) {
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const {branch, SelectCheckValidation, setShow} = props;
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState({actualPage: 1, totalItems: 1});

    const paginate = (button: string) => {
        switch (button) {
            case 'back':
                if (page.actualPage > 1)
                    setPage({
                        actualPage: page.actualPage - 1,
                        totalItems: page.totalItems - locations.gFloor.pageSize,
                    });
                break;
            case 'next':
                if (page.actualPage < locations.gFloor.totalPages)
                    setPage({
                        actualPage: page.actualPage + 1,
                        totalItems: page.totalItems + locations.gFloor.pageSize,
                    });
                break;
        }
    };

    useEffect(() => {
        setPage({actualPage: 1, totalItems: 1});
    }, [branch.idBranch]);
    useEffect(() => {
        if (branch.idBranch !== undefined)
            dispatch(getLocationFloor(branch.idBranch, 3, page.actualPage, token));
    }, [page.actualPage, branch.idBranch]);

    return (
        <ContainerFlex FlexDir="column" Width="78%" Height="auto" Justify="start">
            {locations.loading ? (
                <LoadingGeneral />
            ) : (
                locations.gFloor !== null &&
                locations.gFloor.data?.length > 0 && (
                    <>
                        <ContainerFlex Justify="end" Padding="0 0 0.8rem 0">
                            <IconContainer
                                Color={page.actualPage > 1 ? '' : '#e5e7e9'}
                                onClick={() => paginate('back')}
                                FontSize="1.25rem"
                                className="material-icons"
                            >
                                keyboard_arrow_left
                            </IconContainer>
                            <Text FontSize="0.75rem">
                                {page.actualPage +
                                    ' - ' +
                                    page.totalItems +
                                    ' de ' +
                                    locations.gFloor.totalPages}
                            </Text>
                            <IconContainer
                                Color={
                                    page.actualPage < locations.gFloor.totalPages ? '' : '#e5e7e9'
                                }
                                onClick={() => paginate('next')}
                                FontSize="1.25rem"
                                className="material-icons"
                            >
                                keyboard_arrow_right
                            </IconContainer>
                        </ContainerFlex>
                        <Table>
                            <TableRowHeadContract
                                Background="#f3f3f3"
                                GridTemplate="1fr 1.2fr 1fr 0.5fr"
                                Padding="0 1rem"
                            >
                                <TableItem Weight="500" Padding="0 5px" Justify="start">
                                    Nombre
                                </TableItem>
                                <TableItem Weight="500" Padding="0 5px" Justify="start">
                                    Observaciones
                                </TableItem>
                                <TableItem Weight="500" Padding="0 5px" Justify="start">
                                    Teléfono
                                </TableItem>
                                <TableItem Weight="500" Padding="0 5px">
                                    Acciones
                                </TableItem>
                            </TableRowHeadContract>
                            {locations.gFloor.data.map((item, index: number) => (
                                <TableRowItems
                                    key={index}
                                    Height="43px"
                                    GridColumn="1fr 1.2fr 1fr 0.5fr"
                                    BackGColor=""
                                    Padding="0 1rem"
                                    Width="auto"
                                >
                                    <TableItems
                                        Padding="0 5px"
                                        Justify="start"
                                        Weigth="400"
                                        Size="0.875rem"
                                    >
                                        {item.floorName}
                                    </TableItems>
                                    <TableItems Padding="0 5px" Justify="start" Width="90%">
                                        <TextEllipsis
                                            title={item.address}
                                            FontSize="0.875rem"
                                            FontWeight="400"
                                            Width="180px"
                                        >
                                            {item.address}
                                        </TextEllipsis>
                                    </TableItems>
                                    <TableItems
                                        Padding="0 5px"
                                        Justify="start"
                                        Weigth="400"
                                        Size="0.875rem"
                                    >
                                        {item.phone}
                                    </TableItems>
                                    <TableItems
                                        Padding="0 5px"
                                        Justify="center"
                                        Weigth="400"
                                        Size="0.875rem"
                                    >
                                        <ImgLocation
                                            Width="20px"
                                            Height="20px"
                                            onClick={() => {
                                                dispatch(
                                                    fetchGetObjectBranch({...item, type: 'edit'})
                                                );
                                                navigate('/Locations/CreateLocation/Floor');
                                            }}
                                            src={editIcons}
                                        />
                                        <ImgLocation
                                            onClick={() => {
                                                SelectCheckValidation(item.id);
                                                dispatch(fetchGetObjectBranch(item));
                                                setShow(true);
                                            }}
                                            Width="20px"
                                            Height="20px"
                                            HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                            src={deleteIconBlack}
                                        />
                                    </TableItems>
                                </TableRowItems>
                            ))}
                        </Table>
                    </>
                )
            )}
        </ContainerFlex>
    );
}
