import {ResponseData} from '@/components/ManageUser/interface';
import {
    DEADLINE_SIMULATION,
    DEADLINE_SIMULATION_SUCCESS,
    DEADLINE_SIMULATION_ERROR,
} from '@CreateDeadline/redux/types';

const InitialState = {
    loading: false,
    error: false,
    data: [],
    filters: null,
};

const deadlineSimulator = (
    state = InitialState,
    action: {type: string; value: number; payload: ResponseData}
) => {
    switch (action.type) {
        case DEADLINE_SIMULATION:
            return {
                ...state,
                filters: action.value,
            };
        case DEADLINE_SIMULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                filters: action.payload,
            };
        case DEADLINE_SIMULATION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

export default deadlineSimulator;
