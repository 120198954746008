import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import rightArrow from '@components/Branches/Images/RightArrow.svg';
import {BranchSchedule} from '@components/Branches/ManageBranch/Schedules/BranchSchedule';
import {IBranchSchedule} from '@components/Branches/interfaces';
import {BranchScheduleEndeavor} from '@components/Branches/ManageBranch/Schedules/BranchScheduleEndeavor';
import {BranchScheduleDischarge} from '@components/Branches/ManageBranch/Schedules/BranchScheduleDischarge';

export const ScheduleMenu = ({showItems, setShowItems}: IBranchSchedule) => {
    const [showOpenSchedule, setShowOpenSchedule] = useState(false);
    const [showPawnSchedule, setShowPawnSchedule] = useState(false);
    const [showDischargeSchedule, setShowDischargeSchedule] = useState(false);
    return (
        <ContainerFlex
            Justify="flex-start"
            Align="flex-start"
            Height="auto"
            FlexDir="column"
            RowGap="24px"
        >
            {!showOpenSchedule ? (
                <ContainerFlex
                    Height="56px"
                    Justify="space-between"
                    Border="1px solid #e5e7e9"
                    Radius="4px"
                    Margin="0 0 24px 0"
                    Padding="0 16px 0 0"
                >
                    <Text FontSize="1rem" FontWeight="500" Padding="18.5px 16px">
                        Horario para apertura/cierre
                    </Text>
                    <Image
                        src={rightArrow}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => setShowOpenSchedule(!showOpenSchedule)}
                    />
                </ContainerFlex>
            ) : (
                <BranchSchedule
                    scheduleName={'Horario para apertura/cierre'}
                    showItems={showItems}
                    setShowItems={setShowItems}
                    showOpenSchedule={showOpenSchedule}
                    setShowOpenSchedule={setShowOpenSchedule}
                />
            )}

            {!showPawnSchedule ? (
                <ContainerFlex
                    Height="56px"
                    Justify="space-between"
                    Border="1px solid #e5e7e9"
                    Radius="4px"
                    Margin="0 0 24px 0"
                    Padding="0 16px 0 0"
                >
                    <Text FontSize="1rem" FontWeight="500" Padding="18.5px 16px">
                        Horario para empeños
                    </Text>
                    <Image
                        src={rightArrow}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => setShowPawnSchedule(!showPawnSchedule)}
                    />
                </ContainerFlex>
            ) : (
                <BranchScheduleEndeavor
                    scheduleName={'Horario para empeños'}
                    showItems={showItems}
                    setShowItems={setShowItems}
                    showOpenSchedule={showPawnSchedule}
                    setShowOpenSchedule={setShowPawnSchedule}
                />
            )}

            {!showDischargeSchedule ? (
                <ContainerFlex
                    Height="56px"
                    Justify="space-between"
                    Border="1px solid #e5e7e9"
                    Radius="4px"
                    Margin="0 0 24px 0"
                    Padding="0 16px 0 0"
                >
                    <Text FontSize="1rem" FontWeight="500" Padding="18.5px 16px">
                        Horario para desempeños
                    </Text>
                    <Image
                        src={rightArrow}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => setShowDischargeSchedule(!showDischargeSchedule)}
                    />
                </ContainerFlex>
            ) : (
                <BranchScheduleDischarge
                    scheduleName={'Horario para desempeños'}
                    showItems={showItems}
                    setShowItems={setShowItems}
                    showOpenSchedule={showDischargeSchedule}
                    setShowOpenSchedule={setShowDischargeSchedule}
                />
            )}
            <ContainerFlex
                Height="56px"
                Justify="space-between"
                Border="1px solid #e5e7e9"
                Radius="4px"
                Padding="0 16px 0 0"
            >
                <Text FontSize="1rem" FontWeight="500" Padding="18.5px 16px">
                    Configurador de días inhábiles
                </Text>
                <Image
                    src={rightArrow}
                    alt="down-arrow"
                    Width="24px"
                    Height="24px"
                    Cursor="pointer"
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
