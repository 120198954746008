import React from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {EnabledCredits} from '@/components/MyAccount/MyCredit/CreditDetails/EnabledCredits';
import {ResumeCredits} from '@/components/MyAccount/MyCredit/CreditDetails/ResumeCredits';
import {CREDIT_CART_SHOPPING, ALTS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import alertIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/alertIcon.svg';

export const CreditsCartShopping = () => {
    const {showWarningMessage} = useSelector((state: RootState) => state.shoppingCarClient);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#F0F0FF"
            Padding="1.5rem  3.75rem"
            Gap="1.5rem"
        >
            <Text oFlow="none" wSpace="normal" FontSize="1.5rem" FontWeight="700" Color="#1D1E20">
                {CREDIT_CART_SHOPPING.TITLE}
            </Text>
            {showWarningMessage && (
                <ContainerFlex
                    Height="100px"
                    Width="100%"
                    Padding="16px"
                    backG="#FFF5DB"
                    FlexDir="column"
                    Gap="8px"
                >
                    <ContainerFlex Width="100%" Align="start" Justify="start" Gap="8px">
                        <Image src={alertIcon} Height="24px" Width="24px" alt={ALTS.WARNING_ICON} />
                        <Text FontFamily="Nunito" FontSize="1rem" FontWeight="700" Color="#614500">
                            {CREDIT_CART_SHOPPING.MESSAGE.TITLE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Width="100%" Align="start" Justify="start">
                        <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                            {CREDIT_CART_SHOPPING.MESSAGE.MESSAGE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            )}
            <ContainerFlex Justify="space-between" Align="start" Gap="1rem">
                <EnabledCredits />
                <ResumeCredits />
            </ContainerFlex>
        </ContainerFlex>
    );
};
