import React from 'react';

import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {BreadcrumbItem} from '@Atoms/BreadcrumbItem/Index';
import {BreadcrumbContainer} from '@Breadcrumb/style';

export default function Breadcrumb(props: {
    items: Array<IBreadcrumbItem>;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    ColumGap?: string;
}) {
    const {items, fontSize, fontWeight, color, ColumGap} = props;
    const lastIndex = items.length - 1;
    return (
        <BreadcrumbContainer
            FontLastChild={fontSize}
            FontWeight={fontWeight}
            Color={color}
            ColumnGap={ColumGap}
        >
            {items.map((item, index) => (
                <BreadcrumbItem item={item} isLastIndex={lastIndex === index} key={index} />
            ))}
        </BreadcrumbContainer>
    );
}
