import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';
import {TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {TableItem} from '@/components/DeadLines/styles';
import {RootState} from '@/config/store';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {PutUpdateBranchStatus} from '@components/Branches/Redux/Actions/PutUpdateBranchStatus';
import {IBranchItems, ITableBranch} from '@components/Branches/interfaces';
import {TableItemsContainer} from '@/components/Shopify/Endeavors/styles';
import {useInfiniteScroll} from '@/components/OrganizationCreation/SubContainer/TreeTableUtils/ReutilizableInfiniteScroll';

export const TableBranch = ({handleIntersect}: ITableBranch) => {
    const getAllBranches = useSelector((state: RootState) => {
        return state.getAllBranches.tableBranches.data;
    });
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const targetRef = useInfiniteScroll({
        onIntersect: handleIntersect,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleStatus = (branchId: number, status: boolean) => {
        dispatch(PutUpdateBranchStatus({branchId: branchId, status: !status}, token));
    };
    return (
        <TableItemsContainer MaxH="440px" MinH="40px">
            {getAllBranches.length !== 0 ? (
                getAllBranches?.map((item: IBranchItems) => (
                    <TableRowItems
                        key={item.branchId}
                        GridColumn="1.5fr 2.5fr 1fr"
                        Cursor="auto"
                        Height="48px"
                        Width="auto"
                        BorderRadius="4px"
                        BackGround={!item.status && '#f3f3f3'}
                    >
                        <TableItem
                            Padding="0 0 0 16px"
                            Justify="start"
                            CursorTable={item.status ? 'pointer' : 'default'}
                            TextAlign="left"
                            Cursor={item.status ? 'pointer' : 'default'}
                            onClick={() =>
                                item.status &&
                                navigate(`/CreateBranch/ManageBranch/${item.branchId}`)
                            }
                        >
                            <TextEllipsis
                                FontSize="0.875rem"
                                FontWeight="500"
                                Color={!item.status && '#a7a9ac'}
                            >
                                {item.branchName}
                            </TextEllipsis>
                        </TableItem>
                        <TableItem
                            Padding="0 0 0 10px"
                            Justify="start"
                            CursorTable={item.status ? 'pointer' : 'default'}
                            Cursor={item.status ? 'pointer' : 'default'}
                            onClick={() =>
                                item.status &&
                                navigate(`/CreateBranch/ManageBranch/${item.branchId}`)
                            }
                        >
                            {item.levelOrganization
                                ?.split(' >')
                                .map((data: string, index: number) => {
                                    if (index === item.levelOrganization.split(' > ').length - 1) {
                                        return (
                                            <Text
                                                key={index}
                                                Margin="0 0 0 6px"
                                                FontSize="0.875rem"
                                                FontWeight="500"
                                                Cursor={item.status ? 'pointer' : 'default'}
                                                Color={!item.status && '#a7a9ac'}
                                            >
                                                {data}
                                            </Text>
                                        );
                                    }
                                    if (0 === item.levelOrganization.split(' > ').length - 1) {
                                        return (
                                            <Text
                                                key={index}
                                                FontSize="0.875rem"
                                                FontWeight="500"
                                                Cursor={item.status ? 'pointer' : 'default'}
                                                Color={!item.status && '#a7a9ac'}
                                            >
                                                {data}
                                            </Text>
                                        );
                                    }
                                    if (index !== item.levelOrganization.split(' > ').length - 1) {
                                        return (
                                            <Text
                                                key={index}
                                                FontSize="0.875rem"
                                                Cursor={item.status ? 'pointer' : 'default'}
                                                Color={!item.status && '#a7a9ac'}
                                                Margin={
                                                    item.levelOrganization.split(' > ').length -
                                                        1 !==
                                                        index &&
                                                    item.levelOrganization.split(' > ').length !== 0
                                                        ? '0 0 0 6px'
                                                        : ''
                                                }
                                            >{`${data} >`}</Text>
                                        );
                                    }
                                    return <></>;
                                })}
                        </TableItem>
                        <TableItem Justify="center">
                            <GreenSwitch
                                checked={item.status}
                                size="small"
                                onChange={() => {
                                    handleStatus(item.branchId, item.status);
                                }}
                            />
                        </TableItem>
                    </TableRowItems>
                ))
            ) : (
                <TableRowItems
                    GridColumn="100%"
                    Cursor="auto"
                    Height="48px"
                    MinH="48px"
                    Width="100%"
                    BorderRadius="4px"
                >
                    <TableItem>
                        <TextEllipsis FontSize="0.875rem" FontWeight="500" Width="100%">
                            No hay coincidencias
                        </TextEllipsis>
                    </TableItem>
                </TableRowItems>
            )}
            <ContainerFlex ref={targetRef} />
        </TableItemsContainer>
    );
};
