import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {TextArea} from '@CashFlow/FundingRequest/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {ContainerFlex, Text, Input, ContainerForm} from '@Shopify/Ecommerce/styles';
import {
    fetchNewPassOrganization,
    fetchOrganizationName,
    fetchSelectNodeId,
    fetchSelectStateModalOrg,
} from '@ActionsOrgCreation/GetAllLevels';
import {IconContainer} from '@/components/Quoter/styles';
import {SearchInput} from '@Customer/Styles';
import {checkedState, fetchUpdateSelectNodeId} from '@ActionsOrgCreation/CheckedState';
import {formatLevels} from '@TreeTableUtils/LevelsAddFunctions';
import {levelCreateOrg} from '@OrganizationCreation/SubContainer/interface';
import {ICreateLevelOrganization} from '@OrganizationCreation/interface';
import {postCreateLevel} from '@OrganizationCreation/Redux/Actions/CreateLevels';
import {postUpdateLevels} from '@OrganizationCreation/Redux/Actions/UpdateLevels';

export const CreateLevelOrganization = ({
    setCurrentPage,
    changeModalState,
}: ICreateLevelOrganization) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const Levels = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const LevelStatement = useSelector((state: RootState) => {
        return state.GetStatmentData;
    });
    const UpdateStatment = useSelector((state: RootState) => {
        return state.GetStatmentData.updateStatement;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceName as string
    );
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: {errors},
    } = useForm<levelCreateOrg>({
        defaultValues: {
            levelName: '',
            fatherId: 0,
            companyId: 0,
            description: '',
        },
    });
    const dispatch: AppDispatch = useDispatch();
    const values = watch();
    const dataNode = {
        levelName: values.levelName ? values.levelName : '',
        description: values.description ? values.description : '',
    };
    const onSubmit = (value: {
        fatherId: number;
        description: string;
        levelName: string;
        companyId: number;
    }) => {
        const data = {
            levelName: value.levelName,
            fatherId: Number(LevelStatement.statement.idLevel),
            companyId: companyId,
            description: value.description,
        };
        const dataUpdate = {
            levelName: value.levelName,
            fatherId:
                UpdateStatment.idLevel === undefined
                    ? Number(LevelStatement.statement.idFather)
                    : Number(UpdateStatment.idLevel),

            companyId: companyId,
            description: value.description,
            levelId: Levels.nodeId.idLevel,
        };
        dispatch(
            Levels.isEdit ? postUpdateLevels(dataUpdate, token) : postCreateLevel(data, token)
        );
        changeModalState(false);
        dispatch(fetchSelectStateModalOrg(false));
        dispatch(fetchOrganizationName(null));
        dispatch(checkedState(''));
        dispatch(fetchSelectNodeId(''));
        setCurrentPage(1);
    };
    useEffect(() => {
        reset(
            token && Levels?.isEdit
                ? {
                      fatherId: !LevelStatement?.statement?.levelPath
                          ? ''
                          : LevelStatement.statement.levelPath,
                      levelName: !Levels?.nameOrganization
                          ? Levels.nodeId.levelName
                          : Levels.nameOrganization?.levelName,
                      description: !Levels?.nameOrganization
                          ? Levels.nodeId.levelDescription
                          : Levels.nameOrganization.description,
                  }
                : {
                      fatherId: !LevelStatement?.statement?.levelPath
                          ? ''
                          : LevelStatement.statement.levelPath,
                      levelName: !Levels?.nameOrganization ? '' : Levels.nameOrganization.levelName,
                      description: !Levels?.nameOrganization
                          ? ''
                          : Levels.nameOrganization.description,
                  }
        );
    }, []);
    return (
        <ContainerFlex
            Align="flex-start"
            FlexDir="column"
            Justify="flex-start"
            BoxS="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            Height="auto"
            Width="33rem"
            backG="#fff"
        >
            <ContainerFlex Justify="flex-start" Height="56px" Bb="solid 1px #35cf44">
                {Levels.isEdit ? (
                    <Text Padding="16.5px 24px" FontSize="1.25rem" Color="#35cf44">
                        Editar nivel organizacional
                    </Text>
                ) : (
                    <Text Padding="16.5px 24px" FontSize="1.25rem" Color="#35cf44">
                        Crear nivel organizacional
                    </Text>
                )}
            </ContainerFlex>
            <ContainerForm
                FlexDir="column"
                Justify="flex-start"
                Align="flex-start"
                BackG="none"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ContainerFlex
                    Padding="24px"
                    FlexDir="column"
                    Justify="flex-start"
                    Align="flex-start"
                >
                    <ContainerFlex
                        Height="40px"
                        FlexDir="column"
                        Justify="flex-start"
                        Align="flex-start"
                    >
                        <Text wSpace="normal" Height="auto">
                            Crea un nivel organizacional que te permite organizar
                        </Text>
                        <Text>estructura de tu empresa</Text>
                    </ContainerFlex>
                    <ContainerFlex
                        MarginTop="32px"
                        FlexDir="column"
                        Justify="flex-start"
                        Align="flex-start"
                        Height="74px"
                    >
                        <Text FontSize="0.75rem" required Color="#6d6e71">
                            Nombre del nivel organizacional
                        </Text>
                        <ContainerFlex>
                            <Input
                                Height="40px"
                                Cursor="text"
                                HolderFontSize="0.875rem"
                                Padding="12px 13px"
                                error={errors.levelName?.message}
                                placeholder="Nombre"
                                Width="100%"
                                {...register('levelName', {
                                    required: {
                                        value: true,
                                        message: 'Campo obligatorio',
                                    },
                                    pattern: {
                                        value: /^[A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü][A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü ]*$/,
                                        message:
                                            'Valor introducido es invalido, no se permiten caracteres especiales',
                                    },
                                    maxLength: {
                                        value: 160,
                                        message: 'Tamaño máximo alcanzado',
                                    },
                                })}
                                type="text"
                            />
                        </ContainerFlex>
                        <Text
                            Transform=""
                            Color="red"
                            FontWeight="400"
                            FontSize="0.625rem"
                            wSpace="normal"
                        >
                            {errors?.levelName?.message}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
                        <Text required FontSize="0.75rem" Color="#6d6e71">
                            Descripción
                        </Text>
                        <ContainerFlex>
                            <TextArea
                                FocusOutline={
                                    errors.description ? '2px solid #35cf44' : '2px solid #35cf44'
                                }
                                FontSize="0.875rem"
                                Padding="13px"
                                HolderFontSize="0.875rem"
                                placeholder="Descripción"
                                Height="56px"
                                {...register('description', {
                                    required: {value: true, message: 'Campo Obligatorio'},
                                    pattern: {
                                        value: /^[A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü,.][A-ZÁÉÍÓÚa-záéíóú0-9ÑñÄËÏÖÜäëïöü,. ]*$/,
                                        message:
                                            'Valor introducido es inválido, sólo se permiten caracteres alfanuméricos, espacios, puntos y comas',
                                    },
                                    maxLength: {
                                        value: 160,
                                        message: 'Tamaño máximo alcanzado',
                                    },
                                })}
                            />
                        </ContainerFlex>
                        {Levels?.fatherId.levelName}
                        <Text
                            Transform=""
                            Color="red"
                            FontWeight="400"
                            FontSize="0.625rem"
                            Width="267px"
                            wSpace="normal"
                            Height="2rem"
                        >
                            {errors?.description?.message}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
                        <Text FontSize="0.75rem" Cursor="auto" Color="#6d6e71" required>
                            Organización superior
                        </Text>
                        <SearchInput
                            Justify="start"
                            Border="1px solid #E5E7E9"
                            Height="40px"
                            Width="100%"
                            GridColumn="95% 5%"
                            Margin="0"
                            BoxShadowF="none"
                            Right="0"
                            Cursor="auto"
                            BorderH={Levels.node.length > 0 ? '1px solid #35cf44' : ''}
                            BoxShadowH={
                                Levels.node.length > 0 ? '0px 4px 4px rgba(122, 193, 67, 0.2)' : ''
                            }
                            BackG={Levels.node.length > 0 ? '' : ' #f3f3f3'}
                            {...register('fatherId', {
                                required: {
                                    value: true,
                                    message: 'Campo obligatorio',
                                },
                            })}
                            onClick={() => {
                                if (Levels.node.length > 0) {
                                    dispatch(
                                        fetchOrganizationName(
                                            dataNode.levelName
                                                ? dataNode
                                                : {
                                                      levelName: '',
                                                      description: '',
                                                  }
                                        )
                                    );
                                    dispatch(fetchUpdateSelectNodeId(LevelStatement?.statement));
                                    dispatch(fetchNewPassOrganization(2));
                                }
                            }}
                        >
                            <Text
                                Cursor="pointer"
                                FontSize=".875rem"
                                Color={Levels.node.length > 0 ? '#414042' : '#a7a9ac'}
                            >
                                {LevelStatement.statement.levelPath
                                    ? formatLevels(LevelStatement.statement.levelPath)
                                    : companyName}
                            </Text>
                            <IconContainer Width="100%" className="material-icons" Color="#a7a9ac">
                                keyboard_arrow_down
                            </IconContainer>
                        </SearchInput>
                        <Text
                            Transform=""
                            Color="red"
                            FontWeight="400"
                            FontSize="0.625rem"
                            Width="267px"
                            wSpace="normal"
                            Height="2rem"
                        >
                            {errors?.fatherId?.message}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Bt="solid 1px #e5e7e9"
                    Justify="flex-end"
                    Align="flex-end"
                    Padding="24px"
                    Height="88px"
                    ColumnGap="24px"
                >
                    <ButtonGeneral
                        width="93px"
                        text="Cancelar"
                        type="submit"
                        border="transparent"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hColor="#35cf44"
                        transform=""
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => {
                            changeModalState(false);
                            dispatch(fetchSelectStateModalOrg(false));
                            dispatch(fetchOrganizationName(null));
                            dispatch(checkedState(''));
                            dispatch(fetchSelectNodeId(''));
                        }}
                    />
                    <ButtonGeneral
                        width="87px"
                        text="Guardar"
                        type="submit"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        transform="capitalize"
                        hText="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        disabled={
                            (values.description || values.levelName) === undefined ||
                            !values.fatherId ||
                            !values.levelName ||
                            !values.description
                        }
                    />
                </ContainerFlex>
            </ContainerForm>
        </ContainerFlex>
    );
};
