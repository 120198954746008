import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    EMPLOYMENT_SITUATION_START,
    EMPLOYMENT_SITUATION_SUCCESS,
    EMPLOYMENT_SITUATION_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';

export const fetchEmploymentDataStart = () => {
    return {
        type: EMPLOYMENT_SITUATION_START,
    };
};
export const fetchEmploymentDataSuccess = (result: AxiosResponse) => {
    return {
        type: EMPLOYMENT_SITUATION_SUCCESS,
        payload: result,
    };
};

export const fetchEmploymentDataError = (error: AxiosError) => {
    return {
        type: EMPLOYMENT_SITUATION_ERROR,
        error: error,
    };
};

export function GetAllEmploymentSituation(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchEmploymentDataStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatEmploymentSituation}
            `,
                {headers}
            );
            dispatch(fetchEmploymentDataSuccess(response));
        } catch (error) {
            dispatch(fetchEmploymentDataError(error as AxiosError));
        }
    };
}
