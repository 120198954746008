import axios from 'axios';
import {PutDataContactInfo} from '@MyCompany/Interface';
import {
    PUT_DATA_CONTACT_START,
    PUT_DATA_CONTACT_SUCCESS,
    PUT_DATA_CONTACT_ERROR,
} from '@MyCompany/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const PutDataContactStart = () => {
    return {
        type: PUT_DATA_CONTACT_START,
    };
};
export const PutDataContactSuccess = (data: unknown) => {
    return {
        type: PUT_DATA_CONTACT_SUCCESS,
        payload: data,
    };
};

export const PutDataContactError = () => {
    return {
        type: PUT_DATA_CONTACT_ERROR,
    };
};

export const PutDataContact = (
    token: string,

    data: PutDataContactInfo
) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(PutDataContactStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutDataContact}`,
                data,
                {headers}
            );
            dispatch(PutDataContactSuccess(response));
        } catch (error) {
            dispatch(PutDataContactError());
        }
    };
};
