import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {clearDocumentsError} from '@Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import IdentityDocsModal from '@Shopify/ClientProfile/IdentityDocsModal';
import documentIcon from '@images/documentIconDark.svg';
import checkMark from '@images/checkMark.svg';
import refreshIcon from '@images/icon-refresh.svg';
import warningIcon from '@images/warning-red.svg';
import {DOCUMENTS} from '@Shopify/ClientProfile/constants';

const Documents: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const dispatch = useDispatch();
    const [showIdDocumentModal, setShowIdDocumentModal] = useState(false);
    const {imageData: addedImageDocs, message: idDocMessage} = useSelector(
        (state: RootState) => state.addIdentificationDoc
    );
    const clientDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.documents
    );
    return (
        <>
            <ContainerFlex
                Padding="1rem 1.5rem"
                FlexDir="column"
                Align="start"
                Gap="1rem"
                Radius="1.5rem"
                Border="1px solid #E8E9EA"
                Self="stretch"
            >
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                    {DOCUMENTS.TITLE}
                </Text>
                <ContainerFlex
                    Align="flex-start"
                    Justify="start"
                    FlexDir="column"
                    AlignContent="flex-start"
                    Gap="1rem"
                    FlexWrap="nowrap"
                >
                    <ContainerFlex FlexDir="column" Gap="0.5rem" Align="flex-start" Width="36.5rem">
                        <Text
                            Padding="0.125rem 0"
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {DOCUMENTS.ADD_IDENTITY_DOCUMENT}
                        </Text>
                        <ContainerFlex
                            Gap="1rem"
                            Height="3.5rem"
                            Border="1px solid #E8E9EA"
                            Radius="1rem"
                            PaddingR="1rem"
                        >
                            <FlexPanel
                                Padding="1rem"
                                Justify="flex-start"
                                Align="center"
                                Background="#FAFAFA"
                                BottomLeftRad="1rem"
                                TopLeftRad="1rem"
                            >
                                <Image
                                    src={documentIcon}
                                    Width="1.5rem"
                                    Height="1.5rem"
                                    ObjectFit="cover"
                                />
                            </FlexPanel>
                            <ContainerFlex
                                Padding="0.5rem 0 0.25rem 0"
                                FlexDir="column"
                                Align="flex-start"
                                Gap="0.25rem"
                                Flex="1 0 0"
                                Self="stretch"
                            >
                                {addedImageDocs &&
                                    addedImageDocs.frontImage &&
                                    addedImageDocs.backImage && (
                                        <Text
                                            FontWeight="500"
                                            FontSize="0.875rem"
                                            Color="#2A2C2F"
                                            LHeight="120%"
                                            oFlow="visible"
                                        >
                                            {addedImageDocs
                                                ? addedImageDocs.frontImage?.fileName
                                                : clientDocs
                                                ? clientDocs[0].nameInDocuments
                                                : ''}{' '}
                                            |{' '}
                                            {addedImageDocs
                                                ? addedImageDocs.backImage?.fileName
                                                : ''}
                                        </Text>
                                    )}
                                <ContainerFlex Gap="0.25rem" Justify="flex-start">
                                    {addedImageDocs &&
                                        addedImageDocs.frontImage &&
                                        addedImageDocs.backImage && (
                                            <>
                                                <Image src={checkMark} Width="1rem" Height="1rem" />
                                                <Text
                                                    FontSize="0.75rem"
                                                    FontWeight="400"
                                                    Color="#54575C"
                                                >
                                                    {addedImageDocs
                                                        ? `${
                                                              addedImageDocs.frontImage?.fileSize ||
                                                              ''
                                                          } ${DOCUMENTS.MB}`
                                                        : clientDocs
                                                        ? clientDocs[0].size
                                                        : ''}{' '}
                                                    |{' '}
                                                    {addedImageDocs
                                                        ? `${
                                                              addedImageDocs.backImage?.fileSize ||
                                                              ''
                                                          } ${DOCUMENTS.MB}`
                                                        : ''}
                                                </Text>
                                            </>
                                        )}
                                </ContainerFlex>
                            </ContainerFlex>
                            <FlexPanel
                                Align="center"
                                Justify="center"
                                Gap="0.25rem"
                                Cursor="pointer"
                                onClick={() => {
                                    dispatch(clearDocumentsError());
                                    setShowIdDocumentModal(true);
                                }}
                            >
                                <Image src={refreshIcon} Width="1.5rem" Height="1.5rem" />
                                <Text
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Color="#5A5AFF"
                                    LHeight="120%"
                                    TextAlign="center"
                                    Cursor="inherit"
                                >
                                    {DOCUMENTS.REPLACE}
                                </Text>
                            </FlexPanel>
                        </ContainerFlex>
                        <Text FontSize="0.875rem" FontWeight="400" LHeight="120%">
                            {DOCUMENTS.MAX_SIZE_17}
                            {idDocMessage && (
                                <Text
                                    FontSize="0.875rem"
                                    Color="#FF6357"
                                    FontWeight="600"
                                    MarginLeft="0.5rem"
                                    LHeight="120%"
                                >
                                    <Image
                                        src={warningIcon}
                                        Width="1rem"
                                        Height="1rem"
                                        Padding="0 0.25rem"
                                        ObjectFit="cover"
                                    />
                                    {DOCUMENTS.ERROR}: {idDocMessage}
                                </Text>
                            )}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="0.5rem"
                        Align="flex-start"
                        Justify="flex-start"
                        Width="36.5rem"
                    >
                        <Text
                            Padding="0.125rem 0"
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {DOCUMENTS.ADD_PROOF_OF_INCOME}
                        </Text>
                        <ContainerFlex
                            Gap="1rem"
                            Height="3.5rem"
                            Border="1px solid #E8E9EA"
                            Radius="1rem"
                            PaddingR="1rem"
                        >
                            <FlexPanel
                                Padding="1rem"
                                Justify="flex-start"
                                Align="center"
                                Background="#FAFAFA"
                                BottomLeftRad="1rem"
                                TopLeftRad="1rem"
                            >
                                <Image
                                    src={documentIcon}
                                    Width="1.5rem"
                                    Height="1.5rem"
                                    ObjectFit="cover"
                                />
                            </FlexPanel>
                            <ContainerFlex
                                Padding="0.5rem 0 0.25rem 0"
                                FlexDir="column"
                                Align="flex-start"
                                Gap="0.25rem"
                                Flex="1 0 0"
                                Self="stretch"
                            >
                                <Text
                                    FontWeight="500"
                                    FontSize="0.875rem"
                                    Color="#2A2C2F"
                                    LHeight="120%"
                                    oFlow="visible"
                                >
                                    {clientDocs ? clientDocs[1].nameInDocuments : ''}
                                </Text>
                                <ContainerFlex Gap="0.25rem" Justify="flex-start">
                                    <Image src={checkMark} Width="1rem" Height="1rem" />
                                    <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                                        {clientDocs ? clientDocs[1].size : ''}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <FlexPanel
                                Align="center"
                                Justify="center"
                                Gap="0.25rem"
                                Cursor="pointer"
                            >
                                <Image src={refreshIcon} Width="1.5rem" Height="1.5rem" />
                                <Text
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Color="#5A5AFF"
                                    LHeight="120%"
                                    TextAlign="center"
                                    Cursor="inherit"
                                >
                                    {DOCUMENTS.REPLACE}
                                </Text>
                            </FlexPanel>
                        </ContainerFlex>
                        <Text FontSize="0.875rem" FontWeight="400" LHeight="120%">
                            {DOCUMENTS.MAX_SIZE_17}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {showIdDocumentModal && (
                <IdentityDocsModal
                    clientId={clientProfile.clientId}
                    showModal={showIdDocumentModal}
                    setShowModal={setShowIdDocumentModal}
                />
            )}
        </>
    );
};
export default Documents;
