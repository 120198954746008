import {Table} from '@DeadLines/styles';
import React, {useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import {useForm, Controller} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorMessage} from '@hookform/error-message';
import {DateTime} from 'luxon';
import Swal from 'sweetalert2';
import {RootState, AppDispatch} from '@config/store';
import 'react-datepicker/dist/react-datepicker.css';

import ButtonGeneral from '@General/Atoms/Button/index';
import {
    getSucursalAudit,
    PostAssignmentAction,
} from '@ActionsAuditAssignment/AuditAssignmentAction';
import {getAuditors} from '@ActionsAuditAssignment/AuditorsAction';
import {getFoundry} from '@ActionsAuditAssignment/FoundryAction';
import {getAssignmentTypes} from '@ActionsAuditAssignment/AssignmentTypesAction';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {RowSucursalTable} from '@AuditAssignment/RowSucursalTable';
import {HeadTableAuditAssignment} from '@AuditAssignment/HeadTableAuditAssignment';
import {RowSucursalHistoryTable} from '@AuditAssignment/RowSucursalHistoryTable';
import {ObtainSucursalIDAction} from '@ActionsAuditAssignment/AuditAssignmentAction';
import '@AuditAssignment/styles.css';
import {HeadTableAuditAssignmentHistory} from '@AuditAssignment/HeadTableAuditAssignmentHistory';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';

const defaultValues = {
    fechaVisita: new Date(),
    idFundidora: 0,
    idAsignacion: 0,
    idAsignado: 0,
};

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '/', label: 'Ecommerce'},
    {to: '', label: 'Asignación de Auditoría'},
];

export function AuditAssignment() {
    const {
        handleSubmit,
        register,
        control,
        formState: {errors},
    } = useForm({
        defaultValues,
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    const handlePageClick = (data: {selected: number}) => {
        dispatch(getSucursalAudit(10, data.selected, token));
    };
    const handlePageClickHistory = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(ObtainSucursalIDAction(sucursal.sucursalID, 5, selected, token));
    };
    useEffect(() => {
        if (token) {
            const getSucursalTable = () => dispatch(getSucursalAudit(10, 0, token));
            getSucursalTable();
            const getAuditorSelect = () => dispatch(getAuditors(token));
            getAuditorSelect();
            const getFoundrySelect = () => dispatch(getFoundry(token));
            getFoundrySelect();
            const getAssignmentTypesSelect = () => dispatch(getAssignmentTypes(token));
            getAssignmentTypesSelect();
        }
    }, []);

    const sucursal = useSelector((state: RootState) => {
        return state.sucursal;
    });
    const sucursalinfoID = useSelector((state: RootState) => {
        return state.sucursal.sucursalinfoID;
    });
    const auditors = useSelector((state: RootState) => {
        return state.auditors.auditors;
    });
    const assignmenttypes = useSelector((state: RootState) => {
        return state.assignmenttypes.assignmenttypes;
    });
    const foundry = useSelector((state: RootState) => {
        return state.foundry.foundry;
    });

    const onSubmit = (values: {
        idAsignado: number;
        idFundidora: number;
        fechaVisita: Date;
        idAsignacion: number;
    }) => {
        if (sucursal.sucursalID === '') {
            Swal.fire({
                icon: 'warning',
                title: 'Agregar visita',
                text: 'Es necesario seleccionar una sucursal',
            });
        } else {
            const jason = {
                idSucursal: sucursal.sucursalID,
                idAsignado: values.idAsignado,
                idAsignador: 5,
                idFundidora: values.idFundidora,
                fechaVisita: DateTime.fromJSDate(values.fechaVisita).toFormat('DD-MM-YYYY'),
                ahora: DateTime.local().minus({hours: 6}).toFormat('dd-MM-yyyy'),
                idAsignacion: values.idAsignacion,
            };
            dispatch(PostAssignmentAction(jason, token));
        }
    };

    return (
        <div className="container-primary">
            {/* <div className="pt-2 pb-2 flex align-items-center">
        <span className="child-breadcrumb">Fundiciones</span>
        <span className="pt-2">
          <i className="material-icons arrow-breadcrumb">
            keyboard_arrow_right
          </i>{" "}
        </span>
        <span className="principal-breadcrumb">Asignación de Auditoría</span>
      </div> */}
            <Breadcrumb items={itemsBreadcrumb} />
            <span className="title">Seleccione sucursal para asignar auditoría.</span>
            <div className="flex container-secondary">
                <div className="w-50 pt-3 pr-4">
                    {sucursal.loadingsucursal === true ? (
                        <LoadingGeneral />
                    ) : (
                        <>
                            <Table>
                                <HeadTableAuditAssignment />
                                <tbody>
                                    {sucursal.sucursal &&
                                        sucursal.sucursal.map(
                                            (
                                                sucursales: JSX.IntrinsicAttributes & {
                                                    idsucursal: number;
                                                    sucursal: string;
                                                    region: string;
                                                    prendas: number;
                                                    costo: number;
                                                }
                                            ) => (
                                                <RowSucursalTable
                                                    key={sucursales.idsucursal}
                                                    {...sucursales}
                                                />
                                            )
                                        )}
                                </tbody>
                            </Table>
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={sucursal.sucursalcount / 10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </>
                    )}
                </div>
                <div className="w-50 pt-3 pl-4">
                    {sucursalinfoID.length !== 0 ? (
                        <Table>
                            <HeadTableAuditAssignment />
                            <tbody>
                                {sucursalinfoID === undefined
                                    ? 'No hay sucursales'
                                    : sucursalinfoID.map(
                                          (
                                              sucursales: JSX.IntrinsicAttributes & {
                                                  idsucursal: number;
                                                  sucursal: string;
                                                  region: string;
                                                  prendas: number;
                                                  costo: number;
                                              }
                                          ) => (
                                              <RowSucursalTable
                                                  key={sucursales.idsucursal}
                                                  {...sucursales}
                                              />
                                          )
                                      )}
                            </tbody>
                        </Table>
                    ) : null}
                    <form className="container-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex pb-3">
                            <div className="column w-100 pr-3">
                                <label className="required">Tipo de Asignación</label>
                                <Controller
                                    {...register('idAsignacion')}
                                    control={control}
                                    rules={{required: true}}
                                    render={() => (
                                        <ReactSelect
                                            options={assignmenttypes}
                                            className="input-form"
                                            isClearable
                                            placeholder="Seleccione tipo asignación"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="idAsignacion"
                                    message="Seleccione tipo de asignación"
                                    as="p"
                                />
                            </div>
                            <div className="column w-100 pl-3">
                                <label className="required">Responsable</label>
                                <Controller
                                    {...register('idAsignado')}
                                    control={control}
                                    rules={{required: true}}
                                    render={() => (
                                        <ReactSelect
                                            options={auditors}
                                            className="input-form"
                                            isClearable
                                            aria-required={true}
                                            placeholder="Seleccione responsable"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="idAsignado"
                                    message="Seleccione un auditor"
                                    as="p"
                                />
                            </div>
                        </div>
                        <div className="flex py-3">
                            <div className="column w-100 pr-3 flex">
                                <label className="required">Fecha visita</label>
                                <Controller
                                    {...register('fechaVisita')}
                                    rules={{required: true}}
                                    control={control}
                                    render={({field: {onChange, onBlur, value}}) => (
                                        <ReactDatePicker
                                            className="input-form"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            minDate={new Date()}
                                            showDisabledMonthNavigation
                                        />
                                    )}
                                />
                            </div>
                            <div className="column w-100 pl-3">
                                <label className="required">Fundidora</label>
                                <Controller
                                    {...register('idFundidora')}
                                    control={control}
                                    rules={{required: true}}
                                    render={() => (
                                        <ReactSelect
                                            options={foundry}
                                            className="input-form"
                                            isClearable
                                            aria-required={true}
                                            placeholder="Seleccione fundidora"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    className="required-text"
                                    name="idFundidora"
                                    message="Seleccione una fundidora"
                                    as="p"
                                />
                            </div>
                        </div>
                        <div className="flex w-100 mt-3 justify-content-end">
                            <ButtonGeneral
                                type="submit"
                                text={'Agregar Visita '}
                                loading={sucursal.loadingsucursal}
                            />
                        </div>
                    </form>
                    {sucursal.loadingsucursalhistory && <LoadingGeneral />}
                    {sucursal.sucursalhistory.length !== 0 ? (
                        <>
                            <div className="mt-4 text-center">
                                <span>Histórico de auditoría</span>
                            </div>
                            <table className="table">
                                <HeadTableAuditAssignmentHistory />
                                <tbody>
                                    {sucursal.sucursalID === undefined
                                        ? 'No hay sucursales'
                                        : sucursal.sucursalhistory.data.map(
                                              (
                                                  sucursales: JSX.IntrinsicAttributes & {
                                                      sucursal: string;
                                                      region: string;
                                                      estado: string;
                                                      fechaVisita: string;
                                                      auditor: string;
                                                  }
                                              ) => (
                                                  <RowSucursalHistoryTable
                                                      key={sucursales.fechaVisita}
                                                      {...sucursales}
                                                  />
                                              )
                                          )}
                                </tbody>
                            </table>
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={sucursal.sucursalhistory.count / 5}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                forcePage={sucursal.sucursalhistory.pageIndex}
                                onPageChange={handlePageClickHistory}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
