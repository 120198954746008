import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOANSACTIVE} from '@components/LoansProducts/constants';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {DivisorLine} from '@/components/MyAccount/styles';

export const LoansActive = () => {
    const {data} = useSelector((state: RootState) => {
        return state.getPersonalCreditProductsReducer;
    });
    return (
        <ContainerFlex
            Height="80px"
            backG="#FFFFFF"
            Padding="1rem 0"
            Radius="1.5rem"
            Gap="1rem"
            MinH="80px"
            Border="1px solid #E8E9EA"
        >
            <ContainerFlex FlexDir="column" PaddingL="24px">
                <Text FontSize="0.875rem" Color="#54575C">
                    {LOANSACTIVE.PRODUCT}
                </Text>
                <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                    {data.activeProducts}
                </Text>
            </ContainerFlex>
            <DivisorLine />
            <ContainerFlex FlexDir="column">
                <Text FontSize="0.875rem" Color="#54575C">
                    {LOANSACTIVE.ACTIVE}
                </Text>
                <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                    {data.loanProducts}
                </Text>
            </ContainerFlex>
            <DivisorLine />
            <ContainerFlex FlexDir="column" PaddingR="24px">
                <Text FontSize="0.875rem" Color="#54575C">
                    {LOANSACTIVE.INACTIVE}
                </Text>
                <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                    {data.inactiveProducts}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
