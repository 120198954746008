import {
    ASSIGNMENT_CLIENT_PROFILE_ERROR,
    ASSIGNMENT_CLIENT_PROFILE_START,
    ASSIGNMENT_CLIENT_PROFILE_SUCCESS,
} from '@Shopify/ListClient/redux/types/Types';
import {
    AssignmentClientRedux,
    AssignmentClientResponse,
} from '@Shopify/ListClient/redux/interfaces';

const initialState: AssignmentClientResponse = {
    error: false,
    errorData: null,
    loading: false,
    dataClient: {
        clientId: 0,
        totalPawns: 0,
        totalMortgage: 0,
        totalLoanPersonality: 0,
        totalContracts: 0,
        acountPawns: '',
        dischargeAverage: 0,
        averagePayCapital: 0,
        totalPawnsDischarge: 0,
        totalContractsInSelling: 0,
    },
};

const GetAssignmentClient = (
    state: AssignmentClientResponse = initialState,
    action: AssignmentClientRedux
): AssignmentClientResponse => {
    switch (action.type) {
        case ASSIGNMENT_CLIENT_PROFILE_START:
            return {...state, loading: true, error: false};
        case ASSIGNMENT_CLIENT_PROFILE_SUCCESS:
            return {...state, loading: false, error: false, dataClient: action.dataClient};
        case ASSIGNMENT_CLIENT_PROFILE_ERROR:
            return {...state, loading: false, error: true, errorData: action.errorData};
        default:
            return state;
    }
};

export default GetAssignmentClient;
