import {GET_BRANCH} from '@components/LocationsBranch/Redux/types';
import {IBranchByLevelId, IBranchByLevelIdRedux} from '@components/LocationsBranch/interfaces';

const initialState: IBranchByLevelId = {
    error: false,
    loading: false,
    branchByLevel: {
        branchName: '',
        levelId: 0,
        description: '',
        branchDirection: null,
        userBranch: [],
        openingHourBranchs: [],
        holidaysBranchs: [],
        storagesBranchs: [],
    },
    isEdit: false,
    branchId: 0,
};

export const getBranchByLevel = (
    state = initialState,
    action: IBranchByLevelIdRedux
): IBranchByLevelId => {
    switch (action.type) {
        case GET_BRANCH.BY_LEVEL_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH.BY_LEVEL_SUCCESS:
            return {
                ...state,
                loading: false,
                branchByLevel: action.branchByLevel,
            };
        case GET_BRANCH.BY_LEVEL_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_BRANCH.IS_EDIT:
            return {
                ...state,
                isEdit: action.isEdit,
                branchId: action.branchId,
            };
        default:
            return state;
    }
};
