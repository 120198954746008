import axios from 'axios';
import {URL} from '@/config/constants/index';
import {DEADLINES} from '@DeadLines/CreateDeadline/redux/types';
import {DeadlineSimulator} from '@CreateDeadline/interfaces';
import {Dispatch} from 'redux';
import {interested} from '@components/DeadLines/CreateDeadline/redux/Constants';

export const deadlines = () => {
    return {
        type: DEADLINES.DEADLINES_STATEINITIAL,
    };
};

export const setStep = (data: number) => {
    return {
        type: DEADLINES.STEP,
        payload: data,
    };
};
export const deadlinesError = (data: unknown) => {
    return {
        type: DEADLINES.DEADLINE_ERROR,
        payload: data,
    };
};

export const initialInformation = (data: {
    companyId: number;
    branches: number[];
    nameTerm: string;
    startDate: string;
    endDate: string;
    catalogLevelType: number | undefined;
    catalogLevel: number | undefined;
}) => {
    return {
        type: DEADLINES.INITIAL_INFORMATION,
        payload: data,
    };
};

export const interestInformation = (data: {
    storageRate: number;
    insuranceRate: number;
    operationRate: number;
    cat: number;
    commission: number;
}) => {
    return {
        type: DEADLINES.INTEREST,
        payload: data,
    };
};

export const amountsAndTimes = (data: {
    minimumLoanAmount: number;
    maximumLoanAmount: number;
    quantityGarments: number;
    negotiationPercentage: number;
    loanPercentage: number;
    marketingPercentage?: number;
    dynamicAmount: boolean;
}) => {
    return {
        type: DEADLINES.AMOUNT_TIMES,
        payload: data,
    };
};
export const tableAmounts = (
    data:
        | {
              order: number;
              lowerLoanPercentage: number;
              higherLoanPercentage: number;
              interestPercentage: number;
              marketingPercentage: number;
              discountPercentage: number;
          }[]
        | interested<{
              order: number;
          }>
) => {
    return {
        type: DEADLINES.AMOUNT_TABLE,
        payload: data,
    };
};

export const tableDeadlines = (
    data:
        | {
              order: number;
              interestPercentage: number;
              daysGrace: number;
              interestMoratoriums: number;
              visible: boolean;
              fixedInterest: boolean;
              printedPrefix: string;
              typePeriod: number;
              numberPeriods: number;
          }[]
        | [
              {
                  order: number;
                  interestPercentage: number;
                  daysGrace: number;
                  interestMoratoriums: number;
                  visible: number;
                  fixedInterest: number;
                  printedPrefix: string;
                  typePeriod: number;
                  numberPeriods: number;
              },
          ]
) => {
    return {
        type: DEADLINES.DEADLINE_TABLE,
        payload: data,
    };
};

export const haveDiscount = (data: boolean) => {
    return {
        type: DEADLINES.DISCOUNTS,
        payload: data,
    };
};

export const discountsTable = (
    data: {
        discountPercentage: number;
        endorsement: boolean;
        discharge: boolean;
        order?: number;
        quantityDiscounts: number;
    }[]
) => {
    return {
        type: DEADLINES.DISCOUNTS_TABLE,
        payload: data,
    };
};

export const changeProduct = (data: number[]) => {
    return {
        type: DEADLINES.CHANGES_PRODUCTS,
        payload: data,
    };
};

export const extensionsProducts = (data: {
    haveExtension: boolean;
    promotionExtensions:
        | {
              dailyCost: number;
              loanLimit: number;
              interestRate: number;
          }
        | {
              dailyCost?: undefined;
              loanLimit?: undefined;
              interestRate?: undefined;
          };
}) => {
    return {
        type: DEADLINES.EXTENTIONS,
        payload: data,
    };
};

export const startsClient = (data: number) => {
    return {
        type: DEADLINES.STARTS,
        payload: data,
    };
};

export const getDeadlineSimulatorSuccess = (result: {data: string}) => {
    return {
        type: DEADLINES.SIMULATION_EXAMPLE,
        payload: result,
    };
};
export const getDeadlineSimulators = (filters: DeadlineSimulator, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(deadlines());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDeadlineSimulator}`,
                filters,
                {headers}
            );
            dispatch(getDeadlineSimulatorSuccess(response.data));
        } catch (error) {
            dispatch(deadlinesError(error));
        }
    };
};
