import React from 'react';
import {SearchInput} from '@/components/Customer/Styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {Box} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {IconContainer} from '@/components/Quoter/styles';
import {IDatePicker} from '@/components/Branches/interfaces';

export const DatePickerContract = ({date, setDate, setValue, errors, trigger}: IDatePicker) => {
    return (
        <ContainerFlex Height="40px" Width="300px" Margin="0 0 28px 0">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    value={date}
                    onChange={(newDate) => {
                        setDate(newDate);
                        setValue('contractRegistrationDate', newDate);
                        trigger('contractRegistrationDate');
                    }}
                    renderInput={({inputRef, inputProps, InputProps}) => (
                        <Box>
                            <SearchInput
                                Width="300px"
                                Height="40px"
                                Position="none"
                                GridColumn="90% 10%"
                                Margin="0 0 5px 0"
                                Border={
                                    errors.contractRegistrationDate
                                        ? '1px solid #ef4f55'
                                        : '1px solid #e5e7e9'
                                }
                                BorderH={
                                    errors.contractRegistrationDate
                                        ? '1px solid #ef4f55'
                                        : '1px solid #35cf44'
                                }
                                BorderF={
                                    errors.contractRegistrationDate
                                        ? '1px solid #ef4f55'
                                        : '1px solid #35cf44'
                                }
                                BoxShadowH="0px 4px 4px rgba(53, 207, 68, 0.2)"
                                BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                            >
                                <input ref={inputRef} {...inputProps} />
                                <IconContainer
                                    Width="100%"
                                    Justify="flex-end"
                                    Color="#6d6e71"
                                    FontSize="1rem"
                                >
                                    {InputProps?.endAdornment}
                                </IconContainer>
                            </SearchInput>
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </ContainerFlex>
    );
};
