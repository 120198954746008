import axios, {AxiosResponse} from 'axios';
import {
    GET_BRANCH_USERS,
    GET_BRANCH_USERS_ERROR,
    GET_BRANCH_USERS_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {Dispatch} from 'redux';
import {URL} from '@config/constants/index';

export const fetchGetBranchUsersStart = () => {
    return {
        type: GET_BRANCH_USERS,
    };
};
export const fetchGetBranchUsersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_BRANCH_USERS_SUCCESS,
        payload: result,
    };
};
export const fetchGetBranchUsersError = () => {
    return {
        type: GET_BRANCH_USERS_ERROR,
    };
};

interface IFilters {
    branchId: number;
    searchName: string;
    orderType: number;
    filterUser: number;
}
export function GetBranchUsers(filters: IFilters, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(fetchGetBranchUsersStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchUsers}`,
                filters,
                {headers}
            );
            dispatch(fetchGetBranchUsersSuccess(response));
        } catch (error) {
            dispatch(fetchGetBranchUsersError());
        }
    };
}
