import {AxiosError, AxiosResponse} from 'axios';
import {
    ROLES_INFORMATION_ASSIGNMENT,
    ROLES_INFORMATION_ERROR,
    ROLES_INFORMATION_START,
    ROLES_INFORMATION_SUCCESS,
} from '@Roles/Redux/Types/types';
import {IDataRole, RolesList} from '@Roles/interface';

const initialState: IDataRole = {
    rolesData: null,
    loading: false,
    error: false,
    errorData: null,
    userRolesId: null,
    pageSize: null,
    totalPages: null,
    totalItems: null,
    pageNumber: null,
};
const GetRolesInformation = (
    state: IDataRole = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; item: RolesList}
): IDataRole => {
    switch (action.type) {
        case ROLES_INFORMATION_START:
            return {...state, loading: true, error: false};
        case ROLES_INFORMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                rolesData: action.payload.data.data,
                pageSize: action.payload.data.pageSize,
                totalPages: action.payload.data.totalPages,
                totalItems: action.payload.data.totalItems,
                pageNumber: action.payload.data.pageNumber,
            };
        case ROLES_INFORMATION_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case ROLES_INFORMATION_ASSIGNMENT:
            return {...state, loading: false, error: false, userRolesId: action.item};
        default:
            return state;
    }
};
export default GetRolesInformation;
