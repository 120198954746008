import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IBreadcrumbItem} from '@components/General/Interfaces/IBreadcrumb';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import Breadcrumb from '@components/General/Moleculs/Breadcrumb/Index';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import arrowIndex from '@components/Branches/Images/ArrowBackIndex.svg';
import {IndexTree} from '@components/Branches/BranchIndex/IndexTree';
import {BranchInfo} from '@components/Branches/ManageBranch/BranchInfo/BranchInfo';
import {BranchUsers} from '@components/Branches/ManageBranch/BranchUsers/BranchUsers';
import {Schedules} from '@components/Branches/ManageBranch/Schedules/Schedules';
import {getTreeComponent} from '@/components/General/BranchTree/Redux/Actions/getOrganizationsAction';
import {RootState} from '@/config/store';
import {createBranchReset} from '@components/Branches/Redux/Actions/CreateBranch';
import {BranchServices} from '@components/Branches/ManageBranch/BranchServices/BranchServices';
import {BranchCashFlow} from '@components/Branches/ManageBranch/BranchCashFlow/BranchCashFlow';
import {IShowItem} from '@components/Branches/interfaces';
import {fetchGetCashFlowSetupReset} from '@components/Branches/ManageBranch/Redux/Actions/GetCashFlowSetup';

export const ManageBranch = () => {
    const navigate = useNavigate();
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/', label: 'Inicio'},
        {to: '#/CreateBranch', label: 'Sucursales'},
        {to: '#/', label: 'Gestionar Sucursales'},
    ];
    const dispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [showItems, setShowItems] = useState<IShowItem>({
        info: false,
        user: false,
        schedule: false,
        services: false,
        cashFlow: false,
    });
    useEffect(() => {
        return () => {
            dispatch(fetchGetCashFlowSetupReset());
        };
    }, []);
    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));
        dispatch(createBranchReset());
    }, []);
    return (
        <ContainerFlex
            Padding="1.625rem 1.5rem"
            Height="auto"
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex Justify="flex-start">
                <ImgLocation
                    src={arrowIndex}
                    Width="1.5rem"
                    Height="1.5rem"
                    Margin="0 12px 0 0"
                    Cursor="pointer"
                    onClick={() => navigate('/CreateBranch')}
                />
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Height="auto" PaddingT="24px" Justify="flex-start" Align="flex-start">
                <IndexTree />
                <ContainerFlex
                    Width="100%"
                    Justify="flex-start"
                    FlexDir="column"
                    Padding="0 0 0 24px"
                    ScrollWidth="0px"
                >
                    <BranchInfo showItems={showItems} setShowItems={setShowItems} />
                    <BranchUsers showItems={showItems} setShowItems={setShowItems} />
                    <Schedules showItems={showItems} setShowItems={setShowItems} />
                    <BranchServices showItems={showItems} setShowItems={setShowItems} />
                    <BranchCashFlow showItems={showItems} setShowItems={setShowItems} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
