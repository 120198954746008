import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import {RootState, AppDispatch} from '@config/store';
import {GetInfoContactCompany} from '@MyCompany/Redux/Actions/GetContactCompany';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import imgContactDelete from '@images/iconContactDelete.svg';
import {IconSvg} from '@/components/Quoter/styles';
import {textDefine} from '@MyCompany/AplicationContact/style';
export default function MessageDeleteCompany() {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const contactCompany = useSelector((state: RootState) => state.GetContactCompany.contact);
    useEffect(() => {
        if (token) {
            dispatch(GetInfoContactCompany(token));
        }
    }, []);

    return (
        <ContainerFlex FlexDir="column" Margin="7%" MarginBt="7%">
            <Text {...textDefine}>
                {contactCompany?.data.nameCompany}, lamentamos que decidas irte,
            </Text>
            <Text {...textDefine} MarginB="16px">
                por favor lee la siguiente información cuidadosamente
            </Text>
            <Text Color="#414042" Height="23px" FontSize="1rem" FontWeight="500" MarginB="24px">
                • Uno de nuestros asesores se pondrá en contacto contigo en un periodo de 24 horas.
            </Text>
            <ImgLocation
                Width="31.3125rem"
                src={imgContactDelete}
                Margin="0 0 24px 0"
                alt="alert"
            />
            <Text Color="#6d6e71" Width="auto" Height="14px" FontSize="0.75rem" FontWeight="300">
                Estos detalles también están disponibles en tu correo electrónico.
            </Text>

            <ContainerFlex
                Height="40px"
                Width="10.6875rem"
                MarginTop="24px"
                Border="1px solid #414042"
                Radius="5px"
                HoverBorder="1px solid #35cf44"
                Fill="#35cf44"
                HColor="#35cf44"
                onClick={() => {
                    window.location.href = '#/MyCompany';
                }}
            >
                <IconSvg
                    width="23"
                    height="23"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M6.4 18 5 16.6 14.6 7H6V5h12v12h-2V8.4L6.4 18z" fill="#414042" />
                </IconSvg>
                <Text FontSize="0.875rem" Color="#414042" Cursor="pointer" MarginLeft="8px">
                    Ir a Mi empresa
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
}
