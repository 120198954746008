export const containerFileRack = [
    {
        id: 1,
        letterName: 'A',
    },
    {
        id: 2,
        letterName: 'B',
    },

    {
        id: 3,
        letterName: 'C',
    },

    {
        id: 4,
        letterName: 'D',
    },
    {
        id: 5,
        letterName: 'E',
    },
    {
        id: 6,
        letterName: 'F',
    },
    {
        id: 7,
        letterName: 'G',
    },
    {
        id: 8,
        letterName: 'H',
    },
    {
        id: 9,
        letterName: 'I',
    },
    {
        id: 10,
        letterName: 'J',
    },
];

export const containerFileVault = [
    {
        id: 1,
        letterName: 'A',
    },
    {
        id: 2,
        letterName: 'B',
    },

    {
        id: 3,
        letterName: 'C',
    },

    {
        id: 4,
        letterName: 'D',
    },
    {
        id: 5,
        letterName: 'E',
    },
    {
        id: 6,
        letterName: 'F',
    },
    {
        id: 7,
        letterName: 'G',
    },
    {
        id: 8,
        letterName: 'H',
    },
    {
        id: 9,
        letterName: 'I',
    },
    {
        id: 10,
        letterName: 'J',
    },
];
export const containerSquabVault = [
    {
        squab: 1,
    },
    {
        squab: 2,
    },

    {
        squab: 3,
    },

    {
        squab: 4,
    },
    {
        squab: 5,
    },
    {
        squab: 6,
    },
    {
        squab: 7,
    },
    {
        squab: 8,
    },
    {
        squab: 9,
    },
    {
        squab: 10,
    },
];
