import {PUT_COOWNER, PUT_COOWNER_ERROR, PUT_COOWNER_SUCCESS} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingCoOwnerUpdated: false,
    idCoOwnerUpdated: 0,
};

const putDataCoOwners = (state = initialState, actions: {type: string; payload: AxiosResponse}) => {
    switch (actions.type) {
        case PUT_COOWNER:
            return {
                ...state,
                loadingCoOwnerUpdated: true,
            };
        case PUT_COOWNER_SUCCESS:
            return {
                ...state,
                loadingCoOwnerUpdated: false,
                idCoOwnerUpdated: state.idCoOwnerUpdated !== 0 ? 0 : actions.payload.data.data,
            };
        case PUT_COOWNER_ERROR:
            return {
                ...state,
                error: false,
                loadingCoOwnerUpdated: false,
            };
        default:
            return state;
    }
};

export default putDataCoOwners;
