import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {ImageLogo} from '@components/LoginClient/styles';
import {addUserText} from '@/components/NewUser/constants';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import elipseIconRole from '@images/Ellipse 976.svg';
import addRole from '@images/addRole.svg';
import questionImg from '@images/questionMark.svg';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

const PosRoleSelect = () => {
    const {control} = useForm();

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="start"
            Border="1px solid #F4F5F5"
            Padding="10px"
            Height=""
            Radius="20px"
            Gap="10px"
        >
            <ContainerFlex Justify="start">
                <ImageLogo src={elipseIconRole} Width="40px" Height="40px" alt="AdminIcon" />

                <Text FontSize="1.7rem" FontWeight="700" color="#2A2C2F" MinH="2rem">
                    {addUserText.roles}
                </Text>
            </ContainerFlex>

            <Text color="#6D6E71" FontSize="1rem" MinH="1.3rem">
                {addUserText.rolesText}
            </Text>
            <ContainerFlex Width="30%">
                <Controller
                    name="colony"
                    control={control}
                    render={({field: {onChange, value, ...field}}) => (
                        <SelectGeneral {...field} value={value} onChange={onChange} />
                    )}
                />
                <ImageLogo
                    src={questionImg}
                    Position="relative"
                    Height="20px"
                    Width="20px"
                    Right="4rem"
                    alt="QuestionMark"
                />
            </ContainerFlex>

            <Text Color="#5A5AFF" Gap="10px" MinH="1.2rem">
                <ImageLogo src={addRole} Height="14px" Width="14px" alt="AddIcon" />
                {addUserText.addRole}
            </Text>
        </ContainerFlex>
    );
};

export default PosRoleSelect;
