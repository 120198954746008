import {useEffect} from 'react';
import {RootState} from '@/config/store';
import {FieldValues} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {ISelectReact} from '@/components/Quoter/inferfaces';
import {
    catMunicipality,
    catStates,
    catColony,
    resetPostalCode,
} from '@components/LocationsBranch/Redux/Action/CatAddress';
import {ICustomAddress} from '@components/LocationsBranch/interfaces';

export const useAddressForm = ({watch, reset, setValue}: ICustomAddress) => {
    const formValues = watch();
    const dispatch = useDispatch();
    const {token, catAddress} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        catAddress: state.catAddress,
    }));

    const selectConvert = (items: FieldValues) => {
        return items.length > 0
            ? items.map((item: FieldValues) => ({
                  value: item.suburbId || item.municipalityId || item.stateId || item.colonyId,
                  label:
                      item.nameSuburb || item.nameMunicipality || item.stateName || item.colonyName,
              }))
            : [{value: '', label: 'Sin opciones'}];
    };

    const address = catAddress.catPostalCode;
    const resetAddressByPostalCode = async () => {
        if (address.cityName) {
            await dispatch(catStates(token, address.countryId));
            await dispatch(catMunicipality(token, {value: address.stateId}));
            await dispatch(catColony(token, {value: address.municipalityId}));
            reset({
                ...watch(),
                postalCode: address.postalCode,
                colony: {
                    value: address.colonys[0].colonyId,
                    label: address.colonys[0].colonyName,
                },
                municipaly: {
                    value: address.municipalityId,
                    label: address.municipalityName,
                },
                state: {value: address.stateId, label: address.stateName},
            });
        }
    };

    const getCatState = (e: ISelectReact) => {
        dispatch(catMunicipality(token, e as ISelectReact));
        dispatch(catColony(token, formValues.municipaly as ISelectReact));
        dispatch(resetPostalCode());
        setValue('municipaly', '');
        setValue('colony', '');
    };

    const getCatMunicipality = (e: ISelectReact) => {
        dispatch(catColony(token, e as ISelectReact));
        dispatch(resetPostalCode());
        setValue('colony', '');
    };

    const catColonies = address.cityName
        ? catAddress.catColonyByMunicipality.filter((i) =>
              address.colonys.map((colony) => colony.colonyId).includes(i.suburbId)
          )
        : catAddress.catColonyByMunicipality;

    useEffect(() => {
        resetAddressByPostalCode();
    }, [address.cityName]);

    return {
        token,
        catAddress,
        catColonies,
        getCatState,
        selectConvert,
        getCatMunicipality,
    };
};
