import {Controller} from 'react-hook-form';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {
    amountPercentageSelectOptions,
    CHARGES_AND_PENALTIES,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IAmountSelectProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {InputCustomContainer} from '@components/PersonalLoans/NewLoanProduct/styles';
import {StyleNumericFormat} from '@/components/Loan/styles';
import {
    AmountValueType,
    amountValueTypes,
    getPlaceHolder,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/helpers/getPlaceholderSelect';
import {getAmountType} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/helpers/getAmountType';

export const AmountSelect: React.FC<IAmountSelectProps> = ({control, errors, watch}) => {
    const [amountValueType, setAmountValueType] = useState<AmountValueType>(amountValueTypes.None);
    const amountTypeRef = useRef<HTMLInputElement>(null);
    const placeholderAmountSelect: string = useMemo(
        () => getPlaceHolder(amountValueType),
        [amountValueType]
    );
    const formState = watch();
    useEffect(() => {
        if (formState.typeChargeAmount === undefined) return;
        const amountType = getAmountType(formState.typeChargeAmount.value);
        setAmountValueType(amountType);
    }, [formState.typeChargeAmount]);
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="700">
                {CHARGES_AND_PENALTIES.FIELDS_TITLES.AMOUNT_PERCENTAGE}
            </Text>
            <InputCustomContainer Gap="0.25rem" Width="100%" Display="grid" GridColumns="146px 1fr">
                <Controller
                    name="typeChargeAmount"
                    control={control}
                    render={({field}) => (
                        <Select
                            {...field}
                            options={amountPercentageSelectOptions}
                            isSearchable={false}
                            placeholder="Selecciona"
                            radius="32px 0px 0px 32px"
                            border="none"
                            BorderRight="1px solid #E8E9EA"
                            hborder=""
                            controlTop=""
                            width="146px"
                            PaddingSelection="0px"
                        />
                    )}
                />

                <Controller
                    name="amount"
                    control={control}
                    render={({field: {onChange, value, ...field}}) => (
                        <StyleNumericFormat
                            {...field}
                            placeholder={placeholderAmountSelect}
                            autoComplete="off"
                            type="text"
                            prefix={amountValueType === amountValueTypes.Amount ? '$ ' : ''}
                            suffix={amountValueType === amountValueTypes.Percentage ? '%' : ''}
                            decimalSeparator="."
                            thousandSeparator={true}
                            decimalScale={amountValueType === amountValueTypes.Percentage ? 2 : 0}
                            allowNegative={false}
                            value={value}
                            height="38px"
                            width="90%"
                            textAlign="start"
                            fontSize="1rem"
                            FontFamily="Nunito"
                            disabled={amountValueType === amountValueTypes.None}
                            FontWeight="400"
                            Color={errors.amount === undefined ? '#2A2C2F' : '#ef4f55'}
                            radius="2rem"
                            getInputRef={amountTypeRef}
                            onValueChange={(values) => {
                                onChange(values.floatValue);
                            }}
                            hborder="none"
                            borderf="none"
                            padding="0px 2rem"
                        />
                    )}
                />
            </InputCustomContainer>
            {errors.amount && <ErrorMessage message={errors.amount.message || ''} />}
        </ContainerFlex>
    );
};
