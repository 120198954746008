import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    POST_CHANGE_PASSWORD_START,
    POST_CHANGE_PASSWORD_SUCCESS,
    POST_CHANGE_PASSWORD_ERROR,
    GET_RANDOM_PASSWORD_START,
    GET_RANDOM_PASSWORD_SUCCESS,
    GET_RANDOM_PASSWORD_ERROR,
    RESET_DATA_PASSWORD,
    POST_PASSWORD_ADD_MESSAGE,
    POST_PASSWORD_REMOVE_MESSAGE,
} from '@components/CreateUsers/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {IFormEditPassword} from '@/components/ManageUser/interface';

export const fetchPostChangePassStart = () => {
    return {
        type: POST_CHANGE_PASSWORD_START,
    };
};

export const fetchPostChangePassSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: POST_CHANGE_PASSWORD_SUCCESS,
        contact: result.data,
    };
};

export const fetchPostChangePassError = () => {
    return {
        type: POST_CHANGE_PASSWORD_ERROR,
    };
};

export function PostChangePass(token: string, data: IFormEditPassword) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostChangePassStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostChangePassword}`,
                data,
                {headers}
            );

            dispatch(fetchPostChangePassSuccess(response));
        } catch (error) {
            dispatch(fetchPostChangePassError());
        }
    };
}

export const fetchGetRandomPassStart = () => {
    return {
        type: GET_RANDOM_PASSWORD_START,
    };
};

export const fetchGetRandomPassSuccess = (result: AxiosResponse<string>) => {
    return {
        type: GET_RANDOM_PASSWORD_SUCCESS,
        datapass: result,
    };
};

export const fetchGetRandomPassError = () => {
    return {
        type: GET_RANDOM_PASSWORD_ERROR,
    };
};
export function GetPasswordRandom(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostChangePassStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPasswordRandom}`,
                {headers}
            );

            dispatch(fetchGetRandomPassSuccess(response.data.data));
        } catch (error) {
            dispatch(fetchGetRandomPassError());
        }
    };
}

export const ResetRandomPassword = () => {
    return {
        type: RESET_DATA_PASSWORD,
    };
};

export const addMessagePasswordChange = (name: string) => {
    return {
        type: POST_PASSWORD_ADD_MESSAGE,
        name: name,
    };
};

export const removeMessagePasswordChange = () => {
    return {
        type: POST_PASSWORD_REMOVE_MESSAGE,
    };
};
