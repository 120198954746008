import {
    ROLES_PASS,
    GET_ROLE,
    GET_ROLE_ERROR,
    GET_ROLE_SUCCESS,
    GET_ROLE_FINISH,
    SAVE_ROLES,
    SEARCH_VALUE,
    GET_ROLE_SIZE,
    GET_ROLE_SIZE_SUCCESS,
    GET_ROLE_SIZE_ERROR,
    GET_ROLE_TYPE,
    GET_ROLE_TYPE_SUCCESS,
    GET_ROLE_TYPE_ERROR,
    GET_SELECT_ROLE_TYPE,
    GET_SELECT_ROLE_SIZE,
    POST_ADD_NEW_ROLE_PAGE,
    SELECT_SHOW_STATE,
    SELECT_ROLETYPE_STATE,
    RESET_TABLE,
    GET_ROLE_SUCCESS_COPY,
    CURRENT_PAGE,
} from '@TypesRoles/types';
import {IRoleTypes, IRoleTypesRedux} from '@Roles/interface';

const initialState: IRoleTypes = {
    loading: false,
    loadingTable: false,
    isSuccess: false,
    pageNumber: 1,
    tableData: [],
    savedRoles: [],
    duplicateSuccess: false,
    sizeCompany: null,
    searchedValue: '',
    isSearch: [],
    actualPage: 0,
    totalPage: {totalItems: 0},
    error: false,
    rolepass: 1,
    rolesize: {},
    roletype: {},
    selectroletype: {
        id: 0,
        usersQuantity: 0,
        roleId: 0,
        roleName: '',
        users: '',
        roleTypeId: 0,
        roleTypeName: '',
        branch: '',
        description: '',
        default: 0,
    },
    selectrolesize: {},
    addrolepage: null,
    selectrolestate: false,
    selectRoleTypeId: 3,
    getEditRole: null,
};

const AllRolesSetting = (state = initialState, action: IRoleTypesRedux): IRoleTypes => {
    switch (action.type) {
        case CURRENT_PAGE:
            return {
                ...state,
                pageNumber: action.pageNumber,
            };
        case SEARCH_VALUE:
            return {
                ...state,
                searchedValue: action.searchedValue,
            };
        case SAVE_ROLES:
            return {
                ...state,
                savedRoles: action.savedRoles,
            };
        case ROLES_PASS:
            return {
                ...state,
                rolepass: action.rolepass,
            };
        case GET_ROLE:
            return {
                ...state,
                loadingTable: true,
            };
        case GET_ROLE_FINISH:
            return {
                ...state,
                loadingTable: false,
            };
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                tableData: action.tableData,
                isSuccess: true,
            };
        case GET_ROLE_SUCCESS_COPY:
            return {
                ...state,
                totalPage: action.totalPage,
                actualPage: action.actualPage,
                loading: false,
            };
        case RESET_TABLE:
            return initialState;
        case GET_ROLE_ERROR:
            return {
                ...state,
                error: true,
                isSuccess: false,
            };
        case GET_ROLE_SIZE:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLE_SIZE_SUCCESS:
            return {
                ...state,
                loading: false,
                rolesize: action.rolesize,
            };
        case GET_ROLE_SIZE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_ROLE_TYPE:
            return {
                ...state,
                loading: true,
            };
        case GET_ROLE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                roletype: action.roletype,
            };
        case GET_ROLE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_SELECT_ROLE_TYPE:
            return {
                ...state,
                loading: false,
                selectroletype: action.selectroletype,
            };
        case GET_SELECT_ROLE_SIZE:
            return {
                ...state,
                loading: false,
                selectrolesize: action.selectrolesize,
            };
        case POST_ADD_NEW_ROLE_PAGE:
            return {
                ...state,
                loading: false,
                addrolepage: action.addrolepage,
            };
        case SELECT_SHOW_STATE:
            return {
                ...state,
                loading: false,
                selectrolestate: action.selectrolestate,
            };
        case SELECT_ROLETYPE_STATE:
            return {
                ...state,
                loading: false,
                selectRoleTypeId: action.selectRoleTypeId,
            };
        default:
            return state;
    }
};
export default AllRolesSetting;
