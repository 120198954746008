import {AppDispatch} from '@/config/store';
import {
    GET_INFO_CONTRACT,
    GET_INFO_CONTRACT_SUCCESS,
    GET_INFO_CONTRACT_ERROR,
    RESET_INFO_STADISTICS_EDIT,
} from '@components/ShoppingCart/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {IInfoContracts} from '@components/ShoppingCart/interface';

export const resetEditQuoter = () => {
    return {
        type: RESET_INFO_STADISTICS_EDIT,
    };
};

export const getInfoContracts = () => {
    return {
        type: GET_INFO_CONTRACT,
    };
};

export const getInfoContractSuccess = (result: AxiosResponse) => {
    return {
        type: GET_INFO_CONTRACT_SUCCESS,
        payload: result,
    };
};
export const getInfoContractError = (error: AxiosError) => {
    return {
        type: GET_INFO_CONTRACT_ERROR,
        error: error,
    };
};

export function getInfoContract(token: string, filters: IInfoContracts) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(getInfoContracts());
        try {
            const response = await axios.get(
                `
            ${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInfoContract}${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getInfoContractSuccess(response));
        } catch (error) {
            dispatch(getInfoContractError(error as AxiosError));
        }
    };
}
