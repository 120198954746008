import React from 'react';
import {
    CloseButton,
    FilterTagContainer,
} from '@/components/CreditsAndPawns/FilterModal/FilterTagStyles';
import {IFilterTagProps} from '@components/CreditsAndPawns/interfaces';

const FilterTag = ({tagLabel, handleFilter}: IFilterTagProps) => {
    return (
        <FilterTagContainer>
            <span>{tagLabel}</span>
            <CloseButton onClick={handleFilter}>x</CloseButton>
        </FilterTagContainer>
    );
};

export default FilterTag;
