import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/LoginClient/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import logo from '@/images/image 14.png';
import totalGross from '@/images/Icon.svg';
import checkIcon from '@/images/checkIcon.svg';
import BarChartIncome from '@/components/AdminGeneral/BarChartIncome';
import BarChartNetIncome from '@/components/AdminGeneral/BarChartNetIncome';
import LineChartIncome from '@/components/AdminGeneral/lineChart';
import {ADMIN_GENERAL} from '@/components/AdminGeneral/constants';
import trending from '@/images/trending_up.svg';
import trendingRed from '@/images/trending_up_red.svg';

const AdminGeneralModule = () => {
    return (
        <ContainerFlex FlexDir="column" Padding="15px" Justify="start" backG="white">
            <ContainerFlex Justify="start" Align="start" Height="5rem">
                <ImageLogo src={logo} Height="70px" Width="70px" />
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Height="4rem"
                    MarginL="5px"
                >
                    <Text FontSize="1.5rem" FontWeight="700">
                        {ADMIN_GENERAL.name}
                    </Text>
                    <Text Color="#ACACFF">
                        {ADMIN_GENERAL.rfc} &nbsp;{' '}
                        <Text Color="#71767D">{ADMIN_GENERAL.rfcNumber}</Text>
                    </Text>
                </ContainerFlex>
                <ButtonGenerals
                    BackGC="white"
                    Color="#5A5AFF"
                    Border="1px solid #5A5AFF"
                    Width="10rem"
                    Margin="8px 0 0 0"
                >
                    {ADMIN_GENERAL.edit}
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex
                Justify="start"
                Width="95%"
                backG="#FAFAFA"
                Height="16rem"
                Radius="24px"
                Padding="10px"
            >
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    FlexDir="column"
                    Br="1px solid #E5E5FF"
                    Width="25%"
                >
                    <ImageLogo src={totalGross} Height="68px" Width="68px" Margin="15px 0" />
                    <Text color="#71767D">{ADMIN_GENERAL.totalGross}</Text>
                    <Text color="#71767D" MarginB="8px">
                        {ADMIN_GENERAL.month}
                    </Text>
                    <Text FontSize="2rem" FontWeight="700" MarginB="8px">
                        {ADMIN_GENERAL.total}
                    </Text>
                    <Text MarginB="3px" Color="#309C60">
                        <ImageLogo src={trending} Height="22px" Width="22px" />
                        {ADMIN_GENERAL.prevMonth}
                    </Text>
                    <Text Color="#309C60">
                        <ImageLogo src={trending} Height="22px" Width="22px" />
                        {ADMIN_GENERAL.prevMonthYear}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    FlexDir="column"
                    Br="1px solid #E5E5FF"
                    Width="25%"
                >
                    <ImageLogo src={checkIcon} Height="68px" Width="68px" Margin="15px 0" />
                    <Text color="#71767D">{ADMIN_GENERAL.totalNet}</Text>
                    <Text color="#71767D" MarginB="8px">
                        {ADMIN_GENERAL.monthNet}
                    </Text>
                    <Text FontSize="2rem" FontWeight="700" MarginB="8px">
                        {ADMIN_GENERAL.totalNetNumber}
                    </Text>
                    <Text MarginB="3px" Color="#309C60">
                        <ImageLogo src={trending} Height="22px" Width="22px" />
                        {ADMIN_GENERAL.prevMonthNet}
                    </Text>
                    <Text Color="#FF6357">
                        <ImageLogo src={trendingRed} Height="22px" Width="22px" />
                        {ADMIN_GENERAL.prevMonthYear}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Width="50%" Justify="start" Align="center">
                    <BarChartIncome />
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Justify="space-around" Height="27rem" MarginTop="10px">
                <ContainerFlex Width="40%" Border="1px solid #F4F5F5" Padding="8px" Radius="24px">
                    <BarChartNetIncome />
                </ContainerFlex>
                <ContainerFlex Width="50%" Border="1px solid #F4F5F5" Padding="8px" Radius="24px">
                    <LineChartIncome />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AdminGeneralModule;
