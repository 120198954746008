import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import ButtonGeneral from '@General/Atoms/Button';
import {IconSvg} from '@/components/Quoter/styles';
import {useNavigate} from 'react-router';
import usersEmpty from '@components/CreateUsers/Icons/UsersEmpty.svg';
import {selectMultiRoleEmpty} from '@components/CreateUsers/Interface';
import CloseIconGreen from '@Roles/Icons/button_link_close.svg';

export const MultiRolesEmpty = (props: selectMultiRoleEmpty) => {
    const {saveChangesForm, setModalStateRol} = props;
    const navigate = useNavigate();
    return (
        <ContainerFlex backG="#fff" Height="auto" FlexDir="column" BoxShadow="" Justify="start">
            <ContainerFlex
                Justify="space-between"
                Height="56px"
                Bb="solid 1px #35cf44"
                Padding="16.5px 24px"
            >
                <Text Color="#35CF44" Height="56px" FontSize="1.25rem" Width="100%">
                    Asignar rol
                </Text>
                <ImgLocation
                    Cursor="pointer"
                    src={CloseIconGreen}
                    Width="24px"
                    Height="24px"
                    onClick={() => {
                        setModalStateRol(false);
                    }}
                />
            </ContainerFlex>
            <ContainerFlex Width="100%" Justify="flex-start" FlexDir="column" Padding="67px">
                <Text FontSize="0.875rem" Color="#6d6e71" FontWeight="500">
                    No hay roles configurados aún
                </Text>
                <ImgLocation src={usersEmpty} Width="437.5px" Height="327px" Padding="32px" />
                <ButtonGeneral
                    FontSize="0.875rem"
                    text={
                        <>
                            <IconSvg
                                width="24"
                                height="24"
                                viewBox="0 0 24 25"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M11.758 19v-6h-6v-2h6V5h2v6h6v2h-6v6h-2z" fill="#6D6E71" />
                            </IconSvg>
                            <Text Padding="0 8px" Cursor="pointer" HColor="#35cf44">
                                Configurar roles
                            </Text>
                        </>
                    }
                    border="solid 1px #414042"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform=""
                    hText="#35cf44"
                    width="10.875rem"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        navigate('/CreateRoles/AddRoles');
                        if (saveChangesForm) saveChangesForm();
                    }}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
