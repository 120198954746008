import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {ContainerFlex, Modal, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {ILevelDuplicatedTree, IPropsModalOrganization} from '@components/ManageUser/interface';
import {RootState} from '@config/store';
import {Branch} from '@General/BranchTree/interfaces';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {transformToStrings} from '@components/ManageUser/ActionsSidebar/constants';

export const ModalOrganizations = ({
    setOpenModalOrganization,
    levelsBranch,
    setValue,
}: IPropsModalOrganization) => {
    const saveChanges = () => {
        setOpenModalOrganization(false);
        onSubmit();
    };

    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const DuplicatedTree = useSelector((state: RootState) => {
        return state.getOrganizationsTree.copyTree;
    });

    function getBranchNamesByIds(levels: ILevelDuplicatedTree[], ids: number[]): Branch[] {
        const branchNames: Branch[] = [];
        if (levels) {
            levels.forEach((level: ILevelDuplicatedTree) => {
                level.nextLevel.forEach((nextLevel: {branches: Branch[]}) => {
                    nextLevel.branches.forEach((branch) => {
                        if (ids.includes(branch.idBranch)) {
                            branchNames.push(branch);
                        }
                    });
                });
            });
        }
        return branchNames;
    }

    useEffect(() => {
        if (levelsBranch && TreeComponent.data) {
            const levelsId: number[] = levelsBranch.map((level) => level.branchId);
            setBranch(levelsId);
            const data = getBranchNamesByIds(DuplicatedTree.data, levelsId);
            setChecked(transformToStrings(levelsId));
            setBranchData(data);
        }
    }, [levelsBranch]);
    const onSubmit = () => {
        const sendData = branchesData.map((data) => ({
            branchId: data.idBranch,
            branchName: data.branchName,
        }));

        setValue('branchs', sendData);
        setOpenModalOrganization(false);
    };
    return (
        <Modal>
            <ContainerFlex
                backG="#fff"
                Width="500px"
                Height="596px"
                FlexDir="column"
                BoxS="0px 0px 12px rgba(0, 0, 0, 0.5)"
                BoxShadow=""
                Justify="space-between"
            >
                <Text
                    Color="#35CF44"
                    Padding="0 24px"
                    Height="56px"
                    FontSize="1.25rem"
                    BorderBt="1px solid #35CF44"
                    Width="100%"
                >
                    Cambiar organización
                </Text>
                <Text
                    FontSize="0.875rem"
                    Color="#414042"
                    TextAlign="start"
                    Width="100%"
                    Padding="24px 24px 0 24px"
                    Height="auto"
                    LHeight="normal"
                    FlexWrap="wrap"
                    wSpace="pre"
                >
                    <Text FontWeight="500" wSpace="pre">
                        ¡Recuerda!:{' '}
                    </Text>
                    Solo se mostrarán las sucursales a las que el{' '}
                    <Text FontWeight="500" wSpace="pre">
                        {''}ROL{' '}
                    </Text>
                    {''}tiene permiso
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    Padding="24px"
                    Justify="start"
                    Gap="32px"
                    Overflow="auto"
                    Align="start"
                    Height="62%"
                >
                    {DuplicatedTree.data && TreeComponent.data && TreeComponent.data.length && (
                        <TreeBranch
                            checked={checked}
                            branchesId={branchesId}
                            levelsId={levelsId}
                            branchesData={branchesData}
                            setChecked={setChecked}
                            setBranch={setBranch}
                            setLevel={setLevel}
                            setBranchData={setBranchData}
                            isEdit={true}
                        />
                    )}
                </ContainerFlex>
                <ContainerFlex
                    Height="88px"
                    Bt="1px solid #E5E7E9"
                    Justify="end"
                    Gap="24px"
                    Padding="24px"
                >
                    <ButtonGeneral
                        text="Cancelar"
                        width="93px"
                        secondaryButton={true}
                        transform="capitalize"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => {
                            setOpenModalOrganization(false);
                        }}
                        border="1px solid transparent"
                    />
                    <ButtonGeneral
                        disabled={branchesData.length === 0}
                        text="Guardar y continuar"
                        width="168px"
                        border="1px solid #414042"
                        bs="0px 0px 8px rgba(167, 169, 172, 0.25)"
                        color="#414042"
                        hColor="#35CF44"
                        hBorder="#35CF44"
                        hShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                        dFontWeight="400"
                        clic={() => saveChanges()}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
