import styled from '@emotion/styled';
import InputMask from 'react-input-mask';

import {IPropsInputMaskStyled} from '@Roles/InputMaskStyled/interfacesStyles';

export const InputMaskStyled = styled(InputMask)<IPropsInputMaskStyled>`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: ${(props) => props.border};
    border-radius: ${(props) => props.radius};
    text-align: ${(props) => props.textalign};
    opacity: 1;
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};
    padding: ${(props) => props.padding};
    color: ${(props) => props.color};
    cursor: ${(props) => props.cursor};
    font-size: ${(props) => props.fontSize};
    box-sizing: border-box;
    ::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        box-shadow: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #35cf44')};
        outline: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #35cf44')};
    }
    &:hover {
        border: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #35cf44')};
        box-shadow: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #35cf44')};
    }
    &::placeholder {
        color: ${(props) => props.holdercolor};
        font-size: ${(props) => props.holderfontsize};
        line-height: ${(props) => props.holderheight};
        font-weight: ${(props) => props.holderweight};
    }
`;
InputMaskStyled.defaultProps = {
    margin: '0',
    height: '40px',
    width: '',
    cursor: 'text',
    holderheight: '1rem',
    holderweight: '400',
    holdercolor: '#cbced1',
    radius: '5px',
};
