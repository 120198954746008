import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

import {Icons} from '@Articles/style';
import {IconSvg} from '@/components/Quoter/styles';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {SearchQuoterProduct} from '@Quoter/Redux/Actions/QuoterActions';
import {
    getShoppingCartContracts,
    searchEditInfo,
} from '@components/ShoppingCart/Redux/Actions/getCartContractsActions';
import {CalculateAuctionPassQuoter} from '@ActionsQuoter/StatusStepOne';
import {cartContracts, IContract} from '@components/ShoppingCart/interface';
import {changeActualStep, saveIdContract} from '@Quoter/Redux/Actions/saveEndeavors';
import {SHOPPING_CART_CARDS, TYPE_N_PLEDGES} from '@components/ShoppingCart/constants';
import {getInfoContract} from '@components/ShoppingCart/Redux/Actions/getInfoContractActions';
import {DeleteItemPledges} from '@components/ShoppingCart/Redux/Actions/deleteItemPledgeActions';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';

export const ShoppingCartCards = () => {
    const dispatch = useDispatch();
    const [showArticles, setShowArticles] = useState<number[]>([]);
    const infoStadistics = useSelector((state: RootState) => state.getInfoContracts.data);
    const {idContract, actualStep} = useSelector((state: RootState) => state.saveEndeavor);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const branch = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails
    );

    const carPledgeInfo = infoStadistics.carPledge?.[0];
    const branchId = branch ? branch[0].branchId : 2;

    const infoContracts = async (
        contractId: number,
        pledge: IContract[],
        contractPawnId: number
    ) => {
        const articleNames = pledge.map((obj) => obj.articleName);

        if (token && articleNames)
            await dispatch(
                SearchQuoterProduct(token, {
                    branchId: branchId,
                    articleName: articleNames?.[0],
                })
            );

        if (token) {
            await dispatch(getInfoContract(token, {carId: carShoppingId, contractId: contractId}));
        }

        dispatch(searchEditInfo());
        deleteTemporalyPawnAndContracts('CONTRACT', contractPawnId);
    };

    useEffect(() => {
        if (token && carPledgeInfo !== undefined) {
            dispatch(
                CalculateAuctionPassQuoter(token, {
                    branchId: branchId,
                    articleId: carPledgeInfo.pledgeId,
                    value: carPledgeInfo.value,
                    loan: carPledgeInfo.loan,
                    stateConservationId: carPledgeInfo.stateConservationId,
                })
            );
        }
    }, [carPledgeInfo]);

    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );

    const deleteTemporalyPawnAndContracts = async (type: string, contractPledgeId: number) => {
        if (token && type === TYPE_N_PLEDGES.CONTRACT)
            dispatch(
                DeleteTemporalyPawns(
                    token,
                    {deleteAllPawns: false, operationType: 1, contractPawnId: contractPledgeId},
                    carShoppingId
                )
            );
        if (token && type === TYPE_N_PLEDGES.PLEDGES)
            dispatch(DeleteItemPledges(token, {pladgeId: contractPledgeId, carId: carShoppingId}));

        if (contracts && contracts.length === 1) dispatch(changeActualStep(1));
    };

    useEffect(() => {
        if (token) dispatch(getShoppingCartContracts(token, {carshopingId: carShoppingId}));
    }, []);

    if (!contracts || contracts.length === 0) {
        return null;
    }

    return (
        <ContainerFlex FlexDir="column" Height="calc(40vh)" Justify="start">
            {contracts &&
                contracts.length > 0 &&
                contracts.map((contract, index: number) => (
                    <ContainerFlex
                        key={contract.contractId}
                        Height="auto"
                        Align="start"
                        Cursor="pointer"
                        Bb="1px solid #E8E9EA"
                        backG={idContract === contract.contractId ? '#F0F0FF' : '#FFF'}
                        FlexDir="column"
                        onClick={() => dispatch(saveIdContract(contract.contractId))}
                    >
                        <Text
                            bGround="#FFE5FB"
                            Border="1px solid #FFC6F6"
                            Color="#610052"
                            Margin="0.5rem 1.5rem"
                            FontSize="0.75rem"
                            FontWeight="500"
                            Height="1.125rem"
                            Padding="0.563rem 0.5rem"
                            BorderRadius="2rem"
                        >
                            {contract.contractName}
                        </Text>
                        {contract.pledges.length > 1 && (
                            <ContainerFlex
                                Padding="0.5rem 1.5rem"
                                Align="start"
                                Gap="0.5rem"
                                Cursor="pointer"
                            >
                                <Image src={contract.pledges[0]?.imgurl} alt="cart-item" />
                                <ContainerFlex Gap="1.5rem">
                                    <ContainerFlex FlexDir="column" Align="start" Gap="0.5rem">
                                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="500">
                                            {`${contract.pledges.length} artículos`}
                                        </Text>
                                        <Text>
                                            <Text
                                                Color="#54575C"
                                                FontSize="1rem"
                                                MarginRight="0.375rem"
                                            >
                                                {SHOPPING_CART_CARDS.Loan}
                                            </Text>
                                            <Text Color="#2A2C2F" FontSize="1rem">
                                                {formatterDolar.format(
                                                    contract.pledges.reduce(
                                                        (a, b) => a + b.articleLoan,
                                                        0
                                                    )
                                                )}
                                            </Text>
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        onClick={() => {
                                            if (showArticles.includes(contract.contractId))
                                                setShowArticles(
                                                    showArticles.filter(
                                                        (i) => i !== contract.contractId
                                                    )
                                                );
                                            else
                                                setShowArticles([
                                                    ...showArticles,
                                                    contract.contractId,
                                                ]);
                                        }}
                                        Gap="0.5rem"
                                        Justify="end"
                                        Cursor="pointer"
                                        Align="start"
                                    >
                                        <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer">
                                            {showArticles.includes(contract.contractId)
                                                ? 'Ocultar'
                                                : 'Mostrar'}
                                        </Text>
                                        <Icons
                                            Cursor="pointer"
                                            className="material-icons"
                                            Color="#5A5AFF"
                                        >
                                            {showArticles.includes(contract.contractId)
                                                ? 'keyboard_arrow_up'
                                                : 'keyboard_arrow_down'}
                                        </Icons>
                                    </ContainerFlex>
                                </ContainerFlex>
                            </ContainerFlex>
                        )}
                        {showArticles.includes(contract.contractId) &&
                            contract.pledges.length > 1 &&
                            contract.pledges.map((pledge, index) => {
                                return (
                                    <ContainerFlex
                                        Padding="0.5rem 1.5rem"
                                        key={index}
                                        Align="start"
                                        Gap="0.5rem"
                                        backG="#FAFAFA"
                                        Cursor="pointer"
                                    >
                                        <Image src={pledge.imgurl} alt="cart-item" />
                                        <ContainerFlex Gap="1.5rem">
                                            <ContainerFlex
                                                FlexDir="column"
                                                Align="start"
                                                Gap="0.5rem"
                                                Cursor="pointer"
                                            >
                                                <Text
                                                    Color="#2A2C2F"
                                                    FontSize="1rem"
                                                    FontWeight="500"
                                                >
                                                    {pledge.articleName}
                                                </Text>
                                                <Text>
                                                    <Text
                                                        Color="#54575C"
                                                        FontSize="1rem"
                                                        MarginRight="0.375rem"
                                                    >
                                                        {SHOPPING_CART_CARDS.Appraisal}
                                                    </Text>
                                                    <Text Color="#2A2C2F" FontSize="1rem">
                                                        {formatterDolar.format(pledge.articleValue)}
                                                    </Text>
                                                </Text>
                                                <Text>
                                                    <Text
                                                        Color="#54575C"
                                                        FontSize="1rem"
                                                        MarginRight="0.375rem"
                                                    >
                                                        {SHOPPING_CART_CARDS.Loan}
                                                    </Text>
                                                    <Text Color="#2A2C2F" FontSize="1rem">
                                                        {formatterDolar.format(pledge.articleLoan)}
                                                    </Text>
                                                </Text>
                                            </ContainerFlex>
                                            {actualStep === 1 && (
                                                <IconSvg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    Cursor="pointer"
                                                    onClick={() =>
                                                        infoContracts(
                                                            contract.contractId,
                                                            contract.pledges,
                                                            contract.contractId
                                                        )
                                                    }
                                                >
                                                    <title>Icon-Edit</title>
                                                    <path
                                                        d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                                        fill="#5A5AFF"
                                                    />
                                                </IconSvg>
                                            )}

                                            <IconSvg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                Cursor="pointer"
                                                onClick={() =>
                                                    deleteTemporalyPawnAndContracts(
                                                        'PLEDGES',
                                                        pledge.pledgeId
                                                    )
                                                }
                                            >
                                                <title>Icon-Delete</title>
                                                <path
                                                    d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                                    fill="#5A5AFF"
                                                />
                                            </IconSvg>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                );
                            })}

                        {contract.pledges.length === 1 &&
                            contract.pledges.map((pledge, index) => (
                                <ContainerFlex
                                    key={index}
                                    Align="start"
                                    Gap="0.5rem"
                                    Padding="0.5rem 1.5rem"
                                    Cursor="pointer"
                                >
                                    <Image src={pledge.imgurl} alt="cart-item" />
                                    <ContainerFlex Gap="1.5rem">
                                        <ContainerFlex
                                            FlexDir="column"
                                            Align="start"
                                            Gap="0.5rem"
                                            Cursor="pointer"
                                        >
                                            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="500">
                                                {pledge.articleName}
                                            </Text>
                                            <Text>
                                                <Text
                                                    Color="#54575C"
                                                    FontSize="1rem"
                                                    MarginRight="0.375rem"
                                                >
                                                    {SHOPPING_CART_CARDS.Appraisal}
                                                </Text>
                                                <Text Color="#2A2C2F" FontSize="1rem">
                                                    {formatterDolar.format(pledge.articleValue)}
                                                </Text>
                                            </Text>
                                            <Text>
                                                <Text
                                                    Color="#54575C"
                                                    FontSize="1rem"
                                                    MarginRight="0.375rem"
                                                >
                                                    {SHOPPING_CART_CARDS.Loan}
                                                </Text>
                                                <Text Color="#2A2C2F" FontSize="1rem">
                                                    {formatterDolar.format(pledge.articleLoan)}
                                                </Text>
                                            </Text>
                                        </ContainerFlex>
                                        {actualStep === 1 && (
                                            <IconSvg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                Cursor="pointer"
                                                onClick={() =>
                                                    infoContracts(
                                                        contract.contractId,
                                                        contract.pledges,
                                                        contract.contractId
                                                    )
                                                }
                                            >
                                                <title>Icon-Edit</title>
                                                <path
                                                    d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                                    fill="#5A5AFF"
                                                />
                                            </IconSvg>
                                        )}

                                        <IconSvg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            Cursor="pointer"
                                            onClick={() =>
                                                deleteTemporalyPawnAndContracts(
                                                    'CONTRACT',
                                                    contract.contractId
                                                )
                                            }
                                        >
                                            <title>Icon-Delete</title>
                                            <path
                                                d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                                fill="#5A5AFF"
                                            />
                                        </IconSvg>
                                    </ContainerFlex>
                                </ContainerFlex>
                            ))}
                    </ContainerFlex>
                ))}
        </ContainerFlex>
    );
};
