import {ADD_CART_PAYMENT, ADD_CART_PAYMENT_SUCCESS, ADD_CART_PAYMENT_ERROR} from '@TypesLoan/types';
import {ISummaryResult, ISummaryResultRedux} from '@components/Loan/Redux/interfaces';

const initialState: ISummaryResult = {
    loading: false,
    error: null,
    paymentResult: {
        succeeded: false,
        message: '',
        errors: [],
        token: '',
        data: {
            pawnsPay: [],
            endorsementsPay: [],
            dischargesPay: [],
            subTotal: 0,
            iva: 0,
            total: 0,
            cashPayment: 0,
            cardPayment: 0,
            changePayment: 0,
        },
    },
};

const cartPaymentReducer = (state = initialState, action: ISummaryResultRedux): ISummaryResult => {
    switch (action.type) {
        case ADD_CART_PAYMENT:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                paymentResult: action.paymentResult,
            };
        case ADD_CART_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default cartPaymentReducer;
