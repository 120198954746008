import {AppDispatch} from '@/config/store';
import {
    GET_ALL_NATIONS,
    GET_ALL_NATIONS_SUCCESS,
    GET_ALL_NATIONS_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

export const getAllNations = () => {
    return {
        type: GET_ALL_NATIONS,
    };
};

export const getAllNationSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_NATIONS_SUCCESS,
        payload: result.data,
    };
};
export const getAllNationsError = (error: AxiosError) => {
    return {
        type: GET_ALL_NATIONS_ERROR,
        error: error,
    };
};

export function getAllNation(token?: string) {
    const headers = {
        Accept: 'application/json',
        ...(token && {Authorization: 'Bearer ' + token}),
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getAllNations());
        try {
            const response = await axios.get(
                `
            ${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllLadaCountry}`,
                {
                    headers,
                }
            );
            dispatch(getAllNationSuccess(response));
        } catch (error) {
            dispatch(getAllNationsError(error as AxiosError));
        }
    };
}
