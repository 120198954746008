import {DATA_STEP_LOANS, SAVE_LOANS_RESET} from '@components/PersonalLoans/Redux/Types/Types';
import {DataStepProps} from '@components/PersonalLoans/interfaces';

const initialState: DataStepProps = {
    dataStep: 1,
};

const DataStepLoans = (
    state: DataStepProps = initialState,
    action: {type: string; payload: number}
): DataStepProps => {
    switch (action.type) {
        case DATA_STEP_LOANS:
            return {...state, dataStep: action.payload};
        case SAVE_LOANS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default DataStepLoans;
