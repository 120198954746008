import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import arrow from '@/images/chevronRight.svg';
import lastPg from '@/images/last_page.svg';
import {ImageLogo} from '@/components/LoginClient/styles';

const Paginate = ({setCurrentPage, currentPage, totalPages, totalItems}) => {
    return (
        <ContainerFlex Justify="center">
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                Color="#fff"
                onClick={() => setCurrentPage(1)}
            >
                <ImageLogo src={lastPg} Width="15px" Height="15px" Transform="rotate(180deg)" />
            </ButtonGenerals>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1 ? true : false}
            >
                <ImageLogo src={arrow} Width="15px" Height="15px" Transform="rotate(180deg)" />
            </ButtonGenerals>
            <Text FontSize="1rem" Padding="0 20px">
                {currentPage}-{totalItems} de {totalPages}
            </Text>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages ? true : false}
            >
                <ImageLogo src={arrow} Width="15px" Height="15px" />
            </ButtonGenerals>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                onClick={() => setCurrentPage(totalPages)}
            >
                <ImageLogo src={lastPg} Width="15px" Height="15px" />
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default Paginate;
