import React from 'react';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import {NEWFEATURE} from '@components/Catalogue/constants';
import {NewFeatureInputNameProps} from '@components/Catalogue/interfaces';

export const NewFeatureInputName = ({register, errors}: NewFeatureInputNameProps) => {
    return (
        <>
            <ContainerFlex Align="start" FlexDir="column">
                <Text Color="1D1E20" FontWeight="700" FontFamily="Nunito" MarginB="0.25rem">
                    {NEWFEATURE.NAME}
                </Text>
                <Input {...register('featureName')} Width="100%" />
                {errors.featureName && (
                    <Text Color="red" Margin="4px 0px 0px 0px">
                        {errors.featureName?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
