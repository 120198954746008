import {AxiosResponse} from 'axios';

import {
    ADD_CATALOG_ARTICLE,
    ADD_CATALOG_ARTICLE_ERROR,
    ADD_CATALOG_ARTICLE_SUCCESS,
} from '@Shopify/Products/redux/types/ProductsShopifyTypes';
const initialState = {
    error: false,
    success: false,
    data: [],
};

const addCatalogRequest = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case ADD_CATALOG_ARTICLE:
            return {
                ...state,
                error: false,
                success: false,
            };
        case ADD_CATALOG_ARTICLE_SUCCESS:
            return {
                ...state,
                success: true,
                data: actions.payload.data,
            };
        case ADD_CATALOG_ARTICLE_ERROR:
            return {
                ...state,
                error: true,
                success: false,
            };
        default:
            return state;
    }
};

export default addCatalogRequest;
