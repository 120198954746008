import React from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import PageNext from '@images/FirsLast.svg';
import LittleArrow from '@images/arrow small.svg';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';

interface PaginatedForTransactionTableProps {
    pageNumber: number;
    totalPage: number;
    totalItems: number;
    nextPage: () => void;
    prevPage: () => void;
    firstPage: () => void;
    lastPage: () => void;
}

export const PaginatedForTransactionTable: React.FC<PaginatedForTransactionTableProps> = ({
    pageNumber,
    totalPage,
    totalItems,
    firstPage,
    nextPage,
    prevPage,
    lastPage,
}) => {
    const message = `${pageNumber} - ${totalItems} de ${totalPage}`;
    return (
        <ContainerFlex backG="none" Height="40px" Width="30%">
            <ButtonGenerals
                Height="14px"
                Cursor="pointer"
                BackGC="none"
                HBackG="none"
                Width="1rem"
                onClick={() => firstPage()}
                disabled={pageNumber === 1 ? true : false}
            >
                <Image src={PageNext} Height="22px" />
            </ButtonGenerals>
            <ButtonGenerals
                Height="16px"
                Cursor="pointer"
                onClick={() => prevPage()}
                BackGC="none"
                HBackG="none"
                Width="1rem"
                disabled={pageNumber === 1 ? true : false}
            >
                <Image src={LittleArrow} Transform="rotate(180deg)" Height="20px" />
            </ButtonGenerals>
            <Text Color="#54575C">{message}</Text>
            <ButtonGenerals
                Height="16px"
                Cursor="pointer"
                onClick={() => nextPage()}
                BackGC="none"
                HBackG="none"
                Width="1rem"
                disabled={pageNumber >= totalPage ? true : false}
            >
                <Image src={LittleArrow} Height="20px" />
            </ButtonGenerals>

            <ButtonGenerals
                Height="16px"
                Cursor="pointer"
                BackGC="none"
                HBackG="none"
                Width="1rem"
                disabled={pageNumber >= totalPage ? true : false}
                onClick={() => lastPage()}
            >
                <Image src={PageNext} Transform="rotate(180deg)" Height="22px" />
            </ButtonGenerals>
        </ContainerFlex>
    );
};
