import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import arrowSmallLeft from '@/components/CreditsAndPawns/icons/arrowSmallLeft.svg';
import noteIllustrationIcon from '@/components/CreditsAndPawns/icons/noteIllustrationIcon.svg';
import {CREDIT_REQUEST_NOTES} from '@/components/CreditsAndPawns/constants';

const CreditRequestNotes = () => {
    return (
        <ContainerFlex
            Height="240px"
            Width="506px"
            Radius="24px"
            Border="1px solid #E8E9EA"
            backG="#fff"
            FlexDir="column"
            Gap="24px"
            Justify="start"
            Padding="16px 24px"
        >
            <ContainerFlex Justify="space-between" Align="start" Width="100%" Height="24px">
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.25rem"
                    Color="#54575C"
                    FontStyle="normal"
                    LHeight="1.50rem"
                >
                    {CREDIT_REQUEST_NOTES.SUB_TITLE}
                </Text>
                <Image src={arrowSmallLeft} Height="24px" width="24px" />
            </ContainerFlex>
            <ContainerFlex
                Height="100%"
                Width="100%"
                backG={`url(${noteIllustrationIcon}) no-repeat center`}
            />
        </ContainerFlex>
    );
};

export default CreditRequestNotes;
