import {
    TYPE_LOCATIONS_SELECTED,
    RESET_SELECT_LOCATIONS,
} from '@components/LocationsBranch/Redux/types';
import {ITypesLocations} from '@components/LocationsBranch/interfaces';

export const fetchSelectTypeGroup = (result: ITypesLocations) => {
    return {type: TYPE_LOCATIONS_SELECTED, typeSelected: result};
};

export const resetTypeLocations = () => ({type: RESET_SELECT_LOCATIONS});
