import {
    CATALOG_EXCEDENT,
    CATALOG_EXCEDENT_SUCCESS,
    CATALOG_EXCEDENT_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';
const initialState = {
    data: [],
    loading: false,
    error: false,
};
interface Status {
    id: number;
    name: string;
}

interface Data {
    tokenExpiration: number;
    succeeded: boolean;
    message: null | string;
    errors: null | string;
    token: string;
    data: Status[];
}

export interface IExceesCatalog {
    data: Data;
    loading: boolean;
    error: boolean;
}
const getCatalogExcess = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case CATALOG_EXCEDENT:
            return {
                ...state,
                loading: true,
            };
        case CATALOG_EXCEDENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case CATALOG_EXCEDENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getCatalogExcess;
