export const ADD_COUNT_MARKET_LOGIN_USER = 'ADD_COUNT_MARKET_LOGIN_USER';
export const ADD_CODE_MARKET_LOGIN_USER = 'ADD_CODE_MARKET_LOGIN_USER';
export const RESET_CODE_MARKET_LOGIN_USER = 'RESET_CODE_MARKET_LOGIN_USER';

export const VERIFY_CODE_REQUEST_CLIENT = 'VERIFY_CODE_REQUEST_CLIENT';
export const VERIFY_CODE_SUCCESS_CLIENT = 'VERIFY_CODE_SUCCESS_CLIENT';
export const VERIFY_CODE_ERROR_CLIENT = 'VERIFY_CODE_ERROR_CLIENT';

export const VERIFY_CODE_NEW_REQUEST_CLIENT = 'VERIFY_CODE_NEW_REQUEST_CLIENT';
export const VERIFY_CODE_NEW_SUCCESS_CLIENT = 'VERIFY_CODE_NEW_SUCCESS_CLIENT';
export const VERIFY_CODE_NEW_ERROR_CLIENT = 'VERIFY_CODE_NEW_ERROR_CLIENT';

export const ADD_EMAIL_USER_CLIENT = 'ADD_EMAIL_USER_CLIENT';
export const ADD_EMAIL_MARKET_LOGIN_USER = 'ADD_EMAIL_MARKET_LOGIN_USER';
export const RESET_EMAIL_MARKET_LOGIN_USER = 'RESET_EMAIL_MARKET_LOGIN_USER';

export const ADD_EMAIL_USER = 'ADD_EMAIL_USER';

export const NEW_PASSWORD_USER_REQUEST = 'NEW_PASSWORD_USER_REQUEST';
export const NEW_PASSWORD_USER_SOUCCES = 'NEW_PASSWORD_USER_SOUCCES';
export const NEW_PASSWORD_USER_ERROR = 'NEW_PASSWORD_USER_ERROR';
export const VERIFY_CODE_RESET_ERROR = 'VERIFY_CODE_RESET_ERROR,';
