import styled from '@emotion/styled';
import {IButtonPagerProps} from '@components/Users/interfaceStyles';

export const ButtonPager = styled.button<IButtonPagerProps>`
    background-color: ${(props) => (props.BGColor ? props.BGColor : 'white')};
    border: none;
    width: auto;
    cursor: pointer;
    padding: ${(props) => (props.Padding ? props.Padding : '0.3rem 0 0 0')};
    &:disabled {
        cursor: ${(props) => (props.OnDisabled ? props.OnDisabled : 'not-allowed')};
    }
`;
