import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import addPlusIcon from '@components/PersonalLoans/NewLoanProduct/icons/addPlusIcon.svg';
import {IBtnShowFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const BtnShowField: React.FC<IBtnShowFieldProps> = ({text, onHandleClick, width}) => {
    return (
        <ContainerFlex Width={width} Height="52px" Justify="start" Align="end">
            <ContainerFlex
                Width="max-content"
                onClick={onHandleClick}
                Gap="0.5rem"
                Cursor="pointer"
                Justify="start"
                Align="end"
            >
                <Image src={addPlusIcon} Width="24" Height="24px" alt="add-plus-icon" />
                <Text FontWeight="700" Color="#5A5AFF" Cursor="pointer">
                    {text}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
