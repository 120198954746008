import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import InfoBlock from '@/components/Shopify/ClientProfile/Information/InfoBlock';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {EMPLOYMENT_INFORMATION} from '@Shopify/ClientProfile/constants';

const EmploymentInfo: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const clientLaborInfo = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.employmentInformation
    );
    return (
        <ContainerFlex
            Padding="1rem 1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1rem"
            Radius="1.5rem"
            Border="1px solid #E8E9EA"
            Self="stretch"
        >
            <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                {EMPLOYMENT_INFORMATION.TITLE}
            </Text>
            <ContainerFlex
                Align="flex-start"
                Justify="start"
                FlexDir="column"
                AlignContent="flex-start"
                Gap="1rem"
                FlexWrap="nowrap"
            >
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.EMPLOYMENT_STATUS}
                        value={clientLaborInfo?.workSituation}
                    />
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.COMPANY_NAME}
                        value={clientLaborInfo?.companyName}
                    />
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.COMPANY_PHONE}
                        value={clientLaborInfo?.companyPhone}
                    />
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.POSITION}
                        optional={EMPLOYMENT_INFORMATION.OPTIONAL}
                        value={clientLaborInfo?.position}
                    />
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Justify="flex-start">
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.SENIORITY}
                        value={clientLaborInfo?.yearsOfSeniorityAtWork}
                    />
                    <InfoBlock
                        label={EMPLOYMENT_INFORMATION.MONTHLY_INCOME}
                        value={numberToCurrency(clientLaborInfo?.monthlyIncome as number)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default EmploymentInfo;
