import * as yup from 'yup';

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\S]{8,16}$/;
const PHONE_REGEX = /^[0-9]{10,}$/;

export const schemaPassword = yup.object().shape({
    newPassword: yup
        .string()
        .required('Ingrese una contraseña')
        .matches(
            PASS_REGEX,
            'Mínimo 8 caracteres, entre ellas 1 mayúscula, números y caracteres especiales.'
        ),
    userId: yup.string().required(),
});

export const schemaForm = yup.object().shape({
    userEmployeeId: yup.number().required(),
    userEmployeeName: yup.string().required('Ingrese un nombre').max(160),
    usersLastName: yup.string().required('Ingrese un apellido').max(160),
    userName: yup.string().required('Ingrese un nombre de usuario').max(160),
    job: yup.string().required('Ingrese un puesto').max(160),
    adress: yup.string().required('Ingrese una dirección').max(160),
    email: yup
        .string()
        .matches(EMAIL_REGEX, 'Ingrese un correo electrónico válido')
        .required('Ingrese un correo'),
    phone: yup
        .string()
        .required('Ingrese un número de teléfono')
        .matches(
            PHONE_REGEX,
            'El campo debe contener solo números y tener al menos 10 caracteres.'
        ),
    statusUser: yup.boolean().required(),
    inmediateBoss: yup.string().required('Ingrese el nombre del jefe inmediato').max(160),
    costCenter: yup.string().required('Ingrese los centrois de costos').max(160),
    companyId: yup.number().required(),
    organization: yup.string().required('Ingrese los nombres de organización').max(160),
    branchs: yup.array().min(1),
    roles: yup.array().min(1),
    userSystemAccesses: yup.object().shape({
        userSystemAccessId: yup.number().required(),
        outWorkingHours: yup.boolean().required(),
        accessOndaysOff: yup.boolean().required(),
        accessOnPeriodVacation: yup.boolean().required(),
        accessOnRestPeriod: yup.boolean().required(),
    }),
    operatingSystems: yup.array(),
    administrativeSystems: yup.array(),
});
