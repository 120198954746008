import * as yup from 'yup';

export const yupClientDocuments = yup.object().shape({
    imageDocFront: yup
        .string()
        .required('Imagen frontal de identificación es requerida.')
        .test('fileType', 'La imagen debe ser .jpg o .png', function (value) {
            return !!value && /\.(jpeg|jpg|png)$/i.test(value);
        }),
    imageDocBack: yup
        .string()
        .required('Imagen reversa de identificación es requerida.')
        .test('fileType', 'La imagen debe ser .jpg o .png', function (value) {
            return !!value && /\.(jpeg|jpg|png)$/i.test(value);
        }),
});
