export const APP_SETTINGS = {
    SETTINGS: 'Ajustes de la app',
    LANGUAJE: 'Idioma',
    COUNTRY: 'País',
    TIME_ZONE: 'Zona horaria',
    COIN_TYPE: 'Moneda',
    NOTIFICATIONS: 'Notificaciones',
    WITHOUT_INFO: 'Información adicional',
    MEXICO: 'México',
    AUTOMATIC_TIME_ZONE: 'Definir automáticamente',
    SESION: 'Inicios de sesión',
    NEW_FUNCTIONS: 'Nuevas funciones',
    CHANGES_ACCOUNT: 'Cambios en la cuenta y empresa',
    CHANGES_PLAN: 'Cambios en planes y adicionales',
    SEARCH_NOTIFY: '¿Estás buscando las notificaciones para roles?',
};
