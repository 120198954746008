import styled from '@emotion/styled';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import {IContainerFlex} from '@components/Shopify/Ecommerce/interfacesStyles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {IPropsButtonGenerals} from '@components/General/Atoms/Button/interfacesStyles';

export const GridContainer = styled(ContainerFlex)<IContainerFlex>`
    display: grid;
`;

export const HiddenButton = styled(ButtonGenerals)<IPropsButtonGenerals>`
    display: none;
`;
