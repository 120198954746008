import {
    GET_ALL_NATIONS,
    GET_ALL_NATIONS_SUCCESS,
    GET_ALL_NATIONS_ERROR,
} from '@components/Quoter/CoownerBeneficiary/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: [],
};

const getAllNations = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_ALL_NATIONS:
            return {...state, loading: true};
        case GET_ALL_NATIONS_SUCCESS:
            return {...state, loading: false, data: action.payload.data};
        case GET_ALL_NATIONS_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default getAllNations;
