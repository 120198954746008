import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {InputData} from '@/components/MyCompany/styles';
import SearchImg from '@/images/search.svg';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import FilterImg from '@/images/filter_alt.svg';
import {ClickAwayListener, Table} from '@mui/material';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import moreVertIcon from '@/images/more_vert.svg';
import waringIcon from '@/images/warningIconeYellow.svg';
import {ImageLogo} from '@/components/LoginClient/styles';
import SideMenu from '@/components/AdminCashflow/SideMenu';
import {CASHFLOWADMIN} from '@/components/AdminCashflow/constants';
import {getCurrentDateInSpanish} from '@/components/AdminCashflow/date';
import Paginate from '@/components/AdminCashflow/Paginate';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getAdminCashflowAction} from '@components/AdminCashflow/Redux/AdminCashflowAction';
import {IAdminBranches} from '@components/AdminCashflow/Interfaces';

const AdminCashflow = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');

    const date = getCurrentDateInSpanish();

    const handleToggle = (branchId: number) => {
        if (openDropdownId === branchId) {
            setIsOpen(!isOpen);
        } else {
            setIsOpen(true);
            setOpenDropdownId(branchId);
        }
    };

    const handleIcon = (income: number) => {
        if (income <= 0) return <ImageIcon src={waringIcon} />;
    };

    useEffect(() => {
        dispatch(getAdminCashflowAction(search, 10, currentPage, token));
    }, [search, currentPage]);

    const data = useSelector(
        (state: RootState) => state.getCashflowAdmin.cashflowData,
        shallowEqual
    );

    const currentBranches = data ? data : [];
    const totalItems = useSelector(
        (state: RootState) => state.getCashflowAdmin.totalItems,
        shallowEqual
    );
    const totalPages = useSelector(
        (state: RootState) => state.getCashflowAdmin.totalPages,
        shallowEqual
    );

    return (
        <ContainerFlex FlexDir="column" Justify="start" Padding="30px" Align="start">
            <Text FontSize="1.5rem" FontWeight="600">
                {CASHFLOWADMIN.cashflow}
            </Text>
            <Text>{date}</Text>

            <ContainerFlex Align="start" Justify="space-between" Height="4rem" MarginTop="15px">
                <SearchInput Height="40px" FontSize="1rem" Right="none" Width="auto%">
                    <InputData Width="100%" onChange={(e) => setSearch(e.target.value)} />
                    <ImageIcon src={SearchImg} />
                </SearchInput>
                <ButtonGenerals
                    BackGC="white"
                    Color="#5A5AFF"
                    Border="1px solid #5A5AFF"
                    Width="8rem"
                >
                    <ImageIcon src={FilterImg} />
                    {CASHFLOWADMIN.filter}
                </ButtonGenerals>
            </ContainerFlex>
            <Table>
                <TableRowHead Height="40px" GridColumn="repeat(7, 1fr)" BackG="none" Border="none">
                    <Text FontWeight="500" Padding="4px 0px">
                        {CASHFLOWADMIN.branch}
                    </Text>
                    <Text FontWeight="500" Padding="4px 0px">
                        {CASHFLOWADMIN.location}
                    </Text>
                    <Text FontWeight="500" Padding="4px 0px">
                        {CASHFLOWADMIN.status}
                    </Text>
                    <Text FontWeight="500" Padding="4px 0px">
                        {CASHFLOWADMIN.userBranch}
                    </Text>
                    <Text FontWeight="500" Padding="4px 0px" JustSelf="end">
                        {CASHFLOWADMIN.cashBranch}
                    </Text>
                    <Text FontWeight="500" Padding="4px 0px" JustSelf="end">
                        {CASHFLOWADMIN.netIncomeDay}
                    </Text>
                </TableRowHead>
                <TableItemsContainer style={{overflow: 'visible'}}>
                    {currentBranches.map((i: IAdminBranches) => (
                        <TableRowItems
                            key={i.branchId}
                            Height="40px"
                            GridColumn="repeat(7, 1fr)"
                            BackGround="none"
                            BorderBottom="1px solid #E8E9EA"
                            Border="none"
                            BorderRadius="0px"
                        >
                            <TextEllipsis FontSize="14px" FontWeight="300" Padding="4px 0px">
                                {i.branchName}
                            </TextEllipsis>
                            <TextEllipsis FontSize="14px" FontWeight="300" Padding="4px 0px">
                                {i.branchAddress}
                            </TextEllipsis>
                            {i.isBranchOpen ? (
                                <Text
                                    FontSize="14px"
                                    FontWeight="600"
                                    Padding="4px 0px"
                                    Color="#174A2E"
                                    bGround="#E4F7EC"
                                    Width="40%"
                                    Height="70%"
                                    BorderRadius="20px"
                                    Display="block"
                                    TextAlign="center"
                                    Border="2px solid #B1E7C9"
                                >
                                    {CASHFLOWADMIN.open}
                                </Text>
                            ) : (
                                <Text
                                    FontSize="14px"
                                    FontWeight="600"
                                    Padding="4px 0px"
                                    bGround="#F4F5F5"
                                    Width="40%"
                                    Height="70%"
                                    BorderRadius="20px"
                                    Display="block"
                                    TextAlign="center"
                                    Border="2px solid #D4D6D8"
                                >
                                    {CASHFLOWADMIN.closed}
                                </Text>
                            )}
                            <Text FontSize="14px" FontWeight="300" Padding="4px 0px">
                                {i.branchUsers}
                            </Text>
                            <Text FontSize="14px" FontWeight="300" Padding="4px 0px" JustSelf="end">
                                {handleIcon(i.dailyCashRevenue)}
                                {i.branchCashAmount.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                })}
                            </Text>

                            {i.dailyCashRevenue >= 0 ? (
                                <Text
                                    FontSize="14px"
                                    FontWeight="300"
                                    Padding="4px 0px"
                                    JustSelf="end"
                                    Color="#309C60"
                                >
                                    {i.dailyCashRevenue.toLocaleString('es-MX', {
                                        style: 'currency',
                                        currency: 'MXN',
                                    })}
                                </Text>
                            ) : (
                                <Text
                                    FontSize="14px"
                                    FontWeight="300"
                                    Padding="4px 0px"
                                    JustSelf="end"
                                    Color="#A82424"
                                >
                                    ${i.dailyCashRevenue}
                                </Text>
                            )}
                            <ContainerFlex Position="relative" OverFlowY="visible">
                                <ImageLogo
                                    src={moreVertIcon}
                                    alt="more"
                                    Width="18px"
                                    Height="18px"
                                    Margin="auto"
                                    Cursor="pointer"
                                    onClick={() => handleToggle(i.branchId)}
                                />

                                {isOpen && openDropdownId === i.branchId && (
                                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                                        <ContainerFlex Position="relative" OverFlowY="visible">
                                            <SideMenu />
                                        </ContainerFlex>
                                    </ClickAwayListener>
                                )}
                            </ContainerFlex>
                        </TableRowItems>
                    ))}
                </TableItemsContainer>
                <Paginate
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalItems={totalItems}
                />
            </Table>
        </ContainerFlex>
    );
};

export default AdminCashflow;
