import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_PERSONAL_CREDIT_PRODUCTS_START,
    GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS,
    GET_PERSONAL_CREDIT_PRODUCTS_ERROR,
} from '@components/LoansProducts/Redux/Types/Types';
import {
    IPersonalCreditProductsInitialState,
    IProductsData,
} from '@components/LoansProducts/interfaces';

const initialState: IPersonalCreditProductsInitialState = {
    data: {
        companyId: 0,
        loanProducts: 0,
        activeProducts: 0,
        inactiveProducts: 0,
    },
    loading: false,
    error: false,
};

export const getPersonalCreditProductsReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_PERSONAL_CREDIT_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS:
            return {
                data: action.payload.data.data as IProductsData,
                loading: false,
                error: false,
            };
        case GET_PERSONAL_CREDIT_PRODUCTS_ERROR:
            return {
                ...state,
                error: true,
            };
        default:
            return state;
    }
};
