export const roleSettingsText = {
    roleSettings: 'Configuración de roles',
    roleSelect: 'Selecciona el rol del usuario',
    roleassign:
        'Asigna un rol administrativo que este usuario fungirá en la empresa, esto afectará los permisos y accesos que tiene dentro de la plataforma.',
    role: 'Rol',
    roleMessage: 'Si deseas configurar los acceso a roles ve a roles',
    roleLink: 'Ir a configurar roles',
    cancel: 'Descartar',
    save: 'Guardar',
};

export interface Option {
    id: number;
    label: string;
}

export const options: Option[] = [
    {id: 1, label: 'Admin'},
    {id: 2, label: 'Cajero'},
    {id: 3, label: 'Depositario'},
    {id: 4, label: 'Director'},
    {id: 5, label: 'Financiero'},
    {id: 6, label: 'Gerente divisional'},
    {id: 7, label: 'Gerente general'},
    {id: 8, label: 'Gerente regional'},
];
