import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {TAB_HEADER_TEXT, TAB_TITLES} from '@components/CreditsAndPawns/constants';
import {numberToCurrency} from '@CashFlow/constants';
import {ICreditsStatisticsProps} from '@components/CreditsAndPawns/interfaces';
import moneyIcon from '@components/CreditsAndPawns/icons/moneyIcon.svg';
import moneyIconSelected from '@components/CreditsAndPawns/icons/moneyIconSelected.svg';
import priceCalculatorIcon from '@components/CreditsAndPawns/icons/priceCalculator.svg';
import priceCalculatorSelected from '@components/CreditsAndPawns/icons/priceCalculatorSelected.svg';

export const CreditsStatistics = ({
    statisticsFilter,
    setStatisticsFilter,
    statisticsData,
    loading,
}: ICreditsStatisticsProps) => {
    const tabHeaders = [
        {
            tabHeader: TAB_HEADER_TEXT.CREDITS,
            icon: moneyIcon,
            iconSelected: moneyIconSelected,
        },
        {
            tabHeader: TAB_HEADER_TEXT.PAWNS,
            icon: priceCalculatorIcon,
            iconSelected: priceCalculatorSelected,
        },
    ];
    const tabTable = [
        {title: TAB_TITLES.TOTAL, amount: statisticsData?.totalAvailable},
        {title: TAB_TITLES.BRANCH, amount: statisticsData?.branchAvailable},
        {title: TAB_TITLES.CREDITS, amount: statisticsData?.investedCredit},
        {title: TAB_TITLES.APPROVED_CREDITS, amount: statisticsData?.approvedCredit},
    ];
    return (
        <>
            <ContainerFlex Bb="1px solid #E8E9EA" Justify="start">
                {tabHeaders.map((header, index) => (
                    <Text
                        Padding="0.5rem 1rem 0.5rem 1rem"
                        Cursor="pointer"
                        Color={statisticsFilter === header.tabHeader ? '#5A5AFF' : '#2A2C2F'}
                        Gap="0.5rem"
                        FontFamily="Nunito"
                        FontWeight={statisticsFilter === header.tabHeader ? '700' : '400'}
                        BorderBt={
                            statisticsFilter === header.tabHeader ? '1.2px solid #5A5AFF' : ''
                        }
                        key={index}
                        onClick={() => setStatisticsFilter(header.tabHeader)}
                    >
                        <Image
                            Width="20px"
                            Height="20px"
                            src={
                                statisticsFilter === header.tabHeader
                                    ? header.iconSelected
                                    : header.icon
                            }
                        />
                        {header.tabHeader}
                    </Text>
                ))}
            </ContainerFlex>

            <ContainerCellGrid
                Padding="1rem 0px"
                bGround="#fff"
                BorderR="1.5rem"
                Border="1px solid #E8E9EA"
                Column="repeat(4,1fr)"
            >
                {tabTable.map((data, index) => (
                    <ContainerFlex
                        FlexDir="column"
                        Gap="0.5rem"
                        Padding="0px 1.5rem"
                        Br={index !== tabTable.length ? '1px solid #E8E9EA' : ''}
                        key={index}
                    >
                        <Text
                            FontFamily="Nunito"
                            FontSize="0.875rem"
                            FontWeight="400"
                            Color="#54575C"
                        >
                            {data.title}
                        </Text>
                        {!loading && statisticsData && (
                            <Text FontFamily="Nunito" FontSize="1.25rem" FontWeight="700">
                                {index !== 3
                                    ? `${numberToCurrency(data.amount)}`
                                    : `${data.amount}`}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
            </ContainerCellGrid>
        </>
    );
};
