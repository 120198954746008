import React, {useState, forwardRef, useImperativeHandle} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';

import arrowIcon from '@images/arrowDark.svg';
import {PAYMENT_GATEWAY, InputFormStyle} from '@Loan/PaymentQuoter/constants';
import {ContainerForm, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import FormInput from '@General/Atoms/LabeledInput/FormInput';
import {schemaPaymentGatewayCashEntry} from '@Loan/PaymentQuoter/yupPaymentGateway';

const PaymentGatewayCashEntry = forwardRef((_, ref) => {
    const [showCashForm, setShowCashForm] = useState(false);

    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaPaymentGatewayCashEntry),
        defaultValues: {
            cashAmount: '',
            changeAmount: '',
        },
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });

    const {
        register,
        control,
        formState: {errors},
    } = formMethods;

    useImperativeHandle(ref, () => ({
        getValues: formMethods.getValues,
        reset: formMethods.reset,
        hasErrors: () => Object.keys(formMethods.formState.errors).length > 0,
        submitForm: async () => {
            const values = await formMethods.trigger();
            if (!values) return;
        },
    }));

    return (
        <FlexPanel
            Padding="0 1rem 1rem 1rem"
            FlexDir="column"
            Align="flex-start"
            Self="stretch"
            Gap="1.5rem"
        >
            <FlexPanel
                Align="flex-start"
                Gap="1rem"
                FlexDir="column"
                Width="100%"
                Bb={`1px solid ${showCashForm ? '#5A5AFF' : '#E8E9EA'}`}
                Padding="0 0 1rem"
            >
                <FlexPanel
                    Align="center"
                    Gap="1rem"
                    Cursor="pointer"
                    onClick={() => setShowCashForm(!showCashForm)}
                >
                    <Image
                        src={arrowIcon}
                        alt="arrow"
                        Width="1.5rem"
                        Height="1.5rem"
                        Transition="transform 0.3s ease"
                        Transform={showCashForm ? 'rotate(90deg)' : 'rotate(180deg)'}
                    />
                    <Text
                        Color="#2A2C2F"
                        FontSize="1rem"
                        FontWeight="700"
                        LetterSpacing="0.019rem"
                        Cursor="inherit"
                    >
                        {PAYMENT_GATEWAY.CASH}
                    </Text>
                </FlexPanel>
            </FlexPanel>
            {showCashForm && (
                <FormProvider {...formMethods}>
                    <ContainerForm FlexDir="column" Justify="start" Align="flex-start">
                        <FlexPanel
                            Gap="0.5rem 1.5rem"
                            Justify="flex-start"
                            Align="flex-start"
                            FlexWrap="wrap"
                        >
                            <FlexPanel Justify="start" Align="center" Width="14.354rem">
                                <FormInput
                                    name="cashAmount"
                                    label={PAYMENT_GATEWAY.ENTER_CASH_AMOUNT}
                                    control={control}
                                    register={register}
                                    error={errors.cashAmount?.message?.toString()}
                                    styles={InputFormStyle}
                                    prefix={
                                        <Text
                                            Color="#2A2C2F"
                                            FontSize="1rem"
                                            LetterSpacing="0.019rem"
                                            oFlow="visible"
                                        >
                                            $
                                        </Text>
                                    }
                                />
                            </FlexPanel>
                            <FlexPanel Justify="start" Align="center" Width="14.354rem">
                                <FormInput
                                    name="changeAmount"
                                    label={PAYMENT_GATEWAY.CHANGE}
                                    control={control}
                                    register={register}
                                    error={errors.changeAmount?.message?.toString()}
                                    styles={InputFormStyle}
                                />
                            </FlexPanel>
                        </FlexPanel>
                    </ContainerForm>
                </FormProvider>
            )}
        </FlexPanel>
    );
});
export default PaymentGatewayCashEntry;
