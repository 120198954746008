import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@config/store';
import {ContainerFlex, Text, Figure, Image} from '@Shopify/Ecommerce/styles';
import ChevronRightGreen from '@components/ManageUser/Icons/chevronRightGreen.svg';
import {EditInformation} from '@components/ManageUser/Form/EditInformation';
import {ViewInformationUser} from '@components/ManageUser/ViewInformationUser';
import {UserData} from '@components/ManageUser/Redux/interface';

export const InformationUser = () => {
    const [isEditInfo, setIsEditInfo] = useState(false);
    const UserInformation: UserData = useSelector((state: RootState) => state.userInformation.data);
    return (
        <ContainerFlex
            Height="auto"
            Border="1px solid #E5E7E9"
            Radius="4px"
            FlexDir="column"
            Justify="space-between"
        >
            <Text
                Height="56px"
                Width="100%"
                BorderBt="0.5px solid #E5E7E9"
                BorderR="4px"
                bGround="#F3F3F3"
                Color="#414042"
                Justify="center"
                FontWeight="500"
            >
                Información del usuario
            </Text>
            <ContainerFlex Height="48px" Padding="16px" Justify="space-between">
                <Text FontSize="0.875rem" Color="#414042" FontWeight="500">
                    Información del contacto
                </Text>
                {!isEditInfo && UserInformation.statusUser && (
                    <Text
                        Color="#35CF44"
                        FontSize="0.875rem"
                        Gap="4px"
                        Cursor="pointer"
                        onClick={() => setIsEditInfo(true)}
                    >
                        Editar
                        <Figure Width="12px" Height="12px">
                            <Image src={ChevronRightGreen} />
                        </Figure>
                    </Text>
                )}
            </ContainerFlex>
            {!isEditInfo ? (
                <ViewInformationUser />
            ) : (
                <EditInformation setIsEditInfo={setIsEditInfo} />
            )}
        </ContainerFlex>
    );
};
