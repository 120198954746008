import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_USER_INFORMATION_BY_ID,
    GET_USER_INFORMATION_BY_ID_SUCCESS,
    GET_USER_INFORMATION_BY_ID_ERROR,
} from '@components/ManageUser/Redux/types';
import {AppDispatch} from '@/config/store';

export const getUserInfo = () => {
    return {
        type: GET_USER_INFORMATION_BY_ID,
    };
};
export const getUserInfoSuccess = (result: AxiosResponse<string>) => {
    return {type: GET_USER_INFORMATION_BY_ID_SUCCESS, payload: result.data};
};

export const getUserInfoError = (err: unknown) => {
    return {
        type: GET_USER_INFORMATION_BY_ID_ERROR,
        payload: err,
    };
};
export function getUserInformation(userId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUserInfo());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UserInformationById.replace(
                    '{userEmployeeId}',
                    userId.toString()
                )}`,
                {headers}
            );
            dispatch(getUserInfoSuccess(response.data));
        } catch (err) {
            dispatch(getUserInfoError(err));
        }
    };
}
