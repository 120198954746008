import React, {useEffect} from 'react';
import {routers} from '@/appRoute';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {FilterListClient} from '@ListClient/FilterList';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {TableCard} from '@ListClient/TableClient';
import {useNavigate} from 'react-router-dom';
import {clientCard} from '@/components/Shopify/ListClient/redux/interfaces';
import {resetSearch, searchClients} from '@/components/Quoter/Redux/Actions/SearchClientActions';
import {TableText} from '@components/Shopify/ListClient/constants';
import addUser from '@images/addUser.svg';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {actualPageCustomer} from '@Quoter/Redux/Actions/SearchClientActions';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';
import {ClientListPageSize} from '@components/Shopify/ListClient/constants';
import {IconContainer} from '@/components/Quoter/styles';
import {IClientSorting} from '@components/Shopify/ListClient/interfaces';

export function ClientsList() {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [{to: '/', label: 'Inicio'}];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const companyId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails?.[0].enterpriceId;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const AllClientsHome: clientCard = useSelector((state: RootState) => state.getClient);
    const sortingConfig = AllClientsHome.sorting;
    const handlePageChange = (newPage: number) => {
        dispatch(actualPageCustomer(newPage));
    };
    const filterCustomer = (sorting?: IClientSorting) => {
        if (
            (AllClientsHome.searchCustomer.length > 2 ||
                AllClientsHome.searchCustomer.length === 0) &&
            companyId &&
            token
        ) {
            dispatch(
                searchClients(token, {
                    searchName: AllClientsHome.searchCustomer,
                    PageNumber: AllClientsHome.actualPage,
                    PageSize: ClientListPageSize,
                    CompanyId: companyId,
                    filterClients: sorting ? sorting.sortedCol : sortingConfig.sortedCol,
                    orderType: sorting ? sorting.direction : sortingConfig.direction,
                })
            );
        }
    };

    useEffect(() => {
        dispatch(resetSearch());
    }, []);

    useEffect(() => {
        filterCustomer();
    }, [AllClientsHome.actualPage, AllClientsHome.searchCustomer]);

    return (
        <>
            <ContainerFlex Align="start" FlexDir="column" Padding="0 1rem" Justify="start">
                <ContainerFlex
                    Justify="start"
                    Padding="1.313rem 0.5rem 1rem 0.5rem"
                    Height="auto"
                    Gap="8px"
                >
                    <IconContainer
                        className="material-icons"
                        FontSize="26px"
                        Color="#5A5AFF"
                        Width="24px"
                        Height="24px"
                        FontWeight="500"
                        onClick={() => navigate('/')}
                    >
                        arrow_back
                    </IconContainer>
                    <Text FontWeight="500">
                        <Breadcrumb items={itemsBreadcrumb} fontSize="16px" color="#5A5AFF" />
                    </Text>
                </ContainerFlex>
                <Text FontSize="1.65rem" FontWeight="600" Padding="0.5rem 0.75rem 2rem 1rem">
                    {TableText.Clients}
                </Text>
                <ContainerFlex
                    Align="center"
                    Justify="space-between"
                    Padding="0"
                    Height="auto"
                    Self="stretch"
                >
                    <FilterListClient AllClientsHome={AllClientsHome} />
                    <ButtonGenerals
                        Height="2.5rem"
                        Padding="0.5rem 1rem"
                        Gap="0.5rem"
                        FontSize="1rem"
                        FontWeight="500"
                        onClick={() => navigate(routers.ClientAddNew)}
                    >
                        <Image src={addUser} alt="add-client" />
                        {TableText.NewClient}
                    </ButtonGenerals>
                </ContainerFlex>
                <ContainerFlex Padding="16px 0 0" Height="auto">
                    {AllClientsHome.loading && (
                        <ContainerFlex>
                            <LoadingAtaskate />
                        </ContainerFlex>
                    )}
                    {!AllClientsHome.loading && (
                        <>
                            <TableCard onSortChange={filterCustomer} sortConfig={sortingConfig} />
                        </>
                    )}
                </ContainerFlex>
                <ContainerFlex Gap="0.5rem" Height="2.5rem">
                    <Pagination
                        pageNumber={AllClientsHome.actualPage ?? 0}
                        totalPages={AllClientsHome.clients.totalPages ?? 1}
                        totalItems={AllClientsHome.clients.totalItems ?? 0}
                        pageSize={ClientListPageSize ?? 0}
                        onPageChange={
                            AllClientsHome.clients.totalItems > 0 ? handlePageChange : () => {}
                        }
                    />
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
}
