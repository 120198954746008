import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_STATIC_INFORMATION_CREDIT_PROFILE_START,
    GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS,
    GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR,
    TOGGLE_CREDIT_TYPE,
    GET_CARD_CREDIT_PROFILE_ERROR,
    GET_CARD_CREDIT_PROFILE_START,
    GET_CARD_CREDIT_PROFILE_SUCCESS,
    GET_FILTER_VALUES_START,
    GET_FILTER_VALUES_SUCCESS,
    GET_FILTER_VALUES_ERROR,
    SET_FILTER_PARAMS,
    RESET_FILTER_PARAMS,
    GET_CREDIT_PROFILE_FILTERS_ERROR,
    GET_CREDIT_PROFILE_FILTERS_START,
    GET_CREDIT_PROFILE_FILTERS_SUCCESS,
    SET_VISUAL_DATE,
    SET_FILTERS_PARAMS_VISUAL,
} from '@/components/MyAccount/MyCredit/Redux/Types/Types';
import {CREDIT_TYPES} from '@/components/MyAccount/constants';
import {
    FilterParams,
    ICreditCard,
    IFilterValues,
    IMyCreditReducer,
    IStaticInformation,
} from '@/components/MyAccount/interfaces';

const filterParams: FilterParams = {
    statusId: null,
    companyId: null,
    requestedDate: null,
    requestAmount: '',
};

const initialState: IMyCreditReducer = {
    staticInformation: {
        totalAmountCredits: 0,
        totalPaid: 0,
        totaOwed: 0,
        activeLoans: 0,
        activePawns: 0,
    },
    loadingStaticInformation: false,
    errorStaticInformation: false,
    personalCredits: [],
    loadingPersonalCredits: false,
    errorPersonalCredits: false,
    creditType: CREDIT_TYPES.ALL,
    filterValues: {
        estadoSolicitud: [],
        company: [],
    },
    loadingFilterValues: false,
    errorFilterValues: false,
    filterParams: filterParams,
    filterParamsVisual: filterParams,
    visualDate: null,
};

export const MyCreditReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
        creditType: string;
        filterParams: FilterParams;
        visualDate: Date | null;
    }
) => {
    switch (action.type) {
        case TOGGLE_CREDIT_TYPE:
            return {
                ...state,
                creditType: action.creditType,
            };
        case GET_STATIC_INFORMATION_CREDIT_PROFILE_START:
            return {
                ...state,
                loadingStaticInformation: true,
                errorStaticInformation: false,
            };
        case GET_STATIC_INFORMATION_CREDIT_PROFILE_SUCCESS:
            return {
                ...state,
                staticInformation: action.payload.data.data as IStaticInformation,
                loadingStaticInformation: false,
                errorStaticInformation: false,
            };
        case GET_STATIC_INFORMATION_CREDIT_PROFILE_ERROR:
            return {
                ...state,
                loadingStaticInformation: false,
                errorStaticInformation: true,
            };
        case GET_CARD_CREDIT_PROFILE_START:
            return {
                ...state,
                loadingPersonalCredits: true,
                errorPersonalCredits: false,
            };
        case GET_CARD_CREDIT_PROFILE_SUCCESS:
            return {
                ...state,
                personalCredits: action.payload.data.data as ICreditCard[],
                loadingPersonalCredits: false,
                errorPersonalCredits: false,
            };
        case GET_CARD_CREDIT_PROFILE_ERROR:
            return {
                ...state,
                loadingPersonalCredits: false,
                errorPersonalCredits: true,
            };
        case GET_FILTER_VALUES_START:
            return {
                ...state,
                loadingFilterValues: true,
                errorFilterValues: false,
            };
        case GET_FILTER_VALUES_SUCCESS:
            return {
                ...state,
                filterValues: action.payload.data.data as IFilterValues,
                loadingFilterValues: false,
                errorFilterValues: false,
            };
        case GET_FILTER_VALUES_ERROR:
            return {
                ...state,
                loadingFilterValues: false,
                errorFilterValues: true,
            };
        case SET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: action.filterParams,
            };
        case SET_FILTERS_PARAMS_VISUAL:
            return {
                ...state,
                filterParamsVisual: action.filterParams,
            };
        case RESET_FILTER_PARAMS:
            return {
                ...state,
                filterParams: initialState.filterParams,
                filterParamsVisual: initialState.filterParams,
                visualDate: initialState.visualDate,
            };
        case GET_CREDIT_PROFILE_FILTERS_START:
            return {
                ...state,
                loadingFilterValues: true,
                errorFilterValues: false,
            };
        case GET_CREDIT_PROFILE_FILTERS_SUCCESS:
            return {
                ...state,
                personalCredits: action.payload.data.data as ICreditCard[],
                loadingFilterValues: false,
                errorFilterValues: false,
            };
        case GET_CREDIT_PROFILE_FILTERS_ERROR:
            return {
                ...state,
                loadingFilterValues: false,
                errorFilterValues: true,
            };
        case SET_VISUAL_DATE:
            return {
                ...state,
                visualDate: action.visualDate,
            };
        default:
            return state;
    }
};
