import React from 'react';

import {CLIENT_PROFILE} from '@Shopify/ClientProfile/constants';
import {Text, Image, TextEllipsis, ContainerGrid} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import userIcon from '@images/Users.svg';
import phoneIcon from '@images/telephone.svg';
import mailIcon from '@images/envelope-dark.svg';
import houseIcon from '@images/house.svg';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';

const ContactInfo: React.FC<ContactInfoProps> = ({clientProfile}) => (
    <FlexPanel Gap="0.5rem" Align="center" Justify="start">
        <FlexPanel Gap="0.25rem" Align="center" Justify="start">
            <Image alt="user" src={userIcon} Width="1.25rem" Height="1.25rem" />
            <Text FontSize="0.875rem" Color="#5A5AFF" FontWeight="400">
                {CLIENT_PROFILE.INE}: {clientProfile.ine}
            </Text>
        </FlexPanel>
        <Text FontSize="1rem" Color="#E8E9EA" Width="0.063rem" oFlow="visible">
            |
        </Text>
        <FlexPanel Padding="0.125rem 0" Align="center" Gap="0.25rem" Justify="start">
            <Image alt="phone" src={phoneIcon} Width="1.25rem" Height="1.25rem" />
            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="400">
                {clientProfile.phone ? clientProfile.phone : '-'}
            </Text>
        </FlexPanel>
        <Text FontSize="1rem" Color="#E8E9EA" Width="0.063rem" oFlow="visible">
            |
        </Text>
        <FlexPanel Padding="0.125rem 0" Align="center" Gap="0.25rem" Justify="start">
            <Image alt="mail" src={mailIcon} Width="1.25rem" Height="1.25rem" />
            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="400">
                {clientProfile.email}
            </Text>
        </FlexPanel>
        <Text FontSize="1rem" Color="#E8E9EA" Width="0.063rem" oFlow="visible">
            |
        </Text>
        <ContainerGrid
            Padding="0.125rem 0"
            Align="center"
            Gap="0.25rem"
            Justify="start"
            GridColumns="1.25rem minmax(5rem, 1fr)"
        >
            <Image alt="home" src={houseIcon} Width="1.25rem" Height="1.25rem" />
            <TextEllipsis Color="#2A2C2F" FontSize="0.875rem" FontWeight="400" Width="100%">
                {clientProfile.address}
            </TextEllipsis>
        </ContainerGrid>
    </FlexPanel>
);

export default ContactInfo;
