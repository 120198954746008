import React, {useEffect} from 'react';
import {IconSvg} from '@/components/Quoter/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IOptionsRolesProps} from '@Roles/interface';
import {fetchRolesIdAssignment} from '@Roles/Redux/Actions/GetRolesInformation';
import {useDispatch, useSelector} from 'react-redux';
import {currentRolId, roleById} from '@Roles/CreateRoles/Redux/Actions/RoleById';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';

export const MoreOptionsRoles: React.FC<IOptionsRolesProps> = ({
    openOptionRoles,
    setShowModal,
    role,
    isOpen,
    setIsOpen,
    onOptionClick,
}) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const navigate = useNavigate();
    const handleEdit = async () => {
        await dispatch(currentRolId(role.credentialsId));
        dispatch(roleById(token, {roleId: role.credentialsId}, navigate));
        setIsOpen(false);
    };

    const handleClickAssigment = () => {
        setShowModal(true);
        setIsOpen(false);
    };

    const adminOptionsText = [
        {adminOptions: 'Editar', action: handleEdit},
        {adminOptions: 'Asignar', action: handleClickAssigment},
    ];
    const dispatch = useDispatch();
    useEffect(() => {}, [openOptionRoles]);
    return (
        <ContainerFlex Position="relative" Height="auto">
            <IconSvg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                Cursor="pointer"
                onClick={() => {
                    {
                        onOptionClick();
                        setIsOpen(true);
                    }
                }}
            >
                <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#5A5AFF"
                />
            </IconSvg>

            {isOpen && (
                <ContainerFlex
                    Position="absolute"
                    PositionTop={'-5rem'}
                    PositionRight="1rem"
                    Width="15rem"
                    Height="auto"
                    ZIndex="3"
                    backG="#FFFF"
                    FlexDir="column"
                    Border="1px solid #E8E9EA"
                    Align="start"
                >
                    <ContainerFlex FlexDir="column" Align="start">
                        {adminOptionsText.map((adminOption) => (
                            <ContainerFlex
                                key={adminOption.adminOptions}
                                Gap="0.5rem"
                                Height="2.5rem"
                                Justify="start"
                                Padding="0.5rem"
                            >
                                <IconSvg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M4.16667 15.8333H5.33333L12.5208 8.64579L11.3542 7.47913L4.16667 14.6666V15.8333ZM16.0833 7.43746L12.5417 3.93746L13.7083 2.77079C14.0278 2.45135 14.4203 2.29163 14.8858 2.29163C15.3508 2.29163 15.7431 2.45135 16.0625 2.77079L17.2292 3.93746C17.5486 4.2569 17.7153 4.64246 17.7292 5.09413C17.7431 5.54524 17.5903 5.93051 17.2708 6.24996L16.0833 7.43746ZM14.875 8.66663L6.04167 17.5H2.5V13.9583L11.3333 5.12496L14.875 8.66663Z"
                                        fill="#2A2C2F"
                                    />
                                </IconSvg>
                                <Text
                                    Cursor="pointer"
                                    FontSize="1rem"
                                    Color="#2A2C2F"
                                    onClick={() => {
                                        adminOption.action();
                                        dispatch(fetchRolesIdAssignment(role));
                                    }}
                                >
                                    {adminOption.adminOptions}
                                </Text>
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
