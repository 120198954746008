import React from 'react';
import {DateTime} from 'luxon';

import {InfoCard, SpanCard} from '@Auction/styles';

interface NewType {
    marca: string;
    modelo: string;
    envejecimiento: number;
    cantidad: number;
    fechaEmpeño: Date;
    fechaAlmoneda: Date;
    pesoTotal: number;
    pesoAccesorios: number;
    pesoMetal: number;
    precioVenta: number;
    costo: number;
    avaluo: number;
    cliente: string;
    usuario: string;
    descripcion: string;
}

export function AuctionsInformation(props: NewType) {
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const {
        marca,
        modelo,
        envejecimiento,
        cantidad,
        fechaEmpeño,
        fechaAlmoneda,
        pesoTotal,
        pesoAccesorios,
        pesoMetal,
        precioVenta,
        costo,
        avaluo,
        cliente,
        usuario,
        descripcion,
    } = props;
    return (
        <InfoCard>
            <SpanCard pl>Marca: {marca}</SpanCard>
            <SpanCard>Modelo: {modelo}</SpanCard>
            <SpanCard pt pl>
                Envejecimiento: {envejecimiento}
            </SpanCard>
            <SpanCard pt>Cantidad: {cantidad}</SpanCard>
            <SpanCard pt pl>
                Fecha Empeño: {DateTime.fromJSDate(fechaEmpeño).toLocaleString(DateTime.DATE_MED)}{' '}
            </SpanCard>
            <SpanCard pt>
                Fecha Almoneda:{' '}
                {DateTime.fromJSDate(fechaAlmoneda).toLocaleString(DateTime.DATE_MED)}
            </SpanCard>
            <SpanCard pt pl>
                Peso total: {pesoTotal} gramos
            </SpanCard>
            <SpanCard pt>Peso accesorios: {pesoAccesorios} gramos</SpanCard>
            <SpanCard pt pl>
                Peso metal: {pesoMetal} gramos
            </SpanCard>
            <SpanCard pt>Precio de venta: {formatterDolar.format(precioVenta)}</SpanCard>
            <SpanCard pt pl>
                Costo (Mutuo): {formatterDolar.format(costo)}{' '}
            </SpanCard>
            <SpanCard pt>Avalúo: {formatterDolar.format(avaluo)}</SpanCard>
            <SpanCard width ptt pl>
                Cliente: {cliente}
            </SpanCard>
            <SpanCard width pt pl>
                Usuario: {usuario}
            </SpanCard>
            <SpanCard width pl pt pb>
                Descripción: {descripcion}
            </SpanCard>
        </InfoCard>
    );
}
