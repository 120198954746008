export const GET_AUCTIONS = 'GET_AUCTIONS';
export const GET_AUCTIONS_SUCCESS = 'GET_AUCTIONS_SUCCESS';
export const GET_AUCTIONS_ERROR = 'GET_AUCTIONS_ERROR';

export const GET_IMAGES_AUCTIONS = 'GET_IMAGES_AUCTIONS';
export const GET_IMAGES_AUCTIONS_SUCCESS = 'GET_IMAGES_AUCTIONS_SUCCESS';
export const GET_IMAGES_AUCTIONS_ERROR = 'GET_IMAGES_AUCTIONS_ERROR';

export const GET_AUDIT_AUCTIONS = 'GET_AUDIT_AUCTIONS';
export const GET_AUDIT_AUCTIONS_SUCCESS = 'GET_AUDIT_AUCTIONS_SUCCESS';
export const GET_AUDIT_AUCTIONS_ERROR = 'GET_AUDIT_AUCTIONS_ERROR';

export const POST_AUDIT_INCIDENTS = 'POST_AUDIT_INCIDENTS';
export const POST_AUDIT_INCIDENTS_SUCCESS = 'POST_AUDIT_INCIDENTS_SUCCESS';
export const POST_AUDIT_INCIDENTS_ERROR = 'POST_AUDIT_INCIDENTS_ERROR';

export const DELETE_AUDIT_AUCTIONS = 'DELETE_AUDIT_AUCTIONS';
export const DELETE_AUDIT_AUCTIONS_SUCCESS = 'DELETE_AUDIT_AUCTIONS_SUCCESS';
export const DELETE_AUDIT_AUCTIONS_ERROR = 'DELETE_AUDIT_AUCTIONS_ERROR';

export const INFO_AUDIT_INCIDENT = 'INFO_AUDIT_INCIDENT';
export const INFO_AUDIT_INCIDENT_SUCCESS = 'INFO_AUDIT_INCIDENT_SUCCESS';
export const INFO_AUDIT_INCIDENT_ERROR = 'INFO_AUDIT_INCIDENT_ERROR';

export const RESET_AUDIT_ACTIONS = 'RESET_AUDIT_ACTIONS';
