import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
    getSecurityBagWeightGold,
    postSecurityBagWeightGold,
} from '@ActionsSecurityBagWeight/SecurityBagWeightJiracasActions';
import {Table} from '@DeadLines/styles';

export function SecurityBagWeightGold() {
    const {
        handleSubmit,
        form,
        register,

        formState: {errors},
    } = useForm({
        defaultValues: {
            sucursal: '',
            costo: '',
            fecha: '',
            region: '',
            fundidora: '',
            prendas: '',
            asignado: '',
        },
    });
    const {id} = useParams();
    const identify = useSelector((state) => {
        return state.securitybagweight.identifyfoundry;
    });

    const dispatch = useDispatch();
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });

    useEffect(() => {
        if (token) {
            const getSecurityBagWeightData = () => {
                dispatch(getSecurityBagWeightGold(identify, token));
            };
            getSecurityBagWeightData();
        }
    }, [dispatch, identify, token]);

    const securitybagweightgold = useSelector((state) => {
        return state.securitybagweightjiracas.securitybagweightgold;
    });
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const onSubmitAudit = async (value) => {
        const formData = new FormData();
        formData.append('IdFundicion', id);
        formData.append('PrecioDolar', value.PrecioDolar);
        formData.append('PrecioOro', value.PrecioOro);
        formData.append('ImagenPrecioOro', value.ImagenPrecioOro[0]);
        formData.append('ImagenPrecioDolar', value.ImagenPrecioDolar[0]);
        formData.append('PorcentajeComision', value.PorcentajeComision);

        await dispatch(postSecurityBagWeightGold(formData, token));
    };

    return (
        <>
            {securitybagweightgold !== {} ? (
                <>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <span className="child-breadcrumb">Fundiciones</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="child-breadcrumb">Fundición</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="principal-breadcrumb">Precios</span>
                    </div>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <form
                            className="flex container-form pl-4 mt-5"
                            onSubmit={handleSubmit(onSubmitAudit)}
                        >
                            <div className="flex justify-content-between w-100">
                                <span>Ingreso de valores del día dólar y onzas de oro</span>
                                <button className="w-30 pl-5 pr-5 btn-add-incidents h-40">
                                    GUARDAR
                                </button>
                            </div>

                            <div className="column w-20 mt-5">
                                <label className="required">Precio dolar</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('PrecioDolar', {
                                        required: 'Ingrese motivo',
                                    })}
                                    id="PrecioDolar"
                                    style={{border: errors.PrecioDolar ? '1px solid red' : ''}}
                                />
                            </div>
                            <div className="column w-30 mt-5">
                                <label className="required">Imagen dolar</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('ImagenPrecioDolar', {
                                        required: 'Ingrese imagen',
                                    })}
                                    id="ImagenPrecioDolar"
                                    style={{
                                        border: errors.ImagenPrecioDolar ? '1px solid red' : '',
                                    }}
                                    type="file"
                                />
                            </div>
                            <div className="column w-20 mt-5">
                                <label className="required">Precio onza oro USD</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('PrecioOro', {
                                        required: 'Ingrese Precio Oro',
                                    })}
                                    id="PrecioOro"
                                    style={{border: errors.PrecioOro ? '1px solid red' : ''}}
                                    aria-invalid={errors.PrecioOro ? 'true' : 'false'}
                                />
                            </div>
                            <div className="column w-30 mt-5">
                                <label className="required">Imagen oro</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('ImagenPrecioOro', {
                                        required: 'Ingrese imagen',
                                    })}
                                    id="ImagenPrecioOro"
                                    type="file"
                                    style={{
                                        border: errors.ImagenPrecioOro ? '1px solid red' : '',
                                    }}
                                />
                            </div>

                            <div className="column w-20 mt-5">
                                <label className="required">Comisión</label>
                                <input
                                    className="input-react-hook-form"
                                    {...register('PorcentajeComision', {
                                        required: 'Ingrese comision',
                                    })}
                                    id="PorcentajeComision"
                                    style={{
                                        border: errors.PorcentajeComision ? '1px solid red' : '',
                                    }}
                                />
                            </div>

                            <div className=" flex flex-wrap pt-4 w-90 mx-auto  mt-5">
                                <span className="w-30 pl-4">
                                    Costo total de paquetes:{' '}
                                    {securitybagweightgold &&
                                        formatterDolar.format(
                                            securitybagweightgold.costoTotalPaquetes
                                        )}
                                </span>
                                <span className="w-30 ">
                                    Onzas a vender:{' '}
                                    {securitybagweightgold && securitybagweightgold.onzasAVender}
                                </span>
                                <span className="w-30 pl-4">
                                    Comisión del proveedor{' '}
                                    {securitybagweightgold && securitybagweightgold.comision}:{' '}
                                    {securitybagweightgold && securitybagweightgold.gramosAPagar}
                                </span>
                            </div>
                            <div className="w-50 mx-auto  mt-5">
                                <Table className="w-92 mx-auto   mt-4">
                                    <thead className="title-head-foundry">
                                        <tr>
                                            <th className="text-center">Lingote</th>
                                            <th className="text-center">Kilatajes</th>
                                            <th className="text-center">Gramos a pagar</th>
                                        </tr>
                                    </thead>
                                </Table>
                                {securitybagweightgold &&
                                    securitybagweightgold.listaLingotes.map(
                                        (recolectionitems, index) => (
                                            <>
                                                <Table className="w-92 mx-auto mt-4">
                                                    <tr className="row-sucursal-tr">
                                                        <td className="text-center">
                                                            {recolectionitems.lingote}
                                                        </td>
                                                        <td className="text-center">
                                                            {recolectionitems.kilatajes}
                                                        </td>
                                                        <td className="text-center">
                                                            {recolectionitems.gramosAPagar}
                                                        </td>
                                                    </tr>
                                                </Table>
                                            </>
                                        )
                                    )}
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
