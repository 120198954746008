import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer, TableRowItems} from '@components/Shopify/Endeavors/styles';
import {DUE_DETAILS} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/constants';
import {Select} from '@components/Quoter/Steps/styles';
import {MovementRowData} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/MovementRowData';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {
    ICreditMovementHistory,
    IOptionType,
} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';
import getCreditMovementHistoryAction from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/GetCreditMovementHistoryActions';

export const MovementsHistorialTable = ({
    filterOptions,
    personalCreditId,
}: {
    filterOptions: IOptionType[];
    personalCreditId: number;
}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data} = useSelector((state: RootState) => {
        return state.getCreditMovementHistory;
    });
    const [movementsTableData, setMovementsTableData] = useState<ICreditMovementHistory[] | null>(
        null
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const tableHeaders = [
        {title: DUE_DETAILS.MOVEMENTS_TABLE_HEADERS.TRANSACTION},
        {title: DUE_DETAILS.MOVEMENTS_TABLE_HEADERS.PAYMENT_METHOD},
        {title: DUE_DETAILS.MOVEMENTS_TABLE_HEADERS.PAYMENT},
        {title: DUE_DETAILS.MOVEMENTS_TABLE_HEADERS.PAYMENT_DATE},
    ];

    useEffect(() => {
        if (token && personalCreditId)
            dispatch(getCreditMovementHistoryAction(token, personalCreditId));
    }, [personalCreditId]);

    useEffect(() => {
        setMovementsTableData(data);
    }, [data]);

    return (
        <>
            <ContainerFlex Justify="end" Height="max-content">
                <Select
                    noOptionsMessage={() => DUE_DETAILS.FILTER_OPTIONS.NO_OPTIONS_MESSAGE}
                    options={filterOptions}
                    width="223px"
                    height="45px"
                    padding="0px"
                    isSearchable={false}
                    defaultValue={{
                        label: DUE_DETAILS.FILTER_OPTIONS.ALL.LABEL,
                        value: DUE_DETAILS.FILTER_OPTIONS.ALL.VALUE,
                    }}
                />
            </ContainerFlex>

            <ContainerFlex MaxH="363px" Height="max-content" Padding="0px 1rem">
                <Table>
                    <TableRowHead
                        Height="48px"
                        GridColumn="repeat(4,1fr) 2fr"
                        BackG="#FAFAFA"
                        BorderB="1px solid #E8E9EA"
                        Margin="0px"
                        Width="100%"
                    >
                        {tableHeaders.map((column, index) => (
                            <TableItem key={index} Height="48px">
                                <Text
                                    Color="#2A2C2F"
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Width="120px"
                                    Justify="center"
                                >
                                    {column.title}
                                </Text>
                            </TableItem>
                        ))}
                    </TableRowHead>
                    <TableItemsContainer Height="100%">
                        {movementsTableData?.map((column, index) => (
                            <MovementRowData
                                {...column}
                                tableLength={movementsTableData.length}
                                index={index}
                                key={index}
                            />
                        )) ?? (
                            <TableRowItems>
                                <TableItem>{DUE_DETAILS.EMPTY_HISTORY}</TableItem>
                            </TableRowItems>
                        )}
                    </TableItemsContainer>
                </Table>
            </ContainerFlex>
        </>
    );
};
