import React, {useState} from 'react';
import {
    DropDownContainer,
    DropDownList,
    DropDownListContainer,
    ListItem,
} from '@/components/CashFlowActions/Operational/styles';
import {BUTTON, options} from '@/components/CashFlowActions/Operational/Constants';
import {ModalBranchBox} from '@/components/CashFlowActions/CashFunding';
import {ModalBanchFunding} from '@/components/CashFlowActions/BranchFunding';
import {ModalReturnOfSurplus} from '@/components/CashFlowActions/ReturnOfSurplus';
import {ModalRegisterMotion} from '@/components/CashFlowActions/RegisterMotion';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import arrow from '@images/arrowButtonTop.svg';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@/config/store';
import {useSelector, useDispatch} from 'react-redux';
import {fetchEmployeesByBranch} from '@/components/CashFlowActions/Redux/Actions/EmployeeByBranchActions';
import {GetReturnType} from '@/components/CashFlowActions/Redux/Actions/RetunrTypeActions';

type OptionKeys =
    | 'Fondear Caja'
    | 'Fondear Sucursal'
    | 'Devolver Excedentes'
    | 'Registrar Movimiento'
    | 'Registrar gastos';

export const ButtonMenuActions = () => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const branchId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.branchId
    );

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionKeys | null>(null);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (option: OptionKeys) => () => {
        if (option === 'Registrar gastos') {
            navigate(`/ExpenseDetailsCreate`);
            return;
        }
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleButtonClick = () => {
        dispatch(fetchEmployeesByBranch(token, branchId));
        dispatch(GetReturnType(token));
    };

    const closeModal = () => {
        setSelectedOption(null);
    };

    const modalComponents: Record<OptionKeys, JSX.Element | null> = {
        'Fondear Caja': <ModalBranchBox isOpen={!!selectedOption} closeModal={closeModal} />,
        'Fondear Sucursal': <ModalBanchFunding isOpen={!!selectedOption} closeModal={closeModal} />,
        'Devolver Excedentes': (
            <ModalReturnOfSurplus isOpen={!!selectedOption} closeModal={closeModal} />
        ),
        'Registrar Movimiento': (
            <ModalRegisterMotion isOpen={!!selectedOption} closeModal={closeModal} />
        ),
        'Registrar gastos': null,
    };

    return (
        <DropDownContainer>
            <ButtonGenerals
                Padding="8px 16px"
                onClick={() => {
                    handleButtonClick();
                    toggling();
                }}
            >
                <Text Cursor="pointer" Color="#fff">
                    {BUTTON.BT}
                </Text>
                <ImageLogo src={arrow} />
            </ButtonGenerals>
            {isOpen && (
                <DropDownListContainer>
                    <DropDownList>
                        {options.map((option) => (
                            <ListItem onClick={onOptionClicked(option as OptionKeys)} key={option}>
                                <Text FontSize="1rem" HColor="#5a5aff">
                                    {option}
                                </Text>
                            </ListItem>
                        ))}
                    </DropDownList>
                </DropDownListContainer>
            )}
            {selectedOption && modalComponents[selectedOption]}
        </DropDownContainer>
    );
};
