export const CASHFLOWADMIN = {
    cashflow: 'Flujo de Efectivo',
    filter: 'Filtro',
    branch: 'Sucursal',
    location: 'Ubicación',
    status: 'Estado',
    userBranch: 'Usurarios en sucursal',
    cashBranch: 'Efectivo en sucursal',
    netIncomeDay: 'Ingresos netos del día',
    open: 'Abierta',
    closed: 'Cerrada',
};

export const SIDEMENU = {
    view: 'Ver sucursal',
    anchor: 'Fondeo de sucursal',
    refund: 'Devolución de excedente',
    register: 'Registrar el movimiento',
};

export const GET_ADMIN_CASHFLOW = 'GET_ADMIN_CASHFLOW';
export const GET_ADMIN_CASHFLOW_SUCCESS = 'GET_ADMIN_CASHFLOW_SUCCESS';
export const GET_ADMIN_CASHFLOW_ERROR = 'GET_ADMIN_CASHFLOW_ERROR';
