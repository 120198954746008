import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {RootState} from '@/config/store';
import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import ButtonGeneral from '@/components/General/Atoms/Button';
import closeIconGreen from '@components/Branches/Images/IconCloseModal.svg';
import {BranchForm} from '@/components/Branches/CreateBranchModal/BranchForm';
import {ICrateBranchModal, IFormCreateBranch} from '@components/Branches/interfaces';
import {CreateBranch, createBranchReset} from '@components/Branches/Redux/Actions/CreateBranch';
import {GetTieredLevel} from '@components/Branches/Redux/Actions/GetTieredLevel';
import {schemaFormModal} from '@components/Branches/schemaValidations';

export const CreateBranchModal = ({createModal, setCreateModal}: ICrateBranchModal) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const idBranch = useSelector((state: RootState) => {
        return state.CreateBranch?.idBranch;
    });
    const {
        handleSubmit,
        register,
        watch,
        reset,
        trigger,
        formState: {errors},
        setValue,
        getValues,
    } = useForm<IFormCreateBranch>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: {
            countryId: undefined,
            countryName: '',
            nameBranch: '',
            description: '',
            levelId: undefined,
        },
    });
    const watchCountry = watch('countryName');
    const watchLevel = watch('levelId');
    const levelSelected = (id: number | undefined) => {
        if (getValues('levelId') === id) {
            return setValue('levelId', undefined);
        }
        setValue('levelId', id);
    };
    useEffect(() => {
        dispatch(GetTieredLevel({companyId, levelId: watchLevel}, token));
    }, [watchLevel]);
    useEffect(() => {
        if (idBranch) navigate(`/CreateBranch/ManageBranch/${idBranch}`);
    }, [idBranch]);
    const onSubmit = (data: IFormCreateBranch) => {
        const postData = {
            companyId,
            countryId: data.countryId,
            nameBranch: data.nameBranch,
            description: data.description,
            levelId: data.levelId,
        };
        dispatch(CreateBranch(postData, token));
    };
    return (
        <Modal
            modalState={createModal ? true : false}
            changeModalState={() => {
                setCreateModal(true);
            }}
            titleModalState={true}
            HeightCont="100%"
            Height="37rem"
            Width="37.5625rem"
            BoxShadow="none"
            BorderRadius="4px"
            background="rgb(167, 169, 172, 0.25)"
            Top="0px"
        >
            <ContainerFlex FlexDir="column" Align="flex-start" BoxS="0 0 12px 0 rgba(0, 0, 0, 0.5)">
                <ContainerFlex
                    Justify="space-between"
                    Height="10%"
                    Bb="solid 1px #35cf44"
                    Padding="16.5px 24px"
                >
                    <Text Color="#35cf44" FontSize="20px">
                        Crear sucursal
                    </Text>
                    <ImgLocation
                        Cursor="pointer"
                        src={closeIconGreen}
                        Width="24px"
                        Height="24px"
                        onClick={() => {
                            setCreateModal(false);
                            reset();
                            dispatch(createBranchReset());
                        }}
                    />
                </ContainerFlex>
                <BranchForm
                    watchCountry={watchCountry}
                    register={register}
                    setValue={setValue}
                    levelSelected={levelSelected}
                    watchLevel={watchLevel}
                    errors={errors}
                    trigger={trigger}
                />
                <ContainerFlex
                    Justify="flex-end"
                    Padding="24px"
                    Height="15%"
                    Bt="1px solid #e5e7e9"
                    Gap="20px"
                >
                    <ButtonGeneral
                        width="93px"
                        text="Cancelar"
                        type="submit"
                        border="transparent"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hColor="#35cf44"
                        transform=""
                        borderHover="solid 1px #a7a9ac"
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => {
                            setCreateModal(false);
                            reset();
                            dispatch(createBranchReset());
                        }}
                    />
                    <ButtonGeneral
                        width="69px"
                        text="Crear"
                        type="submit"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        transform="capitalize"
                        borderHover="solid 1px #a7a9ac"
                        hText="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={handleSubmit(onSubmit)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
