import {
    AmountValueType,
    amountValueTypes,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/helpers/getPlaceholderSelect';

const amountTypeHashMap: {[key: number]: AmountValueType} = {
    0: amountValueTypes.Amount,
    1: amountValueTypes.Percentage,
};
export const getAmountType = (value: number): AmountValueType =>
    amountTypeHashMap[value] || amountValueTypes.None;
