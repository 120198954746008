import {
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,
    VERIFY_CODE_RESET_ERROR,
} from '@components/MarketLogin/redux/types/types';

const initialState = {
    loading: false,
    error: null,
};

export const verifyCode = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CODE_REQUEST:
            return {...state, loading: true, error: null};
        case VERIFY_CODE_SUCCESS:
            return {...state, loading: false, succeeded: true};
        case VERIFY_CODE_RESET_ERROR:
            return {...state, error: null};
        case VERIFY_CODE_ERROR:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
