import {
    GET_FOUNDRY,
    GET_FOUNDRY_SUCCESS,
    GET_FOUNDRY_ERROR,
} from '@TypesAuditAssignment/FoundryTypes';

const initialState = {
    foundry: [],
    error: false,
    loading: false,
};

const foundry = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_FOUNDRY:
            return {
                ...state,
                loading: true,
            };
        case GET_FOUNDRY_SUCCESS:
            return {
                ...state,
                foundry: action.value,
                loading: false,
            };
        case GET_FOUNDRY_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
export default foundry;
