import React, {useState} from 'react';
import {ContainerFlex, InputColor, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import arrowIcon from '@images/arrowDownIcon.svg';
import {AmortizationTable, IPromotionData} from '@Quoter/inferfaces';
import {TABLE_PARAMS} from '@Quoter/constants';

export const PromotionContainer = ({
    data,
    selectContract,
    promotion,
    handlePromotionAssignment,
    error,
}: IPromotionData) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Height="auto"
            Border="1px solid #E8E9EA"
            Padding="8px 16px"
            Radius="16px"
            Gap="8px"
        >
            <ContainerFlex Justify="space-between" Gap="8px" Height="32px" ColumnGap="8px">
                <ContainerFlex ColumnGap="8px" Justify="start" Align="center">
                    <InputColor
                        type="radio"
                        checked={promotion.some(
                            (assignment) =>
                                assignment.contractId === selectContract &&
                                assignment.id === data.id
                        )}
                        onChange={() => handlePromotionAssignment(selectContract, data.id)}
                    />
                    <Text FontSize="1rem" color="#2A2C2F" FontWeight="500">
                        {data.termName}
                    </Text>
                </ContainerFlex>
                <Image
                    src={arrowIcon}
                    alt="type"
                    Width="1.5rem"
                    Height="1.5rem"
                    Cursor="pointer"
                    Transform={showDetails ? 'rotate(180deg)' : 'rotate(0deg)'}
                    onClick={() => setShowDetails(!showDetails)}
                />
            </ContainerFlex>
            {showDetails && (
                <>
                    <ContainerFlex
                        Bb="1px solid #E8E9EA"
                        Width="100%"
                        Height="32px"
                        GridColumns="repeat(7, 1fr) 1.5rem"
                        Justify="center"
                        ColumnGap="16px"
                    >
                        {TABLE_PARAMS.map((data: {title: string}, index: number) => {
                            return (
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F" FontWeight="500">
                                        {data.title}
                                    </Text>
                                </ContainerFlex>
                            );
                        })}
                    </ContainerFlex>
                    {data &&
                        data.amortizationTables.length > 0 &&
                        data.amortizationTables.map((data: AmortizationTable, index: number) => (
                            <ContainerFlex
                                Bb="1px solid #E8E9EA"
                                Width="100%"
                                Height="40px"
                                GridColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                                Justify="start"
                                Align="start"
                                ColumnGap="16px"
                                key={index}
                            >
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F" Width="10rem">
                                        {data.paydayLimit}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.mutual}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.interestPercentage}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.interest}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.tax}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.endorsementPayment}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    key={index}
                                    Height="100%"
                                    Width="100%"
                                >
                                    <Text FontSize="1rem" color="#2A2C2F">
                                        {data.dischargePayment}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        ))}
                </>
            )}
        </ContainerFlex>
    );
};
