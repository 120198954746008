import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {UPDATE_BRANCH_FORM} from '@components/LocationsBranch/Redux/types';
import {IUpdateBranch} from '@components/LocationsBranch/Redux/interfaces';

const updateBranchStart = () => ({type: UPDATE_BRANCH_FORM.BRANCH_START});
const updateBranchSuccess = (result: AxiosResponse) => ({
    type: UPDATE_BRANCH_FORM.BRANCH_SUCCESS,
    updateBranch: result.data,
});
const updateBranchError = (error: AxiosError) => ({
    type: UPDATE_BRANCH_FORM.BRANCH_ERROR,
    error: error,
});

export const updateBranch = (token: string, cancelOperation: () => void, data: IUpdateBranch) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(updateBranchStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateBranch}`,
                data,
                {headers}
            );
            dispatch(updateBranchSuccess(response.data));
            cancelOperation();
        } catch (error) {
            dispatch(updateBranchError(error as AxiosError));
        }
    };
};
