import {
    DELETE_COOWNER,
    DELETE_COOWNER_ERROR,
    DELETE_COOWNER_SUCCESS,
} from '@TypesQouter/TypesGeneral';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loadingCoOwnerDeleted: false,
    idCoOwnerDeleted: 0,
};

const deleteDataCoOwner = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case DELETE_COOWNER:
            return {
                ...state,
                loadingCoOwnerDeleted: true,
                error: false,
            };
        case DELETE_COOWNER_SUCCESS:
            return {
                ...state,
                loadingCoOwnerDeleted: false,
                idCoOwnerDeleted: actions.payload.data.data,
            };
        case DELETE_COOWNER_ERROR:
            return {
                ...state,
                error: false,
                loadingCoOwnerDeleted: true,
            };
        default:
            return state;
    }
};

export default deleteDataCoOwner;
