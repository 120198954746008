import React, {useState} from 'react';
import Logo from '@images/logoAtaskate.svg';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {LoginAccess} from '@Login/Administrative/LoginAccess';
import {ImageLogo} from '@Login/style';

export const LoginAdministrative = () => {
    const [userExists, setUserExists] = useState(true);
    return (
        <ContainerFlex FlexDir="column" Height="100vh" backG="#ACACFF">
            <ContainerFlex
                backG="#FFFFFF"
                FlexDir="column"
                Width="29.063rem"
                Height="auto"
                Radius="24px"
                Padding={userExists ? '1.5rem' : '.7rem 1.5rem'}
            >
                <ImageLogo src={Logo} />
                <LoginAccess userExists={userExists} setUserExists={setUserExists} />
            </ContainerFlex>
        </ContainerFlex>
    );
};
