import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    SEND_CONFIRMATION_VOUCHER_FAIL,
    SEND_CONFIRMATION_VOUCHER_START,
    SEND_CONFIRMATION_VOUCHER_SUCCESS,
    SEND_CONFIRMATION_VOUCHER_RESET,
} from '@/components/QuickPayment/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {IConfirmationVoucherData} from '@/components/QuickPayment/interfaces';
import {URL} from '@/config/constants';

export const sendConfirmationVoucherStart = () => {
    return {
        type: SEND_CONFIRMATION_VOUCHER_START,
    };
};

export const sendConfirmationVoucherSuccess = (result: AxiosResponse) => {
    return {
        type: SEND_CONFIRMATION_VOUCHER_SUCCESS,
        payload: result,
    };
};

export const sendConfirmationVoucherError = (error: AxiosError) => {
    return {
        type: SEND_CONFIRMATION_VOUCHER_FAIL,
        error: error,
    };
};

export const sendConfirmationVoucherReset = () => {
    return {
        type: SEND_CONFIRMATION_VOUCHER_RESET,
    };
};

export const sendConfirmationVoucher = (data: IConfirmationVoucherData) => {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(sendConfirmationVoucherStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SendConfirmationVoucher}`,
                {headers, params: data}
            );
            dispatch(sendConfirmationVoucherSuccess(response));
        } catch (error) {
            dispatch(sendConfirmationVoucherError(error as AxiosError));
        }
    };
};
