import {AppDispatch} from '@/config/store';
import {
    GET_PAYMENT_TYPE,
    GET_PAYMENT_TYPE_SUCCESS,
    GET_PAYMENT_TYPE_ERROR,
} from '@components/CashFlow/Redux/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getPaymentTypes = () => {
    return {
        type: GET_PAYMENT_TYPE,
    };
};

export const getPaymentTypesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_PAYMENT_TYPE_SUCCESS,
        payload: result,
    };
};

export const getPaymentTypesError = (error: AxiosError) => {
    return {
        type: GET_PAYMENT_TYPE_ERROR,
        payload: error,
    };
};

export function getPaymentMethodsCashflow(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPaymentTypes());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPaymentTypes}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getPaymentTypesSuccess(response));
        } catch (error) {
            dispatch(getPaymentTypesError(error as AxiosError));
        }
    };
}
