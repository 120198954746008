import {
    CURRENT_PAGE,
    SPLICED,
    TOTAL_ITEMS,
    BRANCH_DEADLINE,
    BRANCH_LENGHT,
    DEADLINE_ID_SEARCH,
    DEADLINES_CHECK,
} from '@ReduxDeadlinesindex/types/paginatedData';
import {AxiosResponse} from 'axios';

const InitialState: {
    actualPage: number;
    splicedPage: number;
    branchDeadline: number;
    deadLineArray: [-1];
    deadlineSearch: string;
    deadLineCheck: [];
    branchs: [];
} = {
    actualPage: 0,
    splicedPage: 10,
    branchDeadline: 0,
    deadLineArray: [-1],
    deadlineSearch: '',
    deadLineCheck: [],
    branchs: [],
};

const currentPaged = (
    state = InitialState,
    action: {
        type: string;
        payload: AxiosResponse;
        actualPage: number;
        splicedPage: number;
        branchDeadline: number;
        deadLineArray: [-1];
        deadlineSearch: string;
        deadLineCheck: [];
        branchs: [];
    }
): {
    actualPage: number;
    splicedPage: number;
    branchDeadline: number;
    deadLineArray: [-1];
    deadlineSearch: string;
    deadLineCheck: [];
    branchs: [];
} => {
    switch (action.type) {
        case DEADLINES_CHECK:
            return {
                ...state,
                deadLineCheck: action.deadLineCheck,
            };
        case DEADLINE_ID_SEARCH:
            return {
                ...state,
                deadlineSearch: action.deadlineSearch,
            };
        case SPLICED:
            return {
                ...state,
                branchs: action.branchs,
            };
        case BRANCH_LENGHT:
            return {
                ...state,
                deadLineArray: action.deadLineArray,
            };
        case BRANCH_DEADLINE:
            return {
                ...state,
                branchDeadline: action.branchDeadline,
            };
        case CURRENT_PAGE:
            return {
                ...state,
                actualPage: action.actualPage,
            };
        case TOTAL_ITEMS:
            return {
                ...state,
                splicedPage: action.splicedPage,
            };
        default:
            return state;
    }
};

export default currentPaged;
