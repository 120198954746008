import {IReducerType} from '@/components/Roles/interface';
import {
    UPDATE_STATUS_LEVEL_START,
    UPDATE_STATUS_LEVEL_ERROR,
    UPDATE_STATUS_LEVEL_SUCCESS,
    UPDATE_STATUS_LEVEL_FINISH,
} from '@TypesOrgCreation/types';

const initialState = {
    updateStatus: false,
    loading: false,
    error: false,
};
const UpdateStatusLevel = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case UPDATE_STATUS_LEVEL_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_STATUS_LEVEL_SUCCESS:
            return {
                ...state,
                updateStatus: action.payload.data.data,
            };
        case UPDATE_STATUS_LEVEL_ERROR:
            return {
                ...state,
                error: true,
            };
        case UPDATE_STATUS_LEVEL_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default UpdateStatusLevel;
