import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    VALIDATE_USER_ADMINISTRATOR,
    VALIDATE_USER_ADMINISTRATOR_ERROR,
    VALIDATE_USER_ADMINISTRATOR_SUCCESS,
} from '@components/SignUp/Redux/types';
import {ISignUpData} from '@components/SignUp/interfaces';
import {saveAccountData} from '@components/SignUp/Redux/Actions/saveDataFormAction';
import {UseFormSetError} from 'react-hook-form';

export const validateEmail = () => ({type: VALIDATE_USER_ADMINISTRATOR});
export const validateEmailSuccess = (result: AxiosResponse) => ({
    type: VALIDATE_USER_ADMINISTRATOR_SUCCESS,
    payload: result,
});
export const validateEmailError = () => ({type: VALIDATE_USER_ADMINISTRATOR_ERROR});

export function validateEmailExist(dataForm: ISignUpData, setError: UseFormSetError<ISignUpData>) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(validateEmail());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetValidateUserAdministrator}`,
                {
                    headers,
                    params: {email: dataForm.email},
                }
            );
            dispatch(validateEmailSuccess(response));
            dispatch(saveAccountData(dataForm));
        } catch (error) {
            dispatch(validateEmailError());
            setError('email', {
                message: 'El correo proporcionado ya está registrado en nuestro sistema.',
            });
        }
    };
}
