import {Controller} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Image, Text, TextArea} from '@/components/Shopify/Ecommerce/styles';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IDescriptionFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const DescriptionField: React.FC<IDescriptionFieldProps> = ({errors, control}) => {
    return (
        <>
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.DESCRIPTION}
                    <Text Color="#54575C" MarginLeft="0.25rem">
                        {LOAN_DETAILS.FIELDS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name="description"
                    control={control}
                    render={({field}) => (
                        <TextArea
                            {...field}
                            Height="40px"
                            FontSize="1rem"
                            Padding="0.5rem 1rem"
                            maxLength={500}
                            Radius="2rem"
                        />
                    )}
                />
                {errors.description && (
                    <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem">
                        <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                        {errors.description.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
