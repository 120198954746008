export const GET_DEADLINE = 'GET_DEADLINE';
export const GET_DEADLINE_SUCCESS = 'GET_DEADLINE_SUCCESS';
export const GET_DEADLINE_ERROR = 'GET_DEADLINE_ERROR';
export const GET_BRANCH_ID = 'GET_BRANCH_ID';
export const GET_BRANCH_ID_SUCCESS = 'GET_BRANCH_ID_SUCCESS';
export const GET_BRANCH_ID_ERROR = 'GET_BRANCH_ID_ERROR';
export const GET_FILTERS_DEADLINE = 'GET_FILTERS_DEADLINE';

export const FILTERS = {
    SEARCH_DEADLINE: 'SEARCH_DEADLINE',
    ACTUAL_PAGE: 'ACTUAL_PAGE',
    FILTER_HEAD: 'FILTER_HEAD',
    ORDER_TYPE: 'ORDER_TYPE',
    DELETE_SHEARCH: 'DELETE_SHEARCH',
};

export const LEVELS_DEADLINES = {
    DEADLINE_GROUP: 'DEADLINES_GROUP',
    DEADLINE_FAMILY: 'DEADLINES_FAMILY',
    DEADLINE_SUBFAMILY: 'DEADLINES_SUBFAMILY',
    DEADLINE_BRAND: 'DEADLINES_BRAND',
    DEADLINE_ARTICLES: 'DEADLINES_ARTICLES',
    DEADLINE_ERROR: 'DEADLINES_ERROR',
    DEADLINE_DELETE_LEVEL_ROUTE: 'DEADLINES_DELETE_LEVEL_ROUTE',
    DEADLINE_GET_LEVEL: 'DEADLINES_GET_LEVEL',
    DEADLINE_ADD_ROUTE_ITEMS: 'DEADLINES_ADD_ROUTE_ITEMS',
    DEADLINE_LAST_ROUTE_ITEM: 'DEADLINE_LAST_ROUTE_ITEM',
};

export const DEADLINE_SEARCH = {
    TRUE_LEVEL_DINAMICS: 'TRUE_LEVEL_DINAMICS',
    FALSE_LEVEL_DINAMICS: 'FALSE_LEVEL_DINAMICS',
    ERROR: 'ERROR',
    SEARCH_ARTICLE: 'SEARCH_ARTICLE',
    RESTART_SEARCH_ARTICLE: 'RESTART_SEARCH_ARTICLE',
    LEVEL_SELECTED: 'LEVEL_SELECTED',
    RESTART_ROUTE: 'RESTART_ROUTE',
    ROUTE_ITEMS: 'ROUTE_ITEMS',
};
