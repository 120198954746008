import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {IChangeDeleteCardModalProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {
    deletePaymentMethodAction,
    updateDefaultPaymentMethodAction,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/PaymentMethodsActions';

export const ChangeDeleteCardModal: React.FC<IChangeDeleteCardModalProps> = ({
    paymentMethodId,
    isDeleting,
    showModal,
    setShowModal,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const title: string = isDeleting
        ? PAYMENT_METHOD.MODAL_DELETE.TITLE
        : PAYMENT_METHOD.MODAL_CHANGE.TITLE;
    const description: string = isDeleting
        ? PAYMENT_METHOD.MODAL_DELETE.DESCRIPTION
        : PAYMENT_METHOD.MODAL_CHANGE.DESCRIPTION;
    const buttonText: string = isDeleting
        ? PAYMENT_METHOD.MODAL_DELETE.DELETE_BTN
        : PAYMENT_METHOD.MODAL_CHANGE.CHANGE_BTN;

    const handleContinue = () => {
        dispatch(
            isDeleting
                ? deletePaymentMethodAction({token, paymentMethodId: paymentMethodId as number})
                : updateDefaultPaymentMethodAction({
                      token,
                      paymentMethodId: paymentMethodId as number,
                  })
        );
        setShowModal((prev) => ({...prev, showModal: false}));
    };

    const handleCancel = () => {
        setShowModal((prev) => ({...prev, showModal: false}));
    };

    return (
        <>
            <Modal Top="0" BackC="rgba(0, 0, 0, 0.40)">
                <ContainerFlex
                    Radius="1.5rem"
                    backG="#FFF"
                    BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Padding="1.5rem"
                    FlexDir="column"
                    Width="600px"
                    Height="max-content"
                    Align="start"
                >
                    <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="700" MarginB="12px">
                        {title}
                    </Text>
                    <Text Color="#000" Padding="12px 0px 24px 0px" wSpace="wrap">
                        {description}
                    </Text>
                    <ContainerFlex Gap="16px" PaddingT="12px">
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            Width="150px"
                            Height="40px"
                            FontWeight="700"
                            onClick={() => handleCancel()}
                        >
                            {PAYMENT_METHOD.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Width="150px"
                            Height="40px"
                            BackGC={isDeleting ? '#A82424' : '#5A5AFF'}
                            onClick={() => handleContinue()}
                        >
                            {buttonText}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
