import React, {useState} from 'react';
import {
    ContainerFlex,
    Text,
    Modal,
    TextArea,
    ContainerForm,
} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ImageLogo} from '@/components/CashFlowExpenseDetails/operational/styles';
import Icone from '@images/MakeCorrection.svg';
import {MODAL_INFO} from '@/components/CashFlowExpenseDetails/operational/constants';
import {useNavigate} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IAuthExpense} from '@components/CashFlowExpenseDetails/operational/interface';
import {putAuthExpenseAction} from '@components/CashFlowExpenseDetails/Redux/ExpenseAuthorizationAction';

interface ModalCuestionsProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const ModalExpenseDetail = ({isOpen, closeModal}: ModalCuestionsProps) => {
    const [textValue, setTextValue] = useState('');
    const [isApproved, setIsApproved] = useState<boolean>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextValue(event.target.value);
    };
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );

    const expenseDetailData = useSelector(
        (state: RootState) => state.getExpenseDetails.expenseDetails,
        shallowEqual
    );

    const authData = {
        id: expenseDetailData.id,
        isApproved: isApproved,
        comments: textValue,
    };

    const handleRejection = async (authData: IAuthExpense) => {
        try {
            await dispatch(putAuthExpenseAction(token, authData));
            closeModal();
            setIsApproved(false);
            navigate('/CashFlow');
        } catch (error) {
            return error;
        }
    };

    if (!isOpen) return null;

    return (
        <Modal>
            <ContainerFlex
                FlexDir="column"
                Width="31.25rem"
                Height="32.25rem"
                Radius="24px"
                backG="#fff"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                Padding="24px"
                Gap="24px"
            >
                <ImageLogo Width="156px" Height="150px" src={Icone} />

                <ContainerFlex Height="" FlexDir="Column" Gap="16px">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {MODAL_INFO.TITTLE}
                    </Text>
                    <ContainerFlex Height="" FlexDir="Column">
                        <Text FontSize="1rem" FontWeight="400" Color="#54575C">
                            {MODAL_INFO.TEXT}
                        </Text>

                        <Text Height="" FontSize="1rem" FontWeight="400" Color="#54575C">
                            {MODAL_INFO.TEXT_ONE}
                        </Text>
                    </ContainerFlex>

                    <Text FontSize="1rem" FontWeight="400" Color="#54575C">
                        {MODAL_INFO.TEXT_TWO}
                    </Text>
                </ContainerFlex>

                <ContainerForm FlexDir="Column" Gap="24px">
                    <ContainerFlex Align="start" FlexDir="column" Gap="4px">
                        <Text Color="#54575C" FontWeight="500" FontSize="0.75rem">
                            {MODAL_INFO.WRITES}
                        </Text>
                        <TextArea
                            Padding=" 8px 16px"
                            FontSize="1rem"
                            value={textValue}
                            onChange={handleTextChange}
                        />
                    </ContainerFlex>
                    <ContainerFlex Align="end" ColumnGap="16px">
                        <ButtonGenerals
                            onClick={closeModal}
                            SecondaryButton="#FFFFFF"
                            Width="170px"
                        >
                            {MODAL_INFO.BT_C}
                        </ButtonGenerals>
                        <ButtonGenerals onClick={() => handleRejection(authData)} Width="170px">
                            {textValue ? MODAL_INFO.BT_E : MODAL_INFO.BT_S}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerForm>
            </ContainerFlex>
        </Modal>
    );
};
