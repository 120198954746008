import {Controller} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IEventTypeSelectProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const EventTypeSelect: React.FC<IEventTypeSelectProps> = ({
    control,
    errors,
    eventTypeOptions,
}) => {
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="700">
                {CHARGES_AND_PENALTIES.FIELDS_TITLES.EVENT_TYPE}
            </Text>
            <Controller
                name="eventType"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        options={eventTypeOptions}
                        noOptionsMessage={() => 'Sin opciones'}
                        isSearchable={false}
                        placeholder="--"
                        controlTop=""
                        ControlBottom="2px"
                    />
                )}
            />
            {errors.eventType && <ErrorMessage message={errors.eventType.message || ''} />}
        </ContainerFlex>
    );
};
