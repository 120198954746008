import React, {useState} from 'react';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import arrow from '@images/arrow small.svg';
import add from '@images/iconeAddWhithe.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {TableAllUsers} from '@/components/MyCompanyUser/TableAllUser';
import {TEXT_USER} from '@/components/MyCompanyUser/Operational/Constants';
import {TableAdmins} from '@/components/MyCompanyUser/TableAdmins';
import {TablePos} from '@/components/MyCompanyUser/TablePos';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Mi empresa'},
    {to: '#/', label: 'Usuario'},
];

export const AllUsers = () => {
    const [selectOption, setSelectOption] = useState(1);

    const optionsNotifications = [
        {id: 1, label: TEXT_USER.OP_FIRST},
        {id: 2, label: TEXT_USER.OP_SECOND},
        {id: 3, label: TEXT_USER.OP_THIRD},
    ];

    return (
        <ContainerFlex
            Padding="16px 24px"
            FlexDir="column"
            Gap="32px"
            Justify="start"
            backG="#FFFFFF"
            Align="start"
        >
            <Breadcrumb items={itemsBreadcrumb} />
            <ContainerFlex Height="">
                <ContainerFlex Justify="start">
                    <Text FontSize="24px" FontWeight="700">
                        {TEXT_USER.TITLE}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Gap="16px" Justify="end">
                    <ButtonGenerals type="button" SecondaryButton="#FFFFFF" Width="132px">
                        {TEXT_USER.BTN_PRIMARY}
                        <ImageLogo src={arrow} alt="icone-arrow" />
                    </ButtonGenerals>
                    <ButtonGenerals type="submit" Width="174px">
                        <ImageLogo src={add} alt="icone-add" />
                        {TEXT_USER.BTN_SECUNDARY}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="start" Width="100%" Height="" Bb="1px solid #D4D6D8">
                <ContainerFlex Width="30%" Height="40px">
                    {optionsNotifications.map((option) => (
                        <ContainerFlex
                            key={option.id}
                            Cursor="pointer"
                            Padding="4px 16px"
                            Bb={
                                selectOption === option.id
                                    ? '1px solid #5A5AFF'
                                    : '1px solid #D4D6D8'
                            }
                            onClick={() => setSelectOption(option.id)}
                        >
                            <Text
                                Cursor="pointer"
                                FontSize="1rem"
                                Color={selectOption === option.id ? '#5A5AFF' : '#54575C'}
                            >
                                {option.label}
                            </Text>
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
            </ContainerFlex>

            {selectOption === 1 && <TableAllUsers />}
            {selectOption === 2 && <TableAdmins />}
            {selectOption === 3 && <TablePos />}
        </ContainerFlex>
    );
};
