import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {MY_CONTRACTED_PLANS} from '@components/MyPlan/Redux/types';

const getPlansStart = () => ({
    type: MY_CONTRACTED_PLANS.START_GET_PLANS,
});
const getPlansSuccess = (result: AxiosResponse) => ({
    type: MY_CONTRACTED_PLANS.SUCCESS_GET_PLANS,
    plans: result,
});
const getPlansError = (err: AxiosError) => ({
    type: MY_CONTRACTED_PLANS.ERROR_GET_PLANS,
    error: err,
});

export const getMyPlans = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPlansStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContractedPlans}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(getPlansSuccess(response.data.data));
        } catch (error) {
            dispatch(getPlansError(error as AxiosError));
        }
    };
};
