import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import plusIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/plusIcon.svg';
import debitCardIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/debitCardIcon.svg';
import {Card} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Card';
import {OtherCards} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/OtherCards';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {DynamicCardFields} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/DynamicCardFields';
import {RootState} from '@/config/store';

export const PaymentCards = () => {
    const [showDynamicFields, setShowDynamicFields] = useState<boolean>(false);
    const {cards, defaultPaymentMethod} = useSelector((state: RootState) => state.paymentMethods);
    const defaultCard = defaultPaymentMethod;
    const otherCards = cards?.filter((card) => !card.isDefaultCard) ?? null;

    return (
        <>
            <ContainerFlex
                Border="1px solid #E8E9EA"
                Radius="0.5rem"
                Gap="1.5rem"
                Padding="1rem"
                FlexDir="column"
                Align="start"
            >
                <ContainerFlex Gap="0.5rem" Height="max-content" Justify="start" Width="624px">
                    <ContainerFlex Width="44px" Height="44px" Radius="60px" backG="#F4F5F5">
                        <Image src={debitCardIcon} Width="24px" Height="24px" />
                    </ContainerFlex>
                    <Text Width="560px" Height="24px" Color="#2A2C2F" FontWeight="700">
                        {PAYMENT_METHOD.CREDIT_OR_DEBIT}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Gap="1rem" FlexDir="column" Align="start">
                    <Text Color="#2A2C2F" FontWeight="700" FontSize="0.875rem">
                        {PAYMENT_METHOD.DEFAULT_CARD}
                    </Text>
                    {defaultCard ? (
                        <Card {...defaultCard} />
                    ) : (
                        <ContainerFlex
                            Border="1px solid #E8E9EA"
                            Height="56px"
                            Padding="1rem"
                            Justify="start"
                            Radius="0.5rem"
                        >
                            <Text Color="#54575C" FontSize="0.875rem">
                                {PAYMENT_METHOD.DEFAULT_CARD_DESCRIPTION}
                            </Text>
                        </ContainerFlex>
                    )}

                    {!otherCards && (
                        <ContainerFlex
                            Justify="start"
                            Bt="1px solid #E8E9EA"
                            Padding="1rem 0px 0px 0px"
                        >
                            <Text
                                Gap="0.5rem"
                                Cursor="pointer"
                                Color="#5A5AFF"
                                FontWeight="700"
                                onClick={() => setShowDynamicFields(!showDynamicFields)}
                            >
                                <Image src={plusIcon} Width="24px" Height="24px" />
                                {PAYMENT_METHOD.ADD_NEW_CARD}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>

                {otherCards && (
                    <OtherCards
                        otherCards={otherCards}
                        showDynamicFields={showDynamicFields}
                        setShowDynamicFields={setShowDynamicFields}
                    />
                )}
                {showDynamicFields && (
                    <DynamicCardFields
                        showDynamicFields={showDynamicFields}
                        setShowDynamicFields={setShowDynamicFields}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
