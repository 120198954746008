import * as yup from 'yup';
import {
    INTEGER_NUMBER_MESSAGE,
    MIN_ITEMS_MESSAGE,
    POSITIVE_NUMBER_MESSAGE,
    REQUIRED_DATE_MESSAGE,
    REQUIRED_MESSAGE,
    TYPE_ERROR_MESSAGE,
} from '@components/CashFlow/Expenses/ExpenseDetails/constants';

export const expendFormSchema = yup.object().shape({
    expendType: yup.object().shape({
        value: yup.number().required(REQUIRED_MESSAGE).min(1),
        label: yup.string().required(REQUIRED_MESSAGE),
    }),
    supplierType: yup.object().shape({
        value: yup.number(),
        label: yup.string(),
    }),
    paymentMethod: yup.object().shape({
        value: yup.number().required(REQUIRED_MESSAGE).min(1),
        label: yup.string().required(REQUIRED_MESSAGE),
    }),
    transferNumber: yup
        .number()
        .required(REQUIRED_MESSAGE)
        .positive(POSITIVE_NUMBER_MESSAGE)
        .integer(INTEGER_NUMBER_MESSAGE)
        .typeError(TYPE_ERROR_MESSAGE),
    date: yup.string().required(REQUIRED_DATE_MESSAGE),
    description: yup.string().optional(),
    items: yup
        .array()
        .of(
            yup.object().shape({
                item: yup.string().required(REQUIRED_MESSAGE),
                article: yup.string().required(REQUIRED_MESSAGE),
                count: yup.string().required(REQUIRED_MESSAGE),
                cost: yup.string().required(REQUIRED_MESSAGE),
                iva: yup.string().required(REQUIRED_MESSAGE),
            })
        )
        .min(1, MIN_ITEMS_MESSAGE),
});
