import {Navigate} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';

export const PrivateRoute = ({children}) => {
    const urllogin = '/Login';

    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    return token ? children : <Navigate to={urllogin} />;
};
