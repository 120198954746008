import React, {useEffect} from 'react';
import {ContainerFlex, Text, Image, ContainerCellGrid} from '@Shopify/Ecommerce/styles';
import {Select} from '@Quoter/Steps/styles';
import AddPlus from '@images/add - plus.svg';
import {ROLES, USER_TYPE} from '@components/CreateUsers/constants';
import {Controller} from 'react-hook-form';
import {RolesUserProps} from '@components/CreateUsers/interfaces';
import * as content from '@components/CreateUsers/Styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {adminRoles, posRoles} from '@components/CreateUsers/Redux/Actions/saveUser';
import {ISelectReact} from '@Quoter/inferfaces';
import {GetRolsTypesByCompany} from '@components/CreateUsers/Redux/Actions/CreateDataUser';
import {useNavigate} from 'react-router-dom';

const RolesUser: React.FC<RolesUserProps> = ({control, errors, setValue}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {credential} = useSelector((state: RootState) => state.saveUser || []);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const DataRoles = useSelector((state: RootState) => state.CreateDataUser.dataRoles?.data);
    const {adminProfile} = useSelector((state: RootState) => state.adminUserProfileSettings);
    const roleOptionsAdmin =
        DataRoles?.rolsAccesses?.map((role: {rolName: string; rolId: number}) => ({
            label: role.rolName,
            value: role.rolId,
        })) || [];
    const roleOptionsPos = DataRoles?.rolsAccessPos?.map(
        (role: {rolName: string; rolId: number}) => ({
            label: role.rolName,
            value: role.rolId,
        })
    );
    const navigate = useNavigate();
    useEffect(() => {
        if (token) {
            dispatch(GetRolsTypesByCompany(companyId, token));
        }
    }, []);
    const setRoles = (e: ISelectReact, typeRol: number) => {
        if (typeRol === USER_TYPE.ADMIN)
            dispatch(adminRoles([{roleIdAdmin: e.value, roleNameAdmin: e.label}]));
        if (typeRol === USER_TYPE.POS)
            dispatch(posRoles([{roleIdEmployee: e.value, roleNameEmployee: e.label}]));
    };

    return (
        <>
            {credential.userType !== USER_TYPE.POS && (
                <ContainerFlex {...content.contentSection}>
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        <Image
                            src={adminProfile && adminProfile.pictureAdministrator}
                            {...content.credential.imgStart}
                        />
                        <Text {...content.subtitles}>{ROLES.TITLEADMIN}</Text>
                    </ContainerFlex>
                    <Text {...content.instructions}>{ROLES.DETAILS}</Text>
                    <ContainerCellGrid
                        FlexDir="column"
                        Align="start"
                        Height="auto"
                        Width="21.438rem"
                    >
                        <Controller
                            name={`roleAdmins`}
                            control={control}
                            rules={{
                                required:
                                    credential.userType !== USER_TYPE.POS
                                        ? {
                                              value: true,
                                              message: 'Campo obligatorio.',
                                          }
                                        : undefined,
                            }}
                            render={({field: {onChange, ref, ...field}}) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    placeholder="Selecciona un rol"
                                    options={roleOptionsAdmin}
                                    isSearchable={false}
                                    loadingMessage={() => 'Cargando...'}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        setRoles(e as ISelectReact, USER_TYPE.ADMIN);
                                    }}
                                    border={errors?.roleAdmins?.message ? '1px solid red' : ''}
                                />
                            )}
                        />
                    </ContainerCellGrid>
                    <ContainerFlex Justify="start" Cursor="pointer" Width="auto">
                        <Image src={AddPlus} alt="icon-warning" Cursor="pointer" />
                        <Text
                            Color="#5A5AFF"
                            FontSize=".875rem"
                            FontWeight="500"
                            Cursor="pointer"
                            onClick={() => navigate('/CreateRoles')}
                        >
                            {ROLES.ADDROLE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            )}
            {credential.userType !== USER_TYPE.ADMIN && (
                <ContainerFlex {...content.contentSection}>
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        <Image
                            src={adminProfile && adminProfile.picturePos}
                            {...content.credential.imgStart}
                        />
                        <Text {...content.subtitles}>{ROLES.TITLEPOS}</Text>
                    </ContainerFlex>
                    <Text {...content.instructions}>{ROLES.DETAILS}</Text>
                    <ContainerCellGrid
                        FlexDir="column"
                        Align="start"
                        Height="auto"
                        Width="21.438rem"
                    >
                        <Controller
                            name={`roleEmployees`}
                            control={control}
                            rules={{
                                required:
                                    credential.userType !== USER_TYPE.POS
                                        ? {
                                              value: true,
                                              message: 'Campo obligatorio.',
                                          }
                                        : undefined,
                            }}
                            render={({field: {onChange, ref, ...field}}) => (
                                <Select
                                    {...field}
                                    ref={ref}
                                    placeholder="Selecciona un rol"
                                    options={roleOptionsPos}
                                    isSearchable={false}
                                    loadingMessage={() => 'Cargando...'}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        setRoles(e as ISelectReact, USER_TYPE.POS);
                                    }}
                                    border={errors?.roleEmployees?.message ? '1px solid red' : ''}
                                />
                            )}
                        />
                    </ContainerCellGrid>
                    <ContainerFlex Justify="start" Cursor="pointer" Width="auto">
                        <Image src={AddPlus} alt="icon-warning" Cursor="pointer" />
                        <Text
                            Color="#5A5AFF"
                            FontSize=".875rem"
                            FontWeight="500"
                            Cursor="pointer"
                            onClick={() => navigate('/CreateRoles')}
                        >
                            {ROLES.ADDROLE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};

export default RolesUser;
