import React from 'react';
import {WarehouseDetail} from '@components/LocationsBranch/WarehouseDetail';
import {StorageTypeDetail} from '@components/LocationsBranch/StorageTypeDetail';
import {IInventoryStorageProps} from '@components/LocationsBranch//interfaces';

export const InventoryStorage = ({
    warehouse,
    onNewWarehouse,
    setSelectedStorage,
    selectedStorageType,
}: IInventoryStorageProps) => {
    return !selectedStorageType || warehouse.id !== selectedStorageType.parentLevel ? (
        <WarehouseDetail
            warehouse={warehouse}
            setSelectedStorage={setSelectedStorage}
            onNewWarehouse={onNewWarehouse}
        />
    ) : (
        <StorageTypeDetail
            warehouse={warehouse}
            selectedStorageType={selectedStorageType}
            setSelectedStorage={setSelectedStorage}
        />
    );
};
