import {AppDispatch} from '@/config/store';
import {
    GET_CITY_START,
    GET_CITY_ERROR,
    GET_CITY_SUCCESS,
    GET_ORGANIZATION_START,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_ERROR,
    OBJECT_BRANCH,
    GET_RACK_ERROR,
    GET_RACK_SUCCESS,
    GET_RACK_START,
    GET_FLOOR_SUCCESS,
    GET_FLOOR_START,
    GET_FLOOR_ERROR,
    POST_RACK_START,
    POST_RACK_SUCCESS,
    POST_RACK_ERROR,
    PUT_RACK_START,
    PUT_RACK_SUCCESS,
    PUT_RACK_ERROR,
    POST_FLOOR_START,
    POST_FLOOR_SUCCESS,
    POST_FLOOR_ERROR,
    PUT_FLOOR_START,
    PUT_FLOOR_SUCCESS,
    PUT_FLOOR_ERROR,
    POST_PENSION_SUCCESS,
    POST_PENSION_START,
    POST_PENSION_ERROR,
    PUT_PENSION_START,
    PUT_PENSION_SUCCESS,
    PUT_PENSION_ERROR,
    GET_PENSION_START,
    GET_PENSION_SUCCESS,
    GET_PENSION_ERROR,
    GET_VAULT_START,
    GET_VAULT_SUCCESS,
    GET_VAULT_ERROR,
    POST_VAULT_START,
    POST_VAULT_SUCCESS,
    POST_VAULT_ERROR,
    GET_CONTAINERC_START,
    GET_CONTAINERC_SUCCESS,
    GET_CONTAINERC_ERROR,
    GET_CREATECONTAINER_START,
    GET_CREATECONTAINER_SUCCESS,
    GET_CREATECONTAINER_ERROR,
    DELETE_RACK_SUCCESS,
    DELETE_RACK_ERROR,
    DELETE_RACK_START,
    DELETE_FLOOR_SUCCESS,
    DELETE_FLOOR_START,
    DELETE_FLOOR_ERROR,
    DELETE_PENSION_SUCCESS,
    DELETE_PENSION_ERROR,
    DELETE_VAULT_SUCCESS,
    DELETE_VAULT_ERROR,
    DELETE_VAULT_START,
    LOCATIONS_IDCONTAINER,
    BRANCH_SAVE,
    DELETE_LOCATIONS_LOGOUT,
    FLOOR_BY_ID_SUCCESS,
    FLOOR_BY_ID_START,
    FLOOR_BY_ID_ERROR,
    PENSION_BY_ID_START,
    PENSION_BY_ID_SUCCESS,
    PENSION_BY_ID_ERROR,
    RACK_BY_ID_START,
    RACK_BY_ID_SUCCESS,
    RACK_BY_ID_ERROR,
    VOULT_ROWS_START,
    VOULT_ROWS_SUCCESS,
    VOULT_ROWS_ERROR,
    VOULT_DRAWERS_START,
    VOULT_DRAWERS_SUCCESS,
    VOULT_DRAWERS_ERROR,
    VOULT_SQUABS_START,
    VOULT_SQUABS_SUCCESS,
    VOULT_SQUABS_ERROR,
} from '@TypesLocation/LocationTypes';

import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import Swal from 'sweetalert2';
import {
    BranchL,
    pension,
    tableFloor,
    tablePension,
    tableRack,
    tableVault,
} from '@Locations/AdminLocations/redux/Interfaces';

export const saveBranch = (result: BranchL | null, corporation?: string) => {
    return {
        type: BRANCH_SAVE,
        payload: {...result, corporation},
    };
};
export const fetchGetCityStart = () => {
    return {
        type: GET_CITY_START,
    };
};
export const fetchGetCitySuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_CITY_SUCCESS,
        payload: result,
    };
};
export const fetchGetCityError = () => {
    return {
        type: GET_CITY_ERROR,
    };
};
export const fetchGetOrganizationStart = () => {
    return {
        type: GET_ORGANIZATION_START,
    };
};
export const fetchGetOrganizationSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_ORGANIZATION_SUCCESS,
        payload: result,
    };
};
export const fetchGetOrganizationError = () => {
    return {
        type: GET_ORGANIZATION_ERROR,
    };
};

export function getCity(municipalityId: string | number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCityStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatCityMunicipality.replace(
                    '{municipalityId}',
                    municipalityId.toString()
                )}`,
                {headers}
            );
            dispatch(fetchGetCitySuccess(res.data));
        } catch (error) {
            dispatch(fetchGetCityError());
        }
    };
}
export function getOrganization(idBranch: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetOrganizationStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_QUOTE}${URL.urlQuote.LocationOrganization.replace(
                    '{idBranch}',
                    idBranch
                )}`,
                {headers}
            );
            dispatch(fetchGetOrganizationSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetOrganizationError());
        }
    };
}

export const fetchGetObjectBranch = (
    items: tableRack | tableVault | tablePension | tableFloor | number[] | {type: string}
) => {
    return {
        type: OBJECT_BRANCH,
        payload: items,
    };
};

export const fetchGetIdContainer = (items: number) => {
    return {
        type: LOCATIONS_IDCONTAINER,
        payload: items,
    };
};

export const fetchGetRackStart = () => {
    return {
        type: GET_RACK_START,
    };
};
export const fetchGetRackSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_RACK_SUCCESS,
        payload: result,
    };
};
export const fetchGetRackError = () => {
    return {
        type: GET_RACK_ERROR,
    };
};

export function getLocationRack(
    branchId: number,
    pageSize: number,
    pageNumber: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetRackStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsRack.replace(
                    '{pageNumber}',
                    String(pageNumber)
                )
                    .replace('{pageSize}', String(pageSize))
                    .replace('{branchId}', String(branchId))}`,
                {headers}
            );
            dispatch(fetchGetRackSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetRackError());
        }
    };
}

export const RackByIdStart = () => {
    return {
        type: RACK_BY_ID_START,
    };
};
export const RackByIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: RACK_BY_ID_SUCCESS,
        payload: result.data,
    };
};
export const RackByIdError = () => {
    return {
        type: RACK_BY_ID_ERROR,
    };
};

export function RackById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(RackByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsRackById.replace(
                    '{rackId}',
                    id
                )}`,
                {headers}
            );
            dispatch(RackByIdSuccess(res.data));
        } catch (error) {
            dispatch(RackByIdError());
        }
    };
}

export const fetchGetFloorStart = () => {
    return {
        type: GET_FLOOR_START,
    };
};
export const fetchGetFloorSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_FLOOR_SUCCESS,
        payload: result,
    };
};
export const fetchGetFloorError = () => {
    return {
        type: GET_FLOOR_ERROR,
    };
};

export function getLocationFloor(
    branchId: number,
    pageSize: number,
    pageNumber: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetFloorStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsFloor.replace(
                    '{pageNumber}',
                    String(pageNumber)
                )
                    .replace('{pageSize}', String(pageSize))
                    .replace('{branchId}', String(branchId))}`,
                {headers}
            );
            dispatch(fetchGetFloorSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetFloorError());
        }
    };
}

export const FloorByIdStart = () => {
    return {
        type: FLOOR_BY_ID_START,
    };
};
export const FloorByIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: FLOOR_BY_ID_SUCCESS,
        payload: result.data,
    };
};
export const FloorByIdError = () => {
    return {
        type: FLOOR_BY_ID_ERROR,
    };
};

export function FloorById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(FloorByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsFlorBy.replace(
                    '{id}',
                    id
                )}`,
                {headers}
            );
            dispatch(FloorByIdSuccess(res.data));
        } catch (error) {
            dispatch(FloorByIdError());
        }
    };
}

export const fetchGetPensionStart = () => {
    return {
        type: GET_PENSION_START,
    };
};
export const fetchGetPensionSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_PENSION_SUCCESS,
        payload: result,
    };
};
export const fetchGetPensionError = () => {
    return {
        type: GET_PENSION_ERROR,
    };
};

export function getLocationPension(
    branchId: number,
    pageSize: number,
    pageNumber: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetPensionStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsPension.replace(
                    '{pageNumber}',
                    String(pageNumber)
                )
                    .replace('{pageSize}', String(pageSize))
                    .replace('{branchId}', String(branchId))}`,
                {headers}
            );
            dispatch(fetchGetPensionSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetPensionError());
        }
    };
}

export const PensionByIdStart = () => {
    return {
        type: PENSION_BY_ID_START,
    };
};
export const PensionByIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: PENSION_BY_ID_SUCCESS,
        payload: result.data,
    };
};
export const PensionByIdError = () => {
    return {
        type: PENSION_BY_ID_ERROR,
    };
};

export function PensionById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(PensionByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocaltionPensionById.replace(
                    '{pensionId}',
                    id
                )}`,
                {headers}
            );
            dispatch(PensionByIdSuccess(res.data));
        } catch (error) {
            dispatch(PensionByIdError());
        }
    };
}

export const fetchGetVaultStart = () => {
    return {
        type: GET_VAULT_START,
    };
};
export const fetchGetVaultSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_VAULT_SUCCESS,
        payload: result,
    };
};
export const fetchGetVaultError = () => {
    return {
        type: GET_VAULT_ERROR,
    };
};

export function getLocationVault(
    branchId: number,
    pageNumber: number,
    pageSize: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetVaultStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsVault.replace(
                    '{branchId}',
                    String(branchId)
                )
                    .replace('{pageNumber}', String(pageNumber))
                    .replace('{pageSize}', String(pageSize))}`,
                {headers}
            );
            dispatch(fetchGetVaultSuccess(res));
        } catch (error) {
            dispatch(fetchGetVaultError());
        }
    };
}

export const VoultRowsByIdStart = () => {
    return {
        type: VOULT_ROWS_START,
    };
};
export const VoultRowsByIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: VOULT_ROWS_SUCCESS,
        payload: result.data,
    };
};
export const VoultRowsByIdError = () => {
    return {
        type: VOULT_ROWS_ERROR,
    };
};

export function VoultRowsById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(VoultRowsByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsVaultRows.replace(
                    '{voultId}',
                    id
                )}`,
                {headers}
            );
            dispatch(VoultRowsByIdSuccess(res.data));
        } catch (error) {
            dispatch(VoultRowsByIdError());
        }
    };
}

export const VoultDrawersByIdStart = () => {
    return {
        type: VOULT_DRAWERS_START,
    };
};
export const VoultDrawersByIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: VOULT_DRAWERS_SUCCESS,
        payload: result.data,
    };
};
export const VoultDrawersByIdError = () => {
    return {
        type: VOULT_DRAWERS_ERROR,
    };
};

export function VoultDrawersById(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(VoultDrawersByIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsVaultDrawers.replace(
                    '{letterId}',
                    id
                )}`,
                {headers}
            );
            dispatch(VoultDrawersByIdSuccess(res.data));
        } catch (error) {
            dispatch(VoultDrawersByIdError());
        }
    };
}

export const VoultSquabsIdStart = () => {
    return {
        type: VOULT_SQUABS_START,
    };
};
export const VoultSquabsIdSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: VOULT_SQUABS_SUCCESS,
        payload: result.data,
    };
};
export const VoultSquabsIdError = () => {
    return {
        type: VOULT_SQUABS_ERROR,
    };
};

export function VoultSquabsId(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(VoultSquabsIdStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsVaultSquabs.replace(
                    '{drawerId}',
                    id
                )}`,
                {headers}
            );
            dispatch(VoultSquabsIdSuccess(res.data));
        } catch (error) {
            dispatch(VoultSquabsIdError());
        }
    };
}

export const fetchInsertRack = () => {
    return {
        type: POST_RACK_START,
    };
};
export const fetchInsertRackSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_RACK_SUCCESS,
        value: result.data,
    };
};
export const fetchInsertRackError = () => {
    return {
        type: POST_RACK_ERROR,
    };
};

export function insertRack(
    data: {branchId: number; rackName: string; rowsCount: number; status: boolean},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchInsertRack());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsInsertRack}`,
                data,
                {headers}
            );
            dispatch(fetchInsertRackSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
            dispatch(fetchInsertRackError());
        }
    };
}

export const fetchUpdateRack = () => {
    return {
        type: PUT_RACK_START,
    };
};
export const fetchUpdateRackSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: PUT_RACK_SUCCESS,
        value: result.data,
    };
};
export const fetchUpdateRackError = () => {
    return {
        type: PUT_RACK_ERROR,
    };
};

export function updateRack(
    data: {branchId: number; rackId: number; rackName: string; rowsCount: number; status: boolean},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchInsertRack());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsUpdateRack}`,
                data,
                {headers}
            );
            dispatch(fetchUpdateRackSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
            dispatch(fetchUpdateRackError());
        }
    };
}

export const fetchInsertFloor = () => {
    return {
        type: POST_FLOOR_START,
    };
};
export const fetchInsertFloorSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_FLOOR_SUCCESS,
        value: result.data,
    };
};
export const fetchInsertFloorError = () => {
    return {
        type: POST_FLOOR_ERROR,
    };
};

export function insertFloor(
    data: {
        branchId: number;
        address: string;
        nameFloor: string;
        phone: string;
        isHighFlow: boolean;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchInsertFloor());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsInsertFloor}`,
                data,
                {headers}
            );
            dispatch(fetchInsertFloorSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            dispatch(fetchInsertFloorError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchUpdateFloor = () => {
    return {
        type: PUT_FLOOR_START,
    };
};
export const fetchUpdateFloorSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: PUT_FLOOR_SUCCESS,
        value: result.data,
    };
};
export const fetchUpdateFloorError = () => {
    return {
        type: PUT_FLOOR_ERROR,
    };
};

export function updateFloor(
    data: {
        branchId: number;
        floorId: number;
        address: string;
        floorName: string;
        phone: string;
        isHighFlow: boolean;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUpdateFloor());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsUpdateFloor}`,
                data,
                {headers}
            );
            dispatch(fetchUpdateFloorSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            dispatch(fetchUpdateFloorError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchInsertPension = () => {
    return {
        type: POST_PENSION_START,
    };
};
export const fetchInsertPensionSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_PENSION_SUCCESS,
        value: result.data,
    };
};
export const fetchInsertPensionError = () => {
    return {
        type: POST_PENSION_ERROR,
    };
};

export function insertPension(
    data: {
        branchId: number;
        namePension: string;
        contact: string;
        address: string;
        phone: string;
        email: string;
        referencessAddress: string;
        cityId: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchInsertPension());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsInsertPension}`,
                data,
                {headers}
            );
            dispatch(fetchInsertPensionSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            dispatch(fetchInsertPensionError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchUpdatePension = () => {
    return {
        type: PUT_PENSION_START,
    };
};
export const fetchUpdatePensionSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: PUT_PENSION_SUCCESS,
        value: result.data,
    };
};
export const fetchUpdatePensionError = () => {
    return {
        type: PUT_PENSION_ERROR,
    };
};

export function updatePension(data: pension, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUpdatePension());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsUpdatePension}`,
                data,
                {headers}
            );
            dispatch(fetchUpdatePensionSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            dispatch(fetchUpdatePensionError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchInsertVault = () => {
    return {
        type: POST_VAULT_START,
    };
};
export const fetchInsertVaultSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: POST_VAULT_SUCCESS,
        value: result.data,
    };
};
export const fetchInsertVaultError = () => {
    return {
        type: POST_VAULT_ERROR,
    };
};

export function insertVault(
    data: {
        voultName: string;
        branchId: number;
        config: {
            letterName: string;
            drawers: ({row: number; squabs: number} | null)[];
        }[];
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchInsertVault());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsInsertVault}`,
                data,
                {headers}
            );
            dispatch(fetchInsertVaultSuccess(response));
            window.location.href = '#/Locations/CreateLocation/LocationSuccess';
        } catch (error) {
            dispatch(fetchInsertVaultError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchGetContainerCStart = () => {
    return {
        type: GET_CONTAINERC_START,
    };
};
export const fetchGetContainerCSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_CONTAINERC_SUCCESS,
        payload: result,
    };
};
export const fetchGetContainerCError = () => {
    return {
        type: GET_CONTAINERC_ERROR,
    };
};

export function getLocationContainerC(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetContainerCStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsCountContainer.replace(
                    '{branchId}',
                    String(branchId)
                )}`,
                {headers}
            );
            dispatch(fetchGetContainerCSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetContainerCError());
        }
    };
}

export const fetchGetCreateContainerStart = () => {
    return {
        type: GET_CREATECONTAINER_START,
    };
};
export const fetchGetCreateContainerSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: GET_CREATECONTAINER_SUCCESS,
        payload: result,
    };
};
export const fetchGetCreateContainerError = () => {
    return {
        type: GET_CREATECONTAINER_ERROR,
    };
};

export function getLocationCreateContainer(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCreateContainerStart());
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsCreateContainer}`,
                {headers}
            );
            dispatch(fetchGetCreateContainerSuccess(res.data));
        } catch (error) {
            dispatch(fetchGetCreateContainerError());
        }
    };
}

export const fetchDeleteRackStart = () => {
    return {
        type: DELETE_RACK_START,
    };
};
export const fetchDeleteRackSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: DELETE_RACK_SUCCESS,
        value: result,
    };
};
export const fetchDeleteRackError = () => {
    return {
        type: DELETE_RACK_ERROR,
    };
};

export function getDeleteRack(
    data: {
        branchId: number;
        rackId: number[];
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeleteRackStart());
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsDeleteRack}`,
                {data, headers}
            );
            dispatch(fetchDeleteRackSuccess(res.data));
            window.location.reload();
        } catch (error) {
            dispatch(fetchDeleteRackError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchDeleteFloorStart = () => {
    return {
        type: DELETE_FLOOR_START,
    };
};
export const fetchDeleteFloorSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: DELETE_FLOOR_SUCCESS,
        value: result,
    };
};
export const fetchDeleteFloorError = () => {
    return {
        type: DELETE_FLOOR_ERROR,
    };
};

export function getDeleteFloor(
    data: {
        branchId: number;
        floorId: number[];
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeleteFloorStart());
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsDeleteFloor}`,
                {data, headers}
            );
            dispatch(fetchDeleteFloorSuccess(res.data));
            window.location.reload();
        } catch (error) {
            dispatch(fetchDeleteFloorError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchDeletePensionStart = () => {
    return {
        type: GET_PENSION_START,
    };
};
export const fetchDeletePensionSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: DELETE_PENSION_SUCCESS,
        value: result,
    };
};
export const fetchDeletePensionError = () => {
    return {
        type: DELETE_PENSION_ERROR,
    };
};

export function getDeletePension(
    data: {
        branchId: number;
        pensionId: number[];
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeletePensionStart());
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsDeletePension}`,
                {data, headers}
            );
            dispatch(fetchDeletePensionSuccess(res.data));
            window.location.reload();
        } catch (error) {
            dispatch(fetchDeletePensionError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const fetchDeleteVaultStart = () => {
    return {
        type: DELETE_VAULT_START,
    };
};
export const fetchDeleteVaultSuccess = (result: {data: AxiosResponse}) => {
    return {
        type: DELETE_VAULT_SUCCESS,
        value: result,
    };
};
export const fetchDeleteVaultError = () => {
    return {
        type: DELETE_VAULT_ERROR,
    };
};

export function getDeleteVault(
    data: {
        branchId: number;
        voultId: number;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDeleteVaultStart());
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LocationsDeleteVault}`,
                {data, headers}
            );
            dispatch(fetchDeleteVaultSuccess(res.data));
            window.location.reload();
        } catch (error) {
            dispatch(fetchDeleteVaultError());
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'Verifique los datos e intente de nuevo',
                position: 'center',
            });
        }
    };
}

export const restartLocations = (branch: null) => {
    return {
        type: DELETE_LOCATIONS_LOGOUT,
        payload: branch,
    };
};
