import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {NEWBRANCHGROUP, NEWGROUP, NEWSTORAGE} from '@components/LocationsBranch/constants';
import {INameLocation} from '@components/LocationsBranch/interfaces';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ContainerFlex, Text, ContainerCellGrid, Input} from '@Shopify/Ecommerce/styles';
import {saveNameLocations} from '@/components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const NameLocation = ({
    register,
    errors,
    watch,
    reset,
    nameLocation,
    newName,
}: INameLocation) => {
    const dispatch = useDispatch();
    const {persistence, typeLocation} = useSelector((state: RootState) => ({
        persistence: state.groupBranchPersistence,
        typeLocation: state.CreateNewGroup.typeSelected,
    }));

    const getNameLocation = () => {
        const LOCATION: {[key: number]: string} = {
            1: NEWGROUP.NAMEGROUP,
            2: NEWBRANCHGROUP.NAMEGROUP,
            3: NEWSTORAGE.NAMEGROUP,
        };
        return LOCATION[typeLocation.locationId];
    };

    useEffect(() => reset({...watch(), [nameLocation]: persistence.name}), []);

    return (
        <ContainerFlex {...style.contentGral} Gap="1.5rem">
            <Text {...style.contentGralTitle}>{getNameLocation()}</Text>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="0.25rem">
                <Text Color="#6D6E71" FontWeight="700">
                    {NEWGROUP.NAME}
                </Text>
                <Input
                    Width="100%"
                    Cursor="text"
                    {...register(`${nameLocation}`)}
                    onChange={(e) => dispatch(saveNameLocations(e.target.value))}
                    error={errors[nameLocation]?.message as string}
                />
                <Text Color="#FF6357" FontSize="0.75rem">
                    {(errors[nameLocation]?.message as string) ||
                        (newName.error && NEWGROUP.NEW_NAME)}
                </Text>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
