import {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_NEWCATALOGID_ERROR,
    CREATE_NEWCATALOGID_START,
    CREATE_NEWCATALOGID_SUCCESS,
    LABEL_OPTION_TARGET,
} from '@components/Articles/Redux/Types';
import {ICreateNewCatalogProps} from '@components/Articles/interface';

const initialState: ICreateNewCatalogProps = {
    dataCatalog: null,
    loading: false,
    error: false,
    errorData: null,
    labelOption: 0,
};

const CreateNewCatalogId = (
    state: ICreateNewCatalogProps = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; value: number}
): ICreateNewCatalogProps => {
    switch (action.type) {
        case CREATE_NEWCATALOGID_START:
            return {...state, loading: true, error: false};
        case CREATE_NEWCATALOGID_SUCCESS:
            return {...state, loading: false, error: false, dataCatalog: action.payload.data};
        case CREATE_NEWCATALOGID_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case LABEL_OPTION_TARGET:
            return {...state, loading: false, error: false, labelOption: action.value};
        default:
            return state;
    }
};
export default CreateNewCatalogId;
