import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {SELECT_TYPE_LOCATIONS} from '@components/LocationsBranch/Redux/types';

export const selectTypeLocationStart = () => ({type: SELECT_TYPE_LOCATIONS.START});
export const selectTypeLocationSuccess = (result: {data: AxiosResponse}) => ({
    type: SELECT_TYPE_LOCATIONS.SUCCESS,
    types: result.data,
});
export const selectTypeLocationError = (error: AxiosError) => ({
    type: SELECT_TYPE_LOCATIONS.ERROR,
    error: error,
});
export const selectTypeLocation = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(selectTypeLocationStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetNewGroupBranch}`,
                {headers}
            );
            dispatch(selectTypeLocationSuccess(response.data));
        } catch (error) {
            dispatch(selectTypeLocationError(error as AxiosError));
        }
    };
};
