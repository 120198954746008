import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {InputCheckbox} from '@/components/Loan/styles';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import closeIcon from '@/images/removeCross.svg';
import {ClickAwayListener} from '@mui/material';
import {options} from '@components/Users/UserProfile/RoleSettings/constants';
import arrow from '@images/close Arrow.svg';

const DropdownCheckbox = () => {
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxChange = (id: number) => {
        setSelectedOptions((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((optionId) => optionId !== id);
            } else if (prevSelected.length < 3) {
                return [...prevSelected, id];
            }
            return prevSelected;
        });
    };

    const selectedLabels = options
        .filter((option) => selectedOptions.includes(option.id))
        .map((option) => option.label);

    return (
        <ContainerFlex backG="#FFFFFF" FlexDir="column" Justify="start" Align="start" Height="auto">
            <ButtonGenerals
                onClick={() => setIsOpen(!isOpen)}
                BackGC="#FFFFFF"
                Border="1px solid #E8E9EA"
                Width="22.67rem"
                Padding="4px"
                HBackG="white"
                Justify="start"
            >
                {selectedLabels.slice(0, 2).map((i) => (
                    <Text
                        BGColor="#F4F5F5"
                        FontWeight="700"
                        Color="#2A2C2F"
                        BorderRadius="8px"
                        Padding="4px"
                        key={i}
                    >
                        {i}
                        <ImageIcon
                            src={closeIcon}
                            width="11.67px"
                            height="11.67px"
                            margin="0 0 0 5px"
                            cursor="pointer"
                            alt="Close"
                        />
                    </Text>
                ))}
                {selectedOptions.length > 2 ? (
                    <Text
                        BGColor="#F4F5F5"
                        FontWeight="700"
                        Color="#2A2C2F"
                        BorderRadius="8px"
                        Padding="4px"
                    >
                        {`+ ${selectedOptions.length - 2}`}
                    </Text>
                ) : (
                    ''
                )}
                <ImageIcon src={arrow} alt="Open" Position="absolute" Left="23rem" />
            </ButtonGenerals>

            {isOpen && (
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="8px">
                        {options.map((option) => (
                            <ContainerFlex
                                key={option.id}
                                FontWeight="400"
                                Align="start"
                                Justify="start"
                                Gap="8px"
                                MarginTop="5px"
                            >
                                <InputCheckbox
                                    type="checkbox"
                                    checked={selectedOptions.includes(option.id)}
                                    onChange={() => handleCheckboxChange(option.id)}
                                />
                                {option.label}
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                </ClickAwayListener>
            )}
        </ContainerFlex>
    );
};

export default DropdownCheckbox;
