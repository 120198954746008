import React from 'react';

import {Numbers} from '@Articles/style';
import {PROGRESSBAR} from '@components/SignUp/constants';
import {IconContainer} from '@CreateBranch/CreateModal/style';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IBarDinamics, ILineBar, IProgressBar} from '@components/SignUp/interfaces';

export const ProgressBar = (props: IProgressBar) => {
    const {progressItems, actualStep} = props;
    return (
        <ContainerFlex Width="auto">
            {progressItems.map((item, index) => (
                <ContainerFlex key={item.step}>
                    <BarDinamic
                        step={item.step}
                        titleStep={item.title}
                        actualStep={actualStep}
                        index={index}
                    />
                    {progressItems[progressItems.length - 1].step > item.step && (
                        <LineDinamicsBar step={item.step} actualStep={actualStep} />
                    )}
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};

export const BarDinamic = (props: IBarDinamics) => {
    const {step, titleStep, actualStep, index} = props;

    const stylesBar = (style: string) => {
        if (style === PROGRESSBAR.BACKGROUND)
            if (actualStep === step) return '#5A5AFF';
            else return actualStep > step ? '#0D166B' : '#FFFFFF';
        if (style === PROGRESSBAR.COLOR)
            if (actualStep === step) return '#5A5AFF';
            else return actualStep > step ? '#2A2C2F' : '#71767D';
        if (style === PROGRESSBAR.SHADOW && actualStep < step)
            return '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)';
    };
    return (
        <ContainerFlex Width="auto" Margin={index === 0 ? '0' : '0 0 0 0.5rem'}>
            <Numbers
                FontSize="0.75rem"
                BGColor={stylesBar(PROGRESSBAR.BACKGROUND)}
                Color={actualStep === step ? '#FFFFFF' : '#71767D'}
                Shadow={stylesBar(PROGRESSBAR.SHADOW)}
                Margin=""
            >
                {actualStep > step ? (
                    <IconContainer Color="#FFFFFF" FontSize="0.875rem" className="material-icons">
                        done
                    </IconContainer>
                ) : (
                    step
                )}
            </Numbers>
            <Text Color={stylesBar(PROGRESSBAR.COLOR)} Margin="0 0.5rem" FontWeight="500">
                {titleStep}
            </Text>
        </ContainerFlex>
    );
};

export const LineDinamicsBar = (props: ILineBar) => {
    const {step, actualStep} = props;
    const stylesBar = (style: string) => {
        if (style === PROGRESSBAR.BACKGROUND)
            if (actualStep === step) return '#5A5AFF';
            else return actualStep > step ? '#54575C' : '#71767D';
    };
    return <ContainerFlex Width="2rem" backG={stylesBar(PROGRESSBAR.BACKGROUND)} Height="1px" />;
};
