export const contentGral = {
    Justify: 'start',
    Align: 'start',
    FlexDir: 'column',
    Border: 'solid 1px #F4F5F5',
    Radius: '1rem',
    Padding: '1rem 1.5rem',
    Gap: '1rem',
    Height: 'min-content',
};

export const contentGralTitle = {
    FontSize: '1.25rem',
    Color: '#2A2C2F',
    FontWeight: '700',
    oFlow: 'none',
};

export const contentGralTitleOp = {
    FontSize: '1.25rem',
    Color: '#71767D',
    FontWeight: '500',
    oFlow: 'none',
};

export const contentForm = {
    BackG: 'none',
    Align: 'start',
    FlexDir: 'column',
    Padding: '1rem',
    Gap: '1rem',
    Justify: 'start',
    Height: 'min-content',
};
