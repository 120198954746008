import {Products} from '@EcommerceShopify/interfaces/interfaces';
import {
    GET_PRODUCTS_SHOPIFY,
    ADD_CARRITO_SHOPI,
    GET_PRODUCTS_SHOPIFY_SUCCESS,
    GET_PRODUCTS_SHOPIFY_ERROR,
    GET_PRODUCTS_SHOPIFY_DETAIL,
    GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS,
    GET_PRODUCTS_SHOPIFY_DETAIL_ERROR,
    POST_UNAUTHORIZED,
    GET_INFO_CLIENT,
    POST_PRODUCTS_SHOPIFY,
    GET_INFO_CLIENT_SUCCESS,
    GET_INFO_CLIENT_ERROR,
    GET_INFO_MOVIMIENTS,
    GET_INFO_MOVIMIENTS_SUCCESS,
    GET_INFO_MOVIMIENTS_ERROR,
    GET_PAGE_ECOMMERCE,
    GET_DETAIL_PAY_SHOPI,
    GET_PAGE_ECOMMERCE_SUCCESS,
    GET_PAGE_ECOMMERCE_ERROR,
    GET_PRODUCTS_SHOPIFY_DETAILS,
    GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS,
    GET_PRODUCTS_SHOPIFY_DETAILS_ERROR,
    GET_DOCUMENT_CLIENT,
    GET_DOCUMENT_CLIENT_SUCCESS,
    GET_DOCUMENT_CLIENT_ERROR,
    GET_CONTRACT_CLIENT,
    GET_CONTRACT_CLIENT_SUCCESS,
    GET_CONTRACT_CLIENT_ERROR,
    GET_IDROW,
    GET_DETAIL_PAY,
    ADD_CARRITO,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_ERROR,
    PUT_REACTIVATION_CONTRACT,
    PUT_REACTIVATION_CONTRACT_SUCCESS,
    PUT_REACTIVATION_CONTRACT_ERROR,
    PUT_DELIVERY_CONTRACT,
    PUT_DELIVERY_CONTRACT_SUCCESS,
    PUT_DELIVERY_CONTRACT_ERROR,
    GET_FILTERS_MOVIMIENTS,
    ADD_CARRITO_START,
    ADD_CARRITO_SUCCESS,
    ADD_CARRITO_ERROR,
    GET_ENDEAVORS_START,
    GET_ENDEAVORS_SUCCESS,
    GET_ENDEAVORS_ERROR,
} from '@TypesProducts/ProductsShopifyTypes';

const initialState = {
    filtersmovements: null,
    IDrow: null,
    detailPay: [],
    detailPayShopi: [],
    documentclient: null,
    contractclient: null,
    productsshopify: null,
    movimientsinfo: null,
    movimientstable: null,
    movimientsvendida: null,
    movimientsvendidas: null,
    productsshopifycount: null,
    productsshopifyPageIndex: null,
    productsshopifydetail: null,
    productsshopifydetails: [],
    pageecommerce: null,
    infoclient: null,
    loadingempeño: [],
    error: false,
    carritoprendas: [],
    carritoprendasshopi: [],
    loading: false,
    loadingproductsshopify: false,
    loadingproductsshopifydetail: false,
    loadingecommerce: false,
    loadingdeliverycontract: false,
    loadinginfoclient: false,
    loadinginfomovements: false,
    loadingcontractclient: false,
    loadingdocumentclient: false,
    loadingreactivation: false,
    isAuthorized: true,
    endeavorslist: null,
    carShoppingId: 0,
};

const productsshopify = (
    state: Products = initialState,
    action: {type: any; value: any; count: any; autho: any; pageIndex: any}
) => {
    switch (action.type) {
        case GET_PRODUCTS_SHOPIFY:
            return {
                ...state,
                productsshopify: null,
                loadingproductsshopify: true,
            };
        case GET_PRODUCTS_SHOPIFY_SUCCESS:
            return {
                ...state,
                productsshopify: action.value,
                productsshopifycount: action.count,
                productsshopifyPageIndex: action.pageIndex,
                loadingproductsshopify: false,
            };
        case POST_PRODUCTS_SHOPIFY:
            return {
                ...state,
                productsshopifydetails: [],
                loadingproductsshopify: false,
            };
        case GET_PRODUCTS_SHOPIFY_ERROR:
            return {
                ...state,
                error: true,
                loadingproductsshopify: false,
            };
        case GET_PRODUCTS_SHOPIFY_DETAIL:
            return {
                ...state,
                loadingproductsshopifydetail: true,
                productsshopifydetail: null,
            };
        case GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS:
            return {
                ...state,
                productsshopifydetail: action.value,
                loadingproductsshopifydetail: false,
            };
        case GET_PRODUCTS_SHOPIFY_DETAIL_ERROR:
            return {
                ...state,
                error: true,
                loadingproductsshopifydetail: false,
            };
        case POST_UNAUTHORIZED:
            return {
                ...state,
                isAuthorized: action.autho,
            };
        case GET_PAGE_ECOMMERCE:
            return {
                ...state,
                loadingecommerce: true,
            };
        case GET_PAGE_ECOMMERCE_SUCCESS:
            return {
                ...state,
                pageecommerce: action.value,
                loadingecommerce: false,
            };
        case GET_PAGE_ECOMMERCE_ERROR:
            return {
                ...state,
                error: true,
                loadingecommerce: false,
            };
        case GET_INFO_CLIENT:
            return {
                ...state,
                loadinginfoclient: true,
                infoclient: null,
            };
        case GET_INFO_CLIENT_SUCCESS:
            return {
                ...state,
                infoclient: action.value,
                loadinginfoclient: false,
            };
        case GET_INFO_CLIENT_ERROR:
            return {
                ...state,
                error: true,
                loadinginfoclient: false,
            };
        case GET_INFO_MOVIMIENTS:
            return {
                ...state,
                movimientsinfo: null,
                loadinginfomovements: true,
                movimientsvendida: null,
                movimientsvendidas: null,
            };
        case GET_INFO_MOVIMIENTS_SUCCESS:
            return {
                ...state,
                movimientsinfo: action.value,
                movimientsvendida: action.value.filter(
                    (itm: {idContractStatus: number; availableGarments: number; idRow: any}) =>
                        (itm.idContractStatus === 3 && itm.idRow === null) ||
                        (itm.idContractStatus === 12 && itm.idRow === null)
                ),
                movimientsvendidas: action.value.filter(
                    (itm: {idContractStatus: number; availableGarments: number; idRow: any}) =>
                        (itm.idContractStatus === 3 && itm.idRow !== null) ||
                        (itm.idContractStatus === 12 && itm.idRow !== null) ||
                        itm.idContractStatus === 9 ||
                        itm.idContractStatus === 7 ||
                        itm.idContractStatus === 2 ||
                        itm.idContractStatus === 1
                ),
                movimientstable: action.value.filter(
                    (itm: {idContractStatus: number}) =>
                        itm.idContractStatus !== 12 &&
                        itm.idContractStatus !== 3 &&
                        itm.idContractStatus !== 9
                ),
                loadinginfomovements: false,
            };
        case GET_INFO_MOVIMIENTS_ERROR:
            return {
                ...state,
                loadinginfomovements: false,
                error: true,
            };
        case GET_PRODUCTS_SHOPIFY_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS:
            return {
                ...state,
                productsshopifydetails: [...state.productsshopifydetails, action.value],
                loading: false,
            };
        case GET_PRODUCTS_SHOPIFY_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_DOCUMENT_CLIENT:
            return {
                ...state,
                loadingdocumentclient: true,
            };
        case GET_DOCUMENT_CLIENT_SUCCESS:
            return {
                ...state,
                documentclient: action.value,
                loadingdocumentclient: false,
            };
        case GET_DOCUMENT_CLIENT_ERROR:
            return {
                ...state,
                loadingdocumentclient: false,
                error: true,
            };
        case GET_CONTRACT_CLIENT:
            return {
                ...state,
                loadingcontractclient: true,
            };
        case GET_CONTRACT_CLIENT_SUCCESS:
            return {
                ...state,
                contractclient: action.value,
                loadingcontractclient: false,
            };
        case GET_CONTRACT_CLIENT_ERROR:
            return {
                ...state,
                loadingcontractclient: false,
                error: true,
            };
        case GET_IDROW:
            return {
                ...state,
                IDrow: action.value,
            };
        case GET_DETAIL_PAY:
            return {
                ...state,
                detailPay: action.value,
            };
        case GET_DETAIL_PAY_SHOPI:
            return {
                ...state,
                detailPayShopi: action.value,
            };
        case GET_TRANSACTION:
            return {
                ...state,
                loading: true,
            };
        case GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_TRANSACTION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case PUT_REACTIVATION_CONTRACT:
            return {
                ...state,
                loadingreactivation: true,
            };
        case PUT_REACTIVATION_CONTRACT_SUCCESS:
            return {
                ...state,
                loadingreactivation: false,
            };
        case PUT_REACTIVATION_CONTRACT_ERROR:
            return {
                ...state,
                loadingreactivation: false,
            };
        case PUT_DELIVERY_CONTRACT:
            return {
                ...state,
                loadingdeliverycontract: true,
            };
        case PUT_DELIVERY_CONTRACT_SUCCESS:
            return {
                ...state,
                loadingdeliverycontract: false,
            };
        case PUT_DELIVERY_CONTRACT_ERROR:
            return {
                ...state,
                loadingdeliverycontract: false,
            };
        case ADD_CARRITO:
            return {
                ...state,
                carritoprendas: action.value,
            };
        case ADD_CARRITO_START:
            return {
                ...state,
                loading: true,
            };
        case ADD_CARRITO_SUCCESS:
            return {
                ...state,
                carritoprendas: action.value.data.data.carClient,
            };
        case ADD_CARRITO_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };

        case ADD_CARRITO_SHOPI:
            return {
                ...state,
                carritoprendasshopi: action.value,
            };
        case GET_FILTERS_MOVIMIENTS:
            return {
                ...state,
                filtersmovements: action.value,
            };
        case GET_ENDEAVORS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_ENDEAVORS_SUCCESS:
            return {
                ...state,
                endeavorslist: action.value.data,
            };

        case GET_ENDEAVORS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default productsshopify;
