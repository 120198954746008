import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {ALTS, ENABLEDCREDTIS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {IDetailLoansCarCredit} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {
    addCreditSelectedAction,
    deleteCreditToShoppingCarAction,
    getDetailsShoppingCarAction,
    removeCreditSelectedAction,
    unselectCreditsSelectedAction,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';
import {useDispatch, useSelector} from 'react-redux';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

export const EnabledCredits = () => {
    const dispatch = useDispatch();
    const {token, carShoopingId} = useSelector((state: RootState) => {
        const userData = state.getUsersValidation.userData;
        return {
            token: String(userData?.token),
            carShoopingId: Number(userData?.carShoopingId),
        };
    });
    const {detailsLoansCarCredits, loansCarCreditsSelected, mustBeUpdate} = useSelector(
        (state: RootState) => state.shoppingCarClient
    );
    useEffect(() => {
        if (token && carShoopingId && mustBeUpdate) {
            dispatch(getDetailsShoppingCarAction(token, carShoopingId.toString()));
        }
    }, [token, carShoopingId, mustBeUpdate]);

    const handleCreditSelected = (
        event: React.ChangeEvent<HTMLInputElement>,
        creditSelected: IDetailLoansCarCredit
    ) => {
        dispatch(
            event.target.checked
                ? addCreditSelectedAction(creditSelected)
                : removeCreditSelectedAction(creditSelected)
        );
    };

    const handleUnselectAll = () => {
        if (loansCarCreditsSelected.length) dispatch(unselectCreditsSelectedAction());
    };

    const handleRemoveCredit = (creditSelected: IDetailLoansCarCredit) => {
        const {paymentCreditId} = creditSelected;
        if (loansCarCreditsSelected.some((credit) => credit.paymentCreditId === paymentCreditId))
            dispatch(removeCreditSelectedAction(creditSelected));

        dispatch(
            deleteCreditToShoppingCarAction({
                token: String(token),
                paymentCreditId,
                carClienteId: Number(carShoopingId),
            })
        );
    };

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FFFFFF"
            Width="100%"
            Height="700px"
            Padding="1.5rem"
            Radius="1.5rem"
            Gap="1.5rem"
            OverFlow="auto"
        >
            <ContainerFlex Justify="space-between" Height="24px">
                <Text wSpace="normal" Color="#2A2C2F" FontWeight="700" FontSize="1.125rem">
                    {ENABLEDCREDTIS.PRODUCT}
                </Text>
                <Text
                    wSpace="normal"
                    Color="#5A5AFF"
                    FontWeight="700"
                    FontSize="1rem"
                    Cursor="pointer"
                    onClick={handleUnselectAll}
                >
                    {ENABLEDCREDTIS.UNSELECT}
                </Text>
            </ContainerFlex>
            {detailsLoansCarCredits?.map((credit) => (
                <ContainerFlex
                    key={credit.paymentCreditId}
                    Justify="start"
                    Align="start"
                    Width="100%"
                    Height="10rem"
                    Padding="1.5rem 0"
                    Bt="1px solid #E8E9EA"
                    BoxS="rgba(228, 204, 255, 0.15) 0px 4px 8px 0px"
                    Radius="0.5rem"
                    Gap="1.5rem"
                >
                    <ContainerFlex Width="100%" Gap="1.5rem" Justify="start" Align="start">
                        <Checkbox
                            checked={loansCarCreditsSelected.some(
                                (CreditsSelected) =>
                                    CreditsSelected.paymentCreditId === credit.paymentCreditId
                            )}
                            onChange={(e) => handleCreditSelected(e, credit)}
                        />
                        <ContainerFlex Width="100%" Justify="start" Align="start" Gap="1rem">
                            <Image
                                src={credit.urlLogoCompany}
                                Width="77px"
                                Height="85px"
                                Radius="8px"
                                Border="1px solid #E8E9EA"
                                alt={ALTS.LOANS_ICON}
                            />
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Align="start"
                                Width="22.688rem"
                                Gap="1rem"
                            >
                                <ContainerFlex
                                    FlexDir="column"
                                    Justify="start"
                                    Align="start"
                                    Height="4.75rem"
                                    Gap="0.5rem"
                                >
                                    <Text wSpace="pre" Color="#1D1E20" FontSize="1.125rem">
                                        {ENABLEDCREDTIS.LOAN} {credit.solicitudeNumber}
                                    </Text>
                                    <Text wSpace="normal" Color="#1D1E20" FontWeight="700">
                                        {ENABLEDCREDTIS.PAYMENT}
                                    </Text>
                                    <Text wSpace="pre" Color="#1D1E20" FontSize="0.75rem">
                                        {ENABLEDCREDTIS.NUMBERPAY} {credit.conterPayment}
                                    </Text>
                                </ContainerFlex>
                                <Text
                                    wSpace="normal"
                                    Color="#5A5AFF"
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Cursor="pointer"
                                    onClick={() => handleRemoveCredit(credit)}
                                >
                                    {ENABLEDCREDTIS.DELETE}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex Justify="end" Width="160px" PaddingR="1.5rem">
                        <Text Color="#1D1E20" FontWeight="700">
                            {numberToCurrencyWithoutCents(credit.amountQuote)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};
