import {AxiosResponse, AxiosError} from 'axios';
import {
    ADD_PERSONAL_CREDIT_TO_CREDITS,
    REMOVE_PERSONAL_CREDIT_IN_CREDITS,
    ADD_FAST_PAYMENT_ERROR,
    ADD_FAST_PAYMENT_START,
    ADD_FAST_PAYMENT_SUCCESS,
    RESET_FAST_PAYMENT,
} from '@components/QuickPayment/Redux/Types/Types';
import {
    IAddFastPaymentReducer,
    IAddFastPayment,
    IPersonalCredit,
} from '@components/QuickPayment/interfaces';
import {TYPE_OF_LOAN} from '@components/QuickPayment/Constants';

const initialState: IAddFastPaymentReducer = {
    personalCreditsSelected: [],
    totalLateLoan: 0,
    totalLoan: 0,
    totalPayment: 0,
    wasPaymentSuccess: false,
    error: false,
    loading: false,
};

const addFastPayment = (
    state: IAddFastPaymentReducer = initialState,
    action: {type: string; payload: IPersonalCredit | AxiosResponse; error: AxiosError}
): IAddFastPaymentReducer => {
    switch (action.type) {
        case ADD_PERSONAL_CREDIT_TO_CREDITS:
            return handleAddPersonalCredit(state, action.payload as IPersonalCredit);
        case REMOVE_PERSONAL_CREDIT_IN_CREDITS:
            return handleRemovePersonalCredit(state, action.payload as IPersonalCredit);
        case ADD_FAST_PAYMENT_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_FAST_PAYMENT_SUCCESS:
            return {
                ...state,
                wasPaymentSuccess: true,
                loading: false,
                error: false,
            };
        case ADD_FAST_PAYMENT_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        case RESET_FAST_PAYMENT:
            return initialState;
        default:
            return state;
    }
};

const handleAddPersonalCredit = (
    state: IAddFastPaymentReducer,
    personalCredit: IPersonalCredit
): IAddFastPaymentReducer => {
    if (
        state.personalCreditsSelected.some(
            (creditSelected) => creditSelected.personalCreditId === personalCredit.personalCreditId
        )
    ) {
        return state;
    }
    const newPersonalCredits = [personalCredit];
    const newState: IAddFastPaymentReducer = {
        ...state,
        personalCreditsSelected: newPersonalCredits,
        ...calculateNewTotals(newPersonalCredits),
    };
    return newState;
};

const handleRemovePersonalCredit = (
    state: IAddFastPaymentReducer,
    personalCredit: IPersonalCredit
): IAddFastPaymentReducer => {
    const newPersonalCredits = state.personalCreditsSelected.filter(
        (creditSelected) => creditSelected.personalCreditId !== personalCredit.personalCreditId
    );
    const newState: IAddFastPaymentReducer = {
        ...state,
        personalCreditsSelected: newPersonalCredits,
        ...calculateNewTotals(newPersonalCredits),
    };
    return newState;
};

const calculateNewTotals = (personalCreditsSelected: IPersonalCredit[]): IAddFastPayment => {
    return personalCreditsSelected?.reduce(
        (totals, creditSelected) => {
            const {amount, paymentCredit} = creditSelected;
            let {totalLateLoan, totalLoan, totalPayment} = totals;
            totalPayment += amount;
            paymentCredit?.forEach(({status, amount}) => {
                if (status.toLowerCase() === TYPE_OF_LOAN.TYPE_LATE.toLowerCase()) {
                    totalLateLoan += amount;
                } else {
                    totalLoan += amount;
                }
            });
            return {totalLateLoan, totalLoan, totalPayment} as IAddFastPayment;
        },
        {totalLateLoan: 0, totalLoan: 0, totalPayment: 0} as IAddFastPayment
    );
};

export default addFastPayment;
