export const FUNDING = {
    TITTLE: 'Fondeo de efectivo',
    TEXT: 'Se ha registrado un fondeo de efectivo a tu cuenta.',
    TEXT_ONE: 'Confirma para continuar.',
    DATE: 'Fecha',
    DATE_COMPLETE: 'Febrero 14, 2024 - 9:30 am',
    BALANCE: 'Saldo inicial',
    FUND: 'Fondeo de efectivo',
    NEW: 'Nuevo saldo',
    BALANCE_CASH: '$0',
    CASH_NEW: '$3,500',
    INCORRECT: 'No estoy de acuerdo',
    CORRECT: 'Confirmar',
};

export const CUESTIONS = {
    TITTLE: '¿Estás seguro que el fondeo no coincide?',
    TEXT: 'El administrador encargado será notificado para solucionar ',
    TEXT_ONE: 'esta discrepancia. Tu sesión estará bloqueada en lo que ',
    TEXT_TWO: 'resuelve este inconveniente. ',
    DATE: 'Fecha',
    DATE_COMPLETE: 'Febrero 14, 2024 - 9:30 am',
    BALANCE: 'Saldo inicial',
    FUND: 'Fondeo de efectivo',
    NEW: 'Nuevo saldo',
    BALANCE_CASH: '$0',
    CASH_NEW: '$3,500',
    INCORRECT: 'Calcelar',
    CORRECT: 'Notificar administrador',
};

export const RESOLUCTION = {
    TITTLE: 'Fondeo de efectivo',
    DATE: 'Fecha',
    DATE_COMPLETE: 'Febrero 14, 2024 - 9:30 am',
    BALANCE: 'Saldo inicial',
    FUND: 'Fondeo de efectivo',
    NEW: 'Nuevo saldo',
    BALANCE_CASH: '$0',
    CASH_NEW: '$3,500',
    SUB_TITTLE: 'Resolución en curso',
    TEXT: 'El administrador ha sido notificado de la discrepancia',
    TEXT_ONE: 'en el fondeo de efectivo, la sesión se mantendrá en',
    TEXT_TWO: 'bloqueada temporalmente en lo que se soluciona.',
};
