import {AxiosError, AxiosResponse} from 'axios';
import {
    ABOUT_PERSONAL_DATA_ERROR,
    ABOUT_PERSONAL_DATA_START,
    ABOUT_PERSONAL_DATA_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataPersonalLoans} from '@components/PersonalLoans/interfaces';

const initialState: IDataPersonalLoans = {
    dataLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

const CreatePersonalLoans = (
    state: IDataPersonalLoans = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataPersonalLoans => {
    switch (action.type) {
        case ABOUT_PERSONAL_DATA_START:
            return {...state, loading: true, error: false};
        case ABOUT_PERSONAL_DATA_SUCCESS:
            return {...state, loading: false, error: false, dataLoans: action.payload.data};
        case ABOUT_PERSONAL_DATA_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default CreatePersonalLoans;
