import axios from 'axios';
import {DataDeleteMotiveDetails} from '@MyCompany/Interface';
import {
    POST_DATA_REASON_START,
    POST_DATA_REASON_ERROR,
    POST_DATA_REASON_SUCCESS,
} from '@MyCompany/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const PostMotiveDeleteStart = () => {
    return {
        type: POST_DATA_REASON_START,
    };
};
export const PostMotiveDeleteSuccess = (value: unknown) => {
    return {
        type: POST_DATA_REASON_SUCCESS,
        payload: value,
    };
};

export const PostMotiveDeleteError = () => {
    return {
        type: POST_DATA_REASON_ERROR,
    };
};

export const PostMotiveDelete = (token: string, data: DataDeleteMotiveDetails) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(PostMotiveDeleteStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostMotiveDelete}`,
                data,
                {headers}
            );
            dispatch(PostMotiveDeleteSuccess(response));
        } catch (error) {
            dispatch(PostMotiveDeleteError());
        }
    };
};
