import * as yup from 'yup';

export const schemaAdminContact = yup.object().shape({
    problemId: yup.number(),
    problem: yup.string().required(),
    fullNames: yup
        .string()
        .required()
        .max(160)
        .matches(/^(?! )[\wñÑáéíóúÁÉÍÓÚ0-9 ]+$/, 'No se permite caracteres especiales'),
    userName: yup.string().required().max(160),
    description: yup.string().max(160),
});
