import * as yup from 'yup';

export const SchemaLoginReset = yup.object().shape({
    email: yup
        .string()
        .email('Ingrese un correo electrónico válido')
        .required('El correo electrónico es requerido')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'El correo no tiene un formato valido'
        ),
});

const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`|\\]).+$/;
export const shemaResetPassword = yup.object().shape({
    password: yup
        .string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('Este campo es obligatorio')
        .matches(
            regex,
            'Debe incluir una mayúscula, una minúscula, un número y un carácter especial'
        ),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
        .required('Este campo es obligatorio'),
});
