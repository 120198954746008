import React, {useState, useEffect} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    getNamesforHoliday,
    isOptional,
    LAST_LOCATION,
    NEWGROUP,
    storageInventory,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import * as style from '@components/LocationsBranch/stylesLocations';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {BusinessLines} from '@components/LocationsBranch/BusinessLines';
import {IShowModalHolidays} from '@components/LocationsBranch/interfaces';
import {newGroup} from '@components/LocationsBranch/Redux/Action/NewGroup';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {HolidaysSchedule} from '@components/LocationsBranch/HolidaysSchedule';
import {newGroupShema} from '@components/LocationsBranch/createLocationsErrors';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {HOLIDAYS, holidaysHours, scheduleHours} from '@components/LocationsBranch/constants';
import {resetFormLocation} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormInitialization';
import {GroupEditForm} from '@components/LocationsBranch/interfaces';
import {getCompanyGroupsById} from '@components/LocationsBranch/Redux/Action/GetGroupsByCompanyId';

export const NewGroup = ({setShowHolidaysModal, selectionId}: IShowModalHolidays) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState({addUser: false, locations: false});

    const {groups, token, company, addGroup, inventory, level, getGroups} = useSelector(
        (state: RootState) => ({
            groups: state.getCreateGroupBranch,
            level: state.getLevels.level,
            token: state.getUsersValidation.userData?.token as string,
            company: state.getUsersValidation.userData?.enterpriceDetails?.[0]
                ?.enterpriceId as number,
            addGroup: state.newGroup,
            inventory: state.Warehouse.warehouses,
            getGroups: state.getGroupsByCompanyId,
        })
    );

    const {
        register,
        control,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameGroup: '',
            businessLines: [],
            users: [],
            openingHours: [],
            holidays: getNamesforHoliday(groups.holidays),
            storages: [],
        },
        resolver: yupResolver(newGroupShema),
    });

    const {initialize} = useInitializeForm();

    const getEditGroup = () => {
        if (!level.levelId && !selectionId) return;
        const searchId = selectionId ?? level.levelId;
        const payload = {
            companyId: company,
            levelId: searchId,
        };
        dispatch(getCompanyGroupsById(token, payload));
    };
    const initializeStorage = () => {
        const {
            levelName = '',
            fatherId = 0,
            companyId = 0,
            description = '',
            user = [],
            openingHours = [],
            holidays = [],
            storages = null,
        } = getGroups.groups || {};

        const source: GroupEditForm = {
            name: levelName,
            levelId: level.levelId,
            description,
            openingHours,
            holidays,
            storagesDetail: storages,
            users: user,
            companyId,
            fatherId,
        };
        initialize(source, 'nameGroup', setValue);
    };

    useEffect(() => {
        if (!level.levelId || !selectionId) return;
        getEditGroup();
    }, [selectionId]);

    useEffect(() => {
        if (getGroups.groups) {
            initializeStorage();
        }
    }, [getGroups.groups]);

    const onsubmit = (values: FieldValues) => {
        const openingHours =
            values.openingHours && scheduleHours(Object.values(values.openingHours));
        const holidayHours =
            values.holidays && holidaysHours(Object.values(values.holidays), groups.holidays);

        const json = {
            levelName: values.nameGroup,
            fatherId: level && level.levelId,
            companyId: company,
            description: values.nameGroup,
            businessLines: values.businessLines,
            ...isOptional('users', values.users),
            ...isOptional('openingHours', openingHours),
            ...isOptional('holidays', holidayHours),
            ...isOptional('storages', storageInventory(inventory, 'nameStorage', 'storages')),
        };
        dispatch(newGroup(token, navigate, json));
    };

    const cancelOperation = async () => {
        resetFormLocation(dispatch);
        navigate('/LocationBranch');
    };

    return (
        <>
            <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
                {groups.loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    <>
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700">
                            {NEWGROUP.TITLE}
                        </Text>
                        <NameLocation
                            newName={addGroup}
                            nameLocation={NEWGROUP.NAME_FORM}
                            register={register}
                            errors={errors}
                            watch={watch}
                            reset={reset}
                        />
                        <RoutesLocations lastLevel={LAST_LOCATION.NEW_GROUP} />
                        <BusinessLines
                            setValue={setValue}
                            errors={errors}
                            nameBusiness={NEWGROUP.LINES}
                        />
                        <UserGroup modal={modal} setModal={setModal} setValue={setValue} />
                        <HolidaysSchedule
                            register={register}
                            control={control}
                            errors={errors}
                            setShowHolidaysModal={setShowHolidaysModal}
                            watch={watch}
                            reset={reset}
                            setValue={setValue}
                            isOptional={true}
                            isEdit={!!selectionId}
                        />
                        <LocationStorage isOptional={true} />
                        {addGroup.loading ? (
                            <ContainerFlex Height="2.5rem">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Gap="1.5rem" Height="2.5rem">
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.CANCEL}
                                    clic={() => cancelOperation()}
                                    color="#5A5AFF"
                                    backGround="#FFFFFF"
                                    hText="#5A5AFF"
                                    hShadow=""
                                    height="2.5rem"
                                    cursor="pointer"
                                    border="1px solid #5A5AFF"
                                    hBackG="#E5E5FF"
                                />
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.SAVE}
                                    type="submit"
                                    height="2.5rem"
                                    cursor="pointer"
                                />
                            </ContainerFlex>
                        )}
                    </>
                )}
            </ContainerForm>
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
        </>
    );
};
