import React from 'react';
import {USER_EJE, header} from '@/components/PendingTransaction/Operational/constants';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import point from '@images/ThreePoint.svg';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';

const POSITIVE_COLOR = '#309C60';
const NEGATIVE_COLOR = '#a82424';

export const PendingTransaction = () => {
    return (
        <ContainerFlex FlexDir="column">
            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="repeat(6, 1fr)"
                    Border="0 0 1px 0 solid red"
                    Height="32px"
                    Gap="16px"
                    BorderB="0.5px solid #e4e7e9"
                >
                    {header.map((header, index) => (
                        <TableItem key={index} Justify="start" Gap="4px" Width="100%">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                {header}
                            </Text>
                        </TableItem>
                    ))}
                </TableRowHead>
                <TableItemsContainer>
                    {USER_EJE.map((user, rowIndex) => {
                        const movementValue = parseFloat(user.MOV.replace(/[^0-9.-]/g, ''));
                        const isPositive = movementValue > 0;
                        const movementColor = isPositive ? POSITIVE_COLOR : NEGATIVE_COLOR;
                        return (
                            <TableRowItems
                                Height="40px"
                                GridColumn="repeat(6, 1fr)"
                                BorderB="0.5px solid #e4e7e9"
                                Border=""
                                key={rowIndex}
                                Gap="19px"
                            >
                                <TableItem Justify="start" Padding="4px 0px">
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="500"
                                        FontSize="0.75rem"
                                        color="#54575C"
                                    >
                                        {user.TYPE}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Padding="4px 0px">
                                    <Text FontFamily="Nunito" FontWeight="500" FontSize="0.75rem">
                                        {user.USER}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Padding="4px 0px">
                                    <Text FontFamily="Nunito" FontWeight="500" FontSize="0.75rem">
                                        {user.ROLE}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Padding="4px 0px">
                                    <Text FontFamily="Nunito" FontWeight="500" FontSize="0.75rem">
                                        {user.DATE}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text
                                        BorderRadius="32px"
                                        Color="color: #614500;"
                                        Border="1px solid #ffe299"
                                        BGColor="#fff5db"
                                        FontWeight="500"
                                        FontSize="0.75rem"
                                        Width="max-content"
                                        FontFamily="Nunito"
                                        Padding="2px 8px"
                                    >
                                        {user.ESTATE}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="end" Gap="24px">
                                    <Text
                                        FontFamily="Nunito"
                                        FontSize="0.875rem"
                                        Color={movementColor}
                                    >
                                        {user.MOV}
                                    </Text>
                                    <ImageLogo Cursor="pointer" src={point} />
                                </TableItem>
                            </TableRowItems>
                        );
                    })}
                </TableItemsContainer>
            </Table>
        </ContainerFlex>
    );
};
