import {
    ADD_EMAIL_USER_CLIENT,
    ADD_EMAIL_MARKET_LOGIN_USER,
    RESET_EMAIL_MARKET_LOGIN_USER,
    VERIFY_CODE_RESET_ERROR,
} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    loading: false,
    message: '',
    error: null,
};

export const newResetCodeClient = (state = initialState, action) => {
    switch (action.type) {
        case ADD_EMAIL_USER_CLIENT:
            return {...state, loading: true};
        case ADD_EMAIL_MARKET_LOGIN_USER:
            return {...state, loading: false};
        case VERIFY_CODE_RESET_ERROR:
            return {...state, error: null};
        case RESET_EMAIL_MARKET_LOGIN_USER:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
