import React from 'react';
import {HighText, TextFormat} from '@TreeTableUtils/styles';
import {CoincidenceProps} from '@TreeTableUtils/interface';

export const Highlight = ({
    text,
    highlight,
    color,
    ColorH,
    lheight,
    Cursor,
    FontSize,
    FontFamily,
    clic,
}: CoincidenceProps) => {
    if (!highlight.trim()) {
        return <TextFormat>{text}</TextFormat>;
    }

    const escapedHighlight = escapeRegExp(highlight);
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    const parts = text.split(regex);

    return (
        <TextFormat LHeight={lheight} Cursor={Cursor} onClick={clic}>
            {parts.map((part: string, i: number) => (
                <HighText
                    key={i}
                    FontWeight={part.toLowerCase() === highlight.toLowerCase() ? '700' : '400'}
                    FontSize={FontSize ? FontSize : '0.875rem'}
                    Padding="0"
                    Color={part === highlight ? color : ColorH}
                    FontFamily={FontFamily}
                    Cursor={part.toLowerCase() === highlight.toLowerCase() ? 'pointer' : 'auto'}
                >
                    {part}
                </HighText>
            ))}
        </TextFormat>
    );
};

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
