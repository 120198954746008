import {
    GET_EXPENSE_DETAILS,
    GET_EXPENSE_DETAILS_SUCCESS,
    GET_EXPENSE_DETAILS_ERROR,
} from '@components/CashFlowExpenseDetails/operational/constants';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    expenseDetails: [],
    loading: false,
    error: false,
};

const getExpenseDetails = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_EXPENSE_DETAILS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_EXPENSE_DETAILS_SUCCESS:
            return {
                ...state,
                expenseDetails: action.payload.data.data,
                loading: false,
            };
        case GET_EXPENSE_DETAILS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getExpenseDetails;
