import PhoneOne from '@/components/AdminNavbar/Notification/img/imgp.png';

export const NOTIFICATIOS = {
    TITLE: 'Notificaciones',
    OP_ONE: 'Todas',
    OP_TWO: 'Mensajes',
    OP_THREE: 'Ordenes',
    MORE: 'Ver más',
};

export const data = [
    {
        TIME: 'Hoy',
        TIME_DATE: 'Hace 30 min',
        TITLE: '¡Nueva marca en catalogo!',
        IMG: PhoneOne,
        DESCRIPTION: 'Pioneer ahora está disponible',
    },
    {
        TIME: 'Hoy',
        TIME_DATE: 'Hace 30 min',
        TITLE: 'Baja de catálogo',
        IMG: PhoneOne,
        DESCRIPTION: 'A partir del 12/02/2024, Sony dejará de estar dis...',
    },
];
