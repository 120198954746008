import axios from 'axios';
import {URL} from '@config/constants/index';

import {
    GET_BANNER_TABLE,
    GET_BANNER_TABLE_SUCCESS,
    GET_BANNER_TABLE_ERROR,
    GET_GROUP,
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR,
    GET_FAMILY,
    GET_FAMILY_SUCCESS,
    GET_FAMILY_ERROR,
    GET_SUBFAMILY,
    GET_SUBFAMILY_SUCCESS,
    GET_SUBFAMILY_ERROR,
    GET_BRAND,
    GET_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    GET_ITEM,
    GET_ITEM_SUCCESS,
    GET_ITEM_ERROR,
    POST_BANNER,
    POST_BANNER_SUCCESS,
    POST_BANNER_ERROR,
    POST_BANNER_DETAIL,
    POST_BANNER_DETAIL_SUCCESS,
    POST_BANNER_DETAIL_ERROR,
    GET_BANNER,
    GET_BANNER_SUCCESS,
    GET_BANNER_ERROR,
} from '@TypesEcommerce/BannerTypes';
import Swal from 'sweetalert2';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';

export const fetchGetBannerTable = () => {
    return {
        type: GET_BANNER_TABLE,
    };
};
export const fetchGetBannerTableSuccess = (result: {
    data: unknown;
    count: number;
    pageIndex: number;
}) => {
    return {
        type: GET_BANNER_TABLE_SUCCESS,
        bannerTable: result.data,
        count: result.count,
        pageIndex: result.pageIndex,
    };
};

export const fetchGetBannerTableError = () => {
    return {
        type: GET_BANNER_TABLE_ERROR,
    };
};

export function getBannerTable(filter = '', pageSize = 10, pageIndex = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        let params = null;
        if (filter === '') {
            params = {
                pageSize,
                pageIndex,
            };
        } else {
            params = {
                filter,
                pageSize,
                pageIndex,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(fetchGetBannerTable());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Banner}${queryString}`,
                {headers}
            );
            dispatch(fetchGetBannerTableSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetBannerTableError());
        }
    };
}

export const fetchGetFamily = () => {
    return {
        type: GET_FAMILY,
    };
};
export const fetchGetFamilySuccess = (result: {data: 0}) => {
    return {
        type: GET_FAMILY_SUCCESS,
        family: result.data,
    };
};
export const fetchGetFamilyError = () => {
    return {
        type: GET_FAMILY_ERROR,
    };
};

export function getFamily(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetFamily());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.GroupbyId.replace(
                    '{groupId}',
                    id
                )}`,
                {headers}
            );

            dispatch(fetchGetFamilySuccess(response));
        } catch (error) {
            dispatch(fetchGetFamilyError());
        }
    };
}

export const fetchGetSubfamily = () => {
    return {
        type: GET_SUBFAMILY,
    };
};
export const fetchGetSubfamilySuccess = (result: {data: 0}) => {
    return {
        type: GET_SUBFAMILY_SUCCESS,
        subfamily: result.data,
    };
};
export const fetchGetSubfamilyError = () => {
    return {
        type: GET_SUBFAMILY_ERROR,
    };
};

export function getSubfamily(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSubfamily());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.FamilybyId.replace(
                    '{familyId}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetSubfamilySuccess(response));
        } catch (error) {
            dispatch(fetchGetSubfamilyError());
        }
    };
}

export const fetchGetBrand = () => {
    return {
        type: GET_BRAND,
    };
};
export const fetchGetBrandSuccess = (result: {data: 0}) => {
    return {
        type: GET_BRAND_SUCCESS,
        brand: result.data,
    };
};
export const fetchGetBrandError = () => {
    return {
        type: GET_BRAND_ERROR,
    };
};

export function getBrand(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetBrand());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.SubFamilybyId.replace(
                    '{subFamilyId}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetBrandSuccess(response));
        } catch (error) {
            dispatch(fetchGetBrandError());
        }
    };
}
export function getBrandGeneral(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetBrand());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Brand}`,
                {headers}
            );
            dispatch(fetchGetBrandSuccess(response));
        } catch (error) {
            dispatch(fetchGetBrandError());
        }
    };
}

export const fetchGetItem = () => {
    return {
        type: GET_ITEM,
    };
};
export const fetchGetItemSuccess = (result: {data: 0}) => {
    return {
        type: GET_ITEM_SUCCESS,
        item: result.data,
    };
};
export const fetchGetItemError = () => {
    return {
        type: GET_ITEM_ERROR,
    };
};

export function getItem(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetItem());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.BrandbyId.replace(
                    '{brandId}',
                    id
                )}`,
                {headers}
            );
            dispatch(fetchGetItemSuccess(response));
        } catch (error) {
            dispatch(fetchGetItemError());
        }
    };
}

export const fetchGetGroup = () => {
    return {
        type: GET_GROUP,
    };
};
export const fetchGetGroupSuccess = (result: {data: unknown}) => {
    return {
        type: GET_GROUP_SUCCESS,
        group: result.data,
    };
};
export const fetchGetGroupError = () => {
    return {
        type: GET_GROUP_ERROR,
    };
};

export function getGroup(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetGroup());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Group}`,
                {headers}
            );
            dispatch(fetchGetGroupSuccess(response));
        } catch (error) {
            dispatch(fetchGetGroupError());
        }
    };
}

export const fetchPostBanner = () => {
    return {
        type: POST_BANNER,
    };
};
export const fetchPostBannerSuccess = (result: {data: unknown}) => {
    return {
        type: POST_BANNER_SUCCESS,
        bannerid: result.data,
    };
};
export const fetchPostBannerError = () => {
    return {
        type: POST_BANNER_ERROR,
    };
};

export function postBanner(token: string, data: FormData, datos: never[]) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostBanner());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.Banner}`,
                data,
                {headers}
            );
            const jason = {
                id: response.data,
                catalogs: datos,
            };
            dispatch(postBannerDetail(token, jason));
            dispatch(fetchPostBannerSuccess(response));
        } catch (error) {
            dispatch(fetchPostBannerError());
        }
    };
}

export const fetchPostBannerDetail = () => {
    return {
        type: POST_BANNER_DETAIL,
    };
};
export const fetchPostBannerDetailSuccess = (result: {data: unknown}) => {
    return {
        type: POST_BANNER_DETAIL_SUCCESS,
        value: result,
    };
};
export const fetchPostBannerDetailError = () => {
    return {
        type: POST_BANNER_DETAIL_ERROR,
    };
};

export function postBannerDetail(
    token: string,
    data: {
        id: number;
        catalogs: never[];
    }
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostBannerDetail());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_STORE}${URL.urlStore.BannerDetail}`,
                data,
                {headers}
            );
            dispatch(fetchPostBannerDetailSuccess(response));
            Swal.fire({
                icon: 'success',
                title: 'Agregar banner',
                text: '¡El banner ha sido agregado exitosamente!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Agregar banner',
            });
            dispatch(fetchPostBannerDetailError());
        }
    };
}

export const fetchGetBanner = () => {
    return {
        type: GET_BANNER,
    };
};
export const fetchGetBannerSuccess = (result: {data: unknown}) => {
    return {
        type: GET_BANNER_SUCCESS,
        banner: result.data,
    };
};
export const fetchGetBannerError = () => {
    return {
        type: GET_BANNER_ERROR,
    };
};
