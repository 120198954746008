type FrequencyTermKeys =
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'semester';

export const getPaymentCount = (
    isDataEmpty: boolean,
    isValidData: boolean,
    value: number
): string | number => {
    if (isDataEmpty) return '-';
    return isValidData ? value : 'NA';
};

const formIdHash: {[key: number]: FrequencyTermKeys} = {
    1: 'fortnightly',
    2: 'monthly',
    3: 'weekly',
    4: 'bimonthly',
    5: 'quarterly',
    6: 'semester',
};

export const getFormId = (id: number) => {
    return formIdHash[id];
};
