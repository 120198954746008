import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {Icons, NavContainer, Numbers, StyledLinkQuoter} from '@Articles/style';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {cancelQuoterOperation} from '@Quoter/Redux/Actions/StepsActions';
import {Text, ContainerFlex, LinkAtaskate} from '@Shopify/Ecommerce/styles';
import {payQuoter} from '@PaymentQuoter/Interfaces';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import SavePDF from '@images/picture_as_pdf.svg';

const PaymentContract = () => {
    const dispatch: AppDispatch = useDispatch();
    const PayQuoter: payQuoter = useSelector((state: RootState) => {
        return state.PayQuoter;
    });
    const [route, setRoute] = useState([
        {
            name: '',
            route: '',
        },
    ]);

    const endeavors = PayQuoter.pay.data.responseDischargeData
        ? PayQuoter.pay.data.responseDischargeData.length
        : 0;

    const discharges = PayQuoter.pay.data.responsePawnData
        ? PayQuoter.pay.data.responsePawnData.length
        : 0;

    const endorsment = PayQuoter.pay.data.responseEndorementData
        ? PayQuoter.pay.data.responseEndorementData.length
        : 0;

    const [contract, setContract] = useState({});
    const detectDownload = (index: string) => {
        setContract({...contract, [index]: index});
    };
    const ArrayRoute = () => {
        setRoute([
            {
                name: 'Tienda en línea',
                route: '/',
            },
            {
                name: 'Pagos online',
                route: '/',
            },
            {
                name: 'Pago de refrendo',
                route: '/',
            },
        ]);
    };
    const [showPrintError, setShowPrintError] = useState(false);
    useEffect(() => {
        ArrayRoute();
    }, []);

    return (
        <>
            {PayQuoter.pay.succeeded ? (
                <ContainerFlex Padding="0 0 0 1.75rem" FlexDir="column">
                    <NavContainer>
                        {route.map((element, index) => {
                            const length = route.length;
                            return (
                                <LinkAtaskate to={element.route} key={index}>
                                    <Text
                                        Border="none"
                                        Color={length - 1 !== index ? ' #6D6E71' : '#414042'}
                                        FontWeight="400"
                                        FontSize={length - 1 !== index ? '0.75rem' : '0.875rem'}
                                        Cursor="pointer"
                                    >
                                        {element.name}
                                    </Text>
                                    {length - 1 !== index && (
                                        <Icons
                                            className="material-icons"
                                            FontSize="1rem"
                                            FontWeight="300"
                                            Color="#A7A9AC"
                                            Margin="0 0.2rem"
                                        >
                                            keyboard_arrow_right
                                        </Icons>
                                    )}
                                </LinkAtaskate>
                            );
                        })}
                    </NavContainer>
                    <ContainerFlex Margin="10.333rem 0" MarginBt="7.438rem" FlexDir="column">
                        <Numbers
                            Width="103.33px"
                            Height="103.33px"
                            FontSize="0.75rem"
                            Color="#FFFFFF"
                            BGColor="#35CF44"
                            Border="1px solid #CBCED1"
                        >
                            <Icons className="material-icons" FontSize="6rem" Color="#FFFFFF">
                                done
                            </Icons>
                        </Numbers>
                        <Text
                            Color="#414042"
                            FontWeight="400"
                            FontSize="1rem"
                            Cursor="pointer"
                            MarginTop="1.583rem"
                        >
                            ¡Empeñó exitosamente!
                        </Text>
                        {PayQuoter.pay.data.responsePawnData && (
                            <Text
                                Color="#414042"
                                FontWeight="500"
                                FontSize="1rem"
                                Cursor="pointer"
                                MarginTop="1.5rem"
                            >
                                Imprimir contrato para finalizar el proceso
                            </Text>
                        )}

                        {PayQuoter.pay.data.responsePawnData &&
                            PayQuoter.pay.data.responsePawnData?.map((item, index: number) => (
                                <ContainerFlex
                                    Margin="1rem 0"
                                    Radius="4px"
                                    Padding="1rem 0"
                                    Width="430px"
                                    Border="1px solid #E5E7E9"
                                    key={index}
                                    Justify="space-around"
                                >
                                    <Text
                                        FontSize="0.875rem"
                                        FontWeight="500"
                                        Color="#6d6e71"
                                        Width="153px"
                                    >
                                        No. contrato {item.contractFolio}
                                    </Text>
                                    <ContainerFlex Width="190px">
                                        <StyledLinkQuoter
                                            onClick={() => detectDownload(item.urlContract)}
                                            href={item.urlContract}
                                        >
                                            <Text
                                                Width="auto"
                                                Height="40px"
                                                FontSize="1rem"
                                                BoxS="0px 0px 8px rgba(167, 169, 172, 0.25);"
                                                BorderRadius="4px"
                                                Justify="center"
                                                Border="1px solid #414042"
                                                Padding="0.75rem 1rem"
                                                Cursor="pointer"
                                            >
                                                <ImgLocation
                                                    Height="25px"
                                                    Width="25px"
                                                    src={SavePDF}
                                                    Margin="0 8px 0 0"
                                                />
                                                Imprimir contrato
                                            </Text>
                                        </StyledLinkQuoter>
                                    </ContainerFlex>
                                </ContainerFlex>
                            ))}
                        {PayQuoter.pay.data.responseEndorementData && (
                            <Text
                                Color="#414042"
                                FontWeight="500"
                                FontSize="1rem"
                                Cursor="pointer"
                                MarginTop="1.5rem"
                            >
                                Imprimir ticket de Refrendo
                            </Text>
                        )}
                        {PayQuoter.pay.data.responseEndorementData &&
                            PayQuoter.pay.data.responseEndorementData?.map(
                                (item, index: number) => (
                                    <ContainerFlex
                                        Margin="1rem 0"
                                        Radius="4px"
                                        Padding="1rem"
                                        Width="auto"
                                        Border="1px solid #E5E7E9"
                                        key={index}
                                    >
                                        No. {index + 1}
                                        <ContainerFlex Width="190px">
                                            <StyledLinkQuoter
                                                onClick={() => detectDownload(item.url[0])}
                                                href={item.url[0]}
                                            >
                                                <Text
                                                    Width="11.813rem"
                                                    Height="40px"
                                                    FontSize="1rem"
                                                    BoxS="0px 0px 8px rgba(167, 169, 172, 0.25);"
                                                    BorderRadius="4px"
                                                    Justify="center"
                                                    Border="1px solid #414042"
                                                    Padding="0.75rem 1rem"
                                                    Cursor="pointer"
                                                >
                                                    <ImgLocation
                                                        Height="25px"
                                                        Width="25px"
                                                        src={SavePDF}
                                                        Margin="0 8px 0 0"
                                                    />
                                                    Imprimir ticket
                                                </Text>
                                            </StyledLinkQuoter>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                )
                            )}

                        {PayQuoter.pay.data.responseDischargeData && (
                            <Text
                                Color="#414042"
                                FontWeight="500"
                                FontSize="1rem"
                                Cursor="pointer"
                                MarginTop="1.5rem"
                            >
                                Imprimir ticket de Desempeño
                            </Text>
                        )}
                        {PayQuoter.pay.data.responseDischargeData &&
                            PayQuoter.pay.data.responseDischargeData?.map((item, index: number) => (
                                <ContainerFlex
                                    Margin="1rem 0"
                                    Radius="4px"
                                    Padding="1rem"
                                    Width="auto"
                                    key={index}
                                >
                                    <Text
                                        FontSize="0.875rem"
                                        FontWeight="500"
                                        Color="#6d6e71"
                                        Width="153px"
                                    >
                                        No. {index + 1}
                                    </Text>
                                    <ContainerFlex Width="190px">
                                        <StyledLinkQuoter
                                            onClick={() => detectDownload(item.url)}
                                            href={item.url}
                                        >
                                            <Text
                                                Width="11.813rem"
                                                Height="40px"
                                                FontSize="1rem"
                                                BoxS="0px 0px 8px rgba(167, 169, 172, 0.25);"
                                                BorderRadius="4px"
                                                Justify="center"
                                                Border="1px solid #414042"
                                                Padding="0.75rem 1rem"
                                                Cursor="pointer"
                                            >
                                                <ImgLocation
                                                    Height="25px"
                                                    Width="25px"
                                                    src={SavePDF}
                                                    Margin="0 8px 0 0"
                                                />
                                                Imprimir ticket
                                            </Text>
                                        </StyledLinkQuoter>
                                    </ContainerFlex>
                                </ContainerFlex>
                            ))}

                        {Object.values(contract).length !== endeavors + discharges + endorsment &&
                            showPrintError && (
                                <ContainerFlex>
                                    <Text Color="red">Favor de imprimir el contrato</Text>
                                </ContainerFlex>
                            )}

                        <ContainerFlex Margin="2.5rem 0">
                            <ButtonGenerals
                                Border="none"
                                Padding="0 16px"
                                Width="auto"
                                Height="40px"
                                FontWeight="500"
                                Transform="none"
                                HColor="#35CF44"
                                onClick={() => {
                                    if (
                                        Object.values(contract).length ===
                                        endeavors + discharges + endorsment
                                    ) {
                                        dispatch(cancelQuoterOperation());
                                        window.location.href = '/';
                                    } else {
                                        setShowPrintError(true);
                                        return;
                                    }
                                }}
                            >
                                Finalizar
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            ) : (
                <ContainerFlex Width="100%">
                    <LoadingGeneral />
                </ContainerFlex>
            )}
        </>
    );
};

export default PaymentContract;
