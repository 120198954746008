import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {FieldValues, useForm} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {
    HOLIDAYS,
    LAST_LOCATION,
    NEWBRANCHGROUP,
    getNamesforHoliday,
    holidaysHours,
    isOptional,
    scheduleHours,
    storageInventory,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import {
    getBranchByLevel,
    isEditBranch,
} from '@components/LocationsBranch/Redux/Action/GetBranchByLevel';
import * as style from '@components/LocationsBranch/stylesLocations';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {SellChannels} from '@components/LocationsBranch/SellChannels';
import {BusinessLines} from '@components/LocationsBranch/BusinessLines';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {newBranch} from '@components/LocationsBranch/Redux/Action/NewBranch';
import {HolidaysSchedule} from '@components/LocationsBranch/HolidaysSchedule';
import {AddressLocations} from '@/components/LocationsBranch/AddressLocations';
import {updateBranch} from '@components/LocationsBranch/Redux/Action/UpdateBranch';
import {newBranchesShema} from '@components/LocationsBranch/createLocationsErrors';
import {ICreateBranches, IFormBranch} from '@components/LocationsBranch/interfaces';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormInitialization';
import {IHolidaysHours, IOpeningHours} from '@components/LocationsBranch/Redux/interfaces';
import {resetFormLocation} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const NewBranchGroup = ({setShowHolidaysModal, setModalAddress}: ICreateBranches) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {initialize} = useInitializeForm();
    const [modal, setModal] = useState({addUser: false, locations: false});

    const {branch, token, addBranch, inventory, levels, editBranch, companyId} = useSelector(
        (state: RootState) => ({
            branch: state.getCreateGroupBranch,
            token: String(state.getUsersValidation.userData?.token),
            companyId: Number(
                state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
            ),
            addBranch: state.newBranch,
            inventory: state.Warehouse.warehouses,
            levels: state.getLevels.level,
            editBranch: state.getBranchByLevel,
            addedUsers: state.AddUserEmployee.addedUsers,
        })
    );

    const {
        register,
        control,
        watch,
        setValue,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameBranch: '',
            branchDirection: {},
            businessLines: [],
            branchSalesChannel: [],
            users: [],
            openingHours: [],
            holidays: getNamesforHoliday(branch.holidays),
            storages: [],
        },
        resolver: yupResolver(newBranchesShema(editBranch.isEdit)),
    });

    const createBranch = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        const json = {
            branchLevelId: levels && levels.levelId,
            nameBranch: values.nameBranch,
            branchDirection: values.branchDirection,
            branchBusinessLine: values.branchBusinessLine,
            branchSalesChannel: values.branchSalesChannel,
            ...isOptional('branchUsers', values.users),
            ...isOptional('branchOpeningHour', openingHours),
            ...isOptional('branchHoliday', holidayHours),
            ...isOptional(
                'branchStorage',
                storageInventory(inventory, 'nameStorage', 'branchStorage')
            ),
        };
        dispatch(newBranch(token, navigate, json));
    };

    const updateBranches = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        const json = {
            branchId: editBranch.branchId,
            nameBranch: values.nameBranch,
            branchDirectionP: values.branchDirection,
            ...isOptional('branchUsersP', values.users),
            ...isOptional('branchOpeningHourP', openingHours),
            ...isOptional('branchHolidayP', holidayHours),
        };
        dispatch(updateBranch(token, cancelOperation, json));
    };

    const onsubmit = (values: FieldValues) => {
        const openingHours =
            values.openingHours && scheduleHours(Object.values(values.openingHours));
        const holidayHours =
            values.holidays && holidaysHours(Object.values(values.holidays), branch.holidays);

        if (editBranch.isEdit) updateBranches(values, openingHours, holidayHours);
        else createBranch(values, openingHours, holidayHours);
    };

    const cancelOperation = () => {
        resetFormLocation(dispatch);
        dispatch(isEditBranch(false, navigate));
    };

    const initializeBranch = () => {
        const {
            branchName = '',
            levelId = 0,
            description = '',
            branchDirection = null,
            userBranch = [],
            openingHourBranchs = [],
            holidaysBranchs = [],
            storagesBranchs = null,
        } = editBranch.branchByLevel;

        const source: IFormBranch = {
            name: branchName,
            levelId: levels && levels.levelId,
            description,
            address: branchDirection,
            openingHours: openingHourBranchs,
            holidays: holidaysBranchs,
            storagesDetail: storagesBranchs,
            users: userBranch,
            companyId: companyId,
            fatherId: levelId,
        };
        initialize(source, NEWBRANCHGROUP.NAME_FORM, setValue);
    };

    useEffect(() => {
        if (editBranch.isEdit)
            dispatch(
                getBranchByLevel(token, {BranchId: editBranch.branchId, LevelId: levels.levelId})
            );
    }, [editBranch.branchId]);
    useEffect(() => {
        if (editBranch.branchId && editBranch.branchByLevel) initializeBranch();
    }, [editBranch.branchByLevel]);

    return (
        <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
            {branch.loading || editBranch.loading ? (
                <ContainerFlex>
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {editBranch.isEdit
                            ? editBranch.branchByLevel.branchName
                            : NEWBRANCHGROUP.TITLE}
                    </Text>
                    <NameLocation
                        newName={addBranch}
                        nameLocation={NEWBRANCHGROUP.NAME_FORM}
                        register={register}
                        errors={errors}
                        watch={watch}
                        reset={reset}
                    />
                    <RoutesLocations lastLevel={LAST_LOCATION.NEW_BRANCH} />
                    <AddressLocations
                        errors={errors}
                        setValue={setValue}
                        nameAddress={NEWBRANCHGROUP.ADDRESS_FORM}
                        setModalAddress={setModalAddress}
                    />
                    {!editBranch.isEdit && (
                        <>
                            <BusinessLines
                                setValue={setValue}
                                errors={errors}
                                nameBusiness={NEWBRANCHGROUP.LINES}
                            />
                            <SellChannels
                                setValue={setValue}
                                errors={errors}
                                nameBusiness={NEWBRANCHGROUP.CHANNELS}
                            />
                        </>
                    )}
                    <UserGroup modal={modal} setModal={setModal} setValue={setValue} />
                    <HolidaysSchedule
                        register={register}
                        control={control}
                        errors={errors}
                        setShowHolidaysModal={setShowHolidaysModal}
                        watch={watch}
                        reset={reset}
                        setValue={setValue}
                        isOptional={false}
                    />
                    <LocationStorage isOptional={false} />
                    {addBranch.loading ? (
                        <ContainerFlex Height="2.5rem">
                            <LoadingAtaskate />
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Gap="1.5rem" Height="2.5rem">
                            <ButtonGeneral
                                width="6.25rem"
                                text={HOLIDAYS.CANCEL}
                                clic={() => cancelOperation()}
                                color="#5A5AFF"
                                backGround="#FFFFFF"
                                hText="#5A5AFF"
                                hShadow=""
                                height="2.5rem"
                                cursor="pointer"
                                border="1px solid #5A5AFF"
                                hBackG="#E5E5FF"
                            />
                            <ButtonGeneral
                                width="6.25rem"
                                text={HOLIDAYS.SAVE}
                                type="submit"
                                height="2.5rem"
                                cursor="pointer"
                            />
                        </ContainerFlex>
                    )}
                </>
            )}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
        </ContainerForm>
    );
};
