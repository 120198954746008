export const GET_PRODUCTS_SHOPIFY = 'GET_PRODUCTS_SHOPIFY';
export const GET_PRODUCTS_SHOPIFY_SUCCESS = 'GET_PRODUCTS_SHOPIFY_SUCCESS';
export const GET_PRODUCTS_SHOPIFY_ERROR = 'GET_PRODUCTS_SHOPIFY_ERROR';

export const GET_CARSHOOPINGID_TEMPORAL = 'GET_CARSHOOPINGID_TEMPORAL';

export const ADD_CATALOG_ARTICLE = 'ADD_CATALOG_ARTICLE';
export const ADD_CATALOG_ARTICLE_SUCCESS = 'ADD_CATALOG_ARTICLE_SUCCESS';
export const ADD_CATALOG_ARTICLE_ERROR = 'ADD_CATALOG_ARTICLE_ERROR';

export const GET_CAR_DATA = 'GET_CAR_DATA';
export const GET_CAR_COMPLETE_VALUATOR = 'GET_CAR_COMPLETE_VALUATOR';

export const GET_CAR_DATA_SUCCESS = 'GET_CAR_DATA_SUCCESS';
export const GET_CAR_DATA_ERROR = 'GET_CAR_DATA_ERROR';

export const DELETE_CAR = 'DELETE_CAR';
export const DELETE_CAR_SUCCESS = 'DELETE_CAR_SUCCESS';
export const DELETE_CAR_ERROR = 'DELETE_CAR_ERROR';

export const GET_PAGE_ECOMMERCE = 'GET_PAGE_ECOMMERCE';
export const GET_PAGE_ECOMMERCE_SUCCESS = 'GET_PAGE_ECOMMERCE_SUCCESS';
export const GET_PAGE_ECOMMERCE_ERROR = 'GET_PAGE_ECOMMERCE_ERROR';

export const GET_PRODUCTS_SHOPIFY_DETAIL = 'GET_PRODUCTS_SHOPIFY_DETAIL';
export const GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS = 'GET_PRODUCTS_SHOPIFY_DETAIL_SUCCESS';
export const GET_PRODUCTS_SHOPIFY_DETAIL_ERROR = 'GET_PRODUCTS_SHOPIFY_DETAIL_ERROR';

export const POST_PRODUCTS_SHOPIFY = 'POST_PRODUCTS_SHOPIFY';
export const POST_PRODUCTS_SHOPIFY_SUCCESS = 'POST_PRODUCTS_SHOPIFY_SUCCESS';
export const POST_PRODUCTS_SHOPIFY_ERROR = 'POST_PRODUCTS_SHOPIFY_ERROR';

export const POST_UNAUTHORIZED = 'POST_UNAUTHORIZED';

export const GET_INFO_CLIENT = 'GET_INFO_CLIENT';
export const GET_INFO_CLIENT_SUCCESS = 'GET_INFO_CLIENT_SUCCESS';
export const GET_INFO_CLIENT_ERROR = 'GET_INFO_CLIENT_ERROR';

export const GET_INFO_MOVIMIENTS = 'GET_INFO_MOVIMIENTS';
export const GET_INFO_MOVIMIENTS_SUCCESS = 'GET_INFO_MOVIMIENTS_SUCCESS';
export const GET_INFO_MOVIMIENTS_ERROR = 'GET_INFO_MOVIMIENTS_ERROR';

export const GET_PRODUCTS_SHOPIFY_DETAILS = 'GET_PRODUCTS_SHOPIFY_DETAILS';
export const GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS = 'GET_PRODUCTS_SHOPIFY_DETAILS_SUCCESS';
export const GET_PRODUCTS_SHOPIFY_DETAILS_ERROR = 'GET_PRODUCTS_SHOPIFY_DETAILS_ERROR';

export const GET_DOCUMENT_CLIENT = 'GET_DOCUMENT_CLIENT';
export const GET_DOCUMENT_CLIENT_SUCCESS = 'GET_DOCUMENT_CLIENT_SUCCESS';
export const GET_DOCUMENT_CLIENT_ERROR = 'GET_DOCUMENT_CLIENT_ERROR';

export const GET_CONTRACT_CLIENT = 'GET_CONTRACT_CLIENT';
export const GET_CONTRACT_CLIENT_SUCCESS = 'GET_CONTRACT_CLIENT_SUCCESS';
export const GET_CONTRACT_CLIENT_ERROR = 'GET_CONTRACT_CLIENT_ERROR';

export const GET_IDROW = 'GET_IDROW';

export const GET_DETAIL_PAY = 'GET_DETAIL_PAY';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_ERROR = 'GET_TRANSACTION_ERROR';

export const PUT_DELIVERY_CONTRACT = 'PUT_DELIVERY_CONTRACT';
export const PUT_DELIVERY_CONTRACT_SUCCESS = 'PUT_DELIVERY_CONTRACT_SUCCESS';
export const PUT_DELIVERY_CONTRACT_ERROR = 'PUT_DELIVERY_CONTRACT_ERROR';

export const POST_EMAIL_VERIFICATION = 'POST_EMAIL_VERIFICATION';
export const POST_EMAIL_VERIFICATION_SUCCESS = 'POST_EMAIL_VERIFICATION_SUCCESS';
export const POST_EMAIL_VERIFICATION_ERROR = 'POST_EMAIL_VERIFICATION_ERROR';

export const PUT_REACTIVATION_CONTRACT = 'PUT_REACTIVATION_CONTRACT';
export const PUT_REACTIVATION_CONTRACT_SUCCESS = 'PUT_REACTIVATION_CONTRACT_SUCCESS';
export const PUT_REACTIVATION_CONTRACT_ERROR = 'PUT_REACTIVATION_CONTRACT_ERROR';

export const ADD_CARRITO = 'ADD_CARRITO';
export const ADD_CARRITO_START = 'ADD_CARRITO_START';
export const ADD_CARRITO_SUCCESS = 'ADD_CARRITO_SUCCESS';
export const ADD_CARRITO_ERROR = 'ADD_CARRITO_ERROR';

export const ADD_CARRITO_SHOPI = 'ADD_CARRITO_SHOPI';
export const GET_FILTERS_MOVIMIENTS = 'GET_FILTERS_MOVIMIENTS';
export const GET_DETAIL_PAY_SHOPI = 'GET_DETAIL_PAY_SHOPI';

export const GET_ENDEAVORS_START = 'GET_ENDEAVORS_START';
export const GET_ENDEAVORS_SUCCESS = 'GET_ENDEAVORS_SUCCESS';
export const GET_ENDEAVORS_ERROR = 'GET_ENDEAVORS_ERROR';

export const DELETE_CARD_START = 'DELETE_CARD_START';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR = 'DELETE_CARD_ERROR';

export const PUT_PROMOTION = 'PUT_PROMOTION';
export const PUT_PROMOTION_SUCCESS = 'PUT_PROMOTION_SUCCESS';
export const PUT_PROMOTION_ERROR = 'PUT_PROMOTION_ERROR';
