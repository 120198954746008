import {AppDispatch} from '@/config/store';
import {
    ADMIN_USER_PROFILE_SETTINGS,
    ADMIN_USER_PROFILE_SETTINGS_SUCCESS,
    ADMIN_USER_PROFILE_SETTINGS_ERROR,
} from '@components/AdminNavbar/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

export const adminUserProfileSettings = () => {
    return {
        type: ADMIN_USER_PROFILE_SETTINGS,
    };
};
export const adminUserProfileSettingsSuccess = (result: AxiosResponse) => {
    return {
        type: ADMIN_USER_PROFILE_SETTINGS_SUCCESS,
        adminProfile: result.data,
    };
};
export const adminUserProfileSettingsError = (error: AxiosError) => {
    return {
        type: ADMIN_USER_PROFILE_SETTINGS_ERROR,
        error: error,
    };
};

export function infoAdminUserProfile(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(adminUserProfileSettings());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AdminUserProfileSettings}`,
                {
                    headers,
                }
            );

            dispatch(adminUserProfileSettingsSuccess(response.data));
        } catch (error) {
            dispatch(adminUserProfileSettingsError(error as AxiosError));
        }
    };
}
