import {Level} from '@/components/Roles/AddRoles/interfaces';

export const transformToStrings = (numbers: number[]): string[] => {
    return numbers.map((data) => data.toString());
};

export const defaultSelected = (
    levels: Level[],
    setLevels: Function,
    setBranches: Function,
    setBranchesDate: Function
) => {
    levels.forEach((level) => {
        setLevels((prevLevelIds: number[]) => [...prevLevelIds, level.idLevel]);
        level.branches.forEach((branch) => {
            setBranchesDate((prevBranchIds: number[]) => [...prevBranchIds, branch.idBranch]);
            setBranches((prevBranchIds: {branchId: number; branchName: string}[]) => [
                ...prevBranchIds,
                {idbranch: branch.idBranch, branchName: branch.branchName},
            ]);
        });
        if (level.nextLevel.length > 0) {
            defaultSelected(level.nextLevel, setLevels, setBranches, setBranchesDate);
        }
    });
};
