import styled from '@emotion/styled';
import {IPropsContainer} from '@components/NavBarPOS/SearchComponent/interface';

const getButtonStyles = (variant: string) => {
    switch (variant) {
        case 'nav':
            return `
            background-color: #5A5AFF;
            border: 1px solid #5A5AFF;
            `;
        case 'component':
            return `
            background-color: #FFFFFF;
            border: 1px solid #FFFFFF;
            `;
    }
};

export const SearchInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 66%;
    height: 38px;
    border: 1px solid #e8e9ea;
    border-radius: 32px;
    background: #ffffff;
    font-size: 1rem;
    &:hover {
        border: 1px solid #5a5aff;
    }
    &:focus-within {
        border: 1px solid #5a5aff;
    }
`;
export const Input = styled.input`
    display: flex;
    width: 100%;
    height: auto;
    border: none;
    border-radius: 32px;
    padding: 0 16px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #a1a5aa;
    }
`;
export const SearchIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border: none;
    padding: 8px 8px 8px 16px;
    border-radius: 32px 0px 0px 32px;
    & svg path {
        fill: #acacff;
    }
`;
export const CancelIconContainer = styled.div<IPropsContainer>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    border: none;
    padding: ${({variant}) => (variant === 'nav' ? '8px 16px 8px 8px' : '0')};
    & svg path {
        fill: #5a5aff;
    }
`;
export const ButtonContainer = styled.button<IPropsContainer>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 38px;
    border: none;
    padding: 8px 16px;
    border-radius: 0px 32px 32px 0px;
    ${({variant}) => getButtonStyles(variant)};
    cursor: pointer;
`;
