import axios from 'axios';
import {URL} from '@config/constants';
import {AppDispatch} from '@/config/store';

import {
    PROPERTIES_ARTICLES,
    PROPERTIES_ARTICLES_SUCCESS,
    PROPERTIES_ARTICLES_ERROR,
    CANCEL_QUOTER,
    RESET_ARTCILE_DINAMICS,
    LEVELS,
} from '@TypesQouter/Types';
import StringUtils from '@GenericScripts/utils';
import {levelArticle, levelBrand, levelFamily, levelSubFamily} from '@Quoter/Redux/intefaces';
import {IArticle, IFamily, IGroup, IProperties, ISubfamily} from '@Quoter/inferfaces';

export const articleProperties = () => ({type: PROPERTIES_ARTICLES});
export const articleload = (value: boolean) => {
    return {type: LEVELS.LOADING, loadingArticle: value};
};
export const articlePropertiesSuccesss = (result: {data: []}) => {
    return {
        type: PROPERTIES_ARTICLES_SUCCESS,
        propertiesarticles: result.data,
    };
};

export const articlePropertiesError = () => ({
    type: PROPERTIES_ARTICLES_ERROR,
});

export const resetDinamics = () => {
    return {
        type: CANCEL_QUOTER,
    };
};

export const resetInputDinamics = () => {
    return {
        type: RESET_ARTCILE_DINAMICS,
    };
};

export const articleDinamics = (token: string, filters: IProperties) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(articleProperties());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ArticleProperties}${queryString}`,
                {headers}
            );
            dispatch(articlePropertiesSuccesss(response.data));
        } catch (error) {
            dispatch(articlePropertiesError());
        }
    };
};

export const ErrorPetition = (value: unknown) => {
    return {
        type: LEVELS.ERRORPETITION,
        payload: value,
    };
};
export const GetGroup = (value: {data: {breadCrumbs: null; groupList: IGroup[]}}) => {
    return {
        type: LEVELS.LEVELGROUP,
        group: value.data,
    };
};
export const Group = (token: string, branchId: number) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelGroup.replace(
                    '{branchId}',
                    String(branchId)
                )}`,
                {headers}
            );
            dispatch(GetGroup(response.data));
        } catch (error) {
            dispatch(ErrorPetition(error));
        }
    };
};

export const GetFamily = (value: {data: {breadCrumbs: null; familyData: IFamily[]}}) => {
    return {
        type: LEVELS.LEVELFAMILY,
        family: value.data,
    };
};
export const Family = (token: string, data: levelFamily) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelFamily}${queryString}`,
                {headers}
            );
            dispatch(GetFamily(response.data));
        } catch (error) {
            dispatch(ErrorPetition(error));
        }
    };
};

export const GetSubfamily = (value: {data: {breadCrumbs: null; subfamilyList: ISubfamily[]}}) => {
    return {
        type: LEVELS.LEVELSUBFAMILY,
        subfamily: value.data,
    };
};

export const Subfamily = (token: string, data: levelSubFamily) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelSubFamily}${queryString}`,
                {headers}
            );
            dispatch(GetSubfamily(response.data));
        } catch (error) {
            dispatch(ErrorPetition(error));
        }
    };
};
export const GetBrand = (value: {data: {breadCrumbs: null; brandList: ISubfamily[]}}) => {
    return {
        type: LEVELS.LEVELBRAND,
        brand: value.data,
    };
};
export const Brand = (token: string, data: levelBrand) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelBrand}${queryString}`,
                {headers}
            );
            dispatch(GetBrand(response.data));
        } catch (error) {
            dispatch(ErrorPetition(error));
        }
    };
};

export const GetArticle = (value: {data: {breadCrumbs: null; articleList: IArticle[]}}) => {
    return {
        type: LEVELS.LEVELARTICLE,
        article: value.data,
    };
};

export const Article = (token: string, data: levelArticle) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelArticle}${queryString}`,
                {headers}
            );
            dispatch(GetArticle(response.data));
        } catch (error) {
            dispatch(ErrorPetition(error));
        }
    };
};
