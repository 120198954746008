import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {cartContracts} from '@components/ShoppingCart/interface';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';

import RenewalItem from '@components/ShoppingCart/RenewalItem';

const Renewals = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);

    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [openSubMenuId, setOpenSubMenuId] = useState<number | null>(null);
    const [selectedMenuOptionId, setSelectedMenuOptionId] = useState<string | null>(null);
    const [selectedSubMenuOption, setSelectedSubMenuOption] = useState<string | null>(null);
    const handleMenuToggle = (menuId: string) => {
        setOpenMenuId((prevId) => (prevId === menuId ? null : menuId));
        setOpenSubMenuId(null);
        setSelectedMenuOptionId(null);
    };

    const handleSubMenuToggle = (menuId: number, value: string) => {
        setOpenSubMenuId((prevId) => {
            if (prevId === menuId) {
                return null;
            }
            setOpenMenuId(null);
            return menuId;
        });
        setSelectedMenuOptionId(null);
        setSelectedSubMenuOption(value);
    };

    const handleOptionSelect = (menuId: string) => {
        setSelectedMenuOptionId(menuId);
    };

    useEffect(() => {
        if (token && carShoppingId && contracts) {
            dispatch(fetchCartById(token, carShoppingId));
        }
    }, [contracts]);

    return (
        <FlexPanel
            Width="24rem"
            FlexDir="column"
            Align="flex-start"
            Justify="space-between"
            OverFlowY="auto"
            OverFlowX="hidden"
        >
            {cartData &&
                cartData.endorsements &&
                cartData.endorsements.map((endorsement) => (
                    <RenewalItem
                        key={endorsement.contractId}
                        carShoppingId={cartData.carShoppingId}
                        endorsement={endorsement}
                        isOpen={openMenuId === `e${endorsement.contractId}`}
                        onMenuToggle={() => handleMenuToggle(`e${endorsement.contractId}`)}
                        isSubMenuOpen={openSubMenuId === endorsement.contractId}
                        onSubMenuToggle={(value: string) =>
                            handleSubMenuToggle(endorsement.contractId, value)
                        }
                        isSelected={selectedMenuOptionId === `e${endorsement.contractId}`}
                        onSelectOption={() => handleOptionSelect(`e${endorsement.contractId}`)}
                        selectedSubMenuOption={selectedSubMenuOption}
                    />
                ))}
        </FlexPanel>
    );
};
export default Renewals;
