import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {fetchNewPassOrganization} from '@ActionsOrgCreation/GetAllLevels';
import {LevelsTreeOrganization} from '@SubContainerOrgCreation/LevelsTreeOrganization';
import {checkedState, fetchUpdateSelectNodeId} from '@ActionsOrgCreation/CheckedState';

export const SelectFatherOrganization = () => {
    const LevelData = useSelector((state: RootState) => {
        return state.GetLevelsData;
    });
    const LevelStatement = useSelector((state: RootState) => {
        return state.GetStatmentData;
    });
    const dispatch: AppDispatch = useDispatch();
    return (
        <ContainerFlex
            backG="#fff"
            Width="33rem"
            Height="auto"
            Align="flex-start"
            FlexDir="column"
            BoxS="0 0 12px 0 rgba(0, 0, 0, 0.5)"
        >
            <ContainerFlex Justify="flex-start" Height="56px" Bb="solid 1px #35cf44">
                <Text Padding="16.5px 24px" FontSize="1.25rem" Color="#35cf44">
                    Seleccionar nivel organizacional
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="flex-start" Padding="24px 24px 0 24px">
                <Text FontSize="0.875rem">Seleccionar nivel organizacional</Text>
                <ContainerFlex
                    MarginTop="32px"
                    FlexDir="column"
                    Justify="flex-start"
                    Align="flex-start"
                    OverFlowY="scroll"
                    Height="300px"
                >
                    <LevelsTreeOrganization data={LevelData.node} />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Bt="solid 1px #e5e7e9"
                Justify="flex-end"
                Align="flex-end"
                Padding="24px"
                Height="88px"
                ColumnGap="24px"
            >
                <ButtonGeneral
                    width="93px"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        dispatch(fetchNewPassOrganization(1));
                        dispatch(fetchUpdateSelectNodeId(''));
                    }}
                />
                <ButtonGeneral
                    width="168px"
                    text="Guardar y continuar"
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        dispatch(fetchNewPassOrganization(1));
                        dispatch(checkedState(LevelStatement.updateStatement));
                    }}
                    disabled={LevelStatement.updateStatement.length === 0}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
