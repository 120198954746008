import axios, {AxiosResponse} from 'axios';
import {
    GET_TIERED_LEVEL,
    GET_TIERED_LEVEL_ERROR,
    GET_TIERED_LEVEL_SUCCESS,
} from '@components/Branches/Redux/Types/types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetTieredLevelStart = () => {
    return {
        type: GET_TIERED_LEVEL,
    };
};
export const fetchGetTieredLevelSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TIERED_LEVEL_SUCCESS,
        payload: result,
    };
};
export const fetchGetTieredLevelError = () => {
    return {
        type: GET_TIERED_LEVEL_ERROR,
    };
};

export function GetTieredLevel(
    data: {companyId: number; levelId: number | undefined},
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetTieredLevelStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTieredLevel}`,
                {
                    headers,
                    params: {
                        companyId: data.companyId,
                        levelId: data.levelId,
                    },
                }
            );
            dispatch(fetchGetTieredLevelSuccess(response));
        } catch (error) {
            dispatch(fetchGetTieredLevelError());
        }
    };
}
