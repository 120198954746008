import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import FilterDatePicker from '@/components/CreditsAndPawns/FilterModal/FilterDatePicker';
import {IFilterValues} from '@/components/CreditsAndPawns/interfaces';

interface IFilterRangePickerProps {
    dispatchFilterValues: (value: IFilterValues) => void;
    filtersValues: IFilterValues;
    title: string;
    startFilterName: string;
    endFilterName: string;
    startFilterValue: Date | null;
    endFilterValue: Date | null;
    disabled?: boolean;
    minDate?: Date | null;
}

const FilterRangePicker = ({
    dispatchFilterValues,
    endFilterName,
    filtersValues,
    startFilterName,
    endFilterValue,
    startFilterValue,
    disabled,
    title,
    minDate,
}: IFilterRangePickerProps) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Gap="4px"
            Justify="flex-start"
            Align="flex-start"
            Width="100%"
            Height={'68px'}
        >
            <Text
                Color="#1D1E20"
                FontFamily="Nunito"
                FontWeight="700"
                LHeight="24px"
                LetterSpacing="0.3px"
            >
                {title}
            </Text>
            <ContainerFlex Gap="8px">
                <FilterDatePicker
                    handleFilter={dispatchFilterValues}
                    filterName={startFilterName}
                    filters={filtersValues}
                    value={startFilterValue}
                />
                <span>-</span>
                <FilterDatePicker
                    handleFilter={dispatchFilterValues}
                    filterName={endFilterName}
                    filters={filtersValues}
                    value={endFilterValue}
                    disabled={disabled}
                    minDate={minDate}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default FilterRangePicker;
