import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {fetchSelectStateModalOrg} from '@ActionsOrgCreation/GetAllLevels';
import IconDelete from '@SubContainerOrgCreation/Icons/DeleteLevelOrganization.svg';
import {checkedState} from '@ActionsOrgCreation/CheckedState';
import {BolderWords, NormalWords} from '@OrganizationCreation/SubContainer/styles';
import {IDeleteOrganization} from '@OrganizationCreation/SubContainer/interface';
import {DeleteUpdateLevel} from '@OrganizationCreation/Redux/Actions/DeleteLevels';

export const DeleteLevelOrganization = ({
    setCurrentPage,
    changeModalState,
}: IDeleteOrganization) => {
    const token = useSelector((state: RootState) => state.getUsersValidation?.userData);
    const LevelStatement = useSelector((state: RootState) => {
        return state.GetStatmentData.statement;
    });
    const dispatch: AppDispatch = useDispatch();
    const selectDeleteUpdateLevel = () => {
        dispatch(
            DeleteUpdateLevel(
                {
                    businessId: Number(token?.enterpriceDetails?.[0]?.enterpriceId),
                    levelId: LevelStatement.idLevel,
                },
                String(token?.token)
            )
        );
        setCurrentPage(1);
        dispatch(fetchSelectStateModalOrg(false));
        changeModalState(false);
        dispatch(checkedState(''));
    };
    return (
        <ContainerFlex
            backG="#fff"
            Height="auto"
            Width="30rem"
            FlexDir="column"
            BoxS="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            Padding="24px"
        >
            <ImgLocation Width="251px" Height="141px" src={IconDelete} />
            <Text
                Padding="1.5rem 0"
                FontSize="0.875rem"
                Color="#414042"
                FontWeight="normal"
                wSpace="normal"
                Height="auto"
                TextAlign="center"
            >
                ¿Estás seguro que deseas eliminar el nivel organizacional?
            </Text>
            <ContainerFlex
                FlexDir="column"
                backG="#fff2ce"
                Border="solid 1px #ffbd00"
                Radius="4px"
                Padding="0.875rem"
                Height="76px"
                RowGap="4px"
            >
                <Text {...NormalWords}>
                    <Text {...BolderWords} Padding="0 4px 0 0">
                        ¡Recuerda!:
                    </Text>
                    Todas las sucursales y usuarios que tengas
                </Text>
                <Text {...NormalWords}>
                    asociados a esté nivel organizacional, serán
                    <Text {...BolderWords} Padding="0 4px">
                        reasignados
                    </Text>
                </Text>
                <Text {...NormalWords}>al nivel superior.</Text>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Width="14.063rem" Padding="1.5rem 0 0 0">
                <ButtonGeneral
                    width="5.813rem"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        changeModalState(false);
                        dispatch(fetchSelectStateModalOrg(false));
                        dispatch(checkedState(''));
                    }}
                />
                <ButtonGeneral
                    width="6.25rem"
                    text="Continuar"
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform="capitalize"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => selectDeleteUpdateLevel()}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
