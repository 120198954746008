import axios from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    SAVE_ACCOUNT_DATA,
    SAVE_COMPANY_DATA,
    SAVE_PLAN_DATA,
    SAVE_TYPE_SALE_DATA,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_ERROR,
    BACK_TO_STEP,
    RESET_ACCOUNT_DATA,
} from '@components/SignUp/Redux/types';
import {ISignUpData, saveCompany, savePlaces, ICreateUserData} from '@components/SignUp/interfaces';
import {getValidateUsersSuccess} from '@/components/Login/Redux/Actions/GetValidateUsers';

export const saveAccountData = (data: ISignUpData) => {
    return {type: SAVE_ACCOUNT_DATA, accountData: data};
};
export const backToStep = () => ({type: BACK_TO_STEP});
export const saveCompanytData = (result: saveCompany) => ({
    type: SAVE_COMPANY_DATA,
    company: result,
});
export const saveTypeSaleData = (result: savePlaces) => ({
    type: SAVE_TYPE_SALE_DATA,
    places: result,
});
export const savePlantData = () => ({type: SAVE_PLAN_DATA});
export const postDataForm = () => ({type: CREATE_ACCOUNT});

export const postDataFormError = () => ({
    type: CREATE_ACCOUNT_ERROR,
});

export const resetAccountData = () => ({type: RESET_ACCOUNT_DATA});

export function saveFormData(data: ICreateUserData) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postDataForm());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateUserAdministrator}`,
                data,
                {headers}
            );
            dispatch(getValidateUsersSuccess(response));
            dispatch(resetAccountData());
        } catch (error) {
            dispatch(postDataFormError());
        }
    };
}
