import {AppDispatch} from '@/config/store';
import {FieldValues} from 'react-hook-form';

import {PERSISTENCE_GROUP_BRANCH} from '@components/LocationsBranch/Redux/types';
import {resetSaveUsers} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import {resetAddedUsers} from '@components/LocationsBranch/Redux/Action/AddUser';
import {selectSearchedStorage} from '@/components/LocationsBranch/Redux/Action/SearchInventoryStorage';
import {IScheduleSlot} from '@/components/ScheduleTable/interfaces/interfaces';

export const saveNameLocations = (result: string) => ({
    type: PERSISTENCE_GROUP_BRANCH.NAME,
    name: result,
});

export const saveBusiness = (businessLine: number[]) => ({
    type: PERSISTENCE_GROUP_BRANCH.BUSINESS_LINE,
    businessLine: businessLine,
});

export const saveSalesChannels = (branchSalesChannel: number[]) => ({
    type: PERSISTENCE_GROUP_BRANCH.SALES_CHANNELS,
    branchSalesChannel: branchSalesChannel,
});

export const saveUsers = (result: number[]) => ({
    type: PERSISTENCE_GROUP_BRANCH.USERS,
    user: result,
});

export const saveOpeningHoursAndHolidays = (result: FieldValues) => ({
    type: PERSISTENCE_GROUP_BRANCH.SCHEDULES_AND_HOLIDAYS,
    openingHourAndHolidays: result,
});

export const saveStorages = (result: []) => ({
    type: PERSISTENCE_GROUP_BRANCH.STORAGE,
    storage: result,
});

export const resetNewLocation = () => ({
    type: PERSISTENCE_GROUP_BRANCH.RESET,
});

export const resetFormLocation = (dispatch: AppDispatch) => {
    dispatch(resetSaveUsers());
    dispatch(resetNewLocation());
    dispatch(resetAddedUsers());
    dispatch(selectSearchedStorage({title: '', ids: []}));
};

export const updateHolidays = (result: IScheduleSlot[]) => ({
    type: PERSISTENCE_GROUP_BRANCH.HOLIDAYS_UPDATE,
    holidays: result,
});
