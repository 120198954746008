import {AppDispatch} from '@/config/store';
import {
    POST_ADD_CHECK_IDENTITY_START,
    POST_ADD_CHECK_IDENTITY_SUCCESS,
    POST_ADD_CHECK_IDENTITY_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {IAddCheckIdentity} from '@components/PersonalLoans/interfaces';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

export const postAddCheckIdentityStart = () => {
    return {
        type: POST_ADD_CHECK_IDENTITY_START,
    };
};

export const postAddCheckIdentitySuccess = (result: AxiosResponse) => {
    return {
        type: POST_ADD_CHECK_IDENTITY_SUCCESS,
        payload: result.data,
    };
};

export const postAddCheckIdentityError = (error: AxiosError) => {
    return {
        type: POST_ADD_CHECK_IDENTITY_ERROR,
        payload: error,
    };
};

export const postAddCheckIdentity = (data: IAddCheckIdentity, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postAddCheckIdentityStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddCheckIdentity}`,
                data,
                {headers}
            );
            dispatch(postAddCheckIdentitySuccess(response));
            dispatch(ActualDataStepLoans(4));
        } catch (error) {
            dispatch(postAddCheckIdentityError(error as AxiosError));
        }
    };
};

export default postAddCheckIdentity;
