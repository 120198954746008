import {
    HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE,
    SET_FREQUENCY_PAYMENTS_TABLE,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IFrequencyItem} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const setFrequencyPaymentsTable = (result: IFrequencyItem[]) => {
    return {
        type: SET_FREQUENCY_PAYMENTS_TABLE,
        payload: result,
    };
};

export const handleCheckboxFrequencyTable = (id: number) => {
    return {
        type: HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE,
        id,
    };
};
