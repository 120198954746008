import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_USERNAME_START,
    GET_USERNAME_SUCCESS,
    GET_USERNAME_ERROR,
    RESET_GET_USERNAME,
} from '@components/CreateUsers/Redux/Types/Types';
import {AppDispatch} from '@/config/store';

export const fetchGetUserNameStart = () => {
    return {
        type: GET_USERNAME_START,
    };
};

export const fetchGetUserNameSuccess = (result: AxiosResponse) => {
    return {
        type: GET_USERNAME_SUCCESS,
        payload: result,
    };
};

export const fetchGetUserNameError = () => {
    return {
        type: GET_USERNAME_ERROR,
    };
};

export function GetUserName(token: string, companyId: number, userName: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetUserNameStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUserName}?userName=${userName}&companyId=${companyId}`,
                {headers}
            );

            dispatch(fetchGetUserNameSuccess(response));
        } catch (error) {
            dispatch(fetchGetUserNameError());
        }
    };
}

export const resetGetUserName = () => {
    return {
        type: RESET_GET_USERNAME,
    };
};
