import {AxiosError, AxiosResponse} from 'axios';
import {
    ABOUT_EMPLOYMENT_ERROR,
    ABOUT_EMPLOYMENT_START,
    ABOUT_EMPLOYMENT_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataEmploymentLoans} from '@components/PersonalLoans/interfaces';

const initialState: IDataEmploymentLoans = {
    employmentLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

const CreateEmploymentSituation = (
    state: IDataEmploymentLoans = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataEmploymentLoans => {
    switch (action.type) {
        case ABOUT_EMPLOYMENT_START:
            return {...state, loading: true, error: false};
        case ABOUT_EMPLOYMENT_SUCCESS:
            return {...state, loading: false, error: false, employmentLoans: action.payload.data};
        case ABOUT_EMPLOYMENT_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default CreateEmploymentSituation;
