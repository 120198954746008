import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {GET_HANDS_SCHEMA} from '@ListClient/redux/types/Types';

export const getHandsSchemaStart = () => ({
    type: GET_HANDS_SCHEMA.GET_HANDS_START,
});

export const getHandsSchemaSuccess = (result: AxiosResponse) => {
    return {
        type: GET_HANDS_SCHEMA.GET_HANDS_SUCCESS,
        fingerData: result.data,
    };
};

export const getHandsSchemaError = () => ({
    type: GET_HANDS_SCHEMA.GET_HANDS_ERROR,
});

export const getFingers = (token: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(getHandsSchemaStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatFingers}`,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(getHandsSchemaSuccess(response.data));
        } catch (error) {
            dispatch(getHandsSchemaError());
        }
    };
};
