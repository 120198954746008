import {
    GET_ASSIGNMENT_TYPES,
    GET_ASSIGNMENT_TYPES_SUCCESS,
    GET_ASSIGNMENT_TYPES_ERROR,
} from '@TypesAuditAssignment/AssignmentTypesTypes';

const initialState = {
    assignmenttypes: [],
    error: false,
    loading: false,
};

const assignmenttypes = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_ASSIGNMENT_TYPES:
            return {
                ...state,
                loading: true,
            };
        case GET_ASSIGNMENT_TYPES_SUCCESS:
            return {
                ...state,
                assignmenttypes: action.value,
                loading: false,
            };
        case GET_ASSIGNMENT_TYPES_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
export default assignmenttypes;
