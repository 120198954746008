import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {removeSelectedUsers} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import DelUserIcon from '@images/DelUserIcon.svg';
import * as style from '@components/CreateUsers/Styles';
import {BUSINESS_LINES} from '@/components/MyPlan/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {IModalState} from '@components/LocationsBranch/interfaces';
import {DELUSER, HOLIDAYS} from '@components/LocationsBranch/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {saveAllIdUsers} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';

export const DelUserLocations = ({modal, setModal}: IModalState) => {
    const dispatch = useDispatch();
    const {saveAllUsers, idUser} = useSelector((state: RootState) => state.delUsersLoctations);

    const removeForUserId = () => {
        dispatch(removeSelectedUsers([idUser]));
        dispatch(saveAllIdUsers([...saveAllUsers, idUser]));
        setModal({...modal, locations: false});
    };

    return (
        <Modal
            modalState={modal.locations}
            changeModalState={() => setModal({...modal, locations: true})}
            titleModalState={false}
            {...style.modalContect}
            Width="31.25rem"
            HeightDialog="23.938rem"
            Padding="1.5rem"
            Gap="1.5rem"
            FlexDirMod="Column"
        >
            <Image src={DelUserIcon} />
            <ContainerFlex FlexDir="Column" Gap="1rem" Height="7.5rem">
                <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                    {DELUSER.WQ_REMOVE}
                </Text>
                <Text Color="#54575C" Align="start">
                    {BUSINESS_LINES.DOT}
                    <Text Color="#54575C" wSpace="normal" Align="start" MarginLeft="0.5rem">
                        {DELUSER.CHANGE_ACCESS}
                    </Text>
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="1.5rem" Height="40px">
                <ButtonGeneral
                    width="11rem"
                    text={HOLIDAYS.CANCEL}
                    clic={() => setModal({...modal, locations: false})}
                    color="#5A5AFF"
                    backGround="#FFFFFF"
                    hText="#5A5AFF"
                    height="40px"
                    cursor="pointer"
                    border="1px solid #5A5AFF"
                    hBackG="#E5E5FF"
                />
                <ButtonGeneral
                    width="11rem"
                    text={HOLIDAYS.REMOVE}
                    clic={() => removeForUserId()}
                    color="#5A5AFF"
                    backGround="#FFFFFF"
                    hText="#5A5AFF"
                    height="40px"
                    cursor="pointer"
                    border="1px solid #5A5AFF"
                    hBackG="#E5E5FF"
                />
            </ContainerFlex>
        </Modal>
    );
};
