import {DateTime} from 'luxon';
import React, {ChangeEvent, useEffect} from 'react';
import {RootState} from '@/config/store';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';

import AddImage from '@images/AddImage.svg';
import * as content from '@components/CreateUsers/Styles';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import {CREATEUSER} from '@components/CreateUsers/constants';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import {IDate, RegisterDataProps} from '@components/CreateUsers/interfaces';
import {personalData} from '@components/CreateUsers/Redux/Actions/saveUser';
import {ContainerFlex, Text, Input, ContainerCellGrid, Image} from '@Shopify/Ecommerce/styles';

export const PersonalData: React.FC<RegisterDataProps> = ({
    register,
    setValue,
    control,
    errors,
}) => {
    const dispatch = useDispatch();
    const {personal} = useSelector((state: RootState) => state.saveUser);
    const saveUser = useSelector((state: RootState) => state.saveUser);
    const [imageSrc, setImageSrc] = React.useState<string | null>(null);

    const imgAdd = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const getImgBase64 = new FileReader();
            getImgBase64.readAsDataURL(e.target.files[0] as Blob);
            getImgBase64.onload = function () {
                const string64 = String(getImgBase64.result);
                setImageSrc(string64);
                const result = String(string64).split(',')[1];
                setValue('imageProfile', result);
                dispatch(
                    personalData({
                        ...personal,
                        imageProfileBase64: string64,
                        imageProfile: result,
                    })
                );
            };
        }
    };

    const converDate = (e: IDate) => {
        if (e && e.c) {
            const date = DateTime.utc(e.c.year, e.c.month, e.c.day, e.c.hour, e.c.minute).toISO();
            dispatch(personalData({...personal, birthDate: date}));
        }
    };

    useEffect(() => {
        setImageSrc(personal.imageProfileBase64);
    }, [saveUser.credential.userType]);

    return (
        <ContainerFlex {...content.contentSection}>
            <Text {...content.subtitles} Padding="0">
                {CREATEUSER.PERSONALTITLE}
            </Text>
            <Text {...content.instructions}>{CREATEUSER.PERSONALDETAILS}</Text>
            <ContainerFlex Justify="start" Align="start" Self="stretch" Gap="1.5rem">
                <FileUpLoad
                    accept="image/*"
                    type="file"
                    onChange={imgAdd}
                    id="file"
                    error={errors.imageProfile?.message}
                />
                <FileUpLoadContent {...content.contentImg}>
                    {imageSrc ? (
                        <Image src={imageSrc} {...content.contentImg.imgStart} />
                    ) : (
                        <Image src={AddImage} {...content.contentImg.imgAdd} />
                    )}
                </FileUpLoadContent>

                <ContainerFlex Display="grid" GridColumns="repeat(3,1fr)" Gap="1rem">
                    <ContainerCellGrid {...content.personalData}>
                        <Text {...content.personalData.name}>{CREATEUSER.USERNAME}</Text>
                        <Input
                            {...content.personalData.input}
                            {...register('names', {
                                required: 'Ingrese datos',
                                pattern: {
                                    value: /^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
                                    message: 'Carácter inválido, ingrese solo letras.',
                                },
                            })}
                            onChange={(e) =>
                                dispatch(personalData({...personal, names: e.target.value}))
                            }
                            error={errors.names?.message}
                        />
                        {errors?.names?.message && (
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.names?.message}
                            </Text>
                        )}
                    </ContainerCellGrid>
                    <ContainerCellGrid {...content.personalData}>
                        <Text {...content.personalData.name}>{CREATEUSER.USERLASTNAME}</Text>
                        <Input
                            {...content.personalData.input}
                            {...register('firsLastNames', {
                                required: 'Ingrese datos',
                                pattern: {
                                    value: /^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
                                    message: 'Carácter inválido, ingrese solo letras.',
                                },
                            })}
                            onChange={(e) =>
                                dispatch(personalData({...personal, firsLastNames: e.target.value}))
                            }
                            error={errors.firsLastNames?.message}
                        />
                        {errors?.firsLastNames?.message && (
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.firsLastNames?.message}
                            </Text>
                        )}
                    </ContainerCellGrid>
                    <ContainerCellGrid {...content.personalData}>
                        <Text {...content.personalData.name}>{CREATEUSER.USERSECONDLASTNAME}</Text>
                        <Input
                            {...content.personalData.input}
                            {...register('secondLastNames', {
                                required: 'Ingrese datos',
                                pattern: {
                                    value: /^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/,
                                    message: 'Carácter inválido, ingrese solo letras.',
                                },
                            })}
                            onChange={(e) =>
                                dispatch(
                                    personalData({...personal, secondLastNames: e.target.value})
                                )
                            }
                            error={errors.secondLastNames?.message}
                        />
                        {errors?.secondLastNames?.message && (
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.secondLastNames?.message}
                            </Text>
                        )}
                    </ContainerCellGrid>
                    <ContainerFlex {...content.personalData}>
                        <Text {...content.personalData.name}>{CREATEUSER.USERBIRTHDAY}</Text>
                        <Controller
                            name={`birthDate`}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Campo obligatorio.',
                                },
                            }}
                            render={({field: {onChange, ref, value, ...field}}) => (
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <DatePickerCustom
                                        mask="__/__/____"
                                        {...field}
                                        inputFormat="dd/MM/yyyy"
                                        value={value}
                                        onChange={(e) => {
                                            if (onChange) onChange(e);
                                            converDate(e as IDate);
                                        }}
                                        maxDate={
                                            new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth(),
                                                new Date().getDate() + 0
                                            )
                                        }
                                        components={{OpenPickerIcon: DateIcon}}
                                        renderInput={({inputRef, inputProps, InputProps}) => (
                                            <CalendarFieldComponent
                                                error={errors.birthDate?.message ? true : false}
                                            >
                                                <InputCalendarField
                                                    ref={inputRef}
                                                    {...inputProps}
                                                />
                                                <CalendarIconContainer>
                                                    {InputProps?.endAdornment}
                                                </CalendarIconContainer>
                                            </CalendarFieldComponent>
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
