import {
    VALIDATE_EMAIL_FORMARKET_PLACE,
    VALIDATE_EMAIL_FORMARKET_PLACE_ERROR,
    VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS,
    RESET_EMAIL,
} from '@components/Login/MarketPlaceLogin/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDataLoginMarketVerify} from '@components/Login/interfaces';

const initialState: IDataLoginMarketVerify = {
    userData: null,
    loading: false,
    error: false,
    errorData: null,
    userType: '',
    userExists: true,
};

const getUsersMarketValidation = (
    state: IDataLoginMarketVerify = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IDataLoginMarketVerify => {
    switch (action.type) {
        case VALIDATE_EMAIL_FORMARKET_PLACE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case VALIDATE_EMAIL_FORMARKET_PLACE_SUCCESS:
            return {
                ...state,
                userData: action.payload.data.data,
                loading: false,
            };
        case VALIDATE_EMAIL_FORMARKET_PLACE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                userExists: false,
                errorData: action.error,
            };
        case RESET_EMAIL:
            return initialState;
        default:
            return state;
    }
};

export default getUsersMarketValidation;
