import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {PrivacityContainer} from '@/components/Login/Administrative/Privacity';
import {MAILVALIDATESUCCESS} from '@/components/Login/constants';
import {ImageLogo} from '@Login/style';
import Logo from '@images/logoAtaskate.svg';
import LogoFox from '@images/FoxMail.svg';
import {Highlight} from '@/components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';

export const MailPasswordSuccess = () => {
    const messageRecovery = useSelector(
        (state: RootState) => state.ResendEmailValidation.userData?.message
    );
    const messageEmail = useSelector(
        (state: RootState) => state.ResendEmailValidation.userData?.data.email
    );
    return (
        <ContainerFlex
            backG="#FFFFFF"
            FlexDir="column"
            Align="center"
            Width="31.25rem"
            Height="auto"
            Radius="1.5rem"
            Padding="1.5rem"
            Gap="16px"
            TextAlign="center"
        >
            <ImageLogo src={Logo} alt="logo" />
            <ImgLocation
                Width="7.875rem"
                Height="8.577rem"
                src={LogoFox}
                alt="logofox"
                Padding="1.5rem 0"
            />
            <Text FontWeight="500" Color="#54575C">
                {MAILVALIDATESUCCESS.TITLE}
            </Text>
            {messageRecovery && messageEmail && (
                <Highlight
                    text={messageRecovery}
                    highlight={messageEmail}
                    color="#54575C"
                    lheight="14px"
                />
            )}
            <PrivacityContainer />
        </ContainerFlex>
    );
};
