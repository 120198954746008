import {useForm} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    ICardData,
    ICardDataSubmit,
    IPaymentFieldsProps,
} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaPaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/schemaPaymentMethods';
import {DateTime} from 'luxon';
import {CardNumberField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CardNumberField';
import {CardOwnerField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CardOwnerField';
import {DatePickerVigency} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/DatePickerVigency';
import {CvvField} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/Fields/CvvField';
import {addNewPaymentMethodAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/PaymentMethodsActions';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {formatDate} from '@/hooks/currentFormatUtils';

export const PaymentFields: React.FC<IPaymentFieldsProps> = ({
    isAddingMethod,
    setIsAddingMethod,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<ICardDataSubmit>({
        resolver: yupResolver(schemaPaymentMethod),
        defaultValues: {
            cardNumber: '',
            cardOwner: '',
            vigency: '',
            cvv: '',
        },
    });

    const onSubmit = (data: ICardDataSubmit) => {
        const newCard: ICardData = {
            ...data,
            vigency: formatDate(
                DateTime.fromISO(data.vigency as string),
                PAYMENT_METHOD.DATE_FORMAT
            ),
        };
        dispatch(addNewPaymentMethodAction({token, paymentMethod: newCard}));
        setIsAddingMethod(!isAddingMethod);
    };
    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsAddingMethod(!isAddingMethod);
    };
    return (
        <ContainerForm onSubmit={handleSubmit(onSubmit)} Gap="1.5rem" FlexDir="column">
            <GridContainer Gap="1rem" Justify="start" GridColumns="2fr 2fr 1.5fr 1fr">
                <CardNumberField control={control} errors={errors} />
                <CardOwnerField register={register} errors={errors} />
                <DatePickerVigency errors={errors} control={control} setValue={setValue} />
                <CvvField control={control} errors={errors} />
            </GridContainer>
            <ContainerFlex Gap="1rem" Justify="end">
                <ButtonGenerals
                    SecondaryButton="#FFFFFF"
                    Width="148px"
                    FontSize="0.875rem"
                    FontWeight="700"
                    Height="32px"
                    onClick={onCancel}
                >
                    {PAYMENT_METHOD.CANCEL}
                </ButtonGenerals>
                <ButtonGenerals Width="148px" FontSize="0.875rem" Height="32px" type="submit">
                    {PAYMENT_METHOD.SAVE_CARD}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerForm>
    );
};
