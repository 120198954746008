import React, {useMemo} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_VALIDATION} from '@components/PersonalLoans/CreditStatus/constants';
import {ILoanDataValidationProps} from '@components/PersonalLoans/CreditStatus/interfaces';
import {TimerStatus} from '@components/PersonalLoans/CreditStatus/CreditValidation/TimerStatus';
import {ContentLevels} from '@Articles/style';
import {TextBold} from '@components/PersonalLoans/CreditStatus/CreditValidation/style';

export const LoanDataValidation = ({
    nameClient,
    time,
    emailClient,
    error,
}: ILoanDataValidationProps) => {
    const emailClientFormatted: string = useMemo(() => {
        if (emailClient) {
            const [user, domain] = emailClient.split('@');
            const userFormatted = user.substring(0, 3) + user.substring(3).replaceAll(/./gi, '*');
            return userFormatted + '@' + domain;
        }
        return '';
    }, [emailClient]);
    return (
        <>
            {error ? (
                <ContainerFlex Padding="1rem 2rem" Height="100px">
                    <Text>{CREDIT_VALIDATION.ERROR}</Text>
                </ContainerFlex>
            ) : (
                <ContainerFlex Padding="1rem 2rem" Justify="space-between">
                    <ContainerFlex
                        Gap="1rem"
                        FlexDir="column"
                        Align="start"
                        Justify="start"
                        Width="542px"
                    >
                        <Text Color="#0D166B" FontSize="1.75rem" FontWeight="700" wSpace="none">
                            {`${nameClient || ''}, estamos validando tu información`}
                        </Text>
                        <ContainerFlex FlexWrap="wrap" Justify="start" Height="max-content">
                            <ContentLevels FontSize="1.25rem" Color="#1D1E20" FontFamily="Nunito">
                                {CREDIT_VALIDATION.EMAIL.SEND_EMAIL}{' '}
                                <TextBold FontSize="1.25rem" Color="#1D1E20">
                                    {emailClientFormatted || ''}{' '}
                                </TextBold>
                                {CREDIT_VALIDATION.EMAIL.FINISH_VALIDATION}
                            </ContentLevels>
                        </ContainerFlex>
                    </ContainerFlex>
                    <TimerStatus timeResponse={time || '00:00:00'} />
                </ContainerFlex>
            )}
        </>
    );
};
