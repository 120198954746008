import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {productsLocations} from '@Locations/interfaces';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import {SelectFileVault} from '@AdminLocations/CreateLocation/style';
import {PensionById} from '@AdminLocations/redux/actions/LocationAction';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';
import {updateLocationProduct} from '@Locations/ManageLocations/redux/actions/ManageLocationsActions';

const ManagePension = () => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const dispatch: AppDispatch = useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            pension: '',
        },
    });

    const onSubmit = (data: {pension: string}) => {
        const values = {
            pledgeId: productsLoations.productId.data.pledgeId,
            containerTypeId: locations.locationsidcontainer,
            pensionId: Number(data.pension),
        };
        dispatch(updateLocationProduct(values, token));
    };

    const detailPension = (value: string) => {
        dispatch(PensionById(value, token));
    };

    const restored = productsLoations.productId.data.locationPledge.pensionId;
    useEffect(() => {
        if (locations.locationsidcontainer === 3) {
            if (restored) {
                detailPension(String(restored));
                reset({pension: String(restored)});
                return;
            } else {
                detailPension('');
            }
        }
    }, [locations.locationsidcontainer, restored]);

    return (
        <ContainerFlex Align="start" FlexDir="column">
            <ContainerForm
                Width="100%"
                FlexDir="column"
                Align="start"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Text Height="auto" FontSize="1.125rem" Padding="0 0 1.813rem 0">
                    Detalles de la ubicación en pensión
                </Text>
                <SelectFileVault Width="25rem">
                    <Text FontSize="0.75rem" required>
                        Pensión
                    </Text>
                    <select
                        {...register('pension', {
                            required: {
                                value: true,
                                message: 'Selecciona una Pensión',
                            },
                        })}
                        onChange={(e) => detailPension(e.target.value)}
                    >
                        <option selected disabled hidden value="">
                            Seleccione
                        </option>
                        {locations.gPension &&
                            locations.gPension.data?.map((item, index: number) => (
                                <option key={index} value={item.id}>
                                    {item.namePension}
                                </option>
                            ))}
                    </select>
                    <Text Color="red" FontSize="0.75rem">
                        {errors?.pension?.message}
                    </Text>
                </SelectFileVault>
                <ContainerFlex Height="160px" FlexDir="column">
                    {locations.loading ? (
                        <LoadingGeneral />
                    ) : (
                        locations.pensionById !== null && (
                            <>
                                <ContainerFlex Align="start" FlexDir="column">
                                    <Text FontWeight="300" FontSize="0.75rem" Color="#858997">
                                        Dirección
                                    </Text>
                                    <Text FontSize="0.875rem" wSpace="normal" Height="auto">
                                        {locations.pensionById?.address}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="space-between" Padding="0.5rem 0">
                                    <ContainerFlex Align="start" FlexDir="column">
                                        <Text FontWeight="300" FontSize="0.75rem" Color="#858997">
                                            Ciudad
                                        </Text>
                                        <Text FontSize="0.875rem">
                                            {locations.pensionById?.city}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Align="start" FlexDir="column">
                                        <Text FontWeight="300" FontSize="0.75rem" Color="#858997">
                                            Teléfono
                                        </Text>
                                        <Text FontSize="0.875rem">
                                            {locations.pensionById?.phone}
                                        </Text>
                                    </ContainerFlex>
                                </ContainerFlex>
                                <ContainerFlex Align="start" FlexDir="column">
                                    <Text FontWeight="300" FontSize="0.75rem" Color="#858997">
                                        Nombre del Contacto
                                    </Text>
                                    <Text FontSize="0.875rem">
                                        {locations.pensionById?.contactName}
                                    </Text>
                                </ContainerFlex>
                            </>
                        )
                    )}
                </ContainerFlex>
            </ContainerForm>
            <ContainerFlex Justify="center" Padding="1.938rem 0 0 0">
                <ButtonGeneral
                    width="10.438rem"
                    text="Guardar y Cerrar"
                    hColor="#414042"
                    clic={handleSubmit(onSubmit)}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ManagePension;
