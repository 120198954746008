import React from 'react';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

interface NewType {
    noFundicion: number;
    precioOro: number;
    lingotes: number;
    fecha: Date;
    precioDolar: number;
    gramo: number;
    onzasOroFino: number;
    total: number;
    costoTotalPaquetes: number;
    purezaPromedio: number;
    gramosOroFino: number;
    subtotal: number;
    porcentajeComision: number;
    comision: number;
    ganancia: number;
    estatus: string;
}
export const RowPurity = (props: NewType): JSX.Element => {
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const {
        noFundicion,
        costoTotalPaquetes,
        purezaPromedio,
        gramosOroFino,
        subtotal,
        porcentajeComision,
        comision,
        precioOro,
        lingotes,
        precioDolar,
        fecha,
        gramo,
        onzasOroFino,
        ganancia,
        total,
        estatus,
    } = props;
    return (
        <tr>
            <td className="text-center">
                <Link to={`/Pureza/${noFundicion}`}>
                    <span className="color-dark">{noFundicion}</span>
                </Link>{' '}
            </td>
            <td className="text-center">
                {DateTime.fromISO(fecha.toISOString()).toFormat('dd/MM/yyyy')}
            </td>
            <td className="text-center">{lingotes}</td>
            <td className="text-center">{formatterDolar.format(precioDolar)}</td>
            <td className="text-center">{formatterDolar.format(precioOro)}</td>
            <td className="text-center">{gramo} </td>
            <td className="text-center">{formatterDolar.format(costoTotalPaquetes)}</td>
            <td className="text-center">{purezaPromedio}</td>
            <td className="text-center">{gramosOroFino}</td>
            <td className="text-center">{onzasOroFino}</td>
            <td className="text-center">{formatterDolar.format(subtotal)}</td>
            <td className="text-center">{porcentajeComision} %</td>
            <td className="text-center">{formatterDolar.format(comision)}</td>
            <td className="text-center">{formatterDolar.format(total)}</td>
            <td className="text-center">{formatterDolar.format(ganancia)}</td>
            <td className="text-center">{estatus}</td>
            {estatus === 'Fundición' ? (
                <td className="text-center">
                    {' '}
                    <Link to={`PesoEscoriaOro/${noFundicion}`}>
                        <i className="material-icons ">paid</i>{' '}
                    </Link>{' '}
                </td>
            ) : (
                <></>
            )}

            {estatus === 'Por cobrar' ? (
                <td className="text-center">
                    {' '}
                    <Link to={`PurezaPago/${noFundicion}`}>
                        <i className="material-icons ">upload</i>{' '}
                    </Link>{' '}
                </td>
            ) : (
                <></>
            )}
        </tr>
    );
};
