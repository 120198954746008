import {
    GET_DATA_ROL_NAME,
    GET_DATA_ROL_NAME_SUCCESS,
    GET_DATA_ROL_NAME_ERROR,
} from '@Roles/AddRoles/Redux/Types';
import {TypeActionRolName} from '@Roles/AddRoles/interfaces';

const initialState = {
    loading: false,
    error: false,
    data: [],
};

const getDataRolName = (state = initialState, action: TypeActionRolName) => {
    switch (action.type) {
        case GET_DATA_ROL_NAME:
            return {...state, loading: true, error: false};
        case GET_DATA_ROL_NAME_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case GET_DATA_ROL_NAME_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default getDataRolName;
