import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ENABLEPAYMENT} from '@components/QuickPayment/Constants';
import PersonalCreditRow from '@components/QuickPayment/PersonalCreditRow';
export const EnablePayment = () => {
    const {personalCreditsSelected} = useSelector((state: RootState) => {
        return state.addFastPayment;
    });
    const {data, typeAccessSelected} = useSelector((state: RootState) => {
        return state.getFastPaymentPersonal;
    });

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FFF"
            Width="100%"
            Height="500px"
            Padding="24px"
            Radius="24px"
            Gap="46px"
            OverFlowY="auto"
        >
            <ContainerFlex
                Width="100%"
                Flex="0"
                FlexDir="column"
                Align="start"
                Justify="start"
                Gap="24px"
            >
                <ContainerFlex Align="start" Justify="start" Gap="4px">
                    <Text FontFamily="Nunito" FontSize="1.0rem" Color="#2A2C2F" FontWeight="700">
                        {`${typeAccessSelected.title}${typeAccessSelected.value}`}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Bb="1px solid #E8E9EA" PaddingB="8px">
                    <Text FontFamily="Nunito" FontSize="0.75rem" Color="#54575C" FontWeight="700">
                        {ENABLEPAYMENT.LOAN}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Width="100%"
                Height="100%"
                MaxH="450px"
                FlexDir="column"
                Align="start"
                Justify="start"
                OverFlowY="auto"
                Gap="16px"
            >
                {data?.map((personalCredit, index) => {
                    const {personalCreditId} = personalCredit;
                    const isPreselected =
                        (personalCreditsSelected.length === 0 && index === 0) ||
                        personalCreditsSelected.some(
                            ({personalCreditId: selectedId}) => selectedId === personalCreditId
                        );
                    return (
                        <PersonalCreditRow
                            key={personalCreditId}
                            isPreselected={isPreselected}
                            {...personalCredit}
                        />
                    );
                })}
            </ContainerFlex>
        </ContainerFlex>
    );
};
