export const AccessPay = {
    Justify: 'start',
    Align: 'start',
    backG: '#FFFFFF',
    FlexDir: 'column',
    Width: '500px',
    Height: '450px',
    Border: 'solid 1px #0D166B',
    Radius: '1.5rem',
    Padding: '1.5rem',
    Gap: '1.5rem',

    accesstype: (id: number) => {
        return id === 1 ? '8px 0px 0px 8px' : id === 3 ? '0px 8px 8px 0px' : '0px';
    },
    grid: {AlignContent: 'start', FlexDir: 'column', Height: '80px'},
    input: {
        RadiusTR: '50px',
        RadiusBR: '50px',
        Padding: '12px',
        FontSize: '0.875rem',
        placeholder: '',
    },
};
