import React from 'react';

import {CREATE_CLIENTS} from '@components/Login/constants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

export const CardsSubtitles = () => {
    return (
        <ContainerFlex FlexDir="column" Align="start" Width="auto" Padding="0 0 0 12px">
            <Text FontSize="1.25rem" Color="#FFFFFF" FontWeight="700" FontFamily="Nunito">
                {CREATE_CLIENTS.MANAGEMENT_ACCOUNT_FIRST}
            </Text>
            <Text FontSize="1.25rem" Color="#FFFFFF" FontWeight="700" FontFamily="Nunito">
                {CREATE_CLIENTS.MANAGEMENT_ACCOUNT_SECOND}
            </Text>
            <Text FontSize="1rem" Color="#FFFFFF" FontFamily="Nunito">
                {CREATE_CLIENTS.PAYMENT}
            </Text>
        </ContainerFlex>
    );
};
