export const COLORS = {
    golden: '#c7a328',
    green: '#7AC143',
    black: '#4A4A4A',
    gray1: '#6D6E71',
    gray2: '#A7A9AC',
    gray3: '#CBCED1',
    gray4: '#E4E7E9',
    gray5: '#F3F3F3',
    gray6: '#EFF0F2',
    LabelsCaptions: '#54575C',
};
