import React, {useState, useEffect, useCallback} from 'react';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider, CalendarPicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';
import {Controller} from 'react-hook-form';

import {months, SpanishLocaleDayText} from '@General/Atoms/CalendarDateSelection/constants';
import {CustomDatePickerProps} from '@General/Atoms/CalendarDateSelection/interfaces';
import {ContainerFlex} from '@EcommerceShopify/styles';
import {
    CalendarModal,
    CalendarSelect,
    CalendarWrapper,
} from '@General/Atoms/CalendarDateSelection/Styles/styles';
import DatePickerInput from '@General/Atoms/CalendarDateSelection/DatePickerInput';
import CalendarButtons from '@General/Atoms/CalendarDateSelection/CalendarButtons';

const years = Array.from({length: 10}, (_, i) => {
    const year = DateTime.now().year + i;
    return {value: year, label: year.toString()};
});

const CalendarDateSelection: React.FC<CustomDatePickerProps> = ({
    name,
    placeholder,
    defaultValue,
    format,
    control,
    error,
    isReset,
    clic,
    onSelect,
    limitDate,
}) => {
    const [selectedDate, setSelectedDate] = useState<DateTime | null>(
        defaultValue ? DateTime.fromJSDate(defaultValue) : null
    );
    const [initialDate, setInitialDate] = useState<DateTime | null>(
        defaultValue ? DateTime.fromJSDate(defaultValue) : null
    );
    const [selectedMonth, setSelectedMonth] = useState<number>(DateTime.now().month);
    const [selectedYear, setSelectedYear] = useState<number>(DateTime.now().year);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (defaultValue) {
            const date = DateTime.fromJSDate(defaultValue);
            setSelectedDate(date);
            setInitialDate(date);
            setSelectedMonth(date.month);
            setSelectedYear(date.year);
        }
    }, [defaultValue]);

    const handleMonthChange = useCallback(
        (selectedOption: {value: number; label: string} | null) => {
            if (selectedOption) {
                const newMonth = selectedOption.value;
                setSelectedMonth(newMonth);
                const updatedDate = DateTime.local(selectedYear, newMonth, 1);
                setSelectedDate(updatedDate);
            }
        },
        [selectedYear]
    );

    const handleYearChange = useCallback(
        (selectedOption: {value: number; label: string} | null) => {
            if (selectedOption) {
                const newYear = selectedOption.value;
                setSelectedYear(newYear);
                const updatedDate = DateTime.local(newYear, selectedMonth, 1);
                setSelectedDate(updatedDate);
            }
        },
        [selectedMonth]
    );

    const handleDateChange = useCallback((date: DateTime | null) => {
        setSelectedDate(date);
    }, []);

    const handleCancel = useCallback(() => {
        setSelectedDate(initialDate);
        setSelectedMonth(initialDate ? initialDate.month : DateTime.now().month);
        setSelectedYear(initialDate ? initialDate.year : DateTime.now().year);
        setAnchorEl(null);
    }, [initialDate]);
    const handleContinue = useCallback(
        (fieldOnChange: (date: Date | [Date, Date] | null) => void) => {
            fieldOnChange(selectedDate ? selectedDate.toJSDate() : null);
            if (onSelect) onSelect(selectedDate ? selectedDate.toJSDate() : null);
            setAnchorEl(null);
        },
        [selectedDate]
    );

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setInitialDate(selectedDate);
            setAnchorEl(event.currentTarget);
        },
        [selectedDate]
    );

    const handleClose = useCallback(() => {
        handleCancel();
    }, [handleCancel]);

    const dayOfWeekFormatter = (day: string): string => {
        const englishDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const index = englishDays.indexOf(day);
        if (index !== -1) {
            return SpanishLocaleDayText.weekDays[index];
        }
        return '';
    };

    const formatDate = (date: DateTime | null): string => {
        if (!date) return '';

        switch (format) {
            case 'MM/yyyy':
                return date.toFormat('MM/yyyy');
            case 'dd/MM':
                return date.toFormat('dd/MM');
            case 'dd/MM/yyyy':
            default:
                return date.toFormat('dd/MM/yyyy');
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-picker-popover' : undefined;
    const handleReset = useCallback(() => {
        setSelectedDate(initialDate);
        setSelectedMonth(initialDate ? initialDate.month : DateTime.now().month);
        setSelectedYear(initialDate ? initialDate.year : DateTime.now().year);
    }, [initialDate]);
    useEffect(() => {
        if (isReset) {
            handleCancel();
        }
    }, [isReset]);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <ContainerFlex
                        FlexDir="column"
                        Justify="start"
                        Align="flex-start"
                        Gap="4px"
                        Height="auto"
                        Flex="1 0 0"
                        onClick={clic}
                    >
                        <DatePickerInput
                            field={field}
                            placeholder={placeholder}
                            value={formatDate(selectedDate)}
                            error={error}
                            onClick={handleClick}
                        />
                        <CalendarModal
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <ContainerFlex
                                Radius="8px"
                                FlexDir="column"
                                Align="flex-start"
                                Border="1px solid #F4F5F5"
                                backG="#FFF"
                                BoxShadow="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                            >
                                <ContainerFlex
                                    Padding="8px"
                                    Align="flex-start"
                                    Gap="8px"
                                    Self="stretch"
                                >
                                    <CalendarSelect
                                        value={
                                            months.find((month) => month.value === selectedMonth) ||
                                            null
                                        }
                                        options={months}
                                        onChange={(option) =>
                                            handleMonthChange(
                                                option as {value: number; label: string}
                                            )
                                        }
                                    />
                                    <CalendarSelect
                                        value={
                                            years.find((year) => year.value === selectedYear) ||
                                            null
                                        }
                                        options={years}
                                        onChange={(option) =>
                                            handleYearChange(
                                                option as {value: number; label: string}
                                            )
                                        }
                                    />
                                </ContainerFlex>
                                <ContainerFlex Padding="8px" Align="flex-end">
                                    <CalendarWrapper className="test">
                                        <CalendarPicker
                                            dayOfWeekFormatter={dayOfWeekFormatter}
                                            date={selectedDate}
                                            onChange={handleDateChange}
                                            minDate={DateTime.local(selectedYear, selectedMonth, 1)}
                                            maxDate={
                                                limitDate
                                                    ? DateTime.now()
                                                    : DateTime.local(
                                                          selectedYear,
                                                          selectedMonth,
                                                          DateTime.local(
                                                              selectedYear,
                                                              selectedMonth
                                                          ).daysInMonth
                                                      )
                                            }
                                            className="calendar-options"
                                        />
                                    </CalendarWrapper>
                                </ContainerFlex>
                                <CalendarButtons
                                    onCancel={handleCancel}
                                    onContinue={() => handleContinue(field.onChange)}
                                    continueDisabled={!selectedDate}
                                    onReset={handleReset}
                                />
                            </ContainerFlex>
                        </CalendarModal>
                    </ContainerFlex>
                )}
            />
        </LocalizationProvider>
    );
};

export default CalendarDateSelection;
