import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';

import {
    CAT_BUSINESS,
    CAT_BUSINESS_SUCCESS,
    CAT_BUSINESS_ERROR,
    CAT_PLACES,
    CAT_PLACES_SUCCESS,
    CAT_PLACES_ERROR,
} from '@components/SignUp/Redux/types';
import {URL} from '@config/constants/index';

export const catBusinessInit = () => ({type: CAT_BUSINESS});
export const catBusinessError = () => ({type: CAT_BUSINESS_ERROR});
export const catBusinessSuccess = (result: AxiosResponse) => ({
    type: CAT_BUSINESS_SUCCESS,
    payload: result,
});

export const catTypeBusiness = () => {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catBusinessInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatTypeBusiness}`,
                {headers}
            );
            dispatch(catBusinessSuccess(response.data));
        } catch (error) {
            dispatch(catBusinessError());
        }
    };
};

export const catPlacesInit = () => ({type: CAT_PLACES});
export const catPlacesError = () => ({type: CAT_PLACES_ERROR});
export const catPlacesSuccess = (result: AxiosResponse) => ({
    type: CAT_PLACES_SUCCESS,
    payload: result,
});

export const catTypePlaces = () => {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(catPlacesInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatTypeSales}`,
                {headers}
            );
            dispatch(catPlacesSuccess(response.data));
        } catch (error) {
            dispatch(catPlacesError());
        }
    };
};
