import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@config/store';
import {CatalogValues, NEW_LEVEL, NEWCATALOG} from '@Articles/constants';
import FieldContent from '@FieldDinamics/FieldContent';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {CatalogIdProps, DynamicCharacteristic, ISearchAllCatalog} from '@Articles/interface';
import {shemaCreateLevel} from '@Articles/FieldDinamics/yupCreateLevel';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import Categorization from '@/components/Articles/FieldDinamics/Categorization';
import CaracteristicsDinamics from '@Articles/FieldDinamics/CaracteristicsDinamics';
import {GetAddLevel} from '@components/Articles/Redux/Actions/GetAddLevel';
import LabelCatalog from '@components/Articles/FieldDinamics/LabelCatalog';
import OtherActions from '@components/Articles/FieldDinamics/OtherActions';
import {NewFeature} from '@/components/Catalogue/NewFeature/NewFeature';
import CaracteristicsHereditary from '@components/Articles/FieldDinamics/CaracteristicsHereditary';
import {
    fetchRouteInfoReset,
    UpdateCatalogIdForm,
} from '@components/Articles/Redux/Actions/UpdateNewCatalogId';
import {
    fetchInfoListReset,
    GetDataCharacteristics,
} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {
    GetDataNewCatalogId,
    ListCatalogReset,
} from '@components/Articles/Redux/Actions/GetNewCatalogId';
import {DesactivateActivateModal} from '@/components/Catalogue/DesactivateActivateModal';
import {resetDynamicFeatures} from '@/components/Catalogue/Redux/Actions/SaveNewDynamicFeatureActions';
import {
    DataCharacteristicDynamic,
    DataCharacteristicsInheriteds,
} from '@components/Articles/Redux/interfaces';
import {IDynamicFeatures} from '@/components/Catalogue/interfaces';

const FieldDinamics = () => {
    const {LevelCatalog}: ISearchAllCatalog = useSelector(
        (state: RootState) => state.ArticleManagementSearch
    );
    const SizeLabelId = useSelector((state: RootState) => state.CreateNewCatalogId.labelOption);
    const catLevelId = LevelCatalog.breadcrumb[LevelCatalog.breadcrumb.length - 1]?.id;
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const DinamicFeature = useSelector((state: RootState) => {
        return state.SaveNewDynamicFeatureReducer;
    });
    const LevelsDataId = useSelector(
        (state: RootState) => state.UpdateNewCatalogId.labelData?.id as number
    );
    const ActionTypeForm = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.actionType as number
    );
    const LevelsDataRoute = useSelector(
        (state: RootState) => state.GetNewCatalogId.targetLevel as number
    );
    const UpdatelevelSelect = useSelector((state: RootState) => state.GetNewCatalogId.dataCatalog);

    const AddDinamicList = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.dataDinamic
    );
    const AddHereditaryList = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.dataHereditary
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [addSwitch, setAddSwitch] = useState(true);
    const [showActivateModal, setShowActivateModal] = useState(false);
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm<CatalogIdProps>({
        resolver: yupResolver(shemaCreateLevel(ActionTypeForm)),
        defaultValues: CatalogValues,
    });
    useEffect(() => {
        const {name = CatalogValues.name} = UpdatelevelSelect || CatalogValues;
        const {description = CatalogValues.description} = UpdatelevelSelect?.levelDescription
            ? {description: UpdatelevelSelect.levelDescription}
            : CatalogValues;
        reset({
            name,
            description,
        });
    }, [UpdatelevelSelect, reset]);

    const injectedArray: DynamicCharacteristic[] =
        AddHereditaryList?.map((item: DataCharacteristicsInheriteds) => ({
            idTypeCharacteristics: item.valueInherited[0].valueDetailInheritedId,
            nameCharacteristics: item.characteristicName,
            isRequerid: item.mandatory ? 1 : 0,
            includeLabelPrinter: item.includesLabel,
            details: [
                {
                    valueDescription: item.valueInherited[0]?.description || '',
                },
            ],
            isInherited: true,
        })) || [];
    const DynamicFormat: DataCharacteristicDynamic[] = DinamicFeature.map(
        (item: IDynamicFeatures) => ({
            dynamicCharacteristicsId: 0,
            idTypeCharacteristics: item.idTypeCharacteristics,
            characteristicName: item.nameCharacteristics,
            mandatory: item.isRequerid,
            includesLabel: item.includeLabelPrinter,
            enabled: item.enabled || true,
            valueDinamics: [
                {
                    valueDetailId: 0,
                    description: item.details.map((list) => list.valueDescription).join(','),
                    statusValue: true,
                },
            ],
        })
    );
    const MinusOne = 1;
    const onSubmit = (values: CatalogIdProps) => {
        if (ActionTypeForm === NEWCATALOG.CREATE) {
            const combinedDynamicCharacteristics = [
                ...injectedArray,
                ...DinamicFeature.map((item) => ({
                    idTypeCharacteristics: item.idTypeCharacteristics,
                    nameCharacteristics: item.nameCharacteristics,
                    isRequerid: item.isRequerid,
                    includeLabelPrinter: item.includeLabelPrinter,
                    details: item.details.map((detail) => ({
                        valueDescription: detail.valueDescription,
                    })),
                })),
            ];

            dispatch(
                GetAddLevel(token, {
                    ...values,
                    level: LevelsDataRoute - MinusOne,
                    catLevelId: catLevelId,
                    fileExtension: values.fileExtension || '',
                    fileContent: values.fileContent || '',
                    sizeLabelId: SizeLabelId,
                    status: addSwitch,
                    dynamicCharacteristic: combinedDynamicCharacteristics,
                })
            );
        }
        if (ActionTypeForm === NEWCATALOG.EDIT) {
            const combinedCharacteristicDynamic = [...DynamicFormat, ...(AddDinamicList ?? [])];
            dispatch(
                UpdateCatalogIdForm(token, {
                    ...values,
                    level: LevelsDataRoute - MinusOne,
                    levelId: LevelsDataId,
                    fileExtension: values.fileExtension || '',
                    fileContent: values.fileContent || '',
                    sizeLabelId: SizeLabelId,
                    othersAction: {status: addSwitch},
                    characteristicDynamic: combinedCharacteristicDynamic,
                    characteristicsInheriteds: AddHereditaryList,
                })
            );
        }
    };
    useEffect(() => {
        if (token && ActionTypeForm === NEWCATALOG.EDIT) {
            dispatch(
                GetDataCharacteristics(
                    {levelId: LevelsDataRoute - MinusOne, CatLevelId: LevelsDataId},
                    token
                )
            );
            dispatch(
                GetDataNewCatalogId(
                    {level: LevelsDataRoute - MinusOne, levelId: LevelsDataId},
                    token
                )
            );
        }
    }, []);
    const CancelCatalog = () => {
        dispatch(ListCatalogReset());
        dispatch(fetchRouteInfoReset());
        dispatch(fetchInfoListReset());
        dispatch(resetDynamicFeatures());
        navigate('/Management/Articles');
    };
    return (
        <>
            <ContainerForm
                Align="left"
                FlexDir="column"
                Gap="1rem"
                Padding="1rem 1.5rem"
                BackG="#FAFAFA"
                Justify="start"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" Padding="0.75rem 0">
                    {`${ActionTypeForm === NEWCATALOG.EDIT ? NEW_LEVEL.EDIT : NEW_LEVEL.LEVEL} ${
                        LevelsDataRoute || ''
                    }`}
                </Text>

                <FieldContent
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                />
                <Categorization />
                {LevelsDataRoute === 5 && <LabelCatalog dispatch={dispatch} />}
                <CaracteristicsHereditary />
                <CaracteristicsDinamics showModal={showModal} setShowModal={setShowModal} />
                <OtherActions
                    addSwitch={addSwitch}
                    setAddSwitch={setAddSwitch}
                    showActivateModal={showActivateModal}
                    setShowActivateModal={setShowActivateModal}
                    ActionTypeForm={ActionTypeForm}
                />
                <ContainerFlex Padding="0 0 1.5rem 0" Align="end">
                    <Text
                        Color="#FF6357"
                        Margin="0.5rem 1rem"
                        MarginB="0.5rem"
                        Cursor="pointer"
                        onClick={() => CancelCatalog()}
                    >
                        {NEW_LEVEL.CANCEL}
                    </Text>
                    <ButtonGeneral text="Guardar" />
                </ContainerFlex>
            </ContainerForm>
            {showModal && <NewFeature showModal={showModal} setShowModal={setShowModal} />}
            {showActivateModal && ActionTypeForm === NEWCATALOG.EDIT && (
                <DesactivateActivateModal
                    levelId={LevelsDataId}
                    level={LevelsDataRoute - 1}
                    isActivating={!addSwitch}
                    isLevel={LevelsDataRoute < 5}
                    addSwitch={addSwitch}
                    setAddSwitch={setAddSwitch}
                    showActivateModal={showActivateModal}
                    setShowActivateModal={setShowActivateModal}
                />
            )}
        </>
    );
};

export default FieldDinamics;
