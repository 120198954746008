import React, {useState} from 'react';

import styled from '@emotion/styled';
import {IconSvg} from '@/components/Quoter/styles';
import {RadioButtonProps} from '@components/Home/stylesInterfaces';

const StateA = () => (
    <IconSvg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.36665 12L2.56665 8.2L3.51665 7.25L6.36665 10.1L12.4833 3.98334L13.4333 4.93334L6.36665 12Z"
            fill="#F0F0FF"
        />
    </IconSvg>
);

const StateB = () => (
    <IconSvg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.36665 12L2.56665 8.2L3.51665 7.25L6.36665 10.1L12.4833 3.98334L13.4333 4.93334L6.36665 12Z"
            fill="#F0F0FF"
        />
    </IconSvg>
);

export const RadioButtonContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const HiddenRadioButton = styled.input`
    display: none;
`;

export const RadioButtonIndicator = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #acacff;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RadioContainer = styled.div`
    border-radius: 50px;
    height: 44px;
    border: 1px solid #acacff;
    aspect-ratio: 1/1;
    background: #f0f0ff;
    justify-content: center;
    align-items: center;
`;
export const Circle = styled.div`
    border-radius: 50px;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    border: 1px solid #acacff;
`;

export const RadioButton: React.FC<RadioButtonProps> = ({value, onChange}) => {
    const [selectedValue, setSelectedValue] = useState<string>(value);

    const handleSelectRadio = (value: string) => {
        setSelectedValue(value);
        onChange(value);
    };

    return (
        <RadioButtonContainer>
            <HiddenRadioButton
                type="radio"
                value={value}
                checked={selectedValue === value}
                onChange={(e: {target: {value: string}}) => handleSelectRadio(e.target.value)}
            />
            <RadioButtonIndicator>
                {selectedValue === value ? <StateA /> : <StateB />}
            </RadioButtonIndicator>
        </RadioButtonContainer>
    );
};

export default RadioButton;
