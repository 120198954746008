import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import {NewGroup} from '@components/LocationsBranch/NewGroup';
import {NewBranchGroup} from '@components/LocationsBranch/NewBranchGroup';
import {HolidaysAdd} from '@components/LocationsBranch/Holidays/HolidaysAdd';
import {AddressAdd} from '@components/LocationsBranch/AddAddress/AddressAdd';
import {getSchedule} from '@components/LocationsBranch/Redux/Action/GetSchedule';
import {getHolidays} from '@components/LocationsBranch/Redux/Action/GetHolidays';
import {
    catTypeBusiness,
    catTypePlaces,
} from '@components/SignUp/Redux/Actions/catPlaceAndBusinessAction';
import {NewStorage} from '@components/LocationsBranch/NewStorage';
import {getLevelBreadcrumb} from '@components/LocationsBranch/Redux/Action/GelLevels';
import {GetUserByLevel} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';

export const CreateNewGroup = () => {
    const dispatch: AppDispatch = useDispatch();
    const [showHolidaysModal, setShowHolidaysModal] = useState(false);
    const [modalAddress, setModalAddress] = useState(false);
    const {typeCreate, token, company, levels} = useSelector((state: RootState) => ({
        levels: state.getLevels.level,
        typeCreate: state.CreateNewGroup.typeSelected,
        token: state.getUsersValidation.userData?.token as string,
        company: state.getUsersValidation.userData?.enterpriceDetails?.[0]?.enterpriceId as number,
    }));

    const loadCataloges = () => {
        dispatch(catTypePlaces());
        dispatch(catTypeBusiness());
        if (levels && levels.levelId) {
            dispatch(getHolidays(token, levels.levelId));
            dispatch(getSchedule(token, levels.levelId));
            dispatch(GetUserByLevel(token, levels.levelId));
        }
    };

    useEffect(() => {
        dispatch(
            getLevelBreadcrumb(token, {companyId: company, typeCreation: typeCreate.locationId})
        );
    }, []);
    useEffect(() => loadCataloges(), [levels?.levelId]);

    return (
        <>
            {typeCreate.locationId === 2 ? (
                <NewBranchGroup
                    setShowHolidaysModal={setShowHolidaysModal}
                    setModalAddress={setModalAddress}
                />
            ) : typeCreate.locationId === 1 ? (
                <NewGroup setShowHolidaysModal={setShowHolidaysModal} />
            ) : (
                <NewStorage setModalAddress={setModalAddress} />
            )}

            {showHolidaysModal && (
                <HolidaysAdd
                    showHolidaysModal={showHolidaysModal}
                    setShowHolidaysModal={setShowHolidaysModal}
                />
            )}

            {modalAddress && (
                <AddressAdd modalAddress={modalAddress} setModalAddress={setModalAddress} />
            )}
        </>
    );
};
