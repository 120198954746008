import React, {useState, useEffect, useCallback} from 'react';
import {Table} from '@DeadLines/styles';
import {useForm, Controller} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import {ErrorMessage} from '@hookform/error-message';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ReactPaginate from 'react-paginate';
import {DateTime} from 'luxon';

import {HeadTableAuditAssignment} from '@AuditAssignment/HeadTableAuditAssignment';
import {ObtainSucursalIDAction} from '@ActionsAuditAssignment/AuditAssignmentAction';
import {getAuditors} from '@ActionsAuditAssignment/AuditorsAction';
import {getFoundry} from '@ActionsAuditAssignment/FoundryAction';
import {getAssignmentTypes} from '@ActionsAuditAssignment/AssignmentTypesAction';
import {
    getAssignmentById,
    putEditAssignmentById,
} from '@ActionsEditAssignment/EditAssignmentActions';
import {RootState, AppDispatch} from '@config/store';
import {RowSucursalHistoryTable} from '@AuditAssignment/RowSucursalHistoryTable';
import {HeadTableAuditAssignmentHistory} from '@AuditAssignment/HeadTableAuditAssignmentHistory';
const newLocal = new Date();
export function EditAssignment() {
    const {
        handleSubmit,
        register,
        control,
        reset,

        formState: {errors},
    } = useForm({
        defaultValues: {
            idAsignado: {value: '', label: ''},
            idFundidora: {value: '', label: ''},
            fecha: newLocal,
            idTipoAsignacion: {value: '', label: ''},
            motivo: '',
        },
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const params = useParams<string>();
    const dispatch: AppDispatch = useDispatch();
    const selectChange = useCallback(async () => {
        if (token) {
            const getAuditorSelect = () => dispatch(getAuditors(token));
            getAuditorSelect();
            const getFoundrySelect = () => dispatch(getFoundry(token));
            getFoundrySelect();
            const getAssignmentTypesSelect = () => dispatch(getAssignmentTypes(token));
            getAssignmentTypesSelect();
            await dispatch(getAssignmentById(String(params.id), token));
        }
    }, [dispatch, params.id, token]);
    useEffect(() => {
        if (token) {
            selectChange();
        }
    }, [params.id, selectChange, token]);
    const assignmentbyid = useSelector((state: RootState) => {
        return state.assignmentbyid.assignmentbyid;
    });
    const assignmentid = useSelector((state: RootState) => {
        return state.assignmentbyid.assignmentid;
    });
    const auditors = useSelector((state: RootState) => {
        return state.auditors.auditors;
    });
    const assignmenttypes = useSelector((state: RootState) => {
        return state.assignmenttypes.assignmenttypes;
    });
    const foundry = useSelector((state: RootState) => {
        return state.foundry.foundry;
    });
    const [selectedid, setSelectedid] = useState(assignmentbyid);
    const handlePageClickHistory = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(ObtainSucursalIDAction(sucursalhistory.sucursalID, 5, selected, token));
    };

    useEffect(() => {
        if (assignmentid) {
            reset({
                idAsignado: {
                    value: assignmentbyid.idAsignado,
                    label: assignmentbyid.asignado,
                },
                idFundidora: {
                    value: assignmentbyid.idFundidora,
                    label: assignmentbyid.fundidora,
                },
                fecha: new Date(assignmentbyid.fecha),
                motivo: assignmentbyid.motivo,
                idTipoAsignacion: {
                    value: assignmentbyid.idTipoAsignacion,
                    label: assignmentbyid.tipoAsignacion,
                },
            });
            setSelectedid({value: assignmentbyid.idFundidora, label: 'Fundidora'});
            dispatch(ObtainSucursalIDAction(assignmentid, 5, 0, token));
        } else {
            return;
        }
    }, [
        assignmentbyid.asignado,
        assignmentbyid.fecha,
        assignmentbyid.fundidora,
        assignmentbyid.idAsignado,
        assignmentbyid.idFundidora,
        assignmentbyid.idTipoAsignacion,
        assignmentbyid.motivo,
        assignmentbyid.tipoAsignacion,
        assignmentid,
        dispatch,
        reset,
        token,
    ]);
    const sucursalhistory = useSelector((state: RootState) => {
        return state.sucursal;
    });
    interface NewType {
        idAsignado: {value: string; label: string};
        idFundidora: {value: string; label: string};
        idTipoAsignacion: {value: string; label: string};
        fecha: Date;
        motivo: string;
    }

    const onSubmit = async (values: NewType) => {
        const jason = {
            idAsignacion: parseInt('1', 10),
            idSucursal: assignmentid,
            idTipoAsignacion: values.idTipoAsignacion.value,
            idAsignado: values.idAsignado.value,
            idAsignador: 5,
            motivo: values.motivo,
            idFundidora: values.idFundidora.value,
            fechaVisita: DateTime.fromJSDate(new Date(values.fecha)).toFormat('yyyy-MM-dd'),
            ahora: DateTime.local().minus({hours: 6}).toFormat('dd-MM-yyyy'),
        };

        await dispatch(putEditAssignmentById(jason, token));
    };

    return (
        <>
            <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <Link to={`/Fundiciones/ConsultaAuditoria`}>
                    <span className="child-breadcrumb">Consulta de Auditoría</span>
                </Link>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Edición de auditoria</span>
            </div>
            <div className="container-primary-edit">
                <span>Seleccione los nuevos datos para reprogramar la auditoría</span>
                <Table>
                    <HeadTableAuditAssignment />
                </Table>
                <form className="flex container-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="column table-container">
                        <label className="required">Tipo de Asignación</label>
                        <Controller
                            {...register('idTipoAsignacion')}
                            control={control}
                            rules={{required: true}}
                            render={({field}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione tipo asignación"
                                    options={assignmenttypes}
                                    className="input-form"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="required-text"
                            name="idTipoAsignacion"
                            message="Seleccione tipo de asignación"
                            as="p"
                        />
                    </div>
                    <div className="column table-container-right">
                        <label className="required">Auditor</label>
                        <Controller
                            {...register('idAsignado')}
                            control={control}
                            rules={{required: true}}
                            render={({field}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione auditor"
                                    options={auditors}
                                    className="input-form"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="required-text"
                            name="idAsignado"
                            message="Seleccione Auditor"
                            as="p"
                        />
                    </div>
                    <div className="column flex table-container">
                        <label className="required">Fecha visita</label>
                        <Controller
                            control={control}
                            name="fecha"
                            render={({field: {onChange, onBlur, value}}) => (
                                <ReactDatePicker
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    dateFormat="dd/MM/yyyy"
                                    showDisabledMonthNavigation
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="required-text"
                            name="fecha"
                            message="Seleccione Fecha visita"
                            as="p"
                        />
                    </div>
                    <div className="column table-container-right">
                        <label className="required">Fundidora</label>
                        <Controller
                            {...register('idFundidora')}
                            control={control}
                            rules={{required: true}}
                            render={({field}) => (
                                <ReactSelect
                                    isClearable
                                    placeholder="Seleccione fundidora"
                                    options={foundry}
                                    value={selectedid}
                                    className="input-form"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="required-text"
                            name="idFundidora"
                            message="Seleccione Fundidora"
                            as="p"
                        />
                    </div>
                    <div className="column flex table-container-right width-100">
                        <label className="required">Motivo por el cambio de auditoría</label>
                        <input
                            className="input-react-hook-form"
                            {...register('motivo', {
                                required: 'Ingrese motivo',
                            })}
                            id="motivo"
                            aria-invalid={errors.motivo ? 'true' : 'false'}
                        />
                        <ErrorMessage
                            errors={errors}
                            className="required-text"
                            name="motivo"
                            message="Seleccione motivo"
                            as="p"
                        />
                    </div>
                    <div className="flex width-100 mt-4 flex-end-button ">
                        <button className="btn-submit flex-end-button" type="submit">
                            Modificar Visita
                        </button>
                    </div>
                </form>

                <Table className="mt-table">
                    <HeadTableAuditAssignmentHistory />
                    <tbody>
                        {sucursalhistory.sucursalhistory.length === 0
                            ? 'No hay sucursales'
                            : sucursalhistory.sucursalhistory.data.map(
                                  (
                                      sucursales: JSX.IntrinsicAttributes & {
                                          sucursal: string;
                                          region: string;
                                          estado: string;
                                          fechaVisita: string;
                                          auditor: string;
                                      }
                                  ) => (
                                      <RowSucursalHistoryTable
                                          key={sucursales.fechaVisita}
                                          {...sucursales}
                                      />
                                  )
                              )}
                    </tbody>
                </Table>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={sucursalhistory.sucursalhistory.count / 5}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={sucursalhistory.sucursalhistory.pageIndex}
                    onPageChange={handlePageClickHistory}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </>
    );
}
