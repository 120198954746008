import {DateTime} from 'luxon';
import * as yup from 'yup';

const isValidDate = (dateString: string | undefined) => {
    if (dateString) {
        const date = DateTime.fromISO(dateString!);
        return date.isValid;
    }
    return false;
};
const isFutureYear = (dateString: string | undefined) => {
    if (dateString) {
        const date = DateTime.fromISO(dateString!);
        return date > DateTime.now();
    }
    return false;
};

export const schemaPaymentMethod = yup.object().shape({
    cardNumber: yup
        .string()
        .required('Campo obligatorio')
        .matches(/^\d{4}\d{4}\d{4}\d{4}$/, 'Ingresa un formato de tarjeta válido'),
    cardOwner: yup
        .string()
        .required('Campo obligatorio')
        .min(3, 'El nombre debe de tener mínimo 3 caracteres')
        .matches(
            /^[a-zA-Z.\sáéíóúñÁÉÍÓÚÑ\-']+$/,
            'Ingresa un nombre válido. Solo letras, puntos y espacios.'
        ),
    vigency: yup
        .string()
        .required('Campo obligatorio')
        .test('is-valid-date', 'Ingrese una fecha válida', isValidDate)
        .test('is-future-date', 'La fecha de caducidad debe de ser futura', isFutureYear),
    cvv: yup
        .string()
        .required('Campo obligatorio')
        .matches(/^[0-9]{3,4}$/, 'El CVV debe ser de 3 o 4 dígitos.'),
});
