import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import React, {useEffect} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import IconMenu from '@images/ICON_MENUPP.png';
import {GridContainer} from '@/components/CreditQuoter/styles';
import ataskateIcon from '@components/PersonalLoans/CreditStatus/icons/ataskateIcon.svg';
import {BusinessLoans} from '@components/PersonalLoans/CreditStatus/CreditValidation/BusinessLoans';
import {QuoteCallToAction} from '@components/PersonalLoans/CreditStatus/CreditValidation/QuoteCallToAction';
import {CREDIT_VALIDATION} from '@components/PersonalLoans/CreditStatus/constants';
import {AppDispatch, RootState} from '@/config/store';
import {getStatusApplicationValidate} from '@components/PersonalLoans/CreditStatus/Redux/Actions/GetStatusApplicationValidateActions';
import {Loading} from '@/components/General/Atoms/LoadingAtaskate/styles';
import {LoanDataValidation} from '@components/PersonalLoans/CreditStatus/CreditValidation/LoanDataValidation';

export const CreditValidation = () => {
    const {creditId} = useParams<{creditId?: string}>();
    const token: string = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token && creditId) {
            dispatch(getStatusApplicationValidate(token, Number(creditId)));
        }
    }, []);
    const timeResponse = useSelector(
        (state: RootState) => state.getStatusApplicationValidate?.data || {}
    );
    const timeLoadingReducer = useSelector(
        (state: RootState) => state.getStatusApplicationValidate
    );
    return (
        <>
            <ContainerFlex
                backG="#E5E5FF"
                Align="center"
                MinH="954px"
                FlexDir="column"
                Position="relative"
            >
                <ContainerFlex
                    FlexDir="column"
                    MinW="1162px"
                    Width="max-content"
                    Height="max-content"
                    Gap="1rem"
                >
                    <ContainerFlex
                        backG="#FFF"
                        Padding="1.5rem"
                        Gap="1rem"
                        Radius="1.5rem"
                        BoxS="0px 4px 8px 0px rgba(228, 204, 255, 0.15)"
                        FlexDir="column"
                        Height="max-content"
                    >
                        <ContainerFlex FlexDir="column" Gap="0.5rem">
                            <ContainerFlex Justify="space-between">
                                <Text
                                    FontSize="1.5rem"
                                    Color="#1D1E20"
                                    FontWeight="700"
                                    Height="33px"
                                    Width="513px"
                                >
                                    {CREDIT_VALIDATION.TITLE}
                                </Text>
                                <Image src={IconMenu} width="147px" height="37px" />
                            </ContainerFlex>
                            <ContainerFlex Height="1px" backG="#E8E9EA">
                                {''}
                            </ContainerFlex>
                        </ContainerFlex>
                        {timeLoadingReducer.loading ? (
                            <ContainerFlex Height="250px">
                                <Loading />
                            </ContainerFlex>
                        ) : (
                            <LoanDataValidation
                                nameClient={timeResponse.nameClient}
                                time={timeResponse.time}
                                emailClient={timeResponse.emailClient}
                                error={timeLoadingReducer.error}
                            />
                        )}
                    </ContainerFlex>

                    <GridContainer GridColumns="740px 403px" MinW="1162px" Gap="1rem" Align="start">
                        <BusinessLoans />
                        <QuoteCallToAction />
                    </GridContainer>
                </ContainerFlex>
                <ContainerFlex
                    Justify="end"
                    Align="end"
                    Position="absolute"
                    PositionTop=""
                    PositionBottom="1rem"
                    PositionRight="1rem"
                    Height="22.012px"
                >
                    <Text FontSize="0.75rem" FontWeight="700" Color="#54575C" MarginRight="0.5rem">
                        {CREDIT_VALIDATION.LOGO.POWER}
                    </Text>
                    <Image src={ataskateIcon} />
                    <Text
                        Padding="0.057rem 0.344rem"
                        Opacity="0.9"
                        Color="#0D166B"
                        BorderRadius="4.586rem"
                        BGColor="#D8FF03"
                        FontSize="0.688rem"
                        FontWeight="700"
                        MarginLeft="-0.25rem"
                    >
                        {CREDIT_VALIDATION.LOGO.CREDITS}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
