import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FinanceRecord, TITLES, financeCards} from '@CashFlow/constantsAtaskateFlow';
import {UsersFlowTable} from '@CashFlow/UsersFlowTable';
import {TransactionTable} from '@CashFlow/TransactionTable';
import {formatNum} from '@/hooks/currentFormatUtils';
import {DetailsCards} from '@CashFlow/DetailsCard';
import Expenses from '@CashFlow/Expenses/Expenses';
import {PendingTransaction} from '@components/PendingTransaction/PendingTransaction';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {getDetails} from '@components/CashFlow/Redux/Actions/GetDetails';
import {defaultFinanceCardsData} from '@components/CashFlow/constants';
import {selectTableAction} from '@components/CashFlow/Redux/Actions/SelectTableAction';
import {ButtonMenuActions} from '@/components/CashFlowActions/ButtonMenuActions';

export const CashFlow = () => {
    const dispatch: AppDispatch = useDispatch();
    const initial = {
        typeEffective: '',
        soldOut: 0,
        details: {
            total: 0,
            totalHeader: '',
            text: '',
            amounts: [],
        },
    };
    const [showDetail, setShowDetail] = useState(false);
    const [AllDetail, setAllDetail] = useState<FinanceRecord>(initial);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );
    const currentBranch = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId ?? null,
        shallowEqual
    );
    const ShowDetail = (data: FinanceRecord) => {
        setShowDetail(true);
        setAllDetail(data);
    };

    const data =
        useSelector(
            (state: RootState) => state.getCashflowDetails?.cashflowDetails,
            shallowEqual
        ) || defaultFinanceCardsData;
    useEffect(() => {
        if (currentBranch) {
            dispatch(getDetails(currentBranch, token));
        }
    }, [currentBranch, data.detailsCashNetIncome.totalExpenses]);
    const financeData = financeCards(data);

    const selectTable = useSelector((state: RootState) => state.selectTableReducer.tableNumber);

    return (
        <ContainerFlex
            Padding="16px"
            Gap="32px"
            Justify="start"
            Align=""
            FlexDir="column"
            backG="#FFFFFF"
        >
            {showDetail && <DetailsCards data={AllDetail} setClose={setShowDetail} />}
            <ContainerFlex Justify="start" Gap="8px" Height="51px">
                <ContainerFlex Align="start" Justify="start" FlexDir="column">
                    <Text FontSize="1.5rem" FontWeight="500" FontFamily="Nunito" Color="#2A2C2F">
                        {TITLES.CASH_FLOW}
                    </Text>
                    <Text FontSize="1rem" FontWeight="500" FontFamily="Nunito" Color="#54575C">
                        {data.date}
                    </Text>
                </ContainerFlex>
                <ButtonMenuActions />
            </ContainerFlex>
            {
                <ContainerFlex ColumnGap="24px" Height="105px">
                    <ContainerFlex
                        ColumnGap="24px"
                        Radius="16px"
                        Border="1px solid #E8E9EA"
                        Padding="16px"
                    >
                        {financeData.boxSale.map((data, index) => {
                            return (
                                <ContainerFlex
                                    FlexDir="column"
                                    Gap="8px"
                                    Br={index === 0 ? '1px solid #E8E9EA' : 'none'}
                                    key={index}
                                >
                                    <Text
                                        FontSize="1.5rem"
                                        FontWeight="700"
                                        Color="#2A2C2F"
                                        wSpace=""
                                    >
                                        {formatNum({
                                            number: data.soldOut,
                                            fractionDigits: 1,
                                            money: false,
                                        })}
                                    </Text>
                                    <Text FontSize="1rem" Color="#54575C">
                                        {data.typeEffective}
                                    </Text>
                                    <Text
                                        FontSize="0.875rem"
                                        FontWeight="700"
                                        Color="#5A5AFF"
                                        Cursor="pointer"
                                        onClick={() => {
                                            ShowDetail(data);
                                        }}
                                    >
                                        {TITLES.VIEW}
                                    </Text>
                                </ContainerFlex>
                            );
                        })}
                    </ContainerFlex>
                    <ContainerFlex
                        ColumnGap="24px"
                        Radius="16px"
                        Border="1px solid #E8E9EA"
                        Padding="16px"
                    >
                        {financeData?.inOutSale.map((data, index) => {
                            return (
                                <ContainerFlex
                                    FlexDir="column"
                                    Gap="8px"
                                    Br={index <= 1 ? '1px solid #E8E9EA' : 'none'}
                                    key={index}
                                >
                                    <Text
                                        FontSize="1.5rem"
                                        FontWeight="700"
                                        Color="#2A2C2F"
                                        wSpace=""
                                    >
                                        {formatNum({
                                            number: data.soldOut,
                                            fractionDigits: 2,
                                            money: false,
                                        })}
                                    </Text>
                                    <Text FontSize="1rem" Color="#54575C">
                                        {data.typeEffective}
                                    </Text>
                                    <Text
                                        FontSize="0.875rem"
                                        FontWeight="700"
                                        Color="#5A5AFF"
                                        Cursor="pointer"
                                        onClick={() => {
                                            ShowDetail(data);
                                        }}
                                    >
                                        {TITLES.VIEW}
                                    </Text>
                                </ContainerFlex>
                            );
                        })}
                    </ContainerFlex>
                </ContainerFlex>
            }
            <ContainerFlex Justify="start" Width="50%" Height="40px">
                <ContainerFlex
                    Cursor="pointer"
                    Bb={selectTable === 1 ? '1px solid #5A5AFF' : '1px solid #D4D6D8'}
                    onClick={() => dispatch(selectTableAction(1))}
                >
                    <Text FontSize="1rem" Color={selectTable === 1 ? '#5A5AFF' : '#54575C'}>
                        {TITLES.USERS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Bb={selectTable === 2 ? '1px solid #5A5AFF' : '1px solid #D4D6D8'}
                    onClick={() => dispatch(selectTableAction(2))}
                    Cursor="pointer"
                >
                    <Text
                        Cursor="pointer"
                        FontSize="1rem"
                        Color={selectTable === 2 ? '#5A5AFF' : '#54575C'}
                    >
                        {TITLES.TANSACCITONS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Bb={selectTable === 3 ? '1px solid #5A5AFF' : '1px solid #D4D6D8'}
                    onClick={() => dispatch(selectTableAction(3))}
                    Cursor="pointer"
                >
                    <Text
                        Cursor="pointer"
                        FontSize="1rem"
                        Color={selectTable === 3 ? '#5A5AFF' : '#54575C'}
                    >
                        {TITLES.PAYS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Bb={selectTable === 4 ? '1px solid #5A5AFF' : '1px solid #D4D6D8'}
                    onClick={() => dispatch(selectTableAction(4))}
                    Cursor="pointer"
                >
                    <Text
                        Cursor="pointer"
                        FontSize="1rem"
                        Color={selectTable === 4 ? '#5A5AFF' : '#54575C'}
                    >
                        {TITLES.PENDINGS}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {selectTable === 1 && <UsersFlowTable />}
            {selectTable === 2 && <TransactionTable />}
            {selectTable === 3 && <Expenses />}
            {selectTable === 4 && <PendingTransaction />}
        </ContainerFlex>
    );
};
