import {
    INITIAL_DATE,
    END_DATE,
    LASTED_DATE,
    STATUS_FILTER,
    SOL_STATE_LABEL,
    EXCESS_STATE_LABEL,
    FILTERREQUEST,
    EXCESSFILTER,
    FILTERS,
    RESET_TABLE_FILTERS,
    SAVED_REQUEST_ID_FILTER,
    SAVED_REQUEST_EXCESS_FILTER,
    ALL_EXCESS,
    ALL_REQUEST,
} from '@ReduxsCashFlow/Types/tableTypes';
const initialState = {
    initialDate: '',
    endDate: '',
    lastedDate: '7',
    requestLabel: '',
    excessLabel: 'Todos',
    filterRequest: '',
    excessFilter: '',
    filters: true,
    statusFilter: [],
    allExcess: true,
    allRequest: true,
    requestIdColecction: [],
    excessIdColecction: [],
};
export interface ISavedFilters {
    initialDate: string;
    endDate: string;
    lastedDate: string;
    requestLabel: string;
    excessLabel: string;
    filterRequest: string;
    excessFilter: string;
    filters: boolean;
    requestIdColecction: string[];
    excessIdColecction: string[];
    statusFilter: [];
    allRequest: boolean;
    allExcess: boolean;
}

const filtersCash = (state = initialState, action: {type: string; payload: ISavedFilters}) => {
    switch (action.type) {
        case FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case FILTERREQUEST:
            return {
                ...state,
                filterRequest: action.payload,
            };
        case EXCESSFILTER:
            return {
                ...state,
                excessFilter: action.payload,
            };
        case SOL_STATE_LABEL:
            return {
                ...state,
                requestLabel: action.payload,
            };
        case EXCESS_STATE_LABEL:
            return {
                ...state,
                excessLabel: action.payload,
            };
        case INITIAL_DATE:
            return {
                ...state,
                initialDate: action.payload,
            };
        case END_DATE:
            return {
                ...state,
                endDate: action.payload,
            };
        case LASTED_DATE:
            return {
                ...state,
                lastedDate: action.payload,
            };
        case STATUS_FILTER:
            return {
                ...state,
                statusFilter: action.payload,
            };
        case SAVED_REQUEST_ID_FILTER:
            return {
                ...state,
                requestIdColecction: action.payload,
            };
        case SAVED_REQUEST_EXCESS_FILTER:
            return {
                ...state,
                excessIdColecction: action.payload,
            };
        case ALL_EXCESS:
            return {
                ...state,
                allExcess: action.payload,
            };
        case ALL_REQUEST:
            return {
                ...state,
                allRequest: action.payload,
            };
        case RESET_TABLE_FILTERS:
            return initialState;
        default:
            return state;
    }
};
export default filtersCash;
