import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {CREDIT_VALIDATION} from '@components/PersonalLoans/CreditStatus/constants';
import multiApoyoLogo from '@components/PersonalLoans/CreditStatus/icons/multiapoyo-logo.svg';
import kueskiLogo from '@components/PersonalLoans/CreditStatus/images/kueski-logo.png';
import baubapLogo from '@components/PersonalLoans/CreditStatus/images/baubap-logo.png';
import {BrandsRow} from '@components/PersonalLoans/CreditStatus/CreditValidation/BrandsRow';

export const BusinessLoans = () => {
    const brandsTableData = [
        {
            brandIconDetails: {
                icon: multiApoyoLogo,
                width: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.LOGO_SIZE.WIDTH,
                height: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.LOGO_SIZE.HEIGHT,
            },
            callToAction: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.CALL_TO_ACTION,
            details: [
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.DETAILS.DESC_1},
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.DETAILS.DESC_2},
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.MULTIAPOYO.DETAILS.DESC_3},
            ],
        },
        {
            brandIconDetails: {
                icon: kueskiLogo,
                width: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.KUESKI.LOGO_SIZE.WIDTH,
                height: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.KUESKI.LOGO_SIZE.HEIGHT,
            },
            callToAction: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.KUESKI.CALL_TO_ACTION,
            details: [
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.KUESKI.DETAILS.DESC_1},
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.KUESKI.DETAILS.DESC_2},
            ],
        },
        {
            brandIconDetails: {
                icon: baubapLogo,
                width: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.BAUBAP.LOGO_SIZE.WIDTH,
                height: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.BAUBAP.LOGO_SIZE.HEIGHT,
            },
            callToAction: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.BAUBAP.CALL_TO_ACTION,
            details: [
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.BAUBAP.DETAILS.DESC_1},
                {description: CREDIT_VALIDATION.TABLE_CONTENT_BUSINESS.BAUBAP.DETAILS.DESC_2},
            ],
        },
    ];

    return (
        <>
            <ContainerFlex
                Radius="1.5rem"
                Gap="1.5rem"
                backG="#FFF"
                Padding="1.5rem 2rem"
                Height="498px"
                FlexDir="column"
            >
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="max-content">
                    <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                        {CREDIT_VALIDATION.BUSINESS_LOANS.TITLE}
                    </Text>
                    <Text Color="#1D1E20">{CREDIT_VALIDATION.BUSINESS_LOANS.DESCRIPTION}</Text>
                </ContainerFlex>

                <ContainerFlex Gap="1rem" Justify="start" Align="start" FlexDir="column">
                    <GridContainer
                        Gap="1rem"
                        GridColumns="225.171px 225px"
                        Justify="start"
                        Bb="1px solid #E8E9EA"
                        Height="37.32px"
                    >
                        <Text Color="#2A2C2F" FontWeight="700">
                            {CREDIT_VALIDATION.BUSINESS_LOANS.TABLE_HEADERS.BUSINESS}
                        </Text>
                        <Text Color="#2A2C2F" FontWeight="700">
                            {CREDIT_VALIDATION.BUSINESS_LOANS.TABLE_HEADERS.DETAILS}
                        </Text>
                    </GridContainer>
                    {brandsTableData.map((brand, index) => (
                        <BrandsRow
                            key={index}
                            {...brand}
                            index={index}
                            brandsTableLength={brandsTableData.length}
                        />
                    ))}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
