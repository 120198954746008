import styled from '@emotion/styled';
import {
    IPropsElipsedText,
    IPropsGridMenu,
    IPropsMenu,
    IPropsOption,
    IPropsStyledSelect,
    IPropsTextPlacelHolder,
} from '@Roles/interfacesStyles';
import Switch from '@mui/material/Switch';

export const GridMenu = styled.div<IPropsGridMenu>`
    grid-template-columns: 1fr 1fr;
    padding: ${(props) => props.Padding};
    border: 1px solid #f3f3f3;
    width: 100%;
    display: grid;
    position: relative;
    height: ${(props) => props.Height};
`;

export const GreenSwitch = styled(Switch)`
    color: #35cf44;
`;

export const MenuIcons = styled.img<IPropsMenu>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
`;

MenuIcons.defaultProps = {
    Width: '24px',
    Height: '16px',
};

export const ImageModalIcon = styled.img`
    width: 185.1px;
    height: 120px;
`;
export const ElipsedText = styled.span<IPropsElipsedText>`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: ${(props) => props.TextAlign};
    width: ${(props) => props.Width};
    align-self: ${(props) => props.Self};
    font-style: ${(props) => props.FontStyle};
    padding: ${(props) => props.Padding};
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    line-height: ${(props) => props.Height};
    color: ${(props) => props.Color};
    margin: ${(props) => props.Margin};
    text-decoration: ${(props) => props.TextDecoration};
    font-family: ${(props) => props.FontFamily};
    cursor: ${(props) => props.Cursor};
`;
ElipsedText.defaultProps = {
    Width: '200px',
    TextDecoration: 'none',
    Height: '1.5rem',
    Cursor: 'auto',
};

export const OptionPicker = styled.div<IPropsStyledSelect>`
    height: 40px;
    width: ${(props) => props.Width};
    position: relative;
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    box-shadow: ${(props) => props.BoxShadow};
    align-items: center;
    border-radius: 4px;

    display: grid;
    padding: 0 12px;
    background-color: ${(props) => props.BackGround};
    justify-content: space-between;
    grid-template-columns: 92% 8%;
    place-items: center;
    box-sizing: border-box;
    gap: 4px;
    cursor: ${(props) => props.Cursor};
    &:hover,
    &:hover > svg > path {
        border-color: ${(props) => props.HColor};
        box-shadow: ${(props) => props.HShadow};
        fill: ${(props) => props.HFill};
    }
    svg {
        transition: rotate 0.15s ease-in-out;
        rotate: ${(props) => props.Rotate};
    }
    svg > path {
        fill: ${(props) => props.Fill};
    }
`;

OptionPicker.defaultProps = {
    Width: '100%',
    Cursor: 'pointer',
    HColor: '#35cf44',
    HShadow: '0px 4px 4px rgba(53, 207, 68, 0.2)',
    HFill: '#35cf44',
};

export const TextPlacelHolder = styled.span<IPropsTextPlacelHolder>`
    font-size: 0.875rem;
    width: 100%;
    color: ${(props) => props.Color};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
`;

export const ListOptions = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    top: 37px;
    border: 1px solid #f3f3f3;
    background-color: #fff;
    z-index: 2;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;

export const Option = styled.div<IPropsOption>`
    height: auto;
    min-height: 40px;
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 0 16px;
    align-items: center;
    box-sizing: border-box;
    color: #414042;
    font-size: 0.875rem;
    cursor: pointer;
    background: ${(props) => props.BackGround};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    gap: 8px;
    box-sizing: border-box;
    &:hover {
        background-color: #f3f3f3;
    }
`;

export const IconContainer = styled.div`
    cursor: pointer;
    width: '50%';
`;
