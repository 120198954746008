import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import useInitInheritGroups from '@/hooks/useInitInheritGroups';
import plusIcon from '@components/Branches/Images/PlusIcon.svg';
import {IUserGroup} from '@components/LocationsBranch/interfaces';
import {InheritFrom} from '@/components/LocationsBranch/InheritFrom';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import * as style from '@components/LocationsBranch/stylesLocations';
import UsersGroupList from '@components/LocationsBranch/UsersGroupList';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';
import {updateUsersPagination} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import {NEWGROUP, INHERIT_TYPE, UserListPageSize} from '@components/LocationsBranch/constants';

export const UserGroup = ({modal, setModal, setValue}: IUserGroup) => {
    const dispatch = useDispatch();
    const usersList = useSelector((state: RootState) => state.GetUserByLevelId);
    const inheritType = INHERIT_TYPE.USER;
    const selectedLevel = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === inheritType
    )?.selectedLevel;

    useInitInheritGroups(inheritType, 3);

    const handlePageChange = (newPage: number) => {
        dispatch(updateUsersPagination(newPage));
    };
    return (
        <ContainerFlex {...style.contentGral}>
            <ContainerFlex Justify="space-between" Align="center" Self="stretch">
                <Text {...style.contentGralTitle}>
                    {NEWGROUP.USERASSIGNMENT}
                    <Text {...style.contentGralTitleOp} MarginLeft="0.5rem" wSpace="pre">
                        {NEWGROUP.OPTIONAL}
                    </Text>
                </Text>
                <InheritFrom
                    inheriterName={selectedLevel?.levelName as string}
                    type={inheritType}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.5rem">
                <UsersGroupList modal={modal} setModal={setModal} setValue={setValue} />
                <ContainerFlex
                    Justify="start"
                    Gap="0.5rem"
                    Padding="0.5rem"
                    Width="fit-content"
                    Cursor="pointer"
                >
                    <Image src={plusIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                    <Text
                        Color="#5A5AFF"
                        FontWeight="700"
                        Cursor="pointer"
                        onClick={() => setModal({...modal, addUser: true})}
                    >
                        {NEWGROUP.ADDUSER}
                    </Text>
                </ContainerFlex>
                {usersList.allUserData.length > 0 && (
                    <ContainerFlex Gap="0.5rem" Height="2.5rem">
                        <Pagination
                            pageNumber={usersList.actualPage ?? 0}
                            totalPages={usersList.companyUsers.totalPages ?? 1}
                            totalItems={usersList.companyUsers.totalItems ?? 0}
                            pageSize={UserListPageSize ?? 0}
                            onPageChange={
                                usersList.companyUsers.totalItems > 0 ? handlePageChange : () => {}
                            }
                        />
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
