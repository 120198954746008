import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {ButtonPager} from '@components/Users/styles';
import {IApplicationsPaginationProps} from '@components/CreditsAndPawns/interfaces';
import firstPageIcon from '@components/CreditsAndPawns/icons/firstPageIcon.svg';
import lastPageIcon from '@components/CreditsAndPawns/icons/lastPageIcon.svg';
import arrowSmallLeft from '@components/CreditsAndPawns/icons/arrowSmallLeft.svg';
import arrowSmallRight from '@components/CreditsAndPawns/icons/arrowSmallRight.svg';

export const ApplicationsPagination = ({
    pageNumber,
    setPageNumber,
    quantityPerPage,
    totalPages,
    totalItems,
}: IApplicationsPaginationProps) => {
    const [showQuantityLabel, setShowQuantityLabel] = useState<string>('1 - 1 de 1');

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
    };

    const showFirstPage = () => {
        setPageNumber(1);
    };

    const previousPage = () => {
        setPageNumber(pageNumber - 1);
    };

    const showLastPage = () => {
        setPageNumber(totalPages);
    };

    useEffect(() => {
        const start = pageNumber === 1 ? 1 : pageNumber * quantityPerPage - quantityPerPage + 1;
        const end = pageNumber === totalPages ? totalItems : pageNumber * quantityPerPage;
        setShowQuantityLabel(`${start} - ${end} de ${totalItems}`);
    }, [pageNumber, totalPages, totalItems]);

    return (
        <ContainerFlex Gap="0.5rem" Height="48px">
            <ButtonPager onClick={showFirstPage} disabled={pageNumber === 1}>
                <Image Width="16px" Height="16px" src={firstPageIcon} />
            </ButtonPager>
            <ButtonPager onClick={previousPage} disabled={pageNumber === 1}>
                <Image Width="16px" Height="16px" src={arrowSmallLeft} />
            </ButtonPager>
            <Text Color="##2A2C2F">{showQuantityLabel}</Text>
            <ButtonPager onClick={nextPage} disabled={pageNumber === totalPages}>
                <Image Width="16px" Height="16px" src={arrowSmallRight} />
            </ButtonPager>
            <ButtonPager onClick={showLastPage} disabled={pageNumber === totalPages}>
                <Image Width="16px" Height="16px" src={lastPageIcon} />
            </ButtonPager>
        </ContainerFlex>
    );
};
