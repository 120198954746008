import {
    UPDATE_TAXDATA_START,
    UPDATE_TAXDATA_SUCCESS,
    UPDATE_TAXDATA_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {
    IUpdateCompanyTaxData,
    UpdateCompanyTaxDataAction,
} from '@components/AccountSettings/Redux/interfaces';

const initialState: IUpdateCompanyTaxData = {
    error: false,
    errorData: null,
    loading: false,
};

const UpdateCompanyTaxData = (
    state = initialState,
    action: UpdateCompanyTaxDataAction
): IUpdateCompanyTaxData => {
    switch (action.type) {
        case UPDATE_TAXDATA_START:
            return {...state, loading: true, error: false};
        case UPDATE_TAXDATA_SUCCESS:
            return {...state, loading: false, error: false};
        case UPDATE_TAXDATA_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};

export default UpdateCompanyTaxData;
