import {
    ADD_MAIN_INFO,
    ADD_MAIN_INFO_ERROR,
    ADD_MAIN_INFO_FINISH,
    ADD_MAIN_INFO_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateMain: false,
};

const PutMainInfo = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case ADD_MAIN_INFO:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_MAIN_INFO_SUCCESS:
            return {
                ...state,
                updateMain: action.payload.data,
            };
        case ADD_MAIN_INFO_ERROR:
            return {
                ...state,
                error: true,
            };
        case ADD_MAIN_INFO_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutMainInfo;
