import {NewDataDeleteMotive} from '@MyCompany/Interface';
import {
    POST_DATA_REASON_ERROR,
    POST_DATA_REASON_SUCCESS,
    POST_DATA_REASON_START,
} from '@MyCompany/Redux/Types/types';

const initialState = {
    Selected: [],
    loading: false,
    error: false,
};

export const PostMotiveDelete = (state: NewDataDeleteMotive = initialState, action: any) => {
    switch (action.type) {
        case POST_DATA_REASON_START:
            return {
                ...state,
                loading: true,
            };

        case POST_DATA_REASON_SUCCESS:
            return {
                ...state,
                loading: false,
                Info: action.payload,
            };
        case POST_DATA_REASON_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
export default PostMotiveDelete;
