import React from 'react';
import {ContainerCellGrid, Input, Text} from '@Shopify/Ecommerce/styles';
import {IPropsEnterNamesUser} from '@components/ManageUser/interface';

export const EnterNamesUser = ({
    register,
    textErrorName,
    textErrorLastName,
}: IPropsEnterNamesUser) => {
    return (
        <>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Nombre (s)
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Nombre"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('userEmployeeName')}
                    error={textErrorName}
                />
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Apellidos
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Apellidos"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('usersLastName')}
                    error={textErrorLastName}
                />
            </ContainerCellGrid>
        </>
    );
};
