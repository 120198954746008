import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import Locations from '@AdminLocations/Locations';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import TreeLocations from '@AdminLocations/CreateLocation/Containers/TreeLocations';
import {restartLocations} from '@AdminLocations/redux/actions/LocationAction';
import ManageLocations from '@Locations/ManageLocations/ManageLocations';

export default function NavLocations(): JSX.Element {
    const userRol = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.userType;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const dispatch: AppDispatch = useDispatch();
    const itemsBreadcrumb: Array<IBreadcrumbItem> =
        userRol === 'Administrator'
            ? [
                  {
                      to: '#/CreateBranch',
                      label: 'Sucursales',
                      reset: () => dispatch(restartLocations(null)),
                  },
                  {to: '', label: 'Gestionar ubicaciones'},
              ]
            : [
                  {
                      to: '/',
                      label: 'Ubicaciones',
                  },
                  {to: '', label: 'Gestionar ubicaciones'},
              ];

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex
                Padding={userRol === 'Administrator' ? '0 0 2rem 0' : '0 12.063rem 0 6.25rem'}
                Justify="start"
            >
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start">
                {userRol === 'Administrator' ? (
                    <>
                        <TreeLocations />
                        <Locations branch={locations.saveBranch} />
                    </>
                ) : (
                    <ManageLocations />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
}
