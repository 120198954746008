import React, {ChangeEvent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, ContainerForm, Text} from '@/components/Shopify/Ecommerce/styles';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
import {DropArchieve} from '@Quoter/Search/DropArchive';
import {ContainerButtons} from '@Quoter/Search/ContainerButtons';
import {generateSchemaRequest} from '@Quoter/Search/SchemaRequest';
import {GroupInput} from '@Quoter/Search/ArticleRequestInputs/GroupInput';
import {FamilyInput} from '@Quoter/Search/ArticleRequestInputs/FamilyInput';
import {SubFamilyInput} from '@Quoter/Search/ArticleRequestInputs/SubFamilyInput';
import {BrandInput} from '@Quoter/Search/ArticleRequestInputs/BrandInput';
import {ArticleInput} from '@Quoter/Search/ArticleRequestInputs/ArticleInput';
import {RootState} from '@/config/store';
import {GroupRequest} from '@Quoter/Redux/Actions/PropiertiesArticleRequest';
import {IArticleRequested, IRequestArticle} from '@Quoter/Search/interfaces';
import {addArticleRequest} from '@Quoter/Redux/Actions/AddCatalogArticleRequest';
import {isSupportedFileType} from '@Quoter/Search/ArticleRequestInputs/validateArchive';

export const ArticleRequest = ({setView, setSuccesMesssage}: IArticleRequested) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [filePropierties, setPropierties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);

    const [isOptional, setIsOpcional] = useState<number>(5);
    const [requiredFields, setRequiredFields] = useState({
        Group: true,
        Family: true,
        SubFamily: true,
        Brand: true,
        Article: true,
    });
    const [labelValues, setLabelValues] = useState({
        Group: '',
        Family: '',
        SubFamily: '',
        Brand: '',
        Article: '',
    });
    const [inputView, setinputSearch] = useState({
        Group: false,
        Family: false,
        SubFamily: false,
        Brand: false,
        Article: false,
    });
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const branch = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails
    );
    const branchId = branch ? branch[0].branchId : 2;
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setSelectedFile(reader.result);
                    setPropierties(file);
                    setErrorFile(false);
                }
            };
        } else {
            setSelectedFile(null);
            setErrorFile(true);
        }
    };
    const {
        register,
        setValue,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
    } = useForm<IRequestArticle>({
        defaultValues: {
            Group: '',
            Family: '',
            SubFamily: '',
            Brand: '',
            Article: '',
        },
        resolver: yupResolver(generateSchemaRequest(requiredFields)),
    });

    const onSubmit = (values: IRequestArticle) => {
        if (token && selectedFile !== null && selectedFile.length > 0) {
            setErrorFile(false);
            const PostData = {
                group: values.Group,
                family: values.Family,
                subFamily: values.SubFamily,
                brand: values.Brand,
                article: values.Article,
                image: selectedFile,
                companyId: companyId,
            };
            dispatch(addArticleRequest(token, PostData, setView, setSuccesMesssage));
        } else {
            setErrorFile(true);
        }
    };

    useEffect(() => {
        if (token) dispatch(GroupRequest(token, branchId));
    }, []);
    useEffect(() => {
        const newRequiredFields = {
            Group: !(isOptional >= 1),
            Family: !(isOptional >= 2),
            SubFamily: !(isOptional >= 3),
            Brand: !(isOptional >= 4),
            Article: !(isOptional >= 5),
        };
        const InputFields = {
            Group: true,
            Family: isOptional <= 2,
            SubFamily: isOptional <= 3,
            Brand: isOptional <= 4,
            Article: true,
        };

        setRequiredFields(newRequiredFields);
        setinputSearch(InputFields);
    }, [isOptional]);
    return (
        <ContainerFlex Height="100%" Width="100%" Position="fixed" ZIndex="5" backG="">
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                BackG="#FFFFFF"
                FlexDir="column"
                Padding="2rem"
                ZIndex="5"
                Height="auto"
                Justify="start"
                MaxHeight="90%"
                Align="start"
                Radius="24px"
                Width="44.875rem"
                Bs="0px 10px 60px 0px rgba(0, 0, 0, 0.25);"
                Gap="16px"
                OverFlow="auto"
                Position="fixed"
            >
                <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                    {REQUESTS_ARTICLE.HEADER}
                </Text>
                <Text FontSize="1rem" Color="#54575C">
                    {REQUESTS_ARTICLE.SUB_HEADER}
                </Text>
                <ContainerFlex
                    Height="31.9rem"
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    OverFlow="auto"
                    Gap="16px"
                >
                    <GroupInput
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        token={token}
                        branchId={branchId}
                        reset={reset}
                        isOptional={isOptional}
                        requiredFields={requiredFields}
                        setIsOpcional={setIsOpcional}
                        labelValues={labelValues}
                        setLabelValues={setLabelValues}
                        watch={watch}
                        inputView={inputView}
                    />

                    <FamilyInput
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        token={token}
                        branchId={branchId}
                        reset={reset}
                        isOptional={isOptional}
                        requiredFields={requiredFields}
                        setIsOpcional={setIsOpcional}
                        labelValues={labelValues}
                        setLabelValues={setLabelValues}
                        watch={watch}
                        inputView={inputView}
                    />
                    <SubFamilyInput
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        token={token}
                        branchId={branchId}
                        reset={reset}
                        isOptional={isOptional}
                        requiredFields={requiredFields}
                        setIsOpcional={setIsOpcional}
                        labelValues={labelValues}
                        setLabelValues={setLabelValues}
                        watch={watch}
                        inputView={inputView}
                    />
                    <BrandInput
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        token={token}
                        branchId={branchId}
                        reset={reset}
                        isOptional={isOptional}
                        requiredFields={requiredFields}
                        setIsOpcional={setIsOpcional}
                        labelValues={labelValues}
                        setLabelValues={setLabelValues}
                        watch={watch}
                        inputView={inputView}
                    />

                    <ArticleInput
                        setValue={setValue}
                        register={register}
                        errors={errors}
                        token={token}
                        branchId={branchId}
                        reset={reset}
                        isOptional={isOptional}
                        requiredFields={requiredFields}
                        setIsOpcional={setIsOpcional}
                        labelValues={labelValues}
                        setLabelValues={setLabelValues}
                        watch={watch}
                        inputView={inputView}
                    />
                    <DropArchieve
                        handleFileChange={handleFileChange}
                        register={register}
                        selectedFile={selectedFile}
                        filePropierties={filePropierties}
                        fileError={errorFile}
                    />
                </ContainerFlex>

                <ContainerButtons setView={setView} />
            </ContainerForm>
        </ContainerFlex>
    );
};
