import axios from 'axios';
import {
    POST_ADD_FLOW,
    POST_ADD_FLOW_SUCCESS,
    POST_ADD_FLOW_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';
import process from 'process';
import {URL} from '@config/constants/index';

export const CreateAddFlowCash = () => {
    return {
        type: POST_ADD_FLOW,
    };
};
export const CreateAddFlowCashSuccess = (data) => {
    return {
        type: POST_ADD_FLOW_SUCCESS,
        payload: data,
    };
};

export const CreateAddFlowCashError = (err) => {
    return {
        type: POST_ADD_FLOW_ERROR,
        payload: err,
    };
};

export const PostCreateAddFlowCash = (token, dataFlowCash) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(CreateAddFlowCash());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddFlow}`,
                dataFlowCash,
                {headers}
            );
            dispatch(CreateAddFlowCashSuccess(response));
            return response;
        } catch (error) {
            dispatch(CreateAddFlowCashError(error));
            throw error;
        }
    };
};
