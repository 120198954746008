import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {selectSearchedStorage} from '@/components/LocationsBranch/Redux/Action/SearchInventoryStorage';
import {addWarehouses} from '@/components/LocationsBranch/Redux/Action/Warehouse';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import ButtonGeneral from '@/components/General/Atoms/Button';
import useInitInheritGroups from '@/hooks/useInitInheritGroups';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ACCORDION_STYLES, INITIAL_WAREHOUSE, STORAGE} from '@components/LocationsBranch/constants';
import {InheritFrom} from '@/components/LocationsBranch/InheritFrom';
import {InventoryStorage} from '@/components/LocationsBranch/InventoryStorage';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import {SearchTextbox} from '@General/Atoms/SearchBoxResultList/SearchTextbox';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {IStorage, IWarehouseProps} from '@components/LocationsBranch/interfaces';
import {GetCompanyLevelsByCompanyId} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {
    SearchInventoryStorage,
    ClearLevelsSearch,
} from '@/components/LocationsBranch/Redux/Action/SearchInventoryStorage';
import AddIcon from '@images/addIcon.svg';
import * as style from '@components/LocationsBranch/stylesLocations';
import arrow from '@images/arrow_small.svg';

export const Warehouse = ({isOptional, onRefresh}: IWarehouseProps) => {
    const dispatch = useDispatch();
    const selection = useSelector((state: RootState) => state.searchInventorySelection);
    const warehouses = useSelector((state: RootState) => state.Warehouse.warehouses);
    const searchResults = useSelector((state: RootState) => state.getSearchInventoryStorage.data);
    const [selectedStorageType, setSelectedStorage] = useState<IStorage | null>(null);

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const levels = useSelector((state: RootState) => state.getLevels.level);

    const getNextStorageId = () => {
        return (
            warehouses.reduce((acc, warehouse) => {
                return warehouse.id > acc ? warehouse.id : acc;
            }, 0) + 1
        );
    };

    const onNewWarehouse = () => {
        const newId = getNextStorageId();
        const newName = `${INITIAL_WAREHOUSE.name} ${newId}`;

        const newWarehouse = JSON.parse(JSON.stringify(warehouses));
        const newStorageType = JSON.parse(
            JSON.stringify({...INITIAL_WAREHOUSE, id: newId, name: newName})
        );

        newWarehouse.push(newStorageType);
        dispatch(addWarehouses(newWarehouse));
    };

    const inheritType = 'location';

    const selectedLevel = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === inheritType
    )?.selectedLevel;

    const handleSearchSelect = (item: LevelSelection) => {
        dispatch(selectSearchedStorage(item));
        onRefresh(item.ids[0]);
        const selection = warehouses.find((witem) => witem.id === item.ids[0]);
        if (item.ids[1] && selection?.storageTypes) {
            const storageType = selection?.storageTypes?.find(
                (storage) => storage.id === item.ids[1]
            );
            setSelectedStorage(JSON.parse(JSON.stringify(storageType)));
        }
    };

    const handleSearch = (searchText: string) => {
        if (levels) dispatch(SearchInventoryStorage(token, searchText, 1));
    };

    const handleReset = () => {
        dispatch(selectSearchedStorage({title: '', ids: []}));
        setSelectedStorage(null);
        dispatch(ClearLevelsSearch());
        onRefresh();
        if (token && companyId) {
            dispatch(GetCompanyLevelsByCompanyId(companyId, token, 0));
        }
    };

    const renderItem = (item: LevelSelection): JSX.Element => {
        const parts = item.title.split('>');
        return (
            <ContainerFlex PaddingR="4px" Align="center" Justify="flex-start" Gap="4px">
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        <Text>{part.trim()}</Text>
                        {index < parts.length - 1 && (
                            <Image
                                src={arrow}
                                Width="1.125rem"
                                Height="1.125rem"
                                Transform="rotate(180deg)"
                            />
                        )}
                    </React.Fragment>
                ))}
            </ContainerFlex>
        );
    };

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    useInitInheritGroups(inheritType, 3);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Border="solid 0.063rem #F4F5F5"
            Radius="1rem"
            Gap="1rem"
            Height="fit-content"
        >
            <Accordion sx={ACCORDION_STYLES} defaultExpanded>
                <AccordionSummary
                    expandIcon={
                        <Image src={arrowDownIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                    }
                >
                    <ContainerFlex Justify="space-between" Align="center" Self="stretch">
                        <Text {...style.contentGralTitle}>
                            {STORAGE.TITLE}
                            {isOptional && (
                                <Text {...style.contentGralTitleOp} MarginLeft="0.5rem">
                                    {STORAGE.OPTIONAL}
                                </Text>
                            )}
                        </Text>
                        <div onClick={stopPropagation}>
                            <InheritFrom
                                inheriterName={selectedLevel?.levelName as string}
                                type="location"
                            />
                        </div>
                    </ContainerFlex>
                </AccordionSummary>
                <AccordionDetails>
                    <ContainerFlex FlexDir="column" Align="start" MarginBt="1rem">
                        <SearchTextbox
                            placeholder={STORAGE.SEARCH}
                            searchAction={handleSearch}
                            resetAction={handleReset}
                            results={searchResults}
                            renderItem={renderItem}
                            onSelect={handleSearchSelect}
                            defaultValue={selection?.selectedLevel?.title}
                            minSearchCharacters={2}
                            showSelection={true}
                            noDataContent={
                                <ContainerFlex
                                    Padding="8px"
                                    Align="center"
                                    Justify="start"
                                    Self="stretch"
                                >
                                    <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                                        {STORAGE.NO_RECORDS_FOUND}
                                    </Text>
                                </ContainerFlex>
                            }
                        />
                    </ContainerFlex>
                    {warehouses.map((warehouse, index) => (
                        <ContainerFlex Padding="8px" FlexDir="Column" key={index}>
                            <InventoryStorage
                                warehouse={warehouse}
                                onNewWarehouse={onNewWarehouse}
                                setSelectedStorage={setSelectedStorage}
                                selectedStorageType={selectedStorageType}
                            />
                        </ContainerFlex>
                    ))}

                    {!selectedStorageType && (
                        <ButtonGeneral
                            color="#5A5AFF"
                            backGround="#FFFFFF"
                            hText="#5A5AFF"
                            hShadow=""
                            height="2.5rem"
                            cursor="pointer"
                            border="1px solid #5A5AFF"
                            hBackG="#E5E5FF"
                            clic={(e: React.MouseEvent) => {
                                e.preventDefault();
                                onNewWarehouse();
                            }}
                            text={
                                <>
                                    <Image src={AddIcon} alt="add" color="#5A5AFF" />
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="700"
                                        Cursor="pointer"
                                        FontSize="1rem"
                                    >
                                        {STORAGE.NEW_WAREHOUSE}
                                    </Text>
                                </>
                            }
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </ContainerFlex>
    );
};
