import React, {useEffect, useState} from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';

import {PaymentMethod} from '@components/Home/Admin/PaymentMethod';
import {EnterpriceConfiguration} from '@components/Home/Admin/EnterpriceConfiguration';
import {CompanyReinforcement} from '@components/Home/Admin/CompanyReinforcement';
import {EnterpricesOutsputs} from '@components/Home/Admin/EnterpricesOutsputs';
import {RecentActivity} from '@components/Home/Admin/RecentActivity';
import {useDispatch, useSelector} from 'react-redux';
import {infoCompanyBenefit} from '@/components/SignUp/Redux/Actions/companyBenefitAction';
import {getAdminHomePercentage} from '@/components/Home/Admin/Redux/Actions/GetHomeAdmin';
import {RootState} from '@/config/store';

export const HomeAdmin = () => {
    const [isAmountDisplayed, setIsAmoutDisplayed] = useState(true);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    const dispatch = useDispatch();
    useEffect(() => {
        if (token) {
            dispatch(infoCompanyBenefit());
            dispatch(getAdminHomePercentage(companyId, token));
        }
    }, []);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Width="100%"
            Padding="19px 24px 0 24px"
            FlexDir="Column"
            Gap="8px"
        >
            {isAmountDisplayed && <PaymentMethod setIsDisplayed={setIsAmoutDisplayed} />}
            <EnterpriceConfiguration />
            <CompanyReinforcement />
            <EnterpricesOutsputs />
            <RecentActivity />
        </ContainerFlex>
    );
};
