export const TREE_PERMISSIONS = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    RESET: 'RESET',
};

export const CREATE_ROLE = {
    CREATE_START: 'CREATE_START',
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    CREATE_ERROR: 'CREATE_ERROR',
};

export const UPDATE_ROLE = {
    UPDATE_START: 'UPDATE_START',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_ERROR: 'UPDATE_ERROR',
};

export const ROLE_ID = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    CURRENT_ID: 'CURRENT_ID',
};

export const ROLE_USERS = {
    ASIGNMENT_START: 'ASIGNMENT_START',
    ASIGNMENT_SUCCESS: 'ASIGNMENT_SUCCESS',
    ASIGNMENT_ERROR: 'ASIGNMENT_ERROR',
};

export const PERSISTENCE_ROLES = {
    TYPE: 'TYPE',
    PERMISSIONS: 'PERMISSIONS',
    ASSIGNMENT: 'ASSIGNMENT',
    RESET: 'RESET',
    CHECKS: 'CHECKS',
    TYPE_ACTION: 'TYPE_ACTION',
};

export const GET_ALL_USER_BY_ROLE = 'GET_ALL_USER_BY_ROLE';
export const GET_ALL_USER_BY_ROLE_SUCCESS = 'GET_ALL_USER_BY_ROLE_SUCCESS';
export const GET_ALL_USER_BY_ROLE_ERROR = 'GET_ALL_USER_BY_ROLE_ERROR';
export const AFFECTED_USERS = {
    START_ROLES: 'START_ROLES',
    SUCCESS_ROLES: 'SUCCESS_ROLES',
    ERROR_ROLES: 'ERROR_ROLES',
};
