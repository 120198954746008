import styled from '@emotion/styled';
import {
    IPropsRowItem,
    IPropsDateSearchInput,
    IIconContainerProps,
    IDashBoardImageWrapProps,
    IDatePickerWrapperProps,
} from '@/components/Shopify/ListClient/styles/interfacesStyles';

export const ImageClient = styled.img`
    width: 51px;
    height: 51px;
    border-radius: 50%;
`;
export const ImageIconClient = styled.img`
    width: 20px;
    height: 13px;
`;

export const RowItem = styled.div<IPropsRowItem>`
    display: grid;
    justify-content: ${(props) => props.Justify};
    grid-template-columns: ${(props) => props.GridColumn};
    align-content: center;
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    width: ${(props) => props.Width};
    opacity: ${(props) => props.Opacity};
    background: ${(props) => props.Background};
    min-height: ${(props) => props.MinHeight};
    max-height: ${(props) => props.MaxHeight};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    &:hover {
        cursor: ${(props) => props.Cursor};
        background-color: ${(props) => props.BackGColor};
    }
`;

export const DateSearchInput = styled.div<IPropsDateSearchInput>`
    position: relative;
    display: flex;
    align-items: center;
    height: ${(props) => (props.Height ? props.Height : '40px')};
    width: ${(props) => (props.Width ? props.Width : '100%')};
    background-color: ${(props) => (props.BackG ? props.BackG : '#ffffff')};
    border: ${(props) => (props.Border ? props.Border : '1px solid #E8E9EA')};
    border-radius: ${(props) => (props.BRadius ? props.BRadius : '2rem')};
    &:hover {
        border: ${(props) => (props.BorderH ? props.BorderH : '1px solid #35cf44')};
        box-shadow: ${(props) => props.BoxShadowH};
    }
    &:focus-within {
        border: ${(props) => (props.BorderF ? props.BorderF : '1px solid #35cf44')};
        box-shadow: ${(props) =>
            props.BoxShadowF ? props.BoxShadowF : '0px 4px 4px rgb(90 90 248 / 19%)'};
    }
    input {
        width: 100%;
        height: 100%;
        padding: 0 16px;
        border: none;
        background: transparent;
        outline: none;
    }
`;

export const IconContainer = styled.div<IIconContainerProps>`
    display: flex;
    justify-content: ${({Justify}) => Justify};
    align-items: center;
    width: ${({Width}) => Width};
    color: ${({Color}) => Color};
    font-size: ${({FontSize}) => FontSize};
    padding: ${({Padding}) => Padding};
`;

export const DatePickerWrapper = styled.div<IDatePickerWrapperProps>`
    & > :first-child {
        width: ${({Width}) => Width || '100%'};
    }
`;

export const DashedBorderImageWrap = styled.div<IDashBoardImageWrapProps>`
    padding: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    margin: ${({Margin}) => Margin};
    border: 4px solid transparent;
    border: 3px dashed #d4d6d8;
    box-sizing: border-box;
`;
