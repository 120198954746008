import React from 'react';
import {ContainerFlex, Text, Modal, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {CUESTIONS, FUNDING, RESOLUCTION} from '@/components/CashFundingModal/Operational/constants';
import warning from '@images/warningIcone.svg';
import {ImageLogo} from '@/components/CashFundingModal/Operational/styles';

interface ModalResoluctionProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const ModalResoluction = ({}: ModalResoluctionProps) => {
    return (
        <Modal>
            <ContainerFlex
                FlexDir="column"
                Width="33.125rem"
                Height="26.438"
                Radius="24px"
                backG="#fff"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                Align="start"
                Padding="24px"
            >
                <ContainerFlex FlexDir="column" Justify="start" Align="start">
                    <Text
                        FontSize="1.5rem"
                        FontWeight="700"
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        MarginB="24px"
                    >
                        {RESOLUCTION.TITTLE}
                    </Text>
                </ContainerFlex>

                <ContainerFlex FlexDir="column">
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text MarginB="8px">{CUESTIONS.DATE}</Text>
                        <Text MarginB="24px">{CUESTIONS.DATE_COMPLETE}</Text>
                    </ContainerFlex>

                    <ContainerCellGrid Padding=" 8px 16px" Column="16px">
                        <Text FontSize="0.875rem" FontWeight="500" Width="100%">
                            {CUESTIONS.BALANCE}
                        </Text>
                        <Text FontSize="0.875rem" FontWeight="500" Width="100%">
                            {CUESTIONS.BALANCE_CASH}
                        </Text>
                    </ContainerCellGrid>

                    <ContainerFlex Bb="1px solid  #2A2C2F">
                        <ContainerCellGrid Padding=" 8px 16px" Column="16px">
                            <Text
                                FontSize="0.875rem"
                                FontFamily="Nunito"
                                color="#2A2C2F"
                                Width="100%"
                            >
                                {CUESTIONS.FUND}
                            </Text>
                            <Text
                                FontSize="0.875rem"
                                FontFamily="Nunito"
                                color="#54575C"
                                Width="100%"
                            >
                                {CUESTIONS.CASH_NEW}
                            </Text>
                        </ContainerCellGrid>
                    </ContainerFlex>

                    <ContainerFlex MarginBt="32px">
                        <ContainerCellGrid Padding=" 8px 16px" Column="16px">
                            <Text FontSize="0.875rem" FontWeight="500" color="#2A2C2F" Width="100%">
                                {CUESTIONS.NEW}
                            </Text>
                            <Text FontSize="0.875rem" FontWeight="400" Width="100%">
                                {FUNDING.CASH_NEW}
                            </Text>
                        </ContainerCellGrid>
                    </ContainerFlex>

                    <ContainerFlex Align="start" ColumnGap="8px" backG="#FFF5DB" Padding="8px">
                        <ImageLogo Width="20px" Height="20px" src={warning} />
                        <ContainerFlex Align="start" FlexDir="column">
                            <Text FontWeight="400" FontSize="1rem" Color="#614500">
                                {RESOLUCTION.SUB_TITTLE}
                            </Text>
                            <Text FontWeight="400" Color="#2A2C2F" FontSize="1rem">
                                {RESOLUCTION.TEXT}
                            </Text>
                            <Text FontWeight="400" Color="#2A2C2F" FontSize="1rem">
                                {RESOLUCTION.TEXT_ONE}
                            </Text>
                            <Text FontWeight="400" Color="#2A2C2F" FontSize="1rem">
                                {RESOLUCTION.TEXT_TWO}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
