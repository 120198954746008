export const UPDATE_ADDRESS = {
    ADDRESS: 'Editar dirección',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    LABEL: 'Etiqueta',
    POSTAL_CODE: 'C.P.',
    STREET: 'Calle',
    NUM_OUT: 'No. exterior',
    NUM_INTERNAL: 'No. interior',
    COLONY: 'Colonia',
    MUNICIPALY: 'Alcaldía',
    STATE: 'Estado',
};

export const VERIFY_FIELD = 'Verifica los campos marcados en rojo.';

export const UPDATE_COMPANY = {
    DATA_COMPANY: 'Datos de mi empresa',
    NAME: 'Nombre',
    MAIL: 'Correos electrónicos',
    PHONES: 'Números de teléfono',
    NAME_COMPANY: 'Nombre de empresa',
    NATURAL_NAME: 'Nombre natural de la empresa',
    EMAIL: 'correo electrónico',
    PHONE: 'número de teléfono',
    ADD_MAIL: 'Agregar correo electrónico',
    ADD_PHONE: 'Agregar número de teléfono',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
};

export const orderContact = (num: number) => {
    const CONTACT: {[key: number]: string} = {
        1: 'Primer ',
        2: 'Segundo ',
        3: 'Tercer ',
    };
    return CONTACT[num] || undefined;
};

export const MY_COMPANY = {
    MY_ENTERPRICE: 'Mi empresa',
    DETAILS_ENTERPRICE: 'Datos de mi empresa',
    TAX_ENTERPRICE: 'Datos fiscales de mi empresa',
    EDIT: 'Editar',
    WITHOUTINFO: 'Sin información',
    EMAIL: 'Correo electrónico:',
    RFC: 'RFC:',
    TAX_ADDRESS: 'Dirección fiscal:',
    COMPLETE_TAX: 'Completa los datos fiscales',
    PRIVACITY_ENTERPRICE: 'Privacidad de mi empresa',
    DOWNLOAD_INFO: 'Descargar información de mi empresa',
    DELL_INFO: 'Eliminar empresa e información',
    EXTENCIONS: ['PNG', 'JPEG', 'SVG', 'png', 'jpeg', 'svg'],
    ERR_EXT: 'La imagen debe ser PNG, JPEG o SVG.',
};

export const withoutInfo = (value: string) => {
    let style;
    if (value === MY_COMPANY.WITHOUTINFO) style = {FontWeight: '400', Color: '#A1A5AA'};
    else style = {FontWeight: '500', Color: '#2A2C2F'};
    return style;
};
