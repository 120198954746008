import axios, {AxiosError} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IUpdateTaxData} from '@components/AccountSettings/Redux/interfaces';
import {GetTaxData} from '@components/AccountSettings/Redux/Actions/GetCompanyTaxData';
import {
    UPDATE_TAXDATA_START,
    UPDATE_TAXDATA_SUCCESS,
    UPDATE_TAXDATA_ERROR,
} from '@components/AccountSettings/Redux/types/Types';

const updateTaxDataStart = () => {
    return {type: UPDATE_TAXDATA_START};
};
const updateTaxDataSuccess = () => {
    return {type: UPDATE_TAXDATA_SUCCESS};
};

const updateTaxDataError = (error: AxiosError) => {
    return {type: UPDATE_TAXDATA_ERROR, error: error};
};

export function UpdateTaxData(data: IUpdateTaxData, token: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(updateTaxDataStart());
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateTaxInformation}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(updateTaxDataSuccess());
            dispatch(GetTaxData(data.companyId, token));
        } catch (error) {
            const axiosError = error as AxiosError;
            dispatch(updateTaxDataError(axiosError));
            throw axiosError;
        }
    };
}
