import React from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';

import ProgressBarComponent from '@components/Home/ProgressBarComponent';

export const ProgressBar = () => {
    const Progress = useSelector((state: RootState) => state.getHomeAdminPercentage);

    return (
        <ContainerFlex Justify="start" Align="start" Padding="16px 0 20px 0" FlexDir="column">
            <Text Color="#71767D" FontSize="1rem" Padding="0 0 8px 0">
                {Progress.data === null ? 0 : Progress.data}% completado
            </Text>
            <ProgressBarComponent
                progress={Progress.data === 0 || Progress.data === null ? 2 : Progress.data}
            />
        </ContainerFlex>
    );
};
