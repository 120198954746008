import React from 'react';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import {Input} from '@components/NavBarPOS/SearchComponent/styles';
import TypeCardVisa from '@images/VISA.svg';
import Help from '@images/help.svg';
import {Controller, FieldValues} from 'react-hook-form';
import ErrorMessage from '@components/QuickPayment/ErrorMessage';
import {IMAGE_ATL_DESCRIPTION} from '@components/QuickPayment/Constants';

export const CardInput: React.FC<FieldValues> = ({control, errors}) => {
    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                Height="2.5rem"
                Radius="2rem"
                Self="start"
                Border="1px solid #E8E9EA"
                GridColumns="10% 80% 10%"
                Padding="0.5rem 1rem 0.5rem 0rem"
            >
                <Controller
                    name="cardNumber"
                    control={control}
                    render={({field}) => (
                        <>
                            <Image
                                src={TypeCardVisa}
                                Width="32px"
                                Height="32px"
                                Padding="0 8px"
                                alt={IMAGE_ATL_DESCRIPTION.CARD_ICON}
                            />
                            <Input {...field} maxLength={16} />
                            <Image
                                src={Help}
                                Width="24px"
                                Height="24px"
                                alt={IMAGE_ATL_DESCRIPTION.HELP_ICON}
                            />
                        </>
                    )}
                />
            </ContainerFlex>
            {errors.cardNumber && <ErrorMessage>{errors.cardNumber.message}</ErrorMessage>}
        </ContainerFlex>
    );
};
