import {
    GET_DATA_EXPENSES_FROM,
    RESET_DATA_FORM,
    IMAGES_PAYMENT,
    IMAGES_PAYMENT_PROP,
    IMAGES_RECIEVE,
    IMAGES_RECIEVE_PROP,
} from '@CashFlow/Expenses/Redux/types';
import {IExpendForm} from '@CashFlow/Expenses/ExpenseDetails/Types';

const initialState = {
    dataFormExpense: {
        expendType: {value: 0, label: ''},
        supplierType: '',
        paymentMethod: {value: 0, label: ''},
        transferNumber: null,
        description: '',
        date: '',
        items: [{item: null, article: '', count: '', cost: '', iva: ''}],
    },
    imagePayment: null,
    imagePaymentProp: {
        name: '',
        size: 0,
    },
    imageRecieve: null,
    imageRecieveProp: {
        name: '',
        size: 0,
    },
};

const getFormExpenses = (
    state = initialState,
    action: {
        type: string;
        payload: IExpendForm;
        ImagePayments: string | null;
        ImagePaymentProps: {name: string; size: number};
        ImageRecieves: string | null;
        ImageRecievesProps: {name: string; size: number};
    }
) => {
    switch (action.type) {
        case GET_DATA_EXPENSES_FROM:
            return {
                ...state,
                dataFormExpense: action.payload,
            };
        case IMAGES_PAYMENT:
            return {
                ...state,
                imagePayment: action.ImagePayments,
            };
        case IMAGES_PAYMENT_PROP:
            return {
                ...state,
                imagePaymentProp: action.ImagePaymentProps,
            };
        case IMAGES_RECIEVE:
            return {
                ...state,
                imageRecieve: action.ImageRecieves,
            };
        case IMAGES_RECIEVE_PROP:
            return {
                ...state,
                imageRecieveProp: action.ImageRecievesProps,
            };
        case RESET_DATA_FORM:
            return initialState;
        default:
            return state;
    }
};

export default getFormExpenses;
