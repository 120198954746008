import {IReducerType} from '@/components/Branches/interfaces';
import {
    GET_BRANCH_DISTANCE_RECEIVE,
    GET_BRANCH_DISTANCE_RECEIVE_ERROR,
    GET_BRANCH_DISTANCE_RECEIVE_FINISH,
    GET_BRANCH_DISTANCE_RECEIVE_RESET,
    GET_BRANCH_DISTANCE_RECEIVE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    branchesThatSendMoney: [],
};

const GetBranchDistanceReceive = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_BRANCH_DISTANCE_RECEIVE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_DISTANCE_RECEIVE_SUCCESS:
            return {
                ...state,
                branchesThatSendMoney: action.payload.data.data,
            };
        case GET_BRANCH_DISTANCE_RECEIVE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRANCH_DISTANCE_RECEIVE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_BRANCH_DISTANCE_RECEIVE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetBranchDistanceReceive;
