import {IPurity, IPurityRedux} from '@/components/Foundry/interfaces';
import {
    GET_PURITY,
    GET_PURITY_SUCCESS,
    GET_PURITY_ERROR,
    GET_PURITY_FILTERS,
    GET_PURITY_LAB,
    GET_PURITY_LAB_SUCCESS,
    GET_PURITY_LAB_ERROR,
    GET_PURITY_FILE,
    GET_PURITY_FILE_ERROR,
    GET_PURITY_FILE_SUCCESS,
    POST_PURITY_FILE,
    POST_PURITY_FILE_SUCCESS,
    POST_PURITY_FILE_ERROR,
    GET_PURITY_INGOT,
    POST_PURITY_PAY,
    POST_PURITY_PAY_ERROR,
    POST_PURITY_PAY_SUCCESS,
} from '@TypesPurity/PurityTypes';

const initialState: IPurity = {
    purity: [],
    error: false,
    filters: {},
    puritylab: {},
    puritycount: 0,
    purityPageIndex: 0,
    loading: false,
    purityfile: null,
    id: null,
    idingot: null,
};

const purity = (state = initialState, action: IPurityRedux): IPurity => {
    switch (action.type) {
        case GET_PURITY:
            return {
                ...state,
                loading: true,
            };
        case GET_PURITY_SUCCESS:
            return {
                ...state,
                purity: action.purity,
                loading: false,
                purityPageIndex: action.purityPageIndex,
                puritycount: action.puritycount,
            };
        case GET_PURITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PURITY_FILTERS:
            return {
                ...state,
                filters: action.filters,
            };
        case GET_PURITY_INGOT:
            return {
                ...state,
                idingot: action.idingot,
            };

        case GET_PURITY_LAB_SUCCESS:
            return {
                ...state,
                puritylab: action.puritylab,
                loading: false,
            };
        case GET_PURITY_LAB_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PURITY_LAB:
            return {
                ...state,
                id: action.id,
                loading: true,
            };
        case GET_PURITY_FILE:
            return {
                ...state,
                loading: true,
            };
        case GET_PURITY_FILE_SUCCESS:
            return {
                ...state,
                purityfile: action.purityfile,
                loading: false,
            };
        case GET_PURITY_FILE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        case POST_PURITY_FILE:
            return {
                ...state,
                loading: true,
            };
        case POST_PURITY_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_PURITY_FILE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        case POST_PURITY_PAY:
            return {
                ...state,
                loading: true,
            };
        case POST_PURITY_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_PURITY_PAY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default purity;
