import {IReducerType} from '@/components/Branches/interfaces';
import {
    GET_BRANCH_DISTANCE,
    GET_BRANCH_DISTANCE_ERROR,
    GET_BRANCH_DISTANCE_FINISH,
    GET_BRANCH_DISTANCE_RESET,
    GET_BRANCH_DISTANCE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    moneyTransfersBranches: [],
};

const GetBranchDistance = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_BRANCH_DISTANCE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_DISTANCE_SUCCESS:
            return {
                ...state,
                moneyTransfersBranches: action.payload.data.data,
            };
        case GET_BRANCH_DISTANCE_ERROR:
            return {
                ...state,
                error: true,
            };
        case GET_BRANCH_DISTANCE_FINISH:
            return {
                ...state,
                loading: false,
            };
        case GET_BRANCH_DISTANCE_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetBranchDistance;
