import * as yup from 'yup';

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const schema = yup.object().shape({
    Email: yup
        .string()
        .required('Se requiere correo electrónico')
        .matches(EMAIL_REGEX, 'Ingrese un correo electrónico válido'),
    Password: yup.string().required('Se requiere contraseña'),
    version: yup.number(),
});
