import {
    GET_INFO_CONTRACT,
    GET_INFO_CONTRACT_SUCCESS,
    GET_INFO_CONTRACT_ERROR,
    RESET_INFO_STADISTICS_EDIT,
} from '@components/ShoppingCart/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    loadingUpdate: false,
    data: [],
};

const getInfoContracts = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_INFO_CONTRACT:
            return {...state, loading: true};
        case GET_INFO_CONTRACT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
            };
        case GET_INFO_CONTRACT_ERROR:
            return {...state, loading: false, error: true};
        case RESET_INFO_STADISTICS_EDIT:
            return {
                ...state,
                loading: false,
                data: [],
            };
        default:
            return state;
    }
};

export default getInfoContracts;
