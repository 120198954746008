import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DATA, DETAIL_COMPONENT} from '@/components/TransactionsDetails/Operational/Constants';
import {RootStateOrAny, useSelector} from 'react-redux';
import {IPawnData} from '@components/TransactionsDetails/Redux/interface';
import {formatterDolar} from '@/hooks/currentFormatUtils';

export const CashUserDetails = () => {
    const PawnsDetails: IPawnData = useSelector((state: RootStateOrAny) => {
        return state.GetPawnDetails;
    });
    return (
        <ContainerFlex Height="max-content">
            <ContainerCellGrid>
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontSize="1rem" Color="#54575C">
                        {DETAIL_COMPONENT.CASH_TOTAL}
                    </Text>
                    <Text FontSize="1rem" Color="#54575C">
                        {DETAIL_COMPONENT.CREDIT_CARD}
                    </Text>
                    <Text FontSize="1rem" Color="#54575C" FontWeight="700">
                        {DETAIL_COMPONENT.COMMIT}
                    </Text>
                </ContainerFlex>
                {PawnsDetails.catState.paymentMethods && (
                    <ContainerFlex FlexDir="column" Align="end">
                        {PawnsDetails.catState.paymentMethods.slice(0, 2).map((method, index) => (
                            <Text key={index} FontSize="1rem" Color="#54575C" FontWeight="700">
                                - {formatterDolar.format(method.amount || 0)}
                            </Text>
                        ))}
                        <Text FontSize="1.25rem" Color="#54575C" FontWeight="700">
                            {DATA.USER.CASH}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
