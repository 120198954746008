import React from 'react';
import {Controller} from 'react-hook-form';

import {IFormSelectProps} from '@General/Atoms/LabeledSelectDropdown/interfaces';
import {TooltipWrapper} from '@General/Atoms/LabeledSelectDropdown/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const FormSelect: React.FC<IFormSelectProps> = ({
    name,
    label,
    mutedLabel,
    options,
    control,
    error,
    tooltip,
    styles = {},
}) => {
    const {
        labelFontSize = '0.75rem',
        labelFontWeight = '500',
        labelColor = '#6D6E71',
        letterSpacing = 'normal',
        mutedLabelFontSize = '0.75rem',
        mutedLabelFontWeight = '500',
        mutedLabelColor = '#A1A5AA',
        errorFontSize = '0.75rem',
    } = styles;

    return (
        <FlexPanel FlexDir="column" Align="flex-start" Gap="0.25rem" Width="100%">
            <Text
                FontSize={labelFontSize}
                FontWeight={labelFontWeight}
                Color={labelColor}
                LetterSpacing={letterSpacing}
            >
                {label}
            </Text>
            {mutedLabel && (
                <Text
                    FontSize={mutedLabelFontSize}
                    FontWeight={mutedLabelFontWeight}
                    Color={mutedLabelColor}
                    PaddingL="0.25rem"
                >
                    {mutedLabel}
                </Text>
            )}
            <ContainerFlex Position="relative">
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            value={value}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!error}
                            options={options}
                            onChange={(selectedOption) => onChange(selectedOption)}
                        />
                    )}
                />
                {tooltip && <TooltipWrapper>{tooltip}</TooltipWrapper>}
            </ContainerFlex>
            {error && (
                <Text Color="#FF6357" FontSize={errorFontSize}>
                    {error}
                </Text>
            )}
        </FlexPanel>
    );
};
export default FormSelect;
