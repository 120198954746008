import styled from '@emotion/styled';
import {COLORS} from '@General/Variables';
import {
    IPropsButtonForTable,
    IPropsTableRowHeadContract,
    IPropsTableRowItemsContract,
} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/AmountAndTimes/interfacesStyles';

export const TableRowHeadContract = styled.div<IPropsTableRowHeadContract>`
    display: grid;
    grid-template-columns: ${(props) => props.Grid};
    height: ${(props) => props.Height};
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border: 1px solid #e4e7e9;
    border-radius: 5px;
`;

TableRowHeadContract.defaultProps = {
    Grid: '0.45fr 0.4fr 0.4fr 0.4fr 0.4fr 0.28fr',
    Height: '52',
};
export const TableRowItemsContract = styled.div<IPropsTableRowItemsContract>`
    display: grid;
    border-radius: 5px;
    border: ${(props) => props.Border};
    margin: ${(props) => props.Margin};
    grid-template-columns: ${(props) => props.Grid};
    height: ${(props) => props.Height};
    align-items: ${(props) => props.Align};
    justify-items: ${(props) => props.Justify};
    box-sizing: border-box;
    background: #fff;
    i {
        color: #757575;
        font-size: 13px;
    }
`;

TableRowItemsContract.defaultProps = {
    Grid: '0.45fr 0.4fr 0.4fr 0.4fr 0.4fr 0.28fr',
    Justify: 'center',
    Margin: '0 0 0.5rem 0',
};

export const ButtonForTable = styled.div<IPropsButtonForTable>`
    width: 160px;
    display: flex;
    height: ${(props) => props.Height};
    font-size: 14px;
    border: 1px solid #414042;
    border-radius: 5px;
    /* margin: 0 auto; */
    justify-content: center;
    background-color: white;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    &:disabled {
        background-color: var(--gray-5);
        color: var(--gray-2);
        border: 1px solid var(--gray-2);
    }
    &:hover {
        border-color: ${COLORS.green};
    }
`;
