import {PERSISTENCE_ROLES} from '@Roles/CreateRoles/Redux/Types';
import {IPersistenceRole, IPersistenceRoleRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: IPersistenceRole = {
    typeRol: {roleType: 0, roleName: ''},
    roles: {
        pos: false,
        admin: false,
    },
    modules: [],
    userId: [],
    typeAction: '',
};

export const PersistenceRole = (
    state = initialState,
    action: IPersistenceRoleRedux
): IPersistenceRole => {
    switch (action.type) {
        case PERSISTENCE_ROLES.TYPE:
            return {
                ...state,
                typeRol: action.typeRol,
            };
        case PERSISTENCE_ROLES.CHECKS:
            return {
                ...state,
                roles: action.roles,
            };
        case PERSISTENCE_ROLES.PERMISSIONS:
            return {
                ...state,
                modules: action.modules,
            };
        case PERSISTENCE_ROLES.ASSIGNMENT:
            return {...state, userId: action.userId};
        case PERSISTENCE_ROLES.TYPE_ACTION:
            return {...state, typeAction: action.typeAction};
        case PERSISTENCE_ROLES.RESET:
            return initialState;
        default:
            return state;
    }
};
