import {ISaveLoansUser, ISaveLoansUserReducer} from '@components/PersonalLoans/interfaces';
import {SAVE_LOANS_RESET, SAVE_LOANS_USER} from '@components/PersonalLoans/Redux/Types/Types';

const initialState: ISaveLoansUser = {
    personal: {
        name: '',
        lastNames: '',
        curp: '',
        birthdate: '',
        phone: '',
        catLadaId: 0,
        addressPersonalLoans: {
            street: '',
            interiorNumber: '',
            externalNumber: '',
            postalCode: '',
            catColonyId: {
                value: 0,
                label: '',
            },
            catMunicipaliyId: {
                value: 0,
                label: '',
            },
            catStateId: {
                value: 0,
                label: '',
            },
        },
    },
};

const SaveLoansUser = (state = initialState, action: ISaveLoansUserReducer): ISaveLoansUser => {
    switch (action.type) {
        case SAVE_LOANS_USER:
            return {
                ...state,
                personal: action.personal,
            };
        case SAVE_LOANS_RESET:
            return initialState;
        default:
            return state;
    }
};
export default SaveLoansUser;
