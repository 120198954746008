import React from 'react';
import warningIcon from '@components/PersonalLoans/NewLoanProduct/icons/warningIcon.svg';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IWarningErrorProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const WarningError: React.FC<IWarningErrorProps> = ({errorMessage}) => {
    return (
        <ContainerFlex Padding="1rem" Gap="0.5rem" Radius="0.5rem" backG="#FFF5DB" Justify="start">
            <Image src={warningIcon} Width="24px" Height="24px" alt="warning-icon" />
            <Text Color="#2A2C2F">{errorMessage}</Text>
        </ContainerFlex>
    );
};
