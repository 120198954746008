import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import arrowSmallDownIcon from '@/components/CreditsAndPawns/icons/arrowSmallDownIcon.svg';
import {ICreditRequestAccordionProps} from '@/components/CreditsAndPawns/interfaces';
import {
    ACCORDION_STYLES,
    ACCORDION_STYLES_NO_PADDING,
} from '@/components/CreditsAndPawns/constants';

const CreditRequestAccordion: React.FC<ICreditRequestAccordionProps> = ({
    summaryTitle,
    children,
}) => {
    return (
        <ContainerFlex FlexDir="column" Width="100%" Gap="16px" Justify="start">
            <ContainerFlex Height="1px" Width="100%" backG="#E8E9EA" />
            <Accordion sx={ACCORDION_STYLES}>
                <AccordionSummary
                    sx={ACCORDION_STYLES_NO_PADDING}
                    expandIcon={<Image src={arrowSmallDownIcon} height="24px" width="25px" />}
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#1D1E20"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {summaryTitle}
                    </Text>
                </AccordionSummary>
                <AccordionDetails sx={ACCORDION_STYLES_NO_PADDING}>{children}</AccordionDetails>
            </Accordion>
        </ContainerFlex>
    );
};

export default CreditRequestAccordion;
