import * as yup from 'yup';
import {VALIDATION_MESSAGE} from '@/components/PersonalLoans/constants';

const clabeRegEx = /^\d{18}$/;

export const schemaUserLoans = yup.object().shape({
    name: yup.string().required('Campo obligatorio'),
    lastNames: yup.string().required('Campo obligatorio'),
    curp: yup
        .string()
        .required('Campo obligatorio')
        .length(18, 'El CURP debe tener exactamente 18 caracteres'),
    birthdate: yup.string().required('Campo obligatorio'),
    phone: yup.string().required('Campo obligatorio').length(10, 'Debe tener 10 digitos.'),
    catLadaId: yup.number(),
    addressPersonalLoans: yup.object().shape({
        street: yup.string().required('Campo obligatorio'),
        interiorNumber: yup.string(),
        externalNumber: yup.string().required('Campo obligatorio'),
        postalCode: yup
            .string()
            .required('Campo obligatorio')
            .length(5, 'El CP debe tener 5 caracteres'),
        catColonyId: yup.object().shape({
            value: yup
                .number()
                .required('Campo obligatorio')
                .test('is-non-zero', 'Campo obligatorio', function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        }),
        catMunicipaliyId: yup.object().shape({
            value: yup
                .number()
                .required('Campo obligatorio')
                .test('is-non-zero', 'Campo obligatorio', function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        }),
        catStateId: yup.object().shape({
            value: yup
                .number()
                .required('Campo obligatorio')
                .test('is-non-zero', 'Campo obligatorio', function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string().required('Campo obligatorio'),
        }),
    }),
});

export const schemaJobLoans = yup.object().shape({
    catEmploymentSituationId: yup
        .object()
        .shape({
            value: yup
                .number()
                .required('Campo obligatorio')
                .test('is-non-zero', 'Campo obligatorio', function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        })
        .required()
        .typeError('Campo obligatorio'),
    nameCompany: yup.string(),
    addresCompany: yup.string(),
    phoneCompany: yup
        .string()
        .nullable()
        .test(
            'is-valid-phone',
            'Debe contener solo dígitos y tener entre 10 y 15 dígitos.',
            function (value) {
                if (!value) return true;
                const isValidLength = value.length >= 10 && value.length <= 15;
                const isDigitsOnly = /^\d+$/.test(value);
                return isValidLength && isDigitsOnly;
            }
        ),
    job: yup.string(),
    catLadaId: yup.string(),
    catLaborOldId: yup
        .object()
        .shape({
            value: yup
                .number()
                .required('Campo obligatorio')
                .test('is-non-zero', 'Campo obligatorio', function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        })
        .required()
        .typeError('Campo obligatorio'),
    monthlyIncome: yup
        .number()
        .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        })
        .nullable()
        .required('Campo obligatorio')
        .typeError('Debe ser un número')
        .test('is-not-zero', 'El ingreso mensual no puede ser cero', (value) => value !== 0),
});

export const schemaUploadLoans = yup.object().shape({
    name: yup.string().required('Campo obligatorio'),
    date: yup
        .string()
        .required('Campo obligatorio')
        .min(4, 'Deben ser 4 dígitos')
        .max(4, 'Deben ser 4 dígitos')
        .matches(/\d/g, {message: 'Ingrese una fecha valida'}),
    signature: yup
        .boolean()
        .oneOf([true], 'Por favor firme la solicitud')
        .required('Campo obligatorio'),
    authorized: yup
        .boolean()
        .oneOf([true], 'Por favor acepte la solicitud')
        .required('Campo obligatorio'),
    nameIncomeDocument: yup.string().required('Campo obligatorio'),
});

export const schemaTransactionLoans = yup.object().shape({
    catBank: yup.string().required(VALIDATION_MESSAGE.BANK),
    keyAccount: yup
        .string()
        .required(VALIDATION_MESSAGE.MANDATORY_FIELD)
        .matches(clabeRegEx, VALIDATION_MESSAGE.CLABE_TRANSACTIONS_LENGTH),
    terms: yup.boolean().oneOf([true], VALIDATION_MESSAGE.TERMS_CONDITIONS),
});
