import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ClickAwayListener} from '@mui/material';
import {IUserEmployee, IUserEmployeeList} from '@components/LocationsBranch/interfaces';
import {AddUserAvtar} from '@components/LocationsBranch/AddUserAvatar';
import {ADD_USER_MODAL} from '@components/LocationsBranch/constants';
import Warning from '@images/warning-blue.svg';

export const SearchUser = ({setDisplay, onSelectUser, data, error, loading}: IUserEmployeeList) => {
    return (
        <ClickAwayListener onClickAway={() => setDisplay(false)}>
            <ContainerFlex
                MarginTop="0.5rem"
                FlexDir="column"
                backG="#FFFFFF"
                Border="0.063 solid #F3F3F3"
                Radius="0.5rem"
                OverFlow="auto"
                BoxS="0 0.688rem 1.663rem 0 #0000000F"
                ZIndex="3"
            >
                {error ||
                    (data && data.length === 0 && !loading && (
                        <ContainerFlex Justify="start" Align="start" Gap="0.5rem" Padding="0.5rem">
                            <Image src={Warning} />
                            <Text
                                Color="#1D1E20"
                                FontSize="0.875rem"
                                FontWeight={500}
                                FontFamily="Nunito"
                            >
                                {ADD_USER_MODAL.NO_RESULTS}
                            </Text>
                        </ContainerFlex>
                    ))}
                {data &&
                    data.length > 0 &&
                    data.map((item: IUserEmployee) => {
                        return (
                            <ContainerFlex
                                key={item.employeeId}
                                Justify="start"
                                Align="start"
                                ColumnGap="0.5rem"
                                HBackground="#f0f0ff"
                                HBorderLeft="0.125rem solid #5a5aff"
                                FlexDir="Column"
                                Gap="0.5rem"
                                Padding="0.5rem"
                                Bb="0.063rem solid #F4F5F5"
                            >
                                <ContainerFlex
                                    key={item.employeeId}
                                    Justify="start"
                                    Align="start"
                                    ColumnGap="0.5rem"
                                    FlexDir="row"
                                    Gap="0.5rem"
                                    Padding="0 0.5rem"
                                >
                                    <AddUserAvtar item={item} onSelectUser={onSelectUser} />
                                </ContainerFlex>
                            </ContainerFlex>
                        );
                    })}
            </ContainerFlex>
        </ClickAwayListener>
    );
};
