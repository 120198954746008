import {
    GET_PAYMENT_TYPE,
    GET_PAYMENT_TYPE_SUCCESS,
    GET_PAYMENT_TYPE_ERROR,
} from '@components/CashFlow/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    paymentMethods: [],
    loading: false,
    error: false,
};

const getPaymentMethods = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_PAYMENT_TYPE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.payload.data.data,
            };
        case GET_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getPaymentMethods;
