const initialState = {
    success: false,
    token: '',
    menu: null,
    open: false,
};
const menu = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MENU_GLOBAL':
            return {
                ...state,
                menu: action.value,
            };
        case 'CHANGE_SIDEBAR':
            return {
                ...state,
                open: action.value,
            };
        default:
            return state;
    }
};
export default menu;
