import styled from '@emotion/styled';
import {IPropsPositionA, IPropsSearchInput, IpopsIcon} from '@Customer/interfacesStyles';

export const SearchInput = styled.div<IPropsSearchInput>`
    background: #ffffff 0% 0% no-repeat padding-box;
    background-color: ${(props) => props.BackG};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    position: ${(props) => props.Position};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginTop};
    right: ${(props) => props.Right};
    top: 0;
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    display: ${(props) => (props.Display ? props.Display : 'grid')};
    grid-template-columns: ${(props) => props.GridColumn};
    flex: ${(props) => props.Flex};
    align-items: center;
    padding: ${(props) => props.Padding};
    min-height: ${(props) => props.MinH};
    cursor: ${(props) => props.Cursor};
    border-color: ${(props) => (props.error ? '#ef4f55' : '#E8E9EA')};
    box-sizing: border-box;
    overflow: ${(props) => props.OverFlow};
    & > input {
        border: none;
        outline: none;
        font-size: ${(props) => props.FontSize};
        color: #050505;
        line-height: 16px;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: ${(props) => (props.InputPadding ? props.InputPadding : '0')};
        width: ${(props) => props.widthInput};
        ::placeholder {
            color: ${(props) => props.PlaceholderColor};
            line-height: ${(props) => props.PlaceholderLHeight};
            font-size: ${(props) => props.PlaceholderFontSize};
            font-weight: ${(props) => props.PlaceholderWeight};
        }
        :disabled {
            background: #ffffff;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            -webkit-box-shadow: ${(props) => props.WBoxShadow};
        }
    }
    &:hover {
        border-color: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowH};
    }
    &:focus-within {
        border-color: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowF};
    }

    figure > svg > path {
        fill: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
    }
`;
SearchInput.defaultProps = {
    Height: '40px',
    Position: 'relative',
    GridColumn: '85% 15%',
    Width: '100%',
    Padding: '0 1rem',
    PlaceholderColor: '#A7A9AC',
    PlaceholderLHeight: '1rem',
    PlaceholderFontSize: '0.875rem',
    PlaceholderWeight: '400',
    BorderH: '1px solid #5A5AFF',
    BorderF: '1px solid #5A5AFF',
    Border: '1px solid #E8E9EA',
    Right: '1rem',
    MinH: '40px',
    Radius: '32px',
    WBoxShadow: '0 0 0px 1000px #fff inset',
    FontSize: '1rem',
    widthInput: 'inherit',
};
export const Icon = styled.i<IpopsIcon>`
    cursor: pointer;
    background: ${(props) => props.Background};
`;
Icon.defaultProps = {
    Background: 'none',
};
export const PositionA = styled.div<IPropsPositionA>`
    position: absolute;
    top: ${(props) => props.top && props.top};
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
    bottom: ${(props) => props.bottom && props.bottom};
`;
export const UserView = styled.div`
    position: relative;
    height: 95%;
    width: 432px;
    margin-top: 0.1rem;
    display: grid;
    grid-template-columns: 12% 10% 50% 6%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0px;
`;
export const Name = styled.span`
    text-transform: capitalize;
    font: normal normal normal 12px/14px Work Sans;
    letter-spacing: 0px;
    color: #414042;
`;
