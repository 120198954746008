import {IHeader} from '@/components/RequestNewCatalogLevel/operational/interface';

export const CATALOG = {
    TITLE: 'Tecnología',
    ADD: 'Solicitar un nuevo catalogo',
    BACK: 'Atrás',
};

export const MOD_TEXT = {
    TITLE: 'Solicitud de nivel de artículo',
    INFO: 'Ingresa el tipo de artículo que deseas dar de alta.',
    MOD: 'Manual',
    LEVEL1: 'Nivel 1',
    LEVEL2: 'Nivel 2',
    LEVEL3: 'Nivel 3',
    LEVEL4: 'Nivel 4',
    LEVEL5: 'Nivel 5',
    ADD: 'Adjuntar un archivo',
    CLOSED: 'Calcelar',
    CORRECT: 'Solicitar',
    SELECT: 'Selecciona ',
    ERROR: 'Tipo o tamaño de archivo no válido.',
};

export const levelColors = ['#FFF', '#FAFAFA', '#FAFAFA', '#F4F5F5', '#E8E9EA'];

export const options = [
    'Fondear Caja',
    'Fondear Sucursal',
    'Devolver Excedentes',
    'Registar Movimiento',
    'Registrar gastos',
];

export const UPLOAD = {
    ADD: 'Sube tu documento arrastrándolo a esta área o haz clic para adjuntarlo.',
    RESET: 'Remplazar',
    MB: '10MB Max.',
};

export const HeaderTitleCatalog: IHeader[] = [
    {
        name: 'Categoria',
    },
    {
        name: 'No. de artículos',
    },
];
export const NAME_CAT = 'Categoría';
export const LEVEL = 'Nivel';
export const NO_ARTICLES = 'No. de artículos';
export const MAXIMUN_LEVEL = 5;
