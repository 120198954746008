import axios from 'axios';
import {
    POST_ADD_EXCESS_MOTIVE,
    POST_ADD_EXCESS_MOTIVE_SUCCESS,
    POST_ADD_EXCESS_MOTIVE_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

import process from 'process';
import {URL} from '@config/constants/index';

export const PostAddReturnExcess = () => ({
    type: POST_ADD_EXCESS_MOTIVE,
});

export const PostAddReturnExcessSuccess = (data) => ({
    type: POST_ADD_EXCESS_MOTIVE_SUCCESS,
    payload: data,
});

export const PostAddReturnExcesshError = (err) => ({
    type: POST_ADD_EXCESS_MOTIVE_ERROR,
    payload: err,
});

export const ReturnExcess = (token, dataRetunr) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(PostAddReturnExcess());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostAddExcessMotive}`,
                dataRetunr,
                {headers}
            );
            dispatch(PostAddReturnExcessSuccess(response.data));
        } catch (error) {
            dispatch(PostAddReturnExcesshError(error));
        }
    };
};
