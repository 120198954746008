import {
    GET_PRODUCTS_EXCHANGE,
    GET_PRODUCTS_EXCHANGE_ERROR,
    GET_PRODUCTS_EXCHANGE_SUCCESS,
    GET_PRODUCTS_ID,
    GET_PRODUCTS_CHECK,
    GET_PRODUCTS_COPY_CHECKED,
} from '@CreateDeadline/redux/types';
import {URL} from '@config/constants';

import axios, {AxiosResponse} from 'axios';
import StringUtils from '@GenericScripts/utils';
import {ParamsProducts} from '@CreateDeadline/interfaces';
import {Dispatch} from 'redux';

export const fetchProductsCopyCheck = (value: boolean[]) => {
    return {
        type: GET_PRODUCTS_COPY_CHECKED,
        productCheckCopy: value,
    };
};
export const fetchGetProductsCheck = (value: (boolean | number)[]) => {
    return {
        type: GET_PRODUCTS_CHECK,
        productsCheck: value,
    };
};

export const fetchGetProducts = () => {
    return {
        type: GET_PRODUCTS_EXCHANGE,
    };
};

export const fetchGetProductsSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_PRODUCTS_EXCHANGE_SUCCESS,
        value: result,
    };
};

export const fetchGetProductsError = () => {
    return {
        type: GET_PRODUCTS_EXCHANGE_ERROR,
    };
};

export const getProductsSimilar = (token: string, filters: ParamsProducts) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        const queryString = StringUtils.jsonToQueryString({
            ...filters,
        });
        dispatch(fetchGetProducts());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSimilarPromotions}${queryString}`,
                {headers}
            );
            dispatch(fetchGetProductsSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetProductsError());
        }
    };
};

export const getProductsId = (value: {allowedTermID: number}[]) => {
    return {
        type: GET_PRODUCTS_ID,
        productsId: value,
    };
};
