import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import Skeleton from '@mui/material/Skeleton';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, ContainerCellGrid, Text} from '@Shopify/Ecommerce/styles';
import {CREDITS_LISTING} from '@Shopify/ClientProfile/constants';
import PersoanlCreditItem from '@Shopify/ClientProfile/PersonalCreditItem';

const PersonalCreditsList = () => {
    const personalCredits = useSelector((state: RootState) => state.getPersonalCredit);

    const ColumnHeader = ({label}: {label: string}) => (
        <ContainerCellGrid
            Height="3rem"
            Padding="0.25rem 1rem"
            Justify="start"
            Align="center"
            Gap="0.25rem"
        >
            <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="700">
                {label}
            </Text>
        </ContainerCellGrid>
    );

    const renderLoading = () => (
        <ContainerFlex {...style.table.content}>
            {Array.from({length: 6}).map((_, index) => (
                <Skeleton key={index} animation="wave" />
            ))}
        </ContainerFlex>
    );

    const renderEmptyState = () => (
        <ContainerFlex
            Color="#2A2C2F"
            Justify="center"
            Padding="1.25rem"
            FontSize="0.875rem"
            FontWeight="500"
        >
            {CREDITS_LISTING.NO_RECORDS}
        </ContainerFlex>
    );

    const renderCreditsList = () =>
        personalCredits.creditsData.personalCredits.map((credit) => (
            <PersoanlCreditItem key={credit.creditId} credit={credit} />
        ));

    const renderContent = () => {
        if (personalCredits.loading) {
            return renderLoading();
        }

        if (!personalCredits?.creditsData?.personalCredits?.length) {
            return renderEmptyState();
        }

        return renderCreditsList();
    };

    return (
        <ContainerFlex
            Radius="0.5rem"
            FlexDir="column"
            Align="flex-start"
            Self="stretch"
            Border="1px solid #E8E9EA"
            backG="#FFF"
        >
            <ContainerFlex
                Display="grid"
                GridColumns="repeat(6, minmax(3.125rem, 1fr)) 2.5rem"
                Gap="1rem"
                Justify="start"
                Align="center"
                Bb="1px solid #E8E9EA"
            >
                <ColumnHeader label={CREDITS_LISTING.LOAN_NO} />
                <ColumnHeader label={CREDITS_LISTING.STATUS} />
                <ColumnHeader label={CREDITS_LISTING.QUANTITY_LOANED} />
                <ColumnHeader label={CREDITS_LISTING.INSTALLMENTS} />
                <ColumnHeader label={CREDITS_LISTING.INTEREST_RATE} />
                <ColumnHeader label={CREDITS_LISTING.ACTIONS} />
            </ContainerFlex>

            {renderContent()}
        </ContainerFlex>
    );
};

export default PersonalCreditsList;
