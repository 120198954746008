import {LEVEL_GROUP_TYPES as TYPE} from '@components/LocationsBranch/Redux/types';
import {Levels, SelectedLevel} from '@components/LocationsBranch/Redux/interfaces';
import {AppDispatch} from '@/config/store';
import {GetLevelsByLevelId} from '@components/LocationsBranch/Redux/Action/GetListLevelsByLevelId';

export const initGroups = (groups: number, groupType: string) => ({
    type: TYPE.INIT_GROUPS,
    payload: Array.from({length: groups}, (_, index) => ({
        group: index + 1,
        levels: [],
        selectedLevelId: 0,
        fetching: false,
    })),
    groupType,
});

export const updateGroup = (group: number, levels: Levels[], groupType: string) => ({
    type: TYPE.UPDATE_GROUP,
    groupLevels: levels,
    group,
    groupType,
});

export const selectGroupLevel = (group: number, selectedLevelId: number, groupType: string) => ({
    type: TYPE.SELECT_GROUP_LEVEL,
    selectedLevel: {levelId: selectedLevelId},
    group,
    groupType,
});

export const setCurrentSelectedLevelId = (groupType: string, selected: SelectedLevel) => ({
    type: TYPE.CURRENT_SELECTED_LEVELID,
    groupType,
    selectedLevel: selected,
});

export const fillingGroup = (group: number, groupType: string) => ({
    type: TYPE.FILLING_GROUP,
    group,
    groupType,
});

export const cancelGroupTypeSelection = (
    companyId: number,
    groupType: string,
    levelId: number,
    token: string
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(initGroups(3, groupType));
        await dispatch(GetLevelsByLevelId(companyId, groupType, 1, levelId, token));
    };
};
