import React from 'react';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {SearchArticle} from '@Articles/SearchArticle';
import {ARTICLE_SEARCH_TITLES} from '@Articles/constants';
import {DinamicLevels} from '@Articles/DinamicLevels';
import {RequestTable} from '@components/Articles/CatalogRequestTable/RequestsTable';

export const Articles = () => {
    return (
        <ContainerFlex Justify="start" Padding="1rem" FlexDir="column" Align="start" Gap="16px">
            <Text Color="#2A2C2F" FontWeight="500" FontSize="1.25rem">
                {ARTICLE_SEARCH_TITLES.TITLE}
            </Text>
            <ContainerFlex
                FlexDir="column"
                backG="#FAFAFA"
                Justify="start"
                Align="start"
                Height="auto"
                Padding="1rem"
                Radius="16px"
            >
                <SearchArticle />
                <DinamicLevels />
            </ContainerFlex>
            <RequestTable />
        </ContainerFlex>
    );
};
