import React from 'react';
import {DropDownMenu} from '@components/General/MenuAmazon/ReutilizableComponent/FloatMenu';
import {ISearchClient, ISearchLoanDropdown} from '@components/CreditsAndPawns/interfaces';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {useNavigate} from 'react-router-dom';
import {NO_RESULTS_FOUND} from '@components/CreditsAndPawns/constants';

const SearchLoanDropdown = ({
    onClearSearch,
    menuDisplayStatus,
    searchOptions,
    onClickOption,
    loading,
    notFoundMessage = NO_RESULTS_FOUND.SEARCH,
}: ISearchLoanDropdown) => {
    const navigate = useNavigate();

    return (
        <DropDownMenu
            OutSideClick={onClearSearch}
            MenuDisplayStatus={menuDisplayStatus && !loading}
            Values={[]}
            Position="absolute"
            Height="fit-content"
            Top="45px"
            Width="100%"
            Right="0"
            OverFlowY="auto"
            ZIndex="4"
            Valued={
                <>
                    {searchOptions && searchOptions.length > 0 ? (
                        searchOptions.map((data) => (
                            <ContainerFlex
                                key={data.applicationNumber}
                                FlexDir="column"
                                Align="flex-start"
                                Padding="0.5rem"
                                HBackground="#F0F0FF"
                                HBorderLeft="2px solid #5A5AFF"
                                Border="2px solid transparent"
                                Cursor="pointer"
                                onClick={() =>
                                    onClickOption
                                        ? onClickOption(data as ISearchClient)
                                        : navigate(`${data.applicationId}/CreditRequest`)
                                }
                            >
                                <Text
                                    Color="#2A2C2F"
                                    FontWeight="400"
                                    FontSize="1rem"
                                    LHeight="24px"
                                    Cursor="pointer"
                                >
                                    {data.applicationNumber}
                                </Text>
                                <Text
                                    Color="#54575C"
                                    FontWeight="400"
                                    FontSize="12px"
                                    Cursor="pointer"
                                >
                                    {data.clientName}
                                </Text>
                            </ContainerFlex>
                        ))
                    ) : (
                        <Text
                            Color="#54575C"
                            FontWeight="400"
                            FontSize="1rem"
                            wSpace="pre-wrap"
                            Margin="0.25rem 0.5rem"
                        >
                            {notFoundMessage}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default SearchLoanDropdown;
