import {Controller} from 'react-hook-form';
import React, {useEffect, useRef, useState} from 'react';
import {ContainerFlex, ContainerForm, Input, Text} from '@components/Shopify/Ecommerce/styles';
import {CREDIT_QUOTER} from '@components/CreditQuoter/constants';
import {Select} from '@Quoter/Steps/styles';
import {StyleNumericFormat} from '@Loan/styles';
import {IQuoteFieldsProps} from '@components/CreditQuoter/interfaces';
import {HiddenButton} from '@components/CreditQuoter/styles';
import {getAmountTermId, setFrequencyTermCreditId} from './Redux/Actions/GetAllCatFrequencyActions';
import {useDispatch, useSelector} from 'react-redux';
import {debounce} from '@mui/material';
import {RootState} from '@/config/store';

export const QuoteFields = ({
    control,
    dataFrequency,
    register,
    handleSubmit,
    onSubmit,
    hiddenSubmitRef,
    frequencyId,
    requestedAmount,
    setRequestedAmount,
}: IQuoteFieldsProps) => {
    const amountRef = useRef<HTMLInputElement>(null);
    const [periods, setPeriods] = useState<{label: string; value: number}[]>([]);
    const dispatch = useDispatch();
    const makeThreeBaseMonthsArray = (minPeriod: number, maxPeriod: number) => {
        setPeriods([]);
        for (let i = minPeriod; i <= maxPeriod; i += 3) {
            setPeriods((prev) => [...prev, {label: `${i}`, value: i}]);
        }
    };
    const selectedFrequency = () => {
        const activeFrequency = dataFrequency.find((f) => f.frequencyId === +frequencyId);
        if (!activeFrequency) return;
        dispatch(setFrequencyTermCreditId(activeFrequency.frequencyTermCreditsId));
        const hasSamePeriods = activeFrequency.minimumPeriod === activeFrequency.maximumPeriod;
        const periodArrayByThreeMonths = !hasSamePeriods
            ? makeThreeBaseMonthsArray(activeFrequency.minimumPeriod, activeFrequency.maximumPeriod)
            : [];
        return periodArrayByThreeMonths;
    };

    useEffect(() => {
        if (dataFrequency.length > 0 && frequencyId !== 0) selectedFrequency();
    }, [frequencyId, dataFrequency]);
    const showDropdown = frequencyId !== 0 && periods.length > 0;
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const debouncedOnChange = debounce((val) => {
        setRequestedAmount(val);
        if (val === 0 || !val) return;
        dispatch(getAmountTermId(token, val, 2));
    }, 500);

    return (
        <>
            <ContainerForm Gap="1rem" FlexDir="column" onSubmit={handleSubmit(onSubmit)}>
                <ContainerFlex FlexDir="column" Gap="0.25rem" Align="start">
                    <Text FontWeight="700" Color="#2A2C2F">
                        {CREDIT_QUOTER.FIELDS.AMOUNT}
                    </Text>
                    <StyleNumericFormat
                        autoComplete="off"
                        type="text"
                        prefix="$ "
                        decimalSeparator="."
                        thousandSeparator={true}
                        decimalScale={0}
                        allowNegative={false}
                        value={requestedAmount}
                        width="180px"
                        padding="0.75rem 1rem"
                        textAlign="center"
                        fontSize="2rem"
                        FontFamily="Nunito"
                        FontWeight="700"
                        Color="#2A2C2F"
                        radius="1rem"
                        getInputRef={amountRef}
                        onValueChange={(values) => {
                            debouncedOnChange(values.floatValue);
                        }}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="0.25rem" Align="start" Justify="center">
                    <ContainerFlex Justify="start" Height="max-content">
                        <Text FontWeight="700" Color="#2A2C2F" FontSize="1rem">
                            {CREDIT_QUOTER.FIELDS.FREQUENCY}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Gap="1rem" Justify="start" Height="40px">
                        {dataFrequency.length > 0 &&
                            dataFrequency.map((frequency) => (
                                <ContainerFlex
                                    Gap="0.5rem"
                                    Width="max-content"
                                    key={frequency.frequencyId}
                                >
                                    <Input
                                        {...register('frequencyId')}
                                        type="radio"
                                        Width="24px"
                                        Height="24px"
                                        value={frequency.frequencyId}
                                    />
                                    <Text Color="#2A2C2F" Width="max-content">
                                        {frequency.nameFrequency}
                                    </Text>
                                </ContainerFlex>
                            ))}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="0.25rem" Align="start" MinH="65px">
                    {showDropdown && (
                        <>
                            <ContainerFlex Justify="start" Height="max-content">
                                <Text FontWeight="700" Color="#2A2C2F" FontSize="1rem">
                                    {CREDIT_QUOTER.FIELDS.PERIODS}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex>
                                <Controller
                                    control={control}
                                    name="paymentTerms"
                                    render={({field: {...field}}) => (
                                        <Select
                                            {...field}
                                            noOptionsMessage={() => 'Sin opciones'}
                                            options={periods}
                                            placeholder="Selecciona un plazo"
                                            isSearchable={false}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                        </>
                    )}
                </ContainerFlex>

                <HiddenButton type="submit" ref={hiddenSubmitRef}>
                    {'Submit'}
                </HiddenButton>
            </ContainerForm>
        </>
    );
};
