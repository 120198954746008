export const incomeData = [
    {year: 2023, month: 'Ago', income: 240},
    {year: 2023, month: 'Sep', income: 260},
    {year: 2023, month: 'Oct', income: 250},
    {year: 2023, month: 'Nov', income: 280},
    {year: 2023, month: 'Dic', income: 260},
    {year: 2024, month: 'Ene', income: 310},
    {year: 2024, month: 'Feb', income: 290},
    {year: 2024, month: 'Mar', income: 330},
    {year: 2024, month: 'Abr', income: 320},
    {year: 2024, month: 'May', income: 340},
    {year: 2024, month: 'Jun', income: 300},
    {year: 2024, month: 'Jul', income: 360},
    {year: 2024, month: 'Ago', income: 350},
];
