import {
    GET_EXPENSES,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSES_ERROR,
} from '@components/CashFlow/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {getTransactionError} from '@components/CashFlow/Redux/Actions/GetCashTransaction';

export const getExpense = () => {
    return {
        type: GET_EXPENSES,
    };
};
export const getExpenseSuccess = (result: AxiosResponse) => {
    return {
        type: GET_EXPENSES_SUCCESS,
        payload: result,
    };
};
export const getExpenseError = (error: AxiosError) => {
    return {
        type: GET_EXPENSES_ERROR,
        error: error,
    };
};

export function getExpensesAction(
    branchId: number,
    search: string,
    pageSize: number,
    pageNumber: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getExpense());
        const params = {
            branchId: branchId.toString(),
            search: search,
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetExpenses}?${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getExpenseSuccess(response));
        } catch (error) {
            dispatch(getTransactionError(error as AxiosError));
        }
    };
}
