import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    UPDATE_STATUS_LEVEL_ERROR,
    UPDATE_STATUS_LEVEL_FINISH,
    UPDATE_STATUS_LEVEL_START,
    UPDATE_STATUS_LEVEL_SUCCESS,
} from '@TypesOrgCreation/types';
import {updatePageNumber} from '@ActionsOrgCreation/CheckedState';
import {AppDispatch} from '@/config/store';
import {updatingOrg} from '@SubContainerOrgCreation/interface';

export const fetchPostUpdateStatusLevelStart = () => {
    return {
        type: UPDATE_STATUS_LEVEL_START,
    };
};
export const fetchPostUpdateStatusLevelSuccess = (result: AxiosResponse) => {
    return {
        type: UPDATE_STATUS_LEVEL_SUCCESS,
        value: result.data,
    };
};
export const fetchPostUpdateStatusLevelError = () => {
    return {
        type: UPDATE_STATUS_LEVEL_ERROR,
    };
};
export const fetchPostUpdateStatusLevelFinish = () => {
    return {
        type: UPDATE_STATUS_LEVEL_FINISH,
    };
};

export function upStatusOrg(token: string, data: updatingOrg) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostUpdateStatusLevelStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpStatusOrganization}`,
                data,
                {headers}
            );
            await dispatch(fetchPostUpdateStatusLevelSuccess(response));
            dispatch(updatePageNumber(1));
        } catch (err) {
            dispatch(fetchPostUpdateStatusLevelError());
        } finally {
            dispatch(fetchPostUpdateStatusLevelFinish());
        }
    };
}
