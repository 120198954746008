import axios, {AxiosResponse} from 'axios';
import {
    PUT_BRANCH_SCHEDULE,
    PUT_BRANCH_SCHEDULE_ERROR,
    PUT_BRANCH_SCHEDULE_FINISH,
    PUT_BRANCH_SCHEDULE_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IScheduleUpdateData, IShowItem} from '@/components/Branches/interfaces';
import {GetBranchSchedule} from '@/components/Branches/ManageBranch/Redux/Actions/GetBranchSchedule';

export const fetchPutBranchScheduleStart = () => {
    return {
        type: PUT_BRANCH_SCHEDULE,
    };
};
export const fetchPutBranchScheduleSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_BRANCH_SCHEDULE_SUCCESS,
        payload: result,
    };
};
export const fetchPutBranchScheduleError = () => {
    return {
        type: PUT_BRANCH_SCHEDULE_ERROR,
    };
};
export const fetchPutBranchScheduleFinish = () => {
    return {
        type: PUT_BRANCH_SCHEDULE_FINISH,
    };
};

export function PutBranchSchedule(
    data: IScheduleUpdateData,
    token: string,
    setShowOpenSchedule: (e: boolean) => void,
    setShowItems: (e: IShowItem) => void,
    showItems: IShowItem,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutBranchScheduleStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutBranchSchedule}`,
                data,
                {headers}
            );
            dispatch(fetchPutBranchScheduleSuccess(response.data));
            setShowOpenSchedule(false);
            setShowItems({
                ...showItems,
                schedule: false,
            });
        } catch (error) {
            dispatch(fetchPutBranchScheduleError());
        } finally {
            dispatch(fetchPutBranchScheduleFinish());
            dispatch(GetBranchSchedule(idBranch, token));
        }
    };
}
