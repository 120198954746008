import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import ReactPaginate from 'react-paginate';

import {HeadTableProductsShopify} from '@Products/HeadTableProductsShopify';
import {
    getProductsShopify,
    getProductsShopifyDetails,
} from '@ActionsProducts/ProductsShopifyActions';
import {RowProductsShopifyTable} from '@Products/RowProductsShopifyTable';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {Table} from '@DeadLines/styles';

export const ProductsShopify = () => {
    const dispatch = useDispatch();
    const {handleSubmit, register} = useForm({
        defaultValues: {
            searchsku: '',
        },
    });

    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const productsshopify = useSelector((state) => {
        return state.productsshopify;
    });
    const filtershopifyproducts = '';
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            const width = document.body.clientWidth;
            setWidth(width);
        };

        updateWidth();

        window.addEventListener('resize', updateWidth);
        if (token) {
            const getProducts = () => dispatch(getProductsShopify('', 10, 0, token));
            getProducts();
            if (productsshopify.productsshopify !== null) {
                productsshopify.productsshopify.map((recolectionitems) =>
                    dispatch(getProductsShopifyDetails(recolectionitems.sku, token))
                );
            }
        }
    }, [dispatch, productsshopify.productsshopify, token]);
    const handlePageClickHistory = (data) => {
        let selected = data.selected;
        dispatch(getProductsShopify(filtershopifyproducts, 10, selected, token));
    };

    const handleUserKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit);
        }
    };

    const onSubmit = (values) => {
        dispatch(getProductsShopify(values.searchsku, 10, productsshopifypage, token));
    };
    const productsshopifycount = useSelector((state) => {
        return state.productsshopify.productsshopifycount;
    });
    const productsshopifypage = useSelector((state) => {
        return state.productsshopify.productsshopifyPageIndex;
    });
    return (
        <div className="w-90 mx-auto pt-4 ">
            <span className="title ">Busca y crea un artículo para publicar en Shopify</span>
            <div className="w-100  pt-4 mr-3">
                <form
                    className="flex col-md-4  pt-md-5 pt-xs-3 pl-0 pr-0"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <input
                        className="input-search"
                        {...register('searchsku', {})}
                        onKeyPress={handleUserKeyPress}
                    />
                    <button className="btn-search" type="submit">
                        {' '}
                        <i className="material-icons">search</i>
                    </button>
                </form>
                {productsshopify.productsshopify !== null ? (
                    <Table className="mt-4  mx-auto">
                        <HeadTableProductsShopify width={width} />
                        {productsshopify.productsshopify.map((recolectionitems) => (
                            <RowProductsShopifyTable
                                width={width}
                                key={recolectionitems.sku}
                                {...recolectionitems}
                            />
                        ))}
                    </Table>
                ) : (
                    <div className="pagination">
                        <LoadingGeneral />
                    </div>
                )}

                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={productsshopifycount / 10}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={productsshopifypage}
                    onPageChange={handlePageClickHistory}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};
