import {
    STEP_PAYMENT_SELECTED,
    ACTION_STATE_SELECTED,
} from '@components/QuickPayment/Redux/Types/Types';
import {GetPaymentProps} from '@components/QuickPayment/interfaces';

const initialState: GetPaymentProps = {
    stepPayment: 1,
    actionState: 1,
};

const GetActionPayment = (
    state = initialState,
    action: {
        type: string;
        stepSelected: number;
        actionState: number;
    }
): GetPaymentProps => {
    switch (action.type) {
        case STEP_PAYMENT_SELECTED:
            return {
                ...state,
                stepPayment: action.stepSelected,
            };
        case ACTION_STATE_SELECTED:
            return {
                ...state,
                actionState: action.actionState,
            };
        default:
            return state;
    }
};

export default GetActionPayment;
