import React, {useEffect, useState} from 'react';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {ImgLocation} from '@Locations/AdminLocations/CreateLocation/style';
import arrow from '@images/arrows.svg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {getTreeComponent} from '@General/BranchTree/Redux/Actions/getOrganizationsAction';
import {PropsModalOrganizations} from '@components/CreateUsers/Interface';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';

export const ModalOrganizations = ({
    setModalStateOrg,
    modalStateOrg,
    setValue,
    trigger,
    levelsBranch,
}: PropsModalOrganizations) => {
    const dispatch: AppDispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);
    const saveChanges = () => {
        const sendData = branchesData.map((data) => ({
            branchId: data.idBranch,
            nameBranch: data.branchName,
        }));
        setValue('levelBranch', sendData);
        setModalStateOrg(false);
        trigger('levelBranch');
    };

    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));
    }, [companyId, dispatch, levelsBranch, token]);

    return (
        <Modal
            modalState={modalStateOrg}
            changeModalState={() => {}}
            titleModalState={true}
            Width="31.25rem"
            Height="34.625rem"
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            BorderRadius="4px"
            background="none"
            FlexDirMod="column"
            Color="#414042"
        >
            <ContainerFlex FlexDir="column">
                <Text
                    BorderBt="solid 1px #35cf44"
                    Width="100%"
                    Padding=" 24px 16px"
                    FontSize="1.25rem"
                    Color="#35cf44"
                    FontWeight="normal"
                    Self="start"
                >
                    Seleccionar nivel organizacional
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    Padding="24px"
                    Justify="start"
                    Gap="32px"
                    Overflow="auto"
                    Align="start"
                >
                    <Text FontSize="0.875rem" Color="#414042" MinH="14px" Height="14px">
                        Selecciona el nivel organizacional
                    </Text>
                    {TreeComponent.data && TreeComponent.data.length ? (
                        <TreeBranch
                            checked={checked}
                            branchesId={branchesId}
                            levelsId={levelsId}
                            branchesData={branchesData}
                            setChecked={setChecked}
                            setBranch={setBranch}
                            setLevel={setLevel}
                            setBranchData={setBranchData}
                            isEdit={false}
                        />
                    ) : (
                        <></>
                    )}
                </ContainerFlex>
                <ContainerFlex Width="31.25rem" Height="5.5rem">
                    <Text
                        MarginLeft="65px"
                        Cursor="pointer"
                        FontSize="0.875rem"
                        Color="#35cf44"
                        FontWeight="normal"
                        MarginRight="8px"
                    >
                        Crear nivel
                    </Text>
                    <ImgLocation Cursor="pointer" Width="12px" src={arrow} />
                    <ButtonGeneral
                        width="5.813rem"
                        height="40px"
                        text="Cancelar"
                        type="button"
                        FontSize="0.875rem"
                        hColor="#35cf44"
                        transform=""
                        border="none"
                        margin="0 0 0 32px;"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5)"
                        clic={() => setModalStateOrg(false)}
                    />
                    <ButtonGeneral
                        width="10.5rem"
                        text="Guardar y continuar"
                        FontSize="0.875rem"
                        transform=""
                        hBorder="#35cf44"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        margin="0 24px 0 24px;"
                        disabled={branchesData.length === 0}
                        clic={() => saveChanges()}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
