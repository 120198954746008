import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@Shopify/Endeavors/styles';
import {pledgeVoult} from '@Locations/interfaces';

export default function VoultProucts(props: {pledgeVoult: pledgeVoult}): JSX.Element {
    const {pledgeVoult} = props;
    return (
        <>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Bóveda
                </Text>
                <Text FontSize="0.875rem">{pledgeVoult.voultName}</Text>
            </TableItem>
            <TableItem FlexDir="Column" Align="start">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Fila
                </Text>
                <Text FontSize="0.875rem">{pledgeVoult.rowName}</Text>
            </TableItem>
            <TableItem FlexDir="Column">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Cajón
                </Text>
                <Text FontSize="0.875rem">{pledgeVoult.drawerNumber}</Text>
            </TableItem>
            <TableItem FlexDir="Column">
                <Text FontSize="0.75rem" Color="#CBCED1">
                    Pichonera
                </Text>
                <Text FontSize="0.875rem">{pledgeVoult.squabNumber}</Text>
            </TableItem>
        </>
    );
}
