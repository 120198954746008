import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {Icons} from '@BranchInformation/style';

const InformationContainer = (props: {icon: string}) => {
    return (
        <ContainerFlex Cursor="pointer" Justify="flex-end" Width="5%">
            <Icons
                className="material-icons"
                FontSize={props.icon === 'edit' ? '1.2rem' : '1.5rem'}
            >
                {props.icon}
            </Icons>
        </ContainerFlex>
    );
};

export default InformationContainer;
