import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';

import {CLIENT_PROFILE} from '@Shopify/ClientProfile/Redux/types';

export const clientProfileIdStart = () => ({type: CLIENT_PROFILE.INITIAL_DATA});
export const clientProfileIdSuccess = (result: AxiosResponse) => ({
    type: CLIENT_PROFILE.PROFILE_SUCCESS,
    clientProfile: result.data,
});
export const clientProfileIdError = (error: AxiosError) => ({
    type: CLIENT_PROFILE.PROFILE_ERROR,
    error: error,
});
export const clientProfileId = (token: string, params: {clientId: number}) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    const queryString = StringUtils.jsonToQueryString({...params});

    return async (dispatch: AppDispatch) => {
        dispatch(clientProfileIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ClientProfile}${queryString}`,
                {headers}
            );
            dispatch(clientProfileIdSuccess(response.data));
        } catch (error) {
            dispatch(clientProfileIdError(error as AxiosError));
        }
    };
};

export const saveIdClient = (idClient: number) => ({
    type: CLIENT_PROFILE.ID_CLIENT,
    idClient: idClient,
});
