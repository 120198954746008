import {
    POST_CREATE_FUNDING,
    POST_CREATE_FUNDING_SUCCESS,
    POST_CREATE_FUNDING_ERROR,
} from '@/components/CashFlowActions/Redux/Types/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const sendCashFunding = (state = initialState, action) => {
    switch (action.type) {
        case POST_CREATE_FUNDING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case POST_CREATE_FUNDING_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: null,
            };
        case POST_CREATE_FUNDING_ERROR:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
