import {IfinanceCardData} from '@components/CashFlow/interfaces';

export type FinanceRecord = {
    typeEffective: string;
    soldOut: number;
    details: {
        total: number;
        totalHeader: string;
        text: string;
        amounts: IAmount[];
    };
};
interface IAmount {
    typeAmount: string;
    amount: number;
}

export interface AllData {
    boxSale: FinanceRecord[];
    inOutSale: FinanceRecord[];
}

interface IHeader {
    name: string;
}

export const financeCards = (financeCardsData: IfinanceCardData) => {
    const usersData = financeCardsData.detailsCashTotalBox.usersCashBox.map((i) => ({
        typeAmount: i.userName,
        amount: i.cashBox,
    }));
    const financeData = {
        boxSale: [
            {
                typeEffective: 'Efectivo en sucursal',
                soldOut: financeCardsData?.cashTotalBranch,
                details: {
                    total: financeCardsData.detailsTotalBranch?.totalBranch,
                    totalHeader: 'Total en sucursal',
                    text: 'El total de efectivo en sucursal comprende el dinero situado en los puntos de manejo de efectivo activos, como cajas registradoras, bolsillos, o cualquier otro método de almacenamiento, además del dinero almacenado en fuentes de reserva, como bóvedas o cajas fuertes.',
                    amounts: [
                        {
                            typeAmount: 'Efectivo en cajas',
                            amount: financeCardsData?.detailsTotalBranch?.totalBoxes,
                        },
                        {
                            typeAmount: 'Fondos en caja',
                            amount: financeCardsData.detailsTotalBranch.fundsReserve,
                        },
                    ],
                },
            },
            {
                typeEffective: 'Efectivo en cajas',
                soldOut: financeCardsData.cashTotalBox,
                details: {
                    total: financeCardsData.detailsCashTotalBox.totalBoxes,
                    totalHeader: 'Total en cajas',
                    text: 'El total de efectivo en cajas abarca el dinero presente en los puntos de manejo de efectivo de la sucursal, ya sean cajas registradoras, bolsillos, o cualquier otro método de almacenamiento utilizado.',
                    amounts: usersData,
                },
            },
        ],
        inOutSale: [
            {
                typeEffective: 'Total ingresos',
                soldOut: financeCardsData.cashTotalIncome,
                details: {
                    total: financeCardsData.cashTotalIncome,
                    totalHeader: 'Total ingresos',
                    text: 'El total de ingresos comprende todas las distintas fuentes de ingreso de dinero, sin importar el método de pago utilizado. ',
                    amounts: [
                        {
                            typeAmount: 'Efectivo',
                            amount: financeCardsData.detailsCashTotalIncome.cash,
                        },
                        {
                            typeAmount: 'Tarjeta de credito',
                            amount: financeCardsData.detailsCashTotalIncome.creditCard,
                        },
                        {
                            typeAmount: 'Tarjeta de debito',
                            amount: financeCardsData.detailsCashTotalIncome.debitCard,
                        },
                        {
                            typeAmount: 'Transferencia bancaria',
                            amount: financeCardsData.detailsCashTotalIncome.bankTransfer,
                        },
                        {
                            typeAmount: 'Monedero digital',
                            amount: financeCardsData.detailsCashTotalIncome.digitalWallet,
                        },
                    ],
                },
            },
            {
                typeEffective: 'Total de egresos',
                soldOut: financeCardsData.cashTotalExpenses,
                details: {
                    total: financeCardsData.cashTotalExpenses,
                    totalHeader: 'Total egresos',
                    text: 'El total de egresos incluye todas las distintas formas de salida de dinero, independientemente del método de pago.',
                    amounts: [
                        {
                            typeAmount: 'Efectivo',
                            amount: financeCardsData.detailsCashTotalExpenses.cash,
                        },
                        {
                            typeAmount: 'Transferencia bancaria',
                            amount: financeCardsData.detailsCashTotalExpenses.bankTransfer,
                        },
                        {
                            typeAmount: 'Monedero digital',
                            amount: financeCardsData.detailsCashTotalExpenses.digitalWallet,
                        },
                    ],
                },
            },
            {
                typeEffective: 'Ingresos netos',
                soldOut: financeCardsData.cashNetIncome,
                details: {
                    total: financeCardsData.cashNetIncome,
                    totalHeader: 'Ingresos netos',
                    text: 'Los ingresos netos se calculan considerando el total de ingresos recibidos por la sucursal y restando el total de egresos originados de diversas fuentes.',
                    amounts: [
                        {
                            typeAmount: 'Total ingresos',
                            amount: financeCardsData.detailsCashNetIncome.totalIncome,
                        },
                        {
                            typeAmount: 'Total egresos',
                            amount: financeCardsData.detailsCashNetIncome.totalExpenses,
                        },
                    ],
                },
            },
        ],
    };
    return financeData;
};

export const HeaderTitles: IHeader[] = [
    {
        name: 'Usuario',
    },
    {
        name: 'Rol',
    },
    {
        name: 'Estado de caja',
    },
    {
        name: '',
    },
    {
        name: 'Ingresos',
    },
    {
        name: 'Salidas',
    },
    {
        name: 'Total contado',
    },
];

interface BoxInfo {
    userName: string;
    Rol: string;
    boxState: string;
    in: number;
    out: number;
    total: number;
    isClose: boolean;
}
interface BoxInfoTransfer {
    transType: string;
    box: string;
    sessionId: string;
    paymentMethod: string;
    date: string;
    hour: string;
    id: string;
    isGain: boolean;
    balance: number;
}
export const boxDataTransfer: BoxInfoTransfer[] = [
    {
        transType: 'Cancelación de empeño',
        box: 'Juan Antonio Ruiz',
        sessionId: 'S1233',
        paymentMethod: 'Efectivo',
        date: '09/08/2024',
        hour: '02:34 pm',
        id: 'C0007',
        isGain: true,
        balance: 500,
    },
    {
        transType: 'Refrendo',
        box: 'Ana María López',
        sessionId: 'S1234',
        paymentMethod: 'Efectivo',
        date: '10/08/2024',
        hour: '10:15 am',
        id: 'C0008',
        isGain: true,
        balance: 300,
    },
    {
        transType: 'Empeño',
        box: 'Carlos Hernández',
        sessionId: 'S1235',
        paymentMethod: 'Tarjeta de crédito',
        date: '11/08/2024',
        hour: '11:20 am',
        id: 'C0009',
        isGain: false,
        balance: 1000,
    },
    {
        transType: 'Refrendo',
        box: 'Marta Gómez',
        sessionId: 'S1236',
        paymentMethod: 'Efectivo',
        date: '12/08/2024',
        hour: '09:45 am',
        id: 'C0010',
        isGain: true,
        balance: 200,
    },
    {
        transType: 'Cancelación de empeño',
        box: 'Luis Ramírez',
        sessionId: 'S1237',
        paymentMethod: 'Efectivo',
        date: '13/08/2024',
        hour: '03:30 pm',
        id: 'C0011',
        isGain: true,
        balance: 400,
    },
    {
        transType: 'Empeño',
        box: 'José Fernández',
        sessionId: 'S1238',
        paymentMethod: 'Tarjeta de crédito',
        date: '14/08/2024',
        hour: '12:15 pm',
        id: 'C0012',
        isGain: false,
        balance: 700,
    },
    {
        transType: 'Refrendo',
        box: 'Laura Martínez',
        sessionId: 'S1239',
        paymentMethod: 'Efectivo',
        date: '15/08/2024',
        hour: '02:00 pm',
        id: 'C0013',
        isGain: true,
        balance: 350,
    },
    {
        transType: 'Empeño',
        box: 'Diego Soto',
        sessionId: 'S1240',
        paymentMethod: 'Tarjeta de crédito',
        date: '16/08/2024',
        hour: '01:10 pm',
        id: 'C0014',
        isGain: false,
        balance: 800,
    },
    {
        transType: 'Cancelación de empeño',
        box: 'Carmen Delgado',
        sessionId: 'S1241',
        paymentMethod: 'Efectivo',
        date: '17/08/2024',
        hour: '04:20 pm',
        id: 'C0015',
        isGain: true,
        balance: 600,
    },
    {
        transType: 'Refrendo',
        box: 'Marcos Ruiz',
        sessionId: 'S1242',
        paymentMethod: 'Efectivo',
        date: '18/08/2024',
        hour: '05:30 pm',
        id: 'C0016',
        isGain: true,
        balance: 250,
    },
    {
        transType: 'Empeño',
        box: 'Clara Torres',
        sessionId: 'S1243',
        paymentMethod: 'Tarjeta de crédito',
        date: '19/08/2024',
        hour: '03:45 pm',
        id: 'C0017',
        isGain: false,
        balance: 900,
    },
    {
        transType: 'Cancelación de empeño',
        box: 'Roberto López',
        sessionId: 'S1244',
        paymentMethod: 'Efectivo',
        date: '20/08/2024',
        hour: '10:50 am',
        id: 'C0018',
        isGain: true,
        balance: 450,
    },
    {
        transType: 'Refrendo',
        box: 'Eva Martínez',
        sessionId: 'S1245',
        paymentMethod: 'Efectivo',
        date: '21/08/2024',
        hour: '12:30 pm',
        id: 'C0019',
        isGain: true,
        balance: 320,
    },
];

export const boxData: BoxInfo[] = [
    {
        userName: 'Juan Carlos',
        Rol: 'gerente',
        boxState: 'Cerrada',
        in: 5000.0,
        out: 200.0,
        total: 4800.0,
        isClose: true,
    },
    {
        userName: 'María López',
        Rol: 'subgerente',
        boxState: 'Abierta',
        in: 3500.0,
        out: 300.0,
        total: 3200.0,
        isClose: true,
    },
    {
        userName: 'Pedro Martínez',
        Rol: 'cajero',
        boxState: 'Cerrada',
        in: 4200.0,
        out: 150.0,
        total: 4050.0,
        isClose: true,
    },
    {
        userName: 'Lucía Fernández',
        Rol: 'supervisor',
        boxState: 'Abierta',
        in: 2800.0,
        out: 100.0,
        total: 2700.0,
        isClose: false,
    },
    {
        userName: 'Carlos García',
        Rol: 'gerente',
        boxState: 'Cerrada',
        in: 5400.0,
        out: 250.0,
        total: 5150.0,
        isClose: false,
    },
    {
        userName: 'Ana Torres',
        Rol: 'subgerente',
        boxState: 'Abierta',
        in: 3100.0,
        out: 180.0,
        total: 2920.0,
        isClose: true,
    },
    {
        userName: 'Roberto Díaz',
        Rol: 'cajero',
        boxState: 'Cerrada',
        in: 4600.0,
        out: 220.0,
        total: 4380.0,
        isClose: true,
    },
    {
        userName: 'Sofía Gómez',
        Rol: 'supervisor',
        boxState: 'Abierta',
        in: 2950.0,
        out: 130.0,
        total: 2820.0,
        isClose: true,
    },
    {
        userName: 'Fernando Pérez',
        Rol: 'gerente',
        boxState: 'Cerrada',
        in: 5100.0,
        out: 270.0,
        total: 4830.0,
        isClose: false,
    },
    {
        userName: 'Patricia Herrera',
        Rol: 'subgerente',
        boxState: 'Abierta',
        in: 3300.0,
        out: 210.0,
        total: 3090.0,
        isClose: true,
    },
    {
        userName: 'Miguel Álvarez',
        Rol: 'cajero',
        boxState: 'Cerrada',
        in: 4800.0,
        out: 190.0,
        total: 4610.0,
        isClose: true,
    },
];

export const TITLES = {
    VIEW: 'Ver detalle',
    USERS: 'Usuarios',
    TANSACCITONS: 'Transacciones',
    PAYS: 'Gastos',
    PENDINGS: 'Pendientes',
    CASH_FLOW: 'Flujo de efectivo',
    DATE: '12 de octubre, 2023',
    ACTIONS: 'Acciones',
};

export const TITLES_FILTER = {
    FILTER: 'Filtros',
    RESTORE: 'Restaurar',
    MOV: 'Movimiento',
    TYPE_TRANS: 'Tipo de transacción',
    CANCEL: 'Cancelar',
    APPLY: 'Aplicar',
    FILTER_DATE: 'Filtrar por fecha',
    DATE: 'Fecha',
    FOMAT_DATE: 'DD/MM/AAAA',
    FILTER_HOUR: 'Filtrar por hora',
    FROM: 'Desde',
    TO: 'Hasta',
};

export const HeaderTitleTransaction: IHeader[] = [
    {
        name: 'Tipo transacción',
    },
    {
        name: 'Caja / Fuente',
    },
    {
        name: 'ID Sesión',
    },
    {
        name: 'Medio de pago',
    },
    {
        name: 'Fecha',
    },
    {
        name: 'Hora',
    },
    {
        name: 'ID',
    },
    {
        name: 'Movimientos',
    },
];
