import {
    PUT_BRANCH_SCHEDULE_DISCHARGE,
    PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
    PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateDischarge: false,
};

const PutScheduleDischarge = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_BRANCH_SCHEDULE_DISCHARGE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_BRANCH_SCHEDULE_DISCHARGE_SUCCESS:
            return {
                ...state,
                updateDischarge: action.payload.data,
            };
        case PUT_BRANCH_SCHEDULE_DISCHARGE_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_BRANCH_SCHEDULE_DISCHARGE_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutScheduleDischarge;
