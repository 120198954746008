import React, {useState} from 'react';
import {ContainerFlex, Text, Modal, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {MOD_TEXT} from '@/components/RequestNewCatalogLevel/operational/constant';
import {CustomSelect} from '@/components/RequestNewCatalogLevel/CustomSelect';
import {useForm, Controller} from 'react-hook-form';
import {FormData} from '@/components/RequestNewCatalogLevel/operational/interface';
import UploadFile from '@/components/RequestNewCatalogLevel/uploadFile';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import cuestions from '@images/CuestionsIcone.svg';
import {useDispatch, useSelector} from 'react-redux';
import {PostNewLevel} from '@/components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {RootState} from '@/config/store';
import {Input} from '@mui/material';
import {
    BrandLevel,
    FamilyLevel,
    SubfamilyLevel,
} from '@components/Articles/Redux/Actions/ArticlesLevel';

interface ModalFundingProps {
    isOpen: boolean;
    closeModal: () => void;
}
export const ModalRequestNewLevel = ({isOpen, closeModal}: ModalFundingProps) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch = useDispatch();
    const {handleSubmit, control, getValues, reset} = useForm<FormData>();
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [currentLevel, setCurrentLevel] = useState<number | null>(null);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    interface GroupItem {
        Group: {data: [{id: number; nameGroup: string; status: boolean; iconUrl: string}]};
        Family: {data: [{id: number; nameFamily: string}]};
        Subfamily: {data: [{id: number; nameSubFamily: string}]};
        Brand: {data: [{id: number; nameBrand: string}]};
    }

    const levelOne: GroupItem = useSelector((state: RootState) => state.ArticleLevel);
    const levelOneOptions = levelOne?.Group?.data?.map((item) => ({
        value: String(item.id),
        label: item.nameGroup,
    }));
    const levelTwoOptions = levelOne?.Family?.data?.map((item) => ({
        value: String(item.id),
        label: item.nameFamily,
    }));
    const levelThreeOptions = levelOne?.Subfamily?.data?.map((item) => ({
        value: String(item.id),
        label: item.nameSubFamily,
    }));
    const levelFourOptions = levelOne?.Brand?.data?.map((item) => ({
        value: String(item.id),
        label: item.nameBrand,
    }));

    const handleLevelTwoOptions = (value: string, onChange: (value: string) => void) => {
        onChange(value);
        reset(
            {
                Category: '',
                Manufacturer: '',
                Brand: '',
            },
            {keepDirty: true, keepValues: true}
        );
        onChange(value);

        const branchId = companyId?.toString();
        if (branchId) {
            dispatch(FamilyLevel(token, branchId, String(value)));
        }
    };

    const handleLevelThreeOptions = (value: string, onChange: (value: string) => void) => {
        onChange(value);
        setCurrentLevel(2);
        reset(
            {
                Manufacturer: '',
                Brand: '',
            },
            {keepDirty: true, keepValues: true}
        );
        const branchId = companyId?.toString();
        if (branchId) {
            dispatch(SubfamilyLevel(token, branchId, String(value)));
        }
    };
    const handleLevelFourOptions = (value: string, onChange: (value: string) => void) => {
        onChange(value);
        setCurrentLevel(3);
        reset(
            {
                Brand: '',
            },
            {keepDirty: true, keepValues: true}
        );
        const branchId = companyId?.toString();
        if (branchId) {
            dispatch(BrandLevel(token, branchId, String(value)));
        }
    };

    const handleTextInput = (value: string, onChange: (value: string) => void) => {
        onChange(value);
        setCurrentLevel(3);
    };

    const onSubmit = (data: FormData) => {
        let result: {contentType: string; encoding: string; base64Data: string} | null = null;

        if (selectedFile) {
            const [metaData, base64Data] = selectedFile.split(',');

            const [contentType, encoding] = metaData.split(/[:;]/);

            result = {
                contentType,
                encoding,
                base64Data,
            };
        }
        const selectDetails: {[key: string]: number} = {};

        const optionMap: {[key: number]: keyof typeof data} = {
            1: 'Level',
            2: 'Category',
            3: 'Manufacturer',
            4: 'Brand',
        };

        const newOptionMap: {[key: number]: string} = {
            1: 'catGroupId',
            2: 'catFamilyId',
            3: 'catSubFamilyId',
            4: 'catBrandId',
        };

        if (currentLevel !== null && currentLevel >= 1 && currentLevel <= 4) {
            const key = optionMap[currentLevel];
            if (key && data[key]) {
                selectDetails[newOptionMap[currentLevel]] = parseInt(data[key]);
            }
        }
        const submitedData = {
            businessId: companyId,
            requestDetails: {
                ...selectDetails,
                articleName: data.Article,
                supportDocumentBase64: result ? result.base64Data : '',
                supportDocumentFileName: result ? result.encoding : '',
            },
        };
        dispatch(PostNewLevel(submitedData, token));
        closeModal();
    };
    return (
        <>
            <Modal>
                <ContainerFlex
                    FlexDir="column"
                    Width="44.875rem"
                    Radius="24px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                    Padding="24px"
                    Height="49.5rem"
                    Gap="16px"
                >
                    <ContainerFlex Height="" FlexDir="column" Justify="start" Align="start">
                        <Text
                            FontSize="1.5rem"
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            MarginB="16px"
                        >
                            {MOD_TEXT.TITLE}
                        </Text>
                        <Text FontSize="1rem" FontWeight="400" Color="#54575C" FontFamily="Nunito">
                            {MOD_TEXT.INFO}
                        </Text>
                    </ContainerFlex>
                    <ContainerForm FlexDir="column" Gap="16px" onSubmit={handleSubmit(onSubmit)}>
                        <ContainerFlex Justify="start">
                            <Text
                                FontSize="1.25rem"
                                FontWeight="500"
                                Color="#54575C"
                                FontFamily="Nunito"
                            >
                                {MOD_TEXT.MOD}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text FontSize="16px" FontWeight="700" Color="#1D1E20">
                                {MOD_TEXT.LEVEL1}
                            </Text>
                            <Controller
                                name="Level"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={levelOneOptions}
                                        onChange={(value) =>
                                            handleLevelTwoOptions(value, field.onChange)
                                        }
                                        value={field.value}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text FontSize="16px" FontWeight="700" Color="#1D1E20">
                                {MOD_TEXT.LEVEL2}
                            </Text>
                            <Controller
                                name="Category"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={levelTwoOptions}
                                        onChange={(value) =>
                                            handleLevelThreeOptions(value, field.onChange)
                                        }
                                        value={field.value}
                                        disabled={!getValues('Level')}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text FontSize="16px" FontWeight="700" Color="#1D1E20">
                                {MOD_TEXT.LEVEL3}
                            </Text>
                            <Controller
                                name="Manufacturer"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={levelThreeOptions}
                                        onChange={(value) =>
                                            handleLevelFourOptions(value, field.onChange)
                                        }
                                        value={field.value}
                                        disabled={!getValues('Category')}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text FontSize="16px" FontWeight="700" Color="#1D1E20">
                                {MOD_TEXT.LEVEL4}
                            </Text>
                            <Controller
                                name="Brand"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={levelFourOptions}
                                        onChange={(value) => handleTextInput(value, field.onChange)}
                                        value={field.value}
                                        disabled={!getValues('Manufacturer')}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Gap="4px">
                            <Text FontSize="1rem" FontWeight="700" Color="#1D1E20">
                                {MOD_TEXT.LEVEL5}
                            </Text>
                            <Controller
                                name="Article"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        placeholder="Introduce el nombre..."
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                        style={{width: '100%'}}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex Align="start" FlexDir="column" Gap="16px">
                            <ContainerFlex Width="" ColumnGap="16px">
                                <Text FontSize="1.25rem" FontWeight="500" Color="#54575C">
                                    {MOD_TEXT.ADD}
                                </Text>
                                <ImageLogo width="24px" Height="24px" src={cuestions} />
                            </ContainerFlex>

                            <UploadFile
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />
                        </ContainerFlex>
                        <ContainerFlex Width="100%" Align="end" ColumnGap="16px" Height="">
                            <ContainerFlex>
                                <Text
                                    FontWeight="500"
                                    FontSize="1rem"
                                    Color="#FF6357"
                                    Cursor="pointer"
                                    onClick={() => closeModal()}
                                >
                                    {MOD_TEXT.CLOSED}
                                </Text>
                            </ContainerFlex>

                            <ButtonGenerals type="submit" Width="100%">
                                {MOD_TEXT.CORRECT}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
