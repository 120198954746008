import axios from 'axios';
import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {EDIT_USER} from '@components/CreateUsers/Redux/Types/Types';
import {IUpdateUsers} from '@components/CreateUsers/Redux/interface';
import {resetOptionsUserNames} from '@components/CreateUsers/Redux/Actions/GetListUserNames';
import {GetPostalInfoReset} from '@/components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';

export const editUserInit = () => ({type: EDIT_USER.INIT});

export const editUserSuccess = (result: {data: null}) => {
    return {
        type: EDIT_USER.SUCCESS,
        editUsers: result.data,
    };
};
export const editUserError = (result: unknown) => {
    return {
        type: EDIT_USER.ERROR,
        payload: result,
    };
};

export const editUser = (token: string, filters: {userProfile: number; userId: number}) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(editUserInit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DataUsersUpdate}${queryString}`,
                {headers}
            );
            dispatch(editUserSuccess(response.data));
        } catch (error) {
            dispatch(editUserError(error));
        }
    };
};

export const updateUserSuccess = (result: {data: null}) => {
    return {
        type: EDIT_USER.UPDATE_SUCCESS,
        editUsers: result.data,
    };
};

export const updateUser = (data: IUpdateUsers, token: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(editUserInit());
        try {
            const response = await await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateUsers}`,
                data,
                {headers}
            );
            dispatch(updateUserSuccess(response.data));
            dispatch(GetPostalInfoReset());
            dispatch(resetOptionsUserNames());
            window.location.href = '#/Users';
        } catch (error) {
            dispatch(editUserError(error));
        }
    };
};
