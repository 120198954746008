import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {Icons} from '@Articles/style';
import * as style from '@Articles/style';
import {CATEGORIZATION, NEWCATALOG} from '@Articles/constants';
import {ISearchAllCatalog} from '@Articles/interface';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

const Categorization = () => {
    const {LevelCatalog}: ISearchAllCatalog = useSelector(
        (state: RootState) => state.ArticleManagementSearch
    );
    const ActionTypeForm = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.actionType as number
    );
    const DataUpdate = useSelector((state: RootState) => state.GetNewCatalogId.dataCatalog);

    return (
        <ContainerFlex {...style.Card}>
            <Text {...style.Card.Title}>{CATEGORIZATION.TITLE_SECTION}</Text>
            <ContainerFlex Justify="start" Height="auto" Gap="4px">
                {ActionTypeForm === NEWCATALOG.EDIT && DataUpdate?.categorization ? (
                    <Text Color="#5A5AFF" FontWeight="500">
                        {DataUpdate.categorization.categorizationType}
                    </Text>
                ) : (
                    LevelCatalog.breadcrumb &&
                    LevelCatalog.breadcrumb.map((item, index: number) => {
                        const lastBread = LevelCatalog.breadcrumb.length;
                        return (
                            <React.Fragment key={index}>
                                <Text Color="#5A5AFF" FontWeight="500">
                                    {item.description}
                                </Text>
                                {lastBread - 1 !== index && (
                                    <Icons
                                        className="material-icons"
                                        Color="#5A5AFF"
                                        FontSize="1rem"
                                    >
                                        keyboard_arrow_right
                                    </Icons>
                                )}
                            </React.Fragment>
                        );
                    })
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Categorization;
