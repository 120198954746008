import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import ButtonGeneral from '@components/General/Atoms/Button';
import {schemaFormServices} from '@components/Branches/schemaValidations';
import {IFormDataServices, IServicesForm} from '@components/Branches/interfaces';
import {StyleNumericFormat} from '@/components/Loan/styles';
import {QuitNumberFormat, limitNumberFormat} from '@/hooks/currentFormatUtils';
import {PostServicesComplements} from '@components/Branches/ManageBranch/Redux/Actions/PostServicesComplements';
import {PutServicesComplements} from '@components/Branches/ManageBranch/Redux/Actions/PutServicesComplements';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const AssociatedCostsForm = ({showServicesForm, setShowServicesForm}: IServicesForm) => {
    const {idBranch} = useParams();
    const dispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const servicesInfo = useSelector((state: RootState) => {
        return state.GetServicesComplements?.servicesComplements;
    });
    const servicesLoad = useSelector((state: RootState) => {
        return state.PutServicesComplements?.loading;
    });
    const {
        handleSubmit,
        control,
        reset,
        formState: {isValid},
    } = useForm<IFormDataServices>({
        resolver: yupResolver(schemaFormServices),
        defaultValues: {
            appraisalPercentage: servicesInfo.appraisalPercentage || '',
            administrativeCost: servicesInfo.administrativeCost || '',
            reprintPledge: servicesInfo.reprintPledge || '',
            reprintPulledApart: servicesInfo.reprintPulledApart || '',
        },
    });
    const onSubmit = (data: IFormDataServices) => {
        const updateData = {
            administrativeCost: Number(data.administrativeCost.replace('%', '')),
            reprintPledge: Number(data.reprintPledge.replace('$ ', '').replace(',', '')),
            reprintPulledApart: Number(data.reprintPulledApart.replace('$ ', '').replace(',', '')),
            appraisalPercentage: Number(data.appraisalPercentage.replace('%', '')),
            branchId: Number(idBranch),
        };
        dispatch(
            !servicesInfo.serviceCostId
                ? PostServicesComplements(updateData, token, setShowServicesForm, Number(idBranch))
                : PutServicesComplements(
                      {...updateData, serviceId: servicesInfo.serviceCostId},
                      token,
                      setShowServicesForm,
                      Number(idBranch)
                  )
        );
    };
    return (
        <ContainerFlex Height="auto" FlexDir="column">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Costos asociados a tu sucursal
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Height="212px"
                Padding="16px 0"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                AlignContent="center"
                ColumnGap="24px"
            >
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Comisión por avalúo
                </Text>
                <Controller
                    name="appraisalPercentage"
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <StyleNumericFormat
                            {...field}
                            height="37px"
                            width="286px"
                            padding="0 0 0 13px"
                            color="#a7a9ac"
                            radius="4px"
                            margin="0 0 24px 0"
                            colorplace="#cbced1"
                            hborder="1px solid #35cf44"
                            borderf=""
                            getInputRef={ref}
                            type="text"
                            suffix="%"
                            decimalSeparator="."
                            thousandSeparator={true}
                            decimalScale={0}
                            allowNegative={false}
                            placeholder="Comisión por avalúo"
                            allowLeadingZeros={false}
                            isAllowed={(values) =>
                                limitNumberFormat(QuitNumberFormat(values.value), 0, 101)
                            }
                        />
                    )}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Costo de reimpresión empeños
                </Text>
                <Controller
                    name="reprintPledge"
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <StyleNumericFormat
                            {...field}
                            height="37px"
                            width="286px"
                            padding="0 0 0 13px"
                            color="#a7a9ac"
                            radius="4px"
                            margin="0 0 24px 0"
                            colorplace="#cbced1"
                            hborder="1px solid #35cf44"
                            borderf=""
                            getInputRef={ref}
                            type="text"
                            prefix="$ "
                            decimalSeparator="."
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            placeholder="Costo de reimpresión empeños"
                            allowLeadingZeros={false}
                            fixedDecimalScale
                            maxLength={16}
                        />
                    )}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Gastos administrativos
                </Text>
                <Controller
                    name="administrativeCost"
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <StyleNumericFormat
                            {...field}
                            height="37px"
                            width="286px"
                            padding="0 0 0 13px"
                            color="#a7a9ac"
                            radius="4px"
                            margin="0 0 24px 0"
                            colorplace="#cbced1"
                            hborder="1px solid #35cf44"
                            borderf=""
                            getInputRef={ref}
                            type="text"
                            suffix="%"
                            decimalSeparator="."
                            thousandSeparator={true}
                            decimalScale={0}
                            allowNegative={false}
                            placeholder="Gastos administrativos"
                            allowLeadingZeros={false}
                            isAllowed={(values) =>
                                limitNumberFormat(QuitNumberFormat(values.value), 0, 101)
                            }
                        />
                    )}
                />
                <Text FontSize="0.75rem" required Color="#6d6e71" Width="300px">
                    Costo de reimpresión de apartados
                </Text>
                <Controller
                    name="reprintPulledApart"
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <StyleNumericFormat
                            {...field}
                            height="37px"
                            width="286px"
                            padding="0 0 0 13px"
                            color="#a7a9ac"
                            radius="4px"
                            margin="0 0 24px 0"
                            colorplace="#cbced1"
                            hborder="1px solid #35cf44"
                            borderf=""
                            getInputRef={ref}
                            type="text"
                            prefix="$ "
                            decimalSeparator="."
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            placeholder="Costo de reimpresión de apartados"
                            allowLeadingZeros={false}
                            fixedDecimalScale
                            maxLength={16}
                        />
                    )}
                />
            </ContainerFlex>
            <ContainerFlex Padding="18px" Height="16%" Gap="20px">
                <ButtonGeneral
                    width="93px"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        setShowServicesForm(!showServicesForm);
                        reset();
                    }}
                    disabled={servicesLoad}
                    cursor={servicesLoad ? '' : 'pointer'}
                />
                <ButtonGeneral
                    width="87px"
                    text={servicesLoad ? <LoadingGeneralButtons /> : 'Guardar'}
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform="capitalize"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    disabled={!isValid || servicesLoad}
                    clic={handleSubmit(onSubmit)}
                    cursor={servicesLoad ? '' : 'pointer'}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
