import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ROLES_INFORMATION_ASSIGNMENT,
    ROLES_INFORMATION_ERROR,
    ROLES_INFORMATION_START,
    ROLES_INFORMATION_SUCCESS,
} from '@Roles/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {RolesList} from '@Roles/interface';
import StringUtils from '@/GenericScripts/utils';

const fetchRolesInformationStart = () => {
    return {type: ROLES_INFORMATION_START};
};
const fetchRolesInformationSuccess = (result: AxiosResponse) => {
    return {type: ROLES_INFORMATION_SUCCESS, payload: result};
};
const fetchRolesInformationError = (error: AxiosError) => {
    return {type: ROLES_INFORMATION_ERROR, error: error};
};

export function GetRolesInformation(
    token: string,
    filters: {
        information: string;
        userProfileType: number;
        pageNumber: number;
    }
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters, pageSize: 10});
    return async (dispatch: AppDispatch) => {
        dispatch(fetchRolesInformationStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRolsInformation}${queryString}`,
                {headers}
            );
            dispatch(fetchRolesInformationSuccess(response));
        } catch (error) {
            dispatch(fetchRolesInformationError(error as AxiosError));
        }
    };
}

export const fetchRolesIdAssignment = (result: null | RolesList) => {
    return {type: ROLES_INFORMATION_ASSIGNMENT, item: result};
};
