import {MODAL_STATES} from '@Roles/Redux/Types/types';

const initialState = {
    isEliminated: false,
    isDuplicated: false,
    isConfirmatedDelete: false,
};

const ModalStates = (
    state = initialState,
    action: {
        confirm: boolean;
        delete: boolean;
        valueDuplicate: boolean;
        type: string;
    }
) => {
    switch (action.type) {
        case MODAL_STATES:
            return {
                isEliminated: action.confirm,
                isDuplicated: action.delete,
                isConfirmatedDelete: action.valueDuplicate,
            };
        default:
            return state;
    }
};
export default ModalStates;
