import React from 'react';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerCellGrid,
    Image,
    InputColor,
} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {formCard, METHODPAYMENT} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {SearchInput} from '@components/Customer/Styles';
import {CardInput} from '@components/QuickPayment/CardInput';
import iCard from '@images/billingblack.svg';
import Calendar from '@images/calendar.svg';
import {FormCardProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {useForm} from 'react-hook-form';
import {ALTS} from './constants';
export const MethodPaymentCredits = () => {
    const {
        register,
        control,
        formState: {errors},
    } = useForm<FormCardProps>({defaultValues: formCard});
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FFFFFF"
            Width="48rem"
            MaxH="39.25rem"
            Padding="1.5rem"
            Radius="1rem"
            Gap="1.5rem"
        >
            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                {METHODPAYMENT.TITLE}
            </Text>

            <ContainerFlex
                FlexDir="column"
                Align="center"
                Width="100%"
                MaxW="45rem"
                MaxH="18.125rem"
                Border="1px solid #E8E9EA"
                Gap="1rem"
                Radius="0.5rem"
                Padding="0.5rem  1rem 1rem 1rem"
            >
                <ContainerFlex
                    Justify="space-between"
                    Align="center"
                    Width="100%"
                    Gap="0.5rem"
                    Height="2.75rem"
                >
                    <ContainerFlex Justify="start" Align="center" Gap="0.5rem">
                        <ContainerFlex backG="#F4F5F5" Width="44px" Height="44px" Radius="60px">
                            <Image src={iCard} width="24px" Height="24px" alt={ALTS.CARD_ICON} />
                        </ContainerFlex>
                        <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {METHODPAYMENT.CARD}
                        </Text>
                    </ContainerFlex>
                    <InputColor type="radio" />
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
                <ContainerFlex Justify="start" Align="start" FlexWrap="wrap" Gap="1rem">
                    <ContainerCellGrid
                        FlexDir="column"
                        Align="start"
                        Height="4.25rem"
                        Width="20rem"
                    >
                        <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {METHODPAYMENT.NUMBER}
                        </Text>
                        <CardInput control={control} errors={errors} />
                    </ContainerCellGrid>
                    <ContainerCellGrid
                        FlexDir="column"
                        Align="start"
                        Height="4.25rem"
                        Width="20rem"
                    >
                        <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {METHODPAYMENT.NAME}
                        </Text>
                        <Input {...register('name')} />
                    </ContainerCellGrid>
                    <ContainerCellGrid
                        FlexDir="column"
                        Align="start"
                        Height="4.25rem"
                        Width="10rem"
                    >
                        <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {METHODPAYMENT.DATE}
                        </Text>
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="75% 25%"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0.5rem 1rem"
                            BackG="#FFFFFF"
                            Radius="32px"
                            Margin="0 0 8px 0"
                        >
                            <Input type="text" maxLength={4} />
                            <Image
                                src={Calendar}
                                width="24px"
                                Height="24px"
                                alt={ALTS.CALENDER_ICON}
                            />
                        </SearchInput>
                    </ContainerCellGrid>
                    <ContainerCellGrid FlexDir="column" Align="start" Height="4.25rem" Width="7rem">
                        <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                            {METHODPAYMENT.CCV}
                        </Text>
                        <Input />
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex Justify="start" Gap="0.5rem" Height="1.5rem">
                    <Checkbox />
                    <Text>{METHODPAYMENT.SAVE}</Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
