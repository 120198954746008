import styled from '@emotion/styled';
import {IPropsFormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/interfacesStyles';

export const DateField = styled.div`
    width: 25rem;
    .MuiFormControl-root {
        min-width: 25rem;
    }
`;
export const Container = styled.div`
    border-top: 1px solid var(--gray-4);
    padding: 24px;
    width: 90vw;
`;
export const FormContainer = styled.form<IPropsFormContainer>`
    display: ${({isDisplay}) => isDisplay};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    margin-top: ${(props) => props.MarginTp};
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${(props) => props.FlexDir};
    box-sizing: border-box;
    padding: ${(props) => props.Padding};
    cursor: pointer;

    > label {
        display: flex;
        flex-wrap: wrap;
        flex-direction: ${(props) => props.FlexDir};
        cursor: pointer;

        > label {
            display: flex;
            flex-direction: column;
            font-size: 12px;
        }

        input,
        select {
            height: ${(props) => props.Height};
            border-radius: 5px;
            border: 1px solid var(--gray-4);
            font-size: 14px;
            width: 100%;
            &:disabled {
                background-color: #fafafa;
                color: var(--gray-2);
            }
            &::placeholder {
                color: var(--gray-2);
            }
        }
        input:disabled {
            background: var(--gray-5);
        }

        select {
            color: black;
        }

        select:invalid {
            color: var(--gray-2);
        }

        option {
            color: black;
        }

        option:first-child {
            color: var(--gray-2);
        }

        .input-height {
            height: 25px;
        }
        .check-size {
            width: 12px;
            height: 12px;
        }
    }
`;

FormContainer.defaultProps = {
    Justify: 'none',
    Width: '',
    FlexDir: '',
    Height: '40px',
};

export const spanProperties = {
    Color: '#6d6e7',
    FontSize: '0.75rem',
};

export const contentProperties = {
    Align: 'start',
    Width: '31%',
    FlexDir: 'column',
};
