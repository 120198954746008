import {
    UPDATE_CASH_NOTIFY,
    UPDATE_CASH_NOTIFY_SUCCESS,
    UPDATE_CASH_NOTIFY_ERROR,
    RESET_UPDATE,
} from '@ReduxCashFlowBasic/Types/TypesBranch';
import {AxiosResponse} from 'axios';
const initialState = {
    updateData: {
        tokenExpiration: 0,
        succeeded: false,
        message: '',
        errors: '',
        token: '',
        data: false,
    },
    loading: false,
    success: false,
    error: false,
};

export interface IPutNotifyCash {
    updateData: AxiosResponse<string>;
    loading: boolean;
    success: boolean;
    error: boolean;
}
const updateFlowNotify = (
    state = initialState,
    action: {type: string; value: AxiosResponse<string>}
) => {
    switch (action.type) {
        case UPDATE_CASH_NOTIFY:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CASH_NOTIFY_SUCCESS:
            return {
                ...state,
                updateData: action.value,
                success: true,
                loading: false,
                error: false,
            };
        case UPDATE_CASH_NOTIFY_ERROR:
            return {
                ...state,
                error: true,
                succeess: false,
                loading: false,
            };
        case RESET_UPDATE:
            return initialState;
        default:
            return state;
    }
};

export default updateFlowNotify;
