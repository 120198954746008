import {AppDispatch} from '@/config/store';
import {
    GroupLevel,
    FamilyLevel,
    SubfamilyLevel,
    BrandLevel,
    ArticleLevel,
} from '@/components/Articles/Redux/Actions/ArticlesLevel';
import {LevelSelected} from '@/components/Articles/Redux/Actions/RoutesArticles';

export const HandleLevelsRoute = (
    dispatch: AppDispatch,
    token: string,
    level: string | number,
    id: number,
    branch: string
) => {
    HandleSearchLevels.map((element) => {
        return (
            (element.description === level || element.level === level) &&
            element.function(dispatch, token, id, branch)
        );
    });
};

export const HandleSearchLevels = [
    {
        level: 0,
        function: (dispatch: AppDispatch, token: string, id: number, branch: string) => {
            dispatch(GroupLevel(token, branch));
            dispatch(LevelSelected('group'));
        },
    },
    {
        level: 1,
        description: 'Grupo',
        function: (dispatch: AppDispatch, token: string, id: number, branch: string) => {
            dispatch(FamilyLevel(token, branch, String(id)));
            dispatch(LevelSelected('family'));
        },
    },
    {
        level: 2,
        description: 'Familia',
        function: (dispatch: AppDispatch, token: string, id: number, branch: string) => {
            dispatch(SubfamilyLevel(token, branch, id?.toString()));
            dispatch(LevelSelected('subfamily'));
        },
    },
    {
        level: 3,
        description: 'SubFamilia',
        function: (dispatch: AppDispatch, token: string, id: number, branch: string) => {
            dispatch(BrandLevel(token, branch, id.toString()));
            dispatch(LevelSelected('brand'));
        },
    },
    {
        level: 4,
        description: 'Marcas',
        function: (dispatch: AppDispatch, token: string, id: number, branch: string) => {
            dispatch(ArticleLevel(token, branch, id?.toString()));
            dispatch(LevelSelected('article'));
        },
    },
];
