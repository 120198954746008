import React from 'react';
import {Controller} from 'react-hook-form';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';

const PersonalDataContactInfo = (props: IClientProofStep) => {
    const {register, errors, control} = props;

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.TELEFONO}</Text>
                <Controller
                    name="mainPhone"
                    control={control}
                    render={() => (
                        <Input {...register('mainPhone')} error={!!errors.mainPhone?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.mainPhone?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.CORREO_ELECTRONICO}</Text>
                <Controller
                    name="mainEmail"
                    control={control}
                    render={() => (
                        <Input {...register('mainEmail')} error={!!errors.mainEmail?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.mainEmail?.message as string}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataContactInfo;
