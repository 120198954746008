import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import phone from '@images/add-plus.svg';
import {SHOPPING_CART} from '@/components/ShoppingCart/constants';
import mailEnvelope from '@components/Branches/Images/MailEnvelope.svg';
import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';

const CardsPayResume = () => {
    const {summary, Contracts} = useSelector((state: RootState) => ({
        summary: state.cartPaymentReducer,
        Contracts: state.getCarContracts as IClienctContactPay,
    }));
    const summaryContracts = summary.paymentResult && summary.paymentResult.data;

    return (
        <ContainerFlex
            Height="4.688rem"
            Justify="start"
            Align="start"
            Bt="1px solid #E8E9EA"
            Padding="1rem 0 0 0"
            ColumnGap="0.5rem"
        >
            <Image
                src={Contracts.data.imgProfile}
                alt="image-profile"
                Height="3rem"
                Width="3rem"
                Radius="50%"
                ObjectFit="cover"
                AspectRatio="1 / 1"
            />
            <ContainerFlex Justify="start" Align="start" Gap="0.25rem" FlexDir="column">
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700" Transform="capitalize">
                    {Contracts.data.clientName}
                </Text>
                <ContainerFlex Justify="start" Gap="0.5rem">
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500" oFlow="initial">
                        {`${
                            summaryContracts &&
                            summaryContracts.pawnsPay &&
                            summaryContracts.pawnsPay.length > 0
                                ? SHOPPING_CART.NUC
                                : SHOPPING_CART.DOCUMENT_OFICIAL
                        } ${Contracts.data.nuc}`}
                    </Text>
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500">
                        <Image src={phone} alt="phone" Width="0.875rem" Height="0.875rem" />
                        {Contracts.data.clientPhone}
                    </Text>
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500">
                        <Image src={mailEnvelope} alt="email" Width="0.875rem" Height="0.875rem" />
                        {Contracts.data.clientEmail}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CardsPayResume;
