import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ICardsContainerProps, ICreditCard} from '@/components/MyAccount/interfaces';
import DataCard from '@/components/MyAccount/MyCredit/DataCard';
import {CREDIT_TYPES, NOT_FOUND} from '@/components/MyAccount/constants';
import {GridContainer} from '@/components/MyAccount/MyCredit/styles';

const CardsContainer = ({title, type, data}: ICardsContainerProps) => {
    const personalCredits = data ? (
        data.map((credit: ICreditCard) => {
            return <DataCard key={credit.solicitudCreditoPersonalId} type={type} data={credit} />;
        })
    ) : (
        <ContainerFlex Gap="16px" Justify="center" Align="center" Height="682px">
            <Text FontSize="1.25rem" FontWeight="700" FontFamily="Nunito" Color="#000000">
                {NOT_FOUND.PERSONAL}
            </Text>
        </ContainerFlex>
    );
    return (
        <ContainerFlex FlexDir="column" Gap="16px" Justify="flex-start" Align="flex-start">
            <Text FontSize="1.25rem" FontWeight="700" FontFamily="Nunito" Color="#000000">
                {title}
            </Text>
            <GridContainer height={'682px'}>
                {!data && type === CREDIT_TYPES.PAWNS ? null : personalCredits}
            </GridContainer>
        </ContainerFlex>
    );
};

export default CardsContainer;
