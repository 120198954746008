import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Input, Text} from '@components/Shopify/Ecommerce/styles';
import {SearchInput} from '@components/Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import editOrganization from '@components/Branches/Images/EditOrg.svg';
import {SelectCountry} from '@components/Branches/CreateBranchModal/SelectCountry';
import {OrganizationTreeModal} from '@components/Branches/CreateBranchModal/OrganizationTreeModal';
import {IBranchForm} from '@components/Branches/interfaces';
import {createBranchReset} from '@components/Branches/Redux/Actions/CreateBranch';

export const BranchForm = ({
    watchCountry,
    register,
    setValue,
    levelSelected,
    watchLevel,
    errors,
    trigger,
}: IBranchForm) => {
    const dispatch = useDispatch();
    const catCountry = useSelector((state: RootState) => {
        return state.GetCatCountry.catCountry;
    });
    const tieredLevel = useSelector((state: RootState) => {
        return state.GetTieredLevel.tieredLevel.data;
    });
    const createError = useSelector((state: RootState) => {
        return state.CreateBranch.error;
    });
    const [openTree, setOpenTree] = useState(false);
    return (
        <ContainerFlex
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
            Padding="24px"
            Height="75%"
        >
            <Text FontSize="0.875rem" Color="#414042">
                Crea una sucursal para tu organizacion
            </Text>
            <Text FontSize="0.75rem" required Color="#6d6e71" Margin="18px 0 0  0">
                País
            </Text>
            <SelectCountry
                data={catCountry}
                optionSelect={watchCountry}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
            />
            {errors.countryName?.message && (
                <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                    {errors.countryName.message}
                </Text>
            )}
            <Text FontSize="0.75rem" required Color="#6d6e71">
                Nombre de la sucursal
            </Text>
            <Input
                Cursor="text"
                Height="2.5rem"
                Margin={errors.nameBranch || createError ? '' : '0 0 24px 0'}
                FontSize="0.875rem"
                HolderFontSize="0.875rem"
                Padding="13px 13px 11px 13px"
                Radius="4px"
                placeholder="Nombre"
                Width="100%"
                {...register('nameBranch')}
                onChange={() => dispatch(createBranchReset())}
                type="text"
                maxLength={160}
                error={errors.nameBranch?.message || createError}
            />
            {errors.nameBranch && (
                <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                    {errors.nameBranch.message}
                </Text>
            )}
            {createError && (
                <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                    El nombre de la sucursal ya existe en la empresa.
                </Text>
            )}
            <Text FontSize="0.75rem" required Color="#6d6e71">
                Descripción
            </Text>
            <Input
                Cursor="text"
                Height="2.5rem"
                Margin={errors.description ? '' : '0 0 24px 0'}
                FontSize="0.875rem"
                HolderFontSize="0.875rem"
                Padding="13px 13px 11px 13px"
                Radius="4px"
                placeholder="Descripción"
                Width="100%"
                {...register('description')}
                type="text"
                maxLength={160}
                error={errors.description?.message}
            />
            {errors.description && (
                <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                    {errors.description.message}
                </Text>
            )}
            <Text FontSize="0.75rem" required Color="#6d6e71">
                Estructura organizacional
            </Text>
            <SearchInput
                Justify="start"
                Border={errors.levelId ? '1px solid #ef4f55' : '1px solid #e5e7e9'}
                BorderH={errors.levelId ? '1px solid #ef4f55' : '1px solid #35cf44'}
                BorderF={errors.levelId ? '1px solid #ef4f55' : '1px solid #35cf44'}
                Width="100%"
                Margin={errors.levelId ? '' : '0 0 24px 0'}
                Padding="0 8px"
                Height="40px"
                BoxShadowH={errors.levelId ? '' : '0px 4px 4px rgba(53, 207, 68, 0.2)'}
                Position="none"
                BackG="#ffffff"
                onClick={() => setOpenTree(!openTree)}
            >
                <ContainerFlex Justify="flex-start">
                    {tieredLevel.split(' > ').map((data: string, index: number) => {
                        if (index === tieredLevel.split(' > ').length - 1) {
                            return (
                                <Text
                                    key={index}
                                    Margin="0 0 0 6px"
                                    FontSize="0.875rem"
                                    FontWeight="500"
                                >
                                    {data}
                                </Text>
                            );
                        }
                        if (0 === tieredLevel.split(' > ').length - 1) {
                            return (
                                <Text key={index} FontSize="0.875rem" FontWeight="500">
                                    {data}
                                </Text>
                            );
                        }
                        if (index !== tieredLevel.split(' > ').length - 1) {
                            return (
                                <Text
                                    key={index}
                                    FontSize="0.875rem"
                                    Margin={
                                        tieredLevel.split(' > ').length - 1 !== index &&
                                        tieredLevel.split(' > ').length !== 0
                                            ? '0 0 0 6px'
                                            : ''
                                    }
                                >{`${data} >`}</Text>
                            );
                        }
                        return <></>;
                    })}
                </ContainerFlex>
                <IconContainer Width="100%" Justify="flex-end" Color="#6d6e71" FontSize="1rem">
                    <Image src={editOrganization} alt="edit-org" />
                </IconContainer>
            </SearchInput>
            {errors.levelId && (
                <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                    {errors.levelId.message}
                </Text>
            )}
            <OrganizationTreeModal
                createModal={openTree}
                setCreateModal={setOpenTree}
                levelSelected={levelSelected}
                watchLevel={watchLevel}
                trigger={trigger}
                edit={false}
            />
        </ContainerFlex>
    );
};
