export const USER_CLIENTS_LOGIN = {
    TITLE: 'Inicia sesión',
    EMAIL: 'Correo electrónico',
    PASS: 'Contraseña',
    FORGET_PASS: 'Olvidé mi contraseña',
    REGISTER: 'Quiero registrarme',
    CREATE_USER: 'Crear cuenta',
};

export const USER_VALIDATE = {
    VALIDATION:
        'El correo electrónico o número de teléfono y/o la contraseña son incorrectos .Por favor, revíselos e inténtelo de nuevo o haga clic en "Olvidé mi contraseña."',
    CLOSE: 'close',
};
