import {
    IDocumentDisbursementInitialStates,
    INewProdcutDocumentsOptions,
} from '@/components/PersonalLoans/interfaces';
import {
    GET_DOCUMENTS_PREDIFINED_OPTIONS_START,
    GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS,
    GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR,
    GET_DISBURSEMENT_OPTIONS_START,
    GET_DISBURSEMENT_OPTIONS_SUCCESS,
    GET_DISBURSEMENT_OPTIONS_ERROR,
    ADD_DOCUMENT_NEW_OPTION,
    DELETE_DOCUMENT_NEW_OPTION,
} from '@/components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {AxiosResponse, AxiosError} from 'axios';

const initialState: IDocumentDisbursementInitialStates = {
    documentOptions: [],
    disbursementOptions: [],
    loading: false,
    error: false,
};

export const DocumentDisbursementReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
        newDocumentOption: INewProdcutDocumentsOptions;
        documentId: number;
    }
): IDocumentDisbursementInitialStates => {
    switch (action.type) {
        case GET_DOCUMENTS_PREDIFINED_OPTIONS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS:
            return {
                ...state,
                documentOptions: action.payload.data,
                loading: false,
            };
        case GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_DISBURSEMENT_OPTIONS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_DISBURSEMENT_OPTIONS_SUCCESS:
            return {
                ...state,
                disbursementOptions: action.payload.data,
                loading: false,
            };
        case GET_DISBURSEMENT_OPTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case ADD_DOCUMENT_NEW_OPTION:
            return {
                ...state,
                documentOptions: [...state.documentOptions, action.newDocumentOption],
            };
        case DELETE_DOCUMENT_NEW_OPTION:
            return {
                ...state,
                documentOptions: state.documentOptions.filter(
                    (document: INewProdcutDocumentsOptions) =>
                        document.documentId !== action.documentId
                ),
            };
        default:
            return state;
    }
};
