export const DEADLINES = {
    DEADLINES_STATEINITIAL: 'DEADLINES_STATEINITIAL',
    DEADLINE_ERROR: 'DEADLINE_ERROR',
    INITIAL_INFORMATION: 'INITIAL_INFORMATION',
    INTEREST: 'INTEREST',
    AMOUNT_TIMES: 'AMOUNT_TIMES',
    AMOUNT_TABLE: 'AMOUNT_TABLE',
    DEADLINE_TABLE: 'DEADLINE_TABLE',
    DEADLINES_SIMULATION: 'DEADLINES_SIMULATION',
    DISCOUNTS: 'DISCOUNTS',
    DISCOUNTS_TABLE: 'DISCOUNTS_TABLE',
    CHANGES_PRODUCTS: 'CHANGES_PRODUCTS',
    EXTENTIONS: 'EXTENTIONS',
    TYPE_CLIENT: 'TYPE_CLIENT',
    SIMULATION_EXAMPLE: 'SIMULATION_EXAMPLE',
    STEP: 'STEP',
    STARTS: 'STARTS',
};

export const CHANGEPRODUCTS = {
    GET_PRODUCTS_COPY_CHECKED: 'GET_PRODUCTS_COPY_CHECKED',
    GET_PRODUCTS_CHECK: 'GET_PRODUCTS_CHECK',
    GET_PRODUCTS_EXCHANGE: 'GET_PRODUCTS_EXCHANGE',
    GET_PRODUCTS_EXCHANGE_SUCCESS: 'GET_PRODUCTS_EXCHANGE_SUCCESS',
    GET_PRODUCTS_EXCHANGE_ERROR: 'GET_PRODUCTS_EXCHANGE_ERROR',
};

export const GET_PRODUCTS_EXCHANGE = 'GET_PRODUCTS_EXCHANGE';
export const GET_PRODUCTS_EXCHANGE_SUCCESS = 'GET_PRODUCTS_EXCHANGE_SUCCESS';
export const GET_PRODUCTS_EXCHANGE_ERROR = 'GET_PRODUCTS_EXCHANGE_ERROR';
export const GET_PRODUCTS_ID = 'GET_PRODUCTS_ID';
export const GET_PRODUCTS_CHECK = 'GET_PRODUCTS_CHECK';
export const GET_PRODUCTS_COPY_CHECKED = 'GET_PRODUCTS_COPY_CHECKED';

export const DEADLINE_SIMULATION = 'DEADLINE_SIMULATION';
export const DEADLINE_SIMULATION_SUCCESS = 'DEADLINE_SIMULATION_SUCCESS';
export const DEADLINE_SIMULATION_ERROR = 'DEADLINE_SIMULATION_ERROR';
