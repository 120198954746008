import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {
    GET_SCHEDULES_ERROR,
    GET_SCHEDULES_START,
    GET_SCHEDULES_SUCCESS,
    RESET_SCHEDULES,
} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';

export const scheduleStart = () => ({
    type: GET_SCHEDULES_START,
});

export const scheduleSuccess = (result: AxiosResponse) => ({
    type: GET_SCHEDULES_SUCCESS,
    schedule: result.data,
});

export const scheduleError = (error: AxiosError) => ({
    type: GET_SCHEDULES_ERROR,
    error: error,
});

export const resetSchedule = () => ({
    type: RESET_SCHEDULES,
});

export const getSchedule = (token: string, LevelId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({LevelId: LevelId});
    return async (dispatch: AppDispatch) => {
        dispatch(scheduleStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetOpeningHoursByLevelId}${queryString}`,
                {headers}
            );
            dispatch(scheduleSuccess(response.data));
        } catch (error) {
            dispatch(scheduleError(error as AxiosError));
        }
    };
};
