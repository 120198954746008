export const RESET_PASSWORD = {
    TITLE: 'Restablecer contraseña',
    TITLE_TWO: 'Cambio de contraseña',
    INDICATION:
        'Puedes cambiar la contraseña o configurar que la contraseña se actualice determinado tiempo para seguridad.',
    TEX_PASS: 'Contraseña',
    REPEAT_PASS: 'Repetir contraseña',
    GENERATE: 'Genera automática mente',
    TEX_PIN: 'Quieres agregar un PIN? (Solo aplica para POS)',
    DATA_PIN: 'Datos del PIN',
    BTN: 'Descartar',
    BTN_KEEP: 'Guardar',
};

export const RESTORE_NEW_PASSWORD = {
    MESSAGE: '8 caracteres como minimo.',
    PASSWORD: 'Contraseña',
    MESSAGE_ONE: 'Debe incluir una mayúscula, una minúscula, un número y',
    MESSAGE_TWO: ' un carácter especial.',
    REPEAT_PASS: '8 caracteres como minimo.',
    SAME_PASSWORD: '8 caracteres como minimo.',
    CLOSE: 'Close icon',
    DONE: 'Close icon',
};

export const VALIDA_FORM = {
    MIN: '8 caracteres como minimo.',
    REQUIRED: 'Este campo es obligatorio',
    MATCHES: 'Debe incluir una mayúscula, una minúscula, un número y un carácter especial',
    ONE_OF: 'Las contraseñas deben coincidir',
    PASS: 'password',
};
