import React, {useEffect} from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CreditDetailsHeader from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsHeader';
import CreditDetailsInformation from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformation';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    GetPersonalCreditAmountById,
    GetCreditLoanDetailById,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/GetPersonalCreditAmountActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {routers} from '@/appRoute';
import {ModalCartShopping} from './ModalCartShopping';

const CreditDetails = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {showModal} = useSelector((state: RootState) => state?.shoppingCarClient);

    useEffect(() => {
        if (!state || !state.personalCreditId || !token) {
            navigate(routers.MyAccount);
        }
        dispatch(GetPersonalCreditAmountById(state.personalCreditId, token));
        dispatch(GetCreditLoanDetailById(state.personalCreditId, token));
    }, [state, token, navigate, dispatch]);

    return (
        <ContainerFlex
            Height="auto"
            MinH="100%"
            Width="100%"
            FlexDir="column"
            backG="#E5E5FF"
            Justify="start"
            Align="center"
            Padding="24px 60px"
            Gap="24px"
        >
            <CreditDetailsHeader />
            <CreditDetailsInformation />
            {showModal && <ModalCartShopping />}
        </ContainerFlex>
    );
};

export default CreditDetails;
