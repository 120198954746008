import React, {useEffect, useState} from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AppDispatch, RootState} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {insertVault, restartLocations} from '@ActionsLocation/LocationAction';
import vault from '@images/vault.svg';
import {
    ContainerArea,
    SubContainerArea,
    SelectContainerVault,
    SelectContainerVaults,
    SelectFileVault,
    ImgLocation,
} from '@CreateLocation/style';
import {TableItem, TableRowItems} from '@Shopify/Endeavors/styles';
import deleteIcon from '@images/delete_forever-black.svg';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import TreeLocations from '@CreateLocation/Containers/TreeLocations';
import {containerFileVault} from '@Locations/AdminLocations/redux/constants';
import {SquabField, location} from '@Locations/AdminLocations/redux/Interfaces';

const Vault = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/CreateBranch', label: 'Sucursal', reset: () => dispatch(restartLocations(null))},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: 'Crear ubicación'},
    ];
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [view, setView] = useState(0);
    const [rows, setRows] = useState<{id: number; letterName: string}[]>([]);
    const [drawers, setDrawers] = useState<{id: number; letternames: string}[]>([]);
    const [squabFields, setSquabFields] = useState<{
        lettername: string;
        squab: number;
        row: number;
    }>({
        lettername: '',
        squab: 0,
        row: 0,
    });
    const [dataComplete, setDataComplete] = useState<
        {drawers: ({row: number; squabs: number} | null)[]; letterName: string}[]
    >([]);

    const defaultData = {
        voultName: '',
        branchId: '',
        config: [],
        letterName: '',
        row: '',
        squabs: '',
    };
    const {register, handleSubmit, reset} = useForm<FieldValues>({
        defaultValues: defaultData,
    });

    const onSubmits = (data: FieldValues) => {
        dispatch(
            insertVault(
                {
                    voultName: data.voultName,
                    branchId: locations.saveBranch.idBranch,
                    config: dataComplete,
                },
                token
            )
        );
    };

    const handleFileChange = (item: string) => {
        setRows(containerFileVault.filter((idSelect: {id: number}) => idSelect.id <= Number(item)));
    };

    const handleRowChange = async (item: string) => {
        const convertLetters = item.length > 2 ? item.substring(2, 4) : item.substring(1, 3);
        const convertDrawers = Number(
            item.length > 2 ? item.substring(0, 2) : item.substring(0, 1)
        );

        const copyItems = [];
        for (let i = 0; i < convertDrawers; i++) {
            copyItems.push({
                id: i + 1,
                letternames: convertLetters,
            });
        }
        setDrawers(
            drawers.filter((items) => items.letternames !== convertLetters).concat(copyItems)
        );
    };

    const handleSquabChange = (item: string, letter: string, index: number) => {
        setSquabFields({
            ...squabFields,
            [letter + index]: {
                lettername: letter,
                squab: Number(item),
                row: index,
            },
        });
    };

    useEffect(() => {
        setDataComplete(
            rows.map((item) => {
                return {
                    letterName: item.letterName,
                    drawers: (Object.values(squabFields) as unknown as SquabField[])
                        .map((items) =>
                            item.letterName === items.lettername
                                ? {
                                      row: items.row,
                                      squabs: items.squab,
                                  }
                                : null
                        )
                        .filter((item: unknown) => item !== null),
                };
            })
        );
    }, [squabFields]);

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex PaddingB="2rem" Justify="start">
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start">
                <TreeLocations />
                <ContainerFlex Align="start" FlexDir="column" Padding="0 6.313rem 0 1.563rem">
                    <ContainerArea>
                        <SubContainerArea>
                            <Text FontSize="1rem" FontWeight="400">
                                Crear Ubicación
                            </Text>
                        </SubContainerArea>
                        <ContainerFlex Align="start" FlexDir="column" Padding="0 1rem">
                            <ContainerFlex
                                FlexDir="column"
                                Width="98px"
                                Padding="3.453rem 0 1rem 0"
                            >
                                <ContainerFlex
                                    Border={'2px solid #7AC143'}
                                    Height="98px"
                                    Radius="5px"
                                >
                                    <img width="50px" height="50px" src={vault} />
                                </ContainerFlex>
                                <Text FontSize="0.875rem" Padding="0.688rem 0 0 0">
                                    Bóveda
                                </Text>
                            </ContainerFlex>
                            <Text
                                FontSize="1.125rem"
                                FontWeight="400"
                                Padding="1.688rem 0 1.563rem 0"
                            >
                                Detalle de la Ubicación en bóveda
                            </Text>
                            <form onSubmit={handleSubmit(onSubmits)}>
                                <ContainerFlex Height="auto" Justify="space-between">
                                    <ContainerFlex
                                        Align="start"
                                        FlexDir="column"
                                        Width="auto"
                                        Padding="0 2.313rem 0 0"
                                    >
                                        <Text FontWeight="400" FontSize="0.75rem" required>
                                            Nombre de bóveda
                                        </Text>
                                        <Input
                                            {...register('voultName', {
                                                required: {
                                                    value: true,
                                                    message: 'Campo Obligatorio',
                                                },
                                            })}
                                            Width="12.6rem"
                                            maxLength={160}
                                        />
                                    </ContainerFlex>
                                    <ContainerFlex Align="start" FlexDir="column">
                                        <SelectFileVault>
                                            <Text FontWeight="400" FontSize="0.75rem" required>
                                                Número de filas
                                            </Text>
                                            <select
                                                {...register('letterName', {
                                                    required: {
                                                        value: true,
                                                        message: 'Selecciona un contenedor',
                                                    },
                                                })}
                                                onChange={(e: {target: {value: string}}) =>
                                                    handleFileChange(e.target.value)
                                                }
                                            >
                                                <option selected disabled hidden value="">
                                                    ----
                                                </option>
                                                {containerFileVault?.map(
                                                    (item: {id: number}, index: number) => (
                                                        <option value={item.id} key={index}>
                                                            {`${item.id} filas`}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </SelectFileVault>
                                    </ContainerFlex>
                                </ContainerFlex>

                                <ContainerFlex FlexWrap="wrap" Align="start" Justify="start">
                                    {rows.map((items, index: number) => (
                                        <ContainerFlex
                                            Width="240px"
                                            Padding="0.625rem 2.313rem 0.625rem 0"
                                            FlexDir="column"
                                            Align="start"
                                            key={index}
                                        >
                                            <ContainerFlex>
                                                <TableRowItems
                                                    Justify="center"
                                                    Border="none"
                                                    Margin="0"
                                                    Height="40px"
                                                    BackGColor=""
                                                    GridColumn="1fr 1fr"
                                                >
                                                    <Text
                                                        Justify="center"
                                                        FontWeight="400"
                                                        FontSize="0.75rem"
                                                    >
                                                        Fila
                                                    </Text>
                                                    <Text
                                                        Justify="center"
                                                        FontWeight="400"
                                                        FontSize="0.75rem"
                                                    >
                                                        # Cajones
                                                    </Text>
                                                </TableRowItems>
                                            </ContainerFlex>

                                            <ContainerFlex
                                                Align="center"
                                                Justify="center"
                                                Border="0.5px solid #e4e7e9"
                                                Radius="5px"
                                            >
                                                <ContainerFlex FlexDir="column">
                                                    <TableRowItems
                                                        Margin="0"
                                                        Height="40px"
                                                        GridColumn="1fr 1fr"
                                                        Background={
                                                            view === items.id
                                                                ? '#E4F2D9'
                                                                : '#F3F3F3'
                                                        }
                                                    >
                                                        <TableItem
                                                            Color={
                                                                view === items.id
                                                                    ? '#7AC143'
                                                                    : '#414042'
                                                            }
                                                            Size="26px"
                                                            onClick={() => {
                                                                setView(items.id);
                                                            }}
                                                        >
                                                            {items.letterName}
                                                        </TableItem>
                                                        <SelectContainerVault>
                                                            <select
                                                                {...register(`row${index}`, {
                                                                    required: {
                                                                        value: true,
                                                                        message:
                                                                            'Selecciona un contenedor',
                                                                    },
                                                                })}
                                                                onChange={(e: {
                                                                    target: {value: string};
                                                                }) =>
                                                                    handleRowChange(e.target.value)
                                                                }
                                                            >
                                                                <option
                                                                    selected
                                                                    disabled
                                                                    hidden
                                                                    value=""
                                                                >
                                                                    ----
                                                                </option>
                                                                {containerFileVault?.map(
                                                                    (
                                                                        item: {
                                                                            id: number;
                                                                            letterName: string;
                                                                        },
                                                                        index: number
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                item.id +
                                                                                items.letterName
                                                                            }
                                                                            key={index}
                                                                        >
                                                                            {item.id}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </SelectContainerVault>
                                                    </TableRowItems>

                                                    <ContainerFlex
                                                        FlexDir="column"
                                                        Padding="30px 12px 5px"
                                                        backG={
                                                            view === items.id
                                                                ? '#F8FFF4'
                                                                : '#FFFFFF'
                                                        }
                                                    >
                                                        <ContainerFlex>
                                                            <TableRowItems
                                                                Margin="0"
                                                                Height="40px"
                                                                GridColumn="1fr 1fr"
                                                                Border="none"
                                                                Background={
                                                                    view === items.id
                                                                        ? '#F8FFF4'
                                                                        : '#FFFFFF'
                                                                }
                                                                BackGColor="none"
                                                            >
                                                                <Text
                                                                    Justify="center"
                                                                    FontWeight="400"
                                                                    FontSize="0.75rem"
                                                                >
                                                                    Fila
                                                                </Text>
                                                                <Text
                                                                    Justify="center"
                                                                    FontWeight="400"
                                                                    FontSize="0.75rem"
                                                                >
                                                                    # Pichoneras
                                                                </Text>
                                                            </TableRowItems>
                                                        </ContainerFlex>
                                                        {drawers
                                                            .filter(
                                                                (item) =>
                                                                    item.letternames ===
                                                                    items.letterName
                                                            )
                                                            ?.map((itemms, index: number) => (
                                                                <ContainerFlex
                                                                    Align="center"
                                                                    Justify="center"
                                                                    FlexDir="column"
                                                                    key={index}
                                                                >
                                                                    <TableRowItems
                                                                        Height="40px"
                                                                        GridColumn="1fr 1fr"
                                                                        Margin="5px 0"
                                                                        Background={
                                                                            view === items.id
                                                                                ? '#E4F2D9'
                                                                                : '#F3F3F3'
                                                                        }
                                                                    >
                                                                        <TableItem Size="26px">
                                                                            {itemms.id}
                                                                        </TableItem>
                                                                        <SelectContainerVaults>
                                                                            <select
                                                                                {...register(
                                                                                    `squabs${items.letterName}${index}`,
                                                                                    {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message:
                                                                                                'Selecciona una Fila',
                                                                                        },
                                                                                    }
                                                                                )}
                                                                                onChange={(e: {
                                                                                    target: {
                                                                                        value: string;
                                                                                    };
                                                                                }) =>
                                                                                    handleSquabChange(
                                                                                        e.target
                                                                                            .value,
                                                                                        items.letterName,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <option
                                                                                    selected
                                                                                    disabled
                                                                                    hidden
                                                                                    value=""
                                                                                >
                                                                                    ----
                                                                                </option>
                                                                                {containerFileVault?.map(
                                                                                    (
                                                                                        item: {
                                                                                            id: number;
                                                                                        },
                                                                                        index: number
                                                                                    ) => (
                                                                                        <option
                                                                                            value={
                                                                                                item.id
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.id
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </SelectContainerVaults>
                                                                    </TableRowItems>
                                                                </ContainerFlex>
                                                            ))}
                                                    </ContainerFlex>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    ))}
                                </ContainerFlex>
                            </form>
                            <ContainerFlex
                                Justify="space-between"
                                Height="auto"
                                Width="75%"
                                Padding="50px 0"
                            >
                                <ButtonGeneral
                                    transform="capitalize"
                                    hideborder="none"
                                    width="167px"
                                    hColor="#35cf44"
                                    text="Cancelar"
                                    clic={() => navigate('/NavLocations')}
                                />
                                <ButtonGeneral
                                    transform="capitalize"
                                    width="167px"
                                    hColor="#35cf44"
                                    text="Crear Ubicación"
                                    clic={handleSubmit(onSubmits)}
                                />
                                <ContainerFlex
                                    Justify="start"
                                    onClick={() => {
                                        reset();
                                        setRows([]);
                                        setDrawers([]);
                                        setSquabFields({
                                            lettername: '',
                                            squab: 0,
                                            row: 0,
                                        });
                                    }}
                                    Width="167px"
                                    Cursor="pointer"
                                >
                                    <ImgLocation
                                        Width="20px"
                                        Height="20px"
                                        Margin="0 5px"
                                        HFilter="invert(17%) sepia(90%) saturate(7107%) hue-rotate(358deg) brightness(113%) contrast(113%)"
                                        src={deleteIcon}
                                    />
                                    <Text Cursor="pointer">Limpiar</Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerArea>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default Vault;
