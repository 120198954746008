import React from 'react';

export const visibilityOn = (
    <path
        data-name="visibility_FILL0_wght400_GRAD0_opsz48"
        d="M12 18.682a3.841 3.841 0 0 0 3.864-3.864A3.841
3.841 0 0 0 12 10.955a3.841 3.841 0 0 0-3.864
3.864A3.841 3.841 0 0 0 12 18.682zm0-1.318a2.555
2.555 0 0 1-1.807-4.352 2.579 2.579 0 0 1 3.614
0A2.545 2.545 0 0 1 12 17.364zm0 4.273a10.186
10.186 0 0 1-6-1.887 11.492 11.492 0 0 1-4-4.932
11.492 11.492 0 0 1 4-4.932A10.186 10.186 0 0 1
12 8a10.186 10.186 0 0 1 6 1.886 11.492 11.492
0 0 1 4 4.932 11.492 11.492 0 0 1-4 4.932
10.186 10.186 0 0 1-6 1.886zm0-6.819zm0
5.455a9.139 9.139 0 0 0 5.057-1.489
9.66 9.66 0 0 0 3.511-3.966 9.66 9.66 0 0
0-3.511-3.966 9.333 9.333 0 0 0-10.114 0 9.822
9.822 0 0 0-3.534 3.966 9.822 9.822 0 0 0 3.534
3.966A9.139 9.139 0 0 0 12 20.273z"
        transform="translate(-2 -8)"
        fill="#6d6e71"
    />
);

export const visibilityOff = (
    <path
        data-name="visibility_off_FILL0_wght400_GRAD0_opsz48"
        d="m15.386 14.886-1-1a2.255 2.255 0 0 0-.614-2.682 2.428
2.428 0 0 0-2.614-.545l-1-1a2.747 2.747 0 0 1 .864-.364A4.2
4.2 0 0 1 12 9.182a3.841 3.841 0 0 1 3.864 3.864 3.974
3.974 0 0 1-.125.989 3 3 0 0 1-.352.852zm2.932
2.932-.909-.909a10.659 10.659 0 0 0 1.943-1.83
7.3 7.3 0 0 0 1.216-2.034 9.072 9.072 0 0 0-3.409-3.989
8.924 8.924 0 0 0-4.932-1.466 10.915 10.915 0 0
0-1.955.182A7.247 7.247 0 0 0 8.7 8.2L7.659
7.136A10.305 10.305 0 0 1 9.693 6.5a11.239
11.239 0 0 1 2.42-.273 10.26 10.26 0 0 1 5.944
1.853A10.739 10.739 0 0 1 22 13.045a11.228
11.228 0 0 1-1.523 2.655 10.977 10.977 0 0
1-2.159 2.118zm1.318 5.136L15.818 19.2a8.83
8.83 0 0 1-1.8.489 12.016 12.016 0 0
1-2.023.17 10.42 10.42 0 0 1-6.023-1.852A10.856
10.856 0 0 1 2 13.045a10.313 10.313 0 0 1 1.261-2.307
12.919 12.919 0 0 1 1.966-2.147L2.364 5.727l.955-.977
17.2 17.2zM6.159 9.545a8.364 8.364 0 0 0-1.625 1.614
7.481 7.481 0 0 0-1.125 1.886A9.1 9.1 0 0 0 6.9
17.034a9.712 9.712 0 0 0 5.282 1.466 11.9 11.9 0
0 0 1.477-.091 3.53 3.53 0 0 0
1.091-.273l-1.45-1.454a2.379 2.379 0 0 1-.614.17 4.429
4.429 0 0 1-.682.057A3.758 3.758 0 0 1 9.273 15.8a3.7
3.7 0 0 1-1.136-2.75 4.141 4.141 0 0 1 .057-.682 2.783
2.783 0 0 1 .17-.614zm6.932 3.228zm-2.636 1.318z"
        transform="translate(-2 -4.75)"
        fill="#6d6e71"
    />
);

export const yellowMsgIcon = (
    <>
        <mask id="eu9kia90ta" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <path fill="#6D6E71" d="M0 0h24v24H0z" />
        </mask>
        <g mask="url(#eu9kia90ta)">
            <path
                d="M12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733
                        9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613
                        3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9
                        1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137
                        3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22zm-1-9h2V7h-2v6zm1 4a.968.968 0 0 0
                        .713-.288A.967.967 0 0 0 13 16a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 15a.967.967 0 0
                        0-.712.287A.968.968 0 0 0 11 16c0 .283.096.52.288.712A.965.965 0 0 0 12 17z"
                fill="#FFBD00"
            />
        </g>
    </>
);

export const temporalBlock =
    '¡Importante! Usuario se encuentra bloqueado. Intenta en 15 minutos o contacta a tú administrador.|El usuario o contraseña son inválidos';

export const userNotExist = 'El usuario, correo electrónico o teléfono no existe';
