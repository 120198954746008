import React from 'react';
import {DateTime} from 'luxon';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {Control, Controller, FieldValues, FormProvider, useForm} from 'react-hook-form';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_ADDRESS} from '@MyCompany/constants';
import {formatDate} from '@/hooks/currentFormatUtils';
import {DATE_FORMATS} from '@Shopify/ListClient/constants';
import {OPERATIONS} from '@Shopify/ClientProfile/constants';
import {ISelectReact} from '@/components/Quoter/inferfaces';
import * as style from '@Shopify/ClientProfile/slylesClient';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {endeavors} from '@Shopify/ClientProfile/Redux/Action/EndeavorsContracts';
import {ICatPawn, IFiltersModal, IFiltersPawn} from '@Shopify/ClientProfile/interfaces';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';

export const FiltersContracts = ({filters, setFilters}: IFiltersModal) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const {catStatus, token, clientId} = useSelector((state: RootState) => ({
        clientId: state.cardClientProfile.clientProfile.clientId,
        catStatus: state.endeavorsContracs,
        token: state.getUsersValidation.userData?.token as string,
    }));

    const selectConvert = (items: ICatPawn[]) =>
        items.map((item) => ({
            value: item.id,
            label: item.name,
        }));

    const {handleSubmit, control, reset} = useForm<IFiltersPawn>({
        defaultValues: {
            endDate: '',
            contractStatus: '' as unknown as ISelectReact,
        },
    });

    const closeModal = () => {
        setFilters(false);
        reset();
    };

    const convertDate = (date: string) => DateTime.fromJSDate(new Date(date));
    const onSubmit = async (values: IFiltersPawn) => {
        const json = {
            clientId: clientId,
            ...(values.endDate && {
                endDate: formatDate(convertDate(values.endDate), DATE_FORMATS.FULL_DATE),
            }),
            ...(values.contractStatus.value && {contractStatus: values.contractStatus.value}),
        };
        await dispatch(endeavors(token, json));
        closeModal();
    };

    return (
        <Modal
            Top="0"
            Justify="end"
            background="none"
            BorderRadius="1rem"
            modalState={filters}
            applyAnimation={true}
            titleModalState={false}
            changeModalState={() => setFilters(true)}
        >
            <FormProvider {...methods}>
                <ContainerForm onSubmit={handleSubmit(onSubmit)} {...style.contentForm}>
                    <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                        {OPERATIONS.ENDEAVORS.FILTER}
                    </Text>
                    <ContainerFlex {...style.conceptFilter}>
                        <Text {...style.conceptFilter.text}>{OPERATIONS.ENDEAVORS.EXPIRATION}</Text>
                        <CalendarDateSelection
                            name="endDate"
                            control={control as unknown as Control<FieldValues>}
                            placeholder=" /  / "
                            format="dd/MM/yyyy"
                            error={false}
                            limitDate={false}
                        />
                    </ContainerFlex>
                    <ContainerFlex {...style.conceptFilter}>
                        <Text {...style.conceptFilter.text}>
                            {OPERATIONS.ENDEAVORS.CONTRACT_STATE}
                        </Text>
                        <Controller
                            name="contractStatus"
                            control={control}
                            render={({field}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(catStatus.catStatusContract)}
                                />
                            )}
                        />
                    </ContainerFlex>

                    {catStatus.loading ? (
                        <ContainerFlex Align="end">
                            <LoadingAtaskate />
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Gap="1.5rem" Align="end">
                            <ButtonGeneral
                                width="100%"
                                text={UPDATE_ADDRESS.CANCEL}
                                clic={() => closeModal()}
                                secondaryButton
                            />
                            <ButtonGeneral width="100%" text={OPERATIONS.CONTINUE} />
                        </ContainerFlex>
                    )}
                </ContainerForm>
            </FormProvider>
        </Modal>
    );
};
