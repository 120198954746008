import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {ActionPayment} from '@components/QuickPayment/ActionPayment';
import {AccessPayment} from '@components/QuickPayment/AccessPayment';
import {STATE_ACTIONS} from '@components/QuickPayment/Constants';
import SuccessPaymentSummary from '@components/QuickPayment/SuccessPaymentSummary/SuccessPaymentSummary';

export const QuickPayment = () => {
    const actionState = useSelector(
        (state: RootState) => state.GetActionPayment.actionState as number
    );
    return (
        <ContainerFlex Width="100" Height="100vh" backG="#F0F0FF" Justify="center" Align="center">
            {actionState === STATE_ACTIONS.ACCESS_PAYMENT && <AccessPayment />}
            {actionState === STATE_ACTIONS.ACTION_PAYMENT && <ActionPayment />}
            {actionState === STATE_ACTIONS.SUCCESS_PAYMENT && <SuccessPaymentSummary />}
        </ContainerFlex>
    );
};
