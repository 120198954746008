import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants';

import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_ERROR,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_SUCCESS_PAGE,
} from '@Quoter/PromotionsRedux/types';

export const getPromotion = () => {
    return {type: GET_PROMOTIONS};
};
export const getPromotionSucess = (result: AxiosResponse) => {
    return {
        type: GET_PROMOTIONS_SUCCESS,
        payload: result,
    };
};
export const getPromotionSucessPage = (result: AxiosResponse) => {
    return {
        type: GET_PROMOTIONS_SUCCESS_PAGE,
        payload: result,
    };
};
export const getPromotionError = () => {
    return {type: GET_PROMOTIONS_ERROR};
};

export const searchgetPromotions = (
    token: string,
    filters: {
        branchId: number;
        ArticlesId: number[];
        loan: number;
        value: number;
        pageNumber: number;
        pageSize: number;
    }
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const articlesIdsQuery = filters.ArticlesId.map((id) => `ArticlesId=${id}`).join('&');
    const queryString = `?branchId=${filters.branchId}&${articlesIdsQuery}&loan=${filters.loan}&value=${filters.value}&pageSize=${filters.pageSize}&pageNumber=${filters.pageNumber}`;

    return async (dispatch: AppDispatch) => {
        dispatch(getPromotion());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PromotionsQuoter}${queryString}`,
                {headers}
            );
            dispatch(getPromotionSucess(response.data.data));
            dispatch(getPromotionSucessPage(response.data));
        } catch (error) {
            dispatch(getPromotionError());
        }
    };
};
