import {CalendarPicker, LocalizationProvider} from '@mui/x-date-pickers';
import React, {useCallback} from 'react';
import {DateTime} from 'luxon';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {
    CalendarSelect,
    CalendarWrapper,
} from '@/components/General/Atoms/CalendarDateSelection/Styles/styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {IRangeCalendarsProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {CalendarRangeButtons} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/CalendarRangeButtons';

export const RangeCalendars: React.FC<IRangeCalendarsProps> = ({
    years,
    months,
    dayOfWeekFormatter,
    setValue,
    initialDueDate,
    setInitialDueDate,
    endDueDate,
    setEndDueDate,
    showCalendarModal,
    setShowCalendarModal,
    handleClearRange,
    isSubmitted,
    trigger,
}) => {
    const handleContinue = () => {
        setValue('dateDueInit', initialDueDate.selectedDate);
        setValue('dateDueEnd', endDueDate.selectedDate);
        setInitialDueDate({
            ...initialDueDate,
            prevDate: initialDueDate.selectedDate,
        });
        setEndDueDate({
            ...endDueDate,
            prevDate: endDueDate.selectedDate,
        });
        if (isSubmitted) {
            trigger('dateDueInit');
            trigger('dateDueEnd');
        }
        setShowCalendarModal(!showCalendarModal);
    };
    const handleCancel = () => {
        setValue('dateDueInit', initialDueDate.prevDate);
        setValue('dateDueEnd', endDueDate.prevDate);
        setInitialDueDate({
            ...initialDueDate,
            selectedDate: initialDueDate.prevDate,
            selectedMonth: initialDueDate.prevDate
                ? initialDueDate.prevDate.month
                : DateTime.now().month,
            selectedYear: initialDueDate.prevDate
                ? initialDueDate.prevDate.year
                : DateTime.now().year,
        });
        setEndDueDate({
            ...endDueDate,
            selectedDate: endDueDate.prevDate,
            selectedMonth: endDueDate.prevDate ? endDueDate.prevDate.month : DateTime.now().month,
            selectedYear: endDueDate.prevDate ? endDueDate.prevDate.year : DateTime.now().year,
        });
        setShowCalendarModal(!showCalendarModal);
    };

    const handleDateChange = useCallback((date: DateTime | null, isInitDate: boolean) => {
        if (date) {
            if (isInitDate) {
                setInitialDueDate({
                    ...initialDueDate,
                    selectedMonth: date.month,
                    selectedYear: date.year,
                    selectedDate: date,
                });
                setValue('dateDueInit', date);
            } else {
                setEndDueDate({
                    ...endDueDate,
                    selectedMonth: date.month,
                    selectedYear: date.year,
                    selectedDate: date,
                });
                setValue('dateDueEnd', date);
            }
        }
    }, []);

    const handleMonthChange = (
        selectedOption: {value: number; label: string} | null | unknown,
        isInitDate: boolean
    ) => {
        if (selectedOption && typeof selectedOption === 'object' && 'value' in selectedOption) {
            const newMonth =
                typeof selectedOption.value === 'number'
                    ? selectedOption.value
                    : DateTime.now().month;
            const updatedDate = DateTime.local(
                initialDueDate.selectedYear,
                newMonth,
                initialDueDate.selectedDate?.day || 1
            );
            if (isInitDate) {
                setInitialDueDate({
                    ...initialDueDate,
                    selectedMonth: newMonth,
                    selectedDate: updatedDate,
                });
                setValue('dateDueInit', updatedDate);
            } else {
                setEndDueDate({
                    ...endDueDate,
                    selectedMonth: newMonth,
                    selectedDate: updatedDate,
                });
                setValue('dateDueEnd', updatedDate);
            }
        }
    };

    const handleYearChange = (
        selectedOption: {value: number; label: string} | null | unknown,
        isInitDate: boolean
    ) => {
        if (selectedOption && typeof selectedOption === 'object' && 'value' in selectedOption) {
            const newYear =
                typeof selectedOption.value === 'number'
                    ? selectedOption.value
                    : DateTime.now().year;
            const updatedDate = DateTime.local(
                newYear,
                initialDueDate.selectedMonth,
                initialDueDate.selectedDate?.day || 1
            );
            if (isInitDate) {
                setInitialDueDate({
                    ...initialDueDate,
                    selectedYear: newYear,
                    selectedDate: updatedDate,
                });
                setValue('dateDueInit', updatedDate);
            } else {
                setEndDueDate({
                    ...endDueDate,
                    selectedYear: newYear,
                    selectedDate: updatedDate,
                });
                setValue('dateDueEnd', updatedDate);
            }
        }
    };
    return (
        <>
            <GridContainer GridColumns="repeat(2, 1fr)">
                <ContainerFlex FlexDir="column">
                    <ContainerFlex Gap="0.5rem" Padding="0.5rem">
                        <CalendarSelect
                            value={
                                months.find(
                                    (month) => month.value === initialDueDate.selectedMonth
                                ) || null
                            }
                            options={months}
                            onChange={(option) => handleMonthChange(option, true)}
                        />
                        <CalendarSelect
                            value={
                                years.find((year) => year.value === initialDueDate.selectedYear) ||
                                null
                            }
                            options={years}
                            onChange={(option) => handleYearChange(option, true)}
                        />
                    </ContainerFlex>
                    <ContainerFlex>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <CalendarWrapper className="test">
                                <CalendarPicker
                                    dayOfWeekFormatter={dayOfWeekFormatter}
                                    date={initialDueDate.selectedDate}
                                    onChange={(e) => handleDateChange(e, true)}
                                    minDate={DateTime.local()}
                                    maxDate={DateTime.local(
                                        initialDueDate.selectedYear,
                                        initialDueDate.selectedMonth,
                                        DateTime.local(
                                            initialDueDate.selectedYear,
                                            initialDueDate.selectedMonth
                                        ).daysInMonth
                                    )}
                                    className="calendar-options"
                                />
                            </CalendarWrapper>
                        </LocalizationProvider>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex FlexDir="column">
                    <ContainerFlex Gap="0.5rem" Padding="0.5rem">
                        <CalendarSelect
                            value={
                                months.find((month) => month.value === endDueDate.selectedMonth) ||
                                null
                            }
                            options={months}
                            onChange={(option) => handleMonthChange(option, false)}
                        />
                        <CalendarSelect
                            value={
                                years.find((year) => year.value === endDueDate.selectedYear) || null
                            }
                            options={years}
                            onChange={(option) => handleYearChange(option, false)}
                        />
                    </ContainerFlex>
                    <ContainerFlex>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <CalendarWrapper className="test">
                                <CalendarPicker
                                    dayOfWeekFormatter={dayOfWeekFormatter}
                                    date={endDueDate.selectedDate}
                                    onChange={(e) => handleDateChange(e, false)}
                                    minDate={DateTime.local(
                                        initialDueDate.selectedYear,
                                        initialDueDate.selectedMonth,
                                        initialDueDate.selectedDate?.day || 1
                                    )}
                                    maxDate={DateTime.local(
                                        endDueDate.selectedYear,
                                        endDueDate.selectedMonth,
                                        DateTime.local(
                                            endDueDate.selectedYear,
                                            endDueDate.selectedMonth
                                        ).daysInMonth
                                    )}
                                    className="calendar-options"
                                />
                            </CalendarWrapper>
                        </LocalizationProvider>
                    </ContainerFlex>
                </ContainerFlex>
            </GridContainer>
            <CalendarRangeButtons
                handleCancel={handleCancel}
                handleClearRange={handleClearRange}
                handleContinue={handleContinue}
                continueDisabled={
                    !initialDueDate.selectedDate ||
                    initialDueDate.selectedDate.startOf('day') < DateTime.local().startOf('day')
                }
            />
        </>
    );
};
