import {FieldValues} from 'react-hook-form';
import * as yup from 'yup';
import {IPropertiesItems} from '@Quoter/inferfaces';
export const SEARCHREGEX = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\s&-_]+$/u;
export const schemaSearch = yup.object().shape({
    article: yup.string().min(2).required().max(255).matches(SEARCHREGEX),
});
export const DinamicInputSchema = (
    dinamic: FieldValues,
    propertiesarticles: IPropertiesItems[]
) => {
    propertiesarticles
        .filter((data) => data.status !== false)
        .forEach((item) => {
            switch (item.typeOfPropertyId) {
                case 1:
                    return (dinamic[item.articleDetailId] = yup
                        .string()
                        .required(item.mandatory ? 'Información requerida' : ''));
                case 2:
                    return (dinamic[item.articleDetailId] = yup
                        .number()
                        .required(item.mandatory ? 'Información requerida' : '')
                        .typeError('Seleccione una opción'));
                case 3:
                    return (dinamic[item.articleDetailId] = yup
                        .object()
                        .required(item.mandatory ? 'Seleccione una opción' : ''));
                case 4:
                    return (dinamic[item.articleDetailId] = yup.boolean().optional());
            }
        });
    const schemaDinamics = yup.object().shape({
        Group: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .typeError('Seleccione una opción'),
        Family: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .typeError('Seleccione una opción'),
        Subfamily: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .typeError('Seleccione una opción'),
        Brand: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .typeError('Seleccione una opción'),
        Article: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .typeError('Seleccione una opción'),
        Note: yup
            .string()
            .max(255, 'Solo se permiten hasta 255 caracteres')
            .matches(SEARCHREGEX, 'Este campo es obligatorio'),
        value: yup.string().required('Información requerida'),
        loan: yup.string().required('Información requerida'),
        Status: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
                state: yup.string(),
            })
            .typeError('Seleccione una opción'),

        ...dinamic,
    });
    return schemaDinamics;
};
