import React, {useCallback, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {RolByEnterpriceData, Role, selectCreateUsersModal} from '@components/CreateUsers/Interface';
import {MultiRolesForm} from '@components/CreateUsers/MultiRolesForm';
import {RootState, AppDispatch} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {getRoles} from '@Roles/Redux/Actions/CreateRoles';
import {useInfiniteScroll} from '@OrganizationCreation/SubContainer/TreeTableUtils/ReutilizableInfiniteScroll';
import ButtonGeneral from '@General/Atoms/Button';
import {IconContainer} from '@General/MenuAmazon/styles';
import frontArrow from '@components/CreateUsers/Icons/ArrowFront.svg';
import {ModalManageRolUser} from '@components/CreateUsers/ModalRol/ModalManageRolUser';
import {MultiRolesEmpty} from '@components/CreateUsers/MultiRolesEmpty';

export const SelectMultiRoles = (props: selectCreateUsersModal) => {
    const {
        modalStateRol,
        setModalStateRol,
        validateValuesEmployeeRoles,
        name,
        lastName,
        saveChangesForm,
        listEmployeeRoles,
    } = props;
    const navigate = useNavigate();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const Roles = useSelector((state: RootState) => {
        return state.AllRoles.tableData;
    });
    const searchedValue = useSelector((state: RootState) => {
        return state.SearchRole.search;
    });
    const dispatch: AppDispatch = useDispatch();
    const RolesPaginate = useSelector((state: RootState) => {
        return state.AllRoles;
    });
    const RoleFilters = useSelector((state: RootState) => {
        return state.SearchRole.filters;
    });
    const [currentPage, setCurrentPage] = useState(0);
    const handleIntersect = useCallback(() => {
        setCurrentPage((prev: number) => prev + 1);
    }, []);
    const targetRef = useInfiniteScroll({
        onIntersect: handleIntersect,
    });
    const filters = {
        searchRoleName: searchedValue,
        pageSize: 20,
        pageNumber: currentPage === 0 ? 1 : currentPage,
        enterpriseId: companyId,
        orderType: RoleFilters.orderType,
        filterRoles: RoleFilters.filterRoles,
    };
    const [selectedRoles, setSelectedRoles] = useState<RolByEnterpriceData[]>([]);
    const [prevRoles, setPrevRoles] = useState<RolByEnterpriceData[]>([]);

    const [modalStateManageRol, setModalStateManageRol] = useState(false);

    const selectRole = (roleId: RolByEnterpriceData) => {
        if (selectedRoles.some((data: RolByEnterpriceData) => data.id === roleId.id)) {
            setSelectedRoles(selectedRoles.filter((id) => id.id !== roleId.id));
        } else {
            setSelectedRoles([...selectedRoles, roleId]);
        }
    };
    useEffect(() => {
        if (listEmployeeRoles) {
            listEmployeeRoles.map((rol: Role) =>
                Roles.map((item: RolByEnterpriceData) => {
                    if (
                        selectedRoles.length < 2 &&
                        !selectedRoles.some((role) => role.id === item.id && item.id === rol.roleId)
                    ) {
                        setSelectedRoles((prevRol) => [...prevRol, item]);
                        setPrevRoles((prevRoles) => [...prevRoles, item]);
                    }
                    return item.id;
                })
            );
        }
    }, [Roles]);

    useEffect(() => {
        dispatch(getRoles(filters, token));
    }, []);
    useEffect(() => {
        if (currentPage <= RolesPaginate.totalPage.totalItems && currentPage > 1) {
            dispatch(getRoles(filters, token));
        }
    }, [currentPage]);

    const saveChanges = () => {
        validateValuesEmployeeRoles();
        setModalStateRol(false);
    };

    return (
        <Modal
            modalState={modalStateRol}
            changeModalState={() => {}}
            Height="auto"
            Width="786px"
            Align="start"
            titleModalState={false}
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            background="none"
        >
            {Roles.length === 0 ? (
                <MultiRolesEmpty setModalStateRol={setModalStateRol} />
            ) : (
                <ContainerFlex
                    backG="#fff"
                    Height="auto"
                    FlexDir="column"
                    BoxShadow=""
                    Justify="start"
                    Gap="32px"
                >
                    <Text
                        Padding="0 24px"
                        Color="#35CF44"
                        Height="56px"
                        FontSize="1.25rem"
                        BorderBt="1px solid #35CF44"
                        Width="100%"
                    >
                        Asignar rol
                    </Text>
                    <ContainerFlex
                        Height="auto"
                        Justify="space-between"
                        FlexWrap="wrap"
                        AlignContent="start"
                        Position="relative"
                        Gap="24px 0px"
                        Padding="0 24px"
                    >
                        <ContainerFlex Height="100%" Width="726px" FlexWrap="wrap" Justify="start">
                            <Text Color="#414042" Height="" FlexWrap="wrap" wSpace="pre">
                                Asigna el rol correspondiente a su puesto de trabajo y sus
                                funciones.{' '}
                                <Text Color="#414042" FontWeight="500" Height="" wSpace="normal">
                                    {' '}
                                    ¡Recuerda!, un usuario
                                </Text>
                                <Text Color="#414042" FontWeight="500" Height="" wSpace="pre">
                                    {'podrá tener 2 roles máximo.'}
                                </Text>
                            </Text>
                        </ContainerFlex>
                        <MultiRolesForm
                            selectedRoles={selectedRoles}
                            selectRole={selectRole}
                            containerRef={targetRef}
                        />
                        {selectedRoles.length >= 1 && (
                            <ContainerFlex Justify="space-between" Height="auto">
                                <Text
                                    FontSize=".875rem"
                                    Color="#414042"
                                    FontWeight="500"
                                    Width="auto"
                                >
                                    Roles sin suplentes asignados:
                                </Text>
                                {selectedRoles.map((data: RolByEnterpriceData) => {
                                    return (
                                        <Text
                                            key={data.id}
                                            Width="auto"
                                            FontSize=".875rem"
                                            Color={
                                                data.users !== 'Sin asignar' ||
                                                (data.substituteEmployee &&
                                                    data.substituteEmployee?.length > 0)
                                                    ? '#35cf44'
                                                    : '#ef4f55'
                                            }
                                        >
                                            • {data.name}
                                        </Text>
                                    );
                                })}
                                <ButtonGeneral
                                    width="147px"
                                    text="Asignar suplente"
                                    Fontsize="0.875"
                                    type="submit"
                                    hColor="#35cf44"
                                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                                    transform=""
                                    borderHover="solid 1px #a7a9ac"
                                    hText="#35cf44"
                                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                                    clic={() => setModalStateManageRol(true)}
                                />
                            </ContainerFlex>
                        )}
                        <ContainerFlex
                            Justify="center"
                            Gap="1.5rem"
                            Padding="24px 0px 24px 0px"
                            Height="88px"
                            Bt="solid 1px #e5e7e9;"
                        >
                            <ContainerFlex
                                Width="auto"
                                Height="88px"
                                onClick={() => {
                                    navigate('/CreateRoles/AddRoles');
                                    if (saveChangesForm) saveChangesForm();
                                }}
                            >
                                <Text
                                    FontSize="0.875rem"
                                    Color="#35cf44"
                                    Cursor="pointer"
                                    MarginRight="5px"
                                >
                                    Agregar un rol
                                </Text>
                                <IconContainer Height="12px" Width="12px" src={frontArrow} />
                            </ContainerFlex>
                            <ButtonGeneral
                                width="5.8125rem"
                                Fontsize="0.875"
                                text="Cancelar"
                                type="submit"
                                border="transparent"
                                bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                                hColor="#35cf44"
                                transform=""
                                borderHover="solid 1px #a7a9ac"
                                hBorder=""
                                hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                                clic={() => {
                                    setModalStateRol(false);
                                }}
                            />
                            <ButtonGeneral
                                disabled={selectedRoles.length === 0}
                                width="10.5rem"
                                text="Guardar y continuar"
                                Fontsize="0.875"
                                type="submit"
                                hColor="#35cf44"
                                bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                                transform=""
                                borderHover="solid 1px #a7a9ac"
                                hText="#35cf44"
                                hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                                clic={() => saveChanges()}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}

            {modalStateManageRol && (
                <ModalManageRolUser
                    setModalStateManageRol={setModalStateManageRol}
                    modalStateManageRol={modalStateManageRol}
                    name={name}
                    lastName={lastName}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                    prevRoles={prevRoles}
                />
            )}
        </Modal>
    );
};
