export const EMAIL_VERIFICATION = {
    TITLE: '  Verifica tu correo',
    MESSAGE: 'Hemos enviado el código de verificación de 4 ',
    EMAIL: 'dígitos a tu correo ',
    EMAIL_NAME: 'carlos_torres@gmail.com',
    TIMER_TEXT: 'Volver a solicitar código en ',
    REQUEST_NEW_CODE: ' Solicitar un nuevo código',

    SECONDS: 'seg',
};
