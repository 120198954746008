import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import ataskateWolfIcon from '@/components/PersonalLoans/icons/ataskateWolfIcon.svg';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import PersonalLoansBankTransaction from '@/components/PersonalLoans/PersonalLoansBankTransaction';
import PersonalLoansAccordion from '@/components/PersonalLoans/PersonalLoansAccordion';
import bankIcon from '@/components/PersonalLoans/icons/bankIcon.svg';
import walletIcon from '@/components/PersonalLoans/icons/walletIcon.svg';
import unCheckIcon from '@/components/PersonalLoans/icons/unCheckIcon.svg';
import checkedIcon from '@/components/PersonalLoans/icons/checkedIcon.svg';
import {ICreditLoanDeposit} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const PersonalLoanSemiApprovedDeposit: React.FC<ICreditLoanDeposit> = ({
    nameClient,
    requestedLoan,
    loanGranted,
    control,
    errors,
}) => {
    const [hasErrorValidation, setErrorValidation] = useState(false);
    const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
    const handleExpandAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedPanel(isExpanded ? panel : false);
        };
    useEffect(() => {
        setErrorValidation(errors.keyAccount || errors.catBank ? true : false);
    }, [errors.keyAccount, errors.catBank]);
    return (
        <ContainerFlex
            FlexDir="column"
            Height="100%"
            Width="100%"
            Justify="start"
            Align="start"
            Gap="24px"
        >
            <ContainerFlex
                Flex="0"
                Width="100%"
                Height="100%"
                Padding="20px 32px"
                Justify="space-between"
                Align="center"
                backG="#FAFAFF"
                Radius="24px"
            >
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Gap="16px"
                    FlexDir="column"
                    Justify="center"
                    Align="start"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.5rem" Color="#1D1E20">
                        {`!${nameClient}, ${PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANNER_MESSAGE_NAME_SA}`}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.75rem" Color="#1D1E20">
                        {`${
                            PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                .BANNER_MESSAGE_HAS_APPROVED_SA
                        }
                    ${numberToCurrencyWithoutCents(loanGranted)}
                    ${
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                            .BANNER_MESSAGE_HAS_APPROVED_OF_SA
                    }
                    ${numberToCurrencyWithoutCents(requestedLoan)}
                    ${
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                            .BANNER_MESSAGE_CREDIT_REQUESTED_SA
                    }`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Flex="0"
                Width="100%"
                Height="100%"
                FlexDir="column"
                Align="start"
                Justify="start"
                Gap="8px"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.SUBTITLE}
                </Text>
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.SUBTITLE_PARAGRAPH}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Flex="1"
                Width="100%"
                Height="100%"
                Gap="16px"
                Justify="start"
                Align="start"
                FlexDir="column"
            >
                <PersonalLoansAccordion
                    summaryIcon={bankIcon}
                    expandedIcon={checkedIcon}
                    collapsedIcon={unCheckIcon}
                    summaryTitle={
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_BANK_TRANSFER_TITLE
                    }
                    summaryParagraph={
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_BANK_TRANSFER_PARAGRAPH
                    }
                    expanded={
                        hasErrorValidation ||
                        expandedPanel ===
                            PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_BANK_TRANSFER_PANEL
                    }
                    onChange={handleExpandAccordion(
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_BANK_TRANSFER_PANEL
                    )}
                >
                    <PersonalLoansBankTransaction control={control} errors={errors} />
                </PersonalLoansAccordion>
                <PersonalLoansAccordion
                    summaryIcon={walletIcon}
                    expandedIcon={checkedIcon}
                    collapsedIcon={unCheckIcon}
                    summaryTitle={
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_ATASKATE_WALLET_TITLE
                    }
                    summaryParagraph={
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                            .ACC_ATASKATE_WALLET_PARAGRAPH
                    }
                    expanded={
                        expandedPanel ===
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_ATASKATE_WALLET_PANEL
                    }
                    onChange={handleExpandAccordion(
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.ACC_ATASKATE_WALLET_PANEL
                    )}
                />
            </ContainerFlex>
            <ContainerFlex
                Flex="0"
                Width="100%"
                Height="100%"
                Padding="16px 32px 16px 16px"
                Justify="space-between"
                Border="1px solid #00B4DE"
                Align="center"
                backG="#D0F6FF"
                Radius="24px"
            >
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Gap="16px"
                    FlexDir="column"
                    Justify="center"
                    Align="start"
                >
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#1D1E20">
                        {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANNER_MESSAGE_SA}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {
                            PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                .BANNER_MESSAGE_PARAGRAPH_SA
                        }
                    </Text>
                </ContainerFlex>
                <Image height="112px" src={ataskateWolfIcon} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalLoanSemiApprovedDeposit;
