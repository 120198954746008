import {
    GET_DATA_MODULES,
    GET_DATA_MODULES_SUCCESS,
    GET_DATA_MODULES_ERROR,
    UPDATE_DATA_MODULES,
    UPDATE_INFO_MODULES,
} from '@Roles/AddRoles/Redux/Types';
import {TypeResponseData} from '@Roles/AddRoles/interfaces';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

const getDataModules = (state = initialState, action: TypeResponseData) => {
    switch (action.type) {
        case GET_DATA_MODULES:
            return {...state, loading: true, error: false};
        case GET_DATA_MODULES_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        case GET_DATA_MODULES_ERROR:
            return {...state, error: true};
        case UPDATE_DATA_MODULES:
            return {
                ...state,
                data: {...state.data, categorys: action.updateCategorys},
            };
        case UPDATE_INFO_MODULES:
            return {
                ...state,
                data: action.updateInfoModules,
            };
        default:
            return state;
    }
};

export default getDataModules;
