import {
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START,
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS,
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR,
    GET_CREDIT_LOAN_DETAIL_BY_ID_START,
    GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS,
    GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const GetPersonalCreditAmountByIdStart = () => {
    return {
        type: GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START,
    };
};

export const GetPersonalCreditAmountByIdSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS,
        payload: payload,
    };
};

export const GetPersonalCreditAmountByIdError = (error: AxiosError) => {
    return {
        type: GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR,
        error: error,
    };
};

export const GetCreditLoanDetailByIdStart = () => {
    return {
        type: GET_CREDIT_LOAN_DETAIL_BY_ID_START,
    };
};

export const GetCreditLoanDetailByIdSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS,
        payload: payload,
    };
};

export const GetCreditLoanDetailByIdError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR,
        error: error,
    };
};

export const GetPersonalCreditAmountById = (personalCreditId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(GetPersonalCreditAmountByIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetByIdPersonalCreditAmount}`,
                {headers, params: {personalCreditId}}
            );
            dispatch(GetPersonalCreditAmountByIdSuccess(response));
        } catch (error) {
            dispatch(GetPersonalCreditAmountByIdError(error as AxiosError));
        }
    };
};

export const GetCreditLoanDetailById = (personalCreditId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(GetCreditLoanDetailByIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetByIdCreditLoanDetail}`,
                {headers, params: {personalCreditId}}
            );
            dispatch(GetCreditLoanDetailByIdSuccess(response));
        } catch (error) {
            dispatch(GetCreditLoanDetailByIdError(error as AxiosError));
        }
    };
};
