import axios, {AxiosResponse} from 'axios';
import {
    CREATE_POSTAL_INFO,
    CREATE_POSTAL_INFO_ERROR,
    CREATE_POSTAL_INFO_SUCCESS,
    RESET_POSTAL_INFO,
} from '@components/Branches/ManageBranch/Redux/Types/types';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetPostalInfoStart = () => {
    return {
        type: CREATE_POSTAL_INFO,
    };
};
export const fetchGetPostalInfoSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_POSTAL_INFO_SUCCESS,
        payload: result.data,
    };
};
export const fetchGetPostalInfoError = () => {
    return {
        type: CREATE_POSTAL_INFO_ERROR,
    };
};

export function GetPostalInfo(postalCode: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetPostalInfoStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPostalInfo}`,
                {
                    headers,
                    params: {
                        postalCode,
                    },
                }
            );
            dispatch(fetchGetPostalInfoSuccess(response));
        } catch (error) {
            dispatch(fetchGetPostalInfoError());
        }
    };
}

export const GetPostalInfoReset = () => {
    return {
        type: RESET_POSTAL_INFO,
    };
};
