import {AppDispatch} from '@/config/store';
import {
    TEMPORAL_PAWN,
    TEMPORAL_PAWN_ERROR,
    TEMPORAL_PAWN_SUCCESS,
    TEMPORAL_PAWN_BLOCK,
} from '@TypesQouter/TypesGeneral';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {ITemporalyPawn} from '@components/Quoter/inferfaces';
export const postTemporal = () => {
    return {
        type: TEMPORAL_PAWN,
    };
};
export const postTemporalSuccess = (data: AxiosResponse<string>) => {
    return {
        type: TEMPORAL_PAWN_SUCCESS,
        payload: data,
    };
};
export const postTemporalError = (value: unknown) => {
    return {
        type: TEMPORAL_PAWN_ERROR,
        payload: value,
    };
};

export const blockPetition = (value: boolean) => {
    return {
        type: TEMPORAL_PAWN_BLOCK,
        block: value,
    };
};

export function postTemporalPawn(token: string, data: ITemporalyPawn) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postTemporal());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostCartTemporal}`,
                data,
                {headers}
            );
            dispatch(postTemporalSuccess(response.data));
            dispatch(blockPetition(true));
        } catch (error) {
            dispatch(postTemporalError(error));
            dispatch(blockPetition(false));
        }
    };
}
