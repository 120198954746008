import {AxiosError, AxiosResponse} from 'axios';
import {SELECTED_DATA_NODE} from '@components/Articles/Redux/Types';
import {DataNodeProps, DataSelectedNode} from '@Articles/Redux/interfaces';

const initialState: DataSelectedNode = {
    dataNode: [],
};
const SelectedDataNode = (
    state: DataSelectedNode = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; value: DataNodeProps[]}
): DataSelectedNode => {
    switch (action.type) {
        case SELECTED_DATA_NODE:
            return {...state, dataNode: action.value};
        default:
            return state;
    }
};
export default SelectedDataNode;
