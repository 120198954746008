import {
    SEARCH_ALL_USERS,
    SEARCH_ALL_USERS_SUCCESS,
    SEARCH_ALL_USERS_ERROR,
    SEARCH_ALL_USERS_STATUS_200,
} from '@components/Users/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: null,
    pageSize: null,
    totalPages: null,
    totalItems: null,
    pageNumber: null,
};

const getSearchAllUsers = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case SEARCH_ALL_USERS:
            return {...state, loading: true};
        case SEARCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                pageSize: action.payload.data.pageSize,
                totalPages: action.payload.data.totalPages,
                totalItems: action.payload.data.totalItems,
                pageNumber: action.payload.data.pageNumber,
            };
        case SEARCH_ALL_USERS_ERROR:
            return {...state, loading: false, error: true};
        case SEARCH_ALL_USERS_STATUS_200:
            return {...state, searchAllUserStatus200: true};
        default:
            return state;
    }
};

export default getSearchAllUsers;
