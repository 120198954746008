import {PAGE_NUMBERED, STATE, UPDATE_STATE} from '@TypesOrgCreation/types';

export const checkedState = (checkedState: {}) => {
    return {
        type: STATE,
        payload: checkedState,
    };
};
export const fetchUpdateSelectNodeId = (value: any) => {
    return {
        type: UPDATE_STATE,
        payload: value,
    };
};
export const updatePageNumber = (pages: number) => {
    return {
        type: PAGE_NUMBERED,
        payload: pages,
    };
};
