import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_DEADLINE,
    GET_DEADLINE_SUCCESS,
    GET_DEADLINE_ERROR,
    FILTERS,
} from '@ReduxDeadlinesindex/types/DeadlineTypes';

const InitialState = {
    deadline: null,
    loading: false,
    error: false,
    deadlineCount: null,
    searchDeadlines: '',
    pageSize: 10,
    actualPages: 1,
    filterHead: 0,
    orderType: 0,
};
const deadline = (state = InitialState, action: ResponseData) => {
    switch (action.type) {
        case GET_DEADLINE:
            return {
                ...state,
                loading: true,
            };
        case GET_DEADLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                deadline: action.payload.data,
                deadlineCount: action.payload,
            };
        case GET_DEADLINE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case FILTERS.SEARCH_DEADLINE:
            return {
                ...state,
                searchDeadlines: action.payload,
            };
        case FILTERS.ACTUAL_PAGE:
            return {
                ...state,
                actualPages: action.payload,
            };
        case FILTERS.FILTER_HEAD:
            return {
                ...state,
                filterHead: action.payload,
            };
        case FILTERS.ORDER_TYPE:
            return {
                ...state,
                orderType: action.payload,
            };
        case FILTERS.DELETE_SHEARCH:
            return {
                ...state,
                searchDeadlines: '',
                actualPages: 1,
                filterHead: 0,
                orderType: 0,
            };
        default:
            return state;
    }
};

export default deadline;
