import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerForm,
    Image,
} from '@/components/Shopify/Ecommerce/styles';
import {
    DATA_FORM,
    InputStyle,
    TextInput,
    Grids,
    TextInputEdit,
    GENERAL_TITLES,
} from '@components/Users/UserProfile/constants';
import {
    contactSchema,
    DEFAULT_VALUES_CONTACT,
    IContactValues,
} from '@components/Users/UserProfile/yupSchema';
import {yupResolver} from '@hookform/resolvers/yup';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import Done from '@images/Check_normal.svg';
import Edit from '@images/editIcon.svg';
import {useDispatch, useSelector} from 'react-redux';
import {IScreenFormPersonalInfo} from '@components/Users/Redux/Reducers/FormUserInfo';
import {RootState} from '@/config/store';
import {getAllFormDataAddress} from '@components/Users/Redux/Actions/ScreenUseSelector';

export const AddressInfo = () => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        reset,
        register,
        formState: {errors},
    } = useForm({
        defaultValues: DEFAULT_VALUES_CONTACT,
        resolver: yupResolver(contactSchema),
    });
    const FormData: IScreenFormPersonalInfo = useSelector(
        (state: RootState) => state.ScreenStateUsersForm
    );
    const [isEdit, setIsEdit] = useState(false);

    const isEmpty = (data: string) => (
        <Text {...TextInputEdit}>{data?.length ? data : GENERAL_TITLES.EMPITY}</Text>
    );
    const onSubmit = (data: IContactValues) => {
        dispatch(getAllFormDataAddress(data));
        setIsEdit(false);
    };
    useEffect(() => {
        reset(FormData.address);
    }, []);
    return (
        <ContainerForm FlexDir="column" Gap="16px" onSubmit={handleSubmit(onSubmit)}>
            <ContainerFlex Justify="space-between" Height="40px" Padding="0 0 16px 0">
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.TITLE_CONTACT_HEADER}
                </Text>
                {isEdit ? (
                    <ButtonGenerals SecondaryButton="none" Height="40px">
                        <Image alt="save" src={Done} />
                        {GENERAL_TITLES.SAVE_CHANGES}
                    </ButtonGenerals>
                ) : (
                    <ContainerFlex ColumnGap="8px" Justify="end">
                        <Image alt="save" src={Edit} />
                        <Text
                            FontWeight="700"
                            Color="#5A5AFF"
                            Cursor="pointer"
                            onClick={() => setIsEdit(true)}
                        >
                            {GENERAL_TITLES.EDIT}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
            <ContainerFlex {...Grids}>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.POSTAL}</Text>
                    {isEdit ? (
                        <Input error={!!errors.postal?.message} {...register('postal')} />
                    ) : (
                        isEmpty(
                            FormData.address.postal === 0 ? '' : String(FormData.address.postal)
                        )
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.STREET}</Text>
                    {isEdit ? (
                        <Input error={!!errors.street?.message} {...register('street')} />
                    ) : (
                        isEmpty(String(FormData.address.street))
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.NO}</Text>
                    {isEdit ? (
                        <Input
                            error={!!errors.outsideNumber?.message}
                            {...register('outsideNumber')}
                        />
                    ) : (
                        isEmpty(String(FormData.address.outsideNumber))
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.NO_IN}</Text>
                    {isEdit ? (
                        <Input
                            error={!!errors.insideNumber?.message}
                            {...register('insideNumber')}
                        />
                    ) : (
                        isEmpty(String(FormData.address.insideNumber))
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.COLONY}</Text>
                    {isEdit ? (
                        <Input error={!!errors.colony?.message} {...register('colony')} />
                    ) : (
                        isEmpty(FormData.address.colony)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.TOWN}</Text>
                    {isEdit ? (
                        <Input error={!!errors.town?.message} {...register('town')} />
                    ) : (
                        isEmpty(FormData.address.town)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.STATE}</Text>
                    {isEdit ? (
                        <Input error={!!errors.state?.message} {...register('state')} />
                    ) : (
                        isEmpty(FormData.address.state)
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
