import React from 'react';
import {StorageData} from '@components/LocationsBranch/StorageData';
import {SearchInput} from '@Customer/Styles';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {STORAGE} from '@components/LocationsBranch/constants';
import {IStorageLevelListProps} from '@components/LocationsBranch/interfaces';
import HelpIcon from '@images/help.svg';
import AddIconBlue from '@images/addIcon.svg';
import MoreMenuIcon from '@images/moreMenu.svg';

export const StorageLevelList = ({
    storage,
    onAddNewLevel,
    onAddNewRow,
    onClickNewRow,
    setEditId,
    floorIndex,
}: IStorageLevelListProps) => {
    return (
        <ContainerFlex
            Display="grid"
            GridColumns="1fr 1fr 1fr"
            Justify="start"
            Align="flex-start"
            Gap="1rem"
            MarginTop="1rem"
            FlexWrap="wrap"
        >
            {storage &&
                storage.levels &&
                storage.levels.map((level, index) => (
                    <ContainerFlex
                        Padding="0.75rem 0.5rem 0.5rem 0.5rem"
                        FlexDir="column"
                        Align="flex-start"
                        Flex="0 calc(33.333% - 1rem)"
                        Radius="0.5rem"
                        backG={level.name ? '#FFFFFF' : '#F0F0FF'}
                        Height={'14rem'}
                        Justify="start"
                        key={index}
                    >
                        {level.name && (
                            <>
                                <ContainerFlex Height={'1.5rem'}>
                                    <ContainerFlex Justify="start" Flex="3">
                                        <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500">
                                            {level.name}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Justify="start" Flex="6">
                                        <Text FontSize="0.75rem">
                                            {level.productQuantity} {STORAGE.PRODUCTS}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Justify="end" Flex="3">
                                        <Text FontSize="0.75rem">
                                            {STORAGE.LEVEL} {level.levelId}
                                        </Text>
                                        <Image
                                            src={MoreMenuIcon}
                                            alt="add"
                                            Width="0.25rem"
                                            Height="1rem"
                                            Margin="0.125rem 0 0 1.125rem"
                                        />
                                    </ContainerFlex>
                                </ContainerFlex>
                                <ContainerFlex
                                    Justify="start"
                                    Height="2.5rem"
                                    backG="#FFFFFF"
                                    onClick={() => {
                                        onClickNewRow(level.id, true, floorIndex);
                                    }}
                                >
                                    <Image src={AddIconBlue} alt="add" color="#5A5AFF" />
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="500"
                                        Cursor="pointer"
                                        FontSize="1rem"
                                    >
                                        {STORAGE.NEW}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Display={'block'} OverFlow="auto" Height={'none'}>
                                    {level.rows &&
                                        level.rows.map((row, index) => (
                                            <StorageData
                                                key={index}
                                                storageType={row}
                                                justifyContent="start"
                                            />
                                        ))}
                                </ContainerFlex>
                                {level.showRowLevelInput && (
                                    <ContainerFlex
                                        Position="relative"
                                        FlexDir="column"
                                        Height={'3.5rem'}
                                        PaddingT="1rem"
                                    >
                                        <SearchInput
                                            GridColumn="90% 10%"
                                            FontSize="1rem"
                                            Right="none"
                                        >
                                            <input
                                                type="text"
                                                name="rowLevel"
                                                onKeyDown={(e) => {
                                                    onAddNewRow(e, floorIndex);
                                                }}
                                                onFocus={() => {
                                                    setEditId(level.id);
                                                }}
                                                autoFocus
                                            />
                                            <Image src={HelpIcon} />
                                        </SearchInput>
                                    </ContainerFlex>
                                )}
                            </>
                        )}
                        {level.showNewLevel && (
                            <ContainerFlex Position="relative" FlexDir="column" Height={'2.5rem'}>
                                <SearchInput GridColumn="90% 10%" FontSize="1rem" Right="none">
                                    <input
                                        type="text"
                                        name="level"
                                        onKeyDown={(e) => {
                                            onAddNewLevel(e, floorIndex);
                                        }}
                                        autoFocus
                                        onFocus={() => {
                                            setEditId(level.id);
                                        }}
                                    />
                                    <Image src={HelpIcon} />
                                </SearchInput>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                ))}
        </ContainerFlex>
    );
};
