import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@images/clousedGIcone.svg';
import doneIcon from '@images/DoneGreen.svg';
import {Icon} from '@/components/MarketForgottenLogin/Operational/styles';
import {RESTORE_NEW_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import {IError} from '@/components/UserProfileResetPassword/Operational/interface';
export const ErrorPasswordRepeat = ({error}: IError) => {
    const errorIcon = error ? closeIcon : doneIcon;
    const errorIconColor = error ? '#A82424' : '#309C60';

    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <Icon
                src={errorIcon}
                alt={error ? RESTORE_NEW_PASSWORD.CLOSE : RESTORE_NEW_PASSWORD.DONE}
                color={errorIconColor}
            />
            <Text
                Color={error ? '#A82424' : '#309C60'}
                FontSize="0.85rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
            >
                {error ? RESTORE_NEW_PASSWORD.REPEAT_PASS : RESTORE_NEW_PASSWORD.SAME_PASSWORD}
            </Text>
        </ContainerFlex>
    );
};
