import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {HeaderTitleTransaction} from '@CashFlow/constantsAtaskateFlow';
import {addSpaceToCurrency, formatterDolar} from '@/hooks/currentFormatUtils';
import {IconContainer} from '@Quoter/styles';
import {SearchFilterTrasn} from '@CashFlow/SearchFilterTrans';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@/config/store';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getAllTransactions} from '@components/CashFlow/Redux/Actions/GetCashTransaction';
import {PaginatedForTransactionTable} from '@components/CashFlow/PaginatedForTransactionTable';
import {IFilterCashFlow, IFilterParams, ITransactions} from '@components/CashFlow/interfaces';
import {getAllPawnsDetails} from '@components/TransactionsDetails/Redux/actions/getPwnDetails';

export const TransactionTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilterData] = useState<IFilterCashFlow>({} as IFilterCashFlow);
    const branchId = useSelector(
        (state: RootState) => state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId,
        shallowEqual
    );

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const filterPersist: IFilterParams = useSelector(
        (state: RootState) => state.filtersPersistance
    );

    const handlePrevPage = () => {
        if (currentPage <= 1) {
            setCurrentPage(1);
        } else setCurrentPage(currentPage - 1);
    };

    const transactions = useSelector(
        (state: RootState) => state.GetAllTransactions.userData as ITransactions[] | null,
        shallowEqual
    );
    const totalPages = useSelector(
        (state: RootState) => state.GetAllTransactions.totalPages,
        shallowEqual
    );
    const totalItems = useSelector(
        (state: RootState) => state.GetAllTransactions.totalItems,
        shallowEqual
    );

    const paginate = {
        pageSize: 10,
        pageNumber: currentPage,
        search: search,
    };

    const dataToDisplay = transactions || [];

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        if (branchId) {
            dispatch(getAllTransactions(filterPersist, branchId, paginate, token));
        }
    }, [filterPersist, paginate.pageNumber, paginate.search]);

    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <SearchFilterTrasn
                search={search}
                setSearch={setSearch}
                filterData={filteredData}
                setFilterData={setFilterData}
            />
            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="repeat(8, 1fr)"
                    Border="0 0 1px 0 solid red"
                    Height="40px"
                    Gap="8px"
                >
                    {HeaderTitleTransaction.map((data, index) => (
                        <TableItem Justify="start" Gap="8px" Width="100%" key={index}>
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" wSpace="">
                                {data.name}
                            </Text>
                        </TableItem>
                    ))}
                </TableRowHead>
                <TableItemsContainer>
                    {dataToDisplay.map((data: ITransactions, index) => {
                        if (!data) return null;

                        return (
                            <TableRowItems
                                Height="40px"
                                GridColumn="repeat(8, 1fr)"
                                BorderB="0.5px solid #e4e7e9"
                                Border=""
                                key={index}
                            >
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {data.transactionType}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" MaxW="13rem">
                                    <TextEllipsis
                                        FontSize="0.875rem"
                                        Color="#54575C"
                                        FontWeight="400"
                                    >
                                        {data.userNameBox}
                                    </TextEllipsis>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" FontWeight="700" Color="#5A5AFF">
                                        {data.sessionId}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {data.paymentType}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {data.date}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Gap="24px">
                                    <Text FontSize="0.875rem" Color="#54575C">
                                        {data.hour}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Gap="24px">
                                    <Text
                                        onClick={async () => {
                                            try {
                                                await dispatch(
                                                    getAllPawnsDetails(String(data.id), token)
                                                );
                                                navigate('/ProductDetails');
                                            } catch (error) {
                                                return error;
                                            }
                                        }}
                                        FontSize="0.875rem"
                                        Color="#54575C"
                                    >
                                        {data.transactioId}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="end" Gap="60px">
                                    <Text
                                        FontSize="0.875rem"
                                        Color={data.isEntry ? '#309C60' : '#A82424'}
                                    >
                                        {`${data.isEntry ? '+' : '-'} ${addSpaceToCurrency(
                                            formatterDolar.format(data.amount)
                                        )}`}
                                    </Text>
                                    <IconContainer
                                        FontSize="0.875rem"
                                        className="material-icons"
                                        Color="#ACACFF"
                                    >
                                        more_vert
                                    </IconContainer>
                                </TableItem>
                            </TableRowItems>
                        );
                    })}
                </TableItemsContainer>
            </Table>
            {dataToDisplay.length === 0 ? (
                <div />
            ) : (
                <ContainerFlex Justify="center">
                    <PaginatedForTransactionTable
                        pageNumber={currentPage}
                        totalPage={totalPages}
                        firstPage={() => setCurrentPage(1)}
                        lastPage={() => setCurrentPage(totalPages)}
                        totalItems={totalItems}
                        nextPage={handleNextPage}
                        prevPage={handlePrevPage}
                    />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
