import React from 'react';
import {ContainerFlex, ContainerGrid, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IProductsTableProps} from '@/components/LoansProducts/interfaces';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import threePoints from '@images/threeDotsBlack.svg';

const ProductsTable = ({dataTable}: IProductsTableProps) => {
    const formatCurrency = (value: number): string => {
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
    };
    const formatTimeValue = (value: number | string): string | null => {
        if (!value) return null;
        return Number(value) === 1 ? `${value} mes` : `${value} meses`;
    };
    return (
        <ContainerFlex
            FlexDir="column"
            Height="220px"
            OverFlowY="auto"
            Justify="start"
            Align="start"
        >
            {dataTable.map((item) => (
                <ContainerGrid
                    key={item.termId}
                    GridColumns="2fr 2fr 2fr 2fr 2fr 1.25fr"
                    Height="2.75rem"
                    BorderBt="1px solid #E8E9EA"
                    Padding="0.5rem 1rem"
                    Gap="1rem"
                >
                    <Text Color="#2A2C2F" FontSize="0.875rem">
                        {item.termName}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem">
                        {formatCurrency(item.minimumQuantity)}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem">
                        {formatCurrency(item.maximumAmount)}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem">
                        {formatTimeValue(item.minimumTime)}
                    </Text>
                    <Text Color="#2A2C2F" FontSize="0.875rem">
                        {formatTimeValue(item.maximumTime)}
                    </Text>
                    <ContainerFlex Justify="start" Align="center" Gap="16px" Width="88px">
                        <GreenSwitch defaultChecked={item.active} />
                        <Image src={threePoints} alt="add" />
                    </ContainerFlex>
                </ContainerGrid>
            ))}
        </ContainerFlex>
    );
};

export default ProductsTable;
