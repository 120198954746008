import styled from '@emotion/styled';
import {IPropsFingerprintBox} from '@components/Shopify/ListClient/FingerprintRegistration/styles/interfaces';

export const FingerprintBox = styled.div<IPropsFingerprintBox>`
    display: flex;
    position: relative;
    flex-direction: column;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    background: ${(props) => props.Background};
    border: ${(props) => props.Border};
    border-radius: 7px;
    transition: border-color 0.3s ease;
    cursor: ${(props) => props.Cursor};
    left: ${(props) => props.Left};
    right: ${(props) => props.Right};
    top: ${(props) => props.Top};
`;
