import axios, {AxiosResponse} from 'axios';
import {
    GET_ROLE_VALIDATE,
    GET_ROLE_VALIDATE_SUCCESS,
    GET_ROLE_VALIDATE_ERROR,
    FILTERS,
    GET_ROLE_VALIDATE_EDIT,
    GET_ROLE_VALIDATE_EDIT_SUCCEES,
    GET_ROLE_VALIDATE_EDIT_ERROR,
    SEARCHED,
    RESET_VALIDATE,
} from '@TypesRoles/types';

import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {TypeParamsValidateNameEdit} from '@Roles/AddRoles/interfaces';
export const resetSearch = (searched: string) => {
    return {
        type: SEARCHED,
        payload: searched,
    };
};
export const resetValidate = () => {
    return {
        type: RESET_VALIDATE,
    };
};
export const fetchSearchedValue = (searched: string) => {
    return {
        type: SEARCHED,
        payload: searched,
    };
};
export const fetchFilters = (data: {orderType: number; filterRoles: number}) => {
    return {
        type: FILTERS,
        filters: data,
    };
};
export const getValidate = () => {
    return {
        type: GET_ROLE_VALIDATE,
    };
};
export const getValidateSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ROLE_VALIDATE_SUCCESS,
        roleSearch: result.data,
    };
};
export const getValidateError = () => {
    return {
        type: GET_ROLE_VALIDATE_ERROR,
    };
};
export function GetRoleValidateName(
    filters: {
        TypeRoleId: number;
        companyId: number;
        roleName: string;
    },
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(getValidate());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateRoleEdit}${queryString}`,
                {headers}
            );
            dispatch(getValidateSuccess(response));
        } catch (error) {
            dispatch(getValidateError());
        }
    };
}
export const getValidateEdit = () => {
    return {
        type: GET_ROLE_VALIDATE_EDIT,
    };
};
export const getValidateSuccessEdit = (result: AxiosResponse) => {
    return {
        type: GET_ROLE_VALIDATE_EDIT_SUCCEES,
        roleSearchEdit: result.data,
    };
};
export const getValidateErrorEdit = () => {
    return {
        type: GET_ROLE_VALIDATE_EDIT_ERROR,
    };
};
export function GetRoleValidateNameEdit(filters: TypeParamsValidateNameEdit, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(getValidateEdit());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateRoleEdit}${queryString}`,
                {headers}
            );
            dispatch(getValidateSuccessEdit(response));
        } catch (error) {
            dispatch(getValidateErrorEdit());
        }
    };
}
