import {GET_EXCEDENT, GET_EXCEDENT_SUCCESS, GET_EXCEDENT_ERROR} from '@ReduxsCashFlow/Types/Types';
const initialState = {
    data: [],
    totalPage: [],
    loading: false,
    error: false,
};
export interface IExcessFoundry {
    data: IFundingExcess;
    totalPage: [];
    loading: boolean;
    error: boolean;
}
interface IFundingExcess {
    tokenExpiration: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalItems: number;
    token: string;
    errors: null | string[];
    message: null | string;
    succeeded: boolean;
    data: IExcessFoundryData[];
}

interface IExcessFoundryData {
    zoneName: string;
    employeeUserId: number;
    motiveId: number;
    motiveName: string;
    statusName: string;
    companyId: number;
    statusId: number;
    urlVoucher: string;
    date: string;
    zoneId: string;
    branchName: string;
    amount: number;
    amountAcepted: number;
    branchId: string;
    id: number;
    employeUserName: string;
    observations: string;
    urlReceived: string;
    urlDeposited: string;
    typeSearch: string;
}
const getAllFlowExcessFoundry = (
    state = initialState,
    action: {type: string; payload: IFundingExcess}
) => {
    switch (action.type) {
        case GET_EXCEDENT:
            return {
                ...state,
                loading: true,
            };
        case GET_EXCEDENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                pageNumber: action.payload,
                totalPage: action.payload,
                loading: false,
            };
        case GET_EXCEDENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getAllFlowExcessFoundry;
