import React, {useState} from 'react';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@Quoter/Icons/styles';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import SelectGeneral from '@General/Atoms/Select/Select';

export const LocationQuoter = () => {
    const [showLocation, setShowLocation] = useState(false);

    return (
        <>
            <ContainerFlex
                Height="100%"
                Gap="24px"
                FlexDir="column"
                Width="100%"
                Justify={showLocation ? 'start' : 'start'}
                Align={showLocation ? 'start' : 'center'}
            >
                {!showLocation && (
                    <ContainerFlex
                        Justify="center"
                        Width="auto"
                        Height="auto"
                        backG="#5A5AFF"
                        Radius="32px"
                        Padding="8px 16px"
                        onClick={() => setShowLocation(true)}
                    >
                        <IconSvg Height="24px" Width="24px" cursor="pointer">
                            <path
                                d="M4 6V4H20V6H4ZM4 20V14H3V12L4 7H20L21 12V14H20V20H18V14H14V20H4ZM6 18H12V14H6V18ZM5.05 12H18.95L18.35 9H5.65L5.05 12Z"
                                fill="white"
                            />
                        </IconSvg>
                        <Text
                            Padding="0px 0px 0px 8px"
                            FontSize="1rem"
                            FontWeight="500"
                            Color="#FFFFFF"
                            Cursor="pointer"
                        >
                            {SHOPPING_CART.LOCATION}
                        </Text>
                    </ContainerFlex>
                )}
                {showLocation && (
                    <>
                        <Text FontSize="1rem" Cursor="pointer" FontWeight="500">
                            {SHOPPING_CART.SELECT_LOCATION}
                        </Text>
                        <ContainerFlex Justify="space-between" Height="auto">
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Align="start"
                                Width="auto"
                                Gap="8px"
                            >
                                <Text FontSize="1rem" Cursor="pointer">
                                    {SHOPPING_CART.SELECT_LOCATION}
                                </Text>
                                <SelectGeneral
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={[{value: 1, label: 'Todas'}]}
                                    noOptionsMessage={() => 'Sin opciones'}
                                />
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Width="auto"
                                Align="start"
                                Gap="8px"
                            >
                                <Text FontSize="1rem" Cursor="pointer">
                                    {SHOPPING_CART.ROW}
                                </Text>
                                <SelectGeneral
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={[{value: 1, label: 'Todas'}]}
                                    noOptionsMessage={() => 'Sin opciones'}
                                />
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Width="auto"
                                Align="start"
                                Gap="8px"
                            >
                                <Text FontSize="1rem" Cursor="pointer">
                                    {SHOPPING_CART.COLUMN}
                                </Text>
                                <SelectGeneral
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={[{value: 1, label: 'Todas'}]}
                                    noOptionsMessage={() => 'Sin opciones'}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    </>
                )}
            </ContainerFlex>
        </>
    );
};
