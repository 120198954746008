import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import React, {useState, useEffect} from 'react';
import ButtonGeneral from '@components/General/Atoms/Button/index';
import {ICompanyBenefits, IAddons} from '@components/SignUp/interfaces';
import {ContainerCellGrid, ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {saveFormData} from '@components/SignUp/Redux/Actions/saveDataFormAction';
import {Addons} from '@components/SignUp/addons';
import {MonthlySummary} from '@components/SignUp/monthlySummary';
import {COMPLEMENTS} from '@components/SignUp/constants';

export const SelectPlan = () => {
    const dispatch = useDispatch();
    const {company, places, accountData} = useSelector((state: RootState) => state.saveDataSingUp);
    const [monthlySubscription, setMonthlySubscription] = useState<IAddons[]>([]);
    const [checkedAddons, setCheckedAddons] = useState<ICompanyBenefits[]>([]);

    const addonsInfo = {
        chechekdPrice: checkedAddons.map((item) => ({
            planName: item.benefitName,
            price: item.price,
        })),
        checkedId: checkedAddons.map((item) => item.id),
    };

    const addAddons = () => {
        setMonthlySubscription([
            ...(company ? company.BusinessItem : []),
            ...(places ? places.PlacesItem : []),
            ...(addonsInfo ? addonsInfo.chechekdPrice : []),
        ]);
    };

    const crearUserAdministrator = () => {
        if (
            company?.CompanyName &&
            company?.TypeBusinessId &&
            places?.TypePlaceId &&
            addonsInfo.checkedId
        ) {
            const data = {
                ...accountData,
                companyName: company.CompanyName,
                typeBussinessId: company.TypeBusinessId,
                typePlaceId: places.TypePlaceId,
                complements: addonsInfo.checkedId,
            };
            dispatch(saveFormData(data));
        }
    };

    useEffect(() => {
        addAddons();
    }, [checkedAddons]);

    return (
        <ContainerFlex
            Display="grid"
            Width="auto"
            Height="auto"
            Radius="1.5rem"
            JustifyItems="center"
            Gap="1.5rem"
            Margin="0 0 2rem 0"
        >
            <ContainerFlex Display="grid" GridColumns="34.75rem 21.875rem" Gap="1rem">
                <ContainerFlex Height="auto" FlexDir="column" Gap="1rem" Padding="0 0 24px 0">
                    <ContainerFlex FlexDir="column" Gap="0.5rem" MinH="fit-content">
                        <Text
                            Color="#2A2C2F"
                            FontSize="1.25rem"
                            FontWeight="500"
                            Self="flex-start"
                            Height="auto"
                        >
                            {COMPLEMENTS.ADDONSTITLE}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="0.875rem"
                            Self="flex-start"
                            wSpace="normal"
                            Height="auto"
                        >
                            {COMPLEMENTS.ADDONSDESCRIPTION}
                        </Text>
                    </ContainerFlex>
                    <Addons checkedAddons={checkedAddons} setCheckedAddons={setCheckedAddons} />
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="400">
                        {COMPLEMENTS.ADDONSLEGEND}
                    </Text>
                </ContainerFlex>
                <MonthlySummary monthlySubscription={monthlySubscription} company={company} />
            </ContainerFlex>
            <ContainerCellGrid FlexDir="column" Gap="0.5rem" Align="center">
                <ButtonGeneral
                    width="26.25rem"
                    text="Comenzar con tu prueba gratis"
                    clic={crearUserAdministrator}
                />
                <Text FontSize="0.75rem" FontWeight="400" Color="#2A2C2F">
                    {COMPLEMENTS.TRIALPERIODLEGEND}
                    <Text TextDecoration="underline" Padding="0 0.125rem" Cursor="pointer">
                        leer más
                    </Text>
                </Text>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
