import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {FilterLabelWrapperProps} from '@/components/MyAccount/interfaces';

const FilterLabelWrapper = ({title, children}: FilterLabelWrapperProps) => {
    return (
        <ContainerFlex
            Justify="flex-start"
            Align="flex-start"
            FlexDir="column"
            Gap="4px"
            Width="243px"
        >
            <Text Color="#1D1E20" FontFamily="Nunito" FontWeight="700" LetterSpacing="0.3px">
                {title}
            </Text>
            {children}
        </ContainerFlex>
    );
};

export default FilterLabelWrapper;
