import {
    GET_USERS_CLIENTS_VALIDATION_DATA,
    GET_USERS_CLIENTS_VALIDATION_SUCCESS,
    GET_USERS_CLIENTS_VALIDATION_ERROR,
    RESET_USER_CLIENTS_DATA,
} from '@components/MarketAccessLogin/Redux/types/types';
import {AxiosError, AxiosResponse} from 'axios';

import {IDataLogin} from '@components/Login/interfaces';

const initialState: IDataLogin = {
    userData: null,
    preData: null,
    loading: false,
    error: false,
    errorData: null,
    userType: '',
    userExists: true,
};

const getUsersClientsValidation = (
    state: IDataLogin = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IDataLogin => {
    switch (action.type) {
        case GET_USERS_CLIENTS_VALIDATION_DATA:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_USERS_CLIENTS_VALIDATION_SUCCESS:
            return {
                ...state,
                userData: action.payload.data.data,
                userType: action.payload.data.data.userType,
                loading: false,
            };
        case GET_USERS_CLIENTS_VALIDATION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                userExists: false,
                errorData: action.error,
            };
        case RESET_USER_CLIENTS_DATA:
            return {
                ...state,
                userExists: true,
                userData: null,
            };
        default:
            return state;
    }
};

export default getUsersClientsValidation;
