export const VALIDATE_BANK_CARD_START = 'VALIDATE_BANK_CARD_START';
export const VALIDATE_BANK_CARD_SUCCESS = 'VALIDATE_BANK_CARD_SUCCESS';
export const VALIDATE_BANK_CARD_ERROR = 'VALIDATE_BANK_CARD_ERROR';

export const ADD_NEW_PAYMENT_START = 'ADD_NEW_PAYMENT_START';
export const ADD_NEW_PAYMENT_SUCCESS = 'ADD_NEW_PAYMENT_SUCCESS';
export const ADD_NEW_PAYMENT_ERROR = 'ADD_NEW_PAYMENT_ERROR';

export const GET_PAYMENTMETHOD_START = 'GET_PAYMENTMETHOD_START';
export const GET_PAYMENTMETHOD_SUCCESS = 'GET_PAYMENTMETHOD_SUCCESS';
export const GET_PAYMENTMETHOD_ERROR = 'GET_PAYMENTMETHOD_ERROR';

export const GET_TAXDATA_START = 'GET_TAXDATA_START';
export const GET_TAXDATA_SUCCESS = 'GET_TAXDATA_SUCCESS';
export const GET_TAXDATA_ERROR = 'GET_TAXDATA_ERROR';

export const UPDATE_TAXDATA_START = 'UPDATE_TAXDATA_START';
export const UPDATE_TAXDATA_SUCCESS = 'UPDATE_TAXDATA_SUCCESS';
export const UPDATE_TAXDATA_ERROR = 'UPDATE_TAXDATA_ERROR';
