import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    ContainerFlex,
    Text,
    Input,
    ContainerForm,
    TextArea,
} from '@/components/Shopify/Ecommerce/styles';
import {BUTTON, MODAL_FUNDING_BRANCH} from '@/components/CashFlowActions/Operational/Constants';
import UploadFile from '@/components/CashFlowActions/UploadFile';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {validationBranchSchema} from '@/components/CashFlowActions/Operational/ValidationYup';
import {ClosedModalProps, FormValues} from '@/components/CashFlowActions/Operational/interfaces';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {PostCreateAddFlowCash} from '@/components/CashFlowActions/Redux/Actions/AddFlowCashAction';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

export const ModalBanchFunding = ({isOpen, closeModal}: ClosedModalProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const branchId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.branchDetails?.[0]?.branchId;
    });
    const errorMesage = useSelector((state: RootState) => state.addFlowCash.error);
    const [formValues] = useState<FormValues>({
        amount: '',
        message: '',
        file: '',
    });

    const [error, setError] = useState<string | null>(null);

    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<FormValues>({
        defaultValues: formValues,
        resolver: yupResolver(validationBranchSchema),
    });
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const formatCurrency = useFormatCurrency();

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
            reset(formValues);
            setSelectedFile(null);
        }
    };

    const onSubmit = async (data: FormValues) => {
        const Amount = parseFloat(data.amount.replace(/[^0-9.-]+/g, ''));
        const dataFlowCash = {
            branchId: branchId,
            amount: Amount,
            observations: data.message,
            fileBase64: selectedFile,
        };

        try {
            await dispatch(PostCreateAddFlowCash(token, dataFlowCash));
            closeModal();
            reset();
            setSelectedFile(null);
        } catch (error) {
            setError(errorMesage);
        }
    };

    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                >
                    <Text
                        FontSize="24px"
                        FontWeight="700"
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        Padding="24px"
                    >
                        {MODAL_FUNDING_BRANCH.TITTLE}
                    </Text>
                    <Text Padding="0px 24px 0px" FontWeight="400" Color="#54575C" FontSize="1rem">
                        {MODAL_FUNDING_BRANCH.TEXT}
                    </Text>
                    <Text Padding="0px 24px 0px" FontWeight="400" Color="#54575C" FontSize="1rem">
                        {MODAL_FUNDING_BRANCH.TEXT_ONE}
                    </Text>

                    <ContainerForm
                        Padding="24px"
                        Align="start"
                        FlexDir="column"
                        Gap="24px"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ContainerFlex
                            Height="max-content"
                            FlexDir="column"
                            Align="start"
                            Gap="8px"
                        >
                            <Text FontSize="1rem" FontWeight="500" color="#54575C">
                                {MODAL_FUNDING_BRANCH.INPUT_MESSAGE}
                            </Text>
                            <Controller
                                name="amount"
                                control={control}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        onBlur={(e) => {
                                            const formattedValue = formatCurrency(e.target.value);
                                            field.onChange(formattedValue);
                                        }}
                                        placeholder="$0.00"
                                        value={field.value}
                                        type="text"
                                        TextAlign="end"
                                    />
                                )}
                            />
                            {errors.amount && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.amount.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex
                            Height="max-content"
                            FlexDir="column"
                            Align="start"
                            Gap="8px"
                        >
                            <Text FontSize="1rem" FontWeight="500" color="#54575C">
                                {MODAL_FUNDING_BRANCH.INPUT_MESSAGE_TEXT}
                                <Text MarginLeft="8px" FontSize="0.75rem">
                                    {MODAL_FUNDING_BRANCH.OPTION}
                                </Text>
                            </Text>
                            <Controller
                                name="message"
                                control={control}
                                render={({field}) => <TextArea {...field} Height="6.5rem" />}
                            />
                        </ContainerFlex>
                        <ContainerFlex
                            Height="max-content"
                            FlexDir="column"
                            Align="start"
                            Gap="8px"
                        >
                            <Text FontSize="1rem" FontWeight="500" color="#54575C">
                                {MODAL_FUNDING_BRANCH.ADJUNTOS}
                                <Text MarginLeft="8px" FontSize="0.875rem">
                                    {MODAL_FUNDING_BRANCH.OPTION}
                                </Text>
                            </Text>
                            <UploadFile
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />
                            <Text FontSize="14px" FontWeight="400">
                                {MODAL_FUNDING_BRANCH.UNTIL}
                            </Text>

                            {error && (
                                <ContainerFlex Justify="start" Height="max-content">
                                    <Text Color="#FF6357" FontSize="0.75rem">
                                        {error}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>

                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                onClick={() => {
                                    closeModal();
                                    reset(formValues);
                                }}
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                type="button"
                            >
                                {BUTTON.CLOSE}
                            </ButtonGenerals>

                            <ButtonGenerals type="submit" Width="100%">
                                {BUTTON.REQUEST}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
