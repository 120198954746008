import styled from '@emotion/styled';
import {IFlexPanel} from '@components/Shopify/ClientProfile/Styles/interfaces';
import {Swiper} from 'swiper/react';

export const FlexPanel = styled.div<IFlexPanel>`
    background-color: ${(props) => props.backG};
    position: ${(props) => props.Position};
    top: ${(props) => props.PositionTop};
    bottom: ${(props) => props.PositionBottom};
    left: ${(props) => props.PositionLeft};
    right: ${(props) => props.PositionRight};
    cursor: ${(props) => props.Cursor};
    display: ${(props) => props.Display};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    min-width: ${(props) => props.MinW};
    max-width: ${(props) => props.MaxW};
    border-bottom-left-radius: ${(props) => props.BottomLeftRad};
    border-top-left-radius: ${(props) => props.TopLeftRad};
    border-bottom-right-radius: ${(props) => props.BottomRightRad};
    border-top-right-radius: ${(props) => props.TopRightRad};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border && props.Border};
    border-bottom: ${(props) => props.Bb};
    border-top: ${(props) => props.Bt};
    box-shadow: ${(props) => props.BoxShadow};
    background: ${(props) => props.Background};
    box-shadow: ${(props) => props.BoxShadow};
    flex-direction: ${(props) => props.FlexDir};
    flex: ${(props) => props.Flex};
    min-height: ${(props) => props.MinH};
    max-height: ${(props) => props.MaxH};
    box-sizing: border-box;
    box-shadow: ${(props) => props.BoxS};
    align-self: ${(props) => props.Self};
    justify-content: ${(props) => props.Justify};
    padding: ${(props) => props.Padding};
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    gap: ${(props) => props.Gap};
    grid-template-columns: ${(props) => props.GridColumns};
    overflow-y: ${(props) => props.OverFlowY};
    overflow-x: ${(props) => props.OverFlowX};
    overflow: ${(props) => props.Overflow};
    &::-webkit-scrollbar {
        width: ${(props) => props.ScrollBarWidth};
        height: ${(props) => props.HeightBarScroll};
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;
FlexPanel.defaultProps = {
    MinH: 'none',
    Cursor: 'auto',
    FlexDir: 'row',
    Padding: '0',
    Align: 'center',
    Justify: 'center',
    FlexWrap: 'nowrap',
    Position: 'none',
    PositionTop: 0,
    PositionBottom: '',
    PositionLeft: 'none',
    MaxW: '',
    MinW: '',
    Display: 'flex',
    ScrollBarWidth: '0.5rem',
};

export const SwiperSlider = styled(Swiper)`
    .swiper-wrapper {
        max-width: 0px;
    }
`;
