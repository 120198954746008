import {useCallback} from 'react';

export const useFormatCurrency = () => {
    const formatCurrency = useCallback((value: string | undefined): string => {
        if (value === undefined || value === '') {
            return '';
        }
        const numberValue = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
        return numberValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }, []);

    return formatCurrency;
};
