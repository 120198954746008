import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import React from 'react';
import {ICalendarRangeButtonsProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';

export const CalendarRangeButtons: React.FC<ICalendarRangeButtonsProps> = ({
    handleClearRange,
    handleCancel,
    handleContinue,
    continueDisabled,
}) => {
    return (
        <>
            <ContainerFlex Justify="space-between">
                <Text
                    FontSize="0.875rem"
                    FontWeight="700"
                    Width="max-content"
                    Cursor="pointer"
                    Color="#5A5AFF"
                    onClick={() => handleClearRange()}
                >
                    {LOAN_DETAILS.DATE_PICKER.CLEAN_SELECTION}
                </Text>
                <ContainerFlex Padding="1rem" Width="max-content" Gap="1rem">
                    <ButtonGenerals
                        SecondaryButton="#FFF"
                        Width="100px"
                        Height="32px"
                        FontSize="0.875rem"
                        type="button"
                        onClick={() => handleCancel()}
                    >
                        {LOAN_DETAILS.DATE_PICKER.BTN_CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals
                        Width="100px"
                        Height="32px"
                        FontSize="0.875rem"
                        type="button"
                        onClick={() => handleContinue()}
                        disabled={continueDisabled}
                    >
                        {LOAN_DETAILS.DATE_PICKER.BTN_CONTINUE}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
