export const GET_SUCURSAL = 'GET_SUCURSAL';
export const GET_SUCURSAL_SUCCESS = 'GET_SUCURSAL_SUCCESS';
export const GET_SUCURSAL_ERROR = 'GET_SUCURSAL_ERROR';

export const OBTAIN_INFO_SUCURSAL = 'OBTAIN_INFO_SUCURSAL';
export const OBTAIN_INFO_SUCURSAL_SUCCESS = 'OBTAIN_INFO_SUCURSAL_SUCCESS';

export const GET_SUCURSAL_HISTORY = 'GET_SUCURSAL_HISTORY';
export const GET_SUCURSAL_HISTORY_SUCCESS = 'GET_SUCURSAL_HISTORY_SUCCESS';
export const GET_SUCURSAL_HISTORY_ERROR = 'GET_SUCURSAL_HISTORY_ERROR';

export const POST_ASSIGNMENT = 'POST_ASSIGNMENT';
export const POST_ASSIGNMENT_SUCCESS = 'POST_ASSIGNMENT_SUCCESS';
export const POST_ASSIGNMENT_ERROR = 'POST_ASSIGNMENT_ERROR';
