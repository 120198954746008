import {AppDispatch} from '@/config/store';
import {
    GET_EXPENSE_TYPE,
    GET_EXPENSE_TYPE_SUCCESS,
    GET_EXPENSE_TYPE_ERROR,
} from '@components/CashFlow/Redux/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getExpenseTypes = () => {
    return {
        type: GET_EXPENSE_TYPE,
    };
};

export const getExpenseTypesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_EXPENSE_TYPE_SUCCESS,
        payload: result,
    };
};

export const getExpenseTypesError = (error: AxiosError) => {
    return {
        type: GET_EXPENSE_TYPE_ERROR,
        payload: error,
    };
};

export function getExpenseTypesAction(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getExpenseTypes());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetExpenseTypes}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getExpenseTypesSuccess(response));
        } catch (error) {
            dispatch(getExpenseTypesError(error as AxiosError));
        }
    };
}
