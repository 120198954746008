import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {TableRowHead} from '@CashFlow/FundingInquiry/styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RowContainer} from '@DeadLines/styles';
import {headerTitles} from '@/components/DeadLines/CreateDeadline/redux/Constants';
import RequestsRowDeadline from '@CreateDeadline/DeadlineSteps/Deadlines/ModalDeadlines/DeadLinesRow';
import {Table} from '@DeadLines/styles';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {DeadlineResult, dealinesSimulators} from '@CreateDeadline/interfaces';
import {ContentLevels, Icons, Numbers} from '@Articles/style';

export function DeadLineModal(props: dealinesSimulators) {
    const {openExample, setOpenExample} = props;
    const routes = useSelector((state: RootState) => state.deadLinesRoutes.RouteItemsPromotions);
    const simulation: DeadlineResult = useSelector((state: RootState) => {
        return state.deadlineSimulator;
    });

    return (
        <Modal
            modalState={openExample}
            changeModalState={() => {}}
            Align="center"
            FlexDirMod="column"
            Width="65%"
            titleModalState={true}
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            Justify="center"
            AlignMod="center"
            Position="fixed"
            Top="50%"
            Height="auto"
            BorderRadius="4px"
        >
            <ContainerFlex Padding="1rem 0" Bb="1px solid #35cf44" Height="56px">
                <ContainerFlex Width="90%" Justify="flex-start" Padding="0 1.5rem">
                    <Text FontSize="1.563rem" Color="#35cf44">
                        Ejemplo de plazo
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    onClick={() => setOpenExample(false)}
                    Padding="0 31.5px 0 0"
                    Width="10%"
                    Justify="flex-end"
                    Cursor="pointer"
                >
                    <Text FontSize="1.563rem" Cursor="pointer" Color="#35cf44">
                        x
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Padding="1.5rem" FlexDir="Column">
                <ContainerFlex Justify="flex-start" Align="flex-start" FlexDir="Column">
                    {simulation.filters?.data && (
                        <Fragment>
                            <ContainerFlex
                                Justify="space-between"
                                Height="auto"
                                Padding="0 0 2rem 0"
                            >
                                <ContainerFlex Width="40%" Justify="flex-start" Align="flex-start">
                                    <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                        Nombre del servicio:
                                    </Text>
                                    <Text FontSize="0.875rem" FontWeight="500">
                                        {simulation.filters?.data.nameTerm}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Width="30%" Justify="flex-start" Align="flex-start">
                                    <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                        Monto préstamo:
                                    </Text>
                                    <Text FontSize="0.875rem" Color="#35cf44" FontWeight="500">
                                        {formatterDolar.format(simulation.filters?.data.loanAmount)}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Width="35%" Justify="flex-start" Align="flex-start">
                                    <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                        Valor avalúo:
                                    </Text>
                                    <Text FontSize="0.875rem" Color="#35cf44" FontWeight="500">
                                        {formatterDolar.format(
                                            simulation.filters?.data.valueAmount
                                        )}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex Justify="space-between" Height="auto">
                                <ContainerFlex Width="40%" Justify="flex-start" Align="flex-start">
                                    <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                        Sucursal:
                                    </Text>
                                    <Text FontSize="0.875rem" FontWeight="500">
                                        {simulation.filters?.data.branchesName}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Width="30%" Justify="flex-start" Align="flex-start">
                                    <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                        Fecha de inicio:
                                    </Text>
                                    <Text FontSize="0.875rem" FontWeight="500">
                                        {simulation.filters?.data.startDate}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Width="35%" Justify="flex-start" Align="flex-start">
                                    <ContainerFlex Justify="flex-start" Align="flex-start">
                                        <Text FontSize="0.875rem" Padding="0 8px 0 0">
                                            Fecha de Fin Vigencia:
                                        </Text>
                                        <Text FontSize="0.875rem" FontWeight="500">
                                            {simulation.filters?.data.endDate}
                                        </Text>
                                    </ContainerFlex>
                                </ContainerFlex>
                            </ContainerFlex>
                            {routes?.length > 0 && (
                                <ContainerFlex
                                    Justify="left"
                                    Height="auto"
                                    FlexWrap="flex-wrap"
                                    Cursor="pointer"
                                    Bb="1px solid #E4E7E9"
                                    Padding="2rem 0 1.5rem 0"
                                >
                                    <Numbers
                                        Width="2.563rem"
                                        Height="2.563rem"
                                        Border={
                                            routes[routes.length - 1].icon
                                                ? ''
                                                : '1px solid #E4E7E9'
                                        }
                                        Margin="0 0.6rem 0 0"
                                    >
                                        {routes[routes?.length - 1].icon ? (
                                            <img src={routes[routes?.length - 1].icon} />
                                        ) : (
                                            <Icons className="material-icons">category</Icons>
                                        )}
                                    </Numbers>
                                    <ContainerFlex Width="auto" Justify="left">
                                        {routes?.map(
                                            (value: {description: string}, index: number) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <ContentLevels
                                                            FontWeight="400"
                                                            Color="#414042"
                                                            FontSize="0.875rem"
                                                            TextDecoration="underline"
                                                        >
                                                            {value.description}
                                                        </ContentLevels>

                                                        {routes?.length - 1 !== index && (
                                                            <Icons
                                                                className="material-icons"
                                                                FontSize="1rem"
                                                                FontWeight="300"
                                                                Color="#A7A9AC"
                                                                Margin="0 0.2rem"
                                                            >
                                                                chevron_right
                                                            </Icons>
                                                        )}
                                                    </Fragment>
                                                );
                                            }
                                        )}
                                    </ContainerFlex>
                                </ContainerFlex>
                            )}
                            <Table>
                                <TableRowHead GridColumn="1fr 1fr 1fr 1fr 1fr 2fr" Height="48px">
                                    {headerTitles.map((header, index) => (
                                        <RowContainer Justify="center" key={index} Align="center">
                                            <Text
                                                Padding="1rem 0"
                                                FontSize="1rem"
                                                FontWeight="500"
                                                wSpace="normal"
                                            >
                                                {header.name}
                                            </Text>
                                        </RowContainer>
                                    ))}
                                </TableRowHead>
                                <RequestsRowDeadline openDetail={true} handleID={() => {}} />
                            </Table>
                        </Fragment>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
}
