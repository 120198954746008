import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {ButtonMenuActions} from '@/components/CashFlowActions/ButtonMenuActions';

export const Elements = () => {
    return (
        <ContainerFlex Height="100vh">
            <ContainerFlex FlexDir="column" Width="50%">
                <ButtonMenuActions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
