import axios from 'axios';
import {AppDispatch} from '@/config/store';

import {
    GET_SECURITYBAG_BRANCH,
    GET_SECURITYBAG_BRANCH_SUCCESS,
    GET_SECURITYBAG_BRANCH_ERROR,
    GET_COLLECTOR,
    GET_COLLECTOR_SUCCESS,
    GET_COLLECTOR_ERROR,
    PUT_IMAGE_DELIVERY,
    PUT_IMAGE_DELIVERY_SUCCESS,
    PUT_IMAGE_DELIVERY_ERROR,
    GET_COLLECTOR_INTERNAL,
    GET_COLLECTOR_INTERNAL_SUCCESS,
    GET_COLLECTOR_INTERNAL_ERROR,
    GET_SECURITYBAG_BRANCH_VALUE,
    GET_SECURITYBAG_BRANCH_VALUE_SUCCESS,
    GET_SECURITYBAG_BRANCH_VALUE_ERROR,
    GET_SECURITYBAG_ADD,
    GET_SECURITYBAG_ADD_SUCCESS,
    GET_SECURITYBAG_ADD_ERROR,
} from '@TypesDelivery/DeliveryTypes';
import {URL} from '@config/constants/index';
import {Delivery, IRowDelivery} from '@Foundry/interfaces';

export const fetchGetCollector = () => {
    return {
        type: GET_COLLECTOR,
    };
};
export const fetchGetCollectorSuccess = (result: {data: unknown}) => {
    return {
        type: GET_COLLECTOR_SUCCESS,
        value: result.data,
    };
};
export const fetchGetCollectorError = () => {
    return {
        type: GET_COLLECTOR_ERROR,
    };
};
export const getCollector = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCollector());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Collector}`, {headers})
            .then((result) => {
                dispatch(fetchGetCollectorSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetCollectorError());
            });
    };
};

export const fetchGetSecurityBagBranch = () => {
    return {
        type: GET_SECURITYBAG_BRANCH,
    };
};
export const fetchGetSecurityBagBranchSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SECURITYBAG_BRANCH_SUCCESS,
        value: result.data,
    };
};
export const fetchGetSecurityBagBranchError = () => {
    return {
        type: GET_SECURITYBAG_BRANCH_ERROR,
    };
};
export const getSecurityBagBranch = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagBranch());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecurityBranch}`, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetSecurityBagBranchSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetSecurityBagBranchError());
            });
    };
};

export const fetchPostSecurityImage = () => {
    return {
        type: PUT_IMAGE_DELIVERY,
    };
};
export const fetchPostSecurityImageSuccess = (result: {data: unknown}) => {
    return {
        type: PUT_IMAGE_DELIVERY_SUCCESS,
    };
};
export const fetchPostSecurityImageError = () => {
    return {
        type: PUT_IMAGE_DELIVERY_ERROR,
    };
};
export const putSecurityImage = (data: FormData, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return (dispatch: AppDispatch) => {
        return axios.put(
            `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecurityImage}`,
            data,
            {headers}
        );
    };
};

export const fetchGetCollectorInternal = () => {
    return {
        type: GET_COLLECTOR_INTERNAL,
    };
};
export const fetchGetCollectorInternalSuccess = (result: {data: unknown}) => {
    return {
        type: GET_COLLECTOR_INTERNAL_SUCCESS,
        value: result.data,
    };
};
export const fetchGetCollectorInternalError = () => {
    return {
        type: GET_COLLECTOR_INTERNAL_ERROR,
    };
};
export const getCollectorInternal = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetCollectorInternal());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.CollectorInternal}`, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetCollectorInternalSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetCollectorInternalError());
            });
    };
};

export const fetchGetSecurityBagBranchValue = () => {
    return {
        type: GET_SECURITYBAG_BRANCH_VALUE,
    };
};
export const fetchGetSecurityBagBranchValueSuccess = (result: unknown) => {
    return {
        type: GET_SECURITYBAG_BRANCH_VALUE_SUCCESS,
        value: result,
    };
};
export const fetchGetSecurityBagBranchValueError = () => {
    return {
        type: GET_SECURITYBAG_BRANCH_VALUE_ERROR,
    };
};
export const getSecurityBagBranchValue = (seguribolsa: IRowDelivery) => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagBranchValueSuccess(seguribolsa));
    };
};

export const fetchGetBranchAdd = () => {
    return {
        type: GET_SECURITYBAG_ADD,
    };
};
export const fetchGetBranchAddSuccess = () => {
    return {
        type: GET_SECURITYBAG_ADD_SUCCESS,
    };
};
export const fetchGetBranchAddError = () => {
    return {
        type: GET_SECURITYBAG_ADD_ERROR,
    };
};
export const getSecuritybagAdd = (data: Delivery, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetBranchAdd());
        return axios
            .put(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SecuritybagAdd}`, data, {
                headers,
            })
            .then((result) => {
                dispatch(fetchGetBranchAddSuccess());
            })
            .catch((err) => {
                dispatch(fetchGetBranchAddError());
            });
    };
};
