import React from 'react';

import {IconContainer} from '@/components/Quoter/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import {getAllLevelsOrganization} from '@ActionsOrgCreation/GetAllLevels';
import {searchData} from '@ActionsOrgCreation/LevelTree';
import {InsertData, NodeData} from '@SubContainerOrgCreation/interface';
import {AppDispatch} from '@/config/store';
interface Level {
    idLevel: number;
    levelName: string;
    levelDescription: string;
    status: boolean;
    step: number;
    nextLevel: Level[];
    level: number;
}

export interface InsertPath extends Level {
    levelPath: string;
}

export function addLevelToObjects(Level: NodeData[], level: number = 1): InsertData[] {
    return Level.map((Children) => {
        const NewLevel: InsertData = {...Children, level};
        NewLevel.nextLevel = addLevelToObjects(NewLevel.nextLevel, level + 1);
        return NewLevel;
    });
}
export const addLevelPath = (data: Level[], path: string[] = []): Level[] =>
    data.map((item) => {
        const {levelName, nextLevel, ...rest} = item;
        const newPath = [...path, levelName];
        return {
            ...rest,
            levelName,
            levelPath: newPath.join('>'),
            nextLevel: addLevelPath(nextLevel || [], newPath),
        };
    });
export const formatLevels = (levels: string) => {
    const parts = levels.split('>');
    return (
        <>
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    <Text FontWeight={index === 0 ? '500' : '400'}>{part.trim()}</Text>
                    {index < parts.length - 1 && (
                        <IconContainer FontSize=".875rem" className="material-icons">
                            keyboard_arrow_right
                        </IconContainer>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export const resetPetition = (
    companyId: number,
    PageSize: number,
    token: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    dispatch: AppDispatch,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
) => {
    dispatch(searchData(''));
    setPageNumber(1);
    setSearch('');
    const paginated = {
        companyId: companyId,
        search: '',
        pageSize: 20,
        pageNumber: PageSize === 0 ? 1 : PageSize,
    };
    dispatch(getAllLevelsOrganization(paginated, token));
};
interface Level {
    idLevel: number;
    levelName: string;
    levelDescription: string;
    status: boolean;
    step: number;
    nextLevel: Level[];
}
