import React, {useState, useRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useDatePickerUtils from '@/hooks/useDatePickerUtils';
import {
    useFormContext,
    Controller,
    FieldError,
    ControllerRenderProps,
    FieldValues,
} from 'react-hook-form';
import {DateSearchInput} from '@components/Shopify/ListClient/styles/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import {CustomDatePickerProps} from '@components/Shopify/ListClient/interfaces';
import {DatePickerWrapper} from '@components/Shopify/ListClient/styles/styles';
import useOutsideClick from '@/hooks/useOutsideClick';
import {DATE_FORMATS} from '@components/Shopify/ListClient/constants';

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    name,
    placeholder,
    openOnError = false,
    width,
    format = DATE_FORMATS.FULL_DATE,
}) => {
    const {determinePickerProps, formatDate, parseDate} = useDatePickerUtils();
    const {
        control,
        formState: {errors},
    } = useFormContext();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const datePickerRef = useRef<HTMLDivElement>(null);

    useOutsideClick(datePickerRef, () => setCalendarOpen(false));

    const getErrorMessage = (error: FieldError | undefined): string | null => {
        return error ? (error.message as string) : null;
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (errors[name] && !openOnError) {
            event.stopPropagation();
        }
    };

    const handleDateChange = (
        date: Date | [Date, Date] | null,
        field: ControllerRenderProps<FieldValues, string>
    ) => {
        if (date) {
            const valueToSet = format === DATE_FORMATS.FULL_DATE ? date : formatDate(date, format);
            field.onChange(valueToSet);
        } else {
            field.onChange('');
        }
    };

    const pickerProps = determinePickerProps(format);

    return (
        <DatePickerWrapper Width={width} ref={datePickerRef}>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <DatePicker
                        selected={
                            field.value
                                ? typeof field.value === 'string'
                                    ? parseDate(field.value, format)
                                    : field.value
                                : null
                        }
                        onChange={(date) => handleDateChange(date, field)}
                        onSelect={() => setCalendarOpen(false)}
                        {...pickerProps}
                        customInput={
                            <DateSearchInput
                                Border={errors[name] ? '1px solid #ef4f55' : '1px solid #E8E9EA'}
                                BorderH={errors[name] ? '1px solid #ef4f55' : '1px solid #5a5af8'}
                                BorderF={errors[name] ? '1px solid #ef4f55' : '1px solid #5a5af8'}
                            >
                                <input
                                    placeholder={placeholder}
                                    ref={field.ref}
                                    value={
                                        format === DATE_FORMATS.FULL_DATE && field.value
                                            ? new Date(field.value).toLocaleDateString('es-MX')
                                            : field.value
                                    }
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    onFocus={handleFocus}
                                    onClick={() => setCalendarOpen(!calendarOpen)}
                                    readOnly
                                />
                            </DateSearchInput>
                        }
                        open={calendarOpen && (openOnError || !errors[name])}
                    />
                )}
            />
            {errors[name] && (
                <Text Color="#FF6357" FontSize="0.875rem">
                    {getErrorMessage(errors[name] as FieldError | undefined)}
                </Text>
            )}
        </DatePickerWrapper>
    );
};

export default CustomDatePicker;
