import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {DateTime} from 'luxon';
import {RootState, AppDispatch} from '@config/store';
import {FieldValues} from 'react-hook-form';

import {
    getSecurityBagWeight,
    getIdentifyFoundry,
} from '@ActionsSecurityBagWeight/SecurityBagWeightActions';
import {getSecurityBagWeightJiracas} from '@ActionsSecurityBagWeight/SecurityBagWeightJiracasActions';
import {Table} from '@DeadLines/styles';

export const SecurityBagWeightJiracas = (): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const {
        handleSubmit,
        register,
        watch,
        reset,

        formState: {errors},
    } = useForm({
        defaultValues: {
            kilatajes: [{pesoTestigo: 0, pesoMetalTestigo: 0, pesoMetalFundidora: 0}],
            pesoAccesorios: '0',
        },
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const identify = useSelector((state: RootState) => {
        return state.securitybagweight.identifyfoundry;
    });
    const watchAllFields = watch();
    useEffect(() => {
        if (token) {
            const getSecurityBagTransit = () => dispatch(getSecurityBagWeight('', 10, 0, token));
            getSecurityBagTransit();
            const getIdentify = () =>
                dispatch(
                    getIdentifyFoundry(
                        DateTime.local().minus({hours: 6}).toFormat('dd-MM-yyyy'),
                        token
                    )
                );
            getIdentify();
        }
    }, [dispatch, token]);
    useEffect(() => {
        if (identify) {
            const getSecurityBagWeightJiracasTable = () =>
                dispatch(getSecurityBagWeightJiracas(identify, token));
            getSecurityBagWeightJiracasTable();
        }
    }, [dispatch, identify, token]);

    const onSubmitAudit = async (values: FieldValues) => {
        const objetos = values.kilatajes.map(
            (item: {
                kilataje: string;
                pesoMetalTestigo: string;
                pesoMetalFundidora: string;
                lingote: string;
            }) => {
                const json = {
                    kilataje: Number(item.kilataje),
                    pesoMetalTestigo: Number(item.pesoMetalTestigo),
                    pesoMetalFundidora: Number(item.pesoMetalFundidora),
                    lingote: Number(item.lingote),
                };
                return json;
            }
        );
        return objetos;
    };
    const securitybagweightjiracas = useSelector((state: RootState) => {
        return state.securitybagweightjiracas.securitybagweightjiracas;
    });
    useEffect(() => {
        if (securitybagweightjiracas) {
            reset({
                pesoAccesorios: securitybagweightjiracas.pesoAccesorios,
            });
        }
    }, [reset, securitybagweightjiracas]);

    return (
        <div className="w-90 mx-auto ">
            <div className="pt-2 pb-3 flex align-items-center w-100 mx-auto">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Fundición</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="child-breadcrumb">Peso de Seguribolsa</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Peso de Jicaras</span>
            </div>
            <div className="flex justify-content-between">
                <span>Selecciona el kilataje que se mezclará en un solo lingote.</span>
            </div>
            <div className="flex mt-5">
                <div className="w-100 card pt-2 ml-3">
                    <form
                        className="flex container-form pl-4 mt-5"
                        onSubmit={handleSubmit(onSubmitAudit)}
                    >
                        <div className="flex justify-content-end w-100 ">
                            <button className="  mr-5 btn-add-incidents h-40">SIGUIENTE</button>
                        </div>
                        <Table className="w-92 mx-auto mt-4">
                            <thead className="title-head-foundry">
                                <tr>
                                    <th className="text-center">Kilatajes</th>
                                    <th className="text-center">Peso Seguribolsa</th>
                                    <th className="pl-1">Peso testigo metal</th>
                                    <th className="pl-1">Peso accesorios</th>
                                    <th className="text-center">Peso Fundidora Metal</th>
                                    <th className="pl-1 text-center">Diferencia PS VS PF</th>
                                    <th className="pl-1 text-center">Diferencia PV VS PF</th>
                                    <th className="text-center">1</th>
                                    <th className="text-center">2</th>
                                    <th className="text-center">3</th>
                                    <th className="text-center">4</th>
                                    <th className="text-center">5</th>
                                    <th className="text-center">6</th>
                                    <th className="text-center">7</th>
                                </tr>
                            </thead>
                        </Table>
                        {securitybagweightjiracas.listBuckets &&
                            securitybagweightjiracas.listBuckets.map(
                                (
                                    recolectionitems: {
                                        kilataje: string;
                                        pesoTestigo: string;
                                        pesoMetalTestigo: string;
                                        pesoAccesorios: string;
                                        pesoMetalFundidora: string;
                                        difPsPf: string;
                                        difPtPf: string;
                                    },
                                    index: number
                                ): JSX.Element => (
                                    <Table className="w-92 mx-auto mt-4" key={index}>
                                        <tr className="row-sucursal-tr">
                                            <td className="w-8">
                                                <input
                                                    className="input-search disabled-button "
                                                    value={recolectionitems.kilataje}
                                                />
                                            </td>
                                            <td className="w-10">
                                                <input
                                                    disabled
                                                    className="input-search disabled-button"
                                                    value={recolectionitems.pesoTestigo}
                                                />
                                            </td>
                                            <td className="w-12">
                                                <input
                                                    className="input-search"
                                                    defaultValue={recolectionitems.pesoMetalTestigo}
                                                />
                                            </td>

                                            <td className="w-12">
                                                <input
                                                    className="input-search disabled-button"
                                                    disabled
                                                    value={0}
                                                />
                                            </td>
                                            <td className="w-15">
                                                <input
                                                    className="input-search"
                                                    defaultValue={
                                                        recolectionitems.pesoMetalFundidora
                                                    }
                                                />
                                            </td>
                                            <td className="w-12">
                                                <input
                                                    className="input-search disabled-button"
                                                    disabled
                                                    value={0}
                                                />
                                            </td>
                                            <td className="w-13">
                                                <input
                                                    className="input-search disabled-button"
                                                    disabled
                                                    value={0}
                                                />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={1} />
                                            </td>
                                            <ErrorMessage
                                                errors={errors}
                                                className="required-text"
                                                name={`kilatajes[${index}].lingote`}
                                                message="Seleccione un lingote"
                                                as="p"
                                            />
                                            <td>
                                                {' '}
                                                <input type="radio" value={2} />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={3} />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={4} />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={5} />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={6} />
                                            </td>
                                            <td>
                                                {' '}
                                                <input type="radio" value={7} />
                                            </td>
                                        </tr>
                                    </Table>
                                )
                            )}

                        <div className="w-25 flex pr-5 pt-5">
                            <label className="w-50"> Peso accesorio</label>

                            <input
                                className="input-react-hook-form"
                                {...register('pesoAccesorios', {})}
                                defaultValue={watchAllFields.pesoAccesorios}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
