import {
    GET_EXCEDENT_ID,
    GET_EXCEDENT_ID_SUCCESS,
    GET_EXCEDENT_ID_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    histor: [],
    loading: false,
    error: false,
};
const getExcedentById = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case GET_EXCEDENT_ID:
            return {
                ...state,
                loading: true,
            };
        case GET_EXCEDENT_ID_SUCCESS:
            return {
                ...state,
                histor: action.payload.data.data,
                loading: true,
            };
        case GET_EXCEDENT_ID_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default getExcedentById;
