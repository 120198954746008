import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_DETAILS_CREDIT_ERROR,
    GET_DETAILS_CREDIT_START,
    GET_DETAILS_CREDIT_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IDetailsCreditReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: IDetailsCreditReducer = {
    data: null,
    loading: false,
    error: false,
};

const getDetailsCredit = (
    state: IDetailsCreditReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IDetailsCreditReducer => {
    switch (action.type) {
        case GET_DETAILS_CREDIT_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_DETAILS_CREDIT_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_DETAILS_CREDIT_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getDetailsCredit;
