import React from 'react';

import * as style from '@components/CreateUsers/Styles';
import {INHERIT_TYPE} from '@components/LocationsBranch/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {IInheritModalProps} from '@components/LocationsBranch/interfaces';
import {InheritAttributes} from '@components/LocationsBranch/InheritAttributes';

export const InheritFromModal: React.FC<IInheritModalProps> = ({type, showModal, setShowModal}) => {
    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContect}
            background="rgb(0 0 0 / 60%)"
            closeOnOutsideClick={false}
            Width="802px"
            HeightDialog={type === INHERIT_TYPE.SCHEDULE ? '34rem' : '44rem'}
        >
            <InheritAttributes setShowModal={setShowModal} type={type} />
        </Modal>
    );
};
