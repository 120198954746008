export const ACCOUNTMENU = {
    WELCOME: 'Bienvenido,',
    ADMIN: 'Ir al Administrativo',
    POS: 'Ir al OP',
    LOGOUT: 'Cerrar sesión',
};

export const TYPE_USERS = {
    ADMIN: 'Administrator',
    POS: 'Employee',
    SUPER_ADMIN: 'SuperAdministrator',
    ADMIN_ERROR: 'SuperAdministrador',
};

export const stylesTextProps = {
    Width: 'auto',
    Height: '2.25rem',
    Justify: 'center',
    FontSize: '1rem',
    FontWeight: '400',
    Cursor: 'pointer',
    BorderRadius: '0 0 0.75rem 0.75rem',
    Color: '#2A2C2F',
    FontFamily: 'Nunito',
};

export const MY_ACCOUNT = {
    MY_ACCOUNT: 'Mi cuenta',
    SUB_MENU: [
        {MESSAGE: 'Mis datos'},
        {MESSAGE: 'Pago y facturación'},
        {MESSAGE: 'Mi plan y apps'},
        {MESSAGE: 'Almacenamiento'},
    ],
};
export const containerStyles = {
    Width: '15.25rem',
    Height: 'auto',
    FlexDir: 'column',
    Position: 'absolute',
    PositionTop: '4.5rem',
    PositionRight: '1rem',
    Radius: '0.75rem',
    BoxS: '0px 10px 60px 0px rgba(0, 0, 0, 0.25)',
    ZIndex: '3',
    backG: '#FFFFFF',
};

export const NAV_SEARCH_PLACEHOLDER = {
    ADMIN_PLACEHOLDER: 'Busca configuraciones, líneas de negocio, módulos...',
    EMPLOYEE_PLACEHOLDER: 'Busca productos contratos, promociones, clientes...',
};
