import React from 'react';
import {TableHead} from '@AuditAssignment/styles';

export function HeadTableRecolectionGold() {
    return (
        <TableHead>
            <tr>
                <th>SKU</th>
                <th>Fecha</th>
                <th>Familia</th>
                <th>Subfamilia</th>
                <th>Kilataje</th>
                <th>Costo</th>
            </tr>
        </TableHead>
    );
}
