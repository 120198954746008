import * as yup from 'yup';

export const holidaysShema = (opening: boolean) => {
    return yup.object().shape({
        holiday: yup.string().required('Seleccione el día festivo'),

        opening: yup.object().shape({
            value: yup.string(),
            label: opening
                ? yup.string().required('Seleccione la apertura')
                : yup.string().optional(),
        }),
        ending: yup.object().shape({
            value: yup.string(),
            label: opening
                ? yup.string().required('Seleccione el cierre')
                : yup.string().optional(),
        }),
    });
};
