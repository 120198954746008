import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Image, ContainerImg, ContainerCellGrid, Text} from '@/components/Shopify/Ecommerce/styles';
import {PASSWORD_EXPIRED} from '@Login/constants';
import AtaskateWolf from '@images/ImageWolfMailBroken.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {updateStatusLinkExpeired} from '@components/Login/Redux/Actions/updateSatusLinkExpiredAction';
import {RootState} from '@/config/store';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';

export const SectionExpiredLink = ({rowId}: {rowId: string}) => {
    const dispatch = useDispatch();
    const {success, loading} = useSelector((state: RootState) => state.updateStatusLink);
    const handleSubmit = () => {
        dispatch(updateStatusLinkExpeired(rowId));
    };

    return (
        <>
            <Text Color="#54575C" FontWeight="500" Height="16px" Justify="center" Width="100%">
                {PASSWORD_EXPIRED.Title}
            </Text>
            <Text
                Color="#54575C"
                Justify="center"
                Width="100%"
                TextAlign="center"
                wSpace="normal"
                FontSize="0.875rem"
            >
                {PASSWORD_EXPIRED.Text}
            </Text>
            <ContainerImg>
                <Image src={AtaskateWolf} alt="ataskate-wolf" />
            </ContainerImg>
            <ContainerCellGrid Height="auto" FlexDir="column" Gap="8px" Align="center">
                {loading ? (
                    <LoadingAtaskate />
                ) : (
                    <ButtonGeneral
                        text={PASSWORD_EXPIRED.Text_Button}
                        width="100%"
                        clic={handleSubmit}
                        disabled={success}
                    />
                )}
                {success && (
                    <Text FontSize="0.75rem" FontWeight="300" Color="#000">
                        {PASSWORD_EXPIRED.Success_Message}
                    </Text>
                )}
            </ContainerCellGrid>
            <Text
                Color="#414042"
                FontSize="0.75rem"
                FontWeight="300"
                TextDecoration="underline"
                Cursor="pointer"
            >
                {PASSWORD_EXPIRED.Text_Contact}
            </Text>
        </>
    );
};
