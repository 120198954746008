import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_DETAILS_CREDIT_ERROR,
    GET_DETAILS_CREDIT_START,
    GET_DETAILS_CREDIT_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IDetailsCreditAction} from '@components/CreditsAndPawns/interfaces';

const getDetailsCreditStart = () => {
    return {
        type: GET_DETAILS_CREDIT_START,
    };
};

const getDetailsCreditSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DETAILS_CREDIT_SUCCESS,
        payload: result,
    };
};

const getDetailsCreditError = (error: AxiosError) => {
    return {
        type: GET_DETAILS_CREDIT_ERROR,
        error: error,
    };
};

export const getDetailsCreditAction = ({token, creditId}: IDetailsCreditAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDetailsCredit}?solicitudeId=${creditId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getDetailsCreditStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getDetailsCreditSuccess(response.data));
        } catch (e) {
            dispatch(getDetailsCreditError(e as AxiosError));
        }
    };
};
