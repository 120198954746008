import {
    GET_SECURITYBAG_BRANCH,
    GET_SECURITYBAG_BRANCH_SUCCESS,
    GET_SECURITYBAG_BRANCH_ERROR,
    GET_COLLECTOR,
    GET_COLLECTOR_SUCCESS,
    GET_COLLECTOR_ERROR,
    PUT_IMAGE_DELIVERY,
    PUT_IMAGE_DELIVERY_SUCCESS,
    PUT_IMAGE_DELIVERY_ERROR,
    GET_COLLECTOR_INTERNAL,
    GET_COLLECTOR_INTERNAL_SUCCESS,
    GET_COLLECTOR_INTERNAL_ERROR,
    GET_SECURITYBAG_BRANCH_VALUE,
    GET_SECURITYBAG_BRANCH_VALUE_SUCCESS,
    GET_SECURITYBAG_BRANCH_VALUE_ERROR,
    GET_SECURITYBAG_ADD,
    GET_SECURITYBAG_ADD_SUCCESS,
    GET_SECURITYBAG_ADD_ERROR,
} from '@TypesDelivery/DeliveryTypes';

const initialState = {
    collector: [],
    collectorinternal: [],
    securitybag: null,
    securitybagvalue: null,
    error: false,
    loading: false,
};

const collector = (state = initialState, action: {type: any; value: any}) => {
    switch (action.type) {
        case GET_SECURITYBAG_BRANCH:
            return {
                ...state,
                loading: true,
            };
        case GET_SECURITYBAG_BRANCH_SUCCESS:
            return {
                ...state,
                securitybag: action.value,
                loading: false,
            };
        case GET_SECURITYBAG_BRANCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_COLLECTOR:
            return {
                ...state,
                loading: true,
            };
        case GET_COLLECTOR_SUCCESS:
            return {
                ...state,
                collector: action.value,
                loading: false,
            };
        case GET_COLLECTOR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_COLLECTOR_INTERNAL:
            return {
                ...state,
                loading: true,
            };
        case GET_COLLECTOR_INTERNAL_SUCCESS:
            return {
                ...state,
                collectorinternal: action.value,
                loading: false,
            };
        case GET_COLLECTOR_INTERNAL_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_IMAGE_DELIVERY:
            return {
                ...state,
                loading: true,
            };
        case PUT_IMAGE_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_IMAGE_DELIVERY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_BRANCH_VALUE:
            return {
                ...state,
                loading: true,
            };
        case GET_SECURITYBAG_BRANCH_VALUE_SUCCESS:
            return {
                ...state,
                securitybagvalue: action.value,
                loading: false,
            };
        case GET_SECURITYBAG_BRANCH_VALUE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SECURITYBAG_ADD:
            return {
                ...state,
                loading: true,
            };
        case GET_SECURITYBAG_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_SECURITYBAG_ADD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default collector;
