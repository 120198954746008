import {
    GET_ALL_USER_BY_ROLE,
    GET_ALL_USER_BY_ROLE_ERROR,
    GET_ALL_USER_BY_ROLE_SUCCESS,
} from '@Roles/CreateRoles/Redux/Types';
import {AxiosResponse} from 'axios';
export interface IItemSearch {
    image: string;
    userId: number;
    userName: string;
    email: string;
}
export interface ISearchUserByRole {
    data: IItemSearch[];
    error: boolean;
    loading: boolean;
}

const initialState: ISearchUserByRole = {
    data: [],
    error: false,
    loading: false,
};

const GetSearchUserByRole = (
    state = initialState,
    action: {payload: AxiosResponse; type: string}
) => {
    switch (action.type) {
        case GET_ALL_USER_BY_ROLE:
            return {...state, loading: true, error: false};
        case GET_ALL_USER_BY_ROLE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case GET_ALL_USER_BY_ROLE_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default GetSearchUserByRole;
