export const PaymentStyles = {
    Justify: 'start',
    Align: 'start',
    MaxH: '60.25rem',
    Height: 'auto',
    FlexDir: 'column',
    Padding: '1.5rem',
    Border: 'solid 1px #E8E9EA',
    Radius: '1.5rem',
    Gap: '1.5rem',
    Grids: {AlignContent: 'start', FlexDir: 'column', Width: '21.875rem', Height: '4.25rem'},
    Inputs: {
        Radius: '50px',
        Padding: '12px',
        FontSize: '0.875rem',
        placeholder: '',
        Width: '21.875rem',
    },
};
