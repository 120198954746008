import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

import {COMPANY_DETAILS, COMPANY_TAX, COMPANY_PRIVACY} from '@MyCompany/Redux/Types/types';

export const detailsCompanyStart = () => ({
    type: COMPANY_DETAILS.START_COMPANY,
});

export const detailsCompanySuccess = (result: AxiosResponse) => ({
    type: COMPANY_DETAILS.SUCCESS_COMPANY,
    company: result.data,
});

export const detailsCompanyError = (err: AxiosError) => ({
    type: COMPANY_DETAILS.ERROR_COMPANY,
    error: err,
});

export const detailsCompany = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(detailsCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetMyCompanyDetails}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(detailsCompanySuccess(response.data));
        } catch (error) {
            dispatch(detailsCompanyError(error as AxiosError));
        }
    };
};

export const taxCompanyStart = () => ({
    type: COMPANY_TAX.START_COMPANY_TAX,
});

export const taxCompanySuccess = (result: AxiosResponse) => ({
    type: COMPANY_TAX.SUCCESS_COMPANY_TAX,
    companyTax: result.data,
});

export const taxCompanyError = (err: AxiosError) => ({
    type: COMPANY_TAX.ERROR_COMPANY_TAX,
    error: err,
});

export const taxCompany = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(taxCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCompanyTaxData}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(taxCompanySuccess(response.data));
        } catch (error) {
            dispatch(taxCompanyError(error as AxiosError));
        }
    };
};

export const privacyCompanyStart = () => ({
    type: COMPANY_PRIVACY.START_COMPANY_PRIVACY,
});

export const privacyCompanySuccess = (result: AxiosResponse) => ({
    type: COMPANY_PRIVACY.SUCCESS_COMPANY_PRIVACY,
    companyPrivacy: result.data,
});

export const privacyCompanyError = (err: AxiosError) => ({
    type: COMPANY_PRIVACY.ERROR_COMPANY_PRIVACY,
    error: err,
});

export const privacyCompany = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(privacyCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStatusPrivacyCompany}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(privacyCompanySuccess(response.data));
        } catch (error) {
            dispatch(privacyCompanyError(error as AxiosError));
        }
    };
};
