import {
    GET_CASHFLOW_DETAILS,
    GET_CASHFLOW_DETAILS_SUCCESS,
    GET_CASHFLOW_DETAILS_ERROR,
} from '@components/CashFlow/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    cashflowDetails: null,
    loading: false,
    error: false,
};

const getCashflowDetails = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_CASHFLOW_DETAILS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CASHFLOW_DETAILS_SUCCESS:
            return {
                ...state,
                cashflowDetails: action.payload.data.data,
                loading: false,
            };
        case GET_CASHFLOW_DETAILS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getCashflowDetails;
