import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {STEPFOUR} from '@/components/PersonalLoans/constants';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import successIcon from '@images/IconSuccess.svg';
import {ImageLogo} from '@components/Login/style';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {GetValidateLoanData} from '@components/PersonalLoans/Redux/Actions/GetValidateLoan';

export const PersonalLoansStepFour = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const personalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId as number;
    });
    const validationList = [
        {validationText: STEPFOUR.VALIDATION.INFORMATION},
        {validationText: STEPFOUR.VALIDATION.IDENTITY},
        {validationText: STEPFOUR.VALIDATION.CREDIT_HISTORY},
    ];
    const [isValid, setIsValid] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (token) dispatch(GetValidateLoanData(personalInformationId, token, setIsValid));
    }, []);
    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column" Height="auto">
            <Text
                FontSize="1.25rem"
                FontWeight="600"
                color="#1D1E20"
                LHeight="1.5rem"
                MarginB="1rem"
                oFlow="none"
            >
                {STEPFOUR.SUBTITLE}
            </Text>

            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                MarginBt="1.5rem"
                Gap="1.375rem"
            >
                {validationList.map((validation, index) => (
                    <ContainerFlex Gap="1.5rem" Justify="start" Align="center" key={index}>
                        {!isValid ? (
                            <Loading width="1rem" padding="0.25rem" />
                        ) : (
                            <ImageLogo src={successIcon} Width="1.5rem" Height="1.5rem" />
                        )}
                        <Text>{validation.validationText}</Text>
                    </ContainerFlex>
                ))}
            </ContainerFlex>

            <ContainerFlex Justify="start">
                <Text FontSize="0.875rem" FontWeight="400" color="#54575C" wSpace="no-wrap">
                    {STEPFOUR.MESSAGE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
