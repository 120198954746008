import {AppDispatch} from '@/config/store';
import {
    GET_POS_NAV,
    GET_POS_NAV_ERROR,
    GET_POS_NAV_SUCCESS,
    RESET_NAV_BAR_POS,
} from '@components/NavBarPOS/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

export const posUserProfileSettings = () => {
    return {
        type: GET_POS_NAV,
    };
};
export const posUserProfileSettingsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_POS_NAV_SUCCESS,
        payload: result.data,
    };
};
export const posUserProfileSettingsError = (error: AxiosError) => {
    return {
        type: GET_POS_NAV_ERROR,
        error: error,
    };
};
export const posUserProfileSettingsReset = () => {
    return {
        type: RESET_NAV_BAR_POS,
    };
};

export function infoposUserProfile(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(posUserProfileSettings());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.posUserProfileSettings}`,
                {
                    headers,
                }
            );

            dispatch(posUserProfileSettingsSuccess(response));
        } catch (error) {
            dispatch(posUserProfileSettingsError(error as AxiosError));
        }
    };
}
