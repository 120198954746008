import {
    GET_PRODUCTS_EXCHANGE_SUCCESS,
    GET_PRODUCTS_EXCHANGE,
    GET_PRODUCTS_EXCHANGE_ERROR,
    GET_PRODUCTS_ID,
    GET_PRODUCTS_CHECK,
    GET_PRODUCTS_COPY_CHECKED,
} from '@CreateDeadline/redux/types';
import {ValueData} from '@CreateDeadline/interfaces';

const initialState = {
    ProductsExchange: [],
    productsCheck: [],
    productCheckCopy: [],
    ProductsId: [],
    error: false,
    loading: false,
};

export const ProductsExchange = (state = initialState, action: ValueData) => {
    switch (action.type) {
        case GET_PRODUCTS_COPY_CHECKED:
            return {
                ...state,
                productCheckCopy: action.productCheckCopy,
            };
        case GET_PRODUCTS_CHECK:
            return {
                ...state,
                productsCheck: action.productsCheck,
            };
        case GET_PRODUCTS_EXCHANGE:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case GET_PRODUCTS_EXCHANGE_SUCCESS:
            return {
                ...state,
                ProductsExchange: action.value,
                loading: false,
            };
        case GET_PRODUCTS_EXCHANGE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PRODUCTS_ID:
            return {
                ...state,
                ProductsId: action.productsId,
            };
        default:
            return state;
    }
};

export default ProductsExchange;
