export const ARTICLE_MANAGES = {
    GROUP: 'GROUP',
    GROUP_SUCCESS: 'GROUP_SUCCESS',
    GROUP_SUCCESS_DATA: 'GROUP_SUCCESS_DATA',
    GROUP_ERROR: 'GROUP_ERROR',
    GROUP_SELECT: 'GROUP_SELECT',
    GROUP_SELECT_SUCCESS: 'GROUP_SELECT_SUCCESS',
    GROUP_SELECT_ERROR: 'GROUP_SELECT_ERROR',
    FAMILY: 'FAMILY',
    RESET_FAMILY: 'RESET_FAMILY',
    SUBFAMILY: 'SUBFAMILY',
    RESET_SUBFAMILY: 'RESET_SUBFAMILY',
    BRAND: 'BRAND',
    RESET_BRAND: 'RESET_BRAND',
    ARTICLES: 'ARTICLES',
    RESET_ARTICLES: 'RESET_ARTICLES',
    ERROR: 'ERROR',
    LEVEL_STATUS: 'LEVEL_STATUS',
    LEVEL_STATUS_SUCCESS: 'LEVEL_STATUS_SUCCESS',
    DELETE_LEVEL_ROUTE: 'DELETE_LEVEL_ROUTE',
    GET_LEVEL: 'GET_LEVEL',
    ADD_ROUTE_ITEMS: 'ADD_ROUTE_ITEMS',
    LEVELCATALOG: 'LEVELCATALOG',
    RESET_ALL: 'RESET_ALL',
};

export const ADDLEVEL = {
    LEVEL: 'LEVEL',
    LEVEL_SUCCESS: 'LEVEL_SUCCESS',
    LEVEL_ERROR: 'LEVEL_ERROR',
};

export const SAVE_GROUP = 'SAVE_GROUP';
export const SAVE_FAMILY = 'SAVE_FAMILY';
export const SAVE_SUBFAMILY = 'SAVE_SUBFAMILY';
export const SAVE_BRAND = 'SAVE_BRAND';
export const SAVE_ARTICLE = 'SAVE_ARTICLE';
export const RESET_SAVED_LEVELS = 'RESET_SAVED_LEVELS';

export const SAVE_LEVELS = {
    SAVE_GROUP: 'SAVE_GROUP',
    FAMILY: 'SAVE_FAMILY',
    SUBFAMILY: 'SAVE_SUBFAMILY',
    BRAND: 'SAVE_BRAND',
    ARTICLES: 'SAVE_ARTICLES',
};
