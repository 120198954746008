import {COLORS} from '@General/Variables';
import styled from '@emotion/styled';

export const BreadcrumbItemContainer = styled.a`
    display: block;
    align-items: center;
    text-decoration: none;
    color: ${COLORS.LabelsCaptions};
    font: normal normal normal 12px Nunito;
    letter-spacing: 0px;
    cursor: pointer;
    &:hover {
        color: ${COLORS.LabelsCaptions};
        cursor: pointer;
    }
`;
