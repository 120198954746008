import * as yup from 'yup';
import {ERROR_GRAL_MESSAGE} from '@components/LocationsBranch/constants';

const NAME = /^[1-9A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ.\s]+$/;
const ADDRESS = {
    colony: yup.number().required(ERROR_GRAL_MESSAGE.ADDRESS),
    street: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    outerNumber: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    interiorNumber: yup.string(),
    municipal: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    state: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
};

export const newGroupShema = yup.object().shape({
    nameGroup: yup
        .string()
        .required(ERROR_GRAL_MESSAGE.NAME_GROUP)
        .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
    businessLines: yup.array().min(1, ERROR_GRAL_MESSAGE.BUSINESS_LINE),
});

export const newBranchesShema = (isEdit: boolean) =>
    yup.object().shape({
        nameBranch: yup
            .string()
            .required(ERROR_GRAL_MESSAGE.NAME_BRANCH)
            .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
        branchDirection: yup.object().shape(ADDRESS),
        branchBusinessLine: isEdit
            ? yup.array().optional()
            : yup.array().min(1, ERROR_GRAL_MESSAGE.BUSINESS_LINE),
        branchSalesChannel: isEdit
            ? yup.array().optional()
            : yup.array().min(1, ERROR_GRAL_MESSAGE.SALES_CHANNEL),
    });

export const newStorageShema = yup.object().shape({
    nameStorage: yup
        .string()
        .required(ERROR_GRAL_MESSAGE.NAME_STORAGE)
        .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
    storageDirection: yup.object().shape(ADDRESS),
});
