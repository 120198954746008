import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {MODAL_INFO_HEADER} from '@/components/CreditsAndPawns/constants';

const CustomerInfoModalHeader = () => {
    return (
        <ContainerFlex
            FlexDir="ow"
            Justify="start"
            Align="center"
            Padding="24px"
            Width="100%"
            Height="72px"
        >
            <Text
                FontSize="1.5rem"
                FontWeight="700"
                Color="#1D1E20"
                FontFamily="Nunito"
                FontStyle="normal"
            >
                {MODAL_INFO_HEADER.TITLE}
            </Text>
        </ContainerFlex>
    );
};

export default CustomerInfoModalHeader;
