import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {setCurrentSelectedLevelId} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {CompanyLevels} from '@components/LocationsBranch/Redux/interfaces';
import {GET_COMPANY_LEVELS} from '@components/LocationsBranch/Redux/types';

const getCompanyLevelsStart = () => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_START,
});

const getCompanyLevelsSuccess = (result: CompanyLevels) => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_SUCCESS,
    payload: result,
});

const getCompanyLevelsError = (error: AxiosError) => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_ERROR,
    error: error,
});

export const GetCompanyLevelsByCompanyId = (
    companyId: number,
    token: string,
    levelId?: number | null,
    filters?: {
        branchSearch?: [number, number];
        inheritType?: string | null;
    }
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCompanyLevelsStart());
        try {
            let searchId = levelId;
            let branchId = 0;
            if (filters?.branchSearch && filters.branchSearch[0] > 0) {
                branchId = filters.branchSearch[0];
                searchId = filters.branchSearch[1];
            }
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLevelsByCompanyId}`,
                {
                    headers,
                    params: {
                        companyId,
                        levelId: searchId,
                    },
                }
            );
            const responseData: CompanyLevels = response.data.data;

            if (branchId > 0) {
                const filteredBranches = responseData.branchs.filter(
                    (branch) => branch.branchId === branchId
                );
                responseData.branchs = filteredBranches;
            }

            const levelIdnum = levelId ? levelId : responseData.levelId;
            dispatch(getCompanyLevelsSuccess(responseData));
            if (filters?.inheritType) {
                dispatch(
                    setCurrentSelectedLevelId(filters.inheritType, {
                        levelId: levelIdnum,
                        levelName: responseData.branchName,
                        breadcrum: null,
                    })
                );
            }
        } catch (error: unknown) {
            dispatch(getCompanyLevelsError(error as AxiosError));
        }
    };
};
