import {AxiosResponse} from 'axios';
import {
    REJECT_LOAN_START,
    REJECT_LOAN_SUCCESS,
    REJECT_LOAN_ERROR,
    TOGGLE_REJECT_MODAL,
} from '@components/PersonalLoans/Redux/Types/Types';

const initialState = {
    loading: false,
    error: false,
    data: {},
    isRejectModalOpen: false,
};

const RejectLoanReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case REJECT_LOAN_START:
            return {
                ...state,
                loading: true,
            };
        case REJECT_LOAN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case REJECT_LOAN_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case TOGGLE_REJECT_MODAL:
            return {
                ...state,
                isRejectModalOpen: !state.isRejectModalOpen,
            };
        default:
            return state;
    }
};

export default RejectLoanReducer;
