import {AxiosResponse} from 'axios';
import {
    GET_ALL_CAT_PERSONAL_LOANS_PERIOD_START,
    GET_ALL_CAT_PERSONAL_LOANS_PERIOD_SUCCESS,
    GET_ALL_CAT_PERSONAL_LOANS_PERIOD_ERROR,
} from '@components/CreditQuoter/Redux/Types/Types';

const initialState = {
    data: {},
    loading: false,
    error: false,
};

export const getAllCatPersonalLoansPeriod = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_ALL_CAT_PERSONAL_LOANS_PERIOD_START:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_CAT_PERSONAL_LOANS_PERIOD_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ALL_CAT_PERSONAL_LOANS_PERIOD_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getAllCatPersonalLoansPeriod;
