import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    FOCUSED_COLORS,
    GENERAL_TITLES,
    SCREEN_TABLE,
    SCREEN_TABLE_DATA,
} from '@components/Users/UserProfile/constants';
import {Table} from '@mui/material';
import {TableItemsContainer, TableRowHead} from '@/components/CashFlow/FundingInquiry/styles';
import {TaskTableMap} from '@components/Users/UserProfile/TaskMap';
import {NextPage} from '@components/Users/UserProfile/Icons/NextAllPage';

export const TaskTable = () => {
    const pageNumer = 1;
    const LastPage = 4;
    const Pagination = `${pageNumer} - ${LastPage}`;

    return (
        <ContainerFlex
            Height="394px"
            Radius="24px"
            Justify="start"
            Padding="16PX"
            Align="start"
            Gap="16px"
            FlexDir="column"
            Border={FOCUSED_COLORS.BORDER_LEFT}
        >
            <Text FontSize="1.25rem" FontWeight="700">
                {GENERAL_TITLES.PENDING}
            </Text>
            <Table>
                <TableRowHead
                    Height="40px"
                    GridColumn="1fr 2fr 2fr 1fr 1fr"
                    BackG="none"
                    Border="none"
                    Gap="16px"
                >
                    <Text FontWeight="700" Padding="4px 0px" wSpace="">
                        {SCREEN_TABLE.DATE}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE.BRANCH}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE.PRIORITY}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE.TASK}
                    </Text>
                    <Text FontWeight="700" Padding="4px 0px">
                        {SCREEN_TABLE.USERS}
                    </Text>
                </TableRowHead>
                <TableItemsContainer>
                    {SCREEN_TABLE_DATA.map((i, index) => (
                        <TaskTableMap i={i} key={index} />
                    ))}
                </TableItemsContainer>
            </Table>
            <ContainerFlex Height="24px">
                <NextPage direction="right" color="#A1A5AA" size={12} />
                <Text>{Pagination} </Text>
                <NextPage direction="left" color="#A1A5AA" size={12} />
            </ContainerFlex>
        </ContainerFlex>
    );
};
