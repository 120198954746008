import React from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';

const CustomerInfoModalEmploymentRow = ({
    firstTitle,
    firstText,
    secondTitle,
    secondText,
}: {
    firstTitle: string;
    firstText: string;
    secondTitle?: string;
    secondText?: string;
}) => {
    return (
        <ContainerFlex Gap="14px" Width="100%" Height="68px" Align="start" Justify="start">
            {firstTitle && firstText && (
                <ContainerFlex
                    FlexDir="column"
                    Width="169px"
                    Height="68px"
                    Gap="4px"
                    Align="start"
                    Justify="start"
                >
                    <Text
                        FontSize=" 1.0rem"
                        FontWeight="700"
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        LHeight="1.50rem"
                        FontStyle="normal"
                    >
                        {firstTitle}
                    </Text>
                    {firstText.length < 15 ? (
                        <Text
                            FontSize=" 1.0rem"
                            FontWeight="400"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            LHeight="1.50rem"
                            FontStyle="normal"
                            Padding="8px 0px"
                        >
                            {firstText}
                        </Text>
                    ) : (
                        <TextEllipsis
                            FontSize="1.0rem"
                            FontWeight="400"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            Height="1.50rem"
                            FontStyle="normal"
                            Padding="8px 0px"
                            Width="100%"
                        >
                            {firstText}
                        </TextEllipsis>
                    )}
                </ContainerFlex>
            )}
            {secondTitle && secondText && (
                <ContainerFlex
                    FlexDir="column"
                    Width="169px"
                    Height="68px"
                    Gap="4px"
                    Align="start"
                    Justify="start"
                >
                    <Text
                        FontSize=" 1.0rem"
                        FontWeight="700"
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        LHeight="1.50rem"
                        FontStyle="normal"
                    >
                        {secondTitle}
                    </Text>
                    {secondText.length < 15 ? (
                        <Text
                            FontSize=" 1.0rem"
                            FontWeight="400"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            LHeight="1.50rem"
                            FontStyle="normal"
                            Padding="8px 0px"
                        >
                            {secondText}
                        </Text>
                    ) : (
                        <TextEllipsis
                            FontSize="1.0rem"
                            FontWeight="400"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            Height="1.50rem"
                            FontStyle="normal"
                            Padding="8px 0px"
                            Width="100%"
                        >
                            {secondText}
                        </TextEllipsis>
                    )}
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default CustomerInfoModalEmploymentRow;
