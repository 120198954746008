import * as yup from 'yup';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {DateTime} from 'luxon';

export const regexPatterns = {
    termName: /^[a-zA-z0-9áéíóúÁÉÍÓÚ',. -]+$/,
    description: /^[a-zA-Z0-9áéíóúÁÉÍÓÚ',.!¡¿?()_:\s\n]*$/,
};

const periodSchema = yup.object().shape({
    label: yup.string(),
    value: yup
        .number()
        .required(VALIDATIONS_MSG.MANDATORY_FIELD)
        .min(1, VALIDATIONS_MSG.MANDATORY_FIELD),
});
const isFutureYear = (dateString: string | undefined | null) => {
    if (dateString) {
        const date = DateTime.fromISO(dateString!);
        const now = DateTime.now();
        return date.startOf('day') >= now.startOf('day');
    }
    return false;
};
const isValidDate = (dateString: string | undefined | null) => {
    if (dateString === null) {
        return false;
    }
    const date = DateTime.fromISO(dateString!);
    if (!date.isValid) {
        return false;
    }
    const now = DateTime.now();
    const tenYearsFromNow = now.plus({years: 10});
    return date >= now.startOf('day') && date <= tenYearsFromNow.endOf('day');
};

export const schemaNewLoanProduct = yup.object().shape(
    {
        termName: yup
            .string()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .max(100, VALIDATIONS_MSG.PRODUCT_DETAILS.TERM_NAME.MAX_LENGTH)
            .test('no-spaces', VALIDATIONS_MSG.NO_SPACES, (value) => value.trim().length > 0)
            .matches(
                regexPatterns.termName,
                VALIDATIONS_MSG.PRODUCT_DETAILS.TERM_NAME.INVALID_CHARACTERS
            ),
        description: yup
            .string()
            .optional()
            .max(500, VALIDATIONS_MSG.PRODUCT_DETAILS.DESCRIPTION.MAX_LENGTH)
            .matches(
                regexPatterns.description,
                VALIDATIONS_MSG.PRODUCT_DETAILS.DESCRIPTION.INVALID_CHARACTERS
            )
            .test('no-spaces', VALIDATIONS_MSG.NO_SPACES, (value) =>
                value ? value.trim().length > 0 : true
            ),
        minAmount: yup
            .number()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .positive(VALIDATIONS_MSG.PRODUCT_DETAILS.MIN_AMOUNT.POSITIVE_NUMBER)
            .lessThan(
                yup.ref('maxAmount'),
                VALIDATIONS_MSG.PRODUCT_DETAILS.MIN_AMOUNT.LESS_THAN_MAX
            ),
        maxAmount: yup
            .number()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .positive(VALIDATIONS_MSG.PRODUCT_DETAILS.MAX_AMOUNT.POSITIVE_NUMBER)
            .moreThan(
                yup.ref('minAmount'),
                VALIDATIONS_MSG.PRODUCT_DETAILS.MAX_AMOUNT.MORE_THAN_MIN
            ),
        porcentAnualRate: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
        periodMin: periodSchema.when('periodMax.value', {
            is: (val: number) => val !== 0,
            then: (schema) =>
                schema.test(
                    'is-less',
                    VALIDATIONS_MSG.PRODUCT_DETAILS.PERIOD_MIN.LESS_THAN_MAX_PERIOD,
                    function (value) {
                        const {periodMax} = this.parent;
                        return !periodMax || periodMax.value === 0 || value.value < periodMax.value;
                    }
                ),
        }),
        periodMax: periodSchema.when('periodMin.value', {
            is: (val: number) => val !== 0,
            then: (schema) =>
                schema.test(
                    'is-greater',
                    VALIDATIONS_MSG.PRODUCT_DETAILS.PERIOD_MAX.MORE_THAN_MIN_PERIOD,
                    function (value) {
                        const {periodMin} = this.parent;
                        return !periodMin || value.value > periodMin.value;
                    }
                ),
        }),
        requestChannel: yup
            .array()
            .of(
                yup.object().shape({
                    label: yup.string(),
                    value: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
                })
            )
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .min(1, VALIDATIONS_MSG.PRODUCT_DETAILS.REQUEST_CHANNEL.AT_LEAST_ONE),
        countLimitLoans: yup
            .number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue !== '' ? Number(originalValue) : null
            )
            .min(0, VALIDATIONS_MSG.PRODUCT_DETAILS.COUNT_LIMIT_LOANS.POSITIVE_NUMBER)
            .integer(VALIDATIONS_MSG.PRODUCT_DETAILS.COUNT_LIMIT_LOANS.INTEGER),
        frequencyTerm: yup
            .object()
            .shape({
                weekly: yup.boolean(),
                fortnightly: yup.boolean(),
                monthly: yup.boolean(),
                bimonthly: yup.boolean(),
                quarterly: yup.boolean(),
                semester: yup.boolean(),
            })
            .test(
                'at-least-one-true',
                VALIDATIONS_MSG.FREQUENCY_TERMS.FREQUENCY_TERM.AT_LEAST_ONE,
                (value) => Object.values(value).some(Boolean)
            ),
        dateDueInit: yup
            .string()
            .nullable()
            .test(
                'is-future-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_INIT.FUTURE_DATE,
                isFutureYear
            )
            .test(
                'is-valid-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_INIT.VALID_DATE,
                isValidDate
            ),
        dateDueEnd: yup
            .string()
            .nullable()
            .notRequired()
            .test(
                'is-end-date-valid',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_END.VALID_DATE,
                (value) => {
                    if (value !== null) {
                        const date = DateTime.fromISO(value!);
                        const now = DateTime.now();
                        const tenYearsFromNow = now.plus({years: 10});
                        return (
                            date.isValid &&
                            date >= now.startOf('day') &&
                            date <= tenYearsFromNow.endOf('day')
                        );
                    }
                    return true;
                }
            )
            .test(
                'is-more-than-init-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_END.MORE_THAN_INIT_DATE,
                function (value) {
                    const {dateDueInit} = this.parent;
                    if (dateDueInit !== null && value !== null && value) {
                        const initDate = DateTime.fromISO(dateDueInit);
                        const endDate = DateTime.fromISO(value);
                        return initDate < endDate;
                    }
                    return true;
                }
            ),
        typePaymentsIns: yup
            .object()
            .shape({
                bankTransfer: yup.boolean(),
                debitCard: yup.boolean(),
                creditCard: yup.boolean(),
                cashPayment: yup.boolean(),
                ataskateWeb: yup.boolean(),
            })
            .test(
                'at-least-one-true-payment',
                VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE,
                (value) => Object.values(value).some(Boolean)
            ),
    },
    [
        ['minAmount', 'maxAmount'],
        ['periodMin', 'periodMax'],
    ]
);
