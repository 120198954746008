import React, {ChangeEvent, useState} from 'react';
import * as style from '@Articles/style';
import {EMPTY_IMAGE_URL, INFORMATION, NEWCATALOG} from '@Articles/constants';
import {IFieldContent} from '@Articles/interface';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import {ContainerFlex, Input, Text, TextArea, Image} from '@Shopify/Ecommerce/styles';
import AddImage from '@images/addImg.svg';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';

const FieldContent = (props: IFieldContent) => {
    const {register, errors, setValue, watch} = props;
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const LevelsData = useSelector(
        (state: RootState) => state.GetNewCatalogId.targetLevel as number
    );
    const ErrorLabel = useSelector((state: RootState) => state.GetAddLevel.error);
    const ErrorAddLevel = useSelector(
        (state: RootState) => state.GetAddLevel.errorData?.response?.data as string
    );
    const ActionTypeForm = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.actionType as number
    );
    const DataUpdate = useSelector((state: RootState) => state.GetNewCatalogId.dataCatalog);

    const isSupportedFileType = (file: File) => {
        const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
        return supportedTypes.includes(file.type);
    };

    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            callback(reader.result as string);
        };
    };

    const handleImgChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024;
        if (fileSize <= 4 && isSupportedFileType(file)) {
            convertToBase64(file, (base64) => {
                setImageSrc(base64);
                const base64String = base64.split(',')[1];
                setValue('fileContent', base64String);
                setValue('fileExtension', file.type.split('/')[1]);
            });
        }
    };

    const TextDescription = watch('description') ?? '';

    return (
        <ContainerFlex {...style.Card}>
            <Text {...style.Card.Title} Padding="0.625rem 0">
                {INFORMATION.TITLE_SECTION}
            </Text>
            <ContainerFlex Gap="1.5rem">
                <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem" Height="auto">
                    <Text FontWeight="700" Color="#6D6E71" FontSize="1rem">
                        {INFORMATION.INPUT_NAME}
                    </Text>
                    <Input
                        {...register('name')}
                        maxLength={255}
                        minLength={3}
                        error={errors?.name?.message}
                        disabled={ActionTypeForm === NEWCATALOG.EDIT}
                        Cursor="text"
                    />
                    <Text {...style.errorInput}>{errors?.name?.message as string}</Text>
                    {ErrorLabel && <Text {...style.errorInput}>{ErrorAddLevel}</Text>}
                </ContainerFlex>
                {LevelsData === INFORMATION.FIVE && (
                    <>
                        <FileUpLoad
                            accept="image/*"
                            type="file"
                            onChange={handleImgChange}
                            id="file"
                            error={String(errors.fileContent?.message)}
                        />
                        <FileUpLoadContent {...style.contentImg}>
                            {imageSrc ? (
                                <Image
                                    alt="upload-icon"
                                    src={imageSrc}
                                    {...style.contentImg.imgStart}
                                />
                            ) : DataUpdate && DataUpdate?.imageUrl !== EMPTY_IMAGE_URL ? (
                                <Image
                                    alt="icon-add-image"
                                    src={DataUpdate.imageUrl}
                                    {...style.contentImg.imgAdd}
                                />
                            ) : (
                                <Image
                                    alt="icon-add-image"
                                    src={AddImage}
                                    {...style.contentImg.imgAdd}
                                />
                            )}
                        </FileUpLoadContent>
                    </>
                )}
            </ContainerFlex>

            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem" Height="auto">
                <Text FontWeight="700" Color="#6D6E71" FontSize="1rem">
                    {INFORMATION.INPUT_DESCRIPTION}
                </Text>

                <TextArea
                    {...register('description')}
                    Cursor="auto"
                    rows={6}
                    error={errors?.description?.message}
                />
                <Text FontSize="0.875rem" FontWeight="400" Color="#54575C">
                    {TextDescription.length}/4.000 caracteres
                </Text>
                <Text {...style.errorInput}>{errors?.description?.message as string}</Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default FieldContent;
