import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {productsLocations} from '@Locations/interfaces';
import {location} from '@Locations/AdminLocations/redux/Interfaces';
import {SelectFileVault} from '@AdminLocations/CreateLocation/style';
import {FloorById} from '@AdminLocations/redux/actions/LocationAction';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';
import {updateLocationProduct} from '@Locations/ManageLocations/redux/actions/ManageLocationsActions';

const ManageFloor = () => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const productsLoations: productsLocations = useSelector((state: RootState) => {
        return state.manageLocationsReducers;
    });
    const dispatch: AppDispatch = useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            floor: '',
        },
    });

    const onSubmit = (data: {floor: string}) => {
        const values = {
            pledgeId: productsLoations.productId.data.pledgeId,
            containerTypeId: locations.locationsidcontainer,
            floorId: Number(data.floor),
        };
        dispatch(updateLocationProduct(values, token));
    };

    const detailFloor = (value: string) => {
        dispatch(FloorById(value, token));
    };

    const restored = productsLoations.productId.data.locationFloor.floorId;
    useEffect(() => {
        if (locations.locationsidcontainer === 4) {
            if (restored) {
                detailFloor(String(restored));
                reset({floor: String(restored)});
                return;
            } else {
                detailFloor('');
            }
        }
    }, [locations.locationsidcontainer, restored]);

    return (
        <ContainerFlex Align="start" FlexDir="column">
            <ContainerForm
                Width="100%"
                FlexDir="column"
                Align="start"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Text Height="auto" FontSize="1.125rem" Padding="0 0 1.813rem 0">
                    Detalles de la ubicación en piso
                </Text>
                <SelectFileVault Width="25rem">
                    <Text FontSize="12px" required>
                        Piso
                    </Text>
                    <select
                        {...register('floor', {
                            required: {
                                value: true,
                                message: 'Selecciona un Piso',
                            },
                        })}
                        onChange={(e) => detailFloor(e.target.value)}
                    >
                        <option selected disabled hidden value="">
                            Seleccione
                        </option>
                        {locations.gFloor &&
                            locations.gFloor.data?.map((item, index: number) => (
                                <option key={index} value={item.id}>
                                    {item.floorName}
                                </option>
                            ))}
                    </select>
                    <Text Color="red" FontSize="12px">
                        {errors?.floor?.message}
                    </Text>
                </SelectFileVault>

                <ContainerFlex
                    Height="60px"
                    FlexDir="column"
                    Padding="0.5rem 0 0 0"
                    Justify="start"
                    Align="start"
                >
                    {locations.loading ? (
                        <LoadingGeneral />
                    ) : (
                        locations.floorById !== null && (
                            <>
                                <Text FontWeight="300" FontSize="12px" Color="#858997">
                                    Detalle de Piso
                                </Text>
                                <Text FontSize="0.875rem" wSpace="normal" Height="auto">
                                    {locations.floorById?.address}
                                </Text>
                            </>
                        )
                    )}
                </ContainerFlex>
            </ContainerForm>
            <ContainerFlex Justify="center" Padding="1.938rem 0 0 0">
                <ButtonGeneral
                    width="10.438rem"
                    text="Guardar y Cerrar"
                    hColor="#414042"
                    clic={handleSubmit(onSubmit)}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ManageFloor;
