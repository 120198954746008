import React from 'react';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, ContainerCellGrid, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {JOBDATA} from '@components/PersonalLoans/constants';
import {
    DataEmploymentProps,
    DataLaborOldProps,
    JobDataProps,
} from '@components/PersonalLoans/interfaces';
import {IconSvg} from '@Quoter/Icons/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {StyleNumericFormat} from '@Loan/styles';
import PhoneFormatInput from '@components/PersonalLoans/PhoneFormat';

export const PersonalLoansJob = ({control, errors, register, setNationSelected}: JobDataProps) => {
    const StateInfo = useSelector(
        (state: RootState) => state.GetAllCatEmploymentSituation?.dataEmployment || []
    );
    const StateOptions = StateInfo.map((data: DataEmploymentProps) => ({
        label: data.description,
        value: data.id,
    }));
    const LaborInfo = useSelector((state: RootState) => state.GetAllCatLaborOld.dataLabor || []);
    const LaborOptions = LaborInfo.map((data: DataLaborOldProps) => ({
        label: data.description,
        value: data.id,
    }));

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="auto" Gap="1rem">
            <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" oFlow="none">
                {JOBDATA.ABOUT}
            </Text>
            <ContainerFlex FlexWrap="wrap" Justify="start" Align="start" Gap="1rem">
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {JOBDATA.STATE}
                    </Text>
                    <Controller
                        control={control}
                        name="catEmploymentSituationId"
                        render={({field: {...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder=""
                                options={StateOptions}
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                hasError={
                                    errors?.catEmploymentSituationId?.value?.message ? true : false
                                }
                            />
                        )}
                    />

                    {errors?.catEmploymentSituationId?.value?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors?.catEmploymentSituationId?.value?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {JOBDATA.NAME}{' '}
                        <Text wSpace="pre" FontWeight="400" FontSize="1rem" Color="#54575C">
                            {JOBDATA.OPTIONAL}
                        </Text>
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('nameCompany')}
                        error={errors.nameCompany?.message}
                    />
                    {errors?.nameCompany?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.nameCompany?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {JOBDATA.ADDRESS}{' '}
                        <Text wSpace="pre" FontWeight="400" FontSize="1rem" Color="#54575C">
                            {JOBDATA.OPTIONAL}
                        </Text>
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('addresCompany')}
                        error={errors.addresCompany?.message}
                    />
                    {errors?.addresCompany?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addresCompany?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {JOBDATA.PHONE}{' '}
                        <Text wSpace="pre" FontWeight="400" FontSize="1rem" Color="#54575C">
                            {JOBDATA.OPTIONAL}
                        </Text>
                    </Text>
                    <PhoneFormatInput
                        control={control}
                        name="phoneCompany"
                        handleNationSelected={setNationSelected}
                    />
                    {errors?.phoneCompany?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.phoneCompany.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {JOBDATA.POSITION}{' '}
                        <Text wSpace="pre" FontWeight="400" FontSize="1rem" Color="#54575C">
                            {JOBDATA.OPTIONAL}
                        </Text>
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('job')}
                        error={errors.job?.message}
                    />
                    {errors?.job?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.job?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {JOBDATA.SENIORITY}
                    </Text>
                    <Controller
                        control={control}
                        name="catLaborOldId"
                        render={({field: {...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder=""
                                options={LaborOptions}
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                hasError={errors?.catLaborOldId?.value?.message ? true : false}
                            />
                        )}
                    />
                    {errors?.catLaborOldId?.value?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors?.catLaborOldId?.value?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {JOBDATA.INCOME}{' '}
                    </Text>
                    <Controller
                        name="monthlyIncome"
                        control={control}
                        render={({field: {onChange, value, ...field}}) => (
                            <StyleNumericFormat
                                {...field}
                                autoComplete="off"
                                type="text"
                                prefix="$ "
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                value={value}
                                hasError={errors.monthlyIncome?.message ? true : false}
                                width="318px"
                                onValueChange={(values) => {
                                    onChange(values.floatValue);
                                }}
                            />
                        )}
                    />
                    {errors?.monthlyIncome?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.monthlyIncome?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
            </ContainerFlex>
        </ContainerFlex>
    );
};
