import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {FOCUSED_COLORS, GENERAL_TITLES} from '@components/Users/UserProfile/constants';
import {ResentAccess} from '@components/Users/UserProfile/RecentAccess';
import {TaskTable} from '@components/Users/UserProfile/TaskTable';
import {PurpleSwitch} from '@/components/CashFlow/SwitchComponent';
import Delete from '@/images/delete _trash_red.svg';
import State from '@/images/Info_blue.svg';
import {DeleteUser} from '@components/Users/UserProfile/DeleteUser';

export const GeneralInformation = () => {
    const [error, setError] = useState(false);
    return (
        <ContainerFlex FlexDir="column" Gap="16px" Justify="start" Align="start">
            <ContainerFlex Height="1.25rem" Justify="start">
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="80px" Radius="24px" Border={FOCUSED_COLORS.BORDER_LEFT}>
                <ContainerFlex Width="50%" Padding="16px" FlexDir="column" Gap="8px">
                    <Text FontSize="1.25rem" FontWeight="700">
                        {GENERAL_TITLES.VALUE_PAW}
                    </Text>
                    <Text>{GENERAL_TITLES.PAWN}</Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="50%"
                    Padding="16px"
                    FlexDir="column"
                    Gap="8px"
                    Bl={FOCUSED_COLORS.BORDER_LEFT}
                >
                    <Text FontSize="1.25rem" FontWeight="700">
                        {GENERAL_TITLES.VALUE_PAW}
                    </Text>
                    <Text>{GENERAL_TITLES.IN}</Text>
                </ContainerFlex>
            </ContainerFlex>
            <TaskTable />
            <ResentAccess />
            <ContainerFlex
                Radius="24px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
                Justify="start"
                Align="startt"
                Padding="16px"
                FlexDir="column"
                Gap="16px"
                ColumnGap="8px"
            >
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.USER}
                </Text>
                <ContainerFlex Justify="space-between" Align="start" ColumnGap="8px">
                    <Image alt="" src={State} Height="14px" />
                    <Text Width="70%" FontWeight="400" wSpace="">
                        {GENERAL_TITLES.DESCATIVATE}
                    </Text>
                    <ContainerFlex Justify="end" Width="30%" Gap="8px">
                        <Text FontSize="0.875rem">{GENERAL_TITLES.USER_ACTIVE}</Text>
                        <PurpleSwitch />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Radius="24px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
                Justify="start"
                Align="startt"
                Padding="16px"
                FlexDir="column"
                Gap="8px"
            >
                {error && <DeleteUser setShow={setError} />}
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.ACCOUNT}
                </Text>
                <ContainerFlex Justify="space-between">
                    <Text Width="70%" FontWeight="400" wSpace="">
                        {GENERAL_TITLES.ACCOUNT_DESCRIPTION}
                    </Text>
                    <ContainerFlex
                        Width="30%"
                        Gap="8px"
                        Justify="end"
                        Cursor="pointer"
                        onClick={() => setError(true)}
                    >
                        <Image alt="" src={Delete} Height="14px" />
                        <Text FontSize="0.875rem" Color="#A82424" Cursor="pointer">
                            {GENERAL_TITLES.DELETE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
