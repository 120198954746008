import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {
    CREDIT_DETAILS_INFORMATION,
    ALTS,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import loansBtnIcon from '@/components/PersonalLoans/icons/loansBtaIcon.svg';
import addShoppingCartIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/addShoppingCartIcon.svg';
import {IPersonalCreditDetailsReducer} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {RootState} from '@/config/store';
import {useSelector, useDispatch} from 'react-redux';
import {routers} from '@/appRoute';
import {useNavigate, useLocation} from 'react-router-dom';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {addCreditToShoppingCarAction} from './Redux/Actions/ShoppingCarClientActions';

const CreditDetailsInformationStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state: {personalCreditId},
    } = useLocation();
    const {token, carShoopingId} = useSelector((state: RootState) => {
        const userData = state.getUsersValidation.userData;
        return {
            token: String(userData?.token),
            carShoopingId: Number(userData?.carShoopingId),
        };
    });
    const {personalCreditAmountData} = useSelector((state: RootState) => {
        return state.GetPersonalCreditAmountReducer as IPersonalCreditDetailsReducer;
    });
    const handleAddShoppingCart = () => {
        if (token && carShoopingId && personalCreditId) {
            dispatch(addCreditToShoppingCarAction(personalCreditId, token, String(carShoopingId)));
        }
    };
    return (
        <ContainerFlex Width="100%" Height="100%" FlexDir="column" Gap="24px">
            <ContainerFlex Width="100%" Justify="space-between" Align="center">
                <ContainerFlex FlexDir="column" Align="start" Justify="center">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#000000">
                        {`${CREDIT_DETAILS_INFORMATION.STATUS.SUB_TITLE} ${personalCreditAmountData?.crontactNumber}`}
                    </Text>
                </ContainerFlex>
                <ContainerFlex MaxW="150px" FlexDir="column" Align="start" Justify="end" Gap="8px">
                    <Image Width="147px" Height="37px" src={loansBtnIcon} alt={ALTS.LOANS_ICON} />
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        {CREDIT_DETAILS_INFORMATION.STATUS.CTA_VISIT_STORE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px"
                Border="1px solid #0D166B"
                backG="#fff"
                Width="100%"
                Height="100%"
                Flex="0"
                Radius="16px"
                Justify="space-between"
            >
                <ContainerFlex Width="100%" Align="start" Justify="start">
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {numberToCurrencyWithoutCents(
                                (personalCreditAmountData?.paymentAmount as number) ?? 0
                            )}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.PAID}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {numberToCurrencyWithoutCents(
                                personalCreditAmountData?.indebtedness ?? 0
                            )}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.OWE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {personalCreditAmountData?.quotesCount}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.FEES}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Width="100%" Gap="16px">
                    <ButtonGenerals
                        SecondaryButton="#FFFFFF"
                        Width="100%"
                        Height="40px"
                        Gap="4px"
                        Padding="4px 16px"
                        Justify="center"
                        FontFamily="Nunito"
                        FontSize="0.88rem"
                        FontWeight="700"
                        onClick={handleAddShoppingCart}
                    >
                        <Image
                            Width="24px"
                            Height="24px"
                            src={addShoppingCartIcon}
                            alt={ALTS.ADD_SHOPPING_CART}
                        />
                        {CREDIT_DETAILS_INFORMATION.STATUS.CTA_PAYMENT}
                    </ButtonGenerals>
                    <ButtonGenerals
                        BackGC="#5A5AFF"
                        Color="#FFF"
                        Width="100%"
                        Height="40px"
                        Gap="8px"
                        Padding="8px 16px"
                        Justify="center"
                        FontFamily="Nunito"
                        FontSize="0.88rem"
                        FontWeight="700"
                        onClick={() => navigate(routers.CreditsCartShopping)}
                    >
                        {CREDIT_DETAILS_INFORMATION.STATUS.CTA_ADD_TO_CART}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailsInformationStatus;
