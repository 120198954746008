import * as yup from 'yup';

const regexAlphanumeric = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/;
export const AddressShema = yup.object().shape({
    postalCode: yup
        .string()
        .required('Ingresar el código postal')
        .min(5, 'El código postal  debe tener 5 dígitos')
        .max(5, 'El código postal  debe tener 5 dígitos'),
    street: yup.string().required('Ingresar la calle').max(100, 'Ingresar máximo 100 caracteres'),
    outerNum: yup
        .string()
        .required('Ingresar una No. exterior')
        .max(5, 'Ingresar máximo 5 caracteres')
        .matches(regexAlphanumeric, 'Ingresá alfanuméricos'),
    interNum: yup
        .string()
        .optional()
        .test('length', 'Ingresá menos de 6 caracteres', (value) => {
            if (!value) return true;
            return value.length <= 5;
        })
        .test('is-valid', 'Ingresá alfanuméricos', (value) => {
            if (!value) return true;
            return regexAlphanumeric.test(value) && value.length <= 5 && value.length >= 1;
        }),
    colony: yup
        .object()
        .shape({
            value: yup.string(),
            label: yup.string(),
        })
        .typeError('Seleccione la colonia'),
    municipaly: yup
        .object()
        .shape({
            value: yup.string(),
            label: yup.string(),
        })
        .typeError('Seleccione el municipio'),
    state: yup
        .object()
        .shape({
            value: yup.string(),
            label: yup.string(),
        })
        .typeError('Seleccione el estado'),
});
