import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {ICheckboxFilterProps} from '@components/CreditsAndPawns/interfaces';

const CheckboxFilter = ({filterOptions, title, handleFilter, filters}: ICheckboxFilterProps) => {
    const optionsHeight = filterOptions.length * 32;

    const handleCheckbox = (value: number) => {
        const isChecked = filters.Frequency.includes(value);
        const updatedFrecuencia = isChecked
            ? filters.Frequency.filter((id) => id !== value)
            : [...filters.Frequency, value];
        handleFilter({
            ...filters,
            Frequency: updatedFrecuencia,
        });
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Gap="8px"
            Justify="flex-start"
            Align="flex-start"
            Height={`${40 + optionsHeight}px`}
        >
            <Text
                Color="#1D1E20"
                FontFamily="Nunito"
                FontWeight="700"
                LHeight="24px"
                LetterSpacing="0.3px"
            >
                {title}
            </Text>
            {filterOptions.map((option) => {
                return (
                    <ContainerFlex
                        Justify="flex-start"
                        Align="center"
                        Gap="4px"
                        Height="24px"
                        key={option.id}
                    >
                        <Checkbox
                            onChange={() => handleCheckbox(option.id)}
                            checked={filters.Frequency.includes(option.id)}
                        />
                        <Text
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontWeight="400"
                            LHeight="24px"
                            LetterSpacing="0.3px"
                        >
                            {option.nombreFrecuencia}
                        </Text>
                    </ContainerFlex>
                );
            })}
        </ContainerFlex>
    );
};

export default CheckboxFilter;
