import React from 'react';
export const RowDocumentFoundry = (props: {
    kilataje: number;
    cantidad: number;
    gramos: number;
    costo: number;
}) => {
    const {kilataje, cantidad, gramos, costo} = props;
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return (
        <tr className="row-sucursal-tr">
            <td>{kilataje}K</td>
            <td>{cantidad}</td>
            <td>{gramos}</td>
            <td>{formatterDolar.format(costo)}</td>
        </tr>
    );
};
