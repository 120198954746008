import styled from '@emotion/styled';
import {Select, MenuItem, Chip, FormControlLabel} from '@mui/material';
import {MenuItemStyledProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const SelectStyled = styled(Select)`
    width: 363px;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e8e9ea;
    border-radius: 32px;
    padding: 8px 16px;
    position: relative;
    &:focus-within {
        border-color: #2a2c2f;
    }
    .MuiSelect-icon {
        display: none;
    }
    .MuiSelect-select {
        padding: 0;
    }
`;

export const ChipStyled = styled(Chip)`
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito';
    border-radius: 8px;
    padding: 4px 8px;
    margin: 0 4px;
`;

export const MenuPropsStyled = styled.div`
    padding: 0;
`;

export const PaperPropsStyled = styled.div`
    border-radius: 8px;
    padding: 0;
    border: 1px solid #f3f3f3;
    background: #fff;
    box-shadow: 0px 11px 26.6px rgba(0, 0, 0, 0.06);
`;

export const MenuItemStyled = styled(MenuItem)<MenuItemStyledProps>`
    display: flex;
    justify-content: start;
    padding: ${(props) => props.Padding};
    gap: 8px;
    border-left: 2px solid transparent;

    &:hover {
        border-left: 2px solid #5a5aff;
        background-color: #e5e5ff;
        color: #5a5aff;
    }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
    display: flex;
    padding: 0;
    margin: 0;

    .MuiFormControlLabel-label {
        padding: 0;
        margin-left: 8px;
        color: #2a2c2f;
        font-family: 'Nunito';
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.3px;
    }
`;
