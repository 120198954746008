import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';

import {listElectronics} from '@Electronics/listElectronics';
import Promociones from '@Promociones/index';
import '@Electronics/styles.css';
import Filters from '@Electronics/Filters';
import {IconContainer} from '@/components/Quoter/styles';

import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {GetProducts} from '@ActionsDragandDrop/actions';

export const FormatNumber = (number) => {
    return new Intl.NumberFormat('ES-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumSignificantDigits: '2',
    }).format(number);
};

const Electronics = () => {
    const {category} = useParams();
    const products = useSelector((state) => state.productsEcommerce.products[0]);
    const [pro, setPro] = useState(listElectronics);
    const [array, setArray] = useState([]);
    const [price, setPrice] = useState([3000, 8000]);
    const dispatch = useDispatch();
    const [statebutton, setStatebutton] = useState(false);

    useEffect(() => {
        dispatch(GetProducts(listElectronics));
    }, []);

    const HandleFilterCategory = (e, value) => {
        let val = e.target.value;
        if (value === true) {
            setArray([...array, val]);
        } else {
            setArray(array.filter((element) => element !== val));
        }
    };
    const HandlePricePrint = (value) => {
        setPrice(value);
    };

    const ResponseFilters = (array, price, products) => {
        let product = [];
        if (array.length >= 1) {
            array.forEach((element) => {
                products.forEach((value) => {
                    if (
                        value.category === element ||
                        value.brand === element ||
                        (value.sale >= price[0] && value.sale <= price[1])
                    ) {
                        product = [...product, value];
                    }
                });
            });
        } else {
            product = products.filter(
                (element) => element.sale >= price[0] && element.sale <= price[1]
            );
        }
        if (product.length === 0) {
            setStatebutton(false);
            setTimeout(() => {
                setStatebutton(true);
                setPro(listElectronics);
            }, 3000);
        }

        // let result = [...new Set(product)];
        setPro([...new Set(product)]);
    };

    return (
        <div className="container-second">
            <div className="mt-4">
                <NavLink to="#" className="links-pages-first">
                    <IconContainer className="material-icons" FontSize="1rem">
                        keyboard_arrow_left
                    </IconContainer>
                    Regresar a los resultados
                </NavLink>
                <NavLink exact to={`/Ecommerce/${category}`} className="links-pages-second">
                    <IconContainer className="material-icons" FontSize="1rem">
                        keyboard_arrow_left
                    </IconContainer>
                    {category}
                </NavLink>
            </div>
            <div className="row mt-4">
                <div className="col-2performance">
                    <Filters
                        statebutton={statebutton}
                        price={price}
                        HandlePricePrint={(e, value) => HandlePricePrint(value)}
                        HandleCheckBox={(e, value) => HandleFilterCategory(e, value)}
                        HandleFilters={() => ResponseFilters(array, price, products)}
                    />
                </div>
                <div className="col-10">
                    {pro.length >= 1 ? (
                        <ul className="row-categories">
                            {pro.map((element, index) => {
                                return (
                                    <li
                                        className="col-lg-4 col-md-3 col-sm-6 border-sides mt-4 animated "
                                        key={index}
                                    >
                                        <div
                                            className="card-img"
                                            style={{backgroundImage: `url(${element.img})`}}
                                        />
                                        <span className="txt-title mt-2">{element.category}</span>
                                        <p className="description">{element.name}</p>
                                        <div className="row">
                                            <div className="col-6 ">
                                                <p>Apartado</p>
                                                <span>{FormatNumber(element.apart)}</span>
                                            </div>
                                            <div className="col-6">
                                                <p>Precio</p>
                                                <p className="price">
                                                    {FormatNumber(element.sale)}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <LoadingGeneral />
                    )}
                </div>
                <Promociones />
            </div>
        </div>
    );
};

export default Electronics;
