import React from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';

import * as style from '@Quoter/stylesConstants';
import {CATEGORTIZATION, LEVELS} from '@components/Quoter/constants';
import {addDinamics} from '@Quoter/Redux/Actions/saveEndeavors';
import {IDinamicsArticles, ISelectReact} from '@Quoter/inferfaces';
import {InputsDinamics} from '@Quoter/Steps/StepOne/InputsDinamics';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerFlex, ContainerForm, Text, TextArea} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

export const DinamicsArticle = (props: IDinamicsArticles) => {
    const {
        errors,
        control,
        register,
        handleBrand,
        handleFamily,
        selectConvert,
        handleArticle,
        handleSubfamily,
        dinamicsProducts,
        setValue,
    } = props;
    const {group, family, subfamily, brand, article, loadingArticle, propertiesarticles} =
        useSelector((state: RootState) => state.propertiesArticle);
    const {inputDinamics, productSearch} = useSelector((state: RootState) => state.saveEndeavor);
    const dispatch: AppDispatch = useDispatch();

    return !loadingArticle ? (
        <ContainerForm
            FlexDir="column"
            Align="start"
            autoComplete="off"
            MarginT="1rem"
            Gap="1.5rem"
            Width="100%"
        >
            <ContainerFlex {...style.content}>
                <ContainerFlex {...style.selectContent}>
                    <Text {...style.level}>{CATEGORTIZATION.LEVEL1}</Text>
                    <Controller
                        name="Group"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                isSearchable={false}
                                options={selectConvert(group ? group.groupList : [])}
                                isLoading={!group}
                                hasError={errors.Group?.message ? true : false}
                                noOptionsMessage={() => 'Sin opciones'}
                                onChange={(e) => {
                                    handleFamily(e);
                                }}
                            />
                        )}
                    />
                    <Text Color="red" FontWeight="400" FontSize="0.7rem">
                        {errors.Group?.message as string}
                    </Text>
                </ContainerFlex>
                <ContainerFlex {...style.selectContent}>
                    <Text {...style.level}>{CATEGORTIZATION.LEVEL2}</Text>
                    <Controller
                        name="Family"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                options={selectConvert(family ? family.familyData : [])}
                                isSearchable={false}
                                isLoading={
                                    productSearch.levelsIds.length > 0 &&
                                    family &&
                                    family.familyData.length === 0
                                }
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                hasError={errors.Family?.message ? true : false}
                                onChange={(e) => {
                                    if (onChange) onChange(e);
                                    handleSubfamily(e);
                                }}
                            />
                        )}
                    />
                    <Text Color="red" FontWeight="400" FontSize="0.7rem">
                        {errors.Family?.message as string}
                    </Text>
                </ContainerFlex>
                <ContainerFlex {...style.selectContent}>
                    <Text {...style.level}>{CATEGORTIZATION.LEVEL3}</Text>
                    <Controller
                        name="Subfamily"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                isSearchable={false}
                                hasError={errors.Subfamily?.message ? true : false}
                                options={selectConvert(subfamily ? subfamily.subfamilyList : [])}
                                isLoading={
                                    productSearch.levelsIds[LEVELS.FAMILY] !== 0 &&
                                    subfamily &&
                                    subfamily.subfamilyList.length === 0
                                }
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                onChange={(e) => {
                                    if (onChange) onChange(e);
                                    handleBrand(e);
                                }}
                            />
                        )}
                    />
                    <Text Color="red" FontWeight="400" FontSize="0.7rem">
                        {errors.Subfamily?.message as string}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <>
                <ContainerFlex {...style.content}>
                    <ContainerFlex {...style.selectContent}>
                        <Text {...style.level}>{CATEGORTIZATION.BRAND}</Text>
                        <Controller
                            name="Brand"
                            control={control}
                            render={({field: {onChange, ref, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(brand ? brand.brandList : [])}
                                    isLoading={
                                        productSearch.levelsIds[LEVELS.SUBFAMILY] !== 0 &&
                                        brand &&
                                        brand.brandList.length === 0
                                    }
                                    loadingMessage={() => 'Cargando...'}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    hasError={errors.Brand?.message ? true : false}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        handleArticle(e);
                                    }}
                                />
                            )}
                        />
                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                            {errors.Brand?.message as string}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex {...style.selectContent}>
                        <Text {...style.level}>{CATEGORTIZATION.ARTICLE}</Text>
                        <Controller
                            name="Article"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={errors.Article?.message ? true : false}
                                    options={selectConvert(article ? article.articleList : [])}
                                    isLoading={
                                        productSearch.levelsIds[LEVELS.BRAND] !== 0 &&
                                        article &&
                                        article.articleList.length === 0
                                    }
                                    loadingMessage={() => 'Cargando...'}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        dinamicsProducts(e as ISelectReact);
                                    }}
                                />
                            )}
                        />
                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                            {errors.Article?.message as string}
                        </Text>
                    </ContainerFlex>
                    <InputsDinamics
                        errors={errors}
                        control={control}
                        dinamics={propertiesarticles}
                        register={register}
                        setValue={setValue}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start">
                    <Text {...style.level}>{CATEGORTIZATION.NOTES}</Text>
                    <TextArea
                        Cursor="auto"
                        rows={5}
                        {...register('Note')}
                        error={!!errors.Note?.message}
                        onChange={(e) => {
                            dispatch(
                                addDinamics({
                                    ...inputDinamics,
                                    Note: e.target.value,
                                })
                            );
                        }}
                    />
                    <Text Color="red" FontWeight="400" FontSize="0.7rem">
                        {errors?.Note?.message as string}
                    </Text>
                </ContainerFlex>
            </>
        </ContainerForm>
    ) : (
        <ContainerFlex Height="11rem">
            <LoadingAtaskate />
        </ContainerFlex>
    );
};
