import React from 'react';

import emailTicket from '@images/mailIcon.svg';
import printTicket from '@images/printIcon.svg';
import ButtonGeneral from '@General/Atoms/Button';
import downloadTicket from '@images/download_focused.svg';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';

export const PrintTicket = () => {
    return (
        <ContainerFlex Gap="1.5rem">
            <ButtonGeneral
                secondaryButton
                text={
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700" Gap="0.5rem">
                        <Image src={emailTicket} alt="email-icon" />
                        {SHOPPING_CART.SHARE_TICKET}
                    </Text>
                }
            />
            <ButtonGeneral
                secondaryButton
                text={
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700" Gap="0.5rem">
                        <Image src={printTicket} alt="print-icon" />
                        {SHOPPING_CART.PRINT_TICKET}
                    </Text>
                }
            />
            <ButtonGeneral
                secondaryButton
                text={
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700" Gap="0.5rem">
                        <Image src={downloadTicket} alt="download-icon" />
                        {SHOPPING_CART.DOWNLOAD_TICKET}
                    </Text>
                }
            />
        </ContainerFlex>
    );
};
