import styled from '@emotion/styled';
import {
    IPropsContainerModal,
    IPropsIcons,
    IPropsInput,
    IPropsModalDialog,
    IPropsSelect,
} from '@CreateBranch/BranchInformation/interfacesStyles';
import arrowSelect from '@images/arrowSelect.svg';
import {keyframes, css} from '@emotion/react';

export const Icons = styled.i<IPropsIcons>`
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: ${(props) => props.FontWeight};
    cursor: ${(props) => props.Cursor};
    margin-left: ${(props) => props.MarginLeft};
    margin-right: ${(props) => props.MarginRight};
    margin-bottom: ${(props) => props.MarginBottom};
    margin-top: ${(props) => props.MarginTop};
    margin: ${(props) => props.Margin};
    opacity: ${(props) => props.Opacity};
    display: ${(props) => props.Display && props.Display};
    justify-content: ${(props) => props.Justify && props.Justify};
    align-items: ${(props) => props.Align && props.Align};
    width: ${(props) => props.Width};

    &:hover {
        color: ${(props) => props.HColor};
    }
`;

export const Select = styled.select<IPropsSelect>`
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    cursor: ${(props) => props.Cursor};
    text-transform: ${(props) => props.Transform};

    box-sizing: border-box;
    background-image: url(${arrowSelect});
    background-position: right 0.6rem center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    option,
    i {
        text-transform: ${(props) => props.TransformO};
        color: ${(props) => props.ColorOption};
        box-sizing: border-box;
        font-size: 1rem;
        padding: 0.75rem 0.5rem;
        height: 40px;
    }

    &:focus {
        border: ${(props) => props.FBorder};
        outline: 1px solid #e8e9ea;
    }
    &:hover {
        border: ${(props) => props.HBorder};
    }

    &::-ms-expand {
        display: none;
    }
`;

Select.defaultProps = {
    Height: '40px',
    Radius: '2.125rem',
    Border: '1px solid #E8E9EA',
    Padding: '0 1rem',
    Color: '#2A2C2F',
    Cursor: 'pointer',
};

export const Input = styled.input<IPropsInput>`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e4e7e9;
    border-radius: ${(props) => props.Radius};
    opacity: 1;
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    padding: ${(props) => props.Padding};
    width: ${(props) => props.Width};
    text-transform: ${(props) => props.Transform};
    outline: ${(props) => props.Outline};
    box-sizing: border-box;
    ::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :disabled {
        background: ${(props) => props.DBackGround};
        border: ${(props) => props.DBorder};
    }
    ::placeholder {
        color: ${(props) => props.PlaceholderColor};
        font-size: ${(props) => props.PlaceholderFontSize};
    }

    &:hover {
        border: ${(props) => props.BorderH};
        box-shadow: ${(props) => props.BoxShadowH};
    }
    &:focus-within {
        border: ${(props) => props.FBorder};
        box-shadow: ${(props) => props.FBoxShadow};
    }
`;

Input.defaultProps = {
    Height: '40px',
    Width: '100%',
    Padding: '0 0.5rem',
    Radius: '5px',
    PlaceholderColor: '#a7a9ac',
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;
const slideOut = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;
export const ContainerModal = styled.div<IPropsContainerModal>`
    width: 100%;
    height: ${(props) => props.HeightCont};
    max-height: ${(props) => props.MaxH};
    position: ${(props) => props.Position};
    z-index: 100;
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    display: flex;
    flex-direction: ${(props) => props.FlexDir};
    margin: ${(props) => props.Margin};
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    justify-content: ${(props) => props.Justify};
    padding: ${(props) => props.PaddingCont};
    gap: ${(props) => props.Gap};
    background: ${(props) => props.Background};
    border-radius: ${(props) => props.BorderRadius};
    box-shadow: ${(props) => props.BoxShadow};
    padding: ${(props) => props.Padding};
    animation: ${(props) =>
        props.ApplyAnimation
            ? css`
                  ${props.AnimationState === 'entering' ? fadeIn : fadeOut} 0.3s
              `
            : 'none'};

    > div {
        animation: ${(props) =>
            props.ApplyAnimation
                ? css`
                      ${props.AnimationState === 'entering' ? slideIn : slideOut} 0.2s forwards
                  `
                : 'none'};
    }
`;

ContainerModal.defaultProps = {
    Position: 'fixed',
    Justify: 'center',
    FlexDir: 'row',
    Align: 'center',
    FlexWrap: 'nowrap',
    Left: '0',
    Background: '#414042cc',
    BorderRadius: '0',
};

export const ModalDialog = styled.div<IPropsModalDialog>`
    display: flex;
    height: ${(props) => props.HeightDialog};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    flex-direction: ${(props) => props.FlexDirMod};
    background: ${(props) => props.backG};
    justify-content: ${(props) => props.JustifyMod};
    padding: ${(props) => props.Padding};
    align-items: ${(props) => props.AlignMod};
    flex-wrap: ${(props) => props.FlexWrapMod};
    border: ${(props) => props.Border};
    cursor: ${(props) => props.Cursor};
    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    color: ${(props) => props.Color};
    box-shadow: ${(props) => props.BoxShadow};
    z-index: ${(props) => props.ZIndex};
    position: ${(props) => props.Position};
    gap: ${(props) => props.Gap};
    border-radius: ${(props) => props.BorderRadius};
    max-height: ${(props) => props.MaxHDialog};
`;

ModalDialog.defaultProps = {
    Margin: '0',
    FlexDirMod: 'row',
    backG: '#ffff',
    JustifyMod: 'center',
    Padding: '0',
    Cursor: 'auto',
    AlignMod: 'center',
    FlexWrapMod: 'nowrap',
    Border: ' ',
    BoxShadow: '5px 5px 20px rgba(167, 169, 172, 0.5)',
    FontSize: '1rem',
    FontWeight: '500',
    Color: '#1111',
    BorderRadius: '1.5rem',
};
export const Edit = styled.p`
    font-weight: 400;
    font-size: 1rem;
    margin: 0.25rem 0;
    color: transparent;
`;
