import axios, {AxiosResponse} from 'axios';
import {
    GET_BRANCH_SCHEDULE_ENDEAVOR,
    GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR,
    GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH,
    GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS,
    GET_BRANCH_SCHEDULE_ENDEAVOR_RESET,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetScheduleEndeavorStart = () => {
    return {
        type: GET_BRANCH_SCHEDULE_ENDEAVOR,
    };
};
export const fetchGetScheduleEndeavorSuccess = (result: AxiosResponse) => {
    return {
        type: GET_BRANCH_SCHEDULE_ENDEAVOR_SUCCESS,
        payload: result,
    };
};
export const fetchGetScheduleEndeavorError = () => {
    return {
        type: GET_BRANCH_SCHEDULE_ENDEAVOR_ERROR,
    };
};
export const fetchGetScheduleEndeavorFinish = () => {
    return {
        type: GET_BRANCH_SCHEDULE_ENDEAVOR_FINISH,
    };
};
export const fetchGetScheduleEndeavorReset = () => {
    return {
        type: GET_BRANCH_SCHEDULE_ENDEAVOR_RESET,
    };
};

export function GetScheduleEndeavor(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetScheduleEndeavorStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetScheduleEndeavor}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetScheduleEndeavorSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetScheduleEndeavorError());
        } finally {
            dispatch(fetchGetScheduleEndeavorFinish());
        }
    };
}
