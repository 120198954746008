import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_LEVELS,
    GET_LEVELS_ERROR,
    GET_LEVELS_SUCCESS,
    IS_EDIT,
    PASS_ORGANIZATION,
    SELECT_NODE_ID,
    SELECT_ORGANIZATION_NAME,
    SELECT_STATE_MODAL_ORG,
} from '@TypesOrgCreation/types';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';

export const getAllLevels = () => {
    return {
        type: GET_LEVELS,
    };
};
export const getAllLevelsSuccess = (result: AxiosResponse<string>) => {
    return {
        type: GET_LEVELS_SUCCESS,
        payload: result.data,
    };
};

export const getAllLevelsError = (err: unknown) => {
    return {
        type: GET_LEVELS_ERROR,
        payload: err,
    };
};
interface IGetOrganization {
    companyId: number;
    search: string;
    pageSize: number;
    pageNumber: number;
}
export function getAllLevelsOrganization(filters: IGetOrganization, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(getAllLevels());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllLevels}${queryString}`,
                {headers}
            );
            dispatch(getAllLevelsSuccess(response));
        } catch (err) {
            dispatch(getAllLevelsError(err));
        }
    };
}

export const IsEditable = (value: boolean) => {
    return {
        type: IS_EDIT,
        payload: value,
    };
};
export const fetchNewPassOrganization = (value: number) => {
    return {
        type: PASS_ORGANIZATION,
        payload: value,
    };
};
interface IDataNode {
    levelName: string;
    description: string;
}
export const fetchOrganizationName = (value: IDataNode | string | null) => {
    return {
        type: SELECT_ORGANIZATION_NAME,
        payload: value,
    };
};
interface node {
    idLevel: number;
    idFather: number;
}
export const fetchSelectNodeId = (value: node | string) => {
    return {
        type: SELECT_NODE_ID,
        payload: value,
    };
};
export const fetchSelectStateModalOrg = (value: boolean) => {
    return {
        type: SELECT_STATE_MODAL_ORG,
        payload: value,
    };
};
