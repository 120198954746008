import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {ERROR_MESSAGES} from '@Quoter/constants';
import {IconSvg} from '@/components/CashFlow/styles';
import {IContractNames} from '@Quoter/inferfaces';

export const ErrorPromotion = () => {
    return (
        <ContainerFlex
            Height="63px"
            Padding="8px 24px"
            ColumnGap="8px"
            backG="#FFF5DB"
            Align="start"
            Justify="space-between"
        >
            <IconSvg Width="20px" height="20px">
                <path
                    d="M0.833008 18.0001L9.99967 2.16675L19.1663 18.0001H0.833008ZM9.99967 15.5001C10.2358 15.5001 10.4337 15.4202 10.5934 15.2605C10.7531 15.1008 10.833 14.9029 10.833 14.6667C10.833 14.4306 10.7531 14.2327 10.5934 14.073C10.4337 13.9133 10.2358 13.8334 9.99967 13.8334C9.76356 13.8334 9.56565 13.9133 9.40592 14.073C9.2462 14.2327 9.16634 14.4306 9.16634 14.6667C9.16634 14.9029 9.2462 15.1008 9.40592 15.2605C9.56565 15.4202 9.76356 15.5001 9.99967 15.5001ZM9.16634 13.0001H10.833V8.83342H9.16634V13.0001Z"
                    fill="#CC9200"
                />
            </IconSvg>
            <ContainerFlex Gap="10px" Align="start" FlexDir="column">
                <Text FontWeight="500" Color="#614500" FontSize="1rem">
                    {ERROR_MESSAGES.TITLE_PROMOTION}
                </Text>

                <Text FontWeight="400" Color="#614500" FontSize="1rem">
                    {ERROR_MESSAGES.SUB_TITLE_PROMOTION}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export const ErrorPromotions = (props: IContractNames) => {
    return (
        <ContainerFlex
            Height="auto"
            Padding="8px 24px"
            ColumnGap="8px"
            backG="#FFE6E6"
            Align="start"
            Justify="space-between"
            Radius="0.5rem"
        >
            <IconSvg Width="20px" height="20px">
                <path
                    d="M9.99984 18.3334C8.84706 18.3334 7.76373 18.1145 6.74984 17.6767C5.73595 17.2395 4.854 16.6459 4.104 15.8959C3.354 15.1459 2.76039 14.264 2.32317 13.2501C1.88539 12.2362 1.6665 11.1529 1.6665 10.0001C1.6665 8.8473 1.88539 7.76397 2.32317 6.75008C2.76039 5.73619 3.354 4.85425 4.104 4.10425C4.854 3.35425 5.73595 2.76036 6.74984 2.32258C7.76373 1.88536 8.84706 1.66675 9.99984 1.66675C11.1526 1.66675 12.2359 1.88536 13.2498 2.32258C14.2637 2.76036 15.1457 3.35425 15.8957 4.10425C16.6457 4.85425 17.2393 5.73619 17.6765 6.75008C18.1143 7.76397 18.3332 8.8473 18.3332 10.0001C18.3332 11.1529 18.1143 12.2362 17.6765 13.2501C17.2393 14.264 16.6457 15.1459 15.8957 15.8959C15.1457 16.6459 14.2637 17.2395 13.2498 17.6767C12.2359 18.1145 11.1526 18.3334 9.99984 18.3334ZM6.99984 14.1667L9.99984 11.1667L12.9998 14.1667L14.1665 13.0001L11.1665 10.0001L14.1665 7.00008L12.9998 5.83342L9.99984 8.83342L6.99984 5.83342L5.83317 7.00008L8.83317 10.0001L5.83317 13.0001L6.99984 14.1667Z"
                    fill="#A82424"
                />
            </IconSvg>
            <ContainerFlex Gap="10px" Align="start" FlexDir="column">
                <Text FontWeight="500" Color="#501111" FontSize="1rem" wSpace="normal">
                    {ERROR_MESSAGES.TITLE_PROMOTIONS_ERROR}: {props.contractNames}.
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
