import React from 'react';
import IconPolygon from '@components/General/BranchTree/icons/Polygon.svg';
import {ContainerFlex, Figure, Image, Text} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@Atoms/Checkbox/Checkbox';
import {ILevelItem} from '@components/Branches/interfaces';

export const LevelItem = ({
    item,
    selectedIds,
    onSelect,
    levelSelected,
    watchLevel,
    trigger,
}: ILevelItem) => {
    const isSelected = (id: number) => selectedIds.some((selectId: number) => selectId === id);
    const handleToggle = () => {
        onSelect(item.idLevel);
    };
    return (
        <ContainerFlex Gap="5px" Height="40px" Justify="start">
            <Figure Rotate={isSelected(item.idLevel) ? '90deg' : ''}>
                <Image src={IconPolygon} alt="icon-polygon" onClick={handleToggle} />
            </Figure>
            <Checkbox
                checked={item.idLevel === watchLevel}
                onChange={() => {
                    levelSelected(item.idLevel);
                    trigger('levelId');
                }}
            />
            <Text Color="#414042" FontSize="0.875rem" FontWeight={item.step === 1 ? '500' : '400'}>
                {item.levelName}
            </Text>
        </ContainerFlex>
    );
};
