import styled from '@emotion/styled';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {Popover as MuiPopover} from '@mui/material';

export const CalendarModal = styled(MuiPopover)`
    .MuiPaper-root {
        border-radius: 8px;
    }
`;

export const CalendarSelect = styled(SelectGeneral)`
    width: 100%;

    > div {
        padding: 0px 16px;
        gap: 8px;
    }
`;

export const CalendarWrapper = styled.div`
    .calendar-options .MuiPickersCalendarHeader-root {
        display: none;
    }

    .MuiDayPicker-header > .MuiDayPicker-weekDayLabel {
        font-family: 'Work Sans';
        font-size: 0.875rem;
        font-weight: 500;
        color: #2a2c2f;
    }

    .MuiPickersDay-root {
        font-family: 'Work Sans';
        font-size: 0.875rem;
        font-weight: 400;
        color: #2a2c2f;
    }

    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
        background-color: #5a5aff;
        color: #fff;

        &:hover {
            background-color: #0d166b;
        }
    }

    .PrivatePickersSlideTransition-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 230px;
    }
`;
