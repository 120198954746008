import {
    VALIDATE_BANK_CARD_START,
    VALIDATE_BANK_CARD_SUCCESS,
    VALIDATE_BANK_CARD_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {
    CardDataAction,
    ValidationBankCardResponse,
} from '@components/AccountSettings/Redux/interfaces';

const initialState: ValidationBankCardResponse = {
    error: false,
    errorData: null,
    loading: false,
    cardData: {
        id: 0,
        typeCard: '',
        imageUrl: '',
    },
};

const ValidateBankCardType = (
    state = initialState,
    action: CardDataAction
): ValidationBankCardResponse => {
    switch (action.type) {
        case VALIDATE_BANK_CARD_START:
            return {...state, loading: true, error: false};
        case VALIDATE_BANK_CARD_SUCCESS:
            return {...state, loading: false, error: false, cardData: action.data};
        case VALIDATE_BANK_CARD_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};

export default ValidateBankCardType;
