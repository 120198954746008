import styled from '@emotion/styled';
import {IPropsModal} from './interfaceType';

export const IconUser = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 16px;
`;

export const SelectContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;
export const SelectField = styled.div<{isOpen: boolean}>`
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 32px;

    &:hover {
        border-color: #007bff;
    }
`;
export const OptionsMenu = styled.div<{isOpen: boolean}>`
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    max-height: 150px;
    overflow-y: auto;
`;

export const Option = styled.div<{isSelected: boolean}>`
    display: flex;
    padding: 8px;
    cursor: pointer;

    flex-direction: row;

    background-color: ${({isSelected}) => (isSelected ? '#e9ecef' : '#fff')};

    &:hover {
        background-color: #f1f1f1;
        border-left: 1px solid #007bff;
    }
`;

export const DropDownContainer = styled('div')`
    width: max-content;
`;

export const DropDownListContainer = styled('div')`
    position: absolute;
    width: 0%;
`;

export const DropDownList = styled('div')`
    flex-direction: column;
    border-radius: 8px;
    background: #ffffff;
    width: 183px;
    height: max-content;
    position: relative;
    right: 100px;
`;

export const ListItem = styled('div')`
    display: flex;
    padding: 8px;
    position: relative;

    &:hover {
        background-color: #f0f0ff;
        border-left: 1.5px solid #5a5aff;
    }
`;

export const TableContainer = styled.div`
    display: table;
    width: 100%;
    padding: 24px;
`;

export const TableRow = styled.div`
    display: table-row;
`;

export const TableCell = styled.div<{align?: string}>`
    display: table-cell;
    padding: 8px;
    text-align: ${({align}) => align || 'left'};
    border-bottom: 1px solid #e8e9ea;
    width='3px'
`;

export const Modal = styled.div<IPropsModal>`
    display: flex;
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    background-color: ${(props) => props.BackC};
    z-index: ${(props) => props.ZIndex};
    align-items: ${(props) => props.Align};
    justify-content: ${(props) => props.Justify};
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    overflow-x: hidden;
    transform: ${(props) => (props.IsVisible ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.5s;
`;

Modal.defaultProps = {
    Height: '100vh',
    Width: '100%',
    ZIndex: '3',
    Align: 'center',
    Justify: 'center',
    Position: 'fixed',
    Top: '0',
    Left: '0',
    IsVisible: false,
};

export const Overlay = styled.div<{isVisible: boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};
    z-index: 2;
`;
