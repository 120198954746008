import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {StyleNumericFormat} from '@/components/Loan/styles';
import {Input} from '@components/Shopify/Ecommerce/styles';
import {TableRowHead} from '@components/Shopify/Endeavors/styles';
import {TableItem} from '@components/Shopify/Endeavors/styles';
import {TableRowItems} from '@components/CashFlow/FundingInquiry/styles';
import {IFormDataI} from '@components/CloseBranch/interface';
import {Controller} from 'react-hook-form';
import {QuitNumberFormat, limitNumberFormat} from '@/hooks/currentFormatUtils';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const CloseBranchForm = ({
    register,
    control,
    errors,
    setValue,
    setUserAmounts,
    userAmounts,
}: IFormDataI) => {
    const BranchData = useSelector((state: RootState) => {
        return state.getUserDataBranch;
    });

    const handleAmountChange = (userId: number, amounted: string) => {
        const amount = Number(amounted.replaceAll('$', '').replaceAll(',', ''));
        const updatedUserAmounts = userAmounts.map((user) => {
            if (user.userEmployeeId === userId) {
                return {...user, amount};
            }
            return user;
        });

        setUserAmounts(updatedUserAmounts);
    };
    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                GridColumns="1fr 1fr"
                Justify="center"
                Align="center"
                Width="44rem"
                Height="100px"
            >
                <ContainerFlex FlexDir="column" Justify="start" Align="start">
                    <Text required FontSize=".75rem">
                        Valor de referencía
                    </Text>

                    <Controller
                        name="diferenceValue"
                        control={control}
                        render={({field}) => (
                            <StyleNumericFormat
                                {...field}
                                height="38px"
                                width="10rem"
                                padding="0 0 0 13px"
                                color="#a7a9ac"
                                radius="4px"
                                margin="0 0 0 0"
                                colorplace="#cbced1"
                                hborder="1px solid #35cf44"
                                borderf=""
                                type="text"
                                prefix="$"
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                placeholder="Valor"
                                allowLeadingZeros={false}
                                isAllowed={(values) =>
                                    limitNumberFormat(
                                        QuitNumberFormat(values.value),
                                        0,
                                        BranchData.Branch.finalBalance
                                    )
                                }
                            />
                        )}
                    />
                    {errors.diferenceValue && (
                        <Text FontSize="0.75rem" Color="#ef4f55">
                            {errors.diferenceValue.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start">
                    <Text required FontSize=".75rem">
                        Observación
                    </Text>
                    <Input
                        Cursor="text"
                        Margin="0"
                        Height="40px"
                        FontSize="0.875rem"
                        HolderFontSize="0.75rem"
                        Radius="4px"
                        placeholder="Observaciones"
                        Width="29.875rem"
                        Padding="0 8px"
                        {...register('observations')}
                        type="text"
                        maxLength={160}
                        error={errors.observations?.message}
                    />
                    {errors.observations && (
                        <Text FontSize="0.75rem" Color="#ef4f55">
                            {errors.observations.message}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="31.063rem" Padding="34px 0" FlexDir="column">
                <TableRowHead GridColumn="1fr 1fr" Height="48px" backG="#e5e7e9" Radius="4px">
                    <TableItem Size=".875rem">
                        <Text FontSize=".875rem" FontWeight="500">
                            Nombre de empleado
                        </Text>
                    </TableItem>
                    <TableItem Size=".875rem">
                        <Text FontSize=".875rem" FontWeight="500">
                            Monto faltante
                        </Text>
                    </TableItem>
                </TableRowHead>
                {BranchData.Branch.branchCashiers.map(
                    (data: {employeeId: number; employeeName: string}) => (
                        <TableRowItems
                            GridColumn="1fr 1fr"
                            Cursor="auto"
                            Height="60px"
                            MinH="60px"
                            Width="100%"
                            BorderRadius="4px"
                            key={data.employeeId}
                        >
                            <TableItem Justify="start" Padding="0 0 0 34.5px">
                                {data.employeeName}
                            </TableItem>
                            <TableItem>
                                <StyleNumericFormat
                                    height="40px"
                                    width="10rem"
                                    padding="0 0 0 13px"
                                    color="#a7a9ac"
                                    radius="4px"
                                    margin="0 0 0 0"
                                    colorplace="#cbced1"
                                    hborder="1px solid #35cf44"
                                    borderf=""
                                    type="text"
                                    prefix="$"
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    allowNegative={false}
                                    placeholder="$"
                                    allowLeadingZeros={false}
                                    onChange={(e) => {
                                        handleAmountChange(data.employeeId, e.target.value);
                                    }}
                                />
                            </TableItem>
                        </TableRowItems>
                    )
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
