import {
    QUOTER_RESET,
    RESET_ARTCILE_DINAMICS,
    SEARCH_VALUATION_STEP,
    CHANGE_ACTUAL_STEP,
    ID_CONTRACT,
    RESET_ALL,
} from '@Quoter/Redux/Types/Types';
import {ISaveEndeavor, IReducerForm} from '@Quoter/inferfaces';

const initialState: ISaveEndeavor = {
    actualStep: 1,
    productSearch: {levelsIds: [], breadComesResult: '', articleName: '', image: ''},
    searchID: {},
    inputDinamics: {},
    stadistics: {},
    filters: false,
    error: false,
    loading: true,
    idContract: 0,
};

const saveEndeavor = (state = initialState, action: IReducerForm): ISaveEndeavor => {
    switch (action.type) {
        case RESET_ALL:
            return initialState;
        case SEARCH_VALUATION_STEP.SEARCH:
            return {...state, actualStep: 1, productSearch: action.productSearch};
        case SEARCH_VALUATION_STEP.ID:
            return {...state, actualStep: 1, searchID: action.searchID};
        case SEARCH_VALUATION_STEP.FILTERS:
            return {...state, actualStep: 1, filters: action.filters};
        case SEARCH_VALUATION_STEP.DINAMICS:
            return {...state, actualStep: 1, inputDinamics: action.inputDinamics};
        case SEARCH_VALUATION_STEP.STADISTICS:
            return {...state, actualStep: 1, stadistics: action.stadistics};
        case QUOTER_RESET:
            return initialState;
        case RESET_ARTCILE_DINAMICS:
            return {...state, actualStep: 1, inputDinamics: {}};
        case CHANGE_ACTUAL_STEP:
            return {...state, actualStep: action.step};
        case ID_CONTRACT:
            return {...state, idContract: action.idContract};
        default:
            return state;
    }
};
export default saveEndeavor;
