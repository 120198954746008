import {GET_OPEN_HOURS} from '@components/LocationsBranch/Redux/types';
import {IScheduleSlot} from '@components/ScheduleTable/interfaces/interfaces';

export const setOpeningHours = (openHours: IScheduleSlot[]) => ({
    type: GET_OPEN_HOURS.ADD,
    openHours,
});

export const resetOpeningHours = () => ({
    type: GET_OPEN_HOURS.RESET,
});
