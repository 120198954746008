import React from 'react';
import {useSelector} from 'react-redux';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {RootState} from '@/config/store';
import {IOtherInfo} from '@components/Branches/interfaces';

export const OtherInfo = ({showOtherForm, setShowOtherForm}: IOtherInfo) => {
    const infoBranch = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo;
    });
    return (
        <ContainerFlex Justify="flex-start" Height="auto" FlexDir="column">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Otra información
                </Text>
                <ContainerFlex
                    Width="auto"
                    Justify="start"
                    Height="80%"
                    onClick={() => {
                        setShowOtherForm(!showOtherForm);
                    }}
                >
                    <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                        Editar
                    </Text>
                    <ImgLocation
                        src={nextArrow}
                        Width="0.875rem"
                        Height="0.875rem"
                        Margin="0 0 0 4px"
                        Cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px 40px"
                Height="88px"
                Justify="space-between"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                Gap="8px"
            >
                <Text FontSize="0.875rem" Color="#414042" Width="45%">
                    Gerente asignado:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 8px"
                        FontStyle="italic"
                    >
                        {infoBranch?.otherInformation?.manager}
                    </Text>
                </Text>
                <Text FontSize="0.875rem" Color="#414042" Width="45%">
                    Correo electrónico:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 8px"
                        FontStyle="italic"
                    >
                        {infoBranch?.otherInformation?.email}
                    </Text>
                </Text>
                <Text FontSize="0.875rem" Color="#414042" Width="55%">
                    Representante legal:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 8px"
                        FontStyle="italic"
                    >
                        {infoBranch?.otherInformation?.legalRepresentative}
                    </Text>
                </Text>
                <Text FontSize="0.875rem" Color="#414042" Width="55%">
                    Fecha de contrato:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 8px"
                        FontStyle="italic"
                    >
                        {infoBranch?.otherInformation?.contractRegistrationDate}
                    </Text>
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="48px" Justify="flex-start" Padding="0 0 16px 40px">
                <Text FontSize="0.875rem" Color="#414042">
                    Registro público de adhesión:
                    <Text
                        FontSize="0.875rem"
                        FontWeight="500"
                        Padding="0 0 0 8px"
                        FontStyle="italic"
                    >
                        {infoBranch?.otherInformation?.publicRegistrationAdhesionNumber}
                    </Text>
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
