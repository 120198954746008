import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {addSpaceToCurrency, formatterDolar} from '@/hooks/currentFormatUtils';
import {TableRowHead} from '@CashFlow/FundingInquiry/styles';
import {TableItem} from '@DeadLines/styles';
import {FinanceRecord} from '@CashFlow/constantsAtaskateFlow';
import ButtonGeneral from '@components/General/Atoms/Button/index';
interface IDetail {
    data: FinanceRecord;
    setClose: (e: boolean) => void;
}
export const DetailsCards = ({data, setClose}: IDetail) => {
    return (
        <ContainerFlex Position="fixed" ZIndex="5" Width="100vw">
            <ContainerFlex
                Gap="32px"
                FlexDir="column"
                MinH="368px"
                MaxH="482px"
                Width="41.87em"
                Padding="24px"
                Radius="24px"
                backG="#FFFFFF"
                Border="1px solid #E8E9EA"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                Height=""
                Justify="start"
                Align="start"
            >
                <Text FontSize="1.5rem" FontWeight="700" TextAlign="start">
                    {data.typeEffective}
                </Text>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="">
                    <Text Color="#54575C" wSpace="" Padding="0 0 24px 0">
                        {data.details.text}
                    </Text>
                    {data.details.amounts.map((data, index) => {
                        return (
                            <TableRowHead
                                GridColumn="1fr 1fr"
                                BackG="white"
                                Width="100%"
                                BorderT="1px solid #E8E9EA"
                                Height="38px"
                                Radius=""
                                key={index}
                            >
                                <TableItem Justify="start" Padding="16px">
                                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                        {data.typeAmount}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="end" Padding="16px">
                                    <Text FontSize="0.875rem" Color="#2A2C2F">
                                        {addSpaceToCurrency(formatterDolar.format(data.amount))}
                                    </Text>
                                </TableItem>
                            </TableRowHead>
                        );
                    })}
                    <TableRowHead
                        GridColumn="1fr 1fr"
                        BackG="white"
                        Width="100%"
                        Margin="8px 0 0 0"
                        BorderT="1px solid #2A2C2F"
                        Height="38px"
                        Radius=""
                    >
                        <TableItem Justify="start" Padding="16px">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                {data.details.totalHeader}
                            </Text>
                        </TableItem>
                        <TableItem Justify="end" Padding="16px">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                {formatterDolar.format(data.details.total)}
                            </Text>
                        </TableItem>
                    </TableRowHead>
                </ContainerFlex>
                <ContainerFlex>
                    <ButtonGeneral text="Cerrar" width="22%" clic={() => setClose(false)} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
