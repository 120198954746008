import React from 'react';

export function HeadTablePurityLab() {
    return (
        <thead className="title-head-foundry">
            <tr>
                <th>Lingote</th>
                <th>Kilatajes</th>
                <th>Pureza Prestalana</th>
                <th>Pureza Fundidora</th>
                <th>Pureza Terceria</th>
                <th>Gramos proveedor</th>
                <th>Gramos oro fino</th>
                <th>Onzas</th>
                <th>Pureza Promedio</th>
                <th>Pureza seleccionada</th>
                <th>Precio lingote</th>
                <th>Subtotal</th>
                <th>Comisión</th>
                <th>Total</th>
                <th>Ganancia</th>
                <th>Archivo</th>
            </tr>
        </thead>
    );
}
