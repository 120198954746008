import {AuctionPassText, catalogItems} from '@components/AuctionPass/Constants';
import {ICatalogItems} from '@components/AuctionPass/interfaces';
import {ImageLogo} from '@components/LoginClient/styles';
import {ContainerFlex, ContainerGrid, Text} from '@components/Shopify/Ecommerce/styles';
import QuestionMark from '@images/QuestionIcon.svg';
import saveCheckImg from '@images/SaveChangesCheck.svg';
import closeModal from '@images/CloseModal.svg';
import React, {useState} from 'react';
import {CatalogTree} from './catalogTree';
import {Tooltip} from './Tooltip';
import {BreadCrumb} from './BreadCrumb';
const AuctionPass = () => {
    const [editableItemId, setEditableItemId] = useState<number | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const handleEditClick = (id: number) => {
        setEditableItemId((prevId) => (prevId === id ? null : id));
        if (isEditable) {
            setIsEditable(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        } else {
            setIsEditable(true);
        }
    };

    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            FlexDir="column"
            Gap="8px"
            backG="#FAFAFA"
            Padding="30px"
        >
            <BreadCrumb />
            <Text FontWeight="700" FontSize="2rem" MinH="3rem">
                {AuctionPassText.AuctionPassTitle}
            </Text>
            <Text MinH="2rem">{AuctionPassText.AuctionPassDescription}</Text>
            <ContainerFlex
                Justify="start"
                Align="start"
                backG="white"
                Padding="25px"
                Radius="24px"
                Border="1px solid #E8E9EA"
                Width="100%"
                FlexDir="column"
            >
                <Text FontWeight="700" FontSize="1.6rem" MinH="2rem">
                    {AuctionPassText.RulesTitle}
                </Text>
                <ContainerGrid
                    GridColumns="1.2fr 1fr 1fr 1.2fr minmax(100px, 0.3fr)"
                    Justify="start"
                    AlignContent="start"
                    Height="5rem"
                >
                    <ContainerFlex
                        Height="4rem"
                        FontWeight="700"
                        Width="80%"
                        Justify="start"
                        MarginL="30px"
                    >
                        {AuctionPassText.CatalogColumn}
                    </ContainerFlex>
                    <ContainerFlex Height="4rem" Justify="start">
                        <Text Width="60%" Height="2.5rem" FontWeight="800">
                            {AuctionPassText.CommercializationColumn}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Height="4rem" Justify="start" FontWeight="700">
                        {AuctionPassText.PercentageColumn}
                    </ContainerFlex>
                    <ContainerFlex
                        Height="4rem"
                        Justify="start"
                        FontWeight="700"
                        Gap="5px"
                        OverFlow="visible"
                    >
                        {AuctionPassText.DaysOfPauseColumn}
                        <ContainerFlex
                            Position="relative"
                            onMouseEnter={() => setIsVisible(true)}
                            onMouseLeave={() => setIsVisible(false)}
                            Height="25px"
                            Width="25px"
                        >
                            <ImageLogo
                                src={QuestionMark}
                                height="20px"
                                Width="20px"
                                Cursor="pointer"
                                alt="QuestionMark"
                            />
                            {isVisible && <Tooltip />}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerGrid>
                <ContainerGrid GridColumns="1.2fr 1fr 1fr 1.2fr .3fr">
                    {catalogItems.map((i: ICatalogItems) => (
                        <React.Fragment key={i.id}>
                            <CatalogTree
                                item={i}
                                editableItemId={editableItemId}
                                handleEditClick={handleEditClick}
                            />
                        </React.Fragment>
                    ))}
                </ContainerGrid>
                {showMessage && (
                    <ContainerFlex
                        Height="4rem"
                        Width="40rem"
                        backG="#E4F7EC"
                        Position="fixed"
                        PositionTop="80%"
                        PositionLeft="38%"
                        Radius="15px"
                        Bb="3px solid #309C60"
                        Justify="start"
                    >
                        <ContainerFlex Gap="10px" Width="90%" Justify="start" MarginL="1rem">
                            <ImageLogo src={saveCheckImg} Width="20px" Height="20px" />
                            <Text FontWeight="800">Cambio guardado</Text>
                        </ContainerFlex>
                        <ImageLogo
                            src={closeModal}
                            Height="15px"
                            Width="15px"
                            Cursor="pointer"
                            onClick={() => setShowMessage(false)}
                        />
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AuctionPass;
