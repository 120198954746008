import {
    POST_VALIDATE_CUSTOM_ROL_ERROR,
    POST_VALIDATE_CUSTOM_ROL_START,
    POST_VALIDATE_CUSTOM_ROL_SUCCESS,
    POST_VALIDATE_CUSTOM_ROL_FINISH,
} from '@TypesRoles/types';
import {IReducerType} from '@Roles/interface';

const initialState = {
    loading: false,
    error: false,
    validateRol: false,
};

const PostValidateRol = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case POST_VALIDATE_CUSTOM_ROL_START:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case POST_VALIDATE_CUSTOM_ROL_SUCCESS:
            return {
                ...state,
                validateRol: action.payload.data,
            };
        case POST_VALIDATE_CUSTOM_ROL_ERROR:
            return {
                ...state,
                error: true,
            };
        case POST_VALIDATE_CUSTOM_ROL_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PostValidateRol;
