import {Controller} from 'react-hook-form';
import {components, MultiValueGenericProps, MultiValueRemoveProps} from 'react-select';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {CheckboxSelect} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/CheckBoxSelect';
import {
    IOptionProps,
    IRequestChannelFieldProps,
    IRequestChannelSelect,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import closeMultiValueIcon from '@components/PersonalLoans/NewLoanProduct/icons/closeMultiValueIcon.svg';

const Option: React.FC<IOptionProps> = ({props, selectAll}) => {
    const {Option} = components;
    const data = props.data as IRequestChannelSelect;
    return (
        <Option {...props}>
            <Text Color="#2A2C2F" Height="max-content" Gap="0.5rem">
                {data.value !== 3 && <Text MarginLeft="1rem" />}
                <CheckboxSelect checked={data.value !== 3 ? props.isSelected : selectAll} />
                {data.label}
            </Text>
        </Option>
    );
};
const MultiValueContainer = (props: MultiValueGenericProps) => {
    const {children} = props;
    return (
        <components.MultiValueContainer {...props}>
            <ContainerFlex
                Height="32px"
                Padding="0.25rem 0.5rem"
                Gap="0.25rem"
                Radius="8px"
                backG="#F4F5F5"
            >
                {children}
            </ContainerFlex>
        </components.MultiValueContainer>
    );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove {...props}>
            <Image Width="20px" Height="20px" src={closeMultiValueIcon} Cursor="pointer" />
        </components.MultiValueRemove>
    );
};

const MultiValueLabel = (props: MultiValueGenericProps) => {
    const {children} = props;
    return (
        <components.MultiValueLabel {...props}>
            <Text Color="#2A2C2F" FontWeight="700" FontSize="16px" FontFamily="Nunito">
                {children}
            </Text>
        </components.MultiValueLabel>
    );
};

export const RequestChannelField: React.FC<IRequestChannelFieldProps> = ({
    control,
    errors,
    watch,
    setValue,
    resetField,
}) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const requestChannelOptions = [
        {value: 3, label: 'Todos los canales'},
        {value: 1, label: 'Online'},
        {value: 2, label: 'Sucursal'},
    ];
    const formState = watch();
    const handleRequestChannelChange = (selected: IRequestChannelSelect[]) => {
        const allOptions = requestChannelOptions.filter((option) => option.value !== 3);
        if (selected.length > 0 && selected[selected.length - 1].value === 3) {
            if (selectAll) {
                return resetField('requestChannel');
            } else {
                setSelectAll(true);
                return setValue('requestChannel', allOptions);
            }
        } else if (selected.length === allOptions.length) {
            setSelectAll(true);
        }
    };
    useEffect(() => {
        if (!formState.requestChannel) return;
        if (formState.requestChannel.length === 0) {
            setSelectAll(false);
        }
    }, [formState.requestChannel]);
    return (
        <ContainerFlex
            Gap="0.25rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Width="362.667px;"
        >
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.APPLICATION_CHANNEL}
            </Text>
            <Controller
                name="requestChannel"
                control={control}
                render={({field: {value, onChange, ...field}}) => (
                    <Select
                        {...field}
                        noOptionsMessage={() => 'Sin opciones'}
                        options={requestChannelOptions}
                        placeholder="Selecciona"
                        padding="0px"
                        radius="2rem"
                        components={{
                            Option: (props) => <Option props={props} selectAll={selectAll} />,
                            MultiValueContainer,
                            MultiValueRemove,
                            MultiValueLabel,
                        }}
                        value={value}
                        isMulti
                        hideSelectedOptions={false}
                        isSearchable={false}
                        closeMenuOnSelect={false}
                        onChange={(e) => {
                            onChange(e);
                            const selected = e as IRequestChannelSelect[];
                            handleRequestChannelChange(selected);
                        }}
                        isClearable={false}
                        MultiRadius="0.5rem"
                        MultiIconHover="transparent"
                    />
                )}
            />
            {errors.requestChannel && (
                <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem">
                    <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                    {errors.requestChannel.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
