import axios from 'axios';
import Swal from 'sweetalert2';
import {Dispatch} from 'redux';

import {URL} from '@config/constants/index';
import {ARTICLE} from '@/components/Articles/Redux/Types';
import {HandleLevelsRoute} from '@/components/Articles/Redux/Actions/RouteLevel';
import {FieldValues} from 'react-hook-form';

export const LevelError = (value: unknown) => {
    return {
        type: ARTICLE.ERROR,
        payload: value,
    };
};

export const GroupSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE.GROUP,
        payload: value,
    };
};
export const GroupLevel = (token: string, BrachId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Group.replace(
                    '{BrachId}',
                    BrachId
                )}`,
                {headers}
            );
            dispatch(GroupSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const FamilySuccess = (value: FieldValues) => {
    return {
        type: ARTICLE.FAMILY,
        payload: value,
    };
};
export const FamilyLevel = (token: string, BrachId: string, GroupId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Family.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{GroupId}', GroupId)}`,
                {headers}
            );
            dispatch(FamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const SubfamilySuccess = (value: FieldValues) => {
    return {
        type: ARTICLE.SUBFAMILY,
        payload: value,
    };
};
export const SubfamilyLevel = (token: string, BrachId: string, FamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SubFamily.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{FamilyId}', FamilyId)}`,
                {headers}
            );
            dispatch(SubfamilySuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const BrandSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE.BRAND,
        payload: value,
    };
};
export const BrandLevel = (token: string, BrachId: string, SubFamilyId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Brand.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{SubFamilyId}', SubFamilyId)}`,
                {headers}
            );
            dispatch(BrandSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const ArticleSuccess = (value: FieldValues) => {
    return {
        type: ARTICLE.ARTICLES,
        payload: value,
    };
};
export const ArticleLevel = (token: string, BrachId: string, BrandId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.Items.replace(
                    '{BrachId}',
                    BrachId
                ).replace('{BrandId}', BrandId)}`,
                {headers}
            );
            dispatch(ArticleSuccess(response.data));
        } catch (error) {
            dispatch(LevelError(error));
        }
    };
};

export const LevelStatus = () => {
    return {
        type: ARTICLE.LEVEL_STATUS,
    };
};
export const LevelStatusSuccess = (value: boolean) => {
    return {
        type: ARTICLE.LEVEL_STATUS_SUCCESS,
        payload: value,
    };
};

export const LevelState = (
    token: string,
    data: FieldValues,
    level: number,
    branch: number,
    json: {
        status: boolean;
        categoryName: string;
        idUpperLevel: number;
    }
) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            dispatch(LevelStatus());
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.LevelStatusUpdating}`,
                data,
                {headers}
            );
            dispatch(LevelStatusSuccess(response.data.data));
            Swal.fire({
                icon: 'success',
                text: `¡Se ${!json.status ? 'inactivo' : 'activo'} con éxito ${
                    json.categoryName[0] + json.categoryName.toLowerCase().substring(1)
                }.`,
                confirmButtonText: 'Continuar',
            });
            HandleLevelsRoute(dispatch, token, level, json.idUpperLevel, String(branch));
        } catch (err) {
            dispatch(LevelError(err));
        }
    };
};

export const LevelAdd = (
    token: string,
    data: FieldValues,
    level: number,
    idHigher: number,
    branch: number
) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddLevel}`, data, {
                headers,
            });
            await Swal.fire({
                icon: 'success',
                text: `Se agregó  con éxito`,
                confirmButtonText: 'Continuar',
            });
            HandleLevelsRoute(dispatch, token, level, idHigher, String(branch));
        } catch (err) {
            dispatch(LevelError(err));
        }
    };
};
