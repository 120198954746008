import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {ICatBrandSearch} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetSearchBar';
import Add from '@images/addIcon.svg';
import {getBreadCrumbs} from '@/components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ClickAwayListener} from '@mui/material';
import {REQUEST_CATALOG} from '@components/InventoryManagement/CatalogManagement/Operational/Constant';
export interface ISearchCat {
    searchTerm: string;
    setShow: (e: boolean) => void;
}
export const SearchArticles = ({searchTerm, setShow}: ISearchCat) => {
    const dispatch = useDispatch();
    const SearchArticles: ICatBrandSearch = useSelector((state: RootState) => state.getSearchBar);
    const HandleSelect = (data: {
        groupId: number;
        familyId: number;
        subFamilyId: number;
        brandId: number;
        itemId: number;
    }) => {
        dispatch(getBreadCrumbs(data));
    };
    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <ContainerFlex
                MaxH="224px"
                MinH={SearchArticles.catSearch.length === 0 ? '40px' : '224px'}
                Justify="start"
                Align="start"
                backG="#FFFFFF"
                Position="relative"
                Border="1px solid #F4F5F5"
                Radius="8px"
                PositionTop="8px"
                BoxS="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
                FlexDir="column"
                OverFlow="auto"
                ZIndex="5"
            >
                {SearchArticles.loading && (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                )}
                {SearchArticles.catSearch &&
                    SearchArticles.catSearch.map((data, index) => {
                        return (
                            <ContainerFlex
                                Justify="start"
                                HBorderLeft="2px solid #5A5AFF"
                                HBackground="#F0F0FF"
                                HColor="#5A5AFF"
                                Height="40px"
                                Padding="8px"
                                Cursor="pointer"
                                onClick={() => {
                                    HandleSelect(data.levelsIds);
                                    setShow(false);
                                }}
                                key={index}
                            >
                                <Text Cursor="pointer">
                                    {data.breadComesName.replaceAll('->', '')}
                                </Text>
                            </ContainerFlex>
                        );
                    })}
                <ContainerFlex
                    Justify="start"
                    HBorderLeft="1px solid #5A5AFF"
                    HBackground="#F0F0FF"
                    Padding="8px"
                    Height="40px"
                >
                    <Image src={Add} Height="16px" />
                    <Text Color="#5A5AFF" Cursor="pointer">
                        {REQUEST_CATALOG}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};
