import {
    GET_PAYMENTMETHOD_START,
    GET_PAYMENTMETHOD_SUCCESS,
    GET_PAYMENTMETHOD_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {
    PaymentMethodAction,
    ICompanyPaymentMethod,
} from '@components/AccountSettings/Redux/interfaces';

const initialState: ICompanyPaymentMethod = {
    loading: false,
    error: null,
    paymentMethods: [],
};

const GetCompanyPaymentMethod = (
    state = initialState,
    action: PaymentMethodAction
): ICompanyPaymentMethod => {
    switch (action.type) {
        case GET_PAYMENTMETHOD_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PAYMENTMETHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.paymentMethods,
            };
        case GET_PAYMENTMETHOD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default GetCompanyPaymentMethod;
