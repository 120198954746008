import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {BUSINESS} from '@components/SignUp/constants';
import {Checkbox} from '@General/Atoms/Checkbox/Checkbox';
import {catPlaces, ICatTypeSales} from '@components/SignUp/interfaces';
import {ContainerCellGrid, ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {saveTypeSaleData} from '@components/SignUp/Redux/Actions/saveDataFormAction';

export const TypeSale = () => {
    const createAccount = useSelector((state: RootState) => state.saveDataSingUp);
    const {catPlaces} = useSelector((state: RootState) => state.catPlaceAndBusiness);
    const dispatch: AppDispatch = useDispatch();
    const [typeSale, setSale] = useState<number[]>([]);

    const typeSales = (id: number) => {
        if (!typeSale.includes(id)) setSale([...typeSale, id]);
        if (typeSale.includes(id)) setSale(typeSale.filter((item: number) => item !== id));
    };

    const onSubmit = () => {
        const place = catPlaces
            .map((item: catPlaces) => {
                return typeSale.includes(item.id)
                    ? {
                          planName:
                              item.point === BUSINESS.ONLINE
                                  ? BUSINESS.MOD_ONLINE
                                  : BUSINESS.MOD_POS,
                          price: item.price ? item.price : 0,
                      }
                    : {planName: ''};
            })
            .filter((item: catPlaces) => item.planName !== '');

        dispatch(
            saveTypeSaleData({
                TypePlaceId: typeSale,
                PlacesItem: place,
            })
        );
    };

    useEffect(() => {
        if (createAccount.places) setSale(createAccount.places.TypePlaceId);
    }, []);

    return (
        <ContainerCellGrid Gap="1.5rem" Width="27.25rem" FlexDir="column">
            <ContainerFlex FlexDir="column">
                <Text FontSize="1.5rem" FontWeight="500" MarginB="0.5rem">
                    {BUSINESS.TITLE}
                </Text>
                <Text wSpace="normal" TextAlign="center">
                    {BUSINESS.EXPERIENCE}
                </Text>
            </ContainerFlex>
            <ContainerCellGrid Gap="1rem" FlexDir="Column">
                {catPlaces.map((item: ICatTypeSales, index: number) => (
                    <ContainerFlex
                        key={index}
                        Justify="start"
                        Cursor="pointer"
                        onClick={() => typeSales(item.id)}
                        Border={
                            typeSale.includes(item.id) ? '1px solid #5A5AFF' : '1px solid #F4F5F5'
                        }
                        Radius="1rem"
                        Padding="1rem 0.5rem"
                    >
                        <Checkbox checked={typeSale.includes(item.id)} />
                        <Text Cursor="pointer" Margin="0 0.5rem">
                            {item.point}
                        </Text>
                    </ContainerFlex>
                ))}
            </ContainerCellGrid>
            <ContainerFlex FontSize="0.75rem">{BUSINESS.SUGESTION}</ContainerFlex>
            <ButtonGeneral
                text={BUSINESS.BTNCONTINUE}
                dColor="#A1A5AA"
                disabled={typeSale.length === 0}
                clic={() => onSubmit()}
            />
        </ContainerCellGrid>
    );
};
