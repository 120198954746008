import {ARTICLE_MANAGES} from '@/components/Articles/Redux/ManageTypes';
import {ISearchAllCatalog} from '@/components/Articles/Redux/interfaces';
import {FieldValues} from 'react-hook-form';

const INITIAL_STATE: ISearchAllCatalog = {
    Group: [],
    Family: [],
    Subfamily: [],
    Brand: [],
    Article: [],
    GroupSelect: [],
    PageData: {totalPages: 0, totalItems: 0},
    Error: null,
    errors: false,
    LevelCatalog: {level: 0, breadcrumb: []},
    statelevel: false,
    loading: false,
};

const ArticleManagementSearch = (
    state: ISearchAllCatalog = INITIAL_STATE,
    action: {type: string; payload: FieldValues}
) => {
    switch (action.type) {
        case ARTICLE_MANAGES.GROUP:
            return {
                ...state,
                loading: true,
            };
        case ARTICLE_MANAGES.GROUP_SUCCESS:
            return {
                ...state,
                Group: action.payload,
                errors: false,
            };
        case ARTICLE_MANAGES.GROUP_SUCCESS_DATA:
            return {
                ...state,
                PageData: action.payload,
            };
        case ARTICLE_MANAGES.GROUP_ERROR:
            return {
                ...state,
                errors: true,
            };
        case ARTICLE_MANAGES.GROUP_SELECT:
            return {
                ...state,
                loading: true,
            };
        case ARTICLE_MANAGES.GROUP_SELECT_SUCCESS:
            return {
                ...state,
                GroupSelect: action.payload.data,
            };
        case ARTICLE_MANAGES.GROUP_SELECT_ERROR:
            return {
                ...state,
                error: true,
            };
        case ARTICLE_MANAGES.FAMILY:
            return {
                ...state,
                Family: action.payload,
            };
        case ARTICLE_MANAGES.RESET_FAMILY:
            return {
                ...state,
                Family: [],
            };
        case ARTICLE_MANAGES.SUBFAMILY:
            return {
                ...state,
                Subfamily: action.payload,
            };
        case ARTICLE_MANAGES.RESET_SUBFAMILY:
            return {
                ...state,
                Subfamily: [],
            };
        case ARTICLE_MANAGES.BRAND:
            return {
                ...state,
                Brand: action.payload,
            };
        case ARTICLE_MANAGES.RESET_BRAND:
            return {
                ...state,
                Brand: [],
            };
        case ARTICLE_MANAGES.ARTICLES:
            return {
                ...state,
                Article: action.payload,
            };
        case ARTICLE_MANAGES.RESET_ARTICLES:
            return {
                ...state,
                Article: [],
            };
        case ARTICLE_MANAGES.LEVEL_STATUS:
            return {
                ...state,
                loading: true,
            };
        case ARTICLE_MANAGES.LEVEL_STATUS_SUCCESS:
            return {
                ...state,
                statelevel: action.payload,
                loading: false,
            };
        case ARTICLE_MANAGES.DELETE_LEVEL_ROUTE:
            return {
                ...state,
                Group: [],
                Family: [],
                Subfamily: [],
                Brand: [],
                Article: [],
            };
        case ARTICLE_MANAGES.ERROR:
            return {
                ...state,
                Error: action.payload,
            };
        case ARTICLE_MANAGES.LEVELCATALOG:
            return {
                ...state,
                LevelCatalog: action.payload,
            };
        case ARTICLE_MANAGES.RESET_ALL:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default ArticleManagementSearch;
