import axios, {AxiosResponse} from 'axios';
import {
    PUT_OTHER_INFO,
    PUT_OTHER_INFO_ERROR,
    PUT_OTHER_INFO_FINISH,
    PUT_OTHER_INFO_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';

import {URL} from '@config/constants/index';
import {IShowItem, IUpdateOtherInfo} from '@/components/Branches/interfaces';
import {AppDispatch} from '@/config/store';
import {GetInformationBranch} from '@/components/Branches/ManageBranch/Redux/Actions/GetInformationBranch';

export const fetchPutOtherInfoStart = () => {
    return {
        type: PUT_OTHER_INFO,
    };
};
export const fetchPutOtherInfoSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_OTHER_INFO_SUCCESS,
        payload: result,
    };
};
export const fetchPutOtherInfoError = () => {
    return {
        type: PUT_OTHER_INFO_ERROR,
    };
};
export const fetchPutOtherInfoFinish = () => {
    return {
        type: PUT_OTHER_INFO_FINISH,
    };
};

export function PutOtherInfo(
    data: IUpdateOtherInfo,
    token: string,
    setShowOtherForm: (e: boolean) => void,
    setShowItems: (e: IShowItem) => void,
    showItems: IShowItem,
    idBranch: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutOtherInfoStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutOtherInfo}`,
                data,
                {headers}
            );
            dispatch(fetchPutOtherInfoSuccess(response));
            setShowOtherForm(false);
            setShowItems({...showItems, info: false});
        } catch (error) {
            dispatch(fetchPutOtherInfoError());
        } finally {
            dispatch(fetchPutOtherInfoFinish());
            dispatch(GetInformationBranch(idBranch, token));
        }
    };
}
