import styled from '@emotion/styled';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export const StyledTreeNode = styled.div`
    .react-checkbox-tree > ol > li > span {
        font-weight: 500;
    }

    .rct-node-icon {
        display: none;
    }
    .rct-text {
        padding-top: 0;
    }

    div {
        display: flex;
        justify-content: space-evenly;
    }

    div ol {
        padding-left: 8px;
    }

    div ol li span {
        gap: 3px;
    }
    .rct-collapse,
    .rct-checkbox,
    .rct-node-icon {
        padding: 0;
    }

    div ol li span button {
        padding: 0;
        display: ${(props) => (props.isBranch ? 'none' : 'block')};
    }
    div ol li span button i {
        font-size: 14px;
    }

    div ol li span label {
        height: 40px;
        padding: 0;
        gap: 5px;
        &:hover {
            background: none;
        }
    }
    div ol li span label span {
        padding: 0;
        font-size: 0.875rem;
        font-weight: ${(props) => props.Weight};
        &.rct-node-icon {
            display: none;
        }
    }

    div ol li span label span {
        &.rct-checkbox {
            width: 16px;
        }
    }

    div ol li span label {
        display: flex;
        align-items: center;
        width: auto;
    }

    div ol li span label span {
        &.rct-checkbox {
            display: flex;
        }
    }

    div ol li span label span span {
        &.rct-icon-uncheck {
            &::before {
                display: none;
            }
            border: 1px solid #6d6e71;
            background-color: #fff;
            height: 16px;
            width: 16px;
            border-radius: 4px;

            &:hover {
                border-color: #35cf44;
            }
        }
        &.rct-collapse {
            padding: 0;
        }
        &.rct-icon-check {
            font-size: 17px;
            color: #35cf44;
        }
    }

    div ol li span label span span {
        &.rct-icon-half-check {
            &::before {
                display: none;
            }
            border: 1px solid #6d6e71;
            background-color: #fff;
            height: 16px;
            width: 16px;
            border-radius: 4px;

            &:hover {
                border-color: #35cf44;
            }
        }
        &.rct-icon-check {
            font-size: 17px;
            color: #35cf44;
        }
    }

    div ol li span label span i {
        &.fa-check-square {
            font-size: 18px;
            color: #35cf44;
        }
    }
`;
