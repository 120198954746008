import React from 'react';
import {HeaderItems} from '@components/Branches/TableBranches/HeaderItems';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {Table, TableRowHead} from '@/components/CashFlow/FundingInquiry/styles';
import {ITableHeader} from '@components/Branches/interfaces';
import {TableHeaderBranchRow} from '@components/Branches/constants';

export const TableBranchHeader = ({filters, setFilters}: ITableHeader) => {
    return (
        <ContainerFlex FlexDir="column">
            <Table>
                <TableRowHead
                    GridColumn="1.5fr 2.5fr 1fr"
                    Height="48px"
                    BackG="#e5e7e9"
                    Radius="4px"
                    Margin="0 0 6px 0"
                >
                    {TableHeaderBranchRow.map(
                        (data: {name: string; id: number; isOrder: boolean}) => (
                            <HeaderItems
                                key={data.id}
                                id={data.id}
                                name={data.name}
                                isOrder={data.isOrder}
                                filters={filters}
                                setFilters={setFilters}
                            />
                        )
                    )}
                </TableRowHead>
            </Table>
        </ContainerFlex>
    );
};
