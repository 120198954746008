import {AppDispatch} from '@/config/store';
import {
    GET_AUDITOR_BY_ASSIGNMENT,
    GET_AUDITOR_BY_ASSIGNMENT_SUCCESS,
    GET_AUDITOR_BY_ASSIGNMENT_ERROR,
    GET_REGION_BY_ASSIGNMENT,
    GET_REGION_BY_ASSIGNMENT_SUCCESS,
    GET_REGION_BY_ASSIGNMENT_ERROR,
    GET_STATUS_BY_ASSIGNMENT,
    GET_STATUS_BY_ASSIGNMENT_SUCCESS,
    GET_STATUS_BY_ASSIGNMENT_ERROR,
    GET_DATERANGE_BY_ASSIGNMENT,
    GET_DATERANGE_BY_ASSIGNMENT_SUCCESS,
    GET_DATERANGE_BY_ASSIGNMENT_ERROR,
    GET_SUCURSAL_BY_ASSIGNMENT,
    GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS,
    GET_SUCURSAL_BY_ASSIGNMENT_ERROR,
} from '@TypesConsultAssignment/ConsultAssignmentFilterTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
import {DateTime} from 'luxon';

export const fetchGetAuditorByAssignment = () => {
    return {
        type: GET_AUDITOR_BY_ASSIGNMENT,
    };
};
export const fetchGetAuditorByAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_AUDITOR_BY_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetAuditorByAssignmentError = () => {
    return {
        type: GET_AUDITOR_BY_ASSIGNMENT_ERROR,
    };
};
export function getAuditorByAssignment(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return (dispatch: AppDispatch) => {
        dispatch(fetchGetAuditorByAssignment());
        axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AuditorByAssignment}`, {
                headers,
            })
            .then((response) => {
                dispatch(fetchGetAuditorByAssignmentSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchGetAuditorByAssignmentError());
            });
    };
}

export const fetchGetRegionByAssignment = () => {
    return {
        type: GET_REGION_BY_ASSIGNMENT,
    };
};
export const fetchGetRegionByAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_REGION_BY_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetRegionByAssignmentError = () => {
    return {
        type: GET_REGION_BY_ASSIGNMENT_ERROR,
    };
};
export function getRegionByAssignment(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetRegionByAssignment());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.RegionByAssignment}`,
                {headers}
            );

            dispatch(fetchGetRegionByAssignmentSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetRegionByAssignmentError());
        }
    };
}

export const fetchGetStatusByAssignment = () => {
    return {
        type: GET_STATUS_BY_ASSIGNMENT,
    };
};
export const fetchGetStatusByAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_STATUS_BY_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetStatusByAssignmentError = () => {
    return {
        type: GET_STATUS_BY_ASSIGNMENT_ERROR,
    };
};
export function getStatusByAssignment(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetStatusByAssignment());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.StatusByAssignment}`,
                {headers}
            );

            dispatch(fetchGetStatusByAssignmentSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetStatusByAssignmentError());
        }
    };
}

export const fetchGetDateRangeByAssignment = () => {
    return {
        type: GET_DATERANGE_BY_ASSIGNMENT,
    };
};
export const fetchGetDateRangeByAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_DATERANGE_BY_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetDateRangeByAssignmentError = () => {
    return {
        type: GET_DATERANGE_BY_ASSIGNMENT_ERROR,
    };
};
export function getDateRangeByAssignment(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetDateRangeByAssignment());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.DateRangeByAssignment}`,
                {headers}
            );
            //Alejandro  20-04-2021
            //Formatea las fechas que vienen del servidor
            response.data.dateMin = DateTime.fromJSDate(new Date(response.data.dateMin)).toFormat(
                'yyyy-MM-dd'
            );
            response.data.dateMax = DateTime.fromJSDate(new Date(response.data.dateMax)).toFormat(
                'yyyy-MM-dd'
            );
            dispatch(fetchGetDateRangeByAssignmentSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetDateRangeByAssignmentError());
        }
    };
}

export const fetchGetSucursalByAssignment = () => {
    return {
        type: GET_SUCURSAL_BY_ASSIGNMENT,
    };
};
export const fetchGetSucursalByAssignmentSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS,
        value: result,
    };
};

export const fetchGetSucursalByAssignmentError = () => {
    return {
        type: GET_SUCURSAL_BY_ASSIGNMENT_ERROR,
    };
};
export function getSucursalByAssignment(id = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSucursalByAssignment());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.SucursalByAssignment}?id=${id}`,
                {headers}
            );

            dispatch(fetchGetSucursalByAssignmentSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSucursalByAssignmentError());
        }
    };
}
