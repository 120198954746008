import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {LAST_LEVEL_BY_COMPANY} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';

export const getLastLevelStart = () => ({type: LAST_LEVEL_BY_COMPANY.ID_LEVEL_START});
export const getLastLevelSuccess = (result: AxiosResponse) => ({
    type: LAST_LEVEL_BY_COMPANY.ID_LEVEL_SUCCESS,
    level: result.data,
});
export const getLastLevelError = (error: AxiosError) => ({
    type: LAST_LEVEL_BY_COMPANY.ID_LEVEL_ERROR,
    error: error,
});
export const getLevelBreadcrumb = (
    token: string,
    params: {companyId: number; typeCreation: number}
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...params});
    return async (dispatch: AppDispatch) => {
        dispatch(getLastLevelStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLastByCompanyId}${queryString}`,
                {headers}
            );
            dispatch(getLastLevelSuccess(response.data));
        } catch (error) {
            dispatch(getLastLevelError(error as AxiosError));
        }
    };
};

export const resetLastLevel = () => ({type: LAST_LEVEL_BY_COMPANY.ID_LEVEL_RESET});
