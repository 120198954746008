import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {ROLES, createFormRol} from '@Roles/constants';
import * as styles from '@Roles/CreateRoles/stylesCreate';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IAssigment, IPermissions} from '@Roles/AddRoles/interfaces';
import {AssigmentSearchList} from '@Roles/CreateRoles/AssigmentSearchList';
import {AssigmentRolesSearch} from '@Roles/CreateRoles/AssigmentRolesSearch';

export const AssigmentRoles = ({setValue, reset}: IPermissions) => {
    const [user, setUser] = useState<IAssigment>({
        add: false,
        search: '',
        checked: [],
        clickSearch: '',
        actionSearch: 'search',
    });
    const persistenceRol = useSelector((state: RootState) => state.PersistenceRole);

    const handleCheckboxChange = (itemId: number) => {
        if (!user.checked.includes(itemId)) setUser({...user, checked: [...user.checked, itemId]});
        if (user.checked.includes(itemId))
            setUser({...user, checked: user.checked.filter((id) => id !== itemId)});
    };

    const handlePersistence = () => {
        if (persistenceRol.userId && persistenceRol.userId.length > 0)
            setUser({...user, add: true, checked: persistenceRol.userId});
        reset({
            ...createFormRol,
            checkAdmin: persistenceRol.roles.admin,
            checkPOS: persistenceRol.roles.pos,
            roleName: persistenceRol.typeRol.roleName,
            roleType: persistenceRol.typeRol.roleType,
            modules: persistenceRol.modules,
            userId: persistenceRol.userId,
        });
    };
    useEffect(() => {
        handlePersistence();
    }, [persistenceRol.modules, persistenceRol.modules.length]);

    return (
        <ContainerFlex {...styles.formContent}>
            <Text {...styles.title.subtitles}>{ROLES.USER_ROL.ASSIGMENT}</Text>

            <>
                <AssigmentRolesSearch user={user} setUser={setUser} setValue={setValue} />
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="16px">
                    <Text FontWeight="500" Color="#2A2C2F">
                        {ROLES.USER_ROL.SUGESTION}
                    </Text>
                    <Text Color="#2A2C2F">{ROLES.USER_ROL.SUGGESTION_ROLES}</Text>
                    <AssigmentSearchList user={user} handleCheckboxChange={handleCheckboxChange} />
                </ContainerFlex>
            </>
        </ContainerFlex>
    );
};
