import React from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {ICardOwnerFieldProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import errorIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/errorIcon.svg';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';

export const CardOwnerField: React.FC<ICardOwnerFieldProps> = ({errors, register}) => {
    return (
        <>
            <ContainerFlex FlexDir="column" Gap="0.5rem" Align="start" Justify="start">
                <Text Color="#1D1E20" FontWeight="700" Width="max-content">
                    {PAYMENT_METHOD.FIELDS.CARD_OWNER}
                </Text>
                <Input Width="100%" {...register('cardOwner')} />
                {errors.cardOwner && (
                    <Text FontSize="0.875rem" Color="#FF6357" Gap="0.25rem">
                        <Image src={errorIcon} Width="16px" Height="16px" />
                        {errors.cardOwner?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
