import React, {useEffect} from 'react';
import {ContainerFlex, ContainerIcon, Figure, Image, Text} from '@Shopify/Ecommerce/styles';
import {Close} from '@components/CreateUsers/Icons/Close';
import IconSuceess from '@components/CreateUsers/Icons/success.svg';
import {useDispatch} from 'react-redux';
import {removeMessagePasswordChange} from './Redux/Actions/PostChangePassword';

export const MessageSuccessPassword = ({
    userPasswordChange,
}: {
    userPasswordChange: string | undefined;
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(removeMessagePasswordChange());
        }, 3000);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <ContainerFlex
            Width="550px"
            Height="48px"
            Justify="space-evenly"
            backG="#fff"
            Radius="4px"
            Border="solid 1px #35cf44"
            BoxS="4px 4px 4px 0 rgba(122, 193, 67, 0.3)"
            Position="absolute"
            Padding="8px"
            AlignContent="space-around"
            PositionLeft="32%"
            PositionTop="60px"
        >
            <Figure>
                <Image src={IconSuceess} />
            </Figure>
            <Text
                Justify="flex-start"
                wSpace="pre"
                FontSize="0.875rem"
                Color="#414042"
                Margin="0 12px"
            >
                La contraseña{' '}
                <Text wSpace="pre" FontSize="0.875rem" FontWeight="500" Color="#414042">
                    {userPasswordChange}{' '}
                </Text>
                fue actualizada con éxito.
            </Text>
            <ContainerIcon
                onClick={() => {
                    dispatch(removeMessagePasswordChange());
                }}
            >
                <Close />
            </ContainerIcon>
        </ContainerFlex>
    );
};
