import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import blueSmallArrowRight from '@components/CreditsAndPawns/icons/blueSmallArrowRight.svg';
import moneyIcon from '@components/PersonalLoans/CreditStatus/icons/moneyIcon.svg';
import {CREDIT_VALIDATION} from '@components/PersonalLoans/CreditStatus/constants';

export const QuoteCallToAction = () => {
    return (
        <>
            <ContainerFlex
                Gap="1.5rem"
                Radius="1.5rem"
                Padding="1rem"
                backG="#FFE5FB"
                Border="1px solid #FF6AE8"
                FlexDir="Column"
                Height="max-content"
            >
                <ContainerFlex Gap="1.5rem" Align="start">
                    <ContainerFlex
                        FlexDir="column"
                        Align="start"
                        Justify="start"
                        Gap="0.5rem"
                        Width="260px"
                    >
                        <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20" wSpace="wrap">
                            {CREDIT_VALIDATION.CALL_TO_ACTION.TITLE}
                        </Text>
                        <Text Color="#2A2C2F" wSpace="wrap">
                            {CREDIT_VALIDATION.CALL_TO_ACTION.DESCRIPTION}
                        </Text>
                    </ContainerFlex>
                    <Image src={moneyIcon} Width="101.326px" Height="86.651px" />
                </ContainerFlex>
                <ButtonGenerals
                    SecondaryButton="#fff"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Width="100%"
                    Gap="0.5rem"
                >
                    {CREDIT_VALIDATION.CALL_TO_ACTION.BTN_DESCRIPTION}
                    <Image src={blueSmallArrowRight} Width="24px" Height="24px" />
                </ButtonGenerals>
            </ContainerFlex>
        </>
    );
};
