import {IBagWeigh} from '@/components/Foundry/interfaces';
import {AppDispatch} from '@/config/store';
import {
    GET_SECURITYBAG_WEIGHT_JIRACAS,
    GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS,
    GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR,
    GET_SECURITYBAG_WEIGHT_ESCORIA,
    GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
    GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    POST_SECURITYBAG_WEIGHT_ESCORIA,
    POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
    POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    GET_SECURITYBAG_WEIGHT_GOLD,
    GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
    GET_SECURITYBAG_WEIGHT_GOLD_ERROR,
    POST_SECURITYBAG_WEIGHT_GOLD,
    POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
    POST_SECURITYBAG_WEIGHT_GOLD_ERROR,
} from '@TypesSecurityBagWeight/SecurityBagWeightJiracas';
import {URL} from '@config/constants/index';

import axios from 'axios';
import Swal from 'sweetalert2';

export const fetchGetSecurityBagWeightJiracas = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_JIRACAS,
    };
};
export const fetchGetSecurityBagWeightJiracasSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SECURITYBAG_WEIGHT_JIRACAS_SUCCESS,
        value: result,
    };
};

export const fetchGetSecurityBagWeightJiracasError = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_JIRACAS_ERROR,
    };
};
export function getSecurityBagWeightJiracas(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagWeightJiracas());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.SecurityBagWeightBuckets.replace('{id}', id)}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagWeightJiracasSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagWeightJiracasError());
        }
    };
}

export const fetchGetSecurityBagWeightEscoria = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_ESCORIA,
    };
};
export const fetchGetSecurityBagWeightEscoriaSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
        value: result,
    };
};

export const fetchGetSecurityBagWeightEscoriaError = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    };
};
export function getSecurityBagWeightEscoria(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagWeightEscoria());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.SecurityBagWeightIngots.replace('{id}', id)}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagWeightEscoriaSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagWeightEscoriaError());
        }
    };
}

export const fetchPostSecurityBagWeightEscoria = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_ESCORIA,
    };
};
export const fetchPostSecurityBagWeightEscoriaSuccess = (result: {data: unknown}) => {
    return {
        type: POST_SECURITYBAG_WEIGHT_ESCORIA_SUCCESS,
        value: result,
    };
};

export const fetchPostSecurityBagWeightEscoriaError = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_ESCORIA_ERROR,
    };
};
export function postSecurityBagWeightEscoria(data: IBagWeigh, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostSecurityBagWeightEscoria());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.postSecurityBagWeightIngots}`,
                data,
                {headers}
            );
            Swal.fire({
                icon: 'success',
                title: 'Agregar jicara',
                text: '¡La jicara ha sido agregada con éxito!',
            });
            dispatch(fetchPostSecurityBagWeightEscoriaSuccess(response.data));
        } catch (error) {
            dispatch(fetchPostSecurityBagWeightEscoriaError());
            Swal.fire({
                icon: 'error',
                title: 'Agregar jicara',
            });
        }
    };
}

export const fetchGetSecurityBagWeightGold = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_GOLD,
    };
};
export const fetchGetSecurityBagWeightGoldSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
        value: result,
    };
};

export const fetchGetSecurityBagWeightGoldError = () => {
    return {
        type: GET_SECURITYBAG_WEIGHT_GOLD_ERROR,
    };
};
export function getSecurityBagWeightGold(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSecurityBagWeightGold());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.SecurityBagWeightGold.replace('{id}', id)}`,
                {headers}
            );
            dispatch(fetchGetSecurityBagWeightGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSecurityBagWeightGoldError());
        }
    };
}

export const fetchPostSecurityBagWeightGold = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_GOLD,
    };
};
export const fetchPostSecurityBagWeightGoldSuccess = (result: {data: unknown}) => {
    return {
        type: POST_SECURITYBAG_WEIGHT_GOLD_SUCCESS,
        value: result,
    };
};

export const fetchPostSecurityBagWeightGoldError = () => {
    return {
        type: POST_SECURITYBAG_WEIGHT_GOLD_ERROR,
    };
};
export function postSecurityBagWeightGold(data: FormData, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostSecurityBagWeightGold());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.postSecurityBagWeightGold}`,
                data,
                {headers}
            );
            Swal.fire({
                icon: 'success',
                title: 'Agregar jicara',
                text: '¡La jicara ha sido agregada con éxito!',
            });
            dispatch(fetchPostSecurityBagWeightGoldSuccess(response.data));
        } catch (error) {
            dispatch(fetchPostSecurityBagWeightGoldError());
            Swal.fire({
                icon: 'error',
                title: 'Agregar jicara',
            });
        }
    };
}
