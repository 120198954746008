export const REQUESTS_ARTICLE = {
    HEADER: 'Solicitud alta de nivel o artículo.',
    SUB_HEADER: 'Ingresa el tipo de artículo que deseas dar de alta.',
    HOLDER: 'Selecciona',
    TITLE_ARCHIEVE: 'Adjuntar un archivo',
    APPRASIAL: 'Avalúo',
    LOAN: 'Préstamo',
    AWESOME: 'Tasa de venta exitosa:',
    WITHOUTINTORMATION: 'Sin información',
    ARCHIVE_UPLOAD: 'Subir archivo',
    ARCHIVE_SIZE: 'Hasta 25 MB',
    ARCHIVE_ERROR: 'El archivo excede el tamaño máximo permitido. Recuerda que es hasta 25 MB',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_ACCEPT: 'Solicitar',
    OPTIONAL: '(Opcional)',
    FIRST_LEVEL: 'Nivel 1 (Grupo)',
    SECOND_LEVEL: 'Nivel 2 (Familia)',
    THIRD_LEVEL: 'Nivel 3 (Subfamilia)',
    FOURTH_LEVEL: 'Nivel 4 (Marca)',
    FIFTH_LEVEL: 'Nivel 5 (Articulo)',
    SUCCESS_REQUEST: '¡Solicitud enviada exitosamente!',
    SUCCESS_REQUESTñ_SECOND: ' Te notificaremos una vez que esté completo.',
};
