import React from 'react';

import documentDark from '@images/documentIconDark.svg';
import documentIcon from '@images/documentIconApp.svg';
import refreshIcon from '@images/icon-refresh.svg';
import checkMark from '@images/checkMark.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {SelectFingerprintProps} from '@ListClient/interfaces';
import {FINGERPRINT_SELECTION} from '@ListClient/constants';

const SelectFingerprint = ({
    selectedFinger,
    imageFileName,
    fileSizeInMB,
    imageSrc,
    onSelectClick,
}: SelectFingerprintProps) => {
    return (
        <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.5rem">
            <Text
                PaddingB="0.313rem"
                FlexDirection="column"
                Justify="center"
                Align="center"
                FontWeight="500"
                FontSize="1rem"
                Color="#1D1E20"
                oFlow="visible"
            >
                {FINGERPRINT_SELECTION.UPLOAD_FINGERPRINT_OF} {selectedFinger.finger}{' '}
                {selectedFinger.hand}
            </Text>
            <ContainerFlex
                Gap="1rem"
                Height="3.5rem"
                Border="1px solid #D4D6D8"
                Radius="1rem"
                PaddingR="1rem"
                Self="stretch"
            >
                <FlexPanel
                    Padding="1rem"
                    Justify="flex-start"
                    Align="center"
                    Background="#FAFAFA"
                    BottomLeftRad="1rem"
                    TopLeftRad="1rem"
                    Height="3.411rem"
                >
                    <Image src={documentDark} Width="1.5rem" Height="1.5rem" ObjectFit="cover" />
                </FlexPanel>
                <ContainerFlex
                    Padding="0.5rem 0 0.25rem 0"
                    FlexDir="column"
                    Align="flex-start"
                    Gap="0.25rem"
                    Flex="1 0 0"
                    Self="stretch"
                >
                    {imageFileName && (
                        <Text FontWeight="500" FontSize="0.875rem" Color="#2A2C2F" oFlow="normal">
                            {imageFileName}
                        </Text>
                    )}
                    {fileSizeInMB && (
                        <ContainerFlex Gap="0.25rem" Justify="flex-start">
                            <Image src={checkMark} Width="1rem" Height="1rem" />
                            <Text FontWeight="400" FontSize="0.875rem" Color="#54575C">
                                {fileSizeInMB} {FINGERPRINT_SELECTION.MB}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
                <FlexPanel
                    Align="center"
                    Justify="center"
                    Gap="0.25rem"
                    Cursor="pointer"
                    onClick={onSelectClick}
                >
                    <Image
                        src={imageSrc ? refreshIcon : documentIcon}
                        Height="1.5rem"
                        Width="1.5rem"
                    />
                    <Text
                        FontWeight="500"
                        FontSize="0.875rem"
                        Color="#5a5af8"
                        TextAlign="center"
                        Cursor="inherit"
                    >
                        {!imageSrc ? FINGERPRINT_SELECTION.UPLOAD : FINGERPRINT_SELECTION.REPLACE}
                    </Text>
                </FlexPanel>
            </ContainerFlex>
            <Text FontWeight="400" FontSize="0.875rem" Color="#54575C" LHeight="0.875rem">
                {FINGERPRINT_SELECTION.UPTO_25MB}
            </Text>
        </ContainerFlex>
    );
};
export default SelectFingerprint;
