export const GET_ALL_BRANCHES = 'GET_ALL_BRANCHES';
export const GET_ALL_BRANCHES_SUCCESS = 'GET_ALL_BRANCHES_SUCCESS';
export const GET_ALL_BRANCHES_ERROR = 'GET_ALL_BRANCHES_ERROR';

export const GET_SEARCH_BRANCHES = 'GET_SEARCH_BRANCHES';
export const GET_SEARCH_BRANCHES_SUCCESS = 'GET_SEARCH_BRANCHES_SUCCESS';
export const GET_SEARCH_BRANCHES_ERROR = 'GET_SEARCH_BRANCHES_ERROR';

export const POST_CREATE_BRANCH = 'POST_CREATE_BRANCH';
export const POST_CREATE_BRANCH_SUCCESS = 'POST_CREATE_BRANCH_SUCCESS';
export const POST_CREATE_BRANCH_ERROR = 'POST_CREATE_BRANCH_ERROR';
export const POST_CREATE_BRANCH_RESET = 'POST_CREATE_BRANCH_RESET';

export const GET_CAT_COUNTRY = 'GET_CAT_COUNTRY';
export const GET_CAT_COUNTRY_SUCCESS = 'GET_CAT_COUNTRY_SUCCESS';
export const GET_CAT_COUNTRY_ERROR = 'GET_CAT_COUNTRY_ERROR';

export const GET_TIERED_LEVEL = 'GET_TIERED_LEVEL';
export const GET_TIERED_LEVEL_SUCCESS = 'GET_TIERED_LEVEL_SUCCESS';
export const GET_TIERED_LEVEL_ERROR = 'GET_TIERED_LEVEL_ERROR';

export const PUT_BRANCH_STATUS = 'PUT_BRANCH_STATUS';
export const PUT_BRANCH_STATUS_SUCCESS = 'PUT_BRANCH_STATUS_SUCCESS';
export const PUT_BRANCH_STATUS_ERROR = 'PUT_BRANCH_STATUS_ERROR';
export const PUT_BRANCH_STATUS_FINISH = 'PUT_BRANCH_STATUS_FINISH';
