import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@/components/Shopify/Endeavors/styles';
import {AMORTIZATION_TABLE_LOANS} from '@components/PersonalLoans/constants';
import {IAmortizationTableProps} from '@components/PersonalLoans/interfaces';
import {AmortizationRowData} from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/AmortizationRowData';

export const AmortizationTable = ({tableData, error}: IAmortizationTableProps) => {
    const tableHeaders = [
        {title: AMORTIZATION_TABLE_LOANS.HEADERS.TERM},
        {title: AMORTIZATION_TABLE_LOANS.HEADERS.CAPITAL_PAYMENT},
        {title: AMORTIZATION_TABLE_LOANS.HEADERS.INTEREST},
        {title: AMORTIZATION_TABLE_LOANS.HEADERS.MONTH_PAYMENT},
        {title: AMORTIZATION_TABLE_LOANS.HEADERS.BALANCE},
    ];
    return (
        <>
            <ContainerFlex MaxH="336px" Height="max-content" Align="start">
                {error ? (
                    <Text FontWeight="700" Height="336px">
                        {AMORTIZATION_TABLE_LOANS.ERROR_MSG}
                    </Text>
                ) : (
                    <Table
                        Radius="0.5rem"
                        Border="1px solid #E8E9EA"
                        Overflow="auto"
                        MaxH="336px"
                        Height="max-content"
                        LCBorderB="none"
                    >
                        <TableRowHead
                            Height="47px"
                            GridColumn="91px repeat(4,1fr)"
                            Radius="0px"
                            Width="100%"
                            BackG="#fff"
                            BorderB="1px solid #E8E9EA"
                        >
                            {tableHeaders.map((column, index) => (
                                <TableItem Padding="1.125rem 1rem" Height="48px" key={index}>
                                    <Text
                                        Width="100%"
                                        Color="#1D1E20"
                                        FontSize="0.875rem"
                                        FontWeight="700"
                                        Height="48px"
                                    >
                                        {column.title}
                                    </Text>
                                </TableItem>
                            ))}
                        </TableRowHead>
                        <TableItemsContainer>
                            {tableData.map((data, index) => (
                                <AmortizationRowData {...data} key={index} />
                            ))}
                        </TableItemsContainer>
                    </Table>
                )}
            </ContainerFlex>
        </>
    );
};
