import {
    PUT_FLOW_DEPOSITED,
    PUT_FLOW_DEPOSITED_SUCCESS,
    PUT_FLOW_DEPOSITED_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';
const initialState = {
    deposited: [],
    loading: false,
    error: false,
};
const setDeposited = (state = initialState, action: {type: string; value: AxiosResponse}) => {
    switch (action.type) {
        case PUT_FLOW_DEPOSITED:
            return {
                ...state,
                loading: true,
            };
        case PUT_FLOW_DEPOSITED_SUCCESS:
            return {
                ...state,
                deposited: action.value,
                loading: false,
            };
        case PUT_FLOW_DEPOSITED_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default setDeposited;
