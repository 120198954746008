import React from 'react';

import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import ConfirmElimination from '@images/confirmElimination.svg';
import {ImageModalIcon} from '@Roles/styles';
import {IDeleteMessage} from '@Roles/RoleTable/interfaces';

export const RoleEditDeleteMessages = ({
    modalVisibility,
    setModalVisibility,
    userTotal,
    deleteConfirm,
}: IDeleteMessage) => {
    return (
        <Modal
            modalState={modalVisibility}
            changeModalState={() => {}}
            titleModalState={true}
            Height="20.5rem"
            Width="28.75rem"
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            BorderRadius="4px"
            background="none"
        >
            <ContainerFlex FlexDir="column" Padding="24px">
                <ImageModalIcon src={ConfirmElimination} />
                <Text
                    FontSize=".875rem"
                    FontWeight="500"
                    TextAlign="center"
                    Margin="0 0 0 16px"
                    Padding="24px"
                >
                    ¿Estás seguro que deseas eliminar el rol?
                </Text>
                <Text
                    FontSize=".875rem"
                    Height="3rem"
                    TextAlign="center"
                    Width="25.75rem"
                    wSpace="normal"
                >
                    Esto dejará a {userTotal} {userTotal > 1 ? 'usuarios' : 'usuario'} y todas las
                    sucursales sin roles asignados y podría causar inconsistencias en tu operación.
                </Text>
                <ContainerFlex
                    Justify="space-between"
                    Width="14.063rem"
                    Padding="24px 0 0 0"
                    Height="40px"
                    onClick={() => {
                        setModalVisibility(false);
                    }}
                >
                    <ButtonGeneral
                        width="5.813rem"
                        text="Cancelar"
                        type="submit"
                        hColor="#414042"
                        transform="capitalize"
                        secondaryButton
                        hborder=""
                        border="none"
                        clic={() => {
                            setModalVisibility(false);
                        }}
                    />
                    <ButtonGeneral
                        width="6.25rem"
                        text="Eliminar"
                        type="submit"
                        hColor="#414042"
                        transform="capitalize"
                        borderHover="solid 1px #a7a9ac"
                        borderDisabled="solid 1px #a7a9ac"
                        border="solid 1px #414042"
                        clic={() => {
                            deleteConfirm();
                            setModalVisibility(false);
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
