import React, {useState} from 'react';

import {
    ContainerCellGrid,
    ContainerFlex,
    Figure,
    Image,
    Input,
    Text,
    TextEllipsis,
} from '@Shopify/Ecommerce/styles';
import {SelectMultiRoles} from '@components/CreateUsers/SelectMultiRoles';
import {IPropsEnterJobAndRole} from '@components/ManageUser/interface';
import edit from '@components/CreateUsers/Icons/edit.svg';

export const EnterJobAndRole = ({
    register,
    textErrorJob,
    listEmployeeRoles,
    lastName,
    name,
    setValue,
    trigger,
}: IPropsEnterJobAndRole) => {
    const [modalStateRol, setModalStateRol] = useState(false);

    return (
        <>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Puesto
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Puesto"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('job')}
                    error={textErrorJob}
                />
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px" required>
                    Rol
                </Text>
                <ContainerFlex
                    Height="40px"
                    Border="1px solid #E5E7E9"
                    BoxShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                    HBorderColor="#35CF44"
                    Radius="4px"
                    Padding="0 12px"
                    Cursor="pointer"
                    Justify="space-between"
                    onClick={() => setModalStateRol(true)}
                >
                    {listEmployeeRoles?.length !== 0 ? (
                        <TextEllipsis
                            Cursor="pointer"
                            FontSize="0.875rem"
                            Color="#414042"
                            Width="90%"
                            FontWeight="400"
                        >
                            {listEmployeeRoles.map((employee, index: number) => {
                                return `${employee.roleName}${
                                    index === listEmployeeRoles.length - 1 ? '' : ', '
                                }`;
                            })}
                        </TextEllipsis>
                    ) : (
                        <Text Cursor="pointer" FontSize="0.875rem" Color="#cbced1">
                            Seleccionar rol
                        </Text>
                    )}
                    <Figure>
                        <Image src={edit} alt={'icon-edit'} />
                    </Figure>
                </ContainerFlex>
            </ContainerCellGrid>

            <SelectMultiRoles
                setModalStateRol={setModalStateRol}
                modalStateRol={modalStateRol}
                setValuesEmployeeRoles={(newValue) => setValue('roles', newValue)}
                validateValuesEmployeeRoles={() => trigger('roles')}
                name={name}
                lastName={lastName}
                listEmployeeRoles={listEmployeeRoles}
            />
        </>
    );
};
