import React from 'react';
import {useForm} from 'react-hook-form';

import {Text, ContainerFlex} from '@Shopify/Ecommerce/styles';
import fondo from '@CloseBox/svg/closeimg.svg';
import {FondoImg, FormCloseBox} from '@CloseBox/styles';
import ButtonGeneral from '@General/Atoms/Button';

export const CloseBox = () => {
    const {register, handleSubmit} = useForm({
        defaultValues: {
            radBox: '',
        },
    });
    const onSubmit = (data: {radBox: string}) => {};
    return (
        <ContainerFlex FlexDir="column" Padding="4rem 0 0 3rem">
            <Text Self="flex-start">
                Visualice los movimientos de la sucursal, gestione las tareas pendientes y cierre
                los movimientos de la sucursal
            </Text>
            <ContainerFlex
                Width="300px"
                MarginTop="40px"
                Height="67px"
                Padding="16px 24px"
                Radius="4px"
                Border="solid 0.5px #7ac143"
            >
                <ContainerFlex FlexDir="column" Align="flex-start">
                    <Text Color="#6d6e71" FontSize="12px" FontWeight="400">
                        Saldo inicial
                    </Text>
                    <Text Color="#404142" FontSize="18px" FontWeight="400">
                        $18,500.00
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="flex-start">
                    <Text Color="#6d6e71" FontSize="12px" FontWeight="400">
                        Saldo al cierre
                    </Text>
                    <Text Color="#404142" FontSize="18px" FontWeight="400">
                        $9,500.00
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Padding="90px 10%" Justify="space-around">
                <ContainerFlex
                    Padding="24px 0 0 0"
                    Width="261px"
                    Justify="flex-start"
                    Height="251px"
                    FlexDir="column"
                    BoxS="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                >
                    <Text Color="#6d6e71" FontSize="10px" FontWeight="400">
                        Valuador
                    </Text>
                    <Text Color="#7ac143" FontSize="16px" FontWeight="400">
                        Jose Miguel Arias
                    </Text>
                    <FondoImg src={fondo} />
                </ContainerFlex>
                <Text Color="#6d6e71" FontSize="40px" FontWeight="400">
                    +
                </Text>
                <ContainerFlex
                    Padding="24px 0 0 0"
                    Width="261px"
                    Justify="flex-start"
                    Height="251px"
                    FlexDir="column"
                    BoxS="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                >
                    <Text Color="#6d6e71" FontSize="10px" FontWeight="400">
                        Valuador
                    </Text>
                    <Text Color="#7ac143" FontSize="16px" FontWeight="400">
                        Antonio Perez Suarez
                    </Text>
                    <FondoImg src={fondo} />
                </ContainerFlex>
                <Text Color="#6d6e71" FontSize="40px" FontWeight="400">
                    +
                </Text>
                <ContainerFlex
                    Padding="24px 0 0 0"
                    Width="261px"
                    Justify="flex-start"
                    Height="251px"
                    FlexDir="column"
                    BoxS="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                >
                    <Text Color="#6d6e71" FontSize="10px" FontWeight="400">
                        Valuador
                    </Text>
                    <Text Color="#7ac143" FontSize="16px" FontWeight="400">
                        Jose Miguel Arias
                    </Text>
                    <FondoImg src={fondo} />
                </ContainerFlex>
            </ContainerFlex>
            <Text Color="#404142" FontSize="14px" FontWeight="400">
                Confirmo que los los valores relacionados en la sucursal se encuentra bien en
                relación al dinero
            </Text>
            <FormCloseBox onSubmit={handleSubmit(onSubmit)}>
                <ContainerFlex Width="100px" Justify="space-around" Padding="1rem 0 1.5rem 0">
                    <input
                        type="radio"
                        value="Si"
                        {...register('radBox', {
                            required: {value: true, message: 'Campo Obligatorio'},
                        })}
                    />
                    <Text>Si</Text>
                    <input
                        type="radio"
                        value="No"
                        {...register('radBox', {
                            required: {value: true, message: 'Campo Obligatorio'},
                        })}
                    />
                    <Text>No</Text>
                </ContainerFlex>
                <ButtonGeneral disabled={true} text="Cerrar sucursal" />
            </FormCloseBox>
        </ContainerFlex>
    );
};

export default CloseBox;
