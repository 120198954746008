import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {PaymentMethod} from '@components/Home/Admin/PaymentMethod';

import {MYPLAN_TEXT, MYPLAN_TABS} from '@components/MyPlan/constants';
import {ContainerTabs} from '@General/ModuleTabs/styles/styles';
import SummaryPlan from '@components/MyPlan/SummaryPlan';
import SalesChannels from '@components/MyPlan//SalesChannels';
import {BusinessLines} from '@components/MyPlan/BusinessLines';

export default function MyPlan() {
    const [isAmountDisplayed, setIsAmoutDisplayed] = useState(true);
    const plansMessage = useSelector((state: RootState) => state.getContractedPlans.plans?.firstText);

    const permanentPrompt = (
        <Text FontSize="1rem" FontWeight="500" Color="#174A2E" oFlow="visible">
            {plansMessage}
        </Text>
    );

    return (
        <ContainerFlex
            Padding="16px 24px"
            FlexDir="column"
            Align="center"
            Justify="start"
            Gap="16px"
            backG="#FAFAFA"
        >
            <ContainerFlex FlexDir="column" Align="flex-start" Justify="start" Height="fit-content" Gap="16px">
                {isAmountDisplayed && <PaymentMethod setIsDisplayed={setIsAmoutDisplayed} />}
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500" oFlow="visible">
                    {MYPLAN_TEXT.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerTabs
                tabs={MYPLAN_TABS}
                visibleContent={permanentPrompt}
                Gap="16px"
                FlexDir="column"
                Align="center"
                Justify="flex-start"
            >
                <SummaryPlan />
                <BusinessLines />
                <SalesChannels />
            </ContainerTabs>
        </ContainerFlex>
    );
}
