import {ACTION_TYPES_CUSTOMER} from '@ActionsCustomer/Customer';
import {
    GET_CUSTOMER_PROFITABILITY_SUCCESS,
    GET_SALE_DAY_SUCCESS,
    GET_SALE_DAY_ERROR,
    GET_PROFITABILITY_COUNTERSIGN_SUCCESS,
    GET_PROFITABILITY_COUNTERSIGN_ERROR,
    GET_PROFITABILITY_DISCHARGE_SUCCESS,
    GET_PROFITABILITY_DISCHARGE_ERROR,
    GET_PROFITABILITY_AUCTION_SUCCESS,
    GET_PROFITABILITY_AUCTION_ERROR,
    GET_PROFITABILITY_DIFERENCE_SUCCESS,
    GET_PROFITABILITY_DIFERENCE_ERROR,
    GET_CUSTOMER_PROFITABILITY_ERROR,
} from '@Customer/redux/types/index';
import {FieldValues} from 'react-hook-form';

const STATE_INITIAL = {
    Customer: {
        names: '',
        firstName: '',
        lastName: '',
    },
    Position: 0,
    SelectUser: {
        nuc: '',
        stars: 0,
        phone: 0,
        email: '',
        income: 0,
        firstName: '',
        lastName: '',
        pawn: {available: 0},
        save: {available: 0},
    },
    BooleanSelect: false,
    ErrorCustomer: '',
};
interface NewType {
    Customer: {names: string; firstName: string; lastName: string};
    Position: number;
    SelectUser: {
        nuc: string;
        phone: number;
        email: string;
        income: number;
        firstName: string;
        lastName: string;
        pawn: {available: number};
        save: {available: number};
    };
    BooleanSelect: boolean;
    ErrorCustomer: string;
}
export const Customer = (
    state: NewType = STATE_INITIAL,
    action: {type: string; payload: FieldValues; value: FieldValues; message: string}
) => {
    switch (action.type) {
        case ACTION_TYPES_CUSTOMER.GetCustomer:
            return {
                ...state,
                Customer: action.payload,
            };
        case ACTION_TYPES_CUSTOMER.SelectUser:
            return {
                ...state,
                SelectUser: action.payload,
            };
        case ACTION_TYPES_CUSTOMER.CleanUser:
            return {
                ...state,
                SelectUser: null,
            };
        case ACTION_TYPES_CUSTOMER.BooleanSelect:
            return {
                ...state,
                BooleanSelect: action.payload,
            };
        case ACTION_TYPES_CUSTOMER.Position:
            return {
                ...state,
                Position: action.value,
            };
        case ACTION_TYPES_CUSTOMER.ErrorCustomer:
            return {
                ...state,
                ErrorCustomer: action.message,
            };
        default:
            return state;
    }
};

const initialState = {
    profitability: {},
    loading: true,
    countersign: null,
    discharge: null,
    auction: null,
    diference: null,
};

export const CustomerProfitability = (
    state = initialState,
    action: {type: string; payload: FieldValues}
) => {
    switch (action.type) {
        case GET_CUSTOMER_PROFITABILITY_SUCCESS:
            return {
                ...state,
                profitability: action.payload,
                loading: false,
            };
        case GET_CUSTOMER_PROFITABILITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PROFITABILITY_COUNTERSIGN_SUCCESS:
            return {
                ...state,
                countersign: action.payload,
                loading: false,
            };
        case GET_PROFITABILITY_COUNTERSIGN_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PROFITABILITY_DISCHARGE_SUCCESS:
            return {
                ...state,
                discharge: action.payload,
            };
        case GET_PROFITABILITY_DISCHARGE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PROFITABILITY_AUCTION_SUCCESS:
            return {
                ...state,
                auction: action.payload,
            };
        case GET_PROFITABILITY_AUCTION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PROFITABILITY_DIFERENCE_SUCCESS:
            return {
                ...state,
                diference: action.payload,
            };
        case GET_PROFITABILITY_DIFERENCE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

const initialStateSaleDay = {
    saleDay: {},
    loading: true,
};

export const GetSaleDay = (
    state = initialStateSaleDay,
    action: {type: string; payload: FieldValues}
) => {
    switch (action.type) {
        case GET_SALE_DAY_SUCCESS:
            return {
                ...state,
                saleDay: action.payload,
                loading: false,
            };
        case GET_SALE_DAY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
