import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {initGroups} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {GetCompanyLevelsByCompanyId} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';

const useInitInheritGroups = (inheritType: string, numOfGroups: number) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const levels = useSelector((state: RootState) => state.getLevels.level);

    useEffect(() => {
        dispatch(initGroups(numOfGroups, inheritType));
        if (token && companyId && levels) {
            dispatch(GetCompanyLevelsByCompanyId(companyId, token, levels.levelId, {inheritType}));
        }
    }, [dispatch, companyId, token, inheritType, levels?.levelId]);
};

export default useInitInheritGroups;
