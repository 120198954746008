import {AppDispatch} from '@/config/store';
import {
    GET_BRAND,
    GET_BRAND_ERROR,
    GET_BRAND_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {updateBrandChildren} from './GetCatGroupAction';
import {getItemsAction} from './GetItemGroupAction';
import {Quantity} from '@/hooks/currentFormatUtils';
import {
    INITIAL_PAGE_ITEM,
    TOTAL_PAGE_ITEM,
} from '@/components/InventoryManagement/CatalogManagement/Operational/Constant';

export const getBrand = () => {
    return {
        type: GET_BRAND,
    };
};

export const getBrandSuccess = (result: AxiosResponse) => {
    return {
        type: GET_BRAND_SUCCESS,
        payload: result,
    };
};

export const getBrandError = (error: AxiosError) => {
    return {
        type: GET_BRAND_ERROR,
        payload: error,
    };
};

export const getBrandAction = (subFamilyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getBrand());
        const params = {
            subFamilyId: subFamilyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBrand}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getBrandSuccess(response));
            const brands = response.data.data;
            dispatch(updateBrandChildren(subFamilyId, brands));
            for (const brand of brands) {
                const QuantityNumber = Quantity(String(brand.nameBrand));
                if (QuantityNumber && QuantityNumber > 0)
                    dispatch(getItemsAction(TOTAL_PAGE_ITEM, INITIAL_PAGE_ITEM, brand.id, token));
            }
        } catch (error) {
            dispatch(getBrandError(error as AxiosError));
        }
    };
};
