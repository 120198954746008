import * as yup from 'yup';
import {IRequiredFields} from '@Quoter/Search/interfaces';

export const schemaRequest = yup.object().shape({
    Group: yup.string().required('Campo Obligatorio'),
    Family: yup.string().required('Campo Obligatorio'),
    SubFamily: yup.string().required('Campo Obligatorio'),
    Brand: yup.string().required('Campo Obligatorio'),
    Article: yup.string().required('Campo Obligatorio'),
});

export const generateSchemaRequest = (requiredFields: IRequiredFields) => {
    return yup.object().shape({
        Group: yup.string().required('Este campo es obligatorio, seleccione una de las opciones'),
        Family: requiredFields.Family
            ? yup.string().notRequired()
            : yup.string().required('Este campo es obligatorio, seleccione una de las opciones'),
        SubFamily: requiredFields.SubFamily
            ? yup.string().notRequired()
            : yup.string().required('Este campo es obligatorio, seleccione una de las opciones'),
        Brand: requiredFields.Brand
            ? yup.string().notRequired()
            : yup.string().required('Este campo es obligatorio, seleccione una de las opciones'),
        Article: requiredFields.Article
            ? yup.string().notRequired()
            : yup.string().required('Este campo es obligatorio, seleccione una de las opciones'),
    });
};
