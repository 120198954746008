import {
    PUT_CASHFLOW_SETUP,
    PUT_CASHFLOW_SETUP_ERROR,
    PUT_CASHFLOW_SETUP_FINISH,
    PUT_CASHFLOW_SETUP_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateCashSetup: false,
};

const PutCashFlowSetup = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_CASHFLOW_SETUP:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_CASHFLOW_SETUP_SUCCESS:
            return {
                ...state,
                updateCashSetup: action.payload.data,
            };
        case PUT_CASHFLOW_SETUP_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_CASHFLOW_SETUP_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutCashFlowSetup;
