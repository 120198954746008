import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    GET_EXPENSE_INVOICE,
    GET_EXPENSE_INVOICE_SUCCESS,
    GET_EXPENSE_INVOICE_ERROR,
} from '@components/CashFlow/Expenses/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';

const getExpenseInvoice = () => {
    return {
        type: GET_EXPENSE_INVOICE,
    };
};

const getExpenseInvoiceSuccess = (result: AxiosResponse) => {
    return {
        type: GET_EXPENSE_INVOICE_SUCCESS,
        payload: result,
    };
};

const getExpenseInvoiceError = (error: AxiosError) => {
    return {
        type: GET_EXPENSE_INVOICE_ERROR,
        error: error,
    };
};

export function getExpenseInvoiceAction(expenseId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getExpenseInvoice());
        const params = {
            id: expenseId.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetExpenseInvoice}?${queryString}`,
                {headers}
            );
            dispatch(getExpenseInvoiceSuccess(response));
        } catch (error) {
            dispatch(getExpenseInvoiceError(error as AxiosError));
        }
    };
}
