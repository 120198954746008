import axios, {AxiosError, AxiosResponse} from 'axios';
import StringUtils from '@/GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {
    GET_STATUS_APPLICATION_VALIDATE_SUCCESS,
    GET_STATUS_APPLICATION_VALIDATE_START,
    GET_STATUS_APPLICATION_VALIDATE_ERROR,
} from '@components/PersonalLoans/CreditStatus/Redux/Types/Types';
import {URL} from '@config/constants/index';

export const getStatusApplicationValidateStart = () => {
    return {
        type: GET_STATUS_APPLICATION_VALIDATE_START,
    };
};

export const getStatusApplicationValidateSuccess = (result: AxiosResponse) => {
    return {
        type: GET_STATUS_APPLICATION_VALIDATE_SUCCESS,
        payload: result.data,
    };
};

export const getStatusApplicationValidateError = (error: AxiosError) => {
    return {
        type: GET_STATUS_APPLICATION_VALIDATE_ERROR,
        error,
    };
};

export const getStatusApplicationValidate = (token: string, solicitudId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {solicitudId};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(getStatusApplicationValidateStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStatusApplicationValidate}${queryString}
            `,
                {headers}
            );
            dispatch(getStatusApplicationValidateSuccess(response));
        } catch (error) {
            dispatch(getStatusApplicationValidateError(error as AxiosError));
        }
    };
};
