import React, {RefObject} from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';
import {TableRowHeadRoles} from '@components/CreateUsers/SelectMultiRoles/TableRowHeadRoles';
import {TableHeaderRowModal} from '@components/CreateUsers/SelectMultiRoles/constants';
import {TableItem, TableItemsContainer} from '@Shopify/Endeavors/styles';
import {RolByEnterpriceData} from '@components/CreateUsers/Interface';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';

export const MultiRolesForm = (props: {
    containerRef: RefObject<HTMLDivElement>;
    selectRole: (roleId: RolByEnterpriceData) => void;
    selectedRoles: RolByEnterpriceData[];
}) => {
    const {selectedRoles, selectRole} = props;
    const Roles = useSelector((state: RootState) => {
        return state.AllRoles.tableData;
    });
    const diableComponents = (id: number) => {
        let isDisable = false;
        if (
            selectedRoles.length === 2 &&
            selectedRoles.some((data: RolByEnterpriceData) => data.id === id) === false
        ) {
            isDisable = true;
        }
        return isDisable;
    };

    return (
        <ContainerFlex FlexDir="column " Height="416px" Justify="start">
            {selectedRoles.length === 2 && (
                <Text
                    FontSize="0.875rem"
                    Color="#ef4f55"
                    MinH="1.2rem"
                    TextAlign="start"
                    Width="100%"
                    Margin="0 0 16px 0"
                >
                    Has seleccionado 2 roles, no puedes adicionar otro rol al usuario
                </Text>
            )}
            <Table>
                <TableRowHead
                    GridColumn="14% 30% 28% 28%"
                    Height="48px"
                    BackG="#e5e7e9"
                    Margin="0 0 4px 0"
                >
                    {TableHeaderRowModal.map(
                        (data: {name: string; id: number; isOrder: boolean}, index: number) => (
                            <TableRowHeadRoles
                                key={index}
                                id={data.id}
                                name={data.name}
                                isOrder={data.isOrder}
                            />
                        )
                    )}
                </TableRowHead>
                <TableItemsContainer Height="340px">
                    {Roles.length > 0 &&
                        Roles.map((item: RolByEnterpriceData, index: number) => (
                            <TableRowItems
                                key={index}
                                GridColumn="14% 30% 30% 26%"
                                Cursor="auto"
                                Height="48px"
                                Width="auto"
                                BackGround={diableComponents(item.id) ? '#f3f3f3' : '#FFF'}
                            >
                                <TableItem Width="5rem">
                                    {item.typeRoleId === 1 ? (
                                        ''
                                    ) : (
                                        <GreenSwitch
                                            onChange={() => {
                                                selectRole(item);
                                            }}
                                            size="small"
                                            checked={selectedRoles.some(
                                                (data: RolByEnterpriceData) => data.id === item.id
                                            )}
                                            disabled={diableComponents(item.id) ? true : false}
                                        />
                                    )}
                                </TableItem>
                                <TableItem Justify="flex-start">
                                    <TextEllipsis
                                        FontSize="0.875rem"
                                        Padding="0 0 0 16px"
                                        Color={diableComponents(item.id) ? '#a7a9ac' : '#414042'}
                                        FontWeight="500"
                                        TextAlign="left"
                                        Width="14rem"
                                    >
                                        {item.name}
                                    </TextEllipsis>
                                </TableItem>
                                <TableItem Justify="center">
                                    <Text
                                        FontSize="0.875rem"
                                        Color={diableComponents(item.id) ? '#a7a9ac' : '#414042'}
                                    >
                                        {item.typeRol.type}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="center">
                                    <TextEllipsis
                                        FontSize="0.875rem"
                                        Color={diableComponents(item.id) ? '#a7a9ac' : '#414042'}
                                        FontWeight="400"
                                        Width="10rem"
                                    >
                                        {item.description.length === 0
                                            ? 'Descripción de rol'
                                            : item.description}
                                    </TextEllipsis>
                                </TableItem>
                            </TableRowItems>
                        ))}
                    <ContainerFlex ref={props.containerRef}></ContainerFlex>
                </TableItemsContainer>
            </Table>
        </ContainerFlex>
    );
};
