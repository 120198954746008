import styled from '@emotion/styled';

export const ContainerLocation = styled.div`
    width: 100vw;
    margin: auto;
`;
export const TableGrid = styled.div`
    display: grid;
    margin-top: 5px;
    grid-template-column: ${(props) => props.GridColumn};
    display: flex;
    resize: none;
`;
TableGrid.defaultProps = {
    GridColumn: '1fr 1fr',
};
export const ArrowAuction = styled.div`
    padding-bottom: 2.188rem;
    display: flex;
    align-items: center;
    justify-content: start;

    span {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0px;
        color: var(--gray-2);
    }

    a {
        color: var(--gray-2);
    }
    span:last-child {
        font-size: 18px;
        letter-spacing: 0px;
        color: var(--black);
        padding-bottom: 0.2rem;
    }
    i {
        color: var(--gray-2);
        font-size: 20px;
    }
`;
export const ImgShow = styled.img`
    width: 125px;
    height: 125px;
    border: 1px solid #e5e7e9;
    border-radius: 19px;
    opacity: 1;
`;
