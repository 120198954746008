import React from 'react';
import {Controller} from 'react-hook-form';

import {IFormInputProps} from '@General/Atoms/LabeledInput/interfaces';
import {PrefixWrapper, StyledInput} from '@General/Atoms/LabeledInput/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';

const FormInput: React.FC<IFormInputProps> = ({
    name,
    label,
    mutedLabel,
    placeholder,
    type = 'text',
    control,
    register,
    error,
    onBlur,
    maxLength,
    inputInfo = null,
    prefix = null,
    styles = {},
}) => {
    const {
        labelFontSize = '0.75rem',
        labelFontWeight = '500',
        labelColor = '#6D6E71',
        letterSpacing = 'normal',
        mutedLabelFontSize = '0.75rem',
        mutedLabelFontWeight = '500',
        mutedLabelColor = '#A1A5AA',
        errorFontSize = '0.75rem',
    } = styles;

    return (
        <FlexPanel FlexDir="column" Align="flex-start" Gap="0.25rem">
            <ContainerFlex Gap="0.25rem" Justify="start" Align="center">
                <Text
                    FontSize={labelFontSize}
                    FontWeight={labelFontWeight}
                    Color={labelColor}
                    LetterSpacing={letterSpacing}
                >
                    {label}
                </Text>
                {mutedLabel && (
                    <Text
                        FontSize={mutedLabelFontSize}
                        FontWeight={mutedLabelFontWeight}
                        Color={mutedLabelColor}
                        PaddingL="0.25rem"
                    >
                        {mutedLabel}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Position="relative">
                {prefix && <PrefixWrapper>{prefix}</PrefixWrapper>}
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => (
                        <StyledInput
                            {...field}
                            {...register(name)}
                            placeholder={placeholder}
                            type={type}
                            hasPrefix={!!prefix}
                            error={!!error}
                            onBlur={(e) => {
                                field.onBlur();
                                if (onBlur) {
                                    onBlur(e);
                                }
                            }}
                            maxLength={maxLength}
                        />
                    )}
                />
            </ContainerFlex>
            {error ? (
                <Text Color="#FF6357" FontSize={errorFontSize} oFlow="visible">
                    {error}
                </Text>
            ) : (
                inputInfo && <>{inputInfo}</>
            )}
        </FlexPanel>
    );
};
export default FormInput;
