import {AppDispatch} from '@/config/store';
import {
    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_ERROR,
} from '@components/CashFlow/Redux/Types';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getUser = () => {
    return {
        type: GET_USER_DETAILS,
    };
};
export const getUserSuccess = (result: AxiosResponse) => {
    return {
        type: GET_USER_DETAILS_SUCCESS,
        payload: result,
    };
};
export const getUserError = (error: AxiosError) => {
    return {
        type: GET_USER_DETAILS_ERROR,
        error: error,
    };
};

export function getUserDetails(
    branchId: number,
    pageSize: number,
    pageNumber: number,
    token: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getUser());
        const params = {
            branchId: branchId.toString(),
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUserDetails}?${queryString}`;

        try {
            const response = await axios.get(url, {headers});
            dispatch(getUserSuccess(response));
        } catch (error) {
            dispatch(getUserError(error as AxiosError));
        }
    };
}
