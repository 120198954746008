import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {EMAIL_VERIFICATION} from '@components/MarketLogin/constants';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {IChangeScreen} from '@/components/Login/interfaces';

export function TextModule() {
    const UserData: IChangeScreen = useSelector((state: RootState) => state.getScreenSelect);
    const email = UserData.dataUser?.email;
    return (
        <ContainerFlex Gap="8px" Height="auto" FlexDir="column">
            <Text
                FontFamily="Nunito"
                Padding="0 10px"
                FontSize="1.5rem"
                FontWeight="700"
                Color="#1D1E20"
            >
                {EMAIL_VERIFICATION.TITLE}
            </Text>
            <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                {EMAIL_VERIFICATION.MESSAGE}
            </Text>
            <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                {EMAIL_VERIFICATION.EMAIL}{' '}
                <Text FontFamily="Nunito" MarginLeft="10px" FontWeight="bold">
                    {email}
                </Text>
            </Text>
        </ContainerFlex>
    );
}
