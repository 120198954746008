import {
    RESET_SELECT_LOCATIONS,
    TYPE_LOCATIONS_SELECTED,
} from '@components/LocationsBranch/Redux/types';
import {IListGroup, IListGroupRedux} from '@components/LocationsBranch/interfaces';

const initialState: IListGroup = {
    error: false,
    loading: false,
    typeSelected: {
        creationDate: '',
        description: '',
        locationId: 0,
        locationName: '',
        status: false,
    },
};

export const CreateNewGroup = (state = initialState, action: IListGroupRedux): IListGroup => {
    switch (action.type) {
        case TYPE_LOCATIONS_SELECTED:
            return {
                ...state,
                typeSelected: action.typeSelected,
                loading: false,
                error: false,
            };
        case RESET_SELECT_LOCATIONS:
            return initialState;
        default:
            return state;
    }
};
