import {IReducerType} from '@/components/Roles/interface';
import {
    GET_LIST_USER_NAMES_START,
    GET_LIST_USER_NAMES_SUCCESS,
    GET_LIST_USER_NAMES_ERROR,
    RESET_DATA_USERNAME,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState = {
    contact: [],
    loading: false,
    error: false,
};

const GetOptionsUserNames = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_LIST_USER_NAMES_START:
            return {
                ...state,
                loading: true,
            };
        case GET_LIST_USER_NAMES_SUCCESS:
            return {
                ...state,
                contact: action.payload.data.data,
                loading: false,
            };
        case GET_LIST_USER_NAMES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_DATA_USERNAME:
            return initialState;
        default:
            return state;
    }
};

export default GetOptionsUserNames;
