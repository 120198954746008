import {FieldValues} from 'react-hook-form';

import {searchArticles} from '@/components/Articles/Redux/interfaces';
import {ARTICLE, ARTICLE_SEARCH, FIELD_DINAMICS} from '@/components/Articles/Redux/Types';

const INITIAL_STATE = {
    Error: '',
    ItemsArticle: null,
    LevelSelected: '',
    RouteItems: [],
    getLevel: 0,
    Dinamics: {
        id: 0,
        level: 0,
        data: [],
    },
};
const SearchArticles = (
    state = INITIAL_STATE,
    action: {type: string; payload: searchArticles; position: number}
) => {
    switch (action.type) {
        case ARTICLE.ADD_ROUTE_ITEMS:
            return {
                ...state,
                RouteItems: [...state.RouteItems, action.payload],
            };
        case ARTICLE_SEARCH.ROUTE_ITEMS:
            return {
                ...state,
                RouteItems: state.RouteItems.length > 0 ? state.RouteItems : action.payload,
            };
        case ARTICLE.DELETE_LEVEL_ROUTE:
            return {
                ...state,
                RouteItems: state.RouteItems.filter(
                    (element: FieldValues, index: number) => index < action.position
                ),
            };
        case ARTICLE_SEARCH.RESTART_ROUTE:
            return {
                ...state,
                RouteItems: [],
                ItemsArticle: null,
            };
        case ARTICLE_SEARCH.SEARCH_ARTICLE:
            return {
                ...state,
                ItemsArticle: action.payload,
            };
        case ARTICLE_SEARCH.LEVEL_SELECTED:
            return {
                ...state,
                LevelSelected: action.payload,
            };
        case ARTICLE_SEARCH.RESTART_SEARCH_ARTICLE:
            return {
                ...state,
                ItemsArticle: null,
                LevelSelected: '',
            };
        case ARTICLE.GET_LEVEL:
            return {
                ...state,
                getLevel: action.payload,
            };
        case FIELD_DINAMICS.ADD_DINAMIC:
            return {
                ...state,
                Dinamics: {...state.Dinamics, id: action.payload},
            };
        case FIELD_DINAMICS.RESTART_DINAMICS:
            return {
                ...state,
                Dinamics: {
                    id: 0,
                    level: 0,
                    data: [],
                },
            };
        case ARTICLE_SEARCH.ERROR:
            return {
                ...state,
                Error: action.payload,
            };
        default:
            return state;
    }
};

export default SearchArticles;
