import * as yup from 'yup';

const PHONE_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const updateAddressResolver = yup.object().shape({
    label: yup
        .string()
        .required('Se necesita ingresar una etiqueta')
        .max(20, 'Indique hasta 20 caracteres'),
    postalCode: yup
        .string()
        .required('El C.P.  debe tener 5 dígitos.')
        .max(5, 'Indique hasta 5 caracteres'),
    street: yup.string().required('Ingresar una calle').max(100, 'Indique hasta 100 caracteres'),
    OutNumber: yup
        .string()
        .required('Ingresar una No. exterior')
        .max(5, 'Indique hasta 5 caracteres'),
    InternalNumber: yup.string().max(5, 'Indique hasta 5 caracteres'),
    colonyId: yup.object().shape({
        value: yup.string(),
        label: yup.string().required('Seleccione una colonia'),
    }),
    municipalityId: yup.object().shape({
        value: yup.string(),
        label: yup.string().required('Seleccione una alcaldía'),
    }),
    stateId: yup.object().shape({
        value: yup.string(),
        label: yup.string().required('Seleccione un estado'),
    }),
});

export const updateCompany = yup.object().shape({
    nameEnterprice: yup.string().required('Indique el nombre de la empresa'),
    naturalEnterprice: yup.string().required('Indique el nombre natural de la empresa'),
    mail: yup.array().of(
        yup.object().shape({
            email: yup
                .string()
                .required('Indique el correo electrónico')
                .matches(EMAIL_REGEX, 'Ingrese un correo electrónico válido'),
        })
    ),
    phones: yup.array().of(
        yup.object().shape({
            phone: yup
                .string()
                .required('Indique el número de teléfono')
                .matches(PHONE_REGEX, 'Ingrese un teléfono válido'),
        })
    ),
});
