export const CREDIT_QUOTER = {
    TITLE: 'Cotiza tu crédito',
    GO_BACK_TO_RESULTS: 'Regresar a resultados',
    VISIT_BRANCH: 'Visitar tienda',
    BTN_REQUEST_NOW: 'Solicitar ahora',
    BTN_SEE_TABLE: 'Ver tabla de amortización',
    FIELDS: {
        AMOUNT: '¿Cuánto dinero necesitas?',
        FREQUENCY: '¿Con que frecuencia quieres pagar?',
        PERIODS: '¿En cuantos plazos quieres pagar?',
    },
    QUOTE_ERROR: 'No existe un plazo disponible para este monto',
};

export const AMORTIZATION_TABLE = {
    TITLE: 'Tabla de amortización',
    TABLE_HEADERS: {
        QUOTE_NUMBER: 'No.cuota',
        CAPITAL_PAYMENT: 'Pago a capital',
        INTEREST: 'Interés',
        AMOUNT_QUOTE: 'Pago de cuota',
        OUTSTANDING_BALANCE: 'Saldo',
    },
    QUOTE_DATA: {
        ANNUAL_INTEREST: 'Interés anual -',
        FREQUENCY: 'Frecuencia -',
        FIXED: 'Fija',
    },
};
