import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {fetchGetObjectBranch, getLocationCreateContainer} from '@ActionsLocation/LocationAction';
import addcont from '@images/AddCont.svg';
import {ContainerImg, ImgLocation} from '@CreateLocation/style';
import {location} from '@Locations/AdminLocations/redux/Interfaces';

const CreateLocation = () => {
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const history = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [name, setName] = useState('');

    useEffect(() => {
        if (token) dispatch(getLocationCreateContainer(token));
    }, [dispatch]);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="25%">
            <ContainerFlex
                Position="relative"
                PositionTop="64px"
                PositionLeft="44px"
                Width="23px"
                Height="23px"
            >
                <ImgLocation src={addcont} />
            </ContainerFlex>
            <ContainerFlex Align="start" FlexWrap="wrap" Width="110px" Height="110px">
                {locations.createcontainer &&
                    locations.createcontainer.map((item, index: number) => (
                        <ContainerImg
                            key={index}
                            backG="#EFF0F2"
                            BoxBackground="#7AC143"
                            Margin="0 4px"
                            Width="45px"
                            Height="45px"
                            Radius="50%"
                            Cursor="pointer"
                            onMouseEnter={() => setName(item.containerName)}
                            onMouseOut={() => setName('')}
                            onClick={() => {
                                history(item.accessUrl);
                                dispatch(fetchGetObjectBranch({type: 'create'}));
                            }}
                        >
                            <ImgLocation
                                Cursor="pointer"
                                onMouseEnter={() => setName(item.containerName)}
                                Width="25px"
                                Height="22px"
                                src={item.urlIcono}
                            />
                        </ContainerImg>
                    ))}
            </ContainerFlex>
            <ContainerFlex Width="110px" Height="20px" Padding="1.5rem 0">
                {name !== '' ? <Text FontSize="14px">Añadir {name}</Text> : ''}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreateLocation;
