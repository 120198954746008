import axios, {AxiosResponse} from 'axios';
import {
    GET_BRANCH_SCHEDULE_DISCHARGE,
    GET_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    GET_BRANCH_SCHEDULE_DISCHARGE_FINISH,
    GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
    GET_BRANCH_SCHEDULE_DISCHARGE_RESET,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const fetchGetScheduleDischargeStart = () => {
    return {
        type: GET_BRANCH_SCHEDULE_DISCHARGE,
    };
};
export const fetchGetScheduleDischargeSuccess = (result: AxiosResponse) => {
    return {
        type: GET_BRANCH_SCHEDULE_DISCHARGE_SUCCESS,
        payload: result,
    };
};
export const fetchGetScheduleDischargeError = () => {
    return {
        type: GET_BRANCH_SCHEDULE_DISCHARGE_ERROR,
    };
};
export const fetchGetScheduleDischargeFinish = () => {
    return {
        type: GET_BRANCH_SCHEDULE_DISCHARGE_FINISH,
    };
};
export const fetchGetScheduleDischargeReset = () => {
    return {
        type: GET_BRANCH_SCHEDULE_DISCHARGE_RESET,
    };
};

export function GetScheduleDischarge(branchId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetScheduleDischargeStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetScheduleDischarge}`,
                {
                    headers,
                    params: {
                        branchId,
                    },
                }
            );
            dispatch(fetchGetScheduleDischargeSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetScheduleDischargeError());
        } finally {
            dispatch(fetchGetScheduleDischargeFinish());
        }
    };
}
