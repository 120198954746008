import axios, {AxiosError} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {GetPaymentMethod} from '@/components/AccountSettings/Redux/Actions/GetCompanyPaymentMethod';
import {IAddPaymentMethod} from '@components/AccountSettings/Redux/interfaces';
import {
    ADD_NEW_PAYMENT_START,
    ADD_NEW_PAYMENT_SUCCESS,
    ADD_NEW_PAYMENT_ERROR,
} from '@components/AccountSettings/Redux/types/Types';

const addPaymentMethodStart = () => {
    return {type: ADD_NEW_PAYMENT_START};
};
const addPaymentMethodSuccess = () => {
    return {type: ADD_NEW_PAYMENT_SUCCESS};
};

const addPaymentMethodError = (error: AxiosError) => {
    return {type: ADD_NEW_PAYMENT_ERROR, error: error};
};

export function AddPaymentMethod(data: IAddPaymentMethod, token: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(addPaymentMethodStart());
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddPaymentMethodCompany}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addPaymentMethodSuccess());
            dispatch(GetPaymentMethod(data.companyId, token));
        } catch (error) {
            dispatch(addPaymentMethodError(error as AxiosError));
        }
    };
}
