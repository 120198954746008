import {
    CHANGE_SCREEN,
    GET_USER_DATA,
    RESET_LOGIN_MARKET,
} from '@components/Login/Redux/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    data: 1,
    dataUser: {},
};

const getScreenSelect = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case CHANGE_SCREEN:
            return {
                ...state,
                data: action.payload,
            };
        case GET_USER_DATA:
            return {
                ...state,
                dataUser: action.payload,
            };
        case RESET_LOGIN_MARKET:
            return initialState;
        default:
            return state;
    }
};

export default getScreenSelect;
