import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import PersonalLoansToAccept from '@/components/PersonalLoans/PersonalLoansToAccept';
import {ICreditLoanSummary} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const PersonalLoanApprovedSummary: React.FC<ICreditLoanSummary> = ({
    requestedLoan,
    dues,
    annualInterest,
    monthlyPayments,
    totalAmount,
    control,
    errors,
}) => {
    return (
        <ContainerFlex
            Width="625px"
            Height="100%"
            Justify="space-between"
            Align="start"
            Padding="24px"
            Gap="24px"
            Radius="24px"
            Border="1px solid #0D166B"
            FlexDir="column"
        >
            <ContainerFlex Width="100%" Height="100%" Align="start" Justify="start">
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.SUBTITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Width="100%"
                Height="100%"
                Align="start"
                Justify="start"
                Gap="16px"
                FlexDir="column"
                Padding="8px"
            >
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.LOAN_REQUESTED}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {numberToCurrencyWithoutCents(requestedLoan)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.FEES}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {dues}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.ANUAL_INTEREST_RATE}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {`${annualInterest}%`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Height="100%" Width="100%" FlexDir="column" Gap="24px">
                <PersonalLoansToAccept
                    dues={dues}
                    monthlyPayments={monthlyPayments}
                    totalAmount={totalAmount}
                    control={control}
                    errors={errors}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalLoanApprovedSummary;
