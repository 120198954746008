export const PERSONALLOANS = {
    TITLE: 'Solicitud de préstamo',
    AVOUT: 'Acerca de ti',
};

export const PERSONALDATA = {
    ABOUT: 'Acerca de ti',
    NAME: 'Nombres',
    LASTNAEM: 'Apellidos',
    CURP: 'CURP',
    BIRTHDAY: 'Fecha de nacimiento',
    PHONE: 'Teléfono',
};

export const ADRESSDATA = {
    LIVE: '¿En dónde vives?',
    STREET: 'Calle',
    NOEXT: 'No. exterior',
    NOINT: 'No. interior',
    OPTIONAL: '(Opcional)',
    CP: 'Código postal',
    COLONY: 'Colonia',
    CITYHALL: 'Alcaldía o municipio',
    STATE: 'Estado',
};

export const JOBDATA = {
    ABOUT: 'Acerca de tu trabajo',
    STATE: 'Situación laboral',
    NAME: 'Nombre de la empresa',
    ADDRESS: 'Dirección  de la empresa',
    PHONE: 'Teléfono de la empresa',
    POSITION: 'Puesto',
    OPTIONAL: '(Opcional)',
    SENIORITY: 'Antigüedad',
    INCOME: 'Ingreso mensual',
};
export const UPLOADDATA = {
    VERIFY: 'Comprobaremos tu identidad',
    SELECT: 'Selecciona tu identificación:',
    TITLE: 'Sube tus documentos aquí',
    SIGNATURE: 'Firma de solicitud de crédito',
    AUTHORIZED: 'Acepto consulta de buró de crédito',
    VALIDATE:
        'Al hacer click en “Validar identidad” validaremos internamente tu identidad y te mostraremos tu contrato',
};
export const UPLOADFRONTDATA = {
    TITLE: 'Parte frontal de la identificación',
    SUBTITLE: 'La parte frontal es el lado de tu identificación donde aparece tu foto.',
};
export const UPLOADBACKDATA = {
    TITLE: 'Parte de atrás de la identificación',
    SUBTITLE: 'La parte trasera es el lado de tu identificación donde no hay foto.',
};
export const UPLOADINCOMEDATA = {
    TITLE: 'Comprobante de ingresos',
};
export const UPLOADDROPDATA = {
    DROP: 'Arrastra aquí tu documento',
    TYPE: 'JPG, PNG, PDF | Peso máximo 4MB',
    SELECT: 'Elegir archivo',
    REMPLACE: 'Remplazar',
};
export const formJobLoans = {
    personalInformationId: null,
    catEmploymentSituationId: {},
    nameCompany: '',
    addresCompany: '',
    catLadaId: '',
    phoneCompany: '',
    job: '',
    catLaborOldId: {},
    monthlyIncome: null,
};

export const formUsers = {
    name: '',
    lastNames: '',
    curp: '',
    birthdate: '',
    catLadaId: 0,
    phone: '',
    addressPersonalLoans: {
        street: '',
        interiorNumber: '',
        externalNumber: '',
        postalCode: '',
        catColonyId: {},
        catMunicipaliyId: {},
        catStateId: {},
    },
};
export const STEPFOUR = {
    SUBTITLE: 'Estamos validando tu solicitud',
    VALIDATION: {
        INFORMATION: 'Estamos validando tu información',
        IDENTITY: 'Estamos validando tu identidad',
        CREDIT_HISTORY: 'Estamos validando tu historial crediticio',
    },
    MESSAGE:
        'Esto puede tardar algunos minutos si se cierra por error puedes regresar a validar el estatus de tu solicitud con tu usuario y contraseña',
};

export const PERSONAL_LOAN_APPROVED = {
    HEADER: {
        TITLE: 'Solicitud de préstamo',
    },
    BODY: {
        DEPOSIT_INFORMATION: {
            BANNER_MESSAGE: 'tu solicitud ha sido aprobada!',
            BANNER_MESSAGE_SA: 'Nosotros te ayudamos a encontrar el dinero que necesitas',
            BANNER_MESSAGE_PARAGRAPH_SA:
                'Puedes aceptar este préstamo y nosotros nos encargamos de buscar el resto con otro negocio.',
            BANNER_MESSAGE_NAME_SA: 'tenemos noticias para ti!',
            BANNER_MESSAGE_HAS_APPROVED_SA: 'Han sido aprobados',
            BANNER_MESSAGE_HAS_APPROVED_OF_SA: 'de los',
            BANNER_MESSAGE_CREDIT_REQUESTED_SA: 'solicitados',
            SUBTITLE: '¿Dónde quieres recibir tu dinero?',
            SUBTITLE_PARAGRAPH:
                'Selecciona el lugar donde quieres que te depositemos el dinero del préstamo:',
            ACC_BANK_TRANSFER_TITLE: 'Transferencia bancaria',
            ACC_BANK_TRANSFER_PARAGRAPH: 'El depósito puede tardar hasta 48h.',
            ACC_BANK_TRANSFER_PANEL: 'BANK_TRANSFER_PANEL',
            BANK_TRANSFER_PASSWORD_LB: 'Cuenta CLABE',
            BANK_TRANSFER_PASSWORD_PLACEHOLDER: 'Ingresa tu cuenta clave',
            BANK_TRANSFER_TITLE_LB: 'Banco',
            BANK_TRANSFER_SELECT_PLACEHOLDER: 'Selecciona una opción',
            ACC_ATASKATE_WALLET_TITLE: 'Billetera Digital de Ataskate',
            ACC_ATASKATE_WALLET_PARAGRAPH: 'Tiempo promedio para el deposito de 1 a 2 horas.',
            ACC_ATASKATE_WALLET_PANEL: 'ATASKATE_WALLET_PANEL',
        },
        SUMMARY: {
            SUBTITLE: 'Resumen del préstamo',
            LOAN_REQUESTED: 'Préstamo Solicitado',
            LOAN_GRANTED: 'Préstamo Aprobado',
            FEES: 'Cuotas',
            ANUAL_INTEREST_RATE: 'Tasa de interes anual',
            MONTHLY_PAYMENTS: 'pagos mensuales de: ',
            TOTAL_AMOUNT: 'Monto total a pagar: ',
            CTA_AMORTIZATION_TABLE: 'Ver tabla de amotización',
            TERMS_AND_CONDITIONS_ACCEPT: 'Acepto',
            TERMS_AND_CONDITIONS: 'términos y condiciones del contrato',
            CTA_ACCEPT_LOAN: 'Aceptar préstamo',
            REJECT_LOAN: 'Rechazar préstamo',
        },
    },
};

export const FORM_DATA_NAMES = {
    PASSWORD_FIELD: 'pasword',
    TRANSFER_BANK: 'bank',
    TERMS_CONDITIONS_ACCEPTED: 'terms_conditions',
};

export const VALIDATION_MESSAGE = {
    TERMS_CONDITIONS: 'Debes aceptar los términos y condiciones.',
    ACCOUNT_NUMBER: {
        REQUIRED: 'El número de cuenta clave es obligatorio.',
        LENGTH: 'El número de cuenta clave debe tener entre 8 y 16 caracteres',
        INVALID_CHARS: 'El número de cuenta clave debe contener solo dígitos.',
    },
    BANK: 'Debes seleccionar un banco.',
    MANDATORY_FIELD: 'Campo obligatorio',
    CLABE_TRANSACTIONS_LENGTH: 'La cuenta CLABE debe tener exactamente 18 dígitos',
};

export const AMORTIZATION_TABLE_LOANS = {
    TITLE: 'Tabla de amortización',
    HEADERS: {
        TERM: 'No.cuota',
        CAPITAL_PAYMENT: 'Pago a capital',
        INTEREST: 'Interés',
        MONTH_PAYMENT: 'Pago de cuota',
        BALANCE: 'Saldo',
    },
    ERROR_MSG: 'No existe un plazo disponible para este monto',
};
export const LadaDefault = {
    countryId: 40,
    name: 'Cánada',
    urlFlag:
        '"https://storage.googleapis.com/goldenstarweb/media/Assests/Banderas/America/Canada.svg"',
    lada: '+1',
};

export const REJECT_LOAN_MODAL = {
    TITLE: '¿Está seguro de rechazar este préstamo?',
    DESCRIPTION: 'Si rechaza la oferta, no podrá acceder a estas condiciones nuevamente.',
    CANCEL_BTN: 'Cancelar',
    REJECT_BTN: 'Rechazar préstamo',
};

// To-do: Delete this array adding backend services
export const amortizationDataItemRepeatedLayout = {
    capitalPayment: 1614.51,
    interest: 40,
    amountQuote: 1666.67,
    outstandingBalance: 8333.33,
};
// To-do: Delete this array after adding backend services
export const amortizationTableLayoutData = [
    {
        quoteNumber: 1,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 8333.33,
    },
    {
        quoteNumber: 2,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 6666.66,
    },
    {
        quoteNumber: 3,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 5000,
    },
    {
        quoteNumber: 4,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 3333.33,
    },
    {
        quoteNumber: 5,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 1666.67,
    },
    {
        quoteNumber: 6,
        ...amortizationDataItemRepeatedLayout,
        outstandingBalance: 0,
    },
];
