import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    IChargeAndPenaltyItem,
    ITableChargesAndPenaltiesProps,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {tableHeaders} from '@components/PersonalLoans/NewLoanProduct/constants';
import {AppDispatch, RootState} from '@/config/store';
import {handleAllChargesCheckboxs} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {RowChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/TableChargesAndPenalties/RowChargesAndPenalties';

export const TableChargesAndPenalties: React.FC<ITableChargesAndPenaltiesProps> = ({
    chargeState,
    setChargeState,
}) => {
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();
    const chargesAndPenaltiesTable: IChargeAndPenaltyItem[] = useSelector(
        (state: RootState) => state.setChargesAndPenaltiesTable.chargesAndPenalties
    );
    useEffect(() => {
        dispatch(handleAllChargesCheckboxs(allChecked));
    }, [allChecked]);

    return (
        <ContainerFlex Radius="1rem" Border="1px solid #E8E9EA" FlexDir="column">
            <GridContainer
                GridColumns="40px repeat(5,1fr) 32px"
                Gap="1rem"
                Padding="0.5rem 1rem"
                Bb="1px solid #E8E9EA"
            >
                <Checkbox onChange={() => setAllChecked(!allChecked)} />
                {tableHeaders.map((item, index) => (
                    <Text
                        FontWeight="700"
                        Color="#1D1E20"
                        FontSize="0.875rem"
                        Width="100%"
                        Padding="0.25rem 0px"
                        key={index}
                    >
                        {item.title}
                    </Text>
                ))}
                <ContainerFlex Width="32px" />
            </GridContainer>
            {chargesAndPenaltiesTable.map((item, index) => (
                <RowChargesAndPenalties
                    item={item}
                    index={index}
                    key={index}
                    chargeState={chargeState}
                    setChargeState={setChargeState}
                />
            ))}
        </ContainerFlex>
    );
};
