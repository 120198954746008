import {SEARCH_INVENTORY} from '@components/LocationsBranch/Redux/types';
import {SelectedSearch, LevelSelection} from '@components/LocationsBranch/Redux/interfaces';

const initialState: SelectedSearch = {
    selectedLevel: null,
};

const searchInventorySelection = (
    state = initialState,
    action: {type: string; payload: LevelSelection | null}
): SelectedSearch => {
    switch (action.type) {
        case SEARCH_INVENTORY.SET_SELECTED_STORAGE:
            return {
                ...state,
                selectedLevel: action.payload,
            };
        default:
            return state;
    }
};

export default searchInventorySelection;
