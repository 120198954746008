import {
    GET_EXPENSE_INVOICE,
    GET_EXPENSE_INVOICE_SUCCESS,
    GET_EXPENSE_INVOICE_ERROR,
} from '@components/CashFlow/Expenses/Redux/types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    expenseInvoice: '',
    loading: false,
    error: false,
};

const getExpenseInvoiceReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_EXPENSE_INVOICE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_EXPENSE_INVOICE_SUCCESS:
            return {
                ...state,
                expenseInvoice: action.payload.data.data,
                loading: false,
            };
        case GET_EXPENSE_INVOICE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getExpenseInvoiceReducer;
