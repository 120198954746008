import React from 'react';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import PersonalData from '@/components/Shopify/ListClient/PersonalData';
import AdditionalInformation from '@/components/Shopify/ListClient/AdditionalInformation';
import CoownerBeneficiary from '@/components/Shopify/ListClient/CoownerBeneficiary';
import Marketing from '@/components/Shopify/ListClient/Marketing';

const CreateClient = (props: IClientProofStep) => {
    return (
        <>
            <PersonalData {...props} />
            <AdditionalInformation {...props} />
            <CoownerBeneficiary {...props} />
            <Marketing {...props} />
        </>
    );
};

export default CreateClient;
