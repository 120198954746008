import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    ContainerFlex,
    Text,
    Input,
    ContainerForm,
    Image,
} from '@/components/Shopify/Ecommerce/styles';
import {
    DATA_FORM,
    InputStyle,
    TextInput,
    Grids,
    GENERAL_TITLES,
    TextInputEdit,
    GENDERS,
} from '@components/Users/UserProfile/constants';
import {DEFAULT_VALUES, schemaPersonalInfo} from '@components/Users/UserProfile/yupSchema';
import {yupResolver} from '@hookform/resolvers/yup';
import {IPersonalInfo} from '@components/Users/UserProfile/interfaces';
import Done from '@images/Check_normal.svg';
import Edit from '@images/editIcon.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {RootState} from '@/config/store';
import {IScreenFormPersonalInfo} from '@components/Users/Redux/Reducers/FormUserInfo';
import {getAllFormData} from '@components/Users/Redux/Actions/ScreenUseSelector';

export const PersonalInfo = () => {
    const dispatch = useDispatch();
    const FormData: IScreenFormPersonalInfo = useSelector(
        (state: RootState) => state.ScreenStateUsersForm
    );
    const isEmpty = (data: string) => (
        <Text {...TextInputEdit}>{data?.length ? data : GENERAL_TITLES.EMPITY}</Text>
    );
    const [isEdit, setIsEdit] = useState(false);
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(schemaPersonalInfo),
    });
    const onSubmit = (data: IPersonalInfo) => {
        dispatch(getAllFormData(data));
        setIsEdit(false);
    };
    useEffect(() => {
        reset(FormData.data);
    }, []);
    return (
        <ContainerForm FlexDir="column" Gap="16px" onSubmit={handleSubmit(onSubmit)}>
            <ContainerFlex Justify="space-between" Height="40px" Padding="0 0 16px 0">
                <Text FontSize="1.25rem" FontWeight="700">
                    {GENERAL_TITLES.TITLE_CONTACT_HEADER}
                </Text>
                {isEdit ? (
                    <ButtonGenerals SecondaryButton="none" Height="40px">
                        <Image alt="save" src={Done} />
                        {GENERAL_TITLES.SAVE_CHANGES}
                    </ButtonGenerals>
                ) : (
                    <ContainerFlex ColumnGap="8px" Justify="end">
                        <Image alt="save" src={Edit} />
                        <Text
                            FontWeight="700"
                            Color="#5A5AFF"
                            Cursor="pointer"
                            onClick={() => setIsEdit(true)}
                        >
                            {GENERAL_TITLES.EDIT}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
            <ContainerFlex {...Grids}>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.NAMES}</Text>
                    {isEdit ? (
                        <Input error={!!errors.name?.message} {...register('name')} />
                    ) : (
                        isEmpty(FormData.data.name)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.LAST_NAME}</Text>
                    {isEdit ? (
                        <Input error={!!errors.lastName?.message} {...register('lastName')} />
                    ) : (
                        isEmpty(FormData.data.lastName)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.OTHER_LAST_NAME}</Text>
                    {isEdit ? (
                        <Input
                            error={!!errors.otherLastName?.message}
                            {...register('otherLastName')}
                        />
                    ) : (
                        isEmpty(FormData.data.otherLastName)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.DATE}</Text>
                    {isEdit ? (
                        <Input error={!!errors.date?.message} {...register('date')} />
                    ) : (
                        isEmpty(FormData.data.date)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.GENDER}</Text>
                    {isEdit ? (
                        <Controller
                            name="gender"
                            control={control}
                            render={({field}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={!!errors.gender?.label?.message}
                                    options={GENDERS}
                                />
                            )}
                        />
                    ) : (
                        isEmpty(FormData.data.gender.label)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.EMAIL}</Text>
                    {isEdit ? (
                        <Input error={!!errors.email?.message} {...register('email')} />
                    ) : (
                        isEmpty(FormData.data.email)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.OTHER_EMAIL}</Text>
                    {isEdit ? (
                        <Input error={!!errors.otherEmail?.message} {...register('otherEmail')} />
                    ) : (
                        isEmpty(FormData.data.otherEmail)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.TEL}</Text>
                    {isEdit ? (
                        <Input error={!!errors.cel?.message} {...register('cel')} />
                    ) : (
                        isEmpty(FormData.data.cel)
                    )}
                </ContainerFlex>
                <ContainerFlex {...InputStyle}>
                    <Text {...TextInput}>{DATA_FORM.OTHER_TEL}</Text>
                    {isEdit ? (
                        <Input
                            error={!!errors.otherCelphone?.message}
                            {...register('otherCelphone')}
                        />
                    ) : (
                        isEmpty(FormData.data.otherCelphone)
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
