import React from 'react';

import {SearchInput} from '@/components/Customer/Styles';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
import {IGroupInput} from '@Quoter/Search/interfaces';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';

export const ArticleInput = ({register, setIsOpcional, requiredFields, errors}: IGroupInput) => {
    return (
        <ContainerFlex FlexDir="column" Height="auto" Gap="4px" Align="start">
            <Text FontSize="0.75rem" Color="#2A2C2F">
                {REQUESTS_ARTICLE.FIFTH_LEVEL}
            </Text>
            <SearchInput
                Height="40px"
                Position="none"
                GridColumn="95% 5%"
                FontSize="1rem"
                PlaceholderColor="#A1A5AA"
                Margin="0"
                WBoxShadow=""
            >
                <input type="text" autoComplete="off" {...register('Article')} placeholder="" />
            </SearchInput>
            {errors.Article?.message && (
                <Text Color="#FF6357" FontSize=".75rem" MarginTop="4px">
                    {errors.Article.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
