import {useForm} from 'react-hook-form';
import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {schema} from '@components/SignUp/validationsYup';
import {ISignUpData} from '@components/SignUp/interfaces';
import {
    ContainerCellGrid,
    ContainerImg,
    Input,
    LinkAtaskate,
    Text,
} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import ButtonGeneral from '@General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {VisibilityOn} from '@components/SignUp/Icons/VisibilityOn';
import {VisibilityOff} from '@components/SignUp/Icons/VisibilityOff';
import {
    catTypeBusiness,
    catTypePlaces,
} from '@components/SignUp/Redux/Actions/catPlaceAndBusinessAction';
import {validateEmailExist} from '@components/SignUp/Redux/Actions/validateEmailAction';
import {infoCompanyBenefit} from '@components/SignUp/Redux/Actions/companyBenefitAction';

export const CreateAccount = () => {
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const defaultValues = useSelector((state: RootState) => state.saveDataSingUp.accountData);

    const loading = useSelector((state: RootState) => state.validateEmailAdministrator.loading);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        watch,
    } = useForm<ISignUpData>({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const onsubmit = (data: ISignUpData) => {
        dispatch(validateEmailExist(data, setError));
    };

    const disabledButton = () => {
        const valuesForm = watch();
        const values = Object.values(valuesForm);
        return values.every((value) => value.length > 2);
    };

    useEffect(() => {
        dispatch(catTypeBusiness());
        dispatch(catTypePlaces());
        dispatch(infoCompanyBenefit());
    }, []);

    return (
        <>
            <ContainerCellGrid Gap="8px" FlexDir="column" Align="center" Width="34vw">
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                    Crear tu cuenta
                </Text>
                <Text Color="#2A2C2F" FontSize="1rem">
                    Cuéntanos un poco sobre ti
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="16px" Justify="space-between" Width="34vw">
                <ContainerCellGrid Gap="4px" Width="16.5vw" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        Nombre(s)
                    </Text>
                    <Input {...register('names')} error={errors.names?.message} Cursor="text" />
                    {errors.names?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.names.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid Gap="4px" Width="16.5vw" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        Apellido(s)
                    </Text>
                    <Input
                        {...register('secondsNames')}
                        error={errors.secondsNames?.message}
                        Cursor="text"
                    />
                    {errors.secondsNames?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.secondsNames.message}
                        </Text>
                    )}
                </ContainerCellGrid>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    Correo electrónico
                </Text>
                <Input
                    {...register('email')}
                    error={errors.email?.message}
                    Cursor="text"
                    autoComplete="off"
                />
                {errors.email?.message && (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                        {errors.email.message}
                    </Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    Contraseña
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    FontSize="1rem"
                    error={errors.password?.message}
                >
                    <input
                        type={viewPassword ? 'text' : 'password'}
                        {...register('password')}
                        autoComplete="off"
                    />
                    <ContainerImg onClick={() => setViewPassword(!viewPassword)}>
                        {viewPassword ? <VisibilityOn /> : <VisibilityOff />}
                    </ContainerImg>
                </SearchInput>
                {errors.password?.message && (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                        {errors.password.message}
                    </Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    Confirma la contraseña
                </Text>
                <Input
                    {...register('confirmPassword')}
                    type={viewPassword ? 'text' : 'password'}
                    Cursor="text"
                    error={errors.confirmPassword?.message}
                    autoComplete="off"
                />
                {errors.confirmPassword?.message && (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                        {errors.confirmPassword.message}
                    </Text>
                )}
            </ContainerCellGrid>
            {loading ? (
                <LoadingAtaskate />
            ) : (
                <ButtonGeneral
                    text="Continuar"
                    width="100%"
                    cursor="pointer"
                    clic={handleSubmit(onsubmit)}
                    disabled={!disabledButton()}
                />
            )}
            <LinkAtaskate
                Color="#5A5AFF"
                FontWeight="500"
                Cursor="pointer"
                to={'/Login/Administrator'}
            >
                Ya tengo una cuentra
            </LinkAtaskate>
        </>
    );
};
