import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ADD_PERSONAL_CREDIT_TO_CREDITS,
    REMOVE_PERSONAL_CREDIT_IN_CREDITS,
    ADD_FAST_PAYMENT_ERROR,
    ADD_FAST_PAYMENT_START,
    ADD_FAST_PAYMENT_SUCCESS,
    RESET_FAST_PAYMENT,
} from '@components/QuickPayment/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IPersonalCredit, IAddFastPaymentEndPoint} from '@components/QuickPayment/interfaces';

export const addPersonalCreditToCredits = (result: IPersonalCredit) => {
    return {
        type: ADD_PERSONAL_CREDIT_TO_CREDITS,
        payload: result,
    };
};

export const removePersonalCreditInCredits = (result: IPersonalCredit) => {
    return {
        type: REMOVE_PERSONAL_CREDIT_IN_CREDITS,
        payload: result,
    };
};

export const resetAddFastPayment = () => {
    return {
        type: RESET_FAST_PAYMENT,
    };
};

const addFastPaymentStart = () => {
    return {
        type: ADD_FAST_PAYMENT_START,
    };
};

const addFastPaymentSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_FAST_PAYMENT_SUCCESS,
        payload: result,
    };
};

const addFastPaymentError = (error: AxiosError) => {
    return {
        type: ADD_FAST_PAYMENT_ERROR,
        error: error,
    };
};

export const addFastPaymentAction = (paymentData: IAddFastPaymentEndPoint) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddFastPayment}`;
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(addFastPaymentStart());
        try {
            const response = await axios.post(url, paymentData, {headers});
            dispatch(addFastPaymentSuccess(response.data));
        } catch (e) {
            dispatch(addFastPaymentError(e as AxiosError));
        }
    };
};
