import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_COMPANY, VERIFY_FIELD} from '@MyCompany/constants';
import {updateCompany} from '@MyCompany/updateCompany';
import {UpdateCompanyForm} from '@MyCompany/UpdateCompanyForm';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {IContacts, IFormCompany, IUpdateDataCompany} from '@MyCompany/Interface';

export const UpdateDataCompany = ({
    updateDataCompany,
    setUpdateDataCompany,
}: IUpdateDataCompany) => {
    const initialContact = {mail: [1], phones: [1]};
    const [contact, setContact] = useState<IContacts>(initialContact);
    const {
        handleSubmit,
        register,
        reset,
        formState: {errors},
    } = useForm<IFormCompany>({
        defaultValues: {
            nameEnterprice: '',
            naturalEnterprice: '',
            mail: [],
            phones: [],
        },
        resolver: yupResolver(updateCompany),
    });
    const cancelOperation = () => {
        setUpdateDataCompany(false);
        setContact(initialContact);
        reset();
    };

    const onSubmit = () => cancelOperation();

    return (
        <Modal
            Top="0"
            modalState={updateDataCompany}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                    {UPDATE_COMPANY.DATA_COMPANY}
                </Text>
                <UpdateCompanyForm
                    register={register}
                    errors={errors}
                    contact={contact}
                    setContact={setContact}
                />
                {errors &&
                    (errors.nameEnterprice ||
                        errors.naturalEnterprice ||
                        errors.mail?.length ||
                        errors.phones?.length) && (
                        <Text Self="center" Color="#FF6357">
                            {VERIFY_FIELD}
                        </Text>
                    )}
                <ContainerFlex Height="auto" Gap="1.5rem">
                    <Text
                        Color="#FF6357"
                        Width="100%"
                        Cursor="pointer"
                        Justify="center"
                        onClick={() => cancelOperation()}
                    >
                        {UPDATE_COMPANY.CANCEL}
                    </Text>
                    <ButtonGeneral width="100%" text={UPDATE_COMPANY.SAVE} />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
