import React, {ChangeEventHandler, forwardRef} from 'react';
import IconCheck from '@images/check.svg';
import IconCheckDisabled from '@images/DisableCheck.svg';
import {
    CheckedBox,
    InputCheckbox,
    StyledCheckbox,
} from '@components/PersonalLoans/NewLoanProduct/styles';

interface CheckboxProps {
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    name?: string;
    border?: string;
    backgroundColor?: string;
    id?: string;
}

export const CheckboxSelect = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const {onChange, disabled, name, checked, id, ...rest} = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <StyledCheckbox>
            <InputCheckbox
                {...rest}
                type="checkbox"
                name={name}
                ref={ref}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
                id={id}
            />
            <CheckedBox src={!disabled ? IconCheck : IconCheckDisabled} />
        </StyledCheckbox>
    );
});
