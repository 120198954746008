import React, {useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';
import {Controller, FieldValues, useForm} from 'react-hook-form';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {
    FormContainer,
    contentProperties,
    spanProperties,
} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {DeadlineGeneral} from '@CreateDeadline/interfaces';
import {StyleNumericFormat} from '@Loan/styles';
import {QuitNumberFormat, limitNumberFormat} from '@/hooks/currentFormatUtils';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import ButtonGeneral from '@/components/General/Atoms/Button';
import Swal from 'sweetalert2';
import {
    amountsAndTimes,
    setStep,
    tableAmounts,
} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import {inputProperties, interested} from '@/components/DeadLines/CreateDeadline/redux/Constants';
import {TableAmounts} from '@DeadLines/CreateDeadline/DeadlineSteps/AmountAndTimes/TableAmounts';

export const AmountsAndTimes = (props: DeadlineGeneral) => {
    const {deadlinesGeneral, deadlineById} = props;
    const dispatch: AppDispatch = useDispatch();
    const [dinamicInterest, setDinamicInterest] = useState(false);
    const {handleSubmit, reset, control, watch} = useForm({
        defaultValues: {
            minimumLoanAmount: '',
            maximumLoanAmount: '',
            quantityGarments: '',
            marketingPercentage: '',
            loanPercentage: '',
            negotiationPercentage: '',
        },
    });
    const values = watch();

    const resetValues = (data: FieldValues) => {
        const rest = {
            minimumLoanAmount: String(data.minimumLoanAmount),
            maximumLoanAmount: String(data.maximumLoanAmount),
            quantityGarments: String(data.quantityGarments),
            loanPercentage: String(data.loanPercentage),
            negotiationPercentage: String(data.negotiationPercentage),
        };
        if (!dinamicInterest)
            reset({...rest, marketingPercentage: String(data.marketingPercentage)});
        if (dinamicInterest) reset({...rest});
    };

    const onSubmit = (data: FieldValues) => {
        if (QuitNumberFormat(data.minimumLoanAmount) <= QuitNumberFormat(data.maximumLoanAmount)) {
            const json = {
                minimumLoanAmount: QuitNumberFormat(data.minimumLoanAmount),
                maximumLoanAmount: QuitNumberFormat(data.maximumLoanAmount),
                quantityGarments: QuitNumberFormat(data.quantityGarments),
                negotiationPercentage: QuitNumberFormat(data.negotiationPercentage),
                loanPercentage: QuitNumberFormat(data.loanPercentage),
                dynamicAmount: dinamicInterest,
            };
            if (dinamicInterest) dispatch(amountsAndTimes(json));
            if (!dinamicInterest) {
                dispatch(tableAmounts([]));
                dispatch(
                    amountsAndTimes({
                        ...json,
                        marketingPercentage: QuitNumberFormat(data.marketingPercentage),
                    })
                );
            }
            dispatch(setStep(4));
        } else {
            Swal.fire({
                icon: 'error',
                text: `Monto minimo de prestamo debe ser menor a Monto máximo de prestamo`,
                confirmButtonText: 'Continuar',
            });
        }
    };
    const dinamicChange = () => {
        setDinamicInterest((prev: boolean) => !prev);
        resetValues(values);
        if (!dinamicInterest) dispatch(tableAmounts([]));
    };

    const validateAmounts = () => {
        let data = false;
        deadlinesGeneral.rangeInterest.map((item) => {
            return item.lowerLoanPercentage > item.higherLoanPercentage && (data = true);
        });
        return data;
    };

    const disableButton = () => {
        const disabledCommon =
            !values.minimumLoanAmount ||
            !values.maximumLoanAmount ||
            !values.quantityGarments ||
            !values.loanPercentage ||
            !values.negotiationPercentage;
        if (!dinamicInterest && (disabledCommon || !values.marketingPercentage)) return true;
        if (
            dinamicInterest &&
            (disabledCommon || deadlinesGeneral.rangeInterest.length === 0 || validateAmounts())
        )
            return true;
        return false;
    };

    const insertInfomationbyId = () => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            (!(
                deadlinesGeneral.amountTimes.minimumLoanAmount ||
                deadlinesGeneral.amountTimes.maximumLoanAmount ||
                deadlinesGeneral.amountTimes.quantityGarments ||
                deadlinesGeneral.amountTimes.negotiationPercentage ||
                deadlinesGeneral.amountTimes.loanPercentage
            ) ||
                deadlinesGeneral.rangeInterest.length === 0)
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            setDinamicInterest(vDeadlines.dynamicAmount);
            const json = {
                minimumLoanAmount: vDeadlines.minimumLoanAmount,
                maximumLoanAmount: vDeadlines.maximumLoanAmount,
                quantityGarments: vDeadlines.quantityGarments,
                negotiationPercentage: vDeadlines.negotiationPercentage,
                loanPercentage: vDeadlines.loanPercentage,
                dynamicAmount: vDeadlines.dynamicAmount,
            };
            if (vDeadlines.dynamicAmount) {
                dispatch(amountsAndTimes(json));
                const range = vDeadlines.rangeInterest.reduce(
                    (acc: interested<{order: number}>, item, index) => {
                        acc[index] = {order: index + 1, ...item};
                        return acc;
                    },
                    {}
                );
                dispatch(tableAmounts(Object.values(range)));
            }
            const jsonInsert = {...json, marketingPercentage: vDeadlines.marketingPercentage};
            if (!vDeadlines.dynamicAmount) dispatch(amountsAndTimes(jsonInsert));
            resetValues(jsonInsert);
        }
    };

    const resetInfoReload = () => {
        if (
            deadlinesGeneral.amountTimes.minimumLoanAmount &&
            (!deadlineById.edit || !deadlineById.copy)
        ) {
            setDinamicInterest(deadlinesGeneral.amountTimes.dynamicAmount);
            resetValues({
                ...deadlinesGeneral.amountTimes,
                marketingPercentage: deadlinesGeneral.amountTimes.marketingPercentage,
            });
        }
    };

    useEffect(() => {
        insertInfomationbyId();
        resetInfoReload();
    }, []);

    return (
        <ContainerFlex
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            onClick={() => {
                if (
                    deadlinesGeneral.amountTimes.minimumLoanAmount ||
                    deadlinesGeneral.interest.storageRate
                )
                    dispatch(setStep(3));
            }}
        >
            <FormContainer onSubmit={handleSubmit(onSubmit)} MarginTp="0" Width="100%">
                <TitleDeadline
                    title={'Montos y tiempos'}
                    step={deadlinesGeneral.step}
                    stepCurrent={3}
                    stepDependence={0}
                    dependence={
                        deadlinesGeneral.amountTimes.quantityGarments !== undefined ||
                        (deadlinesGeneral.rangeInterest?.length > 0 &&
                            deadlinesGeneral.amountTimes.dynamicAmount)
                    }
                />
                {deadlinesGeneral.step === 3 && (
                    <ContainerFlex FlexDir="column" Padding="0 0 1.5rem 0">
                        <ContainerFlex
                            Padding="1.5rem 1.5rem 0.25rem 1.5rem"
                            Justify="space-between"
                            FlexWrap="wrap"
                        >
                            <ContainerFlex {...contentProperties} Padding="0 0 1.5rem 0">
                                <Text {...spanProperties} required>
                                    Monto mínimo de préstamo
                                </Text>
                                <Controller
                                    name="minimumLoanAmount"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            {...inputProperties}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            maxLength={12}
                                            prefix="$ "
                                            placeholder="Monto mínimo de préstamo"
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex {...contentProperties} Padding="0 0 1.5rem 0">
                                <Text {...spanProperties} required>
                                    Monto máximo de préstamo
                                </Text>
                                <Controller
                                    name="maximumLoanAmount"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            {...inputProperties}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            maxLength={12}
                                            prefix="$ "
                                            placeholder="Monto máximo de préstamo"
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex {...contentProperties} Padding="0 0 1.5rem 0">
                                <Text {...spanProperties} required>
                                    Cantidad de prendas
                                </Text>
                                <Controller
                                    name="quantityGarments"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            {...inputProperties}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            placeholder="Cantidad de prendas"
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    11
                                                )
                                            }
                                            onKeyDown={(e: {
                                                keyCode: number;
                                                preventDefault: () => void;
                                            }) => {
                                                if (e.keyCode === 190) e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex {...contentProperties}>
                                <Text Color="#6d6e7" FontSize="0.75rem">
                                    ¿Montos dinámicos?
                                </Text>
                                <Text FontSize="0.875rem">
                                    No
                                    <FormControlLabel
                                        className="control-label"
                                        control={
                                            <GreenSwitch
                                                disabled={deadlineById.edit}
                                                checked={dinamicInterest}
                                                onChange={dinamicChange}
                                                size="small"
                                            />
                                        }
                                        label=""
                                    />
                                    Sí
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex {...contentProperties}>
                                <Text {...spanProperties} required>
                                    Porcentaje de préstamo
                                </Text>
                                <Controller
                                    name="loanPercentage"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            {...inputProperties}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            placeholder="Porcentaje de préstamo"
                                            suffix="%"
                                            isAllowed={(values) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex {...contentProperties}>
                                <Text {...spanProperties} required>
                                    Porcentaje de negociación
                                </Text>
                                <Controller
                                    name="negotiationPercentage"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            {...inputProperties}
                                            disabled={deadlineById.edit}
                                            type="text"
                                            placeholder="Porcentaje de negociación"
                                            suffix="%"
                                            isAllowed={(values) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            {!dinamicInterest && (
                                <ContainerFlex {...contentProperties}>
                                    <Text {...spanProperties} required>
                                        Porcentaje Comercialización
                                    </Text>
                                    <Controller
                                        name="marketingPercentage"
                                        control={control}
                                        render={({field: {ref, ...field}}) => (
                                            <StyleNumericFormat
                                                {...field}
                                                getInputRef={ref}
                                                {...inputProperties}
                                                disabled={deadlineById.edit}
                                                type="text"
                                                placeholder="Porcentaje Comercialización"
                                                suffix="%"
                                                isAllowed={(values) =>
                                                    limitNumberFormat(
                                                        QuitNumberFormat(values.value),
                                                        0,
                                                        1000
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                        {dinamicInterest && (
                            <TableAmounts
                                hasDynamicInterest={dinamicInterest}
                                deadlinesGeneral={deadlinesGeneral}
                                deadlineById={deadlineById}
                                validateAmounts={() => validateAmounts()}
                            />
                        )}
                        {!deadlineById.edit && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                hColor="#35cf44"
                                disabled={disableButton()}
                                clic={handleSubmit(onSubmit)}
                                text="Continuar"
                            />
                        )}
                    </ContainerFlex>
                )}
            </FormContainer>
        </ContainerFlex>
    );
};
