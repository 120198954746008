import axios from 'axios';

import {URL} from '@config/constants/index';

import {
    DEADLINE_SEARCH,
    LEVELS_DEADLINES,
} from '@DeadLines/DeadlinesIndex/redux/types/DeadlineTypes';
import {Dispatch} from 'redux';

export const ErrorSearch = (value: unknown) => {
    return {
        type: DEADLINE_SEARCH.ERROR,
        payload: value,
    };
};
export const TrueDeadlines = () => {
    return {
        type: DEADLINE_SEARCH.TRUE_LEVEL_DINAMICS,
    };
};
export const FalseDeadlines = () => {
    return {
        type: DEADLINE_SEARCH.FALSE_LEVEL_DINAMICS,
    };
};
export const SearchDeadlines = (value: unknown) => {
    return {
        type: DEADLINE_SEARCH.SEARCH_ARTICLE,
        payload: value,
    };
};

export const RestartSearchArticle = () => {
    return {
        type: DEADLINE_SEARCH.RESTART_SEARCH_ARTICLE,
    };
};
export const LevelSelected = (level: string) => {
    return {
        type: DEADLINE_SEARCH.LEVEL_SELECTED,
        payload: level,
    };
};
export const DeleteRouteItems = (value: number) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_DELETE_LEVEL_ROUTE,
        position: value,
    };
};
export const RestartRoute = () => {
    return {
        type: DEADLINE_SEARCH.RESTART_ROUTE,
    };
};
export const RouteDeadlines = (value: unknown) => {
    return {
        type: DEADLINE_SEARCH.ROUTE_ITEMS,
        payload: value,
    };
};
export const GetLevelId = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_GET_LEVEL,
        payload: value,
    };
};
export const AddRouteItems = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_ADD_ROUTE_ITEMS,
        payload: value,
    };
};
export const LastRouteItems = (value: unknown) => {
    return {
        type: LEVELS_DEADLINES.DEADLINE_LAST_ROUTE_ITEM,
        payload: value,
    };
};
export const SearchArticleFetch = (token: string, Search: string, BranchId: string) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SearchDeadlinesLevels.replace(
                    '{BranchId}',
                    BranchId
                ).replace('{Search}', Search)}`,
                {headers}
            );
            dispatch(RestartSearchArticle());
            dispatch(SearchDeadlines(response.data));
        } catch (error) {
            dispatch(ErrorSearch(error));
        }
    };
};
