export const GET_AUDITOR_BY_ASSIGNMENT = 'GET_AUDITOR_BY_ASSIGNMENT';
export const GET_AUDITOR_BY_ASSIGNMENT_SUCCESS = 'GET_AUDITOR_BY_ASSIGNMENT_SUCCESS';
export const GET_AUDITOR_BY_ASSIGNMENT_ERROR = 'GET_AUDITOR_BY_ASSIGNMENT_ERROR';

export const GET_STATUS_BY_ASSIGNMENT = 'GET_STATUS_BY_ASSIGNMENT';
export const GET_STATUS_BY_ASSIGNMENT_SUCCESS = 'GET_STATUS_BY_ASSIGNMENT_SUCCESS';
export const GET_STATUS_BY_ASSIGNMENT_ERROR = 'GET_STATUS_BY_ASSIGNMENT_ERROR';

export const GET_REGION_BY_ASSIGNMENT = 'GET_REGION_BY_ASSIGNMENT';
export const GET_REGION_BY_ASSIGNMENT_SUCCESS = 'GET_REGION_BY_ASSIGNMENT_SUCCESS';
export const GET_REGION_BY_ASSIGNMENT_ERROR = 'GET_REGION_BY_ASSIGNMENT_ERROR';

export const GET_SUCURSAL_BY_ASSIGNMENT = 'GET_SUCURSAL_BY_ASSIGNMENT';
export const GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS = 'GET_SUCURSAL_BY_ASSIGNMENT_SUCCESS';
export const GET_SUCURSAL_BY_ASSIGNMENT_ERROR = 'GET_SUCURSAL_BY_ASSIGNMENT_ERROR';

export const GET_DATERANGE_BY_ASSIGNMENT = 'GET_DATERANGE_BY_ASSIGNMENT';
export const GET_DATERANGE_BY_ASSIGNMENT_SUCCESS = 'GET_DATERANGE_BY_ASSIGNMENT_SUCCESS';
export const GET_DATERANGE_BY_ASSIGNMENT_ERROR = 'GET_DATERANGE_BY_ASSIGNMENT_ERROR';

export const GET_FILTERS_BY_ASSIGNMENT = 'GET_FILTERS_BY_ASSIGNMENT';
