import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';

import Success from '@images/Icon_Success.png';
import {useNavigate} from 'react-router-dom';
import {TableGrid} from '@AdminLocations/style';
import {ContainerArea, SubContainerArea} from '@CreateLocation/style';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {restartLocations} from '@AdminLocations/redux/actions/LocationAction';
import TreeLocations from '@AdminLocations/CreateLocation/Containers/TreeLocations';

const LocationSuccess = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    setTimeout(() => navigate('/NavLocations'), 5000);
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '#/CreateBranch', label: 'Sucursales', reset: () => dispatch(restartLocations(null))},
        {to: '#/NavLocations', label: 'Gestionar ubicaciones'},
        {to: '', label: 'Crear ubicación'},
    ];

    return (
        <ContainerFlex Padding="1rem 2.313rem" FlexDir="column" Align="space-between">
            <ContainerFlex PaddingB="2rem" Justify="start">
                <Breadcrumb fontSize="1.125rem" fontWeight="400" items={itemsBreadcrumb} />
            </ContainerFlex>
            <TableGrid>
                <TreeLocations />
                <ContainerFlex
                    Align="flex-start"
                    FlexDir="column"
                    Padding="0 2.125rem"
                    Height="95vh"
                >
                    <ContainerArea>
                        <SubContainerArea>
                            <Text FontSize="1rem" FontWeight="400">
                                Crear Ubicación
                            </Text>
                        </SubContainerArea>
                        <ContainerFlex
                            Justify="center"
                            Align="center"
                            FlexDir="column"
                            Padding="5rem"
                            Height="auto"
                        >
                            <ContainerFlex FlexDir="column" Width="50%">
                                <ContainerFlex Width="90px" Height="90px" Padding="5rem 0">
                                    <img src={Success} alt="succes" />
                                </ContainerFlex>
                                <ContainerFlex Padding="10px 0" FlexDir="column">
                                    <Text FontSize="1.25rem" wSpace="pre">
                                        Ubicación creada
                                    </Text>
                                    <Text FontSize="1.25rem" wSpace="pre">
                                        exitosamente
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerArea>
                </ContainerFlex>
            </TableGrid>
        </ContainerFlex>
    );
};
export default LocationSuccess;
