import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_STATUS_APPLICATION_VALIDATE_SUCCESS,
    GET_STATUS_APPLICATION_VALIDATE_START,
    GET_STATUS_APPLICATION_VALIDATE_ERROR,
} from '@components/PersonalLoans/CreditStatus/Redux/Types/Types';

const initialState = {
    data: {},
    loading: false,
    error: false,
};

const getStatusApplicationValidate = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_STATUS_APPLICATION_VALIDATE_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_STATUS_APPLICATION_VALIDATE_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_STATUS_APPLICATION_VALIDATE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getStatusApplicationValidate;
