import * as yup from 'yup';

export const SchemaLogin = yup.object().shape({
    email: yup
        .string()
        .email('Ingrese un correo electrónico válido')
        .required('El correo electrónico es requerido')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'El correo no tiene un formato valido'
        ),
    password: yup.string().required('La contraseña es requerida'),
});
