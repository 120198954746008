import * as yup from 'yup';

export const shemaCreateRole = yup.object().shape({
    checkAdmin: yup.boolean().required('Indique al menos un tipo de rol'),
    checkPOS: yup.boolean().required('Indique al menos un tipo de rol'),
    roleName: yup.string().when(['checkAdmin', 'checkPOS'], {
        is: (checkAdmin: boolean, checkPOS: boolean) => checkAdmin || checkPOS,
        then: (schema) => schema.required('Ingrese un nombre'),
        otherwise: (schema) => schema.optional(),
    }),
});
