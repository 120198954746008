import {
    VERIFY_CODE_REQUEST_CLIENT,
    VERIFY_CODE_SUCCESS_CLIENT,
    VERIFY_CODE_ERROR_CLIENT,
} from '@components/MarketForgottenLogin/Redux/types';

const initialState = {
    loading: false,
    error: null,
};

export const verifyCodeClient = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CODE_REQUEST_CLIENT:
            return {...state, loading: true, error: null};
        case VERIFY_CODE_SUCCESS_CLIENT:
            return {...state, loading: false, succeeded: true};
        case VERIFY_CODE_ERROR_CLIENT:
            return {...state, loading: false, error: action.payload.response.data};
        default:
            return state;
    }
};
