import {
    GET_ALLPAWN_DETAILS,
    GET_ALLPAWN_DETAILS_ERROR,
    GET_ALLPAWN_DETAILS_RESET,
    GET_ALLPAWN_DETAILS_SUCCESS,
} from '@components/TransactionsDetails/Redux/types';
import {IReducerType} from '@components/Branches/interfaces';
import {IPawnData} from '@components/TransactionsDetails/Redux/interface';

const initialState: IPawnData = {
    loading: false,
    error: false,
    catState: {
        customerName: '',
        customerNuc: 0,
        customerImageUrl: '',
        customerBalance: 0,
        contractTerm: '',
        contractNumber: '',
        pickUpDropOff: '',
        contractTotal: 0,
        contractVat: 0,
        contractSubtotal: 0,
        items: [],
        paymentMethods: [
            {
                id: 0,
                name: '',
                amount: 0,
            },
        ],
        inventory: {
            position: '',
            row: '',
            column: '',
        },
    },
};

const GetPawnDetails = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_ALLPAWN_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case GET_ALLPAWN_DETAILS_SUCCESS:
            return {
                ...state,
                catState: action.payload,
                loading: false,
            };
        case GET_ALLPAWN_DETAILS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_ALLPAWN_DETAILS_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetPawnDetails;
