export const SELECTED_ITEM_CREDITS = 'SELECTED_ITEM_CREDITS';

export const GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START = 'GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START';
export const GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS = 'GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS';
export const GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR = 'GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR';

export const GET_CREDIT_LOAN_DETAIL_BY_ID_START = 'GET_CREDIT_LOAN_DETAIL_BY_ID_START';
export const GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS = 'GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS';
export const GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR = 'GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR';

export const SHOPPING_CAR_CLIENT_START = 'GET_SHOPPING_CAR_CLIENT_START';
export const SHOPPING_CAR_CLIENT_ERROR = 'GET_SHOPPING_CAR_CLIENT_ERROR';
export const SHOPPING_CAR_CLIENT_SUCCESS = 'ADD_SHOPPING_CAR_CLIENT_SUCCESS';
export const GET_DETAILS_SHOPPING_CAR_SUCCESS = 'GET_DETAILS_SHOPPING_CAR_SUCCESS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SHOW_WARNING_MESSAGE = 'SHOW_WARNING_MESSAGE';

export const ADD_CREDIT_SELECTED = 'ADD_CREDIT_SELECTED';
export const REMOVE_CREDIT_SELECTED = 'REMOVE_CREDIT_SELECTED';
export const UNSELECT_CREDITS_SELECTED = 'UNSELECT_CREDITS_SELECTED';

export const GET_CREDIT_MOVEMENT_HISTORY_ERROR = 'GET_CREDIT_MOVEMENT_HISTORY_ERROR';
export const GET_CREDIT_MOVEMENT_HISTORY_START = 'GET_CREDIT_MOVEMENT_HISTORY_START';
export const GET_CREDIT_MOVEMENT_HISTORY_SUCCESS = 'GET_CREDIT_MOVEMENT_HISTORY_SUCCESS';
export const GET_SOLICITUDE_TERMS_DETAILS_ERROR = 'GET_SOLICITUDE_TERMS_DETAILS_ERROR';
export const GET_SOLICITUDE_TERMS_DETAILS_START = 'GET_SOLICITUDE_TERMS_DETAILS_START';
export const GET_SOLICITUDE_TERMS_DETAILS_SUCCESS = 'GET_SOLICITUDE_TERMS_DETAILS_SUCCESS';
export const CLEAR_DATA_TERMS_DETAILS = 'CLEAR_DATA_TERMS_DETAILS';

export const ADD_CARD = 'ADD_CARD';
export const CHANGE_DEFAULT_CARD = 'CHANGE_DEFAULT_CARD';
export const SET_CARD_ID = 'SET_CARD_ID';
export const DELETE_CARD = 'DELETE_CARD';

export const PAYMENT_METHOD_ERROR = 'PAYMENT_METHOD_ERROR';
export const PAYMENT_METHOD_START = 'PAYMENT_METHOD_START';
export const PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHOD_SUCCESS';
export const GET_CLIENT_CARDS_START = 'GET_CLIENT_CARDS_START';
export const GET_CLIENT_CARDS_SUCCESS = 'GET_CLIENT_CARDS_SUCCESS';
