import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {DropDownMenu} from '@General/MenuAmazon/ReutilizableComponent/FloatMenu';
import {MenuItem} from '@General/MenuAmazon/styles';
import visibility from '@images/visibility.svg';
import duplicate from '@images/duplicateOutline.svg';
import {MenuIcons} from '@Roles/styles';
import {
    deadlinesById,
    getCopy,
    getEdit,
} from '@CreateDeadline/redux/actions/GetAllPromotionServices';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {menuDeadline} from '@components/DeadLines/DeadlinesIndex/redux/Interface';

export const ContextualMenuDeadline = (props: menuDeadline) => {
    const {handleClickAway, contextualMenu, dinamicTop, id} = props;
    const dispatch = useDispatch();

        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const handleMenu = async (e: number) => {
        await dispatch(deadlinesById(String(e), token));
        window.location.href = '#/NewDeadline/RouteDeadline';
    };

    return (
        <DropDownMenu
            OutSideClick={handleClickAway}
            MenuDisplayStatus={contextualMenu}
            Position="absolute"
            Height="88px"
            Top={dinamicTop <= 3 ? '0' : '2rem'}
            Bottom="0"
            Width="7.688rem"
            Values={[]}
            Right="0.5rem"
            Valued={
                <>
                    <MenuItem
                        onClick={() => {
                            handleClickAway();
                            handleMenu(id);
                            dispatch(getEdit(true));
                        }}
                    >
                        <MenuIcons
                            className="material-icons"
                            Width="1.344rem"
                            Height="0.938rem"
                            src={visibility}
                        />
                        <Text FontSize="0.875rem" Color="#414042" Cursor="span">
                            Visualizar
                        </Text>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClickAway();
                            handleMenu(id);
                            dispatch(getCopy(true));
                        }}
                    >
                        <MenuIcons
                            className="material-icons"
                            Width="1.25rem"
                            Height="1.25rem"
                            src={duplicate}
                        />
                        <Text FontSize="0.875rem" Color="#414042" Cursor="span">
                            Duplicar
                        </Text>
                    </MenuItem>
                </>
            }
        />
    );
};
