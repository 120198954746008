import React from 'react';
import {useDispatch} from 'react-redux';
import {Routes, Route} from 'react-router-dom';

import {PrivateRoute} from '@routers/PrivateRoute';
import {PublicRoute} from '@routers/PublicRoute';
import {InterceptorApp} from '@/components/Login/Interceptor/Interceptor';
import {AdminContact} from '@/components/LoginClient/SubContainers/AdminContact';
import {LoginAdministrative} from '@Login/Administrative/LoginAdministrative';
import {AccoutVerification} from '@/components/Login/Operational/AccountVerification';
import {NewPassword} from '@/components/Login/PasswordForgotAdmin/ConfirmPass/NewPasswd';
import {LoginOperational} from '@/components/Login/Operational/index';

import {SignUp} from '@/components/SignUp';
import {VerifyMailPassword} from '@/components/Login/PasswordForgotAdmin/VerifyMailPassword';
import {DashboardRoutes} from '@/routers/DashboardRoutes';
import {LoginClient} from '@/components/Login/MarketPlaceLogin/LoginClient';
import {AccountContainer} from '@/components/Login/MarketPlaceLogin/AccountContainer';
import {CustomerPanelClient} from '@/components/MarketCustomerPanel/Modulos/CustomerPanelClient';
import {ResetAcountContainer} from '@/components/MarketForgottenLogin/ResetAccountContainer';
import {ResetAccountCode} from '@/components/MarketForgottenLogin/ResetAccountCode';
import {ResetPasswordClient} from '@/components/MarketForgottenLogin/ResetPasswordClient';
import {SuccesfulPassword} from '@/components/MarketForgottenLogin/SuccessfulConfirmation';
import {AccountContainerLogin} from '@components/MarketAccessLogin/AccountContainerLogin';
import {PlataformSelection} from '@/components/Login/Administrative/PlataformSelextion';
import {QuickPayment} from '@/components/QuickPayment/QuickPayment';
import {Elements} from '@/components/CashFlowActions/elemens';
import {StatusPayment} from '@/components/MyAccount/MyCredit/CreditDetails/StatusPayment';

export const AppRouter = () => {
    const dispatch = useDispatch();
    InterceptorApp(dispatch);

    return (
        <div>
            <Routes>
                <Route
                    path="Login/*"
                    element={
                        <PublicRoute>
                            <Routes>
                                <Route
                                    element={<AccoutVerification />}
                                    path="/AccountVerification"
                                />
                                <Route element={<LoginAdministrative />} path="/" />
                                <Route
                                    element={<PlataformSelection />}
                                    path="/platform-selection"
                                />

                                <Route element={<LoginClient />} path="/MarketClient" />
                                <Route element={<AccountContainer />} path="/MarketValide" />
                                <Route
                                    element={<CustomerPanelClient />}
                                    path="/CustomerMarketClient"
                                />
                                <Route element={<Elements />} path="/ButtonActions" />
                                <Route element={<ResetAcountContainer />} path="ResetAccount" />
                                <Route element={<ResetAccountCode />} path="RestoredPassword" />
                                <Route
                                    element={<ResetPasswordClient />}
                                    path="RestoredNewPassword"
                                />
                                <Route element={<SuccesfulPassword />} path="PasswordSouccess" />

                                <Route element={<LoginOperational />} path="/Employee" />
                                <Route element={<AccountContainerLogin />} path="/MarketUser" />
                                <Route element={<AccountContainerLogin />} path="/MarketUser" />
                                <Route element={<VerifyMailPassword />} path="/ForgotPassword" />
                                <Route element={<NewPassword />} path="/ResetAdminPassword/:user" />
                                <Route element={<AdminContact />} path="/AdminContact" />
                                <Route element={<QuickPayment />} path="/QuickPayment" />
                                <Route element={<StatusPayment />} path="/StatusPayment" />
                            </Routes>
                        </PublicRoute>
                    }
                />

                <Route
                    path="SignUp"
                    element={
                        <PublicRoute>
                            <SignUp />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <DashboardRoutes />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </div>
    );
};
