import axios, {AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';

import {IClientSorting} from '@Shopify/ListClient/interfaces';
import {URL} from '@config/constants';

import {
    ACTUAL_PAGE_CUSTOMER,
    CURRENT_TABLE,
    CUSTOMER_SEARCH,
    GET_CLIENT,
    GET_CLIENT_ERROR,
    GET_CLIENT_SUCESS,
    RESET_SEARCH_CLIENT,
    CLIENT_SORT,
} from '@TypesQouter/Types';
import StringUtils from '@/GenericScripts/utils';

export const client = () => {
    return {type: GET_CLIENT};
};
export const clientSucess = (result: AxiosResponse) => {
    return {
        type: GET_CLIENT_SUCESS,
        payload: result,
    };
};
export const clientSort = (sorting: IClientSorting) => ({
    type: CLIENT_SORT,
    payload: sorting,
});
export const clientError = () => {
    return {type: GET_CLIENT_ERROR};
};

export const searchClients = (
    token: string,
    filters: {
        searchName: string;
        PageNumber?: number;
        PageSize?: number;
        CompanyId: number;
        filterClients?: number | null;
        orderType?: number | null;
    }
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({
        ...filters,
    });
    return async (dispatch: AppDispatch) => {
        dispatch(client());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllClient}${queryString}`,
                {headers}
            );
            dispatch(clientSucess(response.data));
        } catch (error) {
            dispatch(clientError());
        }
    };
};

export const resetSearch = () => {
    return {type: RESET_SEARCH_CLIENT};
};

export const actualPageCustomer = (result: number) => {
    return {type: ACTUAL_PAGE_CUSTOMER, payload: result};
};

export const tableCustomer = (result: number) => {
    return {type: CURRENT_TABLE, payload: result};
};

export const searchCustomers = (result: string) => {
    return {type: CUSTOMER_SEARCH, payload: result};
};
