import {AppDispatch} from '@/config/store';

import {resetGetAllUsers} from '@/components/CreateUsers/Redux/Actions/GetAllUsers';
import {RestartRoute} from '@/components/Articles/Redux/Actions/RoutesArticles';
import {cancelQuoterOperation, logoutClient} from '@Quoter/Redux/Actions/StepsActions';
import {resetTable} from '@Roles/Redux/Actions/CreateRoles';
import {fetchAddCarrito} from '@Shopify/Products/redux/actions/ProductsShopifyActions';
import {resetOptionsUserNames} from '@/components/CreateUsers/Redux/Actions/GetListUserNames';
import {ResetRandomPassword} from '@/components/CreateUsers/Redux/Actions/PostChangePassword';
import {resetDataForm} from '@/components/CreateUsers/Redux/Actions/dataFormCreateUserAction';
import {resetPassQuoter} from '@/components/Quoter/Redux/Actions/StatusStepOne';
import {deleteDeadlines} from '@/components/DeadLines/DeadlinesIndex/redux/actions/DeadlineAction';
import {restartLocations} from '@/components/Locations/AdminLocations/redux/actions/LocationAction';
import {branchSelected} from '@/components/CashFlow/Reduxs/Actions/getNearByBranchesActions';
import {resetTree} from '@/components/General/BranchTree/Redux/Actions/getOrganizationsAction';
import {logoutUser} from '@/components/Login/Redux/Actions/GetValidateUsers';
import {restarQuoter} from '@/components/Quoter/Redux/Actions/saveEndeavors';
import {resetModulesPos} from '@/components/Home/HomeEmployee/Redux/Actions/getModulesPosAction';
import {resetTreeBranch} from '@/components/CreateUsers/Redux/Actions/TreeGroupsAndBranches';
import {resetUsers} from '@/components/CreateUsers/Redux/Actions/saveUser';
import {resetAllSavedLevels} from '@/components/Articles/Redux/Actions/SavedLevels';
import {ResetAllManagementLevel} from '@/components/Articles/Redux/Actions/SearchManagement';
import {resetSearchClient} from '@/components/ShoppingCart/Redux/Actions/SearchClientList';
import {resetLoansUsers} from '@/components/PersonalLoans/Redux/Actions/SaveLoansUser';
import {ListCatalogReset} from '@/components/Articles/Redux/Actions/GetNewCatalogId';
import {fetchRouteInfoReset} from '@/components/Articles/Redux/Actions/UpdateNewCatalogId';
import {fetchInfoListReset} from '@/components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {ResetDataStepLoans} from '@/components/PersonalLoans/Redux/Actions/DataStepLoans';
import {resetFilters} from '@/components/CashFlow/Redux/Actions/filtersPersistance';

export const logoutSesion = (dispatch: AppDispatch) => {
    dispatch(logoutUser());
    dispatch(RestartRoute());
    dispatch(deleteDeadlines());
    dispatch(restartLocations(null));
    dispatch(cancelQuoterOperation());
    dispatch(fetchAddCarrito());
    dispatch(logoutClient());
    dispatch(resetTable());
    dispatch(resetGetAllUsers());
    dispatch(resetOptionsUserNames());
    dispatch(ResetRandomPassword());
    dispatch(resetDataForm());
    dispatch(resetPassQuoter());
    dispatch(branchSelected(''));
    dispatch(resetTree());
    dispatch(restarQuoter());
    dispatch(resetModulesPos());
    dispatch(resetTreeBranch());
    dispatch(resetUsers());
    dispatch(resetAllSavedLevels());
    dispatch(ResetAllManagementLevel());
    dispatch(resetSearchClient());
    dispatch(resetLoansUsers());
    dispatch(ResetDataStepLoans());
    dispatch(fetchRouteInfoReset());
    dispatch(fetchInfoListReset());
    dispatch(ListCatalogReset());
    dispatch(resetFilters());
};
