export const GET_DATA_EXPENSES_FROM = 'GET_DATA_EXPENSES_FROM';
export const IMAGES_PAYMENT = 'IMAGES_PAYMENT';
export const IMAGES_PAYMENT_PROP = 'IMAGES_PAYMENT_PROP';
export const IMAGES_RECIEVE = 'IMAGES_RECIEVE';
export const IMAGES_RECIEVE_PROP = 'IMAGES_RECIEVE_PROP';

export const RESET_DATA_FORM = 'RESET_DATA_FORM';

export const GET_EXPENSE_INVOICE = 'GET_EXPENSE_INVOICE';
export const GET_EXPENSE_INVOICE_SUCCESS = 'GET_EXPENSE_INVOICE_SUCCED';
export const GET_EXPENSE_INVOICE_ERROR = 'GET_EXPENSE_INVOICE_ERROR';
