import React from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {MODAL_INFO_FOOTER} from '@/components/CreditsAndPawns/constants';
import {closeCustomerInformationModal} from '@/components/CreditsAndPawns/Redux/Actions/GetCustomerInformationActions';

const CustomerInfoModalFooter = () => {
    const dispatch: AppDispatch = useDispatch();
    const handleCloseModal = () => {
        dispatch(closeCustomerInformationModal());
    };

    return (
        <ContainerFlex Width="100%" Height="88PX" Padding="24px" Align="center" Justify="center">
            <ButtonGenerals
                SecondaryButton="#FFFFFF"
                Width="100%"
                Height="40px"
                Gap="8px"
                Padding="8px 16px"
                Justify="center"
                FontFamily="Nunito"
                FontSize="1.0rem"
                FontWeight="700"
                onClick={handleCloseModal}
            >
                {MODAL_INFO_FOOTER.CTA_CLOSE}
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default CustomerInfoModalFooter;
