import styled from '@emotion/styled';
import {
    IPropsContent,
    IPropsPeak,
    IPropsWindows,
} from '@/components/Shopify/OptionMenu/styles/interfacesStyles';

export const ContainerGeneral = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 0.5px solid #e4e7e9;
    border-right: 0.5px solid #e4e7e9;
    width: 100%;
    border-radius: 5px;
`;

export const Container = styled.div<IPropsContent>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: ${(props) => props.Width};
    height: 40px;
    margin: ${(props) => props.Margin};
    padding: ${(props) => props.Padding};
    font-size: 14px;
    font-weight: ${(props) => (props.Weight === true ? '600' : 'none')};
    background: ${(props) => (props.Bg === true ? '#ebfbed' : 'none')};
    &:last-child {
        border-top: 0px solid #e4e7e9;
        border-bottom: 0.5px solid #e4e7e9;
    }
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.Bg};
        background-color: #ebfbed;

        span {
            color: ${(props) => (props.ColorGreen ? '#35cf44' : '')};
        }
    }
`;
Container.defaultProps = {
    Padding: '0px',
    Margin: '0px',
    Width: '100%',
};

export const GoldenBoy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Windows = styled.div<IPropsWindows>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0px 0px 20px;
    border-bottom: ${(props) => props.BorderBottom};
    border-left: ${(props) => props.BorderSide};
    background: ${(props) => (props.Bg === true ? '#EFF0F2' : 'none')};
    width: 100%;
    height: 40px;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.Bgs};
    }
`;

Windows.defaultProps = {
    BorderBottom: '0.5px solid #E4E7E9',
    BorderSide: 'none',
};

export const Peak = styled.div<IPropsPeak>`
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    border-left: ${(props) => (props.BLeft === true ? '16px solid #eff0f2' : 'none')};
    background: ${(props) => (props.Bg === true ? '#ffffff' : 'none')};
    top: -28px;
    right: -218px;
    width: 100%;
    position: relative;
`;

export const Nav = styled.div`
    border-top: 0.5px solid #e4e7e9;
    width: 100%;
    border-radius: 5px;
`;
