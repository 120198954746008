import React, {useState} from 'react';
import {ContainerCellGrid, ContainerFlex, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {RESET_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import {Line} from '@/components/UserProfileResetPassword/Operational/styles';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {shemaResetPassword} from '@/components/UserProfileResetPassword/Operational/ValidateYup';
import {NewPassword} from '@/components/UserProfileResetPassword/Operational/interface';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {ErrorPassword} from '@/components/UserProfileResetPassword/ErrorMessagePass';
import {ErrorPasswordRepeat} from '@/components/UserProfileResetPassword/ErrorMesageRepeat';
import {ErrorMessagesPassword} from '@/components/UserProfileResetPassword/ErrorMessage';

export const ProfileUserResetPassword = () => {
    const [viewPassword] = useState(false);
    const {
        register,
        formState: {errors},
        watch,
    } = useForm<NewPassword>({
        resolver: yupResolver(shemaResetPassword),
        mode: 'onChange',
    });
    const password = watch('password');
    const repeatPassword = watch('repeatPassword');

    return (
        <ContainerFlex FlexDir="column" Padding="24px" Height="max-content" Gap="16px">
            <ContainerFlex Justify="start">
                <Text FontSize="1.5rem" FontWeight="700">
                    {RESET_PASSWORD.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
            >
                <ContainerFlex FlexDir="column" Align="start">
                    <Text FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                        {RESET_PASSWORD.TITLE_TWO}
                    </Text>
                    <Text FontWeight="400" Color="#2A2C2F">
                        {RESET_PASSWORD.INDICATION}
                    </Text>
                </ContainerFlex>
                <Line />
                <ContainerCellGrid Gap="32px" Justify="start" Align="start">
                    <ContainerFlex FlexDir="column" FontWeight="700" Align="start">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.TEX_PASS}
                        </Text>
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            error={errors.password?.message}
                            Radius="32px"
                        >
                            <input
                                type={viewPassword ? 'Text' : 'Password'}
                                {...register('password')}
                            />
                        </SearchInput>
                        {password && password.length > 0 && (
                            <ErrorPassword error={!!errors.password?.message} password={password} />
                        )}
                        {errors.password?.message && (!password || password.length === 0) && (
                            <ErrorMessagesPassword message={errors.password?.message} />
                        )}
                    </ContainerFlex>
                    <ContainerFlex Height="max-content" FlexDir="column" Align="start">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.REPEAT_PASS}
                        </Text>
                        <SearchInput
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            BoxShadowH=""
                            BoxShadowF=""
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            error={errors.repeatPassword?.message}
                            Radius="32px"
                        >
                            <input
                                type={viewPassword ? 'Text' : 'Password'}
                                {...register('repeatPassword')}
                            />
                        </SearchInput>
                        {repeatPassword && repeatPassword.length > 0 && (
                            <ErrorPasswordRepeat
                                error={!!errors.repeatPassword?.message}
                                password={password}
                            />
                        )}
                        {errors.repeatPassword?.message &&
                            (!repeatPassword || repeatPassword.length === 0) && (
                                <ErrorMessagesPassword message={errors.repeatPassword?.message} />
                            )}
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerFlex Justify="start">
                    <Text Cursor="pointer" FontWeight="700" Color="#5A5AFF" HColor="#0D166B">
                        {RESET_PASSWORD.GENERATE}
                    </Text>
                </ContainerFlex>
                <Line />
                <ContainerCellGrid Gap="16px">
                    <ContainerFlex Align="end" Justify="space-between">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.TEX_PIN}
                        </Text>
                        <GreenSwitch />
                    </ContainerFlex>
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontWeight="700">{RESET_PASSWORD.DATA_PIN}</Text>
                        <Input />
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
            >
                <ButtonGenerals Width="147px" SecondaryButton="#FFFFFF">
                    {RESET_PASSWORD.BTN}
                </ButtonGenerals>
                <ButtonGenerals Width="147px">{RESET_PASSWORD.BTN_KEEP}</ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};
