import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {ISearchAssigment} from '@Roles/AddRoles/interfaces';
import * as styles from '@/components/Quoter/stylesConstants';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Input} from '@components/NavBarPOS/SearchComponent/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {assignment} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';
import {GetRolesUser} from '@Roles/CreateRoles/Redux/Actions/UserRoles';

export const AssigmentRolesSearch = ({user, setUser, setValue}: ISearchAssigment) => {
    const RolesUsersError = useSelector((state: RootState) => state.GetEmployeeUserSearch.error);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const dispatch = useDispatch();

    const handleSearch = () => {
        if (user.actionSearch === 'search')
            setUser({...user, clickSearch: user.search, actionSearch: 'close'});
        if (user.actionSearch === 'close')
            setUser({...user, search: '', clickSearch: '', actionSearch: 'search'});
    };

    useEffect(() => {
        if (token && user.search.length > 2)
            dispatch(GetRolesUser({companyId: companyId, search: user.search}, token));
    }, [user.clickSearch, user.search]);

    useEffect(() => {
        setValue('userId', user.checked);
        dispatch(assignment(user.checked));
    }, [user.checked]);

    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                Height="2.5rem"
                Radius="2rem"
                Self="start"
                Border="1px solid #E8E9EA"
                GridColumns="90% 10%"
                Padding="0.5rem 1rem 0.5rem 0rem"
            >
                <Input
                    type="input"
                    placeholder="Buscar"
                    value={user.search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUser({
                            ...user,
                            search: e.target.value,
                            actionSearch: e.target.value ? 'close' : 'search',
                        })
                    }
                />
                <Icons {...styles.iconSearch} onClick={() => handleSearch()}>
                    {user.actionSearch}
                </Icons>
            </ContainerFlex>
            {RolesUsersError === true && (
                <ContainerFlex
                    Position="absolute"
                    PositionTop="16rem"
                    backG="#FFFFFF"
                    ZIndex="101"
                    Width="38.31rem"
                    Height="2.69rem"
                    Padding="12px 8px"
                    Border="1px solid #E8E9EA"
                >
                    <Text Color="#54575C" FontWeight="500" wSpace="pre">
                        No existen más usuarios,{' '}
                        <Text
                            Color="#5A5AFF"
                            FontWeight="500"
                            wSpace="pre"
                            Cursor="pointer"
                            onClick={() => (window.location.href = '#/Users/')}
                        >
                            crea usuarios{' '}
                        </Text>
                        primero y asigna su rol.
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
