import {AxiosError, AxiosResponse} from 'axios';
import {
    EMPLOYEE_USER_SEARCH_ERROR,
    EMPLOYEE_USER_SEARCH_START,
    EMPLOYEE_USER_SEARCH_SUCCESS,
    USER_ASSIGNMENT_ROLES_ERROR,
    USER_ASSIGNMENT_ROLES_START,
    USER_ASSIGNMENT_ROLES_SUCCESS,
} from '@Roles/Redux/Types/types';
import {SearchItemRole} from '@Roles/interface';

const initialState: SearchItemRole = {
    rolesSearch: null,
    roleAssignment: null,
    loading: false,
    error: false,
    errorData: null,
};

const GetEmployeeUserSearch = (
    state: SearchItemRole = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
) => {
    switch (action.type) {
        case EMPLOYEE_USER_SEARCH_START:
            return {...state, loading: true, error: false};
        case EMPLOYEE_USER_SEARCH_SUCCESS:
            return {...state, loading: false, error: false, rolesSearch: action.payload.data.data};
        case EMPLOYEE_USER_SEARCH_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        case USER_ASSIGNMENT_ROLES_START:
            return {...state, loading: true, error: false};
        case USER_ASSIGNMENT_ROLES_SUCCESS:
            return {...state, loading: false, error: false, roleAssignment: action.payload.data};
        case USER_ASSIGNMENT_ROLES_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};

export default GetEmployeeUserSearch;
