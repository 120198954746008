import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import {CLIENT_INFO} from '@/components/Loan/PaymentQuoter/constants';
import envelope from '@images/envelope.svg';
import telephone from '@images/telephone.svg';

const CardsPay = () => {
    const Contracts: IClienctContactPay = useSelector((state: RootState) => state.getCarContracts);

    return (
        <ContainerFlex
            Height="76px"
            Justify="start"
            Align="start"
            Radius="24px"
            Border="1px solid #E8E9EA"
            Padding="16px"
            ColumnGap="8px"
        >
            <Image src={Contracts.data.imgProfile} Height="38px" Width="38px" Radius="50%" />
            <ContainerFlex Justify="start" Align="start" Gap="4px" FlexDir="column">
                <Text FontSize="1rem" Color="#5A5AFF" FontWeight="500">
                    {Contracts.data.clientName}
                </Text>
                <ContainerFlex Justify="start" ColumnGap="8px">
                    <Text FontSize="0.75rem" Color="#71767D" FontWeight="500">
                        {CLIENT_INFO.NUC}: {Contracts.data.nuc}
                    </Text>
                    <Image src={envelope} Height="12px" Width="12px" />
                    <Text FontSize="0.75rem" Color="#71767D" FontWeight="500">
                        {Contracts.data.clientEmail}
                    </Text>
                    <Image src={telephone} Height="12px" Width="12px" />
                    <Text FontSize="0.75rem" Color="#71767D" FontWeight="500">
                        {Contracts.data.clientPhone}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CardsPay;
