import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {HeaderTitles} from '@CashFlow/constantsAtaskateFlow';
import {formatterDolar} from '@/hooks/currentFormatUtils';
import {IconContainer} from '@Quoter/styles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {PaginatedForTransactionTable} from '@CashFlow/PaginatedForTransactionTable';
import {getUserDetails} from '@components/CashFlow/Redux/Actions/GetUserDetails';
import {IUserFlowDetails} from '@components/CashFlow/interfaces';

export const UsersFlowTable = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalItems = useSelector((state: RootState) => state.getUserDetails.totalItems);
    const totalPages = useSelector((state: RootState) => state.getUserDetails.totalPages);
    const dispatch: AppDispatch = useDispatch();

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );
    const currentBranch = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId ?? null,
        shallowEqual
    );

    useEffect(() => {
        if (currentBranch) dispatch(getUserDetails(currentBranch, 10, currentPage, token));
    }, []);

    const userData = useSelector(
        (state: RootState) => state.getUserDetails.userflowDetails as IUserFlowDetails[] | null,
        shallowEqual
    );
    const result = userData ? userData : [];

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Table>
            <TableRowHead
                BackG="none"
                GridColumn="repeat(7, 1fr)"
                Border="0 0 1px 0 solid red"
                Height="40px"
            >
                {HeaderTitles.map((data, index) => {
                    return (
                        <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700" key={index}>
                            {data.name}
                        </Text>
                    );
                })}
            </TableRowHead>

            <TableItemsContainer>
                {result.map((data, index) => {
                    return (
                        <TableRowItems
                            Height="40px"
                            GridColumn="repeat(7, 1fr)"
                            BorderB="0.5px solid #e4e7e9"
                            Border=""
                            key={index}
                        >
                            <TableItem Justify="start">
                                <Text FontSize="0.875rem" Color="#54575C">
                                    {data.userName}
                                </Text>
                            </TableItem>
                            <TableItem Justify="start">
                                <Text FontSize="0.875rem" Color="#54575C">
                                    {data.role[0]}
                                </Text>
                            </TableItem>
                            <TableItem Justify="start">
                                <ContainerFlex
                                    Radius="16px"
                                    backG={data.cashStatus ? '#E4F7EC' : '#F4F5F5'}
                                    Border={
                                        data.cashStatus ? '1px solid #B1E7C9' : '1px solid #D4D6D8'
                                    }
                                    Padding="2px 8px"
                                    Width="63px"
                                    Height="18px"
                                >
                                    <Text
                                        FontSize="0.75rem"
                                        FontWeight="700"
                                        Color={data.cashStatus ? '#54575C' : '#174A2E'}
                                    >
                                        {data.cashStatus ? 'Abierta' : 'Cerrada'}
                                    </Text>
                                </ContainerFlex>
                            </TableItem>
                            <TableItem />
                            <TableItem Justify="start">
                                <Text FontSize="0.875rem" Color="#54575C">
                                    {formatterDolar.format(data.income)}
                                </Text>
                            </TableItem>
                            <TableItem Justify="start">
                                <Text FontSize="0.875rem" Color="#54575C">
                                    {formatterDolar.format(data.expenses)}
                                </Text>
                            </TableItem>
                            <TableItem Justify="end" Gap="24px">
                                <Text FontSize="0.875rem" Color="#54575C">
                                    {formatterDolar.format(data.totalBox)}
                                </Text>
                                <IconContainer FontSize="0.875rem" className="material-icons">
                                    more_vert
                                </IconContainer>
                            </TableItem>
                        </TableRowItems>
                    );
                })}
                {result.length === 0 ? (
                    <div />
                ) : (
                    <ContainerFlex Justify="center">
                        <PaginatedForTransactionTable
                            firstPage={() => setCurrentPage(1)}
                            lastPage={() => setCurrentPage(totalPages)}
                            pageNumber={currentPage}
                            totalPage={totalPages}
                            totalItems={totalItems}
                            nextPage={handleNextPage}
                            prevPage={handlePrevPage}
                        />
                    </ContainerFlex>
                )}
            </TableItemsContainer>
        </Table>
    );
};
