import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import {IContract, cartContracts} from '@components/ShoppingCart/interface';
import StandbySuccess from '@images/estados.svg';
import StandbyClock from '@images/standbyclock.svg';
import StandbyClockBlue from '@images/standbyclock_blue.svg';
import ArrowRight from '@images/chevronRight.svg';
import {changeActualStep} from '@Quoter/Redux/Actions/saveEndeavors';
import {ImagePledge, ImageProdcutsProps} from '@Quoter/CoownerBeneficiary/interface';
import {COOWNER_BENEFICIARY} from '@components/Quoter/CoownerBeneficiary/constants';
import {ImagePledgesComponent} from '@Quoter/CoownerBeneficiary/ImagesPledges';
import {setSelectedPledge} from '@Quoter/CoownerBeneficiary/Redux/Actions/UploadArticleImages';

export const NewUploadImageProducts = ({isCheck, setIsCheck, SaveCoOwners}: ImageProdcutsProps) => {
    const dispatch = useDispatch();
    const [imagePledges, setImagePledges] = useState<ImagePledge[]>([]);
    const [imagesRender, setImagesRender] = useState<{fileName: string; formFile: string}[]>([]);
    const [imageToReplace, setImageToReplace] = useState<{
        pledgeId: number;
        fileName: string;
    } | null>(null);

    const {idContract} = useSelector((state: RootState) => state.saveEndeavor);
    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const selectedPledge = useSelector(
        (state: RootState) => state.SelectedPledgeReducer.dataPledge
    );

    const handleSelectPledge = (pledge: IContract) => {
        dispatch(setSelectedPledge(pledge));
        handleImageToRender(pledge.pledgeId);
    };

    const handleImageToRender = (pledgeId: number) => {
        const currentImages =
            imagePledges.find((pledge) => pledge.pledgeId === pledgeId)?.photosPladges || [];
        setImagesRender(currentImages.filter((img) => img.fileSize < 10 * 1024 * 1024));
    };

    useEffect(() => {
        if (contracts && contracts.length > 0) {
            const selectedContract = contracts.find(
                (contract) => contract.contractId === idContract
            );
            if (selectedContract) {
                dispatch(setSelectedPledge(selectedContract.pledges[0]));
                handleImageToRender(selectedContract.pledges[0].pledgeId);
            }
        }
    }, [idContract]);

    useEffect(() => {
        if (selectedPledge) {
            handleImageToRender(selectedPledge.pledgeId);
        }
    }, [imagePledges, selectedPledge]);

    const isSelectedPledgeWithImages = (pledgeId: number) => {
        const pledge =
            imagePledges.length > 0 && imagePledges.find((pledge) => pledge.pledgeId === pledgeId);
        return pledge && pledge.photosPladges.length > 0;
    };

    return (
        <>
            <ContainerFlex
                Height="auto"
                Justify="start"
                Align="start"
                FlexDir="column"
                Padding="1.25rem"
                Gap="2.5rem"
                Border="solid 1px #E8E9EA"
                Radius="24px"
            >
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                    Tomar y/o subir imágenes
                </Text>
                <ContainerFlex Justify="space-around" backG="#FAFAFA" Padding="0.5rem 1rem">
                    <ContainerFlex Justify="start" Gap="1.5rem">
                        {contracts &&
                            contracts.length > 0 &&
                            contracts.map((contract) =>
                                contract.pledges.map(
                                    (pledge) =>
                                        contract.contractId === idContract && (
                                            <ContainerFlex
                                                key={pledge.pledgeId}
                                                Border={
                                                    selectedPledge &&
                                                    selectedPledge.pledgeId === pledge.pledgeId
                                                        ? '1.5px solid #5A5AFF'
                                                        : '1.5px solid #E5E5FF'
                                                }
                                                Width="150px"
                                                Height="40px"
                                                Radius="8px"
                                                Justify="start"
                                                Padding="0.5rem"
                                                Gap="0.688rem"
                                                backG={
                                                    selectedPledge &&
                                                    selectedPledge.pledgeId === pledge.pledgeId
                                                        ? '#E5E5FF'
                                                        : '#FFFFFF'
                                                }
                                                Cursor="pointer"
                                                onClick={() => handleSelectPledge(pledge)}
                                            >
                                                {isSelectedPledgeWithImages(pledge.pledgeId) ? (
                                                    <ImgLocation
                                                        src={StandbySuccess}
                                                        Width="24px"
                                                        Height="24px"
                                                        Cursor="pointer"
                                                    />
                                                ) : (
                                                    <ImgLocation
                                                        src={
                                                            pledge.pledgeId ===
                                                            selectedPledge?.pledgeId
                                                                ? StandbyClockBlue
                                                                : StandbyClock
                                                        }
                                                        Width="24px"
                                                        Height="24px"
                                                        Cursor="pointer"
                                                    />
                                                )}
                                                <Text
                                                    Cursor="pointer"
                                                    Color="#0D166B"
                                                    FontSize="0.875rem"
                                                    FontWeight="500"
                                                >
                                                    {pledge.articleName}
                                                </Text>
                                            </ContainerFlex>
                                        )
                                )
                            )}
                    </ContainerFlex>
                    <ContainerFlex
                        Width="32px"
                        Height="32px"
                        Cursor="pointer"
                        Radius="50%"
                        backG="#FFFFFF"
                    >
                        <ImgLocation src={ArrowRight} Cursor="pointer" />
                    </ContainerFlex>
                </ContainerFlex>
                <ImagePledgesComponent
                    imagePledges={imagePledges}
                    setImagePledges={setImagePledges}
                    imagesRender={imagesRender}
                    imageToReplace={imageToReplace}
                    setImageToReplace={setImageToReplace}
                    selectedPledge={selectedPledge}
                    setSelectedPledge={(pledge) => dispatch(setSelectedPledge(pledge))}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    setImagesRender={setImagesRender}
                />
            </ContainerFlex>

            <ContainerFlex Gap="1rem">
                <ContainerFlex
                    Radius="32px"
                    Border="1px solid #5A5AFF"
                    Padding="8px 16px"
                    Width="auto"
                    Cursor="pointer"
                    onClick={() => dispatch(changeActualStep(1))}
                >
                    <Text FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                        {COOWNER_BENEFICIARY.Cancel}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Radius="32px"
                    backG="#5A5AFF"
                    Padding="8px 16px"
                    Width="auto"
                    Cursor="pointer"
                    onClick={() => SaveCoOwners()}
                >
                    <Text FontWeight="500" Color="#FFF" Cursor="pointer">
                        {COOWNER_BENEFICIARY.Finish}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
