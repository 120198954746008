import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState, AppDispatch} from '@config/store';
import {ObtainSucursalIDAction} from '@ActionsAuditAssignment/AuditAssignmentAction';
import {Tabletr} from '@AuditAssignment/styles';
export const RowSucursalTable = (props: {
    sucursal: string;
    region: string;
    prendas: number;
    costo: number;
    idsucursal: number;
}) => {
    const {sucursal, region, prendas, costo, idsucursal} = props;
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    const ConfirmIDSucursal = (id: number) => {
        dispatch(ObtainSucursalIDAction(id, 5, 0, token));
    };

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Tabletr onClick={() => ConfirmIDSucursal(idsucursal)}>
            <td>{sucursal}</td>
            <td>{region}</td>
            <td>{prendas}</td>
            <td>{formatterDolar.format(costo)}</td>
        </Tabletr>
    );
};
