import React, {useState} from 'react';
import {RootState, AppDispatch} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@Breadcrumb/Index';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {OrganzationLevelModals} from '@SubContainerOrgCreation/OrganzationLevelModals';
import {OrganizationTable} from '@SubContainerOrgCreation/OrganizationTable';
import {SearchOrganization} from '@SubContainerOrgCreation/SearchOrganization';
import {saveLevelNodes} from '@ActionsOrgCreation/LevelTree';
import {IconContainer} from '@/components/Quoter/styles';
import {IsEditable, fetchNewPassOrganization} from '@ActionsOrgCreation/GetAllLevels';
import {addLevelPath} from '@SubContainerOrgCreation/TreeTableUtils/LevelsAddFunctions';

export const CreateOrganization = () => {
    const Levels = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/', label: 'Inicio'},
        {to: '', label: 'Organizaciones'},
    ];
    const dispatch: AppDispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const PageSize = useSelector((state: RootState) => {
        return state.GetStatmentData.pageNumber;
    });
    const [currentPage, setCurrentPage] = useState(PageSize);
    return (
        <ContainerFlex Padding="24px 26px" Align="flex-start" FlexDir="column">
            <Breadcrumb items={itemsBreadcrumb} />
            <ContainerFlex Justify="space-between" Align="end">
                <SearchOrganization setCurrentPage={setCurrentPage} />
                <ContainerFlex Justify="flex-end" Padding="0 0 12px 0">
                    <Text
                        Color="#35cf44"
                        Cursor="pointer"
                        FontSize=".875rem"
                        onClick={() => {
                            dispatch(saveLevelNodes(addLevelPath(Levels.node)));
                            dispatch(fetchNewPassOrganization(1));
                            dispatch(IsEditable(false));
                            setShowModal(true);
                        }}
                    >
                        Crear nivel organizacional
                    </Text>
                    <IconContainer
                        FontSize=".875rem"
                        className="material-icons"
                        Color="#35cf44"
                        onClick={() => {
                            dispatch(saveLevelNodes(addLevelPath(Levels.node)));
                            dispatch(fetchNewPassOrganization(1));
                            setShowModal(true);
                        }}
                    >
                        keyboard_arrow_right
                    </IconContainer>
                </ContainerFlex>
            </ContainerFlex>
            <OrganizationTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <OrganzationLevelModals
                modalState={showModal}
                changeModalState={setShowModal}
                setCurrentPage={setCurrentPage}
            />
        </ContainerFlex>
    );
};
