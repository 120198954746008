import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@components/LoginClient/styles';
import breadCrumbImg from '@images/arrowBreadCrumb.svg';

export const BreadCrumb = () => {
    return (
        <ContainerFlex Justify="start" Gap="10px">
            <Text>Casa de Empeño</Text>
            <ImageLogo src={breadCrumbImg} Height="8px" Width="8px" />
            <Text FontWeight="700">Pase almoneda</Text>
        </ContainerFlex>
    );
};
