import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';

import {
    ADD_DEADLINE,
    ADD_DEADLINE_SUCCESS,
    ADD_DEADLINE_ERROR,
    PUT_DEADLINE,
    PUT_DEADLINE_SUCCESS,
    PUT_DEADLINE_ERROR,
    DELETE_DEADLINE,
    DELETE_DEADLINE_SUCCESS,
    DELETE_DEADLINE_ERROR,
    GET_DEADLINE_ID,
    GET_DEADLINE_ID_SUCCESS,
    GET_DEADLINE_ID_ERROR,
    GET_DEADLINE_BRANCH,
    GET_DEADLINE_BRANCH_SUCCESS,
    GET_DEADLINE_BRANCH_ERROR,
    ISEDIT,
    ISCOPY,
    DELETEDEALINE,
} from '@ReduxCreateDeadline/types/PromotionsTypes';
import StringUtils from '@GenericScripts/utils';
import {createDeadline, updateDeadline} from '@CreateDeadline/interfaces';
import {Dispatch} from 'redux';
import {setStep} from '@components/DeadLines/CreateDeadline/redux/actions/DeadlinesGeneral';

export const postAddDeadline = () => {
    return {
        type: ADD_DEADLINE,
    };
};
export const postAddDeadlineSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: ADD_DEADLINE_SUCCESS,
        payload: result,
    };
};

export const postAddDeadlineError = () => {
    return {
        type: ADD_DEADLINE_ERROR,
    };
};

export const PostDeadlines = (filters: createDeadline, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(postAddDeadline());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostDeadline}`,
                filters,
                {headers}
            );
            dispatch(postAddDeadlineSuccess(response.data));
        } catch (error) {
            dispatch(postAddDeadlineError());
            dispatch(setStep(8));
        }
    };
};

export const putUpdateDeadline = (filters: updateDeadline) => {
    return {
        type: PUT_DEADLINE,
        payload: filters,
    };
};
export const putUpdateDeadlineSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: PUT_DEADLINE_SUCCESS,
        payload: result,
    };
};

export const putUpdateDeadlineError = () => {
    return {
        type: PUT_DEADLINE_ERROR,
    };
};

export const putUpdateDeadlines = (filters: updateDeadline, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(putUpdateDeadline(filters));
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateDeadline}`,
                filters,
                {headers}
            );
            dispatch(putUpdateDeadlineSuccess(response.data));
        } catch (error) {
            dispatch(putUpdateDeadlineError());
        }
    };
};

export const deleteDeadline = () => {
    return {
        type: DELETE_DEADLINE,
    };
};
export const deleteDeadlineSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_DEADLINE_SUCCESS,
        payload: result,
    };
};

export const deleteDeadlineError = () => {
    return {
        type: DELETE_DEADLINE_ERROR,
    };
};

export const deleteDeadlines = (id: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(deleteDeadline());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteDeadline}`,
                {data: {id: id}, headers}
            );
            dispatch(deleteDeadlineSuccess(response.data));
        } catch (error) {
            dispatch(deleteDeadlineError());
        }
    };
};

export const getDeadlineById = () => {
    return {
        type: GET_DEADLINE_ID,
    };
};
export const getDeadlineByIdSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DEADLINE_ID_SUCCESS,
        payload: result,
    };
};

export const getEdit = (data: boolean) => {
    return {
        type: ISEDIT,
        payload: data,
    };
};

export const getCopy = (data: boolean) => {
    return {
        type: ISCOPY,
        payload: data,
    };
};

export const deleteDataDeadline = () => {
    return {
        type: DELETEDEALINE,
    };
};

export const getDeadlineByIdError = () => {
    return {
        type: GET_DEADLINE_ID_ERROR,
    };
};

export const deadlinesById = (data: string, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        dispatch(getDeadlineById());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPromotionsById.replace(
                    '{Id}',
                    data
                )}`,
                {headers}
            );
            dispatch(getDeadlineByIdSuccess(response));
        } catch (error) {
            dispatch(getDeadlineByIdError());
        }
    };
};

export const getDeadlineBranchById = () => {
    return {
        type: GET_DEADLINE_BRANCH,
    };
};
export const getDeadlineBranchByIdSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: GET_DEADLINE_BRANCH_SUCCESS,
        payload: result,
    };
};

export const getDeadlineBranchByIdError = () => {
    return {
        type: GET_DEADLINE_BRANCH_ERROR,
    };
};

export const getDeadlineBranchesById = (
    filters: {BranchId: number; Article: number},
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
    };
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: Dispatch) => {
        dispatch(getDeadlineBranchById());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPromotionsBranchIdArticle}${queryString}`,
                {headers}
            );
            dispatch(getDeadlineBranchByIdSuccess(response.data));
        } catch (error) {
            dispatch(getDeadlineBranchByIdError());
        }
    };
};
