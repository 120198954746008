import * as yup from 'yup';
export const getValidationSchema = (dateFilter: boolean, hourFilter: boolean) => {
    return yup.object().shape({
        movimentType: yup
            .object()
            .shape({
                value: yup.number().required('Campo obligatorio'),
                label: yup.string().required('Campo obligatorio'),
            })
            .required('Campo obligatorio'),
        transactionType: yup
            .object()
            .shape({
                value: yup.number().required('Campo obligatorio'),
                label: yup.string().required('Campo obligatorio'),
            })
            .required('Campo obligatorio'),
        date: dateFilter ? yup.string().required('Campo obligatorio') : yup.string(),
        firstHour: hourFilter
            ? yup
                  .object()
                  .shape({
                      value: yup.number().required('Campo obligatorio'),
                      label: yup.string().required('Campo obligatorio'),
                  })
                  .required('Campo obligatorio')
            : yup.object().shape({
                  value: yup.number(),
                  label: yup.string(),
              }),
        secondHour: hourFilter
            ? yup
                  .object()
                  .shape({
                      value: yup.number().required('Campo obligatorio'),
                      label: yup.string().required('Campo obligatorio'),
                  })
                  .required('Campo obligatorio')
            : yup.object().shape({
                  value: yup.number(),
                  label: yup.string(),
              }),
    });
};
