import {HeadTablePurity} from '@Foundry/Purity/HeadTablePurity';
import React, {useState, useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {getPurity} from '@ActionsPurity/PurityActions';
import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {RowPurity} from '@Purity/RowPurity';
import {FilterPurity} from '@Purity/FilterPurity';

export function Purity(): JSX.Element {
    const handlePageClickHistory = (data: {selected: number}) => {
        const selected = data.selected;
        dispatch(getPurity(purity.filters, 10, selected, token));
    };
    const dispatch: AppDispatch = useDispatch();
    const [filterbool, setFilterbool] = useState(false);
    useEffect(() => {
        if (token) {
            const getTablePurity = () => dispatch(getPurity(purity.filters, 10, 0, token));
            getTablePurity();
        }
    }, [dispatch]);

    const purity = useSelector((state: RootState) => {
        return state.purity;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    return (
        <div className="container-primary">
            <div className="pt-2 pb-2 flex align-items-center">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Pureza</span>
            </div>
            <div
                className="flex width-100 mt-4 flex-end-button cursor"
                onClick={() => setFilterbool(!filterbool)}
            >
                <i className="material-icons mr-2 ">filter_list</i>
                Filtrar
            </div>
            {filterbool && <FilterPurity />}
            <div className="table-contai">
                <table>
                    <HeadTablePurity />

                    {purity.loading && (
                        <div className="w-90">
                            <LoadingGeneral />
                        </div>
                    )}
                    {purity.purity &&
                        purity.purity.map(
                            (
                                assignments: JSX.IntrinsicAttributes & {
                                    noFundicion: number;
                                    costoTotalPaquetes: number;
                                    estatus: string;
                                    purezaPromedio: number;
                                    gramosOroFino: number;
                                    subtotal: number;
                                    porcentajeComision: number;
                                    comision: number;
                                    precioOro: number;
                                    lingotes: number;
                                    precioDolar: number;
                                    fecha: Date;
                                    gramo: number;
                                    onzasOroFino: number;
                                    ganancia: number;
                                    total: number;
                                }
                            ) => <RowPurity key={assignments.noFundicion} {...assignments} />
                        )}
                </table>
            </div>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={purity.puritycount / 10}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={purity.purityPageIndex}
                onPageChange={handlePageClickHistory}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </div>
    );
}
