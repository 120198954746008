import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START,
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS,
    GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR,
    GET_CREDIT_LOAN_DETAIL_BY_ID_START,
    GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS,
    GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {IPersonalCreditDetailsReducer} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const initialState: IPersonalCreditDetailsReducer = {
    loading: false,
    error: null,
    personalCreditAmountData: null,
    creditLoanDetailData: null,
};

const GetPersonalCreditAmountReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_PERSONAL_CREDIT_AMOUNT_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case GET_PERSONAL_CREDIT_AMOUNT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                personalCreditAmountData: action.payload.data.data,
            };
        case GET_PERSONAL_CREDIT_AMOUNT_BY_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case GET_CREDIT_LOAN_DETAIL_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CREDIT_LOAN_DETAIL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                creditLoanDetailData: action.payload.data.data,
            };
        case GET_CREDIT_LOAN_DETAIL_BY_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default GetPersonalCreditAmountReducer;
