import React, {ChangeEvent, useState, useEffect, useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {validateDocumentImage} from '@Shopify/ClientProfile/Redux/Action/ValidClientIdImage';
import {
    IImageDocSelectionProps,
    ValidateDocumentImageData,
} from '@components/Shopify/ClientProfile/interfaces';
import {FileUpLoadContent} from '@Steps/styles';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import uploadIcon from '@images/arrowUpload.svg';
import {
    UPLOAD_DOCUMENTS,
    ALLOWED_EXTENSIONS,
    BORDER_DEFAULT,
    BORDER_ERROR,
} from '@components/Shopify/ClientProfile/constants';

const resetImageInfo = () => ({
    fileName: '',
    formFile: '',
    image: '',
    fileExtension: '',
    fileSize: '',
});

const ImageDocSelection: React.FC<IImageDocSelectionProps> = ({
    type,
    maxSize,
    setValue,
    errors,
    documentsInfo,
    setDocumentsInfo,
}) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [imageError, setImageError] = useState(false);
    const [uploadBorder, setUploadBorder] = useState('1px dashed #D4D6D8');

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const resetDocumentsInfo = () => {
        setDocumentsInfo((prevState) => ({
            ...prevState,
            [type === UPLOAD_DOCUMENTS.FRONT ? 'frontImage' : 'backImage']: resetImageInfo(),
        }));
    };

    const processFile = async (file: File) => {
        const maxSizeBytes = maxSize * 1024 * 1024;
        if (file.size > maxSizeBytes) {
            setImageError(true);
            resetDocumentsInfo();
            return;
        }

        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (!fileExtension || !ALLOWED_EXTENSIONS.includes(fileExtension)) {
            setImageError(true);
            resetDocumentsInfo();
            return;
        }

        setImageError(false);
        const fileSizeInBytes = (file.size / (1024 * 1024)).toFixed(2);

        const readFileAsDataURL = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });
        };

        try {
            const fileContent = await readFileAsDataURL(file);
            const base64Content = fileContent.split(',')[1];

            const imageData: ValidateDocumentImageData = {
                listImages: [
                    {
                        fileExtension: fileExtension,
                        fileContent: base64Content,
                    },
                ],
            };

            await dispatch(
                validateDocumentImage(
                    imageData,
                    type === UPLOAD_DOCUMENTS.FRONT ? 'frontImage' : 'backImage',
                    token
                )
            );
            setDocumentsInfo((prevState) => ({
                ...prevState,
                [type === UPLOAD_DOCUMENTS.FRONT ? 'frontImage' : 'backImage']: {
                    fileName: file.name,
                    formFile: base64Content,
                    image: fileContent,
                    fileExtension,
                    fileSize: fileSizeInBytes,
                },
            }));
            setValue(
                type === UPLOAD_DOCUMENTS.FRONT ? 'imageDocFront' : 'imageDocBack',
                file.name,
                {shouldValidate: true}
            );
        } catch (error) {
            setImageError(true);
        }
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            await processFile(file);
        }
    };

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLLabelElement>) => {
            event.preventDefault();
            if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                const file = event.dataTransfer.files[0];
                await processFile(file);
            }
        },
        [processFile]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        const hasBackError = type === UPLOAD_DOCUMENTS.BACK && errors?.imageDocBack?.message;
        const hasFrontError = type === UPLOAD_DOCUMENTS.FRONT && errors?.imageDocFront?.message;
        if (hasBackError || hasFrontError) {
            setUploadBorder(BORDER_ERROR);
        } else {
            setUploadBorder(BORDER_DEFAULT);
        }
    }, [errors?.imageDocBack?.message, errors?.imageDocFront?.message, type]);

    const imageSrc =
        type === UPLOAD_DOCUMENTS.FRONT
            ? documentsInfo.frontImage?.image
            : documentsInfo.backImage?.image;

    return (
        <>
            <input
                type="file"
                accept=".jpg,.png"
                style={{display: 'none'}}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            <FileUpLoadContent
                FDirection="column"
                Justify="center"
                Align="center"
                Width="16.938rem"
                Height="10.063rem"
                Border={uploadBorder}
                BackG="#FFF"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={(e) => e.preventDefault()}
            >
                {imageSrc ? (
                    <>
                        <Image
                            src={imageSrc}
                            Height="100%"
                            Width="100%"
                            ObjectFit="contain"
                            Radius="0"
                            Margin="0"
                            onClick={() => fileInputRef.current?.click()}
                        />
                    </>
                ) : (
                    <ContainerFlex Justify="center" Align="center" FlexDir="column" Gap="0.25rem">
                        <Image src={uploadIcon} width="24px" height="24px" />
                        <Text
                            Color="#2A2C2F"
                            FontWeight="400"
                            FontSize="1rem"
                            LetterSpacing="0.3px"
                        >
                            {UPLOAD_DOCUMENTS.DRAG_ELEMENTS_HERE}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontWeight="400"
                            FontSize="1rem"
                            LetterSpacing="0.3px"
                        >
                            {UPLOAD_DOCUMENTS.OR}
                        </Text>
                        <ButtonGenerals
                            Height="2rem"
                            Padding="0.25rem 1rem"
                            Justify="center"
                            FontWeight="700"
                            FontSize="0.875rem"
                            HBackG="#5A5AFF"
                            type="button"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            {UPLOAD_DOCUMENTS.SEARCH_FILES}
                        </ButtonGenerals>
                    </ContainerFlex>
                )}
            </FileUpLoadContent>
            {imageError && (
                <Text FontSize="1rem" Color="#FF6357" FontWeight="400">
                    {UPLOAD_DOCUMENTS.ADD_IMAGE_ERROR}
                </Text>
            )}
        </>
    );
};
export default ImageDocSelection;
