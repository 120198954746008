import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import ellipse from '@images/EllipseIcon.svg';
import {CART_MESSAGES} from '@Loan/PaymentQuoter/constants';
import {SHOPPING_CART} from '@/components/ShoppingCart/constants';
import {DATE_FORMATS} from '@/components/Shopify/ListClient/constants';
import {convertDate} from '@/components/Shopify/ClientProfile/constants';
import {formatDate, formatNum} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Text, Image, ContainerImg} from '@Shopify/Ecommerce/styles';
import {IClienCarDataMapping} from '@/components/Shopify/Products/redux/interface';
import {FlexPanel} from '@/components/Shopify/ClientProfile/Styles/styles';

export const ContractDetail = ({
    endorsementsPay,
    pawnsPay,
    dischargesPay,
}: IClienCarDataMapping) => {
    const branchId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.branchId
    );

    const contractData = () => {
        return {
            img: endorsementsPay?.imgURL || pawnsPay?.pledges[0].imgurl || dischargesPay?.imgURL,
            folio:
                endorsementsPay?.contractFolio ||
                pawnsPay?.contractName ||
                dischargesPay?.contractFolio,
            articleNumber:
                endorsementsPay?.pledgeNumber ||
                pawnsPay?.pledges.length ||
                dischargesPay?.pledgeNumber,
            nextDatePay:
                (endorsementsPay &&
                    formatDate(
                        convertDate(endorsementsPay.nextPaymentDate),
                        DATE_FORMATS.FULL_DATE
                    )) ||
                (pawnsPay && formatDate(convertDate(pawnsPay.dueDate), DATE_FORMATS.FULL_DATE)),
            subtotal:
                (endorsementsPay &&
                    formatNum({
                        number: endorsementsPay.subEndorsement,
                        fractionDigits: 0,
                        money: true,
                    })) ||
                (pawnsPay &&
                    formatNum({
                        number: pawnsPay.loan,
                        fractionDigits: 0,
                        money: true,
                    })) ||
                (dischargesPay &&
                    formatNum({
                        number: dischargesPay.subDischarge,
                        fractionDigits: 0,
                        money: true,
                    })),
        };
    };

    return (
        <ContainerFlex FlexDir="Column" Align="start">
            <FlexPanel
                Width="100%"
                Justify="start"
                Align="start"
                Padding="1rem 0.5rem"
                Gap="1.5rem"
                Bb="1px solid #E8E9EA"
                Bt="1px solid #E8E9EA"
            >
                <ContainerImg Width="2.113rem" Radius="0.25rem" Border="1px solid #E8E9EA">
                    <Image
                        src={contractData().img}
                        alt="cart-item"
                        height="37rem"
                        Width="33rem"
                        AspectRatio="1"
                        ObjectFit="contain"
                    />
                </ContainerImg>
                <ContainerFlex Align="start" FlexDir="column" Gap="0.5rem">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {`${CART_MESSAGES.CONTRACT}: ${branchId}C${contractData().folio}`}
                    </Text>
                    <ContainerFlex Justify="start" Gap="1.5rem">
                        <Text FontSize="0.875rem" Color="#2A2C2F" Gap="0.25rem">
                            <Image src={ellipse} alt="ellipse" Height="1.5rem" Width="1.5rem" />
                            {`${SHOPPING_CART.NO_GARMENTS} ${contractData().articleNumber}`}
                        </Text>
                        {!dischargesPay && (
                            <Text FontSize="0.875rem" Color="#2A2C2F" Gap="0.25rem">
                                {`${SHOPPING_CART.EXPIRATION_DATE} ${contractData().nextDatePay}`}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="end" Color="#54575C">
                    {contractData().subtotal}
                </ContainerFlex>
            </FlexPanel>
            {endorsementsPay && endorsementsPay.extension.numberDays !== 0 && (
                <Text Color="#1D1E20" Padding="0.5rem 0">
                    {`${SHOPPING_CART.EXTENSION} ${endorsementsPay?.extension.numberDays} ${
                        SHOPPING_CART.EXTENSION_DAYS
                    } ${formatDate(
                        convertDate(endorsementsPay.extension.nextPaymentDate),
                        DATE_FORMATS.FULL_DATE
                    )}`}
                </Text>
            )}
        </ContainerFlex>
    );
};
