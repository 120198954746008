import {
    ADD_CODE_MARKET_LOGIN,
    ADD_COUNT_MARKET_LOGIN,
    RESET_CODE_MARKET_LOGIN,
} from '@components/MarketLogin/redux/types/types';

const initialState = {
    count: 0,
    loading: false,
    code: ['', '', '', ''],
    error: null,
};

const codeValideReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CODE_MARKET_LOGIN:
            return {
                ...state,
                loading: true,
                error: null,
                code: action.payload,
            };
        case ADD_COUNT_MARKET_LOGIN:
            return {
                ...state,
                loading: false,
                error: null,
                count: action.payload,
            };
        case RESET_CODE_MARKET_LOGIN:
            return {
                ...state,
                loading: false,
                error: null,
                count: 30,
            };
        default:
            return state;
    }
};

export default codeValideReducer;
