import {SOL_STATEMENT} from '@ReduxsCashFlow/Types/tableTypes';

const initialState = {
    state: 1,
    loading: false,
    error: false,
};
const setRequest = (state = initialState, action: {type: string; payload: number}) => {
    switch (action.type) {
        case SOL_STATEMENT:
            return {
                ...state,
                state: action.payload,
            };
        default:
            return state;
    }
};

export default setRequest;
