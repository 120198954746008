import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import {RootState, AppDispatch} from '@config/store';

import {
    deleteSecurityBagWeightError,
    getSecurityBagWeightReview,
} from '@ActionsSecurityBagWeight/SecurityBagWeightActions';

export const RowSecurityBagReview = (props: {
    seguribolsa: number;
    papeleta: string;
    sucursal: string;
    fecha: string;
    costo: number;
}) => {
    const {seguribolsa, papeleta, sucursal, fecha, costo} = props;
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const EditAssignment = (id: number) => {
        setCount(0);
        setCount(id);
    };
    const cancelAssignment = (id: number) => {
        Swal.fire({
            title: 'Cancelar asignación',
            text: '¿Esta seguro que desea cancelar la asignación?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.value) {
                const json = {
                    idFundicion: Number(identify),
                    seguribolsa: id,
                };
                await dispatch(deleteSecurityBagWeightError(json, token));
                dispatch(getSecurityBagWeightReview('', 10, 0, identify, token));
            }
        });
    };
    const identify = useSelector((state: RootState) => {
        return state.securitybagweight.identifyfoundry;
    });
    const [count, setCount] = useState(0);
    return (
        <tr className="row-sucursal-tr" onClick={() => EditAssignment(seguribolsa)}>
            <td className="font-weight-bolder text-reset color-black">
                <Link to={`/PesoSeguribolsa/${seguribolsa}`}>
                    <span className="color-dark">{seguribolsa}</span>
                </Link>
            </td>
            <td>{papeleta}</td>
            <td>{sucursal}</td>
            <td>{DateTime.fromISO(fecha).toFormat('LL')}</td>
            {count === seguribolsa ? (
                <td onClick={() => cancelAssignment(seguribolsa)}>
                    <i className="material-icons icon-color">clear</i>{' '}
                    <span className="icon-color">Cancelar</span>
                </td>
            ) : (
                <td>{formatterDolar.format(costo)}</td>
            )}
        </tr>
    );
};
