import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {catPlaces} from '@components/SignUp/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {SELLCHANNEL} from '@components/LocationsBranch/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import * as style from '@components/LocationsBranch/stylesLocations';
import {IBusinessLines} from '@components/LocationsBranch/interfaces';
import {saveSalesChannels} from '@components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const SellChannels = ({setValue, errors, nameBusiness}: IBusinessLines) => {
    const dispatch = useDispatch();
    const {lines, business} = useSelector((state: RootState) => ({
        lines: state.groupBranchPersistence,
        business: state.catPlaceAndBusiness,
    }));

    const saveChannels = (id: number) => {
        if (lines.branchSalesChannel.includes(id))
            dispatch(saveSalesChannels(lines.branchSalesChannel.filter((i) => i !== id)));
        else dispatch(saveSalesChannels([...lines.branchSalesChannel, id]));
    };

    useEffect(() => setValue(nameBusiness, lines.branchSalesChannel), [lines.branchSalesChannel]);

    return (
        <ContainerFlex {...style.contentGral}>
            <Text {...style.contentGralTitle}>{SELLCHANNEL.TITLE}</Text>
            {business.catPlaces &&
                business.catPlaces.map((item: catPlaces) => (
                    <ContainerFlex
                        onClick={() => saveChannels(item.id)}
                        key={item.id}
                        Justify="start"
                        Gap="0.5rem"
                    >
                        <Checkbox checked={lines.branchSalesChannel.includes(item.id)} />
                        <Text Color="#2A2C2F">{item.description}</Text>
                    </ContainerFlex>
                ))}
            {errors[nameBusiness] && (
                <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                    {errors[nameBusiness]?.message as string}
                </Text>
            )}
        </ContainerFlex>
    );
};
