import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RESUMETYPEPAY} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {ResumeProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

export const ResumeTypePay = ({SetSolveResume}: ResumeProps) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Width="23.438rem"
            MinH="16.25rem"
            Height="16.25rem"
            FlexDir="column"
            backG="#FFFFFF"
            Padding="1.5rem"
            Border="solid 1px #0D166B"
            Radius="1.5rem"
            Gap="1.5rem"
        >
            <ContainerFlex Justify="space-between" Height="24px">
                <Text
                    oFlow="normal"
                    wSpace="normal"
                    FontSize="1.25rem"
                    FontWeight="700"
                    Color="#1D1E20"
                >
                    {RESUMETYPEPAY.TITLE}
                </Text>
                <Text
                    oFlow="normal"
                    wSpace="normal"
                    FontSize="0.875rem"
                    FontWeight="700"
                    Color="#5A5AFF"
                >
                    {RESUMETYPEPAY.EDIT}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                <ContainerFlex Justify="space-between" Height="24px">
                    <Text wSpace="normal" FontSize="1rem" Color="#2A2C2F">
                        {RESUMETYPEPAY.LOAN}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" Color="#2A2C2F">
                        {`$${RESUMETYPEPAY.VALUE}`}
                    </Text>
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
                <ContainerFlex Justify="space-between" Height="60px" Padding="0.5rem 0">
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        {RESUMETYPEPAY.RESUME}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        {`$${RESUMETYPEPAY.VALUE}`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ButtonGenerals
                Width="100%"
                Height="40px"
                Padding="8px 16px"
                onClick={() => SetSolveResume(true)}
                FontWeight="500"
            >
                {RESUMETYPEPAY.PAY}
            </ButtonGenerals>
        </ContainerFlex>
    );
};
