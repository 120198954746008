import React, {useState} from 'react';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@components/LoginClient/styles';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import DropdownCheckbox from '@components/AuctionPass/CheckBox';
import ConfirmButton from '@images/ConfirmButton.svg';
import editIcon from '@images/PencilIcon.svg';
import openTreeIcon from '@images/openTreeIcon.svg';
import {ICatalogItems, CatalogTreeProps} from '@components/AuctionPass/interfaces';

export const CatalogTree: React.FC<CatalogTreeProps> = ({
    item,
    editableItemId,
    handleEditClick,
}) => {
    const [expandedItems, setExpandedItems] = useState<{[key: number]: boolean}>({});

    const handleToggleExpand = (id: number) => {
        setExpandedItems((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const renderCatalogItem = (catalogItem: ICatalogItems, depth: number) => {
        const isExpanded = expandedItems[catalogItem.id] || false;
        const marginLeft = `${depth * 20}px`;

        return (
            <React.Fragment key={catalogItem.id}>
                <ContainerFlex
                    Height="4rem"
                    Bt="1px solid #E8E9EA"
                    onClick={() => handleToggleExpand(catalogItem.id)}
                    Cursor="pointer"
                    MarginL={marginLeft}
                    Justify="start"
                    Gap="15px"
                    MaxH="4rem"
                >
                    <ImageLogo
                        src={openTreeIcon}
                        Height="15px"
                        Width="15px"
                        Transform={isExpanded ? '' : 'rotate(0.25turn)'}
                        alt="OpenIcon"
                    />
                    {catalogItem.catalog}
                </ContainerFlex>
                <ContainerFlex Height="4rem" Justify="start" Bt="1px solid #E8E9EA" MinH="4rem">
                    {editableItemId === catalogItem.id ? (
                        <ContainerFlex>
                            <DropdownCheckbox />
                        </ContainerFlex>
                    ) : (
                        catalogItem.commercialization
                    )}
                </ContainerFlex>
                <ContainerFlex Height="4rem" Justify="start" Bt="1px solid #E8E9EA" MinH="4rem">
                    {editableItemId === catalogItem.id ? (
                        <Input Width="80%" type="number" />
                    ) : (
                        catalogItem.percentage
                    )}
                </ContainerFlex>
                <ContainerFlex Height="4rem" Justify="start" Bt="1px solid #E8E9EA" MinH="4rem">
                    {editableItemId === catalogItem.id ? (
                        <Input Width="70%" type="number" />
                    ) : (
                        catalogItem.daysOfPause
                    )}
                </ContainerFlex>
                <ContainerFlex Height="4rem" Justify="start" Bt="1px solid #E8E9EA" MinH="4rem">
                    <ImageLogo
                        src={editableItemId === catalogItem.id ? ConfirmButton : editIcon}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleEditClick(catalogItem.id);
                        }}
                        Height={editableItemId === catalogItem.id ? '30px' : '16px'}
                        Width={editableItemId === catalogItem.id ? '30px' : '16px'}
                        Position="relative"
                        Cursor="pointer"
                        alt="Edit"
                    />
                </ContainerFlex>
                {isExpanded &&
                    catalogItem.children &&
                    catalogItem.children.map((child) => renderCatalogItem(child, depth + 1))}
            </React.Fragment>
        );
    };

    return <React.Fragment>{renderCatalogItem(item, 0)}</React.Fragment>;
};
