import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import infoIconBlue from '@/components/CreditsAndPawns/icons/infoIconBlue.svg';
import infoIconRed from '@/components/CreditsAndPawns/icons/infoIconRed.svg';
import infoIconGreen from '@/components/CreditsAndPawns/icons/infoIconGreen.svg';
import infoIconYellow from '@/components/CreditsAndPawns/icons/infoIconYellow.svg';
import {
    IDetailsCreditScore,
    IDetailsCreditScoreState,
    IDetailsCreditScoreProps,
} from '@/components/CreditsAndPawns/interfaces';
import {StyledSVG} from '@/components/CreditsAndPawns/styles';
import {LIMITS_RANGES} from '@/components/CreditsAndPawns/constants';

const ScoreIndicator: React.FC<IDetailsCreditScoreProps> = ({score, scoreName, indicators}) => {
    const [scoreIndicatorObject, setScoreIndicatorObject] = useState<IDetailsCreditScore>({
        score: 0,
        scoreName: '',
        scoreColor: '#A82424',
        scoreColorBg: '#F7D3D3',
        infoIcon: infoIconRed,
        indicatorPositionX: 0,
        indicatorPositionY: 0,
        rotationAngle: 0,
    });

    useEffect(() => {
        const scoreAngleValue = score < 300 ? 300 : score > 850 ? 850 : score;
        const calculateAngle = () =>
            180 - (calculatePercentIndicatorScore(scoreAngleValue) / 100) * 180;
        const getScoreColor = (scoreName: string): IDetailsCreditScoreState => {
            switch (scoreName.toLocaleLowerCase()) {
                case 'regular':
                    return {
                        ...LIMITS_RANGES.REGULAR,
                        infoIconColor: infoIconYellow,
                    } as IDetailsCreditScoreState;
                case 'bueno':
                    return {
                        ...LIMITS_RANGES.GOOD,
                        infoIconColor: infoIconBlue,
                    } as IDetailsCreditScoreState;
                case 'excelente':
                    return {
                        ...LIMITS_RANGES.EXCELLENT,
                        infoIconColor: infoIconGreen,
                    } as IDetailsCreditScoreState;
                default:
                    return {
                        ...LIMITS_RANGES.BAD,
                        infoIconColor: infoIconRed,
                    } as IDetailsCreditScoreState;
            }
        };
        const angle = calculateAngle();

        const radius = 52;
        const x = radius + radius * Math.cos((angle * Math.PI) / 180);
        const y = radius - radius * Math.sin((angle * Math.PI) / 180);
        const {scoreColor, scoreColorBg, infoIconColor} = getScoreColor(scoreName);
        const calculateRotationAngle = (score: number) => {
            const ranges = [
                {...LIMITS_RANGES.BAD},
                {...LIMITS_RANGES.REGULAR},
                {...LIMITS_RANGES.GOOD},
                {...LIMITS_RANGES.EXCELLENT},
            ];

            for (const range of ranges) {
                if (score >= range.min && score <= range.max) {
                    const {min, max, startAngle, endAngle} = range;
                    const normalizedScore = (score - min) / (max - min);
                    return Math.floor(startAngle + normalizedScore * (endAngle - startAngle));
                }
            }
            return 0;
        };

        setScoreIndicatorObject({
            score,
            scoreName,
            scoreColor,
            scoreColorBg,
            infoIcon: infoIconColor,
            indicatorPositionX: x,
            indicatorPositionY: y,
            rotationAngle: calculateRotationAngle(scoreAngleValue),
        });
    }, []);

    const calculatePercentIndicatorScore = (score: number) => {
        let totalPercentage = 0;
        const ranges = indicators.map((indicator) => {
            const indicatorRange = indicator.indicatorRange
                .trim()
                .split('-')
                .map((indicator) => indicator.trim());
            return {
                min: parseInt(indicatorRange.shift() as string, 10),
                max: parseInt(indicatorRange.pop() as string, 10),
            };
        });
        ranges.forEach((range, i) => {
            const {min, max} = range;
            if (score >= min && score <= max) {
                const rangePercentage = ((score - min) / (max - min)) * 25;
                totalPercentage = Math.floor(i * 25 + rangePercentage);
            }
        });

        return totalPercentage;
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="center"
            Height="104px"
            Width="104px"
            Gap="7px"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="center" Position="relative">
                <StyledSVG width="104" height="104" viewBox="0 0 104 104">
                    <path
                        d="M1.82654 52C0.81777 52 -0.00334598 51.1816 0.0320549 50.1734C0.493042 37.0454 5.90992 24.551 15.2304 15.2304C24.9823 5.47856 38.2087 1.47959e-06 52 0C65.7913 -1.47959e-06 79.0177 5.47855 88.7695 15.2304C98.0901 24.551 103.507 37.0454 103.968 50.1734C104.003 51.1816 103.182 52 102.173 52C101.165 52 100.351 51.1815 100.312 50.1735C99.8532 38.0146 94.8218 26.4489 86.1864 17.8136C77.1196 8.74675 64.8224 3.65308 52 3.65308C39.1776 3.65308 26.8804 8.74676 17.8136 17.8136C9.17818 26.449 4.14681 38.0147 3.68756 50.1735C3.64948 51.1816 2.83531 52 1.82654 52Z"
                        fill="#F4F5F5"
                    />
                    <path
                        d="M15.4406 17.6374C14.7055 16.9465 13.5467 16.9805 12.8821 17.7394C4.97798 26.7635 0.43655 38.2465 0.0299033 50.2359C-0.00429162 51.2441 0.817807 52.0614 1.82658 52.0602C2.83535 52.059 3.64853 51.2397 3.6854 50.2316C4.08983 39.1736 8.27711 28.5861 15.5458 20.2429C16.2084 19.4822 16.1756 18.3283 15.4406 17.6374Z"
                        fill="#A82424"
                    />
                    <path
                        d="M51.1726 1.83336C51.156 0.82473 50.3242 0.0172203 49.3167 0.0692433C37.0791 0.701189 25.4547 5.63679 16.5007 14.0026C15.7636 14.6913 15.7669 15.8506 16.481 16.5631C17.1951 17.2755 18.3495 17.2712 19.0885 16.5845C27.3694 8.88859 38.0889 4.3372 49.3771 3.72425C50.3844 3.66955 51.1893 2.84199 51.1726 1.83336Z"
                        fill="#CC9200"
                    />
                    <path
                        d="M87.478 16.522C88.1913 15.8087 88.1932 14.6494 87.4553 13.9616C78.7051 5.80508 67.4069 0.914201 55.4714 0.115969C54.4648 0.0486542 53.6209 0.843442 53.5889 1.85171C53.557 2.85997 54.3492 3.69965 55.3555 3.76963C66.3627 4.53511 76.7796 9.0445 84.8706 16.5465C85.6103 17.2323 86.7647 17.2353 87.478 16.522Z"
                        fill="#008FCC"
                    />
                    <path
                        d="M102.173 52C103.182 52 104.003 51.1816 103.968 50.1735C103.546 38.1595 98.9727 26.6599 91.0285 17.6375C90.3618 16.8804 89.2029 16.8494 88.4697 17.5422C87.7364 18.235 87.7067 19.389 88.3713 20.1479C95.6771 28.4897 99.8939 39.0927 100.312 50.1736C100.351 51.1816 101.165 52 102.173 52Z"
                        fill="#309C60"
                    />
                </StyledSVG>
                <StyledSVG
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    SPosition="absolute"
                    SLeft={`${scoreIndicatorObject.indicatorPositionX - 11.5}`}
                    STop={`${scoreIndicatorObject.indicatorPositionY - 11.5}`}
                    STransformOrigin="center"
                    STransition="transform 0.5s"
                    STransform={`rotate(${scoreIndicatorObject.rotationAngle || 0}deg)`}
                >
                    <circle opacity="0.24" cx="11.5" cy="11.5" r="8" fill="#5A5AFF" />
                    <circle cx="11.5" cy="11.5" r="4" fill="#0D166B" />
                    <path
                        d="M0.5 22.3535C0.5 22.6297 0.723858 22.8535 1 22.8535L5.5 22.8535C5.77614 22.8535 6 22.6297 6 22.3535C6 22.0774 5.77614 21.8535 5.5 21.8535L1.5 21.8535L1.5 17.8535C1.5 17.5774 1.27614 17.3535 1 17.3535C0.723857 17.3535 0.5 17.5774 0.5 17.8535L0.5 22.3535ZM14.6464 7.99996L0.646447 22L1.35355 22.7071L15.3536 8.70707L14.6464 7.99996Z"
                        fill="#0D166B"
                    />
                </StyledSVG>

                <ContainerFlex Position="absolute" Justify="center" Align="center">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.5rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.8rem"
                        MarginTop="-10px"
                    >
                        {scoreIndicatorObject.score}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Width="82px"
                Height="33px"
                backG={scoreIndicatorObject.scoreColorBg}
                Padding="8px"
                Gap="8px"
                Radius="8px"
                MarginTop="-45px"
            >
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontStyle="normal"
                    FontSize="0.88rem"
                    LHeight="1.05rem"
                    Color={scoreIndicatorObject.scoreColor}
                >
                    {scoreIndicatorObject.scoreName}
                </Text>
                <Image Width="16px" Height="16px" src={scoreIndicatorObject.infoIcon} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ScoreIndicator;
