import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CustomerInfoModalEmployment from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalEmployment';
import CustomerInfoModalVouchers from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModalVouchers';
import {ICustomerInformation} from '@/components/CreditsAndPawns/interfaces';

const CustomerInfoModalBody: React.FC<ICustomerInformation> = ({
    employmentSituationName,
    address,
    job,
    monthlyIncome,
    company,
    phone,
    laborOld,
    archiveType,
}) => {
    const employmentData = {
        employmentSituationName,
        address,
        job,
        monthlyIncome,
        company,
        phone,
        laborOld,
    };
    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="start"
            Padding=" 0px 24px"
            Height="100%"
            Width="100%"
            OverFlowY="auto"
            Gap="16px"
        >
            <CustomerInfoModalEmployment {...employmentData} />
            <ContainerFlex backG="#E8E9EA" Height="1px" Width="100%" />
            <CustomerInfoModalVouchers vouchers={archiveType} />
        </ContainerFlex>
    );
};

export default CustomerInfoModalBody;
