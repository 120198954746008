import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RolesList} from '@Roles/interface';
import {MoreOptionsRoles} from '@Roles/MoreOptionRoles';
import MenuRolesAssignment from '@Roles/MenuRolesAssignment';
import {fetchRolesIdAssignment} from '@Roles/Redux/Actions/GetRolesInformation';
import {useDispatch} from 'react-redux';
import {LoadingAtaskate} from '@components/General/Atoms/LoadingAtaskate';
import {POPUPTEXTROLES} from '@Roles/constants';

const RolesFilters = ({searchRoles}: {searchRoles: () => void}) => {
    const {loading, userRolesId, rolesData} = useSelector(
        (state: RootState) => state.GetRolesInformation
    );
    const RolesErrorList = useSelector(
        (state: RootState) => state.GetRolesInformation.errorData?.response?.data as string
    );
    const RolesError = useSelector(
        (state: RootState) => state.GetRolesInformation.error as boolean
    );
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [openOptionRole, setOpenOptionRole] = useState<RolesList | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleOptionClick = (user: RolesList) => {
        setOpenOptionRole((prevUser) => (prevUser === user ? null : user));
    };
    return (
        <>
            {loading ? (
                <LoadingAtaskate />
            ) : (
                <>
                    {RolesError ? (
                        <ContainerFlex>
                            <Text Color="#54575C" FontWeight="500">
                                {RolesErrorList}
                            </Text>
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Position="relative" FlexDir="column" Height="auto">
                            {rolesData &&
                                rolesData.map((role: RolesList) => {
                                    const isAssign =
                                        !role.usersQuantity ||
                                        role.usersQuantity === POPUPTEXTROLES.ASSIGN;
                                    return (
                                        <ContainerFlex
                                            key={role.credentialsId}
                                            Position="relative"
                                            Display="grid"
                                            GridColumns="repeat(3, 1fr) 0.1fr"
                                            Justify="start"
                                            Height="2.5rem"
                                        >
                                            <Text
                                                Margin="0.5rem"
                                                MarginB="0.5rem"
                                                Color="#5A5AFF"
                                                FontSize="0.875rem"
                                                FontWeight="500"
                                            >
                                                {role.credentialsName}
                                            </Text>
                                            <ContainerFlex Justify="start" Gap="0.5rem">
                                                {role.rolNameAdmin && (
                                                    <Text
                                                        Justify="center"
                                                        Width="4.375rem"
                                                        Height="1.125rem"
                                                        BorderRadius="2rem"
                                                        FontSize="0.75rem"
                                                        FontWeight="500"
                                                        Padding="0.125rem 0.5rem"
                                                        Color="#000061"
                                                        bGround="#E5E5FF"
                                                        Border="1px solid #ACACFF"
                                                    >
                                                        {role.rolNameAdmin}
                                                    </Text>
                                                )}
                                                {role.rolNamePos && (
                                                    <Text
                                                        Justify="center"
                                                        Width="4.375rem"
                                                        Height="1.125rem"
                                                        BorderRadius="2rem"
                                                        FontSize="0.75rem"
                                                        FontWeight="500"
                                                        Padding="0.125rem 0.5rem"
                                                        Color="#174A2E"
                                                        bGround="#E4F7EC"
                                                        Border="1px solid #B1E7C9"
                                                    >
                                                        {role.rolNamePos}
                                                    </Text>
                                                )}
                                            </ContainerFlex>
                                            <Text
                                                Width="max-content"
                                                Cursor={isAssign ? 'pointer' : 'default'}
                                                Color={isAssign ? '#5A5AFF' : '#54575C'}
                                                FontSize="0.875rem"
                                                onClick={() => {
                                                    if (isAssign) {
                                                        setShowModal(true);
                                                        dispatch(fetchRolesIdAssignment(role));
                                                    }
                                                }}
                                            >
                                                {isAssign
                                                    ? POPUPTEXTROLES.ASSIGN
                                                    : role.usersQuantity}
                                            </Text>
                                            <MoreOptionsRoles
                                                role={role}
                                                openOptionRoles={role}
                                                setShowModal={setShowModal}
                                                isOpen={openOptionRole === role && isOpen}
                                                setIsOpen={setIsOpen}
                                                onOptionClick={() => handleOptionClick(role)}
                                            />
                                        </ContainerFlex>
                                    );
                                })}
                        </ContainerFlex>
                    )}
                    {userRolesId && (
                        <MenuRolesAssignment
                            showModal={showModal}
                            setShowModal={setShowModal}
                            roleId={userRolesId.credentialsId}
                            searchRoles={searchRoles}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default RolesFilters;
