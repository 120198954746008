import {HeadTableConsultAssignment} from '@Foundry/ConsultAssignment/HeadTableConsultAssignment';
import React, {useState, useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import {getConsultAssignment} from '@ConsultAssignment/redux/actions/ConsultAssignmentAction';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {LoadingGeneral} from '@General/Atoms/Loading/LoadingGeneral';
import {RowConsultAssignment} from '@ConsultAssignment/RowConsultAssignment';
import {FilterConsultAssignment} from '@ConsultAssignment/FilterConsultAssignment';
import {getDateRangeByAssignment} from '@ActionsConsultAssignment/ConsultAssignmentFilterAction';
import {ArrowAuction} from '@Auction/styles';
import {ContainerConsult, Filter, TableConsult} from '@ConsultAssignment/style';

export function ConsultAssignment(): JSX.Element {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const handlePageClickHistory = (data: {selected: number}) => {
        dispatch(getConsultAssignment(assignment.filters, 10, data.selected, token));
    };

    const dispatch: AppDispatch = useDispatch();
    const [filterbool, setFilterbool] = useState(false);

    useEffect(() => {
        if (token) {
            const getDateRange = () => dispatch(getDateRangeByAssignment(token));
            getDateRange();
        }
    }, [dispatch]);

    const assignment = useSelector((state: RootState) => {
        return state.assignment;
    });

    const daterangefilter = useSelector((state: RootState) => {
        return state.assignmentfilter.daterangefilter;
    });

    useEffect(() => {
        if (daterangefilter) {
            const getAssignmentTable = () =>
                dispatch(getConsultAssignment(daterangefilter, 10, 0, token));
            getAssignmentTable();
        } else {
            return;
        }
    }, [daterangefilter]);

    return (
        <ContainerConsult>
            <ArrowAuction>
                <span>Fundiciones</span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>Consulta de Auditoría</span>
            </ArrowAuction>
            <Filter onClick={() => setFilterbool(!filterbool)}>
                <i className="material-icons">filter_list</i>
                Filtrar
            </Filter>

            {filterbool && <FilterConsultAssignment />}
            <TableConsult>
                <HeadTableConsultAssignment />
                {assignment.loading === true ? (
                    <LoadingGeneral />
                ) : (
                    <tbody>
                        {assignment.assignment &&
                            assignment.assignment.map(
                                (
                                    assignments: JSX.IntrinsicAttributes & {
                                        noAsignacio: number;
                                        estatus: string;
                                        fundidora: string;
                                        fecha: string;
                                        auditor: string;
                                        noSeguribolsa: string;
                                        noPapeleta: string;
                                        noPrendas: number;
                                        costo: number;
                                        sucursal: string;
                                        region: string;
                                    }
                                ) => (
                                    <RowConsultAssignment
                                        key={assignments.noAsignacio}
                                        {...assignments}
                                    />
                                )
                            )}
                    </tbody>
                )}
            </TableConsult>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={assignment.assignmentcount / 10}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={assignment.assignmentPageIndex}
                onPageChange={handlePageClickHistory}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </ContainerConsult>
    );
}
