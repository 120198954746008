import {
    PUT_BRANCH_SCHEDULE,
    PUT_BRANCH_SCHEDULE_ERROR,
    PUT_BRANCH_SCHEDULE_SUCCESS,
    PUT_BRANCH_SCHEDULE_FINISH,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    updateSchedule: false,
};

const PutBranchSchedule = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case PUT_BRANCH_SCHEDULE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_BRANCH_SCHEDULE_SUCCESS:
            return {
                ...state,
                updateSchedule: action.payload.data,
            };
        case PUT_BRANCH_SCHEDULE_ERROR:
            return {
                ...state,
                error: true,
            };
        case PUT_BRANCH_SCHEDULE_FINISH:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default PutBranchSchedule;
