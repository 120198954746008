import React from 'react';
import {HeaderUserItems} from '@components/Branches/ManageBranch/BranchUsers/TableBranchUsers/HeaderUserItems';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import {Table, TableRowHead} from '@components/Shopify/Endeavors/styles';
import {ITableUserHeader} from '@/components/Branches/interfaces';
import {HeaderUserRow} from '@components/Branches/constants';

export const TableUserHeader = ({filters, setFilters}: ITableUserHeader) => {
    return (
        <ContainerFlex FlexDir="column">
            <Table>
                <TableRowHead
                    GridColumn="1.5fr 2.5fr 1fr"
                    Height="48px"
                    backG="#e5e7e9"
                    Radius="4px"
                >
                    {HeaderUserRow.map((data: {name: string; id: number; isOrder: boolean}) => (
                        <HeaderUserItems
                            key={data.id}
                            id={data.id}
                            name={data.name}
                            isOrder={data.isOrder}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    ))}
                </TableRowHead>
            </Table>
        </ContainerFlex>
    );
};
