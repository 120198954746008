import {CHANGEPRODUCTS, DEADLINES} from '@DeadLines/CreateDeadline/redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    initialInformation: {},
    interest: {},
    amountTimes: {},
    rangeInterest: [],
    gracePhase: [],
    deadline: [],
    deadlineSimulation: {},
    haveDiscount: false,
    promotionDiscount: [],
    possibleProductChanges: [],
    promotionExtensions: {},
    step: 1,
    starts: 0,
    error: false,
    loading: false,
};

const deadlinesGeneral = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case DEADLINES.DEADLINES_STATEINITIAL:
            return {
                ...state,
                initialInformation: {},
                interest: {},
                amountTimes: {},
                rangeInterest: [],
                gracePhase: [],
                deadline: [],
                deadlineSimulation: {},
                haveDiscount: false,
                promotionDiscount: [],
                possibleProductChanges: [],
                promotionExtensions: {},
                step: 1,
                starts: 0,
                error: false,
                loading: true,
            };
        case DEADLINES.INITIAL_INFORMATION:
            return {
                ...state,
                initialInformation: action.payload,
            };
        case DEADLINES.INTEREST:
            return {
                ...state,
                interest: action.payload,
            };
        case DEADLINES.AMOUNT_TIMES:
            return {
                ...state,
                amountTimes: action.payload,
            };
        case DEADLINES.AMOUNT_TABLE:
            return {
                ...state,
                rangeInterest: action.payload,
            };
        case DEADLINES.DEADLINE_TABLE:
            return {
                ...state,
                gracePhase: action.payload,
            };
        case DEADLINES.DEADLINES_SIMULATION:
            return {
                ...state,
                deadline: action.payload,
            };
        case DEADLINES.SIMULATION_EXAMPLE:
            return {
                ...state,
                deadlineSimulation: action.payload,
                loading: false,
            };
        case DEADLINES.DISCOUNTS:
            return {
                ...state,
                haveDiscount: action.payload,
            };
        case DEADLINES.DISCOUNTS_TABLE:
            return {
                ...state,
                promotionDiscount: action.payload,
            };
        case DEADLINES.CHANGES_PRODUCTS:
            return {
                ...state,
                possibleProductChanges: action.payload,
            };
        case CHANGEPRODUCTS.GET_PRODUCTS_EXCHANGE:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case CHANGEPRODUCTS.GET_PRODUCTS_EXCHANGE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DEADLINES.EXTENTIONS:
            return {
                ...state,
                promotionExtensions: action.payload,
            };
        case DEADLINES.STARTS:
            return {
                ...state,
                starts: action.payload,
            };
        case DEADLINES.DEADLINE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DEADLINES.STEP:
            return {
                ...state,
                step: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default deadlinesGeneral;
