import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {updateWarehouses} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {StorageData} from '@components/LocationsBranch/StorageData';
import {INITIAL_STORAGE, STORAGE} from '@components/LocationsBranch/constants';
import {IRow, IStorage, IWarehouseDetailProps} from '@components/LocationsBranch/interfaces';
import {EditWarehouseModal} from '@components/LocationsBranch/EditWarehouse';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';
import {ActionMenu} from '@components/LocationsBranch/Menu';
import AddIcon from '@images/addIcon.svg';
import HelpIcon from '@images/help.svg';

export const WarehouseDetail = ({
    warehouse,
    setSelectedStorage,
    onNewWarehouse,
}: IWarehouseDetailProps) => {
    const dispatch = useDispatch();
    const [showNewLevelButton, setShowNewLevelButton] = useState(false);
    const [showEditWarehouseModal, setShowEditWarehouseModal] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const storageTypes = warehouse.storageTypes || [];

    const onAddNewStorage = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        if (e.key === 'Enter') {
            const newId = storageTypes.length + 1;
            const newStorageType = JSON.parse(
                JSON.stringify({
                    ...INITIAL_STORAGE,
                    id: newId,
                    name: value,
                    parentLevel: warehouse.id,
                    warehouseId: warehouse.id,
                    storageFloor: [...getInitialFloor(1, warehouse.id, false)],
                })
            );
            warehouse.storageTypes = [...storageTypes, newStorageType];
            dispatch(updateWarehouses(warehouse));
            setShowNewLevelButton(false);
        }
    };

    const onNewLevel = () => {
        setShowNewLevelButton(true);
    };

    const onClickEdit = (storageType: IStorage | IRow) => {
        setSelectedStorage(JSON.parse(JSON.stringify(storageType)));
    };

    const onEditWarehouse = () => {
        setShowEditWarehouseModal(true);
    };

    return (
        <>
            <ContainerFlex
                backG="#FAFAFA"
                Gap="1rem"
                FlexDir="column"
                Padding="1rem 1.5rem 1rem 1.5rem"
                Align="start"
                Justify="start"
                Radius="1rem"
                MarginBt="1rem"
            >
                <ContainerFlex Justify="start" Align="start" Gap="0.5rem" Radius="1rem">
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                        {warehouse.name}
                    </Text>
                    <Text FontSize="0.75rem" MarginTop="0.313rem">
                        {STORAGE.LEVEL}0
                    </Text>{' '}
                    <ActionMenu warehouse={warehouse} onEditWarehouse={onEditWarehouse} />
                </ContainerFlex>
                <ContainerFlex
                    backG="#FFFFFF"
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="0.5rem"
                    Height="14rem"
                    Padding="0.5rem"
                    Radius="1rem"
                >
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {STORAGE.TYPE}
                        <Text FontSize="0.75rem" MarginLeft="0.5rem">
                            {STORAGE.LEVEL}
                            {'1'}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="0.5rem" Height="2.5rem" backG="#FFFFFF">
                        <Image src={AddIcon} alt="add" />
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            Cursor="pointer"
                            FontSize="1rem"
                            onClick={onNewLevel}
                        >
                            {STORAGE.ADD}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        FlexDir="column"
                        OverFlowY="auto"
                        Height={'none'}
                    >
                        {storageTypes &&
                            storageTypes.map((storageType, index) => (
                                <StorageData
                                    key={index}
                                    storageType={storageType}
                                    onClickEdit={onClickEdit}
                                />
                            ))}
                    </ContainerFlex>
                    {showNewLevelButton && (
                        <ContainerFlex
                            Position="relative"
                            FlexDir="column"
                            Height={'3.5rem'}
                            Width="21rem"
                        >
                            <SearchInput GridColumn="90% 10%" FontSize="1rem" Right="none">
                                <input onKeyDown={onAddNewStorage} ref={inputRef} autoFocus />
                                <Image src={HelpIcon} />
                            </SearchInput>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            {showEditWarehouseModal && (
                <EditWarehouseModal
                    setShowEditWarehouseModal={setShowEditWarehouseModal}
                    showEditWarehouseModal={showEditWarehouseModal}
                    warehouse={warehouse}
                />
            )}
        </>
    );
};
