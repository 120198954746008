import {PERSONAL_CREDIT} from '@Shopify/ClientProfile/Redux/types';
import {IPersonalCredit, IPersonalCreditRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IPersonalCredit = {
    error: false,
    loading: false,
    creditsData: {
        clientId: 0,
        totalLoan: 0,
        totalLoanBalance: 0,
        totalPayment: 0,
        personalCredits: [],
    },
};

const actionHandlers = {
    [PERSONAL_CREDIT.GET_START]: (state: IPersonalCredit) => ({
        ...state,
        loading: true,
        error: false,
    }),
    [PERSONAL_CREDIT.GET_SUCCESS]: (state: IPersonalCredit, action: IPersonalCreditRedux) => ({
        ...state,
        creditsData: action.creditsData,
        loading: false,
        error: false,
    }),
    [PERSONAL_CREDIT.GET_ERROR]: (state: IPersonalCredit) => ({
        ...state,
        loading: false,
        error: true,
    }),
};

const getPersonalCredit = (state = initialState, action: IPersonalCreditRedux): IPersonalCredit => {
    const handler = actionHandlers[action.type];
    return handler ? handler(state, action) : state;
};
export default getPersonalCredit;
