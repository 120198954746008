import {AppDispatch} from '@/config/store';
import {ADD_PAYMENT, ADD_PAYMENT_SUCCESS, ADD_PAYMENT_ERROR} from '@TypesLoan/types';
import {URL} from '@config/constants/index';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';

import axios, {AxiosResponse, AxiosError} from 'axios';
import {ContractResponse, IAddPayment} from '@components/Loan/Redux/interfaces';

export const payQuoter = () => {
    return {
        type: ADD_PAYMENT,
    };
};
export const payQuoterSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_PAYMENT_SUCCESS,
        payload: result,
    };
};
export const payQuoterError = (errorMessage: string) => {
    return {
        type: ADD_PAYMENT_ERROR,
        payload: errorMessage,
    };
};

export function addPayment(token: string, data: IAddPayment) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(payQuoter());
        try {
            const response = await axios.post<AxiosResponse<ContractResponse>>(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddPayment}`,
                data,
                {headers}
            );
            dispatch(payQuoterSuccess(response.data));
            return response.data;
        } catch (error: unknown) {
            const err = error as AxiosError;
            dispatch(toggleLoading(false));
            dispatch(payQuoterError(err.message));
            throw err;
        }
    };
}
