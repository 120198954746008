import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {Holidays} from '@/components/LocationsBranch/Holidays';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ScheduleGroup} from '@components/LocationsBranch/ScheduleGroup';
import {IHolidaysSchedules} from '@components/LocationsBranch/interfaces';
import {saveOpeningHoursAndHolidays} from '@/components/LocationsBranch/Redux/Action/GroupBranchPersistence';

export const HolidaysSchedule = ({
    register,
    control,
    errors,
    watch,
    setValue,
    reset,
    setShowHolidaysModal,
    isOptional,
    isEdit = false,
}: IHolidaysSchedules) => {
    const dispatch = useDispatch();
    const {openingHourAndHolidays} = useSelector(
        (state: RootState) => state.groupBranchPersistence
    );

    useEffect(
        () =>
            reset({
                ...watch(),
                openingHours: openingHourAndHolidays.openingHours,
                holidays: openingHourAndHolidays.holidays,
                switchHolidayGral: openingHourAndHolidays.switchHolidayGral,
            }),
        []
    );

    useEffect(() => {
        dispatch(
            saveOpeningHoursAndHolidays({
                openingHours: watch('openingHours'),
                holidays: watch('holidays'),
                switchHolidayGral: watch('switchHolidayGral'),
            })
        );
    }, [watch('openingHours'), watch('holidays'), watch('switchHolidayGral')]);

    return (
        <ContainerFlex {...style.contentGral}>
            <ScheduleGroup
                register={register}
                control={control}
                errors={errors}
                watch={watch}
                isOptional={isOptional}
                setValue={setValue}
                isEdit={isEdit}
            />
            <Holidays
                register={register}
                control={control}
                errors={errors}
                setShowHolidaysModal={setShowHolidaysModal}
                watch={watch}
                setValue={setValue}
            />
        </ContainerFlex>
    );
};
