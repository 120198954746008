import {ResponseData} from '@/components/ManageUser/interface';
import {
    GET_CLIENT,
    GET_CLIENT_SUCESS,
    GET_CLIENT_ERROR,
    RESET_SEARCH_CLIENT,
    CUSTOMER_SEARCH,
    ACTUAL_PAGE_CUSTOMER,
    CURRENT_TABLE,
    CLIENT_SORT,
} from '@TypesQouter/Types';

const initialState = {
    error: false,
    loading: false,
    clients: [],
    actualPage: 1,
    searchCustomer: '',
    sorting: {
        sortedCol: 1,
        direction: 0,
    },
};

const getClient = (state = initialState, actions: ResponseData) => {
    switch (actions.type) {
        case GET_CLIENT:
            return {...state, loading: true, error: false};
        case GET_CLIENT_SUCESS:
            return {...state, error: false, loading: false, clients: actions.payload};
        case GET_CLIENT_ERROR:
            return {...state, error: true, loading: false, clients: []};
        case ACTUAL_PAGE_CUSTOMER:
            return {...state, actualPage: actions.payload};
        case CURRENT_TABLE:
            return {...state, table: actions.payload};
        case CUSTOMER_SEARCH:
            return {...state, searchCustomer: actions.payload};
        case CLIENT_SORT:
            return {
                ...state,
                sorting: actions.payload,
            };
        case RESET_SEARCH_CLIENT:
            return initialState;
        default:
            return state;
    }
};

export default getClient;
