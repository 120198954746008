import React, {ChangeEvent, useRef, useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@/components/Quoter/styles';
import {ArchiveInputContainer} from '@/components/Quoter/Steps/styles';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {useCallback} from 'react';
import {useEffect} from 'react';

const UploadIdDocument = (props: IClientProofStep) => {
    const {selectedFile, setSelectedFile} = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [filePropierties, setPropierties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files && event.target.files[0];
            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setPropierties(file);
                        setErrorFile(false);
                    }
                };
            } else {
                setSelectedFile(null);
                setErrorFile(true);
            }
        }
    };
    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files?.[0];
        if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setSelectedFile(reader.result);
                    setPropierties(file);
                    setErrorFile(false);
                }
            };
        } else {
            setSelectedFile(null);
            setErrorFile(true);
        }
    }, []);
    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);
    useEffect(() => {
        setSelectedFile(null);
    }, []);
    return (
        <ArchiveInputContainer
            Height="56px"
            Width="100%"
            error={errorFile}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            <ContainerFlex>
                {selectedFile && selectedFile?.length > 0 ? (
                    <ContainerFlex ColumnGap="8px" Justify="start">
                        <ContainerFlex
                            Padding="16px"
                            backG="#F4F5F5"
                            Width="auto"
                            Radius="32px 0 0 32px"
                        >
                            <IconSvg Width="24px" Height="24px">
                                <path
                                    d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z"
                                    fill="#71767D"
                                />
                            </IconSvg>
                        </ContainerFlex>
                        <ContainerFlex
                            Justify="space-between"
                            Padding="0 18px 0 8px"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {filePropierties !== null ? filePropierties?.name : ''}
                                </Text>
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {filePropierties !== null
                                        ? (filePropierties.size / (1024 * 1024)).toFixed(2)
                                        : 0}
                                    MB
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Justify="end">
                                <IconSvg Width="24px" Height="24px">
                                    <path
                                        d="M3.33301 16.6666V15H5.62467L5.29134 14.7083C4.56912 14.0694 4.06217 13.3403 3.77051 12.5208C3.47884 11.7014 3.33301 10.875 3.33301 10.0416C3.33301 8.49998 3.79467 7.12831 4.71801 5.92665C5.6419 4.72554 6.8469 3.93054 8.33301 3.54165V5.29165C7.33301 5.65276 6.52745 6.2672 5.91634 7.13498C5.30523 8.00331 4.99967 8.9722 4.99967 10.0416C4.99967 10.6666 5.11773 11.2741 5.35384 11.8641C5.58995 12.4547 5.95801 13 6.45801 13.5L6.66634 13.7083V11.6666H8.33301V16.6666H3.33301ZM11.6663 16.4583V14.7083C12.6663 14.3472 13.4719 13.7328 14.083 12.865C14.6941 11.9966 14.9997 11.0278 14.9997 9.95831C14.9997 9.33331 14.8816 8.72554 14.6455 8.13498C14.4094 7.54498 14.0413 6.99998 13.5413 6.49998L13.333 6.29165V8.33331H11.6663V3.33331H16.6663V4.99998H14.3747L14.708 5.29165C15.3886 5.9722 15.8852 6.71165 16.198 7.50998C16.5102 8.30887 16.6663 9.12498 16.6663 9.95831C16.6663 11.5 16.2044 12.8714 15.2805 14.0725C14.3572 15.2741 13.1525 16.0694 11.6663 16.4583Z"
                                        fill="#5A5AFF"
                                    />
                                </IconSvg>
                                <Text FontSize="0.75rem" Color="#5A5AFF" FontWeight="500">
                                    Remplazar
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                ) : (
                    <ContainerFlex ColumnGap="8px" onClick={() => fileInputRef.current?.click()}>
                        <IconSvg Width="24px" Height="24px">
                            <path
                                d="M11 20V11.85L8.4 14.45L7 13L12 8L17 13L15.6 14.45L13 11.85V20H11ZM4 9V6C4 5.45 4.196 4.97933 4.588 4.588C4.97933 4.196 5.45 4 6 4H18C18.55 4 19.021 4.196 19.413 4.588C19.8043 4.97933 20 5.45 20 6V9H18V6H6V9H4Z"
                                fill="#5A5AFF"
                            />
                        </IconSvg>
                        <Text FontSize="1rem" Color="#54575">
                            Arrastre elementos aquí o
                        </Text>
                        <Text FontSize="1rem" Color="#5A5AFF">
                            Busque archivos.
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ArchiveInputContainer>
    );
};

export default UploadIdDocument;
