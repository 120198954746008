import React, {useState} from 'react';
import {
    ContainerFlex,
    Text,
    Image,
    ContainerCellGrid,
    Input,
    ContainerForm,
} from '@/components/Shopify/Ecommerce/styles';
import IconSuccess from '@images/StatusSuccess.svg';
import MarkEmail from '@images/mark email read.svg';
import CloseRed from '@images/closeRed.svg';
import * as content from '@/components/MyAccount/MyCredit/CreditDetails/Styles';
import {formEmail, STATUSPAYMENT} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaEmail} from '@/components/MyAccount/MyCredit/CreditDetails/SchemaCreditsDetails';

export const StatusPayment = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schemaEmail),
        defaultValues: formEmail,
    });
    const [checkEmail, setCheckEmail] = useState('');
    const onSubmit = async (values: {email: string}) => {
        setCheckEmail(values.email);
    };
    return (
        <ContainerFlex {...content.PaymentStyles} backG="#FFFFFF">
            <Text
                oFlow="normal"
                wSpace="normal"
                FontSize="1.25rem"
                FontWeight="700"
                Color="#1D1E20"
            >
                {STATUSPAYMENT.TITLE}
            </Text>
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <ContainerFlex
                Justify="start"
                backG="#D1FFD1"
                Height="5.438rem"
                Padding="1rem"
                Gap="1rem"
                Radius="1.5rem"
            >
                <Image src={IconSuccess} Width="55px" Height="55px" />
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                    <Text
                        oFlow="normal"
                        wSpace="normal"
                        FontSize="1.25rem"
                        FontWeight="700"
                        Color="#0D166B"
                    >
                        {STATUSPAYMENT.STATUS}
                    </Text>
                    <Text wSpace="normal" FontSize="0.875rem" Color="#1D1E20">
                        {STATUSPAYMENT.SUB}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="center"
                Height="auto"
                backG="#FAFAFA"
                Padding="1.5rem"
                Gap="1.5rem"
                Radius="1.5rem"
            >
                <ContainerFlex Justify="space-between" Align="center" Height="24px">
                    <Text wSpace="normal" FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {STATUSPAYMENT.RESUME}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#5A5AFF">
                        {STATUSPAYMENT.ACCOUNT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="space-between"
                    Align="center"
                    Padding="0.5rem 0"
                    Height="40px"
                >
                    <Text wSpace="normal" FontSize="1rem" Color="#2A2C2F">
                        {STATUSPAYMENT.LOAN}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" Color="#2A2C2F">
                        ${'400.00'}
                    </Text>
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
                <ContainerFlex Justify="space-between" Height="40px">
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        {STATUSPAYMENT.TOTAL}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        ${'1000.00'}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {checkEmail ? (
                <ContainerFlex
                    Justify="start"
                    backG="#FFFFFF"
                    Border="1px solid #E8E9EA"
                    Radius="1.5rem"
                    Gap="1rem"
                    Height="4.75rem"
                    Padding="1rem 1.5rem"
                >
                    <ContainerFlex backG="#F4F5F5" Width="44px" Height="44px" Radius="60px">
                        <Image src={MarkEmail} Width="24px" Height="24px" />
                    </ContainerFlex>
                    <Text oFlow="normal" wSpace="pre">
                        {STATUSPAYMENT.CHECKED}{' '}
                        <Text oFlow="normal" wSpace="normal" FontWeight="700">
                            {checkEmail}
                        </Text>
                    </Text>
                </ContainerFlex>
            ) : (
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Border="1px solid #E8E9EA"
                    backG="#FFFFFF"
                    Padding="1.5rem"
                    Gap="1.5rem"
                    Radius="1.5rem"
                >
                    <Text
                        oFlow="normal"
                        wSpace="normal"
                        FontSize="1rem"
                        FontWeight="700"
                        Color="#2A2C2F"
                        Height="1.5rem"
                    >
                        {STATUSPAYMENT.SEND}
                    </Text>
                    <ContainerForm onSubmit={handleSubmit(onSubmit)}>
                        <ContainerFlex Justify="start" Gap="1rem" Align="end">
                            <ContainerCellGrid {...content.PaymentStyles.Grids}>
                                <Text
                                    oFlow="normal"
                                    wSpace="normal"
                                    FontSize="1rem"
                                    FontWeight="700"
                                    Color="#2A2C2F"
                                >
                                    {STATUSPAYMENT.EMAIL}
                                </Text>
                                <Input
                                    {...content.PaymentStyles.Inputs}
                                    {...register('email')}
                                    error={errors.email?.message}
                                />
                                {errors?.email?.message && (
                                    <ContainerFlex Justify="start" Gap="0.25rem">
                                        <Image src={CloseRed} Width="16px" Height="16px" />
                                        <Text
                                            Color="#ef4f55"
                                            FontSize="0.75rem"
                                            Align="start"
                                            wSpace="normal"
                                            Height="auto"
                                            FontWeight="400"
                                        >
                                            {errors.email?.message}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </ContainerCellGrid>
                            <ButtonGenerals
                                FontSize="0.875rem"
                                Padding="0 1rem"
                                Width="11.375rem"
                                Height="2rem"
                                SecondaryButton="#FFFFFF"
                                FontWeight="700"
                                type="submit"
                            >
                                {STATUSPAYMENT.BAUCHER}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            )}
            <ContainerFlex Justify="center" Width="100%">
                <ButtonGenerals Width="9.813rem" FontWeight="700">
                    {STATUSPAYMENT.PAY}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};
