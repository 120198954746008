import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import {RowSchedule} from '@components/Branches/ManageBranch/Schedules/RowSchedule';
import ButtonGeneral from '@components/General/Atoms/Button';
import {IScheduleBranch, IScheduleMenu} from '@components/Branches/interfaces';
import {PutScheduleEndeavor} from '@components/Branches/ManageBranch/Redux/Actions/PutScheduleEndeavor';
import {useParams} from 'react-router-dom';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const BranchScheduleEndeavor = ({
    scheduleName,
    showItems,
    setShowItems,
    showOpenSchedule,
    setShowOpenSchedule,
}: IScheduleMenu) => {
    const {idBranch} = useParams();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceName as string
    );
    const schedule: IScheduleBranch[] = useSelector((state: RootState) => {
        return state.GetScheduleEndeavor?.scheduleEndeavor;
    });
    const endeavorLoad = useSelector((state: RootState) => {
        return state.PutScheduleEndeavor?.loading;
    });
    const dispatch = useDispatch();
    const [scheduleUpdated, setScheduleUpdated] = useState<IScheduleBranch[]>(schedule);
    const handleSubmit = () => {
        const data = {
            hours: scheduleUpdated,
        };
        dispatch(
            PutScheduleEndeavor(
                data,
                token,
                setShowOpenSchedule,
                setShowItems,
                showItems,
                Number(idBranch)
            )
        );
    };
    return (
        <ContainerFlex Height="auto" Justify="flex-start" Align="flex-start" FlexDir="column">
            <ContainerFlex
                Height="56px"
                Justify="space-between"
                Bt="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Radius="4px 4px 0 0"
                Margin="0 0 24px 0"
                Padding="0 16px 0 0"
            >
                <Text FontSize="1rem" FontWeight="500" Padding="18.5px 16px">
                    {scheduleName}
                </Text>
                <Image
                    src={downArrowSelected}
                    alt="down-arrow"
                    Width="24px"
                    Height="24px"
                    Cursor="pointer"
                    onClick={() => setShowOpenSchedule(!showOpenSchedule)}
                />
            </ContainerFlex>
            <ContainerFlex
                RowGap="14px"
                FlexDir="column"
                Height="500px"
                Justify="flex-start"
                Align="flex-start"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Radius="0 0 4px 4px"
                Padding="16px"
                backG="rgba(243, 243, 243, 0.4)"
            >
                <Text FontSize="0.875rem" TextAlign="start" Width="100%" Color="#414042">
                    Horario de empeños personalizados para
                    <Text FontWeight="500" Padding="0 0 0 6px" FontSize="0.875rem">
                        {companyName}
                    </Text>
                </Text>
                <ContainerFlex FlexDir="column" Align="flex-start">
                    {scheduleUpdated?.map((item: IScheduleBranch) => (
                        <RowSchedule
                            key={item.operationId}
                            item={item}
                            scheduleUpdated={scheduleUpdated}
                            setScheduleUpdated={setScheduleUpdated}
                        />
                    ))}
                </ContainerFlex>
                <ContainerFlex Height="40px" ColumnGap="20px" Margin="16px 0 0 0">
                    <ButtonGeneral
                        width="181px"
                        text="Cancelar"
                        type="submit"
                        border="transparent"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hColor="#35cf44"
                        transform=""
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => {
                            setShowOpenSchedule(false);
                        }}
                        disabled={endeavorLoad}
                        cursor={endeavorLoad ? '' : 'pointer'}
                    />
                    <ButtonGeneral
                        width="170px"
                        text={endeavorLoad ? <LoadingGeneralButtons /> : 'Guardar'}
                        type="submit"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        transform="capitalize"
                        hText="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => handleSubmit()}
                        disabled={endeavorLoad}
                        cursor={endeavorLoad ? '' : 'pointer'}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
