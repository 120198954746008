import React from 'react';
import {IPropsEnterName} from '@components/CreateUsers/Interface';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import {InputStyles} from '@components/CreateUsers/Styles';

export const EnterName = ({register}: IPropsEnterName) => {
    return (
        <ContainerFlex MarginTop="24px">
            <ContainerFlex FlexDir="column" Align="start" FlexWrap="wrap">
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Nombre (s)
                </Text>
                <Input
                    Cursor="text"
                    maxLength={160}
                    placeholder="Nombres"
                    {...InputStyles}
                    {...register('names')}
                    Padding="16px"
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="start" AlignContent="end" FlexWrap="wrap">
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Apellidos
                </Text>
                <Input
                    Cursor="text"
                    maxLength={160}
                    placeholder="Apellidos"
                    {...InputStyles}
                    {...register('surnaNames')}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
