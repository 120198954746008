import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import useFormatDate from '@/hooks/useFormatDate';
import {IPayGatewaySummaryProps} from '@Loan/PaymentQuoter/Interfaces';
import arrowIcon from '@images/arrowDark.svg';
import arrowUpIcon from '@images/arrowTopLeft.svg';
import {PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import {Text, Image, Line} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import InstallmentSummary from '@Loan/PaymentQuoter/InstallmentSummary';

const PaymentGatewaySummary = ({clientData, onPay}: IPayGatewaySummaryProps) => {
    const cartData = useSelector((state: RootState) => state.getCartByCartId.cartData);
    return (
        <FlexPanel
            Padding="1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1.5rem"
            Self="stretch"
            Background="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Width="100%"
        >
            <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20" LetterSpacing="0.019rem">
                {PAYMENT_GATEWAY.CLIENT}
            </Text>
            <FlexPanel FlexDir="column" Align="flex-start" Gap="1rem" Self="stretch">
                <FlexPanel Align="center" Gap="0.5rem" Flex="1 0 0">
                    <Image
                        alt="client-img"
                        src={clientData.imgProfile}
                        Width="2.375rem"
                        Height="2.375rem"
                        Radius="3rem"
                        Background="#ACACFF"
                    />
                    <Text
                        FontSize="1.25rem"
                        FontWeight="700"
                        Color="#1D1E20"
                        LetterSpacing="0.019rem"
                        Transform="capitalize"
                    >
                        {clientData.clientName}
                    </Text>
                </FlexPanel>
                <Line />
                {cartData?.endorsements &&
                    cartData.endorsements.map((eitem, index: number) => (
                        <FlexPanel
                            key={eitem.contractId}
                            FlexDir="column"
                            Gap="1rem"
                            Align="self-start"
                            Width="100%"
                        >
                            <FlexPanel Justify="space-between" Width="100%">
                                <FlexPanel Gap="1rem">
                                    <Text
                                        Color="#2A2C2F"
                                        FontSize="1rem"
                                        FontWeight="700"
                                        LetterSpacing="0.019rem"
                                    >
                                        {PAYMENT_GATEWAY.PURCHASE_SUMMARY}
                                    </Text>
                                    <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                        {PAYMENT_GATEWAY.ARTICLE_COUNT} {eitem.pledgeNumber}
                                    </Text>
                                </FlexPanel>
                                <Image
                                    alt="arrow"
                                    src={arrowIcon}
                                    Width="1.5rem"
                                    Height="1.5rem"
                                    Transform="rotate(90deg)"
                                />
                            </FlexPanel>
                            <FlexPanel Gap="1rem">
                                <Image
                                    src={eitem.imgUrl}
                                    alt="article"
                                    Cursor="pointer"
                                    Width="3rem"
                                    Height="3rem"
                                />
                                <FlexPanel Gap="1rem">
                                    <FlexPanel
                                        Radius="2rem"
                                        Padding="0.125rem 0.5rem"
                                        Height="1.25rem"
                                        Width="5.313rem"
                                        Border="1px solid #99E0FF"
                                        Background="#DBF4FF"
                                    >
                                        <Text FontWeight="700" FontSize="0.75rem" Color="#004461">
                                            {PAYMENT_GATEWAY.RENEWAL}
                                        </Text>
                                    </FlexPanel>
                                    <Text FontSize="0.875rem" FontWeight="400" Color="#54575C">
                                        {PAYMENT_GATEWAY.CONTRACT} {eitem.contractId}
                                    </Text>
                                </FlexPanel>
                            </FlexPanel>
                            {eitem.extension && (
                                <FlexPanel Align="center" Gap="1rem" Self="stetch">
                                    <FlexPanel
                                        Width="1.5rem"
                                        Height="2.125rem"
                                        Padding="0.188rem 0.25rem"
                                    >
                                        <Image
                                            src={arrowUpIcon}
                                            alt="arrow"
                                            Width="0.938rem"
                                            Height="1.063rem"
                                        />
                                    </FlexPanel>
                                    <Text Color="#1D1E20" FontWeight="700" LetterSpacing="0.019rem">
                                        {PAYMENT_GATEWAY.EXTENSION}
                                    </Text>
                                    <Text
                                        Color="#1D1E20"
                                        FontWeight="400"
                                        LetterSpacing="0.019rem"
                                    >{`${eitem.extension.numberDays} ${
                                        PAYMENT_GATEWAY.DAYS
                                    } ${useFormatDate(eitem.extension.nextPaymentDate)}`}</Text>
                                </FlexPanel>
                            )}
                            {eitem.pass && <InstallmentSummary pass={eitem.pass} />}
                            <Line />
                        </FlexPanel>
                    ))}
                <Text
                    Color="#2A2C2F"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                    oFlow="visible"
                >
                    {PAYMENT_GATEWAY.PAYMENT_SUMMARY}
                </Text>
                <FlexPanel FlexDir="column" Gap="0.5rem" Background="#FFF" Width="90%">
                    <FlexPanel Justify="space-between" Width="100%">
                        <Text
                            Color="#2A2C2F"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.SUBTOTAL}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {numberToCurrency(cartData?.subTotal || 0)}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Justify="space-between" Width="100%">
                        <Text
                            Color="#2A2C2F"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.IVA}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {numberToCurrency(cartData?.iva || 0)}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Justify="space-between" Width="100%">
                        <Text
                            Color="#2A2C2F"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.TOTAL_PASS}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {numberToCurrency(cartData?.totalPass || 0)}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Justify="space-between" Width="100%">
                        <Text Color="#2A2C2F" TextAlign="right" FontSize="1.25rem" FontWeight="700">
                            {PAYMENT_GATEWAY.TOTAL}
                        </Text>
                        <Text Color="#1D1E20" TextAlign="right" FontSize="1.25rem" FontWeight="700">
                            {numberToCurrency(cartData?.total || 0)}
                        </Text>
                    </FlexPanel>
                </FlexPanel>
                <Line />
                <ButtonGenerals Width="100%" Cursor="pointer" type="button" onClick={onPay}>
                    {PAYMENT_GATEWAY.PAY}
                </ButtonGenerals>
            </FlexPanel>
        </FlexPanel>
    );
};
export default PaymentGatewaySummary;
