import {Controller} from 'react-hook-form';
import React from 'react';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {NEWFEATURE} from '@components/Catalogue/constants';
import {NewFeatureCheckboxProps} from '@components/Catalogue/interfaces';

export const NewFeatureCheckboxs = ({control}: NewFeatureCheckboxProps) => {
    return (
        <>
            <ContainerFlex Align="start" FlexDir="column" Gap="1rem">
                <ContainerFlex Align="center" Justify="start" Gap="0.5rem">
                    <Controller
                        name="mandatoryFeature"
                        control={control}
                        render={({field}) => <Checkbox {...field} checked={field.value} />}
                    />
                    <Text FontFamily="Nunito" FontWeight="400">
                        {NEWFEATURE.MANDATORYFEATURE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="center" Justify="start" Gap="0.5rem">
                    <Controller
                        name="printEtiquette"
                        control={control}
                        render={({field}) => <Checkbox {...field} checked={field.value} />}
                    />
                    <Text FontFamily="Nunito" FontWeight="400">
                        {NEWFEATURE.PRINTETTIQUERE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
