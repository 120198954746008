import {TREE_PERMISSIONS} from '@Roles/CreateRoles/Redux/Types';
import {ITreePermissions, ITreePermissionsRedux} from '@/components/Roles/AddRoles/interfaces';

const initialState: ITreePermissions = {
    treePermissions: [],
    error: false,
    loading: false,
};

const treePermissions = (state = initialState, action: ITreePermissionsRedux): ITreePermissions => {
    switch (action.type) {
        case TREE_PERMISSIONS.START:
            return {...state, loading: true, error: false};
        case TREE_PERMISSIONS.SUCCESS:
            return {
                ...state,
                treePermissions: action.treePermissions,
                loading: false,
                error: false,
            };
        case TREE_PERMISSIONS.ERROR:
            return {...state, loading: false, error: false};
        case TREE_PERMISSIONS.RESET:
            return initialState;
        default:
            return state;
    }
};
export default treePermissions;
