import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, ContainerForm, Text} from '@/components/Shopify/Ecommerce/styles';
import {Controller} from 'react-hook-form';
import {
    FormLabel,
    Input,
} from '@/components/QuickPayment/SuccessPaymentSummary/ResumenPagoExitosoStyles';
import emailWithCheck from '@/images/emailWithCheck.svg';
import {ISuccessFormVoucher} from '@/components/QuickPayment/interfaces';
import {VOUCHER_FORM} from '@/components/QuickPayment/Constants';

const SuccessFormVoucher = ({
    control,
    email,
    register,
    emailSend,
    errorMessage,
    handleSubmit,
    onSubmit,
}: ISuccessFormVoucher) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Padding="24px"
            Border="1px solid #E8E9EA"
            Radius="24px"
            Align="flex-start"
            Gap="24px"
            Height={emailSend ? '76px' : errorMessage ? '187px' : '166px'}
        >
            {!emailSend ? (
                <>
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="700" Color="#1D1E20">
                        {VOUCHER_FORM.TITLE}
                    </Text>
                    <ContainerForm
                        FlexDir="column"
                        Gap="4px"
                        Align="flex-start"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormLabel htmlFor={VOUCHER_FORM.FORM_FIELDS.EMAIL.NAME}>
                            {VOUCHER_FORM.FORM_FIELDS.EMAIL.LABEL}
                        </FormLabel>
                        <ContainerFlex Gap="16px" Justify="flex-start" Width="350px">
                            <ContainerFlex FlexDir="column" Gap="4px" Align="flex-start">
                                <Controller
                                    name={VOUCHER_FORM.FORM_FIELDS.EMAIL.NAME}
                                    control={control}
                                    render={({field}) => (
                                        <Input
                                            error={!!errorMessage}
                                            {...field}
                                            {...register(VOUCHER_FORM.FORM_FIELDS.EMAIL.NAME)}
                                        />
                                    )}
                                />
                                {errorMessage && (
                                    <ContainerFlex Gap="4px" Justify="flex-start" Height="16px">
                                        <Text
                                            Color="#FF6357"
                                            FontSize="0.875rem"
                                            FontFamily="Nunito"
                                        >
                                            {VOUCHER_FORM.CLOSE_BTN_TEXT}
                                        </Text>
                                        <Text
                                            Color="#FF6357"
                                            FontSize="0.875rem"
                                            FontFamily="Nunito"
                                        >
                                            {errorMessage}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                            <ButtonGenerals
                                BackGC="transparent"
                                HBackG="transparent"
                                Radius="32px"
                                Border="1px solid #5A5AFF"
                                Padding="4px 16px"
                                FontFamily="Nunito"
                                Margin={errorMessage ? '0 0 20px 0' : '0'}
                                type="submit"
                                Cursor="pointer"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontSize="1rem"
                                    FontWeight="700"
                                    Color="#5A5AFF"
                                    Cursor="pointer"
                                >
                                    {VOUCHER_FORM.ACTION_BTN_TEXT}
                                </Text>
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </>
            ) : (
                <ContainerFlex Justify="flex-start" Gap="16px">
                    <ContainerFlex
                        Gap="16px"
                        Justify="center"
                        Align="center"
                        backG="#F4F5F5"
                        Radius="60px"
                        Width="44px"
                        Height="44px"
                    >
                        <img src={emailWithCheck} alt="email logo" />
                    </ContainerFlex>
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                        {VOUCHER_FORM.SUCCESS_MESSAGE}
                        <Text
                            FontFamily="Nunito"
                            FontSize="1rem"
                            FontWeight="700"
                            Color="#2A2C2F"
                            MarginLeft="4px"
                        >
                            {email}
                        </Text>
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default SuccessFormVoucher;
