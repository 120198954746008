import {
    GET_CAT_SUBURB,
    GET_CAT_SUBURB_ERROR,
    GET_CAT_SUBURB_RESET,
    GET_CAT_SUBURB_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    catSuburb: [],
};

const GetCatSuburb = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_CAT_SUBURB:
            return {
                ...state,
                loading: true,
            };
        case GET_CAT_SUBURB_SUCCESS:
            return {
                ...state,
                catSuburb: action.payload.data.data,
                loading: false,
            };
        case GET_CAT_SUBURB_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_CAT_SUBURB_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetCatSuburb;
