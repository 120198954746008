import axios, {AxiosError} from 'axios';
import {
    GET_PERCENT_AMORTIZATION_ERROR,
    GET_PERCENT_AMORTIZATION_START,
    GET_PERCENT_AMORTIZATION_SUCCESS,
    SET_REQUESTED_AMOUNT_SOLICITUDE,
    SET_REQUESTED_AMOUNT_RECEIVE,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IPercentageAmortizationAction} from '@components/CreditsAndPawns/interfaces';

const getPercentageAmortizationStart = () => {
    return {
        type: GET_PERCENT_AMORTIZATION_START,
    };
};

const getPercentageAmortizationSuccess = (result: number) => {
    return {
        type: GET_PERCENT_AMORTIZATION_SUCCESS,
        payload: result,
    };
};

const getPercentageAmortizationError = (error: AxiosError) => {
    return {
        type: GET_PERCENT_AMORTIZATION_ERROR,
        error: error,
    };
};
export const setRecalculatedAmount = (recalculatedAmount: number) => {
    return {
        type: SET_REQUESTED_AMOUNT_SOLICITUDE,
        payload: recalculatedAmount,
    };
};

export const setRecalculatedAmountReceive = (recalculatedAmountReceive: number) => {
    return {
        type: SET_REQUESTED_AMOUNT_RECEIVE,
        payload: recalculatedAmountReceive,
    };
};
export const getPercentageAmortizationAction = ({
    token,
    totalAmount,
    anualRate,
    countPeriod,
}: IPercentageAmortizationAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPercentageAmortization}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params = {totalAmount, anualRate, countPeriod};
    return async (dispatch: AppDispatch) => {
        dispatch(getPercentageAmortizationStart());
        try {
            const response = await axios.get(url, {headers, params});
            const {message} = response.data;
            dispatch(getPercentageAmortizationSuccess(parseFloat(message)));
        } catch (e) {
            dispatch(getPercentageAmortizationError(e as AxiosError));
        }
    };
};
