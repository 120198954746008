import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@/components/Locations/AdminLocations/CreateLocation/style';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {IconSvg} from '@/components/Quoter/styles';
import branchImg from '@/components/Branches/Images/BranchIndexImg.svg';
import {IEmptyBranches} from '@components/Branches/interfaces';

export const EmptyBranches = ({createModal, setCreateModal}: IEmptyBranches) => {
    return (
        <ContainerFlex FlexDir="column" Gap="32px">
            <Text FontSize="0.875rem" Color="#6d6e71" FontWeight="500">
                No hay sucursales creadas aún
            </Text>
            <ImgLocation src={branchImg} Width="27.34375rem" Height="20.4375rem" />
            <ButtonGeneral
                FontSize="0.875rem"
                text={
                    <>
                        <IconSvg
                            width="24"
                            height="24"
                            viewBox="0 0 24 25"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M11.758 19v-6h-6v-2h6V5h2v6h6v2h-6v6h-2z" fill="#6D6E71" />
                        </IconSvg>
                        <Text Padding="0 8px" Cursor="pointer" HColor="#35cf44">
                            Crear sucursal
                        </Text>
                    </>
                }
                border="solid 1px #414042"
                bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                transform=""
                hText="#35cf44"
                width="10.875rem"
                hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                clic={() => {
                    setCreateModal(!createModal);
                }}
            />
        </ContainerFlex>
    );
};
