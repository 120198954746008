import styled from '@emotion/styled';
import {IPropsButtonGenerals} from '@General/Atoms/Button/interfacesStyles';

export const ButtonGenerals = styled.button<IPropsButtonGenerals>`
    display: flex;
    justify-content: ${(props) => props.Justify};
    border: ${(props) => (props.SecondaryButton ? '1px solid #5A5AFF' : props.Border)};
    text-transform: ${(props) => props.Transform};
    border-radius: ${(props) => props.Radius};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    min-height: ${(props) => props.MHeight};
    font-family: ${(props) => props.FontFamily};
    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    align-items: center;
    margin: ${(props) => props.Margin};
    background-color: ${(props) => (props.SecondaryButton ? '#FFFFFF' : props.BackGC)};
    margin-top: ${(props) => props.MT};
    box-shadow: ${(props) => props.BS};
    color: ${(props) => (props.SecondaryButton ? '#5A5AFF' : props.Color)};
    position: ${(props) => props.Position};
    cursor: ${(props) => props.Cursor};
    gap: ${(props) => props.Gap};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    border-top-left-radius: ${(props) => props.RadiusTL};
    border-bottom-left-radius: ${(props) => props.RadiusBL};
    border-right: ${(props) => props.BorderR};
    border-left: ${(props) => props.BorderL};
    border-top: ${(props) => props.BorderT};
    border-bottom: ${(props) => props.BorderB};

    &:hover {
        border-color: ${(props) => props.HBorder};
        border: ${(props) => props.BorderH};
        color: ${(props) => props.HColor};
        box-shadow: ${(props) => props.HShadow};
        background-color: ${(props) => (props.SecondaryButton ? '#E5E5FF' : props.HBackG)};
    }

    &:disabled {
        background-color: ${(props) => props.DBackG};
        border: ${(props) => props.DBorder};
        color: ${(props) => props.DColor};
        font-weight: ${(props) => props.DFontWeight};
        box-shadow: ${(props) => props.HDBox};
        cursor: ${(props) => props.DCursor};
    }

    &[disabled]:hover {
        border: ${(props) => props.HDBorder};
        color: ${(props) => props.HDColor};
        box-shadow: ${(props) => props.HDBox};
    }

    &:hover svg path {
        fill: ${(props) => props.FillIcon};
    }

    &:hover span {
        color: ${(props) => (props.SecondaryButton ? '#5A5AFF' : props.HText)};
    }

    span {
        color: ${(props) => props.TextColor};
    }
`;

ButtonGenerals.defaultProps = {
    Height: '40px',
    Justify: 'center',
    Radius: '32px',
    FontSize: '16px',
    BackGC: '#5A5AFF',
    Padding: '8px 16px',
    Gap: '8px',
    Transform: '',
    HBackG: '#0D166B',
    DBackG: '#E8E9EA',
    DBorder: 'none',
    Color: '#FFFFFF',
    Border: 'none',
    DColor: '#a1a5aa',
    DCursor: 'default',
    Cursor: 'pointer',
    FontWeight: '700',
    FillIcon: '#FFFFFF',
    FontFamily: 'Nunito',
};
