import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {TableHeaderText, TableList} from '@Roles/constants';
import addUser from '@images/addUser.svg';
import {FilterUserType} from '@components/Users/FilterUserType';
import RolesFilters from '@Roles/RolesFilters';
import {GetRolesInformation} from '@Roles/Redux/Actions/GetRolesInformation';
import {FilterType} from '@components/Users/interface';
import {RolesSearch} from '@Roles/RolesSearch';
import {useNavigate} from 'react-router-dom';
import {treeReset} from '@Roles/CreateRoles/Redux/Actions/TreePermissions';
import {typeActionRole} from '@Roles/CreateRoles/Redux/Actions/PersistenceRole';
import {RolesPage} from '@Roles/RolesPage';

export const RolesList = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userType, setUserType] = useState<FilterType | null>(null);
    const [search, setSearch] = useState<string>('');
    const [pageNumber, setPageNumber] = useState(1);

    const handleSearch = (searchText: string) => {
        setSearch(searchText);
    };
    const handleFilter = (accessType: FilterType) => {
        setUserType(accessType);
    };

    const createRole = async () => {
        await dispatch(typeActionRole('Nuevo'));
        navigate('/Roles/CreateRole');
    };
    const tableHeader = [
        {tableHeaderText: TableHeaderText.Role},
        {tableHeaderText: TableHeaderText.Type},
        {tableHeaderText: TableHeaderText.Users},
        {TableHeaderText: ''},
    ];

    const searchRoles = () => {
        if (token)
            dispatch(
                GetRolesInformation(token, {
                    information: search ?? search,
                    userProfileType: userType?.userId ?? 3,
                    pageNumber: pageNumber,
                })
            );
    };

    useEffect(() => {
        searchRoles();
        dispatch(treeReset());
    }, [search, userType, token, dispatch, pageNumber]);

    return (
        <ContainerFlex FlexDir="column" Gap="1rem" Padding="1rem 1.5rem" Height="auto">
            <ContainerFlex Justify="space-between" Height="2.5rem">
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                    {TableList.Roles}
                </Text>
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="500"
                    onClick={() => createRole()}
                >
                    <Image src={addUser} alt="add-user" />
                    {TableList.NewRoles}
                </ButtonGenerals>
            </ContainerFlex>
            <FilterUserType onFilterChange={handleFilter} />
            <ContainerFlex Gap="0.5rem" FlexDir="column">
                <RolesSearch onSearch={handleSearch} />
                <ContainerFlex FlexDir="column">
                    <ContainerFlex
                        Padding="0.5rem"
                        Display="grid"
                        GridColumns="repeat(3, 1fr) 0.1fr"
                        Bb="1px solid #E8E9EA"
                        Justify="start"
                    >
                        {tableHeader.map((header, index) => (
                            <Text key={index} Color="#2A2C2F" FontWeight="500" FontSize="0.875rem">
                                {header.tableHeaderText}
                            </Text>
                        ))}
                    </ContainerFlex>
                    <RolesFilters searchRoles={searchRoles} />
                    <RolesPage pageNumber={pageNumber} setPageNumber={setPageNumber} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
