import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_MOVEMENT_HISTORY_ERROR,
    GET_CREDIT_MOVEMENT_HISTORY_START,
    GET_CREDIT_MOVEMENT_HISTORY_SUCCESS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {
    ICreditMovementHistoryReducer,
    ICreditMovementHistory,
    ICreditMovementHistoryAPI,
} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';

const initialState: ICreditMovementHistoryReducer = {
    data: null,
    loading: false,
    error: false,
};

const getCreditMovementHistory = (
    state: ICreditMovementHistoryReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): ICreditMovementHistoryReducer => {
    switch (action.type) {
        case GET_CREDIT_MOVEMENT_HISTORY_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CREDIT_MOVEMENT_HISTORY_SUCCESS:
            return {
                data: getCreditMovementHistoryData(action.payload),
                loading: false,
                error: false,
            };
        case GET_CREDIT_MOVEMENT_HISTORY_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

const getCreditMovementHistoryData = (response: AxiosResponse): ICreditMovementHistory[] => {
    const {data} = response;

    return data?.map((credit: ICreditMovementHistoryAPI) => {
        const {transactionDescription, cardTypeUrl, cardNumber, amount, paymentDate} = credit;

        const CreditMovementHistory = {
            transactionType: transactionDescription,
            paymentMethod: cardNumber,
            paymentMethodURL: cardTypeUrl,
            payment: amount,
            paymentDate,
        };
        return CreditMovementHistory;
    });
};
export default getCreditMovementHistory;
