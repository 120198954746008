import {
    GET_SIZE_COMPANY_ERROR,
    GET_SIZE_COMPANY_START,
    GET_SIZE_COMPANY_SUCCESS,
    PUT_SIZE_COMPANY_ERROR,
    PUT_SIZE_COMPANY_START,
    PUT_SIZE_COMPANY_SUCCESS,
} from '@TypesRoles/types';
import {IReducerType} from '@Roles/interface';

const initialState = {
    loading: false,
    error: false,
    sizeCompany: null,
    putSizeCompany: null,
};

const GetSizeCompany = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_SIZE_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_SIZE_COMPANY_SUCCESS:
            return {
                ...state,
                sizeCompany: action.payload.data,
                loading: false,
            };
        case GET_SIZE_COMPANY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_SIZE_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case PUT_SIZE_COMPANY_SUCCESS:
            return {
                ...state,
                putSizeCompany: action.payload.data,
                loading: false,
            };
        case PUT_SIZE_COMPANY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default GetSizeCompany;
