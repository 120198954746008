import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, ContainerForm, TextArea, Text} from '@/components/Shopify/Ecommerce/styles';
import UploadFile from '@/components/CashFlow/Expenses/ExpenseDetails/UploadFile';
import {
    DEFAULT_FORM,
    EXPENSE_DETAILS,
} from '@/components/CashFlow/Expenses/ExpenseDetails/constants';
import {useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {IExpendForm, IPropsFormExpense} from '@CashFlow/Expenses/ExpenseDetails/Types';
import {expendFormSchema} from '@CashFlow/Expenses/ExpenseDetails/schemaExpend';
import {PrincipalInformation} from '@CashFlow/Expenses/ExpenseDetails/PrincipalInfo';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {AddItemTable} from '@CashFlow/Expenses/ExpenseDetails/AddItemTable';
import {useNavigate} from 'react-router-dom';
import {COOWNER_BENEFICIARY} from '@/components/Quoter/CoownerBeneficiary/constants';
import {EXPENSE_DETAILS_NEW} from '@CashFlow/Expenses/ExpenseDetails/ConstantNew';
import CreateProvider from '@/components/CashFlow/CreateProvider/CreateProvider';
import {
    getAllExpensesFormData,
    getImageForm,
    getImageFormProp,
    getImageRecieveForm,
    getImageRecieveFormProp,
    resetAllFormDataExpenses,
} from '@CashFlow/Expenses/Redux/Actions/GetExpensessDataAction';
import {RootState} from '@/config/store';
import {formatDate} from '@/hooks/currentFormatUtils';
import {DateTime} from 'luxon';
import {postCreateExpenseAction} from '@components/CashFlow/Redux/Actions/PostCreateExpense';
import {selectTableAction} from '@components/CashFlow/Redux/Actions/SelectTableAction';

export const ExpenseDetailsCreate = () => {
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: {errors},
    } = useForm<IExpendForm>({
        resolver: yupResolver(expendFormSchema),
        defaultValues: DEFAULT_FORM,
    });
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string,
        shallowEqual
    );
    const {fields, append} = useFieldArray({
        control,
        name: 'items',
    });
    const watchedValues = watch();
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [filePropierties, setPropierties] = useState<{name: string; size: number}>({
        name: '',
        size: 0,
    });
    const [fileReceipProperties, setPropiertiesReceipt] = useState<{name: string; size: number}>({
        name: '',
        size: 0,
    });
    const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IExpendForm>(DEFAULT_FORM);
    const navigate = useNavigate();

    const FormExpensesData: IPropsFormExpense = useSelector(
        (state: RootState) => state.getFormExpenses,
        shallowEqual
    );
    const branchIdUser = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId ?? null,
        shallowEqual
    );

    const onSubmit = async (data: IExpendForm) => {
        const mappedItems = data.items.map((item) => {
            const json = {
                quantity: item.count,
                description: item.article,
                amount: Number(
                    item.cost.replaceAll('$', '').replaceAll(',', '').replaceAll('.', '')
                ),
                amountWithVat: Number(
                    item.iva.replaceAll('$', '').replaceAll(',', '').replaceAll('.', '')
                ),
            };
            return json;
        });

        const postForm = {
            branchId: branchIdUser,
            expenseDetails: {
                expenseTypeId: data.expendType.value,
                expenseProviderId: 1,
                paymentMethodId: data.paymentMethod.value,
                referenceNumber: data.transferNumber,
                date: today,
                expenseDetails: mappedItems,
                remarks: data.description,
                receiptBase64: selectedFile,
                invoiceBase64: selectedReceipt,
                receiptFileName: fileReceipProperties.name,
                invoiceFileName: filePropierties.name,
            },
        };
        try {
            await dispatch(postCreateExpenseAction(token, postForm));
            dispatch(selectTableAction(3));
            dispatch(resetAllFormDataExpenses());
        } catch (error) {
            return error;
        } finally {
            navigate('/CashFlow');
        }
    };
    const updatedFormValues = useMemo(() => {
        return {
            ...formValues,
            ...watchedValues,
            items: watchedValues.items.map((item, index) => ({
                ...formValues.items[index],
                ...item,
            })),
        };
    }, [formValues, watchedValues]);

    useEffect(() => {
        if (JSON.stringify(formValues) !== JSON.stringify(watchedValues)) {
            setFormValues(updatedFormValues);
            dispatch(getAllExpensesFormData(watchedValues));
        }
    }, [updatedFormValues, watchedValues, dispatch]);
    const today = useMemo(() => DateTime.now(), []);

    useEffect(() => {
        reset(FormExpensesData.dataFormExpense);
        setValue('date', formatDate(today, 'dd/MM/yyyy'));
    }, [today, reset, setValue]);

    const handleSetSelectedFile = useCallback(() => {
        setSelectedFile(FormExpensesData.imagePayment);
        setPropierties(FormExpensesData.imagePaymentProp);
        setSelectedReceipt(FormExpensesData.imageRecieve);
        setPropiertiesReceipt(FormExpensesData.imageRecieveProp);
    }, [FormExpensesData]);
    useEffect(() => {
        if (
            (FormExpensesData.imagePayment && FormExpensesData.imagePayment?.length > 0) ||
            (FormExpensesData.imagePaymentProp !== null &&
                FormExpensesData.imagePaymentProp.name) ||
            (FormExpensesData.imageRecieve && FormExpensesData.imageRecieve?.length > 0) ||
            (FormExpensesData.imageRecieveProp !== null && FormExpensesData.imageRecieveProp.name)
        ) {
            handleSetSelectedFile();
        }
    }, [FormExpensesData, handleSetSelectedFile]);
    useEffect(() => {
        if (filePropierties) {
            dispatch(getImageForm(selectedFile));
            dispatch(getImageFormProp(filePropierties));
            dispatch(getImageRecieveForm(selectedReceipt));
            dispatch(getImageRecieveFormProp(fileReceipProperties));
        }
    }, [selectedFile, filePropierties, selectedReceipt, fileReceipProperties]);

    return (
        <>
            {isOpen && <CreateProvider onClose={() => setIsOpen(false)} />}
            <ContainerForm
                FlexDir="column"
                Padding="2rem"
                Justify="start"
                Gap="1rem"
                background="#FAFAFA"
                OverFlow=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ContainerFlex Height="1rem" Radius="16px" Justify="space-between">
                    <Text FontSize="1.4rem" FontWeight="700">
                        {EXPENSE_DETAILS_NEW.NewExpens}
                    </Text>
                    <Text FontSize="1.4rem" FontWeight="700">
                        {EXPENSE_DETAILS.total}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Padding="1rem"
                    backG="white"
                    Gap="16px"
                >
                    <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                        <Text FontSize="1.2rem" FontWeight="500">
                            {EXPENSE_DETAILS.principalInfo}
                        </Text>
                    </ContainerFlex>
                    <PrincipalInformation
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        field={fields}
                        append={append}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" backG="white" Gap="1rem">
                    <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                        <Text FontSize="1.2rem" FontWeight="500">
                            {EXPENSE_DETAILS.expenseDetails}
                        </Text>
                    </ContainerFlex>
                    <AddItemTable
                        control={control}
                        register={register}
                        errors={errors}
                        field={fields}
                        setValue={setValue}
                        append={append}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontWeight="500">
                            {EXPENSE_DETAILS.observations}
                            <Text FontWeight="500" Color="#A1A5AA">
                                &nbsp;{EXPENSE_DETAILS.optional}
                            </Text>
                        </Text>
                        <TextArea
                            {...register('description')}
                            rows={6}
                            error={!!errors?.description?.message}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" backG="white" Gap="2rem">
                    <ContainerFlex Height="1.5rem" Width="100%" Justify="start">
                        <Text FontSize="1.2rem" FontWeight="500">
                            {EXPENSE_DETAILS.attached}
                        </Text>
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                        <Text FontWeight="600">
                            {EXPENSE_DETAILS.proofOfPayment}
                            <Text FontWeight="600" Color="#A1A5AA">
                                &nbsp;{EXPENSE_DETAILS.optional}
                            </Text>
                        </Text>

                        <UploadFile
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            fileProps={filePropierties}
                            setProps={setPropierties}
                        />
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                        <Text FontWeight="600">{EXPENSE_DETAILS.receipt}</Text>
                        <UploadFile
                            selectedFile={selectedReceipt}
                            setSelectedFile={setSelectedReceipt}
                            fileProps={fileReceipProperties}
                            setProps={setPropiertiesReceipt}
                        />
                        <Text FontWeight="300">{EXPENSE_DETAILS.XMLformat}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex ColumnGap="16px">
                    <ContainerFlex
                        Radius="32px"
                        Border="1px solid #5A5AFF"
                        Padding="8px 16px"
                        Width="15.6%"
                        Cursor="pointer"
                        onClick={() => {
                            navigate('/CashFlow');
                            dispatch(resetAllFormDataExpenses());
                        }}
                    >
                        <Text FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                            {COOWNER_BENEFICIARY.Cancel}
                        </Text>
                    </ContainerFlex>
                    <ButtonGeneral text={'Guardar'} width="15.6%" />
                </ContainerFlex>
            </ContainerForm>
        </>
    );
};
