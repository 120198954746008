import {IDocumentFoundry, IDocumentFoundryRedux} from '@/components/Foundry/interfaces';
import {
    GET_DOCUMENT_HEADER,
    GET_DOCUMENT_HEADER_SUCCESS,
    GET_DOCUMENT_HEADER_ERROR,
    GET_DOCUMENT_BODY,
    GET_DOCUMENT_BODY_SUCCESS,
    GET_DOCUMENT_BODY_ERROR,
    PUT_DOCUMENT_FINISH,
    PUT_DOCUMENT_FINISH_SUCCESS,
    PUT_DOCUMENT_FINISH_ERROR,
} from '@TypesDocumentFoundry/DocumentFoundryTypes';

const initialState: IDocumentFoundry = {
    documentheader: {},
    documentbody: {},
    error: false,
    loading: false,
};

const documentfoundry = (state = initialState, action: IDocumentFoundryRedux): IDocumentFoundry => {
    switch (action.type) {
        case GET_DOCUMENT_HEADER:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENT_HEADER_SUCCESS:
            return {
                ...state,
                documentheader: action.documentheader,
                loading: false,
            };
        case GET_DOCUMENT_HEADER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_DOCUMENT_BODY:
            return {
                ...state,
                loading: true,
            };
        case GET_DOCUMENT_BODY_SUCCESS:
            return {
                ...state,
                documentbody: action.documentbody,
                loading: false,
            };
        case GET_DOCUMENT_BODY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PUT_DOCUMENT_FINISH:
            return {
                ...state,
                loading: true,
            };
        case PUT_DOCUMENT_FINISH_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_DOCUMENT_FINISH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default documentfoundry;
