import {composeWithDevTools} from 'redux-devtools-extension';
import {combineReducers, applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

import sucursal from '@components/Foundry/AuditAssignment/redux/reducers/AuditAssignmentReducer';
import auditors from '@components/Foundry/AuditAssignment/redux/reducers/AuditorsReducer';
import assignmenttypes from '@components/Foundry/AuditAssignment/redux/reducers/AssignmentTypesReducer';
import foundry from '@components/Foundry/AuditAssignment/redux/reducers/FoundryReducer';
import assignment from '@components/Foundry/ConsultAssignment/redux/reducers/ConsultAssignmentReducer';
import assignmentbyid from '@components/Foundry/EditAssignment/redux/reducers/EditAssigmentReducers';
import assignmentvalidation from '@components/Foundry/Sucursal/redux/reducers/AssignmentValidationReducers';
import menu from '@components/General/MenuAmazon/redux/reducers/MenuReducer';
import productsEcommerce from '@components/dragAndDrop/redux/reducers/reducers';
import Filters from '@components/Electronics/redux/reducer/reducer';
import {
    Customer,
    CustomerProfitability,
    GetSaleDay,
} from '@components/Customer/redux/reducers/CustomerReducer';
import assignmentfilter from '@components/Foundry/ConsultAssignment/redux/reducers/ConsultAssignmentFilterReducer';
import assignmentrecolectiongold from '@components/Foundry/RecolectionGold/redux/reducers/RecolectionGoldReducers';
import auctions from '@components/Foundry/Auction/redux/reducers/AuctionReducers';
import incidentsfoundry from '@components/Foundry/IncidentsFoundry/redux/reducers/IncidentsFoundryReducers';
import documentfoundry from '@components/Foundry/DocumentFoundry/redux/reducers/DocumentFoundryReducers';
import collector from '@components/Foundry/Delivery/redux/reducers/DeliveryReducers';
import employeemanagers from '@components/Foundry/AssignmentFoundry/redux/reducers/AssignmentFoundryReducers';
import securitybagweight from '@components/Foundry/SecurityBagWeight/redux/reducers/SecurityBagWeightReducers';
import securitybagweightjiracas from '@components/Foundry/SecurityBagWeight/redux/reducers/SecurityBagWeightJiracasReducers';
import purity from '@components/Foundry/Purity/redux/reducers/PurityReducers';
import productsshopify from '@components/Shopify/Products/redux/reducers/ProductsShopifyReducers';
import banner from '@components/Ecommerce/redux/reducers/BannerReducers';
import reactivations from '@components/Pawn/redux/reducers/PawnReducers';
import deadline from '@components/DeadLines/DeadlinesIndex/redux/reducers/DeadlineReducer';
import locationReducers from '@components/Locations/AdminLocations/redux/reducers/LocationReducers';
import manageLocationsReducers from '@components/Locations/ManageLocations/redux/reducers/ManageLocationsReducers';
import homeclient from '@components/Shopify/Ecommerce/redux/reducers/HomeClientReducers';
import OrganizationsTree from '@components/OrganizationTreeView/redux/reducers';
import nearByBranches from '@components/CashFlow/Reduxs/Reducer/getNearByBranchesReducer';
import ProductsExchange from '@/components/DeadLines/CreateDeadline/redux/reducers/ProductsexchangeReducer';
import PayQuoter from '@components/Loan/Redux/reducer/PayQuoter';
import balanceCashFlow from '@components/CashFlow/Reduxs/Reducer/BalanceCashFlow';
import requestExcessMotive from '@components/CashFlow/Reduxs/Reducer/RequestExessMotive';
import getAllFlow from '@components/CashFlow/Reduxs/Reducer/GetAllFlowCash';
import getFlowById from '@components/CashFlow/Reduxs/Reducer/GetCashFlowById';
import setDeposited from '@components/CashFlow/Reduxs/Reducer/SetDeposited';
import putAprovedRequest from '@components/CashFlow/Reduxs/Reducer/SetAprovationRequest';
import getBalanceds from '@components/CashFlow/Reduxs/Reducer/GetBalanceBranch';
import getAccounted from '@components/CashFlow/Reduxs/Reducer/EmployeAccount';
import getBranch from '@components/CashFlow/Reduxs/Reducer/GetBranchs';
import getFlowCashDetails from '@components/CashFlowBasic/Redux/Reducers/GetAllFlowCash';
import getAllFlowExcessMotive from '@components/CashFlow/Reduxs/Reducer/GetAllExcessMotive';
import getAllFlowExcessFoundry from '@components/CashFlow/Reduxs/Reducer/GetAllExcedentFoundrySearch';
import putAprovedExcess from '@components/CashFlow/Reduxs/Reducer/PutStatusExcedent';
import getCatalogExcess from '@components/CashFlow/Reduxs/Reducer/CatalogExcedent';
import getExcedentById from '@components/CashFlow/Reduxs/Reducer/GetExcedentById';
import GetBalanceTree from '@components/CashFlow/Reduxs/Reducer/GetBalanceTree';
import currentPaged from '@components/DeadLines/DeadlinesIndex/redux/reducers/paginatedData';
import {getDeadlinedAll} from '@components/DeadLines/CreateDeadline/redux/reducers/GetAlPromotionsServices';
import deadLinesRoutes from '@components/DeadLines/DeadlinesIndex/redux/reducers/SearchReducer';
import DataLevels from '@components/DeadLines/DeadlinesIndex/redux/reducers/DataReducer';
import deadlineSimulator from '@/components/DeadLines/CreateDeadline/redux/reducers/DeadLineSimulator';
import setRequest from '@components/CashFlow/Reduxs/Reducer/SolState';
import getClient from '@components/Quoter/Redux/Reducers/SearchClientReducer';
import infoQuoted from '@components/Quoter/Redux/Reducers/StepsReducers';
import getPromotionsQuoter from '@components/Quoter/Redux/Reducers/GetPromotionsReducers';
import CardProducts from '@components/Loan/Redux/reducer/CardProducts';
import getCoOwners from '@components/Quoter/Redux/Reducers/GetAllCoOwner';
import statusQuoter from '@components/Quoter/Redux/Reducers/StatusStepOne';
import propertiesArticle from '@components/Quoter/Redux/Reducers/PropertiesArticle';
import postCarPawn from '@components/Quoter/Redux/Reducers/CreateTemporalyPawn';
import getNacionality from '@components/Quoter/Redux/Reducers/GetNacionality';
import getAllBenefist from '@components/Quoter/Redux/Reducers/AllBenefist';
import {CarValuatorId} from '@components/Shopify/Products/redux/reducers/CarClient';
import requestCashFlow from '@components/CashFlow/Reduxs/Reducer/RequestCashFlow';
import NavMenuVisible from '@components/Layout/redux/reducer/MenuBar';
import getAllLevelsOrganization from '@components/OrganizationCreation/Redux/Reducer/GetAllLevels';
import GetLevelsData from '@components/OrganizationCreation/Redux/Reducer/LevelTree';
import searchLevels from '@components/OrganizationCreation/Redux/Reducer/SearchLevel';
import GetStatmentData from '@components/OrganizationCreation/Redux/Reducer/CheckedState';
import GetContactCompany from '@components/MyCompany/Redux/Reducers/GetContactCompany';
import GetMotiveDelete from '@components/MyCompany/Redux/Reducers/GetMotiveDelete';
import GetDataContactPonzu from '@components/MyCompany/Redux/Reducers/GetDataContactPonzu';
import GetPrincipalContactCompany from '@components/MyCompany/Redux/Reducers/GetInfoContactCompany';
import GetAllContactData from '@components/MyCompany/Redux/Reducers/GetAllInfoContactCompany';
import PostMotiveDelete from '@components/MyCompany/Redux/Reducers/PostMotiveDelete';
import getDataRolName from '@components/Roles/AddRoles/Redux/Reducers/getDataRolNameReducer';
import getSearchedUsers from '@components/Roles/AddRoles/Redux/Reducers/getSearchedUsersReducer';
import getSearchedOrganizations from '@components/Roles/AddRoles/Redux/Reducers/getSearchedOrganizationsReducer';
import getDataModules from '@components/Roles/AddRoles/Redux/Reducers/getDataModulesReducer';
import AllRolesSetting from '@components/Roles/Redux/Reducer/AllRolesSetting';
import SearchRoleNameValidation from '@components/Roles/Redux/Reducer/SearchRoleNameValidation';
import PutDataContact from '@components/MyCompany/Redux/Reducers/PutDataContact';
import GetSizeCompany from '@components/Roles/Redux/Reducer/GetSizeCompany';
import CreateStructure from '@components/Roles/Redux/Reducer/CreateStructure';
import postAddRol from '@components/Roles/AddRoles/Redux/Reducers/postNewRolReducer';
import PostDataContactAdd from '@components/MyCompany/Redux/Reducers/PostDataContactAdd';
import PostEmailContact from '@components/MyCompany/Redux/Reducers/PostEmailContact';
import GetOptionsUserNames from '@components/CreateUsers/Redux/Reducers/GetListUserNames';
import GetAllUsers from '@components/CreateUsers/Redux/Reducers/GetAllUsers';
import GetUserName from '@components/CreateUsers/Redux/Reducers/GetUserName';
import UpdateRoleState from '@components/Roles/Redux/Reducer/UpdateRoleState';
import GetEmailInUse from '@components/CreateUsers/Redux/Reducers/GetEmailInUse';
import GetPhoneInUse from '@components/CreateUsers/Redux/Reducers/GetPhoneInUse';
import DeleteRolesR from '@components/Roles/Redux/Reducer/DeleteRoles';
import editInfoRol from '@components/Roles/Redux/Reducer/EditRolReducer';
import getOrganizationsTree from '@components/General/BranchTree/Redux/Reducers/getOrganizationsReducer';
import ModalStates from '@components/Roles/Redux/Reducer/ModalStates';
import GetAllBranches from '@/components/Branches/Redux/Reducer/GetAllBranches';
import PostChangePass from '@components/CreateUsers/Redux/Reducers/PostChangePassword';
import postImageUser from '@components/ManageUser/Redux/Reducers/postImageUserReducer';
import getUserInformation from '@components/ManageUser/Redux/Reducers/GetUserInformation';
import putAccessToSystemUser from '@components/ManageUser/Redux/Reducers/putAccessToSystemUserReducer';
import getAccessToSystemUser from '@components/ManageUser/Redux/Reducers/geAccessToSystemUserReducer';
import getPasswordRandom from '@components/ManageUser/Redux/Reducers/getPasswordRandomReducer';
import putDataUserEmployee from '@components/ManageUser/Redux/Reducers/putDataUserEmployeeReducer';
import getImageUser from '@components/ManageUser/Redux/Reducers/getImageUserReducer';
import dataFormCreateUserForm from '@components/CreateUsers/Redux/Reducers/datafFormCreateUser';
import GetCatCountry from '@/components/Branches/Redux/Reducer/GetCatCountry';
import GetTieredLevel from '@/components/Branches/Redux/Reducer/GetTieredLevel';
import CreateBranch from '@/components/Branches/Redux/Reducer/CreateBranch';
import GetInformationBranch from '@/components/Branches/ManageBranch/Redux/Reducer/GetInformationBranch';
import GetBranchUsers from '@/components/Branches/ManageBranch/Redux/Reducer/GetBranchUsers';
import GetCatCity from '@/components/Branches/ManageBranch/Redux/Reducer/GetCatCity';
import GetCatMunicipality from '@/components/Branches/ManageBranch/Redux/Reducer/GetCatMunicipality';
import GetCatSuburb from '@/components/Branches/ManageBranch/Redux/Reducer/GetCatSuburb';
import GetCatState from '@/components/Branches/ManageBranch/Redux/Reducer/GetCatState';
import GetPostalInfo from '@/components/Branches/ManageBranch/Redux/Reducer/GetPostalInfo';
import GetSearchBranches from '@/components/Branches/Redux/Reducer/GetSearchBranches';
import GetSearchUsers from '@/components/Branches/ManageBranch/Redux/Reducer/GetSearchUsers';
import cashFlowScreens from '@/components/CashFlow/Reduxs/Reducer/tableReducer';
import filtersCash from '@/components/CashFlow/Reduxs/Reducer/savedFilters';
import PutUpdateBranchStatus from '@/components/Branches/Redux/Reducer/PutUpdateBranchStatus';
import PostValidateRol from '@/components/Roles/Redux/Reducer/PostValidateRol';
import deadlinesGeneral from '@/components/DeadLines/CreateDeadline/redux/reducers/DeadlinesGeneral';
import PutOtherInfo from '@/components/Branches/ManageBranch/Redux/Reducer/PutOtherInfo';
import PutMainInfo from '@/components/Branches/ManageBranch/Redux/Reducer/PutMainInfo';
import PutComplementaryInfo from '@/components/Branches/ManageBranch/Redux/Reducer/PutComplementaryInfo';
import CreateUserBranch from '@/components/Branches/ManageBranch/Redux/Reducer/CreateUserBranch';
import TreeBranchesLocations from '@/components/Locations/ManageLocations/redux/reducers/TreeLocations';
import currentPageFlow from '@/components/CashFlow/Reduxs/Reducer/currentPageFlow';
import GetBranchSchedule from '@/components/Branches/ManageBranch/Redux/Reducer/GetBranchSchedule';
import PutBranchSchedule from '@/components/Branches/ManageBranch/Redux/Reducer/PutBranchSchedule';
import GetServicesComplements from '@/components/Branches/ManageBranch/Redux/Reducer/GetServicesComplements';
import PostServicesComplements from '@/components/Branches/ManageBranch/Redux/Reducer/PostServicesComplements';
import PutServicesComplements from '@/components/Branches/ManageBranch/Redux/Reducer/PutServicesComplements';
import GetCashFlowSetup from '@/components/Branches/ManageBranch/Redux/Reducer/GetCashFlowSetup';
import PostCashFlowSetup from '@/components/Branches/ManageBranch/Redux/Reducer/PostCashFlowSetup';
import PutCashFlowSetup from '@/components/Branches/ManageBranch/Redux/Reducer/PutCashFlowSetup';
import GetBranchDistance from '@/components/Branches/ManageBranch/Redux/Reducer/GetBranchDistance';
import getFlowNotify from '@/components/CashFlowBasic/Redux/Reducers/CashFlowNotifyRedux';
import updateFlowNotify from '@/components/CashFlowBasic/Redux/Reducers/UpdateCashFlowNotify';
import GetBranchDistanceReceive from '@/components/Branches/ManageBranch/Redux/Reducer/GetBranchDistanceReceive';
import GetScheduleEndeavor from '@/components/Branches/ManageBranch/Redux/Reducer/GetScheduleEndeavor';
import GetScheduleDischarge from '@/components/Branches/ManageBranch/Redux/Reducer/GetScheduleDischarge';
import PutScheduleEndeavor from '@/components/Branches/ManageBranch/Redux/Reducer/PutScheduleEndeavor';
import PutScheduleDischarge from '@/components/Branches/ManageBranch/Redux/Reducer/PutScheduleDischarge';
import DeleteLevels from '@/components/OrganizationCreation/Redux/Reducer/DeleteLevels';
import CreateLevels from '@/components/OrganizationCreation/Redux/Reducer/CreateLevels';
import UpdateLevels from '@/components/OrganizationCreation/Redux/Reducer/UpdateLevels';
import UpdateStatusLevel from '@/components/OrganizationCreation/Redux/Reducer/UpdateStatusLevel';
import getFlowId from '@/components/CashFlowBasic/Redux/Reducers/saveCashId';
import BranchIdCash from '@/components/CashFlow/Reduxs/Reducer/BranchIdCash';
import GetLoginProblems from '@/components/LoginClient/Redux/Reducer/GetLoginProblems';
import CreateProblems from '@/components/LoginClient/Redux/Reducer/CreateProblems';
import getUserDataBranch from '@/components/CloseBranch/redux/reducer/getUserDataBranch';
import putUserBranchInfo from '@/components/CloseBranch/redux/reducer/PutUserDataInfo';
import endeavorsClient from '@/components/Shopify/Endeavors/redux/reducers/EndeavorsClient';
import saveDataBenefiaciary from '@/components/Quoter/Redux/Reducers/AllBeneficiary';
import saveDataCoOwner from '@/components/Quoter/Redux/Reducers/PostCoOwner';
import putDataBenefiaciary from '@/components/Quoter/Redux/Reducers/PutBeneficiary';
import putDataCoOwners from '@/components/Quoter/Redux/Reducers/PutCoOwner';
import deleteDataCoOwner from '@/components/Quoter/Redux/Reducers/DeleteCoOwner';
import deleteDataBeneficiary from '@/components/Quoter/Redux/Reducers/DeleteBeneficiary';
import getUsersValidation from '@/components/Login/Redux/Reducer/GetValidatedUsers';
import saveDataSingUp from '@/components/SignUp/Redux/Reducers/saveDataFormReducer';
import validateEmailAdministrator from '@/components/SignUp/Redux/Reducers/validateEmailReducer';
import catCompanyBenefits from '@/components/SignUp/Redux/Reducers/companyBenefitReducer';
import catPlaceAndBusiness from '@/components/SignUp/Redux/Reducers/catPlaceAndBusiness';
import saveEndeavor from '@/components/Quoter/Redux/Reducers/saveEndeavor';
import modulesAdmistrator from '@/components/SideBarMenu/Redux/modulesAdministratorReducer';
import ResendEmailValidation from '@/components/Login/Redux/Reducer/ResendEmailValidation';
import SearchQuoter from '@/components/Quoter/Redux/Reducers/SearchArticle';
import adminUserProfileSettings from '@/components/AdminNavbar/Redux/adminUserProfileSettingsReducer';
import getModulesData from '@/components/Home/HomeEmployee/Redux/Reducers/getModulesPosReducer';
import getHomeAdminPercentage from '@/components/Home/Admin/Redux/Reducer/GetHomeAdminPercentage';
import getNavBarPos from '@/components/NavBarPOS/Redux/Reducer/GetNavBarPos';
import getFastActions from '@/components/NavBarPOS/Redux/Reducer/getFastActions';
import propertiesArticleRequest from '@/components/Quoter/Redux/Reducers/PropiertiesArticlesRequest';
import getSearchAllUsers from '@/components/Users/Redux/Reducers/searchAllUsersReducer';
import TreeGroupBranches from '@/components/CreateUsers/Redux/Reducers/TreeGroupsAndBranches';
import validateLink from '@/components/Login/Redux/Reducer/validationLinkExpiredReducer';
import updateStatusLink from '@/components/Login/Redux/Reducer/updateSatusLinkExpiredReducer';
import CreateDataUser from '@/components/CreateUsers/Redux/Reducers/CreateDataUser';
import {saveUser} from '@/components/CreateUsers/Redux/Reducers/saveUsers';
import {getScreenUserStates} from '@/components/LoginClient/Redux/Reducer/LoginScreen';
import {editUsers} from '@/components/CreateUsers/Redux/Reducers/editUsers';
import updateStateUsers from '@/components/Users/Redux/Reducers/updateUsersStatusReducer';
import addCatalogRequest from '@/components/Quoter/Redux/Reducers/AddCatalogArticleRequest';
import GetRolesInformation from '@/components/Roles/Redux/Reducer/GetRolesInformation';
import GetEmployeeUserSearch from '@/components/Roles/Redux/Reducer/GetEmployeeUserSearch';
import getRequestsTable from '@/components/Articles/CatalogRequestTable/Redux/getHighLevelRequestsReducers';

import getCartContracts from '@/components/ShoppingCart/Redux/Reducer/getCartContractsReducer';
import deleteItemPledgeReducer from '@/components/ShoppingCart/Redux/Reducers/deleteItemPledgeReducer';
import clientList from '@/components/ShoppingCart/Redux/Reducers/ClientList';
import newClientProcess from '@/components/Shopify/ListClient/redux/Reducers/newClientProcessReducer';
import getHandsFingers from '@/components/Shopify/ListClient/redux/Reducers/getHandsFingers';
import getPromotions from '@/components/Quoter/PromotionsRedux/Redux/GetPromotions';
import getAllNations from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/getAllNationsReducer';
import treePermissions from '@/components/Roles/CreateRoles/Redux/Reducer/TreePermissions';
import CreateUpdateRole from '@/components/Roles/CreateRoles/Redux/Reducer/CreateUpdateRole';
import getInfoContracts from '@/components/ShoppingCart/Redux/Reducers/getInfoContractReducer';
import {getClientInfo} from '@/components/Shopify/Products/redux/reducers/GetInfoClient';
import {getCarContracts} from '@/components/Shopify/Products/redux/reducers/GetCarContracts';
import getAllCoowner from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/GetAllCoOwnerReducer';
import addCoownerBeneficiary from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/AddCoOwnerBeneficiaryReducer';
import UploadArticleImages from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/UploadArticleImages';
import {PersistenceRole} from '@/components/Roles/CreateRoles/Redux/Reducer/PersistenceRole';
import {RoleById} from '@/components/Roles/CreateRoles/Redux/Reducer/RoleById';
import UpdateCoOwner from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/UpdateCoOwner';
import formAddCoOwner from '@components/Quoter/CoownerBeneficiary/Redux/Reducers/FormAddCoOwnerReducer';
import UserRoles from '@/components/Roles/CreateRoles/Redux/Reducer/UserRoles';
import {removeRole} from '@/components/Roles/RemoveRole/Redux/Reducer/RemoveRole';
import GetSearchUserByRole from '@/components/Roles/CreateRoles/Redux/Reducer/GetUserByRole';
import AddPaymentReducer from '@/components/Loan/Redux/reducer/AddPaymentReducer';
import cartPaymentReducer from '@/components/Loan/Redux/reducer/AddCartPaymentReducer';
import {loadingReducer} from '@/components/General/Atoms/LoadingAtaskate/Redux/Reducers/loadingReducer';
import {selectTypesLocations} from '@/components/LocationsBranch/Redux/Reducer/SelectOptionsLocation';
import {affectedRoles} from '@/components/Roles/CreateRoles/Redux/Reducer/AffectedRoles';
import {CreateNewGroup} from '@/components/LocationsBranch/Redux/Reducer/CreateNewGroup';
import GetAssignmentClient from '@/components/Shopify/ListClient/redux/Reducers/GetAssignmentClient';
import {cardClientProfile} from '@/components/Shopify/ClientProfile/Redux/Reducer/CardProfileById';
import clientDetails from '@/components/Shopify/ClientProfile/Redux/Reducer/GetClientDetails';
import getScreenSelect from '@/components/Login/Redux/Reducer/ChangeScreen';
import getAllValidateType from '@/components/PersonalLoans/Redux/Reducers/GetAllValidateIdTypeReducer';
import ValidateBankCardType from '@/components/AccountSettings/Redux/Reducers/ValidateBankCardType';
import AddCompanyPaymentMethod from '@/components/AccountSettings/Redux/Reducers/AddPaymentMethod';
import GetCompanyPaymentMethod from '@/components/AccountSettings/Redux/Reducers/GetCompanyPaymentMethod';
import GetAllCatEmploymentSituation from '@/components/PersonalLoans/Redux/Reducer/GetAllCatEmploymentSituation';
import GetAllCatLaborOld from '@/components/PersonalLoans/Redux/Reducer/GetAllCatLaborOld';
import DataStepLoans from '@/components/PersonalLoans/Redux/Reducer/DataStepLoans';
import getUsersMarketValidation from '@/components/Login/MarketPlaceLogin/Redux/Reducer/ValidateEmailForMarketPlace';
import codeValideReducer from '@/components/MarketLogin/redux/reducers/codeValideReducer';
import SelectedPledgeReducer from '@/components/Quoter/CoownerBeneficiary/Redux/Reducers/SelectedPledgeReducer';
import {verifyCode} from '@/components/MarketLogin/redux/reducers/codeVerifyReducer';
import creaateUserData from '@/components/Login/MarketPlaceLogin/Redux/Reducer/CreateUserMarketPlace';
import {newValidationCodeMarket} from '@/components/MarketLogin/redux/reducers/newValidationCodeReducer';
import {detailsCompany} from '@/components/MyCompany/Redux/Reducers/DetailsCompany';
import {updateImgCompany} from '@/components/MyCompany/Redux/Reducers/UpdateImgCompany';
import SaveLoansUser from '@/components/PersonalLoans/Redux/Reducer/SaveLoansUser';
import SaveLoansJob from '@/components/PersonalLoans/Redux/Reducer/SaveLoansJob';
import CreateEmploymentSituation from '@/components/PersonalLoans/Redux/Reducer/CreateEmploymentSituation';
import CreatePersonalLoans from '@/components/PersonalLoans/Redux/Reducer/CreatePersonalLoans';
import CodePasswordChange from '@/components/MarketForgottenLogin/Redux/Reducers/ResetPassCodeReducer';
import {newResetCodeClient} from '@/components/MarketForgottenLogin/Redux/Reducers/NewResetCodeReducer';
import {verifyCodeClient} from '@/components/MarketForgottenLogin/Redux/Reducers/VerifyCodeClientReducer';
import {EmailResetCodeClient} from '@/components/MarketForgottenLogin/Redux/Reducers/EmailResetClientReducer';
import addEmail from '@/components/MarketForgottenLogin/Redux/Reducers/emailReducer';
import {NewpasswordUser} from '@/components/MarketForgottenLogin/Redux/Reducers/NewPasswordReducer';
import ArticleManagementSearch from '@/components/Articles/Redux/Reducer/SearchManagement';
import SavedLevels from '@/components/Articles/Redux/Reducer/SavedLevels';
import ArticleLevel from '@/components/Articles/Redux/Reducer/ArticlesLevel';
import SearchArticles from '@/components/Articles/Redux/Reducer/RoutesArticles';
import DetailFields from '@/components/Articles/Redux/Reducer/FieldDinamics';
import GetAddLevel from '@/components/Articles/Redux/Reducer/GetAddLevel';
import CreateNewCatalogId from '@/components/Articles/Redux/Reducer/CreateNewCatalogId';
import GetAllCatArticleTypeDetailsReducer from '@/components/Catalogue/Redux/Reducer/GetAllCatArticleTypeDetailsReducer';
import SaveNewDynamicFeatureReducer from '@components/Catalogue/Redux/Reducer/SaveNewDynamicFeatureReducer';
import GetInheritedFeatures from '@/components/Articles/Redux/Reducer/GetInheritedFeatures';
import getUsersClientsValidation from '@/components/MarketAccessLogin/Redux/reducers/AccessLoginReducer';
import {getBusinessLines} from '@/components/MyPlan/Redux/Reducers/GetBusinessLines';
import {getContractedPlans} from '@/components/MyPlan/Redux/Reducers/GetContractedPlans';
import getSalesChannels from '@/components/MyPlan/Redux/Reducers/GetSalesChannels';
import UpdateNewCatalogId from '@/components/Articles/Redux/Reducer/UpdateNewCatalogId';
import GetDetailsCharacteristics from '@/components/Articles/Redux/Reducer/GetDetailsCharacteristics';
import GetNewCatalogId from '@/components/Articles/Redux/Reducer/GetNewCatalogId';
import getAllCatFrequency from '@/components/CreditQuoter/Redux/Reducer/GetAllCatFrequencyReducer';
import getAllCatPersonalLoansPeriod from '@components/CreditQuoter/Redux/Reducer/GetAllCatPersonalLoansPeriodReducer';
import getQuoteCreditPersonalLoans from '@/components/CreditQuoter/Redux/Reducer/GetQuoteCreditPersonalLoansReducer';
import getTableAmortization from '@/components/CreditQuoter/Redux/Reducer/GetTableAmortizationReducer';
import getActivateLevelOrItem from '@/components/Catalogue/Redux/Reducer/GetActivateLevelOrItemReducer';
import {getCreateGroupBranch} from '@/components/LocationsBranch/Redux/Reducer/GetCreateGroupBranch';
import GetCompanyTaxData from '@/components/AccountSettings/Redux/Reducers/GetCompanyTaxData';
import UpdateCompanyTaxData from '@/components/AccountSettings/Redux/Reducers/UpdateTaxData';
import getCompanyLevels from '@/components/LocationsBranch/Redux/Reducer/GetCompanyLevels';
import getListLevels from '@/components/LocationsBranch/Redux/Reducer/GetListLevelsByLevelId';
import levelGroups from '@/components/LocationsBranch/Redux/Reducer/LevelGroups';
import getSearchLevelsAndBranch from '@/components/LocationsBranch/Redux/Reducer/SearchLevelsAndBranch';
import {getAllTotalAvailables} from '@/components/CreditsAndPawns/Redux/Reducer/GetAllTotalAvailablesReducer';
import getDetailsCredit from '@/components/CreditsAndPawns/Redux/Reducer/GetDetailsCreditReducer';
import getListLoans from '@/components/CreditsAndPawns/Redux/Reducer/getListLoansReducer';
import getCustomerInfo from '@/components/CreditsAndPawns/Redux/Reducer/GetCustomerInformationReducer';
import {catAddress} from '@/components/LocationsBranch/Redux/Reducer/CatAddress';
import {AddUserEmployee} from '@components/LocationsBranch/Redux/Reducer/AddUser';
import GetUserByLevelId from '@/components/LocationsBranch/Redux/Reducer/GetUserByLevel';
import delUsersLoctations from '@/components/LocationsBranch/Redux/Reducer/DelUsers';
import Warehouse from '@/components/LocationsBranch/Redux/Reducer/Warehouse';
import getCashflowDetails from '@/components/CashFlow/Redux/Reducer/GetDetails';
import getUserDetails from '@/components/CashFlow/Redux/Reducer/GetUserDetails';
import {AddNewLevel} from '@/components/RequestNewCatalogLevel/Redux/Reducers/AddNewLevelReducer';
import GetActionPayment from '@/components/QuickPayment/Redux/Reducer/GetActionPayment';
import getStatusApplicationValidate from '@/components/PersonalLoans/CreditStatus/Redux/Reducer/GetStatusApplicationValidateReducer';
import getAllCatBank from '@/components/PersonalLoans/Redux/Reducer/GetAllCatBankReducer';
import getCreditLoanSummary from '@/components/PersonalLoans/Redux/Reducer/GetCreditLoanSummaryReducer';
import addWireTransfer from '@/components/PersonalLoans/Redux/Reducer/AddWireTransferPersonalLoanReducer';
import {newGroup} from '@/components/LocationsBranch/Redux/Reducer/NewGroup';
import {groupBranchPersistence} from '@/components/LocationsBranch/Redux/Reducer/GroupBranchPersistence';
import {newBranch} from '@/components/LocationsBranch/Redux/Reducer/NewBranch';
import {getAddress} from '@/components/LocationsBranch/Redux/Reducer/AddAddressLocation';
import getFilterListLoans from '@components/CreditsAndPawns/Redux/Reducer/GetFilterListLoansReducer';
import getFormExpenses from '@/components/CashFlow/Expenses/Redux/Reducer/GetExpexsesDataReducee';
import getCreditAndPawnsHistory from '@/components/CreditsAndPawns/Redux/Reducer/GetCreditAndPawnsHistoryReducer';
import GetApprovedCreditSolicitudeReducer from '@/components/CreditsAndPawns/Redux/Reducer/GetApprovedCreditSolicitudeReducer';
import GetPercentageAmortizationReducer from '@/components/CreditsAndPawns/Redux/Reducer/GetPercentageAmortizationReducer';
import getSearchInventoryStorage from '@/components/LocationsBranch/Redux/Reducer/SearchInventoryStorage';
import searchInventorySelection from '@/components/LocationsBranch/Redux/Reducer/SearchInventorySelection';
import GetPawnDetails from '@/components/TransactionsDetails/Redux/Reducer/getPawnsDetails';
import {sendConfirmationVoucher} from '@/components/QuickPayment/Redux/Reducer/SendConfirmationVoucherReducer';
import {getAdminCashflowReducer} from '@/components/AdminCashflow/Redux/AdminCashflowReducer';
import getPaymentMethods from '@/components/CashFlow/Redux/Reducer/GetPaymentType';
import getExpenseTypes from '@/components/CashFlow/Redux/Reducer/GetExpenseType';
import getBranchProviders from '@/components/CashFlow/Redux/Reducer/GetBranchProviders';
import postCreateExpense from '@/components/CashFlow/Redux/Reducer/PostCreateExpense';
import getExpenses from '@/components/CashFlow/Redux/Reducer/GetExpenses';
import getTransactions from '@/components/CashFlow/Redux/Reducer/GetTransactionCash';
import getExpenseInvoiceReducer from '@/components/CashFlow/Expenses/Redux/Reducer/GetExpenseInvoiceReducer';
import {filtersPersistance} from '@/components/CashFlow/Redux/Reducer/FiltersPersistance';
import {selectTableReducer} from '@/components/CashFlow/Redux/Reducer/SelectTableReducer';
import getExpenseDetails from '@/components/CashFlowExpenseDetails/Redux/ExpenseDetailsReducer';
import putExpenseAuth from '@/components/CashFlowExpenseDetails/Redux/ExpenseAuthorizationReducer';
import getAmortizationTableLoan from '@/components/PersonalLoans/Redux/Reducer/GetAmortizationTableLoanReducer';
import getFastPaymentPersonal from '@/components/QuickPayment/Redux/Reducer/GetFastPaymentPersonalReducer';
import addFastPayment from '@/components/QuickPayment/Redux/Reducer/AddFastPaymentReducer';
import {MyCreditReducer} from '@/components/MyAccount/MyCredit/Redux/Reducers/MyCreditReducer';
import {employeeCashFunding} from '@/components/CashFlowActions/Redux/Reducers/EmployeeByBranchReducer';
import {sendCashFunding} from '@/components/CashFlowActions/Redux/Reducers/SendCashFundingReducer';
import {addFlowCash} from '@/components/CashFlowActions/Redux/Reducers/AddFlowCashReducer';
import {employeeReturnType} from '@/components/CashFlowActions/Redux/Reducers/RertuntypeReducer';
import {retunrExcess} from '@/components/CashFlowActions/Redux/Reducers/RetunrAddExecessReducer';
import SelectedDataNode from '@/components/Articles/Redux/Reducer/SelectedDataNode';
import {quickAction} from '@/components/Shopify/ClientProfile/Redux/Reducer/QuickAction';
import {newStorage} from '@/components/LocationsBranch/Redux/Reducer/NewStorage';
import {getLevels} from '@/components/LocationsBranch/Redux/Reducer/GetLevels';
import addIdentificationDoc from '@/components/Shopify/ClientProfile/Redux/Reducer/AddIdentificationDoc';
import getFrequencyPayments from '@/components/PersonalLoans/NewLoanProduct/Redux/Reducer/GetFrequencyPaymentsReducer';
import setFrequencyPaymentsTable from '@/components/PersonalLoans/NewLoanProduct/Redux/Reducer/FrequencyPaymentsTableReducer';
import {endeavorsContracs} from '@/components/Shopify/ClientProfile/Redux/Reducer/EndeavorsContracts';
import {getClientTransactions} from '@/components/Shopify/ClientProfile/Redux/Reducer/GetTransactions';
import getPersonalCredit from '@/components/Shopify/ClientProfile/Redux/Reducer/GetPersonalCredit';
import {getCatGroups} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetCatGroups';
import {getFamilyGroup} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetFamilyGroup';
import {getSubfamilyManager} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetSubFamilyGroup';
import {getBrand} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetBrandGroup';
import {getItems} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetItemGroup';
import {deatilContract} from '@/components/Shopify/ClientProfile/Redux/Reducer/DetailsContract';
import ScreenStateUsersForm from '@/components/Users/Redux/Reducers/FormUserInfo';
import ScreenStateUsers from '@/components/Users/Redux/Reducers/ScreenUsers';
import getCartByCartId from '@/components/ShoppingCart/Redux/Reducer/GetCartByCartId';
import searchOperations from '@/components/Shopify/ClientProfile/Redux/Reducer/SearchOperations';
import {updateBranch} from '@/components/LocationsBranch/Redux/Reducer/UpdateBranch';
import {getBranchByLevel} from '@/components/LocationsBranch/Redux/Reducer/GetBranchByLevel';
import {getPersonalCreditProductsReducer} from '@/components/LoansProducts/Redux/Reducers/getPersonalCreditProductsReducer';
import {getProductsCustomerUserReducer} from '@/components/LoansProducts/Redux/Reducers/getProductsCustomerUserReducer';
import shoppingCarClient from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Reducer/ShoppingCarClientReducer';
import getCreditMovementHistory from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Reducer/GetCreditMovementHistoryReducer';
import getSolicitudeTermDetails from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Reducer/GetSolicitudeTermDetailsReducer';
import paymentMethods from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Reducer/PaymentMethodsReducer';
import GetPersonalCreditAmountReducer from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Reducer/GetPersonalCreditAmountReducer';
import renewalReducer from '@/components/ShoppingCart/Redux/Reducers/RenewalReducer';
import setPaymentMethods from '@/components/PersonalLoans/NewLoanProduct/Redux/Reducer/PaymentMethodsReducer';
import setChargesAndPenaltiesTable from '@/components/PersonalLoans/NewLoanProduct/Redux/Reducer/ChargesAndPenaltiesTableReducer';
import {getSearchBar} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetSearchBar';
import UpdatePersonalLoans from '@/components/PersonalLoans/Redux/Reducer/UpdatePersonalLoans';
import RejectLoanReducer from '@/components/PersonalLoans/Redux/Reducers/RejectLoanReducer';
import {DocumentDisbursementReducer} from '@/components/PersonalLoans/NewLoanProduct/Redux/Reducer/DocumentDisbursementReducer';
import endorsementsReducer from '@/components/ShoppingCart/Redux/Reducers/EndorsementsReducer';
import getGroupsByCompanyId from '@/components/LocationsBranch/Redux/Reducer/GetGroupsByCompanyId';
import getGroupOpenHours from '@/components/LocationsBranch/Redux/Reducer/AddOpenHours';
import {getSelectedGroupPath} from '@/components/RequestNewCatalogLevel/Redux/Reducers/GetSelectGroup';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'selectTableReducer',
        'filtersPersistance',
        'retunrExcess',
        ' addFlowCash',
        'sendCashFunding',
        'employeeCashFunding',
        'getFormExpenses',
        'NewpasswordUser',
        'addEmail',
        'EmailResetCodeClient',
        'verifyCodeClient',
        'newResetCodeClient',
        'CodePasswordChange',
        'getUsersClientsValidation',
        'newValidationCodeMarket',
        'verifyCodeReducer',
        'getScreenSelect',
        'codeValideReducer',
        'getUsersValidation',
        'clientList',
        'CarValuatorId',
        'getDeadlinedAll',
        'deadlineById',
        'currentPaged',
        'qwoterGeneral',
        'getAllBenefist',
        'getDeadlinedAll',
        'productsshopify',
        'menu',
        'GetBalanceTree',
        'amountTemporal',
        'homeclient',
        'auctions',
        'assignmentvalidation',
        'assignmentrecolectiongold',
        'securitybagweight',
        'reactivations',
        'DeadlineTable',
        'TableAmounts',
        'ProductsExchange',
        'ProductsId',
        'locationReducers',
        'manageLocationsReducers',
        'SearchLoan',
        'SpecsLoan',
        'Instalments',
        'Save',
        'Customer',
        'SearchArticles',
        'getInformation',
        'branchEnterprises',
        'saveBranchEnterprises',
        'InputDinamic',
        'DetailFields',
        'getAllBranchBusiness',
        'nearByBranches',
        'OrganizationsTree',
        'infoQuoted',
        'getProductsQuoted',
        'PayQuoter',
        'GetPrincipalContactCompany',
        'deadLinesRoutes',
        'deadlinesGeneral',
        'getClient',
        'saveDataSingUp',
        'catCompanyBenefits',
        'catPlaceAndBusiness',
        'PersistenceRole',
        'RoleById',
        'saveEndeavor',
        'getModulesData',
        'TreeGroupBranches',
        'CreateDataUser',
        'saveUser',
        'editUsers',
        'PersistenceRole',
        'CreateNewGroup',
        'cardClientProfile',
        'SaveLoansJob',
        'CreatePersonalLoans',
        'CreateEmploymentSituation',
        'SaveLoansUser',
        'DataStepLoans',
        'SelectedPledgeReducer',
        'newClientProcess',
        'getCartContracts',
        'creaateUserData',
        'CreateNewCatalogId',
        'UpdateNewCatalogId',
        'GetDetailsCharacteristics',
        'GetNewCatalogId',
        'delUsersLoctations',
        'groupBranchPersistence',
        'getTransactions',
        'getAddress',
        'Warehouse',
        'getLevels',
    ],
};

const reducers = combineReducers({
    banner: banner,
    getFormExpenses: getFormExpenses,
    locationReducers: locationReducers,
    reactivations: reactivations,
    menu: menu,
    sucursal: sucursal,
    auditors: auditors,
    assignmenttypes: assignmenttypes,
    getScreenUserStates: getScreenUserStates,
    foundry: foundry,
    assignment: assignment,
    assignmentbyid: assignmentbyid,
    assignmentfilter: assignmentfilter,
    assignmentvalidation: assignmentvalidation,
    assignmentrecolectiongold: assignmentrecolectiongold,
    auctions: auctions,
    incidentsfoundry: incidentsfoundry,
    documentfoundry: documentfoundry,
    collector: collector,
    employeemanagers: employeemanagers,
    securitybagweight: securitybagweight,
    securitybagweightjiracas: securitybagweightjiracas,
    purity: purity,
    productsshopify: productsshopify,
    productsEcommerce: productsEcommerce,
    filters: Filters,
    ArticleLevel: ArticleLevel,
    homeclient: homeclient,
    endeavorsClient: endeavorsClient,
    deadline: deadline,
    Customer: Customer,
    OrganizationsTree: OrganizationsTree,
    nearByBranches: nearByBranches,
    ProductsExchange: ProductsExchange,
    CustomerProfitability: CustomerProfitability,
    GetSaleDay: GetSaleDay,
    SearchArticles: SearchArticles,
    PayQuoter: PayQuoter,
    manageLocationsReducers: manageLocationsReducers,
    cashFlowDetails: getFlowCashDetails,
    cashFlowScreens: cashFlowScreens,
    filtersCash: filtersCash,
    DetailFields: DetailFields,
    GetAllFlowcash: getAllFlow,
    balanceCashFlow: balanceCashFlow,
    requestExcessMotive: requestExcessMotive,
    requestCashFlow: requestCashFlow,
    getAllFlow: getAllFlow,
    getFlowById: getFlowById,
    setFlowDeposited: setDeposited,
    setAproveRequest: putAprovedRequest,
    GetBalanceTree: GetBalanceTree,
    getBalanceds: getBalanceds,
    getAccount: getAccounted,
    getAllFlowExcessFoundry: getAllFlowExcessFoundry,
    getAllFlowExcessMotive: getAllFlowExcessMotive,
    putAprovedExcess: putAprovedExcess,
    getBranch: getBranch,
    getCatalogExcess: getCatalogExcess,
    getExcedentById: getExcedentById,
    currentPaged: currentPaged,
    getDeadlinedAll: getDeadlinedAll,
    deadLinesRoutes: deadLinesRoutes,
    deadlineSimulator: deadlineSimulator,
    DataLevels: DataLevels,
    setRequest: setRequest,
    getClient: getClient,
    infoQuoted: infoQuoted,
    CardProducts: CardProducts,
    getCoOwners: getCoOwners,
    getPromotionsQuoter: getPromotionsQuoter,
    statusQuoter: statusQuoter,
    propertiesArticle: propertiesArticle,
    getNacionality: getNacionality,
    postCarPawn: postCarPawn,
    getAllBenefist: getAllBenefist,
    CarValuatorId: CarValuatorId,
    NavMenuVisible: NavMenuVisible,
    getAllLevels: getAllLevelsOrganization,
    GetLevelsData: GetLevelsData,
    searchLevels: searchLevels,
    DeleteLevels: DeleteLevels,
    CreateLevels: CreateLevels,
    UpdateLevels: UpdateLevels,
    UpdateStatusLevels: UpdateStatusLevel,
    GetStatmentData: GetStatmentData,
    GetContactCompany: GetContactCompany,
    GetMotiveDelete: GetMotiveDelete,
    GetDataContactPonzu: GetDataContactPonzu,
    GetPrincipalContactCompany: GetPrincipalContactCompany,
    GetAllContactData: GetAllContactData,
    PostMotiveDelete: PostMotiveDelete,
    AllRoles: AllRolesSetting,
    getDataRolName: getDataRolName,
    getSearchedUsers: getSearchedUsers,
    getSearchedOrganizations: getSearchedOrganizations,
    SearchRole: SearchRoleNameValidation,
    GetSizeCompany: GetSizeCompany,
    CreateStructure: CreateStructure,
    postAddRol: postAddRol,
    PutDataContact: PutDataContact,
    PostDataContactADD: PostDataContactAdd,
    PostEmailContact: PostEmailContact,
    GetOptionsUserNames: GetOptionsUserNames,
    GetAllUsers: GetAllUsers,
    GetUserName: GetUserName,
    GetEmailInUse: GetEmailInUse,
    GetPhoneInUse: GetPhoneInUse,
    UpdateRoleState: UpdateRoleState,
    getDataModules: getDataModules,
    DeleteRolesR: DeleteRolesR,
    editInfoRol: editInfoRol,
    ModalStates: ModalStates,
    postImageUser: postImageUser,
    userInformation: getUserInformation,
    putAccessToSystemUser: putAccessToSystemUser,
    getAccessToSystemUser: getAccessToSystemUser,
    getOrganizationsTree: getOrganizationsTree,
    PostChangePass: PostChangePass,
    getPasswordRandom: getPasswordRandom,
    putDataUserEmployee: putDataUserEmployee,
    getImageUser: getImageUser,
    dataFormCreateUserForm: dataFormCreateUserForm,
    getAllBranches: GetAllBranches,
    getSearchBranches: GetSearchBranches,
    CreateBranch: CreateBranch,
    GetCatCountry: GetCatCountry,
    GetTieredLevel: GetTieredLevel,
    GetInformationBranch: GetInformationBranch,
    PutUpdateBranchStatus: PutUpdateBranchStatus,
    GetBranchUsers: GetBranchUsers,
    GetSearchUsers: GetSearchUsers,
    GetCatCity: GetCatCity,
    GetCatMunicipality: GetCatMunicipality,
    GetCatSuburb: GetCatSuburb,
    GetCatState: GetCatState,
    GetPostalInfo: GetPostalInfo,
    deadlinesGeneral: deadlinesGeneral,
    PostValidateRol: PostValidateRol,
    PutOtherInfo: PutOtherInfo,
    PutMainInfo: PutMainInfo,
    PutComplementaryInfo: PutComplementaryInfo,
    CreateUserBranch: CreateUserBranch,
    TreeBranchesLocations: TreeBranchesLocations,
    currentFlow: currentPageFlow,
    GetBranchSchedule: GetBranchSchedule,
    PutBranchSchedule: PutBranchSchedule,
    GetScheduleEndeavor: GetScheduleEndeavor,
    GetScheduleDischarge: GetScheduleDischarge,
    PutScheduleEndeavor: PutScheduleEndeavor,
    PutScheduleDischarge: PutScheduleDischarge,
    GetServicesComplements: GetServicesComplements,
    PostServicesComplements: PostServicesComplements,
    PutServicesComplements: PutServicesComplements,
    GetCashFlowSetup: GetCashFlowSetup,
    PostCashFlowSetup: PostCashFlowSetup,
    PutCashFlowSetup: PutCashFlowSetup,
    GetBranchDistance: GetBranchDistance,
    GetFlowNotify: getFlowNotify,
    updateFlowNotify: updateFlowNotify,
    GetBranchDistanceReceive: GetBranchDistanceReceive,
    getFlowId: getFlowId,
    BranchIdCash: BranchIdCash,
    GetLoginProblems: GetLoginProblems,
    CreateProblems: CreateProblems,
    getUserDataBranch: getUserDataBranch,
    putUserBranchInfo: putUserBranchInfo,
    saveDataBenefiaciary: saveDataBenefiaciary,
    saveDataCoOwner: saveDataCoOwner,
    putDataBenefiaciary: putDataBenefiaciary,
    putDataCoOwners: putDataCoOwners,
    deleteDataCoOwner: deleteDataCoOwner,
    deleteDataBeneficiary: deleteDataBeneficiary,
    getUsersValidation: getUsersValidation,
    saveDataSingUp: saveDataSingUp,
    validateEmailAdministrator: validateEmailAdministrator,
    catCompanyBenefits: catCompanyBenefits,
    catPlaceAndBusiness: catPlaceAndBusiness,
    saveEndeavor: saveEndeavor,
    modulesAdmistrator: modulesAdmistrator,
    ResendEmailValidation: ResendEmailValidation,
    SearchQuoter: SearchQuoter,
    adminUserProfileSettings: adminUserProfileSettings,
    getModulesData: getModulesData,
    getHomeAdminPercentage: getHomeAdminPercentage,
    getNavBarPos: getNavBarPos,
    getFastActions: getFastActions,
    propertiesArticleRequest: propertiesArticleRequest,
    getSearchAllUsers: getSearchAllUsers,
    TreeGroupBranches: TreeGroupBranches,
    validateLink: validateLink,
    updateStatusLink: updateStatusLink,
    CreateDataUser: CreateDataUser,
    saveUser: saveUser,
    editUsers: editUsers,
    updateStateUsers: updateStateUsers,
    addCatalogRequest: addCatalogRequest,
    GetRolesInformation: GetRolesInformation,
    GetEmployeeUserSearch: GetEmployeeUserSearch,
    getRequestsTable: getRequestsTable,
    ArticleManagementSearch: ArticleManagementSearch,
    SavedLevels: SavedLevels,
    getCartContracts: getCartContracts,
    deleteItemPledgeReducer: deleteItemPledgeReducer,
    clientList: clientList,
    newClientProcess: newClientProcess,
    getHandsFingers: getHandsFingers,
    getPromotions: getPromotions,
    getAllNations: getAllNations,
    treePermissions: treePermissions,
    CreateUpdateRole: CreateUpdateRole,
    getInfoContracts: getInfoContracts,
    getClientInfo: getClientInfo,
    getCarContracts: getCarContracts,
    getAllCoowner: getAllCoowner,
    addCoownerBeneficiary: addCoownerBeneficiary,
    PersistenceRole: PersistenceRole,
    RoleById: RoleById,
    UpdateCoOwner: UpdateCoOwner,
    formAddCoOwner: formAddCoOwner,
    UploadArticleImages: UploadArticleImages,
    UserRoles: UserRoles,
    removeRole: removeRole,
    GetSearchUserByRole: GetSearchUserByRole,
    loading: loadingReducer,
    AddPaymentReducer: AddPaymentReducer,
    cartPaymentReducer: cartPaymentReducer,
    selectTypesLocations: selectTypesLocations,
    affectedRoles: affectedRoles,
    CreateNewGroup: CreateNewGroup,
    GetAssignmentClient: GetAssignmentClient,
    cardClientProfile: cardClientProfile,
    clientDetails: clientDetails,
    getPersonalCredit: getPersonalCredit,
    getScreenSelect: getScreenSelect,
    getAllValidateType: getAllValidateType,
    ValidateBankCardType: ValidateBankCardType,
    AddCompanyPaymentMethod: AddCompanyPaymentMethod,
    GetCompanyPaymentMethod: GetCompanyPaymentMethod,
    GetAllCatEmploymentSituation: GetAllCatEmploymentSituation,
    GetAllCatLaborOld: GetAllCatLaborOld,
    SaveLoansJob: SaveLoansJob,
    SaveLoansUser: SaveLoansUser,
    DataStepLoans: DataStepLoans,
    getUsersMarketValidation: getUsersMarketValidation,
    codeValideReducer: codeValideReducer,
    SelectedPledgeReducer: SelectedPledgeReducer,
    verifyCode: verifyCode,
    creaateUserData: creaateUserData,
    newValidationCodeMarket: newValidationCodeMarket,
    detailsCompany: detailsCompany,
    updateImgCompany: updateImgCompany,
    CreatePersonalLoans: CreatePersonalLoans,
    UpdatePersonalLoans: UpdatePersonalLoans,
    CreateEmploymentSituation: CreateEmploymentSituation,
    GetAddLevel: GetAddLevel,
    CreateNewCatalogId: CreateNewCatalogId,
    UpdateNewCatalogId: UpdateNewCatalogId,
    SelectedDataNode: SelectedDataNode,
    GetDetailsCharacteristics: GetDetailsCharacteristics,
    GetNewCatalogId: GetNewCatalogId,
    CodePasswordChange: CodePasswordChange,
    newResetCodeClient: newResetCodeClient,
    verifyCodeClient: verifyCodeClient,
    EmailResetCodeClient: EmailResetCodeClient,
    addEmail: addEmail,
    NewpasswordUser: NewpasswordUser,
    GetAllCatArticleTypeDetailsReducer: GetAllCatArticleTypeDetailsReducer,
    SaveNewDynamicFeatureReducer: SaveNewDynamicFeatureReducer,
    GetInheritedFeatures: GetInheritedFeatures,
    getUsersClientsValidation: getUsersClientsValidation,
    getBusinessLines: getBusinessLines,
    getContractedPlans: getContractedPlans,
    getSalesChannels: getSalesChannels,
    getAllCatFrequency: getAllCatFrequency,
    getAllCatPersonalLoansPeriod: getAllCatPersonalLoansPeriod,
    getQuoteCreditPersonalLoans: getQuoteCreditPersonalLoans,
    getTableAmortization: getTableAmortization,
    getActivateLevelOrItem: getActivateLevelOrItem,
    getCreateGroupBranch: getCreateGroupBranch,
    GetCompanyTaxData: GetCompanyTaxData,
    UpdateCompanyTaxData: UpdateCompanyTaxData,
    getCompanyLevels: getCompanyLevels,
    getListLevels: getListLevels,
    levelGroups: levelGroups,
    getSearchLevelsAndBranch: getSearchLevelsAndBranch,
    getAllTotalAvailables: getAllTotalAvailables,
    getDetailsCredit: getDetailsCredit,
    getListLoans: getListLoans,
    getCustomerInfo: getCustomerInfo,
    catAddress: catAddress,
    AddUserEmployee: AddUserEmployee,
    GetUserByLevelId: GetUserByLevelId,
    delUsersLoctations: delUsersLoctations,
    Warehouse: Warehouse,
    getCashflowDetails: getCashflowDetails,
    AddNewLevel: AddNewLevel,
    GetActionPayment: GetActionPayment,
    getStatusApplicationValidate: getStatusApplicationValidate,
    getAllCatBank: getAllCatBank,
    getCreditLoanSummary: getCreditLoanSummary,
    addWireTransfer: addWireTransfer,
    newGroup: newGroup,
    groupBranchPersistence: groupBranchPersistence,
    newBranch: newBranch,
    getAddress: getAddress,
    getFilterListLoans: getFilterListLoans,
    getCreditAndPawnsHistory: getCreditAndPawnsHistory,
    GetApprovedCreditSolicitudeReducer: GetApprovedCreditSolicitudeReducer,
    GetPercentageAmortizationReducer: GetPercentageAmortizationReducer,
    getSearchInventoryStorage: getSearchInventoryStorage,
    searchInventorySelection: searchInventorySelection,
    GetPawnDetails: GetPawnDetails,
    sendConfirmationVoucher: sendConfirmationVoucher,
    getCashflowAdmin: getAdminCashflowReducer,
    getPaymentMethods: getPaymentMethods,
    getExpenseTypes: getExpenseTypes,
    getBranchProviders: getBranchProviders,
    PostCreateExpense: postCreateExpense,
    getExpenses: getExpenses,
    GetAllTransactions: getTransactions,
    getFastPaymentPersonal: getFastPaymentPersonal,
    addFastPayment: addFastPayment,
    MyCreditReducer: MyCreditReducer,
    employeeCashFunding: employeeCashFunding,
    sendCashFunding: sendCashFunding,
    addFlowCash: addFlowCash,
    employeeReturnType: employeeReturnType,
    retunrExcess: retunrExcess,
    getUserDetails: getUserDetails,
    getExpenseInvoiceReducer: getExpenseInvoiceReducer,
    filtersPersistance: filtersPersistance,
    selectTableReducer: selectTableReducer,
    getExpenseDetails: getExpenseDetails,
    putExpenseAuth: putExpenseAuth,
    getAmortizationTableLoan: getAmortizationTableLoan,
    quickAction: quickAction,
    newStorage: newStorage,
    getLevels: getLevels,
    paymentMethods: paymentMethods,
    addIdentificationDoc: addIdentificationDoc,
    getFrequencyPayments: getFrequencyPayments,
    setFrequencyPaymentsTable: setFrequencyPaymentsTable,
    getCreditMovementHistory: getCreditMovementHistory,
    getSolicitudeTermDetails: getSolicitudeTermDetails,
    endeavorsContracs: endeavorsContracs,
    GetPersonalCreditAmountReducer: GetPersonalCreditAmountReducer,
    ScreenStateUsers: ScreenStateUsers,
    getClientTransactions: getClientTransactions,
    getCatGroups: getCatGroups,
    getFamilyGroup: getFamilyGroup,
    getSubfamilyManager: getSubfamilyManager,
    getBrand: getBrand,
    getItems: getItems,
    deatilContract: deatilContract,
    ScreenStateUsersForm: ScreenStateUsersForm,
    getCartByCartId: getCartByCartId,
    searchOperations: searchOperations,
    updateBranch: updateBranch,
    getBranchByLevel: getBranchByLevel,
    getPersonalCreditProductsReducer: getPersonalCreditProductsReducer,
    getProductsCustomerUserReducer: getProductsCustomerUserReducer,
    shoppingCarClient: shoppingCarClient,
    renewalReducer: renewalReducer,
    setPaymentMethods: setPaymentMethods,
    setChargesAndPenaltiesTable: setChargesAndPenaltiesTable,
    getSearchBar: getSearchBar,
    RejectLoanReducer: RejectLoanReducer,
    DocumentDisbursementReducer: DocumentDisbursementReducer,
    endorsementsReducer: endorsementsReducer,
    getGroupsByCompanyId: getGroupsByCompanyId,
    getGroupOpenHours: getGroupOpenHours,
    getSelectedGroupPath: getSelectedGroupPath,
});
export type RootState = ReturnType<typeof reducers>;
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export type AppDispatch = typeof store.dispatch;
export default store;
