import React from 'react';
import {ContainerCellGrid, Input, Text} from '@Shopify/Ecommerce/styles';
import {IPropsEnterCostAndBoss} from '@components/ManageUser/interface';

export const EnterCostAndBoss = ({register}: IPropsEnterCostAndBoss) => {
    return (
        <>
            <ContainerCellGrid Justify="center" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px">
                    Centro de costos
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Centro de costos"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('costCenter')}
                />
            </ContainerCellGrid>
            <ContainerCellGrid Justify="center" FlexDir="column" Gap="4px">
                <Text FontSize="0.75rem" Color="#6d6e71" Height="12px">
                    Nombre de jefe inmediato
                </Text>
                <Input
                    maxLength={160}
                    placeholder="Jefe inmediato"
                    Padding="12px"
                    HolderFontSize="0.875rem"
                    FontSize="0.875rem"
                    {...register('inmediateBoss')}
                />
            </ContainerCellGrid>
        </>
    );
};
