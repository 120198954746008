import React from 'react';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {useDispatch, useSelector} from 'react-redux';

import {postProductsShopifyDetail} from '@ActionsProducts/ProductsShopifyActions';

export const RowProductsShopifyTable = (props) => {
    const {sku, articulo, sucursal, avaluo, width} = props;
    const dispatch = useDispatch();
    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const Redirec = () => {
        if (width > 600) {
            window.location.href = `/Administracion/Shopify/${sku}`;
        }
    };
    const productsshopifydetails = useSelector((state) => {
        return state.productsshopify.productsshopifydetails;
    });
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const handleToggle = async (sku) => {
        const found = productsshopifydetails.find((element) => element.sku === sku);
        const formData = new FormData();
        formData.append('sku', found.sku);
        formData.append('articulo', found.articulo);
        formData.append('descripcion', found.descripcion);
        formData.append('idSucursalVitrina', found.idSucursalVitrina);
        formData.append('sucursalVitrina', found.sucursalVitrina);
        formData.append('estaEnShopify', false);
        formData.append('largo', found.largo);
        formData.append('ancho', found.ancho);
        formData.append('alto', found.alto);
        formData.append('peso', found.peso);

        dispatch(postProductsShopifyDetail(formData, token, found.fotos, sku));
    };
    const Validar = (sku) => {
        if (productsshopifydetails.length > 0) {
            const found = productsshopifydetails.find((element) => element.sku === sku);
            if (found !== undefined) {
                return found.estaEnShopify;
            } else {
                return;
            }
        }
    };
    return (
        <tr className="row-sucursal-tr" onClick={() => Redirec()}>
            {/* <td ><img src={[base, foto].join("")} alt="Avatar" className="avatar"/></td> */}
            <td>{sku}</td>
            {width > 600 && (
                <>
                    <td>{articulo}</td>
                    <td>{sucursal}</td>
                    <td>{formatterDolar.format(avaluo)}</td>
                </>
            )}
            <td>
                <GreenSwitch
                    name="estaEnShopify"
                    defaultChecked={Validar(sku)}
                    onClick={() => handleToggle(sku)}
                />
            </td>
        </tr>
    );
};
