import axios from 'axios';
import Swal from 'sweetalert2';

import {URL} from '@config/constants/index';
import {FIELD_DINAMICS} from '@/components/Articles/Redux/Types';
import {Dispatch} from 'redux';
import {FieldValues} from 'react-hook-form';

export const ErrorDetail = (value: unknown) => {
    return {
        type: FIELD_DINAMICS.ERROR,
        payload: value,
    };
};

export const fieldsDinamics = () => {
    return {
        type: FIELD_DINAMICS.DETAIL,
    };
};

export const GetArticlesSuccess = (result: {data: FieldValues}) => {
    return {
        type: FIELD_DINAMICS.DETAIL_SUCCESS,
        value: result.data,
    };
};

export const getTableFieldDinamics = (value: FieldValues) => {
    return {
        type: FIELD_DINAMICS.INPUT_DINAMICS,
        fieldDinamics: value,
    };
};

export const GetArticle = (token: string, levelId: string, level: string) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.TableDinamics.replace(
                    '{LevelId}',
                    levelId
                ).replace('{Level}', level)}`,
                {headers}
            );
            dispatch(GetArticlesSuccess(response.data));
        } catch (error) {
            dispatch(ErrorDetail(error));
        }
    };
};

export const catLabelSizeSuccess = (result: {data: FieldValues}) => {
    return {
        type: FIELD_DINAMICS.CAT_LABELSIZE,
        value: result.data,
    };
};

export const CatLabelSize = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatLabelSize}`,
                {headers}
            );
            dispatch(catLabelSizeSuccess(response.data));
        } catch (error) {
            dispatch(ErrorDetail(error));
        }
    };
};

export const catTipoArticleSuccess = (result: {
    data: {
        articles_detail: {
            labelSizeId: number;
            labelSize: string;
            getItemsDetailsViewModels: [
                {
                    id: number;
                    controlItemId: number;
                    catTypeItemDetailId: number;
                    catTypeItemDetailName: string;
                    required: number;
                    property: string;
                    status: boolean;
                    detailList: [
                        {
                            id: number;
                            name: string;
                            status: boolean;
                        },
                    ];
                },
            ];
        };
        FieldDinamics: [];
        catLabelSize: [
            {
                labelTypeId: number;
                nameLabelType: string;
            },
        ];
        catTipoArticle: [
            {
                id: number;
                descripcion: string;
            },
        ];
        labelOption: number;
        loading: boolean;
        error: boolean;
    };
}) => {
    return {
        type: FIELD_DINAMICS.CAT_TIPO_ARTICLE,
        value: result.data,
    };
};

export const CatTypeArticle = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatTipoArticle}`,
                {headers}
            );
            dispatch(catTipoArticleSuccess(response.data));
        } catch (error) {
            dispatch(ErrorDetail(error));
        }
    };
};

export const LabelDetail = (value: number) => {
    return {
        type: FIELD_DINAMICS.LABEL_DETAIL,
        value: value,
    };
};

export const AddArticles = (
    token: string,
    data: {
        level: number | undefined;
        levelId: number | undefined;
        labelSizeId: number;
        data: Array<{
            detailId: number;
            type: number;
            property: string;
            required: number;
            content: unknown;
            statusDetail: boolean;
        }>;
    }
) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: Dispatch) => {
        return axios
            .post(`${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddFieldDinamic}`, data, {
                headers,
            })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    text: `Se realizó el proceso con éxito.`,
                    confirmButtonText: 'Continuar',
                });
            })
            .catch((err) => {
                dispatch(ErrorDetail(err.message));
                Swal.fire({
                    icon: 'error',
                    text: `Ocurrió un error en el proceso.`,
                    confirmButtonText: 'Continuar',
                });
            });
    };
};
