import {
    GET_EXPENSE_TYPE,
    GET_EXPENSE_TYPE_SUCCESS,
    GET_EXPENSE_TYPE_ERROR,
} from '@components/CashFlow/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    expenseTypes: [],
    loading: false,
    error: false,
};

const getExpenseTypes = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_EXPENSE_TYPE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_EXPENSE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                expenseTypes: action.payload.data.data,
            };
        case GET_EXPENSE_TYPE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getExpenseTypes;
