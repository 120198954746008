import {
    GET_HOME_ADMIN,
    GET_HOME_ADMIN_ERROR,
    GET_HOME_ADMIN_SUCCESS,
} from '@components/Home/Admin/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: null,
};

const getHomeAdminPercentage = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_HOME_ADMIN:
            return {...state, loading: true};
        case GET_HOME_ADMIN_SUCCESS:
            return {...state, loading: false, data: action.payload.data};
        case GET_HOME_ADMIN_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
export default getHomeAdminPercentage;
