export const InputStyles = {
    Padding: '12px',
    Width: '300px',
    FontSize: '0.875rem',
    Color: '#414042',
    FontWeight: 'normal',
    HolderFontSize: '0.875rem',
};

export const contentLocation = {
    Border: '1px solid #E8E9EA',
    Justify: 'space-between',
    Width: '20rem',
    Height: '40px',
    Radius: '2rem',
    Padding: '0.5rem 1rem',
    Color: '#2A2C2F',
    BorderFc: '1px solid #5A5AFF',
    BorderHv: '1px solid #5A5AFF',
    Cursor: 'pointer',
};

export const iconSearch = {
    className: 'material-icons',
    FontSize: '1.5rem',
    Color: '#5A5AFF',
    Cursor: 'pointer',
};

export const modalContect = {
    Align: 'center',
    Width: '41.313rem',
    BoxShadow: '0px 10px 60px 0px rgba(0, 0, 0, 0.25)',
    Position: 'fixed',
    Top: '0',
    Padding: '0',
    BorderRadius: '1.5rem',
    background: 'none',
    HeightDialog: '34.375rem',
    MaxHDialog: '90%',
};

export const search = {
    Position: 'none',
    GridColumn: '95% 5%',
    FontSize: '1rem',
    PlaceholderColor: '#A1A5AA',
    WBoxShadow: '',
    Margin: '0 0 0.5rem 0',
};

export const treeTitle = {
    FontSize: '0.75rem',
    FontWeight: '500',
    Color: '#54575C',
};

export const Btn = {
    BorderRadius: '2rem',
    FontWeight: '500',
    Cursor: 'pointer',
    Padding: '0.5rem 1rem',
    Color: '#FF6357',
};

export const contentTree = {
    FlexDir: 'column',
    Height: '17rem',
    MaxH: 'fit-content',
    OverFlowY: 'auto',
    Justify: 'start',
    Align: 'start',
};

export const allOptions = {
    Justify: 'start',
    Padding: '0.5rem',
    Height: 'auto',
    HBackground: '#f0f0ff',
    HBorderLeft: '0.094rem solid #5a5aff',
    Cursor: 'pointer',
};

export const allText = {PaddingL: '0.5rem', Color: '#54575C', Cursor: 'pointer'};

export const contentGroup = {
    Cursor: 'pointer',
    Justify: 'start',
    Padding: '0.5rem 1.5rem',
    HBackground: '#f0f0ff',
    HBorderLeft: '0.094rem solid #5a5aff',
    Height: 'auto',
};

export const labelGroup = {
    Padding: '0 0 0 0.5rem',
    Color: '#54575C',
    Cursor: 'pointer',
    FontWeight: '400',
};

export const GroupText = {
    Padding: '0 0.5rem',
    FontWeight: '500',
    FontSize: '0.75rem',
    Color: '#54575C',
    Cursor: 'pointer',
};

export const icons = {
    className: 'material-icons',
    Color: '#54575C',
    FontSize: '1rem',
    Cursor: 'pointer',
};

export const contentBranches = {
    Justify: 'start',
    Padding: '0.5rem 1.5rem',
    HBackground: '#f0f0ff',
    HBorderLeft: '0.094rem solid #5a5aff',
    Cursor: 'pointer',
};

export const tabsTree = {FlexDir: 'Column', Align: 'start', Gap: '0.5rem'};

export const contentLabelGroup = {
    Justify: 'space-between',
    Cursor: 'pointer',
    Height: 'auto',
};

export const contentSection = {
    FlexDir: 'column',
    Gap: '1rem',
    Padding: '1rem',
    Align: 'start',
    Border: '1px solid #F4F5F5',
    Radius: '1rem',
};

export const subtitles = {
    FontSize: '1.25rem',
    Color: '#2A2C2F',
    FontWeight: '500',
    Padding: '0.625rem 0',
};

export const instructions = {
    FontWeight: '500',
    FontSize: '0.75rem',
    Color: '#6D6E71',
    Padding: '0.375rem 0',
};

export const contentImg = {
    Width: 'auto',
    Height: 'auto',
    RABorder: '50%',
    Align: 'start',
    htmlFor: 'file',
    BackG: 'none',

    imgStart: {
        Width: '4rem',
        Height: '4rem',
        Radius: '50%',
        alt: 'upload-image',
        Cursor: 'pointer',
        ObjectFit: 'cover',
        AspRatio: '1',
    },

    imgAdd: {
        alt: 'icon-add-image',
        Cursor: 'pointer',
        Radius: '50%',
        ObjectFit: 'cover',
        AspRatio: '1',
    },
};

export const personalData = {
    FlexDir: 'column',
    Align: 'start',
    Height: 'auto',

    name: {FontWeight: '500', FontSize: '0.75rem', Color: '#6D6E71', Padding: '0 0 0.25rem 0'},
    input: {Width: '100%', Margin: '0 0 8px 0', Cursor: 'text'},
};

export const credential = {
    Padding: '1rem',
    Gap: '1rem',
    Justify: 'start',
    Height: 'auto',
    Radius: '0.625rem',
    Cursor: 'pointer',

    imgStart: {
        alt: 'rol-image',
        width: '32px',
        height: '32px',
        ObjectFit: 'cover',
    },

    imgAdd: {
        alt: 'rol-image',
        width: '32px',
        height: '32px',
        ObjectFit: 'cover',
    },

    rolname: {
        FontSize: '0.75rem',
        FontWeight: '700',
        Cursor: 'pointer',
    },
};

export const sugestions = {
    Justify: 'start',
    Height: 'auto',
    Width: 'auto',
    FlexWrap: 'wrap',

    name: {
        FontWeight: '500',
        Cursor: 'pointer',
        Width: 'auto',
        Color: '#5A5AFF',
        MarginLeft: '4px',
    },
};
