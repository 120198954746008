const useFormatDate = (
    dateInput: Date | string | null,
    format: string = 'dd/MM/yyyy',
    use24HourFormat: boolean = true
): string => {
    if (!dateInput) return '';

    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    if (isNaN(date.getTime())) {
        throw new RangeError('Invalid time value');
    }

    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'UTC',
    };

    if (format.includes('dd')) options.day = '2-digit';
    if (format.includes('MM')) options.month = '2-digit';
    if (format.includes('yyyy')) options.year = 'numeric';

    if (format.includes('HH') || format.includes('hh')) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
        options.hour12 = !use24HourFormat;
    }

    return new Intl.DateTimeFormat('es-MX', options).format(date);
};

export const useFormatDateAndHour = (isoDateString: string): string => {
    const date: Date = new Date(isoDateString);
    const day: string = date.getDate().toString().padStart(2, '0');
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    const year: number = date.getFullYear();
    const hours: string = date.getHours().toString().padStart(2, '0');
    const minutes: string = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getDaysDifference = (startDate: Date | string, endDate: Date | string): number => {
    const toDate = (date: Date | string): Date => {
        if (typeof date === 'string') {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate.getTime())) {
                throw new RangeError(`Invalid date format: ${date}`);
            }
            return parsedDate;
        }
        return date;
    };

    const start = toDate(startDate);
    const end = toDate(endDate);

    const differenceInTime = start.getTime() - end.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays > 0 ? differenceInDays : 0;
};

export default useFormatDate;
