import React from 'react';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@components/Quoter/styles';
import {OptionListProps} from '@components/Catalogue/interfaces';

export const OptionList = ({
    fields,
    register,
    remove,
    errors,
    handleAddOption,
}: OptionListProps) => {
    return (
        <>
            <ContainerFlex Gap="1rem" Align="start" FlexDir="column">
                {fields.map((field, index) => (
                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        FlexDir="column"
                        Gap="0.25rem"
                        key={field.id}
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            Color="#6D6E71"
                            MarginLeft="2rem"
                        >
                            {`Opción ${index + 1}`}
                        </Text>
                        <ContainerFlex Gap="0.5rem">
                            <IconSvg Width="24px" Height="24px">
                                <path d="M4 15V13H20V15H4ZM4 11V9H20V11H4Z" fill="#ACACFF" />
                            </IconSvg>
                            <Input
                                {...register(`details.${index}.valueDescription`)}
                                Width="100%"
                            />
                            {fields.length > 1 && (
                                <IconSvg
                                    Width="24px"
                                    Height="24px"
                                    Cursor="pointer"
                                    onClick={() => remove(index)}
                                >
                                    <path d="M5 13V11H19V13H5Z" fill="#FF8E85" />
                                </IconSvg>
                            )}
                        </ContainerFlex>
                        {errors.details && (
                            <Text Color="red" Margin="4px 0px 0px 0px">
                                {errors.details[index]?.valueDescription?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
                <ContainerFlex
                    Width="auto"
                    Gap="0.25rem"
                    Cursor="Pointer"
                    FontSize="0.875rem"
                    onClick={handleAddOption}
                >
                    <IconSvg Width="24px" Height="24px">
                        <path d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z" fill="#5A5AFF" />
                    </IconSvg>
                    <Text
                        Cursor="Pointer"
                        UserSelect="none"
                        FontWeight="700"
                        FontFamily="Nunito"
                        Color="#5A5AFF"
                    >
                        {'Agregar valor'}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
