import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_QUOTE_CREDIT_PERSONAL_LOANS_ERROR,
    GET_QUOTE_CREDIT_PERSONAL_LOANS_START,
    GET_QUOTE_CREDIT_PERSONAL_LOANS_SUCCESS,
} from '@components/CreditQuoter/Redux/Types/Types';
import {IFiltersQuoteCredit} from '@components/CreditQuoter/interfaces';
import StringUtils from '@/GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const getQuoteCreditPersonalLoansStart = () => {
    return {
        type: GET_QUOTE_CREDIT_PERSONAL_LOANS_START,
    };
};

export const getQuoteCreditPersonalLoansSuccess = (result: AxiosResponse) => {
    return {
        type: GET_QUOTE_CREDIT_PERSONAL_LOANS_SUCCESS,
        payload: result.data,
    };
};

export const getQuoteCreditPersonalLoansError = (error: AxiosError) => {
    return {
        type: GET_QUOTE_CREDIT_PERSONAL_LOANS_ERROR,
        error,
    };
};

export const getQuoteCreditPersonalLoans = (token: string, filters: IFiltersQuoteCredit) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(getQuoteCreditPersonalLoansStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTermQuoteCreditPersonalLoans}${queryString}`,
                {headers}
            );
            dispatch(getQuoteCreditPersonalLoansSuccess(response));
        } catch (error) {
            dispatch(getQuoteCreditPersonalLoansError(error as AxiosError));
        }
    };
};
