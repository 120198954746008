import React from 'react';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import AddPaymentMethodForm from '@components/AccountSettings/AddPaymentMethodForm';
import EditTaxInfoForm from '@components/AccountSettings/EditTaxInfoForm';
import {IPaymentAndBillingModalProps} from '@components/AccountSettings/interfaces';

export const PaymentAndBillingModal: React.FC<IPaymentAndBillingModalProps> = ({
    modalFormType,
    showModal,
    setShowModal,
    onSuccess,
}) => {
    const renderModalContent = () => {
        switch (modalFormType) {
            case 'paymentMethod':
                return <AddPaymentMethodForm onClose={setShowModal} onSuccess={onSuccess} />;
            case 'taxInfo':
                return <EditTaxInfoForm onClose={setShowModal} />;
            default:
                return null;
        }
    };
    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
            closeOnOutsideClick={false}
        >
            {renderModalContent()}
        </Modal>
    );
};

export default PaymentAndBillingModal;
