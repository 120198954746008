import {AppDispatch} from '@/config/store';

import {
    DELETE_ITEM_PLEDGE,
    DELETE_ITEM_PLEDGE_SUCCESS,
    DELETE_ITEM_PLEDGE_ERROR,
} from '@components/ShoppingCart/Redux/types';
import {IDeleteItemPledge} from '@components/ShoppingCart/Redux/interfaces';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import axios, {AxiosResponse} from 'axios';
import {getShoppingCartContracts} from '@components/ShoppingCart/Redux/Actions/getCartContractsActions';

export const DeleteItemPledge = () => {
    return {
        type: DELETE_ITEM_PLEDGE,
    };
};
export const DeleteItemPledgeSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: DELETE_ITEM_PLEDGE_SUCCESS,
        payload: result.data,
    };
};
export const DeleteItemPledgeError = () => {
    return {
        type: DELETE_ITEM_PLEDGE_ERROR,
    };
};

export function DeleteItemPledges(token: string, filters: IDeleteItemPledge) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);

    return async (dispatch: AppDispatch) => {
        dispatch(DeleteItemPledge());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteItemPledge}${queryString}`,
                {headers}
            );
            dispatch(DeleteItemPledgeSuccess(response));
            dispatch(getShoppingCartContracts(token, {carshopingId: filters.carId}));
        } catch (error) {
            dispatch(DeleteItemPledgeError());
        }
    };
}
