import React from 'react';

import replaceIcon from '@components/Branches/Images/ReplaceIcon.svg';
import deleteIcon from '@components/Branches/Images/DeleteIconRed.svg';
import {PaymentAdminOptionsText} from '@components/AccountSettings/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

const PaymentRecordOptions = () => {
    return (
        <ContainerFlex FlexDir="column" Align="start">
            {PaymentAdminOptionsText.map((adminOption) => (
                <ContainerFlex
                    key={adminOption.adminOptions}
                    Gap="0.5rem"
                    Height="2.5rem"
                    Justify="start"
                    Padding="0.5rem"
                >
                    {adminOption.adminOptions === 'Volver principal' ? (
                        <Image src={replaceIcon} alt="down-arrow" Width="20px" Height="20px" />
                    ) : (
                        <Image src={deleteIcon} alt="down-arrow" Width="20px" Height="20px" />
                    )}
                    <Text
                        Cursor="pointer"
                        FontSize="1rem"
                        Color={adminOption.adminOptions === 'Eliminar' ? '#FF6357' : '#2A2C2F'}
                    >
                        {adminOption.adminOptions}
                    </Text>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};

export default PaymentRecordOptions;
