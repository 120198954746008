import {GET_COMPANY_LEVELS} from '@components/LocationsBranch/Redux/types';
import {
    CompanyLevelsState,
    GetCompanyLevelsAction,
} from '@components/LocationsBranch/Redux/interfaces';

const initialState: CompanyLevelsState = {
    data: {
        branchId: 0,
        branchName: '',
        countLevels: '',
        message: '',
        levelId: 0,
        levels: [],
        branchs: [],
    },
    loading: false,
    error: null,
};

const getCompanyLevels = (
    state = initialState,
    action: GetCompanyLevelsAction
): CompanyLevelsState => {
    switch (action.type) {
        case GET_COMPANY_LEVELS.GET_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_COMPANY_LEVELS.GET_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_COMPANY_LEVELS.GET_LEVELS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default getCompanyLevels;
