import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FieldValues, useForm} from 'react-hook-form';
import {RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_ADDRESS} from '@MyCompany/constants';
import {ADDRESS_MODAL} from '@components/LocationsBranch/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {AddressForm} from '@components/LocationsBranch/AddAddress/AddressForm';
import {resetAll} from '@components/LocationsBranch/Redux/Action/CatAddress';
import {
    IAddressFields,
    IAddressAdd,
    IAddressData,
    ILocation,
} from '@components/LocationsBranch/interfaces';
import {AddressShema} from '@components/LocationsBranch/AddAddress/AddressErrors';
import {
    addAddress,
    setAddressError,
} from '@/components/LocationsBranch/Redux/Action/AddAddressLocation';
import {ISelectReact} from '@/components/Quoter/inferfaces';

export const AddressAdd = ({modalAddress, setModalAddress}: IAddressAdd) => {
    const dispatch = useDispatch();
    const {address} = useSelector((state: RootState) => state.getAddress);

    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        setValue,
        formState: {errors},
    } = useForm<IAddressFields>({
        defaultValues: {
            postalCode: address?.postalCode || '',
            street: address?.street || '',
            outerNum: address?.outerNum || '',
            interNum: address?.interNum || '',
            colony: (address?.colony as unknown as ISelectReact) || '',
            municipaly: (address?.municipaly as unknown as ISelectReact) || '',
            state: (address?.state as unknown as ISelectReact) || '',
        },
        resolver: yupResolver(AddressShema),
    });

    const closeModal = () => {
        setModalAddress(false);
        reset();
        dispatch(resetAll());
    };

    const onSubmit = async (values: FieldValues) => {
        try {
            const addressData: IAddressData = {
                postalCode: values.postalCode,
                street: values.street,
                outerNum: values.outerNum,
                interNum: values.interNum,
                colony: values.colony as ILocation | undefined,
                municipaly: values.municipaly as ILocation | undefined,
                state: values.state as ILocation | undefined,
            };
            const result = await dispatch(addAddress(addressData));

            if (result?.type === 'ADD_NEW_ADDRESS') closeModal();
        } catch (error) {
            dispatch(setAddressError());
        }
    };

    return (
        <Modal
            Top="0"
            modalState={modalAddress}
            changeModalState={() => setModalAddress(true)}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                    {ADDRESS_MODAL.TITLE}
                </Text>
                <AddressForm
                    register={register}
                    control={control}
                    errors={errors}
                    watch={watch}
                    reset={reset}
                    setValue={setValue}
                />
                <ContainerFlex Height="auto" Gap="1.5rem">
                    <ButtonGeneral
                        width="100%"
                        text={UPDATE_ADDRESS.CANCEL}
                        clic={() => closeModal()}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="2.5rem"
                        cursor="pointer"
                        border="1px solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral width="100%" text={UPDATE_ADDRESS.SAVE} />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
