import {AppDispatch} from '@/config/store';
import {GETPRODUCTSCARD, GETPRODUCTSCARD_SUCCESS, GETPRODUCTSCARD_ERROR} from '@TypesLoan/types';
import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';

export const CardProducts = () => {
    return {
        type: GETPRODUCTSCARD,
    };
};
export const CardProductsSuccess = (result: AxiosResponse) => {
    return {
        type: GETPRODUCTSCARD_SUCCESS,
        payload: result,
    };
};
export const CardProductsError = () => {
    return {
        type: GETPRODUCTSCARD_ERROR,
    };
};

export function GetCardProducts(token: string, carShoopingId: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(CardProducts());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CardProducts.replace(
                    '{carShoopingId}',
                    String(carShoopingId)
                )}`,
                {headers}
            );
            dispatch(CardProductsSuccess(response.data));
        } catch (error) {
            dispatch(CardProductsError());
        }
    };
}
