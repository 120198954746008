import {ResponseData} from '@/components/ManageUser/interface';
import {GETPRODUCTSCARD, GETPRODUCTSCARD_SUCCESS, GETPRODUCTSCARD_ERROR} from '@TypesLoan/types';

const initialState = {
    products: null,
    loading: false,
};

const CardProducts = (state = initialState, action: ResponseData) => {
    switch (action.type) {
        case GETPRODUCTSCARD:
            return {
                ...state,
                loading: true,
            };
        case GETPRODUCTSCARD_SUCCESS:
            return {
                ...state,
                products: action.payload,
                loading: false,
            };
        case GETPRODUCTSCARD_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default CardProducts;
