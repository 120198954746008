import React from 'react';
import {Svg} from '@Shopify/Ecommerce/styles';

export const VisibilityOn = () => {
    return (
        <Svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            cursor="pointer"
        >
            <mask
                id="mask0_2864_27473"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <path
                d="M12 16C13.25 16 14.3127 15.5627 15.188 14.688C16.0627 13.8127 16.5 12.75 16.5 11.5C16.5 10.25 16.0627 9.18733 15.188 8.312C14.3127 7.43733 13.25 7 12 7C10.75 7 9.68738 7.43733 8.81205 8.312C7.93738 9.18733 7.50005 10.25 7.50005 11.5C7.50005 12.75 7.93738 13.8127 8.81205 14.688C9.68738 15.5627 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6127 13.9373 10.088 13.412C9.56272 12.8873 9.30005 12.25 9.30005 11.5C9.30005 10.75 9.56272 10.1123 10.088 9.587C10.6127 9.06233 11.25 8.8 12 8.8C12.75 8.8 13.3877 9.06233 13.913 9.587C14.4377 10.1123 14.7 10.75 14.7 11.5C14.7 12.25 14.4377 12.8873 13.913 13.412C13.3877 13.9373 12.75 14.2 12 14.2ZM12 19C9.68338 19 7.56672 18.3877 5.65005 17.163C3.73338 15.9377 2.28338 14.2833 1.30005 12.2C1.25005 12.1167 1.21672 12.0123 1.20005 11.887C1.18338 11.7623 1.17505 11.6333 1.17505 11.5C1.17505 11.3667 1.18338 11.2373 1.20005 11.112C1.21672 10.9873 1.25005 10.8833 1.30005 10.8C2.28338 8.71667 3.73338 7.06267 5.65005 5.838C7.56672 4.61267 9.68338 4 12 4C14.3167 4 16.4334 4.61267 18.35 5.838C20.2667 7.06267 21.7167 8.71667 22.7001 10.8C22.75 10.8833 22.7834 10.9873 22.8 11.112C22.8167 11.2373 22.8251 11.3667 22.8251 11.5C22.8251 11.6333 22.8167 11.7623 22.8 11.887C22.7834 12.0123 22.75 12.1167 22.7001 12.2C21.7167 14.2833 20.2667 15.9377 18.35 17.163C16.4334 18.3877 14.3167 19 12 19ZM12 17C13.8834 17 15.6127 16.504 17.188 15.512C18.7627 14.5207 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7627 8.479 17.188 7.487C15.6127 6.49567 13.8834 6 12 6C10.1167 6 8.38738 6.49567 6.81205 7.487C5.23738 8.479 4.03338 9.81667 3.20005 11.5C4.03338 13.1833 5.23738 14.5207 6.81205 15.512C8.38738 16.504 10.1167 17 12 17Z"
                fill="#5A5AFF"
            />
        </Svg>
    );
};
