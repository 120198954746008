import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import correctIcone from '@images/correctIcone.svg';
import {ImageLogo as LoginImageLogo} from '@LoginClient/styles';
import {IMessage} from '@/components/UserProfileResetPassword/Operational/interface';

export const ErrorMessagesPassword = ({message}: IMessage) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <LoginImageLogo Width="auto" Height="auto" src={correctIcone} alt="icone-correct" />
            <Text FontSize="0.85rem" FontFamily="Nunito" wSpace="normal" TextAlign="justify">
                {message}
            </Text>
        </ContainerFlex>
    );
};
