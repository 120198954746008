import {AssigmentAuction} from '@/components/Foundry/interfaces';
import {AppDispatch} from '@/config/store';
import {
    GET_SUCURSAL,
    GET_SUCURSAL_SUCCESS,
    GET_SUCURSAL_ERROR,
    OBTAIN_INFO_SUCURSAL,
    OBTAIN_INFO_SUCURSAL_SUCCESS,
    GET_SUCURSAL_HISTORY,
    GET_SUCURSAL_HISTORY_SUCCESS,
    GET_SUCURSAL_HISTORY_ERROR,
    POST_ASSIGNMENT,
    POST_ASSIGNMENT_SUCCESS,
    POST_ASSIGNMENT_ERROR,
} from '@TypesAuditAssignment/AuditAssignmentTypes';
import {URL} from '@config/constants/index';

import axios from 'axios';
import Swal from 'sweetalert2';

export const fetchGetSucursal = () => {
    return {
        type: GET_SUCURSAL,
    };
};
export const fetchGetSucursalSuccess = (result: {data: unknown}) => {
    return {
        type: GET_SUCURSAL_SUCCESS,
        value: result.data,
    };
};
export const fetchGetSucursalError = () => {
    return {
        type: GET_SUCURSAL_ERROR,
    };
};

export const fetchObtainSucursalById = (id: number) => {
    return {
        type: OBTAIN_INFO_SUCURSAL,
        value: id,
    };
};
export const fetchObtainInfoSucursalById = () => {
    return {
        type: OBTAIN_INFO_SUCURSAL_SUCCESS,
    };
};
export const fetchGetSucursalHistory = () => {
    return {
        type: GET_SUCURSAL_HISTORY,
    };
};
export const fetchGetSucursalHistorySuccess = (sucursalhistory: unknown) => {
    return {
        type: GET_SUCURSAL_HISTORY_SUCCESS,
        value: sucursalhistory,
    };
};
export const fetchGetSucursalHistoryError = () => {
    return {
        type: GET_SUCURSAL_HISTORY_ERROR,
    };
};

export function ObtainSucursalIDAction(id: number, pageSize = 5, pageIndex = 0, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchObtainSucursalById(id));
        dispatch(fetchObtainInfoSucursalById());
        dispatch(fetchGetSucursalHistory());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentByStore.replace(
                    '{id}',
                    id.toString()
                )}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
                {headers}
            );
            dispatch(fetchGetSucursalHistorySuccess(response.data));
        } catch (error) {
            dispatch(fetchGetSucursalHistoryError());
        }
    };
}
export const getSucursalAudit = (pageSize = 10, pageIndex = 0, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetSucursal());
        return axios
            .get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Sucursal}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
                {headers}
            )
            .then((result) => {
                dispatch(fetchGetSucursalSuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetSucursalError());
            });
    };
};
export const fetchPostAssignment = () => {
    return {
        type: POST_ASSIGNMENT,
    };
};
export const fetchPostAssignmentSuccess = () => {
    return {
        type: POST_ASSIGNMENT_SUCCESS,
    };
};

export const fetchPostAssignmentError = () => {
    return {
        type: POST_ASSIGNMENT_ERROR,
    };
};

export function PostAssignmentAction(data: AssigmentAuction, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostAssignment());
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.AssignmentPost}`,
                data,
                {headers}
            );
            dispatch(fetchPostAssignmentSuccess());
            Swal.fire('Success', '¡La visita de auditoría ha sido guardada con éxito!', 'success');
        } catch (error) {
            dispatch(fetchPostAssignmentError());
            Swal.fire('Error!', 'No se pudo agregar la visita. Intentalo de nuevo.', 'error');
        }
    };
}
