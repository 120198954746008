import {
    GET_CAT_CITY,
    GET_CAT_CITY_ERROR,
    GET_CAT_CITY_RESET,
    GET_CAT_CITY_SUCCESS,
} from '@components/Branches/ManageBranch/Redux/Types/types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    catCity: [],
};

const GetCatCity = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_CAT_CITY:
            return {
                ...state,
                loading: true,
            };
        case GET_CAT_CITY_SUCCESS:
            return {
                ...state,
                catCity: action.payload.data,
                loading: false,
            };
        case GET_CAT_CITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_CAT_CITY_RESET:
            return initialState;
        default:
            return state;
    }
};

export default GetCatCity;
