import React, {RefObject, useEffect, useRef, useState} from 'react';
import {ListOptions, OptionPicker, TextPlacelHolder, Option} from '@components/Roles/styles';
import {ISelectSuburbForm, ISuburbItem, WrapperRefType} from '@components/Branches/interfaces';
import {select} from '@components/Branches/constants';

export const SelectSuburb = ({data, optionSelect, setValue, disabled}: ISelectSuburbForm) => {
    const [openList, setOpenList] = useState(false);
    const wrapperRef = useRef<WrapperRefType>(null);
    const [selectOption, setSelectOption] = useState<null | string>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
            setOpenList(false);
        }
    };
    const selectValue = (suburb: string) => {
        setValue('suburb', suburb);
        setSelectOption(suburb);
        setOpenList(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        setSelectOption(optionSelect);
    }, [optionSelect]);
    return (
        <OptionPicker
            onClick={() => {
                if (!disabled && data.length !== 1) setOpenList(!openList);
            }}
            Margin="0 0 28px 0"
            Border={openList ? '2px solid #35CF44' : '1px solid #e5e7e9'}
            Rotate={openList ? '-180deg' : ''}
            Fill={openList ? '#35cf44' : '#6D6E71'}
            BackGround={disabled ? '#f3f3f3' : '#FFF'}
            HColor={disabled ? '' : '#35cf44'}
            HShadow={disabled ? '' : '0px 4px 4px rgba(53, 207, 68, 0.2)'}
            HFill={disabled ? '' : '#35cf44'}
            Cursor={disabled ? 'default' : 'pointer'}
            ref={wrapperRef as RefObject<HTMLDivElement>}
            Width="300px"
        >
            <TextPlacelHolder Color={selectOption && !disabled ? '#414042' : '#A7A9AC'}>
                {selectOption || select}
            </TextPlacelHolder>
            <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.799988 1.46666L1.73332 0.533325L4.79999 3.59999L7.86665 0.533325L8.79999 1.46666L4.79999 5.46666L0.799988 1.46666Z"
                    fill="#6D6E71"
                />
            </svg>
            {openList && (
                <ListOptions>
                    {data.length === 0 ? (
                        <Option onClick={() => setOpenList(!openList)}>Ninguna opción</Option>
                    ) : (
                        <>
                            {data.map((option: ISuburbItem, index: number) => (
                                <Option
                                    key={index}
                                    onClick={() => {
                                        selectValue(option.nameSuburb);
                                        setOpenList(!openList);
                                    }}
                                    BackGround="#FFF"
                                >
                                    {option.nameSuburb}
                                </Option>
                            ))}
                        </>
                    )}
                </ListOptions>
            )}
        </OptionPicker>
    );
};
