export const delSecction = {
    Color: '#fff',
    backG: '#5A5AFF',
    HBackground: '#0D166B',
    FontWeight: '400',
    Padding: '0.5rem 1rem',
    Cursor: 'pointer',
    Radius: '2rem',
    Width: 'auto',
};

export const restartSecction = {
    Color: '#FF6357',
    Padding: '0.5rem 1rem',
    Cursor: 'pointer',
};
