import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {RenewalItemMenuProps} from '@components/ShoppingCart/interface';
import {renewalMenuStyles} from '@components/ShoppingCart/styles';
import {RENEWAL_OPTIONS} from '@components/ShoppingCart/constants';

const RenewalItemMenu: React.FC<RenewalItemMenuProps> = ({
    onExtendClick,
    onPayTowardsPrincipalClick,
    endorsement,
}) => {
    return (
        <FlexPanel
            FlexDir="column"
            Align="flex-start"
            Self="stretch"
            Border="1px solid #F3F3F3"
            Background="#FFFFFF"
            BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
        >
            {RENEWAL_OPTIONS({
                extend: onExtendClick,
                endorsement,
                payTowardsPrincipal: onPayTowardsPrincipalClick,
            }).map((option, index) => (
                <Text
                    key={index}
                    {...renewalMenuStyles}
                    Color={option.color}
                    onClick={option.action}
                >
                    {option.label}
                </Text>
            ))}
        </FlexPanel>
    );
};
export default RenewalItemMenu;
