import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import {formatterDolar} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {VALUTATE_PROFILE} from '@Shopify/ClientProfile/constants';
import {PROFILEVALUATE} from '@components/Shopify/ListClient/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ProfileValuateProps} from '@components/Shopify/ListClient/interfaces';
import {GetClientProfileInformation} from '@components/Shopify/ListClient/redux/Actions/GetAssignmentClient';

export const ProfileValuate = ({idClient, showModal, setShowModal}: ProfileValuateProps) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const dispatch = useDispatch();

    const {dataClient, loading} = useSelector((state: RootState) => state.GetAssignmentClient);

    useEffect(() => {
        if (token) dispatch(GetClientProfileInformation({clientId: idClient}, token));
    }, []);

    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => {}}
            titleModalState={true}
            background="none"
            FlexDirMod="column"
            Height="18.25rem"
            BorderRadius="1rem"
            Padding="0 1.5rem"
            Gap="1.5rem"
            Width="65rem"
        >
            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" Self="start">
                {PROFILEVALUATE.TITLE}
            </Text>

            {loading ? (
                <ContainerFlex Height="14.292rem">
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                dataClient && (
                    <ContainerFlex
                        FlexDir="column"
                        Align="start"
                        Border="solid 1px #E8E9EA"
                        Radius="1.5rem"
                        Gap="1rem"
                        Padding="1rem"
                    >
                        <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="400">
                            {PROFILEVALUATE.BEHAVIOR}
                        </Text>
                        <ContainerFlex Display="grid" GridColumns="repeat(4, 1fr)" Gap="1rem">
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.TOTAL_PAWN}</Text>
                                <Text Color="#54575C">
                                    {formatterDolar.format(dataClient.totalPawns)}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.TOTAL_CONTRACTS}</Text>
                                <Text Color="#54575C">{dataClient.totalContracts}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.TOTAL_MORTGAGE}</Text>
                                <Text Color="#54575C">{dataClient.totalMortgage}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">
                                    {VALUTATE_PROFILE.TOTAL_LOAN_PERSONALITY}
                                </Text>
                                <Text Color="#54575C">{dataClient.totalLoanPersonality}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.ACCOUN_PAWN}</Text>
                                <Text Color="#54575C">{dataClient.acountPawns}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.AVG_DISCHARGE}</Text>
                                <Text Color="#54575C">{dataClient.dischargeAverage}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.AVG_CAPITAL}</Text>
                                <Text Color="#54575C">{dataClient.averagePayCapital}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">
                                    {VALUTATE_PROFILE.TOTAL_PAWNS_DISCHARGE}
                                </Text>
                                <Text Color="#54575C">{dataClient.totalPawnsDischarge}</Text>
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start" Gap="0.25rem">
                                <Text Color="#71767D">{VALUTATE_PROFILE.TOTAL_IN_SELLING}</Text>
                                <Text Color="#54575C">{dataClient.totalContractsInSelling}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                )
            )}
            <Text
                Color="#FF6357"
                FontSize="1rem"
                FontWeight="500"
                Cursor="pointer"
                oFlow="none"
                MarginB="1rem"
                onClick={() => setShowModal(false)}
            >
                {VALUTATE_PROFILE.CLOSE_BTN}
            </Text>
        </Modal>
    );
};
