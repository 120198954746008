import React from 'react';

import '@Loading/styles.css';
import './styles.css';

export function LoadingGeneral() {
    return (
        <div className="container-loader">
            <div className="loader">
                <span className="first" />
                <span className="second" />
                <span className="third" />
            </div>
        </div>
    );
}
