import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import disabledOrg from '@images/disabledOrganizations.svg';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@General/Atoms/Button';
import {IconImg} from '@/components/Quoter/styles';
import {BolderWords, NormalWords} from '@SubContainerOrgCreation/styles';
import {disabledOrganizations} from '@SubContainerOrgCreation/interface';

const DisabledOrganization = ({
    showModalDisabled,
    setShowModalDisabled,
    ChangesStatus,
}: disabledOrganizations) => {
    const LevelData = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    return (
        <Modal
            modalState={showModalDisabled}
            changeModalState={() => setShowModalDisabled(!showModalDisabled)}
            Align="center"
            FlexDirMod="column"
            titleModalState={true}
            BoxShadow="0 0 12px 0 rgba(0, 0, 0, 0.5)"
            background="none"
            BorderRadius="4px"
            Width="28.75rem"
        >
            <ContainerFlex Padding="1.5rem 1.531rem" FlexDir="column">
                <IconImg
                    Content="none"
                    Top="auto"
                    Left="auto"
                    Opacity="1"
                    Position="inherit"
                    src={disabledOrg}
                />
                <Text
                    Padding="1.5rem 0"
                    FontSize="0.875rem"
                    Color="#414042"
                    FontWeight="normal"
                    wSpace="normal"
                    Height="auto"
                    TextAlign="center"
                >
                    ¿Estas seguro que deseas deshabilitar el nivel organizacional?
                </Text>
                <ContainerFlex
                    FlexDir="column"
                    backG="#fff2ce"
                    Border="solid 1px #ffbd00"
                    Radius="4px"
                    Padding="0.625rem"
                >
                    <Text {...NormalWords}>
                        <Text {...BolderWords} Padding="0 4px 0 0">
                            ¡Recuerda!:
                        </Text>
                        Todas las sucursales y usuarios que tengas
                    </Text>
                    <Text {...NormalWords}>
                        asociados a esté nivel organizacional, serán
                        <Text {...BolderWords} Padding="0 4px">
                            desactivados
                        </Text>
                    </Text>
                    <Text {...NormalWords}>hasta que lo vuelvas habilitar.</Text>
                </ContainerFlex>
                <ContainerFlex Justify="space-between" Width="14.063rem" Padding="1.5rem 0 0 0">
                    <ButtonGeneral
                        width="5.813rem"
                        text="Cancelar"
                        type="submit"
                        border="transparent"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hColor="#35cf44"
                        transform=""
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => setShowModalDisabled(!showModalDisabled)}
                    />
                    <ButtonGeneral
                        width="6.25rem"
                        text="Continuar"
                        type="submit"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        transform="capitalize"
                        hText="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => ChangesStatus()}
                        loading={LevelData.loading}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default DisabledOrganization;
