import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import ArrowArbolRight from '@images/SideBarMenuAdmin/ArrowArbolRight.svg';
import ArrowArbolUp from '@images/SideBarMenuAdmin/ArrowArbolUp.svg';
import ArrowSmallUp from '@images/SideBarMenuAdmin/ArrowSmallUp.svg';
import {RootState} from '@/config/store';
import {IModulesAdministrator, MainMenuItemProps} from '@components/SideBarMenu/interfaces';
import {useNavigate} from 'react-router-dom';
import SideBarMenuSubItem from '@components/SideBarMenu/SideBarMenuSubItem';
import SideBarMenuSubItemExpanded from '@components/SideBarMenu/SideBarMenuSubItemExpanded';
import {MENUADMINSETTINGS} from '@components/SideBarMenu/constantsText';

const SideBarMenuItem: React.FC<MainMenuItemProps> = ({
    item,
    index,
    clickedItems,
    clickedSubItem,
    settingsOpen,
    setSettingsOpen,
    menuSettings,
    setMenuSettings,
    handleSubItemClick,
    expandedSubItems,
    setClickedItems,
    setClickedSubItem,
}) => {
    const modulesAdministrator: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );
    const settingSpace = modulesAdministrator?.find((item) => item.downMenuItem);
    const navigate = useNavigate();
    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const clickedSettings = () => {
        setMenuSettings(!menuSettings);
        setSettingsOpen(!settingsOpen);
    };
    const handleClickClose = () => {
        setMenuSettings(false);
        setSettingsOpen(false);
    };
    const handleItemClick = (index: number) => {
        if (expandedItems.includes(index)) {
            setExpandedItems(expandedItems.filter((item) => item !== index));
        }
        if (clickedItems !== index) {
            setExpandedItems([index, ...expandedItems]);
        }
        setClickedItems(clickedItems === index ? null : index);
        setClickedSubItem(null);
        setMenuSettings(false);
    };
    return (
        <ContainerFlex
            key={index}
            FlexDir="column"
            Height="auto"
            Justify="flex-end"
            PaddingT={item === settingSpace ? '90%' : ''}
        >
            <ContainerFlex
                FlexDir="column"
                Justify={item === settingSpace && settingsOpen ? 'end' : 'center'}
            >
                <ContainerFlex
                    Width="15.25rem"
                    Height="0.0625rem"
                    MarginTop={item.isHamburger ? '1rem' : 'none'}
                    MarginBt={item.isHamburger ? '1rem' : 'none'}
                    backG={item.isHamburger ? '#E8E9EA' : 'none'}
                />

                <ContainerFlex
                    Justify="space-between"
                    Padding={settingsOpen && item.isHamburger ? '0rem' : '0.5rem'}
                    Height={settingsOpen && item.isHamburger ? '0rem' : '2.5rem'}
                    backG={clickedItems === index && !item.isHamburger ? '#F0F0FF' : '#FAFAFF'}
                    Bl={
                        clickedItems === index && !item.isHamburger
                            ? ' 1.5px solid  #5A5AFF'
                            : 'none'
                    }
                    HBorderLeft="1.5px solid  #5A5AFF"
                    HBackground="#F0F0FF"
                >
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        {!item.isHamburger &&
                            item.title !== MENUADMINSETTINGS.SETTINGS &&
                            (item.cssIcon || item.cssIconHover) && (
                                <Image
                                    alt="item-icon"
                                    src={
                                        clickedItems === index && !item.isHamburger
                                            ? item.cssIconHover
                                            : item.cssIcon
                                    }
                                    onClick={() => handleClickClose()}
                                    Cursor="pointer"
                                />
                            )}
                        {!item.isHamburger &&
                            item.title === MENUADMINSETTINGS.SETTINGS &&
                            (item.cssIcon || item.cssIconHover) && (
                                <Image
                                    alt="item-icon"
                                    src={settingsOpen ? item.cssIconHover : item.cssIcon}
                                    Cursor="pointer"
                                    onClick={() => handleClickClose()}
                                />
                            )}

                        {!settingsOpen && (
                            <Text
                                FontSize={item.isHamburger ? ' 0.875rem' : '1rem'}
                                FontWeight={item.isHamburger ? '500' : '400'}
                                Color={
                                    clickedItems === index && !item.isHamburger
                                        ? '#5A5AFF'
                                        : '#54575C'
                                }
                                onClick={
                                    item.title === MENUADMINSETTINGS.SETTINGS
                                        ? () => clickedSettings()
                                        : () => {
                                              navigate(`${item.route}`);
                                              handleClickClose();
                                              handleItemClick(index);
                                          }
                                }
                                Cursor="pointer"
                                HColor="#5A5AFF"
                            >
                                {item.title}
                            </Text>
                        )}
                    </ContainerFlex>

                    {!settingsOpen && item.isHamburger && (
                        <Image
                            alt="arrow-small-up"
                            src={ArrowSmallUp}
                            onClick={() => handleItemClick(index)}
                            Cursor="pointer"
                        />
                    )}

                    {!settingsOpen &&
                        !expandedItems.includes(index) &&
                        item.item.length > 0 &&
                        item.title !== MENUADMINSETTINGS.SETTINGS &&
                        !item.isHamburger && (
                            <Image src={ArrowArbolRight} alt="arrow-arbol-right" />
                        )}
                    {expandedItems.includes(index) && item.item.length > 0 && !item.isHamburger && (
                        <Image src={ArrowArbolUp} alt="arrow-arbol-up" />
                    )}
                </ContainerFlex>

                {!item.isHamburger && expandedItems.includes(index) && (
                    <ContainerFlex FlexDir="column">
                        {item.item.map((subItem, subIndex) => (
                            <SideBarMenuSubItemExpanded
                                key={subIndex}
                                subItem={subItem}
                                index={index}
                                subIndex={subIndex}
                                clickedSubItem={clickedSubItem}
                                handleSubItemClick={handleSubItemClick}
                            />
                        ))}
                    </ContainerFlex>
                )}
                {item.isHamburger && !expandedItems.includes(index) && (
                    <ContainerFlex FlexDir="column">
                        {item.item.map((subItem, subIndex) => (
                            <SideBarMenuSubItem
                                key={subIndex}
                                subItem={subItem}
                                index={index}
                                subIndex={subIndex}
                                clickedSubItem={clickedSubItem}
                                settingsOpen={settingsOpen}
                                handleSubItemClick={handleSubItemClick}
                                expandedSubItems={expandedSubItems}
                                handleClickClose={handleClickClose}
                            />
                        ))}
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default SideBarMenuItem;
