import axios from 'axios';
import {PostDataContactAdd} from '@MyCompany/Interface';
import {
    POST_DATA_CONTACT_ADD_START,
    POST_DATA_CONTACT_ADD_SUCCESS,
    POST_DATA_CONTACT_ADD_ERROR,
} from '@MyCompany/Redux/Types/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {AxiosResponse} from 'axios';

export const PostDataContactAddStart = () => {
    return {
        type: POST_DATA_CONTACT_ADD_START,
    };
};
export const PostDataContactAddSuccess = (data: AxiosResponse<string>) => {
    return {
        type: POST_DATA_CONTACT_ADD_SUCCESS,
        payload: data,
    };
};

export const PostDataContactAddError = () => {
    return {
        type: POST_DATA_CONTACT_ADD_ERROR,
    };
};

export const PostAddContactData = (token: string, data: PostDataContactAdd) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(PostDataContactAddStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostDataContactAdd}`,
                data,
                {headers}
            );
            dispatch(PostDataContactAddSuccess(response));
        } catch (error) {
            dispatch(PostDataContactAddError());
        }
    };
};
