import React, {useState} from 'react';

import {Categories} from '@Electronics/listElectronics';
import {LoadingGeneralButtons} from '@Electronics/loading-buttons';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';

const Filters = (props) => {
    const filter = Categories.info.results;
    const [quality, setQuality] = useState(3);
    const HanldeQuality = (e, value) => {
        setQuality(value);
    };
    return (
        <>
            {filter ? (
                <>
                    {filter.map((element) => {
                        return (
                            <div key={element.id}>
                                <div className="d-flex">
                                    <h4 className="filter-titles">{element.name}</h4>
                                    <div className="line-filter"></div>
                                </div>
                                <form className="filter-form d-flex flex-column">
                                    {element.data.map((value) => {
                                        return (
                                            <form
                                                key={value.id}
                                                control={
                                                    <Checkbox
                                                        value={value.name}
                                                        onChange={props.HandleCheckBox}
                                                        name={value.name}
                                                    />
                                                }
                                                label={value.name}
                                            />
                                        );
                                    })}
                                </form>
                            </div>
                        );
                    })}
                </>
            ) : null}
            <div className="mb-4 mt-5">
                <div className="d-flex">
                    <h4 className="filter-titles">Precio</h4>
                    <div className="line-filter"></div>
                </div>
                {/* <SliderPerformance 
                    defaultValue={props.price}
                    step={1}
                    onChange={props.HandlePricePrint}
                    className='mb-3 '
                    min={0}
                    max={16000}
                /> */}
                <span className="text-price">
                    Precio ${props.price[0]} - ${props.price[1]}
                </span>

                {props.stateButton ? (
                    <button className="button-filter" onClick={props.HandleFilters}>
                        Filtrar
                    </button>
                ) : (
                    <button className="button-filter" disabled>
                        <LoadingGeneralButtons />
                    </button>
                )}
            </div>
            <div>
                <div className="d-flex">
                    <h4 className="filter-titles">Calidad</h4>
                    <div className="line-filter"></div>
                </div>
                {/* <Rating
                        name='rating-quality'
                        value={quality}
                        defaultValue={quality}
                        onChange={(e, value)=>HanldeQuality(e, value)}
                    /> */}
            </div>
        </>
    );
};

export default Filters;
