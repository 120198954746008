import * as yup from 'yup';

const regexPatterns = {
    cardHolder: /^[a-zA-Z.\sáéíóúñÁÉÍÓÚÑ\-']+$/,
    creditCard: /^(?:\d{4} ){3}\d{3,4}$|^\d{15,16}$/,
    cvv: /^[0-9]{3,4}$/,
    month: /^(0[1-9]|1[0-2])$/,
    year: /^[0-9]{4}$/,
};

const isFutureDate = (month: string, year: string) => {
    const [currentYear, currentMonth] = [new Date().getFullYear(), new Date().getMonth() + 1];
    const [expYear, expMonth] = [parseInt(year, 10), parseInt(month, 10)];

    return expYear > currentYear || (expYear === currentYear && expMonth >= currentMonth);
};

export const schemaAddNewPaymentMethod = yup
    .object()
    .shape({
        cardHolder: yup
            .string()
            .matches(
                regexPatterns.cardHolder,
                'Ingresa un nombre válido. Solo letras, puntos y espacios.'
            ),
        cardNumber: yup
            .string()
            .test('valid-card-number', 'El número de tarjeta debe tener 16 dígitos.', (value) =>
                regexPatterns.creditCard.test(value ? value.replace(/\s+/g, '') : '')
            ),
        expiryMonth: yup
            .string()
            .matches(regexPatterns.month, 'Selecciona un mes válido entre 1 y 12.')
            .test(
                'is-future-date',
                'La fecha de expiración debe ser en el futuro.',
                function (value) {
                    const {expiryYear} = this.parent;
                    return value && expiryYear && isFutureDate(value, expiryYear);
                }
            ),
        expiryYear: yup
            .string()
            .matches(regexPatterns.year, 'Ingresa un año de expiración futuro.')
            .test(
                'is-future-date',
                'La fecha de expiración debe ser en el futuro.',
                function (value) {
                    const {expiryMonth} = this.parent;
                    return value && expiryMonth && isFutureDate(expiryMonth, value);
                }
            ),
        cvv: yup.string().matches(regexPatterns.cvv, 'El CVV debe ser de 3 o 4 dígitos.'),
    })
    .test('general-error', 'Al menos un campo debe ser proporcionado.', function (value) {
        const {cardHolder, cardNumber, expiryMonth, expiryYear, cvv} = value;
        if (!cardHolder && !cardNumber && !expiryMonth && !expiryYear && !cvv) {
            return this.createError({
                path: 'at-least-one',
                message: 'Verifica los campos marcados en rojo.',
            });
        }
        return true;
    });
