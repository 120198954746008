import {GET_CARSHOOPINGID_TEMPORAL} from '@TypesProducts/ProductsShopifyTypes';
import {AxiosResponse} from 'axios';
const initialState = {
    error: false,
    loading: false,
    carShoopi: 0,
    blockActivate: false,
};

export const CarValuatorId = (
    state = initialState,
    actions: {type: string; payload: AxiosResponse}
) => {
    switch (actions.type) {
        case GET_CARSHOOPINGID_TEMPORAL:
            return {
                ...state,
                carShoppingId: actions.payload,
            };
        default:
            return state;
    }
};
