export const GET_IMAGE_USER = 'GET_IMAGE_USER';
export const GET_IMAGE_USER_SUCCESS = 'GET_IMAGE_USER_SUCCESS';
export const GET_IMAGE_USER_ERROR = 'GET_IMAGE_USER_ERROR';

export const POST_IMAGE_USER = ' POST_IMAGE_USER';
export const POST_IMAGE_USER_SUCCESS = ' POST_IMAGE_USER_SUCCESS';
export const POST_IMAGE_USER_ERROR = ' POST_IMAGE_USER_ERROR';

export const GET_USER_INFORMATION_BY_ID = ' GET_USER_INFORMATION_BY_ID';
export const GET_USER_INFORMATION_BY_ID_SUCCESS = 'GET_USER_INFORMATION_BY_ID_SUCCESS';
export const GET_USER_INFORMATION_BY_ID_ERROR = ' GET_USER_INFORMATION_BY_ID_ERROR';

export const PUT_DATA_USER_EMPLOYEE = 'PUT_DATA_USER_EMPLOYEE';
export const PUT_DATA_USER_EMPLOYEE_SUCCESS = 'PUT_DATA_USER_EMPLOYEE_SUCCESS';
export const PUT_DATA_USER_EMPLOYEE_ERROR = 'PUT_DATA_USER_EMPLOYEE_ERROR';
export const RESET_DATA_USER_UPDATE = 'RESET_DATA_USER_UPDATE';

export const GET_ACCESS_TO_SYSTEM_USER = 'GET_ACCESS_TO_SYSTEM_USER';
export const GET_ACCESS_TO_SYSTEM_USER_SUCCESS = 'GET_ACCESS_TO_SYSTEM_USER_SUCCESS';
export const GET_ACCESS_TO_SYSTEM_USER_ERROR = 'GET_ACCESS_TO_SYSTEM_USER_ERROR';

export const PUT_ACCESS_TO_SYSTEM_USER = 'PUT_ACCESS_TO_SYSTEM_USER';
export const PUT_ACCESS_TO_SYSTEM_USER_SUCCESS = 'PUT_ACCESS_TO_SYSTEM_USER_SUCCESS';
export const PUT_ACCESS_TO_SYSTEM_USER_ERROR = 'PUT_ACCESS_TO_SYSTEM_USER_ERROR';

export const GET_PASSWORD_RANDOM = 'GET_PASSWORD_RANDOM';
export const GET_PASSWORD_RANDOM_SUCCESS = 'GET_PASSWORD_RANDOM_SUCCESS';
export const GET_PASSWORD_RANDOM_ERROR = 'GET_PASSWORD_RANDOM_ERROR';
