export const GET_SECURITYBAG_BRANCH = 'GET_SECURITYBAG_BRANCH';
export const GET_SECURITYBAG_BRANCH_SUCCESS = 'GET_SECURITYBAG_BRANCH_SUCCESS';
export const GET_SECURITYBAG_BRANCH_ERROR = 'GET_SECURITYBAG_BRANCH_ERROR';

export const GET_SECURITYBAG_BRANCH_VALUE = 'GET_SECURITYBAG_BRANCH_VALUE';
export const GET_SECURITYBAG_BRANCH_VALUE_SUCCESS = 'GET_SECURITYBAG_BRANCH_VALUE_SUCCESS';
export const GET_SECURITYBAG_BRANCH_VALUE_ERROR = 'GET_SECURITYBAG_BRANCH_VALUE_ERROR';

export const GET_COLLECTOR = 'GET_COLLECTOR';
export const GET_COLLECTOR_SUCCESS = 'GET_COLLECTOR_SUCCESS';
export const GET_COLLECTOR_ERROR = 'GET_COLLECTOR_ERROR';

export const GET_COLLECTOR_INTERNAL = 'GET_COLLECTOR_INTERNAL';
export const GET_COLLECTOR_INTERNAL_SUCCESS = 'GET_COLLECTOR_INTERNAL_SUCCESS';
export const GET_COLLECTOR_INTERNAL_ERROR = 'GET_COLLECTOR_INTERNAL_ERROR';

export const PUT_IMAGE_DELIVERY = 'PUT_IMAGE_DELIVERY';
export const PUT_IMAGE_DELIVERY_SUCCESS = 'PUT_IMAGE_DELIVERY_SUCCESS';
export const PUT_IMAGE_DELIVERY_ERROR = 'PUT_IMAGE_DELIVERY_ERROR';

export const GET_SECURITYBAG_ADD = 'GET_SECURITYBAG_ADD';
export const GET_SECURITYBAG_ADD_SUCCESS = 'GET_SECURITYBAG_ADD_SUCCESS';
export const GET_SECURITYBAG_ADD_ERROR = 'GET_SECURITYBAG_ADD_ERROR';
