import React from 'react';
export const TotalTableFoundry = (props: {
    cantidad: number;
    gramos: number;
    costo: number;
}): JSX.Element => {
    const {cantidad, gramos, costo} = props;

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <tr className="row-sucursal-tr">
            <td className="icon-color">Total</td>
            <td className="icon-color">{cantidad}</td>
            <td className="icon-color">{gramos}</td>
            <td className="icon-color">{formatterDolar.format(costo)}</td>
        </tr>
    );
};
