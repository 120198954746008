import {IconSvg} from '@/components/Quoter/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
interface ISuccessArticle {
    setSuccesMesssage: (e: boolean) => void;
}
export const SuccessMessageRequestArticle = ({setSuccesMesssage}: ISuccessArticle) => {
    return (
        <ContainerFlex
            Width="34.375rem"
            Color="#E4F7EC"
            Position="fixed"
            ZIndex="5"
            backG="#E4F7EC"
            ColumnGap="8px"
            Radius="8px"
            Height="auto"
            Padding="8px"
            PositionLeft={'40%'}
        >
            <IconSvg height="32px" width="32px">
                <path
                    d="M12.7338 23.9999L5.13379 16.3999L7.03379 14.4999L12.7338 20.1999L24.9671 7.96655L26.8671 9.86655L12.7338 23.9999Z"
                    fill="#309C60"
                />
            </IconSvg>
            <ContainerFlex FlexDir="column" ColumnGap="8px" Align="start">
                <Text FontSize="1.25rem" Color="#174A2E" FontWeight="500">
                    {REQUESTS_ARTICLE.SUCCESS_REQUEST}
                </Text>
                <Text FontSize="1.25rem" Color="#174A2E" FontWeight="400">
                    {REQUESTS_ARTICLE.SUCCESS_REQUESTñ_SECOND}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
