import {
    GET_AUCTIONS,
    GET_AUCTIONS_SUCCESS,
    GET_AUCTIONS_ERROR,
    GET_IMAGES_AUCTIONS,
    GET_IMAGES_AUCTIONS_SUCCESS,
    GET_IMAGES_AUCTIONS_ERROR,
    GET_AUDIT_AUCTIONS,
    GET_AUDIT_AUCTIONS_SUCCESS,
    GET_AUDIT_AUCTIONS_ERROR,
    POST_AUDIT_INCIDENTS,
    POST_AUDIT_INCIDENTS_SUCCESS,
    POST_AUDIT_INCIDENTS_ERROR,
    DELETE_AUDIT_AUCTIONS,
    DELETE_AUDIT_AUCTIONS_SUCCESS,
    DELETE_AUDIT_AUCTIONS_ERROR,
    RESET_AUDIT_ACTIONS,
    INFO_AUDIT_INCIDENT,
    INFO_AUDIT_INCIDENT_SUCCESS,
    INFO_AUDIT_INCIDENT_ERROR,
} from '@TypesAuction/AuctionTypes';

const initialState = {
    auctions: [],
    auctionsimages: [],
    auctionsaudit: null,
    error: false,

    loading: false,
    infoaudit: null,
};

const auctions = (state = initialState, action: {incidencia: any; type: string; value: any}) => {
    switch (action.type) {
        case GET_AUCTIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_AUCTIONS_SUCCESS:
            return {
                ...state,
                auctions: action.value,
                loading: false,
            };
        case GET_AUCTIONS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case INFO_AUDIT_INCIDENT:
            return {
                ...state,
                loading: true,
            };
        case INFO_AUDIT_INCIDENT_SUCCESS:
            return {
                ...state,
                infoaudit: action.value,
                tipoincidencia: action.incidencia,
                loading: false,
            };
        case INFO_AUDIT_INCIDENT_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_IMAGES_AUCTIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_IMAGES_AUCTIONS_SUCCESS:
            return {
                ...state,
                auctionsimages: action.value,
                loading: false,
            };
        case GET_IMAGES_AUCTIONS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_AUDIT_AUCTIONS:
            return {
                ...state,

                loading: true,
            };
        case GET_AUDIT_AUCTIONS_SUCCESS:
            return {
                ...state,
                auctionsaudit: action.value,
                loading: false,
            };
        case GET_AUDIT_AUCTIONS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case POST_AUDIT_INCIDENTS:
            return {
                ...state,
                loading: true,
            };
        case POST_AUDIT_INCIDENTS_SUCCESS:
            return {
                ...state,

                loading: false,
            };
        case POST_AUDIT_INCIDENTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case DELETE_AUDIT_AUCTIONS:
            return {
                ...state,
                loading: true,
            };
        case DELETE_AUDIT_AUCTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_AUDIT_AUCTIONS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case RESET_AUDIT_ACTIONS:
            return {
                ...state,
                auctionsaudit: null,
            };
        default:
            return state;
    }
};
export default auctions;
