import React from 'react';
import {ContainerFlex, Image, Input} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import SearchImg from '@/images/search.svg';
import {BRANCH_ASSIGNMENT} from './Operational/Constants';

export const SearchFilterAssignment = () => {
    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <ContainerFlex backG="" Height="40px" Justify="start" Gap="16px">
                <ContainerFlex Width="270px">
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="calc(100% - 24px) 24px"
                        Margin="0 0 4px 0"
                        BoxShadowH=""
                        BoxShadowF=""
                        Padding="0 1rem 0 0"
                        BackG="#FFFFFF"
                        Radius="32px"
                        InputPadding="0 1rem"
                    >
                        <Input type="text" placeholder={BRANCH_ASSIGNMENT.SEARCH} />
                        <Image
                            alt="icone-search"
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            src={SearchImg}
                        />
                    </SearchInput>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
