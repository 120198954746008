import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import downArrowSelected from '@components/Branches/Images/DownArrowSelected.svg';
import upArrowSelected from '@components/Branches/Images/UpArrowSelected.svg';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import {TableUserHeader} from '@components/Branches/ManageBranch/BranchUsers/TableBranchUsers/TableUserHeader';
import {TableUserBranch} from '@components/Branches/ManageBranch/BranchUsers/TableBranchUsers/TableUserBranch';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {AddUserModal} from '@components/Branches/ManageBranch/BranchUsers/AddUserModal';
import {GetBranchUsers} from '@components/Branches/ManageBranch/Redux/Actions/GetBranchUsers';
import {RootState} from '@/config/store';
import {IconContainer} from '@/components/Quoter/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {IBranchUser, IBranchs} from '@components/Branches/interfaces';
import {GetAllUsers} from '@/components/CreateUsers/Redux/Actions/GetAllUsers';
import {User} from '@/components/CreateUsers/Interface';
import {CreateUserBranch} from '@components/Branches/ManageBranch/Redux/Actions/CreateUserBranch';
import {Highlight} from '@components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';

export const BranchUsers = ({showItems, setShowItems}: IBranchUser) => {
    const {idBranch} = useParams();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const branchUsers = useSelector((state: RootState) => {
        return state.GetBranchUsers?.branchUsers?.data;
    });
    const {loading} = useSelector((state: RootState) => {
        return state.GetBranchUsers;
    });
    const searchUsers = useSelector((state: RootState) => state.GetAllUsers?.contact);
    const dispatch = useDispatch();
    const [search, setSearch] = useState(false);
    const [itemSearch, setItemSearch] = useState(false);
    const [searchedValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState({
        branchId: Number(idBranch),
        searchName: '',
        orderType: 0,
        filterUser: 0,
    });
    const [filterSearch, setFilterSearch] = useState({
        companyId,
        searchName: '',
        pageSize: 20,
        pageNumber: 1,
        orderType: 1,
        filterUser: 0,
    });
    const [addUserModal, setAddUserModal] = useState(false);
    useEffect(() => {
        dispatch(GetBranchUsers(filters, token));
    }, []);
    useEffect(() => {
        if (filterSearch.searchName.length > 2) dispatch(GetAllUsers(token, filterSearch));
    }, [filterSearch]);
    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                backG="#f3f3f3"
                Height="56px"
                Justify="space-between"
                Radius="4px 4px 0 0"
                Border="1px solid #e5e7e9"
                Padding="18.5px 16px"
            >
                <Text FontSize="1rem" FontWeight="500" Color="#414042">
                    Usuarios
                </Text>
                {!showItems.user ? (
                    <Image
                        src={downArrowSelected}
                        alt="down-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                user: !showItems.user,
                                info: false,
                                schedule: false,
                                services: false,
                                cashFlow: false,
                            });
                        }}
                    />
                ) : (
                    <Image
                        src={upArrowSelected}
                        alt="up-arrow"
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setShowItems({
                                ...showItems,
                                user: !showItems.user,
                            });
                        }}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="column"
                Height="auto"
                Padding="16px"
                Radius="0 0 4px 4px"
                Bb="1px solid #e5e7e9"
                Bl="1px solid #e5e7e9"
                Br="1px solid #e5e7e9"
                Gap={!showItems.user ? '0px' : '8px'}
                MarginBt="24px"
            >
                <AddUserModal addUserModal={addUserModal} setAddUserModal={setAddUserModal} />
                {!showItems.user ? (
                    <>
                        <Text FontSize="0.875rem" Color="#6d6e71">
                            Listado de usuarios asociados a la sucursal
                        </Text>
                        {branchUsers?.length === 0 && !loading && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                Completa la información requerida para operar la sucursal
                            </Text>
                        )}
                    </>
                ) : (
                    <ContainerFlex Justify="start" FlexDir="column">
                        <ContainerFlex Justify="space-between">
                            <ContainerFlex Padding="0 0 16px 0" Justify="start">
                                <SearchInput
                                    Width="20.37rem"
                                    Height="40px"
                                    Position="relative"
                                    GridColumn="90% 10%"
                                    BorderH="1px solid #35CF44"
                                    BoxShadowH=""
                                    BorderF="2px solid #35CF44"
                                    BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                                    Margin="0 0 0 16px"
                                >
                                    <input
                                        value={filterSearch.searchName}
                                        placeholder="Buscar nombre del usuario"
                                        onChange={(e: {target: {value: string}}) => {
                                            setFilterSearch({
                                                ...filterSearch,
                                                searchName: e.target.value,
                                            });
                                            setSearchValue(e.target.value);
                                            if (e.target.value.length >= 3) {
                                                setSearch(true);
                                                setItemSearch(true);
                                            }
                                            if (e.target.value.length < 3) {
                                                setSearch(false);
                                                setItemSearch(false);
                                            }
                                        }}
                                    />
                                    {!itemSearch ? (
                                        <IconContainer Width="100%" className="material-icons">
                                            search
                                        </IconContainer>
                                    ) : (
                                        <IconContainer
                                            Width="100%"
                                            className="material-icons"
                                            onClick={() => {
                                                setFilters({
                                                    ...filters,
                                                    searchName: '',
                                                });
                                                setFilterSearch({
                                                    ...filterSearch,
                                                    searchName: '',
                                                });
                                                setItemSearch(false);
                                                setSearch(false);
                                            }}
                                        >
                                            close
                                        </IconContainer>
                                    )}

                                    {search && (
                                        <ContainerFlex
                                            Height="auto"
                                            FlexDir="column"
                                            backG="#fff"
                                            Position="absolute"
                                            PositionTop="100%"
                                            Border="1px solid #F3F3F3"
                                            Justify="start"
                                            ZIndex="2"
                                            Radius="4px"
                                        >
                                            <ContainerFlex
                                                Height={
                                                    searchUsers?.filter(
                                                        (data: User) =>
                                                            !data.branchs
                                                                .map(
                                                                    (branch: IBranchs) =>
                                                                        branch.branchId
                                                                )
                                                                .includes(Number(idBranch))
                                                    ).length < 5
                                                        ? 'auto'
                                                        : '200px'
                                                }
                                                OverFlowY="scroll"
                                                FlexWrap="wrap"
                                            >
                                                {searchUsers
                                                    ?.filter(
                                                        (data: User) =>
                                                            !data.branchs
                                                                .map(
                                                                    (branch: IBranchs) =>
                                                                        branch.branchId
                                                                )
                                                                .includes(Number(idBranch))
                                                    )
                                                    .map((item: User, index: number) => (
                                                        <ContainerFlex
                                                            key={index}
                                                            Justify="start"
                                                            Padding="0 16px"
                                                            Height="40px"
                                                            backG="#fff"
                                                            BoxBackground="#f3f3f3"
                                                            Cursor="pointer"
                                                            onClick={() => {
                                                                dispatch(
                                                                    CreateUserBranch(
                                                                        {
                                                                            branchId:
                                                                                Number(idBranch),
                                                                            usersId: [item.userId],
                                                                        },
                                                                        token,
                                                                        setItemSearch,
                                                                        Number(idBranch)
                                                                    )
                                                                );
                                                                setFilters({
                                                                    ...filters,
                                                                    searchName: '',
                                                                });
                                                                setFilterSearch({
                                                                    ...filterSearch,
                                                                    searchName: '',
                                                                });
                                                                setSearch(false);
                                                            }}
                                                        >
                                                            <Highlight
                                                                text={item.userName}
                                                                highlight={searchedValue}
                                                            />
                                                        </ContainerFlex>
                                                    ))}
                                            </ContainerFlex>
                                            {searchUsers?.filter(
                                                (data: User) =>
                                                    !data.branchs
                                                        .map((branch: IBranchs) => branch.branchId)
                                                        .includes(Number(idBranch))
                                            ).length > 5 && (
                                                <ContainerFlex
                                                    Justify="start"
                                                    Height="40px"
                                                    backG="#fff"
                                                    Padding="0 16px"
                                                    Bt="1px solid #F3F3F3"
                                                >
                                                    <Text
                                                        FontSize="0.875rem"
                                                        Color="#414042"
                                                        Padding="0 6px 0 0"
                                                    >{`${
                                                        searchUsers?.filter(
                                                            (data: User) =>
                                                                !data.branchs
                                                                    .map(
                                                                        (branch: IBranchs) =>
                                                                            branch.branchId
                                                                    )
                                                                    .includes(Number(idBranch))
                                                        ).length - 5
                                                    } resultados más de`}</Text>
                                                    <Text
                                                        FontSize="0.875rem"
                                                        FontWeight="700"
                                                        Color="#414042"
                                                    >{`"${filterSearch.searchName}"`}</Text>
                                                </ContainerFlex>
                                            )}
                                            {searchUsers?.filter(
                                                (data: User) =>
                                                    !data.branchs
                                                        .map((branch: IBranchs) => branch.branchId)
                                                        .includes(Number(idBranch))
                                            ).length === 0 && (
                                                <ContainerFlex
                                                    Justify="start"
                                                    Height="40px"
                                                    backG="#fff"
                                                    Padding="0 16px"
                                                    Bt="1px solid #F3F3F3"
                                                >
                                                    <Text FontSize="0.875rem" Color="#414042">
                                                        {'No se encontraron coincidencias'}
                                                    </Text>
                                                </ContainerFlex>
                                            )}
                                        </ContainerFlex>
                                    )}
                                </SearchInput>
                            </ContainerFlex>
                            <ContainerFlex
                                Width="auto"
                                Justify="start "
                                Height="80%"
                                onClick={() => {
                                    setAddUserModal(!addUserModal);
                                }}
                            >
                                <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                                    Añadir usuario
                                </Text>
                                <ImgLocation
                                    src={nextArrow}
                                    Width="0.875rem"
                                    Height="0.875rem"
                                    Margin="0 0 0 4px"
                                    Cursor="pointer"
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                        <TableUserHeader filters={filters} setFilters={setFilters} />
                        <TableUserBranch />
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
