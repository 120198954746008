import styled from '@emotion/styled';
export const TreeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Option = styled.div<{isSelected: boolean}>`
    cursor: pointer;
    padding: 8px;
    width: 17.59rem;
    background-color: ${({isSelected}) => (isSelected ? '#E5E5FF' : '#FFF')};
    border-left: ${({isSelected}) => (isSelected ? '2px solid #5A5AFF ' : '2px solid #fff')};
    &:hover {
        background-color: #f5f5f5;
        border-left: 2px solid #5a5aff;
    }
`;

export const Img = styled.img`
    width: 32px;
    height: 32px;
`;
export const ScrollableOptionsContainer = styled.div`
    max-height: calc(5 * 40px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
`;
