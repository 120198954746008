import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';

import filter from '@images/filter.svg';
import expanded from '@images/expande.svg';
import menuOptions from '@images/IconMenuOptions.svg';
import {savePawnId} from './Redux/Action/DetailsContract';
import {DATE_FORMATS} from '@Shopify/ListClient/constants';
import {convertDate, OPERATIONS} from '@Shopify/ClientProfile/constants';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {IPawnContracts} from '@Shopify/ClientProfile/interfaces';
import {formatDate, formatNum} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {FiltersContracts} from '@Shopify/ClientProfile/FiltersContracts';
import {catStatusContract, endeavors} from '@Shopify/ClientProfile/Redux/Action/EndeavorsContracts';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';

export const EndeavorContracts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {pawn, token, clientId} = useSelector((state: RootState) => ({
        clientId: state.cardClientProfile.clientProfile.clientId,
        pawn: state.endeavorsContracs.contracts,
        token: state.getUsersValidation.userData?.token as string,
    }));
    const [filters, setFilters] = useState(false);

    const handleOptionClick = async (contract: IPawnContracts) => {
        await dispatch(savePawnId(contract.contractId));
        navigate('/ClientProfile/ContractDetail');
    };

    useEffect(() => {
        dispatch(catStatusContract(token));
        dispatch(endeavors(token, {clientId: clientId}));
    }, []);

    return (
        <ContainerFlex FlexDir="column" Align="end" Gap="1rem">
            <ContainerFlex {...style.contractsResume.tab} JustifyItems="center">
                {OPERATIONS.ENDEAVORS.TOTALS.map((header: string, index: number) => (
                    <Text Color="#54575C" FontSize="0.875rem" key={index}>
                        {header}
                    </Text>
                ))}
                <Text {...style.contractsResume.content}>
                    {pawn &&
                        formatNum({
                            number: pawn.totalBalance,
                            fractionDigits: 2,
                            money: true,
                        })}
                </Text>
                <Text {...style.contractsResume.content}>{pawn && pawn.totalContracts}</Text>
                <Text {...style.contractsResume.content}>
                    {pawn &&
                        formatNum({
                            number: pawn.totalPayment,
                            fractionDigits: 2,
                            money: true,
                        })}
                </Text>
            </ContainerFlex>
            <FlexPanel
                {...style.filter}
                Border="1px solid #5A5AFF"
                Radius="2rem"
                onClick={() => setFilters(true)}
            >
                <Image Height="1.5rem" Width="1.5rem" src={filter} alt="filters-icon" />
                <Text {...style.filter.text}>{OPERATIONS.FILTER}</Text>
            </FlexPanel>
            <ContainerFlex
                {...style.table.content}
                Padding="0.5rem"
                GridColumns="repeat(10, 1fr) .4fr"
            >
                {OPERATIONS.ENDEAVORS.HEADERS.map((header: string, index: number) => (
                    <ContainerFlex
                        key={index}
                        Cursor={index === 0 ? 'pointer' : 'auto'}
                        Gap="0.25rem"
                        Justify="start"
                    >
                        <Text
                            {...style.table.header}
                            Padding="0.25rem 0"
                            Cursor={index === 0 ? 'pointer' : 'auto'}
                        >
                            {header}
                        </Text>
                        {index === 0 && <Image src={expanded} alt="expanded-icon" />}
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            {pawn && pawn.pawnContracts && pawn.pawnContracts.length > 0 ? (
                pawn.pawnContracts.map((item) => (
                    <ContainerFlex
                        key={item.contractId}
                        {...style.table.content}
                        GridColumns="repeat(10, 1fr) .2fr"
                    >
                        <Text {...style.table.header} Color="#5A5AFF">
                            {item.contractNumber}
                        </Text>
                        <Text {...style.table.details}>{item.numberGarments}</Text>
                        <ContainerFlex {...style.contentImg} Radius="0.25rem">
                            <Image src={item.urlGarment} {...style.img} alt="articles-img" />
                        </ContainerFlex>
                        <Text {...style.table.details}>
                            {formatNum({
                                number: item.renewal,
                                fractionDigits: 0,
                                money: true,
                            })}
                        </Text>
                        <Text {...style.table.details}>
                            {formatNum({
                                number: item.balance,
                                fractionDigits: 0,
                                money: true,
                            })}
                        </Text>
                        <Text {...style.table.details}>
                            {formatNum({
                                number: item.loan,
                                fractionDigits: 0,
                                money: true,
                            })}
                        </Text>
                        <Text {...style.table.details}>
                            {formatNum({
                                number: item.appraisal,
                                fractionDigits: 0,
                                money: true,
                            })}
                        </Text>
                        <Text {...style.table.details}>{item.branchName}</Text>
                        <Text {...style.table.details}>
                            {formatDate(convertDate(item.endDate), DATE_FORMATS.FULL_DATE)}
                        </Text>
                        <Text {...style.colorStatusContract(item.status)}>{item.status}</Text>
                        <Image
                            onClick={() => handleOptionClick(item)}
                            src={menuOptions}
                            alt="menuIcon"
                        />
                    </ContainerFlex>
                ))
            ) : (
                <ContainerFlex FontWeight="500" Color="#2A2C2F" FontSize="0.875rem">
                    {OPERATIONS.ENDEAVORS.NO_CONTRACTS}
                </ContainerFlex>
            )}

            {filters && <FiltersContracts filters={filters} setFilters={setFilters} />}
        </ContainerFlex>
    );
};
