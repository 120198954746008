import {
    GET_EMPLOYEE_INCIDENTS,
    GET_EMPLOYEE_INCIDENTS_SUCCESS,
    GET_EMPLOYEE_INCIDENTS_ERROR,
    GET_EMPLOYEE_RESPONSIBLE,
    GET_EMPLOYEE_RESPONSIBLE_SUCCESS,
    GET_EMPLOYEE_RESPONSIBLE_ERROR,
    PUT_AUDIT_INCIDENTS,
    PUT_AUDIT_INCIDENTS_SUCCESS,
    PUT_AUDIT_INCIDENTS_ERROR,
} from '@TypesIncidentsFoundry/IncidentsFoundryTypes';

import {resetAuditAuctions} from '@ActionsAuction/AuctionActions';
import {URL} from '@config/constants/index';

import axios from 'axios';
import Swal from 'sweetalert2';
import {AppDispatch} from '@/config/store';
import {IncidentsFoundry} from '@/components/Foundry/interfaces';

export const fetchGetEmployeeIncidents = () => {
    return {
        type: GET_EMPLOYEE_INCIDENTS,
    };
};
export const fetchGetEmployeeIncidentsSuccess = (result: {data: unknown}) => {
    return {
        type: GET_EMPLOYEE_INCIDENTS_SUCCESS,
        value: result,
    };
};

export const fetchGetEmployeeIncidentsError = () => {
    return {
        type: GET_EMPLOYEE_INCIDENTS_ERROR,
    };
};
export function getEmployeeIncidents(sku: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeIncidents());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.EmployeeIncident.replace(
                    '{sku}',
                    sku
                )}`,
                {headers}
            );
            dispatch(fetchGetEmployeeIncidentsSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetEmployeeIncidentsError());
        }
    };
}

export const fetchGetEmployeeResponsible = () => {
    return {
        type: GET_EMPLOYEE_RESPONSIBLE,
    };
};
export const fetchGetEmployeeResponsibleSuccess = (result: {data: unknown}) => {
    return {
        type: GET_EMPLOYEE_RESPONSIBLE_SUCCESS,
        value: result,
    };
};

export const fetchGetEmployeeResponsibleError = () => {
    return {
        type: GET_EMPLOYEE_RESPONSIBLE_ERROR,
    };
};
export function getEmployeeResponsible(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmployeeResponsible());
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_SERVICE_FOUNDRY
                }${URL.urlFoundry.EmployeeResponsible.replace('{id}', id)}`,
                {headers}
            );
            dispatch(fetchGetEmployeeResponsibleSuccess(response.data));
        } catch (error) {
            dispatch(fetchGetEmployeeResponsibleError());
        }
    };
}

export const fetchPutAuditIncidents = () => {
    return {
        type: PUT_AUDIT_INCIDENTS,
    };
};
export const fetchPutAuditIncidentsSuccess = () => {
    return {
        type: PUT_AUDIT_INCIDENTS_SUCCESS,
    };
};

export const fetchPutAuditIncidentsError = () => {
    return {
        type: PUT_AUDIT_INCIDENTS_ERROR,
    };
};
export function putAuditIncidents(
    data: IncidentsFoundry,
    token: string,
    idSucursal: number,
    idAsignacion: number
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPutAuditIncidents());

        return axios
            .post(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.IncidentAudit}`, data, {
                headers,
            })
            .then((result) => {
                dispatch(fetchPutAuditIncidentsSuccess());
                Swal.fire({
                    icon: 'success',
                    title: ' Crear incidencia',
                    text: '¡La incidencia ha sido creada con éxito!',
                });
                window.location.href = `/Fundiciones/Recoleccion/${idSucursal}/${idAsignacion}`;
                dispatch(resetAuditAuctions());
            })
            .catch((err) => {
                dispatch(fetchPutAuditIncidentsError());
                Swal.fire({
                    icon: 'error',
                    title: 'Crear incidencia',
                    text: err.response.data,
                });
            });
    };
}
