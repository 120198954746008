import {
    REQUEST_CASHFLOW,
    REQUEST_CASHFLOW_SUCCESS,
    REQUEST_CASHFLOW_ERROR,
} from '@ReduxsCashFlow/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    data: null,
    loading: false,
    error: false,
};

const requestCashFlow = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case REQUEST_CASHFLOW:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_CASHFLOW_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case REQUEST_CASHFLOW_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default requestCashFlow;
