import {
    GET_LOGIN_PROBLEMS,
    GET_LOGIN_PROBLEMS_ERROR,
    GET_LOGIN_PROBLEMS_SUCCESS,
} from '@components/LoginClient/Redux/Types/Types';
import {IReducerType} from '@components/Branches/interfaces';

const initialState = {
    loading: false,
    error: false,
    catProblems: [],
};

const GetLoginProblems = (state = initialState, action: IReducerType) => {
    switch (action.type) {
        case GET_LOGIN_PROBLEMS:
            return {
                ...state,
                loading: true,
            };
        case GET_LOGIN_PROBLEMS_SUCCESS:
            return {
                ...state,
                catProblems: action.payload.data,
                loading: false,
            };
        case GET_LOGIN_PROBLEMS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetLoginProblems;
