import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IBreadcrumbItem} from '@General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import Deadline from '@Deadlinesindex/Deadline';
import {allDeadlines} from '@ActionsDeadlinesindex/DeadlineAction';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';
import {getTreeComponent} from '@/components/General/BranchTree/Redux/Actions/getOrganizationsAction';
import {dealinesPages} from '@components/DeadLines/DeadlinesIndex/redux/Interface';

export default function NewDeadline(): JSX.Element {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/', label: 'Aplicaciones'},
        {to: '/', label: 'Ciclo prendario'},
        {to: '', label: 'Plazos'},
    ];

    const dispatch = useDispatch();
        const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const deadline: dealinesPages = useSelector((state: RootState) => {
        return state.deadline;
    });

    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);
    const [searchTerm, setsearchTerm] = useState('');
    const [pageSize, setPageSize] = useState(1);

    const filterDeadline = (close?: boolean) => {
        const filters = {
            companyId: companyId,
            branches: branchesId,
            search: close ? '' : deadline.searchDeadlines,
            pageSize: deadline.pageSize,
            pageNumber: deadline.actualPages,
            filterPromotionType: deadline.filterHead,
            orderType: deadline.orderType,
        };
        dispatch(allDeadlines(filters, token));
    };
    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));
    }, []);

    useEffect(() => {
        filterDeadline();
    }, [
        branchesId,
        deadline.searchDeadlines,
        deadline.filterHead,
        deadline.actualPages,
        deadline.orderType,
    ]);

    return (
        <ContainerFlex FlexDir="column" Padding="0 1.875rem 1.5rem 1.5rem">
            <ContainerFlex Justify="flex-start" Padding="30px 0 20px 0">
                <Breadcrumb items={itemsBreadcrumb} />
            </ContainerFlex>
            <ContainerFlex Align="left" Justify="left">
                <ContainerFlex
                    Width="20%"
                    FlexDir="column"
                    Border="solid 1px #e5e7e9"
                    Justify="start"
                    Height="700px"
                    Radius="4px"
                    Align="start"
                    OverFlow="auto"
                    Margin="0 25px 1rem 0"
                >
                    <ContainerFlex
                        Height="48px"
                        Padding="16px"
                        Bb="solid 1px #e5e7e9"
                        Justify="flex-start"
                    >
                        <Text FontSize="0.875rem" Color="#414042">
                            Organizaciones
                        </Text>
                    </ContainerFlex>
                    {TreeComponent.data && TreeComponent.data.length && (
                        <TreeBranch
                            checked={checked}
                            branchesId={branchesId}
                            levelsId={levelsId}
                            branchesData={branchesData}
                            setChecked={setChecked}
                            setBranch={setBranch}
                            setLevel={setLevel}
                            setBranchData={setBranchData}
                            isEdit={false}
                        />
                    )}
                </ContainerFlex>
                <ContainerFlex Width="80%" Height="fit-content" FlexDir="column" Align="left">
                    <Deadline
                        Branch={branchesId}
                        filterDeadline={filterDeadline}
                        searchTerm={searchTerm}
                        setsearchTerm={setsearchTerm}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        deadline={deadline}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
}
