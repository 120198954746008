import axios from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

import {
    GET_FOUNDRY,
    GET_FOUNDRY_SUCCESS,
    GET_FOUNDRY_ERROR,
} from '@TypesAuditAssignment/FoundryTypes';

export const fetchGetFoundry = () => {
    return {
        type: GET_FOUNDRY,
    };
};
export const fetchGetFoundrySuccess = (result: {data: unknown}) => {
    return {
        type: GET_FOUNDRY_SUCCESS,
        value: result.data,
    };
};
export const fetchGetFoundryError = () => {
    return {
        type: GET_FOUNDRY_ERROR,
    };
};
export const getFoundry = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetFoundry());
        return axios
            .get(`${process.env.REACT_APP_SERVICE_FOUNDRY}${URL.urlFoundry.Foundry}`, {headers})
            .then((result) => {
                dispatch(fetchGetFoundrySuccess(result));
            })
            .catch((err) => {
                dispatch(fetchGetFoundryError());
            });
    };
};
