import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {useForm} from 'react-hook-form';
import es from 'date-fns/locale/es';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import Swal from 'sweetalert2';

import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {initInfoSubmit, initialInfo} from '@CreateDeadline/interfaces';
import {ContentLevels, Icons, Numbers} from '@Articles/style';
import {
    AddRouteItems,
    DeleteRouteItems,
    FalseDeadlines,
    GetLevelId,
} from '@ActionsDeadlinesindex/Search';
import {HandleLevelsRoute} from '@/components/Articles/Redux/Actions/RouteLevel';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import {initialInformation, setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

registerLocale('es', es);

export const InitialInformation = (props: initialInfo) => {
    const login = useSelector((state: RootState) => state.getUsersValidation.userData);
    const routes = useSelector((state: RootState) => state.deadLinesRoutes.RouteItemsPromotions);
    const dispatch: AppDispatch = useDispatch();
    const {Branch, deadlinesGeneral, deadlineById} = props;
    const [startDatePicker, setStartDatePicker] = useState<Date | null>(null);
    const [endDatePicker, setEndDatePicker] = useState<Date | null>(null);
    const {
        handleSubmit,
        register,
        reset,
        watch,
        formState: {},
    } = useForm({
        defaultValues: {
            startDate: startDatePicker,
            endDate: endDatePicker,
            nameTerm: '',
        },
    });
    const value = watch();

    const levelCurrent = () => {
        if (routes?.length) {
            const ROUTE: {[key: number]: {id: number; level: number}} = {
                1: {id: routes[0]?.id, level: 0},
                2: {id: routes[1]?.id, level: 1},
                3: {id: routes[2]?.id, level: 2},
                4: {id: routes[3]?.id, level: 3},
                5: {id: routes[4]?.id, level: 4},
            };
            return ROUTE[routes?.length];
        }
    };

    const HandleRoute = (index: number, value: {id: number}[]) => {
        if (deadlinesGeneral.step < 10 && !deadlineById.edit) {
            const id = index === 0 ? 0 : value[index - 1].id;
            HandleLevelsRoute(dispatch, String(login?.token), index, id, String(Branch[0]));
            dispatch(DeleteRouteItems(index));
            dispatch(FalseDeadlines());
            dispatch(GetLevelId(index));
        }
    };

    const openStep = () => {
        if (deadlinesGeneral.initialInformation.startDate) {
            reset({
                startDate: new Date(deadlinesGeneral.initialInformation.startDate),
                endDate: new Date(deadlinesGeneral.initialInformation.startDate),
                nameTerm: deadlinesGeneral.initialInformation.nameTerm,
            });
        }
    };

    const onSubmit = (data: initInfoSubmit) => {
        if (startDatePicker !== null && endDatePicker !== null) {
            const startDate = new Date(`${startDatePicker}`);
            const endDate = new Date(`${endDatePicker}`);
            if (startDate.getTime() < endDate.getTime()) {
                const json = {
                    companyId: Number(login?.enterpriceDetails?.[0]?.enterpriceId),
                    branches: Branch,
                    nameTerm: data.nameTerm,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    catalogLevelType: Number(levelCurrent()?.level),
                    catalogLevel: Number(levelCurrent()?.id),
                };
                dispatch(initialInformation(json));
                dispatch(setStep(2));
            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'Fecha de Inicio debe ser menor a Fecha Vigencia.',
                    confirmButtonText: 'Continuar',
                });
            }
        }
    };

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            !deadlinesGeneral.initialInformation.nameTerm
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            setStartDatePicker(new Date(`${vDeadlines.startDate}`));
            setEndDatePicker(new Date(`${vDeadlines.endDate}`));
            const json = {
                companyId: vDeadlines.companyId,
                branches: vDeadlines.branches,
                nameTerm: vDeadlines.nameTerm,
                startDate: vDeadlines.startDate,
                endDate: vDeadlines.endDate,
                catalogLevelType: vDeadlines.catalogLevelType,
                catalogLevel: vDeadlines.catalogLevel,
            };
            dispatch(initialInformation(json));
            const lastLevel = deadlineById.deadlineId.data;
            lastLevel.breadCrumbs?.map((item) => {
                dispatch(
                    AddRouteItems({
                        description: item.description,
                        id: item.id,
                        status: true,
                        icon: item.urlIcon,
                    })
                );
                return {
                    description: item.description,
                    id: item.id,
                    status: true,
                    icon: item.urlIcon,
                };
            });
            reset({nameTerm: vDeadlines.nameTerm});
        }

        if (
            deadlinesGeneral.initialInformation.nameTerm &&
            (!deadlineById.edit || !deadlineById.copy)
        ) {
            setStartDatePicker(new Date(`${deadlinesGeneral.initialInformation.startDate}`));
            setEndDatePicker(new Date(`${deadlinesGeneral.initialInformation.endDate}`));
            reset({nameTerm: deadlinesGeneral.initialInformation.nameTerm});
        }
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
        >
            <FormContainer MarginTp="0" Width="100%" autoComplete="off">
                <TitleDeadline
                    title={'Información inicial'}
                    step={deadlinesGeneral.step}
                    stepCurrent={1}
                    stepDependence={9}
                    openStep={openStep}
                    dependence={deadlinesGeneral.initialInformation.nameTerm !== ''}
                />
                <ContainerFlex smFlexWrap="wrap" FlexDir="column">
                    {routes?.length > 0 && (
                        <ContainerFlex
                            Justify="left"
                            FlexWrap="flex-wrap"
                            Cursor="pointer"
                            Bb="1px solid #E4E7E9"
                            Padding="1rem 0 1rem 3.875rem"
                        >
                            <Numbers
                                Width="2.563rem"
                                Height="2.563rem"
                                Border={routes[routes?.length - 1].icon ? '' : '1px solid #E4E7E9'}
                                Margin="0 0.6rem 0 0"
                            >
                                {routes[routes?.length - 1].icon ? (
                                    <img src={routes[routes?.length - 1].icon} />
                                ) : (
                                    <Icons className="material-icons">category</Icons>
                                )}
                            </Numbers>
                            <ContainerFlex Width="auto" Justify="left">
                                {routes?.map((value: {description: string}, index: number) => {
                                    return (
                                        <ContainerFlex key={index} Width="auto">
                                            <ContentLevels
                                                FontWeight="400"
                                                Color="#414042"
                                                FontSize="0.875rem"
                                                TextDecoration="underline"
                                                Cursor={deadlinesGeneral.step < 10 ? 'pointer' : ''}
                                                onClick={() => HandleRoute(index, routes)}
                                            >
                                                {value.description}
                                            </ContentLevels>
                                            {routes?.length - 1 !== index && (
                                                <Icons
                                                    className="material-icons"
                                                    FontSize="1rem"
                                                    FontWeight="300"
                                                    Color="#A7A9AC"
                                                    Margin="0 0.2rem"
                                                >
                                                    chevron_right
                                                </Icons>
                                            )}
                                        </ContainerFlex>
                                    );
                                })}
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                    {deadlinesGeneral.step === 1 && (
                        <ContainerFlex FlexDir="column">
                            <ContainerFlex
                                Padding="1.5rem 6.188rem"
                                Align="start"
                                Justify="space-between"
                            >
                                <ContainerFlex
                                    Justify="start"
                                    Width="20%"
                                    FlexDir="column"
                                    Align="start"
                                    InputReact
                                >
                                    <Text FontSize="0.75rem" Color="#6d6e7" required>
                                        Fecha inicio
                                    </Text>
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        selected={startDatePicker}
                                        placeholderText="DD/MM/AAAA"
                                        wrapperClassName="datePicker__multi"
                                        disabled={deadlineById.edit}
                                        onChange={(date: Date) => setStartDatePicker(date)}
                                    />
                                </ContainerFlex>
                                <ContainerFlex
                                    Width="20%"
                                    FlexDir="column"
                                    Justify="flex-start"
                                    Align="start"
                                    InputReact
                                >
                                    <Text FontSize="0.75rem" Color="#6d6e7" required>
                                        Fecha Vigencia
                                    </Text>
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        selected={endDatePicker}
                                        wrapperClassName="datePicker__multi"
                                        placeholderText="DD/MM/AAAA"
                                        disabled={deadlineById.edit}
                                        onChange={(date: Date) => setEndDatePicker(date)}
                                    />
                                </ContainerFlex>
                                <ContainerFlex Width="55%" FlexDir="column" Align="start">
                                    <Text Color="#6d6e7" FontSize="0.75rem" required>
                                        Nombre del plazo
                                    </Text>
                                    <Input
                                        type="text"
                                        placeholder="Escribe aquí"
                                        {...register('nameTerm', {})}
                                        disabled={deadlineById.edit}
                                        maxLength={160}
                                        onKeyDown={(e: {
                                            key: string;
                                            preventDefault: () => void;
                                        }) => {
                                            if (e.key === "'" || e.key === '"' || e.key === '`')
                                                e.preventDefault();
                                        }}
                                    />
                                </ContainerFlex>
                            </ContainerFlex>
                            {!deadlineById.edit && (
                                <ButtonGeneral
                                    FontSize="0.875rem"
                                    margin="0 0 1.5rem 0"
                                    hColor="#35cf44"
                                    text="Continuar"
                                    clic={handleSubmit(onSubmit)}
                                    disabled={!startDatePicker || !endDatePicker || !value.nameTerm}
                                />
                            )}
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </FormContainer>
        </ContainerFlex>
    );
};
