import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import addPlusIconLight from '@components/CreditsAndPawns/icons/addPlusIconLight.svg';
import helpIcon from '@components/CreditsAndPawns/icons/helpIcon.svg';
import {CREDITSANDPAWNS_FOOTER_TABS} from '@components/CreditsAndPawns/constants';

const Footer = () => {
    return (
        <ContainerFlex Height="max-content" Justify="start" Gap="1px" backG="#F4F5F5">
            <ContainerFlex
                Gap="0.5rem"
                Width="max-content"
                Padding="0.5rem 1.5rem"
                Bt="1.5px solid #5A5AFF"
                Cursor="pointer"
                backG="#fff"
            >
                <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer">
                    {CREDITSANDPAWNS_FOOTER_TABS.VENTANA_PRINCIPAL}
                </Text>
                <Image Width="20px" Height="20px" src={helpIcon} />
            </ContainerFlex>
            <ContainerFlex
                Padding="0.5rem 1.5rem"
                Width="max-content"
                Bt="1.5px solid #D4D6D8"
                backG="#fff"
                Cursor="pointer"
            >
                <Image Width="20px" Height="20px" src={addPlusIconLight} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Footer;
