export const MODALCART = {
    CART: 'Carrito',
    CLOSE_MODAL: 'x',
    PAY: 'Pago de Cuota',
    LOAN: 'Préstamo',
    SHOW_CAR: 'Ver carrito',
};
export const ENABLEDCREDTIS = {
    PRODUCT: 'Productos',
    UNSELECT: 'Deseleccionar productos',
    LOAN: 'Préstamo',
    PAYMENT: 'Pago de cuota',
    NUMBERPAY: 'No. de cuota:',
    DELETE: 'Eliminar',
};
export const RESUMECREDITS = {
    TITLE: 'Resumen de compra',
    PAY: 'Pago cuota ',
    TOTAL: 'Subtotal',
    BUTTON: 'Comenzar proceso de compra',
};

export const CREDIT_DETAILS_HEADER = {
    CTA_BACK_TO: 'Volver a Mis Créditos',
};
export const CREDIT_DETAILS_PAYMENT = {
    SUB_TITLE: 'Método de pago',
    PARAGRAPH:
        'Agregando un método de pago podrás domiciliar tu pago y evitar asi cobros extra por mora.',
    CTA_ADD_PAYMENT_METHOD: 'Agregar método de pago',
};
export const CREDIT_DETAILS_INFORMATION = {
    STATUS: {
        SUB_TITLE: 'Crédito persona',
        CTA_ADD_TO_CART: 'Pagar cuota',
        CTA_PAYMENT: 'Agregar al carrito',
        FEES: 'Cuotas',
        OWE: 'Lo que debo',
        PAID: 'Pagado',
        CTA_VISIT_STORE: 'Visitar la tienda oficial',
        PAID_PRICE: '$ 2.000',
        OWE_PRICE: '$ 10.000',
        FEE_VALUE: '2/24',
    },
    LOAN: {
        SUB_TITLE: 'Detalle del préstamo',
        REQUESTED_QUANTITY: 'Cantidad solicitada',
        APPLICATION_DATE: 'Fecha de solicitud',
        STATE: 'Estado',
        REQUESTED_IN: 'Solicitado en',
        APPLICATION_FORM: 'Forma de solicitud',
        FEES: 'Cuotas',
        PAYMENT_FREQUENCY: 'Frecuencia de pago',
        CTA_TEXT: 'Descargar contrato',
    },
    TERMS: {},
};

export const CREDIT_CART_SHOPPING = {
    TITLE: 'Carrito de compra',
    MESSAGE: {
        TITLE: 'No hay productos seleccionados',
        MESSAGE: 'Selecciona un producto para poner proceder con la pasarela de pago.',
    },
};

export const STATUS_CODE = 200;

export const ALTS = {
    DOWNLOAD_BUTTON: 'icono de descarga de archivos',
    ARROW_LEFT: 'icono de flecha a la izquierda',
    ADD_SHOPPING_CART: 'icono para agrear articulo al carro de compras',
    LOANS_ICON: 'logo de compania',
    WARNING_ICON: 'icono de alerta amarillo',
    CARD_ICON: 'icono de tarjeta de credito',
    CALENDER_ICON: 'icono de calendario',
    DELETE_ICON: 'icono de eliminacion',
};
