import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@/config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {actualPage, searchDeadlines} from '@DeadLines/DeadlinesIndex/redux/actions/DeadlineAction';
import {DeadlineTableFilters} from '@components/DeadLines/DeadlinesIndex/redux/Interface';

export const FilterDeadline = (props: DeadlineTableFilters) => {
    const {filterDeadline, searchTerm, setsearchTerm, pageSize, setPageSize, deadline} = props;
    const [searchFocus, setSearchFocus] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const {register, reset} = useForm({
        defaultValues: {
            searchTerm: searchTerm,
        },
    });

    const closeSearch = () => {
        dispatch(searchDeadlines(''));
        paginate('firstPage');
        setsearchTerm('');
        reset({searchTerm: ''});
        setSearchFocus(false);
        filterDeadline(true);
    };

    const paginate = (button: string) => {
        switch (button) {
            case 'firstPage':
                if (deadline.actualPages !== 1) {
                    dispatch(actualPage(1));
                    setPageSize(1);
                }
                break;
            case 'back':
                if (deadline.actualPages > 1) {
                    dispatch(actualPage(deadline.actualPages - 1));
                    setPageSize(pageSize - deadline.deadlineCount.pageSize);
                }
                break;
            case 'next':
                if (deadline.actualPages < deadline.deadlineCount.totalPages) {
                    dispatch(actualPage(deadline.actualPages + 1));
                    setPageSize(pageSize + deadline.deadlineCount.pageSize);
                }
                break;
            case 'lastPage':
                if (deadline.actualPages !== deadline.deadlineCount.totalPages) {
                    dispatch(actualPage(deadline.deadlineCount.totalPages));
                    setPageSize(
                        deadline.deadlineCount.pageSize * (deadline.deadlineCount.totalPages - 1) +
                            1
                    );
                }
                break;
        }
    };

    return (
        <ContainerFlex Padding="16px 0">
            <ContainerFlex Width="50%" Justify="start">
                <SearchInput
                    Height="40px"
                    Width="20.063rem"
                    Position="none"
                    Padding="0 1rem 0 1rem"
                >
                    <input
                        type="textfield"
                        placeholder="Buscar nombre de plazo"
                        {...register('searchTerm')}
                        onChange={(e) => {
                            if (e.target.value.length >= 3) {
                                dispatch(searchDeadlines(e.target.value));
                                setsearchTerm(e.target.value);
                                paginate('firstPage');
                            }
                        }}
                        maxLength={160}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => {
                            if (searchTerm === '') setSearchFocus(false);
                        }}
                    />
                    {!searchFocus && searchTerm === '' ? (
                        <IconContainer
                            JustifySelf="end"
                            FontSize="1.5rem"
                            Color="#6d6e71"
                            className="material-icons"
                        >
                            search
                        </IconContainer>
                    ) : (
                        <IconContainer
                            onClick={() => closeSearch()}
                            JustifySelf="end"
                            FontSize="1.5rem"
                            Color="#6d6e71"
                            className="material-icons"
                        >
                            close
                        </IconContainer>
                    )}
                </SearchInput>
            </ContainerFlex>

            <ContainerFlex
                Width="50%"
                Justify={deadline.deadlineCount?.data.length > 0 ? 'space-between' : 'end'}
            >
                {deadline.deadlineCount?.data.length > 0 && (
                    <ContainerFlex Width="50%">
                        <IconContainer
                            Color={deadline.actualPages !== 1 ? '' : '#e5e7e9'}
                            onClick={() => paginate('firstPage')}
                            FontSize="1.25rem"
                            className="material-icons"
                        >
                            first_page
                        </IconContainer>
                        <IconContainer
                            Color={deadline.actualPages > 1 ? '' : '#e5e7e9'}
                            onClick={() => paginate('back')}
                            FontSize="1.25rem"
                            className="material-icons"
                        >
                            keyboard_arrow_left
                        </IconContainer>
                        <Text FontSize="0.75rem">
                            {deadline.actualPages}-{pageSize} de {deadline.deadlineCount.totalPages}
                        </Text>
                        <IconContainer
                            Color={
                                deadline.actualPages < deadline.deadlineCount.totalPages
                                    ? ''
                                    : '#e5e7e9'
                            }
                            onClick={() => paginate('next')}
                            FontSize="1.25rem"
                            className="material-icons"
                        >
                            keyboard_arrow_right
                        </IconContainer>
                        <IconContainer
                            Color={
                                deadline.actualPages !== deadline.deadlineCount.totalPages
                                    ? ''
                                    : '#e5e7e9'
                            }
                            onClick={() => paginate('lastPage')}
                            FontSize="1.25rem"
                            className="material-icons"
                        >
                            last_page
                        </IconContainer>
                    </ContainerFlex>
                )}

                <ButtonGeneral
                    FontSize="0.875rem"
                    hColor="#35cf44"
                    width="9.75rem"
                    text="Crear nuevo plazo"
                    clic={() => {
                        window.location.href = '#/NewDeadline/RouteDeadline';
                    }}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
