import {NEW_GROUP} from '@components/LocationsBranch/Redux/types';
import {INewGroup, INewGroupRedux} from '@components/LocationsBranch/interfaces';

const initialState: INewGroup = {
    error: false,
    loading: false,
    newGroup: null,
};

export const newGroup = (state = initialState, action: INewGroupRedux): INewGroup => {
    switch (action.type) {
        case NEW_GROUP.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case NEW_GROUP.SUCCESS:
            return {
                ...state,
                loading: false,
                newGroup: action.newGroup,
            };
        case NEW_GROUP.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
