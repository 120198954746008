import React, {useState, useEffect, useRef} from 'react';
import {ContainerFlex, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {Slider} from '@/components/CreditsAndPawns/styles';
import {CREDIT_REQUEST_INFORMATION} from '@/components/CreditsAndPawns/constants';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {
    setRecalculatedAmount,
    setRecalculatedAmountReceive,
    getPercentageAmortizationAction,
} from '@/components/CreditsAndPawns/Redux/Actions/GetPercentageAmortizationActions';
import {ICreditRequestPercentageProps} from '@/components/CreditsAndPawns/interfaces';

const CreditRequestPercentage: React.FC<ICreditRequestPercentageProps> = ({
    amountSolicutude: amountSolicitude,
    countPeriod,
    anualRate,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const [percentageValue, setPercentageValue] = useState<number>(amountSolicitude);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const time: number = 1000;
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {recalculatedAmount, recalculatedAmountReceive} = useSelector((state: RootState) => {
        return state.GetPercentageAmortizationReducer;
    });

    useEffect(() => {
        dispatch(setRecalculatedAmount(amountSolicitude));
        dispatch(
            getPercentageAmortizationAction({
                token,
                totalAmount: amountSolicitude,
                anualRate,
                countPeriod,
            })
        );
    }, [amountSolicitude]);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value);
        setPercentageValue(newValue);
        dispatch(setRecalculatedAmount(newValue));
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            dispatch(
                getPercentageAmortizationAction({
                    token,
                    totalAmount: newValue,
                    anualRate,
                    countPeriod,
                })
            );
        }, 1000);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ''));
        if (!newValue) {
            setPercentageValue(0);
            dispatch(setRecalculatedAmount(0));
            dispatch(setRecalculatedAmountReceive(0));
        } else if (newValue <= amountSolicitude) {
            setPercentageValue(newValue);
            dispatch(setRecalculatedAmount(newValue));
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                dispatch(
                    getPercentageAmortizationAction({
                        token,
                        totalAmount: newValue,
                        anualRate,
                        countPeriod,
                    })
                );
            }, time);
        }
    };
    return (
        <>
            <ContainerFlex Width="100%" FlexDir="column" Gap="24px">
                <ContainerFlex Width="100%" Height="24px">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {CREDIT_REQUEST_INFORMATION.PERCENTAGE_MESSAGE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="124px"
                    FlexDir="column"
                    Justify="space-between"
                    Align="center"
                    Gap="4px"
                >
                    <ContainerFlex Width="234px" Height="68px" Justify="center" Align="center">
                        <Input
                            FontFamily="Nunito"
                            FontSize="2.05rem"
                            Padding="8px 24px"
                            Radius="8px"
                            TextAlign="center"
                            Height="68px"
                            FontWeight="500"
                            value={numberToCurrencyWithoutCents(recalculatedAmount)}
                            onChange={handleInputChange}
                        />
                    </ContainerFlex>
                    <Slider
                        type="range"
                        min={0}
                        max={amountSolicitude}
                        value={percentageValue}
                        onChange={handleSliderChange}
                        Cursor="pointer"
                    />
                    <ContainerFlex Width="100%" Height="12px" Justify="space-between">
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {`0%`}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {`100%`}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <ContainerFlex Width="100%" Radius="16px" backG="#F4F5F5" Padding="16px 0px">
                <ContainerFlex Width="100%" Gap="8px" Justify="center" Align="center">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {CREDIT_REQUEST_INFORMATION.LEND}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(recalculatedAmount)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Width="100%" Gap="8px" Justify="center" Align="center">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {CREDIT_REQUEST_INFORMATION.RECEIVE}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="1.0rem"
                        Color="#2A2C2F"
                        FontStyle="normal"
                        LHeight="1.5rem"
                    >
                        {numberToCurrencyWithoutCents(recalculatedAmountReceive)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};

export default CreditRequestPercentage;
