import React from 'react';
import {useForm, Controller, FieldError} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {CustomSelect} from './InputSelect';
import {CustomSelectOptions} from './SelectOptions';
import {validationFilter} from './Operational/validateYup';
import {MOD_FILTER, data_options, data_type} from './Operational/Constants';
import {ClosedModalProps} from './Operational/interfaceType';

export const ModalFilter = ({isOpen, closeModal}: ClosedModalProps) => {
    const {
        reset,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(validationFilter),
        defaultValues: {
            Access: '',
            State: '',
        },
    });

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
            reset();
        }
    };
    const handleClose = () => {
        reset();
        closeModal();
    };
    const onSubmit = () => {
        closeModal();
        reset();
    };

    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Padding="24px"
                >
                    <ContainerForm Gap="24px" FlexDir="column" onSubmit={handleSubmit(onSubmit)}>
                        <ContainerFlex Justify="start" Height="max-content">
                            <Text FontWeight="700" FontSize="1.5rem">
                                {MOD_FILTER.TITLE}
                            </Text>
                        </ContainerFlex>

                        <ContainerFlex Justify="start" Height="max-content">
                            <Text color="#54575C" FontSize="1.25rem" FontWeight="600">
                                {MOD_FILTER.INDICATION}
                            </Text>
                        </ContainerFlex>

                        <ContainerFlex
                            Gap="8px"
                            Height="max-content"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="700">
                                {MOD_FILTER.ACCESS}
                            </Text>
                            <Controller
                                name="Access"
                                control={control}
                                render={({field}) => (
                                    <CustomSelect
                                        key={field.value}
                                        {...field}
                                        placeholder="Selecciona"
                                        options={data_type}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.Access && (
                                <Text Color="#FF6357">{(errors.Access as FieldError).message}</Text>
                            )}
                        </ContainerFlex>

                        <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                            <Text color="#54575C" FontSize="1rem" FontWeight="700">
                                {MOD_FILTER.STATE}
                            </Text>
                            <Controller
                                name="State"
                                control={control}
                                render={({field}) => (
                                    <CustomSelectOptions
                                        key={field.value}
                                        {...field}
                                        placeholder="Selecciona"
                                        options={data_options}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.State && (
                                <Text Color="#FF6357">{(errors.State as FieldError).message}</Text>
                            )}
                        </ContainerFlex>

                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                onClick={() => handleClose()}
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                type="button"
                            >
                                {MOD_FILTER.BTN}
                            </ButtonGenerals>

                            <ButtonGenerals Width="100%" type="submit">
                                {MOD_FILTER.BTNS}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
