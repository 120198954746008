import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    POST_UPDATE_LEVELS_ERROR,
    POST_UPDATE_LEVELS_FINISH,
    POST_UPDATE_LEVELS_START,
    POST_UPDATE_LEVELS_SUCCESS,
} from '@TypesOrgCreation/types';
import {updatePageNumber} from '@ActionsOrgCreation/CheckedState';
import {AppDispatch} from '@/config/store';
import {IUpdateLevel} from '@OrganizationCreation/interface';

export const fetchPostUpdateLevelStart = () => {
    return {
        type: POST_UPDATE_LEVELS_START,
    };
};
export const fetchPostUpdateLevelSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: POST_UPDATE_LEVELS_SUCCESS,
        value: result.data,
    };
};
export const fetchPostUpdateLevelError = () => {
    return {
        type: POST_UPDATE_LEVELS_ERROR,
    };
};
export const fetchPostUpdateLevelFinish = () => {
    return {
        type: POST_UPDATE_LEVELS_FINISH,
    };
};
export function postUpdateLevels(data: IUpdateLevel, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostUpdateLevelStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateLevel}`,
                data,
                {headers}
            );
            dispatch(fetchPostUpdateLevelSuccess(response));
            dispatch(updatePageNumber(1));
        } catch (error) {
            dispatch(fetchPostUpdateLevelError());
        } finally {
            dispatch(fetchPostUpdateLevelFinish());
        }
    };
}
