import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {FETCH_UPDATE_ENDORSEMENTS_CART} from '@components/ShoppingCart/Redux/types';
import {IUpdateEndorsementsBody} from '@components/ShoppingCart/Redux/interfaces';

export const fetchUpdateEndorsementCartStart = () => ({
    type: FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_START,
});

export const fetchUpdateEndorsementCartSuccess = (result: AxiosResponse) => ({
    type: FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_SUCCESS,
    payload: result.data,
});

export const fetchUpdateEndorsementCartError = (error: AxiosError) => ({
    type: FETCH_UPDATE_ENDORSEMENTS_CART.FETCH_UPDATE_ENDORSEMENTS_CART_ERROR,
    error: error,
});

export const fetchUpdateEndorsementCart = (
    token: string,
    data: IUpdateEndorsementsBody,
    recalculate?: () => void,
    errorCallback?: () => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUpdateEndorsementCartStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateEndorsements}`,
                data,
                {
                    headers,
                }
            );
            dispatch(fetchUpdateEndorsementCartSuccess(response.data));
            if (recalculate) recalculate();
        } catch (error) {
            dispatch(fetchUpdateEndorsementCartError(error as AxiosError));
            if (errorCallback) errorCallback();
        }
    };
};
