import {IDataFormUser} from '@components/CreateUsers/Interface';
import {
    UPDATE_DATA_FORM_CREATE_USER,
    RESET_FORM_CREATE_USER,
} from '@components/CreateUsers/Redux/Types/Types';

const initialState = {
    status: true,
    startChangePassword: true,
    aditionalInformationEmployee: {
        manageAditionalInformation: false,
        startSesionEmail: false,
        startSesionPhone: false,
    },
    employeeRolees: [],
    levelBranch: [],
};

const dataFormCreateUserForm = (state = initialState, action: IDataFormUser) => {
    switch (action.type) {
        case UPDATE_DATA_FORM_CREATE_USER:
            return action.payload;
        case RESET_FORM_CREATE_USER:
            return initialState;
        default:
            return state;
    }
};

export default dataFormCreateUserForm;
