import React from 'react';

import {IIdentityDocsModalProps} from '@components/Shopify/ClientProfile/interfaces';
import * as style from '@components/CreateUsers/Styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import AddIdentityDocs from '@components/Shopify/ClientProfile/AddIdentityDocs';

export const IdentityDocsModal: React.FC<IIdentityDocsModalProps> = ({
    clientId,
    showModal,
    setShowModal,
}) => {
    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContect}
            background="rgb(0 0 0 / 60%)"
            closeOnOutsideClick={false}
            Width="38.313rem"
            HeightDialog="26.563rem"
        >
            <AddIdentityDocs clientId={clientId} setShowModal={setShowModal} />
        </Modal>
    );
};
export default IdentityDocsModal;
