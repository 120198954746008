import {URL} from '@config/constants/index';

import axios, {AxiosResponse} from 'axios';
import {
    GET_EMAIL_IN_USE_START,
    GET_EMAIL_IN_USE_SUCCESS,
    GET_EMAIL_IN_USE_ERROR,
    RESET_GET_EMAIL_IN_USE,
} from '@components/CreateUsers/Redux/Types/Types';
import { AppDispatch } from '@/config/store';

export const fetchGetEmailInUseStart = () => {
    return {
        type: GET_EMAIL_IN_USE_START,
    };
};

export const fetchGetEmailInUseSuccess = (result: AxiosResponse) => {
    return {
        type: GET_EMAIL_IN_USE_SUCCESS,
        payload: result,
    };
};

export const fetchGetEmailInUseError = () => {
    return {
        type: GET_EMAIL_IN_USE_ERROR,
    };
};

export function GetEmailInUse(token: string, companyId: number, email: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetEmailInUseStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetEmailInUse}?email=${email}&companyId=${companyId}`,
                [],
                {headers}
            );

            dispatch(fetchGetEmailInUseSuccess(response));
        } catch (error) {
            dispatch(fetchGetEmailInUseError());
        }
    };
}

export const resetGetEmailInUse = () => {
    return {
        type: RESET_GET_EMAIL_IN_USE,
    };
};
