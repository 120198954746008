import {PERSISTENCE_GROUP_BRANCH} from '@components/LocationsBranch/Redux/types';
import {
    ILocationsPersistence,
    ILocationsPersistenceRedux,
} from '@components/LocationsBranch/interfaces';

const initialState: ILocationsPersistence = {
    name: '',
    businessLine: [],
    branchSalesChannel: [],
    user: [],
    openingHourAndHolidays: {},
    holidays: [],
    storage: [],
};

export const groupBranchPersistence = (
    state = initialState,
    action: ILocationsPersistenceRedux
) => {
    const PERSISTENCE: {
        [key: string]: (
            state: ILocationsPersistence,
            action: ILocationsPersistenceRedux
        ) => ILocationsPersistence;
    } = {
        [PERSISTENCE_GROUP_BRANCH.NAME]: (state, action) => ({
            ...state,
            name: action.name,
        }),
        [PERSISTENCE_GROUP_BRANCH.BUSINESS_LINE]: (state, action) => ({
            ...state,
            businessLine: action.businessLine,
        }),
        [PERSISTENCE_GROUP_BRANCH.SALES_CHANNELS]: (state, action) => ({
            ...state,
            branchSalesChannel: action.branchSalesChannel,
        }),
        [PERSISTENCE_GROUP_BRANCH.USERS]: (state, action) => ({
            ...state,
            user: action.user,
        }),
        [PERSISTENCE_GROUP_BRANCH.SCHEDULES_AND_HOLIDAYS]: (state, action) => ({
            ...state,
            openingHourAndHolidays: action.openingHourAndHolidays,
        }),
        [PERSISTENCE_GROUP_BRANCH.STORAGE]: (state, action) => ({
            ...state,
            storage: action.storage,
        }),
        [PERSISTENCE_GROUP_BRANCH.RESET]: () => initialState,
        [PERSISTENCE_GROUP_BRANCH.HOLIDAYS_UPDATE]: (state, action) => ({
            ...state,
            holidays: action.holidays,
        }),
    };

    const getCurrentState = PERSISTENCE[action.type];
    return getCurrentState ? getCurrentState(state, action) : state;
};
